import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TurnosService, MenuService, AlertService, UsuariosService, MaquinasService, OperariosService, AreasProductivasService } from '@app/_services';
import {  Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { groupBy } from "@progress/kendo-data-query";
import { MyFunctions } from '@app/_helpers';


@Component({
    selector: 'app-turnosOperarios',
    templateUrl: 'turnosOperarios.component.html'
})

export class TurnosOperariosComponent {

    public id: number;
    public ano_readonly: boolean;
    public numSemana_readonly: boolean;
    public fechaInicio: Date;
    public fechaFin: Date;
    public listaMaquinas: any;
    public listaMaquinasSeccion = [];
    public listaMaquinasImprimir: any;
    public backspaceIsPressed: boolean = false;

    public time = new Date("10:00");

    public horasDesvio = 1;

    public modalReference: NgbModalRef;
    public modalReferenceloading: NgbModalRef;
    public closeResult = '';
    public form: FormGroup;
    public loading = false;
    public submitted = false;
    public isAddMode: boolean;
    public user = this.userService.userValue;

    public turnos =
        [
            { nombreTurno: this.translateService.instant('manana'), idTurno: 1, operarios: [], showMultiselect: true },
            { nombreTurno: this.translateService.instant('tarde'), idTurno: 2, operarios: [], showMultiselect: true },
            { nombreTurno: this.translateService.instant('noche'), idTurno: 3, operarios: [], showMultiselect: true }
        ];

    public diasSemana =
        [
            { nombreDia: this.translateService.instant('lunes'), idDia: 1, turnosDia: this.turnos },
            { nombreDia: this.translateService.instant('martes'), idDia: 2, turnosDia: this.turnos },
            { nombreDia: this.translateService.instant('miercoles'), idDia: 3, turnosDia: this.turnos },
            { nombreDia: this.translateService.instant('jueves'), idDia: 4, turnosDia: this.turnos },
            { nombreDia: this.translateService.instant('viernes'), idDia: 5, turnosDia: this.turnos },
            { nombreDia: this.translateService.instant('sabado'), idDia: 6, turnosDia: this.turnos },
            { nombreDia: this.translateService.instant('domingo'), idDia: 7, turnosDia: this.turnos }
        ];

    public maquinasVaciarSeleccionadas: any[] = [];
    public turnosVaciarSeleccionados: any[] = [];
    public diasVaciarSemanaSeleccionados: any[] = [];
    public fechaCompleta: String;

    public tablaPorDefecto = [
        {
            idMaquina: -1,
            nombreMaquina: '',
            dias: this.diasSemana
        },
        {
            idMaquina: -2,
            nombreMaquina: '',
            dias: this.diasSemana
        }];
    public tablaMaquinas = [];
    public tablaMaquinasOriginal = [];
    public listaGuardar = [];
    public listaEliminar = "";



    public areasProductivas = [];
    public selectedArea;
    public maquinas = [];
    public listaOperarios = [];
    public multiselectValue = [];
    public listaNombreValorOperario = [];
    public turnoSemanaNum: number = -1;
    public turnoAnnoNum: number = -1;
    public turnosCalendario: any;
    public idCalendario: number = 0;
    public listaOperarios1: any;
    public ensenarTabla = false;
    public diaImprimir: any;
    public proba: any;
    public numDuplicarSemana: number;
    public selectedAreaSeccion = [];
    public weekNo: number;
    public fechaInicioSemana: any;
    public listaTurnosPosteriores = [];
    public selectedTurnosPosteriores = [];
    public isChecked: boolean = false;
    public isCheckedVaciarMaquina: boolean = false;
    public isCheckedVaciarTurnos: boolean = false;
    public isCheckedVaciarDiaSemana: boolean = false;
    public turnoNoCreado: boolean = false;

    // POPUP COPIAR LUNES
    martesSelected = false;
    lunesSelected = false;
    miercolesSelected = false;
    juevesSelected = false;
    viernesSelected = false;
    sabadoSelected = false;
    domingoSelected = false;

    public turnoACopiarSeleccionado: any;
    public turnoACopiar: any = [];

    @ViewChild('popupCopiarDias') popupCopiarDias: NgbModalRef;
    @ViewChild('popupDuplicar') popupDuplicar: NgbModalRef;
    @ViewChild('popupVaciar') popupVaciar: NgbModalRef;
    @ViewChild('popupImprimir') popupImprimir: NgbModalRef;

    constructor(private turnosService: TurnosService,
        private maquinasService: MaquinasService,
        private areasProductivasService: AreasProductivasService,
        private operariosService: OperariosService,
        private translateService: TranslateService,
        private userService: UsuariosService,
        public router: Router,
        private menuService: MenuService,
        private modalService: NgbModal,
        public myFunctions: MyFunctions,
        protected alertService: AlertService) {

        this.menuService.titulo = this.translateService.instant('turnosOperarios').toUpperCase();

        this.turnoACopiar = [
            {id: 1, nombre: this.translateService.instant("lunes")},
            {id: 2, nombre: this.translateService.instant("martes")},
            {id: 3, nombre: this.translateService.instant("miercoles")},
            {id: 4, nombre: this.translateService.instant("jueves")},
            {id: 5, nombre: this.translateService.instant("viernes")},
            {id: 6, nombre: this.translateService.instant("sabado")},
            {id: 7, nombre: this.translateService.instant("domingo")},
        ]
        this.turnoACopiarSeleccionado = this.turnoACopiar[0]; 

    }

    ngOnInit() {
        console.log(this.user);
        console.log(this.userService);


        this.areasProductivasService.Get_AreasConSecciones(this.user.id).subscribe((json) => {
            var areas: any = json;
            var data = [];
            this.selectedAreaSeccion.push(areas[0]);

            areas.forEach(area => {
                var areaSeccion = {
                    idArea: area.idAreaProductiva,
                    nombreArea: area.nombreAreaProductiva,
                    idSeccion: area.idSeccion,
                    nombreSeccion: area.nombreSeccion
                }
                data.push(areaSeccion);
            });
            this.areasProductivas = groupBy(data, [{ field: "nombreArea" }])

            this.maquinasService.get().subscribe((json) => {
                var maquinasValues: any = json;
                var maquinaLista = [];
                maquinasValues.forEach(maquinaValue => {
                    this.maquinas.push({ text: maquinaValue.nombre, value: maquinaValue.nombre });
                    var maquina = {
                        idMaquina: maquinaValue.id,
                        maquina: maquinaValue.nombre,
                        idSeccion: maquinaValue.idSeccion
                    };
                    maquinaLista.push(maquina);
                });
                this.listaMaquinas = maquinaLista;

                this.listaMaquinas.forEach(listaMaquinas => {
                    this.selectedAreaSeccion.forEach(selectedArea => {
                        if (listaMaquinas.idSeccion == selectedArea.idSeccion) {
                            this.listaMaquinasSeccion.push(listaMaquinas);
                        }
                    });
                });
            });
        });

        this.operariosService.getAllUsuariosConColor().subscribe((json) => {
            var operarios: any = json;
            operarios.forEach(operario => {
                this.listaOperarios.push(operario);
                this.listaNombreValorOperario.push({
                    id: operario.idUsuario,
                    nombre: operario.nombre,
                    color: operario.color,
                    nombreCompleto: operario.nombre + " " + operario.apellido,
                    iniciales: this.iniciales(operario.nombre, operario.apellido)
                });
            });
        });
        //console.log(this.listaOperarios);

        this.turnosService.GetAll().subscribe((result) => {
            this.turnosCalendario = result.data;
        });



    }

    onSubmit() {
        //console.log(this.tablaMaquinasOriginal);
        this.compararInicialConFinal();
        console.log("Lista para añadir => ", this.listaGuardar)
        console.log("Lista para eliminar => " + this.listaEliminar)
        var error1 = false;
        var error2 = false;
        //Guardar operarios

        this.turnosService.Guardar_turno_operario_Bulk(this.listaGuardar).subscribe((result) => {
            if (result.error == true) {
                console.log("Errorea operarioa gordetzean 1")
                error1 = true;
            }
            //Eliminar operarios
            if (this.listaEliminar != ""){
                this.turnosService.Delete_turnos_operarios(this.listaEliminar).subscribe((result) => {
                    if (result == -1) {
                        console.log("Errorea operarioa gordetzean 2")
                        error1 = true;
                    }
                    if (error1 || error2) {
                        this.alertService.error(this.translateService.instant('error'));
                    } else {
                        this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
                    }
                    this.cargarTablaMaquinas(this.listaMaquinasSeccion, this.turnoSemanaNum);
                });
            }else{
                if (error1 || error2) {
                    this.alertService.error(this.translateService.instant('error'));
                } else {
                    this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
                }
                this.cargarTablaMaquinas(this.listaMaquinasSeccion, this.turnoSemanaNum);
            }
            


        });


    }

    public vaciar() {
        this.modalReference = this.modalService.open(this.popupVaciar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    public abrirPopupDuplicar() {
        this.modalReference = this.modalService.open(this.popupDuplicar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    public abrirPopupCopiarDias() {
        this.modalReference = this.modalService.open(this.popupCopiarDias, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    public abrirPopupImprimir() {
        this.modalReference = this.modalService.open(this.popupImprimir, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    public onChange(e: any) {
        this.turnoSemanaNum = this.getWeekNumber(e);
        this.turnoAnnoNum = e.getFullYear();
        //console.log("Numero semana => " + this.turnoSemanaNum + "Año => " + this.turnoAnnoNum );

        var find = false;
        this.turnosCalendario.forEach(turnoCalendario => {
            if (this.turnoSemanaNum == turnoCalendario.numSemana && this.turnoAnnoNum == turnoCalendario.anno) {
                find = true;
                this.idCalendario = turnoCalendario.id;
                this.fechaInicioSemana = turnoCalendario.fechaInicio;
            }
        });
        if (find) {
            this.turnosService.Get_Turnos_Posteriores(this.fechaInicioSemana).subscribe(json => {
                var turnosPosteriores: any = json.data;
                turnosPosteriores.forEach(turnoPosterior => {
                    var data = {
                        id: turnoPosterior.id,
                        turno: turnoPosterior.anno.toString() + " - " + turnoPosterior.numSemana.toString()
                    }
                    this.listaTurnosPosteriores.push(data);
                });

                this.cargarTablaMaquinas(this.listaMaquinasSeccion, this.turnoSemanaNum);
            });
        } else {
            // grida ezabatu
            var hutsa = []
            this.cargarTablaMaquinas(this.listaMaquinasSeccion, -1);
        }
    }
    public onClick() {
        this.isChecked = !this.isChecked;
        this.selectedTurnosPosteriores = this.isChecked ? this.listaTurnosPosteriores.slice() : [];
    }
    public onClickVaciarMaquina() {
        this.isCheckedVaciarMaquina = !this.isCheckedVaciarMaquina;
        this.maquinasVaciarSeleccionadas = this.isCheckedVaciarMaquina ? this.listaMaquinas.map(f => f.idMaquina) : [];
    }
    public onClickVaciarTurnos() {
        this.isCheckedVaciarTurnos = !this.isCheckedVaciarTurnos;
        this.turnosVaciarSeleccionados = this.isCheckedVaciarTurnos ? this.turnos.map(f => f.idTurno) : [];
    }
    public onClickVaciarDiaSemana() {
        this.isCheckedVaciarDiaSemana = !this.isCheckedVaciarDiaSemana;
        this.diasVaciarSemanaSeleccionados = this.isCheckedVaciarDiaSemana ? this.diasSemana.map(f => f.idDia) : [];
    }

    public cargarTablaMaquinas(maquinas: any, semanaTurno: number) {
        //cargar maquinas en el json
        var listaT = [];
        var listaD = [];
        this.tablaMaquinas = [];
        this.tablaMaquinasOriginal = [];

        if (semanaTurno != -1) {
            //Traer los operarios del turno
            this.turnoNoCreado = false;
            var listaOperarios: any;
            this.turnosService.Get_turno_operario_byId(this.idCalendario).subscribe((json) => {
                this.ensenarTabla = true;
                listaOperarios = json.data;
                maquinas.forEach(maquina => {
                    listaD = [];
                    this.diasSemana.forEach(dia => {
                        listaT = [];
                        this.turnos.forEach(turno => {
                            var operariosValores = this.cargarOperariosSeleccionados(listaOperarios, maquina.idMaquina, dia.idDia, turno.idTurno);
                            if (operariosValores.length > 0) {
                                var listaTurnos = {
                                    showMultiselect: false,
                                    idTurno: turno.idTurno,
                                    nombreTurno: turno.nombreTurno,
                                    operarios: operariosValores
                                };
                            } else {
                                var listaTurnos = {
                                    showMultiselect: true,
                                    idTurno: turno.idTurno,
                                    nombreTurno: turno.nombreTurno,
                                    operarios: operariosValores
                                };
                            }
                            listaT.push(listaTurnos);
                        });
                        var listaDias = {
                            idDia: dia.idDia,
                            nombreDia: dia.nombreDia,
                            turnosDia: listaT
                        };
                        listaD.push(listaDias);
                    });
                    var mqn = {
                        idMaquina: maquina.idMaquina,
                        nombreMaquina: maquina.maquina,
                        dias: listaD
                    };
                    this.tablaMaquinas.push(mqn);
                    //this.tablaMaquinasOriginal.push(mqn);
                });
                // Guardar la tabla original para compararla con la final para saber cual operario insertar y cual eliminar
                this.tablaMaquinasOriginal = JSON.parse(JSON.stringify(this.tablaMaquinas));
            });
        } else {
            this.ensenarTabla = false;
            this.turnoNoCreado = true;
        }
    }

    public iniciales(nombre: string, apellido: string) {
        var nombreSeparado = nombre.split("");
        var iniNombre = nombreSeparado[0];
        var apellidoSeparado = apellido.split("");
        var iniApellido = apellidoSeparado[0];
        return iniNombre?.toUpperCase() + iniApellido?.toUpperCase()
    }

    public cargarOperariosSeleccionados(todosOperarios: any, idMaquina: number, dia: number, turnoDia: number) {
        var resultado = [];
        var idOperarios = [];

        if (todosOperarios != undefined) {
            todosOperarios.forEach(idOperario => {
                if (idOperario.idMaquina == idMaquina && idOperario.dia == dia && idOperario.turnoDia == turnoDia) {
                    idOperarios.push(idOperario)
                }
            });
        }

        this.listaNombreValorOperario.forEach(valorOperario => {
            idOperarios.forEach(idOperario => {
                if (valorOperario.id == idOperario.idOperario) {
                    var data = {
                        id: valorOperario.id,
                        nombre: valorOperario.nombre,
                        color: valorOperario.color,
                        iniciales: valorOperario.iniciales,
                        idDB: idOperario.id,
                        nombreCompleto: valorOperario.nombreCompleto
                    }
                    resultado.push(data);
                }
            });
        });
        return resultado;
    }

    //getWeekNumber
    public getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        //fecha completa del dia seleccionado
        //this.fechaCompleta = d.getDate().toString() + "/" + (d.getMonth() + 1).toString() + "/" + d.getFullYear().toString()
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        this.weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        //return [d.getUTCFullYear(), weekNo];
        return this.weekNo;
    }

    public copiarDias() {
        // obtener operarios del turno del lunes
        //this.tablaMaquinas = [];
        var tablaMaquinasAux = [];
        var operariosTurno = [];
        var operariosTurnoAux = [];
        var listaT = [];
        var listaD = [];
        this.tablaMaquinas.forEach(maquina => {
            listaD = [];

            operariosTurno = maquina.dias.filter(f => {
                if (f.idDia == this.turnoACopiarSeleccionado.id)
                    return true;
            });
            operariosTurno = operariosTurno[0].turnosDia;

            maquina.dias.forEach(dia => {
                listaT = [];
                if (dia.idDia == this.turnoACopiarSeleccionado.id) {
                    operariosTurnoAux = operariosTurno;
                } else {
                    // insertar usuarios en los dias seleccionados
                    if (this.lunesSelected && dia.idDia == 1) {
                        operariosTurnoAux = operariosTurno;
                    }
                    else if (this.martesSelected && dia.idDia == 2) {
                        operariosTurnoAux = operariosTurno;
                    }
                    else if (this.miercolesSelected && dia.idDia == 3) {
                        operariosTurnoAux = operariosTurno;
                    }
                    else if (this.juevesSelected && dia.idDia == 4) {
                        operariosTurnoAux = operariosTurno;
                    }
                    else if (this.viernesSelected && dia.idDia == 5) {
                        operariosTurnoAux = operariosTurno;
                    }
                    else if (this.sabadoSelected && dia.idDia == 6) {
                        operariosTurnoAux = operariosTurno;
                    }
                    else if (this.domingoSelected && dia.idDia == 7) {
                        operariosTurnoAux = operariosTurno;
                    } else {
                        //si no esta elegido para copiar mantener valores actuales
                        operariosTurnoAux = dia.turnosDia;
                    }
                }
                //listaT.push(operariosTurnoAux);
                var listaDias = {
                    idDia: dia.idDia,
                    nombreDia: dia.nombreDia,
                    turnosDia: this.myFunctions.copy(operariosTurnoAux)
                };
                listaD.push(listaDias);
            });
            var mqn = {
                idMaquina: maquina.idMaquina,
                nombreMaquina: maquina.nombreMaquina,
                dias: listaD
            };
            tablaMaquinasAux.push(mqn);
        });

        this.tablaMaquinas = tablaMaquinasAux;

        this.modalReference.close();
    }

    // public valueChangeDia(valueList: any): void {
    //     this.diasVaciarSemanaSeleccionados = [];
    //     valueList.forEach(value => {
    //         this.diasVaciarSemanaSeleccionados.push(value.idDia);
    //     });

    // }
    // public valueChangeTurno(valueList: any): void {
    //     this.turnosVaciarSeleccionados = [];
    //     valueList.forEach(value => {
    //         this.turnosVaciarSeleccionados.push(value.idTurno);
    //     });
    // }
    // public valueChangeMaquina(valueList: any): void {
    //     this.maquinasVaciarSeleccionadas = [];
    //     valueList.forEach(value => {
    //         this.maquinasVaciarSeleccionadas.push(value.idMaquina);
    //     });

    // }

    public vaciarDia(event: any) {
        if (this.diasVaciarSemanaSeleccionados.length > 0 && this.turnosVaciarSeleccionados.length > 0 && this.maquinasVaciarSeleccionadas.length > 0) {
            console.log("Dias a vaciar => " + this.diasVaciarSemanaSeleccionados);
            console.log("Turnos a vaciar => " + this.turnosVaciarSeleccionados);
            console.log("Maquinas a vaciar => " + this.maquinasVaciarSeleccionadas);
            this.tablaMaquinas.forEach((maquina, i) => {
                maquina.dias.forEach((dia, j) => {
                    dia.turnosDia.forEach((turno, k) => {
                        // Buscar entre las opciones elegidas para vaciar los operarios
                        this.maquinasVaciarSeleccionadas.forEach(maquinaVaciar => {
                            this.diasVaciarSemanaSeleccionados.forEach(diaVaciar => {
                                this.turnosVaciarSeleccionados.forEach(turnoVaciar => {
                                    //si coincide vaciar
                                    if (maquina.idMaquina == maquinaVaciar && dia.idDia == diaVaciar && turno.idTurno == turnoVaciar) {
                                        this.tablaMaquinas[i].dias[j].turnosDia[k].operarios = [];
                                    }
                                });
                            });
                        });
                    });
                });
            });
            this.modalReference.close();
        } else {
            this.alertService.error(this.translateService.instant('valoresObligatorios'));
        }

    }

    public compararInicialConFinal() {
        this.listaGuardar = [];
        this.listaEliminar = "";
        var operarioEsta = false;

        console.log(this.tablaMaquinasOriginal);

        this.tablaMaquinas.forEach(
            (maquina, i) => {
                maquina.dias.forEach(
                    (dia, j) => {
                        dia.turnosDia.forEach(
                            (turnoDia, k) => {
                                //sacar lista de operarios a insertar
                                turnoDia.operarios.forEach(
                                    operario => {
                                        operarioEsta = false;
                                        this.tablaMaquinasOriginal[i].dias[j].turnosDia[k].operarios.forEach(operarioOriginal => {
                                            if (operario.id == operarioOriginal.id && operarioEsta == false) {
                                                operarioEsta = true;
                                                //console.log("Berdina");
                                            }
                                        });
                                        if (!operarioEsta) {
                                            //console.log("Ez dago sartu");
                                            var data = {
                                                idCalendarioSemanas: this.idCalendario,
                                                idMaquina: parseInt(maquina.idMaquina.toString()),
                                                dia: parseInt(dia.idDia.toString()),
                                                turnoDia: parseInt(turnoDia.idTurno.toString()),
                                                idOperario: parseInt(operario.id.toString())
                                            };
                                            this.listaGuardar.push(data);
                                        }
                                    });
                                //sacar lista de operarios a eliminar
                                this.tablaMaquinasOriginal[i].dias[j].turnosDia[k].operarios.forEach(operarioOriginal => {
                                    operarioEsta = false;
                                    turnoDia.operarios.forEach(operario => {
                                        if (operario.id == operarioOriginal.id && operarioEsta == false) {
                                            operarioEsta = true;
                                            //console.log("Berdina");
                                        }
                                    });
                                    if (!operarioEsta) {
                                        //console.log("Badago ezabatu");
                                        if (this.listaEliminar == "") {
                                            this.listaEliminar = this.listaEliminar + operarioOriginal.idDB;
                                        } else {
                                            this.listaEliminar = this.listaEliminar + "," + operarioOriginal.idDB;
                                        }
                                    }
                                });
                            });
                    });
            });
    }

    public duplicarSemana() {
        this.listaGuardar = []
        var siguienteSemana = this.turnoSemanaNum + 1;
        // Traer la ultima semana de los turnos creados
        this.selectedTurnosPosteriores
        //
        this.tablaMaquinas.forEach(maquina => {
            maquina.dias.forEach(dia => {
                dia.turnosDia.forEach(turnoDia => {
                    turnoDia.operarios.forEach(operario => {
                        // for (let index = 0; index < this.numDuplicarSemana; index++) {
                        //     var data = {
                        //         idCalendarioSemanas: siguienteSemana + index,
                        //         idMaquina: parseInt(maquina.idMaquina.toString()),
                        //         dia: parseInt(dia.idDia.toString()),
                        //         turnoDia: parseInt(turnoDia.idTurno.toString()),
                        //         idOperario: parseInt(operario.id.toString())
                        //     };
                        //     this.listaGuardar.push(data);
                        // }
                        this.selectedTurnosPosteriores.forEach(turnoPosterior => {
                            var data = {
                                idCalendarioSemanas: turnoPosterior.id,
                                idMaquina: parseInt(maquina.idMaquina.toString()),
                                dia: parseInt(dia.idDia.toString()),
                                turnoDia: parseInt(turnoDia.idTurno.toString()),
                                idOperario: parseInt(operario.id.toString())
                            };
                            this.listaGuardar.push(data);
                        });
                    });
                });
            });
        });
        console.log(this.listaGuardar);
        this.turnosService.Guardar_turno_operario_Bulk(this.listaGuardar).subscribe((result) => {
            console.log("Duplikatuak gordeta");
        });
        this.modalReference.close();
    }

    public imprimir(e: any) {
        var listaOperarios = "";
        var listaTurnos = [];
        var listaMaquinas = [];
        this.listaMaquinasImprimir = [];
        //Creamos un json con los datos necesarios para imprimir

        // Creamos la primera fila con los datos del dia del turno
        this.fechaCompleta = this.translateService.instant('turno').toUpperCase() + " " + this.diasSemana[this.diaImprimir.idDia - 1].nombreDia.toUpperCase()
            + " " + this.translateService.instant('semana').toUpperCase() + " " + this.weekNo.toString();
        listaMaquinas.push({
            maquina: this.fechaCompleta,
            mannana: "",
            tarde: "",
            noche: ""
        })

        //
        this.tablaMaquinas.forEach(maquina => {
            listaTurnos = [];
            maquina.dias[this.diaImprimir.idDia - 1].turnosDia.forEach(turnoDia => {
                listaOperarios = "0";
                turnoDia.operarios.forEach(operario => {
                    var data = {
                        nombre: operario.nombreCompleto
                    }
                    listaOperarios = listaOperarios + "," + operario.nombreCompleto;
                });
                var data = {
                    operarios: listaOperarios
                }
                listaTurnos.push(data);
            });
            var data = {
                maquina: maquina.nombreMaquina,
                mannana: listaTurnos[0].operarios,
                tarde: listaTurnos[1].operarios,
                noche: listaTurnos[2].operarios
            }
            listaMaquinas.push(data);

        });
        this.listaMaquinasImprimir = listaMaquinas;
        //console.log("listaMaquinasImprimir", this.listaMaquinasImprimir)
        //console.log(this.tablaImprimir(this.listaMaquinasImprimir));

        this.turnosService.crear_horario_turnos(listaMaquinas).subscribe((result) => {
            var pdf: any = result;
            if (pdf.error == true) {
                console.log("ERROR" + pdf.descripcion);
            } else {
                var data = this.base64ToBlob(pdf.impreso); // Conversión de base 64 a blob
                var blob = new Blob([data], { type: 'application/pdf' });
                var downloadURL = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = downloadURL;
                link.download = this.translateService.instant('turnos');
                link.click();
                link.remove();
                this.loading = false;
            }


            this.modalReference.close();
        });



    }

    base64ToBlob(base64str) {
        var binary = atob(base64str.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        return view;
    }

    public onChangeDuplicar(value: string): void {
        this.numDuplicarSemana = parseInt(value);
    }

    public cambiarAreaSeccion() {
        //var selection: any = this.selectedAreaSeccion;
        this.listaMaquinasSeccion = [];
        if (this.selectedAreaSeccion.length == 0) {
            console.log("ERROR seccion vacio")
        } else {
            this.listaMaquinas.forEach(listaMaquinas => {
                this.selectedAreaSeccion.forEach(selectedArea => {
                    if (listaMaquinas.idSeccion == selectedArea.idSeccion) {
                        this.listaMaquinasSeccion.push(listaMaquinas);
                    }
                });

            });
            this.cargarTablaMaquinas(this.listaMaquinasSeccion, this.turnoSemanaNum);
        }
    }

    public open() {
        var div = document.getElementsByClassName("k-animation-container")[0];
        div.className += " turnoOperarios-multiselect";
        // div["style"]["width"] = 'auto!important';
    }

    public selectionChange(event, operario) {
        setTimeout(() => {
            if (event.length > 1) {
                event.shift();
            }
            event = this.myFunctions.copy(event);
            operario = event;
        }, 0.5);
    }


}