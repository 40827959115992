import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, AlarmasTipoService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-alarmastipo',
  templateUrl: './alarmasTipo.component.html'
})
export class AlarmasTipoComponent implements OnInit {

  form: FormGroup;
  dataAlarmasTipo: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  user = this.userService.userValue;

  modalReference: NgbModalRef;

  constructor(private alarmasTipoService: AlarmasTipoService,
    private alertService: AlertService,
    private menuService: MenuService,
    private userService: UsuariosService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('alarmastipo').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/alarmastipo') {
          this.cargarDatos();
        }
      }
    });

  }

  cargarDatos() {
    this.alarmasTipoService.getAll().pipe(first()).subscribe((result) => {
      this.dataAlarmasTipo = result;
    });

  }

  ngOnInit(): void {

  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['alarmastipo/editar/', this.mySelection[0]]);
    }
  }

  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['alarmastipo/editar/', this.mySelection[0]]);
    }
  }

  onClickNuevo() {
    this.router.navigate(['alarmastipo/crear']);
  }

  onClickEliminar(content) {
    if (this.mySelection[0] > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  eliminarRegistro() {

    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        this.form = this.formBuilder.group({
          id: element
        });
        this.alarmasTipoService.delete(this.form.value.id).pipe(first()).subscribe({
          next: () => {
            this.dataAlarmasTipo = this.dataAlarmasTipo.filter(x => x.id !== element);
            this.isDeleting = false;
            this.alertService.success(this.translateService.instant('eliminadocorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['../alarmastipo'], { relativeTo: this.route });
          },
          error: error => {
            this.isDeleting = false;
            this.alertService.error(error);
          }
        });
      }
    });

    this.modalReference.close();
    this.mySelection = [];

  }

}
