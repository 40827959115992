import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
const baseUrl = `${environment.apiUrl}/eskola_alumnos`;

@Injectable()
export class Eskola_AlumnosService {

  constructor(private http: HttpClient) { }


  getAll_alumnos(){
    return this.http.get<any[]>(`${baseUrl}`);
    
  }

  getAll_alumnos_filtrado(filtroTutores, filtroGrupos, filtroCompleto){
    return this.http.post<any>(`${baseUrl}/getAll_alumnos_filtrado`, {filtroTutores, filtroGrupos,  filtroCompleto }, { withCredentials: true });
  }

  deleteAlumno(id_usuario: number, id_grupo:number){
    return this.http.post<JSON>(`${baseUrl}/delete_alumno`, { id_usuario, id_grupo }, { withCredentials: true });
  }

  insertAlumno(Id_usuario: number, Id_grupo:number){
    return this.http.post<JSON>(`${baseUrl}/insertAlumno`, { Id_usuario, Id_grupo}, { withCredentials: true });
  }

  getGruposDeAlumno(Id_usuario: number){
    return this.http.post<JSON>(`${baseUrl}/getGruposDeAlumno`, { Id_usuario }, { withCredentials: true });

  }

  importarAlumno(data) {
    return this.http.post<any>(baseUrl + "/importarAlumno", data , { withCredentials: true });
}



}