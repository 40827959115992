import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { query } from '@angular/animations';

import { environment } from '@environments/environment';
import { formatDate } from '@angular/common';

const baseUrl = `${environment.apiUrl}/series`;
@Injectable()
export class SeriesService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }


  importar(data) {

    return this.http.post<any>(baseUrl + "/importar", data, { withCredentials: true });
  }

  public delete(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("id", data.ids);
    return this.http.post<JSON>(baseUrl + "/eliminarSeries", formData, { withCredentials: true });
  }

  public insert(idParte, idRuta) {
    var formData: any = new FormData();
    formData.append("idParte", idParte);
    formData.append("idRuta", idRuta);
    return this.http.post<JSON>(baseUrl + "/insertarSerie", formData, { withCredentials: true });
  }

  public insertConDatos(idParte, idRuta,colada, lote, numeroserie, numeroseriecliente, numero, patronNumeroSerie, patronNumeroSerieCliente) {
    var formData: any = new FormData();
    formData.append("idParte", idParte);
    formData.append("idRuta", idRuta);
    formData.append("colada", colada);
    formData.append("lote", lote);
    formData.append("numeroserie", numeroserie);
    formData.append("numeroseriecliente", numeroseriecliente);
    formData.append("numero", numero);
    formData.append("patronNumeroSerie", patronNumeroSerie);
    formData.append("patronNumeroSerieCliente", patronNumeroSerieCliente);
    return this.http.post<JSON>(baseUrl + "/insertarSerieConDatos", formData, { withCredentials: true });
  }

  public getById(id): Observable<any> {
    var formData: any = new FormData();
    formData.append("id", id);
    return this.http.post<JSON>(baseUrl + "/getById", formData, {withCredentials: true});
  }

  public update_series(seriesPieza){
    return this.http.post<JSON>(`${baseUrl}/update_series/`, { dt: seriesPieza }, { withCredentials: true });
  }

  public conseguirNumeroConPatron(patronNumeroSerie, idParte): Observable<number>{
    var formData: any = new FormData();
    formData.append("patronNumeroSerie", patronNumeroSerie);
    formData.append("idParte", idParte);
    return this.http.post<number>(baseUrl + "/conseguirNumeroConPatron", formData, { withCredentials: true })
  }



}
