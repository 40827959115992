import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, GestionDocumentalService, MenuService, UsuariosService, ConfiguracionService, SubidaFicherosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { FileInfo, FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-piezas-documento',
  templateUrl: './piezas-documento.component.html'
})
export class PiezasDocumentoComponent implements OnInit {

  public idOf: number;
  public idPieza: number;
  public idParte: number;
  public idRuta: number;
  public idDocumento: number;
  public idVista: number;
  public user: any;
  
  public uploadSaveUrl;
  public uploadRemoveUrl;
  public fichero: String = "";
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.png', '.pdf'],
    maxFileSize: 20048576
  };
  
  public closeResult = '';
  public form: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public myFiles: FileInfo[];
  public errorLink= false;
  public errorFichero = false;
  public ficheroViejo: String = '';
  public antesFichero: boolean;

  constructor( private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private menuService: MenuService,
    private userService: UsuariosService,
    private alertService: AlertService,
    private gestionDocumentalService: GestionDocumentalService,
    private configuracionService: ConfiguracionService,
    private subidaFicherosService: SubidaFicherosService)
  {
    this.user = this.userService.userValue;
    this.menuService.titulo = this.translateService.instant('documento').toUpperCase();
  }

  ngOnInit(): void {

    //Estos son solo necesarios para poder volver a las vistas
    this.idOf = Number(this.activatedRoute.snapshot.params['idOf']);
    this.idPieza = Number(this.activatedRoute.snapshot.params['idPieza']);
    this.idParte = Number(this.activatedRoute.snapshot.params['idParte']);
    this.idVista = Number(this.activatedRoute.snapshot.params['idVista']);
    
    //Para subir el documento y editarlo
    this.idRuta = Number(this.activatedRoute.snapshot.params['idRuta']);
    this.idDocumento = Number(this.activatedRoute.snapshot.params['idDocumento']);


    this.uploadSaveUrl = `${environment.apiUrl}/gestionDocumental/documento/upload/` + this.idRuta; // should represent an actual API endpoint
    this.uploadRemoveUrl = `${environment.apiUrl}/gestionDocumental/documento/remove/` + this.idRuta; // should represent an actual API endpoint

    //Para evitar errores
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', [Validators.required]],
      isLink: [false,],
      esDirectorio: [false,],
      fichero: ['',],
      link: ['',]
    });

    if(this.idDocumento != -1){
      this.gestionDocumentalService.getDocumentoById(this.idDocumento).subscribe((response: any)=>{
        if(response[0].esLink){
          this.antesFichero = false;
        }else{
          this.antesFichero = true;
        }
        if(response[0].esLink)
          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            nombre: [response[0].nombre, [Validators.required]],
            isLink: [response[0].esLink,],
            esDirectorio: [response[0].isDir,],
            fichero: ['',],
            link: [response[0].fichero,]
          });
        else
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [response[0].nombre, [Validators.required]],
          isLink: [response[0].esLink,],
          esDirectorio: [response[0].isDir,],
          fichero: [response[0].fichero,],
          link: [,]
        });
        this.ficheroViejo = this.form.value.fichero;
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public atras() {
    if(this.idVista == 0){ //Volver a rutas
      this.router.navigate(['rutas/editar/' + this.idOf + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta]);
    }else if(this.idVista == 1){//volver a piezas
      this.router.navigate(['piezas/editar/' + this.idOf + "/" + this.idPieza + "/" + this.idParte]);
    }
  }

  public onSelect(ev): void {
    this.fichero = ev.files[0].name;
    this.form.controls['fichero'].setValue(this.fichero);
    if(!this.form.value.isLink && this.fichero != ''){
      this.errorFichero = false;
    }
  }
  guardarDocumento2(){
    console.log("En guardar documento");
    debugger
    this.configuracionService.get_configuracion_gestion_documental().subscribe((res)=>{
      console.log("Configuracion de gestion documental: "+res);
      
      if(res != null){
        switch(res[0].idTipo){
        //FTP
          case 2:{
            var carpeta = 'gestion_documental';//this.carpetas.filter(f=> f.id == this.idCarpeta);
            var file = this.form.value.archivo;
            this.subidaFicherosService.uploadFileFTP(file,carpeta).subscribe(r => {
              if(r != null){
                var msg:any = r;
                this.form.controls['fichero'].setValue(msg.message);
                this.guardarDocumento2();
                // this.filename = msg.message;
                // this.nuevo();
              }
              console.log("Erantzuna FTP");
              console.log(r);
            })
            break;
          }
        //Azure externo
          case 3:{
            var carpeta = 'gestion_documental';//this.carpetas.filter(f=> f.id == this.idCarpeta);
            var file = this.form.value.archivo;
            this.subidaFicherosService.uploadFileAzureExterno(file[0],carpeta).subscribe(r => {
              if(r != null){
                var msg:any = r;
                this.form.controls['fichero'].setValue(msg.message);
                this.guardarDocumento2();
                //this.form.controls['archivoBase64'].setValue(msg.message);
                // this.filename = msg.message;
                // this.nuevo();
              }
              console.log("Erantzuna AZURE");
              console.log(r);
            })
            break;
          }
        //Google cloud
          case 5:{
            debugger
            var carpeta = 'gestion_documental';//this.carpetas.filter(f=> f.id == this.idCarpeta);
            var file = this.form.value.archivo.rawFile;
            //var fichero = this.ficheroOriginal;
            debugger
            this.subidaFicherosService.uploadFileAGoogleCloud(carpeta,file).subscribe(r => {
              if(r != null){
                var msg:any = r;
                this.form.controls['fichero'].setValue(msg.message);
                this.guardarDocumento2();
                //this.form.controls['archivoBase64'].setValue(msg.message);
                // this.filename = msg.message;
                // this.nuevo();
              }
              console.log("Erantzuna GOOGLE CLOUD");
              console.log(r);
            })
            break;
          }
        //Azure interno
          case 6:{
            console.log("En azure eris");
            debugger
            var carpeta = 'gestion_documental';//this.carpetas.filter(f=> f.id == this.idCarpeta);
            var file = this.form.value.archivo.rawFile;
            debugger
            this.subidaFicherosService.uploadFile(file.name,file,carpeta).subscribe(r => {
              if(r != null){
                var msg:any = r;
                this.form.controls['fichero'].setValue(msg.message);
                this.guardarDocumento2();
                //this.form.controls['archivoBase64'].setValue(msg.message);
                // this.filename = msg.message;
                // this.nuevo();
              }
              console.log("Erantzuna AZURE");
              console.log(r);
            })
            break;
          }
        
          
        }
      } 
    })
  }
  guardarDocumento(){
    this.submitted = true;
    while (this.form.value.fichero.includes('\\')){
      this.form.value.fichero = this.form.value.fichero.toString().replace('\\', '/')
    }
    while (this.form.value.link.includes('\\')){
      this.form.value.link = this.form.value.link.toString().replace('\\', '/')
    }
    if (this.form.value.isLink && (this.form.value.link == '' || this.form.value.link == null)) {
      this.errorLink = true;
    } else if (this.form.value.isLink && (this.form.value.link != '' && this.form.value.link != null)) {
      this.errorLink = false;
    }
    if (!this.form.value.isLink && (this.form.value.fichero == '' || this.form.value.fichero == null)) {
      this.errorFichero = true;
    } else if (!this.form.value.isLink && (this.form.value.fichero != '' && this.form.value.fichero != null)) {
      this.errorFichero = false;
    }
    //if(!this.form.value.isLink && (Object.keys(upload.fileList._files).length === 0 && (this.form.value.fichero == '' || this.form.value.fichero == null))){
    //  this.errorFichero = true;
    //}
    if (this.form.invalid || this.errorLink || this.errorFichero) {
      return;
    }
    if(this.idDocumento == -1){
      if(this.form.value.isLink){
        this.gestionDocumentalService.crearDocumento(this.idRuta, this.form.value.nombre, this.form.value.link, this.form.value.isLink,
          this.form.value.esDirectorio).subscribe((response: any)=>{
          if (!response.error) {
            //var aux: any = document.getElementsByClassName('k-upload-selected')[0];
            //aux.click();
            //upload.uploadFiles();
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.atras();
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }else{
        this.gestionDocumentalService.crearDocumento(this.idRuta, this.form.value.nombre, this.form.value.fichero, this.form.value.isLink,
          this.form.value.esDirectorio).subscribe((response: any)=>{
          if (!response.error) {
            //var aux: any = document.getElementsByClassName('k-upload-selected')[0];
            //aux.click();
            //upload.uploadFiles();
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.atras();
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }
    }else{
      if(this.form.value.isLink){
        this.gestionDocumentalService.editarDocumento(this.antesFichero, this.ficheroViejo, this.idDocumento, this.idRuta, this.form.value.nombre, this.form.value.link, this.form.value.isLink,
          this.form.value.esDirectorio).subscribe((response: any)=>{
          if (!response.error) {
            //var aux: any = document.getElementsByClassName('k-upload-selected')[0];
            //if(aux !== undefined)
            //  aux.click();
            //upload.uploadFiles();
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.atras();
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }else{
        this.gestionDocumentalService.editarDocumento(this.antesFichero, this.ficheroViejo, this.idDocumento, this.idRuta, this.form.value.nombre, this.form.value.fichero, this.form.value.isLink,
          this.form.value.esDirectorio).subscribe((response: any)=>{
          if (!response.error) {
            //var aux: any = document.getElementsByClassName('k-upload-selected')[0];
            //if(aux !== undefined)
            //  aux.click();
            //upload.uploadFiles();
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.atras();
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }
    }
  }

  errorEventHandler(e: ErrorEvent) {
    this.alertService.error(this.translateService.instant('error'));
  }

}
