<div class="row">

    <div class="col-md-6 col-lg-6">
        <div class="card">
            <div class="card-body">
                <h3 class="pt-3">{{ 'datos' | translate}}</h3>
                <div class="form-row">
                    <div class="form-group col-5">
                        <kendo-label text="{{ 'nombre' | translate}}">
                            <kendo-textbox [(ngModel)]="alumno.Nombre" maxlength="150"
                                [ngClass]="{ 'is-invalid': errorNombreVacio }">
                            </kendo-textbox>
                            <div *ngIf="errorNombreVacio" class="invalid-feedback">
                                <div *ngIf="errorNombreVacio">{{ 'nombrerequerido' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col-5">
                        <kendo-label text="{{ 'nombreUsuario' | translate}}">
                            <kendo-textbox [(ngModel)]="alumno.NombreUsuario" maxlength="150"
                                [ngClass]="{ 'is-invalid': errorNombreUsuarioVacio }">
                            </kendo-textbox>
                            <div *ngIf="errorNombreUsuarioVacio" class="invalid-feedback">
                                <div *ngIf="errorNombreUsuarioVacio">{{ 'nombreUsuariorequerido' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-5">
                        <kendo-label text="{{ 'apellido1' | translate}}">
                            <kendo-textbox [(ngModel)]="alumno.Apellido1" maxlength="150"
                                [ngClass]="{ 'is-invalid': errorApellido1Vacio }"></kendo-textbox>
                            <div *ngIf="errorApellido1Vacio" class="invalid-feedback">
                                <div *ngIf="errorApellido1Vacio">{{ 'apellidorequerido' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col-5">
                        <kendo-label text="{{ 'apellido2' | translate}}">
                            <kendo-textbox [(ngModel)]="alumno.Apellido2" maxlength="150"
                                [ngClass]="{ 'is-invalid': errorApellido2Vacio }"></kendo-textbox>
                                <div *ngIf="errorApellido2Vacio" class="invalid-feedback">
                                    <div *ngIf="errorApellido2Vacio">{{ 'apellidorequerido' | translate}}</div>
                                </div>
                        </kendo-label>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-5">
                        <kendo-label text="{{ 'email' | translate}}">
                            <kendo-textbox [(ngModel)]="alumno.Email" maxlength="150"  [ngClass]="{ 'is-invalid': errorEmailVacio || errorEmailNoValido }">
                            </kendo-textbox>
                            <div *ngIf="errorEmailVacio || errorEmailNoValido" class="invalid-feedback">
                                <div *ngIf="errorEmailVacio">{{ 'emailrequerido' | translate}}</div>
                                <div *ngIf="errorEmailNoValido">{{ 'emailincorrecto' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col-5">
                        <kendo-label text="{{ 'telefono' | translate}}">
                            <kendo-textbox [(ngModel)]="alumno.telefono" maxlength="9"  [ngClass]="{ 'is-invalid': errorTelefonoVacio || errorTelefonoNoValido}">
                            </kendo-textbox>
                            <div *ngIf="errorTelefonoVacio || errorTelefonoNoValido" class="invalid-feedback">
                                <div *ngIf="errorTelefonoVacio">{{ 'telefonoEsrequerido' | translate}}</div>
                                <div *ngIf="errorTelefonoNoValido">{{ 'telefonoEsValido' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                </div>
                <div class="form-group">
                    <kendo-label text="{{'observaciones' | translate}}"></kendo-label>
                        <div class="caja">
                            <kendo-textarea [(ngModel)]="alumno.observaciones" >

                            </kendo-textarea>
                        </div>
                </div>

                <div class="form-row">
                    <div class="form-group form-check">
                        <kendo-label for="activo" class="form-check-label" text="{{ 'activo' | translate}}">
                        </kendo-label>
                        <div class="caja">
                            <kendo-switch id="activo" [(ngModel)]="alumno.Activo" [onLabel]="' '" [offLabel]="' '"
                                [ngClass]="{ 'is-invalid': false}"> </kendo-switch>
                        </div>
                    </div>
                </div>




            </div>
        </div>
      
        <div class="card">
            <div class="card-body">
                <div *ngIf="isAddMode">
                    <h3 class="pt-3">{{ 'contraseñaAutomatica' | translate}}</h3>
                    <p>*{{ 'contraseñaNombreUsuario' | translate}}</p>
                </div>
                <div *ngIf="!isAddMode">
                    <h3 class="pt-3">{{ 'cambiarcontrasena' | translate}}</h3>
                    <p>*{{ 'dejelovacio' | translate}}</p>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <kendo-label text="{{ 'contrasena' | translate}}">
                            <input kendoTextBox type="password" [disabled]="isAddMode" [(ngModel)]="alumno.Password" class="form-control"
                                [ngClass]="{ 'is-invalid': errorContrasennaVacia || errorContrasenna6caracteres }" />
                            <div *ngIf="errorContrasennaVacia || errorContrasenna6caracteres" class="invalid-feedback">
                                <div *ngIf="errorContrasennaVacia">{{ 'contrasenarequerida' | translate}}</div>
                                <div *ngIf="errorContrasenna6caracteres">{{ 'contrasena6caracteres' | translate}}</div>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="form-group col">
                        <kendo-label text="{{ 'confirmarcontrasena' | translate}}">
                            <input kendoTextBox type="password" [disabled]="isAddMode" [(ngModel)]="alumno.ConfirmPassword" class="form-control"
                                [ngClass]="{ 'is-invalid': errorConfirmarContrasenaVacia || errorContrasennasNoCoinciden}" />
                            <div *ngIf="errorConfirmarContrasenaVacia || errorContrasennasNoCoinciden " class="invalid-feedback">
                                <div *ngIf="errorConfirmarContrasenaVacia">
                                    {{ 'contrasenaconfirmacionrequerida' | translate}}
                                </div>
                                <div *ngIf="errorContrasennasNoCoinciden">
                                    {{ 'contrasenasnocoinciden' | translate}}
                                </div>
                            </div>
                        </kendo-label>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <button kendoButton [disabled]="loading  || this.user.gestionUsuarios < 2"
                    class="btn mr-1  btn-primary" (click)="guardarClick()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'guardar' | translate}}
                </button>
                <a routerLink="/alumnos" class="btn mr-1  btn-danger" >{{ 'cancelar' | translate}}</a>
            </div>

        </div>

    </div>
    
</div>