import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/centromecanizado`;

@Injectable()
export class CentroMecanizadoService {

  constructor(
    private http: HttpClient
  ) {
  }

  get(ciclo) {
    return this.http.get<any>(`${baseUrl}/${ciclo}`);
  }

  get_Tiempos_Lote_Pieza(){
    return this.http.get<any>(`${baseUrl}/get_Tiempos_Lote_Pieza`);
  }
  
  get_Tiempos_Lote_Pieza_byId(idMaquina){
    return this.http.get<any>(`${baseUrl}/get_Tiempos_Lote_Pieza_byId/${idMaquina}`);
  }
  
  get_solo_OEE_hornos(idsHornos) {
    return this.http.get<any>(`${baseUrl}/get_solo_OEE_hornos/${idsHornos}`);
  }

  get_solo_OEE() {
    return this.http.get<any>(`${baseUrl}/get_solo_OEE`);
  }

  getIsometrico(ciclo) {
    return this.http.get<any>(`${baseUrl}/getIsometrico/${ciclo}`);
  }

  getById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetById/${idMaquina}`);
  }

  getInstalacionById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetInstalacionById/${idMaquina}`);
  }

  GetHmiExtrusoraById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetHmiExtrusoraById/${idMaquina}`);
  }

  GetHmiImpresoraPlasticoById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetHmiImpresoraPlasticoById/${idMaquina}`);
  }

  GetHmiInyectoraById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetHmiInyectoraById/${idMaquina}`);
  }

  GetDatosCicloInyectoraById(idMaquina, idCiclo, tipoMaquinaInyeccion) {
    return this.http.get<any>(`${baseUrl}/GetDatosCicloInyectoraById/${idMaquina}/${idCiclo}/${tipoMaquinaInyeccion}`);
  }

  GetHmiHornoById(idMaquina: string, tipoMaquinaInduccion) {
    return this.http.get<any>(`${baseUrl}/GetHmiHornoById/${idMaquina}/${tipoMaquinaInduccion}`);
  }

  GetHmiHornoTempleById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetHmiHornoTempleById/${idMaquina}`);
  }

  GetHmiHornoTempleByIdAndDate(idMaquina: string, fechaInicio: Date, fechaFin: Date) {
    return this.http.get<any>(`${baseUrl}/GetHmiHornoTempleByIdAndDate/${idMaquina}/${fechaInicio}/${fechaFin}`);
  }

  GetDatosRecetaHornoById(idMaquina, idReceta, listaIdRecetas, tipoMaquinaInduccion) {
    return this.http.get<any>(`${baseUrl}/GetDatosRecetaHornoById/${idMaquina}/${idReceta}/${listaIdRecetas}/${tipoMaquinaInduccion}`);
  }

  GetHmiAditivoById(idMaquina: string) {
    return this.http.get<any>(`${baseUrl}/GetHmiAditivoById/${idMaquina}`);
  }

  getGruposMaquinas() {
    return this.http.post<JSON>(`${baseUrl}/GetGruposMaquinas`, {}, { withCredentials: true });
  }
  
}
