<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <h3 class="pt-3">{{ 'datos' | translate}}</h3>
    <div class="form-row">
        <div class="form-group col-5">
            <kendo-label text="{{ 'usuario' | translate}}">
                <kendo-textbox formControlName="nombreUsuario" maxlength="150"
                    [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                <div *ngIf="submitted && f.nombreUsuario.errors" class="invalid-feedback">
                    <div *ngIf="f.nombreUsuario.errors.required">{{ 'nombreusuariorequerido' | translate}}
                    </div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group col-5">
            <kendo-label text="{{ 'nombre' | translate}}">
                <kendo-textbox formControlName="nombre" maxlength="150" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group col-5">
            <kendo-label text="{{ 'apellido1' | translate}}">
                <kendo-textbox formControlName="apellido1" maxlength="150"
                    [ngClass]="{ 'is-invalid': submitted && f.apellido1.errors }"></kendo-textbox>
                <div *ngIf="submitted && f.apellido1.errors" class="invalid-feedback">
                    <div *ngIf="f.apellido1.errors.required">{{ 'apellidorequerido' | translate}}</div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group col-5">
            <kendo-label text="{{ 'apellido2' | translate}}">
                <kendo-textbox formControlName="apellido2" maxlength="150"
                    [ngClass]="{ 'is-invalid': submitted && f.apellido2.errors }"></kendo-textbox>
            </kendo-label>
        </div>
    </div>
    <div class="form-group">
        <kendo-label text="{{ 'email' | translate}}">
            <kendo-textbox formControlName="email" maxlength="150" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{ 'emailusuariorequerido' | translate}}</div>
            </div>
        </kendo-label>
    </div>
    <h3 class="pt-3">{{ 'cambiarcontrasena' | translate}}</h3>
    <p>*{{ 'dejelovacio' | translate}}</p>
    <div class="form-row">
        <div class="form-group col">
            <kendo-label text="{{ 'contrasena' | translate}}">
                <input kendoTextBox type="password" maxlength="150" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{ 'contrasenarequerida' | translate}}</div>
                    <div *ngIf="f.password.errors.minlength">{{ 'contrasena6caracteres' | translate}}</div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group col">
            <kendo-label text="{{ 'confirmarcontrasena' | translate}}">
                <input kendoTextBox type="password" maxlength="150" formControlName="confirmPassword" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">
                        {{ 'contrasenaconfirmacionrequerida' | translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">{{ 'contrasenasnocoinciden' | translate}}
                    </div>
                </div>
            </kendo-label>
        </div>
    </div>
    <div class="form-group">
        <button kendoButton type="submit" [disabled]="loading || this.user.profiles<2" class="btn mr-1  btn-primary mr-2" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'actualizar' | translate}}
        </button>
        <button kendoButton type="button" (click)="onDelete()" [disabled]="deleting" class="btn mr-1  btn-danger">
            <span *ngIf="deleting" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'eliminar' | translate}}
        </button>
        <a routerLink="../" href="" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
    </div>
</form>
