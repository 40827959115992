<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <!--LINEA 1-->
  <div class="row">
    <!--COLUMNA 1-->
    <div class="col">
      <div class="row">
        <!--DATOS-->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'datos' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <!--Maquina y abreviatura-->
              <div class="row">
                <div class="form-group col-md-9">
                  <kendo-label text="{{ 'maquina' | translate }}">
                    <kendo-textbox formControlName="nombre"></kendo-textbox>
                  </kendo-label>
                </div>
                <div class="form-group col-md-3">
                  <kendo-label text="{{ 'abreviatura' | translate }}">
                    <kendo-textbox formControlName="abreviatura" maxlength="3" required
                      [ngClass]="{ 'is-invalid': submitted && f.abreviatura.errors }"></kendo-textbox>
                  </kendo-label>
                </div>
              </div>

              <!--Num maquina, orden, num serie-->
              <div class="row">
                <div class="col-md-4">
                  <!--Numero Maquina-->
                  <div class="form-group">
                    <kendo-label text="{{ 'numMaquina' | translate }}">
                      <kendo-textbox formControlName="numeroModelo"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
                <div class="col-md-4">
                  <!--Orden Maquina-->
                  <div class="form-group">
                    <kendo-label text="{{ 'orden' | translate }}">
                      <kendo-textbox formControlName="ordenModelo"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
                <div class="col-md-4">
                  <!--Numero Serie-->
                  <div class="form-group">
                    <kendo-label text="{{ 'numSerie' | translate }}">
                      <kendo-textbox formControlName="numSerie"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
              </div>

              <!--Marca-->
              <div class="row">
                <div class="col-md-12">
                  <!--Marca-->
                  <div class="form-group">
                    <label class="control-label">{{ 'marca' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist [data]="marcas" [textField]="'nombre'" [valueField]="'id'"
                        (selectionChange)="marcas_selectionChange($event)" [value]="marca_selectedItem"
                        class="form-control">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>
              </div>

              <!--Control y activo-->
              <div class="row">
                <div class="col-md-9">
                  <!--Control-->
                  <div class="form-group">
                    <label class="control-label">{{ 'control' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist [data]="controles" [textField]="'nombre'" [valueField]="'id'"
                        (selectionChange)="controles_selectionChange($event)" [value]="control_selectedItem"
                        class="form-control">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <!--Activo-->
                  <kendo-label text="{{ 'activo' | translate}}">
                    <br />
                    <kendo-switch formControlName="activo" [onLabel]="' '" [offLabel]="' '"
                      id="activoId"></kendo-switch>
                  </kendo-label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--CONEXION-->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'conexion' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body inyectora-conexion-cont">

              <!--IP, Puerto-->
              <div class="row">
                <!--IP-->
                <div class="col-md-9">
                  <div class="form-group">
                    <kendo-label text="{{ 'ip' | translate }}">
                      <kendo-textbox [disabled]="true" formControlName="ip"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>

                <!--Puerto-->
                <div class="col-md-3">
                  <div class="form-group">
                    <kendo-label text="{{ 'puerto' | translate }}">
                      <kendo-textbox [disabled]="true" formControlName="puerto"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
              </div>

              <!--Timezone, t sincr-->
              <div class="row">
                <div class="col-md-9">
                  <!--Timezone-->
                  <div class="form-group">
                    <label class="control-label">{{ 'Timezone' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist [disabled]="false" [(data)]="timeZonesList" [textField]="'timeZoneSQL'"
                        class="form-control" [valueField]="'id'" formControlName="idTimezone"
                        [ngClass]="{ 'invalid-dropdown': submitted && f.idTimezone.errors }">
                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                          <span *ngIf="dataItem!=undefined" style="vertical-align: text-top; margin-left: 5px;">{{
                            dataItem.timeZoneSQL }} ({{ dataItem.desvioUTC }})</span>
                        </ng-template>
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                          <span>
                            <span class="k-icon k-i-clock"></span>
                            <span *ngIf="dataItem!=undefined" style="vertical-align: text-top; margin-left: 5px;">{{
                              dataItem.timeZoneSQL }} ({{ dataItem.desvioUTC }})</span>
                          </span>
                        </ng-template>
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <!--Tiempo sincr. (seg)-->
                  <div class="form-group">
                    <kendo-label text="{{ 'tiempoSincrSeg' | translate }}">
                      <kendo-textbox [disabled]="true" formControlName="tiempoSincrSeg"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
              </div>

              <!--URL camara-->
              <div class="row">
                <div class="col-md-12">
                  <!--URL camara-->
                  <div class="form-group">
                    <kendo-label text="{{ 'urlCamara' | translate }}">
                      <kendo-textbox [disabled]="true" formControlName="urlCamara"></kendo-textbox>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--POTENCIOMETROS-->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'potenciometros' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="row">
                <!--T microparada-->
                <div class="col-md">
                  <!--tiempo microparada-->
                  <kendo-label text="{{ 'tiempoMicroParada' | translate}}">
                    <kendo-numerictextbox class="form-control" formControlName="tiempoMicroParada" [min]="0"
                      [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                  </kendo-label>
                </div>
                <!--T microejecucion-->
                <div class="col-md">
                  <!--tiempo microejecucion-->
                  <kendo-label text="{{ 'tiempoMicroEjecucion' | translate}}">
                    <kendo-numerictextbox class="form-control" formControlName="tiempoMicroEjecucion" [min]="0"
                      [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                  </kendo-label>
                </div>
                <!--Frec. captura de datos-->
                <div class="col-md-3">
                  <div class="form-group">
                    <label>{{ 'frecCapturaDatos' | translate }}</label>
                    <kendo-numerictextbox formControlName="freq_captura_datos" class="form-control" [min]="0"
                      [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--CARACTERISTICAS-->
      <div class="clearfix">

        <div class="card maquinas-conf-caracteristicas-conf">
          <div class="card-header">
            <h3>
              <label ID="lCaracteristicas" CssClass="">{{ 'caracteristicas' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div class="row">
              <!--Seccion-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'seccion' | translate }}">
                    <kendo-dropdownlist [(data)]="groupedSeccion" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="idSeccion"
                      [ngClass]="{ 'invalid-dropdown': submitted && f.idSeccion.errors }"></kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
              <!--Clasificacion-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'clasificacion' | translate}}">
                    <div class="caja">
                      <kendo-multiselecttree kendoMultiSelectTreeExpandable checkboxes="true"
                        [kendoMultiSelectTreeHierarchyBinding]="clasificaciones" [checkableSettings]="checkableSettings"
                        [textField]="'nombre'" [valueField]="'id'" [childrenField]="'clasificacionInferior'"
                        [(value)]="clasificacionesSeleccionadas" placeholder="{{ 'clasificaciones' | translate }}"
                        [isNodeExpanded]="isNodeExpanded" (valueChange)="valueChange($event, 1)" [tagMapper]="tagMapper"
                        style="background: white;">
                      </kendo-multiselecttree>
                    </div>
                  </kendo-label>
                </div>
              </div>

              <!--Atributos-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'atributo' | translate}}">
                    <div class="caja">
                      <kendo-multiselecttree kendoMultiSelectTreeExpandable checkboxes="true"
                        [kendoMultiSelectTreeHierarchyBinding]="atributos" [checkableSettings]="checkableSettings"
                        [textField]="'nombre'" [valueField]="'id'" [childrenField]="'atributoInferior'"
                        [(value)]="atributosSeleccionadas" placeholder="{{ 'atributos' | translate }}"
                        [isNodeExpanded]="isNodeExpanded" (valueChange)="valueChange($event, 2)" [tagMapper]="tagMapper"
                        style="background: white;">
                      </kendo-multiselecttree>
                    </div>
                  </kendo-label>
                </div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'agruparProcesos' | translate}}">
                    <div class="caja">
                      <kendo-switch formControlName="agruparProcesos" [onLabel]="' '" [offLabel]="' '"
                        id="agruparProcesos" (valueChange)="onChangeAgruparProcesos($event)"></kendo-switch>
                    </div>
                  </kendo-label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="agruparProcesosSwitch">

              <div class="col-md">
                <div class="form-group">
                  <div class="float-left">
                    <kendo-label text="Kg min">
                      <kendo-numerictextbox class="form-control" formControlName="capacidadPesoMin" [min]="0"
                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                    </kendo-label>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <div class="float-left">
                    <kendo-label text="Kg max">
                      <kendo-numerictextbox class="form-control" formControlName="capacidadPesoMax" [min]="0"
                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="agruparProcesosSwitch">

              <div class="col-md">
                <div class="form-group">
                  <div class="float-left">
                    <!--Maxima del potenciometro-->
                    <kendo-label text="{{ 'unidadesMin' | translate}}">
                      <kendo-numerictextbox class="form-control" formControlName="capacidadUnidadesMin" [min]="0"
                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                    </kendo-label>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <div class="float-left">
                    <!--Maxima del potenciometro-->
                    <kendo-label text="{{ 'unidadesMax' | translate}}">
                      <kendo-numerictextbox class="form-control" formControlName="capacidadUnidadesMax" [min]="0"
                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!--HOME SELECCION-->
      <div class="clearfix">

        <div class="card maquinas-conf-caracteristicas-conf">
          <div class="card-header">
            <h3>
              <label ID="lCaracteristicas" CssClass="">{{ 'modulos' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div class="row">
              <!--Home modulo izquierda-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'homeModIzq' | translate }}">
                    <kendo-dropdownlist [(data)]="homeSeleccion" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="homeModIzq">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span *ngIf="![7, 11].includes(dataItem.id)">{{ dataItem.agrupado}} {{dataItem.nombre}}</span>
                        <span *ngIf="[7, 11].includes(dataItem.id)">{{ dataItem.agrupado}}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
              <!--Home modulo derecha-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'homeModDer' | translate }}">
                    <kendo-dropdownlist [(data)]="homeSeleccion" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="homeModDer">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span *ngIf="![7, 11].includes(dataItem.id)">{{ dataItem.agrupado}} {{dataItem.nombre}}</span>
                        <span *ngIf="[7, 11].includes(dataItem.id)">{{ dataItem.agrupado}}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
            </div>
            <!--parametros posibles de la home principales-->
            <div class="row" *ngIf="form.value.homeModIzq.id == 7 || form.value.homeModDer.id == 7">
              <!--Parametro 1-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'parametro' | translate }} 1">
                    <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="homeModParametro1">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
              <!--Parametro 2-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'parametro' | translate }} 2">
                    <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="homeModParametro2">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
              <!--Parametro 3-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'parametro' | translate }} 3">
                    <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="homeModParametro3">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
              <!--Parametro 4-->
              <div class="col-md">
                <div class="form-group">
                  <kendo-label text="{{ 'parametro' | translate }} 4">
                    <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                      [valueField]="'id'" formControlName="homeModParametro4">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span>{{ dataItem.nombre | translate }}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                  </kendo-label>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--COLUMNA 2-->
    <div class="col">
      <div class="row">
        <!--IMAGEN-->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'imagen' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="imagen-maquina">
                <!--IMAGEN VIEJA-->
                <div class="clearfix text-center">
                  <img [src]="imageToShow" style="max-height: 113px">
                </div>
                <div class="clearfix">

                  <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false"
                    (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                    <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                      clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                      dropFilesHere="{{ 'dropFilesHere' | translate }}"
                      externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                      fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                      fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                      filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                      filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                      filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                      headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                      headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                      headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                      invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                      invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                      invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                      remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                      retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                      uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>

                </div>
              </div>
            </div>
          </div>
        </div>
        <!--ISOMETRICO-->
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'isometrico' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="imagen-isometrico-maquina" (click)="onClickAbrirPopup()">
                <div class="clearfix text-center"><img style="max-width: 95px; max-height: 95px;"
                    [src]="selectedDibujoMaquina.imagen"></div>
                <div class="clearfix text-center"><label style="font-size: 12px;">{{ 'dimensiones' | translate }}:
                    {{selectedDibujoMaquina.dimensiones}}</label></div>
                <div class="clearfix text-center"><button type="button" class="btn btn-primary">{{
                    'cambiarImagenIsometrica' | translate }}</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--CONFIGURACION HMI-->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lCaracteristicas" CssClass="">{{ 'configuracionHMI' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
              <div class="clearfix configuracion-hmi-mecanizado">

                <!--Inicio-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiInicio"
                    [onLabel]="translateService.instant('inicio')" [offLabel]="translateService.instant('inicio')"
                    id="hmiInicioId"></kendo-switch>
                </div>

                <!--Manual usuario-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiManualUsuario"
                    [onLabel]="translateService.instant('manualUsuario')"
                    [offLabel]="translateService.instant('manualUsuario')" id="hmiManualUsuarioId"></kendo-switch>
                </div>

                <!--Prog planificados-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiProgPlanificados"
                    [onLabel]="translateService.instant('progPlanificados')"
                    [offLabel]="translateService.instant('progPlanificados')" id="hmiProgPlanificadosId"></kendo-switch>
                </div>

                <!--Fuera de taller-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiFueraTaller"
                    [onLabel]="translateService.instant('fueraDeTaller')"
                    [offLabel]="translateService.instant('fueraDeTaller')" id="hmiFueraTallerId"></kendo-switch>
                </div>

                <!--Visualizador de planos-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiVisualizadorDePlanos"
                    [onLabel]="translateService.instant('visializadorDePlanos')"
                    [offLabel]="translateService.instant('visializadorDePlanos')"
                    id="hmiVisualizadorDePlanosId"></kendo-switch>
                </div>

                <!--Alarma-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiAlarma"
                    [onLabel]="translateService.instant('alarma')" [offLabel]="translateService.instant('alarma')"
                    id="hmiAlarmaId"></kendo-switch>
                </div>

                <!--Cambio de monitor-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiCambioMonitor"
                    [onLabel]="translateService.instant('cambioDeMonitor')"
                    [offLabel]="translateService.instant('cambioDeMonitor')" id="hmiCambioMonitorId"></kendo-switch>
                </div>

                <!--Librerias-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiLibrerias"
                    [onLabel]="translateService.instant('librerias')" [offLabel]="translateService.instant('librerias')"
                    id="hmiLibreriasId"></kendo-switch>
                </div>

                <!--Mantenimiento-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiMantenimiento"
                    [onLabel]="translateService.instant('mantenimiento')"
                    [offLabel]="translateService.instant('mantenimiento')" id="hmiMantenimientoId"></kendo-switch>
                </div>

                <!--Mes-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiMes"
                    [onLabel]="translateService.instant('mes')" [offLabel]="translateService.instant('mes')"
                    id="hmiMesId"></kendo-switch>
                </div>

                <!--Autodiag y compens-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiAutodiagYCompens"
                    [onLabel]="translateService.instant('autodiagYCompens')"
                    [offLabel]="translateService.instant('autodiagYCompens')" id="hmiAutodiagYCompensId"></kendo-switch>
                </div>

                <!--Preparacion-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiPreparacion"
                    [onLabel]="translateService.instant('preparacion')"
                    [offLabel]="translateService.instant('preparacion')" id="hmiPreparacionId"></kendo-switch>
                </div>

                <!--Mantenimiento-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiMantenimiento2"
                    [onLabel]="translateService.instant('mantenimiento')"
                    [offLabel]="translateService.instant('mantenimiento')" id="hmiMantenimiento2Id"></kendo-switch>
                </div>

                <!--Cambio placa-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiCambioPlaca"
                    [onLabel]="translateService.instant('cambioPlaca')"
                    [offLabel]="translateService.instant('cambioPlaca')" id="hmiCambioPlacaId"></kendo-switch>
                </div>

                <!--Ejecucion-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="hmiEjecucion"
                    [onLabel]="translateService.instant('ejecucion')" [offLabel]="translateService.instant('ejecucion')"
                    id="hmiEjecucionId"></kendo-switch>
                </div>

                
                <!--controlRepeticiones-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="controlRepeticiones"
                    [onLabel]="translateService.instant('controlRepeticiones')" [offLabel]="translateService.instant('controlRepeticiones')"
                    id="controlRepeticionesId"></kendo-switch>
                </div>
                <!--asignarOperaciones-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="asignarOperaciones"
                    [onLabel]="translateService.instant('asignarOperaciones')" [offLabel]="translateService.instant('asignarOperaciones')"
                    id="asignarOperacionesId"></kendo-switch>
                </div>
                <!--verReportInciden-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="verReportInciden"
                    [onLabel]="translateService.instant('verReportInciden')" [offLabel]="translateService.instant('verReportInciden')"
                    id="verReportIncidenId"></kendo-switch>
                </div>
                <!--perdidas-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="perdidas"
                    [onLabel]="translateService.instant('perdidas')" [offLabel]="translateService.instant('perdidas')"
                    id="perdidasId"></kendo-switch>
                </div>
                <!--calidad-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="calidad"
                    [onLabel]="translateService.instant('calidad')" [offLabel]="translateService.instant('calidad')"
                    id="calidadId"></kendo-switch>
                </div>
                <!--herramientas-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="herramientas"
                    [onLabel]="translateService.instant('herramientas')" [offLabel]="translateService.instant('herramientas')"
                    id="herramientasId"></kendo-switch>
                </div>
                <!--cabezal-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="cabezal"
                    [onLabel]="translateService.instant('cabezal')" [offLabel]="translateService.instant('cabezal')"
                    id="cabezalId"></kendo-switch>
                </div>
                <!--gestorDocumental-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="gestorDocumental"
                    [onLabel]="translateService.instant('gestorDocumental')" [offLabel]="translateService.instant('gestorDocumental')"
                    id="gestorDocumentalId"></kendo-switch>
                </div>
                <!--camara-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="camara"
                    [onLabel]="translateService.instant('camara')" [offLabel]="translateService.instant('camara')"
                    id="camaraId"></kendo-switch>
                </div>
                <!--observaciones-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="observaciones"
                    [onLabel]="translateService.instant('observaciones')" [offLabel]="translateService.instant('observaciones')"
                    id="observacionesId"></kendo-switch>
                </div>
                <!--escaneoDePegatinas-->
                <div class="form-group">
                  <kendo-switch class="kendo-switch-tipo2" formControlName="escaneoDePegatinas"
                    [onLabel]="translateService.instant('escaneoDePegatinas')" [offLabel]="translateService.instant('escaneoDePegatinas')"
                    id="escaneoDePegatinasId"></kendo-switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--VALORES TECNOLOGICOS-->
  <div class="card">
    <div class="card-header">
      <h3>
        <label>{{ 'valoresTecnologicos' | translate }}</label>
      </h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="row">

        <!--contrapresion-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoCerrarMolde' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="contrapresion" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--tiempoInyeccion-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoInyeccion' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tiempoInyeccion" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--tiempoPresionRemanente-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoPresionRemanente' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tiempoPresionRemanente" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--tiempoDosificacionPlastificacion-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoDosificPlastificacion' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tiempoDosificacionPlastificacion" [onLabel]="' '"
                  [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--tiempoEnfriamiento-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoEnfriamiento' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tiempoEnfriamiento" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--paradaDosificado-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoAbrirMolde' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="paradaDosificado" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--tiempoCiclo-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'tiempoCiclo' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tiempoCiclo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--presionInyeccionMax-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'presInyMax' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="presionInyeccionMax" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--ptoConmutacion-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'ptoConmutacion' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="ptoConmutacion" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--presionRemanente-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'presRemanente' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="presionRemanente" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--cojin-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'cojin' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="cojin" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--retrocesoHusillo-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'carreraPlastificacion' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="retrocesoHusillo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--paradaMolde-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'revolucionesPlastificacion' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="paradaMolde" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--revolucionesHusillo-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'presionServicioScf' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="revolucionesHusillo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--fuerzaDeCierre-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'dosificacionScf' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="fuerzaDeCierre" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--temperaturaMolde-->
        <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'diferenciaPresionScf' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="temperaturaMolde" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>

        <!--tempPresionFija-->
        <!-- <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'dosificacionScf' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tempPresionFija" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div> -->

        <!--tempPresionMovil-->
        <!-- <div class="col-md-2">
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'diferenciaPresionScf' | translate}}">
              <div class="caja">
                <kendo-switch formControlName="tempPresionMovil" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div> -->



      </div>
    </div>
  </div>

  <div class="row" style="margin: -5px 0 15px -17px;">

    <!--tempCamaraCaliente_activo-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'tCamaraCaliente' | translate}}</label>
          <kendo-switch formControlName="tempCamaraCaliente_activo"
            (valueChange)="onChangeTempCamaraCaliente_activo($event)" [onLabel]="' '" [offLabel]="' '"
            style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'maxZonas' | translate}}">
                <kendo-numerictextbox formControlName="tempCamaraCaliente_maxZonas" class="form-control"
                  [disabled]="!f.tempCamaraCaliente_activo.value" [min]="0" [max]="9999" [autoCorrect]="true"
                  [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--tempHusillo_activo-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'tHusillo' | translate}}</label>
          <kendo-switch formControlName="tempHusillo_activo" (valueChange)="onChangeTempHusillo_activo($event)"
            [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'maxZonas' | translate}}">
                <kendo-numerictextbox formControlName="tempHusillo_maxZonas" class="form-control"
                  [disabled]="!f.tempHusillo_activo.value" [min]="0" [max]="9999" [autoCorrect]="true"
                  [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--cascada_activo-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'cascada' | translate}}</label>
          <kendo-switch formControlName="cascada_activo" (valueChange)="onChangeCascada_activo($event)" [onLabel]="' '"
            [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label text="{{ 'maxZonas' | translate}}">
                <kendo-numerictextbox formControlName="cascada_maxZonas" class="form-control"
                  [disabled]="!f.cascada_activo.value" [min]="0" [max]="9999" [autoCorrect]="true"
                  [format]="'n'"></kendo-numerictextbox>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--mostrarEnGrafico_activo-->
    <div class="col-md-3" style="padding-right: 0px;">
      <div class="row extrusoras-panel-rangos">
        <div class="col-md-12 extrusoras-panel-rangos-header">
          <label class="extrusoras-panel-rangos-header-titulo">{{ 'mostrarEnGrafico' | translate}}</label>
          <kendo-switch formControlName="mostrarEnGrafico_activo"
            (valueChange)="onChangeMostrarEnGrafico_activo($event)" [onLabel]="' '" [offLabel]="' '"
            style="float: right; margin-top: 2px;"></kendo-switch>
        </div>
        <div class="col-md-12 extrusoras-panel-rangos-body">
          <div class="row" style="margin-top: 7px;">
            <div class="col-md-4">
              <kendo-label class="form-check-label" text="{{ 'desgloseTiempos' | translate}}">
                <div class="caja">
                  <kendo-switch formControlName="mostrarEnGrafico_desgloseTiempos"
                    [disabled]="!f.mostrarEnGrafico_activo.value" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </div>
              </kendo-label>
            </div>
            <div class="col-md-4">
              <kendo-label class="form-check-label" text="{{ 'cascadas' | translate}}">
                <div class="caja">
                  <kendo-switch formControlName="mostrarEnGrafico_cascadas"
                    [disabled]="!f.mostrarEnGrafico_activo.value" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </div>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="form-group" style=" margin-top: 20px;">

    <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">
      {{ 'guardar' | translate}}
    </button>
    <a class="btn btn-danger btn-sm mr-1" (click)="atras()">
      {{ 'cancelar' | translate}}
    </a>

  </div>

  <!-- POPUP: Isometrico -->
  <ng-template #popupIsometrico let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ 'isometrico' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="cont-filtro-maquinas-areas clearfix">

        <!-- DIBUJOS MAQUINAS -->
        <div class="isometricos-popup">

          <div *ngFor="let maquina of dibujosMaquinas" (click)="selectedDibujoMaquina = maquina; onClickCerrarPopup()"
            class="clickable" [ngClass]="{'actual': maquina==selectedDibujoMaquina}"
            style="padding: 0px; height: 115px;">
            <img style="max-width: 95px; max-height: 95px;" [src]="maquina.imagen">
            <label style="font-size: 12px;">{{ 'dimensiones' | translate }}: {{maquina.dimensiones}}</label>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-footer">
    </div>
  </ng-template>
</form>