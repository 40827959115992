<!-- FILTRO SUPERIOR -->
<div class="card">
  <div class="card-body" ng-controller="filtros">
    <!-- PLANES ACCION -->
    <div class="float-left mr-2">
      <label>{{ 'planesDeAccion' | translate}}</label>
      <div class="caja" style="width: 400px;">
        <kendo-dropdownlist [data]="planesacciones "
                            [textField]="'nombre'"
                            [valueField]="'id'"
                            (selectionChange)="planDeAccionSelected($event)"
                            class="form-control">
        </kendo-dropdownlist>
      </div>
    </div>
    <!-- FECHA INICIO -->
    <div class="float-left mr-2">
      <label>{{ 'fechainicio' | translate}}</label>
      <div class="caja" style="width: 130px;">
        <label class="label-fecha-pa">{{ fechaIniciostr }}</label>
      </div>
    </div>
    <!-- FECHA FIN -->
    <div class="float-left mr-2">
      <label>{{ 'fechafin' | translate}}</label>
      <div class="caja" style="width: 130px;">
        <label class="label-fecha-pa">{{ fechaFinstr }}</label>
      </div>
    </div>
    <!-- RESPONSABLE -->
    <div class="float-left mr-2">
      <label>{{ 'responsable' | translate}}</label>
      <div class="caja" style="width: 400px;">
        <input [readonly]="true" [value]="responsable" kendoTextBox class="form-control" />
      </div>
    </div>
    <!-- CLASIFICAR POR -->
    <div class="float-left mr-2">
      <label>{{ 'clasificarPerdidaPor' | translate}}</label>
      <div class="caja" style="width: 400px;">
        <div class="form-group">
          <div class="btn mr-1" [class.btn-success]="clasificarPorTiempoNumero" [class.btn-outline-success]="!clasificarPorTiempoNumero">
            <input type="radio" name="tiempoNumeroGroup" id="btnTiempo" class="k-radio" (click)="btnTiempo()" [checked]="clasificarPorTiempoNumero" kendoRadioButton>
            <label class="k-radio-label" for="btnTiempo">{{ 'tiempo' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!clasificarPorTiempoNumero" [class.btn-outline-success]="clasificarPorTiempoNumero">
            <input type="radio" name="tiempoNumeroGroup" id="btnNumero" class="k-radio" (click)="btnNumero()" [checked]="!clasificarPorTiempoNumero" kendoRadioButton>
            <label class="k-radio-label" for="btnNumero">{{ 'numero' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TODO -->
<div class="row">
  <!-- GRAFICOS -->
  <div class="col-md-8 ">

    <div class="row">
      <div class="perdidas-periodo perdidas-bloque col-md">
        <div class="card">
          <div class="card-body">
            <label runat="server" ID="lperdidasperiodo" class="perdida-bloque-titulo" data-toggle="tooltip" data-placement="bottom" title="{{ 'perdidasenperiodo' | translate }}">{{ 'perdidasenperiodo' | translate }}</label>
            <label runat="server" ID="lporcentajeperdidasperiodotiempo" class="perdida-bloque-horas" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.porcentajetiempo }}">
              {{ resumenperdidas.enperiodo.porcentajetiempo }}
              <span class="k-icon k-i-arrow-chevron-up icono-med-pa" *ngIf="flechaPerdidasEnPeriodoPorcen_PorTiempo == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-med-pa" *ngIf="flechaPerdidasEnPeriodoPorcen_PorTiempo == 'down'"></span>
            </label>
            <label runat="server" ID="lporcentajeperdidasperiodonumero" class="perdida-bloque-horas" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.porcentajenumero }}">
              {{ resumenperdidas.enperiodo.porcentajenumero }}
              <span class="k-icon k-i-arrow-chevron-up icono-med-pa" *ngIf="flechaPerdidasEnPeriodoPorcen_PorNumero == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-med-pa" *ngIf="flechaPerdidasEnPeriodoPorcen_PorNumero == 'down'"></span>
            </label>

            <label runat="server" ID="lvalorperdidasperiodotiempo" class="perdida-bloque-porcentaje" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.horas }}">
              {{resumenperdidas.enperiodo.horas}}
              <span class="k-icon k-i-arrow-chevron-up icono-peq-pa" *ngIf="flechaPerdidasEnPeriodoTiempo_PorTiempo == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-peq-pa" *ngIf="flechaPerdidasEnPeriodoTiempo_PorTiempo == 'down'"></span>
            </label>
            <label runat="server" ID="lvalorperdidasperiodonumero" class="perdida-bloque-porcentaje" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.numeroperdidas }}">
              {{resumenperdidas.enperiodo.numeroperdidas}}
              <span class="k-icon k-i-arrow-chevron-up icono-peq-pa" *ngIf="flechaPerdidasEnPeriodoTiempo_PorNumero == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-peq-pa" *ngIf="flechaPerdidasEnPeriodoTiempo_PorNumero == 'down'"></span>
            </label>

          </div>
        </div>
      </div>
      <div class=" variacion-periodo perdidas-bloque col-md">
        <div class="card">
          <div class="card-body">
            <label runat="server" ID="lvariacionperiodo" class="perdida-bloque-titulo" data-toggle="tooltip" data-placement="bottom" title="{{ 'perdidasanteriores' | translate }}">{{ 'perdidasanteriores' | translate }}</label>
            <label runat="server" ID="lporcentajevariacionesperiodotiempo" class="perdida-bloque-horas" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.porcentajevariaciontiempo }}">{{ resumenperdidas.enperiodo.porcentajevariaciontiempo }}</label>
            <label runat="server" ID="lporcentajevariacionesperiodonumero" class="perdida-bloque-horas" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.porcentajevariacionnumero }}">{{ resumenperdidas.enperiodo.porcentajevariacionnumero }}</label>
            <label runat="server" ID="lvalorvariacionperiodotiempo" class="perdida-bloque-porcentaje" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.horasvariacion }}">{{ resumenperdidas.enperiodo.horasvariacion }}</label>
            <label runat="server" ID="lvalorvariacionperiodonumero" class="perdida-bloque-porcentaje" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.enperiodo.numeroperdidasvariacion }}">{{ resumenperdidas.enperiodo.numeroperdidasvariacion }}</label>
          </div>
        </div>
      </div>
      <div class="perdidas-global perdidas-bloque col-md">
        <div class="card">
          <div class="card-body">

            <label runat="server" ID="lperdidasglobal" class="perdida-bloque-titulo" data-toggle="tooltip" data-placement="bottom" title="{{ 'perdidasglobalesperiodo' | translate }}">{{ 'perdidasglobalesperiodo' | translate }}</label>

            <label runat="server" ID="lporcentajevariacionesglobaltiempo" class="perdida-bloque-horas" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.porcentajetiempo }}">
              {{ resumenperdidas.globales.porcentajetiempo }}
              <span class="k-icon k-i-arrow-chevron-up icono-med-pa" *ngIf="flechaPerdidasTotalesEnPeriodoPorcen_PorTiempo == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-med-pa" *ngIf="flechaPerdidasTotalesEnPeriodoPorcen_PorTiempo == 'down'"></span>
            </label>
            <label runat="server" ID="lporcentajevariacionesglobalnumero" class="perdida-bloque-horas" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.porcentajenumero }}">
              {{ resumenperdidas.globales.porcentajenumero }}
              <span class="k-icon k-i-arrow-chevron-up icono-med-pa" *ngIf="flechaPerdidasTotalesEnPeriodoPorcen_PorNumero == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-med-pa" *ngIf="flechaPerdidasTotalesEnPeriodoPorcen_PorNumero == 'down'"></span>
            </label>

            <label runat="server" ID="lvalorperdidasglobaltiempo" class="perdida-bloque-porcentaje" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.horas }}">
              {{ resumenperdidas.globales.horas }}
              <span class="k-icon k-i-arrow-chevron-up icono-peq-pa" *ngIf="flechaPerdidasTotalesEnPeriodoTiempo_PorTiempo == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-peq-pa" *ngIf="flechaPerdidasTotalesEnPeriodoTiempo_PorTiempo == 'down'"></span>
            </label>
            <label runat="server" ID="lvalorperdidasglobalnumero" class="perdida-bloque-porcentaje" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.numeroperdidas }}">
              {{ resumenperdidas.globales.numeroperdidas }}
              <span class="k-icon k-i-arrow-chevron-up icono-peq-pa" *ngIf="flechaPerdidasTotalesEnPeriodoTiempo_PorNumero == 'up'"></span>
              <span class="k-icon k-i-arrow-chevron-down icono-peq-pa" *ngIf="flechaPerdidasTotalesEnPeriodoTiempo_PorNumero == 'down'"></span>
            </label>

          </div>
        </div>
      </div>
      <div class="variacion-global perdidas-bloque  col-md">
        <div class="card">
          <div class="card-body">
            <label runat="server" ID="lvariacionperiodo" class="perdida-bloque-titulo" data-toggle="tooltip" data-placement="bottom" title="{{ 'perdidasglobalesanterior' | translate }}">{{ 'perdidasglobalesanterior' | translate }}</label>
            <label runat="server" ID="lporcentajevariacionperiodotiempo" class="perdida-bloque-horas" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.porcentajevariaciontiempo }}">{{ resumenperdidas.globales.porcentajevariaciontiempo }}</label>
            <label runat="server" ID="lporcentajevariacionperiodonumero" class="perdida-bloque-horas" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.porcentajevariacionnumero }}">{{ resumenperdidas.globales.porcentajevariacionnumero }}</label>
            <label runat="server" ID="lvalorvariacionperiodotiempo" class="perdida-bloque-porcentaje" *ngIf="clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.horasvariacion }}">{{ resumenperdidas.globales.horasvariacion }}</label>
            <label runat="server" ID="lvalorvariacionperiodonumero" class="perdida-bloque-porcentaje" *ngIf="!clasificarPorTiempoNumero" data-toggle="tooltip" data-placement="bottom" title="{{ resumenperdidas.globales.numeroperdidasvariacion }}">{{ resumenperdidas.globales.numeroperdidasvariacion }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <label class="titulo-perdidas" runat="server" ID="lblcomparativas"></label>
        </div>
        <div class="card-body">
          <div id="graficacomparativasperdidas_maquinas">
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <label class="titulo-perdidas" runat="server" ID="lblevolucion"></label>
        </div>
        <div class="card-body">
          <div id="graficaevolucionperdidas_maquinas">
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- FILTRO LATERAL IZQUIERDA -->
  <div class="col-md-4">
    <!-- GRID: ACCIONES -->
    <div class="card minigrid">
      <div class="card-header">
        <label class="titulo-perdidas" runat="server" ID="lAcciones">{{ 'acciones' | translate}}</label>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <kendo-grid [data]="acciones" [selectable]="{ checkboxOnly: true }" kendoGridSelectBy="idAccion" [selectedKeys]="selectedAcciones" [height]="305" (selectionChange)="onSelectAccion($event)">
            <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column>
            <kendo-grid-column field="idAccion" hidden="hidden" title="ID" width="40"></kendo-grid-column>
            <kendo-grid-column field="nombreAccion" title="Accion" width="250"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </ul>
      </div>
    </div>
    <!-- GRID: MAQUINAS -->
    <div class="card minigrid">
      <div class="card-header">
        <label class="titulo-perdidas" runat="server" ID="lPerdidas">{{ 'maquinas' | translate}}</label>
      </div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <kendo-grid [data]="perdidas" [selectable]="{ checkboxOnly: true }" kendoGridSelectBy="idPerdida" [selectedKeys]="selectedPerdidas" [height]="305" (selectionChange)="onSelectPerdida($event)">
            <kendo-grid-checkbox-column [width]="40"></kendo-grid-checkbox-column>
            <kendo-grid-column field="idPerdida" hidden="hidden" title="ID" width="40"></kendo-grid-column>
            <kendo-grid-column field="nombrePerdida" width="120"></kendo-grid-column>
            <kendo-grid-column field="horasactualesstr" width="80"></kendo-grid-column>
            <kendo-grid-column field="porcentaje" width="80"></kendo-grid-column>
            <kendo-grid-column field="variacionicono" title="" width="80">
              <ng-template kendoGridCellTemplate let-dataItem>
                <i class='{{ dataItem.variacionicono }}'></i>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" width="80">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-sparkline style="width: 100%" [seriesDefaults]="{color: '#22c4c4'}" [data]="dataItem.valoresMiniGrafico" type="area">
                  <kendo-chart-series-item-tooltip [visible]="false">
                  </kendo-chart-series-item-tooltip>
                </kendo-sparkline>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </ul>
      </div>
    </div>
  </div>
</div>




