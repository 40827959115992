import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService, AlertService, CalendariosService, Eskola_HorariosSeccionesService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { elementAt, first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from './../_helpers/myfunctions';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';

export const DAYS_OF_WEEK = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

@Component({
  selector: 'app-calendario-cuadrados',
  templateUrl: 'calendario_cuadrados.component.html',
})
export class CalendarioCuadradosComponent implements OnInit {
  user = this.usuariosService.userValue;
  navigationSubscription;
  private translate: TranslateService;
  public monthsInYearFila = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  public year;
  public DaysInMonths;
  public periodoActualSeleccionado = [];
  public listadoPeriodosInactivos;
  public listadoReservas;

  public dtHorariosSecciones: any = [];
  public horariosSecciones: any = [];

  guardar: boolean = false;

  @Output() diasInactivos: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() diasActivos: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() yearEnviado: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() newFechaInicioSemana: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() newFechaFinSemana: EventEmitter<Date> = new EventEmitter<Date>();
  @Input() calendarioInicio: Date; // to receive when domingo checkbox changes in parent
  @Input() calendarioFin: Date; // to receive when idCalendario is changed in parent
  @Input() docente:any;
  @Input() curso:any;
  @Input() grupo:any ;


  opened = false;
  mensajeError = '';

  constructor(private eskolaCalendariosService: CalendariosService,
    private eskola_horariosSeccionesService: Eskola_HorariosSeccionesService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService, private modalService: NgbModal,
    public myFunctions: MyFunctions, public alertService: AlertService, private eRef: ElementRef) {
    this.translate = translateService;
    this.year = (new Date()).getFullYear();
    this.cargarMeses();


    this.inicializarDiasConReservasEnEseAno(this.year);
  }

  ngOnInit(): void {


  }

  cargarMeses() {
    this.DaysInMonths = [];
    // Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el primero del mes
    for (let i = 0; i < 12; i++) {
      this.DaysInMonths.push(new Date(this.year, i, 1));
    }
  }


  calcularAfectados(fecha1, fecha2, miArray) {
    let lista_de_afectados = [];
    let lista_de_intactos = [];
    miArray.forEach(element => {
      if (element[0] <= fecha2 && element[1] >= fecha1) {
        lista_de_afectados.push([new Date(element[0]), new Date(element[1])]);
      }
      else {
        lista_de_intactos.push([new Date(element[0]), new Date(element[1])]);
      }
    });
    return { afectados: lista_de_afectados, intactos: lista_de_intactos };
  }


  fechasIguales(fecha1, fecha2) {
    if (fecha1.getDate() == fecha2.getDate() && fecha1.getMonth() == fecha2.getMonth() && fecha1.getFullYear() == fecha2.getFullYear()) {
      return true;
    }
    else {
      return false;
    }
  }


  public esReserva(date: Date):{reserva:boolean, reservas} {
    var reservas : any = [];
    if (this.horariosSecciones == undefined || this.horariosSecciones.length == 0) {return {reserva:false, reservas:reservas} }
    else if (date.getFullYear() == this.year) {
      var reserva: boolean = false;
      this.horariosSecciones.forEach(element => {
        if (date.getFullYear() == element.fechaInicio.getFullYear() && date.getMonth() == element.fechaInicio.getMonth() && date.getDate() == element.fechaInicio.getDate()) {
          reserva = true;
          reservas.push(element);
    
        }
      });
      return {reserva:reserva, reservas:reservas};

    } else {
      return {reserva:true, reservas:reservas};
    }
  }


  public getFocusedDate(month) {
    return new Date(this.year, month, 1);
  }

  public valueClickCalendar(month, event) {

    this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
    let auxFecha1 = new Date(this.periodoActualSeleccionado[0].getFullYear(), this.periodoActualSeleccionado[0].getMonth(), this.periodoActualSeleccionado[0].getDate());
    let newFechaInicio:Date = this.startOfWeek(auxFecha1);
    let newFechaFin:Date = this.startOfWeek(auxFecha1);
   
    newFechaFin.setDate(auxFecha1.getDate()+6);
    newFechaFin.setHours(23);
    newFechaFin.setMinutes(59);

    this.router.navigate(['eskola_horariosSecciones/' , this.myFunctions.datetimeToSQL(newFechaInicio) , this.myFunctions.datetimeToSQL(newFechaFin)]);



  }

  public previousYear() {
    this.year -= 1;
    this.cargarMeses();
    this.inicializarDiasConReservasEnEseAno(this.year);
    this.yearEnviado.emit(this.year);

  }

  public nextYear() {
    this.year += 1;
    this.cargarMeses();
    this.inicializarDiasConReservasEnEseAno(this.year);
    this.yearEnviado.emit(this.year);

  }

  ngOnChanges(changes: SimpleChanges) {

    if(this.curso == undefined && this.docente == undefined && this.grupo == undefined){
      this.inicializarDiasConReservasEnEseAno(this.year);
    }
    
    this.yearEnviado.emit(this.year);

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(event.target.id == "onFilter"){

      debugger
    //filtramos los horarios del curso seleccionado
    this.horariosSecciones = this.dtHorariosSecciones.filter(f=>{
      var cursoId = f.id_curso;
      var grupoId = f.id_grupo;
      var docentes:boolean = true;
      if(this.curso!=undefined){
        cursoId = this.curso.id;
      }
      if(this.grupo!=undefined){
        grupoId = this.grupo.id;
      }

      if(this.docente!=undefined){
        if(this.docente.grupos.filter(g=> g == f.id_grupo).length>0){
          docentes = true;
        }else{
          docentes = false;
        }
      }

      if(f.id_curso == cursoId && f.id_grupo == grupoId && docentes){
        return f;
      }
      
    });
    console.log(this.horariosSecciones);
    this.cargarMeses();
  }
  

    
  }

  buscarElemento(miArray, elemento) {
    let encontrado = false;
    miArray.forEach(element => {
      if (this.fechasIguales(element[0], elemento[0]) && this.fechasIguales(element[1], elemento[1])) {
        encontrado = true;
      }
    });
    return encontrado;
  }

  buscarElemento2(miArray, elemento) {
    let encontrado = false;
    miArray.forEach(element => {
      if (this.fechasIguales(element, elemento)) {
        encontrado = true;
      }
    });
    return encontrado;
  }



  inicializarDiasConReservasEnEseAno(year) {
    this.eskola_horariosSeccionesService.getHorariosSeccionesCurso(-1).subscribe((result: any) => {
      this.dtHorariosSecciones = result;
      this.listadoReservas = [];
      this.horariosSecciones = [];
      if (result != null && result.length > 0) {
        result.forEach(element => {
          let fecha = element.fechaInicio.split('T')[0].split('-');
          let auxiFecha = new Date(fecha[0], fecha[1] - 1, fecha[2]);
          if (auxiFecha.getFullYear() == year) {
            this.listadoReservas.push(auxiFecha);
            element.fechaInicio = new Date(element.fechaInicio);
            element.fechaFin = new Date(element.fechaFin);
            this.horariosSecciones.push(element);
          }

        });
      
      }
    });

  }


  // Functions to obtains days in month
  getMondaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 1) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getTuesdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 2) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getWednesdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 3) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getThursdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 4) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getFridaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 5) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getSaturdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 6) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getSundaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 0) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }


  // function to know if is a leap year
  // Every year that is exactly divisible by four is a leap year,
  // except for years that are exactly divisible by 100, but these centurial years are
  // leap years if they are exactly divisible by 400
  leapyear() {
    return ((this.year % 4 == 0) && (this.year % 100 != 0)) || (this.year % 400 == 0);
  }

  close() {
    this.opened = false;
  }

  addZero(n: number) {
    if (n < 10) {
      return '0' + n.toString();
    }
    else {
      return n.toString();
    }
  }
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }


}
