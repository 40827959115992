import { Component, ContentChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService, OperacionesService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';
// import { ThisReceiver } from '@angular/compiler/public_api';

@Component({ templateUrl: 'hmiInyectoras.html' })

export class HMIInyectorasComponent implements OnInit {

  interval: any;
  interval2: any;

  idmaquina: any;
  maquina: any;
  maquinasModel: any;
  marcasModel: any;

  //CSS pestaña
  pestannaColor: any = "";

  //PANEL MAQUINA
  operario: any = "";
  tituloDonut: any = "-";
  fechaIni: any;
  fecha: any;
  fechaServidor: any;
  tiempo: any = 0;
  tiempoCorregido: string = "";
  tiempoEst: any = 0;
  tiempoEstCorregido: string = "";
  tiempoPred: any = 0;
  tiempoPredCorregido: string = "";
  desviacion: string = "";
  ciclo: any = "";
  procesosTipo: any = "-";
  procesosTipoMinuscSinAcentos: any = "-";
  porcentaje: any = 0;
  estadoImg: any = "";

  // TAB PROCESOS  
  historicoProcesos: any;
  alarmas: any;
  planificados: any;

  //TAB MANTENIMINETOS
  mantenimientoPorFecha: any;
  mantenimientoPorTiempo: any;
  loadingPorFecha: any;
  loadingPorTiempo: any;

  //TAB NOTAS
  notas: any;

  //TAB HERRAMIENTAS
  herramientasCabezal: any;
  herramientas: any;
  roturasCambiosHerramientas: any;

  //LABELS OF
  of: any = "";
  cliente: any = "";
  nombrePieza: any = "";
  idOperacion: any = "";
  referenciaPieza: any = "";
  realizadas: any = "";
  //LABELS DATOS
  nCavidades: any = "";
  material: any = "";
  refMaterial: any = "";
  refMolde: any = "";

  //LISTA CICLOS
  listaCiclos: any = [];
  primeraVezListaCiclosCargada: boolean = true;

  //GRAFICOS
  graficoEjecucion: any;
  graficoResumenSemana: any;
  datosEjeX: any = [];

  //GRAFICO MULTIEJE
  public grafico1: any;
  public grafico2: any;
  public grafico3: any;
  public grafico4: any;
  // public grafico5: any;
  // public grafico6: any;
  // public grafico7: any;
  public subgrafico: any;
  public mostrarGraficosPorLeyenda: any = [ true, true, true, true ];
  public arrayFechas: any = ["-"];
  public fechaInicioZoom: any;
  public fechaFinZoom: any;
  public fechaIniCicloSeleccionado: any;
  public fechaFinCicloSeleccionado: any;
  // public colores = [
  //   "#3366cc", // tiempoCerrarMolde
  //   "#cb3737", // tiempoInyeccion
  //   "#32a814", // tiempoPresionRemanente
  //   "#b4e50a", // tiempoDosificPlastificacion

  //   "#ac5cad", // tiempoEnfriamiento
  //   "#57a9aa", // tiempoAbrirMolde
  //   "#adadad", // tiempoCiclo

  //   "#00ffeb", // presInyMax
  //   "#36cc39", // ptoConmutacion
  //   "#3863cf", // presRemanente
  //   "#ff9900", // cojin
  //   "#b3e90c", // carreraPlastificacion
  //   "#3366cc", // revolucionesPlastificacion
  //   "#cb3737", // presionServicioScf
  //   "#32a814", // dosificacionScf
  //   "#b4e50a", // diferenciaPresionScf
  // ];

  public colores: any = [
    { color: "#8B0707", css: "granate", enUso: true },
    { color: "#3366CC", css: "azul", enUso: true },
    { color: "#FF9900", css: "naranja", enUso: true },
    { color: "#9836CC", css: "morado", enUso: true },
  ];

  public datosGraficoMultieje: any = {};
  public listaTooltip: any = [];
  public ratonEnGraficoMultieje: boolean = false;
  public ratonEnSubGrafico: boolean = false;

  public datosDB: any = [];
  public jsonDB: any = [];

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;

  //PESTANAS
  loadingPestannas: boolean = true;
  listaValoresTecnologicos: any = [];
  listaTooltipSeleccionados: any = [4,6,7,10];

  listaTCamaraCaliente: any = [];
  listaTHusillo: any = [];
  listaCascada: any = [];

  valorTempCamaraCaliente: any;
  tolerTempCamaraCaliente: any;
  valorTempHusillo: any;
  tolerTempHusillo: any;
  valorCascada: any;
  tolerCascada: any;

  alertaTempCamaraCaliente: any;
  alertaTempHusillo: any;
  alertaCascada: any;

  user = this.usuariosService.userValue;

  //OEE
  oee: any = [
    { valor: 0, nombre: this.translateService.instant("oee"), valorGrafico: 0 },
    { valor: 0, nombre: this.translateService.instant("disp"), valorGrafico: 0 },
    { valor: 0, nombre: this.translateService.instant("rend"), valorGrafico: 0 },
    { valor: 0, nombre: this.translateService.instant("cal"), valorGrafico: 0 },
  ];

  // Solo visibles apartados seleccionados en la configuracion
  conf: any;
  tempCamaraCaliente_activo = true;
  tempHusillo_activo = true;
  cascada_activo = true;
  listaVariablesVisibles: any = [];

  // Enseñar OF y DATOS del ciclo seleccionado
  dCiclo: any;
  cicloActual: any;

  // CALCULO TIEMPOS NUEVO
  public r1: boolean = false;
  public unicaEjecucionTiempos: boolean = true;
  public tiempoLoteSegundos: number = -1;
  public loadingDatosTiempo: boolean = false;
  public timeOutTiempos: any;
  public ejecucion: any;
  public tiempoOperacion: string = "--:--:--";

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private operacionesService: OperacionesService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    public translateService: TranslateService,
    public myFunctions: MyFunctions) {

    this.interval = setInterval(() => {
      this.refreshData();
    }, 60000); //gero 10 segundu jarri!!!

    this.interval2 = setInterval(() => {
      this.refreshDataTiempos();
    }, 1000);

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('inyectora');
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));
    this.maquina = {
      nombre: "-",
      imagenBase64: "",
      marca: { imagenBase64: "" },
      tiempoReal: [],
    };
    this.calcularTiempos2();
    this.cargarMaquinasModel();
  }

  quitarAcentos(katea) {
    return katea.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  CargarIconosEstadoMaquina() {

    var tipoProceso = this.quitarAcentos(this.procesosTipo.toLowerCase()); //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    if (this.tiempoEst == 0) {
      this.estadoImg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      this.porcentaje = "";
    } else {
      var ahora = new Date(Date.parse(this.fechaServidor));
      var fechaOrig = new Date(Date.parse(this.fechaIni));

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      var docentaje = this.calcularPorcentaje(segundosfiff, 12, this.tiempoEst, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, this.tiempoEst, 1, true);
      this.porcentaje = porcentaje + "%";
      this.estadoImg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
    }

  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }
  }

  cargarMaquina() {

    //COGEMOS LA MAQUINA
    this.maquina = this.maquinasModel.find(x => x.id === this.idmaquina);
    this.maquina.marca = this.marcasModel.find(x => x.id === this.maquina.idmarca);
    this.r1 = true;
    //PINTAMOS LOS GRAFICOS
    this.pintarGraficos();

  }

  pintarGraficos() {
    this.pintarGraficoEjecucion();
    this.pintarGraficoResumenSemana();
    setTimeout(function () {
      // this.pintarGraficoMultiEje();
      this.pintarSubGraficoMultiEje();
      //DESPUES DE PINTAR LOS GRAFICOS CARGAMOS TODOS LOS DATOS
      this.cargarTodosLosDatos();
    }.bind(this), 50);
  }

  pintarGraficoEjecucion() {
    var that = this;
    this.graficoEjecucion = c3.generate({
      bindto: '#ejecucionGrafico_hmiIny',
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 14,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#ejecucionGrafico_hmiIny .c3-chart-arcs-title')
      .text("")
      .style("font-size", "26px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#ejecucionGrafico_hmiIny .c3-chart-arcs path").style("stroke-width", "0px");

  }

  pintarGraficoResumenSemana() {

    this.graficoResumenSemana = c3.generate({
      bindto: '#resumenSemanaGrafico_hmiIny',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });

  }

  pintarGraficoMultiEje() {
    var i;
    for (i = 1; i <= 4; i++) {
      this.pintarGraficoMultiEjeAux(i);
    }

    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#grafico1_hmiIny svg').node().childNodes;
    list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

    d3.select('#grafico1_hmiIny .c3-event-rect')._groups[0][0].onmousedown =
      function (e) {
        if (e.button != 0) {
          var evt = new MouseEvent("mousedown", {
            altKey: e.altKey,
            bubbles: e.bubbles,
            button: 0,
            buttons: e.buttons,
            cancelable: e.cancelable,
            clientX: e.clientX,
            clientY: e.clientY,
            composed: e.composed,
            ctrlKey: e.ctrlKey,
            detail: e.detail,
            metaKey: e.metaKey,
            movementX: e.movementX,
            movementY: e.movementY,
            relatedTarget: e.relatedTarget,
            screenX: e.screenX,
            screenY: e.screenY,
            shiftKey: e.shiftKey,
            view: e.view
          });
          d3.select("#grafico1_hmiIny .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
        }
      };
    d3.select('#grafico2_hmiIny .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiIny .c3-event-rect')._groups[0][0].onmousedown;
    d3.select('#grafico3_hmiIny .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiIny .c3-event-rect')._groups[0][0].onmousedown;
    d3.select('#grafico4_hmiIny .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiIny .c3-event-rect')._groups[0][0].onmousedown;
  
  }

  pintarGraficoMultiEjeAux(i) {
    var th = this;
    let labelAux: any = '';
    if (this.listaTooltipSeleccionados.length > 0) {
      labelAux = this.listaValoresTecnologicos[this.listaTooltipSeleccionados[i-1]];
      if (labelAux != undefined) labelAux = labelAux.nombre;
    }
    let positionAux = 'outer-middle';
    let showY1axis = false;
    let showY2axis = true;
    let yAxis = 'y2';
    if (i <= 2) { showY2axis = false; showY1axis = true; yAxis = 'y'}

    var chart = c3.generate({
      bindto: "#grafico" + i + "_hmiIny",
      padding: {
        left:124,
        // left: 256,
        right: 15,
        bottom: 15
      },
      data: {
        x: 'x',
        columns: [
          ['x'],
          ['valor']
        ],
        colors: {
          valor: th.colores[i - 1],
        },
        order: null,
        axes: {
          valor: yAxis
        }
      },
      regions: [],
      transition: {
        duration: 0
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            values: function(c) { return th.datosEjeX },
            //format: '%m/%d - %H:%M:%S'
            format: function (x) {
              if (x != undefined && x instanceof Date) {
                if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                  if (x.getDay() == 0) return th.translateService.instant("domingo");
                  if (x.getDay() == 1) return th.translateService.instant("lunes");
                  if (x.getDay() == 2) return th.translateService.instant("martes");
                  if (x.getDay() == 3) return th.translateService.instant("miercoles");
                  if (x.getDay() == 4) return th.translateService.instant("jueves");
                  if (x.getDay() == 5) return th.translateService.instant("viernes");
                  if (x.getDay() == 6) return th.translateService.instant("sabado");
                } else {
                  return th.myFunctions.dateToHourString(x);
                }
              }
              return '';
            },
            padding: 0,
            count: 10
          },
        },
        y: {
          show: showY1axis,
          label: { 
            position: positionAux,
            text: labelAux
          },
          padding: 0,
          tick: {
            count: 11,
            format: function(x) {
              return th.myFunctions.numero_con_formato(x, 1)
            }
          }
        },
        y2: {
          show: showY2axis,
          label: { 
            position: positionAux,
            text: labelAux
          },
          padding: 0,
          tick: {
            count: 11,
            format: function(x) {
              return th.myFunctions.numero_con_formato(x, 1)
            }
          }
        }
      },
      legend: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {

          var fecha = d[0].x;

          if (fecha >= th.fechaInicioZoom && fecha <= th.fechaFinZoom) {

            var $$ = this, config = $$.config,
              titleFormat = config.tooltip_format_title || defaultTitleFormat,
              nameFormat = config.tooltip_format_name || function (name) { return name; },
              valueFormat = config.tooltip_format_value || defaultValueFormat,
              text, i, title, value, name, bgcolor;
            for (i = 0; i < d.length; i++) {
              if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }

              if (!text) {
                title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
              }

            }

            var indice = th.arrayFechas.findIndex(x => x == d[0].x);
            var camposSeleccionados = [];

            if (th.maquina.tipoMaquinaInyeccion == 1) {
              th.listaValoresTecnologicos.forEach(function (campo) {
                if (campo.seleccionadoLeyenda) camposSeleccionados.push(campo);
              }, this);
            } else {
              th.listaTooltip.forEach(function (campo) {
                if (campo.seleccionadoLeyenda) camposSeleccionados.push(campo);
              }, this);
            }

            camposSeleccionados.forEach(function (campo) {

              if (th.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] && campo.valoresMomentaneo[indice] != undefined) {
                name = th.translateService.instant(campo.nombre);

                if (!campo.nombre.startsWith("cascada")) {
                  value = campo.valoresMomentaneo[indice] + " " + campo.unidad;
                } else {
                  if (campo.valoresMomentaneo[indice] == true) value = th.translateService.instant("activo");
                  if (campo.valoresMomentaneo[indice] == false) value = th.translateService.instant("inactivo");
                }

                bgcolor = campo.color;
                text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[0].id + "'>";
                text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                text += "<td class='value'>" + value + "</td>";
                text += "</tr>";
              }
            }, this);

            if (th.ratonEnGraficoMultieje) th.subgrafico.tooltip.show({ x: d[0].x });

            return text + "</table>";

          }

        }
      },
      zoom: {
        enabled: (i == 1),
        type: 'drag',
        disableDefaultBehavior: true,
        onzoomend: function (domain) {
          th.fechaInicioZoom = domain[0];
          th.fechaFinZoom = domain[1];
          th.aplicarZoomEnGrafico();
        }
      },
      onmouseover: function () {
        th.ratonEnGraficoMultieje = true;
      },
      onmouseout: function () {
        th.ratonEnGraficoMultieje = false;
        th.subgrafico.tooltip.hide();
      }
    });


    if (i == 1) {
      this.grafico1 = chart;
      this.grafico1.resize({
        height: 445
      });
    }
    if (i == 2) {
      this.grafico2 = chart;
      this.grafico2.resize({
        height: 445
      });
    }
    if (i == 3) {
      this.grafico3 = chart;
      this.grafico3.resize({
        height: 445
      });
    }
    if (i == 4) {
      this.grafico4 = chart;
      this.grafico4.resize({
        height: 445
      });
    }
  
  }

  pintarSubGraficoMultiEje() {

    var th = this;

    this.subgrafico = c3.generate({
      bindto: "#subgrafico_hmiIny",
      padding: {
        //left: 256,
        left: 124,
        right: 15,
        bottom: 15
      },
      data: {
        x: 'x',
        columns: [
          ['x'],
          ['data1_color'],
          ['data1_blanco'],
          ['data2_color'],
          ['data2_blanco'],
          ['data3_color'],
          ['data3_blanco'],
          ['data4_color'],
          ['data4_blanco'],
          // ['data5_color'],
          // ['data5_blanco']
        ],
        types: {
          data1_color: 'area-step',
          data1_blanco: 'area-step',
          data2_color: 'area-step',
          data2_blanco: 'area-step',
          data3_color: 'area-step',
          data3_blanco: 'area-step',
          data4_color: 'area-step',
          data4_blanco: 'area-step',
          // data5_color: 'area-step',
          // data5_blanco: 'area-step'
        },
        groups: [['data1_color', 'data1_blanco', 'data2_color', 'data2_blanco', 'data3_color', 'data3_blanco', 'data4_color', 'data4_blanco']], //, 'data5_color', 'data5_blanco']],
        order: null
      },
      color: {
        pattern: [th.colores[7], '#ffffff', th.colores[8], '#ffffff', th.colores[9], '#ffffff', th.colores[10], '#ffffff'],//, th.colores[11], '#ffffff']
      },
      transition: {
        duration: 0
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            values: function (x) {
              var dif = (x[1].getTime() - x[0].getTime()) / 1000;

              var fechaIni = x[0];
              fechaIni.setMinutes(fechaIni.getMinutes() - 10);
              var fechaFin = x[1];
              fechaFin.setMinutes(fechaFin.getMinutes() + 10);

              if (dif < 180)
                return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(120);
              else if (dif < 1800)
                return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(20);
              else
                return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(10);
            },
            //format: '%m/%d - %H:%M:%S'
            format: function (x) {
              if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                if (x.getDay() == 0) return th.translateService.instant("domingo");
                if (x.getDay() == 1) return th.translateService.instant("lunes");
                if (x.getDay() == 2) return th.translateService.instant("martes");
                if (x.getDay() == 3) return th.translateService.instant("miercoles");
                if (x.getDay() == 4) return th.translateService.instant("jueves");
                if (x.getDay() == 5) return th.translateService.instant("viernes");
                if (x.getDay() == 6) return th.translateService.instant("sabado");
              } else {
                return th.myFunctions.dateToHourString(x);
              }
            },
          },
          padding: 0,
          count: 10
        }
      },
      legend: {
        show: false
      },
      point: {
        show: false
      },
      tooltip: {
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if (th.ratonEnSubGrafico) th.grafico1.tooltip.show({ x: d[0].x });
          return "";
        }
      },
      // zoom: {
      //   enabled: true,
      //   type: 'drag',
      //   disableDefaultBehavior: true,
      //   onzoomend: function (domain) {
      //     th.fechaInicioZoom = domain[0];
      //     th.fechaFinZoom = domain[1];
      //     th.aplicarZoomEnGrafico();
      //   }
      // },
      onmouseover: function () {
        th.ratonEnSubGrafico = true;
      },
      onmouseout: function () {
        th.ratonEnSubGrafico = false;
        th.grafico1.tooltip.hide();
      }
    });



    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#subgrafico_hmiIny svg').node().childNodes;
    list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

    d3.select('#subgrafico_hmiIny .c3-event-rect')._groups[0][0].onmousedown = function (e) {
      if (e.button != 0) {
        var evt = new MouseEvent("mousedown", {
          altKey: e.altKey,
          bubbles: e.bubbles,
          button: 0,
          buttons: e.buttons,
          cancelable: e.cancelable,
          clientX: e.clientX,
          clientY: e.clientY,
          composed: e.composed,
          ctrlKey: e.ctrlKey,
          detail: e.detail,
          metaKey: e.metaKey,
          movementX: e.movementX,
          movementY: e.movementY,
          relatedTarget: e.relatedTarget,
          screenX: e.screenX,
          screenY: e.screenY,
          shiftKey: e.shiftKey,
          view: e.view
        });
        d3.select("#subgrafico_hmiIny .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
      }
    }

  }

  cargarTodosLosDatos() {

    // Traer los datos de las maquinas para enseñar solo los datos seleccionados en la configuracion
    console.log("idmaquina", this.idmaquina);
    this.maquinasService.GetInyectoraById(this.idmaquina).subscribe((result) => {
      this.conf = result.data[0];

      this.tempCamaraCaliente_activo = this.conf.tempCamaraCaliente_activo;
      this.tempHusillo_activo = this.conf.tempHusillo_activo;
      this.cascada_activo = this.conf.cascada_activo;
    });
    console.log("CONF:", this.conf);
    this.cargarVariablesVisibles(this.conf);
    this.centroMecanizadoService.GetHmiInyectoraById(this.idmaquina).subscribe((result) => {

      var datos: any = result;

      var ejecucionActual = datos.ejecuciones;

      if (result.oee != undefined && result.oee.length > 0) {
        var resultadoOee = this.myFunctions.calcularOee(result.oee);

        var oee = [];

        //tempAnilloEntrada
        var min: any = 0;
        var max: any = 100;
        var valor: any = resultadoOee[0];
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("oee"), valorGrafico: valorGrafico });

        //tempIBCDeEntrada
        var min: any = 0;
        var max: any = 100;
        var valor: any = resultadoOee[1];
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("disp"), valorGrafico: valorGrafico });

        //tempRodilloCalandra
        var min: any = 0;
        var max: any = 100;
        var valor: any = resultadoOee[2];
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("rend"), valorGrafico: valorGrafico });

        //tempRodilloHalador
        var min: any = 0;
        var max: any = 100;
        var valor: any = resultadoOee[3];
        var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
        oee.push({ valor: valor, nombre: this.translateService.instant("cal"), valorGrafico: valorGrafico });

        this.oee = oee;

      } else {
        this.oee = [
          { valor: 0, nombre: this.translateService.instant("oee"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("disp"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("rend"), valorGrafico: 0 },
          { valor: 0, nombre: this.translateService.instant("cal"), valorGrafico: 0 },
        ];

      }

      if (ejecucionActual.length > 0) {

        //pestannaColor
        if (ejecucionActual[0].idProcesos_tipo == 1) this.pestannaColor = "pestañaprocesos colorejecucion";
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.pestannaColor = "pestañaprocesos colorparada";
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.pestannaColor = "pestañaprocesos colorpreparacion";
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.pestannaColor = "pestañaprocesos colormantenimiento";
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.pestannaColor = "pestañaprocesos coloralarma";
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.pestannaColor = "pestañaprocesos colorapagada";
        else this.pestannaColor = "pestañaprocesos colorejecucion";

        //PANEL MAQUINA
        if (ejecucionActual[0].operario == " ") this.operario = this.translateService.instant('sinOperario');
        else this.operario = ejecucionActual[0].operario;

        this.fechaServidor = new Date(ejecucionActual[0].horaServidor);
        //this.fechaIni = new Date(ejecucionActual[0].fecha);

        if (this.procesosTipo.toUpperCase() != 'APAGADA') {
          this.tiempoEst = ejecucionActual[0].tiempoEstimadoEjecucion;
          this.tiempoEstCorregido = this.corregirFormato(this.tiempoEst);
          this.tiempoPred = ejecucionActual[0].tiempoPredictivoEjecucion;
          this.tiempoPredCorregido = this.corregirFormato(this.tiempoPred);
          this.ciclo = ejecucionActual[0].nCiclo;
        } else {
          this.cargarTiempoApagado()
        }

        //console.log("Tiempo estimado: " + this.tiempoEst);
        if (ejecucionActual[0].idProcesos_tipo == 1) this.tituloDonut = this.translateService.instant("ejecucion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.tituloDonut = this.translateService.instant("parada").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.tituloDonut = this.translateService.instant("preparacion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.tituloDonut = this.translateService.instant("mantenimiento").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.tituloDonut = this.translateService.instant("alarma").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.tituloDonut = this.translateService.instant("apagada").toUpperCase();
        else this.tituloDonut = this.translateService.instant("ejecucion").toUpperCase();

        if (ejecucionActual[0].idProcesos_tipo == 1) this.procesosTipo = this.translateService.instant("ejecucion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.procesosTipo = this.translateService.instant("parada").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.procesosTipo = this.translateService.instant("preparacion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.procesosTipo = this.translateService.instant("mantenimiento").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.procesosTipo = this.translateService.instant("alarma").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.procesosTipo = this.translateService.instant("apagada").toUpperCase();
        else this.procesosTipo = this.translateService.instant("ejecucion").toUpperCase();

        this.procesosTipoMinuscSinAcentos = this.quitarAcentos(this.procesosTipo.toLowerCase());

        //GRAFICO EJECUCION
        this.ahora = new Date(Date.parse(ejecucionActual[0].horaServidor));//this.myFunctions.getDateNow();
        this.fecha = new Date(Date.parse(ejecucionActual[0].fechainicio));//this.myFunctions.getDateNow();
        var segundosfiff = (this.ahora - this.fecha) / 1000;
        var porcen = this.calcularPorcentaje(segundosfiff, 100, this.tiempoEst, 1, true);
        d3.select('#ejecucionGrafico_hmiIny .c3-chart-arcs-title').text(Math.round(porcen) + "%");
        this.graficoEjecucion.load({ columns: [['completo', porcen], ['nocompleto', 100 - porcen]] });


        //DATOS PANEL
        // this.of = ejecucionActual[0].of;
        // this.cliente = ejecucionActual[0].cliente;
        // this.nombrePieza = ejecucionActual[0].nombrePieza;
        // this.referenciaPieza = ejecucionActual[0].referenciaPieza;
        // if (ejecucionActual[0].of == undefined || ejecucionActual[0].of == "")
        //   this.realizadas = "";
        // else
        //   this.realizadas = ejecucionActual[0].cuantasHechas + "/" + ejecucionActual[0].cantidad;
        // CARGAREMOS LOS DATOS DEL CICLO SELECCIONADO
        this.of = ejecucionActual[0].of;
        this.cliente = ejecucionActual[0].cliente;
        this.nombrePieza = ejecucionActual[0].nombrePieza;
        this.idOperacion = ejecucionActual[0].idOperacion;
        this.referenciaPieza = ejecucionActual[0].referenciaPieza;
        if (ejecucionActual[0].of == undefined || ejecucionActual[0].of == "")
          this.realizadas = "";
        else
          this.realizadas = ejecucionActual[0].cuantasHechas + "/" + ejecucionActual[0].cantidad;


        this.CargarIconosEstadoMaquina();

        this.nCavidades = ejecucionActual[0].nCavidades;
        this.material = ejecucionActual[0].material;
        this.refMaterial = ejecucionActual[0].refMaterial;
        this.refMolde = ejecucionActual[0].refMolde;

        // PARA CORREGIR!
        this.operario = ejecucionActual[0].operario;

      }

      //RESUMEN SEMANA
      var tiempoPorSemana = datos.tiempoPorSemana;

      if (tiempoPorSemana.length == 0) {
        tiempoPorSemana = [{ porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }];
      }

      var tiempoPorSemanaData = [[this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
      [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
      [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
      [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
      [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
      [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
      [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]];

      this.graficoResumenSemana.load({ columns: tiempoPorSemanaData });

      //LISTA CICLOS
      //var lCiclos = [
      //  { id: 1, seleccionado: false, lineaRoja: false, nCiclo: "32", piezas: "64/205", tiempoInyeccion: "3:79", fechaHora: "20-04-2021" },
      //  { id: 2, seleccionado: false, lineaRoja: false, nCiclo: "31", piezas: "62/205", tiempoInyeccion: "3:79", fechaHora: "20-04-2021" },
      //  { id: 3, seleccionado: false, lineaRoja: true, nCiclo: "30", piezas: "60/205", tiempoInyeccion: "3:79", fechaHora: "20-04-2021" },
      //  { id: 4, seleccionado: false, lineaRoja: false, nCiclo: "29", piezas: "58/205", tiempoInyeccion: "3:79", fechaHora: "20-04-2021" },
      //];

      //LISTA RECETAS
      // var lCiclos = datos.listaCiclos.table.reverse();
      var lCiclos = this.comprobarCicloRojo(datos.listaCiclos);

      //LES PONEMOS LA FECHA BIEN
      for (var i = 0; i < lCiclos.length; i++) {
        lCiclos[i].fechaini = new Date(lCiclos[i].fechaini);
        lCiclos[i].fechafin = new Date(lCiclos[i].fechaFin);
      }

      //LES PONEMOS EL TIEMPO DE CICLO
      // for (var i = 0; i < lCiclos.length; i++) {
      //   if (i == lCiclos.length - 1) { //es el ultimo ciclo
      //     var fechaActual: any = new Date();
      //     lCiclos[i].tiempoInyeccionCiclo = (fechaActual - lCiclos[i].fechaini) / 1000;
      //   } else { // no es el ultimo ciclo
      //     lCiclos[i].tiempoInyeccionCiclo = (lCiclos[i].fechafin - lCiclos[i].fechaini) / 1000;
      //   }
      // }

      //Metemos los cilos que no esten en la lista
      lCiclos.forEach(function (ciclo) {
        //Miramos a ver si el ciclo ya está metido en la lista
        var cicloExistente = this.listaCiclos.find(x => x.id == ciclo.id);
        if (cicloExistente == undefined) {
          this.listaCiclos.unshift(ciclo);
          //Si es el ultimo ciclo de la lista y es la primera vez que se cargan los ciclos => calculamos los datos de las pestañas
          if (this.listaCiclos.length == lCiclos.length && this.primeraVezListaCiclosCargada) {
            this.primeraVezListaCiclosCargada = false;
            this.cargarInformacion(this.listaCiclos[0]);
          }
        } else { //Si ya esta metida, le actualizamos el tiempoInyeccion
          cicloExistente.tiempoInyeccionCiclo = ciclo.tiempoInyeccionCiclo;
        }
      }, this);

      //Si ya teniamos metido algun ciclo que ya no esta, lo borramos
      this.listaCiclos.forEach(function (ciclo) {
        //Miramos a ver si el ciclo ya está metido en la lista
        var cicloExistente = lCiclos.find(x => x.id == ciclo.id);
        if (cicloExistente == undefined) this.listaCiclos = this.listaCiclos.filter(x => x.id != ciclo.id);
      }, this);

    });
    this.cargarDatosProcesos();
    this.cargarDatosMantenimientos();
    this.cargarDatosNotas();
  }

  comprobarCicloRojo(lCiclos) {
    var listaCiclos = lCiclos.table.reverse();
    var valoresProgramados = lCiclos.table1;
    listaCiclos.forEach(element => {
      var infOperacion = valoresProgramados.filter(f => f.idOperacion == element.idOperacion)[0];
      if (infOperacion == undefined) {
        infOperacion = {
          tiempoCerrarMolde: 0, tiempoCerrarMoldeTolerancia: 0
          , tiempoInyeccion: 0, tiempoInyeccionTolerancia: 0
          , tiempoPresionRemanente: 0, tiempoPresionRemanenteTolerancia: 0
          , tiempoDosificacionPlastificacion: 0, tiempoDosificacionPlastificacionTolerancia: 0
          , tiempoEnfriamiento: 0, tiempoEnfriamientoTolerancia: 0
          , tiempoAbrirMolde: 0, tiempoAbrirMoldeTolerancia: 0
          , tiempoCiclo: 0, tiempoCicloTolerancia: 0
          , presionInyeccionMax: 0, presionInyeccionMaxTolerancia: 0
          , ptoConmutacion: 0, ptoConmutacionTolerancia: 0
          , presionRemanente: 0, presionRemanenteTolerancia: 0
          , cojin: 0, cojinTolerancia: 0
          , carreraPlastificacion: 0, carreraPlastificacionTolerancia: 0
          , revolucionesPlastificacion: 0, revolucionesPlastificacionTolerancia: 0
          , presionServicioSCFenArranque: 0, presionServicioSCFenArranqueTolerancia: 0
          , dosificacionScf: 0, dosificacionScfTolerancia: 0
          , diferenciaPresionSCF: 0, diferenciaPresionSCFTolerancia: 0
        }
      }

      // Comprobar que los valores estan entre la tolerancia del programado
      if (!(parseFloat(element.tiempoCerrarMolde) >= (parseFloat(infOperacion.tiempoCerrarMolde) - parseFloat(infOperacion.tiempoCerrarMoldeTolerancia)) && parseFloat(element.tiempoCerrarMolde) <= (parseFloat(infOperacion.tiempoCerrarMolde) + parseFloat(infOperacion.tiempoCerrarMoldeTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.tiempoInyeccion) >= (parseFloat(infOperacion.tiempoInyeccion) - parseFloat(infOperacion.tiempoInyeccionTolerancia)) && parseFloat(element.tiempoInyeccion) <= (parseFloat(infOperacion.tiempoInyeccion) + parseFloat(infOperacion.tiempoInyeccionTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.tiempoPresionRemanente) >= (parseFloat(infOperacion.tiempoPresionRemanente) - parseFloat(infOperacion.tiempoPresionRemanenteTolerancia)) && parseFloat(element.tiempoPresionRemanente) <= (parseFloat(infOperacion.tiempoPresionRemanente) + parseFloat(infOperacion.tiempoPresionRemanenteTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.tiempoDosificacionPlastificacion) >= (parseFloat(infOperacion.tiempoDosificacionPlastificacion) - parseFloat(infOperacion.tiempoDosificacionPlastificacionTolerancia)) && parseFloat(element.tiempoDosificacionPlastificacion) <= (parseFloat(infOperacion.tiempoDosificacionPlastificacion) + parseFloat(infOperacion.tiempoDosificacionPlastificacionTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.tiempoEnfriamiento) >= (parseFloat(infOperacion.tiempoEnfriamiento) - parseFloat(infOperacion.tiempoEnfriamientoTolerancia)) && parseFloat(element.tiempoEnfriamiento) <= (parseFloat(infOperacion.tiempoEnfriamiento) + parseFloat(infOperacion.tiempoEnfriamientoTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.tiempoAbrirMolde) >= (parseFloat(infOperacion.tiempoAbrirMolde) - parseFloat(infOperacion.tiempoAbrirMoldeTolerancia)) && parseFloat(element.tiempoAbrirMolde) <= (parseFloat(infOperacion.tiempoAbrirMolde) + parseFloat(infOperacion.tiempoAbrirMoldeTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.tiempoCiclo) >= (parseFloat(infOperacion.tiempoCiclo) - parseFloat(infOperacion.tiempoCicloTolerancia)) && parseFloat(element.tiempoCiclo) <= (parseFloat(infOperacion.tiempoCiclo) + parseFloat(infOperacion.tiempoCicloTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.presionInyeccionMax) >= (parseFloat(infOperacion.presionInyeccionMax) - parseFloat(infOperacion.presionInyeccionMaxTolerancia)) && parseFloat(element.presionInyeccionMax) <= (parseFloat(infOperacion.presionInyeccionMax) + parseFloat(infOperacion.presionInyeccionMaxTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.ptoConmutacion) >= (parseFloat(infOperacion.ptoConmutacion) - parseFloat(infOperacion.ptoConmutacionTolerancia)) && parseFloat(element.ptoConmutacion) <= (parseFloat(infOperacion.ptoConmutacion) + parseFloat(infOperacion.ptoConmutacionTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.presionRemanente) >= (parseFloat(infOperacion.presionRemanente) - parseFloat(infOperacion.presionRemanenteTolerancia)) && parseFloat(element.presionRemanente) <= (parseFloat(infOperacion.presionRemanente) + parseFloat(infOperacion.presionRemanenteTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.cojin) >= (parseFloat(infOperacion.cojin) - parseFloat(infOperacion.cojinTolerancia)) && parseFloat(element.cojin) <= (parseFloat(infOperacion.cojin) + parseFloat(infOperacion.cojinTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.carreraPlastificacion) >= (parseFloat(infOperacion.carreraPlastificacion) - parseFloat(infOperacion.carreraPlastificacionTolerancia)) && parseFloat(element.carreraPlastificacion) <= (parseFloat(infOperacion.carreraPlastificacion) + parseFloat(infOperacion.carreraPlastificacionTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.revolucionesPlastificacion) >= (parseFloat(infOperacion.revolucionesPlastificacion) - parseFloat(infOperacion.revolucionesPlastificacionTolerancia)) && parseFloat(element.revolucionesPlastificacion) <= (parseFloat(infOperacion.revolucionesPlastificacion) + parseFloat(infOperacion.revolucionesPlastificacionTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.presionServicioSCFenArranque) >= (parseFloat(infOperacion.presionServicioSCFenArranque) - parseFloat(infOperacion.presionServicioSCFenArranqueTolerancia)) && parseFloat(element.presionServicioSCFenArranque) <= (parseFloat(infOperacion.presionServicioSCFenArranque) + parseFloat(infOperacion.presionServicioSCFenArranqueTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.dosificacionScf) >= (parseFloat(infOperacion.dosificacionScf) - parseFloat(infOperacion.dosificacionScfTolerancia)) && parseFloat(element.dosificacionScf) <= (parseFloat(infOperacion.dosificacionScf) + parseFloat(infOperacion.dosificacionScfTolerancia)))) element.lineaRoja = true;
      else if (!(parseFloat(element.diferenciaPresionSCF) >= (parseFloat(infOperacion.diferenciaPresionSCF) - parseFloat(infOperacion.diferenciaPresionSCFTolerancia)) && parseFloat(element.diferenciaPresionSCF) <= (parseFloat(infOperacion.diferenciaPresionSCF) + parseFloat(infOperacion.diferenciaPresionSCFTolerancia)))) element.lineaRoja = true;
    });
    return listaCiclos;
  }

  ahora: any;

  calcularTiempos() {
    if (this.fechaIni != null && this.fechaIni != undefined) {
      var dateAux = new Date(this.ahora);
      dateAux.setSeconds(dateAux.getSeconds() + 1);
      this.ahora = dateAux
      if (this.procesosTipo.toUpperCase() != 'APAGADA') {
        this.tiempo = (this.ahora - this.fecha) / 1000;
        if (this.tiempo < 0)
          this.tiempo = 0
        this.tiempoCorregido = this.corregirFormato(this.tiempo);
        if (this.tiempoLoteSegundos != -1) {
          this.tiempoLoteSegundos = this.tiempoLoteSegundos + 1;
          this.tiempoOperacion = this.corregirFormato(this.tiempoLoteSegundos);
        } else {
          this.tiempoOperacion = "--:--:--"
        }

        this.calcularDesviacion();
      } else {
        this.cargarTiempoApagado();
      }
    }
  }

  calcularTiempos2() {

    if (this.r1) {
      if (this.unicaEjecucionTiempos) {
        this.unicaEjecucionTiempos = false;
        this.centroMecanizadoService.get_Tiempos_Lote_Pieza_byId(this.idmaquina).subscribe(result => {
          var datosTiempo: any = result;
          var tieneTiempo: boolean = false;

          if (datosTiempo[0]?.tiempoLote != undefined && datosTiempo[0]?.tiempoUltimaPieza != undefined) {
            this.tiempoLoteSegundos = datosTiempo[0].tiempoLote;
          } else {
            this.tiempoLoteSegundos = -1;
          }
          //this.loadingDatosTiempo = false;
          setTimeout((d) => this.loadingDatosTiempo = false, 1000);
          this.unicaEjecucionTiempos = true;

          this.timeOutTiempos = setTimeout((d) => this.calcularTiempos2(), 60000);
        });
      }

    } else {
      setTimeout((d) => this.calcularTiempos2(), 1000);
    }

  }

  refreshData() {
    if (this.router.url == '/procesosinyectora/' + this.idmaquina) {
      this.cargarTodosLosDatos();
    } else {
      clearInterval(this.interval);
    }
  }

  refreshDataTiempos() {
    if (this.router.url == '/procesosinyectora/' + this.idmaquina) {
      this.calcularTiempos();
    } else {
      clearInterval(this.interval2);
    }
  }

  // cargarValoresGrafico se utiliza para que los valores del grafico se carguen solo la primera vez y no cada vez que se hace click en una receta
  cargarInformacion(ciclo) {
    this.loadingPestannas = true;

    this.listaCiclos.map((c) => c.seleccionado = false);

    ciclo.seleccionado = true;

    this.centroMecanizadoService.GetHmiInyectoraById(this.idmaquina).subscribe((result) => {
      this.cicloActual = result.ejecuciones
      this.of = this.cicloActual[0].of;
      this.cliente = this.cicloActual[0].cliente;
      this.nombrePieza = this.cicloActual[0].nombrePieza;
      this.referenciaPieza = this.cicloActual[0].referenciaPieza;
      if (this.cicloActual[0].of == undefined || this.cicloActual[0].of == "")
        this.realizadas = "";
      else
        this.realizadas = this.cicloActual[0].cuantasHechas + "/" + this.cicloActual[0].cantidad;

      this.nCavidades = this.cicloActual[0].nCavidades;
      this.material = this.cicloActual[0].material;
      this.refMaterial = this.cicloActual[0].refMaterial;
      this.refMolde = this.cicloActual[0].refMolde;
    });

    if (this.user.idDb == 1) {
      ciclo.id = 3587;
    }
    this.centroMecanizadoService.GetDatosCicloInyectoraById(this.idmaquina, ciclo.id, this.maquina.tipoMaquinaInyeccion).subscribe(
      (result) => {

        this.datosDB = result;

        this.operacionesService.getRecetasOperacion(this.idOperacion).subscribe(
          (json) => {
            this.jsonDB = json;
            this.clickCiclo(ciclo, false);

        });
      }
    );

  }

  clickCiclo(ciclo, click = true) {

    if (this.maquina.tipoMaquinaInyeccion == 2 && click) { // esto es necesario para que cuando sea tipo 2 se haga la consulta al clickar en un ciclo
      this.cargarInformacion(ciclo);
      return;
    }
    //CICLO
    if (this.listaCiclos.filter(f => f.id == ciclo.id).length > 0) {
      this.dCiclo = this.listaCiclos.filter(f => f.id == ciclo.id)[0];

      if (this.jsonDB.length == 0) {
        this.jsonDB = [{
          tiempoCerrarMolde: 0, tiempoCerrarMoldeTolerancia: 0
          , tiempoInyeccion: 0, tiempoInyeccionTolerancia: 0
          , tiempoPresionRemanente: 0, tiempoPresionRemanenteTolerancia: 0
          , tiempoDosificacionPlastificacion: 0, tiempoDosificacionPlastificacionTolerancia: 0
          , tiempoEnfriamiento: 0, tiempoEnfriamientoTolerancia: 0
          , tiempoAbrirMolde: 0, tiempoAbrirMoldeTolerancia: 0
          , tiempoCiclo: 0, tiempoCicloTolerancia: 0
          , presionInyeccionMax: 0, presionInyeccionMaxTolerancia: 0
          , ptoConmutacion: 0, ptoConmutacionTolerancia: 0
          , presionRemanente: 0, presionRemanenteTolerancia: 0
          , cojin: 0, cojinTolerancia: 0
          , carreraPlastificacion: 0, carreraPlastificacionTolerancia: 0
          , revolucionesPlastificacion: 0, revolucionesPlastificacionTolerancia: 0
          , presionServicioSCFenArranque: 0, presionServicioSCFenArranqueTolerancia: 0
          , dosificacionScf: 0, dosificacionScfTolerancia: 0
          , diferenciaPresionSCF: 0, diferenciaPresionSCFTolerancia: 0
        }]
      }

      this.listaTooltipSeleccionados = [4,6,7,10]
      //LISTA VALORES TECNOLOGICOS
      this.listaValoresTecnologicos = [
        { index: 0, visible: this.conf.contrapresion, nombre: this.translateService.instant("tiempoCerrarMolde"), dim: "seg.", toler: this.jsonDB[0].tiempoCerrarMoldeTolerancia, programado: this.jsonDB[0].tiempoCerrarMolde, actual: this.dCiclo.tiempoCerrarMolde
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 1, visible: this.conf.tiempoInyeccion, nombre: this.translateService.instant("tiempoInyeccion"), dim: "seg.", toler: this.jsonDB[0].tiempoInyeccionTolerancia, programado: this.jsonDB[0].tiempoInyeccion, actual: this.dCiclo.tiempoInyeccion
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 2, visible: this.conf.tiempoPresionRemanente, nombre: this.translateService.instant("tiempoPresionRemanente"), dim: "seg.", toler: this.jsonDB[0].tiempoPresionRemanenteTolerancia, programado: this.jsonDB[0].tiempoPresionRemanente, actual: this.dCiclo.tiempoPresionRemanente
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 3, visible: this.conf.tiempoDosificacionPlastificacion, nombre: this.translateService.instant("tiempoDosificPlastificacion"), dim: "seg.", toler: this.jsonDB[0].tiempoDosificacionPlastificacionTolerancia, programado: this.jsonDB[0].tiempoDosificacionPlastificacion, actual: this.dCiclo.tiempoDosificacionPlastificacion
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 4, visible: this.conf.tiempoEnfriamiento, nombre: this.translateService.instant("tiempoEnfriamiento"), dim: "seg.", toler: this.jsonDB[0].tiempoEnfriamientoTolerancia, programado: this.jsonDB[0].tiempoEnfriamiento, actual: this.dCiclo.tiempoEnfriamiento
              , estaEnGrafico: 4, seleccionadoLeyenda: true, color: this.colores[0].color, colorClass: this.colores[0].css, unidad: "", valoresMomentaneo: []},
        { index: 5, visible: this.conf.paradaDosificado, nombre: this.translateService.instant("tiempoAbrirMolde"), dim: "seg.", toler: this.jsonDB[0].tiempoAbrirMoldeTolerancia, programado: this.jsonDB[0].tiempoAbrirMolde, actual: this.dCiclo.tiempoAbrirMolde
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 6, visible: this.conf.tiempoCiclo, nombre: this.translateService.instant("tiempoCiclo"), dim: "seg.", toler: this.jsonDB[0].tiempoCicloTolerancia, programado: this.jsonDB[0].tiempoCiclo, actual: this.dCiclo.tiempoCiclo
              , estaEnGrafico: 1, seleccionadoLeyenda: true, color: this.colores[1].color, colorClass: this.colores[1].css, unidad: "", valoresMomentaneo: []},
        { index: 7, visible: this.conf.presionInyeccionMax, nombre: this.translateService.instant("presInyMax"), dim: "bar.", toler: this.jsonDB[0].presionInyeccionMaxTolerancia, programado: this.jsonDB[0].presionInyeccionMax, actual: this.dCiclo.presionInyeccionMax
              , estaEnGrafico: 2, seleccionadoLeyenda: true, color: this.colores[2].color, colorClass: this.colores[2].css, unidad: "", valoresMomentaneo: []},
        { index: 8, visible: this.conf.ptoConmutacion, nombre: this.translateService.instant("ptoConmutacion"), dim: "mm.", toler: this.jsonDB[0].ptoConmutacionTolerancia, programado: this.jsonDB[0].ptoConmutacion, actual: this.dCiclo.ptoConmutacion
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 9, visible: this.conf.presionRemanente, nombre: this.translateService.instant("presRemanente"), dim: "bar.", toler: this.jsonDB[0].presionRemanenteTolerancia, programado: this.jsonDB[0].presionRemanente, actual: this.dCiclo.presionRemanente
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 10, visible: this.conf.cojin, nombre: this.translateService.instant("cojin"), dim: "mm.", toler: this.jsonDB[0].cojinTolerancia, programado: this.jsonDB[0].cojin, actual: this.dCiclo.cojin
              , estaEnGrafico: 3, seleccionadoLeyenda: true, color: this.colores[3].color, colorClass: this.colores[3].css, unidad: "", valoresMomentaneo: []},
        { index: 11, visible: this.conf.retrocesoHusillo, nombre: this.translateService.instant("carreraPlastificacion"), dim: "", toler: this.jsonDB[0].carreraPlastificacionTolerancia, programado: this.jsonDB[0].carreraPlastificacion, actual: this.dCiclo.carreraPlastificacion
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 12, visible: this.conf.paradaMolde, nombre: this.translateService.instant("revolucionesPlastificacion"), dim: "U/min", toler: this.jsonDB[0].revolucionesPlastificacionTolerancia, programado: this.jsonDB[0].revolucionesPlastificacion, actual: this.dCiclo.revolucionesPlastificacion
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 13, visible: this.conf.revolucionesHusillo, nombre: this.translateService.instant("presionServicioScf"), dim: "bar.", toler: this.jsonDB[0].presionServicioSCFenArranqueTolerancia, programado: this.jsonDB[0].presionServicioSCFenArranque, actual: this.dCiclo.presionServicioSCFenArranque
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 14, visible: this.conf.fuerzaDeCierre, nombre: this.translateService.instant("dosificacionScf"), dim: "", toler: this.jsonDB[0].dosificacionScfTolerancia, programado: this.jsonDB[0].dosificacionScf, actual: this.dCiclo.dosificacionScf
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},
        { index: 15, visible: this.conf.temperaturaMolde, nombre: this.translateService.instant("diferenciaPresionScf"), dim: "bar.", toler: this.jsonDB[0].diferenciaPresionSCFTolerancia, programado: this.jsonDB[0].diferenciaPresionSCF, actual: this.dCiclo.diferenciaPresionSCF
              , estaEnGrafico: -1, seleccionadoLeyenda: false, color: '#000000', colorClass: '', unidad: "", valoresMomentaneo: []},

        // { nombre: this.translateService.instant("fuerzaDeCierre"), dim: "KN", toler: 200, programado: this.dCiclo.fuerzaDeCierre + 100, actual: this.dCiclo.fuerzaDeCierre },
        // { nombre: this.translateService.instant("temperaturaMolde"), dim: "ºC", toler: 5, programado: 20, actual: this.dCiclo.temperaturaMolde },
      ];
      this.listaValoresTecnologicos.forEach(function (valor) {
        if (parseFloat(valor.actual) >= (parseFloat(valor.programado) - parseFloat(valor.toler)) && parseFloat(valor.actual) <= (parseFloat(valor.programado) + parseFloat(valor.toler))) valor.lineaRoja = false;
        else valor.lineaRoja = true;
      }, this);
      console.log(this.listaValoresTecnologicos);
      this.cargarVariablesVisibles(this.conf);
      //VALORES T CAMARA CALIENTE, T HUSILLO y CASCADA
      // this.valorTempCamaraCaliente = this.dCiclo.temperaturaCamaraCaliente;;
      // this.tolerTempCamaraCaliente = 0;
      // this.valorTempHusillo = this.dCiclo.temperaturaHusillo;
      // this.tolerTempHusillo = 0;
      // this.valorCascada = 5;
      // this.tolerCascada = 0;

      // this.alertaTempCamaraCaliente = false;
      // this.alertaTempHusillo = false;
      // this.alertaCascada = false;

      //LISTA T CAMARA CALIENTE
      //Controlar que el tamaño es el indicado en la configuracion
      var datosCamaraCaliente = this.dCiclo.tCamaraCaliente.split(",");
      this.listaTCamaraCaliente = [];
      if (this.conf.tempCamaraCaliente_maxZonas > datosCamaraCaliente.length) {
        for (var i = 0; i < this.conf.tempCamaraCaliente_maxZonas; i++) {
          if (i < datosCamaraCaliente.length) {
            this.listaTCamaraCaliente.push({ lineaRoja: false, zona: "Zona " + (i + 1), toler: 20, actual: datosCamaraCaliente[i] });
          } else {
            this.listaTCamaraCaliente.push({ lineaRoja: false, zona: "Zona " + (i + 1), toler: 20, actual: "" });
          }
        }
      } else {
        for (var i = 0; i < this.conf.tempCamaraCaliente_maxZonas; i++) {
          this.listaTCamaraCaliente.push({ lineaRoja: false, zona: "Zona " + (i + 1), toler: 0, actual: datosCamaraCaliente[i] });
        }
      }

      //LISTA T HUSILLO
      var datosHusillo = this.dCiclo.tHusillo.split(",");
      this.listaTHusillo = [];
      if (this.conf.tempHusillo_maxZonas > datosHusillo.length) {
        for (var i = 0; i < this.conf.tempHusillo_maxZonas; i++) {
          if (i < datosHusillo.length) {
            if (i == 0) {
              this.listaTHusillo.push({ lineaRoja: false, zona: "Brida", toler: 20, actual: datosHusillo[i] });
            }
            else {
              this.listaTHusillo.push({ lineaRoja: false, zona: "Zona " + i, toler: 20, actual: datosHusillo[i] });
            }
          } else {
            if (i == 0) {
              this.listaTHusillo.push({ lineaRoja: false, zona: "Brida", toler: 20, actual: "" });
            }
            else {
              this.listaTHusillo.push({ lineaRoja: false, zona: "Zona " + i, toler: 20, actual: "" });
            }
          }

        }
      } else {
        for (var i = 0; i < this.conf.tempHusillo_maxZonas; i++) {
          if (i == 0) {
            this.listaTHusillo.push({ lineaRoja: false, zona: "Brida", toler: 20, actual: datosHusillo[i] });
          }
          else {
            this.listaTHusillo.push({ lineaRoja: false, zona: "Zona " + i, toler: 20, actual: datosHusillo[i] });
          }
        }

      }


      //LISTA CASCADA
      var datosCascadaAbrir1 = this.dCiclo.cascadaAbrir1.split(",");
      var datosCascadaAbrir2 = this.dCiclo.cascadaAbrir2.split(",");
      var datosCascadaCerrar1 = this.dCiclo.cascadaCerrar1.split(",");
      var datosCascadaCerrar2 = this.dCiclo.cascadaCerrar2.split(",");

      // 5 lerro hauek gero ezabatu
      datosCascadaAbrir1 = [2.5, 525, 44, 525, 525, 525];
      datosCascadaCerrar1 = [2, 525, 40, 525, 2, 525];
      datosCascadaAbrir2 = [0.6, 1.09, 0, 1.09, 0, 1.09];
      datosCascadaCerrar2 = [1.1, 1.1, 1.1, 1.1, 1.1, 1.1];
      this.conf.cascada_maxZonas = 6;

      this.listaCascada = [];
      if (this.conf.cascada_maxZonas > datosCascadaAbrir1.length) {
        for (var i = 0; i < this.conf.cascada_maxZonas; i++) {
          // if (i < datosCascadaAbrir1.length){
          this.listaCascada.push({ lineaRoja: false, zona: (i + 1), abrir: datosCascadaAbrir1[i], cerrar: datosCascadaCerrar1[i], abrir2: datosCascadaAbrir2[i], cerrar2: datosCascadaCerrar2[i] });
          // }else{
          //   this.listaCascada.push({ lineaRoja: false, zona: (i + 1), abrir: "", cerrar: "", abrir2: "", cerrar2: "" });
          // }
        }
      } else {
        for (var i = 0; i < this.conf.cascada_maxZonas; i++) {
          this.listaCascada.push({ lineaRoja: false, zona: (i + 1), abrir: datosCascadaAbrir1[i], cerrar: datosCascadaCerrar1[i], abrir2: datosCascadaAbrir2[i], cerrar2: datosCascadaCerrar2[i] });
        }
      }
    }
    else {

      this.listaTCamaraCaliente = [];
      this.listaTHusillo = [];
      this.listaCascada = [];

    }

    //GRAFICO MULTIEJE
    if (this.maquina.tipoMaquinaInyeccion == 1) {
      this.datosGraficoMultieje = {
        fechas: [],
        tiempoCerrarMolde : [], 
        tiempoInyeccion: [],
        tiempoPresionRemanente: [],
        tiempoDosificacionPlastificacion: [],
        tiempoEnfriamiento: [],
        tiempoAbrirMolde: [],
        tiempoCiclo: [],
        presionInyeccionMax: [],
        ptoConmutacion: [],
        presionRemanente: [],
        cojin: [],
        carreraPlastificacion: [],
        revolucionesPlastificacion: [],
        presionServicioSCFenArranque: [],
        dosificacionScf: [],
        diferenciaPresionSCF: []
      }
    } else {
      this.datosGraficoMultieje = {
        fechas: [],
        presionInyeccion: [],
        velocidadInyeccion: [],
        posicionHusillo: [],
        revolucionesHusillo: [],
      }
    }

    if (this.datosDB.dtValoresCiclo != null) {
      var listaGrafico = [];
      if (this.maquina.tipoMaquinaInyeccion == 1) listaGrafico = this.myFunctions.copy(this.listaCiclos);
      else listaGrafico = this.myFunctions.copy(this.datosDB.dtValoresCiclo);
      listaGrafico.forEach(function (row) {

        // row.fecha_date = this.myFunctions.sqlToJsDateT(row.fecha);
        row.fecha_date = (new Date(row.fechaini)); //new Date(row.fecha).getMilliseconds()
        //row.fecha_time = this.myFunctions.sqlToJsDateT(row.fecha).getTime();

        this.datosGraficoMultieje.fechas.push(row.fecha_date);
        if (this.maquina.tipoMaquinaInyeccion == 1) {
          this.datosGraficoMultieje.tiempoCerrarMolde.push(row.tiempoCerrarMolde);
          this.datosGraficoMultieje.tiempoInyeccion.push(row.tiempoInyeccion);
          this.datosGraficoMultieje.tiempoPresionRemanente.push(row.tiempoPresionRemanente);
          this.datosGraficoMultieje.tiempoDosificacionPlastificacion.push(row.tiempoDosificacionPlastificacion);
          this.datosGraficoMultieje.tiempoEnfriamiento.push(row.tiempoEnfriamiento);
          this.datosGraficoMultieje.tiempoAbrirMolde.push(row.tiempoAbrirMolde);
          this.datosGraficoMultieje.tiempoCiclo.push(row.tiempoCiclo);
          this.datosGraficoMultieje.presionInyeccionMax.push(row.presionInyeccionMax);
          this.datosGraficoMultieje.ptoConmutacion.push(row.ptoConmutacion);
          this.datosGraficoMultieje.presionRemanente.push(row.presionRemanente);
          this.datosGraficoMultieje.cojin.push(row.cojin);
          this.datosGraficoMultieje.carreraPlastificacion.push(row.carreraPlastificacion);
          this.datosGraficoMultieje.revolucionesPlastificacion.push(row.revolucionesPlastificacion);
          this.datosGraficoMultieje.presionServicioSCFenArranque.push(row.presionServicioSCFenArranque);
          this.datosGraficoMultieje.dosificacionScf.push(row.dosificacionScf);
          this.datosGraficoMultieje.diferenciaPresionSCF.push(row.diferenciaPresionSCF);
        } else {
          this.datosGraficoMultieje.presionInyeccion.push(row.presionInyeccion);
          this.datosGraficoMultieje.velocidadInyeccion.push(row.velocidadInyeccion);
          this.datosGraficoMultieje.posicionHusillo.push(row.posicionHusillo);
          this.datosGraficoMultieje.revolucionesHusillo.push(row.revolucionesHusillo);
        }
      }, this);
    }

    this.listaTooltip = [
      { nombre: "presionInyeccion", estaEnGrafico: 1, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[0], color: this.colores[0].color, unidad: "", valoresMomentaneo: [] },
      { nombre: "velocidadInyeccion", estaEnGrafico: 2, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[1], color: this.colores[1].color, unidad: "", valoresMomentaneo: [] },
      { nombre: "posicionHusillo", estaEnGrafico: 3, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[2], color: this.colores[2].color, unidad: "", valoresMomentaneo: [] },
      { nombre: "revolucionesHusillo", estaEnGrafico: 4, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[3], color: this.colores[3].color, unidad: "", valoresMomentaneo: [] }
      // { nombre: "tiempoInyeccion", estaEnGrafico: 5, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[4], color: this.colores[4], unidad: "", valoresMomentaneo: [] },
      // { nombre: "tiempoPresionRemanente", estaEnGrafico: 6, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[5], color: this.colores[5], unidad: "", valoresMomentaneo: [] },
      // { nombre: "tiempoPlastificacion", estaEnGrafico: 7, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[6], color: this.colores[6], unidad: "", valoresMomentaneo: [] },
      // { nombre: "cascada1", estaEnGrafico: 8, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[7], color: this.colores[7], unidad: "", valoresMomentaneo: [] },
      // { nombre: "cascada2", estaEnGrafico: 9, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[8], color: this.colores[8], unidad: "", valoresMomentaneo: [] },
      // { nombre: "cascada3", estaEnGrafico: 10, seleccionadoLeyenda: this.mostrarGraficosPorLeyenda[9], color: this.colores[9], unidad: "", valoresMomentaneo: [] },
    ];

    this.fechaInicio = this.datosGraficoMultieje.fechas[0];
    this.fechaFin = this.datosGraficoMultieje.fechas[this.datosGraficoMultieje.fechas.length - 1];
    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;

    this.fechaIniCicloSeleccionado = ciclo.fechaini;
    this.fechaFinCicloSeleccionado = ciclo.fechafin;

    this.pintarGraficoMultiEje();
    this.cargarGraficosTodos();

    this.loadingPestannas = false;
  }

  RedirigirRendimiento() {
    this.router.navigate(["informerendimiento/" + this.idmaquina]);
  }

  //GRAFICOS
  aplicarZoomEnGrafico() {
    this.cargarGraficosTodos();
  }

  onClickMostrarTodos() {
    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;
    this.cargarGraficosTodos();
  }

  clicklLeyenda(campo) {
    if (campo.seleccionadoLeyenda) {
      campo.seleccionadoLeyenda = false;
      this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = false;
    } else {
      campo.seleccionadoLeyenda = true;
      this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = true;
    }
    this.cargarGraficosTodos();
  }

  cargarGraficosTodos() {

    var fechas: any = [];
    var tiempoCerrarMolde  : any = [];
    var tiempoInyeccion: any = [];
    var tiempoPresionRemanente: any = [];
    var tiempoDosificacionPlastificacion: any = [];
    var tiempoEnfriamiento: any = [];
    var tiempoAbrirMolde: any = [];
    var tiempoCiclo: any = [];
    var presionInyeccionMax: any = [];
    var ptoConmutacion: any = [];
    var presionRemanente: any = [];
    var cojin: any = [];
    var carreraPlastificacion: any = [];
    var revolucionesPlastificacion: any = [];
    var presionServicioSCFenArranque: any = [];
    var dosificacionScf: any = [];
    var diferenciaPresionSCF: any = [];

    var presionInyeccion: any = [];
    var velocidadInyeccion: any = [];
    var posicionHusillo: any = [];
    var revolucionesHusillo: any = [];

    var i;

    for (i = 0; i < this.datosGraficoMultieje.fechas.length; i++) {
      if ((this.datosGraficoMultieje.fechas[i + 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i - 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] >= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i] >= this.fechaFinZoom)) {

        fechas.push(this.datosGraficoMultieje.fechas[i]);

        if (this.maquina.tipoMaquinaInyeccion == 1) {
          tiempoCerrarMolde.push(this.datosGraficoMultieje.tiempoCerrarMolde[i]);
          tiempoInyeccion.push(this.datosGraficoMultieje.tiempoInyeccion[i]);
          tiempoPresionRemanente.push(this.datosGraficoMultieje.tiempoPresionRemanente[i]);
          tiempoDosificacionPlastificacion.push(this.datosGraficoMultieje.tiempoDosificacionPlastificacion[i]);
          tiempoEnfriamiento.push(this.datosGraficoMultieje.tiempoEnfriamiento[i]);
          tiempoAbrirMolde.push(this.datosGraficoMultieje.tiempoAbrirMolde[i]);
          tiempoCiclo.push(this.datosGraficoMultieje.tiempoCiclo[i]);
          presionInyeccionMax.push(this.datosGraficoMultieje.presionInyeccionMax[i]);
          ptoConmutacion.push(this.datosGraficoMultieje.ptoConmutacion[i]);
          presionRemanente.push(this.datosGraficoMultieje.presionRemanente[i]);
          cojin.push(this.datosGraficoMultieje.cojin[i]);
          carreraPlastificacion.push(this.datosGraficoMultieje.carreraPlastificacion[i]);
          revolucionesPlastificacion.push(this.datosGraficoMultieje.revolucionesPlastificacion[i]);
          presionServicioSCFenArranque.push(this.datosGraficoMultieje.presionServicioSCFenArranque[i]);
          dosificacionScf.push(this.datosGraficoMultieje.dosificacionScf[i]);
          diferenciaPresionSCF.push(this.datosGraficoMultieje.diferenciaPresionSCF[i]);
        } else {
          presionInyeccion.push(this.datosGraficoMultieje.presionInyeccion[i]);
          velocidadInyeccion.push(this.datosGraficoMultieje.velocidadInyeccion[i]);
          posicionHusillo.push(this.datosGraficoMultieje.posicionHusillo[i]);
          revolucionesHusillo.push(this.datosGraficoMultieje.revolucionesHusillo[i]);
        }
      }
    }

    //beheko hau kendu diot, bestela ez ditu milisegunduen datuak erakusten...
    // if (fechas.length > 300) {
    //   fechas = this.aplanarArray(fechas, 300);

    //   tiempoCerrarMolde = this.aplanarArray(tiempoCerrarMolde, 300);
    //   tiempoInyeccion = this.aplanarArray(tiempoInyeccion, 300);
    //   tiempoPresionRemanente = this.aplanarArray(tiempoPresionRemanente, 300);
    //   tiempoDosificacionPlastificacion = this.aplanarArray(tiempoDosificacionPlastificacion, 300);
    //   tiempoEnfriamiento = this.aplanarArray(tiempoEnfriamiento, 300);
    //   tiempoAbrirMolde = this.aplanarArray(tiempoAbrirMolde, 300);
    //   tiempoCiclo = this.aplanarArray(tiempoCiclo, 300);
    //   presionInyeccionMax = this.aplanarArray(presionInyeccionMax, 300);
    //   ptoConmutacion = this.aplanarArray(ptoConmutacion, 300);
    //   presionRemanente = this.aplanarArray(presionRemanente, 300);
    //   cojin = this.aplanarArray(cojin, 300);
    //   carreraPlastificacion = this.aplanarArray(carreraPlastificacion, 300);
    //   revolucionesPlastificacion = this.aplanarArray(revolucionesPlastificacion, 300);
    //   presionServicioSCFenArranque = this.aplanarArray(presionServicioSCFenArranque, 300);
    //   dosificacionScf = this.aplanarArray(dosificacionScf, 300);
    //   diferenciaPresionSCF = this.aplanarArray(diferenciaPresionSCF, 300);
    // }
    this.arrayFechas = fechas;

    var c1: boolean = this.fechaInicioZoom < fechas[0];
    var c2: boolean = this.fechaFinZoom > fechas[fechas.length - 1];

    //#region UNSHIFT Y SHIFT LOS DATOS
    if (c1) fechas.unshift(this.fechaInicioZoom);
    fechas.unshift('x');
    if (c2) fechas.push(this.fechaFinZoom);

    if (this.maquina.tipoMaquinaInyeccion == 1) {
      if (c1) tiempoCerrarMolde.unshift(null);
      tiempoCerrarMolde.unshift('valor');
      if (c2) tiempoCerrarMolde.push(null);
      
      if (c1) tiempoInyeccion.unshift(null);
      tiempoInyeccion.unshift('valor');
      if (c2) tiempoInyeccion.push(null);
      
      if (c1) tiempoPresionRemanente.unshift(null);
      tiempoPresionRemanente.unshift('valor');
      if (c2) tiempoPresionRemanente.push(null);
      
      if (c1) tiempoDosificacionPlastificacion.unshift(null);
      tiempoDosificacionPlastificacion.unshift('valor');
      if (c2) tiempoDosificacionPlastificacion.push(null);
      
      if (c1) tiempoEnfriamiento.unshift(null);
      tiempoEnfriamiento.unshift('valor');
      if (c2) tiempoEnfriamiento.push(null);
      
      if (c1) tiempoAbrirMolde.unshift(null);
      tiempoAbrirMolde.unshift('valor');
      if (c2) tiempoAbrirMolde.push(null);
      
      if (c1) tiempoCiclo.unshift(null);
      tiempoCiclo.unshift('valor');
      if (c2) tiempoCiclo.push(null);
      
      if (c1) presionInyeccionMax.unshift(null);
      presionInyeccionMax.unshift('valor');
      if (c2) presionInyeccionMax.push(null);
      
      if (c1) ptoConmutacion.unshift(null);
      ptoConmutacion.unshift('valor');
      if (c2) ptoConmutacion.push(null);
      
      if (c1) presionRemanente.unshift(null);
      presionRemanente.unshift('valor');
      if (c2) presionRemanente.push(null);
      
      if (c1) cojin.unshift(null);
      cojin.unshift('valor');
      if (c2) cojin.push(null);
      
      if (c1) carreraPlastificacion.unshift(null);
      carreraPlastificacion.unshift('valor');
      if (c2) carreraPlastificacion.push(null);
      
      if (c1) revolucionesPlastificacion.unshift(null);
      revolucionesPlastificacion.unshift('valor');
      if (c2) revolucionesPlastificacion.push(null);
      
      if (c1) presionServicioSCFenArranque.unshift(null);
      presionServicioSCFenArranque.unshift('valor');
      if (c2) presionServicioSCFenArranque.push(null);
      
      if (c1) dosificacionScf.unshift(null);
      dosificacionScf.unshift('valor');
      if (c2) dosificacionScf.push(null);
      
      if (c1) diferenciaPresionSCF.unshift(null);
      diferenciaPresionSCF.unshift('valor');
      if (c2) diferenciaPresionSCF.push(null);
    } else {
      if (c1) presionInyeccion.unshift(null);
      presionInyeccion.unshift('valor');
      if (c2) presionInyeccion.push(null);
      
      if (c1) velocidadInyeccion.unshift(null);
      velocidadInyeccion.unshift('valor');
      if (c2) velocidadInyeccion.push(null);
      
      if (c1) posicionHusillo.unshift(null);
      posicionHusillo.unshift('valor');
      if (c2) posicionHusillo.push(null);
      
      if (c1) revolucionesHusillo.unshift(null);
      revolucionesHusillo.unshift('valor');
      if (c2) revolucionesHusillo.push(null);
    }
    //#endregion

    //Tooltip
    if (this.maquina.tipoMaquinaInyeccion == 1) {
      this.listaValoresTecnologicos.forEach(
        function (campo) {
          if (campo.nombre == this.translateService.instant("tiempoCerrarMolde")) campo.valoresMomentaneo = tiempoCerrarMolde ;
          if (campo.nombre == this.translateService.instant("tiempoInyeccion")) campo.valoresMomentaneo = tiempoInyeccion;
          if (campo.nombre == this.translateService.instant("tiempoPresionRemanente")) campo.valoresMomentaneo = tiempoPresionRemanente;
          if (campo.nombre == this.translateService.instant("tiempoDosificPlastificacion")) campo.valoresMomentaneo = tiempoDosificacionPlastificacion;
          if (campo.nombre == this.translateService.instant("tiempoEnfriamiento")) campo.valoresMomentaneo = tiempoEnfriamiento;
          if (campo.nombre == this.translateService.instant("tiempoAbrirMolde")) campo.valoresMomentaneo = tiempoAbrirMolde;
          if (campo.nombre == this.translateService.instant("tiempoCiclo")) campo.valoresMomentaneo = tiempoCiclo;
          if (campo.nombre == this.translateService.instant("presInyMax")) campo.valoresMomentaneo = presionInyeccionMax;
          if (campo.nombre == this.translateService.instant("ptoConmutacion")) campo.valoresMomentaneo = ptoConmutacion;
          if (campo.nombre == this.translateService.instant("presRemanente")) campo.valoresMomentaneo = presionRemanente;
          if (campo.nombre == this.translateService.instant("cojin")) campo.valoresMomentaneo = cojin;
          if (campo.nombre == this.translateService.instant("carreraPlastificacion")) campo.valoresMomentaneo = carreraPlastificacion;
          if (campo.nombre == this.translateService.instant("revolucionesPlastificacion")) campo.valoresMomentaneo = revolucionesPlastificacion;
          if (campo.nombre == this.translateService.instant("presionServicioScf")) campo.valoresMomentaneo = presionServicioSCFenArranque;
          if (campo.nombre == this.translateService.instant("dosificacionScf")) campo.valoresMomentaneo = dosificacionScf;
          if (campo.nombre == this.translateService.instant("diferenciaPresionScf")) campo.valoresMomentaneo = diferenciaPresionSCF;
        }, this);
    } else {
      this.listaTooltip.forEach(
        function (campo) {
          if (campo.nombre == "presionInyeccion") campo.valoresMomentaneo = presionInyeccion;
          if (campo.nombre == "velocidadInyeccion") campo.valoresMomentaneo = velocidadInyeccion;
          if (campo.nombre == "posicionHusillo") campo.valoresMomentaneo = posicionHusillo;
          if (campo.nombre == "revolucionesHusillo") campo.valoresMomentaneo = revolucionesHusillo;
          // if (campo.nombre == "tiempoInyeccion") campo.valoresMomentaneo = tiempoInyeccion;
          // if (campo.nombre == "tiempoPresion") campo.valoresMomentaneo = tiempoPresion;
          // if (campo.nombre == "tiempoPlastificacion") campo.valoresMomentaneo = tiempoPlastificacion;
  
          // if (campo.nombre == "cascada1") campo.valoresMomentaneo = cascada1;
          // if (campo.nombre == "cascada2") campo.valoresMomentaneo = cascada2;
          // if (campo.nombre == "cascada3") campo.valoresMomentaneo = cascada3;
          // if (campo.nombre == "cascada4") campo.valoresMomentaneo = cascada4;
          // if (campo.nombre == "cascada5") campo.valoresMomentaneo = cascada5;
        }, this);
    }

    var valoresGrafico1;
    var valoresGrafico2;
    var valoresGrafico3;
    var valoresGrafico4;
    if (this.maquina.tipoMaquinaInyeccion == 1) {
      valoresGrafico1 = {valoresMomentaneo: [], color: '#ffffff'};
      if (this.listaTooltipSeleccionados.length > 0) valoresGrafico1 = this.listaValoresTecnologicos[this.listaTooltipSeleccionados[0]];
      valoresGrafico2 = {valoresMomentaneo: [], color: '#ffffff'};
      if (this.listaTooltipSeleccionados.length > 1) valoresGrafico2 = this.listaValoresTecnologicos[this.listaTooltipSeleccionados[1]];
      valoresGrafico3 = {valoresMomentaneo: [], color: '#ffffff'};
      if (this.listaTooltipSeleccionados.length > 2) valoresGrafico3 = this.listaValoresTecnologicos[this.listaTooltipSeleccionados[2]];
      valoresGrafico4 = {valoresMomentaneo: [], color: '#ffffff'};
      if (this.listaTooltipSeleccionados.length > 3) valoresGrafico4 = this.listaValoresTecnologicos[this.listaTooltipSeleccionados[3]];
    } else {
      valoresGrafico1 = { valoresMomentaneo: presionInyeccion, color: this.colores[0].color};
      valoresGrafico2 = { valoresMomentaneo: velocidadInyeccion, color: this.colores[1].color};
      valoresGrafico3 = { valoresMomentaneo: posicionHusillo, color: this.colores[2].color};
      valoresGrafico4 = { valoresMomentaneo: revolucionesHusillo, color: this.colores[3].color};
    }

    // Grafico 1
    this.grafico1.load({ columns: [fechas, valoresGrafico1.valoresMomentaneo] });
    d3.selectAll('#grafico1_hmiIny .c3-axis-y line').style("stroke", valoresGrafico1.color);
    d3.selectAll('#grafico1_hmiIny .c3-axis-y path').style("stroke", valoresGrafico1.color);
    // d3.selectAll('#grafico1_hmiIny .c3-axis-y-label').text("PRUEBA BAT");
    d3.selectAll('#grafico1_hmiIny .c3-axis-y text').style("fill", valoresGrafico1.color);
    d3.selectAll('#grafico1_hmiIny .c3-axis-y path').style("stroke-width", "1.3px");
    d3.selectAll('#grafico1_hmiIny .c3-line').style("stroke-width", "1.3px");
    d3.selectAll('#grafico1_hmiIny .c3-axis-y tspan').style("fill", valoresGrafico1.color);
    this.grafico1.data.colors({ valor: d3.rgb(valoresGrafico1.color) });

    // Grafico 2
    this.grafico2.load({ columns: [fechas, valoresGrafico2.valoresMomentaneo] });
    d3.selectAll('#grafico2_hmiIny .c3-axis-y line').style("stroke", valoresGrafico2.color);
    d3.selectAll('#grafico2_hmiIny .c3-axis-y path').style("stroke", valoresGrafico2.color);
    d3.selectAll('#grafico2_hmiIny .c3-axis-y-label').text("PRUEBA BI");
    d3.selectAll('#grafico2_hmiIny .c3-axis-y text').style("fill", valoresGrafico2.color);
    d3.selectAll('#grafico2_hmiIny .c3-axis-y path').style("stroke-width", "1.3px");
    d3.selectAll('#grafico2_hmiIny .c3-line').style("stroke-width", "1.3px");
    d3.selectAll('#grafico2_hmiIny .c3-axis-y tspan').style("fill", valoresGrafico2.color);
    this.grafico2.data.colors({ valor: d3.rgb(valoresGrafico2.color) });

    // Grafico 3
    this.grafico3.load({ columns: [fechas, valoresGrafico3.valoresMomentaneo] });
    d3.selectAll('#grafico3_hmiIny .c3-axis-y2 line').style("stroke", valoresGrafico3.color);
    d3.selectAll('#grafico3_hmiIny .c3-axis-y2 path').style("stroke", valoresGrafico3.color);
    d3.selectAll('#grafico3_hmiIny .c3-axis-y2-label').text("PRUEBA HIRU");
    d3.selectAll('#grafico3_hmiIny .c3-axis-y2 text').style("fill", valoresGrafico3.color);
    d3.selectAll('#grafico3_hmiIny .c3-axis-y2 path').style("stroke-width", "1.3px");
    d3.selectAll('#grafico3_hmiIny .c3-line').style("stroke-width", "1.3px");
    d3.selectAll('#grafico3_hmiIny .c3-axis-y2 tspan').style("fill", valoresGrafico3.color);
    this.grafico3.data.colors({ valor: d3.rgb(valoresGrafico3.color) });

    // Grafico 4
    this.grafico4.load({ columns: [fechas, valoresGrafico4.valoresMomentaneo] });
    d3.selectAll('#grafico4_hmiIny .c3-axis-y2 line').style("stroke", valoresGrafico4.color);
    d3.selectAll('#grafico4_hmiIny .c3-axis-y2 path').style("stroke", valoresGrafico4.color);
    d3.selectAll('#grafico4_hmiIny .c3-axis-y2-label').text("PRUEBA LAU");
    d3.selectAll('#grafico4_hmiIny .c3-axis-y2 text').style("fill", valoresGrafico4.color);
    d3.selectAll('#grafico4_hmiIny .c3-axis-y2 path').style("stroke-width", "1.3px");
    d3.selectAll('#grafico4_hmiIny .c3-line').style("stroke-width", "1.3px");
    d3.selectAll('#grafico4_hmiIny .c3-axis-y2 tspan').style("fill", valoresGrafico4.color);
    this.grafico4.data.colors({ valor: d3.rgb(valoresGrafico4.color) });

    // Los valores de las fechas
    this.datosEjeX = this.aplanarArray(fechas, 10).slice(1);

    //Regiones (grafico 1)
    //var regiones = [
    //  { start: this.datosGraficoMultieje.fechas[17], end: this.datosGraficoMultieje.fechas[44], class: 'regionTiempoInyeccion' },
    //  { start: this.datosGraficoMultieje.fechas[44], end: this.datosGraficoMultieje.fechas[60], class: 'regionTiempoPresionRemanente' },
    //  { start: this.datosGraficoMultieje.fechas[60], end: this.datosGraficoMultieje.fechas[114], class: 'regionTiempoPlastificacion' },
    //];

    //this.grafico1.regions.remove();
    //this.grafico1.internal.config.regions = [];
    //regiones.forEach(function (region) {
    //  if (region.class == 'regionTiempoInyeccion' && this.mostrarGraficosPorLeyenda[4]) {
    //    this.grafico1.regions.add(region); this.grafico1.regions.add(region);//Poner 2 veces para tener mas opacidad
    //  }
    //  if (region.class == 'regionTiempoPresionRemanente' && this.mostrarGraficosPorLeyenda[5]) {
    //    this.grafico1.regions.add(region); this.grafico1.regions.add(region); this.grafico1.regions.add(region);//Poner 3 veces para tener mas opacidad
    //  }
    //  if (region.class == 'regionTiempoPlastificacion' && this.mostrarGraficosPorLeyenda[6]) {
    //    this.grafico1.regions.add(region); this.grafico1.regions.add(region); this.grafico1.regions.add(region);//Poner 3 veces para tener mas opacidad
    //  }
    //}, this);

    //Regiones (grafico 1)
    if (this.fechaIniCicloSeleccionado != undefined && this.fechaFinCicloSeleccionado != undefined) {
      var region = { start: this.fechaIniCicloSeleccionado, end: this.fechaFinCicloSeleccionado, class: 'regionTiempoPresionRemanente' };

      this.grafico1.regions.remove();
      this.grafico1.internal.config.regions = [];
      this.grafico1.regions.add(region); this.grafico1.regions.add(region);//Poner 2 veces para tener mas opacidad
    }

    //Subgrafico
    var datosSubGrafico = [
      fechas,
      ['data1_color'],
      ['data1_blanco'],
      ['data2_color'],
      ['data2_blanco'],
      ['data3_color'],
      ['data3_blanco'],
      ['data4_color'],
      ['data4_blanco'],
      ['data5_color'],
      ['data6_blanco']
    ];

    this.subgrafico.load({ columns: datosSubGrafico });
    this.posicionarEjesGrafico();

  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging
    };
  }

  aplanarArray(data, aCuantosElementos) {
    //var aCuantosElementos = 1000;
    //var data = [15, 16, 9, 1, 84, 1, 6, 77];

    var resultado = [];
    resultado.push(data[0]);
    var i;
    for (i = 1; i < aCuantosElementos; i++) {
      resultado.push(data[Math.round((data.length / aCuantosElementos) * i)]);
    }
    resultado.push(data[data.length - 1]);
    return resultado;
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }
  //FIN GRAFICOS


  cargarVariablesVisibles(valoresTec) {
    if (valoresTec == undefined) {
      this.listaVariablesVisibles = [];
      for (let index = 0; index < 18; index++) {
        this.listaVariablesVisibles.push(true);
      }
    } else {
      this.listaVariablesVisibles = [];
      this.listaVariablesVisibles.push(valoresTec.contrapresion);
      this.listaVariablesVisibles.push(valoresTec.tiempoInyeccion);
      this.listaVariablesVisibles.push(valoresTec.tiempoPresionRemanente);
      this.listaVariablesVisibles.push(valoresTec.tiempoDosificacionPlastificacion);
      this.listaVariablesVisibles.push(valoresTec.tiempoEnfriamiento);
      this.listaVariablesVisibles.push(valoresTec.paradaDosificado);
      this.listaVariablesVisibles.push(valoresTec.tiempoCiclo);
      this.listaVariablesVisibles.push(valoresTec.presionInyeccionMax);
      this.listaVariablesVisibles.push(valoresTec.ptoConmutacion);
      this.listaVariablesVisibles.push(valoresTec.presionRemanente);
      this.listaVariablesVisibles.push(valoresTec.cojin);
      this.listaVariablesVisibles.push(valoresTec.retrocesoHusillo);
      this.listaVariablesVisibles.push(valoresTec.paradaMolde);
      this.listaVariablesVisibles.push(valoresTec.revolucionesHusillo);
      this.listaVariablesVisibles.push(valoresTec.fuerzaDeCierre);
      this.listaVariablesVisibles.push(valoresTec.temperaturaMolde);
      // this.listaVariablesVisibles.push(true);
      // this.listaVariablesVisibles.push(true);
    }

  }

  // CARGAR PROCESOS
  cargarDatosProcesos() {
    this.hmiService.Get_ProcesosHistorial(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.historicoProcesos = [];
      datuak.forEach(function (valor, indice, array) {
        var tipoProceso = "";
        if (valor.idProcesos_Tipo == 1) tipoProceso = this.translateService.instant("ejecucion");
        if (valor.idProcesos_Tipo == 2) tipoProceso = this.translateService.instant("parada");
        if (valor.idProcesos_Tipo == 3) tipoProceso = this.translateService.instant("preparacion");
        if (valor.idProcesos_Tipo == 4) tipoProceso = this.translateService.instant("mantenimiento");
        if (valor.idProcesos_Tipo == 6) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 7) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 8) tipoProceso = this.translateService.instant("apagado");
        this.historicoProcesos.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            tipo: tipoProceso,
            tipoAlarNombre: valor.descripcion,
            nombre: valor.nombrePrograma,
            OF: valor.of,
            tiemporeal: this.myFunctions.secondsTo_HH_MM_SS(valor.tiemporeal),
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoPredictivo),
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_" + valor.idProcesos_Tipo,
          }
        );
      }, this);
    });

    this.hmiService.Get_ProcesosAlarmas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.alarmas = [];
      datuak.forEach(function (valor, indice, array) {
        var divnombre;
        if (valor.idAlarma_tipo == 1) divnombre = "css_linea_lateral_2";
        else divnombre = "css_linea_lateral_6";
        this.alarmas.push(
          {
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            numeroAlarma: valor.numeroAlarma,
            descripcion: valor.descripcion,
            divcss: divnombre,
          }
        );
      }, this);
    });

    this.hmiService.Get_Planificados(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.planificados = [];
      datuak.forEach(function (valor, indice, array) {
        this.planificados.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            operacion: valor.operacion,
            OF: valor.of,
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoEstimado),
            programa: 123456,
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_8",
          }
        );
      }, this);
    });

  }

  // CARGAR MANTENIMIENTOS
  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow();
      var finHoy = this.myFunctions.getDateNow();
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);

            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }
            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            //ANTES DE USAR EL CALCULO DEL PROXIMO MANTENIMIENTO, HAY QUE TRADUCIRLO A LA HORA DEL USUARIO!
            // hacer esto desde la DB es dificil xq se pasa dia y hora... no una fecha como tal.
            //ARATZ SIGUE AQUI
            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.myFunctions.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }

          }
          else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }

          if (row.idTipo == 1) {//diario
            tipo = "diario";
          }
          else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          }
          else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          }
          else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          }
          else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      });

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      });

    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  // CARGAR NOTAS
  cargarDatosNotas() {
    this.hmiService.Get_NotasActualesMaquina(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.notas = datuak;
    });
  }

  //CARGAR HERRAMIENTAS
  cargarHerramientas() {

    this.hmiService.Get_Herramientas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.herramientas = datuak;
    });
    this.hmiService.Get_CambiosRoturas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.roturasCambiosHerramientas = datuak;
    });

  }

  //CARGAR TIEMPOS APAGADO
  cargarTiempoApagado() {
    this.tiempo = 0;
    this.tiempoCorregido = "00:00:00";
    this.tiempoEst = 0;
    this.tiempoPred = 0;
  }

  corregirFormato(tiempo: number) {
    // if (tiempo > 86400) {
    //   var dias = Math.floor(tiempo / 86400);
    //   var horas = this.pad(Math.floor(Math.floor(tiempo % 86400) / 3600));
    //   return dias + "d " + horas + "h"
    // } else {
      var hora = this.pad(Math.floor(tiempo / 3600));
      var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
      var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
      return hora + ":" + minutos + ":" + segundos;
    // }
  }

  calcularDesviacion() {
    var desv = this.tiempoEst - this.tiempo;
    if (desv > 0) {
      this.desviacion = "- " + this.corregirFormato(Math.abs(desv));
    } else {
      this.desviacion = "+ " + this.corregirFormato(Math.abs(desv));
    }
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  clickGridLeyenda(event) {

    //#region  actualizar la lista de valores tecnologicos
    event.deselectedRows.forEach(element => {
      this.listaValoresTecnologicos[element.index].estaEnGrafico = -1;
      this.listaValoresTecnologicos[element.index].seleccionadoLeyenda = false;
      this.colores.filter(f => f.color == this.listaValoresTecnologicos[element.index].color)[0].enUso = false;
      this.listaValoresTecnologicos[element.index].colorClass = '';
      this.listaValoresTecnologicos[element.index].color = '#000000';
    });
    event.selectedRows.forEach(element => {
      if (this.listaValoresTecnologicos.filter(x => x.estaEnGrafico != -1).length < 4) {
        var primerColorEnDesuso = this.colores.find(function (x) { return !x.enUso; });
        primerColorEnDesuso.enUso = true;
        this.listaValoresTecnologicos[element.index].estaEnGrafico = this.listaValoresTecnologicos.filter(x => x.estaEnGrafico != -1).length + 1;
        this.listaValoresTecnologicos[element.index].seleccionadoLeyenda = true;
        this.listaValoresTecnologicos[element.index].colorClass = primerColorEnDesuso.css;
        this.listaValoresTecnologicos[element.index].color = primerColorEnDesuso.color;
      } else {
        // Esto es necesario para que si se ha seleccionado mas de cuatro elementos se deseleccionen
        this.listaTooltipSeleccionados = this.listaTooltipSeleccionados.filter(f => f != element.index);
      }
    });
    //#endregion

    this.mostrarGraficosPorLeyenda= [ false, false, false, false];

    var indexGrafico = 0;
    this.listaTooltipSeleccionados.forEach(function (campo) {
        this.mostrarGraficosPorLeyenda[indexGrafico] = true;
        indexGrafico++;
    }, this);

    this.pintarGraficoMultiEjeAux(this.listaTooltipSeleccionados.length);
    this.cargarGraficosTodos();

  }

  public rowCallbackValoresTecnologicos(context: RowClassArgs) {
    var clase = context.dataItem.colorClass;
    // if (context.dataItem.lineaRoja) clase += ' ciclo-rojo';
    if (!context.dataItem.visible) clase += ' d-none';
    return clase
  }

  posicionarEjesGrafico() {
    var element = document.getElementById("grafico1_hmiIny").getElementsByClassName("c3-event-rect")[0];
    var width = element["width"]["animVal"]["value"];

    // trasladar eje de los graficos
    document.getElementById("grafico1_hmiIny").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + 0 + "px, 0px)";
    document.getElementById("grafico2_hmiIny").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + -64 + "px, 0px)";
    document.getElementById("grafico4_hmiIny").getElementsByClassName("c3-axis-y2")[0]["style"]["transform"]="translate(" + (width + 64) + "px, 0px)";
  }

}
