import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/disponibilidad`;

@Injectable()
export class DisponibilidadService {

  constructor(private http: HttpClient) { }

  //#region CONFIGURACION
  public Get_Configuracion(): Observable<any> {
    return this.http.get(baseUrl + "/configuracion");
  }
  //#endregion

  //#region TURNOS
  public Get_Turno(id): Observable<any> {
    return this.http.get(baseUrl + "/turnos/" + id);
  }

  public Get_Turnos(): Observable<any> {
    return this.http.get(baseUrl + "/turnos");
  }

  public Insert_Turnos(ano, numSemana, fechaInicio, fechaFin) {
    return this.http.post<any>(baseUrl + "/turnos/crear", { anno: ano, numSemana: numSemana, fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public Delete_Turnos(data): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/turnos/eliminar", formData, { withCredentials: true });
  }

  public Get_Turno_Detalle(idCalendarioSemanasDisponibilidad): Observable<any> {
    return this.http.get(baseUrl + "/turnos/detalle/" + idCalendarioSemanasDisponibilidad);
  }

  public Get_Turno_Defecto(): Observable<any> {
    return this.http.get(baseUrl + "/turnos/detalle/defecto");
  }

  public Insert_Turno_Detalle(idCalendarioSemanasDisponibilidad, data) {
    return this.http.post<any>(baseUrl + "/turnos/detalle/crear", {
      idCalendarioSemanasDisponibilidad: idCalendarioSemanasDisponibilidad, idMaquina: data.idMaquina,
      lunesMannanaInicio: data.lunesMannanaInicio, lunesMannanaFin: data.lunesMannanaFin, lunesTardeInicio: data.lunesTardeInicio, lunesTardeFin: data.lunesTardeFin, lunesNocheInicio: data.lunesNocheInicio, lunesNocheFin: data.lunesNocheFin,
      martesMannanaInicio: data.martesMannanaInicio, martesMannanaFin: data.martesMannanaFin, martesTardeInicio: data.martesTardeInicio, martesTardeFin: data.martesTardeFin, martesNocheInicio: data.martesNocheInicio, martesNocheFin: data.martesNocheFin,
      miercolesMannanaInicio: data.miercolesMannanaInicio, miercolesMannanaFin: data.miercolesMannanaFin, miercolesTardeInicio: data.miercolesTardeInicio, miercolesTardeFin: data.miercolesTardeFin, miercolesNocheInicio: data.miercolesNocheInicio, miercolesNocheFin: data.miercolesNocheFin,
      juevesMannanaInicio: data.juevesMannanaInicio, juevesMannanaFin: data.juevesMannanaFin, juevesTardeInicio: data.juevesTardeInicio, juevesTardeFin: data.juevesTardeFin, juevesNocheInicio: data.juevesNocheInicio, juevesNocheFin: data.juevesNocheFin,
      viernesMannanaInicio: data.viernesMannanaInicio, viernesMannanaFin: data.viernesMannanaFin, viernesTardeInicio: data.viernesTardeInicio, viernesTardeFin: data.viernesTardeFin, viernesNocheInicio: data.viernesNocheInicio, viernesNocheFin: data.viernesNocheFin,
      sabadoMannanaInicio: data.sabadoMannanaInicio, sabadoMannanaFin: data.sabadoMannanaFin, sabadoTardeInicio: data.sabadoTardeInicio, sabadoTardeFin: data.sabadoTardeFin, sabadoNocheInicio: data.sabadoNocheInicio, sabadoNocheFin: data.sabadoNocheFin,
      domingoMannanaInicio: data.domingoMannanaInicio, domingoMannanaFin: data.domingoMannanaFin, domingoTardeInicio: data.domingoTardeInicio, domingoTardeFin: data.domingoTardeFin, domingoNocheInicio: data.domingoNocheInicio, domingoNocheFin: data.domingoNocheFin,
    }, { withCredentials: true });
  }

  public Update_Turno_Detalle(data) {
    return this.http.post<any>(baseUrl + "/turno/detalle/editar", {
      idCalendarioSemanasDisponibilidad: data.idCalendarioSemanasDisponibilidad, idMaquina: data.idMaquina,
      lunesMannanaInicio: data.lunesMannanaInicio, lunesMannanaFin: data.lunesMannanaFin, lunesTardeInicio: data.lunesTardeInicio, lunesTardeFin: data.lunesTardeFin, lunesNocheInicio: data.lunesNocheInicio, lunesNocheFin: data.lunesNocheFin,
      martesMannanaInicio: data.martesMannanaInicio, martesMannanaFin: data.martesMannanaFin, martesTardeInicio: data.martesTardeInicio, martesTardeFin: data.martesTardeFin, martesNocheInicio: data.martesNocheInicio, martesNocheFin: data.martesNocheFin,
      miercolesMannanaInicio: data.miercolesMannanaInicio, miercolesMannanaFin: data.miercolesMannanaFin, miercolesTardeInicio: data.miercolesTardeInicio, miercolesTardeFin: data.miercolesTardeFin, miercolesNocheInicio: data.miercolesNocheInicio, miercolesNocheFin: data.miercolesNocheFin,
      juevesMannanaInicio: data.juevesMannanaInicio, juevesMannanaFin: data.juevesMannanaFin, juevesTardeInicio: data.juevesTardeInicio, juevesTardeFin: data.juevesTardeFin, juevesNocheInicio: data.juevesNocheInicio, juevesNocheFin: data.juevesNocheFin,
      viernesMannanaInicio: data.viernesMannanaInicio, viernesMannanaFin: data.viernesMannanaFin, viernesTardeInicio: data.viernesTardeInicio, viernesTardeFin: data.viernesTardeFin, viernesNocheInicio: data.viernesNocheInicio, viernesNocheFin: data.viernesNocheFin,
      sabadoMannanaInicio: data.sabadoMannanaInicio, sabadoMannanaFin: data.sabadoMannanaFin, sabadoTardeInicio: data.sabadoTardeInicio, sabadoTardeFin: data.sabadoTardeFin, sabadoNocheInicio: data.sabadoNocheInicio, sabadoNocheFin: data.sabadoNocheFin,
      domingoMannanaInicio: data.domingoMannanaInicio, domingoMannanaFin: data.domingoMannanaFin, domingoTardeInicio: data.domingoTardeInicio, domingoTardeFin: data.domingoTardeFin, domingoNocheInicio: data.domingoNocheInicio, domingoNocheFin: data.domingoNocheFin,
    }, { withCredentials: true });
  }
  //#endregion

  //#region INFORME CALCULO DISPONIBILIDAD
  public Get_CalculoDisponibilidad_HorasSemana(ano, numSemana, idMaquina, mes, fechaInicioSemana): Observable<any> {
    return this.http.post<JSON>(baseUrl + "/calculodisponiblidad/horassemana", { ano: ano, numSemana: numSemana, idMaquina: idMaquina, mes: mes, fechaInicio: fechaInicioSemana.toISOString() }, { withCredentials: true });
  }

  public Get_CalculoDisponibilidad_HorasInactivasSemana(fechaInicioSemana, fechaFinSemana, fechaAhora, idMaquina, mes): Observable<any> {
    return this.http.post<JSON>(baseUrl + "/calculodisponiblidad/horasinactivassemana", { fechaInicio: fechaInicioSemana.toISOString(), fechaFin: fechaFinSemana.toISOString(), fechaAhora: fechaAhora.toISOString(), idMaquina: idMaquina, mes: mes }, { withCredentials: true });
  }

  public Get_CalculoDispInac_Informe(ano, mes, mesesPorInforme, idMaquina): Observable<any> {
    return this.http.post<JSON>(baseUrl + "/calculodisponiblidad/informe", { ano: ano, mes: mes, mesesPorInforme: mesesPorInforme, idMaquina: idMaquina}, { withCredentials: true });
  }
  //#endregion

  Imprimir_Disponibilidad(data) {
    return this.http.post<any>(baseUrl + "/imprimir/", { idMaquina: data.idMaquina, ano: data.ano, mes: data.mes, mesesPorInforme: data.mesesPorInforme, inversion: data.inversion, numeroInventario: data.numeroInventario, disponibilidadPorcentajeContratoCompra: data.disponibilidadPorcentajeContratoCompra,
    mesAno: data.mesAno, projectManager: data.projectManager, fechaFirma: data.fechaFirma.toISOString() }, { withCredentials: true });
  }

}
