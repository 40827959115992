import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosService, MaquinasService, EskolaGruposService, HistoricoOperacionesService, InformeProyectosService, OperariosService, ControlesService, Eskola_AlumnosService, Eskola_AsignaturasRetosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-grupos',
  templateUrl: './eskola_grupos.component.html'
})
export class EskolaGruposComponent implements OnInit {
  

  
  private translate: TranslateService;
  form: FormGroup;

  public dtDocentes = [];
  public dtAlumnos = [];
  public dtAsignaturas = [];
  public fini: any;
  public ffin: any;
  public idcliente: any;
  public idpieza: any;
  public idof: any;
  public nSerie: any;
  public idOperacion: any;
  private dataFiltro: any;
  private dataInforme: any;

  public loadingDatos: boolean = false;
  public loadingPerdidas: boolean = false;
  public tieneDatos: boolean = true;

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //PANEL MAQUINAS
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //GRAFICOS Y GRIDS VISIBLES
  public datosVisibles: boolean = false;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;


  dataGrupos: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  public docentes: any = [];
  public tutoresNombreCompleto: any = [];

  modalReference: NgbModalRef;
  user = this.userService.userValue;
  public loadingPanel: any = false;


  //PARA AÑADIR PAGGING AL GRID
  public skipOperaciones = 0;
  public skipMaquinas = 0;
  public skipOperarios = 0;
  public skipPiezas = 0;
  public skipClientes = 0;



  //VARIABLES FILTRO
  public activoFiltro: number = 0;



  //FILTRO
  status: boolean = true; //para abrir o cerrar el filtro * se carga abierto siempre! si se autofiltra, se cierra solo.
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas

  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('inicio'), field: "fechaInicio", sqlfield: "grupo.inicio", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          },
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    ]
  };
  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    { id: 2, nombre: this.translateService.instant('inicio'), field: "fechaInicio", sqlfield: "grupo.inicio", tipo: 'date' },
    { id: 3, nombre: this.translateService.instant('fin'), field: "fechaFin", sqlfield: "grupo.fin", tipo: 'date' },
    { id: 4, nombre: this.translateService.instant('nombre'), field: "nombre", sqlfield: "grupo.nombre", tipo: 'comboEstrincto' },
    { id: 5, nombre: this.translateService.instant('docentes'), field: "docentes", sqlfield: "u.Id", tipo: 'comboEstrincto' },
    { id: 6, nombre: this.translateService.instant('tutor'), field: "tutoresNombreCompleto", sqlfield: "ut.Id", tipo: 'comboEstrincto' },
    { id: 7, nombre: this.translateService.instant('curso'), field: "id_cursoLectivo", sqlfield: "cursos.id", tipo: 'comboEstrincto' },
    { id: 8, nombre: this.translateService.instant('tipoGrupo'), field: "id_tipoGrupo", sqlfield: "gruposTipo.id", tipo: 'comboEstrincto' },
    { id: 9, nombre: this.translateService.instant('activo'), field: "activoF", sqlfield: "grupo.activo", tipo: 'check' },

  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  //Elene
  public filtro_listaNombre: any = [];
  public filtro_listaDocentes: any = [];
  public filtro_listaTutores: any = [];
  public filtro_listaCursos: any = [];
  public filtro_listaTipoDeGrupo: any = [];
  public filtro_listaActivo: any;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;
  dataInformeGroupByHistoricoOperacion: any[];
  //END FILTRO

  constructor(private gruposService: EskolaGruposService,
    private asignaturasService: Eskola_AsignaturasRetosService,
    private alumnosService: Eskola_AlumnosService,
    private historicoOperacionesService: HistoricoOperacionesService,
    private informeProyectosService: InformeProyectosService,
    private maquinasService: MaquinasService,
    public myFunctions: MyFunctions,
    translate: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translateService.instant('grupos').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/grupos') {
          this.cargarDatos();
        }
      }
    });

  }

  async ngOnInit() {

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });

    this.loadingFiltro = true;

    //this.cargarDatosFiltro();
    this.cargarGrupos();//DESDE AQUI SE LLAMA A CARGARDATSOFILTRO
    this.cargarAreasProductivas();//DESDE AQUI SE LLAMA A CARGARDATSOFILTRO


    var controlCiclo = 0;
    while (document.getElementById("graficoClientes_AnaliticaAvanzadaEjecuciones") == null && controlCiclo < 10) {
      await new Promise(r => {
        controlCiclo++;
        setTimeout(r, 200)
      });
    }

  }

  cellClick(e) {
    console.log(e);
    this.router.navigate(['/grupos/' + e.dataItem.id]);
  }
  nuevoClick() {
    this.router.navigate(['/grupos/nuevo']);
  }
  editarClick() {
    if (this.mySelection.length == 0 || this.mySelection == undefined) {
      return;
    }
    this.router.navigate(['/grupos/' + this.mySelection[0]]);
  }
  //FILTRO
  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
    }
  }
  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
      this.actualizarVisible = true;
    }
  }
  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.cargarDatos();



  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo() {
    var em = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          // public opcionDate = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {

    console.log("nidea " + event.target.classList.contains("calendarFiltro-calendarioSeleccionado"));
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  // Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value, calendario) {
    if (value == -1) {
      (calendario.children[0].children[0].getElementsByTagName('kendo-calendar-header')[0].children[2].children[0] as any).click();
      return;
    }
    if (value == 1) {
      (calendario.children[0].children[0].getElementsByTagName('kendo-calendar-header')[0].children[2].children[2] as any).click();
    }
  }

  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    //debugger;
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 6);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();


    this.gruposService.getAll().subscribe((data) => {
      data.forEach(element => {
        // NOMBRE GRUPO
        var e: any = [];
        e.id = element.nombre;
        e.nombre = element.nombre;
        if (this.filtro_listaNombre.filter(r => r.nombre === e.nombre).length <= 0) {
          this.filtro_listaNombre.push(e);
        }
        // CURSO
        var g: any = [];
        g.id = element.id_cursoLectivo;
        g.nombre = element.nombre_cursoLectivo;
        if (this.filtro_listaCursos.filter(r => r.id === g.id).length <= 0) {
          this.filtro_listaCursos.push(g);
        }
        //Tipo de grupo
        var tipo: any = [];
        tipo.id = element.id_tipoGrupo;
        tipo.nombre = element.nombre_grupo;
        if (this.filtro_listaTipoDeGrupo.filter(r => r.id === tipo.id).length <= 0) {
          this.filtro_listaTipoDeGrupo.push(tipo);
        }
        //tutores
        if (element.tutores != null) {
          var tutoresNombreCompletoAux = [];
          var tutores = element.tutores.split(",");
          tutores.forEach(tutor => {
            // conseguimos ID de cada tutor         
            var currentTutor = tutor.split(';')
            var tu: any = [];
            tu.id = currentTutor[0];
            tu.nombre = currentTutor[1];
            tu.grupo = element.id;
            //para no guardar tutores repetidos
            if (this.filtro_listaTutores.filter(r => r.id === tu.id).length <= 0) {
              this.filtro_listaTutores.push(tu);
            }
          });

        }
      });
    });


    //ACTIVO
    this.filtro_listaActivo = [
      { nombre: this.translateService.instant("activo"), id: 1, value: 1 },
      { nombre: this.translateService.instant("inactivo"), id: 0, value: 0 },
    ];


    //OPERARIOS
    this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
      this.filtro_listaOperarios = data;
      this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));

      r3 = true;
      if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
    });

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.filtro_listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });


    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
      (data: any) => {
        this.filtro_listaHerramientas = data;
        this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      });

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
        { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto' },
        { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
        { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
        { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
        { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
        { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
        { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
        { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
        { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
        { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id == 4) { // nombre
        row.combo = this.filtro_listaNombre.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 5) { // docentes
        row.combo = this.filtro_listaDocentes.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));

      }
      else if (row.columna.id == 6) { // tutores
        row.combo = this.filtro_listaTutores.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // cursos
        row.combo = this.filtro_listaCursos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK

      }

      else if (row.columna.id == 8) { // tipos de grupo 

        row.combo = this.filtro_listaTipoDeGrupo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 9) { // activo
        row.combo = this.filtro_listaActivo;
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {
    // si se miran las proximas funciones se vera como se aplica el filtro. 
    // aqui hay un ejemplo de todos modos:    
    //         var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    //         filtroFechas = filtroFechas.replace(/hb.fechaTurno(.*?)hb.fechaTurno/g, 'fechaMod$1fechaCreado');
    //         var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    this.cargarDatosFiltro();
    this.status = true;
    this.actualizarVisible = false;
  }

  // CONSULTAS INFORME
  cargarDatosInforme() {
    var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    filtroFechas = filtroFechas.replace(/hb.fechaTurno(.*?)hb.fechaTurno/g, 'fechaMod$1fechaCreado');

    var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    this.datosVisibles = true;

    this.loadingDatos = true;
    this.loadingPerdidas = true;
    (<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = true;

    this.informeProyectosService.Get_Informe_Of_Cliente_Pieza_Avanzado_filtrado(filtroFechas, filtroCompleto).pipe(first()).subscribe(
      (data: any) => {

        this.loadingDatos = false;

        this.dataInforme = data;

        var dataInformeGroupByHistoricoOperacion = [];
        //GROUP BY POR HISTORICO OPERACION
        this.dataInforme.forEach(function (a) {
          if (!this[a.idHistoricoOperaciones]) {
            this[a.idHistoricoOperaciones] = {
              idHistoricoOperaciones: a.idHistoricoOperaciones, operacion: a.operacion, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0,
              tApagadaDisponibilidad: 0, tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0,
              tMantenimientoCalidad: 0, tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0, tEstimadoGlobal: 0, tiempoPredictivoGlobal: 0,
              avanceAcumulado: 0, consumoAcumulado: 0, cambioPlacas: 0, roturaHerramientas: 0, tTotal: 0, tTotalHistoricoOperacion: 0, terminado: a.terminado
            };
            dataInformeGroupByHistoricoOperacion.push(this[a.idHistoricoOperaciones]);
          }
          this[a.idHistoricoOperaciones].tEjecucion += a.tEjecucion;
          this[a.idHistoricoOperaciones].tParadaDisponibilidad += a.tParadaDisponibilidad;
          this[a.idHistoricoOperaciones].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
          this[a.idHistoricoOperaciones].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
          this[a.idHistoricoOperaciones].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
          this[a.idHistoricoOperaciones].tParadaRendimiento += a.tParadaRendimiento;
          this[a.idHistoricoOperaciones].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
          this[a.idHistoricoOperaciones].tAlarmaRendimiento += a.tAlarmaRendimiento;
          this[a.idHistoricoOperaciones].tApagadaRendimiento += a.tApagadaRendimiento;
          this[a.idHistoricoOperaciones].tParadaCalidad += a.tParadaCalidad;
          this[a.idHistoricoOperaciones].tMantenimientoCalidad += a.tMantenimientoCalidad;
          this[a.idHistoricoOperaciones].tAlarmaCalidad += a.tAlarmaCalidad;
          this[a.idHistoricoOperaciones].tApagadaCalidad += a.tApagadaCalidad;
          this[a.idHistoricoOperaciones].tNegativoCalidad += a.tNegativoCalidad;
          this[a.idHistoricoOperaciones].tEstimado += a.tEstimado;
          this[a.idHistoricoOperaciones].tEstimadoGlobal = Math.max(this[a.idHistoricoOperaciones].tEstimadoGlobal, a.tEstimadoGlobal);
          this[a.idHistoricoOperaciones].tiempoPredictivoGlobal = Math.max(this[a.idHistoricoOperaciones].tiempoPredictivoGlobal, a.tiempoPredictivoGlobal);
          this[a.idHistoricoOperaciones].avanceAcumulado += a.avanceAcumulado;
          this[a.idHistoricoOperaciones].consumoAcumulado += a.consumoAcumulado;
          this[a.idHistoricoOperaciones].cambioPlacas += a.cambioPlacas;
          this[a.idHistoricoOperaciones].roturaHerramientas += a.roturaHerramientas;
          this[a.idHistoricoOperaciones].tTotal += a.tTotal;
          this[a.idHistoricoOperaciones].tTotalHistoricoOperacion += a.tTotalHistoricoOperacion;
          this[a.idHistoricoOperaciones].terminado = this[a.idHistoricoOperaciones].terminado && a.terminado;
        }, Object.create(null));

        //FOREACH CON EL IF DE TIEMPO ESTIMADO
        //Si tReal de la operacion recibida < tReal operacion total (operacion cortada por fecha): usar tEstimado ficticio
        //Si tReal de la operacion recibida = tReal operacion total (operacion no cortada por fecha):
        //  Si terminada=1: usar tEstimado global
        //  Si terminada=0:
        //    Si tReal de la operacion recibida <= tEstimado: tEst=tReal
        //    Si tReal de la operacion recibida > tEstimado: tEst=tEst
        dataInformeGroupByHistoricoOperacion.forEach(function (a) {
          if (a.tTotal < a.tTotalHistoricoOperacion) {//(operacion cortada por fecha)
            a.tEstimadoFinal = a.tEstimado;
          } else {//(operacion no cortada por fecha)
            if (a.terminado) {
              a.tEstimadoFinal = a.tEstimadoGlobal;
            } else {
              if (a.tTotal <= a.tEstimadoGlobal) {
                a.tEstimadoFinal = a.tTotal;
              } else {
                a.tEstimadoFinal = a.tEstimadoGlobal;
              }
            }
          }
        });

        this.dataInformeGroupByHistoricoOperacion = dataInformeGroupByHistoricoOperacion;

        //Ponemos estos valores a 0 para mandar todos los grids a la primera pagina
        this.skipOperaciones = 0;
        this.skipMaquinas = 0;
        this.skipOperarios = 0;
        this.skipPiezas = 0;
        this.skipClientes = 0;

        if (this.dataInforme.length > 0) {

          this.tieneDatos = true;


        } else {
          this.tieneDatos = false;
        }

      });
  }
  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
      // Historico Piezas:
      //   - fechaIni , fechaFin *fijo*
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

      // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
      var r1, r2 = false;
      // HISTORICO PIEZAS??
      /**/var IdHistorico = Number.parseInt(this.route.snapshot.params['idHistoricoPieza']);
      this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
          }
          r1 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
      // HISTORICO OPERACIONES??
      /**/var IdHistoricoOp = Number.parseInt(this.route.snapshot.params['idHistoricoOperacion']);
      this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
          }
          r2 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else {
      // Informe Proyectos:
      //   - fechaIni , fechaFin *fijo*
      //   - idcliente (posible extra)
      //   - idpieza (posible extra)
      //   - idof (posible extra)
      //   - terminados (posible extra)
      // FECHAS (INICIO, FIN)
      /**/this.fini = this.route.snapshot.params['fini'];
      /**/this.ffin = this.route.snapshot.params['ffin'];
      if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
        && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
      // OF
      /**/this.idof = Number.parseInt(this.route.snapshot.params['idof']);
      var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idof']);
      if (rowOFs.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
      }
      // CLIENTE
      /**/this.idcliente = Number.parseInt(this.route.snapshot.params['idcliente']);
      // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
      var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idcliente']);
      if (rowClientes.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
      }
      // PIEZA
      /**/this.idpieza = Number.parseInt(this.route.snapshot.params['idpieza']);
      var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idpieza']);
      if (rowPiezas.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
      }
      // TERMINADO 
      // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
      // * solo lo voy a filtrar si es 1
      /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
      if (this.route.snapshot.params['terminados'] == '1') {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
          operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  // END FILTRO

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas)
        this.cargar_Filtro();
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });
        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }


  //DATOS TABLA
  cargarDatosFiltro() {

    var filtroTutores: any = this.filtroToSQL(['ut.Id']);// filtro solo de tutores
    var filtroDocentes: any = this.filtroToSQL(['u.Id']);// filtro solo de docentes
    console.log(filtroDocentes);

    var filtroCompleto: any = this.filtroToSQL(['grupo.nombre', 'cursos.id', 'gurposTipo.id', 'grupo.activo', 'grupo.inicio', 'grupo.fin']); // filtro completo


    this.datosVisibles = true;

    this.loadingDatos = true;

    //(<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = true;
    this.gruposService.getAll_filtrado(filtroDocentes, filtroTutores, filtroCompleto).subscribe((result) => {
      this.dataGrupos = result;


      if (this.dataGrupos != null && this.dataGrupos.length > 0)
        this.dataGrupos.forEach(element => {
          element.fechainicio = this.myFunctions.dateShorted(new Date(element.inicio));
          element.fechafin = this.myFunctions.dateShorted(new Date(element.fin));
            //Procesamos los docentes
            if (element.docentes != null) {
              var docentes = element.docentes.split(",");
  
              var docentesAux = [];
              var docentesAux2 = [];
              var colores = element.colores.split(",");
              var docentesC = [];
              element.docentesId = [];
              docentes.forEach(docente => {
                var id;
                var nombre;
                var apellido;
                if (docente.split(";").length > 0) {
                  if (docente == this.translateService.instant("desconocido")) {
                    docentesAux.push("undefinied");
                    docentesAux2.push("undefinied");
                  } else {
                    id = docente.split('!')[0];
                    nombre = docente.split('!')[1].split(';')[0];
                    apellido = docente.split('!')[1].split(';')[1];
                    var e: any = [];
                    e.id = id;
                    e.nombre = nombre + ' ' + apellido;
                    //para no guardar docentes repetidos
                    if (this.filtro_listaDocentes.filter(e => e.id === id).length <= 0) {
                      this.filtro_listaDocentes.push(e);
                    }
  
                    if (!docentesAux2.includes(nombre + " " + apellido)) {
                      if (apellido != undefined) {
                        docentesAux2.push(nombre + " " + apellido);
                      } else {
                        docentesAux2.push(nombre);
                      }
                      if (nombre != undefined) {
                        nombre = nombre.trim().substring(0, 1).toUpperCase();
                      }
                      if (apellido != undefined) {
                        apellido = apellido.trim().substring(0, 1).toUpperCase();
                      }
                      docentesAux.push(nombre + apellido);
                      element.docentesId.push(id);
                    }
  
                  }
  
                }
              }
              );
              colores.forEach(color => {
                if (!docentesC.includes(color)) {
                  docentesC.push(color);
                }
  
              });
              element.docentesAux2 = docentesAux2.join(",");
              element.docentesAux = docentesAux.join(",");
              element.docentesC = docentesC.join(",");
            }
  
            //Procesamos los tutores
            if (element.tutores != null) {
              var tutoresNombreCompletoAux = [];
              var tutoresId = [];
              var tutores = element.tutores.split(",");
              tutores.forEach(tutor => {
                // conseguimos ID de cada tutor         
                var currentTutor = tutor.split(';');
                tutoresNombreCompletoAux.push(currentTutor[1]);
                this.tutoresNombreCompleto.push(currentTutor[1]);
                tutoresId.push(currentTutor[0]);
  
  
              });
              element.tutoresNombreCompletoAux = tutoresNombreCompletoAux.join(', ');
              element.tutoresId = tutoresId;
  
            }
        })
      console.log(result);
    });



  }
  //DATOS TABLA
  cargarDatos() {


    this.datosVisibles = true;

    this.loadingDatos = true;

    //(<HTMLInputElement>document.getElementById('checkboxGrupos')).disabled = true;
    this.gruposService.getAll().subscribe((result) => {
      this.dataGrupos = result;


      if (this.dataGrupos != null && this.dataGrupos.length > 0)
        this.dataGrupos.forEach(element => {
          element.fechainicio = this.myFunctions.dateShorted(new Date(element.inicio));
          element.fechafin = this.myFunctions.dateShorted(new Date(element.fin));
          //Procesamos los docentes
          if (element.docentes != null) {
            var docentes = element.docentes.split(",");

            var docentesAux = [];
            var docentesAux2 = [];
            var colores = element.colores.split(",");
            var docentesC = [];
            element.docentesId = [];
            docentes.forEach(docente => {
              var id;
              var nombre;
              var apellido;
              if (docente.split(";").length > 0) {
                if (docente == this.translateService.instant("desconocido")) {
                  docentesAux.push("undefinied");
                  docentesAux2.push("undefinied");
                } else {
                  id = docente.split('!')[0];
                  nombre = docente.split('!')[1].split(';')[0];
                  apellido = docente.split('!')[1].split(';')[1];
                  var e: any = [];
                  e.id = id;
                  e.nombre = nombre + ' ' + apellido;
                  //para no guardar docentes repetidos
                  if (this.filtro_listaDocentes.filter(e => e.id === id).length <= 0) {
                    this.filtro_listaDocentes.push(e);
                  }

                  if (!docentesAux2.includes(nombre + " " + apellido)) {
                    if (apellido != undefined) {
                      docentesAux2.push(nombre + " " + apellido);
                    } else {
                      docentesAux2.push(nombre);
                    }
                    if (nombre != undefined) {
                      nombre = nombre.trim().substring(0, 1).toUpperCase();
                    }
                    if (apellido != undefined) {
                      apellido = apellido.trim().substring(0, 1).toUpperCase();
                    }
                    docentesAux.push(nombre + apellido);
                    element.docentesId.push(id);
                  }

                }

              }
            }
            );
            colores.forEach(color => {
              if (!docentesC.includes(color)) {
                docentesC.push(color);
              }

            });
            element.docentesAux2 = docentesAux2.join(",");
            element.docentesAux = docentesAux.join(",");
            element.docentesC = docentesC.join(",");
          }

          //Procesamos los tutores
          if (element.tutores != null) {
            var tutoresNombreCompletoAux = [];
            var tutoresId = [];
            var tutores = element.tutores.split(",");
            tutores.forEach(tutor => {
              // conseguimos ID de cada tutor         
              var currentTutor = tutor.split(';');
              tutoresNombreCompletoAux.push(currentTutor[1]);
              this.tutoresNombreCompleto.push(currentTutor[1]);
              tutoresId.push(currentTutor[0]);


            });
            element.tutoresNombreCompletoAux = tutoresNombreCompletoAux.join(', ');
            element.tutoresId = tutoresId;

          }
        })
      console.log(result);
    });

    this.userService.getAllUsers(7).subscribe(res => {
      this.dtDocentes = res['data'];
    })

    this.userService.getAllUsers(6).subscribe(res => {
      this.dtAlumnos = res['data'];
    })

    this.asignaturasService.getAll().subscribe(res => {
      this.dtAsignaturas = res;
    });

    this.loadingPanel = false;

  }
  onClickEliminar(content) {
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });

  }
  eliminarRegistro() {
    this.modalReference.close();
    this.mySelection.forEach(grupoSeleccionado => {
      console.log(grupoSeleccionado);
      if (grupoSeleccionado > 0) {
        this.isDeleting = true;
        this.gruposService.deleteGrupo(grupoSeleccionado).subscribe({
          next: () => {
            this.isDeleting = false;
            this.alertService.success(this.translateService.instant('eliminadocorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['../grupos'], { relativeTo: this.route });
          },
          error: error => {
            this.isDeleting = false;
            this.alertService.error(error);
          }
        });
      }
    });
  }

  duplicarClick() {
    this.loadingPanel = true;
    this.mySelection.forEach(grupoSeleccionado => {
      var r1, r2, r3, r4 = false;
      var asignaturaskop = 0;
      if (grupoSeleccionado > 0) {
        var grupo = this.dataGrupos.filter(f => f.id == grupoSeleccionado)[0];
        var tutoresGrupo: any = [];
        var alumnosGrupo: any = [];
        var asignaturasGrupo: any = [];
        var nombreNuevo = grupo?.nombre + " copia";


        this.alumnosService.getAll_alumnos_filtrado("", "WHERE id_grupo = " + grupoSeleccionado ,"").subscribe(res => {
          alumnosGrupo = res;
          r1 = true;
          if (r1 && r2) {
            grupo.tutoresGrupo = tutoresGrupo;
            grupo.alumnosGrupo = alumnosGrupo;
            grupo.asignaturasGrupo = asignaturasGrupo;
            grupo.nombreNuevo = nombreNuevo;
            this.duplicar(grupo);

          }

        });

        this.gruposService.getAsignaturasGrupo(" WHERE id_grupo = " + grupoSeleccionado).subscribe(res => {

          asignaturasGrupo = res;
          asignaturasGrupo.forEach(asignatura => {
            this.gruposService.geDocentesAsignaturasGrupo(" WHERE id_grupo = " + grupoSeleccionado + " AND id_asignaturaReto = " + asignatura.id_asignatura).subscribe(r => {
              asignatura.docentes = r;
              asignaturaskop++;
              if (asignaturaskop == asignaturasGrupo.length) {
                r2 = true;
                if (r1 && r2) {
                  grupo.tutoresGrupo = tutoresGrupo;
                  grupo.alumnosGrupo = alumnosGrupo;
                  grupo.asignaturasGrupo = asignaturasGrupo;
                  grupo.nombreNuevo = nombreNuevo;
                  this.duplicar(grupo);

                }
              }

            });
          });

          if (r1 && r2) {
            grupo.tutoresGrupo = tutoresGrupo;
            grupo.alumnosGrupo = alumnosGrupo;
            grupo.asignaturasGrupo = asignaturasGrupo;
            grupo.nombreNuevo = nombreNuevo;
            this.duplicar(grupo);

          }

        });

      }
    });
  }

  duplicar(grupo) {
    var r1, r2, r3, r4, r5 = false;
    var alumnoskont = 0;
    var tutoreskont = 0;
    var asignaturaskon = 0;
    // insertamos grupo
    this.gruposService.insertGrupo(grupo.nombreNuevo, grupo.id_cursoLectivo, grupo.id_tipoGrupo, grupo.inicio, grupo.fin, grupo.calendario, grupo.activo, grupo.color).subscribe(res => {
      if (!res['error']) {

        var idNuevoGrupo = res['id'];
        r1 = true;


        // insertamos alumnos
        grupo.alumnosGrupo.forEach(alumno => {
          this.alumnosService.insertAlumno(alumno.id_usuario, idNuevoGrupo).subscribe(r => {
            alumnoskont++;
            if (alumnoskont == grupo.alumnosGrupo.length) {
              r2 = true;
              if (r1 && r2 && r3 && r4) {
                //this.cargarDatos();
                this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                this.router.navigate(['../grupos'], { relativeTo: this.route });
              }
            }
          });
        });

        // insertamos tutores
        grupo.tutoresId.forEach(element => {
          this.gruposService.insertTutorGrupo(element, idNuevoGrupo).subscribe(r => {
            tutoreskont++;
            if (tutoreskont == grupo.tutoresId.length) {
              r3 = true;
              if (r1 && r2 && r3 && r4) {
                //this.cargarDatos();
                this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                this.router.navigate(['../grupos'], { relativeTo: this.route });
              }
            }
          });
        });


        // insertamos asignaturas
        grupo.asignaturasGrupo.forEach(asignatura => {
          var docenteskont = 0;
          this.gruposService.insertAsignaturaGrupo(asignatura.id_asignatura, idNuevoGrupo).subscribe(res => {
            // insertamos docentes de asignatura
            asignatura.docentes.forEach(docente => {
              this.gruposService.insertDocenteAsignaturaGrupo(docente.id_docente, idNuevoGrupo, asignatura.id_asignatura).subscribe(r => {
                docenteskont++;
                if (docenteskont == asignatura.docentes.length) {
                  asignaturaskon++;
                  if (asignaturaskon == grupo.asignaturasGrupo.length) {
                    r4 = true;
                    if (r1 && r2 && r3 && r4) {
                      this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                      this.router.navigate(['../grupos'], { relativeTo: this.route });
                    }
                  }
                }
              });

            });
          });

        });

        if(r1 && r2 && r3 && r4){
          //this.cargarDatos();
          this.alertService.success(this.translateService.instant('creadocorrectamente'),{ keepAfterRouteChange: true });                 
          this.router.navigate(['../grupos'], { relativeTo: this.route });
        }

      }

    });
  }


  cargarMaquinas() {

    var r1: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1) {
          this.maquinas.forEach(
            maquina => {
              maquina.idMaquina = maquina.id;
              maquina.nombreMaquina = maquina.nombre;
            });
          this.cargar_Filtro();
        }
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1) {
        this.maquinas.forEach(
          maquina => {
            maquina.idMaquina = maquina.id;
            maquina.nombreMaquina = maquina.nombre;
          });
        this.cargar_Filtro();
      }
    }


  }


}





