import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsumibleComponent } from '@app/consumibles/consumible.component';
import { environment } from '@environments/environment';
import { tableColumnInsertLeftIcon } from '@progress/kendo-svg-icons';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/eskola_horariosSecciones`;

@Injectable()
export class Eskola_HorariosSeccionesService {

  constructor(private http: HttpClient) { }

  getHorariosSeccionesCurso(Id_curso:number) {
    return this.http.post<any>(`${baseUrl}/get_horariosSeccionesCurso`, {Id_curso},{ withCredentials: true });
  }

  eliminarHorarioSeccion(Id:number){
    return this.http.post<any>(`${baseUrl}/delete_HorarioSeccion`, {Id},{ withCredentials: true });
 
  }
  insertHorarioSeccionGrupo(FechaInicio, FechaFin, Id_grupoMaquinas, CantidadMaquinas, Id_grupo){
    return this.http.post<any>(`${baseUrl}/insert_HorarioSeccionGrupo`, {FechaInicio, FechaFin, Id_grupoMaquinas, CantidadMaquinas, Id_grupo}, { withCredentials: true });
 
  }
  updateHorarioSeccionGrupo(Id, FechaInicio, FechaFin, Id_grupoMaquinas, CantidadMaquinas, Id_grupo){
    return this.http.post<any>(`${baseUrl}/update_HorarioSeccionGrupo`, {Id, FechaInicio, FechaFin, Id_grupoMaquinas, CantidadMaquinas, Id_grupo},{ withCredentials: true });
 
  }
}
