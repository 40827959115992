import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/historicoMantenimientos`;

@Injectable()
export class HistoricoMantenimientosService {

  constructor(private http: HttpClient) { }

  Get(idMaquinas: string, fechaIni: string, fechaFin: string, idEstados: string, idMantenimientos: string, idHMantenimientos: string) {
    return this.http.post<JSON>(`${baseUrl}/Get`, { idMaquinas, fechaIni, fechaFin, idEstados, idMantenimientos, idHMantenimientos }, { withCredentials: true });
  }
  update_fachas(idHMantenimientos: number, fecha: string, fechaIni: string, fechaFin: string, ultimo: boolean, idMaquina: number, idMaquinaMantenimiento: number) {
    return this.http.post<JSON>(`${baseUrl}/update_fachas`, { idHMantenimientos, fecha, fechaIni, fechaFin, ultimo, idMaquina, idMaquinaMantenimiento }, { withCredentials: true });
  }
  update_estado(idHMantenimientos: number, idEstado: number) {
    return this.http.post<JSON>(`${baseUrl}/update_estado`, { idHMantenimientos, idEstado }, { withCredentials: true });
  }
  delete(idMaquina: number, idMaquinaMantenimiento: number, idHMantenimiento: number, idTipo: number) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { idMaquina, idMaquinaMantenimiento, idHMantenimiento, idTipo }, { withCredentials: true });
  }
  insert(idMantenimiento: number) {
    return this.http.post<JSON>(`${baseUrl}/insert`, { idMantenimiento }, { withCredentials: true });
  }
}
