import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/incidencias`;

@Injectable()
export class IncidenciasService {

  constructor(private http: HttpClient) { }

  public Get_Incidencias(id, idMaquina, abierto, fini, ffin, finiPeriodoAnterior): Observable<any> {
    return this.http.get(baseUrl + "/" + id + "/" + idMaquina + "/" + abierto + "/" + fini + "/" + ffin + "/" + finiPeriodoAnterior);
  }

  public Get_Incidencias2(id, idMaquina, abierto): Observable<any> {
    return this.http.get(baseUrl + "/" + id + "/" + idMaquina + "/" + abierto);
  }

  Insert_Incidencia(data, envio) {
    return this.http.post<any>(baseUrl + "/crear/" + envio, { id: -1, idMaquina: data.idMaquina, fechaIncidencia: data.fechaIncidencia.toISOString(),
      afectaProduccion: data.afectaProduccion, causa: data.causa, cliente: data.cliente, descripcion: data.descripcion, acciones: data.acciones,
      incidenciaAcciones: data.incidenciaAcciones, incidenciaFicheros: data.incidenciaFicheros, gravedad: data.gravedad, 
      idPerdida: data.idPerdida, observaciones: data.observaciones,
      seguimiento: data.seguimiento, idHistoricoOperaciones: data.idHistoricoOperaciones}, { withCredentials: true });
  }

  getIncidenciasPiezas(data) {
    return this.http.post<any>(baseUrl + "/getTablaPiezasIncidencias/", { 
      idOFs: data.idOFs, idOFs_Piezas: data.idOFs_Piezas, idOFs_Partes: data.idOFs_Partes, 
      idOfs_Operacion: data.idOfs_Operacion, idOfs_Rutas: data.idOfs_Rutas});
  }

  Update_Incidencia(data, envio) {
    return this.http.post<any>(baseUrl + "/editar/" + envio, { id: data.id, idMaquina: data.idMaquina, fechaIncidencia: data.fechaIncidencia.toISOString(),
      afectaProduccion: data.afectaProduccion, causa: data.causa, cliente: data.cliente, descripcion: data.descripcion, acciones: data.acciones,
      incidenciaAcciones: data.incidenciaAcciones, gravedad: data.gravedad, idPerdida: data.idPerdida, observaciones: data.observaciones,
    seguimiento: data.seguimiento, idHistoricoOperaciones: data.idHistoricoOperaciones}, { withCredentials: true });
  }

  Delete_Incidencias(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  Copiar_Incidencias(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/copiar", formData, { withCredentials: true });
  }

  Imprimir_Incidencias(data: any, idMaq): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/imprimir/" + idMaq, formData, { withCredentials: true });
  }

  Cerrar_Incidencia(id): Observable<any> {
    return this.http.post<JSON>(baseUrl + "/cerrar/" + id, { withCredentials: true });
  }

  Reabrir_Incidencia(id): Observable<any> {
    return this.http.post<JSON>(baseUrl + "/reabrir/" + id, { withCredentials: true });
  }

  // Acciones
  public Get_Acciones(idIncidencia, id): Observable<any> {
    return this.http.get(baseUrl + "/acciones/" + idIncidencia + "/" + id);
  }

  // Archivos
  public Get_Archivos(idIncidencia, id): Observable<any> {
    return this.http.get(baseUrl + "/archivos/" + idIncidencia + "/" + id);
  }

  Insert_Archivo(archivo, idIncidencia, nombre) {
    return this.http.post<any>(baseUrl + "/archivos/crear/", { idIncidencia: idIncidencia, nombre: nombre,
      ruta: archivo}, { withCredentials: true });
  }

  Update_Archivo(archivo, idIncidencia, nombre, id) {
    return this.http.post<any>(baseUrl + "/archivos/editar/", { id: id, idIncidencia: idIncidencia, nombre: nombre,
      ruta: archivo}, { withCredentials: true });
  }

  download(nombre): Observable<any> {
    return this.http.get(baseUrl + "/download/" + nombre);
  }

  Delete_Archivos(data: any, idIncidencia): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);
    return this.http.post<JSON>(baseUrl + "/eliminarArchivos/"+idIncidencia, formData, { withCredentials: true });
  }

  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

}
