import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';
import { TipoUsuario } from './_models';

import { HomeComponent } from './home';
import { Home_v2Component } from './home/home_v2.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { ConfiguracionPlanificadorComponent } from './configuracion/configuracionPlanificador.component';
import { ConfiguracionCalidadComponent } from './configuracion/configuracionCalidad.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';

import { RepercusionesPerdidasComponent } from './repercusiones/perdidasnuevo/repercusionesperdidas.component';
import { RepercusionesMaquinasComponent } from './repercusiones/maquinasnuevo/repercusionesmaquinas.component';

import { ControlasignacionesComponent } from './controlasignaciones/controlasignaciones.component';
import { PlanesdeaccionComponent } from './plandeaccion/planesdeaccion.component';
import { PlandeaccionDetalleComponent } from './plandeaccion/detalle/plandeaccionDetalle.component';
import { AccionDetalleComponent } from './acciones/accionDetalle.component';
import { ValidadorComponent } from './validador/validador.component';
import { BonosComponent } from './bonos/bonos.component';
import { SubcontratadoComponent } from './subcontratado/subcontratado.component';
import { SubcontratadosComponent } from './subcontratado/subcontratados.component';
import { HistoricoOperacionesComponent } from './historicoOperaciones/historicoOperaciones.component';
import { HistoricoOperacionesInduccionComponent } from './historicoOperaciones/historicoOperacionesInduccion.component';
import { HistoricoProcesosComponent } from './historicoOperaciones/historicoProcesos.component';
import { HistoricoProcesosInduccionComponent } from './historicoOperaciones/historicoProcesosInduccion.component';
import { HistoricoPiezasComponent } from './historicoOperaciones/historicoPiezas.component';
import { OperacionesDetalleComponent } from './operaciones/operacionesDetalle.component';
import { OperacionesPredefinidasDetalleComponent } from './operacionesPredefinidas/operacionesPredefinidasDetalle/operacionesPredefinidasDetalle.component';
import { OperacionesPredefinidasComponent } from './operacionesPredefinidas/operacionesPredefinidas.component';
import { PiezaDetalleComponent } from './piezas/piezaDetalle.component';
import { OFsComponent } from './proyectos/OFs.component';
import { OFDetalleComponent } from './proyectos/OFDetalle.component';
import { DiccionarioPerdidasComponent } from './diccionarioPerdidas/diccionarioPerdidas.component';
import { DiccionarioPerdidaDetalleComponent } from './diccionarioPerdidas/diccionarioPerdidaDetalle/diccionarioPerdidaDetalle.component';
import { InformeRendimientoComponent } from './informeRendimiento/informeRendimiento.component';
import { InformeTecnicoEjecucionComponent } from './informeTecnicoEjecucion/informeTecnicoEjecucion.component';
import { InformeElectricoComponent } from './informeElectrico/informeElectrico.component';
import { CompatibilidadmaquinasComponent } from './compatibilidadmaquinas/compatibilidadmaquinas.component';
import { RutasDetalleComponent } from './rutas/rutasDetalle.component';
import { RutasPredefinidasDetalleComponent } from './rutasPredefinidas/rutasPredefinidasDetalle.component';
import { InformeOEEComponent } from './informesOEE/informeOEE.component';
import { InformeDisponibilidadOEEComponent } from './informesOEE/informeDisponibilidadOEE.component';
import { InformeRendimientoOEEComponent } from './informesOEE/informeRendimientoOEE.component';
import { InformeCalidadOEEComponent } from './informesOEE/informeCalidadOEE.component';
import { InformeOEEComponent_V2 } from './informesOEE_V2/informeOEE_V2.component';
import { InformeDisponibilidadOEEComponent_V2 } from './informesOEE_V2/informeDisponibilidadOEE_V2.component';
import { InformeRendimientoOEEComponent_V2 } from './informesOEE_V2/informeRendimientoOEE_V2.component';
import { InformeCalidadOEEComponent_V2 } from './informesOEE_V2/informeCalidadOEE_V2.component';
import { PlanificadorLargoMaquinaComponent } from './planificador/planificadorLargoMaquina.component';
import { PlanificadorLargoMaquinasComponent } from './planificador/planificadorLargoMaquinas.component';
import { PlanificadorLargoComparativaVersionesComponent } from './planificador/planificadorLargoComparativaVersiones.component';
import { PlanificadorCortoComponent } from './planificador/planificadorCorto.component';
import { PlanificadorCortoTemporalComponent } from './planificador/PlanificadorCortoTemporal.component';
import { PlanificadorLista } from './planificador/PlanificadorLista.component';
import { PlanificadorHerramientasComponent } from './planificador/planificadorHerramientas.component';
import { MantenimientosPredefinidosComponent } from './mantenimientosPredefinidos/mantenimientosPredefinidos.component';
import { MantenimientosPredefinidosDetalleComponent } from './mantenimientosPredefinidos/mantenimientosPredefinidosDetalle/mantenimientosPredefinidosDetalle.component';
import { MaquinasComponent } from './maquinas/maquinas.component';
import { InstalacionesComponent } from './instalaciones/instalaciones.component';
import { MaquinaDetalleComponent } from './maquinas/maquinasTiposDetalle/maquinaDetalle.component';
import { ExtrusoraDetalleComponent } from './maquinas/maquinasTiposDetalle/extrusoraDetalle.component';
import { ImpresoraPlasticoDetalleComponent } from './maquinas/maquinasTiposDetalle/impresoraPlasticoDetalle.component';
import { InyectoraDetalleComponent } from './maquinas/maquinasTiposDetalle/inyectoraDetalle.component';
import { HornoDetalleComponent } from './maquinas/maquinasTiposDetalle/hornoDetalle.component';
import { HornoTempleDetalleComponent } from './maquinas/maquinasTiposDetalle/hornoTempleDetalle.component';
import { AditivoDetalleComponent } from './maquinas/maquinasTiposDetalle/aditivoDetalle.component';
import { InstalacionDetalleComponent } from './instalaciones/instalacionesDetalle/instalacionDetalle.component';
import { CampoDetalleComponent } from './instalaciones/camposDetalle/campoDetalle.component';
import { MaquinaDetalleEjeComponent } from './maquinaDetalle/maquinaDetalleEje.component';
import { MaquinaDetalleAlarmasControladasComponent } from './maquinaDetalle/maquinaDetalleAlarmasControladas.component';
import { MaquinaDetalleSubEstadosComponent } from './maquinaDetalle/maquinaDetalleSubEstados.component';
import { MaquinaDetalleAlarmasPasivasComponent } from './maquinaDetalle/maquinaDetalleAlarmasPasivas.component';
import { MaquinaDetalleMantenimientoComponent } from './maquinaDetalle/maquinaDetalleMantenimiento.component';
import { MaquinaDetalleManualComponent } from './maquinaDetalle/maquinaDetalleManual.component';
import { MaquinaDetalleManualCarpetaComponent } from './maquinaDetalle/maquinaDetalleManualCarpeta.component';
import { MaquinaDetalleCapaComponent } from './maquinaDetalle/maquinaDetalleCapa.component';
import { HMIComponent } from './hmi/hmi.component';
import { HMIOfflineComponent } from './hmi/hmioffline.component';
import { HMIExtrusorasComponent } from './hmi/hmiExtrusoras.component';
import { HMIImpresorasComponent } from './hmi/hmiImpresoras.component';
import { HMIInyectorasComponent } from './hmi/hmiInyectoras.component';
import { HMIHornosComponent } from './hmi/hmiHornos.component';
import { HMIHornosTempleComponent } from './hmi/hmiHornosTemple.component';
import { HMIAditivoComponent } from './hmi/hmiAditivo.component';
import { HMIInstalacionesComponent } from './hmi/hmiInstalaciones.component';
import { InformeComparativaRendimientoComponent } from './informeComparativaRendimiento/informeComparativaRendimiento.component';
import { InformeEjecuciones } from './informeEjecuciones/informeEjecuciones.component';
import { InformeConsumo } from './informeConsumo/informeConsumo.component';
import { MaquinasGruposComponent } from './maquinasGrupos/maquinasGrupos.component';
import { EditarCrearMaquinasGrupoComponent } from './maquinasGrupos/editar-crear-maquinasGrupo/editar-crear-maquinasGrupo.component';
import { MaquinasGruposPlanificadorComponent } from './maquinasGruposPlanificador/maquinasGruposPlanificador.component';
import { EditarCrearMaquinasGrupoPlanificadorComponent } from './maquinasGruposPlanificador/editar-crear-maquinasGrupoPlanificador/editar-crear-maquinasGrupoPlanificador.component';
import { InformeCalculoDisponibilidadComponent } from './disponibilidad/informes/informeCalculoDisponibilidad.component';
import { InformesIncidenciasComponent } from './disponibilidad/informes/informesIncidencias.component';
import { InformeIncidenciaComponent } from './disponibilidad/informes/informeIncidencia.component';
import { TurnosDisponibilidadComponent } from './disponibilidad/turnos/turnosDisponibilidad.component';
import { TurnoDisponibilidadoDetalleComponent } from './disponibilidad/turnos/turnoDetalle/turnoDisponibilidadDetalle.component';
import { ControlesComponent } from './controles/controles.component';
import { ControlesDetalleComponent } from './controles/controlesDetalle/controlesDetalle.component';
import { MarcasComponent } from './marcas/marcas.component';
import { MarcasDetalleComponent } from './marcas/marcasDetalle/marcasDetalle.component';
import { HistoricoAlarmasComponent } from './historicoAlarmas/historicoAlarmas.component';
import { HistoricoParametrosComponent } from './historicoParametros/historicoParametros.component';
import { SeguimientosProyectosComponent } from './seguimientosProyectos/seguimientosProyectos.component';
import { HistoricoMantenimientosComponent } from './historicoMantenimientos/historicoMantenimientos.component';
import { ConsumiblesComponent } from './consumibles/consumibles.component';
import { ConsumibleComponent } from './consumibles/consumible.component';
import { ConsumiblesTiposComponent } from './consumibles/consumiblesTipos.component';
import { ConsumibleTipoComponent } from './consumibles/consumibleTipo.component';
import { GestionConsumiblesComponent } from './consumibles/gestion-consumibles.component';
import { NotasComponent } from './notas/notas.component';
import { NotasCrearComponent } from './notas/notas-crear/notas-crear.component';
import { NotasEditarComponent } from './notas/notas-editar/notas-editar.component';
import { MovimientosConsumiblesComponent } from './consumibles/movimientos-consumibles.component';


import { HistoricoConsumibles } from './consumibles/historicoConsumibles.component';
import { HistoricoConsumible } from './consumibles/historicoConsumible.component';
import { HistoricoConsumibleNserie } from './consumibles/historicoConsumibleNserie.component';
import { HistoricoConsumibleTipo } from './consumibles/historicoConsumibleTipo.component';
import { VistaConsumibles } from './consumibles/vistaConsumibles.component';
import { HerramientasComponent } from './herramientas/herramientas.component';
import { HerramientasDetalleComponent } from './herramientas/herramientasDetalle/herramientasDetalle.component';
import { TiposHerramientasComponent } from './herramientas/tiposHerramientas.component';
import { TiposHerramientasDetalleComponent } from './herramientas/tiposHerramientasDetalle/tiposHerramientasDetalle.component';
import { PlacasDetalleComponent } from './herramientas/placasDetalle/placasDetalle.component';
import { HerramientasUnidadesComponent } from './herramientasUnidades/herramientasUnidades.component';
import { HerramientasUnidadesDetalleComponent } from './herramientasUnidades/herramientasUnidadesDetalle/herramientasUnidadesDetalle.component';
import { PlacasUnidadesDetalleComponent } from './herramientasUnidades/placasUnidadesDetalle/placasUnidadesDetalle.component';
import { AlmacenesComponent } from './almacenes/almacenes.component';
import { AlmacenesDetalleComponent } from './almacenes/almacenesDetalle/almacenesDetalle.component';
import { FabricantesComponent } from './fabricantes/fabricantes.component';
import { FabricantesDetalleComponent } from './fabricantes/fabricantesDetalle/fabricantesDetalle.component';
import { PiezasPredefinidasComponent } from './piezasPredefinidas/piezasPredefinidas.component';
import { PiezasPredefinidasDetalleComponent } from './piezasPredefinidas/piezasPredefinidasDetalle/piezasPredefinidasDetalle.component';
import { TurnosComponent } from './turnos/turnos.component';
import { TurnoDetalleComponent } from './turnos/turnoDetalle/turnoDetalle.component';
import { InformeProyectosComponent_v2 } from './informeProyectos/informeProyectos_v2.component';
import { AnaliticaAvanzadaEjecucionesComponent } from './analiticaAvanzadaEjecuciones/analiticaAvanzadaEjecuciones.component';
import { AnaliticaAvanzadaEjecucionesComponent_V2 } from './analiticaAvanzadaEjecuciones_V2/analiticaAvanzadaEjecuciones.component_V2';
import { InformeComparativaProyectosComponent } from './informeComparativaProyectos/informeComparativaProyectos.component';
import { VistaAlmacenesComponent } from './vistaAlmacenes/vistaAlmacenes.component';
import { VistaHerramientasPlacasComponent } from './vistaHerramientasPlacas/vistaHerramientasPlacas.component';
import { MaterialesTipoComponent } from './materialesTipo/materialesTipo.component';
import { MaterialesTipoDetalleComponent } from './materialesTipo/materialesTipoDetalle/materialesTipoDetalle.component';

import { Eskola_AsignaturasRetosComponent } from './eskola_asignaturasRetos/eskola_asignaturasRetos.component';
import { Eskola_AsignaturasRetosDetalleComponent } from './eskola_asignaturasRetos/eskola_asignaturasRetosDetalle/eskola_asignaturasRetosDetalle.component';

import { Eskola_CursosLectivosComponent } from './eskola_cursosLectivos/eskola_cursosLectivos.component';
import { Eskola_CursosLectivosDetalleComponent } from './eskola_cursosLectivos/eskola_cursosLectivosDetalle/eskola_cursosLectivosDetalle.component';

import { EskolaGruposComponent } from './eskola_grupos/eskola_grupos.component';
//import { AlumnosComponent } from './eskola_alumnos/eskola_alumnos.component';

import { EskolaAlumnosComponent } from './eskola_alumnos/eskola_alumnos.component';
import { Eskola_AlumnosDetalleComponent } from './eskola_alumnos/eskola_alumnosDetalle/eskola_alumnosDetalle.component';

import { Eskola_DocentesComponent } from './eskola_docentes/eskola_docentes.component';

import { EskolaCalendariosComponent } from './eskola_calendarios/eskola_calendarios.component';
import { CalendariosDetalleComponent } from './eskola_calendarios/eskola_calendariosDetalle/eskola_calendariosDetalle.component';
import { Eskola_LanzadorOfComponent } from './eskola_lanzadorOf/eskola_lanzadorOf';
import { Eskola_HorariosSecciones } from './eskola_horariosSecciones/eskola_horariosSecciones.component';
import { Eskola_InformeAlumnoComponent } from './eskola_informeAlumno/eskola_informeAlumno.component';
//import { CalendariosComponent } from './calendarios/calendarios.component';
//import { CalendariosDetalleComponent } from './calendarios/calendariosDetalle/calendariosDetalle.component';

import { AlarmasTipoComponent } from './alarmasTipo/alarmasTipo.component';
import { AlarmasTipoDetalleComponent } from './alarmasTipo/alarmasTipoDetalle/alarmasTipoDetalle.component';
import { PiezasTipoComponent } from './piezasTipo/piezasTipo.component';
import { PiezasTipoDetalleComponent } from './piezasTipo/piezasTipoDetalle/piezasTipoDetalle.component';
import { OperacionesTipoComponent } from './operacionesTipo/operacionesTipo.component';
import { OperacionesTipoDetalleComponent } from './operacionesTipo/operacionesTipoDetalle/operacionesTipoDetalle.component';
import { PlantasIsometricoComponent } from './plantaIsometrico/plantasIsometrico.component';
import { DiseñadorPlantaIsometricoComponent } from './plantaIsometrico/diseñadorPlantaIsometrico.component';
import { HomePlantaIsometricoComponent } from './plantaIsometrico/homePlantaIsometrico.component';
import { AreasProductivasComponent } from './areasProductivas/areasProductivas.component';
import { AreaProductivaComponent } from './areasProductivas/areaProductiva.component';
import { HistoricoDatosComponent } from './historicoDatos/historicoDatos.component';
import { VisorProgramasComponent } from './visor-programas/visor-programas.component';
import { VisorVersionComponent } from './visor-version/visor-version.component';
import { PiezasBoxplotComponent } from './piezas-boxplot/piezas-boxplot.component';
import { OperacionesBoxplotComponent } from './operaciones-boxplot/operaciones-boxplot.component';
import { ComparativaTiemposBoxplotComponent } from './comparativa-tiempos-boxplot/comparativa-tiempos-boxplot.component';
import { PerdidasGruposComponent } from './diccionarioPerdidas-grupos/perdidas-grupos.component';
import { EditarCrearDiccionarioPerdidasGruposComponent } from './diccionarioPerdidas-grupos/editar-crear-diccionario-perdidas-grupos/editar-crear-diccionario-perdidas-grupos.component';
import { AsignacionTiemposComponent } from './asignacion-tiempos/asignacion-tiempos.component';
import { ClientesOrdenComponent } from './clientes-orden/clientes-orden.component';
import { EditarCrearClientesComponent } from './clientes-orden/editar-crear-clientes/editar-crear-clientes.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { TiposActivoComponent } from './tipos-activo/tipos-activo.component';
import { TiposActivoDetalleComponent } from './tipos-activo/tipos-activo-detalle/tipos-activo-detalle.component';
import { SeccionesDetalleComponent } from './secciones-detalle/secciones-detalle.component';
import { InformeCalidadComponent } from './informe-calidad/informe-calidad.component';
import { ActivosMaestrosComponent } from './activos_maestros/activosMaestros.component';
import { ActivosMaestrosDetalleComponent } from './activos_maestros/activosMaestros-detalle/activosMaestros-detalle.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { ActivosMaestrosDocumentoComponent } from './activos_maestros/activos-maestros-documento/activos-maestros-documento.component';
import { ProveedorDetalleComponent } from './proveedores/proveedor-detalle/proveedor-detalle.component';
import { ActivosMaestrosProveedorComponent } from './activos_maestros/activos-maestros-proveedor/activos-maestros-proveedor.component';
import { ActivosMaestrosVistArbolComponent } from './activos_maestros/activos-maestros-vist-arbol/activos-maestros-vist-arbol.component';
import { ArbolActivosMaestrosComponent } from './arbol-activos-maestros/arbol-activos-maestros.component';
import { ActivosComponent } from './activos/activos.component';
import { ActivosDetalleComponent } from './activos/activos-detalle/activos-detalle.component';
import { ActivosDocumentoComponent } from './activos/activos-documento/activos-documento.component';
import { StockComponent } from './stock/stock.component';
import { GeneradorPedidoComponent } from './stock/generador-pedido/generador-pedido.component';
import { PiezasDocumentoComponent } from './piezas/piezas-documento/piezas-documento.component';
import { PiezasPredefinidasDocumentoComponent } from './piezasPredefinidas/piezasPredefinidasDetalle/piezasPredefinidas-documento/piezasPredefinidas-documento.component';
import { HistogramaTiempoPiezaComponent } from './histogramaTiempoPieza/histogramaTiempoPieza.component';
import { RecetasDetalleComponent } from './recetas/recetasDetalle.component';
import { RecetasInduccionComponent } from './recetas/recetasInduccion.component';
import { RecetasInyeccionComponent } from './recetas/recetasInyeccion.component';
import { ToleranciasTipoComponent } from './toleranciasTipo/toleranciasTipo.component';
import { ToleranciasTipoDetalleComponent } from './toleranciasTipo/toleranciasTipoDetalle/toleranciasTipoDetalle.component';
import { InformeTempladoComponent } from './informeTemplado/informeTemplado.component';
//import { InformesPersonalizadosComponent } from './informesPersonalizados/informesPersonalizados.component';
import { CursosLectivosGruposComponent } from './eskola_cursoslectivosgrupos/eskola_cursoslectivosgrupos.component';
import { CursosLectivosGruposTodosComponent } from './eskola_cursoslectivosgrupos/eskola_cursoslectivosgrupostodos.component';
import { CursosLectivosTiposComponent } from './eskola_cursoslectivostipos/eskola_cursoslectivostipos.component';
import { CursosLectivosTiposTodosComponent } from './eskola_cursoslectivostipos/eskola_cursoslectivostipostodos.component';
import { InformePersonalizadoComponent } from './informePersonalizado/informePersonalizado.component';
import { InformesPersonalizadosDisenadorComponent } from './informesPersonalizadosDisenador/informesPersonalizadosDisenador.component';
import { CrearInformesPersonalizadosDisenadorComponent } from './informesPersonalizadosDisenador/crearInformesPersonalizados/crearInformesPersonalizadosDisenador.component';
import { EditarInformesPersonalizadosDisenadorComponent } from './informesPersonalizadosDisenador/editarInformesPersonalizados/editarInformesPersonalizadosDisenador.component';
import { Eskola_VistaAnualComponent } from './eskola_horariosSecciones/eskola_horariosSecciones_vistaAnual/eskola_horariosSecciones_vistaAnual.component';
import { TurnosOperariosComponent } from './turnosOperarios/turnosOperarios.component';
import { Eskola_GruposDetalleComponent } from './eskola_grupos/eskola_gruposDetalle/eskola_gruposDetalle.component';
import { Eskola_DocentesDetalleComponent } from './eskola_docentes/eskola_docentesDetalle/eskola_docentesDetalle.component';
import { Eskola_ReservaMaquinasComponent } from './eskola_reservaMaquinas/eskola_reservaMaquinas.component';
import { HometvComponent } from './home_tv/home_tv.component';
import { Hometv_v2Component } from './home_tv/home_tv_v2.component';
import { AtributosComponent } from './atributos/atributos.component';
// import { CrearAtributosComponent } from './atributos/crearAtributos/crearAtributos.component';
import { EditarAtributosComponent } from './atributos/editarAtributos/editarAtributos.component';
import { InformeProyectosComponent } from './informeProyectos/informeProyectos.component';
import { AnalisisRendimientoComponent } from './informes/analisisRendimiento/analisisRendimiento.component';
import { ClasificacionesComponent } from './clasificaciones/clasificaciones.component';
// import { ClasificacionesCrearComponent } from './clasificaciones/clasificaciones-crear/clasificaciones-crear.component';
import { ClasificacionesEditarComponent } from './clasificaciones/clasificaciones-editar/clasificaciones-editar.component';
import { ContenedoresComponent } from './contenedores/contenedores.component';
import { ContenedorDetalleComponent } from './contenedores/contenedorDetalle/contenedorDetalle.component';
import { InformeContenedorDetalleComponent } from './informeContenedores/informeContenedorDetalle/informeContenedorDetalle.component';
import { InformeContenedoresComponent } from './informeContenedores/informeContenedores.component';
import { BloqueoRangosComponent } from './bloqueoRangos/bloqueoRangos.component';
import { BloqueoRangosDetalleComponent } from './bloqueoRangos/bloqueoRangosDetalle/bloqueoRangosDetalle.component';
import { GruposPlanificadorComponent } from './gruposPlanificador/gruposPlanificador.component';
import { AutomatizacionesComponent } from './automatizaciones/automatizaciones.component';
import { AutomatizacionDetalleComponent } from './automatizaciones/automatizacionDetalle.component';
import { SeguimientoOFsConfiguradorComponent } from './seguimientoOFsConfigurador/seguimientoOFsConfigurador.component';
import { SeguimientoOFsConfiguradorDetalleComponent } from './seguimientoOFsConfigurador/seguimientoOFsConfiguradorDetalle/seguimientoOFsConfiguradorDetalle.component';

const loginModule = () => import('./login/login.module').then(x => x.LoginModule);

const routes: Routes = [
  { path: '', component: HomeComponent, loadChildren: loginModule, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { permisos: ["home==1"] } },
  { path: 'homev2', component: Home_v2Component, canActivate: [AuthGuard], data: { permisos: ["home==1"] } },
  { path: 'login', loadChildren: loginModule },
  {
    path: 'configuracion', component: ConfiguracionComponent, canActivate: [AuthGuard]
  },
  {
    path: 'configuracionPlanificador', component: ConfiguracionPlanificadorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'configuracionCalidad', component: ConfiguracionCalidadComponent, canActivate: [AuthGuard]
  },
  {
    path: 'profile', component: DetailsComponent, canActivate: [AuthGuard],
    children: [
      { path: 'update', component: UpdateComponent, canActivate: [AuthGuard] }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard], data: { permisos: ["GestionUsuarios==1"] },
    children: [
      {
        path: 'editar/:id', component: EditarCrearUsuarioComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: EditarCrearUsuarioComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'usuarios-tipos', component: UsuariosTiposComponent, canActivate: [AuthGuard], data: { permisos: ["GestionUsuarios==1"] },
    children: [
      {
        path: 'editar/:id', component: EditarCrearUsuarioTipoComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: EditarCrearUsuarioTipoComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  { path: 'repercusiones/perdidas', component: RepercusionesPerdidasComponent, canActivate: [AuthGuard], data: { permisos: ["perdidas==1"] } },
  { path: 'repercusiones/maquinas', component: RepercusionesMaquinasComponent, canActivate: [AuthGuard], data: { permisos: ["PermisoMaquinas==1"] } },
  { path: 'controlasignaciones', component: ControlasignacionesComponent, canActivate: [AuthGuard], data: { permisos: ["controlDeAsignaciones==1"] } },
  {
    path: 'planesaccion', component: PlanesdeaccionComponent, canActivate: [AuthGuard], data: { permisos: ["planesDeAccion==1"] }, children: [
      {
        path: 'editar/:id', component: PlandeaccionDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: PlandeaccionDetalleComponent, canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'acciones', component: AccionDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["planesDeAccion==1"] }, children: [
      {
        path: 'editar/:id', component: AccionDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: AccionDetalleComponent, canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'validador', component: ValidadorComponent, canActivate: [AuthGuard], data: { permisos: ["validador==1"] }
  },
  {
    path: 'validador/:id/:fechaini/:fechafin', component: ValidadorComponent, canActivate: [AuthGuard], data: { permisos: ["validador==1"] }
  },
  {
    path: 'bonos', component: BonosComponent, canActivate: [AuthGuard], data: { permisos: ["bonos==1"] }
  },
  {
    path: 'bonos/:id/:fechaini/:fechafin', component: BonosComponent, canActivate: [AuthGuard], data: { permisos: ["bonos==1"] }
  },
  {
    path: 'subcontratados', component: SubcontratadosComponent, canActivate: [AuthGuard], data: { permisos: ["planificador==2"] }
  },
  {
    path: 'subcontratado/:id', component: SubcontratadoComponent, canActivate: [AuthGuard], data: { permisos: ["planificador==2"] }
  },
  {
    path: 'historicoOperaciones/:idHistorico_piezas/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: HistoricoOperacionesComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoOperacionesInduccion/:idHistorico_piezas/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: HistoricoOperacionesInduccionComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoProcesos/:idHistorico_piezas/:idHistorico_operaciones/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: HistoricoProcesosComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoProcesosInduccion/:idHistorico_piezas/:idHistorico_operaciones/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: HistoricoProcesosInduccionComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'recetasInduccion/:idHistorico_piezas/:idHistorico_operaciones/:numeroOF/:idReceta/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: RecetasInduccionComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'recetasInyeccion/:idHistorico_piezas/:idHistorico_operaciones/:numeroOF/:idReceta/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: RecetasInyeccionComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoPiezas/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion/:nSerie', component: HistoricoPiezasComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoPiezas/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion', component: HistoricoPiezasComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoPiezas/:fechaIni/:fechaFin/:idturno/:idMaquina/:idHerramienta/:idOperario/:idOf/:idCliente/:idPieza/:idParte/:idOperacion/:idSeccion/:idGrupoMaq', component: HistoricoPiezasComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'historicoPiezas', component: HistoricoPiezasComponent, canActivate: [AuthGuard], data: { permisos: ["historicoPiezas==1"] }
  },
  {
    path: 'planificadorLargoMaquina/:tipo/:id/:idVersion', component: PlanificadorLargoMaquinaComponent, canActivate: [AuthGuard], data: { permisos: ["planificadorLargo==1"] }
  },
  {
    path: 'planificadorLargoMaquinas/:idVersion', component: PlanificadorLargoMaquinasComponent, canActivate: [AuthGuard], data: { permisos: ["planificadorLargo==1"] }
  },
  {
    path: 'planificadorLargoComparativaVersiones', component: PlanificadorLargoComparativaVersionesComponent, canActivate: [AuthGuard], data: { permisos: ["planificadorLargo==1"] }
  },
  {
    path: 'planificadorCorto', component: PlanificadorCortoComponent, canActivate: [AuthGuard], data: { permisos: ["planificadorCorto==1"] }
  },
  {
    path: 'planificadorCortoTemporal/:version/:tiempoEstimado/:ineficiencia', component: PlanificadorCortoTemporalComponent, canActivate: [AuthGuard], data: { permisos: ["planificadorCorto==1"] }
  },
  {
    path: 'planificadorLista/:version/:tipo/:idMaquina', component: PlanificadorLista, canActivate: [AuthGuard], data: { permisos: ["planificadorLista==1"] }
  },
  {
    path: 'planificadorHerramientas/:version/:tiempoEstimado/:ineficiencia', component: PlanificadorHerramientasComponent, canActivate: [AuthGuard], data: { permisos: ["planificadorHerramientas==1"] }
  },
  {
    path: 'OF', component: OFsComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },
    children: [
      {
        path: 'editar/:id', component: OFDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: OFDetalleComponent, canActivate: [AuthGuard]
      }
    ]

  },
  {
    path: 'informeOEE_V2', component: InformeOEEComponent, canActivate: [AuthGuard], data: { permisos: ["informeOEE==1"] }
  },
  {
    path: 'informeDisponibilidadOEE_V2', component: InformeDisponibilidadOEEComponent, canActivate: [AuthGuard], data: { permisos: ["informeDisponibilidad==1"] }
  },
  {
    path: 'informeRendimientoOEE_V2', component: InformeRendimientoOEEComponent, canActivate: [AuthGuard], data: { permisos: ["informeRendimiento==1"] }
  },
  {
    path: 'informeCalidadOEE_V2', component: InformeCalidadOEEComponent, canActivate: [AuthGuard], data: { permisos: ["informeCalidad==1"] }
  },
  {
    path: 'informeOEE', component: InformeOEEComponent_V2, canActivate: [AuthGuard], data: { permisos: ["informeOEE==1"] }
  },
  {
    path: 'informeDisponibilidadOEE', component: InformeDisponibilidadOEEComponent_V2, canActivate: [AuthGuard], data: { permisos: ["informeDisponibilidad==1"] }
  },
  {
    path: 'informeRendimientoOEE', component: InformeRendimientoOEEComponent_V2, canActivate: [AuthGuard], data: { permisos: ["informeRendimiento==1"] }
  },
  {
    path: 'informeCalidadOEE', component: InformeCalidadOEEComponent_V2, canActivate: [AuthGuard], data: { permisos: ["informeCalidad==1"] }
  },
  {
    path: 'piezas', canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },
    children: [
      {
        path: 'editar/:idof/:idpieza/:idparte', component: PiezaDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idof', component: PiezaDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'documento/:idOf/:idPieza/:idParte/:idRuta/:idDocumento/:idVista', component: PiezasDocumentoComponent, canActivate: [AuthGuard]
      },
    ]

  },
  {
    path: 'operaciones', canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },
    children: [
      {
        path: 'editar/:idof/:idpieza/:idparte/:idruta/:idoperacion/:idVista', component: OperacionesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idof/:idpieza/:idparte/:idruta/:idVista', component: OperacionesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crearpredefinido/:idpieza/:idparte/:idruta/:pred', component: OperacionesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crearpredefinido/:idof/:idpieza/:idparte/:idruta/:pred', component: OperacionesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editarReceta/:idof/:idpieza/:idparte/:idruta/:idOperacion/:idVista/:id', component: RecetasDetalleComponent, canActivate: [AuthGuard],
      },
      {
        path: 'crearReceta/:idof/:idpieza/:idparte/:idruta/:idOperacion/:idVista', component: RecetasDetalleComponent, canActivate: [AuthGuard],
      },
    ]

  },
  {
    path: 'operacionespredefinidas', component: OperacionesPredefinidasComponent, canActivate: [AuthGuard], data: { permisos: ["operacionesPredefinidas==1"] }
  },
  {
    path: 'operacionespredefinidas', canActivate: [AuthGuard], data: { permisos: ["operacionesPredefinidas==1"] },
    children: [
      {
        path: 'editar/:idoperacion', component: OperacionesPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: OperacionesPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idpieza/:idparte/:idruta/:idVista', component: OperacionesPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:idpieza/:idparte/:idruta/:idoperacion/:idVista', component: OperacionesPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'anadireredefinido/:idof/:idpieza/:idparte/:idruta/:ispred', component: OperacionesPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editarReceta/:idOperacion/:id', component: RecetasDetalleComponent, canActivate: [AuthGuard],
      },
      {
        path: 'crearReceta/:idOperacion', component: RecetasDetalleComponent, canActivate: [AuthGuard],
      },
    ]

  },
  {
    path: 'diccionarioperdidas', component: DiccionarioPerdidasComponent, canActivate: [AuthGuard], data: { permisos: ["diccionarioPerdidas==1"] },
  },
  {
    path: 'diccionarioperdidascrear/:idPadre', component: DiccionarioPerdidaDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["diccionarioPerdidas==1"] },
  },
  {
    path: 'diccionarioperdidaseditar/:id/:idPadre', component: DiccionarioPerdidaDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["diccionarioPerdidas==1"] },
  },
  {
    path: 'mantenimientospredefinidos', component: MantenimientosPredefinidosComponent, canActivate: [AuthGuard], data: { permisos: ["mantenimientosPredefinidos==1"] },
    children: [
      {
        path: 'editar/:id', component: MantenimientosPredefinidosDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: MantenimientosPredefinidosDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'analisisRendimiento', component: InformeRendimientoComponent, canActivate: [AuthGuard], data: { permisos: ["informeRendimiento==1"] }
  },
  {
    path: 'analisisRendimiento/:idMaquina', component: InformeRendimientoComponent, canActivate: [AuthGuard], data: { permisos: ["informeRendimiento==1"] }
  },
  {
    path: 'informerendimiento', component: AnalisisRendimientoComponent, canActivate: [AuthGuard], data: { permisos: ["informes==1"] }
  },
  {
    path: 'informerendimiento/:idMaquina', component: AnalisisRendimientoComponent, canActivate: [AuthGuard], data: { permisos: ["informes==1"] }
  },
  {
    path: 'informetecnicoejecucion', component: InformeTecnicoEjecucionComponent, canActivate: [AuthGuard], data: { permisos: ["informeTecnicoEjecucion==1"] }
  },
  {
    path: 'informetecnicoejecucion/:idMaquina/:fini/:ffin/:idcliente/:idpieza/:idof/:terminados/:idHistoricoPieza/:idHistoricoOperacion', component: InformeTecnicoEjecucionComponent, canActivate: [AuthGuard], data: { permisos: ["informeTecnicoEjecucion==1"] }
  },
  {
    path: 'informeElectrico', component: InformeElectricoComponent, canActivate: [AuthGuard], data: { permisos: ["informes==1"] }
  },
  {
    path: 'compatibilidadmaquinas', component: CompatibilidadmaquinasComponent, canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] }
  },
  {
    path: 'informeproyectos_v2', component: InformeProyectosComponent, canActivate: [AuthGuard], data: { permisos: ["informes==1"] }
  },
  {
    path: 'informeproyectos', component: InformeProyectosComponent_v2, canActivate: [AuthGuard], data: { permisos: ["informes==1"] }
  },  
  {
    path: 'analiticaavanzadaejecuciones_V2', component: AnaliticaAvanzadaEjecucionesComponent, canActivate: [AuthGuard], data: { permisos: ["analiticaAvanzadaEjecuciones==1"] }
  },
  {
    path: 'analiticaavanzadaejecuciones_V2/:fini/:ffin/:idcliente/:idpieza/:idof/:terminados/:idHistoricoPieza/:idHistoricoOperacion', component: AnaliticaAvanzadaEjecucionesComponent, canActivate: [AuthGuard], data: { permisos: ["analiticaAvanzadaEjecuciones==1"] }
  },
  {
    path: 'analiticaavanzadaejecuciones', component: AnaliticaAvanzadaEjecucionesComponent_V2, canActivate: [AuthGuard], data: { permisos: ["analiticaAvanzadaEjecuciones==1"] }
  },
  {
    path: 'analiticaavanzadaejecuciones/:fini/:ffin/:idcliente/:idpieza/:idof/:terminados/:idHistoricoPieza/:idHistoricoOperacion', component: AnaliticaAvanzadaEjecucionesComponent_V2, canActivate: [AuthGuard], data: { permisos: ["analiticaAvanzadaEjecuciones==1"] }
  },
  {
    path: 'informecomparativaproyectos', component: InformeComparativaProyectosComponent, canActivate: [AuthGuard], data: { permisos: ["informes==1"] }
  },
  {
    path: 'rutas', canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },
    children: [
      {
        path: 'editar/:idof/:idpieza/:idparte/:idruta', component: RutasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idof/:idpieza/:idparte', component: RutasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idpieza/:idparte', component: RutasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:idpieza/:idparte/:idruta', component: RutasDetalleComponent, canActivate: [AuthGuard]
      },

    ]
  },
  {
    path: 'rutaspredefinidas', canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },
    children: [
      {
        path: 'editar/:idof/:idpieza/:idparte/:idruta', component: RutasPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idof/:idpieza/:idparte', component: RutasPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear/:idpieza/:idparte', component: RutasPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:idpieza/:idparte/:idruta', component: RutasPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },

    ]
  },
  {
    path: 'procesos/:id', component: HMIComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesosoffline/:id', component: HMIOfflineComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesosextrusora/:id', component: HMIExtrusorasComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesosimpresora/:id', component: HMIImpresorasComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesosinyectora/:id', component: HMIInyectorasComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesoshorno/:id', component: HMIHornosComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesoshornotemple/:id', component: HMIHornosTempleComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesosaditivo/:id', component: HMIAditivoComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'procesosinstalacion/:id', component: HMIInstalacionesComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto? 
  },
  {
    path: 'maquinas', component: MaquinasComponent, canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: MaquinaDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: MaquinaDetalleComponent, canActivate: [AuthGuard],
      },
      {
        path: 'copiar/:id', component: MaquinaDetalleComponent, canActivate: [AuthGuard],
      },
      {
        path: 'eje/crear/:idMaquina', component: MaquinaDetalleEjeComponent, canActivate: [AuthGuard]
      },
      {
        path: 'eje/editar/:idMaquina/:id', component: MaquinaDetalleEjeComponent, canActivate: [AuthGuard],
      },
      {
        path: 'alarmacontrolada/crear/:idMaquina', component: MaquinaDetalleAlarmasControladasComponent, canActivate: [AuthGuard]
      },
      {
        path: 'alarmacontrolada/editar/:idMaquina/:id', component: MaquinaDetalleAlarmasControladasComponent, canActivate: [AuthGuard],
      },
      {
        path: 'subestado/crear/:idMaquina', component: MaquinaDetalleSubEstadosComponent, canActivate: [AuthGuard]
      },
      {
        path: 'subestado/editar/:idMaquina/:id', component: MaquinaDetalleSubEstadosComponent, canActivate: [AuthGuard],
      },
      {
        path: 'alarmapasiva/crear/:idMaquina', component: MaquinaDetalleAlarmasPasivasComponent, canActivate: [AuthGuard]
      },
      {
        path: 'alarmapasiva/editar/:idMaquina/:id', component: MaquinaDetalleAlarmasPasivasComponent, canActivate: [AuthGuard]
      },
      {
        path: 'mantenimiento/crear/:idMaquina', component: MaquinaDetalleMantenimientoComponent, canActivate: [AuthGuard]
      },
      {
        path: 'mantenimiento/editar/:idMaquina/:id', component: MaquinaDetalleMantenimientoComponent, canActivate: [AuthGuard]
      },
      {
        path: 'manual/crear/:idMaquina', component: MaquinaDetalleManualComponent, canActivate: [AuthGuard]
      },
      {
        path: 'manual/editar/:idMaquina/:id', component: MaquinaDetalleManualComponent, canActivate: [AuthGuard]
      },
      {
        path: 'manualCarpeta/crear/:idMaquina', component: MaquinaDetalleManualCarpetaComponent, canActivate: [AuthGuard]
      },
      {
        path: 'manualCarpeta/editar/:idMaquina/:id', component: MaquinaDetalleManualCarpetaComponent, canActivate: [AuthGuard]
      },
      {
        path: 'capa/crear/:idMaquina', component: MaquinaDetalleCapaComponent, canActivate: [AuthGuard]
      },
      {
        path: 'capa/editar/:idMaquina/:id', component: MaquinaDetalleCapaComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'extrusoras', canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: ExtrusoraDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: ExtrusoraDetalleComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'impresorasPlastico', canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: ImpresoraPlasticoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: ImpresoraPlasticoDetalleComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'inyectoras', canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: InyectoraDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: InyectoraDetalleComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'hornos', canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: HornoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: HornoDetalleComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'hornosTemple', canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: HornoTempleDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: HornoTempleDetalleComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'aditivo', canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'crear', component: AditivoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: AditivoDetalleComponent, canActivate: [AuthGuard],
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'instalaciones', component: InstalacionesComponent, canActivate: [AuthGuard], data: { permisos: ["instalaciones==1"] },
    children: [
      {
        path: 'crear', component: InstalacionDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: InstalacionDetalleComponent, canActivate: [AuthGuard],
      },
      {
        path: 'editar/:idInstalacion/editarcampo/:idCampo', component: CampoDetalleComponent, canActivate: [AuthGuard]
      }
      ,
      {
        path: 'editar/:idInstalacion/crearcampo', component: CampoDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'informecomparativarendimiento', component: InformeComparativaRendimientoComponent, canActivate: [AuthGuard], data: { permisos: ["comparativaRendimiento==1"] }
  },
  {
    path: 'informeejecuciones', component: InformeEjecuciones, canActivate: [AuthGuard], data: { permisos: ["informeEjecuciones==1"] }
  },
  {
    path: 'informeconsumo', component: InformeConsumo, canActivate: [AuthGuard], data: { permisos: ["informeDeConsumo==1"] }
  },
  {
    path: 'maquinasgrupos', component: MaquinasGruposComponent, canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'editar/:id', component: EditarCrearMaquinasGrupoComponent, canActivate: [AuthGuard],
      },
      {
        path: 'crear', component: EditarCrearMaquinasGrupoComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'maquinasgruposplanificador', component: MaquinasGruposPlanificadorComponent, canActivate: [AuthGuard], data: { permisos: ["maquinas==1"] },
    children: [
      {
        path: 'editar/:id', component: EditarCrearMaquinasGrupoPlanificadorComponent, canActivate: [AuthGuard],
      },
      {
        path: 'crear', component: EditarCrearMaquinasGrupoPlanificadorComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'incormecalculodisponibilidad', component: InformeCalculoDisponibilidadComponent, canActivate: [AuthGuard], data: { permisos: ["informeDisponibilidad==1"] }
  },
  {
    path: 'informesincidencias', component: InformesIncidenciasComponent, canActivate: [AuthGuard], data: { permisos: ["informesIncidencias==1"] },
  },
  {
    path: 'informesincidencias/editar/:id', component: InformeIncidenciaComponent, canActivate: [AuthGuard], data: { permisos: ["informesIncidencias==1"] },
  },
  {
    path: 'informesincidencias/crear/:idMaq', component: InformeIncidenciaComponent, canActivate: [AuthGuard], data: { permisos: ["informesIncidencias==1"] },
  },
  //{
  //  path: 'informesincidencias', component: InformesIncidenciasComponent, canActivate: [AuthGuard], data: { permisos: ["informesIncidencias==1"] },
  //  children: [
  //    {
  //      path: 'editar/:id', component: InformeIncidenciaComponent, canActivate: [AuthGuard],
  //    },
  //    {
  //      path: 'crear/:idMaq', component: InformeIncidenciaComponent, canActivate: [AuthGuard]
  //    }
  //  ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  //},
  {
    path: 'turnosDisponibilidad', component: TurnosDisponibilidadComponent, canActivate: [AuthGuard], data: { permisos: ["calendarioDisponibilidad==1"] },
    children: [
      {
        path: 'crear', component: TurnoDisponibilidadoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: TurnoDisponibilidadoDetalleComponent, canActivate: [AuthGuard],
      },
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },

  {
    path: 'controles', component: ControlesComponent, canActivate: [AuthGuard], data: { permisos: ["controles==1"] },
    children: [
      {
        path: 'editar/:id', component: ControlesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: ControlesDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'marcas', component: MarcasComponent, canActivate: [AuthGuard], data: { permisos: ["marcas==1"] },
    children: [
      {
        path: 'editar/:id', component: MarcasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: MarcasDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'historicoalarmas', component: HistoricoAlarmasComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] }//Aratz: que permisos usa esto?

  },
  {
    path: 'historicoparametros', component: HistoricoParametrosComponent, canActivate: [AuthGuard], data: { permisos: ["historicoParametros==1"] },

  },
  {
    path: 'seguimientosproyectos', component: SeguimientosProyectosComponent, canActivate: [AuthGuard], data: { permisos: ["seguimiento==1"] },
  },
  {
    path: 'historicomantenimientos', component: HistoricoMantenimientosComponent, canActivate: [AuthGuard], data: { permisos: ["historicoMantenimientos==1"] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'almacenes', component: AlmacenesComponent, canActivate: [AuthGuard], data: { permisos: ["almacenes==1"] },
    children: [
      {
        path: 'editar/:id', component: AlmacenesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: AlmacenesDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'fabricantes', component: FabricantesComponent, canActivate: [AuthGuard], data: { permisos: ["fabricantes==1"] },
    children: [
      {
        path: 'editar/:id', component: FabricantesDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: FabricantesDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'consumiblesTipos', component: ConsumiblesTiposComponent, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'consumibleTipo/:id', component: ConsumibleTipoComponent, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'consumibles', component: ConsumiblesComponent, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'consumible/:id', component: ConsumibleComponent, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'historicoConsumibles', component: HistoricoConsumibles, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'historicoConsumible/:idTipo/:idConsumible', component: HistoricoConsumible, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'historicoConsumibleNserie/:idTipo/:idConsumible/:idHistoricoConsumible', component: HistoricoConsumibleNserie, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'historicoConsumibleTipo/:idTipo', component: HistoricoConsumibleTipo, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'vistaConsumibles', component: VistaConsumibles, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'gestionConsumibles', component: GestionConsumiblesComponent, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'movimientosConsumibles', component: MovimientosConsumiblesComponent, canActivate: [AuthGuard], data: { permisos: ["consumibles==1"] },
  },
  {
    path: 'notas', component: NotasComponent, canActivate: [AuthGuard]
  },
  {
    path: 'notas/crear', component: NotasCrearComponent, canActivate: [AuthGuard]
  },
  {
    path: 'notas/editar/:id', component: NotasEditarComponent, canActivate: [AuthGuard]
  },
  {
    path: 'herramientas', component: HerramientasComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
    children: [
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'herramientas/editar/:id', component: HerramientasDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'herramientas/crear', component: HerramientasDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'herramientas/editarplacas/:id', component: PlacasDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'herramientas/crearplacas', component: PlacasDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'herramientasunidades', component: HerramientasUnidadesComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
    children: [


    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'herramientasunidades/editar/:id', component: HerramientasUnidadesDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'herramientasunidades/crear', component: HerramientasUnidadesDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'herramientasunidades/editarplacas/:id', component: PlacasUnidadesDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["placas==1"] },
  },
  {
    path: 'herramientasunidades/crearplacas', component: PlacasUnidadesDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["placas==1"] },
  },
  {
    path: 'tiposHerramientas', component: TiposHerramientasComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
    children: [


    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'tiposHerramientas/editar/:id', component: TiposHerramientasDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'tiposHerramientas/crear', component: TiposHerramientasDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["herramientas==1"] },
  },
  {
    path: 'piezaspredefinidas', component: PiezasPredefinidasComponent, canActivate: [AuthGuard], data: { permisos: ["piezasPredefinidas==1"] },
    children: [
      {
        path: 'editar/:idparte', component: PiezasPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: PiezasPredefinidasDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'documento/:idPieza/:idParte/:idRuta/:idDocumento/:idVista', component: PiezasPredefinidasDocumentoComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'turnos', component: TurnosComponent, canActivate: [AuthGuard], data: { permisos: ["turnos==1"] },
    children: [
      {
        path: 'crear', component: TurnoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'editar/:id', component: TurnoDetalleComponent, canActivate: [AuthGuard],
      },
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'vistaalmacenes', component: VistaAlmacenesComponent, canActivate: [AuthGuard], data: { permisos: ["almacenes==1"] },

  },
  {
    path: 'vistaherramientasplacas', component: VistaHerramientasPlacasComponent, canActivate: [AuthGuard], data: { permisos: ["placas==1"] },

  },
  {
    path: 'alarmastipo', component: AlarmasTipoComponent, canActivate: [AuthGuard], data: { permisos: ["alarmasTipo==1"] },
    children: [
      {
        path: 'editar/:id', component: AlarmasTipoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: AlarmasTipoDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'materialestipo', component: MaterialesTipoComponent, canActivate: [AuthGuard], data: { permisos: ["materiales==1"] },
    children: [
      {
        path: 'editar/:id', component: MaterialesTipoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: MaterialesTipoDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'eskola_asignaturasretos', component: Eskola_AsignaturasRetosComponent, canActivate: [AuthGuard], data: { permisos: ["materiales==1"] },
    children: [
      {
        path: 'editar/:id', component: Eskola_AsignaturasRetosDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: Eskola_AsignaturasRetosDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'eskola_cursoslectivos', component: Eskola_CursosLectivosComponent, canActivate: [AuthGuard], data: {},
    children: [
      {
        path: 'editar/:id', component: Eskola_CursosLectivosDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: Eskola_CursosLectivosDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'grupos', component: EskolaGruposComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'grupos/nuevo', component: Eskola_GruposDetalleComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'grupos/:id', component: Eskola_GruposDetalleComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'calendarios', component: EskolaCalendariosComponent, canActivate: [AuthGuard], data: {},
    children: [
      {
        path: 'nuevo', component: CalendariosDetalleComponent, canActivate: [AuthGuard]
      }
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  { path: 'calendarios/editar/:id', component: CalendariosDetalleComponent, canActivate: [AuthGuard] },
  {
    path: 'alumnos', component: EskolaAlumnosComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },

  {
    path: 'alumnos/crear', component: Eskola_AlumnosDetalleComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'alumnos/:id', component: Eskola_AlumnosDetalleComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },

  {
    path: 'docentes', component: Eskola_DocentesComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  
  {
    path: 'docentes/crear', component: Eskola_DocentesDetalleComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'docentes/:id', component: Eskola_DocentesDetalleComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'eskola_lanzadorOf', component: Eskola_LanzadorOfComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'eskola_horariosSecciones', component: Eskola_HorariosSecciones, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
  path: 'eskola_horariosSecciones/:fechaInicio/:fechaFin', component: Eskola_HorariosSecciones, canActivate: [AuthGuard], data: {},
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
},
  {
  path: 'eskola_horariosSecciones_vistaAnual', component: Eskola_VistaAnualComponent, canActivate: [AuthGuard], data: {},
  runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },

  {
    path: 'eskola_informeAlumno', component: Eskola_InformeAlumnoComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
    },
  {
    path: 'piezastipo', component: PiezasTipoComponent, canActivate: [AuthGuard], data: { permisos: ["piezasTipo==1"] },
    children: [
      {
        path: 'editar/:id', component: PiezasTipoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: PiezasTipoDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'eskola_reservaMaquinas', component: Eskola_ReservaMaquinasComponent, canActivate: [AuthGuard], data: {},
    runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'operacionestipo', component: OperacionesTipoComponent, canActivate: [AuthGuard], data: { permisos: ["operacionesTipo==1"] },
    children: [
      {
        path: 'editar/:id', component: OperacionesTipoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: OperacionesTipoDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'plantasisometrico', component: PlantasIsometricoComponent, canActivate: [AuthGuard], data: { permisos: ["plantasIsometrico==1"] },
  },
  {
    path: 'diseñadorplantaisometrico/:idPlanta', component: DiseñadorPlantaIsometricoComponent, canActivate: [AuthGuard], data: { permisos: ["plantasIsometrico==1"] },
  },
  {
    path: 'homeplantaisometrico/:idPlanta', component: HomePlantaIsometricoComponent, canActivate: [AuthGuard], data: { permisos: ["plantasIsometrico==1"] },
  },
  {
    path: 'areasProductivas', component: AreasProductivasComponent, canActivate: [AuthGuard], data: { permisos: ["areaProductiva==1"] },
  },
  {
    path: 'areaProductiva/:id', component: AreaProductivaComponent, canActivate: [AuthGuard], data: { permisos: ["areaProductiva==1"] },
  },
  {
    path: 'historicodatos', component: HistoricoDatosComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },//Aratz: que permisos usa esto
  },
  {
    path: 'historicodatos/:idMaquina/:idCampo', component: HistoricoDatosComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },//Aratz: que permisos usa esto
  },
  {
    path: 'visorprogramas', component: VisorProgramasComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },//Aratz: que permisos usa esto
  },
  {
    path: 'visorVersion/:idP/:idV', component: VisorVersionComponent, canActivate: [AuthGuard], data: { permisos: ["proyectos==1"] },//Aratz: que permisos usa esto
  },
  {
    path: 'informesPiezasBoxplot', component: PiezasBoxplotComponent, canActivate: [AuthGuard], data: { permisos: ["piezas==1"] },
  },
  {
    path: 'informesOperacionesBoxplot/:fechaIni/:fechaFin/:idOF/:idPieza/:idCliente/:idParte', component: OperacionesBoxplotComponent, canActivate: [AuthGuard], data: { permisos: ["operaciones==1"] },//Aratz: que permisos usa esto
  },
  {
    path: 'comparativaTiemposBoxplot', component: ComparativaTiemposBoxplotComponent, canActivate: [AuthGuard], data: { permisos: ["comparativaTiempos==1"] },
  },
  {
    path: 'perdidasGrupos', component: PerdidasGruposComponent, canActivate: [AuthGuard], data: { permisos: ["perdidas==1"] },
    children: [
      {
        path: 'editar/:id', component: EditarCrearDiccionarioPerdidasGruposComponent, canActivate: [AuthGuard],
      },
      {
        path: 'crear', component: EditarCrearDiccionarioPerdidasGruposComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: "asignacionTiempos", component: AsignacionTiemposComponent, canActivate: [AuthGuard], data: { permisos: ["asignacionTiempos==1"] },
  },
  {
    path: "clientes", component: ClientesOrdenComponent, canActivate: [AuthGuard], data: { permisos: ["clientes==1"] },
    children: [
      {
        path: 'editar/:id', component: EditarCrearClientesComponent, canActivate: [AuthGuard],
      },
      {
        path: 'crear', component: EditarCrearClientesComponent, canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: "tutorial", component: TutorialComponent, canActivate: [AuthGuard]
  },
  {
    path: "tiposActivos", component: TiposActivoComponent, canActivate: [AuthGuard], data: { permisos: ["activosTipos==1"] },
  },
  {
    path: "tiposActivo/:id", component: TiposActivoDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["activosTipos==1"] },
  },
  {
    path: "seccion/:idAreaProductiva/:idSeccion", component: SeccionesDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["secciones==1"] },
  },
  {
    path: "informeCalidad", component: InformeCalidadComponent, canActivate: [AuthGuard], data: { permisos: ["informes==1"] },
  },
  {
    path: "activosMaestros", component: ActivosMaestrosComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: "activoMaestro/:id", component: ActivosMaestrosDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: 'activoMaestro/:idActivo/documento/:idDocumento', component: ActivosMaestrosDocumentoComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: 'activoMaestro/:idActivo/proveedor/:idProveedor', component: ActivosMaestrosProveedorComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: 'activoMaestro/:idActivo/arbolCompleto/:idActivoSelected', component: ActivosMaestrosVistArbolComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: 'activoMaestro/:idActivo/arbolInferior/:idActivoSelected', component: ActivosMaestrosVistArbolComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: 'activoMaestro/:idActivo/asignarRelacion/:idRelacion', component: ActivosMaestrosVistArbolComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: 'arbolActivosMaestros', component: ArbolActivosMaestrosComponent, canActivate: [AuthGuard], data: { permisos: ["activosMaestro==1"] },
  },
  {
    path: "activos", component: ActivosComponent, canActivate: [AuthGuard], data: { permisos: ["activos==1"] },
  },
  {
    path: "activo/:id", component: ActivosDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["activos==1"] },
  },
  {
    path: 'activo/:idActivo/documento/:idDocumento', component: ActivosDocumentoComponent, canActivate: [AuthGuard], data: { permisos: ["activos==1"] },
  },
  {
    path: "proveedores", component: ProveedoresComponent, canActivate: [AuthGuard], data: { permisos: ["proveedores==1"] },
  },
  {
    path: "stock", component: StockComponent, canActivate: [AuthGuard], data: { permisos: ["stock==1"] },
  },
  {
    path: "generadorPedido", component: GeneradorPedidoComponent, canActivate: [AuthGuard], data: { permisos: ["stock==1"] },
  },
  {
    path: "proveedor/:id", component: ProveedorDetalleComponent, canActivate: [AuthGuard], data: { permisos: ["proveedores==1"] },
  },
  {
    path: "histogramaTiempoPieza", component: HistogramaTiempoPiezaComponent, canActivate: [AuthGuard], data: { permisos: ["piezas==1"] },
  },
  {
    path: 'toleranciastipo', component: ToleranciasTipoComponent, canActivate: [AuthGuard], data: { permisos: ["tolerancias==1"] },
    children: [
      {
        path: 'editar/:id', component: ToleranciasTipoDetalleComponent, canActivate: [AuthGuard]
      },
      {
        path: 'crear', component: ToleranciasTipoDetalleComponent, canActivate: [AuthGuard]
      }
    ], runGuardsAndResolvers: 'paramsOrQueryParamsChange'
  },
  {
    path: 'informeTemplado', component: InformeTempladoComponent, canActivate: [AuthGuard]
  },
  {
    path: 'informeTemplado/:id', component: InformeTempladoComponent, canActivate: [AuthGuard]
  },
  /*{
    path: 'informesPersonalizados', component: InformesPersonalizadosComponent, canActivate: [AuthGuard]
  }, */
  {
    path: 'cursoslectivosgrupos', component: CursosLectivosGruposTodosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'cursoslectivosgrupos/:id', component: CursosLectivosGruposComponent, canActivate: [AuthGuard]
  },
  {
    path: 'cursoslectivostipos', component: CursosLectivosTiposTodosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'cursoslectivostipos/:id', component: CursosLectivosTiposComponent, canActivate: [AuthGuard]
  },
  {
    path: 'informepersonalizado', component: InformePersonalizadoComponent, canActivate: [AuthGuard], data: { permisos: ["informeRendimiento==1"] }
  },
  {
    path: 'informepersonalizado/:id', component: InformePersonalizadoComponent, canActivate: [AuthGuard], data: { permisos: ["informeRendimiento==1"] }
  },
  {
    path: 'informesPersonalizados', component: InformesPersonalizadosDisenadorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'crearInformesPersonalizados', component: CrearInformesPersonalizadosDisenadorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'editarInformesPersonalizados/:id', component: EditarInformesPersonalizadosDisenadorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'turnosOperarios', component: TurnosOperariosComponent, canActivate: [AuthGuard], data: { permisos: ["turnos==1"] }
  },
  {
    path: 'home_tv_v2', component: HometvComponent, canActivate: [AuthGuard], data: { permisos: ["home==1"] }
  },
  {
    path: 'home_tv', component: Hometv_v2Component, canActivate: [AuthGuard], data: { permisos: ["home==1"] }
  },
  {
    path: "atributos", component: AtributosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'atributos/editar/:id', component: EditarAtributosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'clasificaciones', component: ClasificacionesComponent, canActivate: [AuthGuard]
  },
  // {
  //   path: 'clasificaciones/crear', component: ClasificacionesCrearComponent, canActivate: [AuthGuard]
  // },
  {
    path: 'clasificaciones/editar/:id', component: ClasificacionesEditarComponent, canActivate: [AuthGuard]
  },
  {
    path: 'contenedores', component: ContenedoresComponent, canActivate: [AuthGuard]
  },
  {
    path: 'contenedores/:id', component: ContenedorDetalleComponent, canActivate: [AuthGuard]
  },
  {
    path: 'informeContenedores', component: InformeContenedoresComponent, canActivate: [AuthGuard]
  },
  {
    path: 'informeContenedores/:id', component: InformeContenedorDetalleComponent, canActivate: [AuthGuard]
  },
  {
    path: 'bloqueoRangos', component: BloqueoRangosComponent, canActivate: [AuthGuard]
  },
  {
    path: 'bloqueoRangos/:id', component: BloqueoRangosDetalleComponent, canActivate: [AuthGuard]
  },
  {
    path: 'gruposPlanificador', component: GruposPlanificadorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'seguimientoOFsConfigurador', component: SeguimientoOFsConfiguradorComponent, canActivate: [AuthGuard]
  },
  {
    path: 'seguimientoOFsConfigurador/:id', component: SeguimientoOFsConfiguradorDetalleComponent, canActivate: [AuthGuard]
  },
  // {
  //   path: 'automatizaciones', component: AutomatizacionesComponent, canActivate: [AuthGuard]
  // },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    onSameUrlNavigation: 'reload'
  , scrollPositionRestoration: 'enabled' // Restaura la posición del scroll al inicio al cambiar de ruta 
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
