import { Component, Injectable, LOCALE_ID, Inject } from '@angular/core';
import { MenuService, PlantaIsometricoService, UsuariosService, MaquinasService, CentroMecanizadoService, InformesPersonalizadosDisenadorService, InformePersonalizadoService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { MyFunctions } from './_helpers';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
  user = this.userService.userValue;
  isExpanded = false;
  tema = 1;
  iniciales = "";
  nombreEmpresa = "";
  configuracion: any;
  idDbDanobat = 40;
  currentYear: number = new Date().getFullYear();

  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  public timeZonesList: any = [];
  public timezoneSeleccionado: any;
  public timezoneSeleccionadoAnterior: any;

  public listaGrupos: any = [];
  public selectedGrupo: any = { id: -1, nombre: "", idMaquinas: [], maquinas: [] };

  public listaPlantas: any = [];
  public selectedPlanta: any = { nombre: "" };

  public listaInformesPersonalizados: any[] = [];

  constructor(@Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    private userService: UsuariosService,
    private centroMecanizadoService: CentroMecanizadoService,
    private maquinasService: MaquinasService,
    private plantaIsometricoService: PlantaIsometricoService,
    private translateService: TranslateService,
    private menuService: MenuService,
    public router: Router,
    public myFunctions: MyFunctions,
    private usuariosService: UsuariosService,
    public informesPersonalizadosDisenadorService: InformesPersonalizadosDisenadorService,
    public informePersonalizadoService: InformePersonalizadoService) {
    this.userService.user.subscribe(x => this.user = x);
    if (this.user != null) {
      
      translateService.setDefaultLang(this.user.idioma);
      this.onLocaleChange(this.user.idioma);
      this.isExpanded = this.user.menuExpandido;
      this.tema = this.user.tema;

      var configuracion_model = this.userService.get_configuracion_model();
      if (configuracion_model == false) {
        this.userService.getConfiguracionVariables().subscribe(json => {
          this.userService.set_configuracion_model(json);
          if (json[0].nombreEmpresa != undefined)
            this.nombreEmpresa = json[0].nombreEmpresa;
        })
      } else {
        if (configuracion_model[0].nombreEmpresa != undefined)
          this.nombreEmpresa = configuracion_model[0].nombreEmpresa;
      }

      this.cargarGrupos();
      this.cargarTimezones();

      var plantaIsometrico_model = this.plantaIsometricoService.Get_ListaPlantas_Model();
      if (plantaIsometrico_model == false) {
        this.plantaIsometricoService.Get_Plantas().subscribe((result) => {
          this.plantaIsometricoService.Set_ListaPlantas_Model(result);
          this.listaPlantas = result;
          if (this.listaPlantas.length > 0) this.selectedPlanta = this.listaPlantas[0];
        });
      } else {
        this.listaPlantas = plantaIsometrico_model;
        if (this.listaPlantas.length > 0) this.selectedPlanta = this.listaPlantas[0];
      }

      this.iniciales = this.user.nombre.substring(0, 1) + this.user.apellido1.substring(0, 1);
      if (this.tema == 1) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
      } else {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('iluna');
      }

      /*CARGAR AREAS PRODUCTIVAS*/
      this.secciones = this.userService.secciones;// this.userService.getSessionSecciones();

      if (this.secciones == undefined) {
        this.userService.getSecciones().subscribe(
          json => {
            this.userService.secciones = json;
            //this.userService.setSessionSecciones(json);

            this.secciones = json;
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

            /* se mira que si no hay ni un area seleccionada, se autoseleccionen todas (EN LA CARGA PORSEACASO)*/
            var sinActivas = true;
            an.forEach(
              row => {
                if (row.activo)
                  sinActivas = false;
              });

            an.forEach(
              row => {
                if (row.activo || sinActivas)
                  this.seccionesSeleccionadas.push(row);
              });
            /*se mira que si no hay ni un area seleccionada, se autoseleccionen todas FIN*/
          });
      } else {
        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

        /* se mira que si no hay ni un area seleccionada, se autoseleccionen todas (EN LA CARGA PORSEACASO)*/
        var sinActivas = true;
        an.forEach(
          row => {
            if (row.activo)
              sinActivas = false;
          });

        an.forEach(
          row => {
            if (row.activo || sinActivas)
              this.seccionesSeleccionadas.push(row);
          });
        /*se mira que si no hay ni un area seleccionada, se autoseleccionen todas FIN*/
      }
    } else {
      translateService.setDefaultLang('es');
      this.onLocaleChange('es')
      this.isExpanded = false;
    }

    this.actualizarMenuInformesPersonalizados();
      
  }

  recargarCamposLogin() {

    this.userService.user.subscribe(x => this.user = x);
    if (this.user != null) {
      this.translateService.setDefaultLang(this.user.idioma);
      this.onLocaleChange(this.user.idioma);
      this.isExpanded = this.user.menuExpandido;
      this.tema = this.user.tema;

      var configuracion_model = this.userService.get_configuracion_model();
      if (configuracion_model == false) {
        this.userService.getConfiguracionVariables().subscribe(json => {
          this.userService.set_configuracion_model(json);
          if (json[0].nombreEmpresa != undefined)
            this.nombreEmpresa = json[0].nombreEmpresa;
        })
      } else {
        if (configuracion_model[0].nombreEmpresa != undefined)
          this.nombreEmpresa = configuracion_model[0].nombreEmpresa;
      }

      var plantaIsometrico_model = this.plantaIsometricoService.Get_ListaPlantas_Model();
      if (plantaIsometrico_model == false) {
        this.plantaIsometricoService.Get_Plantas().subscribe((result) => {
          this.plantaIsometricoService.Set_ListaPlantas_Model(result);
          this.listaPlantas = result;
          if (this.listaPlantas.length > 0) this.selectedPlanta = this.listaPlantas[0];
        });
      } else {
        this.listaPlantas = plantaIsometrico_model;
        if (this.listaPlantas.length > 0) this.selectedPlanta = this.listaPlantas[0];
      }

      this.iniciales = this.user.nombre.substring(0, 1) + this.user.apellido1.substring(0, 1);
      if (this.tema == 1) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
      } else {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('iluna');
      }

    }

  }

  seccionChanged() {
    var lidPermiso = [];
    if (this.seccionesSeleccionadas.length > 0) {
      var an: any = this.seccionesSeleccionadas;
      an.forEach(
        row => {
          lidPermiso.push(row.idPermiso)
        });
    } else {
      var an: any = this.secciones;
      an.forEach(
        row => {
          lidPermiso.push(row.idPermiso)
        });
    }
    this.userService.updateSeccion(this.myFunctions.listToStirng(lidPermiso, ",", "0")).subscribe(
      json => {
        /* SE ACTUALIZAN LOS VALORES DE this.userService.secciones PARA TODAS LAS PAGINAS */
        an = this.secciones;
        an.forEach(
          seccion => {
            if (lidPermiso.length == 0)
              seccion.activo = true;
            else
              seccion.activo = lidPermiso.includes(seccion.idPermiso);
          }
        );
        this.userService.secciones = this.secciones;
        window.location.reload()
      });
  }

  timezoneChanged() {
    if (this.timezoneSeleccionado != undefined) {
      var timezone = this.timezoneSeleccionado;
      this.userService.updateTimezone(this.user.id, timezone.id, timezone.timeZoneJS, timezone.timeZoneSQL, timezone.desvioHoras).subscribe(
        (result: number) => {
          if (result > 0) {
            this.timezoneSeleccionadoAnterior = this.timezoneSeleccionado;
            this.user.idTimezone = timezone.id;
            this.user.timeZone = timezone.timeZoneJS;
            this.user.timeZoneSQL = timezone.timeZoneSQL;
            this.user.desvioTimezone = timezone.desvioHoras;
            window.location.reload()
          } else {
            this.timezoneSeleccionado = this.timezoneSeleccionadoAnterior;
          }
        });
    }
  }

  public onLocaleChange(locale: string): void {
    this.localeId = locale;
    (<CldrIntlService>this.intlService).localeId = locale;
  }

  cargarGrupos() {
    var gruposMaquinas_model = this.maquinasService.get_grupos_model();
    var grupoSeleccionado_model = this.maquinasService.get_grupo_seleccionado_model();
    if (gruposMaquinas_model == false) {
        this.centroMecanizadoService.getGruposMaquinas().subscribe((result) => {
        var grupos: any = result;

        //GROUP BY POR ID
        var groupByPorId = [];
        grupos.forEach(function (a) {
          if (!this[a.idGrupo]) {
            this[a.idGrupo] = { id: a.idGrupo, nombre: a.nombreGrupo, idMaquinas: [], maquinas: [] };
            groupByPorId.push(this[a.idGrupo]);
          }
          this[a.idGrupo].idMaquinas.push(a.idMaquina);
        }, Object.create(null));

        groupByPorId.forEach(function (g) {
          if (g.nombre == "" || g.nombre == " ") g.nombre = " - ";
        });

        this.maquinasService.set_grupos_model(groupByPorId);
        this.maquinasService.set_grupo_seleccionado_model(-1); //Lo ponemos a -1 porque se ha reiniciado el modelo de los grupos
        this.listaGrupos = groupByPorId;

        if (this.listaGrupos.length > 0){
          this.selectedGrupo = this.listaGrupos[0];
          this.maquinasService.set_grupo_seleccionado_model(this.selectedGrupo.id);
        } 
      });
    } else {
      this.listaGrupos = gruposMaquinas_model;
      if (this.listaGrupos.length > 0){
        var auxi = this.listaGrupos.filter(x=>x.id == grupoSeleccionado_model);
        if(auxi == undefined || auxi==null){
          this.selectedGrupo = this.listaGrupos[0];
          this.maquinasService.set_grupo_seleccionado_model(this.selectedGrupo.id);
        }else{
          this.selectedGrupo = auxi[0];
        }
        
      } 
    }
  }

  cargarTimezones() {
    var timezones_model = this.usuariosService.get_timezones_model();
    if (timezones_model == false) {
      this.usuariosService.getTimezones().subscribe((result) => {
        this.timeZonesList = result;
        this.timezoneSeleccionado = this.timeZonesList.find(x => x.id == this.user.idTimezone);
        if (this.timezoneSeleccionado == undefined) {
          this.timezoneSeleccionado = this.timeZonesList[0];
          this.user.idTimezone = 52; //Central Europe Standard Time
        }
      });
    } else {
      this.timeZonesList = timezones_model;
      this.timezoneSeleccionado = this.timeZonesList.find(x => x.id == this.user.idTimezone);
      if (this.timezoneSeleccionado == undefined) {
        this.timezoneSeleccionado = this.timeZonesList[0];
        this.user.idTimezone = 52; //Central Europe Standard Time
      }
    }
  }

  temaIluna() {
    this.tema = 2;
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido).subscribe();
    this.user.tema = this.tema;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('iluna');
    body.classList.add('iluna');
  }
  temaArgia() {
    this.tema = 1;
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido).subscribe();
    this.user.tema = this.tema;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('iluna');
  }
  expandir() {
    this.isExpanded = !this.isExpanded;
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.user.tema, this.isExpanded).subscribe();
    this.user.menuExpandido = this.isExpanded;
  }

  clickGrupo(grupo: any) {
    this.selectedGrupo = grupo;
    this.maquinasService.set_grupo_seleccionado_model(this.selectedGrupo.id);
  }

  clickInforme() {
    location.reload();
  }

  useLanguage(language: string) {
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, language, this.user.tema, this.user.menuExpandido).subscribe(
      f => (
        window.location.reload()
      )
    )
  }

  actualizarMenuInformesPersonalizados(){
    this.informesPersonalizadosDisenadorService.Get_Informes().subscribe(
      (result: any) => {
        this.informePersonalizadoService.set_informes(result);
        this.listaInformesPersonalizados =  this.informePersonalizadoService.informes; //this.maquinasService.get_informes();
      });
  }

  redirectToInformespersonalizados(id) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['informepersonalizado/', id]);
    });
  }

  logout() {
    this.userService.logout();
  }
}

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === 'saveUrl') {
      const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
        type: HttpEventType.UploadProgress,
        loaded: x,
        total: 100
      }).pipe(delay(1000)));

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
      events.push(success);

      return concat(...events);
    }

    if (req.url === 'removeUrl') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}
