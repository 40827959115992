import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, ProveedoresService, UsuariosService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-proveedor-detalle',
  templateUrl: './proveedor-detalle.component.html',
  styleUrls: ['./proveedor-detalle.component.less']
})
export class ProveedorDetalleComponent implements OnInit {

  proveedorNombre: string = '';
  proveedorDescripcion: string = '';
  activo: boolean;
  idProveedor: number;
  submitted = false;
  errorNombre = false;
  user = this.usuariosService.userValue;


  constructor(private proveedoresService: ProveedoresService,
    private usuariosService: UsuariosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    protected alertService: AlertService) {
    this.menuService.titulo = this.translateService.instant('proveedor');
    this.idProveedor = Number(this.route.snapshot.params['id']);
    this.cargarDatos();

  }

  cargarDatos() {
    if(this.idProveedor != 0)
      this.proveedoresService.GetById(this.idProveedor).subscribe((response: any)=>{
          this.proveedorNombre = response[0].nombre;
          this.proveedorDescripcion = response[0].descripcion;
          this.activo = response[0].activo;
      });
    else{
      this.proveedorNombre = '';
      this.proveedorDescripcion = '';
      this.activo = false;
    }
  }
  ngOnInit(): void {
  }

  proveedorGuardarClick() {
    if(this.proveedorNombre === ''){
      this.errorNombre = true;
      return;
    }
    this.submitted= true;
    if(this.idProveedor === 0){
      this.proveedoresService.insertProveedor(this.proveedorNombre, this.proveedorDescripcion, this.activo).subscribe((response: any)=>{
        if (response>= 0) {
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          this.router.navigate(['/proveedores']);     
        } else {
          this.alertService.error(this.translateService.instant('error'));
        }
      });
    } else{
      this.proveedoresService.updateProveedor(this.idProveedor, this.proveedorNombre, this.proveedorDescripcion, this.activo).subscribe((response: any)=>{
        if (response>= 0) {
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          this.router.navigate(['/proveedores']);   
        } else {
          this.alertService.error(this.translateService.instant('error'));
        }
      });
    }
  }

}
