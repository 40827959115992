import { Component } from '@angular/core';
import { MaquinasService, UsuariosService, MenuService, InformePersonalizadoService, InformeProyectosService, HistoricoOperacionesService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { GroupResult, groupBy, filterBy, CompositeFilterDescriptor, State, process } from '@progress/kendo-data-query';
import { first } from 'rxjs/operators';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';

interface ItemTurno {
  nombre: string,
  id: number
}

@Component({
  selector: 'app-informe-personalizado',
  templateUrl: './informePersonalizado.component.html'
})

export class InformePersonalizadoComponent {
  user = this.userService.userValue;

  //#region DEFINICION DE PARAMETROS
  public id: number = 0;

  public fechaInicioFiltro: any;
  public fechaFinFiltro: any;
  public fechaInicio: any;
  public fechaFin: any;
  // Fechas para controlar el rango de si hacer o no consulta nueva
  public fechaIniUltimaConsulta: any;
  public fechaFinUltimaConsulta: any;

  public tipoAgrupado: number = 0;

  public maquinaPreSeleccionada: number;
  public aparecer = false;

  public form: FormGroup;
  public idTurno: number;

  public turnosCombo: Array<ItemTurno> = [];
  public turnosComboSelected: string = "1, 2, 3";
  public comboTurnosSeleccion: any = [];
  public turnos_placeHolder: string = "";

  public turnos: JSON;

  // POSICION 1: col-md-4, col-md-6, col-md-10
  public posicion: any = {
    1: "col-md-4",
    2: "col-md-6",
    3: "col-md-12"
  }
  public lineaPosicion: Array<number> = [];
  public linea: Array<number> = [];
  public tipoDato: Array<string> = [];
  public tipoGrafico: Array<string> = [];
  public titulo: Array<string> = [];
  public lineasMax: Array<number> = [];
  public anchura: Array<string> = [];
  public altura: Array<string> = [];
  public filtrarMaquina = [];
  public filtrarOperario = [];

  public cantidadOEE: number = 0;
  public cantidadPerdidas: number = 0;
  public cantidadPerdidasOEE: number = 0;
  public cantidadMantenimiento: number = 0;
  public cantidadMantenimientoOEE: number = 0;
  public cantidadAlarma: number = 0;
  public cantidadAlarmaOEE: number = 0;
  public cantidadResumenSemana: number = 0;
  public cantidadResumenSemanaSubestados: number = 0;
  public cantidadDobleAlarma: number = 0;
  public cantidadDobleMantenimiento: number = 0;
  public cantidadOee1: number = 0;
  public cantidadSubperdidas: number = 0;
  public cantidadSubperdidasOEE: number = 0;
  public cantidadGrupoperdidas: number = 0;
  public cantidadGrupoperdidasOEE: number = 0;
  public cantidadResumenSemanaLineas: number = 0;
  public cantidadMantenimientosLineas: number = 0;
  public cantidadGrupoPerdidasLineas: number = 0;
  public cantidadPerdidasLineas: number = 0;
  public cantidadSubperdidasLineas: number = 0;
  public cantidadAlarmasLineas: number = 0;
  public cantidadOEELineas: number = 0;
  public cantidadOEE1Lineas: number = 0;
  public cantidadOEEBarra: number = 0;
  public cantidadMantenimientosCantidadLineas: number = 0;
  public cantidadGrupoPerdidasCantidadLineas: number = 0;
  public cantidadPerdidasCantidadLineas: number = 0;
  public cantidadSubperdidasCantidadLineas: number = 0;
  public cantidadAlarmasCantidadLineas: number = 0;
  public cantidadGrids: number = 0;
  // public cantidadOperaciones: number = 0;
  // public cantidadPerdidasGrid: number = 0;

  public filtroFechaFin: any;
  public filtroFechaIni: any;

  // consulta base de datos
  public data: any = [];
  public original: any = [];

  public visibleTipo: boolean = true;
  public chartDonutResumenSemana: any = [];
  public chartDonutResumenSemanaSubestados: any = [];
  public dataResumenSemana: any;
  public dataResumenSemanaSubestados: any = {};
  public chartDonutOEE1: any = [];
  public dataChartDonutOEE1: any = [];
  public chartDonutOEE: any = [];
  public dataChartDonutOEE: any = [];
  public chartDonutOEEDisponibilidad: any = [];
  public dataChartDonutDisponibilidad: any = [];
  public chartDonutOEERendimiento: any = [];
  public dataChartDonutRendimiento: any = [];
  public chartDonutOEECalidad: any = [];
  public dataChartDonutCalidad: any = [];
  public chartDonutParadas: any = [];
  public dataGraficoDonutParadas: any = {};
  public dataGraficoDonutParadasParaTooltip: any = {};
  public chartDonutSubperdidas: any = [];
  public dataGraficoDonutSubperdidas: any = [];
  public chartDonutGrupoPerdidas: any = [];
  public dataGraficoDonutGrupoPerdidas: any = [];
  public chartDonutMantenimientos: any = [];
  public dataGraficoDonutMantenimientos = [];
  public chartDonutAlarmas: any = [];
  public dataGraficoDonutAlarmas = [];
  //DOBLE CIRCULAR
  public chartDonutTipoAlarmas: any = [];
  public dataGraficoDonutTipoAlarmas = [];
  public dataGraficoDonutTipoAlarmasParaTooltip: any = {};
  public chartDonutGravedadAlarmas: any = [];
  public dataGraficoDonutGravedadAlarmas: any = {};
  public dataGraficoDonutGravedadAlarmasParaTooltip: any = {};
  public chartDonutDobleMantenimientos;
  public dataGraficoDonutDobleMantenimientos = [];
  public dataGraficoDonutDobleMantenimientosParaTooltip: any = {};

  // GRAFICOS BARRAS
  public chartOEEBarras: any = [];
  public dataOEEBarras: any;

  // GRAFICOS LINEAS
  public chartDonutResumenSemanaLineas: any = [];
  public dataResumenSemanaLineas: any;
  public chartDonutMantenimientosLineas: any = [];
  public dataMantenimientosLineas: any = {};
  public chartDonutGrupoPerdidasLineas: any = [];
  public dataGrupoPerdidasLineas: any = {};
  public chartDonutPerdidasLineas: any = [];
  public dataPerdidasLineas: any = {};
  public chartDonutSubperdidasLineas: any = [];
  public dataSubperdidasLineas: any = {};
  public chartDonutAlarmasLineas: any = [];
  public dataAlarmasLineas: any = {};
  public chartDonutOEELineas: any = [];
  public dataOEELineas: any = {};
  public chartDonutOEE1Lineas: any = [];
  public dataOEE1Lineas: any = {};

  // GRAFICOS POR TIPO OEE
  public dataPerdidasOEE: any = {};
  public chartPerdidasOEE: any = [];
  public dataSubperdidasOEE: any = {};
  public chartSubperdidasOEE: any = [];
  public dataGrupoPerdidasOEE: any = {};
  public chartGrupoPerdidasOEE: any = [];
  public dataAlarmasOEE: any = {};
  public chartAlarmasOEE: any = [];
  public dataMantenimientoOEE: any = {};
  public chartMantenimientoOEE: any = [];

  public chartDonutGrupoPerdidasCantidadLineas: any = [];
  public dataGrupoPerdidasCantidadLineas: any = {};
  public chartDonutPerdidasCantidadLineas: any = [];
  public dataPerdidasCantidadLineas: any = {};
  public chartDonutSubperdidasCantidadLineas: any = [];
  public dataSubperdidasCantidadLineas: any = {};
  public chartDonutAlarmasCantidadLineas: any = [];
  public dataAlarmasCantidadLineas: any = {};
  public chartDonutMantenimientosCantidadLineas: any = [];
  public dataMantenimientosCantidadLineas: any = {};

  public alarmasNoSeleccionadas: any = [];
  public mantenimientosNoSeleccionadas: any = [];
  public gravedadAlarmasNoSeleccionadas: any = [];
  public perdidasNoSeleccionadas: any = [];
  public subperdidasNoSeleccionadas: any = [];
  public grupoPerdidasNoSeleccionadas: any = [];
  public resumenNoSeleccionado: any = [];
  public resumenSubestadosNoSeleccionado: any = [];
  public disponibilidadNoSeleccionadas: any = [];
  public perdidasOEENoSeleccionadas: any = [];
  public subperdidasOEENoSeleccionadas: any = [];
  public grupoPerdidasOEENoSeleccionadas: any = [];
  public alarmasOEENoSeleccionadas: any = [];
  public mantenimientosOEENoSeleccionadas: any = [];

  public alarmasNoSeleccionadasIndex: any = [];
  public mantenimientosNoSeleccionadasIndex: any = [];
  public gravedadAlarmasNoSeleccionadasIndex: any = [];
  public perdidasNoSeleccionadasIndex: any = [];
  public subperdidasNoSeleccionadasIndex: any = [];
  public grupoPerdidasNoSeleccionadasIndex: any = [];
  public resumenNoSeleccionadoIndex: any = [];
  public resumenSubestadosNoSeleccionadoIndex: any = [];
  public disponibilidadNoSeleccionadasIndex: any = [];
  public perdidasOEENoSeleccionadasIndex: any = [];
  public subperdidasOEENoSeleccionadasIndex: any = [];
  public grupoPerdidasOEENoSeleccionadasIndex: any = [];
  public alarmasOEENoSeleccionadasIndex: any = [];
  public mantenimientosOEENoSeleccionadasIndex: any = [];
  public operacionesTablasNoSeleccionadasIndex: any = [];

  // TABLAS
  public selectedItemsOperacionesTablas: any = [];
  public jsonTablaOperaciones: any = []

  // Datos de todas las tablas
  public datosGrid: any = [];
  public tiposColumna: any = [];
  public columnasGrids: any = [];
  public columnasGrid: any = [];
  public state: State = {
    skip: 5,
    take: 10
  };
  public grid: any;

  // con esta variable sabemos si los graficos hay que actualizarlos por vinculacion o por filtro
  public vinculacion: boolean = false;

  // GRAFICOS EXCLUIDOS 
  // circular
  public grupoPerdidasExcluidas: any = [];
  public perdidasExcluidas: any = [];
  public subperdidasExcluidas: any = [];
  public alarmasExcluidas: any = [];
  public dobleAlarmasExcluidas: any = [];
  public mantenimientosExcluidos: any = [];
  public resumenSemanaExcluidos: any = [];
  public resumenSemanaSubestadosExcluidos: any = [];
  public oeeExcluidos: any = [];
  public oee1Excluidos: any = [];
  public perdidasOeeExcluidas: any = [];
  public subperdidasOeeExcluidas: any = [];
  public grupoPerdidasOeeExcluidas: any = [];
  public alarmasOeeExcluidas: any = [];
  public mantenimientosOeeExcluidos: any = [];

  // lineas
  public grupoPerdidasLineasExcluidas: any = [];
  public perdidasLineasExcluidas: any = [];
  public subperdidasLineasExcluidas: any = [];
  public alarmasLineasExcluidas: any = [];
  public mantenimientosLineasExcluidos: any = [];
  public resumenSemanaLineasExcluidos: any = [];
  public oeeLineasExcluidos: any = [];
  public oee1LineasExcluidos: any = [];

  // lineas cantidad
  public grupoPerdidasLineasCantidadExcluidas: any = [];
  public perdidasLineasCantidadExcluidas: any = [];
  public subperdidasLineasCantidadExcluidas: any = [];
  public alarmasLineasCantidadExcluidas: any = [];
  public mantenimientosLineasCantidadExcluidos: any = [];
  // public operacionesTablasExcluidas: any = [];
  // public perdidasTablasExcluidas: any = [];
  public tablasExcluidas: any = [];

  // barras
  public oeeBarrasExcluidos: any = [];

  // informacion del oee
  public tEjecucionOEE = 0;
  public tParadaDisponibilidad = 0;
  public tMantenimientoDisponibilidad = 0;
  public tAlarmaDisponibilidad = 0;
  public tApagadaDisponibilidad = 0;
  public tParadaRendimiento = 0;
  public tMantenimientoRendimiento = 0;
  public tAlarmaRendimiento = 0;
  public tApagadaRendimiento = 0;
  public tParadaCalidad = 0;
  public tMantenimientoCalidad = 0;
  public tAlarmaCalidad = 0;
  public tApagadaCalidad = 0;
  public tNegativoCalidad = 0;
  public tEstimado = 0;
  public tTotalHistoricoOperacion = 0;
  public tEstimadoFinal = 0;
  public tEstimadoGlobal = 0;

  public tEjecucionArray = [];
  public tParadaDisponibilidadArray = [];
  public tMantenimientoDisponibilidadArray = [];
  public tAlarmaDisponibilidadArray = [];
  public tApagadaDisponibilidadArray = [];
  public tParadaRendimientoArray = [];
  public tMantenimientoRendimientoArray = [];
  public tAlarmaRendimientoArray = [];
  public tApagadaRendimientoArray = [];
  public tParadaCalidadArray = [];
  public tMantenimientoCalidadArray = [];
  public tAlarmaCalidadArray = [];
  public tApagadaCalidadArray = [];
  public tNegativoCalidadArray = [];
  public tEstimadoArray = [];

  // TOP N en la leyenda de los gráficos
  public topN: number = 10;
  public skip = 0;

  // VISIBILIDAD DE NO CONTENIDO EN LOS GRAFICOS
  public labelGrupoPerdidas: boolean = false;
  public labelResumenSemana: boolean = false;
  public labelPerdidas: boolean = false;
  public labelSubperdidas: boolean = false;
  public labelAlarmas: boolean = false;
  public labelMantenimientos: boolean = false;
  public labelAlarmasDoble: boolean = false;

  public loadingGantt: boolean = false;
  public loadingResumenSemana: boolean = false;
  public loadingHorasDia: boolean = false;
  public loadingOeeHoras: boolean = false;
  public loadingPerdidas: boolean = false;

  private tiempoTransition: number = 2000;

  private colorPreparacion: String = "#096844";
  private colorEjecucion: String = "#C0EADA";
  private colorParada: String = "#E7CB68";
  private colorMicroparada: String = "#cf8729";
  private colorMantenimiento: String = "#99AFCC";
  private colorAlarma: String = "#D33737";
  private colorApagada: String = "#424242";
  private colorOEEActivo: String = "#18D6B0";
  private colorOEENoActivo: String = "#3D6063";


  //PANEL MAQUINAS
  //private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  public agrupado: number = 0;

  public fechaMin: Date;
  public fechaMax: Date;

  // VARIABLES input del filtro
  private dataFiltro: any;
  public eligiendoFechas: boolean = false;

  public fini: any;
  public ffin: any;
  public idcliente: any;
  public idpieza: any;
  public idof: any;
  public nSerie: any;
  public idOperacion: any;

  public loadingDatos: boolean = false;
  public tieneDatos: boolean = true;

  status: boolean = true;
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translate.instant('y') },
    { id: 1, nombre: this.translate.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translate.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translate.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translate.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translate.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translate.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translate.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translate.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translate.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translate.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translate.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translate.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translate.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translate.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translate.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translate.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translate.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translate.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translate.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translate.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translate.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translate.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translate.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translate.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translate.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translate.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translate.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translate.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translate.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
  // DATOS INICIALES DEL FILTRO
  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto: any;
  public datosFiltro = {
    logic: { id: 1, nombre: this.translate.instant('o') },
    group: []
  };
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
    { id: 3, nombre: this.translate.instant('turno'), field: "tipoTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
    { id: 4, nombre: this.translate.instant('seccion'), field: "idSeccion", sqlfield: "m.idSeccion", tipo: 'comboEstrincto' },
    { id: 5, nombre: this.translate.instant('grupoMaquinas'), field: "idMaquinaGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
    { id: 6, nombre: this.translate.instant('maquina'), field: "idmaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
    { id: 7, nombre: this.translate.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
    { id: 8, nombre: this.translate.instant('of'), field: "numeroOF", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },
    { id: 9, nombre: this.translate.instant('cliente'), field: "cliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },
    { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },
    { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'comboFlexible' },
    { id: 12, nombre: this.translate.instant('parte'), field: "nombreParte", sqlfield: "do.nombreParte", tipo: 'comboFlexible' },
    { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
    { id: 14, nombre: this.translate.instant('terminado'), field: "operacionTerminada", sqlfield: "do.operacionTerminada", tipo: 'check' },
    { id: 15, nombre: this.translate.instant('colada'), field: "colada", sqlfield: "ho.colada", tipo: 'comboFlexible' },
    { id: 16, nombre: this.translate.instant('lote'), field: "lote", sqlfield: "ho.lote", tipo: 'comboFlexible' }
  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;
  //END FILTRO

  //Formato de los grids
  public formato = [];
  //#endregion

  constructor( 
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private myFunctions: MyFunctions,
    private InformePersonalizadoService: InformePersonalizadoService,
    private informeProyectosService: InformeProyectosService,
    private historicoOperacionesService: HistoricoOperacionesService,) {
    this.userService.user.subscribe(x => this.user = x);

    //this.maquinaPreSeleccionada = this.route.snapshot.params['idMaquina'];

    //this.menuService.titulo = this.translate.instant('informepersonalizado').toUpperCase();
    this.cargarFechas();
    this.filtroPorDefecto = {
      logic: { id: 1, nombre: this.translate.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translate.instant('y') },
          group: [
            {
              columna: { id: 1, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
              operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
              fechaIni: this.fechaInicio,
              fechaFin: this.fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: [],
              tipoNoEditable: true,
              tipoNoVisible: true
            },
            {
              columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
              operator: { id: 0, nombre: '' },
              fechaIni: this.fechaInicio,
              fechaFin: this.fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: [],
              tipoNoEditable: false,
              tipoNoVisible: false
            }
          ]
        }
      ]
    };
    this.cargarMaquinas();
    this.cargarGrupos();
    this.cargarAreasProductivas();

    this.cargarTurnosCombo();

  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.fechaInicio == undefined) {
      this.fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = this.fechaInicio
    if (fechaIni.getDay() == 3) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.fechaInicio);
    this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.fechaInicio.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) {
        //this.filtrarMaquinasPorAreaProductivaYGrupo();
        this.cargar_Filtro();
      }
    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }
  cargarMaquinas() {
    var r1: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1) {
          this.maquinas.forEach(
            maquina => {
              maquina.idMaquina = maquina.id;
              maquina.nombreMaquina = maquina.nombre;
            });
          this.cargar_Filtro();
        }
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1) {
        this.maquinas.forEach(
          maquina => {
            maquina.idMaquina = maquina.id;
            maquina.nombreMaquina = maquina.nombre;
          });
        this.cargar_Filtro();
      }
    }
  }


  private cargarTurnosCombo() {
    this.turnos_placeHolder = this.translate.instant("seleccioneTurnos")
    this.turnosCombo = [
      { nombre: this.translate.instant("manana"), id: 1 },
      { nombre: this.translate.instant("tarde"), id: 2 },
      { nombre: this.translate.instant("noche"), id: 3 },
      { nombre: this.translate.instant("fueraTurno"), id: 5 }
    ];
  }

  /*ngAfterViewInit() {
      var c = document.getElementsByClassName('ARATZ')[0]; 
     //var html = " aratz";
     //     html += " <kendo-combobox [data]=\"datosGrid\"></kendo-combobox>";
     //      html += " aratz";
     //      c.innerHTML= html;

    console.log("____________________________________________")
     console.log(c)
    //console.log(this.zdravko)     


      const factory = this.resolver.resolveComponentFactory(GridComponent);
      //const comboboxRef = this.zdravko.createComponent(factory);

      var gridRef  = factory.create(this.injector, [], null);
      this.renderer.appendChild(c, gridRef.location.nativeElement);

      
  //var kkk = c.childNodes[0];
  //kkk.appendChild(factory.create(ComboBoxComponent));
  //console.log(kkk)

      //const comboboxReaaaf = kkk.createComponent(factory);
      
      gridRef.instance.data = [
        { name: 'John Doe', age: 32 },
        { name: 'Jane Doe', age: 31 }
      ];


      gridRef.instance.columns = [
        { field: 'name', title: 'Name' },
        { field: 'age', title: 'Age' }
      ] as unknown as QueryList<ColumnBase>;

      gridRef.instance.sortable = { mode: 'multiple' };
      gridRef.instance.groupable = false;
      gridRef.instance.scrollable = 'scrollable';

      var bbb;
      bbb += "<kendo-grid-column width=\"95%\" [style]=\"{'text-align': 'left'}\" field=\"id\"";
              bbb += "title=\"{{ 'Nombre columna' | translate}}\">";
              bbb += "</kendo-grid-column>";


      gridRef.location.nativeElement.innerHTML = bbb;


      //gridRef.location.nativeElement.setAttribute("\[kendoGridBinding\]", "datosGrid[0]");
    
      console.log(gridRef)
      console.log(c)
  }*/

  ngOnInit() {
    //Informes
    this.id = this.route.snapshot.params['id'];
    this.InformePersonalizadoService.get_informePersonalizado(this.id).subscribe(
      json => {
        var dataInforme = json;
        this.menuService.titulo = json[0]?.nombre;

        var r1 = false, r2 = false;

        //Tipos de columnas
        this.InformePersonalizadoService.get_tiposColumna().subscribe(
          json => {
            this.tiposColumna = json;
            r1 = true;
            if (r1 && r2) this.cargarModulos(dataInforme);
          });

        //Todas las columnas
        this.InformePersonalizadoService.get_columnasGrid().subscribe(
          json => {
            this.columnasGrids = json;
            r2 = true;
            if (r1 && r2) this.cargarModulos(dataInforme);
          });


      });

    // cargar la consulta
    this.loadingPerdidas = true;

    // var fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7)) + "T00:00:00Z";
    // var fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), 1)) + "T00:00:00Z";
    var fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(this.fechaInicio) + "T00:00:00Z";
    var fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(this.myFunctions.dateAddDays(this.fechaFin, 1)) + "T00:00:00Z";

    // actualizar la fecha de ultima consulta
    var regex = /\d\d:\d\d:\d\d/i;
    this.fechaIniUltimaConsulta = this.myFunctions.datetimeToSQL(new Date(fechaInicio));
    this.fechaIniUltimaConsulta = this.fechaIniUltimaConsulta.replace(regex, '00:00:00');
    this.fechaFinUltimaConsulta = this.myFunctions.datetimeToSQL(new Date(fechaFin));
    this.fechaFinUltimaConsulta = this.fechaFinUltimaConsulta.replace(regex, '00:00:00');

    this.InformePersonalizadoService.getAll(fechaInicio, fechaFin).subscribe(
      json => {
        this.data = this.juntarTablas(json);

        if (this.data != null) { // hau da datu basean errorerik baldin badago panelan loading-a ez agertzeko gehiago
          this.data.forEach(element => {
            element['oculto'] = 0;

            // preparar las fechas
            element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
            element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
            element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
            element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
            element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
            element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
          });
          this.original = this.myFunctions.copy(this.data);
        } else this.data = [];

        // ACTUALIZAR INFORMACION PARA COMBOS DE FILTRO
        this.dataFiltro = this.data;
        this.loadingPerdidas = false;

        this.cargarGraficos();
        // es necesario cargar primero los datos de las tablas para que se cargue su contenido
        this.cargarDatosTablas();
        var that = this;
        setTimeout(function () {
          that.cargarInformacionGraficos();
        }, 10);
      });

    //TRADUCCIÓN AUTOMATICA
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.cargarTurnosCombo();
      //if (!this.esOffline) this.cargarInformeRendimiento();
      //if (this.esOffline) this.cargarInformeRendimientoOffline();
    });


    this.agrupado = 0;


    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });

  }

  // ngAfterViewInit() {
  //   this.cargarInformacionGraficos();
  // }

  juntarTablas(json) {
    // table => consulta principal
    var base = json.table;
    // table1 => alarmas
    var infAlarmas = json.table1;
    // table2 => perdidas
    var infPerdidas = json.table2;
    // table3 => mantenimientos
    var infMantenimientos = json.table3;
    // table4 => maquinas
    var infMaquinas = json.table4;
    // table5 => operaciones
    var infOperaciones = json.table5;
    // table6 => ofs
    var infOfs = json.table6;
    // table7 => tiempos, lote...
    var infTiempos = json.table7;
    // table8 => subestados
    var infSubestados = json.table8;
    // table9 && table10 => configuracion
    var infConfiguracion = json.table9;
    var infConfiguracion2 = json.table10;
    // tablas juntadas
    var result = [];

    base.forEach(element => {
      var alarma = infAlarmas.filter(f => f.idHistorico_Procesos == element.idHistoricoProcesos)[0];
      var perdidas = infPerdidas.filter(f => f.idPerdidaHistoricoBase == element.idPerdidaHistoricoBase)[0];
      var mant = infMantenimientos.filter(f => f.idMaquinaMantenimiento == element.idMaquinaMantenimiento)[0];
      var maq = infMaquinas.filter(f => f.idmaquina == element.idmaquina)[0];
      var op = infOperaciones.filter(f => f.idOperacion == element.idOperacion)[0];
      var tiempos = infTiempos.filter(f => f.idHistoricoOperaciones == element.idHistoricoOperaciones)[0];
      var subestados = infSubestados.filter(f => f.idProcesos_Tipo_subEstados == element.idProcesos_Tipo_subEstados)[0];

      if (op == undefined)
        op = { nombreOperacion: '', tiempoEstimadoEjecucion: -1, tiempoEstimadoPreparacion: 0, tiempoPredictivoEjecucion: 0, tiempoPredictivoPreparacion: 0, operacionTerminada: -1, idRuta: -1 }

      var ofs = infOfs.filter(f => f.idRuta == op.idRuta)[0];

      if (alarma == undefined)
        alarma = { alarma: '', gravedadAlarma: -1 };
      if (perdidas == undefined)
        perdidas = { idPerdida: -1, perdida: '', idSubPerdida: -1, subPerdida: '', idGrupoPerdida: -1, grupoPerdida: '' };
      if (mant == undefined)
        mant = { maquinaMantenimiento: '', idmaquinaMantenimiento: -1 }
      if (ofs == undefined)
        ofs = {
          idOF: -1, numeroOF: '', idCliente: -1, cliente: '', nombreSubCliente: '', referencia: ''
          , idPieza: -1, nombrePieza: '', referenciaPieza: '', numeroPlano: '', idParte: -1, nombreParte: ''
          , referenciaParte: '', idRuta: -1, nombreRuta: '', usaLotes: 0, cantidad: 0, piezasTerminadas: 0
        }
      if (tiempos == undefined)
        tiempos = {
          lote: -1, colada: -1, nSerie: -1, idEstado: -1, tiempoTotalRendimientoOperacion: 0,
          tiempoTotalRendimientoPreparacion: 0, tiempoTotalOperacion: 0, tiempoTotalPreparacion: 0, tiempoTotal: 0
        }
      if (subestados == undefined)
        subestados = { nombreSubestado: '', colorSubestado: 'rgba(85, 222, 165, 1)' };

      var lag = Object.assign({}, element, alarma);
      lag = Object.assign({}, lag, perdidas);
      lag = Object.assign({}, lag, mant);
      lag = Object.assign({}, lag, maq);
      lag = Object.assign({}, lag, op);
      lag = Object.assign({}, lag, ofs);
      lag = Object.assign({}, lag, tiempos);
      lag = Object.assign({}, lag, subestados);
      lag = Object.assign({}, lag, infConfiguracion[0]);
      lag = Object.assign({}, lag, infConfiguracion2[0]);

      // calcular el tNegativoCalidad
      if ((lag["idProcesos_Tipo"] == 3 && lag["operacion"] == 1 && lag["idPerdidaHistoricoBase"] == 3) || lag["idEstado"] == 6)
        lag["tNegativoCalidad"] = lag["tiemporeal"];
      else if (lag["usaLotes"] == 1 && lag["cantidad"] != 0 && lag["cantidadAchatarrada"] > 0)
        lag["tNegativoCalidad"] = lag["tiemporeal"];
      else
        lag["tNegativoCalidad"] = 0;

      result.push(lag);
    });
    return result;
  }

  cargarModulos(dataInforme) {
    //Modulos
    this.InformePersonalizadoService.get_modulos().subscribe(
      json => {
        var dataModulos = json;

        for (var i = 0; i < Object.keys(dataInforme).length; i++) {
          this.lineaPosicion.push(i + 1)                                              // Controla las lineas

          if (dataInforme[i]['idModulo1'] != -1) {
            //Guardar columna si la tiene
            var colLag = [];
            this.columnasGrids.forEach(c => {
              if (c.idModulo == dataInforme[i]['idModulo1']) {
                //if (this.columnasGrid[i] == undefined) this.columnasGrid[i] = [];
                var col = this.tiposColumna.find(t => t.id == c.tipoColumna);
                col.idGrid = i;
                col['tamannoColumna'] = c.tamannoColumna;
                col['nombre'] = this.translate.instant(col['nombre']);
                colLag.push(Object.assign({}, col));
              }
            });
            if (colLag.length != 0) this.columnasGrid.push(colLag);
            this.linea.push(i);                                                   // La linea donde se quiere posicionar
            for (var j = 0; j < Object.keys(dataModulos).length; j++) {
              if (dataModulos[j]['id'] == dataInforme[i]['idModulo1']) {
                this.titulo.push(dataModulos[j]['nombre']);                       // titulo del modulo
                this.lineasMax.push(dataModulos[j]['cantidadLineas']);            // maxima cantidad de lineas en una pagina de una tabla
                this.filtrarMaquina.push(dataModulos[j]['filtrarIdMaquinas'].split(","));    // filtros propios del grafico/tabla por maquina
                this.filtrarOperario.push(dataModulos[j]['filtrarIdOperarios'].split(","));  // filtros propio del grafico/tabla por operario

                if (dataInforme[i]['modulo1Anchura'] != null) {                    // definir la altura y la anchura
                  // this.anchura.push(this.posicion[dataInforme[i]['modulo1Anchura']]);
                  this.anchura.push(dataInforme[i]['modulo1Anchura']);
                } else {
                  this.anchura.push(this.posicion[1]);
                }

                // TIPO DE DATO
                var tipoDato_DAT = dataModulos[j]['id_informesPersonalizados_tipoDato_DAT'];
                if (tipoDato_DAT == 1 || tipoDato_DAT == 24 || tipoDato_DAT == 29) {         // Grupo perdidas
                  this.tipoDato.push("grupoPerdidas");
                  this.tiposGrafico(dataModulos[j], "grupoPerdidas");
                } else if (tipoDato_DAT == 2 || tipoDato_DAT == 15 || tipoDato_DAT == 25 || tipoDato_DAT == 30) {  // Perdidas
                  this.tipoDato.push("perdidas");
                  this.tiposGrafico(dataModulos[j], "perdidas");
                } else if (tipoDato_DAT == 3 || tipoDato_DAT == 26 || tipoDato_DAT == 31) {  // Subperdidas
                  this.tipoDato.push("subperdidas");
                  this.tiposGrafico(dataModulos[j], "subperdidas");
                } else if (tipoDato_DAT == 4 || tipoDato_DAT == 28 || tipoDato_DAT == 33 || tipoDato_DAT == 36) {  // Alarmas
                  this.tipoDato.push("alarmas");
                  this.tiposGrafico(dataModulos[j], "alarmas");
                  // } else if (tipoDato_DAT == 5) {  // Gravedad alarmas
                  //   this.tipoDato.push("gravedadAlarmas");
                } else if (tipoDato_DAT == 6 || tipoDato_DAT == 27 || tipoDato_DAT == 32 || tipoDato_DAT == 35) {  // Mantenimientos
                  this.tipoDato.push("mantenimientos");
                  this.tiposGrafico(dataModulos[j], "mantenimientos");
                } else if (tipoDato_DAT == 7 || tipoDato_DAT == 23) {  // Resumen semana
                  this.tipoDato.push("resumenSemana");
                  this.tiposGrafico(dataModulos[j], "resumenSemana");
                } else if (tipoDato_DAT == 38) { // Resumen semana subestados
                  this.tipoDato.push("resumenSemanaSubestados");
                  this.tiposGrafico(dataModulos[j], "resumenSemanaSubestados");
                } else if (tipoDato_DAT == 8 || tipoDato_DAT == 12 || tipoDato_DAT == 21) {  // OEE (4)
                  this.tipoDato.push("oee");
                  this.tiposGrafico(dataModulos[j], "oee");
                  // } else if (tipoDato_DAT == 9) {  // Grupos perdidas y perdidas
                  //   this.tipoDato.push("grupoPerdidasYPerdidas");
                  // } else if (tipoDato_DAT == 10) {  // Perdidas y subperdidas
                  //   this.tipoDato.push("perdidasYSubperdidas");
                } else if (tipoDato_DAT == 11) {  // Doble alarmas
                  this.tipoDato.push("dobleAlarmas");
                  this.tiposGrafico(dataModulos[j], "dobleAlarmas");
                } else if (tipoDato_DAT == 22 || tipoDato_DAT == 34) {  // OEE (1)
                  this.tipoDato.push("oee1");
                  this.tiposGrafico(dataModulos[j], "oee1");
                } else if (tipoDato_DAT == 16) {  // Perdidas por tipo OEE
                  this.tipoDato.push("perdidasOEE");
                  this.tiposGrafico(dataModulos[j], "perdidasOEE");
                } else if (tipoDato_DAT == 17) {  // Subperdidas por tipo OEE
                  this.tipoDato.push("subperdidasOEE");
                  this.tiposGrafico(dataModulos[j], "subperdidasOEE");
                } else if (tipoDato_DAT == 18) {  // Grupo de perdidas por tipo OEE
                  this.tipoDato.push("grupoPerdidasOEE");
                  this.tiposGrafico(dataModulos[j], "grupoPerdidasOEE");
                } else if (tipoDato_DAT == 19) {  // Alarmas por tipo OEE
                  this.tipoDato.push("alarmasOEE");
                  this.tiposGrafico(dataModulos[j], "alarmasOEE");
                } else if (tipoDato_DAT == 20) {  // Mantenimiento por tipo OEE
                  this.tipoDato.push("mantenimientoOEE");
                  this.tiposGrafico(dataModulos[j], "mantenimientoOEE");
                } else if (tipoDato_DAT == 37) {  // Operaciones
                  this.tipoDato.push("operaciones");
                  this.tiposGrafico(dataModulos[j], "operaciones");
                } else if (tipoDato_DAT == 39) { // maquinas
                  this.tipoDato.push("maquinas");
                  this.tiposGrafico(dataModulos[j], "maquinas");
                }

                break;
              }
            }
          }

          if (dataInforme[i]['idModulo2'] != -1) {
            //Guardar columna si la tiene
            var colLag = [];
            this.columnasGrids.forEach(c => {
              if (c.idModulo == dataInforme[i]['idModulo2']) {
                //if (this.columnasGrid[i] == undefined) this.columnasGrid[i] = [];
                var col = this.tiposColumna.find(t => t.id == c.tipoColumna);
                col.idGrid = i;
                col['tamannoColumna'] = c.tamannoColumna;
                colLag.push(Object.assign({}, col));
              }
            });
            if (colLag.length != 0) this.columnasGrid.push(colLag);
            this.linea.push(i);                                                   // La linea donde se quiere posicionar
            for (var j = 0; j < Object.keys(dataModulos).length; j++) {
              if (dataModulos[j]['id'] == dataInforme[i]['idModulo2']) {
                this.titulo.push(dataModulos[j]['nombre']);                       // titulo del modulo
                this.lineasMax.push(dataModulos[j]['cantidadLineas']);            // maxima cantidad de lineas en una pagina de una tabla
                this.filtrarMaquina.push(dataModulos[j]['filtrarIdMaquinas'].split(","));    // filtros propios del grafico/tabla por maquina
                this.filtrarOperario.push(dataModulos[j]['filtrarIdOperarios'].split(","));  // filtros propio del grafico/tabla por operario

                if (dataInforme[i]['modulo2Anchura'] != null) {                    // definir la altura y la anchura
                  // this.anchura.push(this.posicion[dataInforme[i]['modulo2Anchura']]);
                  this.anchura.push(dataInforme[i]['modulo2Anchura']);
                } else {
                  this.anchura.push(this.posicion[1]);
                }

                // TIPO DE DATO
                var tipoDato_DAT = dataModulos[j]['id_informesPersonalizados_tipoDato_DAT'];
                if (tipoDato_DAT == 1 || tipoDato_DAT == 24 || tipoDato_DAT == 29) {         // Grupo perdidas
                  this.tipoDato.push("grupoPerdidas");
                  this.tiposGrafico(dataModulos[j], "grupoPerdidas");
                } else if (tipoDato_DAT == 2 || tipoDato_DAT == 15 || tipoDato_DAT == 25 || tipoDato_DAT == 30) {  // Perdidas
                  this.tipoDato.push("perdidas");
                  this.tiposGrafico(dataModulos[j], "perdidas");
                } else if (tipoDato_DAT == 3 || tipoDato_DAT == 26 || tipoDato_DAT == 31) {  // Subperdidas
                  this.tipoDato.push("subperdidas");
                  this.tiposGrafico(dataModulos[j], "subperdidas");
                } else if (tipoDato_DAT == 4 || tipoDato_DAT == 28 || tipoDato_DAT == 33 || tipoDato_DAT == 36) {  // Alarmas
                  this.tipoDato.push("alarmas");
                  this.tiposGrafico(dataModulos[j], "alarmas");
                  // } else if (tipoDato_DAT == 5) {  // Gravedad alarmas
                  //   this.tipoDato.push("gravedadAlarmas");
                } else if (tipoDato_DAT == 6 || tipoDato_DAT == 27 || tipoDato_DAT == 32 || tipoDato_DAT == 35) {  // Mantenimientos
                  this.tipoDato.push("mantenimientos");
                  this.tiposGrafico(dataModulos[j], "mantenimientos");
                } else if (tipoDato_DAT == 7 || tipoDato_DAT == 23) {  // Resumen semana
                  this.tipoDato.push("resumenSemana");
                  this.tiposGrafico(dataModulos[j], "resumenSemana");
                } else if (tipoDato_DAT == 38) { // Resumen semana subestados
                  this.tipoDato.push("resumenSemanaSubestados");
                  this.tiposGrafico(dataModulos[j], "resumenSemanaSubestados");
                } else if (tipoDato_DAT == 8 || tipoDato_DAT == 12 || tipoDato_DAT == 21) {  // OEE (4)
                  this.tipoDato.push("oee");
                  this.tiposGrafico(dataModulos[j], "oee");
                  // } else if (tipoDato_DAT == 9) {  // Grupos perdidas y perdidas
                  //   this.tipoDato.push("grupoPerdidasYPerdidas");
                  // } else if (tipoDato_DAT == 10) {  // Perdidas y subperdidas
                  //   this.tipoDato.push("perdidasYSubperdidas");
                } else if (tipoDato_DAT == 11) {  // Doble alarmas
                  this.tipoDato.push("dobleAlarmas");
                  this.tiposGrafico(dataModulos[j], "dobleAlarmas");
                } else if (tipoDato_DAT == 22 || tipoDato_DAT == 34) {  // OEE (1)
                  this.tipoDato.push("oee1");
                  this.tiposGrafico(dataModulos[j], "oee1");
                } else if (tipoDato_DAT == 16) {  // Perdidas por tipo OEE
                  this.tipoDato.push("perdidasOEE");
                  this.tiposGrafico(dataModulos[j], "perdidasOEE");
                } else if (tipoDato_DAT == 17) {  // Subperdidas por tipo OEE
                  this.tipoDato.push("subperdidasOEE");
                  this.tiposGrafico(dataModulos[j], "subperdidasOEE");
                } else if (tipoDato_DAT == 18) {  // Grupo de perdidas por tipo OEE
                  this.tipoDato.push("grupoPerdidasOEE");
                  this.tiposGrafico(dataModulos[j], "grupoPerdidasOEE");
                } else if (tipoDato_DAT == 19) {  // Alarmas por tipo OEE
                  this.tipoDato.push("alarmasOEE");
                  this.tiposGrafico(dataModulos[j], "alarmasOEE");
                } else if (tipoDato_DAT == 20) {  // Mantenimiento por tipo OEE
                  this.tipoDato.push("mantenimientoOEE");
                  this.tiposGrafico(dataModulos[j], "mantenimientoOEE");
                } else if (tipoDato_DAT == 37) {  // Operaciones
                  this.tipoDato.push("operaciones");
                  this.tiposGrafico(dataModulos[j], "operaciones");
                } else if (tipoDato_DAT == 39) { // maquinas
                  this.tipoDato.push("maquinas");
                  this.tiposGrafico(dataModulos[j], "maquinas");
                }

                break;
              }
            }
          }

          if (dataInforme[i]['idModulo3'] != -1) {
            //Guardar columna si la tiene
            var colLag = [];
            this.columnasGrids.forEach(c => {
              if (c.idModulo == dataInforme[i]['idModulo3']) {
                //if (this.columnasGrid[i] == undefined) this.columnasGrid[i] = [];
                var col = this.tiposColumna.find(t => t.id == c.tipoColumna);
                col.idGrid = i;
                col['tamannoColumna'] = c.tamannoColumna;
                colLag.push(Object.assign({}, col));
              }
            });
            if (colLag.length != 0) this.columnasGrid.push(colLag);
            this.linea.push(i);                                                   // La linea donde se quiere posicionar
            for (var j = 0; j < Object.keys(dataModulos).length; j++) {
              if (dataModulos[j]['id'] == dataInforme[i]['idModulo3']) {
                this.titulo.push(dataModulos[j]['nombre']);                       // titulo del modulo
                this.lineasMax.push(dataModulos[j]['cantidadLineas']);            // maxima cantidad de lineas en una pagina de una tabla
                this.filtrarMaquina.push(dataModulos[j]['filtrarIdMaquinas'].split(","));    // filtros propios del grafico/tabla por maquina
                this.filtrarOperario.push(dataModulos[j]['filtrarIdOperarios'].split(","));  // filtros propio del grafico/tabla por operario

                if (dataInforme[i]['modulo3Anchura'] != null) {                    // definir la altura y la anchura
                  // this.anchura.push(this.posicion[dataInforme[i]['modulo3Anchura']]);
                  this.anchura.push(dataInforme[i]['modulo3Anchura']);
                } else {
                  this.anchura.push(this.posicion[1]);
                }

                // TIPO DE DATO
                var tipoDato_DAT = dataModulos[j]['id_informesPersonalizados_tipoDato_DAT'];
                if (tipoDato_DAT == 1 || tipoDato_DAT == 24 || tipoDato_DAT == 29) {         // Grupo perdidas
                  this.tipoDato.push("grupoPerdidas");
                  this.tiposGrafico(dataModulos[j], "grupoPerdidas");
                } else if (tipoDato_DAT == 2 || tipoDato_DAT == 15 || tipoDato_DAT == 25 || tipoDato_DAT == 30) {  // Perdidas
                  this.tipoDato.push("perdidas");
                  this.tiposGrafico(dataModulos[j], "perdidas");
                } else if (tipoDato_DAT == 3 || tipoDato_DAT == 26 || tipoDato_DAT == 31) {  // Subperdidas
                  this.tipoDato.push("subperdidas");
                  this.tiposGrafico(dataModulos[j], "subperdidas");
                } else if (tipoDato_DAT == 4 || tipoDato_DAT == 28 || tipoDato_DAT == 33 || tipoDato_DAT == 36) {  // Alarmas
                  this.tipoDato.push("alarmas");
                  this.tiposGrafico(dataModulos[j], "alarmas");
                  // } else if (tipoDato_DAT == 5) {  // Gravedad alarmas
                  //   this.tipoDato.push("gravedadAlarmas");
                } else if (tipoDato_DAT == 6 || tipoDato_DAT == 27 || tipoDato_DAT == 32 || tipoDato_DAT == 35) {  // Mantenimientos
                  this.tipoDato.push("mantenimientos");
                  this.tiposGrafico(dataModulos[j], "mantenimientos");
                } else if (tipoDato_DAT == 7 || tipoDato_DAT == 23) {  // Resumen semana
                  this.tipoDato.push("resumenSemana");
                  this.tiposGrafico(dataModulos[j], "resumenSemana");
                } else if (tipoDato_DAT == 38) { // Resumen semana subestados
                  this.tipoDato.push("resumenSemanaSubestados");
                  this.tiposGrafico(dataModulos[j], "resumenSemanaSubestados");
                } else if (tipoDato_DAT == 8 || tipoDato_DAT == 12 || tipoDato_DAT == 21) {  // OEE (4)
                  this.tipoDato.push("oee");
                  this.tiposGrafico(dataModulos[j], "oee");
                  // } else if (tipoDato_DAT == 9) {  // Grupos perdidas y perdidas
                  //   this.tipoDato.push("grupoPerdidasYPerdidas");
                  // } else if (tipoDato_DAT == 10) {  // Perdidas y subperdidas
                  //   this.tipoDato.push("perdidasYSubperdidas");
                } else if (tipoDato_DAT == 11) {  // Doble alarmas
                  this.tipoDato.push("dobleAlarmas");
                  this.tiposGrafico(dataModulos[j], "dobleAlarmas");
                } else if (tipoDato_DAT == 22 || tipoDato_DAT == 34) {  // OEE (1)
                  this.tipoDato.push("oee1");
                  this.tiposGrafico(dataModulos[j], "oee1");
                } else if (tipoDato_DAT == 16) {  // Perdidas por tipo OEE
                  this.tipoDato.push("perdidasOEE");
                  this.tiposGrafico(dataModulos[j], "perdidasOEE");
                } else if (tipoDato_DAT == 17) {  // Subperdidas por tipo OEE
                  this.tipoDato.push("subperdidasOEE");
                  this.tiposGrafico(dataModulos[j], "subperdidasOEE");
                } else if (tipoDato_DAT == 18) {  // Grupo de perdidas por tipo OEE
                  this.tipoDato.push("grupoPerdidasOEE");
                  this.tiposGrafico(dataModulos[j], "grupoPerdidasOEE");
                } else if (tipoDato_DAT == 19) {  // Alarmas por tipo OEE
                  this.tipoDato.push("alarmasOEE");
                  this.tiposGrafico(dataModulos[j], "alarmasOEE");
                } else if (tipoDato_DAT == 20) {  // Mantenimiento por tipo OEE
                  this.tipoDato.push("mantenimientoOEE");
                  this.tiposGrafico(dataModulos[j], "mantenimientoOEE");
                } else if (tipoDato_DAT == 37) {  // Operaciones
                  this.tipoDato.push("operaciones");
                  this.tiposGrafico(dataModulos[j], "operaciones");
                } else if (tipoDato_DAT == 39) { // maquinas
                  this.tipoDato.push("maquinas");
                  this.tiposGrafico(dataModulos[j], "maquinas");
                }

                break;
              }
            }
          }
        }

      });
  }

  tiposGrafico(dataModulos: any, datoGrafico: string) {

    if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 1) {         // Circular
      this.tipoGrafico.push("circular");
      if (datoGrafico == "grupoPerdidas") this.grupoPerdidasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "perdidas") this.perdidasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "subperdidas") this.subperdidasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "alarmas") this.alarmasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "mantenimientos") this.mantenimientosExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "resumenSemana") this.resumenSemanaExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "perdidasOEE") this.perdidasOeeExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "subperdidasOEE") this.subperdidasOeeExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "grupoPerdidasOEE") this.grupoPerdidasOeeExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "alarmasOEE") this.alarmasOeeExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "mantenimientoOEE") this.mantenimientosOeeExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "resumenSemanaSubestados") this.resumenSemanaSubestadosExcluidos.push(dataModulos['excluir']);

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 2) {  // Circular doble
      this.tipoGrafico.push("circularDoble");
      if (datoGrafico == "dobleAlarmas") this.dobleAlarmasExcluidas.push(dataModulos['excluir']);

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 3) {  // OEE
      this.tipoGrafico.push("oee");
      if (datoGrafico == "oee") this.oeeExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "oee1") this.oee1Excluidos.push(dataModulos['excluir']);

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 4) {  // OEE barras
      this.tipoGrafico.push("oeeBarras");
      if (datoGrafico == "oee") this.oeeBarrasExcluidos.push(dataModulos['excluir']);

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 5) {  // Barras verticales
      this.tipoGrafico.push("barrasVerticales");

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 6) {  // Barras horizontales
      this.tipoGrafico.push("barrasHorizontales");

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 8) {  // Lineas
      this.tipoGrafico.push("lineas");
      if (datoGrafico == "grupoPerdidas") this.grupoPerdidasLineasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "perdidas") this.perdidasLineasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "subperdidas") this.subperdidasLineasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "alarmas") this.alarmasLineasExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "mantenimientos") this.mantenimientosLineasExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "resumenSemana") this.resumenSemanaLineasExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "oee") this.oeeLineasExcluidos.push(dataModulos['excluir']);
      else if (datoGrafico == "oee1") this.oee1LineasExcluidos.push(dataModulos['excluir']);

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 7) {  // Tablaº
      this.tipoGrafico.push("tabla");
      // if (datoGrafico == "operaciones") this.operacionesTablasExcluidas.push(dataModulos['excluir']);
      // if (datoGrafico == "perdidas") this.perdidasTablasExcluidas.push(dataModulos['excluir']);
      this.tablasExcluidas.push(dataModulos['excluir']);

    } else if (dataModulos['id_informesPersonalizados_tipoGrafico_DAT'] == 9) {  // Lineas cantidad
      this.tipoGrafico.push("lineasCantidad");
      if (datoGrafico == "grupoPerdidas") this.grupoPerdidasLineasCantidadExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "perdidas") this.perdidasLineasCantidadExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "subperdidas") this.subperdidasLineasCantidadExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "alarmas") this.alarmasLineasCantidadExcluidas.push(dataModulos['excluir']);
      else if (datoGrafico == "mantenimientos") this.mantenimientosLineasCantidadExcluidos.push(dataModulos['excluir']);
    }
  }

  cargarGraficos() {

    // Crear las filas necesarias
    for (var i = 0; i <= Math.max(...this.lineaPosicion); i++) {
      this.formato.push([]);
    }

    // Cargar los graficos necesarios
    for (var i = 0; i < this.tipoGrafico.length; i++) {

      switch (this.tipoDato[i]) {

        // OEE (4)
        case "oee":
          if (this.tipoGrafico[i] == "oee") {
            this.insertOEECircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadOEE++;
          } else if (this.tipoGrafico[i] == "oeeBarras") {
            this.insertOEEBarra(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadOEEBarra++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertOEELineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadOEELineas++;
          }
          break;

        // GRUPO PERDIDAS
        case "grupoPerdidas":
          if (this.tipoGrafico[i] == "circular") {
            this.insertGrupoPerdidasCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrupoperdidas++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertGrupoPerdidasLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrupoPerdidasLineas++;
          } else if (this.tipoGrafico[i] == "lineasCantidad") {
            this.insertGrupoPerdidascantidadLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrupoPerdidasCantidadLineas++;
          }
          break;

        case "grupoPerdidasOEE":
          if (this.tipoGrafico[i] == "circular") {
            this.insertGrupoPerdidasOEECircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrupoperdidasOEE++;
          }
          break;

        // PERDIDAS
        case "perdidas":
          if (this.tipoGrafico[i] == "circular") {
            this.insertPerdidasCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadPerdidas++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertPerdidasLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadPerdidasLineas++;
          } else if (this.tipoGrafico[i] == "lineasCantidad") {
            this.insertPerdidasCantidadLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadPerdidasCantidadLineas++;
          } else if (this.tipoGrafico[i] == "tabla") {
            this.insertPerdidasTabla(this.anchura[i], this.titulo[i], this.lineasMax[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrids++;
          }
          break;

        case "perdidasOEE":
          if (this.tipoGrafico[i] == "circular") {
            this.insertPerdidasOEECircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadPerdidasOEE++;
          }
          break;

        // SUBPERDIDAS
        case "subperdidas":
          if (this.tipoGrafico[i] == "circular") {
            this.insertSubperdidasCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadSubperdidas++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertSubperdidasLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadSubperdidasLineas++;
          } else if (this.tipoGrafico[i] == "lineasCantidad") {
            this.insertSubperdidasCantidadLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadSubperdidasCantidadLineas++;
          }
          break;

        case "subperdidasOEE":
          if (this.tipoGrafico[i] == "circular") {
            this.insertSubperdidasOEECircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadSubperdidasOEE++;
          }
          break;

        // ALARMAS
        case "alarmas":
          if (this.tipoGrafico[i] == "circular") {
            this.insertAlarmaCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadAlarma++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertAlarmasLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadAlarmasLineas++;
          } else if (this.tipoGrafico[i] == "lineasCantidad") {
            this.insertAlarmasCantidadLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadAlarmasCantidadLineas++;
          } else if (this.tipoGrafico[i] == "tabla") {
            this.insertAlarmasTabla(this.anchura[i], this.titulo[i], this.lineasMax[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrids++;
          }
          break;

        case "alarmasOEE":
          if (this.tipoGrafico[i] == "circular") {
            this.insertAlarmasOEECircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadAlarmaOEE++;
          }
          break;

        // MANTENIMIENTOS
        case "mantenimientos":
          if (this.tipoGrafico[i] == "circular") {
            this.insertMantenimientosCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadMantenimiento++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertMantenimientosLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadMantenimientosLineas++;
          } else if (this.tipoGrafico[i] == "lineasCantidad") {
            this.insertMantenimientosCantidadLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadMantenimientosCantidadLineas++;
          } else if (this.tipoGrafico[i] == "tabla") {
            this.insertMantenimientosTabla(this.anchura[i], this.titulo[i], this.lineasMax[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrids++;
          }
          break;

        case "mantenimientoOEE":
          if (this.tipoGrafico[i] == "circular") {
            this.insertMantenimientosOEECircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadMantenimientoOEE++;
          }
          break;

        // RESUMEN SEMANA
        case "resumenSemana":
          if (this.tipoGrafico[i] == "circular") {
            this.insertResumenSemanaCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertResumenSemanaLineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadResumenSemanaLineas++;
          }
          break;
        case "resumenSemanaSubestados":
          if (this.tipoGrafico[i] == "circular") {
            this.insertResumenSemanaSubestadosCircular(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
          }
          break;

        // DOBLE ALARMAS
        case "dobleAlarmas":
          if (this.tipoGrafico[i] == "circularDoble") {
            this.insertCircularDobleAlarma(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadDobleAlarma++;
          }
          break;

        // OEE (1)
        case "oee1":
          if (this.tipoGrafico[i] == "oee") {
            this.insertOEE1(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadOee1++;
          } else if (this.tipoGrafico[i] == "lineas") {
            this.insertOEE1Lineas(this.anchura[i], this.titulo[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadOEE1Lineas++;
          }
          break;

        // OPERACIONES
        case "operaciones":
          if (this.tipoGrafico[i] == "tabla") {
            this.insertOperacionesTabla(this.anchura[i], this.titulo[i], this.lineasMax[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrids++;
          }
          break;
        case "maquinas":
          if (this.tipoGrafico[i] == "tabla") {
            this.insertMaquinasTabla(this.anchura[i], this.titulo[i], this.lineasMax[i], this.linea[i], this.filtrarMaquina[i], this.filtrarOperario[i]);
            this.cantidadGrids++;
          }
          break;
        default:
          break;

      }
    }

  }

  cargarInformacionGraficos() {
    this.cargarDatos();
    this.cargarDatosTablas();
    // this.prepararDatosOEE();
  }

  //#region  INSERTAR TODOS LOS GRAFICOS EN EL HTML

  insertOEECircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutOEE_InformeRendimiento_" + this.cantidadOEE,
        idDisponibilidad: "chartDonutOEEDisponibilidad_InformeRendimiento_" + this.cantidadOEE,
        idRendimiento: "chartDonutOEERendimiento_InformeRendimiento_" + this.cantidadOEE,
        idCalidad: "chartDonutOEECalidad_InformeRendimiento_" + this.cantidadOEE,
        nGrid: this.cantidadOEE,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 8, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'OEECircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertPerdidasCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutParadas_" + this.cantidadPerdidas,
        nGrid: this.cantidadPerdidas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 2, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'PerdidasCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertPerdidasOEECircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutParadasOEE_" + this.cantidadPerdidasOEE,
        nGrid: this.cantidadPerdidasOEE,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 16, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'PerdidasOEE',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertGrupoPerdidasCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutGrupoPerdidas_" + this.cantidadGrupoperdidas,
        nGrid: this.cantidadGrupoperdidas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 1, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'GrupoPerdidasCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertGrupoPerdidasOEECircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartGrupoPerdidasOEE_" + this.cantidadGrupoperdidasOEE,
        nGrid: this.cantidadGrupoperdidasOEE,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 18, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'GrupoPerdidasOEE',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertSubperdidasCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutSubperdidas_" + this.cantidadSubperdidas,
        nGrid: this.cantidadSubperdidas,
        class: posicion + '%',
        titulo: titulo,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        cols: [],
        tipo: 3, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        contenido: {
          tipo: 'SubperdidasCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertSubperdidasOEECircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutSubperdidasOEE_" + this.cantidadSubperdidasOEE,
        nGrid: this.cantidadSubperdidasOEE,
        class: posicion + '%',
        titulo: titulo,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        cols: [],
        tipo: 17, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        contenido: {
          tipo: 'SubperdidasOEE',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertMantenimientosCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutMantenimientos_" + this.cantidadMantenimiento,
        nGrid: this.cantidadMantenimiento,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 6, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'MantenimientosCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertMantenimientosOEECircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartMantenimientoOEE_" + this.cantidadMantenimientoOEE,
        nGrid: this.cantidadMantenimientoOEE,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 20, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'MantenimientosOEE',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertOEE1(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutOEE1_" + this.cantidadOee1,
        nGrid: this.cantidadOee1,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 22, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'OEE1Circular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertResumenSemanaCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutResumenSemana_" + this.cantidadResumenSemana,
        nGrid: this.cantidadResumenSemana,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        tipo: 7, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        contenido: {
          tipo: 'ResumenSemanaCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
    this.cantidadResumenSemana++;
  }

  insertResumenSemanaSubestadosCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutResumenSemanaSubestados_" + this.cantidadResumenSemanaSubestados,
        nGrid: this.cantidadResumenSemanaSubestados,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        tipo: 38, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        contenido: {
          tipo: 'ResumenSemanaSubestadosCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
    this.cantidadResumenSemanaSubestados++;
  }

  insertAlarmaCircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutAlarmas_" + this.cantidadAlarma,
        nGrid: this.cantidadAlarma,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 4, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'AlarmasCircular',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertAlarmasOEECircular(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartAlarmasOEE_" + this.cantidadAlarmaOEE,
        nGrid: this.cantidadAlarmaOEE,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 19, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'AlarmasOEE',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertCircularDobleAlarma(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutTipoAlarmas_" + this.cantidadDobleAlarma,
        idGravedad: "chartDonutGravedadAlarmas_" + this.cantidadDobleAlarma,
        nGrid: this.cantidadDobleAlarma,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 11, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'AlarmasDoble',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertResumenSemanaLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutResumenSemanaLineas_" + this.cantidadResumenSemanaLineas,
        nGrid: this.cantidadResumenSemanaLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 7, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'ResumenSemanaLineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertMantenimientosLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutMantenimientosLineas_" + this.cantidadMantenimientosLineas,
        nGrid: this.cantidadMantenimientosLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 6, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'MantenimientosLineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertMantenimientosCantidadLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutMantenimientosCantidadLineas_" + this.cantidadMantenimientosCantidadLineas,
        nGrid: this.cantidadMantenimientosCantidadLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 6, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'MantenimientosLineasCantidad',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertGrupoPerdidasLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutGrupoPerdidasLineas_" + this.cantidadGrupoPerdidasLineas,
        nGrid: this.cantidadGrupoPerdidasLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 1, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'GrupoPerdidasLineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertGrupoPerdidascantidadLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutGrupoPerdidasCantidadLineas_" + this.cantidadGrupoPerdidasCantidadLineas,
        nGrid: this.cantidadGrupoPerdidasCantidadLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 1, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'GrupoPerdidasLineasCantidad',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertPerdidasLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutPerdidasLineas_" + this.cantidadPerdidasLineas,
        nGrid: this.cantidadPerdidasLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 2, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'PerdidasLineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertPerdidasCantidadLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutPerdidasCantidadLineas_" + this.cantidadPerdidasCantidadLineas,
        nGrid: this.cantidadPerdidasCantidadLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 2, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'PerdidasLineasCantidad',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertSubperdidasLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutSubperdidasLineas_" + this.cantidadSubperdidasLineas,
        nGrid: this.cantidadSubperdidasLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 3, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'SubperdidasLineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertSubperdidasCantidadLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutSubperdidasCantidadLineas_" + this.cantidadSubperdidasCantidadLineas,
        nGrid: this.cantidadSubperdidasCantidadLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 3, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'SubperdidasLineasCantidad',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertAlarmasLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutAlarmasLineas_" + this.cantidadAlarmasLineas,
        nGrid: this.cantidadAlarmasLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 4, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'AlarmasLineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertAlarmasCantidadLineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutAlarmasCantidadLineas_" + this.cantidadAlarmasCantidadLineas,
        nGrid: this.cantidadAlarmasCantidadLineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 4, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'AlarmasLineasCantidad',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertOEELineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutOEELineas_" + this.cantidadOEELineas,
        nGrid: this.cantidadOEELineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 8, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'OEELineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertOEE1Lineas(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {
    this.formato[row].push(
      {
        id: "chartDonutOEE1Lineas_" + this.cantidadOEE1Lineas,
        nGrid: this.cantidadOEE1Lineas,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 22, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'OEE1Lineas',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertOEEBarra(posicion: string, titulo: string, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "chartDonutOEEBarra_" + this.cantidadOEEBarra,
        nGrid: this.cantidadOEEBarra,
        class: posicion + '%',
        titulo: titulo,
        cols: [],
        tipo: 8, // InformesPersonalizados_tipoDato_DAT
        lineasMax: -1,
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'OEEBarras',
          data: [],//this.datosGrid[this.cantidadOperaciones],
          selectedData: []
        }
      }
    );
  }

  insertOperacionesTabla(posicion: string, titulo: string, lineasMax: number, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "operacionesGrid_" + this.cantidadGrids,
        nGrid: this.cantidadGrids,
        class: posicion + '%',
        titulo: titulo,
        cols: this.columnasGrid[this.cantidadGrids],
        tipo: 37, // InformesPersonalizados_tipoDato_DAT
        lineasMax: lineasMax,
        excluido: this.tablasExcluidas[this.cantidadGrids],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'grid',
          filtrado: true,
          limpiarFiltroActivado: false,
          data: [],//this.datosGrid[this.cantidadOperaciones],
          dataView: [],
          selectedData: [],
          selectedDataInformation: []
        }
      }
    );

  }

  insertPerdidasTabla(posicion: string, titulo: string, lineasMax: number, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "perdidasGrid_" + this.cantidadGrids,
        nGrid: this.cantidadGrids,
        class: posicion + '%',
        titulo: titulo,
        cols: this.columnasGrid[this.cantidadGrids],
        tipo: 15, // InformesPersonalizados_tipoDato_DAT
        lineasMax: lineasMax,
        excluido: this.tablasExcluidas[this.cantidadGrids],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'grid',
          filtrado: true,
          limpiarFiltroActivado: false,
          data: [],//this.datosGrid[this.cantidadPerdidasGrid],
          dataView: [],
          selectedData: [],
          selectedDataInformation: []
        }
      }
    );

  }

  insertMantenimientosTabla(posicion: string, titulo: string, lineasMax: number, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "mantenimientosGrid_" + this.cantidadGrids,
        nGrid: this.cantidadGrids,
        class: posicion + '%',
        titulo: titulo,
        cols: this.columnasGrid[this.cantidadGrids],
        tipo: 35, // InformesPersonalizados_tipoDato_DAT
        lineasMax: lineasMax,
        excluido: this.tablasExcluidas[this.cantidadGrids],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'grid',
          filtrado: true,
          limpiarFiltroActivado: false,
          data: [],//this.datosGrid[this.cantidadPerdidasGrid],
          dataView: [],
          selectedData: [],
          selectedDataInformation: []
        }
      }
    );

  }

  insertAlarmasTabla(posicion: string, titulo: string, lineasMax: number, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "alarmasGrid_" + this.cantidadGrids,
        nGrid: this.cantidadGrids,
        class: posicion + '%',
        titulo: titulo,
        cols: this.columnasGrid[this.cantidadGrids],
        tipo: 35, // InformesPersonalizados_tipoDato_DAT
        lineasMax: lineasMax,
        excluido: this.tablasExcluidas[this.cantidadGrids],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'grid',
          filtrado: true,
          limpiarFiltroActivado: false,
          data: [],//this.datosGrid[this.cantidadPerdidasGrid],
          dataView: [],
          selectedData: [],
          selectedDataInformation: []
        }
      }
    );

  }

  insertMaquinasTabla(posicion: string, titulo: string, lineasMax: number, row: number, filtrarMaquinas, filtrarOperarios) {

    this.formato[row].push(
      {
        id: "maquinasGrid_" + this.cantidadGrids,
        nGrid: this.cantidadGrids,
        class: posicion + '%',
        titulo: titulo,
        cols: this.columnasGrid[this.cantidadGrids],
        tipo: 39, // InformesPersonalizados_tipoDato_DAT
        lineasMax: lineasMax,
        excluido: this.tablasExcluidas[this.cantidadGrids],
        filtrarMaquinas: filtrarMaquinas,
        filtrarOperarios: filtrarOperarios,
        contenido: {
          tipo: 'grid',
          filtrado: true,
          limpiarFiltroActivado: false,
          data: [],//this.datosGrid[this.cantidadPerdidasGrid],
          dataView: [],
          selectedData: [],
          selectedDataInformation: []
        }
      }
    );

  }
  //#endregion

  //#region  CARGAR TODA LA INFORMACION NECESARIA DE LA BASE DE DATOS

  // esta funcion es la principal, en esta funcion se recorre el json obtenido desde la base de datos
  cargarDatos() {

    //#region PARAMETROS NECESARIOS
    // Alarmas circular
    var data = this.data;
    var alarma: any = [];
    var tPerdidaAlarma: any = [];
    var tAlarmaOriginal: any = [];
    var cAlarma = [];
    var cAlarmasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];
    var gravedadAlarma: any = [];
    var Jgravedad = [];

    // Mantenimientos circular
    var cMantenimientos = [];
    var mantenimiento: any = [];
    var tPerdidaMantenimientos: any = [];
    var tMantenimientosOriginal: any = [];
    var cMantenimientosOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Perdidas circular
    var cPerdidas = [];
    var perdida: any = [];
    var tPerdidaPerdidas: any = [];
    var tPerdidaPerdidasOriginal: any = [];
    var cPerdidasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Subperdidas circular
    var cSubperdidas = [];
    var subperdida: any = [];
    var tPerdidaSubperdidas: any = [];
    var tSubperdidasOriginal: any = [];
    var cSubperdidasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Grupo perdidas circular
    var cGrupoPerdidas = [];
    var grupoperdida: any = [];
    var tPerdidaGrupoPerdidas: any = [];
    var tGrupoPerdidasOriginal: any = [];
    var cGrupoPerdidasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Resumen semana circular
    var tPreparacion = 0;
    var tEjecucionResumenSemana = 0;
    var tMicroparadas = 0;
    var tParada = 0;
    var tMantenimiento = 0;
    var tAlarma = 0;
    var tApagado = 0;

    // Resumen semana subestados circular
    var cResumenSemanaSubestados = [];
    var resumenSemanaSubestados: any = [];
    var resumenSemanaSubestadosColors: any = [];
    var tResumenSemanaSubestados: any = [];

    // OEE circular
    var idHistoricoOperacionesEjecucionArray = [];
    var idHistoricoOperacionesPreparacionArray = [];
    this.tEjecucionOEE = 0;
    this.tParadaDisponibilidad = 0;
    this.tMantenimientoDisponibilidad = 0;
    this.tAlarmaDisponibilidad = 0;
    this.tApagadaDisponibilidad = 0;
    this.tParadaRendimiento = 0;
    this.tMantenimientoRendimiento = 0;
    this.tAlarmaRendimiento = 0;
    this.tApagadaRendimiento = 0;
    this.tParadaCalidad = 0;
    this.tMantenimientoCalidad = 0;
    this.tAlarmaCalidad = 0;
    this.tApagadaCalidad = 0;
    this.tNegativoCalidad = 0;
    this.tEstimado = 0;
    this.tTotalHistoricoOperacion = 0;
    this.tEstimadoFinal = 0;
    this.tEstimadoGlobal = 0;

    // Alarmas lineas
    var txArrayAlarmas: any = ['x'];
    let columnsArrayAlarmas: any = [txArrayAlarmas];
    var namesArrayAlarmas: any = {};
    var xAlarmas: any = {};
    var columnsArrayCantidadAlarmas: any = [txArrayAlarmas];

    // Mantenimientos lineas
    var txArrayMantenimientos: any = ['x'];
    var columnsArrayMantenimientos: any = [txArrayMantenimientos];
    var namesArrayMantenimientos: any = {};
    var xMantenimientos: any = {};
    var columnsArrayCantidadMantenimientos: any = [txArrayMantenimientos];

    // Perdidas lineas
    var txArrayPerdidas: any = ['x'];
    var columnsArrayPerdidas: any = [txArrayPerdidas]
    var namesArrayPerdidas: any = {};
    var xPerdidas: any = {};
    var columnsArrayCantidadPerdidas: any = [txArrayPerdidas];

    // Subperdidas lineas
    var txArraySubperdidas: any = ['x'];
    var columnsArraySubperdidas: any = [txArraySubperdidas];
    var namesArraySubperdidas: any = {};
    var xSubperdidas: any = {};
    var columnsArrayCantidadSubperdidas: any = [txArraySubperdidas];

    // Grupo perdidas lineas
    var txArrayGrupoPerdidas: any = ['x'];
    var columnsArrayGrupoPerdidas: any = [txArrayGrupoPerdidas]
    var namesArrayGrupoPerdidas: any = {};
    var xGrupoPerdidas: any = {};
    var columnsArrayCantidadGrupoPerdidas: any = [txArrayGrupoPerdidas];

    // Resumen semana lineas
    var txArrayResumenSemana: any = ['x'];
    var tPreparacionArray: any = ['preparacion'];
    var tEjecucionArray: any = ['ejecucion'];
    var tMicroparadasArray: any = ['microparada'];
    var tParadaArray: any = ['parada'];
    var tMantenimientoArray: any = ['mantenimiento'];
    var tAlarmaArray: any = ['alarma'];
    var tApagadoArray: any = ['apagada'];

    // OEE lineas
    var txArrayOEE: any = ['x'];
    var columnsArrayOEE: any = [txArrayOEE, ['disponibilidad'], ['rendimiento'], ['calidad'], ['total']]
    var namesArrayOEE = {
      'disponibilidad': this.translate.instant('disponibilidad'),
      'rendimiento': this.translate.instant('rendimiento'),
      'calidad': this.translate.instant('calidad'),
      'total': this.translate.instant('total')
    };
    var xOEE = {
      'disponibilidad': 'x',
      'rendimiento': 'x',
      'calidad': 'x',
      'total': 'x'
    }
    var idHistoricoOperacionesEjecucion = [];
    var idHistoricoOperacionesPreparacion = [];
    this.tEjecucionArray = [];
    this.tParadaDisponibilidadArray = [];
    this.tMantenimientoDisponibilidadArray = [];
    this.tAlarmaDisponibilidadArray = [];
    this.tApagadaDisponibilidadArray = [];
    this.tParadaRendimientoArray = [];
    this.tMantenimientoRendimientoArray = [];
    this.tAlarmaRendimientoArray = [];
    this.tApagadaRendimientoArray = [];
    this.tParadaCalidadArray = [];
    this.tMantenimientoCalidadArray = [];
    this.tAlarmaCalidadArray = [];
    this.tApagadaCalidadArray = [];
    this.tNegativoCalidadArray = [];
    this.tEstimadoArray = [];
    //#endregion


    // CARGAR DATOS PARA GRAFICOS CIRCULARES
    data.forEach(d => {

      var diff = this.convertToDate(d);

      // ALARMAS TIPO CIRCULAR
      var index = alarma.indexOf(d['alarma']);
      if (d.idProcesos_Tipo == 6) {
        if (index == -1) {
          alarma.push(d['alarma']);
          xAlarmas[d['alarma']] = 'x';
          if (d["oculto"] == 0) {
            tPerdidaAlarma.push(diff);
          } else {
            tPerdidaAlarma.push(0);
          }
          tAlarmaOriginal.push(diff);
        } else {
          if (d["oculto"] == 0) {
            tPerdidaAlarma[index] += diff;
          }
          tAlarmaOriginal[index] += diff;
        }
      }

      // ALARMAS TIPO OEE
      if (d.idProcesos_Tipo == 6) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cAlarmasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cAlarmasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cAlarmasOEE[2][1] += diff;
          } else { // no computable
            cAlarmasOEE[3][1] += diff;
          }
        }
      }

      // ALARMAS GRAVEDAD CIRCULAR
      var index = gravedadAlarma.indexOf(d['gravedadAlarma']);
      if (d.idProcesos_Tipo == 6) {
        var diff = this.convertToDate(d);
        if (index == -1) {
          gravedadAlarma.push(d['gravedadAlarma']);
          if (d["oculto"] == 0) {
            Jgravedad.push(diff);
          } else {
            Jgravedad.push(0);
          }
        } else {
          if (d["oculto"] == 0) {
            Jgravedad[index] += diff;
          }
        }
      }

      // MANTENIMIENTOS TIPO CIRCULAR
      var index = mantenimiento.indexOf(d['maquinaMantenimiento']);
      if (d.idProcesos_Tipo == 4) {
        if (index == -1) {
          mantenimiento.push(d['maquinaMantenimiento']);
          if (d["oculto"] == 0) {
            tPerdidaMantenimientos.push(diff);
          } else {
            tPerdidaMantenimientos.push(0);
          }
          tMantenimientosOriginal.push(diff);
        } else {
          if (d["oculto"] == 0) {
            tPerdidaMantenimientos[index] += diff;
          }
          tMantenimientosOriginal[index] += diff;
        }
      }

      // MANTENIMIENTOS TIPO OEE
      if (d.idProcesos_Tipo == 4) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cMantenimientosOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cMantenimientosOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cMantenimientosOEE[2][1] += diff;
          } else { // no computable
            cMantenimientosOEE[3][1] += diff;
          }
        }
      }

      // PERDIDAS TIPO CIRCULAR
      var index = perdida.indexOf(d['perdida']);
      if (index == -1) {
        // if ((d['idPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idPerdida'] != -1) {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idPerdida'] != -1) {
          perdida.push(d['perdida']);
          if (d["oculto"] == 0) {
            tPerdidaPerdidas.push(diff);
          } else {
            tPerdidaPerdidas.push(0);
          }
          tPerdidaPerdidasOriginal.push(diff);
        }
      } else {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idPerdida'] != -1) {
          if (d["oculto"] == 0) {
            // if ((d['idPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idPerdida'] != -1) {
            tPerdidaPerdidas[index] += diff;
          }
          tPerdidaPerdidasOriginal[index] += diff;
        }
      }

      // PERDIDAS TIPO OEE
      // if ((d['idPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idPerdida'] != -1) {
      if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idPerdida'] != -1) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cPerdidasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cPerdidasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cPerdidasOEE[2][1] += diff;
          } else { // no computable
            cPerdidasOEE[3][1] += diff;
          }
        }
      }

      // SUBPERDIDAS TIPO CIRCULAR
      var index = subperdida.indexOf(d['subPerdida']);
      if (index == -1) {
        // if ((d['idSubPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idSubPerdida'] != -1) {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idSubPerdida'] != -1) {
          subperdida.push(d['subPerdida']);
          if (d["oculto"] == 0) {
            tPerdidaSubperdidas.push(diff);
          } else {
            tPerdidaSubperdidas.push(0);
          }
          tSubperdidasOriginal.push(diff);
        }
      } else {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idSubPerdida'] != -1) {
          if (d["oculto"] == 0) {
            // if ((d['idSubPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idSubPerdida'] != -1) {
            tPerdidaSubperdidas[index] += diff;
          }
          tSubperdidasOriginal[index] += diff;
        }
      }

      // SUBPERDIDAS POR TIPO OEE
      // if ((d['idSubPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idSubPerdida'] != -1) {
      if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idSubPerdida'] != -1) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cSubperdidasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cSubperdidasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cSubperdidasOEE[2][1] += diff;
          } else { // no computable
            cSubperdidasOEE[3][1] += diff;
          }
        }
      }

      // GRUPO PERDIDAS TIPO CIRCULAR
      var index = grupoperdida.indexOf(d['grupoPerdida']);
      if (index == -1) {
        // if ((d['idGrupoPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idGrupoPerdida'] != -1) {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idGrupoPerdida'] != -1) {
          grupoperdida.push(d['grupoPerdida']);
          if (d["oculto"] == 0) {
            tPerdidaGrupoPerdidas.push(diff);
          } else {
            tPerdidaGrupoPerdidas.push(0);
          }
          tGrupoPerdidasOriginal.push(diff);
        }
      } else {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idGrupoPerdida'] != -1) {
          if (d["oculto"] == 0) {
            // if ((d['idGrupoPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idGrupoPerdida'] != -1) {
            tPerdidaGrupoPerdidas[index] += diff;
          }
          tGrupoPerdidasOriginal[index] += diff;
        }
      }

      // GRUPO PERDIDAS TIPO OEE
      // if ((d['idGrupoPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idGrupoPerdida'] != -1) {
      if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idGrupoPerdida'] != -1) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cGrupoPerdidasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cGrupoPerdidasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cGrupoPerdidasOEE[2][1] += diff;
          } else { // no computable
            cGrupoPerdidasOEE[3][1] += diff;
          }
        }
      }

      // RESUMEN SEMANA TIPO CIRCULAR
      if (d["oculto"] == 0) {
        if (d.idProcesos_Tipo == 1) {
          tEjecucionResumenSemana += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 0) {
          tParada += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 1) {
          tMicroparadas += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 3) {
          tPreparacion += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4) {
          tMantenimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 || d.idProcesos_Tipo == 7) {
          tAlarma += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8) {
          tApagado += this.convertToDate(d);
        }
      }

      // RESUMEN SEMANA SUBESTADOS TIPO CIRCULAR
      if (d.idProcesos_Tipo_subEstados > 99) {
        var index = resumenSemanaSubestados.indexOf(d['nombreSubestado']);
        if (index == -1) {
          resumenSemanaSubestados.push(d['nombreSubestado']);
          resumenSemanaSubestadosColors.push(d['colorSubestado']);
          if (d['oculto'] == 0)
            tResumenSemanaSubestados.push(diff);
          else
            tResumenSemanaSubestados.push(0);
        } else {
          if (d['oculto'] == 0) {
            tResumenSemanaSubestados[index] += diff;
          }
        }
      }

      // OEE CIRCULAR
      if (d["oculto"] == 0) {
        var tEjecucion = 0;

        if ((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 1) {
          this.tParadaDisponibilidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 1) {
          this.tMantenimientoDisponibilidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 1) {
          this.tAlarmaDisponibilidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 1) {
          this.tApagadaDisponibilidad += this.convertToDate(d);
        }

        // si es rendimiento y tiene operacion entonces tiene que ir a ejecucion
        else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tParadaRendimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tMantenimientoRendimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tAlarmaRendimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tApagadaRendimiento += this.convertToDate(d);
        }

        else if ((d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tNegativoCalidad += d.tiemporeal;
        } else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tParadaCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tMantenimientoCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tAlarmaCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tApagadaCalidad += this.convertToDate(d);
        } else {
          this.tEjecucionOEE += this.convertToDate(d);
          tEjecucion = d.tiemporeal;
        }

        // if (d.idTipoPerdidaOEE == 2) {
        if (tEjecucion > 0) {
          // si la pieza tiene algo de preparacion (que no sea ni dispo ni calidad):
          //  - t estimado total = t esti ejecucion + t esti preparacion
          //  - t total: t total rendiminieto op + t total rendimiento prep 
          // si no:
          //  - t est: t esti ejecucion
          //  - t total: t total rendminieto op
          var tEstimadoTotal = 0;
          var tTotal = 0;
          var tEstEjec = 0;
          if (d.usaLotes)
            tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
          else
            tEstEjec = d.tiempoEstimadoEjecucion;

          if (d.tiempoTotalRendimientoOperacion == -1 || d.tiempoEstimadoEjecucion == -1) { // no tiene operacion
            // tEstimadoTotal = tEjecucion +  d.tiempoTotalRendimientoPreparacion;
            tEstimadoTotal = 0;
            tTotal = -1;
          } else {
            if (d.tiempoTotalRendimientoOperacion != 0) {
              tEstimadoTotal += tEstEjec
            }
            if (d.tiempoTotalRendimientoOperacion != 0) {
              tEstimadoTotal += d.tiempoEstimadoPreparacion
            }
            tTotal = d.tiempoTotal;
          }

          // if (d.idEstado == 5 || d.idEstado == 4)
          //   var tEstLag = 0;
          // else if (d.ejecucionSinOperacionRepercuteA == 0 && tTotal == -1)
          //   var tEstLag = 0;
          if (d.ejecucionSinOperacionRepercuteA != 0 && tTotal <= 0)
            var tEstLag = (tEjecucion + d.tiempoTotalRendimientoPreparacion) * (d.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
          else
            var tEstLag = tEstimadoTotal * d.tiemporeal / tTotal;

          
          if (d.idEstado == 5 || d.idEstado == 4)
            this.tNegativoCalidad += Math.min(d.tiemporeal, tEstLag);

          // tiempo estimado parcial
          // despues de tener el tiempo estimado total, y el tiempo total operacion,
          // limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)
          if (d.tiemporeal < tEstLag)
            this.tEstimado += d.tiemporeal;
          else
            this.tEstimado += tEstLag;
        }

        // this.tNegativoCalidad += d['tNegativoCalidad'];
      }

    });

    // CARGAR ALARMAS
    cAlarma = this.cargarDatosAlarma(alarma, tPerdidaAlarma, tAlarmaOriginal);
    // CARGAR MANTENIMIENTOS
    cMantenimientos = this.cargarDatosMantenimientos(mantenimiento, tPerdidaMantenimientos, tMantenimientosOriginal);
    // CARGAR PERDIDAS
    cPerdidas = this.cargarDatosPerdidas(perdida, tPerdidaPerdidas, tPerdidaPerdidasOriginal);
    // CARGAR SUBPERDIDAS
    cSubperdidas = this.cargarDatosSubperdidas(subperdida, tPerdidaSubperdidas, tSubperdidasOriginal);
    // CARGAR GRUPO PERDIDAS
    cGrupoPerdidas = this.cargarDatosGrupoPerdidas(grupoperdida, tPerdidaGrupoPerdidas, tGrupoPerdidasOriginal);
    // RESUMEN SEMANA SUBESTADOS
    cResumenSemanaSubestados = this.cargarDatosResumenSemanaSubestados(resumenSemanaSubestados, tResumenSemanaSubestados, resumenSemanaSubestadosColors);

    // Calcular datos para graficos lineas y graficos por tipo OEE
    for (var j = 0; j < alarma.length; j++) {
      columnsArrayAlarmas.push([alarma[j]]);
      columnsArrayCantidadAlarmas.push([alarma[j]]);
      namesArrayAlarmas[alarma[j]] = this.cortarLeyenda(alarma[j]);
      xAlarmas[alarma[j]] = 'x';
    }
    var nAlarmasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[3][1]) + ")"
    };
    for (j = 0; j < cMantenimientos.length; j++) {
      columnsArrayMantenimientos.push([cMantenimientos[j][0]]);
      columnsArrayCantidadMantenimientos.push([cMantenimientos[j][0]]);
      namesArrayMantenimientos[cMantenimientos[j][0]] = this.cortarLeyenda(cMantenimientos[j][0]);
      xMantenimientos[cMantenimientos[j][0]] = 'x';
    }
    var nMantenimientosOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[3][1]) + ")"
    };
    for (j = 0; j < cPerdidas.length; j++) {
      columnsArrayPerdidas.push([cPerdidas[j][0]]);
      columnsArrayCantidadPerdidas.push([cPerdidas[j][0]]);
      namesArrayPerdidas[cPerdidas[j][0]] = this.cortarLeyenda(cPerdidas[j][0]);
      xPerdidas[cPerdidas[j][0]] = 'x';
    }
    var nPerdidasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[3][1]) + ")"
    };
    for (j = 0; j < cSubperdidas.length; j++) {
      columnsArraySubperdidas.push([cSubperdidas[j][0]]);
      columnsArrayCantidadSubperdidas.push([cSubperdidas[j][0]]);
      namesArraySubperdidas[cSubperdidas[j][0]] = this.cortarLeyenda(cSubperdidas[j][0]);
      xSubperdidas[cSubperdidas[j][0]] = 'x';
    }
    var nSubperdidasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[3][1]) + ")"
    };
    for (j = 0; j < cGrupoPerdidas.length; j++) {
      columnsArrayGrupoPerdidas.push([cGrupoPerdidas[j][0]]);
      columnsArrayCantidadGrupoPerdidas.push([cGrupoPerdidas[j][0]]);
      namesArrayGrupoPerdidas[cGrupoPerdidas[j][0]] = this.cortarLeyenda(cGrupoPerdidas[j][0]);
      xGrupoPerdidas[cGrupoPerdidas[j][0]] = 'x';
    }
    var nGrupoPerdidasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[3][1]) + ")"
    };

    // CARGAR DATOS PARA GRAFICOS DE LINEAS
    this.tipoAgrupado = this.agruparFechas(data); // de esta manera sabemos si hay que agrupar por día por semana o por año. 0=>dia, 1=>semana, 2=>año
    data.forEach(d => {

      var date = new Date(d['fechaTurno']);
      var month = date.getUTCMonth() + 1;
      var dayAux = date.getUTCDate() + 1;
      var day = date.getUTCFullYear() + "-" + month + "-" + dayAux;
      var diff = this.convertToDate(d);

      // ALARMAS LINEAS
      if (d['idProcesos_Tipo'] == 6) {
        if (this.tipoAgrupado == 0) var index = txArrayAlarmas.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayAlarmas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayAlarmas.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayAlarmas.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayAlarmas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayAlarmas.push(this.startOfMonth(date));
          for (var i = 1; i < columnsArrayAlarmas.length; i++) {
            if (columnsArrayAlarmas[i][0] == d['alarma'] && d["oculto"] == 0) {
              columnsArrayAlarmas[i].push(diff);
              columnsArrayCantidadAlarmas[i].push(1);
            } else {
              columnsArrayAlarmas[i].push(0);
              columnsArrayCantidadAlarmas[i].push(0);
            }
          }
        } else {
          for (var i = 1; i < columnsArrayAlarmas.length; i++) {
            if (columnsArrayAlarmas[i][0] == d['alarma']) {
              if (d["oculto"] == 0) {
                columnsArrayAlarmas[i][index] += diff;
                columnsArrayCantidadAlarmas[i][index] += 1;
              }
            }
          }
        }
      }

      // MANTENIMIENTOS LINEAS
      if (d['idProcesos_Tipo'] == 4) {
        if (this.tipoAgrupado == 0) var index = txArrayMantenimientos.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayMantenimientos.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayMantenimientos.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayMantenimientos.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayMantenimientos.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayMantenimientos.push(this.startOfMonth(date));
          for (var i = 1; i < columnsArrayMantenimientos.length; i++) {
            if (columnsArrayMantenimientos[i][0] == d['maquinaMantenimiento']) {
              if (d["oculto"] == 0) {
                columnsArrayMantenimientos[i].push(diff);
                columnsArrayCantidadMantenimientos[i].push(1);
              } else {
                columnsArrayMantenimientos[i].push(0);
                columnsArrayCantidadMantenimientos[i].push(0);
              }
            } else {
              columnsArrayMantenimientos[i].push(0);
              columnsArrayCantidadMantenimientos[i].push(0);
            }
          }
        } else {
          for (var i = 1; i < columnsArrayMantenimientos.length; i++) {
            if (columnsArrayMantenimientos[i][0] == d['maquinaMantenimiento']) {
              if (d["oculto"] == 0) {
                columnsArrayMantenimientos[i][index] += diff;
                columnsArrayCantidadMantenimientos[i][index] += 1;
              }
            }
          }
        }
      }

      // PERDIDAS LINEAS
      if (this.tipoAgrupado == 0) var index = txArrayPerdidas.indexOf(day.toString());
      else if (this.tipoAgrupado == 1) var index = txArrayPerdidas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
      else var index = txArrayPerdidas.indexOf(this.startOfMonth(date));
      if (index == -1) {
        if (this.tipoAgrupado == 0) txArrayPerdidas.push(day.toString());
        else if (this.tipoAgrupado == 1) txArrayPerdidas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else txArrayPerdidas.push(this.startOfMonth(date));
        for (var i = 1; i < columnsArrayPerdidas.length; i++) {
          if (((columnsArrayPerdidas[i][0] == d['perdida'])) && d["oculto"] == 0) {
            columnsArrayPerdidas[i].push(diff);
            columnsArrayCantidadPerdidas[i].push(1);
          } else {
            columnsArrayPerdidas[i].push(0);
            columnsArrayCantidadPerdidas[i].push(0);
          }
        }
      } else {
        for (var i = 1; i < columnsArrayPerdidas.length; i++) {
          if ((columnsArrayPerdidas[i][0] == d['perdida'])) {
            if (d["oculto"] == 0) {
              columnsArrayPerdidas[i][index] += diff;
              columnsArrayCantidadPerdidas[i][index] += 1;
            }
          }
        }
      }

      // SUBPERDIDAS LINEAS
      if (this.tipoAgrupado == 0) var index = txArraySubperdidas.indexOf(day.toString());
      else if (this.tipoAgrupado == 1) var index = txArraySubperdidas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
      else var index = txArraySubperdidas.indexOf(this.startOfMonth(date));
      if (index == -1) {
        if (this.tipoAgrupado == 0) txArraySubperdidas.push(day.toString());
        else if (this.tipoAgrupado == 1) txArraySubperdidas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else txArraySubperdidas.push(this.startOfMonth(date));
        for (var i = 1; i < columnsArraySubperdidas.length; i++) {
          if (columnsArraySubperdidas[i][0] == d['subPerdida'] && d["oculto"] == 0) {
            columnsArraySubperdidas[i].push(diff);
            columnsArrayCantidadSubperdidas[i].push(1);
          } else {
            columnsArraySubperdidas[i].push(0);
            columnsArrayCantidadSubperdidas[i].push(0);
          }
        }
      } else {
        for (var i = 1; i < columnsArraySubperdidas.length; i++) {
          if (columnsArraySubperdidas[i][0] == d['subPerdida'] && d["oculto"] == 0) {
            columnsArraySubperdidas[i][index] += diff;
            columnsArrayCantidadSubperdidas[i][index] += 1;
          }
        }
      }

      // GRUPO PERDIDAS LINEAS
      if (this.tipoAgrupado == 0) var index = txArrayGrupoPerdidas.indexOf(day.toString());
      else if (this.tipoAgrupado == 1) var index = txArrayGrupoPerdidas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
      else var index = txArrayGrupoPerdidas.indexOf(this.startOfMonth(date));
      if (index == -1) {
        if (this.tipoAgrupado == 0) txArrayGrupoPerdidas.push(day.toString());
        else if (this.tipoAgrupado == 1) txArrayGrupoPerdidas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else txArrayGrupoPerdidas.push(this.startOfMonth(date));
        for (var i = 1; i < columnsArrayGrupoPerdidas.length; i++) {
          if (columnsArrayGrupoPerdidas[i][0] == d['grupoPerdida'] && d["oculto"] == 0) {
            columnsArrayGrupoPerdidas[i].push(diff);
            columnsArrayCantidadGrupoPerdidas[i].push(1)
          } else {
            columnsArrayGrupoPerdidas[i].push(0);
            columnsArrayCantidadGrupoPerdidas[i].push(0)
          }
        }
      } else {
        for (var i = 1; i < columnsArrayGrupoPerdidas.length; i++) {
          if (columnsArrayGrupoPerdidas[i][0] == d['grupoPerdida'] && d["oculto"] == 0) {
            columnsArrayGrupoPerdidas[i][index] += diff;
            columnsArrayCantidadGrupoPerdidas[i][index] += 1;
          }
        }
      }

      // RESUMEN SEMANA LINEAS
      if (d["oculto"] == 0) {
        if (this.tipoAgrupado == 0) var index = txArrayResumenSemana.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayResumenSemana.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayResumenSemana.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayResumenSemana.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayResumenSemana.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayResumenSemana.push(this.startOfMonth(date));
          tEjecucionArray.push(0);
          tParadaArray.push(0);
          tMicroparadasArray.push(0);
          tPreparacionArray.push(0);
          tMantenimientoArray.push(0);
          tAlarmaArray.push(0);
          tApagadoArray.push(0);
          index = txArrayResumenSemana.length - 1
        }

        if (d.idProcesos_Tipo == 1) {
          tEjecucionArray[index] += diff;
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 0) {
          tParadaArray[index] += diff;
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 1) {
          tMicroparadasArray[index] += diff;
        } else if (d.idProcesos_Tipo == 3) {
          tPreparacionArray[index] += diff;
        } else if (d.idProcesos_Tipo == 4 && d['idmaquinaMantenimiento'] != -1) {
          tMantenimientoArray[index] += diff;
        } else if (d.idProcesos_Tipo == 6 || d.idProcesos_Tipo == 7) {
          tAlarmaArray[index] += diff;
        } else if (d.idProcesos_Tipo == 8) {
          tApagadoArray[index] += diff;
        }
      }

      // OEE LINEAS
      if (d["oculto"] == 0) {
        if (this.tipoAgrupado == 0) var index = txArrayOEE.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayOEE.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayOEE.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayOEE.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayOEE.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayOEE.push(this.startOfMonth(date));

          this.tEjecucionArray.push(0);
          this.tParadaDisponibilidadArray.push(0);
          this.tMantenimientoDisponibilidadArray.push(0);
          this.tAlarmaDisponibilidadArray.push(0);
          this.tApagadaDisponibilidadArray.push(0);
          this.tParadaRendimientoArray.push(0);
          this.tMantenimientoRendimientoArray.push(0);
          this.tAlarmaRendimientoArray.push(0);
          this.tApagadaRendimientoArray.push(0);
          this.tParadaCalidadArray.push(0);
          this.tMantenimientoCalidadArray.push(0);
          this.tAlarmaCalidadArray.push(0);
          this.tApagadaCalidadArray.push(0);
          this.tNegativoCalidadArray.push(0);
          this.tEstimadoArray.push(0);

          index = this.tEjecucionArray.length - 1;

        } else {
          index -= 1;
        }

        var tEjecucion = 0;

        if ((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 1) {
          this.tParadaDisponibilidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 1) {
          this.tMantenimientoDisponibilidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 1) {
          this.tAlarmaDisponibilidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 1) {
          this.tApagadaDisponibilidadArray[index] += this.convertToDate(d);
        }

        // si es rendimiento y tiene operacion entonces tiene que ir a ejecucion
        else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tParadaRendimientoArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tMantenimientoRendimientoArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tAlarmaRendimientoArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tApagadaRendimientoArray[index] += this.convertToDate(d);
        }

        else if ((d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tNegativoCalidadArray[index] += d.tiemporeal;
        } else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tParadaCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tMantenimientoCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tAlarmaCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tApagadaCalidadArray[index] += this.convertToDate(d);
        } else {
          this.tEjecucionArray[index] += this.convertToDate(d);
          tEjecucion = d.tiemporeal;
        }

        // if (d.idTipoPerdidaOEE == 2) {
        if (tEjecucion > 0) {
          // si la pieza tiene algo de preparacion (que no sea ni dispo ni calidad):
          //  - t estimado total = t esti ejecucion + t esti preparacion
          //  - t total: t total rendiminieto op + t total rendimiento prep 
          // si no:
          //  - t est: t esti ejecucion
          //  - t total: t total rendminieto op
          var tEstimadoTotal = 0;
          var tTotal = 0;
          var tEstEjec = 0;
          if (d.usaLotes)
            tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
          else
            tEstEjec = d.tiempoEstimadoEjecucion;

          if (d.tiempoTotalRendimientoOperacion == -1 || d.tiempoEstimadoEjecucion == -1) { // no tiene operacion
            // tEstimadoTotal = tEjecucion +  d.tiempoTotalRendimientoPreparacion;
            tEstimadoTotal = 0;
            tTotal = -1;
          } else if (d.tiempoTotalRendimientoPreparacion != 0) { // tiene preparacion
            tEstimadoTotal = tEstEjec + d.tiempoEstimadoPreparacion;
            // tTotal = d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion;
            tTotal = d.tiempoTotal;
          } else {
            tEstimadoTotal = tEstEjec;
            // tTotal = d.tiempoTotalRendimientoOperacion;
            tTotal = d.tiempoTotal;
          }

          // if (d.idEstado == 5 || d.idEstado == 4)
          //   var tEstLag = 0;
          // if (d.ejecucionSinOperacionRepercuteA == 0 && tTotal == -1)
          //   var tEstLag = 0;
          if (d.ejecucionSinOperacionRepercuteA != 0 && tTotal <= 0)
            var tEstLag = (tEjecucion + d.tiempoTotalRendimientoPreparacion) * (d.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
          else
            var tEstLag = tEstimadoTotal * d.tiemporeal / tTotal;

          if (d.idEstado == 5 || d.idEstado == 4)
            this.tNegativoCalidadArray[index] += Math.min(d.tiemporeal, tEstLag);

          // tiempo estimado parcial
          // despues de tener el tiempo estimado total, y el tiempo total operacion,
          // limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)
          if (d.tiemporeal < tEstLag)
            this.tEstimadoArray[index] += d.tiemporeal;
          else
            this.tEstimadoArray[index] += tEstLag;
        }

        // this.tNegativoCalidad += d['tNegativoCalidad'];
      }

    });

    // CARGAR ALARMAS
    this.dataAlarmasLineas['columns'] = columnsArrayAlarmas;
    this.dataAlarmasLineas['names'] = namesArrayAlarmas;
    this.dataAlarmasCantidadLineas['columns'] = columnsArrayCantidadAlarmas;
    this.dataAlarmasCantidadLineas['names'] = namesArrayAlarmas;
    this.cargarDatosAlarmasLineas(xAlarmas, cAlarma);
    this.dataAlarmasOEE['columns'] = cAlarmasOEE;
    this.dataAlarmasOEE['names'] = nAlarmasOEE;
    this.cargarDatosAlarmaOEE(cAlarmasOEE);
    this.cargarDatosGravedadAlarma(gravedadAlarma, Jgravedad, cAlarma);

    // CARGAR MANTENIMIENTOS
    this.dataMantenimientosLineas['columns'] = columnsArrayMantenimientos;
    this.dataMantenimientosLineas['names'] = namesArrayMantenimientos;
    this.dataMantenimientosCantidadLineas['columns'] = columnsArrayCantidadMantenimientos;
    this.dataMantenimientosCantidadLineas['names'] = namesArrayMantenimientos;
    this.cargarDatosMantenimientosLineas(xMantenimientos, cMantenimientos);
    this.dataMantenimientoOEE['columns'] = cMantenimientos;
    this.dataMantenimientoOEE['names'] = nMantenimientosOEE;
    this.cargarDatosMantenimientoOEE(cMantenimientosOEE);

    // CARGAR PERDIDAS
    this.dataPerdidasLineas['columns'] = columnsArrayPerdidas;
    this.dataPerdidasLineas['names'] = namesArrayPerdidas;
    this.dataPerdidasCantidadLineas['columns'] = columnsArrayCantidadPerdidas;
    this.dataPerdidasCantidadLineas['names'] = namesArrayPerdidas;
    this.cargarDatosPerdidasLineas(xPerdidas, cPerdidas);
    this.dataPerdidasOEE['columns'] = cPerdidasOEE;
    this.dataPerdidasOEE['names'] = nPerdidasOEE;
    this.cargarDatosPerdidasOEE(cPerdidasOEE);

    // CARGAR SUBPERDIDAS
    this.dataSubperdidasLineas['columns'] = columnsArraySubperdidas;
    this.dataSubperdidasLineas['names'] = namesArraySubperdidas;
    this.dataSubperdidasCantidadLineas['columns'] = columnsArrayCantidadSubperdidas;
    this.dataSubperdidasCantidadLineas['names'] = namesArraySubperdidas;
    this.cargarDatosSubperdidasLineas(xSubperdidas, cSubperdidas);
    this.dataSubperdidasOEE['columns'] = cSubperdidasOEE;
    this.dataSubperdidasOEE['names'] = nSubperdidasOEE;
    this.cargarDatosSubperdidasOEE(cSubperdidasOEE);

    // CARGAR GRUPO PERDIDAS
    this.dataGrupoPerdidasLineas['columns'] = columnsArrayGrupoPerdidas;
    this.dataGrupoPerdidasLineas['names'] = namesArrayGrupoPerdidas;
    this.dataGrupoPerdidasCantidadLineas['columns'] = columnsArrayCantidadGrupoPerdidas;
    this.dataGrupoPerdidasCantidadLineas['names'] = namesArrayGrupoPerdidas;
    this.cargarDatosGrupoPerdidasLineas(xGrupoPerdidas, cGrupoPerdidas);
    this.dataGrupoPerdidasOEE['columns'] = cGrupoPerdidasOEE;
    this.dataGrupoPerdidasOEE['names'] = nGrupoPerdidasOEE;
    this.cargarDatosGrupoPerdidasOEE(cGrupoPerdidasOEE);

    // CARGAR RESUMEN SEMANA
    this.cargarDatosResumenSemana(tPreparacion, tEjecucionResumenSemana, tMicroparadas, tParada, tMantenimiento, tAlarma, tApagado)
    this.dataResumenSemanaLineas = {
      columns: [
        txArrayResumenSemana,
        tPreparacionArray,
        tEjecucionArray,
        tMicroparadasArray,
        tParadaArray,
        tMantenimientoArray,
        tAlarmaArray,
        tApagadoArray
      ],
      names: {
        preparacion: this.translate.instant("preparacion"),
        ejecucion: this.translate.instant("ejecucion"),
        microparada: this.translate.instant("microparada"),
        parada: this.translate.instant("parada"),
        mantenimiento: this.translate.instant("mantenimiento"),
        alarma: this.translate.instant("alarma"),
        apagada: this.translate.instant("apagada"),
      }
    }
    this.cargarDatosResumenSemanaLineas();

    // CARGAR OEE
    this.cargarDatosOEE();
    this.cargarDatosOEE1();
    this.cargarDatosOEELineas(columnsArrayOEE, namesArrayOEE, xOEE);
    this.cargarDatosOEE1Lineas(columnsArrayOEE);

  }

  // prepararDatosOEE() {
  //   // OEE circular
  //   var idHistoricoOperacionesEjecucionArray = [];
  //   var idHistoricoOperacionesPreparacionArray = [];
  //   this.tEjecucionOEE = 0;
  //   this.tParadaDisponibilidad = 0;
  //   this.tMantenimientoDisponibilidad = 0;
  //   this.tAlarmaDisponibilidad = 0;
  //   this.tApagadaDisponibilidad = 0;
  //   this.tParadaRendimiento = 0;        
  //   this.tMantenimientoRendimiento = 0;
  //   this.tAlarmaRendimiento = 0;
  //   this.tApagadaRendimiento = 0;
  //   this.tParadaCalidad = 0;
  //   this.tMantenimientoCalidad = 0;
  //   this.tAlarmaCalidad = 0;
  //   this.tApagadaCalidad = 0;
  //   this.tNegativoCalidad = 0;   
  //   this.tEstimado = 0;
  //   this.tTotalHistoricoOperacion = 0;
  //   this.tEstimadoFinal = 0;
  //   this.tEstimadoGlobal = 0;

  //   var result = [];
  //   var arrayHistoricoOperaciones = {}
  //   this.data.forEach(d => {
  //     if (d.oculto == 0) {
  //       if (!arrayHistoricoOperaciones[d.idHistoricoOperaciones]) {
  //         if (d.idTipoPerdidaOEE == 2) {
  //           arrayHistoricoOperaciones[d.idHistoricoOperaciones] = {
  //             idHistoricoOperaciones: d.idHistoricoOperaciones,
  //             idOperacion: d.idOperacion,
  //             operacion: d.operacion,
  //             usaLotes: d.usaLotes,
  //             idTipoPerdidaOEE: d.idTipoPerdidaOEE,
  //             idProcesos_Tipo: d.idProcesos_Tipo,
  //             tiempoEstimadoEjecucion: d.tiempoEstimadoEjecucion,
  //             tiempoEstimadoPreparacion: d.tiempoEstimadoPreparacion,
  //             cantidad: d.cantidad,
  //             tEstimadoTotal: d.tiempoEstimadoEjecucion * d.cantidad + d.tiempoEstimadoPreparacion,
  //             tiempoParcial: d.tiemporeal,
  //             tiempoTotal: d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion,
  //             tiempoTotalRendimientoOperacion: d.tiempoTotalRendimientoOperacion,
  //             tiempoTotalRendimientoPreparacion: d.tiempoTotalRendimientoPreparacion,
  //             porcentajeTiempo: 0,
  //             tEstimadoPonderado: 0,
  //             tEstimadoMinimo: 0,
  //             idEstado: d.idEstado,
  //             ejecucionSinOperacionRepercuteA: d.ejecucionSinOperacionRepercuteA,
  //             porcentajeEjecucionSinOperacionRepercuteOEE: d.porcentajeEjecucionSinOperacionRepercuteOEE
  //           }
  //         } else {
  //           arrayHistoricoOperaciones[d.idHistoricoOperaciones] = {
  //             idHistoricoOperaciones: d.idHistoricoOperaciones,
  //             idOperacion: d.idOperacion,
  //             operacion: d.operacion,
  //             usaLotes: d.usaLotes,
  //             idTipoPerdidaOEE: d.idTipoPerdidaOEE,
  //             idProcesos_Tipo: d.idProcesos_Tipo,
  //             tiempoEstimadoEjecucion: d.tiempoEstimadoEjecucion,
  //             tiempoEstimadoPreparacion: d.tiempoEstimadoPreparacion,
  //             cantidad: d.cantidad,
  //             tEstimadoTotal: d.tiempoEstimadoEjecucion * d.cantidad + d.tiempoEstimadoPreparacion,
  //             tiempoParcial: 0,
  //             tiempoTotal: d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion,
  //             tiempoTotalRendimientoOperacion: d.tiempoTotalRendimientoOperacion,
  //             tiempoTotalRendimientoPreparacion: d.tiempoTotalRendimientoPreparacion,
  //             porcentajeTiempo: 0,
  //             tEstimadoPonderado: 0,
  //             tEstimadoMinimo: 0,
  //             idEstado: d.idEstado,
  //             ejecucionSinOperacionRepercuteA: d.ejecucionSinOperacionRepercuteA,
  //             porcentajeEjecucionSinOperacionRepercuteOEE: d.porcentajeEjecucionSinOperacionRepercuteOEE
  //           }
  //         }
  //         result.push(arrayHistoricoOperaciones[d.idHistoricoOperaciones]);
  //       } else {
  //         if (d.idTipoPerdidaOEE == 2) 
  //           arrayHistoricoOperaciones[d.idHistoricoOperaciones].tiempoParcial += d.tiemporeal;
  //       }
  //     }
  //   });

  //   result.forEach(d => {
  //     d.porcentajeTiempo = d.tiempoParcial * 100 / d.tiempoTotal;
  //     d.tEstimadoPonderado = d.tEstimadoTotal * d.porcentajeTiempo / 100;
  //     d.tEstimadoMinimo = Math.min(d.tEstimadoPonderado, d.tiempoParcial);
  //   });

  //   result.forEach(d => {
  //     var tEjecucion = 0;

  //     if((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 1) {
  //       this.tParadaDisponibilidad += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 1) {
  //       this.tMantenimientoDisponibilidad += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 1) {
  //       this.tAlarmaDisponibilidad += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 1) {
  //       this.tApagadaDisponibilidad += this.convertToDate(d);
  //     } 

  //     // si es rendimiento y tiene operacion entonces tiene que ir a ejecucion
  //     else if(d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
  //       this.tParadaRendimiento += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
  //       this.tMantenimientoRendimiento += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
  //       this.tAlarmaRendimiento += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
  //       this.tApagadaRendimiento += this.convertToDate(d);
  //     } 

  //     else if((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 3 ) {
  //       this.tParadaCalidad += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 3) {
  //       this.tMantenimientoCalidad += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 3) {
  //       this.tAlarmaCalidad += this.convertToDate(d);
  //     } else if(d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 3) {
  //       this.tApagadaCalidad += this.convertToDate(d);
  //     } else {
  //       this.tEjecucionOEE += this.convertToDate(d);
  //       tEjecucion = d.tiempoParcial;
  //     }

  //     // si la pieza tiene algo de preparacion (que no sea ni dispo ni calidad):
  //     //  - t estimado total = t esti ejecucion + t esti preparacion
  //     //  - t total: t total rendiminieto op + t total rendimiento prep 
  //     // si no:
  //     //  - t est: t esti ejecucion
  //     //  - t total: t total rendminieto op
  //     var tEstimadoTotal = 0;
  //     var tTotal = 0;
  //     var tEstEjec = 0;
  //     if (d.usaLotes)
  //       tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
  //     else 
  //       tEstEjec = d.tiempoEstimadoEjecucion;


  //     if (d.tiempoTotal == -1) { // no tiene operacion
  //       // tEstimadoTotal = tEjecucion +  d.tiempoTotalRendimientoPreparacion;
  //       tEstimadoTotal = 0;
  //       tTotal = -1;
  //     } else if (d.tiempoTotalRendimientoPreparacion != 0) { // tiene preparacion
  //       tEstimadoTotal = tEstEjec + d.tiempoEstimadoPreparacion;
  //       tTotal = d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion;
  //     } else {
  //       tEstimadoTotal = tEstEjec;
  //       tTotal = d.tiempoTotalRendimientoOperacion;
  //     }

  //     var tEstLag;
  //     if (d.idEstado == 5 || d.idEstado == 4)
  //       tEstLag = 0;
  //     else if (d.ejecucionSinOperacionRepercuteA == 0 && tTotal == -1)
  //       tEstLag = 0;
  //     else if (d.ejecucionSinOperacionRepercuteA != 0 && tTotal == -1)
  //       tEstLag = (tEjecucion +  d.tiempoTotalRendimientoPreparacion) * (d.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
  //     else
  //       tEstLag = d.tEstimadoMinimo //* d.tiemporeal / tTotal;

  //     // tiempo estimado parcial
  //     // despues de tener el tiempo estimado total, y el tiempo total operacion,
  //     // limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)

  //     this.tEstimado += Math.min(tEstLag, d.tiempoParcial);

  //     this.tNegativoCalidad += d['tNegativoCalidad'];
  //   });

  //   console.table(result);
  // }

  cargarDatosTablas() {
    var jsonTabla: any = [] // Datos para la tabla actual operaciones
    var i = 0;
    this.data.forEach(d => {

      //#region tEstimadoGlobal
      var tEstimadoGlobal = 0;
      var tEstEjec = 0;
      if (d.usaLotes)
        tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
      else
        tEstEjec = d.tiempoEstimadoEjecucion;

      if (d.tiempoTotalRendimientoPreparacion == 0)
        tEstimadoGlobal = tEstEjec;
      else
        tEstimadoGlobal = tEstEjec + d.tiempoEstimadoPreparacion;
      //#endregion

      jsonTabla.push({});
      var len = jsonTabla.length - 1;
      jsonTabla[len]['id'] = i;
      jsonTabla[len]['cantidad'] = d['cantidad'];
      jsonTabla[len]['idHistoricoOperaciones'] = d['idHistoricoOperaciones'];
      jsonTabla[len]['numeroOF'] = d['numeroOF'];
      jsonTabla[len]['cliente'] = d['cliente'];
      jsonTabla[len]['nombrePieza'] = d['nombrePieza'];
      jsonTabla[len]['nombreOperacion'] = d['nombreOperacion'];
      jsonTabla[len]['maquina'] = d['maquina'];
      jsonTabla[len]['fechaini'] = this.myFunctions.dateWithoutYearShorted(new Date(d['fechaini'])) + " " + this.myFunctions.dateToHHMM(new Date(d['fechaini']));
      jsonTabla[len]['fechafin'] = this.myFunctions.dateWithoutYearShorted(new Date(d['fechafin'])) + " " + this.myFunctions.dateToHHMM(new Date(d['fechafin']));
      jsonTabla[len]['piezasTerminadas'] = d['piezasTerminadas'];
      jsonTabla[len]['nSerie'] = d['nSerie'];
      jsonTabla[len]['colada'] = d['colada'];
      jsonTabla[len]['lote'] = d['lote'];
      jsonTabla[len]['oculto'] = d['oculto'];
      // convertir en string el tipoTurno
      switch (d['tipoTurno']) {
        case 1: jsonTabla[len]['tipoTurno'] = this.translate.instant("manana"); break;
        case 2: jsonTabla[len]['tipoTurno'] = this.translate.instant("tarde"); break;
        case 3: jsonTabla[len]['tipoTurno'] = this.translate.instant("noche"); break;
        default: break;
      }
      // procesar los tiempos por cada idHistoricoOperacion
      if (d['tiempoTotalRendimientoOperacion'] > 0)
        jsonTabla[len]['tiempoTotalRendimientoOperacion'] = d['tiempoTotalRendimientoOperacion'] - tEstEjec;
      else
        jsonTabla[len]['tiempoTotalRendimientoOperacion'] = d['tiempoTotalRendimientoOperacion'];
      if (d.tiempoTotalRendimientoPreparacion > 0)
        jsonTabla[len]['tiempoTotalRendimientoPreparacion'] = d['tiempoTotalRendimientoPreparacion'] - d['tiempoEstimadoPreparacion'];
      else
        jsonTabla[len]['tiempoTotalRendimientoPreparacion'] = d['tiempoTotalRendimientoPreparacion']

      if (d['tiempoTotalOperacion'] > 0)
        jsonTabla[len]['tiempoTotalDesvioOperacion'] = d['tiempoTotalOperacion'] - tEstEjec;
      else
        jsonTabla[len]['tiempoTotalDesvioOperacion'] = d['tiempoTotalOperacion'];
      if (d['tiempoTotalPreparacion'] > 0)
        jsonTabla[len]['tiempoTotalDesvioPreparacion'] = d['tiempoTotalPreparacion'] - d['tiempoEstimadoPreparacion'];
      else
        jsonTabla[len]['tiempoTotalDesvioPreparacion'] = d['tiempoTotalPreparacion']
      // jsonTabla[len]['tiempoEstimadoEjecucion'] = d['tiempoEstimadoEjecucion'];
      jsonTabla[len]['tiempoEstimadoEjecucion'] = tEstimadoGlobal;
      // tiempos totales
      jsonTabla[len]['tiempoTotalRendimientoTotal'] = jsonTabla[len]['tiempoTotalRendimientoOperacion'] + jsonTabla[len]['tiempoTotalRendimientoPreparacion'];
      jsonTabla[len]['tiempoTotalDesvioTotal'] = jsonTabla[len]['tiempoTotalDesvioOperacion'] + jsonTabla[len]['tiempoTotalDesvioPreparacion'];
      jsonTabla[len]['tiemporeal'] = d['tiemporeal'];
      // perdidas
      jsonTabla[len]['perdida'] = d['perdida'];
      jsonTabla[len]['subPerdida'] = d['subPerdida'];
      jsonTabla[len]['grupoPerdida'] = d['grupoPerdida'];
      // mantenimientos
      jsonTabla[len]['maquinaMantenimiento'] = d['maquinaMantenimiento'];
      // alarmas
      jsonTabla[len]['alarma'] = d['alarma'];
      jsonTabla[len]['gravedadAlarma'] = d['gravedadAlarma'];
      // maquinas
      jsonTabla[len]['maquina'] = d['maquina'];
      jsonTabla[len]['maquinaGrupo'] = d['maquinaGrupo'];
      jsonTabla[len]['seccion'] = d['seccion'];
      jsonTabla[len]['areaProductiva'] = d['areaProductiva'];

      i++;
    });

    // CARGAR TABLAS OPERACIONES
    this.cargarDatosTablaOperaciones(jsonTabla);

  }

  // esta funcion se utiliza para agrupar las fechas en los grafico de lineas en el eje x
  agruparFechas(d) {

    var date1 = new Date(this.fechaInicioFiltro);
    var date2 = new Date(this.fechaFinFiltro);
    var difTime = date2.getTime() - date1.getTime();
    var difDays = difTime / (1000 * 60 * 60 * 24);
    if (!isNaN(difDays) && difDays > 31 && difDays <= 182) { // hay que agrupar por semana     
      return 1;

    } else if (!isNaN(difDays) && difDays > 182) { // hay que agrupar por mes
      return 2;
    } else { // no hay que agrupar
      return 0;
    }

  }

  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  startOfMonth(date) {
    var d = date.getFullYear() + '-' + this.myFunctions.addZero(date.getMonth() + 1) + '-' + '01';
    return d;
  }
  getNumberOfWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }
  dateTo_Mes_YYYY(d) {
    var fecha = new Date(d);
    var year = fecha.getUTCFullYear();
    var month = fecha.getUTCMonth() + 2;
    switch (month) {
      case 1:
        return this.translate.instant('enero') + ' ' + year;
        break;
      case 2:
        return this.translate.instant('febrero') + ' ' + year;
        break;
      case 3:
        return this.translate.instant('marzo') + ' ' + year;
        break;
      case 4:
        return this.translate.instant('abril') + ' ' + year;
        break;
      case 5:
        return this.translate.instant('mayo') + ' ' + year;
        break;
      case 6:
        return this.translate.instant('junio') + ' ' + year;
        break;
      case 7:
        return this.translate.instant('julio') + ' ' + year;
        break;
      case 8:
        return this.translate.instant('agosto') + ' ' + year;
        break;
      case 9:
        return this.translate.instant('septiembre') + ' ' + year;
        break;
      case 10:
        return this.translate.instant('octubre') + ' ' + year;
        break;
      case 11:
        return this.translate.instant('noviembre') + ' ' + year;
        break;
      case 12:
        return this.translate.instant('diciembre') + ' ' + year;
        break;

      default:
        break;
    }
  }

  //#region PREPARAR LOS DATOS DESDE LOS DATOS OBTENIDOS DE LA FUNCION cargar_datos y cargarDatosTablas
  // agrupar la informacion de la taula segun las columnas
  cargarDatosTablaOperaciones(jsonTablaOperaciones) {
    this.formato.forEach(row => {
      row.forEach(element => {
        if (element.tipo == 37 || element.tipo == 15 || element.tipo == 35 || element.tipo == 36 || element.tipo == 39) {
          element.contenido.data = [];
          let uniqueRow = {};

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado    
          let jsonLag;
          if (element.filtrarMaquinas[0] != "-1" || element.filtrarOperarios[0] != "-1") {
            this.cargarDatosTablasConFiltroPropio(element.filtrarMaquinas, element.filtrarOperarios);
            jsonLag = this.myFunctions.copy(this.jsonTablaOperaciones);
          } else {
            jsonLag = this.myFunctions.copy(jsonTablaOperaciones);
          }

          if (!element.excluido)
            jsonLag = jsonTablaOperaciones.filter(f => f.oculto == 0);

          jsonLag.forEach(json => {
            // agrupar por columnas seleccionadas
            let valorRow = "";
            element.cols.forEach(col => {
              if (!col.columna.includes("tiempo") && !col.columna.includes("cantidad") && !col.columna.includes("fecha") && !col.columna.includes("piezasTerminadas"))
                valorRow += json[col.columna] + "_"
            });

            // para que en la tabla no aparezcan filas vacias
            var regex = /[^\_]/i;
            if (valorRow.search(regex) != -1) {
              if (typeof uniqueRow[valorRow] != "object") {
                uniqueRow[valorRow] = [];
                uniqueRow[valorRow][0] = json.id;
                uniqueRow[valorRow][1] = [json.idHistoricoOperaciones];
                json.idsRelacionados = [json.id];
                element.contenido.data.push(this.myFunctions.copy(json));
              } else {
                let index = uniqueRow[valorRow][0];
                element.contenido.data.every(valor => {
                  if (valor.id == index) {
                    valor.idsRelacionados.push(json.id) // es necesario para el filtrado de la vinculacion
                    valor.tiemporeal += json.tiemporeal;

                    // solo sumar los valores si tienen un idHistoricoOperacion diferente (por cada idHistoricoOperacion solo se coje un valor)
                    if (!(uniqueRow[valorRow][1].includes(json.idHistoricoOperaciones))) {
                      valor.tiempoTotalRendimientoOperacion += json.tiempoTotalRendimientoOperacion;
                      valor.tiempoTotalRendimientoPreparacion += json.tiempoTotalRendimientoPreparacion;
                      valor.tiempoTotalDesvioOperacion += json.tiempoTotalDesvioOperacion;
                      valor.tiempoTotalDesvioPreparacion += json.tiempoTotalDesvioPreparacion;
                      valor.tiempoTotalRendimientoTotal += json.tiempoTotalRendimientoTotal;
                      valor.tiempoTotalDesvioTotal += json.tiempoTotalDesvioTotal;
                      // valor.tiempoEstimadoEjecucion += json.tiempoEstimadoEjecucion;
                      valor.tiempoEstimadoEjecucion = Math.max(json.tiempoEstimadoEjecucion, valor.tiempoEstimadoEjecucion);
                      valor.cantidad += json.cantidad;
                      valor.fechafin = json.fechafin;
                      valor.piezasTerminadas += json.piezasTerminadas;
                      uniqueRow[valorRow][1].push(json.idHistoricoOperaciones);
                    }
                    return false;
                  }
                  return true;
                });
              }
            }
          });

          this.state.take = element.lineasMax;
          this.state.skip = 0;
          element.contenido.dataView = process(element.contenido.data, this.state);

        }
      });

    });
  }

  cargarDatosResumenSemana(tPreparacion, tEjecucion, tMicroparadas, tParada, tMantenimiento, tAlarma, tApagado, sinFiltroPropio = true) {

    // si no hay datos
    if (tPreparacion == 0 && tEjecucion == 0 && tMicroparadas == 0 && tParada == 0 && tMantenimiento == 0 && tAlarma == 0 && tApagado == 0) {
      this.labelResumenSemana = true;
    } else {
      this.labelResumenSemana = false;
    }

    var c = [
      ['preparacion', tPreparacion],
      ['ejecucion', tEjecucion],
      ['microparada', tMicroparadas],
      ['parada', tParada],
      ['mantenimiento', tMantenimiento],
      ['alarma', tAlarma],
      ['apagada', tApagado]
    ];
    this.dataResumenSemana = {
      columns: c,
      names: {
        preparacion: this.translate.instant("preparacion") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tPreparacion) + ")",
        ejecucion: this.translate.instant("ejecucion") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tEjecucion) + ")",
        microparada: this.translate.instant("microparada") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tMicroparadas) + ")",
        parada: this.translate.instant("parada") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tParada) + ")",
        mantenimiento: this.translate.instant("mantenimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tMantenimiento) + ")",
        alarma: this.translate.instant("alarma") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tAlarma) + ")",
        apagada: this.translate.instant("apagada") + " (" + this.myFunctions.secondsTo_HH_MM_SS(tApagado) + ")",
      },

    }

    if (sinFiltroPropio)
      this.cargarResumenSemanaCircular(this.dataResumenSemana["columns"], this.dataResumenSemana["names"]);


  }

  cargarDatosResumenSemanaLineas(sinFiltroPropio = true) {
    // buscar el maximo para cargar el eje y dinamico (la primera fila y la primera columna son no dinamicas)
    var max = 36000; // el minimo sera 3 horas
    var values = [];
    for (var i = 1; i < this.dataResumenSemanaLineas['columns'].length; i++) {
      for (var j = 1; j < this.dataResumenSemanaLineas['columns'][i].length; j++) {
        if (this.dataResumenSemanaLineas['columns'][i][j] > max) max = this.dataResumenSemanaLineas['columns'][i][j];
      }
    }
    for (var i = 1; i <= 10; i++) {
      values.push((max / 10) * i);
    }
    if (this.cantidadResumenSemanaLineas > 0 && sinFiltroPropio) {
      this.cargarResumenSemanaLineas(this.dataResumenSemanaLineas, this.dataResumenSemana['columns'], max, values);
    }
  }

  cargarDatosResumenSemanaSubestados(resumenSemanaSubestados, tResumenSemanaSubestados, colors, sinFiltroPropio = true) {
    var cResumenSemanaSubestados = [];
    var nResumenSemanaSubestados = {};
    var j = 0;
    resumenSemanaSubestados.forEach(element => {
      cResumenSemanaSubestados.push([element, tResumenSemanaSubestados[j]]);
      nResumenSemanaSubestados[element] = element + " (" + this.myFunctions.secondsTo_HH_MM_SS(tResumenSemanaSubestados[j]) + ")";
      j++;
    });

    this.dataResumenSemanaSubestados = {
      columns: cResumenSemanaSubestados,
      names: nResumenSemanaSubestados,
      colors: colors
    }

    // si no hay datos
    if (cResumenSemanaSubestados.length == 0) {
      this.labelResumenSemana = true;
    } else {
      this.labelResumenSemana = false;
    }

    if (this.cantidadResumenSemanaSubestados > 0 && sinFiltroPropio) {
      this.cargarResumenSemanaSubestadosCircular(this.dataResumenSemanaSubestados, cResumenSemanaSubestados);
    }

    return cResumenSemanaSubestados;
  }

  cargarDatosOEE(sinFiltroPropio = true) {
    //CALCULAR DONUTS
    var oee: number = 0;
    var cal: number = 0;
    var ren: number = 0;
    var dis: number = 0;

    var perdidasDisponibilidad = this.tParadaDisponibilidad + this.tMantenimientoDisponibilidad + this.tAlarmaDisponibilidad + this.tApagadaDisponibilidad;
    var perdidasRendimiento = this.tParadaRendimiento + this.tMantenimientoRendimiento + this.tAlarmaRendimiento + this.tApagadaRendimiento;

    var perdidasCalidad = this.tParadaCalidad + this.tMantenimientoCalidad + this.tAlarmaCalidad + this.tApagadaCalidad;

    var disDividendo: number = this.tEjecucionOEE + perdidasRendimiento;
    var disDivisor: number = this.tEjecucionOEE + perdidasRendimiento + perdidasDisponibilidad;
    if (disDivisor == 0) dis = 0;
    else dis = (disDividendo / disDivisor) * 100;

    var renDividendo: number = this.tEstimado - this.tNegativoCalidad;
    var renDivisor: number = this.tEjecucionOEE + perdidasRendimiento;
    if (renDivisor == 0) ren = 0;
    else ren = (renDividendo / renDivisor) * 100;

    var calDividendo: number = this.tEstimado - this.tNegativoCalidad - perdidasCalidad;
    var calDivisor: number = this.tEstimado;
    if (calDivisor == 0) cal = 0;
    else cal = (calDividendo / calDivisor) * 100;

    // oee = cal * ren * dis / (10000);

    if (this.cantidadOEE > 0) {

      if (oee > 100) oee = 100;
      if (dis > 100) dis = 100;
      if (ren > 100) ren = 100;
      if (cal > 100) cal = 100;

      oee = cal * ren * dis / (10000);

      var dataGraficoDonutOEE = [['completo', oee], ['nocompleto', 100 - oee]];
      var dataGraficoDonutDisponibilidad = [['completo', dis], ['nocompleto', 100 - dis]];
      var dataGraficoDonutRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
      var dataGraficoDonutCalidad = [['completo', cal], ['nocompleto', 100 - cal]];

      this.dataChartDonutOEE.columns = dataGraficoDonutOEE;
      this.dataChartDonutDisponibilidad.columns = dataGraficoDonutDisponibilidad;
      this.dataChartDonutRendimiento.columns = dataGraficoDonutRendimiento;
      this.dataChartDonutCalidad.columns = dataGraficoDonutCalidad;

      if (sinFiltroPropio) {
        this.cargarOEECircular(dataGraficoDonutOEE);
        this.cargarDisponibilidadCircular(dataGraficoDonutDisponibilidad);
        this.cargarRendimientoCircular(dataGraficoDonutRendimiento);
        this.cargarCalidadCircular(dataGraficoDonutCalidad);
      }
    }

    // Barras OEE
    var tRendimiento: number = 0;
    if (this.tEstimado > this.tEjecucionOEE) {
      tRendimiento = 0;
      this.tEstimado = this.tEjecucionOEE;
    } else {
      tRendimiento = this.tEjecucionOEE - this.tEstimado;
    }

    if (this.cantidadOEEBarra > 0) {
      this.dataOEEBarras = [[this.translate.instant('total'), tRendimiento + this.tEstimado + perdidasRendimiento + perdidasDisponibilidad, tRendimiento + this.tEstimado + perdidasRendimiento, this.tEstimado, this.tEstimado - this.tNegativoCalidad - perdidasCalidad],
      [this.translate.instant('rendimiento'), 0, 0, tRendimiento, 0],
      [this.translate.instant('paradas'), 0, this.tParadaDisponibilidad, this.tParadaRendimiento, this.tParadaCalidad],
      [this.translate.instant('mantenimientos'), 0, this.tMantenimientoDisponibilidad, this.tMantenimientoRendimiento, this.tMantenimientoCalidad],
      [this.translate.instant('alarmas'), 0, this.tAlarmaDisponibilidad, this.tAlarmaRendimiento, this.tAlarmaCalidad],
      [this.translate.instant('apagadas'), 0, this.tApagadaDisponibilidad, this.tApagadaRendimiento, this.tApagadaCalidad],
      [this.translate.instant('perdidasCalidad'), 0, 0, 0, this.tNegativoCalidad]];

      if (sinFiltroPropio) {
        this.cargarOEEBarras(this.dataOEEBarras)
      }
    }

  }

  cargarDatosOEE1(sinFiltroPropio = true) {
    var oee: number = 0;
    var cal: number = 0;
    var ren: number = 0;
    var dis: number = 0;

    var perdidasDisponibilidad = this.tParadaDisponibilidad + this.tMantenimientoDisponibilidad + this.tAlarmaDisponibilidad + this.tApagadaDisponibilidad;
    var perdidasRendimiento = this.tParadaRendimiento + this.tMantenimientoRendimiento + this.tAlarmaRendimiento + this.tApagadaRendimiento;

    var perdidasCalidad = this.tParadaCalidad + this.tMantenimientoCalidad + this.tAlarmaCalidad + this.tApagadaCalidad;

    var disDividendo: number = this.tEjecucionOEE + perdidasRendimiento;
    var disDivisor: number = this.tEjecucionOEE + perdidasRendimiento + perdidasDisponibilidad;
    if (disDivisor == 0) dis = 0;
    else dis = (disDividendo / disDivisor) * 100;

    var renDividendo: number = this.tEstimado;
    var renDivisor: number = this.tEjecucionOEE + perdidasRendimiento;
    if (renDivisor == 0) ren = 0;
    else ren = (renDividendo / renDivisor) * 100;

    var calDividendo: number = this.tEstimado - this.tNegativoCalidad - perdidasCalidad;
    var calDivisor: number = this.tEstimado;
    if (calDivisor == 0) cal = 0;
    else cal = (calDividendo / calDivisor) * 100;

    oee = cal * ren * dis / (10000);

    if (disDivisor == 0) dis = 0;
    else dis = (disDividendo / disDivisor) * 100;

    if (renDivisor == 0) ren = 0;
    else ren = (renDividendo / renDivisor) * 100;

    // oee = cal * ren * dis / (10000);
    d3.select('#chartDonutOEE1_0 .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(oee) + "%");

    if (oee > 100) oee = 100;
    if (dis > 100) dis = 100;
    if (ren > 100) ren = 100;
    if (cal > 100) cal = 100;
    oee = cal * ren * dis / (10000);
    var dataGraficoDonutOEE1 = [['completo', oee], ['nocompleto', 100 - oee]];
    this.dataChartDonutOEE1 = dataGraficoDonutOEE1;

    if (this.cantidadOee1 > 0 && sinFiltroPropio) {
      this.cargarOEE1(dataGraficoDonutOEE1);
    }
  }

  cargarDatosOEELineas(columnsArrayOEE, namesArrayOEE, xOEE, sinFiltroPropio = true) {
    for (var i = 0; i < this.tEjecucionArray.length; i++) {
      let oee = 0;
      let cal = 0;
      let ren = 0;
      let dis = 0;

      let perdidasDisponibilidad = this.tParadaDisponibilidadArray[i] + this.tMantenimientoDisponibilidadArray[i] + this.tAlarmaDisponibilidadArray[i] + this.tApagadaDisponibilidadArray[i];
      let perdidasRendimiento = this.tParadaRendimientoArray[i] + this.tMantenimientoRendimientoArray[i] + this.tAlarmaRendimientoArray[i] + this.tApagadaRendimientoArray[i];

      let perdidasCalidad = this.tParadaCalidadArray[i] + this.tMantenimientoCalidadArray[i] + this.tAlarmaCalidadArray[i] + this.tApagadaCalidadArray[i];

      let disDividendo = this.tEjecucionArray[i] + perdidasRendimiento;
      let disDivisor = this.tEjecucionArray[i] + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      let renDividendo = this.tEstimadoArray[i];
      let renDivisor = this.tEjecucionArray[i] + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      let calDividendo = this.tEstimadoArray[i] - this.tNegativoCalidadArray[i] - perdidasCalidad;
      let calDivisor = this.tEstimadoArray[i];
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);

      // disponibilidad
      columnsArrayOEE[1].push(dis);
      // rendimiento
      columnsArrayOEE[2].push(ren);
      // calidad
      columnsArrayOEE[3].push(cal);
      // total
      columnsArrayOEE[4].push(oee);
    }

    this.dataOEELineas['columns'] = columnsArrayOEE;
    this.dataOEELineas['names'] = namesArrayOEE;

    if (this.cantidadOEELineas > 0 && sinFiltroPropio) {
      this.cargarOEELineas(this.dataOEELineas, xOEE)
    }
  }

  cargarDatosOEE1Lineas(columnsArrayOEE, sinFiltroPropio = true) {
    this.dataOEE1Lineas['columns'] = [columnsArrayOEE[0], columnsArrayOEE[4]];
    this.dataOEE1Lineas['names'] = {
      'total': this.translate.instant('total')
    }
    var x1OEE = { 'total': 'x' };

    if (this.cantidadOEE1Lineas > 0 && sinFiltroPropio) {
      this.cargarOEE1Lineas(this.dataOEE1Lineas, x1OEE);
    }
  }

  cargarDatosMantenimientos(mantenimiento, tPerdidaMantenimientos, tMantenimientosOriginal, sinFiltroPropio = true) {
    var cMantenimientos = [];
    var nMantenimientos = {};
    for (var j = 0; j < mantenimiento.length; j++) {
      if (mantenimiento[j] == "") {
        mantenimiento[j] = "Sin asignar";
      }
      cMantenimientos[j] = [mantenimiento[j], tPerdidaMantenimientos[j], tMantenimientosOriginal[j]];
    }
    // TOP N
    cMantenimientos.sort((a, b) => b[2] - a[2]);
    var top = cMantenimientos.slice(0, this.topN);
    top.forEach(t => {
      nMantenimientos[t[0]] = this.cortarLeyenda(t[0]) + " (" + this.myFunctions.secondsTo_HH_MM_SS(t[1]) + ")";
      // t.pop();
    });

    // RESTANTE
    if (cMantenimientos.length >= this.topN) {
      var totalOtros = 0;
      var totalOtrosTot = 0;
      var restante = cMantenimientos.slice((this.topN - 1), cMantenimientos.length);
      restante.forEach(element => {
        totalOtros += element[1];
        totalOtrosTot += element[2];
      });
      top.push(['otros', totalOtros, totalOtrosTot]);
      nMantenimientos['otros'] = this.translate.instant('otros') + " (" + this.myFunctions.secondsTo_HH_MM_SS(totalOtros) + ")";
    }

    top = top.sort((a,b) => b[2] - a[2]);
    top.forEach(t => t.pop());
    this.dataGraficoDonutMantenimientos['columns'] = top;
    this.dataGraficoDonutMantenimientos['names'] = nMantenimientos;

    // si no hay datos
    if (cMantenimientos.length == 0) {
      this.labelMantenimientos = true;

    } else {
      this.labelMantenimientos = false;
    }

    if (this.cantidadMantenimiento > 0 && sinFiltroPropio) {
      this.cargarMantenimientoCircular(this.dataGraficoDonutMantenimientos, top);
    }

    return top;

  }

  cargarDatosMantenimientosLineas(xMantenimientos, cMantenimientos, sinFiltroPropio = true) {
    // buscar el maximo para cargar el eje y dinamico (la primera fila y la primera columna son no dinamicas)
    var max = 36000; // el minimo sera 10 horas
    var values = [];
    for (var i = 1; i < this.dataMantenimientosLineas['columns'].length; i++) {
      for (var j = 1; j < this.dataMantenimientosLineas['columns'][i].length; j++) {
        if (this.dataMantenimientosLineas['columns'][i][j] > max) max = this.dataMantenimientosLineas['columns'][i][j];
      }
    }
    for (var i = 1; i <= 10; i++) {
      values.push((max / 10) * i);
    }
    if (this.cantidadMantenimientosLineas > 0 && sinFiltroPropio) {
      this.cargarMantenimientoLineas(this.dataMantenimientosLineas, xMantenimientos, cMantenimientos, max, values);
    }

    if (this.cantidadMantenimientosCantidadLineas > 0 && sinFiltroPropio) {
      this.cargarMantenimientoCantidadLineas(this.dataMantenimientosCantidadLineas, xMantenimientos, cMantenimientos);
    }

  }

  cargarDatosMantenimientoOEE(cMantenimientos, sinFiltroPropio = true) {
    // si no hay datos
    if (cMantenimientos[0][1] == 0 && cMantenimientos[1][1] == 0 && cMantenimientos[2][1] == 0 && cMantenimientos[3][1] == 0) {
      this.labelMantenimientos = true;
    } else {
      this.labelMantenimientos = false;
    }
    this.dataMantenimientoOEE["columns"] = cMantenimientos;

    if (this.cantidadMantenimientoOEE > 0 && sinFiltroPropio) {
      this.cargarMantenimientosOEECircular(cMantenimientos);
    }

  }

  cargarDatosGravedadAlarma(gravedadAlarma, Jgravedad, cAlarma, sinFiltroPropio = true) {
    var cGravedad = [];
    var nGravedad = [];
    for (var j = 0; j < gravedadAlarma.length; j++) {
      if (gravedadAlarma[j] == -1) {
        gravedadAlarma[j] = "Sin asignar";
      }
      cGravedad[j] = [gravedadAlarma[j], Jgravedad[j]];
      nGravedad[gravedadAlarma[j]] = this.cortarLeyenda(gravedadAlarma[j]) + " (" + this.myFunctions.secondsTo_HH_MM_SS(Jgravedad[j]) + ")";
    }

    // gravedad
    this.dataGraficoDonutGravedadAlarmas['columns'] = cGravedad;
    this.dataGraficoDonutGravedadAlarmas['names'] = nGravedad;

    if (this.cantidadDobleAlarma > 0 && sinFiltroPropio) {
      this.cargarDobleAlarmaCircular(cGravedad, nGravedad, cAlarma);
    }

  }

  cargarDatosAlarma(alarma, tPerdida, tPerdidaOriginal, sinFiltroPropio = true) {
    var cAlarma = [];
    var nAlarma = [];
    for (var j = 0; j < alarma.length; j++) {
      if (alarma[j] == "") {
        alarma[j] = "Sin asignar";
      }
      cAlarma[j] = [alarma[j], tPerdida[j], tPerdidaOriginal[j]];
    }
    // TOP N
    cAlarma.sort((a, b) => b[2] - a[2]);
    var top = cAlarma.slice(0, this.topN);
    top.forEach(t => {
      nAlarma[t[0]] = this.cortarLeyenda(t[0]) + " (" + this.myFunctions.secondsTo_HH_MM_SS(t[1]) + ")";
      // t.pop();
    });

    // RESTANTE
    if (cAlarma.length >= this.topN) {
      var totalOtros = 0;
      var totalOtrosTot = 0;
      var restante = cAlarma.slice((this.topN - 1), cAlarma.length);
      restante.forEach(element => {
        totalOtros += element[1];
        totalOtrosTot += element[2];
      });
      top.push(['otros', totalOtros, totalOtrosTot]);
      nAlarma['otros'] = this.translate.instant('otros') + " (" + this.myFunctions.secondsTo_HH_MM_SS(totalOtros) + ")";
    }

    top = top.sort((a,b) => b[2] - a[2]);
    top.forEach(t => t.pop());
    this.dataGraficoDonutAlarmas['columns'] = top;
    this.dataGraficoDonutAlarmas['names'] = nAlarma;

    // Si no hay datos
    if (cAlarma.length == 0) {
      this.labelAlarmas = true;
    } else {
      this.labelAlarmas = false;
    }

    if (this.cantidadAlarma > 0 && sinFiltroPropio) {
      this.cargarAlarmaCircular(this.dataGraficoDonutAlarmas, top);
    }
    if (this.cantidadDobleAlarma > 0 && sinFiltroPropio) {
      this.cargarDobleAlarmaCircularTipo(this.dataGraficoDonutAlarmas, top);
    }

    return top;

  }

  cargarDatosAlarmasLineas(x, cAlarma, sinFiltroPropio = true) {
    var max = 36000; // el minimo sera 10 horas
    var values = [];
    for (var i = 1; i < this.dataAlarmasLineas['columns'].length; i++) {
      for (var j = 1; j < this.dataAlarmasLineas['columns'][i].length; j++) {
        if (this.dataAlarmasLineas['columns'][i][j] > max) max = this.dataAlarmasLineas['columns'][i][j];
      }
    }
    for (var i = 1; i <= 10; i++) {
      values.push((max / 10) * i);
    }
    if (this.cantidadAlarmasLineas > 0 && sinFiltroPropio) {
      this.cargarAlarmasLineas(this.dataAlarmasLineas, x, cAlarma, max, values);
    }

    if (this.cantidadAlarmasCantidadLineas > 0 && sinFiltroPropio) {
      this.cargarAlarmasCantidadLineas(this.dataAlarmasCantidadLineas, x, cAlarma)
    }
  }

  cargarDatosAlarmaOEE(cAlarmasOEE, sinFiltroPropio = true) {
    // si no hay datos
    if (cAlarmasOEE[0][1] == 0 && cAlarmasOEE[1][1] == 0 && cAlarmasOEE[2][1] == 0 && cAlarmasOEE[3][1] == 0) {
      this.labelAlarmas = true
    } else {
      this.labelAlarmas = false;
    }
    this.dataAlarmasOEE["columns"] = cAlarmasOEE;
    if (this.cantidadAlarmaOEE > 0 && sinFiltroPropio) {
      this.cargarAlarmasOEECircular(cAlarmasOEE);
    }

  }

  cargarDatosPerdidas(perdida, tPerdidaPerdidas, tPerdidasOriginal, sinFiltroPropio = true) {
    var cPerdidas = [];
    var nPerdidas = [];
    for (var j = 0; j < perdida.length; j++) {
      if (perdida[j] == "") {
        perdida[j] = "Sin asignar";
      }
      cPerdidas[j] = [perdida[j], tPerdidaPerdidas[j], tPerdidasOriginal[j]];
    }
    // TOP N
    cPerdidas.sort((a, b) => b[2] - a[2]);
    var top = cPerdidas.slice(0, (this.topN - 1));
    top.forEach(t => {
      nPerdidas[t[0]] = this.cortarLeyenda(t[0]) + " (" + this.myFunctions.secondsTo_HH_MM_SS(t[1]) + ")";
      // t.pop();
    });

    // RESTANTE
    if (cPerdidas.length >= this.topN) {
      var totalOtros = 0;
      var totalOtrosTot = 0;
      var restante = cPerdidas.slice((this.topN - 1), cPerdidas.length);
      restante.forEach(element => {
        totalOtros += element[1];
        totalOtrosTot += element[2];
      });
      top.push(['otros', totalOtros, totalOtrosTot]);
      nPerdidas['otros'] = this.translate.instant('otros') + " (" + this.myFunctions.secondsTo_HH_MM_SS(totalOtros) + ")";
    }

    top = top.sort((a,b) => b[2] - a[2]);
    top.forEach(t => t.pop());
    this.dataGraficoDonutParadas['columns'] = top;
    this.dataGraficoDonutParadas['names'] = nPerdidas;

    // si no hay datos
    if (cPerdidas.length == 0) {
      this.labelPerdidas = true;
    } else {
      this.labelPerdidas = false;
    }

    if (this.cantidadPerdidas > 0 && sinFiltroPropio) {
      this.cargarPerdidasCircular(this.dataGraficoDonutParadas, top);
    }

    return top;
  }

  cargarDatosPerdidasLineas(xPerdidas, cPerdidas, sinFiltroPropio = true) {
    var max = 36000; // el minimo sera 10 horas
    var values = [];
    for (var i = 1; i < this.dataPerdidasLineas['columns'].length; i++) {
      for (var j = 1; j < this.dataPerdidasLineas['columns'][i].length; j++) {
        if (this.dataPerdidasLineas['columns'][i][j] > max) max = this.dataPerdidasLineas['columns'][i][j];
      }
    }
    for (var i = 1; i <= 10; i++) {
      values.push((max / 10) * i);
    }
    if (this.cantidadPerdidasLineas > 0 && sinFiltroPropio) {
      this.cargarPerdidasLineas(this.dataPerdidasLineas, xPerdidas, cPerdidas, max, values);
    }

    if (this.cantidadPerdidasCantidadLineas > 0 && sinFiltroPropio) {
      this.cargarPerdidasCantidadLineas(this.dataPerdidasCantidadLineas, xPerdidas, cPerdidas);
    }
  }

  cargarDatosPerdidasOEE(cPerdidasOEE, sinFiltroPropio = true) {
    // si no hay datos
    if (cPerdidasOEE[0][1] == 0 && cPerdidasOEE[1][1] == 0 && cPerdidasOEE[2][1] == 0 && cPerdidasOEE[3][1] == 0) {
      this.labelPerdidas = true;
    } else {
      this.labelPerdidas = false;
    }
    this.dataPerdidasOEE["columns"] = cPerdidasOEE;
    if (this.cantidadPerdidasOEE > 0 && sinFiltroPropio) {
      this.cargarPerdidasOEECircular(cPerdidasOEE);
    }

  }

  cargarDatosSubperdidas(subperdida, tPerdidaSubperdidas, tSubperdidasOriginal, sinFiltroPropio = true) {
    var cSubperdidas = [];
    var nSubperdidas = {};

    for (var j = 0; j < subperdida.length; j++) {
      if (subperdida[j] == "") {
        subperdida[j] = "Sin asignar";
      }
      cSubperdidas[j] = [subperdida[j], tPerdidaSubperdidas[j], tSubperdidasOriginal[j]];
    }
    // TOP N
    cSubperdidas.sort((a, b) => b[2] - a[2]);
    var top = cSubperdidas.slice(0, this.topN);
    top.forEach(t => {
      nSubperdidas[t[0]] = this.cortarLeyenda(t[0]) + " (" + this.myFunctions.secondsTo_HH_MM_SS(t[1]) + ")";
      // t.pop();
    });
    
    // RESTANTE
    if (cSubperdidas.length >= this.topN) {
      var totalOtros = 0;
      var totalOtrosTot = 0;
      var restante = cSubperdidas.slice((this.topN - 1), cSubperdidas.length);
      restante.forEach(element => {
        totalOtros += element[1];
        totalOtrosTot += element[2];
      });
      top.push(['otros', totalOtros, totalOtrosTot]);
      nSubperdidas['otros'] = this.translate.instant('otros') + " (" + this.myFunctions.secondsTo_HH_MM_SS(totalOtros) + ")";
    }

    top = top.sort((a,b) => b[2] - a[2]);
    top.forEach(t => t.pop());
    this.dataGraficoDonutSubperdidas['columns'] = top;
    this.dataGraficoDonutSubperdidas['names'] = nSubperdidas;

    // si no hay datos
    if (cSubperdidas.length == 0) {
      this.labelSubperdidas = true;
    } else {
      this.labelSubperdidas = false;
    }

    if (this.cantidadSubperdidas > 0 && sinFiltroPropio) {
      this.cargarSubperdidasCircular(this.dataGraficoDonutSubperdidas, top);
    }

    return top;
  }

  cargarDatosSubperdidasLineas(xSubperdidas, cSubperdidas, sinFiltroPropio = true) {
    // buscar el maximo para cargar el eje y dinamico (la primera fila y la primera columna son no dinamicas)
    var max = 36000; // el minimo sera 10 horas
    var values = [];
    for (var i = 1; i < this.dataSubperdidasLineas['columns'].length; i++) {
      for (var j = 1; j < this.dataSubperdidasLineas['columns'][i].length; j++) {
        if (this.dataSubperdidasLineas['columns'][i][j] > max) max = this.dataSubperdidasLineas['columns'][i][j];
      }
    }
    for (var i = 1; i <= 10; i++) {
      values.push((max / 10) * i);
    }
    if (this.cantidadSubperdidasLineas > 0 && sinFiltroPropio) {
      this.cargarSubperdidasLineas(this.dataSubperdidasLineas, xSubperdidas, cSubperdidas, max, values);
    }

    if (this.cantidadSubperdidasCantidadLineas > 0 && sinFiltroPropio) {
      this.cargarSubperdidasCantidadLineas(this.dataSubperdidasCantidadLineas, xSubperdidas, cSubperdidas);
    }
  }

  cargarDatosSubperdidasOEE(cSubperdidasOEE, sinFiltroPropio = true) {
    // si no hay datos
    if (cSubperdidasOEE[0][1] == 0 && cSubperdidasOEE[1][1] == 0 && cSubperdidasOEE[2][1] == 0 && cSubperdidasOEE[3][1] == 0) {
      this.labelSubperdidas = true;
    } else {
      this.labelSubperdidas = false;
    }
    this.dataSubperdidasOEE["columns"] = cSubperdidasOEE;

    if (this.cantidadSubperdidasOEE > 0 && sinFiltroPropio) {
      this.cargarSubperdidasOEECircular(cSubperdidasOEE);
    }

  }

  cargarDatosGrupoPerdidas(grupoperdida, tPerdidaGrupoPerdidas, tGrupoPerdidasOriginal, sinFiltroPropio = true) {
    var cGrupoPerdidas = [];
    var nGrupoPerdidas = {};
    for (var j = 0; j < grupoperdida.length; j++) {
      if (grupoperdida[j] == "") {
        grupoperdida[j] = "Sin asignar";
      }
      cGrupoPerdidas[j] = [grupoperdida[j], tPerdidaGrupoPerdidas[j], tGrupoPerdidasOriginal[j]];
    }
    // TOP N
    cGrupoPerdidas.sort((a, b) => b[2] - a[2]);
    var top = cGrupoPerdidas.slice(0, this.topN);
    top.forEach(t => {
      nGrupoPerdidas[t[0]] = this.cortarLeyenda(t[0]) + " (" + this.myFunctions.secondsTo_HH_MM_SS(t[1]) + ")";
      // t.pop();
    });

    // RESTANTE
    if (cGrupoPerdidas.length >= this.topN) {
      var totalOtros = 0;
      var totalOtrosTot = 0;
      var restante = cGrupoPerdidas.slice((this.topN - 1), cGrupoPerdidas.length);
      restante.forEach(element => {
        totalOtros += element[1];
        totalOtrosTot += element[2];
      });
      top.push(['otros', totalOtros, totalOtrosTot]);
      nGrupoPerdidas['otros'] = this.translate.instant('otros') + " (" + this.myFunctions.secondsTo_HH_MM_SS(totalOtros) + ")";
    }

    top = top.sort((a,b) => b[2] - a[2]);
    top.forEach(t => t.pop());
    this.dataGraficoDonutGrupoPerdidas['columns'] = top;
    this.dataGraficoDonutGrupoPerdidas['names'] = nGrupoPerdidas;

    // si no hay datos
    if (cGrupoPerdidas.length == 0) {
      this.labelGrupoPerdidas = true;
    } else {
      this.labelGrupoPerdidas = false;
    }

    if (this.cantidadGrupoperdidas > 0 && sinFiltroPropio) {
      this.cargarGrupoPerdidasCircular(this.dataGraficoDonutGrupoPerdidas, top);
    }

    return top;


  }

  cargarDatosGrupoPerdidasLineas(xGrupoPerdidas, cGrupoPerdidas, sinFiltroPropio = true) {
    // buscar el maximo para cargar el eje y dinamico (la primera fila y la primera columna son no dinamicas)
    var max = 36000; // el minimo sera 10 horas
    var values = [];
    for (var i = 1; i < this.dataGrupoPerdidasLineas['columns'].length; i++) {
      for (var j = 1; j < this.dataGrupoPerdidasLineas['columns'][i].length; j++) {
        if (this.dataGrupoPerdidasLineas['columns'][i][j] > max) max = this.dataGrupoPerdidasLineas['columns'][i][j];
      }
    }
    for (var i = 1; i <= 10; i++) {
      values.push((max / 10) * i);
    }
    if (this.cantidadGrupoPerdidasLineas > 0 && sinFiltroPropio) {
      this.cargarGrupoPerdidasLineas(this.dataGrupoPerdidasLineas, xGrupoPerdidas, cGrupoPerdidas, max, values);
    }

    if (this.cantidadGrupoPerdidasCantidadLineas > 0 && sinFiltroPropio) {
      this.cargarGrupoPerdidasCantidadLineas(this.dataGrupoPerdidasCantidadLineas, xGrupoPerdidas, cGrupoPerdidas);
    }
  }

  cargarDatosGrupoPerdidasOEE(cGrupoPerdidasOEE, sinFiltroPropio = true) {
    // si no hay datos
    if (cGrupoPerdidasOEE[0][1] == 0 && cGrupoPerdidasOEE[1][1] == 0 && cGrupoPerdidasOEE[2][1] == 0 && cGrupoPerdidasOEE[3][1] == 0) {
      this.labelGrupoPerdidas = true;
    } else {
      this.labelGrupoPerdidas = false;
    }
    this.dataGrupoPerdidasOEE["columns"] = cGrupoPerdidasOEE;

    if (this.cantidadGrupoperdidasOEE > 0 && sinFiltroPropio) {
      this.cargarGrupoPerdidasOEECircular(cGrupoPerdidasOEE);
    }
  }
  //#endregion

  //#region  PROCESAR LOS DATOS PARA LOS MODULOS QUE NECESITAN UN FILTRADO PROPIO

  cargarDatosConFiltroPropio(idMaquinas, idOperarios, tipo) {
    //#region PARAMETROS
    // Alarmas circular
    var data = this.data;
    var alarma: any = [];
    var tPerdidaAlarma: any = [];
    var tAlarmaOriginal: any = [];
    var cAlarma = [];
    var cAlarmasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];
    var gravedadAlarma: any = [];
    var Jgravedad = [];

    // Mantenimientos circular
    var cMantenimientos = [];
    var mantenimiento: any = [];
    var tPerdidaMantenimientos: any = [];
    var tMantenimientosOriginal: any = [];
    var cMantenimientosOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Perdidas circular
    var cPerdidas = [];
    var perdida: any = [];
    var tPerdidaPerdidas: any = [];
    var tPerdidaPerdidasOriginal: any = [];
    var cPerdidasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Subperdidas circular
    var cSubperdidas = [];
    var subperdida: any = [];
    var tPerdidaSubperdidas: any = [];
    var tSubperdidasOriginal: any = [];
    var cSubperdidasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Grupo perdidas circular
    var cGrupoPerdidas = [];
    var grupoperdida: any = [];
    var tPerdidaGrupoPerdidas: any = [];
    var tGrupoPerdidasOriginal: any = [];
    var cGrupoPerdidasOEE: any = [['disponibilidad', 0],
    ['rendimiento', 0],
    ['calidad', 0],
    ['noComputable', 0]];

    // Resumen semana circular
    var tPreparacion = 0;
    var tEjecucionResumenSemana = 0;
    var tMicroparadas = 0;
    var tParada = 0;
    var tMantenimiento = 0;
    var tAlarma = 0;
    var tApagado = 0;

    // Resumen semana subestados circular
    var cResumenSemanaSubestados = [];
    var resumenSemanaSubestados: any = [];
    var resumenSemanaSubestadosColors: any = [];
    var tResumenSemanaSubestados: any = [];

    // OEE circular
    var idHistoricoOperacionesEjecucionArray = [];
    var idHistoricoOperacionesPreparacionArray = [];
    this.tEjecucionOEE = 0;
    this.tParadaDisponibilidad = 0;
    this.tMantenimientoDisponibilidad = 0;
    this.tAlarmaDisponibilidad = 0;
    this.tApagadaDisponibilidad = 0;
    this.tParadaRendimiento = 0;
    this.tMantenimientoRendimiento = 0;
    this.tAlarmaRendimiento = 0;
    this.tApagadaRendimiento = 0;
    this.tParadaCalidad = 0;
    this.tMantenimientoCalidad = 0;
    this.tAlarmaCalidad = 0;
    this.tApagadaCalidad = 0;
    this.tNegativoCalidad = 0;
    this.tEstimado = 0;

    // Alarmas lineas
    var txArrayAlarmas: any = ['x'];
    let columnsArrayAlarmas: any = [txArrayAlarmas];
    var namesArrayAlarmas: any = {};
    var xAlarmas: any = {};
    var columnsArrayCantidadAlarmas: any = [txArrayAlarmas];

    // Mantenimientos lineas
    var txArrayMantenimientos: any = ['x'];
    var columnsArrayMantenimientos: any = [txArrayMantenimientos];
    var namesArrayMantenimientos: any = {};
    var xMantenimientos: any = {};
    var columnsArrayCantidadMantenimientos: any = [txArrayMantenimientos];

    // Perdidas lineas
    var txArrayPerdidas: any = ['x'];
    var columnsArrayPerdidas: any = [txArrayPerdidas]
    var namesArrayPerdidas: any = {};
    var xPerdidas: any = {};
    var columnsArrayCantidadPerdidas: any = [txArrayPerdidas];

    // Subperdidas lineas
    var txArraySubperdidas: any = ['x'];
    var columnsArraySubperdidas: any = [txArraySubperdidas];
    var namesArraySubperdidas: any = {};
    var xSubperdidas: any = {};
    var columnsArrayCantidadSubperdidas: any = [txArraySubperdidas];

    // Grupo perdidas lineas
    var txArrayGrupoPerdidas: any = ['x'];
    var columnsArrayGrupoPerdidas: any = [txArrayGrupoPerdidas]
    var namesArrayGrupoPerdidas: any = {};
    var xGrupoPerdidas: any = {};
    var columnsArrayCantidadGrupoPerdidas: any = [txArrayGrupoPerdidas];

    // Resumen semana lineas
    var txArrayResumenSemana: any = ['x'];
    var tPreparacionArray: any = ['preparacion'];
    var tEjecucionArray: any = ['ejecucion'];
    var tMicroparadasArray: any = ['microparada'];
    var tParadaArray: any = ['parada'];
    var tMantenimientoArray: any = ['mantenimiento'];
    var tAlarmaArray: any = ['alarma'];
    var tApagadoArray: any = ['apagada'];

    // OEE lineas
    var txArrayOEE: any = ['x'];
    var columnsArrayOEE: any = [txArrayOEE, ['disponibilidad'], ['rendimiento'], ['calidad'], ['total']]
    var namesArrayOEE = {
      'disponibilidad': this.translate.instant('disponibilidad'),
      'rendimiento': this.translate.instant('rendimiento'),
      'calidad': this.translate.instant('calidad'),
      'total': this.translate.instant('total')
    };
    var xOEE = {
      'disponibilidad': 'x',
      'rendimiento': 'x',
      'calidad': 'x',
      'total': 'x'
    }
    var idHistoricoOperacionesEjecucion = [];
    var idHistoricoOperacionesPreparacion = [];
    this.tEjecucionArray = [];
    this.tParadaDisponibilidadArray = [];
    this.tMantenimientoDisponibilidadArray = [];
    this.tAlarmaDisponibilidadArray = [];
    this.tApagadaDisponibilidadArray = [];
    this.tParadaRendimientoArray = [];
    this.tMantenimientoRendimientoArray = [];
    this.tAlarmaRendimientoArray = [];
    this.tApagadaRendimientoArray = [];
    this.tParadaCalidadArray = [];
    this.tMantenimientoCalidadArray = [];
    this.tAlarmaCalidadArray = [];
    this.tApagadaCalidadArray = [];
    this.tNegativoCalidadArray = [];
    this.tEstimadoArray = [];
    //#endregion

    var dataFiltrado = [];

    if (idMaquinas[0] != "-1" && idOperarios[0] != "-1")
      dataFiltrado = this.data.filter(f => (idMaquinas.includes(f.idmaquina.toString()) && idOperarios.includes(f.idOperario.toString())));
    else if (idMaquinas[0] != "-1")
      dataFiltrado = this.data.filter(f => idMaquinas.includes(f.idmaquina.toString()));
    else if (idOperarios[0] != "-1")
      dataFiltrado = this.data.filter(f => idOperarios.includes(f.idOperario.toString()));

    dataFiltrado.forEach(d => {
      var diff = this.convertToDate(d);

      // ALARMAS TIPO CIRCULAR
      var index = alarma.indexOf(d['alarma']);
      if (d.idProcesos_Tipo == 6) {
        if (index == -1) {
          alarma.push(d['alarma']);
          xAlarmas[d['alarma']] = 'x';
          if (d["oculto"] == 0) {
            tPerdidaAlarma.push(diff);
          } else {
            tPerdidaAlarma.push(0);
          }
          tAlarmaOriginal.push(diff);
        } else {
          if (d["oculto"] == 0) {
            tPerdidaAlarma[index] += diff;
          }
          tAlarmaOriginal[index] += diff;
        }
      }

      // ALARMAS TIPO OEE
      if (d.idProcesos_Tipo == 6) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cAlarmasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cAlarmasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cAlarmasOEE[2][1] += diff;
          } else { // no computable
            cAlarmasOEE[3][1] += diff;
          }
        }
      }

      // ALARMAS GRAVEDAD CIRCULAR
      var index = gravedadAlarma.indexOf(d['gravedadAlarma']);
      if (d.idProcesos_Tipo == 6) {
        var diff = this.convertToDate(d);
        if (index == -1) {
          gravedadAlarma.push(d['gravedadAlarma']);
          if (d["oculto"] == 0) {
            Jgravedad.push(diff);
          } else {
            Jgravedad.push(0);
          }
        } else {
          if (d["oculto"] == 0) {
            Jgravedad[index] += diff;
          }
        }
      }

      // MANTENIMIENTOS TIPO CIRCULAR
      var index = mantenimiento.indexOf(d['maquinaMantenimiento']);
      if (d.idProcesos_Tipo == 4) {
        if (index == -1) {
          mantenimiento.push(d['maquinaMantenimiento']);
          if (d["oculto"] == 0) {
            tPerdidaMantenimientos.push(diff);
          } else {
            tPerdidaMantenimientos.push(0);
          }
          tMantenimientosOriginal.push(diff);
        } else {
          if (d["oculto"] == 0) {
            tPerdidaMantenimientos[index] += diff;
          }
          tMantenimientosOriginal[index] += diff;
        }
      }

      // MANTENIMIENTOS TIPO OEE
      if (d.idProcesos_Tipo == 4) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cMantenimientosOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cMantenimientosOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cMantenimientosOEE[2][1] += diff;
          } else { // no computable
            cMantenimientosOEE[3][1] += diff;
          }
        }
      }

      // PERDIDAS TIPO CIRCULAR
      var index = perdida.indexOf(d['perdida']);
      if (index == -1) {
        // if ((d['idPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idPerdida'] != -1) {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idPerdida'] != -1) {
          perdida.push(d['perdida']);
          if (d["oculto"] == 0) {
            tPerdidaPerdidas.push(diff);
          } else {
            tPerdidaPerdidas.push(0);
          }
          tPerdidaPerdidasOriginal.push(diff);
        }
      } else {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idPerdida'] != -1) {
          if (d["oculto"] == 0) {
            // if ((d['idPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idPerdida'] != -1) {
            tPerdidaPerdidas[index] += diff;
          }
          tPerdidaPerdidasOriginal[index] += diff;
        }
      }

      // PERDIDAS TIPO OEE
      // if ((d['idPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idPerdida'] != -1) {
      if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idPerdida'] != -1) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cPerdidasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cPerdidasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cPerdidasOEE[2][1] += diff;
          } else { // no computable
            cPerdidasOEE[3][1] += diff;
          }
        }
      }

      // SUBPERDIDAS TIPO CIRCULAR
      var index = subperdida.indexOf(d['subPerdida']);
      if (index == -1) {
        // if ((d['idSubPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idSubPerdida'] != -1) {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idSubPerdida'] != -1) {
          subperdida.push(d['subPerdida']);
          if (d["oculto"] == 0) {
            tPerdidaSubperdidas.push(diff);
          } else {
            tPerdidaSubperdidas.push(0);
          }
          tSubperdidasOriginal.push(diff);
        }
      } else {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idSubPerdida'] != -1) {
          if (d["oculto"] == 0) {
            // if ((d['idSubPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idSubPerdida'] != -1) {
            tPerdidaSubperdidas[index] += diff;
          }
          tSubperdidasOriginal[index] += diff;
        }
      }

      // SUBPERDIDAS POR TIPO OEE
      // if ((d['idSubPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idSubPerdida'] != -1) {
      if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idSubPerdida'] != -1) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cSubperdidasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cSubperdidasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cSubperdidasOEE[2][1] += diff;
          } else { // no computable
            cSubperdidasOEE[3][1] += diff;
          }
        }
      }

      // GRUPO PERDIDAS TIPO CIRCULAR
      var index = grupoperdida.indexOf(d['grupoPerdida']);
      if (index == -1) {
        // if ((d['idGrupoPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idGrupoPerdida'] != -1) {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idGrupoPerdida'] != -1) {
          grupoperdida.push(d['grupoPerdida']);
          if (d["oculto"] == 0) {
            tPerdidaGrupoPerdidas.push(diff);
          } else {
            tPerdidaGrupoPerdidas.push(0);
          }
          tGrupoPerdidasOriginal.push(diff);
        }
      } else {
        if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idGrupoPerdida'] != -1) {
          if (d["oculto"] == 0) {
            // if ((d['idGrupoPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idGrupoPerdida'] != -1) {
            tPerdidaGrupoPerdidas[index] += diff;
          }
          tGrupoPerdidasOriginal[index] += diff;
        }
      }

      // GRUPO PERDIDAS TIPO OEE
      // if ((d['idGrupoPerdida'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3)) || d['idGrupoPerdida'] != -1) {
      if ((d['idPerdidaHistoricoBase'] == -1 && !(d['microparada'] == 1 || d['idProcesos_Tipo'] == 1 || d['idProcesos_Tipo'] == 3|| d['idProcesos_Tipo'] == 4)) || d['idGrupoPerdida'] != -1) {
        if (d["oculto"] == 0) {
          if (d['idTipoPerdidaOEE'] == 1) { // disponibilidad
            cGrupoPerdidasOEE[0][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 2) { // rendimiento
            cGrupoPerdidasOEE[1][1] += diff;
          } else if (d['idTipoPerdidaOEE'] == 3) { // calidad
            cGrupoPerdidasOEE[2][1] += diff;
          } else { // no computable
            cGrupoPerdidasOEE[3][1] += diff;
          }
        }
      }

      // RESUMEN SEMANA TIPO CIRCULAR
      if (d["oculto"] == 0) {
        if (d.idProcesos_Tipo == 1) {
          tEjecucionResumenSemana += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 0) {
          tParada += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 1) {
          tMicroparadas += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 3) {
          tPreparacion += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4) {
          tMantenimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 || d.idProcesos_Tipo == 7) {
          tAlarma += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8) {
          tApagado += this.convertToDate(d);
        }
      }

      // RESUMEN SEMANA SUBESTADOS TIPO CIRCULAR
      if (d.idProcesos_Tipo_subEstados > 99) {
        var index = resumenSemanaSubestados.indexOf(d['nombreSubestado']);
        if (index == -1) {
          resumenSemanaSubestados.push(d['nombreSubestado']);
          resumenSemanaSubestadosColors.push(d['colorSubestado']);
          if (d['oculto'] == 0)
            tResumenSemanaSubestados.push(diff);
          else
            tResumenSemanaSubestados.push(0);
        } else {
          if (d['oculto'] == 0) {
            tResumenSemanaSubestados[index] += diff;
          }
        }
      }

      // OEE CIRCULAR
      if (d["oculto"] == 0) {
        var tEjecucion = 0;

        if ((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 1) {
          this.tParadaDisponibilidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 1) {
          this.tMantenimientoDisponibilidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 1) {
          this.tAlarmaDisponibilidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 1) {
          this.tApagadaDisponibilidad += this.convertToDate(d);
        }

        // si es rendimiento y tiene operacion entonces tiene que ir a ejecucion
        else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tParadaRendimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tMantenimientoRendimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tAlarmaRendimiento += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tApagadaRendimiento += this.convertToDate(d);
        }

        else if ((d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tNegativoCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tParadaCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tMantenimientoCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tAlarmaCalidad += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tApagadaCalidad += this.convertToDate(d);
        } else {
          this.tEjecucionOEE += this.convertToDate(d);
          tEjecucion = d.tiemporeal;
        }

        if (tEjecucion > 0) {
          // si la pieza tiene algo de preparacion (que no sea ni dispo ni calidad):
          //  - t estimado total = t esti ejecucion + t esti preparacion
          //  - t total: t total rendiminieto op + t total rendimiento prep 
          // si no:
          //  - t est: t esti ejecucion
          //  - t total: t total rendminieto op
          var tEstimadoTotal = 0;
          var tTotal = 0;
          var tEstEjec = 0;
          if (d.usaLotes)
            tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
          else
            tEstEjec = d.tiempoEstimadoEjecucion;

          if (d.tiempoTotalRendimientoOperacion == -1 || d.tiempoEstimadoEjecucion == -1) { // no tiene operacion
            // tEstimadoTotal = tEjecucion +  d.tiempoTotalRendimientoPreparacion;
            tEstimadoTotal = 0;
            tTotal = -1;
          } else if (d.tiempoTotalRendimientoPreparacion != 0) { // tiene preparacion
            tEstimadoTotal = tEstEjec + d.tiempoEstimadoPreparacion;
            // tTotal = d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion;
            tTotal = d.tiempoTotal;
          } else {
            tEstimadoTotal = tEstEjec;
            // tTotal = d.tiempoTotalRendimientoOperacion;
            tTotal = d.tiempoTotal;
          }

          // if (d.idEstado == 5 || d.idEstado == 4)
          //   var tEstLag = 0;
          // if (d.ejecucionSinOperacionRepercuteA == 0 && tTotal == -1)
          //   var tEstLag = 0;
          if (d.ejecucionSinOperacionRepercuteA != 0 && tTotal <= 0)
            var tEstLag = (tEjecucion + d.tiempoTotalRendimientoPreparacion) * (d.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
          else
            var tEstLag = tEstimadoTotal * d.tiemporeal / tTotal;

          if (d.idEstado == 5 || d.idEstado == 4)
            this.tNegativoCalidad += Math.min(d.tiemporeal, tEstLag);

          // tiempo estimado parcial
          // despues de tener el tiempo estimado total, y el tiempo total operacion,
          // limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)
          if (d.tiemporeal < tEstLag)
            this.tEstimado += d.tiemporeal;
          else
            this.tEstimado += tEstLag;
        }

        // this.tNegativoCalidad += d['tNegativoCalidad'];
      }
    });

    if (tipo.includes("ResumenSemana"))
      this.cargarDatosResumenSemana(tPreparacion, tEjecucionResumenSemana, tMicroparadas, tParada, tMantenimiento, tAlarma, tApagado, false);
    else if (tipo.includes("Subestado"))
      cResumenSemanaSubestados = this.cargarDatosResumenSemanaSubestados(resumenSemanaSubestados, tResumenSemanaSubestados, resumenSemanaSubestadosColors, false);
    else if (tipo.includes("Alarmas"))
      cAlarma = this.cargarDatosAlarma(alarma, tPerdidaAlarma, tAlarmaOriginal, false);
    else if (tipo.includes("Mantenimientos"))
      cMantenimientos = this.cargarDatosMantenimientos(mantenimiento, tPerdidaMantenimientos, tMantenimientosOriginal, false);
    else if (tipo.includes("GrupoPerdidas"))
      cGrupoPerdidas = this.cargarDatosGrupoPerdidas(grupoperdida, tPerdidaGrupoPerdidas, tGrupoPerdidasOriginal, false);
    else if (tipo.includes("Subperdidas"))
      cSubperdidas = this.cargarDatosSubperdidas(subperdida, tPerdidaSubperdidas, tSubperdidasOriginal, false);
    else if (tipo.includes("Perdidas"))
      cPerdidas = this.cargarDatosPerdidas(perdida, tPerdidaPerdidas, tPerdidaPerdidasOriginal, false);

    //#region  Calcular datos para graficos lineas y graficos por tipo OEE
    for (var j = 0; j < alarma.length; j++) {
      columnsArrayAlarmas.push([alarma[j]]);
      columnsArrayCantidadAlarmas.push([alarma[j]]);
      namesArrayAlarmas[alarma[j]] = this.cortarLeyenda(alarma[j]);
      xAlarmas[alarma[j]] = 'x';
    }
    var nAlarmasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cAlarmasOEE[3][1]) + ")"
    };
    for (j = 0; j < cMantenimientos.length; j++) {
      columnsArrayMantenimientos.push([cMantenimientos[j][0]]);
      columnsArrayCantidadMantenimientos.push([cMantenimientos[j][0]]);
      namesArrayMantenimientos[cMantenimientos[j][0]] = this.cortarLeyenda(cMantenimientos[j][0]);
      xMantenimientos[cMantenimientos[j][0]] = 'x';
    }
    var nMantenimientosOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cMantenimientosOEE[3][1]) + ")"
    };
    for (j = 0; j < cPerdidas.length; j++) {
      columnsArrayPerdidas.push([cPerdidas[j][0]]);
      columnsArrayCantidadPerdidas.push([cPerdidas[j][0]]);
      namesArrayPerdidas[cPerdidas[j][0]] = this.cortarLeyenda(cPerdidas[j][0]);
      xPerdidas[cPerdidas[j][0]] = 'x';
    }
    var nPerdidasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cPerdidasOEE[3][1]) + ")"
    };
    for (j = 0; j < cSubperdidas.length; j++) {
      columnsArraySubperdidas.push([cSubperdidas[j][0]]);
      columnsArrayCantidadSubperdidas.push([cSubperdidas[j][0]]);
      namesArraySubperdidas[cSubperdidas[j][0]] = this.cortarLeyenda(cSubperdidas[j][0]);
      xSubperdidas[cSubperdidas[j][0]] = 'x';
    }
    var nSubperdidasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cSubperdidasOEE[3][1]) + ")"
    };
    for (j = 0; j < cGrupoPerdidas.length; j++) {
      columnsArrayGrupoPerdidas.push([cGrupoPerdidas[j][0]]);
      columnsArrayCantidadGrupoPerdidas.push([cGrupoPerdidas[j][0]]);
      namesArrayGrupoPerdidas[cGrupoPerdidas[j][0]] = this.cortarLeyenda(cGrupoPerdidas[j][0]);
      xGrupoPerdidas[cGrupoPerdidas[j][0]] = 'x';
    }
    var nGrupoPerdidasOEE = {
      disponibilidad: this.translate.instant("disponibilidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[0][1]) + ")",
      rendimiento: this.translate.instant("rendimiento") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[1][1]) + ")",
      calidad: this.translate.instant("calidad") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[2][1]) + ")",
      noComputable: this.translate.instant("noComputable") + " (" + this.myFunctions.secondsTo_HH_MM_SS(cGrupoPerdidasOEE[3][1]) + ")"
    };
    //#endregion

    // CARGAR DATOS PARA GRAFICOS DE LINEAS
    this.tipoAgrupado = this.agruparFechas(data); // de esta manera sabemos si hay que agrupar por día por semana o por año. 0=>dia, 1=>semana, 2=>año
    dataFiltrado.forEach(d => {

      var date = new Date(d['fechaTurno']);
      var month = date.getUTCMonth() + 1;
      var dayAux = date.getUTCDate() + 1;
      var day = date.getUTCFullYear() + "-" + month + "-" + dayAux;
      var diff = this.convertToDate(d);

      // ALARMAS LINEAS
      if (d['idProcesos_Tipo'] == 6) {
        if (this.tipoAgrupado == 0) var index = txArrayAlarmas.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayAlarmas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayAlarmas.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayAlarmas.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayAlarmas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayAlarmas.push(this.startOfMonth(date));
          for (var i = 1; i < columnsArrayAlarmas.length; i++) {
            if (columnsArrayAlarmas[i][0] == d['alarma'] && d["oculto"] == 0) {
              columnsArrayAlarmas[i].push(diff);
              columnsArrayCantidadAlarmas[i].push(1);
            } else {
              columnsArrayAlarmas[i].push(0);
              columnsArrayCantidadAlarmas[i].push(0);
            }
          }
        } else {
          for (var i = 1; i < columnsArrayAlarmas.length; i++) {
            if (columnsArrayAlarmas[i][0] == d['alarma']) {
              if (d["oculto"] == 0) {
                columnsArrayAlarmas[i][index] += diff;
                columnsArrayCantidadAlarmas[i][index] += 1;
              }
            }
          }
        }
      }

      // MANTENIMIENTOS LINEAS
      if (d['idProcesos_Tipo'] == 4) {
        if (this.tipoAgrupado == 0) var index = txArrayMantenimientos.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayMantenimientos.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayMantenimientos.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayMantenimientos.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayMantenimientos.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayMantenimientos.push(this.startOfMonth(date));
          for (var i = 1; i < columnsArrayMantenimientos.length; i++) {
            if (columnsArrayMantenimientos[i][0] == d['maquinaMantenimiento']) {
              if (d["oculto"] == 0) {
                columnsArrayMantenimientos[i].push(diff);
                columnsArrayCantidadMantenimientos[i].push(1);
              } else {
                columnsArrayMantenimientos[i].push(0);
                columnsArrayCantidadMantenimientos[i].push(0);
              }
            } else {
              columnsArrayMantenimientos[i].push(0);
              columnsArrayCantidadMantenimientos[i].push(0);
            }
          }
        } else {
          for (var i = 1; i < columnsArrayMantenimientos.length; i++) {
            if (columnsArrayMantenimientos[i][0] == d['maquinaMantenimiento']) {
              if (d["oculto"] == 0) {
                columnsArrayMantenimientos[i][index] += diff;
                columnsArrayCantidadMantenimientos[i][index] += 1;
              }
            }
          }
        }
      }

      // PERDIDAS LINEAS
      if (this.tipoAgrupado == 0) var index = txArrayPerdidas.indexOf(day.toString());
      else if (this.tipoAgrupado == 1) var index = txArrayPerdidas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
      else var index = txArrayPerdidas.indexOf(this.startOfMonth(date));
      if (index == -1) {
        if (this.tipoAgrupado == 0) txArrayPerdidas.push(day.toString());
        else if (this.tipoAgrupado == 1) txArrayPerdidas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else txArrayPerdidas.push(this.startOfMonth(date));
        for (var i = 1; i < columnsArrayPerdidas.length; i++) {
          if (((columnsArrayPerdidas[i][0] == d['perdida'])) && d["oculto"] == 0) {
            columnsArrayPerdidas[i].push(diff);
            columnsArrayCantidadPerdidas[i].push(1);
          } else {
            columnsArrayPerdidas[i].push(0);
            columnsArrayCantidadPerdidas[i].push(0);
          }
        }
      } else {
        for (var i = 1; i < columnsArrayPerdidas.length; i++) {
          if ((columnsArrayPerdidas[i][0] == d['perdida'])) {
            if (d["oculto"] == 0) {
              columnsArrayPerdidas[i][index] += diff;
              columnsArrayCantidadPerdidas[i][index] += 1;
            }
          }
        }
      }

      // SUBPERDIDAS LINEAS
      if (this.tipoAgrupado == 0) var index = txArraySubperdidas.indexOf(day.toString());
      else if (this.tipoAgrupado == 1) var index = txArraySubperdidas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
      else var index = txArraySubperdidas.indexOf(this.startOfMonth(date));
      if (index == -1) {
        if (this.tipoAgrupado == 0) txArraySubperdidas.push(day.toString());
        else if (this.tipoAgrupado == 1) txArraySubperdidas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else txArraySubperdidas.push(this.startOfMonth(date));
        for (var i = 1; i < columnsArraySubperdidas.length; i++) {
          if (columnsArraySubperdidas[i][0] == d['subPerdida'] && d["oculto"] == 0) {
            columnsArraySubperdidas[i].push(diff);
            columnsArrayCantidadSubperdidas[i].push(1);
          } else {
            columnsArraySubperdidas[i].push(0);
            columnsArrayCantidadSubperdidas[i].push(0);
          }
        }
      } else {
        for (var i = 1; i < columnsArraySubperdidas.length; i++) {
          if (columnsArraySubperdidas[i][0] == d['subPerdida'] && d["oculto"] == 0) {
            columnsArraySubperdidas[i][index] += diff;
            columnsArrayCantidadSubperdidas[i][index] += 1;
          }
        }
      }

      // GRUPO PERDIDAS LINEAS
      if (this.tipoAgrupado == 0) var index = txArrayGrupoPerdidas.indexOf(day.toString());
      else if (this.tipoAgrupado == 1) var index = txArrayGrupoPerdidas.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
      else var index = txArrayGrupoPerdidas.indexOf(this.startOfMonth(date));
      if (index == -1) {
        if (this.tipoAgrupado == 0) txArrayGrupoPerdidas.push(day.toString());
        else if (this.tipoAgrupado == 1) txArrayGrupoPerdidas.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else txArrayGrupoPerdidas.push(this.startOfMonth(date));
        for (var i = 1; i < columnsArrayGrupoPerdidas.length; i++) {
          if (columnsArrayGrupoPerdidas[i][0] == d['grupoPerdida'] && d["oculto"] == 0) {
            columnsArrayGrupoPerdidas[i].push(diff);
            columnsArrayCantidadGrupoPerdidas[i].push(1)
          } else {
            columnsArrayGrupoPerdidas[i].push(0);
            columnsArrayCantidadGrupoPerdidas[i].push(0)
          }
        }
      } else {
        for (var i = 1; i < columnsArrayGrupoPerdidas.length; i++) {
          if (columnsArrayGrupoPerdidas[i][0] == d['grupoPerdida'] && d["oculto"] == 0) {
            columnsArrayGrupoPerdidas[i][index] += diff;
            columnsArrayCantidadGrupoPerdidas[i][index] += 1;
          }
        }
      }

      // RESUMEN SEMANA LINEAS
      if (d["oculto"] == 0) {
        if (this.tipoAgrupado == 0) var index = txArrayResumenSemana.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayResumenSemana.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayResumenSemana.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayResumenSemana.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayResumenSemana.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayResumenSemana.push(this.startOfMonth(date));
          tEjecucionArray.push(0);
          tParadaArray.push(0);
          tMicroparadasArray.push(0);
          tPreparacionArray.push(0);
          tMantenimientoArray.push(0);
          tAlarmaArray.push(0);
          tApagadoArray.push(0);
          index = txArrayResumenSemana.length - 1
        }

        if (d.idProcesos_Tipo == 1) {
          tEjecucionArray[index] += diff;
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 0) {
          tParadaArray[index] += diff;
        } else if (d.idProcesos_Tipo == 2 && d['microparada'] == 1) {
          tMicroparadasArray[index] += diff;
        } else if (d.idProcesos_Tipo == 3) {
          tPreparacionArray[index] += diff;
        } else if (d.idProcesos_Tipo == 4 && d['idmaquinaMantenimiento'] != -1) {
          tMantenimientoArray[index] += diff;
        } else if (d.idProcesos_Tipo == 6 || d.idProcesos_Tipo == 7) {
          tAlarmaArray[index] += diff;
        } else if (d.idProcesos_Tipo == 8) {
          tApagadoArray[index] += diff;
        }
      }

      // OEE LINEAS
      if (d["oculto"] == 0) {
        if (this.tipoAgrupado == 0) var index = txArrayOEE.indexOf(day.toString());
        else if (this.tipoAgrupado == 1) var index = txArrayOEE.indexOf(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
        else var index = txArrayOEE.indexOf(this.startOfMonth(date));
        if (index == -1) {
          if (this.tipoAgrupado == 0) txArrayOEE.push(day.toString());
          else if (this.tipoAgrupado == 1) txArrayOEE.push(this.myFunctions.dateToYYYY_MM_DD_guion(this.startOfWeek(date)));
          else txArrayOEE.push(this.startOfMonth(date));

          this.tEjecucionArray.push(0);
          this.tParadaDisponibilidadArray.push(0);
          this.tMantenimientoDisponibilidadArray.push(0);
          this.tAlarmaDisponibilidadArray.push(0);
          this.tApagadaDisponibilidadArray.push(0);
          this.tParadaRendimientoArray.push(0);
          this.tMantenimientoRendimientoArray.push(0);
          this.tAlarmaRendimientoArray.push(0);
          this.tApagadaRendimientoArray.push(0);
          this.tParadaCalidadArray.push(0);
          this.tMantenimientoCalidadArray.push(0);
          this.tAlarmaCalidadArray.push(0);
          this.tApagadaCalidadArray.push(0);
          this.tNegativoCalidadArray.push(0);
          this.tEstimadoArray.push(0);

          index = this.tEjecucionArray.length - 1;

        } else {
          index -= 1;
        }

        var tEjecucion = 0;

        if ((d.idProcesos_Tipo == 2 || d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 1) {
          this.tParadaDisponibilidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 1) {
          this.tMantenimientoDisponibilidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 1) {
          this.tAlarmaDisponibilidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 1) {
          this.tApagadaDisponibilidadArray[index] += this.convertToDate(d);
        }

        // si es rendimiento y tiene operacion entonces tiene que ir a ejecucion
        else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tParadaRendimientoArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tMantenimientoRendimientoArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tAlarmaRendimientoArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 2 && d.operacion == 0) {
          this.tApagadaRendimientoArray[index] += this.convertToDate(d);
        }

        else if ((d.idProcesos_Tipo == 1 || d.idProcesos_Tipo == 3) && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tNegativoCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 2 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tParadaCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 4 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tMantenimientoCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 6 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tAlarmaCalidadArray[index] += this.convertToDate(d);
        } else if (d.idProcesos_Tipo == 8 && d['idTipoPerdidaOEE'] == 3 && d.operacion == 0) {
          this.tApagadaCalidadArray[index] += this.convertToDate(d);
        } else {
          this.tEjecucionArray[index] += this.convertToDate(d);
          tEjecucion = d.tiemporeal;
        }

        if (tEjecucion > 0) {
          // si la pieza tiene algo de preparacion (que no sea ni dispo ni calidad):
          //  - t estimado total = t esti ejecucion + t esti preparacion
          //  - t total: t total rendiminieto op + t total rendimiento prep 
          // si no:
          //  - t est: t esti ejecucion
          //  - t total: t total rendminieto op
          var tEstimadoTotal = 0;
          var tTotal = 0;
          var tEstEjec = 0;
          if (d.usaLotes)
            tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
          else
            tEstEjec = d.tiempoEstimadoEjecucion;

          if (d.tiempoTotalRendimientoOperacion == -1 || d.tiempoEstimadoEjecucion == -1) { // no tiene operacion
            // tEstimadoTotal = tEjecucion +  d.tiempoTotalRendimientoPreparacion;
            tEstimadoTotal = 0;
            tTotal = -1;
          } else if (d.tiempoTotalRendimientoPreparacion != 0) { // tiene preparacion
            tEstimadoTotal = tEstEjec + d.tiempoEstimadoPreparacion;
            // tTotal = d.tiempoTotalRendimientoOperacion + d.tiempoTotalRendimientoPreparacion;
            tTotal = d.tiempoTotal;
          } else {
            tEstimadoTotal = tEstEjec;
            // tTotal = d.tiempoTotalRendimientoOperacion;
            tTotal = d.tiempoTotal;
          }

          // if (d.idEstado == 5 || d.idEstado == 4)
          //   var tEstLag = 0;
          // if (d.ejecucionSinOperacionRepercuteA == 0 && tTotal == -1)
          //   var tEstLag = 0;
          if (d.ejecucionSinOperacionRepercuteA != 0 && tTotal <= 0)
            var tEstLag = (tEjecucion + d.tiempoTotalRendimientoPreparacion) * (d.porcentajeEjecucionSinOperacionRepercuteOEE / 100);
          else
            var tEstLag = tEstimadoTotal * d.tiemporeal / tTotal;

          if (d.idEstado == 5 || d.idEstado == 4)
            this.tNegativoCalidadArray[index] += Math.min(d.tiemporeal, tEstLag);

          // tiempo estimado parcial
          // despues de tener el tiempo estimado total, y el tiempo total operacion,
          // limitamos el tiempo estimado parcial segun el tiempo real de la linea (proceso)
          if (d.tiemporeal < tEstLag)
            this.tEstimadoArray[index] += d.tiemporeal;
          else
            this.tEstimadoArray[index] += tEstLag;
        }

        // this.tNegativoCalidad += d['tNegativoCalidad'];
      }

    });

    switch (tipo) {
      case "AlarmasLineas":
      case "AlarmasLineasCantidad":
        this.dataAlarmasLineas['columns'] = columnsArrayAlarmas;
        this.dataAlarmasLineas['names'] = namesArrayAlarmas;
        this.dataAlarmasCantidadLineas['columns'] = columnsArrayCantidadAlarmas;
        this.dataAlarmasCantidadLineas['names'] = namesArrayAlarmas;
        this.cargarDatosAlarmasLineas(xAlarmas, cAlarma, false);
        break;
      case "AlarmasOEE":
        this.dataAlarmasOEE['columns'] = cAlarmasOEE;
        this.dataAlarmasOEE['names'] = nAlarmasOEE;
        this.cargarDatosAlarmaOEE(cAlarmasOEE, false);
        return;
      case "AlarmasDoble":
        this.dataAlarmasOEE['columns'] = cAlarmasOEE;
        this.dataAlarmasOEE['names'] = nAlarmasOEE;
        this.cargarDatosAlarmaOEE(cAlarmasOEE, false);
        this.cargarDatosGravedadAlarma(gravedadAlarma, Jgravedad, cAlarma, false);
        break;
      case "MantenimientosLineas":
      case "MantenimientosLineasCantidad":
        this.dataMantenimientosLineas['columns'] = columnsArrayMantenimientos;
        this.dataMantenimientosLineas['names'] = namesArrayMantenimientos;
        this.dataMantenimientosCantidadLineas['columns'] = columnsArrayCantidadMantenimientos;
        this.dataMantenimientosCantidadLineas['names'] = namesArrayMantenimientos;
        this.cargarDatosMantenimientosLineas(xMantenimientos, cMantenimientos, false);
        break;
      case "MantenimientosOEE":
        this.dataMantenimientoOEE['columns'] = cMantenimientos;
        this.dataMantenimientoOEE['names'] = nMantenimientosOEE;
        this.cargarDatosMantenimientoOEE(cMantenimientosOEE, false);
        break;
      case "PerdidasLineas":
      case "PerdidasLineasCantidad":
        this.dataPerdidasLineas['columns'] = columnsArrayPerdidas;
        this.dataPerdidasLineas['names'] = namesArrayPerdidas;
        this.dataPerdidasCantidadLineas['columns'] = columnsArrayCantidadPerdidas;
        this.dataPerdidasCantidadLineas['names'] = namesArrayPerdidas;
        this.cargarDatosPerdidasLineas(xPerdidas, cPerdidas, false);
        break;
      case "PerdidasOEE":
        this.dataPerdidasOEE['columns'] = cPerdidasOEE;
        this.dataPerdidasOEE['names'] = nPerdidasOEE;
        this.cargarDatosPerdidasOEE(cPerdidasOEE, false);
        break;
      case "SubperdidasLineas":
      case "SubperdidasLineasCantidad":
        this.dataSubperdidasLineas['columns'] = columnsArraySubperdidas;
        this.dataSubperdidasLineas['names'] = namesArraySubperdidas;
        this.dataSubperdidasCantidadLineas['columns'] = columnsArrayCantidadSubperdidas;
        this.dataSubperdidasCantidadLineas['names'] = namesArraySubperdidas;
        this.cargarDatosSubperdidasLineas(xSubperdidas, cSubperdidas, false);
        break;
      case "SubperdidasOEE":
        this.dataSubperdidasOEE['columns'] = cSubperdidasOEE;
        this.dataSubperdidasOEE['names'] = nSubperdidasOEE;
        this.cargarDatosSubperdidasOEE(cSubperdidasOEE, false);
        break;
      case "GrupoPerdidasLineas":
      case "GrupoPerdidasLineasCantidad":
        this.dataGrupoPerdidasLineas['columns'] = columnsArrayGrupoPerdidas;
        this.dataGrupoPerdidasLineas['names'] = namesArrayGrupoPerdidas;
        this.dataGrupoPerdidasCantidadLineas['columns'] = columnsArrayCantidadGrupoPerdidas;
        this.dataGrupoPerdidasCantidadLineas['names'] = namesArrayGrupoPerdidas;
        this.cargarDatosGrupoPerdidasLineas(xGrupoPerdidas, cGrupoPerdidas, false);
        break;
      case "GrupoPerdidasOEE":
        this.dataGrupoPerdidasOEE['columns'] = cGrupoPerdidasOEE;
        this.dataGrupoPerdidasOEE['names'] = nGrupoPerdidasOEE;
        this.cargarDatosGrupoPerdidasOEE(cGrupoPerdidasOEE, false);
        break;
      case "ResumenSemanaLineas":
        this.dataResumenSemanaLineas = {
          columns: [
            txArrayResumenSemana,
            tPreparacionArray,
            tEjecucionArray,
            tMicroparadasArray,
            tParadaArray,
            tMantenimientoArray,
            tAlarmaArray,
            tApagadoArray
          ],
          names: {
            preparacion: this.translate.instant("preparacion"),
            ejecucion: this.translate.instant("ejecucion"),
            microparada: this.translate.instant("microparada"),
            parada: this.translate.instant("parada"),
            mantenimiento: this.translate.instant("mantenimiento"),
            alarma: this.translate.instant("alarma"),
            apagada: this.translate.instant("apagada"),
          }
        }
        this.cargarDatosResumenSemanaLineas(false);
        break;
      case "OEECircular":
      case "OEEBarras":
        this.cargarDatosOEE(false);
        break;
      case "OEE1Circular":
        this.cargarDatosOEE1(false);
        break;
      case "OEELineas":
      case "OEE1Lineas":
        this.cargarDatosOEELineas(columnsArrayOEE, namesArrayOEE, xOEE, false);
        this.cargarDatosOEE1Lineas(columnsArrayOEE, false);
        break;

      default:
        break;
    }

  }

  cargarDatosTablasConFiltroPropio(idMaquinas, idOperarios) {

    var dataFiltrado = [];

    if (idMaquinas[0] != "-1" && idOperarios[0] != "-1")
      dataFiltrado = this.data.filter(f => (idMaquinas.includes(f.idmaquina.toString()) && idOperarios.includes(f.idOperario.toString())));
    else if (idMaquinas[0] != "-1")
      dataFiltrado = this.data.filter(f => idMaquinas.includes(f.idmaquina.toString()));
    else if (idOperarios[0] != "-1")
      dataFiltrado = this.data.filter(f => idOperarios.includes(f.idOperario.toString()));

    var jsonTabla: any = [] // Datos para la tabla actual
    var i = 0;
    dataFiltrado.forEach(d => {

      //#region tEstimadoGlobal
      var tEstimadoGlobal = 0;
      var tEstEjec = 0;
      if (d.usaLotes)
        tEstEjec = d.tiempoEstimadoEjecucion * d.cantidad;
      else
        tEstEjec = d.tiempoEstimadoEjecucion;

      if (d.tiempoTotalRendimientoPreparacion == 0)
        tEstimadoGlobal = tEstEjec;
      else
        tEstimadoGlobal = tEstEjec + d.tiempoEstimadoPreparacion;
      //#endregion

      jsonTabla.push({});
      var len = jsonTabla.length - 1;
      jsonTabla[len]['id'] = i;
      jsonTabla[len]['idHistoricoOperaciones'] = d['idHistoricoOperaciones'];
      jsonTabla[len]['numeroOF'] = d['numeroOF'];
      jsonTabla[len]['cliente'] = d['cliente'];
      jsonTabla[len]['nombrePieza'] = d['nombrePieza'];
      jsonTabla[len]['nombreOperacion'] = d['nombreOperacion'];
      jsonTabla[len]['oculto'] = d['oculto'];
      // procesar los tiempos por cada idHistoricoOperacion
      if (d['tiempoTotalRendimientoOperacion'] > 0)
        jsonTabla[len]['tiempoTotalRendimientoOperacion'] = d['tiempoTotalRendimientoOperacion'] - tEstEjec;
      else
        jsonTabla[len]['tiempoTotalRendimientoOperacion'] = d['tiempoTotalRendimientoOperacion'];
      if (d.tiempoTotalRendimientoPreparacion > 0)
        jsonTabla[len]['tiempoTotalRendimientoPreparacion'] = d['tiempoTotalRendimientoPreparacion'] - d['tiempoEstimadoPreparacion'];
      else
        jsonTabla[len]['tiempoTotalRendimientoPreparacion'] = d['tiempoTotalRendimientoPreparacion']

      if (d['tiempoTotalOperacion'] > 0)
        jsonTabla[len]['tiempoTotalDesvioOperacion'] = d['tiempoTotalOperacion'] - tEstEjec;
      else
        jsonTabla[len]['tiempoTotalDesvioOperacion'] = d['tiempoTotalOperacion'];
      if (d['tiempoTotalPreparacion'] > 0)
        jsonTabla[len]['tiempoTotalDesvioPreparacion'] = d['tiempoTotalPreparacion'] - d['tiempoEstimadoPreparacion'];
      else
        jsonTabla[len]['tiempoTotalDesvioPreparacion'] = d['tiempoTotalPreparacion']
      // jsonTabla[len]['tiempoEstimadoEjecucion'] = d['tiempoEstimadoEjecucion'];
      jsonTabla[len]['tiempoEstimadoEjecucion'] = tEstimadoGlobal;
      // tiempos totales
      jsonTabla[len]['tiempoTotalRendimientoTotal'] = jsonTabla[len]['tiempoTotalRendimientoOperacion'] + jsonTabla[len]['tiempoTotalRendimientoPreparacion'];
      jsonTabla[len]['tiempoTotalDesvioTotal'] = jsonTabla[len]['tiempoTotalDesvioOperacion'] + jsonTabla[len]['tiempoTotalDesvioPreparacion'];
      jsonTabla[len]['tiemporeal'] = d['tiemporeal'];
      // perdidas
      jsonTabla[len]['perdida'] = d['perdida'];
      jsonTabla[len]['subPerdida'] = d['subPerdida'];
      jsonTabla[len]['grupoPerdida'] = d['grupoPerdida'];
      // mantenimientos
      jsonTabla[len]['maquinaMantenimiento'] = d['maquinaMantenimiento'];
      // alarmas
      jsonTabla[len]['alarma'] = d['alarma'];
      jsonTabla[len]['gravedadAlarma'] = d['gravedadAlarma'];
      // maquinas
      jsonTabla[len]['maquina'] = d['maquina'];
      jsonTabla[len]['maquinaGrupo'] = d['maquinaGrupo'];
      jsonTabla[len]['seccion'] = d['seccion'];
      jsonTabla[len]['areaProductiva'] = d['areaProductiva'];

      i++;
    });

    // CARGAR TABLAS OPERACIONES
    this.jsonTablaOperaciones = this.myFunctions.copy(jsonTabla);
  }

  //#endregion

  //#endregion

  //#region  GRAFICOS: CIRCULARES, BARRAS Y LINEAS
  cargarOEECircular(c) {
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEECircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataChartDonutOEE.columns;
          }

          if (!this.vinculacion || (this.vinculacion && !this.oeeExcluidos[i])) {
            this.dataChartDonutOEE[i] = [['completo', 0], ['nocompleto', 100]];
            this.chartDonutOEE[i] = c3.generate({
              bindto: "#chartDonutOEE_InformeRendimiento_" + i,
              data: {
                columns: c,
                type: 'donut',
                names: {
                  oee: this.translate.instant("oee"),
                },
                order: 'null'
              },
              donut: {
                title: "0",
                width: 15,
                label: { show: false }
              },
              color: {
                pattern: [this.colorOEEActivo, this.colorOEENoActivo],
              },
              legend: {
                show: false
              },
              tooltip: {
                show: false
              },
              transition: {
                duration: this.tiempoTransition
              }
            });
            d3.select('#chartDonutOEE_InformeRendimiento_' + i + ' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
              .text(Math.round(c[0][1]) + "%");
          }
          i++;
        }
      });
    });
  }

  cargarDisponibilidadCircular(c) {
    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEECircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataChartDonutDisponibilidad.columns;
          }
          if (!this.vinculacion || (this.vinculacion && !this.oeeExcluidos[i])) {
            this.chartDonutOEEDisponibilidad[i] = c3.generate({
              bindto: '#chartDonutOEEDisponibilidad_InformeRendimiento_' + i,
              data: {
                columns: c,
                type: 'donut',
                names: {
                  disponibilidad: this.translate.instant("disponibilidad"),
                },
                order: 'null',
                onclick: function (d) {
                  for (var i = 0; i < c.length; i++) {
                    if (c[i][0] == d.id && that.disponibilidadNoSeleccionadas.indexOf(c[i][0]) == -1) {
                      that.disponibilidadNoSeleccionadas.push(c[i][0]);

                      for (var j = 0; j < Object.keys(that.data).length; i++) {

                      }
                      break;
                    }
                  }
                  that.cargarDatosInforme();
                  that.updateLeyenda();
                }
              },
              donut: {
                title: "0",
                width: 15,
                label: { show: false }
              },
              color: {
                pattern: [this.colorOEEActivo, this.colorOEENoActivo],
              },
              legend: {
                show: false
              },
              tooltip: {
                show: false
              },
              transition: {
                duration: this.tiempoTransition
              }
            });
            d3.select('#chartDonutOEEDisponibilidad_InformeRendimiento_' + i + ' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
              .text(Math.round(c[0][1]) + "%");
          }
          i++;
        }
      });
    });
  }

  cargarRendimientoCircular(c) {
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEECircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataChartDonutRendimiento.columns;
          }
          if (!this.vinculacion || (this.vinculacion && !this.oeeExcluidos[i])) {
            this.chartDonutOEERendimiento[i] = c3.generate({
              bindto: '#chartDonutOEERendimiento_InformeRendimiento_' + i,
              data: {
                columns: c,
                type: 'donut',
                names: {
                  rendimiento: this.translate.instant("rendimiento"),
                },
                order: 'null'
              },
              donut: {
                title: "0",
                width: 15,
                label: { show: false }
              },
              color: {
                pattern: [this.colorOEEActivo, this.colorOEENoActivo],
              },
              legend: {
                show: false
              },
              tooltip: {
                show: false
              },
              transition: {
                duration: this.tiempoTransition
              }
            });
            d3.select('#chartDonutOEERendimiento_InformeRendimiento_' + i + ' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
              .text(Math.round(c[0][1]) + "%");
          }
          i++;
        }
      });
    });
  }

  cargarCalidadCircular(c) {
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEECircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataChartDonutCalidad.columns;
          }
          if (!this.vinculacion || (this.vinculacion && !this.oeeExcluidos[i])) {
            this.dataChartDonutCalidad[i] = [['completo', 0], ['nocompleto', 100]];
            this.chartDonutOEECalidad[i] = c3.generate({
              bindto: '#chartDonutOEECalidad_InformeRendimiento_' + i,
              data: {
                columns: c,
                type: 'donut',
                names: {
                  calidad: this.translate.instant("calidad"),
                },
                order: 'null'
              },
              donut: {
                title: "0",
                width: 15,
                label: { show: false }
              },
              color: {
                pattern: [this.colorOEEActivo, this.colorOEENoActivo],
              },
              legend: {
                show: false
              },
              tooltip: {
                show: false
              },
              transition: {
                duration: this.tiempoTransition
              }
            });
            d3.select('#chartDonutOEECalidad_InformeRendimiento_' + i + ' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
              .text(Math.round(c[0][1]) + "%");
          }
          i++;
        }
      });
    });
  }

  cargarResumenSemanaCircular(c, n) {
    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'ResumenSemanaCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataResumenSemana.columns;
            n = this.dataResumenSemana.names;
          }


          if (!this.vinculacion || (this.vinculacion && !this.resumenSemanaExcluidos[i])) {
            let estaExcluido = this.resumenSemanaExcluidos[i];
            let index = i;
            if (!estaExcluido) {  // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutResumenSemana[i] = c3.generate({
                bindto: '#chartDonutResumenSemana_' + i,
                data: {
                  columns: c,

                  names: n,
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutResumenSemana_' + i + ' .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutResumenSemana_" + i + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.name);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutResumenSemana_'+ i +' .c3-chart-arcs-title').node().innerHTML = "";
                  }*/
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.resumenNoSeleccionado.indexOf(c[i][0]) == -1) {
                        that.resumenNoSeleccionado.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          let v1 = row.idProcesos_Tipo == 1 && d.id == "ejecucion";
                          let v2 = row.idProcesos_Tipo == 2 && row['microparada'] == 0 && d.id == "parada";
                          let v3 = row.idProcesos_Tipo == 2 && row['microparada'] == 1 && d.id == "microparada";
                          let v4 = row.idProcesos_Tipo == 3 && d.id == "preparacion";
                          let v5 = row.idProcesos_Tipo == 4 && d.id == "mantenimiento";
                          let v6 = (row.idProcesos_Tipo == 6 || row.idProcesos_Tipo == 7) && d.id == "alarma";
                          let v7 = row.idProcesos_Tipo == 8 && d.id == "apagada";
                          if (v1 || v2 || v3 || v4 || v5 || v6 || v7) {
                            that.resumenNoSeleccionadoIndex.push(j);
                            row['oculto'] = 1;
                          }
                        });

                        break;

                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {

                        var index = that.resumenNoSeleccionado.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.resumenNoSeleccionado.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            let v1 = row.idProcesos_Tipo == 1 && d == "ejecucion";
                            let v2 = row.idProcesos_Tipo == 2 && row['microparada'] == 0 && d == "parada";
                            let v3 = row.idProcesos_Tipo == 2 && row['microparada'] == 1 && d == "microparada";
                            let v4 = row.idProcesos_Tipo == 3 && d == "preparacion";
                            let v5 = row.idProcesos_Tipo == 4 && d == "mantenimiento";
                            let v6 = (row.idProcesos_Tipo == 6 || row.idProcesos_Tipo == 7) && d == "alarma";
                            let v7 = row.idProcesos_Tipo == 8 && d == "apagada";
                            if (v1 || v2 || v3 || v4 || v5 || v6 || v7) {
                              that.resumenNoSeleccionadoIndex.push(j);
                              row['oculto'] = 1;
                            }
                          });

                          break;

                        } else if (c[i][0] == d) {
                          that.resumenNoSeleccionado.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            let v1 = row.idProcesos_Tipo == 1 && d == "ejecucion";
                            let v2 = row.idProcesos_Tipo == 2 && row['microparada'] == 0 && d == "parada";
                            let v3 = row.idProcesos_Tipo == 2 && row['microparada'] == 1 && d == "microparada";
                            let v4 = row.idProcesos_Tipo == 3 && d == "preparacion";
                            let v5 = row.idProcesos_Tipo == 4 && d == "mantenimiento";
                            let v6 = (row.idProcesos_Tipo == 6 || row.idProcesos_Tipo == 7) && d == "alarma";
                            let v7 = row.idProcesos_Tipo == 8 && d == "apagada";
                            // comprobar que anteriormente la fila no esta desactivada
                            //var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if (v1 || v2 || v3 || v4 || v5 || v6 || v7) {
                              that.resumenNoSeleccionadoIndex.splice(that.resumenNoSeleccionadoIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }


                    }
                  },
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  show: false
                }
              });
              d3.selectAll("#chartDonutResumenSemana_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutResumenSemana[i] = c3.generate({
                bindto: '#chartDonutResumenSemana_' + i,
                data: {
                  columns: c,

                  names: n,
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutResumenSemana_' + i + ' .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutResumenSemana_" + i + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.name);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutResumenSemana_'+ i +' .c3-chart-arcs-title').node().innerHTML = "";
                  }*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutResumenSemana[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                tooltip: {
                  show: false
                }
              });
              d3.selectAll("#chartDonutResumenSemana_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }

          i++;
        }
      });
    });
  }

  cargarResumenSemanaSubestadosCircular(information, c) {
    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'ResumenSemanaSubestadosCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataResumenSemanaSubestados.columns;
            information = this.dataResumenSemanaSubestados;
          }


          if (!this.vinculacion || (this.vinculacion && !this.resumenSemanaSubestadosExcluidos[i])) {
            let estaExcluido = this.resumenSemanaSubestadosExcluidos[i];
            let index = i;
            if (!estaExcluido) {  // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutResumenSemanaSubestados[i] = c3.generate({
                bindto: '#chartDonutResumenSemanaSubestados_' + i,
                data: {
                  columns: c,
                  names: information.names,
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutResumenSemanaSubestados_' + i + ' .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutResumenSemanaSubestados_" + i + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.name);
                  },
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.resumenSubestadosNoSeleccionado.indexOf(c[i][0]) == -1) {
                        that.resumenSubestadosNoSeleccionado.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if (row['idProcesos_Tipo_subEstados'] == d.id) {
                            that.resumenSubestadosNoSeleccionadoIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;

                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: information.colors
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {

                        var index = that.resumenSubestadosNoSeleccionado.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.resumenSubestadosNoSeleccionado.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if (row['nombreSubestado'] == d) {
                              that.resumenSubestadosNoSeleccionadoIndex.push(j);
                              row.oculto = 1;
                            }
                          });

                          break;

                        } else if (c[i][0] == d) {
                          that.resumenSubestadosNoSeleccionado.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            //var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12;
                            if (row['nombreSubestado'] == d) {
                              that.resumenSubestadosNoSeleccionadoIndex.splice(that.resumenSubestadosNoSeleccionadoIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }


                    }
                  },
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  show: false
                }
              });
              d3.selectAll("#chartDonutResumenSemanaSubestados_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutResumenSemanaSubestados[i] = c3.generate({
                bindto: '#chartDonutResumenSemanaSubestados_' + i,
                data: {
                  columns: c,
                  names: information.names,
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutResumenSemanaSubestados_' + i + ' .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutResumenSemanaSubestados_" + i + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.name);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutResumenSemanaSubestados_'+ i +' .c3-chart-arcs-title').node().innerHTML = "";
                  }*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutResumenSemanaSubestados[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: information.colors
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                tooltip: {
                  show: false
                }
              });
              d3.selectAll("#chartDonutResumenSemanaSubestados_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }

          i++;
        }
      });
    });
  }

  cargarMantenimientoCircular(information: any, c) {
    var that = this;
    var j = 0;

    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'MantenimientosCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGraficoDonutMantenimientos["columns"];
            information = this.dataGraficoDonutMantenimientos;
          }

          if (!this.vinculacion || (this.vinculacion && !this.mantenimientosExcluidos[j])) {
            let estaExcluido = this.mantenimientosExcluidos[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutMantenimientos[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutMantenimientos_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutMantenimientos_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutMantenimientos_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.mantenimientosNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.mantenimientosNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d.id && row['idProcesos_Tipo'] == 4)) {
                            that.mantenimientosNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.mantenimientosNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.mantenimientosNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d && row['idProcesos_Tipo'] == 4)) {
                              that.mantenimientosNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.mantenimientosNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r1 && r2 && r3 && r4 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d && row['idProcesos_Tipo'] == 4)) {
                              that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutMantenimientos_' + j
              });
              d3.selectAll("#chartDonutMantenimientos_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutMantenimientos[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutMantenimientos_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutMantenimientos_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutMantenimientos_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/ onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutMantenimientos[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutMantenimientos_' + j
              });
              d3.selectAll("#chartDonutMantenimientos_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }

          j++;
        }
      });
    });
  }

  cargarMantenimientosOEECircular(c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'MantenimientosOEE') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataMantenimientoOEE.columns;
          }

          if (!this.vinculacion || (this.vinculacion && !this.mantenimientosOeeExcluidos[j])) {
            let estaExcluido = this.mantenimientosOeeExcluidos[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartMantenimientoOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataMantenimientoOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartMantenimientoOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartMantenimientoOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.mantenimientosOEENoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.mantenimientosOEENoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            var r0 = (row.idProcesos_Tipo == 4);
                            var r1 = row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d.id;
                            var r2 = row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d.id;
                            var r3 = row["idTipoPerdidaOEE"] == 3 && "calidad" == d.id;
                            var r4 = row["idTipoPerdidaOEE"] == 4 && "noComputable" == d.id;
                            if (r0 && (r1 || r2 || r3 || r4)) {
                              that.mantenimientosOEENoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }

                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.mantenimientosOEENoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) { // ocultar la leyenda
                          that.mantenimientosOEENoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = (row.idProcesos_Tipo == 4);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.mantenimientosOEENoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) { // enseñar la leyenda
                          that.mantenimientosOEENoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = (row.idProcesos_Tipo == 4);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            // var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r13;
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.mantenimientosOEENoSeleccionadasIndex.splice(that.mantenimientosOEENoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartMantenimientoOEE_' + j
              });
              d3.selectAll("#chartMantenimientoOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartMantenimientoOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataMantenimientoOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartMantenimientoOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartMantenimientoOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartMantenimientoOEE[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartMantenimientoOEE_' + j
              });
              d3.selectAll("#chartMantenimientoOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarOEE1(c) {
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEE1Circular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataChartDonutOEE1;
          }

          if (!this.vinculacion || (this.vinculacion && !this.oee1Excluidos[i])) {
            this.chartDonutOEE1[i] = c3.generate({
              bindto: "#chartDonutOEE1_" + i,
              data: {
                columns: c,
                type: 'donut',
                names: {
                  oee: this.translate.instant("oee"),
                },
                order: 'null'
              },
              donut: {
                title: "0",
                width: 15,
                label: { show: false }
              },
              color: {
                pattern: [this.colorOEEActivo, this.colorOEENoActivo],
              },
              legend: {
                show: false
              },
              tooltip: {
                show: false
              },
              transition: {
                duration: this.tiempoTransition
              }
            });
            // d3.selectAll("#chartDonutOEE1_"+ i +" .c3-chart-arcs path").style("stroke-width", "0px");
            d3.select('#chartDonutOEE1_0 .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
              .text(Math.round(c[0][1]) + "%");
            i++;
          }
        }
      });
    });
  }

  cargarOEEBarras(c) {
    //GRAFICO OEE BARRAS
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEEBarras') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataOEEBarras;
          }

          if (!this.vinculacion || (this.vinculacion && !this.oeeBarrasExcluidos[i])) {
            this.chartOEEBarras[i] = c3.generate({
              data: {
                columns: c,
                order: null,
                type: 'bar',
                groups: [[this.translate.instant('total'), this.translate.instant('paradas'), this.translate.instant('mantenimientos'),
                this.translate.instant('alarmas'), this.translate.instant('apagadas'), this.translate.instant('rendimiento'), this.translate.instant('perdidasCalidad')]]
              },
              color: {
                pattern: [['#44e3c4'], ['#f99f4d'], ['#ebe078'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ffa8a8']]
              },
              axis: {
                x: {
                  type: 'category',
                  tick: {
                    multiline: false
                  },
                  categories: [this.translate.instant('tiempoTotal'), this.translate.instant('disponibilidad'),
                  this.translate.instant('rendimiento'), this.translate.instant('calidad')]
                },
                y: {
                  show: false,
                },
                rotated: true
              },
              transition: {
                duration: 2000
              },
              bar: {
                width: {
                  ratio: 0.85
                }
              },
              tooltip: {
                format: {
                  value: function (value) {
                    var hours = Math.floor(value / (60 * 60));
                    var divisor_for_minutes = value % (60 * 60);
                    var minutes = Math.floor(divisor_for_minutes / 60);
                    if (0 <= minutes && minutes < 10)
                      return hours + ":0" + minutes + "h";
                    else
                      return hours + ":" + minutes + "h";
                  }
                }
              },
              onrendered: function () {
                d3.selectAll("#chartDonutOEEBarra_" + i + " .c3-bar")
                  .style("opacity", function (d) {
                    if (d.index === 1) {
                      return 1;
                    } else {
                      return 0.4
                    }
                  });
              },
              bindto: '#chartDonutOEEBarra_' + i
            });
          }
          i++;
        }
      });
    });
  }

  cargarAlarmaCircular(information: any, c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'AlarmasCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGraficoDonutAlarmas['columns'];
            information = this.dataGraficoDonutAlarmas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.alarmasExcluidas[j])) {
            let estaExcluido = this.alarmasExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutAlarmas.push(c3.generate({
                data: {
                  columns: information["columns"],
                  names: information["names"],
                  type: 'donut',
                  /*onmouseover: function (d) {
                    d3.select('#chartDonutAlarmas_'+ j +' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutAlarmas_"+ j +" .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },*/
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutAlarmas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.alarmasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.alarmasNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d.id && row['idProcesos_Tipo'] == 6)) {
                            that.alarmasNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    // actualizar leyenda de todos los graficos de alarmas
                    that.updateLeyenda();

                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.alarmasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.alarmasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.alarmasNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.splice(that.alarmasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutAlarmas_' + j
              }));
              d3.selectAll("#chartDonutAlarmas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutAlarmas.push(c3.generate({
                data: {
                  columns: information["columns"],
                  names: information["names"],
                  type: 'donut',
                  /*onmouseover: function (d) {
                    d3.select('#chartDonutAlarmas_'+ j +' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutAlarmas_"+ j +" .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },*/
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutAlarmas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutAlarmas[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutAlarmas_' + j
              }));
              d3.selectAll("#chartDonutAlarmas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarAlarmasOEECircular(c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'AlarmasOEE') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataAlarmasOEE["columns"];
          }
          if (!this.vinculacion || (this.vinculacion && !this.alarmasOeeExcluidas[j])) {
            let estaExcluido = this.alarmasOeeExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartAlarmasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataAlarmasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartAlarmasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartAlarmasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.alarmasOEENoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.alarmasOEENoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            var r0 = (row.idProcesos_Tipo == 6);
                            var r1 = row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d.id;
                            var r2 = row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d.id;
                            var r3 = row["idTipoPerdidaOEE"] == 3 && "calidad" == d.id;
                            var r4 = row["idTipoPerdidaOEE"] == 4 && "noComputable" == d.id;
                            if (r0 && (r1 || r2 || r3 || r4)) {
                              that.alarmasOEENoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }

                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.alarmasOEENoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) { // ocultar la leyenda
                          that.alarmasOEENoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = (row.idProcesos_Tipo == 6);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.alarmasOEENoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) { // enseñar la leyenda
                          that.alarmasOEENoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = (row.idProcesos_Tipo == 6);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            // var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r12 && r13;
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.alarmasOEENoSeleccionadasIndex.splice(that.alarmasOEENoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartAlarmasOEE_' + j
              });
              d3.selectAll("#chartAlarmasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartAlarmasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataAlarmasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartAlarmasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartAlarmasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartAlarmasOEE[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartAlarmasOEE_' + j
              });
              d3.selectAll("#chartAlarmasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarPerdidasCircular(information: any, c) {
    var that = this;
    var j = 0;

    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'PerdidasCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGraficoDonutParadas["columns"];
            information = this.dataGraficoDonutParadas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.perdidasExcluidas[j])) {
            let estaExcluido = this.perdidasExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutParadas[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutParadas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutParadas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.perdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.perdidasNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["perdida"] == d.id)) {
                              that.perdidasNoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }

                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;

                      for (var i = 0; i < c.length; i++) {
                        var index = that.perdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.perdidasNoSeleccionadas.push(c[i][0]);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["perdida"] == d) {
                              that.perdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });
                          break;
                        } else if (c[i][0] == d) {
                          that.perdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["perdida"] == d) {
                              that.perdidasNoSeleccionadasIndex.splice(that.perdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });
                          break;
                        }

                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutParadas_' + j
              });
              d3.selectAll("#chartDonutParadas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutParadas[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutParadas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutParadas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutParadas[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutParadas_' + j
              });
              d3.selectAll("#chartDonutParadas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }

          }
          j++;
        }
      });
    });
  }

  cargarPerdidasOEECircular(c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'PerdidasOEE') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataPerdidasOEE.columns;
          }

          if (!this.vinculacion || (this.vinculacion && !this.perdidasOeeExcluidas[j])) {
            let estaExcluido = this.perdidasOeeExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartPerdidasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataPerdidasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutParadasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutParadasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.perdidasOEENoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.perdidasOEENoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            var r0 = ((row['idPerdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idPerdida'] != -1);
                            var r1 = row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d.id;
                            var r2 = row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d.id;
                            var r3 = row["idTipoPerdidaOEE"] == 3 && "calidad" == d.id;
                            var r4 = row["idTipoPerdidaOEE"] == 4 && "noComputable" == d.id;
                            if (r0 && (r1 || r2 || r3 || r4)) {
                              that.perdidasOEENoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }

                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.perdidasOEENoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) { // ocultar la leyenda
                          that.perdidasOEENoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = ((row['idPerdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idPerdida'] != -1);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.perdidasOEENoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) { // enseñar la leyenda
                          that.perdidasOEENoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = ((row['idPerdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idPerdida'] != -1);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            // var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r7 && r9 && r10 && r11 && r12 && r13;
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.perdidasOEENoSeleccionadasIndex.splice(that.perdidasOEENoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutParadasOEE_' + j
              });
              d3.selectAll("#chartDonutParadasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartPerdidasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataPerdidasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutParadasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutParadasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartPerdidasOEE[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutParadasOEE_' + j
              });
              d3.selectAll("#chartDonutParadasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarSubperdidasCircular(information: any, c) {
    var that = this;
    var j = 0;

    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'SubperdidasCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGraficoDonutSubperdidas["columns"];
            information = this.dataGraficoDonutSubperdidas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.subperdidasExcluidas[j])) {
            let estaExcluido = this.subperdidasExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutSubperdidas[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutSubperdidas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutSubperdidas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutSubperdidas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.subperdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.subperdidasNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["subPerdida"] == d.id)) {
                            row.oculto = 1;
                            that.subperdidasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.subperdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.subperdidasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["subPerdida"] == d) {
                              that.subperdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.subperdidasNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["subPerdida"] == d) {
                              that.subperdidasNoSeleccionadasIndex.splice(that.subperdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutSubperdidas_' + j
              });
              d3.selectAll("#chartDonutSubperdidas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutSubperdidas[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutSubperdidas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutSubperdidas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutSubperdidas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutSubperdidas[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutSubperdidas_' + j
              });
              d3.selectAll("#chartDonutSubperdidas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }

          j++;
        }
      });
    });

  }

  cargarSubperdidasOEECircular(c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'SubperdidasOEE') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataSubperdidasOEE.columns;
          }

          if (!this.vinculacion || (this.vinculacion && !this.subperdidasOeeExcluidas[j])) {
            let estaExcluido = this.subperdidasOeeExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartSubperdidasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataSubperdidasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutSubperdidasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutSubperdidasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  onclick: function (d) {

                    let i = 0;
                    that.data.forEach(
                      row => {
                        i++;
                        var r0 = ((row['idSubperdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idSubperdida'] != -1);
                        var r1 = row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d.id;
                        var r2 = row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d.id;
                        var r3 = row["idTipoPerdidaOEE"] == 3 && "calidad" == d.id;
                        var r4 = row["idTipoPerdidaOEE"] == 4 && "noComputable" == d.id;
                        if (r0 && (r1 || r2 || r3 || r4)) {
                          that.subperdidasOEENoSeleccionadasIndex.push(i)
                          row.oculto = 1;
                        }
                      });

                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.subperdidasOEENoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) { // ocultar la leyenda
                          that.subperdidasOEENoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = ((row['idSubperdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idSubperdida'] != -1);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.subperdidasOEENoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          if (!disable) {
                            that.cargarDatosInforme();
                            that.updateLeyenda();
                          }

                          break;
                        } else if (c[i][0] == d) { // enseñar la leyenda
                          that.subperdidasOEENoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = ((row['idSubPerdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idSubPerdida'] != -1);
                            var v1 = (that.data[j]["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (that.data[j]["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (that.data[j]["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (that.data[j]["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            // var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r7 && r8 && r10 && r11 && r12 && r13;
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.subperdidasOEENoSeleccionadasIndex.splice(that.subperdidasOEENoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                    }
                  },

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutSubperdidasOEE_' + j
              });
              d3.selectAll("#chartDonutSubperdidasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartSubperdidasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataSubperdidasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutSubperdidasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutSubperdidasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartSubperdidasOEE[indexExcluido].hide(d.id)
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutSubperdidasOEE_' + j
              });
              d3.selectAll("#chartDonutSubperdidasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarGrupoPerdidasCircular(information: any, c) {
    var that = this;
    var j = 0;

    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'GrupoPerdidasCircular') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGraficoDonutGrupoPerdidas["columns"];
            information = this.dataGraficoDonutGrupoPerdidas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasExcluidas[j])) {
            let estaExcluido = this.grupoPerdidasExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutGrupoPerdidas[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutGrupoPerdidas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutGrupoPerdidas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutGrupoPerdidas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.grupoPerdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.grupoPerdidasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["grupoPerdida"] == d.id)) {
                            row.oculto = 1;
                            that.grupoPerdidasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {

                        var index = that.grupoPerdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.grupoPerdidasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["grupoPerdida"] == d) {
                              that.grupoPerdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.grupoPerdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            //var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["grupoPerdida"] == d) {
                              that.grupoPerdidasNoSeleccionadasIndex.splice(that.grupoPerdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutGrupoPerdidas_' + j
              });
              d3.selectAll("#chartDonutGrupoPerdidas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutGrupoPerdidas[j] = c3.generate({
                data: {
                  columns: c,
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutGrupoPerdidas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutGrupoPerdidas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutGrupoPerdidas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutGrupoPerdidas[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutGrupoPerdidas_' + j
              });
              d3.selectAll("#chartDonutGrupoPerdidas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarGrupoPerdidasOEECircular(c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'GrupoPerdidasOEE') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGrupoPerdidasOEE.columns;
          }
          if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasOeeExcluidas[j])) {
            let estaExcluido = this.grupoPerdidasOeeExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartGrupoPerdidasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataGrupoPerdidasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartGrupoPerdidasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartGrupoPerdidasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.grupoPerdidasOEENoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.grupoPerdidasOEENoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            var r0 = ((row['idGrupoPerdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idGrupoPerdida'] != -1);
                            var r1 = row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d.id;
                            var r2 = row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d.id;
                            var r3 = row["idTipoPerdidaOEE"] == 3 && "calidad" == d.id;
                            var r4 = row["idTipoPerdidaOEE"] == 4 && "noComputable" == d.id;
                            if (r0 && (r1 || r2 || r3 || r4)) {
                              that.grupoPerdidasOEENoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }

                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.grupoPerdidasOEENoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) { // ocultar la leyenda
                          that.grupoPerdidasOEENoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = ((row['idGrupoPerdida'] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row['idGrupoPerdida'] != -1);
                            var v1 = (row["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (row["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (row["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (row["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.grupoPerdidasOEENoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) { // enseñar la leyenda
                          that.grupoPerdidasOEENoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            var v0 = ((that.data[j]['idGrupoPerdida'] == -1 && that.data[j]['idProcesos_Tipo'] == 2 && that.data[j]['microparada'] == 0) || that.data[j]['idGrupoPerdida'] != -1);
                            var v1 = (that.data[j]["idTipoPerdidaOEE"] == 1 && "disponibilidad" == d);
                            var v2 = (that.data[j]["idTipoPerdidaOEE"] == 2 && "rendimiento" == d);
                            var v3 = (that.data[j]["idTipoPerdidaOEE"] == 3 && "calidad" == d);
                            var v4 = (that.data[j]["idTipoPerdidaOEE"] == 4 && "noComputable" == d);
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            // var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r11 && r12 && r13;
                            if (v0 && (v1 || v2 || v3 || v4)) {
                              that.grupoPerdidasOEENoSeleccionadasIndex.splice(that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartGrupoPerdidasOEE_' + j
              });
              d3.selectAll("#chartGrupoPerdidasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartGrupoPerdidasOEE[j] = c3.generate({
                data: {
                  columns: c,
                  names: this.dataGrupoPerdidasOEE['names'],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartGrupoPerdidasOEE_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartGrupoPerdidasOEE_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutParadas_'+j+' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartGrupoPerdidasOEE[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'right',

                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartGrupoPerdidasOEE_' + j
              });
              d3.selectAll("#chartGrupoPerdidasOEE_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarDobleAlarmaCircular(cGravedad, nGravedad, c) {

    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'AlarmasDoble') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            cGravedad = this.dataGraficoDonutGravedadAlarmas.columns;
            nGravedad = this.dataGraficoDonutGravedadAlarmas.names;
          }

          if (!this.vinculacion || (this.vinculacion && !this.dobleAlarmasExcluidas[j])) {
            // GRAVEDAD
            let estaExcluido = this.dobleAlarmasExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutGravedadAlarmas.push(c3.generate({
                data: {
                  columns: cGravedad,
                  names: nGravedad,
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutGravedadAlarmas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutGravedadAlarmas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutGravedadAlarmas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {
                    for (var i = 0; i < cGravedad.length; i++) {
                      if (cGravedad[i][0] == d.id && that.gravedadAlarmasNoSeleccionadas.indexOf(cGravedad[i][0]) == -1) {
                        that.gravedadAlarmasNoSeleccionadas.push(cGravedad[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["gravedadAlarma"] == -1 && row['idProcesos_Tipo'] == 6) || (row["gravedadAlarma"] == d.id && row['idProcesos_Tipo'] == 6)) {
                            row.oculto = 1;
                            that.gravedadAlarmasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();
                  }

                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'bottom',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < cGravedad.length; i++) {
                        var index = that.gravedadAlarmasNoSeleccionadas.indexOf(cGravedad[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (cGravedad[i][0] == d && index == -1) {
                          that.gravedadAlarmasNoSeleccionadas.push(cGravedad[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["gravedadAlarma"] == -1 && row['idProcesos_Tipo'] == 6) || (row["gravedadAlarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.gravedadAlarmasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (cGravedad[i][0] == d) {
                          that.gravedadAlarmasNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["gravedadAlarma"] == -1 && row['idProcesos_Tipo'] == 6) || (row["gravedadAlarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.gravedadAlarmasNoSeleccionadasIndex.splice(that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  }
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutGravedadAlarmas_' + j
              }));
              d3.selectAll("#chartDonutGravedadAlarmas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data {
              this.chartDonutGravedadAlarmas.push(c3.generate({
                data: {
                  columns: cGravedad,
                  names: nGravedad,
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutGravedadAlarmas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutGravedadAlarmas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutGravedadAlarmas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutGravedadAlarmas[indexExcluido].hide(d.id);
                  }

                },
                transition: {
                  duration: 2000
                },
                legend: {
                  //bottom, right, inset
                  position: 'bottom',
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },
                bindto: '#chartDonutGravedadAlarmas_' + j
              }));
              d3.selectAll("#chartDonutGravedadAlarmas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarDobleAlarmaCircularTipo(information: any, c) {
    var that = this;
    var j = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'AlarmasDoble') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGraficoDonutAlarmas["columns"];
            information = this.dataGraficoDonutAlarmas;
          }

          // TIPO
          if (!this.vinculacion || (this.vinculacion && !this.dobleAlarmasExcluidas[j])) {
            let estaExcluido = this.dobleAlarmasExcluidas[j];
            let index = j;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutTipoAlarmas[j] = c3.generate({
                data: {
                  columns: information["columns"],
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutTipoAlarmas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutTipoAlarmas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutTipoAlarmas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.alarmasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.alarmasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d.id && row['idProcesos_Tipo'] == 6)) {
                            that.alarmasNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  show: false,
                  //bottom, right, inset
                  //position: 'bottom',
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.alarmasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.alarmasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.alarmasNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.splice(that.alarmasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                /*tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },*/
                bindto: '#chartDonutTipoAlarmas_' + j
              });
              d3.selectAll("#chartDonutTipoAlarmas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutTipoAlarmas[j] = c3.generate({
                data: {
                  columns: information["columns"],
                  names: information["names"],
                  type: 'donut',
                  onmouseover: function (d) {
                    d3.select('#chartDonutTipoAlarmas_' + j + ' .c3-chart-arcs-title')
                      .append("tspan")
                      .attr("font-size", "35")
                      .text((d.ratio * 100).toFixed(1) + "%");
                    d3.select("#chartDonutTipoAlarmas_" + j + " .c3-chart-arcs-title")
                      .append("tspan")
                      .attr("dy", 24)
                      .attr("x", 0)
                      .attr("font-size", "10")
                      .text(d.id);
                  },
                  /*onmouseout: function (d) {
                    d3.select('#chartDonutTipoAlarmas_'+ j +' .c3-chart-arcs-title').node().innerHTML = "";
                  },*/
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutTipoAlarmas[indexExcluido].hide(d.id);
                  }
                },
                transition: {
                  duration: 2000
                },
                legend: {
                  show: false,
                },
                color: {
                  pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
                },
                /*tooltip: {
                  show: true,
                  contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
                      d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
                    return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                  }
                },*/
                bindto: '#chartDonutTipoAlarmas_' + j
              });
              d3.selectAll("#chartDonutTipoAlarmas_" + j + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          j++;
        }
      });
    });
  }

  cargarResumenSemanaLineas(information: any, c, max, values) {
    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'ResumenSemanaLineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataResumenSemanaLineas.columns;
            information = this.dataResumenSemanaLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.resumenSemanaLineasExcluidos[i])) {
            let estaExcluido = this.resumenSemanaLineasExcluidos[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutResumenSemanaLineas[i] = c3.generate({
                bindto: '#chartDonutResumenSemanaLineas_' + i,
                data: {
                  xs: {
                    'preparacion': 'x',
                    'ejecucion': 'x',
                    'microparada': 'x',
                    'parada': 'x',
                    'mantenimiento': 'x',
                    'alarma': 'x',
                    'apagada': 'x',
                  },
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [['preparacion', 'ejecucion', 'microparada', 'parada', 'mantenimiento', 'alarma', 'apagada']],
                  names: {
                    preparacion: this.translate.instant("preparacion"),
                    ejecucion: this.translate.instant("ejecucion"),
                    microparada: this.translate.instant("microparada"),
                    parada: this.translate.instant("parada"),
                    mantenimiento: this.translate.instant("mantenimiento"),
                    alarma: this.translate.instant("alarma"),
                    apagada: this.translate.instant("apagada"),
                  },
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.resumenNoSeleccionado.indexOf(c[i][0]) == -1) {
                        that.resumenNoSeleccionado.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          let v1 = row.idProcesos_Tipo == 1 && d.id == "ejecucion";
                          let v2 = row.idProcesos_Tipo == 2 && row['microparada'] == 0 && d.id == "parada";
                          let v3 = row.idProcesos_Tipo == 2 && row['microparada'] == 1 && d.id == "microparada";
                          let v4 = row.idProcesos_Tipo == 3 && d.id == "preparacion";
                          let v5 = row.idProcesos_Tipo == 4 && d.id == "mantenimiento";
                          let v6 = (row.idProcesos_Tipo == 6 || row.idProcesos_Tipo == 7) && d.id == "alarma";
                          let v7 = row.idProcesos_Tipo == 8 && d.id == "apagada";
                          if (v1 || v2 || v3 || v4 || v5 || v6 || v7) {
                            that.resumenNoSeleccionadoIndex.push(j);
                            row['oculto'] = 1;
                          }
                        });

                        break;

                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {

                        var index = that.resumenNoSeleccionado.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.resumenNoSeleccionado.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            let v1 = row.idProcesos_Tipo == 1 && d == "ejecucion";
                            let v2 = row.idProcesos_Tipo == 2 && row['microparada'] == 0 && d == "parada";
                            let v3 = row.idProcesos_Tipo == 2 && row['microparada'] == 1 && d == "microparada";
                            let v4 = row.idProcesos_Tipo == 3 && d == "preparacion";
                            let v5 = row.idProcesos_Tipo == 4 && d == "mantenimiento";
                            let v6 = (row.idProcesos_Tipo == 6 || row.idProcesos_Tipo == 7) && d == "alarma";
                            let v7 = row.idProcesos_Tipo == 8 && d == "apagada";
                            if (v1 || v2 || v3 || v4 || v5 || v6 || v7) {
                              that.resumenNoSeleccionadoIndex.push(j);
                              row['oculto'] = 1;
                            }
                          });

                          break;

                        } else if (c[i][0] == d) {
                          that.resumenNoSeleccionado.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            let v1 = row.idProcesos_Tipo == 1 && d == "ejecucion";
                            let v2 = row.idProcesos_Tipo == 2 && row['microparada'] == 0 && d == "parada";
                            let v3 = row.idProcesos_Tipo == 2 && row['microparada'] == 1 && d == "microparada";
                            let v4 = row.idProcesos_Tipo == 3 && d == "preparacion";
                            let v5 = row.idProcesos_Tipo == 4 && d == "mantenimiento";
                            let v6 = (row.idProcesos_Tipo == 6 || row.idProcesos_Tipo == 7) && d == "alarma";
                            let v7 = row.idProcesos_Tipo == 8 && d == "apagada";
                            // comprobar que anteriormente la fila no esta desactivada
                            //var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if (v1 || v2 || v3 || v4 || v5 || v6 || v7) {
                              that.resumenNoSeleccionadoIndex.splice(that.resumenNoSeleccionadoIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutResumenSemanaLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutResumenSemanaLineas[i] = c3.generate({
                bindto: '#chartDonutResumenSemanaLineas_' + i,
                data: {
                  xs: {
                    'preparacion': 'x',
                    'ejecucion': 'x',
                    'microparada': 'x',
                    'parada': 'x',
                    'mantenimiento': 'x',
                    'alarma': 'x',
                    'apagada': 'x',
                  },
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [['preparacion', 'ejecucion', 'microparada', 'parada', 'mantenimiento', 'alarma', 'apagada']],
                  names: {
                    preparacion: this.translate.instant("preparacion"),
                    ejecucion: this.translate.instant("ejecucion"),
                    microparada: this.translate.instant("microparada"),
                    parada: this.translate.instant("parada"),
                    mantenimiento: this.translate.instant("mantenimiento"),
                    alarma: this.translate.instant("alarma"),
                    apagada: this.translate.instant("apagada"),
                  },
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutResumenSemanaLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutResumenSemanaLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarMantenimientoLineas(information: any, xValue, c, max, values) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'MantenimientosLineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataMantenimientosLineas.columns;
            information = this.dataMantenimientosLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.mantenimientosLineasExcluidos[i])) {
            let estaExcluido = this.mantenimientosLineasExcluidos[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutMantenimientosLineas[i] = c3.generate({
                bindto: '#chartDonutMantenimientosLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.mantenimientosNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.mantenimientosNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d.id && row['idProcesos_Tipo'] == 4)) {
                            that.mantenimientosNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.mantenimientosNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.mantenimientosNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d && row['idProcesos_Tipo'] == 4)) {
                              that.mantenimientosNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.mantenimientosNoSeleccionadas.splice(index, 1);

                          // for (var j = 0; j<Object.keys(that.data).length; j++) {
                          //   // comprobar que anteriormente la fila no esta desactivada
                          //   var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                          //   var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   //var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var rt = r1 && r2 && r3 && r4 && r6 && r7 && r8 && r9 && r10 && r11 && r12;
                          //   if (d == "Sin asignar" && that.data[j]["maquinaMantenimiento"] == "" && that.data[j]['idProcesos_Tipo'] == 4) {
                          //     that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                          //     if (rt) that.data[j]["oculto"] = 0;
                          //   } else if (that.data[j]["maquinaMantenimiento"] == d && that.data[j]['idProcesos_Tipo'] == 4) {
                          //     that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                          //     if (rt) that.data[j]["oculto"] = 0;
                          //   }
                          // }

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d && row['idProcesos_Tipo'] == 4)) {
                              that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutMantenimientosLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutMantenimientosLineas[i] = c3.generate({
                bindto: '#chartDonutMantenimientosLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutMantenimientosLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutMantenimientosLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarMantenimientoCantidadLineas(information: any, xValue, c) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'MantenimientosLineasCantidad') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataMantenimientosCantidadLineas.columns;
            information = this.dataMantenimientosCantidadLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.mantenimientosLineasCantidadExcluidos[i])) {
            let estaExcluido = this.mantenimientosLineasCantidadExcluidos[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutMantenimientosCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutMantenimientosCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.mantenimientosNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.mantenimientosNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d.id && row['idProcesos_Tipo'] == 4)) {
                            that.mantenimientosNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.mantenimientosNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.mantenimientosNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d && row['idProcesos_Tipo'] == 4)) {
                              that.mantenimientosNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.mantenimientosNoSeleccionadas.splice(index, 1);

                          // for (var j = 0; j<Object.keys(that.data).length; j++) {
                          //   // comprobar que anteriormente la fila no esta desactivada
                          //   var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                          //   var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   //var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                          //   var rt = r1 && r2 && r3 && r4 && r6 && r7 && r8 && r9 && r10 && r11 && r12;
                          //   if (d == "Sin asignar" && that.data[j]["maquinaMantenimiento"] == "" && that.data[j]['idProcesos_Tipo'] == 4) {
                          //     that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                          //     if (rt) that.data[j]["oculto"] = 0;
                          //   } else if (that.data[j]["maquinaMantenimiento"] == d && that.data[j]['idProcesos_Tipo'] == 4) {
                          //     that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                          //     if (rt) that.data[j]["oculto"] = 0;
                          //   }
                          // }

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["maquinaMantenimiento"] == "" && row['idProcesos_Tipo'] == 4) || (row["maquinaMantenimiento"] == d && row['idProcesos_Tipo'] == 4)) {
                              that.mantenimientosNoSeleccionadasIndex.splice(that.mantenimientosNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutMantenimientosCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutMantenimientosCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutMantenimientosCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutMantenimientosCantidadLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutMantenimientosCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarGrupoPerdidasLineas(information: any, xValue, c, max, values) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'GrupoPerdidasLineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGrupoPerdidasLineas.columns;
            information = this.dataGrupoPerdidasLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasLineasExcluidas[i])) {
            let estaExcluido = this.grupoPerdidasLineasExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutGrupoPerdidasLineas[i] = c3.generate({
                bindto: '#chartDonutGrupoPerdidasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.grupoPerdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.grupoPerdidasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["grupoPerdida"] == d.id)) {
                            row.oculto = 1;
                            that.grupoPerdidasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                      // format: '%Y-%m-%d'
                      // culling: { max: 10 },
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {

                        var index = that.grupoPerdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.grupoPerdidasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["grupoPerdida"] == d) {
                              that.grupoPerdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.grupoPerdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            //var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["grupoPerdida"] == d) {
                              that.grupoPerdidasNoSeleccionadasIndex.splice(that.grupoPerdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  },
                  grouped: true
                },
                grid: {
                  y: {
                    show: true
                  }
                },
              });
              d3.selectAll("#chartDonutGrupoPerdidasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutGrupoPerdidasLineas[i] = c3.generate({
                bindto: '#chartDonutGrupoPerdidasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutGrupoPerdidasLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutGrupoPerdidasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarGrupoPerdidasCantidadLineas(information: any, xValue, c) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'GrupoPerdidasLineasCantidad') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataGrupoPerdidasCantidadLineas.columns;
            information = this.dataGrupoPerdidasCantidadLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasLineasCantidadExcluidas[i])) {
            let estaExcluido = this.grupoPerdidasLineasCantidadExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutGrupoPerdidasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutGrupoPerdidasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.grupoPerdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.grupoPerdidasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["grupoPerdida"] == d.id)) {
                            row.oculto = 1;
                            that.grupoPerdidasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                    /*tick: {
                      values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400, 97200],
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours
                      }
                    }*/
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {

                        var index = that.grupoPerdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.grupoPerdidasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["grupoPerdida"] == d) {
                              that.grupoPerdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.grupoPerdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            //var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["grupoPerdida"] == d) {
                              that.grupoPerdidasNoSeleccionadasIndex.splice(that.grupoPerdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutGrupoPerdidasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutGrupoPerdidasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutGrupoPerdidasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutGrupoPerdidasCantidadLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                    /*tick: {
                      values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400, 97200],
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours
                      }
                    }*/
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutGrupoPerdidasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarPerdidasLineas(information: any, xValue, c, max, values) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'PerdidasLineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataPerdidasLineas.columns;
            information = this.dataPerdidasLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.perdidasLineasExcluidas[i])) {
            let estaExcluido = this.perdidasLineasExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutPerdidasLineas[i] = c3.generate({
                bindto: '#chartDonutPerdidasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.perdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.perdidasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["perdida"] == d.id)) {
                              that.perdidasNoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }
                    that.cargarDatosInforme();
                    that.updateLeyenda();
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;

                      for (var i = 0; i < c.length; i++) {
                        var index = that.perdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.perdidasNoSeleccionadas.push(c[i][0]);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["perdida"] == d) {
                              that.perdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });
                          break;
                        } else if (c[i][0] == d) {
                          that.perdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["perdida"] == d) {
                              that.perdidasNoSeleccionadasIndex.splice(that.perdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });
                          break;
                        }

                      }
                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutPerdidasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutPerdidasLineas[i] = c3.generate({
                bindto: '#chartDonutPerdidasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutPerdidasLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    padding: { right: 0, bottom: 0, left: 0 },
                    max: max,
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutPerdidasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }

          }
          i++;
        }
      });
    });
  }

  cargarPerdidasCantidadLineas(information: any, xValue, c) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'PerdidasLineasCantidad') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataPerdidasCantidadLineas.columns;
            information = this.dataPerdidasCantidadLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.perdidasLineasCantidadExcluidas[i])) {
            let estaExcluido = this.perdidasLineasCantidadExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutPerdidasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutPerdidasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.perdidasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.perdidasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(
                          row => {
                            j++;
                            if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["perdida"] == d.id)) {
                              that.perdidasNoSeleccionadasIndex.push(j)
                              row.oculto = 1;
                            }
                          });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                  }
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.perdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.perdidasNoSeleccionadas.push(c[i][0]);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["perdida"] == d) {
                              that.perdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });
                          break;
                        } else if (c[i][0] == d) {
                          that.perdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["perdida"] == d) {
                              that.perdidasNoSeleccionadasIndex.splice(that.perdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });
                          break;
                        }

                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },

                },
                transition: {
                  duration: this.tiempoTransition
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutPerdidasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutPerdidasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutPerdidasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutPerdidasCantidadLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutPerdidasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarSubperdidasLineas(information: any, xValue, c, max, values) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'SubperdidasLineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataSubperdidasLineas.columns;
            information = this.dataSubperdidasLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.subperdidasLineasExcluidas[i])) {
            let estaExcluido = this.subperdidasLineasExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutSubperdidasLineas[i] = c3.generate({
                bindto: '#chartDonutSubperdidasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      var index = that.subperdidasNoSeleccionadas.indexOf(c[i][0]);
                      if (c[i][0] == d.id && index == -1) {
                        that.subperdidasNoSeleccionadas.push(c[i][0]);
                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["subPerdida"] == d.id)) {
                            row.oculto = 1;
                            that.subperdidasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();
                    that.updateLeyenda();


                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.subperdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.subperdidasNoSeleccionadas.push(c[i][0]);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["subPerdida"] == d) {
                              that.subperdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.subperdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["subPerdida"] == d) {
                              that.subperdidasNoSeleccionadasIndex.splice(that.subperdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutSubperdidasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutSubperdidasLineas[i] = c3.generate({
                bindto: '#chartDonutSubperdidasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutSubperdidasLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    padding: { right: 0, bottom: 0, left: 0 },
                    max: max,
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutSubperdidasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarSubperdidasCantidadLineas(information: any, xValue, c) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'SubperdidasLineasCantidad') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataSubperdidasCantidadLineas.columns;
            information = this.dataSubperdidasCantidadLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.subperdidasLineasCantidadExcluidas[i])) {
            let estaExcluido = this.subperdidasLineasCantidadExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutSubperdidasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutSubperdidasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d) {

                    for (var i = 0; i < c.length; i++) {
                      var index = that.subperdidasNoSeleccionadas.indexOf(c[i][0]);
                      if (c[i][0] == d.id && index == -1) {
                        that.subperdidasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || (row["subPerdida"] == d.id)) {
                            row.oculto = 1;
                            that.subperdidasNoSeleccionadasIndex.push(j);
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();

                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.subperdidasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.subperdidasNoSeleccionadas.push(c[i][0]);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["subPerdida"] == d) {
                              that.subperdidasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.subperdidasNoSeleccionadas.splice(index, 1);
                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["idPerdidaHistoricoBase"] == -1 && row['idProcesos_Tipo'] == 2 && row['microparada'] == 0) || row["subPerdida"] == d) {
                              that.subperdidasNoSeleccionadasIndex.splice(that.subperdidasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutSubperdidasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutSubperdidasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutSubperdidasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutSubperdidasCantidadLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    //max: 30,
                    padding: { right: 0, bottom: 0, left: 0 },
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutSubperdidasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarAlarmasLineas(information: any, xValue, c, max, values) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'AlarmasLineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataAlarmasLineas.columns;
            information = this.dataAlarmasLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.alarmasLineasExcluidas[i])) {
            let estaExcluido = this.alarmasLineasExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutAlarmasLineas[i] = c3.generate({
                bindto: '#chartDonutAlarmasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.alarmasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.alarmasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d.id && row['idProcesos_Tipo'] == 6)) {
                            that.alarmasNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.alarmasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.alarmasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.alarmasNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.splice(that.alarmasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutAlarmasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutAlarmasLineas[i] = c3.generate({
                bindto: '#chartDonutAlarmasLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  //type: 'area', 'area-spline',
                  //type: 'line',
                  //groups: [groups],
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutAlarmasLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    //min: 0,
                    max: max,
                    padding: { right: 0, bottom: 0, left: 0 },
                    tick: {
                      values: values,
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours + "h";
                      }
                    }
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                tooltip: {
                  format: {
                    value: function (value) {
                      var hours = Math.floor(value / (60 * 60));
                      var divisor_for_minutes = value % (60 * 60);
                      var minutes = Math.floor(divisor_for_minutes / 60);
                      if (0 <= minutes && minutes < 10)
                        return hours + ":0" + minutes + "h";
                      else
                        return hours + ":" + minutes + "h";
                    }
                  }
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutAlarmasLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarAlarmasCantidadLineas(information: any, xValue, c) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }

    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'AlarmasLineasCantidad') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            c = this.dataAlarmasCantidadLineas.columns;
            information = this.dataAlarmasCantidadLineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.alarmasLineasCantidadExcluidas[i])) {
            let estaExcluido = this.alarmasLineasCantidadExcluidas[i];
            let index = i;
            if (!estaExcluido) { // si no esta excluido hay que generar los onclick de la leyenda y data
              this.chartDonutAlarmasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutAlarmasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d) {
                    for (var i = 0; i < c.length; i++) {
                      if (c[i][0] == d.id && that.alarmasNoSeleccionadas.indexOf(c[i][0]) == -1) {
                        that.alarmasNoSeleccionadas.push(c[i][0]);

                        let j = 0;
                        that.data.forEach(row => {
                          j++;
                          if ((d.id == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d.id && row['idProcesos_Tipo'] == 6)) {
                            that.alarmasNoSeleccionadasIndex.push(j);
                            row.oculto = 1;
                          }
                        });

                        break;
                      }
                    }

                    that.cargarDatosInforme();

                    that.updateLeyenda();
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    padding: { right: 0, bottom: 0, left: 0 },
                    /*tick: {
                      values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400, 97200],
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours
                      }
                    }*/
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                legend: {
                  item: {
                    onclick: function (d) {

                      // esta variable se utiliza para activar o desactivar la funcion de vinculacion 
                      var disable = false;
                      for (var i = 0; i < c.length; i++) {
                        var index = that.alarmasNoSeleccionadas.indexOf(c[i][0]);
                        if (c[i][0] == d && c[i][1] == 0 && index == -1) { // no dejar que haga ninguna vinculacion
                          disable = true;
                          break;
                        } else if (c[i][0] == d && index == -1) {
                          that.alarmasNoSeleccionadas.push(c[i][0]);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.push(j);
                              row["oculto"] = 1;
                            }
                          });

                          break;
                        } else if (c[i][0] == d) {
                          that.alarmasNoSeleccionadas.splice(index, 1);

                          let j = 0;
                          that.data.forEach(row => {
                            j++;
                            // comprobar que anteriormente la fila no esta desactivada
                            var r1 = that.resumenNoSeleccionadoIndex.indexOf(j) == -1;
                            var r2 = that.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r3 = that.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r4 = that.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r5 = that.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
                            //var r6 = that.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r7 = that.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
                            var r8 = that.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r9 = that.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r10 = that.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r11 = that.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r12 = that.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
                            var r13 = that.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;
                            var rt = r2 && r3 && r4 && r5 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
                            if ((d == "Sin asignar" && row["alarma"] == "" && row['idProcesos_Tipo'] == 6) || (row["alarma"] == d && row['idProcesos_Tipo'] == 6)) {
                              that.alarmasNoSeleccionadasIndex.splice(that.alarmasNoSeleccionadasIndex.indexOf(j), 1);
                              if (rt) row["oculto"] = 0;
                            }
                          });

                          break;
                        }
                      }

                      if (!disable) {
                        that.cargarDatosInforme();
                        that.updateLeyenda();
                      }

                    }
                  },
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutAlarmasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            } else { // si esta excluido unicamente se crea onclick en data
              this.chartDonutAlarmasCantidadLineas[i] = c3.generate({
                bindto: '#chartDonutAlarmasCantidadLineas_' + i,
                data: {
                  xs: xValue,
                  columns: information.columns,
                  names: information.names,
                  onclick: function (d, any, indexExcluido = index) {
                    that.chartDonutAlarmasCantidadLineas[indexExcluido].hide(d.id);
                  }
                },
                color: {
                  pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
                },
                axis: {
                  x: {
                    type: 'timeseries',
                    tick: {
                      format: function (x) {
                        if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                        else if (that.tipoAgrupado == 1) {
                          var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                          return weekNumber;
                        } else {
                          return that.dateTo_Mes_YYYY(x);
                        }
                      }
                    }
                  },
                  y: {
                    padding: { right: 0, bottom: 0, left: 0 },
                    /*tick: {
                      values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400, 97200],
                      format: function (d) {
                        const sec = parseInt(d, 10);
                        let hours = Math.floor(sec / 3600);
                        let minutes = Math.floor((sec - (hours * 3600)) / 60);
                        let seconds = sec - (hours * 3600) - (minutes * 60);
                        return hours
                      }
                    }*/
                  }
                },
                transition: {
                  duration: this.tiempoTransition
                },
                grid: {
                  y: {
                    show: true
                  }
                }
              });
              d3.selectAll("#chartDonutAlarmasCantidadLineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
            }
          }
          i++;
        }
      });
    });
  }

  cargarOEELineas(information: any, xValue) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }
    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEELineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            information = this.dataOEELineas;
          }
          if (!this.vinculacion || (this.vinculacion && !this.oeeLineasExcluidos[i])) {
            this.chartDonutOEELineas[i] = c3.generate({
              bindto: '#chartDonutOEELineas_' + i,
              data: {
                xs: xValue,
                columns: information.columns,
                //type: 'area', 'area-spline',
                //type: 'line',
                //groups: [groups],
                names: information.names
              },
              color: {
                pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
              },
              axis: {
                x: {
                  type: 'timeseries',
                  tick: {
                    format: function (x) {
                      if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                      else if (that.tipoAgrupado == 1) {
                        var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                        return weekNumber;
                      } else {
                        return that.dateTo_Mes_YYYY(x);
                      }
                    }
                  }
                },
                y: {
                  min: 0,
                  max: 100,
                  tick: {
                    format: function (value) {
                      return d3.format('.0%')(value / 100)
                    },
                    outer: false
                  },
                  padding: { top: 10, bottom: 0 }
                }
              },
              transition: {
                duration: this.tiempoTransition
              },
              grid: {
                y: {
                  show: true
                }
              }
            });
            d3.selectAll("#chartDonutOEELineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
          }
          i++;
        }
      });
    });
  }

  cargarOEE1Lineas(information: any, xValue) {

    if (Object.keys(xValue).length == 0) {
      xValue = 'x';
    }
    var that = this;
    var i = 0;
    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'OEE1Lineas') {

          // si hay filtrado propio la informacion hay que volver a preprocesarla para hacer el filtrado
          if (col.filtrarMaquinas[0] != "-1" || col.filtrarOperarios[0] != "-1") {
            this.cargarDatosConFiltroPropio(col.filtrarMaquinas, col.filtrarOperarios, col.contenido.tipo);
            information = this.dataOEE1Lineas;
          }

          if (!this.vinculacion || (this.vinculacion && !this.oee1LineasExcluidos[i])) {
            this.chartDonutOEE1Lineas[i] = c3.generate({
              bindto: '#chartDonutOEE1Lineas_' + i,
              data: {
                xs: xValue,
                columns: information.columns,
                //type: 'area', 'area-spline',
                //type: 'line',
                //groups: [groups],
                names: information.names
              },
              color: {
                pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
              },
              axis: {
                x: {
                  type: 'timeseries',
                  tick: {
                    format: function (x) {
                      if (that.tipoAgrupado == 0) return that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x));
                      else if (that.tipoAgrupado == 1) {
                        var weekNumber = that.translate.instant('semana') + ' ' + that.getNumberOfWeek(new Date(x)) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(x)) + ')';
                        return weekNumber;
                      } else {
                        return that.dateTo_Mes_YYYY(x);
                      }
                    }
                  }
                },
                y: {
                  min: 0,
                  max: 100,
                  tick: {
                    format: function (value) {
                      return d3.format('.0%')(value / 100)
                    },
                    outer: false
                  },
                  padding: { top: 10, bottom: 0 }
                }
              },
              transition: {
                duration: this.tiempoTransition
              },
              grid: {
                y: {
                  show: true
                }
              }
            });
            d3.selectAll("#chartDonutOEE1Lineas_" + i + " .c3-chart-arcs path").style("stroke-width", "0px");
          }
          i++;
        }
      });
    });
  }

  //#endregion END GRAFICO

  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnseñar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnseñar.push(['Otros', totalOtros]);
      return arrayParaEnseñar;
    }
  }

  public cortarLeyenda(inputText, trimmed = false) {

    var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var width = context.measureText(inputText).width;

    if (width > 160) {
      return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
    } else if (trimmed) {
      return inputText + "...";
    } else {
      return inputText;
    }

  }

  convertToDate(data) {
    var fechaF = new Date(data['fechafin']);
    var fechaI = new Date(data['fechaini']);
    var diff = (fechaF.getTime() - fechaI.getTime()) / 1000;
    return diff;
  }

  cargarDatosInforme() {
    this.vinculacion = true;

    this.cargarDatos();
    // this.prepararDatosOEE();
    this.cargarDatosTablas();

  }

  updateLeyenda(isTabla = false) {
    // actualizar leyenda de todos los graficos de perdidas
    for (var i = 0; i < this.chartDonutParadas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.perdidasExcluidas[i]))
        for (var j = 0; j < this.perdidasNoSeleccionadas.length; j++) {
          this.chartDonutParadas[i].hide(this.perdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutPerdidasLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.perdidasLineasExcluidas[i]))
        for (var j = 0; j < this.perdidasNoSeleccionadas.length; j++) {
          this.chartDonutPerdidasLineas[i].hide(this.perdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutPerdidasCantidadLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.perdidasLineasCantidadExcluidas[i]))
        for (var j = 0; j < this.perdidasNoSeleccionadas.length; j++) {
          this.chartDonutPerdidasCantidadLineas[i].hide(this.perdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartPerdidasOEE.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.perdidasOeeExcluidas[i]))
        for (var j = 0; j < this.perdidasOEENoSeleccionadas.length; j++) {
          this.chartPerdidasOEE[i].hide(this.perdidasOEENoSeleccionadas[j]);
        }
    }
    // subperdidas
    for (var i = 0; i < this.chartDonutSubperdidas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.subperdidasExcluidas[i]))
        for (var j = 0; j < this.subperdidasNoSeleccionadas.length; j++) {
          this.chartDonutSubperdidas[i].hide(this.subperdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutSubperdidasLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.subperdidasLineasExcluidas[i]))
        for (var j = 0; j < this.subperdidasNoSeleccionadas.length; j++) {
          this.chartDonutSubperdidasLineas[i].hide(this.subperdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutSubperdidasCantidadLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.subperdidasLineasCantidadExcluidas[i]))
        for (var j = 0; j < this.subperdidasNoSeleccionadas.length; j++) {
          this.chartDonutSubperdidasCantidadLineas[i].hide(this.subperdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartSubperdidasOEE.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.subperdidasOeeExcluidas[i]))
        for (var j = 0; j < this.subperdidasOEENoSeleccionadas.length; j++) {
          this.chartSubperdidasOEE[i].hide(this.subperdidasOEENoSeleccionadas[j]);
        }
    }

    // grupo perdidas
    for (var i = 0; i < this.chartDonutGrupoPerdidas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasExcluidas[i]))
        for (var j = 0; j < this.grupoPerdidasNoSeleccionadas.length; j++) {
          this.chartDonutGrupoPerdidas[i].hide(this.grupoPerdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutGrupoPerdidasLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasLineasExcluidas[i]))
        for (var j = 0; j < this.grupoPerdidasNoSeleccionadas.length; j++) {
          this.chartDonutGrupoPerdidasLineas[i].hide(this.grupoPerdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutGrupoPerdidasCantidadLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasLineasCantidadExcluidas[i]))
        for (var j = 0; j < this.grupoPerdidasNoSeleccionadas.length; j++) {
          this.chartDonutGrupoPerdidasCantidadLineas[i].hide(this.grupoPerdidasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartGrupoPerdidasOEE.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.grupoPerdidasOeeExcluidas[i]))
        for (var j = 0; j < this.grupoPerdidasOEENoSeleccionadas.length; j++) {
          this.chartGrupoPerdidasOEE[i].hide(this.grupoPerdidasOEENoSeleccionadas[j]);
        }
    }

    // update leyenda de resumen
    for (var i = 0; i < this.chartDonutResumenSemana.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.resumenSemanaExcluidos[i]))
        for (var j = 0; j < this.resumenNoSeleccionado.length; j++) {
          this.chartDonutResumenSemana[i].hide(this.resumenNoSeleccionado[j]);
        }
    }
    for (var i = 0; i < this.chartDonutResumenSemanaLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.resumenSemanaLineasExcluidos[i]))
        for (var j = 0; j < this.resumenNoSeleccionado.length; j++) {
          this.chartDonutResumenSemanaLineas[i].hide(this.resumenNoSeleccionado[j]);
        }
    }
    this.chartDonutResumenSemanaSubestados.forEach(element => {
      if (!this.vinculacion || (this.vinculacion && !this.resumenSemanaSubestadosExcluidos[i]))
        this.resumenSubestadosNoSeleccionado.forEach(row => {
          element.hide(row);
        });
    });

    // update leyenda de alarmas
    for (var i = 0; i < this.chartDonutGravedadAlarmas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.dobleAlarmasExcluidas[i]))
        for (var j = 0; j < this.alarmasNoSeleccionadas.length; j++) {
          this.chartDonutGravedadAlarmas[i].hide(this.alarmasNoSeleccionadas[j]);
        }
    }

    // update leyenda de alarmas
    for (var i = 0; i < this.chartDonutAlarmas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.alarmasExcluidas[i]))
        for (var j = 0; j < this.alarmasNoSeleccionadas.length; j++) {
          this.chartDonutAlarmas[i].hide(this.alarmasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutTipoAlarmas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.dobleAlarmasExcluidas[i]))
        for (var j = 0; j < this.alarmasNoSeleccionadas.length; j++) {
          this.chartDonutTipoAlarmas[i].hide(this.alarmasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutAlarmasLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.alarmasLineasExcluidas[i]))
        for (var j = 0; j < this.alarmasNoSeleccionadas.length; j++) {
          this.chartDonutAlarmasLineas[i].hide(this.alarmasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutAlarmasCantidadLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.alarmasLineasCantidadExcluidas[i]))
        for (var j = 0; j < this.alarmasNoSeleccionadas.length; j++) {
          this.chartDonutAlarmasCantidadLineas[i].hide(this.alarmasNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartAlarmasOEE.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.alarmasOeeExcluidas[i]))
        for (var j = 0; j < this.alarmasOEENoSeleccionadas.length; j++) {
          this.chartAlarmasOEE[i].hide(this.alarmasOEENoSeleccionadas[j]);
        }
    }

    // update leyenda de mantenimientos
    for (var i = 0; i < this.chartDonutMantenimientos.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.mantenimientosExcluidos[i]))
        for (var j = 0; j < this.mantenimientosNoSeleccionadas.length; j++) {
          this.chartDonutMantenimientos[i].hide(this.mantenimientosNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutMantenimientosLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.mantenimientosLineasExcluidos[i]))
        for (var j = 0; j < this.mantenimientosNoSeleccionadas.length; j++) {
          this.chartDonutMantenimientosLineas[i].hide(this.mantenimientosNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartDonutMantenimientosCantidadLineas.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.mantenimientosLineasCantidadExcluidos[i]))
        for (var j = 0; j < this.mantenimientosNoSeleccionadas.length; j++) {
          this.chartDonutMantenimientosCantidadLineas[i].hide(this.mantenimientosNoSeleccionadas[j]);
        }
    }
    for (var i = 0; i < this.chartMantenimientoOEE.length; i++) {
      if (!this.vinculacion || (this.vinculacion && !this.mantenimientosOeeExcluidos[i]))
        for (var j = 0; j < this.mantenimientosOEENoSeleccionadas.length; j++) {
          this.chartMantenimientoOEE[i].hide(this.mantenimientosOEENoSeleccionadas[j]);
        }
    }

    // update seleccionados de las tablas
    if (!isTabla) {
      var rowIndex = 0;
      this.formato.forEach(r => {
        var colIndex = 0;
        r.forEach(c => {
          if (c.contenido.tipo == 'grid' && c.contenido.selectedData.length != 0) {
            this.filtrarGrid(c.contenido.selectedData, rowIndex, colIndex);
          }
          colIndex++;
        });
        rowIndex++;
      });
    }
  }

  segundosAFormatoHora(tiempo: number) {
    var isNegativo = false;
    if (tiempo < 0) {
      isNegativo = true;
      tiempo = Math.abs(tiempo);
    }

    if (tiempo < 60 * 60 * 64) {
      var horas = this.myFunctions.addZero(Math.floor(tiempo / 3600));
      var minutos = this.myFunctions.addZero(Math.floor((tiempo % 3600) / 60));
      var segundos = this.myFunctions.addZero(Math.floor(((tiempo % 3600) % 60) / 1));
      var formatoHora = horas + ":" + minutos + ":" + segundos;
    } else {
      var dias = Math.floor((tiempo / 3600) / 24);
      var horas = this.myFunctions.addZero(Math.floor((tiempo / 3600) % 24));
      var formatoHora = dias + "d " + horas + "h"
    }

    if (isNegativo)
      return "-" + formatoHora;
    else
      return formatoHora;
  }

  //#region  VINCULACION TABLAS

  selectData(event, row, col) {
    // actualizar las filas seleccionadas
    event.selectedRows.forEach(element => {
      if (!(element in this.formato[row][col].contenido.selectedDataInformation))
        this.formato[row][col].contenido.selectedDataInformation.push(element);
    });
    // actualizar las filar desselecionadas
    event.deselectedRows.forEach(element => {
      this.formato[row][col].contenido.selectedDataInformation = this.formato[row][col].contenido.selectedDataInformation.filter(x => x.index != element.index);
    });
    this.formato[row][col].contenido.filtrado = false;
    this.formato[row][col].contenido.limpiarFiltroActivado = true;
  }

  filtrarGrid(selectedData, rowFormato, colFormato, filtrar = false) {

    // esto unicamente se hace si se ha dado al boton filtrar
    if (filtrar)
      this.formato[rowFormato][colFormato].contenido.filtrado = true;

    // unicamente filtrar los datos de la tabla si se ha dado al boton filtrar
    if (this.formato[rowFormato][colFormato].contenido.filtrado) {

      // obtener las columnas que se deben tener en cuenta ADIB: ['cliente', 'numeroOF']
      var nombreColumnas = []
      this.formato[rowFormato][colFormato].cols.forEach(element => {
        nombreColumnas.push(element.columna);
        console.log(element);
      });

      // obtener el contenido seleccionado ADIB [['CAF'], ['000555']]
      var valoresColumnasSeleccionadas = [];
      var i = 0;
      nombreColumnas.forEach(row => {
        valoresColumnasSeleccionadas.push([]);
        this.formato[rowFormato][colFormato].contenido.selectedDataInformation.forEach(element => {
          valoresColumnasSeleccionadas[i].push(element.dataItem[row]);
        });
        i++;
      });

      // procesar la informacion. Si no hay elementos seleccionados se ensenna toda la informacion, sino unicamente la informacion seleccionada
      var indexData = 0;
      if (this.formato[rowFormato][colFormato].contenido.selectedDataInformation.length != 0) {
        this.data.forEach(element => {
          indexData++;
          var i = 0;
          var oculto = false;
          var valoresColumnas = [];
          // obtener unicamente la informacion necesaria
          nombreColumnas.forEach(col => {
            valoresColumnas.push(element[col]);
          });
          // comparar si la informacion esta seleccionada
          var index = [];
          var j = -1;
          while ((j = valoresColumnasSeleccionadas[0].indexOf(valoresColumnas[0], j + 1)) != -1) {
            index.push(j);
          }
          if (index.length != 0) {
            index.every(ind => {
              oculto = false;
              i = 0;
              nombreColumnas.every(val => {
                if (element[val] != valoresColumnasSeleccionadas[i][ind] && !val.includes("tiempo") && !val.includes("cantidad")) {
                  oculto = true;
                  return false;
                }
                i++;
                return true;
              });
              // si no esta oculto significa que se debe ensennar la informacion por lo que no se debe seguir mirando
              if (oculto)
                return true;
              else
                return false;
            });
          } else {
            oculto = true;
          }

          // asignar si esta oculto o no
          if (oculto)
            element.oculto = oculto;
          else {
            if (this.comprobarOculto(indexData) && this.comprobarOcultoGraficos(indexData))
              element.oculto = oculto;
          }
        });
      }

      this.cargarDatosInforme();
      this.updateLeyenda(true);
    }
  }

  limpiarFiltroGrid(selectedData, row, col) {

    // if (selectedData.length != 0) {
    // deseleccionar todas las filas
    this.formato[row][col].contenido.selectedDataInformation = [];
    this.formato[row][col].contenido.selectedData = [];
    this.formato[row][col].contenido.filtrado = false;
    this.formato[row][col].contenido.limpiarFiltroActivado = false;

    //#region  desfiltrar todos los datos de las tablas
    var dataIndex = 0;
    this.data.forEach(element => {
      dataIndex++;
      if (this.comprobarOcultoGraficos(dataIndex))
        element.oculto = false;
    });
    //#endregion

    var rowIndex = 0;
    // si todas las filas de las tablas estan deseleccionadas
    var todosDeseleccionados = true;
    this.formato.forEach(r => {
      var colIndex = 0;
      r.forEach(c => {
        if (c.contenido.tipo == 'grid' && c.contenido.filtrado) {
          this.filtrarGrid(c.contenido.selectedData, rowIndex, colIndex);
          todosDeseleccionados = false;
        }
        colIndex++;
      });
      rowIndex++;
    });

    if (todosDeseleccionados) {
      var dataIndex = 0;
      this.data.forEach(element => {
        dataIndex++;
        if (this.comprobarOcultoGraficos(dataIndex))
          element.oculto = false;
      });
      this.cargarDatosInforme();
      this.updateLeyenda(true);
    }
    // }

  }

  // con esta funcion se comprueba que la fila de la tabla no este activada en ningun otro sitio
  comprobarOculto(j) {

    // comprobar que anteriormente la fila no esta activada en las tablas
    var rTaula = true;
    var rowCount = 0;
    var colCount = 0;
    this.formato.every(row => {
      colCount = 0;
      row.every(col => {
        if (col.contenido.tipo == 'grid' && col.contenido.filtrado) {
          // si un id esta relacionado, todos los 'idsRelacionados' tambien
          col.contenido.selectedData.every(element => {
            var d = col.contenido.data.filter(f => f.id == element)[0];
            if (d == undefined || !d?.idsRelacionados.includes(j - 1))
              rTaula = false;
            return rTaula;
          });

        } else {
          rTaula = true;
        }
        colCount++;
        return rTaula;
      });
      rowCount++;
      return rTaula;
    });

    var rt = rTaula;
    return rt;
  }
  comprobarOcultoGraficos(j) {

    // comprobar que anteriormente la fila no esta desactivada en los graficos
    var r1 = this.resumenNoSeleccionadoIndex.indexOf(j) == -1;
    var r2 = this.grupoPerdidasNoSeleccionadasIndex.indexOf(j) == -1;
    var r3 = this.perdidasNoSeleccionadasIndex.indexOf(j) == -1;
    var r4 = this.subperdidasNoSeleccionadasIndex.indexOf(j) == -1;
    var r5 = this.mantenimientosNoSeleccionadasIndex.indexOf(j) == -1;
    var r6 = this.alarmasNoSeleccionadasIndex.indexOf(j) == -1;
    var r7 = this.gravedadAlarmasNoSeleccionadasIndex.indexOf(j) == -1;
    var r8 = this.perdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
    var r9 = this.subperdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
    var r10 = this.grupoPerdidasOEENoSeleccionadasIndex.indexOf(j) == -1;
    var r11 = this.alarmasOEENoSeleccionadasIndex.indexOf(j) == -1;
    var r12 = this.mantenimientosOEENoSeleccionadasIndex.indexOf(j) == -1;
    var r13 = this.resumenSubestadosNoSeleccionadoIndex.indexOf(j) == -1;

    var rt = r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13;
    return rt;
  }
  limpiarVinculaciones() {
    this.alarmasNoSeleccionadas = [];
    this.mantenimientosNoSeleccionadas = [];
    this.gravedadAlarmasNoSeleccionadas = [];
    this.perdidasNoSeleccionadas = [];
    this.subperdidasNoSeleccionadas = [];
    this.grupoPerdidasNoSeleccionadas = [];
    this.resumenNoSeleccionado = [];
    this.resumenSubestadosNoSeleccionado = [];
    this.disponibilidadNoSeleccionadas = [];
    this.perdidasOEENoSeleccionadas = [];
    this.subperdidasOEENoSeleccionadas = [];
    this.grupoPerdidasOEENoSeleccionadas = [];
    this.alarmasOEENoSeleccionadas = [];
    this.mantenimientosOEENoSeleccionadas = [];

    this.alarmasNoSeleccionadasIndex = [];
    this.mantenimientosNoSeleccionadasIndex = [];
    this.gravedadAlarmasNoSeleccionadasIndex = [];
    this.perdidasNoSeleccionadasIndex = [];
    this.subperdidasNoSeleccionadasIndex = [];
    this.grupoPerdidasNoSeleccionadasIndex = [];
    this.resumenNoSeleccionadoIndex = [];
    this.resumenSubestadosNoSeleccionadoIndex = [];
    this.disponibilidadNoSeleccionadasIndex = [];
    this.perdidasOEENoSeleccionadasIndex = [];
    this.subperdidasOEENoSeleccionadasIndex = [];
    this.grupoPerdidasOEENoSeleccionadasIndex = [];
    this.alarmasOEENoSeleccionadasIndex = [];
    this.mantenimientosOEENoSeleccionadasIndex = [];
    this.operacionesTablasNoSeleccionadasIndex = [];

    this.formato.forEach(row => {
      row.forEach(col => {
        if (col.contenido.tipo == 'grid') {
          col.contenido.selectedData = [];
          col.contenido.selectedDataInformation = [];
        }
      });
    })
  }

  //#endregion

  //#region FILTRO
  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translate.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            // fechaFin: this.myFunctions.getDateNow(),
            fechaIni: this.fechaInicio,
            fechaFin: this.fechaFin,
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        // fechaFin: this.myFunctions.getDateNow(),
        fechaIni: this.fechaInicio,
        fechaFin: this.fechaFin,
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        tipoNoEditable: false,
        tipoNoVisible: false
      })
    }
  }
  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        // fechaFin: this.myFunctions.getDateNow(),
        fechaIni: this.fechaInicio,
        fechaFin: this.fechaFin,
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        tipoNoEditable: false,
        tipoNoVisible: false
      })
      this.actualizarVisible = true;
    }
  }
  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.limpiarVinculaciones();
    //this.cargarInformacionGraficos();
    this.loadingPerdidas = true;
    // var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7));
    // var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow());
    var fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(this.fechaInicio) + "T00:00:00Z";
    var fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(this.myFunctions.dateAddDays(this.fechaFin, 1)) + "T00:00:00Z";

    this.InformePersonalizadoService.getAll(fechaInicio, fechaFin).subscribe(
      json => {
        this.data = this.juntarTablas(json);

        if (this.data != null) { // hau da datu basean errorerik baldin badago panelan loading-a ez agertzeko gehiago
          this.data.forEach(element => {
            element['oculto'] = 0;

            // preparar las fechas
            element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
            element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
            element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
            element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
            element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
            element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
          });
          this.original = this.myFunctions.copy(this.data);
        } else this.data = [];

        this.loadingPerdidas = false;
        this.vinculacion = false;

        // ACTUALIZAR INFORMACION PARA COMBOS DE FILTRO
        this.dataFiltro = this.data;

        this.cargarInformacionGraficos();
      });

  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    //this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo(): CompositeFilterDescriptor {
    var em: CompositeFilterDescriptor = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  // filtroToKendo_recursivo(jFiltro): CompositeFilterDescriptor {
  //   // es una linea o es un grupo?
  //   if (jFiltro.group != undefined) {
  //     // GRUPO
  //     //variables para crear la estructura final
  //     var filtro = [];
  //     var logica: string = "and";
  //     if (jFiltro.logic.id == 1)
  //       logica = "or"
  //     //por cada grupo
  //     jFiltro.group.forEach(
  //       linea => {
  //         var newRow = this.filtroToKendo_recursivo(linea);
  //         if (newRow != undefined && newRow != "ERROR")
  //           filtro.push(newRow);
  //       });
  //     return { logic: logica, filters: filtro };
  //   }
  //   else if (jFiltro.columna != undefined) {
  //     // LINEA
  //     var jRow = {} as CompositeFilterDescriptor;
  //     // DATE -
  //     if (jFiltro.columna.tipo == 'date') {
  //       if (jFiltro.operator.dobleValor) {
  //         var jSubRow1 = {};
  //         var jSubRow2 = {};
  //         jSubRow1["field"] = jFiltro.columna.field;
  //         jSubRow2["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 7) {
  //           jRow["logic"] = 'and';
  //           jSubRow1["operator"] = "gte"
  //           jSubRow2["operator"] = "lte"
  //         }
  //         else if (jFiltro.operator.id == 8) {
  //           jRow["logic"] = 'or';
  //           jSubRow1["operator"] = "lt"
  //           jSubRow2["operator"] = "gt"
  //         }
  //         jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
  //         jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

  //         var subFiltro = [];
  //         subFiltro.push(jSubRow1);
  //         subFiltro.push(jSubRow2);
  //         jRow["filters"] = subFiltro;
  //       }
  //       else {
  //         jRow["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "lt";
  //         }
  //         else if (jFiltro.operator.id == 2) {
  //           jRow["operator"] = "lte";
  //         }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "gt";
  //         }
  //         else if (jFiltro.operator.id == 4) {
  //           jRow["operator"] = "gte";
  //         }
  //         else if (jFiltro.operator.id == 5) {
  //           jRow["operator"] = "eq";
  //         }
  //         else if (jFiltro.operator.id == 6) {
  //           jRow["operator"] = "neq";
  //         }
  //         jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
  //       }
  //     }
  //     // DATETIME -
  //     else if (jFiltro.columna.tipo == 'dateTime') {
  //       // public opcionDateTime = [
  //       //   // { id: 0, nombre: ' ', tipo: '' },
  //       //   { id: 1, nombre: '<', dobleValor: false },
  //       //   { id: 2, nombre: '<=', dobleValor: false },
  //       //   { id: 3, nombre: '>', dobleValor: false },
  //       //   { id: 4, nombre: '>=', dobleValor: false },
  //       //   { id: 5, nombre: '=', dobleValor: false },
  //       //   { id: 6, nombre: '!=', dobleValor: false }
  //       // ];
  //       if (jFiltro.operator.dobleValor) {
  //         // no existe este caso por ahora
  //       }
  //       else {
  //         jRow["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "lt";
  //         }
  //         else if (jFiltro.operator.id == 2) {
  //           jRow["operator"] = "lte";
  //         }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "gt";
  //         }
  //         else if (jFiltro.operator.id == 4) {
  //           jRow["operator"] = "gte";
  //         }
  //         else if (jFiltro.operator.id == 5) {
  //           jRow["operator"] = "eq";
  //         }
  //         else if (jFiltro.operator.id == 6) {
  //           jRow["operator"] = "neq";
  //         }
  //         jRow["value"] = jFiltro.fechaIni;
  //       }
  //     }
  //     // COMBO ESTRICTO -
  //     else if (jFiltro.columna.tipo == 'comboEstrincto') {
  //       // public opcionComboEstricto = [
  //       //   // { id: 0, nombre: ' ', tipo: '' },
  //       //   { id: 1, nombre: 'Uniselect', dobleValor: false },
  //       //   { id: 2, nombre: 'Multiselect', dobleValor: true },
  //       //   { id: 3, nombre: 'No select', dobleValor: false },
  //       //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
  //       // ];
  //       if (jFiltro.operator.dobleValor) {
  //         if (jFiltro.operator.id == 2) {
  //           jRow["logic"] = 'or';
  //         }
  //         else if (jFiltro.operator.id == 4) {
  //           jRow["logic"] = 'and';
  //         }
  //         var subFiltro = [];
  //         jFiltro.comboSelecteds.forEach(
  //           seleccionado => {
  //             var jSubRow1 = {};
  //             jSubRow1["field"] = jFiltro.columna.field;
  //             if (jFiltro.operator.id == 2) {
  //               jSubRow1["operator"] = "eq";
  //             }
  //             else if (jFiltro.operator.id == 4) {
  //               jSubRow1["operator"] = "neq";
  //             }
  //             jSubRow1["value"] = seleccionado.id;
  //             subFiltro.push(jSubRow1);
  //           });
  //         jRow["filters"] = subFiltro;
  //       }
  //       else {
  //         jRow["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "eq";
  //         }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "neq";
  //         }
  //         if (jFiltro.comboSelected != undefined)
  //           jRow["value"] = jFiltro.comboSelected.id;
  //       }
  //     }
  //     // COMBO FLEXIBLE -
  //     else if (jFiltro.columna.tipo == 'comboFlexible') {
  //       // public opcionComboFlexible = [
  //       //   // { id: 0, nombre: ' ', tipo: '' },
  //       //   { id: 1, nombre: 'Uniselect', dobleValor: false },
  //       //   { id: 2, nombre: 'Multiselect', dobleValor: true },
  //       //   { id: 3, nombre: 'No select', dobleValor: false },
  //       //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
  //       //   { id: 5, nombre: 'Empieza por', dobleValor: false },
  //       //   { id: 6, nombre: 'No empieza por', dobleValor: false },
  //       //   { id: 7, nombre: 'Acaba por', dobleValor: false },
  //       //   { id: 8, nombre: 'No acaba por', dobleValor: false },
  //       //   { id: 9, nombre: 'Contiene', dobleValor: false },
  //       //   { id: 10, nombre: 'No contiene', dobleValor: false },
  //       //   { id: 11, nombre: 'Igual que', dobleValor: false },
  //       //   { id: 12, nombre: 'Diferente a', dobleValor: false }
  //       // ];
  //       if (jFiltro.operator.dobleValor) {
  //         if (jFiltro.operator.id == 2) {
  //           jRow["logic"] = 'or';
  //         }
  //         else if (jFiltro.operator.id == 4) {
  //           jRow["logic"] = 'and';
  //         }
  //         var subFiltro = [];
  //         jFiltro.comboSelecteds.forEach(
  //           seleccionado => {
  //             var jSubRow1 = {};
  //             jSubRow1["field"] = jFiltro.columna.field;
  //             if (jFiltro.operator.id == 2) {
  //               jSubRow1["operator"] = "eq";
  //             }
  //             else if (jFiltro.operator.id == 4) {
  //               jSubRow1["operator"] = "neq";
  //             }
  //             jSubRow1["value"] = seleccionado.id;
  //             subFiltro.push(jSubRow1);
  //           });
  //         jRow["filters"] = subFiltro;
  //       }
  //       else {
  //         jRow["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "eq";
  //           if (jFiltro.comboSelected != undefined)
  //             jRow["value"] = jFiltro.comboSelected.id;
  //         }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "neq";
  //           if (jFiltro.comboSelected != undefined)
  //             jRow["value"] = jFiltro.comboSelected.id;
  //         }
  //         else if (jFiltro.operator.id == 5) {
  //           jRow["operator"] = "startswith";
  //           jRow["value"] = jFiltro.text;
  //         }
  //         // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
  //         //   jRow["operator"] = NO "startswith";
  //         //   jRow["value"] = jFiltro.text; 
  //         // }
  //         else if (jFiltro.operator.id == 7) {
  //           jRow["operator"] = "endswith";
  //           jRow["value"] = jFiltro.text;
  //         }
  //         // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
  //         //   jRow["operator"] = NO "endswith"; 
  //         //   jRow["value"] = jFiltro.text;
  //         // }
  //         else if (jFiltro.operator.id == 9) {
  //           jRow["operator"] = "contains";
  //           jRow["value"] = jFiltro.text;
  //         }
  //         else if (jFiltro.operator.id == 10) {
  //           jRow["operator"] = "doesnotcontain";
  //           jRow["value"] = jFiltro.text;
  //         }
  //         else if (jFiltro.operator.id == 11) {
  //           jRow["operator"] = "eq";
  //           jRow["value"] = jFiltro.text;
  //         }
  //         else if (jFiltro.operator.id == 12) {
  //           jRow["operator"] = "neq";
  //           jRow["value"] = jFiltro.text;
  //         }
  //       }
  //     }
  //     // CHECK -
  //     else if (jFiltro.columna.tipo == 'check') {
  //       // no es necesaria una opcion
  //       // if (jFiltro.operator.dobleValor) {
  //       //   // no existe este caso por ahora
  //       // }
  //       // else{
  //       jRow["field"] = jFiltro.columna.field;
  //       jRow["operator"] = "eq";
  //       jRow["value"] = jFiltro.check;
  //       // }        
  //     }
  //     // NUMERIC -
  //     else if (jFiltro.columna.tipo == 'numeric') {
  //       // public opcionNumericDecimal = [
  //       //   // { id: 0, nombre: ' ', tipo: '' },
  //       //   { id: 1, nombre: '<', dobleValor: false },
  //       //   { id: 2, nombre: '<=', dobleValor: false },
  //       //   { id: 3, nombre: '>', dobleValor: false },
  //       //   { id: 4, nombre: '>=', dobleValor: false },
  //       //   { id: 5, nombre: '=', dobleValor: false },
  //       //   { id: 6, nombre: '!=', dobleValor: false },
  //       //   { id: 7, nombre: 'Entre', dobleValor: true },
  //       //   { id: 8, nombre: 'No entre', dobleValor: true }
  //       // ];
  //       if (jFiltro.operator.dobleValor) {
  //         var jSubRow1 = {};
  //         var jSubRow2 = {};
  //         jSubRow1["field"] = jFiltro.columna.field;
  //         jSubRow2["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 7) {
  //           jRow["logic"] = 'and';
  //           jSubRow1["operator"] = "gte"
  //           jSubRow2["operator"] = "lte"
  //         }
  //         else if (jFiltro.operator.id == 8) {
  //           jRow["logic"] = 'or';
  //           jSubRow1["operator"] = "lt"
  //           jSubRow2["operator"] = "gt"
  //         }
  //         jSubRow1["value"] = jFiltro.numberMin;
  //         jSubRow2["value"] = jFiltro.numberMax;

  //         var subFiltro = [];
  //         subFiltro.push(jSubRow1);
  //         subFiltro.push(jSubRow2);
  //         jRow["filters"] = subFiltro;
  //       }
  //       else {
  //         jRow["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "lt";
  //         }
  //         else if (jFiltro.operator.id == 2) {
  //           jRow["operator"] = "lte";
  //         }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "gt";
  //         }
  //         else if (jFiltro.operator.id == 4) {
  //           jRow["operator"] = "gte";
  //         }
  //         else if (jFiltro.operator.id == 5) {
  //           jRow["operator"] = "eq";
  //         }
  //         else if (jFiltro.operator.id == 6) {
  //           jRow["operator"] = "neq";
  //         }
  //         jRow["value"] = jFiltro.numberMin;
  //       }
  //     }
  //     // DECIMAL -
  //     else if (jFiltro.columna.tipo == 'decimal') {
  //       // public opcionNumericDecimal = [
  //       //   // { id: 0, nombre: ' ', tipo: '' },
  //       //   { id: 1, nombre: '<', dobleValor: false },
  //       //   { id: 2, nombre: '<=', dobleValor: false },
  //       //   { id: 3, nombre: '>', dobleValor: false },
  //       //   { id: 4, nombre: '>=', dobleValor: false },
  //       //   { id: 5, nombre: '=', dobleValor: false },
  //       //   { id: 6, nombre: '!=', dobleValor: false },
  //       //   { id: 7, nombre: 'Entre', dobleValor: true },
  //       //   { id: 8, nombre: 'No entre', dobleValor: true }
  //       // ];
  //       if (jFiltro.operator.dobleValor) {
  //         var jSubRow1 = {};
  //         var jSubRow2 = {};
  //         jSubRow1["field"] = jFiltro.columna.field;
  //         jSubRow2["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 7) {
  //           jRow["logic"] = 'and';
  //           jSubRow1["operator"] = "gte"
  //           jSubRow2["operator"] = "lte"
  //         }
  //         else if (jFiltro.operator.id == 8) {
  //           jRow["logic"] = 'or';
  //           jSubRow1["operator"] = "lt"
  //           jSubRow2["operator"] = "gt"
  //         }
  //         jSubRow1["value"] = jFiltro.decimalMin;
  //         jSubRow2["value"] = jFiltro.decimalMax;

  //         var subFiltro = [];
  //         subFiltro.push(jSubRow1);
  //         subFiltro.push(jSubRow2);
  //         jRow["filters"] = subFiltro;
  //       }
  //       else {
  //         jRow["field"] = jFiltro.columna.field;
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "lt";
  //         }
  //         else if (jFiltro.operator.id == 2) {
  //           jRow["operator"] = "lte";
  //         }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "gt";
  //         }
  //         else if (jFiltro.operator.id == 4) {
  //           jRow["operator"] = "gte";
  //         }
  //         else if (jFiltro.operator.id == 5) {
  //           jRow["operator"] = "eq";
  //         }
  //         else if (jFiltro.operator.id == 6) {
  //           jRow["operator"] = "neq";
  //         }
  //         jRow["value"] = jFiltro.decimalMin;
  //       }
  //     }
  //     // STRING -
  //     else if (jFiltro.columna.tipo == 'string') {
  //       // public opcionString = [
  //       //   // { id: 0, nombre: ' ', tipo: '' },
  //       //   { id: 1, nombre: 'Empieza por', dobleValor: false },
  //       //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
  //       //   { id: 3, nombre: 'Acaba por', dobleValor: false },
  //       //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
  //       //   { id: 5, nombre: 'Contiene', dobleValor: false },
  //       //   { id: 6, nombre: 'No contiene', dobleValor: false },
  //       //   { id: 7, nombre: 'Igual que', dobleValor: false },
  //       //   { id: 8, nombre: 'Diferente a', dobleValor: false }
  //       // ];
  //       jRow["field"] = jFiltro.columna.field;
  //       if (jFiltro.operator.dobleValor) {
  //         // no existe este caso por ahora
  //       }
  //       else {
  //         if (jFiltro.operator.id == 1) {
  //           jRow["operator"] = "startswith";
  //         }
  //         // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
  //         //   jRow["operator"] = NO "startswith"; 
  //         // }
  //         else if (jFiltro.operator.id == 3) {
  //           jRow["operator"] = "endswith";
  //         }
  //         // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
  //         //   jRow["operator"] = NO "endswith"; 
  //         // }
  //         else if (jFiltro.operator.id == 5) {
  //           jRow["operator"] = "contains";
  //         }
  //         else if (jFiltro.operator.id == 6) {
  //           jRow["operator"] = "doesnotcontain";
  //         }
  //         else if (jFiltro.operator.id == 7) {
  //           jRow["operator"] = "eq";
  //         }
  //         else if (jFiltro.operator.id == 8) {
  //           jRow["operator"] = "neq";
  //         }
  //       }
  //       jRow["value"] = jFiltro.text;
  //     }

  //     // LINEA
  //     if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
  //       return jRow;
  //     }
  //     // GRUPO
  //     if (jRow["filters"] != undefined && jRow["logic"] != undefined)
  //       if (jRow["filters"].length > 0) {
  //         return jRow;
  //       }
  //     // else // filtro sin terminar! no es un filtro a tener en cuenta
  //     //   return {}
  //   }
  //   else {
  //     //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
  //     return "ERROR";
  //   }
  // }

  // DE NUESTRO FILTRO A SQL FILTER

  filtroToKendo_recursivo(jFiltro): CompositeFilterDescriptor {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: "or" | "and" = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR" as unknown as CompositeFilterDescriptor)
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      //var jRow : CompositeFilterDescriptor;

      var jRow = {} as CompositeFilterDescriptor;

      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          //jSubRow1["field"] = jFiltro.columna.field;
          //jSubRow2["field"] = jFiltro.columna.field;
          // jSubRow1["field"] = "fechaIniFiltro";
          // jSubRow2["field"] = "fechaFinFiltro";
          jSubRow1["field"] = "fechaTurnoFiltro";
          jSubRow2["field"] = "fechaTurnoFiltro";
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lt" // lte
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);

          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible' || jFiltro.columna.tipo == 'comboFlexibleOperarioMaquina') {
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
                if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10) jSubRow1["operator"] = "contains"; // Operario / Maquina
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
                if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10) jSubRow1["operator"] = "doesnotcontain"; // Operario / Maquina
              }
              jSubRow1["value"] = seleccionado.id;
              if (jFiltro.columna.id == 9) jSubRow1["value"] = seleccionado.nombre.split(" ").join(";"); // Operario
              if (jFiltro.columna.id == 10) jSubRow1["value"] = seleccionado.nombre; //Maquina
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
            if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
            if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR" as unknown as CompositeFilterDescriptor;
    }
  }

  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            this.fechaInicioFiltro = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
            this.fechaFinFiltro = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
        this.eligiendoFechas = false;
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          this.eligiendoFechas = false;
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
      this.eligiendoFechas = true;
    }
    else {
      row.mostrarCalendario = false;
      this.eligiendoFechas = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 7);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos90DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 3, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
      { nombreTurno: this.translate.instant("manana"), idTurno: 1 },
      { nombreTurno: this.translate.instant("tarde"), idTurno: 2 },
      { nombreTurno: this.translate.instant("noche"), idTurno: 3 }
    ];

    //OPERARIOS
    this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
      this.filtro_listaOperarios = data;
      this.filtro_listaOperarios.forEach(element => {
        element.id = element.id.toString();
      });
      this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario?.toLowerCase() > b.nombreOperario?.toLowerCase()) ? 1 : ((b.nombreOperario?.toLowerCase() > a.nombreOperario?.toLowerCase()) ? -1 : 0));

      r3 = true;
      if (r1 && r2 && r3) this.cargarConTodasLasRespuestas();
    });

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones?.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.filtro_listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones?.length == 0) && (enGrupo || idsGruposSelecteds?.length == 0));
    });

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
      (data: any) => {
        this.filtro_listaHerramientas = data;
        this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3) this.cargarConTodasLasRespuestas();
      });


    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        { id: 3, nombre: this.translate.instant('turno'), field: "tipoTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
        { id: 4, nombre: this.translate.instant('seccion'), field: "idSeccion", sqlfield: "m.idSeccion", tipo: 'comboEstrincto' },
        { id: 5, nombre: this.translate.instant('grupoMaquinas'), field: "idMaquinaGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
        { id: 6, nombre: this.translate.instant('maquina'), field: "idmaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
        { id: 7, nombre: this.translate.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
        { id: 8, nombre: this.translate.instant('of'), field: "numeroOF", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },
        { id: 9, nombre: this.translate.instant('cliente'), field: "cliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },
        { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },
        { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'comboFlexible' },
        { id: 12, nombre: this.translate.instant('parte'), field: "nombreParte", sqlfield: "do.nombreParte", tipo: 'comboFlexible' },
        { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
        { id: 14, nombre: this.translate.instant('terminado'), field: "operacionTerminada", sqlfield: "do.operacionTerminada", tipo: 'check' },
        { id: 15, nombre: this.translate.instant('colada'), field: "colada", sqlfield: "ho.colada", tipo: 'comboFlexible' },
        { id: 16, nombre: this.translate.instant('lote'), field: "lote", sqlfield: "ho.lote", tipo: 'comboFlexible' }
      ];

    r1 = true;
    if (r1 && r2 && r3) this.cargarConTodasLasRespuestas();

  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];
      // cargar los combos
      if (row.columna.id == 3) { // turnos
        var lag = [];
        dataFiltroLag.forEach(
          turno => {
            if (!lag.includes(turno.tipoTurno)) {
              lag.push(turno.tipoTurno);
            }
          });
        row.combo = this.turnosCombo.filter(f => (lag.includes(f.id)));
      }
      else if (row.columna.id == 4) { // seccion
        var lag = [];
        dataFiltroLag.forEach(
          seccion => {
            if (!lag.includes(seccion.seccion)) {
              lag.push(seccion.seccion);
            }
          });
        this.groupedSeccion.forEach(f => {
          f.items.forEach(element => {
            var aux = lag.includes(element['nombre']);
            f.items.filter(f => (lag.includes(element['nombre'])))
          });
        });
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        var lag = [];
        dataFiltroLag.forEach(
          grupo => {
            if (!lag.includes(grupo.maquinaGrupo)) {
              lag.push(grupo.maquinaGrupo);
            }
          });
        var g = this.grupos.filter(f => (lag.includes(f.nombre)));
        row.combo = g.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        var lag = [];
        dataFiltroLag.forEach(
          maquina => {
            if (!lag.includes(maquina.maquina)) {
              lag.push(maquina.maquina);
            }
          });
        var m = this.filtro_listaMaquinas.filter(f => (lag.includes(f.nombre)));
        row.combo = m.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // operarios 
        var lag = [];
        dataFiltroLag.forEach(
          operario => {
            if (!lag.includes(operario.idOperario)) {
              lag.push(operario.idOperario);
            }
          });
        var filtro = this.filtro_listaOperarios.filter(f => (lag.includes(f.numOperario.toString())));
        row.combo = filtro.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.numeroOF)) {
              lag.push(of.numeroOF);
              var js = { id: of.numeroOF, nombre: of.numeroOF };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.cliente)) {
              lag.push(cliente.cliente);
              var js = { id: cliente.cliente, nombre: cliente.cliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
              lag.push(operacion.nombreOperacion);
              var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      else if (row.columna.id == 15) { // colada FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          colada => {
            if (!lag.includes(colada.colada)) {
              lag.push(colada.colada);
              var js = { id: colada.colada, nombre: colada.colada };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 16) { // lote FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          lote => {
            if (!lag.includes(lote.lote)) {
              lag.push(lote.lote);
              var js = { id: lote.lote, nombre: lote.lote };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {

    this.loadingPerdidas = true;

    var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    var filtroCompleto: any = this.filtroToSQL(['hb.fechaTurno']); // este filtro sirve para hacer el segundo filtro

    this.limpiarVinculaciones();

    if (this.fechaInicioFiltro < this.fechaIniUltimaConsulta || this.fechaFinFiltro > this.fechaFinUltimaConsulta) { // hay que hacer la consulta    
      this.fechaIniUltimaConsulta = this.fechaInicioFiltro;
      this.fechaFinUltimaConsulta = this.fechaFinFiltro;

      this.InformePersonalizadoService.getAll(this.fechaInicioFiltro, this.fechaFinFiltro).subscribe(
        json => {
          this.data = this.juntarTablas(json);

          // ACTUALIZAR INFORMACION PARA COMBOS DE FILTRO
          this.dataFiltro = this.data;

          if (this.data != null) { // hau da datu basean errorerik baldin badago panelan loading-a ez agertzeko gehiago
            this.data.forEach(element => {
              element['oculto'] = 0;

              // preparar las fechas
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
              element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
            });
            this.original = this.myFunctions.copy(this.data);
          } else this.data = [];

          this.data = filterBy(this.data, this.filtroToKendo());
          this.loadingPerdidas = false;
          this.vinculacion = false;
          this.cargarInformacionGraficos();
        });
      this.status = true;
      this.actualizarVisible = false;
    } else { // no es necesario hacer la consulta

      this.data = filterBy(this.original, this.filtroToKendo());
      this.data.forEach(element => {
        element['oculto'] = 0;

        // preparar las fechas
        element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaini);
        element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
        element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechafin);
        element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
        element.fechaTurnoFiltro = this.myFunctions.sqlToJsDateT(element.fechaTurno);
        element.fechaTurnoFiltro = this.myFunctions.datetimeToSQL(element.fechaTurnoFiltro);
      });

      this.status = true;
      this.loadingPerdidas = false;
      this.vinculacion = false;
      this.actualizarVisible = false;
      this.cargarInformacionGraficos();
    }

  }
  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translate.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translate.instant('y') },
          group: []
        }
      ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
      // Historico Piezas:
      //   - fechaIni , fechaFin *fijo*
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

      // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
      var r1, r2 = false;
      // HISTORICO PIEZAS??
      /**/var IdHistorico = Number.parseInt(this.route.snapshot.params['idHistoricoPieza']);
      this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
          }
          r1 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
      // HISTORICO OPERACIONES??
      /**/var IdHistoricoOp = Number.parseInt(this.route.snapshot.params['idHistoricoOperacion']);
      this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
          }
          r2 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else {
      // Informe Proyectos:
      //   - fechaIni , fechaFin *fijo*
      //   - idcliente (posible extra)
      //   - idpieza (posible extra)
      //   - idof (posible extra)
      //   - terminados (posible extra)
      // FECHAS (INICIO, FIN)
      /**/this.fini = this.route.snapshot.params['fini'];
      /**/this.ffin = this.route.snapshot.params['ffin'];
      if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
        && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
      // OF
      /**/this.idof = Number.parseInt(this.route.snapshot.params['idof']);
      var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idof']);
      if (rowOFs.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            // fechaFin: this.myFunctions.getDateNow(),
            fechaIni: this.fechaInicio,
            fechaFin: this.fechaFin,
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
      }
      // CLIENTE
      /**/this.idcliente = Number.parseInt(this.route.snapshot.params['idcliente']);
      // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
      var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idcliente']);
      if (rowClientes.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 9, nombre: this.translate.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            // fechaFin: this.myFunctions.getDateNow(),
            fechaIni: this.fechaInicio,
            fechaFin: this.fechaFin,
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
      }
      // PIEZA
      /**/this.idpieza = Number.parseInt(this.route.snapshot.params['idpieza']);
      var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idpieza']);
      if (rowPiezas.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            // fechaFin: this.myFunctions.getDateNow(),
            fechaIni: this.fechaInicio,
            fechaFin: this.fechaFin,
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
      }
      // TERMINADO 
      // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
      // * solo lo voy a filtrar si es 1
      /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
      if (this.route.snapshot.params['terminados'] == '1') {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 14, nombre: this.translate.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' },
            operator: { id: 0, nombre: '' },
            // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            // fechaFin: this.myFunctions.getDateNow(),
            fechaIni: this.fechaInicio,
            fechaFin: this.fechaFin,
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          // fechaFin: this.myFunctions.getDateNow(),
          fechaIni: this.fechaInicio,
          fechaFin: this.fechaFin,
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable: false,
          tipoNoVisible: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translate.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translate.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          // fechaFin: this.myFunctions.getDateNow(),
          fechaIni: this.fechaInicio,
          fechaFin: this.fechaFin,
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          // fechaFin: this.myFunctions.getDateNow(),
          fechaIni: this.fechaInicio,
          fechaFin: this.fechaFin,
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
          operator: { id: 7, nombre: this.translate.instant('igualQue'), dobleValor: false },
          // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          // fechaFin: this.myFunctions.getDateNow(),
          fechaIni: this.fechaInicio,
          fechaFin: this.fechaFin,
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          // fechaFin: this.myFunctions.getDateNow(),
          fechaIni: this.fechaInicio,
          fechaFin: this.fechaFin,
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          // fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          // fechaFin: this.myFunctions.getDateNow(),
          fechaIni: this.fechaInicio,
          fechaFin: this.fechaFin,
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable: false,
          tipoNoVisible: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  //#endregion

  //#region GRID DE LAS TABLAS
  public onSelectAllChangeGrid(checkedState: SelectAllCheckboxState, row, col, keyId) {

    if (checkedState === 'checked') {

      this.formato[row][col].contenido.selectedDataInformation = [];
      this.formato[row][col].contenido.selectedData = [];
      this.formato[row][col].contenido.data.forEach(element => {
        this.formato[row][col].contenido.selectedDataInformation.push(element)
        this.formato[row][col].contenido.selectedData.push(element[keyId])
      });

    } else {
      this.formato[row][col].contenido.selectedDataInformation = [];
      this.formato[row][col].contenido.selectedData = [];
    }

  }
  public onChange(state: State, row, col): void {
    this.state = state;
    this.formato[row][col].contenido.dataView = process(this.formato[row][col].contenido.data, this.state);
  }
  //#endregion
}
