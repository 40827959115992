import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/eskola_cursoslectivos`;

@Injectable()
export class Eskola_CursosLectivosService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getById(idCurso: number) {
    return this.http.get<any>(`${baseUrl}/${idCurso}`);
  }

  create(id: number, nombre: string, inicio: string, fin: string, observaciones: string):Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/create`, { id, nombre, inicio, fin, observaciones }, { withCredentials: true });
  }

  update(id: number, nombre: string, inicio: String, fin: String, observaciones: string) {

    return this.http.post<JSON>(`${baseUrl}/update`, { id, nombre, inicio, fin, observaciones }, { withCredentials: true });
  }

  delete(id: number) {

    return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  }
  //HORARIOS
  getHorariosDelCurso(id_cursoLectivo:number){
    return this.http.post<JSON>(`${baseUrl}/get_horarios`, { id_cursoLectivo }, { withCredentials: true });
  }
  insertHorario(id_cursoLectivo:number, dia:number, horaInicio:Date, horaFin:Date, nuevo:boolean):Observable<any>{
    return this.http.post<JSON>(`${baseUrl}/insert_Horario`, { dia, id_cursoLectivo, horaInicio, horaFin, nuevo}, { withCredentials: true });
  }
  updateHorario(id: number, dia:number, horaInicio:Date, horaFin:Date) :Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/update_Horario`, { id, dia, horaInicio, horaFin}, { withCredentials: true });
  }
  deleteHorario(id: number) :Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/delete_Horario`, {id}, { withCredentials: true });
  }

  getHorasMaxyMin(id_cursoLectivo:number, dia:number){
    return this.http.post<JSON>(`${baseUrl}/get_horasMaxyMin`, { id_cursoLectivo, dia }, { withCredentials: true });
 
  }


}
