import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/validador`;

@Injectable()
export class ValidadorService {

  constructor(
    private http: HttpClient
  ) {
  }

  //SELECT 
  get(idMaquina: number, fecha: string,
      sEjecucion: string, sParada: string, sPreparacion: string, sMantenimiento: string, sAlarma: string, sApagada: string, sCanal: string,
      sNombrePrograma: string, sDescripcion: string, sOperario: string,
      sOF: string, sCliente: string, sRefPieza: string, sPieza: string, sOperacion: string, sOperacionNoEncontrada: string,
      sDuracion: string, sInicio: string, sFin: string, hoy: boolean ) {
      return this.http.post<JSON>(`${baseUrl}/Get`, {
      idMaquina, fecha,
      sEjecucion, sParada, sPreparacion, sMantenimiento, sAlarma, sApagada, sCanal,
      sNombrePrograma, sDescripcion, sOperario,
      sOF, sCliente, sRefPieza, sPieza, sOperacion, sOperacionNoEncontrada,
        sDuracion, sInicio, sFin, hoy
    }, { withCredentials: true });
  }

  update(dt: any, idMaquina: number, validado: boolean, fecha: string, arrayPerdidas: any) {
    return this.http.post<JSON>(`${baseUrl}/Update`, { dt, idMaquina, validado, fecha, arrayPerdidas }, { withCredentials: true });
  }

  getTiemposMicroParadas() {
    return this.http.post<JSON>(`${baseUrl}/getTiemposMicroParadas`, {}, { withCredentials: true });
  }

  Get_piezas_Nserie(idParte: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_piezas_Nserie`, { idParte }, { withCredentials: true });
  }

  asignarOperacionEndiferida(fechaInicio, fechaFin, piezasTerminadas, piezasAchatarradas, piezasApartadas, idOperacion, idPlanificado, idMaquina, numoperario, idProcesoTipo, idMantenimiento, idPlanificadoLargo, colada, nserie, lote, offline, terminado, idOf, idPieza, idParte) {
    return this.http.post<JSON>(`${baseUrl}/asignar_operacion_endiferida`, { fechaInicio, fechaFin, piezasTerminadas, piezasAchatarradas, piezasApartadas, idOperacion, idPlanificado, idMaquina, numoperario, idProcesoTipo, idMantenimiento, idPlanificadoLargo, colada, nserie, lote, offline, terminado, idOf, idPieza, idParte }, { withCredentials: true });
  }

}
