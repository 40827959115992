import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService, PlanificadorService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({ templateUrl: 'hmiOffline.html' })

export class HMIOfflineComponent implements OnInit {

  interval: any;
  interval2: any;
  intervalSegundos: any;
  idmaquina: any;
  maquina: any;
  user = this.usuariosService.userValue;

  dataProduccion: any;

  operarios: any = [];

  maquinasModel: any;
  marcasModel: any;

  datosProcesos: any;

  datosPlanificados: any;

  mantenimientoPorFecha: any;
  mantenimientoPorTiempo: any;
  loadingPorFecha: any;
  loadingPorTiempo: any;

  notas: any;

  resumenSemanaGrafico: any;

  //HELPERS
  private now: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate(), this.myFunctions.getDateNow().getHours(), this.myFunctions.getDateNow().getMinutes(), this.myFunctions.getDateNow().getSeconds());

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    private translateService: TranslateService,
    private maquinasService: MaquinasService,
    private planificadorService: PlanificadorService,
    private myFunctions: MyFunctions) {

    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('hmiOffline').toUpperCase();
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));

    this.maquina = {
      id: this.idmaquina,
      idmarca: 0,
      nombre: "",
      modelo: "",
      marca: "",
    };

    this.dibujarGraficoResumenSemana();
    this.cargarMaquinasModel();

  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarDatos();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarDatos();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarDatos();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarDatos();
      }
    }
  }

  dibujarGraficoResumenSemana() {
    this.resumenSemanaGrafico = c3.generate({
      bindto: '#resumenSemanaGrafico_offline',
      padding: {
        top: -30,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });
  }

  async dibujarGraficoResumenSemanaRegistro(registro) {

    var count = 0;
    while (document.getElementById("resumenSemanaGrafico_offline_" + registro.idRegistro) == null && count < 10) {
      await new Promise(r => setTimeout(r, 100));
      count++;
    }

    registro.resumenSemanaGrafico = c3.generate({
      bindto: "#resumenSemanaGrafico_offline_" + registro.idRegistro,
      padding: {
        top: -40,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });

    this.cargarResumenSemanaRegistro(registro);

  }

  cargarDatos() {

    this.cargarMaquina();
    this.cargarOperarios();
    this.cargarProduccion();
    this.cargarProcesos();
    this.cargarDatosNotas();
    this.cargarDatosMantenimientos();
    this.cargarPlanificados();

  }

  cargarMaquina() {

    var maquina = this.maquinasModel.find(x => x.id === this.idmaquina);

    this.maquina.nombre = this.maquinasModel.find(x => x.id === maquina.id).nombre;
    this.maquina.modelo = this.maquinasModel.find(x => x.id === maquina.id).imagenBase64;
    this.maquina.marca = this.marcasModel.find(x => x.id === maquina.idmarca).imagenBase64;

  }

  cargarOperarios() {

    this.hmiService.Get_ejecuciones_maquina_offline(this.idmaquina).subscribe((result) => {

      var datos: any = result;
      var count = 1;

      datos.forEach(function (registro) {

        registro.idRegistro = count;
        count++;

        registro.nPieza = registro.hechas + "/" + registro.cantidad;
        registro.ejecss = "estado-" + registro.procesos_Tipostr;

        var hanpasado = this.HanPasadoDias(new Date(Date.parse(registro.fechainicio)), this.myFunctions.getDateNow());
        var hapasadoTiempo = this.HanPasadoTiempo(new Date(Date.parse(registro.fechainicio)), this.myFunctions.getDateNow());
        if (hanpasado > 0) registro.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
        else registro.tiempopasado = hapasadoTiempo.toString();
        registro.segundospasados = Math.round((new Date(Date.now()).getTime() - new Date(Date.parse(registro.fechainicio)).getTime()) / 1000);
        //registro.tiempopasado = this.myFunctions.secondsTo_HH_MM_SS(registro.segundospasados);

        registro.tiempoEst = this.myFunctions.secondsTo_HH_MM_SS(registro.tiempoEstimadoSegundos);
        registro.tiempoPrep = this.myFunctions.secondsTo_HH_MM_SS(registro.tiempoEstimadoPreparacionSegundos);
        registro.tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(registro.tiempoPredSegundos);

        if (registro.refOF == "") registro.refOF = "-";
        if (registro.cliente == "") registro.cliente = "-";
        if (registro.pieza == "") registro.pieza = "-";
        if (registro.parte == "") registro.parte = "-";
        if (registro.operacion == "") registro.operacion = "-";
        if (registro.nPieza == "") registro.nPieza = "-";
        if (registro.repetidas == "") registro.repetidas = "-";

        this.CargarIconosEstadoMaquina(registro);

      }, this);

      this.operarios = datos;

      this.operarios.forEach(function (registro) {

        this.dibujarGraficoResumenSemanaRegistro(registro);

      }, this);

      clearInterval(this.intervalSegundos);
      clearInterval(this.interval);

      this.intervalSegundos = setInterval(() => {
        this.actualizarTiempoEnVivo();
      }, 1000);

      this.interval = setInterval(() => {
        this.refreshOperarios();
        this.cargarProduccion();
      }, 5000);

      this.interval2 = setInterval(() => {
        this.cargarProcesos();
        this.cargarDatosNotas();
        this.cargarDatosMantenimientos();
        this.cargarPlanificados();
      }, 300000);

    });

  }

  refreshOperarios() {

    if (this.router.url == '/procesosoffline/' + this.idmaquina) {

      this.hmiService.Get_ejecuciones_maquina_offline(this.idmaquina).subscribe((result) => {

        var datos: any = result;
        var count = 1;

        datos.forEach(function (registro) {

          registro.idRegistro = count;
          count++;

          registro.nPieza = registro.hechas + "/" + registro.cantidad;
          registro.ejecss = "estado-" + registro.procesos_Tipostr;

          var hanpasado = this.HanPasadoDias(new Date(Date.parse(registro.fechainicio)), this.myFunctions.getDateNow());
          var hapasadoTiempo = this.HanPasadoTiempo(new Date(Date.parse(registro.fechainicio)), this.myFunctions.getDateNow());
          if (hanpasado > 0) registro.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
          else registro.tiempopasado = hapasadoTiempo.toString();
          registro.segundospasados = Math.round((new Date(Date.now()).getTime() - new Date(Date.parse(registro.fechainicio)).getTime()) / 1000);
          //registro.tiempopasado = this.myFunctions.secondsTo_HH_MM_SS(registro.segundospasados);

          registro.tiempoEst = this.myFunctions.secondsTo_HH_MM_SS(registro.tiempoEstimadoSegundos);
          registro.tiempoPrep = this.myFunctions.secondsTo_HH_MM_SS(registro.tiempoEstimadoPreparacionSegundos);
          registro.tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(registro.tiempoPredSegundos);

          if (registro.refOF == "") registro.refOF = "-";
          if (registro.cliente == "") registro.cliente = "-";
          if (registro.pieza == "") registro.pieza = "-";
          if (registro.parte == "") registro.parte = "-";
          if (registro.operacion == "") registro.operacion = "-";
          if (registro.nPieza == "") registro.nPieza = "-";
          if (registro.repetidas == "") registro.repetidas = "-";

          this.CargarIconosEstadoMaquina(registro);

          //ACTUALIZAR LISTA DE OPERARIOS
          var operario = this.operarios.find(x => x.idRegistro == registro.idRegistro);

          if (operario != undefined) {

            operario.cantidad = registro.cantidad;
            operario.cliente = registro.cliente;
            operario.ejecss = registro.ejecss;
            operario.estadoimg = registro.estadoimg;
            operario.fechainicio = registro.fechainicio;
            operario.hechas = registro.hechas;
            operario.idOperario = registro.idOperario;
            //operario.idRegistro = registro.idRegistro;
            operario.nPieza = registro.nPieza;
            operario.operacion = registro.operacion;
            operario.operario = registro.operario;
            operario.parte = registro.parte;
            operario.pieza = registro.pieza;
            operario.procesos_Tipo = registro.procesos_Tipo;
            operario.procesos_Tipostr = registro.procesos_Tipostr;
            operario.refOF = registro.refOF;
            operario.repetidas = registro.repetidas;
            operario.segundospasados = registro.segundospasados;
            operario.tiempoEst = registro.tiempoEst;
            operario.tiempoEstimadoPreparacionSegundos = registro.tiempoEstimadoPreparacionSegundos;
            operario.tiempoEstimadoSegundos = registro.tiempoEstimadoSegundos;
            operario.tiempoPred = registro.tiempoPred;
            operario.tiempoPredSegundos = registro.tiempoPredSegundos;
            operario.tiempoPrep = registro.tiempoPrep;
            operario.tiempopasado = registro.tiempopasado;
            operario.porcentaje = registro.porcentaje;

          } else {//NUEVO OPERARIO EN PUESTO

            this.operarios.push(registro);

          }

        }, this);

        //ELIMINAMOS LOS OPERARIOS QUE YA NO ESTAN
        //var listaIdsOperarios = datos.map(o => o.idOperario);

        //this.operarios.forEach(function (registro, index) {
        //  if (listaIdsOperarios.indexOf(registro.idOperario) == -1) {
        //    this.operarios.splice(index, 1);
        //  }
        //}, this);

        //ACTUALIZAR LOS GRAFICOS DE RESUMEN SEMANA O CREARLOS SI NO ESTAN
        this.refreshResumenSemanaOperario(0);

        clearInterval(this.intervalSegundos);

        this.intervalSegundos = setInterval(() => {
          this.actualizarTiempoEnVivo();
        }, 1000);

      });

    } else {

      clearInterval(this.interval);

    }

  }

  async refreshResumenSemanaOperario(registro) {

    var count = 0;
    while (document.getElementById("resumenSemanaGrafico_offline_" + registro.idRegistro) == null && count < 5) {
      await new Promise(r => setTimeout(r, 100));
      count++;
    }

    this.operarios.forEach(function (registro) {

      if (registro.previousDataResumenSemana == undefined) registro.previousDataResumenSemana = [
        [this.translateService.instant("ejecucion"), 0],
        [this.translateService.instant("parada"), 0],
        [this.translateService.instant("microparada"), 0],
        [this.translateService.instant("preparacion"), 0],
        [this.translateService.instant("mantenimiento"), 0],
        [this.translateService.instant("alarma"), 0],
        [this.translateService.instant("apagada"), 0]
      ];

      registro.resumenSemanaGrafico = c3.generate({
        bindto: "#resumenSemanaGrafico_offline_" + registro.idRegistro,
        padding: {
          top: -40,
        },
        data: {
          columns: registro.previousDataResumenSemana,
          type: 'bar',
          order: null
        },
        transition: {
          duration: 0
        },
        color: {
          pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
        },
        axis: {
          y: {
            show: false
          },
          x: {
            show: false
          }
        },
        bar: {
          width: {
            ratio: 1
          },
          space: 0.1
        },
        tooltip: {
          format: {
            title: function (d) { return 'Resumen semana' },
            value: function (value, ratio, id) {
              return value + '%';
            }
          }
        }
      });

      this.cargarResumenSemanaRegistro(registro);

    }, this);

  }

  actualizarTiempoEnVivo() {

    if (this.router.url == '/procesosoffline/' + this.idmaquina) {

      this.operarios.forEach(function (registro) {

        var hanpasado = this.HanPasadoDias(new Date(Date.parse(registro.fechainicio)), this.myFunctions.getDateNow());
        var hapasadoTiempo = this.HanPasadoTiempo(new Date(Date.parse(registro.fechainicio)), this.myFunctions.getDateNow());
        if (hanpasado > 0) registro.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
        else registro.tiempopasado = hapasadoTiempo.toString();
        registro.segundospasados = Math.round((new Date(Date.now()).getTime() - new Date(Date.parse(registro.fechainicio)).getTime()) / 1000);
        //registro.tiempopasado = this.myFunctions.secondsTo_HH_MM_SS(registro.segundospasados);

      }, this);

    } else {
      clearInterval(this.intervalSegundos);
    }

  }

  cargarProduccion() {

    this.hmiService.Get_produccion_offline(this.idmaquina).subscribe((json) => {

      this.dataProduccion = json;

    });

  }

  cargarResumenSemanaRegistro(registro) {

    var idRegistroMax = Math.max(...this.operarios.map(a => a.idRegistro));

    if (registro.idRegistro == idRegistroMax) {

      var d = this.myFunctions.getDateNow()
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1);

      var astelehena = new Date(d.setDate(diff));
      var hurrengoAstelehena = new Date(d.setDate(diff));
      hurrengoAstelehena.setDate(hurrengoAstelehena.getDate() + 7);

      astelehena.setHours(0, 0, 0);
      hurrengoAstelehena.setHours(0, 0, 0);

      this.hmiService.Get_ResumenSemana_offline(this.idmaquina, "", this.myFunctions.dateToYYYYMMDDtHHmmSSz(astelehena), this.myFunctions.dateToYYYYMMDDtHHmmSSz(hurrengoAstelehena)).subscribe((result) => {

        var datos: any = result;

        var groupByOperario = [];

        //GROUP BY POR OPERARIO
        datos.forEach(function (a) {
          if (!this[a.idOperario]) {
            this[a.idOperario] = {
              idOperario: a.idOperario, tEjecucion: 0, tParada: 0, tMicroParadas: 0, tPreparacion: 0, tMantenimiento: 0, tAlarma: 0, tApagado: 0, tSinActividad: 0
            };
            groupByOperario.push(this[a.idOperario]);
          }
          this[a.idOperario].tEjecucion += a.tEjecucion;
          this[a.idOperario].tParada += a.tParada;
          this[a.idOperario].tMicroParadas += a.tMicroParadas;
          this[a.idOperario].tPreparacion += a.tPreparacion;
          this[a.idOperario].tMantenimiento += a.tMantenimiento;
          this[a.idOperario].tAlarma += a.tAlarma;
          this[a.idOperario].tApagado += a.tApagado;
          this[a.idOperario].tSinActividad += a.tSinActividad;
        }, Object.create(null));

        this.operarios.forEach(function (registro) {

          //RESUMEN SEMANA DE CADA OPERARIO
          var resumenSemanaDeOperario = groupByOperario.find(obj => obj.idOperario == registro.idOperario);

          if (resumenSemanaDeOperario == undefined) resumenSemanaDeOperario = { tAlarma: 0, tApagado: 0, tEjecucion: 0, tMantenimiento: 0, tMicroParadas: 0, tParada: 0, tPreparacion: 0, tSinActividad: 0 };

          var tTotal = resumenSemanaDeOperario.tEjecucion + resumenSemanaDeOperario.tParada + resumenSemanaDeOperario.tMicroParadas + resumenSemanaDeOperario.tPreparacion + resumenSemanaDeOperario.tMantenimiento + resumenSemanaDeOperario.tAlarma + resumenSemanaDeOperario.tApagado + resumenSemanaDeOperario.tSinActividad;
          if (tTotal == 0) tTotal = 1;

          var dataResumenSemana = [
            [this.translateService.instant("ejecucion"), (resumenSemanaDeOperario.tEjecucion * 100 / tTotal).toFixed(1)],
            [this.translateService.instant("parada"), (resumenSemanaDeOperario.tParada * 100 / tTotal).toFixed(1)],
            [this.translateService.instant("microparada"), (resumenSemanaDeOperario.tMicroParadas * 100 / tTotal).toFixed(1)],
            [this.translateService.instant("preparacion"), (resumenSemanaDeOperario.tPreparacion * 100 / tTotal).toFixed(1)],
            [this.translateService.instant("mantenimiento"), (resumenSemanaDeOperario.tMantenimiento * 100 / tTotal).toFixed(1)],
            [this.translateService.instant("alarma"), (resumenSemanaDeOperario.tAlarma * 100 / tTotal).toFixed(1)],
            [this.translateService.instant("apagada"), (resumenSemanaDeOperario.tApagado * 100 / tTotal).toFixed(1)]
          ];

          registro.previousDataResumenSemana = dataResumenSemana;

          registro.resumenSemanaGrafico.load({ columns: dataResumenSemana });

        }, this);

        //RESUMEN SEMANA GLOBAL
        var tAlarma = 0, tApagado = 0, tEjecucion = 0, tMantenimiento = 0, tMicroParadas = 0, tParada = 0, tPreparacion = 0, tSinActividad = 0;

        groupByOperario.forEach(function (registro) {

          tEjecucion += registro.tEjecucion;
          tParada += registro.tParada;
          tMicroParadas += registro.tMicroParadas;
          tPreparacion += registro.tPreparacion;
          tMantenimiento += registro.tMantenimiento;
          tAlarma += registro.tAlarma;
          tApagado += registro.tApagado;
          tSinActividad += registro.tSinActividad;

        }, this);

        var tTotal = tEjecucion + tParada + tMicroParadas + tPreparacion + tMantenimiento + tAlarma + tApagado + tSinActividad;
        if (tTotal == 0) tTotal = 1;

        var dataResumenSemana = [
          [this.translateService.instant("ejecucion"), (tEjecucion * 100 / tTotal).toFixed(1)],
          [this.translateService.instant("parada"), (tParada * 100 / tTotal).toFixed(1)],
          [this.translateService.instant("microparada"), (tMicroParadas * 100 / tTotal).toFixed(1)],
          [this.translateService.instant("preparacion"), (tPreparacion * 100 / tTotal).toFixed(1)],
          [this.translateService.instant("mantenimiento"), (tMantenimiento * 100 / tTotal).toFixed(1)],
          [this.translateService.instant("alarma"), (tAlarma * 100 / tTotal).toFixed(1)],
          [this.translateService.instant("apagada"), (tApagado * 100 / tTotal).toFixed(1)]
        ];

        this.resumenSemanaGrafico.load({ columns: dataResumenSemana });

      });

    }
  }

  cargarProcesos() {

    this.hmiService.Get_procesos_offline(this.idmaquina).subscribe((json) => {

      var JprocesosTemp: any = [];
      // order by 
      var array = []
      var an: any = json;
      an.forEach(a => array[a['maquina'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fechaini']))] = a);
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key));

      var maquina: string = '';
      var procesos_Tipo: string = '';
      var nombrePrograma: string = '';
      var operario: string = '';
      var fecha: Date = this.now;
      var fechaIni: Date = this.now;
      var fechafin: Date = this.now;
      var tiempoRealHH: string = '';
      var tiempoEstHH: string = '';
      var perdida: string = '';
      var duracion: number = 0;

      var refof: string = '';
      var cliente: string = '';
      var pieza: string = '';
      var parte: string = '';
      var operacion: string = '';

      var primero = true;

      keys.forEach(key => {
        var row = array[key];

        if (!primero //para no entrar en la primera vuelta
          && ((String(new Date(fechafin)) != String(new Date(row.fechaini)))
            || (!(operario == row.operario)
              || !(maquina == row.maquina)
              || !(nombrePrograma == row.nombrePrograma)
              || (!(procesos_Tipo == row.procesos_Tipo) && !("parada" == row.procesos_Tipo))
              || ((fechafin.getTime() - new Date(row.fechaini).getTime()) / 1000) > 120)
            || (((fechafin.getTime() - new Date(row.fechaini).getTime()) / 1000) <= 120 && ((fechafin.getTime() - new Date(row.fechaini).getTime()) / 1000) > 0 && !("parada" == row.procesos_Tipo)))
        ) {
          var jOperario: any = {};
          jOperario['maquina'] = maquina;
          jOperario['procesos_Tipo'] = procesos_Tipo;
          jOperario['nombrePrograma'] = nombrePrograma;
          jOperario['operario'] = operario;
          jOperario['fechaini'] = fechaIni;
          jOperario['fechafin'] = fechafin;

          jOperario['refof'] = refof;
          jOperario['cliente'] = cliente;
          jOperario['pieza'] = pieza;
          jOperario['parte'] = parte;
          jOperario['operacion'] = operacion;

          jOperario['tiempoRealHH'] = this.myFunctions.secondsToHms(duracion);
          jOperario['tiempoEstHH'] = tiempoEstHH;

          jOperario['perdida'] = perdida;
          jOperario['duracion'] = this.myFunctions.secondsToHms(duracion);
          jOperario['duracionS'] = fecha.getTime() - fechaIni.getTime();
          JprocesosTemp.push(jOperario);

          maquina = row.maquina + '';
          procesos_Tipo = row.procesos_Tipo + '';
          nombrePrograma = row.nombrePrograma + '';
          operario = row.operario + '';
          fechaIni = this.myFunctions.dateCopy(new Date(row.fechaini));
          tiempoEstHH = row.tiempoEstHH;
          perdida = row.perdida + '';
          duracion = 0;

          refof = row.refof + '';
          cliente = row.cliente + '';
          pieza = row.pieza + '';
          parte = row.parte + '';
          operacion = row.operacion + '';

        } else if (primero) { //es la primera row
          maquina = row.maquina + '';
          procesos_Tipo = row.procesos_Tipo + '';
          nombrePrograma = row.nombrePrograma + '';
          operario = row.operario + '';
          fechaIni = this.myFunctions.dateCopy(new Date(row.fechaini));
          tiempoEstHH = row.tiempoEstHH;
          perdida = row.perdida + '';
          duracion = 0;
          primero = false;

          refof = row.refof + '';
          cliente = row.cliente + '';
          pieza = row.pieza + '';
          parte = row.parte + '';
          operacion = row.operacion + '';
        }
        duracion += row.tiempoRealHH;
        fechafin = this.myFunctions.dateCopy(new Date(row.fechafin));
      });
      if (!primero) {
        var jOperario: any = {};
        jOperario['maquina'] = maquina;
        jOperario['procesos_Tipo'] = procesos_Tipo;
        jOperario['nombrePrograma'] = nombrePrograma;
        jOperario['operario'] = operario;
        jOperario['fechaini'] = fechaIni;
        jOperario['fechafin'] = fechafin;

        jOperario['tiempoRealHH'] = this.myFunctions.secondsToHms(duracion);
        jOperario['tiempoEstHH'] = tiempoEstHH;

        jOperario['perdida'] = perdida;
        jOperario['duracion'] = this.myFunctions.secondsToHms(duracion);
        jOperario['duracionS'] = fecha.getTime() - fechaIni.getTime();

        jOperario['refof'] = refof;
        jOperario['cliente'] = cliente;
        jOperario['pieza'] = pieza;
        jOperario['parte'] = parte;
        jOperario['operacion'] = operacion;

        JprocesosTemp.push(jOperario);
      }

      this.datosProcesos = JprocesosTemp.slice(-50).reverse(); //COGEMOS LOS 50 ULTIMOS Y LES DAMOS LA VUELTA

    });

  }

  cargarDatosNotas() {
    this.hmiService.Get_NotasActualesMaquina(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.notas = datuak;
    });
  }

  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      //ViewState("checkMantenimientos") = ":"

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);

            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }
            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.myFunctions.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }


          } else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }


          if (row.idTipo == 1) {//diario
            tipo = "diario";
          } else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          } else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          } else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          } else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      });

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      })

    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      //ViewState("checkMantenimientos") = ":"

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  clickSwitch(event: any, dataitem: any, tipo: number) {
    this.loadingPorFecha = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switck=true)
      this.hmiService.UNupdate_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switck=false)
      this.hmiService.update_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  clickSwitchTiempo(event: any, dataitem: any, tipo: number) {
    this.loadingPorTiempo = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switck=true)
      this.hmiService.UNupdate_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switck=false)
      this.hmiService.update_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.esRojo) {
      case false:
        return "";
      case true:
        return "gridFilaRoja";
      default:
        return "";
    }
  }

  cargarPlanificados() {

    if (this.router.url == '/procesosoffline/' + this.idmaquina) {

      this.planificadorService.GetTodo(1, true, true, this.idmaquina).subscribe((result) => {
        this.datosPlanificados = result;
      });

    } else {

      clearInterval(this.interval2);

    }

  }

  CargarIconosEstadoMaquina(registro) {

    switch (registro.procesos_Tipostr) {

      case "apagada": registro.estadoimg = "assets/dc-apagada.png"; break;

      case "parada": registro.estadoimg = "assets/dc-parada.png"; break;

      case "ejecucion":
        if (registro.tiempoEstimadoSegundos == 0) {

          registro.estadoimg = "assets/dcg12.png";
          registro.porcentaje = "";

        } else {

          var ahora = this.myFunctions.getDateNow()
          var fechaOrig = new Date(Date.parse(registro.fechainicio));

          var dif = ahora.getTime() - fechaOrig.getTime();

          var Seconds_from_T1_to_T2 = dif / 1000;
          var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

          var docentaje = this.calcularPorcentaje(segundosfiff, 12, registro.tiempoEstimadoSegundos, 1, true);
          var porcentaje = this.calcularPorcentaje(segundosfiff, 100, registro.tiempoEstimadoSegundos, 1, true);
          registro.porcentaje = porcentaje + "%";
          registro.estadoimg = "assets/dcg" + docentaje + ".png";

        }
        break;

      case "preparacion":
        if (registro.tiempoEstimadoSegundos == 0) {

          registro.estadoimg = "assets/dcg12.png";
          registro.porcentaje = "";

        } else {

          var ahora = this.myFunctions.getDateNow()
          var fechaOrig = new Date(Date.parse(registro.fechainicio));

          var dif = ahora.getTime() - fechaOrig.getTime();

          var Seconds_from_T1_to_T2 = dif / 1000;
          var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

          var docentaje = this.calcularPorcentaje(segundosfiff, 12, registro.tiempoEstimadoPreparacionSegundos, 1, true);
          var porcentaje = this.calcularPorcentaje(segundosfiff, 100, registro.tiempoEstimadoPreparacionSegundos, 1, true);
          registro.porcentaje = porcentaje + "%";
          registro.estadoimg = "assets/dcg" + docentaje + ".png";

        }
        break;

      case "mantenimiento": registro.estadoimg = "assets/dc-mantenimiento.png"; break;

      case "alarma": registro.estadoimg = "assets/dc-alarma.png"; break;

    }

  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  HanPasadoDias(fechaOrig, ahora) {
    var dt1 = new Date(fechaOrig);
    var dt2 = new Date(ahora);
    var difSegundos = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.floor(difSegundos / (60 * 60 * 24));
  }

  HanPasadoTiempo(fechaOrig, ahora) {
    let diffInMilliSeconds = Math.abs(fechaOrig - ahora) / 1000;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = Math.floor(diffInMilliSeconds % 60);
    diffInMilliSeconds -= minutes * 60;

    let difference = '';

    if (hours < 10) difference = "0" + hours.toString();
    else difference = hours.toString();

    difference = difference + ":";
    if (minutes < 10) difference = difference + "0" + minutes.toString();
    else difference = difference + minutes.toString();

    difference = difference + ":";
    if (seconds < 10) difference = difference + "0" + seconds.toString();
    else difference = difference + seconds.toString();

    return difference;
  }

}
