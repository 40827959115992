import { Component } from '@angular/core';
import { PlanesAccionService } from '../../../app/_services/planesaccion.service';
import { FormGroup } from '@angular/forms';
import { RepercusionesService } from '../../_services/repercusiones.service';
import { ResumenPerdidas } from '../../_models/ResumenPerdidas';
import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import * as c3 from 'c3';

import { first } from 'rxjs/operators';

@Component({
  selector: 'app-graficos-data',
  templateUrl: './repercusionesperdidas.html'
})

export class RepercusionesPerdidasComponent {

  user = this.usuariosService.userValue;
  public formGroup: FormGroup;
  public planaccion: any;
  public globales: number;
  public fechaIniciostr: string;
  public fechaFinstr: string;
  public responsable: string;

  public resumenperdidas: ResumenPerdidas;

  public fechaInicioPlan: Date;
  public fechaFinPlan: Date;
  public fechaInicioPlanPeriodoAnterior: Date;
  public fechaFinPlanPeriodoAnterior: Date;

  //FLECHAS CUANDO SE FILTRA POR TIEMPO
  flechaPerdidasEnPeriodoPorcen_PorTiempo: string = "";
  flechaPerdidasEnPeriodoTiempo_PorTiempo: string = "";
  flechaPerdidasTotalesEnPeriodoPorcen_PorTiempo: string = "";
  flechaPerdidasTotalesEnPeriodoTiempo_PorTiempo: string = "";

  //FLECHAS CUANDO SE FILTRA POR NUMERO
  flechaPerdidasEnPeriodoPorcen_PorNumero: string = "";
  flechaPerdidasEnPeriodoTiempo_PorNumero: string = "";
  flechaPerdidasTotalesEnPeriodoPorcen_PorNumero: string = "";
  flechaPerdidasTotalesEnPeriodoTiempo_PorNumero: string = "";

  public clasificarPorTiempoNumero: boolean = true;

  public idPlan: number;
  public acciones: any;

  public accionesPerdidas: any;
  public selectedAcciones: any = [];
  public perdidas: any;
  public selectedPerdidas: any = [];

  public historicoPerdidas: any;
  public planesacciones: any;

  public condicionesPerdidas: any;

  public tiempoPerdidasPeriodo: any;
  public tiempoPerdidasPeriodoAnterior: any;
  public numeroPerdidasPeriodo: any;
  public numeroPerdidasPeriodoAnterior: any;

  public graficacomparativas;
  public graficaevolucion;
  public datagraficaevolucion;

  public aaaa: any;

  constructor(private planesaccionService: PlanesAccionService,
    private menuService: MenuService,
    private translate: TranslateService,
    private repercusionesService: RepercusionesService,
    private usuariosService: UsuariosService,
    private myFunctions: MyFunctions) {

    this.resumenperdidas = new ResumenPerdidas();
    this.resumenperdidas.enperiodo.horas = "0";
    this.resumenperdidas.enperiodo.porcentajetiempo = "0";
    this.resumenperdidas.enperiodo.porcentajenumero = "0";
    this.resumenperdidas.enperiodo.numeroperdidas = "0";
    this.resumenperdidas.enperiodo.horasvariacion = "0";
    this.resumenperdidas.enperiodo.porcentajevariaciontiempo = "0";
    this.resumenperdidas.enperiodo.porcentajevariacionnumero = "0";
    this.resumenperdidas.enperiodo.numeroperdidasvariacion = "0";

    this.resumenperdidas.globales.horas = "0";
    this.resumenperdidas.globales.porcentajetiempo = "0";
    this.resumenperdidas.globales.porcentajenumero = "0";
    this.resumenperdidas.globales.numeroperdidas = "0";
    this.resumenperdidas.globales.horasvariacion = "0";
    this.resumenperdidas.globales.porcentajevariaciontiempo = "0";
    this.resumenperdidas.globales.porcentajevariacionnumero = "0";
    this.resumenperdidas.globales.numeroperdidasvariacion = "0";

    this.menuService.titulo = this.translate.instant('perdidas').toUpperCase();

  }

  ngOnInit() {

    this.planesaccionService.GetAll().pipe(first()).subscribe((result: any) => {
      this.planesacciones = result.data;
    });

    this.dibujarGraficos();

  }

  dibujarGraficos() {

    var th = this;

    this.graficacomparativas = c3.generate({
      bindto: '#graficacomparativasperdidas_perdidas',
      data: {
        columns: [],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 100
      },
      bar: {
        width: {
          ratio: 0.5
        }
      },
      axis: {
        x: {
          type: 'category',
          categories: ['', '', '', '', '', '']
        },
        y: {
          tick: {
            format: function (x) {
              if (th.clasificarPorTiempoNumero) return th.myFunctions.secondsTo_HH_MM(Math.round(x * 60 * 60));
              else return x;
            }
          }
        }
      },
      size: {
        height: 220
      },
      legend: {
        show: false
      },
      tooltip: {
        format: {
          value: function (value, ratio, id) {
            if (th.clasificarPorTiempoNumero) return th.myFunctions.secondsTo_HH_MM(Math.round(value * 60 * 60));
            else return value;
          }
        }
      }
    });

    this.graficaevolucion = c3.generate({
      bindto: '#graficaevolucionperdidas_perdidas',
      data: {
        x: 'x',
        columns: [
          ['x', '', '', '', '', '', '', '', '', '', '', '', '']
        ],
        sample: 'area-spline',
        order: null
      },
      zoom: {
        enabled: true
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: this.GetFormat(this.user.idioma)
          }
        },
        y: {
          min: 0,
          padding: { top: 0, bottom: 0 },
          tick: {
            format: function (x) {
              if (th.clasificarPorTiempoNumero) return th.myFunctions.secondsTo_HH_MM(Math.round(x * 60 * 60));
              else return x;
            }
          }
        }
      },
      size: {
        height: 220
      },
      transition: {
        duration: 100
      },
      tooltip: {
        format: {
          value: function (value, ratio, id) {
            if (th.clasificarPorTiempoNumero) return th.myFunctions.secondsTo_HH_MM(Math.round(value * 60 * 60));
            else return value;
          }
        }
      },
      spline: {
        interpolation: {
          type: "monotone"
        }
      }
    });

    this.graficaevolucion.transform('area-spline');
    this.datagraficaevolucion = [];

  }

  GetFormat(idioma: String) {
    var format: String;
    switch (idioma.toLowerCase().trim()) {
      case 'eu': format = '%Y-%m-%d'; break;
      case 'en':
      case 'es': format = '%d-%m-%Y'; break;
      default: format = '%d-%m-%Y'; break;
    }
    return format;
  }

  btnTiempo() {

    this.clasificarPorTiempoNumero = true;

    if (this.selectedAcciones.length > 0 && this.selectedPerdidas.length > 0) {
      this.onSelectAccion(null);
    }

  }

  btnNumero() {

    this.clasificarPorTiempoNumero = false;

    if (this.selectedAcciones.length > 0 && this.selectedPerdidas.length > 0) {
      this.onSelectAccion(null);
    }

  }

  public planDeAccionSelected(value: any): void {

    this.perdidas = [];
    this.idPlan = value.id;
    this.responsable = value.responsable;

    this.fechaIniciostr = this.getDateStringFromString(value.fechaini);
    this.fechaFinstr = this.getDateStringFromString(value.fechafin);

    this.fechaInicioPlan = this.getDateFromString(value.fechaini);
    this.fechaFinPlan = this.getDateFromString(value.fechafin);

    this.fechaInicioPlanPeriodoAnterior = new Date(this.fechaInicioPlan.getTime() - (this.fechaFinPlan.getTime() - this.fechaInicioPlan.getTime()));
    this.fechaFinPlanPeriodoAnterior = this.fechaInicioPlan;

    this.fechaInicioPlan.setHours(0, 0, 0);
    this.fechaFinPlan.setHours(0, 0, 0);
    this.fechaInicioPlanPeriodoAnterior.setHours(0, 0, 0);
    this.fechaFinPlanPeriodoAnterior.setHours(0, 0, 0);

    this.cargarPlanDeAccion();

  }

  cargarPlanDeAccion() {

    var r1, r2, r3: boolean = false;

    this.repercusionesService.get_Acciones_Perdidas(this.idPlan).pipe(first()).subscribe((result: any) => {
      this.accionesPerdidas = result;
      r1 = true;
      if (r1 && r2 && r3) this.cargarGrids();
    });

    this.repercusionesService.get_All_Perdidas_Justificadas(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicioPlanPeriodoAnterior),
      this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFinPlan)).pipe(first()).subscribe((result: any) => {
        this.historicoPerdidas = result;
        r2 = true;
        if (r1 && r2 && r3) this.cargarGrids();
      });



    this.repercusionesService.get_All_Tiempos_Perdidas_Con_No_Justificadas(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicioPlan),
      this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFinPlan),
      this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicioPlanPeriodoAnterior),
      this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFinPlanPeriodoAnterior)).pipe(first()).subscribe((result: any) => {
        this.tiempoPerdidasPeriodo = result[0].tiempoPerdidasPeriodo;
        this.tiempoPerdidasPeriodoAnterior = result[0].tiempoPerdidasPeriodoAnterior;
        this.numeroPerdidasPeriodo = result[0].numeroPerdidasPeriodo;
        this.numeroPerdidasPeriodoAnterior = result[0].numeroPerdidasPeriodoAnterior;
        r3 = true;
        if (r1 && r2 && r3) this.cargarGrids();
      });

  }

  cargarGrids() {

    var groupByPorAccion = [];
    var groupByPorPerdida = [];
    var groupByPorPerdida2 = [];

    //GROUP BY POR ACCION
    this.accionesPerdidas.forEach(function (a) {
      if (!this[a.idAccion]) {
        this[a.idAccion] = {
          idAccion: a.idAccion, nombreAccion: a.nombreAccion, fIniAccion: a.fIniAccion, fFinAccion: a.fFinAccion, perdidas: [],
        };
        groupByPorAccion.push(this[a.idAccion]);
      }
      this[a.idAccion].perdidas.push({ idPerdida: a.idPerdida, nombrePerdida: a.nombrePerdida });
    }, Object.create(null));

    //GROUP BY POR PERDIDA DE LAS LISTAS DE PERDIDAS DENTRO DE LAS ACCIONES (groupByPorAccion.perdidas)
    //HAY QUE HACERLO DOS VECES PARA AGRUPAR LOS RANGOS DE FECHA DE CADA PERDIDA QUE VIENEN DE LA ACCION
    groupByPorAccion.forEach(function (a) {
      a.perdidas.forEach(function (b) {
        if (!this[b.idPerdida]) {
          this[b.idPerdida] = {
            idPerdida: b.idPerdida, nombrePerdida: b.nombrePerdida, rangoFechas: []
          };
          groupByPorPerdida.push(this[b.idPerdida]);
        }
        this[b.idPerdida].rangoFechas.push({ fIniRango: a.fIniAccion, fFinRango: a.fFinAccion });
      }, Object.create(null));
    });

    groupByPorPerdida.forEach(function (a) {
      if (!this[a.idPerdida]) {
        this[a.idPerdida] = {
          idPerdida: a.idPerdida, nombrePerdida: a.nombrePerdida, rangoFechas: []
        };
        groupByPorPerdida2.push(this[a.idPerdida]);
      }
      this[a.idPerdida].rangoFechas.push(a.rangoFechas[0]);
    }, Object.create(null));

    this.acciones = groupByPorAccion;
    this.selectedAcciones = groupByPorAccion.map(a => a.idAccion);
    this.perdidas = groupByPorPerdida2;
    this.selectedPerdidas = groupByPorPerdida2.map(a => a.idPerdida);

    this.cargarDatos(true);

  }

  public onSelectAccion(e) {

    var th = this;

    var groupByPorPerdida = [];
    var groupByPorPerdida2 = [];

    //GROUP BY POR PERDIDA DE LAS LISTAS DE PERDIDAS DENTRO DE LAS ACCIONES (groupByPorAccion.perdidas)
    //HAY QUE HACERLO DOS VECES PARA AGRUPAR LOS RANGOS DE FECHA DE CADA PERDIDA QUE VIENEN DE LA ACCION
    this.acciones.forEach(function (a) {
      a.perdidas.forEach(function (b) {
        if (!this[b.idPerdida]) {
          this[b.idPerdida] = {
            idPerdida: b.idPerdida, nombrePerdida: b.nombrePerdida, rangoFechas: []
          };
          //AÑADIR EL ELEMENTO SOLO SI ESTA SELECCIONADO EN EL GRID
          if (th.selectedAcciones.includes(a.idAccion)) groupByPorPerdida.push(this[b.idPerdida]);
        }
        this[b.idPerdida].rangoFechas.push({ fIniRango: a.fIniAccion, fFinRango: a.fFinAccion });
      }, Object.create(null));
    });

    groupByPorPerdida.forEach(function (a) {
      if (!this[a.idPerdida]) {
        this[a.idPerdida] = {
          idPerdida: a.idPerdida, nombrePerdida: a.nombrePerdida, rangoFechas: []
        };
        groupByPorPerdida2.push(this[a.idPerdida]);
      }
      this[a.idPerdida].rangoFechas.push(a.rangoFechas[0]);
    }, Object.create(null));

    this.perdidas = groupByPorPerdida2;
    this.selectedPerdidas = groupByPorPerdida2.map(a => a.idPerdida);

    this.cargarDatos(true);

  }

  public onSelectPerdida(e) {

    this.cargarDatos(false);

  }

  cargarDatos(cargarDatosGrid) {

    var th = this

    //GUARDAMOS LAS CONDICIONES DERIVADAS DE LAS ACCIONES SELECCIONADAS (se guarda en cada elemento un idPerdida y el rango de fechas)
    var condicionesPerdidas = [];
    this.perdidas.forEach(function (a) {
      a.rangoFechas.forEach(function (b) {
        if (th.selectedPerdidas.includes(a.idPerdida)) condicionesPerdidas.push({ idPerdida: a.idPerdida, fIniRango: b.fIniRango, fFinRango: b.fFinRango });
      });
    });

    this.condicionesPerdidas = condicionesPerdidas;

    this.cargarDatosPaneles(condicionesPerdidas);
    this.cargarDatosGraficoComparativasPerdidas(condicionesPerdidas, cargarDatosGrid);
    this.cargarDatosGraficoEvolucionPerdidas(condicionesPerdidas, cargarDatosGrid);

  }

  cargarDatosPaneles(condicionesPerdidas) {

    var horasEnPeriodo = 0;
    var horasAnteriores = 0;
    var horasGlobalesEnPeriodo = 0;
    var horasGlobalesAnteriores = 0;

    var numeroPerdidasEnPeriodo = 0;
    var numeroPerdidasAnteriores = 0;
    var numeroPerdidasGlobalesEnPeriodo = 0;
    var numeroPerdidasGlobalesAnteriores = 0;

    var fechaInicioPlan = this.fechaInicioPlan;
    var fechaFinPlan = this.fechaFinPlan;
    var fechaInicioPlanPeriodoAnterior = this.fechaInicioPlanPeriodoAnterior;
    var fechaFinPlanPeriodoAnterior = this.fechaFinPlanPeriodoAnterior;

    this.historicoPerdidas.forEach(function (a) {

      var fechaTurno = new Date(a.fechaTurno);

      var condicionCumplida = false;
      condicionesPerdidas.forEach(function (b) {
        var fIniRango = new Date(b.fIniRango);
        var fFinRango = new Date(b.fFinRango);

        //horasEnPeriodo
        if (a.idPerdida == b.idPerdida && fechaTurno >= fIniRango && fechaTurno <= fFinRango && !condicionCumplida) {
          horasEnPeriodo += a.duracionPerdida;
          numeroPerdidasEnPeriodo += a.numeroPerdidas;
          condicionCumplida = true;
        }

        //horasAnteriores
        if (a.idPerdida == b.idPerdida && fechaTurno >= fechaInicioPlanPeriodoAnterior && fechaTurno < fechaFinPlanPeriodoAnterior && !condicionCumplida) {
          horasAnteriores += a.duracionPerdida;
          numeroPerdidasAnteriores += a.numeroPerdidas;
          condicionCumplida = true;
        }
      });

      //horasGlobalesEnPeriodo
      if (fechaTurno >= fechaInicioPlan && fechaTurno <= fechaFinPlan) {
        horasGlobalesEnPeriodo += a.duracionPerdida;
        numeroPerdidasGlobalesEnPeriodo += a.numeroPerdidas;
      }

      //horasGlobalesAnteriores
      if (fechaTurno >= fechaInicioPlanPeriodoAnterior && fechaTurno < fechaFinPlanPeriodoAnterior) {
        horasGlobalesAnteriores += a.duracionPerdida;
        numeroPerdidasGlobalesAnteriores += a.numeroPerdidas;
      }

    });

    //PERDIDAS EN TIEMPO
    this.resumenperdidas.enperiodo.horas = this.myFunctions.secondsTo_HH_MM(horasEnPeriodo) + " / " + this.myFunctions.secondsTo_HH_MM(this.tiempoPerdidasPeriodo);
    this.resumenperdidas.enperiodo.horasvariacion = this.myFunctions.secondsTo_HH_MM(horasAnteriores) + " / " + this.myFunctions.secondsTo_HH_MM(this.tiempoPerdidasPeriodoAnterior);
    this.resumenperdidas.globales.horas = this.myFunctions.secondsTo_HH_MM(horasGlobalesEnPeriodo) + " / " + this.myFunctions.secondsTo_HH_MM(this.tiempoPerdidasPeriodo);
    this.resumenperdidas.globales.horasvariacion = this.myFunctions.secondsTo_HH_MM(horasGlobalesAnteriores) + " / " + this.myFunctions.secondsTo_HH_MM(this.tiempoPerdidasPeriodoAnterior);

    var enperiodoPorcentajetiempo = (horasEnPeriodo / this.tiempoPerdidasPeriodo) * 100;
    var enperiodoPorcentajevariaciontiempo = (horasAnteriores / this.tiempoPerdidasPeriodoAnterior) * 100;
    var globalesPorcentajetiempo = (horasGlobalesEnPeriodo / this.tiempoPerdidasPeriodo) * 100;
    var globalesPorcentajevariaciontiempo = (horasGlobalesAnteriores / this.tiempoPerdidasPeriodoAnterior) * 100;
    this.resumenperdidas.enperiodo.porcentajetiempo = enperiodoPorcentajetiempo.toFixed(0) + "%";
    this.resumenperdidas.enperiodo.porcentajevariaciontiempo = enperiodoPorcentajevariaciontiempo.toFixed(0) + "%";
    this.resumenperdidas.globales.porcentajetiempo = globalesPorcentajetiempo.toFixed(0) + "%";
    this.resumenperdidas.globales.porcentajevariaciontiempo = globalesPorcentajevariaciontiempo.toFixed(0) + "%";

    //PERDIDAS EN NUMERO
    this.resumenperdidas.enperiodo.numeroperdidas = numeroPerdidasEnPeriodo.toString() + " / " + this.numeroPerdidasPeriodo.toString();
    this.resumenperdidas.enperiodo.numeroperdidasvariacion = numeroPerdidasAnteriores.toString() + " / " + this.numeroPerdidasPeriodoAnterior.toString();
    this.resumenperdidas.globales.numeroperdidas = numeroPerdidasGlobalesEnPeriodo.toString() + " / " + this.numeroPerdidasPeriodo.toString();
    this.resumenperdidas.globales.numeroperdidasvariacion = numeroPerdidasGlobalesAnteriores.toString() + " / " + this.numeroPerdidasPeriodoAnterior.toString();

    var enperiodoPorcentajenumero = (numeroPerdidasEnPeriodo / this.numeroPerdidasPeriodo) * 100;
    var enperiodoPorcentajevariacionnumero = (numeroPerdidasAnteriores / this.numeroPerdidasPeriodoAnterior) * 100;
    var globalesPorcentajenumero = (numeroPerdidasGlobalesEnPeriodo / this.numeroPerdidasPeriodo) * 100;
    var globalesPorcentajevariacionnumero = (numeroPerdidasGlobalesAnteriores / this.numeroPerdidasPeriodoAnterior) * 100;
    this.resumenperdidas.enperiodo.porcentajenumero = enperiodoPorcentajenumero.toFixed(0) + "%";
    this.resumenperdidas.enperiodo.porcentajevariacionnumero = enperiodoPorcentajevariacionnumero.toFixed(0) + "%";
    this.resumenperdidas.globales.porcentajenumero = globalesPorcentajenumero.toFixed(0) + "%";
    this.resumenperdidas.globales.porcentajevariacionnumero = globalesPorcentajevariacionnumero.toFixed(0) + "%";

    //FLECHAS
    if (enperiodoPorcentajetiempo < enperiodoPorcentajevariaciontiempo) this.flechaPerdidasEnPeriodoPorcen_PorTiempo = "down";
    else this.flechaPerdidasEnPeriodoPorcen_PorTiempo = "up";

    if (horasEnPeriodo < horasAnteriores) this.flechaPerdidasEnPeriodoTiempo_PorTiempo = "down";
    else this.flechaPerdidasEnPeriodoTiempo_PorTiempo = "up";

    if (enperiodoPorcentajenumero < enperiodoPorcentajevariacionnumero) this.flechaPerdidasEnPeriodoPorcen_PorNumero = "down";
    else this.flechaPerdidasEnPeriodoPorcen_PorNumero = "up";

    if (numeroPerdidasEnPeriodo < numeroPerdidasAnteriores) this.flechaPerdidasEnPeriodoTiempo_PorNumero = "down";
    else this.flechaPerdidasEnPeriodoTiempo_PorNumero = "up";

    if (globalesPorcentajetiempo < globalesPorcentajevariaciontiempo) this.flechaPerdidasTotalesEnPeriodoPorcen_PorTiempo = "down";
    else this.flechaPerdidasTotalesEnPeriodoPorcen_PorTiempo = "up";

    if (horasGlobalesEnPeriodo < horasGlobalesAnteriores) this.flechaPerdidasTotalesEnPeriodoTiempo_PorTiempo = "down";
    else this.flechaPerdidasTotalesEnPeriodoTiempo_PorTiempo = "up";

    if (globalesPorcentajenumero < globalesPorcentajevariacionnumero) this.flechaPerdidasTotalesEnPeriodoPorcen_PorNumero = "down";
    else this.flechaPerdidasTotalesEnPeriodoPorcen_PorNumero = "up";

    if (numeroPerdidasGlobalesEnPeriodo < numeroPerdidasGlobalesAnteriores) this.flechaPerdidasTotalesEnPeriodoTiempo_PorNumero = "down";
    else this.flechaPerdidasTotalesEnPeriodoTiempo_PorNumero = "up";

  }

  cargarDatosGraficoComparativasPerdidas(condicionesPerdidas, cargarDatosGrid) {

    var th = this;

    var groupByPorPerdida = [];

    var fechaInicioPlanPeriodoAnterior = this.fechaInicioPlanPeriodoAnterior;
    var fechaFinPlanPeriodoAnterior = this.fechaFinPlanPeriodoAnterior;

    //GROUP BY POR PERDIDA
    this.historicoPerdidas.forEach(function (a) {
      var fechaTurno = new Date(a.fechaTurno);
      if (!this[a.idPerdida]) {
        this[a.idPerdida] = {
          idPerdida: a.idPerdida, nombrePerdida: a.nombrePerdida, fechaTurno: a.fechaTurno, tiempoPeriodosSeleccionados: 0, numeroPerdidasPeriodosSeleccionados: 0, tiempoPeriodoAnterior: 0, numeroPerdidasPeriodoAnterior: 0
        };
        groupByPorPerdida.push(this[a.idPerdida]);
      }
      var duracionPeriodosSeleccionadosAux = 0;
      var numeroPerdidasPeriodosSeleccionadosAux = 0;
      var duracionPeriodoAnteriorAux = 0;
      var numeroPerdidasPeriodoAnteriorAux = 0;
      var condicionCumplida = false;
      condicionesPerdidas.forEach(function (b) {
        var fIniRango = new Date(b.fIniRango);
        var fFinRango = new Date(b.fFinRango);
        if (a.idPerdida == b.idPerdida && fechaTurno >= fIniRango && fechaTurno <= fFinRango && !condicionCumplida) {
          duracionPeriodosSeleccionadosAux += a.duracionPerdida;
          numeroPerdidasPeriodosSeleccionadosAux += a.numeroPerdidas;
          condicionCumplida = true;
        }
        if (a.idPerdida == b.idPerdida && fechaTurno >= fechaInicioPlanPeriodoAnterior && fechaTurno < fechaFinPlanPeriodoAnterior && !condicionCumplida) {
          duracionPeriodoAnteriorAux += a.duracionPerdida;
          numeroPerdidasPeriodoAnteriorAux += a.numeroPerdidas;
          condicionCumplida = true;
        }
      });
      this[a.idPerdida].tiempoPeriodosSeleccionados += duracionPeriodosSeleccionadosAux;
      this[a.idPerdida].numeroPerdidasPeriodosSeleccionados += numeroPerdidasPeriodosSeleccionadosAux;
      this[a.idPerdida].tiempoPeriodoAnterior += duracionPeriodoAnteriorAux;
      this[a.idPerdida].numeroPerdidasPeriodoAnterior += numeroPerdidasPeriodoAnteriorAux;
    }, Object.create(null));

    var valoresActuales = [this.translate.instant('actuales')];
    var valoresAnteriores = [this.translate.instant('anteriores')];

    var columnasCategorias = [];
    if (cargarDatosGrid) this.aaaa = {};

    groupByPorPerdida.forEach(function (a) {
      if (this.selectedPerdidas.includes(a.idPerdida)) {
        if (th.clasificarPorTiempoNumero) {//TIEMPO DE PERDIDAS
          valoresActuales.push(a.tiempoPeriodosSeleccionados / 60 / 60); //en horas
          valoresAnteriores.push(a.tiempoPeriodoAnterior / 60 / 60); //en horas
        } else {//NUMERO DE PERDIDAS
          valoresActuales.push(a.numeroPerdidasPeriodosSeleccionados);
          valoresAnteriores.push(a.numeroPerdidasPeriodoAnterior);
        }
        columnasCategorias.push(a.nombrePerdida);
        if (cargarDatosGrid) {
          if (th.clasificarPorTiempoNumero) {
            this.aaaa[a.nombrePerdida] = [a.tiempoPeriodosSeleccionados, a.tiempoPeriodoAnterior];
          } else {
            this.aaaa[a.nombrePerdida] = [a.numeroPerdidasPeriodosSeleccionados, a.numeroPerdidasPeriodoAnterior];
          }
        }
      }
    }, this);

    var columnas = [valoresActuales, valoresAnteriores];

    var colores = {};
    colores[this.translate.instant('actuales')] = '#54c0d3';
    colores[this.translate.instant('anteriores')] = '#f2a325';

    var data = {
      columns: columnas,
      colors: colores,
      categories: columnasCategorias
    }
    this.graficacomparativas.load(data);

  }

  cargarDatosGraficoEvolucionPerdidas(condicionesPerdidas, cargarDatosGrid) {

    var th = this;

    var historicoPerdidasFiltrado = [];
    this.historicoPerdidas.forEach(function (a) {
      var fechaTurno = new Date(a.fechaTurno);
      var condicionCumplida = false;
      condicionesPerdidas.forEach(function (b) {
        var fIniRango = new Date(b.fIniRango);
        var fFinRango = new Date(b.fFinRango);
        if (a.idPerdida == b.idPerdida && fechaTurno >= fIniRango && fechaTurno <= fFinRango && !condicionCumplida) {
          historicoPerdidasFiltrado.push(a);
          condicionCumplida = true;
        }
      });
    });

    var fechas = [...new Set(historicoPerdidasFiltrado.map(a => a.fechaTurno))];

    var perdidas = [...new Set(historicoPerdidasFiltrado.map(a => a.nombrePerdida))];

    var dataFinal = []
    perdidas.forEach(function (a) {
      dataFinal.push([a]);
    });

    fechas.forEach(function (fecha) {
      dataFinal.forEach(function (b) {
        var rowHistorico = historicoPerdidasFiltrado.find(element => (element.fechaTurno == fecha && element.nombrePerdida == b[0]));
        if (rowHistorico != undefined) {
          if (th.clasificarPorTiempoNumero) b.push(rowHistorico.duracionPerdida / 60 / 60); //en horas //TIEMPO DE PERDIDAS
          else b.push(rowHistorico.numeroPerdidas);//NUMERO DE PERDIDAS          
        } else {
          b.push(0);
        }
      });
    });

    var fechasGrafico = fechas.map(function (fecha) {
      return fecha.substring(0, 10);
    });
    fechasGrafico.unshift("x");

    dataFinal.unshift(fechasGrafico);

    var oldData = this.datagraficaevolucion.map(function (x) {
      return x[0];
    });

    this.updateGraficoEvolucion(this.graficaevolucion, oldData, dataFinal);

    this.datagraficaevolucion = dataFinal;

    if (cargarDatosGrid) this.rellenarGrid();

  }

  rellenarGrid() {

    var th = this;

    var graficoEvolucion = this.graficaevolucion;

    this.perdidas.forEach(function (a) {

      try {

        a.valoresMiniGrafico = graficoEvolucion.data.values(a.nombrePerdida);

        if (th.clasificarPorTiempoNumero) a.horasactualesstr = th.myFunctions.secondsTo_HH_MM(th.aaaa[a.nombrePerdida][0]); //en horas //TIEMPO DE PERDIDAS
        else a.horasactualesstr = th.aaaa[a.nombrePerdida][0];//NUMERO DE PERDIDAS          

        a.porcentaje = ((th.aaaa[a.nombrePerdida][0] / th.aaaa[a.nombrePerdida][1]) * 100).toFixed(0) + "%";

        if (th.aaaa[a.nombrePerdida][0] >= th.aaaa[a.nombrePerdida][1]) a.variacionicono = "fas fa-angle-double-up";
        else a.variacionicono = "fas fa-angle-double-down";

      } catch (e) {

      }

    });

  }

  getDateStringFromString(fecha: String) {
    var fechastr: string[];
    var fechaDate: string;
    fechastr = fecha.split("-");
    switch (this.user.idioma) {
      case 'eu': fechaDate = fechastr[2] + "-" + fechastr[1] + "-" + fechastr[0]; break;
      default: fechaDate = fechastr[0] + "-" + fechastr[1] + "-" + fechastr[2]; break;
    }
    return fechaDate;
  }

  getDateFromString(fecha: String) {
    var fechastr: string[];

    var d: Date;
    fechastr = fecha.split("-");
    switch (this.user.idioma) {
      case 'eu': d = new Date(fecha.toString());
      default: d = new Date(fechastr[2] + "-" + fechastr[1] + "-" + fechastr[0]); break;
    }

    return d;
  }

  public updateGraficoEvolucion(grafico, oldData, newData) {

    var nombresOldData = oldData.map(function (x) {
      return x;
    });

    var nombresNewData = newData.map(function (x) {
      return x[0];
    });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      if (nombresNewData.indexOf(value) === -1 && value != "x") {
        nombresAEliminar.push(value);
      }
    }

    grafico.load({
      columns: newData,
      unload: nombresAEliminar
    });

  }


}
