import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { CursosLectivosTiposService } from '@app/_services/eskola_cursosLectivosTipos.service'
import { dateFieldName } from '@progress/kendo-angular-intl';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-cursoslectivostipostodos',
  templateUrl: 'eskola_cursoslectivostipostodos.component.html'
})

export class CursosLectivosTiposTodosComponent {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //COMBO
  public Jnombre: any;
  public JnombreSelected: any;
  public selectedValueNombre: any;
  public filtroContiene: string = "";

  //VARIABLES FILTRO
  status: boolean = true;
  public actualizarVisible: boolean = false;
  public nombreFiltro: string = '';
  public activoFiltro: number = 0;

  //GRID: consumibles
  public consumibles: any;
  public seleccionados: number[] = [];
  public grupos: any;
  public tiposGrupos: any;

  //VARIABLES DB
  public id: number = 0;
  public nombre: string = '';
  public descripcion: string = '';
  public activo: number = 0;


  //POPUP: Eliminar
  public popupEliminar: boolean = false;
  public popupErrorAlEliminar: boolean = false;

  user = this.userService.userValue;

  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  }; 
  

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal,
    private cursosLectivosTiposService: CursosLectivosTiposService,
    public myFunctions: MyFunctions,) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('tiposgrupo').toUpperCase();
  }
  ngOnInit() {
    this.cargarTipos();
  }

  cargarTipos() {
    this.loadingPanel = true;
    this.cursosLectivosTiposService.Get_tipos().subscribe(
      (json) => {
        this.tiposGrupos = json;
        this.Jnombre = json;
        this.JnombreSelected = '';
        this.loadingPanel = false;
      });
  }

  onClickNuevo() {
    this.router.navigate(['cursoslectivostipos/0']);
  }
  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['cursoslectivostipos/', this.seleccionados[0]]);
    }
  }

  onClickCopiar() {
    debugger;
    if (this.seleccionados[0] > 0) {
      this.seleccionados.forEach(seleccionado => {
        this.cursosLectivosTiposService.GetById(seleccionado).subscribe(
          (json) => {
            let nombreCopia: string = json[0].nombre + " (copia)";
            debugger;
            this.cursosLectivosTiposService.Create(nombreCopia, json[0].descripcion, json[0].activo).subscribe(
              (json) => {
                location.reload();
                this.router.navigate(['cursoslectivostipos']);                
              });
              
        });
      });
      
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['cursoslectivostipos/', this.seleccionados[0]]);
    }
  }
  onClickEliminar() {
    if (this.seleccionados.length > 0) {
      for( var i=0; i<this.seleccionados.length; i++ ) {
        this.cursosLectivosTiposService.Delete(this.seleccionados[i]).subscribe(
          (json) => {
            location.reload();
            this.router.navigate(['cursoslectivostipos']);
          });
      }
      
    }
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }


  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  onFilter() {
    this.cargarDatosInforme();
    this.status = true;
    this.actualizarVisible = false;
  }

  cargarDatosInforme() {
    this.loadingPanel = true;
    if (this.JnombreSelected == '' || this.JnombreSelected === undefined) {
      if(this.filtroContiene.toString() == ""){
        if (this.activoFiltro) {
          this.cursosLectivosTiposService.Get_tiposFiltrosActivo(1).subscribe(
            (json) => {
              this.tiposGrupos = json;
              this.loadingPanel = false;
            });
        } else {
          this.cursosLectivosTiposService.Get_tiposFiltrosActivo(0).subscribe(
            (json) => {
              this.tiposGrupos = json;
              this.loadingPanel = false;
            });
        }
      }else{
        if (this.activoFiltro) {
          this.cursosLectivosTiposService.Get_tiposFiltros(this.filtroContiene, 1).subscribe(
            (json) => {
              this.tiposGrupos = json;
              this.loadingPanel = false;
            });
        } else {
          this.cursosLectivosTiposService.Get_tiposFiltros(this.filtroContiene, 0).subscribe(
            (json) => {
              this.tiposGrupos = json;
              this.loadingPanel = false;
            });
        }
      }
    } else {
      if (this.activoFiltro) {
        this.cursosLectivosTiposService.Get_tiposFiltros(this.JnombreSelected.nombre, 1).subscribe(
          (json) => {
            this.tiposGrupos = json;
            this.loadingPanel = false;
          });
      } else {
        this.cursosLectivosTiposService.Get_tiposFiltros(this.JnombreSelected.nombre, 0).subscribe(
          (json) => {
            this.tiposGrupos = json;
            this.loadingPanel = false;
          });
      }
    }  
  }

  borrarFiltro() {
    this.nombreFiltro = '';
    this.activoFiltro = 0;
    this.cargarTipos();
  }

    

}
