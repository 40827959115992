<!-- GRID -->
<kendo-treelist kendoTreeListExpandable [kendoTreeListFlatBinding]="dataGrid" 
            [sortable]="true" 
            [navigable]="true"
            idField="id"
            (cellClick)="onClick_Editar($event)"
            parentIdField="idPadre"
            kendoTreeListSelectable
            [(selectedItems)]="seleccionados"
            [selectable]="settings"
            [initiallyExpanded]="true" parentIdField="idPadre">
    <!-- BOTONES ARRIBA -->
    <ng-template kendoTreeListToolbarTemplate position="top">
        <a (click)="onClick_Nuevo()" class="btn btn-primary btn-sm mr-1">
            {{ 'nuevo' | translate }}</a>
        <button (click)="onClick_Editar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate }}</button>
        <button (click)="onClick_Elimnar(eliminar)" class="btn btn-danger btn-sm mr-1">{{ 'eliminar' | translate }}</button>
    </ng-template>
    <!-- CHECKBOX -->
    <kendo-treelist-checkbox-column width="5%">
        <ng-template kendoTreeListHeaderTemplate>
            <input class="k-checkbox" id="selectAllCheckboxId" showSelectAll="true">
            <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
        </ng-template>
    </kendo-treelist-checkbox-column>
    <!-- NOMBRE -->
    <kendo-treelist-column [expandable]="true"  width="95%" [style]="{'text-align': 'left'}" field="nombre"
        title="{{ 'nombre' | translate}}"></kendo-treelist-column>

</kendo-treelist>

<!-- MODAL: Eliminar atributo -->
<ng-template #eliminar let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ "eliminaratributo" | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">
            {{ "no" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="eliminaratributos()">
            {{ "si" | translate }}
        </button>
    </div>
</ng-template>