<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <div class="card">
        <div class="card-body">
          <!--Tiempo programa hasta (min)-->
          <div class="form-group">
            <kendo-label text="{{ 'tiempoProgramaHastaMin' | translate }}">
              <kendo-numerictextbox class="form-control" formControlName="tiempoProgramaHastaMin" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>
          <!--Alarma leve-->
          <div class="form-group">
            <kendo-label text="{{ 'alarmaLeve' | translate }}">
              <kendo-numerictextbox class="form-control" formControlName="alarmaLeve" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>
          <!--Tipo leve-->
          <div class="form-group">
            <label class="control-label">{{ 'tipo' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist formControlName="idTipoLeve" [data]="tiposLeves" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="tiposLeves_selectionChange($event)" [value]="tipoLeve_selectedItem" class="form-control">
              </kendo-dropdownlist>
            </div>
          </div>
          <!--Alarma grave-->
          <div class="form-group">
            <kendo-label text="{{ 'alarmaGrave' | translate }}">
              <kendo-numerictextbox class="form-control" formControlName="alarmaGrave" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>
          <!--Tipo grave-->
          <div class="form-group">
            <label class="control-label">{{ 'tipo' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist formControlName="idTipoGrave" [data]="tiposGraves" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="tiposGraves_selectionChange($event)" [value]="tipoGrave_selectedItem" class="form-control">
              </kendo-dropdownlist>
            </div>
          </div>

          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
