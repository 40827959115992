
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- FILTRO -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
  <div class="card-header">
    <h3>{{ 'filtro' | translate}}</h3>
    <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i class="fas fa-times"></i></button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <!-- Filtro: CON GRUPOS -->
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
    <ng-template #recursiveListTmpl let-filtross="filtros">
      <!-- NOMBRE -->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'nombre' | translate }}</label>
        <div class="caja" style="width: 400px">
          <kendo-combobox [data]="Jnombre"
                          [textField]="'nombre'"
                          [valueField]="'id'"
                          (valueChange)='onOptionsSelected($event)'
                          [(value)]="JnombreSelected"
                          [(ngModel)]="selectedValueNombre"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          [class.requerido]="requiereNombre">
          </kendo-combobox>
        </div>
      </div>
      <!-- ACTIVO -->
      <div class="form-group">
        <kendo-label text="{{ 'activo' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-switch [(ngModel)]="activoFiltro" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
        </div>
      </div>
      <!-- CONTIENE -->
      <div class="float-left mr-1">
        <div class="caja" style="width: 400px">
          <label>{{ 'filterContainsOperator' | translate}}</label>
          <kendo-textbox value="" [(ngModel)]="filtroContiene" ></kendo-textbox>
        </div>
      </div>
    </ng-template>
    <div class="clearfix">
      <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()"> {{ 'filtrar' | translate }}</button>
      <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton' | translate }}</button>
    </div>
  </div>
</div>
<div>
  <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }}</button>
  <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button>
</div>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<!-- GRID -->
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <kendo-grid [kendoGridBinding]="tiposGrupos"
              [sortable]="true"
              [navigable]="true"
              [rowHeight]="36"
              [height]="750"
              [pageSize]="30"
              kendoGridSelectBy="id"
              scrollable="virtual"
              [selectedKeys]="seleccionados"
              (cellClick)="cellClick($event)">
    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">
      <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
      <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
      <button (click)="onClickCopiar()" class="btn btn-copiar btn-sm mr-1">{{ 'duplicar' | translate}}</button>
      <button (click)="onClickEliminar()" class="btn btn-danger btn-sm mr-1">{{ 'eliminar' | translate}}</button>
    </ng-template>
    <!--checkbox-->
    <kendo-grid-checkbox-column width="5%">
      <ng-template kendoGridHeaderTemplate>
        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
               [state]="selectAllState">
        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template>
    </kendo-grid-checkbox-column>
    <!--nombre-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
    <!--Descripcion-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>
    <!--Activo-->
    <!--<kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="activo" title="{{ 'activo' | translate}}"></kendo-grid-column>-->
    <!-- PRUEBA -->
    <kendo-grid-column width="5%" field="activo" [style]="{'text-align': 'center'}" 
        title="{{ 'activo' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.activo" [(value)]="dataItem.activo" disabled />
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>


<!-- POPUP: Eliminar -->
<div class="popup-cont" *ngIf="popupEliminar">
  <div class="popup" style="width: 300px;">
    <div class="popup-header">
      <h3>
        <label>{{'eliminar' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'preguntaeliminarpopup' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-danger mr-1" (click)="this.popupEliminar = false;">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="eliminarRegistro()" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>

<!-- POPUP: ERROR Eliminar -->
<div class="popup-cont" *ngIf="popupErrorAlEliminar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlEliminar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlEliminar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
