<style>
  .cont-filtro-maquinas-areas .cont-select-maquinas-out.desplegar {
    width: calc(100% - 630px) !important;
  }
</style>
<div class="clearfix">

  <div class="cont-filtro-maquinas-areas clearfix">
    <div class="filtro-areaproductiva-cont">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <!--AREAS PRODUCTIVAS-->
            <div class="col-md-12" style="margin-top: -3px;">
              <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [autoClose]="false"
                                 (close)="seccionChanged()"
                                 [(ngModel)]="seccionesSeleccionadas"
                                 placeholder="{{ 'secciones' | translate }}"
                                 style="width: 200px;"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
            <!--GRUPOS-->
            <div class="col-md-12" style="margin-top: 3px;">
              <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [autoClose]="false"
                                 (close)="seccionChanged()"
                                 [(ngModel)]="gruposSeleccionados"
                                 placeholder="{{ 'grupoMaquinas' | translate }}"
                                 style="width: 200px;"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
                </ng-template>
              </kendo-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MAQUINAS -->
    <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
      <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
        <i class="fas fa-ellipsis-v"></i>
      </div>
      <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
        <div *ngFor="let maquina of JmaquinasMostradas" (click)="selectedMaquina = maquina.id; desplegarMaquinasClick(); maquinaClicked();" class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina}">
          <label>{{maquina.nombre}}</label>
          <img class="fas fa-3x" [src]="maquina.imagenBase64">
        </div>

        <div *ngIf="loadingIncid" class="k-i-loading" style="z-index: 3;"></div>
      </div>

    </div>
    <!--Año | Mes-->
    <form [formGroup]="form">
      <div class="card cont-filtro-informes ml-2">
        <div class="card-body">
          <div class="row">
            <!--Año-->
            <div class="col-lg-6">
              <label class="control-label">{{ 'fecha' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox class="form-control" formControlName="ano" [min]="0" [max]="9999"
                                      [autoCorrect]="true" [format]="'#'" (valueChange)="ano_onValueChange($event)"></kendo-numerictextbox>
              </div>
            </div>
            <!--Mes-->
            <div class="col-lg-6 px-md-2">
              <div class="caja">
                <kendo-dropdownlist class="form-control" formControlName="meses" [data]="meses" [textField]="'nombre'"
                                    [valueField]="'id'" (selectionChange)="meses_selectionChange($event)" [value]="meses_selectedItem"></kendo-dropdownlist>
              </div>
            </div>
          </div>
          <div class="form-group" style="margin: 5px 0px 5px 5px;">
            <button (click)="cargarInforme()" [disabled]="loadingIncid" class="btn btn-success btn-sm mr-1">
              <span *ngIf="loadingIncid" class="spinner-border spinner-border-sm mr-1"></span> {{ 'cargarDisponibilidad' | translate }}
            </button>
            <button (click)="onClickImprimir()" class="btn btn-primary">
              <span *ngIf="loading"
                    class="spinner-border spinner-border-sm mr-1"></span><i class="fa fa-print"></i> {{ 'imprimir' | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>

</div>
<form [formGroup]="form">
  <!--CONTENIDO-->
  <div class="clearfix row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div class="card">
        <div class="card-body">

          <!--Inversión | Número de inventario-->
          <div class="row">
            <!--Inversión-->
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <kendo-label text="{{ 'inversion' | translate }}">
                  <div class="caja">
                    <kendo-textbox formControlName="inversion"></kendo-textbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--Número de inventario-->
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <kendo-label text="{{ 'numeroInventario' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="numeroInventario" [autoCorrect]="true" [format]="'#'">
                    </kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>

          <!--Disponibilidad en % por contrato de compra-->
          <div class="row">
            <!--Disponibilidad en % por contrato de compra-->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="form-group">
                <kendo-label text="{{ 'disponibilidadPorcentajeContratoCompra' | translate }}">
                  <div class="caja">
                    <kendo-textbox formControlName="disponibilidadPorcentajeContratoCompra" [disabled]="true">
                    </kendo-textbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>

          <!--Mes/Año-->
          <div class="row">
            <!--Mes/Año-->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="form-group">
                <kendo-label text="{{ 'mesAno' | translate }}">
                  <div class="caja">
                    <kendo-textbox formControlName="mesAno" [disabled]="true"></kendo-textbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>

          <!-- Loading -->
          <div class="row justify-content-center p-3" *ngIf="loadingIncid">
            <span class="spinner-border" style="width: 4rem; height: 4rem;"></span>
          </div>

          <!--RGListas-->
          <div id="divInforme"></div>

          <!--<div>
            <table id="semana">
              <tr>
                <th rowspan="2">
                  numSemana
                </th>
                <th colspan="7">
                  diasSemana
                </th>
              </tr>
              <tr>
                <th>
                  lunes
                </th>
                <th>
                  martes
                </th>
                <th>
                  miercoles
                </th>
                <th>
                  jueves
                </th>
                <th>
                  viernes
                </th>
                <th>
                  sabado
                </th>
                <th>
                  domingo
                </th>
              </tr>
              <tr>
                <td>
                  horasDisponibles
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
              </tr>
              <tr>
                <td>
                  horasInactivas
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
                <td>
                  0,00
                </td>
              </tr>
              <tr>
                <td>
                  totalHorasInactivasPorSemana
                </td>
                <td colspan="7">
                  0,00
                </td>
              </tr>
              <tr>
                <td>
                  totalHorasDisponiblesPorSemana
                </td>
                <td colspan="7">
                  0,00
                </td>
              </tr>
            </table>
          </div>-->
          <!--Project Manager-->
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <kendo-label text="{{ 'projectManager' | translate }}">
                  <div class="caja">
                    <kendo-textbox formControlName="projectManager"></kendo-textbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>

          <!--Fecha-->
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div class="form-group">
                <kendo-label text="{{ 'fechaFirma' | translate }}">
                  <div class="caja">
                    <kendo-datepicker formControlName="fechaFirma" [weekNumber]="true">
                      <kendo-calendar-messages today="{{ 'hoy' | translate }}"></kendo-calendar-messages>
                    </kendo-datepicker>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
