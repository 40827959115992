import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/perdidas`;

@Injectable()
export class PerdidasService {

  constructor(
    private http: HttpClient
  ) {
  }

  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''

    }

    return this.http
      .get(baseUrl);
  }

  public getPerdidas(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/getPerdidas`, { idMaquina }, { withCredentials: true });
  }

  public justificarMultiPerdida(timelinePerdidas, idsHistoricoProceso, justificarHastaFinProceso) {
    return this.http.post<JSON>(`${baseUrl}/justificarMultiPerdida`, { timelinePerdidas, idsHistoricoProceso, justificarHastaFinProceso }, { withCredentials: true });
  }

  public Update_historico_procesos_perdidas(titulo, id, idTipoPerdida, idHistorico_Proceso) {
    return this.http.post<JSON>(`${baseUrl}/Update_historico_procesos_perdidas`, { titulo, id, idTipoPerdida, idHistorico_Proceso}, { withCredentials: true });
  }

}
