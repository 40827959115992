import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UsuariosService, AlertService, MenuService, MaquinasService, AreasProductivasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { State, process } from '@progress/kendo-data-query';
import { Renderer2, NgZone, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RowClassArgs, CellClickEvent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Location } from '@angular/common';


const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-editar-crear-maquinasGrupo',
  templateUrl: './editar-crear-maquinasGrupo.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`.k-grid tr.dragging {
                background-color: #00b8bd;
            };`]
})

export class EditarCrearMaquinasGrupoComponent implements OnInit, AfterViewInit, OnDestroy {

  public state: State = {
    skip: 0,
    take: 100
  };
  public gridData: any;
  private currentSubscription: Subscription;

  public fromAllMaquinasSelect: boolean = false;
  public buttonAllMaquinasSelectName: string = '';

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  checkscargados: boolean;
  loading = false;
  submitted = false;
  user = this.usuariosService.userValue;
  dataGrupo: any;
  dataMaquinasGrupo: any[];
  maquina: any;
  row: any;
  mySelection2: number[] = [];
  mySelection2Aux: number[] = [];

  JSeccion: any;  //TODOOOO
  JSeccionSelected: any;   //TODOOOO
  JDatSeccion: any;    //TODOOOO

  JAreaProductiva: any;  //TODOOOO
  JAreaProductivaSelected: any;   //TODOOOO
  JDatAreaProductiva: any;    //TODOOOO
  JAPDisabled: boolean = false;

  previousUrl: string = null;
  currentUrl: string = null;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private areasProductivasService: AreasProductivasService,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    private menuService: MenuService,
    private maquinasService: MaquinasService,
    private renderer: Renderer2,
    private location: Location,
    private zone: NgZone
  ) {



    this.gridData = process([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}], this.state);
    if ((this.gridData.data.length <= this.mySelection2Aux.length) || this.mySelection2Aux.length == 0) this.buttonAllMaquinasSelectName = this.translateService.instant('seleccionarTodo');
    else this.buttonAllMaquinasSelectName = this.translateService.instant('deseleccionarTodo');

    this.cargarCombos();
  }

  ngOnInit() {

    this.menuService.titulo = this.translateService.instant('maquinasgrupo');
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.checkscargados = true;


    this.form = this.formBuilder.group({
      idGrupo: this.route.snapshot.params['id'],
      idDb: this.user.idDb,
      nombre: new FormControl('', [Validators.required]),
      orden: new FormControl(0, [Validators.required]),
      // idSeccion: -1,
      activo: new FormControl(true),
      idMaquinas: '',
      allMaquinas: '',
      ordenMaquinas: ''
    });

    this.maquinasService.getGrupoMaquinasById(this.id).subscribe(json => {
      this.dataGrupo = json.data[0];
      this.form.patchValue(this.dataGrupo);

      // if (this.form.value.nombre == '')
        //if (this.route.snapshot.params.seccion.length>0) {this.form.value.idSeccion=this.route.snapshot.params.seccion;}
        // if (this.route.snapshot.paramMap.get("seccion") != null) { this.form.value.idSeccion = this.route.snapshot.params.seccion; }



      this.dataMaquinasGrupo = json.data2;
      this.gridData = process(json.data2, this.state);
      this.selectMaquinas();

      //seccion eta area productiva comboetan selekzionatutako balioa jarri
      // this.JSeccionSelected = this.JSeccion.filter(f => f.id == this.form.value.idSeccion)[0];

      // if (this.JSeccionSelected == undefined) { this.JAPDisabled = true; }
      // else {
      //   this.JAPDisabled = false;
      //   this.form.value.idSeccion = this.JSeccionSelected.id;
      // }

      //areaproductiva selected balioa jarri.
      if (!this.JAPDisabled) this.JAreaProductivaSelected = this.JAreaProductiva.filter(f => f.id == this.JSeccionSelected.idAreaProductiva)[0];
      this.JAPDisabled = true;

    });



  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  selectMaquinas() {
    var i = 1;
    this.mySelection2 = [];
    this.mySelection2Aux = [];
    for (this.maquina of this.dataMaquinasGrupo) {
      if (this.maquina.seleccionado == true) {
        this.mySelection2.push(this.maquina.id);
      }
      i = i + 1;
    }
    this.mySelection2Aux = Object.assign([], this.mySelection2); //kopia
    if(this.mySelection2.length < this.dataMaquinasGrupo.length){
      this.buttonAllMaquinasSelectName = this.translateService.instant('seleccionarTodo');
    }else{
      this.buttonAllMaquinasSelectName = this.translateService.instant('deseleccionarTodo');
    }
  }

  guardarMaquinas() {
    var i = 0;
    for (this.maquina of this.dataMaquinasGrupo) {
      this.dataMaquinasGrupo[i].seleccionado = false;
      i = i + 1;
    }

    for (this.row of this.mySelection2) {
      i = 0
      for (this.maquina of this.dataMaquinasGrupo) {
        if (this.maquina.id == this.row) {
          this.dataMaquinasGrupo[i].seleccionado = true;
          break;
        }
        i = i + 1;
      }

    }
    var id = 0;
    for (this.maquina of this.dataMaquinasGrupo) {

      if (id == 0) {
        this.form.get('allMaquinas').setValue(this.maquina.id);
        this.form.get('ordenMaquinas').setValue(this.maquina.orden);
        if (this.maquina.seleccionado == true) {
          this.form.get('idMaquinas').setValue(this.maquina.id);
          id = 1;
        }
      } else {
        this.form.get('allMaquinas').setValue(this.form.get('allMaquinas').value + ',' + this.maquina.id);
        this.form.get('ordenMaquinas').setValue(this.form.get('ordenMaquinas').value + ',' + this.maquina.orden);
        if (this.maquina.seleccionado == true) {
          this.form.get('idMaquinas').setValue(this.form.get('idMaquinas').value + ',' + this.maquina.id);
        }
      }
    }

    // if (this.JSeccionSelected != undefined) this.form.value.idSeccion = this.JSeccionSelected.id;
  }



  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.changeOrdenMaquinas();

    this.submitted = true;
    this.guardarMaquinas()
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.crearGrupo();
    } else {
      this.updateGrupo();
    }
    if (this.fromAllMaquinasSelect) {
      //this.fromAllMaquinasSelect = false;
    }

  }

  goBack() {
    // window.history.back();
    this.location.back();

    
  }

  private crearGrupo() {
    var createGrupo = {
      nombre: this.form.value.nombre,
      orden: this.form.value.orden,
      activo: this.form.value.activo,
      idMaquinas: this.form.value.idMaquinas
    }
    this.maquinasService.createGrupoMaquinas(createGrupo)
      .pipe(first())
      .subscribe({
        next: () => {

          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          //this.router.navigate(['../'], { relativeTo: this.route });
          this.goBack();

        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateGrupo() {
    this.maquinasService.updateGrupoMaquinas(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          //this.router.navigate(['../../'], { relativeTo: this.route });
          this.goBack();
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging
    };
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    
    
    tableRows.forEach(item => {
      
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }

  // public showGridTooltip(e: MouseEvent): void {
  //   const element = e.target as HTMLElement;
  //   if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
  //     && element.offsetWidth < element.scrollWidth) {
  //     this.tooltipDir.toggle(element);
  //   } else {
  //     this.tooltipDir.hide();
  //   }
  // }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  private checkAllMaquinas(e) {
    e.preventDefault();
    if (this.gridData.data.length <= this.mySelection2Aux.length && this.gridData.data.length <= this.mySelection2.length) {
      this.mySelection2Aux = [];
      this.buttonAllMaquinasSelectName = this.translateService.instant('seleccionarTodo');
    }
    else {
      this.gridData.data.forEach(f => {
        //f.seleccionado = true;
        this.mySelection2Aux.push(f.id);
      });
      this.buttonAllMaquinasSelectName = this.translateService.instant('deseleccionarTodo');
    }
    this.fromAllMaquinasSelect = true;
    //this.mySelectionAux=this.mySelection;
    this.mySelection2 = Object.assign([], this.mySelection2Aux);
  }


  private changeOrdenMaquinas() {
    let order = 1;
    this.gridData.data.forEach(elem => {
      elem.orden = order;
      order++;
    });
  }

  public cellClickHandler(e: CellClickEvent): void {
    if (this.gridData.data.length == this.mySelection2.length) this.gridData.data.forEach(f => { this.mySelection2Aux.push(f.id); });
    if (this.mySelection2.length == 0) this.mySelection2Aux = [];

    if (this.mySelection2Aux.includes(e.dataItem.id)) {
      //remove id from myselection
      const index = this.mySelection2Aux.indexOf(e.dataItem.id, 0);
      if (index > -1) {
        this.mySelection2Aux.splice(index, 1);
      }
      this.buttonAllMaquinasSelectName = this.translateService.instant('seleccionarTodo');
    }
    else {
      this.mySelection2Aux.push(e.dataItem.id)
    }
    this.mySelection2 = Object.assign([], this.mySelection2Aux);

  }


  public cargarCombos() {
    this.JAPDisabled = true;
    //secciones
    this.areasProductivasService.Seccion_Get_All().subscribe(
      json => {
        this.JDatSeccion = json;
        this.JSeccion = this.JDatSeccion;
      }
    );

    //areaproductiva (todas las áreas reproductivas)
    this.areasProductivasService.Get(-1).subscribe(
      json1 => {
        this.JDatAreaProductiva = json1;
        this.JAreaProductiva = this.JDatAreaProductiva;
      }
    );
  }

  public seccionChanged(seccionObject: any): void    //TODOOOOO
  {
    if (seccionObject == undefined) this.JAPDisabled = true;
    else {
      this.JAPDisabled = false;
      this.form.value.idSeccion = this.JSeccionSelected.id;
    }

    if (!this.JAPDisabled) this.JAreaProductivaSelected = this.JAreaProductiva.filter(f => f.id == seccionObject.idAreaProductiva)[0];
    this.JAPDisabled = true;
  }

  public areaProductivaChanged(value: String): void    //TODOOOOO
  {
    let cambio = 1;
    // this.loadingPanel = false;
    // let nSerievalue=this.JnSerieSelected;
    // if (typeof this.JnSerieSelected ==='string')
    // {
    // this.JnSerieSelected={};
    // this.JnSerieSelected['rn']=this.JnSerie[0].rn;
    // this.JnSerieSelected['idOF']=this.JnSerie[0].idOF;
    // this.JnSerieSelected['idPieza']=this.JnSerie[0].idPieza;
    // this.JnSerieSelected['idParte']=this.JnSerie[0].idParte;
    // this.JnSerieSelected['nSerie']=nSerievalue;
    // this.JnSerieSelected['idHP']=this.JnSerie[0].idHP;

    // this.JnSerie.push(this.JnSerieSelected);
    // this.cargarCombos();
    // }

  }


}
