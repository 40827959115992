<div class="grafico-forma-cont">

  <div class="forma">
  
  <div class="barra-interior-forma" [ngStyle]="{'height': calcularAltura()}">
  
  </div>
  
  </div>
  
  
  
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 393.3 653.9" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#00FFC2;}
  </style>
  <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
    <path d="M1,0.112 c0,-0.028,-0.101,-0.058,-0.251,-0.075 v-0.01 c0,-0.02,-0.109,-0.027,-0.244,-0.027 c-0.002,0,-0.007,0,-0.009,0 c-0.135,0,-0.244,0.006,-0.244,0.027 v0.01 C0.101,0.054,0,0.084,0,0.112 v0.676 c0,0.005,0.007,0.011,0.02,0.018 V0.921 c0,0.008,0.016,0.015,0.035,0.015 h0 c0.02,0,0.035,-0.007,0.035,-0.015 v-0.025 c0.023,0.005,0.052,0.009,0.087,0.013 c0.078,0.009,0.178,0.014,0.285,0.015 V0.985 c0,0.008,0.016,0.015,0.035,0.015 s0.035,-0.007,0.035,-0.015 v-0.061 c0.108,-0.001,0.208,-0.006,0.285,-0.015 c0.035,-0.004,0.064,-0.008,0.087,-0.013 V0.921 c0,0.008,0.016,0.015,0.035,0.015 h0 c0.02,0,0.035,-0.007,0.035,-0.015 V0.807 c0.015,-0.007,0.023,-0.014,0.023,-0.019 V0.112 M0.906,0.851 c-0.013,-0.003,-0.028,-0.005,-0.044,-0.008 c0.016,-0.004,0.031,-0.007,0.044,-0.011 V0.851 M0.091,0.831 c0.014,0.004,0.029,0.008,0.046,0.012 c-0.017,0.002,-0.033,0.005,-0.046,0.008 V0.831 M0.193,0.885 c-0.063,-0.007,-0.091,-0.014,-0.102,-0.019 v0.014 c0.011,-0.004,0.039,-0.012,0.102,-0.019 c0.008,-0.001,0.017,-0.002,0.026,-0.003 c0.071,0.012,0.154,0.021,0.244,0.022 v0.018 C0.361,0.898,0.266,0.894,0.193,0.885 M0.804,0.885 c-0.073,0.008,-0.168,0.013,-0.27,0.014 v-0.018 c0.091,-0.002,0.175,-0.011,0.246,-0.022 c0.008,0.001,0.017,0.002,0.024,0.002 c0.063,0.007,0.091,0.014,0.102,0.019 v-0.014 C0.895,0.871,0.867,0.879,0.804,0.885" class="st0"></path>
  </clipPath>
  </svg>
  
  </div>
    
    