import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';


/* SERVICES*/
import { MaquinasService, UsuariosService, PlanificadorService, MenuService, ConfiguracionService, HistoricoOperacionesService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { GridComponent } from '@progress/kendo-angular-grid';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { Observable, iif } from 'rxjs';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

//D&D start
import { process, State } from '@progress/kendo-data-query';
import { fromEvent, Subscription } from 'rxjs';
import { Renderer2, NgZone } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { AnimationBuilder } from '@angular/animations';
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';
//D&D end

// ALETR GUARDADO
import { AlertService } from '@app/_services';


@Component({
  selector: 'app-planificadorLista',
  templateUrl: './planificadorLista.component.html'
})

export class PlanificadorLista {
  public that = this;
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public Jmaquinas: any;
  public JmaquinasMostradas: any;
  public selectedMaquinas: any = [];

  //Contenidos tooltips headers
  public contenidoTienePrograma: any;

  public loadingPanel: boolean = false;
  public loadingGrid: boolean = false;

  public Jplanificadores: any;
  public JplanificadoresSelected: any;

  public mostrarCorto: boolean = false;
  public mostrarLargo: boolean = false;

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //MAQUINA VISIBLE/INVISIBLE (PARA EXPORTAR A EXCEL)
  maquinaVisible: any = "hidden";
  public aparecer = false;

  //DATOS DE CONFIGURACION
  public ocultarParte: number = 0;

  popupExportarMaquinas: boolean = false;
  maquinasSeleccionadasExportar: any = [];

  // D&D start
  public stateDrag: State = {
    skip: 0,
    take: 100000000 // si se reduce, se limita el numero de lineas del Excel
  };
  public gridData: any = process([], this.stateDrag);
  public JPlanificadorLista: any;

  private currentSubscription: Subscription;
  public targetCells: NodeListOf<HTMLTableDataCellElement>;
  public JOperacionesSelecteds = [];
  // D&D end

  //DIRECCION DEL FICHERO DE COPIA
  public fichero: any = '';

  // GUARDAR
  public guardarActivo = false;

  public esTTTbergara = false;

  //"LOAD"
  constructor(
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private planificadorService: PlanificadorService,
    private historicoOperacionesService: HistoricoOperacionesService,
    public router: Router,
    private menuService: MenuService,
    public route: ActivatedRoute,
    private renderer: Renderer2,
    private zone: NgZone,
    private configuracionService: ConfiguracionService,
    private alertService: AlertService,
    public myFunctions: MyFunctions
  ) {

    
    var configuracion_model = this.userService.get_configuracion_model();
    if (configuracion_model == false) {
      this.userService.getConfiguracionVariables().subscribe(json => {
        this.userService.set_configuracion_model(json);
        if (json[0].nombreEmpresa != undefined)
          this.esTTTbergara = ('TTT BERGARA' == json[0].nombreEmpresa.toUpperCase() );
      })
    } else {
      if (configuracion_model[0].nombreEmpresa != undefined)
        this.esTTTbergara = ('TTT BERGARA' == configuracion_model[0].nombreEmpresa.toUpperCase() );
    }

    this.loadingPanel = true;

    this.menuService.titulo = this.translateService.instant('lista').toUpperCase();
    this.contenidoTienePrograma = this.translateService.instant('tieneProgramaCNC');

    this.selectedMaquinas = [parseInt(this.route.snapshot.params['idMaquina'])];

    
    this.configuracionService.get_configuracion_planificador().subscribe(result => {
        this.ocultarParte = result[0].ocultarParte; // esta configuracion deveria de ser general, pero la consulta general se coge con un select * de 2 tablas y ambas tienen la misma columna
    })

    this.cargarGrupos();
    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });


        });
    }
    else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
    }



    if (this.route.snapshot.params['tipo'] == 1) {
      this.mostrarLargo = true;
    }
    else if (this.route.snapshot.params['tipo'] == 2) {
      this.mostrarCorto = true;
    }
    else {
      this.mostrarLargo = true;
      this.mostrarCorto = true;
    }

    this.planificadorService.getPlanificadoresActivos(this.translateService.instant('real')).subscribe(
      json => {
        this.Jplanificadores = json;

        this.JplanificadoresSelected = json[0];

        // this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
        //   this.ocultarParte = result[0].ocultarParte; // una empresa no oculta y muestra partes segun planificador!
        // });

        var maquinas_planificador_model = this.maquinasService.get_maquinas_planificador_model();
        if (maquinas_planificador_model == false) {
          this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
            this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
            this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();

            console.log(this.Jmaquinas)

            //FLTRO POR SECCIONES
            var idsSeccionesSelecteds: any = [];
            if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
              this.seccionesSeleccionadas.forEach(
                seccion => {
                  idsSeccionesSelecteds.push(seccion.id);
                });
            } else {
              this.secciones.forEach(
                seccion => {
                  idsSeccionesSelecteds.push(seccion.id);
                });
            }
            this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))
            this.JmaquinasMostradas.push({ id: -1, nombre: this.translateService.instant('fueraDeTaller'), imagen: "fueraDeTaller.png", idERP: "", imagenBase64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAyCAIAAACI1vFAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAd9JREFUeNrsmFurgkAQx0sWKU0iIiJ68vt/qJ5EJEIpswsR588ZGGJL87Kd48bMk6w4s7+dy844zPN88BUyFBIhERIhERIhERIh0WWz2fz9jsMwfLnuDL5FhKR/opp+cLvd+lkkmpGAYbvdWh9dfcZo4JOXGKPRiOINYodPNIzFYkEPq18JgsCO6HrGmEwm9uWJLRhvSCzCqCK5Xq8WYVSRFEVhEUatKqyUQpHNsuz5FS2eTic7SICRpunLV2Xr0kEKiZDYU7vu9/t+v+9JRepEAoyyouQ4juu6uDRB+5HdKAUT0P/ZSQs9/HK5hCU84/o3Pjny/QuSOI6bHlbdPAEAY5BVOMcgxmw24zYCmnlw6OQTz/M4SXiKgnbGIPF9v10YlDlc24MBEmwa8xP3I5Qzz1Ph5XJhq3BXOwD+96fpfzyj9Xpdx/9voovPBpZ2u91jw88t5ng8bu0K1g/lDIMM4TacaoyBjIcW6iCppp3PZwQVvMEYsNSlTcbkTKqw+yiKAAaFWGGq6XRaU9X7/8LVlaR7w19RBinOtfxsT0IwSZJooQwD8/ncyNyC0ILDK4q+MRLyPpx+PB7JOYgxMNQ3U+ewDocDJTriGVGnVTNjJP0XIRESIRESIflP+RFgAAIwSQhnd/J+AAAAAElFTkSuQmCC", visblePlanificador: true, idSeccion: -1, idGrupos: "" })
            //this.JmaquinasMostradas.unshift({ id: -1, nombre: this.translateService.instant('fueraDeTaller'), imagen: "fueraDeTaller.png", idERP: "", imagenBase64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAyCAIAAACI1vFAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAd9JREFUeNrsmFurgkAQx0sWKU0iIiJ68vt/qJ5EJEIpswsR588ZGGJL87Kd48bMk6w4s7+dy844zPN88BUyFBIhERIhERIhERIh0WWz2fz9jsMwfLnuDL5FhKR/opp+cLvd+lkkmpGAYbvdWh9dfcZo4JOXGKPRiOINYodPNIzFYkEPq18JgsCO6HrGmEwm9uWJLRhvSCzCqCK5Xq8WYVSRFEVhEUatKqyUQpHNsuz5FS2eTic7SICRpunLV2Xr0kEKiZDYU7vu9/t+v+9JRepEAoyyouQ4juu6uDRB+5HdKAUT0P/ZSQs9/HK5hCU84/o3Pjny/QuSOI6bHlbdPAEAY5BVOMcgxmw24zYCmnlw6OQTz/M4SXiKgnbGIPF9v10YlDlc24MBEmwa8xP3I5Qzz1Ph5XJhq3BXOwD+96fpfzyj9Xpdx/9voovPBpZ2u91jw88t5ng8bu0K1g/lDIMM4TacaoyBjIcW6iCppp3PZwQVvMEYsNSlTcbkTKqw+yiKAAaFWGGq6XRaU9X7/8LVlaR7w19RBinOtfxsT0IwSZJooQwD8/ncyNyC0ILDK4q+MRLyPpx+PB7JOYgxMNQ3U+ewDocDJTriGVGnVTNjJP0XIRESIRESIflP+RFgAAIwSQhnd/J+AAAAAElFTkSuQmCC", visblePlanificador: true, idSeccion: -1, idGrupos: "" })

            // AUTOSELECT solo en la primera maquina
            if (this.selectedMaquinas.length == 0 || (this.selectedMaquinas.length == 1 && this.selectedMaquinas[0] == 0))
              this.selectedMaquinas = [this.JmaquinasMostradas[0].id];

            // AUTOSELECT de todas las maquinas
            //this.JmaquinasMostradas.forEach(
            //  maquina => {
            //    this.selectedMaquinas.push(maquina.id);
            //  });

            //this.cargarMaquinas();
            this.cargarDatos();
          })
        } else {
          this.Jmaquinas = maquinas_planificador_model;

          //FLTRO POR SECCIONES
          var idsSeccionesSelecteds: any = [];
          if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
            this.seccionesSeleccionadas.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          } else {
            this.secciones.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          }
          this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))
          this.JmaquinasMostradas.push({ id: -1, nombre: this.translateService.instant('fueraDeTaller'), imagen: "fueraDeTaller.png", idERP: "", imagenBase64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAyCAIAAACI1vFAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAd9JREFUeNrsmFurgkAQx0sWKU0iIiJ68vt/qJ5EJEIpswsR588ZGGJL87Kd48bMk6w4s7+dy844zPN88BUyFBIhERIhERIhERIh0WWz2fz9jsMwfLnuDL5FhKR/opp+cLvd+lkkmpGAYbvdWh9dfcZo4JOXGKPRiOINYodPNIzFYkEPq18JgsCO6HrGmEwm9uWJLRhvSCzCqCK5Xq8WYVSRFEVhEUatKqyUQpHNsuz5FS2eTic7SICRpunLV2Xr0kEKiZDYU7vu9/t+v+9JRepEAoyyouQ4juu6uDRB+5HdKAUT0P/ZSQs9/HK5hCU84/o3Pjny/QuSOI6bHlbdPAEAY5BVOMcgxmw24zYCmnlw6OQTz/M4SXiKgnbGIPF9v10YlDlc24MBEmwa8xP3I5Qzz1Ph5XJhq3BXOwD+96fpfzyj9Xpdx/9voovPBpZ2u91jw88t5ng8bu0K1g/lDIMM4TacaoyBjIcW6iCppp3PZwQVvMEYsNSlTcbkTKqw+yiKAAaFWGGq6XRaU9X7/8LVlaR7w19RBinOtfxsT0IwSZJooQwD8/ncyNyC0ILDK4q+MRLyPpx+PB7JOYgxMNQ3U+ewDocDJTriGVGnVTNjJP0XIRESIRESIflP+RFgAAIwSQhnd/J+AAAAAElFTkSuQmCC", visblePlanificador: true, idSeccion: -1, idGrupos: "" })
          //this.JmaquinasMostradas.unshift({ id: -1, nombre: this.translateService.instant('fueraDeTaller'), imagen: "fueraDeTaller.png", idERP: "", imagenBase64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAyCAIAAACI1vFAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAd9JREFUeNrsmFurgkAQx0sWKU0iIiJ68vt/qJ5EJEIpswsR588ZGGJL87Kd48bMk6w4s7+dy844zPN88BUyFBIhERIhERIhERIh0WWz2fz9jsMwfLnuDL5FhKR/opp+cLvd+lkkmpGAYbvdWh9dfcZo4JOXGKPRiOINYodPNIzFYkEPq18JgsCO6HrGmEwm9uWJLRhvSCzCqCK5Xq8WYVSRFEVhEUatKqyUQpHNsuz5FS2eTic7SICRpunLV2Xr0kEKiZDYU7vu9/t+v+9JRepEAoyyouQ4juu6uDRB+5HdKAUT0P/ZSQs9/HK5hCU84/o3Pjny/QuSOI6bHlbdPAEAY5BVOMcgxmw24zYCmnlw6OQTz/M4SXiKgnbGIPF9v10YlDlc24MBEmwa8xP3I5Qzz1Ph5XJhq3BXOwD+96fpfzyj9Xpdx/9voovPBpZ2u91jw88t5ng8bu0K1g/lDIMM4TacaoyBjIcW6iCppp3PZwQVvMEYsNSlTcbkTKqw+yiKAAaFWGGq6XRaU9X7/8LVlaR7w19RBinOtfxsT0IwSZJooQwD8/ncyNyC0ILDK4q+MRLyPpx+PB7JOYgxMNQ3U+ewDocDJTriGVGnVTNjJP0XIRESIRESIflP+RFgAAIwSQhnd/J+AAAAAElFTkSuQmCC", visblePlanificador: true, idSeccion: -1, idGrupos: "" })


          // AUTOSELECT solo en la primera maquina
          if (this.selectedMaquinas.length == 0 || (this.selectedMaquinas.length == 1 && this.selectedMaquinas[0] == 0))
            this.selectedMaquinas = [this.JmaquinasMostradas[0].id];

          // AUTOSELECT de todas las maquinas
          //this.JmaquinasMostradas.forEach(
          //  maquina => {
          //    this.selectedMaquinas.push(maquina.id);
          //  });

          //this.cargarMaquinas();
          this.cargarDatos();
        }
      });


    //PARA IMPRIMIR EXCEL SIN TENER EN CUENTA LA PAGINACION 
    this.allData = this.allData.bind(this);
  }

  clickMaquina(maquina) {

    if (this.selectedMaquinas.includes(maquina.id)) {
      this.selectedMaquinas.splice(this.selectedMaquinas.indexOf(maquina.id), 1)
    } else {
      this.selectedMaquinas.push(maquina.id);
    }
    this.cargarDatos();
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinasPlanificadorTodos().subscribe(json => {
      this.grupos = json.data;
    });

  }

  ngOnInit() { }

  seccionChanged() {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    } else {
      this.grupos.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }
    this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.JmaquinasMostradas.push({ id: -1, nombre: this.translateService.instant('fueraDeTaller'), imagen: "fueraDeTaller.png", idERP: "", imagenBase64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAyCAIAAACI1vFAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAd9JREFUeNrsmFurgkAQx0sWKU0iIiJ68vt/qJ5EJEIpswsR588ZGGJL87Kd48bMk6w4s7+dy844zPN88BUyFBIhERIhERIhERIh0WWz2fz9jsMwfLnuDL5FhKR/opp+cLvd+lkkmpGAYbvdWh9dfcZo4JOXGKPRiOINYodPNIzFYkEPq18JgsCO6HrGmEwm9uWJLRhvSCzCqCK5Xq8WYVSRFEVhEUatKqyUQpHNsuz5FS2eTic7SICRpunLV2Xr0kEKiZDYU7vu9/t+v+9JRepEAoyyouQ4juu6uDRB+5HdKAUT0P/ZSQs9/HK5hCU84/o3Pjny/QuSOI6bHlbdPAEAY5BVOMcgxmw24zYCmnlw6OQTz/M4SXiKgnbGIPF9v10YlDlc24MBEmwa8xP3I5Qzz1Ph5XJhq3BXOwD+96fpfzyj9Xpdx/9voovPBpZ2u91jw88t5ng8bu0K1g/lDIMM4TacaoyBjIcW6iCppp3PZwQVvMEYsNSlTcbkTKqw+yiKAAaFWGGq6XRaU9X7/8LVlaR7w19RBinOtfxsT0IwSZJooQwD8/ncyNyC0ILDK4q+MRLyPpx+PB7JOYgxMNQ3U+ewDocDJTriGVGnVTNjJP0XIRESIRESIflP+RFgAAIwSQhnd/J+AAAAAElFTkSuQmCC", visblePlanificador: true, idSeccion: -1, idGrupos: "" })
    //this.JmaquinasMostradas.unshift({ id: -1, nombre: this.translateService.instant('fueraDeTaller'), imagen: "fueraDeTaller.png", idERP: "", imagenBase64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAyCAIAAACI1vFAAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAd9JREFUeNrsmFurgkAQx0sWKU0iIiJ68vt/qJ5EJEIpswsR588ZGGJL87Kd48bMk6w4s7+dy844zPN88BUyFBIhERIhERIhERIh0WWz2fz9jsMwfLnuDL5FhKR/opp+cLvd+lkkmpGAYbvdWh9dfcZo4JOXGKPRiOINYodPNIzFYkEPq18JgsCO6HrGmEwm9uWJLRhvSCzCqCK5Xq8WYVSRFEVhEUatKqyUQpHNsuz5FS2eTic7SICRpunLV2Xr0kEKiZDYU7vu9/t+v+9JRepEAoyyouQ4juu6uDRB+5HdKAUT0P/ZSQs9/HK5hCU84/o3Pjny/QuSOI6bHlbdPAEAY5BVOMcgxmw24zYCmnlw6OQTz/M4SXiKgnbGIPF9v10YlDlc24MBEmwa8xP3I5Qzz1Ph5XJhq3BXOwD+96fpfzyj9Xpdx/9voovPBpZ2u91jw88t5ng8bu0K1g/lDIMM4TacaoyBjIcW6iCppp3PZwQVvMEYsNSlTcbkTKqw+yiKAAaFWGGq6XRaU9X7/8LVlaR7w19RBinOtfxsT0IwSZJooQwD8/ncyNyC0ILDK4q+MRLyPpx+PB7JOYgxMNQ3U+ewDocDJTriGVGnVTNjJP0XIRESIRESIflP+RFgAAIwSQhnd/J+AAAAAElFTkSuQmCC", visblePlanificador: true, idSeccion: -1, idGrupos: "" })

  }

  cargarDatos() {

    this.loadingGrid = true;

    var version = 1;
    if (this.JplanificadoresSelected != undefined) version = this.JplanificadoresSelected.value;

    if (this.selectedMaquinas.length == 0) {
      this.gridData = process([], this.stateDrag);
      this.loadingPanel = false;
      this.loadingGrid = false;
    } else {
      this.planificadorService.GetTodo(version, this.mostrarCorto, this.mostrarLargo, this.selectedMaquinas.toString()).subscribe(
        json => {
          var an: any = json;

          an.forEach(
            row => {
              //row.tiempoTotalEnSegundosHHMMSS = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoTotalEnSegundos);
              if (row.idMaquina == -1) {
                row.maquina = this.translateService.instant('fueraDeTaller');
              }
              if (row.tienePrograma == 0) {
                row.tieneProgramaText = this.translateService.instant('no');
              }
              else {
                row.tieneProgramaText = this.translateService.instant('si');
              }
              if (row.tieneHerramienta == 0) {
                row.tieneHerramientaText = this.translateService.instant('no');
              }
              else {
                row.tieneHerramientaText = this.translateService.instant('si');
              }

              row.fechaIni = new Date(row.fechaIni);
              row.fechaFin = new Date(row.fechaFin);

              row.fechaLimite_excel = (new Date(row.fechaLimite)).getTime() < (new Date(1993)).getTime() ? '' :  this.myFunctions.dateToYYYYMMDD(new Date(row.fechaLimite));

              var a = (new Date(row.fecha_previa)).getTime();
              var b = (new Date(1993)).getTime();

              row.fecha_previa_excel = (new Date(row.fecha_previa)).getTime() < (new Date(1993)).getTime() ? '' :  this.myFunctions.dateToYYYYMMDD(new Date(row.fecha_previa));
              row.fecha_proxima_excel = (new Date(row.fecha_proxima)).getTime() < (new Date(1993)).getTime() ? '' :  this.myFunctions.dateToYYYYMMDD(new Date(row.fecha_proxima));

            });

          this.gridData = process(an, this.stateDrag);
          this.JPlanificadorLista = an;
          this.loadingPanel = false;
          this.loadingGrid = false;

          if (this.currentSubscription != undefined)
            this.currentSubscription.unsubscribe();

          var soloUnaMaquina = true;
          var idMaquina = -1;
          this.gridData.data.forEach(
            row => {
              if (idMaquina > 0 && idMaquina != row.idMaquina) soloUnaMaquina = false;
              idMaquina = row.idMaquina;
            });
          // SOLO SE ACTIVARA EL D&D SI HAY UNA SOLA MAQUINA EN EL GRID
          if (soloUnaMaquina && !this.mostrarLargo && this.mostrarCorto) {
            this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
            this.guardarActivo = true;
          }
          else
            this.guardarActivo = false;


          //ARATZ: mirar como es la tabla que despues se guarda
          //console.log("*******************************************************************");
          //console.table(this.gridData.data);
          //console.log("*******************************************************************");
        });
    }

  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  versionChanged(event) {
    this.loadingPanel = true;
    this.JplanificadoresSelected = event;
    // this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
    //   this.ocultarParte = result[0].ocultarParte; // una empresa no oculta y muestra partes segun planificador!
    // });
    this.cargarDatos();
  }
  btnMostrarCorto() {
    this.loadingPanel = true;
    this.mostrarCorto = !this.mostrarCorto
    this.cargarDatos();
  }
  btnMostrarLargo() {
    this.loadingPanel = true;
    this.mostrarLargo = !this.mostrarLargo
    this.cargarDatos();
  }

  imprimirXls(e, grid: GridComponent) {
    console.log("________________________________________________________________________________")
    console.log(grid)
    e.preventDefault();
    setTimeout(function () {
      grid.saveAsExcel();
    });
  }

  //Cargar datos de la ruta
  public cargarURLRuta() {
    // URL: \\configuracion_variables(servSmb_GestorDocumental)\OFs_Rutas_GestionDocumental(fichero)
    if (this.JOperacionesSelecteds.length == 0) {
      // mensaje de error no hay ninguna pieza seleccionada
      this.alertService.error(this.translateService.instant('piezaNoSeleccionada'));
    }
    else if (this.JOperacionesSelecteds.length > 1) {
      // mensaje de error hay mas de una pieza seleccionada
      this.alertService.error(this.translateService.instant('dseleccionarSoloUnaPieza'));
    }
    else {
      this.fichero = '';
      // copiar direccion de la ruta
      // console.log("---------------------------");
      // console.log("idruta: " + this.JPlanificadorLista[this.JOperacionesSelecteds[0] - 1].idruta);
      // console.log("---------------------------");
      this.planificadorService.obtenerDireccionRuta(Number.parseInt(this.JPlanificadorLista[this.JOperacionesSelecteds[0] - 1].idRuta)).subscribe((data: any) => {
        var dir: any = data[0];
        if (dir == undefined) {
          // mensaje de error no hay ningun documento
          this.alertService.error(this.translateService.instant('noHayDocumento'));
        } else {
          this.fichero = dir.fichero;
          this.fichero = this.fichero.replaceAll(' ', '');
          this.fichero = this.fichero.replaceAll('/', '\\');
          navigator.clipboard.writeText(this.fichero);
          this.alertService.success(this.translateService.instant('direccionCopiada'));
        }

        // console.log(this.fichero)
      });
    }

  }

  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  
  
  btnIrAOperacion(dataItem){
    console.log("btnIrAOperacion");
    console.log(dataItem);
    const url = this.router.serializeUrl(this.router.parseUrl('#/operaciones/editar/' + dataItem.idOF + "/" + dataItem.idPieza + "/" + dataItem.idParte + "/" + dataItem.idRuta + "/" + dataItem.idOperacion + "/1"));
    window.open(url, '_blank');
  }



  //IMPRIMIR EXCEL SIN TENER EN CUENTA LA PAGINACION
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: this.gridData.data,
    };
    return result;
  }

  // D&D START
  public ngAfterViewInit(): void {
    var soloUnaMaquina = true;
    var idMaquina = -1;
    this.gridData.data.forEach(
      row => {
        if (idMaquina > 0 && idMaquina != row.idMaquina) soloUnaMaquina = false;
        idMaquina = row.idMaquina;
      });
    // SOLO SE ACTIVARA EL D&D SI HAY UNA SOLA MAQUINA EN EL GRID
    if (soloUnaMaquina)
      this.currentSubscription = this.handleDragAndDrop();
  }
  public ngOnDestroy(): void {
    if (this.currentSubscription != undefined)
      this.currentSubscription.unsubscribe();
  }
  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.grid-plan-corto tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        
        console.log("DROPPED")

        // 0. prevent default to allow drop
        e.preventDefault();
        // 1. cogemos el row que estamos moviendo (dragged)
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        // 2. creamos un nuevo contenedor con TODOS los elemenos, incluido el dragged
        var an: any = [dataItem];
        this.gridData.data.forEach(element => an.push(element));
        // 3. ordenamos el nuevo contenedor
        an = an.sort(function (elem1, elem2) {
          if (elem1.ordenPlanificado > elem2.ordenPlanificado) return 1;
          if (elem1.ordenPlanificado <= elem2.ordenPlanificado) return -1;
          return 0;
        });
        // 4. actualizamos el indice que estamos desplazando
        draggedItemIndex = an.indexOf(dataItem);
        // 5. cogemos el row sobre el que estamos
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = an[dropIndex];
        // 6. si no acabamos de pasar por encima (si hemos cambiado de row)
        if (dataItem.OLDordenPlanificado != dropItem.ordenPlanificado) {
          // 6.1. asiganamos el nuevo orden al dragged y al droped
          var orden = dataItem.ordenPlanificado + 0;
          dataItem.ordenPlanificado = dropItem.ordenPlanificado + 0;
          dataItem.OLDordenPlanificado = orden;
          dropItem.ordenPlanificado = orden;
        }
        // 7. quitamos de nuevo la linea que hemos añadido arriba  
        an.splice(draggedItemIndex, 1);
        // 8. actualizamos el grid
        this.gridData.data = an;
        // 9. estaba asi y sin esto no hace bien el D&D... mantenedlo asi
        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );

        // se les da color a las celdas actuales
        const targetEl = e.target as HTMLElement;
        if (targetEl.tagName === "TD") {
          // se quita el color a las celdas anteriores
          if (this.targetCells) {
            this.removeLineIndicators();
          }
          this.targetCells = targetEl.parentElement.querySelectorAll("td, th");
          this.targetCells.forEach((td: HTMLElement) => {
            const gridData: any[] = this.gridData.data as any[];
            if (td.tagName === "TH" && gridData.length !== 0) {
              this.renderer.addClass(td, "linea-draging");
            } else if (td.tagName === "TD") {
              this.renderer.addClass(td, "linea-draging");
            }
          });
        }

      }));

      sub.add(dragEnd.subscribe((e: any) => {
        
        console.log("DROPPED")

        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
        dataItem.OLDordenPlanificado = undefined;

        this.gridData.data = this.gridData.data.sort(function (elem1, elem2) {
          // Get data
          var keyA = elem1.ordenPlanificado, keyB = elem2.ordenPlanificado;
          // Compare the 2 elems
          if (keyA > keyB) return 1;
          if (keyA <= keyB) return -1;
          return 0;
        });

        // se quita el color a las celdas anteriores
        if (this.targetCells) {
          this.removeLineIndicators();
        }
      }));
    });

    return sub;
  }
  rowCallback(context: RowClassArgs) {
    // context.dataItem.orden = context.index + 1;
    return {
      dragging: context.dataItem.dragging
    };
  }
  // quita las marcas rojas de la linea de drop
  removeLineIndicators() {
    this.targetCells.forEach((td: HTMLElement) => {
      this.renderer.removeAttribute(td, "class");
    });
  }
  // D&D END


  // GUARDAR  
  btnGuardar() {

    var an = this.gridData.data;
    an.forEach(
      element => {
        element.version = this.JplanificadoresSelected.value;
      });

    this.planificadorService.Update_corto(an).subscribe({
      next: () => {
        this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
      }
    });
  }




}
