<!-- FILTRO -->
<div class="card">
    <div class="card-header">
      <h3>
        <label CssClass="">{{ 'filtro' | translate}}</label>
      </h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="clearfix">
        <!--FECHA INICIO-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'fechainicio' | translate }}</label>
            <div class="caja">
              <kendo-datepicker [(value)]="fechaIni" (valueChange)="onChangeFechaIni($event)" class="form-control" style="max-width: 170px;"></kendo-datepicker>
            </div>
          </div>
        </div>

        <!--FECHA FIN-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'fechafin' | translate }}</label>
            <div class="caja">
              <kendo-datepicker [(value)]="fechaFin" (valueChange)="onChangeFechaFin($event)" class="form-control" style="max-width: 170px;"></kendo-datepicker>
            </div>
          </div>
        </div>

        <!-- ÁREA PRODUCTIVA / SECCIÓN -->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'seccion' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}"
                                 kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [autoClose]="false"
                                 [(ngModel)]="seccionesSeleccionadas"
                                 (close)="seccionChanged()"
                                 style="width: 200px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

        <!-- GRUPOS DE MAQUINAS -->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'grupoMaquinas' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}"
                                 [data]="JgruposMaquinasCombo"
                                 kendoMultiSelectSummaryTag
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [(ngModel)]="JgruposMaquinasSelected"
                                 placeholder="{{ 'grupoMaquinas' | translate }}"
                                 [autoClose]="false"
                                 (close)="seccionChanged()"
                                 style="width: 200px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

        <!--LISTA MAQUINAS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'maquina' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" kendoMultiSelectSummaryTag [data]="listaMaquinasMostradas" [(ngModel)]="maquinasSeleccionadas" [textField]="'nombreMaquina'" [valueField]="'idMaquina'" placeholder="{{ 'seleccioneMaquina2' | translate }}" [autoClose]="false" style="width: 295px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreMaquina}}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

        <!--TERMINADOS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'terminados' | translate }}</label>
            <div class="caja" style="margin: 0 0 0 7px;">
              <kendo-switch [(ngModel)]="terminados" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
            </div>
          </div>
        </div>
        <div class="row">
          
        </div>
        <!--LISTA OFS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'of' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOfs" [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'" [valueField]="'idOf'" placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false" style="width: 295px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>
        
        <!--LISTA CLIENTES-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'cliente' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaClientes" [(ngModel)]="clientesSeleccionados" [textField]="'nombreCliente'" [valueField]="'idCliente'" placeholder="{{ 'seleccioneCliente' | translate }}" [autoClose]="false" style="width: 295px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

        <!--LISTA PIEZAS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'pieza' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'" placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 295px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

        <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
        <div class="float-left mr-2" *ngIf="!user.ocultarParte">
          <div class="form-group">
            <label>{{ 'parte' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPartes" [(ngModel)]="partesSeleccionadas" [textField]="'nombreParte'" [valueField]="'idParte'" placeholder="{{ 'seleccioneParte' | translate }}" [autoClose]="false" style="width: 295px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreParte}}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'partesSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

        <!--LISTA PROCESOS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'operacion' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOperaciones" [(ngModel)]="operacionesSeleccionadas" [textField]="'nombreOperacion'" [valueField]="'idOperacion'" placeholder="{{ 'seleccioneOperacion' | translate }}" [autoClose]="false" style="width: 295px;">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperacion }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'operacionesSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>
        </div>

      </div>
      <div class="clearfix">
        <div class="fa-pull-left">
          <button type="button" [disabled]="!hayDatosFiltro" class="btn btn-danger mr-1 float-left" (click)="btnLimpiarFiltro()">{{ 'limpiarFiltro' | translate }}</button>
          <button type="button" [disabled]="!hayDatosFiltro"class="btn btn-primary float-left " (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>

        </div>
      </div>
    </div>
  </div>


  <!--CAJAS-->
  <div class="row">
    <!-- Horas estimadas-->
      <div class="horasEstimadas-bloque d-block col-md">
      <div class="card">
          <div class="card-body">
          <label class="horasEstimadas-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ 'horasEstimadas' | translate }}">{{ 'horasEstimadas' | translate }}</label>
          <label class="horasEstimadas-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
          </label>
          </div>
      </div>
      </div>
      <div class="horasReales-bloque col-md">
          <div class="card">
          <div class="card-body">
              <label class="horasreales-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ 'horasReales' | translate }}">{{ 'horasReales' | translate }}</label>
              <label class="horasreales-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
              </label>
          </div>
          </div>
      </div>
      <div class="desvio-bloque col-md">
          <div class="card">
          <div class="card-body">
              <label class="desvio-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ 'desvio' | translate }}">{{ 'desvio' | translate }}</label>
              <label class="desvio-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
              </label>
          </div>
          </div>
      </div>
      <div class="porcentaje-bloque col-md">
          <div class="card">
          <div class="card-body">
              <label class="porcentaje-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ '%' | translate }}">{{ '%' | translate }}</label>
              <label class="porcentaje-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
              </label>
          </div>
          </div>
      </div>
    

    <!-- Horas predictivas-->
      <div class="horasPredictivas-bloque col-md">
      <div class="card">
          <div class="card-body">
          <label class="horasEstimadas-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ 'horasPredictivas' | translate }}">{{ 'horasEstimadas' | translate }}</label>
          <label class="horasEstimadas-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
          </label>
          </div>
      </div>
      </div>
      <div class="horasPredctivasReales-bloque col-md">
          <div class="card">
          <div class="card-body">
              <label class="horasreales-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ 'horasReales' | translate }}">{{ 'horasReales' | translate }}</label>
              <label class="horasreales-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
              </label>
          </div>
          </div>
      </div>
      <div class="desvioPredictivas-bloque col-md">
          <div class="card">
          <div class="card-body">
              <label class="desvio-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ 'desvio' | translate }}">{{ 'desvio' | translate }}</label>
              <label class="desvio-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
              </label>
          </div>
          </div>
      </div>
      <div class="porcentajePredictivas-bloque col-md">
          <div class="card">
          <div class="card-body">
              <label class="porcentaje-titulo d-block" data-toggle="tooltip" data-placement="top" title="{{ '%' | translate }}">{{ '%' | translate }}</label>
              <label class="porcentaje-horas d-block" data-toggle="tooltip" data-placement="bottom" title="">
              </label>
          </div>
          </div>
      </div>
  </div>
  

  <!--GRID OF-->
<div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'OF' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          <div kendoTooltip
                showOn="none"
                [tooltipTemplate]="template"
                filter=".k-grid td"
                (mouseover)="showGridTooltip($event)">
            <kendo-grid [kendoGridBinding]="dataInforme"
                        [resizable]="true"
                        [pageable]="true"
                        [pageSize]="10"
                        [skip]="skip"
                        [navigable]="true"
                        [sortable]="true"
                        filterable="menu"
                        (cellClick)="cellClick($event)">
              <kendo-grid-column-group title="">
                <kendo-grid-column field="numeroOF" title="{{ 'OF' | translate }}" [style]="{'text-align': 'left'}" width="15%"></kendo-grid-column>
                <kendo-grid-column *ngIf="!user.ocultarParte" field="nombreParte" title="{{ 'parte' | translate }}" [style]="{'text-align': 'left'}" width="10%"></kendo-grid-column>
                <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" [style]="{'text-align': 'left'}" width="15%"></kendo-grid-column>
                <kendo-grid-column field="cantPiezas" title="{{ 'cantidad2' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}" width="7%"></kendo-grid-column>
              </kendo-grid-column-group>
              <kendo-grid-column-group title="{{'horas' | translate}}" [headerStyle]="{'text-align': 'center'}">
                <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'horasEstimadas' | translate }}"  [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoEstimadoTotal)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tiempoPredictivoTotal" title="{{ 'horasPredictivas' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoPredictivoTotal)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tiempoTotal" title="{{ 'horasReales' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoTotal)}}</ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>
              <kendo-grid-column-group title="{{'desvio' | translate}}" [headerStyle]="{'text-align': 'center'}">
                <kendo-grid-column field="desvio" title="{{ 'estimadoReal' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.desvio)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="porcenDesvio" title="{{ '%' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'center'}" width="7%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenDesvio.toFixed(1)}}%</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="desvioPredictivo" title="{{ 'predictivoReal' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.desvioPredictivo)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="porcenDesvioPredictivo" title="{{ '%' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'center'}" width="7%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenDesvioPredictivo.toFixed(1)}}%</ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>
              <!--TRADUCCIÓN TEXTOS DEL GRID-->
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
            <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
