import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, CalendariosService, UsuariosService, Eskola_CursosLectivosService, Eskola_HorariosSeccionesService, EskolaGruposService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';




@Component({
  selector: 'app-calendariosDetalle',
  templateUrl: 'eskola_horariosSecciones_vistaAnual.component.html',
})
export class Eskola_VistaAnualComponent implements OnInit {
  navigationSubscription;
  private translate: TranslateService;

  form: FormGroup;

  isAddMode: boolean;
  loading = false;
  submitted = false;

  user = this.userService.userValue;

  //combo cursos
  cursos: any = [];
  cursoSelected:any ;

  //combo grupos
  grupos : any = [];
  grupoSelected: any ;

  // combo docentes
  docentes:any = [];
  docenteSelected:any;

  //variables tabla DB
  dtHorariosSecciones:any = [];
  dtCursos:any = []; 
  dtDocentes:any = [];
  dtGrupos:any = [];
  horariosSecciones:any = [];




  public year = false;
  public calendarioInicio:Date;
  public calendarioFin:Date;
  public listItemsCalendario: any;
  public calendarioSelected: any;
  public calendarioSelectedModal: any;
  public ultimoId: number;
  public DaysInMonths;
  public calendario;
  public mostrarCalendario: boolean = false;




  modalReference: NgbModalRef;

  constructor(
    private eskola_gruposService: EskolaGruposService,
    private eskola_horariosSeccionesService: Eskola_HorariosSeccionesService,
    private eskola_cursosService: Eskola_CursosLectivosService,
    private myFunctions: MyFunctions,
    private eskolaCalendariosService: CalendariosService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private alertService: AlertService,
    private userService: UsuariosService,
    private menuService: MenuService,
    private modalService: NgbModal
  ) {

    this.menuService.titulo = this.translateService.instant('calendarios').toUpperCase();

  }
 /*
  ngAfterViewInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.cargarMeses();
    this.cargarCalendario();
  }*/
  
  ngOnInit() {
    //como aqui se cargan mas cosas que solo los datos ponemos la carga desde el inicio
    //this.loadingPanel = true
    this.cargarDatos();
    this.cargarMeses();
    this.cargarCalendario();

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: new FormControl('', [Validators.required, Validators.maxLength(200)]),
  });   

  }
  cargarDatos(){
    this.eskola_cursosService.getAll().subscribe(res=>{
      this.dtCursos = res;
      this.cursos = res;
    });
    this.eskola_horariosSeccionesService.getHorariosSeccionesCurso(-1).subscribe(res=>{
      this.dtHorariosSecciones = res;
      this.horariosSecciones = res;
    });
    this.eskola_gruposService.getAll_docentes().subscribe(res=>{
      this.dtDocentes = res;
      res.forEach(docente => {
        var d = this.docentes.filter(f=>f.id_docente == docente.id_docente)
        if (d.length!=0){
          d[0].grupos.push(docente.grupoId);
        }else{
          docente.grupos=[];
          docente.grupos.push(docente.grupoId);
          this.docentes.push(docente);
        }
      });
    });
    this.eskola_gruposService.getAll().subscribe(res=>{
      this.dtGrupos = res;
      this.grupos = res;
    });


  }
  cargarCalendario() {
    this.listItemsCalendario = [];
    this.eskolaCalendariosService.getAll().subscribe(
      (json: any) => {
        if (json.length > 0) {
          json.forEach(element => {
            this.listItemsCalendario.push({ id: element.id, nombre: element.nombre });

          });
          this.calendarioSelected = this.listItemsCalendario[0].id;
          this.ultimoId = this.listItemsCalendario[this.listItemsCalendario.length - 1].id;
        }

      });

  }


  actualizarAno(valueEmitted) {
    if (this.year != valueEmitted) {
      this.year = valueEmitted;
    }
  }
  actualizarFechaInicio(valueEmitted) {
    if (this.calendarioInicio != valueEmitted) {
      this.calendarioInicio = valueEmitted;
    
    }
  }
  actualizarFechaFin(valueEmitted) {
    if (this.calendarioFin != valueEmitted) {
      this.calendarioFin = valueEmitted;
 
    }
  }

  limpiarFiltro(){
    this.cursoSelected = undefined;
    this.docenteSelected = undefined;
    this.grupoSelected = undefined;
  }
 
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su d�a correcto, los pondremos en el d�a actual y el primer d�a del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }

  cursoSelectedChange(){
    this.grupoSelected=undefined;
    if(this.cursoSelected!= undefined || this.cursoSelected.length!=0){
      this.grupos = this.dtGrupos.filter(f=> f.id_cursoLectivo == this.cursoSelected.id);
    }else{
      this.grupos = this.dtGrupos;
    }
   
  }

  
 

}