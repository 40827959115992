import { Component } from '@angular/core';
import { UsuariosService, MenuService, InformeProyectosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MyFunctions } from '@app/_helpers';
import * as c3 from 'c3';
import * as d3 from 'd3';

@Component({
  selector: 'app-informe-comparativa-proyectos',
  templateUrl: './informeComparativaProyectos.component.html'
})

export class InformeComparativaProyectosComponent {

  user = this.userService.userValue;
  public form: FormGroup;

  public listaProyectos: any;

  public listaOperarios: any;
  public placeHolderOperario = { idOperario: 0, nombreOperario: this.translateService.instant('seleccioneOperario') };

  public listaMaquinas: any;
  public placeHolderMaquina = { idMaquina: 0, nombreMaquina: this.translateService.instant('seleccioneMaquina2') };

  public listaHerramientas: any;
  public placeHolderHerramienta = { idHerramienta: 0, nombreHerramienta: this.translateService.instant('seleccioneHerramienta') };

  public listaOfs: any;
  public placeHolderOf = { idOf: 0, nombreOf: this.translateService.instant('seleccioneOf') };

  public listaClientes: any;
  public placeHolderCliente = { idCliente: 0, nombreCliente: this.translateService.instant('seleccioneCliente') };

  public listaPiezas: any;
  public placeHolderPieza = { idPieza: 0, nombrePieza: this.translateService.instant('seleccionePieza') };

  public listaPartes: any;
  public placeHolderParte = { idParte: 0, nombreParte: this.translateService.instant('seleccioneParte') };

  public listaOperaciones: any;
  public placeHolderOperacion = { idOperacion: 0, nombreOperacion: this.translateService.instant('seleccioneOperacion') };


  constructor(private informeProyectosService: InformeProyectosService,
    private userService: UsuariosService,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private myFunctions: MyFunctions) {

    this.menuService.titulo = this.translateService.instant('informeComparativaProyectos').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

  }

  ngOnInit() {

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });

    this.cargarDatosFiltro();
    this.cargarDatos();

  }

  cargarDatosFiltro() {

    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().pipe(first()).subscribe((data: any) => {
      this.listaMaquinas = data;
    });

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe((data: any) => {
      this.listaHerramientas = data;
      this.listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
    });

    //OPERARIOS
    this.informeProyectosService.Get_Operarios().pipe(first()).subscribe((data: any) => {
      this.listaOperarios = data;
      this.listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));
    });

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes().pipe(first()).subscribe((data: any) => {

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByOperacion = [];
      var groupByParte = [];

      //GROUP BY POR CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

      //GROUP BY POR PIEZA
      data.forEach(function (a) {
        if (!this[a.idPieza]) {
          this[a.idPieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.idPieza]);
        }
      }, Object.create(null));

      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

      //GROUP BY POR OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR OPERACION
      data.forEach(function (a) {
        if (!this[a.idOperacion]) {
          this[a.idOperacion] = {
            idOperacion: a.idOperacion, nombreOperacion: a.nombreOperacion,
          };
          groupByOperacion.push(this[a.idOperacion]);
        }
      }, Object.create(null));

      this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1));
      this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

      //GROUP BY POR PARTE
      data.forEach(function (a) {
        if (!this[a.idParte]) {
          this[a.idParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.idParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

    });

  }

  cargarDatos() {

    this.listaProyectos = [
      {
        id: 0,
        estadoBloque: 1,
        nombre: this.translateService.instant('proyecto').toUpperCase() + " 1",
        fechaIni: [], //aratz,
        fechaFin: [], //aratz,
        operarioSeleccionado: [], //aratz ,
        maquinaSeleccionada: [], //aratz,
        herramientaSeleccionada: [], //aratz,
        ofSeleccionado: [], //aratz,
        clienteSeleccionado: [], //aratz,
        piezaSeleccionada: [], //aratz,
        parteSeleccionada: [], //aratz,
        operacionSeleccionada: [], //aratz,
        cargandoDatos: false,
        disponibilidad: 0,
        rendimiento: 0,
        calidad: 0,
        horasReales: 0,
        horasEstimadas: 0,
        desvioHorasEstimado: 0,
        horasPrevistas: 0,
        desvioHorasPrevistas: 0,
        avanceAcumulado: 0,
        kwAcumulados: 0,
        nPieza: 0,
        nAlarmas: 0,
        nOperaciones: 0
      }
    ];

  }

  agregarProyecto(proyecto) {

    proyecto.estadoBloque = 2;

    var fechaini = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000)); //6 egun atzera
    var fechafin = this.myFunctions.getDateNow()

    fechaini.setHours(0, 0, 0);
    fechafin.setHours(0, 0, 0);

    proyecto.fechaIni = fechaini;
    proyecto.fechaFin = fechafin;

  }


  //BOTONES FILTRO TOP
  clickAbrirFiltro(proyecto) {
    proyecto.estadoBloque = 4;
  }
  clickCerrarrFiltro(proyecto) {
    proyecto.estadoBloque = 3;
  }

  clickBtnFiltrar(proyecto) {
    //CARGAR DATOS DEL PROYECTO
    this.cargarDatosProyecto(proyecto);

    proyecto.estadoBloque = 3;

    //AÑADIR NUEVO PROYECTO
    var id = proyecto.id + 1;

    if (id < 4) {

      var nuevoProyecto = {
        id: id,
        estadoBloque: 1,
        nombre: this.translateService.instant('proyecto').toUpperCase() + " " + (id + 1),
        fechaIni: [], //aratz,
        fechaFin: [], //aratz,
        operarioSeleccionado: [], //aratz,
        maquinaSeleccionada: [], //aratz,
        herramientaSeleccionada: [], //aratz,
        ofSeleccionado: [], //aratz,
        clienteSeleccionado: [], //aratz,
        piezaSeleccionada: [], //aratz,
        parteSeleccionada: [], //aratz,
        operacionSeleccionada: [], //aratz,
        cargandoDatos: false,
        disponibilidad: 0,
        rendimiento: 0,
        calidad: 0,
        horasReales: 0,
        horasEstimadas: 0,
        desvioHorasEstimado: 0,
        horasPrevistas: 0,
        desvioHorasPrevistas: 0,
        avanceAcumulado: 0,
        kwAcumulados: 0,
        nPieza: 0,
        nAlarmas: 0,
        nOperaciones: 0
      };

      this.listaProyectos[id] = nuevoProyecto;
    }
  }
  clickBtnRE_Filtrar(proyecto) {
    //CARGAR DATOS DEL PROYECTO

    proyecto.cargandoDatos = true;
    proyecto.estadoBloque = 3;

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(proyecto.fechaIni);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(proyecto.fechaFin);

    var listaIdClientes = (proyecto.clienteSeleccionado.length == 0) ? [] : [proyecto.clienteSeleccionado.map(x => x.idCliente)];
    var listaIdPiezas = (proyecto.piezaSeleccionada.length == 0) ? [] : [proyecto.piezaSeleccionada.map(x => x.idPieza)];
    var listaIdOfs = (proyecto.ofSeleccionado.length == 0) ? [] : [proyecto.ofSeleccionado.map(x => x.idOf)];
    var listaIdPartes = (proyecto.parteSeleccionada.length == 0) ? [] : [proyecto.parteSeleccionada.map(x => x.idParte)];
    var listaIdOperaciones = (proyecto.operacionSeleccionada.length == 0) ? [] : proyecto.operacionSeleccionada.map(x => x.idOperacion);

    this.informeProyectosService.Get_DatosInforme_Comparativa(fechaInicio, fechaFin, listaIdClientes.join(), listaIdPiezas.join(), listaIdOfs.join(), listaIdPartes.join(), listaIdOperaciones.join()
    ).pipe(first()).subscribe((data: any) => {

      proyecto.cargandoDatos = false;
      proyecto.datos = data;

      this.cargarTodosLosDatos(proyecto);

    });
  }
  clickBtnLimpiarFiltro(proyecto) {
    var fechaini = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000)); //6 egun atzera
    var fechafin = this.myFunctions.getDateNow()

    fechaini.setHours(0, 0, 0);
    fechafin.setHours(0, 0, 0);

    proyecto.fechaIni = fechaini;
    proyecto.fechaFin = fechafin;


    proyecto.operarioSeleccionado = []; //aratz;
    proyecto.maquinaSeleccionada = []; //aratz;
    proyecto.herramientaSeleccionada = []; //aratz;
    proyecto.ofSeleccionado = []; //aratz;
    proyecto.clienteSeleccionado = []; //aratz;
    proyecto.piezaSeleccionada = []; //aratz;
    proyecto.parteSeleccionada = []; //aratz;
    proyecto.operacionSeleccionada = []; //aratz;
  }
  clickBtnCancelar(proyecto) {

    proyecto.estadoBloque = 1;

    proyecto.operarioSeleccionado = []; //aratz;
    proyecto.maquinaSeleccionada = []; //aratz
    proyecto.herramientaSeleccionada = []; //aratz;
    proyecto.ofSeleccionado = []; //aratz
    proyecto.clienteSeleccionado = []; //aratz;
    proyecto.piezaSeleccionada = []; //aratz;
    proyecto.parteSeleccionada = []; //aratz;
    proyecto.operacionSeleccionada = []; //aratz;

  }
  clickBtnEliminar(proyecto) {

    this.listaProyectos.splice(proyecto.id, 1);

    //ACTUALIZAMOS LOS PROYECTOS (ID-s Y NOMBRES)
    this.listaProyectos.forEach(function (proyecto, index) {

      proyecto.id = index;
      proyecto.nombre = this.translateService.instant('proyecto').toUpperCase() + " " + (index + 1);

    }, this);

    //SI HABIA CUATRO PROYECTOS Y EN NINGUNO ESTA EL MENU DESPLEGADO ENTONCES AÑADIMOS LA VENTANA DE AÑADIR PROYECTO
    var algunoDesplegado = false;
    this.listaProyectos.forEach(function (proyecto) {
      if (proyecto.estadoBloque == 1 || proyecto.estadoBloque == 2) algunoDesplegado = true;
    });

    if (this.listaProyectos.length == 3 && !algunoDesplegado) {

      var id = 3;

      var nuevoProyecto = {
        id: id,
        estadoBloque: 1,
        nombre: this.translateService.instant('proyecto').toUpperCase() + " " + (id + 1),
        fechaIni: [], //aratz
        fechaFin: [], //aratz
        operarioSeleccionado: [], //aratz
        maquinaSeleccionada: [], //aratz
        herramientaSeleccionada: [], //aratz
        ofSeleccionado: [], //aratz
        clienteSeleccionado: [], //aratz
        piezaSeleccionada: [], //aratz
        parteSeleccionada: [], //aratz
        operacionSeleccionada: [], //aratz
        cargandoDatos: false,
        disponibilidad: 0,
        rendimiento: 0,
        calidad: 0,
        horasReales: 0,
        horasEstimadas: 0,
        desvioHorasEstimado: 0,
        horasPrevistas: 0,
        desvioHorasPrevistas: 0,
        avanceAcumulado: 0,
        kwAcumulados: 0,
        nPieza: 0,
        nAlarmas: 0,
        nOperaciones: 0
      };

      this.listaProyectos[id] = nuevoProyecto;

    }

  }

  cargarDatosProyecto(proyecto) {

    proyecto.cargandoDatos = true;

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(proyecto.fechaIni);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(proyecto.fechaFin);
    var listaIdClientes = (proyecto.clienteSeleccionado.length == 0) ? [] : [proyecto.clienteSeleccionado.map(x => x.idCliente)];
    var listaIdPiezas = (proyecto.piezaSeleccionada.length == 0) ? [] : [proyecto.piezaSeleccionada.map(x => x.idPieza)];
    var listaIdOfs = (proyecto.ofSeleccionado.length == 0) ? [] : [proyecto.ofSeleccionado.map(x => x.idOf)];
    var listaIdPartes = (proyecto.parteSeleccionada.length == 0) ? [] : [proyecto.parteSeleccionada.map(x => x.idParte)];
    var listaIdOperaciones = (proyecto.operacionSeleccionada.length == 0) ? [] : proyecto.operacionSeleccionada.map(x => x.idOperacion);

    this.informeProyectosService.Get_DatosInforme_Comparativa(fechaInicio, fechaFin, listaIdClientes.join(), listaIdPiezas.join(), listaIdOfs.join(), listaIdPartes.join(), listaIdOperaciones.join()
    ).pipe(first()).subscribe((data: any) => {

      proyecto.cargandoDatos = false;
      proyecto.datos = data;

      this.cargarTodosLosDatos(proyecto);

    });

  }

  public onChangeFechaIni(value: Date, proyecto: any): void {
    proyecto.fechaIni.setHours(0, 0, 0);
  }
  public onChangeFechaFin(value: Date, proyecto: any): void {
    proyecto.fechaFin.setHours(0, 0, 0);
  }

  public filtroChange(e: any, proyecto: any): void {
    this.cargarTodosLosDatos(proyecto);
  }

  cargarTodosLosDatos(proyecto) {

    //FILTRAMOS LOS DATOS POR OPERARIO, MAQUINA O HERRAMIENTA SI TIENEN ALGUNO SELECCIONADO
    var datosFiltrados = proyecto.datos;

    if (proyecto.operarioSeleccionado.length > 0) { //FILTRAR POR OPERARIO
      datosFiltrados = datosFiltrados.filter(x => proyecto.operarioSeleccionado.map(x => x.idOperario).includes(x.idOperario));
    }

    if (proyecto.maquinaSeleccionada.length > 0) { //FILTRAR POR MAQUINA
      datosFiltrados = datosFiltrados.filter(x => proyecto.maquinaSeleccionada.map(x => x.idMaquina).includes(x.idMaquina));
    }

    //if (!(proyecto.operarioSeleccionado == undefined || proyecto.operarioSeleccionado.idOperario == undefined)) { //FILTRAR POR HERRAMIENTA (SIN HACER)
    //  var idOperario = proyecto.operarioSeleccionado.idOperario;
    //}

    proyecto.datosFiltrados = datosFiltrados;

    if (proyecto.graficoBarra == undefined) this.dibujarGraficoBarras(proyecto);
    else this.cargarGraficoBarras(proyecto);

    if (proyecto.graficoDonut == undefined) this.dibujarGraficoDonut(proyecto);
    else this.cargarGraficoDonut(proyecto);

    this.cargarDatosPanel(proyecto);
  }


  async dibujarGraficoBarras(proyecto) {

    while (document.getElementById("graficoBarra_" + proyecto.id) == null) {
      await new Promise(r => setTimeout(r, 100));
    }

    var data = [[this.translateService.instant('total'), 0],
    [this.translateService.instant('calidad'), 0],
    [this.translateService.instant('rendimiento'), 0],
    [this.translateService.instant('disponibilidad'), 0]];

    var graficoBarras = c3.generate({
      size: {
        height: 70,
      },
      data: {
        columns: data,
        order: null,
        type: 'bar',
        groups: [[this.translateService.instant('total'), this.translateService.instant('calidad'), this.translateService.instant('rendimiento'),
        this.translateService.instant('disponibilidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#ffa8a8'], ['#ebe078'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ffa8a8']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translateService.instant('tiempoTotal')],
          show: false,
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 1000
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      legend: {
        show: false
      },
      bindto: '#graficoBarra_' + proyecto.id
    });

    proyecto.graficoBarra = graficoBarras;

    this.cargarGraficoBarras(proyecto);

  }

  cargarGraficoBarras(proyecto) {

    var tPerdidasDisponibilidad = 0;
    var tEjecuciones = 0;
    var tEstimado = 0;
    var tRendimientoNegativos = 0;
    var tCalidadNegativos = 0;

    proyecto.datosFiltrados.forEach(function (registro) {

      tPerdidasDisponibilidad += registro.tParadaDis + registro.tMantenimientoDis + registro.tAlarmaDis + registro.tApagadaDis;

      tEjecuciones += registro.tParadaJustificada + registro.tEjecucion;
      tEstimado += registro.tEstimadoBruto;

      tRendimientoNegativos += registro.tParadaRen + registro.tMantenimientoRen + registro.tAlarmaRen + registro.tApagadaRen;
      tCalidadNegativos += registro.tParadaCal + registro.tMantenimientoCal + registro.tAlarmaCal + registro.tApagadaCal + registro.tEjecucionNegativoCal;

    }, this);

    var tiempoAzulDisponibilidad = 0;
    var tiempoRojoDisponibilidad = 0;
    var tiempoAzulRendimiento = 0;
    var tiempoRojoRendimiento = 0;
    var tiempoAzulCalidad = 0;
    var tiempoRojoCalidad = 0;

    //RENDIMIENTO
    var aux = tEjecuciones - tRendimientoNegativos;
    if (aux < 0) aux = 0;

    if ((aux) < tEstimado) {
      tiempoAzulRendimiento = aux;
      tiempoRojoRendimiento = 0;
    } else {
      tiempoRojoRendimiento = aux - tEstimado;
      tiempoAzulRendimiento = tEstimado;
    }

    //CALIDAD
    tiempoAzulCalidad = tiempoAzulRendimiento - tCalidadNegativos;
    tiempoRojoCalidad = tCalidadNegativos;

    //DISPONIBILIDAD
    tiempoRojoDisponibilidad = tPerdidasDisponibilidad;
    tiempoAzulDisponibilidad = tiempoAzulRendimiento + tiempoRojoRendimiento;

    var data = [[this.translateService.instant('total'), tiempoAzulCalidad],
    [this.translateService.instant('calidad'), tiempoRojoCalidad],
    [this.translateService.instant('rendimiento'), tiempoRojoRendimiento],
    [this.translateService.instant('disponibilidad'), tiempoRojoDisponibilidad]];

    proyecto.graficoBarra.load({ columns: data });

    setTimeout(function () {
      proyecto.graficoBarra.flush();
    }, 1000);

  }

  async dibujarGraficoDonut(proyecto) {

    while (document.getElementById("graficoDonut_" + proyecto.id) == null) {
      await new Promise(r => setTimeout(r, 100));
    }

    var graficoDonut = c3.generate({
      bindto: '#graficoDonut_' + proyecto.id,
      data: {
        columns: [['completo', 0], ['nocompleto', 0]],
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 1000
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#graficoDonut_' + proyecto.id + ' .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll('#graficoDonut_' + proyecto.id + " .c3-chart-arcs path").style("stroke-width", "0px");

    proyecto.graficoDonut = graficoDonut;

    this.cargarGraficoDonut(proyecto);

  }

  cargarGraficoDonut(proyecto) {

    var tPerdidasDisponibilidad = 0;
    var tEjecuciones = 0;
    var tEstimado = 0;
    var tRendimientoNegativos = 0;
    var tCalidadNegativos = 0;

    proyecto.datosFiltrados.forEach(function (registro) {

      tPerdidasDisponibilidad += registro.tParadaDis + registro.tMantenimientoDis + registro.tAlarmaDis + registro.tApagadaDis;

      tEjecuciones += registro.tParadaJustificada + registro.tEjecucion;
      tEstimado += registro.tEstimadoBruto;

      tRendimientoNegativos += registro.tParadaRen + registro.tMantenimientoRen + registro.tAlarmaRen + registro.tApagadaRen;
      tCalidadNegativos += registro.tParadaCal + registro.tMantenimientoCal + registro.tAlarmaCal + registro.tApagadaCal + registro.tEjecucionNegativoCal;

    }, this);

    var tiempoAzulDisponibilidad = 0;
    var tiempoRojoDisponibilidad = 0;
    var tiempoAzulRendimiento = 0;
    var tiempoRojoRendimiento = 0;
    var tiempoAzulCalidad = 0;
    var tiempoRojoCalidad = 0;

    //RENDIMIENTO
    var aux = tEjecuciones - tRendimientoNegativos;
    if (aux < 0) aux = 0;

    tiempoRojoRendimiento = aux - tEstimado;
    tiempoAzulRendimiento = tEstimado;

    var ren = 0;
    if (tiempoAzulRendimiento + tiempoRojoRendimiento == 0) ren = 0;
    else ren = (tiempoAzulRendimiento / (tiempoAzulRendimiento + tiempoRojoRendimiento)) * 100;

    //CALIDAD
    tiempoAzulCalidad = tiempoAzulRendimiento - tCalidadNegativos;
    tiempoRojoCalidad = tCalidadNegativos;

    var cal = 0;
    if (tiempoAzulCalidad + tiempoRojoCalidad == 0) cal = 0;
    else cal = (tiempoAzulCalidad / (tiempoAzulCalidad + tiempoRojoCalidad)) * 100;

    //DISPONIBILIDAD
    tiempoRojoDisponibilidad = tPerdidasDisponibilidad;
    tiempoAzulDisponibilidad = tiempoAzulRendimiento + tiempoRojoRendimiento;

    var dis = 0;
    if (tiempoAzulDisponibilidad + tiempoRojoDisponibilidad == 0) dis = 0;
    else dis = (tiempoAzulDisponibilidad / (tiempoAzulDisponibilidad + tiempoRojoDisponibilidad)) * 100;

    //DONUT
    var oee = (ren * cal * dis) / 10000;
    d3.select('#graficoDonut_' + proyecto.id + ' .c3-chart-arcs-title').transition().duration(500).style("font-size", "0px").style("opacity", "0").transition().duration(500).style("font-size", "30px").style("opacity", "1")
      .text(Math.round(oee) + "%");

    if (oee > 100) oee = 100;

    var data = [['completo', oee], ['nocompleto', 100 - oee]];
    proyecto.graficoDonut.load({ columns: data });

    //DATOS OEE PANEL
    proyecto.disponibilidad = dis;
    proyecto.rendimiento = ren;
    proyecto.calidad = cal;

  }

  cargarDatosPanel(proyecto) {

    proyecto.horasReales = 0;
    proyecto.horasEstimadas = 0;
    proyecto.desvioHorasEstimado = 0;
    proyecto.horasPrevistas = 0;
    proyecto.desvioHorasPrevistas = 0;
    proyecto.avanceAcumulado = 0;
    proyecto.kwAcumulados = 0;
    proyecto.nPiezas = 0;
    proyecto.nAlarmas = 0;
    proyecto.nOperaciones = 0;

    proyecto.datosFiltrados.forEach(function (registro) {

      proyecto.horasReales += registro.tParadaJustificada + registro.tEjecucion;
      proyecto.horasEstimadas += registro.tEstimadoBruto;
      proyecto.horasPrevistas += registro.tPredictivo;

      proyecto.cantidad += registro.numeroOperaciones;
      proyecto.cantidadReal += registro.cantReal;

      proyecto.nPiezas += registro.numeroPiezas;
      proyecto.nAlarmas += registro.numeroAlarmas;
      proyecto.nOperaciones += registro.numeroOperaciones;

      proyecto.avanceAcumulado += registro.avanceAcumulado;
      proyecto.kwAcumulados += registro.consumoAcumulado;

    }, this);

    proyecto.desvioHorasEstimado = proyecto.horasReales - proyecto.horasEstimadas;
    proyecto.desvioHorasPrevistas = proyecto.horasReales - proyecto.horasPrevistas;

  }

}
