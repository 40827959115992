import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/combo`;

@Injectable()
export class ComboService {

  constructor(private http: HttpClient) { }

  public Get_Controles(): Observable<any> {
    return this.http.get(baseUrl + "/controles");
  }

  public Get_Marcas(): Observable<any> {
    return this.http.get(baseUrl + "/marcas");
  }

  public Get_Acciones(): Observable<any> {
    return this.http.get(baseUrl + "/acciones");
  }

  public Get_TiposAlarmas(): Observable<any> {
    return this.http.get(baseUrl + "/tiposalarmas");
  }

  public Get_TipoAlarma(): Observable<any> {
    return this.http.get(baseUrl + "/tipoalarma");
  }

  public Get_TiposPerdidas_DAT(): Observable<any> {
    return this.http.get(baseUrl + "/tiposPerdidas_DAT");
  }

  public Get_TiposManuales_DAT(): Observable<any> {
    return this.http.get(baseUrl + "/tiposManuales_DAT");
  }

  public Get_TiposManualesCarpetas_DAT(): Observable<any> {
    return this.http.get(baseUrl + "/manualesCarpetas_DAT");
  }
}
