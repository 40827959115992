import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, Eskola_ReservaMaquinasService, MenuService, Eskola_CursosLectivosService, UsuariosService, Eskola_AlumnosService, EskolaGruposService, Eskola_AsignaturasRetosService, Eskola_OfsService, Eskola_OfsPiezasService, Eskola_HorariosSeccionesService, Eskola_MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';
import { OFsComponent } from '@app/proyectos/OFs.component';
import { Reserva } from "@app/_models/reserva";
import { NotEqualFilterOperatorComponent } from '@progress/kendo-angular-grid';
interface ItemTurnoGantt {
  min: Date,
  max: Date,
  clase: string,
  id_grupo: number
}

declare var GanttChartReservaMaquinas: any;
@Component({
  selector: 'app-reservaMaquinas',
  templateUrl: './eskola_reservaMaquinas.component.html'
})
export class Eskola_ReservaMaquinasComponent implements AfterViewInit {

  //variables grupo
  misGrupos: any = [];
  jGrupoFiltro: any = [];

  //variables horariossecciones
  dtHorariosSecciones: any = [];
  horariosSecciones: any = [];
  misHorariosSeccionesGrupo: any = [];

  //variables maquinas
  dtGruposMaquinas: any = [];
  gruposMaquinas: any = [];
  jGruposMaquinasFiltro;
  //combosemanas
  fechaInicio;
  fechaFin;
  fechaInicioCurso;
  fechaFinCurso;
  Jsemanas;
  JsemanaSelected;

  // horas max y min de cada dia de la semana, no se podrán hacer reservas fuera de esas horas
  horaMax;
  horaMin;
  horaMaxLunes;
  horaMinLunes;
  horaMaxMartes;
  horaMinMartes;
  horaMaxMiercoles;
  horaMinMiercoles
  horaMaxJueves;
  horaMinJueves;
  horaMaxViernes;
  horaMinViernes;
  horaMaxSabado;
  horaMinSabado;
  horaMaxDomingo;
  horaMinDomingo;

  horasMaxYMin:any = [
    {id:1, horaMin:undefined, horaMax:undefined},
    {id:2, horaMin:undefined, horaMax:undefined},
    {id:3, horaMin:undefined, horaMax:undefined},
    {id:4, horaMin:undefined, horaMax:undefined},
    {id:5, horaMin:undefined, horaMax:undefined},
    {id:3, horaMin:undefined, horaMax:undefined},
    {id:7, horaMin:undefined, horaMax:undefined},
  ];

  // para la nueva reserva
  diaReserva:any;
  grupoDeMaquinasReserva:any;
  maquinaReserva:any;
  horaInicioReserva;
  horaFinReserva;
  OfReserva:any;
  alumnosReserva:any = [];

  dtCursos;
  cursos;

  dtHorarios;
  horarios;


  grupos;
  dtGrupos;

  maquinas;
  dtMaquinas;

  dtAlumnos;
  alumnos;

  dtOfs;
  ofs:any = [];

  dtReservas:any;
  reservas: any;

  status:boolean = true;
  loadingFiltro:boolean = false;
  loadingGantt: boolean = false;
  //GANTT
  private GanttChartReservaMaquinas: any;
  gruposMaquinasGantts: any = [];

  //MENU LATERAL
  menuDesplegado = false;
  diasSemana:any = [];

  //AYUDAS FECHAS
  private today: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
  private now: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate(), this.myFunctions.getDateNow().getHours(), this.myFunctions.getDateNow().getMinutes(), this.myFunctions.getDateNow().getSeconds());

  yoAlumno:any;

  public faltanDatos = false;

  user = this.userService.userValue;
  loadingPanel: boolean = false;

  //variables de validacion
  errorHorasFueraCentro :boolean = false;
  errorHorasMal: boolean = false;
  errorReservaNoValida: boolean = false;
  errorReservaExiste:boolean = false;

  constructor(private cursosLectivosService: Eskola_CursosLectivosService,
    private reservasService: Eskola_ReservaMaquinasService,
    private alumnosService: Eskola_AlumnosService,
    private gruposService: EskolaGruposService,
    private horariosService: Eskola_HorariosSeccionesService,
    private eskola_maquinasService: Eskola_MaquinasService,
    private eskola_cursosService: Eskola_CursosLectivosService,
    private eskolaOfsService: Eskola_OfsService,
    private eskolaOfsPiezasService: Eskola_OfsPiezasService,
    private alertService: AlertService,
    private asignaturasRetosService: Eskola_AsignaturasRetosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private myFunctions: MyFunctions,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('reservasMaquinas').toUpperCase();


  }

  ngAfterViewInit(): void {
    this.loadingPanel = true;
    this.cargarDatos();
  }


  // carga los datos necesarios de la db
  async cargarDatos(){
      // cargamos los grupos
      var r1, r2, r3, r4, r5, r6, r7, r8, r9, r10, r11, r12, r13, r14, r15, r16, r17: boolean = false;
      if (this.user.usuarioTipo == "Alumno") {
        // si es alumno
        this.alumnosService.getGruposDeAlumno(this.user.id).subscribe(res => {
          this.misGrupos = res;
          this.jGrupoFiltro = [];
          r14 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
            this.cargarComboSemanas();
          }
        });
      } else if (this.user.usuarioTipo == "Docente") {
        // si es docente
        var filtroDocente = " WHERE ((u.Id IN ( " + this.user.id + " ))) ";
        this.gruposService.getAll_filtrado(filtroDocente, "", "").subscribe(res => {
          this.misGrupos = res;
          this.jGrupoFiltro = res[0];
          r14 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
            this.cargarComboSemanas();
          }
        });
      } else if (this.user.usuarioTipo == "Administrador") {
        this.gruposService.getAll().subscribe(res => {
          this.misGrupos = res;
          this.jGrupoFiltro = res[0];
          r14 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
            this.cargarComboSemanas();
          }
        });
      }
      this.eskola_cursosService.getAll().subscribe(res => {
        this.dtCursos = res;
        this.cursos = res;
        r1 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });
      this.eskola_cursosService.getHorariosDelCurso(-1).subscribe(res => {
        this.dtHorarios = res;
        this.horarios = res;
        r2 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });
      this.eskola_cursosService.getHorasMaxyMin(-1, -1).subscribe(res => {
        this.horaMax = res[0].horaMax;
        this.horaMin = res[0].horaMin;
        this.fechaInicioCurso = new Date(res[0].inicioMin);
        this.fechaFinCurso = new Date(res[0].finMax);
        r3 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 1).subscribe(res => {
        this.horaMaxLunes = res[0].horaMax;
        this.horaMinLunes = res[0].horaMin;
        this.horasMaxYMin[0].horaMax = res[0].horaMax;
        this.horasMaxYMin[0].horaMin = res[0].horaMin;
    
        r7 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }


      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 2).subscribe(res => {
        this.horaMaxMartes = res[0].horaMax;
        this.horaMinMartes = res[0].horaMin;
        this.horasMaxYMin[1].horaMax = res[0].horaMax;
        this.horasMaxYMin[1].horaMin = res[0].horaMin;
        r8 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }

      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 3).subscribe(res => {
        this.horaMaxMiercoles = res[0].horaMax;
        this.horaMinMiercoles = res[0].horaMin;
        this.horasMaxYMin[2].horaMax = res[0].horaMax;
        this.horasMaxYMin[2].horaMin = res[0].horaMin;
        r9 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }

      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 4).subscribe(res => {
        this.horaMaxJueves = res[0].horaMax;
        this.horaMinJueves = res[0].horaMin;
        this.horasMaxYMin[3].horaMax = res[0].horaMax;
        this.horasMaxYMin[3].horaMin = res[0].horaMin;
        r10 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }

      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 5).subscribe(res => {
        this.horaMaxViernes = res[0].horaMax;
        this.horaMinViernes = res[0].horaMin;
        this.horasMaxYMin[4].horaMax = res[0].horaMax;
        this.horasMaxYMin[4].horaMin = res[0].horaMin;
        r11 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }

      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 6).subscribe(res => {
        this.horaMaxSabado = res[0].horaMax;
        this.horaMinSabado = res[0].horaMin;
        this.horasMaxYMin[5].horaMax = res[0].horaMax;
        this.horasMaxYMin[5].horaMin = res[0].horaMin;
        r12 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }

      });
      this.eskola_cursosService.getHorasMaxyMin(-1, 7).subscribe(res => {

        this.horaMaxDomingo = res[0].horaMax;
        this.horaMinDomingo = res[0].horaMin;
        this.horasMaxYMin[6].horaMax = res[0].horaMax;
        this.horasMaxYMin[6].horaMin = res[0].horaMin;
        r13 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      


      });
      this.eskola_maquinasService.getGruposMaquinas().subscribe(res => {
        this.dtGruposMaquinas = res;
        this.gruposMaquinas = res;
        this.jGruposMaquinasFiltro = res;
          r4 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });
      this.eskola_maquinasService.get().subscribe(res=>{
        this.dtMaquinas = res;
        this.maquinas = res;
        //procesamos grupos de maquinas
        // if(this.maquinas.idsNombresGrupos!=''){
        //   res.forEach(maquina => {
        //     var gMaquinaslag = maquina.idsNombresGrupos.split(',');
        //     gMaquinaslag.forEach(gmaquina => {
        //       var grupoDeMaquina: any {
        //         id: 
        //       }
        //       var idGrupo = gmaquina.split(';')[0];
        //       var nombreGrupo = gmaquina.split(';')[1];
        //     });
        //   });
        // }
      
        r15 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });

      this.horariosService.getHorariosSeccionesCurso(-1).subscribe(result => {
        this.dtHorariosSecciones = result;
        this.horariosSecciones = result;
        r5 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });
      this.gruposService.getAll().subscribe(res => {
        this.grupos = res;
        this.dtGrupos = res;
        r6 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });
      

        this.eskolaOfsService.GetOfsAlumnos(-1).subscribe(res=>{
          res.forEach(of => {
            of.alumnosOf = of.alumnosOf.split(',');
            of.nombreDb = of.nombre;
            of.nombre = of.nombre.replace("!", " ");
            this.dtOfs = res;
          });
          r16 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
            this.cargarComboSemanas();
          }
        });

      this.reservasService.getAll().subscribe(res=>{
        // procesamos alumnos
        if(res!=undefined){
          res.forEach(reserva => {
            var alumnos = reserva.alumnos.split(',');
            reserva.alumnosP = [];
            alumnos.forEach(alumno => {
              var id = Number(alumno.split(';')[0]);
              var nombreCompleto = alumno.split(';')[1];
              var iniciales = nombreCompleto.split(' ')[0].charAt(0) + nombreCompleto.split(' ')[1].charAt(0);
              var color = alumno.split(';')[2];
    
              reserva.alumnosP.push({
                id:id,
                nombreCompleto:nombreCompleto,
                iniciales:iniciales,
                color: color
              });
            });
          });
          
        }

        this.dtReservas = res;
        this.reservas = res;
        r17 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13 && r14 &&  r15 && r16 && r17)  {
          this.cargarComboSemanas();
        }
      });

  }


  wait(seconds: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, seconds * 1000);
    });
  }



  // carga los datos para el dropdown de semanas
  cargarComboSemanas() {
    debugger;
    this.Jsemanas = [];
    var fecha = this.myFunctions.startOfWeek(this.myFunctions.dateCopy(this.fechaInicioCurso))
    var semana = this.myFunctions.getNumberOfWeek(fecha);
    var año = fecha.getFullYear();

    var fechaMax = this.myFunctions.dateCopy(this.fechaFinCurso);
    var añoMax = fechaMax.getFullYear();
    var semanaMax = this.myFunctions.getNumberOfWeek(fechaMax);


    var index = 1;
    while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
      //crear linea Json
      var fecha2 = new Date(fecha);
      var fecha3 = this.myFunctions.dateCopy(fecha);
      fecha3.setDate(fecha.getDate() + 6);
      fecha3.setHours(23);
      fecha3.setMinutes(59);
      var js: any = {};
      js['value'] = index;
      js['fechaInicio'] = fecha2;
      js['fechaFin'] = fecha3;
      js['año'] = año;
      js['semana'] = semana;
      if (this.user.idioma != "eu") {
        js['text'] = this.translateService.instant('semana') + " " + semana + ' (' + this.myFunctions.dateToYYYYMMDD(fecha2) + ')';

      } else {
        js['text'] = semana + " " + this.translateService.instant('semana') + ' (' + this.myFunctions.dateToYYYYMMDD(fecha2) + ')';

      }
      if (fecha2.getFullYear() == this.today.getFullYear() && this.myFunctions.getNumberOfWeek(fecha2) == this.myFunctions.getNumberOfWeek(this.today)) {
        js['presentWeek'] = true;
      } else {
        js['presentWeek'] = false;
      }
      this.Jsemanas.push(js);

      //actualizar variables para proximo loop
      index++;
      fecha.setDate(fecha.getDate() + 7);
      semana = this.myFunctions.getNumberOfWeek(fecha);
      año = fecha.getFullYear();
    }
    debugger;
    // si es la primera vez que carga seleccionamos la semana actual
    if (this.fechaInicio == undefined && this.fechaFin == undefined) {
      this.JsemanaSelected = this.Jsemanas.filter(f => f.presentWeek == true)[0];
      if(this.JsemanaSelected != undefined){
        this.fechaInicio = this.JsemanaSelected.fechaInicio;
        this.fechaFin = this.JsemanaSelected.fechaFin;
      }else{
        //this.JsemanaSelected = this.Jsemanas;
        this.fechaFin = this.Jsemanas[0].fechaFin;
        this.fechaInicio = this.Jsemanas[0].fechaInicio;
      }
      
    } else if (this.JsemanaSelected == undefined) {
      // venimos de la url
      this.JsemanaSelected = this.Jsemanas.filter(f => f.fechaInicio.getFullYear() == this.fechaInicio.getFullYear() && f.fechaInicio.getMonth() == this.fechaInicio.getMonth() && f.fechaInicio.getDate() == this.fechaInicio.getDate())[0];
    }
    //this.loadingPanel = false;
    this.actualizarDiasSemana();
    this.prepararGantts();

  }

  /*
  cuando cambia la semana actualiza sus dias
  */
  actualizarDiasSemana() {
    debugger
    this.diasSemana = [];
    let fecha;
    if(this.fechaInicio != undefined){
      fecha = this.myFunctions.dateCopy(this.fechaInicio); //ELENE
    }else{
      fecha = this.myFunctions.startOfWeek(this.myFunctions.dateCopy(this.fechaInicioCurso))
    }
    //var fecha = this.myFunctions.startOfWeek(this.myFunctions.dateCopy(this.fechaInicio)) // MARTIN
    debugger;
    while (fecha <= this.fechaFin) {
      var diaSemana = {
        dia: this.myFunctions.asignarDiaSemana(fecha).dia,
        id: this.myFunctions.asignarDiaSemana(fecha).id,
        fecha: this.myFunctions.dateCopy(fecha)
      }
      // si el centro está cerrado ese día no lo añadimos a la lista
      if(this.horasMaxYMin[diaSemana.id-1].horaMin !=null){
        this.diasSemana.push(diaSemana);
      }
      
      fecha.setDate(fecha.getDate() + 1);


    }
    debugger;
    this.diaReserva = this.diasSemana[0];
    this.horaInicioReserva = new Date(this.diasSemana[0].fecha);
    this.horaFinReserva = new Date(this.diasSemana[0].fecha);
    this.asignarHoraInicioYFinDia(this.horaInicioReserva, this.horaFinReserva,1);

    
  }
  // prepara los datos que necesitarán los Gantt
  prepararGantts(){
    debugger;
    this.gruposMaquinas.forEach(grupo => {
      grupo.shifts = [];
      grupo.maquinas = [];
      grupo.tasks = [];
      grupo.maquinas = this.maquinas.filter(f=> f.idGrupoMaquina == grupo.id);
      grupo.maquinas.forEach(maquina=>{
        maquina.name = maquina.nombre;
        maquina.image = 'assets/idcontent/' + this.user.idDb + '/modelos/' + maquina.imagen;
      })

      this.prepararGantt(grupo,undefined);
      this.diasSemana.forEach(dia => {
        this.prepararGantt(grupo, dia);
      });
    });
  }
  /*
   preparamos los datos que necesitará un Gantt de un grupo de máquinas y un día en específico
   @param grupo gruo de maquinas
   @param dia dia de la semana
  */
  prepararGantt(grupo, dia){
    if(dia==undefined){
      this.generarGantt(grupo,dia);
    }else{
      // traemos los horarios secciones del grupo en ese dia (en el gantt aparecerá esa franja en azul)
      grupo.shifts = grupo.shifts.concat(this.getShifts(grupo, dia));
      grupo.shiftsDia = this.getShifts(grupo, dia);
      grupo.tasks =  grupo.tasks.concat(this.getTasks(grupo, dia));
      grupo.tasksDia =  this.getTasks(grupo, dia);
     this.generarGantt(grupo, dia);
    }
 
  }

  /*
    genera un gantt para un grupo de maquinas y un dia en concreto
    @param grupo grupo de maquinas
    @param dia dia de la semana
  */
  generarGantt(grupo, dia){
    debugger;
    if(grupo.maquinas.length==0){
      return;
    }
    if(dia == undefined){
      let data = {
        machines: grupo.maquinas,
        tasks: [] ,
        hidden: true
      };
  
      // gantt-solo  maquinas, sin horas
      var aux = document.getElementById('gantt-chart-' + grupo.id);
      var id:string = 'gantt-chart-' + grupo.id; 
      var gantt = new GanttChartReservaMaquinas(id, {
        width: '500', //introductir un tamaño o poner 'fit' para que se adapte a su padre
        height: Number(grupo.maquinas.length*125).toString(),
        startDate: this.fechaInicio,
        endDate: this.fechaFin,
        shifts:[],
        data: data,
  
  
      }, this.translateService)

    }else{
      // gantt para cada día de la semana
    if(this.horasMaxYMin[dia.id-1].horaMin!=undefined && this.horasMaxYMin[dia.id-1].horaMax!=undefined){
      var newFechaInicioLunes = this.myFunctions.dateCopy(dia.fecha);
      var newFechaFinLunes = this.myFunctions.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(newFechaInicioLunes, newFechaFinLunes, dia.id);
      var id:string = 'gantt-chart-'+grupo.id+'-'+dia.id;
      var gantt2 = new GanttChartReservaMaquinas(id, {
        width: '500', //introductir un tamaño o poner 'fit' para que se adapte a su padre
        height: Number(grupo.maquinas.length*125).toString(),
        startDate:newFechaInicioLunes,
        endDate: newFechaFinLunes,
        shifts:  grupo.shiftsDia,
        data: {
          machines: grupo.maquinas,
          tasks: grupo.tasksDia,
          visible: true
  
        }
  
      }, this.translateService)
      
      }
      if(dia.id==this.diasSemana.length){
        this.loadingPanel = false;
      }
    }
   
   
  }
  /*
    genera los shifts para el gantt
    @param grupo grupo de maquinas
    @param dia dia de la semana
    @return array de turnos
   */
  getShifts(grupo, dia):Array<ItemTurnoGantt>{
    // si es alumno queremos los shifts de todos los grupos
    if(this.user.usuarioTipo=="Alumno"){
      var shifts: Array<ItemTurnoGantt> = [];
      this.misGrupos.forEach(g => {
      var horariosSeccionesDelGrupoYDia:any = this.horariosSecciones.filter(f=> f.id_grupo == g.id && new Date(f.fechaInicio).getDate()==dia.fecha.getDate() && f.id_grupoMaquinas == grupo.id);
      horariosSeccionesDelGrupoYDia.forEach(hs => {
        var newShift:ItemTurnoGantt ={
          min: new Date(hs.fechaInicio),
          max:new Date(hs.fechaFin),
          clase: "mañana", // para que salga azul
          id_grupo: hs.id_grupo
        }
        shifts.push(newShift);
      });
      });
      return shifts;
    }
    var horariosSeccionesDelGrupoYDia:any = this.horariosSecciones.filter(f=> f.id_grupo == this.jGrupoFiltro.id && new Date(f.fechaInicio).getDate()==dia.fecha.getDate() && f.id_grupoMaquinas == grupo.id);
      var shifts: Array<ItemTurnoGantt> = [];
      horariosSeccionesDelGrupoYDia.forEach(hs => {
        var newShift:ItemTurnoGantt ={
          min: new Date(hs.fechaInicio),
          max:new Date(hs.fechaFin),
          clase: "mañana", // para que salga azul,
          id_grupo: hs.id_grupo
        }
        shifts.push(newShift);
      });
      return shifts;
  }
  /* 
     arregla las tasks para el gantt
     @param grupo grupo de maquinas
     @param dia dia de la semana
     @return array con las tasks
  */
  getTasks(grupo, dia):Array<any>{
    var tasks = [];
    var reservas = this.reservas.filter(f=> f.id_grupoMaquinas == grupo.id  && new Date(f.fechaInicio).getDate()==dia.fecha.getDate());
    if(reservas!=undefined){
      reservas.forEach(reserva => {
        var task = reserva;
        task.startDate = new Date(reserva.fechaInicio);
        task.endDate = new Date(reserva.fechaFin);
        task.machine = reserva.id_maquina;
        task.of = "";
        task.detail = (d) => "";     


        //solo podré ver los nombres en las reservas de mis grupos
        var miGrupo = this.misGrupos.filter(f=> f.id == task.id_grupo)[0];
        if(miGrupo!=undefined){
          task.detail = (d) => this.crearTooltip(d);
          task.of = "nirea";
        }
        // si no soy alumno, solo las reservas del grupo seleccionado se verán en azul
        if(this.user.usuarioTipo != "Alumno"){
          if(task.id_grupo == this.jGrupoFiltro.id){
            task.backgroundColor = "#22c4c4";
          }else{
            // si la reserva no es del grupo seleccionado se verá en gris
            task.backgroundColor = "#555";
          }
        }else{
          // si soy alumno solo mis reservas ser verán en azul
          var esMiReserva = (task.alumnosP.filter(f=>f.id == this.user.id).length>0)?true : false;
          if(esMiReserva){
            task.backgroundColor = "#22c4c4";
          }else{
            // si la reserva no es mia se verá en gris
            task.backgroundColor = "#555";
          }
        }
     
        tasks.push(task);
      });
    }
    return tasks;
  }
  /*
   crea el tooltip y los circulos con las iniciales que después se verán en el gantt
   @param tasks lista de tasks 
   @return objeto con dos string, tooltipNombre el html para el tooltip y ciruclos en html para circulos
  */
  crearTooltip(task):{tooltipNombres:String, circulos:String}{
    var tooltipNombres = "";
    var circulos = "";
    task.alumnosP.forEach(alumno => {
      var span = `<span class = "grid-circulo" style = "background-color: `+alumno.color + `">` + alumno.iniciales + `</span>`;
      var label = `<p><span class="tooltip-valor">` + alumno.nombreCompleto +`</span></p>`;
      tooltipNombres +=label;
      circulos+=span;
    });;
    var of = this.dtOfs.filter(f=> f.id == task.id_of)[0];
    var labelOf = `<strong>` + of.nombre +`</strong>`;
    tooltipNombres +=labelOf;
    return {tooltipNombres:tooltipNombres, circulos:circulos};
  }
 
  //MENU LATERAL
  
  gruposMaquinasReservaSelectedChange(){
    this.maquinaReserva = this.grupoDeMaquinasReserva.maquinas[0];
  }

  // cuando cambia el dia seleccionado para una nueva reseva, actualiza las fechas de las horas de la reserva
  diaSemanaReservaSelectedChange(){
    var newFechaInicio = new Date(this.diaReserva.fecha);
    newFechaInicio.setHours(this.horaInicioReserva.getHours());
    newFechaInicio.setMinutes(this.horaInicioReserva.getMinutes());
    this.horaInicioReserva = newFechaInicio;

    var newFechaFin= new Date(this.diaReserva.fecha);
    newFechaFin.setHours(this.horaFinReserva.getHours());
    newFechaFin.setMinutes(this.horaFinReserva.getMinutes());
    this.horaFinReserva = newFechaFin;
  }

  mostrarMenuLateral() {
    this.menuDesplegado = true;
  }
  guardarClick(){
  

    if(this.validar()){
      var idsAlumnos = [];
      this.alumnosReserva.forEach(alumno => {
        idsAlumnos.push(alumno.id_usuario);
      });
      var idsAlumnosString = idsAlumnos.join(',');
      var reserva:Reserva = new Reserva ();
        reserva.Id_of = this.OfReserva.id;
        reserva.Id_grupoMaquinas = this.grupoDeMaquinasReserva.id;
        reserva.Id_maquina =  this.maquinaReserva.id;
        reserva.Id_grupo = this.jGrupoFiltro.id;
        reserva.FechaInicio= this.horaInicioReserva;
        reserva.FechaFin = this.horaFinReserva,
        reserva.Id_alumnos = idsAlumnosString
         
      this.reservasService.insert_reservaMaquina(reserva).subscribe(res=>{
        if(res['error']==false){
          this.cancelarClick()
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.cargarDatos();
          this.router.navigate(['../eskola_reservaMaquinas'], { relativeTo: this.route });
        }else{
          this.cancelarClick()
          this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
          this.cargarDatos();
          this.router.navigate(['../eskola_reservaMaquinas'], { relativeTo: this.route });
        }
      });
    }
  }
  cancelarClick(){
    this.menuDesplegado = false;
    this.alumnosReserva = [];
    this.OfReserva = undefined;
    this.grupoDeMaquinasReserva = undefined;
    this.maquinaReserva = undefined;
    this.horaFinReserva = undefined;
    this.horaFinReserva = undefined;
    this.diaReserva = this.diasSemana[0];
    this.horaInicioReserva = new Date(this.diasSemana[0].fecha);
    this.horaFinReserva = new Date(this.diasSemana[0].fecha);
    this.asignarHoraInicioYFinDia(this.horaInicioReserva, this.horaFinReserva,1);
  }
  
  // comprueba que todos los datos necesarios sean correctos
  // @return true si todo es correcto false si hay algun error
  validar(): boolean{
    var correcto: boolean = true;
    this.errorHorasFueraCentro = false;
    this.errorHorasMal = false;
    this.errorReservaNoValida = false;
    this.errorReservaExiste = false;

    // comprobamos si las horas están bien
    if(this.horaInicioReserva >= this.horaFinReserva){
      this.errorHorasMal  = true;
      correcto = false;
    }
    // comprobamos si las horas están dentro del horairio del centro
     else if(this.myFunctions.dateToHHMM(this.horaInicioReserva) < this.horasMaxYMin[this.diaReserva.id-1].horaMin ||this.myFunctions.dateToHHMM(this.horaFinReserva) > this.horasMaxYMin[this.diaReserva.id-1].horaMax ){
      this.errorHorasFueraCentro =  true;
      correcto = false;
    }else{
      // comprobamos que la reserva que quiere hacer esté dentro de sus horas de prioridad
      var shiftsDentro = this.grupoDeMaquinasReserva.shifts.filter(f=> (f.min.getDate() == this.diaReserva.fecha.getDate() ) && (f.min <= this.horaInicioReserva && f.max >=this.horaFinReserva));
      if(shiftsDentro.length==0){
        this.errorReservaNoValida = true;
        correcto = false;
      }else{
        this.jGrupoFiltro = this.grupos.filter(f=>f.id == shiftsDentro[0].id_grupo)[0] ; // la resrva se guardará como reserva del grupo que tiene prioridad
        // comprobamos que no haya ninguna reserva  a esa hora
        var reservasDentro = this.reservas.filter(f=> f.id_maquina  == this.maquinaReserva.id && (f.startDate< this.horaFinReserva && this.horaInicioReserva < f.endDate))
          if(reservasDentro.length>0){
            this.errorReservaExiste = true;
            correcto = false;
          }
        
      }
      // comprobamos que no haya ninguna reserva a esta hora
    }
    return correcto;
  }
  //END MENU LATERAL
  // trae los alumnos del grupo y despiega el menu lateral
  nuevoClick() {
    //si no soy alumno
    if(this.user.usuarioTipo!="Alumno"){
      this.alumnosService.getAll_alumnos_filtrado("","WHERE grupo.id IN ("+this.jGrupoFiltro.id+")","").subscribe(res=>{
        this.alumnos = res;
      });
    }else{
      var filtroGrupos = " WHERE grupo.id IN ( ";
      var i:number = 0;
      this.misGrupos.forEach(grupo => {
        if(i<this.misGrupos.length-1){
          filtroGrupos += grupo.id + ", ";
          i++;
        }
        
      });

      filtroGrupos += this.misGrupos[i].id +") ";
      this.alumnosService.getAll_alumnos_filtrado("",filtroGrupos,"").subscribe(res=>{
        this.alumnos = res;
        this.yoAlumno = this.alumnos.filter(f=> f.id_usuario == this.user.id)[0];
        this.alumnosReserva.push(this.yoAlumno);
      });
    }
    
    this.mostrarMenuLateral();
  }
  // cuando cambian los alumnos seleccionados actualiza las Ofs posibles
  selectedAlumnosReservaChange($event){
    console.log(this.alumnosReserva);
    if(this.alumnosReserva.length==0){
      if(this.user.usuarioTipo == "Alumno"){
        // si soy alumno, siempre tengo que estar en la reserva
        this.alumnosReserva.push(this.yoAlumno);
      }
      this.OfReserva = undefined;
      this.grupoDeMaquinasReserva = undefined;
      this.maquinaReserva = undefined;
      return;
    }
    this.OfReserva = undefined;
    this.grupoDeMaquinasReserva = undefined;
    this.maquinaReserva = undefined;
    this.ofs = [];
    // miramos que OFs son de los alumnos seleccionados
    this.dtOfs.forEach(of => {
      var esValida = this.alumnosReserva.every(alumno=>{
        return of.alumnosOf.includes(alumno.id_usuario.toString());
      });

      if(esValida){
        this.ofs.push(of);
      }
    });
  }

  //FILTRO
  onFilter() {
    this.loadingPanel = true;
    //procesamos la fecha
    if(this.fechaInicio!=this.JsemanaSelected.fechaInicio){
      this.fechaInicio = this.JsemanaSelected.fechaInicio;
      this.fechaFin = this.JsemanaSelected.fechaFin;
      this.actualizarDiasSemana();
    }
   // procesamos los grupos de maquinas
    this.gruposMaquinas = [];
    this.jGruposMaquinasFiltro.forEach(gMaquina => {
      var gm = this.dtGruposMaquinas.filter(f=> f.id == gMaquina.id)[0];
      if(gm!=undefined){
        this.gruposMaquinas.push(gm);
      }
    });
    this.gruposMaquinas.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)); // ordenamos para que siempre aparezcan en el mismo orden
    // para que le de tiempo a cargar el HTML
    // despues de un rato es la solución que he encontrado
    // setTimeout(() => {  
    //   this.prepararGantts();
    // }, 500);
    this.prepararGantts();
    this.status = true;
  }
  borrarFiltro() {

  }
  //END FILTRO

  /* 
   asigna la hora minima de inicio y maxima de fin a un dia en especifico
   @param fechaInicio fecha de inicio
   @param fehchaFin fecha del final 
   fechaInicio y fechaFin son la el mismo dia
   @param dia dia de la semana 1-7, se asignaran las horas maximas y minimas de ese dia
  */
  asignarHoraInicioYFinDia(fechaInicio, fechaFin, dia) {
    switch (dia) {
      case 1:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinLunes.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinLunes.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxLunes.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxLunes.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 2:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinMartes.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinMartes.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxMartes.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxMartes.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 3:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinMiercoles.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinMiercoles.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxMiercoles.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxMiercoles.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 4:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinJueves.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinJueves.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxJueves.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxJueves.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 5:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinViernes.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinViernes.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxViernes.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxViernes.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 6:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinSabado.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinSabado.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxSabado.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxSabado.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 7:
        if(this.horaMaxDomingo!=undefined && this.horaMinDomingo!=undefined){
          if (fechaInicio != undefined) {
            fechaInicio.setHours(this.horaMinDomingo.split(':')[0]);
            fechaInicio.setMinutes(this.horaMinDomingo.split(':')[1]);
            fechaInicio.setSeconds(0);
          }
          if (fechaFin != undefined) {
            fechaFin.setHours(this.horaMaxDomingo.split(':')[0]);
            fechaFin.setMinutes(this.horaMaxDomingo.split(':')[1]);
            fechaFin.setSeconds(0);
          }
          break;
        }
    

    }



  }
}
