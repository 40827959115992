<!-- Datos bajo cabecera-->
<div class="card">
    <div class="card-header">
      <h3>
        <label>{{ 'datos' | translate}}</label>
      </h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="clearfix">
        <div class="row">
          <div class="bloques-datos">
              <label>{{ 'programa' | translate}}</label>
              <label #programa></label>
          </div>
          <div class="bloques-datos">
            <label>{{ 'version' | translate}}</label>
            <label #version></label>
          </div>
          <div class="bloques-datos" >
            <label>{{ 'fecha' | translate}}</label>
            <label #fecha></label>
          </div>
          <div class="bloques-datos" >
            <button type="button" class="btn btn-info m-1 float-left" (click)="showFile()">{{ 'verPrograma' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
<!--GRID Versiones-->
<kendo-grid  [kendoGridBinding]="listaSubVersiones"
[navigable]="true"
[pageable]="true"
[sortable]="true"
[reorderable]="true"
[resizable]="true"
[pageSize]="25">
    <kendo-grid-column field="fechaVersion" title="{{ 'fecha' | translate}}" [style]="{'text-align': 'center'}">
      <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.dateToDayString(parseDate(dataItem.fechaVersion))}}</ng-template>
  </kendo-grid-column>
    <kendo-grid-column field="cliente" title="{{ 'cliente' | translate}}"></kendo-grid-column>
    <kendo-grid-column field="numeroOF" title="{{ 'of' | translate}}"></kendo-grid-column>
    <kendo-grid-column field="pieza" title="{{ 'pieza' | translate}}"></kendo-grid-column>
    <kendo-grid-column *ngIf="!user.ocultarParte" field="parte" title="{{ 'parte' | translate}}"></kendo-grid-column>
    <kendo-grid-column field="operario" title="{{ 'operario' | translate}}"></kendo-grid-column>
    <kendo-grid-column field="nombreMaquina" title="{{ 'maquina' | translate}}"></kendo-grid-column>
    <kendo-grid-column field="tReal" title="{{ 'tiempo' | translate}}" [style]="{'text-align': 'right'}">
      <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tReal)}}</ng-template>
  </kendo-grid-column>
    <kendo-grid-column field="observaciones" title="{{ 'observacion' | translate}}"></kendo-grid-column>
    <!--Set list of programs && programs data, field should be the same as attribute we want-->
    <ng-container *ngFor="let subversion of listaSubVersiones"></ng-container>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>
