import { Component } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import {  } from 'rxjs';

import { ControlesService, MenuService } from '@app/_services';

import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService } from '@app/_services';
import { Protocolos_DAT } from '@app/_models';

@Component({
  selector: 'app-controles-detalle',
  templateUrl: './controlesDetalle.component.html'
})
export class ControlesDetalleComponent {

  private translate: TranslateService;

  public id: number;
  public requerido: boolean;
  public controles_DAT: Protocolos_DAT[];
  public selectedItem: Protocolos_DAT;
  public idProtocolo_DAT: number;
  public requeridoChecked: boolean;
  public operacion: boolean;
  public operacionChecked: boolean;



  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  constructor(
    private formBuilder: FormBuilder,
    private controlesService: ControlesService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    translate: TranslateService) {

    this.translate = translate;


  }

  ngOnInit() {
    this.menuService.titulo = this.translate.instant('control').toUpperCase();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.controlesService.GetAll_Controles_DAT().pipe(first()).subscribe(
        (result: any) => {

          var an: any = result.data;
          an.forEach(f => {
            f.nombre = this.translate.instant(f.nombre)
          });

          this.controles_DAT = result.data;
        }
      );
    });

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      descripcion: ['',],
      //idProtocolo_DAT: ['',],
      requerido: [false,],
      operacion: [false,]
    });

    this.controlesService.GetAll_Controles_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre)
        });

        this.controles_DAT = result.data;
      }
    );

    if (this.id > 0) {
      this.controlesService.GetById(this.id).pipe().subscribe((result) => {
        this.selectedItem = new Protocolos_DAT(result.data[0].iDprotocolo, '');
        this.idProtocolo_DAT = result.data[0].iDprotocolo;

        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre, Validators.required],

          idProtocolo_DAT: [result.data[0].IDprotocolo],

        });
      })
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {

    this.controlesService.insert(this.form.value, this.idProtocolo_DAT)
      .subscribe((result) => {
        {
          if (result.error == false) {
            this.router.navigate(['controles']);
          }
          else {

          }
        }
      }
      );
  }

  private update() {
    this.form.value.id = this.id;
    this.controlesService.update(this.form.value, this.idProtocolo_DAT)
      .subscribe((result) => {
        if (result.error == false) {
          this.router.navigate(['controles']);
        } else {

        }
      });
  }

  public selectionChange(value: any): void {
    this.idProtocolo_DAT = value.id
  }

  public atras() {
    this.router.navigate(['controles']);
  }
}
