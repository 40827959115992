<div class="row">
  <div class="col-calendario">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'calendario' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div>
          <app-calendario-cuadrados [docente]="docenteSelected" [curso]="cursoSelected" [grupo]="grupoSelected">
          </app-calendario-cuadrados>
        </div>
      </div>
    </div>
  </div>
  <div class="col-datos-calendario ">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'filtro' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">

        <div class="form-group">
          <kendo-label text="{{'curso' | translate}}">
            <kendo-combobox [data]="cursos" valueField="id" textField="nombre"  [clearButton]="false"
              [(value)]="cursoSelected" (valueChange)="cursoSelectedChange()"></kendo-combobox>
          </kendo-label>
        </div>
        <div class="form-group">
          <kendo-label text="{{'grupo' | translate}}">
            <kendo-combobox [data]="grupos" valueField="id" textField="nombre"  [clearButton]="false"
              [(value)]="grupoSelected" ></kendo-combobox>
          </kendo-label>
        </div>
        <div class="form-group">
          <kendo-label text="{{'docente' | translate}}">
            <kendo-combobox [data]="docentes" valueField="id_docente" textField="nombre"  [clearButton]="false"
              [(value)]="docenteSelected"></kendo-combobox>
          </kendo-label>
        </div>
        <!--BOTONES DE ABAJO-->
        <div class="form-group">
          <div class="calendar-botones">
            <button kendoButton class="btn mr-1  btn-primary btn-sm mr-1 mb-2 w-100" id="onFilter">{{'aceptar' | translate}}</button>
            <button kendoButton class="btn mr-1  btn-danger btn-sm mr-1 mb-2 w-100" id="limpiarFiltro" (click)="limpiarFiltro()">{{'filterClearButton' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>