import { Component } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { MenuService, UsuariosService, PlantaIsometricoService, CentroMecanizadoService, InstalacionesService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { AppComponent } from '@app/app.component';

declare var require: any
var $ = require('jquery');
require('jquery-ui-dist/jquery-ui');

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({
  templateUrl: 'homePlantaIsometrico.component.html',
  styleUrls: ['../../assets/isometrico/estilos.css']
})

export class HomePlantaIsometricoComponent {

  mousePosition = {
    x: 0,
    y: 0
  };

  public idPlanta: any;

  ciclo: number = 0;
  datosPendientesMecanizado: boolean = false;
  datosPendientesInstalaciones: boolean = false;

  interval: any;
  intervalTiempoPasado: any;

  public alto: any = 10;
  public ancho: any = 10;

  public maquinas = [];

  public valdosas: any = [];
  public valdosasExtra: any = [];

  graficoResumenSemana: any;
  graficoDonutOee: any;
  graficoDonutDisponibilidad: any;
  graficoDonutRendimiento: any;
  graficoDonutCalidad: any;

  primeraCargaCompletada1: boolean = false;
  primeraCargaCompletada2: boolean = false;

  //DATOS PANEL MAQUINA
  public menuDesplegadoMaquina: boolean = false;

  idMaquina: any;

  nombreMaquina: any;

  tipo_maquina: any;

  operario: any;

  idProcesosTipo: any;
  procesosTipo: any;
  procesosTipoStr: any;

  porcentaje: any;
  estadoimg: any;
  tiempopasado: any;
  tiempoEstEjec: any;
  tiempoEstPrep: any;
  tiempoPred: any;
  tiempoAcumuladoOperacion: any;
  tiempoRealHornosTemple: any;

  incidencia: any;

  refOF: any;
  cliente: any;
  pieza: any;
  parte: any;
  operacion: any;
  numeroSerie: any;
  realizadas: any;

  //MECANIZADO
  ejes: any;

  //EXTRUSORAS
  rendSopladores: any;
  controlEsp: any;
  temperaturasExtrusora: any;

  //IMPRESORAS DE PLASTICO
  speed: any;
  secTempBCD: any;
  secTunelTempBCD: any;
  presionesImpresora: any;

  //INYECTORAS
  cicloInyectora: any;
  tonelaje: any;
  unidadInyeccion: any;
  numMaquina: any;

  //HORNOS
  cicloHorno: any;
  eurosHora: any;
  porcenEurosHora: any;
  piezasHora: any;
  porcenPiezasHora: any;
  tRestCalidad: any;
  temperatura: any;
  kilowatio: any;


  //HORNOS TEMPLE:
  temperaturaProgramada: any;
  temperaturaActual: any;
  temperaturaInterior: any;
  consumo: any;
  nivelO2: any;

  temperaturasHornoTemple: any;

  resumenSemana: any;

  dataMaquinas: any;
  esperadnoOEE: boolean = false;
  primeraCarga: boolean = false;
  oee: any;
  disponibilidad: any;
  rendimiento: any;
  calidad: any;

  //DATOS PANEL INSTALACION
  public menuDesplegadoInstalaciones: boolean = false;

  user = this.userService.userValue;

  instalacionMomentanea: any = { nombre: "", campos: [] };
  campos: any = [{ nombre: "campo1", visible: false }, { nombre: "campo2", visible: false }, { nombre: "campo3", visible: false }, { nombre: "campo4", visible: false },
  { nombre: "campo5", visible: false }, { nombre: "campo6", visible: false }, { nombre: "campo7", visible: false }, { nombre: "campo8", visible: false },
  { nombre: "campo9", visible: false }, { nombre: "campo10", visible: false }];

  constructor(private userService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private plantaIsometricoService: PlantaIsometricoService,
    private centroMecanizadoService: CentroMecanizadoService,
    private instalacionesService: InstalacionesService,
    private myFunctions: MyFunctions,
    private appComponent: AppComponent) {

    //AL CAMBIAR DE PAGINA VOLVER A HABILITAR EL SCROLL
    router.events.subscribe(value => {
      this.allowScroll();
    });

    this.interval = setInterval(() => {
      this.refreshData();
    }, 50000);

    this.router.events.subscribe((event: Event) => {
      //if (event instanceof NavigationStart) {

      //}

      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('/homeplantaisometrico/')) {
          this.cargarTodo();
        }
      }

      //if (event instanceof NavigationError) {

      //}
    });
  }

  cargarTodo(): void {

    this.menuService.titulo = this.translateService.instant('homePlanta').toUpperCase();

    this.idPlanta = this.route.snapshot.params['idPlanta'];

    var selectedPlanta = this.appComponent.listaPlantas.find(x => x.id == this.idPlanta);
    if (selectedPlanta != undefined) this.appComponent.selectedPlanta = selectedPlanta;

    this.prepararVariables();

    this.crearObjetos();

    this.crearValdosas();

    this.dibujarGraficos();

  }

  ngOnInit(): void {

  }

  prepararVariables() {

    this.idMaquina = -1;
    this.nombreMaquina = "";
    this.tipo_maquina = -1;
    this.operario = "";

    this.idProcesosTipo = 0;
    this.procesosTipo = "";

    this.porcentaje = "0%";
    this.estadoimg = "assets/dcg/dcg0-apagada.png";
    this.tiempopasado = "00:00:00";
    this.tiempoEstEjec = "00:00:00";
    this.tiempoEstPrep = "00:00:00";
    this.tiempoPred = "00:00:00";
    this.tiempoAcumuladoOperacion = 0;

    this.incidencia = "";

    this.refOF = "";
    this.cliente = "";
    this.pieza = "";
    this.parte = "";
    this.operacion = "";
    this.numeroSerie = "";
    this.realizadas = "";

    //MECANIZADO
    this.ejes = [
      { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 1", valorGrafico: 0 },
      { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 2", valorGrafico: 0 },
      { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potencabezal"), valorGrafico: 0 },
      { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potenavances"), valorGrafico: 0 },
    ];

    //EXTRUSORAS
    this.rendSopladores = 0;
    this.controlEsp = 0;
    this.temperaturasExtrusora = [
      { valor: 0, nombre: this.translateService.instant("tAnill"), valorGrafico: 0 },
      { valor: 0, nombre: this.translateService.instant("tIBC"), valorGrafico: 0 },
      { valor: 0, nombre: this.translateService.instant("tRodCal"), valorGrafico: 0 },
      { valor: 0, nombre: this.translateService.instant("tRodHal"), valorGrafico: 0 },
    ];

    //IMPRESORAS DE PLASTICO
    this.speed = 0;
    this.secTempBCD = 0;
    this.secTunelTempBCD = 0;
    this.presionesImpresora = [
      { valor: 0, nombre: this.translateService.instant("desb"), valorGrafico: 0 },
      { valor: 0, nombre: this.translateService.instant("tAlim"), valorGrafico: 0 },
      { valor: 0, nombre: this.translateService.instant("rodEnf"), valorGrafico: 0 },
      { valor: 0, nombre: this.translateService.instant("bobi"), valorGrafico: 0 },
    ];

    //INYECTORAS
    this.cicloInyectora = "";
    this.tonelaje = "";
    this.unidadInyeccion = "";
    this.numMaquina = "";

    //HORNOS
    this.cicloHorno = "";
    this.eurosHora = "";
    this.porcenEurosHora = "";
    this.piezasHora = "";
    this.porcenPiezasHora = "";
    this.tRestCalidad = "";
    this.temperatura = false;
    this.kilowatio = false;

    //HORNOS TEMPLE:
    this.temperaturaProgramada = "";
    this.temperaturaActual = "";
    this.temperaturaInterior = "";
    this.consumo = "";
    this.nivelO2 = "";
    this.temperaturasHornoTemple = [];

    this.resumenSemana = [];

    this.oee = 0;
    this.disponibilidad = 0;
    this.rendimiento = 0;
    this.calidad = 0;

  }

  crearObjetos() {

    ///MAQUINAS
    this.maquinas = [];

    this.plantaIsometricoService.Get_Maquinas().subscribe(result => {

      var maquinas: any = result;

      maquinas.forEach(function (maq) {

        var maquina: any = {};
        maquina.idMaquina = maq.idMaquina;
        maquina.nombre = maq.nombre;
        maquina.idObjeto = maq.idObjeto;
        maquina.imagenes = [maq.imagen1_1, maq.imagen1_2, maq.imagen1_3, maq.imagen2_1, maq.imagen2_2, maq.imagen2_3, maq.imagen3_1, maq.imagen3_2, maq.imagen3_3, maq.imagen4_1, maq.imagen4_2, maq.imagen4_3];
        maquina.imagenesMenu = [maq.imagenMenu1, maq.imagenMenu2, maq.imagenMenu3, maq.imagenMenu4];
        maquina.posicionImagen = 0;
        maquina.seleccionada = false;
        maquina.alto = maq.alto;
        maquina.ancho = maq.ancho;

        this.maquinas.push(maquina);

      }, this);

    });

  }

  crearValdosas() {

    this.plantaIsometricoService.Get_Dimensiones(this.idPlanta).subscribe(result => {

      this.alto = result[0].alto;
      this.ancho = result[0].ancho;

      //UNA VEZ TENEMOS EL ALTO Y EL ANCHO CREAMOS EL ISOMETRICO (PARA AJUSTARLE EL ZOOM)
      this.crearIsometrico();

      var r1, r2: boolean = false;

      //COGEMOS LAS VALDOSAS
      this.plantaIsometricoService.Get_Disenno(this.idPlanta).subscribe(result => {

        var res: any = result;

        if (res.length > 0) {
          this.valdosas = res;
        } else {
          var i;
          for (i = 1; i <= (this.ancho * this.alto); i++) {
            this.valdosas.push({ tilenum: i, esObjeto: false, pared: 0, tileSeleccionado: false });
          }
        }

        this.anadirParedes();
        this.cargarDatos();

        r1 = true;
        if (r1 && r2) this.anadirEnseñarEstado();

      });

      //COGEMOS LAS VALDOSAS DEL SEGUNDO PISO
      this.plantaIsometricoService.Get_Disenno_Segundo_Piso(this.idPlanta).subscribe(result => {

        var res: any = result;

        if (res.length > 0) {
          this.valdosasExtra = res;
        } else {
          this.valdosasExtra = [];
        }

        r2 = true;
        if (r1 && r2) this.anadirEnseñarEstado();

      });

    });

  }

  anadirEnseñarEstado() {//DECIDIMOS QUE VALDOSAS TENDRAN EL INDICADOR DE ESTADO (PARA OBJETOS DE MAS DE 1x1)

    this.valdosas.forEach(function (val) {
      if (val.esObjeto && val.idTipoObjeto == 1 && !val.tienePareja) { //1x1
        val.ensennarEstado = true;
      } else if (val.esObjeto && val.idTipoObjeto == 1 && val.tienePareja) { //Mas de 1x1

        var valdosasPareja = this.valdosas.filter(function (e) { return e.pareja == val.pareja; });
        var valdosasSegundaPlanta = this.valdosasExtra.filter(function (e) { return e.idObjeto == val.idObjeto; });

        //2x1
        if (valdosasPareja.length == 2) {
          if (valdosasSegundaPlanta.length == 0) {//NO TIENE IMAGENES EN SEGUNDO PISO
            valdosasPareja[1].ensennarEstado = true;
          } else {//TIENE IMAGENES EN SEGUNDO PISO
            if (valdosasSegundaPlanta.length == 1) valdosasSegundaPlanta[0].ensennarEstado = true;
            if (valdosasSegundaPlanta.length == 2) valdosasSegundaPlanta[1].ensennarEstado = true;
          }
        }

        //3x1
        if (valdosasPareja.length == 3) {
          if (valdosasSegundaPlanta.length == 0) {//NO TIENE IMAGENES EN SEGUNDO PISO
            valdosasPareja[1].ensennarEstado = true;
          } else {//TIENE IMAGENES EN SEGUNDO PISO
            if (valdosasSegundaPlanta.length == 1) valdosasSegundaPlanta[0].ensennarEstado = true;
            if (valdosasSegundaPlanta.length == 2) valdosasSegundaPlanta[1].ensennarEstado = true;
            if (valdosasSegundaPlanta.length == 3) valdosasSegundaPlanta[2].ensennarEstado = true;
          }
        }

        //4x1
        if (valdosasPareja.length == 4) {
          if (valdosasSegundaPlanta.length == 0) {//NO TIENE IMAGENES EN SEGUNDO PISO
            valdosasPareja[0].ensennarEstado = true;
          } else {//TIENE IMAGENES EN SEGUNDO PISO
            if (valdosasSegundaPlanta.length == 1) valdosasSegundaPlanta[0].ensennarEstado = true;
            if (valdosasSegundaPlanta.length == 2) valdosasSegundaPlanta[1].ensennarEstado = true;
            if (valdosasSegundaPlanta.length == 3) valdosasSegundaPlanta[2].ensennarEstado = true;
            if (valdosasSegundaPlanta.length == 4) valdosasSegundaPlanta[3].ensennarEstado = true;
          }
        }

      }

      //SI ES INSTALACION NO ENSEÑAR
      //if (val.idTipoMaquina == 8) val.ensennarEstado = false;

    }, this);

  }

  anadirParedes() { //AÑADE LAS PAREDES A LAS VALDOSAS DEL FONDO

    //PONER TODAS LAS VALDOSAS SIN PARED
    this.valdosas.forEach(function (val) {
      val.pared = 0;

      //JON: Aprobetxamos que se recorren todas las valdosas para añadir las carreteras y no tener que recorrer todas otra vez solo para esto (un poco cutre pero por ahorrar una buelta mas)
      if (val.idTipoObjeto == 2){
        val.carretera = val.src;
      }

    }, this);

    //COLOCAR LAS PAREDES DEL FONDO A LA IZQUIERDA
    var i;
    for (i = 0; i < this.ancho; i++) {
      this.valdosas[i].pared = 1;
    }

    //COLOCAR LAS PAREDES DEL FONDO A LA DERECHA
    var i;
    for (i = 0; i < this.alto; i++) {
      this.valdosas[i * this.ancho].pared = 2;
    }

    //COLOCAR LA PARED DE LA ESQUINA DEL FONDO
    this.valdosas[0].pared = 3;

  }

  dibujarGraficos() {
    this.dibujarResumenSemana();
    this.dibujarOee();
    this.dibujarCamposInstalaciones();
  }

  dibujarResumenSemana() {

    this.graficoResumenSemana = c3.generate({
      bindto: '#graficoResumenSemana_Isometrico',
      size: {
        height: 91
      },
      padding: {
        top: -15,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });

  }

  dibujarOee() {

    this.dibujarDonut("#graficoDonutOee_Isometrico", 1);
    this.dibujarDonut("#graficoDonutDisponibilidad_Isometrico", 2);
    this.dibujarDonut("#graficoDonutRendimiento_Isometrico", 3);
    this.dibujarDonut("#graficoDonutCalidad_Isometrico", 4);

  }

  dibujarDonut(div, index) {

    var donut = c3.generate({
      bindto: div,
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        names: {
          oee: this.translateService.instant("oee"),
        },
        order: 'null'
      },
      donut: {
        title: "",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: ["#18D6B0", "#3D6063"],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: 500
      }
    });

    d3.selectAll(div + " .c3-chart-arcs path").style("stroke-width", "0px");
    d3.select(div + ' .c3-chart-arcs-title').text("").style("font-size", "17px").attr("fill", "#43cdcd");

    if (index == 1) this.graficoDonutOee = donut;
    if (index == 2) this.graficoDonutDisponibilidad = donut;
    if (index == 3) this.graficoDonutRendimiento = donut;
    if (index == 4) this.graficoDonutCalidad = donut;

  }

  async dibujarCamposInstalaciones() {

    var th = this;

    //ESPERAR A QUE CREE LOS DIVS
    while (document.getElementById("graficoValorInstalacion_1") == null || document.getElementById("graficoHistoricoInstalacion_10") == null) {
      await new Promise(r => setTimeout(r, 100));
    }

    var i;
    for (i = 1; i <= 10; i++) {

      var chartValor = c3.generate({
        bindto: '#graficoValorInstalacion_' + i,
        data: {
          columns: [
            ['data', 0]
          ],
          type: 'gauge'
        },
        gauge: {
          label: {
            format: function (value, ratio) {
              return "";
            },
          },
          min: 0,
          max: 100,
          width: 15
        },
        color: {
          pattern: ['#00A190']
        },
        legend: {
          show: false
        },
        tooltip: {
          show: false
        },
        size: {
          width: 100,
          height: 70
        },
        transition: {
          duration: 500
        }
      });

      var chartHistorico = c3.generate({
        bindto: '#graficoHistoricoInstalacion_' + i,
        data: {
          x: 'x',
          columns: [
            ['x'],
            ['data']
          ],
          types: {
            data: 'area',
          }
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              values: function (x) {
                return d3.scaleTime().domain(x).ticks(2);
              },
              format: '%H:%M:%S'
            },
            padding: 0
          },
          y: {
            tick: {
              values: function (x) {
                return d3.scaleLinear().domain(x).ticks(5);
              }
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          format: {
            name: function (d) { return th.translateService.instant('valor'); }
          }
        },
        padding: {
          bottom: -5
        },
        size: {
          width: 235,
          height: 100
        },
        transition: {
          duration: 500
        },
        point: {
          show: false
        }
      });

      this.campos[i - 1].chartValor = chartValor;
      this.campos[i - 1].chartHistorico = chartHistorico;

    }

  }

  onMouseDown($event) {
    this.mousePosition.x = $event.screenX;
    this.mousePosition.y = $event.screenY;
  }

  clickValdosa(valdosa, event) {

    if (this.mousePosition.x === event.screenX && this.mousePosition.y === event.screenY) {

      this.menuDesplegadoMaquina = false;
      this.menuDesplegadoInstalaciones = false;

      if (valdosa.tileSeleccionado) {

        //Deseleccionar todos los tiles
        this.valdosas.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);
        this.valdosasExtra.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);

      } else {

        //Deseleccionar todos los tiles
        this.valdosas.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);
        this.valdosasExtra.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);

        if (valdosa.idTipoObjeto == 1) valdosa.tileSeleccionado = true;

        //Seleccionar los tiles de la misma pareja
        if (valdosa.tienePareja) {
          this.valdosas.forEach(function (tile) {
            if (tile.esObjeto) {
              if (valdosa.pareja == tile.pareja) tile.tileSeleccionado = true;
            }
          }, this);
        }
        //Seleccionar los tiles del segundo piso
        this.valdosasExtra.forEach(function (tile) {
          if (tile.esObjeto) {
            if (valdosa.idObjeto == tile.idObjeto && valdosa.idTipoMaquina == tile.idTipoMaquina) {
              tile.tileSeleccionado = true;
            }
          }
        }, this);

        if (valdosa.idTipoMaquina == 1 || valdosa.idTipoMaquina == 2 || valdosa.idTipoMaquina == 3 || valdosa.idTipoMaquina == 4 || valdosa.idTipoMaquina == 5) this.mostrarDatosMaquinaPanel(valdosa);
        if (valdosa.idTipoMaquina == 8) this.mostrarDatosInstalacionPanel(valdosa);
        if (valdosa.idTipoMaquina == 12) this.mostrarDatosMaquinaPanel(valdosa); //this.mostrarDatosHornosTemplePanel(valdosa);

      }

    }

  }

  mouseEnterValdosa(valdosa) {

    valdosa.sobre = true;

    //Seleccionar los tiles de la misma pareja
    if (valdosa.tienePareja) {
      this.valdosas.forEach(function (tile) {
        if (tile.esObjeto) {
          if (valdosa.pareja == tile.pareja && valdosa.idTipoMaquina == tile.idTipoMaquina) tile.sobre = true;
        }
      }, this);
    }
    //Seleccionar los tiles del segundo piso
    this.valdosasExtra.forEach(function (tile) {
      if (tile.esObjeto) {
        if (valdosa.idObjeto == tile.idObjeto && valdosa.idTipoMaquina == tile.idTipoMaquina) tile.sobre = true;
      }
    }, this);

  }

  mouseLeaveValdosa(valdosa) {

    valdosa.sobre = false;

    //Deseleccionar los tiles de la misma pareja
    if (valdosa.tienePareja) {
      this.valdosas.forEach(function (tile) {
        if (tile.esObjeto) {
          if (valdosa.pareja == tile.pareja) tile.sobre = false;
        }
      }, this);
    }
    //Deseleccionar los tiles del segundo piso
    this.valdosasExtra.forEach(function (tile) {
      if (tile.esObjeto) {
        if (valdosa.idObjeto == tile.idObjeto) tile.sobre = false;
      }
    }, this);

  }

  crearIsometrico() {

    //enumerar tiles
    $('.tile').each(function (i, c) {
      $(c).attr('tilenum', i + 1);
    });

    var i = 1.0;

    //DESPLAZAR MAPA
    $(".planta").draggable();

    //ZOOM
    $('.planta').on('mousewheel', 'div', function (e) {
      if (e.originalEvent.wheelDelta / 120 > 0) {
        i = i + 0.1;
      }
      else {
        i = i - 0.1;
      }
      if (i < 0.5)
        i = 0.5
      if (i > 3)
        i = 3
      $(".planta").css('transform', 'scale(' + i + ')');
    });

    //ZOOM INICIAL (DEPENDIENDO DE ANCHO Y ALTO)
    var dimMax = Math.max(this.alto, this.ancho);
    var dif = Math.abs(this.alto - this.ancho);
    if (dimMax > 10 && dimMax <= 15) {
      $(".planta").css('transform', 'scale(0.85)');
      //$(".isometrico").css('top', dif * -3 + '%');
    }
    if (dimMax > 15 && dimMax <= 20) {
      $(".planta").css('transform', 'scale(0.7)');
      //$(".isometrico").css('top', dif * -3 + '%');
    }
    if (dimMax > 20 && dimMax <= 30) {
      $(".planta").css('transform', 'scale(0.6)');
      //$(".isometrico").css('top', dif * -3 + '%');
    }

    //EN EL HOME DESPLAZAR A LA DERECHA PORQUE NO HAY PANEL ABIERTO
    $(".isometrico").css('left', '65%');

    //NO PERMITIR SCROLL
    var main = document.getElementById("master");
    main.style.overflowY = "hidden";
    main.style.overflowX = "hidden";

  }

  allowScroll() {
    if (!this.router.url.includes('/homeplantaisometrico/')) {
      var main = document.getElementById("master");
      main.style.overflowY = "auto";
      main.style.overflowX = "auto";
    }
  }

  ajustarZoom() {

  }

  cargarDatos() {

    this.cargarDatosMecanizado();
    this.cargarDatosInstalaciones();

  }

  cargarDatosMecanizado() {

    this.centroMecanizadoService.getIsometrico(this.ciclo).subscribe((result) => {

      this.primeraCargaCompletada1 = true;

      var dataMaquinas: any = result.maquinas;
      this.dataMaquinas = dataMaquinas;
      dataMaquinas.forEach((maquina) => {

        var ejecuciones = result.ejecuciones.filter((element) => (element.idMaquina == maquina.id && element.id == maquina.idHistoricoProcTemp));
        //var canales = result.canales.filter((element) => element.idMaquina == maquina.id);
        var dtEjes = result.ejes.filter((element) => element.iDmaquina == maquina.id);
        var tiempoReal = result.tiempoReal.filter((element) => element.idmaquina == maquina.id);
        var tiempoRealExtrusoras = result.tiempoRealExtrusoras.filter((element) => element.idMaquina == maquina.id);
        var tiempoRealImpresoras = result.tiempoRealImpresoras.filter((element) => element.idMaquina == maquina.id);
        var tiempoRealInyectoras = result.tiempoRealInyectoras.filter((element) => element.idMaquina == maquina.id);
        var tiempoRealHornos = [];//var tiempoRealHornos = result.tiempoRealHornos.filter((element) => element.idMaquina == maquina.id);
        //var tiempoRealHornosTemple = [];
        this.tiempoRealHornosTemple = [];
        if (result.tiempoRealHornosTemple != null) this.tiempoRealHornosTemple = result.tiempoRealHornosTemple.filter((element) => element.idMaquina == maquina.id);
        var tiempoPorSemana = result.tiempoPorSemana.filter((element) => element.idMaquina == maquina.id);
        // var dtOee = result.oee.filter((element) => element.idMaquina == maquina.id);

        var idMaquina;
        var nombreMaquina;
        var tipo_maquina;
        var operario;
        var idProcesosTipo;
        var procesosTipo;
        var procesosTipoStr;

        var porcentaje;
        var estadoimg;
        var fechaServidor;
        var tiempopasado;
        var tiempoEstEjec;
        var tiempoEstPrep;
        var tiempoPred;
        var tiempoAcumuladoOperacion;

        var incidencia;

        var refOF;
        var cliente;
        var pieza;
        var parte;
        var operacion;
        var numeroSerie;
        var realizadas;

        var ejes;

        var rendSopladores;
        var controlEsp;
        var temperaturasExtrusora;

        var speed;
        var secTempBCD;
        var secTunelTempBCD;
        var presionesImpresora;

        var cicloInyectora;
        var tonelaje;
        var unidadInyeccion;
        var numMaquina;

        var cicloHorno;
        var eurosHora;
        var porcenEurosHora;
        var piezasHora;
        var porcenPiezasHora;
        var tRestCalidad;
        var temperatura;
        var kilowatio;

        var temperaturaProgramada;
        var temperaturaActual;
        var temperaturaInterior;
        var consumo;
        var nivelO2;

        var temperaturasHornoTemple = [];

        var resumenSemana = [];

        if (ejecuciones != undefined && ejecuciones.length > 0) {

          idMaquina = ejecuciones[0].idMaquina;
          nombreMaquina = ejecuciones[0].nombre;
          tipo_maquina = maquina.tipo_maquina;
          operario = ejecuciones[0].operario;
          if (operario == "" || operario == " ") operario = this.translateService.instant('sinOperario');
          idProcesosTipo = ejecuciones[0].idProcesos_Tipo;
          procesosTipo = ejecuciones[0].procesos_Tipo;
          if (procesosTipo != null) procesosTipoStr = this.translateService.instant(procesosTipo);
          else procesosTipoStr = "";

          if (ejecuciones[0].refOF == undefined || ejecuciones[0].refOF == "") {
            refOF = this.translateService.instant("noproceso");
            cliente = "";
            pieza = "";
            parte = "";
            operacion = "";
            numeroSerie = "";
            realizadas = "";
          } else {
            refOF = ejecuciones[0].refOF;
            cliente = ejecuciones[0].cliente;
            pieza = ejecuciones[0].pieza;
            parte = ejecuciones[0].parte;
            operacion = ejecuciones[0].operacion;
            numeroSerie = ejecuciones[0].numeroSerie;
            realizadas = ejecuciones[0].hechas + "/" + ejecuciones[0].cantidad;
          }

          fechaServidor = ejecuciones[0].fechaServidor;
          tiempopasado = ejecuciones[0].fechainicio;
          tiempoEstEjec = this.myFunctions.secondsTo_HH_MM_SS(ejecuciones[0].tiempoEstimadoSegundos);
          tiempoEstPrep = this.myFunctions.secondsTo_HH_MM_SS(ejecuciones[0].tiempoPreparacionSegundos);
          tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(ejecuciones[0].tiempoPredSegundos);
          tiempoAcumuladoOperacion = ejecuciones[0].tiempoAcumuladoOperacion;

          this.CargarIconosEstadoMaquina(maquina, ejecuciones);

          porcentaje = maquina.porcentaje;
          estadoimg = maquina.estadoimg;

          //INCIDENCIAQUI
          if (procesosTipo != null) {
            if (procesosTipo.toLowerCase() == 'alarma') incidencia = ejecuciones[0].descripcion;
            if (procesosTipo.toLowerCase() == 'parada') incidencia = ejecuciones[0].parada;
            if (procesosTipo.toLowerCase() == 'mantenimiento') incidencia = ejecuciones[0].mantenimiento;
          }

        } else {

          idMaquina = -1;
          nombreMaquina = "";
          tipo_maquina = -1;
          operario = "";
          idProcesosTipo = -1;
          procesosTipo = "";
          procesosTipoStr = "";

          refOF = this.translateService.instant("noproceso");
          cliente = "";
          pieza = "";
          parte = "";
          operacion = "";
          numeroSerie = "";
          realizadas = "";

          fechaServidor = "00:00:00";
          tiempopasado = "00:00:00";
          tiempoEstEjec = "00:00:00";
          tiempoEstPrep = "00:00:00";
          tiempoPred = "00:00:00";
          tiempoAcumuladoOperacion = 0;

          incidencia = "";

          porcentaje = "0%";
          estadoimg = "assets/dcg/dcg0-apagada.png";

        }

        if (tiempoPorSemana != undefined && tiempoPorSemana.length > 0) {
          resumenSemana = [
            [this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
            [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
            [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
            [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
            [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
            [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
            [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]
          ];
        } else {
          resumenSemana = [
            [this.translateService.instant("ejecucion"), 0],
            [this.translateService.instant("parada"), 0],
            [this.translateService.instant("microparada"), 0],
            [this.translateService.instant("preparacion"), 0],
            [this.translateService.instant("mantenimiento"), 0],
            [this.translateService.instant("alarma"), 0],
            [this.translateService.instant("apagada"), 0]
          ];
        }

        if (tiempoReal != undefined && tiempoReal.length > 1 && dtEjes != undefined) {

          //EJE 1
          var minEje1 = dtEjes[0].feedMinimo;
          var maxEje1 = dtEjes[0].feedMaximo;
          var nombreEje1 = dtEjes[0].nombre;

          var tempAlarmEje1 = dtEjes[0].tempAlarm;
          var tempMaxEje1 = dtEjes[0].tempMax;
          var consumAlarmEje1 = dtEjes[0].consumAlarm;
          var consumMaxEje1 = dtEjes[0].consumMax;

          var eje1 = tiempoReal[0].rpm;
          var valorGraficoEje1 = this.calcularPorcentaje(eje1 - minEje1, 48, maxEje1 - minEje1, 0, true);
          var tempEje1 = tiempoReal[0].temp;
          var consumEje1 = tiempoReal[0].consumo;

          var alertaTemperaturaEje1;
          var logoalarmatempEje1;
          var alertaConsumoEje1;
          var logoalarmaconsumEje1;

          if (tempEje1 > tempMaxEje1 && tempMaxEje1 != -1) {
            alertaTemperaturaEje1 = true;
            logoalarmatempEje1 = "assets/tempRojo.png";
          } else if (tempEje1 > tempAlarmEje1 && tempAlarmEje1 != -1) {
            alertaTemperaturaEje1 = true;
            logoalarmatempEje1 = "assets/tempAmarillo.png";
          } else {
            alertaTemperaturaEje1 = false;
            logoalarmatempEje1 = "";
          }

          if (consumEje1 > consumMaxEje1 && consumMaxEje1 != -1) {
            alertaConsumoEje1 = true;
            logoalarmaconsumEje1 = "assets/warningRojo.png";
          } else if (consumEje1 > consumAlarmEje1 && consumAlarmEje1 != -1) {
            alertaConsumoEje1 = true;
            logoalarmaconsumEje1 = "assets/warningAmarillo.png";
          } else {
            alertaConsumoEje1 = false;
            logoalarmaconsumEje1 = "";
          }

          //EJE 2
          var minEje2 = dtEjes[1].feedMinimo;
          var maxEje2 = dtEjes[1].feedMaximo;
          var nombreEje2 = dtEjes[1].nombre;

          var tempAlarmEje2 = dtEjes[1].tempAlarm;
          var tempMaxEje2 = dtEjes[1].tempMax;
          var consumAlarmEje2 = dtEjes[1].consumAlarm;
          var consumMaxEje2 = dtEjes[1].consumMax;

          var eje2 = tiempoReal[1].rpm;
          var valorGraficoEje2 = this.calcularPorcentaje(eje2 - minEje2, 48, maxEje2 - minEje2, 0, true);
          var tempEje2 = tiempoReal[1].temp;
          var consumEje2 = tiempoReal[1].consumo;

          var alertaTemperaturaEje2;
          var logoalarmatempEje2;
          var alertaConsumoEje2;
          var logoalarmaconsumEje2;

          if (tempEje2 > tempMaxEje2 && tempMaxEje2 != -1) {
            alertaTemperaturaEje2 = true;
            logoalarmatempEje2 = "assets/tempRojo.png";
          } else if (tempEje2 > tempAlarmEje2 && tempAlarmEje2 != -1) {
            alertaTemperaturaEje2 = true;
            logoalarmatempEje2 = "assets/tempAmarillo.png";
          } else {
            alertaTemperaturaEje2 = false;
            logoalarmatempEje2 = "";
          }

          if (consumEje2 > consumMaxEje2 && consumMaxEje2 != -1) {
            alertaConsumoEje2 = true;
            logoalarmaconsumEje2 = "assets/warningRojo.png";
          } else if (consumEje2 > consumAlarmEje2 && consumAlarmEje2 != -1) {
            alertaConsumoEje2 = true;
            logoalarmaconsumEje2 = "assets/warningAmarillo.png";
          } else {
            alertaConsumoEje2 = false;
            logoalarmaconsumEje2 = "";
          }

          //POTEN 1
          var minPotenciometro = dtEjes[0].potenMin;
          var maxPotenciometro = dtEjes[0].potenMax;

          var poten1 = tiempoReal[0].potenciometro;
          var valorGraficoPoten1 = this.calcularPorcentaje(poten1 - minPotenciometro, 48, maxPotenciometro - minPotenciometro, 0, true);

          //POTEN 2
          var poten2 = tiempoReal[0].potenciometro2;
          var valorGraficoPoten2 = this.calcularPorcentaje(poten2 - minPotenciometro, 48, maxPotenciometro - minPotenciometro, 0, true);

          ejes = [
            { alertaTemperatura: alertaTemperaturaEje1, logoalarmatemp: logoalarmatempEje1, alertaConsumo: alertaConsumoEje1, logoalarmaconsum: logoalarmaconsumEje1, valor: eje1.toFixed(1), nombre: nombreEje1, valorGrafico: valorGraficoEje1 },
            { alertaTemperatura: alertaTemperaturaEje2, logoalarmatemp: logoalarmatempEje2, alertaConsumo: alertaConsumoEje2, logoalarmaconsum: logoalarmaconsumEje2, valor: eje2.toFixed(1), nombre: nombreEje2, valorGrafico: valorGraficoEje2 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: poten1.toFixed(1), nombre: this.translateService.instant("potencabezal"), valorGrafico: valorGraficoPoten1 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: poten2.toFixed(1), nombre: this.translateService.instant("potenavances"), valorGrafico: valorGraficoPoten2 },
          ];

        } else {

          ejes = [
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 1", valorGrafico: 0 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 2", valorGrafico: 0 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potencabezal"), valorGrafico: 0 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potenavances"), valorGrafico: 0 },
          ];

        }

        //SI ES EXTRUSORA CALCULAR TEMPERATURAS
        if (maquina.tipo_maquina == 2) {
          if (tiempoRealExtrusoras.length > 0) {

            temperaturasExtrusora = [];

            //infoControlEspesores
            controlEsp = tiempoRealExtrusoras[0].infoControlEspesores;

            //rendimientoSopladores
            rendSopladores = tiempoRealExtrusoras[0].rendimientoSopladores;

            //tempAnilloEntrada
            var min = tiempoRealExtrusoras[0].tempAnilloEntrada_min;
            var max = tiempoRealExtrusoras[0].tempAnilloEntrada_max;
            var valor = tiempoRealExtrusoras[0].tempAnilloEntrada;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tAnill"), valorGrafico: valorGrafico });

            //tempIBCDeEntrada
            var min = tiempoRealExtrusoras[0].tempIBCDeEntrada_min;
            var max = tiempoRealExtrusoras[0].tempIBCDeEntrada_max;
            var valor = tiempoRealExtrusoras[0].tempIBCDeEntrada;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tIBC"), valorGrafico: valorGrafico });

            //tempRodilloCalandra
            var min = tiempoRealExtrusoras[0].tempRodilloCalandra_min;
            var max = tiempoRealExtrusoras[0].tempRodilloCalandra_max;
            var valor = tiempoRealExtrusoras[0].tempRodilloCalandra;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tRodCal"), valorGrafico: valorGrafico });

            //tempRodilloHalador
            var min = tiempoRealExtrusoras[0].tempRodilloHalador_min;
            var max = tiempoRealExtrusoras[0].tempRodilloHalador_max;
            var valor = tiempoRealExtrusoras[0].tempRodilloHalador;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tRodHal"), valorGrafico: valorGrafico });

          } else {

            rendSopladores = 0;
            controlEsp = 0;
            temperaturasExtrusora = [
              { valor: 0, nombre: this.translateService.instant("tAnill"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tIBC"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tRodCal"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tRodHal"), valorGrafico: 0 },
            ];

          }
        }

        //SI ES IMPRESORA DE PLASTICO CALCULAR PRESIONES
        if (maquina.tipo_maquina == 3) {
          if (tiempoRealImpresoras.length > 0) {

            presionesImpresora = [];

            //speed
            speed = tiempoRealImpresoras[0].speed;

            //secadorTempBCD
            secTempBCD = tiempoRealImpresoras[0].secadorTempBCD;

            //secadorTunelTempBCD
            secTunelTempBCD = tiempoRealImpresoras[0].secadorTunelTempBCD;

            //desbobinadora
            var min = tiempoRealImpresoras[0].desbobinadora_min;
            var max = tiempoRealImpresoras[0].desbobinadora_max;
            var valor = tiempoRealImpresoras[0].desbobinadora;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("desb"), valorGrafico: valorGrafico });

            //tomaDeAlimentacion
            var min = tiempoRealImpresoras[0].tomaDeAlimentacion_min;
            var max = tiempoRealImpresoras[0].tomaDeAlimentacion_max;
            var valor = tiempoRealImpresoras[0].tomaDeAlimentacion;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tAlim"), valorGrafico: valorGrafico });

            //accionadorRodilloEnfriamiento
            var min = tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_min;
            var max = tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_max;
            var valor = tiempoRealImpresoras[0].accionadorRodilloEnfriamiento;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("rodEnf"), valorGrafico: valorGrafico });

            //bobinadora
            var min = tiempoRealImpresoras[0].bobinadora_min;
            var max = tiempoRealImpresoras[0].bobinadora_max;
            var valor = tiempoRealImpresoras[0].bobinadora;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("bobi"), valorGrafico: valorGrafico });

          } else {

            speed = 0;
            secTempBCD = 0;
            secTunelTempBCD = 0;
            presionesImpresora = [
              { valor: 0, nombre: this.translateService.instant("desb"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tAlim"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("rodEnf"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("bobi"), valorGrafico: 0 },
            ];

          }
        }

        //SI ES INYECTORA
        if (maquina.tipo_maquina == 4) {
          if (tiempoRealInyectoras.length > 0) {
            cicloInyectora = tiempoRealInyectoras[0].nCiclo;
            tonelaje = maquina.tonelaje;
            unidadInyeccion = maquina.unidadInyeccion;
            numMaquina = maquina.numMaquina;
          } else {
            cicloInyectora = "";
            tonelaje = "";
            unidadInyeccion = "";
            numMaquina = "";
          }
        }

        //SI ES HORNO
        if (maquina.tipo_maquina == 5) {
          if (tiempoRealHornos.length > 0) {
            cicloHorno = tiempoRealHornos[0].nCiclo;
            eurosHora = tiempoRealHornos[0].euros_hora;
            porcenEurosHora = tiempoRealHornos[0].porcentaje_euros_hora;
            piezasHora = tiempoRealHornos[0].piezas_hora;
            porcenPiezasHora = tiempoRealHornos[0].porcentaje_piezas_hora;
            tRestCalidad = tiempoRealHornos[0].tRestanteCalidad;
            temperatura = tiempoRealHornos[0].temperatura;
            kilowatio = tiempoRealHornos[0].kilowatio;
          } else {
            cicloHorno = "";
            eurosHora = "";
            porcenEurosHora = "";
            piezasHora = "";
            porcenPiezasHora = "";
            tRestCalidad = "";
            temperatura = false;
            kilowatio = false;
          }
        }

        //SI ES HORNO temple
        if (maquina.tipo_maquina == 12) {
          var temperaturaRealHornosTemple = [];
          if (this.tiempoRealHornosTemple.length > 0) {

            temperaturaProgramada = this.tiempoRealHornosTemple[0].temperaturaProgramada;
            temperaturaActual = this.tiempoRealHornosTemple[0].temperaturaActual;
            temperaturaInterior = this.tiempoRealHornosTemple[0].temperaturaInterior;
            consumo = this.tiempoRealHornosTemple[0].consumo;
            nivelO2 = this.tiempoRealHornosTemple[0].nivelO2;

            //temperaturaProgramada
            var min: any = maquina.temperaturaMin;
            var max: any = maquina.temperaturaMax;
            var valor: any = temperaturaProgramada;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturaRealHornosTemple.push({ valor: valor, nombre: this.translateService.instant("temperaturaProgr"), valorGrafico: valorGrafico });

            //temperaturaActual
            valor = temperaturaActual;
            valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturaRealHornosTemple.push({ valor: valor, nombre: this.translateService.instant("temperaturaAct"), valorGrafico: valorGrafico });

          }
          else {
            temperaturaProgramada = "";
            temperaturaActual = "";
            temperaturaInterior = "";
            consumo = "";
            nivelO2 = "";
          }
          maquina.temperaturasHornoTemple = temperaturaRealHornosTemple;
        }

        // var resultadoOee = this.myFunctions.calcularOee(dtOee);

        //ENCONTRAMOS LAS VALDOSAS QUE PERTENEZCAN A ESTA MAQUINA Y LE PONEMOS LOS DATOS
        this.valdosas.forEach(function (valdosa) {
          if (valdosa.idObjeto == idMaquina && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina != 8) {
            valdosa.datosPanel = [{
              idMaquina: idMaquina,
              nombreMaquina: nombreMaquina,
              tipo_maquina: tipo_maquina,
              operario: operario,
              idProcesosTipo: idProcesosTipo,
              procesosTipo: procesosTipo,
              procesosTipoStr: procesosTipoStr,
              porcentaje: porcentaje,
              estadoimg: estadoimg,
              fechaServidor: fechaServidor,
              tiempopasado: tiempopasado,
              tiempoEstEjec: tiempoEstEjec,
              tiempoEstPrep: tiempoEstPrep,
              tiempoPred: tiempoPred,
              tiempoAcumuladoOperacion: tiempoAcumuladoOperacion,
              incidencia: incidencia,
              refOF: refOF,
              cliente: cliente,
              pieza: pieza,
              parte: parte,
              operacion: operacion,
              numeroSerie: numeroSerie,
              realizadas: realizadas,
              //MECANIZADO
              ejes: ejes,
              //EXTRUSORA
              rendSopladores: rendSopladores,
              controlEsp: controlEsp,
              temperaturasExtrusora: temperaturasExtrusora,
              //IMPRESORA DE PLASTICO
              speed: speed,
              secTempBCD: secTempBCD,
              secTunelTempBCD: secTunelTempBCD,
              presionesImpresora: presionesImpresora,
              //INYECTORA
              cicloInyectora: cicloInyectora,
              tonelaje: tonelaje,
              unidadInyeccion: unidadInyeccion,
              numMaquina: numMaquina,
              //HORNO
              cicloHorno: cicloHorno,
              eurosHora: eurosHora,
              porcenEurosHora: porcenEurosHora,
              piezasHora: piezasHora,
              porcenPiezasHora: porcenPiezasHora,
              tRestCalidad: tRestCalidad,
              temperatura: temperatura,
              kilowatio: kilowatio,
              //HORNO temple
              temperaturaProgramada: temperaturaProgramada,
              temperaturaActual: temperaturaActual,
              temperaturaInterior: temperaturaInterior,
              consumo: consumo,
              nivelO2: nivelO2,
              temperaturasHornoTemple: maquina.temperaturasHornoTemple,
              resumenSemana: resumenSemana,
              cargandoOEE: true,
              oee: 0,
              disponibilidad: 0,
              rendimiento: 0,
              calidad: 0
            }];

            //Si esta seleccionada actualizamos el panel (solo la primera coincidencia y si no es una instalacion (idTipoMaquina!=8))
            var actualizado = false;
            if (valdosa.tileSeleccionado && valdosa.esObjeto && valdosa.idTipoObjeto == 1 && !actualizado && valdosa.idTipoMaquina != 8) {
              actualizado = true;
              this.mostrarDatosMaquinaPanel(valdosa);
            }

          }
        }, this);
        //ENCONTRAMOS LAS VALDOSAS DE SEGUNDO PISO QUE PERTENEZCAN A ESTA MAQUINA Y LE PONEMOS LOS DATOS
        this.valdosasExtra.forEach(function (valdosa) {
          if (valdosa.idObjeto == idMaquina && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina != 8) {
            valdosa.datosPanel = [{
              idMaquina: idMaquina,
              nombreMaquina: nombreMaquina,
              tipo_maquina: tipo_maquina,
              operario: operario,
              idProcesosTipo: idProcesosTipo,
              procesosTipo: procesosTipo,
              procesosTipoStr: procesosTipoStr,
              porcentaje: porcentaje,
              estadoimg: estadoimg,
              fechaServidor: fechaServidor,
              tiempopasado: tiempopasado,
              tiempoEstEjec: tiempoEstEjec,
              tiempoEstPrep: tiempoEstPrep,
              tiempoPred: tiempoPred,
              tiempoAcumuladoOperacion: tiempoAcumuladoOperacion,
              incidencia: incidencia,
              refOF: refOF,
              cliente: cliente,
              pieza: pieza,
              parte: parte,
              operacion: operacion,
              numeroSerie: numeroSerie,
              realizadas: realizadas,
              //MECANIZADO
              ejes: ejes,
              //EXTRUSORA
              rendSopladores: rendSopladores,
              controlEsp: controlEsp,
              temperaturasExtrusora: temperaturasExtrusora,
              //IMPRESORA DE PLASTICO
              speed: speed,
              secTempBCD: secTempBCD,
              secTunelTempBCD: secTunelTempBCD,
              presionesImpresora: presionesImpresora,
              //INYECTORA
              cicloInyectora: cicloInyectora,
              tonelaje: tonelaje,
              unidadInyeccion: unidadInyeccion,
              numMaquina: numMaquina,
              //HORNO
              cicloHorno: cicloHorno,
              eurosHora: eurosHora,
              porcenEurosHora: porcenEurosHora,
              piezasHora: piezasHora,
              porcenPiezasHora: porcenPiezasHora,
              tRestCalidad: tRestCalidad,
              temperatura: temperatura,
              kilowatio: kilowatio,
              //HORNO temple
              temperaturaProgramada: temperaturaProgramada,
              temperaturaActual: temperaturaActual,
              temperaturaInterior: temperaturaInterior,
              consumo: consumo,
              nivelO2: nivelO2,
              temperaturasHornoTemple: maquina.temperaturasHornoTemple,

              resumenSemana: resumenSemana,
              cargandoOEE: true,
              oee: 0,
              disponibilidad: 0,
              rendimiento: 0,
              calidad: 0
            }];
          }
        }, this);

      })
      this.primeraCarga = true;
      this.cargarDatosMaquinas_OEE();
    });

  }

  cargarDatosMaquinas_OEE() {
    if (!this.esperadnoOEE) {     
        this.esperadnoOEE = true;
        this.centroMecanizadoService.get_solo_OEE().subscribe(
        (dtOee) => {
          this.dataMaquinas.forEach( (maquina) => {
              this.oee = dtOee.filter((element) => element.idMaquina == maquina.id); //ARATZ: como este calculo realentizaba la primera carga de la pagina, y meterla en el 2º cicli era esperar demasiado, se hace al terminar la consulta inicial, y cuando el ciclo buelve a 0.
              this.tratarDatosMaquina_OEE(maquina);
          });
          this.esperadnoOEE = false;
          setTimeout((d) => this.cargarDatosMaquinas_OEE(), 10 * 60 * 1000); 
        });
    }
  }
  tratarDatosMaquina_OEE(maquina) {
    var resultadoOee = this.myFunctions.calcularOee(this.oee);

    //ENCONTRAMOS LAS VALDOSAS DE SEGUNDO PISO QUE PERTENEZCAN A ESTA MAQUINA Y LE PONEMOS LOS DATOS
    this.valdosas.forEach(
      (valdosa) => {
        if (valdosa.idObjeto == maquina.id && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina != 8 && valdosa.datosPanel != undefined) {            
            valdosa.datosPanel[0].cargandoOEE = false;        
            valdosa.datosPanel[0].oee = resultadoOee[0];
            valdosa.datosPanel[0].disponibilidad = resultadoOee[1];
            valdosa.datosPanel[0].rendimiento = resultadoOee[2];
            valdosa.datosPanel[0].calidad = resultadoOee[3];
        }
      });
  }


  cargarDatosInstalaciones() {

    this.instalacionesService.Get_instalaciones_temporal().subscribe((result) => {

      this.primeraCargaCompletada2 = true;

      var instalaciones_campos: any = result;
      var instalaciones = [];

      //AGRUPAMOS POR INSTALACION
      instalaciones_campos.forEach(function (a) {
        if (!this[a.id]) {
          this[a.id] = {
            id: a.id, nombre: a.nombre, imagen: a.imagen, tieneHistorico_Procesos: a.tieneHistorico_Procesos, idProcesos_Tipo: a.idProcesos_Tipo, campos: []
          };
          instalaciones.push(this[a.id]);
        }
      }, Object.create(null));

      //LE METEMOS A CADA INSTALACION SUS CAMPOS
      instalaciones_campos.forEach(function (row) {
        var instalacion = instalaciones.find(x => x.id === row.id);
        instalacion.campos.push({
          idReferencia: row.idReferencia,
          nombre: row.nombreCampo,
          minimo: row.minimo,
          maximo: row.maximo,
          unidad: row.unidad,
          valor: row.valor,
          valoresHistoricos: row.valoresHistoricos,
          fechas: row.fechas
        });
      });

      if (instalaciones.length > 0) this.instalacionMomentanea = instalaciones[0];

      var th = this;

      //ENCONTRAMOS LAS VALDOSAS QUE PERTENEZCAN A CADA INSTALACION Y LE PONEMOS LOS DATOS
      instalaciones.forEach(function (row) {
        th.valdosas.forEach(function (valdosa) {
          if (valdosa.idObjeto == row.id && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina == 8) {
            valdosa.instalacion = row;

            //DETERMINAMOS SI LA INSTALACION ES UN SILO
            if (valdosa.src == "silos/silo01-00.png") valdosa.esSilo = true;
            else valdosa.esSilo = false;

            //SI ES SILO LE PONEMOS LA IMAGEN DE SILO CON EL PORCENTAJE CORRESPONDIENTE (DEL PRIMER CAMPO)
            var campoNivel = valdosa.instalacion.campos[0];
            if (campoNivel != undefined && valdosa.esSilo) {
              var min = campoNivel.minimo;
              var max = campoNivel.maximo;
              var valor = campoNivel.valor;
              if (valor >= min && valor <= (min + (max - min) * 0.05)) valdosa.src = "silos/silo01-00.png";
              else if (valor > (min + (max - min) * 0.05) && valor <= (min + (max - min) * 0.33)) valdosa.src = "silos/silo01-01.png";
              else if (valor > (min + (max - min) * 0.33) && valor <= (min + (max - min) * 0.7)) valdosa.src = "silos/silo01-02.png";
              else valdosa.src = "silos/silo01-03.png";
            }

            //Si esta seleccionada actualizamos el panel (solo la primera coincidencia)
            var actualizado = false;
            if (valdosa.tileSeleccionado && valdosa.esObjeto && valdosa.idTipoObjeto == 1 && !actualizado && valdosa.idTipoMaquina == 8) {
              actualizado = true;
              th.mostrarDatosInstalacionPanel(valdosa);
            }

            //prueba kuito
            var procesosTipo = "";
            if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 1) procesosTipo = "ejecucion";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 2) procesosTipo = "parada";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 3) procesosTipo = "preparacion";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 4) procesosTipo = "mantenimiento";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 6) procesosTipo = "alarma";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 8) procesosTipo = "apagada";
            else procesosTipo = "ejecucion";

            if (row.tieneHistorico_Procesos) {
              valdosa.datosPanel = [{ procesosTipo: procesosTipo }];
            }

          }
        });
      });

    });

  }

  refreshData() {

    this.ciclo++;
    if (this.ciclo == 60) this.ciclo = 0; //5 minutos

    if (this.router.url.includes('/homeplantaisometrico/')) {
      if (this.primeraCargaCompletada1 && this.primeraCargaCompletada2) {
        if (!this.datosPendientesMecanizado) {
          this.datosPendientesMecanizado = true;
          this.refreshDataMecanizado();
        }
        if (!this.datosPendientesInstalaciones) {
          this.datosPendientesInstalaciones = true;
          this.refreshDataInstalaciones();
        }
      }
    } else {
      clearInterval(this.interval);
    }

  }

  refreshDataMecanizado() {

    this.centroMecanizadoService.getIsometrico(this.ciclo).subscribe((result) => {

      this.datosPendientesMecanizado = false;

      var dataMaquinas: any = result.maquinas;

      dataMaquinas.forEach((maquina) => {

        var ejecuciones = result.ejecuciones.filter((element) => (element.idMaquina == maquina.id && element.id == maquina.idHistoricoProcTemp));
        //var canales = result.canales.filter((element) => element.idMaquina == maquina.id);
        var dtEjes = result.ejes.filter((element) => element.iDmaquina == maquina.id);
        var tiempoReal = result.tiempoReal.filter((element) => element.idmaquina == maquina.id);
        var tiempoRealExtrusoras = result.tiempoRealExtrusoras.filter((element) => element.idMaquina == maquina.id);
        var tiempoRealImpresoras = result.tiempoRealImpresoras.filter((element) => element.idMaquina == maquina.id);
        var tiempoRealInyectoras = result.tiempoRealInyectoras.filter((element) => element.idMaquina == maquina.id);
        var tiempoRealHornos = [];//var tiempoRealHornos = result.tiempoRealHornos.filter((element) => element.idMaquina == maquina.id);
        var tiempoPorSemana = result.tiempoPorSemana.filter((element) => element.idMaquina == maquina.id);
        // var dtOee = result.oee.filter((element) => element.idMaquina == maquina.id);

        var idMaquina;
        var nombreMaquina;
        var tipo_maquina;
        var operario;
        var idProcesosTipo;
        var procesosTipo;
        var procesosTipoStr;

        var porcentaje;
        var estadoimg;
        var fechaServidor;
        var tiempopasado;
        var tiempoEstEjec;
        var tiempoEstPrep;
        var tiempoPred;
        var tiempoAcumuladoOperacion;

        var incidencia;

        var refOF;
        var cliente;
        var pieza;
        var parte;
        var operacion;
        var numeroSerie;
        var realizadas;

        var ejes;

        var rendSopladores;
        var controlEsp;
        var temperaturasExtrusora;

        var speed;
        var secTempBCD;
        var secTunelTempBCD;
        var presionesImpresora;

        var cicloInyectora;
        var tonelaje;
        var unidadInyeccion;
        var numMaquina;

        var cicloHorno;
        var eurosHora;
        var porcenEurosHora;
        var piezasHora;
        var porcenPiezasHora;
        var tRestCalidad;
        var temperatura;
        var kilowatio;

        var temperaturaProgramada;
        var temperaturaActual;
        var temperaturaInterior;
        var consumo;
        var nivelO2;

        var resumenSemana = [];

        if (ejecuciones != undefined && ejecuciones.length > 0) {

          idMaquina = ejecuciones[0].idMaquina;
          nombreMaquina = ejecuciones[0].nombre;
          tipo_maquina = maquina.tipo_maquina;
          operario = ejecuciones[0].operario;
          if (operario == "" || operario == " ") operario = this.translateService.instant('sinOperario');
          idProcesosTipo = ejecuciones[0].idProcesos_Tipo;
          procesosTipo = ejecuciones[0].procesos_Tipo;
          if (procesosTipo != null) procesosTipoStr = this.translateService.instant(procesosTipo);
          else procesosTipoStr = "";

          if (ejecuciones[0].refOF == undefined || ejecuciones[0].refOF == "") {
            refOF = this.translateService.instant("noproceso");
            cliente = "";
            pieza = "";
            parte = "";
            operacion = "";
            numeroSerie = "";
            realizadas = "";
          } else {
            refOF = ejecuciones[0].refOF;
            cliente = ejecuciones[0].cliente;
            pieza = ejecuciones[0].pieza;
            parte = ejecuciones[0].parte;
            operacion = ejecuciones[0].operacion;
            numeroSerie = ejecuciones[0].numeroSerie;
            realizadas = ejecuciones[0].hechas + "/" + ejecuciones[0].cantidad;
          }

          fechaServidor = ejecuciones[0].fechaServidor;
          tiempopasado = ejecuciones[0].fechainicio;
          tiempoEstEjec = this.myFunctions.secondsTo_HH_MM_SS(ejecuciones[0].tiempoEstimadoSegundos);
          tiempoEstPrep = this.myFunctions.secondsTo_HH_MM_SS(ejecuciones[0].tiempoPreparacionSegundos);
          tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(ejecuciones[0].tiempoPredSegundos);
          tiempoAcumuladoOperacion = ejecuciones[0].tiempoAcumuladoOperacion;

          this.CargarIconosEstadoMaquina(maquina, ejecuciones);

          porcentaje = maquina.porcentaje;
          estadoimg = maquina.estadoimg;

          //INCIDENCIAQUI
          if (procesosTipo != null) {
            if (procesosTipo.toLowerCase() == 'alarma') incidencia = ejecuciones[0].descripcion;
            if (procesosTipo.toLowerCase() == 'parada') incidencia = ejecuciones[0].parada;
            if (procesosTipo.toLowerCase() == 'mantenimiento') incidencia = ejecuciones[0].mantenimiento;
          }

        } else {

          idMaquina = -1;
          nombreMaquina = "";
          tipo_maquina = -1;
          operario = "";
          idProcesosTipo = -1;
          procesosTipo = "";
          procesosTipoStr = "";

          refOF = this.translateService.instant("noproceso");
          cliente = "";
          pieza = "";
          parte = "";
          operacion = "";
          numeroSerie = "";
          realizadas = "";

          fechaServidor = "00:00:00";
          tiempopasado = "00:00:00";
          tiempoEstEjec = "00:00:00";
          tiempoEstPrep = "00:00:00";
          tiempoPred = "00:00:00";
          tiempoAcumuladoOperacion = 0;

          incidencia = "";

          porcentaje = "0%";
          estadoimg = "assets/dcg/dcg0-apagada.png";

        }

        if (tiempoPorSemana != undefined && tiempoPorSemana.length > 0) {
          resumenSemana = [
            [this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
            [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
            [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
            [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
            [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
            [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
            [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]
          ];
        } else {
          //resumenSemana = [
          //  [this.translateService.instant("ejecucion"), 0],
          //  [this.translateService.instant("parada"), 0],
          //  [this.translateService.instant("microparada"), 0],
          //  [this.translateService.instant("preparacion"), 0],
          //  [this.translateService.instant("mantenimiento"), 0],
          //  [this.translateService.instant("alarma"), 0],
          //  [this.translateService.instant("apagada"), 0]
          //];
        }

        if (tiempoReal != undefined && tiempoReal.length > 1 && dtEjes != undefined) {

          //EJE 1
          var minEje1 = dtEjes[0].feedMinimo;
          var maxEje1 = dtEjes[0].feedMaximo;
          var nombreEje1 = dtEjes[0].nombre;

          var tempAlarmEje1 = dtEjes[0].tempAlarm;
          var tempMaxEje1 = dtEjes[0].tempMax;
          var consumAlarmEje1 = dtEjes[0].consumAlarm;
          var consumMaxEje1 = dtEjes[0].consumMax;

          var eje1 = tiempoReal[0].rpm;
          var valorGraficoEje1 = this.calcularPorcentaje(eje1 - minEje1, 48, maxEje1 - minEje1, 0, true);
          var tempEje1 = tiempoReal[0].temp;
          var consumEje1 = tiempoReal[0].consumo;

          var alertaTemperaturaEje1;
          var logoalarmatempEje1;
          var alertaConsumoEje1;
          var logoalarmaconsumEje1;

          if (tempEje1 > tempMaxEje1 && tempMaxEje1 != -1) {
            alertaTemperaturaEje1 = true;
            logoalarmatempEje1 = "assets/tempRojo.png";
          } else if (tempEje1 > tempAlarmEje1 && tempAlarmEje1 != -1) {
            alertaTemperaturaEje1 = true;
            logoalarmatempEje1 = "assets/tempAmarillo.png";
          } else {
            alertaTemperaturaEje1 = false;
            logoalarmatempEje1 = "";
          }

          if (consumEje1 > consumMaxEje1 && consumMaxEje1 != -1) {
            alertaConsumoEje1 = true;
            logoalarmaconsumEje1 = "assets/warningRojo.png";
          } else if (consumEje1 > consumAlarmEje1 && consumAlarmEje1 != -1) {
            alertaConsumoEje1 = true;
            logoalarmaconsumEje1 = "assets/warningAmarillo.png";
          } else {
            alertaConsumoEje1 = false;
            logoalarmaconsumEje1 = "";
          }

          //EJE 2
          var minEje2 = dtEjes[1].feedMinimo;
          var maxEje2 = dtEjes[1].feedMaximo;
          var nombreEje2 = dtEjes[1].nombre;

          var tempAlarmEje2 = dtEjes[1].tempAlarm;
          var tempMaxEje2 = dtEjes[1].tempMax;
          var consumAlarmEje2 = dtEjes[1].consumAlarm;
          var consumMaxEje2 = dtEjes[1].consumMax;

          var eje2 = tiempoReal[1].rpm;
          var valorGraficoEje2 = this.calcularPorcentaje(eje2 - minEje2, 48, maxEje2 - minEje2, 0, true);
          var tempEje2 = tiempoReal[1].temp;
          var consumEje2 = tiempoReal[1].consumo;

          var alertaTemperaturaEje2;
          var logoalarmatempEje2;
          var alertaConsumoEje2;
          var logoalarmaconsumEje2;

          if (tempEje2 > tempMaxEje2 && tempMaxEje2 != -1) {
            alertaTemperaturaEje2 = true;
            logoalarmatempEje2 = "assets/tempRojo.png";
          } else if (tempEje2 > tempAlarmEje2 && tempAlarmEje2 != -1) {
            alertaTemperaturaEje2 = true;
            logoalarmatempEje2 = "assets/tempAmarillo.png";
          } else {
            alertaTemperaturaEje2 = false;
            logoalarmatempEje2 = "";
          }

          if (consumEje2 > consumMaxEje2 && consumMaxEje2 != -1) {
            alertaConsumoEje2 = true;
            logoalarmaconsumEje2 = "assets/warningRojo.png";
          } else if (consumEje2 > consumAlarmEje2 && consumAlarmEje2 != -1) {
            alertaConsumoEje2 = true;
            logoalarmaconsumEje2 = "assets/warningAmarillo.png";
          } else {
            alertaConsumoEje2 = false;
            logoalarmaconsumEje2 = "";
          }

          //POTEN 1
          var minPotenciometro = dtEjes[0].potenMin;
          var maxPotenciometro = dtEjes[0].potenMax;

          var poten1 = tiempoReal[0].potenciometro;
          var valorGraficoPoten1 = this.calcularPorcentaje(poten1 - minPotenciometro, 48, maxPotenciometro - minPotenciometro, 0, true);

          //POTEN 2
          var poten2 = tiempoReal[0].potenciometro2;
          var valorGraficoPoten2 = this.calcularPorcentaje(poten2 - minPotenciometro, 48, maxPotenciometro - minPotenciometro, 0, true);

          ejes = [
            { alertaTemperatura: alertaTemperaturaEje1, logoalarmatemp: logoalarmatempEje1, alertaConsumo: alertaConsumoEje1, logoalarmaconsum: logoalarmaconsumEje1, valor: eje1.toFixed(1), nombre: nombreEje1, valorGrafico: valorGraficoEje1 },
            { alertaTemperatura: alertaTemperaturaEje2, logoalarmatemp: logoalarmatempEje2, alertaConsumo: alertaConsumoEje2, logoalarmaconsum: logoalarmaconsumEje2, valor: eje2.toFixed(1), nombre: nombreEje2, valorGrafico: valorGraficoEje2 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: poten1.toFixed(1), nombre: this.translateService.instant("potencabezal"), valorGrafico: valorGraficoPoten1 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: poten2.toFixed(1), nombre: this.translateService.instant("potenavances"), valorGrafico: valorGraficoPoten2 },
          ];

        } else {

          ejes = [
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 1", valorGrafico: 0 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 2", valorGrafico: 0 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potencabezal"), valorGrafico: 0 },
            { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potenavances"), valorGrafico: 0 },
          ];

        }

        //SI ES EXTRUSORA CALCULAR TEMPERATURAS
        if (maquina.tipo_maquina == 2) {
          if (tiempoRealExtrusoras.length > 0) {

            temperaturasExtrusora = [];

            //infoControlEspesores
            controlEsp = tiempoRealExtrusoras[0].infoControlEspesores;

            //rendimientoSopladores
            rendSopladores = tiempoRealExtrusoras[0].rendimientoSopladores;

            //tempAnilloEntrada
            var min = tiempoRealExtrusoras[0].tempAnilloEntrada_min;
            var max = tiempoRealExtrusoras[0].tempAnilloEntrada_max;
            var valor = tiempoRealExtrusoras[0].tempAnilloEntrada;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tAnill"), valorGrafico: valorGrafico });

            //tempIBCDeEntrada
            var min = tiempoRealExtrusoras[0].tempIBCDeEntrada_min;
            var max = tiempoRealExtrusoras[0].tempIBCDeEntrada_max;
            var valor = tiempoRealExtrusoras[0].tempIBCDeEntrada;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tIBC"), valorGrafico: valorGrafico });

            //tempRodilloCalandra
            var min = tiempoRealExtrusoras[0].tempRodilloCalandra_min;
            var max = tiempoRealExtrusoras[0].tempRodilloCalandra_max;
            var valor = tiempoRealExtrusoras[0].tempRodilloCalandra;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tRodCal"), valorGrafico: valorGrafico });

            //tempRodilloHalador
            var min = tiempoRealExtrusoras[0].tempRodilloHalador_min;
            var max = tiempoRealExtrusoras[0].tempRodilloHalador_max;
            var valor = tiempoRealExtrusoras[0].tempRodilloHalador;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturasExtrusora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tRodHal"), valorGrafico: valorGrafico });

          } else {

            rendSopladores = 0;
            controlEsp = 0;
            temperaturasExtrusora = [
              { valor: 0, nombre: this.translateService.instant("tAnill"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tIBC"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tRodCal"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tRodHal"), valorGrafico: 0 },
            ];

          }
        }

        //SI ES IMPRESORA DE PLASTICO CALCULAR PRESIONES
        if (maquina.tipo_maquina == 3) {
          if (tiempoRealImpresoras.length > 0) {

            presionesImpresora = [];

            //speed
            speed = tiempoRealImpresoras[0].speed;

            //secadorTempBCD
            secTempBCD = tiempoRealImpresoras[0].secadorTempBCD;

            //secadorTunelTempBCD
            secTunelTempBCD = tiempoRealImpresoras[0].secadorTunelTempBCD;

            //desbobinadora
            var min = tiempoRealImpresoras[0].desbobinadora_min;
            var max = tiempoRealImpresoras[0].desbobinadora_max;
            var valor = tiempoRealImpresoras[0].desbobinadora;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("desb"), valorGrafico: valorGrafico });

            //tomaDeAlimentacion
            var min = tiempoRealImpresoras[0].tomaDeAlimentacion_min;
            var max = tiempoRealImpresoras[0].tomaDeAlimentacion_max;
            var valor = tiempoRealImpresoras[0].tomaDeAlimentacion;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("tAlim"), valorGrafico: valorGrafico });

            //accionadorRodilloEnfriamiento
            var min = tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_min;
            var max = tiempoRealImpresoras[0].accionadorRodilloEnfriamiento_max;
            var valor = tiempoRealImpresoras[0].accionadorRodilloEnfriamiento;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("rodEnf"), valorGrafico: valorGrafico });

            //bobinadora
            var min = tiempoRealImpresoras[0].bobinadora_min;
            var max = tiempoRealImpresoras[0].bobinadora_max;
            var valor = tiempoRealImpresoras[0].bobinadora;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            presionesImpresora.push({ valor: valor.toFixed(1), nombre: this.translateService.instant("bobi"), valorGrafico: valorGrafico });

          } else {
            speed = 0;
            secTempBCD = 0;
            secTunelTempBCD = 0;
            presionesImpresora = [
              { valor: 0, nombre: this.translateService.instant("desb"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("tAlim"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("rodEnf"), valorGrafico: 0 },
              { valor: 0, nombre: this.translateService.instant("bobi"), valorGrafico: 0 },
            ];

          }
        }

        //SI ES INYECTORA
        if (maquina.tipo_maquina == 4) {
          if (tiempoRealInyectoras.length > 0) {
            cicloInyectora = tiempoRealInyectoras[0].nCiclo;
            tonelaje = maquina.tonelaje;
            unidadInyeccion = maquina.unidadInyeccion;
            numMaquina = maquina.numMaquina;
          } else {
            cicloInyectora = "";
            tonelaje = "";
            unidadInyeccion = "";
            numMaquina = "";
          }
        }

        //SI ES HORNO
        if (maquina.tipo_maquina == 5) {
          if (tiempoRealHornos.length > 0) {
            cicloHorno = tiempoRealHornos[0].nCiclo;
            eurosHora = tiempoRealHornos[0].euros_hora;
            porcenEurosHora = tiempoRealHornos[0].porcentaje_euros_hora;
            piezasHora = tiempoRealHornos[0].piezas_hora;
            porcenPiezasHora = tiempoRealHornos[0].porcentaje_piezas_hora;
            tRestCalidad = tiempoRealHornos[0].tRestanteCalidad;
            temperatura = tiempoRealHornos[0].temperatura;
            kilowatio = tiempoRealHornos[0].kilowatio;
          } else {
            cicloHorno = "";
            eurosHora = "";
            porcenEurosHora = "";
            piezasHora = "";
            porcenPiezasHora = "";
            tRestCalidad = "";
            temperatura = false;
            kilowatio = false;
          }
        }
        //SI ES HORNO temple
        if (maquina.tipo_maquina == 12) {
          var temperaturaRealHornosTemple = [];
          if (this.tiempoRealHornosTemple.length > 0) {

            temperaturaProgramada = this.tiempoRealHornosTemple[0].temperaturaProgramada;
            temperaturaActual = this.tiempoRealHornosTemple[0].temperaturaActual;
            temperaturaInterior = this.tiempoRealHornosTemple[0].temperaturaInterior;
            consumo = this.tiempoRealHornosTemple[0].consumo;
            nivelO2 = this.tiempoRealHornosTemple[0].nivelO2;

            //temperaturaProgramada
            var min: any = maquina.temperaturaMin;
            var max: any = maquina.temperaturaMax;
            var valor: any = this.temperaturaProgramada;
            var valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturaRealHornosTemple.push({ valor: valor, nombre: this.translateService.instant("temperaturaProgr"), valorGrafico: valorGrafico });

            //temperaturaActual
            valor = temperaturaActual;
            valorGrafico = this.calcularPorcentaje(valor - min, 48, max - min, 0, true);
            temperaturaRealHornosTemple.push({ valor: valor, nombre: this.translateService.instant("temperaturaAct"), valorGrafico: valorGrafico });

          }
          else {
            temperaturaProgramada = "";
            temperaturaActual = "";
            temperaturaInterior = "";
            consumo = "";
            nivelO2 = "";
          }
          maquina.temperaturasHornoTemple = temperaturaRealHornosTemple;
        }

        // var resultadoOee = this.myFunctions.calcularOee(dtOee);

        //ENCONTRAMOS LAS VALDOSAS QUE PERTENEZCAN A ESTA MAQUINA Y LE PONEMOS LOS DATOS
        this.valdosas.forEach(function (valdosa) {
          if (valdosa.idObjeto == idMaquina && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina != 8) {

            valdosa.datosPanel[0].idMaquina = idMaquina;
            valdosa.datosPanel[0].nombreMaquina = nombreMaquina;
            valdosa.datosPanel[0].tipo_maquina = tipo_maquina;
            valdosa.datosPanel[0].operario = operario;
            valdosa.datosPanel[0].idProcesosTipo = idProcesosTipo;
            valdosa.datosPanel[0].procesosTipo = procesosTipo;
            valdosa.datosPanel[0].procesosTipoStr = procesosTipoStr;
            valdosa.datosPanel[0].porcentaje = porcentaje;
            valdosa.datosPanel[0].estadoimg = estadoimg;
            valdosa.datosPanel[0].fechaServidor = fechaServidor;
            valdosa.datosPanel[0].tiempopasado = tiempopasado;
            valdosa.datosPanel[0].tiempoEstEjec = tiempoEstEjec;
            valdosa.datosPanel[0].tiempoEstPrep = tiempoEstPrep;
            valdosa.datosPanel[0].tiempoPred = tiempoPred;
            valdosa.datosPanel[0].tiempoAcumuladoOperacion = tiempoAcumuladoOperacion;
            valdosa.datosPanel[0].incidencia = incidencia;
            valdosa.datosPanel[0].refOF = refOF;
            valdosa.datosPanel[0].cliente = cliente;
            valdosa.datosPanel[0].pieza = pieza;
            valdosa.datosPanel[0].parte = parte;
            valdosa.datosPanel[0].operacion = operacion;
            valdosa.datosPanel[0].numeroSerie = numeroSerie;
            valdosa.datosPanel[0].realizadas = realizadas;

            //MECANIZADO
            valdosa.datosPanel[0].ejes = ejes;
            //EXTRUSORA
            valdosa.datosPanel[0].rendSopladores = rendSopladores;
            valdosa.datosPanel[0].controlEsp = controlEsp;
            valdosa.datosPanel[0].temperaturasExtrusora = temperaturasExtrusora;
            //IMPRESORA DE PLASTICO
            valdosa.datosPanel[0].speed = speed;
            valdosa.datosPanel[0].secTempBCD = secTempBCD;
            valdosa.datosPanel[0].secTunelTempBCD = secTunelTempBCD;
            valdosa.datosPanel[0].presionesImpresora = presionesImpresora;
            //INYECTORA
            valdosa.datosPanel[0].cicloInyectora = cicloInyectora;
            valdosa.datosPanel[0].tonelaje = tonelaje;
            valdosa.datosPanel[0].unidadInyeccion = unidadInyeccion;
            valdosa.datosPanel[0].numMaquina = numMaquina;
            //HORNO
            valdosa.datosPanel[0].cicloHorno = cicloHorno;
            valdosa.datosPanel[0].eurosHora = eurosHora;
            valdosa.datosPanel[0].porcenEurosHora = porcenEurosHora;
            valdosa.datosPanel[0].piezasHora = piezasHora;
            valdosa.datosPanel[0].porcenPiezasHora = porcenPiezasHora;
            valdosa.datosPanel[0].tRestCalidad = tRestCalidad;
            valdosa.datosPanel[0].temperatura = temperatura;
            valdosa.datosPanel[0].kilowatio = kilowatio;
            //HORNO temple
            valdosa.datosPanel[0].temperaturaProgramada = temperaturaProgramada;
            valdosa.datosPanel[0].temperaturaActual = temperaturaActual;
            valdosa.datosPanel[0].temperaturaInterior = temperaturaInterior;
            valdosa.datosPanel[0].consumo = consumo;
            valdosa.datosPanel[0].nivelO2 = nivelO2;

            if (resumenSemana.length > 0) valdosa.datosPanel[0].resumenSemana = resumenSemana;
            // if (JSON.stringify(resultadoOee) != JSON.stringify([0, 0, 0, 0])) {
            //   valdosa.datosPanel[0].oee = resultadoOee[0];
            //   valdosa.datosPanel[0].disponibilidad = resultadoOee[1];
            //   valdosa.datosPanel[0].rendimiento = resultadoOee[2];
            //   valdosa.datosPanel[0].calidad = resultadoOee[3];
            // }

            //Si esta seleccionada actualizamos el panel (solo la primera coincidencia y si no es una instalacion (idTipoMaquina!=8))
            var actualizado = false;
            if (valdosa.tileSeleccionado && valdosa.esObjeto && valdosa.idTipoObjeto == 1 && !actualizado && valdosa.idTipoMaquina != 8) {
              actualizado = true;
              this.mostrarDatosMaquinaPanel(valdosa);
            }

          }
        }, this);

        //ENCONTRAMOS LAS VALDOSAS DE SEGUNDO PISO QUE PERTENEZCAN A ESTA MAQUINA Y LE PONEMOS LOS DATOS
        this.valdosasExtra.forEach(function (valdosa) {
          if (valdosa.idObjeto == idMaquina && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina != 8) {

            valdosa.datosPanel[0].idMaquina = idMaquina;
            valdosa.datosPanel[0].nombreMaquina = nombreMaquina;
            valdosa.datosPanel[0].tipo_maquina = tipo_maquina;
            valdosa.datosPanel[0].operario = operario;
            valdosa.datosPanel[0].idProcesosTipo = idProcesosTipo;
            valdosa.datosPanel[0].procesosTipo = procesosTipo;
            valdosa.datosPanel[0].procesosTipoStr = procesosTipoStr;
            valdosa.datosPanel[0].porcentaje = porcentaje;
            valdosa.datosPanel[0].estadoimg = estadoimg;
            valdosa.datosPanel[0].fechaServidor = fechaServidor;
            valdosa.datosPanel[0].tiempopasado = tiempopasado;
            valdosa.datosPanel[0].tiempoEstEjec = tiempoEstEjec;
            valdosa.datosPanel[0].tiempoEstPrep = tiempoEstPrep;
            valdosa.datosPanel[0].tiempoPred = tiempoPred;
            valdosa.datosPanel[0].tiempoAcumuladoOperacion = tiempoAcumuladoOperacion;
            valdosa.datosPanel[0].incidencia = incidencia;
            valdosa.datosPanel[0].refOF = refOF;
            valdosa.datosPanel[0].cliente = cliente;
            valdosa.datosPanel[0].pieza = pieza;
            valdosa.datosPanel[0].parte = parte;
            valdosa.datosPanel[0].operacion = operacion;
            valdosa.datosPanel[0].numeroSerie = numeroSerie;
            valdosa.datosPanel[0].realizadas = realizadas;

            //MECANIZADO
            valdosa.datosPanel[0].ejes = ejes;
            //EXTRUSORA
            valdosa.datosPanel[0].rendSopladores = rendSopladores;
            valdosa.datosPanel[0].controlEsp = controlEsp;
            valdosa.datosPanel[0].temperaturasExtrusora = temperaturasExtrusora;
            //IMPRESORA DE PLASTICO
            valdosa.datosPanel[0].speed = speed;
            valdosa.datosPanel[0].secTempBCD = secTempBCD;
            valdosa.datosPanel[0].secTunelTempBCD = secTunelTempBCD;
            valdosa.datosPanel[0].presionesImpresora = presionesImpresora;
            //INYECTORA
            valdosa.datosPanel[0].cicloInyectora = cicloInyectora;
            valdosa.datosPanel[0].tonelaje = tonelaje;
            valdosa.datosPanel[0].unidadInyeccion = unidadInyeccion;
            valdosa.datosPanel[0].numMaquina = numMaquina;
            //HORNO
            valdosa.datosPanel[0].cicloHorno = cicloHorno;
            valdosa.datosPanel[0].eurosHora = eurosHora;
            valdosa.datosPanel[0].porcenEurosHora = porcenEurosHora;
            valdosa.datosPanel[0].piezasHora = piezasHora;
            valdosa.datosPanel[0].porcenPiezasHora = porcenPiezasHora;
            valdosa.datosPanel[0].tRestCalidad = tRestCalidad;
            valdosa.datosPanel[0].temperatura = temperatura;
            valdosa.datosPanel[0].kilowatio = kilowatio;
            //HORNO TEMPLE
            valdosa.datosPanel[0].temperaturaProgramada = temperaturaProgramada;
            valdosa.datosPanel[0].temperaturaActual = temperaturaActual;
            valdosa.datosPanel[0].temperaturaInterior = temperaturaInterior;
            valdosa.datosPanel[0].consumo = consumo;
            valdosa.datosPanel[0].nivelO2 = nivelO2;

            valdosa.datosPanel[0].resumenSemana = resumenSemana;
            // valdosa.datosPanel[0].oee = resultadoOee[0];
            // valdosa.datosPanel[0].disponibilidad = resultadoOee[1];
            // valdosa.datosPanel[0].rendimiento = resultadoOee[2];
            // valdosa.datosPanel[0].calidad = resultadoOee[3];
          }
        }, this);

      })
    });

  }

  refreshDataInstalaciones() {

    this.instalacionesService.Get_instalaciones_temporal().subscribe((result) => {

      this.datosPendientesInstalaciones = false;

      var instalaciones_campos: any = result;
      var instalaciones = [];

      //AGRUPAMOS POR INSTALACION
      instalaciones_campos.forEach(function (a) {
        if (!this[a.id]) {
          this[a.id] = {
            id: a.id, nombre: a.nombre, imagen: a.imagen, tieneHistorico_Procesos: a.tieneHistorico_Procesos, idProcesos_Tipo: a.idProcesos_Tipo, campos: []
          };
          instalaciones.push(this[a.id]);
        }
      }, Object.create(null));

      //LE METEMOS A CADA INSTALACION SUS CAMPOS
      instalaciones_campos.forEach(function (row) {
        var instalacion = instalaciones.find(x => x.id === row.id);
        instalacion.campos.push({
          idReferencia: row.idReferencia,
          nombre: row.nombreCampo,
          minimo: row.minimo,
          maximo: row.maximo,
          unidad: row.unidad,
          valor: row.valor,
          valoresHistoricos: row.valoresHistoricos,
          fechas: row.fechas
        });
      });

      if (instalaciones.length > 0) this.instalacionMomentanea = instalaciones[0];

      var th = this;

      //ENCONTRAMOS LAS VALDOSAS QUE PERTENEZCAN A CADA INSTALACION Y LE PONEMOS LOS DATOS
      instalaciones.forEach(function (row) {
        th.valdosas.forEach(function (valdosa) {
          if (valdosa.idObjeto == row.id && valdosa.idTipoObjeto == 1 && valdosa.idTipoMaquina == 8) {
            valdosa.instalacion = row;

            //SI ES SILO LE PONEMOS LA IMAGEN DE SILO CON EL PORCENTAJE CORRESPONDIENTE (DEL PRIMER CAMPO)
            var campoNivel = valdosa.instalacion.campos[0];
            if (campoNivel != undefined && valdosa.esSilo) {
              var min = campoNivel.minimo;
              var max = campoNivel.maximo;
              var valor = campoNivel.valor;
              if (valor >= min && valor <= (min + (max - min) * 0.05)) valdosa.src = "silos/silo01-00.png";
              else if (valor > (min + (max - min) * 0.05) && valor <= (min + (max - min) * 0.33)) valdosa.src = "silos/silo01-01.png";
              else if (valor > (min + (max - min) * 0.33) && valor <= (min + (max - min) * 0.7)) valdosa.src = "silos/silo01-02.png";
              else valdosa.src = "silos/silo01-03.png";
            }

            //Si esta seleccionada actualizamos el panel (solo la primera coincidencia)
            var actualizado = false;
            if (valdosa.tileSeleccionado && valdosa.esObjeto && valdosa.idTipoObjeto == 1 && !actualizado && valdosa.idTipoMaquina == 8) {
              actualizado = true;
              th.mostrarDatosInstalacionPanel(valdosa);
            }

            //prueba kuito
            var procesosTipo = "";
            if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 1) procesosTipo = "ejecucion";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 2) procesosTipo = "parada";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 3) procesosTipo = "preparacion";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 4) procesosTipo = "mantenimiento";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 6) procesosTipo = "alarma";
            else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 8) procesosTipo = "apagada";
            else procesosTipo = "ejecucion";

            if (row.tieneHistorico_Procesos) {
              valdosa.datosPanel = [{ procesosTipo: procesosTipo }];
            }

          }
        });
      });

    });

  }

  mostrarDatosMaquinaPanel(valdosa) {

    if (valdosa.esObjeto && valdosa.idTipoObjeto == 1 && valdosa.datosPanel != undefined) {

      var estabaDesplegado = this.menuDesplegadoMaquina;
      this.menuDesplegadoMaquina = true;

      var datosPanel = valdosa.datosPanel[0];

      this.idMaquina = datosPanel.idMaquina;
      this.nombreMaquina = datosPanel.nombreMaquina;
      this.tipo_maquina = datosPanel.tipo_maquina;
      this.operario = datosPanel.operario;
      this.idProcesosTipo = datosPanel.idProcesosTipo;
      this.procesosTipo = datosPanel.procesosTipo;
      this.procesosTipoStr = datosPanel.procesosTipoStr;
      this.porcentaje = datosPanel.porcentaje;
      this.estadoimg = datosPanel.estadoimg;

      this.tiempopasado = this.calcularTiempoPasado(new Date(Date.parse(datosPanel.fechaServidor)), new Date(Date.parse(datosPanel.tiempopasado)), datosPanel.tiempoAcumuladoOperacion);
      //Le sumamos un segundo a la hora
      var dateAux = new Date(Date.parse(datosPanel.fechaServidor));
      dateAux.setSeconds(dateAux.getSeconds() + 1);
      datosPanel.fechaServidor = dateAux

      this.incidencia = datosPanel.incidencia;

      clearInterval(this.intervalTiempoPasado);

      this.intervalTiempoPasado = setInterval(() => {
        this.recalcularTiempoPasado(datosPanel.fechaServidor, datosPanel.tiempopasado, datosPanel.tiempoAcumuladoOperacion);
        //Le sumamos un segundo a la hora
        var dateAux = new Date(Date.parse(datosPanel.fechaServidor));
        dateAux.setSeconds(dateAux.getSeconds() + 1);
        datosPanel.fechaServidor = dateAux
      }, 1000);

      this.tiempoEstEjec = datosPanel.tiempoEstEjec;
      this.tiempoEstPrep = datosPanel.tiempoEstPrep;
      this.tiempoPred = datosPanel.tiempoPred;
      this.tiempoAcumuladoOperacion = datosPanel.tiempoAcumuladoOperacion;
      this.refOF = datosPanel.refOF;
      this.cliente = datosPanel.cliente;
      this.pieza = datosPanel.pieza;
      this.parte = datosPanel.parte;
      this.operacion = datosPanel.operacion;
      this.numeroSerie = datosPanel.numeroSerie;
      this.realizadas = datosPanel.realizadas;

      //MECANIZADO
      for (var i = 0; i < this.ejes.length; i++) {
        this.ejes[i].alertaConsumo = datosPanel.ejes[i].alertaConsumo;
        this.ejes[i].alertaTemperatura = datosPanel.ejes[i].alertaTemperatura;
        this.ejes[i].logoalarmaconsum = datosPanel.ejes[i].logoalarmaconsum;
        this.ejes[i].logoalarmatemp = datosPanel.ejes[i].logoalarmatemp;
        this.ejes[i].nombre = datosPanel.ejes[i].nombre;
        this.ejes[i].valor = datosPanel.ejes[i].valor;
        this.ejes[i].valorGrafico = datosPanel.ejes[i].valorGrafico;
      }

      //EXTRUSORA
      if (this.tipo_maquina == 2) {
        this.rendSopladores = datosPanel.rendSopladores;
        this.controlEsp = datosPanel.controlEsp;
        for (var i = 0; i < this.temperaturasExtrusora.length; i++) {
          this.temperaturasExtrusora[i].nombre = datosPanel.temperaturasExtrusora[i].nombre;
          this.temperaturasExtrusora[i].valor = datosPanel.temperaturasExtrusora[i].valor;
          this.temperaturasExtrusora[i].valorGrafico = datosPanel.temperaturasExtrusora[i].valorGrafico;
        }
      }

      //IMPRESORAS DE PLASTICO
      if (this.tipo_maquina == 3) {
        this.speed = datosPanel.speed;
        this.secTempBCD = datosPanel.secTempBCD;
        this.secTunelTempBCD = datosPanel.secTunelTempBCD;
        for (var i = 0; i < this.presionesImpresora.length; i++) {
          this.presionesImpresora[i].nombre = datosPanel.presionesImpresora[i].nombre;
          this.presionesImpresora[i].valor = datosPanel.presionesImpresora[i].valor;
          this.presionesImpresora[i].valorGrafico = datosPanel.presionesImpresora[i].valorGrafico;
        }
      }

      //INYECTORAS
      if (this.tipo_maquina == 4) {
        this.cicloInyectora = datosPanel.cicloInyectora;
        this.tonelaje = datosPanel.tonelaje;
        this.unidadInyeccion = datosPanel.unidadInyeccion;
        this.numMaquina = datosPanel.numMaquina;
      }

      //HORNOS
      if (this.tipo_maquina == 5) {
        this.cicloHorno = datosPanel.cicloHorno;
        this.eurosHora = datosPanel.eurosHora;
        this.porcenEurosHora = datosPanel.porcenEurosHora;
        this.piezasHora = datosPanel.piezasHora;
        this.porcenPiezasHora = datosPanel.porcenPiezasHora;
        this.tRestCalidad = datosPanel.tRestCalidad;
        this.temperatura = datosPanel.temperatura;
        this.kilowatio = datosPanel.kilowatio;
      }

      //HORNOS TEMPLE
      if (this.tipo_maquina == 12) {
        this.temperaturaProgramada = datosPanel.temperaturaProgramada;
        this.temperaturaActual = datosPanel.temperaturaActual;
        this.consumo = datosPanel.consumo;
        this.nivelO2 = datosPanel.nivelO2;
        this.temperaturaInterior = datosPanel.temperaturaInterior;
        this.temperaturasHornoTemple = datosPanel.temperaturasHornoTemple; //todoooo
      }

      this.resumenSemana = datosPanel.resumenSemana;
      this.oee = datosPanel.oee;
      this.disponibilidad = datosPanel.disponibilidad;
      this.rendimiento = datosPanel.rendimiento;
      this.calidad = datosPanel.calidad;

      var th = this;

      d3.select('#graficoDonutOee_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.oee.toFixed(0) + "%");
      d3.select('#graficoDonutDisponibilidad_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.disponibilidad.toFixed(0) + "%");
      d3.select('#graficoDonutRendimiento_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.rendimiento.toFixed(0) + "%");
      d3.select('#graficoDonutCalidad_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.calidad.toFixed(0) + "%");

      if (estabaDesplegado) {

        th.graficoResumenSemana.load({ columns: th.resumenSemana });

        th.graficoDonutOee.load({ columns: [['completo', th.oee], ['nocompleto', 100 - th.oee]] });

        th.graficoDonutDisponibilidad.load({ columns: [['completo', th.disponibilidad], ['nocompleto', 100 - th.disponibilidad]] });

        th.graficoDonutRendimiento.load({ columns: [['completo', th.rendimiento], ['nocompleto', 100 - th.rendimiento]] });

        th.graficoDonutCalidad.load({ columns: [['completo', th.calidad], ['nocompleto', 100 - th.calidad]] });

      } else {

        setTimeout(function () {

          th.graficoResumenSemana.flush();
          th.graficoResumenSemana.load({ columns: th.resumenSemana });

          th.graficoDonutOee.flush();
          th.graficoDonutOee.load({ columns: [['completo', th.oee], ['nocompleto', 100 - th.oee]] });

          th.graficoDonutDisponibilidad.flush();
          th.graficoDonutDisponibilidad.load({ columns: [['completo', th.disponibilidad], ['nocompleto', 100 - th.disponibilidad]] });

          th.graficoDonutRendimiento.flush();
          th.graficoDonutRendimiento.load({ columns: [['completo', th.rendimiento], ['nocompleto', 100 - th.rendimiento]] });

          th.graficoDonutCalidad.flush();
          th.graficoDonutCalidad.load({ columns: [['completo', th.calidad], ['nocompleto', 100 - th.calidad]] });

        }, 500);

      }

    } else if (valdosa.esObjeto && valdosa.idTipoObjeto == 1) { //Si no tiene datosPanel

      var estabaDesplegado = this.menuDesplegadoMaquina;
      this.menuDesplegadoMaquina = true;

      this.idMaquina = -1;
      this.nombreMaquina = "-";
      this.tipo_maquina = -1;
      this.operario = "";

      this.idProcesosTipo = 0;
      this.procesosTipo = "";

      this.porcentaje = "0%";
      this.estadoimg = "assets/dcg/dcg0-apagada.png";
      this.tiempopasado = "00:00:00";
      this.tiempoEstEjec = "00:00:00";
      this.tiempoEstPrep = "00:00:00";
      this.tiempoPred = "00:00:00";
      this.tiempoAcumuladoOperacion = 0;

      this.incidencia = "";

      this.refOF = "";
      this.cliente = "";
      this.pieza = "";
      this.parte = "";
      this.operacion = "";
      this.numeroSerie = "";
      this.realizadas = "";

      this.ejes = [
        { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 1", valorGrafico: 0 },
        { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: "Eje 2", valorGrafico: 0 },
        { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potencabezal"), valorGrafico: 0 },
        { alertaTemperatura: false, logoalarmatemp: "", alertaConsumo: false, logoalarmaconsum: "", valor: 0, nombre: this.translateService.instant("potenavances"), valorGrafico: 0 },
      ];

      this.resumenSemana = [
        [this.translateService.instant("ejecucion"), 0],
        [this.translateService.instant("parada"), 0],
        [this.translateService.instant("microparada"), 0],
        [this.translateService.instant("preparacion"), 0],
        [this.translateService.instant("mantenimiento"), 0],
        [this.translateService.instant("alarma"), 0],
        [this.translateService.instant("apagada"), 0]
      ];

      this.oee = 0;
      this.disponibilidad = 0;
      this.rendimiento = 0;
      this.calidad = 0;

      var th = this;

      d3.select('#graficoDonutOee_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.oee.toFixed(0) + "%");
      d3.select('#graficoDonutDisponibilidad_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.disponibilidad.toFixed(0) + "%");
      d3.select('#graficoDonutRendimiento_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.rendimiento.toFixed(0) + "%");
      d3.select('#graficoDonutCalidad_Isometrico .c3-chart-arcs-title').style("font-size", "17px").style("opacity", "1").text(th.calidad.toFixed(0) + "%");

      if (estabaDesplegado) {

        th.graficoResumenSemana.load({ columns: th.resumenSemana });

        th.graficoDonutOee.load({ columns: [['completo', th.oee], ['nocompleto', 100 - th.oee]] });

        th.graficoDonutDisponibilidad.load({ columns: [['completo', th.disponibilidad], ['nocompleto', 100 - th.disponibilidad]] });

        th.graficoDonutRendimiento.load({ columns: [['completo', th.rendimiento], ['nocompleto', 100 - th.rendimiento]] });

        th.graficoDonutCalidad.load({ columns: [['completo', th.calidad], ['nocompleto', 100 - th.calidad]] });

      } else {

        setTimeout(function () {

          th.graficoResumenSemana.flush();
          th.graficoResumenSemana.load({ columns: th.resumenSemana });

          th.graficoDonutOee.flush();
          th.graficoDonutOee.load({ columns: [['completo', th.oee], ['nocompleto', 100 - th.oee]] });

          th.graficoDonutDisponibilidad.flush();
          th.graficoDonutDisponibilidad.load({ columns: [['completo', th.disponibilidad], ['nocompleto', 100 - th.disponibilidad]] });

          th.graficoDonutRendimiento.flush();
          th.graficoDonutRendimiento.load({ columns: [['completo', th.rendimiento], ['nocompleto', 100 - th.rendimiento]] });

          th.graficoDonutCalidad.flush();
          th.graficoDonutCalidad.load({ columns: [['completo', th.calidad], ['nocompleto', 100 - th.calidad]] });

        }, 500);

      }

    } else {
      this.menuDesplegadoMaquina = false;
    }

  }

  mostrarDatosInstalacionPanel(valdosa) {

    var th = this;

    //PONEMOS TODOS LOS CAMPOS INVISIBLES
    this.campos.forEach(function (campo) {
      campo.visible = false;
    });

    if (valdosa.esObjeto && valdosa.idTipoObjeto == 1) {

      this.menuDesplegadoInstalaciones = true;
      this.instalacionMomentanea = valdosa.instalacion;

      if (valdosa.instalacion != undefined) {

        valdosa.instalacion.campos.forEach(function (campo, i) {

          //PONEMOS EL CAMPO VISIBLE Y LE PONEMOS NOMBRE
          this.campos[i].visible = true;
          this.campos[i].nombre = campo.nombre;
          this.campos[i].valor = campo.valor.toFixed(1) + " " + campo.unidad;

          //ACTUALIZAR GRAFICO VALOR
          this.campos[i].chartValor.internal.config.gauge_min = campo.minimo;
          this.campos[i].chartValor.internal.config.gauge_max = campo.maximo;
          this.campos[i].chartValor.load({ columns: [['data', campo.valor]] });

          //ACTUALIZAR GRAFICO HISTORICO (LE DAMOS LA VUELTA)
          var fechasHistoricos = campo.fechas.split(",").reverse().map(function (e) { return th.myFunctions.sqlToJsDate(e); });
          var arrayValoresHistoricos = campo.valoresHistoricos.split(",").reverse().map(Number);
          fechasHistoricos.unshift("x");
          arrayValoresHistoricos.unshift("data");
          this.campos[i].chartHistorico.load({
            columns: [fechasHistoricos, arrayValoresHistoricos]
          });

        }, this);

      }

    }

  }

  mostrarDatosHornosTemplePanel(valdosa) {

  }

  calcularTiempoPasado(fechaServidor, fechaOrig, tiempoAcumuladoOperacion) {

    var ahora = fechaServidor; //this.myFunctions.getDateNow();

    var hanpasado = this.HanPasadoDias(fechaOrig, ahora, tiempoAcumuladoOperacion);
    var hapasadoTiempo = this.HanPasadoTiempo(fechaOrig, ahora, tiempoAcumuladoOperacion);

    if (hanpasado > 0)
      return hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
    else
      return hapasadoTiempo.toString();

  }

  recalcularTiempoPasado(fechaServidor, tiempopasado, tiempoAcumuladoOperacion) {

    if (this.router.url.includes('/homeplantaisometrico/')) {
      this.tiempopasado = this.calcularTiempoPasado(new Date(Date.parse(fechaServidor)), new Date(Date.parse(tiempopasado)), tiempoAcumuladoOperacion);
    } else {
      clearInterval(this.intervalTiempoPasado);
    }

  }

  HanPasadoDias(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    var dt1 = new Date(fechaOrig);
    var dt2 = new Date(ahora);
    var difSegundos = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.floor(difSegundos / (60 * 60 * 24));
  }

  HanPasadoTiempo(fechaOrig, ahora, tiempoAcumuladoOperacion) {

    let diffInMilliSeconds = Math.abs(fechaOrig - ahora) / 1000;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = Math.floor(diffInMilliSeconds % 60);
    diffInMilliSeconds -= minutes * 60;

    let difference = '';

    if (hours < 10) difference = "0" + hours.toString();
    else difference = hours.toString();

    difference = difference + ":";
    if (minutes < 10) difference = difference + "0" + minutes.toString();
    else difference = difference + minutes.toString();

    difference = difference + ":";
    if (seconds < 10) difference = difference + "0" + seconds.toString();
    else difference = difference + seconds.toString();

    return difference;

  }

  CargarIconosEstadoMaquina(maquina, ejecuciones) {

    var tipoProceso = ejecuciones[0].procesos_Tipo; //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    if (ejecuciones[0].tiempoEstimadoSegundos == 0) {
      maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      maquina.porcentaje = "";
    } else {
      var ahora = new Date(Date.parse(ejecuciones[0].fechaServidor));//this.myFunctions.getDateNow();
      var fechaOrig = new Date(Date.parse(ejecuciones[0].fechainicio));
      var tiempoAcumuladoOperacion = ejecuciones[0].tiempoAcumuladoOperacion;

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2 + tiempoAcumuladoOperacion;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      var docentaje = this.calcularPorcentaje(segundosfiff, 12, ejecuciones[0].tiempoEstimadoSegundos, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, ejecuciones[0].tiempoEstimadoSegundos, 1, true);
      maquina.porcentaje = porcentaje + "%";
      maquina.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
    }

  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }

  redirigirHmi() {

    if (this.tipo_maquina == 1) {
      this.router.navigate(["procesos/" + this.idMaquina]);
    }

    if (this.tipo_maquina == 2) {
      this.router.navigate(["procesosextrusora/" + this.idMaquina]);
    }

    if (this.tipo_maquina == 3) {
      this.router.navigate(["procesosimpresora/" + this.idMaquina]);
    }

    if (this.tipo_maquina == 4) {
      this.router.navigate(["procesosinyectora/" + this.idMaquina]);
    }

    if (this.tipo_maquina == 5) {
      this.router.navigate(["procesoshorno/" + this.idMaquina]);
    }

    if (this.tipo_maquina == 12) {
      this.router.navigate(["procesoshornotemple/" + this.idMaquina]);
    }

  }

  redirigirInformeRendimiento() {
    this.router.navigate(["informerendimiento/" + this.idMaquina]);
  }

  redirigirOee() {
    this.router.navigate(["informeOEE"]);
  }

  redirigirHmiInstalacion() {
    this.router.navigate(["procesosinstalacion/" + this.instalacionMomentanea.id]);
  }

}
