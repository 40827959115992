import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
const baseUrl = `${environment.apiUrl}/eskola_grupos`;

@Injectable()
export class EskolaGruposService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getAll_filtrado(filtroDocentes, filtroTutores, filtroCompleto) {
    return this.http.post<JSON>(`${baseUrl}/getAll_filtrado`, {filtroDocentes, filtroTutores, filtroCompleto }, { withCredentials: true });
  }

  deleteGrupo(id: number){
    return this.http.post<JSON>(`${baseUrl}/delete_grupo`, { id }, { withCredentials: true });
  }

  getAll_alumnos(){
    return this.http.get<any[]>(`${baseUrl}/getAll_alumnos`);
    
  }

  getAll_alumnos_filtrado(filtroTutores, filtroCompleto) {
    return this.http.post<JSON>(`${baseUrl}/getAll_alumnos_filtrado`, {filtroTutores, filtroCompleto }, { withCredentials: true });
  }

  GetAll_datosDocentes(): Observable<any> {
    return this.http.get(`${baseUrl}/GetAll_datosDocentes`, { withCredentials: true });
  }

  getAll_docentes(): Observable<any> {
    return this.http.get<any[]>(`${baseUrl}/GetAll_docentes`,{ withCredentials: true });
  }

  getAll_docentes_filtrado(filtroCompleto, filtroGrupos) {
    return this.http.post<JSON>(`${baseUrl}/getAll_docentes_filtrado`, {filtroCompleto,filtroGrupos }, { withCredentials: true });
  }

  deleteDocente(id: number){
    return this.http.post<JSON>(`${baseUrl}/delete_docente`, { id }, { withCredentials: true });
  }

  insertGrupo(Nombre, Id_cursoLectivo, Id_tipoGrupo, FechaInicio, FechaFin, Calendario, Activo, Color){
    return this.http.post<JSON>(`${baseUrl}/insertGrupo`, {Nombre,Id_cursoLectivo, Id_tipoGrupo, FechaInicio,FechaFin, Calendario, Activo, Color }, { withCredentials: true });

  }
  updateGrupo(Id, Nombre,Id_cursoLectivo, Id_tipoGrupo, FechaInicio,FechaFin, Calendario, Activo, Color){
    return this.http.post<JSON>(`${baseUrl}/updateGrupo`, {Id, Nombre,Id_cursoLectivo, Id_tipoGrupo, FechaInicio,FechaFin, Calendario, Activo, Color }, { withCredentials: true });

  }

  insertTutorGrupo(Id_usuario:number, Id_grupo:number){
    return this.http.post<JSON>(`${baseUrl}/insertTutorGrupo`, {Id_usuario, Id_grupo}, { withCredentials: true });

  }
  deleteTutorGrupo(Id_usuario:number, Id_grupo:number){
    return this.http.post<JSON>(`${baseUrl}/deleteTutorGrupo`, {Id_usuario, Id_grupo}, { withCredentials: true });
  }

  getTutoresGrupo(Filtro:string){
    return this.http.post<any>(`${baseUrl}/getTutoresGrupo`, {Filtro}, { withCredentials: true });

  }

  insertAsignaturaGrupo(Id_asignatura: number, Id_grupo:number){
    return this.http.post<any>(`${baseUrl}/insertAsignaturaGrupo`, {Id_asignatura, Id_grupo}, { withCredentials: true });

  }
  insertDocenteAsignaturaGrupo(Id_docente: number, Id_grupo:number, Id_asignaturaRetos:number){
    return this.http.post<any>(`${baseUrl}/insertDocenteAsignaturaGrupo`, {Id_docente, Id_asignaturaRetos, Id_grupo}, { withCredentials: true });

  }

  getAsignaturasGrupo(Filtro:string){
    return this.http.post<any>(`${baseUrl}/getAsignaturasGrupo`, {Filtro}, { withCredentials: true });

  }
  geDocentesAsignaturasGrupo(Filtro:string){
    return this.http.post<any>(`${baseUrl}/getDocentesAsignaturasGrupo`, {Filtro}, { withCredentials: true });

  }

  deleteAsignaturasGrupo(Id:number, Id_asignatura:number, Id_grupo:number){
    return this.http.post<any>(`${baseUrl}/deleteAsignaturasGrupo`, {Id, Id_asignatura, Id_grupo}, { withCredentials: true });
  }
  deleteDocenteAsignaturasGrupo(Id:number, Id_docente: number, Id_grupo:number, Id_asignaturaRetos:number){
    return this.http.post<any>(`${baseUrl}/deleteDocenteAsignaturasGrupo`, {Id, Id_docente, Id_asignaturaRetos, Id_grupo}, { withCredentials: true });
  }
  importarDocente(data) {
    return this.http.post<any>(baseUrl + "/importarDocente", data , { withCredentials: true });
}

}