import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Usuario } from '@app/_models';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/calendario`;

@Injectable()
export class CalendarioService {
    public titulo: string;

    constructor(private http: HttpClient) {
    }

    public insertReglaCalendario1(ano, codcomedor, codcomida, dia1, dia2, dia3, dia4, dia5, dia6, dia7) {
        return this.http.post<any>(baseUrl + '/insertReglaCalendario1', { ano, codcomedor, codcomida, dia1, dia2, dia3, dia4, dia5, dia6, dia7 }, { withCredentials: true });
    }

    public updateReglaCalendario1(ano, codcomedor, codcomida, dia1, dia2, dia3, dia4, dia5, dia6, dia7) {
        return this.http.post<any>(baseUrl + '/updateReglaCalendario1', { ano, codcomedor, codcomida, dia1, dia2, dia3, dia4, dia5, dia6, dia7 }, { withCredentials: true });
    }

    public getPeriodosAnoActual(ano, codcomedor, codcomida) {
        return this.http.post<any>(baseUrl + '/getPeriodosAnoActual', { ano, codcomedor, codcomida }, { withCredentials: true });
    }

    public insertReglaCalendario2(ano, codcomedor, codcomida, fechaInicio, fechaFin, activo) {
        // hay que corregir las fechas
        let mes, dia;
        let auxiFechaInicio = null;
        let auxiFechaFin = null;
        if (fechaInicio != null && fechaInicio != '') {
            ano = fechaInicio.getFullYear();
            mes = fechaInicio.getMonth() + 1;
            dia = fechaInicio.getDate();
            auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
        }
        if (fechaFin != null && fechaFin != '') {
            ano = fechaFin.getFullYear();
            mes = fechaFin.getMonth() + 1;
            dia = fechaFin.getDate();
            auxiFechaFin = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T23:59:59' + 'Z';
        }
        return this.http.post<any>(baseUrl + '/insertReglaCalendario2', { ano, codcomedor, codcomida, fechaInicio: auxiFechaInicio, fechaFin: auxiFechaFin, activo }, { withCredentials: true });
    }

    deleteReglaCalendario2(id): Observable<any> {
        return this.http.get(`${baseUrl}/deleteReglaCalendario2/${id}`, { withCredentials: true });
    }

    // Auxiliar functions
    addZero(n: number) {
        if (n < 10) {
            return '0' + n.toString();
        }
        else {
            return n.toString();
        }
    }
}
