import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuService, AlertService, UsuariosService, EskolaGruposService, Eskola_AsignaturasRetosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';
import { FileInfo, UploadEvent } from '@progress/kendo-angular-upload';

@Component({
    selector: 'app-docentes',
    templateUrl: './eskola_docentes.component.html'
  })
export class Eskola_DocentesComponent implements OnInit{
  private translate: TranslateService;
  form: FormGroup;
  private dataFiltro: any;
  public loadingDatos: boolean = false;
        //GRAFICOS Y GRIDS VISIBLES
        public datosVisibles: boolean = false;
  dataDocentes: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;

  public tutoresC: any = [];

  modalReference: NgbModalRef;
  user = this.userService.userValue;
  public loadingPanel: any = false;

  public myFiles: Array<FileInfo> = [];
  modalReferenceloadingupload: NgbModalRef;
  private _kendoFiles: FileInfo[];

   //FILTRO
   status: boolean = true; //para abrir o cerrar el filtro * se carga abierto siempre! si se autofiltra, se cierra solo.
   // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
   //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
   public andOr = [
     { id: 0, nombre: this.translateService.instant('y') },
     { id: 1, nombre: this.translateService.instant('o') }
   ];
   public opcionDate = [
     { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
     { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
   ];
   public opcionDateTime = [
     // { id: 0, nombre: ' ', tipo: '' },
     { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
     { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
     { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
     { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
     { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
     { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
   ];
   public opcionNumericDecimal = [
     // { id: 0, nombre: ' ', tipo: '' },
     { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
     { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
     { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
     { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
     { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
     { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
     { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
     { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
   ];
   public opcionComboEstricto = [
     // { id: 0, nombre: ' ', tipo: '' },
     // { id: 1, nombre: 'Uniselect', dobleValor: false},
     { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
     // { id: 3, nombre: 'No select', dobleValor: false},
     { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
   ];
   public opcionComboFlexible = [
     // { id: 0, nombre: ' ', tipo: '' },
     // { id: 1, nombre: 'Uniselect', dobleValor: false},
     { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
     // { id: 3, nombre: 'No select', dobleValor: false},
     { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
     { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
     // { id: 6, nombre: 'No empieza por', dobleValor: false},
     { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
     // { id: 8, nombre: 'No acaba por', dobleValor: false},
     { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
     { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
     // { id: 11, nombre: 'Igual que', dobleValor: false},
     // { id: 12, nombre: 'Diferente a', dobleValor: false}
   ];
   public opcionString = [
     // { id: 0, nombre: ' ', tipo: '' },
     { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
     // { id: 2, nombre: 'No empieza por', dobleValor: false},
     { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
     // { id: 4, nombre: 'No acaba por', dobleValor: false},
     { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
     { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
     { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
     { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
   ];
   // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
   //FECHA DOBLE
   public DaysInMonths;
 
   // VARIABLES input del filtro
   public permitirFiltroVacio = false; // permite vaciar el filtro por completo
   public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
   public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
   public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
   public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
 
   // DATOS INICIALES DEL FILTRO
   public filtroPorDefecto = {
     logic: { id: 1, nombre: this.translateService.instant('o') },
     group: [
       {
         logic: { id: 0, nombre: this.translateService.instant('y') },
         group: [
           {
             columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
             operator: { id: 0, nombre: '' },
             fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
             fechaFin: this.myFunctions.getDateNow(),
             mostrarCalendario: false,
             text: '',
             numberMin: 0,
             numberMax: 0,
             decimalformat: '0.000',
             decimalMin: 0.0,
             decimalMax: 0.0,
             check: false,
             combo: [{ id: 1, nombre: "" }],
             comboSelected: {},
             comboSelecteds: []
           }
         ]
       }
     ]
   }; 
   public datosFiltro = {
     logic: { id: 1, nombre: this.translateService.instant('o') },
     group: []
   };  
   // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
   public columnasFiltro = [
     { id: 2, nombre: this.translateService.instant('nombre'), field: "id", sqlfield: "ud.id", tipo: 'comboEstrincto' },
     { id: 3, nombre: this.translateService.instant('email'), field: "email", sqlfield: "ud.email", tipo: 'comboEstrincto'},   
     { id: 4, nombre: this.translateService.instant('telefono'), field: "telefono", sqlfield: "ud.telefono", tipo: 'comboEstrincto' },
     { id: 5, nombre: this.translateService.instant('tutorde'), field: "grupoId", sqlfield: "g.id", tipo: 'comboEstrincto' },
     { id: 6, nombre: this.translateService.instant('activo'), field: "activo", sqlfield: "ud.activo", tipo: 'check' },
     
     
     ];

    
    // Combo cargable del filtro
    public filtro_listaANombre: any = [];
    public filtro_listaEmail: any = [];
    public filtro_listaTelefono: any = [];
    public filtro_listaGrupo: any = [];
    public filtro_listaActivo: any = [];
    public filtro_ocultarPartes: boolean;

    // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;
  

    // END FILTRO

  constructor(private gruposService: EskolaGruposService,
    private asignaturasService: Eskola_AsignaturasRetosService,
    private alertService: AlertService,
    translate: TranslateService, 
    public myFunctions: MyFunctions,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translateService.instant('docentes').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/docentes') {
          this.cargarDatos();
        }
      }
    });
  
  }

  ngOnInit(): void {
      this.cargarDatos()
  }

  //DATOS TABLA
  cargarDatos() {
    this.gruposService.getAll_docentes().subscribe((result) => {
      this.dataDocentes = result;

      if(this.dataDocentes != null && this.dataDocentes.length > 0)
        this.dataDocentes.forEach(element => {
           //vemos si es tutor
           if(element.grupos != null && element.grupos!=undefined){
            //es tutor
            var gruposAux = element.grupos.split(',');
            var grupostutor = [];
            gruposAux.forEach(grupo => {
              var idGrupo = grupo.split(';')[0];
              var nombreGrupo = grupo.split(';')[1];
              grupostutor.push(nombreGrupo);
            });
            element.grupo = grupostutor.join(', ');
            element.grupoNombre = grupostutor.join(', ');
            
          }else{
            // no es tutor
            element.grupo = "";
          }

        })

    });
    this.cargar_Filtro();
  }

  onClickEliminar(content){
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
 
  }

  eliminarRegistro(){
    this.modalReference.close();
    this.mySelection.forEach(docenteSeleccionado => {
      if (docenteSeleccionado > 0) {
        this.isDeleting = true;
        this.userService.delete(docenteSeleccionado, {value:{id:docenteSeleccionado}}).subscribe(res=>{
          this.asignaturasService.deleteDocente(docenteSeleccionado).subscribe({
            next: () => {
              this.gruposService.deleteDocente(docenteSeleccionado).subscribe({
                next: () => {
                  this.isDeleting = false;
                  this.alertService.success(this.translateService.instant('eliminadocorrectamente'), { keepAfterRouteChange: true });
                  this.router.navigate(['../docentes'], { relativeTo: this.route });
                },
                error: error => {
                  this.isDeleting = false;
                  this.alertService.error(error);
                }
              });
            },
            error: BadRequest =>{
              this.isDeleting = false;
                  this.alertService.error(BadRequest);
            }
          
          });
     
        });

      }
     });
  }

  cellClick(event){
    this.router.navigate(['/docentes/' + event.dataItem.id]);
  }

  editarClick(){
    if(this.mySelection.length>0){
      this.router.navigate(['/docentes/' +this.mySelection[0]]);
    }
  }

  //FILTRO

  //Datos tabla con filtro
  cargarDatosFiltro() {

    var filtroCompleto: any = this.filtroToSQL(['ud.id','ud.email','ud.telefono','ud.activo']); // filtro completo
    var filtroGrupos: any = this.filtroToSQL(['g.id']); // filtro grupos
    this.datosVisibles = true;
    this.loadingDatos = true;
    this.gruposService.getAll_docentes_filtrado(filtroCompleto, filtroGrupos).subscribe((result) => {
      this.dataDocentes = result;
      

      if(this.dataDocentes != null && this.dataDocentes.length > 0)
        this.dataDocentes.forEach(element => {
                    //vemos si es tutor
           if(element.grupos != null && element.grupos!=undefined){
            //es tutor
            var gruposAux = element.grupos.split(',');
            var grupostutor = [];
            gruposAux.forEach(grupo => {
              var idGrupo = grupo.split(';')[0];
              var nombreGrupo = grupo.split(';')[1];
              grupostutor.push(nombreGrupo);
            });
            element.grupo = grupostutor.join(', ');
            element.grupoNombre = grupostutor.join(', ');
            
          }else{
            // no es tutor
            element.grupo = "";
          }

        })
    });
    
  }
  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
    }
  }
  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
      this.actualizarVisible = true;
    }
  }
  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.cargarDatos();
   
  
    
  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
 
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
     
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
   
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
    
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
   
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
       
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {

          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 6);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    
    //FECHAS
    this.cargarMeses();

   
    this.gruposService.getAll_docentes().subscribe((data)=>{
      data.forEach(element => {
         // NOMBRES DOCENTE
        var d: any =[];
        d.id = element.id; //usamos el id de la tabla usuario para que no se repitan
        d.nombre = element.nombre; 
        if(this.filtro_listaANombre.filter(r=> r.id===d.id).length<=0){
          this.filtro_listaANombre.push(d);
        }
        // EMAILS
        var e:any=[];
        e.id=element.email;
        e.nombre=element.email;
        if(this.filtro_listaEmail.filter(r=> r.nombre ===e.nombre).length<=0){
          this.filtro_listaEmail.push(e);
        }
        //Telefonos
        var t: any=[];
        t.id=element.telefono;
        t.nombre=element.telefono;
        if(this.filtro_listaTelefono.filter(r=> r.nombre ===t.nombre).length<=0){
          this.filtro_listaTelefono.push(t);
        }

        //grupos
        if(element.grupos!=null){
          this.gruposService.getAll().subscribe(res=>{
            res.forEach(grupo=>{
              var g : any = [];
              g.id = grupo.id;
              g.nombre = grupo.nombre;

              if(this.filtro_listaGrupo.filter(r=> r.id ===g.id).length<=0){
                this.filtro_listaGrupo.push(g);
              }
            })
          });
        
       
      }
      });
    });

   
    //ACTIVO
    this.filtro_listaActivo = [
      { nombre: this.translateService.instant("activo"), id: 1, value: 1 },
      { nombre: this.translateService.instant("inactivo"), id: 0, value: 0 },  
    ];

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES
    
    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('nombre'), field: "id", sqlfield: "ud.id", tipo: 'comboEstrincto' },
        { id: 3, nombre: this.translateService.instant('email'), field: "email", sqlfield: "ud.email", tipo: 'comboEstrincto'},   
        { id: 4, nombre: this.translateService.instant('telefono'), field: "telefono", sqlfield: "ud.telefono", tipo: 'comboEstrincto' },
        { id: 5, nombre: this.translateService.instant('tutorde'), field: "grupoId", sqlfield: "g.id", tipo: 'comboEstrincto' },
        { id: 6, nombre: this.translateService.instant('activo'), field: "activo", sqlfield: "ud.activo", tipo: 'comboEstrincto' },
        
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      
  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id ==2) { // nombre
        row.combo = this.filtro_listaANombre.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));  
      }
      else if (row.columna.id == 3) { // email
        row.combo = this.filtro_listaEmail.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));  
       
      }
      else if (row.columna.id == 4) { // telefono
        row.combo = this.filtro_listaTelefono.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));  
       
      }
      else if (row.columna.id == 5) { // tutorde
        row.combo = this.filtro_listaGrupo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 6) { // activo
        row.combo = this.filtro_listaActivo;
       }
    }
  }
  onFilter() {

    this.cargarDatosFiltro();
    this.status = true;
    this.actualizarVisible = false;
  }

  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
   
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };

    }
 
    
  // END FILTRO


  onUploadButtonClick(e: UploadEvent, content) {

    this._kendoFiles = e.files;
    this.myFiles = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append('idioma', this.user.idioma);
    formData.append('idDb', this.user.idDb.toString());
    formData.append('tema', this.user.tema.toString());
    formData.append('menuexpandido', this.user.menuExpandido.toString());


    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.gruposService.importarDocente(formData).subscribe((result)=>{
      this.modalReferenceloadingupload.close();
      if (result['error'] == false) {
        var kont = 0;
        result['data'].forEach(alumnoInsertado => {
          this.userService.assignColour(alumnoInsertado.id).subscribe(res =>{
            kont ++;
            if(kont == result['data'].length){
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.router.navigate(['../docentes'], { relativeTo: this.route });
            }
          });
        });
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
      this.myFiles = [];
      this.cargarDatos();
    });
  }



}

