import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService, DiccionarioPerdidasService, MaquinasService, MenuService, UsuariosService, AtributosService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TipoPerdida_DAT } from '@app/_models';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Renderer2, NgZone, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { State, process } from '@progress/kendo-data-query';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { RowArgs, CellClickEvent } from "@progress/kendo-angular-grid";

/*import {
  AddEvent,
  CancelEvent,
  CellClickEvent,
  CellCloseEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
} from "@progress/kendo-angular-grid";*/


const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-diccionario-perdida-detalle',
  templateUrl: './diccionarioPerdidaDetalle.component.html'
})
export class DiccionarioPerdidaDetalleComponent {

  public state: State = {
    skip: 0,
    take: 100
  };

  private translate: TranslateService;

  public id: number;
  public idPadre: number;
  public idPadreAux: number;

  public tiposObservacion_DAT: any = [];
  public selectedItemObservacion: any;
  public idTipoObservacion_DAT: number;

  public tiposPerdidas_DAT: TipoPerdida_DAT[];
  public selectedItem: TipoPerdida_DAT;
  public idTipoPerdida_DAT: number;
  public tiposGravedad_DAT: any = [];
  public selectedItemGravedad: any;
  public selectedItemGrupo: -1;
  public selectedTipoDiccionarioPerdida: -1;
  public selectedItemTipoPerdida: -1;
  public idTipoGravedad_DAT: number;
  public idGrupoPerdida_DAT: number;
  public idTipoDiccionarioPerdida_DAT: number;
  public atributosSelected: any = [];
  public idAtributosSelected: any = [];
  public operacion: boolean;
  public operacionChecked: boolean;
  public tienePadre: boolean = false;

  public fromAllMaquinasSelect: boolean = false;
  public buttonAllMaquinasSelectName: string = '';

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  mySelection: number[] = [];
  mySelectionAux: number[] = [];

  public maquinas: any = [];
  private currentSubscription: Subscription;
  public seleccionados: number[] = [];
  public hijos: any = [];
  public subtiposAnnadir: any = [];
  public listaIdsHijos: any = [];
  public disableEliminar;

  public gruposPerdida: any = [];
  public atributosList: any = [];
  public diccionarioPerdidasTipos: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private diccionarioPerdidasService: DiccionarioPerdidasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private renderer: Renderer2,
    translate: TranslateService,
    private maquinasService: MaquinasService,
    private atributosService: AtributosService,
    private alertService: AlertService,
    private zone: NgZone,
    private modalService: NgbModal) {

    this.translate = translate;

    this.menuService.titulo = this.translate.instant('diccionarioPerdidas').toUpperCase();

    if (this.maquinas.length <= this.mySelectionAux.length) this.buttonAllMaquinasSelectName = this.translate.instant('seleccionarTodo');
    else this.buttonAllMaquinasSelectName = this.translate.instant('deseleccionarTodo');

    this.cargarDatos();
    this.route.params.subscribe(params => { //si los params cambian
      this.id = this.route.snapshot.params['id'];
      this.idPadre = this.route.snapshot.params['idPadre'];
      this.isAddMode = !this.id;
    });
  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    this.disableEliminar = true;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.diccionarioPerdidasService.GetAll_TiposPerdidas_DAT().pipe(first()).subscribe(
        (result: any) => {

          var an: any = result.data;
          an.forEach(f => {
            f.nombre = this.translate.instant(f.nombre)
          });

          this.tiposPerdidas_DAT = result.data;
        }
      );
    });

    this.id = this.route.snapshot.params['id'];
    this.idPadre = this.route.snapshot.params['idPadre'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      descripcion: ['',],
      operacion: [false,],
      seguimiento: [false,],
      tieneSubtipos: [false,],
      esPreparacion: [false,]
    });

    this.diccionarioPerdidasService.GetAll_TiposPerdidas_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre)
        });

        this.tiposPerdidas_DAT = result.data;
      }
    );

    this.tiposObservacion_DAT = [
      { id: 0, nombre: this.translate.instant("sinObservacion") },
      { id: 1, nombre: this.translate.instant("observacionRequerida") },
      { id: 2, nombre: this.translate.instant("observacionOpcional") }
    ];

    this.tiposGravedad_DAT = [
      { id: 1, nombre: this.translate.instant("leve") },
      { id: 2, nombre: this.translate.instant("medio") },
      { id: 3, nombre: this.translate.instant("grave") }
    ];

    var auxi = this.id;
    if (auxi == undefined || auxi == null)
      auxi = -1;
    this.diccionarioPerdidasService.GetAll_datosMaquinas(auxi).subscribe(json => {
      this.maquinas = json;
      this.selectDiccionarios();
    });


    this.diccionarioPerdidasService.GetAllGrupos().subscribe(element => {
      var gr: any = element.data;
      this.gruposPerdida = [];
      gr.forEach(grupo => {
        this.gruposPerdida.push(grupo);
      });
      this.atributosService.getAtributos().subscribe(data => {
        var at: any = data;
        this.atributosList = [];
        at.forEach(atributo => {
          this.atributosList.push(atributo);
        });
        this.atributosService.GetAtributosDiccionarioPerdidas(this.id).subscribe(listaAtributos => {
          var lista: any = listaAtributos;
          this.atributosSelected = [];
          lista.forEach(atributos => {
            this.atributosSelected.push(atributos);
            this.idAtributosSelected.push(atributos.idAtributo)
          });
        });
        this.diccionarioPerdidasService.getDiccionarioPerdidasTipo().subscribe(tipos => {
          var ti: any = tipos;
          this.diccionarioPerdidasTipos = [];
          ti.forEach(tipo => {
            this.diccionarioPerdidasTipos.push(tipo);
          });
        })
        // console.log(this.atributosList);
      });
    });

    if (this.id > 0) {
      this.diccionarioPerdidasService.GetById(this.id).pipe().subscribe((result) => {

        this.idPadreAux = result.data[0].idPadre;
        this.operacionChecked = result.data[0].operacion;
        this.selectedItem = new TipoPerdida_DAT(result.data[0].idTipoPerdida_DAT, "");
        this.idTipoPerdida_DAT = result.data[0].idTipoPerdida_DAT;
        this.selectedItemObservacion = this.tiposObservacion_DAT.find(x => x.id == result.data[0].requerido);
        this.idTipoObservacion_DAT = result.data[0].requerido;
        this.selectedItemGravedad = this.tiposGravedad_DAT.find(x => x.id == result.data[0].gravedad);
        this.idTipoGravedad_DAT = result.data[0].gravedad;
        this.selectedTipoDiccionarioPerdida = result.data[0].idDiccionarioPerdidasDat
        this.selectedItemGrupo = result.data[0].idGrupoPerdida
        if (result.data[0].idPadre != -1) {
          this.tienePadre = true;
        } else {
          this.tienePadre = false;
        }


        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre, Validators.required],
          descripcion: [result.data[0].descripcion,],
          //idTipoPerdida_DAT: [result.data[0].idTipoPerdida_DAT,],
          operacion: [(result.data[0].operacion != null && result.data[0].operacion != undefined) ? result.data[0].operacion : false,],
          seguimiento: [(result.data[0].seguimiento != null && result.data[0].seguimiento != undefined) ? result.data[0].seguimiento : false,],
          tieneSubtipos: [(result.data[0].tieneSubtipos != null && result.data[0].tieneSubtipos != undefined) ? result.data[0].tieneSubtipos : false],
          esPreparacion: [(result.data[0].esPreparacion != null && result.data[0].esPreparacion != undefined) ? result.data[0].esPreparacion : false],
          idDiccionarioPerdidaTipo: this.selectedTipoDiccionarioPerdida, idGrupoPerdida: this.selectedItemGrupo
        });

        this.diccionarioPerdidasService.getSubtipos(this.id).pipe().subscribe((result: any) => {
          this.hijos = result;
          this.disableEliminar = result.length < 1;

          this.diccionarioPerdidasService.getSubtipos_annadir().pipe().subscribe((data: any) => {
            this.subtiposAnnadir = data;
            this.hijos.forEach(hijo => {
              this.listaIdsHijos.push(hijo.id);
            });
          });
        });

      });
    }
  }

  selectDiccionarios() { //función para obtener la selecciçon de máquinas
    this.mySelection = [];
    this.mySelectionAux = [];
    for (var maquina of this.maquinas) {
      if (maquina.seleccionado == true) {
        this.mySelection.push(maquina.id);
      }
    }
    //this.mySelectionAux=this.mySelection;   //erreferentzia
    this.mySelectionAux = Object.assign([], this.mySelection); //kopia
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging
    };
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.maquinas.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.maquinas.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.maquinas.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.maquinas.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.maquinas.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (!this.fromAllMaquinasSelect) {
      this.submitted = true;
      if (this.form.invalid) {
        return;
      }

      this.loading = true;
      if (this.isAddMode) {
        this.insert();
      } else {
        this.update();
      }
    }
    else this.fromAllMaquinasSelect = false;
  }

  private insert() {
    if (this.idPadreAux != undefined) {
      this.form.value.idPadre = this.idPadreAux > 0 ? this.idPadreAux : -1;
    } else {
      this.form.value.idPadre = this.idPadre > 0 ? this.idPadre : -1;
    }
    if (this.hijos.length > 0) {
      this.form.value.tieneSubtipos = 1
    } else {
      this.form.value.tieneSubtipos = 0
    }
    var listaST: string = "";
    this.atributosSelected.forEach(lista => {
      if (listaST == "") {
        listaST = lista.id.toString();
      } else {
        listaST = listaST + "," + lista.id.toString();
      }
    });
    this.form.value.atributos = listaST;

    if (this.form.value.idGrupoPerdida == undefined) {
      this.form.value.idGrupoPerdida = -1;
    }
    if (this.form.value.atributos == undefined) {
      this.form.value.atributos = -1;
    }
    if (this.form.value.idDiccionarioPerdidaTipo == undefined) {
      this.form.value.idDiccionarioPerdidaTipo = -1;
    }

    var listaIdsHijos = "";
    this.hijos.forEach(hijo => {
      if (listaIdsHijos == "") {
        listaIdsHijos = hijo.id.toString();
      } else {
        listaIdsHijos = listaIdsHijos + "," + hijo.id.toString();
      }
    });

    this.diccionarioPerdidasService.insert(this.form.value, this.idTipoObservacion_DAT, this.idTipoPerdida_DAT, this.idTipoGravedad_DAT, this.mySelection.join(","), listaIdsHijos)
      .subscribe((result) => {
        {
          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            this.atras();
          } else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      }
      );
  }

  private update() {
    this.form.value.id = this.id;
    if (this.hijos.length > 0) {
      this.form.value.tieneSubtipos = 1
    } else {
      this.form.value.tieneSubtipos = 0
    }
    if (this.idPadreAux != undefined) {
      this.form.value.idPadre = this.idPadreAux > 0 ? this.idPadreAux : -1;
    } else {
      this.form.value.idPadre = this.idPadre > 0 ? this.idPadre : -1;
    }
    var listaST = "";
    this.atributosSelected.forEach(lista => {
      if (listaST == "") {
        listaST = lista.id;
      } else {
        listaST = listaST + "," + lista.id;
      }
    });
    this.form.value.atributos = listaST;
    if (this.form.value.idGrupoPerdida == undefined) {
      this.form.value.idGrupoPerdida = -1;
    }
    if (this.form.value.atributos == undefined) {
      this.form.value.atributos = "-1";
    }
    if (this.form.value.idDiccionarioPerdidaTipo == undefined) {
      this.form.value.idDiccionarioPerdidaTipo = -1;
    }
    var listaIdsHijos = "";
    this.hijos.forEach(hijo => {
      if (listaIdsHijos == "") {
        listaIdsHijos = hijo.id.toString();
      } else {
        listaIdsHijos = listaIdsHijos + "," + hijo.id.toString();
      }
    });
    this.diccionarioPerdidasService.update(this.form.value, this.idTipoObservacion_DAT, this.idTipoPerdida_DAT, this.idTipoGravedad_DAT, this.mySelection.join(","), listaIdsHijos)
      .subscribe((result) => {
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          this.atras();
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      });
  }

  public selectionChangeObservacion(value: any): void {
    this.idTipoObservacion_DAT = value.id
  }

  public selectionChange(value: any): void {
    this.idTipoPerdida_DAT = value.id
  }

  public selectionChangeGravedad(value: any): void {
    this.idTipoGravedad_DAT = value.id
  }

  public selectionChangeGrupoPerdida(value: any): void {
    this.selectedItemGrupo = value.id;
    this.form.value.idGrupoPerdida = value.id;
  }

  public selectionChangeTipoDiccionarioPerdida(value: any): void {
    this.selectedTipoDiccionarioPerdida = value.id;
    this.form.value.idDiccionarioPerdidaTipo = value.id;
  }
  // public selectionChangeTipoPerdida(value: any): void {
  //   this.idTipoPerdida_DAT = value.id
  // }
  public valueChangeAtributo(e: any) {
    this.atributosSelected = e;
  }

  public atras() {
    if (this.idPadre < 0) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['diccionarioperdidas'])
      );
    }
    else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['diccionarioperdidaseditar/' + this.idPadre + "/-1"])
      );
    }
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  @ViewChild('save') save: NgbModalRef;
  @ViewChild('save2') save2: NgbModalRef;
  @ViewChild('delete') delete: NgbModalRef;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  onClickNuevo(content, e) {
    e.preventDefault(); //Así prevenimos el Submit
    if (this.isAddMode) {
      if (this.form.invalid) {
        this.submitted = true;
      } else {
        this.submitted = false;
        this.modalReference = this.modalService.open(this.save, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    } else {
      if (this.form.invalid) {
        this.submitted = true;
      } else {
        this.submitted = false;
        this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }

  }

  onClickEliminar(content, e) {
    e.preventDefault(); //Así prevenimos el Submit
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(this.delete, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEditar() {

    if (this.seleccionados.length == 1) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this.router.navigate(['diccionarioperdidaseditar/' + this.seleccionados[0] + "/-1"])
      );
    }




    // e.preventDefault(); //Así prevenimos el submit
    // if (this.isAddMode) {
    //   if (this.form.invalid) {
    //     this.submitted = true;
    //   } else {
    //     this.submitted = false;
    //     this.modalReference = this.modalService.open(this.save2, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    //   }
    // } else {
    //   if (this.form.invalid) {
    //     this.submitted = true;
    //   } else {
    //     this.submitted = false;
    //     this.modalReference = this.modalService.open(this.save2, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    //   }
    // }
  }

  cellClick(content, e) {
    this.seleccionados = [];
    this.seleccionados.push(e.dataItem.id);
    if (e.columnIndex > 0) {
      if (this.isAddMode) {
        if (this.form.invalid) {
          this.submitted = true;
        } else {
          this.submitted = false;
          this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        }
      } else {
        if (this.form.invalid) {
          this.submitted = true;
        } else {
          this.submitted = false;
          this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        }
      }
    }
  }

  private eliminarRegistro(contentloading) {

    // this.diccionarioPerdidasService.delete({ ids: this.seleccionados }).subscribe(
    //   (data) => {
    //     if (data.error == false) {
    //       this.diccionarioPerdidasService.getSubtipos(this.id).pipe().subscribe((result: any) => {
    //         this.hijos = result;
    //         this.disableEliminar = result.length < 1;
    //       });
    //     }
    //     this.modalReferenceloading.close();
    //   }
    // );
    var listaIdHijos = "";

    if (this.seleccionados.length > 0) {
      //this.hijos = [];
      var elementosEliminar
      this.seleccionados.forEach(hijo => {
        var aurk = true;
        var kont = 0;
        while (aurk) {
          if (this.hijos[kont].id == hijo){
            aurk = false;
          }
          kont++;
        }
        if (!aurk){
          this.hijos.splice(kont-1, 1);
        }
      });
    } else {
      this.hijos = [];
    }


    // this.diccionarioPerdidasService.update_Subtipos(-1, listaIdHijos).subscribe((data: any) => {
    //   this.modalReference.close();
    //   this.diccionarioPerdidasService.getSubtipos(this.id).pipe().subscribe((result: any) => {
    //     this.hijos = result;
    //     this.disableEliminar = result.length < 1;

    //     this.diccionarioPerdidasService.getSubtipos_annadir().pipe().subscribe((data: any) => {
    //       this.subtiposAnnadir = data;
    //       this.hijos.forEach(hijo => {
    //         this.listaIdsHijos.push(hijo.id);
    //       });
    //     });
    //   });
    // });


    this.modalReference.close();
    //this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  private guardarRegistro() {
    if (this.isAddMode) {
      if (this.idPadreAux != undefined) {
        this.form.value.idPadre = this.idPadreAux > 0 ? this.idPadreAux : -1;
      } else {
        this.form.value.idPadre = this.idPadre > 0 ? this.idPadre : -1;
      }
      var listaIdsHijos = "";
      this.hijos.forEach(hijo => {
        if (listaIdsHijos == "") {
          listaIdsHijos = hijo.id.toString();
        } else {
          listaIdsHijos = listaIdsHijos + "," + hijo.id.toString();
        }
      });
      this.diccionarioPerdidasService.insert(this.form.value, this.idTipoObservacion_DAT, this.idTipoPerdida_DAT, this.idTipoGravedad_DAT, this.mySelection.join(","), listaIdsHijos)
        .subscribe((result) => {
          {
            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['diccionarioperdidascrear/' + this.id])
              );
            } else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        });
    } else {
      this.form.value.id = this.id;
      if (this.idPadreAux != undefined) {
        this.form.value.idPadre = this.idPadreAux > 0 ? this.idPadreAux : -1;
      } else {
        this.form.value.idPadre = this.idPadre > 0 ? this.idPadre : -1;
      }
      var listaIdsHijos = "";
      this.hijos.forEach(hijo => {
        if (listaIdsHijos == "") {
          listaIdsHijos = hijo.id.toString();
        } else {
          listaIdsHijos = listaIdsHijos + "," + hijo.id.toString();
        }
      });
      this.diccionarioPerdidasService.insert(this.form.value, this.idTipoObservacion_DAT, this.idTipoPerdida_DAT, this.idTipoGravedad_DAT, this.mySelection.join(","), listaIdsHijos)
        .subscribe((result) => {
          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
              this.router.navigate(['diccionarioperdidascrear/' + this.id])
            );
          } else {
            this.alertService.error(this.translate.instant('error'));
          }
        });
    }



    this.modalReference.close();
  }

  private editarRegistro() {
    this.form.value.id = this.id;
    if (this.idPadreAux != undefined) {
      this.form.value.idPadre = this.idPadreAux > 0 ? this.idPadreAux : -1;
    } else {
      this.form.value.idPadre = this.idPadre > 0 ? this.idPadre : -1;
    }
    var listaIdsHijos = "";
    this.hijos.forEach(hijo => {
      if (listaIdsHijos == "") {
        listaIdsHijos = hijo.id.toString();
      } else {
        listaIdsHijos = listaIdsHijos + "," + hijo.id.toString();
      }
    });
    this.diccionarioPerdidasService.insert(this.form.value, this.idTipoObservacion_DAT, this.idTipoPerdida_DAT, this.idTipoGravedad_DAT, this.mySelection.join(","), listaIdsHijos)
      .subscribe((result) => {
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.router.navigate(['diccionarioperdidaseditar/' + this.seleccionados[0] + "/" + this.id])
          );
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      });
    this.modalReference.close();
  }

  private checkAllMaquinas() {
    if (this.maquinas.length <= this.mySelectionAux.length && this.maquinas.length <= this.mySelection.length) {
      this.mySelectionAux = [];
      this.buttonAllMaquinasSelectName = this.translate.instant('seleccionarTodo');
    }
    else {
      this.maquinas.forEach(f => {
        //f.seleccionado = true;
        this.mySelectionAux.push(f.id);
      });
      this.buttonAllMaquinasSelectName = this.translate.instant('deseleccionarTodo');
    }
    this.fromAllMaquinasSelect = true;
    //this.mySelectionAux=this.mySelection;
    this.mySelection = Object.assign([], this.mySelectionAux);
  }

  private updateHijos() {
    var listaIdHijos = [];
    this.hijos = [];

    //listaIdHijos = new Set(this.listaIdsHijos).size !== this.listaIdsHijos.length;
    listaIdHijos = this.listaIdsHijos.splice(0, this.listaIdsHijos.length, ...new Set(this.listaIdsHijos));;

    this.listaIdsHijos.forEach(hijo => {

      var hijoNuevo = this.subtiposAnnadir.filter(f => f.id == hijo);
      this.hijos.push(hijoNuevo[0]);



      // if (listaIdHijos == "") {
      //   listaIdHijos = hijo.toString();
      // } else {
      //   listaIdHijos = listaIdHijos + "," + hijo
      // }
    });
    // this.diccionarioPerdidasService.update_Subtipos(parseInt(this.id.toString()), listaIdHijos).subscribe((data: any) => {
    //   this.modalReference.close();
    //   this.diccionarioPerdidasService.getSubtipos(this.id).pipe().subscribe((result: any) => {
    //     this.hijos = result;
    //     this.disableEliminar = result.length < 1;

    //     this.diccionarioPerdidasService.getSubtipos_annadir().pipe().subscribe((data: any) => {
    //       this.subtiposAnnadir = data;
    //       this.hijos.forEach(hijo => {
    //         this.listaIdsHijos.push(hijo.id);
    //       });
    //     });
    //   });
    // });
    var a = this.hijos;
    this.modalReference.close();
  }


  //public cellClickHandler({  sender,  rowIndex,  columnIndex,  dataItem,  isEdited,}: CellClickEvent): void 
  public cellClickHandler(e: CellClickEvent): void {
    if (this.maquinas.length == this.mySelection.length) this.maquinas.forEach(f => { this.mySelectionAux.push(f.id); });
    if (this.mySelection.length == 0) this.mySelectionAux = [];

    if (this.mySelectionAux.includes(e.dataItem.id)) {
      //remove id from myselection
      const index = this.mySelectionAux.indexOf(e.dataItem.id, 0);
      if (index > -1) {
        this.mySelectionAux.splice(index, 1);
      }
      this.buttonAllMaquinasSelectName = this.translate.instant('seleccionarTodo');
    }
    else {
      this.mySelectionAux.push(e.dataItem.id)
    }
    this.mySelection = Object.assign([], this.mySelectionAux);
  }

}
