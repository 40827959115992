import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/operarios`;

@Injectable()
export class OperariosService {

  constructor(
    private http: HttpClient
  ) {
  }

  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }
  getAllUsuariosConColor() {
    return this.http.post<JSON>(`${baseUrl}/getAllUsuariosConColor`, {}, { withCredentials: true });
  }
}
