import { Injectable } from '@angular/core';
import { UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { format } from '@progress/kendo-angular-intl';
import * as moment from 'moment-timezone';
@Injectable()
export class MyFunctions {
  user = this.userService.userValue;
  constructor(private userService: UsuariosService, private translateService: TranslateService) {
  }

  public stringMaxLength(s: string, maxLength: number) {
    if (s.length > maxLength)
      s = s.substring(0, maxLength - 3) + "...";
    return s;
  }

  public dateToHHmm(fecha: Date) {
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    return this.addZero(hora) + ':' + this.addZero(minutos);
  }
  
  public datetimeToSQL(fecha: Date) {
    //2020-10-25T23:00:00Z
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '/' + this.addZero(mes) + '/' + this.addZero(dia) + ' ' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos);
  }

  public intToBool(i: number) {
    return (i > 0);
  }

  //FECHAS
  public dateToString(m) {
    if (m == null) {
      return "";
    } else if (m == "-") {
      return "-";
    } else {
      var dateString =
        ("0" + m.getDate()).slice(-2) + "/" +
        ("0" + (m.getMonth() + 1)).slice(-2) + "/" +
        m.getFullYear() + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);
      return dateString;
    }
  }

  public dateToDayString(m) {
    if (m == null) {
      return "";
    } else {
      var dateString =
        ("0" + m.getDate()).slice(-2) + "/" +
        ("0" + (m.getMonth() + 1)).slice(-2) + "/" +
        m.getFullYear();
      return dateString;
    }
  }

  public dateToHourString(m) {
    if (m == null) {
      return "";
    } else {
      var dateString =
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);
      return dateString;
    }
  }

  public sqlToJsDate(dateString) {
    //var dateString = "2010-08-09 01:02:03";
    if (dateString === "") {
      return null;
    } else {
      var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
      var dateArray = reggie.exec(dateString);

      var dateObject = new Date(
        (+dateArray[1]),
        (+dateArray[2]) - 1, // Careful, month starts at 0!
        (+dateArray[3]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[6])
      );
      return dateObject;
    }
  }

  public sqlToJsDateT(dateString) {
    //var dateString = "2010-08-09T01:02:03.xxx";
    if (dateString === "") {
      return null;
    } else {
      var reggie = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})/;
      var dateArray = reggie.exec(dateString);
      if (dateArray == null) {
        reggie = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{2})/;
        dateArray = reggie.exec(dateString);
      }

      if (dateArray == null) {
        reggie = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{1})/;
        dateArray = reggie.exec(dateString);
      }

      if (dateArray == null) {
        reggie = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
        dateArray = reggie.exec(dateString);
      }
      var dateObject = new Date(
        (+dateArray[1]),
        (+dateArray[2]) - 1, // Careful, month starts at 0!
        (+dateArray[3]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[6])
      );
      return dateObject;
    }
  }

  public secondsTo_HH_MM_SS(segundos) {

    if (segundos == undefined) return "";

    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (hs + ':' + ms + ':' + ss == '00:00:00')
      return '00:00:00';
    else
      if (segundos < 0)
        return '-' + hs + ':' + ms + ':' + ss;
      else
        return hs + ':' + ms + ':' + ss;
  }

  public secondsTo_HH_MM(segundos) {

    if (segundos == undefined) return "00:00:00";

    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0)
      return '-' + hs + ':' + ms;
    else
      return hs + ':' + ms;
  }

  public milisecondsTo_HH_MM(milisegundos) {

    if (milisegundos == undefined) return "00:00:00";

    var segs;
    if (milisegundos < 0) segs = -(milisegundos / 1000);
    else segs = (milisegundos / 1000);

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (milisegundos < 0) return '-' + hs + ':' + ms;
    else return hs + ':' + ms;
  }

  public milisecondsTo_HH_MM_SS(milisegundos) {

    if (milisegundos == undefined) return "00:00:00";

    var segs;
    if (milisegundos < 0) segs = -(milisegundos / 1000);
    else segs = (milisegundos / 1000);

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (milisegundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }

  public tipoToText(tipo) {
    if (tipo == 1) return "PC"
    if (tipo = 2) return "CNC"
  }

  public sumarleLaVALaVersion(version) {
    return "v" + version
  }

  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  public dateToYYYYMMDDtHHmmSS(fecha: Date) {
    //2020-10-25T23:00:00
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos);
  }

  dateToYYYYMMDDHHmmSS(fecha: Date) { // es para ordenar en ascendente el string
    //20201025230000
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + this.addZero(dia) + this.addZero(hora) + this.addZero(minutos) + this.addZero(segundos);
  }

  dateToYYYYMMDDHHmmSSconSeparacion(fecha: Date) { // YYYY-MM-DD hh:mm:ss
    //20201025230000
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + ' ' +
      this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos);
  }

  public dateToYYYY_MM_DD(fecha: Date) {
    //25_10_2020
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '_' + this.addZero(mes) + '_' + this.addZero(dia);
  }
  public dateToYYYYMMDD_contrabarra(fecha: Date) {
    //25_10_2020
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }
  public YYYY_MM_DDToDate(s: string) {
    //2022_05_17 TO date!
    // EL SEPARADOR NO TIENE POR QUE SER '_'! puede ser cualquier caracter
    var ano: number = +s.substring(0, 4);
    var mes: number = +s.substring(5, 7);
    var dia: number = +s.substring(8, 10);
    return new Date(ano, mes - 1, dia);
  }

  public dateToYYYY_MM_DD_guion(fecha: Date) {
    //2020-10-25
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia);
  }

  public datetimeToDate(fecha: Date) {
    //25_10_2020
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate());
  }

  public HH_MM_ToSeconds(s: String) {
    var segundos = 0;

    var sSplited: any = s.split(":", 2);

    segundos = sSplited[0] * 3600 + sSplited[1] * 60;

    return segundos;
  }

  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  public getDateNow() {
    var d: Date;
    if (this.user == undefined)
      d = new Date(moment.tz(Date.now(), "Europe/Madrid").format('YYYY/MM/DD HH:mm:ss'))
    else
      d = new Date(moment.tz(Date.now(), this.user.timeZone).format('YYYY/MM/DD HH:mm:ss'))

    return d;
  }

  public dateTimeToDate(fecha: Date) {
    //2020/10/25
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth();
    var dia = fecha.getDate(); //getDay da el dia de la semana!

    return new Date(ano, mes, dia);
  }
  public dateAddDays(fecha: Date, addDays: number) {
    var fecha2 = this.dateCopy(fecha);
    if (addDays != 0)
      fecha2.setDate(fecha2.getDate() + addDays);

    return new Date(fecha2);
  }
  public dateAddMonths(fecha: Date, addMonths: number) {
    var fecha2 = this.dateCopy(fecha);
    if (addMonths != 0)
      fecha2.setMonth(fecha.getMonth() + addMonths);

    return new Date(fecha2);
  }
  public dateAddSeconds(date, seconds) {
    date.setSeconds(date.getSeconds() + seconds);
    return date;
  }

  dateWithoutYearShorted(fecha: Date) { // es para ordenar en ascendente el string
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var auxFecha = "";
    var monthArray = [this.translateService.instant("eneroAcortado"), this.translateService.instant("febreroAcortado"),
    this.translateService.instant("marzoAcortado"), this.translateService.instant("abrilAcortado"),
    this.translateService.instant("mayoAcortado"), this.translateService.instant("junioAcortado"),
    this.translateService.instant("julioAcortado"), this.translateService.instant("agostoAcortado"),
    this.translateService.instant("septiembreAcortado"), this.translateService.instant("octubreAcortado"),
    this.translateService.instant("noviembreAcortado"), this.translateService.instant("diciembreAcortado")];
    switch (this.user.idioma) {
      case "es":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " ";
        break;
      case "eu":
        auxFecha += monthArray[Number(mes) - 1] + " " + dia + " ";
        break;
      case "en":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " ";
        break;
      case "it":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " ";
        break;
      case "ru":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " ";
        break;
      default:
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " ";
        break;

    }
    return auxFecha;
  }
  dateShorted(fecha: Date) { // es para ordenar en ascendente el string
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var auxFecha = "";
    var monthArray = [this.translateService.instant("eneroAcortado"), this.translateService.instant("febreroAcortado"),
    this.translateService.instant("marzoAcortado"), this.translateService.instant("abrilAcortado"),
    this.translateService.instant("mayoAcortado"), this.translateService.instant("junioAcortado"),
    this.translateService.instant("julioAcortado"), this.translateService.instant("agostoAcortado"),
    this.translateService.instant("septiembreAcortado"), this.translateService.instant("octubreAcortado"),
    this.translateService.instant("noviembreAcortado"), this.translateService.instant("diciembreAcortado")];
    switch (this.user.idioma) {
      case "es":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " " + ano + " ";
        break;
      case "eu":
        auxFecha += ano + " " + monthArray[Number(mes) - 1] + " " + dia + " ";
        break;
      case "en":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " " + ano + " ";
        break;
      case "it":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " " + ano + " ";
        break;
      case "ru":
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " " + ano + " ";
        break;
      default:
        auxFecha += dia + " " + monthArray[Number(mes) - 1] + " " + ano + " ";
        break;

    }
    return auxFecha;
  }




  public dateToHHMM(fecha: Date) {
    var horas = fecha.getHours().toString();
    var minutos = fecha.getMinutes().toString();
    if (Number(horas) < 10)
      horas = this.addZero(Number(horas));
    if (Number(minutos) < 10)
      minutos = this.addZero(Number(minutos));
    return horas + ':' + minutos;
  }

  public dateToHHMMSS(fecha: Date) {
    var horas = fecha.getHours().toString();
    var minutos = fecha.getMinutes().toString();
    var segundos = fecha.getSeconds().toString();
    if (Number(horas) < 10)
      horas = this.addZero(Number(horas));
    if (Number(minutos) < 10)
      minutos = this.addZero(Number(minutos));
    if (Number(segundos) < 10)
      segundos = this.addZero(Number(segundos));
    return horas + ':' + minutos + ':' + segundos;
  }

  //LIST

  public listToStirng(lista, separator, emptyCase) {
    if (lista == undefined) {
      return emptyCase;
    } else {
      var s = "";

      lista.forEach(
        elem => {
          if (s == "")
            s = elem.toString();
          else
            s += separator + elem.toString();
        }
      );

      if (emptyCase != undefined && s == "")
        s = emptyCase

      return s;
    }
  }

  public dateToDD_MM_YYYY_forwardslash(start: Date) {
    // 12/11/2020
    var fecha = new Date(start);

    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return this.addZero(dia) + "/" + this.addZero(mes) + "/" + ano;
  }

  // ADD zero
  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  //HOME
  calcularOee(dtOee) {

    if (dtOee != undefined && dtOee.length > 0) {

      var tEjecucion = 0;
      var tParadaDisponibilidad = 0;
      var tMantenimientoDisponibilidad = 0;
      var tAlarmaDisponibilidad = 0;
      var tApagadaDisponibilidad = 0;
      var tParadaRendimiento = 0;
      var tMantenimientoRendimiento = 0;
      var tAlarmaRendimiento = 0;
      var tApagadaRendimiento = 0;
      var tParadaCalidad = 0;
      var tMantenimientoCalidad = 0;
      var tAlarmaCalidad = 0;
      var tApagadaCalidad = 0;
      var tNegativoCalidad = 0;
      var tEstimado = 0;

      dtOee.forEach(function (a) {
        tEjecucion += a.tEjecucion;
        tParadaDisponibilidad += a.tParadaDisponibilidad;
        tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
        tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
        tApagadaDisponibilidad += a.tApagadaDisponibilidad;
        tParadaRendimiento += a.tParadaRendimiento;
        tMantenimientoRendimiento += a.tMantenimientoRendimiento;
        tAlarmaRendimiento += a.tAlarmaRendimiento;
        tApagadaRendimiento += a.tApagadaRendimiento;
        tParadaCalidad += a.tParadaCalidad;
        tMantenimientoCalidad += a.tMantenimientoCalidad;
        tAlarmaCalidad += a.tAlarmaCalidad;
        tApagadaCalidad += a.tApagadaCalidad;
        tNegativoCalidad += a.tNegativoCalidad;
        tEstimado += a.tEstimado;
      });

      //CALCULAR DONUTS
      var oee: number = 0;
      var cal: number = 0;
      var ren: number = 0;
      var dis: number = 0;

      var perdidasDisponibilidad = tParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
      var perdidasRendimiento = tParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;

      var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

      var disDividendo: number = tEjecucion + perdidasRendimiento;
      var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      var renDividendo: number = tEstimado;
      var renDivisor: number = tEjecucion + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      var calDividendo: number = tEstimado - tNegativoCalidad - perdidasCalidad;
      var calDivisor: number = tEstimado;
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);



      return [oee, dis, ren, cal];

    } else {

      return [0, 0, 0, 0];

    }

  }
  //END HOME

  //CHECK DE UNDEFINED EN HTML
  zitu_if(json, valor, default_value) {
    if (json == undefined)
      return default_value;
    else
      return json[valor];
  }

  quitarRepeticiones(a: any[]): any[] {
    var unique = a.filter(this.onlyUnique);
    return unique
  }
  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }



  // ESTA FUNCION COPIA JSONs!! MEN PRINCIPIO FECHAS Y BOOLEANOS TAMBIEN HACE BIEN, SI FALLA EN ALGO, REVISAD
  // en principio deberia poder copiar de TODO! 
  copy<T>(source: T): T {
    return Array.isArray(source)
      ? source.map(item => this.copy(item))
      : source instanceof Date
        ? new Date(source.getTime())
        : source && typeof source === 'object'
          ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
            o[prop] = this.copy((source as { [key: string]: any })[prop]);
            return o;
          }, Object.create(Object.getPrototypeOf(source)))
          : source as T;
  }

  //ESTA FUNCION ES PARA LAS SELECCIONES EN LOS GRIDS, QUE SELECCIONE TODOS PORQUE KENDO SOLO SELECCIONA 50 COMO MUCHO

  //ASI SE DEBE PONER EL CHECKBOX
  /* <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                       (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,variablesSeleccionadas,
                       dataGrid1, 'id')">
                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template> */
  public onSelectAllChangeGrid(checkedState: SelectAllCheckboxState, variablesSeleccionadas, dataGrid, keyId) {

    if (checkedState === 'checked') {

      variablesSeleccionadas = [];


      dataGrid.forEach(element => {
        variablesSeleccionadas.push(element[keyId])
      });

    } else {

      variablesSeleccionadas = [];
    }

  }

  //FUNCION AUXILIAR PARA EL DESPLIEGUE DE MAQUINAS
  desplegarMaquinasClick(vista) {
    var th = vista;
    setTimeout(function () {
      th.aparecer = !th.aparecer;
    }, 200);
  }

  asignarDiaSemana(date): { dia: string, id: number } {

    var dia: string = "";
    var id: number;
    if (date.getDay() == 1) {
      dia = this.translateService.instant('lunes');
      id = 1;
    }
    if (date.getDay() == 2) {
      dia = this.translateService.instant('martes');
      id = 2;
    }
    if (date.getDay() == 3) {
      dia = this.translateService.instant('miercoles');
      id = 3;
    }
    if (date.getDay() == 4) {
      dia = this.translateService.instant('jueves');
      id = 4;
    }
    if (date.getDay() == 5) {
      dia = this.translateService.instant('viernes');
      id = 5;
    }
    if (date.getDay() == 6) {
      dia = this.translateService.instant('sabado');
      id = 6;
    }
    if (date.getDay() == 0) {
      dia = this.translateService.instant('domingo');
      id = 7
    }
    return { dia, id };
  }

  dateToYYYYMMDD(fecha: Date) {
    //2020-10-25
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return año + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }

  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  startOfMonth(date) {
    var d = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + '01';
    return new Date(d);
  }

  getNumberOfWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }

  dateTo_Mes_YYYY(d) {
    var fecha = new Date(d);
    var year = fecha.getUTCFullYear();
    var month = fecha.getUTCMonth() + 2;
    switch (month) {
      case 1:
        return this.translateService.instant('enero') + ' ' + year;
      case 2:
        return this.translateService.instant('febrero') + ' ' + year;
      case 3:
        return this.translateService.instant('marzo') + ' ' + year;
      case 4:
        return this.translateService.instant('abril') + ' ' + year;
      case 5:
        return this.translateService.instant('mayo') + ' ' + year;
      case 6:
        return this.translateService.instant('junio') + ' ' + year;
      case 7:
        return this.translateService.instant('julio') + ' ' + year;
      case 8:
        return this.translateService.instant('agosto') + ' ' + year;
      case 9:
        return this.translateService.instant('septiembre') + ' ' + year;
      case 10:
        return this.translateService.instant('octubre') + ' ' + year;
      case 11:
        return this.translateService.instant('noviembre') + ' ' + year;
      case 12:
        return this.translateService.instant('diciembre') + ' ' + year;

      default:
        break;
    }
  }

  getDatefromWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  Min_date(fecha1, fecha2): Date {
    return fecha1 < fecha2 ? fecha1 : fecha2;
  }
  Max_date(fecha1, fecha2): Date {
    return fecha1 > fecha2 ? fecha1 : fecha2;
  }

  rendondeo_mod(valor, mod) {
    // Esta funcion redondea valor al numero mas cercano que sea multiplo de mod
    // Ej:
    /*
        rendondeo_mod(5.5, 10) = 10
        rendondeo_mod(4.96, 10) = 0
        rendondeo_mod(105.5, 10) = 110
        rendondeo_mod(105.5, 100) = 100
        rendondeo_mod(1013.45, 100) = 1000
        rendondeo_mod(73.45, 100) = 100
    */

    /* Como a veces no redondea bien los decimales, se hace el proximo redondea si es que se han especificado decimales. */
    var decimalesMod = 0
    var decimales = mod.toString().split('.')[1];
    if (decimales != undefined)
      decimalesMod = decimales.length;

    return parseFloat((Math.round(valor / mod) * mod).toFixed(decimalesMod));
    // return (Math.round(valor / mod) * mod);

  }

  numero_con_formato(num, digits) {
    if (num.toString().length > 3) {

      // esto es necesario por si el numero viene con muchos decimales
      if (num < 1000) {
        return num.toFixed(digits);
      }

      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
      });
      return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    } else {
      return num;
    }

  }

  secondsTo_HHh_MM(num) {
    var numAbs = Math.abs(num);
    if (numAbs < 60)
      if (num < 0)
        return Math.floor(num) + "''";
      else
        return "+" + Math.floor(num) + "''";

    var hora = Math.floor(numAbs / (60 * 60));
    var minuto = Math.floor((numAbs - hora * (60 * 60)) / 60);
    var segundos = Math.floor(numAbs - (hora * 3600) - (minuto * 60));
    var result = "+";
    if (num < 0)
      result = "-";

    if (hora != 0)
      result = result + hora + "h ";
    if (minuto != 0)
      result = result + minuto + "' ";
    if (hora == 0 && segundos != 0)
      result = result + segundos + "''";
    return result;
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    } else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }

  public addXzero(n: number, x: number) {
    var zeros = 20;
    if (zeros > x)
      zeros = x;
    var r = '00000000000000000000' + n.toString();
    return r.substring(r.length - zeros, r.length);
  }


  // MAQUINAS ENCADENADAS

  conseguir_cadenasDeMaquinas(maquinas_encadenadas) {
    var cadenas = [];
    // INICIAR CADENAS POR EL FINAL
    var origenes = [];
    var destinos = [];
    maquinas_encadenadas.forEach(
      pareja => {
        if (!origenes.includes(pareja.idMaquina)) origenes.push(pareja.idMaquina);
        if (!destinos.includes(pareja.idMaquinaProximaEncadenada)) destinos.push(pareja.idMaquinaProximaEncadenada);
      });
    //    Se da una primera vuelta para sacar las maquinas que son SOLO FINALES de cadeas. 
    destinos.forEach(
      maquina => {
        if (!origenes.includes(maquina)) cadenas.push({ subcadena: false, maquinas: [maquina] });
      });
    //    Con las cadenas ya empezadas, daremos vueltas a las encadenadas hasta que no añadamos ninguna maquina
    var seguir = true; // Para entrar siempore almenos 1 vez
    var iteraciones = 0; // * pondre un limite de 100 maquinas... para evitar bucles (aunque este control deveria de ser al crear las cadenas...)
    while (seguir && iteraciones < 10) {
      seguir = false
      maquinas_encadenadas.forEach(
        pareja => {
          cadenas.forEach(
            cadena => {
              // * siuna maquina ya existe en una cadena, no se añadira de nuevo para no seguir el bucle... (aunque este control deveria de ser al crear las cadenas...)
              if (cadena.maquinas[0] == pareja.idMaquinaProximaEncadenada && !cadena.maquinas.includes(pareja.idMaquina)) {
                var maquinas = this.copy(cadena.maquinas);
                maquinas.unshift(pareja.idMaquina);
                // Para evitar lineas duplicadas, se comprueba que esa cadena no exita ya entre las cadenas y subcadenas existentes.
                var cadenaExistente = this.check_cadenaExistente(cadenas, maquinas);
                if (!cadenaExistente) {
                  cadena.subcadena = true;
                  cadenas.push({ subcadena: false, maquinas: maquinas });
                  seguir = true;
                }
              }
            });
        });
      iteraciones++;
    }

    var r_cadenas = [];
    cadenas.forEach(
      cadena => {
        // * siuna maquina ya existe en una cadena, no se añadira de nuevo para no seguir el bucle... (aunque este control deveria de ser al crear las cadenas...)
        if (!cadena.subcadena) r_cadenas.push(cadena.maquinas);
      });

    return r_cadenas;
  }
  check_cadenaExistente(cadenas, maquinas) {
    var r = false;

    cadenas.forEach(
      f => {
        if (r) return r;
        r = f.maquinas.every((value, index) => value == maquinas[index]);
      });
    return r;
  }
}
