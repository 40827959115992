import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/hmi`;
@Injectable()
export class HmiService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  Get_ProcesosHistorial(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_ProcesosHistorial`, { idmaquina }, { withCredentials: true });
  }

  Get_ProcesosAlarmas(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_ProcesosAlarmas`, { idmaquina }, { withCredentials: true });
  }

  Get_Planificados(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_Planificados`, { idmaquina }, { withCredentials: true });
  }

  Get_MantenimientosRecientes_porFecha(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_MantenimientosRecientes_porFecha`, { idmaquina }, { withCredentials: true });
  }

  Get_MantenimientosRecientes_porTiempo(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_MantenimientosRecientes_porTiempo`, { idmaquina }, { withCredentials: true });
  }

  GetPdf_MantenimientosRecientes(nomPdf: string) {
    return this.http.post<JSON>(`${baseUrl}/GetPdf_MantenimientosRecientes`, { nomPdf }, { withCredentials: true });
  }

  UNupdate_mantenimiento_codigos(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/UNupdate_mantenimiento_codigos`, { idmaquina }, { withCredentials: true });
  }

  update_mantenimiento_codigos(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/update_mantenimiento_codigos`, { idmaquina }, { withCredentials: true });
  }

  UNupdate_mantenimiento_codigos_Tiempo(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/UNupdate_mantenimiento_codigos_Tiempo`, { idmaquina }, { withCredentials: true });
  }

  update_mantenimiento_codigos_Tiempo(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/update_mantenimiento_codigos_Tiempo`, { idmaquina }, { withCredentials: true });
  }

  Get_NotasActualesMaquina(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_NotasActualesMaquina`, { idmaquina }, { withCredentials: true });
  }

  Get_MaximoHerramientas(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_MaximoHerramientas`, { idmaquina }, { withCredentials: true });
  }

  Get_HerramientasActuales(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_HerramientasActuales`, { idmaquina }, { withCredentials: true });
  }

  Get_Herramientas(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_Herramientas`, { idmaquina }, { withCredentials: true });
  }

  Get_CambiosRoturas(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_CambiosRoturas`, { idmaquina }, { withCredentials: true });
  }

  get_ejes_infoDet_id(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/get_ejes_infoDet_id`, { idmaquina }, { withCredentials: true });
  }

  Get_DatosTiempoReal_ejes_det(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_DatosTiempoReal_ejes_det`, { idmaquina }, { withCredentials: true });
  }

  Get_ejes_maquina(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_ejes_maquina`, { idmaquina }, { withCredentials: true });
  }

  Get_potenciadores_maquina(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_potenciadores_maquina`, { idmaquina }, { withCredentials: true });
  }

  Get_ejecuciones_maquina_offline(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_ejecuciones_maquina_offline`, { idmaquina }, { withCredentials: true });
  }

  Get_procesos_offline(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_procesos_offline`, { idmaquina }, { withCredentials: true });
  }

  Get_produccion_offline(idmaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_produccion_offline`, { idmaquina }, { withCredentials: true });
  }

  Get_ResumenSemana_offline(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_ResumenSemana_offline`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }

  public Get_maquinas_parametros() {
    return this.http.post<JSON>(`${baseUrl}/Get_maquinas_parametros`, { withCredentials: true });
  }
  public Set_maquinas_parametros(idMaquina_parametro: number, idOperario: number, fecha: string, valor: number) {
    return this.http.post<JSON>(`${baseUrl}/Set_maquinas_parametros`, { idMaquina_parametro, idOperario, fecha, valor }, { withCredentials: true });
  }
  
  public Update_parametros(tabla: string, columna: string, idMaquina: number, valor: number, fechaIni: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Update_parametros`, { tabla, columna, idMaquina, valor, fechaIni, fechaFin }, { withCredentials: true });
  }
}
