<div class="card">
  <div class="card-body">
    <div class="clearfix">
      <!-- AREA PRODUCTIVA -->
      <div class="float-left mr-2">
        <div class="form-group">
          <kendo-label text="{{ 'areaProductiva' | translate }} / {{ 'seccion' | translate }}">
            <div class="caja">
              <kendo-multiselect [data]="areasProductivas" [textField]="'nombreSeccion'" [valueField]="'idSeccion'"
                [(ngModel)]="selectedAreaSeccion" (valueChange)="cambiarAreaSeccion()">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreSeccion}}
                  </ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                    'seccionesSeleccionadas'
                    |
                    translate }}</ng-container>
                </ng-template>
              </kendo-multiselect>
            </div>
          </kendo-label>
        </div>
      </div>
      <!-- GRUPO MAQUINAS -->
      <div class="float-left mr-2">
        <div class="form-group">
          <kendo-label text="{{ 'grupoMaquinas' | translate }}">
            <div class="caja">
              <kendo-multiselect [data]="grupos" [textField]="'nombre'" [valueField]="'id'" [(ngModel)]="selectedGrupo"
                (valueChange)="cambiarAreaSeccion()">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}
                  </ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                    'seccionesSeleccionadas'
                    |
                    translate }}</ng-container>
                </ng-template>
              </kendo-multiselect>
            </div>
          </kendo-label>
        </div>
      </div>
    </div>
  </div>
</div>

<kendo-tabstrip #tabstrip (tabSelect)="tabSelected($event)">
  <kendo-tabstrip-tab id="real" [title]="real" [selected]="true">
    <ng-template kendoTabContent>

      <div class="card">
        <div class="card-body" *ngIf="tablaMaquinas[0] != undefined">
          <kendo-grid [data]="tablaMaquinas[0].datos">
            <kendo-grid-column field="idMaquina" hidden="hidden" title="Product ID" width="20%"></kendo-grid-column>
            <kendo-grid-column field="nombreMaquina" title=""></kendo-grid-column>
            <kendo-grid-column *ngFor="let columna of tablaMaquinas[0]?.datos[0].compatibles; let i = index"
              title="{{columna.nombreMaquinaCompatible}}" [headerStyle]="{'text-align': 'center'}"
              [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="input-group">
                  <!-- value nola jari datua ondo ?¿?¿ -->
                  <div *ngIf="dataItem.idMaquina == dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true" spinners="false" format="#"
                      decimals="0" [style]="{'text-align': 'right'}" [disabled]="true"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="dataItem.idMaquina != dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true"
                      [style]="{'text-align': 'right'}" spinners="false" format="#" decimals="0"></kendo-numerictextbox>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
        <div class="float-left form-group" *ngIf="!vaciarMaquinas">
          <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="onSubmit(contentloading,$event)"
            [disabled]="this.user.maquinas<2">
            {{ 'guardar' | translate}}
          </button>
        </div>
      </div>


    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab id="v1" [title]="v1" (tabSelect)="tabSelected(2)">
    <ng-template kendoTabContent>

      <div class="card">
        <div class="card-body" *ngIf="tablaMaquinas[1] != undefined">
          <kendo-grid [data]="tablaMaquinas[1]?.datos">
            <kendo-grid-column field="idMaquina" hidden="hidden" title="Product ID" width="20%"></kendo-grid-column>
            <kendo-grid-column field="nombreMaquina" title=""></kendo-grid-column>
            <kendo-grid-column *ngFor="let columna of tablaMaquinas[1]?.datos[0].compatibles; let i = index"
              title="{{columna.nombreMaquinaCompatible}}" [headerStyle]="{'text-align': 'center'}"
              [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="input-group">
                  <!-- value nola jari datua ondo ?¿?¿ -->
                  <div *ngIf="dataItem.idMaquina == dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true" spinners="false" format="#"
                      decimals="0" [style]="{'text-align': 'right'}" [disabled]="true"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="dataItem.idMaquina != dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true"
                      [style]="{'text-align': 'right'}" spinners="false" format="#" decimals="0"></kendo-numerictextbox>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
        <div class="float-left form-group" *ngIf="!vaciarMaquinas">
          <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="onSubmit(contentloading,$event)"
            [disabled]="this.user.maquinas<2">
            {{ 'guardar' | translate}}
          </button>
        </div>
      </div>


    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab id="v2" [title]="v2" (tabSelect)="tabSelected(3)">
    <ng-template kendoTabContent>

      <div class="card">
        <div class="card-body" *ngIf="tablaMaquinas[2] != undefined">
          <kendo-grid [data]="tablaMaquinas[2]?.datos">
            <kendo-grid-column field="idMaquina" hidden="hidden" title="Product ID" width="20%"></kendo-grid-column>
            <kendo-grid-column field="nombreMaquina" title=""></kendo-grid-column>
            <kendo-grid-column *ngFor="let columna of tablaMaquinas[2]?.datos[0].compatibles; let i = index"
              title="{{columna.nombreMaquinaCompatible}}" [headerStyle]="{'text-align': 'center'}"
              [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="input-group">
                  <!-- value nola jari datua ondo ?¿?¿ -->
                  <div *ngIf="dataItem.idMaquina == dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true" spinners="false" format="#"
                      decimals="0" [style]="{'text-align': 'right'}" [disabled]="true"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="dataItem.idMaquina != dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true"
                      [style]="{'text-align': 'right'}" spinners="false" format="#" decimals="0"></kendo-numerictextbox>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
        <div class="float-left form-group" *ngIf="!vaciarMaquinas">
          <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="onSubmit(contentloading,$event)"
            [disabled]="this.user.maquinas<2">
            {{ 'guardar' | translate}}
          </button>
        </div>
      </div>


    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab id="v3" [title]="v3" (tabSelect)="tabSelected(4)">
    <ng-template kendoTabContent>

      <div class="card">
        <div class="card-body" *ngIf="tablaMaquinas[3] != undefined">
          <kendo-grid [data]="tablaMaquinas[3]?.datos">
            <kendo-grid-column field="idMaquina" hidden="hidden" title="Product ID" width="20%"></kendo-grid-column>
            <kendo-grid-column field="nombreMaquina" title=""></kendo-grid-column>
            <kendo-grid-column *ngFor="let columna of tablaMaquinas[3]?.datos[0].compatibles; let i = index"
              title="{{columna.nombreMaquinaCompatible}}" [headerStyle]="{'text-align': 'center'}"
              [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="input-group">
                  <!-- value nola jari datua ondo ?¿?¿ -->
                  <div *ngIf="dataItem.idMaquina == dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true" spinners="false" format="#"
                      decimals="0" [style]="{'text-align': 'right'}" [disabled]="true"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="dataItem.idMaquina != dataItem.compatibles[i].idMaquinaCompatible">
                    <kendo-numerictextbox class="chk_compatible form-control" [min]="0" [max]="100" step="10"
                      [(value)]="dataItem.compatibles[i].porcenCompatibilidad"
                      (valueChange)="onChange($event, dataItem, i)" [autoCorrect]="true"
                      [style]="{'text-align': 'right'}" spinners="false" format="#" decimals="0"></kendo-numerictextbox>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
        <div class="float-left form-group" *ngIf="!vaciarMaquinas">
          <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="onSubmit(contentloading,$event)"
            [disabled]="this.user.maquinas<2">
            {{ 'guardar' | translate}}
          </button>
        </div>
      </div>


    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>






<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup">{{ 'guardando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'guardando' | translate }}...</span>
    </div>
  </div>
</ng-template>






<router-outlet></router-outlet>