<form [formGroup]="form">

  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <!--Año-->
            <div class="col-md-3">
              <div class="form-group">
                <kendo-label text="{{ 'ano' | translate }}">
                  <kendo-textbox formControlName="ano" [ngClass]="{ 'is-invalid': submitted && f.ano.errors }" [readonly]="ano_readonly" (valueChange)="ano_onValueChange($event)"></kendo-textbox>
                </kendo-label>
              </div>
            </div>
            <!--Numero semana-->
            <div class="col-md-3">
              <div class="form-group">
                <kendo-label text="{{ 'numSemana' | translate }}">
                  <kendo-textbox formControlName="numSemana" [ngClass]="{ 'is-invalid': submitted && f.numSemana.errors }" [readonly]="numSemana_readonly" (valueChange)="numSemana_onValueChange($event)"></kendo-textbox>
                </kendo-label>
              </div>
            </div>
            <!--Fechas-->
            <div class="col-md-3">
              <div class="form-group">
                <kendo-label text="{{ 'fechainicio' | translate }}">
                  <kendo-datepicker [weekNumber]="true" [readonly]="true" [(value)]="fechaInicio">
                    <kendo-calendar-messages today="{{ 'hoy' | translate }}">
                    </kendo-calendar-messages>
                  </kendo-datepicker>
                </kendo-label>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <kendo-label text="{{ 'fechafin' | translate }}">
                  <kendo-datepicker [weekNumber]="true" [readonly]="true" [(value)]="fechaFin">
                    <kendo-calendar-messages today="{{ 'hoy' | translate }}">
                    </kendo-calendar-messages>
                  </kendo-datepicker>
                </kendo-label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--GRID-->
      <kendo-grid [kendoGridBinding]="horario" kendoGridSelectBy="idMaquina" [resizable]="true" class="turnos-grid">
        <!--id-->
        <kendo-grid-column width="100%" field="idMaquina" hidden="hidden" title="id"></kendo-grid-column>
        <!--horario-->
        <kendo-grid-column width="5%" field="maquina" title="{{ 'horario' | translate}}"></kendo-grid-column>

        <kendo-grid-column-group title="{{ 'lunes' | translate}}">
          <kendo-grid-column field="lunesMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [style]="{'text-align': 'right'}" [(value)]="dataItem.lunesMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.lunesMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="lunesTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.lunesTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.lunesTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="lunesNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.lunesNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.lunesNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <kendo-grid-column-group title="{{ 'martes' | translate}}">
          <kendo-grid-column field="martesMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.martesMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.martesMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="martesTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.martesTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.martesTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="martesNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.martesNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.martesNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <kendo-grid-column-group title="{{ 'miercoles' | translate}}">
          <kendo-grid-column field="miercolesMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.miercolesMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.miercolesMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="miercolesTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.miercolesTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.miercolesTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="miercolesNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.miercolesNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.miercolesNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <kendo-grid-column-group title="{{ 'jueves' | translate}}">
          <kendo-grid-column field="juevesMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.juevesMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.juevesMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="juevesTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.juevesTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.juevesTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="juevesNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.juevesNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.juevesNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <kendo-grid-column-group title="{{ 'viernes' | translate}}">
          <kendo-grid-column field="viernesMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.viernesMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.viernesMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="viernesTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.viernesTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.viernesTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="viernesNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.viernesNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.viernesNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <kendo-grid-column-group title="{{ 'sabado' | translate}}">
          <kendo-grid-column field="sabadoMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.sabadoMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.sabadoMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="sabadoTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.sabadoTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.sabadoTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="sabadoNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.sabadoNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.sabadoNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <kendo-grid-column-group title="{{ 'domingo' | translate}}">
          <kendo-grid-column field="domingoMannana" title="{{ 'manana' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.domingoMannanaInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.domingoMannanaFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="domingoTarde" title="{{ 'tarde' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.domingoTardeInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.domingoTardeFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="domingoNoche" title="{{ 'noche' | translate}}" width="5%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-timepicker [(value)]="dataItem.domingoNocheInicio"></kendo-timepicker>
              <kendo-timepicker [(value)]="dataItem.domingoNocheFin"></kendo-timepicker>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid-column-group>

        <!--TRADUCCIÓN TEXTOS DEL GRID-->
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
      </kendo-grid>

      <!--BOTONES-->
      <div class="form-group">
        <button class="btn btn-primary btn-sm mr-1" (click)="onSubmit()" [disabled]="this.user.turnos<2">
          {{ 'guardar' | translate}}
        </button>
        <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupDuplicar(popupDuplicar)" [disabled]="this.user.turnos<2">
          {{ 'duplicar' | translate}}
        </button>
        <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
          {{ 'cancelar' | translate}}
        </button>
        <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupCopiarDias()">
          {{ 'copiarTurnosLunes' | translate}}
        </button>
        <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupCopiarMaquina()">
          {{ 'copiarTurnosMaquina' | translate}}
        </button>
        <div [style]="{'text-align': 'right'}">
          <kendo-dropdownlist [data]="diasSemana" [textField]="'text'"
          [valueField]="'value'" [(value)]="selectedItem"></kendo-dropdownlist>
          <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarDia($event)" [disabled]="this.user.turnos<2">
            {{ 'eliminar' | translate}}
          </button>
        </div>
      </div>

      <!--POPUP DUPLICAR-->
      <ng-template #popupDuplicar let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title"></h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{ 'popupduplicarsemana' | translate }}</p>

          <!--Numero semana-->
          <div class="col-md-3">
            <div class="form-group">
              <kendo-textbox formControlName="numDuplicarSemana"></kendo-textbox>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
          <button type="button" class="btn btn-copiar" (click)="duplicarSemana()" [disabled]="this.user.turnos<2">{{ 'duplicar' | translate }}</button>
        </div>
      </ng-template>

      <!--POPUP COPIAR DIAS-->
   <ng-template #popupCopiarDias let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'popupcopiarlunes' | translate }}</p>
      <div class="row" >
        <div class="col" >
          <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="martesSelected" [onLabel]="' '" [offLabel]="' '"></kendo-switch> <kendo-label>{{ 'martes' | translate }}</kendo-label> <br/>
          <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="miercolesSelected" [onLabel]="' '" [offLabel]="' '"></kendo-switch> <kendo-label>{{ 'miercoles' | translate }}</kendo-label> <br/> 
          <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="juevesSelected" [onLabel]="' '" [offLabel]="' '"></kendo-switch> <kendo-label>{{ 'jueves' | translate }}</kendo-label> <br/>
        </div>
        <div class="col">
          <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="viernesSelected" [onLabel]="' '" [offLabel]="' '"></kendo-switch> <kendo-label>{{ 'viernes' | translate }}</kendo-label> <br/>
          <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="sabadoSelected" [onLabel]="' '" [offLabel]="' '"></kendo-switch> <kendo-label>{{ 'sabado' | translate }}</kendo-label> <br/>
          <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="domingoSelected" [onLabel]="' '" [offLabel]="' '"></kendo-switch> <kendo-label>{{ 'domingo' | translate }}</kendo-label> <br/>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-copiar" (click)="copiarDias()" [disabled]="this.user.turnos<2">{{ 'copiar' | translate }}</button>
    </div>
  </ng-template>

  <!--POPUP COPIAR MAQUINA-->
  <ng-template #popupCopiarMaquina let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'copiarTurnosMaquina' | translate }}</p>
      <div class="row" >
        <div class="col" >
          <kendo-label>{{ 'seleccionarMaquina' | translate }}</kendo-label> <br/>
          <kendo-dropdownlist [data]="maquinas" [textField]="'text'"
          [valueField]="'value'" [(value)]="selectedMachine" ></kendo-dropdownlist>
        </div>
        <div class="col">
          <kendo-label>{{ 'seleccionarMaquina2' | translate }}</kendo-label> <br/>
          <ng-container *ngFor="let maquina of maquinas; let i= index;">
            <kendo-switch *ngIf="selectedMachine.value != maquina.value" [ngModelOptions]="{standalone: true}" [(ngModel)]="maquinasSeleccionadas[i]" [onLabel]="' '" [offLabel]="' '"></kendo-switch> 
            <kendo-label *ngIf="selectedMachine.value != maquina.value">{{ maquina.value | translate }}</kendo-label> <br/>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-copiar" (click)="copiarMaquinas()" [disabled]="this.user.turnos<2">{{ 'copiar' | translate }}</button>
    </div>
  </ng-template>

  
    </div>
  </div>
</form>
