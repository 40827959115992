import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, AlarmasTipoService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-alarmasTipoDetalle',
  templateUrl: './alarmasTipoDetalle.component.html'
})
export class AlarmasTipoDetalleComponent implements OnInit {

  navigationSubscription;

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  public user = this.userService.userValue;

  tipoOeeLista: any;
  tipoOeeSeleccionado: any;

  constructor(
    private alarmasTipoService: AlarmasTipoService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UsuariosService,
    private router: Router,
    private translateService: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService
  ) {

    this.menuService.titulo = this.translateService.instant('alarmastipo').toUpperCase();
    this.user = this.userService.userValue;

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      nombre: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });

    if (!this.isAddMode) {
      this.alarmasTipoService.getById(this.id).pipe(first()).subscribe((result) => {
        this.form.patchValue(result);
      });
    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearTipoAlarma();
    } else {
      this.updateTipoAlarma();
    }

  }

  private crearTipoAlarma() {
    this.alarmasTipoService.create(-1, this.form.value.nombre)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['alarmastipo/']);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateTipoAlarma() {
    this.alarmasTipoService.update(this.form.value.id, this.form.value.nombre)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['alarmastipo/']);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

}
