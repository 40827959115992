import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HistogramaTiemposPiezaService, InformeProyectosService, MaquinasService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import * as $ from 'jquery';
import { IntlService } from '@progress/kendo-angular-intl';
import * as c3 from 'c3';
import { ExcelService } from '@progress/kendo-angular-grid';
import { MyFunctions } from '@app/_helpers';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-histograma-tiempo-pieza',
  templateUrl: './histogramaTiempoPieza.component.html'
})
export class HistogramaTiempoPiezaComponent implements OnInit {
  user = this.userService.userValue;
  //Variables filtro
  status: boolean = false; //para abrir o cerrar el filtro
  public data: any; //datos para el filtro
  public listaOfs: any = [];
  public ofsSeleccionados: any = [];
  public listaPiezas: any = [];
  public piezasSeleccionadas: any = [];
  public listaPartes: any = [];
  public partesSeleccionadas: any = [];
  public listaRutas: any = [];
  public rutasSeleccionadas: any = [];
  public listaOperaciones: any = [];
  public operacionesSeleccionadas: any = [];
  public listaProcesos: any = [];
  public procesosSeleccionados: any = [];
  public listaMaquinas: any;
  public maquinasSeleccionadas: any;

  //PANEL MAQUINAS
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  public listaGraficos: any = [];
  public graficoSeleccionado: any = [];
  public soloOperacionesDesvio: boolean = false;
  public opcionesSeleccionadas: boolean = false;
  public datosFiltro: any = [];
  public datosFiltroExtra: any = [];

  //Variables calendario filtro
  public monthsInYearFila = [0, 1]; //Solo va a haber dos meses todo el tiempo
  public DaysInMonths;
  public periodoActualSeleccionado = [];
  mostrarCalendario = false; //Para abarir o cerrar el calendario
  public calendarElement: any;

  public graficaHistograma: any;

  public loading: any; //variable de carga de la grafica

  public noPrimeraVez: any = false;

  //INSTRUCCIONES FILTRO
  ////Al agregar un nuevo elemento en el filtro, hay que añadirlo en la función de cargarDatosFiltro, y crear su funcion de change, y además agregar su opcion en
  //incluirEnTag() de ser eliminado

  constructor(private menuService: MenuService, 
    public router: Router, private translateService: TranslateService,
    private informeProyectosService: InformeProyectosService, private myFunctions: MyFunctions,
    private HistogramaTiemposPiezaService: HistogramaTiemposPiezaService, private userService: UsuariosService,
    private maquinasService: MaquinasService, private cdRef: ChangeDetectorRef, //esto evita el error de expressionchangedafterviewinit
    private intl: IntlService) { //este último es para traducir las fechas para los tags
      console.log(this.user);
    this.menuService.titulo = this.translateService.instant('histogramaTiempoPieza').toUpperCase();
    this.cargarMeses();  //Se cargan los meses del calendario del filtro aquí, ya que necesitamos que esté en el DOM desde el inicio
  }

  //FUNCIONES ESPECIFICAS DEL FILTRO
  //Comprobar si las opciones obligatorias ya están escogidas, y entonces poner el botón de filtrar como habilitado
  //Mter esta funcion en los change de los que sean obligatorios
  comprobarOpcionesObligatorias() {
    if (!this.user.ocultarParte){
      if(this.ofsSeleccionados.length > 0){
        if(this.listaPartes.length==0){
          if (this.piezasSeleccionadas.length > 0
            && this.procesosSeleccionados.length > 0) {
            this.opcionesSeleccionadas = true;
          } else {
            this.opcionesSeleccionadas = false;
          }
        }else{
          if (this.piezasSeleccionadas.length > 0 && this.partesSeleccionadas.length > 0 
            && this.procesosSeleccionados.length > 0) {
            this.opcionesSeleccionadas = true;
          } else {
            this.opcionesSeleccionadas = false;
          }
        }
        
      }else{
        if(this.listaPartes.length==0){
          if (this.piezasSeleccionadas.length > 0
            && this.procesosSeleccionados.length > 0) {
            this.opcionesSeleccionadas = true;
          } else {
            this.opcionesSeleccionadas = false;
          }
        }else{
          if (this.piezasSeleccionadas.length > 0 && this.partesSeleccionadas.length > 0 
            && this.procesosSeleccionados.length > 0) {
            this.opcionesSeleccionadas = true;
          } else {
            this.opcionesSeleccionadas = false;
          }
        }
      }
    }
    else {
      if(this.ofsSeleccionados.length > 0){
        if (this.piezasSeleccionadas.length > 0
          && this.procesosSeleccionados.length > 0) {
          this.opcionesSeleccionadas = true;
        } else {
          this.opcionesSeleccionadas = false;
        }
      }else{
        if (this.piezasSeleccionadas.length > 0
          && this.procesosSeleccionados.length > 0) {
          this.opcionesSeleccionadas = true;
        } else {
          this.opcionesSeleccionadas = false;
        }
      }
    }
  }

  //Función para obtener la cantidad de elementos que tienen datos seleccionados dentro del filtro, y mostrar los botones de cara a ello
  getChildren() {
    return document.getElementsByClassName("filtro-seleccionados")[0].children.length;
  }

  clickEvent() { //Función para abrir y cerrar el menú del filtro
    this.status = !this.status;
    this.mostrarCalendario = false;
  }

  cargarDatosFiltro() {
    //se toman los datos de los clientes, piezas, ofs y operaciones
    this.informeProyectosService.Get_OfsPiezasOperacionesPartesRutasDesdeHistorico().pipe(first()).subscribe((data: any) => {
      this.data = data;

      var groupByPieza = [];
      var groupByOf = [];
      var groupByParte = [];
      var groupByRuta = [];
      var groupByOperacion = [];


      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));

      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1 && item.idPieza !=null));
      this.listaPiezas = this.listaPiezas.filter(item => (item.nombrePieza != ""));
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))

      //GROUP POR PARTE
      data.forEach(function (a) {
        if (!this[a.nombreParte]) {
          this[a.nombreParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.nombreParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.idParte!=null));
      this.listaPartes = this.listaPartes.filter(item => (item.nombreParte != ""));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1 && item.idOf!=null));
      this.listaOfs = this.listaOfs.filter(item => (item.nombreOf != ""));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY RUTA
      data.forEach(function (a) {
        if (!this[a.idRuta]) {
          this[a.idRuta] = {
            idRuta: a.idRuta, nombreRuta: a.nombreRuta,
          };
          groupByRuta.push(this[a.idRuta]);
        }
      }, Object.create(null));

      this.listaRutas = groupByRuta.filter(item => (item.idRuta != -1 && item.idRuta!=null));
      this.listaRutas = this.listaRutas.filter(item => (item.nombreRuta != ""));
      this.listaRutas.sort((a, b) => (a.nombreRuta > b.nombreRuta) ? 1 : ((b.nombreRuta > a.nombreRuta) ? -1 : 0));

      //GROUP BY Operaciones
      data.forEach(function (a) {
        if (!this[a.idOperacion]) {
          this[a.idOperacion] = {
            idOperacion: a.idOperacion, nombreOperacion: a.nombreOperacion,
          };
          groupByOperacion.push(this[a.idOperacion]);
        }
      }, Object.create(null));

      this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1 && item.idOperacion!=null));
      this.listaOperaciones = this.listaOperaciones.filter(item => (item.nombreOperacion != ""));
      this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));


      //MAQUINAS
      //FLTRO POR SECCIONES
      var idsSeccionesSelecteds: any = [];
      if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
        this.seccionesSeleccionadas.forEach(
          seccion => {
            idsSeccionesSelecteds.push(seccion.id);
          });
      }
      else {
        this.secciones.forEach(
          seccion => {
            idsSeccionesSelecteds.push(seccion.id);
          });
      }
      //Le ponemos los tags a las secciones que el usuario tenga seleccionadas por defecto
      if (this.seccionesSeleccionadas.length > 0) { //si tiene alguna escogida
        //se agrega el tag
        var textoAuxi = "";
        if (this.seccionesSeleccionadas.length == 1)
          textoAuxi = this.seccionesSeleccionadas[0].nombre;
        else if (this.seccionesSeleccionadas.length > 1) {
          textoAuxi = this.seccionesSeleccionadas.length + " " + this.translateService.instant('seccionesSeleccionadas');
        }
        this.incluirEnTag(textoAuxi, "seccionesLabel");
      } else {  //se elimina el tag
        this.removeTag("seccionesLabel");
      }
      //FLTRO POR GRUPOS
      var idsGruposSelecteds: any = [];
      if (this.gruposSeleccionados) {
        this.gruposSeleccionados.forEach(
          grupo => {
            idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
          });
      }

      this.listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
      this.listaMaquinas = this.maquinas.filter(f => {
        var enGrupo = false
        f.idsGrupos.split(',').forEach(
          idGrupo => {
            enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
          });
        return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
      });
    });

    //Ahora cargamos el tipo de proceso y el gráfico de referencia
    this.listaProcesos.push({ 'nombreProceso': this.translateService.instant("ejecucion"), 'idProceso': 1 },
      { 'nombreProceso': this.translateService.instant("parada"), 'idProceso': 2 },
      { 'nombreProceso': this.translateService.instant("microparada"), 'idProceso': 3 },
      { 'nombreProceso': this.translateService.instant("mantenimiento"), 'idProceso': 4 },
      { 'nombreProceso': this.translateService.instant("alarma"), 'idProceso': 5 },
      { 'nombreProceso': this.translateService.instant("apagado"), 'idProceso': 6 });
    //Al principio todos están seleccionados
    this.procesosSeleccionados.push({ 'nombreProceso': this.translateService.instant("ejecucion"), 'idProceso': 1 },
      { 'nombreProceso': this.translateService.instant("parada"), 'idProceso': 2 },
      { 'nombreProceso': this.translateService.instant("microparada"), 'idProceso': 3 },
      { 'nombreProceso': this.translateService.instant("mantenimiento"), 'idProceso': 4 },
      { 'nombreProceso': this.translateService.instant("alarma"), 'idProceso': 5 },
      { 'nombreProceso': this.translateService.instant("apagado"), 'idProceso': 6 });

    //Le ponemos los tags por los seleccionados
    if (this.procesosSeleccionados.length > 0) { //si tiene alguna escogida
      //se agrega el tag
      var textoAuxi = "";
      if (this.procesosSeleccionados.length == 1)
        textoAuxi = this.procesosSeleccionados[0].nombreProceso;
      else if (this.procesosSeleccionados.length > 1) {
        textoAuxi = this.procesosSeleccionados.length + " " + this.translateService.instant('procesosSeleccionados');
      }
      this.incluirEnTag(textoAuxi, "procesosLabel");
    } else {  //se elimina el tag
      this.removeTag("procesosLabel");
    }

    this.listaGraficos.push({ 'nombre': this.translateService.instant("ninguno"), 'id': 1 },
      { 'nombre': this.translateService.instant("estimado"), 'id': 2 },
      { 'nombre': this.translateService.instant("predictivo"), 'id': 3 });
    this.graficoSeleccionado = this.listaGraficos[0]; //Establecemos este como el base
  }

  cargarMaquinasIniciales() {
    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.cargarDatosFiltro();
    });

    this.cargarAreasProductivas();
  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    var r1: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1) {
          this.maquinas.forEach(
            maquina => {
              maquina.idMaquina = maquina.id;
              maquina.nombreMaquina = maquina.nombre;
            });
          this.cargarDatosFiltro();
        }
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1) {
        this.maquinas.forEach(
          maquina => {
            maquina.idMaquina = maquina.id;
            maquina.nombreMaquina = maquina.nombre;
          });
        this.cargarDatosFiltro();
      }
    }
  }

  seccionChanged() {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }

    this.listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    this.listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });
  }

  btnFiltrar() {
    var listaIdsPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => a.idPieza);
    var listaIdsOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
    var listaIdsPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => a.idParte);
    var listaIdsRutas = (this.rutasSeleccionadas === undefined) ? [] : this.rutasSeleccionadas.map(a => a.idRuta);
    var listaIdsOperaciones = (this.operacionesSeleccionadas === undefined) ? [] : this.operacionesSeleccionadas.map(a => a.idOperacion);
    var listaIdsProcesos = (this.procesosSeleccionados === undefined) ? [] : this.procesosSeleccionados.map(a => a.idProceso);
    var listaIdsMaquinas = (this.maquinasSeleccionadas === undefined) ? [] : this.maquinasSeleccionadas.map(a => a.idMaquina);
    if (this.periodoActualSeleccionado == undefined || this.periodoActualSeleccionado.length == 0) {
      this.periodoActualSeleccionado = [null, null]
    }
    this.loading = false;
    this.HistogramaTiemposPiezaService.getDatosFiltrados(listaIdsOfs.join(","), listaIdsPiezas.join(","), listaIdsPartes.join(","), listaIdsOperaciones.join(","),
      listaIdsRutas.join(","), this.periodoActualSeleccionado[0], this.periodoActualSeleccionado[1], listaIdsProcesos.join(","),
      this.graficoSeleccionado.id, this.soloOperacionesDesvio, listaIdsMaquinas.join(",")).subscribe((result) => {
        this.datosFiltro = result;
        this.datosFiltroExtra = { "listaProcesos": listaIdsProcesos.join(","), "graficaSeleccionada": this.graficoSeleccionado.id, "piezaSeleccionada": this.piezasSeleccionadas };
        document.getElementById("grafica-histograma").innerHTML = ""; //limpiamos el div para quitar la grafica y volver a cargarla
        this.crearGrafica();
      });

    if (!this.noPrimeraVez)
      this.noPrimeraVez = true;
  }

  btnLimpiarFiltro() { //Al limpiar el filtro, además de vaciar las listas, también hay que mandar todos los elementos de nuevo a la parte de abajo (se suben al seleccionarlos)
    //excepto los obligatorios
    this.ofsSeleccionados = [];
    this.piezasSeleccionadas = [];
    this.periodoActualSeleccionado = [];
    if (!this.user.ocultarParte)
      this.partesSeleccionadas = [];
    this.operacionesSeleccionadas = [];
    this.soloOperacionesDesvio = false;
    this.graficoSeleccionado = this.listaGraficos[0];
    this.opcionesSeleccionadas = false;
    this.gruposSeleccionados = [];
    this.maquinasSeleccionadas = [];
    this.restringirRestoDatos();
    var elemento = document.getElementById("fechasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
    var elemento = document.getElementById("operacionesFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
    var elemento = document.getElementById("desvioFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
    var elemento = document.getElementById("graficoReferenciaFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
    var elemento = document.getElementById("gruposFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
    var elemento = document.getElementById("maquinasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);

    //Quitamos todos los tags
    document.getElementById("tagsFiltro").innerHTML = "";


    //Le ponemos los tags a las secciones que el usuario tenga seleccionadas por defecto, pues este tag es obligatorio y siempre está
    if (this.seccionesSeleccionadas.length > 0) { //si tiene alguna escogida
      //se agrega el tag
      var textoAuxi = "";
      if (this.seccionesSeleccionadas.length == 1)
        textoAuxi = this.seccionesSeleccionadas[0].nombre;
      else if (this.seccionesSeleccionadas.length > 1) {
        textoAuxi = this.seccionesSeleccionadas.length + " " + this.translateService.instant('seccionesSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "seccionesLabel");
    } else {  //se elimina el tag
      this.removeTag("seccionesLabel");
    }
    //Al limpiar el filtro, se deben coger todos los procesos
    this.procesosSeleccionados = this.listaProcesos;
    if (this.procesosSeleccionados.length > 0) { //si tiene alguno escogido, pasa a arriba
      //se agrega el tag
      var textoAuxi = "";
      if (this.procesosSeleccionados.length == 1)
        textoAuxi = this.procesosSeleccionados[0].nombreProceso;
      else if (this.procesosSeleccionados.length > 1) {
        textoAuxi = this.procesosSeleccionados.length + " " + this.translateService.instant('procesosSeleccionados');
      }
      this.incluirEnTag(textoAuxi, "procesosLabel");
    } else { //sino
      //se elimina el tag
      this.removeTag("procesosLabel");
    }
  }

  @HostListener('document:click', ['$event']) //función que detecta todos los clicks del documento
  processClickEvents(event) {
    if (this.calendarElement !== event.target && !this.calendarElement.contains(event.target)) { //si el click se ha hecho fuera del calendario, cierralo
      this.mostrarCalendario = false;
    }
  }

  //Funciones para inicializacion del filtro
  colocarCalendario() { //Función para colocar el calendario siempre respectivo al input de las fechas
    //y hacer que si nos movemos con el ratón este se cierre automáticamente
    var that = this;
    var posCalendario = $('.calendarFiltro-input').offset().top + $('.calendarFiltro-input').height() + 5; //Cogemos la posición a la que hay que colocar el calendario
    (document.getElementsByClassName("calendarFiltro-calendario") as any)[0].style.top = posCalendario + 'px';
    (document.getElementsByClassName("cont-filtro-desplegable") as any)[0].addEventListener("wheel", function (e) { //Evento de la rueda del mouse
      if (that.mostrarCalendario) { //si está abierto el calendario lo cerramos
        that.mostrarCalendario = false;
      }
    });
  }

  ngOnInit(): void {
    this.cargarMaquinasIniciales(); //Se cargan las maquinas y resto de datos del filtro
    this.calendarElement = document.getElementsByClassName('calendarFiltro')[0]; //Le indicamos el elemento para poder usarlo después en otras funciones
    this.loading = false;
    this.crearGrafica();
  }

  ngAfterViewChecked() { //Utilizamos esta función para detectar cuando en el calendario se cambia de mes clicando en el menú, si eso
    //sucede, no te debe dejar, por lo que corregimos la fecha
    if (document.getElementById("calendario-title-0").innerText == document.getElementById("calendario-title-1").innerText) {
      //es el mismo mes, hay que corregirlos
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
      this.cdRef.detectChanges(); //Esto lo ponemos para indicarle a angular que se ha hecho un cambio y evitar errores
    }
  }

  //FUNCIONES DE CAMBIO EN EL FILTRO
  //Funciones del filtro para subir los elementos cuando se seleccione algo en ellos (los saca del div no seleccionados y los mete en el de seleccionados)
  //Incluir funcion comprobarOpcionesObligatorias() para las obligatorias en los change
  //Obligatorios
  ofsChange() {
    if (this.ofsSeleccionados.length > 0) { //si hay elementos insertar el tag
      var textoAuxi = "";
      if (this.ofsSeleccionados.length == 1){
        textoAuxi = this.ofsSeleccionados[0].nombreOf;
        
      }
      else if (this.ofsSeleccionados.length > 1) {
        textoAuxi = this.ofsSeleccionados.length + " " + this.translateService.instant('ofsSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "ofsLabel");
    } else { //sino quitarlo
      this.removeTag("ofsLabel");
    }
    //Este es obligatorio, por lo que siempre está arriba, pero lo quitamos y lo agregamos de nuevo para que con la animación se destaquen los cambios
    var elemento = document.getElementById("ofFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.restringirRestoDatos();
    this.comprobarOpcionesObligatorias();
    
  }

  piezasChange() {
    if (this.piezasSeleccionadas.length > 0) { //si hay elementos insertar el tag
      var textoAuxi = "";
      if (this.piezasSeleccionadas.length == 1)
        textoAuxi = this.piezasSeleccionadas[0].nombrePieza;
      else if (this.piezasSeleccionadas.length > 1) {
        textoAuxi = this.piezasSeleccionadas.length + " " + this.translateService.instant('piezasSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "piezasLabel");
    } else { //sino quitarlo
      this.removeTag("piezasLabel");
    }
    //Este es obligatorio, por lo que siempre está arriba, pero lo quitamos y lo agregamos de nuevo para que con la animación se destaquen los cambios
    var elemento = document.getElementById("piezaFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.restringirRestoDatos();
    this.comprobarOpcionesObligatorias();
  }

  partesChange() {
    if (this.partesSeleccionadas.length > 0) { //si hay elementos insertar el tag
      var textoAuxi = "";
      if (this.partesSeleccionadas.length == 1)
        textoAuxi = this.partesSeleccionadas[0].nombreParte;
      else if (this.partesSeleccionadas.length > 1) {
        textoAuxi = this.partesSeleccionadas.length + " " + this.translateService.instant('partesSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "partesLabel");
    } else { //sino quitarlo
      this.removeTag("partesLabel");
    }
    //Este es obligatorio, por lo que siempre está arriba, pero lo quitamos y lo agregamos de nuevo para que con la animación se destaquen los cambios
    var elemento = document.getElementById("parteFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.restringirRestoDatos();
    this.comprobarOpcionesObligatorias();
  }

  seccionesChange() {
    if (this.seccionesSeleccionadas.length > 0) { //si tiene alguna escogida
      //se agrega el tag
      var textoAuxi = "";
      if (this.seccionesSeleccionadas.length == 1)
        textoAuxi = this.seccionesSeleccionadas[0].nombre;
      else if (this.seccionesSeleccionadas.length > 1) {
        textoAuxi = this.seccionesSeleccionadas.length + " " + this.translateService.instant('seccionesSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "seccionesLabel");
    } else {  //se elimina el tag
      this.removeTag("seccionesLabel");
    }
    var elemento = document.getElementById("seccionesFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.restringirRestoDatos();
  }

  procesosChange() {
    if (this.procesosSeleccionados.length > 0) { //si tiene alguno escogido, pasa a arriba
      //se agrega el tag
      var textoAuxi = "";
      if (this.procesosSeleccionados.length == 1)
        textoAuxi = this.procesosSeleccionados[0].nombreProceso;
      else if (this.procesosSeleccionados.length > 1) {
        textoAuxi = this.procesosSeleccionados.length + " " + this.translateService.instant('procesosSeleccionados');
      }
      this.incluirEnTag(textoAuxi, "procesosLabel");
    } else { //sino
      //se elimina el tag
      this.removeTag("procesosLabel");
    }
    this.comprobarOpcionesObligatorias();
  }

  //Opcionales
  //Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  public valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if (event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.periodoActualSeleccionado.length == 1) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.periodoActualSeleccionado = [];
      } else {
        if (this.periodoActualSeleccionado.length == 0) {
          this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
        }
        else if (this.periodoActualSeleccionado.length == 1) {
          this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
          //Limpiamos las fechas
          this.periodoActualSeleccionado[0].setHours(0);
          this.periodoActualSeleccionado[0].setMinutes(0);
          this.periodoActualSeleccionado[0].setSeconds(0);
          var auxi1 = this.periodoActualSeleccionado[0];
          this.periodoActualSeleccionado[1].setHours(0);
          this.periodoActualSeleccionado[1].setMinutes(0);
          this.periodoActualSeleccionado[1].setSeconds(0);
          var auxi2 = this.periodoActualSeleccionado[1];
          if (auxi1 > auxi2) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            this.periodoActualSeleccionado = [auxi2, auxi1];
          }
          this.incluirEnTag(this.intl.formatDate(this.periodoActualSeleccionado[0]) + "-" + this.intl.formatDate(this.periodoActualSeleccionado[1]), "fechasLabel");
          this.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          var elemento = document.getElementById("fechasFiltro");
          elemento.parentElement.removeChild(elemento);
          document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
          this.colocarCalendario();
        } else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.removeTag("fechasLabel");
          this.periodoActualSeleccionado = []; //hay que vaciarlo primero, pues si ya tenía dos es una selección nueva
          this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
        }

      }
    }
  }
  rutasChange() {
    if (this.rutasSeleccionadas.length > 0) { //si tiene alguna escogida, pasa a arriba
      var elemento = document.getElementById("rutasFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
      //y se agrega el tag
      var textoAuxi = "";
      if (this.operacionesSeleccionadas.length == 1)
        textoAuxi = this.operacionesSeleccionadas[0].nombreRuta;
      else if (this.rutasSeleccionadas.length > 1) {
        textoAuxi = this.rutasSeleccionadas.length + " " + this.translateService.instant('rutasSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "rutasLabel");
    } else { //sino vuelve a abajo
      var elemento = document.getElementById("rutasFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
      //y se elimina el tag
      this.removeTag("rutasLabel");
    }
    this.restringirRestoDatos();
  }

  operacionesChange() {
    if (this.operacionesSeleccionadas.length > 0) { //si tiene alguno escogido, pasa a arriba
      var elemento = document.getElementById("operacionesFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
      //y se agrega el tag
      var textoAuxi = "";
      if (this.operacionesSeleccionadas.length == 1)
        textoAuxi = this.operacionesSeleccionadas[0].nombreOperacion;
      else if (this.operacionesSeleccionadas.length > 1) {
        textoAuxi = this.operacionesSeleccionadas.length + " " + this.translateService.instant('operacionesSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "operacionesLabel");
    } else { //sino vuelve a abajo
      var elemento = document.getElementById("operacionesFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
      //y se elimina el tag
      this.removeTag("operacionesLabel");
    }
    this.restringirRestoDatos();
  }

  desvioChange() { //En este en específico si se vuelve a seleccionar false vuelve a ponerlo abajo, porque no es relevante
    if (this.soloOperacionesDesvio) { //si es true
      //va para arriba
      var elemento = document.getElementById("desvioFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
      //y se incluye el tag
      this.incluirEnTag("soloOperacionesDesvio", "operacionesDesvioLabel");
    } else {//si es false
      //vuelve a abajo
      var elemento = document.getElementById("desvioFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
      //y se elimina el tag
      this.removeTag("operacionesDesvioLabel");
    }

  }

  graficoChange(event) { //En este en específico si se vuelve a seleccionar el base vuelve a ponerlo abajo, porque no es relevante
    //en este tipo de elementos hay que usar el event para saber el cambio, ya que el seleccionados se actualiza despues
    if (event != this.listaGraficos[0]) {//si NO se ha seleccionado el de por defecto
      //sube a arriba
      var elemento = document.getElementById("graficoReferenciaFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
      //y se incluye el tag
      this.incluirEnTag(event.nombre, "graficoLabel");
    } else { //si se ha seleccionado el de por defecto
      //vuelve a abajo
      var elemento = document.getElementById("graficoReferenciaFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
      //y se elimina el tag
      this.removeTag("graficoLabel");
    }
  }

  maquinasChange() {
    if (this.maquinasSeleccionadas.length > 0) { //si tiene alguna escogido, pasa a arriba
      var elemento = document.getElementById("maquinasFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
      //y se agrega el tag
      var textoAuxi = "";
      if (this.maquinasSeleccionadas.length == 1)
        textoAuxi = this.maquinasSeleccionadas[0].nombre;
      else if (this.maquinasSeleccionadas.length > 1) {
        textoAuxi = this.maquinasSeleccionadas.length + " " + this.translateService.instant('maquinasSeleccionadas');
      }
      this.incluirEnTag(textoAuxi, "maquinasLabel");
    } else { //sino vuelve a abajo
      var elemento = document.getElementById("maquinasFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
      //y se elimina el tag
      this.removeTag("maquinasLabel");
    }
    this.restringirRestoDatos();
  }

  gruposChange() {
    if (this.gruposSeleccionados.length > 0) { //si tiene alguno escogido, pasa a arriba
      var elemento = document.getElementById("gruposFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
      //y se agrega el tag
      var textoAuxi = "";
      if (this.gruposSeleccionados.length == 1)
        textoAuxi = this.gruposSeleccionados[0].nombre;
      else if (this.gruposSeleccionados.length > 1) {
        textoAuxi = this.gruposSeleccionados.length + " " + this.translateService.instant('gruposSeleccionados');
      }
      this.incluirEnTag(textoAuxi, "gruposLabel");
    } else { //sino vuelve a abajo
      var elemento = document.getElementById("gruposFiltro");
      elemento.parentElement.removeChild(elemento);
      document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento);
      //y se elimina el tag
      this.removeTag("gruposLabel");
    }
    this.restringirRestoDatos();
  }


  restringirRestoDatos() { //Función auxiliar para que cuando se seleccione un of,pieza, parte, ruta, operacion, 
    //se restrinjan el resto de datos a solo poder seleccionar los asociados a este
    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(of => idsPiezas.push(of.idPieza));

    var idsPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(of => idsPartes.push(of.idParte));

    var idsRutas = [];
    if (this.rutasSeleccionadas != undefined)
      this.rutasSeleccionadas.forEach(of => idsRutas.push(of.idRuta));

    var idsOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(of => idsOperaciones.push(of.idOperacion));

    var groupByOf = [];
    var groupByPieza = [];
    var groupByParte = [];
    var groupByRuta = [];
    var groupByOperacion = [];


    //GROUP POR OF
    var lag: any = {};
    this.data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
          && (idsPartes.includes(row.idParte) || idsPartes[0] == undefined)
          && (idsRutas.includes(row.idRuta) || idsRutas[0] == undefined)
          && (idsOperaciones.includes(row.idOperacion) || idsOperaciones[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1 && item.idOf != null));
    this.listaOfs = this.listaOfs.filter(item => (item.nombreOf != ""));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP POR PIEZA
    var lag: any = {};
    this.data.forEach(
      row => {
        if (!lag[row.idPieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsPartes.includes(row.idParte) || idsPartes[0] == undefined)
          && (idsRutas.includes(row.idRuta) || idsRutas[0] == undefined)
          && (idsOperaciones.includes(row.idOperacion) || idsOperaciones[0] == undefined)
        ) {
          lag[row.idPieza] = { idPieza: row.idPieza, nombrePieza: row.nombrePieza };
          groupByPieza.push(lag[row.idPieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1 && item.idPieza!=null));
    this.listaPiezas = this.listaPiezas.filter(item => (item.nombrePieza != ""));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    //GROUP POR PARTE
    var lag: any = {};
    this.data.forEach(
      row => {
        if (!lag[row.idParte]
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsRutas.includes(row.idRuta) || idsRutas[0] == undefined)
          && (idsOperaciones.includes(row.idOperacion) || idsOperaciones[0] == undefined)
        ) {
          lag[row.idParte] = { idParte: row.idParte, nombreParte: row.nombreParte };
          groupByParte.push(lag[row.idParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.idParte!=null));
    this.listaPartes = this.listaPartes.filter(item => (item.nombreParte != ""));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

    //GROUP POR RUTA
    var lag: any = {};
    this.data.forEach(
      row => {
        if (!lag[row.idRuta]
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
          && (idsPartes.includes(row.idParte) || idsPartes[0] == undefined)
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsOperaciones.includes(row.idOperacion) || idsOperaciones[0] == undefined)
        ) {
          lag[row.idRuta] = { idRuta: row.idRuta, nombreRuta: row.nombreRuta };
          groupByRuta.push(lag[row.idRuta]);
        }
      });

    this.listaRutas = groupByRuta.filter(item => (item.idRuta != -1 && item.idRuta!=-1));
    this.listaRutas = this.listaRutas.filter(item => (item.nombreRuta != ""));
    this.listaRutas.sort((a, b) => (a.nombreRuta > b.nombreRuta) ? 1 : ((b.nombreRuta > a.nombreRuta) ? -1 : 0));

    //GROUP POR OPERACION
    var lag: any = {};
    this.data.forEach(
      row => {
        if (!lag[row.idOperacion]
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
          && (idsPartes.includes(row.idParte) || idsPartes[0] == undefined)
          && (idsRutas.includes(row.idRuta) || idsRutas[0] == undefined)
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
        ) {
          lag[row.idOperacion] = { idOperacion: row.idOperacion, nombreOperacion: row.nombreOperacion };
          groupByOperacion.push(lag[row.idOperacion]);
        }
      });

    this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1 && item.idOperacion!=null));
    this.listaOperaciones = this.listaOperaciones.filter(item => (item.nombreOperacion != ""));
    this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    //MAQUINAS
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }

    this.listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    this.listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });
  }

  //FUNCIONES DEL CALENDARIO DEL FILTRO

  //Función para que el calendario se muestre o no
  showCalendar() {
    this.mostrarCalendario = !this.mostrarCalendario;
    this.colocarCalendario();
  }

  //Función para inicializar los meses del calendario
  cargarMeses() {
    var year = (new Date()).getFullYear();
    var currentMonth = (new Date()).getMonth();
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(new Date());
    this.DaysInMonths.push(new Date(year, currentMonth + 1, 1));
  }

  //Función para pintar del color adecuado el periodo seleccionado
  public isDateSelected(date: Date): boolean {
    if ((this.periodoActualSeleccionado[0] != null && this.periodoActualSeleccionado[0] != undefined) &&
     (this.periodoActualSeleccionado[1] != null && this.periodoActualSeleccionado[1] != undefined)) {
      if (this.periodoActualSeleccionado.length == 0) {
        return false;
      } else if (this.periodoActualSeleccionado.length == 1) {
        if ((date.getDate() == this.periodoActualSeleccionado[0].getDate() &&
          date.getMonth() == this.periodoActualSeleccionado[0].getMonth() &&
          date.getFullYear() == this.periodoActualSeleccionado[0].getFullYear())) {
          return true;
        } else {
          return false;
        }
      }
      else if (this.periodoActualSeleccionado.length == 2) {
        //primero hacemos limpia con las fechas por si se queda basura o cambia el gtm
        var auxFecha1 = new Date(this.periodoActualSeleccionado[0].getFullYear(), this.periodoActualSeleccionado[0].getMonth(), this.periodoActualSeleccionado[0].getDate());
        var auxFecha2 = new Date(this.periodoActualSeleccionado[1].getFullYear(), this.periodoActualSeleccionado[1].getMonth(), this.periodoActualSeleccionado[1].getDate());
        var auxFechaActual = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (auxFechaActual >= auxFecha1 && auxFechaActual <= auxFecha2) {
          return true;
        }
        else {
          return false;
        }
      }
    }

  };

  //Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
      (document.getElementById("calendario-1").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
      (document.getElementById("calendario-1").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }

  //Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton() {
    this.periodoActualSeleccionado = []; //hay que vaciarlo primero
    var auxDate = new Date();
    auxDate.setDate(auxDate.getDate() - 1);//le ponemos la fecha de ayer
    this.periodoActualSeleccionado.push(auxDate);
    this.periodoActualSeleccionado.push(auxDate);
    this.incluirEnTag("ultimas24H", "fechasLabel");
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    //Y que lo añada com que ya se ha cambiado la fecha
    var elemento = document.getElementById("fechasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.colocarCalendario();
  }

  ultimos7DiasButton() {
    this.periodoActualSeleccionado = []; //hay que vaciarlo primero
    var today = new Date();
    var previousweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6);
    this.periodoActualSeleccionado.push(previousweek);
    this.periodoActualSeleccionado.push(today);
    this.incluirEnTag("ultimos7Dias", "fechasLabel");
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    //Y que lo añada com que ya se ha cambiado la fecha
    var elemento = document.getElementById("fechasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.colocarCalendario();
  }

  ultimos30DiasButton() {
    this.periodoActualSeleccionado = []; //hay que vaciarlo primero
    var today = new Date();
    var previousweek = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
    this.periodoActualSeleccionado.push(previousweek);
    this.periodoActualSeleccionado.push(today);
    this.incluirEnTag("ultimos30Dias", "fechasLabel");
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    //Y que lo añada com que ya se ha cambiado la fecha
    var elemento = document.getElementById("fechasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.colocarCalendario();
  }

  ultimos60DiasButton() {
    this.periodoActualSeleccionado = []; //hay que vaciarlo primero
    var today = new Date();
    var previousweek = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
    this.periodoActualSeleccionado.push(previousweek);
    this.periodoActualSeleccionado.push(today);
    this.incluirEnTag("ultimos60Dias", "fechasLabel");
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    //Y que lo añada com que ya se ha cambiado la fecha
    var elemento = document.getElementById("fechasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.colocarCalendario();
  }

  ultimos90DiasButton() {
    this.periodoActualSeleccionado = []; //hay que vaciarlo primero
    var today = new Date();
    var previousweek = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
    this.periodoActualSeleccionado.push(previousweek);
    this.periodoActualSeleccionado.push(today);
    this.incluirEnTag("ultimos90Dias", "fechasLabel");
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    //Y que lo añada como que ya se ha cambiado la fecha
    var elemento = document.getElementById("fechasFiltro");
    elemento.parentElement.removeChild(elemento);
    document.getElementsByClassName("filtro-seleccionados")[0].append(elemento);
    this.colocarCalendario();

  }

  //FUNCIONES ETIQUETAS FILTRO
  //Función para incluir los tags en el card de etiquetas, se les pone un id cuando se añaden para poder eleminarlos y así actualizarlos
  incluirEnTag(textoAIncluir, elemento) {//Al inicio borramos el previo que hubiese
    var elementoTags = document.getElementById("tagsFiltro");
    var elementoABorrar = document.getElementById(elemento);
    if (elementoABorrar != null)
      elementoTags.removeChild(elementoABorrar);
    var textoTraducido = this.translateService.instant(textoAIncluir);
    //Y creamos e incluimos el nuevo
    var elementoAuxi = "<div class='tags-filtro' id='" + elemento + "'><label class='tags-filtro-label'>" + textoTraducido + "</label><i class='fas fa-times' style='cursor:pointer'></i></div>";
    elementoTags.innerHTML = elementoTags.innerHTML + elementoAuxi;
    //Ahora hay que darle la función al elemento agregado para que cuando se pulse en la x se quite la etiqueta
    var elementoCreado = document.getElementById(elemento);
    var listaHijos = elementoCreado.children;
    var that = this;
    for (var i = 0; i < listaHijos.length; i++) {
      if (listaHijos[i].tagName == "I") {
        listaHijos[i].addEventListener("click", function () {
          var elementoABorrar = document.getElementById(elemento);
          if (elementoABorrar != null) {
            elementoTags.removeChild(elementoABorrar);
            switch (elemento) {
              case "fechasLabel":
                that.periodoActualSeleccionado = [];
                break;
              case "ofsLabel":
                that.ofsSeleccionados = [];
                break;
              case "piezasLabel":
                that.piezasSeleccionadas = [];
                break;
              case "partesLabel":
                that.partesSeleccionadas = [];
                break;
              case "procesosLabel":
                that.procesosSeleccionados = [];
                break;
              //Todos estos son obligatorios, por lo que además de vaciar la tabla hay que bajarlos
              case "rutasLabel":
                that.operacionesSeleccionadas = [];
                var elemento2 = document.getElementById("rutasFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              case "operacionesLabel":
                that.operacionesSeleccionadas = [];
                var elemento2 = document.getElementById("operacionesFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              case "operacionesDesvioLabel":
                that.soloOperacionesDesvio = false;
                var elemento2 = document.getElementById("desvioFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              case "graficoLabel":
                that.graficoSeleccionado = that.listaGraficos[0];
                var elemento2 = document.getElementById("graficoReferenciaFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              case "maquinasLabel":
                that.maquinasSeleccionadas = [];
                var elemento2 = document.getElementById("maquinasFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              case "gruposLabel":
                that.gruposSeleccionados = [];
                var elemento2 = document.getElementById("gruposFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              case "seccionesLabel":
                that.seccionesSeleccionadas = [];
                var elemento2 = document.getElementById("seccionesFiltro");
                elemento2.parentElement.removeChild(elemento2);
                document.getElementsByClassName("filtro-no-seleccionados")[0].append(elemento2);
                break;
              default:
                break;
            }
            that.restringirRestoDatos();
          }

        });
      }
    }
  }

  //Función para borrar los tags cuando se vacía un elemento seleccionado
  removeTag(elemento) {
    var elementoTags = document.getElementById("tagsFiltro");
    var elementoABorrar = document.getElementById(elemento);
    if (elementoABorrar != null)
      elementoTags.removeChild(elementoABorrar);
  }

  //FUNCIONES DE LA GRÁFICA
  //Función para crear la gráfica
  crearGrafica() {
    var that = this; //Para ayudarnos en el interior de las funciones
    var nSeries = []; //Aquí se guardarán los números de serie
    var nSeriesMap = new Map();
    var operaciones = []; //aquí guardaremos las operaciones
    var operacionesMap = new Map();
    var valoresPorNserie = []; //aquí se guardarán los valores de las operaciones por cada número de serie
    var valoresMarraLine = []; //aquí se guardarán los valores de la marra
    var valoresMarra = []; //aquí se guardarán los valores de la marra
    var tiemposEstimadosPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie
    var tiemposPredictivosPorNserie = []; //aquí se guardarán los tiemposPredictivos (para el tooltip) de las operaciones por cada número de serie
    var tiemposEjecucionPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie
    var tiemposAlarmaPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie
    var tiemposParadaPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie
    var tiemposMicroParadaPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie
    var tiemposApagadoPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie
    var tiemposMantenimientoPorNserie = []; //aquí se guardarán los tiemposEstimados (para el tooltip) de las operaciones por cada número de serie

    // el nSerie siempre necesita una 'x' al principio porque la barra x la definimos como 'x'
    nSeries.push('x');

    //Utilizaremos datos extra donde se han guardado las variables usadas al filtrar, solo en caso de que mientras se filtraba el usuario las hubiese cambiado
    if (this.datosFiltroExtra.graficaSeleccionada != 1){ //el 1 es ninguno
      nSeries.push(this.translateService.instant('referencia')); //La primera columna es un grafico de referencia
    }

    //vamos a mirar las dimensiones de los numeros de serie y las Operaciones antes de empezar con los valores   
    //creamos un Array de numeros de serie por orden de FECHAINI
    var indexnSeries = 0;
    var indexOperaciones = 0;
    this.datosFiltro.forEach(
      (element) => {
        if (!nSeries.includes(element.idHistorico_piezas)){
          nSeries.push(element.idHistorico_piezas);
          indexnSeries++;
          nSeriesMap.set(element.idHistorico_piezas, {nSerie: element.nSerie, index: indexnSeries});
        }
        //creamos un Array de operaciones por orden de SECUENCIA
        if (!operaciones.includes(element.idOperacion)) {
          operaciones.push(element.idOperacion);
          operacionesMap.set(element.idOperacion, {nombreOperacion: element.nombreOperacion, index: indexOperaciones});
          indexOperaciones++;
        }
    });
    
    nSeries = Array.from(nSeriesMap.values());
    nSeries = nSeries.map(f => f.nSerie);
    nSeries = ['x', ...nSeries];

    operaciones = Array.from(operacionesMap.values()).map(f => f.nombreOperacion);
    //la primera linea de datos son los números de serie
    valoresPorNserie.push(nSeries);
    tiemposEstimadosPorNserie.push(nSeries);
    tiemposPredictivosPorNserie.push(nSeries);
    tiemposEjecucionPorNserie.push(nSeries);
    tiemposAlarmaPorNserie.push(nSeries);
    tiemposParadaPorNserie.push(nSeries);
    tiemposMicroParadaPorNserie.push(nSeries);
    tiemposApagadoPorNserie.push(nSeries);
    tiemposMantenimientoPorNserie.push(nSeries);

    //creamos la tabla de las dimensiones deseadas    
    operaciones.forEach(
      (operacion) => {
        var a = [], b = [], c = [], d = [], e = [], f = [], g = [], h = [], j = []; //necesitamos crear uno por cada array
        //el primer valor sera el nombre de la operacion
        a.push(operacion);
        b.push(operacion);
        c.push(operacion);
        d.push(operacion);
        e.push(operacion);
        f.push(operacion);
        g.push(operacion);
        h.push(operacion);
        j.push(operacion);
        //el resto seran los tiempos totales (que cargaremos a 0 por ahora)
        var i = 0;
        nSeries.forEach(
          (nSeries) => {
            if (this.datosFiltroExtra.graficaSeleccionada != 1) { //el 1 es ninguno
              if (i < 2) {
                i++; //el primero es la x, por lo que no hay que añadir el cero //y el segundo es la predefinida
              } else {
                a.push(0);
                b.push(0);
                c.push(0);
                d.push(0);
                e.push(0);
                f.push(0);
                g.push(0);
                h.push(0);
                j.push(0);
              }
            } else {
              if (i < 1) {
                i++; //el primero es la x, por lo que no hay que añadir el cero //y el segundo es la predefinida
              } else {
                a.push(0);
                b.push(0);
                c.push(0);
                d.push(0);
                e.push(0);
                f.push(0);
                g.push(0);
                h.push(0);
                j.push(0);
              }
            }

          });
        valoresPorNserie.push(a);
        tiemposEstimadosPorNserie.push(b);
        tiemposEjecucionPorNserie.push(c);
        tiemposAlarmaPorNserie.push(d);
        tiemposParadaPorNserie.push(e);
        tiemposMicroParadaPorNserie.push(f);
        tiemposApagadoPorNserie.push(g);
        tiemposMantenimientoPorNserie.push(h);
        tiemposPredictivosPorNserie.push(j);
      });
    
    //damos el valor que corresponde a cada celda de la matriz
    this.datosFiltro.forEach(
      (element) => {
        var x = operacionesMap.get(element.idOperacion).index + 1;
        // var y = nSeries.indexOf(element.idHistorico_piezas);
        var y = nSeriesMap.get(element.idHistorico_piezas).index;
        if (valoresPorNserie[x] != undefined) {
          if (this.datosFiltroExtra.graficaSeleccionada == 2) {
            if (valoresPorNserie[x][1] == 0) {
              valoresPorNserie[x][1] = element.tiempoEstimado;
            }
          } else if (this.datosFiltroExtra.graficaSeleccionada == 3) {
            if (valoresPorNserie[x][1] == 0) {
              valoresPorNserie[x][1] = element.tiempoPredictivo;
            }
          }

          valoresPorNserie[x][y] = element.tiemposAcumuladosTotales;
          tiemposEstimadosPorNserie[x][y] = element.tiempoEstimado;
          tiemposPredictivosPorNserie[x][y] = element.tiempoPredictivo;

          if (element.cantidad != 0) {
            tiemposEjecucionPorNserie[x][y] = element.tiempoAcumuladoEjecucion / element.cantidad;
            tiemposAlarmaPorNserie[x][y] = element.tiempoAcumuladoAlarma / element.cantidad;
            tiemposParadaPorNserie[x][y] = element.tiempoAcumuladoParada / element.cantidad;
            tiemposMicroParadaPorNserie[x][y] = element.tiempoAcumuladoMicroParada / element.cantidad;
            tiemposApagadoPorNserie[x][y] = element.tiempoAcumuladoApagado / element.cantidad;
            tiemposMantenimientoPorNserie[x][y] = element.tiempoAcumuladoMantenimiento / element.cantidad;
          } else {
            tiemposEjecucionPorNserie[x][y] = element.tiempoAcumuladoEjecucion;
            tiemposAlarmaPorNserie[x][y] = element.tiempoAcumuladoAlarma;
            tiemposParadaPorNserie[x][y] = element.tiempoAcumuladoParada;
            tiemposMicroParadaPorNserie[x][y] = element.tiempoAcumuladoMicroParada;
            tiemposApagadoPorNserie[x][y] = element.tiempoAcumuladoApagado;
            tiemposMantenimientoPorNserie[x][y] = element.tiempoAcumuladoMantenimiento;
          }
        }
      });
    
    //Ahora filtramos la matriz para quitarle los numeros de serie que tengan ceros en todas sus operaciones
    var numAuxi = 1;
    if (this.datosFiltroExtra.graficaSeleccionada != 1)
      numAuxi = 2;
    for (var columns = numAuxi; columns < nSeries.length + 1; columns++) {
      var sum = 0;
      //Hay que mirar si todas las filas de esa misma columna tienen como valor un cero, y entonces se quita
      for (var rows = 1; rows < operaciones.length + 1; rows++) {
        sum += valoresPorNserie[rows][columns];
      }
      //Al salir del for interior tendremos la suma de los valores de una columna, comprobamos si es cero
      if (sum == 0) {//hay que borrarla
        for (var rows = 0; rows < operaciones.length + 1; rows++) {
          valoresPorNserie[rows].splice(columns, 1);
        }
      }
    }
    if(this.datosFiltroExtra.graficaSeleccionada != undefined &&  this.datosFiltroExtra.graficaSeleccionada!= 1){

      // insertar en la primera linea el nombre de t.referencia
      if (valoresPorNserie[0][1] == '') valoresPorNserie[0][1] = ' '
      valoresPorNserie[0] = [
                            ...valoresPorNserie[0].slice(0, 1)
                            , this.translateService.instant("t.referencia")
                            , ...valoresPorNserie[0].slice(1)
                          ];

      valoresMarra.push('t.referenciaLine');
      valoresMarraLine.push(this.translateService.instant("t.referencia"));
      var tiempoTotal= 0;
      for(var i=1; i<valoresPorNserie.length; i++){
        tiempoTotal += valoresPorNserie[i][1]; //ahí esta el tiempo estimado o predictivo

        // Esto es necesario para que en los graficos que no sean t.referencia no salga la rederencia
        valoresPorNserie[i] = [...valoresPorNserie[i].slice(0, 1), 0, ...valoresPorNserie[i].slice(1)];
        tiemposEstimadosPorNserie[i] = [...tiemposEstimadosPorNserie[i].slice(0,1), 0, ...tiemposEstimadosPorNserie[i].slice(1)];
        tiemposPredictivosPorNserie[i] = [...tiemposPredictivosPorNserie[i].slice(0,1), 0, ...tiemposPredictivosPorNserie[i].slice(1)];
        tiemposEjecucionPorNserie[i] = [...tiemposEjecucionPorNserie[i].slice(0,1), 0, ...tiemposEjecucionPorNserie[i].slice(1)];
        tiemposAlarmaPorNserie[i] = [...tiemposAlarmaPorNserie[i].slice(0,1), 0, ...tiemposAlarmaPorNserie[i].slice(1)];
        tiemposParadaPorNserie[i] = [...tiemposParadaPorNserie[i].slice(0,1), 0, ...tiemposParadaPorNserie[i].slice(1)];
        tiemposMicroParadaPorNserie[i] = [...tiemposMicroParadaPorNserie[i].slice(0,1), 0, ...tiemposMicroParadaPorNserie[i].slice(1)];
        tiemposApagadoPorNserie[i] = [...tiemposApagadoPorNserie[i].slice(0,1), 0, ...tiemposApagadoPorNserie[i].slice(1)];
        tiemposMantenimientoPorNserie[i] = [...tiemposMantenimientoPorNserie[i].slice(0,1), 0, ...tiemposMantenimientoPorNserie[i].slice(1)];
      }

      valoresMarra.push(tiempoTotal);
      valoresMarraLine.push(tiempoTotal);
      for(var i=1; i<valoresPorNserie[0].length-1; i++){
        valoresMarraLine.push(0);
        valoresMarra.push(tiempoTotal);
      }

      valoresPorNserie = [
        ...valoresPorNserie.slice(0, 1)
        , valoresMarraLine
        , valoresMarra
        , ...valoresPorNserie.slice(1)
      ];
      operaciones.push(this.translateService.instant("t.referencia"));
    }

    // Esto es necesario para que cuando no se trabaje con nSeries se visualicen los datos
    if (valoresPorNserie[0][1] == '') valoresPorNserie[0][1] = ' ';

    //una vez tenemos el formato correcto de los datos, creamos la gráfica
    this.graficaHistograma = c3.generate({
      bindto: '#grafica-histograma',
      data: {
        x: 'x',
        columns: valoresPorNserie,
        type: 'bar',
        types: {
          't.referenciaLine': 'line',
        },
        names: {
          't.referenciaLine': this.translateService.instant("t.referencia")
        },
        groups: [
          operaciones
        ],
        order: 'asc' // stack order by sum of values descendantly.
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            rotate: -45,
            multiline: false
          }
        },
        y: {
          tick: {
            format: function (d) { return that.myFunctions.secondsTo_HH_MM_SS(d); }
          }
        }
      },
      tooltip: {
        grouped: false,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if(d[0].id!="t.referenciaLine"){
              if (that.datosFiltroExtra.graficaSeleccionada == 2) {
              //se ha seleccionado un gráfico de referencia
              if (d[0].index == 0) {
                //el primero es el de referencia, solo debe mostrar el tiempo estimado o predictivo
                return "<table class='c3-tooltip'><tbody>" +
                  "<tr><th colspan='2'>" + d[0].name + "</th></tr>" +
                  "<tr><td class='name'>" + that.translateService.instant("TEstimado") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(d[0].value) + "</td></tr>" +
                  "</tbody></table>";
              }
            } else if (that.datosFiltroExtra.graficaSeleccionada == 3) {
              //se ha seleccionado un gráfico de referencia
              if (d[0].index == 0) {
                //el primero es el de referencia, solo debe mostrar el tiempo estimado o predictivo
                return "<table class='c3-tooltip'><tbody>" +
                  "<tr><th colspan='2'>" + d[0].name + "</th></tr>" +
                  "<tr><td class='name'>" + that.translateService.instant("TPredictivo") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(d[0].value) + "</td></tr>" +
                  "</tbody></table>";
              }
            }
            var x = operaciones.indexOf(d[0].name) + 1;
            //falta la operacion
            var y = d[0].index + 1;//le sumamos 1 porque el primero que es x no cuenta

            var tiemposRealesTotal = 0;
            var tiemposEstimadosTotales = 0;
            var tiemposPredictivosTotales = 0;
            for (var j = 1; j < operaciones.length; j++) {
              tiemposRealesTotal += valoresPorNserie[j][y];
              tiemposEstimadosTotales += tiemposEstimadosPorNserie[j][y];
              tiemposPredictivosTotales += tiemposPredictivosPorNserie[j][y];
            }

            var auxiString1 = "<table class='c3-tooltip'><tbody>";
            auxiString1 += "<tr><th colspan='2'>" + that.datosFiltroExtra.piezaSeleccionada[0].nombrePieza + "</th></tr>";
            auxiString1 += "<tr><td class='name'>" + that.translateService.instant("TEstimado") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposEstimadosTotales) + "</td></tr>";
            auxiString1 += "<tr><td class='name'>" + that.translateService.instant("TPredictivo") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposPredictivosTotales) + "</td></tr>";
            auxiString1 += "<tr><td class='name'>" + that.translateService.instant("TReal") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposRealesTotal) + "</td></tr>";
            auxiString1 += "</tbody></table>";

            var auxiString = auxiString1 + "<table class='c3-tooltip'><tbody>";
            auxiString += "<tr><th colspan='2'>" + d[0].name + "</th></tr>";
            auxiString += "<tr><td class='name'>" + that.translateService.instant("TEstimado") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposEstimadosPorNserie[x][y]) + "</td></tr>";
            auxiString += "<tr><td class='name'>" + that.translateService.instant("TPredictivo") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposPredictivosPorNserie[x][y]) + "</td></tr>";
            auxiString += "<tr><td class='name'>" + that.translateService.instant("TReal") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(d[0].value) + "</td></tr>";
            if (that.datosFiltroExtra.listaProcesos.includes("1"))
              auxiString += "<tr><td class='name'>" + that.translateService.instant("TEjecucion") + ":</td><td class='value'>  " + that.myFunctions.secondsTo_HH_MM_SS(tiemposEjecucionPorNserie[x][y]) + "</td></tr>";
            if (that.datosFiltroExtra.listaProcesos.includes("2"))
              auxiString += "<tr><td class='name'>" + that.translateService.instant("TAlarma") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposAlarmaPorNserie[x][y]) + "</td></tr>";
            if (that.datosFiltroExtra.listaProcesos.includes("3"))
              auxiString += "<tr><td class='name'>" + that.translateService.instant("TParada") + ":</td><td class='value'>  " + that.myFunctions.secondsTo_HH_MM_SS(tiemposParadaPorNserie[x][y]) + "</td></tr>";
            if (that.datosFiltroExtra.listaProcesos.includes("4"))
              auxiString += "<tr><td class='name'>" + that.translateService.instant("TMicroparada") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposMicroParadaPorNserie[x][y]) + "</td></tr>";
            if (that.datosFiltroExtra.listaProcesos.includes("5"))
              auxiString += "<tr><td class='name'>" + that.translateService.instant("TApagado") + ":</td><td class='value'> " + that.myFunctions.secondsTo_HH_MM_SS(tiemposApagadoPorNserie[x][y]) + "</td></tr>";
            if (that.datosFiltroExtra.listaProcesos.includes("6"))
              auxiString += "<tr><td class='name'>" + that.translateService.instant("TMantenimiento") + ":</td><td class='value'>  " + that.myFunctions.secondsTo_HH_MM_SS(tiemposMantenimientoPorNserie[x][y]) + "</td></tr>";
            auxiString += "</tbody></table>";
            return auxiString;
          }else{
            return "";
          }
          
        }
      }
    });
    this.loading = true;
  }

}