import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/historicodatos`;

@Injectable()
export class HistoricoDatosService {

  constructor(private http: HttpClient) { }

  public Get_Ejes_Mecanizado(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Ejes_Mecanizado`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_datos_ejes_maquina(idmaquina: number, fechaIni: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_datos_ejes_maquina`, { idmaquina, fechaIni, fechaFin }, { withCredentials: true });
  }

  public Get_datos_ejes_maquina_filtro(consultaFiltro: string, fechaIni: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_datos_ejes_maquina_filtro`, { consultaFiltro, fechaIni }, { withCredentials: true });
  }

  public Get_datos_ejes_maquina_resumen(idmaquina: number, fechaIni: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_datos_ejes_maquina_resumen`, { idmaquina, fechaIni, fechaFin }, { withCredentials: true });
  }

  public Get_Generales_Extrusora(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Generales_Extrusora`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Campos_Extrusora(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Campos_Extrusora`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Historico_Extrusora(idMaquina, fechaInicio, fechaFin) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Extrusora`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public Get_Historico_Extrusora_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Extrusora_filtro`, { consultaFiltro }, { withCredentials: true });
  }

  public Get_Campos_ImpresoraPlastico(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Campos_ImpresoraPlastico`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Historico_ImpresorasPlastico(idMaquina, fechaInicio, fechaFin) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_ImpresorasPlastico`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public Get_Historico_ImpresorasPlastico_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_ImpresorasPlastico_filtro`, { consultaFiltro }, { withCredentials: true });
  }

  public Get_Campos_Inyectora(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Campos_Inyectora`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Historico_Inyectora(idMaquina, fechaInicio, fechaFin) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Inyectora`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public Get_Historico_Inyectora_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Inyectora_filtro`, { consultaFiltro }, { withCredentials: true });
  }

  public Get_Campos_Horno(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Campos_Horno`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Campos_HornosTemple(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Campos_HornosTemple`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Historico_Horno(idMaquina, fechaInicio, fechaFin) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Horno`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public Get_Historico_Horno_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Horno_filtro`, { consultaFiltro }, { withCredentials: true });
  }

  public Get_Historico_HornosTemple_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_HornosTemple_filtro`, { consultaFiltro }, { withCredentials: true });
  }

  public Get_Campos_Instalacion(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_Campos_Instalacion`, { idMaquina: idMaquina }, { withCredentials: true });
  }

  public Get_Historico_Instalaciones(idMaquina, fechaInicio, fechaFin, tieneHistoricoProcesos = true) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Instalaciones`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin, tieneHistoricoProcesos: tieneHistoricoProcesos }, { withCredentials: true });
  }

  public Get_Historico_Instalaciones_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Historico_Instalaciones_filtro`, { consultaFiltro }, { withCredentials: true });
  }

}
