import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/instalaciones`;

@Injectable()
export class InstalacionesService {

  constructor(private http: HttpClient) { }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  public GetById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetCampoPLCById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public insertInstalacion(nombre, idSeccion, orden, activo, tieneHistorico_Procesos, freq_captura_datos, ip, port, rack, slot, nombreArchivo, archivoBase64, abreviatura, dibujoMaquinaId) {

    if (orden == null) orden = 0;
    if (freq_captura_datos == null) freq_captura_datos = 0;
    if (tieneHistorico_Procesos == null) tieneHistorico_Procesos =0;

     return this.http.post<any>(baseUrl + "/insertInstalacion", {
       nombre: nombre, idSeccion: idSeccion, orden: orden, activo: activo, tieneHistorico_Procesos: tieneHistorico_Procesos,
       freq_captura_datos: freq_captura_datos, ip: ip, port: port, rack: rack, slot: slot, nombreArchivo: nombreArchivo, archivoBase64: archivoBase64, abreviatura: abreviatura, dibujoMaquinaId
     }, { withCredentials: true });

  }

  public updateInstalacion(id, nombre, idSeccion, orden, activo, tieneHistorico_Procesos, freq_captura_datos, ip, port, rack, slot, nombreArchivo, archivoBase64, abreviatura, dibujoMaquinaId) {

    if (orden == null) orden = 0;
    if (freq_captura_datos == null) freq_captura_datos = 0;
    if (tieneHistorico_Procesos == null) tieneHistorico_Procesos =0;

     return this.http.post<any>(baseUrl + "/updateInstalacion", {
       id: id, nombre: nombre, idSeccion: idSeccion, orden: orden, activo: activo, tieneHistorico_Procesos: tieneHistorico_Procesos,
       freq_captura_datos: freq_captura_datos, ip: ip, port: port, rack: rack, slot: slot, nombreArchivo: nombreArchivo, archivoBase64: archivoBase64, abreviatura: abreviatura, dibujoMaquinaId
     }, { withCredentials: true });

  }

  public deleteInstalacion(idsInstalaciones) {
    return this.http.post<any>(baseUrl + "/deleteInstalacion", { idsInstalaciones: idsInstalaciones }, { withCredentials: true });
  }

  public GetCamposById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/campos/" + id);
  }

  public GetCamposPLCById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/camposPLC/" + id);
  }

  public GetCamposPLCByCampoId(id: number): Observable<any> {
  return this.http.get(baseUrl + "/camposPLCbyId/" + id);
  }

  public Insert_Campos(dt) {
    return this.http.post<JSON>(`${baseUrl}/Insert_Campos`, { dt }, { withCredentials: true });
  }

  public Insert_CampoPLC( nombre: string,
          idInstalacion: number, idTipo_PLC_DAT: number, idTipoRegistro_PLC_DAT: number, idTipo_Dato_Campo_DAT: number, 
          idTipo_Campo_Instalacion: number, idTipo_Grafico: number, idTipo_Area_PLC: number, 
          posColumnaHistorico: number, orden: number, maximo: number, minimo: number,
          restarAnterior: boolean, activo: boolean, freq_captura_datos: number, 
          ipAddress_S7: string, port_S7: number, rack_S7: number, slot_S7: number,
          dbNumber_S7: number, start_S7: number, amount_S7: number, position_S7: number, size_S7: number,
          ipAddress_Modbus: string, port_Modbus: number, unitIdentifier_Modbus: number, 
          startingAddress_Modbus: number, quantity_Modbus: number, position_Modbus: number,
          port_Backnet: number, useExclusibePort_Backnet: boolean, dontFragment_Backnet: boolean, 
          maxPayload_Backnet: number, localEndpointIP_Backnet: string,
          lowLimit_Backnet: number, high_limit_Backnet: number, instance_Backnet: number, device_id_Backnet: number, idTipo_Grafico_Dato: number, idTipo_Unidad: number) 
      {
          if (orden == null) orden = 0;
          if (freq_captura_datos == null) freq_captura_datos = 0;
          if (maximo == null) maximo =0;
          if (minimo == null) minimo =0;
          if (restarAnterior == null) restarAnterior =false;
          if (activo == null) activo =false;
          return this.http.post<JSON>(`${baseUrl}/insertCampoPLC`, { nombre,
                    idInstalacion, idTipo_PLC_DAT, idTipoRegistro_PLC_DAT, idTipo_Dato_Campo_DAT,
                    idTipo_Campo_Instalacion, idTipo_Grafico, idTipo_Area_PLC, 
                    posColumnaHistorico, orden, maximo, minimo,
                    restarAnterior, activo, freq_captura_datos, 
                    ipAddress_S7, port_S7, rack_S7, slot_S7,
                    dbNumber_S7, start_S7, amount_S7, position_S7, size_S7,
                    ipAddress_Modbus, port_Modbus, unitIdentifier_Modbus, 
                    startingAddress_Modbus, quantity_Modbus, position_Modbus,
                    port_Backnet, useExclusibePort_Backnet, dontFragment_Backnet, 
                    maxPayload_Backnet, localEndpointIP_Backnet,
                    lowLimit_Backnet, high_limit_Backnet, instance_Backnet, device_id_Backnet, idTipo_Grafico_Dato, idTipo_Unidad
          }, { withCredentials: true });
}

  public Duplicate_CampoPLC(idsCampo) {
    return this.http.post<JSON>(`${baseUrl}/Duplicate_CamposPLC`, { idsCampo }, { withCredentials: true });
  }

  public Update_Campos(dt) {
    return this.http.post<JSON>(`${baseUrl}/Update_Campos`, { dt }, { withCredentials: true });
  }

  public Update_CampoPLC(id: number, nombre: string,
                idInstalacion: number, idTipo_PLC_DAT: number, idTipoRegistro_PLC_DAT: number, idTipo_Dato_Campo_DAT: number, 
                idTipo_Campo_Instalacion: number, idTipo_Grafico: number, idTipo_Area_PLC: number, 
                posColumnaHistorico: number, orden: number, maximo: number, minimo: number,
                restarAnterior: boolean, activo: boolean, freq_captura_datos: number, 
                ipAddress_S7: string, port_S7: number, rack_S7: number, slot_S7: number,
                 dbNumber_S7: number, start_S7: number, amount_S7: number, position_S7: number, size_S7: number,
                 ipAddress_Modbus: string, port_Modbus: number, unitIdentifier_Modbus: number, 
                startingAddress_Modbus: number, quantity_Modbus: number, position_Modbus: number,
                 port_Backnet: number, useExclusibePort_Backnet: boolean, dontFragment_Backnet: boolean, 
                 maxPayload_Backnet: number, localEndpointIP_Backnet: string,
                 lowLimit_Backnet: number, high_limit_Backnet: number, instance_Backnet: number, device_id_Backnet: number, idTipo_Grafico_Dato: number, idTipo_Unidad: number) 
        {
            if (orden == null) orden = 0;
            if (freq_captura_datos == null) freq_captura_datos = 0;
            if (maximo == null) maximo =0;
            if (minimo == null) minimo =0;
            if (restarAnterior == null) restarAnterior =false;
            if (activo == null) activo =false;
          return this.http.post<JSON>(`${baseUrl}/updateCampoPLC`, { id, nombre,
                      idInstalacion, idTipo_PLC_DAT, idTipoRegistro_PLC_DAT, idTipo_Dato_Campo_DAT,
                      idTipo_Campo_Instalacion, idTipo_Grafico, idTipo_Area_PLC, 
                      posColumnaHistorico, orden, maximo, minimo,
                      restarAnterior, activo, freq_captura_datos, 
                      ipAddress_S7, port_S7, rack_S7, slot_S7,
                      dbNumber_S7, start_S7, amount_S7, position_S7, size_S7,
                      ipAddress_Modbus, port_Modbus, unitIdentifier_Modbus, 
                      startingAddress_Modbus, quantity_Modbus, position_Modbus,
                      port_Backnet, useExclusibePort_Backnet, dontFragment_Backnet, 
                      maxPayload_Backnet, localEndpointIP_Backnet,
                      lowLimit_Backnet, high_limit_Backnet, instance_Backnet, device_id_Backnet, idTipo_Grafico_Dato, idTipo_Unidad
          }, { withCredentials: true });
  }

  public Delete_Campos(idInstalacion) {
    return this.http.post<JSON>(`${baseUrl}/Delete_Campos`, { idInstalacion: idInstalacion }, { withCredentials: true });
  }

  public Delete_CampoPLC(idsCampo) {
    return this.http.post<JSON>(`${baseUrl}/Delete_CampoPLC`, { idsCampo: idsCampo }, { withCredentials: true });
  }

  public Get_instalaciones_temporal() {
    return this.http.post<JSON>(`${baseUrl}/Get_instalaciones_temporal`, {}, { withCredentials: true });
  }

  public Get_instalaciones_temporal_byId(idInstalacion) {
    return this.http.post<JSON>(`${baseUrl}/Get_instalaciones_temporal_byId`, { idInstalacion: idInstalacion}, { withCredentials: true });
  }

  public Get_TiposCampos_All()
  {
    //return this.http.post<JSON>(`${baseUrl}/GetTiposCamposAll`);
    return this.http.get(baseUrl + "/GetTiposCamposAll");
  }

  public Get_TiposDatos_All()
  {
    return this.http.get(baseUrl + "/GetTiposDatosAll");
  }

  public Get_TiposGraficos_All()
  {
    //return this.http.post<JSON>(`${baseUrl}/GetTiposGraficosAll`, {}, { withCredentials: true });
    return this.http.get(baseUrl + "/GetTiposGraficosAll");
  }
  
  public Get_TiposPLC_All()
  {
    //return this.http.post<JSON>(`${baseUrl}/GetTiposGraficosAll`, {}, { withCredentials: true });
    return this.http.get(baseUrl + "/GetTiposPLCAll");
  }
  
  public Get_Areas_All()
  {
    //return this.http.post<JSON>(`${baseUrl}/GetTiposGraficosAll`, {}, { withCredentials: true });
    return this.http.get(baseUrl + "/GetAreasAll");
  }
  
  public Get_TiposRegistro_All()
  {
    //return this.http.post<JSON>(`${baseUrl}/GetTiposGraficosAll`, {}, { withCredentials: true });
    return this.http.get(baseUrl + "/GetTiposRegistroAll");
  }

  public Get_TiposGraficoCreados(idInstalacion: number)
  {
    return this.http.post<JSON>(`${baseUrl}/get_tiposGraficosCreados`, { idInstalacion }, { withCredentials: true });
  }

  public Insert_estructura(columnas: any) {
    return this.http.post<JSON>(`${baseUrl}/insert_estructura`, { columnas }, { withCredentials: true });
  }

  public Insert_estructura_home(columnas: any) {
    return this.http.post<JSON>(`${baseUrl}/insert_estructura_home`, { columnas }, { withCredentials: true });
  }

  public get_instalaciones_columnas(idInstalacion: number) {
    return this.http.post<JSON>(`${baseUrl}/get_instalaciones_columnas`, { idInstalacion }, { withCredentials: true });
  }

  public get_instalaciones_home(idInstalacion: number) {
    return this.http.post<JSON>(`${baseUrl}/get_instalaciones_home`, { idInstalacion }, { withCredentials: true });
  }

  public get_instalaciones_modulos() {
    return this.http.post<JSON>(`${baseUrl}/get_instalaciones_modulos`, { }, { withCredentials: true });
  }

  public get_instalaciones_datos(id) {
    return this.http.post<JSON>(`${baseUrl}/get_instalaciones_datos`, { id }, { withCredentials: true });
  }

  public get_iconos_graficoDatos() {
    return this.http.post<JSON>(`${baseUrl}/get_iconos_graficoDatos`, { }, { withCredentials: true });
  }

  public get_tiposUnidad_all() {
    return this.http.post<JSON>(`${baseUrl}/get_TiposUnidad_All`, { }, { withCredentials: true });
  }

  public get_consumo_agrupado(idInstalacion, tipoAgrupado, isDato = false) {
    return this.http.post<JSON>(`${baseUrl}/get_consumo_agrupado`, { idInstalacion, tipoAgrupado, isDato }, { withCredentials: true });
  }
}
