import { Component, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as c3 from 'c3';
import * as moment from 'moment';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import { isCompositeFilterDescriptor, process } from '@progress/kendo-data-query';

/* SERVICES*/
import { MaquinasService, UsuariosService, PlanificadorService, MenuService, HistoricoOperacionesService, ConfiguracionService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import { PageChangeEvent, SelectAllCheckboxState, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

@Component({
    selector: 'app-planificadorLargoMaquinas',
    templateUrl: './planificadorLargoMaquinas.component.html'
})

export class PlanificadorLargoMaquinasComponent {

    public dtOperacionesPlanificador: any;
    public dtTiemposMaquinas: any;

    public JgruposMaquinas: any;
    public requiereMaquina: boolean = false;
    public requiereSemana: boolean = false;

    public Jmaquinas: any;
    public JmaquinasMostradas: any;
    private Jplanificador: any;
    private JfechaMin: Date;
    private JfechaMax: Date;

    private JplanificadorDentroTaller: any;
    private JplanificadorFueraTaller: any;

    public aplicarTiempoEstimado: boolean = true; // si no se usa el tiempo estimado, se usa el tiempo predictivo
    public aplicarIneficiencias: boolean = false; // si se añade o no se añade el tiempo de ineficiencia

    public Jplanificadores: any;
    public JplanificadoresSelected: any;
    public JplanificadoresSinOriginal: any;
    public JplanificadoresSelectedCopiar: any;

    public DatOperaciones: any;//TODOS PROGRAMAS 
    // diria que esta linea no hace falta public DatOperaciones_grupos_operaciones: any;//TODOS PROGRAMAS QUE ESTAN EN GRUPOS
    public DatOperaciones_grupos: any;//TODOS PROGRAMAS QUE ESTAN EN GRUPOS
    public Joperaciones: any;//GRID PROGRAMAS 
    public Jgrupos: any;//GRID GRUPOS 
    public Jturnos: any;//FECHAS DE TURNOS 
    public Jmantenimientos: any;//INFO DE MANTENIMINTOS 
    public operacionesSelected = [];//GRID PROGRAMAS SELECCIONADOS
    public piezas: any;
    public JpiezasVal: any;
    public operaciones: any;
    public ofsSelected = [];
    public piezasSelected = [];
    public piezasSelectedAux = [];

    public Jsemanas = [];
    public JsemanaSelected: any;
    public JmaquinaSelected: any;
    public todasMaquinas: boolean = false;
    public asignarSemanaEntrega: boolean = false;

    //DATOS DE CONFIGURACION
    public dtConfiguracion: any = {};
    public dtConfiguracionPlanificador: any = {};
    public multiplicadorTiempos: number = 0.0;
    public prioridadCliente: number;
    public prioridadFacturacion: number;
    public prioridadOF: number;
    public prioridadMaquina: number;
    public maxCapacidadMaquina: number;
    public pasoCapacidadMaquina: number;
    public prioridadFecha: number;
    public prioridadTemperatura: number;
    public dias_reserva: number;
    public percent_cap_maquina: number;
    public optimizarCuelloBotella: boolean;
    public metodologiasDePlanificacion: any;
    public metodologiasDePlanificacionSelected: any;
    public replanificarTiempoEstimado: boolean;
    public replanificarIneficiencias: boolean;
    public planificarSoloNoPlanificadas: boolean;
    public asap_maximoMesesParaIniciarAntesDeFechaEntrega: number;

    public visibleUsarVersion: boolean = false;

    public loadingPanel: boolean = false;
    public loading_anadir: boolean = false;



    private draggedOperacionPartida: any;
    private semanaDraggedOperacionPartida: any;
    private semanaActualOperacionPartida: any;
    private semanaOperacionPartida: any;
    private idMaquinaOperacionPartida: any;

    @ViewChild('popupOperacionPartida') popupOperacionPartida: NgbModalRef;

    //popupPlanificar: boolean = false;
    @ViewChild('popupPlanificar') popupPlanificar: NgbModalRef;
    @ViewChild('popupReorganizar') popupReorganizar: NgbModalRef;
    @ViewChild('popupMoverOperaciones') popupMoverOperaciones: NgbModalRef;
    @ViewChild('popupUsarVersion') popupUsarVersion: NgbModalRef;
    @ViewChild('popupBorrarVersion') popupBorrarVersion: NgbModalRef;
    @ViewChild('popupCopiarAVersion') popupCopiarAVersion: NgbModalRef;
    @ViewChild('popupAtrasarFijados') popupAtrasarFijados: NgbModalRef;
    @ViewChild('popupReplanificarAultimaSemana') popupReplanificarAultimaSemana: NgbModalRef;
    @ViewChild('popupVeteAlCorto') popupVeteAlCorto: NgbModalRef;
    popupReorganizandoVersion: boolean = false;

    @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

    private dataFiltro: any;
    public listaOfs: any;
    public ofsSeleccionados: any;

    public listaClientes: any;
    public clientesSeleccionados: any;

    public listaPiezas: any;
    public piezasSeleccionados: any;

    user = this.userService.userValue;
    modalReference: NgbModalRef;
    modalReferenceloading: NgbModalRef;

    public JgruposMaquinasCombo: any;
    public JgruposMaquinasSelected: any;
    public JgruposMaquinasMaquinas: any;

    maximoValor: number = 0;

    tiemposCorto: any;
    operacionesCorto: any;
    operacionesOrdenadasEjecucionFueraCorto: any;

    //AREA PRODUCTIVA / SECCION
    private secciones: any;
    public groupedSeccion: GroupResult[];
    public seccionesSeleccionadas: any[] = [];

    private moverOperaciones_e;
    private moverOperaciones_semana;
    private moverOperaciones_idMaquina;
    private moverOperaciones_todas: boolean;


    public numeroCirculos: number = 4; // incluido  (...)

    public desplazaminetoSemanas = 0;
    public bloquearAvanceDesplazamientoSemanas = true;
    private semanasMostradas = 10;
    private semanasPorDesplazamiento = 5;
    public agruparOperacionesPorColor = false;

    private cadenas_Maquinas = [];

    //"LOAD"
    constructor(private maquinasService: MaquinasService, private userService: UsuariosService, private translateService: TranslateService,
        private modalService: NgbModal, private planificadorService: PlanificadorService, public myFunctions: MyFunctions,
        public router: Router, private menuService: MenuService, private historicoOperacionesService: HistoricoOperacionesService,
        private configuracionService: ConfiguracionService,
        public route: ActivatedRoute) {

        /*CARGA PRINCIPAL*/
        this.loadingPanel = true;

        this.todasMaquinas = false;
        this.asignarSemanaEntrega = false;

        this.menuService.titulo = this.translateService.instant('largoMaquinas').toUpperCase();

        this.userService.user.subscribe(x => this.user = x);

        /*CARGAR AREAS PRODUCTIVAS*/
        var an1: any = this.userService.secciones;
        this.secciones == undefined

        if (an1 != undefined)
            this.secciones = an1.filter(f => f.activo === true);
        if (this.secciones == undefined) {

            this.userService.getSecciones().subscribe(
                json => {
                    this.userService.secciones = json;

                    //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
                    var an1: any = this.userService.secciones;
                    this.secciones = an1.filter(f => f.activo === true);

                    var an: any = this.secciones;
                    this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

                    an.forEach(
                        row => {
                            if (row.activo)
                                this.seccionesSeleccionadas.push(row);
                        });
                });
        }
        else {

            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

            an.forEach(
                row => {
                    if (row.activo)
                        this.seccionesSeleccionadas.push(row);
                });
        }

        var respuestaMaquinas = new Promise((resolve, reject) => {
            //MAQUINAS
            var maquinas_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
            if (maquinas_planificador_model == false) {
                this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(json => {
                    this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
                    this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
                    //FLTRO POR SECCIONES
                    var idsSeccionesSelecteds: any = [];
                    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
                        this.seccionesSeleccionadas.forEach(
                            seccion => {
                                idsSeccionesSelecteds.push(seccion.id);
                            });
                    } else {
                        this.secciones.forEach(
                            seccion => {
                                idsSeccionesSelecteds.push(seccion.id);
                            });
                    }
                    this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

                    resolve(true);
                })
            }
            else {
                this.Jmaquinas = maquinas_planificador_model;

                //FLTRO POR SECCIONES
                var idsSeccionesSelecteds: any = [];
                if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
                    this.seccionesSeleccionadas.forEach(
                        seccion => {
                            idsSeccionesSelecteds.push(seccion.id);
                        });
                } else {
                    this.secciones.forEach(
                        seccion => {
                            idsSeccionesSelecteds.push(seccion.id);
                        });
                }
                this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

                resolve(true);
            }
        });

        respuestaMaquinas.then(() => {
            this.planificadorService.getPlanificadoresActivos(this.translateService.instant('real')).subscribe(
                json => {
                    this.Jplanificadores = json;

                    var version = this.route.snapshot.params['idVersion'];


                    this.JplanificadoresSelected = json[version];

                    var copia: any = [];
                    copia.push(json[0]);
                    copia.push(json[1]);
                    copia.push(json[2]);
                    copia.push(json[3]);
                    this.JplanificadoresSinOriginal = copia;
                    this.JplanificadoresSelectedCopiar = copia[0];

                    var r1, r2, r3, r4, r5, r6, r7, r8 = false;
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                        (json) => {

                            this.DatOperaciones = json;

                            r1 = true;
                            if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                            }
                        }
                    );

                    this.planificadorService.GetFechaSemanasLimite().subscribe(
                        (json) => {
                            if (Object.keys(json).length > 0) {
                                var a: any = json[0];
                                //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                                //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                                //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                                var fecha = this.startOfWeek(this.myFunctions.getDateNow());

                                var semana = this.getNumberOfWeek(fecha);
                                var año = fecha.getFullYear();

                                var fechaMax = a.fechaMax;
                                var añoMax = a.año;
                                var semanaMax = a.semana;

                                var index = 0;
                                while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
                                    //crear linea Json para añadir al "grid"
                                    var fecha2 = new Date(fecha);
                                    var js: any = {};
                                    js['value'] = index;
                                    js['fecha'] = fecha2;
                                    var fechaAnno = new Date(fecha);
                                    fechaAnno.setDate(fechaAnno.getDate() + 3);
                                    año = fechaAnno.getFullYear();
                                    js['año'] = año;
                                    js['semana'] = semana;
                                    js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                                    this.Jsemanas.push(js);

                                    //actualizar variables para proximo loop
                                    index++;
                                    fecha.setDate(fecha.getDate() + 7);
                                    semana = this.getNumberOfWeek(fecha);
                                    año = fecha.getFullYear();
                                }
                            }
                            else {
                                //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                                //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                                //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                                var fecha = this.startOfWeek(this.myFunctions.getDateNow());
                                var semana = this.getNumberOfWeek(fecha);
                                var año = fecha.getFullYear();


                                var index = 0;
                                //crear linea Json para añadir al "grid"
                                var fecha2 = new Date(fecha);
                                var js: any = {};
                                js['value'] = index;
                                js['fecha'] = fecha2;
                                var fechaAnno = new Date(fecha);
                                fechaAnno.setDate(fechaAnno.getDate() + 3);
                                año = fechaAnno.getFullYear();
                                js['año'] = año;
                                js['semana'] = semana;
                                js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                                this.Jsemanas.push(js);
                            }

                            r2 = true;
                            if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                            }
                        }
                    );

                    // GRUPOS DE MAQUINAS (COMBO)
                    this.maquinasService.getGruposMaquinasPlanificadorTodos().subscribe(
                        json => {
                            this.JgruposMaquinasCombo = json.data.filter(f => f.activo == 1);

                            r3 = true;
                            if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();

                            }
                        }
                    );

                    // GRUPOS DE MAQUINAS
                    this.maquinasService.getGrupoMaquinas_MaquinasPlanificadorById('0').subscribe(
                        json => {

                            this.JgruposMaquinasMaquinas = json;

                            r4 = true;
                            if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                            }
                        }
                    );

                    // CONFIGURACION DE PLANIFICADOR
                    this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
                        this.dtConfiguracionPlanificador = result[0];

                        this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
                        this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
                        this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;
                        this.semanasMostradas = this.dtConfiguracionPlanificador.semanasMostradasPlanificadorLargoMaquinas;
                        this.semanasPorDesplazamiento = this.dtConfiguracionPlanificador.semanasPorDesplazamientoPlanificadorLargoMaquinas;
                        this.agruparOperacionesPorColor = this.dtConfiguracionPlanificador.agruparOperacionesPorColorPlanificadorLargoMaquinas;

                        // DATOS DE CORTO
                        this.planificadorService.Get_tiempos_planificador_corto(0, this.JplanificadoresSelected.value, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                            json => {
                                this.tiemposCorto = json;
                                this.planificadorService.Get_tiempos_ejecutados(this.JplanificadoresSelected.value).subscribe(
                                    json => {
                                        //CARGAR TIEMPOS EJECUTADOS START 
                                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                        var an: any = json;
                                        var operacionesOrdenadas2 = {};
                                        var operacionesEnEjecucion = {};
                                        an.forEach(
                                            a => {
                                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                    operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                                else
                                                    operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                            });
                                        var an2: any = this.tiemposCorto;
                                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                        an2.forEach(
                                            row => {
                                                // añadir las 3 nuevas variables
                                                row['tiempoEjecutado'] = 0;
                                                row['operacionEmpezada'] = false;
                                                row['enEjecucion'] = false;

                                                //se ponen las que estan en ejecucion en este momento
                                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                                if (enEjecucion) {
                                                    if (enEjecucion == 1) {
                                                        row['enEjecucion'] = true;
                                                    }
                                                }

                                                //se descuenta el tiempo por maquina y operacion
                                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                                if (tiempoEjecutado) {
                                                    //se le pone que esta empezada
                                                    if (tiempoEjecutado > 0) {
                                                        row['operacionEmpezada'] = true;
                                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                        //se descuenta el tiempo de las 3 partes que afecta 
                                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                        } else {
                                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                        }
                                                    }
                                                }
                                            });
                                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                        an2.forEach(
                                            row => {
                                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                                if (tiempoEjecutado) {
                                                    if (tiempoEjecutado > 0) {
                                                        row['operacionEmpezada'] = true;
                                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                        //se descuenta el tiempo de las 3 partes que afecta 
                                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                            operacionesOrdenadas2[row['idOperacion']] = 0;
                                                        } else {
                                                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                        }
                                                    }
                                                }
                                            });
                                        //CARGAR TIEMPOS EJECUTADOS END
                                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                        var an3: any = an2;
                                        var jsonTiemposCorto: any = {};
                                        var maquinas: any = [];
                                        an3.forEach(
                                            row => {
                                                if (jsonTiemposCorto[row.idMaquina]) {
                                                    jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                                }
                                                else {
                                                    maquinas.push(row.idMaquina);
                                                    jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                                }
                                            });
                                        var valoresFinales: any = [];
                                        maquinas.forEach(
                                            maquina => {
                                                var js: any = {};
                                                js.idMaquina = maquina;
                                                js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                                js.tiempo = jsonTiemposCorto[maquina];
                                                valoresFinales.push(js);
                                            });
                                        this.operacionesCorto = an3;
                                        this.tiemposCorto = valoresFinales;
                                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;

                                        //CAMBIAR EL FORMATO AL RESULTADO END
                                        r5 = true;
                                        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                                            this.cargarDatosGraficos();
                                            this.cargarOFs();
                                        }
                                    }
                                );
                            });

                    });


                    // CONFIGURACION GENERAL
                    this.configuracionService.get_configuracion().subscribe(result => {
                        this.dtConfiguracion = result[0];
                        r6 = true;
                        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                            this.cargarDatosGraficos();
                            this.cargarOFs();
                        }
                    });

                    this.configuracionService.get_metodologiaDePlanificacion().subscribe(result => {
                        var an: any = result;
                        an.forEach(element => {
                            element.nombre = this.translateService.instant(element.nombre);
                        });
                        this.metodologiasDePlanificacion = an;

                        this.metodologiasDePlanificacionSelected = an[0];

                        //CAMBIAR EL FORMATO AL RESULTADO END
                        r7 = true;
                        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                            this.cargarDatosGraficos();
                            this.cargarOFs();
                        }
                    });

                    this.planificadorService.get_grupos(version).subscribe(
                        (json) => {

                            // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                                grupo => {
                                    grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r8 = true;
                            if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                            }
                        }
                    );
                });
        });

        this.cargarDatosFiltro();
    }

    ngOnInit() {
        this.planificadorService.get_maquinas_encadenadas().subscribe(
            (json) => {
                this.cadenas_Maquinas = this.myFunctions.conseguir_cadenasDeMaquinas(json);
            }
        );
    }

    cerrarPopUp() {
        //this.popupPlanificar = false;
    }

    /////////SELECT ALL GRID/////////
    public selectAllState: SelectAllCheckboxState = 'unchecked';
    public state: any;

    public onSelectAllChange(checkedState: SelectAllCheckboxState) {
        if (checkedState === 'checked') {

            this.piezasSelected = [];
            this.selectAllState = 'checked';

            var dataParaFiltrar;
            if (this.state == undefined) {
                dataParaFiltrar = this.JpiezasVal;
            } else {
                this.state.skip = 0;
                this.state.take = 10000000;
                dataParaFiltrar = process(this.JpiezasVal, this.state).data;
            }

            dataParaFiltrar.forEach(
                operacion => {
                    this.piezasSelected.push(operacion.idOperacion)
                });

        } else {

            this.piezasSelected = [];
            this.selectAllState = 'unchecked';

        }

        this.piezasSelectedAux = this.piezasSelected;

    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.piezasSelected = this.piezasSelectedAux;
        this.selectAllState = 'unchecked';
    }

    public pageChange(event: PageChangeEvent): void {
        this.piezasSelected = this.piezasSelectedAux;
    }
    /////////FIN SELECT ALL GRID/////////

    seccionChanged() {

        //FLTRO POR SECCIONES
        var idsSeccionesSelecteds: any = [];
        if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
            this.seccionesSeleccionadas.forEach(
                seccion => {
                    idsSeccionesSelecteds.push(seccion.id);
                });
        } else {
            this.secciones.forEach(
                seccion => {
                    idsSeccionesSelecteds.push(seccion.id);
                });
        }
        this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

    }

    cargarOFs() {
        //var r1, r2: boolean = false;

        //this.planificadorService.GetBySinPlanificar_piezas(this.JplanificadoresSelected.value).subscribe(
        //  (json) => {
        //    r1 = true;
        //    this.piezas = json;
        //    this.loadItems()
        //    if (r1 && r2)
        //      this.crearTablaOFs()
        //  }
        //);
        this.planificadorService.GetBySinPlanificar_operaciones(this.JplanificadoresSelected.value).subscribe(
            (json: any) => {
                //r2 = true;
                if (json.data != null && json.data.length > 0) {
                    var dict: any = {};
                    if (json.imagenes.length > 0) {
                        //Tenemos las imagenes, creamos el diccionario
                        json.imagenes.forEach(element => {
                            dict[element.imagen] = element.imagenBASE64;
                        });
                    }
                    json.data.forEach(element => {
                        //Ahora seguimos con las maquinas
                        var maquinas = element.maquinas.split(",");
                        var maquinasAuxi = [];
                        var maquinasAuxi2 = [];
                        maquinas.forEach(maquina => {
                            if (maquina == this.translateService.instant("desconocido")) {
                                maquinasAuxi2.push("undefined");
                                maquinasAuxi.push("undefined");
                            } else {
                                var nombre = maquina.trim().substring(0, 1).toUpperCase();
                                var apellido = maquina.trim().substring(1, 2).toUpperCase();
                                maquinasAuxi2.push(maquina);
                                maquinasAuxi.push(nombre + apellido);
                            }
                        });
                        element.maquinasAuxi2 = maquinasAuxi2.join(";");
                        element.maquinasAuxi = maquinasAuxi.join(",");
                        //Ahora hay que corregir las imagenes de las maquinas
                        var imagenes = element.maquinasIm.split(';and;');
                        var auxiImagenes = "";
                        imagenes.forEach(imagen => {
                            auxiImagenes += dict[imagen] + ";and;";
                        });
                        element.maquinasIm = auxiImagenes;
                    });
                }

                this.piezas = json.data;
                this.JpiezasVal = json.data;
                this.loading_anadir = false;

                //if (r1 && r2)
                //this.crearTablaOFs()
            }
        );
    }

    crearTablaOFs() {
        //for (var j = 0; j <= this.piezas.length - 1; j++) {
        //  var idParte = this.piezas[j].idParte;
        //  var operaciones = this.operaciones.filter(element => element.idParte == idParte);
        //  this.piezas[j].operaciones = operaciones;
        //}
    }

    //BOTONES "FILTRO"
    btnTiempoEstimado() {
        this.aplicarTiempoEstimado = true;
        var version = this.JplanificadoresSelected.value;
        this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
                this.tiemposCorto = json;

                this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                    json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                            a => {
                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                else
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                            });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                            row => {
                                // añadir las 3 nuevas variables
                                row['tiempoEjecutado'] = 0;
                                row['operacionEmpezada'] = false;
                                row['enEjecucion'] = false;

                                //se ponen las que estan en ejecucion en este momento
                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                if (enEjecucion) {
                                    if (enEjecucion == 1) {
                                        row['enEjecucion'] = true;
                                    }
                                }

                                //se descuenta el tiempo por maquina y operacion
                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                if (tiempoEjecutado) {
                                    //se le pone que esta empezada
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                            row => {
                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                if (tiempoEjecutado) {
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                            row => {
                                if (jsonTiemposCorto[row.idMaquina]) {
                                    jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                }
                                else {
                                    maquinas.push(row.idMaquina);
                                    jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                }
                            });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                            maquina => {
                                var js: any = {};
                                js.idMaquina = maquina;
                                js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                js.tiempo = jsonTiemposCorto[maquina];
                                valoresFinales.push(js);
                            });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END

                        this.cargarDatosGraficos()
                        //this.cargarGraficos();
                        this.cargarOFs();
                        this.cargarDatosFiltro();

                        if (this.JplanificadoresSelected.value > 1)
                            this.visibleUsarVersion = true;
                        else
                            this.visibleUsarVersion = false;
                    }
                );

            }
        );
    }
    btnPredictivo() {
        this.aplicarTiempoEstimado = false;
        var version = this.JplanificadoresSelected.value;
        this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
                this.tiemposCorto = json;

                this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                    json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                            a => {
                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                else
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                            });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                            row => {
                                // añadir las 3 nuevas variables
                                row['tiempoEjecutado'] = 0;
                                row['operacionEmpezada'] = false;
                                row['enEjecucion'] = false;

                                //se ponen las que estan en ejecucion en este momento
                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                if (enEjecucion) {
                                    if (enEjecucion == 1) {
                                        row['enEjecucion'] = true;
                                    }
                                }

                                //se descuenta el tiempo por maquina y operacion
                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                if (tiempoEjecutado) {
                                    //se le pone que esta empezada
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                            row => {
                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                if (tiempoEjecutado) {
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                            row => {
                                if (jsonTiemposCorto[row.idMaquina]) {
                                    jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                }
                                else {
                                    maquinas.push(row.idMaquina);
                                    jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                }
                            });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                            maquina => {
                                var js: any = {};
                                js.idMaquina = maquina;
                                js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                js.tiempo = jsonTiemposCorto[maquina];
                                valoresFinales.push(js);
                            });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END

                        this.cargarDatosGraficos()
                        //this.cargarGraficos();
                        this.cargarOFs();
                        this.cargarDatosFiltro();

                        if (this.JplanificadoresSelected.value > 1)
                            this.visibleUsarVersion = true;
                        else
                            this.visibleUsarVersion = false;
                    }
                );

            }
        );
    }
    btnIneficiencia() {
        this.aplicarIneficiencias = !this.aplicarIneficiencias;
        var version = this.JplanificadoresSelected.value;
        this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
                this.tiemposCorto = json;
                this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                    json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                            a => {
                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                else
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                            });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                            row => {
                                // añadir las 3 nuevas variables
                                row['tiempoEjecutado'] = 0;
                                row['operacionEmpezada'] = false;
                                row['enEjecucion'] = false;

                                //se ponen las que estan en ejecucion en este momento
                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                if (enEjecucion) {
                                    if (enEjecucion == 1) {
                                        row['enEjecucion'] = true;
                                    }
                                }

                                //se descuenta el tiempo por maquina y operacion
                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                if (tiempoEjecutado) {
                                    //se le pone que esta empezada
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                            row => {
                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                if (tiempoEjecutado) {
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                            row => {
                                if (jsonTiemposCorto[row.idMaquina]) {
                                    jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                }
                                else {
                                    maquinas.push(row.idMaquina);
                                    jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                }
                            });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                            maquina => {
                                var js: any = {};
                                js.idMaquina = maquina;
                                js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                js.tiempo = jsonTiemposCorto[maquina];
                                valoresFinales.push(js);
                            });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END
                        this.cargarDatosGraficos()
                        //this.cargarGraficos();
                        this.cargarOFs();
                        this.cargarDatosFiltro();

                        if (this.JplanificadoresSelected.value > 1)
                            this.visibleUsarVersion = true;
                        else
                            this.visibleUsarVersion = false;
                    }
                );

            }
        );
    }
    //CAMBIO DE VERSION
    versionChanged(event) {

        var r1, r2, r3 = false;

        this.JplanificadoresSelected = event;

        this.desplazaminetoSemanas = 0;

        // CONFIGURACION DE PLANIFICADOR
        this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
            this.dtConfiguracionPlanificador = result[0];

            this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
            this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
            this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;
            this.semanasMostradas = this.dtConfiguracionPlanificador.semanasMostradasPlanificadorLargoMaquinas;
            this.semanasPorDesplazamiento = this.dtConfiguracionPlanificador.semanasPorDesplazamientoPlanificadorLargoMaquinas;
            this.agruparOperacionesPorColor = this.dtConfiguracionPlanificador.agruparOperacionesPorColorPlanificadorLargoMaquinas;

            this.planificadorService.Get_tiempos_planificador_corto(0, this.JplanificadoresSelected.value, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                json => {
                    this.tiemposCorto = json;

                    this.planificadorService.Get_tiempos_ejecutados(this.JplanificadoresSelected.value).subscribe(
                        json => {
                            //CARGAR TIEMPOS EJECUTADOS START 
                            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                            var an: any = json;
                            var operacionesOrdenadas2 = {};
                            var operacionesEnEjecucion = {};
                            an.forEach(
                                a => {
                                    if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                    else
                                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                    operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                    operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                });
                            var an2: any = this.tiemposCorto;
                            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                            an2.forEach(
                                row => {
                                    // añadir las 3 nuevas variables
                                    row['tiempoEjecutado'] = 0;
                                    row['operacionEmpezada'] = false;
                                    row['enEjecucion'] = false;

                                    //se ponen las que estan en ejecucion en este momento
                                    var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                    if (enEjecucion) {
                                        if (enEjecucion == 1) {
                                            row['enEjecucion'] = true;
                                        }
                                    }

                                    //se descuenta el tiempo por maquina y operacion
                                    var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                    if (tiempoEjecutado) {
                                        //se le pone que esta empezada
                                        if (tiempoEjecutado > 0) {
                                            row['operacionEmpezada'] = true;
                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                            //se descuenta el tiempo de las 3 partes que afecta 
                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                            } else {
                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                            }
                                        }
                                    }
                                });
                            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                            an2.forEach(
                                row => {
                                    var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                    if (tiempoEjecutado) {
                                        if (tiempoEjecutado > 0) {
                                            row['operacionEmpezada'] = true;
                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                            //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                            //se descuenta el tiempo de las 3 partes que afecta 
                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                operacionesOrdenadas2[row['idOperacion']] = 0;
                                            } else {
                                                operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                            }
                                        }
                                    }
                                });
                            //CARGAR TIEMPOS EJECUTADOS END
                            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                            var an3: any = an2;
                            var jsonTiemposCorto: any = {};
                            var maquinas: any = [];
                            an3.forEach(
                                row => {
                                    if (jsonTiemposCorto[row.idMaquina]) {
                                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                    }
                                    else {
                                        maquinas.push(row.idMaquina);
                                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                    }
                                });
                            var valoresFinales: any = [];
                            maquinas.forEach(
                                maquina => {
                                    var js: any = {};
                                    js.idMaquina = maquina;
                                    js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                    js.tiempo = jsonTiemposCorto[maquina];
                                    valoresFinales.push(js);
                                });
                            this.operacionesCorto = an3;
                            this.tiemposCorto = valoresFinales;
                            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                            //CAMBIAR EL FORMATO AL RESULTADO END

                            this.popupReorganizandoVersion = false; //si no se pone en no visible, la propia funcion de crear graficos llama a comprovar version y vuelve a llamar a crear graficos

                            r2 = true;
                            if (r1 && r2 && r3) {
                                this.cargarDatosGraficos()
                                //this.cargarGraficos();
                                this.cargarOFs();
                            }
                            this.cargarDatosFiltro();

                            if (this.JplanificadoresSelected.value > 1)
                                this.visibleUsarVersion = true;
                            else
                                this.visibleUsarVersion = false;
                        }
                    );

                }
            );


            this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                (json) => {

                    this.DatOperaciones = json;

                    r1 = true;
                    if (r1 && r2 && r3) {
                        this.cargarDatosGraficos();
                        this.cargarOFs();
                    }
                }
            );

            this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                (json) => {

                    // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                    var grupos = (json as any).datOperaciones_grupos
                    grupos.forEach(
                        grupo => {
                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                        });

                    this.Jgrupos = grupos;

                    this.DatOperaciones_grupos = grupos;

                    r3 = true;
                    if (r1 && r2 && r3) {
                        this.cargarDatosGraficos();
                        this.cargarOFs();
                    }
                }
            );
        });
    }

    cargarDatosGraficos() {
        this.getGrafico();
    }
    private crearDivsGrupos() {
        this.loadingPanel = true;

        document.getElementById("contenedorGeneral").innerHTML = "";

        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

        this.actualizarReorganizandoVersion();

        var bukatua = new Promise<void>((resolve, reject) => {
            this.planificadorService.GruposMaquinasGet().subscribe(
                json => {
                    this.JgruposMaquinas = json;

                    ////se calcula la fecha maxima de todas la maquinas       
                    if (Object.keys(this.Jplanificador).length > 0) {
                        //segun el año hay que sacar en que dia empezo y se le resta a la suma de semanas completas para tener el inicio de esa semana.
                        this.JfechaMin = this.startOfWeek(this.myFunctions.getDateNow());

                        var Jfin = this.Jplanificador.filter(function (i, n) {
                            return i.idParte != "-1";
                        });

                        if (Object.keys(Jfin).length == 0) {
                            //var inicioAñoFin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                            //this.JfechaMax = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                            //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

                            this.JfechaMax = this.startOfWeek(this.myFunctions.getDateNow());
                            this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * 8));
                        } else {
                            var inicioAñoFin = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0));

                            var añoMax = 0
                            var semanaMax = 0
                            Jfin.forEach(
                                row => {
                                    if (row.año > añoMax || (row.año == añoMax && row.semana > semanaMax)) {
                                        añoMax = row.año
                                        semanaMax = row.semana
                                    }
                                });

                            // ESTA FORMA NO FUNCIONA SI LA ULTIMA MAQUINA NO TIENE NADA PLANIFICADO O ESTA MENOS PLANIFICADA QUE LAS DEMAS!
                            //this.JfechaMax = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0).getTime() +
                            //  (Jfin[Object.keys(Jfin).length - 1].semana * 7 * (1000 * 60 * 60 * 24)) -
                            //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));
                            this.JfechaMax = new Date(new Date(añoMax, 0, 0).getTime() +
                                (semanaMax * 7 * (1000 * 60 * 60 * 24)) -
                                ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

                            //var fecha: Date = Jfin[Object.keys(Jfin).length - 1].fechaIni;
                            //this.JfechaMax = this.startOfWeek(fecha);

                            var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
                            if (weeks < 8)
                                this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * (8 - weeks)));
                        }

                    } else {
                        this.JfechaMin = this.startOfWeek(this.myFunctions.getDateNow());

                        this.JfechaMax = this.startOfWeek(this.myFunctions.getDateNow());
                        this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * 8));

                    }
                    resolve();

                    this.loadingPanel = false;

                }
            );
        });

        bukatua.then(() => {
            var JplanificadorPruebas: any = this.JgruposMaquinas;

            let listaIdsMaquinasRepetidos = JplanificadorPruebas.map((obj) => obj.idGrupo);

            var idsGruposSelecteds: any = [];
            if (this.JgruposMaquinasSelected && this.JgruposMaquinasSelected.length > 0) {
                this.JgruposMaquinasSelected.forEach(
                    grupo => {
                        idsGruposSelecteds.push(grupo.id);
                    });
            } else {
                this.JgruposMaquinasCombo.forEach(
                    grupo => {
                        idsGruposSelecteds.push(grupo.id);
                    });
            }

            //FLTRO POR SECCIONES
            var idsSeccionesSelecteds: any = [];
            if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
                this.seccionesSeleccionadas.forEach(
                    seccion => {
                        idsSeccionesSelecteds.push(seccion.id);
                    });
            }
            else {
                this.secciones.forEach(
                    seccion => {
                        idsSeccionesSelecteds.push(seccion.id);
                    });
            }
            var listaIdsMaquinas = listaIdsMaquinasRepetidos.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });


            //FUERA DE TALLERplani
            var datosMaquina = this.Jplanificador.filter(unaMaquina => unaMaquina.fueraTaller == 1);
            if (false) {// (Object.keys(datosMaquina).length > 0) {
                var idGrupo = 'fuerataller';
                var idMaquina = '0';

                var divContenedorGrupo = document.createElement('div');
                divContenedorGrupo.id = 'divContenedorGrupo_' + idGrupo;
                divContenedorGrupo.className = "row";

                var divNombreGrupo = document.createElement('div');
                divNombreGrupo.id = 'divNombreGrupo_' + idGrupo;
                divNombreGrupo.className = "col-sm-2 col-md-2 col-lg-1 plan-largo-colgrupo  plan-largo-grupo1";

                var divNombreGrupoLabel = document.createElement('label');
                divNombreGrupoLabel.innerText += '';//this.translateService.instant('fuerataller');
                divNombreGrupoLabel.className += "nombregrupo";
                divNombreGrupo.appendChild(divNombreGrupoLabel);

                var divContenedorResto = document.createElement('div');
                divContenedorResto.id = 'divContenedorResto_' + idGrupo;
                divContenedorResto.className = "col-sm-10 col-md-10 col-lg-11 plan-largo-colgraf pl-0";

                document.getElementById('contenedorGeneral').appendChild(divContenedorGrupo);
                document.getElementById('divContenedorGrupo_' + idGrupo).appendChild(divNombreGrupo);
                document.getElementById('divContenedorGrupo_' + idGrupo).appendChild(divContenedorResto);

                var divContenedorTodo = document.createElement('div');
                divContenedorTodo.id = 'divContenedorTodo_' + idMaquina + "_" + idGrupo;
                divContenedorTodo.className = "row";
                // divContenedorTodo.addEventListener('click', (self) =>
                //   this.redirectAMaquina(idMaquina, 0)//no deveria entrar nunca aqui, ahora se usa subcontratado en vez de fuera de taller
                // );

                var divNombreMaquina = document.createElement('div');
                divNombreMaquina.id = 'divNombreMaquina_' + idMaquina + "_" + idGrupo;
                divNombreMaquina.className = "col-sm-2 col-md-2 col-lg-1 cont-nombre-maquina";
                divNombreMaquina.addEventListener('click', (self) =>
                    this.redirectAMaquina(idMaquina, 0)//no deveria entrar nunca aqui, ahora se usa subcontratado en vez de fuera de taller
                );

                var divNombreMaquina2 = document.createElement('div');
                divNombreMaquina2.id = 'divNombreMaquina2_' + idMaquina + "_" + idGrupo;
                divNombreMaquina2.className = "cont-inner-nombre-maquina";
                divNombreMaquina.appendChild(divNombreMaquina2);

                var divNombreMaquinaLabel = document.createElement('label');
                divNombreMaquinaLabel.innerText += this.translateService.instant('fuerataller');
                divNombreMaquinaLabel.className += "nombremaquina";
                divNombreMaquina2.appendChild(divNombreMaquinaLabel);

                var divContenedorGrafico = document.createElement('div');
                divContenedorGrafico.id = 'divContenedorGrafico_' + idMaquina + "_" + idGrupo;
                divContenedorGrafico.className = "col-sm-10 col-md-10 col-lg-11 col-graficos-largo";

                var graficoFueraTaller = document.createElement('div');
                graficoFueraTaller.id = 'graficoFueraTaller_' + idMaquina + "_" + idGrupo;
                graficoFueraTaller.className = "";
                var graficoCargaMaquina = document.createElement('div');
                graficoCargaMaquina.id = 'graficoCargaMaquina_' + idMaquina + "_" + idGrupo;
                graficoCargaMaquina.className = "";

                divContenedorResto.appendChild(divContenedorTodo);
                document.getElementById('divContenedorTodo_' + idMaquina + "_" + idGrupo).appendChild(divNombreMaquina);
                document.getElementById('divContenedorTodo_' + idMaquina + "_" + idGrupo).appendChild(divContenedorGrafico);
                document.getElementById('divContenedorGrafico_' + idMaquina + "_" + idGrupo).appendChild(graficoFueraTaller);
                document.getElementById('divContenedorGrafico_' + idMaquina + "_" + idGrupo).appendChild(graficoCargaMaquina);

                this.JplanificadorDentroTaller = datosMaquina.filter(function (i, n) {
                    return i.fueraTaller == 0;
                });

                this.JplanificadorFueraTaller = datosMaquina.filter(function (i, n) {
                    return i.fueraTaller == 1;
                });
                this.cargarGraficoFuera(idMaquina + "_" + idGrupo);
            }

            //EN CADA LOOP SE CONSIGUE CADA GRUPO QUE MAQUINAS TIENE
            listaIdsMaquinas.forEach(function (idGrupo) {
                var datosMaquina = JplanificadorPruebas.filter(
                    unaMaquina => unaMaquina.idGrupo === idGrupo &&
                        (idsGruposSelecteds.includes(unaMaquina.idGrupo) || idsGruposSelecteds.length == 0) &&
                        (idsSeccionesSelecteds.includes(unaMaquina.idSeccion) || this.secciones.length == 0)
                );
                if (datosMaquina.length > 0) {
                    var idGrupo = datosMaquina[0].idGrupo;
                    var idMaquina = datosMaquina[0].idMaquina;

                    var divContenedorGrupo = document.createElement('div');
                    divContenedorGrupo.id = 'divContenedorGrupo_' + idGrupo;
                    divContenedorGrupo.className = "row";

                    var divNombreGrupo = document.createElement('div');
                    divNombreGrupo.id = 'divNombreGrupo_' + idGrupo;
                    divNombreGrupo.className = "col-sm-2 col-md-2 col-lg-1 plan-largo-colgrupo  plan-largo-grupo1";

                    var divNombreGrupoLabel = document.createElement('label');
                    divNombreGrupoLabel.innerText += datosMaquina[0].nombreGrupo;
                    divNombreGrupoLabel.className += "nombregrupo";
                    divNombreGrupo.appendChild(divNombreGrupoLabel);

                    var divContenedorResto = document.createElement('div');
                    divContenedorResto.id = 'divContenedorResto_' + idGrupo;
                    divContenedorResto.className = "col-sm-10 col-md-10 col-lg-11 plan-largo-colgraf pl-0";

                    document.getElementById('contenedorGeneral').appendChild(divContenedorGrupo);
                    document.getElementById('divContenedorGrupo_' + idGrupo).appendChild(divNombreGrupo);
                    document.getElementById('divContenedorGrupo_' + idGrupo).appendChild(divContenedorResto);

                    datosMaquina.forEach(function (item) {
                        var datosMaquina = this.Jplanificador.filter(unaMaquina => unaMaquina.idMaquina === item.idMaquina && unaMaquina.tipo === item.tipo);

                        var divContenedorTodo = document.createElement('div');
                        divContenedorTodo.id = 'divContenedorTodo_' + item.idMaquina + "_" + idGrupo;
                        divContenedorTodo.className = "row";
                        // divContenedorTodo.addEventListener('click', (self) =>
                        //   this.redirectAMaquina(item.idMaquina, item.tipo)
                        // );

                        var divNombreMaquina = document.createElement('div');
                        divNombreMaquina.id = 'divNombreMaquina_' + item.idMaquina + "_" + idGrupo;
                        divNombreMaquina.className = "col-sm-2 col-md-2 col-lg-1 cont-nombre-maquina";
                        divNombreMaquina.addEventListener('click', (self) =>
                            this.redirectAMaquina(item.idMaquina, item.tipo)
                        );
                        var maquina = '';

                        this.Jmaquinas.forEach(
                            f => {
                                if (item.idMaquina === f.id && item.tipo == f.tipo)
                                    maquina = f.nombre;
                            }
                        );

                        var divNombreMaquina2 = document.createElement('div');
                        divNombreMaquina2.id = 'divNombreMaquina2_' + item.idMaquina + "_" + idGrupo;
                        divNombreMaquina2.className = "cont-inner-nombre-maquina";
                        divNombreMaquina.appendChild(divNombreMaquina2);

                        var divNombreMaquinaLabel = document.createElement('label');
                        divNombreMaquinaLabel.innerText += maquina;
                        divNombreMaquinaLabel.className += "nombremaquina";
                        divNombreMaquina2.appendChild(divNombreMaquinaLabel);

                        var img = document.createElement('img');

                        var maquinaConImagen = this.Jmaquinas.find(x => x.id === item.idMaquina && x.tipo == item.tipo);
                        //img.src = 'assets/idcontent/' + this.user.idDb + '/modelos/' + item.imagen;
                        img.src = maquinaConImagen.imagenBase64;
                        divNombreMaquina2.appendChild(img);

                        var divContenedorGrafico = document.createElement('div');
                        divContenedorGrafico.id = 'divContenedorGrafico_' + item.idMaquina + "_" + idGrupo;
                        divContenedorGrafico.className = "col-sm-10 col-md-10 col-lg-11 col-graficos-largo";

                        var graficoFueraTaller = document.createElement('div');
                        graficoFueraTaller.id = 'graficoFueraTaller_' + item.idMaquina + "_" + idGrupo;
                        graficoFueraTaller.className = "";
                        var graficoCargaMaquina = document.createElement('div');
                        graficoCargaMaquina.id = 'graficoCargaMaquina_' + item.idMaquina + "_" + idGrupo;
                        graficoCargaMaquina.className = "";

                        divContenedorResto.appendChild(divContenedorTodo);
                        document.getElementById('divContenedorTodo_' + item.idMaquina + "_" + idGrupo).appendChild(divNombreMaquina);
                        document.getElementById('divContenedorTodo_' + item.idMaquina + "_" + idGrupo).appendChild(divContenedorGrafico);
                        document.getElementById('divContenedorGrafico_' + item.idMaquina + "_" + idGrupo).appendChild(graficoFueraTaller);
                        document.getElementById('divContenedorGrafico_' + item.idMaquina + "_" + idGrupo).appendChild(graficoCargaMaquina);

                        this.JplanificadorDentroTaller = datosMaquina.filter(function (i, n) {
                            return i.fueraTaller == 0;
                        });

                        this.JplanificadorFueraTaller = datosMaquina.filter(function (i, n) {
                            return i.fueraTaller == 1;
                        });

                        this.cargarGraficoDentro(item.idMaquina + "_" + idGrupo, 200, item.tipo);

                    }, this);
                }
            }, this);

        });

    }

    redirectAMaquina(idMaquina, tipo) {
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value - 1;
        if (Number.isNaN(version))
            version = 0
        this.router.navigateByUrl('planificadorLargoMaquina/' + tipo + '/' + idMaquina + '/' + version);
    }

    //DENTRO DE TALLER
    private cargarGraficoDentro(idMaquina, height, tipo) {


        //Numero de semanas que vamos a mostrar
        var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
        var weekArray = [];
        weekArray.push("parte");
        for (let i = 0; i <= weeks; i++) {
            weekArray.push(0);
        }

        var data = [];
        var colores: any = {};
        var tipos: any = {};
        var grupos = [];
        var gruposArray = [];

        var listaIdOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
        var listaIdClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.idCliente);
        //var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.idPieza);
        var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.ids);
        listaIdPiezas = listaIdPiezas.reduce((acc, curVal) => { return acc.concat(curVal) }, []); //flatten array of arrays


        /*   CARGAR TURNOS   */
        //se añade al grupo para que vayan todos juntos en una sola columna
        var parte = this.translateService.instant('horasSemana');
        gruposArray.push(parte);
        var parteCapacidad = this.translateService.instant('capacidad');
        gruposArray.push(parteCapacidad);
        //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
        colores[parte] = '#edb90e';//'#a2a2a2';
        colores[parteCapacidad] = '#ee8625';
        //se añade a la tabla de semanas
        var weekArray = [];
        weekArray.push(parte);
        //se añaden los tiempos
        for (let i = 0; i <= weeks; i++) {
            weekArray.push(0);
        }
        data.push(weekArray)
        var weekArrayCapacidad = [];
        weekArrayCapacidad.push(parteCapacidad);
        //se añaden los tiempos
        for (let i = 0; i <= weeks; i++) {
            weekArrayCapacidad.push(0);
        }
        data.push(weekArrayCapacidad)
        if (Object.keys(this.JplanificadorDentroTaller).length > 0) {
            this.JplanificadorDentroTaller.forEach(
                row => {
                    //si el OF es nuevo se añade al sistema de tablas
                    if (row.idParte == '-1' && row.idOperacionesGrupos == "-1") {
                        if (row.tipo == 1) {
                            //MAQUINAS!
                            var indexSemanas = 0;
                            this.Jsemanas.forEach(f => {
                                if (f.semana == row.semana && f.año == row.año)
                                    indexSemanas = f.value + 1;
                            });

                            for (let idParte of data) {
                                if (idParte[0] == parte) {
                                    if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                                        idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                                    }
                                }
                                if (idParte[0] == parteCapacidad)
                                    if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                                        idParte[indexSemanas] = idParte[indexSemanas] + row.capacidadTurno; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                                    }
                            }
                        }
                        else {
                            //SUBCONTRATADOS!! (esto es especial)
                            var indexSemanas = 0;
                            weekArray.forEach(
                                week => {
                                    indexSemanas++;
                                    for (let idParte of data) {
                                        if (idParte[0] == parte)
                                            if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                                                idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                                            }
                                        if (idParte[0] == parteCapacidad)
                                            if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                                                idParte[indexSemanas] = idParte[indexSemanas] + row.capacidadTurno; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                                            }
                                    }
                                });
                        }
                    }
                });
        }
        /*   CARGAR TURNOS END   */

        tipos[this.translateService.instant('horasSemana')] = 'step';
        tipos[this.translateService.instant('capacidad')] = 'step';

        // tipos[this.translateService.instant('planificadorCorto')] = 'bar';
        // colores[this.translateService.instant('planificadorCorto')] = '#afe0e8';

        /*   CARGAR CORTO  POR OPERACION */
        if (data[0]) {
            var TtotalenSemanaCorto = 0
            var semanaCorto = 1
            var quedanTurnos = true

            this.operacionesCorto.forEach(
                row => {
                    var idMaquina_split = idMaquina.split("_", 1);
                    // se mira que mauqina es
                    if (row.idMaquina == idMaquina_split[0]) {
                        //si el OF es nuevo #se añade al sistema de tablas
                        var parte = row.idParte + ' - ' + row.idOperacionVisible + ' - ' + row.refOF + ' - ' + row.parte + ' - ' + row.operacionVisible;

                        //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
                        if ((listaIdOfs.includes(row.idOF) || listaIdOfs.length == 0) &&
                            (listaIdClientes.includes(row.idCliente) || listaIdClientes.length == 0) &&
                            (listaIdPiezas.includes(row.idPieza) || listaIdPiezas.length == 0)) {
                            colores[parte + " (" + this.translateService.instant('corto') + ")"] = '#afe0e8';
                        } else {
                            colores[parte + " (" + this.translateService.instant('corto') + ")"] = '#e4eeee';
                        }

                        gruposArray.push(parte + " (" + this.translateService.instant('corto') + ")");

                        var weekArray = [];
                        weekArray.push(parte + " (" + this.translateService.instant('corto') + ")");
                        for (let i = 0; i <= weeks; i++) {
                            weekArray.push(0);
                        }

                        //check si hay turnos y avanzar a proxima semana con tiempos START
                        if (data[1][semanaCorto] == 0 && quedanTurnos) {
                            quedanTurnos = false
                            for (let i = semanaCorto; i < data[1].length; i++) {
                                if (data[1][i] > 0)
                                    quedanTurnos = true;
                            }
                        }
                        var limite = 0;
                        while (limite < 10 && data[1][semanaCorto] == 0 && quedanTurnos) {
                            semanaCorto += 1;
                            limite += 1;
                        }
                        //check si hay turnos y avanzar a proxima semana con tiempos END

                        var tiempoOperacionAMeter = row.tiempo + 0; // tiempo a meter de la operacion actual.
                        while (tiempoOperacionAMeter > 0) { // se hace un loop por si se corta en mas de 1 semana
                            //SemanaCorto: en que semana esta metiendo el planificador corto.
                            var tiempoTurnoEnSemana = data[1][semanaCorto]; // cuanto tiempo de turno (con capacidad) dispone la semana en la que estamos metiendo el corto.
                            //TtotalenSemanaCorto; // cuanto tiempo hemos metido del corto en la semana en la que estamos metiendo el corto.              
                            // weekArray[semanaCorto] : // row para meter en el grafico de la operacion actual con los tiempos por semana
                            if (TtotalenSemanaCorto + tiempoOperacionAMeter < tiempoTurnoEnSemana || !quedanTurnos) {
                                // La semana tiene espacio suficiente para la operacion actual.
                                weekArray[semanaCorto] = tiempoOperacionAMeter;
                                TtotalenSemanaCorto += tiempoOperacionAMeter;
                                tiempoOperacionAMeter = 0;
                            } else {
                                // La semana NO tiene espacio suficiente para la operacion actual.
                                var tempoDeOperacionQueEntraEnSemana = tiempoTurnoEnSemana - TtotalenSemanaCorto;
                                weekArray[semanaCorto] = tempoDeOperacionQueEntraEnSemana;
                                tiempoOperacionAMeter = tiempoOperacionAMeter - tempoDeOperacionQueEntraEnSemana;
                                // Se pasa a la siguiente semana y se resetea el tiempo total.
                                semanaCorto += 1;
                                tiempoTurnoEnSemana = data[1][semanaCorto];
                                //check si hay turnos y avanzar a proxima semana con tiempos START
                                if (tiempoTurnoEnSemana == 0 && quedanTurnos) {
                                    quedanTurnos = false
                                    for (let i = semanaCorto; i < data[1].length; i++) {
                                        if (data[1][i] > 0)
                                            quedanTurnos = true;
                                    }
                                }
                                var limite = 0;
                                while (limite < 100 && tiempoTurnoEnSemana == 0 && quedanTurnos) {
                                    semanaCorto += 1;
                                    tiempoTurnoEnSemana = data[1][semanaCorto];
                                    limite += 1;
                                }
                                //check si hay turnos y avanzar a proxima semana con tiempos END
                                TtotalenSemanaCorto = 0; //se reinicia el tiempo de la semana 
                            }
                        }
                        // Se añade la linea de la operacion al grafico
                        data.push(weekArray);
                    }
                });
        }
        /*   CARGAR CORTO END   */

        /*   CARGAR LARGO   */
        if (Object.keys(this.JplanificadorDentroTaller).length > 0) {
            this.JplanificadorDentroTaller.forEach(
                row => {
                    //si el OF es nuevo #se añade al sistema de tablas
                    var negativo: string = '';
                    if (row.fueraDeTiempo == 2)
                        negativo = '-';
                    var parte = negativo + row.idParte + ' - ' + row.idOperacionVisible + ' - ' + row.refOF + ' - ' + row.parte + ' - ' + row.operacionVisible;

                    if (row.idParte != '-1') {
                        if (!gruposArray.includes(parte)) {
                            //se añade al grupo para que vayan todos juntos en una sola columna
                            gruposArray.push(parte);
                            //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
                            if ((listaIdOfs.includes(row.idOF) || listaIdOfs.length == 0) &&
                                (listaIdClientes.includes(row.idCliente) || listaIdClientes.length == 0) &&
                                (listaIdPiezas.includes(row.idPieza) || listaIdPiezas.length == 0)) {
                                if (row.fueraDeTiempo == 0) {
                                    colores[parte] = '#3289a8'; //AZUL
                                } else if (row.fueraDeTiempo == 1) {
                                    colores[parte] = '#eb8f34'; //NARANJA
                                } else {
                                    colores[parte] = '#EA4335'; //ROJO
                                }
                            } else {
                                if (row.fueraDeTiempo == 0) {
                                    colores[parte] = '#D6E7EE'; //AAZUL
                                } else if (row.fueraDeTiempo == 1) {
                                    colores[parte] = '#FBE9D6'; //NARANJA
                                } else {
                                    colores[parte] = '#F4D6D8'; //ROJO
                                }
                            }
                            //se añade a la tabla de semanas
                            var weekArray = [];
                            weekArray.push(parte);
                            for (let i = 0; i <= weeks; i++) {
                                weekArray.push(0);
                            }
                            data.push(weekArray)
                        }

                        var indexSemanas = 0;
                        this.Jsemanas.forEach(f => {
                            if (f.semana == row.semana && f.año == row.año)
                                indexSemanas = f.value + 1;
                        });
                        for (let idParte of data)
                            if (idParte[0] == parte)
                                if (indexSemanas <= weeks + 1 && indexSemanas > 0)
                                    idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                    } else if (row.idOperacionesGrupos != '-1') {
                        parte = "Grupo " + row.idOperacionesGrupos;

                        if (!gruposArray.includes(parte)) {
                            //se añade al grupo para que vayan todos juntos en una sola columna
                            gruposArray.push(parte);
                            //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
                            if ((listaIdOfs.includes(row.idOF) || listaIdOfs.length == 0) &&
                                (listaIdClientes.includes(row.idCliente) || listaIdClientes.length == 0) &&
                                (listaIdPiezas.includes(row.idPieza) || listaIdPiezas.length == 0)) {
                                if (row.fueraDeTiempo == 0) {
                                    colores[parte] = '#3289a8'; //AZUL
                                } else if (row.fueraDeTiempo == 1) {
                                    colores[parte] = '#eb8f34'; //NARANJA
                                } else {
                                    colores[parte] = '#EA4335'; //ROJO
                                }
                            } else {
                                if (row.fueraDeTiempo == 0) {
                                    colores[parte] = '#D6E7EE'; //AAZUL
                                } else if (row.fueraDeTiempo == 1) {
                                    colores[parte] = '#FBE9D6'; //NARANJA
                                } else {
                                    colores[parte] = '#F4D6D8'; //ROJO
                                }
                            }
                            //se añade a la tabla de semanas
                            var weekArray = [];
                            weekArray.push(parte);
                            for (let i = 0; i <= weeks; i++) {
                                weekArray.push(0);
                            }
                            data.push(weekArray)
                        }

                        var indexSemanas = 0;
                        this.Jsemanas.forEach(f => {
                            if (f.semana == row.semana && f.año == row.año)
                                indexSemanas = f.value + 1;
                        });

                        for (let idParte of data)
                            if (idParte[0] == parte)
                                if (indexSemanas <= weeks + 1 && indexSemanas > 0)
                                    idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1

                    }
                }
            );

        } else {

            //si el OF es nuevo se añade al sistema de tablas
            var titulo: string = this.translateService.instant('dentroDeTaller');
            if (!gruposArray.includes(titulo)) {
                //se añade al grupo para que vayan todos juntos en una sola columna
                gruposArray.push(titulo);
                //se le da color en funcion de su estado        
                colores[titulo] = '#afe0e8';

                //se añade a la tabla de semanas
                var weekArray = [];
                weekArray.push(titulo);
                for (let i = 0; i <= weeks; i++) {
                    weekArray.push(0);
                }
                data.push(weekArray)
            }
        }
        /*   CARGAR LARGO  END  */

        //Este tiene que ser un array de arrays, tiene opcion de agruar en cachos para hacer diferentes torres
        grupos.push(gruposArray.filter(f => f != this.translateService.instant('horasSemana')));

        var max = 0
        //if (tipo == 1)
        max = this.conseguir_maximo(this.Jplanificador, this.maximoValor, tipo, idMaquina);
        //else
        //  max = this.conseguir_maximo(this.Jplanificador, this.maximoValor, tipo);

        var th = this;

        // FILTRAMOS LAS SEMANAS A MOSTRAR
        var dataEntreSemanas = [];
        this.bloquearAvanceDesplazamientoSemanas = false;
        data.forEach(
            operacion => {
                var operacionEntreSemanas = [];
                var indice = 0;
                operacion.forEach(
                    semana => {

                        if (indice == 0) {
                            // TITULO de la operacion
                            operacionEntreSemanas.push(semana);
                        } else if (indice > this.desplazaminetoSemanas && indice <= this.desplazaminetoSemanas + this.semanasMostradas) {
                            operacionEntreSemanas.push(semana);
                        };
                        indice++;
                    });
                if (operacionEntreSemanas.length <= this.semanasMostradas)
                    this.bloquearAvanceDesplazamientoSemanas = true;

                dataEntreSemanas.push(operacionEntreSemanas);
            });

        // SI ES NECESARIO AGRUPAMOS LAS OPERACIONES POR COLORES
        var dataGrafico = []
        var grupoGrafico = []
        var coloresGrafico = {}
        var tiposGrafico = {}

        if (this.agruparOperacionesPorColor) {
            var idsAzules = [];
            var idsAmarillos = [];
            var idsRojos = [];
            var idsCorto = [];

            var textoCapacidad = this.translateService.instant('horasScapacidademana');
            var textoHorasSemanales = this.translateService.instant('horasSemana');
            var textoCorto = this.translateService.instant('planificadorCorto');

            for (const key in colores) {
                if (colores[key] === "#afe0e8") {
                    idsCorto.push(key);
                } else if (colores[key] === "#3289a8") {
                    idsAzules.push(key);
                } else if (colores[key] === "#eb8f34") {
                    idsAmarillos.push(key);
                } else if (colores[key] === "#EA4335") {
                    idsRojos.push(key);
                }
            }

            dataGrafico = [
                dataEntreSemanas[0].slice(),
                dataEntreSemanas[1].slice(),
                dataEntreSemanas[0].slice().map(() => 0),
                dataEntreSemanas[0].slice().map(() => 0),
                dataEntreSemanas[0].slice().map(() => 0),
                dataEntreSemanas[0].slice().map(() => 0)
            ];
            dataGrafico[0][0] = textoCapacidad;
            dataGrafico[1][0] = textoHorasSemanales;
            dataGrafico[2][0] = textoCorto;
            dataGrafico[3][0] = "azul-ok";
            dataGrafico[4][0] = "amarillo-alerta";
            dataGrafico[5][0] = "rojo-no-ok";

            var indice = 0;
            dataEntreSemanas.forEach(
                operacion => {
                    var rowCollor = [];
                    // MIRAMOS QUE ROW TENEMOS QUE RELLENAR
                    if (indice > 1) {
                        if (idsCorto.includes(operacion[0])) {
                            rowCollor = dataGrafico[2];
                        } else if (idsAzules.includes(operacion[0])) {
                            rowCollor = dataGrafico[3];
                        } else if (idsAmarillos.includes(operacion[0])) {
                            rowCollor = dataGrafico[4];
                        } else if (idsRojos.includes(operacion[0])) {
                            rowCollor = dataGrafico[5];
                        }

                        // SUMAMOS LAS ROWS LEIDAS            
                        for (let i = 1; i < rowCollor.length; i++) {
                            rowCollor[i] += operacion[i];
                        }
                    }

                    indice++;
                });

            grupoGrafico = [[textoCorto, 'azul-ok', 'amarillo-alerta', 'rojo-no-ok']];

            coloresGrafico[textoCapacidad] = "#ee8625";
            coloresGrafico[textoHorasSemanales] = "#edb90e";
            coloresGrafico[textoCorto] = "#afe0e8";
            coloresGrafico['azul-ok'] = "#3289a8";
            coloresGrafico['amarillo-alerta'] = "#eb8f34";
            coloresGrafico['rojo-no-ok'] = "#EA4335";

            tiposGrafico[textoCapacidad] = "step";
            tiposGrafico[textoHorasSemanales] = "step";
            tiposGrafico[textoCorto] = "bar";
            tiposGrafico['azul-ok'] = "bar";
            tiposGrafico['amarillo-alerta'] = "bar";
            tiposGrafico['rojo-no-ok'] = "bar";

        } else {
            dataGrafico = dataEntreSemanas;
            grupoGrafico = grupos;
            coloresGrafico = colores;
            tiposGrafico = tipos;
        }

        var chartDentro = c3.generate({
            size: {
                height: height
            },
            bindto: '#graficoCargaMaquina_' + idMaquina,
            data: {
                type: 'bar',
                columns: dataGrafico,
                groups: grupoGrafico,
                colors: coloresGrafico,
                types: tiposGrafico,
                order: null
            },
            grid: {
                y: {
                    show: true
                }
            },
            legend: {
                show: false
            },
            axis: {
                y: {
                    max: max,
                    padding: {
                        bottom: 10,
                        top: -20
                    },
                    tick: {
                        values: [40 * 3600, 2 * 40 * 3600, 3 * 40 * 3600],
                        format: function (d) { return Math.round(d / 3600) + ' h'; }
                    }
                },
                x: {
                    tick: {
                        format: (d) => {
                            var semana = '';
                            this.Jsemanas.forEach(f => {
                                if (f.value == d + this.desplazaminetoSemanas)
                                    semana = this.translateService.instant('semana') + ' ' + (f.text);
                            });
                            return semana;
                        }
                    }
                }
            },
            tooltip: {
                contents: function (data, defaultTitleFormat, defaultValueFormat, color) {

                    //quitar los valores a 0
                    var index = data[0].index + 1;
                    var new_data = [];

                    var tiempoTotal = 0;
                    var tiempoLargo = 0;

                    dataEntreSemanas.forEach(
                        operacion => {
                            if (operacion[index] > 0) {
                                var tooltip_row = {
                                    id: operacion[0],
                                    index: index,
                                    name: operacion[0],
                                    tooltipOpder: 900,
                                    value: operacion[index],
                                    x: index
                                }


                                var index_guion = tooltip_row.name.indexOf(" - ")
                                if (index_guion > 0) {
                                    tiempoLargo += tooltip_row.value;
                                    tooltip_row.name = tooltip_row.name.split(' - ').slice(2).join(' - ');
                                }
                                if (tooltip_row.name != th.translateService.instant('horasSemana') && tooltip_row.name != th.translateService.instant('capacidad'))
                                    tiempoTotal += tooltip_row.value;

                                if (tooltip_row.name == th.translateService.instant('horasSemana')) { tooltip_row.tooltipOpder = 0; }
                                else if (tooltip_row.name == th.translateService.instant('capacidad')) { tooltip_row.tooltipOpder = 1; }
                                else if (tooltip_row.name == th.translateService.instant('planificadorCorto')) { tooltip_row.tooltipOpder = 2; }
                                else { tooltip_row.tooltipOpder = 900; }

                                new_data.push(tooltip_row);
                            }
                        })

                    if (tiempoLargo > 0) {
                        var j: any = {};
                        j.id = th.translateService.instant('totalLargo');
                        j.index = new_data[0].index;
                        j.name = th.translateService.instant('totalLargo');
                        j.value = tiempoLargo;
                        j.x = new_data[0].index;
                        j.tooltipOpder = 3;

                        new_data.push(j)
                    }
                    if (tiempoTotal > 0) {
                        var j: any = {};
                        j.id = th.translateService.instant('total');
                        j.index = new_data[0].index;
                        j.name = th.translateService.instant('total');
                        j.value = tiempoTotal;
                        j.x = new_data[0].index;
                        j.tooltipOpder = 4;

                        new_data.push(j)
                    }

                    new_data = new_data.sort(function (elem1, elem2) {
                        if (elem1.tooltipOpder > elem2.tooltipOpder) return 1;
                        if (elem1.value > elem2.value) return -1;
                        return 0;
                    });

                    var new_data_reduct = [];


                    if (!th.agruparOperacionesPorColor) {
                        new_data_reduct = new_data;
                    } else {
                        var last_row = {
                            id: th.translateService.instant("otros"),
                            index: index,
                            name: th.translateService.instant("otros"),
                            tooltipOpder: 900,
                            value: 0,
                            x: index
                        }
                        var indice_top = 0;
                        var top = 15;
                        new_data.forEach(
                            row => {
                                if (indice_top < top) {
                                    new_data_reduct.push(row);
                                } else {
                                    last_row.value += row.value;
                                }
                                indice_top++;
                            });
                        if (last_row.value > 0) {
                            new_data_reduct.push(last_row);
                        }
                    }

                    if (new_data_reduct.length == 0) {
                        return "";
                    } else {
                        var auxiString1 = "<table class='c3-tooltip'><tbody>";
                        auxiString1 += "<tr><th colspan='2'>" + th.translateService.instant('semana') + ' ' + th.Jsemanas[new_data_reduct[0].x - 1].text + "</th></tr>";

                        new_data_reduct.forEach(f => {
                            var color = "<span style='background-color:" + colores[f.id] + "'></span>";
                            if (f.name == th.translateService.instant('totalLargo'))
                                color = "<span style='background-color:#1f77b4'></span>";
                            if (f.name == th.translateService.instant('total'))
                                color = "<span style='background-color:#ff7f0e'></span>";
                            auxiString1 += "<tr class=c3-tooltip-name--" + f.id.replace(' ', '-') + "'>";
                            auxiString1 += "<td class='name'>" + color + f.name + "</td><td class='value'> " + Math.round(f.value / 3600) + "h. </td>";
                            auxiString1 += "</tr>"
                        });
                        auxiString1 += "</tbody></table>";

                        return auxiString1;
                    }


                }
            },
            transition: {
                duration: 2000
            },
            zoom: {
                enabled: true,
                justY: true
            },
            onItemDropped: (e, semana) => {
                var idM: number = idMaquina.split("_", 1)[0];
                this.dropGrafico(e, semana, idM);
            }
        });
    }

    dropGrafico(e, semana, idMaquina) {
        // preparamos las variables para un codigo mas comprensible
        var splitted = e.subject.id.split(" - ", 2);
        var idParte: number = 0;
        if (splitted.length > 0)
            idParte = splitted[0].replace("-", "");
        var idOperacionVisible: number = 0;
        if (splitted.length > 1)
            idOperacionVisible = splitted[1].replace("-", "");

        var semanaActual = this.getNumberOfWeek(new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate()));
        var semanaDragged = e.subject.index
        var dragged = this.DatOperaciones.filter(f => f.idParte == idParte && f.semana == semanaDragged + semanaActual && f.idMaquina == idMaquina && (idOperacionVisible == 0 || f.idOperacion == idOperacionVisible))

        if (semana != semanaDragged) {

            if (e.subject.id.includes(" (" + this.translateService.instant('corto') + ")")) {
                this.modalReference = this.modalService.open(this.popupVeteAlCorto, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
            }
            else {
                this.moverOperaciones_e = e;
                this.moverOperaciones_semana = semana;
                this.moverOperaciones_idMaquina = idMaquina;

                this.modalReference = this.modalService.open(this.popupMoverOperaciones, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
            }
        }
    }
    conseguir_maximo(DatOperaciones, maximoValor, tipo, idMaquina) {
        // COMO LAS MAQUINAS VIENEN REPETIDAS POR CADA GRUPO EN EL QUE ESTAN, O VIENEN CON IDGRUPO = 0, SE HACE UNA LISTA CON CUANTAS REPETICIONES SALEN (PARA DIVIDIR LOS TIEMPOS DESPUES)
        // Y SOLO CON LAS MAQUINAS QUE HAY QUE MOSTRAR, DE ESTA FORMA, LAS MAQUINAS QUE NO HAY QUE MOSTRAR SIEMPRE QUEDARAN FUERA DE LA LISTA Y NO SE TENDRA SU TIEMPO EN CUENTA
        var repeticiones: any = {};
        this.JgruposMaquinas.forEach(
            maquina => {
                if (maquina.idGrupo > 0 && (tipo == 1 || (tipo == 2 && idMaquina == maquina.idMaquina + '_' + maquina.idGrupo && maquina.activo == 1))) {
                    if (repeticiones[maquina.tipo + '_' + maquina.idMaquina] == undefined)
                        repeticiones[maquina.tipo + '_' + maquina.idMaquina] = 1;
                    else
                        repeticiones[maquina.tipo + '_' + maquina.idMaquina]++;
                }
            });
        //SE AÑADEN LOS TIEMPOS POR MAQUINA DIVIDIDOS POR EL NUMERO DE REPETICIONES DE ESTAS PARA CONSEGUIR UN UNICO MAXIMO POR MAQUINA (LAS SUBCONTRATADAS VAN APARTE, de forma individual)
        var JmaquinasSemanas: any = {};
        DatOperaciones.forEach(
            row => {
                if (row.tipo == tipo && repeticiones[row.tipo + '_' + row.idMaquina] != undefined) {
                    // if ((row.idParte > 0 || row.idParte == -1) && row.fueraTaller == 0) // NO SE PORQUE PONIA row.idParte == -1 SI ESO SON TURNOS! NO OPERACIONES Y SE SUMAN APARTE
                    if ((row.idParte > 0) && row.fueraTaller == 0) {
                        if (JmaquinasSemanas[row.tipo + '_' + row.idMaquina + "_" + row.semana + '_Planificado'] == undefined)
                            JmaquinasSemanas[row.tipo + '_' + row.idMaquina + "_" + row.semana + '_Planificado'] = 0;
                        JmaquinasSemanas[row.tipo + '_' + row.idMaquina + "_" + row.semana + '_Planificado'] += row.tiempo / repeticiones[row.tipo + '_' + row.idMaquina];
                    } else if (row.idParte == - 1) {
                        if (JmaquinasSemanas[row.tipo + '_' + row.idMaquina + "_" + row.semana + '_Turno'] == undefined) JmaquinasSemanas[row.tipo + '_' + row.idMaquina + "_" + row.semana + '_Turno'] = 0;
                        JmaquinasSemanas[row.tipo + '_' + row.idMaquina + "_" + row.semana + '_Turno'] += row.tiempo / repeticiones[row.tipo + '_' + row.idMaquina];
                    }
                }
            }
        );
        var max = 40 * 3600; //minimo un turno de 40h
        // SE COGE EL MAXIMO 
        var an: any = Object.keys(JmaquinasSemanas);
        an.forEach(
            col => {
                if (max < JmaquinasSemanas[col]) {
                    max = JmaquinasSemanas[col];
                }
            });

        return max * 1.2;
        // return max *0.9;
    }

    //FUERA DE TALLER
    private cargarGraficoFuera(idMaquina) {
        //Numero de semanas que vamos a mostrar
        var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
        var weekArray = [];
        weekArray.push("parte");
        for (let i = 0; i <= weeks; i++) {
            weekArray.push(0);
        }

        var data = [];
        var colores: any = {};

        var grupos = [];
        var gruposArray = [];

        if (Object.keys(this.JplanificadorFueraTaller).length > 0) {
            this.JplanificadorFueraTaller.forEach(
                row => {
                    //si el OF es nuevo se añade al sistema de tablas
                    var negativo: string = '';
                    if (row.fueraDeTiempo == 1)
                        negativo = '-';
                    var parte = negativo + row.idParte + ' - ' + row.idOperacionVisible + ' - ' + row.refOF + ' - ' + row.parte + ' - ' + row.operacionVisible;
                    if (!gruposArray.includes(parte)) {
                        //se añade al grupo para que vayan todos juntos en una sola columna
                        gruposArray.push(parte);
                        //se le da color en funcion de su estado
                        if (row.fueraDeTiempo == 0) {
                            colores[parte] = '#afe0e8';
                        } else {
                            colores[parte] = '#EA4335';
                        }
                        //se añade a la tabla de semanas
                        var weekArray = [];
                        weekArray.push(parte);
                        for (let i = 0; i <= weeks; i++) {
                            weekArray.push(0);
                        }
                        data.push(weekArray)
                    }

                    var indexSemanas = 0;
                    this.Jsemanas.forEach(f => {
                        if (f.semana == row.semana && f.año == row.año)
                            indexSemanas = f.value + 1;
                    });

                    //actualizamos el valor correspondiente en su semana 
                    for (let idParte of data)
                        if (idParte[0] == parte)
                            if (indexSemanas <= weeks + 1 && indexSemanas > 0)
                                idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo;
                }
            );
        } else {
            //si el OF es nuevo se añade al sistema de tablas
            var titulo: string = this.translateService.instant('fueraDeTaller');
            if (!gruposArray.includes(titulo)) {
                //se añade al grupo para que vayan todos juntos en una sola columna
                gruposArray.push(titulo);
                //se le da color en funcion de su estado        
                colores[titulo] = '#afe0e8';
                //se añade a la tabla de semanas
                var weekArray = [];
                weekArray.push(titulo);
                for (let i = 0; i <= weeks; i++) {
                    weekArray.push(0);
                }
                data.push(weekArray)
            }
        }

        //Este tiene que ser un array de arrays, tiene opcion de agruar en cachos para hacer diferentes torres
        grupos.push(gruposArray);

        var tipos: any = {};
        tipos[this.translateService.instant('horasSemana')] = 'step';
        var th = this;
        if (data[0][0] != "Fuera de taller") {
            var chartFuera = c3.generate({
                size: {
                    height: 100
                },
                bindto: '#graficoFueraTaller_' + idMaquina,
                data: {
                    type: 'bar',
                    columns: data,
                    groups: grupos,
                    colors: colores,
                    types: tipos,
                    onclick: (e) => {
                    }
                },
                grid: {
                    y: {
                        show: true
                    }
                },
                legend: {
                    show: false
                },
                axis: {
                    y: {
                        padding: {
                            bottom: 0,
                        },
                        tick: {
                            count: 4,
                            format: function (d) { return Math.round(d / 3600) + ' h'; }
                        }
                    },
                    x: {
                        tick: {
                            format: (d) => {
                                var semana = '';
                                this.Jsemanas.forEach(f => {
                                    if (f.value == d)
                                        semana = this.translateService.instant('semana') + ' ' + f.text;
                                });
                                return semana;
                            }
                        }
                    }
                },
                tooltip: {
                    contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                        //quitar los valores a 0
                        d = d.filter(
                            line =>
                                line.value != 0
                        )
                        //quitar el codigo del principio
                        var tiempoTotal = 0;
                        var tiempoLargo = 0;
                        d.forEach(f => {
                            var index = f.name.indexOf(" - ")
                            if (index > 0) {
                                tiempoLargo += f.value;
                                f.name = f.name.substr(index + 3, f.name.length)
                            }
                            if (f.name != th.translateService.instant('horasSemana'))
                                tiempoTotal += f.value;
                        })
                        if (tiempoLargo > 0) {
                            var j: any = {};
                            j.id = th.translateService.instant('totalLargo');
                            j.index = d[0].index;
                            j.name = th.translateService.instant('totalLargo');
                            j.value = tiempoLargo;
                            j.x = d[0].index;

                            d.push(j)
                        }
                        if (tiempoTotal > 0) {
                            var j: any = {};
                            j.id = th.translateService.instant('total');
                            j.index = d[0].index;
                            j.name = th.translateService.instant('total');
                            j.value = tiempoTotal;
                            j.x = d[0].index;

                            d.push(j)
                        }
                        if (d.length > 1) {
                        }
                        if (d.length == 0)
                            return "";
                        else
                            return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
                    }
                },
                transition: {
                    duration: 2000
                },
                zoom: {
                    enabled: true,
                    justY: true
                }
            });
        }
    }

    //BOTONES REORGANIZAR START
    btnReorganizar() {
        this.prioridadCliente = 0;
        this.prioridadFacturacion = 0;
        this.prioridadOF = 0;
        this.prioridadMaquina = 0;
        this.prioridadFecha = 0;
        this.prioridadTemperatura = 0;

        this.metodologiasDePlanificacionSelected = this.metodologiasDePlanificacion[0];

        this.dias_reserva = 0;
        this.percent_cap_maquina = 0;
        this.optimizarCuelloBotella = true;
        this.replanificarTiempoEstimado = true;
        this.replanificarIneficiencias = false;
        this.planificarSoloNoPlanificadas = false;

        this.prioridadCliente = this.dtConfiguracionPlanificador.prioridadCliente;
        this.prioridadFacturacion = this.dtConfiguracionPlanificador.prioridadFacturacion;
        this.prioridadOF = this.dtConfiguracionPlanificador.prioridadOF;
        this.prioridadMaquina = this.dtConfiguracionPlanificador.prioridadMaquina;
        this.prioridadFecha = this.dtConfiguracionPlanificador.prioridadFecha;
        this.prioridadTemperatura = this.dtConfiguracionPlanificador.prioridadTemperatura;

        this.metodologiasDePlanificacionSelected = this.metodologiasDePlanificacion.find(f => f.id == this.dtConfiguracionPlanificador.metodologiaPlanificacion);

        this.dias_reserva = this.dtConfiguracionPlanificador.dias_reserva;
        this.percent_cap_maquina = this.dtConfiguracionPlanificador.percent_cap_maquina;
        this.maxCapacidadMaquina = this.dtConfiguracionPlanificador.capacidadMaquinaPlanificador200 ? 200 : 100;
        this.pasoCapacidadMaquina = this.dtConfiguracionPlanificador.capacidadMaquinaPlanificador200 ? 20 : 10;
        this.optimizarCuelloBotella = this.dtConfiguracionPlanificador.optimizarCuelloBotella;
        this.replanificarTiempoEstimado = this.dtConfiguracionPlanificador.replanificarTiempoEstimado;
        this.replanificarIneficiencias = this.dtConfiguracionPlanificador.replanificarIneficiencias;
        this.planificarSoloNoPlanificadas = this.dtConfiguracionPlanificador.planificarSoloNoPlanificadas;
        this.asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.dtConfiguracionPlanificador.asap_maximoMesesParaIniciarAntesDeFechaEntrega;

        this.modalReference = this.modalService.open(this.popupReorganizar, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
    }
    btnReorganizarTiempoEstimado() {
        this.replanificarTiempoEstimado = true;
    }
    btnReorganizarTiempoPredictivo() {
        this.replanificarTiempoEstimado = false;
    }
    btnReorganizarIneficiencia() {
        this.replanificarIneficiencias = !this.replanificarIneficiencias;
    }
    btnReorganizarAceptar() {
        var prioridadCliente: number = 0;
        var prioridadFacturacion: number = 0;
        var prioridadOF: number = 0;
        var prioridadMaquina: number = 0;
        var prioridadFecha: number = 0;
        var prioridadTemperatura: number = 0;

        var metodologiaPlanificacion: number = 0;

        var dias_reserva: number = 0;
        var percent_cap_maquina: number = 0;
        var optimizarCuelloBotella: boolean = true;
        var replanificarTiempoEstimado = true;
        var replanificarIneficiencias = false;
        var planificarSoloNoPlanificadas = false;
        var asap_maximoMesesParaIniciarAntesDeFechaEntrega = 6;

        var total: number = 0;

        total = this.prioridadCliente + this.prioridadFacturacion + this.prioridadOF + this.prioridadMaquina + this.prioridadFecha + this.prioridadTemperatura;

        prioridadCliente = this.reglaDeTres(this.prioridadCliente, total, 100);
        prioridadFacturacion = this.reglaDeTres(this.prioridadFacturacion, total, 100);
        prioridadOF = this.reglaDeTres(this.prioridadOF, total, 100);
        prioridadMaquina = this.reglaDeTres(this.prioridadMaquina, total, 100);
        prioridadFecha = this.reglaDeTres(this.prioridadFecha, total, 100);
        prioridadTemperatura = this.reglaDeTres(this.prioridadTemperatura, total, 100);

        metodologiaPlanificacion = this.metodologiasDePlanificacionSelected.id;

        dias_reserva = this.dias_reserva;
        percent_cap_maquina = this.percent_cap_maquina;

        replanificarTiempoEstimado = this.replanificarTiempoEstimado;
        replanificarIneficiencias = this.replanificarIneficiencias;
        planificarSoloNoPlanificadas = this.planificarSoloNoPlanificadas;
        asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.asap_maximoMesesParaIniciarAntesDeFechaEntrega;


        optimizarCuelloBotella = this.optimizarCuelloBotella;

        var tiempo = 1;
        if (!this.aplicarTiempoEstimado)
            tiempo = 2;

        this.planificadorService.Reorganizar_Skootik(2, this.JplanificadoresSelected.value, tiempo,// la maquina no se usa por ahora
            prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina, dias_reserva, percent_cap_maquina, prioridadFecha, prioridadTemperatura,
            metodologiaPlanificacion, optimizarCuelloBotella, 0, !replanificarTiempoEstimado, replanificarIneficiencias, planificarSoloNoPlanificadas, asap_maximoMesesParaIniciarAntesDeFechaEntrega).subscribe(
                json => {
                    var r1, r2, r3, r4 = false;
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                        (json) => {

                            this.DatOperaciones = json;

                            r1 = true;
                            if (r1 && r2 && r3 && r4) {
                                this.cargarOFs()
                                this.cargarDatosGraficos()
                            }
                        }
                    );

                    this.planificadorService.GetFechaSemanasLimite().subscribe(
                        (json) => {

                            if (Object.keys(json).length > 0) {
                                var a: any = json[0];
                                //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                                //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                                //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                                var fecha = this.startOfWeek(this.myFunctions.getDateNow());

                                var semana = this.getNumberOfWeek(fecha);
                                var año = fecha.getFullYear();

                                var fechaMax = a.fechaMax;
                                var añoMax = a.año;
                                var semanaMax = a.semana;

                                var index = 0;
                                while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
                                    //crear linea Json para añadir al "grid"
                                    var fecha2 = new Date(fecha);
                                    var js: any = {};
                                    js['value'] = index;
                                    js['fecha'] = fecha2;
                                    var fechaAnno = new Date(fecha);
                                    fechaAnno.setDate(fechaAnno.getDate() + 3);
                                    año = fechaAnno.getFullYear();
                                    js['año'] = año;
                                    js['semana'] = semana;
                                    js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                                    this.Jsemanas.push(js);

                                    //actualizar variables para proximo loop
                                    index++;
                                    fecha.setDate(fecha.getDate() + 7);
                                    semana = this.getNumberOfWeek(fecha);
                                    año = fecha.getFullYear();
                                }
                            } else {
                                //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                                //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                                //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                                var fecha = this.startOfWeek(this.myFunctions.getDateNow());
                                var semana = this.getNumberOfWeek(fecha);
                                var año = fecha.getFullYear();


                                var index = 0;
                                //crear linea Json para añadir al "grid"
                                var fecha2 = new Date(fecha);
                                var js: any = {};
                                js['value'] = index;
                                js['fecha'] = fecha2;
                                var fechaAnno = new Date(fecha);
                                fechaAnno.setDate(fechaAnno.getDate() + 3);
                                año = fechaAnno.getFullYear();
                                js['año'] = año;
                                js['semana'] = semana;
                                js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                                this.Jsemanas.push(js);
                            }

                            r2 = true;
                            if (r1 && r2 && r3 && r4) {
                                this.cargarOFs()
                                this.cargarDatosGraficos()
                            }
                        }
                    );

                    var version = this.JplanificadoresSelected.value;
                    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                        json => {

                            this.tiemposCorto = json;

                            this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                                json => {
                                    //CARGAR TIEMPOS EJECUTADOS START 
                                    //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                    var an: any = json;
                                    var operacionesOrdenadas2 = {};
                                    var operacionesEnEjecucion = {};
                                    an.forEach(
                                        a => {
                                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                            else
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                        });
                                    var an2: any = this.tiemposCorto;
                                    //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                    an2.forEach(
                                        row => {
                                            // añadir las 3 nuevas variables
                                            row['tiempoEjecutado'] = 0;
                                            row['operacionEmpezada'] = false;
                                            row['enEjecucion'] = false;

                                            //se ponen las que estan en ejecucion en este momento
                                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (enEjecucion) {
                                                if (enEjecucion == 1) {
                                                    row['enEjecucion'] = true;
                                                }
                                            }

                                            //se descuenta el tiempo por maquina y operacion
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                //se le pone que esta empezada
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                    an2.forEach(
                                        row => {
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //CARGAR TIEMPOS EJECUTADOS END
                                    //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                    var an3: any = an2;
                                    var jsonTiemposCorto: any = {};
                                    var maquinas: any = [];
                                    an3.forEach(
                                        row => {
                                            if (jsonTiemposCorto[row.idMaquina]) {
                                                jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                            }
                                            else {
                                                maquinas.push(row.idMaquina);
                                                jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                            }
                                        });
                                    var valoresFinales: any = [];
                                    maquinas.forEach(
                                        maquina => {
                                            var js: any = {};
                                            js.idMaquina = maquina;
                                            js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                            js.tiempo = jsonTiemposCorto[maquina];
                                            valoresFinales.push(js);
                                        });
                                    this.operacionesCorto = an3;
                                    this.tiemposCorto = valoresFinales;
                                    this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                    //CAMBIAR EL FORMATO AL RESULTADO END

                                    r3 = true;
                                    if (r1 && r2 && r3 && r4) {
                                        this.cargarDatosGraficos();
                                        this.cargarOFs();
                                    }
                                }
                            );


                            r3 = true;
                            if (r1 && r2 && r3 && r4) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                            }
                        }
                    );

                    this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                        (json) => {

                            // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                                grupo => {
                                    grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r4 = true;
                            if (r1 && r2 && r3 && r4) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                            }
                        }
                    );


                }
            );

        this.modalReference.close();
    }
    //BOTONES REORGANIZAR END
    // btnReorganizarAceptar() {
    //   var prioridadCliente: number = 0;
    //   var prioridadFacturacion: number = 0;
    //   var prioridadOF: number = 0;
    //   var prioridadMaquina: number = 0;
    //   var prioridadFecha: number = 0;
    //   var prioridadTemperatura: number = 0;

    //   var metodologiaPlanificacion: number = 0;

    //   var dias_reserva: number = 0;
    //   var percent_cap_maquina: number = 0;
    //   var optimizarCuelloBotella: boolean = true;
    //   var replanificarTiempoEstimado = true;
    //   var replanificarIneficiencias = false;

    //   var total: number = 0;

    //   total = this.prioridadCliente + this.prioridadFacturacion + this.prioridadOF + this.prioridadMaquina + this.prioridadFecha + this.prioridadTemperatura;

    //   prioridadCliente = this.reglaDeTres(this.prioridadCliente, total, 100);
    //   prioridadFacturacion = this.reglaDeTres(this.prioridadFacturacion, total, 100);
    //   prioridadOF = this.reglaDeTres(this.prioridadOF, total, 100);
    //   prioridadMaquina = this.reglaDeTres(this.prioridadMaquina, total, 100);
    //   prioridadFecha = this.reglaDeTres(this.prioridadFecha, total, 100);
    //   prioridadTemperatura = this.reglaDeTres(this.prioridadTemperatura, total, 100);

    //   metodologiaPlanificacion = this.metodologiasDePlanificacionSelected.id;

    //   dias_reserva = this.dias_reserva;
    //   percent_cap_maquina = this.percent_cap_maquina;

    //   replanificarTiempoEstimado = this.replanificarTiempoEstimado;
    //   replanificarIneficiencias = this.replanificarIneficiencias;


    //   optimizarCuelloBotella = this.optimizarCuelloBotella;

    //   var tiempo = 1;
    //   if (!this.aplicarTiempoEstimado)
    //     tiempo = 2;

    //   var version = "real";
    //   if (this.JplanificadoresSelected.value == 2) version = "v1";
    //   else if (this.JplanificadoresSelected.value == 3) version = "v2";
    //   else if (this.JplanificadoresSelected.value == 4) version = "v3";

    //   var prioridadDeMaquina = prioridadMaquina >= 25

    //   this.planificadorService.Reorganizar_Planificador(2, version, prioridadDeMaquina, metodologiaPlanificacion, percent_cap_maquina, prioridadCliente
    //     , prioridadFacturacion, prioridadOF, prioridadFecha, prioridadTemperatura, replanificarTiempoEstimado, dias_reserva, optimizarCuelloBotella).subscribe(
    //     json => {
    //       this.popupReorganizandoVersion = true;
    //       this.actualizarReorganizandoVersion()
    //       // var r1, r2, r3 = false;
    //       // this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
    //       //   (json) => {

    //       //     this.DatOperaciones = json;

    //       //     r1 = true;
    //       //     if (r1 && r2 && r3) {
    //       //       this.cargarOFs()
    //       //       this.cargarDatosGraficos()
    //       //     }
    //       //   }
    //       // );

    //       // this.planificadorService.GetFechaSemanasLimite().subscribe(
    //       //   (json) => {

    //       //     if (Object.keys(json).length > 0) {
    //       //       var a: any = json[0];
    //       //       //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
    //       //       //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
    //       //       //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
    //       //       //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
    //       //       var fecha = this.startOfWeek(this.myFunctions.getDateNow());

    //       //       var semana = this.getNumberOfWeek(fecha);
    //       //       var año = fecha.getFullYear();

    //       //       var fechaMax = a.fechaMax;
    //       //       var añoMax = a.año;
    //       //       var semanaMax = a.semana;

    //       //       var index = 0;
    //       //       while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
    //       //         //crear linea Json para añadir al "grid"
    //       //         var fecha2 = new Date(fecha);
    //       //         var js: any = {};
    //       //         js['value'] = index;
    //       //         js['fecha'] = fecha2;
    //       //         js['año'] = año;
    //       //         js['semana'] = semana;
    //       //         js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
    //       //         this.Jsemanas.push(js);

    //       //         //actualizar variables para proximo loop
    //       //         index++;
    //       //         fecha.setDate(fecha.getDate() + 7);
    //       //         semana = this.getNumberOfWeek(fecha);
    //       //         año = fecha.getFullYear();
    //       //       }
    //       //     } else {
    //       //       //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
    //       //       //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
    //       //       //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
    //       //       //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
    //       //       var fecha = this.startOfWeek(this.myFunctions.getDateNow());
    //       //       var semana = this.getNumberOfWeek(fecha);
    //       //       var año = fecha.getFullYear();


    //       //       var index = 0;
    //       //       //crear linea Json para añadir al "grid"
    //       //       var fecha2 = new Date(fecha);
    //       //       var js: any = {};
    //       //       js['value'] = index;
    //       //       js['fecha'] = fecha2;
    //       //       js['año'] = año;
    //       //       js['semana'] = semana;
    //       //       js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
    //       //       this.Jsemanas.push(js);
    //       //     }

    //       //     r2 = true;
    //       //     if (r1 && r2 && r3) {
    //       //       this.cargarOFs()
    //       //       this.cargarDatosGraficos()
    //       //     }
    //       //   }
    //       // );

    //       // var version = this.JplanificadoresSelected.value;
    //       // this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
    //       //   json => {

    //       //     this.tiemposCorto = json;

    //       //     this.planificadorService.Get_tiempos_ejecutados().subscribe(
    //       //       json => {
    //       //         //CARGAR TIEMPOS EJECUTADOS START 
    //       //         //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
    //       //         var an: any = json;
    //       //         var operacionesOrdenadas2 = {};
    //       //         var operacionesEnEjecucion = {};
    //       //         an.forEach(
    //       //           a => {
    //       //             if (operacionesOrdenadas2[a['idOFs_Operacion']])
    //       //               operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
    //       //             else
    //       //               operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

    //       //             operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
    //       //             operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
    //       //           });
    //       //         var an2: any = this.tiemposCorto;
    //       //         //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
    //       //         an2.forEach(
    //       //           row => {
    //       //             // añadir las 3 nuevas variables
    //       //             row['tiempoEjecutado'] = 0;
    //       //             row['operacionEmpezada'] = false;
    //       //             row['enEjecucion'] = false;

    //       //             //se ponen las que estan en ejecucion en este momento
    //       //             var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
    //       //             if (enEjecucion) {
    //       //               if (enEjecucion == 1) {
    //       //                 row['enEjecucion'] = true;
    //       //               }
    //       //             }

    //       //             //se descuenta el tiempo por maquina y operacion
    //       //             var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
    //       //             if (tiempoEjecutado) {
    //       //               //se le pone que esta empezada
    //       //               if (tiempoEjecutado > 0) {
    //       //                 row['operacionEmpezada'] = true;
    //       //                 //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
    //       //                 var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
    //       //                 //se descuenta el tiempo de las 3 partes que afecta 
    //       //                 if (tiempoEjecutado <= maximoTiempoAppointment) {
    //       //                   row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
    //       //                   operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
    //       //                   operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
    //       //                 } else {
    //       //                   operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
    //       //                   operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
    //       //                   row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
    //       //                 }
    //       //               }
    //       //             }
    //       //           });
    //       //         //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
    //       //         an2.forEach(
    //       //           row => {
    //       //             var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
    //       //             if (tiempoEjecutado) {
    //       //               if (tiempoEjecutado > 0) {
    //       //                 row['operacionEmpezada'] = true;
    //       //                 //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
    //       //                 //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
    //       //                 var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
    //       //                 //se descuenta el tiempo de las 3 partes que afecta 
    //       //                 if (tiempoEjecutado <= maximoTiempoAppointment) {
    //       //                   row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
    //       //                   operacionesOrdenadas2[row['idOperacion']] = 0;
    //       //                 } else {
    //       //                   operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
    //       //                   row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
    //       //                 }
    //       //               }
    //       //             }
    //       //           });
    //       //         //CARGAR TIEMPOS EJECUTADOS END
    //       //         //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
    //       //         var an3: any = an2;
    //       //         var jsonTiemposCorto: any = {};
    //       //         var maquinas: any = [];
    //       //         an3.forEach(
    //       //           row => {
    //       //             if (jsonTiemposCorto[row.idMaquina]) {
    //       //               jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
    //       //             }
    //       //             else {
    //       //               maquinas.push(row.idMaquina);
    //       //               jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
    //       //             }
    //       //           });
    //       //         var valoresFinales: any = [];
    //       //         maquinas.forEach(
    //       //           maquina => {
    //       //             var js: any = {};
    //       //             js.idMaquina = maquina;
    //       //             js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
    //       //             js.tiempo = jsonTiemposCorto[maquina];
    //       //             valoresFinales.push(js);
    //       //           });
    //       //         this.operacionesCorto = an3;
    //       //         this.tiemposCorto = valoresFinales;
    //       //         this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
    //       //         //CAMBIAR EL FORMATO AL RESULTADO END

    //       //         r3 = true;
    //       //         if (r1 && r2 && r3) {
    //       //           this.cargarDatosGraficos();
    //       //           this.cargarOFs();
    //       //         }
    //       //       }
    //       //     );


    //       //     r3 = true;
    //       //     if (r1 && r2 && r3) {
    //       //       this.cargarDatosGraficos();
    //       //       this.cargarOFs();
    //       //     }
    //       //   }
    //       // );
    //     }
    //   );

    //   this.modalReference.close();
    // }
    btnCopiarAVersion() {
        this.modalReference = this.modalService.open(this.popupCopiarAVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
    }
    btnCopiarAVersionAceptar() {
        this.planificadorService.copiarAVersion(this.JplanificadoresSelected.value, this.JplanificadoresSelectedCopiar.value, 2).subscribe(
            json => {
                this.JplanificadoresSelected = this.JplanificadoresSelectedCopiar;

                var r1, r2, r3, r4 = false;
                this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                    (json) => {
                        r1 = true;

                        this.DatOperaciones = json;

                        if (r1 && r2 && r3 && r4) {
                            this.cargarOFs()
                            this.cargarDatosGraficos()
                        }
                    }
                );

                this.planificadorService.GetFechaSemanasLimite().subscribe(
                    (json) => {
                        r2 = true;

                        if (Object.keys(json).length > 0) {
                            var a: any = json[0];
                            //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                            //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                            //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                            var fecha = this.startOfWeek(this.myFunctions.getDateNow());

                            var semana = this.getNumberOfWeek(fecha);
                            var año = fecha.getFullYear();

                            var fechaMax = a.fechaMax;
                            var añoMax = a.año;
                            var semanaMax = a.semana;

                            var index = 0;
                            while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
                                //crear linea Json para añadir al "grid"
                                var fecha2 = new Date(fecha);
                                var js: any = {};
                                js['value'] = index;
                                js['fecha'] = fecha2;
                                var fechaAnno = new Date(fecha);
                                fechaAnno.setDate(fechaAnno.getDate() + 3);
                                año = fechaAnno.getFullYear();
                                js['año'] = año;
                                js['semana'] = semana;
                                js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                                this.Jsemanas.push(js);

                                //actualizar variables para proximo loop
                                index++;
                                fecha.setDate(fecha.getDate() + 7);
                                semana = this.getNumberOfWeek(fecha);
                                año = fecha.getFullYear();
                            }
                        } else {
                            //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                            //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                            //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                            var fecha = this.startOfWeek(this.myFunctions.getDateNow());
                            var semana = this.getNumberOfWeek(fecha);
                            var año = fecha.getFullYear();


                            var index = 0;
                            //crear linea Json para añadir al "grid"
                            var fecha2 = new Date(fecha);
                            var js: any = {};
                            js['value'] = index;
                            js['fecha'] = fecha2;
                            var fechaAnno = new Date(fecha);
                            fechaAnno.setDate(fechaAnno.getDate() + 3);
                            año = fechaAnno.getFullYear();
                            js['año'] = año;
                            js['semana'] = semana;
                            js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                            this.Jsemanas.push(js);
                        }

                        if (r1 && r2 && r3 && r4) {
                            this.cargarOFs()
                            this.cargarDatosGraficos()
                        }
                    }
                );

                var version = this.JplanificadoresSelected.value;
                this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                    json => {

                        this.tiemposCorto = json;
                        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                            json => {
                                //CARGAR TIEMPOS EJECUTADOS START 
                                //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                var an: any = json;
                                var operacionesOrdenadas2 = {};
                                var operacionesEnEjecucion = {};
                                an.forEach(
                                    a => {
                                        if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                            operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                        else
                                            operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                        operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                        operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                    });
                                var an2: any = this.tiemposCorto;
                                //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                an2.forEach(
                                    row => {
                                        // añadir las 3 nuevas variables
                                        row['tiempoEjecutado'] = 0;
                                        row['operacionEmpezada'] = false;
                                        row['enEjecucion'] = false;

                                        //se ponen las que estan en ejecucion en este momento
                                        var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                        if (enEjecucion) {
                                            if (enEjecucion == 1) {
                                                row['enEjecucion'] = true;
                                            }
                                        }

                                        //se descuenta el tiempo por maquina y operacion
                                        var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                        if (tiempoEjecutado) {
                                            //se le pone que esta empezada
                                            if (tiempoEjecutado > 0) {
                                                row['operacionEmpezada'] = true;
                                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                //se descuenta el tiempo de las 3 partes que afecta 
                                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                } else {
                                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                }
                                            }
                                        }
                                    });
                                //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                an2.forEach(
                                    row => {
                                        var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                        if (tiempoEjecutado) {
                                            if (tiempoEjecutado > 0) {
                                                row['operacionEmpezada'] = true;
                                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                //se descuenta el tiempo de las 3 partes que afecta 
                                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                    operacionesOrdenadas2[row['idOperacion']] = 0;
                                                } else {
                                                    operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                }
                                            }
                                        }
                                    });
                                //CARGAR TIEMPOS EJECUTADOS END
                                //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                var an3: any = an2;
                                var jsonTiemposCorto: any = {};
                                var maquinas: any = [];
                                an3.forEach(
                                    row => {
                                        if (jsonTiemposCorto[row.idMaquina]) {
                                            jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                        }
                                        else {
                                            maquinas.push(row.idMaquina);
                                            jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                        }
                                    });
                                var valoresFinales: any = [];
                                maquinas.forEach(
                                    maquina => {
                                        var js: any = {};
                                        js.idMaquina = maquina;
                                        js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                        js.tiempo = jsonTiemposCorto[maquina];
                                        valoresFinales.push(js);
                                    });
                                this.operacionesCorto = an3;
                                this.tiemposCorto = valoresFinales;
                                this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                //CAMBIAR EL FORMATO AL RESULTADO END

                                r3 = true;
                                if (r1 && r2 && r3 && r4) {
                                    this.cargarDatosGraficos();
                                    this.cargarOFs();
                                }
                            }
                        );

                        this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                            (json) => {

                                // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                                var grupos = (json as any).datOperaciones_grupos
                                grupos.forEach(
                                    grupo => {
                                        grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                    });

                                this.Jgrupos = grupos;

                                this.DatOperaciones_grupos = grupos;

                                r4 = true;
                                if (r1 && r2 && r3 && r4) {
                                    this.cargarDatosGraficos();
                                    this.cargarOFs();
                                }
                            }
                        );

                    }
                );

                if (this.JplanificadoresSelected.value > 1)
                    this.visibleUsarVersion = true;
                else
                    this.visibleUsarVersion = false;

            });

        this.modalReference.close();
    }

    btnPlanificar() {
        this.requiereMaquina = false;
        this.requiereSemana = false;
        this.modalReference = this.modalService.open(this.popupPlanificar, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
        //this.popupPlanificar = true;
    }

    eliminarValorSemana() {
        this.asignarSemanaEntrega = !this.asignarSemanaEntrega;
        if (this.asignarSemanaEntrega)
            this.JsemanaSelected = undefined;
    }

    eliminarValorMaquina() {
        this.todasMaquinas = !this.todasMaquinas;
        if (this.todasMaquinas)
            this.JmaquinaSelected = undefined;
    }

    btnAnadir() {
        var salir = false;
        if (this.JmaquinaSelected == undefined && !this.todasMaquinas) {
            this.requiereMaquina = true;
            salir = true;
        }
        if (this.JsemanaSelected == undefined && !this.asignarSemanaEntrega) {
            this.requiereSemana = true;
            salir = true;
        }

        if (!salir) {
            this.loadingPanel = true;
            var reqInsertar: any = [];

            var idsOperacionSimultanea_tratadas = [];

            //MAQUINA QUE SE AÑADIRA POR DEFECTO
            var idMaquina = -1
            if (this.JmaquinaSelected != undefined) idMaquina = parseInt(this.JmaquinaSelected.id);
            // Se miran todas las piezas y se cogen las seleccionadas.
            this.piezas.forEach(
                pieza => {
                    if (this.piezasSelected.includes(pieza.idOperacion)) {

                        // En caso de no haber especificado maquina, se añade a la de la propia operacion
                        if (this.todasMaquinas) {
                            if (pieza.idsMaquinas != "") {
                                if (pieza.idsMaquinas.split(",")[1] != '-1') {
                                    var r2: any = {};
                                    if (pieza.idsMaquinas.includes(",")) {
                                        if (pieza.idsMaquinas.split(",")[0] != "") {
                                            idMaquina = parseInt(pieza.idsMaquinas.split(",")[0]);
                                        } else {
                                            idMaquina = parseInt(pieza.idsMaquinas.split(",")[1]);
                                        }
                                    } else {
                                        idMaquina = parseInt(pieza.idsMaquinas);
                                    }
                                }
                            }
                        }
                        if (pieza.idOperacionSimultanea > 0 && !idsOperacionSimultanea_tratadas.includes(pieza.idOperacionSimultanea)) {
                            // SI TIENE OP SIMULTANEAS
                            var ops_simultaneas = this.piezas.filter(f => f.idOperacionSimultanea == pieza.idOperacionSimultanea);
                            // Las ordenamos en el orden requerido.
                            ops_simultaneas.sort((s1, s2) => {
                                if (s1.orden > s2.orden) return 1
                                else if (s1.orden < s2.orden) return -1
                                else return 0
                            });

                            // Sacamos el orden de simultanea que tiene la operacion seleccionada
                            var i = 0;
                            var index = 0;
                            ops_simultaneas.forEach(
                                operacion => {
                                    if (operacion.idOperacion == pieza.idOperacion) index = i;
                                    i++;
                                });

                            // Sacamos la primera ruta que nos coincida con la maquina 
                            var cadenaEncontrada = [];
                            this.cadenas_Maquinas.forEach(
                                cadena => {
                                    if (cadena[index] == idMaquina) {
                                        cadenaEncontrada = cadena;
                                    }
                                });
                            // Las añadimos para el insert
                            var ordenSimultanea = 0
                            ops_simultaneas.forEach(
                                operacion => {
                                    if (cadenaEncontrada[ordenSimultanea] == undefined) {
                                        this.annadirOperacionParaInsert(operacion, reqInsertar, idMaquina);
                                    } else {
                                        this.annadirOperacionParaInsert(operacion, reqInsertar, cadenaEncontrada[ordenSimultanea]);
                                    }
                                    ordenSimultanea++;
                                });
                            idsOperacionSimultanea_tratadas.push(pieza.idOperacionSimultanea)
                        } else {
                            // SI NO TIENE OP SIMULTANEAS
                            this.annadirOperacionParaInsert(pieza, reqInsertar, idMaquina);
                        }
                    }
                }
            );

            if (reqInsertar.length > 0) {
                this.loading_anadir = true;
                this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
                    //cerrar popup
                    //this.popupPlanificar = false;
                    var r1, r2, r3: boolean = false;
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                        (json) => {
                            r1 = true;

                            this.DatOperaciones = json;
                            if (r1 && r2 && r3) {
                                this.cargarDatosGraficos();
                                this.cargarOFs()
                                this.loadingPanel = false;
                            }
                        }
                    );

                    var version = this.JplanificadoresSelected.value;
                    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                        json => {

                            this.tiemposCorto = json;

                            this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                                json => {
                                    //CARGAR TIEMPOS EJECUTADOS START 
                                    //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                    var an: any = json;
                                    var operacionesOrdenadas2 = {};
                                    var operacionesEnEjecucion = {};
                                    an.forEach(
                                        a => {
                                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                            else
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                        });
                                    var an2: any = this.tiemposCorto;
                                    //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                    an2.forEach(
                                        row => {
                                            // añadir las 3 nuevas variables
                                            row['tiempoEjecutado'] = 0;
                                            row['operacionEmpezada'] = false;
                                            row['enEjecucion'] = false;

                                            //se ponen las que estan en ejecucion en este momento
                                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (enEjecucion) {
                                                if (enEjecucion == 1) {
                                                    row['enEjecucion'] = true;
                                                }
                                            }

                                            //se descuenta el tiempo por maquina y operacion
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                //se le pone que esta empezada
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                    an2.forEach(
                                        row => {
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //CARGAR TIEMPOS EJECUTADOS END
                                    //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                    var an3: any = an2;
                                    var jsonTiemposCorto: any = {};
                                    var maquinas: any = [];
                                    an3.forEach(
                                        row => {
                                            if (jsonTiemposCorto[row.idMaquina]) {
                                                jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                            }
                                            else {
                                                maquinas.push(row.idMaquina);
                                                jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                            }
                                        });
                                    var valoresFinales: any = [];
                                    maquinas.forEach(
                                        maquina => {
                                            var js: any = {};
                                            js.idMaquina = maquina;
                                            js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                            js.tiempo = jsonTiemposCorto[maquina];
                                            valoresFinales.push(js);
                                        });
                                    this.operacionesCorto = an3;
                                    this.tiemposCorto = valoresFinales;
                                    this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                    //CAMBIAR EL FORMATO AL RESULTADO END

                                    r2 = true;
                                    if (r1 && r2 && r3) {
                                        this.cargarDatosGraficos();
                                        this.cargarOFs();
                                        this.loadingPanel = false;
                                    }
                                }
                            );
                        }
                    );


                    this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                        (json) => {

                            // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                                grupo => {
                                    grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r3 = true;
                            if (r1 && r2 && r3) {
                                this.cargarDatosGraficos();
                                this.cargarOFs();
                                this.loadingPanel = false;
                            }
                        }
                    );
                });
            } else {
                this.loadingPanel = false;
            }
        }
    }

    // Como ahora se hacen simultaneas y normales, ambas pasan por esta operacion
    annadirOperacionParaInsert(pieza, reqInsertar, idMaquina) {
        var r2: any = {};
        r2.idMaquina = idMaquina;
        if (this.asignarSemanaEntrega)
            r2.fecha = pieza.fechafin;
        else
            r2.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año));
        r2.idOperacion = pieza.idOperacion;
        r2.idOperacionesGrupos = pieza.idOperacionesGrupos;
        if (pieza.restantes < pieza.restantesSelected)
            r2.cantidad = pieza.restantes;
        else
            r2.cantidad = pieza.restantesSelected;
        r2.version = this.JplanificadoresSelected.value;
        reqInsertar.push(r2);
    }

    btnBorrarVersion() {
        this.modalReference = this.modalService.open(this.popupBorrarVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
    }
    btnBorrarVersionAceptar() {
        this.planificadorService.borrarVersion(this.JplanificadoresSelected.value, 2).subscribe(
            json => {
                var r1, r2, r3, r4 = false;
                this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                    (json) => {
                        r1 = true;

                        this.DatOperaciones = json;

                        if (r1 && r2 && r3 && r4) {
                            this.cargarOFs()
                            this.cargarDatosGraficos()
                        }
                    }
                );

                this.planificadorService.GetFechaSemanasLimite().subscribe(
                    (json) => {
                        r2 = true;

                        if (Object.keys(json).length > 0) {
                            var a: any = json[0];
                            //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                            //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                            //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                            var fecha = this.startOfWeek(this.myFunctions.getDateNow());

                            var semana = this.getNumberOfWeek(fecha);
                            var año = fecha.getFullYear();

                            var fechaMax = a.fechaMax;
                            var añoMax = a.año;
                            var semanaMax = a.semana;

                            var index = 0;
                            while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
                                //crear linea Json para añadir al "grid"
                                var fecha2 = new Date(fecha);
                                var js: any = {};
                                js['value'] = index;
                                js['fecha'] = fecha2;
                                var fechaAnno = new Date(fecha);
                                fechaAnno.setDate(fechaAnno.getDate() + 3);
                                año = fechaAnno.getFullYear();
                                js['año'] = año;
                                js['semana'] = semana;
                                js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                                this.Jsemanas.push(js);

                                //actualizar variables para proximo loop
                                index++;
                                fecha.setDate(fecha.getDate() + 7);
                                semana = this.getNumberOfWeek(fecha);
                                año = fecha.getFullYear();
                            }
                        } else {
                            //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                            //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                            //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                            var fecha = this.startOfWeek(this.myFunctions.getDateNow());
                            var semana = this.getNumberOfWeek(fecha);
                            var año = fecha.getFullYear();


                            var index = 0;
                            //crear linea Json para añadir al "grid"
                            var fecha2 = new Date(fecha);
                            var js: any = {};
                            js['value'] = index;
                            js['fecha'] = fecha2;
                            var fechaAnno = new Date(fecha);
                            fechaAnno.setDate(fechaAnno.getDate() + 3);
                            año = fechaAnno.getFullYear();
                            js['año'] = año;
                            js['semana'] = semana;
                            js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                            this.Jsemanas.push(js);
                        }

                        if (r1 && r2 && r3 && r4) {
                            this.cargarOFs()
                            this.cargarDatosGraficos()
                        }
                    }
                );

                var version = this.JplanificadoresSelected.value;
                this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                    json => {

                        this.tiemposCorto = json;

                        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                            json => {
                                //CARGAR TIEMPOS EJECUTADOS START 
                                //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                var an: any = json;
                                var operacionesOrdenadas2 = {};
                                var operacionesEnEjecucion = {};
                                an.forEach(
                                    a => {
                                        if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                            operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                        else
                                            operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                        operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                        operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                    });
                                var an2: any = this.tiemposCorto;
                                //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                an2.forEach(
                                    row => {
                                        // añadir las 3 nuevas variables
                                        row['tiempoEjecutado'] = 0;
                                        row['operacionEmpezada'] = false;
                                        row['enEjecucion'] = false;

                                        //se ponen las que estan en ejecucion en este momento
                                        var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                        if (enEjecucion) {
                                            if (enEjecucion == 1) {
                                                row['enEjecucion'] = true;
                                            }
                                        }

                                        //se descuenta el tiempo por maquina y operacion
                                        var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                        if (tiempoEjecutado) {
                                            //se le pone que esta empezada
                                            if (tiempoEjecutado > 0) {
                                                row['operacionEmpezada'] = true;
                                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                //se descuenta el tiempo de las 3 partes que afecta 
                                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                } else {
                                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                }
                                            }
                                        }
                                    });
                                //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                an2.forEach(
                                    row => {
                                        var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                        if (tiempoEjecutado) {
                                            if (tiempoEjecutado > 0) {
                                                row['operacionEmpezada'] = true;
                                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                //se descuenta el tiempo de las 3 partes que afecta 
                                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                    operacionesOrdenadas2[row['idOperacion']] = 0;
                                                } else {
                                                    operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                }
                                            }
                                        }
                                    });
                                //CARGAR TIEMPOS EJECUTADOS END
                                //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                var an3: any = an2;
                                var jsonTiemposCorto: any = {};
                                var maquinas: any = [];
                                an3.forEach(
                                    row => {
                                        if (jsonTiemposCorto[row.idMaquina]) {
                                            jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                        }
                                        else {
                                            maquinas.push(row.idMaquina);
                                            jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                        }
                                    });
                                var valoresFinales: any = [];
                                maquinas.forEach(
                                    maquina => {
                                        var js: any = {};
                                        js.idMaquina = maquina;
                                        js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                        js.tiempo = jsonTiemposCorto[maquina];
                                        valoresFinales.push(js);
                                    });
                                this.operacionesCorto = an3;
                                this.tiemposCorto = valoresFinales;
                                this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                //CAMBIAR EL FORMATO AL RESULTADO END


                                r3 = true;
                                if (r1 && r2 && r3 && r4) {
                                    this.cargarDatosGraficos();
                                    this.cargarOFs();
                                }
                            }
                        );


                    }
                );


                this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                    (json) => {

                        // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                        var grupos = (json as any).datOperaciones_grupos
                        grupos.forEach(
                            grupo => {
                                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                            });

                        this.Jgrupos = grupos;

                        this.DatOperaciones_grupos = grupos;

                        r4 = true;
                        if (r1 && r2 && r3 && r4) {
                            this.cargarOFs()
                            this.cargarDatosGraficos()
                        }
                    }
                );
            });

        this.modalReference.close();
    }

    btnComparativaVersiones() {
        const url = this.router.serializeUrl(this.router.parseUrl('#/planificadorLargoComparativaVersiones'));
        window.open(url, '_blank');
    }


    public showGridTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
            && element.offsetWidth < element.scrollWidth) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    cargarDatosFiltro() {
        //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

        this.planificadorService.Get_filtro_Ofs(version, 0, 0).subscribe((data: any) => {

            this.dataFiltro = data;

            var groupByCliente = [];
            var groupByPieza = [];
            var groupByOf = [];
            var groupByOperacion = [];
            var groupByParte = [];

            //GROUP BY POR OF
            data.forEach(function (a) {
                if (!this[a.idOf]) {
                    this[a.idOf] = {
                        idOf: a.idOf, nombreOf: a.nombreOf,
                    };
                    groupByOf.push(this[a.idOf]);
                }
            }, Object.create(null));

            this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
            this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

            //GROUP BY POR CLIENTE
            data.forEach(function (a) {
                if (!this[a.idCliente]) {
                    this[a.idCliente] = {
                        idCliente: a.idCliente, nombreCliente: a.nombreCliente,
                    };
                    groupByCliente.push(this[a.idCliente]);
                }
            }, Object.create(null));

            this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
            this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

            //GROUP BY POR PIEZA
            var kontagailua = 1;
            var listaPiezasSinRepetidos = [];
            data.forEach(function (a) {
                if (listaPiezasSinRepetidos.filter(x => x.nombrePieza == a.nombrePieza).length == 0 && a.idPieza != -1) {
                    listaPiezasSinRepetidos.push({ idPieza: kontagailua, nombrePieza: a.nombrePieza, ids: [a.idPieza] });
                    kontagailua++;
                }
                else {
                    listaPiezasSinRepetidos.filter(x => x.nombrePieza == a.nombrePieza)[0].ids.push(a.idPieza);
                }
                // if (!this[a.idPieza]) {

                //   this[a.idPieza] = {
                //     idPieza: a.idPieza, nombrePieza: a.nombrePieza,
                //   };
                //   groupByPieza.push(this[a.idPieza]);
                // }
            }, Object.create(null));

            //this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
            this.listaPiezas = listaPiezasSinRepetidos;
            this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

        });

    }
    CambioFiltro() {
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

        this.planificadorService.Get_filtro_Ofs(version, 0, 0).subscribe((data: any) => {
            this.dataFiltro = data;
        });
        var data: any = this.dataFiltro;

        var idsOFs = [];
        if (this.ofsSeleccionados != undefined)
            this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

        var idsClientes = [];
        if (this.clientesSeleccionados != undefined)
            this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

        var idsPiezas = [];
        if (this.piezasSeleccionados != undefined)
            this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.idPieza));

        var groupByCliente = [];
        var groupByPieza = [];
        var groupByOf = [];

        //GROUP BY POR OF
        var lag: any = {};
        data.forEach(
            row => {
                if (!lag[row.idOf]
                    && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
                    && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
                ) {
                    lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
                    groupByOf.push(lag[row.idOf]);
                }
            });

        this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
        this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

        //GROUP BY POR CLIENTE
        lag = {};
        data.forEach(
            row => {
                if (!lag[row.idCliente]
                    && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
                    && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
                ) {
                    lag[row.idCliente] = {
                        idCliente: row.idCliente, nombreCliente: row.nombreCliente,
                    };
                    groupByCliente.push(lag[row.idCliente]);
                }
            });

        this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
        this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

        //GROUP BY POR PIEZA
        lag = {};
        var kontagailua = 1;
        var listaPiezasSinRepetidos = [];
        data.forEach(
            row => {
                if (//!lag[row.idPieza]
                    //listaPiezasSinRepetidos.filter(x => x.nombrePieza==row.nombrePieza).length==0 && 
                    (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
                    && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
                ) {
                    // lag[row.idPieza] = {
                    //   idPieza: row.idPieza, nombrePieza: row.nombrePieza, ids: [row.idPieza]
                    // };
                    // groupByPieza.push(lag[row.idPieza]);
                    if (listaPiezasSinRepetidos.filter(x => x.nombrePieza == row.nombrePieza).length == 0) {
                        listaPiezasSinRepetidos.push({ idPieza: kontagailua, nombrePieza: row.nombrePieza, ids: [row.idPieza] });
                        kontagailua++;
                    }
                    else listaPiezasSinRepetidos.filter(x => x.nombrePieza == row.nombrePieza)[0].ids.push(row.idPieza);

                }
            });

        //this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
        this.listaPiezas = listaPiezasSinRepetidos.filter(item => (item.idPieza != -1));;
        this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));
    }


    btnFiltrar() {

        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;
        this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {

                this.tiemposCorto = json;

                this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                    json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                            a => {
                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                else
                                    operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                            });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                            row => {
                                // añadir las 3 nuevas variables
                                row['tiempoEjecutado'] = 0;
                                row['operacionEmpezada'] = false;
                                row['enEjecucion'] = false;

                                //se ponen las que estan en ejecucion en este momento
                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                if (enEjecucion) {
                                    if (enEjecucion == 1) {
                                        row['enEjecucion'] = true;
                                    }
                                }

                                //se descuenta el tiempo por maquina y operacion
                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                if (tiempoEjecutado) {
                                    //se le pone que esta empezada
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                            row => {
                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                if (tiempoEjecutado) {
                                    if (tiempoEjecutado > 0) {
                                        row['operacionEmpezada'] = true;
                                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                        //se descuenta el tiempo de las 3 partes que afecta 
                                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                            operacionesOrdenadas2[row['idOperacion']] = 0;
                                        } else {
                                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                        }
                                    }
                                }
                            });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                            row => {
                                if (jsonTiemposCorto[row.idMaquina]) {
                                    jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                }
                                else {
                                    maquinas.push(row.idMaquina);
                                    jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                }
                            });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                            maquina => {
                                var js: any = {};
                                js.idMaquina = maquina;
                                js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                js.tiempo = jsonTiemposCorto[maquina];
                                valoresFinales.push(js);
                            });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END

                        this.cargarDatosGraficos()
                        //this.cargarGraficos();
                        this.cargarOFs();
                        this.cargarDatosFiltro();

                        if (this.JplanificadoresSelected.value > 1)
                            this.visibleUsarVersion = true;
                        else
                            this.visibleUsarVersion = false;
                    }
                );

            }
        );
    }
    btnLista() {
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

        const url = this.router.serializeUrl(this.router.parseUrl('#/planificadorLista/' + version + '/' + 1 + '/' + 0));
        window.open(url, '_blank');
    }

    btnActualizarReorganizandoVersion() {
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;
        this.actualizarReorganizandoVersion();
    }

    actualizarReorganizandoVersion() {
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

        this.planificadorService.Get_ultima_reorganizacion(2, version).subscribe(
            json => {
                var an: any = json;
                if (an.length == 0) {
                    if (this.popupReorganizandoVersion) {
                        this.popupReorganizandoVersion = false;

                        var r1, r2, r3 = false;

                        // CONFIGURACION DE PLANIFICADOR
                        this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
                            this.dtConfiguracionPlanificador = result[0];

                            this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
                            this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
                            this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;
                            this.semanasMostradas = this.dtConfiguracionPlanificador.semanasMostradasPlanificadorLargoMaquinas;
                            this.semanasPorDesplazamiento = this.dtConfiguracionPlanificador.semanasPorDesplazamientoPlanificadorLargoMaquinas;
                            this.agruparOperacionesPorColor = this.dtConfiguracionPlanificador.agruparOperacionesPorColorPlanificadorLargoMaquinas;

                            this.planificadorService.Get_tiempos_planificador_corto(0, this.JplanificadoresSelected.value, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                                json => {
                                    this.tiemposCorto = json;

                                    this.planificadorService.Get_tiempos_ejecutados(this.JplanificadoresSelected.value).subscribe(
                                        json => {
                                            //CARGAR TIEMPOS EJECUTADOS START 
                                            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                            var an: any = json;
                                            var operacionesOrdenadas2 = {};
                                            var operacionesEnEjecucion = {};
                                            an.forEach(
                                                a => {
                                                    if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                                    else
                                                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                                    operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                                    operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                                });
                                            var an2: any = this.tiemposCorto;
                                            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                            an2.forEach(
                                                row => {
                                                    // añadir las 3 nuevas variables
                                                    row['tiempoEjecutado'] = 0;
                                                    row['operacionEmpezada'] = false;
                                                    row['enEjecucion'] = false;

                                                    //se ponen las que estan en ejecucion en este momento
                                                    var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                                    if (enEjecucion) {
                                                        if (enEjecucion == 1) {
                                                            row['enEjecucion'] = true;
                                                        }
                                                    }

                                                    //se descuenta el tiempo por maquina y operacion
                                                    var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                                    if (tiempoEjecutado) {
                                                        //se le pone que esta empezada
                                                        if (tiempoEjecutado > 0) {
                                                            row['operacionEmpezada'] = true;
                                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                            //se descuenta el tiempo de las 3 partes que afecta 
                                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                            } else {
                                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                            }
                                                        }
                                                    }
                                                });
                                            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                            an2.forEach(
                                                row => {
                                                    var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                                    if (tiempoEjecutado) {
                                                        if (tiempoEjecutado > 0) {
                                                            row['operacionEmpezada'] = true;
                                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                            //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                            //se descuenta el tiempo de las 3 partes que afecta 
                                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idOperacion']] = 0;
                                                            } else {
                                                                operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                            }
                                                        }
                                                    }
                                                });
                                            //CARGAR TIEMPOS EJECUTADOS END
                                            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                            var an3: any = an2;
                                            var jsonTiemposCorto: any = {};
                                            var maquinas: any = [];
                                            an3.forEach(
                                                row => {
                                                    if (jsonTiemposCorto[row.idMaquina]) {
                                                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                                    }
                                                    else {
                                                        maquinas.push(row.idMaquina);
                                                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                                    }
                                                });
                                            var valoresFinales: any = [];
                                            maquinas.forEach(
                                                maquina => {
                                                    var js: any = {};
                                                    js.idMaquina = maquina;
                                                    js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                                    js.tiempo = jsonTiemposCorto[maquina];
                                                    valoresFinales.push(js);
                                                });
                                            this.operacionesCorto = an3;
                                            this.tiemposCorto = valoresFinales;
                                            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                            //CAMBIAR EL FORMATO AL RESULTADO END

                                            this.popupReorganizandoVersion = false; //si no se pone en no visible, la propia funcion de crear graficos llama a comprovar version y vuelve a llamar a crear graficos

                                            r2 = true;
                                            if (r1 && r2 && r3) {
                                                this.cargarDatosGraficos()
                                                //this.cargarGraficos();
                                                this.cargarOFs();
                                            }
                                            this.cargarDatosFiltro();

                                            if (this.JplanificadoresSelected.value > 1)
                                                this.visibleUsarVersion = true;
                                            else
                                                this.visibleUsarVersion = false;
                                        }
                                    );

                                }
                            );


                            this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                                (json) => {

                                    this.DatOperaciones = json;

                                    r1 = true;
                                    if (r1 && r2 && r3) {
                                        this.cargarDatosGraficos();
                                        this.cargarOFs();
                                    }
                                }
                            );


                            this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                                (json) => {

                                    // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                                    var grupos = (json as any).datOperaciones_grupos
                                    grupos.forEach(
                                        grupo => {
                                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                        });

                                    this.Jgrupos = grupos;

                                    this.DatOperaciones_grupos = grupos;

                                    r3 = true;
                                    if (r1 && r2 && r3) {
                                        this.cargarDatosGraficos();
                                        this.cargarOFs();
                                    }
                                }
                            );
                        });
                    }
                } else {
                    this.popupReorganizandoVersion = true;
                    if (this.router.url.includes('/planificadorLargoMaquinas')) {
                        setTimeout((d) => this.actualizarReorganizandoVersion(), 1000);
                    }
                }
            });
    }

    /*ATRASAR UNA SEMANA START*/
    btnAtrasarUnaSemana() {
        this.modalReference = this.modalService.open(this.popupAtrasarFijados, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
    btnAtrasarFijadosCancelar() {
        this.loadingPanel = true;
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;
        this.planificadorService.AtrasarUnaSemana(version, false, 0).subscribe(
            e => {
                this.modalReference.close();
                this.cargarDatosGraficos();
                this.cargarOFs();
            });
    }
    btnAtrasarFijadosAceptar() {
        this.loadingPanel = true;
        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;
        this.planificadorService.AtrasarUnaSemana(version, true, 0).subscribe(
            e => {
                this.modalReference.close();
                this.cargarDatosGraficos();
                this.cargarOFs();
            });
    }
    /*ATRASAR UNA SEMANA END*/

    /*REPLANIFICAR ULTIMA SEMANA START*/
    btnReplanificarTodoUltimaSemana() {
        this.modalReference = this.modalService.open(this.popupReplanificarAultimaSemana, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
    btnReplanificarAultimaSemanaAceptar() {
        this.loadingPanel = true;
        var version = this.JplanificadoresSelected.value;
        this.planificadorService.ReplanificarTodoUltimaSemana(version).subscribe(
            json => {
                this.modalReference.close();
                this.cargarOFs()
                this.cargarDatosGraficos()
            });
    }
    /*REPLANIFICAR ULTIMA SEMANA END*/

    /*POPUP MOVER TODAS OPERACIONES START*/
    btnMoverOperacionesCancelar() {
        this.moverOperaciones_todas = false;

        this.modalReference.close();

        if (this.user.planificador == 2) this.moverOperacion_Check_operacionPartida();  //Dejar mover operaciones sólo si el usuario tiene permisos
    }

    btnMoverOperacionesAceptar() {
        this.moverOperaciones_todas = true;

        this.modalReference.close();

        if (this.user.planificador == 2) this.moverOperacion_Check_operacionPartida();  //Dejar mover operaciones sólo si el usuario tiene permisos
    }

    moverOperacion_Check_operacionPartida() {
        var e = this.moverOperaciones_e;
        var semana = this.moverOperaciones_semana;
        // preparamos las variables para un codigo mas comprensible
        var splitted = e.subject.id.split(" - ", 2);
        var idParte: string = '0';
        if (splitted.length > 0)
            idParte = splitted[0].replace("-", "");

        var idOperacionVisible: number = 0;
        if (splitted.length > 1)
            idOperacionVisible = splitted[1].replace("-", "");

        var semanaActual = this.getNumberOfWeek(new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate()));
        var semanaDragged = e.subject.index
        //var dragged = this.DatOperaciones.find(f => f.idParte == id && f.semana == semanaDragged + semanaActual && f.idMaquina == this.selectedMaquina);

        var dragged = [];
        var añoActual = this.Jsemanas[0].año;
        var intentos = 0;
        //this.JoperacionesVal = [];
        var semanaDraggedTemp = semanaDragged;
        while (dragged.length == 0 && intentos < 120) {

            var semanaParaProbar = semanaDraggedTemp + semanaActual

            if (semanaParaProbar < 0)
                semanaParaProbar += 53 //algo mas que las semanas maximas por año... porseacaso

            if (!idParte.includes(this.translateService.instant("grupo")))
                dragged = this.DatOperaciones.filter(f => f.idParte == idParte && f.semana == semanaParaProbar && f.idMaquina == this.moverOperaciones_idMaquina && (idOperacionVisible == 0 || f.idOperacion == idOperacionVisible))
            else
                dragged = this.DatOperaciones_grupos.filter(f => this.translateService.instant("grupo") + " " + f.idOperacionesGrupos == idParte && f.semana == semanaParaProbar && f.idMaquina == this.moverOperaciones_idMaquina && (idOperacionVisible == 0 || f.idOperacion == idOperacionVisible))


            semanaDraggedTemp--;
            intentos++;
        }

        if (semana != semanaDragged && dragged.length > 0) {
            if (dragged[0].dividida < 2) {
                // se crea la estructura necesaria para la API
                var req: any = [];
                var req2: any = [];

                dragged.forEach(
                    dragRow => {
                        //    Sacar
                        var r: any = {};
                        r.idMaquina = this.moverOperaciones_idMaquina;
                        r.fecha = this.dateToYYYYMMDDtHHmmSSz(this.Jsemanas[semanaDragged].fecha);
                        r.idOperacion = dragRow.idOperacion;
                        r.idOperacionesGrupos = dragRow.idOperacionesGrupos;
                        r.nuevaCantidad = 0;
                        r.version = this.JplanificadoresSelected.value;
                        req.push(r);
                        //    Meter
                        var r_2: any = {};
                        r_2.idMaquina = this.moverOperaciones_idMaquina;
                        r_2.fecha = this.dateToYYYYMMDDtHHmmSSz(this.Jsemanas[semana].fecha);
                        r_2.idRuta = dragRow.idRuta;
                        r_2.idOperacion = dragRow.idOperacion;
                        r_2.idOperacionesGrupos = dragRow.idOperacionesGrupos;
                        r_2.cantidad = dragRow.cantidad;
                        r_2.version = this.JplanificadoresSelected.value;
                        req2.push(r_2);
                    });

                if (req.length > 0 && req2.length > 0 && this.user.planificador == 2) {
                    this.loadingPanel = true;
                    var r11, r12: boolean = false;
                    this.planificadorService.UpdateCantidades(req).subscribe(r => {
                        r11 = true;
                        if (r11 && r12) {
                            //this.visibleInfo = false;
                            var r1, r2, r3: boolean = false;
                            //this.cargarGraficos();
                            this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                                (json) => {
                                    this.DatOperaciones = json;
                                    var an: any = json;
                                    this.DatOperaciones = an.filter(f => f.idMaquina == this.moverOperaciones_idMaquina)

                                    r1 = true;
                                    if (r1 && r2 && r3) {
                                        this.operacionesSelected = [];
                                        //this.cargarGraficos();

                                        this.cargarDatosGraficos()
                                        this.cargarOFs();
                                        this.cargarDatosFiltro();
                                    }
                                }
                            );
                            var version = this.JplanificadoresSelected.value;
                            this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                                json => {
                                    this.tiemposCorto = json;


                                    this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                                        json => {
                                            //CARGAR TIEMPOS EJECUTADOS START 
                                            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                            var an: any = json;
                                            var operacionesOrdenadas2 = {};
                                            var operacionesEnEjecucion = {};
                                            an.forEach(
                                                a => {
                                                    if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                                    else
                                                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                                    operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                                    operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                                });
                                            var an2: any = this.tiemposCorto;
                                            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                            an2.forEach(
                                                row => {
                                                    // añadir las 3 nuevas variables
                                                    row['tiempoEjecutado'] = 0;
                                                    row['operacionEmpezada'] = false;
                                                    row['enEjecucion'] = false;

                                                    //se ponen las que estan en ejecucion en este momento
                                                    var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                                    if (enEjecucion) {
                                                        if (enEjecucion == 1) {
                                                            row['enEjecucion'] = true;
                                                        }
                                                    }

                                                    //se descuenta el tiempo por maquina y operacion
                                                    var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                                    if (tiempoEjecutado) {
                                                        //se le pone que esta empezada
                                                        if (tiempoEjecutado > 0) {
                                                            row['operacionEmpezada'] = true;
                                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                            //se descuenta el tiempo de las 3 partes que afecta 
                                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                            } else {
                                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                            }
                                                        }
                                                    }
                                                });
                                            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                            an2.forEach(
                                                row => {
                                                    var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                                    if (tiempoEjecutado) {
                                                        if (tiempoEjecutado > 0) {
                                                            row['operacionEmpezada'] = true;
                                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                            //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                            //se descuenta el tiempo de las 3 partes que afecta 
                                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idOperacion']] = 0;
                                                            } else {
                                                                operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                            }
                                                        }
                                                    }
                                                });
                                            //CARGAR TIEMPOS EJECUTADOS END
                                            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                            var an3: any = an2;
                                            var jsonTiemposCorto: any = {};
                                            var maquinas: any = [];
                                            an3.forEach(
                                                row => {
                                                    if (jsonTiemposCorto[row.idMaquina]) {
                                                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                                    }
                                                    else {
                                                        maquinas.push(row.idMaquina);
                                                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                                    }
                                                });
                                            var valoresFinales: any = [];
                                            maquinas.forEach(
                                                maquina => {
                                                    var js: any = {};
                                                    js.idMaquina = maquina;
                                                    js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                                    js.tiempo = jsonTiemposCorto[maquina];
                                                    valoresFinales.push(js);
                                                });
                                            this.operacionesCorto = an3;
                                            this.tiemposCorto = valoresFinales;
                                            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                            //CAMBIAR EL FORMATO AL RESULTADO END

                                            r2 = true;

                                            if (r1 && r2 && r3) {
                                                this.operacionesSelected = [];
                                                // this.cargarGraficos();

                                                this.cargarDatosGraficos()
                                                this.cargarOFs();
                                                this.cargarDatosFiltro();
                                            }
                                        }
                                    );

                                }
                            );
                            this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                                (json) => {

                                    // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                                    var grupos = (json as any).datOperaciones_grupos
                                    grupos.forEach(
                                        grupo => {
                                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                        });

                                    this.Jgrupos = grupos;

                                    this.DatOperaciones_grupos = grupos;

                                    r3 = true;
                                    if (r1 && r2 && r3) {
                                        this.operacionesSelected = [];
                                        //this.cargarGraficos();

                                        this.cargarDatosGraficos()
                                        this.cargarOFs();
                                        this.cargarDatosFiltro();
                                    }
                                }
                            );
                        }
                    });

                    this.planificadorService.InsertOperacionesPlanificadas(req2).subscribe(r => {
                        r12 = true;
                        if (r11 && r12) {
                            //this.visibleInfo = false;
                            var r1, r2, r3: boolean = false;
                            //this.cargarGraficos();
                            this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                                (json) => {
                                    r1 = true;
                                    this.DatOperaciones = json;
                                    var an: any = json;
                                    this.DatOperaciones = an.filter(f => f.idMaquina == this.moverOperaciones_idMaquina)

                                    if (r1 && r2 && r3) {
                                        this.operacionesSelected = [];
                                        // this.cargarGraficos();

                                        this.cargarDatosGraficos()
                                        this.cargarOFs();
                                        this.cargarDatosFiltro();
                                    }
                                }
                            );
                            var version = this.JplanificadoresSelected.value;
                            this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                                json => {
                                    this.tiemposCorto = json;

                                    this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                                        json => {
                                            //CARGAR TIEMPOS EJECUTADOS START 
                                            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                            var an: any = json;
                                            var operacionesOrdenadas2 = {};
                                            var operacionesEnEjecucion = {};
                                            an.forEach(
                                                a => {
                                                    if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                                    else
                                                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                                    operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                                    operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                                });
                                            var an2: any = this.tiemposCorto;
                                            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                            an2.forEach(
                                                row => {
                                                    // añadir las 3 nuevas variables
                                                    row['tiempoEjecutado'] = 0;
                                                    row['operacionEmpezada'] = false;
                                                    row['enEjecucion'] = false;

                                                    //se ponen las que estan en ejecucion en este momento
                                                    var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                                    if (enEjecucion) {
                                                        if (enEjecucion == 1) {
                                                            row['enEjecucion'] = true;
                                                        }
                                                    }

                                                    //se descuenta el tiempo por maquina y operacion
                                                    var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                                    if (tiempoEjecutado) {
                                                        //se le pone que esta empezada
                                                        if (tiempoEjecutado > 0) {
                                                            row['operacionEmpezada'] = true;
                                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                            //se descuenta el tiempo de las 3 partes que afecta 
                                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                            } else {
                                                                operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                                operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                            }
                                                        }
                                                    }
                                                });
                                            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                            an2.forEach(
                                                row => {
                                                    var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                                    if (tiempoEjecutado) {
                                                        if (tiempoEjecutado > 0) {
                                                            row['operacionEmpezada'] = true;
                                                            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                            //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                            //se descuenta el tiempo de las 3 partes que afecta 
                                                            if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                                operacionesOrdenadas2[row['idOperacion']] = 0;
                                                            } else {
                                                                operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                                row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                            }
                                                        }
                                                    }
                                                });
                                            //CARGAR TIEMPOS EJECUTADOS END
                                            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                            var an3: any = an2;
                                            var jsonTiemposCorto: any = {};
                                            var maquinas: any = [];
                                            an3.forEach(
                                                row => {
                                                    if (jsonTiemposCorto[row.idMaquina]) {
                                                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                                    }
                                                    else {
                                                        maquinas.push(row.idMaquina);
                                                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                                    }
                                                });
                                            var valoresFinales: any = [];
                                            maquinas.forEach(
                                                maquina => {
                                                    var js: any = {};
                                                    js.idMaquina = maquina;
                                                    js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                                    js.tiempo = jsonTiemposCorto[maquina];
                                                    valoresFinales.push(js);
                                                });
                                            this.operacionesCorto = an3;
                                            this.tiemposCorto = valoresFinales;
                                            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                            //CAMBIAR EL FORMATO AL RESULTADO END

                                            r2 = true;

                                            if (r1 && r2 && r3) {
                                                this.operacionesSelected = [];
                                                //this.cargarGraficos();

                                                this.cargarDatosGraficos()
                                                this.cargarOFs();
                                                this.cargarDatosFiltro();
                                            }
                                        }
                                    );


                                }
                            );
                            this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                                (json) => {

                                    // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                                    var grupos = (json as any).datOperaciones_grupos
                                    grupos.forEach(
                                        grupo => {
                                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                        });

                                    this.Jgrupos = grupos;

                                    this.DatOperaciones_grupos = grupos;

                                    r3 = true;
                                    if (r1 && r2 && r3) {
                                        this.operacionesSelected = [];
                                        //this.cargarGraficos();

                                        this.cargarDatosGraficos()
                                        this.cargarOFs();
                                        this.cargarDatosFiltro();
                                    }
                                }
                            );
                        }
                    });

                    if (this.moverOperaciones_todas) {
                        this.planificadorService.mover_operacionesRelacionadas(semana - semanaDragged, req2).subscribe();
                    }
                }
            }
            else {
                this.draggedOperacionPartida = dragged;
                this.semanaDraggedOperacionPartida = semanaDragged;
                this.semanaActualOperacionPartida = semanaActual;
                this.semanaOperacionPartida = semana;
                this.idMaquinaOperacionPartida = this.moverOperaciones_idMaquina;
                this.modalReference = this.modalService.open(this.popupOperacionPartida, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            }
        }
        else {
            // this.cargarGraficos();

            this.cargarDatosGraficos()
            this.cargarOFs();
            this.cargarDatosFiltro();
        }
    }

    /*POPUP MOVER TODAS OPERACIONES END*/
    btnOperacionPartidaAceptar() {
        // se crea la estructura necesaria para la API
        //    Sacar
        var req: any = [];
        var req2: any = [];

        var dragged = this.draggedOperacionPartida;
        var semanaDragged = this.semanaDraggedOperacionPartida;
        var semanaActual = this.semanaActualOperacionPartida;
        var semana = this.semanaOperacionPartida;
        var idMaquina = this.idMaquinaOperacionPartida;

        // se crea la estructura necesaria para la API
        //    Sacar
        var req: any = [];
        var req2: any = [];

        dragged.forEach(
            dragRow => {
                var r: any = {};
                r.idMaquina = idMaquina;
                r.fecha = this.getDateOfISOWeek(semanaDragged + semanaActual + 1, dragRow.año); //si aqui se le pone +1 no borra nada.
                r.idOperacion = dragRow.idOperacion;
                r.nuevaCantidad = 0;
                r.version = this.JplanificadoresSelected.value;
                req.push(r);
                //    Meter
                var r_2: any = {};
                r_2.idMaquina = idMaquina;
                r_2.fecha = this.getDateOfISOWeek(semana + semanaActual + 1, dragRow.año); // SI ES LUNES - 2 HACE MAL Y -1 BIEN //si se le pone +1 añade una semana mas tarde
                r_2.idRuta = dragRow.idRuta;
                r_2.idOperacion = dragRow.idOperacion;
                r_2.cantidad = dragRow.cantidad;
                r_2.version = this.JplanificadoresSelected.value;
                req2.push(r_2);
            });

        if (req.length > 0 && req2.length > 0) {
            this.loadingPanel = true;
            var r11, r12: boolean = false;
            this.planificadorService.UpdateCantidades(req).subscribe(r => {
                r11 = true;
                if (r11 && r12) {
                    var r1, r2, r3: boolean = false;
                    //this.cargarGraficos();
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                        (json) => {
                            r1 = true;

                            this.DatOperaciones = json;
                            var an: any = json;
                            //this.DatOperaciones = an.filter(f => f.idMaquina == idMaquina) //solo cuando es una maquina!

                            if (r1 && r2 && r3) {
                                this.operacionesSelected = [];

                                this.cargarDatosGraficos()
                                this.cargarOFs();
                                this.cargarDatosFiltro();

                            }
                        }
                    );
                    var version = this.JplanificadoresSelected.value;
                    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                        json => {

                            this.tiemposCorto = json;
                            this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                                json => {
                                    //CARGAR TIEMPOS EJECUTADOS START 
                                    //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                    var an: any = json;
                                    var operacionesOrdenadas2 = {};
                                    var operacionesEnEjecucion = {};
                                    an.forEach(
                                        a => {
                                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                            else
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                        });
                                    var an2: any = this.tiemposCorto;
                                    //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                    an2.forEach(
                                        row => {
                                            // añadir las 3 nuevas variables
                                            row['tiempoEjecutado'] = 0;
                                            row['operacionEmpezada'] = false;
                                            row['enEjecucion'] = false;

                                            //se ponen las que estan en ejecucion en este momento
                                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (enEjecucion) {
                                                if (enEjecucion == 1) {
                                                    row['enEjecucion'] = true;
                                                }
                                            }

                                            //se descuenta el tiempo por maquina y operacion
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                //se le pone que esta empezada
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                    an2.forEach(
                                        row => {
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //CARGAR TIEMPOS EJECUTADOS END
                                    //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                    var an3: any = an2;
                                    var jsonTiemposCorto: any = {};
                                    var maquinas: any = [];
                                    an3.forEach(
                                        row => {
                                            if (jsonTiemposCorto[row.idMaquina]) {
                                                jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                            }
                                            else {
                                                maquinas.push(row.idMaquina);
                                                jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                            }
                                        });
                                    var valoresFinales: any = [];
                                    maquinas.forEach(
                                        maquina => {
                                            var js: any = {};
                                            js.idMaquina = maquina;
                                            js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                            js.tiempo = jsonTiemposCorto[maquina];
                                            valoresFinales.push(js);
                                        });
                                    this.operacionesCorto = an3;
                                    this.tiemposCorto = valoresFinales;
                                    this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                    //CAMBIAR EL FORMATO AL RESULTADO END

                                    r2 = true;
                                    if (r1 && r2 && r3) {
                                        this.operacionesSelected = [];

                                        this.cargarDatosGraficos()
                                        this.cargarOFs();
                                        this.cargarDatosFiltro();

                                    }
                                }
                            );

                        }
                    );
                    this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                        (json) => {

                            // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                                grupo => {
                                    grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r3 = true;
                            if (r1 && r2 && r3) {
                                this.operacionesSelected = [];

                                this.cargarDatosGraficos()
                                this.cargarOFs();
                                this.cargarDatosFiltro();
                            }
                        }
                    );
                }
            });
            this.planificadorService.InsertOperacionesPlanificadas(req2).subscribe(r => {
                r12 = true;
                if (r11 && r12) {
                    var r1, r2, r3: boolean = false;
                    //this.cargarGraficos();
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                        (json) => {
                            r1 = true;

                            this.DatOperaciones = json;
                            var an: any = json;
                            //this.DatOperaciones = an.filter(f => f.idMaquina == idMaquina) //solo cuando es una maquina!

                            if (r1 && r2 && r3) {
                                this.operacionesSelected = [];

                                this.cargarDatosGraficos()
                                this.cargarOFs();
                                this.cargarDatosFiltro();

                            }
                        }
                    );
                    var version = this.JplanificadoresSelected.value;
                    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                        json => {

                            this.tiemposCorto = json;

                            this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                                json => {
                                    //CARGAR TIEMPOS EJECUTADOS START 
                                    //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                                    var an: any = json;
                                    var operacionesOrdenadas2 = {};
                                    var operacionesEnEjecucion = {};
                                    an.forEach(
                                        a => {
                                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                            else
                                                operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                                        });
                                    var an2: any = this.tiemposCorto;
                                    //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                                    an2.forEach(
                                        row => {
                                            // añadir las 3 nuevas variables
                                            row['tiempoEjecutado'] = 0;
                                            row['operacionEmpezada'] = false;
                                            row['enEjecucion'] = false;

                                            //se ponen las que estan en ejecucion en este momento
                                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (enEjecucion) {
                                                if (enEjecucion == 1) {
                                                    row['enEjecucion'] = true;
                                                }
                                            }

                                            //se descuenta el tiempo por maquina y operacion
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                //se le pone que esta empezada
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                                    an2.forEach(
                                        row => {
                                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                            if (tiempoEjecutado) {
                                                if (tiempoEjecutado > 0) {
                                                    row['operacionEmpezada'] = true;
                                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                                    //se descuenta el tiempo de las 3 partes que afecta 
                                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                                        operacionesOrdenadas2[row['idOperacion']] = 0;
                                                    } else {
                                                        operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                                        row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                                    }
                                                }
                                            }
                                        });
                                    //CARGAR TIEMPOS EJECUTADOS END
                                    //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                                    var an3: any = an2;
                                    var jsonTiemposCorto: any = {};
                                    var maquinas: any = [];
                                    an3.forEach(
                                        row => {
                                            if (jsonTiemposCorto[row.idMaquina]) {
                                                jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                            }
                                            else {
                                                maquinas.push(row.idMaquina);
                                                jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                            }
                                        });
                                    var valoresFinales: any = [];
                                    maquinas.forEach(
                                        maquina => {
                                            var js: any = {};
                                            js.idMaquina = maquina;
                                            js.tipo = 1; // cuando se metan las subcontratas esto sera diferente...
                                            js.tiempo = jsonTiemposCorto[maquina];
                                            valoresFinales.push(js);
                                        });
                                    this.operacionesCorto = an3;
                                    this.tiemposCorto = valoresFinales;
                                    this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                                    //CAMBIAR EL FORMATO AL RESULTADO END


                                    r2 = true;
                                    if (r1 && r2 && r3) {
                                        this.operacionesSelected = [];

                                        this.cargarDatosGraficos()
                                        this.cargarOFs();
                                        this.cargarDatosFiltro();

                                    }
                                }
                            );


                        }
                    );
                    this.planificadorService.get_grupos(this.JplanificadoresSelected.value).subscribe(
                        (json) => {

                            // diria que esta linea no hace falta this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones; 

                            var grupos = (json as any).datOperaciones_grupos
                            grupos.forEach(
                                grupo => {
                                    grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                                });

                            this.Jgrupos = grupos;

                            this.DatOperaciones_grupos = grupos;

                            r3 = true;
                            if (r1 && r2 && r3) {
                                this.operacionesSelected = [];

                                this.cargarDatosGraficos()
                                this.cargarOFs();
                                this.cargarDatosFiltro();
                            }
                        }
                    );
                }
            });

            if (this.moverOperaciones_todas) {
                this.planificadorService.mover_operacionesRelacionadas(semana - semanaDragged, req2).subscribe();
            }
        }

        this.draggedOperacionPartida = undefined;
        this.semanaDraggedOperacionPartida = undefined;
        this.semanaActualOperacionPartida = undefined;
        this.semanaOperacionPartida = undefined;
        this.idMaquinaOperacionPartida = undefined;

        this.modalReference.close();
    }

    private getGrafico() {
        this.actualizarReorganizandoVersion();

        var version = 1;
        if (this.JplanificadoresSelected != undefined)
            version = this.JplanificadoresSelected.value;

        var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.ids);
        listaIdPiezas = listaIdPiezas.reduce((acc, curVal) => { return acc.concat(curVal) }, []); //flatten array of arrays

        //todas las OF tienen que tener un valor asignado para cada semana. En caso de tener que marcar algo en rojo tambien se tedria que añadir aparte
        // LEEME   POR FIN TENEMOS FUERA DE TALLER!!!!
        //          el fuera de taller no se hace con la columna fuera de taller, esta se ha quedado OBSOLETA
        //          se hace con tipo 1 o tipo 2. tipo 1 hacer referencia a maquinas, tipo 2 a subcontratas
        var r1, r2, r3 = false
        this.planificadorService.get_largoMaquina(-1, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias, 1).subscribe(
            json => {

                this.dtOperacionesPlanificador = (json as any).dtOperaciones;
                this.dtTiemposMaquinas = (json as any).dtTiempos;

                this.Jplanificador = [];
                var dtOperaciones = this.myFunctions.copy(this.dtOperacionesPlanificador);
                dtOperaciones.forEach(operacion => {
                    // Cogemos el tiempo estimado de esa operacion para esa maquina
                    var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacion.idMaquina &&
                        ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
                            (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
                    // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
                    if (tiempos.length == 0) {
                        tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
                            (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
                    }
                    if (tiempos.length > 0) {
                        // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                        // 1. tiempo estimado o predictivo
                        // 2. tiene preparacion si es principal = 1
                        var tiempo = tiempos[0];
                        if (this.aplicarTiempoEstimado) {
                            operacion.tiempoEstimado = tiempo.tiempoEstimadoEjecucion * operacion.cantidadPlanificada
                            if (operacion.principal)
                                operacion.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
                        } else {
                            operacion.tiempoEstimado = tiempo.tiempoPredictivoEjecucion * operacion.cantidadPlanificada
                            if (operacion.principal)
                                operacion.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
                        }
                        // 3. se aplica infeciciencia en caso de ser necesario
                        operacion.tiempoIneficiencia = 0;
                        if (this.aplicarIneficiencias) {
                            operacion.tiempoIneficiencia = (operacion.tiempoEstimado * tiempo.ineficiencia / 100);
                        }
                        operacion.tiempo = operacion.tiempoEstimado + operacion.tiempoIneficiencia;
                    }
                    // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
                    // if ((this.JmaquinaSelected.agruparProcesos && operacion.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacion.idOperacion > 0 ){
                    this.Jplanificador.push(operacion);
                    // }
                });

                r1 = true;
                if (r1 && r2 && r3)
                    this.cargarGrafico();
            });
        this.planificadorService.get_turnos_en_fechas(-1).subscribe(
            json => {
                (json as any).forEach(
                    turno => {
                        turno.fechaIni = new Date(turno.fechaIni);
                        turno.fechaFin = new Date(turno.fechaFin);

                        turno.fechaIniTeorico = new Date(turno.fechaIniTeorico);
                        turno.fechaFinTeorico = new Date(turno.fechaFinTeorico);
                        // turno.fechaIni = this.myFunctions.sqlToJsDate(turno.fechaIni);
                        // turno.fechaFin = this.myFunctions.sqlToJsDate(turno.fechaFin);

                        // turno.fechaIniTeorico = this.myFunctions.sqlToJsDateT(turno.fechaIniTeorico);
                        // turno.fechaFinTeorico = this.myFunctions.sqlToJsDateT(turno.fechaFinTeorico);
                    });

                this.Jturnos = json;
                r2 = true;
                if (r1 && r2 && r3)
                    this.cargarGrafico();
            });
        this.planificadorService.bloqueos_por_mantenimientos(-1).subscribe(
            json => {
                // se calculan todos los bloqueos que crearan los mantenimientos
                (json as any).forEach(
                    mantenimiento => {
                        mantenimiento.fechaIni = this.myFunctions.datetimeToDate(new Date(mantenimiento.fechaIni));
                        mantenimiento.fechaFin = this.myFunctions.datetimeToDate(new Date(mantenimiento.fechaFin));
                    });

                this.Jmantenimientos = json;

                r3 = true;
                if (r1 && r2 && r3)
                    this.cargarGrafico();
            });
    }
    private cargarGrafico() {
        /* SE CALCULA EL TIEMPO DE BLOQUEOS */
        if (false) {
            var bloqueosFechas_mantenimientos = [];
            this.Jmantenimientos.forEach(
                mantenimiento => {
                    var fechaIni_mantenimiento: Date = this.myFunctions.dateAddSeconds(this.myFunctions.dateCopy(mantenimiento.fechaIni), this.myFunctions.HH_MM_ToSeconds(mantenimiento.horaIni))
                    var fechaFin_mantenimiento: Date = this.myFunctions.dateAddSeconds(this.myFunctions.dateCopy(fechaIni_mantenimiento), mantenimiento.duracion)

                    switch (mantenimiento.idTipo) {
                        case 1: //DIARIO                    
                            while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                                var bloqueoFechas_mantenimiento = {
                                    fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                                    fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                                    idMantenimiento: mantenimiento.idMantenimiento,
                                    idMaquina: mantenimiento.idMaquina
                                }
                                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                                fechaIni_mantenimiento = this.myFunctions.dateAddDays(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento);
                                fechaFin_mantenimiento = this.myFunctions.dateAddDays(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento);
                            }
                            break;
                        case 2://SEMANAL       
                            while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                                var bloqueoFechas_mantenimiento = {
                                    fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                                    fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                                    idMantenimiento: mantenimiento.idMantenimiento,
                                    idMaquina: mantenimiento.idMaquina
                                }
                                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                                fechaIni_mantenimiento = this.myFunctions.dateAddDays(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento * 7);
                                fechaFin_mantenimiento = this.myFunctions.dateAddDays(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento * 7);
                            }
                            break;
                        case 3://MENSUAL
                            while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                                var bloqueoFechas_mantenimiento = {
                                    fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                                    fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                                    idMantenimiento: mantenimiento.idMantenimiento,
                                    idMaquina: mantenimiento.idMaquina
                                }
                                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                                fechaIni_mantenimiento = this.myFunctions.dateAddMonths(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento);
                                fechaFin_mantenimiento = this.myFunctions.dateAddMonths(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento);
                            }
                            break;
                        case 4://ANUAL
                            while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                                var bloqueoFechas_mantenimiento = {
                                    fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                                    fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                                    idMantenimiento: mantenimiento.idMantenimiento,
                                    idMaquina: mantenimiento.idMaquina
                                }
                                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                                fechaIni_mantenimiento = this.myFunctions.dateAddMonths(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento * 12);
                                fechaFin_mantenimiento = this.myFunctions.dateAddMonths(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento * 12);
                            }
                            break;
                        case 5://POR TURNOS
                            var turnos_que_afectan = this.Jturnos.filter(f => f.idMaquina == mantenimiento.idMaquina && f.idTipoTurno == mantenimiento.idTurno);

                            var cont = 0;
                            turnos_que_afectan.forEach(
                                turno => {
                                    cont++; // se suma 1 al principio prara checkear la frecuencia directamente
                                    if (cont >= mantenimiento.frecuenciaMantenimiento) {
                                        var bloqueoFechas_mantenimiento = {
                                            fechaFin: this.myFunctions.dateAddSeconds(this.myFunctions.dateCopy(turno.fechaIniTeorico), mantenimiento.duracion),
                                            fechaini: this.myFunctions.dateCopy(turno.fechaIniTeorico),
                                            idMantenimiento: mantenimiento.idMantenimiento,
                                            idMaquina: mantenimiento.idMaquina
                                        }
                                        bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                                        cont = 0;
                                    }
                                });

                            break;
                    }
                });
        }
        var tiemposTurnos = [];
        var tiempoTurno = {
            año: 0
            , capacidadTurno: 0
            , cliente: ''
            , dividida: 0
            , fechaIni: ''
            , fechaLimite: ''
            , fueraDeTiempo: 0
            , fueraTaller: false
            , grupoPrincipal: -1
            , idCliente: -1
            , idOperacionesGrupos: -1
            , idMaquina: -1
            , idOF: -1
            , idOperacion: -1
            , idOperacionVisible: -1
            , idParte: -1
            , idPieza: -1
            , maquina: ''
            , operacionVisible: ''
            , parte: ''
            , pieza: ''
            , plano: ''
            , operacionEmpezada: false
            , preTiempo: 0
            , principal: 1
            , proyecto: ''
            , refOF: ''
            , refPieza: ''
            , semana: 0
            , tiempo: 0
            , tiempoEjecutado: 0
            , tiempoIneficiencia: 0
            , tiempoSinBloqueos: 0
            , tipo: 1
        }
        this.Jturnos.forEach(
            turno => {
                if (tiempoTurno.semana != turno.semana || tiempoTurno.idMaquina != turno.idMaquina) {
                    if (tiempoTurno.semana > 0) tiemposTurnos.push(this.myFunctions.copy(tiempoTurno)); // para evitar la linea 0
                    tiempoTurno = {
                        año: turno.año
                        , capacidadTurno: 0
                        , cliente: ''
                        , dividida: 0
                        , fechaIni: turno.fechaPlanificado
                        , fechaLimite: turno.fechaPlanificado
                        , fueraDeTiempo: 0
                        , fueraTaller: false
                        , grupoPrincipal: -1
                        , idCliente: -1
                        , idOperacionesGrupos: -1
                        , idMaquina: turno.idMaquina
                        , idOF: -1
                        , idOperacion: -1
                        , idOperacionVisible: -1
                        , idParte: -1
                        , idPieza: -1
                        , maquina: ''
                        , operacionVisible: ''
                        , parte: ''
                        , pieza: ''
                        , plano: ''
                        , operacionEmpezada: false
                        , preTiempo: 0
                        , principal: 1
                        , proyecto: ''
                        , refOF: ''
                        , refPieza: ''
                        , semana: turno.semana
                        , tiempo: 0
                        , tiempoEjecutado: 0
                        , tiempoIneficiencia: 0
                        , tiempoSinBloqueos: (turno.fechaFin.getTime() - turno.fechaIni.getTime()) / 1000
                        , tipo: 1
                    }
                }

                if (false) {
                    var bloqueos_turno = bloqueosFechas_mantenimientos.filter(f => f.fechaini < turno.fechaFinTeorico && f.fechaFin > turno.fechaIniTeorico && f.idMaquina == turno.idMaquina);
                    bloqueos_turno.sort((s1, s2) => {
                        if (s1.fechaini > s2.fechaini) return 1
                        else if (s1.fechaIni < s2.fechaIni) return -1
                        else return 0
                    });
                }
                var fechaMin = turno.fechaIni;
                if (false) {
                    bloqueos_turno.forEach(
                        bloqueo => {
                            if (bloqueo.fechaini > fechaMin) {
                                var dateDiff = (bloqueo.fechaini.getTime() - fechaMin.getTime()) / 1000
                                tiempoTurno.preTiempo += dateDiff;
                                tiempoTurno.tiempo += dateDiff;
                                tiempoTurno.capacidadTurno += dateDiff * turno.capacidadPorDefectoPlanificador / 100;
                            }
                            fechaMin = this.myFunctions.Max_date(bloqueo.fechaFin, fechaMin);
                        });
                }
                if (turno.fechaFin > fechaMin) {
                    var dateDiff = (turno.fechaFin.getTime() - fechaMin.getTime()) / 1000
                    tiempoTurno.preTiempo += dateDiff;
                    tiempoTurno.tiempo += dateDiff;
                    tiempoTurno.capacidadTurno += dateDiff * turno.capacidadPorDefectoPlanificador / 100;
                }
            });
        if (tiempoTurno.semana > 0)
            tiemposTurnos.push(this.myFunctions.copy(tiempoTurno));

        (tiemposTurnos as any).forEach(
            element => {
                this.Jplanificador.push(element);
            });

        /* ANTES DE NADA! DESCONTAR EL TIEMPO EN EJECUCION QUE HA SOBRADO DEL CORTO START */
        this.Jplanificador.forEach(
            row => {
                row['tiempoEjecutado'] = 0;
                row['operacionEmpezada'] = false;
                row['enEjecucion'] = false;

                //se descuenta el tiempo por maquina y operacion
                var tiempoEjecutado = this.operacionesOrdenadasEjecucionFueraCorto[row['idMaquina'] + '_' + row['idOperacion']];
                if (tiempoEjecutado) {
                    //se le pone que esta empezada
                    if (tiempoEjecutado > 0) {
                        row['operacionEmpezada'] = true;
                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                        //se descuenta el tiempo de las 3 partes que afecta 
                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] - tiempoEjecutado;
                            this.operacionesOrdenadasEjecucionFueraCorto[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                        } else {
                            this.operacionesOrdenadasEjecucionFueraCorto[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                        }
                    }
                }
            });
        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
        this.Jplanificador.forEach(
            row => {
                var tiempoEjecutado = this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']];
                if (tiempoEjecutado) {
                    if (tiempoEjecutado > 0) {
                        row['operacionEmpezada'] = true;
                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                        //se descuenta el tiempo de las 3 partes que afecta 
                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = 0;
                        } else {
                            this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                        }
                    }
                }
            });
        //   se descuenta lo ejecutado al tiempo total
        this.Jplanificador.forEach(
            row => {
                if (row['tiempoEjecutado'] > 0) {
                    row['tiempo'] = row['tiempo'] - row['tiempoEjecutado'];
                    row['preTiempo'] = row['preTiempo'] - row['tiempoEjecutado'];
                }

            });

        /* ANTES DE NADA! DESCONTAR EL TIEMPO EN EJECUCION QUE HA SOBRADO DEL CORTO END*/

        this.JplanificadorDentroTaller = this.Jplanificador.filter(function (i, n) {
            return i.fueraTaller == 0;
        });
        this.JplanificadorFueraTaller = this.Jplanificador.filter(function (i, n) {
            return i.fueraTaller == 1;
        });

        if (Object.keys(this.Jplanificador).length > 0) {
            //segun el año hay que sacar en que dia empezo y se le resta a la suma de semanas completas para tener el inicio de esa semana.
            //var inicioAñoIni = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
            //this.JfechaMin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
            //  ((inicioAñoIni.getDay() + 6) * (1000 * 60 * 60 * 24)));

            this.JfechaMin = this.startOfWeek(this.myFunctions.getDateNow());

            var Jfin = this.Jplanificador.filter(function (i, n) {
                return i.idParte != "-1";
            });
            if (Object.keys(Jfin).length == 0) {
                //var inicioAñoFin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                //this.JfechaMax = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

                this.JfechaMax = this.startOfWeek(this.myFunctions.getDateNow());
                this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * 8));
            }
            else {
                var inicioAñoFin = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0));
                this.JfechaMax = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0).getTime() +
                    (Jfin[Object.keys(Jfin).length - 1].semana * 7 * (1000 * 60 * 60 * 24)) -
                    ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));
                //var fecha: Date = Jfin[Object.keys(Jfin).length - 1].fechaIni;
                //this.JfechaMax = this.startOfWeek(fecha);

                var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
                if (weeks < 9)
                    this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * (9 - weeks)));
            }
        }
        else {
            //si nos igual da igual la semana que sea.
            //var inicioAñoIni = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
            //this.JfechaMin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
            //  ((inicioAñoIni.getDay() + 6) * (1000 * 60 * 60 * 24)));

            this.JfechaMin = this.startOfWeek(this.myFunctions.getDateNow());

            //var inicioAñoFin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
            //this.JfechaMax = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
            //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
            //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

            this.JfechaMax = this.startOfWeek(this.myFunctions.getDateNow());

            this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * 8));
        }

        this.crearDivsGrupos();
    }


    btnAgruparOperacionesPorColor() {
        this.agruparOperacionesPorColor = !this.agruparOperacionesPorColor;

        this.crearDivsGrupos();
    }


    btnMoverSemanas(desplazamiento) {
        this.desplazaminetoSemanas += desplazamiento * this.semanasPorDesplazamiento;

        this.crearDivsGrupos();
    }




    /* LAG FUNCTIONS */
    startOfWeek(date) {
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }
    getNumberOfWeek(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }
    getDateOfISOWeek(w, y) {
        var simple = new Date(y, 0, 1 + (w - 1) * 7);
        var dow = simple.getDay();
        var ISOweekStart = simple;
        if (dow <= 4)
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        else
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        return ISOweekStart;
    }
    secondsToHms(seconds: number) {
        const days = Math.floor(seconds / 86400);
        const remainderSeconds = seconds % 86400;
        const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
        return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
    }
    dateToYYYYMMDDtHHmmSSz(fecha: Date) {
        //2020-10-25T23:00:00Z
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1;
        var dia = fecha.getDate(); //getDay da el dia de la semana!
        var hora = fecha.getHours();
        var minutos = fecha.getMinutes();
        var segundos = fecha.getSeconds();
        return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
    }
    dateToYYYYMMDD(fecha: Date) {
        //2020-10-25
        var año = fecha.getFullYear();
        var mes = fecha.getMonth() + 1;
        var dia = fecha.getDate(); //getDay da el dia de la semana!
        return año + '/' + this.addZero(mes) + '/' + this.addZero(dia);
    }
    addZero(n: number) {
        if (n < 10)
            return '0' + n.toString();
        else
            return n.toString();
    }
    reglaDeTres(valor: number, total: number, sobre: number) {
        if (total == 0)
            return 0;
        else {
            return Number(Math.floor(sobre * valor / total));
        }
    }
}
