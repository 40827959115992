import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/configuracion`;

@Injectable()
export class ConfiguracionService {
  private config_global : JSON; 

  constructor(private http: HttpClient) { }

  public load_config_global() {    
    return this.http.post<JSON>(`${baseUrl}/get_config_global`, {}, { withCredentials: true });
  }
  public get_config_global() {
    return this.config_global;
  }
  public set_config_global(config_global) {
    this.config_global = config_global;
  }

  public get_emailFabricante() {
    return this.http.post<JSON>(`${baseUrl}/get_emailFabricante`, {}, { withCredentials: true });
  }

  public get_emailOperario() {
    return this.http.post<JSON>(`${baseUrl}/get_emailOperario`, {}, { withCredentials: true });
  }

  public get_configuracion() {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion`, {}, { withCredentials: true });
  }

  public get_configuracion_planificador() {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion_planificador`, {}, { withCredentials: true });
  }

  public get_configuracion_planificador_V(version: number) {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion_planificador_V/` , { version }, { withCredentials: true });
  }

  public get_configuracion_calidad() {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion_calidad`, {}, { withCredentials: true });
  }

  public get_configuracion_OEE() {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion_OEE`, {}, { withCredentials: true });
  }

  public update_configuracion(form) {
    
    var data = form.value;

    if (data.codigo == null) data.codigo = 0;
    if (data.porcentajeDisponibilidadContratoCompra == null) data.porcentajeDisponibilidadContratoCompra = 0;
    if (data.mesesPorInforme == null) data.mesesPorInforme = 0;
    if (data.disponibilidadMesInicio == null) data.disponibilidadMesInicio = { valor: 1 };

    return this.http.post<JSON>(`${baseUrl}/update_configuracion`, {
      colorFeed1: data.colorFeed1, colorFeed2: data.colorFeed2, colorFeed3: data.colorFeed3, colorFeed4: data.colorFeed4, colorFeed5: data.colorFeed5,
      colorConsumo1: data.colorConsumo1, colorConsumo2: data.colorConsumo2, colorConsumo3: data.colorConsumo3, colorConsumo4: data.colorConsumo4,
      codigo: data.codigo, porcentajeDisponibilidadContratoCompra: data.porcentajeDisponibilidadContratoCompra, mesesPorInforme: data.mesesPorInforme,
      disponibilidadMesInicio: data.disponibilidadMesInicio.valor, email: data.email, usuario: data.usuario, smtp: data.smtp, puerto: data.puerto, contrasena: data.contrasena,
      emailBuhler: data.emailBuhler, usuarioBuhler: data.usuarioBuhler, smtpBuhler: data.smtpBuhler, puertoBuhler: data.puertoBuhler, contrasenaBuhler: data.contrasenaBuhler,
      perdidaAlarmaPorDefecto: data.perdidaAlarmaPorDefecto, perdidaParadaPorDefecto: data.perdidaParadaPorDefecto, 
      perdidaMicroparadaPorDefecto: data.perdidaMicroparadaPorDefecto, alarmasDentroDeOperacion: data.alarmasDentroDeOperacion, 
      perdidaAlarmaPorDefectoSinOperacion: data.perdidaAlarmaPorDefectoSinOperacion, perdidaParadaPorDefectoSinOperacion: data.perdidaParadaPorDefectoSinOperacion,
      perdidaMicroparadaPorDefectoSinOperacion: data.perdidaMicroparadaPorDefectoSinOperacion, perdidaApagadaPorDefectoSinOperacion: data.perdidaApagadaPorDefectoSinOperacion,
      perdidaAlarmaPorDefectoConOperacion: data.perdidaAlarmaPorDefectoConOperacion, perdidaParadaPorDefectoConOperacion: data.perdidaParadaPorDefectoConOperacion,
      perdidaMicroparadaPorDefectoConOperacion: data.perdidaMicroparadaPorDefectoConOperacion, perdidaApagadaPorDefectoConOperacion: data.perdidaApagadaPorDefectoConOperacion,
      continuarOperacionConParada: data.continuarOperacionConParada, continuarOperacionConApagada: data.continuarOperacionConApagada, continuarOperacionConAlarma: data.continuarOperacionConAlarma,
      procesoIdentificadoSinTiempoEstimadoRepercuteOEE: data.procesoIdentificadoSinTiempoEstimadoRepercuteOEE, ejecucionSinOperacionRepercuteOEE: data.ejecucionSinOperacionRepercuteOEE,
      segundosParaAsignarParadasAOperacion: data.segundosParaAsignarParadasAOperacion, segundosParaAsignarApagadasAOperacion: data.segundosParaAsignarApagadasAOperacion,
      segundosParaAsignarAlarmasAOperacion: data.segundosParaAsignarAlarmasAOperacion, verColada: data.verColada, verLote: data.verLote, verNSerie: data.verNSerie
      , isQr: data.isQR, parametros: data.parametros, sep: data.sep, paramsLength: data.paramsLength, tieneSep: data.tieneSep, visorTV: data.tamanoTVSelected,
      segundosCicloMaquinasTV: data.segundosCicloMaquinasTV, disenoTv: data.disenoSelected, home_tipoCalculoLote: data.tipoCalculoLote, seguimientoOFsUltimosMeses: data.seguimientoOFsUltimosMeses
    }, { withCredentials: true });
  }

  public update_configuracion_OEE(variablesOEE) {
    
    return this.http.post<JSON>(`${baseUrl}/update_configuracion_OEE`, {
      ejecucionFueraturnoEsOperacion: variablesOEE.ejecucionFueraturnoEsOperacion, 
      ejecucionEsOperacionSegundos: variablesOEE.ejecucionEsOperacionSegundos, 
      ejecucionConOperacionRepercuteA: variablesOEE.ejecucionConOperacionRepercuteA,
      ejecucionSinOperacionRepercuteA: variablesOEE.ejecucionSinOperacionRepercuteA,
      preparacionFueraturnoEsOperacion : variablesOEE.preparacionFueraturnoEsOperacion ,
      preparacionEsOperacionSegundos: variablesOEE.preparacionEsOperacionSegundos,
      preparacionConOperacionRepercuteA: variablesOEE.preparacionConOperacionRepercuteA,
      preparacionSinOperacionRepercuteA: variablesOEE.preparacionSinOperacionRepercuteA,
      paradaFueraturnoEsOperacion: variablesOEE.paradaFueraturnoEsOperacion,
      paradaEsOperacionSegundos: variablesOEE.paradaEsOperacionSegundos,
      paradaConOperacionRepercuteA: variablesOEE.paradaConOperacionRepercuteA,
      paradaSinOperacionRepercuteA: variablesOEE.paradaSinOperacionRepercuteA,
      microparadaFueraturnoEsOperacion : variablesOEE.microparadaFueraturnoEsOperacion ,
      microparadaEsOperacionSegundos: variablesOEE.microparadaEsOperacionSegundos,
      microParadaConOperacionRepercuteA: variablesOEE.microParadaConOperacionRepercuteA,
      microParadaSinOperacionRepercuteA: variablesOEE.microParadaSinOperacionRepercuteA,
      alarmaFueraturnoEsOperacion: variablesOEE.alarmaFueraturnoEsOperacion,
      alarmaEsOperacionSegundos: variablesOEE.alarmaEsOperacionSegundos,
      alarmaConOperacionRepercuteA: variablesOEE.alarmaConOperacionRepercuteA,
      alarmaSinOperacionRepercuteA: variablesOEE.alarmaSinOperacionRepercuteA,
      mantenimientoFueraturnoEsOperacion : variablesOEE.mantenimientoFueraturnoEsOperacion ,
      mantenimientoEsOperacionSegundos : variablesOEE.mantenimientoEsOperacionSegundos ,
      mantenimientoConOperacionRepercuteA: variablesOEE.mantenimientoConOperacionRepercuteA,
      mantenimientoSinOperacionRepercuteA: variablesOEE.mantenimientoSinOperacionRepercuteA,
      apagadaFueraturnoEsOperacion: variablesOEE.apagadaFueraturnoEsOperacion,
      apagadaEsOperacionSegundos: variablesOEE.apagadaEsOperacionSegundos,
      apagadaConOperacionRepercuteA: variablesOEE.apagadaConOperacionRepercuteA,
      apagadaSinOperacionRepercuteA: variablesOEE.apagadaSinOperacionRepercuteA,
      quitarDisponibilidadDeOperacion: variablesOEE.quitarDisponibilidadDeOperacion,
      ejecucionSinOperarioFueraTurnoRepercuteOEE: variablesOEE.ejecucionSinOperarioFueraTurnoRepercuteOEE,
      preparacionSinOperarioFueraTurnoRepercuteOEE: variablesOEE.preparacionSinOperarioFueraTurnoRepercuteOEE,
      paradaSinOperarioFueraTurnoRepercuteOEE: variablesOEE.paradaSinOperarioFueraTurnoRepercuteOEE,
      microparadaSinOperarioFueraTurnoRepercuteOEE: variablesOEE.microparadaSinOperarioFueraTurnoRepercuteOEE,
      alarmaSinOperarioFueraTurnoRepercuteOEE: variablesOEE.alarmaSinOperarioFueraTurnoRepercuteOEE,
      mantenimientoSinOperarioFueraTurnoRepercuteOEE: variablesOEE.mantenimientoSinOperarioFueraTurnoRepercuteOEE,
      apagadaSinOperarioFueraTurnoRepercuteOEE: variablesOEE.apagadaSinOperarioFueraTurnoRepercuteOEE
    }, { withCredentials: true });

  }

  Update_EmailsFabricantes(dt) {
    return this.http.post<JSON>(`${baseUrl}/Update_EmailsFabricantes`, { dt }, { withCredentials: true });
  }

  Update_EmailsOperarios(dt) {
    return this.http.post<JSON>(`${baseUrl}/Update_EmailsOperarios`, { dt }, { withCredentials: true });
  }

  public update_configuracion_planificador(form, verPorOperacion, replanificarTiempoEstimado, criterioAnnadirOperacion, metodologiaPlanificacion) {

    var data = form;

    return this.http.post<JSON>(`${baseUrl}/update_configuracion_planificador`, {
      asap_maximoMesesParaIniciarAntesDeFechaEntrega: data.asap_maximoMesesParaIniciarAntesDeFechaEntrega, agruparOperacionesPorColorPlanificadorLargoMaquinas: data.agruparOperacionesPorColorPlanificadorLargoMaquinas,
      prioridadCliente: data.prioridadCliente, prioridadFacturacion: data.prioridadFacturacion, prioridadOF: data.prioridadOF, prioridadMaquina: data.prioridadMaquina, 
      prioridadFecha: data.prioridadFecha, prioridadTemperatura : data.prioridadTemperatura, dias_reserva: data.dias_reserva, percent_cap_maquina: data.percent_cap_maquina, verPorOperacion: verPorOperacion,
      replanificarTiempoEstimado: replanificarTiempoEstimado, replanificarIneficiencias: data.replanificarIneficiencias, optimizarCuelloBotella: data.optimizarCuelloBotella, tiempoparada: data.tiempoparada,
      ocultarParte: data.ocultarParte, cambioOperacion: data.cambioOperacion, limitarMovimientosMaquinasCompatibles: data.limitarMovimientosMaquinasCompatibles,
      criterioAnnadirOperacion: criterioAnnadirOperacion, metodologiaPlanificacion: metodologiaPlanificacion, operario_puede_cambiar_operacion_automatica: data.operario_puede_cambiar_operacion_automatica,
      version: data.version
    }, { withCredentials: true });

  }

  public create_configuracion_planificador(form, verPorOperacion, replanificarTiempoEstimado, criterioAnnadirOperacion, metodologiaPlanificacion) {

    var data = form;

    return this.http.post<JSON>(`${baseUrl}/create_configuracion_planificador`, {
      prioridadCliente: data.prioridadCliente, prioridadFacturacion: data.prioridadFacturacion, prioridadOF: data.prioridadOF, prioridadMaquina: data.prioridadMaquina, 
      prioridadFecha: data.prioridadFecha, dias_reserva: data.dias_reserva, percent_cap_maquina: data.percent_cap_maquina, verPorOperacion: verPorOperacion,
      replanificarTiempoEstimado: replanificarTiempoEstimado, replanificarIneficiencias: data.replanificarIneficiencias, optimizarCuelloBotella: data.optimizarCuelloBotella, tiempoparada: data.tiempoparada,
      ocultarParte: data.ocultarParte, cambioOperacion: data.cambioOperacion, limitarMovimientosMaquinasCompatibles: data.limitarMovimientosMaquinasCompatibles,
      criterioAnnadirOperacion: criterioAnnadirOperacion, metodologiaPlanificacion: metodologiaPlanificacion, operario_puede_cambiar_operacion_automatica: data.operario_puede_cambiar_operacion_automatica,
      version: data.version
    }, { withCredentials: true });

  }

  public update_configuracion_calidad(form) {

    var data = form.value;

    return this.http.post<JSON>(`${baseUrl}/update_configuracion_calidad`, {
      codigo: data.codigo, resetearFrecuencia: data.resetearFrecuencia, 
      IdControlarCotasNoCriticas: data.idControlarCotasNoCriticas,
      IdControlarCotasCriticas: data.idControlarCotasCriticas,
      IdObservacionPiezas: data.idObservacionPiezas,
      primerasPiezasNum: data.primerasPiezasNum, inputarSoloDecimales: data.inputarSoloDecimales,
      mostrarValoresNoOKOperario: data.mostrarValoresNoOKOperario
    }, { withCredentials: true });

  }

  public update_configuracion_calidad_frecuencias(frecuencias) {

    return this.http.post<JSON>(`${baseUrl}/update_configuracion_calidad_frecuencias`, { frecuencias }, { withCredentials: true });

  }

  public get_configuracion_calidad_frecuencias() {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion_calidad_frecuencias`, {}, { withCredentials: true });
  }

  public get_cuelloDeBotella() {
    return this.http.post<JSON>(`${baseUrl}/get_cuelloDeBotella`, {}, { withCredentials: true });
  }

  Update_CuelloDeBotella(idmaquinas) {
    return this.http.post<JSON>(`${baseUrl}/Update_CuelloDeBotella`, { idmaquinas }, { withCredentials: true });
  }

  public get_metodologiaDePlanificacion() {
    return this.http.post<JSON>(`${baseUrl}/get_metodologiaDePlanificacion`, {}, { withCredentials: true });
  }

  public get_criterioParaAnnadirOperacion() {
    return this.http.post<JSON>(`${baseUrl}/get_criterioParaAnnadirOperacion`, {}, { withCredentials: true });
  }

  public get_controlarCotas_DAT() {
    return this.http.post<JSON>(`${baseUrl}/get_controlarCotas_DAT`, {}, { withCredentials: true });
  }

  public get_observacionPiezas_DAT() {
    return this.http.post<JSON>(`${baseUrl}/get_observacionPiezas_DAT`, {}, { withCredentials: true });
  }

  public get_disenoTv_DAT() {
    return this.http.post<JSON>(`${baseUrl}/get_disenoTv_DAT`, {}, { withCredentials: true });
  }

  //GESTION DOCUMENTAL
  public get_configuracion_gestion_documental() {
    return this.http.post<JSON>(`${baseUrl}/get_configuracion_gestion_documental`, {}, { withCredentials: true });
  }
  public add_configuracion_gestion_documental(config) {
    return this.http.post<JSON>(`${baseUrl}/add_configuracion_gestion_documental`, {model:config}, { withCredentials: true });
  }
  public update_configuracion_gestion_documental(config) {
    debugger
    return this.http.post<JSON>(`${baseUrl}/update_configuracion_gestion_documental`, 
    {
      id:config.id,
      idTipo:config.idTipo,
      GCjson:config.GCjson,
      GCnombreBucket:config.GCnombreBucket,
      FTPservidor:config.FTPservidor,
      FTPnombreUsuario:config.FTPnombreUsuario,
      FTPpassword:config.FTPpassword,
      AZcadConexion:config.AZcadConexion,
      AZcontenedor:config.AZcontenedor
    }, { withCredentials: true });
  }
  //END GESTION DOCUMENTAL

  //#region TURNOS
  public getConfiguracionTurnos() {
    return this.http.post<JSON>(`${baseUrl}/getConfiguracionTurnos`, {}, { withCredentials: true });
  } 
  public addConfiguracionTurnos(
        lunesMannanaInicio, 
        lunesMannanaFin, 
        lunesTardeInicio, 
        lunesTardeFin, 
        lunesNocheInicio, 
        lunesNocheFin, 
        martesMannanaInicio,
        martesMannanaFin,
        martesTardeInicio, 
        martesTardeFin,
        martesNocheInicio, 
        martesNocheFin,
        miercolesMannanaInicio,
        miercolesMannanaFin,
        miercolesTardeInicio,
        miercolesTardeFin,
        miercolesNocheInicio,
        miercolesNocheFin, 
        juevesMannanaInicio, 
        juevesMannanaFin, 
        juevesTardeInicio,
        juevesTardeFin,
        juevesNocheInicio, 
        juevesNocheFin, 
        viernesMannanaInicio,
        viernesMannanaFin, 
        viernesTardeInicio, 
        viernesTardeFin, 
        viernesNocheInicio, 
        viernesNocheFin, 
        sabadoMannanaInicio,
        sabadoMannanaFin, 
        sabadoTardeInicio, 
        sabadoTardeFin, 
        sabadoNocheInicio, 
        sabadoNocheFin, 
        domingoMannanaInicio,
        domingoMannanaFin, 
        domingoTardeInicio, 
        domingoTardeFin, 
        domingoNocheInicio, 
        domingoNocheFin
  ){
    return this.http.post<JSON>(`${baseUrl}/addConfiguracionTurnos`, 
    {
      lunesMannanaInicio, 
        lunesMannanaFin, 
        lunesTardeInicio, 
        lunesTardeFin, 
        lunesNocheInicio, 
        lunesNocheFin, 
        martesMannanaInicio,
        martesMannanaFin,
        martesTardeInicio, 
        martesTardeFin,
        martesNocheInicio, 
        martesNocheFin,
        miercolesMannanaInicio,
        miercolesMannanaFin,
        miercolesTardeInicio,
        miercolesTardeFin,
        miercolesNocheInicio,
        miercolesNocheFin, 
        juevesMannanaInicio, 
        juevesMannanaFin, 
        juevesTardeInicio,
        juevesTardeFin,
        juevesNocheInicio, 
        juevesNocheFin, 
        viernesMannanaInicio,
        viernesMannanaFin, 
        viernesTardeInicio, 
        viernesTardeFin, 
        viernesNocheInicio, 
        viernesNocheFin, 
        sabadoMannanaInicio,
        sabadoMannanaFin, 
        sabadoTardeInicio, 
        sabadoTardeFin, 
        sabadoNocheInicio, 
        sabadoNocheFin, 
        domingoMannanaInicio,
        domingoMannanaFin, 
        domingoTardeInicio, 
        domingoTardeFin, 
        domingoNocheInicio, 
        domingoNocheFin
    }, { withCredentials: true });
  }
  public updateConfiguracionTurnos(
        lunesMannanaInicio, 
        lunesMannanaFin, 
        lunesTardeInicio, 
        lunesTardeFin, 
        lunesNocheInicio, 
        lunesNocheFin, 
        martesMannanaInicio,
        martesMannanaFin,
        martesTardeInicio, 
        martesTardeFin,
        martesNocheInicio, 
        martesNocheFin,
        miercolesMannanaInicio,
        miercolesMannanaFin,
        miercolesTardeInicio,
        miercolesTardeFin,
        miercolesNocheInicio,
        miercolesNocheFin, 
        juevesMannanaInicio, 
        juevesMannanaFin, 
        juevesTardeInicio,
        juevesTardeFin,
        juevesNocheInicio, 
        juevesNocheFin, 
        viernesMannanaInicio,
        viernesMannanaFin, 
        viernesTardeInicio, 
        viernesTardeFin, 
        viernesNocheInicio, 
        viernesNocheFin, 
        sabadoMannanaInicio,
        sabadoMannanaFin, 
        sabadoTardeInicio, 
        sabadoTardeFin, 
        sabadoNocheInicio, 
        sabadoNocheFin, 
        domingoMannanaInicio,
        domingoMannanaFin, 
        domingoTardeInicio, 
        domingoTardeFin, 
        domingoNocheInicio, 
        domingoNocheFin
  ){
    return this.http.post<JSON>(`${baseUrl}/updateConfiguracionTurnos`, 
    {
      lunesMannanaInicio, 
        lunesMannanaFin, 
        lunesTardeInicio, 
        lunesTardeFin, 
        lunesNocheInicio, 
        lunesNocheFin, 
        martesMannanaInicio,
        martesMannanaFin,
        martesTardeInicio, 
        martesTardeFin,
        martesNocheInicio, 
        martesNocheFin,
        miercolesMannanaInicio,
        miercolesMannanaFin,
        miercolesTardeInicio,
        miercolesTardeFin,
        miercolesNocheInicio,
        miercolesNocheFin, 
        juevesMannanaInicio, 
        juevesMannanaFin, 
        juevesTardeInicio,
        juevesTardeFin,
        juevesNocheInicio, 
        juevesNocheFin, 
        viernesMannanaInicio,
        viernesMannanaFin, 
        viernesTardeInicio, 
        viernesTardeFin, 
        viernesNocheInicio, 
        viernesNocheFin, 
        sabadoMannanaInicio,
        sabadoMannanaFin, 
        sabadoTardeInicio, 
        sabadoTardeFin, 
        sabadoNocheInicio, 
        sabadoNocheFin, 
        domingoMannanaInicio,
        domingoMannanaFin, 
        domingoTardeInicio, 
        domingoTardeFin, 
        domingoNocheInicio, 
        domingoNocheFin
    }, { withCredentials: true });
  }

  //#endregion
}
