import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, Eskola_CursosLectivosService, UsuariosService, Eskola_AlumnosService, EskolaGruposService, Eskola_AsignaturasRetosService, Eskola_OfsService, Eskola_OfsPiezasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-lanzadorOf',
  templateUrl: './eskola_lanzadorOf.component.html'
})
export class Eskola_LanzadorOfComponent implements OnInit {
  @ViewChild(' #multiselectAlumnos') list;

  modalReference: NgbModalRef;
  user = this.userService.userValue;
  comboAlumnos: any = [];
  auxComboAlumnos: any = [];
  allAlumnos: any = [];
  grupos: any = [];
  ofs: any = [];
  piezasPredefinidas: any = [];
  asignaturasretos: any = [];
  asignaturasRetos: any = [];
  idGrupo: number;
  gruposDeTrabajo: any = [];
  loadingPanel: any = false;


  public grupoDeTrabajoPorDefecto: any = { id: -1, alumnos: [], showMultiselect: true };


  of: any = {

    nombre: '',
    referencia: '',
    terminado: false,
    fechaIni: undefined,
    fechaFin: undefined,
    piezas: undefined,
    publica: false
  }

  // elementos seleccionados de los grid
  selectedAsignaturasretos = [];
  selectedAlumnos = [];
  selectedGrupos = [];
  selectedOf = [];
  selectedPredefinidas = [];

  multiselectValue = [];

  //variables radio button
  tipoAsignacion: number;
  tipoOfs: number;

  //variables TITULO
  nOfs: number;
  nPiezas_Of: number;

  //data table que vendrá desde la DB
  dtOfs;
  dtPiezas;

  //validación
  errorNombreVacio: boolean;
  errorNombreRepetido: boolean;
  errorSinAsignar: boolean;
  errorCaracterEspecial: boolean;



  constructor(private cursosLectivosService: Eskola_CursosLectivosService,
    private alumnosService: Eskola_AlumnosService,
    private gruposService: EskolaGruposService,
    private eskolaOfsService: Eskola_OfsService,
    private eskolaOfsPiezasService: Eskola_OfsPiezasService,
    private alertService: AlertService,
    private asignaturasRetosService: Eskola_AsignaturasRetosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('lanzadorOf').toUpperCase();


  }

  ngOnInit(): void {
    this.nOfs = 0;
    this.nPiezas_Of = 1;
    this.idGrupo = 1;
    this.errorNombreVacio = false;
    this.errorNombreRepetido = false;
    this.errorSinAsignar = false;
    this.cargarDatos();
  }
  cargarDatos() {
    this.loadingPanel = true;
    this.gruposDeTrabajo.push(this.grupoDeTrabajoPorDefecto);


    //cargamos grupos
    this.getGrupos();
    //cargamos asignaturas/retos
    this.getAsignaturasRetos();
    /* cargamos piezas predefinidas */
    this.getPiezasPredefinidas();
    //cargamos ofs
    this.getOfs();
    this.loadingPanel = false;
  }
  // reinicia todos los valores como si hubiesemos recargado la página
  reiniciar() {

    this.selectedAsignaturasretos = [];
    this.selectedAlumnos = [];
    this.selectedGrupos = [];
    this.gruposDeTrabajo = [];
    this.annadirLinea();
    this.selectedOf = [];
    this.selectedPredefinidas = [];
    this.multiselectValue = [];

    this.errorNombreVacio = false;
    this.errorNombreRepetido = false;
    this.errorSinAsignar = false;

    this.of = {
      nombre: '',
      referencia: '',
      terminado: false,
      fechaIni: undefined,
      fechaFin: undefined,
      piezas: undefined,
      publica: false
    }

    this.nOfs = 0;
    this.nPiezas_Of = 1;
    this.tipoOfs = 1;
    this.getOfs();
    this.actualizarNumeroDeAlumnos();
    this.getPiezasPredefinidas();
    this.filtrarAlumnos();

    this.loadingPanel = false;
  }
  // carga los grupos de la DB
  getGrupos() {
    this.gruposService.getAll().subscribe(result => {
      this.grupos = result;
      this.grupos.forEach(grupo => {

        grupo.nombreC = grupo.nombre + " (" + grupo.nombreCurso + ")";
        //conseguimos numero de alumnos de cada grupo
        this.alumnosService.getAll_alumnos_filtrado("", "WHERE ((grupo.id IN ('" + grupo.id + "')))", "").subscribe(alumnos => {
          grupo.numeroDeAlumnos = "0/" + alumnos.length;
        });
      });
    });
  }
  // carga las asignaturas/retos de la DB
  getAsignaturasRetos() {
    this.asignaturasRetosService.getAll().subscribe(result => {
      this.asignaturasRetos = result;
    });
  }
  // carga las piezas predefinidas de la DB
  getPiezasPredefinidas() {
    this.eskolaOfsPiezasService.getPiezasPredefinidas().subscribe(result => {
      this.piezasPredefinidas = result;
    });
  }
  // carga las OFs de la DB y sus piezas
  getOfs() {
    var r1, r2: Boolean = false;
    this.eskolaOfsService.getAll().subscribe(
      result => {
        this.dtOfs = result;
        r1 = true;

        if (r1 && r2)
          this.unirTablas()
      });
    this.eskolaOfsPiezasService.getPiezasDeOf(-1).subscribe(
      result => {
        this.dtPiezas = result;
        r2 = true;

        if (r1 && r2)
          this.unirTablas()
      });
  }

  // asigna las piezas a las OFs
  unirTablas() {
    var id = 1;
    this.dtOfs.forEach(
      of => {
        of.id = id;
        of.nombreDb = of.nombre;
        of.nombre = of.nombre.replace("!", " ");
        id = id + 1;
        var ofPiezas = this.dtPiezas.filter(f => f.idOf == of.idOf)
        ofPiezas.forEach(
          pieza => {
            pieza.id = id;
            id = id + 1;
          });
        of.idPieza = -1;
        of.piezas = ofPiezas;
      });
    this.ofs = this.dtOfs;
  }

  // actualiza la lista de OFs seleccionadas (this.OFs)
  // controla que al seleccionar otra OF se deseleccione otra OF y sus piezas
  // @param event
  onSelectionChange(event) {

    if (this.selectedOf[0] != undefined) {
      var selectedRowOF = this.dtOfs.filter(f => f.id == this.selectedOf[0].itemKey);
      var selectedRowPieza = this.dtPiezas.filter(f => f.id == this.selectedOf[0].itemKey);
      var idOf = -1;

      if (selectedRowOF[0] != undefined)
        idOf = selectedRowOF[0].idOf
      else if (selectedRowPieza[0] != undefined) {
        idOf = selectedRowPieza[0].idOf
        // si ha sido seleccionada cantidad =1 
        /*
        if (event.action == "add") {
          selectedRowPieza[0].cantidad = 1; */
        // si ha sido deseleccionada ponemos cantidad = 0
        /*  } else if (event.action == "remove") {
            selectedRowPieza[0].cantidad = 0;
          }*/
      }
      // si ha seleccionado otra OF o una pieza de otra OF limpiar lista
      if (idOf != event.items[0].dataItem.idOf)
        setTimeout(() => {
          // No es una funcion optima, pero no se nos ocurre como hacerla mejor.
          // Despues de muchas horas. Este codigo es el unico que funciona correctamente.
          this.selectedOf = [];
          this.selectedOf.push({ itemKey: event.items[0].dataItem.id });
        }, 1);
    }
  }
  // actualiza la cantidad de las piezas predefinidas cuando son seleccionadas o deseleccionadas
  // @param event evento que lanza el elemento
  selectedPredefinidasChange(event) {
    // si ha sido deseleccionada ponemos cantidad = 0
    if (event.deselectedRows.length > 0) {
      var affectedPieza = this.piezasPredefinidas.filter(f => f.id == event.deselectedRows[0].dataItem.id)[0];
      affectedPieza.cantidad = 0;
    }
    // si ha sido seleccionada ponemos cantidad = 1
    else if (event.selectedRows.length > 0) {
      var affectedPieza = this.piezasPredefinidas.filter(f => f.id == event.selectedRows[0].dataItem.id)[0];
      affectedPieza.cantidad = 1;
    }
  }
  // actualiza el Nº de OFs cuando cambia Nº Piezas/OF
  nPiezas_Of_onChange() {
    if (this.nOfs > 0) {
      if (this.tipoAsignacion == 1) {
        this.actualizarNumeroDeOfs(this.selectedAlumnos.length)
      } else if (this.tipoAsignacion == 2) {
        this.actualizarNumeroDeOfs(this.gruposDeTrabajo.length - 1)
      }
    }

  }
  // actualiza el nombre
  // @param e evento que lanza el input
  nombreChange(e) {
    this.of.nombre = e.target.value;
  }

  // actualiza si la OF será publica
  publicaChange(){
    this.of.publica = !this.of.publica;
  }
  //
  // actualiza el numero de OFs que van a crearse
  // @param listaSize numero de grupos/alumnos que tendrán OF
  actualizarNumeroDeOfs(listaSize) {
    this.nOfs = this.nPiezas_Of * listaSize;
  }
  // actualiza el tipo de asignación a 1 (por Alumno)
  radioPorAlumno_Click() {
    this.tipoAsignacion = 1;
    // limpiamos grupos porque hemos cambiado a alumnos
    this.gruposDeTrabajo = [];
    this.nOfs = 0;
    this.annadirLinea();

  }
  // actualiza el tipo de asignación a 2 (por Grupo de trabajo)
  radioPorGrupo_Click() {
    this.tipoAsignacion = 2;
    //limpiamos porque hemos cambiado a grupos de trabajo
    this.selectedAlumnos = [];
    this.nOfs = 0;
    this.actualizarNumeroDeAlumnos();
  }
  // actualiza el valor de tipo de Of a 3 (piezas predefinidas)
  radioPiezasPredeterminadas_Click() {
    this.tipoOfs = 3;
    // limpiamos OFs porque hemos cambido a piezas predefinidas
    this.selectedOf = [];
  }
  // momentuz ez
  radioMisProyectos_Click() {
    this.tipoOfs = 2;
  }
  // actualiza el valor de tipo de Of a 1 (todas)
  radioTodos_Click() {
    this.tipoOfs = 1;
    // limpiamos predefinidas porque hemos cambiado a OFs todos
    this.selectedPredefinidas = [];
  }
  // cuando cambiamos los grupos seleccionados, actualizamos la lista de alumnos y el numero de alumnos seleccionados
  // @param event
  selectedGruposChange(event) {
    if (this.selectedGrupos.length == 0) {
      this.selectedAlumnos = [];
      this.gruposDeTrabajo = [];
      this.annadirLinea();
      this.actualizarNumeroDeOfs(this.gruposDeTrabajo.length - 1);
    }
    //si se deseleccionan grupos
    if (event.deselectedRows.length > 0) {

      event.deselectedRows.forEach(element => {
        let grupoId = element.dataItem.id;
        //quitar los alumnos de selectedAlumnos
        let alumnosParaQuitar = this.comboAlumnos.filter(a => a.id_grupo === grupoId);
        // si estamos trabajando con alumnos quitamos los alumnos que estaban en el grupo deseleccionado
        if (this.selectedAlumnos.length > 0 && this.tipoAsignacion == 1) {
          alumnosParaQuitar.forEach(alumno => {
            let index = this.selectedAlumnos.findIndex(x => x === alumno.id);
            this.selectedAlumnos.splice(index, 1);
          });
        }// si estamos trabajando con grupos de alumnos quitamos los alumnos que estaban en el grupo deseleccionado
        else if (this.gruposDeTrabajo.length > 0 && this.tipoAsignacion == 2) {
          alumnosParaQuitar.forEach(alumno => {
            this.gruposDeTrabajo.forEach(grupoTrabajo => {
              grupoTrabajo.alumnos = grupoTrabajo.alumnos.filter(f => f.id != alumno.id);
            });
          });
        }
      });

    }
    this.actualizarNumeroDeAlumnos();
    this.filtrarAlumnos();

  }
  // cuando cambiamos los alumnos seleccionados actualizamos el numero de alumnos y el numero de Ofs
  selectedAlumnosChange() {
    this.actualizarNumeroDeAlumnos();
    this.actualizarNumeroDeOfs(this.selectedAlumnos.length);
  }
  // filtra los alumnos para quedarse solo con los que estén en los grupos seleccionados
  filtrarAlumnos() {
    this.comboAlumnos = [];
    this.auxComboAlumnos = [];
    var filtro = "";
    if (this.selectedGrupos.length > 0) {
      filtro = "WHERE ((grupo.id IN ( ";
      for (var i = 0; i < this.selectedGrupos.length - 1; i++) {
        filtro += "'" + this.selectedGrupos[i] + "', ";
      }
      filtro += "'" + this.selectedGrupos[i] + "' ))) ";
      this.alumnosService.getAll_alumnos_filtrado("", filtro, "").subscribe(result => {
        console.log(result);
       
        this.comboAlumnos = result;
        this.auxComboAlumnos = result;
      });
    } else {
      this.comboAlumnos = [];
      this.selectedAlumnos = [];
    }

  }
  // actualiza el numero de alumnos seleccionados de cada grupo
  actualizarNumeroDeAlumnos() {

    this.selectedGrupos.forEach(grupoId => {
      let kopAlumnosSelected = 0;
      this.selectedAlumnos.forEach(alumnoId => {
        var alumno = this.comboAlumnos.filter(a => a.id_usuario === alumnoId)[0];
        var ids_grupos = alumno.id_grupo.split(',');
        if (ids_grupos.includes(grupoId.toString())) {
          kopAlumnosSelected++;
        }
      });
      this.grupos.filter(x => x.id === grupoId)[0].numeroDeAlumnos = kopAlumnosSelected + "/" + this.grupos.filter(x => x.id === grupoId)[0].numeroDeAlumnos.split('/')[1];

    });
  }



  // MULTISELECT GRUPOS

  // inserta en un grupo los alumnos seleccionados en el multiselect
  //@param grupo grupo en el que se insertarán los alumnos
  valueChange(event, grupo) {
    if (event.length == 0) {
      this.eliminarGrupo(grupo.id);
      return;
    }
    this.multiselectValue = event;
    var lagGrupoId = grupo.id;
    // vaciamos los alumno de antes
    grupo.alumnos = [];
    //metemos los alumnos seleccionados en el grupo actual
    this.multiselectValue.forEach(alumno => {
      grupo.alumnos.push(alumno);
    });

    //añadir linea vacia al final
    if (lagGrupoId == -1) {
      grupo.id = this.idGrupo;
      this.idGrupo++;
      this.annadirLinea();

    }
    this.actualizarNumeroDeOfs(this.gruposDeTrabajo.length - 1);



  }

  // oculta los multiselect de todos los grupos excepto del seleccionado
  // @param grupo grupo que mostrará el multiselect
  mostrarMultiselect(grupo) {
    // ocultamos todos los multiselect
    this.gruposDeTrabajo.forEach(element => {
      // el grupo vacío no se oculta
      if(element.id!=-1)
      element.showMultiselect = false;
    });
    //mostramos el multiselect del grupo seleccionado
    grupo.showMultiselect = true;

  }

  // función de filtro del multiselect
  // @param filter evento que lanza el multiselect, string para filtrar
  filterChange(filter) {
    if (this.comboAlumnos != null) {
      this.comboAlumnos = this.auxComboAlumnos.filter(
        (s) => s.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1
      );
    }
  }
  // END MULTISELECT GRUPOS



  // añade un grupo de trabajo vacío a la lista de grupos de trabajo (this.gruposDeTrabajo)
  annadirLinea() {
    let nuevoGrupo = { id: -1, alumnos: [], showMultiselect: true }
    this.gruposDeTrabajo.push(nuevoGrupo);
  }
  // elimina un grupo de la lista de grupos de trabajo (this.gruposDeTrabajo)
  // @param id id del grupo a eliminar
  eliminarGrupo(id) {
    // la linea vacía no se puede eliminar
    if (id == -1) return;
    console.log(id)
    var index = this.gruposDeTrabajo.findIndex(d => d.id === id);
    this.gruposDeTrabajo.splice(index, 1);
    this.actualizarNumeroDeOfs(this.gruposDeTrabajo.length - 1);
    console.log(this.gruposDeTrabajo)

  }

  // crea una Of
  crearOf() {
    if (!this.validar()) {
      return;
    }
    this.loadingPanel = true;
    // si no hay errores continuamos
    if (this.tipoAsignacion == 1) {
      this.crearOfPorAlumnos();
    } else if (this.tipoAsignacion == 2) {
      this.crearOfPorGruposDeTrabajo();
    }
  }
  // comprueba que todos los datos necesarios sean correctos
  // @return true si todo es correcto false si hay algun error
  validar(): boolean {
    var correcto: boolean = true;
    this.errorNombreRepetido = false;
    this.errorNombreVacio = false;
    this.errorSinAsignar = false;
    this.errorCaracterEspecial = false;
    // comprobamos que estén los datos necesarios
    if (this.of.nombre == null || this.of.nombre == undefined || this.of.nombre == '') {
      this.errorNombreVacio = true;
      correcto = false;
    } else if (this.of.nombre.includes('!')) {
      this.errorCaracterEspecial = true;
      correcto = false;
    }

    if (this.dtOfs.filter(f => f.nombreDb.split('!')[0] == this.of.nombre).length > 0) {
      this.errorNombreRepetido = true;
      correcto = false;
    }

    if (this.nOfs == 0) {
      this.errorSinAsignar = true;
      correcto = false;
    }
    return correcto;
  }
  // crea las OFs si el tipo de asignacion es POR ALUMNO
  crearOfPorAlumnos() {

    let newOf: any = this.of;
    let newOfs = [];
    let newPiezas = [];

    var r1 = false, r2 = false, r3 = false, r4: boolean = false;
    if (this.selectedAsignaturasretos.length == 0) { r3 = true }


    //tipoOf todos
    if (this.selectedOf.length > 0 && this.tipoOfs == 1) {
      this.selectedOf.forEach(element => {
        // que no sea Of, buscamos las piezas
        let selectedRowOF = this.dtOfs.filter(f => f.id == element.itemKey);
        let selectedRowPieza = this.dtPiezas.filter(f => f.id == element.itemKey);
        // es Of
        if (selectedRowOF[0] != undefined) {
          newOf = selectedRowOF[0]
          newOf.nombre = this.of.nombre;
          newOf.publica = this.of.publica;
        }
        // es pieza
        else if (selectedRowPieza[0] != undefined) {

          let newPieza = {
            nombre: selectedRowPieza[0].nombre,
            idOf: undefined,
            referencia: (selectedRowPieza[0].referencia == null) ? undefined : selectedRowPieza[0].referencia,
            numeroPlano: (selectedRowPieza[0].numeroPlano == null) ? undefined : selectedRowPieza[0].numeroPlano,
            terminado: 0,
            fechaIni: undefined,
            fechaFin: undefined,
            idPiezaPredefinida: (selectedRowPieza[0].idPiezaPredefinida == null) ? -1 : selectedRowPieza[0].idPiezaPredefinida,
            id_Piezas_tipos: (selectedRowPieza[0].id_Piezas_tipos == null) ? -1 : selectedRowPieza[0].id_Piezas_tipos,
            cantidad: selectedRowPieza[0].cantidad
          }
          for (var i = 0; i < newPieza.cantidad; i++) {
            newPiezas.push(newPieza);
          }
        }
      });

    }//tipoOfs piezas predefinidas 
    else if (this.selectedPredefinidas.length > 0 && this.tipoOfs == 3) {
      this.selectedPredefinidas.forEach(pPredefId => {
        let result = this.piezasPredefinidas.filter(f => f.id == pPredefId)[0];
        let newPieza = {
          nombre: result.nombre,
          idOf: undefined,
          referencia: result.referencia,
          numeroPlano: result.numeroPlano,
          terminado: 0,
          fechaIni: undefined,
          fechaFin: undefined,
          idPiezaPredefinida: result.id,
          id_Piezas_tipos: (result.id_Piezas_tipos == null) ? -1 : result.id_Piezas_tipos,
          cantidad: result.cantidad
        }

        for (var i = 0; i < newPieza.cantidad; i++) {
          newPiezas.push(newPieza);
        }
      });

    } else if (this.selectedOf.length == 0 && this.selectedPredefinidas.length == 0) {
      r4 = true;
    }

    for (var i = 0; i < this.selectedAlumnos.length; i++) {
      if (this.nPiezas_Of > 1) {
        for (var j = 1; j <= this.nPiezas_Of; j++) {
          let nuevaOf = {
            nombre: newOf.nombre + "!" + (i + 1) + "." + j,
            referencia: newOf.referencia,
            terminado: 0,
            fechaIni: undefined,
            fechaFin: undefined,
            piezas: newPiezas,
            idAlumno: this.selectedAlumnos[i],
            publica: newOf.publica
          }
          newOfs.push(nuevaOf);
        }

      } else {
        let nuevaOf = {
          nombre: newOf.nombre + "!" + (i + 1),
          referencia: newOf.referencia,
          terminado: 0,
          fechaIni: undefined,
          fechaFin: undefined,
          piezas: newPiezas,
          idAlumno: this.selectedAlumnos[i],
          publica: newOf.publica
        }
        newOfs.push(nuevaOf);
      }
    }


    var kontOfs: number = 0;
    var kontPiezasOfs: number = 0;
    var kontAlumnos: number = 0;
    var kontAsignaturas: number = 0;
    newOfs.forEach(ofInsertar => {
      this.eskolaOfsService.insertOf(ofInsertar.nombre, ofInsertar.referencia, ofInsertar.terminado, ofInsertar.fechaIni, ofInsertar.fechaFin, ofInsertar.publica).subscribe(response => {
        if (!response.error) {

          kontOfs++;

          //id de la OF recien creada
          let idOf = response.id;
          // insertar Ofs alumno
          this.eskolaOfsService.insertOfAlumno(idOf, ofInsertar.idAlumno).subscribe(r => {
            if (!r.error) {
              kontAlumnos++;
              if (kontAlumnos == this.selectedAlumnos.length) {
                r2 = true;
                //si todas las poeraciones han resultado true
                if (r1 && r2 && r3 && r4) {
                  this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                  this.reiniciar();
                }
              }

            } else {
              r2 = false;
              this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
              this.loadingPanel = false;
            }
          });
          // insertar Ofs asignaturas

          this.selectedAsignaturasretos.forEach(idAsignatura => {
            this.eskolaOfsService.insertOfAsignatura(idOf, idAsignatura).subscribe(r => {
              if (!r.error) {
                kontAsignaturas++;
                if (kontAsignaturas == this.selectedAsignaturasretos.length) {
                  r3 = true;
                  //si todas las poeraciones han resultado true
                  if (r1 && r2 && r3 && r4) {
                    this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                    this.reiniciar();

                  }
                }



              } else {
                r3 = false;
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                this.loadingPanel = false;

              }

            });
          });
          // insertamos las piezas de la Of

          if (ofInsertar.piezas.length == 0) {
            r4 = true
          } else {
            r4 = false;
            var kontPiezas: number = 0;
            ofInsertar.piezas.forEach(pieza => {

              this.eskolaOfsPiezasService.insertPieza(idOf, pieza.referencia, pieza.nombre, pieza.terminado, pieza.numeroPlano, pieza.fechaIni, pieza.fechaFin, pieza.idPiezaPredefinida, pieza.id_Piezas_tipos).subscribe(r => {
                if (!r.error) {
                  kontPiezas++;
                  if (kontPiezas == ofInsertar.piezas.length) {
                    // si hemos insertado todas las piezas de la OF sumamos
                    kontPiezasOfs++;
                    // si hemos insertados todas las piezas de todas las OFs ponemos true
                    if (kontPiezasOfs == newOfs.length) {
                      r4 = true;
                      //si todas las poeraciones han resultado true
                      if (r1 && r2 && r3 && r4) {
                        this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                        this.reiniciar();

                      }
                    }

                  }


                } else {
                  r4 = false;
                  this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });

                }
              });
            });
          }

          if (kontOfs == newOfs.length) {
            r1 = true;
            //si todas las poeraciones han resultado true
            if (r1 && r2 && r3 && r4) {
              this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
              this.reiniciar();

            }
          }



        } else {
          this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
          r1 = false;

        }

      });


    });







  }
  // crea las OFs si el tipo de asignacion es POR GRUPOS DE TRABAJO
  crearOfPorGruposDeTrabajo() {
    let newOf: any = this.of;
    let newOfs = [];
    let newPiezas = [];

    var r1 = false, r2 = false, r3 = false, r4: boolean = false;
    if (this.selectedAsignaturasretos.length == 0) { r3 = true }

    //tipoOf todos
    if (this.selectedOf.length > 0 && this.tipoOfs == 1) {
      this.selectedOf.forEach(element => {
        // que no sea Of, buscamos las piezas
        let selectedRowOF = this.dtOfs.filter(f => f.id == element.itemKey);
        let selectedRowPieza = this.dtPiezas.filter(f => f.id == element.itemKey);
        // es Of
        if (selectedRowOF[0] != undefined) {
          newOf = selectedRowOF[0]
          newOf.nombre = this.of.nombre;
          newOf.publica = this.of.publica;
        }
        // es pieza
        else if (selectedRowPieza[0] != undefined) {

          let newPieza = {
            nombre: selectedRowPieza[0].nombre,
            idOf: undefined,
            referencia: (selectedRowPieza[0].referencia == null) ? undefined : selectedRowPieza[0].referencia,
            numeroPlano: (selectedRowPieza[0].numeroPlano == null) ? undefined : selectedRowPieza[0].numeroPlano,
            terminado: 0,
            fechaIni: undefined,
            fechaFin: undefined,
            idPiezaPredefinida: (selectedRowPieza[0].idPiezaPredefinida == null) ? -1 : selectedRowPieza[0].idPiezaPredefinida,
            id_Piezas_tipos: (selectedRowPieza[0].id_Piezas_tipos == null) ? -1 : selectedRowPieza[0].id_Piezas_tipos,
            cantidad: selectedRowPieza[0].cantidad
          }
          for (var i = 0; i < newPieza.cantidad; i++) {
            newPiezas.push(newPieza);
          }
        }
      });

    }//tipoOfs piezas predefinidas 
    else if (this.selectedPredefinidas.length > 0 && this.tipoOfs == 3) {
      this.selectedPredefinidas.forEach(pPredefId => {
        let result = this.piezasPredefinidas.filter(f => f.id == pPredefId)[0];
        let newPieza = {
          nombre: result.nombre,
          idOf: undefined,
          referencia: result.referencia,
          numeroPlano: result.numeroPlano,
          terminado: 0,
          fechaIni: undefined,
          fechaFin: undefined,
          idPiezaPredefinida: result.id,
          id_Piezas_tipos: (result.id_Piezas_tipos == null) ? -1 : result.id_Piezas_tipos,
          cantidad: result.cantidad
        }

        for (var i = 0; i < newPieza.cantidad; i++) {
          newPiezas.push(newPieza);
        }
      });

    } else if (this.selectedOf.length == 0 && this.selectedPredefinidas.length == 0) {
      r4 = true;
    }

    for (var i = 0; i < this.gruposDeTrabajo.length - 1; i++) {
      if (this.nPiezas_Of > 1) {
        for (var j = 1; j <= this.nPiezas_Of; j++) {
          let nuevaOf = {
            nombre: newOf.nombre + "!" + (i + 1) + "." + j,
            referencia: newOf.referencia,
            terminado: 0,
            fechaIni: undefined,
            fechaFin: undefined,
            piezas: newPiezas,
            alumnos: this.gruposDeTrabajo[i].alumnos.filter(f => f.id),
            publica: newOf.publica

          }

          newOfs.push(nuevaOf);
        }
      } else {
        let nuevaOf = {
          nombre: newOf.nombre + "!" + (i + 1),
          referencia: newOf.referencia,
          terminado: 0,
          fechaIni: undefined,
          fechaFin: undefined,
          piezas: newPiezas,
          alumnos: this.gruposDeTrabajo[i].alumnos.filter(f => f.id),
          publica: newOf.publica

        }

        newOfs.push(nuevaOf);

      }

    }
    // insertamos las nuevas OFs
    var kontOfs: number = 0;
    var kontAsignaturas: number = 0;
    var kontPiezasOfs: number = 0;
    var kontGruposOfs: number = 0;
    newOfs.forEach(ofInsertar => {
      this.eskolaOfsService.insertOf(ofInsertar.nombre, ofInsertar.referencia, ofInsertar.terminado, ofInsertar.fechaIni, ofInsertar.fechaFin, ofInsertar.publica).subscribe(response => {
        if (!response.error) {
          kontOfs++;

          //id de la OF recien creada
          let idOf = response.id;
          // insertar Ofs alumnos
          if(ofInsertar.alumnos.length>0){
          var kontAlumnos: number = 0;
          ofInsertar.alumnos.forEach(alumno => {
            this.eskolaOfsService.insertOfAlumno(idOf, alumno.id).subscribe(r => {
              if (!r.error) {
                kontAlumnos++;
                // si hemos insertado todos los alumnos de un grupo
                if (kontAlumnos == ofInsertar.alumnos.length) {
                    kontGruposOfs++;
                  // si hemos insertado todos los alumnos de todas las OFs
                  if (kontGruposOfs == newOfs.length) {
                    r2 = true;
                    //si todas las poeraciones han resultado true
                    if (r1 && r2 && r3 && r4) {
                      this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                      this.reiniciar();
                    }
                  }
                }

              } else {
                r2 = false;
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                this.loadingPanel = false;

              }
            });
          });
        }

          // insertar Ofs asignaturas

          this.selectedAsignaturasretos.forEach(idAsignatura => {
            this.eskolaOfsService.insertOfAsignatura(idOf, idAsignatura).subscribe(r => {
              if (!r.error) {
                kontAsignaturas++;
                if (kontAsignaturas == this.selectedAsignaturasretos.length) {
                  r3 = true;
                  //si todas las poeraciones han resultado true
                  if (r1 && r2 && r3 && r4) {
                    this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });

                    this.reiniciar();
                  }
                }
              } else {
                r3 = false;
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });

              }
            });
          });
          // insertamos las piezas de la Of

          if (ofInsertar.piezas.length == 0) {
            r4 = true
          } else {
            var kontPiezas: number = 0;
            ofInsertar.piezas.forEach(pieza => {
              this.eskolaOfsPiezasService.insertPieza(idOf, pieza.referencia, pieza.nombre, pieza.terminado, pieza.numeroPlano, pieza.fechaIni, pieza.fechaFin, pieza.idPiezaPredefinida, pieza.id_Piezas_tipos).subscribe(r => {
                if (!r.error) {
                  kontPiezas++;
                  // cuando haya insertados todas las piezas de la OF sumamos
                  if (kontPiezas == ofInsertar.piezas.length) {
                    kontPiezasOfs++;
                    //si hemos insertado todas las piezas de todas las OFs ponemos true
                    if (kontPiezasOfs == newOfs.length) {
                      r4 = true;
                      //si todas las poeraciones han resultado true
                      if (r1 && r2 && r3 && r4) {
                        this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                        this.reiniciar();
                      }
                    }
                  }

                } else {
                  r4 = false;
                  this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });

                }

              });
            });
          }


          //si todas las poeraciones han resultado true
          if (kontOfs == newOfs.length) {
            r1 = true;
            if (r1 && r2 && r3 && r4) {
              this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
              this.reiniciar();
            }
          }



        } else {
          r1 = false;
          this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });

        }
      });


    });

  }


}
