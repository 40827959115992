<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <!-- DATOS �REA PRODUCTIVA -->
      <div class="card">
        <div class="card-header">
          <h3 class="pt-3">{{ 'datos' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="form-group ">
            <kendo-label text="{{ 'nombre' | translate}}">
              <div class="caja">
                <kendo-textbox maxlength="150" formControlName="nombre"
                  [ngClass]="{ 'is-invalid': submitted && form.controls.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && form.controls.nombre.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.nombre.errors.required">{{ 'nombreEsrequerido' | translate}}</div>
                </div>
              </div>
            </kendo-label>
            <div class="form-group ">
              <kendo-label text="{{ 'descripcion' | translate}}">
                <div class="caja">
                  <textarea kendoTextArea style="resize: none;" rows="5" formControlName="descripcion"
                    [ngClass]="{ 'is-invalid': submitted && form.controls.descripcion.errors }">
                </textarea>
                  <div *ngIf="submitted && form.controls.nombre.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.nombre.errors.required">{{ 'descripcionrequerido' | translate}}</div>
                  </div>
                </div>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <!-- DATOS CONTACTO �REA PRODUCTIVA -->
          <div class="card">
            <div class="card-header">
              <h3 class="pt-3">{{ 'contacto' | translate}}</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group ">
                    <kendo-label text="{{ 'email' | translate}}">
                      <div class="caja">
                        <kendo-textbox type="email" maxlength="150" formControlName="email" [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }">
                        </kendo-textbox>
                        <div *ngIf="submitted && form.controls.email.errors" class="invalid-feedback">
                          <div *ngIf="form.controls.email.errors.required">{{ 'emailEsrequerido' | translate}}</div>
                          <div *ngIf="form.controls.email.errors.email">{{ 'emailEsValido' | translate}}</div>
                        </div>
                      </div>
                    </kendo-label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <kendo-label text="{{ 'telefono' | translate}}">
                      <div class="caja">
                        <kendo-maskedtextbox formControlName="telefono" (focusout)="onChangeNumberCorrect($event)" [ngClass]="{ 'is-invalid': (submitted && form.controls.telefono.errors) || (submitted && telefonoInvalido)}">
                        </kendo-maskedtextbox>
                        <div *ngIf="submitted && form.controls.telefono.errors" class="invalid-feedback">
                          <div *ngIf="form.controls.telefono.errors.required">{{ 'telefonoEsrequerido' | translate}}</div>
                        </div>
                        <div *ngIf="submitted && telefonoInvalido" class="invalid-feedback">
                          <div *ngIf="telefonoInvalido">{{ 'telefonoEsValido' | translate}}</div>
                        </div>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!-- DATOS UBICACI�N �REA PRODUCTIVA -->
          <div class="card">
            <div class="card-header">
              <h3 class="pt-3">{{ 'ubicacion' | translate}}</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group ">
                    <kendo-label text="{{ 'direccion' | translate}}">
                      <div class="caja">
                        <kendo-textbox maxlength="150" formControlName="direccion" [ngClass]="{ 'is-invalid': submitted && form.controls.direccion.errors }">
                        </kendo-textbox>
                        <div *ngIf="submitted && form.controls.direccion.errors" class="invalid-feedback">
                          <div *ngIf="form.controls.direccion.errors.required">{{ 'direccionEsrequerido' | translate}}</div>
                        </div>
                      </div>
                    </kendo-label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <kendo-label text="{{ 'codigoPostal' | translate}}">
                      <div class="caja">
                        <kendo-maskedtextbox maxlength="15" formControlName="codigoPostal" [ngClass]="{ 'is-invalid': submitted && form.controls.codigoPostal.errors }">
                        </kendo-maskedtextbox>
                        <div *ngIf="submitted && form.controls.codigoPostal.errors" class="invalid-feedback">
                          <div *ngIf="form.controls.codigoPostal.errors.required">{{ 'codigoPostalEsrequerido' | translate}}</div>
                        </div>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group ">
                    <kendo-label text="{{ 'provincia' | translate}}">
                      <div class="caja">
                        <kendo-textbox maxlength="150" formControlName="provincia" [ngClass]="{ 'is-invalid': submitted && form.controls.provincia.errors }"
                          (focusout)="onChangeTextCorrect($event)">
                        </kendo-textbox>
                        <div *ngIf="submitted && form.controls.provincia.errors" class="invalid-feedback">
                          <div *ngIf="form.controls.provincia.errors.required">{{ 'provinciaEsrequerido' | translate}}</div>
                        </div>
                      </div>
                    </kendo-label>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group ">
                    <kendo-label text="{{ 'pais' | translate}}">
                      <div class="caja">
                        <kendo-textbox maxlength="150" formControlName="pais" (focusout)="onChangeTextCorrect($event)" [ngClass]="{ 'is-invalid': submitted && form.controls.pais.errors }">
                        </kendo-textbox>
                        <div *ngIf="submitted && form.controls.pais.errors" class="invalid-feedback">
                          <div *ngIf="form.controls.pais.errors.required">{{ 'paisEsrequerido' | translate}}</div>
                        </div>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <!-- SECCIONES -->
      <h3 class="pt-3">{{ 'secciones' | translate}}</h3>
      <kendo-grid [kendoGridBinding]="dataSecciones" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" (cellClick)="cellClick($event)" [selectedKeys]="mySelection">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1"> {{ 'editar' |
            translate}} </button>
          <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1"> {{ 'nuevo' |
            translate}}
          </button>
          <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting || this.user.areaProductiva<2"> {{ 'eliminar' | translate}} </button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="95%" field="nombre" title="{{ 'nombre' | translate}}"> </kendo-grid-column>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>
  <button type="button" class="btn btn-primary" (click)="guardarClick()" [disabled]="this.user.areaProductiva<2">{{ 'guardar' | translate }}</button>
  <button type="button" class="btn btn-danger ml-2" (click)="cancelarClick()">{{ 'cancelar' | translate }}</button>


  <!-- POPUP: Borrar planning -->
  <ng-template #popupBorrar let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
        }}</button>
      <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()" [disabled]="this.user.areaProductiva<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>
</form>