import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, RecetasService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-recetasDetalle',
  templateUrl: './recetasDetalle.component.html'
})
export class RecetasDetalleComponent implements OnInit {

  navigationSubscription;

  form: FormGroup;
  idof: string;
  idpieza: string;
  idparte: string;
  idruta: string;
  idOperacion: string;
  idVista: string;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  vieneDeOperacionPred: boolean;

  //TABS
  listaTCamaraCaliente = [];
  listaTHusillo = [];
  listaCascadaAbrir1 = [];
  listaCascadaCerrar1 = [];
  listaCascadaAbrir2 = [];
  listaCascadaCerrar2 = [];

  user = this.userService.userValue;


  constructor(
    private recetasService: RecetasService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService
  ) {

    this.menuService.titulo = this.translateService.instant('receta').toUpperCase();

  }

  ngOnInit() {
    this.idof = this.route.snapshot.params['idof'];
    this.idpieza = this.route.snapshot.params['idpieza'];
    this.idparte = this.route.snapshot.params['idparte'];
    this.idruta = this.route.snapshot.params['idruta'];
    this.idOperacion = this.route.snapshot.params['idOperacion'];
    this.idVista = this.route.snapshot.params['idVista'];
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.vieneDeOperacionPred = !this.idof && !this.idpieza && !this.idparte && !this.idruta && !this.idVista;

    this.form = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      idOperacion: this.route.snapshot.params['idOperacion'],
      nombre: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      tiempoCiclo: 0,
      tiempoInyeccion: 0,
      tiempoPresionRemanente: 0,
      tiempoEnfriamiento: 0,
      tiempoDosificacionPlastificacion: 0,
      presionInyeccionMax: 0,
      presionRemanente: 0,
      contrapresion: 0,
      ptoConmutacion: 0,
      paradaDosificado: 0,
      retrocesoHusillo: 0,
      paradaMolde: 0,
      cojin: 0,
      revolucionesHusillo: 0,
      fuerzaDeCierre: 0,
      temperaturaHusillo: 0,
      temperaturaCamaraCaliente: 0,
      tempPresionFija: 0,
      tempPresionMovil: 0,
      temperaturaMolde: 0,
      pesoInyeccionEspumado: 0,
      porcenScf: 0,
      dosificacionScf: 0
    });

    if (!this.isAddMode) {
      this.recetasService.getById(this.id).pipe(first()).subscribe((result) => {
        if (result.length > 0) {
          var receta = result[0];
          this.form.patchValue(receta);

          //Cargamos los grids de los tabs
          this.listaTCamaraCaliente = [];
          var valoresTCamaraCaliente = receta.tCamaraCaliente.split(",");
          if (receta.tCamaraCaliente == "") valoresTCamaraCaliente = [];
          for (var i = 0; i < valoresTCamaraCaliente.length; i++)
            this.listaTCamaraCaliente.push({ num: i + 1, valor: Number(valoresTCamaraCaliente[i]) });

          this.listaTHusillo = [];
          var valoresTHusillo = receta.tHusillo.split(",");
          if (receta.tHusillo == "") valoresTHusillo = [];
          for (var i = 0; i < valoresTHusillo.length; i++)
            this.listaTHusillo.push({ num: i + 1, valor: Number(valoresTHusillo[i]) });

          this.listaCascadaAbrir1 = [];
          var valoresCascadaAbrir1 = receta.cascadaAbrir1.split(",");
          if (receta.cascadaAbrir1 == "") valoresCascadaAbrir1 = [];
          for (var i = 0; i < valoresCascadaAbrir1.length; i++)
            this.listaCascadaAbrir1.push({ num: i + 1, valor: Number(valoresCascadaAbrir1[i]) });

          this.listaCascadaCerrar1 = [];
          var valoresCascadaCerrar1 = receta.cascadaCerrar1.split(",");
          if (receta.cascadaCerrar1 == "") valoresCascadaCerrar1 = [];
          for (var i = 0; i < valoresCascadaCerrar1.length; i++)
            this.listaCascadaCerrar1.push({ num: i + 1, valor: Number(valoresCascadaCerrar1[i]) });

          this.listaCascadaAbrir2 = [];
          var valoresCascadaAbrir2 = receta.cascadaAbrir2.split(",");
          if (receta.cascadaAbrir2 == "") valoresCascadaAbrir2 = [];
          for (var i = 0; i < valoresCascadaAbrir2.length; i++)
            this.listaCascadaAbrir2.push({ num: i + 1, valor: Number(valoresCascadaAbrir2[i]) });

          this.listaCascadaCerrar2 = [];
          var valoresCascadaCerrar2 = receta.cascadaCerrar2.split(",");
          if (receta.cascadaCerrar2 == "") valoresCascadaCerrar2 = [];
          for (var i = 0; i < valoresCascadaCerrar2.length; i++)
            this.listaCascadaCerrar2.push({ num: i + 1, valor: Number(valoresCascadaCerrar2[i]) });

        }

      });
    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearReceta();
    } else {
      this.updateReceta();
    }

  }

  private crearReceta() {
    console.log("crearReceta")

    var valoresTCamaraCaliente = this.listaTCamaraCaliente.map(x => x.valor).join();
    var valoresTHusillo = this.listaTHusillo.map(x => x.valor).join();
    var valoresCascadaAbrir1 = this.listaCascadaAbrir1.map(x => x.valor).join();
    var valoresCascadaCerrar1 = this.listaCascadaCerrar1.map(x => x.valor).join();
    var valoresCascadaAbrir2 = this.listaCascadaAbrir2.map(x => x.valor).join();
    var valoresCascadaCerrar2 = this.listaCascadaCerrar2.map(x => x.valor).join();

    this.recetasService.create(-1, this.form.value, valoresTCamaraCaliente, valoresTHusillo, valoresCascadaAbrir1, valoresCascadaCerrar1, valoresCascadaAbrir2, valoresCascadaCerrar2)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          if (this.vieneDeOperacionPred) this.router.navigate(['operacionespredefinidas/editar/' + this.idOperacion]);
          else this.router.navigate(['operaciones/editar/' + this.idof + '/' + this.idpieza + '/' + this.idparte + '/' + this.idruta + '/' + this.idOperacion + '/' + this.idVista]);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });

  }

  private updateReceta() {

    var valoresTCamaraCaliente = this.listaTCamaraCaliente.map(x => x.valor).join();
    var valoresTHusillo = this.listaTHusillo.map(x => x.valor).join();
    var valoresCascadaAbrir1 = this.listaCascadaAbrir1.map(x => x.valor).join();
    var valoresCascadaCerrar1 = this.listaCascadaCerrar1.map(x => x.valor).join();
    var valoresCascadaAbrir2 = this.listaCascadaAbrir2.map(x => x.valor).join();
    var valoresCascadaCerrar2 = this.listaCascadaCerrar2.map(x => x.valor).join();

    this.recetasService.update(this.form.value.id, this.form.value, valoresTCamaraCaliente, valoresTHusillo, valoresCascadaAbrir1, valoresCascadaCerrar1, valoresCascadaAbrir2, valoresCascadaCerrar2)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          if (this.vieneDeOperacionPred) this.router.navigate(['operacionespredefinidas/editar/' + this.idOperacion]);
          else this.router.navigate(['operaciones/editar/' + this.idof + '/' + this.idpieza + '/' + this.idparte + '/' + this.idruta + '/' + this.idOperacion + '/' + this.idVista]);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });

  }

  //TABS
  agregarTCamaraCaliente() {
    this.listaTCamaraCaliente.push({ num: this.listaTCamaraCaliente.length + 1, valor: 0 });
  }
  eliminarTCamaraCaliente(dataItem) {
    this.listaTCamaraCaliente = this.listaTCamaraCaliente.filter(x => x.num != dataItem.num);
    for (var i = 0; i < this.listaTCamaraCaliente.length; i++)
      this.listaTCamaraCaliente[i].num = i + 1;
  }

  agregarTHusillo() {
    this.listaTHusillo.push({ num: this.listaTHusillo.length + 1, valor: 0 });
  }
  eliminarTHusillo(dataItem) {
    this.listaTHusillo = this.listaTHusillo.filter(x => x.num != dataItem.num);
    for (var i = 0; i < this.listaTHusillo.length; i++)
      this.listaTHusillo[i].num = i + 1;
  }

  agregarCascadaAbrir1() {
    this.listaCascadaAbrir1.push({ num: this.listaCascadaAbrir1.length + 1, valor: 0 });
  }
  eliminarCascadaAbrir1(dataItem) {
    this.listaCascadaAbrir1 = this.listaCascadaAbrir1.filter(x => x.num != dataItem.num);
    for (var i = 0; i < this.listaCascadaAbrir1.length; i++)
      this.listaCascadaAbrir1[i].num = i + 1;
  }

  agregarCascadaCerrar1() {
    this.listaCascadaCerrar1.push({ num: this.listaCascadaCerrar1.length + 1, valor: 0 });
  }
  eliminarCascadaCerrar1(dataItem) {
    this.listaCascadaCerrar1 = this.listaCascadaCerrar1.filter(x => x.num != dataItem.num);
    for (var i = 0; i < this.listaCascadaCerrar1.length; i++)
      this.listaCascadaCerrar1[i].num = i + 1;
  }

  agregarCascadaAbrir2() {
    this.listaCascadaAbrir2.push({ num: this.listaCascadaAbrir2.length + 1, valor: 0 });
  }
  eliminarCascadaAbrir2(dataItem) {
    this.listaCascadaAbrir2 = this.listaCascadaAbrir2.filter(x => x.num != dataItem.num);
    for (var i = 0; i < this.listaCascadaAbrir2.length; i++)
      this.listaCascadaAbrir2[i].num = i + 1;
  }

  agregarCascadaCerrar2() {
    this.listaCascadaCerrar2.push({ num: this.listaCascadaCerrar2.length + 1, valor: 0 });
  }
  eliminarCascadaCerrar2(dataItem) {
    this.listaCascadaCerrar2 = this.listaCascadaCerrar2.filter(x => x.num != dataItem.num);
    for (var i = 0; i < this.listaCascadaCerrar2.length; i++)
      this.listaCascadaCerrar2[i].num = i + 1;
  }

}
