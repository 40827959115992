import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/historicoParametros`;

@Injectable()
export class HistoricoParametrosService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/" + id);
  }
  public GetAllEjesMaquinasWithIdMaquinas() {
    return this.http.get<JSON>(`${baseUrl}/ejesMaquinasidmaquinas`, {});
  }
  public GetAllEjesMaquinas() {
    return this.http.get<JSON>(`${baseUrl}/ejesMaquinas`, {});
  }
  public GetAllTiposVariables() {
    return this.http.get<JSON>(`${baseUrl}/tiposvariables`, {});
  }
  
  public GetAllGruposMaquinas(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }
  public GetAllMaquinas(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/histoc");
  }

  insert(data, logo, archivo) {
    return this.http.post<any>(baseUrl + "/crear", {id: -1,  nombre: data.nombre, logo: logo, archivo: archivo}, { withCredentials: true });
  }//limitesejes
  filtrarAlarmas(id, fechaIni, fechaFin, idMaquina, idGrupoMaquina) {
    return this.http.post<any>(baseUrl + "/filtraralarmas", { id: id, fechaIni: fechaIni, fechaFin: fechaFin, idMaquina: idMaquina, idMaquinaGrupo: idGrupoMaquina}, { withCredentials: true });
  }//filtrarParametrosEjes
  filtrarParametrosEjes(id, fechaIni, fechaFin, idMaquina, nombreEje, idTipoVariable) {
    return this.http.post<any>(baseUrl + "/filtrarparametros", { id: id, fechaIni: fechaIni, fechaFin: fechaFin, idMaquina: idMaquina, idEje: nombreEje, idTipoVariable: idTipoVariable}, { withCredentials: true });
  }
  limitesejes(id, idMaquina, idEje, idTipoVariable) {
    return this.http.post<any>(baseUrl + "/limitesejes", { id: id,  idMaquina: idMaquina, tipoVariable: idTipoVariable, idEje:idEje }, { withCredentials: true });
  }
  update(data, logo, imagen) {

    return this.http.post<any>(baseUrl + "/editar", { id:data.id, nombre: data.nombre, logo: logo, archivo : imagen  }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }
  public download(idMarca): Observable<any> {
    return this.http.get(baseUrl + "/logo/download/" + idMarca);
  } 
}
