<!-- GRID -->
<kendo-grid [kendoGridBinding]="informes"
            [sortable]="true"
            [navigable]="true"
            [selectable]="{enabled: true, mode: 'multiple'}"
            filterable="menu"
            [pageSize]="30"
            kendoGridSelectBy="id"
            [selectedKeys]="seleccionados"
            (cellClick)="cellClick($event)">

  <!-- BOTONES ARRIBA -->
  <ng-template kendoGridToolbarTemplate position="top">
    <a [routerLink]="['/crearInformesPersonalizados']" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</a>
    <button (click)="onClick_Editar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate }}</button>
    <button (click)="onClick_Duplicar()" class="btn btn-info  btn-sm mr-1">{{ 'duplicar' | translate }}</button>
    <button (click)="onClick_Elimnar(eliminar)" class="btn btn-danger btn-sm mr-1">{{ 'eliminar' | translate }}</button>
  </ng-template>

  <!-- CHECKBOX -->
  <kendo-grid-checkbox-column width="5%">
    <ng-template kendoGridHeaderTemplate>
      <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
             (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,listaNotas, 'id')">
      <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
    </ng-template>
  </kendo-grid-checkbox-column>

  <!-- NOMBRE -->
  <kendo-grid-column width="95%" [style]="{'text-align': 'left'}" field="nombre"
                     title="{{ 'informe' | translate}}">
  </kendo-grid-column>

  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>

<!-- MODAL: Eliminar Informe -->
<ng-template #eliminar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "eliminarinforme" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">
      {{ "no" | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="eliminarInforme()">
      {{ "si" | translate }}
    </button>
  </div>
</ng-template>
