import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService, AreasProductivasService, UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-areasProductivas',
  templateUrl: './areasProductivas.component.html'
})
export class AreasProductivasComponent implements OnInit {
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  dataAreasProductivas: any[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;
  public user = this.userService.userValue;

  constructor(private areasProductivasService: AreasProductivasService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {


    this.menuService.titulo = this.translateService.instant('areasProductivas');
    
    this.cargarDatos();

  }

  cargarDatos() {
    this.areasProductivasService.Get(0).subscribe(
      result => {
        var an: any = result;
        this.dataAreasProductivas = an;
      });
  }

  ngOnInit(): void {
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['areaProductiva/', this.mySelection[0]]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['areaProductiva/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['areaProductiva/0']);
  }
  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        this.mySelection.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.areasProductivasService.Delete(ids).subscribe(() => {
          this.isDeleting = false;
          this.cargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

}
