<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 12px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<div class="row">
  <!-- MENU LATERAL -->
  <div class="col-sm-4 col-lg-3 col-xl-2 barra-izq-oee">
    <!-- INFORMES -->
    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <h3>
            <label runat="server" ID="lInformes" CssClass="">{{ 'informes' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!--BOTON OEE-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeOEE']">
              <input type="radio" name="informesGroup" id="btnOee" class="k-radio" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnOee">{{ 'oee' | translate }}</label>
            </a>
          </div>
          <!--BOTON Disponibilidad-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeDisponibilidadOEE']">
              <input type="radio" name="informesGroup" id="btnDisponibilidad" class="k-radio" checked="checked"
                     kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnDisponibilidad">{{ 'disponibilidad' | translate }}</label>
            </a>
          </div>
          <!--BOTON Rendimiento-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeRendimientoOEE']">
              <input type="radio" name="informesGroup" id="btnRendimiento" class="k-radio" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnRendimiento">{{ 'rendimiento' | translate }}</label>
            </a>
          </div>
          <!--BOTON Calidad-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeCalidadOEE']">
              <input type="radio" name="informesGroup" id="btnCalidad" class="k-radio" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnCalidad">{{ 'calidad' | translate }}</label>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- TURNOS -->
    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <h3>
            <label ID="lTurnos" class="">{{ 'turnos' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!-- TODOS -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnTodos" class="k-radio" checked="checked"
                     (click)="btnTodos_Click($event)" kendoRadioButton>
              <label class="k-radio-label" for="btnTodos">{{ 'todos' | translate }}</label>
            </a>
          </div>
          <!-- MAÑANA -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnMañana" class="k-radio" (click)="btnManana_Click($event)"
                     kendoRadioButton>
              <label class="k-radio-label" for="btnMañana">{{ 'manana' | translate }}</label>
            </a>
          </div>
          <!-- TARDE -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnTarde" class="k-radio" (click)="btnTarde_Click($event)"
                     kendoRadioButton>
              <label class="k-radio-label" for="btnTarde">{{ 'tarde' | translate }}</label>
            </a>
          </div>
          <!-- NOCHE -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnNoche" class="k-radio" (click)="btnNoche_Click($event)"
                     kendoRadioButton>
              <label class="k-radio-label" for="btnNoche">{{ 'noche' | translate }}</label>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- FILTRO NUEVO -->
    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <h3>
            <label ID="lTurnos" class="">{{ 'filtro' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!-- FECHA -->
          <div class="calendarFiltro">
            <label>{{ 'fechas' | translate }}</label>
            <div class="calendarFiltro-input" (click)="showCalendar()">
              <label *ngIf="dpFechaIni != undefined && dpFechaFin == undefined">{{dpFechaIni | kendoDate}}</label>
              <label *ngIf="dpFechaFin != undefined && dpFechaFin != undefined">{{dpFechaIni | kendoDate}} -
                {{dpFechaFin | kendoDate}}</label>
              <i class="k-icon k-i-calendar"></i>
            </div>

            <!-- CALENDARIO EXPANDIDO -->
            <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;"
                 [ngClass]="mostrarCalendario1 ? 'mostrarCalendario' : 'ocultarCalendario'">
              <div class="row">
                <div class="col-mes">
                  <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="infinite"
                                  (click)="valueClickCalendar(0, $event)">
                    <ng-template kendoCalendarMonthCellTemplate let-date>
                      <span class="calendarFiltro-calendario-contenido-dia"
                            [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(date)"> {{ date.getDate() }}
                      </span>
                    </ng-template>
                    <ng-template kendoCalendarHeaderTitleTemplate let-title>
                      <div> {{ title }} </div>
                    </ng-template>
                  </kendo-calendar>
                </div>
              </div>
            </div>

            <!-- BOTON FILTRAR -->

            <button type="button" class="btn btn-primary float-left nolabel w-100" (click)="recargarPagina()">{{
              'filtrar' | translate }}</button>

          </div>
        </div>
      </div>
    </div>

    <!-- MAQUINAS -->
    <div class="clearfix">
      <div class="card">
        <!-- <div class="card-header">
          <h3>
            <label class="titulo-perdidas" ID="lAcciones">{{ 'maquinas' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div> -->
        <div class="card-body">
          <kendo-tabstrip [keepTabContent]="true">
            <kendo-tabstrip-tab id="tabMaquinas" title="{{ 'maquinas' | translate}}" [selected]="true">
              <ng-template kendoTabContent>
                <!-- FILTRO GRUPOS -->
                <div class="clearfix ml-2">
                  <!--AREAS PRODUCTIVAS-->
                  <div class="form-group">
                    <label>{{ 'secciones' | translate }}</label>
                    <div class="caja">
                      <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion" [textField]="'nombre'"
                                         [valueField]="'id'" [autoClose]="false" [(ngModel)]="seccionesSeleccionadas"
                                         (valueChange)="cargarMaquinas2()" placeholder="{{ 'secciones' | translate }}"
                                         style="width: 200px;" [kendoDropDownFilter]="{operator: 'contains'}">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span class="k-icon k-i-arrow-s"></span>
                          <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                          <ng-container *ngIf="dataItems.length > 1"> {{ dataItems.length }} {{
                            'seccionesSeleccionadas'
                            | translate }} </ng-container>
                        </ng-template>
                        <kendo-multiselect-messages
                                                    noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                      </kendo-multiselect>
                    </div>
                  </div>
                </div>
                <div class="clearfix ml-2">
                  <!--GRUPOS-->
                  <div class="form-group">
                    <label>{{ 'maquinasgrupos' | translate }}</label>
                    <div class="caja">
                      <kendo-multiselect kendoMultiSelectSummaryTag [data]="gruposSeccion" [textField]="'nombre'"
                                         [valueField]="'id'" [autoClose]="false" [(ngModel)]="gruposSeleccionados"
                                         placeholder="{{ 'grupoMaquinas' | translate }}" (valueChange)="cargarMaquinas2()"
                                         style="width: 200px;" [kendoDropDownFilter]="{operator: 'contains'}">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span class="k-icon k-i-arrow-s"></span>
                          <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                          <ng-container *ngIf="dataItems.length > 1"> {{ dataItems.length }} {{
                            'gruposSeleccionados' |
                            translate }} </ng-container>
                        </ng-template>
                      </kendo-multiselect>
                    </div>
                  </div>
                </div>
                <!-- MAQUINAS -->
                <div class="clearfix ml-2">
                  <!-- filtro maquinas -->
                  <kendo-textbox [style.width.px]="180" placeholder="{{ 'filtrarMaquinas' | translate }}" [clearButton]="true" [size]="size"
                                 [rounded]="rounded" [fillMode]="fillMode" (valueChange)="filtroMaquinas($event)">
                  </kendo-textbox>
                </div>
                <div class="clearfix">
                  <ul class="list-group list-group-flush">
                    <kendo-grid id="gridmaquinasOEE" [data]="listaMaquinasAux" kendoGridSelectBy="id"
                                [selectedKeys]="maquinasSeleccionadas" [height]="600"
                                [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
                      <kendo-grid-checkbox-column [width]="40" showSelectAll="true"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="id" hidden="hidden" title="ID" width="40"></kendo-grid-column>
                      <kendo-grid-column field="nombre" title="{{ 'maquinas' | translate}}"
                                         width="150"></kendo-grid-column>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                           filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                           filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                           filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                           filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                           filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                           filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                           filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                           filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                           filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                           filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                           filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                           filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                           filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                           filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                           filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                           filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                           filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                           filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                           filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                           groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                           noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                  </ul>

                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabOperarios" title="{{ 'operarios' | translate}}">
              <ng-template kendoTabContent>
                <div class="clearfix ml-2">
                  <kendo-textbox [style.width.px]="180" placeholder="{{ 'filtrarOperarios' | translate }}" [clearButton]="true" [size]="size"
                                 [rounded]="rounded" [fillMode]="fillMode" (valueChange)="filtroOperarios($event)">
                  </kendo-textbox>
                  <!-- OPERARIOS -->
                  <div class="form-group">
                    <div class="caja">
                      <div class="clearfix">
                        <ul class="list-group list-group-flush">
                          <kendo-grid id="gridOperariosOEE" [data]="listaOperariosAux" kendoGridSelectBy="idUsuario"
                                      [selectedKeys]="operariosSeleccionados" [height]="600"
                                      [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
                            <kendo-grid-checkbox-column [width]="40" showSelectAll="true"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="idUsuario" hidden="hidden" title="ID"
                                               width="40"></kendo-grid-column>
                            <kendo-grid-column field="nombreCompleto" title="Operario" width="150"></kendo-grid-column>
                            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                 filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                 filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                 filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                 filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                 filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                 filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                 filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                 filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                 filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                 filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                 filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                 filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                 filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                 filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                 filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                 filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                 filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                 filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                 filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                 groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                 noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                          </kendo-grid>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </div>
  <!-- INFORME COMPLETO -->
  <div class="col-sm-8 col-lg-9 col-xl-10">


    <!-- GRAFICOS -->

    <div class="row">
      <!-- DONUT -->
      <div class="col-md-3">
        <div class="card">
          <div class="card-header">
            <h3>
              <label runat="server" ID="lDisponibilidad" CssClass="">{{ 'disponibilidad' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
            <!--Grafico donut disponibilidad-->
            <div class="row" id="chartDonutDisponibilidad_OEE_Disp" style="height: 210px;">
            </div>
          </div>
        </div>
      </div>
      <!-- LINEAL -->
      <div class="col-md-9">
        <div class="card">
          <div class="card-header">
            <h3>
              <label runat="server" ID="lHoras" CssClass="">{{ 'horas' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
            <!--Grafico barras horas-->
            <div class="row" id="chartBarHoras_Disp" style="height: 210px;">
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- DONUTS -->
    <div class="row">
      <!-- PERDIDAS -->
      <div class="col-xl-4 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header">
            <h3>
              <label runat="server" ID="lParadas" CssClass="">{{ 'perdidas' | translate}}</label>
            </h3>
            <!-- AGRUPAR POR: -->
            <div id="checkboxGrupos" class="position-absolute clearfix" style="top: 8px; right: 45px;">
              <!-- *ngIf="checkboxGruposVisible" -->
              <!-- Grupo -->
              <div class="float-left mr-2">
                <input type="radio" name="groupData" class="k-radio" (change)="checkboxChange(2)"
                       [checked]="agrupado == 2" kendoRadioButton>
                <label for="groupData"> {{ 'grupo' | translate }}</label><br>
              </div>
              <!-- Perdida -->
              <div class="float-left mr-2">
                <input type="radio" name="groupData" class="k-radio" (change)="checkboxChange(1)"
                       [checked]="agrupado == 1" kendoRadioButton>
                <label for="groupData"> {{ 'perdidas' | translate }}</label><br>
              </div>
              <!-- Sub-perdida -->
              <div class="float-left mr-2">
                <input type="radio" name="groupData" class="k-radio" (change)="checkboxChange(0)"
                       [checked]="agrupado == 0" kendoRadioButton>
                <label for="groupData"> {{ 'subperdidas' | translate }}</label><br>
              </div>
            </div>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <!--Grafico donut paradas-->
            <div class="row" [ngClass]="{'d-none': this.sinPerdidas}" id="chartPerdidas_Disp" style="height: 300px;"></div>
            <div class="clearfix cont-donut-vacio" *ngIf="sinPerdidas" style="height: 300px;position: relative;display: flex;align-items: center;">
              <img class="float-left" src="../../../assets/img/donut-vacio.png" style="height: auto; margin: 0 auto; width: 60%; max-width: 275px;" />
              <a style="text-align: center; color: lightgrey;">{{ 'noDatosParadas' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- MANTENIMIENTOS -->
      <div class="col-xl-4 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header">
            <h3>
              <label runat="server" ID="lMantenimientos" CssClass="">{{ 'mantenimientos' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <!--Grafico donut mantenimientos-->
            <div class="row" [ngClass]="{'d-none': this.sinMantenimientos}" id="chartMantenimientos_Disp" style="height: 300px;"></div>
            <div class="clearfix cont-donut-vacio" *ngIf="sinMantenimientos" style="height: 300px;position: relative;display: flex;align-items: center;">
              <img class="float-left" src="../../../assets/img/donut-vacio.png" style="height: auto; margin: 0 auto; width: 60%; max-width: 275px;" />
              <a style="text-align: center; color: lightgrey;">{{ 'noDatosMantenimientos' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- ALARMAS -->
      <div class="col-xl-4 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-header">
            <h3>
              <label runat="server" ID="lAlarmas" CssClass="">{{ 'alarmas' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <!--Grafico donut alarmas-->
            <div class="row" [ngClass]="{'d-none': this.sinAlarmas}" id="chartAlarmas_Disp" style="height: 300px;"></div>
            <div class="clearfix cont-donut-vacio" *ngIf="sinAlarmas" style="height: 300px;position: relative;display: flex;align-items: center;">
              <img class="float-left" src="../../../assets/img/donut-vacio.png" style="height: auto; margin: 0 auto; width: 60%; max-width: 275px;" />
              <a style="text-align: center; color: lightgrey;">{{ 'noDatosAlarmas' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- GRID -->
    <div class="row">
      <div class="col-md-12">
        <div class="clearfix">
          <div class="card">
            <div class="card-header">
              <h3>
                <label runat="server" ID="loperarios" CssClass="">{{ 'perdidas' | translate }}</label>
              </h3>
              <div class="plegarpanel"></div>
            </div>
            <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                 (mouseover)="showGridTooltip($event)">
              <!-- GRID OF-->
              <kendo-grid [kendoGridBinding]="datosPerdidas" [resizable]="true" [navigable]="true" [sortable]="true"
                          [reorderable]="true" [columnMenu]="{ filter: true }" (cellClick)="cellClick($event)"
                          scrollable="virtual" [rowHeight]="30" [height]="812"
                          #grid="kendoGrid">

                <ng-template kendoGridToolbarTemplate>
                  <button kendoButton type="button" (click)="imprimirXls($event, grid)" icon="file-excel">Excel</button>
                </ng-template>
                <kendo-grid-excel fileName="{{'disponibilidad' | translate}}.xlsx"></kendo-grid-excel>

                <kendo-grid-column field="identificadorOperacion" title="{{ 'operacion' | translate }}" width="400%"></kendo-grid-column>
                <!-- PIEZA -->
                <kendo-grid-column field="colada" *ngIf="user.verColada" title="{{ 'pedircolada' | translate }}" width="70%"></kendo-grid-column>
                <kendo-grid-column *ngIf="usuarioIdDbCAF && user.verLote" field="lote" title="{{ 'rueda' | translate }}" width="70%"></kendo-grid-column>
                <kendo-grid-column *ngIf="!usuarioIdDbCAF && user.verLote" field="lote" title="{{ 'lote' | translate }}" width="70%"></kendo-grid-column>
                <kendo-grid-column field="nserie" *ngIf="user.verNSerie" title="{{ 'nserie' | translate }}" width="70%"></kendo-grid-column>
                <!-- PERDIDA -->
                <kendo-grid-column field="grupoperdida" title="{{ 'grupo' | translate }}" width="70%"></kendo-grid-column>
                <kendo-grid-column field="perdida" title="{{ 'perdidas' | translate }}" width="70%"></kendo-grid-column>
                <kendo-grid-column field="subperdida" title="{{ 'subperdidas' | translate }}" width="70%"></kendo-grid-column>
                <!-- PRODUCCION -->
                <kendo-grid-column field="operario" title="{{ 'operarios' | translate }}" width="70%" class="celda-tooltip-externo">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="tooltip-consumibles-contenido">
                      <span *ngIf="dataItem.operario_color != 'null'" class="grid-circulo" [style.background-color]="dataItem.operario_color + ' !important'">{{dataItem.operario_reducido}}</span>
                      <span *ngIf="dataItem.operario_color == 'null'" class="grid-circulo"><i class="fas fa-user-alt"></i></span>
                      <span class="tooltiptext"><label *ngIf="operario !=''">{{dataItem.operario}}</label></span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <!-- <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="7%"></kendo-grid-column> -->
                <kendo-grid-column field="maquina" title="{{ 'maquinas' | translate }}" width="70%" class="celda-tooltip-externo">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="tooltip-consumibles-contenido">
                      <span *ngIf="dataItem.maquinaIm!='null'" a class="grid-circulo" style="background-color:white!important;" [ngStyle]="{'background-image': 'url(' + dataItem.maquinaIm + ')'}"></span>
                      <span *ngIf="dataItem.maquinaIm=='null'" class="grid-circulo" [style.background-color]="dataItem.maquina_colores + '!important'">{{maquina}}</span>
                      <span class="tooltiptext"><label *ngIf="maquina!=''">{{dataItem.maquina}}</label></span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechaIni" title="{{ 'inicio' | translate }}" width="100%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaIni | kendoDate }} {{dataItem.fechaIni | kendoDate:'HH:mm:ss' }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechaFin" title="{{ 'fin' | translate }}" width="100%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaFin | kendoDate }} {{dataItem.fechaFin | kendoDate:'HH:mm:ss' }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="duracion_HH_mm_ss" title="{{ 'tiempo' | translate }}" width="70%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{ myFunctions.secondsTo_HH_MM_SS(dataItem.duracion) }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="observacionHistoricoPerdida" title="{{ 'observacion' | translate }}" width="300%"></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                     filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                     filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                     filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                     filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                     filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                     filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                     filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                     filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                     filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                     filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                     filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                     filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                     filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                     filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                     filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                     filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                     filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                     filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                     filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                     groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                     noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="k-i-loading"></div>