import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-historicoConsumibles',
  templateUrl: 'historicoConsumibles.component.html'
})

export class HistoricoConsumibles {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //GRID: consumibles Tipos
  public consumiblesTipos: any;
  public seleccionados: number[] = [];

  //POPUP: Eliminar
  public popupEliminar: boolean = false;
  public popupErrorAlEliminar: boolean = false;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('historicoConsumibles').toUpperCase();
  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    this.loadingPanel = true;
    this.consumiblesService.Get_consumiblesTipos().subscribe(
      (json) => {
        this.consumiblesTipos = json;
        this.loadingPanel = false;
      });
  }
  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['historicoConsumibleTipo/', this.seleccionados[0]]);
    }
  }
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['historicoConsumibleTipo/', this.seleccionados[0]]);
    }
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
