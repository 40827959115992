<div class="cont-filtro-desplegable card" [ngClass]="menuAbierto ? 'filtro-desplegado' : 'filtro-oculto'">
    <div class="card-header">
        <h3 *ngIf="eligiendoTipoModulo">{{ 'modulos' | translate }}</h3>
        <h3 *ngIf="eligiendoContenidoModulo">{{this.nombreTipoModuloSeleccionado}}</h3>
        <button type="button" class="flt-cerrar" (click)="cerrarMenu()">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="filtro-desplegable card-body flt">

        <div class="cont-tipo-grafico clearfix" *ngIf="eligiendoTipoModulo">
            <div class="out-selec-tipo-grafico" *ngFor="let tipoGrafico of tiposGrafico; let i = index">
                <div class="selec-tipo-grafico"
                    (click)="seleccionarTipoModulo(tipoGrafico.id, tipoGrafico.nombre, tipoGrafico.altura, tipoGrafico.anchura)">
                    <i class="{{tipoGrafico.icon}}"></i>
                    <p>{{tipoGrafico.nombre}}</p>
                </div>
            </div>
        </div>
        <!-- titulo -->
        <kendo-label *ngIf="eligiendoContenidoModulo" text="{{ 'titulo' | translate }}">
            <input *ngIf="eligiendoContenidoModulo" type="text" name="titulo"
                style="width: 100%; margin-bottom: 10px;" [(ngModel)]="tituloModulo"
                [ngStyle]="{ 'border': !tituloAdecuado ? 'solid 1px red' : 'solid 1px #dfdfdf'}" />
        </kendo-label>
        <!-- origen de datos -->
        <kendo-label *ngIf="eligiendoContenidoModulo" text="{{ 'origendatos' | translate }}">
            <kendo-combobox style="width: 100%; margin-bottom: 10px;" [data]="origenesDatos"
                [value]="this.origenSeleccionado" (valueChange)="origenChange($event)" textField="nombre"
                valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                [ngStyle]="{ 'border': !origenAdecuado ? 'solid 1px red' : 'solid 1px #dfdfdf'}">
            </kendo-combobox>
        </kendo-label>
        <!-- anchura del modulo -->
        <kendo-label *ngIf="eligiendoContenidoModulo" text="{{ 'anchuraModulo' | translate }}"></kendo-label><br>
        <kendo-numerictextbox *ngIf="eligiendoContenidoModulo" format="n0" [min]="0" [max]="100" [autoCorrect]="true" [(value)]="anchuraModulo"></kendo-numerictextbox><br>

        <!-- filtrar por maquinas -->
        <kendo-label *ngIf="eligiendoContenidoModulo" text="{{ 'filtrarMaquinas' | translate }}">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="filtrarMaquinas"></kendo-switch>
        </kendo-label>
        <!-- secciones -->
        <kendo-multiselect kendoMultiSelectSummaryTag *ngIf="filtrarMaquinas" style="width: 100%; margin-bottom: 10px;" [(data)]="filtroSecciones"
            [(ngModel)]="filtroSeccionesSeleccionado" [textField]="'nombre'"
            [valueField]="'id'" [kendoDropDownFilter]="{operator: 'contains'}" [autoClose]="false" placeholder="{{ 'seleccioneSeccion' | translate }}"
            (valueChange)="filtroTabla($event)">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' |
                    translate }}</ng-container>
            </ng-template>
        </kendo-multiselect>
        <!-- grupos -->
        <kendo-multiselect kendoMultiSelectSummaryTag *ngIf="filtrarMaquinas" style="width: 100%; margin-bottom: 10px;" [(data)]="filtroGrupos"
            [(ngModel)]="filtroGruposSeleccionado" [textField]="'nombre'"
            [valueField]="'id'" [kendoDropDownFilter]="{operator: 'contains'}" [autoClose]="false" placeholder="{{ 'seleccioneGrupo' | translate }}"
            (valueChange)="filtroTabla($event)">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' |
                    translate }}</ng-container>
            </ng-template>
        </kendo-multiselect>
        <!-- maquinas -->
        <kendo-multiselect kendoMultiSelectSummaryTag *ngIf="filtrarMaquinas && (filtroSeccionesSeleccionado.length != 0 || filtroGruposSeleccionado.length != 0)" style="width: 100%; margin-bottom: 10px;" [data]="filtroMaquinas"
            [(ngModel)]="filtroMaquinasSeleccionado" textField="nombreMaquina"
            valueField="idMaquina" [kendoDropDownFilter]="{operator: 'contains'}" [autoClose]="false" placeholder="{{ 'seleccioneMaquina' | translate }}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreMaquina}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">
                    {{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}
                </ng-container>
            </ng-template>
        </kendo-multiselect>
        <br>
        <!-- filtrar por operarios -->
        <kendo-label *ngIf="eligiendoContenidoModulo" text="{{ 'filtrarOperarios' | translate }}">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="filtrarOperarios"></kendo-switch>
            <kendo-multiselect kendoMultiSelectSummaryTag *ngIf="filtrarOperarios" style="width: 100%; margin-bottom: 10px;" [data]="filtroOperarios"
                [(ngModel)]="filtroOperariosSeleccionado" textField="nombreOperario"
                valueField="idOperario" [kendoDropDownFilter]="{operator: 'contains'}" [autoClose]="false" placeholder="{{ 'seleccioneOperario' | translate }}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperario}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">
                      {{ dataItems.length }} {{ 'operariosSeleccionados' | translate }}
                    </ng-container>
                  </ng-template>
            </kendo-multiselect>
        </kendo-label>

        <br>

        <!-- GRID COLUMNAS TABLA -->
        <kendo-label *ngIf="eligiendoContenidoModulo && this.idTipoModuloSeleccionado == 7"
            text="{{ 'columnas' | translate }}">
            <kendo-grid [data]="gridData" 
                        [sortable]="true" 
                        [navigable]="true"
                        [selectable]="{checkboxOnly: true, mode: 'multiple'}" 
                        filterable="menu" 
                        [pageSize]="30"
                        kendoGridSelectBy="idReferencia" 
                        [selectedKeys]="columnasSeleccionadas"
                        [rowClass]="rowCallback"
                        class="grid-columnas">

                <!-- CHECKBOX -->
                <kendo-grid-checkbox-column width="10%">
                    <ng-template kendoGridHeaderTemplate>
                        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                            (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,columnasTabla, 'id')">
                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                    </ng-template>
                </kendo-grid-checkbox-column>

                <!-- ORDEN -->
                <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="20%"></kendo-grid-column>
                <!-- NOMBRE -->
                <kendo-grid-column width="35%" [style]="{'text-align': 'left'}" field="nombreTranslate"
                    title="{{ 'nombre' | translate}}">
                </kendo-grid-column>
                <!-- % COLUMNA -->
                <kendo-grid-column width="35%" [style]="{'text-align': 'left'}" editor="numeric"
                    title="{{ 'tamano' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="float-left">
                            <kendo-numerictextbox format="n0" [min]="0" [max]="100" [autoCorrect]="true" [(value)]="dataItem.tamannoColumna"></kendo-numerictextbox>
                        </div>
                        <label class="float-left"> % </label>
                      </ng-template>
                </kendo-grid-column>

                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </kendo-label>

        <!-- CANTIDAD DE LINEAS EN LA TABLA -->
        <kendo-label *ngIf="eligiendoContenidoModulo && this.idTipoModuloSeleccionado == 7" text="{{ 'cantidadLineas' | translate }}"></kendo-label><br>
        <kendo-numerictextbox *ngIf="eligiendoContenidoModulo && this.idTipoModuloSeleccionado == 7" format="n0" [min]="0" [max]="100" [autoCorrect]="true" [(value)]="cantidadLineasTabla"></kendo-numerictextbox><br>

        <!-- EXCLUIR DE LA VINCULACION -->
        <kendo-label *ngIf="eligiendoContenidoModulo" text="{{ 'excluir' | translate }}"></kendo-label><br>
        <kendo-switch *ngIf="eligiendoContenidoModulo" [onLabel]="' '" [offLabel]="' '" [(ngModel)]="excluir"></kendo-switch><br>

        <button type="button" class="btn btn-nuevo mt-3" (click)="agregarModulo()">{{ 'anadir' | translate }}</button>

    </div>
</div>

<!-- al clickar fuera del menu se cerrara -->
<div (click)="cerrarMenu()" style="position:fixed; top:0; left:0; right:0; bottom:0; display:block;"></div>

<div class="cabecera-editor card">
    <div class="clearfix">
        <form [formGroup]="formTitulo">
            <div class="cabecera-editor-titulo">
                <!-- TITULO -->
                <input type="text" placeholder="Título" name="titulo" formControlName="titulo"
                    style="width: 100%;border: solid 1px #dfdfdf;" value="" [(ngModel)]="tituloInforme" required
                    [ngClass]="!tituloValido ? 'border border-danger' : ''" />
            </div>
            <div class="cabecera-editor-botones">
                <!-- BOTONES -->
                <div class="btn-group float-right" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-nuevo btn-sm mr-1" (click)="guardarInforme()">{{ 'guardar' | translate }}</button>
                    <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="guardarComoCopia()">{{ 'guardarcomocopia' | translate }}</button>
                    <button type="button" class="btn btn-danger btn-sm mr-1"
                        (click)="this.router.navigate(['informesPersonalizados']);">{{ 'cancelar' | translate }}</button>
                </div>
            </div>
        </form>
    </div>
</div>

<!--contenedor-->
<div class="clearfix contenedor-editor">
    <!-- LINEAS -->
    <div class="card" *ngFor="let linea of lineas; let i = index" [attr.data-index]="i"
        [ngClass]="linea.oculto ? 'oculto' : ''">
        <div class="card-header">
            <div class="cont-opciones-editor">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <div class="btn-group">
                        <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i data-toggle="dropdown" class="icon-columnas"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <div class="d-flex justify-content-center bd-highlight">
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 1)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 2)">
                                    <span class="doscol-icono">
                                        <span class="col-icono"></span>
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 3)">
                                    <span class="trescol-icono">
                                        <span class="col-icono"></span>
                                        <span class="col-icono"></span>
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i data-toggle="dropdown" class="icon-ajustes"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <button class="dropdown-item" type="button" (click)="linea.oculto = true"><i
                                    class="icon-novisible"></i>{{ 'ocultar' | translate }}</button>
                            <button class="dropdown-item" type="button" (click)="duplicarLinea(linea)"><i
                                    class="icon-duplicar"></i>{{ 'duplicar' | translate }}</button>
                            <button class="dropdown-item" type="button" (click)="eliminarLinea(i)"><i
                                    class="icon-equis"></i>{{ 'eliminar' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">

                    <div *ngIf="this.lineas[i].idTipoModulo1 == -1" class="agregar-elemento"
                        (click)="botonAnnadirModulo(i, 1)">
                        <i class="icon-mas-alt"></i>
                    </div>

                    <div *ngIf="this.lineas[i].idTipoModulo1 != -1" class="tipo-grafico-encolumna" >
                        <div class="cont-opciones-editor">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <div class="btn-group">
                                    <button type="button" class="btn dropdown-toggle " data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i data-toggle="dropdown" class="icon-ajustes"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <button class="dropdown-item" type="button"><i
                                                class="icon-novisible"></i>{{ 'ocultar' | translate }}</button>
                                        <button class="dropdown-item" type="button" (click)="duplicarModulo(i, 1)"><i
                                                class="icon-duplicar"></i>{{ 'duplicar' | translate }}</button>
                                        <button class="dropdown-item" type="button" (click)="eliminarModulo(i, 1)"><i
                                                class="icon-equis"></i>{{ 'eliminar' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="icon-{{linea.nombreModulo1}}"></i>
                        <div class="informePersonalizadoModuloAltura" (click)="botonAnnadirModulo(i, 1)">
                            <!-- dibujar el grafico -->
                            <i [ngClass]="{ 'icon-circular-individual' : this.lineas[i].idTipoModulo1 == 1, 
                                            'icon-circular-doble' : this.lineas[i].idTipoModulo1 == 2,
                                            'icon-oee' : this.lineas[i].idTipoModulo1 == 3,
                                            'icon-oee-barras' : this.lineas[i].idTipoModulo1 == 4,
                                            'icon-grafico-barras' : this.lineas[i].idTipoModulo1 == 5,
                                            'icon-oee-barras' : this.lineas[i].idTipoModulo1 == 6,
                                            'icon-tabla' : this.lineas[i].idTipoModulo1 == 7,
                                            'icon-grafico' : this.lineas[i].idTipoModulo1 == 8,
                                            'icon-grafico' : this.lineas[i].idTipoModulo1 == 9
                                          }"></i>
                            <p>{{linea.tituloModulo1}}</p>
                        </div>
                        
                    </div>

                </div>
                <div class="col" *ngIf="linea.columnas > 1">

                    <div *ngIf="this.lineas[i].idTipoModulo2 == -1" class="agregar-elemento"
                        (click)="botonAnnadirModulo(i, 2);">
                        <i class="icon-mas-alt"></i>
                    </div>

                    <div *ngIf="this.lineas[i].idTipoModulo2 != -1" class="tipo-grafico-encolumna" >
                        <div class="cont-opciones-editor">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <div class="btn-group">
                                    <button type="button" class="btn dropdown-toggle " data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i data-toggle="dropdown" class="icon-ajustes"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <button class="dropdown-item" type="button"><i
                                                class="icon-novisible"></i>{{ 'ocultar' | translate }}</button>
                                        <button class="dropdown-item" type="button" (click)="duplicarModulo(i, 2)"><i
                                                class="icon-duplicar"></i>{{ 'duplicar' | translate }}</button>
                                        <button class="dropdown-item" type="button" (click)="eliminarModulo(i, 2)"><i
                                                class="icon-equis"></i>{{ 'eliminar' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="icon-{{linea.nombreModulo2}}"></i>
                        <div class="informePersonalizadoModuloAltura" (click)="botonAnnadirModulo(i, 2)">
                            <!-- dibujar el grafico -->
                            <i [ngClass]="{ 'icon-circular-individual' : this.lineas[i].idTipoModulo2 == 1, 
                                            'icon-circular-doble' : this.lineas[i].idTipoModulo2 == 2,
                                            'icon-oee' : this.lineas[i].idTipoModulo2 == 3,
                                            'icon-oee-barras' : this.lineas[i].idTipoModulo2 == 4,
                                            'icon-grafico-barras' : this.lineas[i].idTipoModulo2 == 5,
                                            'icon-oee-barras' : this.lineas[i].idTipoModulo2 == 6,
                                            'icon-tabla' : this.lineas[i].idTipoModulo2 == 7,
                                            'icon-grafico' : this.lineas[i].idTipoModulo2 == 8,
                                            'icon-grafico' : this.lineas[i].idTipoModulo2 == 9
                                            }"></i>
                            <p>{{linea.tituloModulo2}}</p>
                        </div>
                    </div>

                </div>
                <div class="col" *ngIf="linea.columnas > 2">

                    <div *ngIf="this.lineas[i].idTipoModulo3 == -1" class="agregar-elemento"
                        (click)="botonAnnadirModulo(i, 3);">
                        <i class="icon-mas-alt"></i>
                    </div>

                    <div *ngIf="this.lineas[i].idTipoModulo3 != -1" class="tipo-grafico-encolumna" >
                        <div class="cont-opciones-editor">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <div class="btn-group">
                                    <button type="button" class="btn dropdown-toggle " data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false">
                                        <i data-toggle="dropdown" class="icon-ajustes"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <button class="dropdown-item" type="button"><i
                                                class="icon-novisible"></i>{{ 'ocultar' | translate }}</button>
                                        <button class="dropdown-item" type="button" (click)="duplicarModulo(i, 3)"><i
                                                class="icon-duplicar"></i>{{ 'duplicar' | translate }}</button>
                                        <button class="dropdown-item" type="button" (click)="eliminarModulo(i, 3)"><i
                                                class="icon-equis"></i>{{ 'eliminar' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <i class="icon-{{linea.nombreModulo3}}" ></i>
                        <div class="informePersonalizadoModuloAltura" (click)="botonAnnadirModulo(i, 3)">
                            <!-- dibujar el grafico -->
                            <i [ngClass]="{ 'icon-circular-individual' : this.lineas[i].idTipoModulo3 == 1, 
                                            'icon-circular-doble' : this.lineas[i].idTipoModulo3 == 2,
                                            'icon-oee' : this.lineas[i].idTipoModulo3 == 3,
                                            'icon-oee-barras' : this.lineas[i].idTipoModulo3 == 4,
                                            'icon-grafico-barras' : this.lineas[i].idTipoModulo3 == 5,
                                            'icon-oee-barras' : this.lineas[i].idTipoModulo3 == 6,
                                            'icon-tabla' : this.lineas[i].idTipoModulo3 == 7,
                                            'icon-grafico' : this.lineas[i].idTipoModulo3 == 8,
                                            'icon-grafico' : this.lineas[i].idTipoModulo3 == 9
                                            }"></i>
                            <p>{{linea.tituloModulo3}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="oculto-overlay">
            <i class="icon-novisible"></i>
            <div class="opcion-habilitar" (click)="linea.oculto = false">
                <i class="icon-visible"></i>
                {{ 'mostrarLinea' | translate }}
            </div>
        </div>
    </div>
</div>
<!--fin contenedor-->
<!--azpiko botoiak -->
<div class="text-center">
    <div class="btn-group mr-2">
        <button type="button" class="btn btn-nuevo dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="icon-mas"></i> {{ 'anadirlinea' | translate }}
        </button>
        <div class="dropdown-menu">
            <div class="d-flex justify-content-center bd-highlight">
                <button type="button" class="btn" (click)="anadirLinea(1)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(2)">
                    <span class="doscol-icono">
                        <span class="col-icono"></span>
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(3)">
                    <span class="trescol-icono">
                        <span class="col-icono"></span>
                        <span class="col-icono"></span>
                        <span class="col-icono"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>

