import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService, UsuariosService, BloqueoRangosService, MaquinasService, OperariosService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { SelectableSettings } from '@progress/kendo-angular-treelist';
import { resolveModuleName } from 'typescript';

@Component({
    selector: 'app-bloqueoRangos',
    templateUrl: 'bloqueoRangos.component.html'
})


export class BloqueoRangosComponent {


    public bloqueos: any;
    public seleccionados: any = [];
    user = this.userService.userValue;
    public listaOperarios: any = [];
    public listaMaquinas: any = [];
    public maquinasBloqueosMap = new Map();
    public operariosBloqueosMap = new Map();

    constructor(
        translate: TranslateService,
        private menuService: MenuService, public router: Router,
        private translateService: TranslateService,
        private modalService: NgbModal,
        private alertService: AlertService,
        private userService: UsuariosService,
        public route: ActivatedRoute,
        public bloqueoRangosService: BloqueoRangosService,
        public maquinasService: MaquinasService,
        public operariosService: OperariosService,
        public myFunctions: MyFunctions) {

        this.translateService = translate;
        this.menuService.titulo = this.translateService.instant('bloqueoRangos').toUpperCase();
    }


    ngOnInit() {
        this.bloqueos = []
        this.bloqueoRangosService.GetBloqueoRangos().subscribe(result => {
            var bloqueo: any = result;
            bloqueo.forEach(bl => {
                var aux = {
                    id: bl.id,
                    nombre: bl.nombre,
                    maquinas: [],
                    listaMaquinasST: "",
                    listaMaquinasImg: "",
                    operarios: [],
                    listaOperariosST: "",
                    listaOperariosColor: "",
                    recurrente: bl.recurrente,
                    periodo: bl.frecuencia,
                    descripcion: bl.descripcion,
                    imagenBase: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAADhCAMAAABFoniZAAAABGdBTUEAALGPC'
                }
                this.bloqueos.push(aux);
            });

            this.cargarDatos();




        });
    }


    cargarDatos() {
        this.operariosService.getAllUsuariosConColor().subscribe(result => {
            var operarios: any = result;
            operarios.forEach(operario => {
                this.listaOperarios.push(operario);
            });
            this.maquinasService.get().subscribe(data => {
                var maquinas: any = data;
                maquinas.forEach(maquina => {
                    this.listaMaquinas.push(maquina);
                });
                this.bloqueoRangosService.GetBloqueoRangos_Maquinas().subscribe(dataMaq => {
                    var maqList: any = dataMaq;
                    this.bloqueos.forEach(bloqueo => {
                        var listaMaquinas = [];
                        var listaMaquinasST = "";
                        var listaMaquinasImg = "";
                        maqList.forEach(maquina => {
                            if (maquina.idBloqueoRangos == bloqueo.id) {
                                this.listaMaquinas.forEach(maquinas => {
                                    if (maquinas.id == maquina.idMaquinas) {
                                        listaMaquinas.push(maquinas);
                                        if (listaMaquinasST == "") {
                                            listaMaquinasST = maquinas.nombre;
                                        } else {
                                            listaMaquinasST = listaMaquinasST + "," + maquinas.nombre;
                                        }
                                        if (listaMaquinasImg == "") {
                                            listaMaquinasImg = maquinas.imagenBase64;
                                        } else {
                                            listaMaquinasImg = listaMaquinasImg + "," + maquinas.imagenBase64;
                                        }
                                    }
                                });
                            }
                        });
                        bloqueo.maquinas = listaMaquinas;
                        bloqueo.listaMaquinasST = listaMaquinasST;
                        bloqueo.listaMaquinasImg = listaMaquinasImg;
                        this.maquinasBloqueosMap.set(bloqueo.id, listaMaquinas);
                    });
                    this.bloqueoRangosService.GetBloqueoRangos_Operarios().subscribe(dataOpe => {
                        var opList: any = dataOpe;
                        this.bloqueos.forEach(bloqueo => {
                            var listaOperarios = [];
                            var listaOperariosST = "";
                            var listaColor = "";
                            opList.forEach(operario => {
                                if (operario.idBloqueoRangos == bloqueo.id) {
                                    this.listaOperarios.forEach(operarios => {
                                        if (operarios.idUsuario == operario.idOperario) {
                                            listaOperarios.push(operarios);
                                            var ini1 = operarios.nombre.charAt(0);
                                            var ini2 = operarios.apellido.charAt(0);
                                            var color = operarios.color;
                                            if (listaOperariosST == "") {
                                                listaOperariosST = ini1 + ini2
                                            } else {
                                                listaOperariosST = listaOperariosST + "," + ini1 + ini2
                                            }
                                            if (listaColor == "") {
                                                listaColor = color;
                                            } else {
                                                listaColor = listaColor + "," + color;
                                            }
                                        }
                                    });
                                }
                            });
                            bloqueo.operarios = listaOperarios;
                            bloqueo.listaOperariosST = listaOperariosST;
                            bloqueo.listaOperariosColor = listaColor;
                            this.operariosBloqueosMap.set(bloqueo.id, listaOperarios);
                        });

                        this.ikusi();
                    });
                });
            });
        });
    }

    public ikusi() {
        var a = this.bloqueos;
        var b = 0;
    }

    cellClick(e) {
        if (e.columnIndex > 0) {
            this.router.navigate(['bloqueoRangos/', e.dataItem.id]);
        }
    }
    onClickEditar() {
        if (this.seleccionados[0] > 0) {
            this.router.navigate(['bloqueoRangos/', this.seleccionados[0]]);
        }
    }
    onClickNuevo() {
        this.router.navigate(['bloqueoRangos/0']);
    }
    onClickEliminar(content) {
        if (this.seleccionados.length > 0) {
            var listaIds = ""
            this.seleccionados.forEach(seleccion => {
                if(listaIds == ""){
                    listaIds = seleccion.toString();
                } else{
                    listaIds = listaIds + "," + seleccion.toString();
                }
            });
            this.bloqueoRangosService.DeleteBloqueoRangos(listaIds).subscribe(result => {
                var res: any = result;
                if (res != -1) {
                    this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['bloqueoRangos'])
                    );
                } else {
                    this.alertService.error(this.translateService.instant('error'));
                }
            });
        }

    }
    onClickDuplicar() {
        if (this.seleccionados.length > 0) {
            this.bloqueoRangosService.DuplicarBloqueoRangos(this.seleccionados[0]).subscribe(result => {
                var res: any = result;
                if (res != -1) {
                    this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['bloqueoRangos'])
                    );
                } else {
                    this.alertService.error(this.translateService.instant('error'));
                }
            });
        }
    }

}