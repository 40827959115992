import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { query } from '@angular/animations';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/operacionespredefinidas`;
@Injectable()
export class OperacionesPredefinidasService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  //NO SE UTILIZA
  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }

  public GetAll(action: string = '',param?:string, data?: ''): Observable<any> {
    this.loading = true;
    var headers, params;

    if(param == undefined) {
      param=''

    }
    
    return this.http
      .get(baseUrl);
  }

  public GetByID(id:Number): Observable<any> {
    this.loading = true;
    var headers, params;

    
    
    return this.http
      .get(baseUrl + "/" + id);
  }

  public delete(data?: any): Observable<any> {
    //this.loading = true;

    var formData: any = new FormData();
    formData.append("idoperacion", data.ids);
    

    return this.http
      .post<JSON>(baseUrl + "/eliminar" , formData,{ withCredentials: true });
     
  }
  //createruta(this.form.value, filename, archivoBase64, this.idRuta, this.idParte, this.idPieza)
  createruta(data, filename, archivo, idsmaquinas, estadosmaquinas, idruta, idparte, idpieza) {

    data['idsmaquinas'] = idsmaquinas;
    data['estadosmaquinas'] = estadosmaquinas;
    data['filename'] = filename;
    data['archivo'] = archivo;
    data['idruta'] = idruta;
    data['idparte'] = idparte;
    data['idpieza'] = idpieza;
    return this.http.post<any>(baseUrl + "/crear", data, { withCredentials: true });
  }

  create(data, filename, archivo, idsmaquinas, estadosmaquinas, histCambiosAntes, histCambiosDespues, histCambiosCampos) {
    data['idsmaquinas'] = idsmaquinas;
    data['estadosmaquinas'] = estadosmaquinas;
    data['filename'] = filename;
    data['archivo'] = archivo;
    data['histCambiosAntes'] = histCambiosAntes;
    data['histCambiosDespues'] = histCambiosDespues;
    data['histCambiosCampos'] = histCambiosCampos;
    return this.http.post<any>(baseUrl + "/crear", data,   { withCredentials: true });
  }

  enlazar(data, filename, archivo, idruta, idparte, idpieza) {

    data['filename'] = filename;
    data['archivo'] = archivo;
    data['idruta'] = idruta;
    data['idparte'] = idparte;
    data['idpieza'] = idpieza;
    return this.http.post<any>(baseUrl + "/enlazar", data, { withCredentials: true });
  }

  enlazarpieza(data, filename, archivo, idruta, idparte, idpieza) {

    data['filename'] = filename;
    data['archivo'] = archivo;
    data['idruta'] = idruta;
    data['idparte'] = idparte;
    data['idpieza'] = idpieza;
    return this.http.post<any>(baseUrl + "/enlazarpieza", data, { withCredentials: true });
  }

  update(data, filename, archivo, idsmaquinas, estadosmaquinas, histCambiosAntes, histCambiosDespues, histCambiosCampos) {
    data['idsmaquinas'] = idsmaquinas;
    data['estadosmaquinas'] = estadosmaquinas;
    data['filename'] = filename;
    data['archivo'] = archivo;
    data['histCambiosAntes'] = histCambiosAntes;
    data['histCambiosDespues'] = histCambiosDespues;
    data['histCambiosCampos'] = histCambiosCampos;

    return this.http.post<any>(baseUrl+"/editar",data,{ withCredentials: true });
  }

 /* update_herramientas(herramientas: JSON, idOperacion: number) {
    return this.http.post<JSON>(`${baseUrl}/Update`, { herramientas, idOperacion}, { withCredentials: true }).subscribe();
  }*/
  addValor(data) {

    return this.http.post<any>(baseUrl + "/addvalor",  data  , { withCredentials: true });

  }

  public updateOrdenes(idRuta:Number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/updateOrdenes/" + idRuta);
  }

  public GetAll_Herramientas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/todaslasherramientas");
  }

  editValor(data) {


    return this.http.post<any>(baseUrl + "/editvalor", data, { withCredentials: true });

  }

  removeValor(id) {

    return this.http.post<any>(baseUrl + "/removevalor", id, { withCredentials: true });

  }

  duplicar(data) {

    return this.http.post<any>(baseUrl + "/duplicar", data, { withCredentials: true });

  }

  update_valores(req: any) {
    req.forEach(element => {
      if(element.tipoControl!=undefined && element.tipoControl!=null){
        if(element.tipoControl.id!=undefined && element.tipoControl.id!=null){
          element.tipoControl = element.tipoControl.id;
        }
      }
      if(element.tipoTolerancia!=undefined && element.tipoTolerancia!=null){
        if(element.tipoTolerancia.id!=undefined && element.tipoTolerancia.id!=null){
          element.tipoTolerancia = element.tipoTolerancia.id;
        }
      }
      if(element.idUtillaje!=undefined && element.idUtillaje!=null){
        if(element.idUtillaje.id!=undefined && element.idUtillaje.id!=null){
          element.idUtillaje = element.idUtillaje.id;
        }
      }
    });
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/update_valores`, { json }, { withCredentials: true });
  }

  update_herramientas(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/update_herramientas`, { json }, { withCredentials: true });
  }

  update_programas(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/update_herramientas`, { json }, { withCredentials: true });
  }

  public GetReferencias(): Observable<any> {
    this.loading = true;
    var headers, params;


    //piezas/<idOF>/<idPieza>/<idParte>

    return this.http
      .get(baseUrl + "/referencias");
  }

  public GetByIDSelectedCombo(data: any): Observable<any> {
    this.loading = true;
    var headers, params;


    //piezas/<idOF>/<idPieza>/<idParte>

    return this.http
      .get(baseUrl + "/combo/" + data.idoperacion);
  }

  public getdatosControl(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getdatosControl");
  }

  public getdatosTolerancia(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getdatosTolerancia");
  }

  public getdatosUtillaje(): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/getdatosUtillaje");
  }

  importarCotas(data) {
    return this.http.post<any>(baseUrl + "/importarCotas", data , { withCredentials: true });
  }

  creardepredefinido(id, idRuta) { 
    return this.http.post<any>(baseUrl + "/creardepredefinido", {Id: id, IdRuta: idRuta}, { withCredentials: true });
  }

  getOperacionesRelacionadas(id) { 
    return this.http.get(baseUrl + "/getOperacionesRelacionadas/" + id);
  }

  updatePredefinido(id, idRuta) { 
    return this.http.post<any>(baseUrl + "/updateOperacion", {idOperacion: id, idRuta: idRuta}, { withCredentials: true });
  }

}
