

<style>
</style>





<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- INFO -->
<div class="card">
  <div class="card-header">
    <h3><label>{{ 'datos' | translate }}</label></h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <!-- DATOS -->
    <div class="clearfix">
      <!-- NOMBRE -->
      <div class="float-left mr-1">
        <div class="form-group">
          <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nombre" [maxlength]="50" [class.requerido]="requiereNombre"></kendo-textbox>
          </div>
        </div>
      </div>
      <!-- FECHAS -->
      <!--<div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
        <div class="flt-opcion">
          <kendo-dropdownlist [data]="opcionDate" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
        </div>
        <div *ngIf="row.operator.id > 0" class="flt-datos">
          <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1" [(value)]="row.fechaIni"></kendo-datepicker>
          <div *ngIf="row.operator.dobleValor" class="flt-dato2">
            <div class="calendarFiltro">
              <div class="calendarFiltro-input" (click)="showCalendar(row)">
                <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni | kendoDate}}</label>
                <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                <i class="k-icon k-i-calendar"></i>
              </div>
            </div>
          </div>
        </div>
      </div>-->
      <!-- CURSO LECTIVO -->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'cursolectivo' | translate }}</label>
        <div class="caja" style="width: 150px">
          <kendo-combobox [data]="JcursosLectivos"
                          [textField]="'nombre'"
                          [valueField]="'id'"
                          [(value)]="JcursosLectivosSelected"
                          [(ngModel)]="selectedValueCurso"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          (selectionChange)="cursoChange()"
                          [class.requerido]="requiereCursoLectivo">
          </kendo-combobox>
        </div>
      </div>
      <!-- CALENDARIO -->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'calendario' | translate }}</label>
        <div class="caja" style="width: 150px">
          <kendo-combobox [data]="JcursosLectivosCalendario"
                          [textField]="'calendario'"
                          [valueField]="'id'"
                          [(value)]="JcursosLectivosCalendarioSelected"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          (selectionChange)="fabricanteChange()"
                          [class.requerido]="requiereCalendario">
          </kendo-combobox>
        </div>
      </div>
      <!-- TIPO -->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'tipo' | translate }}</label>
        <div class="caja" style="width: 150px">
          <kendo-combobox [data]="JcursosLectivosTipo"
                          [textField]="'tipo'"
                          [valueField]="'id'"
                          [(value)]="JcursosLectivosTipoSelected"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          (selectionChange)="fabricanteChange()"
                          [class.requerido]="requiereTipo">
          </kendo-combobox>
        </div>
      </div>
      <!-- COLOR -->
      <!--<div class="float-left mr-1">
        <label class="crontol-label">{{ 'color' | translate }}</label>
        <div class="circle">
          <TelerikColorPicker @bind-Value="#282f89"></TelerikColorPicker>
        </div>
      </div>-->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'color' | translate }}</label>
        <div class="caja">
          <input type="color" id="colorSelected" />
        </div>
      </div>
      <!-- ACTIVO -->
      <div class="float-left mr-1">
        <div class="form-group">
          <kendo-label text="{{ 'activo' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [(ngModel)]="activo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- TITULO -->
<div class="float-left mr-1">
  <div class="card" style="width: 500px">
    <div class="card-header">
      <h3><label>{{ 'titulo' | translate }}</label></h3>
      <div class="plegarpanel"></div>
      <div class="card-body" >
        <!-- DATOS -->
        <div class="clearfix" id="horario">
            <div class="float-left mr-1">
              <!-- DIA -->
              <div class="caja" style="width: 150px">
                <kendo-combobox [data]="listItems"
                            [textField]="'text'"
                            [valueField]="'id'"
                            [ngModel]="selectedValue"
                            (ngModelChange)="diaChange()"
                            placeholder="{{ 'dia' | translate }}">
                </kendo-combobox>
              </div>
            </div>
            <!-- HORA INICIO -->
            <div class="float-left mr-1" >
              <input id="hora-inicio" value="8:00 AM" title="timepicker" style="width: 80px" />
            </div>
            <!-- HORA FIN -->
            <div class="float-left mr-1" style="width: 150px">
              <input id="hora-fin" value="14:00 AM" title="timepicker" style="width: 80px" />
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ALUMNOS -->
<div class="float-left mr-1">
  <div class="card" style="width: 1300px">
    <div class="card-header">
      <h3><label>{{ 'alumnos' | translate }}</label></h3>
      <div class="plegarpanel"></div>
      <div class="card-body">
        <!-- DATOS -->
        <div class="clearfix">
            <!-- GRID: Tooltip -->
            <ng-template #template let-anchor>
              <span>{{ anchor.nativeElement.innerText }}</span>
            </ng-template>
            <kendo-grid [kendoGridBinding]="grupoAlumnos"
                        [sortable]="true"
                        [navigable]="true"
                        filterable="menu"
                        [rowHeight]="36"
                        [pageSize]="30"
                        kendoGridSelectBy="id"
                        scrollable="virtual"
                        [selectedKeys]="seleccionados">
              <ng-template kendoGridToolbarTemplate>
                <button id="bNuevo" kendoGridAddCommand class="btn btn-primary btn-sm mr-1">{{ 'agregar' | translate }}</button>
                <button (click)="eliminar()" class="btn btn-danger btn-sm mr-1">{{ 'eliminar' | translate}}</button>
              </ng-template>
              <!-- CHECK -->
              <kendo-grid-checkbox-column width="5%">
                <ng-template kendoGridHeaderTemplate>
                  <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                         [state]="selectAllState">
                  <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
              </kendo-grid-checkbox-column>
              <!-- NOMBRE -->
              <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="alumno" title="{{ 'nombre' | translate}}"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </div>
      </div>
    </div>
  </div>  
</div>
<!-- BOT(ONES) -->
<div class="clearfix">
  <button kendoButton class="btn mr-1  btn-success" (click)="guardar()" [disabled]="this.user.consumibles<2">{{ 'guardar' | translate}}</button>
  <button kendoButton class="btn mr-1  btn-copy" (click)="copiar()">{{ 'copiar' | translate}}</button>
  <button kendoButton class="btn mr-1  btn-danger" (click)="cancelar()">{{ 'cancelar' | translate}}</button>
</div>

<!-- POPUP: Error al guardar -->
<div class="popup-cont" *ngIf="popupErrorAlGuardar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlGuardar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlGuardar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
