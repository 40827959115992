<!-- al clickar fuera del menu se cerrara -->
<div (click)="cerrarMenu()" style="position:fixed; top:0; left:0; right:0; bottom:0; display:block;"></div>

<div class="cont-filtro-desplegable card" [ngClass]="menuAbierto ? 'filtro-desplegado' : 'filtro-oculto'">
    <div class="card-header">
        <h3>{{ 'configuracion' | translate }}</h3>
        <button type="button" class="flt-cerrar" (click)="cerrarMenu()">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="filtro-desplegable card-body flt">
        <!-- NOMBRE -->
        <div class="row">
            <div class="col">
                <kendo-label text="{{ 'nombre' | translate }}">
                    <kendo-textbox style="width: 100%;border: solid 1px #dfdfdf; margin-bottom: 10px;"
                        [value]="nombreEdit" (valueChange)="valueChangeNombre($event)">
                    </kendo-textbox>
                </kendo-label>
            </div>

        </div>
        <div class="row">
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'vinculado' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-switch [(ngModel)]="vinculadoEdit" (valueChange)="valueChangeVinculado($event)"
                        [onLabel]="' '" [offLabel]="' '"> </kendo-switch>
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'resto' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-switch [(ngModel)]="restoEdit" (valueChange)="valueChangeResto($event)" [onLabel]="' '"
                        [offLabel]="' '"> </kendo-switch>
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'corto' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-switch [(ngModel)]="cortoEdit" (valueChange)="valueChangeCorto($event)" [onLabel]="' '"
                        [offLabel]="' '"> </kendo-switch>
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'largo' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-switch [(ngModel)]="largoEdit" (valueChange)="valueChangeLargo($event)" [onLabel]="' '"
                        [offLabel]="' '"> </kendo-switch>
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'manual' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-switch [(ngModel)]="manualEdit" (valueChange)="valueChangeManual($event)" [onLabel]="' '"
                        [offLabel]="' '"> </kendo-switch>
                </div>
            </div>
        </div>

        <!-- SI VINCULADO ACTIVO -->
        <div *ngIf="vinculadoEdit">
            <!-- RAYA -->
            <div class="clearfix" *ngIf="largoEdit">
                <label>{{ 'semanasVista' | translate }}</label>
            </div>
            <div class="clearfix" *ngIf="largoEdit">
                <kendo-dropdownlist [data]="listaSemanasVista" [(ngModel)]="semanaEdit" (valueChange)="valueChangeSemanaVista($event)"></kendo-dropdownlist>
            </div>
            <!-- ORIGEN -->
            <!-- <div class="clearfix">
                <label>{{ 'origen' | translate}}</label>
            </div> -->
            <div class="clearfix">
                <!-- LISTA/FILTRO MAQUINAS -->

                <!-- MAQUINAS ORIGEN -->
                <div class="">
                    <div class="card">
                        <div class="card-header">
                            <h3>
                                <label ID="lMaquinasOrigen" CssClass="">{{ 'maquinasOrigen' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">

                            <!-- AGREGAR MAQUINAS -->
                            <!-- MAQUINAS -->
                            <div class="agregar-elemento-maquina" (click)="showOrigen();">
                                <span *ngIf="agregarSeleccionadosOrigen.length == 0">{{'maquinas' | translate}}</span>
                                <div *ngIf="agregarSeleccionadosOrigen.length != 0" class="clearfix">
                                    <div class="cont-tags-filtro">
                                        <div *ngFor="let maq of agregarOrigenMaquinas" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ maq.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarMaquinaOrigen(maq.id)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card agregar-maquinas-cont"
                                style="position: absolute !important; left: 0; right: 0; z-index: 1;"
                                *ngIf="agregarOrigen" id="agregar-maquinas">
                                <div class="card-body">

                                    <div class="clearfix agregar-maquinas-buscadorfiltro">
                                        <!-- BUSCAR -->
                                        <div class="buscador-cont">
                                            <kendo-textbox placeholder="{{ 'buscar' | translate }}"
                                                (valueChange)="buscarChangeOrigen($event)"></kendo-textbox>
                                        </div>
                                        <!-- FILTRO-->
                                        <div class="btn-filtro-cont"
                                            [ngClass]="{ 'filtro-parametros': filtroOrigen.length != 0 }"
                                            (click)="showFiltroOrigen(); showSeccionesOrigen = true; showGruposOrigen = false; showClasificacionesOrigen = false">
                                            <span class="icon-filtro"></span>
                                        </div>
                                    </div>

                                    <!-- SELECCIONADOS DEL FILTRO -->
                                    <div class="clearfix cont-tags-filtro" *ngIf="filtroOrigen.length > 0">
                                        <div *ngFor="let filtro of filtroOrigen" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarFiltroOrigen(filtro.nombre)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>

                                    <div class="card cont-grid-agrupado"
                                        style="position: absolute !important; left: 0; right: 0; top: 50px; z-index: 1;"
                                        *ngIf="ensennarFiltroOrigen">
                                        <div class="card-body">

                                            <!-- FILTRO -->
                                            <div class="grid-agrupado">
                                                <!-- SELECCIONADOS DEL FILTRO -->
                                                <div class="clearfix cont-tags-filtro" *ngIf="filtroOrigen.length > 0">
                                                    <div *ngFor="let filtro of filtroOrigen" class="tags-filtro">
                                                        <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                                        <!-- ELIMINAR -->
                                                        <button (click)="eliminarFiltroOrigen(filtro.nombre)"
                                                            class="fas fa-times" style="cursor:pointer"></button>
                                                    </div>
                                                </div>
                                                <!-- BUSCAR -->
                                                <div class="caja">
                                                    <kendo-textbox placeholder="{{ 'buscar' | translate }}"
                                                        (valueChange)="buscarFiltroChangeOrigen($event)"></kendo-textbox>
                                                </div>
                                                <kendo-grid [kendoGridBinding]="groupedFiltro"
                                                    [selectedKeys]="seccionesSeleccionadasOrigen" [sortable]="true"
                                                    [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                                    [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                                    (cellClick)="annadirFiltroSeleccionadoOrigen($event)"
                                                    [groupable]="true" [group]="groupsFiltro">
                                                    <!-- Columna para saber si esta seleccionado -->
                                                    <kendo-grid-column width="5px" [style]="{'text-align': 'left'}"
                                                        field="select" title="" class="p-0" [filterable]="false">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            <input class="k-checkbox" type="checkbox"
                                                                [checked]="dataItem.isSeleccionado"
                                                                (change)="annadirFiltroSeleccionadoOrigen($event, dataItem)" />
                                                        </ng-template>
                                                    </kendo-grid-column>
                                                    <kendo-grid-column width="95px" field="nombre"
                                                        title=""></kendo-grid-column>
                                                    <kendo-grid-column field="grouped" width="0%" title=" "
                                                        hidden="hidden"></kendo-grid-column>
                                                </kendo-grid>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- TABLA DE MAQUINAS-->
                                    <!-- AGRUPAR POR ... -->
                                    <div class="button-contenedores-filtro">
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorSeccionesOrigen, 'btn btn-sm': !isAgruparPorSeccionesOrigen }"
                                            (click)="agruparPorSeccionesOrigen()">{{ 'secciones' | translate }}</button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorGruposOrigen, 'btn btn-sm': !isAgruparPorGruposOrigen }"
                                            (click)="agruparPorGruposOrigen()">{{ 'grupos' | translate }}</button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorClasificacionesOrigen, 'btn btn-sm': !isAgruparPorClasificacionesOrigen }"
                                            (click)="agruparPorClasificacionesOrigen()">{{ 'clasificaciones' | translate
                                            }}</button>
                                    </div>

                                    <!-- LISTA MAQUINAS -->
                                    <div *ngIf="isAgruparPorSeccionesOrigen" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinasAgregarOrigen"
                                            [selectedKeys]="agregarSeleccionadosOrigen" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaOrigen($event)" [groupable]="true"
                                            [group]="groupsSecciones">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span *ngIf="dataItem.imagenBase64!=''" class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                            <span *ngIf="dataItem.imagenBase64==''"
                                                                class="grid-circulo">{{dataItem.siglas}}</span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                            <kendo-grid-column field="nombreSeccion" width="50%"
                                                title="{{ 'seccion' | translate}}" hidden="hidden"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                    <div *ngIf="isAgruparPorGruposOrigen" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinaParaGrupos"
                                            [selectedKeys]="agregarSeleccionadosOrigen" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaOrigen($event)" [groupable]="true"
                                            [group]="groupsGrupos">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                            <kendo-grid-column field="nombreGrupo" width="50%"
                                                title="{{ 'grupo' | translate}}" hidden="hidden"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                    <div *ngIf="isAgruparPorClasificacionesOrigen" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinasAgregarOrigen"
                                            [selectedKeys]="agregarSeleccionadosOrigen" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaOrigen($event)">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                    <!-- END TABLA DE MAQUINAS-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- RAYA -->

            <!-- DESTINO -->
            <!-- <div class="clearfix">
                <label>{{ 'destino' | translate}}</label>
            </div> -->
            <div class="clearfix">
                <!-- LISTA/FILTRO MAQUINAS -->
                <!-- MAQUINAS DESTINO -->
                <div class="">
                    <div class="card">
                        <div class="card-header">
                            <h3>
                                <label ID="lMaquinasDestino" CssClass="">{{ 'maquinasDestino' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">


                            <!-- AGREGAR MAQUINAS -->
                            <!-- MAQUINAS -->
                            <div class="agregar-elemento-maquina" (click)="showDestino();">
                                <span *ngIf="agregarSeleccionadosDestino.length == 0">{{'maquinas' | translate}}</span>
                                <div *ngIf="agregarSeleccionadosDestino.length != 0" class="clearfix">
                                    <div class="cont-tags-filtro">
                                        <div *ngFor="let maq of agregarDestinoMaquinas" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ maq.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarMaquinaDestino(maq.id)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card agregar-maquinas-cont"
                                style="position: absolute !important; left: 0; right: 0; z-index: 1;"
                                *ngIf="agregarDestino" id="agregar-maquinas">
                                <div class="card-body">

                                    <div class="clearfix agregar-maquinas-buscadorfiltro">
                                        <!-- BUSCAR -->
                                        <div class="buscador-cont">
                                            <kendo-textbox placeholder="{{ 'buscar' | translate }}"
                                                (valueChange)="buscarChangeDestino($event)"></kendo-textbox>
                                        </div>
                                        <!-- FILTRO-->
                                        <div class="btn-filtro-cont"
                                            [ngClass]="{ 'filtro-parametros': filtroDestino.length != 0 }"
                                            (click)="showFiltroDestino(); showSeccionesDestino = true; showGruposDestino = false; showClasificacionesDestino = false">
                                            <span class="icon-filtro"></span>
                                        </div>
                                    </div>

                                    <!-- SELECCIONADOS DEL FILTRO -->
                                    <div class="clearfix cont-tags-filtro" *ngIf="filtroDestino.length > 0">
                                        <div *ngFor="let filtro of filtroDestino" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarFiltroDestino(filtro.nombre)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>

                                    <div class="card  cont-grid-agrupado"
                                        style="position: absolute !important; left: 0; right: 0; top: 50px; z-index: 1;"
                                        *ngIf="ensennarFiltroDestino">
                                        <div class="card-body">

                                            <!-- FILTRO -->
                                            <div class="grid-agrupado">
                                                <!-- SELECCIONADOS DEL FILTRO -->
                                                <div class="clearfix cont-tags-filtro" *ngIf="filtroDestino.length > 0">
                                                    <div *ngFor="let filtro of filtroDestino" class="tags-filtro">
                                                        <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                                        <!-- ELIMINAR -->
                                                        <button (click)="eliminarFiltroDestino(filtro.nombre)"
                                                            class="fas fa-times" style="cursor:pointer"></button>
                                                    </div>
                                                </div>
                                                <!-- BUSCAR -->
                                                <div class="caja">
                                                    <kendo-textbox placeholder="{{ 'buscar' | translate }}"
                                                        (valueChange)="buscarFiltroChangeDestino($event)"></kendo-textbox>
                                                </div>
                                                <kendo-grid [kendoGridBinding]="groupedFiltro"
                                                    [selectedKeys]="seccionesSeleccionadasDestino" [sortable]="true"
                                                    [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                                    [navigable]="true" kendoGridSelectBy="idGrid" [height]="270"
                                                    (cellClick)="annadirFiltroSeleccionadoDestino($event)"
                                                    [groupable]="true" [group]="groupsFiltro">
                                                    <!-- Columna para saber si esta seleccionado -->
                                                    <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                        field="select" title="" class="p-0" [filterable]="false">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            <input type="checkbox" class="k-checkbox"
                                                                [checked]="dataItem.isSeleccionadoDestino"
                                                                (change)="annadirFiltroSeleccionadoDestino($event, dataItem)" />
                                                        </ng-template>
                                                    </kendo-grid-column>
                                                    <kendo-grid-column width="95%" field="nombre"
                                                        title=""></kendo-grid-column>
                                                    <kendo-grid-column field="grouped" width="0%" title=" "
                                                        hidden="hidden"></kendo-grid-column>
                                                </kendo-grid>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- TABLA DE MAQUINAS-->
                                    <!-- AGRUPAR POR ... -->
                                    <div class="button-contenedores-filtro">
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorSeccionesDestino, 'btn btn-sm': !isAgruparPorSeccionesDestino }"
                                            (click)="agruparPorSeccionesDestino()">{{ 'secciones' | translate
                                            }}</button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorGruposDestino, 'btn btn-sm': !isAgruparPorGruposDestino }"
                                            (click)="agruparPorGruposDestino()">{{ 'grupos' | translate }}</button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorClasificacionesDestino, 'btn btn-sm': !isAgruparPorClasificacionesDestino }"
                                            (click)="agruparPorClasificacionesDestino()">{{ 'clasificaciones' |
                                            translate }}</button>
                                    </div>

                                    <!-- LISTA MAQUINAS -->
                                    <div *ngIf="isAgruparPorSeccionesDestino" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinasAgregarDestino"
                                            [selectedKeys]="agregarSeleccionadosDestino" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaDestino($event)" [groupable]="true"
                                            [group]="groupsSecciones">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasDestino()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado"
                                                        *ngIf="dataItem.isSeleccionadoDestino"></div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                            <kendo-grid-column field="nombreSeccion" width="50%"
                                                title="{{ 'seccion' | translate}}" hidden="hidden"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                    <div *ngIf="isAgruparPorGruposDestino" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinaParaGrupos"
                                            [selectedKeys]="agregarSeleccionadosDestino" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaDestino($event)" [groupable]="true"
                                            [group]="groupsGrupos">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado"
                                                        *ngIf="dataItem.isSeleccionadoDestino"></div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                            <kendo-grid-column field="nombreGrupo" width="50%"
                                                title="{{ 'grupo' | translate}}" hidden="hidden"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                    <div *ngIf="isAgruparPorClasificacionesDestino" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinasAgregarDestino"
                                            [selectedKeys]="agregarSeleccionadosDestino" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaDestino($event)">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado"
                                                        *ngIf="dataItem.isSeleccionadoDestino"></div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                    <!-- END TABLA DE MAQUINAS-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>




            <!-- ACTUAL -->
            <!-- <div class="clearfix">
                <label>{{ 'actual' | translate}}</label>
            </div> -->
            <div class="clearfix">
                <!-- LISTA/FILTRO MAQUINAS -->

                <!-- MAQUINAS ACTUAL -->
                <div class="">
                    <div class="card">
                        <div class="card-header">
                            <h3>
                                <label ID="lMaquinasActual" CssClass="">{{ 'maquinasActual' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">

                            <!-- AGREGAR MAQUINAS -->
                            <!-- MAQUINAS -->
                            <div class="agregar-elemento-maquina" (click)="showActual();">
                                <span *ngIf="agregarSeleccionadosActual.length == 0">{{'maquinas' | translate}}</span>
                                <div *ngIf="agregarSeleccionadosActual.length != 0" class="clearfix">
                                    <div class="cont-tags-filtro">
                                        <div *ngFor="let maq of agregarActualMaquinas" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ maq.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarMaquinaActual(maq.id)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card agregar-maquinas-cont"
                                style="position: absolute !important; left: 0; right: 0; z-index: 1;"
                                *ngIf="agregarActual" id="agregar-maquinas">
                                <div class="card-body">

                                    <div class="clearfix agregar-maquinas-buscadorfiltro">
                                        <!-- BUSCAR -->
                                        <div class="buscador-cont">
                                            <kendo-textbox placeholder="{{ 'buscar' | translate }}"
                                                (valueChange)="buscarChangeActual($event)"></kendo-textbox>
                                        </div>
                                        <!-- FILTRO-->
                                        <div class="btn-filtro-cont"
                                            [ngClass]="{ 'filtro-parametros': filtroActual.length != 0 }"
                                            (click)="showFiltroActual(); showSeccionesActual = true; showGruposActual = false; showClasificacionesActual = false">
                                            <span class="icon-filtro"></span>
                                        </div>
                                    </div>

                                    <!-- SELECCIONADOS DEL FILTRO -->
                                    <div class="clearfix cont-tags-filtro" *ngIf="filtroActual.length > 0">
                                        <div *ngFor="let filtro of filtroActual" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarFiltroActual(filtro.nombre)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>

                                    <div class="card cont-grid-agrupado"
                                        style="position: absolute !important; left: 0; right: 0; top: 50px; z-index: 1;"
                                        *ngIf="ensennarFiltroActual">
                                        <div class="card-body">

                                            <!-- FILTRO -->
                                            <div class="grid-agrupado">
                                                <!-- SELECCIONADOS DEL FILTRO -->
                                                <div class="clearfix cont-tags-filtro" *ngIf="filtroActual.length > 0">
                                                    <div *ngFor="let filtro of filtroActual" class="tags-filtro">
                                                        <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                                        <!-- ELIMINAR -->
                                                        <button (click)="eliminarFiltroActual(filtro.nombre)"
                                                            class="fas fa-times" style="cursor:pointer"></button>
                                                    </div>
                                                </div>
                                                <!-- BUSCAR -->
                                                <div class="caja">
                                                    <kendo-textbox placeholder="{{ 'buscar' | translate }}"
                                                        (valueChange)="buscarFiltroChangeActual($event)"></kendo-textbox>
                                                </div>
                                                <kendo-grid [kendoGridBinding]="groupedFiltro"
                                                    [selectedKeys]="seccionesSeleccionadasActual" [sortable]="true"
                                                    [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                                    [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                                    (cellClick)="annadirFiltroSeleccionadoActual($event)"
                                                    [groupable]="true" [group]="groupsFiltro">
                                                    <!-- Columna para saber si esta seleccionado -->
                                                    <kendo-grid-column width="5px" [style]="{'text-align': 'left'}"
                                                        field="select" title="" class="p-0" [filterable]="false">
                                                        <ng-template kendoGridCellTemplate let-dataItem>
                                                            <input class="k-checkbox" type="checkbox"
                                                                [checked]="dataItem.isSeleccionado"
                                                                (change)="annadirFiltroSeleccionadoActual($event, dataItem)" />
                                                        </ng-template>
                                                    </kendo-grid-column>
                                                    <kendo-grid-column width="95px" field="nombre"
                                                        title=""></kendo-grid-column>
                                                    <kendo-grid-column field="grouped" width="0%" title=" "
                                                        hidden="hidden"></kendo-grid-column>
                                                </kendo-grid>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- TABLA DE MAQUINAS-->
                                    <!-- AGRUPAR POR ... -->
                                    <div class="button-contenedores-filtro">
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorSeccionesActual, 'btn btn-sm': !isAgruparPorSeccionesActual }"
                                            (click)="agruparPorSeccionesActual()">{{ 'secciones' | translate }}</button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorGruposActual, 'btn btn-sm': !isAgruparPorGruposActual }"
                                            (click)="agruparPorGruposActual()">{{ 'grupos' | translate }}</button>
                                        <button type="button"
                                            [ngClass]="{'btn btn-success btn-sm': isAgruparPorClasificacionesActual, 'btn btn-sm': !isAgruparPorClasificacionesActual }"
                                            (click)="agruparPorClasificacionesActual()">{{ 'clasificaciones' | translate
                                            }}</button>
                                    </div>

                                    <!-- LISTA MAQUINAS -->
                                    <div *ngIf="isAgruparPorSeccionesActual" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinasAgregarActual"
                                            [selectedKeys]="agregarSeleccionadosActual" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaActual($event)" [groupable]="true"
                                            [group]="groupsSecciones">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span *ngIf="dataItem.imagenBase64!=''" class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                            <span *ngIf="dataItem.imagenBase64==''"
                                                                class="grid-circulo">{{dataItem.siglas}}</span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                            <kendo-grid-column field="nombreSeccion" width="50%"
                                                title="{{ 'seccion' | translate}}" hidden="hidden"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                    <div *ngIf="isAgruparPorGruposActual" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinaParaGrupos"
                                            [selectedKeys]="agregarSeleccionadosActual" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaActual($event)" [groupable]="true"
                                            [group]="groupsGrupos">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                            <kendo-grid-column field="nombreGrupo" width="50%"
                                                title="{{ 'grupo' | translate}}" hidden="hidden"></kendo-grid-column>
                                        </kendo-grid>
                                    </div>

                                    <div *ngIf="isAgruparPorClasificacionesActual" class="grid-agrupado">
                                        <kendo-grid [kendoGridBinding]="maquinasAgregarActual"
                                            [selectedKeys]="agregarSeleccionadosActual" [sortable]="true"
                                            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                            [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                            (cellClick)="annadirMaquinaActual($event)">
                                            <!-- Columna para saber si esta seleccionado -->
                                            <kendo-grid-column width="5%" [style]="{'text-align': 'left'}"
                                                field="select" title="" class="p-0" [filterable]="false">
                                                <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                    </div>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <!-- Imagen -->
                                            <kendo-grid-column field="maquinas" title="" width="8%"
                                                class="celda-tooltip-externo" [filterable]="false">
                                                <ng-template kendoGridCellTemplate let-dataItem>
                                                    <span class="tooltip-consumibles-contenido">
                                                        <ng-container>
                                                            <span class="grid-circulo"
                                                                style="background-color:white!important;"
                                                                [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                        </ng-container>
                                                    </span>
                                                </ng-template>
                                            </kendo-grid-column>
                                            <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                        </kendo-grid>
                                    </div>
                                    <!-- END TABLA DE MAQUINAS-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>



        <!-- RAYA -->

        <div class="row">
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'piezasMinima' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-numerictextbox format="##" [min]="0" [max]="piezaMaxEdit" [value]="piezaMinEdit"
                        (valueChange)="valueChangeMin($event)">
                    </kendo-numerictextbox>
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'piezasOptima' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-numerictextbox format="##" [min]="piezaMinEdit" [max]="piezaMaxEdit"
                        [value]="piezaOptimaEdit" (valueChange)="valueChangeOptimo($event)">
                    </kendo-numerictextbox>
                </div>
            </div>
            <div class="col">
                <div class="clearfix">
                    <label>{{ 'piezasMaxima' | translate }}</label>
                </div>
                <div class="clearfix">
                    <kendo-numerictextbox format="##" [min]="piezaMinEdit" [value]="piezaMaxEdit"
                        (valueChange)="valueChangeMax($event)">
                    </kendo-numerictextbox>
                </div>
            </div>

        </div>

    </div>

</div>







<!-- al clickar fuera del menu se cerrara -->
<div (click)="cerrarMenu()" style="position:fixed; top:0; left:0; right:0; bottom:0; display:block;"></div>

<div class="cabecera-editor card">
    <div class="clearfix">
        <form [formGroup]="formTitulo">
            <div class="cabecera-editor-titulo">
                <!-- TITULO -->
                <input type="text" placeholder="Título" name="titulo" formControlName="titulo"
                    style="width: 100%;border: solid 1px #dfdfdf;" value="" [(ngModel)]="tituloSeguimiento" required
                    [ngClass]="!tituloValido ? 'border border-danger' : ''" />
            </div>
            <div class="cabecera-editor-botones">
                <!-- BOTONES -->
                <div class="btn-group float-right" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-nuevo btn-sm mr-1" (click)="guardarSeguimiento(0)">{{ 'guardar'
                        | translate }}</button>
                    <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="guardarSeguimiento(1)">{{
                        'guardarcomocopia' |
                        translate }}</button>
                    <button type="button" class="btn btn-danger btn-sm mr-1"
                        (click)="this.router.navigate(['seguimientoOFsConfigurador']);">{{ 'cancelar' | translate
                        }}</button>
                </div>
            </div>
        </form>
    </div>
</div>



<div class="clearfix contenedor-editor">
    <!-- LINEAS -->
    <div class="card" *ngFor="let linea of lineas; let i = index" [attr.data-index]="i"
        [ngClass]="linea.oculto ? 'oculto' : ''">
        <div class="card-header">
            <div class="cont-opciones-editor">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <div class="btn-group">
                        <button type="button" class="btn dropdown-toggle " (click)="subirLinea(i)">
                            <i data-toggle="dropdown" class="icon-flechaarriba"></i>
                        </button>
                        <button type="button" class="btn dropdown-toggle " (click)="bajarLinea(i)">
                            <i data-toggle="dropdown" class="icon-flechaabajo"></i>
                        </button>
                        <button type="button" class="btn dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i data-toggle="dropdown" class="icon-columnas"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <div class="d-flex justify-content-center bd-highlight">
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 1)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 2)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 3)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 4)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 5)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 6)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 7)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                                <button type="button" class="btn" (click)="cambiarCantidadColumnas(i, 8)">
                                    <span class="uncol-icono">
                                        <span class="col-icono"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i data-toggle="dropdown" class="icon-ajustes"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <!-- <button class="dropdown-item" type="button" (click)="linea.oculto = true"><i
                                    class="icon-novisible"></i>{{ 'ocultar' | translate }}</button> -->
                            <button class="dropdown-item" type="button" (click)="duplicarLinea(linea)"><i
                                    class="icon-duplicar"></i>{{ 'duplicar' | translate }}</button>
                            <button class="dropdown-item" type="button" (click)="eliminarLinea(i)"><i
                                    class="icon-equis"></i>{{ 'eliminar' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row" *ngIf="linea.colNum > 0">
                <div class="col" *ngFor="let col of linea.infoColumnas; let j = index">
                    <div *ngIf="col.nombre == ''" class="agregar-elemento" (click)="botonAnnadirInfo(j, linea.id, i);">
                        <i class="icon-mas-alt"></i>
                    </div>
                    <div *ngIf="col.nombre != ''"
                        style="border: solid 1px #dfdfdf; border-radius: 3px; margin: 10px 0 0 0; text-align: center; padding: 4px 0px 0px 0px; cursor: pointer; height: calc(100% - 10px);"
                        (click)="botonAnnadirInfo(j, linea.id, i);">
                        <div class="clearfix">
                            <label>{{col.nombre}}</label>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <button type="button" class="btn" (mouseenter)="clickFlecha=true"
                                    (mouseleave)="clickFlecha=false" (click)="columnaIzquierda(i,j)">
                                    <i data-toggle="dropdown" class="icon-flechaizquierda"></i>
                                </button>
                            </div>
                            <div class="col-8">
                                <i class="icon-engranaje"></i>
                            </div>
                            <div class="col-2">
                                <button type="button" class="btn" (mouseenter)="clickFlecha=true"
                                    (mouseleave)="clickFlecha=false"  (click)="columnaDerecha(i,j)">
                                    <i data-toggle="dropdown" class="icon-flechaderecha"></i>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>









<!-- ANNADIR NUEVA LINEA -->
<div class="text-center">
    <div class="btn-group mr-2">
        <button type="button" class="btn btn-nuevo dropdown-toggle " data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="icon-mas"></i> {{ 'anadirlinea' | translate }}
        </button>
        <div class="dropdown-menu">
            <div class="d-flex justify-content-center bd-highlight">
                <button type="button" class="btn" (click)="anadirLinea(1)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(2)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(3)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(4)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(5)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(6)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(7)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
                <button type="button" class="btn" (click)="anadirLinea(8)">
                    <span class="uncol-icono">
                        <span class="col-icono"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>