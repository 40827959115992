export * from './usuarios.service';
export * from './alert.service';
export * from './menu.service';
export * from './usuarios-tipos.service'
export * from './planesaccion.service';
export * from './repercusiones.service';
export * from './controlasignaciones.service';
export * from './acciones.service';
export * from './validador.service';
export * from './bonos.service';
export * from './subcontratado.service';
export * from './maquinas.service';
export * from './perdidas.service';
export * from './of.service';
export * from './piezas.service';
export * from './piezasPredefinidas.service';
export * from './operaciones.service';
export * from './operacionesPredefinidas.service';
export * from './diccionarioPerdidas.service';
export * from './mantenimientosPredefinidos.service';
export * from './configuracion.service';
export * from './historicoMaquinasDatos.service';
export * from './historicoMaquinasDatosTemporal.service';
export * from './historicoMaquinasDatosTemporal2.service';
export * from './controles.service';
export * from './informeRendimiento.service';
export * from './rutas.service';
export * from './rutasPredefinidas.service';
export * from './herramientas.service';
export * from './consumibles.service';
export * from './notas.service';
export * from './bom.service';
export * from './gestiondocumental.service';
export * from './maquinas.service';
export * from './marcas.service';
export * from './historicoAlarmas.service';
export * from './historicoParametros.service';
export * from './historicoMantenimientos.service';
export * from './almacenes.service';
export * from './programas.service';
export * from './boxplot.service';
export * from './fabricantes.service';
export * from './informeoee.service';
export * from './partes.service';
export * from './operarios.service';
export * from './planificador.service';
export * from './series.service';
export * from './centromecanizado.service';
export * from './hmi.service';
export * from './informeComparativaRndimiento.service';
export * from './combo.service';
export * from './informeEjecuciones.service';
export * from './informeConsumo.service';
export * from './turnos.service';
export * from './HistoricoOperaciones.service';
export * from './Disponibilidad.service';
export * from './resetallmodels.service';
export * from './informeProyectos.service';
export * from './piezasTipo.service';
export * from './materialesTipo.service';
export * from './operacionesTipo.service';
export * from './plantaIsometrico.service';
export * from './instalaciones.service';
export * from './areasProductivas.service';
export * from './historicoDatos.service';
export * from './incidencias.service';
export * from './alarmasTipo.service';
export * from './asignacion-tiempos.service';
export * from './clientes.service';
export * from './informe-calidad.service';
export * from './activos.service';
export * from './proveedores.service';
export * from './histogramaTiemposPieza.service';
export * from './recetas.service';
export * from './toleranciasTipo.service';
export * from './eskola_asignaturasRetos.service';
export * from './eskola_cursosLectivos.service';
export * from './eskola_cursosLectivosGrupos.service';
export * from './eskola_cursosLectivosTipos.service';
export * from './informePersonalizado.service';
export * from './informesPersonalizadosDisenador.service';
export * from './eskola_alumnos.service'
export * from './eskola_grupos.service'
export * from './eskola_calendarios.service'
export * from './calendario.service'
export * from './informePersonalizado.service';
export * from './eskola_alumnos.service';
export * from './eskola_grupos.service';
export * from './eskola_calendarios.service';
export * from './calendario.service';
export * from './eskola_Ofs.service';
export * from './eskola_OfsPiezas.service';
export * from './eskola_maquinas.service';
export * from './eskola_horariosSecciones.service';
export * from './eskola_reservaMaquinas.service';
export * from './atributos.service';
export * from './clasificaciones.service';

export * from '../app.component';
export * from './recetasInduccion.service';
export * from './contenedores.service';
export * from './bloqueoRangos.service';
export * from './automatizaciones.service';
export * from './seguimientoOFs.service';
export * from './subida-ficheros.service';