import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DateTimePickerActiveTab } from '@progress/kendo-angular-dateinputs';

const baseUrl = `${environment.apiUrl}/consumibles`;
const baseUrlGrupos = `${environment.apiUrl}/cursoslectivosgrupos`
const baseUrlTipos = `${environment.apiUrl}/cursoslectivostipos`

@Injectable()
export class CursosLectivosTiposService {

  constructor(private http: HttpClient) { }

  // TIPOS DE GRUPO
  public Get_tipos() {
    return this.http.post<JSON>(`${baseUrlTipos}/get_tipos`, {}, { withCredentials: true });
  }

  public GetById(id: number) {
    return this.http.post<JSON>(`${baseUrlTipos}/Get_tiposbyid`, { id }, { withCredentials: true });
  }

  public Create(nombre: string, descripcion: string, activo: number) {
    return this.http.post<JSON>(`${baseUrlTipos}/create_curso_lectivo_tipos`, { nombre, descripcion, activo }, { withCredentials: true });
  }

  public Update(id: number, nombre: string, descripcion: string, activo: number) {
    return this.http.post<JSON>(`${baseUrlTipos}/update_curso_lectivo_tipos`, { id, nombre, descripcion, activo }, { withCredentials: true });
  }

  public Delete(id: number) {
    return this.http.post<JSON>(`${baseUrlTipos}/delete_curso_lectivo_tipos`, { id }, { withCredentials: true });
  }

  public Get_tiposFiltros(nombre: string, activo: number) {
    return this.http.post<JSON>(`${baseUrlTipos}/get_tiposFiltros`, { nombre, activo }, { withCredentials: true });
  }

  public Get_tiposFiltrosActivo(activo: number) {
    return this.http.post<JSON>(`${baseUrlTipos}/get_tiposFiltrosActivos`, { activo }, { withCredentials: true });
  } 

}
