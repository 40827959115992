import { Component, Input, AfterViewInit, EventEmitter, Output,SimpleChanges } from '@angular/core';
import { MyFunctions } from '@app/_helpers/';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';
import { FilterService } from '@progress/kendo-angular-grid';
import { FilterDescriptor } from '@progress/kendo-data-query';
import * as moment from 'moment';

@Component({
  selector: 'filtro-fechas',
  templateUrl: './filtro-fechas.component.html',
})
export class FiltroFechasComponent implements AfterViewInit {
  // Current set filter.
  @Input() public currentFilter: any;
  // Value in charge of setting the filter.
  @Input() public filterService: FilterService;
  // Field we want to filter.
  @Input() public field: string;

  private format = 'YYYY/MM/DD';
  public DaysInMonths = [];
  @Input() public fechaIni: Date;
  @Input() public fechaFin: Date;
  @Output() newFechaInicio = new EventEmitter<Date>();
  @Output() newFechaFin = new EventEmitter<Date>();

  private today: Date;
  public mostrarCalendario = false;
  public requiereFechas = true;
  constructor(public myFunctions: MyFunctions) {
    this.today = new Date();
  }
  public ngAfterViewInit(): void {
  }
  ngOnChanges() {

  }
  showCalendar() {
    this.mostrarCalendario = !this.mostrarCalendario;
  }
  /**
   * Función para agregar los días seleccionados
   * al periodo correspondiente (el periodo seleccionado)
   */
   valueClickCalendar(month, event) {
   
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.fechaIni != undefined && this.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
   
        this.fechaFin = this.DaysInMonths[month];
        this.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (this.fechaIni == undefined && this.fechaFin == undefined) {
      

          this.fechaIni = this.DaysInMonths[month];
        }
        else if (this.fechaIni != undefined && this.fechaFin == undefined) {
     
          this.fechaFin = this.DaysInMonths[month];
          if (this.fechaIni > this.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.fechaIni.getTime());
            this.fechaIni = new Date(this.fechaFin.getTime());
            this.fechaFin = aux;
          }
          this.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
     
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.fechaIni = this.DaysInMonths[month];
          this.fechaFin = undefined;
        }
      }
    }

    this.newFechaFin.emit(this.fechaFin);
    this.newFechaInicio.emit(this.fechaIni);
    this.requiereFechas=false;


   

  }
  // valueClickCalendar(month, event) {
  //   if (!event.target.classList.contains('calendarFiltro-calendario-contenido-dia')) {
  //     if ((event.target.classList.contains('calendarFiltro-calendarioSeleccionado') && this.fechaIni != undefined && this.fechaFin == undefined)) {// se ha vuelto a seleccionar en el periodo, de seleccionar
  //       if (this.fechaIni != undefined && this.fechaFin == undefined) {// se ha vuelto a seleccionar en el periodo, de seleccionar
  //         console.log("B")
  //         this.fechaFin = this.DaysInMonths[month];
  //         //this.crearFiltro();
  //         this.mostrarCalendario = false;
  //       } else {
  //         if (this.fechaIni == undefined && this.fechaFin == undefined) {
  //           console.log("C1")
  //           this.fechaIni = this.DaysInMonths[month];
  //         }
  //         else if (this.fechaIni != undefined && this.fechaFin == undefined) {
  //           console.log("C2")
  //           this.fechaFin = this.DaysInMonths[month];
  //           if (this.fechaIni > this.fechaFin) {
  //             const aux = new Date(this.fechaIni.getTime());
  //             this.fechaIni = new Date(this.fechaFin.getTime());
  //             this.fechaFin = aux;
  //           }
  //           this.mostrarCalendario = false;
  //           //this.crearFiltro();
  //         }
  //         else {
  //           // en este caso había dos y se borran para empezar a seleccionar otra vez
  //           // por tanto, quitamos el tag hasta que se hayan seleccionado las dos
  //           this.fechaIni = this.DaysInMonths[month];
  //           this.fechaFin = undefined;
  //         }
  //       }
  //     }
  //   }
  // }
  // Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    this.DaysInMonths.push(this.today);
  }
  // Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value, calendario) {
    if (value == -1) {
      (calendario.children[0].children[0].getElementsByTagName('kendo-calendar-header')[0].children[2].children[0] as any).click();
      return;
    }
    if (value == 1) {
      (calendario.children[0].children[0].getElementsByTagName('kendo-calendar-header')[0].children[2].children[2] as any).click();
    }
  }

  // Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.fechaIni == undefined && this.fechaFin == undefined) {
      return false;
    } else if (this.fechaIni != undefined && this.fechaFin == undefined) {
      return date.getFullYear() == this.fechaIni.getFullYear() && 
             date.getMonth() == this.fechaIni.getMonth() && 
             date.getDate() == this.fechaIni.getDate();
    } else if (this.fechaIni != undefined && this.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.fechaIni.getFullYear(), this.fechaIni.getMonth(), this.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate());
    }
  };
  // isDateSelected(date) {
  //   if (this.fechaIni == undefined && this.fechaFin == undefined) {
  //     return false;
  //   }
  //   if (this.fechaIni != undefined && this.fechaFin == undefined) {
  //     return date.getFullYear() == this.fechaIni.getFullYear() && date.getMonth() == this.fechaIni.getMonth() && date.getDate() == this.fechaIni.getDate();
  //   }
  //   if (this.fechaIni != undefined && this.fechaFin != undefined) {
  //     return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.fechaIni.getFullYear(), this.fechaIni.getMonth(), this.fechaIni.getDate()) &&
  //       new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate());
  //   }
  // }

  ultimas24HButton() {
    this.fechaIni = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
    this.fechaFin = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos7DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate() - 6);
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos30DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 1, this.fechaFin.getDate());
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos60DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 2, this.fechaFin.getDate());
    this.mostrarCalendario = false;
    this.crearFiltro();
  }
  ultimos90DiasButton() {
    this.fechaFin = this.today;
    this.fechaIni = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 3, this.fechaFin.getDate());
    this.mostrarCalendario = false;
    this.crearFiltro();
  }

  private crearFiltro() {
    const fechaIniFormateada: string = moment(this.fechaIni).format(this.format);
    const fechaFinFormateada: string = moment(this.fechaFin).format(this.format);
    const filters = [
      { field: this.field, operator: 'gte', value: fechaIniFormateada },
      { field: this.field, operator: 'lte', value: fechaFinFormateada }
    ];
    this.filterService.filter({
      logic: 'and',
      filters,
    });
  }
}
