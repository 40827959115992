

<div class="diseñador">

  <div class="panel-superior-secciones">
    <div class="row">
      <div class="col-12 col-md-4 col-xl-3">
        <div class="form-group">
          <kendo-textbox style="height: 30px;" [(ngModel)]="nombrePlanta" [ngStyle]="{'border-color':nombrePlanta == '' ? 'red' : '' }"></kendo-textbox>
        </div>
      </div>      
      <div class="col-12 col-md-4 col-xl-2">
        <div class="form-group">
          <kendo-dropdowntree kendoDropDownTreeExpandable
                              [kendoDropDownTreeHierarchyBinding]="areasProductivas"
                              [textField]="'nombre'"
                              [valueField]="'id'"
                              [childrenField]="'secciones'"
                              (valueChange)="valueChangeAreasSecciones($event)"
                              [(ngModel)]="itemSeleccionadoAreasSeccionesTree"
                              placeholder="{{ 'seccion' | translate }}"
                              style="background: white;"></kendo-dropdowntree>
        </div>
      </div>
    </div>
  </div>

  <div class="planta ui-draggable ui-draggable-handle" style="position: relative;">

    <div class="isometrico suelo contenedor1" [ngStyle]="{'height.px': alto*100, 'width.px': ancho*100}">

      <ng-container *ngFor="let valdosa of valdosas">

        <!--VALDOSA CON OBJETO-->
        <div *ngIf="valdosa.esObjeto" class="tile" [attr.tilenum]="valdosa.tilenum" [attr.nomaq]="valdosa.nomaq" [attr.pareja]="valdosa.pareja"
             (click)="clickValdosa(valdosa, $event)" (mouseenter)="mouseEnterValdosa(valdosa)" (mouseleave)="mouseLeaveValdosa(valdosa)" (mousedown)="onMouseDown($event)"
             [ngClass]="valdosa.clase" 
             [class.tile-seleccionado]="valdosa.tileSeleccionado"
             [class.sobre]="valdosa.sobre"
             [class.tile-verde]="valdosa.tileVerde"
             [class.tile-roja]="valdosa.tileRoja"
             [ngStyle]="{ 'background-image': (valdosa.idTipoObjeto == 2 && valdosa.src != '') ? 'url(../../assets/isometrico/iconos/carreteras/' + valdosa.src + ')' : '',
                          'cursor': 'pointer' }">
          <img *ngIf="valdosa.pared == 1" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande01.png">
          <img *ngIf="valdosa.pared == 2" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande02.png">
          <img *ngIf="valdosa.pared == 3" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande03.png">
          <div *ngIf="(valdosa.idTipoObjeto == 1 || valdosa.idTipoObjeto == 4)" class="sombra"></div>
          <img *ngIf="(valdosa.idTipoObjeto == 1 || valdosa.idTipoObjeto == 3 || valdosa.idTipoObjeto == 4)" src="{{ '../../assets/isometrico/iconos/' + valdosa.src}}">
        </div>

        <!--VALDOSA SIN OBJETO-->
        <div *ngIf="!valdosa.esObjeto" class="tile" [ngClass]="valdosa.clase" [attr.tilenum]="valdosa.tilenum"
             (click)="clickValdosaVacia(valdosa, $event)" (mouseenter)="mouseEnterValdosaVacia(valdosa)" (mouseleave)="mouseLeaveValdosaVacia(valdosa)" (mousedown)="onMouseDown($event)"
             [ngStyle]="{'background-image': (valdosa.vistaPrevia  && valdosa.idTipoObjeto == 2 && valdosa.src != '') ? 'url(../../assets/isometrico/iconos/carreteras/' + valdosa.src + ')' : '' }"
             [ngClass]="{ 'tile-verde': valdosa.tileVerde, 'tile-roja': valdosa.tileRoja }">
          <img *ngIf="valdosa.pared == 1" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande01.png">
          <img *ngIf="valdosa.pared == 2" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande02.png">
          <img *ngIf="valdosa.pared == 3" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande03.png">
          <img *ngIf="valdosa.vistaPrevia && (valdosa.idTipoObjeto == 1 || valdosa.idTipoObjeto == 3 || valdosa.idTipoObjeto == 4)" src="{{ '../../assets/isometrico/iconos/' + valdosa.src }}" style="opacity: 0.8">
        </div>

      </ng-container>

      <!--VALDOSAS EXTRA (PARA OBJETOS DE MAS DE UN PISO-->
      <ng-container *ngFor="let valdosa of valdosasExtra">

        <div class="tile" style="position: absolute; outline: 0px;" [attr.tilenum]="valdosa.tilenum" [attr.nomaq]="valdosa.nomaq" [attr.pareja]="valdosa.pareja"
             [ngStyle]="{ 'top': valdosa.posTop + 'px', 'right': valdosa.posRight + 'px' }"
             [ngClass]="{ 'tile-seleccionado': valdosa.tileSeleccionado, 'sobre':valdosa.sobre, 'tile-verde': valdosa.tileVerde, 'tile-roja': valdosa.tileRoja }">
          <img src="{{ '../../assets/isometrico/iconos/' + valdosa.src}}"
               [ngStyle]="{ 'transition': valdosa.transitionMomentaneo }">
        </div>

      </ng-container>

    </div>

  </div>

  <div class="panel-selector desplegado">

    <div class="selector-categorias">
      <div class="clearfix">

        <button class="nav-link selector-categoria selector-categoria-maquinas" href="#"><i class="fas fa-cog"></i></button>
        <button class="nav-link selector-categoria selector-categoria-caminos" href="#"><i class="fas fa-road"></i></button>
        <button class="nav-link selector-categoria selector-categoria-paredes" href="#"><i class="fas fa-map"></i></button>
        <button class="nav-link selector-categoria selector-categoria-otros" href="#"><i class="fas fa-box-open"></i></button>

      </div>
    </div>

    <div class="panel-grupo-selector-inner">
      <div class="selector-grupo-maquinas">
        <table class="table">

          <ng-container *ngFor="let maquina of maquinas">

            <tr *ngIf="maquina.ensennarEnMenu" class="clearfix linea-selector-grupo" (click)="clickObjeto(maquina)" [ngClass]="{ 'linea-maquina-ubicada': maquina.seleccionada, 'maquina-en-planta': maquina.estaEnPlanta }">
              <td class="col-imagen"><img class="img-miniatura" style="margin-bottom: 0px;" src="{{ '../../assets/isometrico/iconos/' + maquina.imagenesMenu[maquina.posicionImagen] }}"></td>
              <td class="col-nombremaquina"><label class="selector-nombremaquina">{{ maquina.nombre }}</label><label class="selector-espacio">{{ maquina.ancho }}x{{ maquina.alto }}</label></td>
              <td *ngIf="maquina.estaEnPlanta" class="col-boton" (click)="onClickEliminarObjetoConcreto(maquina, $event)"><button class="botoneliminar"><i class="fas fa-trash"></i></button></td>
              <td *ngIf="!maquina.estaEnPlanta" class="col-boton" (click)="clickGirarMaquina(maquina, $event)"><button class="botongirar"><i class="fas fa-sync-alt"></i></button></td>
            </tr>

          </ng-container>

        </table>
      </div>
    </div>

    <div class="selector-grupo-caminos">
      <div class="row grupos-inner-cont ">

        <div class="col-md-3" *ngFor="let carretera of carreteras" (click)="clickObjeto(carretera)" style="padding: 7px 7px 7px 7px;" [ngClass]="{ 'linea-maquina-ubicada': carretera.seleccionada }">
          <img src="{{ '../../assets/isometrico/iconos/carreteras/' + carretera.miniatura }}">
        </div>

      </div>
    </div>

    <div class="selector-grupo-paredes">
      <div class="row grupos-inner-cont">

        <div class="col-md-3" *ngFor="let pared of paredes" (click)="clickObjeto(pared)" [ngClass]="{ 'linea-maquina-ubicada': pared.seleccionada }">
          <img src="{{ '../../assets/isometrico/iconos/' + pared.src }}">
        </div>

      </div>
    </div>

    <div class="selector-grupo-otros">
      <div class="row grupos-inner-cont">

        <div class="col-md-3" *ngFor="let otro of otros" (click)="clickObjeto(otro)" [ngClass]="{ 'linea-maquina-ubicada': otro.seleccionada }">
          <img src="{{ '../../assets/isometrico/iconos/' + otro.miniatura }}">
        </div>

      </div>
    </div>

    <div class="row" style="position: absolute; bottom: 6%; right: 0; left: 8%; ">

      <div class="col-md">
        <kendo-label text="{{ 'alto' | translate}}">
          <div class="row">
            <button kendoButton icon="minus" (click)="disminuirAlto()"></button>
            <kendo-numerictextbox [format]="'0'" [min]="5" [max]="30" [value]="alto" [spinners]="false" [readonly]="true" (valueChange)="onChangeAlto($event)" style="width: 50% !important;"></kendo-numerictextbox>
            <button kendoButton icon="plus" (click)="aumentarAlto()"></button>
          </div>
        </kendo-label>
      </div>

      <div class="col-md">
        <kendo-label text="{{ 'ancho' | translate}}">
          <div class="row">
            <button kendoButton icon="minus" (click)="disminuirAncho()"></button>
            <kendo-numerictextbox [format]="'0'" [min]="5" [max]="30" [value]="ancho" [spinners]="false" [readonly]="true" (valueChange)="onChangeAncho($event)" style="width: 50% !important;"></kendo-numerictextbox>
            <button kendoButton icon="plus" (click)="aumentarAncho()"></button>
          </div>
        </kendo-label>
      </div>

    </div>

    <div class="row" style="position: absolute; bottom: 1%; left: 4%;">
      <div class="col-md-6">
        <button (click)="onClickEliminarObjeto()" class="btn btn-danger btn-sm mr-1"  [disabled]="this.user.plantasIsometrico<2"
               [ngStyle]="{ 'visibility': ensennarBotonEliminarObjeto ? 'visible' : 'hidden' }">{{ 'eliminarObjeto' | translate}}</button>
      </div>
      <div class="col-md-3">
        <button (click)="onClickGuardar()" [disabled]="nombrePlanta=='' || this.user.plantasIsometrico<2" class="btn btn-primary btn-sm mr-1"  >{{ 'guardar' | translate}}</button>
      </div>
      <div class="col-md-3">
        <button (click)="onClickCancelar()" class="btn btn-danger btn-sm mr-1">{{ 'cancelar' | translate}}</button>
      </div>
    </div>

  </div>

  <div class="example-wrapper">

    <!--POPUP GUARDANDO-->
    <kendo-dialog title="{{ 'guardando' | translate}}" [minWidth]="250" [width]="450" *ngIf="guardando == 1">
      <p style="margin: 30px; text-align: center;">{{ 'guardandoDiseno' | translate}}</p>
      <div class="k-i-loading"></div>
    </kendo-dialog>

    <!--POPUP GUARDADO-->
    <kendo-dialog title="{{ 'guardado' | translate}}" (close)="cerrarPopUp()" [minWidth]="250" [width]="450" *ngIf="guardando == 2">
      <p style="margin: 30px; text-align: center;">{{ 'disenoGuardado' | translate}}</p>
      <kendo-dialog-actions>
        <button kendoButton primary="true" (click)="cerrarPopUp()">{{ 'aceptar' | translate}}</button>
      </kendo-dialog-actions>
    </kendo-dialog>

    <!--POPUP ERROR GUARDADO-->
    <kendo-dialog title="{{ 'error2' | translate}}" (close)="cerrarPopUp()" [minWidth]="250" [width]="450" *ngIf="guardando == 3">
      <p style="margin: 30px; text-align: center;">{{ 'errorDisenno' | translate}}</p>
      <kendo-dialog-actions>
        <button kendoButton primary="true" (click)="cerrarPopUp()">{{ 'aceptar' | translate}}</button>
      </kendo-dialog-actions>
    </kendo-dialog>

  </div>

</div>


