import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MyFunctions } from '@app/_helpers/';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, Eskola_CursosLectivosService, EskolaGruposService, UsuariosService, CursosLectivosTiposService, CalendariosService, Eskola_AlumnosService, Eskola_AsignaturasRetosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-cursosLectivosDetalle',
    templateUrl: './eskola_gruposDetalle.component.html'
})
export class Eskola_GruposDetalleComponent implements OnInit {

    navigationSubscription;

    form: FormGroup;
    public id: number;
    isAddMode: boolean = true;
    loading = false;
    loadingPanel = false;
    submitted = false;

    user = this.userService.userValue;

    // grupo
    grupo: any;

    //curso
    dtCursos: any = [];
    cursos: any = [];
    JcursoSelected: any = [];

    //calendario
    dtCalendarios: any = [];
    calendarios: any = [];
    JcalendarioSelected: any = [];

    //tipo
    dtTipos: any = [];
    tipos: any = [];
    JtipoSelected: any = [];

    //alumnos
    dtAlumnos: any = [];
    alumnos: any = [];
    alumnosAux: any = [];
    selectedAlumnos: any = [];
    multiselectAlumnos: any = [];
    alumnosParaEliminar: any = [];

    //docentes
    dtDocentes: any = [];
    docentes: any = [];
    docentesAux: any = [];
    selectedDocentes: any = [];
    multiselectDocentes: any = [];
    docentesParaEliminar: any = [];

    //tutores
    dtTutores: any = [];
    tutores: any = [];
    tutoresAux: any = [];
    selectedTutores: any = [];
    multiselectTutores: any = [];
    tutoresParaEliminar: any = [];

    //asignaturas/retos
    dtAsignaturasRetos: any = [];
    asignaturasRetos: any = [];
    aisgnaturasRetosAux: any = [];
    selectedAsignaturas: any = [];
    asignaturasParaEliminar: any = [];
    multiselectAsignaturasRetos: any = [];




    //Variables de validacion
    errorNombreVacio: boolean;
    errorFechasVacias: boolean;
    errorFechasFuera: boolean;
    errorCalendarioVacio: boolean;
    errorColorVacio: boolean;
    errorCursoVacio: boolean;
    errorTipoVacio: boolean;
    errorAlumnosVacios: boolean;
    errorTutoresVacios: boolean;
    errorDocentesVacios: boolean;
    errorAsignaturasRetosVacios: boolean;

    constructor(
        private myFunctions: MyFunctions,
        private alumnosService: Eskola_AlumnosService,
        private asignaturasRetosService: Eskola_AsignaturasRetosService,
        private calendariosService: CalendariosService,
        private cursosLectivosService: Eskola_CursosLectivosService,
        private cursosTiposService: CursosLectivosTiposService,
        private gruposService: EskolaGruposService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private alertService: AlertService,
        private userService: UsuariosService,
        private menuService: MenuService,
        private renderer: Renderer2
    ) {

        this.menuService.titulo = this.translateService.instant('grupo').toUpperCase();
        //this.cargarDatos()
    }

    async ngOnInit() {
        this.loadingPanel = true;
        this.errorNombreVacio = false;
        this.errorFechasVacias = false;
        this.errorFechasFuera = false;
        this.errorCalendarioVacio = false;
        this.errorColorVacio = false;
        this.errorCursoVacio = false;
        this.errorTipoVacio = false;
        this.errorAlumnosVacios = false;
        this.errorTutoresVacios = false;
        this.errorDocentesVacios = false;
        this.errorAsignaturasRetosVacios = false;

        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;


        this.grupo = {
            id: -1,
            id_cursoLectivo: this.translateService.instant('seleccionar'),
            id_tipoGrupo: this.translateService.instant('seleccionar'),
            calendario: this.translateService.instant('seleccionar'),
            nombre: "",
            activo: false,
            inicio: undefined,
            fin: undefined,
            color: undefined

        }
        if (!this.isAddMode) {
            {
                this.gruposService.getAll_filtrado("", "", "WHERE grupo.id IN (" + this.id + ")").subscribe(res => {
                  
                    this.grupo = res[0];
                    this.grupo.inicio = new Date(res[0].inicio);
                    this.grupo.fin = new Date(res[0].fin);
                });
            }
        }
        this.cargarDatos();
        setTimeout(() => {

            this.loadingPanel = false;

        }, 700);


    }

    cargarDatos() {

        this.cursosLectivosService.getAll().subscribe(res => {
            this.dtCursos = res;
            this.cursos = res;
        });
        this.cursosTiposService.Get_tipos().subscribe(res => {
            this.dtTipos = res;
            this.tipos = res;
        });
        this.calendariosService.getAll().subscribe(res => {
            this.dtCalendarios = res;
            this.calendarios = res;
        });

        this.userService.getAllUsers(6).subscribe(res => {
            res['data'].forEach(alumno => {
                alumno.nombrecompleto = alumno.nombre + " " + alumno.apellido1 + " " + alumno.apellido2;
            });
            this.dtAlumnos = res['data'];
            //this.selectedDocentes = res['data'];
            if (this.isAddMode) {
                this.alumnosAux = res['data'];
                this.alumnos = res['data'];
            } else {
                this.alumnosService.getAll_alumnos_filtrado("", "WHERE grupo.id IN (" + this.id + ")", "").subscribe(res => {
                    this.dtAlumnos.forEach(alumno => {
                        var a = res.filter(f => f.id_usuario === alumno.id)[0];
                        if (a != undefined) {
                            this.selectedAlumnos.push(alumno);
                        } else {
                            this.alumnos.push(alumno);
                            this.alumnosAux.push(alumno);
                        }

                    });
                });
            }
        });
        this.userService.getAllUsers(7).subscribe(res => {
            res['data'].forEach(docente => {
                docente.nombrecompleto = docente.nombre + " " + docente.apellido1 + " " + docente.apellido2;
            });
            this.dtDocentes = res['data'];
            this.dtTutores = res['data'];
            this.asignaturasRetosService.getAll().subscribe(res => {
                res.forEach(asignatura => {
                    asignatura.showMultiselectDocentes = false;
                    var arraydocentesId = asignatura.docentesId.split(',');
                    asignatura.docentes = [];
                    //asignatura.docentesAux = [];
                    asignatura.selectedDocentes = [];
                    arraydocentesId.forEach(id => {
                        var docente = this.dtDocentes.filter(f => f.id == id)[0];
                        if (docente != undefined) {
                            docente.nombrecompleto = docente.nombre + " " + docente.apellido1 + " " + docente.apellido2;
                            asignatura.docentes.push(docente);
                            //asignatura.docentesAux.push(docente);
                        }

                    });
                });
                this.dtAsignaturasRetos = res;
                if (this.isAddMode) {
                    this.aisgnaturasRetosAux = res;
                    this.asignaturasRetos = res;
                } else {
                    this.gruposService.getAsignaturasGrupo("WHERE id_grupo = " + this.grupo.id).subscribe(res => {
                        this.dtAsignaturasRetos.forEach(a => {
                            var ar = res.filter(f => f.id_asignatura == a.id)[0];
                            if (ar != undefined) {
                                a.docentes = [];
                                //a.docentesAux = [];
                                a.selectedDocentes = [];
                                a.hasierakoak = [];
                                this.gruposService.geDocentesAsignaturasGrupo("WHERE id_grupo = " + this.grupo.id + " AND id_asignaturaReto = " + a.id).subscribe(result => {
                                    this.dtDocentes.forEach(d => {
                                        var dg = result.filter(f => f.id_docente == d.id)[0];
                                        if (dg != undefined) {
                                            a.selectedDocentes.push(d);
                                            a.hasierakoak.push(d);
                                            a.docentes.push(d);
                                        } else {
                                            a.docentes.push(d);
                                           // a.docentesAux.push(d);
                                        }

                                    });
                                });
                                this.selectedAsignaturas.push(a);
                            } else {
                                this.aisgnaturasRetosAux.push(a);
                                this.asignaturasRetos.push(a);
                            }


                        });
                    });
                }
            });
            if (this.isAddMode) {
                this.tutoresAux = res['data'];
                this.tutores = res['data'];

               // this.docentes = res['data'];
               // this.docentesAux = res['data'];
            } else {
                this.gruposService.getTutoresGrupo("WHERE id_grupo IN (" + this.id + ")").subscribe(res => {
                    this.dtTutores.forEach(tutor => {
                        var a = res.filter(f => f.id_tutor === tutor.id)[0];
                        if (a != undefined) {
                            this.selectedTutores.push(tutor);
                        } else {
                            this.tutores.push(tutor);
                            this.tutoresAux.push(tutor);
                        }

                    });
                });
            }
        });

    }

    cursoSelectedChange() {
        this.grupo.id_cursoLectivo = this.JcursoSelected.id;
    }
    calendarioSelectedChange() {
        this.grupo.calendario = this.JcalendarioSelected.id;
    }
    tipoSelectedChange() {
        this.grupo.id_tipoGrupo = this.JtipoSelected.id;
    }

    onClickCancelar() {
        this.router.navigate(['/grupos']);
    }

    // MULTISELECT ALUMNOS
    // función de filtro del multiselect
    // @param filter evento que lanza el multiselect, string para filtrar
    filterChange(filter) {
        if (this.alumnos != null && this.alumnosAux != null) {
            if (filter == undefined) {
                this.alumnos = this.alumnosAux;
            }
            this.alumnos = this.alumnosAux.filter(
                (s) => s.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            );
        }
    }


    valueChange(selectedValue) {
        // lo añadimos a alumnos seleccionados
        var newAlumno = selectedValue[0];
        newAlumno.id_grupo = this.grupo.id;
        newAlumno.es_nuevo = true;
        this.selectedAlumnos.push(newAlumno);
        // limpiamos multiselect
        this.multiselectAlumnos = [];
        // quitamos de la lista de alumnos para que no vuelva a escoger el mismo
        this.alumnosAux = this.alumnosAux.filter(f => f.id != newAlumno.id);
        this.filterChange("");
    }

    annadirParaEliminar(mota, elemento) {
        switch (mota) {
            case "alumno":
                // lo añadimos a la lista de alumnos para eliminar
                this.alumnosParaEliminar.push(elemento.id);
                // lo quitamos de alumnos seleccionados
                this.selectedAlumnos = this.selectedAlumnos.filter(f => f.id != elemento.id);
                // lo añadimos al resto de alumnos para mostrar en la seleccion
                this.alumnosAux.push(elemento);
                this.alumnosAux = this.alumnosAux.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
                this.filterChange("");
                break;
            case "asignatura":
                this.asignaturasParaEliminar.push(elemento);
                elemento.selectedDocentes = [];
                this.selectedAsignaturas = this.selectedAsignaturas.filter(f => f.id != elemento.id);
                this.aisgnaturasRetosAux.push(elemento);
                this.aisgnaturasRetosAux = this.aisgnaturasRetosAux.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
                this.asignaturasFilterChange("");
                break;
            case "tutor":
                // lo añadimos a la lista de tutores para eliminar
                this.tutoresParaEliminar.push(elemento.id);
                // lo quitamos de tutores seleccionados
                this.selectedTutores = this.selectedTutores.filter(f => f.id != elemento.id);
                // lo añadimos al resto de tutores para mostrar en la seleccion
                this.tutoresAux.push(elemento);
                // ordenamos los tutores que se van a mostrar
                this.tutoresAux = this.tutoresAux.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
                this.filterChangeTutor("");
                break;

        }

    }
    // END MULTISELECT ALUMNOS

    //SELECT ASIGNATURAS/RETOS
    valueChangeAsignaturas(selectedValue) {
        // lo añadimos a alumnos seleccionados
        var newAsignatura = selectedValue[0];
        newAsignatura.id_grupo = this.grupo.id;
        newAsignatura.es_nuevo = true;
        newAsignatura.showMultiselectDocentes = true;
        this.selectedAsignaturas.push(newAsignatura);
        // limpiamos multiselect
        this.multiselectAsignaturasRetos = [];
        // quitamos de la lista de alumnos para que no vuelva a escoger el mismo
        this.aisgnaturasRetosAux = this.aisgnaturasRetosAux.filter(f => f.id != newAsignatura.id);
        this.asignaturasFilterChange("");
    }

    asignaturasFilterChange(filter) {
        if (this.asignaturasRetos != undefined && this.aisgnaturasRetosAux != undefined) {
            if (filter == undefined) {
                this.asignaturasRetos = this.aisgnaturasRetosAux;
            }
            this.asignaturasRetos = this.aisgnaturasRetosAux.filter(
                (s) => s.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            );
        }
    }
    valueChangeDocentes(selectedValue, asignatura) {

    }
    // END MULTISELECT ASIGNATURAS/RETOS

    //MULTISELECT TUTOR
    valueChangeTutor(selectedValue) {
        // lo añadimos a tutores seleccionados
        var newTutor = selectedValue[0];
        newTutor.id_grupo = this.grupo.id;
        newTutor.es_nuevo = true;
        this.selectedTutores.push(newTutor);
        // limpiamos multiselect
        this.multiselectTutores = [];
        // quitamos de la lista de tutores para que no vuelva a escoger el mismo
        this.tutoresAux = this.tutoresAux.filter(f => f.id != newTutor.id);
        this.filterChangeTutor("");
    }
    filterChangeTutor(filter) {
        if (this.tutores != null && this.tutoresAux != null) {
            if (filter == undefined) {
                this.tutores = this.tutoresAux;
            }
            this.tutores = this.tutoresAux.filter(
                (s) => s.nombre.toLowerCase().indexOf(filter.toLowerCase()) !== -1
            );
        }
    }
    //END MULSTISELECT TUTOR


    guardarClick() {
        if (this.validarGrupo()) {
            if (this.isAddMode == true) {
                this.insertGrupo();
                setTimeout(() => {
                    this.alertService.success(this.translateService.instant('creadocorrectamente'),{ keepAfterRouteChange: true });
                    this.router.navigate(["\grupos"]);

                }, 700);
            } else {
            
                this.updateGrupo();
                setTimeout(() => {
                    this.alertService.success(this.translateService.instant('actualizadocorrecto'),{ keepAfterRouteChange: true });
                    
                    this.router.navigate(["\grupos"]);

                }, 700);
            }
        }

    }

    // funciones llamada DB
    insertGrupo() {
        this.gruposService.insertGrupo(this.grupo.nombre, this.grupo.id_cursoLectivo, this.grupo.id_tipoGrupo, this.grupo.inicio, this.grupo.fin, this.grupo.calendario, this.grupo.activo, this.grupo.color).subscribe(res => {
            if (!res['error']) {
                var idNuevoGrupo = res['id'];
                this.selectedAlumnos.forEach(alumno => {
                    this.alumnosService.insertAlumno(alumno.id, idNuevoGrupo).subscribe();
                });
                this.selectedTutores.forEach(tutor => {
                    this.gruposService.insertTutorGrupo(tutor.id, idNuevoGrupo).subscribe();
                });
                this.selectedAsignaturas.forEach(asignatura => {
                    // insertar asignatura
                    this.gruposService.insertAsignaturaGrupo(asignatura.id, idNuevoGrupo).subscribe();
                    // insertar docentes de asignatura
                    asignatura.selectedDocentes.forEach(docente => {
                        this.gruposService.insertDocenteAsignaturaGrupo(docente.id, idNuevoGrupo, asignatura.id).subscribe();
                    });
                });

            }
        });
        this.alumnosParaEliminar = [];

    }
    updateGrupo() {
        this.gruposService.updateGrupo(this.grupo.id, this.grupo.nombre, this.grupo.id_cursoLectivo, this.grupo.id_tipoGrupo, this.grupo.inicio, this.grupo.fin, this.grupo.calendario, this.grupo.activo, this.grupo.color).subscribe(res => {
            if (!res['error']) {
                this.selectedAlumnos.forEach(alumno => {
                    if (alumno.es_nuevo) {
                        this.alumnosService.insertAlumno(alumno.id, this.grupo.id).subscribe();
                    }
                });
                this.alumnosParaEliminar.forEach(alumnoId => {
                    this.alumnosService.deleteAlumno(alumnoId, this.grupo.id).subscribe();
                });
                this.alumnosParaEliminar = [];
                this.selectedTutores.forEach(tutor => {
                    if (tutor.es_nuevo) {
                        this.gruposService.insertTutorGrupo(tutor.id, this.grupo.id).subscribe();
                    }
                });
                this.tutoresParaEliminar.forEach(alumnoId => {
                    this.gruposService.deleteTutorGrupo(alumnoId, this.grupo.id).subscribe();
                });
                this.tutoresParaEliminar = [];

                this.selectedAsignaturas.forEach(asignatura => {
                    if (asignatura.es_nuevo) {
                        this.gruposService.insertAsignaturaGrupo(asignatura.id, this.grupo.id).subscribe();
                       
                        asignatura.selectedDocentes.forEach(docente => {
                            this.gruposService.insertDocenteAsignaturaGrupo(docente.id, this.grupo.id, asignatura.id).subscribe();

                        });

                    } else {
                        asignatura.selectedDocentes.forEach(docente => {
                            if (asignatura.hasierakoak.filter(f => f.id == docente.id).length == 0) {
                                // el docente no estaba así que lo insertamos
                                this.gruposService.insertDocenteAsignaturaGrupo(docente.id, this.grupo.id, asignatura.id).subscribe();
                            }
                        });
                        //vemos si hemos eliminado algun docente
                        asignatura.hasierakoak.forEach(d => {
                            if (asignatura.selectedDocentes.filter(f => f.id == d.id).length == 0) {
                                //el docente no está así que lo eliminamos
                                this.gruposService.deleteDocenteAsignaturasGrupo(-1, d.id, this.grupo.id, asignatura.id).subscribe()
                            };
                        });

                    }

                });

                this.asignaturasParaEliminar.forEach(a => {
                    this.gruposService.deleteAsignaturasGrupo(-1, a.id, this.grupo.id).subscribe();
                });
                this.asignaturasParaEliminar = []

            }
        });
    }

    duplicarClick(){
        this.grupo.nombre = this.grupo.nombre + " copia";
        this.insertGrupo();
        setTimeout(() => {
            this.alertService.success(this.translateService.instant('creadocorrectamente'),{ keepAfterRouteChange: true });
            this.router.navigate(["\grupos"]);

        }, 700);
        
    }

    //validar formulario
    validarGrupo(): boolean {
        var correcto = true;
        this.errorNombreVacio = false;
        this.errorFechasVacias = false;
        this.errorFechasFuera = false;
        this.errorCalendarioVacio = false;
        this.errorColorVacio = false;
        this.errorCursoVacio = false;
        this.errorTipoVacio = false;
        this.errorAlumnosVacios = false;
        this.errorTutoresVacios = false;
        this.errorDocentesVacios = false;
        this.errorAsignaturasRetosVacios = false;

        // comprobamos nombre
        if (this.grupo.nombre == undefined || this.grupo.nombre == "") {
            this.errorNombreVacio = true;
            correcto = false;
        }

        // comprobamos fechas
        if (this.grupo.inicio == undefined || this.grupo.fin == undefined) {
            this.errorFechasVacias = true;
            correcto = false;
        } else if (this.grupo.id_cursoLectivo != this.translateService.instant('seleccionar') && (this.grupo.inicio < new Date(this.JcursoSelected.inicio) || this.grupo.fin > new Date(this.JcursoSelected.fin))) {
            this.errorFechasFuera = true;
            correcto = false;
        }

        // comprobamos curso
        if (this.grupo.id_cursoLectivo == this.translateService.instant('seleccionar')) {
            this.errorCursoVacio = true;
            correcto = false;
        }

        // comprobamos calendario
        if (this.grupo.calendario == this.translateService.instant('seleccionar')) {
            this.errorCalendarioVacio = true;
            correcto = false;
        }

        // comprobamos tipo
        if (this.grupo.id_tipoGrupo == this.translateService.instant('seleccionar')) {
            this.errorTipoVacio = true;
            correcto = false;
        }
        // comprobamos color
        if (this.grupo.color == undefined || this.grupo.color == this.translateService.instant('seleccionar')) {
            this.errorColorVacio = true;
            correcto = false;
        }

        // // comprobamos alumnos seleccionados
        // if (this.selectedAlumnos.length == 0 || this.selectedAlumnos == undefined) {
        //     this.errorAlumnosVacios = true;
        //     correcto = false;
        // }

        // // comprobamos tutores seleccionados
        // if (this.selectedTutores.length == 0 || this.selectedTutores == undefined) {
        //     this.errorTutoresVacios = true;
        //     correcto = false;
        // }

        // // comprobamos asignaturas/retos seleccionados
        // if (this.selectedAsignaturas.length == 0 || this.selectedAsignaturas == undefined) {
        //     this.errorAsignaturasRetosVacios = true;
        //     correcto = false;
        // } else {
        //     // comprobamos que las asignaturas seleccionadas tengan docentes asignados
        //     this.selectedAsignaturas.forEach(asignatura => {
        //         if (asignatura.selectedDocentes.length == 0 || asignatura.selectedDocentes == undefined) {
        //             this.errorDocentesVacios = true;
        //             correcto = false;
        //         }
        //     });
        // }


        return correcto;


    }

}
