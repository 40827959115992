<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }
</style>

  <!-- FILTRO -->
<div class="card">
  <div class="card-header">
    <h3>
      <label>{{ 'filtro' | translate}}</label>
    </h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <div class="clearfix">
      <!--LISTA OFS-->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'of' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOfs" [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'" [valueField]="'idOf'" placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>

      <!--LISTA CLIENTES-->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'cliente' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaClientes" [(ngModel)]="clientesSeleccionados" [textField]="'nombreCliente'" [valueField]="'idCliente'" placeholder="{{ 'seleccioneCliente' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>

      <!--LISTA PIEZAS-->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'pieza' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'" placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>

      <!--LISTA PLANOS -->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'plano' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPlanos" [(ngModel)]="planosSeleccionados" [textField]="'numeroPlano'" [valueField]="'numeroPlano'" placeholder="{{ 'SeleccionePlano' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].numeroPlano}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'planosSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>

      <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
      <div class="float-left mr-2" *ngIf="!user.ocultarParte">
        <div class="form-group">
          <label>{{ 'parte' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPartes" [(ngModel)]="partesSeleccionadas" [textField]="'nombreParte'" [valueField]="'idParte'" placeholder="{{ 'seleccioneParte' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreParte}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'partesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>

      <!--LISTA PROCESOS-->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'operacion' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOperaciones" [(ngModel)]="operacionesSeleccionadas" [textField]="'nombreOperacion'" [valueField]="'idOperacion'" placeholder="{{ 'seleccioneOperacion' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperacion }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'operacionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>

      <!--LISTA MAQUINAS-->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'maquina' | translate }}</label>
          <div class="caja">
            <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaMaquinasMostradas" [(ngModel)]="maquinasSeleccionadas" [textField]="'nombreMaquina'" [valueField]="'idMaquina'" placeholder="{{ 'seleccioneMaquina2' | translate }}" [autoClose]="false" style="width: 295px;">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreMaquina}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>


      <!--TERMINADOS-->
      <div class="float-left mr-2">
        <div class="form-group">
          <label>{{ 'terminados' | translate }}</label>
          <div class="caja" style="margin: 0 0 0 7px;">
            <kendo-switch (valueChange)="CambioFiltro()" [(ngModel)]="terminados" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>

      <div class="clearfix">
        <div class="fa-pull-left">
          <button type="button" class="btn btn-danger mr-1 float-left" (click)="btnLimpiarFiltro()">{{ 'limpiarFiltro' | translate }}</button>
          <button type="button" class="btn btn-copiar mr-1 float-left" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

  <kendo-tabstrip tabPosition="top" [keepTabContent]="false" (tabSelect)="onTabSelected($event)">
    <kendo-tabstrip-tab title="{{ 'tiempoEstimadoEjecucion' | translate}}" [selected]="true">
      <ng-template kendoTabContent>
        <!-- GRID: SIN PREPARACION -->
        <div kendoTooltip
             showOn="none"
             [tooltipTemplate]="template"
             filter=".k-grid td"
             (mouseover)="showGridTooltip($event)">
          <kendo-grid [kendoGridBinding]="dataGrid1"
                      [height]="500"
                      [resizable]="true"
                      [navigable]="true"
                      [sortable]="true"
                      filterable="menu"
                      class="grid-font-10"
                      kendoGridSelectBy="id"
                      [selectedKeys]="variablesSeleccionadas"
                      [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
                      [pageable]="true"
                      [pageSize]="100">
            <kendo-grid-checkbox-column [width]="20">
              <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                       (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,variablesSeleccionadas,
                       dataGrid1, 'id')">
                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
              </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column field="numeroOF" title="{{ 'OF' | translate }}" width="20%" [style]="{'text-align': 'center'}"></kendo-grid-column>
            <kendo-grid-column field="nombreCliente" title="{{ 'cliente' | translate }}" width="25%" [style]="{'text-align': 'center'}"></kendo-grid-column>
            <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" width="30%"></kendo-grid-column>
            <kendo-grid-column field="numeroPlano" title="{{ 'plano' | translate }}" width="25%"></kendo-grid-column>
            <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate }}" width="25%"></kendo-grid-column>
            <kendo-grid-column field="maquina" title="{{ 'maquinas' | translate }}" width="35%" [style]="{'text-align': 'center'}"></kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoSinPreparacion" title="{{ 'tEstimado' | translate }}" width="27%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoEstimadoSinPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tiempoPredicitvoSinPreparacion" title="{{ 'tPredictivo' | translate }}" width="27%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoPredictivoSinPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="cantOperaciones" title="{{ 'cantOperaciones' | translate }}" width="20%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="mejorTiempoSinPreparacion" title="{{ 'mejor_tiempo' | translate }}" width="25%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.mejorTiempoSinPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="mediaTiempoSinPreparacion" title="{{ 'tiempo_medio' | translate }}" width="25%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.mediaTiempoSinPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="peorTiempoSinPreparacion" title="{{ 'peorTiempo' | translate }}" width="25%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.peorTiempoSinPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="dataItem.auxTiempoSinPreparacion" title="{{ 'nuevoTiempo' | translate }}" width="25%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-maskedtextbox [mask]="mask" [(value)]="dataItem.auxTiempoSinPreparacion" (focusout)="focusOut($event, dataItem)"></kendo-maskedtextbox>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" width="15%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <button class="btn" (click)="redirect(dataItem)"><i class="fa fa-sign-in-alt"></i></button>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'tiempoEstimadoPreparacion' | translate}}">
      <ng-template kendoTabContent>
        <!-- GRID: PREPARACION -->
        <div kendoTooltip
             showOn="none"
             [tooltipTemplate]="template"
             filter=".k-grid td"
             (mouseover)="showGridTooltip($event)">
          <kendo-grid [kendoGridBinding]="dataGrid2"
                      [height]="500"
                      [resizable]="true"
                      [navigable]="true"
                      [sortable]="true"
                      filterable="menu"
                      class="grid-font-10"
                      kendoGridSelectBy="id"
                      [selectedKeys]="variablesSeleccionadas2"
                      [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
                      [pageable]="true"
                      [pageSize]="100">

            <kendo-grid-checkbox-column [width]="20" >
              <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                       (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,variablesSeleccionadas2,
                       dataGrid2, 'id')">
                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
              </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column field="numeroOF" title="{{ 'OF' | translate }}" width="20%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="nombreCliente" title="{{ 'cliente' | translate }}" width="25%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" width="30%"></kendo-grid-column>
            <kendo-grid-column field="numeroPlano" title="{{ 'plano' | translate }}" width="25%"></kendo-grid-column>
            <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate }}" width="25%"></kendo-grid-column>
            <kendo-grid-column field="maquina" title="{{ 'maquinas' | translate }}" width="32%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tEstimado' | translate }}" width="30%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoEstimadoPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tiempoPredictivoPreparacion" title="{{ 'tPredictivo' | translate }}" width="30%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoPredictivoPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="cantOperaciones" title="{{ 'cantOperaciones' | translate }}" width="25%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="mejorTiempoPreparacion" title="{{ 'mejor_tiempo' | translate }}" width="25%" [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.mejorTiempoPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="mediaTiempoPreparacion" title="{{ 'tiempo_medio' | translate }}" width="25%" [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.mediaTiempoPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="peorTiempoPreparacion" title="{{ 'peorTiempo' | translate }}" width="25%" [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.peorTiempoPreparacion)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="dataItem.nuevoTiempoPreparacion" title="{{ 'nuevoTiempo' | translate }}" width="25%" [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-maskedtextbox [mask]="mask" [(value)]="dataItem.auxTiempoPreparacion" (focusout)="focusOut($event, dataItem)"></kendo-maskedtextbox>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" width="15%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <button class="btn" (click)="redirect(dataItem)"><i class="fa fa-sign-in-alt"></i></button>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
            <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
</div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
  <div class="form-group" style=" margin-top: 20px;">

    <button class="btn btn-copiar btn-sm mr-1" (click)="btnMejorTiempo()">
      {{ 'asignarMejorTiempo' | translate}}
    </button>
    <button class="btn btn-nuevo btn-sm mr-1" (click)="btnTiempoPred()">
      {{ 'asignarTiempoPredictivo' | translate}}
    </button>
    <button class="btn btn-tiempoMedio btn-sm mr-1 btn-info" (click)="btnTiempoMedio()">
      {{ 'asignarTiempoMedio' | translate}}
    </button>
    <button class="btn btn-peorTiempo btn-sm mr-1 btn-secondary" (click)="btnPeorTiempo()">
      {{ 'asignarPeorTiempo' | translate}}
    </button>
    <button class="btn btn-copiar btn-sm mr-1" (click)="btnSave()"  [disabled]="this.user.asignacionTiempos<2">
      {{ 'guardar' | translate}}
    </button>

  </div>
</div>
