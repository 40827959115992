<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">         
  <kendo-grid [kendoGridBinding]="OF"
              scrollable="virtual"
              [rowHeight]="37"
              [height]="800"
              [resizable]="true"
              [navigable]="true"
              [sortable]="true"
              filterable="menu"
              kendoGridSelectBy="idAuxiliar"
              [selectedKeys]="ofsseleccionado"
              [selectable]="{checkboxOnly: true, drag: true, enabled: true, mode: 'multiple'}"
              (cellClick)="cellClick($event)"
              *ngIf="router.url === '/OF'">

    <ng-template kendoGridToolbarTemplate position="top">
      <div class="card">
        <div class="card-body">
          <div class="clearfix">
            <div class="form-group m-0 float-left">

              <button id="btneditar" Class="btnEditatu btn btn-success btn-sm mr-1" (click)="onClickEditar()">{{ 'editar' | translate }}</button>
              <button id="btnnuevo" (click)="onClickNuevo()" Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</button>
              <button id="btneliminar" Class="btnEzabatu btn btn-danger btn-sm mr-1" (click)="onClickEliminar(content)" [disabled]="this.user.proyectos < 2">{{ 'eliminar' | translate }}</button>
              
              <button type="button" class="btn" [class.btn-success]="vertodos" [class.btn-outline-success]="!vertodos" (click)=" btnVerTodos()">{{ 'terminadosynoterminados' | translate }}</button>

            </div>

            <div class="form-group mb-0 float-right" style="float: right; margin-left: 20px; margin-right: 20px">
              <div class="form-row">
                <label class="control-label mr-2 mt-1">{{ 'subirarchivoexcel' | translate }}</label>
                <kendo-upload (upload)="onUploadButtonClick($event,contentloadingupload)" [restrictions]="{allowedExtensions: ['.xlsx']  }" [(ngModel)]="myFiles">
                  <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                         pause="{{ 'pause' | translate }}"
                                         remove="{{ 'remove' | translate }}"
                                         resume="{{ 'resume' | translate }}"
                                         retry="{{ 'retry' | translate }}"
                                         select="{{ 'select' | translate }}"
                                         uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <!--checkbox-->
    <kendo-grid-checkbox-column width="3%">
      <ng-template kendoGridHeaderTemplate>
        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
               (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,ofsseleccionado,
               OF, 'idAuxiliar')">
        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template>
    </kendo-grid-checkbox-column>

    <kendo-grid-column field="numOf" title="{{ 'of' | translate }}" width="5%" [headerStyle]="{'text-align': 'center'}"></kendo-grid-column>
    <kendo-grid-column field="plano" title="{{ 'plano' | translate }}" width="12%" [headerStyle]="{'text-align': 'center'}">    </kendo-grid-column>
    <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="16%" [headerStyle]="{'text-align': 'center'}">    </kendo-grid-column>
    <kendo-grid-column field="refpieza" title="{{ 'refpieza' | translate }}" width="9%" [headerStyle]="{'text-align': 'center'}">    </kendo-grid-column>
    <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="10%" [headerStyle]="{'text-align': 'center'}">    </kendo-grid-column>
    <kendo-grid-column *ngIf="!user.ocultarParte" field="parte" title="{{ 'parte' | translate }}" width="5%" [headerStyle]="{'text-align': 'center'}">    </kendo-grid-column>
    <kendo-grid-column field="fechaini" title="{{ 'fechainicio' | translate }}" width="8%" [headerStyle]="{'text-align': 'center'}">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="fechaEntrega" title="{{ 'fechaentrega' | translate }}" width="8%" [headerStyle]="{'text-align': 'center'}">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaEntrega | kendoDate }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column [style]="{'text-align': 'right'}" field="cantidad" title="{{ 'cantidad' | translate }}" width="5%" [headerStyle]="{'text-align': 'center'}">
    </kendo-grid-column>
    <kendo-grid-column [style]="{'text-align': 'right'}" field="hechos" title="{{ 'hechos' | translate }}" width="6%" [headerStyle]="{'text-align': 'center'}">
    </kendo-grid-column>
    <kendo-grid-column [style]="{'text-align': 'right'}" field="prototipo" title="{{ 'prototipo' | translate }}" width="5%" [headerStyle]="{'text-align': 'center'}">
      <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
        <input type="checkbox" [checked]="dataItem.prototipo" disabled />
      </ng-template>
    </kendo-grid-column>
  
    <kendo-grid-column width="5%" field="terminado" [style]="{'text-align': 'left'}" title="{{ 't' | translate }}" filter="boolean" [headerStyle]="{'text-align': 'center'}">
      <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
        <input type="checkbox" [checked]="dataItem.terminado" disabled />
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>


<router-outlet></router-outlet>



<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>


<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="Eliminar(contentloading)" [disabled]="this.user.proyectos < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<ng-template #contentloadingupload let-modal>

  <div class="modal-body">
    <p>{{ 'importando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'importando' | translate }}...</span>
    </div>
  </div>
</ng-template>


<ng-template #contenterrorimportacion let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <ul class="listaerrores">
        <li>ERROR</li>
        <li>ERROR</li>
        <li>ERROR</li>
        <li>ERROR</li>
        <li>ERROR</li>
        <li>ERROR</li>
        <li>ERROR</li>
      </ul>
    </div>
  </div>

  <!--<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="Eliminar(contentloading)" [disabled]="this.user.proyectos < 2">{{ 'si' | translate }}</button>
  </div>-->
</ng-template>

<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup"></p>
    <div class="spinner-border" role="status">
      <span class="sr-only">

        {{ 'cargando' | translate }}...
      </span>
    </div>
  </div>
</ng-template>


