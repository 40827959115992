import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AlmacenesService, MenuService, UsuariosService, MaquinasService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { NgxImageCompressService } from 'ngx-image-compress';

import { GroupResult, groupBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-almacenes-detalle',
  templateUrl: './almacenesDetalle.component.html'
})
export class AlmacenesDetalleComponent {

  private translate: TranslateService;
  public id: number;
  public requerido: boolean;

  public imageToShow: any;
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  public user = this.userService.userValue;
  alertService: any;

  public listaTipoAlmacenes: any = [];
  public listaTipoPuesto: any = [];

  public secciones: any = [];
  public idSeccionSelected: any = [];
  public idSelected: any = {};
  public groupedSeccion: GroupResult[];

  public grupos: any = [];

  public listaPuestos: any = [];
  public maquinas: any = [];
  public instalaciones: any = [];
  public puestos: any = [];

  public enableSwicthPosiciones: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private almacenesService: AlmacenesService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private maquinasService: MaquinasService,
    private translateService: TranslateService,
    private ngxImageCompressService: NgxImageCompressService) {

    this.translate = translateService;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      archivo: new FormControl(''),
      descripcion: [''],
      capacidad: [''],
      tipoAlmacenSeleccionado: [{ id: 0 }],
      seccionesSeleccionadas: [{ id: 0 }],
      gruposSeleccionados: [{ id: 0 }],
      tiposPuestossSeleccionados: [{ id: 0 }],
      puestosSeleccionados: [{ id: 0 }],
      tienePosiciones: [false]
    });
  }

  ngOnInit() {
    this.cargarCombos();
  }

  cargarCombos() {
    var r1, r2, r3, r4, r5, r6 = false;
    /* TIPOS DE ALMACENES */
    var dt: any = [
      { id: 1, nombre: this.translateService.instant('puesto') },
      { id: 2, nombre: this.translateService.instant('armarioInteligente') },
      { id: 3, nombre: this.translateService.instant('armarioOalmacen') }
    ]
    this.listaTipoAlmacenes = <JSON>dt;
    r1 = true;

    /* SECCIONES / AREAS PRODUCTIVAS */
    var an1: any = this.userService.secciones;
    this.secciones = undefined;
    if (an1 != undefined) {
      this.secciones = an1.filter(f => f.activo === true);
    }
    if (this.secciones == undefined) {
      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;
          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);
          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
          r2 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6)
            this.cargarDatos();
        });
    } else {
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
      r2 = true;
    }

    /* GRUPOS */
    this.maquinasService.getGruposMaquinas().subscribe(
      json => {
        this.grupos = json.data;
        r3 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6)
          this.cargarDatos();
      });

    /* TIPO PUESTO */
    var dt2: any = [
      { id: 1, nombre: this.translateService.instant('mecanizado') },
      { id: 2, nombre: this.translateService.instant('extrusora') },
      { id: 3, nombre: this.translateService.instant('impresorasPlastico') },
      { id: 4, nombre: this.translateService.instant('inyectora') },
      { id: 5, nombre: this.translateService.instant('aditivo') },
      { id: 6, nombre: this.translateService.instant('laser') },
      { id: 7, nombre: this.translateService.instant('impresora3D') },
      { id: 8, nombre: this.translateService.instant('instalacion') },
      { id: 9, nombre: this.translateService.instant('medicion') },
      { id: 10, nombre: this.translateService.instant('puestoManual') }
    ]
    this.listaTipoPuesto = <JSON>dt2;
    r4 = true;

    /* PUESTOS */
    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        //seccionesCargadas = true;
        this.puestos = this.puestos.concat(this.maquinas);

        r5 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6)
          this.cargarDatos();
      })
    } else {
      this.maquinas = maquinas_model;
      //seccionesCargadas = true;
      this.puestos = this.puestos.concat(this.maquinas);
      r5 = true;
    }
    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        this.puestos = this.puestos.concat(this.instalaciones);

        r6 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6)
          this.cargarDatos();
      })
    } else {
      this.instalaciones = instalaciones_model;
      this.puestos = this.puestos.concat(this.instalaciones);
      r6 = true
    }

    if (r1 && r2 && r3 && r4 && r5 && r6)
      this.cargarDatos();
  }

  cargarDatos() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.menuService.titulo = this.translate.instant('almacen');
    this.enableSwicthPosiciones = false;
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      id: [-1,],
      nombre: ['', Validators.required],
      descripcion: [''],
      tipoAlmacenSeleccionado: [{ id: 0 }],
      seccionesSeleccionadas: [{ id: 0 }],
      gruposSeleccionados: [{ id: 0 }],
      tiposPuestossSeleccionados: [{ id: 0 }],
      puestosSeleccionados: [{ id: 0 }],
      nombreArchivo: ['',],
      archivo: ['',],
      capacidad: ['',],
      archivoBase64: ['',],
      tienePosiciones: [false,]
    });

    

    this.idSelected = { id: 0, nombre: '' };

    if (!this.isAddMode) {

      this.almacenesService.GetById(this.id).pipe().subscribe(
        (result) => {

          if (result.data[0].imagenBase64 != "" && result.data[0].imagenBase64 != null) {
            this.imageToShow = result.data[0].imagenBase64;
          }

          if(result.data[0].idTipoAlmacen==1){
            this.enableSwicthPosiciones = true;
          }else{
            this.enableSwicthPosiciones = false;
          }

          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            id: [this.id,],
            nombre: [result.data[0].nombre, Validators.required],
            descripcion: [result.data[0].descripcion],
            capacidad: [result.data[0].capacidad],
            tipoAlmacenSeleccionado: [{ id: result.data[0].idTipoAlmacen }],
            seccionesSeleccionadas: [{ id: result.data[0].idSeccion }],
            tiposPuestossSeleccionados: [{ id: result.data[0].idTipoPuesto }],
            puestosSeleccionados: [{ id: result.data[0].idPuesto }],
            gruposSeleccionados: [{ id: 0 }],
            nombreArchivo: [result.data[0].imagen],
            archivo: ['',],
            archivoBase64: ['',],
            tienePosiciones: [result.data[0].tienePosiciones]
          });

          this.idSelected = { id: result.data[0].idTipoAlmacen };

          this.filtrarMaquinasPorAreaProductivaYGrupo();
        })
    } else {
      this.filtrarMaquinasPorAreaProductivaYGrupo();
    }
  }

  filtrarMaquinasPorAreaProductivaYGrupo() {
    this.listaPuestos = this.puestos.filter(f => {
      var enGrupo = false;
      if (this.form.value.gruposSeleccionados != undefined && this.form.value.gruposSeleccionados != null) {
        f.idsGrupos.split(',').forEach(
          idGrupo => {
            enGrupo = enGrupo || this.form.value.gruposSeleccionados.id == idGrupo;
          });
      }

      var enSeccion = false;
      if (this.form.value.seccionesSeleccionadas != undefined && this.form.value.seccionesSeleccionadas != null) {
        enSeccion = this.form.value.seccionesSeleccionadas.id == f.idSeccion;
      }

      var enTipo = false;
      if (this.form.value.tiposPuestossSeleccionados != undefined && this.form.value.tiposPuestossSeleccionados != null) {
        enTipo = this.form.value.tiposPuestossSeleccionados.id == f.tipo_maquina;
      }

      return (
        (enSeccion || this.form.value.seccionesSeleccionadas == undefined || this.form.value.seccionesSeleccionadas.id == 0) &&
        (enGrupo || this.form.value.gruposSeleccionados == undefined || this.form.value.gruposSeleccionados.id == 0) &&
        (enTipo || this.form.value.tiposPuestossSeleccionados == undefined || this.form.value.tiposPuestossSeleccionados.id == 0)
      );

    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private async insert() {
    this.almacenesService.insert(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.router.navigate(['almacenes']);
      }
    });
  }

  private async update() {
    this.almacenesService.update(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.router.navigate(['almacenes']);
      }
    });
  }

  public atras() {
    this.router.navigate(['almacenes']);
  }

  //AZABAL: IMAGEN NUEVO
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.form.controls['nombreArchivo'].setValue(e.files[0].name);

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
      th.imageToShow = archivoBase64;
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
    this.imageToShow = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  tipoAlmacenChanged(e){
    console.log(e);
     switch(e.id){
      case 1:{
        this.enableSwicthPosiciones = true;
        this.form.value.tienePosiciones = true;
        break;
      }
      default:{
        this.enableSwicthPosiciones = false;
        this.form.value.tienePosiciones = false;
        break;
      }
    } 
  }

}
