import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { MenuService, ContenedoresService, AlertService } from '@app/_services';

@Component({
    selector: 'app-contenedores',
    templateUrl: 'contenedores.component.html'
  })

export class ContenedoresComponent {

    public translate;

    public contenedores: any = [];
    public seleccionados = [];

    public isDeleting = false;

    constructor(
        translate: TranslateService,
        private alertService: AlertService,
        private menuService: MenuService,
        private contenedoresService: ContenedoresService,
        public router: Router) { 
            
        this.translate = translate;
        this.menuService.titulo = this.translate.instant('contenedores').toUpperCase();
    }

    ngOnInit() {
        this.cargarDatos();
    }

    cargarDatos() {
        this.contenedoresService.GetAll().subscribe(json => { 
            this.contenedores = json;
        });
    }

    onClickNuevo() {
        this.router.navigate(['contenedores/0']);
    }

    onClickEditar() {
        var id = this.seleccionados[0];
        this.router.navigate(['contenedores/' + id]);
    }

    onClickEliminar() {
        this.contenedoresService.DeleteContenedor(this.seleccionados).subscribe((json: any) => {
            if (json > 0) {
                this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                this.cargarDatos();
            } else {
                this.alertService.error(this.translate.instant('error'));
            }
        });
    }

    cellClick(event) {
        this.router.navigate(['contenedores/' + this.seleccionados[0]]);
    }

}