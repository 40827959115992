<!--   FILTRO-->
<!-- <div class="card w-20" [ngStyle]="{'visibility': status ? 'hidden' : 'visible'}"> -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">

  <div class="card-header">
    <h3>{{ 'filtro' | translate}}</h3>
    <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i
        class="fas fa-times"></i></button>
  </div>
  <div class="filtro-desplegable card-body flt-body">

    <div class="clearfix">
      <div style="width:200px">
        <kendo-label text="{{ 'semana' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-combobox [data]="Jsemanas" [textField]="'text'" [valueField]="'value'" [(value)]="JsemanaSelected" [clearButton]="false"></kendo-combobox>
        </div>
      </div>
      <!--los alumnos no pueden filtrar por grupo-->
      <div *ngIf="user.usuarioTipo!='Alumno'">
        <kendo-label text="{{ 'grupo' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-combobox style="width:200px" [data]="misGrupos" [(value)]="jGrupoFiltro" textField="nombre"
            valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}">
          </kendo-combobox>
        </div>
      </div>
      <div>
        <kendo-label text="{{'maquinasgrupos' | translate}}"></kendo-label>
        <div class="caja">
          <kendo-multiselect kendoMultiSelectSummaryTag style="width:200px" [data]="dtGruposMaquinas"
            [(value)]="jGruposMaquinasFiltro" [textField]="'nombre'" [valueField]="'id'">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate
                }}</ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
      <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()"> {{ 'filtrar' | translate
        }}</button>
      <!-- <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton'
                | translate }}</button> -->


    </div>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <div class="cont-tags-filtro" id="tagsFiltro">
      <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i
          class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }}</button>
      <!-- <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i
                    class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button> -->
    </div>

    <div class="float-left mr-2">
      <button kendoButton class=" btn btn-primary btn-sm mr-1" (click)="nuevoClick()">{{
        'nuevaReserva' |
        translate}}</button>
    </div>
  </div>

  <div *ngIf="loadingFiltro" class="k-i-loading"></div>
</div>
<!--END FILTRO-->

<!--GANNTS-->
<div *ngIf="loadingPanel" class="k-i-loading"></div>

<div *ngFor="let grupoM of gruposMaquinas">
  <div class="clearfix">
    <div class="card">
      <div class="card-body">
        <div class="row ml-2">
          <div class="float-left mr-2">
            <h5>{{grupoM.nombre}}</h5>
          </div>
        </div>

        <div class="clearfix">
          <div class="gantt-horariosSecciones-maquinas">
            <table>
              <tr>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}" style="min-height:125px;"></div>
                </td>
              </tr>
            </table>
          </div>
          <div class="gantt-horariosSecciones-dias" style="overflow-x:scroll;">
            <table>
              <tr>

                <td>
                  <div id="gantt-chart-{{grupoM.id}}-1" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:30px"></div>
                </td>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}-2" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:30px"></div>
                </td>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}-3" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:30px"></div>
                </td>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}-4" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:30px"></div>
                </td>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}-5" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:30px"></div>
                </td>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}-6" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:30px"></div>
                </td>
                <td>
                  <div id="gantt-chart-{{grupoM.id}}-7" style="min-height:125px; max-width: 500px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
              </tr>
            </table>
          </div>


        </div>

      </div>
    </div>
  </div>
</div>

<!--MENU LATERAL CREAR RESERVA-->
<div class="panel-menu" [ngClass]="{ 'desplegado': menuDesplegado }"
  [ngStyle]="{ 'width': menuDesplegado ? '20%' : '0px'}" #menuLateral>
  <div class="card-header">
    <h3 *ngIf="JsemanaSelected!=undefined && user.idioma!='eu'">{{'reserva' | translate}} {{JsemanaSelected.text}}
    </h3>
    <h3 *ngIf="JsemanaSelected!=undefined  && user.idioma=='eu'">{{'reserva' | translate}} {{JsemanaSelected.text}}
    </h3>

  </div>
  <div class="card-body">
    <div>
      <kendo-label text="{{'alumnos' | translate}}"></kendo-label>
      <div class="caja">
        <kendo-multiselect kendoMultiSelectSummaryTag style="width:100%" [data]="alumnos" [textField]="'nombre'" [valueField]="'id_usuario'" [(value)]="alumnosReserva"
          [kendoDropDownFilter]="{operator: 'contains'}"
          (valueChange)="selectedAlumnosReservaChange($event)">
          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
            <span class="k-icon k-i-arrow-s"></span>
            <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
            <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length}} {{ 'seleccionados' | translate }}</ng-container>
          </ng-template>
          
          <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
        </kendo-multiselect>

      </div>
    </div>
    <div *ngIf="alumnosReserva!=undefined && alumnosReserva.length!=0">
      <kendo-label text="{{'of' | translate}}"></kendo-label>
      <div class="caja">
        <kendo-dropdownlist style="width:100%" [data]="ofs" [textField]="'nombre'"
          [(value)]="OfReserva">
          <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
        </kendo-dropdownlist>
      </div>
    </div>

    <div *ngIf="OfReserva!=undefined && OfReserva.length!=0">
      <kendo-label text="{{'maquinasgrupo' | translate}}"></kendo-label>
      <div class="caja">
        <kendo-dropdownlist style="width:100%" [data]="gruposMaquinas" [textField]="'nombre'" [valueField]="'id'"
          [(value)]="grupoDeMaquinasReserva" (valueChange)="gruposMaquinasReservaSelectedChange()"></kendo-dropdownlist>
      </div>
    </div>
    <div *ngIf="grupoDeMaquinasReserva!=undefined">
      <kendo-label text="{{'maquina' | translate}}"></kendo-label>
      <div class="caja">
        <kendo-dropdownlist style="width:100%" [data]="grupoDeMaquinasReserva.maquinas" [textField]="'nombre'"
          [valueField]="'id'" [(value)]="maquinaReserva"></kendo-dropdownlist>
      </div>
    </div>
    <div>
      <div *ngIf="maquinaReserva!=undefined">
        <kendo-label text="{{'diasSemana' | translate}}"></kendo-label>
        <div class="caja">
          <kendo-dropdownlist
            style="width:100%" [data]="diasSemana" [textField]="'dia'" [valueField]="'fecha'" [(value)]="diaReserva"
            (valueChange)="diaSemanaReservaSelectedChange()"></kendo-dropdownlist>
        </div>
        <div class="row ml-1">

          <div class="float-left mr-2">
            <kendo-label text="{{'inicio' | translate}}"></kendo-label>
            <div class="caja">
              <kendo-timepicker [formatPlaceholder]="{ hour: '00', minute: '00'}" [(value)]="horaInicioReserva">
                <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
              </kendo-timepicker-messages>
              </kendo-timepicker>
            </div>
          </div>
          <div class="float-left">
            <kendo-label text="{{'fin' | translate}}"></kendo-label>
            <div class="caja">
              <kendo-timepicker [formatPlaceholder]="{ hour: '00', minute: '00'}" [(value)]="horaFinReserva">
                <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
              </kendo-timepicker-messages>
              </kendo-timepicker>
            </div>
          </div>
          <p class="m-0" *ngIf="errorHorasMal" style="color:red;">{{ 'horasMal' | translate}}</p>
          <p class="m-0" *ngIf="errorHorasFueraCentro" style="color:red;">{{ 'errorHorasFueraCentro' | translate}}</p>
          <p class="m-0" *ngIf="errorReservaNoValida" style="color:red;">{{ 'errorReservaNoValida' | translate}}</p>
          <p class="m-0" *ngIf="errorReservaExiste" style="color:red;">{{ 'errorReservaExiste' | translate}}</p>
        </div>
      </div>
    </div>

    <div class="mt-4" style="text-align: center;">
      <button kendoButton class="btn-primary ml-1" (click)="guardarClick()"
        [disabled]="maquinaReserva==undefined">{{'aceptar'|translate}}</button>
      <button kendoButton class="btn-danger ml-1" (click)="cancelarClick()">{{'cancelar'|translate}}</button>
    </div>

  </div>
