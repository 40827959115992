import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UsuariosService, AlertService, MenuService, MaquinasService, SubcontratadoService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { State, process } from '@progress/kendo-data-query';
import { Renderer2, NgZone, AfterViewInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RowClassArgs, CellClickEvent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-editar-crear-maquinasGrupoPlanificador',
  templateUrl: './editar-crear-maquinasGrupoPlanificador.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`.k-grid tr.dragging {
                background-color: #00b8bd;
            };`]
})


export class EditarCrearMaquinasGrupoPlanificadorComponent implements OnInit, AfterViewInit, OnDestroy {

  public state: State = {
    skip: 0,
    take: 100
  };
  public gridData: any;
  private currentSubscription: Subscription;

  public fromAllMaquinasSelect: boolean= false;
  public buttonAllMaquinasSelectName: string='';

  form: FormGroup;
  id: string;
  idNum: number;
  isAddMode: boolean;
  checkscargados: boolean;
  loading = false;
  submitted = false;
  user = this.usuariosService.userValue;
  dataGrupo: any;
  dataMaquinasGrupo: any[] = [];
  maquina: any;
  row: any;
  mySelection2: number[] = [];
  mySelection2Aux: number[] = [];

  public gridDataSubcontratado: any;
  dataSubcontratadosGrupo: any[] = [];
  mySelection2Subcontratado: number[] = [];
  subcontratado: any;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    private menuService: MenuService,
    private maquinasService: MaquinasService,
    private subcontratadoService: SubcontratadoService,
    private renderer: Renderer2,
    private zone: NgZone
  ) {
    // Al inicializar hay que 
    this.gridData = process([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}], this.state);
    
    if ((this.gridData.data.length<=this.mySelection2Aux.length) || this.mySelection2Aux.length==0)     this.buttonAllMaquinasSelectName=this.translateService.instant('seleccionarTodo');
    else    this.buttonAllMaquinasSelectName=this.translateService.instant('deseleccionarTodo');

    this.gridDataSubcontratado = process([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}], this.state);
  }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('maquinasgrupoPlanificador');
    this.id = this.route.snapshot.params['id'];
    this.idNum = + this.id;
    if (Number.isNaN(this.idNum)) this.idNum = 0;
    this.isAddMode = !this.id;
    this.checkscargados = true;

    this.form = this.formBuilder.group({
      idGrupo: this.route.snapshot.params['id'],
      idDb: this.user.idDb,
      nombre: new FormControl('', [Validators.required]),
      orden: new FormControl(0, [Validators.required]),
      activo: new FormControl(true),
      idMaquinas: '',
      allMaquinas: '',
      ordenMaquinas: '',
      idSubcontratados: ''
    });

    this.maquinasService.getGrupoMaquinasPlanificadorById(this.id).subscribe(json => {
      this.dataGrupo = json.data[0];
      this.form.patchValue(this.dataGrupo);
      this.dataMaquinasGrupo = json.data2;
      this.gridData = process(json.data2, this.state);
      this.mySelection2 = [];
      this.selectMaquinas();
    });

    //LA PRIMERA LLAMADA API TRAE LOS DATOS DEL GRUPO, esta no, solo trae los datos de las "maquinas" (en este caso subcontratas)
    this.subcontratadoService.getGrupoSubcontrtatadosPlanificadorById(this.idNum).subscribe(
      (json) => {
        var an: any = json;
        this.dataSubcontratadosGrupo = an;
        this.form.patchValue(this.dataSubcontratadosGrupo);
        this.gridDataSubcontratado = process(an, this.state);
        this.mySelection2Subcontratado = [];
        this.selectSubcontratados();
    });
  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  selectMaquinas() {
    var i = 1;
    this.mySelection2 = [];
    this.mySelection2Aux = [];
     for (this.maquina of this.dataMaquinasGrupo) {
         if (this.maquina.seleccionado == true){
           this.mySelection2.push(this.maquina.id);
         }
         i = i + 1;
     }
     this.mySelection2Aux = Object.assign([], this.mySelection2); //kopia
   }

  selectSubcontratados() {
    var i = 1;
    this.mySelection2 = [];
    for (this.subcontratado of this.dataSubcontratadosGrupo) {
      if (this.subcontratado.seleccionado == true) {
        this.mySelection2Subcontratado.push(this.subcontratado.id);
      }
      i = i + 1;
    }
  }
  //GUARDAR MAQUINAS
  guardarMaquinas() {
    var i = 0;
    for (this.maquina of this.dataMaquinasGrupo) {
      this.dataMaquinasGrupo[i].seleccionado = false;
      i = i + 1;
    }

    for (this.row of this.mySelection2) {
      i = 0
      for (this.maquina of this.dataMaquinasGrupo) {
        if (this.maquina.id == this.row) {
          this.dataMaquinasGrupo[i].seleccionado = true;
          break;
        }
        i = i + 1;
      }

    }
    var id = 0;
    for (this.maquina of this.dataMaquinasGrupo) {
      if (id == 0) {
        this.form.get('allMaquinas').setValue(this.maquina.id);
        this.form.get('ordenMaquinas').setValue(this.maquina.orden);
        if (this.maquina.seleccionado == true) {
          this.form.get('idMaquinas').setValue(this.maquina.id);
          id = 1;
        }
      } else {
        this.form.get('allMaquinas').setValue(this.form.get('allMaquinas').value+','+this.maquina.id);
        this.form.get('ordenMaquinas').setValue(this.form.get('ordenMaquinas').value+','+this.maquina.orden);
        if (this.maquina.seleccionado == true) {
          this.form.get('idMaquinas').setValue(this.form.get('idMaquinas').value + ',' + this.maquina.id);
        }
      }
    }
  }
  //GUARDAR SUBCONTRATADOS
  guardarSubcontratado() {
    var i = 0;
    for (this.subcontratado of this.dataSubcontratadosGrupo) {
      this.dataSubcontratadosGrupo[i].seleccionado = false;
      i = i + 1;
    }
    for (this.row of this.mySelection2Subcontratado) {
      i = 0
      for (this.subcontratado of this.dataSubcontratadosGrupo) {
        if (this.subcontratado.id == this.row) {
          this.dataSubcontratadosGrupo[i].seleccionado = true;
          break;
        }
        i = i + 1;
      }

    }
    var id = 0;
    for (this.subcontratado of this.dataSubcontratadosGrupo) {
      if (id == 0) {
        if (this.subcontratado.seleccionado == true) {
          this.form.get('idSubcontratados').setValue(this.subcontratado.id);
          id = 1;
        }
      } else {
        if (this.subcontratado.seleccionado == true) {
          this.form.get('idSubcontratados').setValue(this.form.get('idSubcontratados').value + ',' + this.subcontratado.id);
        }
      }
    }
  }


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.changeOrdenMaquinas();
    
      this.submitted = true;
      this.guardarMaquinas();
      this.guardarSubcontratado();
      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
        return;
      }
      this.loading = true;
      if (this.isAddMode) {
        this.crearGrupo();
      } else {
        this.updateGrupo();
      }
    
    if (this.fromAllMaquinasSelect)  this.fromAllMaquinasSelect = false;

  }


  private crearGrupo() {
    var listaIdMaquinas = [];
    this.gridData.data.forEach(function (registro) {
      if (registro.seleccionado == true) listaIdMaquinas.push(registro.id);
    }, this);
    this.form.controls["idMaquinas"].setValue(listaIdMaquinas.join(","));

    var listaidSubcontratados = [];
    this.gridDataSubcontratado.data.forEach(function (registro) {
      if (registro.seleccionado == true) listaidSubcontratados.push(registro.id);
    }, this);
    this.form.controls["idSubcontratados"].setValue(listaidSubcontratados.join(","));

    this.maquinasService.createGrupoMaquinasPlanificador(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });

        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateGrupo() {
    
    var listaIdMaquinas = [];
    this.gridData.data.forEach(function (registro) {
      if (registro.seleccionado == true) listaIdMaquinas.push(registro.id);
    }, this);
    this.form.controls["idMaquinas"].setValue(listaIdMaquinas.join(","));

    var listaidSubcontratados = [];
    
    this.gridDataSubcontratado.data.forEach(function (registro) {
      if (registro.seleccionado == true) listaidSubcontratados.push(registro.id);
    }, this);
    this.form.controls["idSubcontratados"].setValue(listaidSubcontratados.join(","));

    this.maquinasService.updateGrupoMaquinasPlanificador(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging
    };
  }

  public rowCallbackSubcontratado(context: RowClassArgs) {
    // esta funcion no es necesaria, pero para mantener los grids separados he creado otra igual
    return {
      dragging: context.dataItem.dragging
    };
  }


  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    
    
    tableRows.forEach(item => {
      
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }

  private checkAllMaquinas(e) 
  {
    e.preventDefault();
    if (this.gridData.data.length<=this.mySelection2Aux.length && this.gridData.data.length<=this.mySelection2.length)
    {
      this.mySelection2Aux=[];
      this.buttonAllMaquinasSelectName=this.translateService.instant('seleccionarTodo');
    }
    else
    {
      this.gridData.data.forEach(f => {
        //f.seleccionado = true;
        this.mySelection2Aux.push(f.id);
      });
      this.buttonAllMaquinasSelectName=this.translateService.instant('deseleccionarTodo');
    }
    this.fromAllMaquinasSelect=true;
    //this.mySelectionAux=this.mySelection;
    this.mySelection2 = Object.assign([], this.mySelection2Aux);
}

  private changeOrdenMaquinas() 
  {
    let order=1;
    this.gridData.data.forEach(elem => {
      elem.orden=order;
      order++;
    });
  }

  public cellClickHandler(e: CellClickEvent): void 
{
    if (this.gridData.data.length==this.mySelection2.length) this.gridData.data.forEach(f => {this.mySelection2Aux.push(f.id);});
      if (this.mySelection2.length==0) this.mySelection2Aux=[];

      if (this.mySelection2Aux.includes(e.dataItem.id)) 
      {
        //remove id from myselection
        const index = this.mySelection2Aux.indexOf(e.dataItem.id, 0);
        if (index > -1) {
          this.mySelection2Aux.splice(index, 1);
        }
        this.buttonAllMaquinasSelectName=this.translateService.instant('seleccionarTodo');
      }
      else 
      {
        this.mySelection2Aux.push(e.dataItem.id)
      }
      this.mySelection2 = Object.assign([], this.mySelection2Aux);
      
}
public showGridTooltip(e: MouseEvent): void {
  const element = e.target as HTMLElement;
  if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
    (element.offsetWidth < element.scrollWidth)
    && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
    //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
    this.tooltipDir.toggle(element);
  } else {
    this.tooltipDir.hide();
  }
}


}
