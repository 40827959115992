import { Component, OnInit } from '@angular/core';
import { MaquinasService, InstalacionesService, TurnosService, UsuariosService, HistoricoMaquinasDatosService, HistoricoMaquinasDatosTemporal2Service, InformeOeeService, CentroMecanizadoService, MenuService } from '@app/_services';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import * as d3 from 'd3';
import * as c3 from 'c3';
import { Usuario } from '@app/_models';
import { dateFieldName } from '@progress/kendo-angular-intl';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit {

    //#region Parametros de maquinas
    public datosMaquinas: any = [];
    public historicoDatosTemporal: any = [];

    public datosCargados: boolean = false;

    public fechaInicio;
    public fechaFin;
    public tipoTurno = new Map();

    public primeraVez = true;

    private timeoutzen;

    public historicoMaquinasDatosCargados = false;
    //#endregion

    //#region Parametros de instalaciones
    public instalaciones: any;
    public infInstalaciones: any;
    public iconosDatos: any;
    public infInstalacion: any;
    public instalacionesCargadas: boolean = false;

    // graficos
    public graficoDonutArray: any = [];
    public dataDonutArray: any = [];
    public graficoBarrasArray: any = [];
    public dataBarrasArray: any = [];
    public graficoLineasArray: any = [];
    public dataLineasArray: any = [];
    public graficoPresionArray: any = [];
    public dataPresionArray: any = [];
    public graficoTemperaturaArray: any = [];
    public dataTemperaturaArray: any = [];
    public graficoResumenSemanaArray: any = [];
    public dataResumenSemanaArray: any = [];

    public fechasG: any;
    public valoresG: any;
    public valoresGResumenSemana: any = [["ejecucion", 0],
    ["parada", 0],
    ["microparada", 0],
    ["preparacion", 0],
    ["mantenimiento", 0],
    ["alarma", 0],
    ["apagada", 0]];

    public showLabelResumenSemana: boolean = false;
    //#endregion

    public idGrupoActual = -1;
    public interval;

    public loading = false;

    //#region Home plantilla base (esto se hace para que mientras los datos se carguen se enseñe una base)
    public plantillaBase =     {
        idHistorico_Procesos: 0,
        idRelacionado: -1,
        idMaquina: 0,
        fechaini: new Date(),
        idSubEstados: null,
        idEstado: 1,
        tiempoEstado: 0,
        horaServidor: new Date(),
        now: new Date(),
        datosOperaciones: [
          {
            idHistorico_operaciones: 0,
            idOperacion: 0,
            idHistorico_operaciones_grupos: 0,
            fechaini: new Date(),
            porcentajeCapacidadMaquina: 0,
            operacion: true,
            nombreOperacion: " ",
            tiempoEstimadoEjecucion: 0,
            tiempoEstimadoPreparacion: 0,
            tiempoPredictivoEjecucion: 0,
            tiempoPredictivoPreparacion: 0,
            operacionTerminada: false,
            idRuta: 0,
            idOF: 0,
            numeroOF: "",
            idCliente: 0,
            cliente: " ",
            nombreSubCliente: "",
            referencia: "",
            idPieza: 0,
            nombrePieza: " ",
            referenciaPieza: "",
            numeroPlano: "",
            idParte: 0,
            nombreParte: "",
            referenciaParte: "",
            nombreRuta: "",
            usaLotes: false,
            cantidad: 0,
            cantidadReal: 1,
            hmoar_tiempoPreparacion: 0,
            hmoar_tiempoOperacion: 0,
            hmoar_tiempoTotalOperacion: 0,
            hmoar_tiempoNoComputable: 0,
            hmoar_tiempoDisponibilidad: 0,
            hmoar_tiempoRendimiento: 0,
            hmoar_tiempoCalidad: 0,
            hmoar_tiempoEstimadoPreparacion: 0,
            hmoar_tiempoEstimadoOperacion: 0,
            hmoar_tiempoPredictivoPreparacion: 0,
            hmoar_tiempoPredictivoOperacion: 0,
            hmoar_cantidadTerminada: 0,
            hmoar_cantidadApartada: 0,
            hmoar_cantidadAchatarrada: 0,
            hmoar_cantidad: 0,
            colada: "",
            lote: "",
            nserie: "",
            ho_cantidadAchatarrada: 0,
            ho_cantidadApartada: 0,
            ho_cantidadTerminada: 0,
            tieneGrupos: false,
            gruposOperaciones: [
              {
                tiempoEstimadoTotal: 0,
                temperaturaTotal: 0,
                terminado: 0,
                nombreGrupo: "",
                unidadesTotal: 0,
                pesoTotal: 0,
                peso: 0,
                unidades: 0,
                temperatura: 0,
                tiempoEstimado: 0,
                cantidad: 0,
                numeroOf: "",
                nombrePieza: "",
                nombreOperacion: " ",
                colorCantidad: "",
              },
            ],
            tiempoLotePrepTurno: 0,
            tiempoLoteTurno: 0,
            contadorTurno: 0,
            tiempoUltimaPieza: 0,
            tiempoLote: 0,
            tiempoLotePrep: 0,
            cantidadApartadaTurno: 0,
            cantidadAchatarradaTurno: 0,
          },
        ],
        datosOperarios: [
          {
            idOperario: 997,
            fechaini: new Date(),
            operario: "",
            operarioSiglas: "",
          },
        ],
        nombrePrograma: " ",
        faseOperacion: 0,
        contadorMaquina: 0,
        contadorEstimado: 0,
        idPerdida: -1,
        perdida: "",
        idSubPerdida: -1,
        subPerdida: "",
        idGrupoPerdida: -1,
        grupoPerdida: "",
        idTipoOEE_perdida: -1,
        maquinaMantenimiento: "",
        observacionMantenimiento: "",
        maquina: "",
        idMaquinaGrupo: 0,
        maquinaGrupo: "",
        idSeccion: 0,
        seccion: "",
        idAreaProductiva: 1,
        areaProductiva: "",
        idHomeSeleccionIzq: 1,
        idHomeSeleccionDer: 4,
        idEje1: -1,
        idEje2: -1,
        idEje3: -1,
        idEje4: -1,
        idVariable_Tipo1: -1,
        idVariable_Tipo2: -1,
        idVariable_Tipo3: -1,
        idVariable_Tipo4: -1,
        coste: 0,
        puestoOffline: true,
        tipo_maquina: 0,
        cantidadesTerminadasEnPreparacion: 0,
        capacidadPesoMaximo: 0,
        capacidadUnidadesMaximo: 0,
        subestado: "",
        color_subestado: "",
        machine_subestado: 0,
        estado: "Ejecución",
        color_estado: "#C0EADA",
        machine_estado: 1,
        piezasHora: 0,
        sinConexion: false,
        FDM_temperaturaHorno: 0,
        FDM_temperaturaExtrusor: 0,
        FDM_dewPoint: 0,
        FDM_airFlow: 0,
        FDM_VoltageUPS: true,
        FDM_ligthTower: 0,
        FDM_puertaAbiertaCerrada: false,
        FDM_posicionesEjes: false,
        FDM_platinaAspiradora: false,
        FDM_cartuchos: "",
        LPFB_nivelDePolvo: 0,
        LPFB_nivelDeO2: 0,
        LPFB_temperaturaCamara: 0,
        LPFB_presionCamara: 0,
        LPFB_potenciaRecirculacion: 0,
        LPFB_presionEntradaCamara: 0,
        LPFB_temperaturaElevador: 0,
        LPFB_temperaturaAguaHelada: 0,
        LPFB_horasFiltro: 0,
        LMD_potenciaDeLaser: 0,
        LMD_velocidadDelRobot: 0,
        LMD_flujoDeMaterial: 0,
        LMD_materialConsumido: 0,
        LMD_flujoDeArgon: 0,
        HP_nivelesDeAgentes: 0,
        HP_modoDeImpresion: 0,
        info_ejes: [
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
            ensennarGrafico: false,
          },
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
          },
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
          },
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
          },
        ],
        tNegativoCalidad: 0,
        piezasHoraEstimado: "0",
        piezasHoraRojo: true,
        eurosHoraEstimado: 0,
        eurosHora: 0,
        eurosHoraRojo: false,
        porcentajeEurosHora: 0,
        porcentajeEurosHoraRojo: true,
      }
    //#endregion
    
    isNaN: Function = Number.isNaN;

    constructor(private historicoMaquinasDatosService: HistoricoMaquinasDatosService,
        private historicoMaquinasDatosTemporalService: HistoricoMaquinasDatosTemporal2Service,
        private maquinasService: MaquinasService,
        public router: Router,
        public appComponent: AppComponent,
        private translateService: TranslateService,
        private instalacionesService: InstalacionesService,
        private turnosService: TurnosService,
        private centroMecanizadoService: CentroMecanizadoService,
        private usuariosService: UsuariosService,
        private menuService: MenuService,
        private informeOeeService: InformeOeeService,
        public myFunctions: MyFunctions) {

    }

    ngOnInit() {
        this.loading = true;
        console.log("HOME: ngOnInit" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))

        this.menuService.titulo = this.translateService.instant('home').toUpperCase();

        this.usuariosService.getConfiguracionVariables().subscribe(result => {

            if (result[0].ensennarHomeIsometrico && this.router.url != '/home') {
                this.router.navigate(["homeplantaisometrico"]);
            } else if (this.router.url == '/home' || this.router.url == '/') {

                this.cargarMaquinas();
                this.cargarInstalaciones();
                // this.actualizarTiempos();
                this.cargarTurnoActual();
        
                // esto es necesario para saber si se ha cambiado de grupo o no
                if (this.idGrupoActual != this.appComponent.selectedGrupo.id && this.instalacionesCargadas) {
                  this.crearGraficos();
                  this.idGrupoActual = this.appComponent.selectedGrupo.id;
                }
                this.interval = setInterval(() => {
                  if (this.idGrupoActual != this.appComponent.selectedGrupo.id && this.instalacionesCargadas) {
                    this.crearGraficos();
                    if (this.historicoDatosTemporal != undefined) this.prepararInformacion();
                    this.idGrupoActual = this.appComponent.selectedGrupo.id;
                  }
                  if (!(this.router.url == '/home' || this.router.url == '/')) {
                    clearInterval(this.interval);
                  }
                }, 1000);
            }
        });
    }

    timeDiff(tiempoEstado, now){
        return this.myFunctions.secondsTo_HH_MM_SS((new Date().getTime() - new Date(now).getTime()) / 1000 + tiempoEstado);
    }

    //#region Maquina con proceso -------------------------------------------------------------------------------------------------------
    cargarTurnoActual() {
        this.turnosService.Get_Turnos_Maquinas().subscribe(json => {
            var an: any = json;
            an.forEach(element => {
                this.tipoTurno.set(element.idMaquina, element);
            });
        });
    }

    cargarMaquinas() {
        //MAQUINAS
        var maquinas_model = this.maquinasService.get_maquinas_model();
        if (maquinas_model == false) {
            this.maquinasService.get().subscribe((json: any) => {
                console.log("HOME: get maquinas DB luzera: " + json.length)
                this.maquinasService.set_maquinas_model(json);
                this.datosMaquinas = this.maquinasService.get_maquinas_model();
                this.datosMaquinas.forEach(element => {
                    element.indexOperacion = 0;
                    element.home = this.plantillaBase;
                });
                console.log("HOME: ha cargado las maquinas" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                this.cargarHistoricoMaquinasDatos();
                this.cargarDatos();
            })
        } else {
            this.datosMaquinas = maquinas_model;
            console.log("HOME: get maquinas web luzera: " + this.datosMaquinas.length)
            this.datosMaquinas.forEach(element => {
                element.indexOperacion = 0;
                element.home = this.plantillaBase;
            });
            console.log("HOME: ha cargado las maquinas" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            this.cargarHistoricoMaquinasDatos();
            this.cargarDatos();
        }
    }

    cargarHistoricoMaquinasDatos() {

        if (this.router.url == '/home' || this.router.url == '/')
            setTimeout((d) => this.cargarHistoricoMaquinasDatos(), (5 * 60) * 1000);

        var fechas = this.cargarFechas()

        // this.fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(fechas.fechaInicio) + "T00:00:00Z";
        // this.fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(fechas.fechaFin) + "T00:00:00Z";
        this.fechaInicio = fechas.fechaInicio;
        this.fechaFin = fechas.fechaFin;
        var OEE_donut_calculado: any;
        if (!this.historicoMaquinasDatosService.util(this.fechaInicio, this.fechaFin)) {
            this.historicoMaquinasDatosService.cargar_historico_completo(this.fechaInicio, this.fechaFin).subscribe(
                j => {
                    this.historicoMaquinasDatosService.set_historico_datos(j, this.fechaInicio, this.fechaFin);
                    this.historicoMaquinasDatosCargados = true;
                    // this.cargarDatos();
                    this.prepararInformacion();
                });
        } else {
            this.historicoMaquinasDatosCargados = true;
            // this.cargarDatos();
            this.prepararInformacion();
        }
    }

    cargarDatos() {

        console.log("____________________________________________________________________")
        console.log(this.historicoMaquinasDatosTemporalService.timeoutzenHome);

        this.historicoMaquinasDatosTemporalService.actualizarTiempos();

        if (!this.historicoMaquinasDatosTemporalService.util()) {
            // if (!(this.historicoDatosTemporal == undefined)) {
            //     this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
            //     console.log("HOME: preparando los datos" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            //     this.prepararInformacion();
            // }
            console.log("HOME: haciendo consulta..." + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            this.historicoMaquinasDatosTemporalService.cargar_historico_temporal().subscribe(
                j => {
                    console.log("HOME: consulta realizada" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                    this.historicoMaquinasDatosTemporalService.set_historico_temporal(j);
                    console.log("HOME: set realizado" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                    // if (this.historicoDatosTemporal == undefined || this.primeraVez) {
                        this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
                        console.log("HOME: preparando los datos" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
                        this.prepararInformacion();
                        this.primeraVez = false;
                    // }
                });
        } else {
            this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
            console.log("HOME: preparando los datos" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
            this.prepararInformacion();
        }

        if ((this.router.url == '/home' || this.router.url == '/')  && this.historicoMaquinasDatosTemporalService.timeoutzenHome == undefined)
            this.historicoMaquinasDatosTemporalService.timeoutzenHome = setTimeout((d) => { this.historicoMaquinasDatosTemporalService.timeoutzenHome = undefined; this.cargarDatos();}, 25000);
    }

    prepararInformacion() {
        this.datosMaquinas.forEach(element => {
            element.primeraCarga = true;
            element.home = this.historicoDatosTemporal.filter(f => f.idMaquina == element.id)[0];
            if (element.home == undefined) {
                console.log("HOME: ez dago daturik kontsultan makina honetan: " + element.id);
                // element.home = this.plantillaBase;
            }

            this.procesarInformacionColumnasConfigurables(element.home?.idHomeSeleccionIzq, 1, element.id);
            this.procesarInformacionColumnasConfigurables(element.home?.idHomeSeleccionDer, 2, element.id);

        });
        console.log(this.datosMaquinas)
        this.datosCargados = true;

        
        if (this.historicoDatosTemporal != undefined && this.historicoDatosTemporal.length != 0) {
            this.loading = false;
            console.log("HOME: datos preparados. FIN" + this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date()))
        }
    }

    /*
        * int idHomeSeleccion: el id del modulo seleccionado
        * int numColumna: 1=>izq ; 2=>der
        * int idMaquina
    */
    procesarInformacionColumnasConfigurables(idHomeSeleccion, numColumna, idMaquina) {
        var info, fechaini, fechafin, turnos;

        //#region Establecer fechas
        if ([1, 4, 7].includes(idHomeSeleccion)) { // fecha semana 
            var fechas = this.cargarFechas();
            fechaini = fechas.fechaInicio;
            fechafin = fechas.fechaFin;
            turnos = [];
        } else if ([2, 5].includes(idHomeSeleccion)) { // fecha dia
            fechaini = this.myFunctions.getDateNow();
            fechafin = this.myFunctions.getDateNow();
            fechafin.setDate(fechafin.getDate() + 1);
            
            fechaini.setHours(0, 0, 0);
            fechafin.setHours(0, 0, 0);

            turnos = [];
        } else if ([3, 6].includes(idHomeSeleccion)) { // fecha turno
            var maquinaInf = this.tipoTurno.get(idMaquina);
            if (maquinaInf != undefined) {
                // fechaini = maquinaInf.min;
                // fechafin = maquinaInf.max;

                fechaini = this.myFunctions.getDateNow();
                fechafin = this.myFunctions.getDateNow();
                fechafin.setDate(fechafin.getDate() + 1);
                
                fechaini.setHours(0, 0, 0);
                fechafin.setHours(0, 0, 0);

                turnos = [maquinaInf.tipoTurno];
            }
        }
        //#endregion

        //#region Establecer datos
        if ([1, 2, 3].includes(idHomeSeleccion)) { // oee
            if (this.historicoMaquinasDatosCargados) info = this.historicoMaquinasDatosService.get_OEE_data_Donut_SIN_filtro([idMaquina], fechaini, fechafin, turnos);
            this.cargarGraficos(idMaquina, info, numColumna, 0);  
        } else if ([7].includes(idHomeSeleccion)) { // parametros
            if (this.historicoMaquinasDatosCargados) info = this.datosMaquinas.filter(f => f.id == idMaquina)[0]?.home.info_ejes;
            this.cargarGraficos(idMaquina, info, numColumna, 3) 
        } else if ([4, 5, 6].includes(idHomeSeleccion)) { // resumen
            if (this.historicoMaquinasDatosCargados) info = this.historicoMaquinasDatosService.get_resumen_estados_porcentaje([idMaquina], fechaini, fechafin, turnos);
            this.cargarGraficos(idMaquina, info, numColumna, 1);  
        }
        //#endregion
        

    }

    actualizarTiempos() {

        if (this.router.url == '/home' || this.router.url == '/')
            setTimeout((d) => this.actualizarTiempos(), 1000);

        if (this.datosCargados)
            this.datosMaquinas.forEach(element => {
                if (element.home != undefined) {
                    element.home.tiempoEstado += 1;
                    element.home.datosOperaciones.forEach(dato => {
                        dato.tiempoActualPieza += 1;
                        dato.tiempoActualLote += 1;
                        dato.tiempoActualTurno += 1;
                    });
                }
            });
    }

    /* TIPO_GRAFICO
        * OEE => 0
        * RESUMEN => 1
        * PARAMETROS => 3
    */
    cargarGraficos(idMaquina, info, col, tipo_grafico) {

        let elemento;
        //#region Cargar datos de OEE
        if (tipo_grafico == 0) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = false;
            if (info != undefined) {

                //#region Esto se utiliza para asegurar que el html este cargado y exista el elemento
                elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
                if (elemento != null) {
                    this.estiloGraficosOEE(info, col, idMaquina);
                    this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                } else {
                    setTimeout(() => {
                        this.estiloGraficosOEE(info, col, idMaquina);
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                    }, 1);
                }
                //#endregion
                
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = Math.round(info.OEE_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = Math.round(info.disponibilidad_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = Math.round(info.rendimiento_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = Math.round(info.calidad_porcentaje);

            }

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_label_" + col] = this.translateService.instant("oee");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_label_" + col] = this.translateService.instant("disp");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_label_" + col] = this.translateService.instant("rend");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_label_" + col] = this.translateService.instant("cal");
        }
        //#endregion

        //#region Cargar resumen semana
        if (tipo_grafico == 1) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajePreparacion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeEjecucion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeParada"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMantenimiento"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeAlarma"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeApagado"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMicroParada"] = 0;

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPreparacion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TEjecucion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TParada"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMantenimiento"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TAlarma"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TApagado"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMicroParada"] = 0;
            if (info != undefined) {
                if (info.columns.length > 0) {
                    if (info.columns.filter(f => f[0] == this.translateService.instant("preparacion")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajePreparacion"] = info.columns.filter(f => f[0] == this.translateService.instant("preparacion"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPreparacion"] = info.columns.filter(f => f[0] == this.translateService.instant("preparacion"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("ejecucion")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeEjecucion"] = info.columns.filter(f => f[0] == this.translateService.instant("ejecucion"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TEjecucion"] = info.columns.filter(f => f[0] == this.translateService.instant("ejecucion"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("parada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeParada"] = info.columns.filter(f => f[0] == this.translateService.instant("parada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TParada"] = info.columns.filter(f => f[0] == this.translateService.instant("parada"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMantenimiento"] = info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMantenimiento"] = info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("alarma")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeAlarma"] = info.columns.filter(f => f[0] == this.translateService.instant("alarma"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TAlarma"] = info.columns.filter(f => f[0] == this.translateService.instant("alarma"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("apagada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeApagado"] = info.columns.filter(f => f[0] == this.translateService.instant("apagada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TApagado"] = info.columns.filter(f => f[0] == this.translateService.instant("apagada"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("microparada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMicroParada"] = info.columns.filter(f => f[0] == this.translateService.instant("microparada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMicroParada"] = info.columns.filter(f => f[0] == this.translateService.instant("microparada"))[0][2];
                    }

                }

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
            }
        }
        //#endregion

        //#region Cargar parametros
        if (tipo_grafico == 3) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = 0;

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_ensennarGrafico_" + col] = true;
            if (info != undefined) {
                //#region VALORES
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_ensennarGrafico_" + col] = info[0]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_ensennarGrafico_" + col] = info[1]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_ensennarGrafico_" + col] = info[2]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_ensennarGrafico_" + col] = info[3]?.ensennarGrafico;

                if (info[0]?.ensennarGrafico) info.porcentaje1 = (info[0]?.valor - info[0]?.valor_min) * 100 / (info[0]?.valor_max - info[0]?.valor_min);
                else info.porcentaje1 = 100;
                if (info[1]?.ensennarGrafico) info.porcentaje2 = (info[1]?.valor - info[1]?.valor_min) * 100 / (info[1]?.valor_max - info[1]?.valor_min);
                else info.porcentaje2 = 100;
                if (info[2]?.ensennarGrafico) info.porcentaje3 = (info[2]?.valor - info[2]?.valor_min) * 100 / (info[2]?.valor_max - info[2]?.valor_min);
                else info.porcentaje3 = 100;
                if (info[3]?.ensennarGrafico) info.porcentaje4 = (info[3]?.valor - info[3]?.valor_min) * 100 / (info[3]?.valor_max - info[3]?.valor_min);
                else info.porcentaje4 = 100;

                //#region Esto se utiliza para asegurar que el html este cargado y exista el elemento
                elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
                if (elemento != null) {
                    this.estiloGraficosParametros(info, col, idMaquina);
                    this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                } else {
                    setTimeout(() => {
                        this.estiloGraficosParametros(info, col, idMaquina);
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                    }, 1);
                }
                //#endregion

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = Math.round(info[0].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = Math.round(info[1].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = Math.round(info[2].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = Math.round(info[3].valor);
                //#endregion
                //#region LABEL
                var label1 = info[0]?.nombreEje;
                var label2 = info[1]?.nombreEje;
                var label3 = info[2]?.nombreEje;
                var label4 = info[3]?.nombreEje;
                if (info[0]?.idEje == 0) label1 = this.translateService.instant(info[0]?.variableTipo_nombre);
                if (info[1]?.idEje == 0) label2 = this.translateService.instant(info[1]?.variableTipo_nombre);
                if (info[2]?.idEje == 0) label3 = this.translateService.instant(info[2]?.variableTipo_nombre);
                if (info[3]?.idEje == 0) label4 = this.translateService.instant(info[3]?.variableTipo_nombre);

                if (info[0]?.idEje == undefined) {
                    if (this.translateService.instant(info[0]?.nombreEje).split(" ") > 1)
                        label1 = this.translateService.instant(info[0]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[0]?.nombreEje) != undefined)
                        label1 = this.translateService.instant(info[0]?.nombreEje).split(" ")[0][0];
                } 
                if (info[1]?.idEje == undefined) {
                    if (this.translateService.instant(info[1]?.nombreEje).split(" ") > 1)
                        label2 = this.translateService.instant(info[1]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[1]?.nombreEje) != undefined)
                        label2 = this.translateService.instant(info[1]?.nombreEje).split(" ")[0][0];
                } 
                if (info[2]?.idEje == undefined) {
                    if (this.translateService.instant(info[2]?.nombreEje).split(" ") > 1)
                        label3 = this.translateService.instant(info[2]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[2]?.nombreEje) != undefined)
                        label3 = this.translateService.instant(info[2]?.nombreEje).split(" ")[0][0];
                } 
                if (info[3]?.idEje == undefined) {
                    if (this.translateService.instant(info[3]?.nombreEje).split(" ") > 1)
                        label4 = this.translateService.instant(info[3]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[3]?.nombreEje) != undefined)
                        label4 = this.translateService.instant(info[3]?.nombreEje).split(" ")[0][0];
                } 
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_label_" + col] = label1;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_label_" + col] = label2;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_label_" + col] = label3;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_label_" + col] = label4;
                //#endregion
                
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
            }
        }
        //#endregion


    }

    estiloGraficosOEE(info, col, idMaquina) {
        let elemento;
        //#region SEKZIO ARGIA
        elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.OEE_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.disponibilidad_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.rendimiento_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.calidad_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        //#endregion

        //#region SEKZIO ILUNA
        elemento = document.getElementById("iluna-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.OEE_porcentaje, 95, 100, 1, true) + "%, #888888 0)";

        elemento = document.getElementById("iluna-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.disponibilidad_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.rendimiento_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.calidad_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        //#endregion

    }

    estiloGraficosParametros(info, col, idMaquina) {
        let elemento;
        //#region SEKZIO ARGIA
        elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje1, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje2, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje3, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje4, 95, 100, 1, true) + "%, #DCE2EA 0)";
        //#endregion

        //#region SEKZIO ILUNA
        elemento = document.getElementById("iluna-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje1, 95, 100, 1, true) + "%, #888888 0)";

        elemento = document.getElementById("iluna-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje2, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje3, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje4, 95, 100, 1, true) + "%, #888888 0)";
        //#endregion

    }

    private cargarFechas() {
        var fechaIni, fechaInicio, fechaFin;

        //calcular fecha inicio
        fechaInicio = this.myFunctions.getDateNow();
        fechaIni = fechaInicio
        if (fechaIni.getDay() == 3) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
        } else if (fechaIni.getDay() == 4) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
        } else if (fechaIni.getDay() == 5) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
        } else if (fechaIni.getDay() == 6) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
        } else if (fechaIni.getDay() == 0) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
        } else if (fechaIni.getDay() == 2) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
        }

        //calcular fecha fin
        fechaIni = new Date(fechaInicio);
        fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 7));

        //calcular horas
        fechaInicio.setHours(0, 0, 0);
        fechaFin.setHours(0, 0, 0);

        return { fechaInicio: fechaInicio, fechaFin: fechaFin }
    }
    //#endregion ------------------------------------------------------------------------------------------------------------------------

    //#region Instalaciones -------------------------------------------------------------------------------------------------------------
    cargarInstalaciones() {
        this.maquinasService.GetInstalaciones().subscribe(json => {
            this.maquinasService.set_instalaciones_model(json);
            this.instalaciones = this.maquinasService.get_instalaciones_model();
      
            // informacion de los modulos
            this.instalacionesService.get_instalaciones_modulos().subscribe(
              result => {
      
                var dataModulos: any = result;
                this.instalaciones.forEach(element => {
                  var m1 = dataModulos.filter(f => f.id == element.m1);
                  var m2 = dataModulos.filter(f => f.id == element.m2);
      
                  if (m1.length > 0) {
                    element['m1Inf'] = { nombre: m1[0].nombre, id_tipoGrafico_DAT: m1[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m1[0].id_tipoDato_DAT, idCampo: m1[0].idCampo };
                  } else {
                    element['m1Inf'] = { nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1 };
                  }
      
                  if (m2.length > 0) {
                    element['m2Inf'] = { nombre: m2[0].nombre, id_tipoGrafico_DAT: m2[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m2[0].id_tipoDato_DAT, idCampo: m2[0].idCampo };
                  } else {
                    element['m2Inf'] = { nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1 };
                  }
      
                });
                this.cargarInstalacionesInformacion();
            });
        })
    }

    cargarInstalacionesInformacion() {
        this.instalaciones = this.instalaciones.sort((a, b) => { if (a.estructura > b.estructura) return -1 })

        var r1 = false, r2 = false;
        this.instalacionesService.get_iconos_graficoDatos().subscribe(
            result => {
                this.iconosDatos = result;
                r1 = true;
                this.instalacionesService.get_instalaciones_datos(-1).subscribe(
                    result => {
                        this.instalacionesCargadas = true;
                        this.infInstalaciones = result;
                        this.crearGraficos();
                    });

                setTimeout((d) => this.refreshDataIns(), 5000); // Despues de cargar los datos por primera vez, llamamos a refresh data (que tiene otro setTimeout()) para que se empiezen a actualizar.
            });
    }

    refreshDataIns() {
        if (this.router.url == '/home' || this.router.url == '/') {
            if (this.instalacionesCargadas) {
                this.refreshDataInstalaciones();
            }
        }
        if (this.router.url == '/home' || this.router.url == '/') {
            setTimeout((d) => this.refreshDataIns(), 60000);
        }
    }

    refreshDataInstalaciones() {
        this.instalacionesService.get_instalaciones_datos(-1).subscribe(
            result => {
                this.infInstalaciones = result;
                this.actualizarGraficos();
            });
    }

    crearGraficos() {

        var j = 0;
        this.instalaciones.forEach(element => {
            this.infInstalacion = this.infInstalaciones.filter(f => f.id == element.id);

            if (element.m1Inf?.nombre != '' && element.m1Inf?.id_tipoGrafico_DAT != -1 && element.m1Inf?.id_tipoDato_DAT != -1) {
                switch (element.m1Inf.id_tipoGrafico_DAT) {
                    case 1: // Barras
                        this.dibujarBarras(j, 1);
                        break;
                    case 2: // Circular
                        break;
                    case 3: // Nivel
                        this.infTemperaturaPosicionNivel(j, 1);
                        break;
                    case 4: // Lineas
                        this.dibujarLineas(j, 1);
                        break;
                    case 5: // Presion
                        this.dibujarPresion(j, 1);
                        break;
                    case 6: // Temperatura
                        this.infTemperaturaPosicionNivel(j, 1);
                        break;
                    case 7: // Posicion
                        this.infTemperaturaPosicionNivel(j, 1);
                        break;
                    case 8: // Dato
                        this.graficoDato(j, 1);
                        break;
                    case 9: // Resumen semana
                        this.dibujarResumenSemana(j, 1);
                        break;
                    case 10: // Resumen KWh por dia
                        this.dibujarBarras(j, 1, 1);
                        break;
                    case 11: // Resumen KWh por semana
                        this.dibujarBarras(j, 1, 2);
                        break;
                    case 12: // Resumen KWh por mes
                        this.dibujarBarras(j, 1, 3);
                        break;
                    case 13: // Dato KWh por dia
                        this.graficoDatoPorResumen(j, 1, 1);
                        break;
                    case 14: // Dato KWh por semana
                        this.graficoDatoPorResumen(j, 1, 2);
                        break;
                    case 15: // Dato KWh por mes
                        this.graficoDatoPorResumen(j, 1, 3);
                        break;
                    default:
                        break;
                }
            }

            if (element.m2Inf?.nombre != '' && element.m2Inf?.id_tipoGrafico_DAT != -1 && element.m2Inf?.id_tipoDato_DAT != -1) {
                switch (element.m2Inf.id_tipoGrafico_DAT) {
                    case 1: // Barras
                        this.dibujarBarras(j, 2);
                        break;
                    case 2: // Circular
                        break;
                    case 3: // Nivel
                        this.infTemperaturaPosicionNivel(j, 2);
                        break;
                    case 4: // Lineas
                        this.dibujarLineas(j, 2);
                        break;
                    case 5: // Presion
                        this.dibujarPresion(j, 2);
                        break;
                    case 6: // Temperatura
                        this.infTemperaturaPosicionNivel(j, 2);
                        break;
                    case 7: // Posicion
                        this.infTemperaturaPosicionNivel(j, 2);
                        break;
                    case 8: // Dato
                        this.graficoDato(j, 2);
                        break;
                    case 9: // Resumen semana
                        this.dibujarResumenSemana(j, 2);
                        break;
                    case 10: // Resumen KWh por dia
                        this.dibujarBarras(j, 2, 1);
                        break;
                    case 11: // Resumen KWh por semana
                        this.dibujarBarras(j, 2, 2);
                        break;
                    case 12: // Resumen KWh por mes
                        this.dibujarBarras(j, 2, 3);
                        break;
                    case 13: // Dato KWh por dia
                        this.graficoDatoPorResumen(j, 2, 1);
                        break;
                    case 14: // Dato KWh por semana
                        this.graficoDatoPorResumen(j, 2, 2);
                        break;
                    case 15: // Dato KWh por mes
                        this.graficoDatoPorResumen(j, 2, 3);
                        break;
                    default:
                        break;
                }
            }

            j++;
        });

    }

    actualizarGraficos() {
        // columnas
        var j = 0;

        var barrasCantidad = 0;
        var lineasCantidad = 0;
        var presionCantidad = 0;
        var resumenSemanaCantidad = 0;

        this.instalaciones.forEach(element => {
            this.infInstalacion = this.infInstalaciones.filter(f => f.id == element.id);
            if (element.m1Inf.nombre != '' && element.m1Inf.id_tipoGrafico_DAT != -1 && element.m1Inf.id_tipoDato_DAT != -1) {
                switch (element.m1Inf.id_tipoGrafico_DAT) {
                    case 1: // Barras
                        this.cargarDatosParaGraficosBarras(j, 'm1Inf', barrasCantidad);
                        // this.cargarDatosBarras(barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 2: // Circular
                        break;
                    case 3: // Nivel
                        this.infTemperaturaPosicionNivel(j, 1);
                        break;
                    case 4: // Lineas
                        var xValue = this.cargarDatosParaGraficosLineas(j, 'm1Inf');
                        if (xValue != -1) this.cargarDatosLineas(xValue, lineasCantidad);
                        lineasCantidad++;
                        break;
                    case 5: // Presion
                        this.cargarDatosParaGraficoPresion(j, 'm1Inf');
                        this.cargarDatosPresion(presionCantidad);
                        presionCantidad++;
                        break;
                    case 6: // Temperatura
                        this.infTemperaturaPosicionNivel(j, 1);
                        break;
                    case 7: // Posicion
                        this.infTemperaturaPosicionNivel(j, 1);
                        break;
                    case 8: // Dato
                        this.graficoDato(j, 1);
                        break;
                    case 9: // Resumen semana
                        this.cargarDatosParaGraficoResumenSemana(j, 'm1Inf');
                        this.cargarDatosResumenSemana(resumenSemanaCantidad);
                        resumenSemanaCantidad++;
                        break;
                    case 10: // Resumen KWh por dia
                        this.cargarDatosParaGraficosBarrasPorDia(j, 'm1Inf', barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 11: // Resumen KWh por semana
                        this.cargarDatosParaGraficosBarrasPorSemana(j, 'm1Inf', barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 12: // Resumen KWh por mes
                        this.cargarDatosParaGraficosBarrasPorMes(j, 'm1Inf', barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 13: // Dato KWh por dia
                        this.graficoDatoPorResumen(j, 1, 1);
                        break;
                    case 14: // Dato KWh por semana
                        this.graficoDatoPorResumen(j, 1, 2);
                        break;
                    case 15: // Dato KWh por mes
                        this.graficoDatoPorResumen(j, 1, 3);
                        break;

                    default:
                        break;
                }
            }

            if (element.m2Inf.nombre != '' && element.m2Inf.id_tipoGrafico_DAT != -1 && element.m2Inf.id_tipoDato_DAT != -1) {
                switch (element.m2Inf.id_tipoGrafico_DAT) {
                    case 1: // Barras
                        this.cargarDatosParaGraficosBarras(j, 'm2Inf', barrasCantidad);
                        // this.cargarDatosBarras(barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 2: // Circular
                        break;
                    case 3: // Nivel
                        this.infTemperaturaPosicionNivel(j, 2);
                        break;
                    case 4: // Lineas
                        var xValue = this.cargarDatosParaGraficosLineas(j, 'm2Inf');
                        if (xValue != -1) this.cargarDatosLineas(xValue, lineasCantidad);
                        lineasCantidad++;
                        break;
                    case 5: // Presion
                        this.cargarDatosParaGraficoPresion(j, 'm2Inf');
                        this.cargarDatosPresion(presionCantidad);
                        presionCantidad++;
                        break;
                    case 6: // Temperatura
                        this.infTemperaturaPosicionNivel(j, 2);
                        break;
                    case 7: // Posicion
                        this.infTemperaturaPosicionNivel(j, 2);
                        break;
                    case 8: // Dato
                        this.graficoDato(j, 2);
                        break;
                    case 9: // Resumen semana
                        this.cargarDatosParaGraficoResumenSemana(j, 'm2Inf');
                        this.cargarDatosResumenSemana(resumenSemanaCantidad);
                        resumenSemanaCantidad++;
                        break;
                    case 10: // Resumen KWh por dia
                        this.cargarDatosParaGraficosBarrasPorDia(j, 'm2Inf', barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 11: // Resumen KWh por semana
                        this.cargarDatosParaGraficosBarrasPorSemana(j, 'm2Inf', barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 12: // Resumen KWh por mes
                        this.cargarDatosParaGraficosBarrasPorMes(j, 'm2Inf', barrasCantidad);
                        barrasCantidad++;
                        break;
                    case 13: // Dato KWh por dia
                        this.graficoDatoPorResumen(j, 2, 1);
                        break;
                    case 14: // Dato KWh por semana
                        this.graficoDatoPorResumen(j, 2, 2);
                        break;
                    case 15: // Dato KWh por mes
                        this.graficoDatoPorResumen(j, 2, 3);
                        break;

                    default:
                        break;
                }
            }

            j++;
        });
    }

    //#region Barras
    /* 
    * tipoInformacion:
    *   -1 => normal
    *   1 => resumen KWh por dia
    *   2 => resumen KWh por semana
    *   3 => resumen KWh por mes
    */
    dibujarBarras(index, m, tipoInformacion = -1) {
        var that = this;
        if (tipoInformacion != -1)
            this.graficoBarrasArray.push(c3.generate({
                bindto: '#graficoBarras_' + m + "_" + index,
                data: {
                    x: 'x',
                    columns: [['x']],
                    type: 'bar'
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            centered: true,
                            // count: 10,
                            format: function (d) {
                                if (tipoInformacion == -1)
                                    return that.myFunctions.addZero(d.getHours()) + ':' + that.myFunctions.addZero(d.getMinutes());
                                else if (tipoInformacion == 2) {
                                    var weekNumber = that.translateService.instant('semana') + ' ' + that.myFunctions.getNumberOfWeek(d) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(d)) + ')';
                                    return weekNumber
                                } else if (tipoInformacion == 3) {
                                    return that.myFunctions.dateTo_Mes_YYYY(d);
                                } else if (tipoInformacion == 1) {
                                    var mes = d.getMonth() + 1;
                                    return d.getFullYear() + "/" + that.myFunctions.addZero(mes) + "/" + d.getDate()
                                }
                            }
                        }
                    },
                    y: {
                        tick: {
                            count: 10,
                            format: function (d) {
                                return that.myFunctions.numero_con_formato(d, 1)
                            }
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                        var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
                        return auxiString1;
                    }
                }
            }));
        else
            this.graficoBarrasArray.push(c3.generate({
                bindto: '#graficoBarras_' + m + "_" + index,
                data: {
                    x: 'x',
                    columns: [['x']],
                    type: 'bar'
                },
                axis: {
                    x: {
                        type: 'timeseries',
                        tick: {
                            centered: true,
                            count: 10,
                            format: function (d) {
                                return that.myFunctions.addZero(d.getHours()) + ':' + that.myFunctions.addZero(d.getMinutes());
                            }
                        }
                    },
                    y: {
                        tick: {
                            count: 10,
                            format: function (d) {
                                return that.myFunctions.numero_con_formato(d, 1)
                            }
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                        var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
                        return auxiString1;
                    }
                }
            }));

        var lag = 'm' + m + 'Inf';
        if (tipoInformacion == -1) {
            this.cargarDatosParaGraficosBarras(index, lag, this.graficoBarrasArray.length - 1);
            // this.cargarDatosBarras(this.graficoBarrasArray.length - 1);
        }
        else if (tipoInformacion == 1)
            this.cargarDatosParaGraficosBarrasPorDia(index, lag, this.graficoBarrasArray.length - 1);
        else if (tipoInformacion == 2)
            this.cargarDatosParaGraficosBarrasPorSemana(index, lag, this.graficoBarrasArray.length - 1);
        else if (tipoInformacion == 3)
            this.cargarDatosParaGraficosBarrasPorMes(index, lag, this.graficoBarrasArray.length - 1);
    }

    cargarDatosParaGraficosBarras(col, m, index) {
        var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][m].idCampo))[0];

        if (inf != undefined) {
            // procesar los datos (si el valor es -1 entonces hay que eliminarlo)
            var valoresHist = inf?.valoresHistoricos.split(",").reverse();
            var fechasHist = inf?.fechas.split(",").reverse();
            var i = 0;
            var fechasToRemove = [];
            var datos = [];
            valoresHist.forEach(element => {
                if (element == "-1.000000")
                    fechasToRemove.push(fechasHist[i]);
                else
                    datos.push({ valor: element, fecha: fechasHist[i] });
                i++
            });
            // valoresHist = valoresHist.filter(f => f != "-1.000000");
            // fechasHist = fechasHist.filter(f => !fechasToRemove.includes(f));

            datos = datos.sort((a, b) => (b.fecha > a.fecha) ? 1 : ((a.fecha > b.fecha) ? -1 : 0))
            valoresHist = [];
            fechasHist = [];
            datos.forEach(element => {
                valoresHist.push(element.valor);
                fechasHist.push(element.fecha);
            })

            // fechas
            var fechas = fechasHist;
            var fechasGrafico: any = ['x'];
            fechas.forEach(element => {
                if (element != '')
                    fechasGrafico.push(new Date(element));
                else
                    fechasGrafico.push(new Date())
            });

            // valores
            var valoresGrafico = [this.instalaciones[col][m].nombre]
            var valores = valoresHist;
            valoresGrafico.push(...valores);
            this.fechasG = fechasGrafico.slice(0, 10);
            this.valoresG = valoresGrafico.slice(0, 10);

            this.cargarDatosBarras(index);
        }

    }

    cargarDatosParaGraficosBarrasPorDia(col, m, index) {
        var inf = this.infInstalacion[0];
        this.instalacionesService.get_consumo_agrupado(inf.id, 1).subscribe(
            (result: any) => {
                var valoresHist = [];
                var fechasHist: any = [];
                result.forEach(element => {
                    valoresHist.push(element.valor);
                    fechasHist.push(new Date(element.dia));
                });

                // valores
                var valoresGrafico = ['KWh']
                var valores = valoresHist.reverse();
                valoresGrafico.push(...valores);
                var fechasGrafico = ['x']
                fechasHist = fechasHist.reverse();
                fechasGrafico.push(...fechasHist);
                this.fechasG = fechasGrafico.slice(0, 5);
                this.valoresG = valoresGrafico.slice(0, 5);
                this.cargarDatosBarras(index);
            });
    }

    cargarDatosParaGraficosBarrasPorSemana(col, m, index) {
        var inf = this.infInstalacion[0];
        this.instalacionesService.get_consumo_agrupado(inf.id, 2).subscribe(
            (result: any) => {
                var valoresHist = [];
                var fechasHist: any = [];
                result.forEach(element => {
                    var anno = element.anno.split(';')[0];
                    valoresHist.push(element.valor);
                    fechasHist.push(this.myFunctions.getDatefromWeek(element.semana, anno));
                });

                // valores
                var valoresGrafico = ['KWh']
                var valores = valoresHist.reverse();
                valoresGrafico.push(...valores);
                var fechasGrafico = ['x']
                fechasHist = fechasHist.reverse();
                fechasGrafico.push(...fechasHist);
                this.fechasG = fechasGrafico.slice(0, 5);
                this.valoresG = valoresGrafico.slice(0, 5);
                this.cargarDatosBarras(index);
            });
    }

    cargarDatosParaGraficosBarrasPorMes(col, m, index) {
        var inf = this.infInstalacion[0];
        this.instalacionesService.get_consumo_agrupado(inf.id, 3).subscribe(
            (result: any) => {
                var valoresHist = [];
                var fechasHist: any = [];
                result.forEach(element => {
                    var anno = element.anno.split(';')[0];
                    valoresHist.push(element.valor);
                    fechasHist.push(new Date(anno, element.mes - 1, 1));
                });

                // valores
                var valoresGrafico = ['KWh']
                var valores = valoresHist.reverse();
                valoresGrafico.push(...valores);
                var fechasGrafico = ['x']
                fechasHist = fechasHist.reverse();
                fechasGrafico.push(...fechasHist);
                this.fechasG = fechasGrafico.slice(0, 5);
                this.valoresG = valoresGrafico.slice(0, 5);
                this.cargarDatosBarras(index);
            });
    }

    cargarDatosBarras(i) {
        this.graficoBarrasArray[i].load({
            unload: true,
            x: 'x',
            columns: [this.fechasG, this.valoresG]
        });
    }
    //#endregion

    //#region Lineas
    dibujarLineas(index, m) {
        var that = this;
        this.graficoLineasArray.push(c3.generate({
            bindto: '#graficoLineas_' + m + "_" + index,
            data: {
                x: 'x',
                columns: [['x']],
                // type: 'bar'
            },
            color: {
                pattern: ['#1FB5B5', '#00F6FF']
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        centered: true,
                        count: 10,
                        format: function (d) {
                            if (d == undefined)
                                return;
                            return that.myFunctions.addZero(d?.getHours()) + ':' + that.myFunctions.addZero(d?.getMinutes());
                        }
                    }
                },
                y: {
                    tick: {
                        count: 10,
                        format: function (d) {
                            return that.myFunctions.numero_con_formato(d, 1)
                        }
                    }
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                    var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
                    return auxiString1;
                }
            }
        }
        ));

        var lag = 'm' + m + 'Inf';
        var xValue = this.cargarDatosParaGraficosLineas(index, lag);
        if (xValue != -1) this.cargarDatosLineas(xValue, this.graficoLineasArray.length - 1);
    }

    cargarDatosParaGraficosLineas(col, m) {
        var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][m].idCampo))[0];

        if (inf != undefined) {
            // procesar los datos (si el valor es -1 entonces hay que eliminarlo)
            var valoresHist = inf?.valoresHistoricos.split(",").reverse();
            var fechasHist = inf?.fechas.split(",").reverse();
            var i = 0;
            var fechasToRemove = [];
            var datos = [];
            valoresHist.forEach(element => {
                if (element == "-1.000000")
                    fechasToRemove.push(fechasHist[i]);
                else
                    datos.push({ valor: element, fecha: fechasHist[i] });
                i++
            });
            // valoresHist = valoresHist.filter(f => f != "-1.000000");
            // fechasHist = fechasHist.filter(f => !fechasToRemove.includes(f));

            datos = datos.sort((a, b) => (b.fecha > a.fecha) ? 1 : ((a.fecha > b.fecha) ? -1 : 0))
            valoresHist = [];
            fechasHist = [];
            datos.forEach(element => {
                valoresHist.push(element.valor);
                fechasHist.push(element.fecha);
            })

            // fechas
            var fechas = fechasHist;
            var fechasGrafico: any = ['x'];
            fechas.forEach(element => {
                if (element != '')
                    fechasGrafico.push(new Date(element));
                else
                    fechasGrafico.push(new Date())
            });

            // valores
            var valoresGrafico = [this.instalaciones[col][m].nombre]

            var xValue = {};
            xValue[this.instalaciones[col][m].nombre] = 'x';
            xValue[this.translateService.instant('media')] = 'x'

            var valores = valoresHist;
            valoresGrafico.push(...valores);

            var mCalc = 0;
            valores.forEach(element => {
                mCalc += parseInt(element);
            });
            mCalc = mCalc / valores.length;
            var media: any = [this.translateService.instant('media')];
            for (var j = 1; j < valoresGrafico.length; j++) {
                media.push(mCalc);
            }

            // this.fechasG = fechasGrafico;
            this.valoresG = [fechasGrafico.slice(0, 10), valoresGrafico.slice(0, 10), media];

            return xValue;
        }
        return -1
    }

    cargarDatosLineas(xValue, i) {
        this.graficoLineasArray[i].load({
            unload: true,
            xs: xValue,
            columns: [this.valoresG[0], this.valoresG[1]]
        });

        this.graficoLineasArray[i].ygrids.remove();
        this.graficoLineasArray[i].ygrids.add([
            { value: this.valoresG[2][1], class: "graficoLineas-media" }
        ])
    }
    //#endregion

    //#region Presion
    dibujarPresion(index, m) {

        this.graficoPresionArray.push(c3.generate({
            bindto: '#graficoPresion_' + m + "_" + index,
            data: {
                columns: [['x']],
                type: "donut",
                order: null,
                hide: [],
                colors: {
                },
            },
            legend: {
                show: false
            },
            tooltip: {
                show: false
            },
            donut: {
                label: {
                    show: false
                },
                width: 30
            }

        }));

        var lag = 'm' + m + 'Inf';
        this.cargarDatosParaGraficoPresion(index, lag);
        this.cargarDatosPresion(this.graficoPresionArray.length - 1);
    }

    cargarDatosPresion(i) {
        this.graficoPresionArray[i].load({
            unload: true,
            columns: this.valoresG,
            order: null,
            hide: ['hide'],
            colors: {
                hide: '#transparent',
                data: '#22C4C4',
                rest: '#E1E1E1'
            },
        });
    }

    cargarDatosParaGraficoPresion(col, m) {
        var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][m].idCampo))[0];

        if (inf != undefined) {
            // valores
            var porcen = (inf?.valor - inf?.minimo) * 100 / (inf?.maximo - inf?.minimo);

            // %80 es el 100% (para que quede en forma de presion)
            var porcenLag = porcen * 80 / 100
            var valoresGrafico = [['hide', 20], ['data', porcenLag], ['rest', 80 - porcenLag]];
            this.valoresG = valoresGrafico;

            d3.select('#graficoPresion_' + m[1] + '_' + col + ' .c3-chart-arcs-title').attr("transform", "rotate(216)")
            d3.select('#graficoPresion_' + m[1] + '_' + col + ' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
                .text(inf?.valor?.toFixed(2));
        }
    }
    //#endregion

    //#region Temperatura y posicion
    infTemperaturaPosicionNivel(col, m) {
        var lag = 'm' + m + 'Inf';
        var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][lag].idCampo))[0];

        if (inf != undefined) {
            this.instalaciones[col][lag].min = inf?.minimo;
            this.instalaciones[col][lag].max = inf?.maximo;
            this.instalaciones[col][lag].value = inf?.valor?.toFixed(2);
            this.instalaciones[col][lag].unidad = inf?.unidad;
        }
    }
    //#endregion

    //#region Datos
    graficoDato(col, m) {
        var lag = 'm' + m + 'Inf';
        var inf_icono = this.iconosDatos.filter(f => (f.id == this.instalaciones[col][lag].id_tipoDato_DAT))[0];
        var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][lag].idCampo))[0];

        if (inf != undefined) {
            this.instalaciones[col][lag].icono = inf_icono?.icono;
            var intValueMore5 = parseInt(inf?.valor).toString().length >= 5

            // para dividirlo en puntos
            var number = []
            if (intValueMore5) {
                this.instalaciones[col][lag].value = Math.abs(inf?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse()
                this.instalaciones[col][lag].value.forEach(element => {
                    number.push(element.split("").reverse().join(""));
                });
                this.instalaciones[col][lag].value = number.join('.');
            } else {
                var numberLag: any = Math.abs(inf?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse();
                numberLag.forEach(element => {
                    number.push(element.split("").reverse().join(""));
                });
                numberLag = number.join('.');
                var comma = "," + inf?.valor?.toFixed(2).toString().split('.')[1];
                this.instalaciones[col][lag].value = numberLag + comma;
            }

            if (inf?.valor?.toString().includes('-'))
                this.instalaciones[col][lag].value = "-" + this.instalaciones[col][lag].value;

            this.instalaciones[col][lag].unidad = inf?.unidad;
        }
    }

    graficoDatoPorResumen(col, m, tipoAgrupado) {
        var lag = 'm' + m + 'Inf';
        var inf_icono = this.iconosDatos.filter(f => (f.id == this.instalaciones[col][lag].id_tipoDato_DAT))[0];
        var inf = this.infInstalacion.filter(f => (f.idCampo == this.instalaciones[col][lag].idCampo))[0];

        if (inf != undefined)
            this.instalacionesService.get_consumo_agrupado(inf.id, tipoAgrupado, true).subscribe(
                (result: any) => {
                    this.instalaciones[col][lag].icono = inf_icono?.icono;

                    if (result.length == 0) {
                        this.instalaciones[col][lag].value = 0;
                        this.instalaciones[col][lag].unidad = "";
                        return;
                    }

                    var intValueMore5 = parseInt(result[0]?.valor).toString().length >= 5

                    // para dividirlo en puntos
                    var number = [];
                    if (intValueMore5) {
                        this.instalaciones[col][lag].value = Math.abs(result[0]?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse()
                        this.instalaciones[col][lag].value.forEach(element => {
                            number.push(element.split("").reverse().join(""));
                        });
                        this.instalaciones[col][lag].value = number.join('.');
                    } else {
                        var numberLag: any = Math.abs(result[0]?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse();
                        numberLag.forEach(element => {
                            number.push(element.split("").reverse().join(""));
                        });
                        numberLag = number.join('.');
                        var comma = "," + result[0]?.valor?.toFixed(2).toString().split('.')[1];
                        this.instalaciones[col][lag].value = numberLag + comma;
                    }

                    if (result[0]?.valor?.toString().includes('-'))
                        this.instalaciones[col][lag].value = "-" + this.instalaciones[col][lag].value;

                    this.instalaciones[col][lag].unidad = inf?.unidad;
                });
    }
    //#endregion

    //#region Resumen semana
    dibujarResumenSemana(index, m) {
        var that = this;
        this.graficoResumenSemanaArray.push(c3.generate({
            bindto: '#graficoResumenSemana_' + m + "_" + index,
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            },
            data: {
                columns: [
                    [that.translateService.instant("ejecucion"), 0],
                    [that.translateService.instant("parada"), 0],
                    [that.translateService.instant("microparada"), 0],
                    [that.translateService.instant("preparacion"), 0],
                    [that.translateService.instant("mantenimiento"), 0],
                    [that.translateService.instant("alarma"), 0],
                    [that.translateService.instant("apagada"), 0]
                ],
                type: 'donut',
                order: null,
            },
            transition: {
                duration: 500
            },
            color: {
                pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
            },
            axis: {
                y: {
                    show: false
                },
                x: {
                    show: false
                }
            },
            bar: {
                width: {
                    ratio: 1
                },
                space: 0.1
            },
            tooltip: {
                format: {
                    title: function (d) { return 'Resumen semana' },
                    value: function (value, ratio, id) {
                        return value + '%';
                    }
                }
            },
            legend: {
                show: false
            }
        }));

        var lag = 'm' + m + 'Inf';
        this.cargarDatosParaGraficoResumenSemana(index, lag);
        this.cargarDatosResumenSemana(this.graficoResumenSemanaArray.length - 1);
    }

    cargarDatosParaGraficoResumenSemana(col, m) {

        this.centroMecanizadoService.getInstalacionById(this.instalaciones[col].id).subscribe((result) => {
            if (result.tiempoPorSemana.length > 0) {
                this.valoresGResumenSemana = [[this.translateService.instant("ejecucion"), result.tiempoPorSemana[0].porcentaje],
                [this.translateService.instant("parada"), result.tiempoPorSemana[1].porcentaje],
                [this.translateService.instant("microparada"), result.tiempoPorSemana[2]?.porcentaje],
                [this.translateService.instant("preparacion"), result.tiempoPorSemana[3]?.porcentaje],
                [this.translateService.instant("mantenimiento"), result.tiempoPorSemana[4]?.porcentaje],
                [this.translateService.instant("alarma"), result.tiempoPorSemana[5]?.porcentaje],
                [this.translateService.instant("apagada"), result.tiempoPorSemana[6]?.porcentaje]];
                this.showLabelResumenSemana = false;
            } else {
                this.valoresGResumenSemana = [[this.translateService.instant("ejecucion"), 0],
                [this.translateService.instant("parada"), 0],
                [this.translateService.instant("microparada"), 0],
                [this.translateService.instant("preparacion"), 0],
                [this.translateService.instant("mantenimiento"), 0],
                [this.translateService.instant("alarma"), 0],
                [this.translateService.instant("apagada"), 0]];
                this.showLabelResumenSemana = true;
            }
        });
    }

    cargarDatosResumenSemana(i) {
        this.graficoResumenSemanaArray[i].load({
            unload: true,
            columns: this.valoresGResumenSemana,
        });
    }
    //#endregion

    //#endregion ------------------------------------------------------------------------------------------------------------------------

    //#region Funciones para redirigir 
    redirigirRendimiento(idMaquina) {
        this.router.navigate(["informerendimiento/" + idMaquina]);
    }

    redirigirAProceso(idmaquina, tipo_maquina, puestoOffline) {

        if (tipo_maquina == 1) {
            if (!puestoOffline) this.router.navigate(["procesos/" + idmaquina]);
            if (puestoOffline) this.router.navigate(["procesosoffline/" + idmaquina]);
        }

        if (tipo_maquina == 2) {
            this.router.navigate(["procesosextrusora/" + idmaquina]);
        }

        if (tipo_maquina == 3) {
            this.router.navigate(["procesosimpresora/" + idmaquina]);
        }

        if (tipo_maquina == 4) {
            this.router.navigate(["procesosinyectora/" + idmaquina]);
        }

        if (tipo_maquina == 5) {
            this.router.navigate(["procesoshorno/" + idmaquina]);
        }

        if (tipo_maquina == 9) {
            this.router.navigate(["procesosaditivo/" + idmaquina]);
        }

        if (tipo_maquina == 12) {
            this.router.navigate(["procesoshornotemple/" + idmaquina]);
        }

    }

    redirigirAProcesoInstalacion(idInstalacion) {
        this.router.navigate(["procesosinstalacion/" + idInstalacion]);
    }

    redirigirAOEE(idMaquina, tipoInformacion) {
        this.informeOeeService.set_maquinasSeleccionadas_model([idMaquina]);
        if (tipoInformacion != 7)
            this.router.navigate(["informeOEE"]);

    }
    //#endregion
}