

<style>
</style>





<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- INFO -->
<div class="card" style="width: 35%;">
  <div class="card-header">
    <h3><label>{{ 'datos' | translate }}</label></h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <!-- DATOS -->
    <div class="clearfix">
      <!-- NOMBRE -->
      <div class="form-group">
        <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-textbox [(value)]="nombre" [maxlength]="50" [class.requerido]="requiereNombre"></kendo-textbox>
        </div>
      </div>
      <!-- DESCRIPCION -->
      <div class="form-group">
        <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
        <div class="caja">
          <textarea [(ngModel)]='descripcion' class="form-control" style="height: 7em;" maxlength="2000"></textarea>
        </div>
      </div>
      <!-- ACTIVO -->
      <div class="form-group">
        <kendo-label text="{{ 'activo' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-switch [(ngModel)]="activo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
        </div>
      </div>
      <!-- BOT(ONES) -->
      <div class="clearfix">
        <button kendoButton class="btn mr-1  btn-success" (click)="guardar()" [disabled]="this.user.consumibles<2">{{ 'guardar' | translate}}</button>
        <button kendoButton class="btn mr-1  btn-danger" (click)="cancelar()">{{ 'cancelar' | translate}}</button>
      </div>
    </div>
  </div>
</div>

<!-- POPUP: Error al guardar -->
<div class="popup-cont" *ngIf="popupErrorAlGuardar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlGuardar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlGuardar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
