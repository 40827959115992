import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, DiccionarioPerdidasService, HistoricoOperacionesService, IncidenciasService, InformeProyectosService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'app-informe-incidencia',
  templateUrl: 'informeIncidencia.component.html'
})

export class InformeIncidenciaComponent {

  formIncidencia: FormGroup;
  id: number;
  bloqueado: boolean = false;
  isAddMode: boolean;
  isClosed: boolean;
  loading = false;
  submitted = false;
  user = this.usuariosService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  modalReferenceSelectPiezaTabla: NgbModalRef;
  modalReferenceSelectPiezaTabla2: NgbModalRef;


  @ViewChild('selectPiezaTabla') selectPiezaTabla: NgbModalRef;
  @ViewChild('selectPiezaTabla2') selectPiezaTabla2: NgbModalRef;
  @ViewChild('of') of: ElementRef;
  @ViewChild('pieza') pieza: ElementRef;
  @ViewChild('parte') parte: ElementRef;
  @ViewChild('operacion') operacion: ElementRef;

  formatDate = 'yyyy-MM-dd HH:mm:ss';

  public incidenciasAcciones = [];
  lastAcc: number = 1;
  formAcciones: FormGroup;
  public incidenciasFicheros = [];
  lastFich: number = 1;
  formFicheros: FormGroup;
  submitArc: boolean = false;
  public seleccionadosFicheros: number[] = [];

  errorExt = false;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 28000000
  };

  public formInstantiated = false;


   //Datos completos para filtro
   ofsListas = false;
   piezasListas = false;
   partesListas = false;
   rutasListas = false;
   operacionesListas = false;
   private dataFiltro: any;
 
   public listaPiezas: any;
   public piezasSeleccionadas: any;
 
   public listaPartes: any; 
   public partesSeleccionadas: any;
 
   public listaOfs: any;
   public ofsSeleccionados: any;
 
   public listaRutas: any;
   public rutasSeleccionadas: any;
 
   public listaOperaciones: any;
   public operacionesSeleccionadas: any;

   public hayDatosFiltro: boolean = false; // for copiar form

   public dataGrid: any;

   public operacionesSelecteds = [];

   public piezaSeleccionada : any = null;

   public tiposIncidencia: any;
   public tiposIncidenciaView: any;
   public incidenciasSeleccionadas: any;
   public tiposSubIncidencias: any;
   public tiposSubIncidenciasView: any;
   public subincidenciasSeleccionadas: any;
   public gruposIncidencias: any;
   public gruposIncidenciasView: any;
   public gruposIncSeleccionado: any;

   public seguimiento: boolean = false;
   public observaciones: boolean = false;

   public tiposGravedad_DAT= [];
   public tiposGravedad_Seleccionada: any;

   public fechaAuxi: any;
   bloquearPerdidas: any;
   bloquearSubPerdidas: any;
   bloquearGrupos: any; 

   //ficheros
  public archivoAgregar: any ="";
  public nombreModal: any = "";
  public errorNombreModal: any = false;
  public errorArchivoModal: any = false;
  public idArchivo: any;
     
  constructor(
    public myFunctions: MyFunctions,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private incidenciasService: IncidenciasService,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    public modalService: NgbModal,
    private menuService: MenuService,
    private cdr: ChangeDetectorRef,
    private informeProyectosService: InformeProyectosService,
    private diccionarioPerdidasService: DiccionarioPerdidasService,
    private historicoOperacionesService: HistoricoOperacionesService
  ) {
    this.menuService.titulo = this.translateService.instant('informeIncidencia');

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = (this.id==undefined);

    this.formIncidencia = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      idMaquina: this.route.snapshot.params['idMaq'],
      fechaIncidencia: new FormControl(this.myFunctions.getDateNow()),
      operarioIncidencia: new FormControl(this.user.nombre),
      afectaProduccion: new FormControl(false),
      causa: new FormControl('1'),
      cliente: new FormControl(''),
      descripcion: new FormControl(''),
      acciones: new FormControl(''),
      incidenciaAcciones: new FormControl(''),
      incidenciaFicheros: new FormControl(''),
      grupoPerdidaId: new FormControl(''),
      idPerdida: new FormControl(''),
      gravedad: new FormControl(''),
      seguimiento: new FormControl(false),
      observaciones: new FormControl(false),
      idHistoricoOperaciones: new FormControl(-1)
    });
   }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    this.isAddMode = (this.id==undefined);

    this.tiposGravedad_DAT = [
      { id: 1, nombre: this.translateService.instant("leve") },
      { id: 2, nombre: this.translateService.instant("medio") },
      { id: 3, nombre: this.translateService.instant("grave") }
    ];

    if (!this.isAddMode) {
      this.incidenciasService.Get_Incidencias2(this.id, -1, false)
        .pipe()
        .subscribe((result) => {
          this.formInstantiated = true;
          this.diccionarioPerdidasService.GetAllGruposWithPerdidas().subscribe(response => {
            this.tiposIncidencia = response.data2;
            this.tiposIncidenciaView = response.data2;
            this.tiposSubIncidencias = response.data3;
            this.tiposSubIncidenciasView = response.data3;
            this.gruposIncidencias = response.data;
            this.gruposIncidenciasView = response.data;
            if (result.data[0].idHistoricoOperaciones !== -1){ //si tiene operación asociada
              this.historicoOperacionesService.Get_datos(Number(result.data[0].idHistoricoOperaciones))
              .pipe()
              .subscribe((result2) => {
                this.piezaSeleccionada = result2[0];
                this.of.nativeElement.innerText = this.piezaSeleccionada.numeroOF;
                this.pieza.nativeElement.innerText = this.piezaSeleccionada.pieza;
                this.parte.nativeElement.innerText = this.piezaSeleccionada.parte;
                this.operacion.nativeElement.innerText = this.piezaSeleccionada.operacion;

                this.fechaAuxi = new Date(result.data[0].fechaIncidencia.replace("T", " "));
                  
                this.seguimiento = result.data[0].seguimiento;
                this.observaciones = result.data[0].observaciones;
                
                if(result.data[0].idGrupo!=-1){
                  var auxi = this.gruposIncidencias.filter(element => element.id == result.data[0].idGrupo);
                  if(auxi!=undefined && auxi!=null && auxi.length>0){
                    this.gruposIncSeleccionado = auxi[0];
                    this.bloquearGrupos=true;
                    this.bloquearPerdidas=false;
                    this.bloquearSubPerdidas=true;

                    if(result.data[0].idPadre!=-1){
                      var auxi = this.tiposIncidencia.filter(element => element.idPerdida == result.data[0].idPadre);
                      if(auxi!=undefined && auxi!=null && auxi.length>0){
                        this.incidenciasSeleccionadas = auxi[0];
                        this.tiposIncidenciaView = this.tiposIncidencia.filter(x=>x.idGrupo == this.gruposIncSeleccionado.id);
                        this.bloquearGrupos=true;
                        this.bloquearPerdidas=true;
                        this.bloquearSubPerdidas=false;

                        if(result.data[0].idPerdida!=-1){
                          var auxi =  this.tiposSubIncidencias.filter(element =>element.idPerdida == result.data[0].idPerdida);
                          if(auxi!=undefined && auxi!=null && auxi.length>0){
                            this.subincidenciasSeleccionadas =auxi[0];
                            this.tiposSubIncidenciasView = this.tiposSubIncidencias.filter(x=>x.idPerdida == this.incidenciasSeleccionadas.idPerdida ||
                              x.idPadre == this.incidenciasSeleccionadas.idPerdida);
                            this.bloquearGrupos=true;
                            this.bloquearPerdidas=true;
                            this.bloquearSubPerdidas=false;
                          }
                          else{
                            this.subincidenciasSeleccionadas = [];
                            this.bloquearGrupos=true;
                            this.bloquearPerdidas=false;
                            this.bloquearSubPerdidas=true;
                          }
                        }else{
                          this.subincidenciasSeleccionadas = [];
                          this.bloquearGrupos=true;
                          this.bloquearPerdidas=false;
                          this.bloquearSubPerdidas=true;
                        }
                      }
                      else{
                        this.incidenciasSeleccionadas = [];
                        this.bloquearGrupos=true;
                        this.bloquearPerdidas=true;
                        this.bloquearSubPerdidas=false;
                      }
                    }else{
                      this.incidenciasSeleccionadas = [];
                      this.bloquearGrupos=true;
                      this.bloquearPerdidas=true;
                      this.bloquearSubPerdidas=false;
                    }
                  }
                  else{
                    this.gruposIncSeleccionado = [];
                    this.bloquearGrupos=false;
                    this.bloquearPerdidas=true;
                    this.bloquearSubPerdidas=true;
                  }
                }else{
                  this.gruposIncSeleccionado = [];
                  this.bloquearGrupos=false;
                  this.bloquearPerdidas=true;
                  this.bloquearSubPerdidas=true;
                }
                  
                if(result.data[0].gravedad == null || result.data[0].gravedad == undefined)
                  this.tiposGravedad_Seleccionada = -1;
                else{
                  var auxi3 = this.tiposGravedad_DAT.filter(element =>element.id == result.data[0].gravedad);
                  if(auxi3!=undefined && auxi3!=null && auxi3.length>0)
                    this.tiposGravedad_Seleccionada = auxi3[0];
                  else
                  this.tiposGravedad_Seleccionada = -1;
                }
  
                this.formIncidencia = this.formBuilder.group({
                  id: new FormControl(this.id),
                  idMaquina: result.data[0].idMaquina,
                  fechaIncidencia: new FormControl(new Date(result.data[0].fechaIncidencia.replace("T", " "))),
                  operarioIncidencia: new FormControl(result.data[0].operarioIncidencia),
                  afectaProduccion: new FormControl(result.data[0].afectaProduccion),
                  causa: new FormControl(result.data[0].causa),
                  cliente: new FormControl(result.data[0].cliente),
                  descripcion: new FormControl(result.data[0].descripcion),
                  acciones: new FormControl(result.data[0].acciones),
                  incidenciaAcciones: new FormControl(''),
                  grupoPerdidaId: new FormControl(this.gruposIncSeleccionado != undefined?this.gruposIncSeleccionado.idGrupo:-1),
                  idPerdida: new FormControl(this.subincidenciasSeleccionadas!=undefined?this.subincidenciasSeleccionadas.idPerdida:-1),
                  gravedad: new FormControl(this.tiposGravedad_Seleccionada),
                  seguimiento: new FormControl(this.seguimiento),
                  observaciones: new FormControl(this.observaciones),
                  idHistoricoOperaciones: new FormControl(result.data[0].idHistoricoOperaciones)
                });

              });
            } else{ //si NO tiene operación asociada

              this.fechaAuxi = new Date(result.data[0].fechaIncidencia.replace("T", " "));
                  
                this.seguimiento = result.data[0].seguimiento;
                this.observaciones = result.data[0].observaciones;
                
                if(result.data[0].idGrupo!=-1){
                  var auxi = this.gruposIncidencias.filter(element => element.id == result.data[0].idGrupo);
                  if(auxi!=undefined && auxi!=null && auxi.length>0){
                    this.gruposIncSeleccionado = auxi[0];
                    this.bloquearGrupos=true;
                    this.bloquearPerdidas=false;
                    this.bloquearSubPerdidas=true;

                    if(result.data[0].idPadre!=-1){
                      var auxi = this.tiposIncidencia.filter(element => element.idPerdida == result.data[0].idPadre);
                      if(auxi!=undefined && auxi!=null && auxi.length>0){
                        this.incidenciasSeleccionadas = auxi[0];
                        this.tiposIncidenciaView = this.tiposIncidencia.filter(x=>x.idGrupo == this.gruposIncSeleccionado.id);
                        this.bloquearGrupos=true;
                        this.bloquearPerdidas=true;
                        this.bloquearSubPerdidas=false;

                        if(result.data[0].idPerdida!=-1){
                          var auxi =  this.tiposSubIncidencias.filter(element =>element.idPerdida == result.data[0].idPerdida);
                          if(auxi!=undefined && auxi!=null && auxi.length>0){
                            this.subincidenciasSeleccionadas =auxi[0];
                            this.tiposSubIncidenciasView = this.tiposSubIncidencias.filter(x=>x.idPerdida == this.incidenciasSeleccionadas.idPerdida ||
                              x.idPadre == this.incidenciasSeleccionadas.idPerdida);
                            this.bloquearGrupos=true;
                            this.bloquearPerdidas=true;
                            this.bloquearSubPerdidas=false;
                          }
                          else{
                            this.subincidenciasSeleccionadas = [];
                            this.bloquearGrupos=true;
                            this.bloquearPerdidas=false;
                            this.bloquearSubPerdidas=true;
                          }
                        }else{
                          this.subincidenciasSeleccionadas = [];
                          this.bloquearGrupos=true;
                          this.bloquearPerdidas=false;
                          this.bloquearSubPerdidas=true;
                        }
                      }
                      else{
                        this.incidenciasSeleccionadas = [];
                        this.bloquearGrupos=true;
                        this.bloquearPerdidas=true;
                        this.bloquearSubPerdidas=false;
                      }
                    }else{
                      this.incidenciasSeleccionadas = [];
                      this.bloquearGrupos=true;
                      this.bloquearPerdidas=true;
                      this.bloquearSubPerdidas=false;
                    }
                  }
                  else{
                    this.gruposIncSeleccionado = [];
                    this.bloquearGrupos=false;
                    this.bloquearPerdidas=true;
                    this.bloquearSubPerdidas=true;
                  }
                }else{
                  this.gruposIncSeleccionado = [];
                  this.bloquearGrupos=false;
                  this.bloquearPerdidas=true;
                  this.bloquearSubPerdidas=true;
                }
                  
                if(result.data[0].gravedad == null || result.data[0].gravedad == undefined)
                  this.tiposGravedad_Seleccionada = -1;
                else{
                  var auxi3 = this.tiposGravedad_DAT.filter(element =>element.id == result.data[0].gravedad);
                  if(auxi3!=undefined && auxi3!=null && auxi3.length>0)
                    this.tiposGravedad_Seleccionada = auxi3[0];
                  else
                  this.tiposGravedad_Seleccionada = -1;
                }
  
                this.formIncidencia = this.formBuilder.group({
                  id: new FormControl(this.id),
                  idMaquina: result.data[0].idMaquina,
                  fechaIncidencia: new FormControl(new Date(result.data[0].fechaIncidencia.replace("T", " "))),
                  operarioIncidencia: new FormControl(result.data[0].operarioIncidencia),
                  afectaProduccion: new FormControl(result.data[0].afectaProduccion),
                  causa: new FormControl(result.data[0].causa),
                  cliente: new FormControl(result.data[0].cliente),
                  descripcion: new FormControl(result.data[0].descripcion),
                  acciones: new FormControl(result.data[0].acciones),
                  incidenciaAcciones: new FormControl(''),
                  grupoPerdidaId: new FormControl(this.gruposIncSeleccionado != undefined?this.gruposIncSeleccionado.idGrupo:-1),
                  idPerdida: new FormControl(this.subincidenciasSeleccionadas!=undefined?this.subincidenciasSeleccionadas.idPerdida:-1),
                  gravedad: new FormControl(this.tiposGravedad_Seleccionada),
                  seguimiento: new FormControl(this.seguimiento),
                  observaciones: new FormControl(this.observaciones),
                  idHistoricoOperaciones: new FormControl(result.data[0].idHistoricoOperaciones)
                });
            }
            
            this.incidenciasService.Get_Archivos(this.id, -1).subscribe((result2) => {
              this.incidenciasFicheros = result2.data;
            
          });
        });
    
          this.incidenciasService.Get_Acciones(this.id, -1).subscribe((result3) => {
            this.incidenciasAcciones = result3.data;
            if(this.incidenciasAcciones.length>0)
              this.lastAcc = result3.data[result3.data.length - 1].id + 1;
          });

          this.isClosed = result.data[0].cerrado;
          this.bloqueado = result.data[0].bloqueado;
      });
    } else {
      this.diccionarioPerdidasService.GetAllGruposWithPerdidas().subscribe(response => {
        this.tiposIncidencia = response.data2;
        this.tiposIncidenciaView = response.data2;
        this.tiposSubIncidencias = response.data3;
        this.tiposSubIncidenciasView = response.data3;
        this.gruposIncidencias = response.data;
        this.gruposIncidenciasView = response.data;
        this.formIncidencia = this.formBuilder.group({
          id: this.route.snapshot.params['id'],
          idMaquina: this.route.snapshot.params['idMaq'],
          fechaIncidencia: new FormControl(this.myFunctions.getDateNow()),
          operarioIncidencia: new FormControl(this.user.nombre),
          afectaProduccion: new FormControl(false),
          causa: new FormControl('1'),
          cliente: new FormControl(''),
          descripcion: new FormControl(''),
          acciones: new FormControl(''),
          incidenciaAcciones: new FormControl(''),
          incidenciaFicheros: new FormControl(''),
          grupoPerdidaId: new FormControl(''),
          idPerdida: new FormControl(''),
          gravedad: new FormControl(''),
          seguimiento: new FormControl(false),
          observaciones: new FormControl(false),
          idHistoricoOperaciones: new FormControl(-1)
        });
        this.formInstantiated = true;
      });
    }
  }
  onGruposIncidenciasChange(element: ComboBoxComponent, cambio, modo){
    switch(modo){
      case 'grupos':
        if (cambio== undefined || cambio.length==0){//Si no está seleccionado el grupo
          this.bloquearGrupos=false;
          this.bloquearPerdidas=true;
          this.bloquearSubPerdidas=true;
        }else{
          this.bloquearGrupos=false;
          this.bloquearPerdidas=false;
          this.bloquearSubPerdidas=true;
          this.tiposIncidenciaView = this.tiposIncidencia.filter(elem => elem.idGrupo === cambio.id);
        }
        //Limpiamos seleccionadas
        if(!(this.incidenciasSeleccionadas.idGrupo==cambio.id)){
          this.incidenciasSeleccionadas=undefined;
        }
        element.toggle(false);// cerrarlo por si acaso
        break;
      case 'perdidas':
        if (cambio== undefined || cambio.length==0){
          this.bloquearGrupos=false;
          this.bloquearPerdidas=true;
          this.bloquearSubPerdidas=true;
          this.tiposIncidenciaView = this.tiposIncidencia.filter(elem => elem.idGrupo === this.gruposIncSeleccionado.id);
        }else{
          this.bloquearGrupos=true;
          this.bloquearPerdidas=false;
          this.bloquearSubPerdidas=false;
          this.tiposSubIncidenciasView = this.tiposSubIncidencias.filter(x=>x.idPadre==cambio.idPerdida || x.idPerdida==cambio.idPerdida);
        }
        //Limpiamos seleccionadas
        if(!(this.subincidenciasSeleccionadas.idPadre==cambio.idPerdida || this.subincidenciasSeleccionadas.idPerdida==cambio.idPerdida)){
          this.subincidenciasSeleccionadas=undefined;
        }
        element.toggle(false);// cerrarlo por si acaso
        break;
      case 'subperdidas':
        if (cambio== undefined || cambio.length==0){
          this.bloquearGrupos=true;
          this.bloquearPerdidas=false;
          this.bloquearSubPerdidas=true;
          this.tiposIncidenciaView = this.tiposIncidencia.filter(elem => elem.idGrupo === this.gruposIncSeleccionado.id);
        }else{
          this.bloquearGrupos=true;
          this.bloquearPerdidas=false;
          this.bloquearSubPerdidas=false;
          this.tiposSubIncidenciasView = this.tiposSubIncidencias.filter(x=>x.idPadre==this.incidenciasSeleccionadas.idPerdida 
            || x.idPerdida==this.incidenciasSeleccionadas.idPerdida);
        }
        element.toggle(false);// cerrarlo por si acaso
        break;
    }
  }
  limpiarSeleccion(){
    this.bloquearGrupos=false;
    this.bloquearPerdidas=true;
    this.bloquearSubPerdidas=true;
    this.gruposIncSeleccionado=undefined;
    this.incidenciasSeleccionadas=undefined;
    this.subincidenciasSeleccionadas=undefined;
  }

  cargarGridPieza(){
    this.incidenciasService.getIncidenciasPiezas({idOFs: this.ofsSeleccionados.idOf, idOFs_Piezas: this.piezasSeleccionadas.idPieza, 
      idOFs_Partes: this.partesSeleccionadas.idParte, idOfs_Operacion: this.operacionesSeleccionadas.idOperacion, idOfs_Rutas: this.rutasSeleccionadas.idRuta}).subscribe((json) => {
          this.dataGrid = json.data;
        });
  }
  

   //ENSEÑAR COLUMNA ROJA SI EL EJECUCION PASA EL ESTIMADO
   public rowCallback(context: RowClassArgs) {
    var registro = context.dataItem;
    if ((registro.tiempoRealEjecucion > registro.tiempoEstimadoEjecucion) && registro.usuarioIdDb == registro.usuarioIdDbGamesa) return { rojo: true };
    if ((registro.tiempoRealPreparacion > registro.tiempoEstimadoPreparacion) && registro.usuarioIdDb == registro.usuarioIdDbGamesa) return { rojo: true };
  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //se toman los datos
    this.informeProyectosService.Get_OfsPiezasOperacionesPartesRutas().pipe(first()).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByPieza = [];
      var groupByOf = [];
      var groupByParte = [];
      var groupByRuta = [];
      var groupByOperacion = [];


      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));

      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))

      //GROUP POR PARTE
      data.forEach(function (a) {
        if (!this[a.nombreParte]) {
          this[a.nombreParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.nombreParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY RUTA
      data.forEach(function (a) {
        if (!this[a.idRuta]) {
          this[a.idRuta] = {
            idRuta: a.idRuta, nombreRuta: a.nombreRuta,
          };
          groupByRuta.push(this[a.idRuta]);
        }
      }, Object.create(null));

      this.listaRutas = groupByRuta.filter(item => (item.idRuta != -1));
      this.listaRutas.sort((a, b) => (a.nombreRuta > b.nombreRuta) ? 1 : ((b.nombreRuta > a.nombreRuta) ? -1 : 0));

      //GROUP BY Operaciones
      data.forEach(function (a) {
        if (!this[a.nombreOperacion]) {
          this[a.nombreOperacion] = {
            idOperacion: a.idOperacion, nombreOperacion: a.nombreOperacion,
          };
          groupByOperacion.push(this[a.nombreOperacion]);
        }
      }, Object.create(null));

      this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1));
      this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    });
  }

  CambioFiltro(): void { //si los datos del filtro cambian actualizar cuáles son los datos seleccionados

    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      idsOFs.push(this.ofsSeleccionados.idOf);

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      idPiezas.push(this.piezasSeleccionadas.nombrePieza);

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      idPartes.push(this.partesSeleccionadas.nombreParte);

    var idRutas = [];
    if (this.rutasSeleccionadas != undefined)
      idRutas.push(this.rutasSeleccionadas.nombreRuta);

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      idOperaciones.push(this.operacionesSeleccionadas.nombreOperacion);

    var groupByPieza = [];
    var groupByOf = [];
    var groupByOperacion = [];
    var groupByParte = [];
    var groupByRuta = [];

    //GROUP BY OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });
    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));
    
    //GROUP BY PARTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreParte]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombreParte] = {
            idParte: row.idParte, nombreParte: row.nombreParte,
          };
          groupByParte.push(lag[row.nombreParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

    //GROUP BY RUTA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreRuta]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombreRuta] = {
            idRuta: row.idRuta, nombreRuta: row.nombreRuta,
          };
          groupByRuta.push(lag[row.nombreRuta]);
        }
      });

    this.listaRutas = groupByRuta.filter(item => (item.idRuta != -1));
    this.listaRutas.sort((a, b) => (a.nombreRuta > b.nombreRuta) ? 1 : ((b.nombreRuta > a.nombreRuta) ? -1 : 0));

    //GROUP BY POR OPERACION
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreOperacion]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)) {
          lag[row.nombreOperacion] = {
            idOperacion: row.idOperacion, nombreOperacion: row.nombreOperacion,
          };
          groupByOperacion.push(lag[row.nombreOperacion]);
        }
      });

    this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1));
    this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    if((this.listaOfs.length > 0 && this.ofsSeleccionados !== undefined))
      this.ofsListas = true;
    else
      this.ofsListas = false;
    if((this.listaPiezas.length > 0 && this.piezasSeleccionadas !== undefined))
      this.piezasListas = true;
    else
      this.piezasListas = false;
    if((this.listaPartes.length > 0 && this.partesSeleccionadas !== undefined))
      this.partesListas = true;
    else
      this.partesListas = false;
    if((this.listaRutas.length > 0 && this.rutasSeleccionadas !== undefined))
      this.rutasListas = true;
    else
      this.rutasListas = false;
    if((this.listaOperaciones.length > 0 && this.operacionesSeleccionadas !== undefined ))
      this.operacionesListas = true;
    else
      this.operacionesListas = false;

    if(this.ofsListas && this.piezasListas && this.partesListas && this.rutasListas && this.operacionesListas)
      this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar
    else
      this.hayDatosFiltro = false;
  }

  cellClick(event:any){
    this.piezaSeleccionada = event.dataItem;
  }

  public limpiarFiltro(){
    this.ofsSeleccionados = undefined;
    this.piezasSeleccionadas = undefined;
    this.partesSeleccionadas = undefined;
    this.rutasSeleccionadas = undefined;
    this.operacionesSeleccionadas = undefined;
    this.CambioFiltro();
  }

  cancelarFiltrado(event: any){
    event.preventDefault() //just in case
    this.limpiarFiltro();
    this.modalReferenceSelectPiezaTabla.close();
  }

  onTabSelected(event: any){}

  openSelectPiezaModal(event: any){
    event.preventDefault(); //prevent submit
    this.cargarDatosFiltro();
    this.modalReferenceSelectPiezaTabla = this.modalService.open(this.selectPiezaTabla, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  openPiezaGridPopUp(event: any){
    this.cargarGridPieza();
    this.modalReferenceSelectPiezaTabla.close();
    this.modalReferenceSelectPiezaTabla2 = this.modalService.open(this.selectPiezaTabla2, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  closePopUp(){
    this.of.nativeElement.innerText = this.piezaSeleccionada.numeroOf;
    this.pieza.nativeElement.innerText = this.piezaSeleccionada.pieza;
    this.parte.nativeElement.innerText = this.piezaSeleccionada.parte;
    this.operacion.nativeElement.innerText = this.piezaSeleccionada.operacion;
    this.modalReferenceSelectPiezaTabla2.close();
    this.limpiarFiltro();

  }
  volverPopUpPieza1(event: any){
    this.piezaSeleccionada = null;
    this.modalReferenceSelectPiezaTabla2.close();
    this.modalReferenceSelectPiezaTabla = this.modalService.open(this.selectPiezaTabla, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  // convenience getter for easy access to form fields
  get f() { return this.formIncidencia.controls; }

  onSubmit(afectaProdEm) {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.formIncidencia.invalid) {
      return;
    }

    if(this.formIncidencia.value.afectaProduccion) {
      this.modalReference = this.modalService.open(afectaProdEm, { backdrop: 'static', size: 'lg', keyboard: false, centered: true }); // Parte de envío de emails
    } else {
      this.loading = true;

      if(this.seguimiento)
        this.recogerAcciones();

      if (this.isAddMode) {
        this.crearIncidencia(false);
      } else {
        this.updateIncidencia(false);
      }
    }
  }

  private crearIncidencia(envio) {
    this.formIncidencia.value.idMaquina = this.formIncidencia.value.idMaquina==null?-1:this.formIncidencia.value.idMaquina;
    this.formIncidencia.value.incidenciaAcciones = this.incidenciasAcciones;
    this.formIncidencia.value.incidenciaFicheros = this.incidenciasFicheros;
    this.formIncidencia.value.seguimiento = this.seguimiento;
    this.formIncidencia.value.observaciones = this.observaciones;
    this.formIncidencia.value.grupoPerdidaId = this.gruposIncSeleccionado==undefined || this.gruposIncSeleccionado.length==0 ?-1: this.gruposIncSeleccionado.id;
    this.formIncidencia.value.idPerdida = this.subincidenciasSeleccionadas==undefined || this.subincidenciasSeleccionadas.length==0?-1: this.subincidenciasSeleccionadas.idPerdida;
    this.formIncidencia.value.gravedad = this.tiposGravedad_Seleccionada !== undefined? this.tiposGravedad_Seleccionada.id: -1;
    
    if (this.piezaSeleccionada !== null){
      this.formIncidencia.value.idHistoricoOperaciones = this.piezaSeleccionada.idHo;
    }
    this.incidenciasService.Insert_Incidencia(this.formIncidencia.value, envio)
      .subscribe((result) => {
        if (!result.error) {
          if(this.formIncidencia.value.afectaProduccion) {
            this.modalReference.close();
          }
          //this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['/informesincidencias']);
        } else {
          //this.alertService.error(this.translateService.instant('errorcrear'));
          this.loading = false;
        }
      });
    
  }

  private updateIncidencia(envio) {
    // Al actualizarse la incidencia, se actualizan también sus acciones
    this.formIncidencia.value.idMaquina = this.formIncidencia.value.idMaquina==null?-1:this.formIncidencia.value.idMaquina;
    this.formIncidencia.value.incidenciaAcciones = this.incidenciasAcciones;
    this.formIncidencia.value.seguimiento = this.seguimiento;
    this.formIncidencia.value.observaciones = this.observaciones;
    this.formIncidencia.value.grupoPerdidaId = this.gruposIncSeleccionado==undefined || this.gruposIncSeleccionado.length==0 ?-1: this.gruposIncSeleccionado.id;
    this.formIncidencia.value.idPerdida = this.subincidenciasSeleccionadas==undefined || this.subincidenciasSeleccionadas.length==0?-1: this.subincidenciasSeleccionadas.idPerdida;
    this.formIncidencia.value.gravedad = this.tiposGravedad_Seleccionada !== undefined? this.tiposGravedad_Seleccionada.id: -1;
    
    if (this.piezaSeleccionada !== null){this.formIncidencia.value.idHistoricoOperaciones = this.piezaSeleccionada.idHistoricoOperaciones;
    }
    this.incidenciasService.Update_Incidencia(this.formIncidencia.value, envio)
      .subscribe((result) => {
        if (!result.error) {
          if(this.formIncidencia.value.afectaProduccion) {
            this.modalReference.close();
          }
          //this.alertService.success(this.translateService.instant('actualizarcorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['/informesincidencias']);
        } else {
          //this.alertService.error(this.translateService.instant('erroractualizar'));
          this.loading = false;
        }
      });
  }

  // Parte acciones tomadas
  onClickNuevaAcc() {
    // Cada vez que se inserte una acción nueva, para distinguirlas de las que ya hay se pondrá un índice incremental negativo
    if (this.isAddMode) {
      this.formAcciones = this.formBuilder.group({
        id: -this.lastAcc,
        idIncidencia: -1,
        accion: new FormControl(''),
        fecha: new FormControl(this.myFunctions.getDateNow()),
        idOperario: new FormControl(this.user.id),
        operario: new FormControl(this.user.nombre)
      });
    } else {
      this.formAcciones = this.formBuilder.group({
        id: -this.lastAcc,
        idIncidencia: this.id,
        accion: new FormControl(''),
        fecha: new FormControl(this.myFunctions.getDateNow()),
        idOperario: new FormControl(this.user.id),
        operario: new FormControl(this.user.nombre)
      });
    }

    this.lastAcc += 1;

    this.incidenciasAcciones.push(this.formAcciones.value);
  }

  recogerAcciones() {
    for (var i = 0; i < this.incidenciasAcciones.length; i++) {
      this.incidenciasAcciones[i].accion = (<HTMLInputElement>document.getElementById("accion_" + this.incidenciasAcciones[i].id)).value;
      this.incidenciasAcciones[i].fecha = new Date(this.incidenciasAcciones[i].fecha).toLocaleString(); // Parseo de fecha para evitar problemas con las horas
    }
  }

  // Parte archivos adjuntos
  onClickNuevoArcAdj(contentFichero) {
    this.archivoAgregar="";
    this.nombreModal="";
    this.idArchivo = -1;
    this.modalReference = this.modalService.open(contentFichero, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  descargarFichero(dataItem) {
    const downloadLink = document.createElement("a");

        downloadLink.href = dataItem.rutaBase64;
        downloadLink.download = dataItem.ruta;
        downloadLink.click();

  }

  onClickEditarArcAdj(contentFichero) {
    if (this.seleccionadosFicheros.length == 1) {
      var dataItem = this.incidenciasFicheros.filter(x=>x.id==this.seleccionadosFicheros[0])[0];
      this.archivoAgregar=dataItem.rutaBase64;
      this.nombreModal=dataItem.nombre;
      this.idArchivo = dataItem.id;
      this.modalReference = this.modalService.open(contentFichero, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  cellClickArcAdj(e, contentFichero) {
    console.log(e.dataItem);
    if (e.columnIndex>0 && e.columnIndex<3) {
      this.archivoAgregar=e.dataItem.rutaBase64;
      this.nombreModal=e.dataItem.nombre;
      this.idArchivo = e.dataItem.id;
      this.modalReference = this.modalService.open(contentFichero, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
    else if(e.columnIndex==3){
      this.descargarFichero(e.dataItem);
    }
  }

  crearActualizarArchivo() {
    this.submitArc = true;

    this.loading = true;

    if(this.archivoAgregar==undefined || this.archivoAgregar==null || this.archivoAgregar==""){
      this.errorArchivoModal=true;
    }else{
      this.errorArchivoModal=false;
    }

    if(this.nombreModal==undefined || this.nombreModal==null || this.nombreModal==""){
      this.errorNombreModal=true;
    }else{
      this.errorNombreModal=false;
    }

    if(this.errorNombreModal || this.errorArchivoModal){
      this.loading = false;
      return;
    }
      

    if(this.idArchivo < 0) { // Caso crear archivo
      this.incidenciasService.Insert_Archivo(this.archivoAgregar, this.id, this.nombreModal)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.incidenciasService.Get_Archivos(this.id, -1).subscribe((result) => {
            this.incidenciasFicheros = result.data;
          });
          this.modalReference.close();
        } else {
          this.alertService.error(this.translateService.instant('errorcrear'));
          this.loading = false;
        }
      });
    } else { // Caso actualizar archivo
      this.incidenciasService.Update_Archivo(this.archivoAgregar, this.id, this.nombreModal, this.idArchivo)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(this.translateService.instant('actualizarcorrectamente'), { keepAfterRouteChange: true });
          this.incidenciasService.Get_Archivos(this.id, -1).subscribe((result) => {
            this.incidenciasFicheros = result.data;
          });
          this.modalReference.close();
        } else {
          this.alertService.error(this.translateService.instant('erroractualizar'));
          this.loading = false;
        }
      });
    }
    this.loading = false;
  }

  onClickEliminarArcAdj(content) {
    if (this.seleccionadosFicheros.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

    if(this.isAddMode) {
      for(var i = 0; i < this.seleccionadosFicheros.length; i++) {
        var j = 0;
        var found = false;
        while(j < this.incidenciasFicheros.length && !found) {
          if(this.incidenciasFicheros[j].id == this.seleccionadosFicheros[i]) {
            this.incidenciasFicheros.splice(j, 1);
            found = true;
          }
          j++;
        }
      }
      this.modalReferenceloading.close();
    } else {
      this.incidenciasService.Delete_Archivos({ ids: this.seleccionadosFicheros }, this.id).subscribe(
        (data) => {
          if (data.error == false) {
            this.incidenciasService.Get_Archivos(this.id, -1).subscribe((result) => {
              this.incidenciasFicheros = result.data;
            }
            );
          }
          this.modalReferenceloading.close();
        }
      );
    }
  }

  selectEventHandlerFile(e: SelectEvent) {
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoAgregar !== "" && th.archivoAgregar !== null){
        archivoBase64 = await th.toBase64(th.archivoAgregar[0]);
      } 
      else 
        archivoBase64 = "";
      th.archivoAgregar = archivoBase64;
    }, 500);
  }

  removeEventHandlerFile(e: RemoveEvent): void {
    this.archivoAgregar = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  // Fechas
  creaFecha(fecha) {
    return new Date(fecha);
  }

  // Botones
  onCopiar() {
    this.incidenciasService.Copiar_Incidencias({ ids: [this.id] }).subscribe((result) => {
      if (!result.error) {
        this.router.navigate(['/informesincidencias']);
      }
    });
  }

  onReabrir() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    if (this.formIncidencia.invalid) {
      return;
    }

    if(this.seguimiento)
      this.recogerAcciones();

    this.formIncidencia.value.incidenciaAcciones = this.incidenciasAcciones;

    this.incidenciasService.Update_Incidencia(this.formIncidencia.value, false)
    .subscribe((result) => {
      if (!result.error) {
        //this.alertService.success(this.translateService.instant('actualizarcorrectamente'), { keepAfterRouteChange: true });
        this.incidenciasService.Reabrir_Incidencia(this.id).subscribe((result) => {
          if (!result.error) {
            this.router.navigate(['/informesincidencias']);
          }
        });
      } else {
        //this.alertService.error(this.translateService.instant('erroractualizar'));
        this.loading = false;
      }
    });
  }

  onCerrarIncid() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    if (this.formIncidencia.invalid) {
      return;
    }

    if(this.seguimiento)
      this.recogerAcciones();

    this.formIncidencia.value.incidenciaAcciones = this.incidenciasAcciones;

    this.incidenciasService.Update_Incidencia(this.formIncidencia.value, false) // El envío en caso de afectar producción se hará en cerrar
    .subscribe((result) => {
      if (!result.error) {
        //this.alertService.success(this.translateService.instant('actualizarcorrectamente'), { keepAfterRouteChange: true });
        this.incidenciasService.Cerrar_Incidencia(this.id).subscribe((result) => {
          if (!result.error) {
            this.router.navigate(['/informesincidencias']);
          }
        });
      } else {
        //this.alertService.error(this.translateService.instant('erroractualizar'));
        this.loading = false;
      }
    });
  }

  // Parte emails
  guardarAfProdEnvioEmail(envio) { // Se enviará email desde estos métodos en caso de que se afecte a producción
    if(envio) { // Caso se envío positivo
      this.loading = true;
      if(this.seguimiento)
      this.recogerAcciones();
  
      if (this.isAddMode) {
        this.crearIncidencia(envio);
      } else {
        this.updateIncidencia(envio);
      }
    } else { // Caso de envío negativo (solo se guardan los cambios de la incidencia para un no administrador, no se envía email)
      if(this.user.usuarios_TiposId != 1) {
        this.loading = true;

        this.recogerAcciones();
    
        if (this.isAddMode) {
          this.crearIncidencia(envio);
        } else {
          this.updateIncidencia(envio);
        }
      } else {
        this.modalReference.close(); // En un administrador no se guardan los cambios, ni se envía email
      }
    }
  }
}
