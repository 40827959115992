import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuService, ToleranciasTipoService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-toleranciasTipoDetalle',
  templateUrl: './toleranciasTipoDetalle.component.html'
})
export class ToleranciasTipoDetalleComponent {
  public events: string[] = [];
  public id: number;
  public imageToShow: any;

  public toleranciasTipoValorItems: any[] = [];
  public toleranciasTipoItems: any[] = [];

  public idTipoTolerancia: number;
  public idTipoValor: number;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  formIconError: boolean = false;
  user = this.userService.userValue;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private toleranciasTipoService: ToleranciasTipoService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService) {

    this.menuService.titulo = this.translateService.instant('toleranciatipo').toUpperCase();
  }

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.toleranciasTipoService.GetTipos().pipe(first()).subscribe(
      (result: any) => {
        this.toleranciasTipoItems = result.data;
      }
    );

    this.toleranciasTipoService.GetValores().pipe(first()).subscribe(
      (result: any) => {
        this.toleranciasTipoValorItems = result.data;
      }
    );

    this.form = this.formBuilder.group({
      id:  new FormControl(-1),
      nombre: new FormControl('', [Validators.required]),
      idTipoTolerancia: new FormControl(1),
      idTipoValor: new FormControl(1),
      descripcion: new FormControl(''),

      activo: new FormControl(true),

      nombreSimbolo: new FormControl(''),
      simbolo: new FormControl(''),
      simboloBase64: new FormControl('')
    });

    if (!this.isAddMode) {
      this.toleranciasTipoService.getById(String(this.id)).pipe(first()).subscribe(
        (result: any) => {
          if (!result.error) {
            this.form.patchValue(result.data[0]);
            this.imageToShow = result.data[0].simboloBase64;
            this.idTipoTolerancia = result.data[0].idTipoTolerancia;
            this.idTipoValor = result.data[0].idTipoValor;
            debugger
          }
        }
      );
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    this.alertService.clear();

    if (this.form.invalid || this.formIconError) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    this.toleranciasTipoService.insert(this.form.value).subscribe((result) => {
      if (!result.error) {
        //this.router.navigate(['toleranciastipo']);
        this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        this.router.navigate(['toleranciastipo']);
      } else {
        this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
        this.loading = false;
      }
    });
  }

  private update() {
    this.form.value.id = this.id;
    this.toleranciasTipoService.update(this.form.value, this.id).subscribe((result) => {
      if (!result.error) {
        // this.router.navigate(['toleranciastipo']);
        this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        this.router.navigate(['toleranciastipo']);
      } else {
        this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
        this.loading = false;
      }
    });
  }

  public guardar() {
    this.onSubmit();
  }

  public atras() {
    this.router.navigate(['toleranciastipo']);
  }

  //#region COMBOBOX
  public toleranciasTipo_selectionChange(value: any): void {
    this.form.controls['idTipoTolerancia'].setValue(value.id);
  }
  public toleranciasTipoValor_selectionChange(value: any): void {
    this.form.controls['idTipoValor'].setValue(value.id);
  }
  //#endregion

  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.form.controls['nombreSimbolo'].setValue(e.files[0].name);

    this.formIconError = e.files[0].validationErrors != null;
    // console.log(this.formIconError);

    setTimeout(async function () {
      var simboloBase64: any = "";
      if (th.form.value.simbolo !== "" && th.form.value.simbolo !== null) simboloBase64 = await th.toBase64(th.form.value.simbolo[0]);
      else simboloBase64 = "";
      th.form.controls['simboloBase64'].setValue(simboloBase64);
      th.imageToShow = simboloBase64;
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreSimbolo'].setValue("");
    this.form.controls['simboloBase64'].setValue("");
    this.imageToShow = "";
    this.formIconError = false;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });


}
