import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OFService, MenuService, PiezasService, InformeProyectosService, UsuariosService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { UploadEvent, FileInfo } from '@progress/kendo-angular-upload';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-OF',
  templateUrl: './OFs.html',
})
export class OFsComponent implements OnInit {
  user = this.userService.userValue;
  public myFiles: Array<FileInfo> = [];
  public mySelection: number[] = [];
  public vertodos: boolean;
  public vernoterminados: boolean;
  public verterminados: boolean;
  tituloPopUp: any;
  private translate: TranslateService;
  public OF: any[];
  public OFaaa: any[];
  public piezas: any[];
  public operaciones: any[];
  public ofsseleccionado: any = [];
  modalReference: NgbModalRef;
  public loadingGraficoTresEjes: any = false;
  modalReferenceloading: NgbModalRef;
  modalReferenceloadingupload: NgbModalRef;
  navigationSubscription;
  closeResult = '';
  private _kendoFiles: FileInfo[];
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private ofService: OFService, private piezasService: PiezasService, private userService: UsuariosService,
    translate: TranslateService, public router: Router, private modalService: NgbModal, 
    protected alertService: AlertService, private menuService: MenuService, private translateService: TranslateService,
    private informeProyectosService: InformeProyectosService, public myFunctions: MyFunctions) {
    this.translate = translate;
    this.menuService.titulo = this.translateService.instant('proyectos').toUpperCase();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/OF') {         
          this.cargarDatos();
        }
        }
    });  
  }
  ngOnInit() {
    this.vertodos = false;
    this.vernoterminados = true;
    this.verterminados = false;
    this.tituloPopUp = document.getElementById("text_loading_popup");
    this.ofsseleccionado = [];
    this.OF = [];

    var an: any = [
      { id: "1", nombre: "A", grupo:"bocal" },
      { id: "2", nombre: "B", grupo:"consonante" },
      { id: "3", nombre: "W", grupo:"consonante" },
      { id: "4", nombre: "Z", grupo:"bocal" }
    ];
  } 
 
  /*tratarDatos() {
    this.loadingGraficoTresEjes = false;
    for (var i = 0; i <= this.OF.length - 1; i++) {

      var idof = this.OF[i].id;

      var piezas = this.piezas.filter(element => element.idOF == idof);

      this.OF[i].piezas = piezas;

      for (var j = 0; j <= piezas.length - 1; j++) {

        var idpieza = piezas[j].idPieza;
        var operaciones = this.operaciones.filter(element => element.idPieza == idpieza);

        piezas[j].operaciones = operaciones;
      }

    }
  }*/

  //public selectAllState: SelectAllCheckboxState = 'unchecked';

 /*  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    if (checkedState === 'checked') {
      this.ofsseleccionado = [];
      this.selectAllState = 'checked';
      this.OF.forEach(
        operacion => {
          this.ofsseleccionado.push(operacion.parteID)
        });
    } else {
      this.ofsseleccionado = [];
      this.selectAllState = 'unchecked';
    }
  } */

  cargarDatos() {
    this.loadingGraficoTresEjes = true;
    if (typeof (this.vertodos) == "undefined" || this.vertodos == null || typeof (this.verterminados) == "undefined" || this.verterminados == null) {
      this.vertodos = false;
      this.vernoterminados = true;
      this.verterminados = false;
    }
    this.ofService.GetfPieszasOfs(this.vertodos, this.verterminados).subscribe((result) => {
      this.OF = result.piezas;
      this.OF.forEach(element=>{
        if(element.parteID==null)
          console.log("fallo");
        element.idAuxiliar = "of"+element.idOF+":pieza"+element.piezaID+":parte"+element.parteID;
      });
      this.loadingGraficoTresEjes = false;
    },
      (error) => {
        this.loadingGraficoTresEjes = false;
      }
    );
  }


  onUploadButtonClick(e: UploadEvent, content) {

    this._kendoFiles = e.files;
    this.myFiles = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.ofService.importar(formData).subscribe((result)=>{
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
      this.myFiles = [];
      this.cargarDatos();
    });
  }

  

  /* cellPiezasClick(e) {
    this.router.navigate(['piezas/editar/' + e.dataItem.idOF + "/" + e.dataItem.idPieza+"/"+e.dataItem.idParte]);
  }

  cellOperacionesClick(e) {

    this.router.navigate(['operaciones/editar/' + e.dataItem.idOF + "/" + e.dataItem.idPieza + "/" + e.dataItem.idParte + "/" + "/" + e.dataItem.idRuta+"/"+e.dataItem.id]);
   
  } */

  /*private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }*/


  onClickEditar() { 
    var auxOF = this.ofsseleccionado[0].split(":pieza")[0].split("of")[1];
    if (Number(auxOF) > 0) {
      this.router.navigate(['OF/editar/', Number(auxOF)]);
    }
  }
  
  cellClick(e) {
    var auxOF = e.dataItem.idAuxiliar.split(":pieza")[0].split("of")[1];
    if (Number(auxOF) > 0) {
      this.router.navigate(['OF/editar/', Number(auxOF)]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['OF/crear/']);
  }

  onClickEliminar(content) {
    if (this.ofsseleccionado.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private Eliminar(contentloading) {
    var auxidsOf = [];
    var auxidsPieza = [];
    var auxidsParte = [];
    this.ofsseleccionado.forEach(element =>{
      //Cogemos todos los ids que necesitemos primero y los guardamos en sus listas
      var auxOF =element.split(":pieza")[0].split("of")[1];
      var auxPieza = element.split(":pieza")[1].split(":parte")[0];
      var auxParte = element.split(":parte")[1];
      if(auxOF != "null" && auxOF!="undefined" && auxOF!="0")auxidsOf.push(auxOF);
      if(auxPieza != "null" && auxPieza!="undefined" && auxPieza!="0")auxidsPieza.push(auxPieza);
      if(auxParte != "null" && auxParte!="undefined" && auxParte!="0")auxidsParte.push(auxParte);
    });
    if(auxidsParte.length >0){
      //Hay que borrar la parte y sus piezas asociadas
      this.piezasService.eliminarpartes(auxidsParte.join(",")).subscribe((data) => {
        this.modalReferenceloading.close();
        if (data.error == false) {
          //mirar si quedan partes asociadas. Los ids de las piezas seleccionadas ser�n los asociados a esas partes,
          //y si se han borrado todas borrar las piezas
          this.piezasService.existenpartes(auxidsPieza.join(",")).subscribe((data) => {
            if(data.existen== false){ //si no existen m�s partes asociadas a esa pieza, borrar las piezas, pues si no tiene ni la parte obligatoria es que era una pieza
               this.piezasService.eliminarpiezas(auxidsPieza.join(",")).subscribe((data) => {
                if (data.error == false) {
                  //mirar si existen m�s piezas en ese of, si no existen m�s, se debe eliminar el of
                  this.piezasService.existenpiezas(auxidsOf.join(",")).subscribe((data) => {
                    if(data.existen== false){ //Si no existen m�s piezas asociadas, entonces era la �ltima pieza y hay que borrar el of tambi�n
                      this.ofService.eliminarTodosOf(auxidsOf.join(",")).subscribe((data: any)=>{
                        this.modalReferenceloading.close();
                        if (data.error == false) {
                          this.cargarDatos();
                          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                        } else {
                          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
                        }
                      });
                    }else{
                      this.cargarDatos();
                      this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                    }
                  });
                } else {
                  this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
                }
              });
            }else{
              this.cargarDatos();
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            }
          });
        } else {
          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
        }
      });
    // }else if(auxidsPieza.length>0){
    //   //aqu� nunca va a entrar, pero lo dejamos por debuggeo. Una pieza SIEMPRE tendr� una parte
    }else if(auxidsOf.length>0){
      //hay que borrar el of, no es necesario comprobar que no tenga piezas o partes asociadas, pues sino habr�a entrado en los ifs previos a borrarlos
      //es raro que suceda esto, pero por si acaso
      this.ofService.eliminarTodosOf(auxidsOf.join(",")).subscribe((data: any)=>{
        this.modalReferenceloading.close();
        if (data.error == false) {
          this.cargarDatos();
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        } else {
          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
        }
      });
    }
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  /*btnTerminadosNoTerminados() {
    this.verterminados = false;
    this.vernoterminados = false;
    this.vertodos = true;
    this.cargarDatos();
  }
  btnTerminados() {
    this.verterminados = true;
    this.vernoterminados = false;
    this.vertodos = false;
    this.cargarDatos();
  }
  btnNoterminados() {
    this.verterminados = false;
    this.vernoterminados = true;
    this.vertodos = false;
    this.cargarDatos();
  }*/
  //BOTONES CHECKBOX
  public onValueChangeSwitch(value: any): void {
    this.verterminados = false;
    this.vernoterminados = true;
    this.vertodos = false;
    if (value) {
     
      this.vernoterminados = true;
      this.vertodos = true;
      
    }
    this.cargarDatos();
   
  }

  btnVerTodos() {
  
    if (!this.vertodos) {
      this.verterminados = false;
      this.vernoterminados = true;
      this.vertodos = true;

    }
    else {
      this.verterminados = false;
      this.vernoterminados = true;
      this.vertodos = false;
    }
    this.cargarDatos();
  }
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

}
