<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-9">

      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'fabricante' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <!--NOMBRE-->
              <div class="form-group">
                <kendo-label text="{{ 'nombre' | translate }}">
                  <div class="caja">
                    <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>

          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.herramientas <2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
