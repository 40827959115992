import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { MenuService, NotasService, OFService, UsuariosService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { MaquinasService } from '@app/_services';
import * as c3 from 'c3';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'informeTemplado-consumibles',
  templateUrl: './informeTemplado.component.html',
})

export class InformeTempladoComponent {

  //VARIABLES BASICAS
  private translate: TranslateService;

  //Elementos actuales
  public ofActual: any = '-';
  public piezaActual: any = '-';
  public parteActual: any = '-';
  public rutaActual: any = '-';
  public operacionActual: any = '-';
  public fechainiActual: any = '-';
  public fechafinActual: any = '-';
  public hornoActual: any = '-';
  public operarioActual: any = '-';
  public tratamientoActual: any = '-';

  //Datos del grafico
  public datosTemperatura: any = []
  public datosTiempo: any = []

  //Abrir o cerrar filtro
  status: boolean = false;

  //Listas
  public listaOFs: any;
  public listaPiezas: any;
  public listaPartes: any;
  public listaRutas: any;
  public listaOperaciones: any;

  //Form para validar
  public form: FormGroup = new FormGroup({
    OFForm: new FormControl(),
    piezaForm: new FormControl(),
    parteForm: new FormControl(),
    rutaForm: new FormControl(),
    operacionForm: new FormControl(),
  });

  //Seleciconados
  public OF: any;
  public pieza: any;
  public parte: any;
  public ruta: any;
  public operacion: any;

  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    private notasService: NotasService,
    private ofService: OFService,
    private usuariosService: UsuariosService,
    private maquinasService: MaquinasService) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('informeTemplado').toUpperCase();
  }

  //Guardamos el usuario actual
  user = this.usuariosService.userValue;

  ngOnInit() {

    //Si llegamos de historico operaciones
    if (this.route.snapshot.params['id'] != undefined) {
      this.operacion = { id: this.route.snapshot.params['id'] } //Fijamos operacion actual
      this.filtrar();
    }

    //Ajustamos el form
    this.form.controls.piezaForm.disable();
    this.form.controls.parteForm.disable();
    this.form.controls.rutaForm.disable();
    this.form.controls.operacionForm.disable();

    //Cargamos OFs
    this.ofService.GetCombo().subscribe(
      (result) => {
        this.listaOFs = result;
      });
  }

  /**
   * Crea el grafico de la temperatura respecto al tiempo transcurrido
   */
  crearGrafico() {

    //Guardamos el valor de this para poder usarlo dentro de la funcion
    var that = this;

    //Funcion de generar grafico (C3)
    c3.generate({

      bindto: '#grafico', //Lo unimos a nuestro div
      //Los datos para el grafico
      data: {
        x: 'Fecha y hora',
        json: {
          "Fecha y hora": this.datosTiempo,
          "Temperatura": this.datosTemperatura
        },
        types: {
          Temperatura: 'area', //Tipo area, para rellenar debajo
        },
        xFormat: '%Y-%m-%d %H:%M:%S.000' //El formato de la fecha que llega
      },
      //Cambiamos el color
      color: {
        pattern: ["#93bae3"]
      },
      //Propiedades de los ejes
      axis: {
        //X
        x: {
          type: 'timeseries', //Tipo timeseries para un ajuste automatico en el eje x por tiempo
          tick: {
            format: '%H:%M', //El formato de la fecha formateada
            fit: true,
          },
          padding: 0 //Quitamos el padding para que comience de 0
        },
        //Y
        y: {
          //Texto del eje y
          label: {
            text: "Temperatura (ºC)",
            position: 'outer-middle'
          },
          //Los valores que pasamos para cada punto
          tick: {
            format: function (d) {
              return (parseInt(d) == d) ? d : null;
            }
          },
          //Ajustamos el grafico con el zoom de +/-100 al max y min de temperatura
          max: Math.max.apply(Math, this.datosTemperatura),
          min: Math.min.apply(Math, this.datosTemperatura),
          padding: { top: 100, bottom: 100 }
        }
      },
      //Personalizamos el tooltip
      tooltip: {
        format: {
          //El titulo
          title: function (d) {
            return that.myFunctions.dateToYYYYMMDDHHmmSSconSeparacion(d);
          },
          //El valor interno
          value: function (v) {
            return v + ' ºC';
          }
        }
      },
      //Escondemos la leyenda
      legend: {
        show: false
      },
      //Escondemos los puntos
      point: {
        show: false,
      }
    });
  }

  /**
   * Al cambiar la seleccion del combo de OF,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de OF seleccionado
   */
  OFChange(value) {
    this.OF = value;
    this.form.controls.piezaForm.reset();
    this.form.controls.parteForm.reset();
    this.form.controls.rutaForm.reset();
    this.form.controls.operacionForm.reset();

    this.form.controls.piezaForm.enable();
    this.form.controls.parteForm.disable();
    this.form.controls.rutaForm.disable();
    this.form.controls.operacionForm.disable();

    this.notasService.GetPiezasByOF(value.value).subscribe(
      (result) => {
        this.listaPiezas = result;

        //En caso de que la pieza no tenga nombre
        this.listaPiezas.forEach(p => {
          if (p.nombre == '') p.nombre = this.translateService.instant('sinnombre');
        });

        //Si en el siguiente combo hay solo una opcion, la cargamos
        if (this.listaPiezas.length == 1) {
          this.piezaChange(this.listaPiezas[0]);
          this.form.controls.piezaForm.disable();
        }
      });
  }

  /**
   * Al cambiar la seleccion del combo de pieza,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la pieza seleccionada
   */
  piezaChange(value) {
    this.pieza = value;
    this.form.controls.parteForm.reset();
    this.form.controls.rutaForm.reset();
    this.form.controls.operacionForm.reset();

    this.form.controls.parteForm.enable();
    this.form.controls.rutaForm.disable();
    this.form.controls.operacionForm.disable();

    this.notasService.GetPartesByPieza(value.id).subscribe(
      (result) => {
        this.listaPartes = result;

        //En caso de que la pieza no tenga nombre
        this.listaPartes.forEach(p => {
          if (p.nombre == '') p.nombre = this.translateService.instant('sinnombre');
        });

        //Si en el siguiente combo hay solo una opcion, la cargamos
        if (this.listaPartes.length == 1) {
          this.parteChange(this.listaPartes[0]);
          this.form.controls.parteForm.disable();
        }
      });
  }

  /**
   * Al cambiar la seleccion del combo de parte,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la parte seleccionada
   */
  parteChange(value) {
    this.parte = value;
    this.form.controls.rutaForm.reset();
    this.form.controls.operacionForm.reset();

    this.form.controls.rutaForm.enable();
    this.form.controls.operacionForm.disable();

    this.notasService.GetRutasByParte(value.id).subscribe(
      (result) => {
        this.listaRutas = result;

        //En caso de que la pieza no tenga nombre
        this.listaRutas.forEach(r => {
          if (r.nombre == '') r.nombre = this.translateService.instant('sinnombre');
        });

        //Si en el siguiente combo hay solo una opcion, la cargamos
        if (this.listaRutas.length == 1) {
          this.rutaChange(this.listaRutas[0]);
          this.form.controls.rutaForm.disable();
        }
      });
  }

  /**
   * Al cambiar la seleccion del combo de ruta,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguiente
   * @param value valor de la ruta seleccionada
   */
  rutaChange(value) {
    this.ruta = value;
    this.form.controls.operacionForm.reset();
    this.form.controls.operacionForm.enable();

    this.notasService.GetOperacionesByRuta(value.id).subscribe(
      (result) => {
        this.listaOperaciones = result;

        //En caso de que la pieza no tenga nombre
        this.listaOperaciones.forEach(o => {
          if (o.nombre == '') o.nombre = this.translateService.instant('sinnombre');
        });

        //Si en el siguiente combo hay solo una opcion, la cargamos
        if (this.listaOperaciones.length == 1) {
          this.operacionChange(this.listaOperaciones[0]);
          this.form.controls.operacionForm.disable();
        }
      });
  }

  /**
   * Al cambiar la seleccion del combo de operacion,
   * actualizar el valor de la variable
   * @param value valor de la operacion seleccionada
   */
  operacionChange(value) {
    this.operacion = value;
  }

  /**
   * Filtrar por la operacion seleccionada y generar el grafico
   */
  filtrar() {
    
    //Comprobar los campos obligarios, aunque se hayan cambiado programaticamente
    this.form.markAllAsTouched();
    if (this.form.valid) {

      //Llamamos a la consulta
      this.maquinasService.GetTempladoHornoByProcesoId(this.operacion.id).subscribe(
        result => {
          result = result.data;

          this.ofActual = result[0].of;
          this.piezaActual = result[0].pieza;
          this.parteActual = result[0].parte;
          this.rutaActual = result[0].ruta;
          this.operacionActual = result[0].operacion;
          this.fechainiActual = result[0].inicio;
          this.fechafinActual = result[0].fin;
          this.hornoActual = result[0].horno;
          this.operarioActual = result[0].nombreOperario;
          this.tratamientoActual = result[0].tratamiento;

          this.datosTemperatura = [];
          this.datosTiempo = [];

          result.forEach(e => {
            this.datosTemperatura.push(e.temperatura);
            this.datosTiempo.push(this.myFunctions.sqlToJsDateT(e.fecha));
          });

          //Escondemos filtro
          this.status = true;

          this.crearGrafico();
        });
    }
  }
}
