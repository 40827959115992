import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { MenuService, NotasService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { FabricantesService } from '@app/_services';
import { AlmacenesService } from '@app/_services/almacenes.service';
import { MyFunctions } from '@app/_helpers';
import { FormControl, FormGroup } from "@angular/forms";
import { UsuariosService } from '@app/_services';

@Component({
  selector: 'app-notas-crear',
  templateUrl: './notas-crear.component.html',
})

export class NotasCrearComponent {

  //Variables basicas
  private translate: TranslateService;
  public permanente: boolean = false;
  public mostrarHastaPieza: boolean = false;
  public mostrarTodo: boolean = false;
  public mostrarRef: boolean = false;

  //Variables cascada
  public referenciaDisabled: boolean = true;
  public piezaDisabled: boolean = true;
  public parteDisabled: boolean = true;
  public rutaDisabled: boolean = true;
  public operacionDisabled: boolean = true;

  //Seleciconados
  public tipoRef: any;
  public referencia: any;
  public OF: any;
  public pieza: any;
  public parte: any;
  public ruta: any;
  public operacion: any;

  //Listas
  public listaTipos: any;
  public listaReferencias: any;
  public listaOFs: any;
  public listaPiezas: any;
  public listaPartes: any;
  public listaRutas: any;
  public listaOperaciones: any;

  //Usuario
  public user = this.usuariosService.userValue;

  //Form para validar
  public formNota: FormGroup = new FormGroup({
    tipoRefForm: new FormControl(),
    referenciaForm: new FormControl(),
    OFForm: new FormControl(),
    piezaForm: new FormControl(),
    parteForm: new FormControl(),
    rutaForm: new FormControl(),
    operacionForm: new FormControl(),
    comentarioForm: new FormControl(),
  });

  //Constructor
  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    public route: ActivatedRoute,
    public fabricantesService: FabricantesService,
    public almacenesService: AlmacenesService,
    private usuariosService: UsuariosService,
    private notasService: NotasService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('Crear nota').toUpperCase();
  }

  /**
   * Funcion ngOnInit, cargar los datos de tipo y OF
   */
  ngOnInit() {
    this.notasService.GetTipos().subscribe(
      (result) => {
        this.listaTipos = result;
      });

    this.notasService.GetOFs().subscribe(
      (result) => {
        this.listaOFs = result;
      });
    this.permanente = false;
    this.mostrarHastaPieza = false;
    this.mostrarTodo = false;
    this.mostrarRef = false;
  }

  /**
   * Al clicar en el boton para crear la nota, insertar esta en la DB
   */
  onSubmit() {
    this.formNota.markAllAsTouched();
    if (this.formNota.valid) {
      if (this.tipoRef.id == 3) this.referencia = this.operacion;
      if (this.tipoRef.id == 4) this.referencia = this.pieza;
      this.notasService.InsertNota(this.tipoRef.id, this.referencia.id, this.user.id, this.formNota.controls.comentarioForm.value, this.permanente).subscribe(
        (result) => {
          console.log("nota añadida");
          this.router.navigate(['notas']);
        });
    }
  }

  /**
   * Al cambiar el combo de tipo de referencia,
   * deshabilitar y resetear los combos correspondientes
   * y cargar el siguente
   * @param value valor del tipo de referencia seleccionada
   */
  tipoChange(value) {
    this.mostrarHastaPieza = false;
    this.mostrarTodo = false;
    this.mostrarRef = false;
    this.tipoRef = value;

    this.formNota.controls.referenciaForm.reset();
    this.formNota.controls.OFForm.reset();
    this.formNota.controls.piezaForm.reset();
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    if (this.tipoRef.id == 3) {
      this.formNota.controls.referenciaForm.disable();
    } else {
      this.formNota.controls.referenciaForm.enable();
    }

    this.formNota.controls.piezaForm.disable();
    this.formNota.controls.parteForm.disable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();

    if (this.tipoRef.id == 1 || this.tipoRef.id == 2 || this.tipoRef.id == 5) {
      this.notasService.GetReferencia(value.id).subscribe(
        (result) => {
          this.listaReferencias = result;
        });
      this.mostrarRef = true;
    }
    if (this.tipoRef.id == 4) this.mostrarHastaPieza = true;
    if (this.tipoRef.id == 3) this.mostrarTodo = true;
  }

  /**
   * Al cambiar la seleccion del combo de la referencia,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la referencia seleccionada
   */
  referenciaChange(value) {
    this.referencia = value;

    this.formNota.controls.OFForm.reset();
    this.formNota.controls.piezaForm.reset();
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.piezaForm.disable();
    this.formNota.controls.parteForm.disable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();
  }

  /**
   * Al cambiar la seleccion del combo de OF,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de OF seleccionado
   */
  OFChange(value) {
    this.OF = value;
    this.formNota.controls.piezaForm.reset();
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.piezaForm.enable();
    this.formNota.controls.parteForm.disable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();

    this.notasService.GetPiezasByOF(value.id).subscribe(
      (result) => {
        this.listaPiezas = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de pieza,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la pieza seleccionada
   */
  piezaChange(value) {
    this.pieza = value;
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.parteForm.enable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();

    this.notasService.GetPartesByPieza(value.id).subscribe(
      (result) => {
        this.listaPartes = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de parte,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la parte seleccionada
   */
  parteChange(value) {
    this.parte = value;
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.rutaForm.enable();
    this.formNota.controls.operacionForm.disable();

    this.notasService.GetRutasByParte(value.id).subscribe(
      (result) => {
        this.listaRutas = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de ruta,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguiente
   * @param value valor de la ruta seleccionada
   */
  rutaChange(value) {
    this.ruta = value;
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.operacionForm.enable();

    this.notasService.GetOperacionesByRuta(value.id).subscribe(
      (result) => {
        this.listaOperaciones = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de operacion,
   * actualizar el valor de la variable
   * @param value valor de la operacion seleccionada
   */
  operacionChange(value) {
    this.operacion = value;
  }
}