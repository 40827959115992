<!-- BARRA SUPERIOR -->
<header id="goiMenua" class="navbar barratop" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}" *ngIf="user">
  <!--<header id="goiMenua" class="navbar barratop barratop-danobat" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}" *ngIf="user"> -->

  <nav class="navbar navbar-expand-sm w-100 p-0">
    <h2 *ngIf="!this.router.url.includes('homeplantaisometrico')"><span>{{menuService.titulo}}</span></h2>

    <!--LISTA DE PLANTAS (SOLO SI ESTAMOS EN LA PAGINA DE PLANTAS)-->
    <ul class="navbar-nav navbar-nav-user usermenu-iso container-planta"
      *ngIf="this.router.url.includes('homeplantaisometrico')">
      <li class="dropdown selector-planta">
        <span class="planta-seleccionado" data-toggle="dropdown">
          <i class="fas fa-list-ul icono-plantas"></i>
          {{ selectedPlanta.nombre }}
        </span>
        <ul class="dropdown-menu dropdown-planta">

          <li class="nav-item listaPlantaItem" [routerLinkActive]="['link-active']" *ngFor="let planta of listaPlantas"
            [ngClass]="{'listaPlantaItemSeleccionado': planta.id == selectedPlanta.id}">
            <a class="nav-link " [routerLink]="['/homeplantaisometrico', planta.id]">
              <i class="fas fa-chevron-right"></i>
              {{ planta.nombre }}
            </a>
          </li>

        </ul>
      </li>
    </ul>

    <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{ show: isExpanded }">
      <ul class="navbar-nav navbar-nav-user usermenu">

        <!--NOMBRE EMPRESA-->
        <li>
          <label class="nombreempresaheader">{{ nombreEmpresa.toUpperCase() }}</label>
        </li>

        <!--GRUPOS DE MAQUINAS (SOLO EN EL HOME)-->
        <li id="cgrupos" class="dropdown selector-grupo"
          *ngIf="this.router.url=='/home' || this.router.url=='/' || this.router.url=='/homev2'">
          <span id="LgrupoActual" class="grupo-seleccionado" data-toggle="dropdown">{{ selectedGrupo.nombre }}</span>
          <ul class="dropdown-menu">
            <ng-container *ngFor="let grupo of listaGrupos">
              <li id="menuGrupo"
                [ngClass]="{'listaGrupoItemSeleccionado': grupo==selectedGrupo, 'listaGrupoItem': grupo!=selectedGrupo}"
                (click)="clickGrupo(grupo)">
                <a>{{ grupo.nombre }}</a>
              </li>
            </ng-container>
          </ul>
        </li>

        <!--IDIOMA -->
        <li id="cidioma" class="dropdown selector-idioma">
          <span id="LidiomaActual" class="idioma-seleccionado" data-toggle="dropdown">{{ 'idiomaSeleccionado' |
            translate}}</span>
          <ul class="dropdown-menu">
            <li id="menuIdiomasES"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='es', 'listaLenguajeItem': this.user.idioma!='es'}"
              (click)="useLanguage('es')">
              <a id="HLes">ES</a>
            </li>
            <li id="menuIdiomasEU"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='eu', 'listaLenguajeItem': this.user.idioma!='eu'}"
              (click)="useLanguage('eu')">
              <a id="HLeu">EU</a>
            </li>
            <li id="menuIdiomasEN"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='en-GB', 'listaLenguajeItem': this.user.idioma!='en-GB'}"
              (click)="useLanguage('en-GB')">
              <a id="HLen">EN</a>
            </li>
            <li id="menuIdiomasDE"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='de', 'listaLenguajeItem': this.user.idioma!='de'}"
              (click)="useLanguage('de')">
              <a id="HLde">DE</a>
            </li>
            <li id="menuIdiomasFR"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='fr', 'listaLenguajeItem': this.user.idioma!='fr'}"
              (click)="useLanguage('fr')">
              <a id="HLfr">FR</a>
            </li>
            <li id="menuIdiomasIT"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='it', 'listaLenguajeItem': this.user.idioma!='it'}"
              (click)="useLanguage('it')">
              <a id="HLit">IT</a>
            </li>
            <li id="menuIdiomasPT"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='pt', 'listaLenguajeItem': this.user.idioma!='pt'}"
              (click)="useLanguage('pt')">
              <a id="HLpt">PT</a>
            </li>
            <li id="menuIdiomasRU"
              [ngClass]="{'listaLenguajeItemSeleccionado': this.user.idioma=='ru', 'listaLenguajeItem': this.user.idioma!='ru'}"
              (click)="useLanguage('ru')">
              <a id="HLpt">RU</a>
            </li>
          </ul>
        </li>

        <!--TUTORIALES -->
        <!--<li style="font-size: 24px;">
          <a class="nombre-usuario" title="Tutorial" [routerLink]="['/tutorial']"><span class="circulo-tutorial">?</span></a>
        </li>-->
        <li>
          <a id="HLtutoriales" title="Tutorial" class="tutoriales-nav" [routerLink]="['/tutorial']">?</a>
        </li>

        <!--MENÚ USUARIO -->
        <li class="dropdown">
          <a data-toggle="dropdown" class="nombre-usuario"><span class="circulo-usuario">{{ iniciales.toUpperCase()
              }}</span></a>

          <div class="dropdown-menu dropdown-usuario">
            <div class="inner-usuario-nombre-cont clearfix" [routerLink]="['/profile']">
              <span class="inner-usuario-circulo-usuario">{{ iniciales.toUpperCase() }}</span>
              <span id="Lnombre" class="inner-usuario-nombre"> {{ user.nombre }} {{ user.apellido1 }}</span>
            </div>

            <div class="clearfix">
              <div class="inner-usuario-tipo-cont">
                <span id="" class="inner-usuario-titulo">{{ 'tipousuario' | translate}}</span>
                <span id="Ltipo" class="inner-usuario-tipo">{{ user.usuarioTipo }}</span>
              </div>

              <div class="inner-usuario-num-cont">
                <span id="" class="inner-usuario-titulo">{{ 'numOperario' | translate}}:</span>
                <span id="Lnum" class="inner-usuario-num">{{ user.numOperario }}</span>
              </div>
            </div>

            <div class="clearfix">
              <div class="cont-areas-productivas-menu">
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion" [textField]="'nombre'"
                  [valueField]="'id'" [autoClose]="false" (close)="seccionChanged()"
                  [(ngModel)]="seccionesSeleccionadas" [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>

            <div class="clearfix">
              <div class="cont-areas-productivas-menu">
                <kendo-dropdownlist [data]="timeZonesList" [textField]="'timeZoneSQL'" [valueField]="'id'"
                  [autoClose]="false" (valueChange)="timezoneChanged()" [(ngModel)]="timezoneSeleccionado"
                  [kendoDropDownFilter]="{operator: 'contains'}" style="width:100%;">
                  <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span *ngIf="dataItem!=undefined" style="vertical-align: text-top; margin-left: 5px;">{{
                      dataItem.timeZoneSQL }} ({{ dataItem.desvioUTC }})</span>
                  </ng-template>
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span>
                      <span class="k-icon k-i-clock"></span>
                      <span *ngIf="dataItem!=undefined" style="vertical-align: text-top; margin-left: 5px;">{{
                        dataItem.timeZoneSQL }} ({{ dataItem.desvioUTC }})</span>
                    </span>
                  </ng-template>
                </kendo-dropdownlist>
              </div>
            </div>

            <div class="clearfix">
              <div class="clearfix"><span class="inner-usuario-titulo">Skin</span></div>
              <div class="inner-usuario-itxura-cont">
                <div (click)="temaArgia()" class="itxura-aldaketa-argia"
                  [ngClass]="{'aukeratuta':tema == 1, '':tema != 1}"></div>
              </div>
              <div class="inner-usuario-itxura-cont">
                <div (click)="temaIluna()" class="itxura-aldaketa-iluna"
                  [ngClass]="{'aukeratuta':tema == 2, '':tema != 2}"></div>
              </div>
            </div>

            <div class="cont-linea">
              <div class="inner-usuario-logout-cont">
                <a (click)="logout()" class="btn btn-danger w-100 salir">{{ 'cerrarsesion' | translate}}</a>
              </div>
            </div>

          </div>
        </li>

      </ul>
    </div>
  </nav>
</header>


<!-- BARRA VERTICAL -->
<div id="ezkerMenua" class="barramenu" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded }" *ngIf="user">
  <!--<div id="ezkerMenua" class="barramenu barramenu-danobat" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded }" *ngIf="user"> -->

  <div class="logotipoa"></div>

  <div id="botonExpandir" (click)="expandir()" class="menu-zabalera-blokeatu"></div>

  <!-- SOLO PARA DANOBAT -->
  <!--<img class="logodanobat" src="assets/danobat-logo.png" /> -->

  <h3>{{ 'principal' | translate}}</h3>

  <ul class="nav flex-column mb-0">

    <li class="nav-item" *ngIf="this.usuariosService.userValue.home">
      <a [routerLink]="['/home']" class="nav-link mecanizado-nav">{{ 'inicio' | translate}}</a>
    </li>

    <!--<li class="nav-item">
      <a [routerLink]="['/homeplantaisometrico']" class="nav-link isometrico-nav">
        {{ 'planta' | translate}}
      </a>
    </li>-->

    <li class="dropdown" *ngIf="this.usuariosService.userValue.home">
      <a id="" class="nav-link isometrico-nav" data-toggle="dropdown">{{ 'planta' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngFor="let planta of listaPlantas">
          <a class="nav-link " [routerLink]="['/homeplantaisometrico', planta.id]">{{ planta.nombre }}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/home_tv']">{{ 'hometv' | translate}}</a>
        </li>
      </ul>
    </li>

    <li class="dropdown" *ngIf="this.usuariosService.userValue.proyectosH">
      <a id="" class="nav-link proyectos-nav" data-toggle="dropdown">{{ 'proyectos' | translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.proyectos">
          <a class="nav-link " [routerLink]="['/OF']">{{ 'proyectos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.operacionesPredefinidas">
          <a class="nav-link " [routerLink]="['/operacionespredefinidas']">{{ 'operacionespredefinidas' |
            translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.piezasPredefinidas">
          <a class="nav-link " [routerLink]="['/piezaspredefinidas']">{{ 'piezaspredefinidas' | translate}}</a>
        </li>
        <!--Asignación de tiempos-->
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.controlDeAsignaciones">
          <a class="nav-link informes-calidad-nav" [routerLink]="['/asignacionTiempos']">{{ 'asignacionTiempos' |
            translate}}</a>
        </li>
      </ul>
    </li>


    <li class="dropdown" *ngIf="this.usuariosService.userValue.seguimiento">
      <a id="" class="nav-link proyectos-nav seguimiento-nav" data-toggle="dropdown">{{ 'seguimiento' | translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.validador">
          <a class="nav-link" [routerLink]="['/validador']">{{ 'validador' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.bonos">
          <a class="nav-link" [routerLink]="['/bonos']">{{ 'bonos' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.controlDeAsignaciones">
          <a class="nav-link " [routerLink]="['/controlasignaciones']">{{ 'controlAsignaciones' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.historicoPiezas">
          <a class="nav-link " [routerLink]="['/historicoPiezas']">{{ 'historicoPiezas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.historicoPiezas">
          <a class="nav-link " [routerLink]="['/historicoalarmas']">{{ 'historicoalarmas' | translate}}</a>
        </li>
        <!--  <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/historicoparametros']">{{ 'historicoparametros' | translate}}</a>
        </li> -->
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="false">
          <a class="nav-link " [routerLink]="['/seguimientosproyectos']">{{ 'seguimientosproyectos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.historicoPiezas">
          <a class="nav-link " [routerLink]="['/historicodatos']">{{ 'historicoDatos' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <!--*ngIf="this.usuariosService.userValue.notas"-->
          <a class="nav-link " [routerLink]="['/notas']">{{ 'notas' | translate}}</a>
        </li>

      </ul>
    </li>

    <li class="dropdown" *ngIf="this.usuariosService.userValue.planificador">
      <a id="" class="nav-link planificador-nav dropdown-toggle" data-toggle="dropdown">{{ 'planificador' |
        translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.vistoLargoTodos">
          <a class="nav-link " [routerLink]="['/planificadorLargoMaquinas/0']">{{ 'largo' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.planificadorCorto">
          <a class="nav-link " [routerLink]="['/planificadorCorto']">{{ 'corto' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/planificadorLista/1/3/0']">{{ 'lista' | translate}}</a>
        </li>

        <!-- <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/planificadorHerramientas/1/true/false']">{{ 'herramientas' |
            translate}}</a>
        </li> -->

      </ul>
    </li>


    <li class="dropdown" *ngIf="this.usuariosService.userValue.informes">
      <a id="" class="nav-link inf-ejecucion" data-toggle="dropdown">{{ 'informes' | translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.analisisDeRendimiento">
          <a class="nav-link " [routerLink]="['/informerendimiento']">{{ 'analisisRendimiento' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.comparativaRendimiento">
          <a class="nav-link " [routerLink]="['/informecomparativarendimiento']">{{ 'comparativaRendimiento' |
            translate}}</a>
        </li>

        <!-- <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.comparativaRendimiento">
          <a class="nav-link " [routerLink]="['/comparativaTiemposBoxplot']">{{ 'comparativa-boxplot' | translate}}</a>
        </li> -->

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informeEjecuciones">
          <a class="nav-link " [routerLink]="['/informeejecuciones']">{{ 'informeEjecuciones' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informeTecnicoEjecucion">
          <a class="nav-link " [routerLink]="['/informetecnicoejecucion']">{{ 'informetecnicoejecucion' |
            translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informeDeConsumo">
          <a class="nav-link " [routerLink]="['/informeconsumo']">{{ 'informeConsumo' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/informeproyectos']">{{ 'informeProyectos' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/informeElectrico']">{{ 'informeElectrico' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']" comparativaTiemposBoxplot>
          <a class="nav-link " [routerLink]="['/analiticaavanzadaejecuciones']">{{ 'analiticaAvanzadaEjecuciones' |
            translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/informecomparativaproyectos']">{{ 'informeComparativaProyectos' |
            translate}}</a>
        </li>
        <!--Histograma tiempo pieza-->
        <li class="nav-item">
          <a [routerLink]="['/histogramaTiempoPieza']" class="nav-link informe-calidad-nav">{{ 'histogramaTiempoPieza' |
            translate}}</a>
        </li>
        <!--Informe calidad-->
        <li class="nav-item" *ngIf="false">
          <a [routerLink]="['/informeCalidad']" class="nav-link informe-calidad-nav">{{ 'informeCalidad' |
            translate}}</a>
        </li>
        <!--Comparativa tiempos boxplot-->
        <li class="nav-item" *ngIf="false">
          <!--*ngIf="this.usuariosService.userValue.comparativaTiempos"-->
          <a [routerLink]="['/comparativaTiemposBoxplot']" class="nav-link comparativa-tiempos-boxplot-nav">{{
            'comparativa-boxplot' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/informeContenedores']">{{ 'informeContenedores' | translate}}</a>
        </li>

        <!--Informes boxplot-->
        <!-- <li class="nav-item">
          <a [routerLink]="['/informesPiezasBoxplot']" class="nav-link informes-boxplot-nav">{{ 'informes-boxplot' | translate}}</a>
        </li> -->
        <li *ngFor="let informe of listaInformesPersonalizados" class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/informepersonalizado', informe.id]"
            (click)="redirectToInformespersonalizados(informe.id)">{{ informe.nombre}}</a>
        </li>



      </ul>
    </li>

    <li class="dropdown" *ngIf="this.usuariosService.userValue.oEE">
      <a id="" class="nav-link inf-oee" data-toggle="dropdown">{{ 'oee' | translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.informeOEE">
          <a class="nav-link " [routerLink]="['/informeOEE']">{{ 'tituloOee' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informeDisponibilidad">
          <a class="nav-link " [routerLink]="['/informeDisponibilidadOEE']">{{ 'tituloDisponibilidad' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informeRendimiento">
          <a class="nav-link " [routerLink]="['/informeRendimientoOEE']">{{ 'tituloRendimiento' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.informeCalidad">
          <a class="nav-link " [routerLink]="['/informeCalidadOEE']">{{ 'tituloCalidad' | translate}}</a>
        </li>
      </ul>
    </li>


    <li class="dropdown" *ngIf="this.usuariosService.userValue.planesDeAccionH">
      <a id="" class="nav-link planesdeaccion-nav" data-toggle="dropdown">{{ 'planesDeAccion' | translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.planesDeAccion">
          <a class="nav-link" [routerLink]="['/planesaccion']">{{ 'planesDeAccion' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/repercusiones/maquinas']">{{ 'maquinas' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/repercusiones/perdidas']">{{ 'perdidas' | translate}}</a>
        </li>

      </ul>
    </li>

    <!--DISPONIBILIDAD-->
    <li class="dropdown" *ngIf="this.usuariosService.userValue.disponibilidad">
      <a id="" class="nav-link disponibilidad-nav" data-toggle="dropdown">{{ 'disponibilidad' | translate}}</a>
      <ul class="dropdown-menu">
        <!--Informes de incidencias-->
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informesIncidencias">
          <a class="nav-link " [routerLink]="['/informesincidencias']">{{ 'informesIncidencias' | translate}}</a>
        </li>
        <!--Calendario de disponibilidad-->
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.calendarioDisponibilidad">
          <a class="nav-link " [routerLink]="['/turnosDisponibilidad']">{{ 'calendarioDisponibilidad' | translate}}</a>
        </li>
        <!--Informe de calculo de disponibilidad-->
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.informeCalculoDisponibilidad">
          <a class="nav-link " [routerLink]="['/incormecalculodisponibilidad']">{{ 'informeCalculoDisponibilidad' |
            translate}}</a>
        </li>
      </ul>
    </li>

    <!--ALMACENES-->
    <li class="dropdown" *ngIf="this.usuariosService.userValue.consumibles">
      <a id="" class="nav-link consumibles-nav" data-toggle="dropdown">{{ 'consumibles' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.consumibles">
          <a class="nav-link " [routerLink]="['/fabricantes']">{{ 'fabricantes' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.consumibles">
          <a class="nav-link " [routerLink]="['/almacenes']">{{ 'almacenes' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.consumibles">
          <a class="nav-link " [routerLink]="['/consumiblesTipos']">{{ 'consumiblesTipos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.consumibles">
          <a class="nav-link " [routerLink]="['/consumibles']">{{ 'consumibles' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.consumibles">
          <a class="nav-link " [routerLink]="['/gestionConsumibles']">{{ 'gestionConsumibles' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.consumibles">
          <a class="nav-link " [routerLink]="['/movimientosConsumibles']">{{ 'movimientosConsumibles' | translate}}</a>
        </li>


        <!-- <li class="nav-item k-hidden" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/historicoConsumibles']">{{ 'historicoConsumibles' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/vistaConsumibles']">{{ 'vistaConsumibles' | translate}}</a>
        </li> -->
        <!-- <li class="nav-item k-hidden" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/vistaalmacenes']">{{ 'vistaAlmacenes' | translate}}</a>
        </li>
        <li class="nav-item k-hidden" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/tiposHerramientas']">{{ 'tiposHerramientas' | translate}}</a>
        </li>
        <li class="nav-item k-hidden" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/herramientasunidades']">{{ 'herramientasplacas' | translate}}</a>
        </li>
        <li class="nav-item k-hidden" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/herramientas']">{{ 'herramientasplacastipos' | translate}}</a>
        </li>
        <li class="nav-item k-hidden" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/vistaherramientasplacas']">{{ 'vistaHerramientasPlacas' | translate}}</a>
        </li> -->

      </ul>
    </li>

    <!--Mantenimientos-->
    <li class="dropdown" *ngIf="this.usuariosService.userValue.maquinas">
      <a id="" class="nav-link mantenimientos-nav" data-toggle="dropdown">{{ 'mantenimientos' | translate}}</a>
      <ul class="dropdown-menu">
        <!--Informes de incidencias-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/historicomantenimientos']">{{ 'mantenimientos' | translate}}</a>
        </li>
      </ul>
    </li>

    <!--Repositorio programas-->
     <li class="dropdown" *ngIf="this.usuariosService.userValue.proyectos">
      <a id="" class="nav-link repositorioProgramas-nav" data-toggle="dropdown">{{ 'repositorioProgramas' | translate}}</a>
      <ul class="dropdown-menu"> 
    <!--Informes de incidencias-->
     <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/visorprogramas']">{{ 'visor-programas' | translate}}</a>
        </li>
      </ul>
    </li> 



  </ul>

  <h3 class="titulo-menu-configuracion">{{ 'gestionusuarios' | translate}}</h3>

  <ul class="nav flex-column mb-0 conf-menu">

    <li class="dropdown" *ngIf="this.usuariosService.userValue.ficheros">
      <a id="" class="ficheros-nav nav-link" data-toggle="dropdown">{{ 'ficheros' | translate}}</a>
      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/plantasisometrico']">{{ 'disenadorPlanta' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/diccionarioperdidas']">{{ 'diccionarioPerdidas' | translate}}</a>
        </li>
        <!--Pérdidas grupos-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/perdidasGrupos']">{{ 'perdidasGrupo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/maquinas']">{{ 'maquinas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="this.usuariosService.userValue.planificador">
          <a class="nav-link" [routerLink]="['/subcontratados']">{{ 'subcontratado' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/instalaciones']">{{ 'instalaciones' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/controles']">{{ 'controles' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/mantenimientospredefinidos']">{{ 'mantenimientospredefinidos' |
            translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/compatibilidadmaquinas']">{{ 'compatibilidadMaquinas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/clientes']">{{ 'clientes' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/maquinasgrupos']">{{ 'maquinasgrupos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/maquinasgruposplanificador']">{{ 'maquinasgruposPlanificador' |
            translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/turnos']">{{ 'turnos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/turnosOperarios']">{{ 'turnosOperarios' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/marcas']">{{ 'marcas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/materialestipo']">{{ 'materialestipo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/alarmastipo']">{{ 'alarmastipo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/piezastipo']">{{ 'piezastipo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/operacionestipo']">{{ 'operacionestipo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/toleranciastipo']">{{ 'toleranciastipo' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/areasProductivas']">{{ 'areasProductivas' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/bloqueoRangos']">{{ 'bloqueoRangos' | translate}}</a>
        </li>

        <!--Todos los informes personalizados-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/informesPersonalizados']">{{ 'disenadorinformes' | translate}}</a>
        </li>
        <!-- <li class="nav-item" [routerLinkActive]="['link-active']" [hidden]>
    <a class="nav-link " [routerLink]="['/activosMaestros']" >{{ 'activosMaestros' | translate}}</a>
  </li>
  <li class="nav-item" [routerLinkActive]="['link-active']" [hidden]>
    <a class="nav-link " [routerLink]="['/arbolActivosMaestros']">{{ 'arbolActivosMaestros' | translate}}</a>
  </li>
  <li class="nav-item" [routerLinkActive]="['link-active']" [hidden]>
    <a class="nav-link " [routerLink]="['/activos']">{{ 'activos' | translate}}</a>
  </li>
  <li class="nav-item" [routerLinkActive]="['link-active']" [hidden]>
    <a class="nav-link " [routerLink]="['/tiposActivos']">{{ 'tiposActivo' | translate}}</a>
  </li>
  <li class="nav-item" [routerLinkActive]="['link-active']" [hidden]>
    <a class="nav-link " [routerLink]="['/stock']">{{ 'stock' | translate}}</a>
  </li>
  <li class="nav-item" [routerLinkActive]="['link-active']" [hidden]>
    <a class="nav-link " [routerLink]="['/proveedores']" >{{ 'proveedores' | translate}}</a>
  </li> -->

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/atributos']">{{ 'atributos' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/clasificaciones']">{{ 'clasificaciones' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/contenedores']">{{ 'contenedores' | translate}}</a>
        </li>

        
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/seguimientoOFsConfigurador']">{{ 'configuradorSeguimientoOf' | translate}}</a>
        </li>

        <!-- <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/automatizaciones']">{{ 'automatizaciones' | translate}}</a>
        </li> -->
      </ul>

    </li>

    <li class="dropdown" *ngIf="this.usuariosService.userValue.administracion">
      <a id="" class="ficheros-nav nav-link" data-toggle="dropdown">{{ 'administracion' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/usuarios-tipos']">{{ 'tiposusuario' | translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']"
          *ngIf="this.usuariosService.userValue.gestionUsuarios">
          <a class="nav-link " [routerLink]="['/usuarios']">{{ 'usuarios' | translate}}</a>
        </li>
      </ul>
    </li>
    <li class="dropdown" *ngIf="this.usuariosService.userValue.configuracion">
      <a id="" class="ficheros-nav nav-link" data-toggle="dropdown">{{ 'configuracion' | translate}}</a>
      <ul class="dropdown-menu">
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/configuracion']">{{ 'configuracion' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/configuracionPlanificador']">{{ 'configuracionPlanificador' |
            translate}}</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/configuracionCalidad']">{{ 'configuracionCalidad' | translate}}</a>
        </li>
      </ul>
    </li>
    <!--ERIS ESKOLA-->
    <li class="dropdown" *ngIf="this.usuariosService.userValue.permiso_eskola > 0">
      <a id="" class="ficheros-nav nav-link" data-toggle="dropdown">{{ 'eriseskola' | translate}}</a>
      <ul class="dropdown-menu">

        <!--Lanzador de Ofs eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/eskola_lanzadorOf']">{{ 'lanzadorOf' | translate}}</a>
        </li>
        <!--Horarios secciones  eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/eskola_horariosSecciones']">{{ 'horariosSecciones' | translate}}</a>
        </li>
        <!--Reserva maquinas  eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/eskola_reservaMaquinas']">{{ 'reservaMaquinas' | translate}}</a>
        </li>
        <!--Calendarios eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/calendarios']">{{ 'calendarios' | translate}}</a>
        </li>
        <!--Cursos lectivos eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/eskola_cursoslectivos']">{{ 'cursoslectivos' | translate}}</a>
        </li>
        <!--Cursos lectivos tipos eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/cursoslectivostipos']">{{ 'cursoslectivostipos' | translate}}</a>
        </li>
        <!--Asignaturas retos eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/eskola_asignaturasretos']">{{ 'asignaturasretos' | translate}}</a>
        </li>
        <!--Grupos eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/grupos']">{{ 'grupos' | translate}}</a>
        </li>
        <!--Docentes eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/docentes']">{{ 'docentes' | translate}}</a>
        </li>
        <!--Alumnos eris eskola-->
        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/alumnos']">{{ 'alumnos' | translate}}</a>
        </li>
      </ul>
    </li>
  </ul>

  <ul class="beheko-minimenu" *ngIf="this.usuariosService.userValue.configuracion">
    <li class="">
      <a id="LoginView1_HLconfiguracion" class="conf desplegar-menuconf">{{ 'configuracion' | translate}}</a>
    </li>
  </ul>


</div>



<div class="contenedor body-content" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}">



  <!-- subnav router outlet -->
  <router-outlet name="subnav"></router-outlet>

  <!-- global alert -->
  <alert></alert>

  <!-- main router outlet -->
  <router-outlet></router-outlet>

</div>

<div class="text-center footer" [ngClass]="{'copyright-danobat': user.idDb == idDbDanobat}" *ngIf="user">
  <p>

    <span class="copy-eris"><a href="https://eris.zitu.net" target="_blank">© {{currentYear}} Eris - ZITU</a></span>

    <!-- ZITU o DANOBAT -->
    <!-- <span class="copy-zitu"><a href="https://www.zitu.net" target="_blank">ZITU</a></span> -->
    <!--<span class="copy-zitu"><a href="https://www.danobatgroup.com/es" target="_blank">DANOBATGROUP</a></span> -->

    <!-- <span class="copy-separador" style="color: #29c4cb;"> - </span>
    <span class="copy-zitu"><a href="http://alchemyml.com/" target="_blank">AlchemyML</a></span> -->

  </p>

</div>