import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DiccionarioPerdidasService, MenuService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-perdidas-grupos',
  templateUrl: './perdidas-grupos.component.html',
  styleUrls: ['./perdidas-grupos.component.less']
})
export class PerdidasGruposComponent implements OnInit {

  @ViewChild('popupBorrar') popupBorrar: NgbModalRef; //referencia al popup de borrado
  form: FormGroup; //formulario
  dataGruposDiccionarioPerdidas: any[]; //datos de los grupos del diccionario de pérdidas
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;
  user = this.userService.userValue;
  
  constructor(private diccionarioPerdidasService: DiccionarioPerdidasService,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/perdidasGrupos') {
          this.menuService.titulo = this.translateService.instant('perdidasGrupo');
          this.cargarDatos();
        } else {
          if (this.router.url == '/perdidasGrupos') {
            this.menuService.titulo = this.translateService.instant('perdidasGrupo');
          }
        }
      }
    });
  }

  cargarDatos() { //cargar datos grupos diccionario de pérdidas
    this.diccionarioPerdidasService.GetAllGrupos().subscribe((result) => {
      this.dataGruposDiccionarioPerdidas = result.data;
    });
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('perdidasGrupo'); //para traducir el título de la ventana
  }

  cellClick(e) { //al clickar en la fila de un grupo redirigir a editar
    if (e.columnIndex > 0) {
      this.router.navigate(['perdidasGrupos/editar/', e.dataItem.id]);
    }
  }
  onClickEditar() { //al pulsar en el botón editar redirigir a editar
    if (this.mySelection[0] > 0) {
      this.router.navigate(['perdidasGrupos/editar/', this.mySelection[0]]);
    }
  }
  onClickNuevo() { //al pulsar en el botón crear redirigir a crear nuevo
    this.router.navigate(['perdidasGrupos/crear']);
  }

  onClickEliminar() { //al pulsar en eliminar lanzar popup de eliminar
    this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptar() { //al aceptar el eliminado, eliminar grupo de la base de datos
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        this.form = this.formBuilder.group({
          idGrupo: element
        });
        this.diccionarioPerdidasService.deleteGrupoDiccionarioPerdidas(this.form.value)
          .pipe(first())
          .subscribe(() => {
            this.isDeleting = false;
            this.dataGruposDiccionarioPerdidas = this.dataGruposDiccionarioPerdidas.filter(x => x.id !== element)
          });
      }
      this.modalReference.close();
    });
    this.mySelection = [];
  }

}
