<kendo-grid [kendoGridBinding]="bloqueos" [sortable]="true" [navigable]="true" filterable="menu" kendoGridSelectBy="id"
    [selectedKeys]="seleccionados" (cellClick)="cellClick($event)">

    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">
        <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
        <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
        <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1"
            [disabled]="isDeleting || this.user.planificador<2">{{ 'eliminar' | translate}}</button>
        <button (click)="onClickDuplicar()" class="btn btn-copiar btn-sm mr-1">{{ 'duplicar' | translate}}</button>
    </ng-template>

    <!--checkbox-->
    <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
    <!--id-->
    <kendo-grid-column width="1%" field="id" hidden="hidden" title="id"></kendo-grid-column>
    <!--nombre-->
    <kendo-grid-column width="20%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
    <!-- maquinas -->
    <kendo-grid-column width="10%" field="maquinas" title="{{ 'maquinas' | translate}}" class="celda-tooltip-externo"
        [filterable]="false">

        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="tooltip-consumibles-contenido">
                <ng-container *ngFor="let maquina of dataItem.maquinas">
                    <span class="grid-circulo" style="background-color:white!important;"
                        [ngStyle]="{'background-image': 'url(' + maquina.imagenBase64 + ')'}"></span>
                </ng-container>
                <span class="tooltiptext">
                    <ng-container *ngFor="let maquina of dataItem.maquinas; let i = index">
                        <label *ngIf="maquina!='undefined'"> {{maquina.nombre}} </label>
                        <label *ngIf="maquina=='undefined'"> {{'desconocido' | translate}} </label>
                    </ng-container>
                </span>
            </span>
        </ng-template>
    </kendo-grid-column>




    <!-- operarios -->
    <kendo-grid-column width="10%" field="operarios" title="{{ 'operarios' | translate}}" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <span class="tooltip-consumibles-contenido">
                <ng-container *ngIf="dataItem.listaOperariosST!=''">
                    <ng-container *ngFor="let operario of dataItem.listaOperariosST.split(','); let i = index">
                        <span *ngIf="operario!='undefined' && operario!='Dundefined'" class="grid-circulo"
                            [style.background-color]="dataItem.listaOperariosColor.split(',')[i]+ '!important'">{{operario}}</span>
                        <span *ngIf="operario=='undefined' || operario=='Dundefined'" class="grid-circulo"><i
                                class="fas fa-user-alt"></i></span>
                    </ng-container>
                    <span class="tooltiptext">
                        <ng-container *ngFor="let operario of dataItem.operarios; let i = index">
                            <label *ngIf="operario !='undefined'">
                                {{operario.nombreCompleto}}
                            </label>
                            <label *ngIf="operario =='undefined'">
                                {{'desconocido' | translate}}
                            </label>
                        </ng-container>
                    </span>
                </ng-container>
            </span>
        </ng-template>
    </kendo-grid-column>
    <!-- recurrente -->
    <!-- <kendo-grid-column width="10%" field="recurrente" title="{{ 'recurrente' | translate}}"></kendo-grid-column> -->
    <kendo-grid-column field="recurrente" title="{{ 'recurrente' | translate}}" width="10%">
        <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" [checked]="recurrente" disabled />
        </ng-template>
    </kendo-grid-column>
    <!-- periodo -->
    <kendo-grid-column width="15%" field="periodo" title="{{ 'periodo' | translate}}"></kendo-grid-column>
    <!-- descripcion -->
    <kendo-grid-column width="25%" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>

</kendo-grid>