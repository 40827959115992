import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '../_helpers';
import { CursosLectivosGruposService } from '@app/_services/eskola_cursosLectivosGrupos.service'
import { dateFieldName } from '@progress/kendo-angular-intl';

@Component({
  selector: 'app-cursoslectivosgrupostodos',
  templateUrl: 'eskola_cursoslectivosgrupostodos.component.html'
})

export class CursosLectivosGruposTodosComponent {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //GRID: consumibles
  public consumibles: any;
  public seleccionados: number[] = [];
  public grupos: any;
  public cursosGrupos: any;

  //VARIABLES DB
  public id: number = 0;
  public nombre: string = '';
  public inicio: string = '';
  public fin: string = '';
  public calendario: string = '';
  public cursoLectivo: string = '';
  public tipo: string = '';
  public color: string = '';
  public esActivo: boolean = false;


  //POPUP: Eliminar
  public popupEliminar: boolean = false;
  public popupErrorAlEliminar: boolean = false;

  user = this.userService.userValue;
  

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal,
    private cursosLectivosGruposService: CursosLectivosGruposService) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('grupos').toUpperCase();
  }
  ngOnInit() {
    this.cargarGrupos();
  }

  cargarGrupos() {
    this.loadingPanel = true;
    this.cursosLectivosGruposService.Get_grupos().subscribe(
      (json) => {
        this.cursosGrupos = json;
        this.loadingPanel = false;
      });
  }

  onClickNuevo() {
    this.router.navigate(['cursoslectivosgrupos/0']);
  }
  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['cursoslectivosgrupos/', this.seleccionados[0]]);
    }
  }
  onClickCopiar() {
    if (this.seleccionados[0] > 0) {
      //this.router.navigate(['cursoslectivosgrupos/', this.seleccionados[0]]);
      this.cursosLectivosGruposService.Get_grupoByid(this.seleccionados[0]).subscribe(
        (json) => {
          var inicioArray = json[0].inicio.split("-");
          var inicio = inicioArray[0] + "/" + inicioArray[1] + "/" + inicioArray[2].split("T")[0];
          var finArray = json[0].fin.split("-");
          var fin = finArray[0] + "/" + finArray[1] + "/" + finArray[2].split("T")[0];
          this.cursosLectivosGruposService.Create(json[0].nombre, json[0].cursoLectivo, inicio, fin, json[0].calendario, json[0].tipo, json[0].activo,json[0].color).subscribe(
              (json) => {
              });
              this.router.navigate(['cursoslectivosgrupos']);
        });
    }
  }
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['cursoslectivosgrupos/', this.seleccionados[0]]);
    }
  }
  onClickEliminar() {
    if (this.seleccionados.length > 0) {
      for( var i=0; i<this.seleccionados.length; i++ ) {
        this.cursosLectivosGruposService.Delete(this.seleccionados[i]).subscribe(
          (json) => {});
      }
      this.router.navigate(['cursoslectivosgrupos']);
    }
  }

  private eliminarRegistro(contentloading, contentNotErased) {
    this.consumiblesService.Delete_consumible_ids(this.seleccionados).subscribe(
      (json) => {
        this.popupEliminar = false;

        var an: any = json;
        if (an == -1) {
          this.popupErrorAlEliminar = true;
        }
        else {
          this.cargarGrupos();
        }

      }
    );
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }


  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

}
