<!-- Formulario con datos e info del grupo y botones para guardar cambios o volver atrás -->
<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="datosCargados" >
    <div class="row">

        <div class="col-md-6 col-lg-6">
            <div class="card">
                <div class="card-body">

                    <h3 class="pt-3">{{ 'datos' | translate}}</h3>

                    <div class="row">
                        <div class="col-md-6">
                            <!--Cliente-->
                            <div class="form-group">
                                <kendo-label text="{{ 'nombre' | translate }}">
                                    <kendo-textbox formControlName="nombre"></kendo-textbox>
                                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                        <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
                                    </div>
                                </kendo-label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <!--Prioridad-->
                            <div class="form-group">
                                <kendo-label text="{{ 'prioridad' | translate}}">
                                    <kendo-numerictextbox class="form-control" formControlName="prioridad" [min]="0"
                                        [max]="100" [autoCorrect]="true" [format]="'n'"
                                        [ngClass]="{ 'is-invalid': submitted && f.prioridad.errors }">
                                    </kendo-numerictextbox>
                                </kendo-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!--Prioridad Facturación-->
                            <div class="form-group">
                                <kendo-label text="{{ 'prioridadFacturacion2' | translate}}">
                                    <kendo-numerictextbox class="form-control" formControlName="prioridadFacturacion"
                                        [min]="0" [max]="100" [autoCorrect]="true" [format]="'n'"
                                        [ngClass]="{ 'is-invalid': submitted && f.prioridadFacturacion.errors }">
                                    </kendo-numerictextbox>
                                </kendo-label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!--Grupo-->
                            <div class="form-group">
                                <kendo-label text="{{ 'grupo' | translate }}">
                                    <kendo-combobox [data]="listaGrupos" [textField]="'nombre'"
                                        [valueField]="'id'" [(value)]="idSelected"
                                        formControlName="grupo"
                                        placeholder="{{ 'seleccioneGrupo' | translate }}"
                                        [kendoDropDownFilter]="{operator: 'contains'}">
                                    </kendo-combobox>
                                </kendo-label>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div class="card">
                <div class="card-body">

                    <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary" [disabled]="this.user.clientes < 2">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{ 'guardar' | translate}}
                    </button>
                    <a routerLink="/clientes" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>

                </div>
            </div>
        </div>


    </div>


</form>
