import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/informecomparativarendimiento`;

@Injectable()
export class InformeComparativaRendimientoService {

  constructor(private http: HttpClient) { }

  public get_configuracion() {
    return this.http.post<JSON>(`${baseUrl}/get/configuracion`, {}, { withCredentials: true });
  }
  public get_donut(idMaquina: number, fechaInicio: Date, fechaFin: Date) {
    return this.http.post<JSON>(`${baseUrl}/get/donut`, { idMaquina, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public get_gantt(idMaquina: number, fechaInicio: Date, fechaFin: Date, fechaAhora: Date, conCanales: boolean) {
    return this.http.post<JSON>(`${baseUrl}/get/gantt`, { idMaquina, fechaInicio, fechaFin, fechaAhora, conCanales }, { withCredentials: true });
  }
}
