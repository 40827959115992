import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/informeejecuciones`;

@Injectable()
export class InformeEjecucionesService {

  constructor(private http: HttpClient) { }

  public get_avances_info(idMaquina) {
    return this.http.get<JSON>(`${baseUrl}/avancesinfo/` + idMaquina);
  }

  public get_colores() {
    return this.http.get<JSON>(`${baseUrl}/colores/`);
  }

  public get_rangos_ejes(idEje) {
    return this.http.get<JSON>(`${baseUrl}/rangosejes/` + idEje);
  }

  public get_avances_estimados_distancia_recorrida(idMaquina, idsTurnos, fechaInicio, fechaFin, fechaAhora) {
    return this.http.post<JSON>(`${baseUrl}/Get_Avances_Estimados_Distancia_Recorrida/`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin, fechaAhora: fechaAhora, idsTurnos: idsTurnos }, { withCredentials: true });
  }

  public get_velocidades(idMaquina, idsTurnos, fechaInicio, fechaFin, fechaAhora, idEje, minimo, suave, media, maximo) {
    return this.http.post<JSON>(`${baseUrl}/velocidades/`, { idMaquina: idMaquina, fechaInicio: fechaInicio, fechaFin: fechaFin, fechaAhora: fechaAhora, idsTurnos: idsTurnos, idEje: idEje, minimo: minimo, suave: suave, media: media, maximo: maximo }, { withCredentials: true });
  }

}
