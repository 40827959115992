import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { AlertService, InformesPersonalizadosDisenadorService, MenuService, InformePersonalizadoService, AppComponent, SeguimientoOFsService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { ResumeEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-seguimiento-OFs-Configurador',
  templateUrl: './seguimientoOFsConfigurador.component.html'
})

export class SeguimientoOFsConfiguradorComponent {

  //Variables basicas
  private translate: TranslateService;

  //Seleccion
  public seleccionados: any[] = [];

  //Lista
  public seguimientos: any[] = [];

  constructor(
    translate: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService, public router: Router,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    private modalService: NgbModal,
    public seguimientoOFsService: SeguimientoOFsService,
    private informePersonalizadoService: InformePersonalizadoService,
    public informesPersonalizadosDisenadorService: InformesPersonalizadosDisenadorService,
    private appComponent: AppComponent) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('configuradorSeguimientos').toUpperCase();
  }


  ngOnInit() {
    this.seguimientoOFsService.GetSeguimiento().subscribe(result => {
      var data: any = result;
      data.forEach(seg => {
        var aux = {
          id: seg.id,
          nombre: seg.nombre
        }
        // this.seguimientos.push(aux);
        this.seguimientos.push(seg);
      });
      console.log(this.seguimientos);
    });
  }


  onClick_Editar() {
    if (this.seleccionados.length == 1) {
      console.log(this.seleccionados[0])
      this.router.navigate(['/seguimientoOFsConfigurador/', this.seleccionados[0]]);
    } else if (this.seleccionados.length == 0) {

    } else {

    }
  }

  onClick_Duplicar() {
    if (this.seleccionados.length == 1) {
      this.seguimientoOFsService.Duplicar_Seguimiento(this.seleccionados[0], this.translate.instant('copia')).subscribe(result => {
        this.router.navigate(['/seguimientoOFsConfigurador/']);
      });
    } else if (this.seleccionados.length == 0) {

    } else {

    }
  }

  onClick_Eliminar() {
    if (this.seleccionados.length == 1) {
      this.seguimientoOFsService.Delete_Seguimiento(this.seleccionados[0]).subscribe(result => {
        this.router.navigate(['/seguimientoOFsConfigurador/']);
      });
    } else if (this.seleccionados.length == 0) {

    } else {

    }
  }

  cellClick(e: CellClickEvent) {
    if (e.columnIndex > 0) {
      console.log(e.rowIndex);
      this.router.navigate(['/seguimientoOFsConfigurador/', e.dataItem.id]);
    }
  }

}
