<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<div class="card">
  <div class="card-header">
    <h3><label>{{ 'datos' | translate }}</label></h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <div class="clearfix">
      <!-- NOMBRE -->
      <div class="float-left mr-1">
        <div class="form-group">
          <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nombre" [maxlength]="50" [class.requerido]="requiereNombre"></kendo-textbox>
          </div>
        </div>
      </div>
      <!-- POSIBLES HIJOS -->
      <div class="float-left mr-1">
        <div class="form-group">
          <label>{{ 'contenido' | translate }}</label>
          <div class="caja">
            <kendo-multiselect kendoMultiSelectSummaryTag
                               [(data)]="consumiblesTipos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               [(ngModel)]="idHijos"
                               placeholder="{{ 'contenido' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'consumibles' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
    <!--SOLICITARES-->
    <div class="clearfix">
      <!--VIDA UTIL-->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'vidaUtil' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarVidaUtil" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!--DIAMETRO-->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'diametro' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarDiametro" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!-- LONGITUD -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'longitud' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarLongitud" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!-- ANGULO -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'angulo' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarAngulo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!-- N CONTENIDO -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'numContenido' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarNumContenido" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!-- SALTO -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'salto' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarSalto" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!-- ID ERP -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'idErp' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarIdErp" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <!-- COSTE -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'coste' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarCoste" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
    
      <!-- vb -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'vc' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarVc" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>

      <!-- f -->
      <div class="float-left mr-4">
        <div class="form-group">
          <label>{{ 'f' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="solicitarF" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
    </div>

    <!-- DESCRIPCION -->
    <div class="clearfix">
      <div class="form-group">
        <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
        <div class="caja">
          <textarea [(ngModel)]='descripcion' class="form-control" style="height: 7em;" maxlength="2000"></textarea>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="clearfix">
  <button kendoButton class="btn mr-1  btn-success" (click)="guardar()" [disabled]="this.user.consumibles<2">{{ 'guardar' | translate}}</button>
  <button kendoButton class="btn mr-1  btn-danger" (click)="cancelar()">{{ 'cancelar' | translate}}</button>
</div>


<!-- POPUP: Error al guardar -->
<div class="popup-cont" *ngIf="popupErrorAlGuardar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlGuardar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlGuardar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
