import { Component } from '@angular/core';
import { NavigationEnd, Router  } from "@angular/router"
import { PlanesAccionService } from '@app/_services/planesaccion.service';
import { AlertService } from '@app/_services/alert.service';
import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-graficos-data',
  templateUrl: 'planesdeaccion.html'
})

export class PlanesdeaccionComponent {

  public mySelection: number[] = [];
  public planesaccion: [];
  public planesseleccionado = [];
  modalReference: NgbModalRef; 
  modalReferenceloading: NgbModalRef; 
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private planesaccionservice: PlanesAccionService,
    public translate: TranslateService,
    private menuService: MenuService,
    public router: Router,
    private modalService: NgbModal,
    private userService: UsuariosService,
    protected alertService: AlertService) {

    this.translate = translate;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/planesaccion') {
          this.menuService.titulo = this.translate.instant('planesDeAccion').toUpperCase();
          this.cargarDatos();
        }
      }
    });

  }
  ngOnInit() {

  }

  cargarDatos() {
    this.planesaccionservice.GetAll().subscribe((result) => {
      this.planesaccion = result.data;
    });
  }

  cellClick(e) {
     if(e.columnIndex>0) this.router.navigate(['planesaccion/editar/', this.planesseleccionado[0] ]);
  }

  onClickEliminar(content) {
    if (this.planesseleccionado.length > 0) {
       this.modalReference=this.modalService.open(content, {backdrop: 'static',size: 'lg', keyboard: false, centered: true});
    }
  }

  onClickEditar() {
    if(this.planesseleccionado[0] > 0){
      this.router.navigate(['planesaccion/editar/', this.planesseleccionado[0] ]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['planesaccion/crear/']);
  }

  private Eliminar(contentloading) {
    this.planesaccionservice.delete({ idplan: this.planesseleccionado }).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.router.navigate(['planesaccion']);
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
    });    
    this.modalReference.close();
    this.modalReferenceloading=this.modalService.open(contentloading, {backdrop: 'static',size: 'sm', keyboard: false, centered: true});
  }

}
