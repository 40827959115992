<style>
    .reldiv1 {
        height: 0px;
        width: 100%;
        position: relative;
    }
</style>

<!-- FILTRO -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
    <div class="card-header">
        <h3>{{ 'filtro' | translate}}</h3>
        <button type="button" class="flt-cerrar" (click)="this.status = !this.status;"><i class="fas fa-times"></i></button>
    </div>
    <div class="filtro-desplegable card-body" [formGroup]="form">

        <!-- OF -->
        <div class="clearfix mt-2 form-group">
            <kendo-label text="{{ 'of' | translate }}">
                <kendo-combobox [data]="listaOFs" (valueChange)="OFChange($event)" [value]="this.OF" textField="text"
                    valueField="value" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                    formControlName="OFForm" required>
                </kendo-combobox>
            </kendo-label>
        </div>

        <!-- PIEZA -->
        <div class="clearfix mt-2 form-group">
            <kendo-label text="{{ 'pieza' | translate }}">
                <kendo-combobox [data]="listaPiezas" (valueChange)="piezaChange($event)" [value]="this.pieza" textField="nombre"
                    valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                    formControlName="piezaForm" required>
                </kendo-combobox>
            </kendo-label>
        </div>

        <!-- PARTE -->
        <div *ngIf="!this.user.ocultarParte" class="clearfix mt-2 form-group">
            <kendo-label text="{{ 'parte' | translate }}">
                <kendo-combobox [data]="listaPartes" (valueChange)="parteChange($event)" [value]="this.parte" textField="nombre"
                    valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                    formControlName="parteForm" required>
                </kendo-combobox>
            </kendo-label>
        </div>

        <!-- RUTA -->
        <div class="clearfix mt-2 form-group">
            <kendo-label text="{{ 'ruta' | translate }}">
                <kendo-combobox [data]="listaRutas" (valueChange)="rutaChange($event)" [value]="this.ruta" textField="nombre"
                    valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                    formControlName="rutaForm" required>
                </kendo-combobox>
            </kendo-label>
        </div>

        <!-- OPERACION -->
        <div class="clearfix mt-2 form-group">
            <kendo-label text="{{ 'operacion' | translate }}">
                <kendo-combobox [data]="listaOperaciones" (valueChange)="operacionChange($event)" [value]="this.operacion"
                    textField="nombre" valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                    formControlName="operacionForm" required>
                </kendo-combobox>
            </kendo-label>
        </div>

        <div class="clearfix">
            <button Class="btn btn-primary btn-sm mt-3 float-right" (click)="filtrar()"> {{ 'filtrar' | translate }}</button>
        </div>
    </div>
</div>

<!-- BLOQUE INFO -->
<div class="historico">
    <!-- INFO-->
    <div class="card">
        <div class="card-body">
            <!-- CONTENIDO -->
            <div class="row">
                <!-- OF -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'of' | translate }}</label>
                    <label class="bloques-info-valor">{{ofActual}}</label>
                </div>
                <!-- PIEZA -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
                    <label class="bloques-info-valor">{{piezaActual}}</label>
                </div>
                <!-- PARTE -->
                <div *ngIf="!this.user.ocultarParte" class="bloques-info ">
                    <label class="bloques-info-label">{{ 'parte' | translate }}</label>
                    <label class="bloques-info-valor">{{parteActual}}</label>
                </div>
                <!-- RUTA -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'ruta' | translate }}</label>
                    <label class="bloques-info-valor">{{rutaActual}}</label>
                </div>
                <!-- OPERACION -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'operacion' | translate }}</label>
                    <label class="bloques-info-valor">{{operacionActual}}</label>
                </div>
                <!-- FECHA INICIO -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'inicio' | translate }}</label>
                    <label class="bloques-info-valor">{{fechainiActual}}</label>
                </div>
                <!-- FECHA FIN -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'fin' | translate }}</label>
                    <label class="bloques-info-valor">{{fechafinActual}}</label>
                </div>
                <!-- HORNO -->
                <div class="bloques-info ">
                    <label class="bloques-info-label">{{ 'horno' | translate }}</label>
                    <label class="bloques-info-valor">{{hornoActual}}</label>
                </div>
                <!-- OPERARIO -->
                <div class="bloques-info">
                    <label class="bloques-info-label">{{ 'operario' | translate }}</label>
                    <label class="bloques-info-valor">{{operarioActual}}</label>
                </div>
                <!-- TRATAMIENTO -->
                <div class="bloques-info">
                    <label class="bloques-info-label">{{ 'tratamiento' | translate }}</label>
                    <label class="bloques-info-valor">{{tratamientoActual}}</label>
                </div>
                <button type="button" class="btn btn-primary float-right mr-3 mt-3" style="height: 30px;"
                (click)="this.status = !this.status;"><i class="fas fa-sync-alt mr-1"></i>{{ 'Cambiar operacion' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<div class="card" style="height: 400px;">
    <div class="card-header">
        <h3>
            <label>{{ 'temperatura' | translate}}</label>
        </h3>
    </div>
    <div class="card-body">
        <div class="reldiv1" id="grafico" style="z-index: 1;">
            <div class="text-center align-middle mt-5">
                <label>{{ 'seleccioneOperacionGrafico' | translate }}</label>
            </div>
        </div>
    </div>
</div>