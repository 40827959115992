import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({ selector: 'grafico-posicion', templateUrl: 'graficoPosicion.html' })

export class GraficoPosicionComponent implements OnInit {

  @Input() public min: number;
  @Input() public max: number;
  @Input() public value: number;

  @Input() public minBarra: number;
  @Input() public maxBarra: number;

  public array = [];

  constructor(public router: Router) {
      
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.array = []
    var m = Math.round(this.min / 10) * 10;
    for (var i=m; i<=this.max; i = i+10) {
      this.array.push(Math.round(i / 10) * 10);
    }    
  }


  ngOnInit(): void {
  }

  calcularTriangulo() {
    if (this.value > this.max) {
      return  '100%';
    }else if(this.value < this.min) {
      return '0%'
    } else {
      var porcen = (this.value - this.min) * 100 / (this.max - this.min);
      return porcen + '%';
    }
  }

  calcularLeftBarra() {
    var pos = this.minBarra - this.min;
    return pos + '%'
  }

  calcularRightBarra() {
    var pos = this.maxBarra - this.max;
    return pos + '%'
  }

  calcularBarraFin() {
    var pos = 100 - (this.maxBarra - this.max);
    return pos + '%'
  }

  showInicio() {
    if (this.minBarra == this.min)
      return 'none'
    else
      return 'block'
  }

  showFin() {
    if (this.maxBarra == this.max)
      return 'none'
    else
      return 'block'
  }

}
