import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
@Component({ selector: 'grafico-temperatura', templateUrl: 'graficoTemperatura.html' })

export class GraficoTemperaturaComponent implements OnInit {

  @Input() public min: number;
  @Input() public max: number;
  @Input() public value: number;
  @Input() public id: string;
  @Input() public isPequeno: boolean = false;

  public array = [];

  constructor(public router: Router) {
      
  }
  
  ngOnChanges(changes: SimpleChanges) {

    var step = 10;
    if (this.isPequeno)
      step = Math.ceil((this.max - this.min) / (8*10)) * 10
    else 
      step = Math.ceil((this.max - this.min) / (100)) * 10;


    this.array = []
    var m = Math.round(this.min / 10) * 10;
    for (var i=m; i<=this.max; i = i+step) {
      var porcen = Math.round(i / step) * 10
      this.array.push([i, porcen+'%']);
    }    
  }


  ngOnInit(): void {
  }

  calcularAltura() {
    if (this.value > this.max) {
      return  '100%';
    } else {
      var porcen = (this.value - this.min) * 100 / (this.max - this.min);
      return porcen + '%';
    }
  }


}
