<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h3>{{ 'atributo' | translate }}</h3>
            </div>
            <div class="card-body">
                <div class="row mt-2">
                    <!-- NOMBRE -->
                    <div class="col-lg-6">
                        <kendo-label text="{{ 'nombre' | translate }}"><br>
                            <input [(ngModel)]="nombre" [(value)]="nombre" (change)="nombreError = false" kendoTextBox [ngClass]="{ 'is-invalid': nombreError }"/>
                        </kendo-label>
                    </div>
                    <!-- ELEMENTO SUPERIOR -->
                    <div class="col-lg-12">
                        <kendo-label text="{{ 'elementoSuperior' | translate }}"><br>
                            <kendo-dropdowntree kendoDropDownTreeExpandable
                                                [kendoDropDownTreeHierarchyBinding]="atributos"
                                                [textField]="'nombre'"
                                                [valueField]="'id'"
                                                [childrenField]="'atributoInferior'"
                                                (valueChange)="cellClick($event)"
                                                [(ngModel)]="idPadreSeleccionado"
                                                placeholder="{{ 'elementoSuperior' | translate }}"
                                                style="background: white;">
                            </kendo-dropdowntree>
                        </kendo-label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="text-left">
    <!-- BOTON SUBMIT -->
    <button kendoButton (click)="onSubmit()" class="btn mr-1  btn-primary">
        {{ 'guardar' | translate}}
    </button>
    <!-- VOLVER A atributos -->
    <a [routerLink]="['/atributos']" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
</div>