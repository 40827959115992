<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<!-- FILTRO -->
<div>
  <div class="clearfix filtro-planificador-largo-cont">
    <!-- Estimado / predictivo / Ineficiencias -->
    <div class="filtro-planificador-largo-col">
      <div class="card">
        <div class="card-body">
          <div class="btn mr-1" [class.btn-success]="aplicarTiempoEstimado" [class.btn-outline-success]="!aplicarTiempoEstimado">
            <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnTiempoEstimado()" [checked]="aplicarTiempoEstimado" kendoRadioButton>
            <label class="k-radio-label" for="btnEstimado">{{ 'estimado' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!aplicarTiempoEstimado" [class.btn-outline-success]="aplicarTiempoEstimado">
            <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnPredictivo()" [checked]="!aplicarTiempoEstimado" kendoRadioButton>
            <label class="k-radio-label" for="btnPredictivo">{{ 'predictivo' | translate }}</label>
          </div>
          <!-- Ineficiencias -->
          <button type="button" class="btn d-block mt-2 w-100" [class.btn-success]="aplicarIneficiencias" [class.btn-outline-success]="!aplicarIneficiencias" (click)="btnIneficiencia()">{{ 'ineficiencia' | translate }}</button>
        </div>
      </div>
    </div>
    <!-- ORIGINAL / VERSION DE SIMULADOR -->
    <div class="filtro-planificador-largo-col">
      <div class="card filtro-planificador-largo-version">
        <div class="card-body">
          <label class="crontol-label float-left m-1">{{ 'version' | translate }}</label>
          <kendo-dropdownlist [data]="Jplanificadores"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [(value)]="JplanificadoresSelected"
                              (valueChange)="versionChanged($event)"
                              style="width: 65px">
          </kendo-dropdownlist>
          <button type="button" class="btn btn-danger ml-1 mr-1" (click)="btnBorrarVersion()"
                  [disabled]="this.user.planificadorLargo<2" [hidden]="!visibleUsarVersion"><i class="icon-equis"></i></button>
          <button type="button" class="btn btn-success mt-2 d-block w-100" (click)="btnCopiarAVersion()">{{ 'copiarAVersion' | translate }}</button>
        </div>
      </div>
    </div>

    <div class="filtro-planificador-largo-col">
      <div class="card">
        <div class="card-body">
          <div class="clearfix">
            <!--LISTA OFS-->
            <div class="float-left mr-2">
              <!--<label>{{ 'of' | translate }}</label>-->
              <kendo-multiselect (valueChange)="CambioFiltro()"
                                 kendoMultiSelectSummaryTag
                                 [data]="listaOfs"
                                 [(ngModel)]="ofsSeleccionados"
                                 [textField]="'nombreOf'"
                                 [valueField]="'idOf'"
                                 placeholder="{{ 'seleccioneOf' | translate }}"
                                 [autoClose]="false"
                                 style="width: 200px;"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
            <!--LISTA CLIENTES-->
            <div class="float-left mr-2">
              <!--<label>{{ 'cliente' | translate }}</label>-->
              <kendo-multiselect (valueChange)="CambioFiltro()"
                                 kendoMultiSelectSummaryTag
                                 [data]="listaClientes"
                                 [(ngModel)]="clientesSeleccionados"
                                 [textField]="'nombreCliente'"
                                 [valueField]="'idCliente'"
                                 placeholder="{{ 'seleccioneCliente' | translate }}"
                                 [autoClose]="false"
                                 style="width: 200px;"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
            <!--LISTA PIEZAS-->
            <div class="float-left mr-2">

              <!--<label>{{ 'pieza' | translate }}</label>-->
              <div class="caja">
                <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionados" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                                   placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 270px;" [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionados' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          <div class="clearfix mt-1">
            <!-- GRUPOS DE MAQUINAS -->
            <div class="float-left mr-2">
              <kendo-multiselect [data]="JgruposMaquinasCombo"
                                 kendoMultiSelectSummaryTag
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [(ngModel)]="JgruposMaquinasSelected"
                                 placeholder="{{ 'grupoMaquinas' | translate }}"
                                 [autoClose]="false"
                                 style="width: 200px;"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                </ng-template>
              </kendo-multiselect>
            </div>
            <!-- ÁREA PRODUCTIVA / SECCIÓN -->
            <div class="float-left mr-1">
              <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [autoClose]="false"
                                 [(ngModel)]="seccionesSeleccionadas"
                                 (close)="seccionChanged()"
                                 style="width: 200px;"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
            <button type="button" class="btn ml-1 mr-1 btn-primary float-right" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="filtro-planificador-largo-col">
      <div class="filtro-planificador-largo-cont-inner">
        <div class="filtro-planificador-largo-col-inner">
          <div class="float-left clearfix">
            <div class="card  mb-1">
              <div class="card-body">
                <div class="cont-pasarsemana" *ngIf="desplazaminetoSemanas > 0 || !bloquearAvanceDesplazamientoSemanas">
                  <button type="button" class="icon-flechaizquierda btn-pasarsemana" (click)="btnMoverSemanas(-1)" [disabled]="desplazaminetoSemanas<=0"> </button>
                  <Label class="label-pasarsemana">{{ 'semana' | translate }}</Label>
                  <button type="button" class="icon-flechaderecha btn-pasarsemana" (click)="btnMoverSemanas(+1)" [disabled]="bloquearAvanceDesplazamientoSemanas"> </button>
                </div>
                <div class="clearfix">
                  <div class="btn mr-1" [class.btn-success]="agruparOperacionesPorColor" [class.btn-outline-success]="!agruparOperacionesPorColor">
                    <input type="radio" id="btnAgrupar" class="k-radio" (click)="btnAgruparOperacionesPorColor()" [checked]="agruparOperacionesPorColor" kendoRadioButton>
                    <label class="k-radio-label" for="btnAgrupar">{{ 'agruparOperacionesPorColorPlanificadorLargoMaquinas' | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filtro-planificador-largo-col-inner">
          <div class="card  mb-1">
            <div class="card-body botones-vistalargo">
              <button type="button" class="btn btn-secondary  " (click)="btnReorganizar()">{{ 'reorganizar' | translate }}</button>
              <button type="button" class="btn btn-info " (click)="btnAtrasarUnaSemana()">{{ 'atrasar' | translate }}</button>
              <button type="button" class="btn btn-primary  " (click)="btnPlanificar()">{{ 'anadir' | translate }}</button>
              <button type="button" class="btn btn-success  " (click)="btnReplanificarTodoUltimaSemana()">{{ 'replanificar' | translate }}</button>
            </div>
          </div>
        </div>
        <div class="filtro-planificador-largo-col-inner ">

          <div class="card">
            <div class="card-body">
              <button type="button" class="btn btn-success mb-1 w-100" (click)="btnLista()">{{ 'lista' | translate }}</button>
              <button type="button" class="btn btn-primary w-100" (click)="btnComparativaVersiones()">{{ 'comparativaVersiones' | translate }}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- GRAFICOS -->
<div class="card" style="min-height:200px">
  <!--LEYENDA-->
  <div class="leyendas leyendas-top">
    <div class="graf-barr-leyenda">
      <span class="colorleyenda" style="background-color: #C3E8EE;"></span>
      <Label>{{ 'enCorto' | translate }}</Label>
    </div>
    <div class="graf-barr-leyenda">
      <span class="colorleyenda" style="background-color: #3289a8;"></span>
      <Label>{{ 'conMargen' | translate }}</Label>
    </div>
    <div class="graf-barr-leyenda">
      <span class="colorleyenda" style="background-color: #eb8f34;"></span>
      <Label>{{ 'enPlazo' | translate }}</Label>
    </div>
    <div class="graf-barr-leyenda">
      <span class="colorleyenda" style="background-color: #EA4335;"></span>
      <Label>{{ 'fueraPlazo' | translate }}</Label>
    </div>
  </div>
  <!-- BARRAS -->
  <div id="contenedorGeneral">
  </div>

  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;">
    <div class="texto-loading-panel" *ngIf="secciones == undefined">
      <Label>{{ 'noTienesSeccionesAsignadas' | translate }}</Label>
    </div>
  </div>
</div>

<!-- POPUP: Añadir -->
<!--<ng-template #popupPlanificar let-modal>-->
<!--<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">-->
<!--<ngb-modal-backdrop style="z-index: 1050;" aria-hidden="true" class="fade modal-backdrop show"></ngb-modal-backdrop>
<ng-modal-window role="dialog" tabindex="-1" aria-modal="true" class="d-block fade modal show">-->
<!--<div class="modal-contant">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="clearfix">
      <kendo-grid [kendoGridBinding]="JpiezasVal"
                  kendoGridSelectBy="idOperacion"
                  filterable="menu"
                  [selectedKeys]="piezasSelected"
                  [navigable]="true"
                  [sortable]="true"
                  scrollable="virtual"
                  [rowHeight]="36"
                  [height]="500"
                  [resizable]="true"
                  (dataStateChange)="dataStateChange($event)"
                  (pageChange)="pageChange($event)"
                  [pageSize]="50"
                  [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">-->
<!--checkbox-->
<!--<kendo-grid-checkbox-column width="5%">
          <ng-template kendoGridHeaderTemplate>
            <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                   [state]="selectAllState"
                   (selectAllChange)="onSelectAllChange($event)">
            <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
          </ng-template>
        </kendo-grid-checkbox-column>

        <kendo-grid-column field="of" title="{{ 'OF' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="plano" title="{{ 'plano' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="20%"></kendo-grid-column>
        <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="lado" title="{{ 'lado' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="subOrden" title="{{ 'suborden' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="32%"></kendo-grid-column>
        <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="20%"></kendo-grid-column>
        <kendo-grid-column field="fechaini" title="{{ 'fechainicio' | translate }}" width="15%">
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini | kendoDate:'HH:mm:ss' }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="fechafin" title="{{ 'fechaentrega' | translate }}" width="15%">
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin | kendoDate:'HH:mm:ss' }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="planificadas" title="{{ 'planificados' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="hechos" title="{{ 'hechos' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="restantes" title="{{ 'restantes' | translate }}" width="20%">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox [(value)]="dataItem.restantesSelected" [format]="'n'" [min]="1" [max]="dataItem.restantes" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                 filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                 filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                 filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                 filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                 filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                 filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                 filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                 filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                 filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                 filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                 filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                 filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                 filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                 filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                 filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                 filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                 filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                 filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                 filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                 groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                 noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <div class="float-left">
      <div class="form-group mr-1">
        <label class="crontol-label float-left m-1">{{ 'maquina' | translate }}</label>
        <div class="caja float-left" style="width: 300px">
          <kendo-combobox [data]="JmaquinasMostradas"
                          [textField]="'nombre'"
                          [valueField]="'id'"
                          [(value)]="JmaquinaSelected"
                          [class.requerido]="requiereMaquina"
                          placeholder="{{ 'maquina' | translate }}"
                          [kendoDropDownFilter]="{operator: 'contains'}">
          </kendo-combobox>
        </div>
      </div>
    </div>
    <div class="float-left">
      <div class="form-group mr-1">
        <div class="form-group form-check">
          <label class="crontol-label float-left m-1">{{ 'meterCadaOperacionEnSuMaquina' | translate }}</label>
          <div class="caja">
            <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="todasMaquinas"> </kendo-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="float-left">
      <div class="form-group mr-1">
        <label class="crontol-label float-left m-1">{{ 'semana' | translate }}</label>
        <div class="caja float-left" style="width: 300px">
          <kendo-combobox [data]="Jsemanas"
                          [textField]="'text'"
                          [valueField]="'value'"
                          [(value)]="JsemanaSelected"
                          [class.requerido]="requiereSemana"
                          placeholder="{{ 'semana' | translate }}"
                          [kendoDropDownFilter]="{operator: 'contains'}">
          </kendo-combobox>
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-danger" (click)="btnAnadir()">{{ 'anadir' | translate }}</button>
  </div>
  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;">
    <div class="texto-loading-panel" *ngIf="secciones == undefined">
      <Label>{{ 'noTienesSeccionesAsignadas' | translate }}</Label>
    </div>
  </div>

</div>-->
<!--</div>-->
<!--</ng-template>-->
<!--</ng-modal-window>-->
<!--</ng-template>-->

<!-- POPUP -->
<ng-template #popupReorganizar let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">
      <label>{{ 'prioridades' | translate }}</label>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- ROW 1 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadCliente" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadCliente" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 2 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadFacturacion" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadFacturacion" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 3 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadOF" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadOF" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 4 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadMaquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadMaquina" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 5 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadFecha" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadFecha" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 6 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadTemperatura" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadTemperatura" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 7 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="percent_cap_maquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="maxCapacidadMaquina" [smallStep]="pasoCapacidadMaquina" [largeStep]="1" [(ngModel)]="percent_cap_maquina" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 8 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
        <div class="caja">
          <kendo-numerictextbox [(value)]="dias_reserva" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          <div class="btn mr-1 ml-1" [class.btn-success]="replanificarTiempoEstimado" [class.btn-outline-success]="!replanificarTiempoEstimado">
            <input type="radio" name="informesGroup2" class="k-radio" [checked]="replanificarTiempoEstimado" (click)="btnReorganizarTiempoEstimado()" kendoRadioButton>
            <label class="k-radio-label" for="btnReorganizarTiempoEstimado">{{ 'estimado' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!replanificarTiempoEstimado" [class.btn-outline-success]="replanificarTiempoEstimado">
            <input type="radio" name="informesGroup2" class="k-radio" [checked]="!replanificarTiempoEstimado" (click)="btnReorganizarTiempoPredictivo()" kendoRadioButton>
            <label class="k-radio-label" for="btnReorganizarTiempoPredictivo">{{ 'predictivo' | translate }}</label>
          </div>
          <button type="button" class="btn mr-1" [class.btn-success]="replanificarIneficiencias" [class.btn-outline-success]="!replanificarIneficiencias"
                  (click)="btnReorganizarIneficiencia()">{{ 'ineficiencia' | translate }}</button>
        </div>
      </div>
    </div>
    <!-- ROW 9 -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="label-color1">{{ 'optimizarCuelloBotella' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="optimizarCuelloBotella" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
          <div class="caja">
            <kendo-dropdownlist [data]="metodologiasDePlanificacion"
                                [textField]="'nombre'"
                                [valueField]="'id'"
                                [(value)]="metodologiasDePlanificacionSelected"
                                style="width: 100px">
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 10 -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="label-color1">{{ 'planificarSoloNoPlanificadas' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="planificarSoloNoPlanificadas" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group" *ngIf="metodologiasDePlanificacionSelected.id == 1">
          <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="asap_maximoMesesParaIniciarAntesDeFechaEntrega" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnReorganizarAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Usar planning -->
<ng-template #popupUsarVersion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'estasSeguroUsarVersion' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnUsarVersionAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Copiar planning a version -->
<ng-template #popupCopiarAVersion let-modal>
  <div class="modal-header">
    <h4> <label>{{'aQueVersion' | translate }}</label> </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- ORIGINAL / VERSION DE SIMULADOR -->
    <div class="float-left">
      <label class="crontol-label float-left m-1">{{ 'planificacion' | translate }}</label>
      <!-- <div class="caja float-left" style="width: 150px"> -->
      <kendo-combobox [data]="JplanificadoresSinOriginal"
                      [textField]="'text'"
                      [valueField]="'value'"
                      [(value)]="JplanificadoresSelectedCopiar"
                      [kendoDropDownFilter]="{operator: 'contains'}">
      </kendo-combobox>
      <!-- </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnCopiarAVersionAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>




<!-- POPUP: Borrar planning -->
<ng-template #popupBorrarVersion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'borrarVersion' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnBorrarVersionAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Reorganizando version -->
<div class="popup-cont " *ngIf="popupReorganizandoVersion">
  <div class="popup " style="width: 215px;">
    <div class="popup-header">
      <h3>
        <label>{{'reorganizandoVersion' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div class="clearfix text-center">
        <div class="position-relative" style="height:100px">
          <div class="k-i-loading" style="z-index: 3;"></div>
        </div>
      </div>
      <div>
        <label class="crontol-label m-1">{{ 'version' | translate }}</label>
        <div class="caja">
          <!--<kendo-combobox [data]="Jplanificadores"
                          [textField]="'text'"
                          [valueField]="'value'"
                          [(value)]="JplanificadoresSelected"
                          (valueChange)="versionChanged($event)"
                          style="width: 100px"
                          [kendoDropDownFilter]="{operator: 'contains'}">
          </kendo-combobox>-->
          <kendo-dropdownlist [data]="Jplanificadores"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [(value)]="JplanificadoresSelected"
                              (valueChange)="versionChanged($event)"
                              style="width: 100px">
          </kendo-dropdownlist>
        </div>
      </div>
    </div>
    <div class="popup-footer text-rigth">
      <button type="button" class="btn btn-primary" (click)="btnActualizarReorganizandoVersion()" [disabled]="this.user.planificadorLargo<2">{{ 'actualizar' | translate }}</button>
    </div>
  </div>
</div>

<!-- POPUP: Atrasar una semana FIJADOS tambien -->
<ng-template #popupAtrasarFijados let-modal>
  <div class="modal-header">
    <h3>
      <label>{{'atrasarUnaSemana' | translate }}</label>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'atrasarFijados' | translate }}</label>
    <div class="text-danger">
      <label>{{'accionNoReversible' | translate }}</label>
    </div>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger mr-auto" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-secondary" (click)="btnAtrasarFijadosCancelar()" [disabled]="this.user.planificadorLargo<2">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-info" (click)="btnAtrasarFijadosAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Replanificar todo a ultima semana -->
<ng-template #popupReplanificarAultimaSemana let-modal>
  <div class="modal-header">
    <h3>
      <label>{{'replanificar' | translate }}</label>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'replanificarTodoUltimaSemana' | translate }}</label>
    <div class="text-danger">
      <label>{{'accionNoReversible' | translate }}</label>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnReplanificarAultimaSemanaAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Añadir -->
<!-- <div class="example-wrapper"> -->
<ng-template #popupPlanificar let-modal>
  <div class="modal-header">
    <h3>
      <label>{{'anadirOFsPlanificador' | translate }}</label>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- <kendo-dialog title="" [maxWidth]="1500" (close)="cerrarPopUp()" *ngIf="popupPlanificar"> -->
  <div class="modal-body">
    <div>
      <kendo-grid [kendoGridBinding]="JpiezasVal"
                  kendoGridSelectBy="idOperacion"
                  filterable="menu"
                  [selectedKeys]="piezasSelected"
                  [navigable]="true"
                  [sortable]="true"
                  scrollable="virtual"
                  [rowHeight]="45.56"
                  [height]="500"
                  [resizable]="true"
                  (dataStateChange)="dataStateChange($event)"
                  (pageChange)="pageChange($event)"
                  [pageSize]="50"
                  [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">

        <!--checkbox-->
        <kendo-grid-checkbox-column width="7%">
          <ng-template kendoGridHeaderTemplate>
            <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                   [state]="selectAllState"
                   (selectAllChange)="onSelectAllChange($event)">
            <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
          </ng-template>
        </kendo-grid-checkbox-column>

        <kendo-grid-column field="of" title="{{ 'OF' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="plano" title="{{ 'plano' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="20%"></kendo-grid-column>
        <kendo-grid-column field="parte" title="{{ 'parte' | translate }}" width="10%"></kendo-grid-column>

        <!-- <kendo-grid-column field="lado" title="{{ 'lado' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column> -->
        <!-- <kendo-grid-column field="subOrden" title="{{ 'suborden' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column> -->
        <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="32%"></kendo-grid-column>
        <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>

        <!-- <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="20%"></kendo-grid-column> -->

        <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="8%" class="celda-tooltip-externo">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="tooltip-consumibles-contenido" *ngIf="dataItem.maquinasAuxi!=undefined">
              <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                <span *ngIf="(dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined') && (i < numeroCirculos -1 || (i == numeroCirculos - 1 && dataItem.maquinasAuxi.split(',').length == numeroCirculos ))" class="grid-circulo"
                      style="background-color:white!important;"
                      [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}">
                </span>
                <span *ngIf="(dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined') && (i < numeroCirculos -1 || (i == numeroCirculos - 1 && dataItem.maquinasAuxi.split(',').length == numeroCirculos ))" class="grid-circulo"
                      [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}
                </span>
                <span *ngIf="i == numeroCirculos - 1 && dataItem.maquinasAuxi.split(',').length > numeroCirculos" class="grid-circulo grid-circulo-mas"><i class="fas fa-plus"></i>
                </span>
              </ng-container>
              <span class="tooltiptext">
                <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                  <label *ngIf="maquina!='undefined'"> {{maquina}} </label>
                  <label *ngIf="maquina=='undefined'"> {{'desconocido' | translate}} </label>
                </ng-container>
              </span>
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="fechaini" title="{{ 'fechainicio' | translate }}" width="15%">
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="fechafin" title="{{ 'fechaentrega' | translate }}" width="15%">
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <!-- <kendo-grid-column field="planificadas" title="{{ 'planificados' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column> -->
        <!-- <kendo-grid-column field="hechos" title="{{ 'hechos' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column> -->
        <kendo-grid-column field="restantes" title="{{ 'restantes' | translate }}" width="12%">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox [(value)]="dataItem.restantesSelected" [format]="'n'" [min]="1" [max]="dataItem.restantes" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada">
            </kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                             filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                             filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                             filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                             filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                             filterContainsOperator="{{'filterContainsOperator' | translate}}"
                             filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                             filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                             filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                             filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                             filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                             filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                             filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                             filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                             filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                             filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                             filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                             filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                             filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                             filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                             groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                             noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
      </kendo-grid>
    </div>
  </div>

  <!-- <kendo-dialog-actions> -->
  <div class="modal-footer modal-botoiak-erdian">

    <div class="form-group">
      <div class="form-group form-check">
        <!-- <label class="crontol-label">{{ 'meterCadaOperacionEnSuMaquina' | translate }}</label> -->
        <div class="caja">
          <!-- <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="todasMaquinas"
                          (valueChange)="eliminarValorMaquina();"> </kendo-switch> -->
          <button type="button" class="btn" [class.btn-success]="todasMaquinas"
                  [class.btn-deselccionado]="!todasMaquinas" (click)="eliminarValorMaquina();">
            <i class="fas fa-check" *ngIf="todasMaquinas"></i>
            <i class="fas fa-times" *ngIf="!todasMaquinas"></i>
            {{ 'meterCadaOperacionEnSuMaquina' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <!-- <label class="crontol-label">{{ 'maquina' | translate }}</label> -->
      <div class="caja">
        <kendo-combobox [data]="JmaquinasMostradas"
                        [textField]="'nombre'"
                        [valueField]="'id'"
                        [(value)]="JmaquinaSelected"
                        [class.requerido]="requiereMaquina"
                        placeholder="{{ 'maquina' | translate }}"
                        [kendoDropDownFilter]="{operator: 'contains'}"
                        [disabled]="todasMaquinas">
        </kendo-combobox>
      </div>
    </div>

    <div class="form-group">
      <div class="form-group form-check">
        <!-- <label class="crontol-label">{{ 'meterCadaOperacionEnSemEntrega' | translate }}</label> -->
        <div class="caja">
          <!-- <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="asignarSemanaEntrega"
                          (valueChange)="eliminarValorSemana();"> </kendo-switch> -->
          <button type="button" class="btn" [class.btn-success]="asignarSemanaEntrega"
                  [class.btn-deselccionado]="!asignarSemanaEntrega" (click)="eliminarValorSemana();">
            <i class="fas fa-check" *ngIf="asignarSemanaEntrega"></i>
            <i class="fas fa-times" *ngIf="!asignarSemanaEntrega"></i>

            {{ 'meterCadaOperacionEnSemEntrega' | translate }}
          </button>

        </div>
      </div>
    </div>

    <div class="form-group">
      <!-- <label class="crontol-label ">{{ 'semana' | translate }}</label> -->
      <div class="caja ">
        <kendo-combobox [data]="Jsemanas"
                        [textField]="'text'"
                        [valueField]="'value'"
                        [(value)]="JsemanaSelected"
                        [class.requerido]="requiereSemana"
                        placeholder="{{ 'semana' | translate }}"
                        [kendoDropDownFilter]="{operator: 'contains'}"
                        [disabled]="asignarSemanaEntrega">
        </kendo-combobox>
      </div>
    </div>

    <div>
      <div *ngIf="loading_anadir" class="k-i-loading" style="z-index: 3;">
        <div class="texto-loading-panel" *ngIf="secciones == undefined">
        </div>
      </div>
      <button type="button" class="btn btn-success" (click)="btnAnadir()" [disabled]="this.user.planificadorLargo<2">{{ 'anadir' | translate }}</button>
    </div>
    <button type="button" class="btn btn-danger mb-1" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <!-- (click)="cerrarPopUp()" -->


  </div>

  <!-- </kendo-dialog-actions> -->
  <!-- </kendo-dialog> -->
</ng-template>
<!-- </div> -->

<!-- POPUP: MOVER OPERACION PARTIDA -->
<ng-template #popupOperacionPartida let-modal>
  <div class="modal-header">
    <h3>
      <!--<label>{{'titulo' | translate }}</label>-->
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'arrastrarOperacionRepartidaEnSemanas' | translate }}</label>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')" [disabled]="this.user.planificadorLargo<2">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnOperacionPartidaAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: MOVER OPERACIONES -->
<ng-template #popupMoverOperaciones let-modal>
  <div class="modal-header">
    <h3>
      <!--<label>{{'titulo' | translate }}</label>-->
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'moverTodasOperacionesRelacionadas' | translate }}</label>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger" (click)="btnMoverOperacionesCancelar()">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnMoverOperacionesAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: VETE AL CORTO -->
<ng-template #popupVeteAlCorto let-modal>
  <div class="modal-header">
    <h3>
      <!--<label>{{'titulo' | translate }}</label>-->
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'veteAlCorto' | translate }}</label>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>