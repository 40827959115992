import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/gestionDocumental`;
@Injectable()
export class GestionDocumentalService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  importar(data) {
    return this.http.post<any>(baseUrl + "/importar", data, { withCredentials: true });
  }

  crearDocumento(idRuta, nombre, ficheroLink, esLink, esDirectorio) {
    return this.http.post<JSON>(`${baseUrl}/documento/crear`, { idActivo: idRuta, nombre: nombre, //idActivo es idRuta, por la reutilizaci�n
      ficheroLink: ficheroLink, esLink: esLink, esDirectorio: esDirectorio }, { withCredentials: true });
  }

  editarDocumento(antesFichero, ficheroViejo, idDocumento, idRuta, nombre, ficheroLink, esLink, esDirectorio) { //idActivo es idRuta, por la reutilizaci�n
    return this.http.post<JSON>(`${baseUrl}/documento/editar`, { antesFichero: antesFichero, ficheroViejo: ficheroViejo, id: idDocumento, idActivo: idRuta, nombre: nombre,
      ficheroLink: ficheroLink, esLink: esLink, esDirectorio: esDirectorio }, { withCredentials: true });
  }

  deleteDocumentos(ids: string, files, idsRutas) {//idActivo es idRuta, por la reutilizaci�n
    return this.http.post<JSON>(`${baseUrl}/deleteDocumentos`, { ids: ids, files: files, idsActivos: idsRutas }, { withCredentials: true });
  }

  getAllDocumentos(idRuta) {
    return this.http.get(`${baseUrl}/getAllDocumentos/${idRuta}`, { withCredentials: true });
  }
  getDocumentoById(idDocumento) {
    return this.http.get(`${baseUrl}/getDocumentoById/${idDocumento}`, { withCredentials: true });
  }

  //PREDEFINIDAS

  crearDocumentoPredefinida(idRuta, nombre, ficheroLink, esLink, esDirectorio) {
    return this.http.post<JSON>(`${baseUrl}/documentoPredefinida/crear`, { idActivo: idRuta, nombre: nombre, //idActivo es idRuta, por la reutilizaci�n
      ficheroLink: ficheroLink, esLink: esLink, esDirectorio: esDirectorio }, { withCredentials: true });
  }

  editarDocumentoPredefinida(antesFichero, ficheroViejo, idDocumento, idRuta, nombre, ficheroLink, esLink, esDirectorio) { //idActivo es idRuta, por la reutilizaci�n
    return this.http.post<JSON>(`${baseUrl}/documentoPredefinida/editar`, { antesFichero: antesFichero, ficheroViejo: ficheroViejo, id: idDocumento, idActivo: idRuta, nombre: nombre,
      ficheroLink: ficheroLink, esLink: esLink, esDirectorio: esDirectorio }, { withCredentials: true });
  }

  deleteDocumentosPredefinidas(ids: string, files, idsRutas) {//idActivo es idRuta, por la reutilizaci�n
    return this.http.post<JSON>(`${baseUrl}/deleteDocumentosPredefinidas`, { ids: ids, files: files, idsActivos: idsRutas }, { withCredentials: true });
  }

  getAllDocumentosPredefinidas(idRuta) {
    return this.http.get(`${baseUrl}/getAllDocumentosPredefinidas/${idRuta}`, { withCredentials: true });
  }
  getDocumentoByIdPredefinida(idDocumento) {
    return this.http.get(`${baseUrl}/getDocumentoByIdPredefinida/${idDocumento}`, { withCredentials: true });
  }

  

}
