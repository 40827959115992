<div class="row">
    <div class="col-calendario">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label CssClass="">{{ 'calendario' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div>
                    <app-calendario [lunes]="lunes" [martes]="martes" [miercoles]="miercoles" [jueves]="jueves"
                        [viernes]="viernes" [sabado]="sabado" [domingo]="domingo" [idCalendario]="id"
                        [calendarioInicio]="calendarioInicio" [calendarioFin]="calendarioFin" [nombre]="nombre"
                        [calendario]="calendario" [activo]="activo" [ultimoId]="ultimoId"
                        (diasInactivos)="actualizarDiasInactivos($event)" (diasActivos)="actualizarDiasActivos($event)"
                        (yearEnviado)="actualizarAno($event)" (nombreVacio)="actualizarNombreVacio($event)"
                        (fechasVacias)="actualizarFechasVacias($event)">
                    </app-calendario>
                </div>
            </div>
        </div>
    </div>
    <div class="col-datos-calendario ">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label CssClass="">{{ 'gestionarCalendario' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">

              <div class="form-goup">
                <kendo-label text="{{'nombre' | translate}}">
                  <kendo-textbox [disabled]="false" [ngClass]="{'is-invalid':requiereNombre}" placeholder=""
                                 [(value)]="nombre"></kendo-textbox>
                
                <div *ngIf="requiereNombre" class="invalid-feedback">
                  <div>{{ 'nombrerequerido' | translate}}</div>
                </div>
              </kendo-label>
              </div>
              <kendo-label text="{{ 'periodoCalendario' | translate}}"></kendo-label>
              <div class="form-goup clearfix">
                <filtro-fechas [fechaIni]="calendarioInicio" [fechaFin]="calendarioFin" [ngClass]="{ 'is-invalid': requiereFechas }"
                               (newFechaInicio)="actualizarFechaInicio($event)" (newFechaFin)="actualizarFechaFin($event)">
                </filtro-fechas>
                <div *ngIf="requiereFechas" class="invalid-feedback">
                  <div>{{ 'periodoRequerido' | translate}}</div>
                </div>
              </div>
              <div class="calendar-switches clearfix">
                <div class="form-group mr-2">
                  <kendo-label text="{{ 'lunes' | translate}}"> </kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioLunes()" [checked]="lunes" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <kendo-label text="{{ 'martes' | translate}}"> </kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioMartes()" [checked]="martes" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <kendo-label text="{{ 'miercoles' | translate}}"> </kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioMiercoles()" [checked]="miercoles" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>

                <div class="form-group mr-2">
                  <kendo-label text="{{ 'jueves' | translate}}"></kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioJueves()" [checked]="jueves" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <kendo-label text="{{ 'viernes' | translate}}"> </kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioViernes()" [checked]="viernes" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <kendo-label text="{{ 'sabado' | translate}}"> </kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioSabado()" [checked]="sabado" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
                <div class="form-group mr-2">
                  <kendo-label text="{{ 'domingo' | translate}}"> </kendo-label>
                  <div class="caja">
                    <kendo-switch (valueChange)="cambioDomingo()" [checked]="domingo" [onLabel]="' '"
                                  [offLabel]="' '">
                    </kendo-switch>
                  </div>

                </div>
              </div>
              <!--<div *ngIf=!isAddMode>-->
                <div class="calendar-diasActivosInactivos">
                  <div class="row">                   
                    <div class="col">
                      <div class="form-group mr-2">
                        <kendo-label text="{{ 'diasInactivos' | translate}}">
                          <kendo-textbox [disabled]="false" [(value)]="diasInactivos"></kendo-textbox>
                        </kendo-label>
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group mr-2">
                        <kendo-label text="{{ 'diasActivos' | translate}}">
                          <kendo-textbox [disabled]="false" [(value)]="diasActivos"></kendo-textbox>
                        </kendo-label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="calendar-botones">
                  <!--<button kendoButton id="duplicarCalendario" (click)="onClickCopiarCalendario()"
                          class="btn mr-1  btn-copiar btn-sm mr-1 mb-2 w-100">
                    {{
 'duplicarCalendario' |
                                translate
                    }}
                  </button>-->
                  <button kendoButton id="periodoInactivo"
                          class="btn mr-1  btn-secondary btn-sm mr-1 mb-2 w-100">
                    {{
 'periodoInactivo' |
                                translate
                    }}
                  </button>               
                  <button kendoButton id="eliminarPeriodo"
                          class="btn mr-1  btn-primary btn-sm mr-1 mb-2 w-100">
                    {{
                                'periodoActivo' | translate
                    }}
                  </button>
                  <!--<button kendoButton id="periodoActivo"
                          class="btn mr-1  btn-primary btn-sm mr-1 mb-2 w-100">
                    {{
                                'periodoActivo' | translate
                    }}
                  </button>
                  <button kendoButton id="eliminarPeriodo"
                          class="btn mr-1  btn-danger btn-sm mr-1 mb-2 w-100">
                    {{
                                'eliminarPeriodo' | translate
                    }}
                  </button>-->
                </div>
                <div class="calendar-leyenda">
                  <div class="row">
                  </div>
                  <div class="row">
                    <span class="colorleyenda calendario-periodoActivo">1</span><span>
                      {{
 'periodoActivo' |
                                    translate
                      }}
                    </span>
                  </div>
                  <div class="row">
                    <span class="colorleyenda calendario-deshabilitado">1</span><span>
                      {{
 'periodoInactivo' |
                                    translate
                      }}
                    </span>
                  </div>
                  <!--<div class="row">
                    <span class="colorleyenda calendario-periodoInactivo">1</span><span>
                      {{
 'periodoInactivo'
                                    |
                                    translate
                      }}
                    </span>
                  </div>-->
                </div>

              <!--</div>-->
                <div class="form-group mr-2">
                  <!--<kendo-switch class="kendo-switch-tipo2" (valueChange)="cambioActivo()" [checked]="activo" [onLabel]="'Activado'"
                                [offLabel]="'Desactivado'">
                  </kendo-switch>-->


                </div>
              

            </div>
        </div>
    </div>
</div>
<!-- POPUP: Copiar a calendario  -->
<ng-template #popupCopiar let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'seleccioneCalendario' | translate }}</label>
        <div class="row">
            <div class="col form-group">
                <kendo-label text="{{ 'nombre' | translate}}">
                    <kendo-dropdownlist class="w-100" [data]="listItemsCalendario" [(ngModel)]="calendarioSelectedModal"
                        valuePrimitive="true" [textField]="'nombre'" [valueField]="'id'">
                    </kendo-dropdownlist>
                </kendo-label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptar()">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>
<router-outlet></router-outlet>
