<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div *ngIf="loading" class="k-i-loading" style="z-index: 3;"></div>

<!-- FILTRO -->
<div class="card">
  <div class="clearfix">
    <div class="card-body">
      <!-- GRUPOS MAQUINAS -->
      <div class="float-left mr-3 d-none">
        <div class="form-group">
          <label class="control-label">{{ 'maquinasgrupos' | translate }}</label>
          <div class="caja">
            <kendo-combobox style="width:150px" [data]="gruposMaquinas" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionGrupoMaquinaChange($event)" class="form-control"
                            [value]="selectedGrupo" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <!-- MAQUINAS -->
      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'maquinas' | translate }}</label>
          <div class="caja">
            <kendo-combobox style="width:250px" [data]="Jmaquinas" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionMaquinaChange($event)" [value]="selectedMaquinaC"
                            class="form-control" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <!-- FECHA INICIO -->
      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'fechainicio' | translate}}</label>
          <div class="caja">
            <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..." class="form-control" (valueChange)="onFechaIniChange($event)" [(value)]="fechaIniValue">
            </kendo-datepicker>
          </div>
        </div>
      </div>
      <!-- FECHA FIN -->
      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'fechafin' | translate}}</label>
          <div class="caja">
            <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..." class="form-control" (valueChange)="onFechaFinChange($event)" [(value)]="fechaFinValue">
            </kendo-datepicker>
          </div>
        </div>
      </div>
      <!-- ESTADO -->
      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'estado' | translate }}</label>
          <div class="caja">
            <kendo-combobox style="width:150px" [data]="estadosMantenimientos_DAT" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionTipoChange($event)"
                            [value]="selectedTipo" class="form-control" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <!-- MANTENIMIENTO -->
      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'mantenimiento' | translate }}</label>
          <div class="caja">
            <kendo-combobox style="width:450px" [popupSettings]="{width: 450,height: 600}" [data]="Mantenimientos_DAT" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionMantenimientoChange($event)"
                            [value]="selectedMantenimiento" class="form-control" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <!-- BOTON -->
      <div class="float-left mr-3">
        <div class="form-group">
          <div class="caja nolabel">
            <a (click)="onFilterClck()" class="btn btn-danger btn-sm mr-1">{{ 'filtro' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <!-- TOOLTIP -->
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <!-- GRID -->
  <div kendoTooltip
       showOn="none"
       [tooltipTemplate]="template"
       filter=".k-grid td"
       (mouseover)="showGridTooltip($event)">
    <kendo-grid [kendoGridBinding]="historicoMantenimientos"
                [groupable]="true"
                [sortable]="true"
                [navigable]="true"
                filterable="menu"
                kendoGridSelectBy="idHMantenimiento"
                scrollable="virtual"
                [rowHeight]="36"
                [height]="700"
                [resizable]="true"
                [selectedKeys]="seleccionados"
                (excelExport)="onExcelExportOneLine($event)"
                >
      <!--BOTONES ARRIBA-->
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="float-left">
          <button (click)="clickEditar(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
        </div>
        <button class="btn mr-1  btn-copiar btn-sm mr-1" kendoGridExcelCommand>{{ 'exportar' | translate}}</button>
      </ng-template>
      <kendo-grid-checkbox-column showSelectAll="true" width="3"></kendo-grid-checkbox-column>
      <!--FECHA-->
      <kendo-grid-column field="fecha" title="{{ 'fechateorica' | translate }}" width="10">
        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fecha | kendoDate }} {{dataItem.fecha | kendoDate:'HH:mm:ss' }}</ng-template>
      </kendo-grid-column>
      <!--INICIO-->
      <kendo-grid-column field="fechaIni" title="{{ 'inicio' | translate }}" width="10">
        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaIni | kendoDate }} {{dataItem.fechaIni | kendoDate:'HH:mm:ss' }}</ng-template>
      </kendo-grid-column>
      <!--FIN-->
      <kendo-grid-column field="fechaFin" title="{{ 'fin' | translate }}" width="10">
        <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaFin | kendoDate }} {{dataItem.fechaFin | kendoDate:'HH:mm:ss' }}</ng-template>
      </kendo-grid-column>
      <!--DURACION-->
      <kendo-grid-column width="7" [style]="{'text-align': 'left'}" field="duracion" title="{{ 'duracion' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.duracion)}}</ng-template>
      </kendo-grid-column>
      <!--OPERARIO-->
      <kendo-grid-column width="15" [style]="{'text-align': 'left'}" field="operario" title="{{ 'operario' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <label *ngIf="dataItem.operario==' '">{{'desconocido' | translate}}</label>
          <label *ngIf="dataItem.operario!=' '">{{ dataItem.operario }}</label>
        </ng-template>
      </kendo-grid-column>
      <!--MAQUINA-->
      <kendo-grid-column width="15" [style]="{'text-align': 'left'}" field="maquina" title="{{ 'maquina' | translate}}"></kendo-grid-column>
      <!--MANTENIMIENTO-->
      <kendo-grid-column width="15" [style]="{'text-align': 'left'}" field="mantenimiento" title="{{ 'mantenimiento' | translate}}"></kendo-grid-column>
      <!--DESCRIPCION-->
      <!--<kendo-grid-column width="30" [style]="{'text-align': 'left'}" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>-->
      <!--TIPO-->
      <kendo-grid-column width="8" field="frecuencia" [style]="{'text-align': 'left'}" title="{{ 'tipo' | translate}}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.tipo | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <!--ESTADO-->
      <kendo-grid-column width="7" field="estadox" [style]="{'text-align': 'left'}" title="{{ 'estado' | translate}}" class="celda-tooltip-externo-header p-0">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- <span>{{ dataItem.estado | translate }}</span> -->
          <span class="celda-completa text-center" [ngClass]="{'bg-rojo': dataItem.idEstado != 3,
            'bg-verde': dataItem.idEstado == 3}">
            <span class="icon-equis" *ngIf="dataItem.idEstado != 3"></span>
            <span class="icon-check" *ngIf="dataItem.idEstado == 3"></span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <!--PERDIDA-->
      <kendo-grid-column field="perdida" [style]="{'text-align': 'left'}" title="{{ 'perdida' | translate}}" width="8">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{ dataItem.perdida | translate }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-excel [fileName]="getFileName()" >

      </kendo-grid-excel>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                            filterContainsOperator="{{'filterContainsOperator' | translate}}"
                            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/">
      </kendo-grid-messages>
    </kendo-grid>
  </div>
</div>


<!-- POPUP -->
<ng-template #popupEditar let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'mantenimiento' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- FECHAS -->
    <div class="row">
      <!-- FECHA -->
      <div class="form-group col">
        <label>{{ 'fecha' | translate }}</label>
        <div class="caja">
          <kendo-datetimepicker [(value)]="rowSelected.fecha" class="form-control" [disabled]="!rowSelected.editable"></kendo-datetimepicker>
        </div>
      </div>
      <!-- FECHA INICIO -->
      <div class="form-group col">
        <label>{{ 'inicio' | translate }}</label>
        <div class="caja">
          <kendo-datetimepicker [(value)]="rowSelected.fechaIni" class="form-control" [disabled]="!rowSelected.editable"></kendo-datetimepicker>
        </div>
      </div>
      <!-- FECHA FIN -->
      <div class="form-group col">
        <label>{{ 'fin' | translate }}</label>
        <div class="caja">
          <kendo-datetimepicker [(value)]="rowSelected.fechaFin" class="form-control" [disabled]="!rowSelected.editable"></kendo-datetimepicker>
        </div>
      </div>
      <!-- DURACION -->
      <div class="form-group col">
        <kendo-label text="{{ 'duracion' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-textbox [(value)]="rowSelected.duracionHHMM" [disabled]="true"></kendo-textbox>
        </div>
      </div>
      <!-- ESTADO -->
      <div class="form-group col">
        <kendo-label text="{{ 'estado' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-combobox style="width:150px" [data]="Jestados" [textField]="'nombre'" [valueField]="'id'" class="form-control" 
                          [(value)]="JestadosSelected" [kendoDropDownFilter]="{operator: 'contains'}">
          </kendo-combobox>
        </div>
      </div>
    </div>
    <!-- MAQUINA -->
    <div class="form-group">
      <kendo-label text="{{ 'maquina' | translate }}"></kendo-label>
      <div class="caja">
        <kendo-textbox [(value)]="rowSelected.maquina" [disabled]="true"></kendo-textbox>
      </div>
    </div>
    <div class="row">
      <!-- MANTENIMIENTO -->
      <div class="form-group col">
        <kendo-label text="{{ 'mantenimiento' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-textbox [(value)]="rowSelected.mantenimiento" [disabled]="true"></kendo-textbox>
        </div>
      </div>
      <!-- TIPO -->
      <div class="form-group col">
        <kendo-label text="{{ 'tipo' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-textbox [(value)]="rowSelected.tipo" [disabled]="true"></kendo-textbox>
        </div>
      </div>
      <!-- PERDIDA -->
      <div class="form-group col">
        <kendo-label text="{{ 'perdida' | translate }}"></kendo-label>
        <div class="caja">
          <kendo-textbox [(value)]="rowSelected.perdida" [disabled]="true"></kendo-textbox>
        </div>
      </div>
    </div>
    <!-- DESCRIPCION -->
    <div class="form-group">
      <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
      <div class="caja">
        <textarea kendoTextArea [(value)]="rowSelected.descripcion" [disabled]="true"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="clickEliminar()" *ngIf="rowSelected.editable" [disabled]="this.user.historicoMantenimientos < 2">{{ 'eliminar' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="actualizaqrMantenimiento()" [disabled]="this.user.historicoMantenimientos < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: ELIMINAR -->
<ng-template #popupEliminar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaEliminarMantenimiento' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro()" [disabled]="this.user.historicoMantenimientos < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
