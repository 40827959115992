import { Component, Renderer2, NgZone } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { UsuariosService, MaquinasService, AlertService, InformesPersonalizadosDisenadorService, InformeProyectosService, MenuService, InformePersonalizadoService, AppComponent } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { Subscription, fromEvent } from 'rxjs';
import { State, process, groupBy } from "@progress/kendo-data-query";
import { tap, take } from 'rxjs/operators';

// Drag and drop
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';

@Component({
  selector: 'app-editar-informes-personalizados',
  templateUrl: './editarInformesPersonalizadosDisenador.component.html',
})

export class EditarInformesPersonalizadosDisenadorComponent {

  //Variables basicas
  private translate: TranslateService;

  //Seleccion
  public seleccionados: any[] = [];
  public nombreTipoModuloSeleccionado: any;
  public idTipoModuloSeleccionado: any;
  public origenSeleccionado: any;
  public alturaTipoModuloSeleccionado: any;
  public anchuraTipoModuloSeleccionado: any;
  public columnasSeleccionadas: any[] = [];

  // Filtro Maquinas y Operarios
  public filtrarMaquinas: boolean = false;
  public filtrarOperarios: boolean = false;

  public filtroCompletoMaquinas: any;
  public filtroMaquinasSecciones: any;
  public filtroMaquinas: any;
  public filtroOperarios: any;
  public filtroSecciones: any = [];
  public filtroGrupos: any = [];

  public filtroMaquinasSeleccionado: any = [];
  public filtroOperariosSeleccionado: any = [];
  public filtroSeccionesSeleccionado: any = [];
  public filtroGruposSeleccionado: any = [];

  //Informacion de la tabla
  public cantidadLineasTabla: number = 10;
  public excluir: boolean = false;
  public tamannos: any = [];
  public orden: any = [];

  //Agregacion de modulos
  public lineaActual: any;
  public columnaActual: any;
  public tituloModulo: any;
  public anchuraModulo: any;
  public modulos: any[] = [];
  public tituloAdecuado: boolean = true;
  public origenAdecuado: boolean = true;

  //Informes
  public tituloInforme: string;
  public idInforme: string;
  public listaInformes: any[] = [];

  //Lineas
  public lineas: any[] = [];

  //Menu abierto/cerrado
  public menuAbierto: boolean = false;

  //Para cambiar el contenido del menu
  public eligiendoTipoModulo: boolean = true;
  public eligiendoContenidoModulo: boolean = false;

  //Listas
  public tiposGrafico: any[] = [];
  public origenesDatos: any[] = [];
  public columnasTabla: any[] = [];
  public idsLineas: any[] = [];

  //El FormGroup para validar
  public formTitulo: FormGroup = new FormGroup({
    titulo: new FormControl()
  });
  public tituloValido = true;

  //Drag and drop
  public state: State = {
    skip: 0,
    take: 100,
  };
  public gridData: any = process([], this.state);
  private currentSubscription: Subscription;

  //Constructor
  constructor(
    private informeProyectosService: InformeProyectosService,
    translate: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService, public router: Router,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    public informesPersonalizadosDisenadorService: InformesPersonalizadosDisenadorService,
    private informePersonalizadoService: InformePersonalizadoService,
    private renderer: Renderer2,
    private zone: NgZone,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private appComponent: AppComponent) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('informespersonalizados').toUpperCase();
    this.idInforme = this.route.snapshot.params['id'];
  }

  /**
   * Funcion ngOnInit
   */
  ngOnInit() {

    this.informesPersonalizadosDisenadorService.Get_TiposGrafico().subscribe(
      (result: any) => {
        this.tiposGrafico = result;
      });

    // SECCIONES
    this.userService.getSecciones().subscribe(
      json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.filtroSecciones = an1.filter(f => f.activo === true);
        var an: any = this.filtroSecciones;
        this.filtroSecciones = groupBy(an, [{ field: 'areaProductiva' }]);
      });

    // grupos
    this.maquinasService.getGruposMaquinas().subscribe(
      json => {
        this.filtroGrupos = json.data;
      }
    );

    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      data => {
        this.filtroMaquinas = data;
        // // selecteds
        // this.filtroMaquinasSeleccionado = [];
        // // procesar las secciones y grupos
        // var secciones = [];
        // this.filtroMaquinas.forEach(element => {
        //   element['nombreSeccion'] = this.translate.instant("seccion") + " " + element.idSeccion;
        //   element['nombreGrupo'] = this.translate.instant("grupo") + " " + element.idGrupos;

        //   if (!secciones.includes(element.idSeccion)) {
        //     this.filtroSecciones.push(element);
        //     secciones.push(element.idSeccion);
        //   } 

        // });
        this.filtroCompletoMaquinas = this.myFunctions.copy(this.filtroMaquinas);
      });
    
    //OPERARIOS
    this.informeProyectosService.Get_Operarios().subscribe((data: any) => {
      this.filtroOperarios = data;
      // selecteds
      this.filtroOperariosSeleccionado = [];
      this.filtroOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));
    });

    this.informesPersonalizadosDisenadorService.Get_Informes().subscribe(
      (result: any) => {
        result = result.filter(obj => {
          return obj.id == this.idInforme;
        })
        this.tituloInforme = result[0].nombre;

        this.informesPersonalizadosDisenadorService.Get_LineasByIdInforme(result[0].id).subscribe(
          (result: any) => {
            this.lineas = result;
            this.lineas.forEach(linea => {
              var count = 0;
              if (linea.idModulo1 != -1 && linea.idModulo1 != undefined) count++;
              if (linea.idModulo2 != -1 && linea.idModulo2 != undefined) count++;
              if (linea.idModulo3 != -1 && linea.idModulo3 != undefined) count++;
              linea.columnas = count;
              linea.nombreModulo1 = "";
              linea.nombreModulo2 = "";
              linea.nombreModulo3 = "";
              this.tiposGrafico.forEach(tipo => {
                if (tipo.id == linea.idTipoModulo1) linea.nombreModulo1 = tipo.nombre;
                if (tipo.id == linea.idTipoModulo2) linea.nombreModulo2 = tipo.nombre;
                if (tipo.id == linea.idTipoModulo3) linea.nombreModulo3 = tipo.nombre;
              });
              // linea.idModulo1 = linea.idTipoModulo1;
              // linea.idModulo2 = linea.idTipoModulo2;
              // linea.idModulo3 = linea.idTipoModulo3;


              linea.modulo1ColumnasTabla = linea.modulo1ColumnasTabla.split(",");
              linea.modulo2ColumnasTabla = linea.modulo2ColumnasTabla.split(",");
              linea.modulo3ColumnasTabla = linea.modulo3ColumnasTabla.split(",");

              linea.modulo1ColumnasOrden = linea.modulo1ColumnasOrden.split(",");
              linea.modulo2ColumnasOrden = linea.modulo2ColumnasOrden.split(",");
              linea.modulo3ColumnasOrden = linea.modulo3ColumnasOrden.split(",");

              linea.modulo1ColumnasTamanno = linea.modulo1ColumnasTamanno.split(",");
              linea.modulo2ColumnasTamanno = linea.modulo2ColumnasTamanno.split(",");
              linea.modulo3ColumnasTamanno = linea.modulo3ColumnasTamanno.split(",");

              linea.modulo1ColumnasActivo = linea.modulo1ColumnasActivo.split(",");
              linea.modulo2ColumnasActivo = linea.modulo2ColumnasActivo.split(",");
              linea.modulo3ColumnasActivo = linea.modulo3ColumnasActivo.split(",");

              linea.modulo1ColumnasActivo = linea.modulo1ColumnasActivo.map(j => JSON.parse(j));
              linea.modulo2ColumnasActivo = linea.modulo2ColumnasActivo.map(j => JSON.parse(j));
              linea.modulo3ColumnasActivo = linea.modulo3ColumnasActivo.map(j => JSON.parse(j));

              this.idsLineas.push(linea.idLinea);
            });
          });
      });


  }

  /**
   * Funcion que se llama cada vez que se crea una linea nueva
   * @param nCol cantidad de columnas a crear
   */
  anadirLinea(nCol: number) {

    this.lineas.push({
      oculto: false,
      idLinea: -1,
      idModulo1: -1,
      idModulo2: -1,
      idModulo3: -1,
      idTipoModulo1: -1,
      idTipoModulo2: -1,
      idTipoModulo3: -1,
      nombreModulo1: '',
      nombreModulo2: '',
      nombreModulo3: '',
      tituloModulo1: '',
      tituloModulo2: '',
      tituloModulo3: '',
      modulo1Anchura: 4-nCol,
      modulo2Anchura: 4-nCol,
      modulo3Anchura: 4-nCol,
      modulo1Altura: 0,
      modulo2Altura: 0,
      modulo3Altura: 0,
      columnas: nCol,
      modulo1ColumnasTabla: [-1],
      modulo2ColumnasTabla: [-1],
      modulo3ColumnasTabla: [-1],
      modulo1ColumnasActivo: [false],
      modulo2ColumnasActivo: [false],
      modulo3ColumnasActivo: [false],
      modulo1ColumnasTamanno: [-1],
      modulo2ColumnasTamanno: [-1],
      modulo3ColumnasTamanno: [-1],
      modulo1CantidadLineasTabla: 10,
      modulo2CantidadLineasTabla: 10,
      modulo3CantidadLineasTabla: 10,
      modulo1Excluir: false,
      modulo2Excluir: false,
      modulo3Excluir: false,
      modulo1ColumnasOrden: [-1],
      modulo2ColumnasOrden: [-1],
      modulo3ColumnasOrden: [-1],
      modulo1filtrarMaquinas: "-1",
      modulo2filtrarMaquinas: "-1",
      modulo3filtrarMaquinas: "-1",
      modulo1filtrarOperarios: "-1",
      modulo2filtrarOperarios: "-1",
      modulo3filtrarOperarios: "-1"
    });
  }

  /**
   * Funcion para eliminar una linea 
   * @param indice indice de la linea a eliminar
   */
  eliminarLinea(indice: number) {

    this.lineas = this.lineas.filter(function (value, index, arr) {
      return index != indice;
    });
  }

  /**
   * Funcion llamada al darle al boton de añadir nuevo modulo
   * @param linea indice de la linea en el que se va a añadir
   * @param columna indice de la columna en el que se va a añadir
   */
  botonAnnadirModulo(linea: number, columna: number) {

    this.menuAbierto = !this.menuAbierto;
    this.lineaActual = linea;
    this.columnaActual = columna;
    this.tituloAdecuado = true;
    this.origenAdecuado = true;

    var mod = "idTipoModulo" + columna;
    if (this.lineas[linea][mod] == -1) {
      // Resetear los valores
      this.excluir = false;
      this.tituloModulo = '';
      this.origenSeleccionado = undefined;
      this.cantidadLineasTabla = 10;
      this.filtrarMaquinas = false;
      this.filtrarOperarios = false;
      this.filtroMaquinasSeleccionado = [];
      this.filtroOperariosSeleccionado = [];
      this.filtroSeccionesSeleccionado = [];
      this.filtroGruposSeleccionado = [];
      this.filtroMaquinas = this.myFunctions.copy(this.filtroCompletoMaquinas);
      this.anchuraModulo = Math.floor(100 / (4 - this.lineas[linea]['modulo' + columna + 'Anchura']));
      this.tamannos = [];
      this.orden = [];
    } else {
      // Establecer los valores antiguos
      this.seleccionarTipoModulo(this.lineas[linea]["idTipoModulo" + columna], "", this.lineas[linea]["modulo" + columna + "Anchura"], this.lineas[linea]["modulo" + columna + "Altura"], true, linea, columna);
      this.tituloModulo = this.lineas[linea]["tituloModulo" + columna];
      this.excluir = this.lineas[linea]["modulo" + columna + "Excluir"];
      this.anchuraModulo = this.lineas[linea]["modulo" + columna + "Anchura"];
      // asignar el tamanno de la tabla
      this.tamannos = this.lineas[linea]["modulo" + columna + "ColumnasTamanno"];
      this.orden = this.lineas[linea]["modulo" + columna + "ColumnasOrden"];

      this.filtroMaquinasSeleccionado = this.filtroCompletoMaquinas.filter(f => this.lineas[linea]['modulo' + columna + 'filtrarMaquinas'].split(",").includes(f.idMaquina.toString()));
      this.filtroOperariosSeleccionado = this.filtroOperarios.filter(f => this.lineas[linea]['modulo' + columna + 'filtrarOperarios'].split(",").includes(f.idOperario.toString()));
      if (this.filtroMaquinasSeleccionado.length != 0) {
        this.filtrarMaquinas = true;
        var secciones = [];
        var grupos = [];
        this.filtroSeccionesSeleccionado = [];
        this.filtroGruposSeleccionado = [];
        this.filtroMaquinasSeleccionado.forEach(element => {
          if (!secciones.includes(element.idSeccion)) {
            this.filtroSeccionesSeleccionado.push(element);
          }

          if (!grupos.includes(element.idGrupos)) {
            this.filtroGruposSeleccionado.push(element);
          }
        });
        // this.seccionChange(this.filtroSeccionesSeleccionado);
        // this.grupoChange(this.filtroGruposSeleccionado);
      } else {
         this.filtrarMaquinas = false;
         this.filtroMaquinasSeleccionado = [];
         this.filtroSeccionesSeleccionado = [];
         this.filtroGruposSeleccionado = [];
      }

      if (this.filtroOperariosSeleccionado.length != 0) this.filtrarOperarios = true;
      else this.filtrarOperarios = false;
    }
  }

  /**
   * Funcion llamada al darle 'Añadir' en el menu de modulo 
   */
  agregarModulo() {

    if (this.tituloModulo == '' || this.origenSeleccionado == undefined) {
      if (this.tituloModulo == '') this.tituloAdecuado = false;
      else this.tituloAdecuado = true;
      if (this.origenSeleccionado == undefined) this.origenAdecuado = false;
      else this.origenAdecuado = true;
      return;
    }

    if (this.columnaActual == 1) {
      this.lineas[this.lineaActual].idTipoModulo1 = this.idTipoModuloSeleccionado;
      this.lineas[this.lineaActual].nombreModulo1 = this.nombreTipoModuloSeleccionado;
      //this.lineas[this.lineaActual].modulo1Anchura = this.anchuraTipoModuloSeleccionado;
      this.lineas[this.lineaActual].modulo1Altura = this.alturaTipoModuloSeleccionado;
      this.lineas[this.lineaActual].tituloModulo1 = this.tituloModulo;
      this.lineas[this.lineaActual].origenModulo1 = this.origenSeleccionado.id;
      this.lineas[this.lineaActual].modulo1CantidadLineasTabla = this.cantidadLineasTabla;
      this.lineas[this.lineaActual].modulo1Excluir = this.excluir;
      this.lineas[this.lineaActual].modulo1Anchura = this.anchuraModulo;

      if (this.idTipoModuloSeleccionado == 7) {
        this.lineas[this.lineaActual].modulo1ColumnasTabla = [];
        this.lineas[this.lineaActual].modulo1ColumnasTamanno = [];
        this.lineas[this.lineaActual].modulo1ColumnasOrden = [];
        this.lineas[this.lineaActual].modulo1ColumnasActivo = [];
        this.columnasTabla.forEach(col => {
          this.lineas[this.lineaActual].modulo1ColumnasTabla.push(col.id);
          this.lineas[this.lineaActual].modulo1ColumnasTamanno.push(col.tamannoColumna);
          this.lineas[this.lineaActual].modulo1ColumnasOrden.push(col.orden);
          if (this.columnasSeleccionadas.includes(col.idReferencia)) 
            this.lineas[this.lineaActual].modulo1ColumnasActivo.push(true);
          else
            this.lineas[this.lineaActual].modulo1ColumnasActivo.push(false);
        });
      }

      this.annadirFiltroMaquinasOperarios(1);

      this.modulos.push({ idTipoModulo: this.idTipoModuloSeleccionado, nombreModulo: this.tituloModulo, origenModulo: this.origenSeleccionado.id, columna: 1 });
      this.menuAbierto = !this.menuAbierto;
    } else if (this.columnaActual == 2) {
      this.lineas[this.lineaActual].idTipoModulo2 = this.idTipoModuloSeleccionado;
      this.lineas[this.lineaActual].nombreModulo2 = this.nombreTipoModuloSeleccionado;
      //this.lineas[this.lineaActual].modulo2Anchura = this.anchuraTipoModuloSeleccionado;
      this.lineas[this.lineaActual].modulo2Altura = this.alturaTipoModuloSeleccionado;
      this.lineas[this.lineaActual].tituloModulo2 = this.tituloModulo;
      this.lineas[this.lineaActual].origenModulo2 = this.origenSeleccionado.id;
      this.lineas[this.lineaActual].modulo2CantidadLineasTabla = this.cantidadLineasTabla;
      this.lineas[this.lineaActual].modulo2Excluir = this.excluir;
      this.lineas[this.lineaActual].modulo2Anchura = this.anchuraModulo;

      if (this.idTipoModuloSeleccionado == 7) {
        this.lineas[this.lineaActual].modulo2ColumnasTabla = [];
        this.lineas[this.lineaActual].modulo2ColumnasTamanno = [];
        this.lineas[this.lineaActual].modulo2ColumnasOrden = [];
        this.lineas[this.lineaActual].modulo2ColumnasActivo = [];
        this.columnasTabla.forEach(col => {
          this.lineas[this.lineaActual].modulo2ColumnasTabla.push(col.id);
          this.lineas[this.lineaActual].modulo2ColumnasTamanno.push(col.tamannoColumna);
          this.lineas[this.lineaActual].modulo2ColumnasOrden.push(col.orden);
          if (col.idReferencia in this.columnasSeleccionadas) 
              this.lineas[this.lineaActual].modulo2ColumnasActivo.push(true);
          else
            this.lineas[this.lineaActual].modulo2ColumnasActivo.push(false);
        });
      }

      this.annadirFiltroMaquinasOperarios(2);
      
      this.modulos.push({ idTipoModulo: this.idTipoModuloSeleccionado, nombreModulo: this.tituloModulo, origenModulo: this.origenSeleccionado.id, columna: 2 });
      this.menuAbierto = !this.menuAbierto;
    } else if (this.columnaActual == 3) {
      this.lineas[this.lineaActual].idTipoModulo3 = this.idTipoModuloSeleccionado;
      this.lineas[this.lineaActual].nombreModulo3 = this.nombreTipoModuloSeleccionado;
      //this.lineas[this.lineaActual].modulo3Anchura = this.anchuraTipoModuloSeleccionado;
      this.lineas[this.lineaActual].modulo3Altura = this.alturaTipoModuloSeleccionado;
      this.lineas[this.lineaActual].tituloModulo3 = this.tituloModulo;
      this.lineas[this.lineaActual].origenModulo3 = this.origenSeleccionado.id;
      this.lineas[this.lineaActual].modulo3CantidadLineasTabla = this.cantidadLineasTabla;
      this.lineas[this.lineaActual].modulo3Excluir = this.excluir;
      this.lineas[this.lineaActual].modulo3Anchura = this.anchuraModulo;

      if (this.idTipoModuloSeleccionado == 7) {
        this.lineas[this.lineaActual].modulo3ColumnasTabla = [];
        this.lineas[this.lineaActual].modulo3ColumnasTamanno = [];
        this.lineas[this.lineaActual].modulo3ColumnasOrden = [];
        this.lineas[this.lineaActual].modulo3ColumnasActivo = [];
        this.columnasTabla.forEach(col => {
          this.lineas[this.lineaActual].modulo3ColumnasTabla.push(col.id);
          this.lineas[this.lineaActual].modulo3ColumnasTamanno.push(col.tamannoColumna);
          this.lineas[this.lineaActual].modulo3ColumnasOrden.push(col.orden);
          if (col.idReferencia in this.columnasSeleccionadas) 
            this.lineas[this.lineaActual].modulo3ColumnasActivo.push(true);
          else
            this.lineas[this.lineaActual].modulo3ColumnasActivo.push(false);
        });
      }

      this.annadirFiltroMaquinasOperarios(3);

      this.modulos.push({ idTipoModulo: this.idTipoModuloSeleccionado, nombreModulo: this.tituloModulo, origenModulo: this.origenSeleccionado.id, columna: 3 });
      this.menuAbierto = !this.menuAbierto;
    }

    this.tituloModulo = '';
    this.idTipoModuloSeleccionado = undefined;
    this.nombreTipoModuloSeleccionado = undefined;
    this.alturaTipoModuloSeleccionado = undefined;
    this.anchuraTipoModuloSeleccionado = undefined;
    this.origenSeleccionado = {};
    this.origenesDatos = [];
    this.tituloAdecuado = true;
    this.origenAdecuado = true;

    this.eligiendoTipoModulo = !this.eligiendoTipoModulo;
    this.eligiendoContenidoModulo = !this.eligiendoContenidoModulo;
  }

  annadirFiltroMaquinasOperarios(col) {
    // filtro maquinas
    if (this.filtrarMaquinas && this.filtroMaquinasSeleccionado.length != 0) {
      this.lineas[this.lineaActual]['modulo' + col + 'filtrarMaquinas'] = [];
      this.filtroMaquinasSeleccionado.forEach(element => {
        this.lineas[this.lineaActual]['modulo' + col + 'filtrarMaquinas'].push(element.idMaquina);
      });  
      this.lineas[this.lineaActual]['modulo' + col + 'filtrarMaquinas'] = this.lineas[this.lineaActual]['modulo' + col + 'filtrarMaquinas'].join(",");
    } else 
      this.lineas[this.lineaActual]['modulo' + col + 'filtrarMaquinas'] = "-1";

    // filtro operarios
    if (this.filtrarOperarios && this.filtroOperariosSeleccionado.length != 0) {
      this.lineas[this.lineaActual]['modulo' + col + 'filtrarOperarios'] = [];
      this.filtroOperariosSeleccionado.forEach(element => {
        this.lineas[this.lineaActual]['modulo' + col + 'filtrarOperarios'].push(element.idOperario);
      });  
      this.lineas[this.lineaActual]['modulo' + col + 'filtrarOperarios'] = this.lineas[this.lineaActual]['modulo' + col + 'filtrarOperarios'].join(",");
    } else 
      this.lineas[this.lineaActual]['modulo' + col + 'filtrarOperarios'] = "-1";
  }

  /**
   * Al elegir el tipo de modulo a añadir
   * @param id id del tipo de modulo
   * @param nombre nombre del tipo de modulo
   * @param isUpdate se utiliza para saber si el modulo es uno nuevo o es una actualizacion
   */
  seleccionarTipoModulo(id: number, nombre: string, altura: number, anchura: number, isUpdate = false, lineaUpdate = -1, columnaUpdate = -1) {

    this.columnasTabla = []; 
    this.columnasSeleccionadas = []; 

    this.recargarGrid();

    this.idTipoModuloSeleccionado = id;
    //this.nombreTipoModuloSeleccionado = nombre;
    this.nombreTipoModuloSeleccionado = this.nombreTipoModulo(id);
    this.alturaTipoModuloSeleccionado = altura;
    this.anchuraTipoModuloSeleccionado = anchura;
    this.eligiendoTipoModulo = !this.eligiendoTipoModulo;
    this.eligiendoContenidoModulo = !this.eligiendoContenidoModulo;

    this.informesPersonalizadosDisenadorService.Get_OrigenDatosByTipoModuloId(id).subscribe(
      (result: any) => {
        this.origenesDatos = result;

        if (isUpdate) {
          this.origenSeleccionado = this.origenesDatos.filter(f => this.lineas[lineaUpdate]["origenModulo" + columnaUpdate] == f.id)[0];
          this.origenChange(this.origenSeleccionado);
          this.columnasSeleccionadas = [];
          var i = 0;
          this.lineas[lineaUpdate]["modulo" + columnaUpdate + "ColumnasActivo"].forEach(element => {
            if (element)
              this.columnasSeleccionadas.push(i);
            i++;
          });
          this.cantidadLineasTabla = this.lineas[lineaUpdate]["modulo" + columnaUpdate + "CantidadLineasTabla"];
        }
      });
  }

  nombreTipoModulo(id) {
    switch (id) {
      case 1:
        return "Circular";
      case 2:
        return "Circular doble";
      case 3:
        return "OEE";
      case 4:
        return "OEE Barras";
      case 5:
        return "Barras Verticales";
      case 6:
        return "Barras Horizontales";
      case 7:
        return "Tabla";
      case 8:
        return "Lineas";
      case 9:
        return "Lineas cantidad";
      default:
        return "";
    }
  }

  /**
   * Al cambiar el origen de los datos del modulo
   * @param value el valor nuevo
   */
  origenChange(value: any) {
    this.origenSeleccionado = value;

    this.informesPersonalizadosDisenadorService.Get_TipoColumnaByTipoDato(this.origenSeleccionado.id).subscribe(
      (result: any) => {
        this.columnasTabla = result;
        var i = 0;

        this.columnasTabla.forEach(element => {
          element['idReferencia'] = i;

          if (this.tamannos[0] == "-1" || this.tamannos.length == 0 || i >= this.tamannos.length) element['tamannoColumna'] = 100;
          else element['tamannoColumna'] = parseInt(this.tamannos[i]);

          if (this.orden.length == 0) element['orden'] = i+1;
          else element['orden'] = parseInt(this.orden[i]);
          
          element['nombreTranslate'] = this.translate.instant(element.nombre);
          i++;
        });

        this.columnasTabla.sort(function(a, b){
            return a.orden - b.orden;
        });

        this.recargarGrid();
      });
  }

  /**
   * Al cambiar la seccion del filtro de maquinas
   * @param value el valor nuevo
  */
  seccionChange(value: any) {
    var secciones = [];
    value.forEach(element => {
      secciones.push(element.idSeccion);
    });

    var grupos = [];
    this.filtroMaquinasSecciones = this.filtroCompletoMaquinas.filter(f => secciones.includes(f.idSeccion));
    this.filtroGruposSeleccionado = this.filtroGruposSeleccionado.filter(f => secciones.includes(f.idSeccion))
    this.filtroGrupos = [];
    this.filtroMaquinasSecciones.forEach(element => {
      if (!grupos.includes(element.idGrupos)) {
        this.filtroGrupos.push(element);
        grupos.push(element.idGrupos);
      }
    });
  }

  /**
   * Al cambiar el grupo del filtro de maquinas
   * @param value el valor nuevo
  */
  grupoChange(value: any) {
    var grupos = [];
    value.forEach(element => {
      grupos.push(element.idGrupos);
    });

    this.filtroMaquinas = this.filtroMaquinasSecciones.filter(f => grupos.includes(f.idGrupos));
    this.filtroMaquinasSeleccionado = this.filtroMaquinasSeleccionado.filter(f => grupos.includes(f.idGrupos))
  }

  filtroTabla(value: any) {
      var secciones = this.filtroSeccionesSeleccionado.map(f => f.id);
      var grupos = this.filtroGruposSeleccionado.map(f => f.id);

      this.filtroMaquinas = this.filtroCompletoMaquinas.filter(f => {
        // secciones
        var r1 = true, r2 = true;

        if (this.filtroSeccionesSeleccionado.length > 0) r1 = secciones.includes(f.idSeccion);
        if (this.filtroGruposSeleccionado.length > 0) {
          r2 = false;
          f.idGrupos.split(",").forEach(element => {
            if (grupos.includes(parseInt(element))) r2 = true;
          });
        }
        return r1 && r2;
      });

  }

  /**
   * Al darle al boton de cerrar el menu, 
   * restaurar valores por defecto
   */
  cerrarMenu() {
      this.menuAbierto = false;
      this.eligiendoContenidoModulo = false;
      this.eligiendoTipoModulo = true;
  }

  /**
   * Al clicar el boton de guardar informe como copia
   */
  guardarComoCopia() {
    if (this.formTitulo.controls.titulo.valid) {

      this.tituloValido = true;
      this.lineas[0].modulos = this.modulos;
      this.lineas[0].tituloInforme = this.tituloInforme + "_Copia";
      console.table(this.lineas);
      this.informesPersonalizadosDisenadorService.Insert_Informe(this.lineas).subscribe(
        (result: any) => {
          console.log("Informe actualizado");
          this.router.navigate(['informesPersonalizados']);
          this.informePersonalizadoService.set_informes(result);
          this.appComponent.actualizarMenuInformesPersonalizados();
        });
    } else {
      this.tituloValido = false;
    }
  }

  /**
   * Al clicar el boton de guardar informe 
   */
  guardarInforme() {

    if (this.formTitulo.controls.titulo.valid) {

      var idsLineasFinal = [];

      //Anchura relativa
      this.lineas.forEach((linea) => {
        var totAnchura = 0;
        // if (linea.idModulo1 != -1 && linea.idModulo2 == -1 && linea.idModulo3 == -1 && linea.columnas == 1) {
        //   linea.modulo1Anchura = 3;
        // } else if (linea.idModulo1 == -1 && linea.idModulo2 != -1 && linea.idModulo3 == -1 && linea.columnas == 1) {
        //   linea.modulo2Anchura = 3;
        // } else if (linea.idModulo1 == -1 && linea.idModulo2 == -1 && linea.idModulo3 != -1 && linea.columnas == 1) {
        //   linea.modulo3Anchura = 3;
        // } else if (linea.modulo1Anchura == 1 && linea.modulo2Anchura == 1 && linea.columnas == 2) {
        //   linea.modulo2Anchura = 2;
        // }

        // Comprobar que la anchura no supere 100%
        if (linea.idTipoModulo1 != -1)
          totAnchura += linea.modulo1Anchura;
        if (linea.idTipoModulo2 != -1)
          totAnchura += linea.modulo2Anchura;
        if (linea.idTipoModulo3 != -1)
          totAnchura += linea.modulo3Anchura;
        if (totAnchura > 100) {
          linea.modulo1Anchura = Math.floor(linea.modulo1Anchura * 100 / totAnchura);
          linea.modulo2Anchura = Math.floor(linea.modulo2Anchura * 100 / totAnchura);
          linea.modulo3Anchura = Math.floor(linea.modulo3Anchura * 100 / totAnchura);
        }
        //Para clasificar update
        idsLineasFinal.push(linea.idLinea);
      });

      var lineasUpdate = [];
      var lineasDelete = [];
      var lineasInsert = [];

      this.idsLineas.forEach(lineaOriginal => {
        var esta = false;
        idsLineasFinal.forEach(lineaFinal => {
          if (lineaOriginal == lineaFinal) esta = true; 
        });
        if (!esta) lineasDelete.push(lineaOriginal);
        if (esta) lineasUpdate.push(lineaOriginal); 
      });

      idsLineasFinal.forEach(lineaFinal => {
        if (lineaFinal == -1) lineasInsert.push(lineaFinal); 
      });

      this.idInforme = this.route.snapshot.params['id']

      this.tituloValido = true;
      this.lineas[0].modulos = this.modulos;
      this.lineas[0].tituloInforme = this.tituloInforme;
      this.lineas[0].idInforme = this.idInforme
      console.table(this.lineas);

      this.informesPersonalizadosDisenadorService.Get_LineasByIdInforme(parseInt(this.idInforme)).subscribe(
        (lineasOriginales: any) => {
          
          lineasOriginales.forEach(element => {
            element.modulo1ColumnasTabla = element.modulo1ColumnasTabla.split(",");
            element.modulo2ColumnasTabla = element.modulo2ColumnasTabla.split(",");
            element.modulo3ColumnasTabla = element.modulo3ColumnasTabla.split(",");

            element.modulo1ColumnasOrden = element.modulo1ColumnasOrden.split(",");
            element.modulo2ColumnasOrden = element.modulo2ColumnasOrden.split(",");
            element.modulo3ColumnasOrden = element.modulo3ColumnasOrden.split(",");

            element.modulo1ColumnasTamanno = element.modulo1ColumnasTamanno.split(",");
            element.modulo2ColumnasTamanno = element.modulo2ColumnasTamanno.split(",");
            element.modulo3ColumnasTamanno = element.modulo3ColumnasTamanno.split(",");

            element.modulo1ColumnasActivo = element.modulo1ColumnasActivo.split(",");
            element.modulo2ColumnasActivo = element.modulo2ColumnasActivo.split(",");
            element.modulo3ColumnasActivo = element.modulo3ColumnasActivo.split(",");

            element.modulo1ColumnasActivo = element.modulo1ColumnasActivo.map(j => JSON.parse(j));
            element.modulo2ColumnasActivo = element.modulo2ColumnasActivo.map(j => JSON.parse(j));
            element.modulo3ColumnasActivo = element.modulo3ColumnasActivo.map(j => JSON.parse(j));
          });
          
          this.informesPersonalizadosDisenadorService.Update_Informe(this.lineas, lineasInsert, lineasUpdate, lineasDelete, lineasOriginales).subscribe(
            (result: any) => {
              console.log("Informe actualizado");
              this.router.navigate(['informesPersonalizados']);
            });
        });

    } else {
      this.tituloValido = false;
    }
  }

  /**
   * Al eliminar un modulo dentro de una linea
   */
  eliminarModulo(linea: number, columna: number) {

    var totModulos = 0;
    if (this.lineas[linea].idTipoModulo1 != -1)
      totModulos += 1;
    if (this.lineas[linea].idTipoModulo2 != -1)
      totModulos += 1;
    if (this.lineas[linea].idTipoModulo3 != -1)
      totModulos += 1;

    this.lineas[linea]['tituloModulo' + columna] = '';
    this.lineas[linea]['idTipoModulo' + columna] = -1;
    this.lineas[linea]['idModulo' + columna] = -1;
    this.lineas[linea]['modulo' + columna + 'Altura'] = 0;
    this.lineas[linea]['nombreModulo' + columna] = '';
    this.lineas[linea]['modulo' + columna + 'Anchura'] = 4 - totModulos;

  }

  /**
   * Al duplicar un modulo dentro de una linea
   */
  duplicarModulo(linea: number, columna: number) {

    if (this.lineas[linea].columnas == 1) {
      this.lineas[linea].tituloModulo2 = this.lineas[linea].tituloModulo1;
      this.lineas[linea].idTipoModulo2 = this.lineas[linea].idTipoModulo1;
      this.lineas[linea].idModulo2 = this.lineas[linea].idModulo1;
      this.lineas[linea].modulo2Anchura = this.lineas[linea].modulo1Anchura;
      this.lineas[linea].modulo2Altura = this.lineas[linea].modulo1Altura;
      this.lineas[linea].nombreModulo2 = this.lineas[linea].nombreModulo1;
      this.lineas[linea].columnas++;
    } else if (this.lineas[linea].columnas == 2 && columna == 1) {
      this.lineas[linea].tituloModulo3 = this.lineas[linea].tituloModulo1;
      this.lineas[linea].idTipoModulo3 = this.lineas[linea].idTipoModulo1;
      this.lineas[linea].idModulo3 = this.lineas[linea].idModulo1;
      this.lineas[linea].modulo3Anchura = this.lineas[linea].modulo1Anchura;
      this.lineas[linea].modulo3Altura = this.lineas[linea].modulo1Altura;
      this.lineas[linea].nombreModulo3 = this.lineas[linea].nombreModulo1;
      this.lineas[linea].columnas++;
    } else if (this.lineas[linea].columnas == 2 && columna == 2) {
      this.lineas[linea].tituloModulo3 = this.lineas[linea].tituloModulo2;
      this.lineas[linea].idTipoModulo3 = this.lineas[linea].idTipoModulo2;
      this.lineas[linea].idModulo3 = this.lineas[linea].idModulo2;
      this.lineas[linea].modulo3Anchura = this.lineas[linea].modulo2Anchura;
      this.lineas[linea].modulo3Altura = this.lineas[linea].modulo2Altura;
      this.lineas[linea].nombreModulo3 = this.lineas[linea].nombreModulo2;
      this.lineas[linea].columnas++;
    } else if (this.lineas[linea].columnas == 3) {
      this.alertService.error(this.translate.instant('imposibleduplicarmodulo'), { keepAfterRouteChange: true });
    }
  }

  /**
   * Al duplicar una linea entera
   */
  duplicarLinea(linea: any) {
    this.lineas.push(this.myFunctions.copy(linea));
  }

  /**
   * Al cambiar la cantidad de columnas de cada linea
   * @param indice indice de la linea
   * @param cols cantidad nueva de columnas
   */
  cambiarCantidadColumnas(indice: number, cols: number) {

    this.lineas[indice].modulo1Anchura = 4 - cols;
    this.lineas[indice].modulo2Anchura = 4 - cols;
    this.lineas[indice].modulo3Anchura = 4 - cols;
    
    switch (cols) {
      case 1:
        if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo2 != -1) {
          this.alertService.error(this.translate.instant('errorcolumnasmodulo1'), { keepAfterRouteChange: true });
        } else if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo3 != -1) {
          this.alertService.error(this.translate.instant('errorcolumnasmodulo1'), { keepAfterRouteChange: true });
        } else if (this.lineas[indice].idTipoModulo2 != -1 && this.lineas[indice].idTipoModulo3 != -1) {
          this.alertService.error(this.translate.instant('errorcolumnasmodulo1'), { keepAfterRouteChange: true });
        } else if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo2 == -1 && this.lineas[indice].idTipoModulo3 == -1) {
          this.lineas[indice].columnas = 1;
        } else if (this.lineas[indice].idTipoModulo1 == -1 && this.lineas[indice].idTipoModulo2 != -1 && this.lineas[indice].idTipoModulo3 == -1) {
          this.lineas[indice].columnas = 1;
        } else if (this.lineas[indice].idTipoModulo1 == -1 && this.lineas[indice].idTipoModulo2 == -1 && this.lineas[indice].idTipoModulo3 != -1) {
          this.lineas[indice].columnas = 1;
        } else if (this.lineas[indice].idTipoModulo1 == -1 && this.lineas[indice].idTipoModulo2 == -1 && this.lineas[indice].idTipoModulo3 == -1) {
          this.lineas[indice].columnas = 1;
        }
        break;

      case 2:
        
        if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo2 != -1 && this.lineas[indice].idTipoModulo3 != -1) {
          this.alertService.error(this.translate.instant('errorcolumnasmodulo2'), { keepAfterRouteChange: true });
        } else if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo2 != -1 && this.lineas[indice].idTipoModulo3 == -1) {
          this.lineas[indice].columnas = 2;
        } else if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo2 == -1 && this.lineas[indice].idTipoModulo3 != -1) {
          this.lineas[indice].idTipoModulo2 = this.lineas[indice].idTipoModulo3;
          this.lineas[indice].idTipoModulo2 = this.lineas[indice].idTipoModulo3;
          this.lineas[indice].nombreModulo2 = this.lineas[indice].nombreModulo3;
          this.lineas[indice].tituloModulo2 = this.lineas[indice].tituloModulo3;
          this.lineas[indice].modulo2Altura = this.lineas[indice].modulo3Altura;
          this.lineas[indice].origenModulo2 = this.lineas[indice].origenModulo3;
          this.lineas[indice].idTipoModulo3 = -1;
          this.lineas[indice].idTipoModulo3 = -1;
          this.lineas[indice].nombreModulo3 = '';
          this.lineas[indice].tituloModulo3 = '';
          this.lineas[indice].modulo3Altura = 0;
          this.lineas[indice].origenModulo3 = 0;
          this.lineas[indice].columnas = 2;
        } else if (this.lineas[indice].idTipoModulo1 == -1 && this.lineas[indice].idTipoModulo2 != -1 && this.lineas[indice].idTipoModulo3 != -1) {
          this.lineas[indice].idTipoModulo1 = this.lineas[indice].idTipoModulo2;
          this.lineas[indice].idTipoModulo1 = this.lineas[indice].idTipoModulo2;
          this.lineas[indice].nombreModulo1 = this.lineas[indice].nombreModulo2;
          this.lineas[indice].tituloModulo1 = this.lineas[indice].tituloModulo2;
          this.lineas[indice].modulo1Altura = this.lineas[indice].modulo2Altura;
          this.lineas[indice].origenModulo1 = this.lineas[indice].origenModulo2;
          this.lineas[indice].idTipoModulo2 = this.lineas[indice].idTipoModulo3;
          this.lineas[indice].idTipoModulo2 = this.lineas[indice].idTipoModulo3;
          this.lineas[indice].nombreModulo2 = this.lineas[indice].nombreModulo3;
          this.lineas[indice].tituloModulo2 = this.lineas[indice].tituloModulo3;
          this.lineas[indice].modulo2Altura = this.lineas[indice].modulo3Altura;
          this.lineas[indice].origenModulo2 = this.lineas[indice].origenModulo3;
          this.lineas[indice].idTipoModulo3 = -1;
          this.lineas[indice].idTipoModulo3 = -1;
          this.lineas[indice].nombreModulo3 = '';
          this.lineas[indice].tituloModulo3 = '';
          this.lineas[indice].modulo3Altura = 0;
          this.lineas[indice].origenModulo3 = 0;
          this.lineas[indice].columnas = 2;
        } else if (this.lineas[indice].idTipoModulo1 != -1 && this.lineas[indice].idTipoModulo2 == -1) {
          this.lineas[indice].idTipoModulo2 = -1;
          this.lineas[indice].idTipoModulo2 = -1;
          this.lineas[indice].nombreModulo2 = '';
          this.lineas[indice].tituloModulo2 = '';
          this.lineas[indice].modulo2Anchura = 0;
          this.lineas[indice].modulo2Altura = 0;
          this.lineas[indice].origenModulo2 = 0;
          this.lineas[indice].columnas = 2;
        } else if (this.lineas[indice].idTipoModulo1 == -1 && this.lineas[indice].idTipoModulo2 == -1 && this.lineas[indice].idTipoModulo3 == -1) {
          this.lineas[indice].columnas = 2;
        }
        break;

      case 3:

        if (this.lineas[indice].idTipoModulo1 == -1) {
          this.lineas[indice].idTipoModulo1 = -1;
          this.lineas[indice].idTipoModulo1 = -1;
          this.lineas[indice].nombreModulo1 = '';
          this.lineas[indice].tituloModulo1 = '';
          this.lineas[indice].modulo1Anchura = 0;
          this.lineas[indice].modulo1Altura = 0;
          this.lineas[indice].origenModulo1 = 0;
          this.lineas[indice].columnas = 3;
        } else if (this.lineas[indice].idTipoModulo2 == -1) {
          this.lineas[indice].idTipoModulo2 = -1;
          this.lineas[indice].idTipoModulo2 = -1;
          this.lineas[indice].nombreModulo2 = '';
          this.lineas[indice].tituloModulo2 = '';
          this.lineas[indice].modulo2Anchura = 0;
          this.lineas[indice].modulo2Altura = 0;
          this.lineas[indice].origenModulo2 = 0;
          this.lineas[indice].columnas = 3;
        } else if (this.lineas[indice].idTipoModulo3 == -1) {
          this.lineas[indice].idTipoModulo3 = -1;
          this.lineas[indice].idTipoModulo3 = -1;
          this.lineas[indice].nombreModulo3 = '';
          this.lineas[indice].tituloModulo3 = '';
          this.lineas[indice].modulo3Altura = 0;
          this.lineas[indice].origenModulo3 = 0;
          this.lineas[indice].columnas = 3;
        }
        console.log(this.lineas)

        break;
    }
  }

  // DRAG AND DROP
    public rowCallback(context: RowClassArgs) {
      context.dataItem.orden = context.index + 1;
      return {
        dragging: context.dataItem.dragging
      };
    }
  
    private handleDragAndDrop(): Subscription {
  
      const sub = new Subscription(() => { });
      let draggedItemIndex;
      const tableRows = Array.from(document.querySelectorAll('.grid-columnas.k-grid tr'));
      tableRows.forEach(item => {
        this.renderer.setAttribute(item, 'draggable', 'true');
        const dragStart = fromEvent<DragEvent>(item, 'dragstart');
        const dragOver = fromEvent(item, 'dragover');
        const dragEnd = fromEvent(item, 'dragend');
  
        sub.add(dragStart.pipe(
          tap(({ dataTransfer }) => {
            try {
              const dragImgEl = document.createElement('span');
              dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
              document.body.appendChild(dragImgEl);
              dataTransfer.setDragImage(dragImgEl, 0, 0);
            } catch (err) {
              // IE doesn't support setDragImage
            }
          })
        ).subscribe(({ target }) => {
          const row: HTMLTableRowElement = <HTMLTableRowElement>target;
          draggedItemIndex = row.rowIndex;
          const dataItem = this.gridData.data[draggedItemIndex];
          dataItem.dragging = true;
        }));
  
        sub.add(dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.gridData.data[dropIndex];
  
          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.gridData.data.splice(dropIndex, 0, dataItem)
          );
        }));
  
        sub.add(dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data[draggedItemIndex];
          dataItem.dragging = false;
        }));
      });
  
      return sub;
    }
  
    recargarGrid() {
      this.gridData = process(this.columnasTabla, this.state);
      this.currentSubscription.unsubscribe();
      this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
    }
  
    public ngAfterViewInit(): void {
      this.currentSubscription = this.handleDragAndDrop();
    }
  
    public ngOnDestroy(): void {
      this.currentSubscription.unsubscribe();
    }
  // END DRAG AND DROP
}