<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">

    <div class="col-md-6 col-lg-4">
      <div class="card">
        <div class="card-body">

          <h3 class="pt-3">{{ 'datos' | translate}}</h3>

          <div class="form-group ">
            <kendo-label text="{{ 'nombre' | translate}}">
              <div class="caja">
                <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
                </div>
              </div>
            </kendo-label>
          </div>
          <div class="form-group ">
            <kendo-label text="{{ 'orden' | translate}}">
              <div class="caja">
                <kendo-numerictextbox class="form-control" formControlName="orden" [min]="0" [max]="9999"
                  [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                <div *ngIf="submitted && f.orden.errors" class="invalid-feedback">
                  <div *ngIf="f.orden.errors.required">{{ 'orden' | translate}}</div>
                </div>
              </div>
            </kendo-label>
          </div>

          <div class="form-group form-check p-0">
            <kendo-label for="activo" class="form-check-label" text="{{ 'activo' | translate}}">
            </kendo-label>
            <div class="caja">
              <kendo-switch id="activo" formControlName="activo" [onLabel]="' '" [offLabel]="' '"
                [ngClass]="{ 'is-invalid': submitted && f.activo.errors }"> </kendo-switch>
            </div>
          </div>



        </div>

      </div>


      <div class="card">
        <div class="card-body">

          <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary" [disabled]="this.user.maquinas<2">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> {{ 'guardar' | translate}} </button>
          <a routerLink="/maquinasgruposplanificador" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>

        </div>
      </div>
    </div>

    <div class="col-md-6 col-lg-8">
      <div class="card">
        <div class="card-body">
          <h3 class="pt-3">{{ 'maquinas' | translate}}</h3>
          <div class="form-row">
           
        <ng-template #template let-anchor>
          <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          
          <div kendoTooltip
              showOn="none"
              [tooltipTemplate]="template"
              filter=".k-grid td"
              (mouseover)="showGridTooltip($event)">

            <kendo-grid 
            [data]="gridData" 
              kendoGridSelectBy="id" 
              [selectedKeys]="mySelection2" 
              [navigable]="true"
              scrollable="virtual"
              [rowHeight]="36"
              [height]="500"
              [resizable]="true" 
              [pageSize]="50" 
              [selectable]="{enabled: true, mode: 'single'}"
              (cellClick)="cellClickHandler($event)">


              <!-- <kendo-grid [kendoGridBinding]="dataBonos"
                  kendoGridSelectBy="id"
                  filterable="menu"
                  [selectedKeys]="mySelectionBonos"
                  [navigable]="true"
                  [sortable]="true"
                  scrollable="virtual"
                  [rowHeight]="36"
                  [height]="500"
                  [resizable]="true"
                  [pageSize]="50"
                  [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'single'}"
                  (cellClick)="cellClickHandler($event)"> -->
  <!--[rowClass]="rowCallback" -->
              <ng-template kendoGridToolbarTemplate position="top">
                <div class="float-left">
                  <button (click)="checkAllMaquinas($event)" class="btn btn-success btn-sm mr-1">{{ buttonAllMaquinasSelectName }}</button>
                  <!-- <button (click)="changeOrdenMaquinas();" class="btn btn-success btn-sm mr-1"> {{ 'buttonChangeOrderMaquinas' | translate }}</button> -->
                </div>
              </ng-template>

              <!-- <kendo-grid-checkbox-column field="seleccionado" title="" width="4%">
                <ng-template kendoGridCellTemplate let-dataItem="rowIndex">
                  <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" enabled />
                </ng-template>
              </kendo-grid-checkbox-column> -->
              <!-- <kendo-grid-column field="orden" title="{{ 'orden' | translate}}" width="7%" [style]="{'text-align': 'right'}">
              </kendo-grid-column> -->
              <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}" width="35%">
              </kendo-grid-column>
              <!-- <kendo-grid-column field="nombreAP" title="{{ 'AreaProd' | translate}}" width="17%">
              </kendo-grid-column>
              <kendo-grid-column field="nombreSeccion" title="{{ 'seccion' | translate}}" width="10%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="tipoActivo" title="{{ 'tipoActivo' | translate}}" width="15%">
              </kendo-grid-column>
              <kendo-grid-checkbox-column field="activo" title="{{ 'activo' | translate}}" title="" width="7%">
                <ng-template kendoGridCellTemplate let-dataItem="rowIndex">
                  <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" disabled />
                </ng-template>
              </kendo-grid-checkbox-column>-->
 
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h3 class="pt-3">{{ 'subcontrataciones' | translate}}</h3>
          <div class="form-row">
            <kendo-grid [data]="gridDataSubcontratado"
                        [rowClass]="rowCallbackSubcontratado"
                        [selectable]="{enabled: true, checkboxOnly: true}"
                        kendoGridSelectBy="id"
                        [selectedKeys]="mySelection2Subcontratado">
              <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}" width="85%">
              </kendo-grid-column>
              <kendo-grid-checkbox-column field="seleccionado" title="{{ 'seleccionado' | translate}}" width="15%">
                <ng-template kendoGridCellTemplate let-dataItem="rowIndex">
                  <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" enabled />
                </ng-template>
              </kendo-grid-checkbox-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
  </div>


</form>
