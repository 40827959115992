<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">

      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'herramienta' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <!--NOMBRE-->
            <div class="form-group col">
              <kendo-label text="{{ 'referencia' | translate }}">
                <div class="caja">
                  <kendo-textbox formControlName="referencia" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                </div>
              </kendo-label>
            </div>

            <!--Nombre-->
            <div class="form-group col">
              <kendo-label text="{{ 'nombre' | translate }}">
                <div class="caja">
                  <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                </div>
              </kendo-label>
            </div>
          </div>
          <div class="row">
            <!--NumHerramienta-->
            <div class="form-group col">
              <kendo-label text="{{ 'numherramienta' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="numherramienta" [decimals]="0"  [format]="'n'" [min]="0" [max]="999999" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>
            
            <!--Diametro-->
            <div class="form-group col">
              <kendo-label text="{{ 'diametro' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="diametro" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>
          </div>
          <!--Longitud-->
          <div class="row">
            <div class="form-group col">
              <kendo-label text="{{ 'longitud' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="longitud" [min]="0" [max]="999999"  [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>

            <!--Angulo-->
            <div class="form-group col">
              <kendo-label text="{{ 'angulo' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="angulo"  [min]="-360" [max]="360"  maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>
          </div>
          <div class="row">
            <!--Nfiltros-->
            <div class="form-group col">
              <kendo-label text="{{ 'nfiltros' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="numfiltros" [decimals]="0"  [format]="'n'" [min]="0" [max]="999999"  maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>
            <!--Saltos-->
            <div class="form-group col">
              <kendo-label text="{{ 'saltos' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="salto" [min]="0" [max]="999999"  [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>
          </div>

          <div class="row">
            <!--Nfiltros-->
            <div class="form-group col">
              <kendo-label text="{{ 'idERP' | translate }}">
                <div class="caja">
                  <kendo-textbox formControlName="iderp" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                </div>
              </kendo-label>
            </div>
            <!--Vida-->
            <div class="form-group col">
              <kendo-label text="{{ 'vida' | translate }}">
                <div class="caja">
                  <kendo-numerictextbox formControlName="vida" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                </div>
              </kendo-label>
            </div>

          </div>
         

          <!--IMAGEN-->
          <!--IMAGEN-->

      
          <ng-template #noImageFound>
            <img src="fallbackImage.png">
          </ng-template>
          <div class="form-group w-50" *ngIf ="false">
            <div *ngIf="imagePreviews.length; else initImage">

              <img *ngFor="let image of imagePreviews"
                   [src]="image.src"
                   alt="image preview"
                   style="width: 200px; margin: 10px;" />
            </div>
            <ng-template #initImage *ngIf ="false">
              <img [src]="imageToShow" style="max-height: 113px">
            </ng-template>
            <kendo-fileselect formControlName="archivo" class="form-control" [restrictions]="restrictions" [multiple]="false" (select)="selectEventHandler($event)">
              <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                     clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                     dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                     externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                     fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                     fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                     filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                     filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                     filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                     headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                     headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                     headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                     invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                     invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                     invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                     pause="{{ 'pause' | translate }}"
                                     remove="{{ 'remove' | translate }}"
                                     resume="{{ 'resume' | translate }}"
                                     retry="{{ 'retry' | translate }}"
                                     select="{{ 'select' | translate }}"
                                     uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
              </kendo-upload-messages>
            </kendo-fileselect>
          </div>
          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.herramientas <2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
