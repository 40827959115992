

<style>
</style>





<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- INFO -->
<div class="card">
  <div class="card-header">
    <h3><label>{{ 'datos' | translate }}</label></h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <!-- DATOS -->
    <div class="clearfix">
      <!-- TIPO CONSUMIBLE -->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'tipo' | translate }}</label>
        <div class="caja" style="width: 150px">
          <kendo-combobox [data]="JconsumiblesTipos"
                          [textField]="'nombre'"
                          [valueField]="'id'"
                          [(value)]="JconsumiblesTiposSelected"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          (selectionChange)="tipoChange()"
                          [disabled]="Jcontenido.length > 0"
                          [class.requerido]="requiereTipo">
          </kendo-combobox>
        </div>
      </div>
      <!-- FABRICANTE -->
      <div class="float-left mr-1">
        <label class="crontol-label">{{ 'fabricante' | translate }}</label>
        <div class="caja" style="width: 150px">
          <kendo-combobox [data]="JconsumiblesFabricantes"
                          [textField]="'nombre'"
                          [valueField]="'id'"
                          [(value)]="JconsumiblesFabricantesSelected"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          (selectionChange)="fabricanteChange()"
                          [class.requerido]="requiereFabricante">
          </kendo-combobox>
        </div>
      </div>
      <!-- NOMBRE -->
      <div class="float-left mr-1">
        <div class="form-group">
          <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nombre" [maxlength]="50" [class.requerido]="requiereNombre"></kendo-textbox>
          </div>
        </div>
      </div>
      <!-- REFERENCIA -->
      <div class="float-left mr-1">
        <div class="form-group">
          <kendo-label text="{{ 'referencia' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="referencia" [maxlength]="50" [class.requerido]="requiereReferencia"></kendo-textbox>
          </div>
        </div>
      </div>
      <!-- TIEN E NSERIE -->
      <div class="float-left mr-1">
        <div class="form-group">
          <kendo-label text="{{ 'tieneNserie' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-switch [(ngModel)]="tieneNserie" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
    </div>
    <!-- DATOS DINAMICOS SEGUN TIPO DE CONSUMIBLE -->
    <div class="clearfix">
      <!--VIDA UTIL-->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_vidaUtil', false)">
        <div class="form-group">
          <label>{{ 'vidaUtil' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox class="k-hidden" format="0" [min]="0" [max]="9999999" [(value)]="vidaUtil" [class.requerido]="requiereVidaUtil"></kendo-numerictextbox>
            <kendo-textbox [value]="vidaUtilHHmmSS"
                           (valueChange)="updateDatosHerramienta(dataItem, $event, 0)"
                           [ngClass]="{ 'is-invalid': requiereVidaUtil }"></kendo-textbox>
          </div>
        </div>
      </div>
      <!--FIABILIDAD-->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_vidaUtil', false)">
        <div class="form-group">
          <label>{{ 'fiabilidad' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="9999999" [(value)]="fiabilidad" [class.requerido]="requierefiabilidad"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!--DIAMETRO-->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_diametro', false)">
        <div class="form-group">
          <label>{{ 'diametro' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="diametro" [class.requerido]="requiereDiametro"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- LONGITUD -->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_longitud', false)">
        <div class="form-group">
          <label>{{ 'longitud' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="longitud" [class.requerido]="requiereLongitud"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- ANGULO -->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_angulo', false)">
        <div class="form-group">
          <label>{{ 'angulo' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="angulo" [class.requerido]="requiereAngulo"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- NUM CONTENIDO -->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_numContenido', false)">
        <div class="form-group">
          <label>{{ 'numContenido' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox format="0" [min]="0" [max]="999999" [(value)]="numContenido" [class.requerido]="requiereNumContenido"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- SALTO -->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_salto', false)">
        <div class="form-group">
          <label>{{ 'salto' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox format="0" [min]="0" [max]="999999" [(value)]="salto" [class.requerido]="requiereSalto"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- ID ERP -->
      <div class="float-left mr-1" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_idERP', false)">
        <div class="form-group">
          <label>{{ 'idErp' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox format="0" [min]="0" [max]="999999" [(value)]="idErp" [class.requerido]="requiereIdERP"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- COSTE -->
      <div class="float-left" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_coste', false)">
        <div class="form-group">
          <label>{{ 'coste' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="coste" [class.requerido]="requiereCoste"></kendo-numerictextbox>
          </div>
        </div>
      </div>

      <!-- VC -->
      <div class="float-left" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_vc', false)">
        <div class="form-group">
          <label>{{ 'VC' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="vc" [class.requerido]="requiereVc"></kendo-numerictextbox>
          </div>
        </div>
      </div>

      <!-- F -->
      <div class="float-left" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_f', false)">
        <div class="form-group">
          <label>{{ 'F' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="f" [class.requerido]="requiereF"></kendo-numerictextbox>
          </div>
        </div>
      </div>

    </div>
    <!-- DESCRIPCION -->
    <div class="clearfix">
      <div class="form-group">
        <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
        <div class="caja">
          <textarea [(ngModel)]='descripcion' class="form-control" style="height: 7em;" maxlength="2000"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- GRID -->
<div class="card" *ngIf="myFunctions.zitu_if(JconsumiblesTiposSelected, 'solicitar_numContenido', false)">
  <div class="card-header">
    <h3><label>{{ 'contenido' | translate }}</label></h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <!-- GRID: Tooltip -->
    <ng-template #template let-anchor>
      <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <kendo-grid position="top"
                [data]="gridData"
                [resizable]="true"
                (remove)="removeHandler()"
                (add)="onClickNuevoContenido($event)"
                [selectedKeys]="contenidoSelected"
                kendoGridSelectBy="idTemp"
                [height]="300"
                [rowClass]="rowCallback">
      <ng-template kendoGridToolbarTemplate>
        <button id="bNuevo" kendoGridAddCommand class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</button>
        <button id="bEliminar" kendoGridRemoveCommand class="btn btn-danger btn-sm mr-1" [disabled]="this.user.consumibles<2">{{ 'eliminar' | translate }}</button>
      </ng-template>
      <!-- CHECK -->
      <kendo-grid-checkbox-column showSelectAll="true" width="3%"></kendo-grid-checkbox-column>
      <!-- ORDEN -->
      <kendo-grid-column  field="orden" title="{{ 'orden' | translate }}" width="4%"></kendo-grid-column>
      <!-- TIPO -->
      <kendo-grid-column editor="boolean" title="{{ 'tipo' | translate }}" width="32%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-combobox [data]="JconsumiblesTipos_grid" [textField]="'nombre'" [valueField]="'id'" [(value)]="dataItem.idConsumibles_contenido_tipo"
                          [kendoDropDownFilter]="{operator: 'contains'}" (selectionChange)="Jconsumibles_tipo_selected(dataItem)">
          </kendo-combobox>
        </ng-template>
      </kendo-grid-column>
      <!-- CONSUMIBLE -->
      <kendo-grid-column editor="boolean" title="{{ 'consumible' | translate }}" width="32%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-combobox [data]="Jconsumibles_filter(dataItem)" [textField]="'nombre'" [valueField]="'id'" [(value)]="dataItem.idConsumibles_contenido"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          [disabled]="dataItem.idConsumibles_contenido_tipo == undefined">
          </kendo-combobox>
        </ng-template>
      </kendo-grid-column>
      <!-- CONTENIDO -->
      <kendo-grid-column editor="numeric" title="{{ 'contenido' | translate }}" width="5%" class="celda-tooltip-externo p-0">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="celda-completa tooltip-consumibles-contenido"
                *ngIf="myFunctions.zitu_if(dataItem.idConsumibles_contenido, 'cantidadContenido', '') >= '0'"
                [ngClass]="{ 'btn-success': myFunctions.zitu_if(dataItem.idConsumibles_contenido, 'cantidadContenido', '') > '0',
                'btn-danger': myFunctions.zitu_if(dataItem.idConsumibles_contenido, 'cantidadContenido', '') == '0'}">
            <label> {{ myFunctions.zitu_if(dataItem.idConsumibles_contenido, 'cantidadContenido', '') }}</label>
            <span class="tooltiptext">
              <span *ngFor="let tooltip of myFunctions.zitu_if(dataItem.idConsumibles_contenido, 'contenido', []) ">
                <label>{{tooltip}}</label>
              </span>
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <!-- FAVORITO -->
      <kendo-grid-column editor="boolean" title="{{ 'favorito' | translate }}" width="5%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-switch [(checked)]="dataItem.preferido" [(value)]="dataItem.preferido" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
        </ng-template>
      </kendo-grid-column>
      <!-- CANTIDAD -->
      <kendo-grid-column editor="numeric" title="{{ 'cantidad' | translate }}" width="10%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-numerictextbox [(value)]="dataItem.cantidad" [decimals]="0" [format]="'n'" [min]="1" [max]="999999" [autoCorrect]="true"></kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
      <!-- BOTON EDITAR -->
      <kendo-grid-column editor="numeric" title=" " width="9%" class="p-0">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoButton class="btn mr-1  btn-primary" (click)="redirect_consumible(dataItem)">
            <i class="fas fa-search"></i>
          </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
</div>
<!-- BOT(ONES) -->
<div class="clearfix">
  <button kendoButton class="btn mr-1  btn-success" (click)="guardar()" [disabled]="this.user.consumibles<2">{{ 'guardar' | translate}}</button>
  <button kendoButton class="btn mr-1  btn-danger" (click)="cancelar()">{{ 'cancelar' | translate}}</button>
</div>

<!-- POPUP: Error al guardar -->
<div class="popup-cont" *ngIf="popupErrorAlGuardar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlGuardar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlGuardar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
