<!-- al clickar fuera del menu se cerrara -->
<div (click)="cerrarMenu()" style="position:fixed; top:0; left:0; right:0; bottom:0; display:block;"></div>

<div class="row">
    <!-- DATOS -->
    <div class="col-md-4">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label ID="lDatos" CssClass="">{{ 'datos' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <!-- NOMBRE -->
                    <div class="col-md-10">
                        <kendo-label text="{{ 'nombre' | translate}}">
                            <div class="caja">
                                <kendo-textbox [(value)]="nombreContenedor"></kendo-textbox>
                            </div>
                        </kendo-label>
                    </div>
                    <!-- ACTIVO -->
                    <div class="col-md-2">
                        <kendo-label text="{{ 'activo' | translate}}">
                            <div class="caja">
                                <kendo-switch [(ngModel)]="isActivo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                            </div>
                        </kendo-label>
                    </div>
                </div>
                <!-- DESCRIPCION -->
                <div class="row">
                    <div class="col-md-12">
                        <kendo-label text="{{ 'descripcion' | translate}}">
                            <div class="caja">
                                <kendo-textarea class="w-100" [(value)]="descripcionContenedor"></kendo-textarea>
                            </div>
                        </kendo-label>
                    </div>
                </div>
                <!-- STOCK -->
                <div class="row">
                    <div class="col-md-4">
                        <kendo-label text="{{ 'stockMinimo' | translate}}">
                            <div class="caja">
                                <kendo-numerictextbox [(value)]="stockMinimo" [min]="0" [max]="9999"
                                    [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </div>
                        </kendo-label>
                    </div>
                    <div class="col-md-4">
                        <kendo-label text="{{ 'stockMaximo' | translate}}">
                            <div class="caja">
                                <kendo-numerictextbox [(value)]="stockMaximo" [min]="0" [max]="9999"
                                    [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </div>
                        </kendo-label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MAQUINAS ORIGEN -->
    <div class="col-md-4">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label ID="lMaquinasOrigen" CssClass="">{{ 'maquinasOrigen' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <kendo-grid [kendoGridBinding]="maquinasOrigen" [sortable]="true"
                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                    [navigable]="true" filterable="menu" [height]="260">
                    <!--nombre-->
                    <kendo-grid-column width="95%" field="nombre" title="{{ 'nombre' | translate}}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="float-left">{{ dataItem.nombre }}</div>
                            <div class="float-right">
                                <div (click)="eliminarMaquinaOrigen(dataItem.id)"><i class="icon-equis"></i></div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!--TRADUCCIÓN TEXTOS DEL GRID-->
                    <kendo-grid-messages filter="{{'filter' | translate}}"
                        filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}"
                        filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}"
                        filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}"
                        filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}"
                        filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}"
                        filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                        filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                        filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}"
                        filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                        filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                        loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                        lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>

                <!-- AGREGAR MAQUINAS -->
                <!-- MAQUINAS -->
                <div class="agregar-elemento-maquina"
                    (click)="showOrigen();">
                    <span *ngIf="agregarSeleccionadosOrigen.length == 0">{{'maquinas' | translate}}</span>
                    <div *ngIf="agregarSeleccionadosOrigen.length != 0" class="clearfix">
                        <div class="cont-tags-filtro">
                            <div *ngFor="let maq of agregarOrigenMaquinas" class="tags-filtro">
                                <label class="tags-filtro-label">{{ maq.nombre }}</label>
                                <!-- ELIMINAR -->
                                <button (click)="eliminarMaquinaOrigen(maq.id)" class="fas fa-times"
                                    style="cursor:pointer"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card agregar-maquinas-cont" style="position: absolute !important; left: 0; right: 0; z-index: 1;" *ngIf="agregarOrigen" id="agregar-maquinas">
                    <div class="card-body">

                         <div class="clearfix agregar-maquinas-buscadorfiltro">
                            <!-- BUSCAR -->
                           <div class="buscador-cont">
                             <kendo-textbox placeholder="{{ 'buscar' | translate }}" (valueChange)="buscarChangeOrigen($event)"></kendo-textbox>
                           </div>
                            <!-- FILTRO-->
                            <div  class="btn-filtro-cont" [ngClass]="{ 'filtro-parametros': filtroOrigen.length != 0 }"
                                (click)="showFiltroOrigen(); showSeccionesOrigen = true; showGruposOrigen = false; showClasificacionesOrigen = false">
                                <span class="icon-filtro"></span>
                            </div>
                        </div>

                        <!-- SELECCIONADOS DEL FILTRO -->
                        <div class="clearfix cont-tags-filtro" *ngIf="filtroOrigen.length > 0">
                            <div *ngFor="let filtro of filtroOrigen" class="tags-filtro">
                                <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                <!-- ELIMINAR -->
                                <button (click)="eliminarFiltroOrigen(filtro.nombre)" class="fas fa-times"
                                    style="cursor:pointer"></button>
                            </div>
                        </div>
                        
                        <div class="card cont-grid-agrupado" style="position: absolute !important; left: 0; right: 0; top: 50px; z-index: 1;"
                            *ngIf="ensennarFiltroOrigen">
                            <div class="card-body">

                                <!-- FILTRO -->
                                <div class="grid-agrupado">
                                    <!-- SELECCIONADOS DEL FILTRO -->
                                    <div class="clearfix cont-tags-filtro" *ngIf="filtroOrigen.length > 0">
                                        <div *ngFor="let filtro of filtroOrigen" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarFiltroOrigen(filtro.nombre)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>
                                    <!-- BUSCAR -->
                                    <div class="caja">
                                        <kendo-textbox placeholder="{{ 'buscar' | translate }}" (valueChange)="buscarFiltroChangeOrigen($event)"></kendo-textbox>
                                    </div>
                                    <kendo-grid [kendoGridBinding]="groupedFiltro"
                                        [selectedKeys]="seccionesSeleccionadasOrigen" [sortable]="true"
                                        [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                        [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                        (cellClick)="annadirFiltroSeleccionadoOrigen($event)" [groupable]="true"
                                        [group]="groupsFiltro">
                                        <!-- Columna para saber si esta seleccionado -->
                                        <kendo-grid-column width="5px" [style]="{'text-align': 'left'}" field="select"
                                            title="" class="p-0" [filterable]="false">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <input class="k-checkbox" type="checkbox" [checked]="dataItem.isSeleccionado" (change)="annadirFiltroSeleccionadoOrigen($event, dataItem)" />
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column width="95px" field="nombre" title=""></kendo-grid-column>
                                        <kendo-grid-column field="grouped" width="0%"
                                            title=" " hidden="hidden"></kendo-grid-column>
                                    </kendo-grid>
                                </div>
                            </div>

                        </div>

                        <!-- TABLA DE MAQUINAS-->
                        <!-- AGRUPAR POR ... -->
                        <div class="button-contenedores-filtro">
                            <button type="button"
                                [ngClass]="{'btn btn-success btn-sm': isAgruparPorSeccionesOrigen, 'btn btn-sm': !isAgruparPorSeccionesOrigen }"
                                (click)="agruparPorSeccionesOrigen()">{{ 'secciones' | translate }}</button>
                            <button type="button"
                                [ngClass]="{'btn btn-success btn-sm': isAgruparPorGruposOrigen, 'btn btn-sm': !isAgruparPorGruposOrigen }"
                                (click)="agruparPorGruposOrigen()">{{ 'grupos' | translate }}</button>
                            <button type="button"
                                [ngClass]="{'btn btn-success btn-sm': isAgruparPorClasificacionesOrigen, 'btn btn-sm': !isAgruparPorClasificacionesOrigen }"
                                (click)="agruparPorClasificacionesOrigen()">{{ 'clasificaciones' | translate
                                }}</button>
                        </div>

                        <!-- LISTA MAQUINAS -->
                        <div *ngIf="isAgruparPorSeccionesOrigen" class="grid-agrupado">
                            <kendo-grid [kendoGridBinding]="maquinasAgregarOrigen"
                                [selectedKeys]="agregarSeleccionadosOrigen" [sortable]="true"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                (cellClick)="annadirMaquinaOrigen($event)" [groupable]="true"
                                [group]="groupsSecciones">
                                <!-- Columna para saber si esta seleccionado -->
                                <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                    title="" class="p-0" [filterable]="false">
                                    <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado"></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <!-- Imagen -->
                                <kendo-grid-column field="maquinas" title="" width="8%"
                                    class="celda-tooltip-externo" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="tooltip-consumibles-contenido">
                                            <ng-container>
                                                <span *ngIf="dataItem.imagenBase64!=''" class="grid-circulo" style="background-color:white!important;"
                                                    [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                <span *ngIf="dataItem.imagenBase64==''" class="grid-circulo">{{dataItem.siglas}}</span>
                                            </ng-container>
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                <kendo-grid-column field="nombreSeccion" width="50%"
                                    title="{{ 'seccion' | translate}}" hidden="hidden"></kendo-grid-column>
                            </kendo-grid>
                        </div>

                        <div *ngIf="isAgruparPorGruposOrigen" class="grid-agrupado">
                            <kendo-grid [kendoGridBinding]="maquinaParaGrupos"
                                [selectedKeys]="agregarSeleccionadosOrigen" [sortable]="true"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                (cellClick)="annadirMaquinaOrigen($event)" [groupable]="true" [group]="groupsGrupos">
                                <!-- Columna para saber si esta seleccionado -->
                                <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                    title="" class="p-0" [filterable]="false">
                                    <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado"></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <!-- Imagen -->
                                <kendo-grid-column field="maquinas" title="" width="8%"
                                    class="celda-tooltip-externo" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="tooltip-consumibles-contenido">
                                            <ng-container>
                                                <span class="grid-circulo" style="background-color:white!important;"
                                                    [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                            </ng-container>
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                <kendo-grid-column field="nombreGrupo" width="50%" title="{{ 'grupo' | translate}}"
                                    hidden="hidden"></kendo-grid-column>
                            </kendo-grid>
                        </div>

                        <div *ngIf="isAgruparPorClasificacionesOrigen" class="grid-agrupado">
                            <kendo-grid [kendoGridBinding]="maquinasAgregarOrigen"
                                [selectedKeys]="agregarSeleccionadosOrigen" [sortable]="true"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                (cellClick)="annadirMaquinaOrigen($event)" >
                                <!-- Columna para saber si esta seleccionado -->
                                <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                    title="" class="p-0" [filterable]="false">
                                    <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasOrigen()"></span>
                                    </ng-template> -->
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado"></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <!-- Imagen -->
                                <kendo-grid-column field="maquinas" title="" width="8%"
                                    class="celda-tooltip-externo" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="tooltip-consumibles-contenido">
                                            <ng-container>
                                                <span class="grid-circulo" style="background-color:white!important;"
                                                    [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                            </ng-container>
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                            </kendo-grid>
                        </div>
                        <!-- END TABLA DE MAQUINAS-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- MAQUINAS DESTINO -->
    <div class="col-md-4">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label ID="lMaquinasDestino" CssClass="">{{ 'maquinasDestino' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <kendo-grid [kendoGridBinding]="maquinasDestino" [sortable]="true"
                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                    [navigable]="true" filterable="menu" [height]="260">
                    <!--nombre-->
                    <kendo-grid-column width="95%" field="nombre" title="{{ 'nombre' | translate}}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="float-left">{{ dataItem.nombre }}</div>
                            <div class="float-right">
                                <div (click)="eliminarMaquinaDestino(dataItem.id)"><i class="icon-equis"></i></div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!--TRADUCCIÓN TEXTOS DEL GRID-->
                    <kendo-grid-messages filter="{{'filter' | translate}}"
                        filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}"
                        filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}"
                        filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}"
                        filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}"
                        filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}"
                        filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                        filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                        filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}"
                        filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                        filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                        loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                        lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>

                                <!-- AGREGAR MAQUINAS -->
                <!-- MAQUINAS -->
                <div class="agregar-elemento-maquina"
                    (click)="showDestino();">
                    <span *ngIf="agregarSeleccionadosDestino.length == 0">{{'maquinas' | translate}}</span>
                    <div *ngIf="agregarSeleccionadosDestino.length != 0" class="clearfix">
                        <div class="cont-tags-filtro">
                            <div *ngFor="let maq of agregarDestinoMaquinas" class="tags-filtro">
                                <label class="tags-filtro-label">{{ maq.nombre }}</label>
                                <!-- ELIMINAR -->
                                <button (click)="eliminarMaquinaDestino(maq.id)" class="fas fa-times"
                                    style="cursor:pointer"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card agregar-maquinas-cont" style="position: absolute !important; left: 0; right: 0; z-index: 1;" *ngIf="agregarDestino" id="agregar-maquinas">
                    <div class="card-body">

                        <div class="clearfix agregar-maquinas-buscadorfiltro">
                            <!-- BUSCAR -->
                            <div class="buscador-cont">
                                <kendo-textbox placeholder="{{ 'buscar' | translate }}" (valueChange)="buscarChangeDestino($event)"></kendo-textbox>
                            </div>
                            <!-- FILTRO-->
                            <div class="btn-filtro-cont" [ngClass]="{ 'filtro-parametros': filtroDestino.length != 0 }"
                                (click)="showFiltroDestino(); showSeccionesDestino = true; showGruposDestino = false; showClasificacionesDestino = false">
                                <span class="icon-filtro"></span>
                            </div>
                        </div>

                        <!-- SELECCIONADOS DEL FILTRO -->
                        <div class="clearfix cont-tags-filtro" *ngIf="filtroDestino.length > 0">
                            <div *ngFor="let filtro of filtroDestino" class="tags-filtro">
                                <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                <!-- ELIMINAR -->
                                <button (click)="eliminarFiltroDestino(filtro.nombre)" class="fas fa-times"
                                    style="cursor:pointer"></button>
                            </div>
                        </div>
                        
                        <div class="card  cont-grid-agrupado" style="position: absolute !important; left: 0; right: 0; top: 50px; z-index: 1;"
                            *ngIf="ensennarFiltroDestino">
                            <div class="card-body">

                                <!-- FILTRO -->
                                <div class="grid-agrupado">
                                    <!-- SELECCIONADOS DEL FILTRO -->
                                    <div class="clearfix cont-tags-filtro" *ngIf="filtroDestino.length > 0">
                                        <div *ngFor="let filtro of filtroDestino" class="tags-filtro">
                                            <label class="tags-filtro-label">{{ filtro.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarFiltroDestino(filtro.nombre)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>
                                    <!-- BUSCAR -->
                                    <div class="caja">
                                        <kendo-textbox placeholder="{{ 'buscar' | translate }}" (valueChange)="buscarFiltroChangeDestino($event)"></kendo-textbox>
                                    </div>
                                    <kendo-grid [kendoGridBinding]="groupedFiltro"
                                        [selectedKeys]="seccionesSeleccionadasDestino" [sortable]="true"
                                        [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                        [navigable]="true" kendoGridSelectBy="idGrid" [height]="270"
                                        (cellClick)="annadirFiltroSeleccionadoDestino($event)" [groupable]="true"
                                        [group]="groupsFiltro">
                                        <!-- Columna para saber si esta seleccionado -->
                                        <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                            title="" class="p-0" [filterable]="false">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <input type="checkbox" class="k-checkbox" [checked]="dataItem.isSeleccionadoDestino" (change)="annadirFiltroSeleccionadoDestino($event, dataItem)" />
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                        <kendo-grid-column field="grouped" width="0%"
                                            title=" " hidden="hidden"></kendo-grid-column>
                                    </kendo-grid>
                                </div>
                            </div>

                        </div>

                        <!-- TABLA DE MAQUINAS-->
                        <!-- AGRUPAR POR ... -->
                        <div class="button-contenedores-filtro">
                            <button type="button"
                                [ngClass]="{'btn btn-success btn-sm': isAgruparPorSeccionesDestino, 'btn btn-sm': !isAgruparPorSeccionesDestino }"
                                (click)="agruparPorSeccionesDestino()">{{ 'secciones' | translate }}</button>
                            <button type="button"
                                [ngClass]="{'btn btn-success btn-sm': isAgruparPorGruposDestino, 'btn btn-sm': !isAgruparPorGruposDestino }"
                                (click)="agruparPorGruposDestino()">{{ 'grupos' | translate }}</button>
                            <button type="button"
                                [ngClass]="{'btn btn-success btn-sm': isAgruparPorClasificacionesDestino, 'btn btn-sm': !isAgruparPorClasificacionesDestino }"
                                (click)="agruparPorClasificacionesDestino()">{{ 'clasificaciones' | translate }}</button>
                        </div>

                        <!-- LISTA MAQUINAS -->
                        <div *ngIf="isAgruparPorSeccionesDestino" class="grid-agrupado">
                            <kendo-grid [kendoGridBinding]="maquinasAgregarDestino"
                                [selectedKeys]="agregarSeleccionadosDestino" [sortable]="true"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                (cellClick)="annadirMaquinaDestino($event)" [groupable]="true"
                                [group]="groupsSecciones">
                                <!-- Columna para saber si esta seleccionado -->
                                <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                    title="" class="p-0" [filterable]="false">
                                    <!-- <ng-template kendoGridHeaderTemplate let-column>
                                        <span class="icon-check" (click)="selectAllMaquinasDestino()"></span>
                                    </ng-template> -->
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionadoDestino"></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <!-- Imagen -->
                                <kendo-grid-column field="maquinas" title="" width="8%"
                                    class="celda-tooltip-externo" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="tooltip-consumibles-contenido">
                                            <ng-container>
                                                <span class="grid-circulo" style="background-color:white!important;"
                                                    [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                            </ng-container>
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                <kendo-grid-column field="nombreSeccion" width="50%"
                                    title="{{ 'seccion' | translate}}" hidden="hidden"></kendo-grid-column>
                            </kendo-grid>
                        </div>

                        <div *ngIf="isAgruparPorGruposDestino" class="grid-agrupado">
                            <kendo-grid [kendoGridBinding]="maquinaParaGrupos"
                                [selectedKeys]="agregarSeleccionadosDestino" [sortable]="true"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                (cellClick)="annadirMaquinaDestino($event)" [groupable]="true" [group]="groupsGrupos">
                                <!-- Columna para saber si esta seleccionado -->
                                <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                    title="" class="p-0" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionadoDestino"></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <!-- Imagen -->
                                <kendo-grid-column field="maquinas" title="" width="8%"
                                    class="celda-tooltip-externo" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="tooltip-consumibles-contenido">
                                            <ng-container>
                                                <span class="grid-circulo" style="background-color:white!important;"
                                                    [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                            </ng-container>
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                <kendo-grid-column field="nombreGrupo" width="50%" title="{{ 'grupo' | translate}}"
                                    hidden="hidden"></kendo-grid-column>
                            </kendo-grid>
                        </div>

                        <div *ngIf="isAgruparPorClasificacionesDestino" class="grid-agrupado">
                            <kendo-grid [kendoGridBinding]="maquinasAgregarDestino"
                                [selectedKeys]="agregarSeleccionadosDestino" [sortable]="true"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                (cellClick)="annadirMaquinaDestino($event)" >
                                <!-- Columna para saber si esta seleccionado -->
                                <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                    title="" class="p-0" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionadoDestino"></div>
                                    </ng-template>
                                </kendo-grid-column>
                                <!-- Imagen -->
                                <kendo-grid-column field="maquinas" title="" width="8%"
                                    class="celda-tooltip-externo" [filterable]="false">
                                    <ng-template kendoGridCellTemplate let-dataItem>
                                        <span class="tooltip-consumibles-contenido">
                                            <ng-container>
                                                <span class="grid-circulo" style="background-color:white!important;"
                                                    [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                            </ng-container>
                                        </span>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                            </kendo-grid>
                        </div>
                        <!-- END TABLA DE MAQUINAS-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="btn-group">
    <button (click)="onClickGuardar()" class="btn btn-success btn-sm mr-1">{{ 'guardar' | translate}}</button>
    <button (click)="onClickCancelar()" class="btn btn-danger btn-sm mr-1">{{ 'cancelar' | translate}}</button>
</div>
