<!--   FILTRO-->
<!-- <div *ngIf="mostrarCalendario" class="box" click="console.log('Click fondo')"></div> CLICK de fondo de calendario doble! -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
  <div class="card-header">
    <h3>{{ 'filtro' | translate}}</h3>
    <button type="button" class="flt-cerrar " (click)="this.status = !this.status;" [disabled]="eligiendoFechas"><i class="fas fa-times"></i></button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <!-- Filtro: CON GRUPOS -->
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
    <ng-template #recursiveListTmpl let-filtross="filtros">
      <div *ngFor="let filtro of filtross" class="mt-2">
        <!-- GRUPO -->
        <div *ngFor="let row of filtro.group" class="flt-grupo">
          <!-- LINEA FILTRO -->
          <div *ngIf="row.group == undefined" class="clearfix flt-linea">
            <!-- AND / OR -->
            <div class="flt-andor">
              <!-- = 1ª ROW NO TIENE NI OR, NI AND-->
              <label class="float-left" *ngIf="row == filtro.group[0]"> {{ 'where' | translate}} </label>
              <!-- = 2ª ROW -->
              <kendo-dropdownlist (selectionChange)="refiltrarFiltro(filtro)" *ngIf="row == filtro.group[1]" [data]="andOr" [textField]="'nombre'" [valueField]="'id'"
                                  [(value)]="filtro.logic">
              </kendo-dropdownlist>
              <!-- > 2ª ROW -->
              <label class="d-flex justify-content-center" *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 0"> {{ 'y' | translate}} </label>
              <label class="d-flex justify-content-center" *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 1"> {{ 'o' | translate}} </label>
            </div>
            <!-- TIPO -->
            <div class="flt-tipo">
              <kendo-dropdownlist (selectionChange)="filtroTipoChanged(filtro, row, $event)" [data]="columnasFiltro" [textField]="'nombre'"
                                  [valueField]="'id'" [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna"  [disabled]="row.tipoNoEditable"></kendo-dropdownlist>
            </div>
            <!-- CONTENEDOR FILTRO DE LINEA -->
            <div class="flt-filtro-linea">
              <!-- Filtro: FECHA -->
              <div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionDate" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 (tenemos un control especial para las fechas dobles) -->
                  <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1" [(value)]="row.fechaIni"></kendo-datepicker>
                  <!-- DATO 1 / 2 -->
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <div class="calendarFiltro">
                      <!-- CONTROL -->
                      <div class="calendarFiltro-input" (click)="showCalendar(row)">
                        <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni | kendoDate}}</label>
                        <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                        <i class="k-icon k-i-calendar"></i>
                      </div>
                      <!-- CONTROL EXPANDIDO -->
                      <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;" [ngClass]="row.mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                        <div class="row">
                          <div class="col-mes">
                            <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic" (click)="valueClickCalendar(row, 0, $event)">
                              <ng-template kendoCalendarMonthCellTemplate let-date>
                                <span class="calendarFiltro-calendario-contenido-dia" [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(row, date)"> {{ date.getDate() }} </span>
                              </ng-template>
                              <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                <div> {{ title }} </div>
                              </ng-template>
                            </kendo-calendar>
                          </div>
                          <div class="col-mes-botones">
                            <div class="calendarFiltro-botones">
                              <div class="clearfix text-center">
                                <!-- style="margin-left: -100px; margin-top: 5px; position: absolute;" -->
                                <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                <button (click)="cargarMeses()">{{'hoy' | translate}}</button>
                              </div>
                              <div>
                                <div class="clearfix"><button (click)="ultimas24HButton(row)">{{'ultimas24H' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos7DiasButton(row)">{{'ultimos7Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos30DiasButton(row)">{{'ultimos30Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos60DiasButton(row)">{{'ultimos60Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos90DiasButton(row)">{{'ultimos90Dias' | translate}}</button></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
              <div *ngIf="row.columna.tipo == 'dateTime'" class="flt-cont-tipo">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionDateTime" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO -->
                  <div class="flt-dato1">
                    <kendo-datetimepicker [(value)]="row.fechaIni"> </kendo-datetimepicker>
                  </div>
                </div>
              </div>
              <!-- Filtro: NUMEROS ENTEROS -->
              <div *ngIf="row.columna.tipo == 'numeric'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999" [(value)]="row.numberMin"></kendo-numerictextbox>
                  </div>
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999" [(value)]="row.numberMax"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: DECIMALES -->
              <div *ngIf="row.columna.tipo == 'decimal'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-numerictextbox [format]="row.decimalformat" [min]="-9999999" [max]="9999999" [(value)]="row.decimalMin"></kendo-numerictextbox>
                  </div>
                  <div class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-numerictextbox *ngIf="row.operator.dobleValor" [format]="row.decimalformat" [min]="-9999999" [max]="9999999" [(value)]="row.decimalMax"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: TEXTO -->
              <div *ngIf="row.columna.tipo == 'string'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionString" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <div class="flt-datos">
                  <div class="flt-dato1 float-left mr-2">
                    <!-- DATO -->
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: COMBO ESTATICO -->
              <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                <div class="flt-opcion">
                  <!-- OPCION -->
                  <kendo-dropdownlist [data]="opcionComboEstricto" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <div class="flt-dato1">
                    <!-- DATO 1 -->
                    <kendo-dropdownlist *ngIf="!row.operator.dobleValor" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.comboSelected" style="width: 220px;">
                    </kendo-dropdownlist>
                  </div>
                  <div class="flt-dato2">
                    <!-- DATO 2 -->
                    <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                                       [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                      </ng-template>
                      <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                  </div>

                </div>
              </div>
              <!-- Filtro: COMBO FLEXIBLE -->
              <div *ngIf="row.columna.tipo == 'comboFlexible'">
                <!-- OPCION -->
                <div class="flt-opcion">
                  <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                </div>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 -->
                  <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4" class="mr-2 float-left" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'"
                                      [(value)]="row.comboSelected"
                                      style="width: 220px;"></kendo-dropdownlist>
                  <!-- DATO 1 -->
                  <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                                     [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                  <!-- DATO 1 -->
                  <div *ngIf="!row.operator.dobleValor && row.operator.id > 4" class="flt-dato1 float-left mr-2">
                    <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                  </div>
                </div>
              </div>
              <!-- Filtro: CHECK -->
              <div *ngIf="row.columna.tipo == 'check'" class="flt-dato1">
                <!-- SERIA MEJOR CON 2 BOTONES SI / NO ? TEXTO PARA VERDADERO / FALSO? -->
                <kendo-switch [(ngModel)]="row.check" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </div>
            <!-- ELIMINAR -->
            <button *ngIf=" (!((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico) && !row.tipoNoVisible "
                    Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i class="fas fa-times"></i> </button>
          </div>
          <!-- SUB GRUPO -->
          <div *ngIf="row.group != undefined">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
          </div>
        </div>
        <!-- BOTON: AÑADIR LINEA (SOLO EN LA ULTIMA LINEA, o primer subgrupo) -->
        <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro && !annadirAutomatico">
          <button Class="btnEditatu btn btn-success btn-sm mr-1" (click)="annadirLineaFiltro(filtro)"> <i class="fas fa-plus"></i> {{ 'agregarCondicion' | translate }} </button>
        </div>
        <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro">
          <hr>
        </div>
      </div>
    </ng-template>
    <div class="clearfix">
      <button class="flt-btnanadirgrupo" (click)="annadirGrupoFiltro()"> <i class="fas fa-plus"></i> {{ 'agregarGrupo' | translate }} </button>
    </div>
    <hr>
    <div class="clearfix">
      <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()" [disabled]="eligiendoFechas"> {{ 'filtrar' | translate }}</button>
      <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()" [disabled]="eligiendoFechas"> {{ 'filterClearButton' | translate }}</button>
    </div>
  </div>
</div>
<!--   CARD CON OPCIONES SELECCIONADAS FILTRO -->
<div class="card">
  <div class="card-body">
    <div class="cont-tags-filtro" id="tagsFiltro">
      <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [datosFiltro] }"></ng-container>
      <ng-template #recursiveListTmpl2 let-filtross="filtros">
        <div *ngFor="let filtro of filtross" class="uno">
          <div class="flt-separador" *ngIf="filtro.group != undefined && filtro != datosFiltro && filtro!=datosFiltro.group[0] && filtroToCount(filtro) > 0"></div>
          <!-- GRUPO -->
          <div *ngFor="let row of filtro.group" class="dos">
            <!-- LINEA FILTRO -->
            <div *ngIf="row.group == undefined &&
            ((row.columna.tipo == 'date') || 
             (row.columna.tipo == 'dateTime') || 
            (row.columna.tipo == 'comboEstrincto' && row.comboSelecteds.length > 0 ) || 
            (row.columna.tipo == 'comboFlexible'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
            (row.columna.tipo == 'check') || 
            (row.columna.tipo == 'numeric') || 
            (row.columna.tipo == 'decimal') || 
            (row.columna.tipo == 'string' && row.text != '' ))" class="tags-filtro">
              <label *ngIf="false"> {{ row.columna.nombre }} </label>
              <label *ngIf="false"> {{ row.operator.nombre }} </label>
              <!-- -->
              <!-- FILTRO -->
              <div class="tags-filtro-label">
                <!-- Filtro: FECHA -->
                <div *ngIf="row.columna.tipo == 'date'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                    <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin == undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                    <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin != undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                  </div>
                </div>
                <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
                <div *ngIf="row.columna.tipo == 'dateTime'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor"> {{row.fechaIni | kendoDate}} </label>
                  </div>
                </div>
                <!-- Filtro: NUMEROS ENTEROS -->
                <div *ngIf="row.columna.tipo == 'numeric'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label"> {{row.numberMin}} </label>
                    <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.numberMax}} </label>
                  </div>
                </div>
                <!-- Filtro: DECIMALES -->
                <div *ngIf="row.columna.tipo == 'decimal'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label"> {{row.decimalMin}} </label>
                    <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.decimalMax}} </label>
                  </div>
                </div>
                <!-- Filtro: TEXTO -->
                <div *ngIf="row.columna.tipo == 'string'">
                  <label class="tags-filtro-label"> {{row.text}} </label>
                </div>
                <!-- Filtro: COMBO ESTATICO -->
                <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                    <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                  </div>
                </div>
                <!-- Filtro: COMBO FLEXIBLE -->
                <div *ngIf="row.columna.tipo == 'comboFlexible'">
                  <div *ngIf="row.operator.id > 0">
                    <div *ngIf="row.operator.id <= 4">
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                      <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                    </div>
                    <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}} </label>
                  </div>
                </div>
                <!-- Filtro: CHECK -->
                <div *ngIf="row.columna.tipo == 'check'">
                  <label class="tags-filtro-label" *ngIf="row.check"> {{ row.columna.nombre }} : {{ 'si' | translate }} </label>
                  <label class="tags-filtro-label" *ngIf="!row.check"> {{ row.columna.nombre }} : {{ 'no' | translate }} </label>
                </div>
              </div>
              <!-- ELIMINAR -->
              <button (click)="borrarLineaFiltro(row, filtro, datPlanos)" class="fas fa-times" style="cursor:pointer" *ngIf="!row.tipoNoVisible"></button>
            </div>
            <!-- SUB GRUPO -->
            <div *ngIf="row.group != undefined">
              <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros:  [row] }"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>
      <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }} ( {{ filtroToCount() }}
        )</button>
      <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button>
      <button *ngIf="actualizarVisible" Class="btn btn-success float-right mr-1" (click)="onFilter()"><i class="fas fa-sync-alt mr-1"></i>{{ 'actualizar' | translate }}</button>
    </div>

  </div>

  <div *ngIf="loadingFiltro" class="k-i-loading"></div>
</div>

<!--GRAFICOS-->
<div class="clearfix" id="grafico-pos">
  <div *ngIf="loadingPerdidas" class="k-i-loading"></div>
</div>

<!-- GRAFICOS - NUEVO -->
<ng-container *ngTemplateOutlet="recursiveListTmplGrid; context:{ formas: formato }"></ng-container>
<ng-template #recursiveListTmplGrid let-forma="formas">
  <div *ngFor="let rows of forma; let j = index" class="row">

    <div *ngFor="let row of rows; let i = index" class="flt-grupo pr-4" [ngStyle]="{'width': row.class}" >
    <!-- <div *ngFor="let row of rows; let i = index" class="flt-grupo" [class]="row.class" > -->

      <!-- GRID -->
      <div *ngIf="row.contenido.tipo == 'grid'" [id]="row.id" >
        <div class="card">
          <div class="card-header">
            <h3>
              {{ row.titulo }}
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div class="row" [id]="row.id" [name]="row.id">

              <!-- GRID -->
              <kendo-grid [data]="row.contenido.dataView"
                          [selectable]="{cell:false, checkboxOnly: false, drag: false, enabled: true, mode: 'multiple'}"
                          [selectedKeys]="row.contenido.selectedData"
                          kendoGridSelectBy="id" 
                          class="grid-plan-corto"
                          filterable="menu"
                          [resizable]="true"
                          [navigable]="true"
                          [reorderable]="true"
                          [sortable]="true" 
                          [pageable]="true"
                          [pageSize]="row.lineasMax"
                          [skip]="state?.skip"
                          (selectionChange)="selectData($event, j, i)"
                          (dataStateChange)="onChange($event, j, i)">
                <ng-template kendoGridToolbarTemplate position="top">
                  <div class="float-right">
                    <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="filtrarGrid(row.contenido.selectedDataInformation, j, i, true)" [disabled]="row.contenido.filtrado">{{'filtrar' | translate }}</button>
                    <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" [disabled]="!row.contenido.limpiarFiltroActivado" (click)="limpiarFiltroGrid(row.contenido.selectedDataInformation, j, i)">{{'limpiarFiltro' | translate }}</button>
                  </div>
                </ng-template>
              
                <!-- CHECKBOX -->
                <kendo-grid-checkbox-column width="10%">
                  <ng-template kendoGridHeaderTemplate>
                    <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                      (selectAllChange)="onSelectAllChangeGrid($event,j,i, 'id')">
                    <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                  </ng-template>
                </kendo-grid-checkbox-column>
              
                <!-- COLUMNAS DINAMICAS TABLA -->
                <kendo-grid-column *ngFor="let col of row.cols" field="{{col.columna}}" title="{{col.nombre}}"
                  width="{{col.tamannoColumna}}%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="col.columna.includes('tiempo')">{{ segundosAFormatoHora(dataItem[col.columna]) }}</span>
                    <span *ngIf="!col.columna.includes('tiempo')">{{ dataItem[col.columna] }}</span>
                  </ng-template>
                </kendo-grid-column>
                
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>

      <!-- RESUMEN SEMANA CIRCULAR Y LINEAS, Y SUBESTADOS CIRCULAR-->
      <div id="moveDiv" *ngIf="row.contenido.tipo == 'ResumenSemanaCircular' || row.contenido.tipo == 'ResumenSemanaLineas' || row.contenido.tipo == 'ResumenSemanaSubestadosCircular'" >
        <div class="card">
          <div class="card-header">
            <h3>
              <label ID="lResumenSemana" CssClass="">{{ row.titulo }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div [id]="row.id" ></div>
            <p [ngStyle]="{'visibility': !labelResumenSemana ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
          </div>
        </div>
      </div>

      <!-- OEE(4) CIRCULAR -->
      <div class="card" *ngIf="row.contenido.tipo == 'OEECircular'">
        <div class="card-header">
          <h3>
          <label ID="lOEE" CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6 col-xxl-12" style="padding: 0 5px">
              <div [id]="row.id" style="height: 140px"></div>
              <p class="oee-donut-label">{{ 'oee' | translate}}</p>
            </div>
            <div class="col-lg-6 col-xxl-4" style="padding: 0 5px">
              <div [id]="row.idDisponibilidad" style="height: 140px"></div>
              <p class="oee-donut-label">{{ 'disponibilidad' | translate}}</p>
            </div>
            <div class="col-lg-6 col-xxl-4" style="padding: 0 5px">
              <div [id]="row.idRendimiento" style="height: 140px"></div>
              <p class="oee-donut-label">{{ 'rendimiento' | translate}}</p>
            </div>
            <div class="col-lg-6 col-xxl-4" style="padding: 0 5px">
              <div [id]="row.idCalidad" style="height: 140px"></div>
              <p class="oee-donut-label">{{ 'calidad' | translate}}</p>
            </div>
          </div>
          <p [ngStyle]="{'visibility': 'hidden'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- OEE(4) BARRAS Y LINEAS -->
      <div id="moveDiv" *ngIf="row.contenido.tipo == 'OEEBarras' || row.contenido.tipo == 'OEELineas'">
        <div class="card">
          <div class="card-header">
            <h3>
              <label ID="lOEEBarra" CssClass="">{{ row.titulo }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div [id]="row.id"></div>
            <p [ngStyle]="{'visibility': 'hidden'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
          </div>
        </div>
      </div>

      <!-- OEE(1) CIRCULAR Y LINEAS -->
      <div class="card" *ngIf="row.contenido.tipo == 'OEE1Circular' || row.contenido.tipo == 'OEE1Lineas'">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row" [id]="row.id">
          </div>
          <p [ngStyle]="{'visibility': 'hidden'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- GRUPO PERDIDAS CIRCULAR, LINEAS, LINEAS CANTIDAD, POR OEE -->
      <div class="card" *ngIf="row.contenido.tipo == 'GrupoPerdidasCircular' || row.contenido.tipo == 'GrupoPerdidasLineas' || row.contenido.tipo == 'GrupoPerdidasLineasCantidad' || row.contenido.tipo == 'GrupoPerdidasOEE'">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row" [id]="row.id" >
          </div>
          <p [ngStyle]="{'visibility': !labelGrupoPerdidas ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- PERDIDAS CIRCULAR, LINEAS, LINEAS CANTIDAD, POR OEE -->
      <div class="card" *ngIf="row.contenido.tipo == 'PerdidasCircular' || row.contenido.tipo == 'PerdidasLineas' || row.contenido.tipo == 'PerdidasLineasCantidad' || row.contenido.tipo == 'PerdidasOEE'">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row" [id]="row.id">
          </div>
          <p [ngStyle]="{'visibility': !labelPerdidas ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- SUBPERDIDAS CIRCULAR, LINEAS, LINEAS CANTIDAD, POR OEE -->
      <div class="card" *ngIf="row.contenido.tipo == 'SubperdidasCircular' || row.contenido.tipo == 'SubperdidasLineas' || row.contenido.tipo == 'SubperdidasLineasCantidad' || row.contenido.tipo == 'SubperdidasOEE'">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row" [id]="row.id">
        </div>
          <p [ngStyle]="{'visibility': !labelSubperdidas ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- ALARMAS CIRCULAR, LINEAS, LINEAS CANTIDAD -->
      <div class="card" *ngIf="row.contenido.tipo == 'AlarmasCircular' || row.contenido.tipo == 'AlarmasLineas' || row.contenido.tipo == 'AlarmasLineasCantidad' || row.contenido.tipo == 'AlarmasOEE'">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row" [id]="row.id">
          </div>
          <p [ngStyle]="{'visibility': !labelAlarmas ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- DOBLE ALARMAS -->
      <div class="card" *ngIf="row.contenido.tipo == 'AlarmasDoble'">
        <div class="card-header">
          <h3>
          <label ID="lOEE" CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6" style="padding: 0 5px" >
              <div [id]="row.id" style="height: 300px"></div>
              <p id="labelAlarmaTipo" class="oee-donut-label">{{ 'tipo' | translate }}</p>
            </div>
            <div class="col-lg-6" style="padding: 0 5px">
              <div [id]="row.idGravedad" style="height: 300px"></div>
              <p id="labelAlarmaGravedad" class="oee-donut-label">{{ 'gravedad' | translate }}</p>
            </div>
          </div>
          <p [ngStyle]="{'visibility': !labelAlarmas ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>

      <!-- MANTNEIMIENTOS CIRCULAR, LINEAS, LINEAS CANTIDAD -->
      <div class="card" *ngIf="row.contenido.tipo == 'MantenimientosCircular' || row.contenido.tipo == 'MantenimientosLineas' || row.contenido.tipo == 'MantenimientosLineasCantidad' || row.contenido.tipo == 'MantenimientosOEE'">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ row.titulo }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row" [id]="row.id">
          </div>
          <p [ngStyle]="{'visibility': !labelMantenimientos ? 'hidden' : 'visible'}" class="text-center"> {{ 'noDatosParaMostrar' | translate}} </p>
        </div>
      </div>
      
    </div>
  </div>
</ng-template>







