import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuService, UsuariosService, PlantaIsometricoService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';
declare var require: any
var $ = require('jquery');
require('jquery-ui-dist/jquery-ui');
import { AppComponent } from '../app.component';

@Component({
  templateUrl: 'diseñadorPlantaIsometrico.component.html',
  styleUrls: ['../../assets/isometrico/estilos.css']
})

export class DiseñadorPlantaIsometricoComponent {

  mousePosition = {
    x: 0,
    y: 0
  };

  public guardando: number = 0; //0: editando diseño, 1: guardando diseño, 2: diseño guardado, 3: error al guardar

  public idPlanta: any;

  public alto: any = 10;
  public ancho: any = 10;

  public nombrePlanta = "";
  public areasProductivas = [];
  public itemSeleccionadoAreasSeccionesTree: any;
  public secciones = [];

  public maquinas = [];
  public carreteras = [];
  public paredes = [];
  public otros = [];

  public valdosas: any = [];
  public valdosasExtra: any = [];

  public ensennarBotonEliminarObjeto: boolean = false;
  user = this.usuariosService.userValue;


  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private appComponent: AppComponent,
    private translateService: TranslateService,
    private plantaIsometricoService: PlantaIsometricoService,
    private myFunctions: MyFunctions) {

    //AL CAMBIAR DE PAGINA VOLVER A HABILITAR EL SCROLL
    router.events.subscribe(value => {
      this.allowScroll();
    });

  }

  ngOnInit(): void {

    this.menuService.titulo = this.translateService.instant('disenadorPlanta').toUpperCase();

    this.idPlanta = this.route.snapshot.params['idPlanta'];

    this.crearObjetos();

  }

  cargarAreasProductivas() {

    var b1, b2: boolean = false;
    var resultPlanta, resultAP: any;

    this.plantaIsometricoService.Get_Planta(this.idPlanta).subscribe(result => {
      resultPlanta = result;
      b1 = true;
      if (b1 && b2) this.cargarAreasProductivas2(resultPlanta, resultAP);
    });

    this.usuariosService.getSecciones().subscribe((result: any) => {      
      resultAP = result;
      b2 = true;
      if (b1 && b2) this.cargarAreasProductivas2(resultPlanta, resultAP);
    });

  }

  cargarAreasProductivas2(resultPlanta, resultAP) {

    this.areasProductivas = [];
    this.secciones = [];
    var idsAreasProductivas = [];
    var i = 0;

    resultAP.forEach(function (row) {
      var areaProductiva: any = { id: i, idAp: row.idAreaProductiva, nombre: row.areaProductiva };
      i++;
      var seccion = { id: i, idS: row.id, nombre: row.nombre, idAreaProductiva: row.idAreaProductiva };
      this.secciones.push(seccion);
      i++;
      if (!idsAreasProductivas.includes(areaProductiva.idAp)) {
        areaProductiva.secciones = [seccion];
        this.areasProductivas.push(areaProductiva);
        idsAreasProductivas.push(areaProductiva.idAp);
      } else {
        var ap = this.areasProductivas.find(x => x.idAp == areaProductiva.idAp);
        if (ap != undefined) ap.secciones.push(seccion);
      }
    }, this);

    var planta = resultPlanta[0];
    this.nombrePlanta = planta.nombre;

    if (planta.idAreaProductiva != null) {
      this.itemSeleccionadoAreasSeccionesTree = this.areasProductivas.find(x => x.idAp == planta.idAreaProductiva);
    }
    if (planta.idSeccion != null) {
      this.areasProductivas.forEach(function (ap) {
        ap.secciones.forEach(function (s) {
          if (s.idS == planta.idSeccion) this.itemSeleccionadoAreasSeccionesTree = s;
        }, this);
      }, this);
    }

    if (this.itemSeleccionadoAreasSeccionesTree != undefined) {
      if (this.itemSeleccionadoAreasSeccionesTree.idAp != undefined) {
        var idSeccionesSelec = this.secciones.filter(x => x.idAreaProductiva == this.itemSeleccionadoAreasSeccionesTree.idAp).map(x => x.idS);
        this.ensennarMaquinasPorSeccion(idSeccionesSelec);
      }
      if (this.itemSeleccionadoAreasSeccionesTree.idS != undefined) {
        this.ensennarMaquinasPorSeccion([this.itemSeleccionadoAreasSeccionesTree.idS]);
      }
    }

  }

  public valueChangeAreasSecciones(value: any) {
    var idSeccionesSelec = [];

    if (this.itemSeleccionadoAreasSeccionesTree != undefined) {
      //ITEM SELECCIONADO ES AREA PRODUCTIVA
      if (this.itemSeleccionadoAreasSeccionesTree.idAp != undefined) {
        idSeccionesSelec = this.secciones.filter(x => x.idAreaProductiva == this.itemSeleccionadoAreasSeccionesTree.idAp).map(x => x.idS);
      }
      //ITEM SELECCIONADO ES SECCION
      if (this.itemSeleccionadoAreasSeccionesTree.idS != undefined) {
        idSeccionesSelec = [this.itemSeleccionadoAreasSeccionesTree.idS];
      }
    }

    this.ensennarMaquinasPorSeccion(idSeccionesSelec);

  }

  ensennarMaquinasPorSeccion(idSeccionesSelec) {
    if (idSeccionesSelec.length == 0) {
      this.maquinas.forEach(function (maq) {maq.ensennarEnMenu = true;});
    } else {
      this.maquinas.forEach(function (maq) {
        if (idSeccionesSelec.includes(maq.idSeccion)) maq.ensennarEnMenu = true;
        else maq.ensennarEnMenu = false;
      }, this);
    }

    var maquinasEnMenu = this.maquinas.filter(x => x.ensennarEnMenu);
    if (maquinasEnMenu == undefined) maquinasEnMenu = [];
    var idsMaquinasEnMenu = maquinasEnMenu.map(x => x.idMaquina);

    this.valdosas.forEach(function (val) {
      if (!idsMaquinasEnMenu.includes(val.idObjeto) && val.idTipoObjeto == 1) {
        var maquina = this.maquinas.find(x => x.idMaquina == val.idObjeto);
        if (maquina != undefined) maquina.estaEnPlanta = false;
        this.eliminarObjeto(val);
      }
    }, this);

  }

  crearObjetos() {

    ///MAQUINAS
    this.maquinas = [];

    this.plantaIsometricoService.Get_Maquinas().subscribe(result => {

      var maquinas: any = result;

      maquinas.forEach(function (maq) {

        var maquina: any = {};
        maquina.idMaquina = maq.idMaquina;
        maquina.esMaquina = true;
        maquina.nombre = maq.nombre;
        maquina.idTipoObjeto = maq.idTipoObjeto;
        maquina.idObjeto = maq.idObjeto;
        maquina.idTipoMaquina = maq.idTipoMaquina;
        // maquina.imagenes = [maq.imagen1_1, maq.imagen1_2, maq.imagen1_3, maq.imagen1_4, maq.imagen1_5, maq.imagen1_6, maq.imagen2_1, maq.imagen2_2, maq.imagen2_3, maq.imagen2_4, maq.imagen2_5, maq.imagen2_6, maq.imagen3_1, maq.imagen3_2, maq.imagen3_3, maq.imagen3_4, maq.imagen3_5, maq.imagen3_6, maq.imagen4_1, maq.imagen4_2, maq.imagen4_3, maq.imagen4_4, maq.imagen4_5, maq.imagen4_6];
        // maquina.imagenesSegundoPiso = [maq.imagenSegundoPiso1_1, maq.imagenSegundoPiso1_2, maq.imagenSegundoPiso1_3, maq.imagenSegundoPiso1_4, maq.imagenSegundoPiso1_5, maq.imagenSegundoPiso1_6, maq.imagenSegundoPiso2_1, maq.imagenSegundoPiso2_2, maq.imagenSegundoPiso2_3, maq.imagenSegundoPiso2_4, maq.imagenSegundoPiso2_5, maq.imagenSegundoPiso2_6, maq.imagenSegundoPiso3_1, maq.imagenSegundoPiso3_2, maq.imagenSegundoPiso3_3, maq.imagenSegundoPiso3_4, maq.imagenSegundoPiso3_5, maq.imagenSegundoPiso3_6, maq.imagenSegundoPiso4_1, maq.imagenSegundoPiso4_2, maq.imagenSegundoPiso4_3, maq.imagenSegundoPiso4_4, maq.imagenSegundoPiso4_5, maq.imagenSegundoPiso4_6];
        maquina.imagenes = JSON.parse(maq.imagenes).filter(f => f.imagen != "");
        maquina.imagenesSegundoPiso = JSON.parse(maq.imagenesSegundoPiso).filter(f => f.imagen != "");
        maquina.imagenesMenu = [maq.imagenMenu1, maq.imagenMenu2, maq.imagenMenu3, maq.imagenMenu4];
        maquina.posicionImagen = 0;
        maquina.seleccionada = false;
        maquina.alto = maq.alto;
        maquina.ancho = maq.ancho;
        maquina.estaEnPlanta = false;
        maquina.idSeccion = maq.idSeccion;
        maquina.ensennarEnMenu = true;
        this.maquinas.push(maquina);

      }, this);

      this.crearValdosas();
      this.cargarAreasProductivas();

    });

    //CARRETERAS
    this.carreteras = [
      { nombre: "Carretera 01", imagenes: [{imagen: "carretera01.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-01.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 02", imagenes: [{imagen: "carretera02.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-02.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 03", imagenes: [{imagen: "carretera03.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-03.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 04", imagenes: [{imagen: "carretera04.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-04.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 05", imagenes: [{imagen: "carretera05.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-05.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 06", imagenes: [{imagen: "carretera06.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-06.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 07", imagenes: [{imagen: "carretera07.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-07.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 08", imagenes: [{imagen: "carretera08.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-08.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 04", imagenes: [{imagen: "carretera09.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-09.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 05", imagenes: [{imagen: "carretera10.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-10.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 06", imagenes: [{imagen: "carretera11.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-11.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 07", imagenes: [{imagen: "carretera12.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-12.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0},
      { nombre: "Carretera 08", imagenes: [{imagen: "carretera13.png", alto: 1, ancho: 1}], miniatura: "carretera-miniatura-13.png", seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0}
    ];

    //PAREDES
    this.paredes = [
      { nombre: "Pared 01", src: "paredes/muro02-01.png", imagenes: [{imagen: "paredes/muro02-01.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 02", src: "paredes/muro02-02.png", imagenes: [{imagen: "paredes/muro02-02.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 03", src: "paredes/muro02-03.png", imagenes: [{imagen: "paredes/muro02-03.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 04", src: "paredes/muro02-04.png", imagenes: [{imagen: "paredes/muro02-04.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 05", src: "paredes/muro02-05.png", imagenes: [{imagen: "paredes/muro02-05.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 06", src: "paredes/muro02-06.png", imagenes: [{imagen: "paredes/muro02-06.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 07", src: "paredes/muro02-07.png", imagenes: [{imagen: "paredes/muro02-07.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 08", src: "paredes/muro02-08.png", imagenes: [{imagen: "paredes/muro02-08.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 09", src: "paredes/muro02-09.png", imagenes: [{imagen: "paredes/muro02-09.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 },
      { nombre: "Pared 10", src: "paredes/muro02-10.png", imagenes: [{imagen: "paredes/muro02-10.png", alto: 1, ancho: 1}], seleccionada: false, ancho: 1, alto: 1, posicionImagen: 0 }
    ];

    //OTROS
    //A estos objetos le ponemos el id en negativo para no confundirlos con las maquiinas
    this.otros = [
      { idObjeto: -1, ancho: 1, alto: 1, nombre: "cajas-1", miniatura: "otros_mini/mini-cajas-1.png", src: "otros/cajas-1.png" ,
            imagenes: [{imagen: "otros/cajas-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -2, ancho: 1, alto: 1, nombre: "cajas-carton-1", miniatura: "otros_mini/mini-cajas-carton-1.png", src: "otros/cajas-carton-1.png" ,
            imagenes: [{imagen: "otros/cajas-carton-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -3, ancho: 1, alto: 1, nombre: "cajas-carton-2", miniatura: "otros_mini/mini-cajas-carton-2.png", src: "otros/cajas-carton-2.png" ,
            imagenes: [{imagen: "otros/cajas-carton-2.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -4, ancho: 1, alto: 1, nombre: "cajas-carton-tipo-2", miniatura: "otros_mini/mini-cajas-carton-tipo-2.png", src: "otros/cajas-carton-tipo-2.png" ,
            imagenes: [{imagen: "otros/cajas-carton-tipo-2.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -5, ancho: 1, alto: 1, nombre: "cajas-carton-tipo-3", miniatura: "otros_mini/mini-cajas-carton-tipo-3.png", src: "otros/cajas-carton-tipo-3.png" ,
            imagenes: [{imagen: "otros/cajas-carton-tipo-3.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -6, ancho: 1, alto: 1, nombre: "cajas-madera-1", miniatura: "otros_mini/mini-cajas-madera-1.png", src: "otros/cajas-madera-1.png" ,
            imagenes: [{imagen: "otros/cajas-madera-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -7, ancho: 1, alto: 1, nombre: "deposito-liquidos", miniatura: "otros_mini/mini-deposito-liquidos.png", src: "otros/deposito-liquidos.png" ,
            imagenes: [{imagen: "otros/deposito-liquidos.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -8, ancho: 1, alto: 1, nombre: "estanterias-barras-metal", miniatura: "otros_mini/mini-estanterias-barras-metal.png", src: "otros/estanterias-barras-metal.png" ,
            imagenes: [{imagen: "otros/estanterias-barras-metal.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -9, ancho: 1, alto: 1, nombre: "estanterias-bidones", miniatura: "otros_mini/mini-estanterias-bidones.png", src: "otros/estanterias-bidones.png" ,
            imagenes: [{imagen: "otros/estanterias-bidones.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -10, ancho: 3, alto: 2, nombre: "estanterias-cajas-carton", miniatura: "otros_mini/mini-estanterias-cajas-carton.png", src1: "otros/estanterias-cajas-carton-001.png", src2: "otros/estanterias-cajas-carton-002.png", src3: "otros/estanterias-cajas-carton-003.png", src4: "otros/estanterias-cajas-carton-004.png", src5: "otros/estanterias-cajas-carton-005.png", src6: "otros/estanterias-cajas-carton-006.png",
          imagenes: [{imagen: "otros/estanterias-cajas-carton-004.png", alto: 1, ancho: 1}, {imagen: "otros/estanterias-cajas-carton-005.png", alto: 1, ancho: 2}, {imagen: "otros/estanterias-cajas-carton-006.png", alto: 1, ancho: 3}
                    ,{imagen: "otros/estanterias-cajas-carton-001.png", alto: 2, ancho: 1}, {imagen: "otros/estanterias-cajas-carton-002.png", alto: 2, ancho: 2}, {imagen: "otros/estanterias-cajas-carton-003.png", alto: 2, ancho: 3}], posicionImagen: 0
      },
      { idObjeto: -11, ancho: 1, alto: 1, nombre: "estanterias-cofres", miniatura: "otros_mini/mini-estanterias-cofres-01.png", src: "otros/estanterias-cofres-01.png" ,
            imagenes: [{imagen: "otros/estanterias-cofres-01.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -12, ancho: 1, alto: 1, nombre: "estanterias-cofres", miniatura: "otros_mini/mini-estanterias-cofres-02.png", src: "otros/estanterias-cofres-02.png" ,
            imagenes: [{imagen: "otros/estanterias-cofres-02.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -13, ancho: 1, alto: 1, nombre: "estanterias-tipo1-1", miniatura: "otros_mini/mini-estanterias-tipo1-1.png", src: "otros/estanterias-tipo1-1.png" ,
            imagenes: [{imagen: "otros/estanterias-tipo1-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -14, ancho: 1, alto: 1, nombre: "estanterias-tipo1-2", miniatura: "otros_mini/mini-estanterias-tipo1-2.png", src: "otros/estanterias-tipo1-2.png" ,
            imagenes: [{imagen: "otros/estanterias-tipo1-2.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -15, ancho: 1, alto: 1, nombre: "transpaleta", miniatura: "otros_mini/mini-transpaleta.png", src: "otros/transpaleta.png" ,
            imagenes: [{imagen: "otros/transpaleta.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -16, ancho: 1, alto: 1, nombre: "forklift-1", miniatura: "otros_mini/mini-forklift-1.png", src: "otros/forklift-1.png" ,
            imagenes: [{imagen: "otros/forklift-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -17, ancho: 1, alto: 1, nombre: "forklift-2", miniatura: "otros_mini/mini-forklift-2.png", src: "otros/forklift-2.png" ,
            imagenes: [{imagen: "otros/forklift-2.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -18, ancho: 1, alto: 1, nombre: "forklift-3", miniatura: "otros_mini/mini-forklift-3.png", src: "otros/forklift-3.png" ,
            imagenes: [{imagen: "otros/forklift-3.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -19, ancho: 1, alto: 1, nombre: "forklift-4", miniatura: "otros_mini/mini-forklift-4.png", src: "otros/forklift-4.png" ,
            imagenes: [{imagen: "otros/forklift-4.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -20, ancho: 1, alto: 1, nombre: "fenwick", miniatura: "otros_mini/mini-fenwick.png", src: "otros/fenwick.png" ,
            imagenes: [{imagen: "otros/fenwick.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -21, ancho: 1, alto: 1, nombre: "palet-sacos", miniatura: "otros_mini/mini-palet-sacos.png", src: "otros/palet-sacos.png" ,
            imagenes: [{imagen: "otros/palet-sacos.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -22, ancho: 1, alto: 1, nombre: "puesto-1", miniatura: "otros_mini/mini-puesto-1.png", src: "otros/puesto-1.png" ,
            imagenes: [{imagen: "otros/puesto-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -23, ancho: 1, alto: 1, nombre: "puesto-2", miniatura: "otros_mini/mini-puesto-2.png", src: "otros/puesto-2.png" ,
            imagenes: [{imagen: "otros/puesto-2.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -24, ancho: 1, alto: 1, nombre: "rollos-1", miniatura: "otros_mini/mini-rollos-1.png", src: "otros/rollos-1.png" ,
            imagenes: [{imagen: "otros/rollos-1.png", alto: 1, ancho: 1}], posicionImagen: 0
      },
      { idObjeto: -25, ancho: 1, alto: 1, nombre: "tubos-estanterias", miniatura: "otros_mini/mini-tubos-estanterias.png", src: "otros/tubos-estanterias.png",
            imagenes: [{imagen: "otros/tubos-estanterias.png", alto: 1, ancho: 1}], posicionImagen: 0
       }
      //{ nombre: "Silo 01", src: "silos/silo01-00.png"
      //{ nombre: "Silo 02", src: "silos/silo01-01.png"
      //{ nombre: "Silo 03", src: "silos/silo01-02.png"
      //{ nombre: "Silo 04", src: "silos/silo01-03.png"
    ];

  }

  crearValdosas() {

    this.plantaIsometricoService.Get_Dimensiones(this.idPlanta).subscribe(result => {

      this.alto = result[0].alto;
      this.ancho = result[0].ancho;

      //UNA VEZ TENEMOS EL ALTO Y EL ANCHO CREAMOS EL ISOMETRICO (PARA AJUSTARLE EL ZOOM)
      this.crearIsometrico();

      //COGEMOS LAS VALDOSAS
      this.plantaIsometricoService.Get_Disenno(this.idPlanta).subscribe(result => {

        var res: any = result;

        if (res.length > 0) {
          this.valdosas = res;
        } else {
          var i;
          for (i = 1; i <= (this.ancho * this.alto); i++) {
            this.valdosas.push({ tilenum: i, esObjeto: false, pared: 0, tileSeleccionado: false, clase:'' });
          }
        }

        this.anadirMaquinasEnPlanta(result);
        this.anadirParedes();
        this.calcularIndices();

      });

      //COGEMOS LAS VALDOSAS DEL SEGUNDO PISO
      this.plantaIsometricoService.Get_Disenno_Segundo_Piso(this.idPlanta).subscribe(result => {

        var res: any = result;

        if (res.length > 0) {
          this.valdosasExtra = res;
        } else {
          this.valdosasExtra = [];
        }

      });

    });

  }

  anadirMaquinasEnPlanta(result) {

    var idMaquinasEnPlanta = result.filter(x => x.idObjeto != null).map(x => x.idObjeto);
    if (idMaquinasEnPlanta != undefined) idMaquinasEnPlanta = [...new Set(idMaquinasEnPlanta)];
    else idMaquinasEnPlanta = [];

    this.maquinas.forEach(function (maq) {
      if (idMaquinasEnPlanta.includes(maq.idObjeto)) maq.estaEnPlanta = true;
    }, this);

  }

  anadirParedes() { //AÑADE LAS PAREDES A LAS VALDOSAS DEL FONDO

    //PONER TODAS LAS VALDOSAS SIN PARED
    this.valdosas.forEach(function (val) {
      val.pared = 0;
    }, this);

    //COLOCAR LAS PAREDES DEL FONDO A LA IZQUIERDA
    var i;
    for (i = 0; i < this.ancho; i++) {
      this.valdosas[i].pared = 1;
    }

    //COLOCAR LAS PAREDES DEL FONDO A LA DERECHA
    var i;
    for (i = 0; i < this.alto; i++) {
      this.valdosas[i * this.ancho].pared = 2;
    }

    //COLOCAR LA PARED DE LA ESQUINA DEL FONDO
    this.valdosas[0].pared = 3;

  }

  calcularIndices() {

    //El indice va de arriba hacia abajo (el punto de arriba sera top: 0, right: 0)

    this.valdosas.forEach(function (val, index) {
      var top = Math.floor(index / this.ancho);
      var right = Math.floor(index % this.ancho);
      val.top = top;
      val.right = right;
    }, this);

  }


  clickObjeto(objeto) {

    if (objeto.seleccionada) {

      //Deseleccionar todos los objetos del menu
      this.maquinas.forEach(function (maq) { maq.seleccionada = false; }, this);
      this.carreteras.forEach(function (car) { car.seleccionada = false; }, this);
      this.paredes.forEach(function (par) { par.seleccionada = false; }, this);
      this.otros.forEach(function (otr) { otr.seleccionada = false; }, this);

      //Deseleccionar el elemento que este clickado en el plano (si hubiera alguno)
      this.valdosas.forEach(function (val) { val.tileSeleccionado = false; }, this);
      this.valdosasExtra.forEach(function (val) { val.tileSeleccionado = false; }, this);

    } else {

      //Deseleccionar todos los objetos del menu
      this.maquinas.forEach(function (maq) { maq.seleccionada = false; }, this);
      this.carreteras.forEach(function (car) { car.seleccionada = false; }, this);
      this.paredes.forEach(function (par) { par.seleccionada = false; }, this);
      this.otros.forEach(function (otr) { otr.seleccionada = false; }, this);

      objeto.seleccionada = true;

      //Deseleccionar el elemento que este clickado en el plano (si hubiera alguno)
      this.valdosas.forEach(function (val) { val.tileSeleccionado = false; }, this);
      this.valdosasExtra.forEach(function (val) { val.tileSeleccionado = false; }, this);

      //Si el objeto clickado en el menu lateral esta colocado en el plano entonces resaltar
      this.valdosas.forEach(function (val) {
        if (objeto.idObjeto != undefined && objeto.idObjeto == val.idObjeto && objeto.idTipoObjeto == 1 && objeto.idTipoMaquina == val.idTipoMaquina) {
          val.tileSeleccionado = true;
        }
      }, this);
      this.valdosasExtra.forEach(function (val) {
        if (objeto.idObjeto != undefined && objeto.idObjeto == val.idObjeto && objeto.idTipoObjeto == 1 && objeto.idTipoMaquina == val.idTipoMaquina) {
          val.tileSeleccionado = true;
        }
      }, this);

    }

    this.ensennarEliminarObjeto();

  }

  clickGirarMaquina(maquina, event) {

    event.stopPropagation();

    if (maquina.posicionImagen == 3) maquina.posicionImagen = 0;
    else maquina.posicionImagen++;

    this.ensennarEliminarObjeto();

  }

  onMouseDown($event) {
    this.mousePosition.x = $event.screenX;
    this.mousePosition.y = $event.screenY;
  }

  clickValdosa(valdosa, event) {
    
    if (this.mousePosition.x === event.screenX && this.mousePosition.y === event.screenY) {

      if (valdosa.tileSeleccionado) {

        //Deseleccionar todos los tiles
        this.valdosas.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);
        this.valdosasExtra.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);

        //Deseleccionar todos los objetos del menu
        this.maquinas.forEach(function (maq) { maq.seleccionada = false; }, this);
        this.carreteras.forEach(function (car) { car.seleccionada = false; }, this);
        this.paredes.forEach(function (par) { par.seleccionada = false; }, this);
        this.otros.forEach(function (otr) { otr.seleccionada = false; }, this);

      } else {

        //Deseleccionar todos los tiles
        this.valdosas.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);
        this.valdosasExtra.forEach(function (tile) {
          tile.tileSeleccionado = false;
        }, this);

        //Deseleccionar todos los objetos del menu
        this.maquinas.forEach(function (maq) { maq.seleccionada = false; }, this);
        this.carreteras.forEach(function (car) { car.seleccionada = false; }, this);
        this.paredes.forEach(function (par) { par.seleccionada = false; }, this);
        this.otros.forEach(function (otr) { otr.seleccionada = false; }, this);

        valdosa.tileSeleccionado = true;

        //Seleccionar los tiles de la misma pareja
        if (valdosa.tienePareja) {
          this.valdosas.forEach(function (tile) {
            if (tile.esObjeto) {
              if (valdosa.pareja == tile.pareja && valdosa.idTipoMaquina == tile.idTipoMaquina) {
                tile.tileSeleccionado = true;
              }
            }
          }, this);
        }
        //Seleccionar los tiles del segundo piso
        this.valdosasExtra.forEach(function (tile) {
          if (tile.esObjeto) {
            if (valdosa.idObjeto == tile.idObjeto && valdosa.idTipoMaquina == tile.idTipoMaquina) {
              tile.tileSeleccionado = true;
            }
          }
        }, this);

        //Si el objeto tiene idObjeto (es unico) seleccionar el objeto en el menu (de momento solo maquinas)
        this.maquinas.forEach(function (maq) {
          if (valdosa.idObjeto != undefined && valdosa.idObjeto == maq.idObjeto && valdosa.idTipoMaquina == maq.idTipoMaquina) {
            maq.seleccionada = true;
          }
        }, this);

      }

      this.ensennarEliminarObjeto();

    }

  }

  mouseEnterValdosa(valdosa) {

    valdosa.sobre = true;

    //Seleccionar los tiles de la misma pareja
    if (valdosa.tienePareja) {
      this.valdosas.forEach(function (tile) {
        if (tile.esObjeto) {
          if (valdosa.pareja == tile.pareja && valdosa.idTipoMaquina == tile.idTipoMaquina) tile.sobre = true;
        }
      }, this);
    }
    //Seleccionar los tiles del segundo piso
    this.valdosasExtra.forEach(function (tile) {
      if (tile.esObjeto) {
        if (valdosa.idObjeto == tile.idObjeto && valdosa.idTipoMaquina == tile.idTipoMaquina) tile.sobre = true;
      }
    }, this);

  }

  mouseLeaveValdosa(valdosa) {

    valdosa.sobre = false;

    //Deseleccionar los tiles de la misma pareja
    if (valdosa.tienePareja) {
      this.valdosas.forEach(function (tile) {
        if (tile.esObjeto) {
          if (valdosa.pareja == tile.pareja && valdosa.idTipoMaquina == tile.idTipoMaquina) tile.sobre = false;
        }
      }, this);
    }
    //Deseleccionar los tiles del segundo piso
    this.valdosasExtra.forEach(function (tile) {
      if (tile.esObjeto) {
        if (valdosa.idObjeto == tile.idObjeto && valdosa.idTipoMaquina == tile.idTipoMaquina) tile.sobre = false;
      }
    }, this);

  }

  clickValdosaVacia(valdosa, event) {

    if (this.mousePosition.x === event.screenX && this.mousePosition.y === event.screenY) {
      
      //Si hay un objeto seleccionado y la valdosa esta vacia entonces poner imagen
      var objeto: any;
      var seleccionada = false;
      var idTipoObjeto = 0; //1: maquina, 2: carretera, 3: pared, 4: otros

      //Cogemos la fecha y hora del momento actual para al eliminar luego los objetos si ya estan en el plano, no borrar el que estamos creando
      var current_datetime = this.myFunctions.getDateNow()
      current_datetime.getMilliseconds
      var momentousData = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds() + ":" + new Date().getMilliseconds();

      this.maquinas.forEach(function (maq) {
        if (maq.seleccionada) {
          objeto = maq;
          seleccionada = true;
          idTipoObjeto = 1;
        }
      }, this);

      this.carreteras.forEach(function (car) {
        if (car.seleccionada) {
          objeto = car;
          seleccionada = true;
          idTipoObjeto = 2;
        }
      }, this);

      this.paredes.forEach(function (par) {
        if (par.seleccionada) {
          objeto = par;
          seleccionada = true;
          idTipoObjeto = 3;
        }
      }, this);

      this.otros.forEach(function (otr) {
        if (otr.seleccionada) {
          objeto = otr;
          seleccionada = true;
          idTipoObjeto = 4;
        }
      }, this);

      if (!valdosa.esObjeto && seleccionada && !valdosa.tileRoja) {

        valdosa.esObjeto = true;
        valdosa.nomaq = objeto.nombre;
        valdosa.idObjeto = objeto.idObjeto;
        valdosa.tileSeleccionado = false;
        valdosa.tienePareja = false;

        valdosa.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + 0;

        //CARRETERA
        if (idTipoObjeto == 2) valdosa.carretera = objeto.src;

        //PARED
        if (idTipoObjeto == 3) valdosa.src = objeto.src;

        //OTROS 1x1
        if (idTipoObjeto == 4 && objeto.ancho == 1 && objeto.alto == 1) valdosa.src = objeto.src;

        //OTROS 3x2
        if (idTipoObjeto == 4 && objeto.ancho == 3 && objeto.alto == 2) {

          valdosa.tienePareja = true;
          valdosa.pareja = objeto.idObjeto + "_" + momentousData;
          valdosa.esObjeto = true;
          valdosa.nomaq = objeto.nombre;
          valdosa.idTipoObjeto = idTipoObjeto;
          valdosa.idObjeto = objeto.idObjeto;
          valdosa.tileVerde = false;
          valdosa.tileRoja = false;
          valdosa.tileSeleccionado = true;
          valdosa.momentousData = momentousData;

          valdosa.vistaPreviaChilds.forEach(function (c, index) {
            c.tienePareja = true;
            c.pareja = objeto.idObjeto + "_" + momentousData;
            c.esObjeto = true;
            c.nomaq = objeto.nombre;
            c.idTipoObjeto = idTipoObjeto;
            c.idObjeto = objeto.idObjeto;
            c.tileVerde = false;
            c.tileRoja = false;
            c.tileSeleccionado = true;
            c.momentousData = momentousData;
            c.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + (index + 1);
          }, this);

          //Deseleccionar todos los demas tiles
          this.valdosas.forEach(function (tile) {
            tile.tileSeleccionado = false;
          }, this);
          this.valdosasExtra.forEach(function (tile) {
            tile.tileSeleccionado = false;
          }, this);

        }

        //MAQUINA 1x1
        if (idTipoObjeto == 1 && objeto.ancho == 1 && objeto.alto == 1) {
          valdosa.src = objeto.imagenes[objeto.posicionImagen * 6].imagen;
          valdosa.tileSeleccionado = true;
          valdosa.idTipoMaquina = objeto.idTipoMaquina;
        }

        //MAQUINA DE MAS DE 1x1
        if (idTipoObjeto == 1 && (objeto.ancho > 1 || objeto.alto > 1)) {

          valdosa.tienePareja = true;
          valdosa.pareja = objeto.idObjeto;
          valdosa.esObjeto = true;
          valdosa.nomaq = objeto.nombre;
          valdosa.idTipoObjeto = idTipoObjeto;
          valdosa.idTipoMaquina = objeto.idTipoMaquina;
          valdosa.idObjeto = objeto.idObjeto;
          valdosa.tileVerde = false;
          valdosa.tileRoja = false;
          valdosa.tileSeleccionado = true;
          valdosa.momentousData = momentousData;
          valdosa.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + 0;

          valdosa.vistaPreviaChilds.forEach(function (c, index) {
            c.tienePareja = true;
            c.pareja = objeto.idObjeto;
            c.esObjeto = true;
            c.nomaq = objeto.nombre;
            c.idTipoMaquina = objeto.idTipoMaquina;
            c.idTipoObjeto = idTipoObjeto;
            c.idObjeto = objeto.idObjeto;
            c.tileVerde = false;
            c.tileRoja = false;
            c.tileSeleccionado = true;
            c.momentousData = momentousData;
            c.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + (index + 1);
          }, this);

          //SI TIENE IMAGEN EN SEGUNDO PISO
          if (valdosa.vistaPreviaChildsSegundoPiso != undefined) {
            valdosa.vistaPreviaChildsSegundoPiso.forEach(function (c) {
              c.tienePareja = true;
              c.pareja = objeto.idObjeto;
              c.esObjeto = true;
              c.nomaq = objeto.nombre;
              c.idTipoObjeto = idTipoObjeto;
              c.idTipoMaquina = objeto.idTipoMaquina;
              c.idObjeto = objeto.idObjeto;
              c.tileSeleccionado = true;
              c.vistaPrevia = false;
              c.sobre = true;
              c.momentousData = momentousData;
              c.transitionMomentaneo = 'all 0s';
              setTimeout(function () {
                c.transitionMomentaneo = 'ease-in-out all 500ms';
              }, 100);
            }, this);
          }

        }

        //PONEMOS LA MAQUINA DEL MENU LATERAL SELECCIONADA
        var maquina = this.maquinas.find(x => x.idMaquina == valdosa.idObjeto);
        if (maquina != undefined) maquina.estaEnPlanta = true;

      }

      //Si el objeto esta en otra valdosa y es un objeto unico entonces borrar de la otra valdosa
      if (objeto != undefined && !valdosa.tileRoja) {
        this.valdosas.forEach(function (val, index) {
          if (objeto.idObjeto != undefined && val.idObjeto == objeto.idObjeto && val.idTipoObjeto == 1 && val.idTipoMaquina == objeto.idTipoMaquina && valdosa.tilenum != val.tilenum && momentousData != val.momentousData) {
            this.valdosas[index].esObjeto = false;
            this.valdosas[index].idObjeto = null;
            this.valdosas[index].idTipoObjeto = null;
            this.valdosas[index].idTipoMaquina = null;
            this.valdosas[index].nomaq = null;
            this.valdosas[index].tienePareja = null;
            this.valdosas[index].pareja = null;
            this.valdosas[index].src = null;
            this.valdosas[index].tileSeleccionado = null;
            this.valdosas[index].carretera = null;
            this.valdosas[index].vistaPrevia = false;
          }
        }, this);

        //Si tiene segundo piso borrar las valdosas de la lista de valdosas de segundo piso
        var index = this.valdosasExtra.length - 1;
        while (index >= 0) {
          if (objeto.idObjeto != undefined && objeto.idObjeto == this.valdosasExtra[index].idObjeto && this.valdosasExtra[index].idTipoObjeto == 1 && objeto.idTipoMaquina == this.valdosasExtra[index].idTipoMaquina && momentousData != this.valdosasExtra[index].momentousData) {
            this.valdosasExtra.splice(index, 1);
          }
          index -= 1;
        }

      }

      this.ensennarEliminarObjeto();

    }
    
  }

  mouseEnterValdosaVacia(valdosa) {

    //Si hay un objeto seleccionado y la valdosa esta vacia entonces hacer vista previa
    var objeto: any;
    var seleccionada = false;
    var idTipoObjeto = 0; //1: maquina, 2: carretera, 3: pared, 4: otros

    this.maquinas.forEach(function (maq) {
      if (maq.seleccionada) {
        objeto = maq;
        seleccionada = true;
        idTipoObjeto = 1;
      }
    }, this);

    this.carreteras.forEach(function (car) {
      if (car.seleccionada) {
        objeto = car;
        seleccionada = true;
        idTipoObjeto = 2;
      }
    }, this);

    this.paredes.forEach(function (par) {
      if (par.seleccionada) {
        objeto = par;
        seleccionada = true;
        idTipoObjeto = 3;
      }
    }, this);

    this.otros.forEach(function (otr) {
      if (otr.seleccionada) {
        objeto = otr;
        seleccionada = true;
        idTipoObjeto = 4;
      }
    }, this);

    if (!valdosa.esObjeto && seleccionada) {
      valdosa.vistaPrevia = true;
      valdosa.idTipoObjeto = idTipoObjeto;

      //CARRETERA
      if (idTipoObjeto == 2) valdosa.carretera = objeto.src;

      //PARED
      if (idTipoObjeto == 3) valdosa.src = objeto.src;

      //OTROS 1x1
      if (idTipoObjeto == 4 && objeto.ancho == 1 && objeto.alto == 1) valdosa.src = objeto.src;

      //OTROS 3x2
      if (idTipoObjeto == 4 && objeto.ancho == 3 && objeto.alto == 2) {

        //PRIMERO CONSEGUIMOS TODAS LAS VALDOSAS QUE VA A OCUPAR
        // var valdosa1;
        // var valdosa2 = valdosa; //LA VALDOSA EN LA QUE ESTAMOS SERÁ LA DEL MEDIO
        // var valdosa3;
        // var valdosa4;
        // var valdosa5;
        // var valdosa6;

        // valdosa1 = this.valdosas[valdosa2.tilenum - 1 - this.ancho];
        // valdosa3 = this.valdosas[valdosa2.tilenum - 1 + this.ancho];
        // if (valdosa1 != undefined) {
        //   valdosa4 = this.valdosas[valdosa1.tilenum - 1 - 1];
        //   if (valdosa1.pared == 2) valdosa4 = undefined;
        // }
        // if (valdosa2 != undefined) {
        //   valdosa5 = this.valdosas[valdosa2.tilenum - 1 - 1];
        //   if (valdosa2.pared == 2) valdosa5 = undefined;
        // }
        // if (valdosa3 != undefined) {
        //   valdosa6 = this.valdosas[valdosa3.tilenum - 1 - 1];
        //   if (valdosa3.pared == 2) valdosa6 = undefined;
        // }

        // if (valdosa1 != undefined && valdosa2 != undefined && valdosa3 != undefined && valdosa4 != undefined && valdosa5 != undefined && valdosa6 != undefined &&
        //   !valdosa1.esObjeto && !valdosa3.esObjeto && !valdosa4.esObjeto && !valdosa5.esObjeto && !valdosa6.esObjeto) {

        //   valdosa1.src = objeto.src1;
        //   valdosa1.vistaPrevia = true;
        //   valdosa1.idTipoObjeto = idTipoObjeto;
        //   valdosa2.src = objeto.src2;
        //   valdosa2.vistaPrevia = true;
        //   valdosa2.idTipoObjeto = idTipoObjeto;
        //   valdosa3.src = objeto.src3;
        //   valdosa3.vistaPrevia = true;
        //   valdosa3.idTipoObjeto = idTipoObjeto;
        //   valdosa4.src = objeto.src4;
        //   valdosa4.vistaPrevia = true;
        //   valdosa4.idTipoObjeto = idTipoObjeto;
        //   valdosa5.src = objeto.src5;
        //   valdosa5.vistaPrevia = true;
        //   valdosa5.idTipoObjeto = idTipoObjeto;
        //   valdosa6.src = objeto.src6;
        //   valdosa6.vistaPrevia = true;
        //   valdosa6.idTipoObjeto = idTipoObjeto;

        //   valdosa1.tileVerde = true;
        //   valdosa2.tileVerde = true;
        //   valdosa3.tileVerde = true;
        //   valdosa4.tileVerde = true;
        //   valdosa5.tileVerde = true;
        //   valdosa6.tileVerde = true;

        //   valdosa2.vistaPreviaChilds = [valdosa1, valdosa3, valdosa4, valdosa5, valdosa6];

        //   valdosa2.vistaPreviaChildsSegundoPiso = [];

        // } else {

        //   valdosa2.vistaPrevia = false;
        //   valdosa2.idTipoObjeto = null;
        //   valdosa2.tileRoja = true;
        //   valdosa2.vistaPreviaChilds = [];

        //   if (valdosa1 != undefined) {
        //     valdosa1.tileRoja = true;
        //     valdosa2.vistaPreviaChilds.push(valdosa1);
        //   }

        //   if (valdosa3 != undefined) {
        //     valdosa3.tileRoja = true;
        //     valdosa2.vistaPreviaChilds.push(valdosa3);
        //   }

        //   if (valdosa4 != undefined) {
        //     valdosa4.tileRoja = true;
        //     valdosa2.vistaPreviaChilds.push(valdosa4);
        //   }

        //   if (valdosa5 != undefined) {
        //     valdosa5.tileRoja = true;
        //     valdosa2.vistaPreviaChilds.push(valdosa5);
        //   }

        //   if (valdosa6 != undefined) {
        //     valdosa6.tileRoja = true;
        //     valdosa2.vistaPreviaChilds.push(valdosa6);
        //   }

        // }

      }

      //#region MAQUINA NxN
      /**
       * EXPLICACION:
       * siempre se coge como valdosa de referencia la valdosa seleccionada
       * valdosaN: el numero de la valdosa que se esta analizando en el momento (valdosaN = 0 es la valdosa seleccionada)
       * valdosaLag: es la valdosa que se esta analizando
       * esPrimeraVez se utiliza para solo annadir a la valdosa todas las valosas que no sean la seleccionada
       * entraEnPlanta se utiliza para saber si toda la maquina entra
       */
      var arrayAncho = [...Array(objeto.ancho).keys()];
      var valdosa1 = valdosa;
      valdosa1.vistaPreviaChilds = [];
      valdosa1.vistaPreviaChildsSegundoPiso = [];
      var esPrimeraVez = true
      var entraEnPlanta = true;
      var index = 0;
      // comprobar si la maquina entra a lo ancho
      arrayAncho.forEach(valdosaN => {
        var valdosaLag;

        if (objeto.posicionImagen == 0 || objeto.posicionImagen == 2) {
          valdosaLag = this.valdosas[(valdosa1.tilenum - 1) + (this.ancho * (valdosaN))]
        } else if (objeto.posicionImagen == 1 || objeto.posicionImagen == 3) {
          valdosaLag = this.valdosas[(valdosa1.tilenum - 1) + (valdosaN)];
        }

        if (valdosa1 != undefined && valdosaLag != undefined && !valdosaLag.esObjeto && entraEnPlanta && (valdosaLag.pared != 2 || valdosa1.pared == 2)) { // entra en planta
          
          valdosa1.src = objeto.imagenes[objeto.posicionImagen * (objeto.ancho * objeto.alto)].imagen;
          valdosa1.vistaPrevia = true;
          
          if (objeto.posicionImagen == 0 || objeto.posicionImagen == 2)
            valdosaLag.src = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + valdosaN].imagen;
          else
            valdosaLag.src = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + (valdosaN + valdosaN * (objeto.alto - 1))].imagen;

          valdosaLag.vistaPrevia = true;
          valdosaLag.idTipoObjeto = idTipoObjeto;

          valdosa1.tileVerde = true;
          valdosaLag.tileVerde = true;

          if (!esPrimeraVez) {
            valdosaLag.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + index;
            valdosa1.vistaPreviaChilds.push(valdosaLag);
          } else {
            esPrimeraVez = false;
            valdosa1.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + index;
          }

          var imagen2PisoArray = objeto.imagenesSegundoPiso?.filter(f => f.posicion == objeto.posicionImagen);
          var imagen2Piso = imagen2PisoArray?.filter(f => (f.ancho - 1) == valdosaN)[0]?.imagen;
          if ((imagen2Piso != null || imagen2Piso != undefined) && imagen2Piso != '') {
            var valdosaSuperior2 = { tilenum: "rel2_" + objeto.idObjeto, vistaPrevia: true, esObjeto: true, pared: 0, tileSeleccionado: false, posTop: (valdosaLag.top - 1) * 100, posRight: (valdosaLag.right - 1) * 100, src: imagen2Piso };
            this.pushValdosaSuperior(valdosaSuperior2);
            valdosa1.vistaPreviaChildsSegundoPiso.push(valdosaSuperior2);
          }

        } else { // no entra en planta
          entraEnPlanta = false;
          
          valdosa1.src = '';
          valdosa1.clase = '';
          valdosa1.vistaPrevia = false;
          valdosa1.idTipoObjeto = null;
          valdosa1.tileRoja = true;

          // deshacer vista primer piso
          var n = 0;
          valdosa1.vistaPreviaChilds.forEach(child => {
            child.tileVerde = false;
            child.tileRoja = true;
            var imagen = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + (n+1)].imagen
            if (objeto.posicionImagen == 1 || objeto.posicionImagen == 3)
              imagen = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + ((n+1) + (n+1) * (objeto.alto - 1))].imagen;
            if ((child.carretera == '' || child.carretera == null) && !child.esObjeto && imagen == child.src) {
              child.src = '';
              valdosa1.clase = '';
              child.vistaPrevia = false;
              child.idTipoObjeto = null;
            }
            n++;
          });

          // deshacer vista segundo piso
          valdosa1.vistaPreviaChildsSegundoPiso.forEach(function (v) {
            var index = this.valdosasExtra.length - 1;
            while (index >= 0) {
              if (v.tilenum == this.valdosasExtra[index].tilenum && this.valdosasExtra[index].vistaPrevia) {
                this.valdosasExtra.splice(index, 1);
              }
              index -= 1;
            }
          }, this);
          
          valdosa1.vistaPreviaChildsSegundoPiso = [];

          if (valdosaLag != undefined) {
            valdosaLag.tileRoja = true;
            valdosa1.vistaPreviaChilds.push(valdosaLag);
          }

        }

        index++;
      });

      // comprobar si la maquina entra a lo alto
      if (objeto.alto > 1) {
        // var arrayAlto = [...Array(objeto.alto).keys()];
        var arrayAlto = [...Array(objeto.ancho).keys()];
        arrayAlto.forEach(valdosaN => {
          var valdosaLag;

          if (objeto.posicionImagen == 0 || objeto.posicionImagen == 2) {
            valdosaLag = this.valdosas[(valdosa1.tilenum) + (this.ancho * valdosaN)]
          } else if (objeto.posicionImagen == 1 || objeto.posicionImagen == 3) {
            valdosaLag = this.valdosas[(valdosa1.tilenum - 1) + (this.ancho) + (valdosaN)];
          }

          if (valdosa1 != undefined && valdosaLag != undefined && !valdosaLag.esObjeto && entraEnPlanta && (valdosaLag.pared != 2 || valdosa1.pared == 2)) { // entra en planta
               
            if (objeto.posicionImagen == 0 || objeto.posicionImagen == 2)
              valdosaLag.src = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + valdosaN + (objeto.ancho)].imagen;
            else
              valdosaLag.src = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + (valdosaN + valdosaN * (objeto.ancho - 1)) + objeto.ancho - 1].imagen;

            valdosaLag.vistaPrevia = true;
            valdosaLag.idTipoObjeto = idTipoObjeto;
            valdosaLag.tileVerde = true;

            if (!esPrimeraVez) {
              valdosaLag.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + index;
              valdosa1.vistaPreviaChilds.push(valdosaLag);
            } else {
              esPrimeraVez = false;
              valdosa1.clase = 'grupo-' + (objeto.ancho * objeto.alto) + '-' + index;
            }

            var imagen2PisoArray = objeto.imagenesSegundoPiso?.filter(f => f.posicion == objeto.posicionImagen);
            var imagen2Piso = imagen2PisoArray?.filter(f => (f.ancho - 1) == valdosaN)[0]?.imagen;
            if ((imagen2Piso != null || imagen2Piso != undefined) && imagen2Piso != '') {
              var valdosaSuperior2 = { tilenum: "rel2_" + objeto.idObjeto, vistaPrevia: true, esObjeto: true, pared: 0, tileSeleccionado: false, posTop: (valdosaLag.top - 1) * 100, posRight: (valdosaLag.right - 1) * 100, src: imagen2Piso };
              this.pushValdosaSuperior(valdosaSuperior2);
              valdosa1.vistaPreviaChildsSegundoPiso.push(valdosaSuperior2);
            }

          } else { // no entra en planta
            entraEnPlanta = false;
            
            valdosa1.src = '';
            valdosa1.clase = '';
            valdosa1.vistaPrevia = false;
            valdosa1.idTipoObjeto = null;
            valdosa1.tileRoja = true;

            // deshacer vista primer piso
            var n = 0;
            valdosa1.vistaPreviaChilds.forEach(child => {
              child.tileVerde = false;
              child.tileRoja = true;
              var imagen = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + (n+1)].imagen
              if (objeto.posicionImagen == 1 || objeto.posicionImagen == 3)
                imagen = objeto.imagenes[(objeto.posicionImagen * (objeto.ancho * objeto.alto)) + ((n+1) + (n+1) * (objeto.alto - 1))].imagen;
              if ((child.carretera == '' || child.carretera == null) && !child.esObjeto && imagen == child.src) {
                child.src = '';
                valdosa1.clase = '';
                child.vistaPrevia = false;
                child.idTipoObjeto = null;
              }
              n++;
            });

            // deshacer vista segundo piso
            valdosa1.vistaPreviaChildsSegundoPiso.forEach(function (v) {
              var index = this.valdosasExtra.length - 1;
              while (index >= 0) {
                if (v.tilenum == this.valdosasExtra[index].tilenum && this.valdosasExtra[index].vistaPrevia) {
                  this.valdosasExtra.splice(index, 1);
                }
                index -= 1;
              }
            }, this);
            
            valdosa1.vistaPreviaChildsSegundoPiso = [];

            if (valdosaLag != undefined) {
              valdosaLag.tileRoja = true;
              valdosa1.vistaPreviaChilds.push(valdosaLag);
            }

          }

          index++;
        })
      }

      //#endregion
    }

  }

  pushValdosaSuperior(valdosaSuperior1) { //AÑADE EN LA POSICION CORRECTA PARA NO SOLAPAR

    if (this.valdosasExtra.length > 0) {

      var añadida = false;
      var index = this.valdosasExtra.length - 1;

      //ITERAMOS DE ATRAS A ALANTE
      while (index >= 0) {
        if (!añadida) {
          if (valdosaSuperior1.posTop > this.valdosasExtra[index].posTop) {
            this.valdosasExtra.splice(index + 1 , 0, valdosaSuperior1);
            añadida = true;
          }
          if (valdosaSuperior1.posTop == this.valdosasExtra[index].posTop) {
            if (valdosaSuperior1.posRight > this.valdosasExtra[index].posRight) {
              this.valdosasExtra.splice(index + 1 , 0, valdosaSuperior1);
              añadida = true;
            }
          }
        }
        index -= 1;
      }

      if (!añadida) {
        this.valdosasExtra.splice(0, 0, valdosaSuperior1);
      }

    } else {

      this.valdosasExtra.push(valdosaSuperior1);

    }

  }

  mouseLeaveValdosaVacia(valdosa) {

    if (valdosa.vistaPrevia) {
      valdosa.vistaPrevia = false;
      valdosa.src = "";
      valdosa.clase = "";
      valdosa.carretera = "";

      //Si es de mas de 1x1 borrar la vista previa de todos los childs
      if (valdosa.vistaPreviaChilds != undefined) {

        valdosa.vistaPreviaChilds.forEach(function (c) {
          c.vistaPrevia = false;
          c.src = "";
          c.clase = "";
          c.carretera = "";
        }, this);

      }

    }

    if (valdosa.vistaPreviaChilds != undefined) {

      valdosa.vistaPreviaChilds.forEach(function (v) {
        v.tileVerde = false;
        v.tileRoja = false;
        // v.src = "";
        // v.clase = "";
      }, this);

      delete valdosa.vistaPreviaChilds;

    }

    //Si tiene segundo piso borrar las valdosas de la lista de valdosas de segundo piso
    if (valdosa.vistaPreviaChildsSegundoPiso != undefined && valdosa.vistaPreviaChildsSegundoPiso.length > 0) {

      valdosa.vistaPreviaChildsSegundoPiso.forEach(function (v) {

        var index = this.valdosasExtra.length - 1;
        while (index >= 0) {
          if (v.tilenum == this.valdosasExtra[index].tilenum && this.valdosasExtra[index].vistaPrevia) {
            this.valdosasExtra.splice(index, 1);
          }
          index -= 1;
        }

        valdosa.vistaPreviaChildsSegundoPiso = [];

      }, this);

    }

    valdosa.tileVerde = false;
    valdosa.tileRoja = false;

    console.log(this.valdosas)

  }

  disminuirAlto() {
    if (this.alto == 5) return;
    var nuevoAlto = this.alto - 1;
    this.onChangeAlto(nuevoAlto);
  }

  aumentarAlto() {
    if (this.alto == 30) return;
    var nuevoAlto = this.alto + 1;
    this.onChangeAlto(nuevoAlto);
  }

  disminuirAncho() {
    if (this.ancho == 5) return;
    var nuevoAncho = this.ancho - 1;
    this.onChangeAncho(nuevoAncho);
  }

  aumentarAncho() {
    if (this.ancho == 30) return;
    var nuevoAncho = this.ancho + 1;
    this.onChangeAncho(nuevoAncho);
  }

  onChangeAlto(e) {

    var altoAnterior = this.alto;
    var altoActual = e;
    this.alto = altoActual;

    if (altoActual > altoAnterior) { //SE HA INCREMENTADO EL ALTO
      var i;
      var tilenumCount = this.valdosas[this.valdosas.length - 1].tilenum;
      for (i = 0; i < this.ancho; i++) {
        tilenumCount++;
        this.valdosas.push({ tilenum: tilenumCount, esObjeto: false, pared: 0, tileSeleccionado: false });
      }
    }

    if (altoActual < altoAnterior) { //SE HA DISMINUIDO EL ALTO
      var i;
      for (i = 0; i < this.ancho; i++) {
        var valdosaEliminada = this.valdosas.pop();
        //SI LA VALDOSA ELIMINADA TIENE UNA MAQUINA QUE OCUPA MAS DE 1x1 ENTONCES BORRAR DE TODAS LAS VALDOSAS ESA MAQUINA
        if (valdosaEliminada.tienePareja) {
          this.valdosas.forEach(function (val) {
            if (val.tienePareja && valdosaEliminada.pareja == val.pareja) {

              //LE QUITAMOS A LA MAQUINA EL ESTADO DE estaEnPlanta
              var maquina = this.maquinas.find(x => x.idMaquina == val.idObjeto);
              if (maquina != undefined) maquina.estaEnPlanta = false;

              val.esObjeto = false;
              val.idObjeto = null;
              val.idTipoMaquina = null;
              val.idTop = null;
              val.nomaq = null;
              val.tienePareja = null;
              val.pareja = null;
              val.src = null;
              val.tileSeleccionado = null;
              val.carretera = null;
              val.vistaPrevia = false;
            }
          }, this);
        }
        //SI ES UN SEGUNDO PISO ELIMINAMOS DEL TODO LA VALDOSA, PARA ESO ITERAMOS LA LISTA ALREVES
        var index = this.valdosasExtra.length - 1;
        while (index >= 0) {
          if (valdosaEliminada.idObjeto == this.valdosasExtra[index].idObjeto) {
            this.valdosasExtra.splice(index, 1);
          }
          index -= 1;
        }
      }
    }

    this.anadirParedes();
    this.calcularIndices();
    this.ensennarEliminarObjeto();
    this.ajustarZoom();

  }

  onChangeAncho(e) {

    var anchoAnterior = this.ancho;
    var anchoActual = e;
    this.ancho = anchoActual;

    if (anchoActual > anchoAnterior) { //SE HA INCREMENTADO EL ANCHO
      var i;
      for (i = 0; i < this.alto; i++) {
        this.valdosas.splice((anchoAnterior * (i + 1) + i), 0, { tilenum: -1, esObjeto: false, pared: 0, tileSeleccionado: false });
      }
    }

    if (anchoActual < anchoAnterior) { //SE HA DISMINUIDO EL ANCHO
      var i;
      for (i = 0; i < this.alto; i++) {
        var valdosasEliminada = this.valdosas.splice((anchoAnterior - 1) * (i + 1), 1); //DEVUELVE LA VALDOSA ELIMINADA EN UN ARRAY
        var valdosaEliminada = valdosasEliminada[0];
        //SI LA VALDOSA ELIMINADA TIENE UNA MAQUINA QUE OCUPA MAS DE 1x1 ENTONCES BORRAR DE TODAS LAS VALDOSAS ESA MAQUINA
        if (valdosaEliminada.tienePareja) {
          this.valdosas.forEach(function (val) {
            if (val.tienePareja && valdosaEliminada.pareja == val.pareja) {

              //LE QUITAMOS A LA MAQUINA EL ESTADO DE estaEnPlanta
              var maquina = this.maquinas.find(x => x.idMaquina == val.idObjeto);
              if (maquina != undefined) maquina.estaEnPlanta = false;

              val.esObjeto = false;
              val.idObjeto = null;
              val.idTipoObjeto = null;
              val.idTipoMaquina = null;
              val.nomaq = null;
              val.tienePareja = null;
              val.pareja = null;
              val.src = null;
              val.tileSeleccionado = null;
              val.carretera = null;
              val.vistaPrevia = false;
            }
          }, this);
        }
        //SI ES UN SEGUNDO PISO ELIMINAMOS DEL TODO LA VALDOSA, PARA ESO ITERAMOS LA LISTA ALREVES
        var index = this.valdosasExtra.length - 1;
        while (index >= 0) {
          if (valdosaEliminada.idObjeto == this.valdosasExtra[index].idObjeto) {
            this.valdosasExtra.splice(index, 1);
          }
          index -= 1;
        }
      }
    }

    //COLOCAMOS BIEN TODOS LOS TILENUM
    if (anchoActual > anchoAnterior || anchoActual < anchoAnterior) {
      this.valdosas.forEach(function (val, index) {
        val.tilenum = index + 1;
      }, this);
    }

    this.anadirParedes();
    this.calcularIndices();
    this.ensennarEliminarObjeto();
    this.ajustarZoom();

  }

  ensennarEliminarObjeto() { //DEPENDIENDO DE SI HAY ALGUN OBJETO SELECCIONADO ENSEÑA EL BOTON DE ELIMINAR OBJETO O NO

    this.ensennarBotonEliminarObjeto = false;

    this.valdosas.forEach(function (val) {
      if (val.tileSeleccionado) {
        this.ensennarBotonEliminarObjeto = true;
      }
    }, this);

  }

  onClickEliminarObjeto() { //ELIMINAR OBJETO SELECCIONADO EN EL PLANO

    var objeto: any;

    this.valdosas.forEach(function (val) {
      if (val.tileSeleccionado) {
        objeto = val;
      }
    }, this);

    //LE QUITAMOS A LA MAQUINA EL ESTADO DE estaEnPlanta
    var maquina = this.maquinas.find(x => x.idMaquina == objeto.idObjeto);
    if (maquina != undefined) maquina.estaEnPlanta = false;

    this.eliminarObjeto(objeto);

  }

  onClickEliminarObjetoConcreto(maquina, event) { //ELIMINAR OBJETO DESDE MENU LATERAL

    var objeto: any;

    this.valdosas.forEach(function (val) {
      if (val.idObjeto == maquina.idMaquina) {
        objeto = val;
      }
    }, this);

    //LE QUITAMOS A LA MAQUINA EL ESTADO DE estaEnPlanta
    maquina.estaEnPlanta = false;

    this.eliminarObjeto(objeto);

  }

  eliminarObjeto(objeto) {

    //ELIMINAMOS EL OBJETO
    if (objeto != undefined) {

      //SI ES UN SEGUNDO PISO ELIMINAMOS DEL TODO LA VALDOSA, PARA ESO ITERAMOS LA LISTA ALREVES
      var index = this.valdosasExtra.length - 1;
      while (index >= 0) {
        if (objeto.idObjeto == this.valdosasExtra[index].idObjeto) {
          this.valdosasExtra.splice(index, 1);
        }
        index -= 1;
      }

      //SI EL OBJETO ELIMINADO ES UNA MAQUINA QUE OCUPA MAS DE 1x1 ENTONCES BORRAR DE TODAS LAS VALDOSAS ESA MAQUINA
      if (objeto.tienePareja) {
        this.valdosas.forEach(function (val) {
          if (val.tienePareja && objeto.pareja == val.pareja) {
            val.esObjeto = false;
            val.idObjeto = null;
            val.idTipoObjeto = null;
            val.idTipoMaquina = null;
            val.nomaq = null;
            val.tienePareja = null;
            val.pareja = null;
            val.src = null;
            val.tileSeleccionado = null;
            val.carretera = null;
            val.vistaPrevia = false;
          }
        }, this);
      }

      objeto.esObjeto = false;
      objeto.idObjeto = null;
      objeto.idTipoObjeto = null;
      objeto.idTipoMaquina = null;
      objeto.nomaq = null;
      objeto.tienePareja = null;
      objeto.pareja = null;
      objeto.src = null;
      objeto.tileSeleccionado = null;
      objeto.carretera = null;
      objeto.vistaPrevia = false;

      //DESELECCIONAR TODOS LOS OBJETOS DEL MENU
      this.maquinas.forEach(function (maq) { maq.seleccionada = false; }, this);
      this.carreteras.forEach(function (car) { car.seleccionada = false; }, this);
      this.paredes.forEach(function (par) { par.seleccionada = false; }, this);
      this.otros.forEach(function (otr) { otr.seleccionada = false; }, this);

    }

    this.ensennarEliminarObjeto();

  }


  onClickGuardar() {

    if (this.alto > 30 || this.ancho > 30 || this.alto < 5 || this.ancho < 5) return;

    //CREAMOS LA DATATABLE QUE LE PASAREMOS A LA API
    var dt: any = [];
    var idPlanta = parseInt(this.idPlanta);

    this.valdosas.forEach(function (val) {

      dt.push({
        idPlanta: idPlanta,
        tilenum: val.tilenum.toString(),
        esObjeto: val.esObjeto,
        idObjeto: val.idObjeto,
        idTipoObjeto: val.idTipoObjeto,
        idTipoMaquina: val.idTipoMaquina,
        nomaq: val.nomaq,
        clase: val.clase,
        src: val.src,
        tienePareja: val.tienePareja,
        pareja: val.pareja,
        pared: val.pared,
        carretera: val.carretera,
        tileSeleccionado: false,
        esSegundoPiso: false,
        posTop: null,
        posRight: null
      });

    }, this);

    this.valdosasExtra.forEach(function (val) {

      dt.push({
        idPlanta: idPlanta,
        tilenum: val.tilenum,
        esObjeto: val.esObjeto,
        idObjeto: val.idObjeto,
        idTipoObjeto: val.idTipoObjeto,
        idTipoMaquina: val.idTipoMaquina,
        nomaq: val.nomaq,
        clase: val.clase,
        src: val.src,
        tienePareja: val.tienePareja,
        pareja: val.pareja,
        pared: 0,
        carretera: "",
        tileSeleccionado: false,
        esSegundoPiso: true,
        posTop: val.posTop,
        posRight: val.posRight
      });

    }, this);


    this.guardando = 1;

    //BORRAR TODOS LAS LINEAS DE LA BASE DE DATOS Y LUEGO INSERTAR LAS NUEVAS
    this.plantaIsometricoService.Delete_Disenno(this.idPlanta).subscribe(result => {

      //DESPUES DE BORRAR GUARDAR LA NUEVA CONFIGURACION Y TAMBIEN INSERTAR LAS NUEVAS VALDOSAS
      var idAreaProductiva, idSeccion = undefined;
      if (this.itemSeleccionadoAreasSeccionesTree != undefined) {
        //ITEM SELECCIONADO ES AREA PRODUCTIVA
        if (this.itemSeleccionadoAreasSeccionesTree.idAp != undefined) {
          idAreaProductiva = this.itemSeleccionadoAreasSeccionesTree.idAp;
        }
        //ITEM SELECCIONADO ES SECCION
        if (this.itemSeleccionadoAreasSeccionesTree.idS != undefined) {
          idSeccion = this.itemSeleccionadoAreasSeccionesTree.idS;
        }
      }

      this.plantaIsometricoService.Set_Dimensiones(this.alto, this.ancho, this.idPlanta, this.nombrePlanta, idAreaProductiva, idSeccion).subscribe(result => {

        //ACTUALIZAR LA LISTA DE PLANTAS
        this.plantaIsometricoService.Get_Plantas().subscribe((result) => {
          this.plantaIsometricoService.Set_ListaPlantas_Model(result);
          this.appComponent.listaPlantas = result;
        });

      });

      //INSERTAMOS VALDOSAS
      this.plantaIsometricoService.Insert_Disenno(dt).subscribe(result => {
        if (result) this.guardando = 2; //GUARDADO
        else this.guardando = 3; //ERROR
      });

    });

  }

  onClickCancelar() {
    this.router.navigate(["plantasisometrico"]);
  }

  cerrarPopUp() {
    this.guardando = 0;
  }

  crearIsometrico() {

    //enumerar tiles
    $('.tile').each(function (i, c) {
      $(c).attr('tilenum', i + 1);
    });

    var i = 1.0;

    //MENU DISEÑADOR
    $(".selector-grupo-maquinas").addClass("d-block");
    $(".selector-grupo-caminos").removeClass("d-block");
    $(".selector-grupo-paredes").removeClass("d-block");
    $(".selector-grupo-otros").removeClass("d-block");

    $('body').on('click', '.selector-categoria-maquinas', function () {
      $(".selector-grupo-maquinas").addClass("d-block");
      $(".selector-grupo-caminos").removeClass("d-block");
      $(".selector-grupo-paredes").removeClass("d-block");
      $(".selector-grupo-otros").removeClass("d-block");
    });

    $('body').on('click', '.selector-categoria-caminos', function () {
      $(".selector-grupo-caminos").addClass("d-block");
      $(".selector-grupo-maquinas").removeClass("d-block");
      $(".selector-grupo-paredes").removeClass("d-block");
      $(".selector-grupo-otros").removeClass("d-block");
    });

    $('body').on('click', '.selector-categoria-paredes', function () {
      $(".selector-grupo-paredes").addClass("d-block");
      $(".selector-grupo-maquinas").removeClass("d-block");
      $(".selector-grupo-caminos").removeClass("d-block");
      $(".selector-grupo-otros").removeClass("d-block");
    });

    $('body').on('click', '.selector-categoria-otros', function () {
      $(".selector-grupo-otros").addClass("d-block");
      $(".selector-grupo-caminos").removeClass("d-block");
      $(".selector-grupo-paredes").removeClass("d-block");
      $(".selector-grupo-maquinas").removeClass("d-block");
    });

    //DESPLAZAR MAPA
    $(".planta").draggable();

    //ZOOM
    $('.planta').on('mousewheel', 'div', function (e) {
      if (e.originalEvent.wheelDelta / 120 > 0) {
        i = i + 0.1;
      }
      else {
        i = i - 0.1;
      }
      if (i < 0.5)
        i = 0.5
      if (i > 3)
        i = 3

      $(".planta").css('transform', 'scale(' + i + ')');
    });

    //ZOOM INICIAL (DEPENDIENDO DE ANCHO Y ALTO)
    var dimMax = Math.max(this.alto, this.ancho);
    var dif = Math.abs(this.alto - this.ancho);
    if (dimMax > 10 && dimMax <= 15) {
      $(".planta").css('transform', 'scale(0.85)');
      //$(".isometrico").css('top', dif * -3 + '%');
    }
    if (dimMax > 15 && dimMax <= 20) {
      $(".planta").css('transform', 'scale(0.7)');
      //$(".isometrico").css('top', dif * -3 + '%');
    }
    if (dimMax > 20 && dimMax <= 30) {
      $(".planta").css('transform', 'scale(0.6)');
      //$(".isometrico").css('top', dif * -3 + '%');
    }

    //NO PERMITIR SCROLL
    var main = document.getElementById("master");
    main.style.overflowY = "hidden";
    main.style.overflowX = "hidden";

  }

  allowScroll() {
    if (this.router.url.toString() != "/dise%C3%B1adorplantaisometrico") {
      var main = document.getElementById("master");
      main.style.overflowY = "auto";
      main.style.overflowX = "auto";
    }
  }

  ajustarZoom() {

  }

}
