import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, InformeOeeService, MenuService, InformeProyectosService, MaquinasService, OperariosService } from '@app/_services';
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

@Component({
  selector: 'app-informedisponibilidadoee-data',
  templateUrl: 'informeDisponibilidadOEE.html'
})

export class InformeDisponibilidadOEEComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  form: FormGroup;
  id: string;
  isAddMode: boolean;

  public loading1Finished = false;
  public loading2Finished = false;
  public loading3Finished = false;
  public loading4Finished = false;

  submitted = false;
  user = this.userService.userValue;

  private dataGraficoDonutDisponibilidad: any;
  private dataGraficoHoras: any;
  private dataGraficoDonutParadas: any;
  private dataGraficoDonutMantenimientos: any;
  private dataGraficoDonutAlarmas: any;

  private graficoDonutDisponibilidad: any;
  private graficoHoras: any;
  private graficoDonutParadas: any;
  private graficoDonutMantenimientos: any;
  private graficoDonutAlarmas: any;

  public maquinas: any = [];
  public maquinasJ: any;
  public maquinasSeleccionadas: string[] = [];
  public datosJ: any;

  public dpFechaIni: Date;
  public dpFechaFin: Date;
  public AnnoMesSemanaDia: number;

  public agrupado: number = 0; // 0 perdidas, 1 padres, 2 grupos


  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeccion: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  // GRID PERDIDAS
  public datosPerdidasGlobal: any = [];
  public datosPerdidas: any = [];

  public usuarioIdDbCAF: any;

  // DISPLAYS (estaban metidos con get element by id...)
  public checkboxGruposVisible: boolean = false;
  public lblNoParadas_DispVisible: boolean = true;

  // TURNO
  private turno: number = -1;

  // CALENDARIO
  public mostrarCalendario1: boolean = false;
  public hoy = new Date();
  //public dpFechaIni: Date = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate() - 7);
  //public dpFechaFin: Date = this.hoy;
  public DaysInMonths = this.hoy;
  public listaOperarios: any = [];
  public operariosSeleccionados: any = [];
  public seleccionarTodo: boolean = false;
  public listaOperariosSTR: string = "-";
  public listaMaquinasAux: any = [];
  public listaOperariosAux: any = [];

  constructor(
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private informeOeeService: InformeOeeService,
    private informeProyectosService: InformeProyectosService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private operariosService: OperariosService
  ) {
    this.usuarioIdDbCAF = userService.userValue.idDb == 7 ? true : false;
  }

  ngOnInit() {
    this.loading1Finished = true;
    this.loading2Finished = true;
    this.loading3Finished = true;
    this.loading4Finished = true;

    this.menuService.titulo = this.translateService.instant('tituloDisponibilidad');

    this.dpFechaIni = this.informeOeeService.get_fechaIni_model();
    this.dpFechaFin = this.informeOeeService.get_fechaFin_model();
    this.AnnoMesSemanaDia = this.informeOeeService.get_AnnoMesSemanaDia_model();
    if (this.dpFechaIni === undefined) {
      // this.dpFechaIni = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000));//7 egun atzera
      // this.dpFechaFin = this.myFunctions.getDateNow()
      // this.dpFechaIni.setHours(0, 0, 0);
      // this.dpFechaFin.setHours(0, 0, 0);
      this.cargarFechas();
      this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
      this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
      this.AnnoMesSemanaDia = 4;
      this.informeOeeService.set_AnnoMesSemanaDia_model(this.AnnoMesSemanaDia);
    }

    this.cargarGrupos();
    this.cargarAreasProductivas();

    var bukatua = new Promise<void>((resolve, reject) => {

      var maquinas_model = this.informeOeeService.get_maquinas_model();
      var maquinasSeleccionadas_model = this.informeOeeService.get_maquinasSeleccionadas_model();
      var seccionesSeleccionadas_model = this.informeOeeService.get_seccionesSeleccionadas_model();
      var maquinasCompletas_model = this.informeOeeService.get_maquinasCompletas_model();
      var gruposSeleccionados_model = this.informeOeeService.get_gruposSeleccionados_model();

      if (maquinas_model == false) {
        this.informeOeeService.get_maquinas().subscribe(
          json => {
            this.maquinasJ = json;
            this.informeOeeService.set_maquinasCompletas_model(this.maquinasJ); //Necesitamos tenerlo al filtrar, pero lo guardamos en el servicio para no estar llamándolo siempre
            this.maquinasSeleccionadas.push(this.maquinasJ[0].id);
            this.cargarMaquinas();
            resolve();
          }
        )
      } else {
        this.maquinas = maquinas_model;
        this.listaMaquinasAux = maquinas_model;
        this.maquinasSeleccionadas = maquinasSeleccionadas_model;
        this.seccionesSeleccionadas = seccionesSeleccionadas_model;
        this.maquinasJ = maquinasCompletas_model;
        this.gruposSeleccionados = gruposSeleccionados_model;
        this.cargarMaquinas2();
        resolve();
      }
      this.operariosService.getAllUsuariosConColor().subscribe(json => {
        var lag: any = json;
        lag.forEach(element => {
          this.listaOperarios.push(element);
          this.listaOperariosAux.push(element);
          //this.operariosSeleccionados.push(element.idUsuario);
        });
        this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
      });
    });

    bukatua.then(() => {
      this.cargarDonuts();
      this.cargarHoras();
      this.cargarDonutsLeyenda();
      this.cargarGraficos();
    });

  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.dpFechaIni == undefined) {
      this.dpFechaIni = this.myFunctions.getDateNow()
    }
    fechaIni = this.dpFechaIni
    if (fechaIni.getDay() == 3) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.dpFechaIni);
    this.dpFechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {
    this.maquinasService.getGruposMaquinas().subscribe(
      json => {
        this.grupos = json.data;
        this.gruposSeccion = this.grupos;
        this.gruposCargados = true;
        
        var aurk = false;
        this.gruposSeccion = [];
        this.grupos.forEach(
          grupo => {
            aurk = false;
            this.seccionesSeleccionadas.forEach(seccion => {
              if ((grupo.idSeccion == seccion.id) && !aurk) {
                aurk = true;
                this.gruposSeccion.push(grupo);
              }
            });
          });
    
      });
  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

    }

  }

  public checkboxChange(tipo: number): void { //función para saber si las pérdidas están agrupadas por grupos

    this.agrupado = tipo;

    this.cargarPerdidas();

  }

  cargarMaquinas() {
    this.maquinasService.get().subscribe(json => {
      this.maquinas = [];
      this.listaMaquinasAux = [];
      var primeraMaquina = true;
      this.maquinasService.set_maquinas_model(json);
      var maquinas1: any;
      maquinas1 = this.maquinasService.get_maquinas_model();

      for (let maquina of this.maquinasJ) {
        maquinas1.forEach(element => {
          if (element.id == maquina.id) {
            var m = { id: maquina.id, nombre: maquina.nombre, imgBase64: element.imagenBase64 };
            this.maquinas.push(m);
            this.listaMaquinasAux.push(m);

            if (primeraMaquina) {
              this.maquinasSeleccionadas.push(maquina.id);
              primeraMaquina = false;
            }
          }
        });
      }

      this.informeOeeService.set_maquinas_model(this.maquinas);
      this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
      this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
      this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
    });
  }
  cargarMaquinas2() {
    var listaMaquinas: any;
    //MAQUINAS
 
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        listaMaquinas = mostra;
        var maquinasAux = [];

        var seccionesSeleccionadas = [];
        this.seccionesSeleccionadas?.forEach(elem => seccionesSeleccionadas.push(elem.id));
        var gruposSeleccionados = [];
        this.gruposSeleccionados?.forEach(elem => gruposSeleccionados.push(elem.id));
        if (this.maquinasJ) {
          for (let maquina of this.maquinasJ) {
            var aux = listaMaquinas.filter(f => f.idMaquina === maquina.id)[0];
            if ((this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) || (this.gruposSeleccionados && this.gruposSeleccionados.length > 0)) {
              if (seccionesSeleccionadas.includes(aux?.idSeccion) || aux?.idsGrupos.filter(r => gruposSeleccionados.includes(Number(r))).length > 0) {
                var m = { id: maquina.id, nombre: maquina.nombre };
                maquinasAux.push(m);
              }

            } else {
              var m = { id: maquina.id, nombre: maquina.nombre };
              maquinasAux.push(m);
            }
            this.maquinas = maquinasAux;
            this.listaMaquinasAux = maquinasAux;

          }
          this.maquinasSeleccionadas.forEach(elem => {
            var aux = this.maquinas.filter(e => e.id === elem);
            if (aux.length === 0)
              this.maquinasSeleccionadas.splice(this.maquinasSeleccionadas.indexOf(elem), 1);
          });
          if (this.maquinasSeleccionadas.length === 0) {
            this.maquinasSeleccionadas.push(this.maquinas[0].id);
          }
        }

        this.informeOeeService.set_maquinas_model(this.maquinas);
        this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
        this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
        this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
      });
  }

  cargarDonuts() {
    this.dataGraficoDonutDisponibilidad = [['completo', 0], ['nocompleto', 100]];

    //GRAFICO DONUT DISPO
    this.graficoDonutDisponibilidad = c3.generate({
      bindto: '#graficoDonutDisponibilidad_Disp',
      data: {
        columns: this.dataGraficoDonutDisponibilidad,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 22,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#graficoDonutDisponibilidad_Disp .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#graficoDonutDisponibilidad_Disp .c3-chart-arcs path").style("stroke-width", "0px");

  }

  cargarHoras() {
    this.dataGraficoHoras = [[this.translateService.instant('total'), 0, 0, 0, 0],
    [this.translateService.instant('rendimiento'), 0, 0, 0, 0],
    [this.translateService.instant('paradas'), 0, 0, 0, 0],
    [this.translateService.instant('mantenimientos'), 0, 0, 0, 0],
    [this.translateService.instant('alarmas'), 0, 0, 0, 0],
    [this.translateService.instant('apagadas'), 0, 0, 0, 0],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, 0]];

    //GRAFICO HORAS BARRAS
    this.graficoHoras = c3.generate({
      data: {
        columns: this.dataGraficoHoras,
        order: null,
        type: 'bar',
        groups: [[this.translateService.instant('total'), this.translateService.instant('paradas'), this.translateService.instant('mantenimientos'),
        this.translateService.instant('alarmas'), this.translateService.instant('apagadas'), this.translateService.instant('rendimiento'), this.translateService.instant('perdidasCalidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#f99f4d'], ['#ebe078'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ffa8a8']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translateService.instant('tiempoTotal'), this.translateService.instant('disponibilidad'),
          this.translateService.instant('rendimiento'), this.translateService.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 0
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      onrendered: function () {
        d3.selectAll("#graficoHoras_Disp .c3-bar")
          .style("opacity", function (d) {
            if (d.index === 1) {
              return 1;
            } else {
              return 0.4
            }
          });
      },
      bindto: '#graficoHoras_Disp'
    });
  }

  cargarDonutsLeyenda() {

    this.dataGraficoDonutParadas = [];
    this.dataGraficoDonutMantenimientos = [];
    this.dataGraficoDonutAlarmas = [];

    //GRAFICO DONUT PARADAS
    var that = this;
    this.graficoDonutParadas = c3.generate({
      data: {
        columns: this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#donutLeyendaParadas_Disp .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#donutLeyendaParadas_Disp .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#donutLeyendaParadas_Disp .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right',
        item: {
          onclick: function (d) {
            //Primero miramos si esta oculto o mostrandose en el chart
            var auxiString = 'c3-legend-item-' + d;
            auxiString = auxiString.split(' ').join('-'); //Asi quitamos todos los espacios y los reemplazamos por '-', ya que no deja usar replaceAll
            var donutLeyenda = document.getElementById("donutLeyendaParadas_Disp").children[0].children[2];
            var nodos = donutLeyenda.getElementsByClassName("c3-legend-item");
            var nodo = undefined;
            for (let i = 0; i < nodos.length; i++) {
              const element = nodos[i];
              if (element.classList.contains(auxiString)) {
                nodo = element;
                break;
              }
            }
            if (nodo.classList.contains("c3-legend-item-hidden"))
              that.graficoDonutParadas.show(d);
            else
              that.graficoDonutParadas.hide(d);
            //Filtrar los datos que no tengan el hide en el grid
            //Primero hay que ver cuales hay que ocultar
            var elementos = donutLeyenda.getElementsByClassName("c3-legend-item-hidden");
            var listaElementosAQuitar = [];
            for (let i = 0; i < elementos.length; i++) {
              var element = elementos[i];
              //Borramos las clases que no nos interesan
              element.classList.remove("c3-legend-item");
              element.classList.remove("c3-legend-item-hidden");
              element.classList.remove("c3-legend-item-focused");
              //Ahora solo deberia quedar la clase que nos interesa para extraer el nombre
              if (element.classList.length > 0) {
                var idElemento = element.classList[0].split("c3-legend-item-")[1];
                if (idElemento.includes('.')) {
                  idElemento = idElemento.substring(0, idElemento.length - 3); //Para quitar los tres puntitos
                }
                idElemento = idElemento.split('-').join(' '); //Volvemos a ponerle los espacios
                listaElementosAQuitar.push(idElemento);
              }
              //Y ahora los volvemos a agregar para que no le cambien los estilos
              element.classList.add("c3-legend-item");
              element.classList.add("c3-legend-item-hidden");
              element.classList.add("c3-legend-item-focused");
            }
            //Ahora filtramos
            if (listaElementosAQuitar.length > 0) {
              switch (that.agrupado) {
                case 0:
                  that.datosPerdidas = that.datosPerdidasGlobal.filter((x) => {
                    var incluye = false;
                    for (let i = 0; i < listaElementosAQuitar.length; i++) {
                      if (x.perdida.includes(listaElementosAQuitar[i])) {
                        incluye = true;
                      }
                    }
                    if (!incluye) return x;
                    else return;
                  });
                  break;
                case 1:
                  that.datosPerdidas = that.datosPerdidasGlobal.filter((x) => {
                    var incluye = false;
                    for (let i = 0; i < listaElementosAQuitar.length; i++) {
                      if (x.perdidaPadre.includes(listaElementosAQuitar[i])) {
                        incluye = true;
                      }
                    }
                    if (!incluye) return x;
                    else return;
                  });
                  break;
                case 2:
                  that.datosPerdidas = that.datosPerdidasGlobal.filter((x) => {
                    var incluye = false;
                    for (let i = 0; i < listaElementosAQuitar.length; i++) {
                      if (x.grupoPerdida.includes(listaElementosAQuitar[i])) {
                        incluye = true;
                      }
                    }
                    if (!incluye) return x;
                    else return;
                  });
                  break;
              }
            }
            else {
              that.datosPerdidas = that.datosPerdidasGlobal;
            }
          }
        }
      },
      tooltip: {
        show: false
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      bindto: '#donutLeyendaParadas_Disp'
    });

    //GRAFICO DONUT MANTENIMIENTOS
    this.graficoDonutMantenimientos = c3.generate({
      data: {
        columns: this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#donutLeyendaMantenimientos_Disp .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#donutLeyendaMantenimientos_Disp .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#donutLeyendaMantenimientos_Disp .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right'
      },
      tooltip: {
        show: false
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      bindto: '#donutLeyendaMantenimientos_Disp'
    });

    //GRAFICO DONUT ALARMAS
    this.graficoDonutAlarmas = c3.generate({
      data: {
        columns: this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#donutLeyendaAlarmas_Disp .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#donutLeyendaAlarmas_Disp .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#donutLeyendaAlarmas_Disp .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right'
      },
      tooltip: {
        show: false
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      bindto: '#donutLeyendaAlarmas_Disp'
    });

    d3.selectAll("#donutLeyendaParadas_Disp .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#donutLeyendaMantenimientos_Disp .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#donutLeyendaAlarmas_Disp .c3-chart-arcs path").style("stroke-width", "0px");

  }

  /* FILTRO */
  btnAnno_Click(event) {
    this.AnnoMesSemanaDia = 1;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setFullYear(this.myFunctions.getDateNow().getFullYear() - 10));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnMes_Click(event) {
    this.AnnoMesSemanaDia = 2;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setMonth(this.myFunctions.getDateNow().getMonth() - 12));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnSemana_Click(event) {
    this.AnnoMesSemanaDia = 3;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setMonth(this.myFunctions.getDateNow().getMonth() - 3));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnDias_Click(event) {
    this.AnnoMesSemanaDia = 4;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), 1);
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnTodos_Click(event) {
    this.turno = -1;
  }
  btnManana_Click(event) {
    this.turno = 1;
  }
  btnTarde_Click(event) {
    this.turno = 2;
  }
  btnNoche_Click(event) {
    this.turno = 3;
  }
  /* BTN FILTRAR */
  public cargarDatosInforme() {

    this.loading1Finished = true;
    this.loading2Finished = true;
    this.loading3Finished = true;
    this.loading4Finished = true;

    if (this.maquinasSeleccionadas.length === 0) {
      this.maquinasSeleccionadas.push(this.maquinasJ[0].id);
    }

    this.cargarGraficos();
    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
    this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
    this.informeOeeService.set_AnnoMesSemanaDia_model(this.AnnoMesSemanaDia);
  }


  public onChangeF(value: Date): void {
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);

    if (this.dpFechaIni > this.dpFechaFin) {
      var f = this.dateCopy(this.dpFechaFin);
      this.dpFechaFin = this.dateCopy(this.dpFechaIni);
      this.dpFechaIni = f;
    }

    //this.cargarGraficos();
  }

  cargarGraficos() {

    this.cargarGraficoBarrasYDonuts();
    this.cargarPerdidas();

  }

  cargarGraficoBarrasYDonuts() {

    var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaIni);
    this.dpFechaFin.setHours(23, 59, 59, 0);
    var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.dpFechaFin.getTime()));

    this.listaOperariosSTR = "-";
    this.operariosSeleccionados.forEach((operario, i) => {
      if (i == 0 && this.operariosSeleccionados.length > 1) {
        this.listaOperariosSTR = operario
      } else if (this.operariosSeleccionados.length == 1) {
        this.listaOperariosSTR = operario
      } else {
        this.listaOperariosSTR = this.listaOperariosSTR + "," + operario
      }
    });

    this.informeOeeService.Get_Oee_Of_Cliente_Pieza(fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno.toString(), this.listaOperariosSTR).subscribe(json => {

      var data: any = json;

      var tEjecucion = 0;
      var tParadaDisponibilidad = 0;
      var tMantenimientoDisponibilidad = 0;
      var tAlarmaDisponibilidad = 0;
      var tApagadaDisponibilidad = 0;
      var tParadaRendimiento = 0;
      var tMantenimientoRendimiento = 0;
      var tAlarmaRendimiento = 0;
      var tApagadaRendimiento = 0;
      var tParadaCalidad = 0;
      var tMantenimientoCalidad = 0;
      var tAlarmaCalidad = 0;
      var tApagadaCalidad = 0;
      var tNegativoCalidad = 0;
      var tEstimado = 0;

      data.forEach(
        function (a) {
          tEjecucion += a.tEjecucion;
          tParadaDisponibilidad += a.tParadaDisponibilidad;
          tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
          tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
          tApagadaDisponibilidad += a.tApagadaDisponibilidad;
          tParadaRendimiento += a.tParadaRendimiento;
          tMantenimientoRendimiento += a.tMantenimientoRendimiento;
          tAlarmaRendimiento += a.tAlarmaRendimiento;
          tApagadaRendimiento += a.tApagadaRendimiento;
          tParadaCalidad += a.tParadaCalidad;
          tMantenimientoCalidad += a.tMantenimientoCalidad;
          tAlarmaCalidad += a.tAlarmaCalidad;
          tApagadaCalidad += a.tApagadaCalidad;
          tNegativoCalidad += a.tNegativoCalidad;
          tEstimado += a.tEstimado;
        });

      //CALCULAR DONUTS
      var oee: number = 0;
      var cal: number = 0;
      var ren: number = 0;
      var dis: number = 0;

      var perdidasDisponibilidad = tParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
      var perdidasRendimiento = tParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;
      var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

      var disDividendo: number = tEjecucion + perdidasRendimiento;
      var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      var renDividendo: number = tEstimado;
      var renDivisor: number = tEjecucion + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      var calDividendo: number = tEstimado - tNegativoCalidad - perdidasCalidad;
      var calDivisor: number = tEstimado;
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);

      d3.select('#graficoDonutDisponibilidad_Disp .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "30px").style("opacity", "1")
        .text(Math.round(dis) + "%");

      if (oee > 100) oee = 100;
      if (dis > 100) dis = 100;
      if (ren > 100) ren = 100;
      if (cal > 100) cal = 100;

      this.dataGraficoDonutDisponibilidad = [['completo', dis], ['nocompleto', 100 - dis]];
      this.graficoDonutDisponibilidad.load({ columns: this.dataGraficoDonutDisponibilidad });

      //CALCULAR BARRAS
      var tRendimiento: number = 0;
      if (tEstimado > tEjecucion) {
        tRendimiento = 0;
        tEstimado = tEjecucion;
      } else {
        tRendimiento = tEjecucion - tEstimado;
      }

      this.dataGraficoHoras = [[this.translateService.instant('total'), tRendimiento + tEstimado + perdidasRendimiento + perdidasDisponibilidad, tRendimiento + tEstimado + perdidasRendimiento, tEstimado, tEstimado - tNegativoCalidad - perdidasCalidad],
      [this.translateService.instant('rendimiento'), 0, 0, tRendimiento, 0],
      [this.translateService.instant('paradas'), 0, tParadaDisponibilidad, tParadaRendimiento, tParadaCalidad],
      [this.translateService.instant('mantenimientos'), 0, tMantenimientoDisponibilidad, tMantenimientoRendimiento, tMantenimientoCalidad],
      [this.translateService.instant('alarmas'), 0, tAlarmaDisponibilidad, tAlarmaRendimiento, tAlarmaCalidad],
      [this.translateService.instant('apagadas'), 0, tApagadaDisponibilidad, tApagadaRendimiento, tApagadaCalidad],
      [this.translateService.instant('perdidasCalidad'), 0, 0, 0, tNegativoCalidad]];

      this.graficoHoras.load({ columns: this.dataGraficoHoras });

      this.loading1Finished = false;
    });
    var a = this.maquinasSeleccionadas.toString()
    this.informeOeeService.Get_Perdidas_Disp(fini, ffin, a, this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(
      (json: any) => {

        var an = [];
        var row: any;

        var dict: any = {};
        //Tenemos las imagenes, creamos el diccionario
        json.imagenes.forEach(
          element => {
            dict[element.imagen] = element.imagenBASE64;
          });

        json.data.forEach(
          element => {
            //Primero procesamos los operarios
            if (element.operario != null) {
              var operarios = element.operario.split(",");
              var operariosAuxi = [];
              var operariosAuxi2 = [];
              operarios.forEach(operario => {
                var nombre;
                var apellido;
                if (operario == this.translateService.instant("desconocido")) {
                  operariosAuxi.push("undefined");
                  operariosAuxi2.push("undefined");
                } else {
                  if (operario.split(';').length > 0) {
                    nombre = operario.split(';')[0];
                    apellido = operario.split(';')[1];
                    if (apellido != undefined)
                      operariosAuxi2.push(nombre + " " + apellido);
                    else
                      operariosAuxi2.push(nombre);
                    if (nombre != undefined)
                      nombre = nombre.trim().substring(0, 1).toUpperCase();
                    if (apellido != undefined)
                      apellido = apellido.trim().substring(0, 1).toUpperCase();
                    operariosAuxi.push(nombre + apellido);
                  }
                }
              });
              element.operariosAuxi2 = operariosAuxi2.join(";");
              element.operariosAuxi = operariosAuxi.join(",");
            } else {
              element.operariosAuxi2 = "";
              element.operariosAuxi = "";
            }
            //Ahora seguimos con las maquinas
            if (element.maquina == "") {
              element.maquinas = this.translateService.instant('desconocido');
            }
            if (element.maquina != null) {
              var maquinas = element.maquina.split(",");
              var maquinasAuxi = [];
              var maquinasAuxi2 = [];
              maquinas.forEach(maquina => {
                if (maquina == this.translateService.instant("desconocido")) {
                  maquinasAuxi2.push("undefined");
                  maquinasAuxi.push("undefined");
                } else {
                  var nombre = maquina.trim().substring(0, 1).toUpperCase();
                  var apellido = maquina.trim().substring(1, 2).toUpperCase();
                  maquinasAuxi2.push(maquina);
                  maquinasAuxi.push(nombre + apellido);
                }
              });
              element.maquinasAuxi2 = maquinasAuxi2.join(";");
              element.maquinasAuxi = maquinasAuxi.join(",");
              //Ahora hay que corregir las imagenes de las maquinas
              var imagenes = element.maquinaIm.split(';and;');
              var auxiImagenes = "";
              imagenes.forEach(imagen => {
                auxiImagenes += dict[imagen] + ";and;";
              });
              element.maquinaIm = auxiImagenes;
            } else {
              element.maquinasAuxi2 = "";
              element.maquinasAuxi = "";
              element.maquinasIm = "";
            }

            //Ahora el resto de procesamientos
            if (row == undefined) { //si no existe una linea que llevamos a rastras, la creamos
              row = this.myFunctions.copy(element);
            } else if (element.fechafin == row.fechaini &&
              element.maquina == row.maquina &&
              element.operario == row.operario &&
              element.lote == row.lote &&
              element.nSerie == row.nSerie &&
              element.colada == row.colada &&
              element.observacion == row.observacion &&
              element.perdida == row.perdida) { //si la linea se continua, le alargamos la fecha fin
              row.fechaini = this.myFunctions.copy(element.fechaini);
            } else {//insertamos la linea actual que llevamos y la que estamos leyendo la dejamos como la actual         
              row.horasReales = (new Date(row.fechafin).getTime() - new Date(row.fechaini).getTime()) / 1000;
              an.push(this.myFunctions.copy(row));
              row = this.myFunctions.copy(element);
            }
          });

        if (json.data.length > 0)
          an.push(this.myFunctions.copy(row));

        //an = json.data;

        this.datosPerdidasGlobal = an;
        this.datosPerdidas = an; //Al inicio los datos no estan filtrados

        this.loading2Finished = false;
      });
  }

  cargarPerdidas() {
    this.datosPerdidas = this.datosPerdidasGlobal;
    var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaIni);
    this.dpFechaFin.setHours(23, 59, 59, 0);
    var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaFin);

    this.listaOperariosSTR = "-";
    this.operariosSeleccionados.forEach((operario, i) => {
      if (i == 0 && this.operariosSeleccionados.length > 1) {
        this.listaOperariosSTR = operario
      } else if (this.operariosSeleccionados.length == 1) {
        this.listaOperariosSTR = operario
      } else {
        this.listaOperariosSTR = this.listaOperariosSTR + "," + operario
      }
    });

    if (this.agrupado == 2) { //si las pérdidas están agrupadas por grupos tomar los datos de estas agrupadas por grupos
      this.informeOeeService.Get_Perdidas_PorGrupo_tipoPerdida(1, fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.translateService.instant("sinGrupo"), this.turno, this.listaOperariosSTR).subscribe(data => {

        document.getElementById('lblNoParadas_Disp').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        this.lblNoParadas_DispVisible = true;
        this.checkboxGruposVisible = false;
        document.getElementById('donutLeyendaParadas_Disp').style.display = 'none';

        var dataPerdidas: any = data;

        var donutDataParadas: any = [];

        dataPerdidas.forEach(function (obj) {
          //PARADAS (idProcesos_Tipo = 1, 2)
          if (obj.tiempoPerdida > 0) {
            donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoParadas_Disp').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            this.lblNoParadas_DispVisible = false;
            this.checkboxGruposVisible = true;
            document.getElementById('donutLeyendaParadas_Disp').style.display = 'block';
          }
        }, this);

        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }))

        d3.selectAll("#donutLeyendaParadas_Disp .c3-chart-arcs path").style("stroke-width", "0px");

        this.loading3Finished = false;
      });
    }
    else if (this.agrupado == 1) {
      this.informeOeeService.Get_Perdidas_PorPadres_tipoPerdida(1, fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(data => {

        document.getElementById('lblNoParadas_Disp').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        this.lblNoParadas_DispVisible = true;
        this.checkboxGruposVisible = false;
        document.getElementById('donutLeyendaParadas_Disp').style.display = 'none';


        var dataPerdidas: any = data;

        var donutDataParadas: any = [];


        dataPerdidas.forEach(function (obj) {
          //PARADAS (idProcesos_Tipo = 1, 2)
          if (obj.tiempoPerdida > 0) {
            donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoParadas_Disp').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            this.lblNoParadas_DispVisible = false;
            this.checkboxGruposVisible = true;
            document.getElementById('donutLeyendaParadas_Disp').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }))

        d3.selectAll("#donutLeyendaParadas_Disp .c3-chart-arcs path").style("stroke-width", "0px");

        this.loading3Finished = false;
      });
    }

    this.informeOeeService.Get_Perdidas_Disponibilidad(fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(data => {
      if (this.agrupado == 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
        document.getElementById('lblNoParadas_Disp').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        this.lblNoParadas_DispVisible = true;
        this.checkboxGruposVisible = false;
        document.getElementById('donutLeyendaParadas_Disp').style.display = 'none';
        var donutDataParadas: any = [];

        this.loading3Finished = false;
      }

      document.getElementById('lblNoMantenimientos_Disp').style.display = 'block';
      document.getElementById('lblNoAlarmas_Disp').style.display = 'block';

      document.getElementById('donutLeyendaMantenimientos_Disp').style.display = 'none';
      document.getElementById('donutLeyendaAlarmas_Disp').style.display = 'none';

      var dataPerdidas: any = data;

      var donutDataMantenimientos: any = [];
      var donutDataAlarmas: any = [];

      dataPerdidas.forEach(function (obj) {
        //PARADAS (idProcesos_Tipo = 1, 2)
        if ((obj.idProcesos_Tipo == 1 || obj.idProcesos_Tipo == 2) && obj.tiempoPerdida > 0 && this.agrupado == 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
          donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoParadas_Disp').style.display = 'none';
          document.getElementById('checkboxGrupos').style.display = 'block';
          this.lblNoParadas_DispVisible = false;
          this.checkboxGruposVisible = true;
          document.getElementById('donutLeyendaParadas_Disp').style.display = 'block';
        }

        //MANTENIMIENTOS (idProcesos_Tipo = 4, 10)
        if ((obj.idProcesos_Tipo == 4 || obj.idProcesos_Tipo == 10) && obj.tiempoPerdida > 0) {
          donutDataMantenimientos.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoMantenimientos_Disp').style.display = 'none';
          document.getElementById('donutLeyendaMantenimientos_Disp').style.display = 'block';
        }

        //ALARMAS (idProcesos_Tipo = 6, 7)
        if ((obj.idProcesos_Tipo == 6 || obj.idProcesos_Tipo == 7) && obj.tiempoPerdida > 0) {
          donutDataAlarmas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoAlarmas_Disp').style.display = 'none';
          document.getElementById('donutLeyendaAlarmas_Disp').style.display = 'block';
        }

      }, this);

      if (this.agrupado == 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);
        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));
        d3.selectAll("#donutLeyendaParadas_Disp .c3-chart-arcs path").style("stroke-width", "0px");
      }

      var oldDataGraficoDonutMantenimientos = this.dataGraficoDonutMantenimientos;
      this.dataGraficoDonutMantenimientos = donutDataMantenimientos.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutMantenimientos = this.acortarDatosGraficoDonuts(this.dataGraficoDonutMantenimientos);

      var oldDataGraficoDonutAlarmas = this.dataGraficoDonutAlarmas;
      this.dataGraficoDonutAlarmas = donutDataAlarmas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutAlarmas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutAlarmas);


      this.updateDonutLeyenda(this.graficoDonutMantenimientos, oldDataGraficoDonutMantenimientos, this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }));
      this.updateDonutLeyenda(this.graficoDonutAlarmas, oldDataGraficoDonutAlarmas, this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }));

      d3.selectAll("#donutLeyendaMantenimientos_Disp .c3-chart-arcs path").style("stroke-width", "0px");
      d3.selectAll("#donutLeyendaAlarmas_Disp .c3-chart-arcs path").style("stroke-width", "0px");



      this.loading4Finished = false;
    });

  }

  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnseñar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnseñar.push(['Otros', totalOtros]);
      return arrayParaEnseñar;
    }
  }

  public updateDonutLeyenda(grafico, oldData, newData) {
    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      grafico.show(value);
      var nombre = "c3-legend-item-" + value
      nombre = nombre.split(' ').join('-'); //Asi quitamos todos los espacios y los reemplazamos por '-', ya que no deja usar replaceAll
      var elemento: any = document.getElementsByClassName(nombre)[0];
      elemento.classList.remove("c3-legend-item-hidden");
      elemento.style.opacity = "1";
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }
    var names = {};
    newData.forEach(function (a) {
      names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
    }, this);

    grafico.load({
      columns: newData,
      names: names
    });
    grafico.unload({
      ids: nombresAEliminar
    });
  }

  public secondsTo_HH_MM_SS(segundos) {
    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }

  public cortarLeyenda(inputText, trimmed = false) {

    var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var width = context.measureText(inputText).width;

    if (width > 140) {
      return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
    } else if (trimmed) {
      return inputText + "...";
    } else {
      return inputText;
    }

  }

  // GRID PERDIDAS
  cellClick(event: CellClickEvent) {
    var row = event.dataItem;
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.dpFechaIni); //FORMATO: YYYY_MM_DD
    this.dpFechaFin.setHours(23, 59, 59, 0);
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.dpFechaFin); //FORMATO: YYYY_MM_DD
    //this.router.navigate(['analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + row.idCliente + '/' + row.idPieza + '/' + row.idOF]);

    window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/0/' + row.idPieza + '/' + row.idOF + '/0/0/0', '_blank');
  }

  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  showCalendar() {
    if (!this.mostrarCalendario1) {
      this.mostrarCalendario1 = true;
      console.log("TRUE");
    }
    else {
      this.mostrarCalendario1 = false;
      console.log("FALSE");
      if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
        this.dpFechaFin = this.dpFechaIni;
      }
      else if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
        this.dpFechaIni = this.myFunctions.getDateNow();
        this.dpFechaFin = this.dpFechaIni;
      }

    }
    // this.mostrarCalendario1 = true; // activa el click de fondo!
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.dpFechaIni != undefined && this.dpFechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.dpFechaFin = this.DaysInMonths[month];
        this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
          this.dpFechaIni = this.DaysInMonths[month];
        }
        else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
          this.dpFechaFin = this.DaysInMonths[month];
          if (this.dpFechaIni > this.dpFechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.dpFechaIni.getTime());
            this.dpFechaIni = new Date(this.dpFechaFin.getTime());
            this.dpFechaFin = aux;
          }
          this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.dpFechaIni = this.DaysInMonths[month];
          this.dpFechaFin = undefined;
        }
      }
    }
  }

  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
      return false;
    } else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
      return date.getFullYear() == this.dpFechaIni.getFullYear() && date.getMonth() == this.dpFechaIni.getMonth() && date.getDate() == this.dpFechaIni.getDate();
    } else if (this.dpFechaIni != undefined && this.dpFechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.dpFechaIni.getFullYear(), this.dpFechaIni.getMonth(), this.dpFechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.dpFechaFin.getFullYear(), this.dpFechaFin.getMonth(), this.dpFechaFin.getDate());
    }
  }

  insertMaquinasSelected(id, e) {
    var aurk = false;
    this.maquinasSeleccionadas.forEach((maquina, index) => {
      if (maquina == id) {
        this.maquinasSeleccionadas.splice(index, 1);
        aurk = true;
      }
    });
    if (!aurk) {
      this.maquinasSeleccionadas.push(id);
    }
    console.log(this.maquinasSeleccionadas);
  }

  selectAllUsuarios() {
    if (this.operariosSeleccionados.length == this.listaOperarios.length) {
      this.seleccionarTodo = true;
      this.operariosSeleccionados = [];
    } else {
      this.seleccionarTodo = false;
      this.operariosSeleccionados = [];
      this.listaOperarios.forEach(operario => {
        this.operariosSeleccionados.push(operario.idUsuario);
      });
    }
    this.operariosSeleccionados;
  }

  filtroMaquinas(e: any) {
    this.listaMaquinasAux = [];
    if (e == '') {
      this.listaMaquinasAux = this.maquinas;
    } else {
      this.maquinas.forEach(maquina => {
        if (maquina.nombre.toLowerCase().includes(e.toLowerCase())) {
          this.listaMaquinasAux.push(maquina);
        }
      });
    }
  }

  filtroOperarios(e: any) {
    this.listaOperariosAux = [];
    if (e == '') {
      this.listaOperariosAux = this.listaOperarios;
    } else {
      this.listaOperarios.forEach(operario => {
        if (operario.nombreCompleto.toLowerCase().includes(e.toLowerCase())) {
          this.listaOperariosAux.push(operario);
        }
      });
    }
    this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
  }

}
