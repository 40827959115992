<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <!--LINEA 1-->
    <div class="row">
        <!--COLUMNA 1-->
        <div class="col">
            <div class="row">
                <!--DATOS-->
                <div class="col-md-6">
                <div class="col-md-12">
                    <div class="card">
                    <div class="card-header">
                        <h3>
                        <label ID="lCaracteristicas" CssClass="">{{ 'datos' | translate }}</label>
                        </h3>
                        <div class="plegarpanel"></div>
                    </div>
                    <div class="card-body">
                        <!--Maquina y abreviatura, Num maquina, orden, num serie-->
                        <div class="row">
                            <div class="form-group col-md-5">
                            <kendo-label text="{{ 'maquina' | translate }}">
                                <kendo-textbox formControlName="nombre"></kendo-textbox>
                            </kendo-label>
                            </div>
                            <div class="form-group col-md">
                            <kendo-label text="{{ 'abreviatura' | translate }}">
                                <kendo-textbox formControlName="abreviatura" maxlength="3" required [ngClass]="{ 'is-invalid': submitted && f.abreviatura.errors }"></kendo-textbox>
                            </kendo-label>
                            </div>
                            <div class="col-md">
                            <!--Numero Maquina-->
                            <div class="form-group">
                                <kendo-label text="{{ 'numMaquina' | translate }}">
                                <kendo-textbox formControlName="numeroModelo"></kendo-textbox>
                                </kendo-label>
                            </div>
                            </div>
                            <div class="col-md">
                            <!--Orden Maquina-->
                            <div class="form-group">
                                <kendo-label text="{{ 'orden' | translate }}">
                                <kendo-textbox formControlName="ordenModelo"></kendo-textbox>
                                </kendo-label>
                            </div>
                            </div>
                            <div class="col-md">
                            <!--Numero Serie-->
                            <div class="form-group">
                                <kendo-label text="{{ 'numSerie' | translate }}">
                                <kendo-textbox formControlName="numSerie"></kendo-textbox>
                                </kendo-label>
                            </div>
                            </div>
                        </div>
        
                        <!--Marca, control, activo-->
                        <div class="row">
                        <div class="col-md-5">
                            <!--Marca-->
                            <div class="form-group">
                            <label class="control-label">{{ 'marca' | translate }}</label>
                            <div class="caja">
                                <kendo-dropdownlist [data]="marcas" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="marcas_selectionChange($event)" [value]="marca_selectedItem" class="form-control">
                                </kendo-dropdownlist>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <!--Control-->
                            <div class="form-group">
                            <label class="control-label">{{ 'control' | translate }}</label>
                            <div class="caja">
                                <kendo-dropdownlist [data]="controles" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="controles_selectionChange($event)" [value]="control_selectedItem" class="form-control">
                                </kendo-dropdownlist>
                            </div>
                            </div>
                        </div>
        
                        <div class="col-md-2">
                            <!--Activo-->
                            <kendo-label text="{{ 'activo' | translate}}">
                            <br />
                            <kendo-switch formControlName="activo" [onLabel]="' '" [offLabel]="' '" id="activoId"></kendo-switch>
                            </kendo-label>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div> 
                <!--CONEXION-->
                <div class="col-md-6">
                    <div class="card">
                    <div class="card-header">
                        <h3>
                        <label ID="lCaracteristicas" CssClass="">{{ 'conexion' | translate }}</label>
                        </h3>
                        <div class="plegarpanel"></div>
                    </div>
                    <div class="card-body">
                        <!--Maquina/Puesto Offline-->
                        <div class="row">
                        <div class="col-md-12">
                            <kendo-switch class="switch-offline kendo-switch-tipo2"
                            formControlName="maquinaPuestoOffline" [onLabel]="translateService.instant('maquinaPuestoOffline')"
                            [offLabel]="translateService.instant('maquinaPuestoOffline')"
                            id="maquinaPuestoOfflineId"></kendo-switch>
                        </div>
                        </div>
        
                        <!--IP, Puerto-->
                        <div class="row">
                        <!--IP-->
                        <div class="col-md-9">
                            <div class="form-group">
                            <kendo-label text="{{ 'ip' | translate }}">
                                <kendo-textbox formControlName="ip"></kendo-textbox>
                            </kendo-label>
                            </div>
                        </div>
        
                        <!--Puerto-->
                        <div class="col-md-3">
                            <div class="form-group">
                            <kendo-label text="{{ 'puerto' | translate }}">
                                <kendo-textbox formControlName="puerto"></kendo-textbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
        
                        <!--Timezone, t sincr-->
                        <div class="row">
                        <div class="col-md-9">
                            <!--Timezone-->
                            <div class="form-group">
                            <label class="control-label">{{ 'Timezone' | translate }}</label>
                            <div class="caja">
                                <kendo-dropdownlist [disabled]="false" [(data)]="timeZonesList" [textField]="'timeZoneSQL'"
                                class="form-control" [valueField]="'id'" formControlName="idTimezone"
                                [ngClass]="{ 'invalid-dropdown': submitted && f.idTimezone.errors }">
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <span *ngIf="dataItem!=undefined" style="vertical-align: text-top; margin-left: 5px;">{{
                                    dataItem.timeZoneSQL }} ({{ dataItem.desvioUTC }})</span>
                                </ng-template>
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    <span>
                                    <span class="k-icon k-i-clock"></span>
                                    <span *ngIf="dataItem!=undefined" style="vertical-align: text-top; margin-left: 5px;">{{
                                        dataItem.timeZoneSQL }} ({{ dataItem.desvioUTC }})</span>
                                    </span>
                                </ng-template>
                                </kendo-dropdownlist>
                            </div>
                            </div>
                        </div>
        
                        <div class="col-md-3">
                            <!--Tiempo sincr. (seg)-->
                            <div class="form-group">
                            <kendo-label text="{{ 'tiempoSincrSeg' | translate }}">
                                <kendo-textbox formControlName="tiempoSincrSeg"></kendo-textbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
        
                        <!--URL camara-->
                        <div class="row">
                        <div class="col-md-12">
                            <!--URL camara-->
                            <div class="form-group">
                            <kendo-label text="{{ 'urlCamara' | translate }}">
                                <kendo-textbox formControlName="urlCamara"></kendo-textbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>                
            </div>

            <div class="row">
                <div class="col">
                    <!--OPCIONES-->
                    <div class="clearfix horno-conf-opciones-cont">

                        <div class="card">
                        <div class="card-header">
                            <h3>
                            <label ID="lCaracteristicas" CssClass="">{{ 'opciones' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">
                            <div class="clearfix">
                            <!--T microparada-->
                            <div class="float-left">
                                <!--tiempo microparada-->
                                <kendo-label text="{{ 'tiempoMicroParada' | translate}}">
                                <kendo-numerictextbox class="form-control" formControlName="tiempoMicroParada" [min]="0" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                </kendo-label>
                            </div>
                            <!--T microejecucion-->
                            <div class="float-left">
                                <!--tiempo microejecucion-->
                                <kendo-label text="{{ 'tiempoMicroEjecucion' | translate}}">
                                <kendo-numerictextbox class="form-control" formControlName="tiempoMicroEjecucion" [min]="0" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                </kendo-label>
                            </div>
                            <!--Frec. captura de datos-->
                            <div class="float-left">
                                <div class="form-group">
                                <label>{{ 'frecCapturaDatos' | translate }}</label>
                                <kendo-numerictextbox formControlName="freq_captura_datos" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                    </div>
                    <!--CARACTERISTICAS-->
                    <div class="clearfix">

                        <div class="card maquinas-conf-caracteristicas-conf">
                        <div class="card-header">
                            <h3>
                            <label ID="lCaracteristicas" CssClass="">{{ 'caracteristicas' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                            <!--Seccion-->
                            <div class="col-md">
                                <div class="form-group">
                                <kendo-label text="{{ 'seccion' | translate }}">
                                    <kendo-dropdownlist [(data)]="groupedSeccion" [textField]="'nombre'" class="form-control" [valueField]="'id'"
                                                        formControlName="idSeccion" [ngClass]="{ 'invalid-dropdown': submitted && f.idSeccion.errors }"></kendo-dropdownlist>
                                </kendo-label>
                                </div>
                            </div>
                            <!--Clasificacion-->
                            <div class="col-md">
                                <div class="form-group">
                                <kendo-label text="{{ 'clasificacion' | translate}}">
                                    <br />
                                    <kendo-multiselecttree kendoMultiSelectTreeExpandable
                                                        checkboxes="true"
                                                        [kendoMultiSelectTreeHierarchyBinding]="clasificaciones"
                                                        [checkableSettings]="checkableSettings"
                                                        [textField]="'nombre'"
                                                        [valueField]="'id'"
                                                        [childrenField]="'clasificacionInferior'"
                                                        [(value)]="clasificacionesSeleccionadas"
                                                        placeholder="{{ 'clasificaciones' | translate }}"
                                                        [isNodeExpanded]="isNodeExpanded"
                                                        (valueChange)="valueChange($event, 1)"
                                                        [tagMapper]="tagMapper"
                                                        style="background: white;">
                                    </kendo-multiselecttree>
                                </kendo-label>
                                </div>
                            </div>

                            <!--Atributos-->
                            <div class="col-md">
                                <div class="form-group">
                                <kendo-label text="{{ 'atributo' | translate}}">
                                    <br />
                                    <kendo-multiselecttree kendoMultiSelectTreeExpandable
                                                        checkboxes="true"
                                                        [kendoMultiSelectTreeHierarchyBinding]="atributos"
                                                        [checkableSettings]="checkableSettings"
                                                        [textField]="'nombre'"
                                                        [valueField]="'id'"
                                                        [childrenField]="'atributoInferior'"
                                                        [(value)]="atributosSeleccionadas"
                                                        placeholder="{{ 'atributos' | translate }}"
                                                        [isNodeExpanded]="isNodeExpanded"
                                                        (valueChange)="valueChange($event, 2)"
                                                        [tagMapper]="tagMapper"
                                                        style="background: white;">
                                    </kendo-multiselecttree>
                                </kendo-label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group">
                                <kendo-label text="{{ 'agruparProcesos' | translate}}">
                                    <div class="caja">
                                    <kendo-switch formControlName="agruparProcesos" [onLabel]="' '" [offLabel]="' '"
                                        id="agruparProcesos" (valueChange)="onChangeAgruparProcesos($event)"></kendo-switch>
                                    </div>
                                </kendo-label>
                                </div>
                            </div>
                            </div>
                            <div class="row" *ngIf="agruparProcesosSwitch">
                            
                            <div class="col-md">
                                <div class="form-group">
                                <div class="float-left">
                                    <kendo-label text="Kg min">
                                    <kendo-numerictextbox class="form-control" formControlName="capacidadPesoMin" [min]="0"
                                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group">
                                <div class="float-left">
                                    <kendo-label text="Kg max">
                                    <kendo-numerictextbox class="form-control" formControlName="capacidadPesoMax" [min]="0"
                                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="row" *ngIf="agruparProcesosSwitch">
                            
                            <div class="col-md">
                                <div class="form-group">
                                <div class="float-left">
                                    <!--Maxima del potenciometro-->
                                    <kendo-label text="{{ 'unidadesMin' | translate}}">
                                    <kendo-numerictextbox class="form-control" formControlName="capacidadUnidadesMin" [min]="0"
                                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-group">
                                <div class="float-left">
                                    <!--Maxima del potenciometro-->
                                    <kendo-label text="{{ 'unidadesMax' | translate}}">
                                    <kendo-numerictextbox class="form-control" formControlName="capacidadUnidadesMax" [min]="0"
                                        [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

                    </div>
                    <!-- RITMO -->
                    <div class="clearfix">
                        <div class="card">
                            <div class="card-header">
                                <h3>
                                    <label ID="lCaracteristicas" CssClass="">{{ 'ritmo' | translate }}</label>
                                </h3>
                                <div class="plegarpanel"></div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <!--Activar ritmo-->
                                    <div class="form-group col">
                                        <kendo-switch class="kendo-switch-tipo2" formControlName="activarRitmo" [onLabel]="translateService.instant('activarRitmo')" [offLabel]="translateService.instant('activarRitmo')" id="activarRitmoId"></kendo-switch>
                                    </div>
                                    <!--Ritmo-->
                                    <div class="form-group col" *ngIf="form.value.activarRitmo">
                                        <kendo-label text="{{ 'ritmo' | translate}}">
                                            <kendo-numerictextbox class="form-control" formControlName="ritmo" [min]="0" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- RANGOS ENFRIAMIENTO/CALENTAMIENTO -->
                <div class="clearfix col maquinas-rangos-enfriamiento-calentamiento">
                    <div class="card">
                        <div class="card-header">
                            <h3>
                                <label ID="lCaracteristicas" CssClass="">{{ 'rangosEnfriamientoCalentamiento' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <!--Tiempo min cambio temperatura-->
                                <div class="form-group col">
                                    <kendo-label text="{{ 'tiempoMin' | translate}}">
                                        <kendo-numerictextbox class="form-control" formControlName="tiempoMinCambioTemperatura" [min]="0" [step]="0.1" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
                                    </kendo-label>                              
                                </div>

                                <div class="tabla-rangos-enfriamiento-calentamiento">
                                    <kendo-grid [kendoGridBinding]="gridRangosEnfCal"
                                    kendoGridSelectBy="id"
                                    [selectable]="{ enabled: false }"
                                    kendoGridSelectBy="id"
                                    [rowClass]="rowCallback">
                                        <!-- icono tenperatura -->
                                        <kendo-grid-column field="subida" title="" width="20">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <i class="icon-flechaarriba-alt" *ngIf="dataItem.subida && (dataItem.temperaturaMin - dataItem.temperaturaMax) != 0"></i>
                                                <i class="icon-flechaabajo-alt" *ngIf="!dataItem.subida && (dataItem.temperaturaMin - dataItem.temperaturaMax) != 0"></i>
                                                <i class="icon-menos" *ngIf="(dataItem.temperaturaMin - dataItem.temperaturaMax) == 0"></i>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="temperaturaMin" title="{{ 'tInicial' | translate }}" width="40">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <kendo-numerictextbox class="form-control" [(value)]="dataItem.temperaturaMin" (valueChange)="onChangeTemperatura(dataItem.id)" [min]="0" [step]="1" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="temperaturaMax" title="{{ 'tFinal' | translate }}" width="40">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <kendo-numerictextbox class="form-control" [(value)]="dataItem.temperaturaMax" (valueChange)="onChangeTemperatura(dataItem.id)" [min]="0" [step]="1" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column field="gradosPorMinuto" title="ºC/min" width="40">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <kendo-numerictextbox class="form-control" [(value)]="dataItem.gradosPorMinuto" [step]="0.1" [min]="0" [step]="1" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <!-- icono eliminar -->
                                        <kendo-grid-column field="delete" title="" width="20">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="float-right">
                                                    <div (click)="eliminarRangoTemperatura(dataItem.id)"><i class="icon-equis"></i></div>
                                                </div>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </kendo-grid>
                                    <div class="clearfix">
                                        <div class="agregar-elemento"
                                            (click)="botonAnnadirRangoTemperatura()">
                                            <i class="icon-mas-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--COLUMNA 2-->
        <div class="col">
            <div class="row  imagenes-horno-cont">
            <!--IMAGEN-->
            <div class="col-md-6">
                <div class="card">
                <div class="card-header">
                    <h3>
                    <label ID="lCaracteristicas" CssClass="">{{ 'imagen' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <div class="imagen-maquina">
                    <!--IMAGEN VIEJA-->
                    <div class="clearfix text-center">
                        <img [src]="imageToShow" style="max-height: 113px">
                    </div>
                    <div class="clearfix">
    
                        <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false" (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                        <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                                clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                                dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                                externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                                fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                                fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                                filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                                filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                                filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                                headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                                headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                                headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                                invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                                invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                                invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                                pause="{{ 'pause' | translate }}"
                                                remove="{{ 'remove' | translate }}"
                                                resume="{{ 'resume' | translate }}"
                                                retry="{{ 'retry' | translate }}"
                                                select="{{ 'select' | translate }}"
                                                uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                        </kendo-fileselect>
    
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <!--ISOMETRICO-->
            <div class="col-md-6">
                <div class="card">
                <div class="card-header">
                    <h3>
                    <label ID="lCaracteristicas" CssClass="">{{ 'isometrico' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <div class="imagen-isometrico-maquina" (click)="onClickAbrirPopup()">
                    <div class="clearfix text-center"><img style="max-width: 95px; max-height: 95px;" [src]="selectedDibujoMaquina.imagen"></div>
                    <div class="clearfix text-center"><label style="font-size: 12px;">{{ 'dimensiones' | translate }}: {{selectedDibujoMaquina.dimensiones}}</label></div>
                    <div class="clearfix text-center"><button type="button" class="btn btn-primary">{{ 'cambiarImagenIsometrica' | translate }}</button></div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--CONFIGURACION HMI-->
            <div class="row">
            <div class="col-md-12">
                <div class="card">
                <div class="card-header">
                    <h3>
                    <label ID="lCaracteristicas" CssClass="">{{ 'configuracionHMI' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <div class="configuracion-hmi-mecanizado">
    
                    <!--Inicio-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiInicio" [onLabel]="translateService.instant('inicio')" [offLabel]="translateService.instant('inicio')" id="hmiInicioId"></kendo-switch>
                    </div>
    
                    <!--Manual usuario-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiManualUsuario" [onLabel]="translateService.instant('manualUsuario')" [offLabel]="translateService.instant('manualUsuario')" id="hmiManualUsuarioId"></kendo-switch>    
                    </div>
                    
                    <!--Prog planificados-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiProgPlanificados" [onLabel]="translateService.instant('progPlanificados')" [offLabel]="translateService.instant('progPlanificados')"id="hmiProgPlanificadosId"></kendo-switch>    
                    </div>
    
                    <!--Fuera de taller-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiFueraTaller" [onLabel]="translateService.instant('fueraDeTaller')" [offLabel]="translateService.instant('fueraDeTaller')"id="hmiFueraTallerId"></kendo-switch>    
                    </div>
                    
                    <!--Visualizador de planos-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiVisualizadorDePlanos" [onLabel]="translateService.instant('visializadorDePlanos')" [offLabel]="translateService.instant('visializadorDePlanos')"id="hmiVisualizadorDePlanosId"></kendo-switch>    
                    </div>
                    
                    <!--Alarma-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiAlarma" [onLabel]="translateService.instant('alarma')" [offLabel]="translateService.instant('alarma')"id="hmiAlarmaId"></kendo-switch>    
                    </div>
    
                    <!--Cambio de monitor-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiCambioMonitor" [onLabel]="translateService.instant('cambioDeMonitor')" [offLabel]="translateService.instant('cambioDeMonitor')"id="hmiCambioMonitorId"></kendo-switch>    
                    </div>
                    
                    <!--Librerias-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiLibrerias" [onLabel]="translateService.instant('librerias')" [offLabel]="translateService.instant('librerias')"id="hmiLibreriasId"></kendo-switch>    
                    </div>
                    
                    <!--Mantenimiento-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiMantenimiento" [onLabel]="translateService.instant('mantenimiento')" [offLabel]="translateService.instant('mantenimiento')"id="hmiMantenimientoId"></kendo-switch>    
                    </div>
    
                    <!--Mes-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiMes" [onLabel]="translateService.instant('mes')" [offLabel]="translateService.instant('mes')"id="hmiMesId"></kendo-switch>    
                    </div>
                    
                    <!--Autodiag y compens-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiAutodiagYCompens" [onLabel]="translateService.instant('autodiagYCompens')" [offLabel]="translateService.instant('autodiagYCompens')"id="hmiAutodiagYCompensId"></kendo-switch>    
                    </div>
                    
                    <!--Preparacion-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiPreparacion" [onLabel]="translateService.instant('preparacion')" [offLabel]="translateService.instant('preparacion')"id="hmiPreparacionId"></kendo-switch>    
                    </div>
    
                    <!--Mantenimiento-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiMantenimiento2" [onLabel]="translateService.instant('mantenimiento')" [offLabel]="translateService.instant('mantenimiento')"id="hmiMantenimiento2Id"></kendo-switch>    
                    </div>
                
                    <!--Cambio placa-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiCambioPlaca" [onLabel]="translateService.instant('cambioPlaca')" [offLabel]="translateService.instant('cambioPlaca')"id="hmiCambioPlacaId"></kendo-switch>
                    </div>
                
                    <!--Ejecucion-->
                    <div class="form-group">
                        <kendo-switch class="kendo-switch-tipo2" formControlName="hmiEjecucion" [onLabel]="translateService.instant('ejecucion')" [offLabel]="translateService.instant('ejecucion')"id="hmiEjecucionId"></kendo-switch>    
                    </div>   
                    
                    
                <!--controlRepeticiones-->
                <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="controlRepeticiones"
                      [onLabel]="translateService.instant('controlRepeticiones')" [offLabel]="translateService.instant('controlRepeticiones')"
                      id="controlRepeticionesId"></kendo-switch>
                  </div>
                  <!--asignarOperaciones-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="asignarOperaciones"
                      [onLabel]="translateService.instant('asignarOperaciones')" [offLabel]="translateService.instant('asignarOperaciones')"
                      id="asignarOperacionesId"></kendo-switch>
                  </div>
                  <!--verReportInciden-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="verReportInciden"
                      [onLabel]="translateService.instant('verReportInciden')" [offLabel]="translateService.instant('verReportInciden')"
                      id="verReportIncidenId"></kendo-switch>
                  </div>
                  <!--perdidas-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="perdidas"
                      [onLabel]="translateService.instant('perdidas')" [offLabel]="translateService.instant('perdidas')"
                      id="perdidasId"></kendo-switch>
                  </div>
                  <!--calidad-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="calidad"
                      [onLabel]="translateService.instant('calidad')" [offLabel]="translateService.instant('calidad')"
                      id="calidadId"></kendo-switch>
                  </div>
                  <!--herramientas-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="herramientas"
                      [onLabel]="translateService.instant('herramientas')" [offLabel]="translateService.instant('herramientas')"
                      id="herramientasId"></kendo-switch>
                  </div>
                  <!--cabezal-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="cabezal"
                      [onLabel]="translateService.instant('cabezal')" [offLabel]="translateService.instant('cabezal')"
                      id="cabezalId"></kendo-switch>
                  </div>
                  <!--gestorDocumental-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="gestorDocumental"
                      [onLabel]="translateService.instant('gestorDocumental')" [offLabel]="translateService.instant('gestorDocumental')"
                      id="gestorDocumentalId"></kendo-switch>
                  </div>
                  <!--camara-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="camara"
                      [onLabel]="translateService.instant('camara')" [offLabel]="translateService.instant('camara')"
                      id="camaraId"></kendo-switch>
                  </div>
                  <!--observaciones-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="observaciones"
                      [onLabel]="translateService.instant('observaciones')" [offLabel]="translateService.instant('observaciones')"
                      id="observacionesId"></kendo-switch>
                  </div>
                  <!--escaneoDePegatinas-->
                  <div class="form-group">
                    <kendo-switch class="kendo-switch-tipo2" formControlName="escaneoDePegatinas"
                      [onLabel]="translateService.instant('escaneoDePegatinas')" [offLabel]="translateService.instant('escaneoDePegatinas')"
                      id="escaneoDePegatinasId"></kendo-switch>
                  </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!--HOME SELECCION-->
            <div class="clearfix">

                <div class="card maquinas-conf-caracteristicas-conf">
                    <div class="card-header">
                        <h3>
                            <label ID="lCaracteristicas" CssClass="">{{ 'modulos' | translate }}</label>
                        </h3>
                        <div class="plegarpanel"></div>
                    </div>
                    <div class="card-body">
                    <div class="row">
                        <!--Home modulo izquierda-->
                        <div class="col-md">
                        <div class="form-group">
                            <kendo-label text="{{ 'homeModIzq' | translate }}">
                            <kendo-dropdownlist [(data)]="homeSeleccion" [textField]="'nombre'" class="form-control"
                                [valueField]="'id'" formControlName="homeModIzq">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span *ngIf="![7, 11].includes(dataItem.id)">{{ dataItem.agrupado}} {{dataItem.nombre}}</span>
                                <span *ngIf="[7, 11].includes(dataItem.id)">{{ dataItem.agrupado}}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                        </div>
                        <!--Home modulo derecha-->
                        <div class="col-md">
                        <div class="form-group">
                            <kendo-label text="{{ 'homeModDer' | translate }}">
                            <kendo-dropdownlist [(data)]="homeSeleccion" [textField]="'nombre'" class="form-control"
                                [valueField]="'id'" formControlName="homeModDer">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span *ngIf="![7, 11].includes(dataItem.id)">{{ dataItem.agrupado}} {{dataItem.nombre}}</span>
                                <span *ngIf="[7, 11].includes(dataItem.id)">{{ dataItem.agrupado}}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                        </div>
                    </div>
                    <!--parametros posibles de la home principales-->
                    <div class="row" *ngIf="form.value.homeModIzq.id == 7 || form.value.homeModDer.id == 7">
                        <!--Parametro 1-->
                        <div class="col-md">
                        <div class="form-group">
                            <kendo-label text="{{ 'parametro' | translate }} 1">
                            <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                                [valueField]="'id'" formControlName="homeModParametro1">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                        </div>
                        <!--Parametro 2-->
                        <div class="col-md">
                        <div class="form-group">
                            <kendo-label text="{{ 'parametro' | translate }} 2">
                            <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                                [valueField]="'id'" formControlName="homeModParametro2">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                        </div>
                        <!--Parametro 3-->
                        <div class="col-md">
                        <div class="form-group">
                            <kendo-label text="{{ 'parametro' | translate }} 3">
                            <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                                [valueField]="'id'" formControlName="homeModParametro3">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                        </div>
                        <!--Parametro 4-->
                        <div class="col-md">
                        <div class="form-group">
                            <kendo-label text="{{ 'parametro' | translate }} 4">
                            <kendo-dropdownlist [(data)]="homeSeleccionParametros" [textField]="'nombre'" class="form-control"
                                [valueField]="'id'" formControlName="homeModParametro4">
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span>{{ dataItem.nombre | translate }}</span>
                                </ng-template>
                            </kendo-dropdownlist>
                            </kendo-label>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

            </div> 
        </div>
    </div>

    <!-- VALORES TECNOLOGICOS -->
    <kendo-tabstrip [keepTabContent]="true">

        <!-- VALORES TECNOLOGICOS -->
        <kendo-tabstrip-tab title="{{ 'valoresTecnologicos' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
            <div class="row" style="margin: -5px 0 15px -17px;">

                <!--temperatura programada-->
                <div class="col-md-3" style="padding-right: 0px;">
                <div class="row extrusoras-panel-rangos">
                    <div class="col-md-12 extrusoras-panel-rangos-header">
                    <label class="extrusoras-panel-rangos-header-titulo">{{ 'temperaturaProgramada' | translate}}</label>
                    <kendo-switch formControlName="temperaturaProgramada_activo" (valueChange)="onChangetemperaturaProgramada($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
                    </div>
                    <div class="col-md-12 extrusoras-panel-rangos-body">
                    <div class="row" style="margin-top: 7px;">
                        <div class="col-md-4">
                        <kendo-label text="{{ 'min' | translate}}">
                            <kendo-numerictextbox formControlName="temperaturaProgramada_min" class="form-control" [disabled]="!f.temperaturaProgramada_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                        </kendo-label>
                        </div>
                        <div class="col-md-4">
                        <kendo-label text="{{ 'max' | translate}}">
                            <kendo-numerictextbox formControlName="temperaturaProgramada_max" class="form-control" [disabled]="!f.temperaturaProgramada_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                        </kendo-label>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                <!--temperatura actual-->
                <div class="col-md-3" style="padding-right: 0px;">
                    <div class="row extrusoras-panel-rangos">
                        <div class="col-md-12 extrusoras-panel-rangos-header">
                        <label class="extrusoras-panel-rangos-header-titulo">{{ 'temperaturaActual' | translate}}</label>
                        <kendo-switch formControlName="temperaturaActual_activo" (valueChange)="onChangetemperaturaActual($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
                        </div>
                        <div class="col-md-12 extrusoras-panel-rangos-body">
                        <div class="row" style="margin-top: 7px;">
                            <div class="col-md-4">
                            <kendo-label text="{{ 'min' | translate}}">
                                <kendo-numerictextbox formControlName="temperaturaActual_min" class="form-control" [disabled]="!f.temperaturaActual_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                            <div class="col-md-4">
                            <kendo-label text="{{ 'max' | translate}}">
                                <kendo-numerictextbox formControlName="temperaturaActual_max" class="form-control" [disabled]="!f.temperaturaActual_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <!--temperatura interior-->
                <div class="col-md-3" style="padding-right: 0px;">
                    <div class="row extrusoras-panel-rangos">
                        <div class="col-md-12 extrusoras-panel-rangos-header">
                        <label class="extrusoras-panel-rangos-header-titulo">{{ 'temperaturaInterior' | translate}}</label>
                        <kendo-switch formControlName="temperaturaInterior_activo" (valueChange)="onChangetemperaturaInterior($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
                        </div>
                        <div class="col-md-12 extrusoras-panel-rangos-body">
                        <div class="row" style="margin-top: 7px;">
                            <div class="col-md-4">
                            <kendo-label text="{{ 'min' | translate}}">
                                <kendo-numerictextbox formControlName="temperaturaInterior_min" class="form-control" [disabled]="!f.temperaturaInterior_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                            <div class="col-md-4">
                            <kendo-label text="{{ 'max' | translate}}">
                                <kendo-numerictextbox formControlName="temperaturaInterior_max" class="form-control" [disabled]="!f.temperaturaInterior_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <!--consumo-->
                <div class="col-md-3" style="padding-right: 0px;">
                    <div class="row extrusoras-panel-rangos">
                        <div class="col-md-12 extrusoras-panel-rangos-header">
                        <label class="extrusoras-panel-rangos-header-titulo">{{ 'consumo' | translate}}</label>
                        <kendo-switch formControlName="consumo_activo" (valueChange)="onChangeconsumo($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
                        </div>
                        <div class="col-md-12 extrusoras-panel-rangos-body">
                        <div class="row" style="margin-top: 7px;">
                            <div class="col-md-4">
                            <kendo-label text="{{ 'min' | translate}}">
                                <kendo-numerictextbox formControlName="consumo_min" class="form-control" [disabled]="!f.consumo_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                            <div class="col-md-4">
                            <kendo-label text="{{ 'max' | translate}}">
                                <kendo-numerictextbox formControlName="consumo_max" class="form-control" [disabled]="!f.consumo_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        
                <!--nivelO2-->
                <div class="col-md-3" style="padding-right: 0px;">
                    <div class="row extrusoras-panel-rangos">
                        <div class="col-md-12 extrusoras-panel-rangos-header">
                        <label class="extrusoras-panel-rangos-header-titulo">{{ 'nivelO2' | translate}}</label>
                        <kendo-switch formControlName="nivelO2_activo" (valueChange)="onChangenivelO2($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
                        </div>
                        <div class="col-md-12 extrusoras-panel-rangos-body">
                        <div class="row" style="margin-top: 7px;">
                            <div class="col-md-4">
                            <kendo-label text="{{ 'min' | translate}}">
                                <kendo-numerictextbox formControlName="nivelO2_min" class="form-control" [disabled]="!f.nivelO2_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                            <div class="col-md-4">
                            <kendo-label text="{{ 'max' | translate}}">
                                <kendo-numerictextbox formControlName="nivelO2_max" class="form-control" [disabled]="!f.nivelO2_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <!--potencial carbono-->
                <div class="col-md-3" style="padding-right: 0px;">
                    <div class="row extrusoras-panel-rangos">
                        <div class="col-md-12 extrusoras-panel-rangos-header">
                        <label class="extrusoras-panel-rangos-header-titulo">{{ 'potencialCarbono' | translate}}</label>
                        <kendo-switch formControlName="potencialCarbono_activo" (valueChange)="onChangepotencialCarbono($event)" [onLabel]="' '" [offLabel]="' '" style="float: right; margin-top: 2px;"></kendo-switch>
                        </div>
                        <div class="col-md-12 extrusoras-panel-rangos-body">
                        <div class="row" style="margin-top: 7px;">
                            <div class="col-md-4">
                            <kendo-label text="{{ 'min' | translate}}">
                                <kendo-numerictextbox formControlName="potencialCarbono_min" class="form-control" [disabled]="!f.potencialCarbono_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                            <div class="col-md-4">
                            <kendo-label text="{{ 'max' | translate}}">
                                <kendo-numerictextbox formControlName="potencialCarbono_max" class="form-control" [disabled]="!f.potencialCarbono_activo" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                            </kendo-label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

            </div>
        </ng-template>
        </kendo-tabstrip-tab>

        <!-- SUBESTADOS-->
        <kendo-tabstrip-tab title="{{ 'subestados' | translate}}" [selected]="false">
        <ng-template kendoTabContent>
            <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <app-maquina-secciones-subestados [id]="id"></app-maquina-secciones-subestados>
            </div>
        </ng-template>
        </kendo-tabstrip-tab>

        <!--MANTENIMIENTOS-->
        <kendo-tabstrip-tab title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
            <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <app-maquina-secciones-mantenimientos [id]="id"></app-maquina-secciones-mantenimientos>
            </div>
        </ng-template>
        </kendo-tabstrip-tab>

        <!--MANUALES-->
        <kendo-tabstrip-tab title="{{ 'manuales' | translate}}">
        <ng-template kendoTabContent>
            <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <app-maquina-secciones-manuales [id]="id"></app-maquina-secciones-manuales>
            </div>
        </ng-template>
        </kendo-tabstrip-tab>

        <!--ALARMAS CONTROLADAS-->
        <kendo-tabstrip-tab title="{{ 'alarmasControladas' | translate}}">
        <ng-template kendoTabContent>
            <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <app-maquina-secciones-alarmas-controladas [id]="id"></app-maquina-secciones-alarmas-controladas>
            </div>
        </ng-template>
        </kendo-tabstrip-tab>


    </kendo-tabstrip>

    <div class="form-group" style=" margin-top: 20px;">

        <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">
          {{ 'guardar' | translate}}
        </button>
        <a class="btn btn-danger btn-sm mr-1" (click)="atras()">
          {{ 'cancelar' | translate}}
        </a>
    
    </div>
    
    <ng-template #popupIsometrico let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{ 'isometrico' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
          <div class="cont-filtro-maquinas-areas clearfix">
    
            <!-- DIBUJOS MAQUINAS -->
            <div class="isometricos-popup">
    
              <div *ngFor="let maquina of dibujosMaquinas" (click)="selectedDibujoMaquina = maquina; onClickCerrarPopup()" class="clickable" [ngClass]="{'actual': maquina==selectedDibujoMaquina}" style="padding: 0px; height: 115px;">
                <img style="max-width: 95px; max-height: 95px;" [src]="maquina.imagen">
                <label style="font-size: 12px;">{{ 'dimensiones' | translate }}: {{maquina.dimensiones}}</label>
              </div>
    
            </div>
          </div>
        </div>
        <div class="modal-footer">
        </div>
    </ng-template>
</form>