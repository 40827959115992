import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuService, UsuariosService, ConfiguracionService, AlertService, DiccionarioPerdidasService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { MustMatch, MyFunctions } from '@app/_helpers';
import { TipoPerdida_DAT } from '@app/_models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-configuracionCalidad',
  templateUrl: './configuracionCalidad.component.html'
})

export class ConfiguracionCalidadComponent {

  user = this.userService.userValue;
  form: FormGroup;
  submitted = false;

  public dtConfiguracionCalidad: any = {};
  public tiposControladoresCotasNoCriticas: any = [];
  public tiposControladoresCotasCriticas: any = [];
  ddControladorCotasNoCriticasChanged: boolean = false;
  ddControladorCotasCriticasChanged: boolean = false;

  public tiposObservacionPiezas: any = [];
  ddObservacionPiezasChanged: boolean = false;
  

  horario: any;


  // FRECUENCIAS

  public valores_modal: any;
  mySelection: number[] = [];

  // END FRECUENCIAS
  

  constructor(
    private myFunctions: MyFunctions,
    private formBuilder: FormBuilder, 
    private configuracionService: ConfiguracionService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    public translate: TranslateService,
    public alertService: AlertService) {

      this.valores_modal = [{
        id: 1,
        nombre: this.translate.instant("frecuencia1"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: false
      },
      {
        id: 2,
        nombre: this.translate.instant("frecuencia2"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: true
      },
      {
        id: 3,
        nombre: this.translate.instant("frecuencia3"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: true
      },
      {
        id: 4,
        nombre: this.translate.instant("frecuencia4"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: true
      },
      {
        id: 5,
        nombre: this.translate.instant("frecuencia5"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: true
      },
      {
        id: 6,
        nombre: this.translate.instant("resto"), 
        cantidad: 100,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: false
      },
      {
        id: 7,
        nombre: this.translate.instant("cambioCritico"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: false
      }];

  }

  ngOnInit() {

    this.menuService.titulo = this.translate.instant('configuracionCalidad').toUpperCase();

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      codigo: [0,],
      resetearFrecuencia: -1,
      idControlarCotasNoCriticas: 2,
      idControlarCotasCriticas: 2,
      idObservacionPiezas: 2,
      primerasPiezasNum: -1,
      inputarSoloDecimales: false,
      mostrarValoresNoOKOperario: false
    }, {});

    this.cargarCombos();

    this.cargarFrecuencias();

    //this.cargarDatos();

  }
  cargarCombos() {
  this.configuracionService.get_controlarCotas_DAT().subscribe((result: any) => {
    result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
    this.tiposControladoresCotasCriticas = result;
    this.tiposControladoresCotasNoCriticas = result.filter(x=>x.nombre!=this.translate.instant('soloCritica'));
    
    this.configuracionService.get_observacionPiezas_DAT().subscribe((result: any) => {
      result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
      this.tiposObservacionPiezas = result;

     this.cargarDatos();

    });
  });

}

  cargarDatos() {
      this.configuracionService.get_configuracion_calidad().subscribe(result => {
        this.dtConfiguracionCalidad = result[0];

        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          codigo: this.dtConfiguracionCalidad.codigo,
          resetearFrecuencia: this.dtConfiguracionCalidad.resetearFrecuencia,
          idControlarCotasNoCriticas: this.dtConfiguracionCalidad.idControlarCotasNoCriticas,
          idControlarCotasCriticas: this.dtConfiguracionCalidad.idControlarCotasCriticas,
          idObservacionPiezas: this.dtConfiguracionCalidad.idObservacionPiezas,
          primerasPiezasNum: this.dtConfiguracionCalidad.primerasPiezasNum,
          inputarSoloDecimales: this.dtConfiguracionCalidad.inputarSoloDecimales,
          mostrarValoresNoOKOperario: this.dtConfiguracionCalidad.mostrarValoresNoOKOperario
        }, {});
      });


  }


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    this.submitted = true;

    if (this.ddControladorCotasNoCriticasChanged) this.form.value.idControlarCotasNoCriticas= this.form.value.idControlarCotasNoCriticas.id;
    if (this.ddControladorCotasCriticasChanged) this.form.value.idControlarCotasCriticas= this.form.value.idControlarCotasCriticas.id;
    if (this.ddObservacionPiezasChanged) this.form.value.idObservacionPiezas= this.form.value.idObservacionPiezas.id;

    this.ddControladorCotasNoCriticasChanged=false;
    this.ddControladorCotasCriticasChanged=false;
    this.ddObservacionPiezasChanged=false;

    if (this.form.invalid) return;

    console.log(this.form)

    this.configuracionService.update_configuracion_calidad(this.form).subscribe(result => {

      this.configuracionService.update_configuracion_calidad_frecuencias(this.valores_modal).subscribe(
        json => {
          var r: any = result;
          this.alertService.success(this.translate.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.cargarDatos();
        });
    
      });

    console.log("valoresFrecuencia", this.valores_modal);

  }

  public idControlarCotasNoCriticas_selectionChange(value: any): void {
    this.ddControladorCotasNoCriticasChanged = true;
  }

  public idControlarCotasCriticas_selectionChange(value: any): void {
    this.ddControladorCotasCriticasChanged = true;
  }

  public idObservacionPiezas_selectionChange(value: any): void {
    this.ddObservacionPiezasChanged = true;
  }

  // frecuencias
  public recalcularFracciones(numElem, dataItem,  e, idElemento)
  {
    var valor = e.target.value;
    switch (parseInt(numElem)) {
      case 1:
        // guardar los porcentajes
        this.valores_modal.filter(x => x.id==idElemento)[0].porcen1=valor;
        this.valores_modal.filter(x => x.id==idElemento)[0].porcen3=Number(Number(valor)*100/Number(dataItem.porcen2));
        break;
      case 2:
        // guardar los porcentajes
        if (Number(valor) == 0) {
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen2=1;
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen3=Number(Number(dataItem.porcen1)*100/1);
        } else {
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen2=valor;
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen3=Number(Number(dataItem.porcen1)*100/Number(valor));
        }
        break;
      case 3:
        // guardar los porcentajes
        let pc1 = Number(valor);
        let pc2= 100;
        if (pc1 == 0) {
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen1=0;
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen2=1;
        } else {
          let mcd = this.EuclidesMCD(pc1, pc2);
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen1=pc1/mcd;
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen2=pc2/mcd;
          this.valores_modal.filter(x => x.id==idElemento)[0].porcen3=valor;
        }
        break;
    
      default:
        break;
    }

    // al cambiar el porcentaje si la cantidad es 0 automaticamente se convierte en 1
    if (dataItem.cantidad == 0) {
      this.valores_modal.filter(x => x.id==idElemento)[0].cantidad = 1;
    }
  }

  public EuclidesMCD(a, b) {
    var iaux; //auxiliar
    a = Math.abs(a); //tomamos valor absoluto
    b = Math.abs(b);
    var i1 = Math.max(a, b); //i1 = el m�s grande
    var i2 = Math.min(a, b); //i2 = el m�s peque�o
  
    do {
      iaux = i2; //guardar divisor
      i2 = i1 % i2; //resto pasa a divisor
      i1 = iaux; //divisor pasa a dividendo
    } while (i2 !== 0);
    return i1; //ultimo resto no nulo
  }

  cargarFrecuencias() {
    this.configuracionService.get_configuracion_calidad_frecuencias().subscribe(
      json => {
        console.log("configuracionFrecuencias", json);
        var data: any = json;
        data.forEach(element => {
          element.nombre = this.translate.instant(element.nombre);
        });

        this.valores_modal = data;
       })
  }

  cantidadChange(dataItem, event) {
    // nota: esto se hace de esta manera porque sino con el kendo-numerictextbox no funciona adecuadamente al cambiar con la misma cantidad

    // actualizar la cantidad de dataItem
    this.valores_modal[dataItem.id - 1].cantidad = parseInt(event.target.value);
    
    // si la cantidad de una frecuencia cambia a 0 entonces ponerle el valor de la siguiente frecuencia
    if (this.valores_modal[dataItem.id - 1].cantidad == 0 && dataItem.id < 5) {
      
      // actualizar la fila actual con los valores de la siguiente
      var nombre = "frecuencia" + dataItem.id;
      this.valores_modal[dataItem.id - 1] = this.myFunctions.copy(this.valores_modal[dataItem.id]);
      this.valores_modal[dataItem.id - 1].id = dataItem.id;
      this.valores_modal[dataItem.id - 1].nombre = this.translate.instant(nombre);
      
      // actualizar el valor del type='number' del html
      event.target.value = this.valores_modal[dataItem.id].cantidad;

      // actualizar el valor de la siguiente linea a 0 para que en la siguiente llamada recursiva entre en esta condicion
      this.valores_modal[dataItem.id].cantidad = 0;
      let auxEventValue = event.target.value;
      event.target.value = 0;
      this.cantidadChange(this.valores_modal[dataItem.id], event);
      event.target.value = auxEventValue;
            
    }
    else if(this.valores_modal[dataItem.id - 1].cantidad == 0 && dataItem.id == 5) {
      this.valores_modal[dataItem.id - 1] = 
      {
        id: 5,
        nombre: this.translate.instant("frecuencia5"), 
        cantidad: 0,
        porcen1: 1, 
        porcen2: 1,
        porcen3: 100,
        desactivado: true
      };
    }

    // activar o desactivar la siguiente frecuencia
    if (dataItem.cantidad != undefined && dataItem.cantidad != "" && dataItem.cantidad > 0 && dataItem.id != 7 && dataItem.id != 6) {
      this.valores_modal[dataItem.id].desactivado = false;
    }

    // calcular el row de resto
    var cantidadTotal = 100;
    if (dataItem.nombre != this.translate.instant("resto") && dataItem.nombre != this.translate.instant("cambioCritico")) {
      var cantidadFrecuencias = 0;
      this.valores_modal.forEach(element => {
        if (element.nombre != this.translate.instant("resto") && element.nombre != this.translate.instant("cambioCritico")
          && element.cantidad != undefined) {
          cantidadFrecuencias += element.cantidad;
        }
      });
      var cantidadesResto = cantidadTotal - cantidadFrecuencias;
      if (cantidadesResto < 0)
        cantidadesResto = 0;
      this.valores_modal.filter(x => x.nombre == this.translate.instant("resto"))[0].cantidad = cantidadesResto;
    }
  }

  // comprobar que todos los inputs de frecuencias de calidad son mayores a 0
  isPositive(event, isPorcen2) {
    if (event.target.value < 0) {
      event.target.value = 1;
    }

    if (isPorcen2) {
      if (event.target.value == 0) {
        event.target.value = 1;
      }
    }
  }

  // end frecuencias

}
