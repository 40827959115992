import { Injectable } from '@angular/core';
import { UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import * as moment from 'moment-timezone';
import { MyFunctions } from './myfunctions';
@Injectable()
export class MyFilter {
  user = this.userService.userValue;

  private filtroCompartido = {};

  constructor(private userService: UsuariosService,
              private translateService: TranslateService,
              private myFunctions: MyFunctions) {
  }

  public set_filtroCompartido(_filtroCompartido){
    this.filtroCompartido = _filtroCompartido;
  }
  public get_filtroCompartido(){
    return this.filtroCompartido;
  }

  public parse_filtro(jsonStr, nuevas_columnasFiltro){  
    // las antiguas columnas y las nuevas se uniran por el texto de la columna... puesto que en diferentes ventanas se han usado diferentes ids para las mismas columnas o diferentes fields.  
    var filtro = JSON.parse(jsonStr);
    return this.filtroStringToFiltro(filtro, nuevas_columnasFiltro);
  }
  private filtroStringToFiltro(jFiltro, nuevas_columnasFiltro) {
    var em = this.filtroStringToFiltro_recursivo(jFiltro, nuevas_columnasFiltro);
    return em;
  }
  private filtroStringToFiltro_recursivo(jFiltro, nuevas_columnasFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var group = [];
      var logic = jFiltro.logic;
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroStringToFiltro_recursivo(linea, nuevas_columnasFiltro);
          if (newRow) 
            group.push(newRow) 
        }); 
      if (group.length > 0)
       return {group: group, logic: logic};
    }
    else if (jFiltro.columna != undefined) {     
      if(jFiltro.columna.id > 0){
        // LINEA
        var columnaEncintrada = false;
        nuevas_columnasFiltro.forEach(
          columna => {
            if(columna.nombre == jFiltro.columna.nombre){
              jFiltro.columna = columna; 
              columnaEncintrada = true;
            }         
        });       
        // FECHAS
        jFiltro.fechaIni = new Date(jFiltro.fechaIni);
        jFiltro.fechaFin = new Date(jFiltro.fechaFin);
        // CHECKS
        jFiltro.check = (jFiltro.check == 1)
        if (columnaEncintrada) return jFiltro;
      } 
    }
  }




  public crearFiltro_rapido_OEE(fechas, idTurno, maquinas, operarios) {
    var filtro = {
      logic: {
          id: 1,
          nombre: "O"
      },
      group: [
          {
              logic: {
                  id: 0,
                  nombre: "Y"
              },
              group: [ ]
          }
      ]
    };
    // se añaden las efchas al filtro
    var inicio = fechas[0];
    var fin = fechas[1];
    if (fin == undefined){
      fin = inicio;
    }
    filtro.group[0].group.push({
      columna: {
          id: 1,
          nombre: "Fecha",
          field: "fechaTurno",
          sqlfield: "",
          tipo: "date"
      },
      operator: {
          id: 7,
          nombre: "Está entre",
          dobleValor: true
      },
      fechaIni: inicio,
      fechaFin: fin,
      mostrarCalendario: false,
      text: "",
      numberMin: 0,
      numberMax: 0,
      decimalformat: 0.000,
      decimalMin: 0,
      decimalMax: 0,
      check: false,
      combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
      comboSelected: {},
      comboSelecteds: []
    });      

    // se añaden los turnos al filtro
    if(idTurno > 0){
      filtro.group[0].group.push( {
          columna: {
              id: 3,
              nombre: "Turno",
              field: "idTipoTurno",
              sqlfield: "",
              tipo: "comboEstrincto"
          },
          operator: {
              id: 2,
              nombre: "Es",
              dobleValor: true
          },
          fechaIni: " NO ES NECESARIO, PARA ÑAPAR ",
          fechaFin: " NO ES NECESARIO, PARA ÑAPAR ",
          mostrarCalendario: false,
          text: "",
          numberMin: 0,
          numberMax: 0,
          decimalformat: 0.000,
          decimalMin: 0,
          decimalMax: 0,
          check: false,
          combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
          comboSelected: {},
          comboSelecteds: [
              {
                  nombre: " NO ES NECESARIO, PARA ÑAPAR ",
                  id: idTurno
              }
          ]
      },);    
    }

    // se añaden las maquinas al filtro
    var jMaquinasSelecteds = [];
    maquinas.forEach(
      maquina => {
        jMaquinasSelecteds.push({
              id: maquina,
              nombre: " NO ES NECESARIO, PARA ÑAPAR ",
              puestoOffline: false,
              idmarca: -1,
              imagen: " NO ES NECESARIO, PARA ÑAPAR ",
              idERP: " NO ES NECESARIO, PARA ÑAPAR ",
              imagenBase64: "",
              visiblePlanificador: true,
              idSeccion: -1,
              tiempoMicroParada: -1,
              tipo_maquina: -1,
              idsGrupos: -1,
              contador: false,
              idMaquina: -1,
              nombreMaquina: " NO ES NECESARIO, PARA ÑAPAR "
          });
    });
    if (jMaquinasSelecteds.length > 0){
      filtro.group[0].group.push( {
        columna: {
            id: 6,
            nombre: "Máquina",
            field: "idMaquina",
            sqlfield: "",
            tipo: "comboEstrincto"
        },
        operator: {
            id: 2,
            nombre: "Es",
            dobleValor: true
        },
        fechaIni: " NO ES NECESARIO, PARA ÑAPAR ",
        fechaFin: " NO ES NECESARIO, PARA ÑAPAR ",
        mostrarCalendario: false,
        text: "",
        numberMin: 0,
        numberMax: 0,
        decimalformat: 0.000,
        decimalMin: 0,
        decimalMax: 0,
        check: false,
        combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
        comboSelected: {},
        comboSelecteds: jMaquinasSelecteds
      },);
    }

    // se añaden los operarios al filtro
    var jOperariosSelecteds = [];
    operarios.forEach(
      operario => {
        jOperariosSelecteds.push({
            id: operario,
            numOperario: operario,
            nombre: " NO ES NECESARIO, PARA ÑAPAR "
        });
    });    
    if (jOperariosSelecteds.length > 0){
      filtro.group[0].group.push(
        {
            columna: {
                id: 7,
                nombre: "Operario",
                field: "idOperario",
                sqlfield: "",
                tipo: "comboEstrincto"
            },
            operator: {
                id: 2,
                nombre: "Es",
                dobleValor: true
            },
            fechaIni: " NO ES NECESARIO, PARA ÑAPAR ",
            fechaFin: " NO ES NECESARIO, PARA ÑAPAR ",
            mostrarCalendario: false,
            text: "",
            numberMin: 0,
            numberMax: 0,
            decimalformat: 0.000,
            decimalMin: 0,
            decimalMax: 0,
            check: false,
            combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
            comboSelected: {},
            comboSelecteds: jOperariosSelecteds
        });
    }

    return filtro;
  } 
  
  public crearFiltro_rapido_AnalisisRendimiento(fechas, idTurnos, maquina) {
    var filtro = {
      logic: {
          id: 1,
          nombre: "O"
      },
      group: [
          {
              logic: {
                  id: 0,
                  nombre: "Y"
              },
              group: [ ]
          }
      ]
    };
    // se añaden las efchas al filtro
    var inicio = fechas[0];
    var fin = fechas[1];
    if (fin == undefined){
      fin = inicio;
    }
    filtro.group[0].group.push({
      columna: {
          id: 1,
          nombre: "Fecha",
          field: "fechaTurno",
          sqlfield: "",
          tipo: "date"
      },
      operator: {
          id: 7,
          nombre: "Está entre",
          dobleValor: true
      },
      fechaIni: inicio,
      fechaFin: fin,
      mostrarCalendario: false,
      text: "",
      numberMin: 0,
      numberMax: 0,
      decimalformat: 0.000,
      decimalMin: 0,
      decimalMax: 0,
      check: false,
      combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
      comboSelected: {},
      comboSelecteds: []
    });      

    // se añaden los turnos al filtro
        if(idTurnos.length > 0){

          var filtroTurnos = []
          idTurnos.forEach(
            idTurno => {
              filtroTurnos.push({
                    nombre: " NO ES NECESARIO, PARA ÑAPAR ",
                    id: idTurno
                });
          });   
          filtro.group[0].group.push( {
              columna: {
                  id: 3,
                  nombre: "Turno",
                  field: "idTipoTurno",
                  sqlfield: "",
                  tipo: "comboEstrincto"
              },
              operator: {
                  id: 2,
                  nombre: "Es",
                  dobleValor: true
              },
              fechaIni: " NO ES NECESARIO, PARA ÑAPAR ",
              fechaFin: " NO ES NECESARIO, PARA ÑAPAR ",
              mostrarCalendario: false,
              text: "",
              numberMin: 0,
              numberMax: 0,
              decimalformat: 0.000,
              decimalMin: 0,
              decimalMax: 0,
              check: false,
              combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
              comboSelected: {},
              comboSelecteds: filtroTurnos
          },); 
        }

    // se añaden las maquinas al filtro
    var jMaquinasSelecteds = [];
        jMaquinasSelecteds.push({
              id: maquina,
              nombre: " NO ES NECESARIO, PARA ÑAPAR ",
              puestoOffline: false,
              idmarca: -1,
              imagen: " NO ES NECESARIO, PARA ÑAPAR ",
              idERP: " NO ES NECESARIO, PARA ÑAPAR ",
              imagenBase64: "",
              visiblePlanificador: true,
              idSeccion: -1,
              tiempoMicroParada: -1,
              tipo_maquina: -1,
              idsGrupos: -1,
              contador: false,
              idMaquina: -1,
              nombreMaquina: " NO ES NECESARIO, PARA ÑAPAR "
          });
    if (jMaquinasSelecteds.length > 0){
      filtro.group[0].group.push( {
        columna: {
            id: 6,
            nombre: "Máquina",
            field: "idMaquina",
            sqlfield: "",
            tipo: "comboEstrincto"
        },
        operator: {
            id: 2,
            nombre: "Es",
            dobleValor: true
        },
        fechaIni: " NO ES NECESARIO, PARA ÑAPAR ",
        fechaFin: " NO ES NECESARIO, PARA ÑAPAR ",
        mostrarCalendario: false,
        text: "",
        numberMin: 0,
        numberMax: 0,
        decimalformat: 0.000,
        decimalMin: 0,
        decimalMax: 0,
        check: false,
        combo: [ /* NO ES NECESARIO, PARA "ÑAPAR" */ ],
        comboSelected: {},
        comboSelecteds: jMaquinasSelecteds
      },);
    }

    return filtro;
  } 
  
  // FECHA MINIMA Y FECHA MAXIMA APLICADAS EN EL FILTRO (para vida util de historico maquinas)
  filtroFechas_MIN_MAX(datosFiltro, incluirFechaActual) {
    // por seguridad solo te tienen en cuenta las fechas de doble valor de "esta entre" sino podria hacer una consulta demasiado grande
    if (incluirFechaActual){
      var now: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);    
      return this.filtroFechas_MIN_MAX_recursivo(datosFiltro, [now, new Date(1950, 0, 1, 0, 0, 0)]);
    } else {
      return this.filtroFechas_MIN_MAX_recursivo(datosFiltro, [undefined, undefined]);
    }
  }
  filtroFechas_MIN_MAX_recursivo(jFiltro, minmax) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?

    var min = minmax[0];
    var max = minmax[1];
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var now_MINMAX = this.filtroFechas_MIN_MAX_recursivo(linea, [min,max]);
          if (now_MINMAX != undefined){
            if(min == undefined || now_MINMAX[0] < min)
              min = now_MINMAX[0];
            if(max == undefined ||now_MINMAX[1] > max)
              max = now_MINMAX[1];
          }
        });
    }
    else if (jFiltro.columna != undefined) {
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 7) {
            if(min == undefined || this.myFunctions.dateTimeToDate(jFiltro.fechaIni) < min)
              min = this.myFunctions.dateTimeToDate(jFiltro.fechaIni);
            if(max == undefined || this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)) > max)
              max = this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1));
          }
        }
      }
    }
    return [min, max];
  }

  
  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo(datosFiltro) {
    // NUESTRO public datosFiltro = {                                                          => KENDO		public value: CompositeFilterDescriptor = {
    // NUESTRO   logic: { id: 0, nombre: 'and' },                                              => KENDO		    logic: "or",
    // NUESTRO   group: [                                                                      => KENDO		    filters: [
    // NUESTRO     {                                                                           => KENDO		      { field: "budget", operator: "gt", value: 60 },
    // NUESTRO       logic: { id: 0, nombre: 'and' },                                          => KENDO		      { field: "country", operator: "contains" },
    // NUESTRO       group: [                                                                  => KENDO		      { field: "discontinued", operator: "eq", value: true },
    // NUESTRO         {                                                                       => KENDO		      {
    // NUESTRO           columna: { id: 0, nombre: ' ', tipo: '' },                            => KENDO		        logic: "and",
    // NUESTRO           operator: { id: 0, nombre: '' },                                      => KENDO		        filters: [
    // NUESTRO           fechaIni: this.myFunctions.getDateNow(),                              => KENDO		          { field: "ordered on", operator: "lt", value: new Date(Date.now()) },
    // NUESTRO           fechaFin: this.myFunctions.getDateNow(),                              => KENDO		        ],
    // NUESTRO           mostrarCalendario: false,                                             => KENDO		      },
    // NUESTRO           text: '',                                                             => KENDO		    ],
    // NUESTRO           numberMin: 0,                                                         => KENDO		  };
    // NUESTRO           numberMax: 0,                                                         => KENDO
    // NUESTRO           decimalformat: '0.000',                                               => KENDO
    // NUESTRO           decimalMin: 0.0,                                                      => KENDO
    // NUESTRO           decimalMax: 0.0,                                                      => KENDO
    // NUESTRO           check: false,                                                         => KENDO
    // NUESTRO           combo: [                                                              => KENDO
    // NUESTRO             {id: 1, nombre: "opcion 1 "},                                       => KENDO
    // NUESTRO             {id: 2, nombre: "opcion 2"},                                        => KENDO
    // NUESTRO             {id: 3, nombre: "opcion 3"},                                        => KENDO
    // NUESTRO             {id: 4, nombre: "opcion 4"},                                        => KENDO
    // NUESTRO             {id: 5, nombre: "opcion 5"}                                         => KENDO
    // NUESTRO           ],                                                                    => KENDO
    // NUESTRO           comboSelected: {},                                                    => KENDO
    // NUESTRO           comboSelecteds: []                                                    => KENDO
    // NUESTRO         }                                                                       => KENDO
    // NUESTRO       ]                                                                         => KENDO
    // NUESTRO     }                                                                           => KENDO
    // NUESTRO   ]                                                                             => KENDO
    // NUESTRO };                                                                              => KENDO

    var em = this.filtroToKendo_recursivo(datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lt"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gte"
          }
          jSubRow1["value"] = this.myFunctions.dateTimeToDate(jFiltro.fechaIni);
          jSubRow2["value"] = this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.dateTimeToDate(jFiltro.fechaIni);
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.dateTimeToDate(jFiltro.fechaIni);
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }
}
