import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { FabricantesService } from '@app/_services';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import { Renderer2, NgZone } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { tumblrBoxIcon } from '@progress/kendo-svg-icons';
import { CursosLectivosTiposService } from '@app/_services/eskola_cursosLectivosTipos.service';

//Variables para el drag and drop de la tabla
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';

@Component({
  selector: 'app-cursoslectivostipos',
  templateUrl: 'eskola_cursoslectivostipos.component.html'
})



export class CursosLectivosTiposComponent {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  // GRID ALUMNOS
  public seleccionados: number[] = [];

  //VARIABLES DB
  public id: number = 0;
  public nombre: string = '';
  public descripcion: string = '';
  public activo: number = 0;

  public Jnombre: any;
  public JnombreSelected: any;
  public tiposGrupos: any;

  //CONTENIDO
  public Jcontenido: any = [];
  public contenidoSelected: number[] = [];

  //REQUIERED
  public requiereNombre: boolean = false;
  public requiereDescripcion: boolean = false;
  public requiereActivo: boolean = false;

  //Variables para el drag and drop del grid
  public state: State = {
    skip: 0,
    take: 100,
  };

  //POPUP: error al eliminar
  public popupErrorAlGuardar: boolean = false;
  public popupSeGuardaranLosCambios: boolean = false;


  public gridData: any = process([], this.state);
  currentSubscription: Subscription;
  user = this.userService.userValue;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService,
    public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    public fabricantesService: FabricantesService,
    private renderer: Renderer2,
    private userService: UsuariosService,
    private zone: NgZone,
    private cursosLectivosTiposService: CursosLectivosTiposService) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('grupo').toUpperCase();
  }

  ngOnInit() {
    //como aqui se cargan mas cosas que solo los datos ponemos la carga desde el inicio
    //this.loadingPanel = true;

    this.id = this.route.snapshot.params['id'];

    this.cargarDatosCursosTipos();
    this.cargarFiltroURL();
  }

  cargarDatosCursosTipos() {
    if (this.id != 0) {
      this.cursosLectivosTiposService.GetById(this.id).subscribe(
        (json) => {
            this.nombre = json[0].nombre;
            this.descripcion = json[0].descripcion;
            this.activo = json[0].activo;
        });
    }
  }

  cargarTipos() {
    this.loadingPanel = true;
    this.cursosLectivosTiposService.Get_tipos().subscribe(
      (json) => {
        this.tiposGrupos = json;
        this.Jnombre = json;
        this.JnombreSelected = undefined;
        this.loadingPanel = false;
      });
  }

  cargarFiltroURL() {
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    
  }

  //CONTENIDO
  removeHandler() {
    if (this.contenidoSelected.length > 0)
      this.Jcontenido = this.Jcontenido.filter(f => !this.contenidoSelected.includes(f.idTemp));
    this.contenidoSelected = [];
    this.reorderData();
    this.recargarGrid();
  }
  
  reorderData() {
    this.Jcontenido.sort(function (a, b) { return a['orden'] - b['orden']; })

    var orden = 1;
    this.Jcontenido.forEach(
      (row) => {
        row.orden = orden;
        orden++;
      });
  }
  redirect_consumible(dataItem) {
    if (dataItem.idConsumibles_contenido != undefined) {
      const url = this.router.serializeUrl(this.router.parseUrl('#/consumible/' + dataItem.idConsumibles_contenido.id));
      window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(this.router.parseUrl('#/consumibleTipo/' + dataItem.idConsumibles_contenido_tipo.id));
      window.open(url, '_blank');
    }
  }

  //BOTONES
  guardar() {
    var guardar = true;
    
    this.requiereNombre = false;
    this.requiereDescripcion = false;
    this.requiereDescripcion = false;
    this.requiereActivo = false;
    //valores obligatorios siempre y cuando se muestren
    if (this.nombre == '') {
      guardar = false;
      this.requiereNombre = true;
    }
    if (this.descripcion == '') {
      guardar = false;
      this.requiereDescripcion = true;
    }

    if (guardar) {      
      if (this.id == 0) {
        if (this.activo) {
          this.cursosLectivosTiposService.Create(this.nombre, this.descripcion, 1).subscribe(
            (json) => {this.router.navigate(['cursoslectivostipos']);});
        } else {
          this.cursosLectivosTiposService.Create(this.nombre, this.descripcion, 0).subscribe(
            (json) => {this.router.navigate(['cursoslectivostipos']);});
        }
        
      } else {
        if (this.activo) {
          this.cursosLectivosTiposService.Update(this.id, this.nombre, this.descripcion, 1).subscribe(
            (json) => {this.router.navigate(['cursoslectivostipos']);});
        } else {
          this.cursosLectivosTiposService.Update(this.id, this.nombre, this.descripcion, 0).subscribe(
            (json) => {this.router.navigate(['cursoslectivostipos']);});
        }        
      }
      //this.router.navigate(['cursoslectivostipos']);
      this.cargarTipos();
    }
  }
  cancelar() {
    this.router.navigate(['cursoslectivostipos']);
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
  //Funciones para cambiar el orden del kendo arrastrando las filas
  public rowCallback(context: RowClassArgs) {
    context.dataItem.orden = context.index + 1;
    return {
      dragging: context.dataItem.dragging
    };
  }
  private handleDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }
  recargarGrid() {
    this.gridData = process(this.Jcontenido, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }
  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }
  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  HMSToSeconds(tiempoEstimado) {
    var horas, minutos, segundos;
    horas = Number(tiempoEstimado.split(":")[0]);
    minutos = Number(tiempoEstimado.split(":")[1]);
    segundos = Number(tiempoEstimado.split(":")[2]);
    return horas * 3600 + minutos * 60 + segundos;
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
}
