import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivosService, AlertService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { FileInfo, FileRestrictions } from '@progress/kendo-angular-upload';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-activos-documento',
  templateUrl: './activos-documento.component.html',
  styleUrls: ['./activos-documento.component.less']
})
export class ActivosDocumentoComponent implements OnInit {

  public idActivo: number = -1;
   public idDocumento: number = -1;
   public user: any;
   
   public uploadSaveUrl;
   public uploadRemoveUrl;
   public fichero: String = "";
   public restrictions: FileRestrictions = {
     allowedExtensions: ['.pdf'],
     maxFileSize: 20048576
   };

   public closeResult = '';
  public form: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public myFiles: FileInfo[];
  public errorLink= false;
  public errorFichero = false;
  public ficheroViejo: String = '';
  public antesFichero: boolean;
  constructor( private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private menuService: MenuService,
    private userService: UsuariosService,
    private alertService: AlertService, private activosService: ActivosService) { 
      this.user = this.userService.userValue;
      this.menuService.titulo = this.translateService.instant('documento').toUpperCase();
    }

  ngOnInit(): void {

    this.idActivo = this.activatedRoute.snapshot.params['idActivo'];
    this.idDocumento = this.activatedRoute.snapshot.params['idDocumento'];


    this.uploadSaveUrl = `${environment.apiUrl}/activos/documentoActivo/upload/` + this.idActivo; // should represent an actual API endpoint
    this.uploadRemoveUrl = `${environment.apiUrl}/activos/documentoActivo/remove/` + this.idActivo; // should represent an actual API endpoint

    //Para evitar errores
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', [Validators.required]],
      isLink: [false,],
      fichero: ['',],
      link: ['',]
    });

    if(this.idDocumento != 0){
      this.activosService.getDocumentoActivoById(this.idDocumento).subscribe((response: any)=>{
        if(response[0].esLink){
          this.antesFichero = false;
        }else{
          this.antesFichero = true;
        }
        if(response[0].esLink)
          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            nombre: [response[0].nombre, [Validators.required]],
            isLink: [response[0].esLink,],
            fichero: ['',],
            link: [response[0].ficheroLink,]
          });
        else
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [response[0].nombre, [Validators.required]],
          isLink: [response[0].esLink,],
          fichero: [response[0].ficheroLink,],
          link: [,]
        });
        this.ficheroViejo = this.form.value.fichero;
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  public atras() {
    this.router.navigate(['activo/', this.idActivo])
  }

  public onSelect(ev): void {
    this.fichero = ev.files[0].name;
    this.form.controls['fichero'].setValue(this.fichero);
    if(!this.form.value.isLink && this.fichero != ''){
      this.errorFichero = false;
    }
  }

  guardarDocumento(upload: any){
    this.submitted= true;
    if(this.form.value.isLink && (this.form.value.link == '' || this.form.value.link == null)){
      this.errorLink = true;
    }else if(this.form.value.isLink && (this.form.value.link != '' && this.form.value.link != null)){
      this.errorLink = false;
    }
    if(!this.form.value.isLink && (Object.keys(upload.fileList._files).length === 0 && (this.form.value.fichero == '' || this.form.value.fichero == null))){
      this.errorFichero = true;
    }
    if (this.form.invalid || this.errorLink || this.errorFichero) {
      return;
    }
    if(this.idDocumento == 0){
      if(this.form.value.isLink){
        this.activosService.crearDocumentoActivo(this.idActivo, this.form.value.nombre, this.form.value.link, this.form.value.isLink).subscribe((response: any)=>{
          if (!response.error) {
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            upload.uploadFiles();
            this.router.navigate(['activo/'+this.idActivo]);
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }else{
        this.activosService.crearDocumentoActivo(this.idActivo, this.form.value.nombre, this.form.value.fichero, this.form.value.isLink).subscribe((response: any)=>{
          if (!response.error) {
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            upload.uploadFiles();
            this.router.navigate(['activo/'+this.idActivo]);
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }
    }else{
      if(this.form.value.isLink){
        this.activosService.editarDocumentoActivo(this.antesFichero, this.ficheroViejo, this.idDocumento, this.idActivo, this.form.value.nombre, this.form.value.link, this.form.value.isLink).subscribe((response: any)=>{
          if (!response.error) {
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            upload.uploadFiles();
            this.router.navigate(['activo/'+this.idActivo]);
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }else{
        this.activosService.editarDocumentoActivo(this.antesFichero, this.ficheroViejo, this.idDocumento, this.idActivo, this.form.value.nombre, this.form.value.fichero, this.form.value.isLink).subscribe((response: any)=>{
          if (!response.error) {
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            upload.uploadFiles();
            this.router.navigate(['activo/'+this.idActivo]);
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
      }
    }
  }

  isLinkChanges(){
    
  }
  errorEventHandler(e: ErrorEvent) {
    this.alertService.error(this.translateService.instant('error'));
  }


}
