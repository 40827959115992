import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/eskola_ofsPiezas`;

@Injectable()
export class Eskola_OfsPiezasService {

  constructor(private http: HttpClient) { }

  getAll() {
   return this.http.get<any[]>(baseUrl);
  }

  getById(idOf: number) {
    return this.http.get<any>(`${baseUrl}/${idOf}`);
  }

  getPiezasDeOf(Id: number) {
    return this.http.post<any>(`${baseUrl}/get_piezasDeOf`, {Id}, { withCredentials: true });
  }
  getAllPiezas() {
    return this.http.post<any>(`${baseUrl}/get_AllPiezas`, { withCredentials: true });
  }
  getPiezasPredefinidas(){
    return this.http.get<any>(`${baseUrl}/get_piezasPredefinidas`);
  }
  getPiezasPredefinidasById(Id){
    return this.http.post<any>(`${baseUrl}/get_piezasPredefinidasById`, {Id}, { withCredentials: true });
  }
  insertPieza(IdOf,Referencia, Nombre, Terminado, NumeroPlano, FechaInicio, FechaFin, Id_piezaPredefinida, Id_tipoPieza){
    //arreglamos fechas 
    var fIni:Date =FechaInicio;
    var fFin: Date = FechaFin;
    var auxFechaFinicio;
    var auxFechaFin;
    if(fIni !== null && fIni !== undefined && FechaInicio != "")
    auxFechaFinicio = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
    auxFechaFinicio = this.dateToYYYYMMDDtHHmmSSz( new Date(2000, 1, 1));
    if(fFin !== null && fFin !== undefined && FechaFin != "")
    auxFechaFin = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
    auxFechaFin =  this.dateToYYYYMMDDtHHmmSSz( new Date(2000, 1, 1));
    console.log(IdOf);
    console.log(Referencia);
    console.log(Nombre);
    console.log(Terminado);
    console.log(NumeroPlano);
    console.log(auxFechaFinicio);
    console.log(auxFechaFin);
    console.log(Id_piezaPredefinida);
    console.log(Id_tipoPieza);
    
    return this.http.post<any>(`${baseUrl}/insert_Pieza`, {IdOf,Referencia, Nombre, Terminado, NumeroPlano, FechaInicio: auxFechaFinicio, FechaFin:auxFechaFin, Id_piezaPredefinida, Id_tipoPieza}, { withCredentials: true });
  }









  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }


}
