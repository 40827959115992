import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsumibleComponent } from '@app/consumibles/consumible.component';
import { environment } from '@environments/environment';
import { tableColumnInsertLeftIcon } from '@progress/kendo-svg-icons';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/eskola_ofs`;

@Injectable()
export class Eskola_OfsService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getById(Id: number):Observable<any> {
    return this.http.post<any>(`${baseUrl}/get_byId`, {Id}, { withCredentials: true });

  }
  insertOf(Nombre, Referencia, Terminado, FechaInicio, FechaFin, Publica){
    var fIni:Date =FechaInicio;
    var fFin: Date = FechaFin;
    var auxFechaFinicio;
    var auxFechaFin;
    if(fIni !== null && fIni !== undefined && FechaInicio != "")
    auxFechaFinicio = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
    auxFechaFinicio = this.dateToYYYYMMDDtHHmmSSz( new Date(2000, 1, 1));
    if(fFin !== null && fFin !== undefined && FechaFin != "")
    auxFechaFin = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
    auxFechaFin =  this.dateToYYYYMMDDtHHmmSSz( new Date(2000, 1, 1));
    
    return this.http.post<any>(`${baseUrl}/insert_Of`, {Nombre, Referencia, Terminado, FechaInicio:auxFechaFinicio, FechaFin: auxFechaFin, Publica}, { withCredentials: true });

  }
  insertOfAlumno(IdOf, Id_alumno){
    console.log(Id_alumno);
    return this.http.post<any>(`${baseUrl}/insert_OfAlumno`, {IdOf, Id_alumno}, { withCredentials: true });
  }
  insertOfAsignatura(IdOf, Id_asignatura){
    return this.http.post<any>(`${baseUrl}/insert_OfAsignatura`, {IdOf, Id_asignatura}, { withCredentials: true });
  }

  GetOfsAlumnos(Id: number) {
    return this.http.post<any>(`${baseUrl}/getOfsAlumnos`, {Id}, { withCredentials: true });

  }












  
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }


}
