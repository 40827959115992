import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/turnos`;

@Injectable()
export class TurnosService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  public Insert(ano, numSemana, fechaInicio, fechaFin) {
    return this.http.post<any>(baseUrl + "/crear", { anno: ano, numSemana: numSemana, fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public Update(data) {
    return this.http.post<any>(baseUrl + "/editar", { id: data.id, nombre: data.nombre, descripcion: data.descripcion, requerido: data.requerido, operacion: data.operacion }, { withCredentials: true });
  }

  public Delete(data): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  public Get_Turno(idCalendarioSemanas): Observable<any> {
    return this.http.post(baseUrl + "/getturno", { idCalendarioSemanas: idCalendarioSemanas }, { withCredentials: true });
  }

  public Get_Last_Turno(): Observable<any> {
    return this.http.post(baseUrl + "/Get_Last_Turno", { }, { withCredentials: true });
  }

  public Insert_Turno(idCalendarioSemanas, data) {
    return this.http.post<any>(baseUrl + "/turno/crear", {
      idCalendarioSemanas: idCalendarioSemanas, idMaquina: data.idMaquina,
      lunesMannanaInicio: data.lunesMannanaInicio, lunesMannanaFin: data.lunesMannanaFin, lunesTardeInicio: data.lunesTardeInicio, lunesTardeFin: data.lunesTardeFin, lunesNocheInicio: data.lunesNocheInicio, lunesNocheFin: data.lunesNocheFin,
      martesMannanaInicio: data.martesMannanaInicio, martesMannanaFin: data.martesMannanaFin, martesTardeInicio: data.martesTardeInicio, martesTardeFin: data.martesTardeFin, martesNocheInicio: data.martesNocheInicio, martesNocheFin: data.martesNocheFin,
      miercolesMannanaInicio: data.miercolesMannanaInicio, miercolesMannanaFin: data.miercolesMannanaFin, miercolesTardeInicio: data.miercolesTardeInicio, miercolesTardeFin: data.miercolesTardeFin, miercolesNocheInicio: data.miercolesNocheInicio, miercolesNocheFin: data.miercolesNocheFin,
      juevesMannanaInicio: data.juevesMannanaInicio, juevesMannanaFin: data.juevesMannanaFin, juevesTardeInicio: data.juevesTardeInicio, juevesTardeFin: data.juevesTardeFin, juevesNocheInicio: data.juevesNocheInicio, juevesNocheFin: data.juevesNocheFin,
      viernesMannanaInicio: data.viernesMannanaInicio, viernesMannanaFin: data.viernesMannanaFin, viernesTardeInicio: data.viernesTardeInicio, viernesTardeFin: data.viernesTardeFin, viernesNocheInicio: data.viernesNocheInicio, viernesNocheFin: data.viernesNocheFin,
      sabadoMannanaInicio: data.sabadoMannanaInicio, sabadoMannanaFin: data.sabadoMannanaFin, sabadoTardeInicio: data.sabadoTardeInicio, sabadoTardeFin: data.sabadoTardeFin, sabadoNocheInicio: data.sabadoNocheInicio, sabadoNocheFin: data.sabadoNocheFin,
      domingoMannanaInicio: data.domingoMannanaInicio, domingoMannanaFin: data.domingoMannanaFin, domingoTardeInicio: data.domingoTardeInicio, domingoTardeFin: data.domingoTardeFin, domingoNocheInicio: data.domingoNocheInicio, domingoNocheFin: data.domingoNocheFin,
    }, { withCredentials: true });
  }
  public Insert_Turnos(data) {
    return this.http.post<any>(baseUrl + "/turnos/crear", {
      idCalendarioSemanas: -1, idMaquina: data.idMaquina,
      lunesMannanaInicio: data.lunesMannanaInicio, lunesMannanaFin: data.lunesMannanaFin, lunesTardeInicio: data.lunesTardeInicio, lunesTardeFin: data.lunesTardeFin, lunesNocheInicio: data.lunesNocheInicio, lunesNocheFin: data.lunesNocheFin,
      martesMannanaInicio: data.martesMannanaInicio, martesMannanaFin: data.martesMannanaFin, martesTardeInicio: data.martesTardeInicio, martesTardeFin: data.martesTardeFin, martesNocheInicio: data.martesNocheInicio, martesNocheFin: data.martesNocheFin,
      miercolesMannanaInicio: data.miercolesMannanaInicio, miercolesMannanaFin: data.miercolesMannanaFin, miercolesTardeInicio: data.miercolesTardeInicio, miercolesTardeFin: data.miercolesTardeFin, miercolesNocheInicio: data.miercolesNocheInicio, miercolesNocheFin: data.miercolesNocheFin,
      juevesMannanaInicio: data.juevesMannanaInicio, juevesMannanaFin: data.juevesMannanaFin, juevesTardeInicio: data.juevesTardeInicio, juevesTardeFin: data.juevesTardeFin, juevesNocheInicio: data.juevesNocheInicio, juevesNocheFin: data.juevesNocheFin,
      viernesMannanaInicio: data.viernesMannanaInicio, viernesMannanaFin: data.viernesMannanaFin, viernesTardeInicio: data.viernesTardeInicio, viernesTardeFin: data.viernesTardeFin, viernesNocheInicio: data.viernesNocheInicio, viernesNocheFin: data.viernesNocheFin,
      sabadoMannanaInicio: data.sabadoMannanaInicio, sabadoMannanaFin: data.sabadoMannanaFin, sabadoTardeInicio: data.sabadoTardeInicio, sabadoTardeFin: data.sabadoTardeFin, sabadoNocheInicio: data.sabadoNocheInicio, sabadoNocheFin: data.sabadoNocheFin,
      domingoMannanaInicio: data.domingoMannanaInicio, domingoMannanaFin: data.domingoMannanaFin, domingoTardeInicio: data.domingoTardeInicio, domingoTardeFin: data.domingoTardeFin, domingoNocheInicio: data.domingoNocheInicio, domingoNocheFin: data.domingoNocheFin,
    }, { withCredentials: true });
  }

  public Insert_Turno_Bulk(dt) {
    return this.http.post<any>(baseUrl + "/turno/crear_bulk", { dt }, { withCredentials: true });
  }

  public Update_Turno(data) {
    return this.http.post<any>(baseUrl + "/turno/editar", {idCalendarioSemanas: data.idCalendarioSemanas, idMaquina: data.idMaquina,
      lunesMannanaInicio: data.lunesMannanaInicio, lunesMannanaFin: data.lunesMannanaFin, lunesTardeInicio: data.lunesTardeInicio, lunesTardeFin: data.lunesTardeFin, lunesNocheInicio: data.lunesNocheInicio, lunesNocheFin: data.lunesNocheFin,
      martesMannanaInicio: data.martesMannanaInicio, martesMannanaFin: data.martesMannanaFin, martesTardeInicio: data.martesTardeInicio, martesTardeFin: data.martesTardeFin, martesNocheInicio: data.martesNocheInicio, martesNocheFin: data.martesNocheFin,
      miercolesMannanaInicio: data.miercolesMannanaInicio, miercolesMannanaFin: data.miercolesMannanaFin, miercolesTardeInicio: data.miercolesTardeInicio, miercolesTardeFin: data.miercolesTardeFin, miercolesNocheInicio: data.miercolesNocheInicio, miercolesNocheFin: data.miercolesNocheFin,
      juevesMannanaInicio: data.juevesMannanaInicio, juevesMannanaFin: data.juevesMannanaFin, juevesTardeInicio: data.juevesTardeInicio, juevesTardeFin: data.juevesTardeFin, juevesNocheInicio: data.juevesNocheInicio, juevesNocheFin: data.juevesNocheFin,
      viernesMannanaInicio: data.viernesMannanaInicio, viernesMannanaFin: data.viernesMannanaFin, viernesTardeInicio: data.viernesTardeInicio, viernesTardeFin: data.viernesTardeFin, viernesNocheInicio: data.viernesNocheInicio, viernesNocheFin: data.viernesNocheFin,
      sabadoMannanaInicio: data.sabadoMannanaInicio, sabadoMannanaFin: data.sabadoMannanaFin, sabadoTardeInicio: data.sabadoTardeInicio, sabadoTardeFin: data.sabadoTardeFin, sabadoNocheInicio: data.sabadoNocheInicio, sabadoNocheFin: data.sabadoNocheFin,
      domingoMannanaInicio: data.domingoMannanaInicio, domingoMannanaFin: data.domingoMannanaFin, domingoTardeInicio: data.domingoTardeInicio, domingoTardeFin: data.domingoTardeFin, domingoNocheInicio: data.domingoNocheInicio, domingoNocheFin: data.domingoNocheFin,
    }, { withCredentials: true });
  }

  public DeleteHistoricoTurnos(fecha) {
    return this.http.post<any>(baseUrl + "/DeleteHistoricoTurnos", { fecha: fecha }, { withCredentials: true });
  }

  public Get_turno_operario_byId(id): Observable<any> {
    return this.http.get(baseUrl + "/Get_turno_operario_byId/" + id);
  }

  public Guardar_turno_operario_Bulk(dt) {
    return this.http.post<any>(baseUrl + "/Guardar_turno_operario_bulk" , { dt }, { withCredentials: true });
  }

  public Delete_turnos_operarios(listaIds) {
    return this.http.post<any>(baseUrl + "/Delete_turnos_operarios", { ids: listaIds }, { withCredentials: true });
  }

  // Para crear el pdf
  public crear_horario_turnos(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/crear_horario_turnos`, { json }, { withCredentials: true });
  }

  public Get_Turnos_Posteriores(fecha): Observable<any> {
    return this.http.get(baseUrl + "/Get_Turnos_Posteriores/" + fecha);
  }

  public Get_TurnosFuturos(){
    return this.http.post<JSON>(`${baseUrl}/Get_TurnosFuturos`, { withCredentials: true });
  }

  public Get_Turnos_Maquinas(){
    return this.http.post<JSON>(`${baseUrl}/Get_Turnos_Maquinas`, {}, { withCredentials: true });
  }

  public Get_Turnos_Maquina_Copia(idMaquina){
    return this.http.get<JSON>(`${baseUrl}/Get_Turnos_Maquina_Copia/` + idMaquina);
  }

  public Insert_Turnos_Maquina_Copia(idMaquinaOrigen, idMaquinaDestino ){
    return this.http.post<JSON>(`${baseUrl}/copiarTurno` , { idMaquinaOrigen, idMaquinaDestino }, { withCredentials: true });
  }

  public Actualizar_Tabla_Turnos(idCalendarioSemanas, borrar) {
    return this.http.get(baseUrl + "/Actualizar_Tabla_Turnos/" + idCalendarioSemanas + "/" + borrar);
  }


}
