import { NgModule, APP_INITIALIZER, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule } from "@angular/common"
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// import ngx-translate, the http loader and drag-drop
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DndModule } from 'ngx-drag-drop';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxImageCompressService } from 'ngx-image-compress';

//Kanban
//import { KanbanModule } from '@syncfusion/ej2-angular-kanban';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
  RepercusionesService,
  PlanesAccionService,
  ControlasignacionesService,
  AccionesService,
  PerdidasService,
  ValidadorService,
  BonosService,
  SubcontratadoService,
  MaquinasService,
  MarcasService,
  HistoricoParametrosService,
  HistoricoAlarmasService,
  HistoricoMantenimientosService,
  ConsumiblesService,
  NotasService,
  HerramientasService,
  AlmacenesService,
  FabricantesService,
  PiezasPredefinidasService,
  OFService,
  PiezasService,
  OperacionesService,
  OperacionesPredefinidasService,
  DiccionarioPerdidasService,
  MantenimientosPredefinidosService,
  ConfiguracionService,
  HistoricoMaquinasDatosService,
  HistoricoMaquinasDatosTemporalService,
  HistoricoMaquinasDatosTemporal2Service,
  ControlesService,
  InformeRendimientoService,
  RutasService,
  RutasPredefinidasService,
  BomService,
  GestionDocumentalService,
  InformeOeeService,
  PartesService,
  OperariosService,
  SeriesService,
  CentroMecanizadoService,
  PlanificadorService,
  HmiService,
  InformeComparativaRendimientoService,
  ComboService,
  InformeEjecucionesService,
  InformeConsumoService,
  TurnosService,
  HistoricoOperacionesService,
  DisponibilidadService,
  ResetAllModelsService,
  InformeProyectosService,
  PiezasTipoService,
  MaterialesTipoService,
  EskolaGruposService,
  Eskola_AsignaturasRetosService,
  Eskola_CursosLectivosService,
  Eskola_OfsService,
  Eskola_MaquinasService,
  Eskola_HorariosSeccionesService,
  Eskola_ReservaMaquinasService,
  AlarmasTipoService,
  OperacionesTipoService,
  PlantaIsometricoService,
  AreasProductivasService,
  InstalacionesService,
  HistoricoDatosService,
  IncidenciasService,
  ProgramasService,
  BoxplotService,
  AsignacionTiemposService, 
  ClientesService, 
  ActivosService, 
  ProveedoresService,
  HistogramaTiemposPiezaService,
  RecetasService,
  ToleranciasTipoService,
  CalendariosService,
  CalendarioService,
  CursosLectivosGruposService,
  CursosLectivosTiposService,
  InformePersonalizadoService,
  Eskola_AlumnosService,
  
  InformesPersonalizadosDisenadorService,
  Eskola_OfsPiezasService,
  RecetasInduccionService,
  AtributosService,
  ClasificacionesService,
  ContenedoresService,
  BloqueoRangosService,
  AutomatizacionesService,
  SeguimientoOFsService,
  SubidaFicherosService
} from '@app/_services';


import 'hammerjs';
//Angular
// import { FilterModule } from '@progress/kendo-angular-filter';
import { DropDownsModule, ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
//import { MonacoEditorModule } from 'ngx-monaco-editor';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DialogsModule } from '@progress/kendo-angular-dialog';

//Paginas
import { HomeComponent } from './home/home.component';
import { Home_v2Component } from './home/home_v2.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { RepercusionesPerdidasComponent } from './repercusiones/perdidasnuevo/repercusionesperdidas.component';
import { RepercusionesMaquinasComponent } from './repercusiones/maquinasnuevo/repercusionesmaquinas.component';
import { ControlasignacionesComponent } from './controlasignaciones/controlasignaciones.component';
import { PlanesdeaccionComponent } from './plandeaccion/planesdeaccion.component';
import { PlandeaccionDetalleComponent } from './plandeaccion/detalle/plandeaccionDetalle.component';
import { AccionDetalleComponent } from './acciones/accionDetalle.component';
import { ValidadorComponent } from './validador/validador.component';
import { BonosComponent } from './bonos/bonos.component';
import { SubcontratadosComponent } from './subcontratado/subcontratados.component';
import { SubcontratadoComponent } from './subcontratado/subcontratado.component';
import { HistoricoOperacionesComponent } from './historicoOperaciones/historicoOperaciones.component';
import { HistoricoOperacionesInduccionComponent } from './historicoOperaciones/historicoOperacionesInduccion.component';
import { HistoricoProcesosComponent } from './historicoOperaciones/historicoProcesos.component';
import { HistoricoProcesosInduccionComponent } from './historicoOperaciones/historicoProcesosInduccion.component';
import { HistoricoPiezasComponent } from './historicoOperaciones/historicoPiezas.component';
import { OFsComponent } from './proyectos/OFs.component';
import { OFDetalleComponent } from './proyectos/OFDetalle.component'
import { PiezaDetalleComponent } from './piezas/piezaDetalle.component';
import { DiccionarioPerdidasComponent } from './diccionarioPerdidas/diccionarioPerdidas.component';
import { DiccionarioPerdidaDetalleComponent } from './diccionarioPerdidas/diccionarioPerdidaDetalle/diccionarioPerdidaDetalle.component';
import { MantenimientosPredefinidosComponent } from './mantenimientosPredefinidos/mantenimientosPredefinidos.component';
import { MantenimientosPredefinidosDetalleComponent } from './mantenimientosPredefinidos/mantenimientosPredefinidosDetalle/mantenimientosPredefinidosDetalle.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { ConfiguracionPlanificadorComponent } from './configuracion/configuracionPlanificador.component';
import { ConfiguracionCalidadComponent } from './configuracion/configuracionCalidad.component';
import { ControlesComponent } from './controles/controles.component';
import { ControlesDetalleComponent } from './controles/controlesDetalle/controlesDetalle.component';
import { InformeRendimientoComponent } from './informeRendimiento/informeRendimiento.component';
import { InformeTecnicoEjecucionComponent } from './informeTecnicoEjecucion/informeTecnicoEjecucion.component';
import { InformeElectricoComponent } from './informeElectrico/informeElectrico.component';
import { OperacionesDetalleComponent } from './operaciones/operacionesDetalle.component';
import { OperacionesPredefinidasDetalleComponent } from './operacionesPredefinidas/operacionesPredefinidasDetalle/operacionesPredefinidasDetalle.component';
import { OperacionesPredefinidasComponent } from './operacionesPredefinidas/operacionesPredefinidas.component';
import { CompatibilidadmaquinasComponent } from './compatibilidadmaquinas/compatibilidadmaquinas.component';
import { RutasDetalleComponent } from './rutas/rutasDetalle.component';
import { RutasPredefinidasDetalleComponent } from './rutasPredefinidas/rutasPredefinidasDetalle.component';
import { InformeOEEComponent } from './informesOEE/informeOEE.component';
import { InformeDisponibilidadOEEComponent } from './informesOEE/informeDisponibilidadOEE.component';
import { InformeRendimientoOEEComponent } from './informesOEE/informeRendimientoOEE.component';
import { InformeCalidadOEEComponent } from './informesOEE/informeCalidadOEE.component';
import { InformeOEEComponent_V2 } from './informesOEE_V2/informeOEE_V2.component';
import { InformeDisponibilidadOEEComponent_V2 } from './informesOEE_V2/informeDisponibilidadOEE_V2.component';
import { InformeRendimientoOEEComponent_V2 } from './informesOEE_V2/informeRendimientoOEE_V2.component';
import { InformeCalidadOEEComponent_V2 } from './informesOEE_V2/informeCalidadOEE_V2.component';
import { PlanificadorLargoMaquinaComponent } from './planificador/planificadorLargoMaquina.component';
import { PlanificadorLargoMaquinasComponent } from './planificador/planificadorLargoMaquinas.component';
import { PlanificadorLargoComparativaVersionesComponent } from './planificador/planificadorLargoComparativaVersiones.component';
import { PlanificadorCortoComponent } from './planificador/planificadorCorto.component';
import { PlanificadorCortoTemporalComponent } from './planificador/PlanificadorCortoTemporal.component';
import { PlanificadorLista } from './planificador/PlanificadorLista.component';
import { PlanificadorHerramientasComponent } from './planificador/planificadorHerramientas.component';
import { HMIComponent } from './hmi/hmi.component';
import { HMIOfflineComponent } from './hmi/hmioffline.component';
import { HMIExtrusorasComponent } from './hmi/hmiExtrusoras.component';
import { HMIImpresorasComponent } from './hmi/hmiImpresoras.component';
import { HMIInyectorasComponent } from './hmi/hmiInyectoras.component';
import { HMIHornosComponent } from './hmi/hmiHornos.component';
import { HMIHornosTempleComponent } from './hmi/hmiHornosTemple.component';
import { HMIAditivoComponent } from './hmi/hmiAditivo.component';
import { HMIInstalacionesComponent } from './hmi/hmiInstalaciones.component';
import { InformeComparativaRendimientoComponent } from './informeComparativaRendimiento/informeComparativaRendimiento.component';
import { MaquinasComponent } from './maquinas/maquinas.component';
import { InstalacionesComponent } from './instalaciones/instalaciones.component';
import { MaquinaDetalleComponent } from './maquinas/maquinasTiposDetalle/maquinaDetalle.component';
import { ExtrusoraDetalleComponent } from './maquinas/maquinasTiposDetalle/extrusoraDetalle.component';
import { ImpresoraPlasticoDetalleComponent } from './maquinas/maquinasTiposDetalle/impresoraPlasticoDetalle.component';
import { InyectoraDetalleComponent } from './maquinas/maquinasTiposDetalle/inyectoraDetalle.component';
import { HornoDetalleComponent } from './maquinas/maquinasTiposDetalle/hornoDetalle.component';
import { HornoTempleDetalleComponent } from './maquinas/maquinasTiposDetalle/hornoTempleDetalle.component';
import { AditivoDetalleComponent } from './maquinas/maquinasTiposDetalle/aditivoDetalle.component';
import { InstalacionDetalleComponent } from './instalaciones/instalacionesDetalle/instalacionDetalle.component';
import { CampoDetalleComponent } from './instalaciones/camposDetalle/campoDetalle.component';
import { MaquinaDetalleEjeComponent } from './maquinaDetalle/maquinaDetalleEje.component';
import { MaquinaDetalleAlarmasControladasComponent } from './maquinaDetalle/maquinaDetalleAlarmasControladas.component';
import { MaquinaDetalleSubEstadosComponent } from './maquinaDetalle/maquinaDetalleSubEstados.component';
import { MaquinaDetalleAlarmasPasivasComponent } from './maquinaDetalle/maquinaDetalleAlarmasPasivas.component';
import { MaquinaDetalleMantenimientoComponent } from './maquinaDetalle/maquinaDetalleMantenimiento.component';
import { MaquinaDetalleManualComponent } from './maquinaDetalle/maquinaDetalleManual.component';
import { MaquinaDetalleManualCarpetaComponent } from './maquinaDetalle/maquinaDetalleManualCarpeta.component';
import { MaquinaDetalleCapaComponent } from './maquinaDetalle/maquinaDetalleCapa.component';
import { MaquinaDetalleSeccionesEjesComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleEjes.component';
import { MaquinaDetalleSeccionesAlarmasControladasComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleAlarmasControladas.component';
import { MaquinaDetalleSeccionesAlarmasPasivasComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleAlarmasPasivas.component';
import { MaquinaDetalleSeccionesMantenimientosComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleMantenimientos.component';
import { MaquinaDetalleSeccionesManualesComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleManuales.component';
import { MaquinaDetalleSeccionesCapasComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleCapas.component';
import { MaquinaDetalleSeccionesSubEstadosComponent } from './maquinaDetalle/maquinaDetalleSecciones/maquinaDetalleSubEstados.component';
import { MarcasComponent } from './marcas/marcas.component';
import { MarcasDetalleComponent } from './marcas/marcasDetalle/marcasDetalle.component';
import { HistoricoAlarmasComponent } from './historicoAlarmas/historicoAlarmas.component';
import { HistoricoParametrosComponent } from './historicoParametros/historicoParametros.component';
import { SeguimientosProyectosComponent } from './seguimientosProyectos/seguimientosProyectos.component';
import { HistoricoMantenimientosComponent } from './historicoMantenimientos/historicoMantenimientos.component';
import { ConsumiblesTiposComponent } from './consumibles/consumiblesTipos.component';
import { ConsumibleTipoComponent } from './consumibles/consumibleTipo.component';
import { ConsumiblesComponent } from './consumibles/consumibles.component';
import { ConsumibleComponent } from './consumibles/consumible.component';
import { HistoricoConsumibles } from './consumibles/historicoConsumibles.component';
import { HistoricoConsumible } from './consumibles/historicoConsumible.component';
import { HistoricoConsumibleNserie } from './consumibles/historicoConsumibleNserie.component';
import { HistoricoConsumibleTipo } from './consumibles/historicoConsumibleTipo.component';
import { VistaConsumibles } from './consumibles/VistaConsumibles.component';
import { GestionConsumiblesComponent } from './consumibles/gestion-consumibles.component';
import { NotasComponent } from './notas/notas.component';
import { NotasCrearComponent } from './notas/notas-crear/notas-crear.component';
import { NotasEditarComponent } from './notas/notas-editar/notas-editar.component';
import { MovimientosConsumiblesComponent } from './consumibles/movimientos-consumibles.component';
import { HerramientasComponent } from './herramientas/herramientas.component';
import { HerramientasDetalleComponent } from './herramientas/herramientasDetalle/herramientasDetalle.component';
import { TiposHerramientasComponent } from './herramientas/tiposHerramientas.component'; 
import { TiposHerramientasDetalleComponent } from './herramientas/tiposHerramientasDetalle/tiposHerramientasDetalle.component';
import { PlacasDetalleComponent } from './herramientas/placasDetalle/placasDetalle.component';
import { HerramientasUnidadesComponent } from './herramientasUnidades/herramientasUnidades.component';
import { HerramientasUnidadesDetalleComponent } from './herramientasUnidades/herramientasUnidadesDetalle/herramientasUnidadesDetalle.component';
import { PlacasUnidadesDetalleComponent } from './herramientasUnidades/placasUnidadesDetalle/placasUnidadesDetalle.component';
import { AlmacenesComponent } from './almacenes/almacenes.component';
import { AlmacenesDetalleComponent } from './almacenes/almacenesDetalle/almacenesDetalle.component';
import { FabricantesComponent } from './fabricantes/fabricantes.component';
import { FabricantesDetalleComponent } from './fabricantes/fabricantesDetalle/fabricantesDetalle.component';
import { PiezasPredefinidasComponent } from './piezasPredefinidas/piezasPredefinidas.component';
import { PiezasPredefinidasDetalleComponent } from './piezasPredefinidas/piezasPredefinidasDetalle/piezasPredefinidasDetalle.component';
import { InformeEjecuciones } from './informeEjecuciones/informeEjecuciones.component';
import { InformeConsumo } from './informeConsumo/informeConsumo.component';
import { MaquinasGruposComponent } from './maquinasGrupos/maquinasGrupos.component';
import { EditarCrearMaquinasGrupoComponent } from './maquinasGrupos/editar-crear-maquinasGrupo/editar-crear-maquinasGrupo.component';
import { MaquinasGruposPlanificadorComponent } from './maquinasGruposPlanificador/maquinasGruposPlanificador.component';
import { EditarCrearMaquinasGrupoPlanificadorComponent } from './maquinasGruposPlanificador/editar-crear-maquinasGrupoPlanificador/editar-crear-maquinasGrupoPlanificador.component';
import { TurnosComponent } from './turnos/turnos.component';
import { TurnoDetalleComponent } from './turnos/turnoDetalle/turnoDetalle.component';
import { InformeCalculoDisponibilidadComponent } from './disponibilidad/informes/informeCalculoDisponibilidad.component';
import { InformesIncidenciasComponent } from './disponibilidad/informes/informesIncidencias.component';
import { InformeIncidenciaComponent } from './disponibilidad/informes/informeIncidencia.component';
import { TurnosDisponibilidadComponent } from './disponibilidad/turnos/turnosDisponibilidad.component';
import { TurnoDisponibilidadoDetalleComponent } from './disponibilidad/turnos/turnoDetalle/turnoDisponibilidadDetalle.component';
import { InformeProyectosComponent } from './informeProyectos/informeProyectos.component';
import { AnaliticaAvanzadaEjecucionesComponent } from './analiticaAvanzadaEjecuciones/analiticaAvanzadaEjecuciones.component';
import { AnaliticaAvanzadaEjecucionesComponent_V2 } from './analiticaAvanzadaEjecuciones_V2/analiticaAvanzadaEjecuciones.component_V2';
import { InformeComparativaProyectosComponent } from './informeComparativaProyectos/informeComparativaProyectos.component';
import { VistaAlmacenesComponent } from './vistaAlmacenes/vistaAlmacenes.component';
import { VistaHerramientasPlacasComponent } from './vistaHerramientasPlacas/vistaHerramientasPlacas.component';
import { MaterialesTipoComponent } from './materialesTipo/materialesTipo.component';
import { MaterialesTipoDetalleComponent } from './materialesTipo/materialesTipoDetalle/materialesTipoDetalle.component';

import { EskolaGruposComponent } from './eskola_grupos/eskola_grupos.component';
import { EskolaCalendariosComponent } from './eskola_calendarios/eskola_calendarios.component';
import { Eskola_AsignaturasRetosComponent } from './eskola_asignaturasRetos/eskola_asignaturasRetos.component';
import { Eskola_AsignaturasRetosDetalleComponent } from './eskola_asignaturasRetos/eskola_asignaturasRetosDetalle/eskola_asignaturasRetosDetalle.component';
import { Eskola_CursosLectivosComponent } from './eskola_cursosLectivos/eskola_cursosLectivos.component';
import { Eskola_CursosLectivosDetalleComponent } from './eskola_cursosLectivos/eskola_cursosLectivosDetalle/eskola_cursosLectivosDetalle.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { Eskola_DocentesComponent } from './eskola_docentes/eskola_docentes.component';
import { Eskola_LanzadorOfComponent } from './eskola_lanzadorOf/eskola_lanzadorOf';
import { Eskola_HorariosSecciones} from './eskola_horariosSecciones/eskola_horariosSecciones.component';
import {Eskola_VistaAnualComponent }  from './eskola_horariosSecciones/eskola_horariosSecciones_vistaAnual/eskola_horariosSecciones_vistaAnual.component';
import {Eskola_GruposDetalleComponent }  from './eskola_grupos/eskola_gruposDetalle/eskola_gruposDetalle.component';
import {Eskola_AlumnosDetalleComponent }  from './eskola_alumnos/eskola_alumnosDetalle/eskola_alumnosDetalle.component';
import {Eskola_DocentesDetalleComponent }  from './eskola_docentes/eskola_docentesDetalle/eskola_docentesDetalle.component';
import {Eskola_ReservaMaquinasComponent }  from './eskola_reservaMaquinas/eskola_reservaMaquinas.component';
import {Eskola_InformeAlumnoComponent }  from './eskola_informeAlumno/eskola_informeAlumno.component';

import { FiltroFechasComponent } from './filtro_fechas/filtro-fechas.component';
import { CalendarioCuadradosComponent } from './calendario_cuadrados/calendario_cuadrados.component';


import { GraficoTemperaturaComponent } from './hmi/graficos/graficoTemperatura.component'
import { GraficoPosicionComponent } from './hmi/graficos/graficoPosicion.component'
import { GraficoNivelComponent } from './hmi/graficos/graficoNivel.component'

import { HMIPanelComponent } from './hmi/panel/hmiPanel.component';

import { AlarmasTipoComponent } from './alarmasTipo/alarmasTipo.component';
import { AlarmasTipoDetalleComponent } from './alarmasTipo/alarmasTipoDetalle/alarmasTipoDetalle.component';
import { PiezasTipoComponent } from './piezasTipo/piezasTipo.component';
import { PiezasTipoDetalleComponent } from './piezasTipo/piezasTipoDetalle/piezasTipoDetalle.component';
import { OperacionesTipoComponent } from './operacionesTipo/operacionesTipo.component';
import { OperacionesTipoDetalleComponent } from './operacionesTipo/operacionesTipoDetalle/operacionesTipoDetalle.component';
import { PlantasIsometricoComponent } from './plantaIsometrico/plantasIsometrico.component';
import { DiseñadorPlantaIsometricoComponent } from './plantaIsometrico/diseñadorPlantaIsometrico.component';
import { HomePlantaIsometricoComponent } from './plantaIsometrico/homePlantaIsometrico.component';
import { HistoricoDatosComponent } from './historicoDatos/historicoDatos.component';
import { AreasProductivasComponent } from './areasProductivas/areasProductivas.component';
import { AreaProductivaComponent } from './areasProductivas/areaProductiva.component';
import { TutorialComponent } from './tutorial/tutorial.component';
import { RecetasDetalleComponent } from './recetas/recetasDetalle.component';
import { RecetasInduccionComponent } from './recetas/recetasInduccion.component';
import { RecetasInyeccionComponent } from './recetas/recetasInyeccion.component';

import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/eu/all';
import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/ru/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import { IntlModule } from '@progress/kendo-angular-intl';
import { MyFunctions } from './_helpers/myfunctions';
import { MyFilter } from './_helpers/myFilter';
import { MyCharts } from './_helpers/myCharts';
import { VisorProgramasComponent } from './visor-programas/visor-programas.component';
import { VisorVersionComponent } from './visor-version/visor-version.component';
import { PiezasBoxplotComponent } from './piezas-boxplot/piezas-boxplot.component';
import { OperacionesBoxplotComponent } from './operaciones-boxplot/operaciones-boxplot.component';
import { ComparativaTiemposBoxplotComponent } from './comparativa-tiempos-boxplot/comparativa-tiempos-boxplot.component';
import { PerdidasGruposComponent } from './diccionarioPerdidas-grupos/perdidas-grupos.component';
import { EditarCrearDiccionarioPerdidasGruposComponent } from './diccionarioPerdidas-grupos/editar-crear-diccionario-perdidas-grupos/editar-crear-diccionario-perdidas-grupos.component';
import { AsignacionTiemposComponent } from './asignacion-tiempos/asignacion-tiempos.component';
import { ClientesOrdenComponent } from './clientes-orden/clientes-orden.component';
import { EditarCrearClientesComponent } from './clientes-orden/editar-crear-clientes/editar-crear-clientes.component';
import { TiposActivoComponent } from './tipos-activo/tipos-activo.component';
import { TiposActivoDetalleComponent} from './tipos-activo/tipos-activo-detalle/tipos-activo-detalle.component';
import { SeccionesDetalleComponent} from './secciones-detalle/secciones-detalle.component';
import { InformeCalidadComponent} from './informe-calidad/informe-calidad.component';
import { ActivosMaestrosComponent } from './activos_maestros/activosMaestros.component';
import { ActivosMaestrosDetalleComponent } from './activos_maestros/activosMaestros-detalle/activosMaestros-detalle.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { ActivosMaestrosDocumentoComponent } from './activos_maestros/activos-maestros-documento/activos-maestros-documento.component';
import { ProveedorDetalleComponent } from './proveedores/proveedor-detalle/proveedor-detalle.component';
import { ActivosMaestrosProveedorComponent } from './activos_maestros/activos-maestros-proveedor/activos-maestros-proveedor.component';
import { ActivosMaestrosVistArbolComponent } from './activos_maestros/activos-maestros-vist-arbol/activos-maestros-vist-arbol.component';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { ArbolActivosMaestrosComponent } from './arbol-activos-maestros/arbol-activos-maestros.component';
import { ActivosComponent } from './activos/activos.component';
import { ActivosDetalleComponent } from './activos/activos-detalle/activos-detalle.component';
import { ActivosDocumentoComponent } from './activos/activos-documento/activos-documento.component';
import { StockComponent } from './stock/stock.component';
import { GeneradorPedidoComponent } from './stock/generador-pedido/generador-pedido.component';
import { PiezasDocumentoComponent } from './piezas/piezas-documento/piezas-documento.component';
import { PiezasPredefinidasDocumentoComponent } from './piezasPredefinidas/piezasPredefinidasDetalle/piezasPredefinidas-documento/piezasPredefinidas-documento.component';
import { HistogramaTiempoPiezaComponent } from './histogramaTiempoPieza/histogramaTiempoPieza.component';
import { ToleranciasTipoComponent } from './toleranciasTipo/toleranciasTipo.component';
import { ToleranciasTipoDetalleComponent } from './toleranciasTipo/toleranciasTipoDetalle/toleranciasTipoDetalle.component';
import { InformeTempladoComponent } from './informeTemplado/informeTemplado.component';
//import { InformesPersonalizadosComponent } from './informesPersonalizados/informesPersonalizados.component';
import { CursosLectivosGruposComponent } from './eskola_cursoslectivosgrupos/eskola_cursoslectivosgrupos.component';
import { CursosLectivosGruposTodosComponent } from './eskola_cursoslectivosgrupos/eskola_cursoslectivosgrupostodos.component';
import { CursosLectivosTiposComponent } from './eskola_cursoslectivostipos/eskola_cursoslectivostipos.component';
import { CursosLectivosTiposTodosComponent } from './eskola_cursoslectivostipos/eskola_cursoslectivostipostodos.component';
import { EskolaAlumnosComponent } from './eskola_alumnos/eskola_alumnos.component';
import { InformePersonalizadoComponent } from './informePersonalizado/informePersonalizado.component';
import { InformesPersonalizadosDisenadorComponent } from './informesPersonalizadosDisenador/informesPersonalizadosDisenador.component';
import { CrearInformesPersonalizadosDisenadorComponent } from './informesPersonalizadosDisenador/crearInformesPersonalizados/crearInformesPersonalizadosDisenador.component';
import { EditarInformesPersonalizadosDisenadorComponent } from './informesPersonalizadosDisenador/editarInformesPersonalizados/editarInformesPersonalizadosDisenador.component';

import { CalendariosDetalleComponent } from './eskola_calendarios/eskola_calendariosDetalle/eskola_calendariosDetalle.component';
import { TurnosOperariosComponent } from './turnosOperarios/turnosOperarios.component';
import { HometvComponent } from './home_tv/home_tv.component';
import { Hometv_v2Component } from './home_tv/home_tv_v2.component';

import { AtributosComponent } from './atributos/atributos.component';
// import { CrearAtributosComponent } from './atributos/crearAtributos/crearAtributos.component';
import { EditarAtributosComponent } from './atributos/editarAtributos/editarAtributos.component';
import { InformeProyectosComponent_v2 } from './informeProyectos/informeProyectos_v2.component';

import { AnalisisRendimientoComponent } from './informes/analisisRendimiento/analisisRendimiento.component';
import { ContenedoresComponent } from './contenedores/contenedores.component';
import { ContenedorDetalleComponent } from './contenedores/contenedorDetalle/contenedorDetalle.component';
import { InformeContenedoresComponent } from './informeContenedores/informeContenedores.component';
import { InformeContenedorDetalleComponent } from './informeContenedores/informeContenedorDetalle/informeContenedorDetalle.component';
import { BloqueoRangosComponent } from './bloqueoRangos/bloqueoRangos.component';
import { BloqueoRangosDetalleComponent } from './bloqueoRangos/bloqueoRangosDetalle/bloqueoRangosDetalle.component';
import { GruposPlanificadorComponent } from './gruposPlanificador/gruposPlanificador.component';
import { SeguimientoOFsConfiguradorComponent } from './seguimientoOFsConfigurador/seguimientoOFsConfigurador.component';
import { SeguimientoOFsConfiguradorDetalleComponent } from './seguimientoOFsConfigurador/seguimientoOFsConfiguradorDetalle/seguimientoOFsConfiguradorDetalle.component';

import { ClasificacionesComponent } from './clasificaciones/clasificaciones.component';
// import { ClasificacionesCrearComponent } from './clasificaciones/clasificaciones-crear/clasificaciones-crear.component';
import { ClasificacionesEditarComponent } from './clasificaciones/clasificaciones-editar/clasificaciones-editar.component';
import { AutomatizacionesComponent } from './automatizaciones/automatizaciones.component';
import { AutomatizacionDetalleComponent } from './automatizaciones/automatizacionDetalle.component';

//ZITU CONTROLES
import { ChartGaugeComponent } from './_helpers/Zitu_controles/chart_gauge.component'

@NgModule({
  imports: [
    IntlModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    ExcelModule,
    PDFModule,
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    // FilterModule,
    InputsModule,
    UploadsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    //MonacoEditorModule.forRoot(),
    LabelModule,
    LayoutModule,
    TooltipModule,
    DialogsModule,
    TreeViewModule,
    TreeListModule,
    //KanbanModule,
    DragDropModule,
    DndModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    Home_v2Component,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    DetailsComponent,
    UpdateComponent,
    RepercusionesPerdidasComponent,
    RepercusionesMaquinasComponent,
    ControlasignacionesComponent,
    PlanesdeaccionComponent,
    PlandeaccionDetalleComponent,
    AccionDetalleComponent,
    ValidadorComponent,
    BonosComponent,
    SubcontratadosComponent,
    SubcontratadoComponent,
    SeguimientosProyectosComponent,
    HistoricoOperacionesComponent,
    HistoricoOperacionesInduccionComponent,
    HistoricoProcesosComponent,
    HistoricoProcesosInduccionComponent,
    HistoricoPiezasComponent,
    OFDetalleComponent,
    OFsComponent,
    PiezaDetalleComponent,
    DiccionarioPerdidasComponent,
    DiccionarioPerdidaDetalleComponent,
    MantenimientosPredefinidosComponent,
    MantenimientosPredefinidosDetalleComponent,
    ConfiguracionComponent,
    ConfiguracionPlanificadorComponent,
    ConfiguracionCalidadComponent,
    ControlesComponent,
    ControlesDetalleComponent,
    InformeRendimientoComponent,
    InformeTecnicoEjecucionComponent,
    InformeElectricoComponent,
    OperacionesPredefinidasDetalleComponent,
    OperacionesPredefinidasComponent,
    OperacionesDetalleComponent,
    CompatibilidadmaquinasComponent,
    RutasDetalleComponent,
    RutasPredefinidasDetalleComponent,
    MaquinasComponent,
    InstalacionesComponent,
    MaquinaDetalleComponent,
    ExtrusoraDetalleComponent,
    ImpresoraPlasticoDetalleComponent,
    InyectoraDetalleComponent,
    HornoDetalleComponent,
    HornoTempleDetalleComponent,
    AditivoDetalleComponent,
    InstalacionDetalleComponent,
    CampoDetalleComponent,
    MaquinaDetalleEjeComponent,
    MaquinaDetalleAlarmasControladasComponent,
    MaquinaDetalleSubEstadosComponent,
    MaquinaDetalleAlarmasPasivasComponent,
    MaquinaDetalleMantenimientoComponent,
    MaquinaDetalleManualComponent,
    MaquinaDetalleManualCarpetaComponent,
    MaquinaDetalleCapaComponent,
    MaquinaDetalleSeccionesEjesComponent,
    MaquinaDetalleSeccionesAlarmasControladasComponent,
    MaquinaDetalleSeccionesAlarmasPasivasComponent,
    MaquinaDetalleSeccionesMantenimientosComponent,
    MaquinaDetalleSeccionesManualesComponent,
    MaquinaDetalleSeccionesCapasComponent,
    MaquinaDetalleSeccionesSubEstadosComponent,
    MarcasComponent,
    MarcasDetalleComponent,
    HistoricoAlarmasComponent,
    HistoricoParametrosComponent,
    HistoricoMantenimientosComponent,
    ConsumiblesTiposComponent,
    ConsumibleTipoComponent,
    ConsumiblesComponent,
    ConsumibleComponent,
    GestionConsumiblesComponent,
    NotasComponent,
    NotasCrearComponent,
    NotasEditarComponent,
    MovimientosConsumiblesComponent,
    HistoricoConsumibles,
    HistoricoConsumible,
    HistoricoConsumibleNserie,
    HistoricoConsumibleTipo,
    VistaConsumibles,
    HerramientasComponent,
    HerramientasDetalleComponent,
    TiposHerramientasComponent,
    TiposHerramientasDetalleComponent,
    PlacasDetalleComponent,
    HerramientasUnidadesComponent,
    HerramientasUnidadesDetalleComponent,
    PlacasUnidadesDetalleComponent,
    AlmacenesComponent,
    AlmacenesDetalleComponent,
    FabricantesComponent,
    FabricantesDetalleComponent,
    PiezasPredefinidasComponent,
    PiezasPredefinidasDetalleComponent,
    InformeOEEComponent,
    InformeDisponibilidadOEEComponent,
    InformeRendimientoOEEComponent,
    InformeCalidadOEEComponent,
    InformeOEEComponent_V2,
    InformeDisponibilidadOEEComponent_V2,
    InformeRendimientoOEEComponent_V2,
    InformeCalidadOEEComponent_V2,
    PlanificadorLargoMaquinaComponent,
    PlanificadorLargoMaquinasComponent,
    PlanificadorLargoComparativaVersionesComponent,
    PlanificadorCortoComponent,
    PlanificadorCortoTemporalComponent,
    PlanificadorLista,
    PlanificadorHerramientasComponent,
    HMIComponent,
    HMIOfflineComponent,
    HMIExtrusorasComponent,
    HMIImpresorasComponent,
    HMIInyectorasComponent,
    HMIHornosComponent,
    HMIHornosTempleComponent,
    HMIAditivoComponent,
    HMIInstalacionesComponent,
    InformeComparativaRendimientoComponent,
    InformeEjecuciones,
    InformeConsumo,
    MaquinasGruposComponent,
    EditarCrearMaquinasGrupoComponent,
    EditarCrearMaquinasGrupoPlanificadorComponent,
    MaquinasGruposPlanificadorComponent,
    TurnosComponent,
    TurnoDetalleComponent,
    InformeCalculoDisponibilidadComponent,
    InformesIncidenciasComponent,
    InformeIncidenciaComponent,
    TurnosDisponibilidadComponent,
    TurnoDisponibilidadoDetalleComponent,
    InformeProyectosComponent,
    AnaliticaAvanzadaEjecucionesComponent,
    AnaliticaAvanzadaEjecucionesComponent_V2,
    InformeComparativaProyectosComponent,
    VistaAlmacenesComponent,
    VistaHerramientasPlacasComponent,
    MaterialesTipoComponent,
    MaterialesTipoDetalleComponent,
    Eskola_AsignaturasRetosComponent,
    Eskola_AsignaturasRetosDetalleComponent,
    Eskola_DocentesComponent,
    CalendarioComponent,
    Eskola_CursosLectivosDetalleComponent,
    Eskola_CursosLectivosComponent,
    EskolaGruposComponent,
    EskolaCalendariosComponent,
    Eskola_LanzadorOfComponent,
    Eskola_HorariosSecciones,
    Eskola_VistaAnualComponent,
    Eskola_GruposDetalleComponent,
    Eskola_AlumnosDetalleComponent,
    Eskola_DocentesDetalleComponent,
    Eskola_ReservaMaquinasComponent,
    CalendarioComponent,
    CalendarioCuadradosComponent,
    FiltroFechasComponent,
    CalendariosDetalleComponent,
    CursosLectivosGruposComponent,
    CursosLectivosGruposTodosComponent,
    CursosLectivosTiposComponent,
    CursosLectivosTiposTodosComponent,
    EskolaAlumnosComponent,
    AlarmasTipoComponent,
    AlarmasTipoDetalleComponent,
    PiezasTipoComponent,
    PiezasTipoDetalleComponent,
    OperacionesTipoComponent,
    OperacionesTipoDetalleComponent,
    PlantasIsometricoComponent,
    DiseñadorPlantaIsometricoComponent,
    HomePlantaIsometricoComponent,
    HistoricoDatosComponent,
    AreasProductivasComponent,
    AreaProductivaComponent,
    VisorProgramasComponent,
    VisorVersionComponent,
    PiezasBoxplotComponent,
    OperacionesBoxplotComponent,
    ComparativaTiemposBoxplotComponent,
    PerdidasGruposComponent,
    EditarCrearDiccionarioPerdidasGruposComponent,
    AsignacionTiemposComponent,
    ClientesOrdenComponent,
    EditarCrearClientesComponent,
    ClientesOrdenComponent,
    TutorialComponent,
    TiposActivoComponent,
    TiposActivoDetalleComponent,
    SeccionesDetalleComponent,
    InformeCalidadComponent,
    ActivosMaestrosComponent,
    ActivosMaestrosDetalleComponent,
    ProveedoresComponent,
    ActivosMaestrosDocumentoComponent,
    ProveedorDetalleComponent,
    ActivosMaestrosProveedorComponent,
    ActivosMaestrosVistArbolComponent,
    ArbolActivosMaestrosComponent,
    ActivosComponent,
    ActivosDetalleComponent,
    ActivosDocumentoComponent,
    StockComponent,
    GeneradorPedidoComponent,
    PiezasDocumentoComponent,
    PiezasPredefinidasDocumentoComponent,
    HistogramaTiempoPiezaComponent,
    RecetasDetalleComponent,
    RecetasInduccionComponent,
    RecetasInyeccionComponent,
    ToleranciasTipoComponent,
    ToleranciasTipoDetalleComponent,
    InformeTempladoComponent,
    //InformesPersonalizadosComponent,
    CursosLectivosGruposComponent,
    CursosLectivosGruposTodosComponent,
    CursosLectivosTiposComponent,
    CursosLectivosTiposTodosComponent,
    InformePersonalizadoComponent,
    InformesPersonalizadosDisenadorComponent,
    CrearInformesPersonalizadosDisenadorComponent,
    EditarInformesPersonalizadosDisenadorComponent,
    GraficoTemperaturaComponent,
    GraficoPosicionComponent,
    GraficoNivelComponent,
    HMIPanelComponent,
    TurnosOperariosComponent,
    HometvComponent,
    Hometv_v2Component,
    AtributosComponent,
    EditarAtributosComponent,
    // CrearAtributosComponent,
    InformeProyectosComponent_v2,
    AnalisisRendimientoComponent,
    ClasificacionesComponent,
    // ClasificacionesCrearComponent,
    ClasificacionesEditarComponent,
    ContenedoresComponent,
    ContenedorDetalleComponent,
    InformeContenedoresComponent,
    InformeContenedorDetalleComponent,
    BloqueoRangosComponent,
    BloqueoRangosDetalleComponent,
    GruposPlanificadorComponent,
    AutomatizacionesComponent,
    AutomatizacionDetalleComponent,
    ChartGaugeComponent,
    SeguimientoOFsConfiguradorComponent,
    SeguimientoOFsConfiguradorDetalleComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'eu' },
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [UsuariosService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MyFunctions, MyFilter, MyCharts,
    MarcasService, HistoricoAlarmasService, HistoricoParametrosService, HistoricoMantenimientosService, ConsumiblesService, NotasService, HerramientasService,
    AlmacenesService, FabricantesService, PiezasPredefinidasService, MenuService, UsuariosTiposService, PlanesAccionService, RepercusionesService,
    ControlasignacionesService, AccionesService, ValidadorService, BonosService, SubcontratadoService, MaquinasService, PerdidasService, OFService, PiezasService,
    OperacionesService, OperacionesPredefinidasService, DiccionarioPerdidasService, MantenimientosPredefinidosService,
    ConfiguracionService, HistoricoMaquinasDatosService, HistoricoMaquinasDatosTemporalService, HistoricoMaquinasDatosTemporal2Service, ControlesService, InformeRendimientoService, RutasService, RutasPredefinidasService, BomService,
    GestionDocumentalService, InformeOeeService, PartesService, OperariosService, PlanificadorService, SeriesService,
    CentroMecanizadoService, HmiService, InformeComparativaRendimientoService, ComboService, InformeEjecucionesService,
    InformeConsumoService, TurnosService, HistoricoOperacionesService, DisponibilidadService, ResetAllModelsService,
    InformeProyectosService, PiezasTipoService, MaterialesTipoService, Eskola_AsignaturasRetosService, 
    CalendariosService, CalendarioService,  Eskola_CursosLectivosService, AlarmasTipoService, OperacionesTipoService, EskolaGruposService, 
    Eskola_HorariosSeccionesService, Eskola_AlumnosService, Eskola_OfsService,
    Eskola_OfsPiezasService,Eskola_MaquinasService, Eskola_ReservaMaquinasService, Eskola_InformeAlumnoComponent,
    PlantaIsometricoService, AreasProductivasService, InstalacionesService, HistoricoDatosService, IncidenciasService,
    NgxImageCompressService, ProgramasService, BoxplotService, AsignacionTiemposService, ClientesService, ActivosService,
    ProveedoresService, HistogramaTiemposPiezaService, RecetasService, ToleranciasTipoService, CursosLectivosGruposService, CursosLectivosTiposService, InformePersonalizadoService, InformesPersonalizadosDisenadorService,
      RecetasInduccionService, AtributosService, ClasificacionesService, ContenedoresService, BloqueoRangosService, AutomatizacionesService, SeguimientoOFsService, SubidaFicherosService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

