import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService, AlertService, CalendariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from './../_helpers/myfunctions';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';

export const DAYS_OF_WEEK = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

@Component({
  selector: 'app-calendario',
  templateUrl: 'calendario.component.html',
})
export class CalendarioComponent implements OnInit {
  user = this.usuariosService.userValue;
  navigationSubscription;
  private translate: TranslateService;
  public monthsInYearFila = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  public year;
  public DaysInMonths;
  public DisabledDatesArray;
  public periodoActualSeleccionado = [];
  public listadoPeriodosInactivos;
  public listadoPeriodosActivos;
  public listadoReservas;
  public listadoPeriodosAnoActual = [];
  public nuevosPeriodos = [];
  public periodosParaEliminar = [];
  guardar:boolean= false;

  @Output() diasInactivos: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() diasActivos: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() yearEnviado: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() nombreVacio: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fechasVacias: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() lunes = false; // to receive when lunes checkbox changes in parent
  @Input() martes = false; // to receive when martes checkbox changes in parent
  @Input() miercoles = false; // to receive when miercoles checkbox changes in parent
  @Input() jueves = false; // to receive when jueves checkbox changes in parent
  @Input() viernes = false; // to receive when viernes checkbox changes in parent
  @Input() sabado = false; // to receive when sabado checkbox changes in parent
  @Input() domingo = false; // to receive when domingo checkbox changes in parent
  @Input() idCalendario = -1; // to receive when idCalendario is changed in parent
  @Input() calendarioInicio: Date; // to receive when domingo checkbox changes in parent
  @Input() calendarioFin: Date; // to receive when idCalendario is changed in parent
  @Input() nombre: string =""; // to receive when nombre is changed in parent
  @Input() calendario:JSON = undefined; // to receive when calendario is changed in parent
  @Input() activo: boolean = false; // to receive when activo is changed in parent
  @Input() ultimoId: number = -1; // to receive when ultimoId is changed in parent



  opened = false;
  mensajeError = '';

  constructor(private eskolaCalendariosService: CalendariosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService, private modalService: NgbModal,
    public myFunctions: MyFunctions, public alertService: AlertService, private eRef: ElementRef) {
    this.translate = translateService;
    this.year = (new Date()).getFullYear();
    this.cargarMeses();
    this.inicializarArraysFechasDisabled();

    //this.inicializarDiasConReservasEnEseAno(this.year, this.codcomedor, this.codcomida);
    this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
  }

  ngOnInit(): void {
    
   
  }

  cargarMeses() {
    this.DaysInMonths = [];
    // Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el primero del mes
    for (let i = 0; i < 12; i++) {
      this.DaysInMonths.push(new Date(this.year, i, 1));
    }
  }

  inicializarArraysFechasDisabled() {
    // Necesitamos crear un array de arrays en el que guardar los arrays de fechas disabled de cada calendario
    this.DisabledDatesArray = [];
    for (let i = 0; i < 12; i++) {
      this.DisabledDatesArray.push([]); // Agregamos los arrays vacíos de los meses
    }
  }

  calcularAfectados(fecha1, fecha2, miArray) {
    let lista_de_afectados = [];
    let lista_de_intactos = [];
    miArray.forEach(element => {
      if (element[0] <= fecha2 && element[1] >= fecha1) {
        lista_de_afectados.push([new Date(element[0]), new Date(element[1])]);
      }
      else {
        lista_de_intactos.push([new Date(element[0]), new Date(element[1])]);
      }
    });
    return { afectados: lista_de_afectados, intactos: lista_de_intactos };
  }

  hacer_cambios(afectados_activos, afectados_inactivos, fecha1, fecha2, es_activo) {

    if (es_activo) {
      // Agregar en activos y despues recortar inactivos
      afectados_activos.push([new Date(fecha1), new Date(fecha2)]);
      let aux_afectados_inactivos = [];
      afectados_inactivos.forEach(element => {
        if (fecha1 <= element[0] && fecha2 >= element[1]) {
          console.log('No hacemos nada');
        }
        if (fecha1 > element[0] && fecha2 >= element[1]) {
          let auxi = new Date(fecha1);
          auxi.setDate(auxi.getDate() - 1);
          aux_afectados_inactivos.push([new Date(element[0]), auxi]);
        }
        if (fecha1 > element[0] && fecha2 < element[1]) {
          let auxi = new Date(fecha1);
          auxi.setDate(auxi.getDate() - 1);
          let auxi2 = new Date(fecha2);
          auxi2.setDate(auxi2.getDate() + 1);
          aux_afectados_inactivos.push([new Date(element[0]), auxi]);
          aux_afectados_inactivos.push([auxi2, new Date(element[1])]);
        }
        if (fecha1 <= element[0] && fecha2 < element[1]) {
          let auxi = new Date(fecha2);
          auxi.setDate(auxi.getDate() + 1);
          aux_afectados_inactivos.push([auxi, new Date(element[1])]);
        }
      });
      return { afectados_activos, afectados_inactivos: aux_afectados_inactivos };
    }
    else { // inactivo
      // Agregar en inactivos y despues recortar activos
      afectados_inactivos.push([fecha1, fecha2]);
      let aux_afectados_activos = [];
      afectados_activos.forEach(element => {
        if (fecha1 <= element[0] && fecha2 >= element[1]) {
          console.log('No hacemos nada');
        }
        if (fecha1 > element[0] && fecha2 >= element[1]) {
          let auxi = new Date(fecha1);
          auxi.setDate(auxi.getDate() - 1);
          aux_afectados_activos.push([new Date(element[0]), auxi]);
        }
        if (fecha1 > element[0] && fecha2 < element[1]) {
          let auxi = new Date(fecha1);
          auxi.setDate(auxi.getDate() - 1);
          aux_afectados_activos.push([new Date(element[0]), auxi]);
          let auxi2 = new Date(fecha2);
          auxi2.setDate(auxi2.getDate() + 1);
          aux_afectados_activos.push([auxi2, new Date(element[1])]);
        }
        if (fecha1 <= element[0] && fecha2 < element[1]) {
          let auxi = new Date(fecha2);
          auxi.setDate(auxi.getDate() + 1);
          aux_afectados_activos.push([auxi, new Date(element[1])]);
        }
      });
      return { afectados_activos: aux_afectados_activos, afectados_inactivos };
    }
  }
/*
  inicializarPeriodosEnEseAno(year, idCalendario) {
    this.listadoPeriodosInactivos = [];
    this.listadoPeriodosActivos = [];
    this.eskolaCalendariosService.getPeriodosAnoActual(year, idCalendario).subscribe((result: any) => {
      if (result.length > 0) {
        this.listadoPeriodosAnoActual = result;
        result.forEach(element => {
          // Creamos y limpiamos las fechas
          let auxFecha1 = element.fechainicio.split('T')[0].split('-');
          let auxFecha2 = element.fechafin.split('T')[0].split('-');
          let fecha1Converted = new Date(auxFecha1[0], auxFecha1[1] - 1, auxFecha1[2]);
          let fecha2Converted = new Date(auxFecha2[0], auxFecha2[1] - 1, auxFecha2[2]);
          // Detectamos los afectados por cada elemento
          let afectados_activos_aux = this.calcularAfectados(fecha1Converted, fecha2Converted, this.listadoPeriodosActivos);
          let afectados_en_activos = afectados_activos_aux.afectados;
          let intactos_en_activos = afectados_activos_aux.intactos;
          let afectados_inactivos_aux = this.calcularAfectados(fecha1Converted, fecha2Converted, this.listadoPeriodosInactivos);
          let afectados_en_inactivos = afectados_inactivos_aux.afectados;
          let intactos_en_inactivos = afectados_inactivos_aux.intactos;

          // Calculamos los cambios
          let auxi_hacer_cambios = this.hacer_cambios(afectados_en_activos, afectados_en_inactivos, fecha1Converted, fecha2Converted, element.activo);
          let afectados_en_activos_nuevo = auxi_hacer_cambios.afectados_activos;
          let afectados_en_inactivos_nuevo = auxi_hacer_cambios.afectados_inactivos;

          // Actualizamos los arrays
          this.listadoPeriodosActivos = afectados_en_activos_nuevo.concat(intactos_en_activos);
          this.listadoPeriodosInactivos = afectados_en_inactivos_nuevo.concat(intactos_en_inactivos);
        });
      }
      this.diasInactivos.emit(this.contarDiasInactivos());
      if (this.leapyear()) {
        this.diasActivos.emit(366 - this.contarDiasInactivos());
      }
      else {
        this.diasActivos.emit(365 - this.contarDiasInactivos());
      }
    });
  }
*/
inicializarPeriodosEnEseAno(year, idCalendario) {

  this.listadoPeriodosInactivos = [];
  this.listadoPeriodosActivos = [];
  this.eskolaCalendariosService.getPeriodosAnoActual(year, idCalendario).subscribe((result: any) => {
 
      this.listadoPeriodosAnoActual = result;
      console.log("----------NUEVOS PERIODOS---------");
      console.log(this.nuevosPeriodos);
      this.nuevosPeriodos.forEach(element => {
        if(element.ano ==year){
        this.listadoPeriodosAnoActual.push(element);
        result.push(element);
        }
      });
      this.periodosParaEliminar.forEach(element => {
        let index = this.listadoPeriodosAnoActual.findIndex(p=>p.id===element.id);
        this.listadoPeriodosActivos.splice(index,1);
        result.splice(index,1);
      });
   
      result.forEach(element => {
        // Creamos y limpiamos las fechas
        let auxFecha1 = element.fechainicio.split('T')[0].split('-');
        let auxFecha2 = element.fechafin.split('T')[0].split('-');
        let fecha1Converted = new Date(auxFecha1[0], auxFecha1[1] - 1, auxFecha1[2]);
        let fecha2Converted = new Date(auxFecha2[0], auxFecha2[1] - 1, auxFecha2[2]);
        // Detectamos los afectados por cada elemento
        let afectados_activos_aux = this.calcularAfectados(fecha1Converted, fecha2Converted, this.listadoPeriodosActivos);
        let afectados_en_activos = afectados_activos_aux.afectados;
        let intactos_en_activos = afectados_activos_aux.intactos;
        let afectados_inactivos_aux = this.calcularAfectados(fecha1Converted, fecha2Converted, this.listadoPeriodosInactivos);
        let afectados_en_inactivos = afectados_inactivos_aux.afectados;
        let intactos_en_inactivos = afectados_inactivos_aux.intactos;

        // Calculamos los cambios
        let auxi_hacer_cambios = this.hacer_cambios(afectados_en_activos, afectados_en_inactivos, fecha1Converted, fecha2Converted, element.activo);
        let afectados_en_activos_nuevo = auxi_hacer_cambios.afectados_activos;
        let afectados_en_inactivos_nuevo = auxi_hacer_cambios.afectados_inactivos;

        // Actualizamos los arrays
        this.listadoPeriodosActivos = afectados_en_activos_nuevo.concat(intactos_en_activos);
        this.listadoPeriodosInactivos = afectados_en_inactivos_nuevo.concat(intactos_en_inactivos);
      });
   
    this.diasInactivos.emit(this.contarDiasInactivos());
    if (this.leapyear()) {
      this.diasActivos.emit(366 - this.contarDiasInactivos());
    }
    else {
      this.diasActivos.emit(365 - this.contarDiasInactivos());
    }
  });
}

  fechasIguales(fecha1, fecha2) {
    if (fecha1.getDate() == fecha2.getDate() && fecha1.getMonth() == fecha2.getMonth() && fecha1.getFullYear() == fecha2.getFullYear()) {
      return true;
    }
    else {
      return false;
    }
  }

  public isDateDisabled(date: Date, month): boolean {
    if (date.getFullYear() == this.year) {
      if (this.DisabledDatesArray[month].includes(date.getDate())) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public esReserva(date: Date): boolean {
    if (this.listadoReservas == undefined || this.listadoReservas.length == 0) { return false; }
    else if (date.getFullYear() == this.year) {
      let auxFechaActual = new Date(date.getFullYear(), date.getMonth(), date.getDate()); // limpiamos la fecha
      let encontrado = false;
      let element;
      let fechaListado;
      for (let i = 0; i < this.listadoReservas.length && !encontrado; i++) {
        element = this.listadoReservas[i];
        fechaListado = new Date(element.getFullYear(), element.getMonth(), element.getDate()); // limpiamos la fecha
        if (auxFechaActual.getDate() == fechaListado.getDate() && auxFechaActual.getMonth() == fechaListado.getMonth() && auxFechaActual.getFullYear() == fechaListado.getFullYear()) {
          encontrado = true;
        }
      }
      if (encontrado) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public isDateInPeriod(date: Date, month): boolean {
    if (this.periodoActualSeleccionado.length == 0) {
      return false;
    } else if (this.periodoActualSeleccionado.length == 1) {
      if ((date.getDate() == this.periodoActualSeleccionado[0].getDate() &&
        date.getMonth() == this.periodoActualSeleccionado[0].getMonth() &&
        date.getFullYear() == this.periodoActualSeleccionado[0].getFullYear())) {
        return true;
      } else {
        return false;
      }
    }
    else if (this.periodoActualSeleccionado.length == 2) {
      // primero hacemos limpia con las fechas por si se queda basura o cambia el gtm
      let auxFecha1 = new Date(this.periodoActualSeleccionado[0].getFullYear(), this.periodoActualSeleccionado[0].getMonth(), this.periodoActualSeleccionado[0].getDate());
      let auxFecha2 = new Date(this.periodoActualSeleccionado[1].getFullYear(), this.periodoActualSeleccionado[1].getMonth(), this.periodoActualSeleccionado[1].getDate());
      let auxFechaActual = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      if (auxFechaActual >= auxFecha1 && auxFechaActual <= auxFecha2) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  public isDateInInactive(date: Date): boolean {
    if (this.listadoPeriodosInactivos.length == 0) {
      return false;
    } else {
      let element;
      let encontrado = false;
      for (let i = 0; i < this.listadoPeriodosInactivos.length; i++) {
        element = this.listadoPeriodosInactivos[i];
        // primero hacemos limpia con las fechas por si se queda basura o cambia el gtm
        let auxFecha1 = new Date(element[0].getFullYear(), element[0].getMonth(), element[0].getDate());
        let auxFecha2 = new Date(element[1].getFullYear(), element[1].getMonth(), element[1].getDate());
        let auxFechaActual = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (auxFechaActual >= auxFecha1 && auxFechaActual <= auxFecha2) {
          encontrado = true;
        }
      }
      return encontrado;
    }
  }

  public isDateInActive(date: Date): boolean {
    if (this.listadoPeriodosActivos.length == 0) {
      return false;
    } else {
      let element;
      let encontrado = false;
      for (let i = 0; i < this.listadoPeriodosActivos.length; i++) {
        element = this.listadoPeriodosActivos[i];
        // primero hacemos limpia con las fechas por si se queda basura o cambia el gtm
        let auxFecha1 = new Date(element[0].getFullYear(), element[0].getMonth(), element[0].getDate());
        let auxFecha2 = new Date(element[1].getFullYear(), element[1].getMonth(), element[1].getDate());
        let auxFechaActual = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (auxFechaActual >= auxFecha1 && auxFechaActual <= auxFecha2) {
          encontrado = true;
        }
      }
      return encontrado;
    }
  }

  public getFocusedDate(month) {
    return new Date(this.year, month, 1);
  }

  public valueClickCalendar(month, event) {
    if (event.target.classList.contains('calendario-periodoSeleccionado') && this.periodoActualSeleccionado.length == 1) {// se ha vuelto a seleccionar en el periodo, deseleccionar
      this.periodoActualSeleccionado = [];
    } else {
      if (this.periodoActualSeleccionado.length == 0) {
        this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
      }
      else if (this.periodoActualSeleccionado.length == 1) {
        // mirar si se han clickado en orden o en orden inverso
        // limpiar fechas, just in case
        let auxFecha1 = new Date(this.periodoActualSeleccionado[0].getFullYear(), this.periodoActualSeleccionado[0].getMonth(), this.periodoActualSeleccionado[0].getDate());
        let auxFechaActual = new Date(this.DaysInMonths[month].getFullYear(), this.DaysInMonths[month].getMonth(), this.DaysInMonths[month].getDate());
        if (auxFecha1 <= auxFechaActual) {
          this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
        }
        else {
          this.periodoActualSeleccionado = [];
          this.periodoActualSeleccionado.push(auxFechaActual);
          this.periodoActualSeleccionado.push(auxFecha1);
        }
      } else {
        this.periodoActualSeleccionado = []; // hay que vaciarlo primero, pues si ya tenía dos es un periodo
        this.periodoActualSeleccionado.push(this.DaysInMonths[month]);
      }
    }

  }

  public previousYear() {
    this.year -= 1;
    this.cargarMeses();
    this.inicializarArraysFechasDisabled();
    this.revisarDiasDeshabilitados(this.lunes, this.martes, this.miercoles, this.jueves, this.viernes, this.sabado, this.domingo);
    //this.inicializarDiasConReservasEnEseAno(this.year, this.codcomedor, this.codcomida);
    this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
    this.yearEnviado.emit(this.year);

  }

  public nextYear() {
    this.year += 1;
    this.cargarMeses();
    this.inicializarArraysFechasDisabled();
    this.revisarDiasDeshabilitados(this.lunes, this.martes, this.miercoles, this.jueves, this.viernes, this.sabado, this.domingo);
    // this.inicializarDiasConReservasEnEseAno(this.year, this.codcomedor, this.codcomida);
    this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
    this.yearEnviado.emit(this.year);

  }
  
    ngOnChanges(changes: SimpleChanges) {
      this.revisarDiasDeshabilitados(changes.lunes != undefined ? changes.lunes.currentValue : null,
        changes.martes != undefined ? changes.martes.currentValue : null,
        changes.miercoles != undefined ? changes.miercoles.currentValue : null,
        changes.jueves != undefined ? changes.jueves.currentValue : null,
        changes.viernes != undefined ? changes.viernes.currentValue : null,
        changes.sabado != undefined ? changes.sabado.currentValue : null,
        changes.domingo != undefined ? changes.domingo.currentValue : null);
     // this.inicializarDiasConReservasEnEseAno(this.year, this.codcomedor, this.codcomida);
      this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
      this.yearEnviado.emit(this.year);
  
    }
  
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.target.id == 'periodoInactivo') {
      if (this.periodoActualSeleccionado.length == 1) {
        // primero hay que duplicar la de inicio para que sea de inicio y fin en este caso
 
       if(this.periodoActualSeleccionado[0]>=this.calendarioInicio && this.periodoActualSeleccionado[0]<=this.calendarioFin){ 
          let ano, mes, dia, auxiFechaInicio;
          ano = this.periodoActualSeleccionado[0].getFullYear();
          mes = this.periodoActualSeleccionado[0].getMonth() + 1;
          dia = this.periodoActualSeleccionado[0].getDate();
          auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
          this.nuevosPeriodos.push({id:Math.random(), id_calendario: this.idCalendario, ano: this.year, fechainicio: auxiFechaInicio, fechafin:auxiFechaInicio, activo:false});
          this.periodoActualSeleccionado=[]; // y vaciamos el periodo
          this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
            /*/
            this.eskolaCalendariosService.insertEskolaCalendarioPeriodos(this.idCalendario, this.year,
              this.periodoActualSeleccionado[0], this.periodoActualSeleccionado[1], false).subscribe((result: any) => {
                this.periodoActualSeleccionado = []; // y vaciamos el periodo
                this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
              });*/   
      
      }

      }
      else if (this.periodoActualSeleccionado.length == 2) {
        let ano, mes, dia, auxiFechaInicio, auxiFechaFin;
          ano = this.periodoActualSeleccionado[0].getFullYear();
          mes = this.periodoActualSeleccionado[0].getMonth() + 1;
          dia = this.periodoActualSeleccionado[0].getDate();
          auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
          ano = this.periodoActualSeleccionado[1].getFullYear();
          mes = this.periodoActualSeleccionado[1].getMonth() + 1;
          dia = this.periodoActualSeleccionado[1].getDate();
          auxiFechaFin = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
        if(this.periodoActualSeleccionado[0]>=this.calendarioInicio && this.periodoActualSeleccionado[1]<=this.calendarioFin){ 
            if (this.periodoActualSeleccionado[1] > this.periodoActualSeleccionado[0]) { // mirar qué fecha debe ir primero, just in case
              this.nuevosPeriodos.push({id:Math.random(), id_calendario: this.idCalendario, ano: this.year, fechainicio: auxiFechaInicio, fechafin:auxiFechaFin, activo:false});
              this.periodoActualSeleccionado=[]; // y vaciamos el periodo
              this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
             
            }
            else {
              this.nuevosPeriodos.push({id:Math.random(), id_calendario: this.idCalendario, ano: this.year, fechainicio: auxiFechaFin, fechafin:auxiFechaInicio, activo:false});
              this.periodoActualSeleccionado=[]; // y vaciamos el periodo
              this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
            }           
          }
        
      }
    }
    if (event.target.id == 'periodoActivo') {
      if (this.periodoActualSeleccionado.length == 1) {
        if(this.periodoActualSeleccionado[0]>=this.calendarioInicio && this.periodoActualSeleccionado[0]<=this.calendarioFin){ 
          let ano, mes, dia, auxiFechaInicio;
          ano = this.periodoActualSeleccionado[0].getFullYear();
          mes = this.periodoActualSeleccionado[0].getMonth() + 1;
          dia = this.periodoActualSeleccionado[0].getDate();
          auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
          this.nuevosPeriodos.push({id:Math.random(), id_calendario: this.idCalendario, ano: this.year, fechainicio: auxiFechaInicio, fechafin:auxiFechaInicio, activo:true});
          this.periodoActualSeleccionado=[]; // y vaciamos el periodo
          this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
     
      }
      }
      else if (this.periodoActualSeleccionado.length == 2) {
        let ano, mes, dia, auxiFechaInicio, auxiFechaFin;
        ano = this.periodoActualSeleccionado[0].getFullYear();
        mes = this.periodoActualSeleccionado[0].getMonth() + 1;
        dia = this.periodoActualSeleccionado[0].getDate();
        auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
        ano = this.periodoActualSeleccionado[1].getFullYear();
        mes = this.periodoActualSeleccionado[1].getMonth() + 1;
        dia = this.periodoActualSeleccionado[1].getDate();
        auxiFechaFin = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
          if(this.periodoActualSeleccionado[0]>=this.calendarioInicio && this.periodoActualSeleccionado[1]<=this.calendarioFin){        
        if (this.periodoActualSeleccionado[1] > this.periodoActualSeleccionado[0]) { // mirar qué fecha debe ir primero, just in case
          this.nuevosPeriodos.push({id:Math.random(), id_calendario: this.idCalendario, ano: this.year, fechainicio: auxiFechaInicio, fechafin:auxiFechaFin, activo:true});
          this.periodoActualSeleccionado=[]; // y vaciamos el periodo
          this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
        }
        else {
          this.nuevosPeriodos.push({id:Math.random(), id_calendario: this.idCalendario, ano: this.year, fechainicio: auxiFechaFin, fechafin:auxiFechaInicio, activo:true});
              this.periodoActualSeleccionado=[]; // y vaciamos el periodo
              this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
        }
      }
      }
    }
    if (event.target.id == 'eliminarPeriodo') {
      if (this.periodoActualSeleccionado.length == 1) {
        // primero hay que duplicar la de inicio para que sea de inicio y fin en este caso
        this.periodoActualSeleccionado.push(this.periodoActualSeleccionado[0]);
      }
      if (this.periodoActualSeleccionado.length == 2) {
        // Primero se toman las fechas y se limpian
        let auxPeriodo1 = new Date(this.periodoActualSeleccionado[0].getFullYear(), this.periodoActualSeleccionado[0].getMonth(), this.periodoActualSeleccionado[0].getDate());
        let auxPeriodo2 = new Date(this.periodoActualSeleccionado[1].getFullYear(), this.periodoActualSeleccionado[1].getMonth(), this.periodoActualSeleccionado[1].getDate());
        if(auxPeriodo1>=this.calendarioInicio && auxPeriodo2<=this.calendarioFin){        
        
        // Ahora hay que buscar los periodos que intersecten con esos días, para encontrar sus códigos y eliminarlos
        this.listadoPeriodosAnoActual.forEach((element) => {
          let auxFecha1 = element.fechainicio.split('T')[0].split('-');
          let auxFecha2 = element.fechafin.split('T')[0].split('-');
          let fecha1Converted = new Date(auxFecha1[0], auxFecha1[1] - 1, auxFecha1[2]);
          let fecha2Converted = new Date(auxFecha2[0], auxFecha2[1] - 1, auxFecha2[2]);
          if (fecha1Converted <= auxPeriodo2 && fecha2Converted >= auxPeriodo1) {
            let index = this.nuevosPeriodos.findIndex(d =>d.id === element.id);
            if(index!=-1){
            this.nuevosPeriodos.splice(index,1);
            this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
          }else{        
            this.periodosParaEliminar.push(element);
            this.inicializarPeriodosEnEseAno(this.year, this.idCalendario);
          
          }
          }
        });
        this.periodoActualSeleccionado = []; // y vaciamos el periodo
      }
      }
    }else if(event.target.id == 'cancelarButton'){
      this.router.navigate(['../calendarios']);
    }
   if(event.target.id == 'guardarButton'){
    debugger;
    console.log(this.calendarioInicio);
    console.log(this.calendarioFin)
    console.log(this.nombre);
    if(this.nombre=="" || this.nombre ==undefined){
      this.nombreVacio.emit(true);
      this.guardar=false;
    }else{
      this.nombreVacio.emit(false);
    }
    if(this.calendarioInicio==undefined|| this.calendarioFin==undefined){
      this.fechasVacias.emit(true);
      this.guardar=false;
    }else{
      this.fechasVacias.emit(false);
    }
    if(this.nombre!="" && this.nombre !=undefined && this.calendarioInicio!=undefined&& this.calendarioFin!=undefined){
      this.guardar=true;
    }
    if(this.guardar){
      this.nombreVacio.emit(false);
      this.fechasVacias.emit(false);
    
   
    if(this.idCalendario!=-1 && this.idCalendario!=undefined){
      //actualizamos el clandario
      this.eskolaCalendariosService.updateEskolaCalendario(this.idCalendario, this.nombre, this.calendarioInicio, this.calendarioFin, this.activo, this.lunes, this.martes,
      this.miercoles, this.jueves, this.viernes, this.sabado, this.domingo).subscribe((result: any) => {
        if(result.error){
          this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
          this.router.navigate(['../calendarios']);  
        }
      });
      //insertamos los nuevos periodos
      debugger;
      if(this.nuevosPeriodos.length>0){
        console.log("guardar button");
        console.log(this.nuevosPeriodos);
      this.nuevosPeriodos.forEach((nuevoPeriodo)=>{
        debugger;
        this.eskolaCalendariosService.insertEskolaCalendarioPeriodos(nuevoPeriodo.id_calendario, nuevoPeriodo.ano, nuevoPeriodo.fechainicio, 
        nuevoPeriodo.fechafin,nuevoPeriodo.activo).subscribe((result:any)=>{
          if(result.error){
            this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
            this.router.navigate(['../calendarios']); 
            
          }
        });
      }); }

      //eliminamos los periodos
      if(this.periodosParaEliminar.length>0){
      this.periodosParaEliminar.forEach((p)=>{
        this.eskolaCalendariosService.deleteEskolaCalendariosPeriodo(p.id).subscribe((result:any)=>{
          if(result.error){
            this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
            this.router.navigate(['../calendarios']);  
          }
        });
      });
    

    }
          //buscamos los periodos que se queden fuera de las nuevas fechas de inicio y fin y los eliminamos
        this.eskolaCalendariosService.getPeriodosDeCalendario(this.idCalendario).subscribe((result:any)=>{
          result.forEach(element => {
            let auxFecha1 = element.fechainicio.split('T')[0].split('-');
            let auxFecha2 = element.fechafin.split('T')[0].split('-');
            let fecha1Converted = new Date(auxFecha1[0], auxFecha1[1] - 1, auxFecha1[2]);
            let fecha2Converted = new Date(auxFecha2[0], auxFecha2[1] - 1, auxFecha2[2]);
            if(fecha1Converted<this.calendarioInicio || fecha2Converted> this.calendarioFin){
              this.eskolaCalendariosService.deleteEskolaCalendariosPeriodo(element.id).subscribe((result:any)=>{
                if(result.error){
                  this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                  this.router.navigate(['../calendarios']);  
                }
              });
            }
          });
        })
    this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
    this.router.navigate(['../calendarios']);  
    }else{
      this.eskolaCalendariosService.insertEskolaCalendario(false,this.nombre, this.calendarioInicio, 
        this.calendarioFin, this.activo, this.lunes, this.martes,this.miercoles, this.jueves, this.viernes, this.sabado, this.domingo).subscribe((result: any) => {
          if (result.error==false){
            this.nuevosPeriodos.forEach((nuevoPeriodo)=>{
              debugger;
              this.eskolaCalendariosService.insertEskolaCalendarioPeriodos(result.id, nuevoPeriodo.ano, nuevoPeriodo.fechainicio,  nuevoPeriodo.fechafin,nuevoPeriodo.activo).subscribe((result:any)=>{
                  if(result.error){
                    this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                    this.router.navigate(['../calendarios']); 
                    
                  }else{
                    this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                    this.router.navigate(['../calendarios/editar/'+Number(this.ultimoId+1)]);
                  }
              }); 
            });              
          }else{
            this.alertService.error(result.description);
          }
        });
    }
  }
  }

  
  }

  buscarElemento(miArray, elemento) {
    let encontrado = false;
    miArray.forEach(element => {
      if (this.fechasIguales(element[0], elemento[0]) && this.fechasIguales(element[1], elemento[1])) {
        encontrado = true;
      }
    });
    return encontrado;
  }

  buscarElemento2(miArray, elemento) {
    let encontrado = false;
    miArray.forEach(element => {
      if (this.fechasIguales(element, elemento)) {
        encontrado = true;
      }
    });
    return encontrado;
  }

  contarDiasInactivos() {
    let count = 0;
    for (let i = 0; i < 12; i++) {
      count += this.DisabledDatesArray[i].length;
    }
    let element;
    let contadosArray = [];
    for (let i = 0; i < this.listadoPeriodosInactivos.length; i++) {
      element = this.listadoPeriodosInactivos[i];
      if (!this.buscarElemento(contadosArray, element)) {
        contadosArray.push(element);
        let auxi = new Date(element[0]);
        let auxi2 = new Date(element[1]);
        count++; // contamos un día para los casos en los que solo hay un día
        while (!this.fechasIguales(auxi, auxi2)) {
          count++;
          auxi.setDate(auxi.getDate() + 1);
        }
      }
    }
    return count;
  }

  revisarDiasDeshabilitados(lunes, martes, miercoles, jueves, viernes, sabado, domingo) {
    if (lunes != null && !lunes) {
      this.desactivarLunes();
    } else if (lunes != null && lunes) {
      this.activarLunes();
    }
    if (martes != null && !martes) {
      this.desactivarMartes();
    } else if (martes != null && martes) {
      this.activarMartes();
    }
    if (miercoles != null && !miercoles) {
      this.desactivarMiercoles();
    } else if (miercoles != null && miercoles) {
      this.activarMiercoles();
    }
    if (jueves != null && !jueves) {
      this.desactivarJueves();
    } else if (jueves != null && jueves) {
      this.activarJueves();
    }
    if (viernes != null && !viernes) {
      this.desactivarViernes();
    } else if (viernes != null && viernes) {
      this.activarViernes();
    }
    if (sabado != null && !sabado) {
      this.desactivarSabado();
    } else if (sabado != null && sabado) {
      this.activarSabado();
    }
    if (domingo != null && !domingo) {
      this.desactivarDomingo();
    } else if (domingo != null && domingo) {
      this.activarDomingo();
    }

  }

  // Functions to activate, inactivate days
  desactivarLunes() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getMondaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarLunes() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getMondaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  desactivarMartes() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getTuesdaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarMartes() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getTuesdaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  desactivarMiercoles() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getWednesdaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarMiercoles() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getWednesdaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  desactivarJueves() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getThursdaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarJueves() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getThursdaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  desactivarViernes() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getFridaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarViernes() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getFridaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  desactivarSabado() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getSaturdaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarSabado() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getSaturdaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  desactivarDomingo() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que agregarles los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getSundaysMonth(i);
      auxi.forEach(element => {
        this.DisabledDatesArray[i].push(element);
      });
    }
  }

  activarDomingo() {
    for (let i = 0; i < 12; i++) { // por cada mes, hay que eliminar del array los lunes
      // Primero hay que obtener los lunes de ese mes
      let auxi = this.getSundaysMonth(i);
      auxi.forEach(element => {
        this.deleteFromArrayDeshabilitadas(element, i);
      });
    }
  }

  // Functions to obtains days in month
  getMondaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 1) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getTuesdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 2) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getWednesdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 3) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getThursdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 4) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getFridaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 5) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getSaturdaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 6) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  getSundaysMonth(month) {
    let result = [];
    let firstDay = new Date(this.year, month, 1); // empezaremos buscando desde el día 1
    let lastDay = new Date(this.year, month + 1, 0); // este será el último día
    let noEncontrado = false;
    while (!noEncontrado) {// mientras no hayamos llegado al último día, seguimos buscando
      if (firstDay.getDay() == 0) { // Necesitamos hacer esto antes de encontrarlo por si el último día es lunes
        result.push(firstDay.getDate());
      }
      if (firstDay.getDate() == lastDay.getDate()) {
        noEncontrado = true;
      }
      firstDay.setDate(firstDay.getDate() + 1);
    }
    return result;
  }

  // Auxiliar function to delete from array
  deleteFromArrayDeshabilitadas(elem, month) {
    const index: number = this.DisabledDatesArray[month].indexOf(elem);
    if (index !== -1) {
      this.DisabledDatesArray[month].splice(index, 1);
    }
  }

  // function to know if is a leap year
  // Every year that is exactly divisible by four is a leap year,
  // except for years that are exactly divisible by 100, but these centurial years are
  // leap years if they are exactly divisible by 400
  leapyear() {
    return ((this.year % 4 == 0) && (this.year % 100 != 0)) || (this.year % 400 == 0);
  }

  close() {
    this.opened = false;
  }
  onClickGuardar(){

  };
  onClickCancelar(){
    this.router.navigate(['../calendarios']);
  }
  addZero(n: number) {
    if (n < 10) {
      return '0' + n.toString();
    }
    else {
      return n.toString();
    }
  }


}
