<div class="row">
  <!-- MENU LATERAL -->
  <div class="col-sm-4 col-lg-3 col-xl-2 barra-izq-oee">
    <!-- INFORMES -->
    <div class="clearfix">

      <div class="card">
        <div class="card-header">
          <h3>
            <label ID="lInformes" class="">{{ 'informes' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!--BOTON OEE-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeOEE']">
              <input type="radio" name="informesGroup" id="btnOee" class="k-radio" checked="checked" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnOee">{{ 'oee' | translate }}</label>
            </a>
          </div>
          <!--BOTON Disponibilidad-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeDisponibilidadOEE']">
              <input type="radio" name="informesGroup" id="btnDisponibilidad" class="k-radio" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnDisponibilidad">{{ 'disponibilidad' | translate }}</label>
            </a>
          </div>
          <!--BOTON Rendimiento-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeRendimientoOEE']">
              <input type="radio" name="informesGroup" id="btnRendimiento" class="k-radio" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnRendimiento">{{ 'rendimiento' | translate }}</label>
            </a>
          </div>
          <!--BOTON Calidad-->
          <div class="clearfix">
            <a class="link-radio-boton " [routerLink]="['/informeCalidadOEE']">
              <input type="radio" name="informesGroup" id="btnCalidad" class="k-radio" kendoRadioButton>
              <!--disabled="true" para control + click-->
              <label class="k-radio-label" for="btnCalidad">{{ 'calidad' | translate }}</label>
            </a>
          </div>
        </div>
      </div>

    </div>
    <!-- TURNOS -->
    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <h3>
            <label ID="lTurnos" class="">{{ 'turnos' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!-- TODOS -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnTodos" class="k-radio" checked="checked"
                (click)="btnTodos_Click($event)" kendoRadioButton>
              <label class="k-radio-label" for="btnTodos">{{ 'todos' | translate }}</label>
            </a>
          </div>
          <!-- MAÑANA -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnMañana" class="k-radio" (click)="btnManana_Click($event)"
                kendoRadioButton>
              <label class="k-radio-label" for="btnMañana">{{ 'manana' | translate }}</label>
            </a>
          </div>
          <!-- TARDE -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnTarde" class="k-radio" (click)="btnTarde_Click($event)"
                kendoRadioButton>
              <label class="k-radio-label" for="btnTarde">{{ 'tarde' | translate }}</label>
            </a>
          </div>
          <!-- NOCHE -->
          <div class="clearfix">
            <a class="link-radio-boton ">
              <input type="radio" name="turnosGroup" id="btnNoche" class="k-radio" (click)="btnNoche_Click($event)"
                kendoRadioButton>
              <label class="k-radio-label" for="btnNoche">{{ 'noche' | translate }}</label>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- FILTRO NUEVO -->
    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <h3>
            <label ID="lTurnos" class="">{{ 'filtro' | translate}}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!-- FECHA -->
          <div class="calendarFiltro">
            <label>{{ 'fechas' | translate }}</label>
            <div class="calendarFiltro-input" (click)="showCalendar()">
              <label *ngIf="dpFechaIni != undefined && dpFechaFin == undefined">{{dpFechaIni | kendoDate}}</label>
              <label *ngIf="dpFechaFin != undefined && dpFechaFin != undefined">{{dpFechaIni | kendoDate}} -
                {{dpFechaFin | kendoDate}}</label>
              <i class="k-icon k-i-calendar"></i>
            </div>

            <!-- CALENDARIO EXPANDIDO -->
            <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;"
              [ngClass]="mostrarCalendario1 ? 'mostrarCalendario' : 'ocultarCalendario'">
              <div class="row">
                <div class="col-mes">
                  <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="infinite"
                    (click)="valueClickCalendar(0, $event)">
                    <ng-template kendoCalendarMonthCellTemplate let-date>
                      <span class="calendarFiltro-calendario-contenido-dia"
                        [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(date)"> {{ date.getDate() }}
                      </span>
                    </ng-template>
                    <ng-template kendoCalendarHeaderTitleTemplate let-title>
                      <div> {{ title }} </div>
                    </ng-template>
                  </kendo-calendar>
                </div>
              </div>
            </div>

            <!-- BOTON FILTRAR -->

            <button type="button" class="btn btn-primary float-left nolabel w-100" (click)="recargarPagina()">{{
              'filtrar' | translate }}</button>

          </div>
        </div>
      </div>
    </div>


    <!-- MAQUINAS -->
    <div class="clearfix">
      <div class="card">
        <div class="card-body">
          <kendo-tabstrip [keepTabContent]="true">
            <kendo-tabstrip-tab id="tabMaquinas" title="{{ 'maquinas' | translate}}" [selected]="true">
              <ng-template kendoTabContent>
                <!-- FILTRO GRUPOS -->
                <div class="clearfix ml-2">
                  <!--AREAS PRODUCTIVAS-->
                  <div class="form-group">
                    <label>{{ 'secciones' | translate }}</label>
                    <div class="caja">
                      <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion" [textField]="'nombre'"
                        [valueField]="'id'" [autoClose]="false" [(ngModel)]="seccionesSeleccionadas"
                        (valueChange)="cargarMaquinas2()" placeholder="{{ 'secciones' | translate }}"
                        style="width: 200px;" [kendoDropDownFilter]="{operator: 'contains'}">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span class="k-icon k-i-arrow-s"></span>
                          <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                          <ng-container *ngIf="dataItems.length > 1"> {{ dataItems.length }} {{ 'seccionesSeleccionadas'
                            |
                            translate }} </ng-container>
                        </ng-template>
                        <kendo-multiselect-messages
                          noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                      </kendo-multiselect>
                    </div>
                  </div>
                </div>
                <div class="clearfix ml-2">
                  <!--GRUPOS-->
                  <div class="form-group">
                    <label>{{ 'maquinasgrupos' | translate }}</label>
                    <div class="caja">
                      <kendo-multiselect kendoMultiSelectSummaryTag [data]="gruposSeccion" [textField]="'nombre'"
                        [valueField]="'id'" [autoClose]="false" [(ngModel)]="gruposSeleccionados"
                        placeholder="{{ 'grupoMaquinas' | translate }}" (valueChange)="cargarMaquinas2()"
                        style="width: 200px;" [kendoDropDownFilter]="{operator: 'contains'}">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span class="k-icon k-i-arrow-s"></span>
                          <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                          <ng-container *ngIf="dataItems.length > 1"> {{ dataItems.length }} {{ 'gruposSeleccionados' |
                            translate }} </ng-container>
                        </ng-template>
                      </kendo-multiselect>
                    </div>
                  </div>
                </div>
                <!-- MAQUINAS -->

                <div class="clearfix ml-2">
                  <!-- filtro maquinas -->
                  <kendo-textbox [style.width.px]="180" placeholder="{{ 'filtrarMaquinas' | translate }}" [clearButton]="true" [size]="size"
                    [rounded]="rounded" [fillMode]="fillMode" (valueChange)="filtroMaquinas($event)">
                  </kendo-textbox>
                </div>
                <div class="clearfix">
                  <ul class="list-group list-group-flush">
                    <kendo-grid id="gridmaquinasOEE" [data]="listaMaquinasAux" kendoGridSelectBy="id"
                      [selectedKeys]="maquinasSeleccionadas" [height]="600"
                      [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
                      <kendo-grid-checkbox-column [width]="40" showSelectAll="true"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="id" hidden="hidden" title="ID" width="40"></kendo-grid-column>
                      <kendo-grid-column field="nombre" title="{{ 'maquinas' | translate}}"
                        width="150"></kendo-grid-column>
                        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                  </ul>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabOperarios" title="{{ 'operarios' | translate}}">
              <ng-template kendoTabContent>
                <div class="clearfix ml-2">
                  <kendo-textbox [style.width.px]="180" placeholder="{{ 'filtrarOperarios' | translate }}" [clearButton]="true" [size]="size"
                    [rounded]="rounded" [fillMode]="fillMode" (valueChange)="filtroOperarios($event)">
                  </kendo-textbox>
                  <!-- OPERARIOS -->
                  <div class="form-group">
                    <div class="caja">
                      <div class="clearfix">
                        <ul class="list-group list-group-flush">
                          <kendo-grid id="gridOperariosOEE" [data]="listaOperariosAux" kendoGridSelectBy="idUsuario"
                            [selectedKeys]="operariosSeleccionados" [height]="600"
                            [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
                            <kendo-grid-checkbox-column [width]="40" showSelectAll="true"></kendo-grid-checkbox-column>
                            <kendo-grid-column field="idUsuario" hidden="hidden" title="ID"
                              width="40"></kendo-grid-column>
                            <kendo-grid-column field="nombreCompleto" title="Operario" width="150"></kendo-grid-column>
                            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                            filterContainsOperator="{{'filterContainsOperator' | translate}}"
                            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                          </kendo-grid>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </kendo-tabstrip-tab>
          </kendo-tabstrip>
        </div>
      </div>
    </div>
  </div>
  <!-- INFORME COMPLETO -->
  <div class="col-sm-8 col-lg-9 col-xl-10">
    <!-- FILTRO / DONUTS! -->
    <div class="row">
      <!-- DONUTS -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            <h3>
              <label runat="server" ID="lFiltro" class="">{{ 'resumen' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <div class="card-body">
            <div class="row">
              <!-- OEE -->
              <div class="col-md-12">
                <div class="row" id="graficoDonutOEE_OEE" style="height: 120px;">
                </div>
                <p ID="lFiltro" class="oee-donut-label">{{ 'oee' | translate}}</p>
              </div>
            </div>
            <div class="row">
              <!-- DISPONIBILIDAD -->
              <div class="col-md-4">
                <div class="row" id="graficoDonutDisponibilidad_OEE" style="height: 120px;">
                </div>
                <p ID="lFiltro" class="oee-donut-label">{{ 'disponibilidad' | translate}}</p>
              </div>
              <!-- RENDIMIENTO -->
              <div class="col-md-4">
                <div class="row" id="graficoDonutRendimiento_OEE" style="height: 120px;">
                </div>
                <p ID="lFiltro" class="oee-donut-label">{{ 'rendimiento' | translate}}</p>
              </div>
              <!-- CALIDAD -->
              <div class="col-md-4">
                <div class="row" id="graficoDonutCalidad_OEE" style="height: 120px;">
                </div>
                <p ID="lFiltro" class="oee-donut-label">{{ 'calidad' | translate}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- EVOLUCION (GRAFICO LINEAL) -->
      <div class="col-md-8">
        <div class="clearfix">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lEvolucion" class="">{{ 'evolucion' | translate}}</label>
              </h3>
              <div class="plegarpanel"></div>
              <!--Grafico lineas evolucion-->
              <div class="row" id="graficoEvolucion_OEE" style="height: 270px;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- HORAS (GRAFICO BARRAS) -->
    <div class="row">
      <div class="col-md-12">
        <div class="clearfix">
          <div class="card">
            <div class="card-header">
              <h3>
                <label ID="lHoras" class="">{{ 'horas' | translate}}</label>
              </h3>
              <div class="plegarpanel"></div>
              <!--Grafico barras horas-->
              <div class="row" id="chartBarHoras" style="height: 240px;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="k-i-loading"></div>