<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- INFO TIPO -->
<div class="card">
  <div class="card-body col-md-8">
    <div class="row">
      <!-- DERECHA -->
      <div class="col-md-4">
        <div class="row">
          <!-- NOMBRE -->
          <div class="col-md-9">
            <div class="form-group">
              <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-textbox [(value)]="nombre" [maxlength]="50" disabled="false"></kendo-textbox>
              </div>
            </div>
          </div>
        </div>
        <!-- SOLICITARES -->
        <div class="row">
          <!--VIDA UTIL-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'vidaUtil' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarVidaUtil" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!--DIAMETRO-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'diametro' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarDiametro" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- LONGITUD -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'longitud' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarLongitud" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- ANGULO -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'angulo' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarAngulo" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- NUM CONTENIDO -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'numContenido' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarNumContenido" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- SALTO -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'salto' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarSalto" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- ID ERP -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'idErp' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarIdErp" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- COSTE -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'coste' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarCoste" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DESCRIPCION -->
      <div class="col-md-7">
        <div class="form-group">
          <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="descripcion" [maxlength]="2000" disabled="false"></kendo-textbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<!-- GRID -->
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <kendo-grid [kendoGridBinding]="consumibles"
              [sortable]="true"
              [navigable]="true"
              filterable="menu"
              [rowHeight]="36"
              [height]="750"
              [pageSize]="30"
              kendoGridSelectBy="id"
              scrollable="virtual"
              [selectedKeys]="seleccionados"
              (cellClick)="cellClick($event)">
    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">
      <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'ver' | translate}}</button>
    </ng-template>
    <!--checkbox-->
    <kendo-grid-checkbox-column width="5%">
      <ng-template kendoGridHeaderTemplate>
        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
               [state]="selectAllState">
        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template>
    </kendo-grid-checkbox-column>
    <!--nombre-->
    <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
    <!--tipo-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="tipo" title="{{ 'tipo' | translate}}"></kendo-grid-column>
    <!--fabricante-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="fabricante" title="{{ 'fabricante' | translate}}"></kendo-grid-column>
    <!--vida-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="vidaUtil" title="{{ 'vidaUtil' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ngcontainer> {{ secondsToHms(dataItem.vidaUtil) }}</ngcontainer>
      </ng-template>
    </kendo-grid-column>
    <!--diametro-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="diametro" title="{{ 'diametro' | translate}}"></kendo-grid-column>
    <!--longitud-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="longitud" title="{{ 'longitud' | translate}}"></kendo-grid-column>
    <!--Angulo-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="angulo" title="{{ 'angulo' | translate}}"></kendo-grid-column>
    <!--nfiltros-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="numContenido" title="{{ 'capacidad' | translate}}"></kendo-grid-column>
    <!--salto-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="salto" title="{{ 'saltos' | translate}}"></kendo-grid-column>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>
