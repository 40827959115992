import { Component, NgZone, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, ClientesService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { closest } from '@progress/kendo-angular-common';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { fromEvent, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-editar-crear-clientes',
  templateUrl: './editar-crear-clientes.component.html',
  styleUrls: ['./editar-crear-clientes.component.less']
})
export class EditarCrearClientesComponent implements OnInit {

  public state: State = {
    skip: 0,
    take: 100
  };
  private currentSubscription: Subscription;
  public gridData: any;
  form: FormGroup; //formulario con datos cliente
  id: string;
  isAddMode: boolean; //para saber si es modo de edición o creación
  loading = false;
  submitted = false;
  user = this.usuariosService.userValue;
  public listaGrupos: any = [];
  public idSelected: any = {};
  public datosCargados = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    private menuService: MenuService,
    private clientesService: ClientesService,
    private renderer: Renderer2,
    private zone: NgZone
  ) {
    
  }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('clientes');
    //ver si es modo de edición o creación
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.clientesService.getGrupos().subscribe(response => {
      var aux: any = response;
      var grupos: any = aux.data;
      var auxCont = 1;
      grupos.forEach(element => {
        element.id = auxCont;
        auxCont++;
      });
      if(this.isAddMode){
        this.listaGrupos = grupos;
        this.form = this.formBuilder.group({ //se crea el formulario
          id: -1,
          idDb: this.user.idDb,
          nombre: ['', [Validators.required]],
          prioridad: [0, [Validators.required]],
          prioridadFacturacion: [0, [Validators.required]],
          grupo: [{ id: 0 }]
        });
        this.idSelected = { id: 0, grupo: '' };
        this.datosCargados = true;
      } else{
        this.clientesService.getClienteById(this.route.snapshot.params['id']).subscribe(response =>{
          var aux: any = response;
          var datos = aux.data;
          this.listaGrupos = grupos;
          if (datos[0].grupo === 'A'){
            this.idSelected = { id: 1, grupo: 'A' };
          } else if(datos[0].grupo === 'B'){
            this.idSelected = { id: 2, grupo: 'B' };
          } else if (datos[0].grupo === 'C'){
            this.idSelected = { id: 3, grupo: 'C' };
          } else{
            this.idSelected = { id: 0, grupo: ''};
          }
          this.form = this.formBuilder.group({ //se crea el formulario
            id: this.route.snapshot.params['id'],
            idDb: this.user.idDb,
            nombre: [datos[0].nombre, [Validators.required]],
            prioridad: [datos[0].prioridad, [Validators.required]],
            prioridadFacturacion: [datos[0].prioridadFacturacion, [Validators.required]],
            grupo: [this.idSelected]
          });
          this.datosCargados = true;
        });
      }
    });
  }
  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging
    };
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        
        // Change this line to get the closest row element
        const dropRow = e.target.closest('.k-grid tr');
        if (dropRow) {
          const dropIndex = dropRow.rowIndex;
          const dropItem = this.gridData.data[dropIndex];
      
          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.gridData.data.splice(dropIndex, 0, dataItem)
          );
        }
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  } 

  public ngAfterViewInit(): void {    
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }
 
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() { 
    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.crearCliente();
    } else {
      this.updateCliente();
    }

  }

  private crearCliente() {
    if(this.form.value.grupo.id === 1){
      this.form.value.grupo = "A";
    } else if(this.form.value.grupo.id === 2){
      this.form.value.grupo = "B";
    } else if(this.form.value.grupo.id === 3){
      this.form.value.grupo = "C";
    } else{
      this.form.value.grupo = "";
    }
    this.clientesService.crearCliente(this.form.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });

        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateCliente() {
    if(this.form.value.grupo.id === 1){
      this.form.value.grupo = "A";
    } else if(this.form.value.grupo.id === 2){
      this.form.value.grupo = "B";
    } else if(this.form.value.grupo.id === 3){
      this.form.value.grupo = "C";
    } else{
      this.form.value.grupo = "";
    }
    
    this.clientesService.editarCliente(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

}
