<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<ul class="list-group list-group-flush" *ngIf="router.url === '/eskola_lanzadorOf'">
  <div class="row">
    <!--PRIMERA COLUMNA-->
    <div class="col">
      <div class="card w-100">
        <div class="card-header">
          <h3>{{'proyectos' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <a class="link-radio-boton ">
                <input type="radio" name="todos" id="todos" class="k-radio" [checked]="tipoOfs==1"
                  (click)="radioTodos_Click()" kendoRadioButton>
                <label class="k-radio-label" for="todos">{{ 'misProyectos' | translate }}</label>
              </a>
            </div>
            <!-- momentu ez 
            <div class="col">
              <a class="link-radio-boton ">
                <input type="radio" name="misProyectos" id="misProyectos" [checked]="tipoOfs==3" class="k-radio"
                  (click)="radioMisProyectos_Click()" kendoRadioButton>
                <label class="k-radio-label" for="misProyectos">{{ 'misProyectos' | translate }}</label>
              </a>
            </div>
            -->
            <div class="col">
              <a class="link-radio-boton ">
                <input type="radio" name="piezasPredefinidas" id="piezasPredefinidas" [checked]="tipoOfs==3"
                  class="k-radio" (click)="radioPiezasPredeterminadas_Click()" kendoRadioButton>
                <label class="k-radio-label" for="piezasPredefinidas">{{ 'piezaspredefinidas' | translate}}</label>
              </a>
            </div>
          </div>
          <!--todos-->
          <kendo-treelist *ngIf="tipoOfs==1" [kendoTreeListHierarchyBinding]="ofs" childrenField="piezas" idField="id"
            kendoTreeListSelectable  [selectable]="{drag: true, enabled: true, mode: 'row', multiple: true}" [(selectedItems)]="selectedOf"
            (selectionChange)="onSelectionChange($event)" kendoTreeListExpandable height="700">
            <kendo-treelist-checkbox-column [width]="45" [checkChildren]="true">
            </kendo-treelist-checkbox-column>
            <kendo-treelist-column [expandable]="true" field="nombre" title="{{'nombre' | translate}}" [width]="250">
            </kendo-treelist-column>
            <kendo-treelist-column field="dataItem" title="" [width]="180">
              <ng-template kendoTreeListCellTemplate let-dataItem>
                <kendo-numerictextbox *ngIf="dataItem.idPieza == undefined" [(value)]="dataItem.cantidad" type="number"
                  format="n0" [min]="0" [autoCorrect]="true">
                </kendo-numerictextbox>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-messages filter="{{'filter' | translate}}"
              filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}"
              filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}"
              filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}"
              filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}"
              filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
              filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
              filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
              filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
              filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
              noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
            </kendo-treelist-messages>
          </kendo-treelist>
          <!--piezas predefinidas-->
          <kendo-grid *ngIf="tipoOfs==3" [kendoGridBinding]="piezasPredefinidas" kendoGridSelectBy="id"
            [sortable]="true" [selectable]="{drag: false, enabled: true, mode: 'multiple', checkboxOnly: true}" [navigable]="true"
            [resizable]="true" [hideHeader]="true" [selectedKeys]="selectedPredefinidas"
            [pageSize]="25" (selectionChange)="selectedPredefinidasChange($event)">
            <kendo-grid-checkbox-column width="20%" [style]="{'text-align': 'left'}" [columnMenu]="false">
            </kendo-grid-checkbox-column>
            <kendo-grid-column width="75%" field="nombre" [style]="{'text-align': 'left'}"></kendo-grid-column>
            <kendo-grid-column field="dataItem" title="cantidad" [width]="180">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-numerictextbox [(value)]="dataItem.cantidad" type="number" format="n0" [min]="0"
                  [autoCorrect]="true">
                </kendo-numerictextbox>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>


        </div>
      </div>
    </div>
    <!--SEGUNDA COLUMNA-->
    <div class="col">
      <div class="card w-100">
        <div class="card-header">
          <h3>{{'nombrarOf' | translate}}</h3>
        </div>
        <div class="card-body">
          <input kendoTextBox placeholder="Nombre OF" class="form-control"
            [ngClass]="{ 'is-invalid': errorNombreRepetido || errorNombreVacio || errorCaracterEspecial }"
            [value]="of.nombre" (change)="nombreChange($event)" />
          <div *ngIf="errorNombreVacio" class="invalid-feedback">
            <div>{{ 'nombrerequerido' | translate}}</div>
          </div>
          <div *ngIf="errorNombreRepetido" class="invalid-feedback">
            <div>{{ 'nombreOfRepetido' | translate}}</div>
          </div>
          <div *ngIf="errorCaracterEspecial" class="invalid-feedback">
            <div>{{ 'errorCaracterEspecial' | translate}}</div>
          </div>
        </div>
      </div>

      <div class="card w-100">
        <div class="card-header">
          <h3>{{'tipoasignacion' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <a class="link-radio-boton ">
                <input type="radio" name="porAlumno" id="porAlumno" class="k-radio"
                  (click)="radioPorAlumno_Click($event)" [checked]="tipoAsignacion==1" kendoRadioButton>
                <label class="k-radio-label" for="porAlumno">{{ 'porAlumno' | translate }}</label>
              </a>
            </div>
            <div class="col">
              <a class="link-radio-boton ">
                <input type="radio" name="porGrupo" id="porGrupo" class="k-radio" (click)="radioPorGrupo_Click()"
                  [checked]="tipoAsignacion==2" kendoRadioButton>
                <label class="k-radio-label" for="porGrupo">{{ 'porGrupodeTrabajo' | translate }}</label>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="card w-100">
        <div class="card-header">
          <h3>{{'asignaturasretos' | translate}}</h3>
        </div>
        <div class="card-body">
          <kendo-grid [kendoGridBinding]="asignaturasRetos" kendoGridSelectBy="id" [sortable]="true"
            [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true" [resizable]="true" [hideHeader]="true" (cellClick)="cellClick($event)"
            [selectedKeys]="selectedAsignaturasretos" [pageSize]="25">
            <kendo-grid-checkbox-column width="7%" [style]="{'text-align': 'left'}" [columnMenu]="false">
            </kendo-grid-checkbox-column>
            <kendo-grid-column width="75%" field="nombre" [style]="{'text-align': 'left'}"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </div>

      <div class="card w-100">
        <div class="card-header">
          <h3>{{'grupos' | translate}}</h3>
        </div>
        <div class="card-body">
          <kendo-grid showSelectAll="true" [kendoGridBinding]="grupos" kendoGridSelectBy="id" [sortable]="true"
            [selectable]="{cell:false, checkboxOnly: true, drag: false, enabled: true, mode: 'multiple'}"
            [navigable]="true" [resizable]="true" [selectedKeys]="selectedGrupos" [pageSize]="25"
            (selectionChange)="selectedGruposChange($event)">
            <kendo-grid-checkbox-column showSelectAll="true" width="8%" [style]="{'text-align': 'left'}"
              [columnMenu]="false"></kendo-grid-checkbox-column>
            <kendo-grid-column title="{{ 'nombre' | translate}}" width="75%" field="nombreC"
              [style]="{'text-align': 'left'}"></kendo-grid-column>
            <kendo-grid-column *ngIf="tipoAsignacion==1" title="" width="15%" field="numeroDeAlumnos"
              [style]="{'text-align': 'left'}">
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>

        </div>
      </div>
    </div>

    <!--TERCERA COLUMNA-->
    <div class="col">

      <div *ngIf="tipoAsignacion==2">
        <div class="card w-100">
          <div class="card-header">
            <h3>{{'gruposdetrabajo' | translate}}</h3>
          </div>
          <div class="card-body">
          <div class="tipo-grid grid-lanzadorof-grupotrabajo">

            <div class="tipo-grid-linea" *ngFor="let grupo of gruposDeTrabajo">
              
                <div class="tipo-grid-col col-grupotrabajo">
                  <div #click (click)="mostrarMultiselect(grupo)">
                    <span *ngIf="!grupo.showMultiselect" class="tooltip-consumibles-contenido">
                      <ng-container *ngFor="let item of grupo.alumnos | slice:0:5;">
                        <span *ngIf="item.id_usuario!=-1" class="grid-circulo"
                          [ngStyle]="{'background-color': item.color}">{{item.nombre.split(' ')[0].charAt(0)}}{{item.nombre.split(' ')[1].charAt(0)}}</span>
                      </ng-container>
                      <span class="tooltiptext">
                        <ng-container *ngFor="let item of grupo.alumnos">
                          <label *ngIf="item !='undefined'">
                            {{item.nombre}}
                          </label>
                          <label *ngIf="item =='undefined'">
                            {{'desconocido' | translate}}
                          </label>
                        </ng-container>
                      </span>
                    </span>
                
                  </div>
                </div>


                <div class="tipo-grid-col col-eliminar">
                  <button Class="flt-eliminar" (click)="eliminarGrupo(grupo.id)"
                    *ngIf="grupo.id != -1 && !grupo.showMultiselect"> <i class="fas fa-times"></i>
                  </button>
                </div>
             
              <div class="seleccion-usuario" *ngIf="grupo.showMultiselect">
                <kendo-multiselect #multiselectAlumnos [data]="comboAlumnos" [filterable]="true" [textField]="'nombre'"
                  (filterChange)="filterChange($event)" [ngClass]="user-selection" [(value)]="grupo.alumnos"
                  [popupSettings]="{ popupClass: 'seleccion-usuario'}" [valueField]="'id_usuario'" [valuePrimitive]="false"
                  (valueChange)="valueChange($event, grupo)" (focus)="mostrarMultiselect(grupo)" (filterChange)="filterChange($event, multiselectAlumnos)">

                  <ng-template kendoMultiSelectTagTemplate let-dataItem>
                    <span class="grid-circulo"
                      [ngStyle]="{'background-color': dataItem.color}">{{dataItem.nombre.split(' ')[0].charAt(0)}}{{dataItem.nombre.split(' ')[1].charAt(0)}}</span>
                  </ng-template>
                  <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <span class="grid-circulo"
                      [ngStyle]="{'background-color': dataItem.color}">{{dataItem.nombre.split(' ')[0].charAt(0)}}{{dataItem.nombre.split(' ')[1].charAt(0)}}</span>
                    <span><label> {{ dataItem.nombre }} </label></span>
                  </ng-template>

                </kendo-multiselect>
              </div>

            </div>

          </div>
          </div>

        </div>
      </div>

      <div *ngIf="tipoAsignacion==1">
        <div class="card w-100">
          <div class="card-header">
            <h3>{{'alumnos' | translate}}</h3>
          </div>
          <div class="card-body">
            <kendo-grid showSelectAll="true" [kendoGridBinding]="comboAlumnos" kendoGridSelectBy="id_usuario" [sortable]="true"
              [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: true, mode: 'multiple'}"
              [navigable]="true" [resizable]="true" (selectionChange)="selectedAlumnosChange($event)"
              [selectedKeys]="selectedAlumnos" [pageSize]="25">
              <kendo-grid-checkbox-column showSelectAll="true" width="5%" [style]="{'text-align': 'left'}"
                [columnMenu]="false"></kendo-grid-checkbox-column>
              <kendo-grid-column width="25%" field="nombre" [style]="{'text-align': 'left'}"
                title="{{ 'nombre' | translate}}"></kendo-grid-column>
              <kendo-grid-column width="25%" field="nombre_grupo" [style]="{'text-align': 'left'}"
                title="{{ 'grupo' | translate}}"></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </div>

      <div class="card w-100">
        <div class="card-header">
          <h3>{{'titulo' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="row ml-2">
            <div class="form-group mr-2" [ngClass]="{ 'is-invalid': errorSinAsignar }">
              <kendo-label text="{{'nPiezasOf'|translate}}"></kendo-label>
              <div class="caja">
                <kendo-numerictextbox type="number" format="n0" [min]="1" [autoCorrect]="true" [(value)]="nPiezas_Of"
                  (valueChange)="nPiezas_Of_onChange()"></kendo-numerictextbox>
              </div>
            </div>
            <div class="form-group mr-2">
              <kendo-label text="{{'nOfs'|translate}}"></kendo-label>
              <div class="caja">
                <input kendoTextBox [disabled]="'true'" class="form-control" [(value)]="nOfs" />
              </div>
            </div>
            <div class="form-group mr-2">
              <kendo-label text="{{ 'ofPublica' | translate}}"></kendo-label>
              <div class="caja">
                <kendo-switch [checked]="of.publica" (valueChange)="publicaChange()" [onLabel]="' '" [offLabel]="' '">
                </kendo-switch>
              </div>
            </div>
            <div class="form-group nolabel">
              <button kendoButton class="btn mr-1  btn-primary btn-sm mr-1" (click)="crearOf()">{{ 'crearOf' |
                translate}}</button>
            </div>
            <div *ngIf="errorSinAsignar" class="invalid-feedback">
              <div>{{ 'errorOfSinAsignar' | translate}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ul>
<router-outlet></router-outlet>
