<div class="row">

  <div class="col-md-5">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="card">
        <div class="card-header">
          <h3>{{ 'receta' | translate}}</h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">

          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate}}">
              <input kendoTextBox formControlName="nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
              <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
              </div>
              <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                <div *ngIf="f.nombre.errors.maxlength">{{ 'nombre200caracteres' | translate}}</div>
              </div>
            </kendo-label>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tiempoCiclo' | translate}}">
                  <kendo-numerictextbox formControlName="tiempoCiclo" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tiempoInyeccion' | translate}}">
                  <kendo-numerictextbox formControlName="tiempoInyeccion" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tiempoPresionRemanente' | translate}}">
                  <kendo-numerictextbox formControlName="tiempoPresionRemanente" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tiempoEnfriamiento' | translate}}">
                  <kendo-numerictextbox formControlName="tiempoEnfriamiento" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tiempoDosificPlastificacion' | translate}}">
                  <kendo-numerictextbox formControlName="tiempoDosificacionPlastificacion" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'presInyMax' | translate}}">
                  <kendo-numerictextbox formControlName="presionInyeccionMax" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'presRemanente' | translate}}">
                  <kendo-numerictextbox formControlName="presionRemanente" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'contrapresion' | translate}}">
                  <kendo-numerictextbox formControlName="contrapresion" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'ptoConmutacion' | translate}}">
                  <kendo-numerictextbox formControlName="ptoConmutacion" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'paradaDosific' | translate}}">
                  <kendo-numerictextbox formControlName="paradaDosificado" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'retrocesoHusillo' | translate}}">
                  <kendo-numerictextbox formControlName="retrocesoHusillo" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'paradaMolde' | translate}}">
                  <kendo-numerictextbox formControlName="paradaMolde" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'cojin' | translate}}">
                  <kendo-numerictextbox formControlName="cojin" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'revolucionesHusillo' | translate}}">
                  <kendo-numerictextbox formControlName="revolucionesHusillo" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'fuerzaDeCierre' | translate}}">
                  <kendo-numerictextbox formControlName="fuerzaDeCierre" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tHusillo' | translate}}">
                  <kendo-numerictextbox formControlName="temperaturaHusillo" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'tCamaraCaliente' | translate}}">
                  <kendo-numerictextbox formControlName="temperaturaCamaraCaliente" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'TPFija' | translate}}">
                  <kendo-numerictextbox formControlName="tempPresionFija" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'TPMovil' | translate}}">
                  <kendo-numerictextbox formControlName="tempPresionMovil" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'temperaturaMolde' | translate}}">
                  <kendo-numerictextbox formControlName="temperaturaMolde" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'pesoInyeccionEspumado' | translate}}">
                  <kendo-numerictextbox formControlName="pesoInyeccionEspumado" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'porcenScf' | translate}}">
                  <kendo-numerictextbox formControlName="porcenScf" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <kendo-label text="{{ 'dosificacionScf' | translate}}">
                  <kendo-numerictextbox formControlName="dosificacionScf" class="form-control"></kendo-numerictextbox>
                </kendo-label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <button kendoButton [disabled]="loading || this.user.recetas <2" class="btn mr-1 mt-2 btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              {{ 'guardar' | translate}}
            </button>
            
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="col-md-7">
    <kendo-tabstrip [keepTabContent]="true">

      <kendo-tabstrip-tab title="{{ 'tCamaraCaliente' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div style="min-height: 500px">
            <kendo-grid [data]="listaTCamaraCaliente">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarTCamaraCaliente()">
                  {{ 'agregar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-column field="num" title="" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <label>Zona {{ dataItem.num }}</label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" width="20%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [style.width.px]="130" [(value)]="dataItem.valor"></kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarTCamaraCaliente(dataItem)" [disabled]="this.user.recetas <2" >
                    {{ 'eliminar' | translate}}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="" title="" width="50%"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'tHusillo' | translate}}">
        <ng-template kendoTabContent>
          <div style="min-height: 500px">
            <kendo-grid [data]="listaTHusillo">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarTHusillo()">
                  {{ 'agregar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-column field="num" title="" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <label *ngIf="dataItem.num == 1">Brida</label>
                  <label *ngIf="dataItem.num > 1">Zona {{ dataItem.num - 1 }}</label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" width="20%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [style.width.px]="130" [(value)]="dataItem.valor"></kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarTHusillo(dataItem)" [disabled]="this.user.recetas <2">
                    {{ 'eliminar' | translate}}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="" title="" width="50%"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'cascadaAbrir1' | translate}}">
        <ng-template kendoTabContent>
          <div style="min-height: 500px">
            <kendo-grid [data]="listaCascadaAbrir1">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarCascadaAbrir1()">
                  {{ 'agregar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-column field="num" title="" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <label>Zona {{ dataItem.num }}</label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" width="20%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [style.width.px]="130" [(value)]="dataItem.valor"></kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarCascadaAbrir1(dataItem)" [disabled]="this.user.recetas <2">
                    {{ 'eliminar' | translate}}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="" title="" width="50%"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'cascadaCerrar1' | translate}}">
        <ng-template kendoTabContent>
          <div style="min-height: 500px">
            <kendo-grid [data]="listaCascadaCerrar1">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarCascadaCerrar1()">
                  {{ 'agregar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-column field="num" title="" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <label>Zona {{ dataItem.num }}</label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" width="20%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [style.width.px]="130" [(value)]="dataItem.valor"></kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarCascadaCerrar1(dataItem)" [disabled]="this.user.recetas <2">
                    {{ 'eliminar' | translate}}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="" title="" width="50%"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'cascadaAbrir2' | translate}}">
        <ng-template kendoTabContent>
          <div style="min-height: 500px">
            <kendo-grid [data]="listaCascadaAbrir2">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarCascadaAbrir2()">
                  {{ 'agregar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-column field="num" title="" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <label>Zona {{ dataItem.num }}</label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" width="20%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [style.width.px]="130" [(value)]="dataItem.valor"></kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarCascadaAbrir2(dataItem)" [disabled]="this.user.recetas <2">
                    {{ 'eliminar' | translate}}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="" title="" width="50%"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'cascadaCerrar2' | translate}}">
        <ng-template kendoTabContent>
          <div style="min-height: 500px">
            <kendo-grid [data]="listaCascadaCerrar2">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarCascadaCerrar2()">
                  {{ 'agregar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-column field="num" title="" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <label>Zona {{ dataItem.num }}</label>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" width="20%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [style.width.px]="130" [(value)]="dataItem.valor"></kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarCascadaCerrar2(dataItem)" [disabled]="this.user.recetas <2">
                    {{ 'eliminar' | translate}}
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="" title="" width="50%"></kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
  </div>

</div>
