<div class="h-100">
  <div [ngClass]="configuracion.tipoPantalla == 2 ? 'row home-maquinas-cont' : 'h-100'"
    style="padding: 8px 5px 0px 10px;">
    <!-- <ng-container *ngFor="let maquina of listaMaquinasEnPantalla[index]"> -->
    <ng-container *ngFor="let maquina of datosMaquinas">

      <div [ngClass]="configuracion.tipoPantalla == 2 ? 'form-horizontal col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 panelosoa tv-grande-cuatro'
                            : 'panelosoa tv-grande-uno'" id="panelosoa"
        *ngIf="maquina != undefined && maquina?.home != undefined && listaMaquinasEnPantalla[index].includes(maquina.id)">

        <!-- DISENO 1: OEE -->
        <div [ngClass]="configuracion.tipoPantalla == 2 ? 'inner-panel disenoTipo1' : 'inner-panel disenoTipo1'"
          *ngIf="configuracion.disenoSelected == 1">
          <!-- Pestaña de color-->
          <div id="pestanaColor" class="pestañaprocesos"
            [ngClass]="{'colorejecucion': maquina.home.idEstado == 1
                                                                          , 'colorparada': maquina.home.idEstado == 2
                                                                          , 'colorpreparacion': maquina.home.idEstado == 3
                                                                          , 'colormantenimiento': maquina.home.idEstado == 4
                                                                          , 'coloralarma': maquina.home.idEstado == 6
                                                                          , 'colorapagada': maquina.home.idEstado == 8}"></div>

          <div class="row ">
            <!-- COL 1 -->
            <div class="col-6">
              <!-- ROW 1.1 -->
              <div class="d-flex">
                <!-- Imagen de las maquinas-->
                <div ID="maquina" class="">
                  <div id="estado">
                    <div class="fotomaquina pqn">
                      <img ID="fotomaquina" src="{{maquina.imagenBase64}}" />
                    </div>
                  </div>
                </div>

                <div class="header-home-maquina-tv">
                  <!-- Nombre de la maquina -->
                  <div class="clearfix nombre-maquina-tv">

                    <label ID="lmodelo">{{maquina.nombre}}</label>

                  </div>

                  <div class="clearfix tiempo-maquina-tv">
                    <label>{{timeDiff(maquina.home.tiempoEstado, maquina.home.now)}}</label>
                  </div>

                  <!-- Estado de ejecucion -->
                  <div class="clearfix estado-maquina-tv">
                    <div>
                      <div [ngClass]="{'ejecucion': maquina.home.idEstado == 1
                                      , 'parada': maquina.home.idEstado == 2
                                      , 'preparacion': maquina.home.idEstado == 3
                                      , 'mantenimiento': maquina.home.idEstado == 4
                                      , 'alarma': maquina.home.idEstado == 6
                                      , 'apagada': maquina.home.idEstado == 8}">
                        <kendo-label text="{{maquina.home.estado.toUpperCase()}}"></kendo-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ROW 2.1 -->
              <div class="clearfix">
                <!--PANEL OF PARA MAQUINAS QUE NO SON INYECTORAS NI HORNOS-->
                <div *ngIf="maquina?.tipo_maquina != 4 && maquina?.tipo_maquina != 5" class="">
                  <!-- LISTA DATOS -->
                  <div *ngIf="!maquina.sinConexion && maquina.home.datosOperaciones.length!=0" class="">
                    <div id="divDatosof" class="datos-of-tv"
                      *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                      <ul>
                        <li>
                          <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                            title="{{datoOperacion.numeroOF}}">
                            <strong>{{'of' |translate}} </strong>
                            {{datoOperacion.numeroOF}}
                          </label>
                        </li>
                        <li *ngIf="datoOperacion.cliente!=''">
                          <label ID="ldatocliente" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                            title="{{datoOperacion.cliente}}">
                            {{datoOperacion.cliente}}
                          </label>
                        </li>
                        <li *ngIf="datoOperacion?.pieza!=''">
                          <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                            title="{{datoOperacion?.pieza}}">
                            {{datoOperacion?.pieza}}
                          </label>
                        </li>
                        <li *ngIf="datoOperacion?.numeroOF != ''">
                          <label ID="ldatoOperacion" style="font-size:25px;" data-toggle="tooltip"
                            data-placement="bottom" title="{{datoOperacion?.numeroOF}}">
                            {{datoOperacion?.numeroOF}}
                          </label>
                        </li>
                        <li *ngIf="datoOperacion?.nserie != ''">
                          <label ID="ldatoNumSerie" style="font-size:25px;" data-toggle="tooltip"
                            data-placement="bottom" title="{{datoOperacion?.nserie}}">
                            <strong>{{ 'nserie' | translate }} </strong>
                            {{datoOperacion?.nserie}}
                          </label>
                        </li>
                        <li>
                          <label ID="LdatoCantidad" style="font-size:25px;" data-toggle="tooltip"
                            data-placement="bottom" title="{{datoOperacion?.cantidadManual}}">
                            <strong>{{ 'pieza' | translate }} </strong>
                            {{datoOperacion?.cantidadManual}} /
                            {{datoOperacion?.cantidadReal}}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="maquina.sinConexion || maquina.home.datosOperaciones.length==0"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" data-toggle="tooltip" data-placement="bottom" style="font-size:25px;">
                      {{ 'noproceso' | translate }}
                    </label>
                  </div>
                </div>

                <!--PANEL OF PARA INYECTORAS-->
                <div class="pdatos-of-inner" *ngIf="maquina.tipo_maquina == 4">

                  <!--MAQUINA-->
                  <label ID="Lmaquina" class="titulo-seccion-maquina">
                    {{
                    'maquina' |
                    translate
                    }}
                  </label>

                  <div id="divMaquinaInyec" class="datos-of-content">
                    <ul>
                      <li *ngIf="maquina.tonelaje!=''">
                        <label ID="ldatotonelaje" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                          data-placement="bottom" title="{{maquina.tonelaje}}">
                          {{maquina.tonelaje}}
                        </label>
                      </li>
                      <li *ngIf="maquina.unidadInyeccion!=''">
                        <label ID="ldatounidadInyeccion" class="label-datos-home" style="width: 60%;"
                          data-toggle="tooltip" data-placement="bottom" title="{{maquina.unidadInyeccion}}">
                          {{maquina.unidadInyeccion}}
                        </label>
                      </li>
                      <li *ngIf="maquina.numeroModelo!=''">
                        <label ID="ldatonumeroMaquina" class="label-datos-home" style="width: 60%;"
                          data-toggle="tooltip" data-placement="bottom" title="{{maquina.numeroModelo}}">
                          {{maquina.numeroModelo}}
                        </label>
                      </li>
                    </ul>
                  </div>

                  <!--DATOS-->
                  <div *ngIf="!maquina.sinConexion && maquina.home.datosOperaciones.length!=0">
                    <div id="divDatosInyec" class="datos-of-content"
                      *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                      <ul>
                        <li *ngIf="maquina.home.datosOperaciones.length!=0">
                          <label ID="ldatocodof" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{datoOperacion?.numeroOF}}">
                            <span class="of-label">
                              {{
                              'of'
                              |translate
                              }}
                            </span>{{datoOperacion?.numeroOF}}
                          </label>
                        </li>
                        <li *ngIf="maquina.nCavidades!=''">
                          <label ID="ldatonCavidades" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.nCavidades}}">
                            {{maquina.nCavidades}}
                          </label>
                        </li>
                        <li *ngIf="maquina.material!=''">
                          <label ID="lmaterial" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.material}}">
                            {{maquina.material}}
                          </label>
                        </li>
                        <li *ngIf="maquina.refMaterial!=''">
                          <label ID="ldatorefMaterial" class="label-datos-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.refMaterial}}">
                            {{maquina.refMaterial}}
                          </label>
                        </li>
                        <li *ngIf="maquina.refMolde!=''">
                          <label ID="ldatorefMolde" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.refMolde}}">
                            {{maquina.refMolde}}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="maquina.sinConexion || maquina.home.datosOperaciones.length==0"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                      {{ 'noproceso' | translate }}
                    </label>
                  </div>
                </div>

                <!--PANEL OF PARA HORNOS-->
                <div class="pdatos-of-inner" *ngIf="maquina.tipo_maquina == 5">

                  <!--DATOS-->
                  <div *ngIf="!maquina.sinConexion && maquina.home.datosOperaciones.length!=0">
                    <div id="divDatosInyec" class="datos-of-content"
                      *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                      <ul>
                        <li>
                          <label ID="ldatocodof" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{datoOperacion?.numeroOF}}">
                            <strong>{{'of' |translate}}</strong>
                            {{datoOperacion?.numeroOF}}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoCliente" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{datoOperacion?.cliente}}">
                            {{ datoOperacion?.cliente }}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoPieza" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{datoOperacion?.pieza}}">
                            {{ datoOperacion?.pieza }}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoTarea" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{datoOperacion?.nombreOperacion}}">
                            {{datoOperacion?.nombreOperacion}}
                          </label>
                        </li>
                        <li>
                          <label ID="ldatoPiezas" class="label-datos-valor-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{ datoOperacion?.cantidadManual }}">
                            <strong>{{ 'pieza' | translate }}</strong> {{
                            datoOperacion?.cantidadManual
                            }} /
                            {{datoOperacion?.cantidadReal }}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div *ngIf="!maquina.sinConexion || maquina.home.datosOperaciones.length==0"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                      {{ 'noproceso' | translate }}
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <!-- COL 2 -->
            <div class="col-6">
              <!-- CUATRO DONUTS-->
              <div class="oee-cont-tv">
                <div class="row">
                  <!-- OEE -->
                  <div class="col-md-12">
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 1"
                      id="{{'graficoDonutOEE_OEE_' + maquina.id}}" style="height: 250px;">
                    </div>
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 2"
                      id="{{'graficoDonutOEE_OEE_' + maquina.id}}" style="height: 120px;">
                    </div>
                    <p ID="lFiltro" class="oee-donut-label">{{ 'oee' | translate}}</p>
                  </div>
                </div>
                <div class="row">
                  <!-- DISPONIBILIDAD -->
                  <div class="col-md-4">
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 1"
                      id="{{'graficoDonutDisponibilidad_OEE_' + maquina.id}}" style="height: 250px;">
                    </div>
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 2"
                      id="{{'graficoDonutDisponibilidad_OEE_' + maquina.id}}" style="height: 120px;">
                    </div>
                    <p ID="lFiltro" class="oee-donut-label">
                      {{ 'disponibilidad' | translate}}
                    </p>
                  </div>
                  <!-- RENDIMIENTO -->
                  <div class="col-md-4">
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 1"
                      id="{{'graficoDonutRendimiento_OEE_' + maquina.id}}" style="height: 250px;">
                    </div>
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 2"
                      id="{{'graficoDonutRendimiento_OEE_' + maquina.id}}" style="height: 120px;">
                    </div>
                    <p ID="lFiltro" class="oee-donut-label">
                      {{ 'rendimiento' | translate}}
                    </p>
                  </div>
                  <!-- CALIDAD -->
                  <div class="col-md-4">
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 1"
                      id="{{'graficoDonutCalidad_OEE_' + maquina.id}}" style="height: 250px;">
                    </div>
                    <div class="row circulo-oee-home-tv" *ngIf="configuracion.tipoPantalla == 2"
                      id="{{'graficoDonutCalidad_OEE_' + maquina.id}}" style="height: 120px;">
                    </div>
                    <p ID="lFiltro" class="oee-donut-label">{{ 'calidad' | translate}}</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- DISENO 2: PRECIO/HORA SIN TIEMPO -->
        <div [ngClass]="configuracion.tipoPantalla == 2 ? 'inner-panel disenoTipo2' : 'inner-panel disenoTipo2'"
          *ngIf="configuracion.disenoSelected == 2">
          <!-- Pestaña de color-->
          <div id="pestanaColor" class="pestañaprocesos" [ngClass]="{'colorejecucion': maquina.home.idEstado == 1
                        , 'colorparada': maquina.home.idEstado == 2
                        , 'colorpreparacion': maquina.home.idEstado == 3
                        , 'colormantenimiento': maquina.home.idEstado == 4
                        , 'coloralarma': maquina.home.idEstado == 6
                        , 'colorapagada': maquina.home.idEstado == 8}"></div>

          <!-- ROW 1 -->
          <div class="row">
            <!-- ROW 1.1 -->
            <div class="d-flex">
              <!-- Imagen de las maquinas-->
              <div ID="maquina" class="">
                <div id="estado">
                  <div class="fotomaquina pqn">
                    <img ID="fotomaquina" src="{{maquina.imagenBase64}}" />
                  </div>
                </div>
              </div>

              <div class="header-home-maquina-tv">
                <!-- Nombre de la maquina -->
                <div class="clearfix nombre-maquina-tv">

                  <label ID="lmodelo">{{maquina.nombre}}</label>

                </div>
              </div>
            </div>
          </div>
          <div class="margen-borde-inferior"></div>
          <!-- ROW 2 -->
          <div class="row cont-datos-alineados">
            <!-- COL 1 -->
            <div class="col-6">
              <!-- ROW 2.1 -->
              <!--PANEL OF PARA MAQUINAS QUE NO SON INYECTORAS NI HORNOS-->
              <!-- TIEMPOS CALIDAD / MANTENIMIENTOS -->
              <div class="tiemposhorno">
                <!-- TIEMPO RESTANTE CALIDAD -->
                <div class="row d-flex justify-content-center">
                  <label class="tiemposhorno-titulo">{{'tRestCalidad' | translate}}</label>
                </div>
                <div class="row d-flex justify-content-center">
                  <label class="tiemposhorno-tiempo">00:00:00</label>
                </div>
                <!-- TIEMPO RESTANTE MANTENIMIENTO -->
                <div class="row d-flex justify-content-center">
                  <label class="tiemposhorno-titulo">{{'tRestMantenimiento' | translate}}</label>
                </div>
                <div *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                  <div class="row d-flex justify-content-center">
                    <label class="tiemposhorno-tiempo">
                      {{faltaMantenimientoST.get(maquina.id)}}</label>
                  </div>
                </div>
                <div *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                  <div class="row d-flex justify-content-center">
                    <label class="tiemposhorno-sin-mant">{{ 'noMantenimientosCorto' | translate}}</label>
                  </div>
                </div>


              </div>

            </div>

            <!-- COL 2 -->
            <div class="col-6">
              <div class="row cont-datos-alineados">
                <!-- COL2 COL1 -->
                <div class="col-7">
                  <div class="euroshorahorno">
                    <!-- EUROS HORA -->
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-titulo">€/h</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-valor">{{maquina.home.eurosHora}}</label>
                    </div>
                    <!-- PIEZAS HORA -->
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-titulo">{{'piezash'|translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-valor">{{maquina.home.piezasHora}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-5 cont-porcentaje-flecha-horno">
                  <!-- PORCENTAJE PIEZAS HORA -->
                  <div class="row d-flex justify-content-center">
                    <div *ngIf="maquina.home.piezasHoraRojo">
                      <i class="icon-flechaabajo-alt-2"></i>
                    </div>
                    <div *ngIf="!maquina.home.piezasHoraRojo">
                      <i class="icon-flechaarriba-alt-2"></i>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <kendo-label class="porcentaje-horno" text="{{maquina.home.porcentajeEurosHora}}%"></kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DISENO 3: PRECIO/HORA CON TIEMPO -->
        <div [ngClass]="configuracion.tipoPantalla == 2 ? 'inner-panel disenoTipo3' : 'inner-panel disenoTipo3'"
          *ngIf="configuracion.disenoSelected == 3">
          <!-- Pestaña de color-->
          <div id="pestanaColor" class="pestañaprocesos"
            [ngClass]="{'colorejecucion': maquina.home.idEstado == 1
                                                                          , 'colorparada': maquina.home.idEstado == 2
                                                                          , 'colorpreparacion': maquina.home.idEstado == 3
                                                                          , 'colormantenimiento': maquina.home.idEstado == 4
                                                                          , 'coloralarma': maquina.home.idEstado == 6
                                                                          , 'colorapagada': maquina.home.idEstado == 8}"></div>

          <!-- ROW 1 -->
          <div class="row">
            <!-- Imagen de las maquinas-->
            <div ID="maquina" class="col-3">
              <div id="estado">
                <div class="fotomaquina pqn">
                  <img ID="fotomaquina" src="{{maquina.imagenBase64}}" />
                </div>
              </div>
            </div>


            <div class="col-9 cabecera-maquina-tv">
              <!-- Nombre de la maquina -->
              <div class="modelo-operario-cont">
                <div class="nombre-modelo-maquina">
                  <div class="modelo-maquina">
                    <label ID="lmodelo">{{maquina.nombre}}</label>
                  </div>
                </div>
                <!-- Nombre del operario-->
                <div class="nombre-operario-maquina" *ngIf="maquina.home.datosOperarios.length == 1">
                  <div ID="persona" class="usuario">
                    <i class="icon-usuario"></i>
                    <label ID="nompersona">{{maquina.home.datosOperarios[0].operario}}</label>
                  </div>
                </div>
              </div>

              <div class="maquina-tiempo-estado-cont">
                <!-- Tiempo ejecucion -->
                <div>
                  <div class="tiempo-ejecucion-maquina">
                    <kendo-label text="{{timeDiff(maquina.home.tiempoEstado, maquina.home.now)}}"></kendo-label>
                  </div>
                  <div class="estado-maquina">
                    <div>
                      <div [ngClass]="{'ejecucion': maquina.home.idEstado == 1
                                  , 'parada': maquina.home.idEstado == 2
                                  , 'preparacion': maquina.home.idEstado == 3
                                  , 'mantenimiento': maquina.home.idEstado == 4
                                  , 'alarma': maquina.home.idEstado == 6
                                  , 'apagada': maquina.home.idEstado == 8}">
                        <kendo-label text="{{maquina.home.estado.toUpperCase()}}"></kendo-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="margen-borde-inferior"></div>
          <!-- ROW 2 -->
          <div class="row ">
            <!-- COL 1 -->
            <div class="col-6">
              <!-- ROW 2.1 -->
              <!-- TIEMPOS CALIDAD / MANTENIMIENTOS -->

              <div id="divDatosof" class="datos-of-tv"
                *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                <ul>
                  <li *ngIf="datoOperacion?.numeroOF!=''">
                    <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.numeroOF}}">
                      <strong>{{'of' |translate}} </strong>
                      {{datoOperacion?.numeroOF}}
                    </label>
                  </li>
                  <li *ngIf="datoOperacion?.numeroOF==''">
                    <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.numeroOF}}">
                      <strong>{{'of' |translate}} </strong>
                      --
                    </label>
                  </li>
                  <li *ngIf="datoOperacion?.pieza!=''">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'pieza' |translate}} </strong>
                      {{datoOperacion?.pieza}}
                    </label>
                  </li>
                  <li *ngIf="datoOperacion?.pieza==''">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'pieza' |translate}} </strong>
                      --
                    </label>
                  </li>
                  <li>
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom">
                      <strong>{{'tRestCalidad' | translate}}</strong>
                      00:00:00
                    </label>
                  </li>

                  <li *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom">
                      <strong>{{'tRestMantenimiento' | translate}} </strong>
                      {{faltaMantenimientoST.get(maquina.id)}}
                    </label>
                  </li>
                  <li *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom">
                      <strong>{{'tRestMantenimiento' | translate}} </strong>
                      {{ 'noMantenimientosCorto' | translate}}
                    </label>
                  </li>

                </ul>
              </div>
              <div class="datos-of-tv" *ngIf="maquina.sinConexion || maquina.home.datosOperaciones.length==0"
                class="label-datos-home-noidentificado">
                <label ID="ldatocodof" data-toggle="tooltip" data-placement="bottom" style="font-size:25px;">
                  {{ 'noproceso' | translate }}
                </label>
              </div>
            </div>

            <!-- COL 2 -->
            <div class="col-6 cont-porcentaje-flecha-horno">
              <!-- COL2 ROW1 -->
              <div class="row row d-flex align-items-center">
                <!-- EUROS/HORA -->
                <div class="col-4 euroshorahorno">
                  <div class="row d-flex justify-content-center">
                    <label class="euroshorahorno-titulo">€/h</label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <label class="euroshorahorno-valor">{{maquina.home.eurosHora}}</label>
                  </div>
                </div>
                <!-- FLETXA -->
                <div class="col-4">
                  <div class="row d-flex justify-content-center">
                    <div *ngIf="maquina.home.eurosHoraRojo">
                      <i class="icon-flechaabajo-alt-2"></i>
                    </div>
                    <div *ngIf="!maquina.home.eurosHoraRojo">
                      <i class="icon-flechaarriba-alt-2"></i>
                    </div>
                  </div>
                </div>
                <!-- PIEZAS/HORA -->
                <div class="col-4 euroshorahorno">
                  <div class="row d-flex justify-content-center">
                    <label class="euroshorahorno-titulo">{{'piezash'|translate}}</label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <label class="euroshorahorno-valor">{{maquina.home.piezasHora}}</label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <label class="euroshorahorno-valor">{{maquina.home.piezasHoraEstimado}}</label>
                  </div>
                </div>

              </div>

              <div class="margen-borde-inferior"></div>

              <!-- COL2 ROW2 -->
              <div class="row d-flex align-items-center lote-actual-cont"
                *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">

                <!-- PORTZENTAIA -->
                <div class="col-3">
                  <!-- Grafiko borobila lote -->
                  <div ID="enjecucion" class="porcent-cont-outer">
                    <div class="porcent-cont">
                      <label ID="lPorcentajeEjecucion" class="donut-porcent">
                        {{ datoOperacion?.porcentajeActualLote }}%
                      </label>
                      <img ID="imgPorcentajeEjecucion" src="{{datoOperacion?.estadoimg}}" />
                    </div>
                  </div>
                </div>

                <!-- TIEMPO LOTE ACTUAL -->
                <div class="col-5">
                  <div class="row d-flex justify-content-center">
                    <label class="lote-actual-titulo">{{'loteActual'| translate}}</label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <label class="lote-actual-valor">{{timeDiff(datoOperacion?.tiempoActualLote,
                      datoOperacion?.horaServidorTiempos)}}</label>
                  </div>
                </div>

                <!-- DESBIDERAPENA -->
                <div class="col-4">
                  <div class="row d-flex justify-content-center">
                    <label class="lote-actual-totaltime">{{'tiempoTotal' | translate}}</label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <label class="lote-actual-valor">{{datoOperacion?.desvioLote}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- DISENO 4: LOTE/PIEZA -->
        <div [ngClass]="configuracion.tipoPantalla == 2 ? 'inner-panel disenoTipo4' : 'inner-panel disenoTipo4'"
          *ngIf="configuracion.disenoSelected == 4">
          <!-- Pestaña de color-->
          <div id="pestanaColor" class="pestañaprocesos"
            [ngClass]="{'colorejecucion': maquina.home.idEstado == 1
                                                                          , 'colorparada': maquina.home.idEstado == 2
                                                                          , 'colorpreparacion': maquina.home.idEstado == 3
                                                                          , 'colormantenimiento': maquina.home.idEstado == 4
                                                                          , 'coloralarma': maquina.home.idEstado == 6
                                                                          , 'colorapagada': maquina.home.idEstado == 8}"></div>

          <!-- ROW 1 -->
          <div class="row">
            <!-- Imagen de las maquinas-->
            <div ID="maquina" class="col-3">
              <div id="estado">
                <div class="fotomaquina pqn">
                  <img ID="fotomaquina" src="{{maquina.imagenBase64}}" />
                </div>
              </div>
            </div>


            <div class="col-9  cabecera-maquina-tv">
              <!-- Nombre de la maquina -->
              <div class="modelo-operario-cont">
                <div class="nombre-modelo-maquina">
                  <div class="modelo-maquina">
                    <label ID="lmodelo">{{maquina.nombre}}</label>
                  </div>
                </div>
                <!-- Nombre del operario-->
                <div class="nombre-operario-maquina" *ngIf="maquina.home.datosOperarios.length == 1">
                  <div ID="persona" class="usuario">
                    <i class="icon-usuario"></i>
                    <label ID="nompersona">{{maquina.home.datosOperarios[0].operario}}</label>
                  </div>
                </div>
              </div>

              <div class="maquina-tiempo-estado-cont">
                <!-- Tiempo ejecucion -->
                <div>
                  <div class="tiempo-ejecucion-maquina">
                    <kendo-label text="{{timeDiff(maquina.home.tiempoEstado, maquina.home.now)}}"></kendo-label>
                  </div>
                  <div class="estado-maquina">
                    <div>
                      <div [ngClass]="{'ejecucion': maquina.home.idEstado == 1
                                  , 'parada': maquina.home.idEstado == 2
                                  , 'preparacion': maquina.home.idEstado == 3
                                  , 'mantenimiento': maquina.home.idEstado == 4
                                  , 'alarma': maquina.home.idEstado == 6
                                  , 'apagada': maquina.home.idEstado == 8}">
                        <kendo-label text="{{maquina.home.estado.toUpperCase()}}"></kendo-label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="margen-borde-inferior"></div>
          <!-- ROW 2 -->
          <div class="row ">
            <!-- COL 1 -->
            <div class="col-6">
              <!-- ROW 2.1 -->
              <!-- TIEMPOS CALIDAD / MANTENIMIENTOS -->

              <div id="divDatosof" class="datos-of-tv" *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                <ul>
                  <li *ngIf="datoOperacion?.numeroOF!=''">
                    <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.numeroOF}}">
                      <strong>{{'of' |translate}} </strong>
                      {{datoOperacion?.numeroOF}}
                    </label>
                  </li>
                  <li *ngIf="datoOperacion?.numeroOF==''">
                    <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.numeroOF}}">
                      <strong>{{'of' |translate}} </strong>
                      --
                    </label>
                  </li>
                  <li *ngIf="datoOperacion?.pieza!=''">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'pieza' |translate}} </strong>
                      {{datoOperacion?.pieza}}
                    </label>
                  </li>
                  <li *ngIf="datoOperacion?.pieza==''">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'pieza' |translate}} </strong>
                      --
                    </label>
                  </li>
                  <li>
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'tRestCalidad' | translate}}</strong>
                      00:00:00
                    </label>
                  </li>
                  <li *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'tRestMantenimiento' | translate}}</strong>
                      {{faltaMantenimientoST.get(maquina.id)}}
                    </label>
                  </li>
                  <li *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'tRestMantenimiento' | translate}}</strong>
                      {{ 'noMantenimientosCorto' | translate}}
                    </label>
                  </li>

                </ul>
              </div>
              <div class="datos-of-tv" *ngIf="maquina.home.datosOperaciones.length==0">
                <ul>
                  <li>
                    <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.numeroOF}}">
                      <strong>{{'of' |translate}} </strong>
                      --
                    </label>
                  </li>
                  <li>
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'pieza' |translate}} </strong>
                      --
                    </label>
                  </li>
                  <li>
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'tRestCalidad' | translate}}</strong>
                      00:00:00
                    </label>
                  </li>
                  <li *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'tRestMantenimiento' | translate}}</strong>
                      {{faltaMantenimientoST.get(maquina.id)}}
                    </label>
                  </li>
                  <li *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                    <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                      title="{{datoOperacion?.pieza}}">
                      <strong>{{'tRestMantenimiento' | translate}}</strong>
                      {{ 'noMantenimientosCorto' | translate}}
                    </label>
                  </li>

                </ul>
              </div>
            </div>

            <!-- COL 2 -->
            <div *ngIf="maquina.home.datosOperaciones.length > 0 && maquina.home.datosOperaciones[0]?.cantidadReal > 1" class="col-6 cont-porcentaje-flecha-horno">
              <div  *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                <!-- *ngIf="verLote == 0 && maquina.ejecuciones[0].cantidad > 1" -->

                <!-- COL2 ROW1 -->
                <div class="row d-flex align-items-center lote-actual-cont" *ngIf="datoOperacion?.cantidadReal > 1">
                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{datoOperacion?.porcentajeActualPieza}}%
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{datoOperacion?.estadoimgPieza}}" />
                      </div>
                    </div>
                  </div>

                  <!-- PIEZA ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'piezaActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{timeDiff(datoOperacion?.tiempoActualPieza, datoOperacion?.horaServidorTiempos)}}</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">{{'desvio' | translate}}</label>
                    </div>

                    <div>
                      <div class="cycle-time-desviacion">
                        <kendo-label class="lote-actual-valor"
                          text="{{myFunctions.secondsTo_HH_MM_SS(datoOperacion?.tiempoPiezaRitmo)}}"></kendo-label>
                      </div>
                      <!-- Estimatutako denborarekiko desbiderapena pieza -->
                      <div class="cycle-time-desviacion">
                        <div *ngIf="datoOperacion?.tiempoPiezaRojo">
                          <label style="color:red">
                            <kendo-label class="pieza-desviacion-valor"
                              text="{{datoOperacion?.desvioPieza}}"></kendo-label>
                          </label>
                        </div>
                        <div *ngIf="!datoOperacion?.tiempoPiezaRojo">
                          <kendo-label class="pieza-desviacion-valor"
                            text="{{datoOperacion?.desvioPieza}}"></kendo-label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="margen-borde-inferior"></div>

                <!-- COL2 ROW2 -->
                <div class="row d-flex align-items-center lote-actual-cont">

                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{datoOperacion?.porcentajeActualLote}}%
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{datoOperacion.estadoimg}}" />
                      </div>
                    </div>
                  </div>

                  <!-- TIEMPO LOTE ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'loteActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{timeDiff(datoOperacion?.tiempoActualLote, datoOperacion?.horaServidorTiempos)}}</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">{{'tiempoTotal' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center"
                      *ngIf="datoOperacion?.tiempoLoteRojo">
                      <label style="color:red">
                        <kendo-label class="lote-actual-valor"
                          text="{{datoOperacion?.desvioLote}}"></kendo-label>
                      </label>
                    </div>
                    <div class="row d-flex justify-content-center"
                      *ngIf="!datoOperacion?.tiempoLoteRojo">
                      <kendo-label class="lote-actual-valor" text="{{datoOperacion?.desvioLote}}"></kendo-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- COL 2 CANTIDAD = 1-->
            <div *ngIf="maquina.home.datosOperaciones.length > 0 && maquina.home.datosOperaciones[0]?.cantidadReal <= 1" class="col-6 cont-porcentaje-flecha-horno">
              <div  *ngFor="let datoOperacion of maquina.home.datosOperaciones; let i = index">
                <!-- *ngIf="verLote == 0 && maquina.ejecuciones[0].cantidad > 1" -->

                <!-- COL2 ROW1 -->
                <div class="row d-flex align-items-center lote-actual-cont">
                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{datoOperacion?.porcentajePiezaCentral.toString().substring(1)}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{datoOperacion?.estadoimgPieza}}" />
                      </div>
                    </div>
                  </div>

                  <!-- PIEZA ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'piezaActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{timeDiff(datoOperacion?.tiempoActualPieza, datoOperacion?.horaServidorTiempos)}}</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">Cycle time</label>
                    </div>

                    <div>
                      <div class="cycle-time-desviacion">
                        <kendo-label class="lote-actual-valor"
                          text="{{myFunctions.secondsTo_HH_MM_SS(datoOperacion?.tiempoPiezaRitmo)}}"></kendo-label>
                      </div>
                      <!-- Estimatutako denborarekiko desbiderapena pieza -->
                      <div class="cycle-time-desviacion">
                        <div *ngIf="datoOperacion?.tiempoPiezaRojo">
                          <label style="color:red">
                            <kendo-label class="pieza-desviacion-valor"
                              text="{{datoOperacion?.desvioPieza}}"></kendo-label>
                          </label>
                        </div>
                        <div *ngIf="!datoOperacion?.tiempoPiezaRojo">
                          <kendo-label class="pieza-desviacion-valor"
                            text="{{datoOperacion?.desvioPieza}}"></kendo-label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="margen-borde-inferior"></div>

              </div>
            </div>
            <!-- si no tiene operacion -->
            <div *ngIf="maquina.home.datosOperaciones.length == 0" class="col-6 cont-porcentaje-flecha-horno">
              <div >
                <!-- *ngIf="verLote == 0 && maquina.ejecuciones[0].cantidad > 1" -->

                <!-- COL2 ROW1 -->
                <div class="row d-flex align-items-center lote-actual-cont">
                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          0%
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="assets/dcg/dcg0-ejecucion.png" />
                      </div>
                    </div>
                  </div>

                  <!-- PIEZA ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'piezaActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">--:--:--</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">{{'desvio' | translate}}</label>
                    </div>

                    <div>
                      <div class="cycle-time-desviacion">
                        <kendo-label class="lote-actual-valor"
                          text="--"></kendo-label>
                      </div>
                      <!-- Estimatutako denborarekiko desbiderapena pieza -->
                      <div class="cycle-time-desviacion">
                        <div>
                          <kendo-label class="pieza-desviacion-valor"
                            text="--"></kendo-label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="margen-borde-inferior" class="d-none"></div>

                <!-- COL2 ROW2 -->
                <div class="row d-flex align-items-center lote-actual-cont" class="d-none">

                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          0%
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="assets/dcg/dcg0-ejecucion.png" />
                      </div>
                    </div>
                  </div>

                  <!-- TIEMPO LOTE ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'loteActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">--:--:--</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">{{'tiempoTotal' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <kendo-label class="lote-actual-valor" text="--"></kendo-label>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-container>
  </div>
</div>