import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({ templateUrl: 'hmiHornos.html' })

export class HMIHornosComponent implements OnInit {

  interval: any;
  interval2: any;
  interval3: any;

  idmaquina: any;
  maquina: any;
  maquinasModel: any;
  marcasModel: any;

  //CSS pestaña
  pestannaColor: any = "";

  // DATOS HORNO
  public datosConfiguracionHorno: any;

  //PANEL MAQUINA
  operario: any = "";
  tituloDonut: any = "-";
  fechaIni: any;
  public fechaServidor: any;
  tiempo: any = 0;
  tiempoEst: any = 0;
  tiempoPred: any = 0;
  public tiempoEstPreparacion: any = 0;
  public tiempoEstPreparacionST: string = ""
  receta: any = "";
  porcentaje_piezas_hora: any = 0;
  porcentaje_euros_hora: any = 0;
  tiempoEst_HH_MM_SS: any;
  tiempoPred_HH_MM_SS: any;
  public procesoTipo: number = 0;

  //LABELS OF
  public of: any = "";
  public of1: any = "";
  public cliente: any = "";
  public cliente1: any = "";
  public pieza: any = "";
  public pieza1: any = "";
  public realizadas: any = "";
  //LABELS DATOS
  public nCavidades: any = "";
  public material: any = "";
  public refMaterial: any = "";
  public refMolde: any = "";

  public eurosHora: any = "";
  public porcenEurosHora: any = "";
  public piezasHora: any = "";
  public porcenPiezasHora: any = "";
  public tRestCalidad: any = "";
  public temperatura: any = false;
  public kilowatio: any = false;
  public cantidad: any = "";
  public cantidad1: any = "";
  public hechas: any = "";
  public hechas1: any = "";
  public nombreOperacion: any = "";
  public nombreOperacion1: any = "";
  public cuantasHechas: any = "";
  public cuantasHechas1: any = "";
  public tEstTotal: any;
  public piezasHoraEst: any;
  public eurosHoraEst: any;

  // TIEMPOS NUEVOS
  public r1: boolean = false;
  public unicaEjecucionTiempos: boolean = true;
  public tiempoPiezaSegundos: number = -1;
  public tiempoLoteSegundos: number = -1;
  public tiempoLotePreparacion: string = "";
  public tiempoLotePreparacionSegundos: number = -1;
  public tiempoPiezaPreparacionSegundos: number = -1;
  public loadingDatosTiempo: boolean = true;
  public timeOutTiempos: any;
  public tiempoEjecucion: any;

  //LISTA RECETAS
  listaRecetas: any = [];
  primeraVezListaRecetasCargada: boolean = true;

  //GRAFICOS
  graficoEjecucion: any;
  graficoResumenSemana: any;

  //GRAFICO MULTIEJE
  public grafico1: any;
  public grafico2: any;
  public grafico3: any;
  public grafico4: any;
  public grafico5: any;
  public mostrarGraficosPorLeyenda: any = [true, true, true, true, true];
  public arrayFechas: any = ["-"];
  public fechaInicioZoom: any;
  public fechaFinZoom: any;
  public fechaIniRecetaSeleccionada: any;
  public fechaFinRecetaSeleccionada: any;
  public coloresMaquinaInduccion1 = [
    "#3366CC", //energia
    "#CB3737", //potencia
    "#32A814", //intensidad
    "#B4E50A", //voltaje
    "#B962C5", //frecuencia
    "#FFC260", //caudal
    "#FF69A3", //temperaturaPieza
    "#6DFACD", //presionBombaDucha
    "#C09927", //temperaturaPolimero
    "#A8AABC", //porcenPolimero
    "#3366CC", //velocidadRotacion
    "#CB3737", //posicionEje1
    "#32A814", //posicionEje2
  ];
  public colores = [
    "#3366CC", //min
    "#CB3737", //max
    "#32A814", //real
  ];
  public datosGraficoMultieje: any = {};
  public listaTooltip: any = [];

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;
  public segundosDeUnDia: number = 86400;

  //PESTANAS
  loadingPestannas: boolean = true;
  listaValoresTecnologicos: any = [];

  listaReceta: any = [];

  user = this.usuariosService.userValue;

  // GRAFICO RESUMEN SEMANA
  public chartDonutResumenSemana: any;
  // GRAFICO DE DATOS
  public chartDatos: any;

  private colorPreparacion: String = "#096844";
  private colorEjecucion: String = "#C0EADA";
  private colorParada: String = "#E7CB68";
  private colorMicroparada: String = "#cf8729";
  private colorMantenimiento: String = "#99AFCC";
  private colorAlarma: String = "#D33737";
  private colorApagada: String = "#424242";

  private tiempoTransition: Number = 2000;

  // PANEL DE LA MAQUINA
  public ejecucionActual: any;

  tiempoActualPieza = new Map();
  tiempoActualLote = new Map();
  tiempoCorregido: String = "";
  desviacion: String = "";
  mouseOverPorcentajeId = undefined;

  tiempoMedioPieza: number = 0;
  desviacionPieza: number = 0;
  desviacionLote: number = 0;
  tiempoDesvioPreparacion: string = "";
  tiempoEstimadoLoteAux: any;
  tiempoEstimadoLote: number = 0;
  signoDesviacionPieza: string = '';
  signoDesviacionLote: string = '';
  tiempoAcumuladoAux: any;
  tiempoAcumulado: number = 0;
  listaTiempo: any = [];
  listaTiempo2: any = [];

  piezaTiempoMedioMap = new Map();
  piezaDesviacionMap = new Map();
  loteDesviacionMap = new Map();

  tiempoEstAux: any;
  tiempoPredAux: any;
  piezaEstMap = new Map();
  piezaPredMap = new Map();

  piezaPorcentajeMap = new Map();
  piezaimgMap = new Map();
  isOFMap = new Map();
  public preparacionimgMap: string = "";
  public preparacionPorcentajeMap: string = "";

  // END PANEL DE LA MAQUINA

  // PROCESOS
  historicoProcesos: any;
  alarmas: any;
  planificados: any;
  datosGraficoArray: any = [{}];
  datosGraficoArray2: any;

  // MANTENIMIENTOS
  mantenimientoPorFecha: any = [];
  mantenimientoPorTiempo: any = [];

  // NOTAS
  notas: any;

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    public translateService: TranslateService,
    public myFunctions: MyFunctions) {

    this.interval = setInterval(() => {
      this.refreshData();
    }, 5000);

    this.interval2 = setInterval(() => {
      this.refreshDataTiempos();
    }, 1000);

    this.interval3 = setInterval(() => {
      this.refreshDataPestanas();
    }, 300000);

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('maquinaDeInduccion');
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));
    this.maquina = {
      nombre: "-",
      imagenBase64: "",
      marca: { imagenBase64: "" },
      tiempoReal: [],
    };
    this.calcularTiempos()
    this.cargarMaquinasModel();
  }

  posicionarEjesGrafico() {
    var element = document.getElementById("grafico1_hmiHor").getElementsByClassName("c3-event-rect")[0];
    var width = element["width"]["animVal"]["value"];

    // trasladar eje de los graficos
    document.getElementById("grafico1_hmiHor").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + 0 + "px, 0px)";
    if (this.maquina.tipoMaquinaInduccion == 1) {
      document.getElementById("grafico2_hmiHor").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + -64 + "px, 0px)";
      document.getElementById("grafico4_hmiHor").getElementsByClassName("c3-axis-y2")[0]["style"]["transform"]="translate(" + (width + 64) + "px, 0px)";
    }
  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }
  }

  cargarMaquina() {

    //COGEMOS LA MAQUINA
    this.maquina = this.maquinasModel.find(x => x.id === this.idmaquina);
    this.maquina.marca = this.marcasModel.find(x => x.id === this.maquina.idmarca);
    this.r1 = true;

    //PINTAMOS LOS GRAFICOS
    this.pintarGraficos();

  }

  pintarGraficos() {
    this.pintarGraficoEjecucion();
    this.pintarGraficoResumenSemana();
    this.cargarResumenSemana();
    // this.cargarGraficoDatos();
    setTimeout(function () {
      this.pintarGraficoMultiEje();
      //DESPUES DE PINTAR LOS GRAFICOS CARGAMOS TODOS LOS DATOS
      this.cargarTodosLosDatos();
    }.bind(this), 50);
  }

  pintarGraficoEjecucion() {

    this.graficoEjecucion = c3.generate({
      bindto: '#ejecucionGrafico_hmiHor',
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 14,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#ejecucionGrafico_hmiHor .c3-chart-arcs-title')
      .text("")
      .style("font-size", "26px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#ejecucionGrafico_hmiHor .c3-chart-arcs path").style("stroke-width", "0px");

  }

  pintarGraficoResumenSemana() {

    var that = this;
    this.graficoResumenSemana = c3.generate({
      bindto: '#resumenSemanaGrafico_hmiHor',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null,
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: [that.colorEjecucion, that.colorParada, that.colorMicroparada, that.colorPreparacion, that.colorMantenimiento, that.colorAlarma, that.colorApagada]
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });

  }

  cargarResumenSemana() {
    var that = this;
    this.chartDonutResumenSemana = c3.generate({
      bindto: '#chartDonutResumenSemana_hmi',
      data: {
        columns: [
          ["ejecucion", 0],
          ["parada", 0],
          ["microparada", 0],
          ["preparacion", 0],
          ["mantenimiento", 0],
          ["alarma", 0],
          ["apagada", 0]
        ],

        names: {
          ejecucion: this.translateService.instant("ejecucion") + " " + 0 + "%",
          parada: this.translateService.instant("parada") + " " + 0 + "%",
          microparada: this.translateService.instant("microparada") + " " + 0 + "%",
          preparacion: this.translateService.instant("preparacion") + " " + 0 + "%",
          mantenimiento: this.translateService.instant("mantenimiento") + " " + 0 + "%",
          alarma: this.translateService.instant("alarma") + " " + 0 + "%",
          apagada: this.translateService.instant("apagada") + " " + 0 + "%",
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana .c3-chart-arcs-title').node().innerHTML = "";
        },
        onclick: function (d) {
          var enlace = '#/informerendimiento/' + that.idmaquina;
          window.open(enlace, '_self');
        }
      },
      color: {
        pattern: [this.colorEjecucion, this.colorParada, this.colorMicroparada, this.colorPreparacion, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });
  }

  cargarGraficoDatos() {
    var that = this;
    this.chartDatos = c3.generate({
      bindto: '#chartDatos',
      data: {
        xs: {
          'energia': 'x',
          'potencia': 'x',
          'intensidad': 'x',
          'voltaje': 'x',
          // 'frecuencia': 'x', 
          // 'caudal': 'x', 
          // 'temperaturaPieza': 'x', 
          // 'presionBombaDucha': 'x', 
          // 'temperaturaPolimero': 'x', 
          // 'porcenPolimero': 'x'
        },
        columns: [
          ['x'],
          ['energia'],
          ['potencia'],
          ['intensidad'],
          ['voltaje'],
          // ['frecuencia'],
          // ['caudal'],
          // ['temperaturaPieza'],
          // ['presionBombaDucha'],
          // ['temperaturaPolimero'],
          // ['porcenPolimero']
        ],
        names: {
          energia: this.translateService.instant("energia"),
          potencia: this.translateService.instant("potencia"),
          intensidad: this.translateService.instant("intensidad"),
          voltaje: this.translateService.instant("voltaje"),
          // frecuencia: this.translateService.instant("frecuencia"),
          // caudal: this.translateService.instant("caudal"),
          // temperaturaPieza: this.translateService.instant("temperaturaPieza"),
          // presionBombaDucha: this.translateService.instant("presion"),
          // temperaturaPolimero: this.translateService.instant("temperaturaPolimero"),
          // porcenPolimero: this.translateService.instant("porcenPolimero")
        },
        onclick: function (d) {
          that.chartDatos.hide(d.id);
        }
      },
      color: {
        pattern: [this.colorPreparacion, this.colorEjecucion, this.colorMicroparada, this.colorParada, this.colorMantenimiento, this.colorAlarma, this.colorApagada]
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d'
          }
        },
        y: {
          //min: 0,
          //max: max,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            // values: values,
            // format: function (d) {
            //   const sec = parseInt(d, 10);
            //   let hours = Math.floor(sec / 3600);
            //   let minutes = Math.floor((sec - (hours * 3600)) / 60);
            //   let seconds = sec - (hours * 3600) - (minutes * 60);
            //   return hours + "h";
            // }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      // tooltip: {
      //   format: {
      //     value: function (value) {
      //       var hours = Math.floor(value / (60 * 60));
      //       var divisor_for_minutes = value % (60 * 60);
      //       var minutes = Math.floor(divisor_for_minutes / 60);
      //       if (0 <= minutes && minutes < 10)
      //         return hours + ":0" + minutes + "h";
      //       else
      //         return hours + ":" + minutes + "h";
      //     }
      //   }
      // },
      grid: {
        y: {
          show: true
        }
      }
    });
    d3.selectAll("#chartDatos .c3-chart-arcs path").style("stroke-width", "0px");
  }

  nFormatter(num, digits) {
    if (num?.toString().length > 3) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
      });
      return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    } else {
      return num;
    }

  }

  pintarGraficoMultiEje() {

    var th = this;

    // Esto es necesario para definir la cantidad de graficos segun el tipo de maquina de induccion
    var max = 1;
    if (this.maquina.tipoMaquinaInduccion == 1) max = 4

    var i;
    for (i = 1; i <= max; i++) {

      let information = th.getLabel(i-1);
      let labelAux = information.label;
      let minAux = information.min;
      let maxAux = information.max;
      let positionAux = 'outer-middle';
      let showY1axis = true;
      let showY2axis = true;
      let yAxis = 'y2';
      if (i <= 2) { showY2axis = false; yAxis = 'y'}
      else showY1axis = false;

      var chart = c3.generate({
        bindto: "#grafico" + i + "_hmiHor",
        padding: {
          left: 128, // 124
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            ['x'],
            ['valor']
          ],
          colors: {
            valor: th.colores[i - 1],
          },
          order: null,
          axes: {
            valor: yAxis
         }
        },
        regions: [],
        transition: {
          duration: 0
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              // values: function (x) {
              //   var dif = (x[1].getTime() - x[0].getTime()) / 1000;

              //   var fechaIni = x[0];
              //   fechaIni.setMinutes(fechaIni.getMinutes() - 10);
              //   var fechaFin = x[1];
              //   fechaFin.setMinutes(fechaFin.getMinutes() + 10);

              //   if (dif < 180)
              //     return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(120);
              //   else if (dif < 1800)
              //     return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(15);
              //   else
              //     return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(10);
              // },
              //format: '%m/%d - %H:%M:%S'
              format: function (x) {
                if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                  if (x.getDay() == 0) return th.translateService.instant("domingo");
                  if (x.getDay() == 1) return th.translateService.instant("lunes");
                  if (x.getDay() == 2) return th.translateService.instant("martes");
                  if (x.getDay() == 3) return th.translateService.instant("miercoles");
                  if (x.getDay() == 4) return th.translateService.instant("jueves");
                  if (x.getDay() == 5) return th.translateService.instant("viernes");
                  if (x.getDay() == 6) return th.translateService.instant("sabado");
                } else {
                  return th.myFunctions.dateToHHMM(x);
                }
              },
            },
            padding: 0
          },
          y: {
            show: showY1axis,
            label: { 
              position: positionAux,
              text: labelAux
            },
            min: minAux,
            max: maxAux,
            padding: 0,
            tick: {
              count: 11,
              format: function(x) {
                return th.myFunctions.numero_con_formato(x, 1)
              }
            }
          },
          y2: {
            show: showY2axis,
            label: { 
              position: positionAux,
              text: labelAux
            },
            min: minAux,
            max: maxAux,
            padding: 0,
            tick: {
              count: 11,
              format: function(x) {
                return th.myFunctions.numero_con_formato(x, 1)
              }
            }
          }
        },
        legend: {
          show: false
        },
        point: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {

            var fecha = d[0].x;

            if (fecha >= th.fechaInicioZoom && fecha <= th.fechaFinZoom) {

              var $$ = this, config = $$.config,
                titleFormat = config.tooltip_format_title || defaultTitleFormat,
                nameFormat = config.tooltip_format_name || function (name) { return name; },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text, i, title, value, name, bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }

                if (!text) {
                  title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                  text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                }

              }

              var indice = th.arrayFechas.findIndex(x => x == d[0].x);
              var camposSeleccionados = [];

              th.listaTooltip.forEach(function (campo) {
                if (campo.seleccionadoLeyenda) camposSeleccionados.push(campo);
              }, this);

              var j = 0;
              camposSeleccionados.forEach(function (campo) {

                if (th.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] && campo.valoresMomentaneo[j][indice] != undefined) {
                  name = th.translateService.instant(campo.nombre);

                  value = campo.valoresMomentaneo[j][indice] + " " + campo.unidad;

                  bgcolor = campo.color;
                  text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[0].id + "'>";
                  text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                  text += "<td class='value'>" + th.nFormatter(value, 1) + "</td>";
                  text += "</tr>";
                }
                j++;
              }, this);

              return text + "</table>";

            }

          }
        },
        zoom: {
          enabled: (i == 1),
          type: 'drag',
          disableDefaultBehavior: true,
          onzoomend: function (domain) {
            th.fechaInicioZoom = domain[0];
            th.fechaFinZoom = domain[1];
            th.aplicarZoomEnGrafico();
          }
        }
      });

      if (i == 1) this.grafico1 = chart;
      if (i == 2) this.grafico2 = chart;
      if (i == 3) this.grafico3 = chart;
      if (i == 4) this.grafico4 = chart;
      // if (i == 5) this.grafico5 = chart;

    }

    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#grafico1_hmiHor svg').node().childNodes;
    list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

    d3.select('#grafico1_hmiHor .c3-event-rect')._groups[0][0].onmousedown = function (e) {
      if (e.button != 0) {
        var evt = new MouseEvent("mousedown", {
          altKey: e.altKey,
          bubbles: e.bubbles,
          button: 0,
          buttons: e.buttons,
          cancelable: e.cancelable,
          clientX: e.clientX,
          clientY: e.clientY,
          composed: e.composed,
          ctrlKey: e.ctrlKey,
          detail: e.detail,
          metaKey: e.metaKey,
          movementX: e.movementX,
          movementY: e.movementY,
          relatedTarget: e.relatedTarget,
          screenX: e.screenX,
          screenY: e.screenY,
          shiftKey: e.shiftKey,
          view: e.view
        });
        d3.select("#grafico1_hmiHor .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
      }
    }

    if (this.maquina.tipoMaquinaInduccion == 1) {
      d3.select('#grafico2_hmiHor .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiHor .c3-event-rect')._groups[0][0].onmousedown;
      d3.select('#grafico3_hmiHor .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiHor .c3-event-rect')._groups[0][0].onmousedown;
      d3.select('#grafico4_hmiHor .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiHor .c3-event-rect')._groups[0][0].onmousedown;
    }
      // d3.select('#grafico5_hmiHor .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiHor .c3-event-rect')._groups[0][0].onmousedown;

  }

  public getLabel(index) {
    var nombre;
    try {
      nombre = this.translateService.instant(this.listaTooltip.filter(f => f.seleccionadoLeyenda)[index]?.nombre);
    } catch {
      nombre = this.listaTooltip.filter(f => f.seleccionadoLeyenda)[index]?.nombre; 
    }
    return {label: nombre, min: 0, max: 100}
  }

  cargarTodosLosDatos() {

    this.centroMecanizadoService.GetHmiHornoById(this.idmaquina, this.maquina.tipoMaquinaInduccion).subscribe((result) => {

      var datos: any = result;
      var ejecucionActual = datos.ejecuciones;
      this.ejecucionActual = datos.ejecuciones;

      if (ejecucionActual.length > 0 && ejecucionActual != null) {

        //pestannaColor
        if (ejecucionActual[0].idProcesos_tipo == 1) this.pestannaColor = "pestañaprocesos colorejecucion";
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.pestannaColor = "pestañaprocesos colorparada";
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.pestannaColor = "pestañaprocesos colorpreparacion";
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.pestannaColor = "pestañaprocesos colormantenimiento";
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.pestannaColor = "pestañaprocesos coloralarma";
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.pestannaColor = "pestañaprocesos colorapagada";
        else this.pestannaColor = "pestañaprocesos colorejecucion";
        this.procesoTipo = ejecucionActual[0].idProcesos_tipo;
        //PANEL MAQUINA
        if (ejecucionActual[0].operario == " ") this.operario = this.translateService.instant('sinOperario');
        else this.operario = ejecucionActual[0].operario;

        this.fechaIni = new Date(ejecucionActual[0].fecha);
        this.fechaServidor = new Date(ejecucionActual[0].horaServidor);
        //this.fechaIni = new Date(datos.datosHistorico[0].fecha);
        this.tiempoEst = ejecucionActual[0].tiempoEstimadoEjecucion;
        this.tiempoPred = ejecucionActual[0].tiempoPredictivoEjecucion;
        this.tiempoEstPreparacion = ejecucionActual[0].tiempoEstimadoPreparacion;
        this.tiempoEstPreparacionST = this.corregirFormato(ejecucionActual[0].tiempoEstimadoPreparacion);
        //this.tiempoEst_HH_MM_SS = this.myFunctions.secondsTo_HH_MM_SS(this.tiempoEst);
        this.tiempoEst_HH_MM_SS = this.corregirFormato(this.tiempoEst);
        //this.tiempoPred_HH_MM_SS = this.myFunctions.secondsTo_HH_MM_SS(this.tiempoPred);
        this.tiempoPred_HH_MM_SS = this.corregirFormato(this.tiempoPred);

        //this.receta = ejecucionActual[0].nReceta;

        if (ejecucionActual[0].idProcesos_tipo == 1) this.tituloDonut = this.translateService.instant("ejecucion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.tituloDonut = this.translateService.instant("parada").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.tituloDonut = this.translateService.instant("preparacion").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.tituloDonut = this.translateService.instant("mantenimiento").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.tituloDonut = this.translateService.instant("alarma").toUpperCase();
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.tituloDonut = this.translateService.instant("apagada").toUpperCase();
        else this.tituloDonut = this.translateService.instant("ejecucion").toUpperCase();

        //GRAFICO EJECUCION
        var ahora: any = this.myFunctions.getDateNow();
        var segundosfiff = (ahora - this.fechaIni) / 1000;
        var porcen = this.calcularPorcentaje(segundosfiff, 100, this.tiempoEst, 1, true);
        d3.select('#ejecucionGrafico_hmiHor .c3-chart-arcs-title').text(Math.round(porcen) + "%");
        this.graficoEjecucion.load({ columns: [['completo', porcen], ['nocompleto', 100 - porcen]] });

        //DATOS PANEL
        this.of = ejecucionActual[0].of;
        this.of1 = ejecucionActual[0].of;
        this.cliente = ejecucionActual[0].cliente;
        this.cliente1 = ejecucionActual[0].cliente;
        this.pieza = ejecucionActual[0].pieza;
        this.pieza1 = ejecucionActual[0].pieza;
        if (ejecucionActual[0].of == undefined || ejecucionActual[0].of == "")
          this.realizadas = "";
        else
          this.realizadas = ejecucionActual[0].cuantasHechas + "/" + ejecucionActual[0].cantidad;

        this.nCavidades = ejecucionActual[0].nCavidades;
        this.material = ejecucionActual[0].material;
        this.refMaterial = ejecucionActual[0].refMaterial;
        this.refMolde = ejecucionActual[0].refMolde;

        //this.eurosHora = ejecucionActual[0].euros_hora;
        this.eurosHora = Math.round(ejecucionActual[0].piezas_hora * this.ejecucionActual[0].coste * 100) / 100;
        // this.porcenEurosHora = ejecucionActual[0].porcentaje_euros_hora;
        this.piezasHora = ejecucionActual[0].piezas_hora;
        // this.porcenPiezasHora = ejecucionActual[0].porcentaje_piezas_hora;
        this.tRestCalidad = this.myFunctions.secondsTo_HH_MM_SS(ejecucionActual[0].tRestanteCalidad);
        this.temperatura = ejecucionActual[0].temperatura;
        this.kilowatio = ejecucionActual[0].kilowatio;
        this.cantidad = ejecucionActual[0].cantidad;
        this.cantidad1 = ejecucionActual[0].cantidad;
        this.hechas = ejecucionActual[0].hechas;
        this.nombreOperacion = ejecucionActual[0].nombreOperacion;
        this.nombreOperacion1 = ejecucionActual[0].nombreOperacion;
        this.cuantasHechas = ejecucionActual[0].cuantasHechas;
        this.cuantasHechas1 = ejecucionActual[0].cuantasHechas;
        var tEstTotalSec = (ejecucionActual[0].tiempoEstimadoEjecucion * ejecucionActual[0].cantidad) + ejecucionActual[0].tiempoEstimadoPreparacion;
        this.tEstTotal = Math.floor(tEstTotalSec / 3600);
        if (this.tEstTotal == 0) this.piezasHoraEst = 0;
        else this.piezasHoraEst = Math.floor(ejecucionActual[0].cantidad / this.tEstTotal);

        this.eurosHoraEst = Math.round(this.piezasHoraEst * this.ejecucionActual[0].coste * 100) / 100;
        var porcenPieza = (ejecucionActual[0].piezas_hora / this.piezasHoraEst) * 100;
        this.porcenPiezasHora = porcenPieza.toFixed(2);
        var porcenEuros = (this.eurosHora / this.eurosHoraEst) * 100;
        this.porcenEurosHora = porcenEuros.toFixed(2);

      }

      //RESUMEN SEMANA
      var tiempoPorSemana = datos.tiempoPorSemana;

      if (tiempoPorSemana.length == 0) {
        tiempoPorSemana = [{ porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }];
      }

      var tiempoPorSemanaData = [[this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
      [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
      [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
      [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
      [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
      [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
      [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]];

      this.graficoResumenSemana.load({ columns: tiempoPorSemanaData });

      var tiempoPorSemanaDataPrueba = {
        columns: [
          ["ejecucion", tiempoPorSemana[0].porcentaje],
          ["parada", tiempoPorSemana[1].porcentaje],
          ["microparada", tiempoPorSemana[2].porcentaje],
          ["preparacion", tiempoPorSemana[3].porcentaje],
          ["mantenimiento", tiempoPorSemana[4].porcentaje],
          ["alarma", tiempoPorSemana[5].porcentaje],
          ["apagada", tiempoPorSemana[6].porcentaje]
        ],
        names: {
          ejecucion: this.translateService.instant("ejecucion") + " " + tiempoPorSemana[0].porcentaje + "%",
          parada: this.translateService.instant("parada") + " " + tiempoPorSemana[1].porcentaje + "%",
          microparada: this.translateService.instant("microparada") + " " + tiempoPorSemana[2].porcentaje + "%",
          preparacion: this.translateService.instant("preparacion") + " " + tiempoPorSemana[3].porcentaje + "%",
          mantenimiento: this.translateService.instant("mantenimiento") + " " + tiempoPorSemana[4].porcentaje + "%",
          alarma: this.translateService.instant("alarma") + " " + tiempoPorSemana[5].porcentaje + "%",
          apagada: this.translateService.instant("apagada") + " " + tiempoPorSemana[6].porcentaje + "%",
        }
      };
      this.chartDonutResumenSemana.load(tiempoPorSemanaDataPrueba);


      //LISTA RECETAS
      var lRecetas = datos.listaRecetas;
      if (datos.listaRecetas.length == 0) {
        this.receta = [];
        this.loadingPestannas = false;
      } else
        this.receta = datos.listaRecetas[datos.listaRecetas.length - 1].id; // ultima receta

      //LES PONEMOS LA FECHA BIEN
      for (var i = 0; i < lRecetas.length; i++) {
        lRecetas[i].fechaIni = new Date(lRecetas[i].fechaIni);
        lRecetas[i].fechaFin = new Date(lRecetas[i].fechaFin);
      }

      //LES PONEMOS EL TIEMPO DE RECETA
      for (var i = 0; i < lRecetas.length; i++) {
        if (i == lRecetas.length - 1 && lRecetas[i].fechaFin.getFullYear() < 2000) { //es la ultima receta
          var fechaActual: any = new Date();
          lRecetas[i].tiempoInyeccion = (fechaActual - lRecetas[i].fechaIni) / 1000;
        } else { // no es la ultima receta
          lRecetas[i].tiempoInyeccion = (lRecetas[i].fechaFin - lRecetas[i].fechaIni) / 1000;
        }
      }

      //Metemos los recetas que no esten en la lista
      lRecetas.forEach(function (receta) {
        //Miramos a ver si el receta ya está metido en la lista
        var recetaExistente = this.listaRecetas.find(x => x.id == receta.id);
        if (recetaExistente == undefined) {
          this.listaRecetas.unshift(receta);
          //Si es la ultima receta de la lista y es la primera vez que se cargan las recetas => calculamos los datos de las pestañas
          if (this.listaRecetas.length == lRecetas.length && this.primeraVezListaRecetasCargada) {
            this.primeraVezListaRecetasCargada = false;
            this.clickReceta(this.listaRecetas[0]);
          }
        } else { //Si ya esta metida, le actualizamos el tiempoInyeccion
          recetaExistente.tiempoInyeccion = receta.tiempoInyeccion;
          recetaExistente.piezas = receta.piezas;
        }
      }, this);

      //Si ya teniamos metido algun receta que ya no esta, lo borramos
      this.listaRecetas.forEach(function (receta) {
        //Miramos a ver si el receta ya está metido en la lista
        var recetaExistente = lRecetas.find(x => x.id == receta.id);
        if (recetaExistente == undefined) this.listaRecetas = this.listaRecetas.filter(x => x.id != receta.id);
      }, this);

      this.calcularCanales(this.maquina, 0);
      // this.calcularTiempoEstPredPieza(this.maquina);
      // this.calcularDesviacionPieza(this.maquina);
      this.calcularDesviacionLote(this.maquina);
      this.CargarIconosEstadoMaquina(this.maquina);

    });

    this.cargarDatosProcesos();
    this.cargarDatosMantenimientos();
    this.cargarDatosNotas();

  }

  // calcularTiempos() {
  //   if (this.fechaIni != null && this.fechaIni != undefined) {
  //     //var ahora: any = this.myFunctions.getDateNow();
  //     var ahora: any = this.ejecucionActual[0].horaServidor;
  //     this.tiempo = (ahora - this.fechaIni) / 1000;
  //     this.tiempoCorregido = this.corregirFormato(this.tiempo);
  //     this.calcularDesviacion();
  //   }
  // }

  refreshData() {
    if (this.router.url == '/procesoshorno/' + this.idmaquina) {
      this.cargarTodosLosDatos();
    } else {
      clearInterval(this.interval);
      this.calcularCanales(this.maquina, 0);
    }
  }

  refreshDataTiempos() {
    if (this.router.url == '/procesoshorno/' + this.idmaquina) {
      //this.calcularTiempos();
    } else {
      clearInterval(this.interval2);
    }
  }

  clickReceta(receta) {
    this.loadingPestannas = true;

    this.listaRecetas.map((c) => c.seleccionado = false);
    receta.seleccionado = true;

    var listaIdRecetas = this.conseguirIdsRecetasAnterioresPosteriores();//Vamos a cargar los datos de 10 recetas antes y 10 recetas despues de la receta seleccionada, para que no carge tan pocos datos

    //PESTANAS
    this.centroMecanizadoService.GetDatosRecetaHornoById(this.idmaquina, receta.id, listaIdRecetas.toString(), this.maquina.tipoMaquinaInduccion).subscribe(
      (result) => {
        var datos: any = result;

        //GRAFICO MULTIEJE
        this.datosGraficoMultieje = {
          fechas: [],
          energia: [], energia_min: [], energia_max: [],
          potencia: [], potencia_min: [], potencia_max: [],
          intensidad: [], intensidad_min: [], intensidad_max: [],
          voltaje: [], voltaje_min: [], voltaje_max: [],
          frecuencia: [], frecuencia_min: [], frecuencia_max: [],
          caudal: [], caudal_min: [], caudal_max: [],
          temperaturaPieza: [], temperaturaPieza_min: [], temperaturaPieza_max: [],
          presionBombaDucha: [], presionBombaDucha_min: [], presionBombaDucha_max: [],
          temperaturaPolimero: [], temperaturaPolimero_min: [], temperaturaPolimero_max: [],
          porcenPolimero: [], porcenPolimero_min: [], porcenPolimero_max: [],
          velocidadRotacion: [], velocidadRotacion_min: [], velocidadRotacion_max: [],
          posicionEje1: [], posicionEje1_min: [], posicionEje1_max: [],
          posicionEje2: [], posicionEje2_min: [], posicionEje2_max: [],
        }

        if (datos.datosHistorico != null) {
          datos.datosHistorico.forEach(function (row) {
            row.fecha_date = this.myFunctions.sqlToJsDate(row.fecha);
            row.fecha_time = this.myFunctions.sqlToJsDate(row.fecha).getTime();

            this.datosGraficoMultieje.fechas.push(row.fecha_date);
            this.datosGraficoMultieje.energia.push(row.energia);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.energia_min.push(row.energia_min);
              this.datosGraficoMultieje.energia_max.push(row.energia_max);
            }

            this.datosGraficoMultieje.potencia.push(row.potencia);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.potencia_min.push(row.potencia_min);
              this.datosGraficoMultieje.potencia_max.push(row.potencia_max);
            }

            this.datosGraficoMultieje.intensidad.push(row.intensidad);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.intensidad_min.push(row.intensidad_min);
              this.datosGraficoMultieje.intensidad_max.push(row.intensidad_max);
            }

            this.datosGraficoMultieje.voltaje.push(row.voltaje);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.voltaje_min.push(row.voltaje_min);
              this.datosGraficoMultieje.voltaje_max.push(row.voltaje_max);
            }

            this.datosGraficoMultieje.frecuencia.push(row.frecuencia);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.frecuencia_min.push(row.frecuencia_min);
              this.datosGraficoMultieje.frecuencia_max.push(row.frecuencia_max);
            }

            this.datosGraficoMultieje.caudal.push(row.caudal);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.caudal_min.push(row.caudal_min);
              this.datosGraficoMultieje.caudal_max.push(row.caudal_max);
            }

            this.datosGraficoMultieje.temperaturaPieza.push(row.temperaturaPieza);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.temperaturaPieza_min.push(row.temperaturaPieza_min);
              this.datosGraficoMultieje.temperaturaPieza_max.push(row.temperaturaPieza_max);
            }

            this.datosGraficoMultieje.presionBombaDucha.push(row.presionBombaDucha);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.presionBombaDucha_min.push(row.presionBombaDucha_min);
              this.datosGraficoMultieje.presionBombaDucha_max.push(row.presionBombaDucha_max);
            }

            this.datosGraficoMultieje.temperaturaPolimero.push(row.temperaturaPolimero);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.temperaturaPolimero_min.push(row.temperaturaPolimero_min);
              this.datosGraficoMultieje.temperaturaPolimero_max.push(row.temperaturaPolimero_max);
            }

            this.datosGraficoMultieje.porcenPolimero.push(row.porcenPolimero);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.porcenPolimero_min.push(row.porcenPolimero_min);
              this.datosGraficoMultieje.porcenPolimero_max.push(row.porcenPolimero_max);
            }
            
            this.datosGraficoMultieje.velocidadRotacion.push(row.velocidadRotacion);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.velocidadRotacion_min.push(row.velocidadRotacion_min);
              this.datosGraficoMultieje.velocidadRotacion_max.push(row.velocidadRotacion_max);
            }
            
            this.datosGraficoMultieje.posicionEje1.push(row.posicionEje1);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.posicionEje1_min.push(row.posicionEje1_min);
              this.datosGraficoMultieje.posicionEje1_max.push(row.posicionEje1_max);
            }
            
            this.datosGraficoMultieje.posicionEje2.push(row.posicionEje2);
            if (this.maquina.tipoMaquinaInduccion == 2) {
              this.datosGraficoMultieje.posicionEje2_min.push(row.posicionEje2_min);
              this.datosGraficoMultieje.posicionEje2_max.push(row.posicionEje2_max);
            }


          }, this);
        }

        this.maquinasService.GetHornoById(this.idmaquina).pipe().subscribe((result) => {

          //#region RECETA    
          this.datosConfiguracionHorno = result.data[0];
          if (this.maquina.tipoMaquinaInduccion != 2) {
            if (datos.datosReceta.length > 0 && datos.datosRecetaOriginal.length > 0) {
              var dReceta = datos.datosReceta[0];
              var dRecetaOriginal = datos.datosRecetaOriginal[0];
              //TIENE DATOS
              this.listaReceta = [
                { nombre: this.translateService.instant("OP_activar_soplador_1"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_1, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_1, modificado: dReceta.oP_activar_soplador_1 },
                { nombre: this.translateService.instant("OP_activar_soplador_2"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_2, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_2, modificado: dReceta.oP_activar_soplador_2 },
                { nombre: this.translateService.instant("OP_activar_soplador_3"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_3, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_3, modificado: dReceta.oP_activar_soplador_3 },
                { nombre: this.translateService.instant("OP_activar_soplador_4"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_4, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_4, modificado: dReceta.oP_activar_soplador_4 },
                { nombre: this.translateService.instant("OP_activar_soplador_5"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_5, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_5, modificado: dReceta.oP_activar_soplador_5 },
                { nombre: this.translateService.instant("OP_activar_soplador_6"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_6, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_6, modificado: dReceta.oP_activar_soplador_6 },
                { nombre: this.translateService.instant("OP_activar_soplador_7"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_7, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_7, modificado: dReceta.oP_activar_soplador_7 },
                { nombre: this.translateService.instant("OP_activar_soplador_8"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_8, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_8, modificado: dReceta.oP_activar_soplador_8 },
                { nombre: this.translateService.instant("OP_activar_soplador_9"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_9, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_9, modificado: dReceta.oP_activar_soplador_9 },
                { nombre: this.translateService.instant("OP_activar_soplador_10"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_10, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_10, modificado: dReceta.oP_activar_soplador_10 },
                { nombre: this.translateService.instant("OP_activar_soplador_11"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_11, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_11, modificado: dReceta.oP_activar_soplador_11 },
                { nombre: this.translateService.instant("OP_activar_soplador_12"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_12, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_12, modificado: dReceta.oP_activar_soplador_12 },
                { nombre: this.translateService.instant("OP_activar_soplador_13"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_13, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_soplador_13, modificado: dReceta.oP_activar_soplador_13 },
                { nombre: this.translateService.instant("OP_activar_salida_cuba"), ensennar: this.datosConfiguracionHorno.op_activar_salida_cuba, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_activar_salida_cuba, modificado: dReceta.oP_activar_salida_cuba },
                { nombre: this.translateService.instant("OP_control_paso_pieza"), ensennar: this.datosConfiguracionHorno.op_control_paso_pieza, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_control_paso_pieza, modificado: dReceta.oP_control_paso_pieza },
                { nombre: this.translateService.instant("OP_rodillo_introductor"), ensennar: this.datosConfiguracionHorno.op_rodillo_introductor, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_rodillo_introductor, modificado: dReceta.oP_rodillo_introductor },
                { nombre: this.translateService.instant("OP_giro_plato"), ensennar: this.datosConfiguracionHorno.op_giro_plato, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_giro_plato, modificado: dReceta.oP_giro_plato },
                { nombre: this.translateService.instant("OP_control_energia_activado"), ensennar: this.datosConfiguracionHorno.op_control_energia_activado, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_control_energia_activado, modificado: dReceta.oP_control_energia_activado },
                { nombre: this.translateService.instant("OP_control_caudal_activado"), ensennar: this.datosConfiguracionHorno.op_control_caudal_activado, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_control_caudal_activado, modificado: dReceta.oP_control_caudal_activado },
                { nombre: this.translateService.instant("OP_control_temperatura_activado"), ensennar: this.datosConfiguracionHorno.op_control_temperatura_activado, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_control_temperatura_activado, modificado: dReceta.oP_control_temperatura_activado },
                { nombre: this.translateService.instant("OP_control_presion_activado"), ensennar: this.datosConfiguracionHorno.op_control_presion_activado, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.oP_control_presion_activado, modificado: dReceta.oP_control_presion_activado },
                { nombre: this.translateService.instant("GEN_configuracion_seleccionada"), ensennar: this.datosConfiguracionHorno.gen_configuracion_seleccionada, unidad: "", estaEnPestanna: 1, original: dRecetaOriginal.geN_configuracion_seleccionada, modificado: dReceta.geN_configuracion_seleccionada },
                { nombre: this.translateService.instant("SP_vibrador_1"), ensennar: this.datosConfiguracionHorno.sp_vibrador_1, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_vibrador_1, modificado: dReceta.sP_vibrador_1 },
                { nombre: this.translateService.instant("SP_mantenimiento_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_mantenimiento_vibrador_2, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_mantenimiento_vibrador_2, modificado: dReceta.sP_mantenimiento_vibrador_2 },
                { nombre: this.translateService.instant("SP_lenta_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_lenta_vibrador_2, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_lenta_vibrador_2, modificado: dReceta.sP_lenta_vibrador_2 },
                { nombre: this.translateService.instant("SP_recuperacion_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_recuperacion_vibrador_2, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_recuperacion_vibrador_2, modificado: dReceta.sP_recuperacion_vibrador_2 },
                { nombre: this.translateService.instant("SP_vibrador_3"), ensennar: this.datosConfiguracionHorno.sp_vibrador_3, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_vibrador_3, modificado: dReceta.sP_vibrador_3 },
                { nombre: this.translateService.instant("CV_cinta_salida"), ensennar: this.datosConfiguracionHorno.cv_cinta_salida, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.cV_cinta_salida, modificado: dReceta.cV_cinta_salida },
                { nombre: this.translateService.instant("CV_proceso_giro"), ensennar: this.datosConfiguracionHorno.cv_proceso_giro, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.cV_proceso_giro, modificado: dReceta.cV_proceso_giro },
                { nombre: this.translateService.instant("CV_rapida_giro"), ensennar: this.datosConfiguracionHorno.cv_rapida_giro, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.cV_rapida_giro, modificado: dReceta.cV_rapida_giro },
                { nombre: this.translateService.instant("consigna_calor"), ensennar: this.datosConfiguracionHorno.consigna_calor, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.consigna_calor, modificado: dReceta.consigna_calor },
                { nombre: this.translateService.instant("velocidad_rapida_subida_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_rapida_subida_volteador, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.velocidad_rapida_subida_volteador, modificado: dReceta.velocidad_rapida_subida_volteador },
                { nombre: this.translateService.instant("velocidad_lenta_subida_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_lenta_subida_volteador, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.velocidad_lenta_subida_volteador, modificado: dReceta.velocidad_lenta_subida_volteador },
                { nombre: this.translateService.instant("velocidad_bajada_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_bajada_volteador, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.velocidad_bajada_volteador, modificado: dReceta.velocidad_bajada_volteador },
                { nombre: this.translateService.instant("SP_consigna_velocidad"), ensennar: this.datosConfiguracionHorno.sp_consigna_velocidad, unidad: "rpm", estaEnPestanna: 2, original: dRecetaOriginal.sP_consigna_velocidad, modificado: dReceta.sP_consigna_velocidad },
                { nombre: this.translateService.instant("OP_piezas_por_giro"), ensennar: this.datosConfiguracionHorno.op_piezas_por_giro, unidad: "uds.", estaEnPestanna: 2, original: dRecetaOriginal.oP_piezas_por_giro, modificado: dReceta.oP_piezas_por_giro },
                { nombre: this.translateService.instant("SP_presion_soplador_1"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_1, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_1, modificado: dReceta.sP_presion_soplador_1 },
                { nombre: this.translateService.instant("SP_presion_soplador_2"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_2, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_2, modificado: dReceta.sP_presion_soplador_2 },
                { nombre: this.translateService.instant("SP_presion_soplador_3"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_3, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_3, modificado: dReceta.sP_presion_soplador_3 },
                { nombre: this.translateService.instant("SP_presion_soplador_4"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_4, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_4, modificado: dReceta.sP_presion_soplador_4 },
                { nombre: this.translateService.instant("SP_presion_soplador_5"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_5, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_5, modificado: dReceta.sP_presion_soplador_5 },
                { nombre: this.translateService.instant("SP_presion_soplador_6"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_6, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_6, modificado: dReceta.sP_presion_soplador_6 },
                { nombre: this.translateService.instant("SP_presion_soplador_7"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_7, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_7, modificado: dReceta.sP_presion_soplador_7 },
                { nombre: this.translateService.instant("SP_presion_soplador_8"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_8, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_8, modificado: dReceta.sP_presion_soplador_8 },
                { nombre: this.translateService.instant("SP_presion_soplador_9"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_9, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_9, modificado: dReceta.sP_presion_soplador_9 },
                { nombre: this.translateService.instant("SP_presion_soplador_10"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_10, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_10, modificado: dReceta.sP_presion_soplador_10 },
                { nombre: this.translateService.instant("SP_presion_soplador_11"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_11, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_11, modificado: dReceta.sP_presion_soplador_11 },
                { nombre: this.translateService.instant("SP_presion_soplador_12"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_12, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_12, modificado: dReceta.sP_presion_soplador_12 },
                { nombre: this.translateService.instant("SP_presion_soplador_13"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_13, unidad: "%", estaEnPestanna: 2, original: dRecetaOriginal.sP_presion_soplador_13, modificado: dReceta.sP_presion_soplador_13 },
                { nombre: this.translateService.instant("OP_sector_anchura_expulsor"), ensennar: this.datosConfiguracionHorno.op_sector_anchura_expulsor, unidad: "ºC", estaEnPestanna: 3, original: dRecetaOriginal.oP_sector_anchura_expulsor, modificado: dReceta.oP_sector_anchura_expulsor },
                { nombre: this.translateService.instant("OP_sector_continuar_antes_parada_auto"), ensennar: this.datosConfiguracionHorno.op_sector_continuar_antes_parada_auto, unidad: "ºC", estaEnPestanna: 3, original: dRecetaOriginal.oP_sector_continuar_antes_parada_auto, modificado: dReceta.oP_sector_continuar_antes_parada_auto },
                { nombre: this.translateService.instant("OP_sector_continuar_calentado_en_parada"), ensennar: this.datosConfiguracionHorno.op_sector_continuar_calentado_en_parada, unidad: "ºC", estaEnPestanna: 3, original: dRecetaOriginal.oP_sector_continuar_calentado_en_parada, modificado: dReceta.oP_sector_continuar_calentado_en_parada },
                { nombre: this.translateService.instant("OP_sector_expulsion_defectuosos"), ensennar: this.datosConfiguracionHorno.op_sector_expulsion_defectuosos, unidad: "ºC", estaEnPestanna: 3, original: dRecetaOriginal.oP_sector_expulsion_defectuosos, modificado: dReceta.oP_sector_expulsion_defectuosos },
                { nombre: this.translateService.instant("OP_activar_expulsor"), ensennar: this.datosConfiguracionHorno.op_activar_expulsor, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.oP_activar_expulsor, modificado: dReceta.oP_activar_expulsor },
                { nombre: this.translateService.instant("OP_Tmp_parada_atasco"), ensennar: this.datosConfiguracionHorno.op_tmp_parada_atasco, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.oP_Tmp_parada_atasco, modificado: dReceta.oP_Tmp_parada_atasco },
                { nombre: this.translateService.instant("Tmp_vaciado_cinta_salida"), ensennar: this.datosConfiguracionHorno.tmp_vaciado_cinta_salida, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_vaciado_cinta_salida, modificado: dReceta.tmp_vaciado_cinta_salida },
                { nombre: this.translateService.instant("Tmp_falta_Material_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_falta_Material_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_falta_Material_vibrador_lineal, modificado: dReceta.tmp_falta_Material_vibrador_lineal },
                { nombre: this.translateService.instant("Tmp_parada_control_falta_material"), ensennar: this.datosConfiguracionHorno.tmp_parada_control_falta_material, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_parada_control_falta_material, modificado: dReceta.tmp_parada_control_falta_material },
                { nombre: this.translateService.instant("Tmp_recuperacion_falta_material_lineal"), ensennar: this.datosConfiguracionHorno.tmp_recuperacion_falta_material_lineal, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_recuperacion_falta_material_lineal, modificado: dReceta.tmp_recuperacion_falta_material_lineal },
                { nombre: this.translateService.instant("Tmp_filtro_defecto_material_vibrador_2"), ensennar: this.datosConfiguracionHorno.tmp_filtro_defecto_material_vibrador_2, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_filtro_defecto_material_vibrador_2, modificado: dReceta.tmp_filtro_defecto_material_vibrador_2 },
                { nombre: this.translateService.instant("Tmp_presencia_material_vibrador_2"), ensennar: this.datosConfiguracionHorno.tmp_presencia_material_vibrador_2, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_presencia_material_vibrador_2, modificado: dReceta.tmp_presencia_material_vibrador_2 },
                { nombre: this.translateService.instant("Tmp_peticion_pieza_vibrador_1"), ensennar: this.datosConfiguracionHorno.tmp_peticion_pieza_vibrador_1, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_peticion_pieza_vibrador_1, modificado: dReceta.tmp_peticion_pieza_vibrador_1 },
                { nombre: this.translateService.instant("Tmp_presencia_pieza_vibrador_1"), ensennar: this.datosConfiguracionHorno.tmp_presencia_pieza_vibrador_1, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_presencia_pieza_vibrador_1, modificado: dReceta.tmp_presencia_pieza_vibrador_1 },
                { nombre: this.translateService.instant("SP_expulsion_salida_cuba_rapida"), ensennar: this.datosConfiguracionHorno.sp_expulsion_salida_cuba_rapida, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.sP_expulsion_salida_cuba_rapida, modificado: dReceta.sP_expulsion_salida_cuba_rapida },
                { nombre: this.translateService.instant("SP_expulsion_salida_cuba_lenta"), ensennar: this.datosConfiguracionHorno.sp_expulsion_salida_cuba_lenta, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.sp_expulsion_salida_cuba_lenta, modificado: dReceta.sP_expulsion_salida_cuba_lenta },
                { nombre: this.translateService.instant("OP_TMP_salida_vibrador_cuba_saturada"), ensennar: this.datosConfiguracionHorno.op_tmp_salida_vibrador_cuba_saturada, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.oP_TMP_salida_vibrador_cuba_saturada, modificado: dReceta.oP_TMP_salida_vibrador_cuba_saturada },
                { nombre: this.translateService.instant("Tmp_vibrador_lineal_completo"), ensennar: this.datosConfiguracionHorno.tmp_vibrador_lineal_completo, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_vibrador_lineal_completo, modificado: dReceta.tmp_vibrador_lineal_completo },
                { nombre: this.translateService.instant("Tmp_def_material_m_alt_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_def_material_m_alt_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_def_material_m_alt_vibrador_lineal, modificado: dReceta.tmp_def_material_m_alt_vibrador_lineal },
                { nombre: this.translateService.instant("Tmp_pres_material_m_alt_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_pres_material_m_alt_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmp_pres_material_m_alt_vibrador_lineal, modificado: dReceta.tmp_pres_material_m_alt_vibrador_lineal },
                { nombre: this.translateService.instant("SP_salida_paso_pieza"), ensennar: this.datosConfiguracionHorno.sp_salida_paso_pieza, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.sP_salida_paso_pieza, modificado: dReceta.sP_salida_paso_pieza },
                { nombre: this.translateService.instant("TMP_giro_plato_inicial_giro"), ensennar: this.datosConfiguracionHorno.tmp_giro_plato_inicial_giro, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmP_giro_plato_inicial_giro, modificado: dReceta.tmP_giro_plato_inicial_giro },
                { nombre: this.translateService.instant("TMP_giro_plato_control"), ensennar: this.datosConfiguracionHorno.tmp_giro_plato_control, unidad: "ms.", estaEnPestanna: 3, original: dRecetaOriginal.tmP_giro_plato_control, modificado: dReceta.tmP_giro_plato_control },
                { nombre: this.translateService.instant("consigna_energia"), ensennar: this.datosConfiguracionHorno.consigna_energia, unidad: "uds.", estaEnPestanna: 4, original: this.nFormatter(dRecetaOriginal.consigna_energia, 1), modificado: this.nFormatter(dReceta.consigna_energia, 1) },
                { nombre: this.translateService.instant("margen_energia"), ensennar: this.datosConfiguracionHorno.margen_energia, unidad: "%", estaEnPestanna: 4, original: dRecetaOriginal.margen_energia, modificado: dReceta.margen_energia },
                { nombre: this.translateService.instant("calculo_energia_pos"), ensennar: this.datosConfiguracionHorno.calculo_energia_pos, unidad: "uds.", estaEnPestanna: 4, original: this.nFormatter(dRecetaOriginal.calculo_energia_maximo, 1), modificado: this.nFormatter(dReceta.calculo_energia_maximo, 1) },
                { nombre: this.translateService.instant("calculo_energia_neg"), ensennar: this.datosConfiguracionHorno.calculo_energia_neg, unidad: "uds.", estaEnPestanna: 4, original: this.nFormatter(dRecetaOriginal.calculo_energia_minimo, 1), modificado: this.nFormatter(dReceta.calculo_energia_minimo, 1) },
                { nombre: this.translateService.instant("consigna_caudal_ducha"), ensennar: this.datosConfiguracionHorno.consigna_caudal_ducha, unidad: "l/m", estaEnPestanna: 4, original: dRecetaOriginal.consigna_caudal_ducha, modificado: dReceta.consigna_caudal_ducha },
                { nombre: this.translateService.instant("margen_caudal_ducha"), ensennar: this.datosConfiguracionHorno.margen_caudal_ducha, unidad: "%", estaEnPestanna: 4, original: dRecetaOriginal.margen_caudal_ducha, modificado: dReceta.margen_caudal_ducha },
                { nombre: this.translateService.instant("calculo_caudal_ducha_pos"), ensennar: this.datosConfiguracionHorno.calculo_caudal_ducha_pos, unidad: "l/m", estaEnPestanna: 4, original: dRecetaOriginal.calculo_caudal_ducha_maximo, modificado: dReceta.calculo_caudal_ducha_maximo },
                { nombre: this.translateService.instant("calculo_caudal_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_caudal_ducha_neg, unidad: "l/m", estaEnPestanna: 4, original: dRecetaOriginal.calculo_caudal_ducha_minimo, modificado: dReceta.calculo_caudal_ducha_minimo },
                { nombre: this.translateService.instant("consigna_temperatura_ducha"), ensennar: this.datosConfiguracionHorno.consigna_temperatura_ducha, unidad: "ºC", estaEnPestanna: 4, original: dRecetaOriginal.consigna_temperatura_ducha, modificado: dReceta.consigna_temperatura_ducha },
                { nombre: this.translateService.instant("margen_temperatura_ducha"), ensennar: this.datosConfiguracionHorno.margen_temperatura_ducha, unidad: "%", estaEnPestanna: 4, original: dRecetaOriginal.margen_temperatura_ducha, modificado: dReceta.margen_temperatura_ducha },
                { nombre: this.translateService.instant("calculo_temperatura_ducha_pos"), ensennar: this.datosConfiguracionHorno.calculo_temperatura_ducha_pos, unidad: "ºC", estaEnPestanna: 4, original: dRecetaOriginal.calculo_temperatura_ducha_maximo, modificado: dReceta.calculo_temperatura_ducha_maximo },
                { nombre: this.translateService.instant("calculo_temperatura_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_temperatura_ducha_neg, unidad: "ºC", estaEnPestanna: 4, original: dRecetaOriginal.calculo_temperatura_ducha_minimo, modificado: dReceta.calculo_temperatura_ducha_minimo },
                { nombre: this.translateService.instant("consigna_presion_ducha"), ensennar: this.datosConfiguracionHorno.consigna_presion_ducha, unidad: "bar.", estaEnPestanna: 4, original: dRecetaOriginal.consigna_presion_ducha, modificado: dReceta.consigna_presion_ducha },
                { nombre: this.translateService.instant("margen_presion_ducha"), ensennar: this.datosConfiguracionHorno.margen_presion_ducha, unidad: "%", estaEnPestanna: 4, original: dRecetaOriginal.margen_presion_ducha, modificado: dReceta.margen_presion_ducha },
                { nombre: this.translateService.instant("calculo_presion_ducha"), ensennar: this.datosConfiguracionHorno.calculo_presion_ducha, unidad: "bar.", estaEnPestanna: 4, original: dRecetaOriginal.calculo_presion_ducha_maximo, modificado: dReceta.calculo_presion_ducha_maximo },
                { nombre: this.translateService.instant("calculo_presion_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_presion_ducha_neg, unidad: "bar.", estaEnPestanna: 4, original: dRecetaOriginal.calculo_presion_ducha_minimo, modificado: dReceta.calculo_presion_ducha_minimo },
              ];
            } else if (datos.datosReceta.length > 0 && datos.datosRecetaOriginal.length == 0) {
              var dReceta = datos.datosReceta[0];
              //TIENE DATOS
              this.listaReceta = [
                { nombre: this.translateService.instant("OP_activar_soplador_1"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_1, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_1 },
                { nombre: this.translateService.instant("OP_activar_soplador_2"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_2, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_2 },
                { nombre: this.translateService.instant("OP_activar_soplador_3"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_3, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_3 },
                { nombre: this.translateService.instant("OP_activar_soplador_4"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_4, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_4 },
                { nombre: this.translateService.instant("OP_activar_soplador_5"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_5, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_5 },
                { nombre: this.translateService.instant("OP_activar_soplador_6"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_6, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_6 },
                { nombre: this.translateService.instant("OP_activar_soplador_7"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_7, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_7 },
                { nombre: this.translateService.instant("OP_activar_soplador_8"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_8, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_8 },
                { nombre: this.translateService.instant("OP_activar_soplador_9"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_9, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_9 },
                { nombre: this.translateService.instant("OP_activar_soplador_10"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_10, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_10 },
                { nombre: this.translateService.instant("OP_activar_soplador_11"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_11, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_11 },
                { nombre: this.translateService.instant("OP_activar_soplador_12"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_12, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_12 },
                { nombre: this.translateService.instant("OP_activar_soplador_13"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_13, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_soplador_13 },
                { nombre: this.translateService.instant("OP_activar_salida_cuba"), ensennar: this.datosConfiguracionHorno.op_activar_salida_cuba, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_activar_salida_cuba },
                { nombre: this.translateService.instant("OP_control_paso_pieza"), ensennar: this.datosConfiguracionHorno.op_control_paso_pieza, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_control_paso_pieza },
                { nombre: this.translateService.instant("OP_rodillo_introductor"), ensennar: this.datosConfiguracionHorno.op_rodillo_introductor, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_rodillo_introductor },
                { nombre: this.translateService.instant("OP_giro_plato"), ensennar: this.datosConfiguracionHorno.op_giro_plato, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_giro_plato },
                { nombre: this.translateService.instant("OP_control_energia_activado"), ensennar: this.datosConfiguracionHorno.op_control_energia_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_control_energia_activado },
                { nombre: this.translateService.instant("OP_control_caudal_activado"), ensennar: this.datosConfiguracionHorno.op_control_caudal_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_control_caudal_activado },
                { nombre: this.translateService.instant("OP_control_temperatura_activado"), ensennar: this.datosConfiguracionHorno.op_control_temperatura_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_control_temperatura_activado },
                { nombre: this.translateService.instant("OP_control_presion_activado"), ensennar: this.datosConfiguracionHorno.op_control_presion_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.oP_control_presion_activado },
                { nombre: this.translateService.instant("GEN_configuracion_seleccionada"), ensennar: this.datosConfiguracionHorno.gen_configuracion_seleccionada, unidad: "", estaEnPestanna: 1, original: "-", modificado: dReceta.geN_configuracion_seleccionada },
                { nombre: this.translateService.instant("SP_vibrador_1"), ensennar: this.datosConfiguracionHorno.sp_vibrador_1, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_vibrador_1 },
                { nombre: this.translateService.instant("SP_mantenimiento_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_mantenimiento_vibrador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_mantenimiento_vibrador_2 },
                { nombre: this.translateService.instant("SP_lenta_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_lenta_vibrador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_lenta_vibrador_2 },
                { nombre: this.translateService.instant("SP_recuperacion_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_recuperacion_vibrador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_recuperacion_vibrador_2 },
                { nombre: this.translateService.instant("SP_vibrador_3"), ensennar: this.datosConfiguracionHorno.sp_vibrador_3, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_vibrador_3 },
                { nombre: this.translateService.instant("CV_cinta_salida"), ensennar: this.datosConfiguracionHorno.cv_cinta_salida, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.cV_cinta_salida },
                { nombre: this.translateService.instant("CV_proceso_giro"), ensennar: this.datosConfiguracionHorno.cv_proceso_giro, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.cV_proceso_giro },
                { nombre: this.translateService.instant("CV_rapida_giro"), ensennar: this.datosConfiguracionHorno.cv_rapida_giro, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.cV_rapida_giro },
                { nombre: this.translateService.instant("consigna_calor"), ensennar: this.datosConfiguracionHorno.consigna_calor, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.consigna_calor },
                { nombre: this.translateService.instant("velocidad_rapida_subida_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_rapida_subida_volteador, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.velocidad_rapida_subida_volteador },
                { nombre: this.translateService.instant("velocidad_lenta_subida_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_lenta_subida_volteador, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.velocidad_lenta_subida_volteador },
                { nombre: this.translateService.instant("velocidad_bajada_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_bajada_volteador, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.velocidad_bajada_volteador },
                { nombre: this.translateService.instant("SP_consigna_velocidad"), ensennar: this.datosConfiguracionHorno.sp_consigna_velocidad, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_consigna_velocidad },
                { nombre: this.translateService.instant("OP_piezas_por_giro"), ensennar: this.datosConfiguracionHorno.op_piezas_por_giro, unidad: "uds.", estaEnPestanna: 2, original: "-", modificado: dReceta.oP_piezas_por_giro },
                { nombre: this.translateService.instant("SP_presion_soplador_1"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_1, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_1 },
                { nombre: this.translateService.instant("SP_presion_soplador_2"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_2 },
                { nombre: this.translateService.instant("SP_presion_soplador_3"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_3, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_3 },
                { nombre: this.translateService.instant("SP_presion_soplador_4"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_4, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_4 },
                { nombre: this.translateService.instant("SP_presion_soplador_5"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_5, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_5 },
                { nombre: this.translateService.instant("SP_presion_soplador_6"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_6, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_6 },
                { nombre: this.translateService.instant("SP_presion_soplador_7"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_7, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_7 },
                { nombre: this.translateService.instant("SP_presion_soplador_8"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_8, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_8 },
                { nombre: this.translateService.instant("SP_presion_soplador_9"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_9, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_9 },
                { nombre: this.translateService.instant("SP_presion_soplador_10"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_10, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_10 },
                { nombre: this.translateService.instant("SP_presion_soplador_11"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_11, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_11 },
                { nombre: this.translateService.instant("SP_presion_soplador_12"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_12, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_12 },
                { nombre: this.translateService.instant("SP_presion_soplador_13"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_13, unidad: "%", estaEnPestanna: 2, original: "-", modificado: dReceta.sP_presion_soplador_13 },
                { nombre: this.translateService.instant("OP_sector_anchura_expulsor"), ensennar: this.datosConfiguracionHorno.op_sector_anchura_expulsor, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_sector_anchura_expulsor },
                { nombre: this.translateService.instant("OP_sector_continuar_antes_parada_auto"), ensennar: this.datosConfiguracionHorno.op_sector_continuar_antes_parada_auto, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_sector_continuar_antes_parada_auto },
                { nombre: this.translateService.instant("OP_sector_continuar_calentado_en_parada"), ensennar: this.datosConfiguracionHorno.op_sector_continuar_calentado_en_parada, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_sector_continuar_calentado_en_parada },
                { nombre: this.translateService.instant("OP_sector_expulsion_defectuosos"), ensennar: this.datosConfiguracionHorno.op_sector_expulsion_defectuosos, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_sector_expulsion_defectuosos },
                { nombre: this.translateService.instant("OP_activar_expulsor"), ensennar: this.datosConfiguracionHorno.op_activar_expulsor, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_activar_expulsor },
                { nombre: this.translateService.instant("OP_Tmp_parada_atasco"), ensennar: this.datosConfiguracionHorno.op_tmp_parada_atasco, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_Tmp_parada_atasco },
                { nombre: this.translateService.instant("Tmp_vaciado_cinta_salida"), ensennar: this.datosConfiguracionHorno.tmp_vaciado_cinta_salida, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_vaciado_cinta_salida },
                { nombre: this.translateService.instant("Tmp_falta_Material_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_falta_Material_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_falta_Material_vibrador_lineal },
                { nombre: this.translateService.instant("Tmp_parada_control_falta_material"), ensennar: this.datosConfiguracionHorno.tmp_parada_control_falta_material, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_parada_control_falta_material },
                { nombre: this.translateService.instant("Tmp_recuperacion_falta_material_lineal"), ensennar: this.datosConfiguracionHorno.tmp_recuperacion_falta_material_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_recuperacion_falta_material_lineal },
                { nombre: this.translateService.instant("Tmp_filtro_defecto_material_vibrador_2"), ensennar: this.datosConfiguracionHorno.tmp_filtro_defecto_material_vibrador_2, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_filtro_defecto_material_vibrador_2 },
                { nombre: this.translateService.instant("Tmp_presencia_material_vibrador_2"), ensennar: this.datosConfiguracionHorno.tmp_presencia_material_vibrador_2, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_presencia_material_vibrador_2 },
                { nombre: this.translateService.instant("Tmp_peticion_pieza_vibrador_1"), ensennar: this.datosConfiguracionHorno.tmp_peticion_pieza_vibrador_1, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_peticion_pieza_vibrador_1 },
                { nombre: this.translateService.instant("Tmp_presencia_pieza_vibrador_1"), ensennar: this.datosConfiguracionHorno.tmp_presencia_pieza_vibrador_1, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_presencia_pieza_vibrador_1 },
                { nombre: this.translateService.instant("SP_expulsion_salida_cuba_rapida"), ensennar: this.datosConfiguracionHorno.sp_expulsion_salida_cuba_rapida, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.sP_expulsion_salida_cuba_rapida },
                { nombre: this.translateService.instant("SP_expulsion_salida_cuba_lenta"), ensennar: this.datosConfiguracionHorno.sp_expulsion_salida_cuba_lenta, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.sP_expulsion_salida_cuba_lenta },
                { nombre: this.translateService.instant("OP_TMP_salida_vibrador_cuba_saturada"), ensennar: this.datosConfiguracionHorno.op_tmp_salida_vibrador_cuba_saturada, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.oP_TMP_salida_vibrador_cuba_saturada },
                { nombre: this.translateService.instant("Tmp_vibrador_lineal_completo"), ensennar: this.datosConfiguracionHorno.tmp_vibrador_lineal_completo, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_vibrador_lineal_completo },
                { nombre: this.translateService.instant("Tmp_def_material_m_alt_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_def_material_m_alt_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_def_material_m_alt_vibrador_lineal },
                { nombre: this.translateService.instant("Tmp_pres_material_m_alt_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_pres_material_m_alt_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmp_pres_material_m_alt_vibrador_lineal },
                { nombre: this.translateService.instant("SP_salida_paso_pieza"), ensennar: this.datosConfiguracionHorno.sp_salida_paso_pieza, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.sP_salida_paso_pieza },
                { nombre: this.translateService.instant("TMP_giro_plato_inicial_giro"), ensennar: this.datosConfiguracionHorno.tmp_giro_plato_inicial_giro, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmP_giro_plato_inicial_giro },
                { nombre: this.translateService.instant("TMP_giro_plato_control"), ensennar: this.datosConfiguracionHorno.tmp_giro_plato_control, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: dReceta.tmP_giro_plato_control },
                { nombre: this.translateService.instant("consigna_energia"), ensennar: this.datosConfiguracionHorno.consigna_energia, unidad: "uds.", estaEnPestanna: 4, original: "-", modificado: dReceta.consigna_energia },
                { nombre: this.translateService.instant("margen_energia"), ensennar: this.datosConfiguracionHorno.margen_energia, unidad: "%", estaEnPestanna: 4, original: "-", modificado: dReceta.margen_energia },
                { nombre: this.translateService.instant("calculo_energia_pos"), ensennar: this.datosConfiguracionHorno.calculo_energia_pos, unidad: "uds.", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_energia_maximo },
                { nombre: this.translateService.instant("calculo_energia_neg"), ensennar: this.datosConfiguracionHorno.calculo_energia_neg, unidad: "uds.", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_energia_minimo },
                { nombre: this.translateService.instant("consigna_caudal_ducha"), ensennar: this.datosConfiguracionHorno.consigna_caudal_ducha, unidad: "l/m", estaEnPestanna: 4, original: "-", modificado: dReceta.consigna_caudal_ducha },
                { nombre: this.translateService.instant("margen_caudal_ducha"), ensennar: this.datosConfiguracionHorno.margen_caudal_ducha, unidad: "%", estaEnPestanna: 4, original: "-", modificado: dReceta.margen_caudal_ducha },
                { nombre: this.translateService.instant("calculo_caudal_ducha_pos"), ensennar: this.datosConfiguracionHorno.calculo_caudal_ducha_pos, unidad: "l/m", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_caudal_ducha_maximo },
                { nombre: this.translateService.instant("calculo_caudal_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_caudal_ducha_neg, unidad: "l/m", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_caudal_ducha_minimo },
                { nombre: this.translateService.instant("consigna_temperatura_ducha"), ensennar: this.datosConfiguracionHorno.consigna_temperatura_ducha, unidad: "ºC", estaEnPestanna: 4, original: "-", modificado: dReceta.consigna_temperatura_ducha },
                { nombre: this.translateService.instant("margen_temperatura_ducha"), ensennar: this.datosConfiguracionHorno.margen_temperatura_ducha, unidad: "%", estaEnPestanna: 4, original: "-", modificado: dReceta.margen_temperatura_ducha },
                { nombre: this.translateService.instant("calculo_temperatura_ducha_pos"), ensennar: this.datosConfiguracionHorno.calculo_temperatura_ducha_pos, unidad: "ºC", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_temperatura_ducha_maximo },
                { nombre: this.translateService.instant("calculo_temperatura_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_temperatura_ducha_neg, unidad: "ºC", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_temperatura_ducha_minimo },
                { nombre: this.translateService.instant("consigna_presion_ducha"), ensennar: this.datosConfiguracionHorno.consigna_presion_ducha, unidad: "bar.", estaEnPestanna: 4, original: "-", modificado: dReceta.consigna_presion_ducha },
                { nombre: this.translateService.instant("margen_presion_ducha"), ensennar: this.datosConfiguracionHorno.margen_presion_ducha, unidad: "%", estaEnPestanna: 4, original: "-", modificado: dReceta.margen_presion_ducha },
                { nombre: this.translateService.instant("calculo_presion_ducha"), ensennar: this.datosConfiguracionHorno.calculo_presion_ducha, unidad: "bar.", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_presion_ducha_maximo },
                { nombre: this.translateService.instant("calculo_presion_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_presion_ducha_neg, unidad: "bar.", estaEnPestanna: 4, original: "-", modificado: dReceta.calculo_presion_ducha_minimo },
              ];
            } else {
              //NO TIENE DATOS
              this.listaReceta = [
                { nombre: this.translateService.instant("OP_activar_soplador_1"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_1, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_2"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_2, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_3"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_3, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_4"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_4, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_5"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_5, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_6"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_6, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_7"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_7, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_8"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_8, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_9"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_9, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_10"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_10, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_11"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_11, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_12"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_12, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_soplador_13"), ensennar: this.datosConfiguracionHorno.op_activar_soplador_13, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_salida_cuba"), ensennar: this.datosConfiguracionHorno.op_activar_salida_cuba, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_control_paso_pieza"), ensennar: this.datosConfiguracionHorno.op_control_paso_pieza, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_rodillo_introductor"), ensennar: this.datosConfiguracionHorno.op_rodillo_introductor, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_giro_plato"), ensennar: this.datosConfiguracionHorno.op_giro_plato, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_control_energia_activado"), ensennar: this.datosConfiguracionHorno.op_control_energia_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_control_caudal_activado"), ensennar: this.datosConfiguracionHorno.op_control_caudal_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_control_temperatura_activado"), ensennar: this.datosConfiguracionHorno.op_control_temperatura_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_control_presion_activado"), ensennar: this.datosConfiguracionHorno.op_control_presion_activado, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("GEN_configuracion_seleccionada"), ensennar: this.datosConfiguracionHorno.gen_configuracion_seleccionada, unidad: "", estaEnPestanna: 1, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_vibrador_1"), ensennar: this.datosConfiguracionHorno.sp_vibrador_1, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_mantenimiento_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_mantenimiento_vibrador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_lenta_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_lenta_vibrador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_recuperacion_vibrador_2"), ensennar: this.datosConfiguracionHorno.sp_recuperacion_vibrador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_vibrador_3"), ensennar: this.datosConfiguracionHorno.sp_vibrador_3, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("CV_cinta_salida"), ensennar: this.datosConfiguracionHorno.cv_cinta_salida, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("CV_proceso_giro"), ensennar: this.datosConfiguracionHorno.cv_proceso_giro, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("CV_rapida_giro"), ensennar: this.datosConfiguracionHorno.cv_rapida_giro, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("consigna_calor"), ensennar: this.datosConfiguracionHorno.consigna_calor, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("velocidad_rapida_subida_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_rapida_subida_volteador, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("velocidad_lenta_subida_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_lenta_subida_volteador, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("velocidad_bajada_volteador"), ensennar: this.datosConfiguracionHorno.velocidad_bajada_volteador, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_consigna_velocidad"), ensennar: this.datosConfiguracionHorno.sp_consigna_velocidad, unidad: "rpm", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_piezas_por_giro"), ensennar: this.datosConfiguracionHorno.op_piezas_por_giro, unidad: "uds.", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_1"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_1, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_2"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_2, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_3"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_3, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_4"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_4, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_5"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_5, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_6"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_6, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_7"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_7, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_8"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_8, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_9"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_9, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_10"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_10, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_11"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_11, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_12"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_12, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_presion_soplador_13"), ensennar: this.datosConfiguracionHorno.sP_presion_soplador_13, unidad: "%", estaEnPestanna: 2, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_sector_anchura_expulsor"), ensennar: this.datosConfiguracionHorno.op_sector_anchura_expulsor, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_sector_continuar_antes_parada_auto"), ensennar: this.datosConfiguracionHorno.op_sector_continuar_antes_parada_auto, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_sector_continuar_calentado_en_parada"), ensennar: this.datosConfiguracionHorno.op_sector_continuar_calentado_en_parada, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_sector_expulsion_defectuosos"), ensennar: this.datosConfiguracionHorno.op_sector_expulsion_defectuosos, unidad: "ºC", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_activar_expulsor"), ensennar: this.datosConfiguracionHorno.op_activar_expulsor, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_Tmp_parada_atasco"), ensennar: this.datosConfiguracionHorno.op_tmp_parada_atasco, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_vaciado_cinta_salida"), ensennar: this.datosConfiguracionHorno.tmp_vaciado_cinta_salida, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_falta_Material_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_falta_Material_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_parada_control_falta_material"), ensennar: this.datosConfiguracionHorno.tmp_parada_control_falta_material, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_recuperacion_falta_material_lineal"), ensennar: this.datosConfiguracionHorno.tmp_recuperacion_falta_material_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_filtro_defecto_material_vibrador_2"), ensennar: this.datosConfiguracionHorno.tmp_filtro_defecto_material_vibrador_2, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_presencia_material_vibrador_2"), ensennar: this.datosConfiguracionHorno.tmp_presencia_material_vibrador_2, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_peticion_pieza_vibrador_1"), ensennar: this.datosConfiguracionHorno.tmp_peticion_pieza_vibrador_1, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_presencia_pieza_vibrador_1"), ensennar: this.datosConfiguracionHorno.tmp_presencia_pieza_vibrador_1, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_expulsion_salida_cuba_rapida"), ensennar: this.datosConfiguracionHorno.sp_expulsion_salida_cuba_rapida, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_expulsion_salida_cuba_lenta"), ensennar: this.datosConfiguracionHorno.sp_expulsion_salida_cuba_lenta, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("OP_TMP_salida_vibrador_cuba_saturada"), ensennar: this.datosConfiguracionHorno.op_tmp_salida_vibrador_cuba_saturada, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_vibrador_lineal_completo"), ensennar: this.datosConfiguracionHorno.tmp_vibrador_lineal_completo, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_def_material_m_alt_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_def_material_m_alt_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("Tmp_pres_material_m_alt_vibrador_lineal"), ensennar: this.datosConfiguracionHorno.tmp_pres_material_m_alt_vibrador_lineal, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("SP_salida_paso_pieza"), ensennar: this.datosConfiguracionHorno.sp_salida_paso_pieza, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("TMP_giro_plato_inicial_giro"), ensennar: this.datosConfiguracionHorno.tmp_giro_plato_inicial_giro, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("TMP_giro_plato_control"), ensennar: this.datosConfiguracionHorno.tmp_giro_plato_control, unidad: "ms.", estaEnPestanna: 3, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("consigna_energia"), ensennar: this.datosConfiguracionHorno.consigna_energia, unidad: "uds.", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("margen_energia"), ensennar: this.datosConfiguracionHorno.margen_energia, unidad: "%", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_energia_pos"), ensennar: this.datosConfiguracionHorno.calculo_energia_pos, unidad: "uds.", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_energia_neg"), ensennar: this.datosConfiguracionHorno.calculo_energia_neg, unidad: "uds.", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("consigna_caudal_ducha"), ensennar: this.datosConfiguracionHorno.consigna_caudal_ducha, unidad: "l/m", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("margen_caudal_ducha"), ensennar: this.datosConfiguracionHorno.margen_caudal_ducha, unidad: "%", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_caudal_ducha_pos"), ensennar: this.datosConfiguracionHorno.calculo_caudal_ducha_pos, unidad: "l/m", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_caudal_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_caudal_ducha_neg, unidad: "l/m", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("consigna_temperatura_ducha"), ensennar: this.datosConfiguracionHorno.consigna_temperatura_ducha, unidad: "ºC", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("margen_temperatura_ducha"), ensennar: this.datosConfiguracionHorno.margen_temperatura_ducha, unidad: "%", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_temperatura_ducha_pos"), ensennar: this.datosConfiguracionHorno.calculo_temperatura_ducha_pos, unidad: "ºC", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_temperatura_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_temperatura_ducha_neg, unidad: "ºC", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("consigna_presion_ducha"), ensennar: this.datosConfiguracionHorno.consigna_presion_ducha, unidad: "bar.", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("margen_presion_ducha"), ensennar: this.datosConfiguracionHorno.margen_presion_ducha, unidad: "%", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_presion_ducha"), ensennar: this.datosConfiguracionHorno.calculo_presion_ducha, unidad: "bar.", estaEnPestanna: 4, original: "-", modificado: "-" },
                { nombre: this.translateService.instant("calculo_presion_ducha_neg"), ensennar: this.datosConfiguracionHorno.calculo_presion_ducha_neg, unidad: "bar.", estaEnPestanna: 4, original: "-", modificado: "-" },
              ];
            }
          }
          //#endregion

          var datosConfigHorno: any = result;

          //#region GRAFICOS
          if (this.maquina.tipoMaquinaInduccion == 2) {
            this.listaTooltip = [
              { nombre: "energia", estaEnGrafico: this.datosConfiguracionHorno.energia_activo ? 1 : -1, seleccionadoLeyenda: true, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.energia_activo },
              { nombre: "energia_min", estaEnGrafico: this.datosConfiguracionHorno.energia_activo ? 1 : -1, seleccionadoLeyenda: true, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "energia_max", estaEnGrafico: this.datosConfiguracionHorno.energia_activo ? 1 : -1, seleccionadoLeyenda: true, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "potencia", estaEnGrafico: /* this.datosConfiguracionHorno.potencia_activo ? 2 : */ -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.potencia_activo },
              { nombre: "potencia_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "potencia_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "intensidad", estaEnGrafico: /* this.datosConfiguracionHorno.intensidad_activo ? 3 : */ -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.intensidad_activo },
              { nombre: "intensidad_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "intensidad_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "voltaje", estaEnGrafico: /* this.datosConfiguracionHorno.voltaje_activo ? 4 : */ -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.voltaje_activo },
              { nombre: "voltaje_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "voltaje_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "frecuencia", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.frecuencia_activo },
              { nombre: "frecuencia_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "frecuencia_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "caudal", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.caudal_activo },
              { nombre: "caudal_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "caudal_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "temperaturaPieza", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.temperaturaPieza_activo },
              { nombre: "temperaturaPieza_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "temperaturaPieza_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "presionBombaDucha", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.presionBombaDucha_activo },
              { nombre: "presionBombaDucha_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "presionBombaDucha_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "temperaturaPolimero", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.temperaturaPolimero_activo },
              { nombre: "temperaturaPolimero_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "temperaturaPolimero_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "porcenPolimero", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.porcenPolimero_activo },
              { nombre: "porcenPolimero_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "porcenPolimero_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "velocidadRotacion", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.velocidadRotacion_activo },
              { nombre: "velocidadRotacion_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "velocidadRotacion_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "posicionEje1", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.posicionEje1_activo },
              { nombre: "posicionEje1_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "posicionEje1_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
              
              { nombre: "posicionEje2", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.posicionEje2_activo },
              { nombre: "posicionEje2_min", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], ensennar: false },
              { nombre: "posicionEje2_max", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], ensennar: false },
            ];
          } else {
            this.listaTooltip = [
              { nombre: "energia", estaEnGrafico: this.datosConfiguracionHorno.energia_activo ? 1 : -1, seleccionadoLeyenda: true, color: this.coloresMaquinaInduccion1[0], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.energia_activo }, 
              { nombre: "potencia", estaEnGrafico:  this.datosConfiguracionHorno.potencia_activo ? 2 :  -1, seleccionadoLeyenda: true, color: this.coloresMaquinaInduccion1[1], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.potencia_activo },
              { nombre: "intensidad", estaEnGrafico:  this.datosConfiguracionHorno.intensidad_activo ? 3 : -1, seleccionadoLeyenda: true, color: this.coloresMaquinaInduccion1[2], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.intensidad_activo },
              { nombre: "voltaje", estaEnGrafico:  this.datosConfiguracionHorno.voltaje_activo ? 4 :  -1, seleccionadoLeyenda: true, color: this.coloresMaquinaInduccion1[3], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.voltaje_activo },
              { nombre: "frecuencia", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[4], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.frecuencia_activo },
              { nombre: "caudal", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[5], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.caudal_activo },
              { nombre: "temperaturaPieza", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[6], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.temperaturaPieza_activo },
              { nombre: "presionBombaDucha", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[7], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.presionBombaDucha_activo },
              { nombre: "temperaturaPolimero", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[8], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.temperaturaPolimero_activo },
              { nombre: "porcenPolimero", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[9], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.porcenPolimero_activo },
              { nombre: "velocidadRotacion", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[10], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.porcenPolimero_activo },
              { nombre: "posicionEje2", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[11], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.porcenPolimero_activo },
              { nombre: "posicionEje1", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.coloresMaquinaInduccion1[12], unidad: "", valoresMomentaneo: [], ensennar: this.datosConfiguracionHorno.porcenPolimero_activo },
            ];
          }

          this.mostrarGraficosPorLeyenda = [true, true, true, true, true];

          this.fechaInicio = this.datosGraficoMultieje.fechas[0];
          this.fechaFin = this.datosGraficoMultieje.fechas[this.datosGraficoMultieje.fechas.length - 1];
          this.fechaInicioZoom = this.fechaInicio;
          this.fechaFinZoom = this.fechaFin;

          this.fechaIniRecetaSeleccionada = receta.fechaIni;
          this.fechaFinRecetaSeleccionada = receta.fechaFin;

          this.pintarGraficoMultiEje();
          this.cargarGraficosTodos();

          //#endregion

          //#region VALORES TECONOLOGICOS
          if (datos.datosHistorico.length > 0) {
            var lastRow = datos.datosHistorico[datos.datosHistorico.length - 1];
            //TIENE DATOS
            // ALDATU gero programado balioak eta tolerantziak aldatu behar dira
            this.listaValoresTecnologicos = [];
            if (datosConfigHorno.data[0].energia_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("energia"), dim: "-", toler: this.nFormatter(lastRow.energia_tolerancia, 1), programado: this.nFormatter(lastRow.energia, 1), actual: this.nFormatter(lastRow.energia_real, 1) });
            } if (datosConfigHorno.data[0].potencia_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("potencia"), dim: "KW", toler: 5, programado: this.nFormatter(lastRow.potencia * 1.04, 1), actual: this.nFormatter(lastRow.potencia_real, 1) });
            } if (datosConfigHorno.data[0].intensidad_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("intensidad"), dim: "A", toler: 5, programado: this.nFormatter(lastRow.intensidad * 1.05, 1), actual: this.nFormatter(lastRow.intensidad_real, 1) });
            } if (datosConfigHorno.data[0].voltaje_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("voltaje"), dim: "V", toler: 5, programado: this.nFormatter(lastRow.voltaje * 1.05, 1), actual: this.nFormatter(lastRow.voltaje_real, 1) });
            } if (datosConfigHorno.data[0].frecuencia_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("frecuencia"), dim: "KHz", toler: 10, programado: this.nFormatter(lastRow.frecuencia * 1.05, 1), actual: this.nFormatter(lastRow.frecuencia_real, 1) });
            } if (datosConfigHorno.data[0].caudal_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("caudal"), dim: "l/m", toler: this.nFormatter(lastRow.caudal_tolerancia, 1), programado: this.nFormatter(lastRow.consigna_caudal_ducha, 1), actual: this.nFormatter(lastRow.caudal_real, 1) });
              // } if (datosConfigHorno.data[0].activo) {
              //   this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("tDucha"), dim: "ºC", toler: this.nFormatter(dReceta.margen_temperatura_ducha, 1), programado: this.nFormatter(dReceta.consigna_temperatura_ducha, 1), actual: this.nFormatter(lastRow.temperaturaPieza, 1) });
            } if (datosConfigHorno.data[0].presionBombaDucha_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("presionBombaDucha"), dim: "bar.", toler: this.nFormatter(lastRow.presion_tolerancia, 1), programado: this.nFormatter(lastRow.consigna_presion_ducha, 1), actual: this.nFormatter(lastRow.presionBombaDucha_real, 1) });
            } if (datosConfigHorno.data[0].temperaturaPolimero_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("temperaturaPolimero"), dim: "ºC", toler: 15, programado: this.nFormatter(lastRow.temperatura_tolerancia * 1.05, 1), actual: this.nFormatter(lastRow.temperaturaPolimero_real, 1) });
            } if (datosConfigHorno.data[0].porcenPolimero_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("porcenPolimero"), dim: "%", toler: 5, programado: this.nFormatter(lastRow.porcenPolimero * 1.05, 1), actual: this.nFormatter(lastRow.porcenPolimero_real, 1) });
            } if (datosConfigHorno.data[0].tiempoCalentamientoTotal_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("tiempoCalentamientoTotal"), dim: "h", toler: 10, programado: this.nFormatter(lastRow.tiempoCalentamientoTotal * 1.05, 1), actual: this.nFormatter(lastRow.tiempoCalentamientoTotal_real, 1) });
            } if (datosConfigHorno.data[0].tiempoEnfriamientoTotal_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("tiempoEnfriamientoTotal"), dim: "h", toler: 10, programado: this.nFormatter(lastRow.tiempoEnfriamientoTotal * 1.05, 1), actual: this.nFormatter(lastRow.tiempoEnfriamientoTotal_real, 1) });
            } if (datosConfigHorno.data[0].velocidadRotacion_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("velocidadRotacion"), dim: "rpm", toler: 0, programado: this.nFormatter(lastRow.velocidadRotacion * 1.05, 1), actual: this.nFormatter(lastRow.velocidadRotacion_real, 1) });
            } if (datosConfigHorno.data[0].posicionEje1_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("posicionEje1"), dim: "mm", toler: 0, programado: this.nFormatter(lastRow.posicionEje1 * 1.05, 1), actual: this.nFormatter(lastRow.posicionEje1_real, 1) });
            } if (datosConfigHorno.data[0].posicionEje2_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("posicionEje2"), dim: "mm", toler: 0, programado: this.nFormatter(lastRow.posicionEje2 * 1.05, 1), actual: this.nFormatter(lastRow.posicionEje2_real, 1) });
            }

            // this.listaValoresTecnologicos = [
            //   { nombre: this.translateService.instant("energia"), dim: "-", toler: this.nFormatter(dReceta.margen_energia, 1), programado: this.nFormatter(dReceta.consigna_energia, 1), actual: this.nFormatter(lastRow.energia, 1) },
            //   { nombre: this.translateService.instant("potencia"), dim: "K", toler: 5, programado: this.nFormatter(lastRow.potencia * 1.04, 1), actual: this.nFormatter(lastRow.potencia, 1) },
            //   { nombre: this.translateService.instant("intensidad"), dim: "A", toler: 5, programado: this.nFormatter(lastRow.intensidad * 1.05, 1), actual: this.nFormatter(lastRow.intensidad, 1) },
            //   { nombre: this.translateService.instant("voltaje"), dim: "V", toler: 5, programado: this.nFormatter(lastRow.voltaje * 1.05, 1), actual: this.nFormatter(lastRow.voltaje, 1) },
            //   { nombre: this.translateService.instant("frecuencia"), dim: "Hz", toler: 10, programado: this.nFormatter(lastRow.frecuencia * 1.05, 1), actual: this.nFormatter(lastRow.frecuencia, 1) },
            //   { nombre: this.translateService.instant("caudal"), dim: "l/m", toler: this.nFormatter(dReceta.margen_caudal_ducha, 1), programado: this.nFormatter(dReceta.consigna_caudal_ducha, 1), actual: this.nFormatter(lastRow.caudal, 1) },
            //   { nombre: this.translateService.instant("tDucha"), dim: "ºC", toler: this.nFormatter(dReceta.margen_temperatura_ducha, 1), programado: this.nFormatter(dReceta.consigna_temperatura_ducha, 1), actual: this.nFormatter(lastRow.temperaturaPieza, 1) },
            //   { nombre: this.translateService.instant("presionBombaDucha"), dim: "bar.", toler: this.nFormatter(dReceta.margen_presion_ducha, 1), programado: this.nFormatter(dReceta.consigna_presion_ducha, 1), actual: this.nFormatter(lastRow.presionBombaDucha, 1) },
            //   { nombre: this.translateService.instant("temperaturaPolimero"), dim: "ºC", toler: 15, programado: this.nFormatter(lastRow.temperaturaPolimero * 1.05, 1), actual: this.nFormatter(lastRow.temperaturaPolimero, 1) },
            //   { nombre: this.translateService.instant("porcenPolimero"), dim: "%", toler: 5, programado: this.nFormatter(lastRow.porcenPolimero * 1.05, 1), actual: this.nFormatter(lastRow.porcenPolimero, 1) },
            //   { nombre: this.translateService.instant("tiempoCalentamientoTotal"), dim: "h", toler: 10, programado: this.nFormatter(lastRow.tiempoCalentamientoTotal * 1.05, 1), actual: this.nFormatter(lastRow.tiempoCalentamientoTotal, 1) },
            //   { nombre: this.translateService.instant("tiempoEnfriamientoTotal"), dim: "h", toler: 10, programado: this.nFormatter(lastRow.tiempoEnfriamientoTotal * 1.05, 1), actual: this.nFormatter(lastRow.tiempoEnfriamientoTotal, 1) },
            // ];

            console.log(this.listaValoresTecnologicos);

            this.listaValoresTecnologicos.forEach(function (valor) {
              if (parseFloat(valor.actual) >= (parseFloat(valor.programado) - (parseFloat(valor.programado) * parseFloat(valor.toler) / 100))
                && parseFloat(valor.actual) <= (parseFloat(valor.programado) + (parseFloat(valor.programado) * parseFloat(valor.toler) / 100))
                || valor.toler == " -")
                valor.lineaRoja = false;
              else
                valor.lineaRoja = true;
            }, this);
          } else {
            //NO TIENE DATOS
            this.listaValoresTecnologicos = [];
            if (datosConfigHorno.data[0].energia_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("energia"), dim: "J", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].potencia_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("potencia"), dim: "KW", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].intensidad_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("intensidad"), dim: "A", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].voltaje_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("voltaje"), dim: "V", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].frecuencia_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("frecuencia"), dim: "KHz", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].caudal_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("caudal"), dim: "l/m", toler: " -", programado: "-", actual: "-", lineaRoja: false });
              // } if (datosConfigHorno.data[0].activo) {
              //   this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("tDucha"), dim: "ºC", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].presionBombaDucha_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("presionBombaDucha"), dim: "bar.", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].temperaturaPolimero_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("temperaturaPolimero"), dim: "ºC", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].porcenPolimero_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("porcenPolimero"), dim: "%", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].tiempoCalentamientoTotal_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("tiempoCalentamientoTotal"), dim: "h", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].tiempoEnfriamientoTotal_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("tiempoEnfriamientoTotal"), dim: "h", toler: " -", programado: "-", actual: "-", lineaRoja: false });
            } if (datosConfigHorno.data[0].velocidadRotacion_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("velocidadRotacion"), dim: "rpm", toler: " -", programado:  " -", actual:  " -", lineaRoja: false });
            } if (datosConfigHorno.data[0].posicionEje1_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("posicionEje1"), dim: "mm", toler:  " -", programado:  " -", actual:  " -", lineaRoja: false });
            } if (datosConfigHorno.data[0].posicionEje2_activo) {
              this.listaValoresTecnologicos.push({ nombre: this.translateService.instant("posicionEje2"), dim: "mm", toler:  " -", programado:  " -", actual:  " -", lineaRoja: false });
            }

            // this.listaValoresTecnologicos = [
            //   { nombre: this.translateService.instant("energia"), dim: "J", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("potencia"), dim: "K", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("intensidad"), dim: "A", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("voltaje"), dim: "V", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("frecuencia"), dim: "Hz", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("caudal"), dim: "l/m", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("tDucha"), dim: "ºC", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("presionBombaDucha"), dim: "bar.", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("temperaturaPolimero"), dim: "ºC", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("porcenPolimero"), dim: "%", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("tiempoCalentamientoTotal"), dim: "h", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            //   { nombre: this.translateService.instant("tiempoEnfriamientoTotal"), dim: "h", toler: " -", programado: "-", actual: "-", lineaRoja: false },
            // ];
          }
          //#endregion

          this.loadingPestannas = false;
        });

      });

  }

  conseguirIdsRecetasAnterioresPosteriores() {
    var indice = 0;
    for (var i = 0; i < this.listaRecetas.length; i++) {
      if (this.listaRecetas[i].seleccionado == true) indice = i;
    }
    var indiceMin = indice - 15;
    var indiceMax = indice + 15;

    var listaIdRecetas = [];
    for (var i = 0; i < this.listaRecetas.length; i++) {
      if (i >= indiceMin && i <= indiceMax) listaIdRecetas.push(this.listaRecetas[i].id);
    }
    return listaIdRecetas;
  }

  RedirigirRendimiento() {
    this.router.navigate(["informerendimiento/" + this.idmaquina]);
  }

  refreshDataPestanas() {

    if (this.router.url == '/procesoshorno/' + this.idmaquina) {

      this.cargarDatosProcesos();
      this.cargarDatosMantenimientos();
      this.cargarDatosNotas();

    } else {
      clearInterval(this.interval2);
    }

  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.esRojo) {
      case false:
        return "";
      case true:
        return "gridFilaRoja";
      default:
        return "";
    }
  }

  // PESTAÑAS PROCESOS
  cargarDatosProcesos() {
    this.hmiService.Get_ProcesosHistorial(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.historicoProcesos = [];
      datuak.forEach(function (valor, indice, array) {
        var tipoProceso = "";
        if (valor.idProcesos_Tipo == 1) tipoProceso = this.translateService.instant("ejecucion");
        if (valor.idProcesos_Tipo == 2) tipoProceso = this.translateService.instant("parada");
        if (valor.idProcesos_Tipo == 3) tipoProceso = this.translateService.instant("preparacion");
        if (valor.idProcesos_Tipo == 4) tipoProceso = this.translateService.instant("mantenimiento");
        if (valor.idProcesos_Tipo == 6) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 7) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 8) tipoProceso = this.translateService.instant("apagado");
        this.historicoProcesos.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            tipo: tipoProceso,
            tipoAlarNombre: valor.descripcion,
            nombre: valor.nombrePrograma,
            OF: valor.of,
            tiemporeal: this.myFunctions.secondsTo_HH_MM_SS(valor.tiemporeal),
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoPredictivo),
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_" + valor.idProcesos_Tipo,
          }
        );
      }, this);
    });

    this.hmiService.Get_ProcesosAlarmas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.alarmas = [];
      datuak.forEach(function (valor, indice, array) {
        var divnombre;
        if (valor.idAlarma_tipo == 1) divnombre = "css_linea_lateral_2";
        else divnombre = "css_linea_lateral_6";
        this.alarmas.push(
          {
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            numeroAlarma: valor.numeroAlarma,
            descripcion: valor.descripcion,
            divcss: divnombre,
          }
        );
      }, this);
    });

    this.hmiService.Get_Planificados(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.planificados = [];
      datuak.forEach(function (valor, indice, array) {
        this.planificados.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            operacion: valor.operacion,
            OF: valor.of,
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoEstimado),
            programa: 123456,
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_8",
          }
        );
      }, this);
    });

  }

  // MANTENIMIENTOS
  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow();
      var finHoy = this.myFunctions.getDateNow();
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);

            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }
            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            //ANTES DE USAR EL CALCULO DEL PROXIMO MANTENIMIENTO, HAY QUE TRADUCIRLO A LA HORA DEL USUARIO!
            // hacer esto desde la DB es dificil xq se pasa dia y hora... no una fecha como tal.
            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.myFunctions.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }

          }
          else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }

          if (row.idTipo == 1) {//diario
            tipo = "diario";
          }
          else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          }
          else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          }
          else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          } 
          else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      });

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      });

    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  // NOTAS
  cargarDatosNotas() {
    this.hmiService.Get_NotasActualesMaquina(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.notas = datuak;
    });
  }

  //GRAFICOS
  aplicarZoomEnGrafico() {
    this.cargarGraficosTodos();
  }

  onClickMostrarTodos() {
    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;
    this.cargarGraficosTodos();
  }

  clicklLeyenda(campo) {
    var numeroDeCamposSeleccionados = this.listaTooltip.filter(x => x.estaEnGrafico != -1 && x.ensennar).length;
    
    var maxCamposSeleccionados = 1;
    if (this.maquina.tipoMaquinaInduccion == 1) maxCamposSeleccionados = 4;
    
    if (campo.seleccionadoLeyenda) {
      //SE HA DESELECCIONADO UN CAMPO
      campo.seleccionadoLeyenda = false;
      campo.estaEnGrafico = -1;
    } else if (!campo.seleccionadoLeyenda && numeroDeCamposSeleccionados < maxCamposSeleccionados) {
      //SE HA SELECCIONADO UN CAMPO
      campo.seleccionadoLeyenda = true;
      campo.estaEnGrafico = numeroDeCamposSeleccionados + 1;
    } else if (!campo.seleccionadoLeyenda && this.maquina.tipoMaquinaInduccion == 2) {
      // SE HA SELECCIONADO UN CAMPO PERO HAY OTRO SELECCIONADO, POR LO QUE HAY QUE DESELECCIONARLO
      this.listaTooltip.forEach(element => {
        element.seleccionadoLeyenda = false;
        element.estaEnGrafico = -1
      });
      campo.seleccionadoLeyenda = true;
      campo.estaEnGrafico = 1;

      this.listaTooltip.filter(f => f.nombre.includes(campo.nombre)).forEach(element => {
        element.estaEnGrafico = 1;
        element.seleccionadoLeyenda = true;
      });
    }


    this.mostrarGraficosPorLeyenda = [false, false, false, false, false];
    var indexGrafico = 1;
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico != -1) {
        campo.estaEnGrafico = indexGrafico;
        this.mostrarGraficosPorLeyenda[indexGrafico - 1] = true;
        if (this.maquina.tipoMaquinaInduccion == 1) indexGrafico++;
      }
    }, this);

    // Esto es necesario para que el label de los graficos se actualice
    this.pintarGraficoMultiEje();
    this.cargarGraficosTodos();
  }

  cargarGraficosTodos() {
    var fechas: any = [];
    var valores1: any = [];
    var valores2: any = [];
    var valores3: any = [];
    var valores4: any = [];
    var valores5: any = [];

    var i;

    var estanEnGrafico: any = {};
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico == 1 && campo.ensennar) estanEnGrafico[campo.nombre] = 1;
      if (campo.estaEnGrafico == 2 && campo.ensennar) estanEnGrafico[campo.nombre] = 2;
      if (campo.estaEnGrafico == 3 && campo.ensennar) estanEnGrafico[campo.nombre] = 3;
      if (campo.estaEnGrafico == 4 && campo.ensennar) estanEnGrafico[campo.nombre] = 4;
      // if (campo.estaEnGrafico == 5 && campo.ensennar) estanEnGrafico[campo.nombre] = 5;
      if (campo.estaEnGrafico == -1 && campo.ensennar) estanEnGrafico[campo.nombre] = -1;
    }, this);

    for (i = 0; i < this.datosGraficoMultieje.fechas.length; i++) {
      if ((this.datosGraficoMultieje.fechas[i + 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i - 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] >= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i] >= this.fechaFinZoom)) {

        fechas.push(this.datosGraficoMultieje.fechas[i]);

        for (const key in this.datosGraficoMultieje) {
          if (key != "fechas") {
            if (estanEnGrafico[key] == 1) {
              valores1.push(this.datosGraficoMultieje[key][i]);
              if (this.maquina.tipoMaquinaInduccion == 2) {
                valores2.push(this.datosGraficoMultieje[key + '_min'][i]);
                valores3.push(this.datosGraficoMultieje[key + '_max'][i]);
              }
            }  
            if (estanEnGrafico[key] == 2) valores2.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 3) valores3.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 4) valores4.push(this.datosGraficoMultieje[key][i]);
            // if (estanEnGrafico[key] == 5) valores5.push(this.datosGraficoMultieje[key][i]);
          }
        }

      }
    }

    if (fechas.length > 300) {
      fechas = this.aplanarArray(fechas, 300);
      valores1 = this.aplanarArray(valores1, 300);
      valores2 = this.aplanarArray(valores2, 300);
      valores3 = this.aplanarArray(valores3, 300);
      valores4 = this.aplanarArray(valores4, 300);
      // valores5 = this.aplanarArray(valores5, 300);
    }

    this.arrayFechas = fechas;


    //Tooltip
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico == 1) {
        campo.valoresMomentaneo = []
        campo.valoresMomentaneo.push(valores1);
        if (this.maquina.tipoMaquinaInduccion == 2) {
          campo.valoresMomentaneo.push(valores2);
          campo.valoresMomentaneo.push(valores3);
        }
      }
      if (campo.estaEnGrafico == 2) campo.valoresMomentaneo = valores2;
      if (campo.estaEnGrafico == 3) campo.valoresMomentaneo = valores3;
      if (campo.estaEnGrafico == 4) campo.valoresMomentaneo = valores4;
      // if (campo.estaEnGrafico == 5) campo.valoresMomentaneo = valores5;
    }, this);

    var c1: boolean = this.fechaInicioZoom < fechas[0];
    var c2: boolean = this.fechaFinZoom > fechas[fechas.length - 1];

    if (c1) fechas.unshift(this.fechaInicioZoom);
    fechas.unshift('x');
    if (c2) fechas.push(this.fechaFinZoom);

    if (c1) valores1.unshift(null);
    valores1.unshift('valor');
    if (c2) valores1.push(null);

    if (c1) valores2.unshift(null);
    if (this.maquina.tipoMaquinaInduccion == 1) valores2.unshift('valor');
    else valores2.unshift('valor_min');
    if (c2) valores2.push(null);

    if (c1) valores3.unshift(null);
    if (this.maquina.tipoMaquinaInduccion == 1)  valores3.unshift('valor');
    else valores3.unshift('valor_max');
    if (c2) valores3.push(null);

    if (c1) valores4.unshift(null);
    valores4.unshift('valor');
    if (c2) valores4.push(null);

    // if (c1) valores5.unshift(null);
    // valores5.unshift('valor');
    // if (c2) valores5.push(null);

    //valores1
    var campoTooltip = this.listaTooltip.filter(x => x.estaEnGrafico == 1)
    let colorTooltip1 = "black";
    if (campoTooltip[0] != undefined) {
      colorTooltip1 = campoTooltip[0].color
      if (this.maquina.tipoMaquinaInduccion == 2) {
        this.grafico1.load({ columns: [fechas, valores1, valores2, valores3] });
        this.grafico1.data.colors({ valor: d3.rgb(campoTooltip[0].color), valor_min: d3.rgb(campoTooltip[1].color), valor_max: d3.rgb(campoTooltip[2].color) });
        this.grafico1.axis.max({y: Math.max(Math.max(Math.max(...valores3.slice(1)), ...valores2.slice(1)), ...valores1.slice(1)) * 1.1}); 
        this.grafico1.axis.min({y: Math.min(Math.min(Math.min(...valores3.slice(1)), ...valores2.slice(1)), ...valores1.slice(1)) * 0.9}); 
      } else {
        this.grafico1.load({ columns: [fechas, valores1] });
        this.grafico1.data.colors({ valor: d3.rgb(campoTooltip[0].color) });
        this.grafico1.axis.max({y: Math.max(...valores1.slice(1)) * 1.1});
        this.grafico1.axis.min({y: Math.min(...valores1.slice(1)) * 0.9}); 
      }

      d3.selectAll('#grafico1_hmiHor .c3-axis-y line').style("stroke", campoTooltip[0].color);
      d3.selectAll('#grafico1_hmiHor .c3-axis-y path').style("stroke", campoTooltip[0].color);
      d3.selectAll('#grafico1_hmiHor .c3-axis-y text').style("fill", campoTooltip[0].color);
      d3.selectAll('#grafico1_hmiHor .c3-axis-y path').style("stroke-width", "1.3px");
      d3.selectAll('#grafico1_hmiHor .c3-line').style("stroke-width", "1.3px");
      d3.selectAll('#grafico1_hmiHor .c3-axis-y tspan').style("fill", campoTooltip[0].color);

      // // minimos
      // this.grafico1.load({ columns: [fechas, valores2] });
      // this.grafico1.data.colors({ valor: d3.rgb(campoTooltip.color) });

      // // maximos
      // this.grafico1.load({ columns: [fechas, valores3] });
      // this.grafico1.data.colors({ valor: d3.rgb(campoTooltip.color) });
    } else {
      this.grafico1.load({ columns: [['x'], ['valor']] });
    }

    if (this.maquina.tipoMaquinaInduccion == 1) {
      // valores2
      var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 2)
      if (campoTooltip != undefined) {
        this.grafico2.load({ columns: [fechas, valores2] });
        this.grafico2.data.colors({ valor: d3.rgb(campoTooltip.color) });
        this.grafico2.axis.max({y: Math.max(...valores2.slice(1)) * 1.1});
        this.grafico2.axis.min({y: Math.min(...valores2.slice(1)) * 0.9});
        d3.selectAll('#grafico2_hmiHor .c3-axis-y line').style("stroke", campoTooltip.color);
        d3.selectAll('#grafico2_hmiHor .c3-axis-y path').style("stroke", campoTooltip.color);
        d3.selectAll('#grafico2_hmiHor .c3-axis-y text').style("fill", campoTooltip.color);
        d3.selectAll('#grafico2_hmiHor .c3-axis-y path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico2_hmiHor .c3-line').style("stroke-width", "1.3px");
        d3.selectAll('#grafico2_hmiHor .c3-axis-y tspan').style("fill", campoTooltip.color);
      } else {
        this.grafico2.load({ columns: [['x'], ['valor']] });
      }

      // valores3
      var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 3)
      if (campoTooltip != undefined) {
        this.grafico3.load({ columns: [fechas, valores3] });
        this.grafico3.data.colors({ valor: d3.rgb(campoTooltip.color) });
        // d3.selectAll('#grafico3_hmiHor .c3-axis-y line').style("stroke", campoTooltip.color);
        // d3.selectAll('#grafico3_hmiHor .c3-axis-y tspan').style("fill", campoTooltip.color);
        // d3.selectAll('#grafico3_hmiHor .c3-axis-y path').style("stroke", campoTooltip.color);

        this.grafico3.axis.max({y2: Math.max(...valores3.slice(1)) * 1.1 });
        this.grafico3.axis.min({y2: Math.min(...valores3.slice(1)) * 0.9 });
        d3.selectAll('#grafico3_hmiHor .c3-axis-y2 line').style("stroke", campoTooltip.color);
        d3.selectAll('#grafico3_hmiHor .c3-axis-y2 path').style("stroke", campoTooltip.color);
        d3.selectAll('#grafico3_hmiHor .c3-axis-y2 text').style("fill", campoTooltip.color);
        d3.selectAll('#grafico3_hmiHor .c3-axis-y2 path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico3_hmiHor .c3-line').style("stroke-width", "1.3px");
        d3.selectAll('#grafico3_hmiHor .c3-axis-y2 tspan').style("fill", campoTooltip.color);//.attr("x", "-7");
      } else {
        this.grafico3.load({ columns: [['x'], ['valor']] });
      }

      // valores4
      var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 4)
      if (campoTooltip != undefined) {
        this.grafico4.load({ columns: [fechas, valores4] });
        this.grafico4.data.colors({ valor: d3.rgb(campoTooltip.color) });
        // d3.selectAll('#grafico4_hmiHor .c3-axis-y line').style("stroke", campoTooltip.color);
        // d3.selectAll('#grafico4_hmiHor .c3-axis-y tspan').style("fill", campoTooltip.color);
        // d3.selectAll('#grafico4_hmiHor .c3-axis-y path').style("stroke", campoTooltip.color);

        this.grafico4.axis.max({y2: Math.max(...valores4.slice(1)) * 1.1});
        this.grafico4.axis.min({y2: Math.min(...valores4.slice(1)) * 0.9});
        d3.selectAll('#grafico4_hmiHor .c3-axis-y2 line').style("stroke", campoTooltip.color);
        d3.selectAll('#grafico4_hmiHor .c3-axis-y2 path').style("stroke", campoTooltip.color);
        d3.selectAll('#grafico4_hmiHor .c3-axis-y2 text').style("fill", campoTooltip.color);
        d3.selectAll('#grafico4_hmiHor .c3-axis-y2 path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico4_hmiHor .c3-line').style("stroke-width", "1.3px");
        d3.selectAll('#grafico4_hmiHor .c3-axis-y2 tspan').style("fill", campoTooltip.color);//.attr("x", "-7");
        
      } else {
        this.grafico4.load({ columns: [['x'], ['valor']] });
      }
    }

    //valores5
    // var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 5)
    // if (campoTooltip != undefined) {
    //   this.grafico5.load({ columns: [fechas, valores5] });
    //   this.grafico5.data.colors({ valor: d3.rgb(campoTooltip.color) });
    //   d3.selectAll('#grafico5_hmiHor .c3-axis-y line').style("stroke", campoTooltip.color);
    //   d3.selectAll('#grafico5_hmiHor .c3-axis-y tspan').style("fill", campoTooltip.color);
    //   d3.selectAll('#grafico5_hmiHor .c3-axis-y path').style("stroke", campoTooltip.color);
      
    // } else {
    //   this.grafico5.load({ columns: [['x'], ['valor']] });
    // }

    //Regiones (grafico 1)
    if (this.fechaIniRecetaSeleccionada != undefined && this.fechaFinRecetaSeleccionada != undefined) {
      var region = { start: this.fechaIniRecetaSeleccionada, end: this.fechaFinRecetaSeleccionada, class: 'regionTiempoPresionRemanente' };

      this.grafico1.regions.remove();
      this.grafico1.internal.config.regions = [];
      this.grafico1.regions.add(region); this.grafico1.regions.add(region);//Poner 2 veces para tener mas opacidad
    }

    d3.selectAll('#grafico1_hmiHor .c3-axis-y tspan').style("fill", colorTooltip1);
    this.posicionarEjesGrafico();

  }

  aplanarArray(data, aCuantosElementos) {
    //var aCuantosElementos = 1000;
    //var data = [15, 16, 9, 1, 84, 1, 6, 77];

    var resultado = [];
    resultado.push(data[0]);
    var i;
    for (i = 1; i < aCuantosElementos; i++) {
      resultado.push(data[Math.round((data.length / aCuantosElementos) * i)]);
    }
    resultado.push(data[data.length - 1]);
    return resultado;
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }
  //FIN GRAFICOS

  // CARGAR PANEL DE LA MAQUINA
  RedirigirAProceso(idmaquina) {
    this.router.navigate(["procesoshorno/" + idmaquina]);
  }

  calcularCanales(maquina: any, i: number) {
    var horas: any = [];
    if (this.router.url == '/procesoshorno/' + maquina.id && i < 5)
      setTimeout((d) => this.calcularCanales(maquina, i + 1), 1000);
    var ahora = new Date(Date.parse(this.ejecucionActual[0].horaServidor));//this.myFunctions.getDateNow();
    //Le sumamos un segundo a la hora
    var dateAux = new Date(Date.parse(this.ejecucionActual[0].horaServidor));
    dateAux.setSeconds(dateAux.getSeconds() + 1);
    this.ejecucionActual[0].horaServidor = dateAux

    maquina.eje1 = true;
    maquina.eje2 = false;
    maquina.eje3 = false;
    maquina.eje4 = false;

    if (this.ejecucionActual[0].length > 0) {
      maquina.enEjecucion = false;
    }

    // maquina.procesos_Tipostr = this.translateService.instant(maquina.ejecuciones[0].procesos_Tipo);
    // maquina.programa = maquina.ejecuciones[0].nombreprograma;

    if (this.maquina.idHistorico_operaciones != -1) {
      if (this.tiempoLoteSegundos == -1 || !this.r1) {
        this.tiempoCorregido = '--:--:--';
      } else {
        this.tiempoPiezaSegundos = this.tiempoPiezaSegundos + 1;
        this.tiempoLoteSegundos = this.tiempoLoteSegundos + 1;
        this.tiempoLotePreparacionSegundos = this.tiempoLotePreparacionSegundos + 1;
        this.tiempoCorregido = this.segundosAFormatoHora(this.tiempoLoteSegundos);
      }
    }
    var now: any = this.ejecucionActual[0].horaServidor;
    this.tiempo = (now.getTime() - Date.parse(this.ejecucionActual[0].fecha)) / 1000;
    this.tiempoEjecucion = this.corregirFormato(this.tiempo)

    var fechaOrig = new Date(Date.parse(this.ejecucionActual[0].fecha));
    var hanpasado = this.HanPasadoDias(fechaOrig, ahora, 0);
    var hapasadoTiempo = this.HanPasadoTiempo(fechaOrig, ahora, 0);
    var hanpasadoConAcumulado = this.HanPasadoDias(fechaOrig, ahora, this.ejecucionActual[0].tiempoAcumuladoOperacion);
    var hapasadoTiempoConAcumulado = this.HanPasadoTiempo(fechaOrig, ahora, this.ejecucionActual[0].tiempoAcumuladoOperacion);
    if (this.ejecucionActual[0].idProcesos_tipo != undefined && this.tituloDonut.toLowerCase() == "ejecucion") {
      maquina.enEjecucion = true;
    }

    maquina.tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(this.tiempoPred);
    maquina.tiempoEst = this.myFunctions.secondsTo_HH_MM_SS(this.tiempoEst);
    this.tiempoLotePreparacion = this.segundosAFormatoHora(this.tiempoLotePreparacionSegundos);

    if (hanpasado > 0) {
      horas = hapasadoTiempo.toString().split(":");
      this.tiempoActualPieza.set(maquina.id, hanpasado.toString() + "d " + horas[0] + "h");
      maquina.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
    }

    else {
      maquina.tiempopasado = hapasadoTiempo.toString();
      this.tiempoActualPieza.set(maquina.id, hapasadoTiempo.toString());
    }

    if (this.mouseOverPorcentajeId != maquina.id) {
      if (hanpasadoConAcumulado > 0) {
        if (!isNaN(hanpasadoConAcumulado)) {
          horas = hapasadoTiempoConAcumulado.toString().split(":");
          this.tiempoActualLote.set(maquina.id, hanpasadoConAcumulado.toString() + "d " + horas[0] + "h");
          maquina.tiempopasadoConAcumulado = hanpasadoConAcumulado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempoConAcumulado.toString();
        }
      }

      else {
        if (!isNaN(hanpasadoConAcumulado)) {
          maquina.tiempopasadoConAcumulado = hapasadoTiempoConAcumulado.toString();
          this.tiempoActualLote.set(maquina.id, hapasadoTiempoConAcumulado.toString());
        } else {
          maquina.tiempopasadoConAcumulado = '00:00:00';
          this.tiempoActualLote.set(maquina.id, '00:00:00');
        }
      }
    }

    // this.calcularTiempoEstPredPieza(maquina);
    // this.calcularDesviacionPieza(maquina);
    this.calcularDesviacionLote(maquina);
    this.CargarIconosEstadoMaquina(maquina);
    this.calcularDesviacion();
  }

  calcularTiempos() {

    if (this.r1) {
      if (this.unicaEjecucionTiempos) {
        this.unicaEjecucionTiempos = false;
        this.centroMecanizadoService.get_Tiempos_Lote_Pieza_byId(this.idmaquina).subscribe(result => {
          var datosTiempo: any = result;
          var tieneTiempo: boolean = false;

          if (datosTiempo[0]?.tiempoLote != undefined && datosTiempo[0]?.tiempoUltimaPieza != undefined) {
            this.tiempoPiezaSegundos = datosTiempo[0].tiempoUltimaPieza;
            this.tiempoLoteSegundos = datosTiempo[0].tiempoLote;
            this.tiempoLotePreparacionSegundos = datosTiempo[0].tiempoLotePrep;
            this.tiempoPiezaPreparacionSegundos = datosTiempo[0].tiempoUltimaPiezaPrep;

          } else {
            this.tiempoPiezaSegundos = -1;
            this.tiempoLoteSegundos = -1;
          }
          // this.loadingDatosTiempo = false;
          setTimeout((d) => this.loadingDatosTiempo = false, 1000);
          this.unicaEjecucionTiempos = true;

          this.timeOutTiempos = setTimeout((d) => this.calcularTiempos(), 60000);
        });
      }

    } else {
      setTimeout((d) => this.calcularTiempos(), 1000);
    }

  }

  mouseOverPorcentaje(id) {
    this.mouseOverPorcentajeId = id;
  }

  mouseOutPorcentaje(id) {
    this.mouseOverPorcentajeId = undefined;
  }

  HanPasadoTiempo(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    let diffInMilliSeconds = Math.abs(fechaOrig - ahora) / 1000;
    diffInMilliSeconds = diffInMilliSeconds + tiempoAcumuladoOperacion;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = Math.floor(diffInMilliSeconds % 60);
    diffInMilliSeconds -= minutes * 60;

    let difference = '';

    if (hours < 10) difference = "0" + hours.toString();
    else difference = hours.toString();

    difference = difference + ":";
    if (minutes < 10) difference = difference + "0" + minutes.toString();
    else difference = difference + minutes.toString();

    difference = difference + ":";
    if (seconds < 10) difference = difference + "0" + seconds.toString();
    else difference = difference + seconds.toString();

    return difference;
  }

  HanPasadoDias(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    var dt1 = new Date(fechaOrig);
    var dt2 = new Date(ahora);
    var difSegundos = (dt2.getTime() - dt1.getTime()) / 1000;
    difSegundos = difSegundos + tiempoAcumuladoOperacion;
    return Math.floor(difSegundos / (60 * 60 * 24));
  }

  calcularDesviacionPieza(maquina: any) {
    var dias = 0;
    var horas = 0;
    if (maquina.ejecuciones[0].hechas != 0) {
      this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
      this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

      if (this.listaTiempo.length > 2) {
        this.tiempoAcumuladoAux = "";
        this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
        this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
        this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
      } else {
        this.listaTiempo = this.tiempoAcumuladoAux.split(':');
        this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1].toString());
      }

      this.tiempoMedioPieza = (parseInt(this.tiempoAcumulado.toString()) / parseInt(maquina.ejecuciones[0].hechas.toString()));
      this.desviacionPieza = parseInt(this.tiempoMedioPieza.toString()) - parseInt(this.piezaEstMap.get(maquina.id).toString());


      if (Math.floor(this.tiempoMedioPieza / 60) > 24) {
        dias = Math.floor(Math.floor(this.tiempoMedioPieza / 60) / 24);
        horas = Math.floor((this.tiempoMedioPieza % 60) / 24);
        this.piezaTiempoMedioMap.set(maquina.id, dias + "d " + horas + "h");
      } else {
        this.piezaTiempoMedioMap.set(maquina.id, this.pad(Math.floor(this.tiempoMedioPieza / 60)) + ":" + this.pad(Math.floor((this.tiempoMedioPieza % 60) / 60)) + ":" + this.pad(Math.floor(((this.tiempoMedioPieza % 60) % 60) / 1)));
      }




      if (this.desviacionPieza < 0) {
        if (Math.abs(this.desviacionPieza) > 60) {
          if (Math.floor(Math.abs(this.desviacionPieza) / 60) > 24) {
            dias = Math.floor(Math.floor(Math.abs(this.desviacionPieza) / 60) / 24);
            horas = Math.floor(Math.floor(Math.abs(this.desviacionPieza) % 60) / 24);
            this.piezaDesviacionMap.set(maquina.id, "-" + dias + "d " + horas + "h");
          } else {
            this.piezaDesviacionMap.set(maquina.id, '- ' + Math.floor(Math.abs(this.desviacionPieza) / 60) + "h " + Math.floor(Math.abs(this.desviacionPieza) % 60) + "min");
          }
        } else {
          this.piezaDesviacionMap.set(maquina.id, '- ' + Math.abs(this.desviacionPieza) + "min");
        }
      } else {
        if (Math.abs(this.desviacionPieza) > 60) {
          if (Math.floor(Math.abs(this.desviacionPieza) / 60) > 24) {
            dias = Math.floor(Math.floor(Math.abs(this.desviacionPieza) / 60) / 24);
            horas = Math.floor(Math.floor(Math.abs(this.desviacionPieza) % 60) / 24);
            this.piezaDesviacionMap.set(maquina.id, "+" + dias + "d " + horas + "h");
          } else {
            this.piezaDesviacionMap.set(maquina.id, '+ ' + Math.floor(Math.abs(this.desviacionPieza) / 60) + "h " + Math.floor((Math.abs(this.desviacionPieza) % 60)) + "min");
          }
        } else {
          this.piezaDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionPieza) + "min");
        }
      }
    } else {
      this.tiempoMedioPieza = 0;
      this.piezaTiempoMedioMap.set(maquina.id, this.tiempoMedioPieza);
      this.desviacionPieza = 0;
      this.piezaDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionPieza) + "min");
    }

  }

  calcularDesviacionLote(maquina: any) {
    var dias = 0;
    var horas = 0;
    if (maquina.tiempopasadoConAcumulado != undefined) {
      this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
    } else {
      this.tiempoAcumuladoAux = "00:00:00";
    }

    this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

    if (this.listaTiempo.length > 2) {
      this.tiempoAcumuladoAux = '';
      this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
      this.tiempoAcumulado = parseInt(this.listaTiempo[0]) * 1440 + parseInt(this.listaTiempo2[0]) * 60 + parseInt(this.listaTiempo2[1].toString());
    } else {
      this.listaTiempo = this.tiempoAcumuladoAux.split(':');
      this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1].toString());
    }
    if (maquina.tiempoEst != undefined) {
      this.tiempoEstimadoLoteAux = maquina.tiempoEst.toString();
    } else {
      this.tiempoEstimadoLoteAux = "00:00:00";
    }

    this.listaTiempo = this.tiempoEstimadoLoteAux.split(' ');

    if (this.listaTiempo.length > 2) {
      this.tiempoEstimadoLoteAux = '';
      this.tiempoEstimadoLoteAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
    } else {
      this.listaTiempo = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1]);
    }

    this.desviacionLote = this.tiempoAcumulado - this.tiempoEstimadoLote;

    if (this.desviacionLote < 0) {
      if (Math.abs(this.desviacionLote) > 60) {
        if (Math.floor(Math.abs(this.desviacionLote) / 60) > 24) {
          dias = Math.floor(Math.floor(Math.abs(this.desviacionLote) / 60) / 24);
          horas = Math.floor(Math.floor(Math.abs(this.desviacionLote) % 60) / 24);
          this.loteDesviacionMap.set(maquina.id, "-" + dias + "d " + horas + "h");
        } else {
          this.loteDesviacionMap.set(maquina.id, '- ' + (Math.floor(Math.abs(this.desviacionLote) / 60) + "h " + Math.floor(Math.abs(this.desviacionLote) % 60)) + "min")
        }
      } else {
        this.loteDesviacionMap.set(maquina.id, '- ' + Math.abs(this.desviacionLote) + "min")
      }
    } else {
      if (Math.abs(this.desviacionLote) > 60) {
        if (Math.floor(Math.abs(this.desviacionLote) / 60) > 24) {
          dias = Math.floor(Math.floor(Math.abs(this.desviacionLote) / 60) / 24);
          horas = Math.floor(Math.floor(Math.abs(this.desviacionLote) % 60) / 24);
          this.loteDesviacionMap.set(maquina.id, "+" + dias + "d " + horas + "h");
        } else {
          this.loteDesviacionMap.set(maquina.id, '+ ' + (Math.floor(Math.abs(this.desviacionLote) / 60) + "h " + Math.floor(Math.abs(this.desviacionLote) % 60)) + "min")
        }
      } else {
        this.loteDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionLote) + "min")
      }
    }
    if(this.tiempoLotePreparacionSegundos > maquina.tiempoPreparacionSegundos){
      this.tiempoDesvioPreparacion = "+ " + this.segundosAFormatoHora(Math.abs(this.tiempoLotePreparacionSegundos - this.tiempoEstPreparacion))
    }else{
      this.tiempoDesvioPreparacion = "- " + this.segundosAFormatoHora(Math.abs(this.tiempoEstPreparacion - this.tiempoLotePreparacionSegundos))
    }

  }

  calcularTiempoEstPredPieza(maquina: any) {

    if (this.tiempoEst == 0) {
      this.piezaEstMap.set(maquina.id, "00:00:00");
    } else {
      this.tiempoEstAux = parseInt(this.tiempoEst.toString()) / parseInt(this.cantidad.toString());

      var horas = this.pad(Math.floor(this.tiempoEstAux / 3600));
      var minutos = this.pad(Math.floor((this.tiempoEstAux % 3600) / 60));
      var segundos = this.pad(Math.floor(((this.tiempoEstAux % 3600) % 60) / 1));

      this.piezaEstMap.set(maquina.id, horas + ":" + minutos + ":" + segundos);

    }

    if (this.tiempoEst == 0) {
      this.piezaPredMap.set(maquina.id, "00:00:00");
    } else {
      this.tiempoPredAux = this.tiempoEst / this.cantidad;

      var horas2 = this.pad(Math.floor(this.tiempoPredAux / 3600));
      var minutos2 = this.pad(Math.floor((this.tiempoPredAux % 3600) / 60));
      var segundos2 = this.pad(Math.floor(((this.tiempoPredAux % 3600) % 60) / 1));

      this.piezaPredMap.set(maquina.id, horas2 + ":" + minutos2 + ":" + segundos2);

    }
  }

  CargarIconosEstadoMaquina(maquina) {
    var tipoProceso = this.tituloDonut.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(); //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    if (this.tiempoEst == 0) {
      maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      //this.piezaimgMap.set(maquina.id, "assets/dcg/dcg12-" + tipoProceso + ".png");
      maquina.porcentaje = "";
    } else {
      var ahora = new Date(Date.parse(this.ejecucionActual[0].horaServidor));//this.myFunctions.getDateNow();
      var fechaOrig = new Date(Date.parse(this.ejecucionActual[0].horaServidor));
      var tiempoAcumuladoOperacion = this.ejecucionActual[0].tiempoAcumuladoOperacion;

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2 + tiempoAcumuladoOperacion;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      if (maquina.tiempopasado != undefined) {
        var tiempoPasadoPiezaList = maquina.tiempopasado.split(":");
        // var tiempoPasadoPieza = parseInt(tiempoPasadoPiezaList[0]) * 3600 + parseInt(tiempoPasadoPiezaList[1]) * 60 + parseInt(tiempoPasadoPiezaList[2]);

        var piezaDocentaje = this.calcularPorcentaje(this.tiempoLoteSegundos, 12, Math.floor(parseInt(this.tiempoEst.toString()) / parseInt(this.cantidad.toString())), 1, true);
        var piezaPorcentaje = this.calcularPorcentaje(this.tiempoLoteSegundos, 100, Math.floor(parseInt(this.tiempoEst.toString()) / parseInt(this.cantidad.toString())), 1, true);
        this.piezaimgMap.set(maquina.id, "assets/dcg/dcg" + piezaDocentaje + "-" + tipoProceso + ".png")
        this.piezaPorcentajeMap.set(maquina.id, piezaPorcentaje + "%");
      }

      var preparacionDocentaje = this.calcularPorcentaje(this.tiempoLotePreparacionSegundos, 12, this.ejecucionActual[0].tiempoEstimadoPreparacion, 1, true);
      var preparacionPorcentaje = this.calcularPorcentaje(this.tiempoLotePreparacionSegundos, 100, this.ejecucionActual[0].tiempoEstimadoPreparacion, 1, true);

      this.preparacionimgMap = "assets/dcg/dcg" + preparacionDocentaje + "-preparacion.png";
      this.preparacionPorcentajeMap = preparacionPorcentaje + "%";

      var docentaje = this.calcularPorcentaje(segundosfiff, 12, this.tiempoEst, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, this.tiempoEst, 1, true);

      maquina.porcentaje = porcentaje + "%";
      maquina.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";


    }

  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  corregirFormato(tiempo: number) {
    // if (tiempo > 86400) {
    //   var dias = Math.floor(tiempo / 86400);
    //   var horas = this.pad(Math.floor(Math.floor(tiempo % 86400) / 3600));
    //   return dias + "d " + horas + "h"
    // } else {
      var hora = this.pad(Math.floor(tiempo / 3600));
      var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
      var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
      return hora + ":" + minutos + ":" + segundos;
    // }
  }

  calcularDesviacion() {
    var desv = this.tiempoEst - this.tiempoLoteSegundos;
    if (desv > 0) {
      this.desviacion = "- " + this.corregirFormato(Math.abs(desv));
    } else {
      this.desviacion = "+ " + this.corregirFormato(Math.abs(desv));
    }
  }

  segundosAFormatoHora(tiempo: number) {
    // if (tiempo < this.segundosDeUnDia) {
    var horas = this.pad(Math.floor(tiempo / 3600));
    var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
    var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
    var formatoHora = horas + ":" + minutos + ":" + segundos;
    // } else {
    //   var dias = Math.floor((tiempo / 3600) / 24);
    //   var horas = this.pad(Math.floor((tiempo / 3600) % 24));
    //   var formatoHora = dias + "d " + horas + "h"
    // }
    return formatoHora;
  }

  // END CARGAR PANEL DE LA MAQUINA

}
