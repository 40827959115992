import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/plantaisometrico`;

@Injectable()
export class PlantaIsometricoService {

  public listaPlantas: any | boolean = false;

  constructor(
    private http: HttpClient
  ) {
  }

  public Get_ListaPlantas_Model(): any | boolean {
    return this.listaPlantas;
  }

  public Set_ListaPlantas_Model(listaP: any): void {
    this.listaPlantas = listaP;
  }

  Get_Plantas() {
    return this.http.post<JSON>(`${baseUrl}/Get_Plantas`, {}, { withCredentials: true });
  }

  Get_Planta(idPlanta) {
    return this.http.post<JSON>(`${baseUrl}/Get_Planta`, { idPlanta: idPlanta }, { withCredentials: true });
  }

  Crear_Planta(nombre) {
    return this.http.post<JSON>(`${baseUrl}/Crear_Planta`, { nombre: nombre }, { withCredentials: true });
  }

  Borrar_Planta(idPlanta) {
    return this.http.post<JSON>(`${baseUrl}/Borrar_Planta`, { idPlanta: idPlanta }, { withCredentials: true });
  }

  Get_Dimensiones(idPlanta) {
    return this.http.post<JSON>(`${baseUrl}/Get_Dimensiones`, { idPlanta: idPlanta }, { withCredentials: true });
  }

  Set_Dimensiones(alto, ancho, idPlanta, nombrePlanta, idAreaProductiva, idSeccion) {
    return this.http.post<JSON>(`${baseUrl}/Set_Dimensiones`, { alto: alto, ancho: ancho, idPlanta: idPlanta, nombrePlanta: nombrePlanta, idAreaProductiva: idAreaProductiva, idSeccion: idSeccion }, { withCredentials: true });
  }

  Get_Maquinas() {
    return this.http.post<JSON>(`${baseUrl}/Get_Maquinas`, {}, { withCredentials: true });
  }

  Get_Disenno(idPlanta) {
    return this.http.post<JSON>(`${baseUrl}/Get_Disenno`, { idPlanta: idPlanta }, { withCredentials: true });
  }

  Get_Disenno_Segundo_Piso(idPlanta) {
    return this.http.post<JSON>(`${baseUrl}/Get_Disenno_Segundo_Piso`, { idPlanta: idPlanta }, { withCredentials: true });
  }

  Delete_Disenno(idPlanta) {
    return this.http.post<JSON>(`${baseUrl}/Delete_Disenno`, { idPlanta: idPlanta }, { withCredentials: true });
  }

  Insert_Disenno(dt) {
    return this.http.post<JSON>(`${baseUrl}/Insert_Disenno`, { dt }, { withCredentials: true });
  }

}
