import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ActivatedRoute } from '@angular/router';
/* SERVICES*/
import { ValidadorService, MaquinasService, UsuariosService, PerdidasService, OFService, PiezasService, 
         PartesService, OperacionesService, OperariosService, InformeRendimientoService, MenuService, InformeProyectosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

declare var GanttChartClick: any;

interface ItemTurnoGantt {
  min: Date,
  max: Date,
  clase: string
}

@Component({
  selector: 'app-validador',
  templateUrl: './validador.component.html'
})


export class ValidadorComponent {

  public timeout;

  public tMicroParada: number;
  public tMaximo: number;
  public tMaximoTotal: number;

  public visibleTipo: boolean = true;
  public visibleProyecto: boolean = true;
  public visibleOperario: boolean = true;
  public visibleObservaciones: boolean = true;
  public validable: boolean = false;
  public visiblePerdida: boolean = true;

  public requireProyecto: boolean = false;
  public requirePieza: boolean = false;
  public requireParte: boolean = false;
  public requireOperacion: boolean = false;

  public Tfaltas: string = "0:00:00";
  public Tejecucion: string = "0:00:00";
  public Tparada: string = "0:00:00";
  public TmicroparadaHHMMSS: string = "0:00:00";
  public Tpreparacion: string = "0:00:00";
  public Tmantenimiento: string = "0:00:00";
  public Talarma: string = "0:00:00";
  public Tapagada: string = "0:00:00";

  mySelection: number[] = [];
  mySelectionAux: number[] = [];
  user = this.userService.userValue;
  public dataValidador: any[];
  public timelinePerdidas = new Map();
  public dataValidador_idHT_con_Subestado: any[];
  public dataValidador_grid: any[];
  public dataValidadorSelecteds: any;
  public pageSize = 40;
  public formGroup: FormGroup;
  public fecha: Date;

  //private selectedMaquina: number = 0;
  //private Jmaquinas: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  //public JmaquinasMostradas: any;
  //private secciones: any;
  //public groupedSeccion: GroupResult[];
  //public seccionesSeleccionadas: any[] = [];

  //PANEL MAQUINAS
  private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  private Jvalidador: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API

  private fechaIni: Date;
  private fechaFin: Date;

  private Jtipos: any;
  private JtiposSelected: any;
  private JDattipos: any;

  private Jperdidas: any;
  private JperdidasSelected: any;
  private JDatperdidas: any;

  private Jproyectos: any;
  private JproyectoSelected: any;
  private JDatproyectos: any;

  private Jpiezas: any;
  private JpiezasSelected: any;
  private JDatpiezas: any;

  private Jpartes: any;
  private JpartesSelected: any;
  private JDatpartes: any;

  private JnSerie: any;
  private JnSerieSelected: any;
  private JDatnSerie: any;
  private nuevoNserie: string = "";
  private nSerieTextInput: boolean = false;
  private cantidadLote: number = 1;
  private cantidadLoteMax: number = 1;
  private usaLotes: boolean = false;

  private Jprogramas: any;
  private JprogramasSelected: any;
  private JDatprogramas: any;

  private Joperarios: any;
  private JoperariosSelected: any;
  private JDatoperarios: any;

  private Jobservacion: any;
  private Jdescripcion: any;


  public aparecer = false;

  // ANNADIR OPERACION
  public esSoloParada: boolean = false;
  public JtiposAnnadirOperacion: any;
  public verNSerie: boolean = false;
  public verLotes: boolean = false;
  public verColada: boolean = false;
  public verCantidades: boolean = false;
  public cantidadTotal: number = 0;
  private JnColadaSelected: any;
  private PPcantidadTerminada: number = 0;
  private PPcantidadAchatarrada: number = 0;
  private PPcantidadApartada: number = 0;
  public cantidadTerminadaMax: number = 9999;
  public cantidadAchatarradaMax: number = 9999;
  public cantidadApartadaMax: number = 9999;
  public verAceptar: boolean = false;
  public isOffline: boolean = false;
  public isTerminado: boolean = false;

  // PARA GUARDAR EL GRID AL HACER CLICK
  public grid: any;

  // SUBESTADOS
  public jSubEstados = [];
  public jSubEstados_personalizados = []; //subestados > 100
  private jSubEstados_personalizadosSelected: any;

  // ASIGNAR PERDIDA
  public asignarMultiperdida = [];
  public dataPerdidasGrid: any = [];
  public idIntervaloTiempo: any = {};
  public perdidaAsignada: any = {};
  public fechaLimite = new Date();
  public esParadaSinJustificar = false;
  public esParadaJustificada = false;
  public perdidaSeleccionada: any = {};
  public observacionPerdida = "";
  public justificarHastaFinProceso = false;
  public justificarHastaFin = "";
  public ensennarJustificarHastaFin = false;
  public esUltimoProceso = false;
  public ultimoJustificado = false;

  @ViewChild('popupEditar') popupEditar: NgbModalRef;
  modalReference: NgbModalRef;
  modalReferenceAsignar: NgbModalRef;
  modalReferenceloading: NgbModalRef;

  public loadingPanel: boolean = false;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  //"LOAD"

  //Variables para saber qué tenemos filtrado
  public sinOf = false;
  public sinOperario = false;
  public sinPerdida = false;
  public soloEjecuciones = false;
  public soloParadas = false;
  public soloMicroParadas = false;
  public soloPreparaciones = false;
  public soloMantenimientos = false;
  public soloAlarmas = false;
  public soloApagadas = false;

  public idMaquina = -1;

  constructor(private validadorService: ValidadorService,
              private maquinasService: MaquinasService,
              private userService: UsuariosService, 
              private translateService: TranslateService,
              private modalService: NgbModal, 
              private perdidasService: PerdidasService, 
              private oFService: OFService, 
              private piezasService: PiezasService,
              private partesService: PartesService, 
              private operacionesService: OperacionesService, 
              private operariosService: OperariosService,
              private informeRendimientoService: InformeRendimientoService, 
              private menuService: MenuService, 
              private myFunctions: MyFunctions,
              private informeProyectosService: InformeProyectosService,
              private route: ActivatedRoute) {

    this.loadingPanel = true;

    this.idMaquina = parseInt(this.route.snapshot.params['id']);

    this.menuService.titulo = this.translateService.instant('validador').toUpperCase();
    this.justificarHastaFin = this.translateService.instant('justificarHastaFin');

    this.userService.user.subscribe(x => this.user = x);

    this.cargarGrupos();
    this.cargarAreasProductivas();

    var fechaURL = this.route.snapshot.params['fechafin'];
    if (fechaURL != undefined) {
      this.fecha = this.myFunctions.YYYY_MM_DDToDate(fechaURL);
    } else {
      this.fecha = this.myFunctions.getDateNow();
    }
    //this.cargarValidador();

    this.cargarCombos();
  }


  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    } else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

  }

  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    } else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    } else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0) this.selectedMaquina = this.maquinasMostradas[0].id;
    else this.selectedMaquina = undefined;

    if (this.idMaquina > 0)
      this.selectedMaquina = this.idMaquina;

    var an = this.maquinas.filter(f => f.id == this.selectedMaquina)
    this.tMicroParada = an[0].tiempoMicroParada;

    //if (cargarDatos) this.identificarMaquinaYCargarDatos();

    if (cargarDatos) {
      this.cargarValidador();
    }

  }

  /////////SELECT ALL GRID/////////
  public selectAllState: SelectAllCheckboxState = 'unchecked';
  public state: State;

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {

    if (checkedState === 'checked') {

      this.mySelection = [];
      this.selectAllState = 'checked';

      var dataParaFiltrar;
      if (this.state == undefined) {
        dataParaFiltrar = this.dataValidador;
      } else {
        this.state.skip = 0;
        this.state.take = 10000000;
        dataParaFiltrar = process(this.dataValidador, this.state).data;
      }

      dataParaFiltrar.forEach(
        operacion => {
          this.mySelection.push(operacion.numFila)
        });

    } else {

      this.mySelection = [];
      this.selectAllState = 'unchecked';

    }

    this.mySelectionAux = this.mySelection;

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.mySelection = this.mySelectionAux;
    this.selectAllState = 'unchecked';

    if (this.sinOf) {
      this.infSinOF();
    } else if (this.sinOperario) {
      this.infSinOperario();
    } else if (this.sinPerdida) {
      this.infSinPerdida(this.tMicroParada);
    } else if (this.soloEjecuciones) {
      this.infEjecuciones();
    } else if (this.soloParadas) {
      this.infParadas();
    } else if (this.soloMicroParadas) {
      this.infMicroparadas();
    } else if (this.soloPreparaciones) {
      this.infPreparaciones();
    } else if (this.soloMantenimientos) {
      this.infMantenimientos();
    } else if (this.soloAlarmas) {
      this.infAlarmas();
    } else if (this.soloApagadas) {
      this.infApagadas();
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.mySelection = this.mySelectionAux;
  }
  /////////FIN SELECT ALL GRID/////////

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  public cargarCombos() {
    //TIPOS
    var obj: any =
      [
        { "value": "1", "text": "" + this.translateService.instant('ejecucion') + "" },
        { "value": "2", "text": "" + this.translateService.instant('parada') + "" },
        { "value": "3", "text": "" + this.translateService.instant('preparacion') + "" },
        { "value": "4", "text": "" + this.translateService.instant('mantenimiento') + "" },
        { "value": "6", "text": "" + this.translateService.instant('alarma') + "" },
        { "value": "8", "text": "" + this.translateService.instant('apagada') + "" }
      ];
    this.JDattipos = <JSON>obj;
    this.Jtipos = <JSON>obj;
    //PERDIDAS
    this.perdidasService.GetCombo().subscribe(
      json => {
        this.JDatperdidas = json;
        this.Jperdidas = this.JDatperdidas;
      }
    )
    //PROYECTOS
    this.oFService.GetComboConCliente().subscribe(
      json => {
        this.JDatproyectos = json;
        this.Jproyectos = this.JDatproyectos;
      }
    )
    //PIEZAS
    this.piezasService.GetCombo().subscribe(
      json => {
        this.JDatpiezas = json;
        this.Jpiezas = this.JDatpiezas;
      }
    )
    //PARTES
    this.partesService.GetCombo().subscribe(
      json => {
        this.JDatpartes = json;
        this.Jpartes = this.JDatpartes;
      }
    )
    //N SERIE //ES OBLIGATORIO SELECCIONAR PARTE PARA CARGAR SUS PIEZAS
    this.validadorService.Get_piezas_Nserie(0).subscribe(
      json => {
        this.JDatnSerie = json;
        this.JnSerie = [];// this.JDatnSerie;
      }
    )
    //PROGRAMAS
    this.operacionesService.GetCombo().subscribe(
      json => {
        this.JDatprogramas = json;
        this.Jprogramas = this.JDatprogramas;
      }
    )
    //OPERARIOS
    this.operariosService.GetCombo().subscribe(
      json => {
        this.JDatoperarios = json;
        this.Joperarios = this.JDatoperarios;
      }
    )
  }

  //FECHA
  public dateChanged(value: Date): void {

    this.loadingPanel = true;

    if (this.timeout != undefined) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.cargarValidador();
    }, 500);
  }

  //CLICK EN MAQUINA
  private maquinaClicked(maquina) {


    this.isOffline = maquina.puestoOffline; 
    this.selectedMaquina =     maquina.id; 
    // this.maquinasDesplegadas = ! this.maquinasDesplegadas;
    this.tMicroParada = maquina.tiempoMicroParada;
    this.desplegarMaquinasClick(); 
    this.cargarValidador();

    // this.loadingPanel = true;

    // document.getElementsByClassName("actual")[0].classList.toggle("actual");
    // if (sender.path[0].toString().includes("object HTMLDivElement")) {
    //   sender.path[0].setAttribute("class", 'clickable actual');
    // } else {
    //   sender.path[1].setAttribute("class", 'clickable actual');
    // }
    // //var an: any = this.maquinas;
    // //an.forEach(
    // //  maquina => {
    // //    if (maquina.id = idMaquina)
    // //      this.selectedMaquina = maquina
    // //  });
    // this.selectedMaquina = idMaquina;

    // var an = this.maquinas.filter(f => f.id == this.selectedMaquina)
    // this.tMicroParada = an[0].tiempoMicroParada;


    // this.cargarValidador();
  }

  //CARGAR DT VALIDADOR
  private cargarValidador() {
    this.loadingPanel = true;
    // cargarGrid
    var hoy = !(this.myFunctions.datetimeToDate(this.fecha) < this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))
      && !(this.myFunctions.datetimeToDate(this.fecha) > this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))

    this.validadorService.get(this.selectedMaquina, this.dateToYYYYMMDDtHHmmSSz(this.fecha),
      this.translateService.instant("ejecucion").toUpperCase(), this.translateService.instant("parada").toUpperCase(), this.translateService.instant("preparacion").toUpperCase(),
      this.translateService.instant("mantenimiento").toUpperCase(), this.translateService.instant("alarma").toUpperCase(), this.translateService.instant("apagada").toUpperCase(),
      this.translateService.instant("canal").toUpperCase(),
      this.translateService.instant("nombrePrograma").toUpperCase(), this.translateService.instant("descripcion").toUpperCase(), this.translateService.instant("operario").toUpperCase(),
      this.translateService.instant("of").toUpperCase(), this.translateService.instant("cliente").toUpperCase(), this.translateService.instant("refPieza").toUpperCase(),
      this.translateService.instant("pieza").toUpperCase(), this.translateService.instant("operacion").toUpperCase(), this.translateService.instant("operacionNoEncontrada").toUpperCase(),
      this.translateService.instant("duracion").toUpperCase(), this.translateService.instant("inicio2").toUpperCase(), this.translateService.instant("fin").toUpperCase(), hoy).subscribe(
        json => {

          var dataAny: any = json;
          this.Jvalidador = dataAny;

          var an: any = [];
          var idHT_agregados = [];
          this.dataValidador_idHT_con_Subestado = [];
          dataAny.forEach(
            row => {

              row['annadirMultiperdida'] = false;
              row['annadirOperacion'] = false;
              row['actualizarPerdida'] = false;
              this.timelinePerdidas.set(row.numFila,  {justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [{}]});

              if (!idHT_agregados.includes(row.idHT)) {
                an.push(row);
                idHT_agregados.push(row.idHT)
              } else {
                this.dataValidador_idHT_con_Subestado.push(row.idHT);
              }
            });

          this.dataValidador_grid = an;

          this.dataValidador = dataAny;

          this.filtrarFiltroSeleccionado();
          this.generarGantt();
          this.updateBotones();
          this.loadingPanel = false;
        }
      )
  }


  private updateBotones() {
    var startDate = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate()),
      endDate = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());

    if (this.tMicroParada == undefined) {
      this.validadorService.getTiemposMicroParadas().subscribe(
        f => {
          var an: any = f[0];
          this.tMicroParada = an.tMicroParada;
          this.tMaximo = an.tMaximo;
          this.tMaximoTotal = an.tMaximoTotal;

          var tMaxParada = 0;
          var tFalta = 0;

          var tEjecucion = 0;
          var tParada = 0;
          var tmicroParada = 0;
          var tPreparacion = 0;
          var tMantenimiento = 0;
          var tAlarma = 0;
          var tApagada = 0;


          this.Jvalidador.forEach(f => {
            var a: Date = new Date(f.fechaini);
            var b: Date = new Date(f.fechafin);
            var dif = Math.abs((b.getTime() - a.getTime()) / 1000);
            if (f.idProcesos_Tipo == '2' && f.idPerdida <= '0' && dif > this.tMicroParada) {
              //si es una parada, sin perdida asignada y mayor a microparada
              if (f.tiemporeal > tMaxParada)
                tMaxParada = f.tiemporeal;
              tFalta = tFalta + dif;
            }
            if (f.idProcesos_Tipo == "1")
              tEjecucion = tEjecucion + dif;
            if (f.idProcesos_Tipo == "2")
              if (f.microParada)
                tmicroParada = tmicroParada + dif;
              else
                tParada = tParada + dif;
            if (f.idProcesos_Tipo == "3")
              tPreparacion = tPreparacion + dif;
            if (f.idProcesos_Tipo == "4")
              tMantenimiento = tMantenimiento + dif;
            if (f.idProcesos_Tipo == "6")
              tAlarma = tAlarma + dif;
            if (f.idProcesos_Tipo == "8")
              tApagada = tApagada + dif;
          });
          if (tMaxParada > this.tMaximo)
            this.validable = false;
          else if (tFalta > this.tMaximoTotal)
            this.validable = false;
          else
            this.validable = true;

          this.Tfaltas = this.secondsToHms(tFalta);
          this.Tejecucion = this.secondsToHms(tEjecucion);
          this.Tparada = this.secondsToHms(tParada);
          this.TmicroparadaHHMMSS = this.secondsToHms(tmicroParada);
          this.Tpreparacion = this.secondsToHms(tPreparacion);
          this.Tmantenimiento = this.secondsToHms(tMantenimiento);
          this.Talarma = this.secondsToHms(tAlarma);
          this.Tapagada = this.secondsToHms(tApagada);
          //if (startDate >= endDate)
          //  this.validable = false
        });
    } else {
      var tMaxParada = 0;
      var tFalta = 0;

      var tEjecucion = 0;
      var tParada = 0;
      var tMicroParada = 0;
      var tPreparacion = 0;
      var tMantenimiento = 0;
      var tAlarma = 0;
      var tApagada = 0;

      this.Jvalidador.forEach(f => {
        var a: Date = new Date(f.fechaini);
        var b: Date = new Date(f.fechafin);
        var dif = Math.abs((b.getTime() - a.getTime()) / 1000);;
        if (f.idProcesos_Tipo == '2' && f.idPerdida <= '0' && dif > this.tMicroParada) {
          //si es una parada, sin perdida asignada y mayor a microparada
          if (f.tiemporeal > tMaxParada)
            tMaxParada = f.tiemporeal;
          tFalta = tFalta + dif;
        }
        if (f.idProcesos_Tipo == "1")
          tEjecucion = tEjecucion + dif;
        if (f.idProcesos_Tipo == "2")
          if (f.microParada)
            tMicroParada = tMicroParada + dif;
          else
            tParada = tParada + dif;
        if (f.idProcesos_Tipo == "3")
          tPreparacion = tPreparacion + dif;
        if (f.idProcesos_Tipo == "4")
          tMantenimiento = tMantenimiento + dif;
        if (f.idProcesos_Tipo == "6")
          tAlarma = tAlarma + dif;
        if (f.idProcesos_Tipo == "8")
          tApagada = tApagada + dif;
      });

      if (tMaxParada > this.tMaximo)
        this.validable = false;
      else if (tFalta > this.tMaximoTotal)
        this.validable = false;
      else
        this.validable = true;

      this.Tfaltas = this.secondsToHms(tFalta);
      this.Tejecucion = this.secondsToHms(tEjecucion);
      this.Tparada = this.secondsToHms(tParada);
      this.TmicroparadaHHMMSS = this.secondsToHms(tMicroParada);
      this.Tpreparacion = this.secondsToHms(tPreparacion);
      this.Tmantenimiento = this.secondsToHms(tMantenimiento);
      this.Talarma = this.secondsToHms(tAlarma);
      this.Tapagada = this.secondsToHms(tApagada);
      //if (startDate >= endDate)
      //  this.validable = false
    }
  }

  //APPOINTMENT CLICK
  appointmentClick(id) {

    // COMO AHORA LAS LINEAS SE DUPLICAN, EN CASO DE TENER SUBESTADO LA OTRA COGEREMOS SIEMPRE LA QUE TIENE SUBESTADO.
    var clickedRow = this.Jvalidador.filter(f => id === f.numFila);
    console.log(this.dataValidador_grid)
    this.dataValidadorSelecteds = this.dataValidador_grid.filter(f => f.idHT == clickedRow[0].idHT && f.idProcesos_Tipo_subEstados >= 100);
    console.log(this.dataValidadorSelecteds)
    if (this.dataValidadorSelecteds.length == 0){
      this.dataValidadorSelecteds = this.dataValidador_grid.filter(f => f.idHT == clickedRow[0].idHT);
    }
    console.log(this.dataValidadorSelecteds)

    this.visibleTipo = true;
    this.visiblePerdida = true;
    this.visibleProyecto = true;
    this.visibleOperario = true;
    this.visibleObservaciones = true;

    this.cargarValores()

    if (Object.keys(this.dataValidadorSelecteds).length > 0) {
      this.modalReference = this.modalService.open(this.popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  //BOTONES EDITAR
  clickEditar(popupEditar) {
    this.dataValidadorSelecteds = this.Jvalidador.filter(f => this.mySelection.includes(f.numFila));
    this.visibleTipo = true;
    this.visiblePerdida = true;
    this.visibleProyecto = true;
    this.visibleOperario = true;
    this.visibleObservaciones = true;

    this.cargarValores()

    if (Object.keys(this.dataValidadorSelecteds).length > 0) {
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  clickEditarOF(popupEditar) {
    this.dataValidadorSelecteds = this.Jvalidador.filter(f => this.mySelection.includes(f.numFila));
    this.visibleTipo = false;
    this.visiblePerdida = false;
    this.visibleProyecto = true;
    this.visibleOperario = false;
    this.visibleObservaciones = false;

    this.cargarValores()

    if (Object.keys(this.dataValidadorSelecteds).length > 0) {
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  clickeditarUsuario(popupEditar) {
    this.dataValidadorSelecteds = this.Jvalidador.filter(f => this.mySelection.includes(f.numFila));
    this.visibleTipo = false;
    this.visiblePerdida = false;
    this.visibleProyecto = false;
    this.visibleOperario = true;
    this.visibleObservaciones = false;

    this.cargarValores()

    if (Object.keys(this.dataValidadorSelecteds).length > 0) {
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }
  clickEditarTipo(popupEditar) {
    this.dataValidadorSelecteds = this.Jvalidador.filter(f => this.mySelection.includes(f.numFila));
    this.visibleTipo = true;
    this.visiblePerdida = true;
    this.visibleProyecto = false;
    this.visibleOperario = false;
    this.visibleObservaciones = false;

    this.cargarValores()

    if (Object.keys(this.dataValidadorSelecteds).length > 0) {
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  clickEditarPerdida(popupEditar) {
    this.dataValidadorSelecteds = this.Jvalidador.filter(f => this.mySelection.includes(f.numFila));

    this.visibleProyecto = false;
    this.visibleOperario = false;
    this.visibleObservaciones = true;
    this.visibleTipo = false;
    this.visiblePerdida = true;


    this.cargarValores(); //honek visiblePerdida aldatzen du, ejekuzioan baldin badago

    if (Object.keys(this.dataValidadorSelecteds).length > 0) {
      this.modalReference = this.modalService.open(popupEditar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  clickAnnadirOperacion(popup) {
    this.dataValidadorSelecteds = this.dataValidador.filter(f => this.mySelection.includes(f.numFila));
    this.cargarValoresAnnadirOperacion();

    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  // Cargar los valores para el popup de annadir operacion
  cargarValoresAnnadirOperacion() {
    this.limpiarValores();
    this.fechaIni = new Date(this.dataValidadorSelecteds[0].fechaini);
    this.fechaFin = new Date(this.dataValidadorSelecteds[0].fechafin);
    this.periodoValido();

    this.JtiposAnnadirOperacion = this.Jtipos.filter(f => (f.value == '1' || f.value == '3'));
    this.JtiposSelected = this.Jtipos.filter(f => (f.value == '1'))[0];
    this.JoperariosSelected = this.Joperarios[0];
  }

  limpiarValores() {
    this.esSoloParada = false;
    this.verNSerie = false;
    this.verLotes = false;
    this.verColada = false;
    this.verCantidades = false;
    this.cantidadTotal = 0;
    this.JnColadaSelected = undefined;
    this.PPcantidadTerminada = 0;
    this.PPcantidadAchatarrada = 0;
    this.PPcantidadApartada = 0;
    this.cantidadTerminadaMax = 9999;
    this.cantidadAchatarradaMax = 9999;
    this.cantidadApartadaMax = 9999;
    this.verAceptar = false;
    this.isTerminado = false;
    this.JproyectoSelected = undefined;
    this.JpiezasSelected = undefined;
    this.JpartesSelected = undefined;
    this.JprogramasSelected = undefined;
  }
  //Cargar los valores del POPUP
  cargarValores() {
    this.fechaIni = new Date(this.dataValidadorSelecteds[0].fechaini);
    this.fechaFin = new Date(this.dataValidadorSelecteds[0].fechafin);

    this.requireProyecto = false;
    this.requirePieza = false;
    this.requireParte = false;
    this.requireOperacion = false;

    this.JtiposSelected = this.JDattipos.filter(f => f.value == this.dataValidadorSelecteds[0].idProcesos_Tipo)[0];
    this.jSubEstados_personalizadosSelected = this.jSubEstados_personalizados.filter(f => f.idProcesos_Tipo_subEstados == this.dataValidadorSelecteds[0].idProcesos_Tipo_subEstados)[0];

    // if (this.JtiposSelected.value == '2')
    //   this.visiblePerdida = true;
    // else
    //   this.visiblePerdida = false;

    this.JperdidasSelected = this.JDatperdidas.filter(f => f.value == this.dataValidadorSelecteds[0].idPerdida)[0];
    //proyecto
    this.JproyectoSelected = this.JDatproyectos.filter(f => f.value == this.dataValidadorSelecteds[0].idCamprint_proyecto)[0];
    if (this.dataValidadorSelecteds[0].idCamprint_proyecto > 0) {
      //pieza
      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == this.dataValidadorSelecteds[0].idCamprint_proyecto);
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == this.dataValidadorSelecteds[0].idCamprint_pieza)[0];
      if (this.dataValidadorSelecteds[0].idCamprint_pieza > 0) {
        //parte
        this.Jpartes = this.JDatpartes.filter(f => f.idPieza == this.dataValidadorSelecteds[0].idCamprint_pieza);
        this.JpartesSelected = this.Jpartes.filter(f => f.value == this.dataValidadorSelecteds[0].idCamprint_parte)[0];
        if (this.dataValidadorSelecteds[0].idCamprint_parte > 0) {
          //programa
          this.Jprogramas = this.JDatprogramas.filter(f => f.idParte == this.dataValidadorSelecteds[0].idCamprint_parte);
          this.JprogramasSelected = this.Jprogramas.filter(f => f.value == this.dataValidadorSelecteds[0].idCamprint_programa)[0];
          //nSerie (si hay parte se cargan los Nserie de la parte)
          this.JnSerie = this.JDatnSerie.filter(f => f.idParte == this.dataValidadorSelecteds[0].idCamprint_parte);
          this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
        } else {
          this.Jprogramas = this.JDatprogramas;
          this.JprogramasSelected = undefined;
          //nSerie (si no hay parte se cargan los Nserie de la pieza)
          this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.dataValidadorSelecteds[0].idCamprint_pieza);
          this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
        }
      } else {
        this.Jpartes = this.JDatpartes;
        this.JpartesSelected = undefined;
        this.Jprogramas = this.JDatprogramas;
        this.JprogramasSelected = undefined;
        //nSerie (si no hay pieza se cargan los Nserie del proyecto)
        this.JnSerie = this.JDatnSerie.filter(f => f.idOF == this.dataValidadorSelecteds[0].idCamprint_proyecto);
        this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
      }
    } else {
      this.Jpiezas = this.JDatpiezas;
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes;
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas;
      this.JprogramasSelected = undefined;
      //nSerie (si no hay proyecto se carga todo)
      this.JnSerie = this.JDatnSerie;
      this.nSerieTextInput = false;
      this.JnSerieSelected = undefined;
      this.nuevoNserie = "";
    }

    this.cargarCantidadLotes(this.dataValidadorSelecteds[0].usaLotes,
      this.dataValidadorSelecteds[0].cantidadParte,
      this.dataValidadorSelecteds[0].cantidadLotesHistoricoOperacion);

    this.JoperariosSelected = this.JDatoperarios.filter(f => f.valor == this.dataValidadorSelecteds[0].idOperario)[0];

    this.Jdescripcion = this.dataValidadorSelecteds[0].descripcion;
    this.Jobservacion = this.dataValidadorSelecteds[0].observacion;

    this.operacionesService.GetByID(this.dataValidadorSelecteds[0].of, this.dataValidadorSelecteds[0].pieza, this.dataValidadorSelecteds[0].parte, 1, this.dataValidadorSelecteds[0].idOperacion).subscribe(
      json => {

        if (json.operacion.length > 0) {
          this.cantidadTotal = json.operacion[0].cantidad;
          this.verColada = json.operacion[0].solicitar_Colada;
          this.verLotes = json.operacion[0].solicitar_Lote;
          this.verNSerie = json.operacion[0].solicitar_N_Serie;
        }

      });

  }
  //CARGAR N SERIE
  cargarNserie(idHPi, nuevoNserie) {
    this.nSerieTextInput = true;
    this.nuevoNserie = nuevoNserie;
    var an: any = this.JnSerie;
    an.forEach(
      row => {
        if (row.idPans > 0)
          this.nSerieTextInput = false;
      });
    this.JnSerieSelected = this.JnSerie.filter(f => f.idHP == idHPi)[0];
  }
  // CARGAR CANTIDAD LOTES
  cargarCantidadLotes(usaLotes, cantidadParte, cantidadLotesHistoricoOperacion) {
    if (usaLotes > 0) {
      //USA LOTES
      this.cantidadLote = cantidadLotesHistoricoOperacion;
      this.cantidadLoteMax = cantidadParte;
      this.usaLotes = true;
    } else {
      //NO USA LOTES
      this.cantidadLote = 1;
      this.cantidadLoteMax = 1;
      this.usaLotes = false;
    }
  }

  //BOTONES FILTRO!
  clickTodo() {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos las variables a false, ninguna esta filtrada
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    this.dataValidador_grid = this.Jvalidador;
    this.generarGantt();
  }
  clickSinOF(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = true;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infSinOF();
    }

    this.generarGantt();
  }
  clickSinOperario(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = true;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infSinOperario();
    }

    this.generarGantt();
  }
  clickSinPerdida(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = true;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;
    var variable_tMicroParada = this.tMicroParada

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infSinPerdida(variable_tMicroParada);
    }

    this.generarGantt();
  }
  clickEjecuciones(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = true;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infEjecuciones();
    }

    this.generarGantt();
  }
  clickParadas(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = true;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infParadas();
    }

    this.generarGantt();
  }
  clickMicroParadas(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = true;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infMicroparadas();
    }

    this.generarGantt();
  }
  clickPreparaciones(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = true;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infPreparaciones();
    }

    this.generarGantt();
  }
  cickMantenimientos(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = true;
    this.soloAlarmas = false;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infMantenimientos();
    }

    this.generarGantt();
  }
  clickAlarmas(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = true;
    this.soloApagadas = false;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infAlarmas();
    }

    this.generarGantt();
  }
  clickApagadas(grid) {
    //DESELECCIONAR TODO Y UNCHECKEAR EL CHECKBOX DE SELECTALL
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.sinOf = false;
    this.sinOperario = false;
    this.sinPerdida = false;
    this.soloEjecuciones = false;
    this.soloParadas = false;
    this.soloMicroParadas = false;
    this.soloPreparaciones = false;
    this.soloMantenimientos = false;
    this.soloAlarmas = false;
    this.soloApagadas = true;

    // para que al actualizar la informacion aparezca en el grid aunque se haya hecho scroll
    this.grid = grid;
    grid.scrollTo({ row: 0, column: 0 });
    this.mySelection = [];
    this.selectAllState = 'unchecked';
    if (this.state?.skip == 0 || this.state == undefined) {
      this.infApagadas();
    }

    this.generarGantt();
  }

  infSinOF() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.refOF === '';
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.refOF === '';
    });
  }
  infSinOperario() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idOperario <= 0;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idOperario <= 0;
    });
  }
  infSinPerdida(variable_tMicroParada) {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idPerdida === -1 && (((i.idProcesos_Tipo === 2 || i.idProcesos_Tipo === 6) && i.tiemporeal > variable_tMicroParada) || i.idProcesos_Tipo === 8);
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idPerdida === -1 && (((i.idProcesos_Tipo === 2 || i.idProcesos_Tipo === 6) && i.tiemporeal > variable_tMicroParada) || i.idProcesos_Tipo === 8);
    });
  }
  infEjecuciones() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 1;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 1;
    });
  }
  infParadas() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 2 && i.microParada == 0;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 2 && i.microParada == 0;
    });
  }
  infMicroparadas() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 2 && i.microParada == 1;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 2 && i.microParada == 1;
    });
  }
  infPreparaciones() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 3;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 3;
    });
  }
  infMantenimientos() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 4;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 4;
    });
  }
  infAlarmas() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 6;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 6;
    });
  }
  infApagadas() {
    this.dataValidador_grid = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 8;
    });
    this.dataValidador = this.Jvalidador.filter(function (i, n) {
      return i.idProcesos_Tipo === 8;
    });
  }

  //POPUP : FILTRO
  tipoChanged(value: any) {
    if (value != undefined)
      if (value.value == '2')
        this.visiblePerdida = true;
      else
        this.visiblePerdida = false;
  }
  ProyectoValueChanged(value: any) {
    if (value != undefined) {
      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == value.value);
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == value.value);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    } else {
      this.Jpiezas = this.JDatpiezas
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas
      this.JprogramasSelected = undefined;
      //nSerie (si no hay proyecto se carga todo)
      this.JnSerie = this.JDatnSerie;
      this.JnSerieSelected = undefined;
    }
  }
  PiezaValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.Jpartes = this.JDatpartes.filter(f => f.idPieza == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == value.value);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    } else {
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == this.JproyectoSelected);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    }
  }
  ParteValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.Jprogramas = this.JDatprogramas.filter(f => f.idParte == value.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = this.Jpartes.filter(f => f.value == value.id)[0].cantidadParte;
      //nSerie (si hay parte se cargan los Nserie de la parte)
      this.JnSerie = this.JDatnSerie.filter(f => f.idParte == value.id);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    } else {
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == this.JpiezasSelected.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = 1;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.JpiezasSelected);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    }
  }
  ProgramaValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.JpartesSelected = this.Jpartes.filter(f => f.value == value.idParte)[0];
    }

    this.operacionesService.GetByID(value.idOF, value.idPieza, value.idParte, 1, value.value).subscribe(
      json => {

        if (json.operacion.length > 0) {
          this.cantidadTotal = json.operacion[0].cantidad;
          this.verColada = json.operacion[0].solicitar_Colada;
          this.verLotes = json.operacion[0].solicitar_Lote;
          this.verNSerie = json.operacion[0].solicitar_N_Serie;
        }

      });
  }

  //ACTUALIZAR JValidador
  actualizarJValidador() {
    //actualizar el JSON de los datos del validador
    //si programa esta visible, tiene que estar seleccionado!
    var salir = false;
    if (!salir) {
      var an: any = [];
      //CUANDO SE PASO DE HISTORICO PROCESOS A HISTORICO TURNOS SE TUBO QUE AÑADIR ESTA LINEA
      var anLAG: any = this.dataValidadorSelecteds;
      var idHT: number = 0;
      anLAG.forEach(
        row => {
          var anLAG2: any = this.dataValidador;
          anLAG2.forEach(
            row2 => {
              if (row2.iDhistorico == row.iDhistorico)
                an.push(row2)
              idHT = row.idHT;
            });
        }
      );

      var casoSubEstado = 0
      // Desde que hay subestados, hay que actualizar las 2 lines que le hacen referencia, la linea con subestado menor a 100, tendra el color original de eris, mientras que el mayor a 100 tendra su color propiamente definido.
      // QUE PUEDE PASAR? 
      // 1- SI tenia subestado y SI tiene subestado ahora tambien: Editar ambas lineas, una con el estado como subestado para mantener en el GANTT y la original.
      // 2- SI tenia subestado y NO tiene subestado              : Borrar la linea que tenga el subestado y dejar solo la orginal.
      // 3- NO tenia subestado y SI tiene subestado ahora tampoco: Crear nueva linea con el subestado!
      // 4- NO tenia subestado y NO tiene subestado ahora        : Editar la unica linea que hay con ese idHT.
      if (this.dataValidador_idHT_con_Subestado.includes(idHT))
        if (this.jSubEstados_personalizadosSelected != undefined)
          casoSubEstado = 1
        else
          casoSubEstado = 2
      else
        if (this.jSubEstados_personalizadosSelected != undefined)
          casoSubEstado = 3
        else
          casoSubEstado = 4

      an.forEach(f => {
        if (this.visibleTipo) {
          var rowEstado = f.idProcesos_Tipo_subEstados < 100; // Era la row de estado o subestado?

          f.idProcesos_Tipo = Number(this.JtiposSelected.value);
          if ((casoSubEstado == 2 || casoSubEstado == 4) || rowEstado)
            f.idProcesos_Tipo_subEstados = f.idProcesos_Tipo;
          else
            f.idProcesos_Tipo_subEstados = Number(this.jSubEstados_personalizadosSelected.idProcesos_Tipo_subEstados);

          // Dependiendo de si es subestado o no se le asignara una maquina y un color.
          switch (casoSubEstado) {
            case 1: // Editar ambas lineas, una con el estado como subestado para mantener en el GANTT y la original.
              if (rowEstado) {
                // ESTADO
                var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo == f2.idProcesos_Tipo_subEstados && f2.idProcesos_Tipo == f.idProcesos_Tipo)[0];
                f.backgroundColor = subEstado.color;
                f.procesos_Tipo = subEstado.nombre;
                f.machine = subEstado.machine;
              } else {
                // SUBestado
                var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo == f2.idProcesos_Tipo_subEstados && f2.idProcesos_Tipo == f.idProcesos_Tipo)[0];
                f.procesos_Tipo = subEstado.nombre;
                var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo_subEstados == f.idProcesos_Tipo_subEstados)[0];
                f.backgroundColor = subEstado.color;
                f.machine = subEstado.machine;
              }
              break;
            case 2: // Borrar la linea que tenga el subestado y dejar solo la orginal.
              // ESTADO
              var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo == f2.idProcesos_Tipo_subEstados && f2.idProcesos_Tipo == f.idProcesos_Tipo)[0];
              f.backgroundColor = subEstado.color;
              f.procesos_Tipo = subEstado.nombre;
              f.machine = subEstado.machine;

              var new_dataValidador_idHT_con_Subestado = [];
              this.dataValidador_idHT_con_Subestado.forEach(
                idHT => {
                  if (f.idHT != idHT)
                    new_dataValidador_idHT_con_Subestado.push(idHT)
                });
              this.dataValidador_idHT_con_Subestado = new_dataValidador_idHT_con_Subestado;
              break;
            case 3: // Crear nueva linea con el subestado!
              // SUBestado
              var rowClone = this.myFunctions.copy(f);

              var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo == f2.idProcesos_Tipo_subEstados && f2.idProcesos_Tipo == f.idProcesos_Tipo)[0];
              rowClone.backgroundColor = subEstado.color;
              rowClone.procesos_Tipo = subEstado.nombre;
              rowClone.machine = subEstado.machine;

              // ESTADO
              f.procesos_Tipo = subEstado.nombre; // el procesos tipo da color a la columna principal del grid
              f.idProcesos_Tipo_subEstados = Number(this.jSubEstados_personalizadosSelected.idProcesos_Tipo_subEstados); //convertimos la linea original de estado en la nueva linea de subestado para editarla en el grid.
              var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo_subEstados == f.idProcesos_Tipo_subEstados)[0];  
              f.backgroundColor = subEstado.color;
              f.machine = subEstado.machine;
  
              this.dataValidador.push(rowClone);
              this.dataValidador_idHT_con_Subestado.push(rowClone.idHT);

              break;
            case 4: // Editar la unica linea que hay con ese idHT.
              // ESTADO
              var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo == f2.idProcesos_Tipo_subEstados && f2.idProcesos_Tipo == f.idProcesos_Tipo)[0];
              f.backgroundColor = subEstado.color;
              f.procesos_Tipo = subEstado.nombre;
              f.machine = subEstado.machine;
              break;
          }

          // SI ES UNA MICROPARADA, SE LE PONE BIEN EL COLORY ORDEN DE SU ESTADO 
          if (f.idProcesos_Tipo == 2 && this.tMicroParada > f.tiemporeal){
            var subEstado = this.jSubEstados.filter(f2 => f2.idProcesos_Tipo_subEstados == 12)[0];

            f.procesos_Tipo = subEstado.nombre; // color triangulo grid

            if (f.idProcesos_Tipo_subEstados == 2){ // color y orden gantt para el estado original
              f.backgroundColor = subEstado.color;
              f.machine = subEstado.machine;
            }
          }
        }
        if (this.visiblePerdida) {
          if (this.JperdidasSelected != undefined) {
            f.idPerdida = this.JperdidasSelected.value;
            f.perdida = this.JperdidasSelected.text;
            f.observacion = this.Jobservacion;
          }
          else {
            f.idPerdida = -1;
            f.perdida = '';
            f.observacion = '';
          }
        }

        if (this.visibleProyecto) {
          if (this.JprogramasSelected == undefined) {
            f.idCamprint_proyecto = -1;
            f.refOF = '';
            f.cliente = '';

            f.idCamprint_pieza = -1;
            f.pieza = '';

            f.idCamprint_parte = -1;
            f.parte = '';
            f.cantidadParte = 1;

            f.idCamprint_programa = -1;
            f.programa = '';
          } else {
            f.idCamprint_proyecto = this.JproyectoSelected.value;
            f.refOF = this.JproyectoSelected.text;
            f.cliente = this.JproyectoSelected.cliente;

            f.idCamprint_pieza = this.JpiezasSelected.value;
            f.pieza = this.JpiezasSelected.text;

            f.idCamprint_parte = this.JpartesSelected.value;
            f.parte = this.JpartesSelected.text;

            // Comprobar que la operacion se haya cambiado
            if (f.idCamprint_programa != this.JprogramasSelected.value) f.idHO = -1;
            f.idCamprint_programa = this.JprogramasSelected.value;
            f.programa = this.JprogramasSelected.text;
          }
          //se actualiza la cantidad lote hecha en esa operacion.
          f.cantidadLotesHistoricoOperacion = this.cantidadLote;
          //si se ha cambiado el Nserie y ahora se ha añadido a mano, se actualiza.
          f.nuevoNserie = this.nuevoNserie;

          //f.idHO se actualiza arriba al actualizar la Operacion! una vez se cambia ya no se mira si existe si se pone de nuevo la anterior 
          if (this.JnSerieSelected == undefined) {
            //si se ha seleccionado otra pieza ya existente se actualiza.
            f.idHPi = -1; // el 0 se usa para cargas vacio desde DB y este es para generar la pieza nueva si hace falta
            //si la nueva pieza seleccionada se añade 
            f.idNserieParte = 0;
            f.nuevoNserie = this.nuevoNserie;
            f.numeroSerie = f.nuevoNserie; //para actualizar el grid
          } else {
            //si se ha seleccionado otra pieza ya existente se actualiza.
            f.idHPi = this.JnSerieSelected.idHP;
            //si la nueva pieza seleccionada se añade 
            f.idNserieParte = this.JnSerieSelected.idPans;
            if (this.JnSerieSelected.idHP == 0)
              f.nuevoNserie = this.JnSerieSelected.nSerie;
            f.numeroSerie = this.JnSerieSelected.nSerie; //para actualizar el grid
          }
        }

        if (this.visibleOperario) {
          if (this.JoperariosSelected != undefined) {
            f.idOperario = this.JoperariosSelected.valor;
            f.operario = this.JoperariosSelected.text;
            const fullName = f['operario'].split(' ');
            if (fullName.length==1) f.iniciales='';
            else f.iniciales = (fullName.shift().charAt(0) + fullName.pop().charAt(0)).toUpperCase();
          } else {
            f.idOperario = -1;
            f.operario = 'undefined';
            f.iniciales='';
          }
        }

        if (this.visibleObservaciones) {
          f.descripcion = this.Jdescripcion;
        }
      })

      this.updateBotones();
      //cerrar popup
      this.modalReference.close();
      this.generarGantt();
    }

    //Ahora miramos cual estaba filtrada para filtrarlas de nuevo
    //Ponemos todas las variables a false menos la actual, ya que se filtra solo el actual
    this.filtrarFiltroSeleccionado();
  }

  filtrarFiltroSeleccionado() {
    if (this.sinOf) {
      this.clickSinOF(this.grid);
    }
    if (this.sinOperario) {
      this.clickSinOperario(this.grid);
    }
    if (this.sinPerdida) {
      this.clickSinPerdida(this.grid);
    }
    if (this.soloEjecuciones) {
      this.clickEjecuciones(this.grid);
    }
    if (this.soloParadas) {
      this.clickParadas(this.grid);
    }
    if (this.soloMicroParadas) {
      this.clickMicroParadas(this.grid);
    }
    if (this.soloPreparaciones) {
      this.clickPreparaciones(this.grid);
    }
    if (this.soloMantenimientos) {
      this.cickMantenimientos(this.grid);
    }
    if (this.soloAlarmas) {
      this.clickAlarmas(this.grid);
    }
    if (this.soloApagadas) {
      this.clickApagadas(this.grid);
    }
  }

  //BOTONES GUARDAR
  guardarValidar() {
    this.guardar(true);
  }
  guardarActualizar() {
    this.guardar(false);
  }

  guardar(validado) {
    this.loadingPanel = true;
    //this.validadorService.update(this.dataValidador, this.selectedMaquina, validado, this.dateToYYYYMMDDtHHmmSSz(this.fecha)).subscribe(

    // var an : any= this.Jvalidador;
    // var validadorSinDuplicadas : any= [];

    // an.forEach(
    //   row => {
    //     if (this.dataValidador_idHT_con_Subestado.includes(row.HT)){
    //       if(row.idProcesos_Tipo_subEstados >= 100){
    //         // si el historico turnos tiene subestado, cogeremos solo la linea con subestado.
    //         validadorSinDuplicadas.push(row);
    //       }            
    //     }
    //     else{
    //       // si el historico turnos NO tiene subestado, cogemos la unica linea que tiene.
    //       validadorSinDuplicadas.push(row);
    //     }
      
    // });

    var dataValidadorAux;
    if (validado) dataValidadorAux = this.Jvalidador.filter(f => this.mySelection.includes(f.id))
    else dataValidadorAux = this.myFunctions.copy(this.Jvalidador);

    this.timelinePerdidas = new Map([...this.timelinePerdidas.entries()].sort((a, b) => a[0] - b[0]))
    var arrayPerdidas = [...this.timelinePerdidas.values()]

    this.validadorService.update(dataValidadorAux, this.selectedMaquina, validado, this.dateToYYYYMMDDtHHmmSSz(this.fecha), arrayPerdidas).subscribe(
      r => {
        this.loadingPanel = false;
        if (validado) {
          var an: any = this.dataValidador
          an.forEach(
            row => {
              row.validado = true;
            });
        }

        this.cargarCombos();
        this.cargarValidador();
      }
    );

  }

  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  };
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  // ANNADIR OPERACION

  periodoValido() {
    this.esSoloParada = false;

    if (this.isOffline) {
      this.dataValidador.forEach(element => {
        var validoFechaIni = this.myFunctions.sqlToJsDateT(element.fechaini) <= this.fechaIni && this.fechaIni < this.fechaFin;
        var validoFechaFin = this.myFunctions.sqlToJsDateT(element.fechafin) >= this.fechaFin;
        var validoIdOperacion = element.idOperacion < 0;
        var validoIdHistoricoOperacion = element.idHistorico_operaciones <= 0;
        var validoIdProcesos = element.idProcesos_Tipo == 2;
        var validoIdPerdida = element.idPerdida < 0;

        if (validoFechaIni && validoFechaFin && validoIdOperacion && validoIdHistoricoOperacion && validoIdProcesos && validoIdPerdida && this.isOffline)
          this.esSoloParada = true;
      })
    } else if (!this.isOffline) {

      var auxData = this.dataValidador.filter(f => {
        var valido = this.fechaIni < this.fechaFin;
        var validoFechaIni = this.myFunctions.sqlToJsDateT(f.fechaini) <= this.fechaIni && this.fechaIni <= this.myFunctions.sqlToJsDateT(f.fechafin);
        var validoEntreFechas = this.myFunctions.sqlToJsDateT(f.fechaini) >= this.fechaIni && this.fechaFin >= this.myFunctions.sqlToJsDateT(f.fechafin);
        var validoFechaFin = this.myFunctions.sqlToJsDateT(f.fechaini) >= this.fechaIni && this.fechaFin <= this.myFunctions.sqlToJsDateT(f.fechafin);
        var validoFinal = this.myFunctions.sqlToJsDateT(f.fechaini) < this.fechaFin;
        var validoInicio = this.fechaIni < this.myFunctions.sqlToJsDateT(f.fechafin);

        if (validoFinal && validoInicio && (valido || validoFechaIni || validoEntreFechas || validoFechaFin)) {
          return true;
        }
      })
      auxData.forEach(element => {
        var validoIdOperacion = element.idOperacion <= 0;
        var validoIdHistoricoOperacion = element.idHistorico_operaciones <= 0;

        if (validoIdOperacion && validoIdHistoricoOperacion) {
          this.esSoloParada = true;
        }
      })
    }
  }

  // si el valor de los combos cambian
  ofValueChanged(value: any) {
    if (value != undefined) {
      this.Jpiezas = this.JDatpiezas.filter(f => f.idOF == value.value);
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == value.value);
      this.JnSerieSelected = undefined;
      // this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    } else {
      this.Jpiezas = this.JDatpiezas
      this.JpiezasSelected = undefined;
      this.Jpartes = this.JDatpartes
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas
      this.JprogramasSelected = undefined;
      //nSerie (si no hay proyecto se carga todo)
      this.JnSerie = this.JDatnSerie;
      this.JnSerieSelected = undefined;
    }
  }
  PiezaValueChangedAnnadirOp(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.Jpartes = this.JDatpartes.filter(f => f.idPieza == value.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == value.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == value.value);
      this.JnSerieSelected = undefined;
    } else {
      this.Jpartes = this.JDatpartes.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JpartesSelected = undefined;
      this.Jprogramas = this.JDatprogramas.filter(f => f.idOF == this.JproyectoSelected.value);
      this.JprogramasSelected = undefined;
      //nSerie (si no hay pieza se cargan los Nserie del proyecto)
      this.JnSerie = this.JDatnSerie.filter(f => f.idOF == this.JproyectoSelected);
      this.JnSerieSelected = undefined;
    }
  }
  ParteValueChangedAnnadirOp(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.Jprogramas = this.JDatprogramas.filter(f => f.idParte == value.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = this.Jpartes.filter(f => f.value == value.id)[0].cantidadParte;
      //nSerie (si hay parte se cargan los Nserie de la parte)
      this.JnSerie = this.JDatnSerie.filter(f => f.idParte == value.id);
      this.JnSerieSelected = undefined;
    } else {
      this.Jprogramas = this.JDatprogramas.filter(f => f.idPieza == this.JpiezasSelected.value);
      this.JprogramasSelected = undefined;
      this.cantidadLoteMax = 1;
      //nSerie (si no hay parte se cargan los Nserie de la pieza)
      this.JnSerie = this.JDatnSerie.filter(f => f.idPieza == this.JpiezasSelected);
      this.JnSerieSelected = undefined;
      this.cargarNserie(this.dataValidadorSelecteds[0].idHPi, this.dataValidadorSelecteds[0].nuevoNserie);
    }
  }
  operacionValueChanged(value: any) {
    if (value != undefined) {
      this.JproyectoSelected = this.Jproyectos.filter(f => f.value == value.idOF)[0];
      this.JpiezasSelected = this.Jpiezas.filter(f => f.value == value.idPieza)[0];
      this.JpartesSelected = this.Jpartes.filter(f => f.value == value.idParte)[0];
    }

    this.verCantidades = true;
    this.verAceptar = true;

    this.operacionesService.GetByID(value.idOF, value.idPieza, value.idParte, 1, value.value).subscribe(
      json => {

        if (json.operacion.length > 0) {
          this.cantidadTotal = json.operacion[0].cantidad;
          this.verColada = json.operacion[0].solicitar_Colada;
          this.verLotes = json.operacion[0].solicitar_Lote;
          this.verNSerie = json.operacion[0].solicitar_N_Serie;
        }

        if (this.verNSerie) {
          this.cantidadTerminadaMax = 1;
          this.cantidadAchatarradaMax = 1;
          this.cantidadApartadaMax = 1;
          this.JnSerie = this.JDatnSerie.filter(f => f.idOF == value.idOF);
          this.JnSerieSelected = undefined;
        } else {
          this.cantidadTerminadaMax = this.cantidadTotal;
          this.cantidadAchatarradaMax = this.cantidadTotal;
          this.cantidadApartadaMax = this.cantidadTotal;
        }
      });
  }
  onChangeCantidad() {
    if (this.verNSerie) {
      if (this.PPcantidadTerminada == 1) {
        this.cantidadAchatarradaMax = 0;
        this.cantidadApartadaMax = 0;
      } else if (this.PPcantidadAchatarrada == 1) {
        this.cantidadTerminadaMax = 0;
        this.cantidadApartadaMax = 0;
      } else if (this.PPcantidadApartada == 1) {
        this.cantidadTerminadaMax = 0;
        this.cantidadAchatarradaMax = 0;
      } else {
        this.cantidadTerminadaMax = 1;
        this.cantidadAchatarradaMax = 1;
        this.cantidadApartadaMax = 1;
      }
    }
  }

  annadirOperacion() {

    // var fechaInicio = new Date(this.fechaIni.setHours(this.fechaIni.getHours() + 1));
    // var fechaFin = new Date(this.fechaFin.setHours(this.fechaFin.getHours() + 1));
    var piezasTerminadas = this.PPcantidadTerminada; //
    var piezasAchatarradas = this.PPcantidadAchatarrada; //
    var piezasApartadas = this.PPcantidadApartada; //
    var idOperacion = this.JprogramasSelected.value;
    var idPlanificado = -1;
    var idMaquina = this.selectedMaquina;
    var numoperario = this.JoperariosSelected.valor;
    // var idHistoricoProcesos; 
    // var idProcesoTipo = this.JtiposSelected.value;
    var idMantenimiento = -1;
    var idPlanificadoLargo = -1;

    var colada;
    var nserie;
    var lote;

    var terminado;

    if (this.verColada)
      colada = this.JnColadaSelected?.colada;
    else
      colada = '';

    if (this.verNSerie)
      nserie = this.JnSerieSelected?.rn;
    else
      nserie = '';

    if (this.verLotes)
      lote = this.cantidadLote;
    else
      lote = '';

      this.dataValidador.every(element => {
        if (element.numFila == this.dataValidadorSelecteds[0].numFila) {
  
          //#region asignar los valores
          var row = this.myFunctions.copy(element);
          row.annadirOperacion = true;
          row.numFila = row.numFila + 0.1;
          row.piezasTerminadas = piezasTerminadas;
          row.piezasAchatarradas = piezasAchatarradas;
          row.piezasApartadas = piezasApartadas;
          row.idOperacion = idOperacion;
          row.idCamprint_programa = idOperacion;
          row.idHO = -1;
          row.idHPiSinModificar = -1
          row.idPlanificado = idPlanificado;
          row.idMaquina = idMaquina;
          row.numoperario = numoperario;
          row.idMantenimiento = idMantenimiento;
          row.idPlanificadoLargo = idPlanificadoLargo;
          row.colada = colada;
          row.nserie = nserie;
          row.lote = lote;
  
          row.programa = this.JprogramasSelected.text;
          row.nombrePrograma = this.JprogramasSelected.text;
          row.pieza = this.JpiezasSelected.text;
          row.refOF = this.JproyectoSelected.text;
          row.parte = this.JpartesSelected.text;
          row.cliente = this.JproyectoSelected.cliente;
          row.idPieza = this.JpiezasSelected.value;
          row.esUltimoProceso = 0;

          if (this.JnSerieSelected == undefined) {
            //si se ha seleccionado otra pieza ya existente se actualiza.
            row.idHPi = 0;
            //si la nueva pieza seleccionada se añade 
            row.idNserieParte = 0;
            row.nuevoNserie = this.nuevoNserie;
            row.nSerie = row.nuevoNserie; //para actualizar el grid
          } else {
            //si se ha seleccionado otra pieza ya existente se actualiza.
            row.idHPi = this.JnSerieSelected.idHP;
            //si la nueva pieza seleccionada se añade 
            row.nuevoNserie = this.JnSerieSelected.nSerie;
            row.numeroSerie = this.JnSerieSelected.nSerie; //para actualizar el grid
            row.nSerie = this.JnSerieSelected.nSerie;
          }
          //#endregion
  
          if (this.fechaIni.getTime() != new Date(element.fechaini).getTime() && this.fechaFin.getTime() != new Date(element.fechafin).getTime()) { // se debe dividir la linea en tres lineas
            
            row.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
            row.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
            row.horaIni = new Date(row.fechaini).toLocaleTimeString();
            row.horaFin = new Date(row.fechafin).toLocaleTimeString();
            row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
            row.THHEjecucion = row.tiempoRealHH;
            this.dataValidador.push(row);
  
            var row2 = this.myFunctions.copy(element);
            row2.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
            row2.horaIni = new Date(row2.fechaini).toLocaleTimeString();
            row2.horaFin = new Date(row2.fechafin).toLocaleTimeString();
            row2.tiempoRealHH = this.calcularTDiferencia(row2['fechafin'], row2['fechaini']);
            row2.THHEjecucion = row2.tiempoRealHH;
            row2.numFila = row2.numFila + 0.2;
            this.dataValidador.push(row2);
  
            element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
            element.horaIni = new Date(element.fechaini).toLocaleTimeString();
            element.horaFin = new Date(element.fechafin).toLocaleTimeString();
            element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
            element.THHEjecucion = element.tiempoRealHH;
            element.esUltimoProceso = 0;
          } else if (this.fechaIni.getTime() == new Date(element.fechaini).getTime() && this.fechaFin.getTime() != new Date(element.fechafin).getTime()) { // se debe dividir la linea en dos lineas
  
            row.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
            row.horaIni = new Date(row.fechaini).toLocaleTimeString();
            row.horaFin = new Date(row.fechafin).toLocaleTimeString();
            row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
            row.THHEjecucion = row.tiempoRealHH;
            this.dataValidador.push(row);
  
            element.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaFin);
            element.horaIni = new Date(element.fechaini).toLocaleTimeString();
            element.horaFin = new Date(element.fechafin).toLocaleTimeString();
            element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
            element.THHEjecucion = element.tiempoRealHH;
          } else if (this.fechaIni.getTime() != new Date(element.fechaini).getTime() && this.fechaFin.getTime() == new Date(element.fechafin).getTime()) { // se debe dividir la linea en dos lineas
  
            row.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
            row.horaIni = new Date(row.fechaini).toLocaleTimeString();
            row.horaFin = new Date(row.fechafin).toLocaleTimeString();
            row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
            row.THHEjecucion = row.tiempoRealHH;
            row.esUltimoProceso = element.esUltimoProceso;
            this.dataValidador.push(row);
  
            element.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(this.fechaIni);
            element.horaIni = new Date(element.fechaini).toLocaleTimeString();
            element.horaFin = new Date(element.fechafin).toLocaleTimeString();
            element.tiempoRealHH = this.calcularTDiferencia(element['fechafin'], element['fechaini']);
            element.THHEjecucion = element.tiempoRealHH;
            element.esUltimoProceso = 0;
          } else { // no se divide la linea solo se actualiza
            //#region asignar los valores
            element.piezasTerminadas = piezasTerminadas;
            element.piezasAchatarradas = piezasAchatarradas;
            element.piezasApartadas = piezasApartadas;
            element.idOperacion = idOperacion;
            element.idCamprint_programa = idOperacion;
            element.idHO = -1;
            element.idHPiSinModificar = -1;
            element.idPlanificado = idPlanificado;
            element.idMaquina = idMaquina;
            element.numoperario = numoperario;
            element.idMantenimiento = idMantenimiento;
            element.idPlanificadoLargo = idPlanificadoLargo;
            element.colada = colada;
            element.nserie = nserie;
            element.lote = lote;
            element.programa = this.JprogramasSelected.text;
            element.nombrePrograma = this.JprogramasSelected.text;
            element.pieza = this.JpiezasSelected.text;
            element.refOF = this.JproyectoSelected.text;
            element.parte = this.JpartesSelected.text;
            element.cliente = this.JproyectoSelected.cliente;
            element.idPieza = this.JpiezasSelected.value;

            if (this.JnSerieSelected == undefined) {
              //si se ha seleccionado otra pieza ya existente se actualiza.
              element.idHPi = 0;
              //si la nueva pieza seleccionada se añade 
              element.idNserieParte = 0;
              element.nuevoNserie = this.nuevoNserie;
              element.nSerie = element.nuevoNserie; //para actualizar el grid
            } else {
              //si se ha seleccionado otra pieza ya existente se actualiza.
              element.idHPi = this.JnSerieSelected.idHP;
              //si la nueva pieza seleccionada se añade 
              element.nuevoNserie = this.JnSerieSelected.nSerie;
              element.numeroSerie = this.JnSerieSelected.nSerie; //para actualizar el grid
              element.nSerie = this.JnSerieSelected.nSerie;
            }
            //#endregion
          }
          return false;
        }
        return true;
      })
  
      // ordenar la lista del grid con las nuevas lineas insertadas
      this.dataValidador = this.dataValidador.sort((a, b) => {
        if (new Date(a.fechaini).getTime() > new Date(b.fechaini).getTime()) return 1
        else if (new Date(a.fechaini).getTime() < new Date(b.fechaini).getTime()) return -1
        else return 0
      })
      console.log(this.dataValidador);
      this.dataValidador_grid = this.dataValidador;

    // this.validadorService.asignarOperacionEndiferida(fechaInicio, fechaFin, piezasTerminadas, piezasAchatarradas, piezasApartadas, idOperacion, idPlanificado, idMaquina, numoperario/*, idHistoricoProcesos*/, idProcesoTipo, idMantenimiento, idPlanificadoLargo, colada, nserie, lote, this.isOffline, terminado, this.JproyectoSelected.value, this.JpiezasSelected.value, this.JpartesSelected.value).subscribe(
    //   json => {
    //     // cargar de nuevo la informacion del validador
    //     this.cargarValidador();
    //   }
    // )

    //cerrar popup
    this.modalReference.close();
    this.generarGantt();
    this.mySelection = [];
  }



  // END ANNADIR OPERACION




  // MAQUINAS SUBESTADOS  
  //GENERAR GANTT
  private generarGantt() {
    this.get_Graficos_conSubestados();
  }
  get_Graficos_conSubestados() {
    this.maquinasService.Get_subestados_activos(this.selectedMaquina).subscribe(
      (j) => {
        this.jSubEstados = j;
        var an: any = j;
        this.jSubEstados_personalizados = an.filter(f => f.idProcesos_Tipo_subEstados >= 100);
        this.cargar_Gantt(); //CARGAR GANT VA DENTRO DE LOS TURNOS
      });
  }

  cargar_Gantt() {
    let startDate = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate()),
      endDate = new Date(this.fecha.getFullYear(), this.fecha.getMonth(), this.fecha.getDate() + 1);

    var hoy = !(this.myFunctions.datetimeToDate(this.fecha) < this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))
      && !(this.myFunctions.datetimeToDate(this.fecha) > this.myFunctions.datetimeToDate(this.myFunctions.getDateNow()))


    this.informeRendimientoService.get_turnos_new(this.selectedMaquina, this.dateToYYYYMMDDtHHmmSSz(startDate), this.dateToYYYYMMDDtHHmmSSz(endDate), hoy).subscribe(
      json => {
        var turnosArray = [];
        var an: any = json;

        var fechaMin = new Date(startDate);
        var fechaMax = new Date(endDate);
        var diaTurno: Date = new Date(startDate);

        // se cargan los horarios de la maquina como "por defecto" para marcar el inicio y fin del dia en caso de que no se cubra 100% con turnos
        var horarioFueraTurno = an.filter(f => f.clase == 'fuera')
        if (horarioFueraTurno.length > 0) {
          fechaMin = new Date(horarioFueraTurno[0].min);
          fechaMax = new Date(horarioFueraTurno[0].max);
          diaTurno = new Date(horarioFueraTurno[0].turno); //ESTA VARIABLE NOS DIRA QUE DIA TOCA VALIDAR EN EL VALIDADOR
        }

        an.forEach(f => {
          var d = new Date(f.turno);
          if (f.clase != 'fuera') {
            if (d.getTime() == diaTurno.getTime()) {
              turnosArray.push({ min: new Date(f.min), max: new Date(f.max), clase: f.clase });
              if (new Date(f.min) < fechaMin) {
                fechaMin = new Date(f.min);
              }
              if (new Date(f.max) > fechaMax) {
                fechaMax = new Date(f.max);
              }
            }
            else if (d < fechaMin) {
              fechaMin = new Date(f.max);
            }
            else if (d >= fechaMax) {
              fechaMax = new Date(f.min);
            }
          }
        });

        // let data = {
        //   machines: [
        //     {
        //       id: '1',
        //       name: this.translateService.instant("ejecucion"),
        //       image: ''
        //     }, {
        //       id: '2',
        //       name: this.translateService.instant("parada"),
        //       image: ''
        //     }, {
        //       id: '3',
        //       name: this.translateService.instant("mantenimiento"),
        //       image: ''
        //     }, {
        //       id: '4',
        //       name: this.translateService.instant("alarma"),
        //       image: ''
        //     }, {
        //       id: '5',
        //       name: this.translateService.instant("apagada"),
        //       image: ''
        //     }
        //   ],
        //   tasks: []
        // };


        // SE GENERA LA ESTRUCTURA BASE
        let data = {
          machines: [
          ],
          tasks: []
        };

        var orden = 1; // para que el gantt se mantenga en orden despues de agrupar los subestados

        this.jSubEstados.forEach(
          (subestado) => {
            if (subestado.idMaquinas_subEstados < 0) {
              // id: subestado.idProcesos_Tipo_subEstados,     
              var machine =
              {
                id: orden,
                name: this.translateService.instant(subestado.nombre),
                image: ''
              }
              orden += 1;
              data.machines.push(machine);
            }
          });


        var an: any = this.dataValidador;
        //CALCULAR TOOLTIP
        an.forEach(f => {
          var tooltip = ``;

          tooltip = tooltip + `<div class="tooltipa"><p><strong><span class="tooltip-negrita">`;

          if (f.idProcesos_Tipo == 1) tooltip = tooltip + `${this.translateService.instant("ejecucion").toUpperCase()}`;
          if (f.idProcesos_Tipo == 2) tooltip = tooltip + `${this.translateService.instant("parada").toUpperCase()}`;
          if (f.idProcesos_Tipo == 3) tooltip = tooltip + `${this.translateService.instant("preparacion").toUpperCase()}`;
          if (f.idProcesos_Tipo == 4) tooltip = tooltip + `${this.translateService.instant("mantenimiento").toUpperCase()}`;
          if (f.idProcesos_Tipo == 6) tooltip = tooltip + `${this.translateService.instant("alarma").toUpperCase()}`;
          if (f.idProcesos_Tipo == 7) tooltip = tooltip + `${this.translateService.instant("alarma").toUpperCase()}`;
          if (f.idProcesos_Tipo == 8) tooltip = tooltip + `${this.translateService.instant("apagada").toUpperCase()}`;
          if (f.idProcesos_Tipo == 9) tooltip = tooltip + `${this.translateService.instant("canal").toUpperCase()}`;

          tooltip = tooltip + `</strong></label></p>`;

          if (f.nombrePrograma != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${f.nombrePrograma}<br></span></p>`;

          if (f.descripcion != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("descripcion").toUpperCase()}: </span><span class="tooltip-valor">${f.descripcion}<br></span></p>`;


          tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${f.operario}</span></p>`;

          tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

          if (f.refOF != "" && f.idProcesos_Tipo != 4) {
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("of").toUpperCase()}: </span><span class="tooltip-valor">${f.refOF}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("cliente").toUpperCase()}: </span><span class="tooltip-valor">${f.cliente}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("refPieza").toUpperCase()}: </span><span class="tooltip-valor">${f.refPieza}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("pieza").toUpperCase()}: </span><span class="tooltip-valor">${f.pieza}<br></span></p>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("operacion").toUpperCase()}: </span><span class="tooltip-valor">${f.programa}<br></span></p>`;
            if (f.colada_gantt != "")
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("colada").toUpperCase()}: </span><span class="tooltip-valor">${f.colada_gantt}<br></span></p>`;
          if (f.lote_gantt != "")
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("lote").toUpperCase()}: </span><span class="tooltip-valor">${f.lote_gantt}<br></span></p>`;
          if (f.numeroSerie_gantt != "")
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${f.numeroSerie_gantt}<br></span></p>`;
          if (f.contador)
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("contador").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidad}<br></span></p>`;
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("cantidad2").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidad}<br></span></p>`;
          } else if (f.idProcesos_Tipo != 4) {
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("operacionNoEncontrada").toUpperCase()}<br></span></p>`;
          } else {
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("mantenimiento").toUpperCase()}: </span><span class="tooltip-valor">${f.nombreMantenimiento}<br></span></p>`;
          }

          if (f.perdida != "") {
            tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
            tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("perdida").toUpperCase()}: </span><span class="tooltip-valor">${f.perdida}<br></span></p>`;
          }

          tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

          var fIni = new Date(f.fechaini);
          var fFin = new Date(f.fechafin);
          var dif = this.myFunctions.secondsTo_HH_MM_SS((fFin.getTime() - fIni.getTime()) / 1000);

          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("duracion").toUpperCase()}: </span><span class="tooltip-valor">${dif}<br></span></p>`;
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("inicio2").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fIni)}<br></span></p>`;
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("fin").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fFin)}<br></span></p>`;

          tooltip = tooltip + `</div>`;

          f.detail2 = tooltip;

          if (this.visibleTipo) {
            f.detail = (d) => f.detail2;
          }
        });
        data.tasks = an;

        console.log("GANTT")
        console.log(data)
        console.log(fechaMin)
        console.log(fechaMax)
        console.log(turnosArray)

        var gantt = new GanttChartClick('gantt-chart', {
          width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
          height: 50 + ((64 - Math.min((4 * Object.keys(data.machines).length), 29)) * Object.keys(data.machines).length),
          startDate: fechaMin,
          endDate: fechaMax,
          shifts: turnosArray,
          data: data
        }, this.translateService).on('task_clicked', (id) => this.appointmentClick(id));


        //.on('task_clicked', function (id) {
        //  var vc = this;
        //  return vc.appointmentClick(id);
        //});

        this.loadingPanel = false;
      }
    )
  }

  //#region ASIGNAR PERDIDA
  clickAsignarPerdida(popup) {

    this.dataValidadorSelecteds = this.dataValidador_grid.filter(f => this.mySelection.includes(f.id));

    //#region comprobar que es una parada sin justificar
    var idPerdida = this.dataValidadorSelecteds[0].idPerdida;
    var idProcesosTipo = this.dataValidadorSelecteds[0].idProcesos_Tipo;
    var microparada = this.dataValidadorSelecteds[0].microParada;
    if (idPerdida == -1 /*&& (idProcesosTipo == 2 || idProcesosTipo == 6 || idProcesosTipo == 8)*/) {
      this.esParadaSinJustificar = true;
      this.esParadaJustificada = false;
    } else if (idPerdida != -1 /*&& (idProcesosTipo == 2 || idProcesosTipo == 6 || idProcesosTipo == 8)*/) {
      this.esParadaSinJustificar = false;
      this.esParadaJustificada = true;
    }
    // else {
    //   this.esParadaSinJustificar = false;
    //   this.esParadaJustificada = false;
    // }
    //#endregion
    
    if (this.esParadaSinJustificar) {
      this.perdidasService.getPerdidas(this.selectedMaquina).subscribe(
        json => {
           this.dataPerdidasGrid = json;
        }
      )

      this.fechaLimite = new Date(this.dataValidadorSelecteds[0].fechafin);
      this.asignarMultiperdida = [{
        id: 0,
        fechaIni: new Date(this.dataValidadorSelecteds[0].fechaini),
        fechaFin: this.fechaLimite,
        fechaMin: new Date(this.dataValidadorSelecteds[0].fechaini),
        fechaMax: this.fechaLimite,
        perdida: "",
        idPerdida: -1,
        idTipoPerdida: -1,
        tieneSubtipos: 0,
        requiereExplicacion: 0,
        explicacion: "",
        solicitarOF: 0
      }]

      if (this.dataValidadorSelecteds[0].esUltimoProceso) {
        this.esUltimoProceso = true;
      } else {
        this.esUltimoProceso = false;
      }
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      this.perdidasService.getPerdidas(this.selectedMaquina).subscribe(
        json => {
           this.dataPerdidasGrid = json;
           this.perdidaAsignada = this.dataPerdidasGrid.filter(f => f.idPerdidaHistoricoBase == idPerdida)[0];
        }
      )
    }

    this.modalReferenceAsignar = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  clickEnsennarPerdidas(popup, perdida) {
    this.idIntervaloTiempo = perdida;
    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  elegirPerdida(event, popup) {

    var index = this.asignarMultiperdida.indexOf(this.idIntervaloTiempo);

    if (this.esParadaSinJustificar) {
      if (event.dataItem.requiereExplicacion == 0) {
        if (event.dataItem.idSubPerdida != -1) {
          this.asignarMultiperdida[index].idPerdida = event.dataItem.idSubPerdida;
          this.asignarMultiperdida[index].perdida = event.dataItem.subperdida;
        } else if (event.dataItem.idPerdida != -1) {
          this.asignarMultiperdida[index].idPerdida = event.dataItem.idPerdida;
          this.asignarMultiperdida[index].perdida = event.dataItem.perdida;
        } else {
          this.asignarMultiperdida[index].idPerdida = event.dataItem.idGrupoPerdida;
          this.asignarMultiperdida[index].perdida = event.dataItem.grupoPerdida;
        }
        this.asignarMultiperdida[index].requiereExplicacion = 0;
        this.asignarMultiperdida[index].explicacion = "";
        this.asignarMultiperdida[index].idTipoPerdida = event.dataItem.idTipoPerdida;
        this.asignarMultiperdida[index].tieneSubtipos = event.dataItem.tieneSubtipos;
        this.asignarMultiperdida[index].solicitarOF = event.dataItem.solicitarOF;
        this.comprobarTipoPerdida(event.dataItem);
      } else {
        this.perdidaSeleccionada = event.dataItem;
        this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }

      // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
      if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1) 
        this.ultimoJustificado = true;
      else 
        this.ultimoJustificado = false;
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      if (event.dataItem.requiereExplicacion == 0) {
        if (event.dataItem.idSubPerdida != -1) {
          this.perdidaAsignada.idPerdida = event.dataItem.idSubPerdida;
          this.perdidaAsignada.perdida = event.dataItem.subperdida;
        } else if (event.dataItem.idPerdida != -1) {
          this.perdidaAsignada.idPerdida = event.dataItem.idPerdida;
          this.perdidaAsignada.perdida = event.dataItem.perdida;
        } else {
          this.perdidaAsignada.idPerdida = event.dataItem.idGrupoPerdida;
          this.perdidaAsignada.perdida = event.dataItem.grupoPerdida;
        }
        this.perdidaAsignada.requiereExplicacion = 0;
        this.perdidaAsignada.explicacion = "";
        this.perdidaAsignada.idTipoPerdida = event.dataItem.idTipoPerdida;
        this.perdidaAsignada.tieneSubtipos = event.dataItem.tieneSubtipos;
        this.perdidaAsignada.solicitarOF = event.dataItem.solicitarOF;
        this.comprobarTipoPerdida(event.dataItem);
      } else {
        this.perdidaSeleccionada = event.dataItem;
        this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }

  }

  // esta funcion comprueba que si la perdida es de tipo ejecucion/preparacion (1,3) entonces que se deba asignar una of
  comprobarTipoPerdida(perdida) {
    // if (perdida.idTipoPerdida == 1 || perdida.idTipoPerdida == 3) {
    if (perdida.solicitarOF == 1 && this.dataValidadorSelecteds[0].idOperacion <= 0) {
      this.clickEditarOF(this.popupEditar);
    }
  }

  addPerdida(perdida) {
    if (perdida.fechaFin > this.fechaLimite) { // se controla que no pongan desde el teclado una fecha mayor
      perdida.fechaFin = this.fechaLimite;
    } 

    var ultimaFila = this.asignarMultiperdida[this.asignarMultiperdida.length - 1];
    if (ultimaFila.id == perdida.id && this.fechaLimite != ultimaFila.fechaFin) {
      this.asignarMultiperdida.push({
        id: ultimaFila.id + 1,
        fechaIni: ultimaFila.fechaFin,
        fechaFin: this.fechaLimite,
        fechaMin: ultimaFila.fechaFin,
        fechaMax: this.fechaLimite,
        perdida: "",
        idPerdida: -1,
        idTipoPerdida: -1,
        tieneSubtipos: 0,
        requiereExplicacion: 0,
        explicacion: "",
        solicitarOF: 0
      })
    } else if (ultimaFila.id != perdida.id) {
      var index = this.asignarMultiperdida.indexOf(perdida);
      if (perdida.fechaFin >= this.asignarMultiperdida[index + 1].fechaFin) { // hay que cambiar las fechas siguientes
        this.asignarMultiperdida[index + 1].fechaFin = this.fechaLimite;
        this.asignarMultiperdida[index + 1].fechaIni = perdida.fechaFin;
        if (this.asignarMultiperdida.length > 2)
          this.asignarMultiperdida = this.asignarMultiperdida.slice(0, index + 2);
        else
          this.asignarMultiperdida = this.asignarMultiperdida.slice(0, index + 1);
      } else { // solo hay que cambiar la fecha de inicio de la siguiente linea
        this.asignarMultiperdida[index + 1].fechaIni = perdida.fechaFin;
      }
    }

    // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
    if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1) 
      this.ultimoJustificado = true;
    else 
      this.ultimoJustificado = false;
    this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;

  }

  eliminar(perdida) {
    if (this.esParadaSinJustificar) {
      var index = this.asignarMultiperdida.indexOf(perdida);
      if (this.asignarMultiperdida.length != 2) {
        this.asignarMultiperdida[index + 1].fechaIni = this.asignarMultiperdida[index - 1].fechaFin;
        this.asignarMultiperdida[index + 1].fechaMin = this.asignarMultiperdida[index - 1].fechaFin;
      } else {
        this.asignarMultiperdida[0].fechaFin = this.fechaLimite;
      }
      this.asignarMultiperdida = this.asignarMultiperdida.filter(f => f.id != perdida.id);

      // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
      if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1) 
        this.ultimoJustificado = true;
      else 
        this.ultimoJustificado = false;
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      this.perdidaAsignada = {
        perdida: "",
        idPerdida: -1,
        idTipoPerdida: -1,
        tieneSubtipos: 0,
        requiereExplicacion: 0,
        explicacion: "",
        solicitarOF: 0
      }
    }
  }

  guardarObservacion() {
    var index = this.asignarMultiperdida.indexOf(this.idIntervaloTiempo);

    if (this.esParadaSinJustificar) {
      this.asignarMultiperdida[index].requiereExplicacion = this.perdidaSeleccionada.requiereExplicacion;
      this.asignarMultiperdida[index].explicacion = this.observacionPerdida;
      this.asignarMultiperdida[index].idTipoPerdida = this.perdidaSeleccionada.idTipoPerdida;
      this.asignarMultiperdida[index].tieneSubtipos = this.perdidaSeleccionada.tieneSubtipos;
      if (this.perdidaSeleccionada.idSubPerdida != -1) {
        this.asignarMultiperdida[index].idPerdida = this.perdidaSeleccionada.idSubPerdida;
        this.asignarMultiperdida[index].perdida = this.perdidaSeleccionada.subperdida;
      } else if (this.perdidaSeleccionada.idPerdida != -1) {
        this.asignarMultiperdida[index].idPerdida = this.perdidaSeleccionada.idPerdida;
        this.asignarMultiperdida[index].perdida = this.perdidaSeleccionada.perdida;
      } else {
        this.asignarMultiperdida[index].idPerdida = this.perdidaSeleccionada.idGrupoPerdida;
        this.asignarMultiperdida[index].perdida = this.perdidaSeleccionada.grupoPerdida;
      }

      // Comprobar que el ultimo rango esta justificado para dar la opcion de justificar hasta el final del proceso
      if (this.asignarMultiperdida[this.asignarMultiperdida.length - 1].idPerdida != -1) 
        this.ultimoJustificado = true;
      else 
        this.ultimoJustificado = false;
      this.ensennarJustificarHastaFin = this.esUltimoProceso && this.ultimoJustificado;
    } else if (this.esParadaJustificada) {
      this.perdidaAsignada.requiereExplicacion = this.perdidaSeleccionada.requiereExplicacion;
      this.perdidaAsignada.explicacion = this.observacionPerdida;
      this.perdidaAsignada.idTipoPerdida = this.perdidaSeleccionada.idTipoPerdida;
      this.perdidaAsignada.solicitarOF = this.perdidaSeleccionada.solicitarOF;
      this.perdidaAsignada.tieneSubtipos = this.perdidaSeleccionada.tieneSubtipos;
      if (this.perdidaSeleccionada.idSubPerdida != -1) {
        this.perdidaAsignada.idPerdida = this.perdidaSeleccionada.idSubPerdida;
        this.perdidaAsignada.perdida = this.perdidaSeleccionada.subperdida;
      } else if (this.perdidaSeleccionada.idPerdida != -1) {
        this.perdidaAsignada.idPerdida = this.perdidaSeleccionada.idPerdida;
        this.perdidaAsignada.perdida = this.perdidaSeleccionada.perdida;
      } else {
        this.perdidaAsignada.idPerdida = this.perdidaSeleccionada.idGrupoPerdida;
        this.perdidaAsignada.perdida = this.perdidaSeleccionada.grupoPerdida;
      }
    }

    this.comprobarTipoPerdida(this.perdidaSeleccionada);

    //cerrar popup
    this.modalReference.close();
  }

  validarPerdidas() {

    // preparar la lista de datos para que en local se vean los cambios (sin hacerlos en la base de datos)
    var auxPerd;
    if (this.esParadaSinJustificar) {
      this.Jvalidador.every(element => {
        if (element.numFila == this.dataValidadorSelecteds[0].numFila) {
          element.annadirMultiperdida = true;
          if (this.asignarMultiperdida.length < 2) {
            element.perdida = this.asignarMultiperdida[0].perdida;
            element.idPerdida = this.asignarMultiperdida[0].idPerdida;
            auxPerd = {
              fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechaini)),
              fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechafin)),
              observacion: {
                id: this.asignarMultiperdida[0].idPerdida,
                idTipoPerdida: this.asignarMultiperdida[0].idTipoPerdida,
                titulo: this.asignarMultiperdida[0].perdida,
                descripcion: this.asignarMultiperdida[0].explicacion,
                requiereExplicacion: this.asignarMultiperdida[0].requiereExplicacion,
                tieneSubtipos: this.asignarMultiperdida[0].tieneSubtipos,
              }
            }
            this.timelinePerdidas.set(element.numFila, {justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [auxPerd]})
          } else {
            var i = 1;
            this.asignarMultiperdida.forEach(perd => {
              let row = this.myFunctions.copy(element);
              row.perdida = perd.perdida;
              row.idPerdida = perd.idPerdida;
              row.fechaini = this.myFunctions.dateToYYYYMMDDtHHmmSS(perd.fechaIni);
              row.fechafin = this.myFunctions.dateToYYYYMMDDtHHmmSS(perd.fechaFin);
              row.horaIni = new Date(row.fechaini).toLocaleTimeString();
              row.horaFin = new Date(row.fechafin).toLocaleTimeString();
              row.tiempoRealHH = this.calcularTDiferencia(row['fechafin'], row['fechaini']);
              row.THHParada = row.tiempoRealHH;
              row.numFila = row.numFila + (0.1*i)
              auxPerd = {
                fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSSz(perd.fechaIni),
                fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSSz(perd.fechaFin),
                observacion: {
                  id: perd.idPerdida,
                  idTipoPerdida: perd.idTipoPerdida,
                  titulo: perd.perdida,
                  descripcion: perd.explicacion,
                  requiereExplicacion: perd.requiereExplicacion,
                  tieneSubtipos: perd.tieneSubtipos,
                }
              }
              this.timelinePerdidas.set(row.numFila, {justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [auxPerd]})
              this.Jvalidador.push(row);
              i++;
            })
            this.Jvalidador =  this.Jvalidador.filter(f => f.numFila != this.dataValidadorSelecteds[0].numFila)
            this.dataValidador_grid = this.dataValidador;
            this.timelinePerdidas.delete(element.numFila);
          }
          return false;
        }
        return true
      })
      // ordenar la lista del grid con las nuevas lineas insertadas
      this.Jvalidador = this.Jvalidador.sort((a, b) => {
        if (new Date(a.fechaini).getTime() > new Date(b.fechaini).getTime()) return 1
        else if (new Date(a.fechaini).getTime() < new Date(b.fechaini).getTime()) return -1
        else return 0
      })
    } else if (this.esParadaJustificada) {
      this.dataValidador.every(element => {
        if (element.numFila == this.dataValidadorSelecteds[0].numFila) {
          if (this.perdidaAsignada.idPerdida != -1) element.annadirMultiperdida = true;
          element.actualizarPerdida = true;
          element.perdida = this.perdidaAsignada.perdida;
          element.idPerdida = this.perdidaAsignada.idPerdida;
          auxPerd = {
            fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechaini)),
            fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSS(new Date(element.fechafin)),
            observacion: {
              id: this.perdidaAsignada.idPerdida,
              idTipoPerdida: this.perdidaAsignada.idTipoPerdida,
              titulo: this.perdidaAsignada.perdida,
              descripcion: this.perdidaAsignada.explicacion,
              requiereExplicacion: this.perdidaAsignada.requiereExplicacion,
              tieneSubtipos: this.perdidaAsignada.tieneSubtipos,
            }
          } 
          this.timelinePerdidas.set(element.numFila, {justificarHastaFinProceso: this.justificarHastaFinProceso, idsHistoricoProceso: '', timelinePerdidas: [auxPerd]}) 

          return false;
        }
        return true
      })
    }

    //Ahora miramos cual estaba filtrada para filtrarlas de nuevo
    this.filtrarFiltroSeleccionado();

    //#region esto se hacia antes cuando para actualizar no era necesario darle al boton de actualizar 
    // if (this.esParadaSinJustificar) {
    //   var idsHistoricoProceso = this.dataValidadorSelecteds[0].iDhistorico;
    //   var timelinePerdidas = [];
    //   this.asignarMultiperdida.forEach(element => {
    //     timelinePerdidas.push({
    //       fechaInicio: this.myFunctions.dateToYYYYMMDDtHHmmSSz(element.fechaIni),
    //       fechaFin: this.myFunctions.dateToYYYYMMDDtHHmmSSz(element.fechaFin),
    //       observacion: {
    //         id: element.idPerdida,
    //         idTipoPerdida: element.idTipoPerdida,
    //         titulo: element.perdida,
    //         descripcion: element.explicacion,
    //         requiereExplicacion: element.requiereExplicacion,
    //         tieneSubtipos: element.tieneSubtipos,
    //       }
    //     })
    //   })
    //   this.perdidasService.justificarMultiPerdida(timelinePerdidas, idsHistoricoProceso, this.justificarHastaFinProceso).subscribe(
    //     json => {
    //       // cargar de nuevo la informacion del validador
    //       this.guardar(false);
    //       // this.cargarValidador();
    //     }
    //   )
    // } else if (this.esParadaJustificada) {
    //   var idsHistoricoProceso = this.dataValidadorSelecteds[0].iDhistorico;
    //   var perdida = {
    //     id: this.perdidaAsignada.idPerdida,
    //     idTipoPerdida: this.perdidaAsignada.idTipoPerdida,
    //     titulo: this.perdidaAsignada.perdida,
    //     descripcion: this.perdidaAsignada.explicacion,
    //     requiereExplicacion: this.perdidaAsignada.requiereExplicacion,
    //     tieneSubtipos: this.perdidaAsignada.tieneSubtipos
    //   }
    //   this.perdidasService.Update_historico_procesos_perdidas(perdida.titulo, perdida.id, perdida.idTipoPerdida, idsHistoricoProceso).subscribe(
    //     json => {
    //       // cargar de nuevo la informacion del validador
    //       this.guardar(false);
    //       // this.cargarValidador();
    //     }
    //   )
    // }

    //cerrar popup
    //#endregion
    
    this.modalReferenceAsignar.close();
    this.generarGantt();
    this.mySelection = [];
  }
  //#endregion

  public calcularTDiferencia(TFin:string, TInicio:string):string
  {
    // let HoraFin = new Date(TFin).toLocaleTimeString(); // 6:40:54
    // let HoraInicio = new Date(TInicio).toLocaleTimeString(); // 6:00:54

    // let HoraFinT = HoraFin.split(':');
    // let HoraInicionT = HoraInicio.split(':');

    // let secondsFin = (+HoraFinT[0]) * 60 * 60 + (+HoraFinT[1]) * 60 + (+HoraFinT[2]); 
    // let secondsInicio = (+HoraInicionT[0]) * 60 * 60 + (+HoraInicionT[1]) * 60 + (+HoraInicionT[2]); 
    // return this.tiempoReal(secondsFin-secondsInicio);

    var a: Date = new Date(TInicio);
    var b: Date = new Date(TFin);
    //var dif = Math.abs((b.getTime() - a.getTime()) / 1000)
    
    return this.myFunctions.milisecondsTo_HH_MM_SS(b.getTime()-a.getTime());
  }


}
