import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({ templateUrl: 'hmiAditivo.html' })

export class HMIAditivoComponent implements OnInit {

  interval: any;
  interval2: any;

  idmaquina: any;
  maquina: any;
  maquinasModel: any;
  marcasModel: any;

  //CSS pestaña
  pestannaColor: any = "";

  //PANEL MAQUINA
  operario: any = "";
  procesosTipo: any = "-";
  procesosTipoMinuscSinAcentos: any = "-";
  fechaServidor: any;
  fechaIni: any;
  porcentaje: any = 0;
  estadoImg: any = "";
  tiempo: any = 0;
  tiempoCorregido: String = "";
  desviacion: String = "";
  tiempoEst: any = 0;
  tiempoEstCorregido: String = "";
  tiempoPred: any = 0;
  tiempoPredCorregido: String = "";
  tiempoDesviacion: any = 0;
  proceso: any = "";
  tipoAditivo: number = 0; //1: FDM, 2: LPFB, 3: LMD, 4: HP

  //LABELS OF
  of: any = "";
  cliente: any = "";
  pieza: any = "";
  parte: any = "";
  operacion: any = "";
  nPieza: any = "";
  material: any = "";

  //IMAGENES
  imgCapaTermica: any = "";
  imgTeoricaReal: any = "";
  imgEscenaTeorica: any = "";
  donutDocentaje: String = "";

  //PROGRESO
  alturaActual: any = "";
  alturaImpresion: any = "";
  capaActual: any = "";
  tiempoTotal: any = 0;
  tiempoActual: any = 0;
  tiempoRestante: any = 0;
  fin: any = null;

  //MATERIAL
  materialActual: number = 0;
  materialNecesario: number = 0;
  materialConsumido: number = 0;
  contenedor: number = 0;

  //GRAFICOS
  graficoProgreso: any;
  graficoMaterial: any;
  graficoResumenSemana: any;

  // MANTENIMIENTO
  mantenimientoPorFecha: any;
  mantenimientoPorTiempo: any;
  loadingPorFecha: any;
  loadingPorTiempo: any;

  //GRAFICO MULTIEJE
  public grafico1: any;
  public grafico2: any;
  public grafico3: any;
  public grafico4: any;
  public grafico5: any;
  public grafico6: any;
  public grafico7: any;
  public grafico8: any;
  public grafico9: any;
  public grafico10: any;
  public mostrarGraficosPorLeyenda: any = [true, true, true, true, false, false, false, false, false, false]; //10 grafiko sartu nahi ditugu
  public arrayFechas: any = ["-"];
  public fechaInicioZoom: any;
  public fechaFinZoom: any;
  public fechaIniRecetaSeleccionada: any;
  public fechaFinRecetaSeleccionada: any;
  public colores = [
    "#3366CC",
    "#CB3737",
    "#32A814",
    "#B4E50A",
    "#B962C5",
    "#FFC260",
    "#FF69A3",
    "#6DFACD",
    "#C09927",
    "#A8AABC",
  ];
  public datosGraficoMultieje: any = {};
  public listaTooltip: any = [];
  public listaTooltipSeleccionados: any = [];
  public primeraVez: boolean = true;//Cargamos solo una vez los graficos

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;

  //PESTANAS
  loadingPestannas: boolean = true;

  user = this.usuariosService.userValue;

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    public translateService: TranslateService,
    public myFunctions: MyFunctions) {

    this.interval = setInterval(() => {
      this.refreshData();
    }, 60000); //60000 MINUTURO, 5000 zegoen.

    this.interval2 = setInterval(() => {
      this.refreshDataTiempos();
    }, 1000);

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('aditivo');
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));
    this.maquina = {
      nombre: "-",
      imagenBase64: "",
      marca: { imagenBase64: "" },
      tiempoReal: [],
    };

    this.cargarMaquinasModel();
    this.cargarDatosMantenimientos();
  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }
  }

  cargarMaquina() {

    //COGEMOS LA MAQUINA
    this.maquina = this.maquinasModel.find(x => x.id === this.idmaquina);
    this.maquina.marca = this.marcasModel.find(x => x.id === this.maquina.idmarca);

    //PINTAMOS LOS GRAFICOS
    this.pintarGraficos();

  }

  pintarGraficos() {
    this.pintarGraficoProgreso();
    this.pintarGraficoMaterial();
    this.pintarGraficoResumenSemana();
    setTimeout(function () {
      this.pintarGraficoMultiEje();
      //DESPUES DE PINTAR LOS GRAFICOS CARGAMOS TODOS LOS DATOS
      this.cargarTodosLosDatos();
    }.bind(this), 50);
  }

  pintarGraficoProgreso() {

    this.graficoProgreso = c3.generate({
      bindto: '#progresoGrafico_hmiAdi',
      data: {
        columns: [
          ['dataAzul', 0],
          ['dataBlanco', 1],
        ],
        order: null,
        type: 'bar',
        groups: [
          ['dataAzul', 'dataBlanco']
        ]
      },
      color: {
        pattern: [['#22C4C4'], ['#FFFFFF']]
      },
      axis: {
        x: {
          show: false
        },
        y: {
          show: false
        }
      },
      tooltip: {
        show: false
      },
      legend: {
        show: false
      }
    });

  }

  pintarGraficoMaterial() {
    var th = this;

    this.graficoMaterial = c3.generate({
      bindto: '#materialGrafico_hmiAdi',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 25,
      },
      data: {
        columns: [
          ['dataAzulMarino', 0], //Material consumido
          ['dataAzulClaro', 0], //Material actual
          ['dataBlanco', 0], //Contenedor
          ['dataRayaRoja', 0] //Lo que queda hasta llegar a la raya roja (porque si no no se ve)(en el grafico es "invisible")
        ],
        order: null,
        type: 'bar',
        groups: [
          ['dataAzulMarino', 'dataAzulClaro', 'dataBlanco', 'dataRayaRoja']
        ]
      },
      color: {
        pattern: [['#4287CC'], ['#A5C6E6'], ['#FFFFFF'], ['#CB3737']]
      },
      axis: {
        x: {
          show: false
        },
        y: {
          show: false
        },
        rotated: true
      },
      grid: {
        y: {
          lines: [{ value: 0 }]
        }
      },
      tooltip: {
        format: {
          title: function (d) { return 'Material' },

          name: function (d) {
            if (d === "dataAzulMarino") return th.translateService.instant('materialConsumido');
            if (d === "dataAzulClaro") return th.translateService.instant('materialActual');
            if (d === "dataBlanco") return th.translateService.instant('contenedor');
            if (d === "dataRayaRoja") return th.translateService.instant('materialNecesario');
          },
          value: function (value, ratio, id) {
            let valor = value;
            if (id == 'dataBlanco') return valor = th.contenedor;
            if (id == 'dataRayaRoja') return valor = th.materialNecesario;
            else valor = value;

            valor = th.redondear2decimales(valor);

            return valor;
          }
        }
      },
      legend: {
        show: false
      }
    });

  }

  redondear2decimales(zki: number): number {
    var m = Number((Math.abs(zki) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(zki);
  }

  pintarGraficoResumenSemana() {

    this.graficoResumenSemana = c3.generate({
      bindto: '#resumenSemanaGrafico_hmiAdi',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0]
        ],
        type: 'bar',
        order: null
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      }
    });

  }

  pintarGraficoMultiEje() {

    var th = this;

    var i;
    for (i = 1; i <= 10; i++) {

      var chart = c3.generate({
        bindto: "#grafico" + i + "_hmiAdi",
        size: {
          height: 400
        },
        padding: {
          left: 124,
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            ['x'],
            ['valor']
          ],
          colors: {
            valor: th.colores[i - 1],
          },
          order: null
        },
        regions: [],
        transition: {
          duration: 0
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              values: function (x) {
                var dif = (x[1].getTime() - x[0].getTime()) / 1000;

                var fechaIni = x[0];
                fechaIni.setMinutes(fechaIni.getMinutes() - 10);
                var fechaFin = x[1];
                fechaFin.setMinutes(fechaFin.getMinutes() + 10);

                if (dif < 180)
                  return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(120);
                else if (dif < 1800)
                  return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(15);
                else
                  return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(10);
              },
              //format: '%m/%d - %H:%M:%S'
              format: function (x) {
                if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                  if (x.getDay() == 0) return th.translateService.instant("domingo");
                  if (x.getDay() == 1) return th.translateService.instant("lunes");
                  if (x.getDay() == 2) return th.translateService.instant("martes");
                  if (x.getDay() == 3) return th.translateService.instant("miercoles");
                  if (x.getDay() == 4) return th.translateService.instant("jueves");
                  if (x.getDay() == 5) return th.translateService.instant("viernes");
                  if (x.getDay() == 6) return th.translateService.instant("sabado");
                } else {
                  return th.myFunctions.dateToHourString(x);
                }
              },
            },
            padding: 0
          }
        },
        legend: {
          show: false
        },
        point: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {

            var fecha = d[0].x;

            if (fecha >= th.fechaInicioZoom && fecha <= th.fechaFinZoom) {

              var $$ = this, config = $$.config,
                titleFormat = config.tooltip_format_title || defaultTitleFormat,
                nameFormat = config.tooltip_format_name || function (name) { return name; },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text, i, title, value, name, bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }

                if (!text) {
                  title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                  text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                }

              }

              var indice = th.arrayFechas.findIndex(x => x == d[0].x);
              var camposSeleccionados = [];

              th.listaTooltip.forEach(function (campo) {
                if (campo.seleccionadoLeyenda) camposSeleccionados.push(campo);
              }, this);

              camposSeleccionados.forEach(function (campo) {

                if (th.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] && campo.valoresMomentaneo[indice] != undefined) {
                  name = th.translateService.instant(campo.nombre);

                  if (typeof campo.valoresMomentaneo[indice] == 'boolean')
                    if (campo.valoresMomentaneo[indice]) value = "True " + campo.unidad;
                    else value = "False " + campo.unidad;
                  else
                    value = campo.valoresMomentaneo[indice] + " " + campo.unidad;

                  bgcolor = campo.color;
                  text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[0].id + "'>";
                  text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                  text += "<td class='value'>" + value + "</td>";
                  text += "</tr>";
                }
              }, this);

              return text + "</table>";

            }

          }
        },
        zoom: {
          enabled: (i == 1),
          type: 'drag',
          disableDefaultBehavior: true,
          onzoomend: function (domain) {
            th.fechaInicioZoom = domain[0];
            th.fechaFinZoom = domain[1];
            th.aplicarZoomEnGrafico();
          }
        }
      });

      if (i == 1) this.grafico1 = chart;
      if (i == 2) this.grafico2 = chart;
      if (i == 3) this.grafico3 = chart;
      if (i == 4) this.grafico4 = chart;
      if (i == 5) this.grafico5 = chart;
      if (i == 6) this.grafico6 = chart;
      if (i == 7) this.grafico7 = chart;
      if (i == 8) this.grafico8 = chart;
      if (i == 9) this.grafico9 = chart;
      if (i == 10) this.grafico10 = chart;

    }

    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#grafico1_hmiAdi svg').node().childNodes;
    list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

    d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = function (e) {
      if (e.button != 0) {
        var evt = new MouseEvent("mousedown", {
          altKey: e.altKey,
          bubbles: e.bubbles,
          button: 0,
          buttons: e.buttons,
          cancelable: e.cancelable,
          clientX: e.clientX,
          clientY: e.clientY,
          composed: e.composed,
          ctrlKey: e.ctrlKey,
          detail: e.detail,
          metaKey: e.metaKey,
          movementX: e.movementX,
          movementY: e.movementY,
          relatedTarget: e.relatedTarget,
          screenX: e.screenX,
          screenY: e.screenY,
          shiftKey: e.shiftKey,
          view: e.view
        });
        d3.select("#grafico1_hmiAdi .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
      }
    }
    d3.select('#grafico2_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;
    d3.select('#grafico3_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;
    d3.select('#grafico4_hmiAdi .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_hmiAdi .c3-event-rect')._groups[0][0].onmousedown;

  }

  cargarTodosLosDatos() {

    this.centroMecanizadoService.GetHmiAditivoById(this.idmaquina).subscribe((result) => {

      var datos: any = result;

      var ejecucionActual = datos.ejecuciones;

      console.log('ejecucionActual[0].materialActual');
      console.log(ejecucionActual[0].materialActual);
      console.log('ejecucionActual[0].materialConsumido');
      console.log(ejecucionActual[0].materialConsumido);
      console.log('ejecucionActual[0].materialNecesario');
      console.log(ejecucionActual[0].materialNecesario);
      console.log('ejecucionActual[0].contenedor');
      console.log(ejecucionActual[0].contenedor);

      if (ejecucionActual.length > 0) {

        //pestannaColor
        if (ejecucionActual[0].idProcesos_tipo == 1) this.pestannaColor = "pestañaprocesos colorejecucion";
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.pestannaColor = "pestañaprocesos colorparada";
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.pestannaColor = "pestañaprocesos colorpreparacion";
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.pestannaColor = "pestañaprocesos colormantenimiento";
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.pestannaColor = "pestañaprocesos coloralarma";
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.pestannaColor = "pestañaprocesos colorapagada";
        else this.pestannaColor = "pestañaprocesos colorejecucion";

        //PANEL MAQUINA
        if (ejecucionActual[0].operario == " ") this.operario = this.translateService.instant('sinOperario');
        else this.operario = ejecucionActual[0].operario;

        this.fechaServidor = new Date(ejecucionActual[0].horaServidor);
        this.fechaIni = new Date(ejecucionActual[0].fecha);
        this.tiempoEst = ejecucionActual[0].tiempoEstimadoEjecucion;
        this.tiempoEstCorregido = this.corregirFormato(this.tiempoEst);
        this.tiempoPred = ejecucionActual[0].tiempoPredictivoEjecucion;
        this.tiempoPredCorregido = this.corregirFormato(this.tiempoPred);
        this.proceso = ejecucionActual[0].operacion;
        this.tipoAditivo = ejecucionActual[0].tipoAditivo; //1: FDM, 2: LPFB, 3: LMD, 4: HP

        if (ejecucionActual[0].idProcesos_tipo == 1) { this.procesosTipo = this.translateService.instant("ejecucion").toUpperCase(); this.donutDocentaje = "ejecucion"; }
        else if (ejecucionActual[0].idProcesos_tipo == 2) { this.procesosTipo = this.translateService.instant("parada").toUpperCase(); this.donutDocentaje = "parada"; }
        else if (ejecucionActual[0].idProcesos_tipo == 3) { this.procesosTipo = this.translateService.instant("preparacion").toUpperCase(); this.donutDocentaje = "preparacion"; }
        else if (ejecucionActual[0].idProcesos_tipo == 4) { this.procesosTipo = this.translateService.instant("mantenimiento").toUpperCase(); this.donutDocentaje = "mantenimiento"; }
        else if (ejecucionActual[0].idProcesos_tipo == 6) { this.procesosTipo = this.translateService.instant("alarma").toUpperCase(); this.donutDocentaje = "alarma"; }
        else if (ejecucionActual[0].idProcesos_tipo == 8) { this.procesosTipo = this.translateService.instant("apagada").toUpperCase(); this.donutDocentaje = "apagada"; }
        else { this.procesosTipo = this.translateService.instant("ejecucion").toUpperCase(); this.donutDocentaje = "ejecucion"; }

        this.procesosTipoMinuscSinAcentos = this.quitarAcentos(this.procesosTipo.toLowerCase());

        //GRAFICO EJECUCION
        this.CargarIconosEstadoMaquina()

        //DATOS PANEL
        this.of = ejecucionActual[0].of;
        this.cliente = ejecucionActual[0].cliente;
        this.pieza = ejecucionActual[0].pieza;
        this.parte = ejecucionActual[0].parte;
        this.operacion = ejecucionActual[0].operacion;
        this.nPieza = ejecucionActual[0].cuantasHechas + "/" + ejecucionActual[0].cantidad;
        this.material = ejecucionActual[0].material;

        //IMAGENES
        this.imgCapaTermica = "../../assets/Additivo_capa-termica.jpg";/////////////////////////////////////
        this.imgTeoricaReal = "../../assets/Additivo_Teorica-Real.jpg";////////////////////////////////
        this.imgEscenaTeorica = "../../assets/aditivo_Escena-teorica.jpg";//////////////////////////////////

        //PROGRESO
        this.alturaActual = ejecucionActual[0].alturaActual;
        this.alturaImpresion = ejecucionActual[0].alturaImpresion;
        this.capaActual = ejecucionActual[0].capaActual;
        this.tiempoTotal = ejecucionActual[0].tiempoTotal;
        this.tiempoActual = ejecucionActual[0].tiempoActual;
        this.tiempoRestante = ejecucionActual[0].tiempoRestante;
        this.fin = new Date(ejecucionActual[0].finPrevisto);
        this.graficoProgreso.load({
          columns: [
            ['dataAzul', this.alturaActual],
            ['dataBlanco', (this.alturaImpresion - this.alturaActual < 0) ? 0 : this.alturaImpresion - this.alturaActual],
          ]
        });

        //MATERIAL
        this.materialActual = ejecucionActual[0].materialActual;
        this.materialConsumido = ejecucionActual[0].materialConsumido;
        this.contenedor = ejecucionActual[0].contenedor;
        this.materialNecesario = ejecucionActual[0].materialNecesario;
        var dataBlanco = (this.contenedor - this.materialConsumido - this.materialActual < 0) ? 0 : this.contenedor - this.materialConsumido - this.materialActual;
        this.graficoMaterial.internal.config.grid_y_lines = [{ value: this.materialNecesario }];
        this.graficoMaterial.load({
          columns: [
            ['dataAzulMarino', this.materialConsumido], //Material consumido
            ['dataAzulClaro', this.materialActual], //Material actual
            ['dataBlanco', dataBlanco], //Contenedor grafico
            //['contenedor', this.contenedor], //Contenedor tooltip
            ['dataRayaRoja', (this.materialNecesario > this.materialConsumido + this.materialActual + dataBlanco) ? this.materialNecesario - (this.materialConsumido + this.materialActual + dataBlanco) : 0] //Lo que queda hasta llegar a la raya roja (porque si no no se ve)(en el grafico es "invisible")
          ]
        });

      }

      //RESUMEN SEMANA
      var tiempoPorSemana = datos.tiempoPorSemana;

      if (tiempoPorSemana.length == 0) {
        tiempoPorSemana = [{ porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }];
      }

      var tiempoPorSemanaData = [[this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
      [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
      [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
      [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
      [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
      [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
      [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]];

      this.graficoResumenSemana.load({ columns: tiempoPorSemanaData });

      //GRAFICO MULTIEJE
      if (this.primeraVez) {

        this.datosGraficoMultieje = {
          fechas: [],
          fdm_puertaAbiertaCerrada: [],
          fdm_temperaturaHorno: [],
          fdm_dewPoint: [],
          fdm_airFlow: [],
          fdm_posicionesEjes: [],
          fdm_platinaAspiradora: [],
          fdm_temperaturaExtrusor: [],
          fdm_cartuchos: [],
          fdm_ligthTower: [],
          fdm_VoltageUPS: [],

          lpfb_nivelDePolvo: [],
          lpfb_nivelDeO2: [],
          lpfb_temperaturaCamara: [],
          lpfb_presionCamara: [],
          lpfb_horasFiltro: [],
          lpfb_potenciaRecirculacion: [],
          lpfb_presionEntradaCamara: [],
          lpfb_temperaturaElevador: [],
          lpfb_temperaturaAguaHelada: [],

          lmd_potenciaDeLaser: [],
          lmd_velocidadDelRobot: [],
          lmd_flujoDeMaterial: [],
          lmd_materialConsumido: [],
          lmd_flujoDeArgon: [],

          hp_modoDeImpresion: [],
          hp_nivelesDeAgentes: [],
        }

        var numDatos = datos.datosHistorico.length;
        datos.datosHistorico.reverse().forEach(function (row) {
          row.fecha_date = this.myFunctions.sqlToJsDate(row.fecha);
          row.fecha_time = this.myFunctions.sqlToJsDate(row.fecha).getTime();

          this.datosGraficoMultieje.fechas.push(row.fecha_date);

          this.datosGraficoMultieje.fdm_puertaAbiertaCerrada.push(row.fdm_puertaAbiertaCerrada);
          this.datosGraficoMultieje.fdm_temperaturaHorno.push(row.fdm_temperaturaHorno);
          this.datosGraficoMultieje.fdm_dewPoint.push(row.fdm_dewPoint);
          this.datosGraficoMultieje.fdm_airFlow.push(row.fdm_airFlow);
          this.datosGraficoMultieje.fdm_posicionesEjes.push(row.fdm_posicionesEjes);
          this.datosGraficoMultieje.fdm_platinaAspiradora.push(row.fdm_platinaAspiradora);
          this.datosGraficoMultieje.fdm_temperaturaExtrusor.push(row.fdm_temperaturaExtrusor);
          this.datosGraficoMultieje.fdm_cartuchos.push(parseInt(row.fdm_cartuchos));
          this.datosGraficoMultieje.fdm_ligthTower.push(row.fdm_ligthTower);
          this.datosGraficoMultieje.fdm_VoltageUPS.push(row.fdm_VoltageUPS);

          this.datosGraficoMultieje.lpfb_nivelDePolvo.push(row.lpfb_nivelDePolvo);
          this.datosGraficoMultieje.lpfb_nivelDeO2.push(row.lpfb_nivelDeO2);
          this.datosGraficoMultieje.lpfb_temperaturaCamara.push(row.lpfb_temperaturaCamara);
          this.datosGraficoMultieje.lpfb_presionCamara.push(row.lpfb_presionCamara);
          this.datosGraficoMultieje.lpfb_horasFiltro.push(row.lpfb_horasFiltro);
          this.datosGraficoMultieje.lpfb_potenciaRecirculacion.push(row.lpfb_potenciaRecirculacion);
          this.datosGraficoMultieje.lpfb_presionEntradaCamara.push(row.lpfb_presionEntradaCamara);
          this.datosGraficoMultieje.lpfb_temperaturaElevador.push(row.lpfb_temperaturaElevador);
          this.datosGraficoMultieje.lpfb_temperaturaAguaHelada.push(row.lpfb_temperaturaAguaHelada);

          this.datosGraficoMultieje.lmd_potenciaDeLaser.push(row.lmd_potenciaDeLaser);
          this.datosGraficoMultieje.lmd_velocidadDelRobot.push(row.lmd_velocidadDelRobot);
          this.datosGraficoMultieje.lmd_flujoDeMaterial.push(row.lmd_flujoDeMaterial);
          this.datosGraficoMultieje.lmd_materialConsumido.push(row.lmd_materialConsumido);
          this.datosGraficoMultieje.lmd_flujoDeArgon.push(row.lmd_flujoDeArgon);

          this.datosGraficoMultieje.hp_modoDeImpresion.push(row.hp_modoDeImpresion);
          this.datosGraficoMultieje.hp_nivelesDeAgentes.push(row.hp_nivelesDeAgentes);

        }, this);

        if (this.tipoAditivo == 1) { //FDM
          this.listaTooltip = [
            { estaEnGrafico: 1, nombre: "fdm_temperaturaHorno", nombreTraducido: this.translateService.instant("fdm_temperaturaHorno"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[0], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_temperaturaHorno[numDatos - 1] },
            { estaEnGrafico: 2, nombre: "fdm_temperaturaExtrusor", nombreTraducido: this.translateService.instant("fdm_temperaturaExtrusor"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[1], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_temperaturaExtrusor[numDatos - 1] },

            { estaEnGrafico: 3, nombre: "fdm_dewPoint", nombreTraducido: this.translateService.instant("fdm_dewPoint"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[2], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_dewPoint[numDatos - 1] },
            { estaEnGrafico: 4, nombre: "fdm_airFlow", nombreTraducido: this.translateService.instant("fdm_airFlow"), tipoDato: "num", seleccionadoLeyenda: true, color: this.colores[3], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_airFlow[numDatos - 1] },
            { estaEnGrafico: -1, nombre: "fdm_VoltageUPS", nombreTraducido: this.translateService.instant("fdm_VoltageUPS"), tipoDato: "bit", seleccionadoLeyenda: true, color: this.colores[9], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_VoltageUPS[numDatos - 1] },

            { estaEnGrafico: -1, nombre: "fdm_ligthTower", nombreTraducido: this.translateService.instant("fdm_ligthTower"), tipoDato: "num", seleccionadoLeyenda: false, color: this.colores[4], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_ligthTower[numDatos - 1] },
            { estaEnGrafico: -1, nombre: "fdm_puertaAbiertaCerrada", nombreTraducido: this.translateService.instant("fdm_puertaAbiertaCerrada"), tipoDato: "bit", seleccionadoLeyenda: true, color: this.colores[5], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_puertaAbiertaCerrada[numDatos - 1] },
            { estaEnGrafico: -1, nombre: "fdm_posicionesEjes", nombreTraducido: this.translateService.instant("fdm_posicionesEjes"), tipoDato: "bit", seleccionadoLeyenda: false, color: this.colores[6], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_posicionesEjes[numDatos - 1] },
            { estaEnGrafico: -1, nombre: "fdm_platinaAspiradora", nombreTraducido: this.translateService.instant("fdm_platinaAspiradora"), tipoDato: "bit", seleccionadoLeyenda: false, color: this.colores[7], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_platinaAspiradora[numDatos - 1] },
            { estaEnGrafico: -1, nombre: "fdm_cartuchos", nombreTraducido: this.translateService.instant("fdm_cartuchos"), tipoDato: "bit", seleccionadoLeyenda: false, color: this.colores[8], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_cartuchos[numDatos - 1] },

            // { nombre: "fdm_VoltageUPS", nombreTraducido: this.translateService.instant("fdm_VoltageUPS"), tipoDato: "bit", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[9], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.fdm_VoltageUPS[numDatos - 1] },
          ];
        }
        if (this.tipoAditivo == 2) { //LPFB
          this.listaTooltip = [
            { nombre: "lpfb_nivelDePolvo", nombreTraducido: this.translateService.instant("lpfb_nivelDePolvo"), tipoDato: "num", estaEnGrafico: 1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_nivelDePolvo[numDatos - 1] },
            { nombre: "lpfb_nivelDeO2", nombreTraducido: this.translateService.instant("lpfb_nivelDeO2"), tipoDato: "num", estaEnGrafico: 2, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_nivelDeO2[numDatos - 1] },
            { nombre: "lpfb_temperaturaCamara", nombreTraducido: this.translateService.instant("lpfb_temperaturaCamara"), tipoDato: "num", estaEnGrafico: 3, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_temperaturaCamara[numDatos - 1] },
            { nombre: "lpfb_presionCamara", nombreTraducido: this.translateService.instant("lpfb_presionCamara"), tipoDato: "num", estaEnGrafico: 4, seleccionadoLeyenda: false, color: this.colores[3], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_presionCamara[numDatos - 1] },
            { nombre: "lpfb_potenciaRecirculacion", nombreTraducido: this.translateService.instant("lpfb_potenciaRecirculacion"), tipoDato: "num", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[4], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_potenciaRecirculacion[numDatos - 1] },
            { nombre: "lpfb_presionEntradaCamara", nombreTraducido: this.translateService.instant("lpfb_presionEntradaCamara"), tipoDato: "num", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[5], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_presionEntradaCamara[numDatos - 1] },
            { nombre: "lpfb_temperaturaElevador", nombreTraducido: this.translateService.instant("lpfb_temperaturaElevador"), tipoDato: "num", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[6], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_temperaturaElevador[numDatos - 1] },
            { nombre: "lpfb_temperaturaAguaHelada", nombreTraducido: this.translateService.instant("lpfb_temperaturaAguaHelada"), tipoDato: "num", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[7], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_temperaturaAguaHelada[numDatos - 1] },
            { nombre: "lpfb_horasFiltro", nombreTraducido: this.translateService.instant("lpfb_horasFiltro"), tipoDato: "num", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[8], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lpfb_horasFiltro[numDatos - 1] },
          ];
        }
        if (this.tipoAditivo == 3) { //LMD
          this.listaTooltip = [
            { nombre: "lmd_potenciaDeLaser", nombreTraducido: this.translateService.instant("lmd_potenciaDeLaser"), tipoDato: "num", estaEnGrafico: 1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lmd_potenciaDeLaser[numDatos - 1] },
            { nombre: "lmd_velocidadDelRobot", nombreTraducido: this.translateService.instant("lmd_velocidadDelRobot"), tipoDato: "num", estaEnGrafico: 2, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lmd_velocidadDelRobot[numDatos - 1] },
            { nombre: "lmd_flujoDeMaterial", nombreTraducido: this.translateService.instant("lmd_flujoDeMaterial"), tipoDato: "num", estaEnGrafico: 3, seleccionadoLeyenda: false, color: this.colores[2], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lmd_flujoDeMaterial[numDatos - 1] },
            { nombre: "lmd_materialConsumido", nombreTraducido: this.translateService.instant("lmd_materialConsumido"), tipoDato: "num", estaEnGrafico: 4, seleccionadoLeyenda: false, color: this.colores[3], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lmd_materialConsumido[numDatos - 1] },
            { nombre: "lmd_flujoDeArgon", nombreTraducido: this.translateService.instant("lmd_flujoDeArgon"), tipoDato: "num", estaEnGrafico: -1, seleccionadoLeyenda: false, color: this.colores[4], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.lmd_flujoDeArgon[numDatos - 1] },
          ];
        }
        if (this.tipoAditivo == 4) { //HP
          this.listaTooltip = [
            { nombre: "hp_nivelesDeAgentes", nombreTraducido: this.translateService.instant("hp_nivelesDeAgentes"), tipoDato: "num", estaEnGrafico: 1, seleccionadoLeyenda: false, color: this.colores[0], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.hp_nivelesDeAgentes[numDatos - 1] },
            { nombre: "hp_modoDeImpresion", nombreTraducido: this.translateService.instant("hp_modoDeImpresion"), tipoDato: "num", estaEnGrafico: 2, seleccionadoLeyenda: false, color: this.colores[1], unidad: "", valoresMomentaneo: [], dim: "", actual: this.datosGraficoMultieje.hp_modoDeImpresion[numDatos - 1] },

          ];
        }

        this.listaTooltipSeleccionados = this.listaTooltip.filter(x => x.estaEnGrafico != -1).map(x => x.nombre);

        this.mostrarGraficosPorLeyenda = [true, true, true, true, false, false, false, false, false, false,];

        this.fechaInicio = this.datosGraficoMultieje.fechas[0];
        this.fechaFin = this.datosGraficoMultieje.fechas[this.datosGraficoMultieje.fechas.length - 1];
        this.fechaInicioZoom = this.fechaInicio;
        this.fechaFinZoom = this.fechaFin;

        this.cargarGraficosTodos();

        this.loadingPestannas = false;

        this.primeraVez = false;

      }

    });
  }

  quitarAcentos(katea) {
    return katea.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  CargarIconosEstadoMaquina() {

    //var tipoProceso = this.quitarAcentos(this.procesosTipo.toLowerCase()); //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"
    var tipoProceso = this.quitarAcentos(this.donutDocentaje.toLowerCase()); //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    if (this.tiempoEst == 0) {
      this.estadoImg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      this.porcentaje = "";
    } else {
      var ahora = new Date(Date.parse(this.fechaServidor));//this.myFunctions.getDateNow();
      var fechaOrig = new Date(Date.parse(this.fechaIni));

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      var docentaje = this.calcularPorcentaje(segundosfiff, 12, this.tiempoEst, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, this.tiempoEst, 1, true);
      this.porcentaje = porcentaje + "%";
      this.estadoImg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";
    }

  }



  calcularTiempos() {
    if (this.fechaIni != null && this.fechaIni != undefined) {
      var ahora: any = this.fechaServidor;
      this.tiempo = (ahora - this.fechaIni) / 1000;
      this.tiempoCorregido = this.corregirFormato(this.tiempo);
      this.calcularDesviacion();
    }
  }

  refreshData() {
    if (this.router.url == '/procesosaditivo/' + this.idmaquina) {
      this.cargarTodosLosDatos();
    } else {
      clearInterval(this.interval);
    }
  }

  refreshDataTiempos() {
    if (this.router.url == '/procesosaditivo/' + this.idmaquina) {
      this.calcularTiempos();
    } else {
      clearInterval(this.interval2);
    }
  }

  RedirigirRendimiento() {
    this.router.navigate(["informerendimiento/" + this.idmaquina]);
  }

  //GRAFICOS
  aplicarZoomEnGrafico() {
    this.cargarGraficosTodos();
  }

  onClickMostrarTodos() {
    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;
    this.cargarGraficosTodos();
  }

  clickGridLeyenda(event) {
    var numeroDeCamposSeleccionados = this.listaTooltip.filter(x => x.estaEnGrafico != -1).length;
    if (event.deselectedRows.length > 0) {
      //SE HA DESELECCIONADO UN CAMPO
      var campo = event.deselectedRows[0].dataItem;
      campo.seleccionadoLeyenda = false;
      campo.estaEnGrafico = -1;
    }
    if (event.selectedRows.length > 0) {
      //SE HA SELECCIONADO UN CAMPO
      //SI YA HAY 4 ELEMENTOS SELECCIONADOS ECHAMOS PARA ATRAS LA SELECCION
      var campo = event.selectedRows[0].dataItem;
      if (numeroDeCamposSeleccionados == 4) {
        this.listaTooltipSeleccionados = this.listaTooltipSeleccionados.filter(x => x != campo.nombre);
      } else {
        campo.seleccionadoLeyenda = true;
        campo.estaEnGrafico = numeroDeCamposSeleccionados + 1;
      }
    }

    this.mostrarGraficosPorLeyenda = [false, false, false, false, false, false, false, false, false, false,];

    var indexGrafico = 1;
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico != -1) {
        campo.estaEnGrafico = indexGrafico;
        this.mostrarGraficosPorLeyenda[indexGrafico - 1] = true;
        indexGrafico++;
      }
    }, this);

    this.cargarGraficosTodos();

  }

  cargarGraficosTodos() {

    var fechas: any = [];
    var valores1: any = [];
    var valores2: any = [];
    var valores3: any = [];
    var valores4: any = [];
    var valores5: any = [];
    var valores6: any = [];
    var valores7: any = [];
    var valores8: any = [];
    var valores9: any = [];
    var valores10: any = [];

    var i;

    var estanEnGrafico: any = {};
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico == 1) estanEnGrafico[campo.nombre] = 1;
      if (campo.estaEnGrafico == 2) estanEnGrafico[campo.nombre] = 2;
      if (campo.estaEnGrafico == 3) estanEnGrafico[campo.nombre] = 3;
      if (campo.estaEnGrafico == 4) estanEnGrafico[campo.nombre] = 4;
      if (campo.estaEnGrafico == 5) estanEnGrafico[campo.nombre] = 5;
      if (campo.estaEnGrafico == 6) estanEnGrafico[campo.nombre] = 6;
      if (campo.estaEnGrafico == 7) estanEnGrafico[campo.nombre] = 7;
      if (campo.estaEnGrafico == 8) estanEnGrafico[campo.nombre] = 8;
      if (campo.estaEnGrafico == 9) estanEnGrafico[campo.nombre] = 9;
      if (campo.estaEnGrafico == 10) estanEnGrafico[campo.nombre] = 10;

      if (campo.estaEnGrafico == -1) estanEnGrafico[campo.nombre] = -1;
    }, this);

    for (i = 0; i < this.datosGraficoMultieje.fechas.length; i++) {
      if ((this.datosGraficoMultieje.fechas[i + 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] >= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i - 1] <= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i + 1] >= this.fechaFinZoom) ||
        (this.datosGraficoMultieje.fechas[i - 1] <= this.fechaInicioZoom && this.datosGraficoMultieje.fechas[i] >= this.fechaFinZoom)) {

        fechas.push(this.datosGraficoMultieje.fechas[i]);

        for (const key in this.datosGraficoMultieje) {
          if (key != "fechas") {
            if (estanEnGrafico[key] == 1) valores1.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 2) valores2.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 3) valores3.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 4) valores4.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 5) valores5.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 6) valores6.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 7) valores7.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 8) valores8.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 9) valores9.push(this.datosGraficoMultieje[key][i]);
            if (estanEnGrafico[key] == 10) valores10.push(this.datosGraficoMultieje[key][i]);
          }
        }

      }
    }

    if (fechas.length > 300) {
      fechas = this.aplanarArray(fechas, 300);
      valores1 = this.aplanarArray(valores1, 300);
      valores2 = this.aplanarArray(valores2, 300);
      valores3 = this.aplanarArray(valores3, 300);
      valores4 = this.aplanarArray(valores4, 300);
      valores5 = this.aplanarArray(valores5, 300);
      valores6 = this.aplanarArray(valores6, 300);
      valores7 = this.aplanarArray(valores7, 300);
      valores8 = this.aplanarArray(valores8, 300);
      valores9 = this.aplanarArray(valores9, 300);
      valores10 = this.aplanarArray(valores10, 300);
    }

    this.arrayFechas = fechas;

    //Tooltip
    this.listaTooltip.forEach(function (campo) {
      if (campo.estaEnGrafico == 1) campo.valoresMomentaneo = valores1;
      if (campo.estaEnGrafico == 2) campo.valoresMomentaneo = valores2;
      if (campo.estaEnGrafico == 3) campo.valoresMomentaneo = valores3;
      if (campo.estaEnGrafico == 4) campo.valoresMomentaneo = valores4;
      if (campo.estaEnGrafico == 5) campo.valoresMomentaneo = valores5;
      if (campo.estaEnGrafico == 6) campo.valoresMomentaneo = valores6;
      if (campo.estaEnGrafico == 7) campo.valoresMomentaneo = valores7;
      if (campo.estaEnGrafico == 8) campo.valoresMomentaneo = valores8;
      if (campo.estaEnGrafico == 9) campo.valoresMomentaneo = valores9;
      if (campo.estaEnGrafico == 10) campo.valoresMomentaneo = valores10;
    }, this);

    var c1: boolean = this.fechaInicioZoom < fechas[0];
    var c2: boolean = this.fechaFinZoom > fechas[fechas.length - 1];

    if (c1) fechas.unshift(this.fechaInicioZoom);
    fechas.unshift('x');
    if (c2) fechas.push(this.fechaFinZoom);

    if (c1) valores1.unshift(null);
    valores1.unshift('valor');
    if (c2) valores1.push(null);

    if (c1) valores2.unshift(null);
    valores2.unshift('valor');
    if (c2) valores2.push(null);

    if (c1) valores3.unshift(null);
    valores3.unshift('valor');
    if (c2) valores3.push(null);

    if (c1) valores4.unshift(null);
    valores4.unshift('valor');
    if (c2) valores4.push(null);

    if (c1) valores5.unshift(null);
    valores5.unshift('valor');
    if (c2) valores5.push(null);

    if (c1) valores6.unshift(null);
    valores6.unshift('valor');
    if (c2) valores6.push(null);

    if (c1) valores7.unshift(null);
    valores7.unshift('valor');
    if (c2) valores7.push(null);

    if (c1) valores8.unshift(null);
    valores8.unshift('valor');
    if (c2) valores8.push(null);

    if (c1) valores9.unshift(null);
    valores9.unshift('valor');
    if (c2) valores9.push(null);

    if (c1) valores10.unshift(null);
    valores10.unshift('valor');
    if (c2) valores10.push(null);

    //valores1
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 1)
    if (campoTooltip != undefined) {
      this.grafico1.load({ columns: [fechas, valores1] });
      this.grafico1.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico1_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico1_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico1_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico1.load({ columns: [['x'], ['valor']] });
    }
    //valores2
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 2)
    if (campoTooltip != undefined) {
      this.grafico2.load({ columns: [fechas, valores2] });
      this.grafico2.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico2_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico2_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico2_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico2.load({ columns: [['x'], ['valor']] });
    }
    //valores3
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 3)
    if (campoTooltip != undefined) {
      this.grafico3.load({ columns: [fechas, valores3] });
      this.grafico3.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico3_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico3_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico3_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico3.load({ columns: [['x'], ['valor']] });
    }
    //valores4
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 4)
    if (campoTooltip != undefined) {
      this.grafico4.load({ columns: [fechas, valores4] });
      this.grafico4.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico4_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico4_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico4_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico4.load({ columns: [['x'], ['valor']] });
    }

    //valores5
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 5)
    if (campoTooltip != undefined) {
      this.grafico5.load({ columns: [fechas, valores5] });
      this.grafico5.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico5_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico5_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico5_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico5.load({ columns: [['x'], ['valor']] });
    }

    //valores6
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 6)
    if (campoTooltip != undefined) {
      this.grafico6.load({ columns: [fechas, valores6] });
      this.grafico6.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico6_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico6_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico6_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico6.load({ columns: [['x'], ['valor']] });
    }

    //valores7
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 7)
    if (campoTooltip != undefined) {
      this.grafico7.load({ columns: [fechas, valores7] });
      this.grafico7.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico7_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico7_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico7_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico7.load({ columns: [['x'], ['valor']] });
    }

    //valores8
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 8)
    if (campoTooltip != undefined) {
      this.grafico8.load({ columns: [fechas, valores8] });
      this.grafico8.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico8_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico8_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico8_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico8.load({ columns: [['x'], ['valor']] });
    }

    //valores9
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 9)
    if (campoTooltip != undefined) {
      this.grafico9.load({ columns: [fechas, valores9] });
      this.grafico9.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico9_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico9_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico9_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico9.load({ columns: [['x'], ['valor']] });
    }

    //valores10
    var campoTooltip = this.listaTooltip.find(x => x.estaEnGrafico == 10)
    if (campoTooltip != undefined) {
      this.grafico10.load({ columns: [fechas, valores7] });
      this.grafico10.data.colors({ valor: d3.rgb(campoTooltip.color) });
      d3.selectAll('#grafico10_hmiAdi .c3-axis-y line').style("stroke", campoTooltip.color);
      d3.selectAll('#grafico10_hmiAdi .c3-axis-y tspan').style("fill", campoTooltip.color);
      d3.selectAll('#grafico10_hmiAdi .c3-axis-y path').style("stroke", campoTooltip.color);
    } else {
      this.grafico10.load({ columns: [['x'], ['valor']] });
    }

  }

  aplanarArray(data, aCuantosElementos) {
    //var aCuantosElementos = 1000;
    //var data = [15, 16, 9, 1, 84, 1, 6, 77];

    var resultado = [];
    if (data.length > 0) {
      resultado.push(data[0]);
      var i;
      for (i = 1; i < aCuantosElementos; i++) {
        resultado.push(data[Math.round((data.length / aCuantosElementos) * i)]);
      }
      resultado.push(data[data.length - 1]);
    }
    return resultado;
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }

    }
    if (porcentaje < 0)
      porcentaje = 0;

    return porcentaje;
  }
  //FIN GRAFICOS

  calcularDescviacionTiempo() {
    this.tiempoDesviacion = this.tiempoEst - this.tiempo
  }

  corregirFormato(tiempo: number) {
    // if (tiempo > 86400) {
    //   var dias = Math.floor(tiempo / 86400);
    //   var horas = this.pad(Math.floor(Math.floor(tiempo % 86400) / 3600));
    //   return dias + "d " + horas + "h"
    // } else {
      var hora = this.pad(Math.floor(tiempo / 3600));
      var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
      var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
      return hora + ":" + minutos + ":" + segundos;
    // }
  }

  calcularDesviacion() {
    var desv = this.tiempoEst - this.tiempo;
    if (desv > 0) {
      this.desviacion = "- " + this.corregirFormato(Math.abs(desv));
    } else {
      this.desviacion = "+ " + this.corregirFormato(Math.abs(desv));
    }
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  //#region MANTENIMIENTOS
  clickSwitchTiempo(event: any, dataitem: any, tipo: number) {
    this.loadingPorTiempo = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switch=true)
      this.hmiService.UNupdate_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switch=false)
      this.hmiService.update_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  clickSwitch(event: any, dataitem: any, tipo: number) {
    this.loadingPorFecha = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switch=true)
      this.hmiService.UNupdate_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switch=false)
      this.hmiService.update_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow();
      var finHoy = this.myFunctions.getDateNow();
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);
            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }

            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            //ANTES DE USAR EL CALCULO DEL PROXIMO MANTENIMIENTO, HAY QUE TRADUCIRLO A LA HORA DEL USUARIO!
            // hacer esto desde la DB es dificil xq se pasa dia y hora... no una fecha como tal.
            //ARATZ SIGUE AQUI
            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }

          }
          else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }

          if (row.idTipo == 1) {//diario
            tipo = "diario";
          }
          else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          }
          else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          }
          else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          } else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      })

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      })
    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }
  //#endregion

}
