import { Component } from '@angular/core';
//import { FormGroup, FormBuilder } from '@angular/forms';
import { InformeConsumoService, MaquinasService, UsuariosService, MenuService, InformeEjecucionesService, HmiService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { MyFunctions } from '@app/_helpers';
import * as c3 from 'c3';
import * as d3 from 'd3';
import * as moment from 'moment';

import { GroupResult, groupBy } from '@progress/kendo-data-query';

interface ItemTurno {
  nombre: string,
  id: number
}

@Component({
  selector: 'app-informe-consumo',
  templateUrl: './informeConsumo.component.html'
})

export class InformeConsumo {
  //#region VARIABLES
  user = this.userService.userValue;

  //public form: FormGroup;

  public jsonMaquinas: any;
  public selectedMaquina: number = -1;
  public turnosCombo: Array<ItemTurno> = [];
  public turnosComboSelected: string = "1, 2, 3";
  public turnos_placeHolder: string = "";
  public fechaInicio: Date;
  public fechaFin: Date;
  public fechaFinDisplay = "none";
  public filtroDiaSemana: boolean = true;

  public loadingPotenciaYAvance: boolean = true;
  public loadingVisorDeDatos: boolean = true;

  public ejes = [];
  public selectedEje: any;

  public chartDonutDistribucionTiempo;
  public dataDistribucionTiempo: JSON;

  private tiempoTransition: number = 0;

  public Fminimo: number = 0;
  public Fsuave: number = 250;
  public Fmedia: number = 500;
  public Falta: number = 1000;
  public Fmaximo: number = 2000;

  public Cminimo: number = 0;
  public Csuave: number = 25;
  public Cmedia: number = 50;
  public Calta: number = 100;
  public Cmaximo: number = 200;

  public Tminimo: number = 0;
  public Tmaximo: number = 100;

  public motor: string = "";

  public colorFeed1: string = "";
  public colorFeed2: string = "";
  public colorFeed3: string = "";
  public colorFeed4: string = "";
  public colorFeed5: string = "";

  public labelHoras1: string = "";
  public labelHoras2: string = "";
  public labelHoras3: string = "";
  public labelHoras4: string = "";
  public labelHoras5: string = "";
  public labelHoras6: string = "";
  public labelHoras7: string = "";
  public labelHoras8: string = "";
  public labelHoras9: string = "";

  public labelPotencia1: string = "";
  public labelDatoPotencia1: string = "";
  public labelDatoPotencia1Azul: string = "";
  public labelDatoPotencia1Verde: string = "";
  public labelDatoPotencia1Amarillo: string = "";
  public labelDatoPotencia1Rojo: string = "";
  public valorPotencia1Azul: number = 0;
  public valorPotencia1Verde: number = 0;
  public valorPotencia1Amarillo: number = 0;
  public valorPotencia1Rojo: number = 0;
  public labelPotencia2: string = "";
  public labelDatoPotencia2: string = "";
  public valorPotencia2Azul: number = 0;
  public valorPotencia2Verde: number = 0;
  public valorPotencia2Amarillo: number = 0;
  public valorPotencia2Rojo: number = 0;
  public labelPotencia3: string = "";
  public labelDatoPotencia3: string = "";
  public valorPotencia3Azul: number = 0;
  public valorPotencia3Verde: number = 0;
  public valorPotencia3Amarillo: number = 0;
  public valorPotencia3Rojo: number = 0;
  public labelPotencia4: string = "";
  public labelDatoPotencia4: string = "";
  public valorPotencia4Azul: number = 0;
  public valorPotencia4Verde: number = 0;
  public valorPotencia4Amarillo: number = 0;
  public valorPotencia4Rojo: number = 0;
  public labelPotencia5: string = "";
  public labelDatoPotencia5: string = "";
  public valorPotencia5Azul: number = 0;
  public valorPotencia5Verde: number = 0;
  public valorPotencia5Amarillo: number = 0;
  public valorPotencia5Rojo: number = 0;

  public leyenda: string = "";
  public colorAzul: number = 0;
  public leyendaAzul: string = "";
  public colorVerde: number = 0;
  public leyendaVerde: string = "";
  public colorAmarillo: number = 0;
  public leyendaAmarillo: string = "";
  public colorRojo: number = 0;
  public leyendaRojo: string = "";

  public chartDonutVisorDatos;


  public chartAvance: any;
  public chartTemp: any;
  public chartConsum: any;
  public chartPotens: any;
  public zoomRangeDia: any = [0, 3600, 7200, 10800, 14400, 18000, 21600, 25200, 28800, 32400, 36000, 39600, 43200, 46800, 50400, 54000, 57600, 61200, 64800, 68400, 72000, 75600, 79200, 82800, 86400];
  public zoomRangeSemana: any = [0, 28800, 57600, 86400, 115200, 144000, 172800, 201600, 230400, 259200, 288000, 316800, 345600, 374400, 403200, 432000, 460800, 489600, 518400, 547200, 576000, 604800];
  public zoomRange: any = this.zoomRangeDia
  public domainZoom: any;
  public tiempos: any;
  public subTiempos: any;
  public indexInicioGrafico: any;
  public indexFinalGrafico: any;
  public tIniZoom: any;
  public tFinZoom: any;

  public listaEjes: any;
  public listaEjes2: any;
  public subListaEjes: any;
  public listaLeyenda: any;

  public avancePanelDerecho: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-'];
  public tempPanelDerecho: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-'];
  public consumPanelDerecho: any = ['-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-', '-'];

  public poten1: boolean;
  public poten2: boolean;

  public timeOutZoom;

  public fechasTitulo: string = this.translateService.instant('fecha');

  public chartAvanceDisplay = true;
  public chartTempDisplay = true;
  public chartConsumDisplay = true;

  //PANEL MAQUINAS
  //private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];
  public aparecer = false;

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //#endregion

  //#region CARGA
  constructor(private informeConsumoService: InformeConsumoService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private menuService: MenuService,
    //private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private myFunctions: MyFunctions) {

    this.menuService.titulo = this.translateService.instant('informeConsumo').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.cargarGrupos();
    this.cargarAreasProductivas();

    this.cargarTurnosCombo();
    this.cargarFechas();

  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });
        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }
  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    // var maquinas_model = this.maquinasService.get_maquinas_model();
    // if (maquinas_model == false) {
    this.maquinasService.Get_Maquinas_Consumos().subscribe(json => {
      // this.maquinasService.set_maquinas_model(json);
      // this.maquinas = this.maquinasService.get_maquinas_model();
      this.maquinas = json;

      this.maquinas.forEach(element => {
        if (element.imagen == '') {
          switch (element.tipo_maquina) {
            case 1: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // mecanizado
            case 2: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // extrusora
            case 3: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // impresora
            case 4: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-07.png"; break; // inyectora
            case 5: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-06.png"; break; // horno
            case 9: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-05.png"; break; // aditivo
            case 11: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // puesto manual
            case 12: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-06.png"; break; // horno temple
          }
        }
      });

      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    })
    // }
    // else {
    //   this.maquinas = maquinas_model;
    //   this.seccionesCargadas = true;
    //   r1 = true;
    //   if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
    //   if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    // }

    //INSTALACIONES
    // var instalaciones_model = this.maquinasService.get_instalaciones_model();
    // if (instalaciones_model == false) {
    this.maquinasService.Get_Instalaciones_Consumos().subscribe(json => {
      this.maquinasService.set_instalaciones_model(json);
      this.instalaciones = this.maquinasService.get_instalaciones_model();
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    })
    // }
    // else {
    //   this.instalaciones = instalaciones_model;
    //   r2 = true;
    //   if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
    //   if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    // }
  }
  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }
    else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }
    else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0 && !(this.selectedMaquina > 0)) this.selectedMaquina = this.maquinasMostradas[0].id;
    //else this.selectedMaquina = undefined;

    //if (cargarDatos) this.identificarMaquinaYCargarDatos();

    if (cargarDatos) {
      this.cargarInforme();
      //this.cargarValidador();
    }
  }
  cargarInforme() {
    this.cargarEjes();
  }

  ngOnInit() {
    //this.form = this.formBuilder.group({
    //  idDb: this.user.idDb
    //});
  }

  private cargarTurnosCombo() {
    this.turnos_placeHolder = this.translateService.instant("seleccioneTurnos")
    this.turnosCombo = [
      { nombre: this.translateService.instant("manana"), id: 1 },
      { nombre: this.translateService.instant("tarde"), id: 2 },
      { nombre: this.translateService.instant("noche"), id: 3 }
    ];
  }

  private cargarFechas() {

    //FILTRO dia
    if (this.filtroDiaSemana) {
      var fechaIni;
      //calcular fecha inicio
      if (this.fechaInicio == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow()
      }

      //calcular fecha fin
      fechaIni = new Date(this.fechaInicio);
      this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 1));

      this.fechaInicio.setHours(0, 0, 0);
      this.fechaFin.setHours(0, 0, 0);
      //FILTRO semana
    } else {
      var fechaIni;
      //calcular fecha inicio
      if (this.fechaInicio == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow()
      }
      fechaIni = this.fechaInicio
      if (fechaIni.getDay() == 3) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
      } else if (fechaIni.getDay() == 4) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
      } else if (fechaIni.getDay() == 5) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
      } else if (fechaIni.getDay() == 6) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
      } else if (fechaIni.getDay() == 0) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
      } else if (fechaIni.getDay() == 2) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
      }
      //calcular fecha fin
      fechaIni = new Date(this.fechaInicio);
      this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 7));

      this.fechaInicio.setHours(0, 0, 0);
      this.fechaFin.setHours(0, 0, 0);
    }
  }

  public cargarEjes() {
    this.ejes = [];
    var primera: boolean = true;
    this.maquinasService.GetAll_Ejes(this.selectedMaquina).pipe(first()).subscribe((result: any) => {
      var an: any = result.data;
      an.forEach(f => {
        if (primera) {
          var eje = {
            activo: f.activo,
            consumo: f.consumo,
            feed: f.feed,
            id: f.id,
            motor: f.motor,
            nombre: f.nombre,
            orden: f.orden,
            posicion: f.posicion,
            principal: f.principal,
            temperatura: f.temperatura,
            visible: f.visible,
            selected: true
          };
          this.ejes.push(eje)

          primera = false;

          this.selectedEje = eje;
          this.cargarDatosGraficos();
        } else {
          var eje = {
            activo: f.activo,
            consumo: f.consumo,
            feed: f.feed,
            id: f.id,
            motor: f.motor,
            nombre: f.nombre,
            orden: f.orden,
            posicion: f.posicion,
            principal: f.principal,
            temperatura: f.temperatura,
            visible: f.visible,
            selected: false
          };
          this.ejes.push(eje)
        }
      });

      this.cargarDatosGraficos();
    });
  }

  public cargarDatosGraficos() {

    this.loadingPotenciaYAvance = true;
    this.loadingVisorDeDatos = true;

    var colores: boolean = false;
    var rangosEjes: boolean = false;

    this.informeConsumoService.get_colores().subscribe((result: any) => {
      colores = true;

      this.colorFeed1 = result.data[0].colorFeed1;
      this.colorFeed2 = result.data[0].colorFeed2;
      this.colorFeed3 = result.data[0].colorFeed3;
      this.colorFeed4 = result.data[0].colorFeed4;
      this.colorFeed5 = result.data[0].colorFeed5;

      this.colorAzul = result.data[0].colorConsumo1;
      this.colorVerde = result.data[0].colorConsumo2;
      this.colorAmarillo = result.data[0].colorConsumo3;
      this.colorRojo = result.data[0].colorConsumo4;

      if (colores && rangosEjes) {
        this.cargarGraficos();
      } else {
        this.loadingPotenciaYAvance = false;
        this.loadingVisorDeDatos = false;
      }
    })


    this.informeConsumoService.get_rangosEjes(this.selectedEje.id).subscribe((result: any) => {
      rangosEjes = true;

      this.Fminimo = result.data.value.table1[0].minimo;
      this.Fsuave = result.data.value.table1[0].cambioColor1;
      this.Fmedia = result.data.value.table1[0].cambioColor2;
      this.Falta = result.data.value.table1[0].cambioColor3;
      this.Fmaximo = result.data.value.table1[0].maximo;

      this.Cminimo = result.data.value.table2[0].minimo;
      this.Csuave = result.data.value.table2[0].cambioColor1;
      this.Cmedia = result.data.value.table2[0].cambioColor2;
      this.Calta = result.data.value.table2[0].cambioColor3;
      this.Cmaximo = result.data.value.table2[0].maximo;

      this.Tminimo = result.data.value.table3[0].minimo;
      this.Tmaximo = result.data.value.table3[0].maximo;

      if (result.data.value.table4[0].motor) {
        this.motor = "r.p.m.";
      } else {
        this.motor = "mm/min";
      }

      if (colores && rangosEjes) {
        this.cargarGraficos();
      } else {
        this.loadingPotenciaYAvance = false;
        this.loadingVisorDeDatos = false;
      }
    })
  }

  public cargarGraficos() {

    if (this.selectedEje.motor) { var unidadAvan = "r.p.m"; var nombreAvan = this.translateService.instant('velocidad'); }
    else { var unidadAvan = "mm/min"; var nombreAvan = this.translateService.instant('avance'); }

    var th = this;
    //#region DISTRIBUCION TIEMPO
    this.chartDonutDistribucionTiempo = c3.generate({
      bindto: '#chartDonutDistribucionTiempo',
      data: {
        columns: [
          ['campo1', 0],
          ['campo2', 0],
          ['campo3', 0],
          ['campo4', 0],
          ['campo5', 0]
        ],
        names: {
          campo1: nombreAvan + ' = ' + this.Fminimo + ' ' + this.motor,
          campo2: this.Fminimo + " < " + unidadAvan + " <= " + this.Fsuave + " " + this.motor,
          campo3: this.Fsuave + " < " + unidadAvan + " <= " + this.Fmedia + " " + this.motor,
          campo4: this.Fmedia + " < " + unidadAvan + " <= " + this.Falta + " " + this.motor,
          campo5: this.Falta + " " + this.motor + " < " + unidadAvan
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutDistribucionTiempo .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutDistribucionTiempo .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutDistribucionTiempo .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: [
          this.colorFeed1,
          this.colorFeed2,
          this.colorFeed3,
          this.colorFeed4,
          this.colorFeed5
        ]
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#chartDonutDistribucionTiempo .c3-chart-arcs path").style("stroke-width", "0px");

    this.listaLeyenda = [];
    this.listaLeyenda.push({
      index: 0,
      titulo: nombreAvan,
      color: "#2dabc1",
      seleccionado: true,
    });
    this.listaLeyenda.push({
      index: 1,
      titulo: this.translateService.instant('temperatura'),
      color: "#8AC24E",
      seleccionado: true,
    });
    this.listaLeyenda.push({
      index: 2,
      titulo: this.translateService.instant('consumo'),
      color: "#F1AC4A",
      seleccionado: true,
    });
    //#endregion

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio)
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)
    var now = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow())

    this.informeConsumoService.get_distribucionTiempo(this.selectedMaquina, this.selectedEje.id, fechaInicio, fechaFin, now, this.turnosComboSelected,
      this.Fminimo, this.Fsuave, this.Fmedia, this.Falta, this.Fmaximo, this.Cminimo, this.Csuave, this.Cmedia, this.Calta, this.Cmaximo
    ).pipe(first()).subscribe((result: any) => {

      this.loadingPotenciaYAvance = false;

      var contParadaAzul = 0;
      var contParadaVerde = 0;
      var contParadaAmarillo = 0;
      var contParadaRojo = 0;
      var contBajaAzul = 0;
      var contBajaVerde = 0;
      var contBajaAmarillo = 0;
      var contBajaRojo = 0;
      var contMediaAzul = 0;
      var contMediaVerde = 0;
      var contMediaAmarillo = 0;
      var contMediaRojo = 0;
      var contAltaAzul = 0;
      var contAltaVerde = 0;
      var contAltaAmarillo = 0;
      var contAltaRojo = 0;
      var contMaximaAzul = 0;
      var contMaximaVerde = 0;
      var contMaximaAmarillo = 0;
      var contMaximaRojo = 0;

      if (Object.keys(result).length > 0) {
        var an: any = result;

        contParadaAzul = an.data[0].valor_RangoAvance1_RangoPotencia1;
        contParadaVerde = an.data[0].valor_RangoAvance1_RangoPotencia2;
        contParadaAmarillo = an.data[0].valor_RangoAvance1_RangoPotencia3;
        contParadaRojo = an.data[0].valor_RangoAvance1_RangoPotencia4;
        contBajaAzul = an.data[0].valor_RangoAvance2_RangoPotencia1;
        contBajaVerde = an.data[0].valor_RangoAvance2_RangoPotencia2;
        contBajaAmarillo = an.data[0].valor_RangoAvance2_RangoPotencia3;
        contBajaRojo = an.data[0].valor_RangoAvance2_RangoPotencia4;
        contMediaAzul = an.data[0].valor_RangoAvance3_RangoPotencia1;
        contMediaVerde = an.data[0].valor_RangoAvance3_RangoPotencia2;
        contMediaAmarillo = an.data[0].valor_RangoAvance3_RangoPotencia3;
        contMediaRojo = an.data[0].valor_RangoAvance3_RangoPotencia4;
        contAltaAzul = an.data[0].valor_RangoAvance4_RangoPotencia1;
        contAltaVerde = an.data[0].valor_RangoAvance4_RangoPotencia2;
        contAltaAmarillo = an.data[0].valor_RangoAvance4_RangoPotencia3;
        contAltaRojo = an.data[0].valor_RangoAvance4_RangoPotencia4;
        contMaximaAzul = an.data[0].valor_RangoAvance5_RangoPotencia1;
        contMaximaVerde = an.data[0].valor_RangoAvance5_RangoPotencia2;
        contMaximaAmarillo = an.data[0].valor_RangoAvance5_RangoPotencia3;
        contMaximaRojo = an.data[0].valor_RangoAvance5_RangoPotencia4;

      }

      var parada = contParadaAzul + contParadaVerde + contParadaAmarillo + contParadaRojo;
      var suave = contBajaAzul + contBajaVerde + contBajaVerde + contBajaAmarillo + contBajaRojo;
      var media = contMediaAzul + contMediaVerde + contMediaAmarillo + contMediaRojo;
      var alta = contAltaAzul + contAltaVerde + contAltaAmarillo + contAltaRojo;
      var maxima = contMaximaAzul + contMaximaVerde + contMaximaAmarillo + contMaximaRojo;
      var contTotal = parada + suave + media + alta + maxima;

      //DISTRIBUCION DE TIEMPO
      if (this.selectedEje.motor) { var unidadAvan = "r.p.m"; var nombreAvan = this.translateService.instant('velocidad'); }
      else { var unidadAvan = "mm/min"; var nombreAvan = this.translateService.instant('avance'); }

      var data = {
        columns: [
          ['campo1', parada],
          ['campo2', suave],
          ['campo3', media],
          ['campo4', alta],
          ['campo5', maxima],
        ],

        names: {
          campo1: nombreAvan + ' = ' + this.Fminimo + ' ' + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(parada) + ')',
          campo2: this.Fminimo + " < " + nombreAvan + " <= " + this.Fsuave + " " + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(suave) + ')',
          campo3: this.Fsuave + " < " + nombreAvan + " <= " + this.Fmedia + " " + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(media) + ')',
          campo4: this.Fmedia + " < " + nombreAvan + " <= " + this.Falta + " " + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(alta) + ')',
          campo5: this.Falta + " " + this.motor + " < " + nombreAvan + ' (' + this.myFunctions.secondsTo_HH_MM_SS(maxima) + ')'
        }
      }

      this.chartDonutDistribucionTiempo.load(data);

      //POTENCIA CONSMIDA EN FUNCION DE RPM
      var maximoHoras = Math.round(Number(contTotal) / 3600);
      while (maximoHoras % 9 != 0) {
        maximoHoras = maximoHoras + 1;
      }

      this.labelHoras9 = (maximoHoras).toString();
      this.labelHoras8 = (maximoHoras - (maximoHoras / 9)).toString();
      this.labelHoras7 = (maximoHoras - (2 * (maximoHoras / 9))).toString();
      this.labelHoras6 = (maximoHoras - (3 * (maximoHoras / 9))).toString();
      this.labelHoras5 = (maximoHoras - (4 * (maximoHoras / 9))).toString();
      this.labelHoras4 = (maximoHoras - (6 * (maximoHoras / 9))).toString();
      this.labelHoras3 = (maximoHoras - (7 * (maximoHoras / 9))).toString();
      this.labelHoras2 = (maximoHoras - (8 * (maximoHoras / 9))).toString();
      this.labelHoras1 = (0).toString();

      this.leyendaAzul = this.Cminimo + " <= kW < " + this.Csuave;
      this.leyendaVerde = this.Csuave + " <= kW < " + this.Cmedia;
      this.leyendaAmarillo = this.Cmedia + " <= kW < " + this.Calta;
      this.leyendaRojo = this.Calta + " <= kW ";

      this.valorPotencia1Azul = this.calcularPorcentaje(contParadaAzul, 100, contTotal, 2, true);
      this.valorPotencia1Verde = this.calcularPorcentaje(contParadaVerde, 100, contTotal, 2, true);
      this.valorPotencia1Amarillo = this.calcularPorcentaje(contParadaAmarillo, 100, contTotal, 2, true);
      this.valorPotencia1Rojo = this.calcularPorcentaje(contParadaRojo, 100, contTotal, 2, true);

      this.labelPotencia1 = nombreAvan + ' = ' + this.Fminimo + ' ' + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(parada) + ')'

      this.valorPotencia2Azul = this.calcularPorcentaje(contBajaAzul, 100, contTotal, 2, true);
      this.valorPotencia2Verde = this.calcularPorcentaje(contBajaVerde, 100, contTotal, 2, true);
      this.valorPotencia2Amarillo = this.calcularPorcentaje(contBajaAmarillo, 100, contTotal, 2, true);
      this.valorPotencia2Rojo = this.calcularPorcentaje(contBajaRojo, 100, contTotal, 2, true);

      this.labelPotencia2 = this.Fminimo + " < " + nombreAvan + " <= " + this.Fsuave + " " + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(suave) + ')'

      this.valorPotencia3Azul = this.calcularPorcentaje(contMediaAzul, 100, contTotal, 2, true);
      this.valorPotencia3Verde = this.calcularPorcentaje(contMediaVerde, 100, contTotal, 2, true);
      this.valorPotencia3Amarillo = this.calcularPorcentaje(contMediaAmarillo, 100, contTotal, 2, true);
      this.valorPotencia3Rojo = this.calcularPorcentaje(contMediaRojo, 100, contTotal, 2, true);

      this.labelPotencia3 = this.Fsuave + " < " + nombreAvan + " <= " + this.Fmedia + " " + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(media) + ')'

      this.valorPotencia4Azul = this.calcularPorcentaje(contAltaAzul, 100, contTotal, 2, true);
      this.valorPotencia4Verde = this.calcularPorcentaje(contAltaVerde, 100, contTotal, 2, true);
      this.valorPotencia4Amarillo = this.calcularPorcentaje(contAltaAmarillo, 100, contTotal, 2, true);
      this.valorPotencia4Rojo = this.calcularPorcentaje(contAltaRojo, 100, contTotal, 2, true);

      this.labelPotencia4 = this.Fmedia + " < " + nombreAvan + " <= " + this.Falta + " " + this.motor + ' (' + this.myFunctions.secondsTo_HH_MM_SS(alta) + ')'

      this.valorPotencia5Azul = this.calcularPorcentaje(contMaximaAzul, 100, contTotal, 2, true);
      this.valorPotencia5Verde = this.calcularPorcentaje(contMaximaVerde, 100, contTotal, 2, true);
      this.valorPotencia5Amarillo = this.calcularPorcentaje(contMaximaAmarillo, 100, contTotal, 2, true);
      this.valorPotencia5Rojo = this.calcularPorcentaje(contMaximaRojo, 100, contTotal, 2, true);

      this.labelPotencia5 = this.Falta + " " + this.motor + " < " + nombreAvan + ' (' + this.myFunctions.secondsTo_HH_MM_SS(maxima) + ')'

    })

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio)
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)
    var now = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow())

    this.informeConsumoService.get_visorDatos(this.selectedMaquina, this.selectedEje.id, fechaInicio, fechaFin, now, this.turnosComboSelected).pipe(first()).subscribe((result: any) => {

      this.loadingVisorDeDatos = false;

      var fechas = ["x"];
      var avances = ["data1"];
      var temperaturas = ["data1"];
      var consumos = ["data1"];
      var an: any = result.data;
      an.forEach(f => {
        fechas.push(f.segundos);
        avances.push(f.feed);
        temperaturas.push(f.temperatura);
        consumos.push(f.consumo);
      })

      //AVANCE
      var chartAvance = c3.generate({
        padding: {
          left: 90,
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            fechas,
            avances,
          ],
          names: {
            data1: this.translateService.instant('avance'),
          },
          order: null
        },
        color: {
          pattern: [
            "#2dabc1"
          ]
        },
        transition: {
          duration: 0
        },
        axis: {
          x: {
            tick: {
              values: this.zoomRange,
              format: function (x) {
                var segs;
                if (x < 0) segs = -(x % 86400);
                else segs = (x % 86400);

                var sec_num = parseInt(segs, 10);
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                var hs, ms, ss;

                if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
                if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
                if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

                if (x < 0) return '-' + hs + ':' + ms;
                else return hs + ':' + ms;

              }
            }, padding: 0,
            min: 0,
            max: (this.fechaFin.getTime() - this.fechaInicio.getTime()) / 1000,
          }
        },
        point: {
          show: false
        },
        zoom: {
          enabled: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var $$ = this, config = $$.config,
              titleFormat = config.tooltip_format_title || defaultTitleFormat,
              text, title;

            title = titleFormat ? titleFormat(d[0].x) : d[0].x;
            text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");

            var index = d[0].index;
            var avan = th.chartAvance.data()[0].values[index].value;
            var temp = th.chartTemp.data()[0].values[index].value;
            var consum = th.chartConsum.data()[0].values[index].value;

            th.listaLeyenda.forEach(function (row, indice, array) {
              if (row.seleccionado) {

                var valorParaEnseñar = "";
                if (row.titulo == th.translateService.instant('consumo')) valorParaEnseñar = consum + " Kw";
                if (row.titulo == th.translateService.instant('velocidad')) valorParaEnseñar = avan + " r.p.m.";
                if (row.titulo == th.translateService.instant('avance')) valorParaEnseñar = avan + " mm/min";
                if (row.titulo == th.translateService.instant('rpm')) valorParaEnseñar = avan + " mm/min";
                if (row.titulo == th.translateService.instant('temperatura')) valorParaEnseñar = temp + "º";

                text += "<tr>";
                text += "<td class='name'><span style='background-color:" + row.color + "'></span>" + row.titulo + "</td>";
                text += "<td class='value'>" + valorParaEnseñar + "</td>";
                text += "</tr>";
              }
            });
            return text + "</table>";
          }
        },
        legend: {
          show: false
        },
        bindto: '#chartAvance'
      });

      //TEMPERATURA
      var chartTemp = c3.generate({
        padding: {
          left: 90,
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            fechas,
            temperaturas,
          ],
          names: {
            data1: this.translateService.instant('temperatura'),
          },
          order: null
        },
        color: {
          pattern: [
            "#8AC24E"
          ]
        },
        transition: {
          duration: 0
        },
        axis: {
          x: {
            tick: {
              values: this.zoomRange,
              format: function (x) {
                var segs;
                if (x < 0) segs = -(x % 86400);
                else segs = (x % 86400);

                var sec_num = parseInt(segs, 10);
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                var hs, ms, ss;

                if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
                if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
                if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

                if (x < 0) return '-' + hs + ':' + ms;
                else return hs + ':' + ms;
              }
            }, padding: 0,
            min: 0,
            max: (this.fechaFin.getTime() - this.fechaInicio.getTime()) / 1000
          }
        },
        point: {
          show: false
        },
        zoom: {
          enabled: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var $$ = this, config = $$.config,
              titleFormat = config.tooltip_format_title || defaultTitleFormat,
              text, title;

            title = titleFormat ? titleFormat(d[0].x) : d[0].x;
            text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");

            var index = d[0].index;
            var avan = th.chartAvance.data()[0].values[index].value;
            var temp = th.chartTemp.data()[0].values[index].value;
            var consum = th.chartConsum.data()[0].values[index].value;

            th.listaLeyenda.forEach(function (row, indice, array) {
              if (row.seleccionado) {

                var valorParaEnseñar = "";
                if (row.titulo == th.translateService.instant('consumo')) valorParaEnseñar = consum + " Kw";
                if (row.titulo == th.translateService.instant('velocidad')) valorParaEnseñar = avan + " r.p.m.";
                if (row.titulo == th.translateService.instant('avance')) valorParaEnseñar = avan + " mm/min";
                if (row.titulo == th.translateService.instant('rpm')) valorParaEnseñar = avan + " mm/min";
                if (row.titulo == th.translateService.instant('temperatura')) valorParaEnseñar = temp + "º";

                text += "<tr>";
                text += "<td class='name'><span style='background-color:" + row.color + "'></span>" + row.titulo + "</td>";
                text += "<td class='value'>" + valorParaEnseñar + "</td>";
                text += "</tr>";
              }
            });
            return text + "</table>";
          }
        },
        legend: {
          show: false
        },
        bindto: '#chartTemp'
      });

      //CONSUMO 
      var chartConsum = c3.generate({
        padding: {
          left: 90,
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            fechas,
            consumos,
          ],
          names: {
            data1: this.translateService.instant('consumo'),
          },
          order: null,
        },
        color: {
          pattern: [
            "#F1AC4A"
          ]
        },
        transition: {
          duration: 0
        },
        axis: {
          x: {
            tick: {
              values: this.zoomRange,
              format: function (x) {
                var segs;
                if (x < 0) segs = -(x % 86400);
                else segs = (x % 86400);

                var sec_num = parseInt(segs, 10);
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                var hs, ms, ss;

                if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
                if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
                if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

                if (x < 0) return '-' + hs + ':' + ms;
                else return hs + ':' + ms;
              }
            }, padding: 0,
            min: 0,
            max: (this.fechaFin.getTime() - this.fechaInicio.getTime()) / 1000
          }
        },
        point: {
          show: false
        },
        zoom: {
          enabled: true,
          type: 'drag',
          onzoomstart: function (event) {
            chartAvance.zoom(th.domainZoom);
            chartTemp.zoom(th.domainZoom);
            chartConsum.zoom(th.domainZoom);
          },
          onzoomend: function (domain) {
            th.domainZoom = domain;
            chartAvance.zoom(domain);
            chartTemp.zoom(domain);
            chartConsum.zoom(domain);
          }
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var $$ = this, config = $$.config,
              titleFormat = config.tooltip_format_title || defaultTitleFormat,
              text, title;

            title = titleFormat ? titleFormat(d[0].x) : d[0].x;
            text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");

            var index = d[0].index;
            var avan = th.chartAvance.data()[0].values[index].value;
            var temp = th.chartTemp.data()[0].values[index].value;
            var consum = th.chartConsum.data()[0].values[index].value;

            th.listaLeyenda.forEach(function (row, indice, array) {
              if (row.seleccionado) {

                var valorParaEnseñar = "";
                if (row.titulo == th.translateService.instant('consumo')) valorParaEnseñar = consum + " Kw";
                if (row.titulo == th.translateService.instant('velocidad')) valorParaEnseñar = avan + " r.p.m.";
                if (row.titulo == th.translateService.instant('avance')) valorParaEnseñar = avan + " mm/min";
                if (row.titulo == th.translateService.instant('rpm')) valorParaEnseñar = avan + " mm/min";
                if (row.titulo == th.translateService.instant('temperatura')) valorParaEnseñar = temp + "º";

                text += "<tr>";
                text += "<td class='name'><span style='background-color:" + row.color + "'></span>" + row.titulo + "</td>";
                text += "<td class='value'>" + valorParaEnseñar + "</td>";
                text += "</tr>";
              }
            });
            return text + "</table>";
          }
        },
        legend: {
          show: false
        },
        bindto: '#chartConsum'
      });

      this.domainZoom = [this.zoomRange[0], this.zoomRange[this.zoomRange.length - 1]];

      //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
      let list_of_children = d3.select('#chartConsum svg').node().childNodes;
      list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

      d3.select('#chartConsum .c3-event-rect')._groups[0][0].onmousedown = function (e) {
        if (e.button != 0) {
          var evt = new MouseEvent("mousedown", {
            altKey: e.altKey,
            bubbles: e.bubbles,
            button: 0,
            buttons: e.buttons,
            cancelable: e.cancelable,
            clientX: e.clientX,
            clientY: e.clientY,
            composed: e.composed,
            ctrlKey: e.ctrlKey,
            detail: e.detail,
            metaKey: e.metaKey,
            movementX: e.movementX,
            movementY: e.movementY,
            relatedTarget: e.relatedTarget,
            screenX: e.screenX,
            screenY: e.screenY,
            shiftKey: e.shiftKey,
            view: e.view
          });
          //var targetelement: any = e;
          //targetelement.path[0].dispatchEvent(evt);
          d3.select("#chartConsum .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
        }
      }
      d3.select('#chartTemp .c3-event-rect')._groups[0][0].onmousedown = d3.select('#chartConsum .c3-event-rect')._groups[0][0].onmousedown;
      d3.select('#chartAvance .c3-event-rect')._groups[0][0].onmousedown = d3.select('#chartConsum .c3-event-rect')._groups[0][0].onmousedown;

      chartAvance.zoom(this.domainZoom);
      chartTemp.zoom(this.domainZoom);
      chartConsum.zoom(this.domainZoom);

      this.chartAvance = chartAvance;
      this.chartTemp = chartTemp;
      this.chartConsum = chartConsum;

    })
  }

  onMostrarTodosZoomClick() {
    this.domainZoom = [this.zoomRange[0], this.zoomRange[this.zoomRange.length - 1]]
    this.chartAvance.zoom(this.domainZoom);
    this.chartTemp.zoom(this.domainZoom);
    this.chartConsum.zoom(this.domainZoom);
  }
  //#endregion

  //#region maquinaClicked
  private maquinaClicked(idMaquina, sender) {
    document.getElementsByClassName("actual")[0].classList.toggle("actual");
    if (sender.path[0].toString().includes("object HTMLDivElement")) {
      sender.path[0].setAttribute("class", 'clickable actual');
    } else {
      sender.path[1].setAttribute("class", 'clickable actual');
    }
    this.selectedMaquina = idMaquina;

    this.cargarEjes();
  }
  //#endregion

  //#region selectedChange_eje
  public selectedChange_eje(e, eje) {
    this.ejes.forEach(function (row) {
      if (row.nombre == eje.nombre) row.selected = true;
      else row.selected = false;
    }, this);
    if (e) {
      this.selectedEje = eje;
      this.cargarDatosGraficos();
    }
  }
  //#endregion

  //#region btnLeyenda

  public btnLeyenda(leyenda) {
    this.listaLeyenda[leyenda.index].seleccionado = !this.listaLeyenda[leyenda.index].seleccionado;

    switch (leyenda.index) {
      case 0: {
        if (this.listaLeyenda[0].seleccionado) {
          this.chartAvance.show('data1');
          this.chartAvanceDisplay = true;
          this.chartAvance.zoom(this.domainZoom);
          this.chartTemp.zoom(this.domainZoom);
          this.chartConsum.zoom(this.domainZoom);
        } else {
          this.chartAvance.hide('data1');
          this.chartAvanceDisplay = false;
        }
        break;
      }
      case 1: {
        if (this.listaLeyenda[1].seleccionado) {
          this.chartTemp.show('data1');
          this.chartTempDisplay = true;
          this.chartAvance.zoom(this.domainZoom);
          this.chartTemp.zoom(this.domainZoom);
          this.chartConsum.zoom(this.domainZoom);
        } else {
          this.chartTemp.hide('data1');
          this.chartTempDisplay = false;
        }
        break;
      }
      case 2: {
        if (this.listaLeyenda[2].seleccionado) {
          this.chartConsum.show('data1');
          this.chartConsumDisplay = true;
          this.chartAvance.zoom(this.domainZoom);
          this.chartTemp.zoom(this.domainZoom);
          this.chartConsum.zoom(this.domainZoom);
        } else {
          this.chartConsum.hide('data1');
          this.chartConsumDisplay = false;
        }
        break;
      }
    }
  }
  //#endregion

  //#region TURNOS
  public turnos_onValueChange(value: any): void {
    this.turnosComboSelected = "";

    for (let turno of value) {
      this.turnosComboSelected += turno.id + ", ";
    }

    this.turnosComboSelected = this.turnosComboSelected.substring(0, this.turnosComboSelected.length - 2);

    if (this.turnosComboSelected == "") {
      this.turnosComboSelected = "1, 2, 3";
    }

    this.cargarDatosGraficos();
  }
  //#endregion

  //#region FECHA
  public fechaInicio_dateChanged(value: Date): void {
    this.cargarFechas();
    this.cargarDatosGraficos();
  }

  public fechaFin_dateChanged(value: Date): void {
  }
  //#endregion

  //#region FILTRO DIS/SEMANA
  public btnDia() {
    this.filtroDiaSemana = true;
    this.fechasTitulo = this.translateService.instant('fecha')
    this.fechaFinDisplay = "none";
    this.zoomRange = this.zoomRangeDia;
    this.cargarFechas();

    this.cargarDatosGraficos();
  }

  public btnSemana() {
    this.filtroDiaSemana = false;
    this.fechasTitulo = this.translateService.instant('fechas')
    this.fechaFinDisplay = "block";
    this.zoomRange = this.zoomRangeSemana;
    this.cargarFechas();

    this.cargarDatosGraficos();
  }
  //#endregion

  //#region CALCULAR PORCENTAJE
  public calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean): number {
    var porcentaje: number = 0;

    if (de != 0) {
      var d: number = 10 ^ decimales;
      var porcen: number = 0
      porcen = valor * sobre * d / de
      if (porcen > sobre * d && limitado) {
        porcen = sobre * d
      }
      porcentaje = porcen / d
    } else {
      if (valor > 0 && limitado) {
        porcentaje = sobre
      } else if (!limitado) {
        porcentaje = -1
      }
    }

    if (porcentaje < 0) {
      porcentaje = 0
    }

    return porcentaje;
  }
  //#endregion

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

}
