import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CentroMecanizadoService, MenuService, UsuariosService, HmiService, MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { RowClassArgs } from '@progress/kendo-angular-grid';

@Component({ templateUrl: 'hmiImpresoras.html' })

export class HMIImpresorasComponent implements OnInit {

  interval: any;
  interval2: any;

  idmaquina: any;
  maquina: any;
  maquinasModel: any;
  marcasModel: any;

  //GRAFICOS
  graficoSecadorTempBcd: any;
  graficoSecadorTunelTempBcd: any;
  graficoDesbobinadora: any;
  graficoTomaDeAlimentacion: any;
  graficoAccionamientoRodillo: any;
  graficoBobinadora: any;
  graficoKwhTotales: any;
  graficoPotenciaInstantanea: any;
  graficoResumenSemana: any;

  labelDesbobinadora: any = "";
  labelTomaDeAlimentacion: any = "";
  labelAccionamientoRodillo: any = "";
  labelBobinadora: any = "";
  labelKwhTotales: any = "";
  labelPotenciaInstantanea: any = "";

  //ENSEÑAR RESUMEN SEMANA
  ensennarResumenSemana: boolean = true;

  //RANGOS
  rangoDesbobinadora: any;
  rangoTomaDeAlimentacion: any;
  rangoAccionadorRodilloEnfiamiento: any;
  rangoBobinadora: any;
  rangoSecadorTempBCD: any;
  rangoSecadorTunelTempBCD: any;
  rangoKwhTotales: any;
  rangoPotenciaInstantanea: any;

  //CSS pestaña
  pestannaColor: any = "";

  //LABEL m/min
  speed: any = "";

  //LABELS OF
  of: any = "";
  cliente: any = "";
  pieza: any = "";
  parte: any = "";
  operacion: any = "";
  nSeriePieza: any = "";
  realizadas: any = "";

  //TABS
  //Procesos
  historicoProcesos: any;
  alarmas: any;
  planificados: any;

  //Mantenimientos
  mantenimientoPorFecha: any;
  mantenimientoPorTiempo: any;
  loadingPorFecha: any;
  loadingPorTiempo: any;

  //Notas
  notas: any;

  user = this.usuariosService.userValue;

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions) {


    this.interval = setInterval(() => {
      this.refreshData();
    }, 5000);

    this.interval2 = setInterval(() => {
      this.refreshDataPestañas();
    }, 300000);

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('impresorasPlastico');
    this.idmaquina = parseInt(this.route.snapshot.paramMap.get("id"));
    this.maquina = {
      imagenBase64: "",
      marca: { imagenBase64: "" },
      css: "AAAAAAAAAAA",
      ejecuciones: [{ refOf: "", cliente: "", pieza: "", parte: "", operacion: "", realizadas: "", repetidas: "", procesos_Tipo: "", descripcion: "" }],
      tiempoReal: [],
    };

    this.cargarMaquinasModel();
  }

  cargarMaquinasModel() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinasModel = this.maquinasService.get_maquinas_model();
        r1 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.maquinasModel = maquinas_model;
      r1 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }

    //MARCAS
    var marcas_model = this.maquinasService.get_marcas_model();
    if (marcas_model == false) {
      this.maquinasService.getMarcas().subscribe(json => {
        this.maquinasService.set_marcas_model(json);
        this.marcasModel = this.maquinasService.get_marcas_model();
        r2 = true;
        if (r1 && r2) {
          this.cargarMaquina();
        }
      })
    } else {
      this.marcasModel = marcas_model;
      r2 = true;
      if (r1 && r2) {
        this.cargarMaquina();
      }
    }
  }

  cargarMaquina() {

    //COGEMOS LA MAQUINA
    this.maquina = this.maquinasModel.find(x => x.id === this.idmaquina);
    this.maquina.marca = this.marcasModel.find(x => x.id === this.maquina.idmarca);

    //CARGAMOS LOS MINIMOS Y MAXIMOS
    this.maquinasService.GetMaximosYMinimosById(this.idmaquina).pipe().subscribe((result) => {

      this.rangoDesbobinadora = { min: result[0].desbobinadora_min, max: result[0].desbobinadora_max };
      this.rangoTomaDeAlimentacion = { min: result[0].tomaDeAlimentacion_min, max: result[0].tomaDeAlimentacion_max };
      this.rangoAccionadorRodilloEnfiamiento = { min: result[0].accionadorRodilloEnfriamiento_min, max: result[0].accionadorRodilloEnfriamiento_max };
      this.rangoBobinadora = { min: result[0].bobinadora_min, max: result[0].bobinadora_max };
      this.rangoSecadorTempBCD = { min: result[0].secadorTempBCD_min, max: result[0].secadorTempBCD_max };
      this.rangoSecadorTunelTempBCD = { min: result[0].secadorTunelTempBCD_min, max: result[0].secadorTunelTempBCD_max };
      this.rangoKwhTotales = { min: result[0].kwhTotales_min, max: result[0].kwhTotales_max };
      this.rangoPotenciaInstantanea = { min: result[0].potenciaInstantanea_min, max: result[0].potenciaInstantanea_max };

      //PINTAMOS LOS GRAFICOS
      this.pintarGraficos();

    });

  }

  pintarGraficos() {
    this.pintarGraficosTemperaturas();
    this.pintarGraficosPresiones();
    this.pintarGraficoResumenSemana();
    //DESPUES DE PINTAR LOS GRAFICOS CARGAMOS TODOS LOS DATOS
    this.cargarTodosLosDatos();
    this.cargarDatosProcesos();
    this.cargarDatosMantenimientos();
    this.cargarDatosNotas();
  }

  pintarGraficosTemperaturas() {

    var th = this;

    var ids = ["graficoSecadorTempBCD_hmiimp", "graficoSecadorTunelTempBCD_hmiimp"];

    ids.forEach(function (id, i) {

      if (i == 0) var rango = th.rangoSecadorTempBCD;
      if (i == 1) var rango = th.rangoSecadorTunelTempBCD;

      var opts = {
        bindto: "#" + ids[i],
        data: {
          columns: [
            ['completo', rango.min],
            ['nocompleto', rango.max]
          ],
          type: 'bar',
          groups: [
            ['completo', 'nocompleto']
          ],
          colors: {
            completo: '#00B8C0',
            nocompleto: '#12192C'
          }
        },
        bar: {
          width: {
            ratio: 0.85
          }
        },
        axis: {
          y: {
            tick: {
              values: function (x) {
                return d3.scaleLinear().domain([rango.min, rango.max]).ticks(5);
              },
              format: function (x) {
                return x + "º";
              },
            },
            padding: 0
          },
        },
        tooltip: {
          show: false
        },
        legend: {
          show: false
        },
        padding: {
          left: 30,
          right: 30
        },
      };

      var grafico = c3.generate(opts);

      d3.selectAll("#" + ids[i] + " .c3-chart-arcs path").style("stroke-width", "0px");

      if (i == 0) th.graficoSecadorTempBcd = grafico;
      if (i == 1) th.graficoSecadorTunelTempBcd = grafico;

    })

    //var i;
    //for (i = 0; i < ids.length; i++) {

    //  if (i == 0) var rango = this.rangoSecadorTempBCD;
    //  if (i == 1) var rango = this.rangoSecadorTunelTempBCD;

    //  var opts = {
    //    bindto: "#" + ids[i],
    //    data: {
    //      columns: [
    //        ['completo', rango.min],
    //        ['nocompleto', rango.max]
    //      ],
    //      type: 'bar',
    //      groups: [
    //        ['completo', 'nocompleto']
    //      ],
    //      colors: {
    //        completo: '#00B8C0',
    //        nocompleto: '#12192C'
    //      }
    //    },
    //    bar: {
    //      width: {
    //        ratio: 0.85
    //      }
    //    },
    //    axis: {
    //      y: {
    //        tick: {
    //          values: function (x) {
    //            return d3.scaleLinear().domain([rango.min, rango.max]).ticks(5);
    //          },
    //          format: function (x) {
    //            return x + "º";
    //          },
    //        },
    //        padding: 0
    //      },
    //    },
    //    tooltip: {
    //      show: false
    //    },
    //    legend: {
    //      show: false
    //    },
    //    padding: {
    //      left: 30,
    //      right: 30
    //    },
    //  };

    //  var grafico = c3.generate(opts);

    //  d3.selectAll("#" + ids[i] + " .c3-chart-arcs path").style("stroke-width", "0px");

    //  if (i == 0) this.graficoSecadorTempBcd = grafico;
    //  if (i == 1) this.graficoSecadorTunelTempBcd = grafico;

    //}

  }

  pintarGraficosPresiones() {

    var th = this;

    var thresholdOpts = {
      boxSize: 14,
      boxFill: false,
      strokeWidth: 4,
      strokeColor: '#e9f0f6',
      fontSize: "8pt"
    };

    var ids = ["graficoDesbobinadora_hmiimp", "graficoTomaDeAlimentacion_hmiimp", "graficoAccionadorRodilloEnfriamiento_hmiimp",
      "graficoBobinadora_hmiimp", "graficoKwhTotales_hmiimp", "graficoPotenciaInstantanea_hmiimp"];

    ids.forEach(function (id, i) {
      if (i == 0) var rango = th.rangoDesbobinadora;
      if (i == 1) var rango = th.rangoTomaDeAlimentacion;
      if (i == 2) var rango = th.rangoAccionadorRodilloEnfiamiento;
      if (i == 3) var rango = th.rangoBobinadora;
      if (i == 4) var rango = th.rangoKwhTotales;
      if (i == 5) var rango = th.rangoPotenciaInstantanea;

      var opts = {
        bindto: "#" + ids[i],
        data: {
          columns: [
            ['data', rango.min]
          ],
          type: 'gauge',
        },
        gauge: {
          label: {
            format: function (value, ratio) {
              return "";
            }
          },
          min: rango.min,
          max: rango.max
        },
        legend: {
          show: false
        },
        tooltip: {
          show: false
        },
        color: {
          pattern: ['#00b95e']
        },
        onrendered: function () {
          th.pintarLineasRangos(this, thresholdOpts, opts);
          th.pintarTextosRangos(this, thresholdOpts, opts);
        },
        onresized: function () {
          th.pintarLineasRangos(this, thresholdOpts, opts);
          th.pintarTextosRangos(this, thresholdOpts, opts);
        },
        min: rango.min,
        max: rango.max
      };

      var grafico = c3.generate(opts);

      if (i == 0) th.graficoDesbobinadora = grafico;
      if (i == 1) th.graficoTomaDeAlimentacion = grafico;
      if (i == 2) th.graficoAccionamientoRodillo = grafico;
      if (i == 3) th.graficoBobinadora = grafico;
      if (i == 4) th.graficoKwhTotales = grafico;
      if (i == 5) th.graficoPotenciaInstantanea = grafico;
    })

  }

  pintarLineasRangos(chart, thOpts, chOpts) {

    var divId = chart.config.bindto;

    d3.selectAll(divId + " line.myline").remove();

    var radius = chart.radius
    var iradius = chart.innerRadius;
    var min = chOpts.min;
    var max = chOpts.max;
    var espacioEntreRangos = (max - min) / 5;
    var rangos = [min + espacioEntreRangos, min + (espacioEntreRangos * 2), min + (espacioEntreRangos * 3), min + (espacioEntreRangos * 4)];
    var angles = [(Math.PI / 5) * 1, (Math.PI / 5) * 2, (Math.PI / 5) * 3, (Math.PI / 5) * 4];
    for (var i in rangos) {
      var v = rangos[i];
      //var angle = Math.PI * (v) / 100;
      var angle = angles[i];
      var x0 = (iradius * Math.cos(angle)) * 0.95;
      var y0 = (iradius * Math.sin(angle)) * 0.95;
      var x1 = (radius * Math.cos(angle)) * 1.05;
      var y1 = (radius * Math.sin(angle)) * 1.05;
      d3.select(divId + " .c3-chart-arcs").append("line")
        .attr('x1', -x0)
        .attr('y1', -y0)
        .attr('x2', -x1)
        .attr('y2', -y1)
        .attr('class', 'myline')
        .style("stroke-width", thOpts.strokeWidth)
        .style("stroke", thOpts.strokeColor);

    }

  }

  pintarTextosRangos(chart, thOpts, chOpts) {

    var divId = chart.config.bindto;
    d3.selectAll(divId + " text.mytxt").remove();

    var radius = chart.radius
    var min = chOpts.min;
    var max = chOpts.max;
    var espacioEntreRangos = (max - min) / 5;
    var rangos = [min + espacioEntreRangos, min + (espacioEntreRangos * 2), min + (espacioEntreRangos * 3), min + (espacioEntreRangos * 4)];
    var angles = [(Math.PI / 5) * 1, (Math.PI / 5) * 2, (Math.PI / 5) * 3, (Math.PI / 5) * 4];

    for (var i in rangos) {
      var v = rangos[i];
      //var angle = Math.PI * (v) / 100;
      var angle = angles[i];
      var x1 = ((radius + thOpts.boxSize) * Math.cos(angle)) + thOpts.boxSize / 2;
      var y1 = ((radius + thOpts.boxSize) * Math.sin(angle)) + thOpts.boxSize / 2;
      var text = d3.select(divId + " .c3-chart-arcs").append("text")
        .attr('x', -x1)
        .attr('y', -y1 + 14)
        .attr('font-size', thOpts.fontSize)
        .attr('class', 'mytxt')
        .text(v);
    }

  }

  pintarGraficoResumenSemana() {

    var grafico = c3.generate({
      bindto: '#graficoResumenSemana_hmiimp',
      data: {
        columns: [
          [this.translateService.instant("preparacion"), 0],
          [this.translateService.instant("ejecucion"), 0],
          [this.translateService.instant("microparada"), 0],
          [this.translateService.instant("parada"), 0],
          [this.translateService.instant("mantenimiento"), 0],
          [this.translateService.instant("alarma"), 0],
          [this.translateService.instant("apagada"), 0],
        ],
        names: {
          preparacion: this.translateService.instant("preparacion"),
          ejecucion: this.translateService.instant("ejecucion"),
          microparada: this.translateService.instant("microparada"),
          parada: this.translateService.instant("parada"),
          mantenimiento: this.translateService.instant("mantenimiento"),
          alarma: this.translateService.instant("alarma"),
          apagada: this.translateService.instant("apagada")
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#graficoResumenSemana_hmiimp .c3-chart-arcs-title').append("tspan").attr("font-size", "25").text((d.ratio * 100).toFixed(1) + "%");
          //d3.select("#graficoResumenSemana_hmiimp .c3-chart-arcs-title")
          //  .append("tspan")
          //  .attr("dy", 24)
          //  .attr("x", 0)
          //  .attr("font-size", "10")
          //  .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#graficoResumenSemana_hmiimp .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: ["#096844", "#C0EADA", "#cf8729", "#E7CB68", "#99AFCC", "#D33737", "#424242"]
      },
      donut: {
        label: { show: false }
      },
      legend: {
        position: 'right'
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#graficoResumenSemana_hmiimp .c3-chart-arcs path").style("stroke-width", "0px");

    this.graficoResumenSemana = grafico;

  }

  cargarTodosLosDatos() {

    this.centroMecanizadoService.GetHmiImpresoraPlasticoById(this.idmaquina).subscribe((result) => {

      var datos: any = result;

      var ejecucionActual = datos.ejecuciones;

      if (ejecucionActual.length > 0) {

        //pestannaColor
        if (ejecucionActual[0].idProcesos_tipo == 1) this.pestannaColor = "pestañaprocesos colorejecucion";
        else if (ejecucionActual[0].idProcesos_tipo == 2) this.pestannaColor = "pestañaprocesos colorparada";
        else if (ejecucionActual[0].idProcesos_tipo == 3) this.pestannaColor = "pestañaprocesos colorpreparacion";
        else if (ejecucionActual[0].idProcesos_tipo == 4) this.pestannaColor = "pestañaprocesos colormantenimiento";
        else if (ejecucionActual[0].idProcesos_tipo == 6) this.pestannaColor = "pestañaprocesos coloralarma";
        else if (ejecucionActual[0].idProcesos_tipo == 8) this.pestannaColor = "pestañaprocesos colorapagada";
        else this.pestannaColor = "pestañaprocesos colorejecucion";

        //speed
        this.speed = ejecucionActual[0].speed;

        //TEMPERATURA
        var completoSecadorTempBCP = ejecucionActual[0].secadorTempBCD;
        var noCompletoSecadorTempBCP = this.rangoSecadorTempBCD.max - ejecucionActual[0].secadorTempBCD;
        this.graficoSecadorTempBcd.load({ columns: [['completo', completoSecadorTempBCP], ['nocompleto', noCompletoSecadorTempBCP]] });

        var completoSecadorTunelTempBCP = ejecucionActual[0].secadorTunelTempBCD;
        var noCmpletoSecadorTunelTempBCP = this.rangoSecadorTunelTempBCD.max - ejecucionActual[0].secadorTunelTempBCD;
        this.graficoSecadorTunelTempBcd.load({ columns: [['completo', completoSecadorTunelTempBCP], ['nocompleto', noCmpletoSecadorTunelTempBCP]] });

        //PRESION
        this.graficoDesbobinadora.load({ columns: [['data', ejecucionActual[0].desbobinadora]] });
        this.graficoTomaDeAlimentacion.load({ columns: [['data', ejecucionActual[0].tomaDeAlimentacion]] });
        this.graficoAccionamientoRodillo.load({ columns: [['data', ejecucionActual[0].accionadorRodilloEnfriamiento]] });
        this.graficoBobinadora.load({ columns: [['data', ejecucionActual[0].bobinadora]] });
        this.graficoKwhTotales.load({ columns: [['data', ejecucionActual[0].kwhTotales]] });
        this.graficoPotenciaInstantanea.load({ columns: [['data', ejecucionActual[0].potenciaInstantanea]] });

        this.labelDesbobinadora = ejecucionActual[0].desbobinadora.toFixed(1) + " %";
        this.labelTomaDeAlimentacion = ejecucionActual[0].tomaDeAlimentacion.toFixed(1) + " %";
        this.labelAccionamientoRodillo = ejecucionActual[0].accionadorRodilloEnfriamiento.toFixed(1) + " %";
        this.labelBobinadora = ejecucionActual[0].bobinadora.toFixed(1) + " %";
        this.labelKwhTotales = ejecucionActual[0].kwhTotales.toFixed(1) + " %";
        this.labelPotenciaInstantanea = ejecucionActual[0].potenciaInstantanea.toFixed(1) + " %";

        //PANEL OF
        this.of = ejecucionActual[0].of;
        this.cliente = ejecucionActual[0].cliente;
        this.pieza = ejecucionActual[0].pieza;
        this.parte = ejecucionActual[0].parte;
        this.operacion = ejecucionActual[0].operacion;
        this.nSeriePieza = ejecucionActual[0].nSeriePieza;
        if (ejecucionActual[0].of == undefined || ejecucionActual[0].of == "")
          this.realizadas = "";
        else
          this.realizadas = ejecucionActual[0].hechas + "/" + ejecucionActual[0].cantidad;

      }

      //RESUMEN SEMANA
      var tiempoPorSemana = datos.tiempoPorSemana;
      this.ensennarResumenSemana = true;

      if (tiempoPorSemana.length == 0) {
        tiempoPorSemana = [{ porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }, { porcentaje: 0 }];
        this.ensennarResumenSemana = false;
      }

      var tiempoPorSemanaData = [[this.translateService.instant("ejecucion"), tiempoPorSemana[0].porcentaje],
      [this.translateService.instant("parada"), tiempoPorSemana[1].porcentaje],
      [this.translateService.instant("microparada"), tiempoPorSemana[2].porcentaje],
      [this.translateService.instant("preparacion"), tiempoPorSemana[3].porcentaje],
      [this.translateService.instant("mantenimiento"), tiempoPorSemana[4].porcentaje],
      [this.translateService.instant("alarma"), tiempoPorSemana[5].porcentaje],
      [this.translateService.instant("apagada"), tiempoPorSemana[6].porcentaje]];

      var namesResumenSemana = {};
      namesResumenSemana[this.translateService.instant("ejecucion")] = this.translateService.instant("ejecucion") + " - " + tiempoPorSemana[0].porcentaje + "%";
      namesResumenSemana[this.translateService.instant("parada")] = this.translateService.instant("parada") + " - " + tiempoPorSemana[1].porcentaje + "%";
      namesResumenSemana[this.translateService.instant("microparada")] = this.translateService.instant("microparada") + " - " + tiempoPorSemana[2].porcentaje + "%";
      namesResumenSemana[this.translateService.instant("preparacion")] = this.translateService.instant("preparacion") + " - " + tiempoPorSemana[3].porcentaje + "%";
      namesResumenSemana[this.translateService.instant("mantenimiento")] = this.translateService.instant("mantenimiento") + " - " + tiempoPorSemana[4].porcentaje + "%";
      namesResumenSemana[this.translateService.instant("alarma")] = this.translateService.instant("alarma") + " - " + tiempoPorSemana[5].porcentaje + "%";
      namesResumenSemana[this.translateService.instant("apagada")] = this.translateService.instant("apagada") + " - " + tiempoPorSemana[6].porcentaje + "%";

      this.graficoResumenSemana.load({ columns: tiempoPorSemanaData, names: namesResumenSemana });

    });

  }

  refreshData() {

    if (this.router.url == '/procesosimpresora/' + this.idmaquina) {

      this.cargarTodosLosDatos();

    } else {

      clearInterval(this.interval);

    }

  }

  refreshDataPestañas() {

    if (this.router.url == '/procesosimpresora/' + this.idmaquina) {

      this.cargarDatosProcesos();
      this.cargarDatosMantenimientos();
      this.cargarDatosNotas();

    } else {

      clearInterval(this.interval2);

    }

  }

  cargarDatosProcesos() {

    this.hmiService.Get_ProcesosHistorial(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.historicoProcesos = [];
      datuak.forEach(function (valor, indice, array) {
        var tipoProceso = "";
        if (valor.idProcesos_Tipo == 1) tipoProceso = this.translateService.instant("ejecucion");
        if (valor.idProcesos_Tipo == 2) tipoProceso = this.translateService.instant("parada");
        if (valor.idProcesos_Tipo == 3) tipoProceso = this.translateService.instant("preparacion");
        if (valor.idProcesos_Tipo == 4) tipoProceso = this.translateService.instant("mantenimiento");
        if (valor.idProcesos_Tipo == 6) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 7) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 8) tipoProceso = this.translateService.instant("apagado");
        this.historicoProcesos.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            tipo: tipoProceso,
            tipoAlarNombre: valor.descripcion,
            nombre: valor.nombrePrograma,
            OF: valor.of,
            tiemporeal: this.myFunctions.secondsTo_HH_MM_SS(valor.tiemporeal),
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoPredictivo),
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_" + valor.idProcesos_Tipo,
          }
        );
      }, this);
    });

    this.hmiService.Get_ProcesosAlarmas(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.alarmas = [];
      datuak.forEach(function (valor, indice, array) {
        var divnombre;
        if (valor.idAlarma_tipo == 1) divnombre = "css_linea_lateral_2";
        else divnombre = "css_linea_lateral_6";
        this.alarmas.push(
          {
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            numeroAlarma: valor.numeroAlarma,
            descripcion: valor.descripcion,
            divcss: divnombre,
          }
        );
      }, this);
    });

    this.hmiService.Get_Planificados(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.planificados = [];
      datuak.forEach(function (valor, indice, array) {
        this.planificados.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            operacion: valor.operacion,
            OF: valor.of,
            tiempoteorico: this.secondsTo_HH_MM_SS(valor.tiempoEstimado),
            programa: 123456,
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_8",
          }
        );
      }, this);
    });

  }

  abrirPdf(mantenimiento) {

    this.hmiService.GetPdf_MantenimientosRecientes(mantenimiento.enlacePdf).subscribe((result) => {

      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + result[0].pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = mantenimiento.enlacePdf;
      }, 0);
    });

  }

  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      //ViewState("checkMantenimientos") = ":"

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);

            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }
            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.myFunctions.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }


          } else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }


          if (row.idTipo == 1) {//diario
            tipo = "diario";
          } else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          } else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          } else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          } else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      });

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      })

    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idmaquina).subscribe((result) => {
      var datuak: any = result;

      //ViewState("checkMantenimientos") = ":"

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  clickSwitch(event: any, dataitem: any, tipo: number) {
    this.loadingPorFecha = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switck=true)
      this.hmiService.UNupdate_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switck=false)
      this.hmiService.update_mantenimiento_codigos(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorFecha = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  clickSwitchTiempo(event: any, dataitem: any, tipo: number) {
    this.loadingPorTiempo = true;

    var prevChecked = !dataitem.checked;

    if (prevChecked) { //El mantenimiento estaba hecho (switck=true)
      this.hmiService.UNupdate_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    } else { //El mantenimiento no estaba hecho (switck=false)
      this.hmiService.update_mantenimiento_codigos_Tiempo(dataitem.id).subscribe((result) => {
        var datuak: any = result;
        this.loadingPorTiempo = false;
        this.cargarDatosMantenimientos();
      });
    }

  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.esRojo) {
      case false:
        return "";
      case true:
        return "gridFilaRoja";
      default:
        return "";
    }
  }

  cargarDatosNotas() {
    this.hmiService.Get_NotasActualesMaquina(this.idmaquina).subscribe((result) => {
      var datuak: any = result;
      this.notas = datuak;
    });
  }

  RedirigirHistoricoDatos(idCampo) {
    this.router.navigate(["historicodatos/" + this.idmaquina + "/" + idCampo]);
  }

  RedirigirRendimiento() {
    this.router.navigate(["informerendimiento/" + this.idmaquina]);
  }

}
