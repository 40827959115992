import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { FabricantesService } from '@app/_services';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import { Renderer2, NgZone } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { tumblrBoxIcon } from '@progress/kendo-svg-icons';
import { CursosLectivosGruposService } from '@app/_services/eskola_cursosLectivosGrupos.service';

//Variables para el drag and drop de la tabla
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';

@Component({
  selector: 'app-cursoslectivosgrupos',
  templateUrl: 'eskola_cursoslectivosgrupos.component.html'
})



export class CursosLectivosGruposComponent {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  // GRID ALUMNOS
  public seleccionados: number[] = [];

  //VARIABLES DB
  public id: number = 0;
  //public JcursosLectivosGruposSelected: any; {id:..., ...} SE DEFINEN ABAJO
  public nombre: string = '';
  public inicio: string = '';
  public fin: string = '';
  public calendario: string = '';
  public cursoLectivo: string = '';
  public tipo: string = '';
  public color: string = '';
  public esActivo: boolean = false;
  public fini: any;
  public ffin: any;
  /////////////////////////////////////////////////
  public descripcion: string = '';
  public tieneNserie: boolean = false;
  public numContenido: number = 0;
  public vidaUtil: number = 0;
  public vidaUtilHHmmSS: string = "00:00:00";
  public fiabilidad: number = 0;
  public diametro: number = 0;
  public longitud: number = 0;
  public angulo: number = 0;
  public salto: number = 0;
  public coste: number = 0;
  public idErp: number = 0;

  //COMBOS
  public JconsumiblesTipos: any;
  public JconsumiblesTipos_grid: any;
  public JconsumiblesTiposSelected: any = {
    descripcion: "",
    id: 0,
    nombre: "",
    orden: 1,
    solicitar_angulo: false,
    solicitar_coste: false,
    solicitar_diametro: false,
    solicitar_idERP: false,
    solicitar_longitud: false,
    solicitar_numContenido: false,
    solicitar_salto: false,
    solicitar_vidaUtil: false,
    idsHijos: []
  };
  /////////////////////////////////////////////////
  public JcursosLectivosGrupos: any;
  public JcursosLectivos: any;
  public JcursosLectivosTipo: any;
  public JcursosLectivosCalendario: any;
  public JcursosLectivosGruposSelected: any;
  public JcursosLectivosSelected: any;
  public JcursosLectivosTipoSelected: any;
  public JcursosLectivosCalendarioSelected: any;
  public Jconsumibles: any;
  public grupoAlumnos: any;
  public nombreAlumnos: any;
  public cursolectivo: any;
  public activo: any;
  public selectedValueCurso: any;

  //CONTENIDO
  public Jcontenido: any = [];
  public contenidoSelected: number[] = [];

  //REQUIERED
  //public requiereTipo: boolean = false;
  public requiereFabricante: boolean = false;
  //public requiereNombre: boolean = false;
  public requiereAngulo: boolean = false;
  public requiereCoste: boolean = false;
  public requiereDiametro: boolean = false;
  public requiereIdERP: boolean = false;
  public requiereLongitud: boolean = false;
  public requiereNumContenido: boolean = false;
  public requiereSalto: boolean = false;
  public requiereVidaUtil: boolean = false;
  public requierefiabilidad: boolean = false;

  public requiereTipo: boolean = false;
  public requiereNombre: boolean = false;
  public requiereCursoLectivo: boolean = false;
  public requiereCalendario: boolean = false;
  public requiereInicio: boolean = false;
  public requiereFin: boolean = false;

  //Variables para el drag and drop del grid
  public state: State = {
    skip: 0,
    take: 100,
  };

  //POPUP: error al eliminar
  public popupErrorAlGuardar: boolean = false;
  public popupSeGuardaranLosCambios: boolean = false;


  public gridData: any = process([], this.state);
  currentSubscription: Subscription;
  user = this.userService.userValue;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService,
    public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    public fabricantesService: FabricantesService,
    private renderer: Renderer2,
    private userService: UsuariosService,
    private zone: NgZone,
    private cursosLectivosGruposService: CursosLectivosGruposService) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('grupo').toUpperCase();
  }

  ngOnInit() {
    //como aqui se cargan mas cosas que solo los datos ponemos la carga desde el inicio
    //this.loadingPanel = true;

    this.id = this.route.snapshot.params['id'];

    this.cargarDatosCursosLectivos();
    this.cargarFiltroURL();
  }
  cargarDatosCursosLectivos() {
    this.cursosLectivosGruposService.Get_cursosLectivos().subscribe(
      (json) => {
        if (Object.keys(json).length > 0) {
          this.JcursosLectivos = json;
          this.JcursosLectivosSelected = "";
        }
      });
    this.cursosLectivosGruposService.Get_tipoGrupos().subscribe(
      (json) => {
        if (Object.keys(json).length > 0) {
          this.JcursosLectivosTipo = json;
          this.JcursosLectivosTipoSelected = "";
          console.log(json);
        }
      });
    this.cursosLectivosGruposService.Get_grupoByid(this.id).subscribe(
      (json) => {
        if (Object.keys(json).length > 0) {
          this.nombre = json[0].nombre;
          //this.JcursosLectivosSelected = this.JcursosLectivos.filter((f) => f.id == json[0].cursoLectivo)[0];
          console.log(json);
          this.JcursosLectivosSelected = {id: json[0].id1, nombre: json[0].cursoLectivo};
          this.JcursosLectivosTipoSelected = {nombre: json[0].tipo, id: json[0].id};
          this.JcursosLectivosCalendarioSelected = json[0].calendario;
          //this.JcursosLectivosTipoSelected = this.JcursosLectivosTipo.filter((f) => f.id == json[0].id)[0];
          this.activo = json[0].activo;
        }
      });
    this.cursosLectivosGruposService.Get_alumnosByid(this.id).subscribe(
      (json) => {
        this.grupoAlumnos = json;
      });
    this.color = document.getElementById("colorSelected").nodeValue;
  }

  public listItems: Array<string> = [
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
  ];

  public selectedValue: 1;

  public diaChange() {

    const div = document.getElementById('horario');
    var html = "<br>";
        html += "<div class='float-left mr-1'>";
          html += "<div class='caja' style='width: 150px'>";
            html +=  "<kendo-combobox [data]=\"listItems\"";
            html +=              "[textField]=\"'text'\"";
            html +=              "[valueField]=\"'id'\"";
            html +=              "[ngModel]=\"selectedValue\"";
            html +=              "(ngModelChange)=\"diaChange()\"";
            html +=             "placeholder=\"{{ 'dia' | translate }}\">";
            html +=  "</kendo-combobox>";
          html +="</div>";
        html +="</div>";
        html += "<div class='float-left mr-1' >";
          html += "<input id='hora-inicio' value='8:00 AM' title='timepicker' style='width: 80px' />";
        html += "</div>";
        html += "<div class='float-left mr-1' style='width: 150px'>";
          html += "<input id='hora-fin' value='14:00 AM' title='timepicker' style='width: 78px' />";
        html += "</div>";
    div.innerHTML += html;
  }

  cursoChange() {

  }

  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    
    // Informe Proyectos:
    //   - fechaIni , fechaFin *fijo*
    //   - terminados (posible extra)
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // TERMINADO 
    // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
    // * solo lo voy a filtrar si es 1
    /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
    if (this.route.snapshot.params['terminados'] == '1') {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: true,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    
  }

  fabricanteChange() {
    //var th = this;
    //setTimeout(function () {
    //  //if (th.JcursosLectivosGruposSelected == undefined)
    //  th.JcursosLectivosGruposSelected = [];//SIN AUTOSELECT th.JcursosLectivosGrupos[0];
    //}, 10);
  }

  //CONTENIDO
  removeHandler() {
    if (this.contenidoSelected.length > 0)
      this.Jcontenido = this.Jcontenido.filter(f => !this.contenidoSelected.includes(f.idTemp));
    this.contenidoSelected = [];
    this.reorderData();
    this.recargarGrid();
  }
  onClickNuevoContenido(event: any) {
    // indice que se usa para la seleccion del grid
    var idTempMax = 0;
    if (this.Jcontenido.length > 0) {
      this.Jcontenido.forEach(
        (row) => {
          if (row.idTemp > idTempMax) {
            idTempMax = row.idTemp + 0;
          }
        });
    }
    idTempMax++;

    var dt: any = {
      'id': 0,
      'idTemp': idTempMax,
      'idConsumibles': this.id,
      'idConsumibles_contenido_tipo': undefined, //SIN AUTOSELECT this.JconsumiblesTipos_grid[0],
      'idConsumibles_contenido': undefined, //SIN AUTOSELECT this.Jconsumibles.filter(f => this.JconsumiblesTipos_grid[0].id == f.idConsumibles_tipos)[0],
      'orden': Object.keys(this.Jcontenido).length + 1,
      'preferido': false,
      'cantidad': 1
    };
    this.Jcontenido.push(dt);

    this.reorderData();
    this.recargarGrid()
  }
  Jconsumibles_tipo_selected(dataItem) {
    dataItem.idConsumibles_contenido = undefined;
  }
  reorderData() {
    this.Jcontenido.sort(function (a, b) { return a['orden'] - b['orden']; })

    var orden = 1;
    this.Jcontenido.forEach(
      (row) => {
        row.orden = orden;
        orden++;
      });
  }
  redirect_consumible(dataItem) {
    if (dataItem.idConsumibles_contenido != undefined) {
      const url = this.router.serializeUrl(this.router.parseUrl('#/consumible/' + dataItem.idConsumibles_contenido.id));
      window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(this.router.parseUrl('#/consumibleTipo/' + dataItem.idConsumibles_contenido_tipo.id));
      window.open(url, '_blank');
    }
  }

  //BOTONES
  guardar() {
    var guardar = true;
    
    this.requiereNombre = false;
    this.requiereCursoLectivo = false;
    this.requiereInicio = false;
    this.requiereFin = false;
    this.requiereCalendario = false;
    this.requiereTipo = false;

    //valores obligatorios siempre y cuando se muestren
    if (this.nombre == '') {
      guardar = false;
      this.requiereNombre = true;
    }
    if (this.cursoLectivo = '') {
      guardar = false;
      this.requiereCursoLectivo = true;
    }
    // Falta da inicio, fin datak sartzea
    if (this.tipo = '') {
      guardar = false;
      this.requiereTipo = true;
    }

    if (guardar) {      
        if (this.id == 0) {
          this.cursosLectivosGruposService.Create(this.nombre, this.JcursosLectivosSelected.nombre, "2022/01/01", "2022/02/03", this.JcursosLectivosCalendarioSelected, 
          this.JcursosLectivosTipoSelected.tipo, this.activo, "").subscribe(
            (json) => {
            });
        } else {
          console.log(this.JcursosLectivosTipoSelected);
          this.cursosLectivosGruposService.Update(this.id, this.nombre, this.JcursosLectivosSelected.nombre, "2022/01/01", "2022/02/03", this.JcursosLectivosCalendarioSelected, 
            this.JcursosLectivosTipoSelected.tipo, this.activo, "").subscribe(
              (json) => {});
        }
        this.router.navigate(['cursoslectivosgrupos']);
    }
  }
  cancelar() {
    this.router.navigate(['cursoslectivosgrupos']);
  }
  copiar() {
    this.cursosLectivosGruposService.Create(this.nombre, this.JcursosLectivosSelected.nombre, "2022/01/01", "2022/02/03", this.JcursosLectivosCalendarioSelected, 
          this.JcursosLectivosTipoSelected.tipo, this.activo, "").subscribe(
            (json) => {
            });
    this.router.navigate(['cursoslectivosgrupos']);
  }
  eliminar() {
    console.log(this.seleccionados);
    if (this.seleccionados.length > 0) {
      for( var i=0; i<this.seleccionados.length; i++ ) {
        console.log(this.seleccionados[i]);
        this.cursosLectivosGruposService.UpdateAlumnoGrupo(this.seleccionados[i]).subscribe(
          (json) => {});
      }
      this.router.navigate(['cursoslectivosgrupos/' + this.id]);
    }
  }
  agregar() {
    this.router.navigate(['consumibles']); // Funtzio hau aldatu behar da
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
  //Funciones para cambiar el orden del kendo arrastrando las filas
  public rowCallback(context: RowClassArgs) {
    context.dataItem.orden = context.index + 1;
    return {
      dragging: context.dataItem.dragging
    };
  }
  private handleDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }
  recargarGrid() {
    this.gridData = process(this.Jcontenido, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }
  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }
  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }


  /*updateDatosHerramienta(dataItevidaUtilHHmmSSm, cambio, porcen) {
    //aquí hay que revisar que si se cambia el tiempoUso también se debe cambiar el de los demás y lo mismo con el porcentaje
    //también comprobar que no se pasen del tope entre todos los objetos

    this.requiereVidaUtil = false;
    var pattern = new RegExp('^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$');
    var result = pattern.test(cambio);
    if (result == false) {
      this.requiereVidaUtil = true;
    }
    else {
      this.vidaUtil = this.HMSToSeconds(cambio);
    }
  }*/
  HMSToSeconds(tiempoEstimado) {
    var horas, minutos, segundos;
    horas = Number(tiempoEstimado.split(":")[0]);
    minutos = Number(tiempoEstimado.split(":")[1]);
    segundos = Number(tiempoEstimado.split(":")[2]);
    return horas * 3600 + minutos * 60 + segundos;
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
}
