<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">

    <div class="col-md-6 col-lg-6">
      <div class="card">
        <div class="card-body">
          <h3 class="pt-3">{{ 'datos' | translate}}</h3>
          <div class="form-row">
            <div class="form-group col-5">
              <kendo-label text="{{ 'idERP' | translate}}">
                <kendo-textbox formControlName="idERP" maxlength="150" class="text-right"></kendo-textbox>
              </kendo-label>
            </div>
            <div class="form-group col-5" *ngIf="!isAddMode">
              <kendo-label text="{{ 'numOperario' | translate}}">
                <kendo-numerictextbox class="form-control" formControlName="numOperario" [min]="0" [max]="99999"
                  [autoCorrect]="true" [format]="'n'" [disabled]="true"></kendo-numerictextbox>
                <div *ngIf="submitted && f.numOperario.errors" class="invalid-feedback">
                  <div *ngIf="f.numOperario.errors.required">{{ 'idrequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'usuario' | translate}}">
                <kendo-textbox formControlName="nombreUsuario" maxlength="150"
                  [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }" ></kendo-textbox>
                <div *ngIf="submitted && f.nombreUsuario.errors" class="invalid-feedback">
                  <div *ngIf="f.nombreUsuario.errors.required">
                    {{ 'nombreusuariorequerido' | translate}}
                  </div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'nombre' | translate}}">
                <kendo-textbox formControlName="nombre" maxlength="150"
                  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'apellido1' | translate}}">
                <kendo-textbox formControlName="apellido1" maxlength="150"
                  [ngClass]="{ 'is-invalid': submitted && f.apellido1.errors }"></kendo-textbox>
                <div *ngIf="submitted && f.apellido1.errors" class="invalid-feedback">
                  <div *ngIf="f.apellido1.errors.required">{{ 'apellidorequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-5">
              <kendo-label text="{{ 'apellido2' | translate}}">
                <kendo-textbox formControlName="apellido2" maxlength="150"
                  [ngClass]="{ 'is-invalid': submitted && f.apellido2.errors }"></kendo-textbox>
              </kendo-label>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-6">
              <kendo-label text="{{ 'email' | translate}}">
                <kendo-textbox formControlName="email" maxlength="150">
                </kendo-textbox>
              </kendo-label>
            </div>
            <div class="form-group col-3">
              <kendo-label text="{{ 'tipo' | translate}}">
                <kendo-dropdownlist [data]="tiposUsuarios_DAT" [textField]="'nombre'" [valueField]="'id'"
                  (valueChange)="valueChange($event)" [value]="selectedItem"
                  [ngClass]="{ 'invalid-dropdown': submitted && errorSeccion }" class="form-control">
                </kendo-dropdownlist>
                <div *ngIf="submitted && errorUsuario" class="invalid-feedback-dropdown">
                  <div *ngIf="errorUsuario">{{ 'tipousuariorequerido' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col-3">
              <kendo-label text="{{ 'secciones' | translate }}">
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion" [textField]="'nombre'"
                  [valueField]="'id'" [autoClose]="false" formControlName="seccionesSeleccionadas"
                  [kendoDropDownFilter]="{operator: 'contains'}"
                  [ngClass]="{ 'invalid-multiselect': submitted && errorSeccion }">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
                <div *ngIf="submitted && errorSeccion" class="invalid-feedback-dropdown">
                  <div *ngIf="errorSeccion">{{ 'seccionrequerida' | translate}}</div>
                </div>
              </kendo-label>
            </div>
          </div>


          <div class="form-row">
            <div class="form-group col-5">
              <kendo-label text="{{ 'coste' | translate}}">
                <kendo-numerictextbox class="form-control" formControlName="coste" [min]="0" [max]="9999"
                  [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
              </kendo-label>
            </div>
            <div class="form-group form-check">
              <kendo-label for="activo" class="form-check-label" text="{{ 'activo' | translate}}">
              </kendo-label>
              <div class="caja">
                <kendo-switch id="activo" formControlName="activo" [onLabel]="' '" [offLabel]="' '"
                  [ngClass]="{ 'is-invalid': submitted && f.activo.errors }"> </kendo-switch>
              </div>
            </div>
          </div>



        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div *ngIf="!isAddMode">
            <h3 class="pt-3">{{ 'cambiarcontrasena' | translate}}</h3>
            <p>*{{ 'dejelovacio' | translate}}</p>
          </div>
          <div class="form-row">
            <div class="form-group col">
              <kendo-label text="{{ 'contrasena' | translate}}">
                <input kendoTextBox type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" name="new-password"
                  id="new-password" autocomplete="new-password" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">{{ 'contrasenarequerida' | translate}}</div>
                  <div *ngIf="f.password.errors.minlength">{{ 'contrasena6caracteres' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="form-group col">
              <kendo-label text="{{ 'confirmarcontrasena' | translate}}">
                <input kendoTextBox type="password" formControlName="confirmPassword" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">
                    {{ 'contrasenaconfirmacionrequerida' | translate}}
                  </div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch">
                    {{ 'contrasenasnocoinciden' | translate}}
                  </div>
                </div>
              </kendo-label>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <button kendoButton [disabled]="loading  || this.user.gestionUsuarios < 2" class="btn mr-1  btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'guardar' | translate}}
          </button>
          <a routerLink="/usuarios" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
        </div>

      </div>
    </div>


    <div class="col-md-6 col-lg-6">
      <div class="card">
        <div class="card-header">
          <h3 class="pt-3">{{ 'maquinasVisibles' | translate}}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <strong><kendo-label text="{{ 'nombre' | translate}}"></kendo-label></strong>
            </div>
            <div class="col-md-4">
              <input type="checkbox" [(checked)]="allSelectedLectura" (change)="checkAllValueLectura()"><strong><kendo-label text="{{ 'lectura' | translate}}" class="p-1"></kendo-label></strong>
            </div>
            <div class="col-md-4">
              <input type="checkbox" [(checked)]="allSelectedEscritura" (change)="checkAllValueEscritura()"><strong><kendo-label text="{{ 'escritura' | translate}}" class="p-1"></kendo-label></strong>
            </div>
          </div>
          
          <div class="form-row">
            <kendo-grid [data]="dataMaquinasPermisos" [selectable]="{enabled: true, checkboxOnly: true}" [hideHeader]="true">

              <kendo-grid-column field="id" hidden="hidden" title="ID" width="120">
              </kendo-grid-column>
              <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}">
              </kendo-grid-column>
              <!-- <kendo-grid-checkbox-column [width]="40" showSelectAll="true"></kendo-grid-checkbox-column> -->
              <kendo-grid-column field="lectura" title="{{ 'lectura' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input class="chk_compatible" type="checkbox" [(checked)]="dataItem.lectura"
                    (change)="checkValueLectura(dataItem)" (valueChange)="onClickMaquina($event)" />
                </ng-template>
              </kendo-grid-column>
              <!-- <kendo-grid-checkbox-column [width]="40" showSelectAll="true"></kendo-grid-checkbox-column> -->
              <kendo-grid-column field="escritura" title="{{ 'escritura' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input class="chk_compatible" type="checkbox" (selectionChange)="onClickMaquina($event)"
                    (change)="checkValueEscritura(dataItem)" [(checked)]="dataItem.escritura" />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
  </div>


</form>