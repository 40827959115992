import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AlertService, ClientesService, MaquinasService, MenuService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-clientes-orden',
  templateUrl: './clientes-orden.component.html',
  styleUrls: ['./clientes-orden.component.less']
})
export class ClientesOrdenComponent implements OnInit {

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef; //referencia al popup de borrado

  public datosCargados;
  public clientes: any;
  public clientesSeleccionados: any = [];
  modalReference: NgbModalRef;
  navigationSubscription;
  isDeleting = false;
  form: FormGroup; //formulario
  user = this.userService.userValue;

  constructor(private clientesService: ClientesService, private menuService: MenuService,public router: Router, private translate: TranslateService,
    private modalService: NgbModal, private userService: UsuariosService,
    protected alertService: AlertService, private formBuilder: FormBuilder) {
    this.datosCargados = false;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/clientes') {
          this.menuService.titulo = this.translate.instant('clientes');
          this.cargarDatos();
        } else {
          if (this.router.url == '/clientes') {
            this.menuService.titulo = this.translate.instant('clientes');
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cargarDatos(){
    this.clientesService.getClientes().subscribe(response => {
      var aux: any = response;
      this.clientes = aux.data;
      this.datosCargados = true;
    });
  }

  public showGridTooltip(e: MouseEvent): void { //para mostrar un tooltip con el dato al pasar el ratón por encima cuando el título o contenido no cabe
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['clientes/editar/', this.clientesSeleccionados[0]]);
    }
  }

  onClickEditar() {
    if (this.clientesSeleccionados > 0) {
      var idCliente = this.clientesSeleccionados[0];
      this.router.navigate(['clientes/editar/' + idCliente]);

    }
  }

  onClickNuevo() { //al pulsar en el botón crear redirigir a crear nuevo
    this.router.navigate(['clientes/crear']);
  }

  onClickEliminar() { //al pulsar en eliminar lanzar popup de eliminar
    this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptar() { //al aceptar el eliminado, eliminar grupo de la base de datos
    this.clientesSeleccionados.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        this.clientesService.removeCliente(element)
          .pipe(first())
          .subscribe(() => {
            this.isDeleting = false;
            this.clientes = this.clientes.filter(x => x.id !== element)
          });
      }
      this.modalReference.close();
    });
  }





}
