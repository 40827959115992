import { Component } from '@angular/core';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { InformeRendimientoService, UsuariosService, MaquinasService, MenuService, InformeOeeService, HistoricoMaquinasDatosService } from '@app/_services';
import { MyFunctions, MyFilter } from '@app/_helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import 'moment-timezone';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

interface ItemTurno {
  nombre: string,
  id: number
}

interface ItemTurnoGantt {
  min: Date,
  max: Date,
  clase: string
}

declare var GanttChartClick: any;
@Component({
  selector: 'app-informe-comparativa-rendimiento',
  templateUrl: './informeComparativaRendimiento.component.html'
})

export class InformeComparativaRendimientoComponent {
  user = this.userService.userValue;

  public form: FormGroup;
  public idTurno: number;
  public groupByPorId = [];
  public groupByPorFecha = [];

  //public jsonMaquinas: any;
  public selectedMaquinas: number[] = [];
  public selectedMaquinasGantt: any[] = [];
  public turnosCombo: Array<ItemTurno> = [];
  public turnosComboSelected: string = "";// "1, 2, 3";
  public turnos_placeHolder: string = "";
  public fechaInicio: Date;
  public fechaFin: Date;

  public dataResumenSemana: JSON;
  public dataGantt: JSON;
  public visibleTipo: boolean = true;
  public dataChartDonutOEE: any;

  private tiempoTransition: number = 0;//2000;

  private colorPreparacion: String = "#096844";
  private colorEjecucion: String = "#C0EADA";
  private colorMicroparada: String = "#cf8729";
  private colorParada: String = "#E7CB68";
  private colorMantenimiento: String = "#99AFCC";
  private colorAlarma: String = "#D33737";
  private colorApagada: String = "#424242";
  private colorSinActividad: String = "#BDBEC3";
  private colorRendimiento: String = "#EFB4B8";
  private colorVerdeOscuro: String = "#256A67";
  private colorVerdeClaro: String = "#00b395";
  private colorOEEActivo: String = "#18D6B0";
  private colorOEENoActivo: String = "#3D6063";

  //PANEL MAQUINAS
  //private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  public aparecer = false;

  public fechaMin: Date;
  public fechaMax: Date;

  //TURNO
  private turno: number = -1;

  constructor(private InformeRendimientoService: InformeRendimientoService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private informeOeeService: InformeOeeService,
    private menuService: MenuService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private myFunctions: MyFunctions,
    private myFilter: MyFilter,
    private historicoMaquinasDatosService: HistoricoMaquinasDatosService
  ) {

    this.menuService.titulo = this.translateService.instant('comparativaRendimiento').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.cargarTurnosCombo();
    this.cargarFechas();

    this.cargarGrupos();
    this.cargarAreasProductivas();
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }
  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }
  }
  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }
    else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }
    else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));


    var maquinasActuales = this.maquinasMostradas.map(r => r.id)
    var newSelectedMaquinas: number[] = [];
    this.selectedMaquinas.forEach(
      idMaquinasseleccionada => {
        if (maquinasActuales.indexOf(idMaquinasseleccionada) > 0) {
          newSelectedMaquinas.push(idMaquinasseleccionada);
        } else {
          //QUITAR EL DIV DE LA PAGINA
          document.getElementById("maquina_graficos_" + idMaquinasseleccionada).remove();
          //QUITAR EL GANTT DE LA LISTA DE GANTTS
          this.selectedMaquinasGantt.splice(this.selectedMaquinasGantt.findIndex(a => a.__obj_container.id === "maquina_chartGantt_" + idMaquinasseleccionada), 1)
        }
      });
    this.selectedMaquinas = newSelectedMaquinas;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });
  }

  private cargarTurnosCombo() {
    this.turnos_placeHolder = this.translateService.instant("seleccioneTurnos")
    this.turnosCombo = [
      { nombre: this.translateService.instant("manana"), id: 1 },
      { nombre: this.translateService.instant("tarde"), id: 2 },
      { nombre: this.translateService.instant("noche"), id: 3 }
    ];
  }
  private cargarFechas() {
    var fechaIni;
    //calcular fecha inicio
    if (this.fechaInicio == undefined) {
      this.fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = this.fechaInicio
    if (fechaIni.getDay() == 3) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }
    //calcular fecha fin
    fechaIni = new Date(this.fechaInicio);
    this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    this.fechaInicio.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);
  }

  public cargarEstructura(idMaquina) {
    //================================================
    //== por cada maquina se añade un elemento div  ==
    //================================================

    if (idMaquina == -1) {
      var divsMaquinasGraficos = document.getElementsByClassName('informe-comparativa');
      while (divsMaquinasGraficos[0]) {
        divsMaquinasGraficos[0].parentNode.removeChild(divsMaquinasGraficos[0]);
      }

      for (let idMaquina2 of this.selectedMaquinas) {
        for (let maquina of this.maquinas) {
          if (maquina.id == idMaquina2) {
            var divMaquinaGraficos = document.createElement('div'); //crear el div
            divMaquinaGraficos.id = "maquina_graficos_" + maquina.id;  //añadirle id
            divMaquinaGraficos.setAttribute('class', 'card informe-comparativa'); //añadirle clases 'card', 'informe-comparativa'

            // HEADER DONUT 1
            var divMaquinaGraficosHeaderH3 = document.createElement('h3'); //crear el h3

            var divMaquinaGraficosHeaderH3Label = document.createElement('label'); //crear el label
            divMaquinaGraficosHeaderH3Label.innerHTML = maquina.nombre //añadir titulo

            //BODY
            var divMaquinaGraficosBody = document.createElement('div'); //crear el div
            divMaquinaGraficosBody.setAttribute('class', 'card-body'); //añadirle clase 'card-body'

            var divMaquinaGraficosBodyRow = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRow.setAttribute('class', 'clearfix'); //añadirle clase 'row'

            var divMaquinaGraficosBodyRowCol1 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol1.setAttribute('class', 'donut-comparativa  col-comparativa '); //añadirle clase 'col-md-3'

            var divMaquinaGraficosBodyRowCol1GraficoDonut = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol1GraficoDonut.style.height = "200px"  //Darle altura '200'
            divMaquinaGraficosBodyRowCol1GraficoDonut.id = 'maquina_chartDonut_' + maquina.id //añadirle un ID

            var divMaquinaGraficosBodyRowCol2 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol2.setAttribute('class', 'gantt-comparativa col-comparativa'); //añadirle clase 'col-md-8'

            var divMaquinaGraficosBodyRowCol2GraficoGantt = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol2GraficoGantt.id = 'maquina_chartGantt_' + maquina.id //añadirle un ID



            var divMaquinaGraficosBodyRowCol3 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3.setAttribute('class', 'oee-comparativa  col-comparativa'); //añadirle clase 'oee-comparativa '


            // HEADER OEE
            var divMaquinaGraficosHeaderH3OEE = document.createElement('h3'); //crear el h3
            divMaquinaGraficosHeaderH3OEE.setAttribute('class', 'titulo-oee-comparativa'); //añadirle clase 'oee-comparativa '

            var divMaquinaGraficosHeaderH3OEELabel = document.createElement('label'); //crear el label
            divMaquinaGraficosHeaderH3OEELabel.innerHTML = this.translateService.instant("oee") //añadir titulo

            var divMaquinaGraficosBodyRowCol3GraficoDonutOEE = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3GraficoDonutOEE.style.height = "120px" //Darle altura '120'
            divMaquinaGraficosBodyRowCol3GraficoDonutOEE.id = 'maquina_chartDonutOEE_' + maquina.id //añadirle un ID

            var divMaquinaGraficosBodyRowCol3Row1 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3Row1.setAttribute('class', 'clearfix oee-labels'); //añadirle clase 'row', 'oee-labels'
            var divMaquinaGraficosBodyRowCol3Row1Label1 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('class', 'oee-label-titulo'); //añadirle clase 'oee-label-titulo'
            divMaquinaGraficosBodyRowCol3Row1Label1.innerHTML = this.translateService.instant("disponibilidad");//añadir titulo
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('title', this.translateService.instant("disponibilidad")); //añadirle texto al tooltip
            var divMaquinaGraficosBodyRowCol3Row1Label2 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row1Label2.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row1Label2.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row1Label2.setAttribute('class', 'oee-label-valor'); //añadirle clase 'oee-label-valor'
            divMaquinaGraficosBodyRowCol3Row1Label2.innerHTML = "0%";//añadir valor

            var divMaquinaGraficosBodyRowCol3Row2 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3Row2.setAttribute('class', 'clearfix oee-labels'); //añadirle clase 'row', 'oee-labels'
            var divMaquinaGraficosBodyRowCol3Row2Label1 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('class', 'oee-label-titulo'); //añadirle clase 'oee-label-titulo'
            divMaquinaGraficosBodyRowCol3Row2Label1.innerHTML = this.translateService.instant("rendimiento");//añadir titulo
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('title', this.translateService.instant("rendimiento")); //añadirle texto al tooltip
            var divMaquinaGraficosBodyRowCol3Row2Label2 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row2Label2.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row2Label2.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row2Label2.setAttribute('class', 'oee-label-valor'); //añadirle clase 'oee-label-valor'
            divMaquinaGraficosBodyRowCol3Row2Label2.innerHTML = "0%";//añadir valor

            var divMaquinaGraficosBodyRowCol3Row3 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3Row3.setAttribute('class', 'clearfix oee-labels'); //añadirle clase 'row', 'oee-labels'
            var divMaquinaGraficosBodyRowCol3Row3Label1 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('class', 'oee-label-titulo'); //añadirle clase 'oee-label-titulo'
            divMaquinaGraficosBodyRowCol3Row3Label1.innerHTML = this.translateService.instant("calidad");//añadir titulo
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('title', this.translateService.instant("calidad")); //añadirle texto al tooltip
            var divMaquinaGraficosBodyRowCol3Row3Label2 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row3Label2.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row3Label2.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row3Label2.setAttribute('class', 'oee-label-valor'); //añadirle clase 'oee-label-valor'
            divMaquinaGraficosBodyRowCol3Row3Label2.innerHTML = "0%";//añadir valor



            divMaquinaGraficosHeaderH3.appendChild(divMaquinaGraficosHeaderH3Label);
            divMaquinaGraficosBodyRowCol1.appendChild(divMaquinaGraficosHeaderH3);

            divMaquinaGraficosHeaderH3OEE.appendChild(divMaquinaGraficosHeaderH3OEELabel);
            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosHeaderH3OEE);


            divMaquinaGraficosBodyRowCol1.appendChild(divMaquinaGraficosBodyRowCol1GraficoDonut);
            divMaquinaGraficosBodyRow.appendChild(divMaquinaGraficosBodyRowCol1);
            divMaquinaGraficosBodyRowCol2.appendChild(divMaquinaGraficosBodyRowCol2GraficoGantt);
            divMaquinaGraficosBodyRow.appendChild(divMaquinaGraficosBodyRowCol2);
            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3GraficoDonutOEE);

            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3Row1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row1Label1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row1Label2);

            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3Row2);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row2Label1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row2Label2);

            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3Row3);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row3Label1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row3Label2);

            divMaquinaGraficosBodyRow.appendChild(divMaquinaGraficosBodyRowCol3);
            divMaquinaGraficosBody.appendChild(divMaquinaGraficosBodyRow);
            divMaquinaGraficos.appendChild(divMaquinaGraficosBody);

            //Div de cargando
            var divMaquinaGraficosCargando = document.createElement('div'); //crear el div
            divMaquinaGraficosCargando.id = "maquina_cargando_" + maquina.id;  //añadirle id
            divMaquinaGraficosCargando.style.backgroundColor = "rgba(255, 255, 255, 1)";
            divMaquinaGraficosCargando.setAttribute('class', 'k-i-loading');
            divMaquinaGraficos.appendChild(divMaquinaGraficosCargando);

            document.getElementById('divGraficos').appendChild(divMaquinaGraficos); //se añade el div donde queramos



            this.cargar_historicoMaquinas(maquina.id, divMaquinaGraficosBodyRowCol1GraficoDonut, divMaquinaGraficosBodyRowCol2GraficoGantt, divMaquinaGraficosBodyRowCol3GraficoDonutOEE, divMaquinaGraficosBodyRowCol3Row1Label2, divMaquinaGraficosBodyRowCol3Row2Label2, divMaquinaGraficosBodyRowCol3Row3Label2, divMaquinaGraficosCargando);
          }
        }
      }
    }
    else {
      if (this.selectedMaquinas.includes(idMaquina)) {
        for (let maquina of this.maquinas) {
          if (maquina.id == idMaquina) {
            var divMaquinaGraficos = document.createElement('div'); //crear el div
            divMaquinaGraficos.id = "maquina_graficos_" + maquina.id;  //añadirle id
            divMaquinaGraficos.setAttribute('class', 'card informe-comparativa'); //añadirle clases 'card', 'informe-comparativa'

            // HEADER DONUT 1
            var divMaquinaGraficosHeaderH3 = document.createElement('h3'); //crear el h3

            var divMaquinaGraficosHeaderH3Label = document.createElement('label'); //crear el label
            divMaquinaGraficosHeaderH3Label.innerHTML = maquina.nombre //añadir titulo

            //BODY
            var divMaquinaGraficosBody = document.createElement('div'); //crear el div
            divMaquinaGraficosBody.setAttribute('class', 'card-body'); //añadirle clase 'card-body'

            var divMaquinaGraficosBodyRow = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRow.setAttribute('class', 'clearfix'); //añadirle clase 'row'

            var divMaquinaGraficosBodyRowCol1 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol1.setAttribute('class', 'donut-comparativa  col-comparativa'); //añadirle clase 'col-md-3'

            var divMaquinaGraficosBodyRowCol1GraficoDonut = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol1GraficoDonut.style.height = "200px"  //Darle altura '200'
            divMaquinaGraficosBodyRowCol1GraficoDonut.id = 'maquina_chartDonut_' + maquina.id //añadirle un ID

            var divMaquinaGraficosBodyRowCol2 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol2.setAttribute('class', 'gantt-comparativa col-comparativa'); //añadirle clase 'col-md-8'

            var divMaquinaGraficosBodyRowCol2GraficoGantt = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol2GraficoGantt.id = 'maquina_chartGantt_' + maquina.id //añadirle un ID

            var divMaquinaGraficosBodyRowCol3 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3.setAttribute('class', 'oee-comparativa  col-comparativa'); //añadirle clase 'oee-comparativa '

            // HEADER OEE
            var divMaquinaGraficosHeaderH3OEE = document.createElement('h3'); //crear el h3
            divMaquinaGraficosHeaderH3OEE.setAttribute('class', 'titulo-oee-comparativa'); //añadirle clase 'oee-comparativa '

            var divMaquinaGraficosHeaderH3OEELabel = document.createElement('label'); //crear el label
            divMaquinaGraficosHeaderH3OEELabel.innerHTML = this.translateService.instant("oee") //añadir titulo


            var divMaquinaGraficosBodyRowCol3GraficoDonutOEE = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3GraficoDonutOEE.style.height = "120px" //Darle altura '120'
            divMaquinaGraficosBodyRowCol3GraficoDonutOEE.id = 'maquina_chartDonutOEE_' + maquina.id //añadirle un ID

            var divMaquinaGraficosBodyRowCol3Row1 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3Row1.setAttribute('class', 'clearfix oee-labels'); //añadirle clase 'row', 'oee-labels'
            var divMaquinaGraficosBodyRowCol3Row1Label1 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('class', 'oee-label-titulo'); //añadirle clase 'oee-label-titulo'
            divMaquinaGraficosBodyRowCol3Row1Label1.innerHTML = this.translateService.instant("disponibilidad");//añadir titulo
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row1Label1.setAttribute('title', this.translateService.instant("disponibilidad")); //añadirle texto al tooltip
            var divMaquinaGraficosBodyRowCol3Row1Label2 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row1Label2.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row1Label2.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row1Label2.setAttribute('class', 'oee-label-valor'); //añadirle clase 'oee-label-valor'
            divMaquinaGraficosBodyRowCol3Row1Label2.innerHTML = "0%";//añadir valor

            var divMaquinaGraficosBodyRowCol3Row2 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3Row2.setAttribute('class', 'clearfix oee-labels'); //añadirle clase 'row', 'oee-labels'
            var divMaquinaGraficosBodyRowCol3Row2Label1 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('class', 'oee-label-titulo'); //añadirle clase 'oee-label-titulo'
            divMaquinaGraficosBodyRowCol3Row2Label1.innerHTML = this.translateService.instant("rendimiento");//añadir titulo
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row2Label1.setAttribute('title', this.translateService.instant("rendimiento")); //añadirle texto al tooltip
            var divMaquinaGraficosBodyRowCol3Row2Label2 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row2Label2.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row2Label2.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row2Label2.setAttribute('class', 'oee-label-valor'); //añadirle clase 'oee-label-valor'
            divMaquinaGraficosBodyRowCol3Row2Label2.innerHTML = "0%";//añadir valor

            var divMaquinaGraficosBodyRowCol3Row3 = document.createElement('div'); //crear el div
            divMaquinaGraficosBodyRowCol3Row3.setAttribute('class', 'clearfix oee-labels'); //añadirle clase 'row', 'oee-labels'
            var divMaquinaGraficosBodyRowCol3Row3Label1 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('class', 'oee-label-titulo'); //añadirle clase 'oee-label-titulo'
            divMaquinaGraficosBodyRowCol3Row3Label1.innerHTML = this.translateService.instant("calidad");//añadir titulo
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row3Label1.setAttribute('title', this.translateService.instant("calidad")); //añadirle texto al tooltip
            var divMaquinaGraficosBodyRowCol3Row3Label2 = document.createElement('label'); //crear el label
            divMaquinaGraficosBodyRowCol3Row3Label2.setAttribute('data-toggle', 'tooltip'); //añadirle tooltip al label
            divMaquinaGraficosBodyRowCol3Row3Label2.setAttribute('data-placement', 'bottom'); //añadir posicionamiento del tooltip
            divMaquinaGraficosBodyRowCol3Row3Label2.setAttribute('class', 'oee-label-valor'); //añadirle clase 'oee-label-valor'
            divMaquinaGraficosBodyRowCol3Row3Label2.innerHTML = "0%";//añadir valor



            divMaquinaGraficosHeaderH3.appendChild(divMaquinaGraficosHeaderH3Label);
            divMaquinaGraficosBodyRowCol1.appendChild(divMaquinaGraficosHeaderH3);

            divMaquinaGraficosHeaderH3OEE.appendChild(divMaquinaGraficosHeaderH3OEELabel);
            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosHeaderH3OEE);

            divMaquinaGraficosBodyRowCol1.appendChild(divMaquinaGraficosBodyRowCol1GraficoDonut);
            divMaquinaGraficosBodyRow.appendChild(divMaquinaGraficosBodyRowCol1);
            divMaquinaGraficosBodyRowCol2.appendChild(divMaquinaGraficosBodyRowCol2GraficoGantt);
            divMaquinaGraficosBodyRow.appendChild(divMaquinaGraficosBodyRowCol2);
            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3GraficoDonutOEE);

            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3Row1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row1Label1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row1Label2);

            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3Row2);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row2Label1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row2Label2);

            divMaquinaGraficosBodyRowCol3.appendChild(divMaquinaGraficosBodyRowCol3Row3);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row3Label1);
            divMaquinaGraficosBodyRowCol3Row1.appendChild(divMaquinaGraficosBodyRowCol3Row3Label2);

            divMaquinaGraficosBodyRow.appendChild(divMaquinaGraficosBodyRowCol3);
            divMaquinaGraficosBody.appendChild(divMaquinaGraficosBodyRow);
            divMaquinaGraficos.appendChild(divMaquinaGraficosBody);

            //Div de cargando
            var divMaquinaGraficosCargando = document.createElement('div'); //crear el div
            divMaquinaGraficosCargando.id = "maquina_cargando_" + maquina.id;  //añadirle id
            divMaquinaGraficosCargando.style.backgroundColor = "rgba(255, 255, 255, 1)";
            divMaquinaGraficosCargando.setAttribute('class', 'k-i-loading');
            divMaquinaGraficos.appendChild(divMaquinaGraficosCargando);

            document.getElementById('divGraficos').appendChild(divMaquinaGraficos); //se añade el div donde queramos



            this.cargar_historicoMaquinas(maquina.id, divMaquinaGraficosBodyRowCol1GraficoDonut, divMaquinaGraficosBodyRowCol2GraficoGantt, divMaquinaGraficosBodyRowCol3GraficoDonutOEE, divMaquinaGraficosBodyRowCol3Row1Label2, divMaquinaGraficosBodyRowCol3Row2Label2, divMaquinaGraficosBodyRowCol3Row3Label2, divMaquinaGraficosCargando);
          }
        }
      } else {
        //QUITAR EL DIV DE LA PAGINA
        document.getElementById("maquina_graficos_" + idMaquina).remove();
        //QUITAR EL GANTT DE LA LISTA DE GANTTS
        this.selectedMaquinasGantt.splice(this.selectedMaquinasGantt.findIndex(a => a.__obj_container.id === "maquina_chartGantt_" + idMaquina), 1)
      }
    }
  }
  private maquinaClicked(idMaquina, sender) {
    //Maquina activa
    //if (sender?.path[0].classList.contains("actual") || sender?.path[1].classList.contains("actual")) {   
    if (this.selectedMaquinas.indexOf(idMaquina) != -1) {
      //Quitar idMaquina de la lista
      const index: number = this.selectedMaquinas.indexOf(idMaquina);
      if (index !== -1) {
        this.selectedMaquinas.splice(index, 1);
      }
      //Maquina NO activa
    }
    else {
      //Añadir idMaquina de la lista
      this.selectedMaquinas.push(idMaquina);
    }

    this.cargarEstructura(idMaquina);
  }
  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  cargar_historicoMaquinas(idMaquina, divChartDonut, divChartGantt, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue, divMaquinaGraficosCargando) {
    var filtro = this.myFilter.crearFiltro_rapido_AnalisisRendimiento([this.fechaInicio, this.fechaFin], [], idMaquina)

    var MinMax = this.myFilter.filtroFechas_MIN_MAX(filtro, true);
    var fechaInicio = MinMax[0];
    var fechaFin = MinMax[1];

    if (!this.historicoMaquinasDatosService.util(fechaInicio, fechaFin)) {
      this.historicoMaquinasDatosService.cargar_historico_completo(fechaInicio, fechaFin).subscribe(
        j => {
          this.historicoMaquinasDatosService.set_historico_datos(j, fechaInicio, fechaFin);
          this.cargado_historicoMaquinas(idMaquina, divChartDonut, divChartGantt, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue, divMaquinaGraficosCargando);
        });
    } else {
      this.cargado_historicoMaquinas(idMaquina, divChartDonut, divChartGantt, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue, divMaquinaGraficosCargando);
    }
  }
  cargado_historicoMaquinas(idMaquina, divChartDonut, divChartGantt, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue, divMaquinaGraficosCargando) {
    // Como en esta pagina no hay un filtro nuevo, se apaña creando la misma estructura desde atras.
    var filtro = this.myFilter.crearFiltro_rapido_AnalisisRendimiento([this.fechaInicio, this.fechaFin], [], idMaquina)
    // Se combierte en filtro Kendo para usar sobre JSON
    var filtro_kendo: any = this.myFilter.filtroToKendo(filtro)

    var historico_completo_filtrado = this.historicoMaquinasDatosService.get_historicoMauqinas_filtrado(filtro_kendo);

    this.cargarGraficos_conDatos(historico_completo_filtrado, idMaquina, divChartDonut, divChartGantt, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue, divMaquinaGraficosCargando);
  }

  //#region CARGA DE GRAFICOS POR MAQUINA
  public cargarGraficos_conDatos(historico_completo_filtrado, idMaquina, divChartDonut, divChartGantt, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue, divMaquinaGraficosCargando) {
    this.cargar_GANTT(historico_completo_filtrado, idMaquina, divChartGantt);

    this.cargar_GraficosResume_Estados(historico_completo_filtrado, idMaquina, divChartDonut);

    this.cargar_GraficosOEE(historico_completo_filtrado, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue);

    // LOADING PANEL
    divMaquinaGraficosCargando.remove();
  }
  // GANTT  
  //ESTA FUNCION FILTRA LOS DATOS LEIDOS DESDE LA BASE DE DATOS
  cargar_GANTT(historico_completo_filtrado, idMaquina, divChartGantt) {
    var chartGantt = this.historicoMaquinasDatosService.load_historico_completo_GANTT_vinculados(divChartGantt.id, idMaquina, this.fechaInicio, this.fechaFin, historico_completo_filtrado, this.selectedMaquinasGantt)
    this.selectedMaquinasGantt.push(chartGantt);
  }
  // RESUMEN SEMANA: SubEstados Y SubEstados Agrupados
  //ESTA FUNCION FILTRA LOS DATOS LEIDOS DESDE LA BASE DE DATOS con un TimeOut porque necesita una carga inicial con los estados o subestados para cargar bien luego los tiempos.
  cargar_GraficosResume_Estados(historico_completo_filtrado, idMaquina, divChartDonut) {
    // ESTADOS
    //    Donut
    var jEstados = this.historicoMaquinasDatosService.get_info_estados([idMaquina]);
    // Estados
    var columnasVacias = []; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos. 
    var nombresVacios = {}; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos.
    var colores = [];
    // El grafico de lineas mostrara siempre 7 dias en este caso, de lunes a domingo de la misma semana, en caso de necesitar mas dias, aqui se deverian introducir las fechas de los dias a mostrar.
    var columnasVacias_Diario = [['x']];
    var grupos = []

    jEstados.forEach(
      subestado => {
        // Variables para cargar el grafico vacio (DONUT)
        columnasVacias.push([subestado.nombre, 0]);
        nombresVacios[subestado.nombre] = subestado.nombre;
        colores.push(subestado.color);
        // Variables para cargar el grafico vacio (LINEAS POR DIAS), si el grafico mostrara mas de 7 dias (lunes-domingo), el numero de "",0" se deveria aumentar al mismo numero de dias que se muestre.
        columnasVacias_Diario.push([subestado.nombre]);
        grupos.push(subestado.nombre);
      });

    //RESUMEN SEMANA
    var chartDonutResumenSemana_Estados = c3.generate({
      bindto: divChartDonut,
      data: {
        columns: this.myFunctions.copy(columnasVacias),
        names: this.myFunctions.copy(nombresVacios),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#' + divChartDonut.id + ' .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#" + divChartDonut.id + " .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#' + divChartDonut.id + ' .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: this.myFunctions.copy(colores)
      },
      legend: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    this.historicoMaquinasDatosService.load_resumen_estados_data_Donut_prefiltrado(chartDonutResumenSemana_Estados, [idMaquina], this.fechaInicio, this.fechaFin, [], historico_completo_filtrado)

  }
  // OEE:
  //  Estos graficos no necesitan de una carga inicial, cuando se carguen los tiempos tendran los mismos "estados"
  cargar_GraficosOEE(historico_completo_filtrado, divChartDonutOEE, divDisponibilidadValue, divRendimientoValue, divCalidadValue) {
    // DONUTS
    var OEE_donut_calculado: any = this.historicoMaquinasDatosService.get_OEE_data_Donut_prefiltrado(historico_completo_filtrado);

    divDisponibilidadValue.innerHTML = OEE_donut_calculado.disponibilidad_porcentaje.toFixed(2) + "%"
    divDisponibilidadValue.setAttribute('title', OEE_donut_calculado.disponibilidad_porcentaje.toFixed(2) + "%"); //añadirle texto al tooltip
    divRendimientoValue.innerHTML = OEE_donut_calculado.rendimiento_porcentaje.toFixed(2) + "%"
    divRendimientoValue.setAttribute('title', OEE_donut_calculado.rendimiento_porcentaje.toFixed(2) + "%"); //añadirle texto al tooltip
    divCalidadValue.innerHTML = OEE_donut_calculado.calidad_porcentaje.toFixed(2) + "%"
    divCalidadValue.setAttribute('title', OEE_donut_calculado.calidad_porcentaje.toFixed(2) + "%"); //añadirle texto al tooltip

    //OEE
    var chartDonutOEE = c3.generate({
      bindto: divChartDonutOEE,
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        names: {
          oee: this.translateService.instant("oee"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });

    d3.select('#' + divChartDonutOEE.id + ' .c3-chart-arcs-title')
      .style("font-size", "25px")
      .style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.OEE_porcentaje.toFixed(2)) + "%");

    chartDonutOEE.load(OEE_donut_calculado.OEE);
  }
  //#endregion

  //FECHA
  public fechaInicio_dateChanged(value: Date): void {

    //BORRAR TODOS LOS TOOLTIPS DEL GANTT, PORQUE SE VAN A VOLVER A CREAR
    this.selectedMaquinasGantt = [];
    var tooltips = document.getElementsByClassName('tooltip-gantt');
    while (tooltips[0]) {
      tooltips[0].parentNode.removeChild(tooltips[0]);
    }

    this.cargarFechas();
    this.cargarEstructura(-1);
  }
  public fechaFin_dateChanged(value: Date): void {
  }

}
