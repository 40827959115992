<!-- TODO -->
<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)" *ngIf="(router.url === '/planesaccion/crear' || router.url.startsWith('/planesaccion/editar')) && !router.url.startsWith('/acciones')">
  <!-- INFO -->
  <div id="divDatosPieza" class="card">
    <div class="card-header">
      <h3>{{ 'datos' | translate }}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="row">
            <!-- NOMBRE -->
            <div class="form-group col-12">
              <label ID="lNombrePlan" class="control-label">{{ 'nombre' | translate }}</label>
              <div class="caja">
                <input formControlName="nombre" [ngClass]="{ 'is-invalid': nombreRequerido }" kendoTextBox [(value)]="nombre" />
              </div>
            </div>
            <!-- FECHA INICIO -->
            <div class="col-md-3">
              <div class="form-group ">
                <label ID="lFinicio" class="control-label">{{ 'fechainicio' | translate }}</label>
                <div class="caja">
                  <kendo-datepicker formControlName="fechaini" ID="rntFinicio" class="form-control" [(value)]="fechaini"></kendo-datepicker> <!--[(placeholder)]="fechaHoyStr"-->
                </div>
              </div>
            </div>
            <!-- FECHA FIN -->
            <div class="col-md-3">
              <div class="form-group ">
                <label ID="lFinicio" class="control-label">{{ 'fechafin' | translate }}</label>
                <div class="caja">
                  <kendo-datepicker formControlName="fechafin" ID="rntFfin" class="form-control" [(value)]="fechafin"></kendo-datepicker> <!--[(placeholder)]="fechaHoyStr"-->
                </div>
              </div>
            </div>
            <!-- RESPONSABLE -->
            <div class="col-md-6">
              <div class="form-group">
                <label ID="lResponsable" class="control-label">{{ 'responsable' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist style="width:100%" valuePrimitive="true" formControlName="idresponsable" [data]="responsables" [textField]="'nombrecompleto'" valueField="id" [(ngModel)]="idResponsable">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DESCRIPCION -->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="form-group">
            <label ID="lDescripcionPlan" class="control-label">{{ 'descripcion' | translate }}</label>
            <div class="caja">
              <textarea formControlName="descripcion" kendoTextArea style="height: 106px;" class="form-control" [(value)]="descripcion"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- GRID -->
  <div class="clearfix" id="capa_acciones" *ngIf="!isAddMode">
    <kendo-grid [data]="acciones" kendoGridSelectBy="id" [selectedKeys]="accionesseleccionadas" (cellClick)="cellClick($event)" class="grid_datos_acciones">
      <ng-template kendoGridToolbarTemplate position="top">
        <a ID="bNuevo" (click)="onClickNuevaAccion()" class="btnguardar btn btn-success btn-sm mr-1">{{ 'nuevo' | translate }}</a>
        <a *ngIf="botonesVisible==true" ID="bEditar" (click)="onClickEditarAccion()" class="btncancelar btn btn-primary btn-sm mr-1">{{ 'editar' | translate }}</a>
        <a *ngIf="botonesVisible==true" ID="bEliminar" (click)="onClickEliminarAccion(content)" 
              class="btneliminar btn-danger btn btn-success btn-sm mr-1"   [class.disabled]="this.user.planesDeAccion<2">{{ 'eliminar' | translate }}</a>
      </ng-template>
      <kendo-grid-checkbox-column [width]="30" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column field="id" hidden="hidden" title="ID" width="40"></kendo-grid-column>
      <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="280%"></kendo-grid-column>
      <kendo-grid-column field="fechaini" title="{{ 'fechainicio' | translate }}" width="70%"></kendo-grid-column>
      <kendo-grid-column field="fechafin" title="{{ 'fechafin' | translate }}" width="70%"></kendo-grid-column>
      <kendo-grid-column field="descripcion" title="{{ 'descripcion' | translate }}" width="280%"></kendo-grid-column>
      <kendo-grid-column field="responsable" title="{{ 'responsable' | translate }}" width="140%"></kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
  <!-- BOTONES -->
  <div class="form-group">
    <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.planesDeAccion<2">{{ 'guardar' | translate}}</button>
    <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="Atras()">{{ 'cancelar' | translate}}</button>
  </div>
</form>

<!-- POPUP: Eliminar seguro? -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="Eliminar(contentloading)" [disabled]="this.user.planesDeAccion<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Eliminado -->
<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p id="text_loading_popup"></p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
