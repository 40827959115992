import { Component, ComponentFactoryResolver } from '@angular/core';
import { InformeRendimientoService, MaquinasService, UsuariosService, HmiService, InformeOeeService, MenuService, HistoricoMaquinasDatosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions, MyFilter, MyCharts } from '@app/_helpers';
import * as c3 from 'c3';
import * as d3 from 'd3';

import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { createTypePredicateNodeWithModifier } from 'typescript';

interface ItemTurno {
  nombre: string,
  id: number
}
interface ItemTurnoGantt {
  min: Date,
  max: Date,
  clase: string
}

@Component({
  selector: 'app-analisis-rendimiento',
  templateUrl: './analisisRendimiento.component.html'
})

export class AnalisisRendimientoComponent {
  user = this.userService.userValue;

  public maquinaPreSeleccionada: number;
  public aparecer = false;

  public form: FormGroup;
  public idTurno: Number;

  public Jmaquinas: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  public JdatosGANTT: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  // public JdatosResumenSemana: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  public selectedMaquina: number = 0;
  public turnoNochePrimero: boolean = false;
  public turnosCombo: Array<ItemTurno> = [];
  // public turnosComboSelected: string = ""; // solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
  public comboTurnosSeleccion: any = [];
  public turnos_placeHolder: string = "";
  public fechaInicio: Date;
  public fechaFin: Date;

  public turnos: JSON;
  public turnosArray: Array<ItemTurnoGantt> = [];
  public chartGanttTimeline;
  public visibleTipo: boolean = true;
  public chartDonutResumenSemana_Estados;
  public sinEstados = true;
  public chartDonutResumenSemana_subEstados;
  public sinSubestados = true;
  public dataResumenSemana: any;
  public chartAreaHorasDia_Estados;
  public chartBar_turnos;
  public sinPiezasTurnos = true;
  public piezasBuenasMannana: number = 0.0;
  public piezasMalasMannana: number = 0.0;
  public porcentajeMannana: number = 0.0;
  public piezasBuenasTarde: number = 0.0;
  public piezasMalasTarde: number = 0.0;
  public porcentajeTarde: number = 0.0;
  public piezasBuenasNoche: number = 0.0;
  public piezasMalasNoche: number = 0.0;
  public porcentajeNoche: number = 0.0;
  public mostrarGraficoPiezasTurnos = true; 
  public dataPiezasTurnos = [];
  public mostrarEnHoras: boolean = false;
  public mostrarContadorTipo: Number = 1; // 1 MANUAL , 2 AUTOMATICO , 3 MANUAL
  public mostrarContadorAccion: Number = 1; // 1 ATRASAR , 2 ADELANTAR
  public mostrarContadorRepartir: Number = 1; // 1 AGRUPAR , 2 REPARTIR

  public chartAreaHorasDia_subEstados;
  public dataHorasDiaLunes: any;
  public dataHorasDiaMartes: any;
  public dataHorasDiaMiercoles: any;
  public dataHorasDiaJueves: any;
  public dataHorasDiaViernes: any;
  public dataHorasDiaSabado: any;
  public dataHorasDiaDomingo: any;
  public chartDonutOEE;
  public dataChartDonutOEE: any;
  public chartDonutOEEDisponibilidad;
  public dataChartDonutDisponibilidad: any;
  public chartDonutOEERendimiento;
  public dataChartDonutRendimiento: any;
  public chartDonutOEECalidad;
  public dataChartDonutCalidad: any;
  public chartBarHoras;
  private dataGraficoHoras: any;
  public dataOeeOffline: any;
  // public dataGraficoDonutParadas = [];
  // public dataGraficoDonutParadasParaTooltip: any = {};
  // public dataGraficoDonutMantenimientos = [];
  // public dataGraficoDonutMantenimientosParaTooltip: any = {};
  // public dataGraficoDonutAlarmas = [];
  // public dataGraficoDonutAlarmasParaTooltip: any = {};
  public dataPerdidasOffline: any;
  public dataPerdidas: any;

  public tieneSubEstados_personalizados = false; //subestados > 100
  // public jEstados: any = [];
  // public jSubEstados: any = [];

  public loadingGantt: boolean = true;
  public loadingResumenSemana_Estados: boolean = true;
  public loadingResumenSemana_subEstados: boolean = true;
  public loadingHorasDia_Estados: boolean = true;
  public loadingHorasDia_subEstados: boolean = true;
  public loadingOeeHoras: boolean = true;
  public loadingPerdidas: boolean = true;

  private tiempoTransition: Number = 2000;

  // LOS COLORES AHORA VIENEN DE SUBESTADOS
  private colorOEEActivo: String = "#18D6B0";
  private colorOEENoActivo: String = "#3D6063";

  private selectedOperariosGantt: any = [];
  private esOffline: boolean;

  //PANEL MAQUINAS
  //private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  agrupado: number;

  maquinasDesplegadas = false;
  maquinasDesplegadasCount = 0; //para que solo se realice una vez

  public fechaMin: Date;
  public fechaMax: Date;

  // TURNO
  // private turno: number = -1;
  public listaIdTurnos: any = [];
  public turnoInt: number = -1;

  public graficoDonutPerdidas;
  public sinPerdidas = true;
  public graficoDonutMantenimientos;
  public sinMantenimientos = true;
  public graficoDonutAlarmas;
  public sinAlarmas = true;



  constructor(private InformeRendimientoService: InformeRendimientoService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private hmiService: HmiService,
    private informeOeeService: InformeOeeService,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private myFunctions: MyFunctions,
    private historicoMaquinasDatosService: HistoricoMaquinasDatosService,
    private myFilter: MyFilter,
    private myCharts: MyCharts) {

    this.userService.user.subscribe(x => this.user = x);

    this.menuService.titulo = this.translate.instant('analisisRendimiento').toUpperCase();

    this.cargarMeses();

    this.cargarGrupos();

    this.cargarTurnosCombo();
    this.cargarFechas();
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      this.cargarAreasProductivas();
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }
  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    //ARATZ (01/09/2021): en esta pagina no siempre se pasa IDmaquina, y si se pasa 0 no funciona el primer click en el grafico.
    //                    La maquina viene cargada en el OnInit()

    if (this.selectedMaquina == 0) {
      this.maquinaPreSeleccionada = parseInt(this.route.snapshot.params['idMaquina'])
      this.selectedMaquina = parseInt(this.route.snapshot.params['idMaquina']);
    }

    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        var maquinaSeleccionada = this.maquinas.find(f => f.id == this.selectedMaquina);
        if(maquinaSeleccionada != undefined) this.turnoNochePrimero = maquinaSeleccionada.turnoNochePrimero;
        else if ( this.maquinas.length > 0) this.turnoNochePrimero = this.maquinas[0].turnoNochePrimero;
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2 && this.maquinas.length > 0) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.maquinas = maquinas_model;
      var maquinaSeleccionada = this.maquinas.find(f => f.id == this.selectedMaquina);
      if(maquinaSeleccionada != undefined) this.turnoNochePrimero = maquinaSeleccionada.turnoNochePrimero;
      else if ( this.maquinas.length > 0) this.turnoNochePrimero = this.maquinas[0].turnoNochePrimero;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2 && this.instalaciones.length > 0) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }
  }
  filtrarMaquinasPorAreaProductivaYGrupo(cargar = true) {


    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }
    else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }
    else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0 && !(this.selectedMaquina > 0)) this.selectedMaquina = this.maquinasMostradas[0].id;

    if (cargar) {
      this.cargar_historicoMaquinas();
      // this.cargarInformeRendimiento();
    }
  }

  private cargarTurnosCombo() {
    this.turnos_placeHolder = this.translate.instant("seleccioneTurnos")
    this.turnosCombo = [
      { nombre: this.translate.instant("manana"), id: 1 },
      { nombre: this.translate.instant("tarde"), id: 2 },
      { nombre: this.translate.instant("noche"), id: 3 }
    ];
  }
  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.fechaInicio == undefined) {
      this.fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = this.fechaInicio
    if (fechaIni.getDay() == 3) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.fechaInicio);
    this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.fechaInicio.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);
  }
  ngOnInit() {
    //TRADUCCIÓN AUTOMATICA
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.cargarTurnosCombo();
    });

    this.agrupado = 1;


    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });

    this.dibujarGraficos();

    this.dibujarGraficosDonuts();

    // // DONUT PARADAS
    // var that = this;
    // this.chartDonutParadas = c3.generate({
    //   data: {
    //     columns: this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }),
    //     type: 'donut',
    //     onmouseover: function (d) {
    //       d3.select('#chartDonutParadas .c3-chart-arcs-title')
    //         .append("tspan")
    //         .attr("font-size", "35")
    //         .text((d.ratio * 100).toFixed(1) + "%");
    //       d3.select("#chartDonutParadas .c3-chart-arcs-title")
    //         .append("tspan")
    //         .attr("dy", 24)
    //         .attr("x", 0)
    //         .attr("font-size", "10")
    //         .text(d.id);
    //     },
    //     onmouseout: function (d) {
    //       d3.select('#chartDonutParadas .c3-chart-arcs-title').node().innerHTML = "";
    //     },
    //   },
    //   transition: {
    //     duration: 2000
    //   },
    //   legend: {
    //     //bottom, right, inset
    //     position: 'right'
    //   },
    //   color: {
    //     pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
    //   },
    //   tooltip: {
    //     show: true,
    //     contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
    //       if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
    //         d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
    //       return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
    //     }
    //   },
    //   bindto: '#chartDonutParadas'
    // });
    // // DONUT MANTENIMIENTOS
    // this.chartDonutMantenimientos = c3.generate({
    //   data: {
    //     columns: this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }),
    //     type: 'donut',
    //     onmouseover: function (d) {
    //       d3.select('#chartDonutMantenimientos .c3-chart-arcs-title')
    //         .append("tspan")
    //         .attr("font-size", "35")
    //         .text((d.ratio * 100).toFixed(1) + "%");
    //       d3.select("#chartDonutMantenimientos .c3-chart-arcs-title")
    //         .append("tspan")
    //         .attr("dy", 24)
    //         .attr("x", 0)
    //         .attr("font-size", "10")
    //         .text(d.id);
    //     },
    //     onmouseout: function (d) {
    //       d3.select('#chartDonutMantenimientos .c3-chart-arcs-title').node().innerHTML = "";
    //     },
    //   },
    //   transition: {
    //     duration: 2000
    //   },
    //   legend: {
    //     //bottom, right, inset
    //     position: 'right'
    //   },
    //   tooltip: {
    //     show: true,
    //     contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
    //       if (that.dataGraficoDonutMantenimientosParaTooltip[d[0].id] != undefined)
    //         d[0].name = that.dataGraficoDonutMantenimientosParaTooltip[d[0].id];
    //       return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
    //     }
    //   },
    //   bindto: '#chartDonutMantenimientos'
    // });
    // // SONUT ALARMAS
    // this.chartDonutAlarmas = c3.generate({
    //   data: {
    //     columns: this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }),
    //     type: 'donut',
    //     onmouseover: function (d) {
    //       d3.select('#chartDonutAlarmas .c3-chart-arcs-title')
    //         .append("tspan")
    //         .attr("font-size", "35")
    //         .text((d.ratio * 100).toFixed(1) + "%");
    //       d3.select("#chartDonutAlarmas .c3-chart-arcs-title")
    //         .append("tspan")
    //         .attr("dy", 24)
    //         .attr("x", 0)
    //         .attr("font-size", "10")
    //         .text(d.id);

    //     },
    //     onmouseout: function (d) {
    //       d3.select('#chartDonutAlarmas .c3-chart-arcs-title').node().innerHTML = "";
    //     },
    //   },
    //   transition: {
    //     duration: 2000
    //   },
    //   legend: {
    //     //bottom, right, inset
    //     position: 'right'
    //   },
    //   color: {
    //     pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
    //   },
    //   tooltip: {
    //     show: true,
    //     contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
    //       if (that.dataGraficoDonutAlarmasParaTooltip[d[0].id] != undefined)
    //         d[0].name = that.dataGraficoDonutAlarmasParaTooltip[d[0].id];
    //       return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
    //     }
    //   },
    //   bindto: '#chartDonutAlarmas'
    // });

    // d3.selectAll("#chartDonutResumenSemana .c3-chart-arcs path").style("stroke-width", "0px");

    // d3.selectAll("#chartDonutOEE_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");
    // d3.selectAll("#chartDonutOEEDisponibilidad_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");
    // d3.selectAll("#chartDonutOEERendimiento_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");
    // d3.selectAll("#chartDonutOEECalidad_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");

    // d3.selectAll("#chartDonutParadas .c3-chart-arcs path").style("stroke-width", "0px");
    // d3.selectAll("#chartDonutMantenimientos .c3-chart-arcs path").style("stroke-width", "0px");
    // d3.selectAll("#chartDonutAlarmas .c3-chart-arcs path").style("stroke-width", "0px");
  }
  // private cargarInformeRendimiento() {
  //       this.loadingGantt = true;
  //   this.loadingResumenSemana_Estados = true;
  //   this.loadingResumenSemana_subEstados = true;
  //   this.loadingHorasDia_Estados = true;
  //   this.loadingHorasDia_subEstados = true;
  //   this.loadingOeeHoras = true;
  //   this.loadingPerdidas = true;

  //   this.cargar_historicoMaquinas();

  //   var listaIdsTurnos = (this.comboTurnosSeleccion === undefined) ? [] : this.comboTurnosSeleccion.map(a => a.id);

  //   this.turnoInt = -1;
  //   var lag: string = "";
  //   if (listaIdsTurnos.length == 0) {
  //     this.turnoInt = -1;
  //   } else {
  //     listaIdsTurnos.forEach(turno => {
  //       lag = lag + turno.toString();
  //     });
  //     this.turnoInt = +lag;
  //   }
  //   this.listaIdTurnos = listaIdsTurnos;

  //   // GRAFICOS ALARMAS Y MANTENIMIENTOS
  //   this.informeOeeService.Get_Alarmas_Mantenimientos(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), listaIdsTurnos.join(",")).subscribe(
  //     data => {
  //       var dataAlarmasMantenimientos: any = data;
  //       var donutDataMantenimientos: any = [];
  //       var donutDataAlarmas: any = [];

  //       dataAlarmasMantenimientos.forEach(function (obj) {

  //         //MANTENIMIENTOS (idProcesos_Tipo = 4, 10)
  //         if ((obj.idProcesos_Tipo == 4 || obj.idProcesos_Tipo == 10) && obj.tiempoPerdida > 0) {
  //           this.dataGraficoDonutMantenimientosParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
  //           donutDataMantenimientos.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
  //           document.getElementById('lblNoMantenimientos_InfRend').style.display = 'none';
  //           document.getElementById('chartDonutMantenimientos').style.display = 'block';
  //         }

  //         //ALARMAS (idProcesos_Tipo = 6, 7)
  //         if ((obj.idProcesos_Tipo == 6 || obj.idProcesos_Tipo == 7) && obj.tiempoPerdida > 0) {
  //           this.dataGraficoDonutAlarmasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
  //           donutDataAlarmas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
  //           document.getElementById('lblNoAlarmas_InfRend').style.display = 'none';
  //           document.getElementById('chartDonutAlarmas').style.display = 'block';
  //         }

  //       }, this);

  //       var oldDataGraficoDonutMantenimientos = this.dataGraficoDonutMantenimientos;
  //       this.dataGraficoDonutMantenimientos = donutDataMantenimientos.sort(function (a, b) { return b[1] - a[1]; });
  //       this.dataGraficoDonutMantenimientos = this.acortarDatosGraficoDonuts(this.dataGraficoDonutMantenimientos);

  //       var oldDataGraficoDonutAlarmas = this.dataGraficoDonutAlarmas;
  //       this.dataGraficoDonutAlarmas = donutDataAlarmas.sort(function (a, b) { return b[1] - a[1]; });
  //       this.dataGraficoDonutAlarmas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutAlarmas);


  //       this.updateDonutLeyenda(this.chartDonutMantenimientos, oldDataGraficoDonutMantenimientos, this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }))
  //       this.updateDonutLeyenda(this.chartDonutAlarmas, oldDataGraficoDonutAlarmas, this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }))

  //       d3.selectAll("#chartDonutMantenimientos .c3-chart-arcs path").style("stroke-width", "0px");
  //       d3.selectAll("#chartDonutAlarmas .c3-chart-arcs path").style("stroke-width", "0px");


  //     }
  //   );

  //   // GRAFICOS PERDIDAS
  //   this.informeOeeService.Get_Perdidas_Todas(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), this.listaIdTurnos.join(",")).subscribe(data => {
  //     this.dataPerdidas = data;
  //     this.cargarDatosPerdidas();
  //   });
  // }
  public turnos_onValueChange(value: any): void {
    // this.turnosComboSelected = ""; // solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato

    // for (let turno of value) {// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    //   this.turnosComboSelected += turno.id + ", ";// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    // }// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato

    // this.turnosComboSelected = this.turnosComboSelected.substring(0, this.turnosComboSelected.length - 2);// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato

    // if (this.turnosComboSelected == "") {// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    //   this.turnosComboSelected = "";// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    // }// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    this.cargar_historicoMaquinas();
    // this.filtrarInformeRendimiento();
  }
  public fechaInicio_dateChanged(value: Date): void {
    this.cargar_historicoMaquinas();
    // this.cargarInformeRendimiento();
  }
  public fechaFin_dateChanged(value: Date): void {
    this.cargar_historicoMaquinas();
    // this.cargarInformeRendimiento();
  }
  public checkboxChange(tipo): void { //función para saber si las pérdidas están agrupadas por grupos
    this.agrupado = tipo;
    // this.cargarDatosPerdidas();
  }
  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnseñar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnseñar.push(['Otros', totalOtros]);
      return arrayParaEnseñar;
    }
  }
  public updateDonutLeyenda(grafico, oldData, newData) {

    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }

    var names = {};
    newData.forEach(function (a) {
      names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
    }, this);

    grafico.load({
      columns: newData,
      names: names
    });
    grafico.unload({
      ids: nombresAEliminar
    });
  }
  // public cortarLeyenda(inputText, trimmed = false) {

  //   var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

  //   var canvas = document.createElement("canvas");
  //   var context = canvas.getContext("2d");
  //   context.font = font;
  //   var width = context.measureText(inputText).width;

  //   if (width > 160) {
  //     return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
  //   } else if (trimmed) {
  //     return inputText + "...";
  //   } else {
  //     return inputText;
  //   }

  // }
  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }








  dibujarGraficos() {


    //RESUMEN SEMANA
    this.chartDonutResumenSemana_Estados = c3.generate({
      bindto: '#chartDonutResumenSemana_Estados',
      data: {
        columns: [
          ['sinDatos', 0],
        ],
        names: {
          sinDatos: this.translate.instant("sinDatos"),
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_Estados .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_Estados .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_Estados .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    //HORAS/DÍA
    this.chartAreaHorasDia_Estados = c3.generate({
      bindto: '#chartAreaHorasDia_Estados',
      data: {
        x: 'x',
        columns: [
          ['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")],
          ['sinDatos', 0, 0, 0, 0, 0, 0, 0],
        ],
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [['sinDatos']],
        names: {
          sinDatos: this.translate.instant("sinDatos"),
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });

    //TURNOS
    this.chartBar_turnos = this.myCharts.dibujarGraficoBarrasTurnos('chartBar_turnos');

    //RESUMEN SUBESTADOS SEMANA: este grafico se RECARGA por completo mas adelante, cuando ya se tengan los subestados y sus colores. Pero se carga para una primera visualizacion.
    this.chartDonutResumenSemana_subEstados = c3.generate({
      bindto: '#chartDonutResumenSemana_subEstados',
      data: {
        columns: [
          ['sinDatos', 0],
        ],

        names: {
          sinDatos: this.translate.instant("sinDatos"),
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_subEstados .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    //SUBESTADOS HORAS/DÍA: este grafico se RECARGA por completo mas adelante, cuando ya se tengan los subestados y sus colores. Pero se carga para una primera visualizacion.
    this.chartAreaHorasDia_subEstados = c3.generate({
      bindto: '#chartAreaHorasDia_subEstados',
      data: {
        x: 'x',
        columns: [
          ['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")],
          ['sinDatos', 0, 0, 0, 0, 0, 0, 0],
        ],
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [['sinDatos']],
        names: {
          sinDatos: this.translate.instant("sinDatos"),
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });

    //OEE
    this.dataChartDonutOEE = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEE = c3.generate({
      bindto: '#chartDonutOEE_InformeRendimiento',
      data: {
        columns: this.dataChartDonutOEE,
        type: 'donut',
        names: {
          oee: this.translate.instant("oee"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });
    //DISPONIBILIDAD
    this.dataChartDonutDisponibilidad = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEEDisponibilidad = c3.generate({
      bindto: '#chartDonutOEEDisponibilidad_InformeRendimiento',
      data: {
        columns: this.dataChartDonutDisponibilidad,
        type: 'donut',
        names: {
          disponibilidad: this.translate.instant("disponibilidad"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });
    //RENDIMIENTO
    this.dataChartDonutRendimiento = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEERendimiento = c3.generate({
      bindto: '#chartDonutOEERendimiento_InformeRendimiento',
      data: {
        columns: this.dataChartDonutRendimiento,
        type: 'donut',
        names: {
          rendimiento: this.translate.instant("rendimiento"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });
    //CALIDAD
    this.dataChartDonutCalidad = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEECalidad = c3.generate({
      bindto: '#chartDonutOEECalidad_InformeRendimiento',
      data: {
        columns: this.dataChartDonutCalidad,
        type: 'donut',
        names: {
          calidad: this.translate.instant("calidad"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });
    //HORAS OEE BARRAS
    this.dataGraficoHoras = [[this.translate.instant('total'), 0, 0, 0, 0],
    [this.translate.instant('rendimiento'), 0, 0, 0, 0],
    [this.translate.instant('microparadas'), 0, 0, 0, 0],
    [this.translate.instant('paradas'), 0, 0, 0, 0],
    [this.translate.instant('mantenimientos'), 0, 0, 0, 0],
    [this.translate.instant('alarmas'), 0, 0, 0, 0],
    [this.translate.instant('apagadas'), 0, 0, 0, 0],
    [this.translate.instant('perdidasCalidad'), 0, 0, 0, 0]];
    this.chartBarHoras = c3.generate({
      data: {
        columns: this.dataGraficoHoras,
        order: null,
        type: 'bar',
        groups: [[this.translate.instant('total'), this.translate.instant('rendimiento'), this.translate.instant('microparadas'), this.translate.instant('paradas'), this.translate.instant('mantenimientos'),
        this.translate.instant('alarmas'), this.translate.instant('apagadas'), this.translate.instant('perdidasCalidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#ee8625'], ['#CF8729'], ['#E7CB68'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ff6347']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translate.instant('tiempoTotal'), this.translate.instant('disponibilidad'), this.translate.instant('rendimiento'), this.translate.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 2000
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      onrendered: function () {
        d3.selectAll("#graficoHoras_Disp .c3-bar")
          .style("opacity", function (d) {
            if (d.index === 1) {
              return 1;
            } else {
              return 0.4
            }
          });
      },
      bindto: '#chartBarHoras_InformeRendimiento'
    });
  }
  dibujarGraficosDonuts() {

    this.graficoDonutPerdidas = this.myCharts.dibujarGraficoDonut_INFO('chartDonutParadas');
    this.graficoDonutMantenimientos = this.myCharts.dibujarGraficoDonut_INFO('chartDonutMantenimientos');
    this.graficoDonutAlarmas = this.myCharts.dibujarGraficoDonut_INFO('chartDonutAlarmas');

  }


  // RECARGAR PAGINA
  recargarPagina(maquina) {
    this.selectedMaquina = maquina.id;
    this.turnoNochePrimero = maquina.turnoNochePrimero;
    this.maquinasDesplegadas = !this.maquinasDesplegadas;
    this.desplegarMaquinasClick();
    setTimeout(() => { // PARA QUE SALGA EL ICONO DE CARGA
      this.cargar_historicoMaquinas();
    }, 0);
  }

  // MAQUINAS SUBESTADOS   
  cargar_historicoMaquinas() {  
    
    this.loadingGantt = true; // hay que tener en cuenta que si no se re-hace la consulta del historico, no se llega a ver el icono de carga
    this.loadingResumenSemana_Estados = true;
    this.loadingHorasDia_Estados = true;
    this.loadingResumenSemana_subEstados = true;
    this.loadingHorasDia_subEstados = true;
    this.loadingOeeHoras = true;
    this.loadingPerdidas = true;

    /* COMO EN TODOS LOS FILTRO LOS TURNOS SE PASAN EN UNA LISTA, AQUI CREAMOS ESA LISTA */
    var listaIdsTurnos = (this.comboTurnosSeleccion === undefined) ? [] : this.comboTurnosSeleccion.map(a => a.id);  
    this.turnoInt = -1;
    var lag: string = "";
    if (listaIdsTurnos.length == 0) {
      this.turnoInt = -1;
    } else {
      listaIdsTurnos.forEach(turno => {
        lag = lag + turno.toString();
      });
      this.turnoInt = +lag;
    }
    this.listaIdTurnos = listaIdsTurnos;
    

    var filtro = this.myFilter.crearFiltro_rapido_AnalisisRendimiento([this.fechaInicio, this.fechaFin], this.listaIdTurnos, this.selectedMaquina)

    var MinMax = this.myFilter.filtroFechas_MIN_MAX(filtro, true);
    var fec
    var fechaInicio = MinMax[0];
    var fechaFin = MinMax[1];

    if (!this.historicoMaquinasDatosService.util(fechaInicio, fechaFin)) {
      this.historicoMaquinasDatosService.cargar_historico_completo(fechaInicio, fechaFin).subscribe(
        j => {
          this.historicoMaquinasDatosService.set_historico_datos(j, fechaInicio, fechaFin);
          this.cargado_historicoMaquinas();
        });
    } else {
      if (this.listaIdTurnos == undefined)
        this.listaIdTurnos = []; // aveces falla la carga xq se llama antes a cargar gantt que a cargar turnos... con mas tiempo tendria que mirar donde se salta las limitaciones.

      this.cargado_historicoMaquinas();
    }
  }
  cargado_historicoMaquinas() {
    // Como en esta pagina no hay un filtro nuevo, se apaña creando la misma estructura desde atras.
    var filtro = this.myFilter.crearFiltro_rapido_AnalisisRendimiento([this.fechaInicio, this.fechaFin], this.listaIdTurnos, this.selectedMaquina)
    // Se combierte en filtro Kendo para usar sobre JSON
    var filtro_kendo: any = this.myFilter.filtroToKendo(filtro)

    var historico_completo_filtrado = this.historicoMaquinasDatosService.get_historicoMauqinas_filtrado(filtro_kendo);

    this.cargarGraficos_conDatos(historico_completo_filtrado);

  }
  // CARGA LOS GRAFICOS CON DATOS 
  cargarGraficos_conDatos(historico_completo_filtrado) {
    this.cargar_GANTT(historico_completo_filtrado);

    this.cargarGraficoTurnos(historico_completo_filtrado);
    this.cargar_GraficosResume_Estados(historico_completo_filtrado);
    this.cargar_GraficosResumen_SubEstados(historico_completo_filtrado);

    this.cargar_GraficosOEE(historico_completo_filtrado);

    this.cargarGraficosDonuts(historico_completo_filtrado);
  }
  // GANTT
  //ESTA FUNCION FILTRA LOS DATOS LEIDOS DESDE LA BASE DE DATOS
  cargar_GANTT(historico_completo_filtrado) {

    this.chartGanttTimeline = this.historicoMaquinasDatosService.load_historico_completo_GANTT('chartGanttTimeline', this.selectedMaquina, this.fechaInicio, this.fechaFin, this.listaIdTurnos, historico_completo_filtrado)
    this.loadingGantt = false;

  }
  // RESUMEN SEMANA: SubEstados Y SubEstados Agrupados
  //ESTA FUNCION FILTRA LOS DATOS LEIDOS DESDE LA BASE DE DATOS con un TimeOut porque necesita una carga inicial con los estados o subestados para cargar bien luego los tiempos.
  cargar_GraficosResume_Estados(historico_completo_filtrado) {
    this.cargarGraficosResumenSemana_Estados_SinDatos();
    setTimeout(() => {
      // ESTADOS
      //    Donut
      this.sinEstados = this.historicoMaquinasDatosService.load_resumen_estados_data_Donut_prefiltrado(this.chartDonutResumenSemana_Estados, [this.selectedMaquina], this.fechaInicio, this.fechaFin, this.listaIdTurnos, historico_completo_filtrado);
      this.loadingResumenSemana_Estados = false;
      //    Diario: este grafico se esta eliminando. No lo voy a crear en prefiltrado
      this.historicoMaquinasDatosService.load_resumen_estados_data_Lineal(this.chartAreaHorasDia_Estados, [this.selectedMaquina], this.fechaInicio, this.fechaFin, this.listaIdTurnos);
      this.loadingHorasDia_Estados = false;
    }, 1);
  }
  cargar_GraficosResumen_SubEstados(historico_completo_filtrado) {
    this.cargarGraficosResumenSemana_SubEstados_SinDatos();
    setTimeout(() => {
      // ESTADOS
      //    Donut
      this.sinSubestados = this.historicoMaquinasDatosService.load_resumen_subestados_data_Donut_prefiltrado(this.chartDonutResumenSemana_subEstados, [this.selectedMaquina], this.fechaInicio, this.fechaFin, this.listaIdTurnos, historico_completo_filtrado);
      this.loadingResumenSemana_subEstados = false;
      //    Diario: este grafico se esta eliminando. No lo voy a crear en prefiltrado
      this.historicoMaquinasDatosService.load_resumen_subestados_data_Lineal(this.chartAreaHorasDia_subEstados, [this.selectedMaquina], this.fechaInicio, this.fechaFin, this.listaIdTurnos);
      this.loadingHorasDia_subEstados = false;
    }, 1);
  }
  // CARGA LOS GRAFICOS VACIOS PARA DESPUES AÑADIR LOS DATOS 
  cargarGraficosResumenSemana_Estados_SinDatos() {
    var jEstados = this.historicoMaquinasDatosService.get_info_estados([this.selectedMaquina]);
    // Estados
    var columnasVacias = []; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos. 
    var nombresVacios = {}; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos.
    var colores = [];
    // El grafico de lineas mostrara siempre 7 dias en este caso, de lunes a domingo de la misma semana, en caso de necesitar mas dias, aqui se deverian introducir las fechas de los dias a mostrar.
    var columnasVacias_Diario = [['x']];
    var grupos = []

    jEstados.forEach(
      subestado => {
        // Variables para cargar el grafico vacio (DONUT)
        columnasVacias.push([subestado.nombre, 0]);
        nombresVacios[subestado.nombre] = subestado.nombre;
        colores.push(subestado.color);
        // Variables para cargar el grafico vacio (LINEAS POR DIAS), si el grafico mostrara mas de 7 dias (lunes-domingo), el numero de "",0" se deveria aumentar al mismo numero de dias que se muestre.
        columnasVacias_Diario.push([subestado.nombre]);
        grupos.push(subestado.nombre);
      });
    //RESUMEN SEMANA
    this.chartDonutResumenSemana_Estados = c3.generate({
      bindto: '#chartDonutResumenSemana_Estados',
      data: {
        columns: this.myFunctions.copy(columnasVacias),
        names: this.myFunctions.copy(nombresVacios),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_Estados .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_Estados .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_Estados .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: this.myFunctions.copy(colores)
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });
    //HORAS/DÍA
    this.chartAreaHorasDia_Estados = c3.generate({
      bindto: '#chartAreaHorasDia_Estados',
      data: {
        x: 'x',
        columns: this.myFunctions.copy(columnasVacias_Diario)
        ,
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [grupos],
        names: this.myFunctions.copy(nombresVacios)
      },
      color: {
        pattern: colores
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });
  }
  cargarGraficosResumenSemana_SubEstados_SinDatos() {
    var jSubEstados = this.historicoMaquinasDatosService.get_info_subestados([this.selectedMaquina]);
    // Estados
    var columnasVacias = []; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos. 
    var nombresVacios = {}; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos.
    var colores = [];
    // El grafico de lineas mostrara siempre 7 dias en este caso, de lunes a domingo de la misma semana, en caso de necesitar mas dias, aqui se deverian introducir las fechas de los dias a mostrar.
    var columnasVacias_Diario = [['x']];
    var grupos = []

    jSubEstados.forEach(
      subestado => {
        // Variables para cargar el grafico vacio (DONUT)
        columnasVacias.push([subestado.nombre, 0]);
        nombresVacios[subestado.nombre] = subestado.nombre;
        colores.push(subestado.color);
        // Variables para cargar el grafico vacio (LINEAS POR DIAS), si el grafico mostrara mas de 7 dias (lunes-domingo), el numero de "",0" se deveria aumentar al mismo numero de dias que se muestre.
        columnasVacias_Diario.push([subestado.nombre]);
        grupos.push(subestado.nombre);

        this.tieneSubEstados_personalizados = true;
      });
    //RESUMEN SEMANA
    this.chartDonutResumenSemana_subEstados = c3.generate({
      bindto: '#chartDonutResumenSemana_subEstados',
      data: {
        columns: this.myFunctions.copy(columnasVacias),
        names: this.myFunctions.copy(nombresVacios),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_subEstados .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: this.myFunctions.copy(colores)
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });
    //HORAS/DÍA
    this.chartAreaHorasDia_subEstados = c3.generate({
      bindto: '#chartAreaHorasDia_subEstados',
      data: {
        x: 'x',
        columns: this.myFunctions.copy(columnasVacias_Diario)
        ,
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [grupos],
        names: this.myFunctions.copy(nombresVacios)
      },
      color: {
        pattern: colores
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });
  }
  // CARGAR GRAFICO TURNOS
  private historico_completo_filtrado = {};
  clickHorasPiezas(_mostrarEnHoras) {
    if (this.mostrarEnHoras != _mostrarEnHoras ){
      this.mostrarEnHoras = _mostrarEnHoras;
      this.cargarGraficoTurnos(this.historico_completo_filtrado);
    }
  }
  clickTipoPiezas(_mostrarContadorTipo) {
    if (this.mostrarContadorTipo != _mostrarContadorTipo ){
      this.mostrarContadorTipo = _mostrarContadorTipo;
      this.cargarGraficoTurnos(this.historico_completo_filtrado);
    }
  }
  clickAccionPiezas(_mostrarContadorAccion) {
    if (this.mostrarContadorAccion != _mostrarContadorAccion ){
      this.mostrarContadorAccion = _mostrarContadorAccion;
      this.cargarGraficoTurnos(this.historico_completo_filtrado);
      this.cargar_GANTT(this.historico_completo_filtrado); // AFECTA EN LOS COLORES
    }
  }
  clickRepartirPiezas(_mostrarContadorRepartir) {
    if (this.mostrarContadorRepartir != _mostrarContadorRepartir ){
      this.mostrarContadorRepartir = _mostrarContadorRepartir;
      this.cargarGraficoTurnos(this.historico_completo_filtrado);
      this.cargar_GANTT(this.historico_completo_filtrado); // AFECTA EN LOS COLORES
    }
  }
  cargarGraficoTurnos(historico_completo_filtrado) {

    //POR SI SE CAMBIA DE HORAS A PIEZAS O AL REVES, SE DEJA GUARDADO EL ULTIMO CARGADO.
    this.historico_completo_filtrado = historico_completo_filtrado;

    //this.mostrarContadorAccion, this.mostrarContadorRepartir

    // this.historicoMaquinasDatosService.montar_historico_contador_test_esto_sera_directo_de_configuracion(this.mostrarContadorAccion, this.mostrarContadorRepartir)

    var filtro = this.myFilter.crearFiltro_rapido_AnalisisRendimiento([this.fechaInicio, this.fechaFin], this.listaIdTurnos, this.selectedMaquina)
    // Se combierte en filtro Kendo para usar sobre JSON
    var filtro_kendo: any = this.myFilter.filtroToKendo(filtro)

    var historico_contador_completo_filtrado = this.historicoMaquinasDatosService.get_historico_contador_filtrado(filtro_kendo);
    var data = this.historicoMaquinasDatosService.load_resumen_piezas_turnos_data_bar(this.chartBar_turnos, historico_contador_completo_filtrado, this.fechaInicio, this.fechaFin, this.listaIdTurnos, this.mostrarEnHoras, this.mostrarContadorTipo, this.turnoNochePrimero);

    var totales = data.totales;
    this.piezasBuenasMannana = totales.mañana.buenas;
    this.piezasMalasMannana = totales.mañana.malas;
    this.piezasBuenasTarde = totales.tarde.buenas;
    this.piezasMalasTarde = totales.tarde.malas;
    this.piezasBuenasNoche = totales.noche.buenas;
    this.piezasMalasNoche = totales.noche.malas;
    
    var totalPiezas = this.piezasBuenasMannana + this.piezasMalasMannana + this.piezasBuenasTarde + this.piezasMalasTarde + this.piezasBuenasNoche + this.piezasMalasNoche;
    this.porcentajeMannana = (this.piezasBuenasMannana + this.piezasMalasMannana) * 100 / totalPiezas;
    this.porcentajeTarde = (this.piezasBuenasTarde + this.piezasMalasTarde) * 100 / totalPiezas;
    this.porcentajeNoche = (this.piezasBuenasNoche + this.piezasMalasNoche) * 100 / totalPiezas;
    
    this.mostrarGraficoPiezasTurnos = totalPiezas > 0;

    this.dataPiezasTurnos = data.data.columns;

    // SE CONPRUEBA SI SE TIENE QUE MOSTRAR O NO EL CONTADOR.
    this.sinPiezasTurnos = true;    
    this.chartGanttTimeline.data.machines.forEach( 
      m => {
        if (m.id == 'contador') 
          this.sinPiezasTurnos = false;
    });

    this.loadingHorasDia_Estados = false;
  }
  roundPiezas(m, t, n){
    /* SI NO SE HACE ASI, EN VEZ SUMAR NUMEROS, SUMA LOS TEXTOS! */
    var total: number= parseFloat(m) + parseFloat(t) + parseFloat(n);
    return Math.round(total * 10) / 10 
  }

  // OEE:
  //  Estos graficos no necesitan de una carga inicial, cuando se carguen los tiempos tendran los mismos "estados"
  cargar_GraficosOEE(historico_completo_filtrado) {
    // DONUTS
    var OEE_donut_calculado: any = this.historicoMaquinasDatosService.get_OEE_data_Donut_prefiltrado(historico_completo_filtrado);

    d3.select('#chartDonutOEE_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.OEE_porcentaje) + "%");
    d3.select('#chartDonutOEEDisponibilidad_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.disponibilidad_porcentaje) + "%");
    d3.select('#chartDonutOEERendimiento_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.rendimiento_porcentaje) + "%");
    d3.select('#chartDonutOEECalidad_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.calidad_porcentaje) + "%");

    this.myCharts.load(this.chartDonutOEE, OEE_donut_calculado.OEE);
    this.myCharts.load(this.chartDonutOEEDisponibilidad, OEE_donut_calculado.disponibilidad);
    this.myCharts.load(this.chartDonutOEERendimiento, OEE_donut_calculado.rendimiento);
    this.myCharts.load(this.chartDonutOEECalidad, OEE_donut_calculado.calidad);

    // BARRAS
    this.myCharts.load(this.chartBarHoras, this.historicoMaquinasDatosService.get_OEE_data_Barras_prefiltrado(historico_completo_filtrado));

    // LOADING PANEL
    this.loadingOeeHoras = false;
  }
  // CARGA DE GRAFICOS: DONUT
  cargarGraficosDonuts(historico_completo_filtrado) {
    this.sinPerdidas = !this.historicoMaquinasDatosService.load_Donut_perdidas_prefiltrado(this.graficoDonutPerdidas, historico_completo_filtrado, 0);
    this.sinMantenimientos = !this.historicoMaquinasDatosService.load_Donut_mantenimientos_prefiltrado(this.graficoDonutMantenimientos, historico_completo_filtrado, 0);
    this.sinAlarmas = !this.historicoMaquinasDatosService.load_Donut_alarmas_prefiltrado(this.graficoDonutAlarmas, historico_completo_filtrado, 0);
    this.loadingPerdidas = false
  }














  //CALENDARIO DOBLE 

  mostrarCalendario = false; //Para abarir o cerrar el calendario
  public DaysInMonths;

  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.fechaInicio != undefined && this.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.fechaFin = this.DaysInMonths[month];
        this.cerrarCalendario();
      } else {
        if (this.fechaInicio == undefined && this.fechaFin == undefined) {
          this.fechaInicio = this.DaysInMonths[month];
        }
        else if (this.fechaInicio != undefined && this.fechaFin == undefined) {
          this.fechaFin = this.DaysInMonths[month];
          if (this.fechaInicio > this.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.fechaInicio.getTime());
            this.fechaInicio = new Date(this.fechaFin.getTime());
            this.fechaFin = aux;
          }
          this.cerrarCalendario();
        }
        else {
          this.fechaInicio = this.DaysInMonths[month];
          this.fechaFin = undefined;
        }
      }
    }
  }
  //Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //Función para que el calendario se muestre o no
  showCalendar() {
    if (!this.mostrarCalendario) {
      this.mostrarCalendario = true;
    }
    else {
      this.cerrarCalendario();
      if (this.fechaInicio != undefined && this.fechaFin == undefined) {
        this.fechaFin = this.fechaInicio;
      }
      else if (this.fechaInicio == undefined && this.fechaFin == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow();
        this.fechaFin = this.fechaInicio;
      }
    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    var today = this.myFunctions.getDateNow();
    this.DaysInMonths.push(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1));
  }
  //Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.fechaInicio == undefined && this.fechaFin == undefined) {
      return false;
    } else if (this.fechaInicio != undefined && this.fechaFin == undefined) {
      return date.getFullYear() == this.fechaInicio.getFullYear() && date.getMonth() == this.fechaInicio.getMonth() && date.getDate() == this.fechaInicio.getDate();
    } else if (this.fechaInicio != undefined && this.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.fechaInicio.getFullYear(), this.fechaInicio.getMonth(), this.fechaInicio.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate());
    }
  };
  //Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton() {
    var today = this.myFunctions.getDateNow();
    this.fechaInicio = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    this.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    this.cerrarCalendario();
  }
  ultimos7DiasButton() {
    this.fechaFin = this.myFunctions.getDateNow();
    this.fechaInicio = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate() - 6);
    this.cerrarCalendario();
  }
  ultimos30DiasButton() {
    this.fechaFin = this.myFunctions.getDateNow();
    this.fechaInicio = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 1, this.fechaFin.getDate());
    this.cerrarCalendario();
  }
  ultimos60DiasButton() {
    this.fechaFin = this.myFunctions.getDateNow();
    this.fechaInicio = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 2, this.fechaFin.getDate());
    this.cerrarCalendario();
  }
  ultimos90DiasButton() {
    this.fechaFin = this.myFunctions.getDateNow();
    this.fechaInicio = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth() - 3, this.fechaFin.getDate());
    this.cerrarCalendario();
  }
  cerrarCalendario() {

    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha

    if (this.fechaInicio != undefined && this.fechaFin != undefined)
      this.cargar_historicoMaquinas();
  }

}
