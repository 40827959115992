<!-- FILTRO-->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
    <div class="card-header">
        <h3>{{ 'filtro' | translate}}</h3>
        <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i class="fas fa-times"></i></button>
    </div>
    <div class="filtro-desplegable card-body flt">
        <!-- Filtro: CON GRUPOS -->
        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
        <ng-template #recursiveListTmpl let-filtross="filtros">
        <div *ngFor="let filtro of filtross" class="mt-2">
            <!-- GRUPO -->
            <div *ngFor="let row of filtro.group" class="flt-grupo">
            <!-- LINEA FILTRO -->
            <div *ngIf="row.group == undefined" class="clearfix flt-linea">
                <!-- AND / OR -->
                <div class="flt-andor">
                <!-- = 1� ROW NO TIENE NI OR, NI AND-->
                <label class="float-left" *ngIf="row == filtro.group[0]"> {{ 'where' | translate}} </label>
                <!-- = 2� ROW -->
                <kendo-dropdownlist (selectionChange)="refiltrarFiltro(filtro)" *ngIf="row == filtro.group[1]" [data]="andOr" [textField]="'nombre'" [valueField]="'id'"
                                    [(value)]="filtro.logic">
                </kendo-dropdownlist>
                <!-- > 2� ROW -->
                <label class="d-flex justify-content-center" *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 0"> {{ 'y' | translate}} </label>
                <label class="d-flex justify-content-center" *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 1"> {{ 'o' | translate}} </label>
                </div>
                <!-- TIPO -->
                <div class="flt-tipo">
                <kendo-dropdownlist (selectionChange)="filtroTipoChanged(filtro, row, $event)" [data]="columnasFiltro" [textField]="'nombre'"
                                    [valueField]="'id'" [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna"  [disabled]="row.tipoNoEditable"></kendo-dropdownlist>
                </div>
                <!-- CONTENEDOR FILTRO DE LINEA -->
                <div class="flt-filtro-linea">
                <!-- Filtro: FECHA -->
                <div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
                    <!-- OPCION -->
                    <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionDate" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <!-- DATO(s) -->
                    <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <!-- DATO 1 (tenemos un control especial para las fechas dobles) -->
                    <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1" [(value)]="row.fechaIni"></kendo-datepicker>
                    <!-- DATO 1 / 2 -->
                    <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                        <div class="calendarFiltro">
                        <!-- CONTROL -->
                        <div class="calendarFiltro-input" (click)="showCalendar(row)">
                            <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni | kendoDate}}</label>
                            <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                            <i class="k-icon k-i-calendar"></i>
                        </div>
                        <!-- CONTROL EXPANDIDO -->
                        <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;" [ngClass]="row.mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                            <div class="row">
                            <div class="col-mes">
                                <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic" (click)="valueClickCalendar(row, 0, $event)">
                                <ng-template kendoCalendarMonthCellTemplate let-date>
                                    <span class="calendarFiltro-calendario-contenido-dia" [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(row, date)"> {{ date.getDate() }} </span>
                                </ng-template>
                                <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                    <div> {{ title }} </div>
                                </ng-template>
                                </kendo-calendar>
                            </div>
                            <div class="col-mes-botones">
                                <div class="calendarFiltro-botones">
                                <div class="clearfix text-center">
                                    <!-- style="margin-left: -100px; margin-top: 5px; position: absolute;" -->
                                    <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                    <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                    <button (click)="cargarMeses()">{{'hoy' | translate}}</button>
                                </div>
                                <div>
                                    <div class="clearfix"><button (click)="ultimas24HButton(row)">{{'ultimas24H' | translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos7DiasButton(row)">{{'ultimos7Dias' | translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos30DiasButton(row)">{{'ultimos30Dias' | translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos60DiasButton(row)">{{'ultimos60Dias' | translate}}</button></div>
                                    <div class="clearfix"><button (click)="ultimos90DiasButton(row)">{{'ultimos90Dias' | translate}}</button></div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- Filtro: FECHA y HORA (el caso ENTRE no esta dise�ado aun) -->
                <div *ngIf="row.columna.tipo == 'dateTime'" class="flt-cont-tipo">
                    <!-- OPCION -->
                    <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionDateTime" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <!-- DATO(s) -->
                    <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <!-- DATO -->
                    <div class="flt-dato1">
                        <kendo-datetimepicker [(value)]="row.fechaIni"> </kendo-datetimepicker>
                    </div>
                    </div>
                </div>
                <!-- Filtro: NUMEROS ENTEROS -->
                <div *ngIf="row.columna.tipo == 'numeric'">
                    <!-- OPCION -->
                    <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <!-- DATO(s) -->
                    <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <div class="flt-dato1">
                        <!-- DATO 1 -->
                        <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999" [(value)]="row.numberMin"></kendo-numerictextbox>
                    </div>
                    <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                        <!-- DATO 2 -->
                        <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999" [(value)]="row.numberMax"></kendo-numerictextbox>
                    </div>
                    </div>
                </div>
                <!-- Filtro: DECIMALES -->
                <div *ngIf="row.columna.tipo == 'decimal'">
                    <div class="flt-opcion">
                    <!-- OPCION -->
                    <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <!-- DATO(s) -->
                    <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <div class="flt-dato1">
                        <!-- DATO 1 -->
                        <kendo-numerictextbox [format]="row.decimalformat" [min]="-9999999" [max]="9999999" [(value)]="row.decimalMin"></kendo-numerictextbox>
                    </div>
                    <div class="flt-dato2">
                        <!-- DATO 2 -->
                        <kendo-numerictextbox *ngIf="row.operator.dobleValor" [format]="row.decimalformat" [min]="-9999999" [max]="9999999" [(value)]="row.decimalMax"></kendo-numerictextbox>
                    </div>
                    </div>
                </div>
                <!-- Filtro: TEXTO -->
                <div *ngIf="row.columna.tipo == 'string'">
                    <div class="flt-opcion">
                    <!-- OPCION -->
                    <kendo-dropdownlist [data]="opcionString" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <div class="flt-datos">
                    <div class="flt-dato1 float-left mr-2">
                        <!-- DATO -->
                        <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                    </div>
                    </div>
                </div>
                <!-- Filtro: COMBO ESTATICO -->
                <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                    <div class="flt-opcion">
                    <!-- OPCION -->
                    <kendo-dropdownlist [data]="opcionComboEstricto" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <!-- DATO(s) -->
                    <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <div class="flt-dato1">
                        <!-- DATO 1 -->
                        <kendo-dropdownlist *ngIf="!row.operator.dobleValor" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.comboSelected" style="width: 220px;">
                        </kendo-dropdownlist>
                    </div>
                    <div class="flt-dato2">
                        <!-- DATO 2 -->
                        <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                                            [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                            <span class="k-icon k-i-arrow-s"></span>
                            <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                            <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                        </ng-template>
                        <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>

                    </div>
                </div>
                <!-- Filtro: COMBO FLEXIBLE -->
                <div *ngIf="row.columna.tipo == 'comboFlexible'">
                    <!-- OPCION -->
                    <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                    </div>
                    <!-- DATO(s) -->
                    <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <!-- DATO 1 -->
                    <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4" class="mr-2 float-left" [data]="row.combo" [textField]="'nombre'" [valueField]="'id'"
                                        [(value)]="row.comboSelected"
                                        style="width: 220px;"></kendo-dropdownlist>
                    <!-- DATO 1 -->
                    <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'"
                                        [autoClose]="false" style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate }}</ng-container>
                        </ng-template>
                        <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                    <!-- DATO 1 -->
                    <div *ngIf="!row.operator.dobleValor && row.operator.id > 4" class="flt-dato1 float-left mr-2">
                        <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                    </div>
                    </div>
                </div>
                <!-- Filtro: CHECK -->
                <div *ngIf="row.columna.tipo == 'check'" class="flt-dato1">
                    <!-- SERIA MEJOR CON 2 BOTONES SI / NO ? TEXTO PARA VERDADERO / FALSO? -->
                    <kendo-switch [(ngModel)]="row.check" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </div>
                </div>
                <!-- ELIMINAR -->
                <button *ngIf=" (!((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico) && !row.tipoNoVisible "
                        Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i class="fas fa-times"></i> </button>
            </div>
            <!-- SUB GRUPO -->
            <div *ngIf="row.group != undefined">
                <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
            </div>
            </div>
            <!-- BOTON: A�ADIR LINEA (SOLO EN LA ULTIMA LINEA, o primer subgrupo) -->
            <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro && !annadirAutomatico">
            <button Class="btnEditatu btn btn-success btn-sm mr-1" (click)="annadirLineaFiltro(filtro)"> <i class="fas fa-plus"></i> {{ 'agregarCondicion' | translate }} </button>
            </div>
            <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro">
            <hr>
            </div>
        </div>
        </ng-template>
        <div class="clearfix">
        <button class="flt-btnanadirgrupo" (click)="annadirGrupoFiltro()"> <i class="fas fa-plus"></i> {{ 'agregarGrupo' | translate }} </button>
        </div>
        <hr>
        <div class="clearfix">
        <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()"> {{ 'filtrar' | translate }}</button>
        <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton' | translate }}</button>
        </div>
    </div>
</div>
<!-- CARD CON OPCIONES SELECCIONADAS FILTRO -->
<div class="card">
    <div class="card-body">
        <div class="cont-tags-filtro" id="tagsFiltro">
        <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [datosFiltro] }"></ng-container>
        <ng-template #recursiveListTmpl2 let-filtross="filtros">
            <div *ngFor="let filtro of filtross" class="uno">
            <div class="flt-separador" *ngIf="filtro.group != undefined && filtro != datosFiltro && filtro!=datosFiltro.group[0] && filtroToCount(filtro) > 0"></div>
            <!-- GRUPO -->
            <div *ngFor="let row of filtro.group" class="dos">
                <!-- LINEA FILTRO -->
                <div *ngIf="row.group == undefined &&
                ((row.columna.tipo == 'date') || 
                (row.columna.tipo == 'dateTime') || 
                (row.columna.tipo == 'comboEstrincto' && row.comboSelecteds.length > 0 ) || 
                (row.columna.tipo == 'comboFlexible'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
                (row.columna.tipo == 'check') || 
                (row.columna.tipo == 'numeric') || 
                (row.columna.tipo == 'decimal') || 
                (row.columna.tipo == 'string' && row.text != '' ))" class="tags-filtro">
                <label *ngIf="false"> {{ row.columna.nombre }} </label>
                <label *ngIf="false"> {{ row.operator.nombre }} </label>
                <!-- -->
                <!-- FILTRO -->
                <div class="tags-filtro-label">
                    <!-- Filtro: FECHA -->
                    <div *ngIf="row.columna.tipo == 'date'">
                    <div *ngIf="row.operator.id > 0">
                        <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                        <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin == undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                        <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin != undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                    </div>
                    </div>
                    <!-- Filtro: FECHA y HORA (el caso ENTRE no esta dise�ado aun) -->
                    <div *ngIf="row.columna.tipo == 'dateTime'">
                    <div *ngIf="row.operator.id > 0">
                        <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor"> {{row.fechaIni | kendoDate}} </label>
                    </div>
                    </div>
                    <!-- Filtro: NUMEROS ENTEROS -->
                    <div *ngIf="row.columna.tipo == 'numeric'">
                    <div *ngIf="row.operator.id > 0">
                        <label class="tags-filtro-label"> {{row.numberMin}} </label>
                        <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.numberMax}} </label>
                    </div>
                    </div>
                    <!-- Filtro: DECIMALES -->
                    <div *ngIf="row.columna.tipo == 'decimal'">
                    <div *ngIf="row.operator.id > 0">
                        <label class="tags-filtro-label"> {{row.decimalMin}} </label>
                        <label class="tags-filtro-label" *ngIf="row.operator.dobleValor"> {{row.decimalMax}} </label>
                    </div>
                    </div>
                    <!-- Filtro: TEXTO -->
                    <div *ngIf="row.columna.tipo == 'string'">
                    <label class="tags-filtro-label"> {{row.text}} </label>
                    </div>
                    <!-- Filtro: COMBO ESTATICO -->
                    <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                    <div *ngIf="row.operator.id > 0">
                        <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                        <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                    </div>
                    </div>
                    <!-- Filtro: COMBO FLEXIBLE -->
                    <div *ngIf="row.columna.tipo == 'comboFlexible'">
                    <div *ngIf="row.operator.id > 0">
                        <div *ngIf="row.operator.id <= 4">
                        <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{ row.comboSelecteds[0].nombre}}</label>
                        <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{ row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                        </div>
                        <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}} </label>
                    </div>
                    </div>
                    <!-- Filtro: CHECK -->
                    <div *ngIf="row.columna.tipo == 'check'">
                    <label class="tags-filtro-label" *ngIf="row.check"> {{ row.columna.nombre }} : {{ 'si' | translate }} </label>
                    <label class="tags-filtro-label" *ngIf="!row.check"> {{ row.columna.nombre }} : {{ 'no' | translate }} </label>
                    </div>
                </div>
                <!-- ELIMINAR -->
                <button (click)="borrarLineaFiltro(row, filtro, datPlanos)" class="fas fa-times" style="cursor:pointer" *ngIf="!row.tipoNoVisible"></button>
                </div>
                <!-- SUB GRUPO -->
                <div *ngIf="row.group != undefined">
                <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros:  [row] }"></ng-container>
                </div>
            </div>
            </div>
        </ng-template>
        <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }} ( {{ filtroToCount() }}
            )</button>
        <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button>
        <button *ngIf="actualizarVisible" Class="btn btn-success float-right mr-1" (click)="onFilter()"><i class="fas fa-sync-alt mr-1"></i>{{ 'actualizar' | translate }}</button>
        </div>

    </div>

    <div *ngIf="loadingFiltro" class="k-i-loading"></div>
</div>

<!-- GRID OF -->
<div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'OF' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          <div kendoTooltip
               showOn="none"
               [tooltipTemplate]="template"
               filter=".k-grid td"
               (mouseover)="showGridTooltip($event)">
            <kendo-grid [kendoGridBinding]="dataGrid"
                        [resizable]="true"
                        [pageable]="true"
                        [pageSize]="10"
                        [skip]="skip"
                        [navigable]="true"
                        [sortable]="true"
                        [reorderable]="true"
                        [columnMenu]="{ filter: true }"
                        (cellClick)="cellClick($event)">
              <kendo-grid-column field="numeroOf" title="{{ 'OF' | translate }}" width="15%"></kendo-grid-column>
              <kendo-grid-column field="nombreCliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
              <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" width="20%"></kendo-grid-column>
              <kendo-grid-column field="tiempoReal" title="{{ 'tReal' | translate }}" width="5%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <!-- HEADER -->
                <ng-template kendoGridHeaderTemplate let-column>
                  <label>
                    <span class="tooltip-consumibles-contenido">
                      <i class="fas fa-info-circle mr-2"></i><span class="tooltipTextHeader" [attr.contenido]="infoTRealGridHeader"></span>
                    </span>
                    {{ 'tReal' | translate }}
                  </label>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoReal)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tiempoTotal" width="5%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <!-- HEADER -->
                <ng-template kendoGridHeaderTemplate let-column>
                  <label>
                    <span class="tooltip-consumibles-contenido">
                      <i class="fas fa-info-circle mr-2"></i><span class="tooltipTextHeader" [attr.contenido]="infoTTotalGridHeader"></span>
                    </span>
                    {{ 'tTotal' | translate }}
                  </label>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoTotal)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tEstimado" title="{{ 'tEstimado' | translate }}" width="5%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoEstimado)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="desvioPorcentaje" title="{{ 'desvioPorcentaje' | translate }}" width="5%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.desvioPorcentaje?.toFixed(2)}}%</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="piezaTerminada" title="{{ 'terminado' | translate }}" width="4%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span *ngIf="dataItem.piezaTerminada" class="k-icon k-i-check"></span>
                  <span *ngIf="!dataItem.piezaTerminada" class="k-icon k-i-close"></span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="cantidad" title="{{ 'nTerminados' | translate }}" width="4%" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span>{{dataItem.nTerminado}}/{{dataItem.cantidad}}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="historicoPiezasButton" title="" width="3%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}" [columnMenu]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button (click)="abrirHistoricoPiezas(dataItem)" class="k-button">
                    <span class="k-icon k-i-wrench"></span>
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
            <div *ngIf="loading" id="loadingDiario" class="k-i-loading"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

<!-- GRAFICOS OEE -->
<div class="row">
    <!-- DONUT RENDIMIENTO -->
    <div class="col-lg-2">
        <div class="clearfix">
          <div class="card">
            <div class="card-header">
              <h3>
                <label>{{ 'rendimiento' | translate}}</label>
              </h3>
              <div class="plegarpanel"></div>
              <div class="row" id="graficoDonutRendimiento_InformeProyectos_v2" style="height: 150px;" [ngStyle]="{'display': tieneDatos ? 'block' : 'none'}">
              </div>
              <div class="row" style="height: 150px; position: relative;" [ngStyle]="{'display': !tieneDatos ? 'block' : 'none'}">
                <a style="position: absolute; top: 39%; width: 100%; text-align: center;">{{ 'norecords' | translate}}</a>
              </div>
            </div>
            <div *ngIf="loading" id="loadingDiario" class="k-i-loading"></div>
          </div>
        </div>
    </div>

    <!-- GRAFICO RENDIMIENTO/CALIDAD -->
    <div class="col-lg-8">
        <div class="clearfix">
          <div class="card">
            <div class="card-header">
              <h3>
                <label>{{ 'rendimiento' | translate}} / {{ 'calidad' | translate}}</label>
              </h3>
              <div class="plegarpanel"></div>
              <div class="row" id="graficoRendimientoCalidad_InformeProyectos_v2" style="height: 150px;" [ngStyle]="{'display': tieneDatos ? 'block' : 'none'}">
              </div>
              <div class="row" style="height: 150px; position: relative;" [ngStyle]="{'display': !tieneDatos ? 'block' : 'none'}">
                <a style="position: absolute; top: 39%; width: 100%; text-align: center;">{{ 'norecords' | translate}}</a>
              </div>
            </div>
            <div *ngIf="loading" id="loadingDiario" class="k-i-loading"></div>
          </div>
        </div>
    </div>

    <!-- DONUT CALIDAD -->
    <div class="col-lg-2">
        <div class="clearfix">
            <div class="card">
            <div class="card-header">
                <h3>
                <label>{{ 'calidad' | translate}}</label>
                </h3>
                <div class="plegarpanel"></div>
                <div class="row" id="graficoDonutCalidad_InformeProyectos_v2" style="height: 150px;" [ngStyle]="{'display': tieneDatos ? 'block' : 'none'}">
                </div>
                <div class="row" style="height: 150px; position: relative;" [ngStyle]="{'display': !tieneDatos ? 'block' : 'none'}">
                <a style="position: absolute; top: 39%; width: 100%; text-align: center;">{{ 'norecords' | translate}}</a>
                </div>
            </div>
            <div *ngIf="loading" id="loadingDiario" class="k-i-loading"></div>
            </div>
        </div>
    </div>
</div>

<!-- GRAFICO CLIENTES -->
<div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'clientes' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
            <div class="row" id="graficoClientes_InformeProyectos_v2" style="height: 230px;" [ngStyle]="{'display': tieneDatos ? 'block' : 'none'}">
            </div>
            <div class="row" style="height: 160px; position: relative;" [ngStyle]="{'display': !tieneDatos ? 'block' : 'none'}">
              <a style="position: absolute; top: 39%; width: 100%; text-align: center;">{{ 'norecords' | translate}}</a>
            </div>
          </div>
          <div *ngIf="loading" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </div>
    </div>
  </div>