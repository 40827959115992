import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MyFunctions } from '@app/_helpers';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

const baseUrl = `${environment.apiUrl}/HistoricoMaquinasDatos`;

@Injectable()

export class HistoricoMaquinasDatosTemporal2Service {

    // Controlar setTimeout en home y hmi
    public timeoutzenHome;
    public timeoutzenHMI;

    // fecha de el ultimo update
    private segundosUtiles: number = 20 /* * 60*/;
    private segundosUtilesTiempos: number = 2 * 60;
    private fechaUpdate: Date;
    private fechaUpdateTiempos: Date;

    // historicos
    private historico_datos: JSON;
    private historico_maquinas_temporal: JSON;
    private historico_operaciones_Map = new Map();
    private historico_operaciones_Anterior_Map = new Map();
    private historico_maquinas_operaciones_temporal: JSON;
    private historico_maquinas_operarios_temporal: JSON;
    private historico_maquinas_datos_tiempo_real_temporal: JSON;
    private historico_maquinas_operaciones_agrupadas_resumen: JSON;

    private historico_tiempos_Map = new Map();
    private historico_cantidades_Map = new Map();

    // informacion
    private info_alarmas: JSON;
    private info_perdidas: JSON;
    private info_mantenimientos: JSON;
    private info_maquinas: JSON;
    private info_operaciones: JSON;
    private info_ofs: JSON;
    private info_subestados: JSON;
    private info_operarios: JSON;
    private info_cantidades: JSON;
    private info_datosTiempoRealHornos: JSON;
    private horaServidor;
    private home_tipoCalculoLote;
    private idHome_informacionPieza_DAT;
    private parametros_mecanizado: JSON;
    private parametros: JSON;
    private info_ejes: JSON;
    private info_parametros: JSON;
    private info_gruposOperaciones: JSON;
    private info_tiempos: JSON;
    private info_contadores: JSON;
    private info_calidad: JSON;

    private timeoutzen;

    constructor(private http: HttpClient, private myFunctions: MyFunctions, private translateService: TranslateService,public router: Router,) {}

    ngOnInit() {
        // this.isPrimeraVez = true;
        // this.actualizarTiempos();
    }

    //#region LLAMADA A LA API
    public cargar_historico_temporal() {
        return this.http.post<JSON>(`${baseUrl}/cargar_historico_temporal`, {}, { withCredentials: true });
    }
    public cargar_tiempos() {
        return this.http.post<JSON>(`${baseUrl}/cargar_tiempos`, {}, { withCredentials: true });
    }
    //#endregion

    //#region GET DE VARIABLES GENERALES PARA SU USO EN DIFERENTES INFORMES
    public util() {
        this.check_fecha_util();

        //#region Comprobar si los tiempos son utiles
        if (!this.utilTiempos()) {
            this.cargar_tiempos().subscribe(j => {
                this.set_tiempos(j);
            });
        }
        //#endregion

        return this.historico_maquinas_temporal != undefined;
    }
    private check_fecha_util() {
        if (this.fechaUpdate != undefined && (new Date().getTime() - this.fechaUpdate.getTime()) / 1000 > this.segundosUtiles) {
            this.historico_maquinas_temporal = undefined;
        }
    }
    public get_historico_temporal() {
        var an: any = this.historico_datos;
        return an;
    }
    public utilTiempos() {
        this.check_fecha_utilTiempos();
        return this.info_tiempos != undefined;
    }
    private check_fecha_utilTiempos() {
        if (this.fechaUpdateTiempos != undefined && (new Date().getTime() - this.fechaUpdateTiempos.getTime()) / 1000 > this.segundosUtilesTiempos) {
            this.info_tiempos = undefined;
        }
    }
    //#endregion

    //#region SET DE VARIABLES PARA ACTUALIZAR LAS VARIABLE GENERALES CON LOS DATOS DE LA API
    public set_historico_temporal(json) {
        // 13: HORA SERVIDOR
        this.horaServidor = json.horaServidor[0].horaServidor;
        this.home_tipoCalculoLote = json.horaServidor[0].home_tipoCalculoLote;
        this.idHome_informacionPieza_DAT = json.horaServidor[0].idHome_informacionPieza_DAT;
        // 00: HISTORICO MAQUINAS RESUMENES
        var historico_maquinas_temporal: any = json.historico_maquinas_temporal;
        historico_maquinas_temporal.forEach(
            element => {
                element.fechaini = new Date(element.fechaini);
                element.tiempoEstado = Math.floor((new Date(this.horaServidor).getTime() - element.fechaini.getTime()) / 1000);
                element.horaServidor = new Date(this.horaServidor);
                element.now = new Date();
                element.home_tipoCalculoLote = this.home_tipoCalculoLote;
            });
        this.historico_maquinas_temporal = historico_maquinas_temporal;
        // 01: HISTORICO MAQUINAS OPERACIONES TEMPORAL
        var historico_maquinas_operaciones_temporal: any = json.historico_maquinas_operaciones_temporal;
        historico_maquinas_operaciones_temporal.forEach(element => {
            element.fechaini = new Date(element.fechaini);
        });
        this.historico_maquinas_operaciones_temporal = historico_maquinas_operaciones_temporal;

        // 02: HISTORICO MAQUINAS OPERARIOS TEMPORAL
        var historico_maquinas_operarios_temporal: any = json.historico_maquinas_operarios_temporal;
        historico_maquinas_operarios_temporal.forEach(element => {
            element.fechaini = new Date(element.fechaini);
        });
        this.historico_maquinas_operarios_temporal = historico_maquinas_operarios_temporal;
        // // 03: ALARMAS
        this.info_alarmas = json.info_alarmas;
        // // 04: PERDIDAS
        // this.info_perdidas = json.info_perdidas;
        // // 05: MANTENIMIENTOS
        this.info_mantenimientos = json.info_mantenimientos;
        // 06: MAQUINAS
        this.info_maquinas = json.info_maquinas;
        // 07: OPERACIONES
        this.info_operaciones = json.info_operaciones;
        // 08: OFS, PIEZAS, PARTES, RUTAS
        this.info_ofs = json.info_ofs;
        // 09: CANTIDADES/TIEMPOS MAQUINAS OPERACIONES AGRUPADAS
        this.historico_maquinas_operaciones_agrupadas_resumen = json.historico_maquinas_operaciones_agrupadas_resumen;
        // 10: SUBESTADOS DE MAQUINA
        var info_subestados: any = json.info_subestados;
        info_subestados.forEach(
            element => {
                if (element.nombre != '')
                    element.nombre = this.translateService.instant(element.nombre);
                else 
                    element.nombre = this.translateService.instant("sinEstado");
            });
        this.info_subestados = info_subestados;
        // 11: OPERARIOS
        this.info_operarios = json.info_operarios;
        // 12: CANTIDADES
        this.info_cantidades = json.info_cantidades;
        // 14: DATOS TIEMPO REAL HORNOS
        this.info_datosTiempoRealHornos = json.datosTiempoRealHorno;
        // 15: HISTORICO MAQUINAS DATOS TIEMPO REAL TEMPORAL
        this.historico_maquinas_datos_tiempo_real_temporal = json.historico_maquinas_datos_tiempo_real_temporal;
        // 16: PARAMETROS MECANIZADO
        this.parametros_mecanizado = json.parametros_mecanizado;
        // 17: PARAMETROS
        this.parametros = json.parametros;
        // 18: EJES
        this.info_ejes = json.info_ejes;
        // 19: INFO PARAMETROS
        this.info_parametros = json.info_parametros;
        // 20: INFO GRUPOS OPERACIONES
        this.info_gruposOperaciones = json.info_gruposOperaciones;
        // 21: INFO TIEMPOS
        // this.info_tiempos = json.info_tiempos;
        // 22: INFO CALIDAD
        this.info_calidad = json.info_calidad;

        this.info_contadores = json.info_contador;

        // Actualizamos las fechas de actualizado y plazo de los datos recibidos
        this.fechaUpdate = new Date();

        // Despues de tratar todos los datos, dejamos preparado un historico completo
        var an: any = this.montar_historico_datos();
        this.historico_datos = an;
    }
    public set_tiempos(json) {
        // 21: INFO TIEMPOS
        this.info_tiempos = json;

        // Actualizamos las fechas de actualizado y plazo de los datos recibidos
        this.fechaUpdateTiempos = new Date();
        this.montar_tiempos();
    }
    private montar_historico_datos() {
        // TABLA COMPLETA
        var result = [];
        console.log(this.historico_maquinas_temporal);
        (this.historico_maquinas_temporal as any).forEach(element => {
            // OPERACIONES TEMPORAL
            var operacionesTemporal_encontrada = this.myFunctions.copy(this.historico_maquinas_operaciones_temporal as any).filter(f => f.idMaquina == element.idMaquina);
            element.datosOperaciones = [];
            
            this.historico_operaciones_Map.set(element.idMaquina, []); // se establece un map para controlar los idHistoricoOperaciones
            if (element.idMaquina == 1)
                console.log("hemen");
            if (operacionesTemporal_encontrada == undefined) {
                element.datosOperaciones.push({
                    idHistorico_operaciones: -1,
                    idOperacion: -1,
                    idHistorico_operaciones_grupos: -1,
                    fechaini: new Date(),
                    porcentajeCapacidadMaquina: 0,
                    operacion: 0,
                    colada: '',
                    lote: '',
                    nserie: '',
                    // contadorEstimado: 0
                })
            } else {
                operacionesTemporal_encontrada.forEach(row => {
                    if (row.operacion) {
                        element.datosOperaciones.push({
                            idHistorico_operaciones: row.idHistorico_operaciones,
                            idOperacion: row.idOperacion,
                            idHistorico_operaciones_grupos: row.idHistorico_operaciones_grupos,
                            fechaini: row.fechaini,
                            porcentajeCapacidadMaquina: row.porcentajeCapacidadMaquina,
                            operacion: row.operacion,
                            colada: row.colada,
                            lote: row.lote,
                            nserie: row.nSerie,
                            // contadorEstimado: row.contadorEstimado
                        })
                        let idHistoricoOperaciones = this.historico_operaciones_Map.get(element.idMaquina);
                        idHistoricoOperaciones.push(row.idHistorico_operaciones)
                        this.historico_operaciones_Map.set(element.idMaquina, idHistoricoOperaciones);
                    }
                });
            }

            // OPERARIOS TEMPORAL
            var operariosTemporal_encontrada = this.myFunctions.copy(this.historico_maquinas_operarios_temporal as any).filter(f => f.idMaquina == element.idMaquina);
            element.datosOperarios = [];
            if (operariosTemporal_encontrada == undefined || operariosTemporal_encontrada.length == 0) {
                element.datosOperarios.push({
                    idOperario: -1,
                    fechaini: new Date()
                })
            } else {
                operariosTemporal_encontrada.forEach(row => {
                    element.datosOperarios.push({
                        idOperario: row.idOperario,
                        fechaini: row.fechaini
                    })
                });
            }
            // DATOS TIEMPO REAL TEMPORAL
            var datosTiempoRealTemporal_encontrada = this.myFunctions.copy(this.historico_maquinas_datos_tiempo_real_temporal as any).filter(f => f.idMaquina == element.idMaquina)[0];
            if (operariosTemporal_encontrada == undefined) {
                element.nombrePrograma = '';
                element.faseOperacion = -1;
                element.contadorMaquina = 0;
                element.contadorEstimado = 0;
            } else {
                element.nombrePrograma = datosTiempoRealTemporal_encontrada.nombrePrograma;
                element.faseOperacion = datosTiempoRealTemporal_encontrada.faseOperacion;
                element.contadorMaquina = datosTiempoRealTemporal_encontrada.contadorMaquina;
                element.contadorEstimado = datosTiempoRealTemporal_encontrada.contadorEstimado;
            }
            // PERDIDA
            // var perdida_encontrada = this.myFunctions.copy(this.info_perdidas as any).filter(f => f.idHistorico_Procesos == element.idHistorico_Procesos)[0];
            // if (perdida_encontrada == undefined) {
            //     element.idPerdida = -1;
            //     element.perdida = '';
            //     element.idSubPerdida = -1;
            //     element.subPerdida = '';
            //     element.idGrupoPerdida = -1;
            //     element.grupoPerdida = '';
            //     element.idTipoOEE_perdida = -1;
            // } else {
            //     element.idPerdida = perdida_encontrada.idPerdida;
            //     element.perdida = perdida_encontrada.perdida;
            //     element.idSubPerdida = perdida_encontrada.idSubPerdida;
            //     element.subPerdida = perdida_encontrada.subPerdida;
            //     element.idGrupoPerdida = perdida_encontrada.idGrupoPerdida;
            //     element.grupoPerdida = perdida_encontrada.grupoPerdida;
            //     element.idTipoOEE_perdida = perdida_encontrada.idTipoOEE_perdida;
            // };

            // ALARMA
            var alarma_encontrada = (this.info_alarmas as any).filter(f => f.idHistorico_Procesos == element.idHistorico_Procesos)[0];
            if (alarma_encontrada == undefined) {
                element.alarma = '';
            } else {
                element.alarma = alarma_encontrada.descripcion;
            }

            // // MANTENIMIENTO
            var mantenimiento_encontrado = (this.info_mantenimientos as any).filter(f => f.idHistorico_Procesos == element.idHistorico_Procesos)[0];
            if (mantenimiento_encontrado == undefined) {
                element.maquinaMantenimiento = '';
                element.observacionMantenimiento = '';
            } else {
                element.maquinaMantenimiento = mantenimiento_encontrado.mantenimiento;
                element.observacionMantenimiento = mantenimiento_encontrado.observacionMantenimiento;
            }

            // MAQUINA
            var maquina_encontrada = (this.info_maquinas as any).filter(f => f.idMaquina == element.idMaquina)[0];
            if (maquina_encontrada == undefined) {
                element.maquina = '';
                element.idMaquinaGrupo = -1;
                element.maquinaGrupo = '';
                element.idSeccion = -1;
                element.seccion = '';
                element.idAreaProductiva = -1;
                element.areaProductiva = '';
                element.idHomeSeleccionIzq = -1;
                element.idHomeSeleccionDer = -1;
                element.idEje1 = -1;
                element.idEje2 = -1;
                element.idEje3 = -1;
                element.idEje4 = -1;
                element.idVariable_Tipo1 = -1;
                element.idVariable_Tipo2 = -1;
                element.idVariable_Tipo3 = -1;
                element.idVariable_Tipo4 = -1;
                // element.coste = 0;
                element.puestoOffline = false;
                element.tipo_maquina = -1;
                element.cantidadesTerminadasEnPreparacion = -1;
                element.capacidadPesoMaximo = 0;
                element.capacidadUnidadesMaximo = 0;
                element.id_contador_turno_tipo_DAT = 1;
                element.tonelaje = 0;
                element.unidadInyeccion = 0;
            } else {
                element.maquina = maquina_encontrada.maquina;
                element.idMaquinaGrupo = maquina_encontrada.idMaquinaGrupo;
                element.maquinaGrupo = maquina_encontrada.maquinaGrupo;
                element.idSeccion = maquina_encontrada.idSeccion;
                element.seccion = maquina_encontrada.seccion;
                element.idAreaProductiva = maquina_encontrada.idAreaProductiva;
                element.areaProductiva = maquina_encontrada.areaProductiva;
                element.idHomeSeleccionIzq = maquina_encontrada.idHomeSeleccionIzq;
                element.idHomeSeleccionDer = maquina_encontrada.idHomeSeleccionDer;
                element.idEje1 = maquina_encontrada.idEje1;
                element.idEje2 = maquina_encontrada.idEje2;
                element.idEje3 = maquina_encontrada.idEje3;
                element.idEje4 = maquina_encontrada.idEje4;
                element.idVariable_Tipo1 = maquina_encontrada.idVariable_Tipo1;
                element.idVariable_Tipo2 = maquina_encontrada.idVariable_Tipo2;
                element.idVariable_Tipo3 = maquina_encontrada.idVariable_Tipo3;
                element.idVariable_Tipo4 = maquina_encontrada.idVariable_Tipo4;
                // element.coste = maquina_encontrada.coste;
                element.puestoOffline = maquina_encontrada.puestoOffline;
                element.tipo_maquina = maquina_encontrada.tipo_maquina;
                element.cantidadesTerminadasEnPreparacion = maquina_encontrada.cantidadesTerminadasEnPreparacion;
                element.capacidadPesoMaximo = maquina_encontrada.capacidadPesoMaximo;
                element.capacidadUnidadesMaximo = maquina_encontrada.capacidadUnidadesMaximo;
                element.id_contador_turno_tipo_DAT = maquina_encontrada.id_contador_turno_tipo_DAT;
                element.tonelaje = maquina_encontrada.tonelaje;
                element.unidadInyeccion = maquina_encontrada.unidadInyeccion;
            }

            //#region DATOS RELACIONADOS CON LA OPERACION
            element.datosOperaciones.forEach(info => {
                // OPERACION
                var operacion_encontrada = (this.info_operaciones as any).filter(f => f.idOperacion == info.idOperacion)[0];
                if (operacion_encontrada == undefined) {
                    info.nombreOperacion = '';
                    info.coste = 0;
                    info.tiempoEstimadoEjecucion = -1;
                    info.tiempoEstimadoPreparacion = 0;
                    info.tiempoPredictivoEjecucion = 0;
                    info.tiempoPredictivoPreparacion = 0;
                    info.operacionTerminada = -1;
                    info.idRuta = -1;
                } else {
                    info.nombreOperacion = operacion_encontrada.nombreOperacion;
                    info.coste = operacion_encontrada.coste;
                    info.tiempoEstimadoEjecucion = operacion_encontrada.tiempoEstimadoEjecucion;
                    info.tiempoEstimadoPreparacion = operacion_encontrada.tiempoEstimadoPreparacion;
                    info.tiempoPredictivoEjecucion = operacion_encontrada.tiempoPredictivoEjecucion;
                    info.tiempoPredictivoPreparacion = operacion_encontrada.tiempoPredictivoPreparacion;
                    info.operacionTerminada = operacion_encontrada.operacionTerminada;
                    info.idRuta = operacion_encontrada.idRuta;
                }

                // OF
                var of_encontrado = (this.info_ofs as any).filter(f => f.idRuta == info.idRuta)[0];
                if (of_encontrado == undefined) {
                    info.idOF = -1;
                    info.numeroOF = '';
                    info.idCliente = -1;
                    info.cliente = '';
                    info.nombreSubCliente = '';
                    info.referencia = '';
                    info.idPieza = -1;
                    info.nombrePieza = '';
                    info.referenciaPieza = '';
                    info.numeroPlano = '';
                    info.idParte = -1;
                    info.nombreParte = '';
                    info.referenciaParte = '';
                    info.idRuta = -1;
                    info.nombreRuta = '';
                    info.usaLotes = 0;
                    info.cantidad = 0;
                    info.cantidadReal = 0;
                } else {
                    info.idOF = of_encontrado.idOF;
                    info.numeroOF = of_encontrado.numeroOF;
                    info.idCliente = of_encontrado.idCliente;
                    info.cliente = of_encontrado.cliente;
                    info.nombreSubCliente = of_encontrado.nombreSubCliente;
                    info.referencia = of_encontrado.referencia;
                    info.idPieza = of_encontrado.idPieza;
                    info.nombrePieza = of_encontrado.nombrePieza;
                    info.referenciaPieza = of_encontrado.referenciaPieza;
                    info.numeroPlano = of_encontrado.numeroPlano;
                    info.idParte = of_encontrado.idParte;
                    info.nombreParte = of_encontrado.nombreParte;
                    info.referenciaParte = of_encontrado.referenciaParte;
                    info.idRuta = of_encontrado.idRuta;
                    info.nombreRuta = of_encontrado.nombreRuta;
                    info.usaLotes = of_encontrado.usaLotes;
                    info.cantidad = of_encontrado.cantidad;
                    info.cantidadReal = of_encontrado.cantidad;
                }

                // Dependiendo de la configuracion. 1=>Nombre piezas.   2=>Numero plano.    3=>Referencia pieza
                info.pieza = info.nombrePieza;
                if (this.idHome_informacionPieza_DAT == 2) info.pieza = info.numeroPlano;
                else if (this.idHome_informacionPieza_DAT == 3) info.pieza = info.referenciaPieza;

                // CANTIDADES / TIEMPOS HOMR
                var hmoar_encontrado = (this.historico_maquinas_operaciones_agrupadas_resumen as any).filter(f => f.idHistorico_operaciones == info.idHistorico_operaciones && f.idMaquina == element.idMaquina)[0];
                if (hmoar_encontrado == undefined) {
                    info.hmoar_tiempoPreparacion = 0;
                    info.hmoar_tiempoOperacion = 0;
                    info.hmoar_tiempoTotalOperacion = 0;
                    info.hmoar_tiempoNoComputable = 0;
                    info.hmoar_tiempoDisponibilidad = 0;
                    info.hmoar_tiempoRendimiento = 0;
                    info.hmoar_tiempoCalidad = 0;
                    info.hmoar_tiempoEstimadoPreparacion = 0;
                    info.hmoar_tiempoEstimadoOperacion = 0;
                    info.hmoar_tiempoPredictivoPreparacion = 0;
                    info.hmoar_tiempoPredictivoOperacion = 0;
                    info.hmoar_cantidadTerminada = 0;
                    info.hmoar_cantidadApartada = 0;
                    info.hmoar_cantidadAchatarrada = 0;
                    info.hmoar_cantidad = 0;
                    info.hmoar_cantidadContador = 0;
                    info.hmoar_fecha_update = new Date();
                    // info.colada = '';
                    // info.lote = '';
                    // info.nserie = '';
                    info.cantidadMaquinaInduccion = 0;
                } else {
                    info.hmoar_tiempoPreparacion = hmoar_encontrado.tiempoPreparacion;
                    info.hmoar_tiempoOperacion = hmoar_encontrado.tiempoOperacion;
                    info.hmoar_tiempoTotalOperacion = hmoar_encontrado.tiempoTotalOperacion;
                    info.hmoar_tiempoNoComputable = hmoar_encontrado.tiempoNoComputable;
                    info.hmoar_tiempoDisponibilidad = hmoar_encontrado.tiempoDisponibilidad;
                    info.hmoar_tiempoRendimiento = hmoar_encontrado.tiempoRendimiento;
                    info.hmoar_tiempoCalidad = hmoar_encontrado.tiempoCalidad;
                    info.hmoar_tiempoEstimadoPreparacion = hmoar_encontrado.tiempoEstimadoPreparacion;
                    info.hmoar_tiempoEstimadoOperacion = hmoar_encontrado.tiempoEstimadoOperacion;
                    info.hmoar_tiempoPredictivoPreparacion = hmoar_encontrado.tiempoPredictivoPreparacion;
                    info.hmoar_tiempoPredictivoOperacion = hmoar_encontrado.tiempoPredictivoOperacion;
                    info.hmoar_cantidadTerminada = hmoar_encontrado.cantidadTerminada;
                    info.hmoar_cantidadApartada = hmoar_encontrado.cantidadApartada;
                    info.hmoar_cantidadAchatarrada = hmoar_encontrado.cantidadAchatarrada;
                    info.hmoar_cantidad = hmoar_encontrado.cantidadTerminada + hmoar_encontrado.cantidadApartada + hmoar_encontrado.cantidadAchatarrada;
                    info.hmoar_cantidadContador = hmoar_encontrado.cantidadContador;
                    info.hmoar_fecha_update = hmoar_encontrado.fecha_update;
                    info.hmoar
                    if (info.hmoar_cantidad == 0)
                        info.hmoar_cantidad = 1; // si no hay datos de ninguna pieza, se contara como que se esta trabajando con la primera
                    // info.colada = hmoar_encontrado.colada;
                    // info.lote = hmoar_encontrado.lote;
                    // info.nserie = hmoar_encontrado.nserie;
                    info.cantidadMaquinaInduccion = hmoar_encontrado.cantidadMaquinaInduccion;
                }

                // HISTORICO OPERACIONES NUEVA
                var cantidades_encontrado = (this.info_cantidades as any).filter(f => f.idHistorico_operaciones == info.idHistorico_operaciones)[0];
                if (cantidades_encontrado == undefined) {
                    info.ho_cantidadAchatarrada = 0;
                    info.ho_cantidadApartada = 0;
                    info.ho_cantidadTerminada = 0;
                } else {
                    info.ho_cantidadAchatarrada = cantidades_encontrado.ho_cantidadAchatarrada;
                    info.ho_cantidadApartada = cantidades_encontrado.ho_cantidadApartada;
                    info.ho_cantidadTerminada = cantidades_encontrado.ho_cantidadTerminada;
                }

                // GRUPOS OPERACIONES
                var gruposOperaciones_encontrado = (this.info_gruposOperaciones as any).filter(f => f.idMaquina == element.idMaquina);
                if (gruposOperaciones_encontrado == undefined || gruposOperaciones_encontrado.length == 0) {
                    info.tieneGrupos = false;
                    info.gruposOperaciones = [{
                        tiempoEstimadoTotal: 0
                        , temperaturaTotal: 0
                        , terminado: 0
                        , nombreGrupo: ''
                        , unidadesTotal: 0
                        , pesoTotal: 0
                        , peso: 0
                        , unidades: 0
                        , temperatura: 0
                        , tiempoEstimado: 0
                        , cantidad: 0
                        , numeroOf: ''
                        , nombrePieza: ''
                        , nombreOperacion: ''
                        , colorCantidad: ''
                    }];
                } else {
                    info.gruposOperaciones = [];
                    info.tieneGrupos = true;
                    info.grupoCantidadOperaciones = gruposOperaciones_encontrado.length;
                    info.grupoCantidadPiezas = gruposOperaciones_encontrado.map(x => x.ogo_cantidad).reduce(function (a, b) { return a + b; }, 0)

                    gruposOperaciones_encontrado.forEach(op => {

                        var colorCantidad = 'rojo';
                        if ((new Date(op.fechaFinLimite)).getTime() - (new Date(this.horaServidor)).getTime() > 0)
                            colorCantidad = 'verde';

                        info.gruposOperaciones.push({
                            tiempoEstimadoTotal: op.hmog_tiempoEstimado
                            , temperaturaTotal: op.og_temperatura
                            , terminado: op.hmog_terminado
                            , nombreGrupo: this.translateService.instant('grupo') + " " + op.idGrupo
                            , unidadesTotal: gruposOperaciones_encontrado.map(x => x.ofo_unidades).reduce(function (a, b) { return a + b; }, 0)
                            , pesoTotal: gruposOperaciones_encontrado.map(x => x.ofo_peso).reduce(function (a, b) { return a + b; }, 0)
                            , peso: op.ofo_peso
                            , unidades: op.ofo_unidades
                            , temperatura: op.ofo_temperatura
                            , tiempoEstimado: op.ofo_tiempoEstimado
                            , cantidad: op.ogo_cantidad
                            , numeroOF: op.numeroOF
                            , nombrePieza: op.nombrePieza
                            , nombreOperacion: op.nombreOperacion
                            , colorCantidad: colorCantidad
                        });
                    });
                }

                // CALIDAD
                var calidad_encontrado = (this.info_calidad as any).filter(f => f.idOperacion == info.idOperacion);
                info.calidad = [];
                if (calidad_encontrado.length > 0) {
                    calidad_encontrado.forEach(element => {
                        info.calidad.push({
                            idOperacion: element.idOperacion,
                            tiempo: element.tiempo
                        });
                    });
                }
                info.tiempoCalidadRestante = 0;

                // TIEMPOS 
                info.tiempoLotePrepTurno = 0;
                info.tiempoLoteTurno = 0;
                info.contadorTurno = 0;
                info.tiempoUltimaPieza = 0;
                info.tiempoLote = 0;
                info.tiempoLotePrep = 0;
                info.cantidadApartadaTurno = 0;
                info.cantidadAchatarradaTurno = 0;

                // // TIEMPOS 
                // var tiempos_encontrado = (this.info_tiempos as any).filter(f => f.idMaquina == element.idMaquina);
                // if (tiempos_encontrado.length == 0) {
                //     info.tiempoLotePrepTurno = 0;
                //     info.tiempoLoteTurno = 0;
                //     info.contadorTurno = 0;
                //     info.tiempoUltimaPieza = 0;
                //     info.tiempoLote = 0;
                //     info.tiempoLotePrep = 0;
                //     info.cantidadApartadaTurno = 0;
                //     info.cantidadAchatarradaTurno = 0;
                // } else {
                //     info.tiempoLotePrepTurno = tiempos_encontrado.map(f => f.tiempoLotePrepTurno).reduce(function (a, b) { return a + b; }, 0);
                //     info.tiempoLoteTurno = tiempos_encontrado.map(f => f.tiempoLoteTurno).reduce(function (a, b) { return a + b; }, 0);
                //     info.contadorTurno = tiempos_encontrado[tiempos_encontrado.length - 1].contadorTurno - tiempos_encontrado[0].contadorTurno;
                //     info.cantidadApartadaTurno = tiempos_encontrado.map(f => f.cantidadApartada).reduce(function (a, b) { return a + b; }, 0);
                //     info.cantidadAchatarradaTurno = tiempos_encontrado.map(f => f.cantidadAchatarrada).reduce(function (a, b) { return a + b; }, 0);

                //     info.tiempoUltimaPieza = tiempos_encontrado[tiempos_encontrado.length - 1].tiempoLoteTurno;
                //     info.tiempoLote = tiempos_encontrado[0].tiempoLote;
                //     info.tiempoLotePrep = tiempos_encontrado[0].tiempoLotePrep;;
                // }

            });
            //#endregion   

            // SUBESTADOS
            var subestado_encontrado = (this.info_subestados as any).filter(f => f.idSubestado == element.idSubEstado && f.idMaquina == element.idMaquina)[0];
            if (subestado_encontrado == undefined) {
                element.subestado = '';
                element.color_subestado = 'rgba(85, 222, 165, 1)';
                element.machine_subestado = 0;
            } else {
                element.subestado = subestado_encontrado.nombre;
                element.color_subestado = subestado_encontrado.color;
                element.machine_subestado = subestado_encontrado.machine;
            }
            var subestado_encontrado = (this.info_subestados as any).filter(f => f.idEstado == element.idEstado && f.idMaquina == element.idMaquina && f.idEstado < 100)[0];
            if (subestado_encontrado == undefined) {
                element.estado = '';
                element.color_estado = 'rgba(85, 222, 165, 1)';
                element.machine_estado = 0;
            } else {
                element.estado = subestado_encontrado.nombre;
                element.color_estado = subestado_encontrado.color;
                element.machine_estado = subestado_encontrado.machine;
            }

            // OPERARIOS
            element.datosOperarios.forEach(info => {
                var operario_encontrado = (this.info_operarios as any).filter(f => f.id == info.idOperario)[0];
                if (operario_encontrado == undefined) {
                    info.operario = this.translateService.instant('sinOperario');
                    info.operarioSiglas = "";
                } else {
                    info.operario = operario_encontrado.nombre + ' ' + operario_encontrado.apellido1;
                    info.operarioSiglas = operario_encontrado.nombre?.charAt(0) + operario_encontrado.apellido1?.charAt(0);
                }

            });

            // DATOS TIEMPO REAL HORNOS
            var datosHorno_encontrado = (this.info_datosTiempoRealHornos as any).filter(f => f.idMaquina == element.idMaquina)[0];
            if (datosHorno_encontrado == undefined) {
                element.piezasHora = 0;
                element.sinConexion = true;
                element.capaActual = 0;
                element.totalCapas = 0;
                element.nombreMaterial = '';
                element.refMaterial = '';
                element.refMolde = '';

            } else {
                element.piezasHora = datosHorno_encontrado.piezas_hora;
                element.sinConexion = datosHorno_encontrado.sinConexion;
                element.capaActual = datosHorno_encontrado.capaActual;
                element.totalCapas = datosHorno_encontrado.totalCapas;
                element.nombreMaterial = datosHorno_encontrado.nombreMaterial;
                element.refMaterial = datosHorno_encontrado.refMaterial;
                element.refMolde = datosHorno_encontrado.refMolde;
            }
            if (element.puestoOffline)
                element.sinConexion = false;


            //#region Preparar los parametros configurables
            // PARAMETROS
            if (element.tipo_maquina != 1) {
                var parametros_encontrados = this.myFunctions.copy(this.parametros as any).filter(f => f.idMaquina == element.idMaquina)[0];
                if (parametros_encontrados == undefined) {
                    //#region inyectora
                    if (element.tipo_maquina == 4) {
                        element.contrapresion = 0;
                        element.tiempoInyeccion = 0;
                        element.tiempoPresionRemanente = 0;
                        element.tiempoDosificacionPlastificacion = 0;
                        element.tiempoEnfriamiento = 0;
                        element.paradaDosificado = 0;
                        element.tiempoCiclo = 0;
                        element.presionInyeccionMax = 0;
                        element.ptoConmutacion = 0;
                        element.presionRemanente = 0;
                        element.cojin = 0;
                        element.retrocesoHusillo = 0;
                        element.paradaMolde = 0;
                        element.revolucionesHusillo = 0;
                        element.fuerzaDeCierre = 0;
                        element.temperaturaMolde = 0;

                    }
                    //#endregion

                    //#region hornos
                    if (element.tipo_maquina == 5) {
                        element.energia = 0
                        element.potencia = 0
                        element.intensidad = 0
                        element.voltaje = 0
                        element.frecuencia = 0
                        element.caudal = 0
                        element.temperaturaPieza = 0
                        element.presionBombaDucha = 0
                        element.temperaturaPolimero = 0
                        element.porcenPolimero = 0
                        element.tiempoCiclo = 0
                        element.tiempoCalentamientoTotal = 0
                        element.tiempoEnfriamientoTotal = 0
                        element.energia_min = 0
                        element.energia_max = 0
                        element.potencia_min = 0
                        element.potencia_max = 0
                        element.intensidad_min = 0
                        element.intensidad_max = 0
                        element.voltaje_min = 0
                        element.voltaje_max = 0
                        element.frecuencia_min = 0
                        element.frecuencia_max = 0
                        element.caudal_min = 0
                        element.caudal_max = 0
                        element.temperaturaPieza_min = 0
                        element.temperaturaPieza_max = 0
                        element.presionBombaDucha_min = 0
                        element.presionBombaDucha_max = 0
                        element.temperaturaPolimero_min = 0
                        element.temperaturaPolimero_max = 0
                        element.porcenPolimero_min = 0
                        element.porcenPolimero_max = 0
                        element.tiempoCiclo_min = 0
                        element.tiempoCiclo_max = 0
                        element.tiempoCalentamientoTotal_min = 0
                        element.tiempoCalentamientoTotal_max = 0
                        element.tiempoEnfriamientoTotal_min = 0
                        element.tiempoEnfriamientoTotal_max = 0
                    }
                    //#endregion

                    //#region aditivos
                    if (element.tipo_maquina == 9) {
                        element.FDM_temperaturaHorno = 0;
                        element.FDM_temperaturaExtrusor = 0;
                        element.FDM_dewPoint = 0;
                        element.FDM_airFlow = 0;
                        element.FDM_VoltageUPS = 0;
                        element.FDM_ligthTower = 0;
                        element.FDM_puertaAbiertaCerrada = 0;
                        element.FDM_posicionesEjes = 0;
                        element.FDM_platinaAspiradora = 0;
                        element.FDM_cartuchos = 0;
                        element.LPFB_nivelDePolvo = 0;
                        element.LPFB_nivelDeO2 = 0;
                        element.LPFB_temperaturaCamara = 0;
                        element.LPFB_presionCamara = 0;
                        element.LPFB_potenciaRecirculacion = 0;
                        element.LPFB_presionEntradaCamara = 0;
                        element.LPFB_temperaturaElevador = 0;
                        element.LPFB_temperaturaAguaHelada = 0;
                        element.LPFB_horasFiltro = 0;
                        element.LMD_potenciaDeLaser = 0;
                        element.LMD_velocidadDelRobot = 0;
                        element.LMD_flujoDeMaterial = 0;
                        element.LMD_materialConsumido = 0;
                        element.LMD_flujoDeArgon = 0;
                        element.HP_nivelesDeAgentes = 0;
                        element.HP_modoDeImpresion = 0;
                    }
                    //#endregion
                } else {
                    //#region inyectora
                    if (element.tipo_maquina == 4) {
                        element.contrapresion = parametros_encontrados.contrapresion;
                        element.tiempoInyeccion = parametros_encontrados.tiempoInyeccion;
                        element.tiempoPresionRemanente = parametros_encontrados.tiempoPresionRemanente;
                        element.tiempoDosificacionPlastificacion = parametros_encontrados.tiempoDosificacionPlastificacion;
                        element.tiempoEnfriamiento = parametros_encontrados.tiempoEnfriamiento;
                        element.paradaDosificado = parametros_encontrados.paradaDosificado;
                        element.tiempoCiclo = parametros_encontrados.tiempoCiclo;
                        element.presionInyeccionMax = parametros_encontrados.presionInyeccionMax;
                        element.ptoConmutacion = parametros_encontrados.ptoConmutacion;
                        element.presionRemanente = parametros_encontrados.presionRemanente;
                        element.cojin = parametros_encontrados.cojin;
                        element.retrocesoHusillo = parametros_encontrados.retrocesoHusillo;
                        element.paradaMolde = parametros_encontrados.paradaMolde;
                        element.revolucionesHusillo = parametros_encontrados.revolucionesHusillo;
                        element.fuerzaDeCierre = parametros_encontrados.fuerzaDeCierre;
                        element.temperaturaMolde = parametros_encontrados.temperaturaMolde;
                    }
                    //#endregion

                    //#region hornos
                    if (element.tipo_maquina == 5) {
                        element.energia = parametros_encontrados.energia;
                        element.potencia = parametros_encontrados.potencia;
                        element.intensidad = parametros_encontrados.intensidad;
                        element.voltaje = parametros_encontrados.voltaje;
                        element.frecuencia = parametros_encontrados.frecuencia;
                        element.caudal = parametros_encontrados.caudal;
                        element.temperaturaPieza = parametros_encontrados.temperaturaPieza;
                        element.presionBombaDucha = parametros_encontrados.presionBombaDucha;
                        element.temperaturaPolimero = parametros_encontrados.temperaturaPolimero;
                        element.porcenPolimero = parametros_encontrados.porcenPolimero;
                        element.tiempoCiclo = parametros_encontrados.tiempoCiclo;
                        element.tiempoCalentamientoTotal = parametros_encontrados.tiempoCalentamientoTotal;
                        element.tiempoEnfriamientoTotal = parametros_encontrados.tiempoEnfriamientoTotal;
                        element.energia_min = parametros_encontrados.energia_min;
                        element.energia_max = parametros_encontrados.energia_max;
                        element.potencia_min = parametros_encontrados.potencia_min;
                        element.potencia_max = parametros_encontrados.potencia_max;
                        element.intensidad_min = parametros_encontrados.intensidad_min;
                        element.intensidad_max = parametros_encontrados.intensidad_max;
                        element.voltaje_min = parametros_encontrados.voltaje_min;
                        element.voltaje_max = parametros_encontrados.voltaje_max;
                        element.frecuencia_min = parametros_encontrados.frecuencia_min;
                        element.frecuencia_max = parametros_encontrados.frecuencia_max;
                        element.caudal_min = parametros_encontrados.caudal_min;
                        element.caudal_max = parametros_encontrados.caudal_max;
                        element.temperaturaPieza_min = parametros_encontrados.temperaturaPieza_min;
                        element.temperaturaPieza_max = parametros_encontrados.temperaturaPieza_max;
                        element.presionBombaDucha_min = parametros_encontrados.presionBombaDucha_min;
                        element.presionBombaDucha_max = parametros_encontrados.presionBombaDucha_max;
                        element.temperaturaPolimero_min = parametros_encontrados.temperaturaPolimero_min;
                        element.temperaturaPolimero_max = parametros_encontrados.temperaturaPolimero_max;
                        element.porcenPolimero_min = parametros_encontrados.porcenPolimero_min;
                        element.porcenPolimero_max = parametros_encontrados.porcenPolimero_max;
                        element.tiempoCiclo_min = parametros_encontrados.tiempoCiclo_min;
                        element.tiempoCiclo_max = parametros_encontrados.tiempoCiclo_max;
                        element.tiempoCalentamientoTotal_min = parametros_encontrados.tiempoCalentamientoTotal_min;
                        element.tiempoCalentamientoTotal_max = parametros_encontrados.tiempoCalentamientoTotal_max;
                        element.tiempoEnfriamientoTotal_min = parametros_encontrados.tiempoEnfriamientoTotal_min;
                        element.tiempoEnfriamientoTotal_max = parametros_encontrados.tiempoEnfriamientoTotal_max;
                    }
                    //#endregion

                    //#region aditivos
                    if (element.tipo_maquina == 9) {
                        element.FDM_temperaturaHorno = parametros_encontrados.fdm_temperaturaHorno;
                        element.FDM_temperaturaExtrusor = parametros_encontrados.fdm_temperaturaExtrusor;
                        element.FDM_dewPoint = parametros_encontrados.fdm_dewPoint;
                        element.FDM_airFlow = parametros_encontrados.fdm_airFlow;
                        element.FDM_VoltageUPS = parametros_encontrados.fdm_VoltageUPS;
                        element.FDM_ligthTower = parametros_encontrados.fdm_ligthTower;
                        element.FDM_puertaAbiertaCerrada = parametros_encontrados.fdm_puertaAbiertaCerrada;
                        element.FDM_posicionesEjes = parametros_encontrados.fdm_posicionesEjes;
                        element.FDM_platinaAspiradora = parametros_encontrados.fdm_platinaAspiradora;
                        element.FDM_cartuchos = parametros_encontrados.fdm_cartuchos;
                        element.LPFB_nivelDePolvo = parametros_encontrados.lpfb_nivelDePolvo;
                        element.LPFB_nivelDeO2 = parametros_encontrados.lpfb_nivelDeO2;
                        element.LPFB_temperaturaCamara = parametros_encontrados.lpfb_temperaturaCamara;
                        element.LPFB_presionCamara = parametros_encontrados.lpfb_presionCamara;
                        element.LPFB_potenciaRecirculacion = parametros_encontrados.lpfb_potenciaRecirculacion;
                        element.LPFB_presionEntradaCamara = parametros_encontrados.lpfb_presionEntradaCamara;
                        element.LPFB_temperaturaElevador = parametros_encontrados.lpfb_temperaturaElevador;
                        element.LPFB_temperaturaAguaHelada = parametros_encontrados.lpfb_temperaturaAguaHelada;
                        element.LPFB_horasFiltro = parametros_encontrados.lpfb_horasFiltro;
                        element.LMD_potenciaDeLaser = parametros_encontrados.lmd_potenciaDeLaser;
                        element.LMD_velocidadDelRobot = parametros_encontrados.lmd_velocidadDelRobot;
                        element.LMD_flujoDeMaterial = parametros_encontrados.lmd_flujoDeMaterial;
                        element.LMD_materialConsumido = parametros_encontrados.lmd_materialConsumido;
                        element.LMD_flujoDeArgon = parametros_encontrados.lmd_flujoDeArgon;
                        element.HP_nivelesDeAgentes = parametros_encontrados.hp_nivelesDeAgentes;
                        element.HP_modoDeImpresion = parametros_encontrados.hp_modoDeImpresion;
                    }
                    //#endregion
                };
            }

            // PARAMETROS INFO (TODO MENOS MECANIZADO)
            if (element.tipo_maquina != 1) {
                element.info_ejes = [];
                var isAditivo = element.tipo_maquina == 9;
                var ejes_encontrados1 = this.myFunctions.copy(this.info_parametros as any).filter(f => f.id == element.idEje1 && f.isAditivo == isAditivo)[0];
                if (ejes_encontrados1 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0,
                        ensennarGrafico: false
                    });
                } else {
                    var valores;
                    var min = element[ejes_encontrados1.columna + "_min"];
                    var max = element[ejes_encontrados1.columna + "_max"];
                    var v = element[ejes_encontrados1.columna];
                    var ensennarGrafico = true;
                    if (min == undefined) { min = 0; ensennarGrafico = false; }
                    if (max == undefined) max = 0;
                    if (v == undefined) v = 0;
                    valores = { min: min, max: max, valor: v }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados1.idEje,
                            nombreEje: ejes_encontrados1.nombre,
                            variableTipo_nombre: ejes_encontrados1.nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor,
                            ensennarGrafico: ensennarGrafico
                        });
                    }
                }

                var ejes_encontrados2 = this.myFunctions.copy(this.info_parametros as any).filter(f => f.id == element.idEje2 && f.isAditivo == isAditivo)[0];
                if (ejes_encontrados2 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0
                    });
                } else {
                    var valores;
                    var min = element[ejes_encontrados2.columna + "_min"];
                    var max = element[ejes_encontrados2.columna + "_max"];
                    var v = element[ejes_encontrados2.columna];
                    if (min == undefined) min = 0;
                    if (max == undefined) max = 0;
                    if (v == undefined) v = 0;
                    valores = { min: min, max: max, valor: v }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados2.idEje,
                            nombreEje: ejes_encontrados2.nombre,
                            variableTipo_nombre: ejes_encontrados2.nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor
                        });
                    }
                }

                var ejes_encontrados3 = this.myFunctions.copy(this.info_parametros as any).filter(f => f.id == element.idEje3 && f.isAditivo == isAditivo)[0];
                if (ejes_encontrados3 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0
                    });
                } else {
                    var valores;
                    var min = element[ejes_encontrados3.columna + "_min"];
                    var max = element[ejes_encontrados3.columna + "_max"];
                    var v = element[ejes_encontrados3.columna];
                    if (min == undefined) min = 0;
                    if (max == undefined) max = 0;
                    if (v == undefined) v = 0;
                    valores = { min: min, max: max, valor: v }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados3.idEje,
                            nombreEje: ejes_encontrados3.nombre,
                            variableTipo_nombre: ejes_encontrados3.nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor
                        });
                    }
                }

                var ejes_encontrados4 = this.myFunctions.copy(this.info_parametros as any).filter(f => f.id == element.idEje4 && f.isAditivo == isAditivo)[0];
                if (ejes_encontrados4 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0
                    });
                } else {
                    var valores;
                    var min = element[ejes_encontrados4.columna + "_min"];
                    var max = element[ejes_encontrados4.columna + "_max"];
                    var v = element[ejes_encontrados4.columna];
                    if (min == undefined) min = 0;
                    if (max == undefined) max = 0;
                    if (v == undefined) v = 0;
                    valores = { min: min, max: max, valor: v }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados4.idEje,
                            nombreEje: ejes_encontrados4.nombre,
                            variableTipo_nombre: ejes_encontrados4.nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor
                        });
                    }
                }
            }

            // PARAMETROS MECANIZADO
            if (element.tipo_maquina == 1) {
                var parametros_mecanizado_encontrados = this.myFunctions.copy(this.parametros_mecanizado as any).filter(f => f.idmaquina == element.idMaquina);
                if (parametros_mecanizado_encontrados == undefined || parametros_mecanizado_encontrados.length == 0) {
                    element.parametros_mecanizado = [{
                        idEje: -1,
                        idEjeVariable: -1,
                        nombre: '',
                        potenciometro: -1,
                        potenciometro_max: 0,
                        potenciometro_min: 0,
                        potenciometro2: -1,
                        potenciometro2_max: 0,
                        potenciometro2_min: 0,
                        avanceGeneral: -1,
                        avanceGeneral_max: 0,
                        avanceGeneral_min: 0,
                        avance_min: 0,
                        avance_max: 0,
                        avance: 0,
                        temperatura: 0,
                        consumo: 0,
                        posicion_max: 0,
                        posicion: 0,
                        posicion_restante: 0
                    }];
                } else {
                    element.parametros_mecanizado = [];
                    parametros_mecanizado_encontrados.forEach(item => {
                        element.parametros_mecanizado.push({
                            idEje: item.idEje,
                            idEjeVariable: item.idEjeVariable,
                            nombre: item.nombre,
                            potenciometro: item.potenciometro,
                            potenciometro_max: item.potenciometro_max,
                            potenciometro_min: 0,
                            potenciometro2: item.potenciometro2,
                            potenciometro2_max: item.potenciometro2_max,
                            potenciometro2_min: 0,
                            avanceGeneral: item.avanceGeneral,
                            avanceGeneral_max: item.avanceGeneral_max,
                            avanceGeneral_min: item.avanceGeneral_min,
                            avance_min: item.avance_min,
                            avance_max: item.avance_max,
                            avance: item.avance,
                            temperatura: item.temperatura,
                            consumo: item.consumo,
                            posicion_max: item.posicion_max,
                            posicion: item.posicion,
                            posicion_restante: item.posicion_restante
                        })
                    });
                }
            }

            // EJES
            if (element.tipo_maquina == 1) {
                element.info_ejes = [];
                var ejes_encontrados1 = this.myFunctions.copy(this.info_ejes as any).filter(f => f.idEje == element.idEje1 && f.idVariable_Tipo == element.idVariable_Tipo1)[0];
                if (ejes_encontrados1 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0,
                        ensennarGrafico: false
                    });
                } else {
                    var valores;
                    if (ejes_encontrados1.idEje == 0) {
                        if (ejes_encontrados1.idVariable_Tipo == 1)
                            valores = { min: element.parametros_mecanizado[0].potenciometro_min, max: element.parametros_mecanizado[0].potenciometro_max, valor: element.parametros_mecanizado[0].potenciometro }
                        if (ejes_encontrados1.idVariable_Tipo == 2)
                            valores = { min: element.parametros_mecanizado[0].potenciometro2_min, max: element.parametros_mecanizado[0].potenciometro2_max, valor: element.parametros_mecanizado[0].potenciometro2 }
                        if (ejes_encontrados1.idVariable_Tipo == 3)
                            valores = { min: element.parametros_mecanizado[0].avanceGeneral_min, max: element.parametros_mecanizado[0].avanceGeneral_max, valor: element.parametros_mecanizado[0].avanceGeneral }
                    } else {
                        var valoresAux = element.parametros_mecanizado.filter(f => f.idEje == ejes_encontrados1.idEje)[0];
                        if (ejes_encontrados1.idVariable_Tipo == 1) // avance
                            valores = { min: ejes_encontrados1.minimo, max: ejes_encontrados1.maximo, valor: valoresAux.avance }
                        if (ejes_encontrados1.idVariable_Tipo == 2) // consumo
                            valores = { min: ejes_encontrados1.minimo, max: ejes_encontrados1.maximo, valor: valoresAux.consumo }
                        if (ejes_encontrados1.idVariable_Tipo == 3) // temperatura
                            valores = { min: ejes_encontrados1.minimo, max: ejes_encontrados1.maximo, valor: valoresAux.temperatura }
                        if (ejes_encontrados1.idVariable_Tipo == 4) // posicion
                            valores = { min: ejes_encontrados1.minimo, max: ejes_encontrados1.maximo, valor: valoresAux.posicion }
                    }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados1.idEje,
                            nombreEje: ejes_encontrados1.nombreEje,
                            variableTipo_nombre: ejes_encontrados1.variableTipo_nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor,
                            ensennarGrafico: true
                        });
                    }
                }

                var ejes_encontrados2 = this.myFunctions.copy(this.info_ejes as any).filter(f => f.idEje == element.idEje2 && f.idVariable_Tipo == element.idVariable_Tipo2)[0];
                if (ejes_encontrados2 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0,
                        ensennarGrafico: false
                    });
                } else {
                    var valores;
                    if (ejes_encontrados2.idEje == 0) {
                        if (ejes_encontrados2.idVariable_Tipo == 1)
                            valores = { min: element.parametros_mecanizado[0].potenciometro_min, max: element.parametros_mecanizado[0].potenciometro_max, valor: element.parametros_mecanizado[0].potenciometro }
                        if (ejes_encontrados2.idVariable_Tipo == 2)
                            valores = { min: element.parametros_mecanizado[0].potenciometro2_min, max: element.parametros_mecanizado[0].potenciometro2_max, valor: element.parametros_mecanizado[0].potenciometro2 }
                        if (ejes_encontrados2.idVariable_Tipo == 3)
                            valores = { min: element.parametros_mecanizado[0].avanceGeneral_min, max: element.parametros_mecanizado[0].avanceGeneral_max, valor: element.parametros_mecanizado[0].avanceGeneral }
                    } else {
                        var valoresAux = element.parametros_mecanizado.filter(f => f.idEje == ejes_encontrados2.idEje)[0];
                        if (ejes_encontrados2.idVariable_Tipo == 1) // avance
                            valores = { min: ejes_encontrados2.minimo, max: ejes_encontrados2.maximo, valor: valoresAux.avance }
                        if (ejes_encontrados2.idVariable_Tipo == 2) // consumo
                            valores = { min: ejes_encontrados2.minimo, max: ejes_encontrados2.maximo, valor: valoresAux.consumo }
                        if (ejes_encontrados2.idVariable_Tipo == 3) // temperatura
                            valores = { min: ejes_encontrados2.minimo, max: ejes_encontrados2.maximo, valor: valoresAux.temperatura }
                        if (ejes_encontrados2.idVariable_Tipo == 4) // posicion
                            valores = { min: ejes_encontrados2.minimo, max: ejes_encontrados2.maximo, valor: valoresAux.posicion }
                    }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados2.idEje,
                            nombreEje: ejes_encontrados2.nombreEje,
                            variableTipo_nombre: ejes_encontrados2.variableTipo_nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor,
                            ensennarGrafico: true
                        });
                    }
                }

                var ejes_encontrados3 = this.myFunctions.copy(this.info_ejes as any).filter(f => f.idEje == element.idEje3 && f.idVariable_Tipo == element.idVariable_Tipo3)[0];
                if (ejes_encontrados3 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0,
                        ensennarGrafico: false
                    });
                } else {
                    var valores;
                    if (ejes_encontrados3.idEje == 0) {
                        if (ejes_encontrados3.idVariable_Tipo == 1)
                            valores = { min: element.parametros_mecanizado[0].potenciometro_min, max: element.parametros_mecanizado[0].potenciometro_max, valor: element.parametros_mecanizado[0].potenciometro }
                        if (ejes_encontrados3.idVariable_Tipo == 2)
                            valores = { min: element.parametros_mecanizado[0].potenciometro2_min, max: element.parametros_mecanizado[0].potenciometro2_max, valor: element.parametros_mecanizado[0].potenciometro2 }
                        if (ejes_encontrados3.idVariable_Tipo == 3)
                            valores = { min: element.parametros_mecanizado[0].avanceGeneral_min, max: element.parametros_mecanizado[0].avanceGeneral_max, valor: element.parametros_mecanizado[0].avanceGeneral }
                    } else {
                        var valoresAux = element.parametros_mecanizado.filter(f => f.idEje == ejes_encontrados3.idEje)[0];
                        if (ejes_encontrados3.idVariable_Tipo == 1) // avance
                            valores = { min: ejes_encontrados3.minimo, max: ejes_encontrados3.maximo, valor: valoresAux.avance }
                        if (ejes_encontrados3.idVariable_Tipo == 2) // consumo
                            valores = { min: ejes_encontrados3.minimo, max: ejes_encontrados3.maximo, valor: valoresAux.consumo }
                        if (ejes_encontrados3.idVariable_Tipo == 3) // temperatura
                            valores = { min: ejes_encontrados3.minimo, max: ejes_encontrados3.maximo, valor: valoresAux.temperatura }
                        if (ejes_encontrados3.idVariable_Tipo == 4) // posicion
                            valores = { min: ejes_encontrados3.minimo, max: ejes_encontrados3.maximo, valor: valoresAux.posicion }
                    }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados3.idEje,
                            nombreEje: ejes_encontrados3.nombreEje,
                            variableTipo_nombre: ejes_encontrados3.variableTipo_nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor,
                            ensennarGrafico: true
                        });
                    }
                }

                var ejes_encontrados4 = this.myFunctions.copy(this.info_ejes as any).filter(f => f.idEje == element.idEje4 && f.idVariable_Tipo == element.idVariable_Tipo4)[0];
                if (ejes_encontrados4 == undefined) {
                    element.info_ejes.push({
                        idEje: -1,
                        nombreEje: '',
                        variableTipo_nombre: '',
                        valor_min: 0,
                        valor_max: 0,
                        valor: 0,
                        ensennarGrafico: false
                    });
                } else {
                    var valores;
                    if (ejes_encontrados4.idEje == 0) {
                        if (ejes_encontrados4.idVariable_Tipo == 1)
                            valores = { min: element.parametros_mecanizado[0].potenciometro_min, max: element.parametros_mecanizado[0].potenciometro_max, valor: element.parametros_mecanizado[0].potenciometro }
                        if (ejes_encontrados4.idVariable_Tipo == 2)
                            valores = { min: element.parametros_mecanizado[0].potenciometro2_min, max: element.parametros_mecanizado[0].potenciometro2_max, valor: element.parametros_mecanizado[0].potenciometro2 }
                        if (ejes_encontrados4.idVariable_Tipo == 3)
                            valores = { min: element.parametros_mecanizado[0].avanceGeneral_min, max: element.parametros_mecanizado[0].avanceGeneral_max, valor: element.parametros_mecanizado[0].avanceGeneral }
                    } else {
                        var valoresAux = element.parametros_mecanizado.filter(f => f.idEje == ejes_encontrados4.idEje)[0];
                        if (ejes_encontrados4.idVariable_Tipo == 1) // avance
                            valores = { min: ejes_encontrados4.minimo, max: ejes_encontrados4.maximo, valor: valoresAux.avance }
                        if (ejes_encontrados4.idVariable_Tipo == 2) // consumo
                            valores = { min: ejes_encontrados4.minimo, max: ejes_encontrados4.maximo, valor: valoresAux.consumo }
                        if (ejes_encontrados4.idVariable_Tipo == 3) // temperatura
                            valores = { min: ejes_encontrados4.minimo, max: ejes_encontrados4.maximo, valor: valoresAux.temperatura }
                        if (ejes_encontrados4.idVariable_Tipo == 4) // posicion
                            valores = { min: ejes_encontrados4.minimo, max: ejes_encontrados4.maximo, valor: valoresAux.posicion }
                    }

                    if (valores != undefined) {
                        element.info_ejes.push({
                            idEje: ejes_encontrados4.idEje,
                            nombreEje: ejes_encontrados4.nombreEje,
                            variableTipo_nombre: ejes_encontrados4.variableTipo_nombre,
                            valor_min: valores.min,
                            valor_max: valores.max,
                            valor: valores.valor,
                            ensennarGrafico: true
                        });
                    }
                }
            }
            //#endregion

            element.tNegativoCalidad = 0;

            let index = 0;
            var tiempo = this.historico_tiempos_Map.get(element.idMaquina);
            element.datosOperaciones.forEach(info => {

                if (element.idMaquina == 1)
                    console.log(info);
                var cantidades = (this.info_contadores as any).filter(f => f.idOperacion == info.idOperacion && f.idMaquina == element.idMaquina)[0];
                if (cantidades == undefined) cantidades = {
                                                            idOperacion: -1, contadorAutomatico: 0, contadorManual: 0, contadorValidado: 0
                                                            , contadorManualAchatarradas: 0, contadorManualApartadas: 0, contadorManualPreparacion: 0, contadorTotalOperacionAutomatico: 0
                                                            , contadorTotalOperacionManual: 0, contadorTotalOperacionValidado: 0
                                                            , contadorTotalOperacionManualAchatarradas: 0, contadorTotalOperacionManualApartadas: 0
                                                            , contadorTotalOperacionManualPreparacion: 0
                                                          }
                info.cantidadTurno = cantidades.contadorAutomatico;
                if (element.id_contador_turno_tipo_DAT == 1)
                    info.cantidadTurno = Math.max(cantidades.contadorAutomatico, cantidades.contadorManual)
                info.cantidadTurnoMal = cantidades.contadorManualAchatarradas + cantidades.contadorManualApartadas;
                info.cantidadTotalOperacionMal = cantidades.contadorTotalOperacionManualAchatarradas + cantidades.contadorTotalOperacionManualApartadas;
                info.contadorAutomatico = cantidades.contadorAutomatico;
                info.contadorManual = cantidades.contadorTotalOperacionManual - cantidades.contadorTotalOperacionManualPreparacion;
                if (info.contadorManual < 0) info.contadorManual = 0;
                info.contadorValidado = cantidades.contadorValidado;
                info.contadorManualAchatarradas = cantidades.contadorManualAchatarradas;
                info.contadorManualApartadas = cantidades.contadorManualApartadas;
                info.contadorManualPreparacion = cantidades.contadorManualPreparacion;
                info.contadorTotalOperacionAutomatico = cantidades.contadorTotalOperacionAutomatico;
                if (element.tipo_maquina == 5)
                    info.contadorTotalOperacionAutomatico = info.cantidadMaquinaInduccion;
                info.contadorTotalOperacionManual = cantidades.contadorTotalOperacionManual;
                info.contadorTotalOperacionValidado = cantidades.contadorTotalOperacionValidado;
                info.contadorTotalOperacionManualAchatarradas = cantidades.contadorTotalOperacionManualAchatarradas;
                info.contadorTotalOperacionManualApartadas = cantidades.contadorTotalOperacionManualApartadas;
                info.contadorTotalOperacionManualPreparacion = cantidades.contadorTotalOperacionManualPreparacion;

                
                //#region Beharrezko parametroak hasieratu
                info.cantidadManual = info.contadorTotalOperacionManual - info.contadorTotalOperacionManualPreparacion;
                info.cantidadMalas = info.cantidadTotalOperacionMal;
                info.contadorEstimado = info.contadorTotalOperacionAutomatico;
                if (element.tipo_maquina == 5)
                    info.contadorEstimado = info.cantidadMaquinaInduccion;
                info.contadorEstimadoConPreparacion = info.contadorTotalOperacionAutomatico;// + info.contadorTotalOperacionManualPreparacion;

                if (tiempo != undefined && tiempo.length > 0) {
                    let tiempoDato = tiempo[index];

                    info.horaServidorTiempos = tiempoDato.horaServidorTiempos;

                    if (tiempoDato.tiempoLotePrep != 0 && info.contadorManual <= 0)
                        info.contadorEstimadoConPreparacion = info.contadorEstimado //+ info.contadorTotalOperacionManualPreparacion;

                    // si el contador es mayor a las piezas totales entonces las piezas totales = contador
                    if (Math.max(info.contadorEstimado, info.cantidadManual) > info.cantidad) info.cantidad = Math.max(info.contadorEstimado, info.cantidadManual);

                    //#region Tiempo preparacion
                    info.tiempoPreparacion = tiempoDato.tiempoPreparacion;
                    info.tiempoPreparacionRojo = tiempoDato.tiempoPreparacionRojo;
                    info.desvioPreparacion = tiempoDato.desvioPreparacion;
                    //#endregion

                    //#region Tiempo lote
                    info.porcentajeActualLote = tiempoDato.porcentajeActualLote;
                    info.tiempoRitmoLote = tiempoDato.tiempoRitmoLote;
                    info.tiempoActualLote = tiempoDato.tiempoActualLote;
                    info.tiempoRitmoLoteEstimadoEnsennar = tiempoDato.tiempoRitmoLoteEstimadoEnsennar;
                    info.tiempoRitmoLoteEstimadoAcumulado = tiempoDato.tiempoRitmoLoteEstimadoAcumulado;
                    info.tiempoRitmoLoteEstimadoRestante = tiempoDato.tiempoRitmoLoteEstimadoRestante;
                    info.desvioLote = tiempoDato.desvioLote;
                    info.tiempoLoteRojo = info.tiempoRitmoLote > info.tiempoRitmoLoteEstimadoEnsennar;
                    //#endregion

                    //#region Tiempo pieza
                    info.tiempoActualPieza = tiempoDato.tiempoActualPieza;
                    info.tiempoPiezaRitmo = tiempoDato.tiempoPiezaRitmo;
                    info.tiempoRitmoPiezaEstimado = tiempoDato.tiempoRitmoPiezaEstimado;
                    info.desvioPieza = tiempoDato.desvioPieza;
                    info.tiempoPiezaRojo = info.tiempoPiezaRojo;
                    
                    info.porcentajeActualPieza = 100;
                    if (info.tiempoRitmoPiezaEstimado != 0) {
                        var tiempoActualPieza: any = (new Date().getTime() - new Date(info.horaServidorTiempos).getTime()) / 1000 + info.tiempoActualPieza
                        info.porcentajeActualPieza = Math.round((tiempoActualPieza / info.tiempoRitmoPiezaEstimado) * 100);
                    }
                    //#endregion

                    info.porcentajePiezaCentral = tiempoDato.porcentajePiezaCentral;

                    //#region Tiempo turno
                    info.tiempoActualTurno = tiempoDato.tiempoActualTurno;
                    // info.cantidadTurno = tiempoDato.cantidadTurno;
                    info.cantidadTurnoMal = tiempoDato.cantidadTurnoMal;

                    info.tiempoRitmoTurno = tiempoDato.tiempoRitmoTurno;
                    info.tiempoRitmoTurnoEstimado = tiempoDato.tiempoRitmoTurnoEstimado;
                    info.desvioTurno = tiempoDato.desvioTurno;
                    info.tiempoTurnoRojo = info.tiempoRitmoTurno > info.tiempoRitmoTurnoEstimado;

                    info.porcentajeTurno = tiempoDato.porcentajeTurno;
                    //#endregion
                }

                //#endregion

                //#region GUARDAR LAS CANTIDADES ANTERIORES PARA QUE SE RECALCULE EL TIEMPO
                var cantidadAnterior = this.historico_cantidades_Map.get(element.idMaquina);
                if (cantidadAnterior == undefined) {
                    // Es la primera vuelta por lo que no se hace nada
                    this.historico_cantidades_Map.set(element.idMaquina, {
                        contadorManual: info.contadorManual
                        , contadorAutomatico: info.contadorAutomatico
                    });
                } else {
                    // Se debe cargar los tiempos si las cantidades son diferentes
                    if (cantidadAnterior.contadorManual != info.contadorManual || cantidadAnterior.contadorAutomatico != info.contadorAutomatico) {

                        this.historico_cantidades_Map.set(element.idMaquina, {
                            contadorManual: info.contadorManual
                            , contadorAutomatico: info.contadorAutomatico
                        });

                        this.cargar_tiempos().subscribe(j => {
                            this.set_tiempos(j);
                        });
                        
                        console.log("API TIEMPOS POR CANTIDADES DIFERENTES")
                    }
                }
                //#endregion

                //#region PIEZAS Y EUROS HORA------------------------------------------------------------------------------------------------------
                if (element.idMaquina == 1) 
                    console.log("hemen");
                var tEstTotal = Math.floor((info.hmoar_tiempoEstimadoOperacion * info.cantidadReal + info.hmoar_tiempoEstimadoPreparacion) / 3600);
                if (tEstTotal == 0)
                    element.piezasHoraEstimado = 0;
                else
                    element.piezasHoraEstimado = (info.cantidadReal / tEstTotal).toFixed(1);
                element.piezasHoraRojo = element.piezasHora < element.piezasHoraEstimado;

                element.eurosHoraEstimado = Math.round(element.piezasHoraEstimado * info.coste * 100) / 100;
                element.eurosHora = Math.round(element.piezasHora * info.coste * 100) / 100;
                element.eurosHoraRojo = element.eurosHora < element.eurosHoraEstimado;

                if (element.eurosHoraEstimado != 0)
                    element.porcentajeEurosHora = (element.eurosHora / element.eurosHoraEstimado * 100).toFixed(1);
                else
                    element.porcentajeEurosHora = 0;
                element.porcentajeEurosHoraRojo = element.porcentajeEurosHora < 100;

                //#endregion ----------------------------------------------------------------------------------------------------------------------
            
                index++;
            });

            if (element.datosOperaciones.length == 0) {
                element.piezasHoraEstimado = 0;
                element.piezasHoraRojo = element.piezasHora < element.piezasHoraEstimado;
                element.eurosHoraEstimado = Math.round(element.piezasHoraEstimado * /*element.coste*/ 0 * 100) / 100;
                element.eurosHora = Math.round(element.piezasHora * /*element.coste*/ 0 * 100) / 100;
                element.eurosHoraRojo = element.eurosHora < element.eurosHoraEstimado;
                if (element.eurosHoraEstimado != 0)
                    element.porcentajeEurosHora = (element.eurosHora / element.eurosHoraEstimado).toFixed(1);
                else
                    element.porcentajeEurosHora = 0;
                element.porcentajeEurosHoraRojo = element.porcentajeEurosHora < 100;
            }

            result.push(element);
        });

        // En la primera vuelta los historico operaciones seran los mismos
        if (this.timeoutzen == undefined) {
            this.historico_operaciones_Anterior_Map = new Map(this.historico_operaciones_Map);
            // this.actualizarTiempos();
        }
        this.montar_tiempos();

        (this.historico_maquinas_temporal as any).forEach(element => {
            // Comprobar que los historicos operaciones siguen siendo los mismos, en caso de que no sean iguales hay que forzar a hacer la llamada api tiempos
            if (JSON.stringify(this.historico_operaciones_Map.get(element.idMaquina)) != JSON.stringify(this.historico_operaciones_Anterior_Map.get(element.idMaquina))) {
                this.cargar_tiempos().subscribe(j => {
                    this.set_tiempos(j);
                });
                this.historico_operaciones_Anterior_Map = new Map(this.historico_operaciones_Map);
                console.log("API TIEMPOS POR OPERACION DIFERENTE")
            }
        });

        // this.montar_tiempos();
        return result;
    }
    public montar_tiempos() {
        if (this.info_tiempos != undefined && this.historico_maquinas_temporal != undefined) {
            (this.historico_maquinas_temporal as any).forEach(element => {

                // Calculos para tiempos
                element?.datosOperaciones.forEach(info => {

                    if (element.idMaquina == 1)
                        console.log("hemen");

                    if ((element.idHistorico_operaciones != element.idHistorico_operacionesAnterior) && !this.timeoutzen == undefined) {
                        element.idHistorico_operacionesAnterior = element.idHistorico_operaciones;
                    }

                    var tiempos_encontrado = (this.info_tiempos as any).filter(f => f.idMaquina == element.idMaquina);
                    if (tiempos_encontrado.length == 0) {
                        info.tiempoLotePrepTurno = 0;
                        info.tiempoLoteTurno = 0;
                        // info.contadorTurno = 0;
                        info.tiempoUltimaPieza = 0;
                        info.tiempoLote = 0;
                        info.tiempoLotePrep = 0;
                        info.cantidadApartadaTurno = 0;
                        info.cantidadAchatarradaTurno = 0;
                        info.diferenciaTiempo = 0;
                        info.horaServidorTiempos = new Date()
                        // info.cantidadManual = info.contadorManual;
                        // info.cantidadMalas = info.cantidadTotalOperacionMal;
                    } else {
                        // info.cantidadManual = info.contadorManual;
                        // info.cantidadMalas = info.cantidadTotalOperacionMal;
                        info.horaServidorTiempos = new Date(tiempos_encontrado[0].horaServidorTiempos);
                        info.diferenciaTiempo = tiempos_encontrado[tiempos_encontrado.length - 1].diferenciaTiempo;
                        info.tiempoLotePrepTurno = tiempos_encontrado.map(f => f.tiempoLotePrepTurno).reduce(function (a, b) { return a + b; }, 0);
                        if (element.idEstado == 3)
                            info.tiempoLotePrepTurno += info.diferenciaTiempo;
                        info.tiempoLoteTurno = tiempos_encontrado.map(f => f.tiempoLoteTurno).reduce(function (a, b) { return a + b; }, 0) + info.diferenciaTiempo;
                        // info.contadorTurno = tiempos_encontrado[tiempos_encontrado.length - 1].contadorTurno - Math.min(...tiempos_encontrado.map(f => f.contadorTurno));
                        // info.cantidadApartadaTurno = tiempos_encontrado.map(f => f.cantidadApartada).reduce(function (a, b) { return a + b; }, 0);
                        // info.cantidadAchatarradaTurno = tiempos_encontrado.map(f => f.cantidadAchatarrada).reduce(function (a, b) { return a + b; }, 0);

                        info.tiempoUltimaPieza = tiempos_encontrado[tiempos_encontrado.length - 1].tiempoUltimaPieza + info.diferenciaTiempo;
                        info.tiempoLote = tiempos_encontrado[0].tiempoLote + info.diferenciaTiempo;
                        if (element.idEstado == 3)
                            info.tiempoLotePrep = tiempos_encontrado[0].tiempoLotePrep + info.diferenciaTiempo;
                        else 
                            info.tiempoLotePrep = tiempos_encontrado[0].tiempoLotePrep;
                    }

                    //#region CALCULOS PARA TIEMPOS NECESARIOS CON CONSULTA-----------------------------------------------------------------------------
                    // info.contadorEstimado = element.contadorEstimado;
                    // if (element.tipo_maquina == 5)
                    //     info.contadorEstimado = info.cantidadMaquinaInduccion;
                    
                    // info.contadorEstimadoSinPreparacion = element.contadorEstimado;
                    // if (info.tiempoLotePrep != 0)
                    //     info.contadorEstimadoSinPreparacion = info.contadorEstimado - element.cantidadesTerminadasEnPreparacion;
                    
                    // info.contadorEstimadoConPreparacion = info.contadorAutomatico;
                    // if (info.tiempoLotePrep != 0 && info.cantidadManual <= 0)
                    //     info.contadorEstimadoConPreparacion = info.contadorAutomatico + element.cantidadesTerminadasEnPreparacion;

                    // si el contador es mayor a las piezas totales entonces las piezas totales = contador
                    let cantidadMax = Math.max((info.contadorEstimado - info.contadorTotalOperacionManualPreparacion), info.cantidadManual);
                    if ((cantidadMax + info.contadorTotalOperacionManualPreparacion) > info.cantidad) info.cantidad = cantidadMax + info.contadorTotalOperacionManualPreparacion;

                    //#region Tiempo preparacion
                    info.tiempoPreparacion = info.tiempoLotePrep;
                    info.tiempoPreparacionRojo = info.tiempoPreparacion > info.tiempoEstimadoPreparacion;
                    // info.desvioPreparacion = this.myFunctions.secondsTo_HHh_MM(info.tiempoPreparacion - info.hmoar_tiempoEstimadoPreparacion);
                    info.desvioPreparacion = this.myFunctions.secondsTo_HHh_MM(info.tiempoPreparacion - info.tiempoEstimadoPreparacion);
                    //#endregion

                    //#region Tiempo pieza
                    info.tiempoActualPieza = info.tiempoUltimaPieza;
                    info.tiempoPiezaRitmo = 0;
                    if (cantidadMax != 0) {
                        info.tiempoPiezaRitmo = (info.tiempoLote - info.tiempoActualPieza) / cantidadMax;
                    }
                    info.tiempoRitmoPiezaEstimado = info.tiempoEstimadoEjecucion;
                    if (info.cantidad > 1) {
                        info.tiempoActualPieza = info.tiempoUltimaPieza;
                        info.desvioPieza = this.myFunctions.secondsTo_HHh_MM(info.tiempoPiezaRitmo - info.tiempoRitmoPiezaEstimado);
                        info.tiempoPiezaRojo = info.tiempoPiezaRitmo > info.tiempoRitmoPiezaEstimado;
                    } else {
                        info.tiempoActualPieza = info.tiempoLote;
                        info.desvioPieza = this.myFunctions.secondsTo_HHh_MM(info.tiempoActualPieza - info.tiempoRitmoPiezaEstimado);
                        info.tiempoPiezaRojo = info.tiempoActualPieza > info.tiempoRitmoPiezaEstimado;
                    }

                    info.porcentajeActualPieza = 100;
                    if (info.tiempoRitmoPiezaEstimado != 0) {
                        var tiempoActualPieza: any = (new Date().getTime() - new Date(info.horaServidorTiempos).getTime()) / 1000 + info.tiempoActualPieza
                        info.porcentajeActualPieza = Math.round((tiempoActualPieza / info.tiempoRitmoPiezaEstimado) * 100);
                    }
                    //#endregion

                    //#region Tiempo lote
                    if (info.cantidad != 0)
                        info.porcentajeActualLote = Math.round((cantidadMax / info.cantidad) * 100);
                    else
                        info.porcentajeActualLote = 100

                    if (info.cantidad > 1) {
                        info.tiempoRitmoLoteEstimadoRestante = ((info.cantidad - cantidadMax) * info.tiempoRitmoPiezaEstimado); // tiempo estimado que falta para las piezas no hechas
                        info.tiempoRitmoLoteEstimadoAcumulado = (cantidadMax * info.tiempoRitmoPiezaEstimado); // tiempo estimado que falta para las piezas no hechas
                        if (this.home_tipoCalculoLote == 1) { // restante
                            info.tiempoActualLote = info.tiempoLote;
                            info.tiempoRitmoLoteEstimadoEnsennar = info.tiempoRitmoLoteEstimadoRestante;
                            info.tiempoRitmoLote = info.tiempoPiezaRitmo * (info.cantidad - cantidadMax);
                            info.desvioLote = this.myFunctions.secondsTo_HHh_MM(info.tiempoRitmoLote - info.tiempoRitmoLoteEstimadoEnsennar);
                            info.tiempoLoteRojo = info.tiempoRitmoLote > info.tiempoRitmoLoteEstimadoEnsennar;
                        } else if (this.home_tipoCalculoLote == 2) { // acumulado
                            info.tiempoActualLote = info.tiempoLote;
                            info.tiempoRitmoLoteEstimadoEnsennar = info.tiempoRitmoLoteEstimadoAcumulado;
                            info.tiempoRitmoLote = info.tiempoPiezaRitmo * cantidadMax;
                            info.desvioLote = this.myFunctions.secondsTo_HHh_MM(info.tiempoRitmoLote - info.tiempoRitmoLoteEstimadoEnsennar);
                            info.tiempoLoteRojo = info.tiempoRitmoLote > info.tiempoRitmoLoteEstimadoEnsennar;
                        } else if (this.home_tipoCalculoLote == 3) { // acumulado y preparacion
                            info.tiempoActualLote = info.tiempoLote + info.tiempoLotePrep;
                            info.tiempoRitmoLoteEstimadoEnsennar = info.tiempoRitmoLoteEstimadoAcumulado + info.tiempoEstimadoPreparacion;
                            info.tiempoRitmoLote = info.tiempoPiezaRitmo * cantidadMax  + info.tiempoPreparacion;
                            info.desvioLote = this.myFunctions.secondsTo_HHh_MM(info.tiempoRitmoLote - info.tiempoRitmoLoteEstimadoEnsennar);
                            info.tiempoLoteRojo = info.tiempoRitmoLote > info.tiempoRitmoLoteEstimadoEnsennar;
                        }
                    }
                    else {
                        info.tiempoActualLote = info.tiempoActualPieza + info.tiempoPreparacion;
                        info.tiempoRitmoLoteEstimadoRestante = ((info.cantidad - cantidadMax) * info.tiempoRitmoPiezaEstimado); // tiempo estimado que falta para las piezas no hechas
                        info.tiempoRitmoLoteEstimadoAcumulado = info.hmoar_tiempoEstimadoPreparacion + info.tiempoRitmoPiezaEstimado; // tiempo estimado que falta para las piezas no hechas
                        info.tiempoRitmoLoteEstimadoEnsennar = info.hmoar_tiempoEstimadoPreparacion + info.tiempoRitmoPiezaEstimado;
                        info.desvioLote = this.myFunctions.secondsTo_HHh_MM(info.tiempoActualLote - info.tiempoRitmoLoteEstimadoEnsennar);
                        info.tiempoLoteRojo = info.tiempoActualLote > info.tiempoRitmoLoteEstimadoEnsennar;
                    }
                    //#endregion
                
                    info.porcentajePiezaCentral = 100;
                    // if (this.home_tipoCalculoLote != 3 && info.tiempoRitmoLoteEstimadoEnsennar != 0) 
                        info.porcentajePiezaCentral = (info.tiempoRitmoLote / info.tiempoRitmoLoteEstimadoEnsennar * 100);
                    if (this.home_tipoCalculoLote == 1 && (info.tiempoRitmoLoteEstimadoEnsennar + info.tiempoEstimadoPreparacion) != 0)
                        info.porcentajePiezaCentral = (info.tiempoRitmoLote / (info.tiempoRitmoLoteEstimadoEnsennar + info.tiempoEstimadoPreparacion) * 100);
                    if (info.cantidad == 1)
                        info.porcentajePiezaCentral = ((info.tiempoActualLote / info.tiempoRitmoLoteEstimadoEnsennar) * 100);
                    
                    if (info.porcentajePiezaCentral > 100)
                        info.porcentajePiezaCentral = Math.round(info.porcentajePiezaCentral);

                    info.porcentajePiezaCentral -= 100;
                    info.porcentajePiezaCentral = Math.round(info.porcentajePiezaCentral);
                    if (info.porcentajePiezaCentral > 999) info.porcentajePiezaCentral = 999;
                    else if (info.porcentajePiezaCentral < -999) info.porcentajePiezaCentral = -999;

                    //#region Tiempo turno
                    info.tiempoActualTurno = info.tiempoLoteTurno + info.tiempoLotePrepTurno;
                    info.cantidadTurnoSinPreparacion = info.cantidadTurno - info.contadorManualPreparacion;
                    // info.cantidadTurno = info.contadorTurno;
                    if (info.tiempoPreparacion != 0 && info.cantidadManual <= 0)
                        info.cantidadTurno = info.cantidadTurno //+ info.contadorTotalOperacionManualPreparacion;
                    info.cantidadTurnoMal = info.cantidadApartadaTurno + info.cantidadAchatarradaTurno;

                    if (info.cantidadTurnoSinPreparacion != 0) info.tiempoRitmoTurno = (info.tiempoActualTurno - info.tiempoActualPieza) / info.cantidadTurnoSinPreparacion;
                    else info.tiempoRitmoTurno = info.tiempoActualTurno - info.tiempoActualPieza;

                    info.tiempoRitmoTurnoEstimado = info.tiempoRitmoPiezaEstimado;
                    info.desvioTurno = info.tiempoRitmoTurno - info.tiempoRitmoTurnoEstimado;
                    info.tiempoTurnoRojo = info.tiempoRitmoTurno > info.tiempoRitmoTurnoEstimado;

                    info.porcentajeTurno = 100;
                    if (info.tiempoRitmoTurnoEstimado != 0)
                        info.porcentajeTurno = (info.tiempoRitmoTurno / info.tiempoRitmoTurnoEstimado * 100).toFixed(1);
                    if (info.porcentajeTurno > 999) info.porcentajeTurno = 999;
                    else if (info.porcentajeTurno < -999) info.porcentajeTurno = -999;
                    //#endregion

                    //#region Tiempo restante calidad
                    var tiempoOperacion = 999999999;
                    var tiempoOperacionAux;
                    info.calidad.forEach(calidadItem => {
                        var tiempoAux = (Math.floor(info.tiempoActualLote / calidadItem.tiempo) + 1) * calidadItem.tiempo;
                        if (tiempoAux <= 0)
                            tiempoOperacionAux = calidadItem.tiempo - info.tiempoActualLote;
                        else
                            tiempoOperacionAux = tiempoAux - info.tiempoActualLote;
                        tiempoOperacion = Math.min(tiempoOperacion, tiempoOperacionAux);
                    });
                    if (tiempoOperacion == 999999999) tiempoOperacion = 0;

                    info.tiempoCalidadRestante = tiempoOperacion;

                    //#endregion

                    //#endregion -------------------------------------------------------------------------------------------------------------------------- 

                });

            });
        }
    }
    actualizarTiempos() {

        if ((this.router.url == '/home' || this.router.url.includes('/procesos') || this.router.url == '/') && this.timeoutzen == undefined)            
            this.timeoutzen = setTimeout((d) => { this.timeoutzen = undefined; this.actualizarTiempos();}, 1000);

        (this.historico_datos as any)?.forEach(element => {
            if (element != undefined) {
                // element.tiempoEstado += 1;
                element.datosOperaciones.forEach(dato => {
                    // if (element.idEstado != 3)
                    //     dato.tiempoActualPieza += 1;
                    // dato.tiempoActualLote += 1;
                    // dato.tiempoActualTurno += 1;
                    // if (element.idEstado == 3)
                    //     dato.tiempoPreparacion += 1;


                    // if (element.idEstado != 3)
                    //     dato.tiempoActualPieza =(element.now.getTime() - new Date(dato.horaServidorTiempos).getTime()) / 1000 + dato.tiempoActualPieza;
                    // dato.tiempoActualLote = (new Date().getTime() - new Date(dato.horaServidorTiempos).getTime()) / 1000 + dato.tiempoActualLote;
                    // dato.tiempoActualTurno = (new Date().getTime() - new Date(dato.horaServidorTiempos).getTime()) / 1000 + dato.tiempoActualTurno;
                    // if (element.idEstado == 3)
                    //     dato.tiempoPreparacion = (new Date().getTime() - new Date(dato.horaServidorTiempos).getTime()) / 1000 + dato.tiempoPreparacion;
                });
                this.historico_tiempos_Map.set(element.idMaquina, element?.datosOperaciones);
            }
        });
    }
    //#endregion

}