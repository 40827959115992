import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/informeoee`;

@Injectable()
export class InformeOeeService {

  public maquinas: Array<any> | boolean = false;
  public maquinasSeleccionadas: Array<any>;
  public maquinasCompletas: Array<any>;
  public seccionesSeleccionadas: Array<any>;
  public gruposSeleccionados: Array<any>;
  public fechaIni: Date;
  public fechaFin: Date;
  public AnnoMesSemanaDia: number;
  constructor(
    private http: HttpClient
  ) {
  }


  /* VARIABLES DE "SESION" */
  // maquinas
  public get_maquinas_model(): Array<any> | boolean {
    return this.maquinas;
  }
  public set_maquinas_model(maquinas: any): void {
    this.maquinas = maquinas;
  }
  // maquinasCompletas
  public get_maquinasCompletas_model(): Array<any> {
    return this.maquinasCompletas;
  }
  public set_maquinasCompletas_model(maquinasCompletas: any): void {
    this.maquinasCompletas = maquinasCompletas;
  }
  // maquinasSeleccionadas
  public get_maquinasSeleccionadas_model(): Array<any> {
    return this.maquinasSeleccionadas;
  }
  public set_maquinasSeleccionadas_model(maquinasSeleccionadas: any): void {
    this.maquinasSeleccionadas = maquinasSeleccionadas;
  }
  // seccionesSeleccionadas
  public get_seccionesSeleccionadas_model(): Array<any> {
    return this.seccionesSeleccionadas;
  }
  public set_seccionesSeleccionadas_model(seccionesSeleccionadas: any): void {
    this.seccionesSeleccionadas = seccionesSeleccionadas;
  }
  // gruposSeleccionados
  public get_gruposSeleccionados_model(): Array<any> {
    return this.gruposSeleccionados;
  }
  public set_gruposSeleccionados_model(gruposSeleccionados: any): void {
    this.gruposSeleccionados = gruposSeleccionados;
  }
  // fechaIni
  public get_fechaIni_model(): Date {
    return this.fechaIni;
  }
  public set_fechaIni_model(fechaIni: Date): void {
    this.fechaIni = fechaIni;
  }
  // fechaFin
  public get_fechaFin_model(): Date {
    return this.fechaFin;
  }
  public set_fechaFin_model(fechaFin: Date): void {
    this.fechaFin = fechaFin;
  }
  // AñoMesSemanaDia
  public get_AnnoMesSemanaDia_model(): number {
    return this.AnnoMesSemanaDia;
  }
  public set_AnnoMesSemanaDia_model(AnnoMesSemanaDia: number): void {
    this.AnnoMesSemanaDia = AnnoMesSemanaDia;
  }


  /*  API CALLS */
  get_maquinas() {
    return this.http.post<JSON>(`${baseUrl}/get_maquinas`, {}, { withCredentials: true });
  }

  get_objetivo_oee() {
    return this.http.post<JSON>(`${baseUrl}/get_objetivo_oee`, {}, { withCredentials: true });
  }

  Get_Oee_Fechas(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string ) {
    return this.http.post<JSON>(`${baseUrl}/Get_Oee_Fechas`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }

  Get_Perdidas_Disp(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Disp`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }

  Get_Oee_Of_Cliente_Pieza(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: string, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Oee_Of_Cliente_Pieza`, { fechaini, fechafin, IdMaquinas, strSinAsignar, idsTurnos: turno, operarios }, { withCredentials: true });
  }

  Get_Oee_Of_Cliente_Pieza_Operario(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Oee_Of_Cliente_Pieza_Operario`, { fechaini, fechafin, IdMaquinas, strSinAsignar }, { withCredentials: true });
  }

  Get_Perdidas_Disponibilidad(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Disponibilidad`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }

  Get_Perdidas_PorGrupo_tipoPerdida(tipo: number, fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, strSinGrupo: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorGrupo_tipoPerdida`, { tipo, fechaini, fechafin, IdMaquinas, strSinAsignar, strSinGrupo, turno, operarios }, { withCredentials: true });
  }

  Get_Perdidas_PorPadres_tipoPerdida(tipo: number,fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorPadres_tipoPerdida`, { tipo, fechaini, fechafin, IdMaquinas, strSinAsignar, strSinGrupo: 'no se usa! -> ' , turno, operarios}, { withCredentials: true }); //como se usa el mismo servicio para los padres y los grupos, se pasa el parametro strSinGrupo vacio
  }

  Get_Perdidas_Rendimiento(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Rendimiento`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }

  Get_Perdidas_Calidad(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Calidad`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }

  Get_Alarmas_Mantenimientos(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, idsTurnos: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Alarmas_Mantenimientos`, { fechaini, fechafin, IdMaquinas, strSinAsignar, idsTurnos }, { withCredentials: true });
  }

  Get_Perdidas_Todas(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, idsTurnos: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Todas`, { fechaini, fechafin, IdMaquinas, strSinAsignar, idsTurnos }, { withCredentials: true });
  }

  Get_Perdidas_PorGrupo(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, idsTurnos: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorGrupo`, { fechaini, fechafin, IdMaquinas, strSinAsignar, idsTurnos }, { withCredentials: true });
  }

  Get_Perdidas_PorSubPerdida(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, idsTurnos: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorSubperdida`, { fechaini, fechafin, IdMaquinas, strSinAsignar, idsTurnos }, { withCredentials: true });
  }

  Get_Perdidas_Todas_PorOperario(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Todas_PorOperario`, { fechaini, fechafin, IdMaquinas, strSinAsignar }, { withCredentials: true });
  }

  Get_Perdidas_Todas_ConFiltros(fechaInicio, fechaFin, listaIdClientes, listaIdPiezas, listaIdOfs, listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios, listaIdPartes, listaIdOperaciones, listaIdNserie, terminados, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Todas_ConFiltros`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaIdClientes: listaIdClientes, listaIdPiezas: listaIdPiezas, listaIdOfs: listaIdOfs,
      listaIdTurnos: listaIdTurnos, listaIdMaquinas: listaIdMaquinas, listaIdHerramientas: listaIdHerramientas,
      listaIdOperarios: listaIdOperarios, listaIdPartes: listaIdPartes, listaIdOperaciones: listaIdOperaciones, listaIdNserie: listaIdNserie, terminados: terminados, strSinAsignar: strSinAsignar
    }, { withCredentials: true });
  }

  Get_Perdidas_Todas_ConFiltros_filtrado(filtroCompleto: string, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Todas_ConFiltros_filtrado`, { filtroCompleto, strSinAsignar }, { withCredentials: true });
  }

  Get_Perdidas_PorGrupo_ConFiltros(fechaInicio, fechaFin, listaIdClientes, listaIdPiezas, listaIdOfs, listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios, listaIdPartes, listaIdOperaciones, listaIdNserie, terminados, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorGrupo_ConFiltros`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaIdClientes: listaIdClientes, listaIdPiezas: listaIdPiezas, listaIdOfs: listaIdOfs,
      listaIdTurnos: listaIdTurnos, listaIdMaquinas: listaIdMaquinas, listaIdHerramientas: listaIdHerramientas,
      listaIdOperarios: listaIdOperarios, listaIdPartes: listaIdPartes, listaIdOperaciones: listaIdOperaciones, listaIdNserie: listaIdNserie, terminados: terminados, strSinAsignar: strSinAsignar
    }, { withCredentials: true });
  }
  Get_Perdidas_PorGrupo_ConFiltros_filtrado(filtroCompleto: string, strSinAgrupar: string, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorGrupo_ConFiltros_filtrado`, { filtroCompleto, strSinAgrupar, strSinAsignar }, { withCredentials: true });
  }
  Get_Perdidas_PorPadre_ConFiltros_filtrado(filtroCompleto: string, strSinAsignar: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_PorPadre_ConFiltros_filtrado`, { filtroCompleto, strSinAsignar }, { withCredentials: true });
  }

  Get_Perdidas_Rend(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Rend`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }
  Get_Perdidas_Cal(fechaini: string, fechafin: string, IdMaquinas: string, strSinAsignar: string, turno: number, operarios: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_Perdidas_Cal`, { fechaini, fechafin, IdMaquinas, strSinAsignar, turno, operarios }, { withCredentials: true });
  }
  Get_Oee_Ultimo_Dia() {
    return this.http.post<JSON>(`${baseUrl}/Get_Oee_Ultimo_Dia`, {}, { withCredentials: true });
  }
}
