import { Component } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MaquinasService, ComboService, MenuService, MantenimientosPredefinidosService, ConfiguracionService, SubidaFicherosService } from '@app/_services';

import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
import { UsuariosService } from '@app/_services';
import { Perdida, Tipo, Combo, TipoMantenimiento_DAT } from '@app/_models';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { MyFunctions } from '../_helpers';
import { MantenimientosPredefinidosComponent } from '@app/mantenimientosPredefinidos/mantenimientosPredefinidos.component';


@Component({
  selector: 'app-maquina-detalle-mantenimiento',
  templateUrl: './maquinaDetalleMantenimiento.component.html'
})

export class MaquinaDetalleMantenimientoComponent {
  public idTipoAcceso: number = 0;
  //#region "CARGA"
  public isUploaded: boolean = false;
  public isLink: boolean = false;
  public borrarDoc: boolean;
  public id: number = -1;
  public idMaquina: number = -1;
  public tipoMaquina: any;
  public perdidas: Perdida[];
  public perdida_selectedItem: Perdida;
  public idPerdida: number;
  public tipos: Tipo[];
  public tipo_selectedItem: Tipo;
  public idTipo: number;
  public fichero: String = "";

  public tiempo: string;
  public contarDesdeUltimo: boolean;
  public contarEjecucion: boolean;
  public contarParada: boolean;
  public contarPreparacion: boolean;
  public contarMantenimiento: boolean;
  public contarAlarma: boolean;
  public contarApagada: boolean;
  public mask = 0;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 1048576
  };
  public uploadSaveUrl;
  public uploadRemoveUrl;

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  public tiposFechaTiempoMantenimientos_DAT: TipoMantenimiento_DAT[];
  public selectedFechaTiempoMantenimiento: TipoMantenimiento_DAT;
  public idTipoFechaTiempoMantenimiento_DAT: number;

  public autoCorrect = true;
  //lista dias
  public listaDias: string[];
  public listaMeses: string[];
  public listaTurnos: any = [];
  public turnosSeleccionados: any = [];
  public dias: any;
  public mes: any;

  rutaInvalida = false;

  constructor(
    private formBuilder: FormBuilder,
    private maquinasService: MaquinasService,
    private mantenimientosPredefinidosService: MantenimientosPredefinidosService,
    private comboService: ComboService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions,
    private configuracionService: ConfiguracionService,
    private subidaFicherosService: SubidaFicherosService) {

    this.menuService.titulo = this.translateService.instant('mantenimiento').toUpperCase();
  }

  ngOnInit() {
    // inicializar listas de dias y meses
    this.listaDias = [this.translateService.instant('lunes'), this.translateService.instant('martes'), this.translateService.instant('miercoles'),
    this.translateService.instant('jueves'), this.translateService.instant('viernes'), this.translateService.instant('sabado'), this.translateService.instant('domingo')];

    this.listaTurnos = [{id: 1, nombre: this.translateService.instant('manana')}, {id: 2, nombre: this.translateService.instant('tarde')}, {id: 3, nombre: this.translateService.instant('noche')}]

    this.listaMeses = [this.translateService.instant('enero'), this.translateService.instant('febrero'), this.translateService.instant('marzo'),
    this.translateService.instant('abril'), this.translateService.instant('mayo'), this.translateService.instant('junio'), this.translateService.instant('julio')
      , this.translateService.instant('agosto'), this.translateService.instant('septiembre'), this.translateService.instant('octubre'), this.translateService.instant('noviembre')
      , this.translateService.instant('diciembre')];

    this.id = this.route.snapshot.params['id'];
    this.idMaquina = this.route.snapshot.params['idMaquina'];
    this.borrarDoc = false;

    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idPerdida: [1,],
      nombre: ['', Validators.required],
      frecuencia: [1,],
      dia: [0,],
      hora: [new Date(1950, 0, 1, 0, 0),],
      tiempoEstimado: ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      link: ['',],
      activarLink: [false,],
      archivo: new FormControl(''),
      pdf: ['',],
      tareaMantenimiento: ['',],
      tiempo: [0,],
      contarDesdeUltimo: [true,],
      contarEjecucion: [true,],
      contarParada: [true,],
      contarPreparacion: [true,],
      contarMantenimiento: [true,],
      contarAlarma: [true,],
      contarApagada: [true,],
      idTipoFechaTiempoMantenimiento_DAT: [1,],
      nombreRuta: ['',],
      esDirectorio: [false,]
    });
    this.turnosSeleccionados = this.listaTurnos[0];

    var r1, r2, r3, r4 = false;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
      r1 = true;
      if (r1 && r2 && r3 && r4) {
        this.cargarDatos();
      }
    });

    this.comboService.Get_TiposPerdidas_DAT().pipe(first()).subscribe((result: any) => {
      var an: any = result.data;
      an.forEach(f => {
        f.nombre = this.translateService.instant(f.nombre)
      });
      this.perdidas = result.data;
      this.perdida_selectedItem = this.perdidas[0];
      this.idPerdida = this.perdidas[0].id;
      r2 = true;
      if (r1 && r2 && r3 && r4) {
        this.cargarDatos();
      }
    });

    this.mantenimientosPredefinidosService.GetAll_TiposMantenimientos_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translateService.instant(f.nombre);

        });

        this.tipos = result.data;
        this.tipo_selectedItem = result.data[0].id;
        this.idTipo = result.data[0].id;
        r3 = true;
        if (r1 && r2 && r3 && r4) {
          this.cargarDatos();
        }
      }
    );
    this.mantenimientosPredefinidosService.GetAll_TiposFechaTiempoMantenimientos_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translateService.instant(f.nombre);

        });

        this.tiposFechaTiempoMantenimientos_DAT = result.data;

        this.selectedFechaTiempoMantenimiento = result.data[0].id;
        this.idTipoFechaTiempoMantenimiento_DAT = result.data[0].id;
        r4 = true;
        if (r1 && r2 && r3 && r4) {
          this.cargarDatos();
        }
      });
  }

  cargarDatos() {
    if (this.id > 0) {
      this.maquinasService.Get_Mantenimiento(this.id).pipe().subscribe((result) => {

        console.log(result);
        //si la frecuencia es anual, traducir dias en mes y dia
        if (result.data[0].idTipo == 4) {
          this.calcularMesDia(result.data[0].dia);
          this.mes = this.listaMeses[this.mes - 1];
        } else {
          this.dias = this.listaDias[result.data[0].dia - 1];
        }

        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre, Validators.required],
          idPerdida: [result.data[0].idTipoPerdida,],
          idTipo: [result.data[0].idTipo,],
          idTipoFechaTiempoMantenimiento_DAT: [result.data[0].idTipoFechaTiempo],
          frecuencia: [result.data[0].frecuenciaMantenimiento,],
          dia: [result.data[0].dia,],
          hora: [new Date(1950, 0, 1, result.data[0].hora.substring(0, 2), result.data[0].hora.substring(3, 5)),],
          tiempoEstimado: [this.myFunctions.secondsTo_HH_MM_SS(result.data[0].tiempoEstimado), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
          link: [result.data[0].link,],
          activarLink: [result.data[0].isLink == true ? true : false,],
          archivo: [,],
          pdf: [result.data[0].pdf,],
          tareaMantenimiento: [result.data[0].textoMantenimiento,],
          tiempo: [result.data[0].tiempo / 3600,],
          contarDesdeUltimo: [result.data[0].contarDesdeUltimo,],
          contarEjecucion: [result.data[0].contarEjecucion,],
          contarParada: [result.data[0].contarParada,],
          contarPreparacion: [result.data[0].contarPreparacion,],
          contarMantenimiento: [result.data[0].contarMantenimiento,],
          contarAlarma: [result.data[0].contarAlarma,],
          contarApagada: [result.data[0].contarApagada,],
          nombreRuta: [result.data[0].nombreRuta],
          esDirectorio: [result.data[0].esDir]
        })
        this.perdida_selectedItem = new Perdida(result.data[0].idTipoPerdida, "");
        this.idPerdida = result.data[0].idTipoPerdida;
        this.tipo_selectedItem = new Perdida(result.data[0].idTipo, "");
        this.idTipo = result.data[0].idTipo;
        this.idTipoFechaTiempoMantenimiento_DAT = result.data[0].idTipoFechaTiempo;
        this.fichero = result.data[0].pdf;
        this.isLink = result.data[0].isLink == true ? true : false;
        
        this.idTipoAcceso = 0;
        if (result.data[0].isLink) this.idTipoAcceso = 1;
        else if (result.data[0].nombreRuta != '') this.idTipoAcceso = 2;

        if (this.idTipo == 5) {
          var ids = result.data[0].turnos;
          this.turnosSeleccionados = this.listaTurnos.filter(f => ids == f.id)[0];
        }
      });
    }
  }
  //#endregion
  // getTipoAcceso(): number {
  //   if (this.form.value.nombreRuta !== '') return 2
  //   if (this.form.value.esLink) return 1
  //   return 0
  // }
  public onClick(tipoAcceso: number): void {
    this.idTipoAcceso = tipoAcceso;
  }

  public onValueChange(value: any): void {
    this.isLink = value;
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    debugger
    this.submitted = true;
    this.form.value.activarLink = this.idTipoAcceso == 1;

    if (this.form.value.nombreRuta.length <= 0 && this.idTipoAcceso == 2) {
      this.rutaInvalida = true;
      return; 
    }else{
      this.rutaInvalida = false;
    }

    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      // this.newInsert();
      this.insert();
    } else {
      // this.newUpdate()
      this.update();
    }
  }
  private newInsert() {
    if(this.idTipoAcceso == 0 || this.idTipoAcceso == 1){ this.form.value.activarLink = true; } //Al subir a FTP, Azure o Google Cloud devuelve un link
    else this.form.value.activarLink = false;
    if(this.idTipoAcceso == 0){
      this.configuracionService.get_configuracion_gestion_documental().subscribe((res)=>{
        if(res != null){
          switch(res[0].idTipo){
          //FTP
            case 2:{
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileFTP(file[0],carpeta).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en pdf, porque sino no se guarda como link
                  //this.form.controls['pdf'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.insert();
                }
                console.log("Erantzuna FTP");
                console.log(r);
              })
              break;
            }
          //Azure externo
            case 3:{
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileAzureExterno(file[0],carpeta).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en pdf, porque sino no se guarda como link
                  //this.form.controls['pdf'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.insert();
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          //Google cloud
            case 5:{
              debugger
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              //var fichero = this.ficheroOriginal;
              debugger
              this.subidaFicherosService.uploadFileAGoogleCloud(carpeta,file[0]).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en pdf, porque sino no se guarda como link
                  //this.form.controls['pdf'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.insert();
                }
                console.log("Erantzuna GOOGLE CLOUD");
                console.log(r);
              })
              break;
            }
          //Azure interno
            case 6:{
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              debugger
              this.subidaFicherosService.uploadFile(file[0].name,file[0],carpeta).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en pdf, porque sino no se guarda como link
                  //this.form.controls['pdf'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.insert();
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          
            
          }
        } 
      })
    }
    
  }
  private newUpdate(){
    if(this.idTipoAcceso == 0 || this.idTipoAcceso == 1){ this.form.value.activarLink = true; } //Al subir a FTP, Azure o Google Cloud devuelve un link
    else this.form.value.activarLink = false;
    if(this.idTipoAcceso == 0){
      this.configuracionService.get_configuracion_gestion_documental().subscribe((res)=>{
        if(res != null){
          switch(res[0].idTipo){
          //FTP
            case 2:{
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileFTP(file[0],carpeta).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  this.form.controls['pdf'].setValue(msg.message);
                  this.update();
                }
                console.log("Erantzuna FTP");
                console.log(r);
              })
              break;
            }
          //Azure externo
            case 3:{
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileAzureExterno(file[0],carpeta).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //this.form.controls['archivoBase64'].setValue(msg.message);
                  this.form.controls['pdf'].setValue(msg.message);
                  debugger
                  this.update();
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          //Google cloud
            case 5:{
              debugger
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              //var fichero = this.ficheroOriginal;
              debugger
              this.subidaFicherosService.uploadFileAGoogleCloud(carpeta,file[0]).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //this.form.controls['archivoBase64'].setValue(msg.message);
                  this.form.controls['pdf'].setValue(msg.message);
                  this.update();
                }
                console.log("Erantzuna GOOGLE CLOUD");
                console.log(r);
              })
              break;
            }
          //Azure interno
            case 6:{
              var carpeta = 'mantenimientos';//this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              debugger
              this.subidaFicherosService.uploadFile(file[0].name,file[0],carpeta).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //this.form.controls['archivoBase64'].setValue(msg.message);
                  this.form.controls['pdf'].setValue(msg.message);
                  this.update();
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          
            
          }
        } 
      })
    }
  }
  private async insert() {
    //Azure
    // this.form.value.activarLink = this.idTipoAcceso == 1;
    // this.form.value.activarLink = this.idTipoAcceso == 0; //Al subir a FTP, Azure o Google Cloud devuelve un link

    var sTiempo = this.form.value.tiempo * 3600;
    var tiempoEstimadoSplit = this.form.value.tiempoEstimado.split(":");
    var tiempoEstimado = Number(tiempoEstimadoSplit[0]) * 3600 + Number(tiempoEstimadoSplit[1]) * 60 + Number(tiempoEstimadoSplit[2]);
    var archivoBase64: any = "";
    if ((this.form.value.archivo != null) && ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != ""))) {
      archivoBase64 = await this.toBase64(this.form.value.archivo[0]);
      this.fichero = this.form.value.archivo[0].name;
    } else {
      this.fichero = "";
    }

    if (typeof this.mes === 'string') {
      this.selectMesChange(this.mes);
    }
    if (typeof this.dias === 'string') {
      this.selectDiaChange(this.dias);
    }
    
    if (this.mes > 1) {
      this.form.value.dia = this.getDaysInMonth(2022, this.mes) + this.dias;
    } else {
      this.form.value.dia = this.dias;
    }

    if (this.idTipo == 5) {
      this.form.value.turnos = this.turnosSeleccionados.id;
    } else {
      this.form.value.turnos = -1;
    }

    this.maquinasService.Insert_Mantenimiento(this.form.value, this.idMaquina, this.idPerdida, this.idTipo,
      this.idTipoFechaTiempoMantenimiento_DAT,
      (this.form.value.archivo != null && this.form.value.archivo != "") ? this.form.value.archivo[0].name : "", archivoBase64, this.form.value.activarLink, sTiempo, tiempoEstimado).subscribe((result) => {
        if (result.error == false) {
          this.atras();
        }
      });

  }
  private async update() {
    this.form.value.id = this.id;
    var sTiempo = this.form.value.tiempo * 3600;
    var tiempoEstimadoSplit = this.form.value.tiempoEstimado.split(":");
    var tiempoEstimado = Number(tiempoEstimadoSplit[0]) * 3600 + Number(tiempoEstimadoSplit[1]) * 60 + Number(tiempoEstimadoSplit[2]);
    var archivoBase64: any = "";
    if ((this.form.value.archivo != null) && ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != ""))) {
      archivoBase64 = await this.toBase64(this.form.value.archivo[0]);
      this.fichero = this.form.value.archivo[0].name;
    } else {
      this.fichero = "";
    }

    if (typeof this.mes === 'string') {
      this.selectMesChange(this.mes);
    }
    if(this.mes != undefined){
      if (this.mes > 1) {
        this.form.value.dia = this.getDaysInMonth(2022, this.mes) + this.dias;
      } else {
        this.form.value.dia = this.dias;
      }
    }
    
    if (this.idTipo == 5) {
      this.form.value.turnos = this.turnosSeleccionados.id;
    } else {
      this.form.value.turnos = -1;
    }

    if (this.idTipoAcceso != 2)
      this.form.value.nombreRuta = '';


    this.maquinasService.Update_Mantenimiento(this.form.value, this.id, this.idPerdida, this.idTipo, this.idTipoFechaTiempoMantenimiento_DAT, (this.form.value.archivo != null && this.form.value.archivo != "") ? this.form.value.archivo[0].name : "", this.borrarDoc, archivoBase64, this.form.value.activarLink, sTiempo, tiempoEstimado, this.idMaquina).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  public atras() {
    this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
      var data: any = result.data;
      if (this.idMaquina > 0) {
        if (data[0].esMaquina == 1) {
          if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
          if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
          if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
          if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
          if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
          if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
          if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
        } else if (data[0].esInstalacion == 1) {
          this.router.navigate(['instalaciones/editar/' + this.idMaquina]); //INSTALACION
        }
      }
    });

  }

  //#region "COMBO"
  public perdidas_selectionChange(value: any): void {
    //this.form.value['idPerdida'].setValue(value.id);
    this.idPerdida = value.id
  }

  public tipos_selectionChange(value: any): void {
    //this.form.controls['idTipo'].setValue(value.id);
    this.idTipo = value.id
  }

  public selectionFechaTiempoMantenimientoChange(value: any): void {
    this.idTipoFechaTiempoMantenimiento_DAT = value.id;
    console.log(value.id);
  }
  //#endregion

  //#region FICHERO
  public selectEventHandler(e: SelectEvent): void {
    this.isUploaded = true;
  }
  public removeEventHandler(e: SelectEvent): void {
    this.isUploaded = false;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //#endregion
  btnBorrarDoc() {
    this.borrarDoc = !this.borrarDoc;
    this.form.value.pdf = "";
    this.form.controls['pdf'].setValue("");
  }

  public selectDiaChange(value: any): void {
    switch (value) {
      case this.translateService.instant('lunes'):
        this.dias = 1;
        break;
      case this.translateService.instant('martes'):
        this.dias = 2;
        break;
      case this.translateService.instant('miercoles'):
        this.dias = 3;
        break;
      case this.translateService.instant('jueves'):
        this.dias = 4;
        break;
      case this.translateService.instant('viernes'):
        this.dias = 5;
        break;
      case this.translateService.instant('sabado'):
        this.dias = 6;
        break;
      case this.translateService.instant('domingo'):
        this.dias = 7;
        break;
      default:
        this.dias = 0;
        break;
    }
    //this.dias = value.id;
    console.log(value + ":" + this.dias);
  }

  public selectMesChange(value: any): void {
    switch (value) {
      case this.translateService.instant('enero'):
        this.mes = 1;
        break;
      case this.translateService.instant('febrero'):
        this.mes = 2;
        break;
      case this.translateService.instant('marzo'):
        this.mes = 3;
        break;
      case this.translateService.instant('abril'):
        this.mes = 4;
        break;
      case this.translateService.instant('mayo'):
        this.mes = 5;
        break;
      case this.translateService.instant('junio'):
        this.mes = 6;
        break;
      case this.translateService.instant('julio'):
        this.mes = 7;
        break;
      case this.translateService.instant('agosto'):
        this.mes = 8;
        break;
      case this.translateService.instant('septiembre'):
        this.mes = 9;
        break;
      case this.translateService.instant('octubre'):
        this.mes = 10;
        break;
      case this.translateService.instant('noviembre'):
        this.mes = 11;
        break;
      case this.translateService.instant('diciembre'):
        this.mes = 12;
        break;
    }
    console.log("selectMesChange" + value + "resultado" + this.mes)
  }

  public selectDia(value: any): void {
    this.dias = value;
  }

  calcularMesDia(dia) {
    if (dia <= 31) {
      this.mes = 1
      this.dias = dia;
    } else if (dia <= 59) {
      this.mes = 2;
      this.dias = dia - 31;
    } else if (dia <= 90) {
      this.mes = 3;
      this.dias = dia - 59;
    } else if (dia <= 120) {
      this.mes = 4;
      this.dias = dia - 90;
    } else if (dia <= 151) {
      this.mes = 5;
      this.dias = dia - 120;
    } else if (dia <= 181) {
      this.mes = 6;
      this.dias = dia - 151;
    } else if (dia <= 212) {
      this.mes = 7;
      this.dias = dia - 181;
    } else if (dia <= 243) {
      this.mes = 8;
      this.dias = dia - 212;
    } else if (dia <= 273) {
      this.mes = 9;
      this.dias = dia - 243;
    } else if (dia <= 304) {
      this.mes = 10;
      this.dias = dia - 273;
    } else if (dia <= 334) {
      this.mes = 11;
      this.dias = dia - 304;
    } else if (dia <= 365) {
      this.mes = 12;
      this.dias = dia - 334;
    }
    console.log(this.mes + ":" + this.dias)
  }

  getDaysInMonth(year, month) {
    var days = 0;
    for (let index = 0; index < month - 1; index++) {
      days = new Date(year, index, 0).getDate() + days;
    }
    console.log(days)
    return days;
  }

}
