import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { InstalacionesService, MenuService, UsuariosService, HmiService, MaquinasService, CentroMecanizadoService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from "@angular/router";
import { MyFunctions } from '@app/_helpers';
import { RowClassArgs } from '@progress/kendo-angular-grid';

import * as d3 from 'd3';
import * as c3 from 'c3';

@Component({ templateUrl: 'hmiInstalaciones.html' })

export class HMIInstalacionesComponent implements OnInit, AfterViewInit {

  interval: any;

  idinstalacion: any;
  instalacion: any;
  instalacionesModel: any;

  pestannaColor: any = "";

  user = this.usuariosService.userValue;

  camposNum = [
    { num: 1, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 2, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 3, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 4, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 5, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 6, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 7, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 8, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 9, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },
    { num: 10, nombre: "", valorConUnidad: "", esEstado: true, id: 0 },];

  // NUEVA ESTRUCTURA START
  // mantenimientos
  mantenimientoPorFecha: any = [];
  mantenimientoPorTiempo: any = [];

  // procesos
  historicoProcesos: any = [];
  alarmas: any = [];
  planificados: any = [];

  
  public math = Math;

    // columna 1
    public col1Agregar: boolean = true;
    public col1Izquierda = false;  
    public col1Derecha = false;  
    public row1Arriba = false;
    public row1Abajo = false;
    public cuadradosIzquierda = false;
    public cuadradosDerecha = false;
    public cuadradosArriba = false;
    public cuadradosAbajo = false; 
    // columna 2
    public col2Agregar = true;  
    public col2Izquierda = false;  
    public col2Derecha = false;  
    public row2Arriba = false;
    public row2Abajo = false;
    public cuadrados2Izquierda = false;
    public cuadrados2Derecha = false;
    public cuadrados2Arriba = false;
    public cuadrados2Abajo = false; 
    // columna 3
    public col3Agregar = true;  
    public col3Izquierda = false;  
    public col3Derecha = false;  
    public row3Arriba = false;
    public row3Abajo = false;
    public cuadrados3Izquierda = false;
    public cuadrados3Derecha = false;
    public cuadrados3Arriba = false;
    public cuadrados3Abajo = false;
  
    public infColumnas: any = {
      1: {
        idInstalacion: this.route.snapshot.params['id'],
        columna: 1,
        estructura: 1,
        m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        colAgregar: false,  
        colIzquierda: false,  
        colDerecha: false,  
        rowArriba: false,
        rowAbajo: false,
        cuadradosIzquierda: false,
        cuadradosDerecha: false,
        cuadradosArriba: false,
        cuadradosAbajo: false
      },
      2: {
        idInstalacion: this.route.snapshot.params['id'],
        columna: 2,
        estructura: 1,
        m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        colAgregar: false,  
        colIzquierda: false,  
        colDerecha: false,  
        rowArriba: false,
        rowAbajo: false,
        cuadradosIzquierda: false,
        cuadradosDerecha: false,
        cuadradosArriba: false,
        cuadradosAbajo: false
      },
      3: {
        idInstalacion: this.route.snapshot.params['id'],
        columna: 3,
        estructura: 1,
        m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1, min: -1, max: -1, value: -1, ensenar: false},
        colAgregar: false,  
        colIzquierda: false,  
        colDerecha: false,  
        rowArriba: false,
        rowAbajo: false,
        cuadradosIzquierda: false,
        cuadradosDerecha: false,
        cuadradosArriba: false,
        cuadradosAbajo: false
      }
    };
    public infColumnasArray: any;
    public id: number;

    public infInstalaciones: any;
    public tieneHistorico_Procesos: boolean = false;
    public tituloTab: string = '';

    // graficos
    public graficoDonutArray: any = [];
    public dataDonutArray: any = [];
    public graficoBarrasArray: any= [];
    public dataBarrasArray: any = [];
    public graficoLineasArray: any = [];
    public dataLineasArray: any = [];
    public graficoPresionArray: any = [];
    public dataPresionArray: any = [];
    public graficoTemperaturaArray: any = [];
    public dataTemperaturaArray: any = [];
    public graficoResumenSemanaArray: any = [];
    public dataResumenSemanaArray: any = [];

    public fechasG: any;
    public valoresG: any;

    public iconosDatos: any;

    // historico procesos

    public maquina: any;

    public ejecuciones: any; 
    public tiempoReal: any;
    public tiempoPorSemana: any;
    public maquinasModel: any;
    public marcasModel: any;
    public piezaPorcentajeMap = new Map();
    public piezaimgMap = new Map();
    public lotePorcentajeMap = new Map();
    public isOFMap = new Map();

    public tiempoActualPieza = new Map();
    public tiempoActualLote = new Map();
    public tiempoApagado = new Map();

    public tiempoEstAux: any;
    public tiempoPredAux:any;
    public piezaEstMap = new Map();
    public piezaPredMap = new Map();
    public loteEstMap = new Map();
    public lotePredMap = new Map();

    public tiempoMedioPieza: number = 0;
    public desviacionPieza: number = 0;
    public desviacionLote: number = 0;
    public tiempoEstimadoLoteAux: any;
    public tiempoEstimadoLote: number = 0;
    public signoDesviacionPieza: string = '';
    public signoDesviacionLote: string = '';
    public tiempoAcumuladoAux: any;
    public tiempoAcumulado: number = 0;
    public listaTiempo: any = [];
    public listaTiempo2: any = [];

    public piezaTiempoMedioMap = new Map();
    public piezaDesviacionMap = new Map();
    public loteDesviacionMap = new Map();
    public tiempoTotalMap = new Map();

    public mouseOverPorcentajeId = undefined;

    // end historico procesos

  // NUEVA ESTRUCTURA END

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private instalacionesService: InstalacionesService,
    private centroMecanizadoService: CentroMecanizadoService,
    private hmiService: HmiService,
    private maquinasService: MaquinasService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions) {

    // this.interval = setInterval(() => {
    //   this.refreshData();
    // }, 10000);

  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('instalacion');
    this.idinstalacion = parseInt(this.route.snapshot.paramMap.get("id"));
    this.instalacion = {
      imagenBase64: "",
      campos: []
    };

    this.cargarMaquinasModel();

    // estructura dinamica
    this.id = this.route.snapshot.params['id'];
    var r1, r2: boolean = false
    // obtener la informacion de la estructura creada
    this.instalacionesService.get_instalaciones_columnas(this.id).subscribe(
      result => {
        var data: any = result;
        this.instalacionesService.get_instalaciones_modulos().subscribe(
          json => {
            var dataModulos: any = json;
            
            data.forEach(element => {
              this.crearEstructura(element.estructura, element.columna);
              // rellenar la informacion de las columnas
              this.infColumnas[element.columna]['estructura'] = element.estructura;
              this.infColumnas[element.columna]["ensenar"] = false;

              var m1 = dataModulos.filter(f => f.id == element.m1);
              var m2 = dataModulos.filter(f => f.id == element.m2);
              var m3 = dataModulos.filter(f => f.id == element.m3);
              var m4 = dataModulos.filter(f => f.id == element.m4);
              if (m1.length > 0) {
                this.infColumnas[element.columna]['m1'] = {nombre: m1[0].nombre, id_tipoGrafico_DAT: m1[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m1[0].id_tipoDato_DAT, idCampo: m1[0].idCampo, min: 10, max: 100, value: 0, ensenar: true};
                this.infColumnas[element.columna]["ensenar"] = true;
              }              
              if (m2.length > 0) {
                this.infColumnas[element.columna]['m2'] = {nombre: m2[0].nombre, id_tipoGrafico_DAT: m2[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m2[0].id_tipoDato_DAT, idCampo: m2[0].idCampo, min: 10, max: 100, value: 0, ensenar: true};
                this.infColumnas[element.columna]["ensenar"] = true;
              }
              if (m3.length > 0) {
                this.infColumnas[element.columna]['m3'] = {nombre: m3[0].nombre, id_tipoGrafico_DAT: m3[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m3[0].id_tipoDato_DAT, idCampo: m3[0].idCampo, min: 10, max: 100, value: 0, ensenar: true};
                this.infColumnas[element.columna]["ensenar"] = true;
              }              
              if (m4.length > 0) {
                this.infColumnas[element.columna]['m4'] = {nombre: m4[0].nombre, id_tipoGrafico_DAT: m4[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m4[0].id_tipoDato_DAT, idCampo: m4[0].idCampo, min: 10, max: 100, value: 0, ensenar: true};
                this.infColumnas[element.columna]["ensenar"] = true;
              } 
              
            });

            this.infColumnasArray = [this.infColumnas[1], this.infColumnas[2], this.infColumnas[3]]

            r1 = true;
            if (r1 && r2) {
              setTimeout(() => {
                this.crearGraficos();
              }, 2000);
            }         
          });
      });

    this.instalacionesService.get_instalaciones_datos(this.idinstalacion).subscribe(
      result => {
        this.infInstalaciones = result;
        r2 = true;
        if (r1 && r2) {
          setTimeout(() => {
            this.crearGraficos();
          }, 2000);
        }
      });

    this.instalacionesService.get_iconos_graficoDatos().subscribe(
      result => {
        this.iconosDatos = result;
      });
  }

  public ngAfterViewInit(): void {
    // this.crearGraficos();
  }

  cargarMaquinasModel() {

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalacionesModel = this.maquinasService.get_instalaciones_model();
        this.cargarInstalacion();
      })
    } else {
      this.instalacionesModel = instalaciones_model;
      this.cargarInstalacion();
    }

  }

  cargarInstalacion() {
    this.cargarDatosMantenimientos();

    //COGEMOS LA INSTALACION
    this.instalacion = this.instalacionesModel.find(x => x.id === this.idinstalacion);
    this.tieneHistorico_Procesos = this.instalacion.tieneHistorico_Procesos;
    if (this.tieneHistorico_Procesos)
      this.tituloTab = this.translateService.instant("procesos");
    else
      this.tituloTab = this.translateService.instant("alarmas");

    //CARGAMOS LOS CAMPOS DE LA INSTALACION
    this.instalacionesService.GetCamposById(this.idinstalacion).pipe().subscribe((result) => {

      var res: any = result.data;

      res.forEach(function (row) {
        row.valorConUnidad = "";
        row.valorConUnidad = "";
      }, this);

      this.instalacion.campos = result.data;

      this.pintarGraficos(this.instalacion.campos.length);

    });

    this.cargarHistoricoProcesos();
    if (this.tieneHistorico_Procesos) {
      this.cargarDatosPanel();
    }

  }

  cargarDatosMantenimientos() {
    this.cargarDatosMantenimientos_porFecha();
    this.cargarDatosMantenimientos_porTiempo();
  }

  cargarDatosMantenimientos_porFecha() {
    this.hmiService.Get_MantenimientosRecientes_porFecha(this.idinstalacion).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorFecha = [];

      var ahora = this.myFunctions.getDateNow();
      var finHoy = this.myFunctions.getDateNow();
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();
      var difHoy;
      var siguiente;

      var myhoraText = "";
      var myFaltaText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      var inf = new Promise((resolve, reject) => {
        datuak.forEach(async function (row, indice, array) {
          rojo = false;
          gris = false;
          CheckBoxChecked = false;
          PDF = false;
          var tipo = "";
          if (row.fechaUltimo != "") {
            if (row.idTipo == 1) {//diario
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (1 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 2) {//semanal
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setDate(siguiente.getDate() + (7 * row.frecuenciaMantenimiento));
            } else if (row.idTipo == 3) {//mensual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (1 * row.frecuenciaMantenimiento));
              var dia2 = row.dia;
              var mes = siguiente.getMonth();
              siguiente = new Date(siguiente.getFullYear(), siguiente.getMonth(), dia2, siguiente.getHours(), siguiente.getMinutes(), siguiente.getSeconds());
              siguiente.setMonth(mes);
            } else if (row.idTipo == 5) { // por turnos
              var that = this;
              await new Promise(function (myResolve, myReject) {
                that.maquinasService.getProximoTurno(that.idmaquina, row.turnos, row.fechaUltimo, row.frecuenciaMantenimiento).subscribe((result) => {
                  if (result.length != 0)
                    siguiente = new Date(result[0].fechaIni);
                  else
                    siguiente = ahora
                  myResolve(result[0].fechaIni);
                });
              });
            } else {//anual
              siguiente = this.myFunctions.sqlToJsDate(row.fechaUltimo);
              siguiente.setMonth(siguiente.getMonth() + (12 * (1 * row.frecuenciaMantenimiento)));
            }
            if (siguiente > ahora) {
              gris = true;
              CheckBoxChecked = true;
              //ViewState("checkMantenimientos") = ViewState("checkMantenimientos") & ":" & row("id") & ":"
            }

            //ANTES DE USAR EL CALCULO DEL PROXIMO MANTENIMIENTO, HAY QUE TRADUCIRLO A LA HORA DEL USUARIO!
            // hacer esto desde la DB es dificil xq se pasa dia y hora... no una fecha como tal.
            //ARATZ SIGUE AQUI
            if (row.idTipo == 1) {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              if ((difHoy / 1000) < 0) {
                rojo = true;
              }
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
            } else {
              myhoraText = siguiente;
              ahora.setHours(0, 0, 0);
              difHoy = siguiente.getTime() - ahora.getTime();
              myFaltaText = this.myFunctions.milisecondsTo_HH_MM(difHoy % (1 * 24 * 60 * 60 * 1000));//myFaltaText = difHoy.ToString("hh\:mm")
              var dias = Math.round(difHoy / 1000 / 60 / 60 / 24);
              if (dias < 0) {
                dias = dias * (-1);
                if (dias == 1) {
                  myFaltaText = dias + " " + "día" + " " + myFaltaText;
                } else {
                  myFaltaText = dias + " " + "días" + " " + myFaltaText;
                }
              }
              if ((difHoy / 1000) < 0) {
                rojo = true;
                //myFaltaText = dif.Add(TimeSpan.FromDays(1)).ToString("d\.hh\:mm\:ss")
                myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif + (1 * 24 * 60 * 60 * 1000));//sumar un dia en milisegundos
                myFaltaText = Math.floor((dif + (1 * 24 * 60 * 60 * 1000)) / (1 * 24 * 60 * 60 * 1000)) + " días, " + this.myFunctions.milisecondsTo_HH_MM_SS((dif + (1 * 24 * 60 * 60 * 1000)) % (1 * 24 * 60 * 60 * 1000));
              }
            }

            myTextText = row.nombre;

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }

          }
          else {
            //no se ha hecho nunca
            CheckBoxChecked = false;

            row

            rojo = true;
            myTextText = row.nombre;
            myhoraText = "-";
            myFaltaText = this.myFunctions.milisecondsTo_HH_MM_SS(dif);

            if (!(row.pdf === "")) {
              PDFText = row.pdf;
              //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
              PDF = true;
            }
          }

          if (row.idTipo == 1) {//diario
            tipo = "diario";
          }
          else if (row.idTipo == 2) {//semanal
            tipo = "semanal";
          }
          else if (row.idTipo == 3) {//mensual
            tipo = "mensual";
          }
          else if (row.idTipo == 5) { // por turnos
            tipo = "porTurnos";
          } else {//anual
            tipo = "anual";
          }

          var mantenimiento = {
            id: row.id,
            texto: myTextText,
            fecha: this.myFunctions.dateToString(myhoraText),
            checked: CheckBoxChecked,
            enlacePdf: PDFText,
            tienePdf: PDF,
            esRojo: rojo,
            tipo: tipo
          };

          /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
          if (!mantenimiento.checked)
            mantenimientosTemp.push(mantenimiento);

          if (indice == array.length - 1) resolve(true);

        }, this);
      })

      inf.then(() => {
        var array = []
        var an: any = mantenimientosTemp;
        an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
        array.sort(function (a, b) { return b[1] - a[1] });
        var keys = [];
        Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
        keys.forEach(key => {
          var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
          this.mantenimientoPorFecha.push(row);
        });
      })
    });
  }

  cargarDatosMantenimientos_porTiempo() {
    this.hmiService.Get_MantenimientosRecientes_porTiempo(this.idinstalacion).subscribe((result) => {
      var datuak: any = result;

      this.mantenimientoPorTiempo = [];

      var ahora = this.myFunctions.getDateNow()
      var finHoy = this.myFunctions.getDateNow()
      finHoy.setHours(23, 59, 59);
      var dif = finHoy.getTime() - ahora.getTime();

      var myhoraText = "";
      var myTextText = "";

      var PDFText = "";
      var PDFURL = "";

      var PDF;
      var rojo;
      var gris;
      var CheckBoxChecked;

      var mantenimientosTemp = [];

      datuak.forEach(function (row, indice, array) {
        rojo = false;
        gris = false;
        CheckBoxChecked = false;
        PDF = false;

        //no se ha hecho nunca
        if (row.tiempoRestante < 0) {
          CheckBoxChecked = false;
          rojo = true;
        }
        else {
          CheckBoxChecked = true;
          rojo = false;
        }

        myTextText = row.nombre;
        myhoraText = "-";

        if (!(row.pdf === "")) {
          PDFText = row.pdf;
          //PDFURL = "~/idcontent/" & current.conexID & "/mantenimientos/" & row("PDF")
          PDF = true;
        }

        myhoraText = this.myFunctions.secondsTo_HH_MM_SS(row.tiempoRestante)

        var mantenimiento = {
          id: row.id,
          texto: myTextText,
          fecha: myhoraText,
          checked: CheckBoxChecked,
          enlacePdf: PDFText,
          tienePdf: PDF,
          esRojo: rojo
        };
        /* NO SE PUEDEN DESHACER MANTENIMIENTOS NI DESDE HMI NI DESDE LA APP, TENDRA QUE HACERSE DESDE HISTORICO */
        if (!mantenimiento.checked)
          mantenimientosTemp.push(mantenimiento);

      }, this);

      var array = []
      var an: any = mantenimientosTemp;
      an.forEach(a => array[a['checked'] + this.myFunctions.dateToYYYYMMDDHHmmSS(new Date(a['fecha'])) + a['id']] = a); // Criterio de orden: false < true
      array.sort(function (a, b) { return b[1] - a[1] });
      var keys = [];
      Object.keys(array).sort().forEach(key => keys.push(key)); // Se ordena segun nuestro criterio de orden
      keys.forEach(key => {
        var row = array[key]; // Cogemos las rows desde el array que hemos creado. Y estos nos vendran en orden.
        this.mantenimientoPorTiempo.push(row);
      });
    });
  }

  // HISTORICO PROCESOS
  cargarHistoricoProcesos() {
    this.hmiService.Get_ProcesosHistorial(this.idinstalacion).subscribe((result) => {
      var datuak: any = result;
      this.historicoProcesos = [];
      datuak.forEach(function (valor, indice, array) {
        var tipoProceso = "";
        if (valor.idProcesos_Tipo == 1) tipoProceso = this.translateService.instant("ejecucion");
        if (valor.idProcesos_Tipo == 2) tipoProceso = this.translateService.instant("parada");
        if (valor.idProcesos_Tipo == 3) tipoProceso = this.translateService.instant("preparacion");
        if (valor.idProcesos_Tipo == 4) tipoProceso = this.translateService.instant("mantenimiento");
        if (valor.idProcesos_Tipo == 6) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 7) tipoProceso = this.translateService.instant("alarma");
        if (valor.idProcesos_Tipo == 8) tipoProceso = this.translateService.instant("apagado");
        this.historicoProcesos.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            tipo: tipoProceso,
            tipoAlarNombre: valor.descripcion,
            nombre: valor.nombrePrograma,
            OF: valor.of,
            tiemporeal: this.myFunctions.secondsTo_HH_MM_SS(valor.tiemporeal),
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoPredictivo),
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_" + valor.idProcesos_Tipo,
          }
        );
      }, this);
    });

    this.hmiService.Get_ProcesosAlarmas(this.idinstalacion).subscribe((result) => {
      var datuak: any = result;
      this.alarmas = [];
      datuak.forEach(function (valor, indice, array) {
        var divnombre;
        if (valor.idAlarma_tipo == 1) divnombre = "css_linea_lateral_2";
        else divnombre = "css_linea_lateral_6";
        this.alarmas.push(
          {
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            numeroAlarma: valor.numeroAlarma,
            descripcion: valor.descripcion,
            divcss: divnombre,
          }
        );
      }, this);
    });

    this.hmiService.Get_Planificados(this.idinstalacion).subscribe((result) => {
      var datuak: any = result;
      this.planificados = [];
      datuak.forEach(function (valor, indice, array) {
        this.planificados.push(
          {
            idProcesos_Tipo: valor.idProcesos_Tipo,
            diaini: valor.diaini,
            horaini: valor.horaini,
            diafin: valor.diafin,
            horafin: valor.horafin,
            operacion: valor.operacion,
            OF: valor.of,
            tiempoteorico: this.myFunctions.secondsTo_HH_MM_SS(valor.tiempoEstimado),
            programa: 123456,
            cliente: valor.cliente,
            parte: valor.parte,
            pieza: valor.pieza,
            divcss: "css_linea_lateral_8",
          }
        );
      }, this);
    });
  }

  cargarDatosPanel() {
    this.centroMecanizadoService.getInstalacionById(this.idinstalacion).subscribe((result) => {

      this.maquina = this.instalacion;

      this.ejecuciones = result.ejecuciones;
      // this.tiempoReal = result.tiempoReal;
      this.tiempoPorSemana = result.tiempoPorSemana;

      this.maquina.ejecuciones = [];
      if (this.ejecuciones.length > 0) {
        if (this.ejecuciones[0].refOF == undefined || this.ejecuciones[0].refOF == "") {
          this.ejecuciones[0].refOF = this.translateService.instant("noproceso");
          this.ejecuciones[0].realizadas = "";
        } else {
          this.ejecuciones[0].realizadas = this.ejecuciones[0].hechas + "/" + this.ejecuciones[0].cantidad;
        }
        this.maquina.ejecuciones = this.ejecuciones;
      } else {
        this.maquina.ejecuciones.push({ procesos_Tipo: "apagada", refof: this.translateService.instant("noproceso"), realizadas: '', eje: 0, pre: 0, mant: 0, alarma: 0, apagado: 0, total: 0 });
      }

      this.CargarCssEstadoMaquina(this.maquina);
      this.CargarIconosEstadoMaquina(this.maquina);

      if (this.ejecuciones[0] != null) {
        this.maquina.operario = this.ejecuciones[0].operario;
        if (this.maquina.operario == " ") this.maquina.operario = this.translateService.instant('sinOperario');
      }

      var maquinaActaulModel = this.instalacionesModel.find(x => x.id === this.maquina.id) // this.maquinasModel.find(x => x.id === this.maquina.id);
      // var marcaActaulModel = this.marcasModel.find(x => x.id === this.maquina.idMarca);
      this.maquina.logo = maquinaActaulModel.imagenBase64;
      // this.maquina.marca = marcaActaulModel.imagenBase64;

      // this.maquina.tiempoReal = this.tiempoReal;

      this.calcularCanales(this.maquina, 0);
      // this.CargarIconosEjes(this.maquina);

      //RESUMEN SEMANA
      if (this.tiempoPorSemana.length == 0) {
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
        this.tiempoPorSemana.push({ porcentaje: 0 });
      }
      this.maquina.tiempoPorSemana = this.tiempoPorSemana;

      this.cargarProgramas();
      
      this.calcularDesviacionPieza(this.maquina);
      this.calcularDesviacionLote(this.maquina);
    });
  }

  CargarCssEstadoMaquina(maquina) {
    maquina.css = "pestañaprocesos color" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();
    maquina.ejecss = "estado-" + maquina.ejecuciones[0].procesos_Tipo.toString().toLowerCase();
  }

  CargarIconosEstadoMaquina(maquina) {

    var tipoProceso = maquina.ejecuciones[0].procesos_Tipo; //"apagada", "parada", "ejecucion", "preparacion" o "mantenimiento" o "alarma"

    if (maquina.ejecuciones[0].tiempoEstimadoSegundos == 0) {
      maquina.estadoimg = "assets/dcg/dcg12-" + tipoProceso + ".png";
      this.piezaimgMap.set(maquina.id, "assets/dcg/dcg12-" + tipoProceso + ".png");
      maquina.porcentaje = "";
    } else {
      var ahora = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));//this.myFunctions.getDateNow();
      var fechaOrig = new Date(Date.parse(maquina.ejecuciones[0].fechainicio));
      var tiempoAcumuladoOperacion = maquina.ejecuciones[0].tiempoAcumuladoOperacion;

      var dif = ahora.getTime() - fechaOrig.getTime();

      var Seconds_from_T1_to_T2 = dif / 1000;
      Seconds_from_T1_to_T2 = Seconds_from_T1_to_T2 + tiempoAcumuladoOperacion;
      var segundosfiff = Math.floor(Seconds_from_T1_to_T2);

      if (maquina.id <= this.tiempoActualPieza.size && maquina.ejecuciones[0].procesos_Tipo != 'apagada') {
        if (this.tiempoActualPieza.get(maquina.id) != undefined) {
          if (!this.tiempoActualPieza.get(maquina.id).includes('-')) {
            if (this.tiempoActualPieza.get(maquina.id).includes('d')) {
              var tiempoPasadoPieza = this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza.get(maquina.id)) * 60;
              var piezaDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
              var piezaPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
            } else if (this.tiempoActualPieza.get(maquina.id).includes(':')) {
              var tiempoPasadoPieza = this.tiempoFormatoHorasAMinutos(this.tiempoActualPieza.get(maquina.id)) * 60;
              var piezaDocentaje = this.calcularPorcentaje(tiempoPasadoPieza, 12, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
              var piezaPorcentaje = this.calcularPorcentaje(tiempoPasadoPieza, 100, Math.floor(parseInt(maquina.ejecuciones[0].tiempoEstimadoSegundos.toString()) / parseInt(maquina.ejecuciones[0].cantidad.toString())), 1, true);
            }
            this.piezaimgMap.set(maquina.id, "assets/dcg/dcg" + piezaDocentaje + "-" + tipoProceso + ".png")
            this.piezaPorcentajeMap.set(maquina.id, piezaPorcentaje + "%");
            this.lotePorcentajeMap.set(maquina.id, maquina.porcentaje);
          }
        }

      }


      var docentaje = this.calcularPorcentaje(segundosfiff, 12, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);
      var porcentaje = this.calcularPorcentaje(segundosfiff, 100, maquina.ejecuciones[0].tiempoEstimadoSegundos, 1, true);

      maquina.porcentaje = porcentaje + "%";
      maquina.estadoimg = "assets/dcg/dcg" + docentaje + "-" + tipoProceso + ".png";



    }

  }

  labelFeed(d: number) {

    var s = "";
    var spl = d.toString().split(",");
    if (d.toString().indexOf(".") > 0)
      spl = d.toString().split(".");

    var decimales = 0;
    if (d >= 1000)
      decimales = 0;
    else if (d >= 100)
      decimales = 1;
    else
      decimales = 2;

    if (spl.length > 1) {
      s = spl[0]
      if (decimales > 0) {
        if (spl[1].length >= 2)
          s = s + "," + spl[1].substring(0, decimales);
        else if (spl[1].length == 1 && decimales == 2)
          s = s + "," + spl[1].substring(0, 1) + "0";
        else if (spl[1].length == 1 && decimales == 1)
          s = s + "," + spl[1].substring(0, 1)
      }
    } else {
      s = spl[0];
      switch (decimales) {
        case 1: s = s + ",0"; break;
        case 2: s = s + ",00"; break;
      }
    }
    return s;
  }

  calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean) {
    var porcentaje = 0.0
    if (de != 0) {
      var d = 10 ^ decimales;
      var porcen = 0;
      porcen = valor * sobre * d / de;
      porcen = Math.round(porcen);
      if (porcen > sobre * d && limitado)
        porcen = sobre * d;
      porcentaje = Math.round(porcen / d);
    }
    else {
      if (valor > 0 && limitado)
        porcentaje = sobre;
      else if (!limitado) {
        porcentaje = -1;
      }
    }
    if (porcentaje < 0)
      porcentaje = 0;
    return porcentaje;
  }

  calcularCanales(maquina: any, i) {
    var horas: any = [];
    if (this.router.url == '/procesosInstalacion/' + this.idinstalacion && i < 5)
      setTimeout((d) => this.calcularCanales(maquina, i+1), 1000);

    maquina.eje1 = true;
    maquina.eje2 = false;
    maquina.eje3 = false;
    maquina.eje4 = false;

    if (maquina.ejecuciones != undefined && maquina.ejecuciones.length > 0) {
      maquina.enEjecucion = false;
    }

    maquina.procesos_Tipostr = this.translateService.instant(maquina.ejecuciones[0].procesos_Tipo);
    maquina.programa = maquina.ejecuciones[0].nombreprograma;

    var ahora = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));//this.myFunctions.getDateNow();
    //Le sumamos un segundo a la hora
    var dateAux = new Date(Date.parse(maquina.ejecuciones[0].fechaServidor));
    dateAux.setSeconds(dateAux.getSeconds() + 1);
    maquina.ejecuciones[0].fechaServidor = dateAux

    var fechaOrig = new Date(Date.parse(maquina.ejecuciones[0].fechainicio));
    var hanpasado = this.HanPasadoDias(fechaOrig, ahora, 0);
    var hapasadoTiempo = this.HanPasadoTiempo(fechaOrig, ahora, 0);
    var hanpasadoConAcumulado = this.HanPasadoDias(fechaOrig, ahora, maquina.ejecuciones[0].tiempoAcumuladoOperacion);
    var hapasadoTiempoConAcumulado = this.HanPasadoTiempo(fechaOrig, ahora, maquina.ejecuciones[0].tiempoAcumuladoOperacion);
    if (maquina.ejecuciones[0].procesos_Tipo != undefined && maquina.ejecuciones[0].procesos_Tipo.toLowerCase().trim() == "ejecucion") {
      maquina.enEjecucion = true;
    }

    maquina.tiempoPred = this.myFunctions.secondsTo_HH_MM_SS(maquina.ejecuciones[0].tiempoPredSegundos);
    maquina.tiempoEst = this.myFunctions.secondsTo_HH_MM_SS(maquina.ejecuciones[0].tiempoEstimadoSegundos);

    // if (hanpasado > 0){
    //   horas = hapasadoTiempo.toString().split(":");
    //   this.tiempoActualPieza.set(maquina.id, hanpasado.toString() + "d " + horas[0] + "h");
    //   maquina.tiempopasado = hanpasado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempo.toString();
    // }
      
    // else{
      maquina.tiempopasado = hapasadoTiempo.toString();
      this.tiempoActualPieza.set(maquina.id, hapasadoTiempo.toString());
    // }

    this.tiempoActualPieza.set(maquina.id, '00:00:00')
    if (maquina.ejecuciones[0].hechas == 0 || maquina.ejecuciones[0].hechas == 1) {
      this.tiempoActualPieza.set(maquina.id, this.tiempoActualLote.get(maquina.id));
    } else if (maquina.ejecuciones[0].hechas > 1) {
      var tiempoPiezaSegundos = Math.floor(maquina.ejecuciones[0].tiempoAcumuladoOperacion / (maquina.ejecuciones[0].hechas - 1))
      var tiempoPiezaFormatoHora = this.segundosAFormatoHora(tiempoPiezaSegundos);
      this.tiempoActualPieza.set(maquina.id, tiempoPiezaFormatoHora)
    }

    if (this.mouseOverPorcentajeId != maquina.id) {
      if (hanpasadoConAcumulado > 0){
        horas = hapasadoTiempoConAcumulado.toString().split(":");
        this.tiempoActualLote.set(maquina.id, hanpasadoConAcumulado.toString() + "d " + horas[0] + "h");
        this.tiempoApagado.set(maquina.id, hanpasadoConAcumulado.toString() + "d " + horas[0] + "h");
        maquina.tiempopasadoConAcumulado = hanpasadoConAcumulado.toString() + " " + this.translateService.instant("numdiashome") + " " + hapasadoTiempoConAcumulado.toString();
      }
        
      else {
        maquina.tiempopasadoConAcumulado = hapasadoTiempoConAcumulado.toString();
        this.tiempoApagado.set(maquina.id, hapasadoTiempoConAcumulado.toString());
        this.tiempoActualLote.set(maquina.id, hapasadoTiempoConAcumulado.toString());
      }
    }
    this.calcularDesviacionPieza(maquina);
    this.calcularDesviacionLote(maquina);
    this.CargarIconosEstadoMaquina(maquina);    
  }


  cargarProgramas() {
    // if (this.ejecuciones[0].textoPrograma != null && this.ejecuciones[0].textoPrograma != "") { //hay programa
    //   this.maquina.programaTitulo = this.ejecuciones[0].nombreprograma + " - " + this.translateService.instant("linea") + ": " + this.tiempoReal[0].lineaPrograma;
    //   this.maquina.programaTexto = "";
    //   var programaEnsenar = this.ejecuciones[0].textoPrograma.split('rn');
    //   var i;
    //   for (i = 0; i < programaEnsenar.length; i++) {
    //     this.maquina.programaTexto = this.maquina.programaTexto + '<span>' + (this.tiempoReal[0].lineaPrograma + i) + ". &nbsp;&nbsp; </span>" + programaEnsenar[i] + "<br/>";
    //   }
    // } else { //no hay programa
      this.maquina.programaTitulo = this.translateService.instant("nohaydatosprograma");
      this.maquina.programaTexto = "";
    // }
  }

  calcularDesviacionPieza(maquina: any) {
    var dias = 0;
    var horas = 0;
    if (maquina.ejecuciones[0].hechas != 0) {
      this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
      this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

      if (this.listaTiempo.length > 2) {
        this.tiempoAcumuladoAux = "";
        this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
        this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
        this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
      } else {
        this.listaTiempo = this.tiempoAcumuladoAux.split(':');
        this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1].toString());
      }

      // Cycle time (Tiempo lote - tiempo pieza actual) / piezas hechas
      var lag = maquina.tiempopasadoConAcumulado
      if (maquina.tiempopasadoConAcumulado.includes('d')) {
        var tiempoAcumuladoMinutos = this.tiempoFormatoDiasAMinutos(maquina.tiempopasadoConAcumulado);
      } else if (maquina.tiempopasadoConAcumulado.includes(':')) {
        var tiempoAcumuladoMinutos = this.tiempoFormatoHorasAMinutos(maquina.tiempopasadoConAcumulado);
      }
      if (this.tiempoActualPieza.get(maquina.id) != undefined) {
        if (this.tiempoActualPieza.get(maquina.id).includes('d')) {
          var tiempoPiezaActualMinutos = this.tiempoFormatoDiasAMinutos(this.tiempoActualPieza.get(maquina.id));
        } else if (this.tiempoActualPieza.get(maquina.id).includes(':')) {
          var tiempoPiezaActualMinutos = this.tiempoFormatoHorasAMinutos(this.tiempoActualPieza.get(maquina.id));
        }
      }
      if (maquina.ejecuciones[0].hechas > 0) {
        this.tiempoMedioPieza = (tiempoAcumuladoMinutos - tiempoPiezaActualMinutos) / maquina.ejecuciones[0].hechas;
      } else {
        this.tiempoMedioPieza = tiempoAcumuladoMinutos - tiempoPiezaActualMinutos;
      }
      // Dar formato a cycle time
      var cycleTimeFormatoHora = this.segundosAFormatoHora(this.tiempoMedioPieza * 60);
      this.piezaTiempoMedioMap.set(maquina.id, cycleTimeFormatoHora);

      // Desviacion pieza
      if (this.piezaEstMap.get(maquina.id) != undefined) {
        if (this.piezaEstMap.get(maquina.id).includes('d')) {
          var tiempoEstimadoPiezaMinutos = this.tiempoFormatoDiasAMinutos(this.piezaEstMap.get(maquina.id));
        } else if (this.piezaEstMap.get(maquina.id).includes(':')) {
          var tiempoEstimadoPiezaMinutos = this.tiempoFormatoHorasAMinutos(this.piezaEstMap.get(maquina.id));
        }
      } else {
        var tiempoEstimadoPiezaMinutos = 0;
      }

      this.desviacionPieza = this.tiempoMedioPieza - tiempoEstimadoPiezaMinutos;
      // Dar formato a desviacion pieza
      if (this.desviacionPieza < 0) {
        if (Math.abs(this.desviacionPieza) > 60) {
          this.piezaDesviacionMap.set(maquina.id, '- ' + this.segundosAFormatoHora(this.desviacionPieza * 60));
        } else {
          this.piezaDesviacionMap.set(maquina.id, '- ' + Math.abs(this.desviacionPieza) + "min");
        }
      } else {
        if (Math.abs(this.desviacionPieza) > 60) {
          this.piezaDesviacionMap.set(maquina.id, "+ " + this.segundosAFormatoHora(this.desviacionPieza * 60));
        } else {
          this.piezaDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionPieza) + "min");
        }
      }
    } else {
      this.tiempoMedioPieza = 0;
      this.piezaTiempoMedioMap.set(maquina.id, this.tiempoMedioPieza);
      this.desviacionPieza = 0;
      this.piezaDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionPieza) + "min");
    }

  }

  calcularDesviacionLote(maquina: any) { //total time
    var dias = 0;
    var horas = 0;
    if (maquina.tiempopasadoConAcumulado != undefined) {
      this.tiempoAcumuladoAux = maquina.tiempopasadoConAcumulado.toString();
    } else {
      this.tiempoAcumuladoAux = "00:00:00";
    }

    this.listaTiempo = this.tiempoAcumuladoAux.split(' ');

    if (this.listaTiempo.length > 2) {
      this.tiempoAcumuladoAux = '';
      this.tiempoAcumuladoAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoAcumuladoAux.split(':');
      this.tiempoAcumulado = parseInt(this.listaTiempo[0]) * 1440 + parseInt(this.listaTiempo2[0]) * 60 + parseInt(this.listaTiempo2[1].toString());
    } else {
      this.listaTiempo = this.tiempoAcumuladoAux.split(':');
      this.tiempoAcumulado = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1].toString());
    }
    if (maquina.tiempoEst != undefined) {
      this.tiempoEstimadoLoteAux = maquina.tiempoEst.toString();
    } else {
      this.tiempoEstimadoLoteAux = "00:00:00";
    }

    this.listaTiempo = this.tiempoEstimadoLoteAux.split(' ');

    if (this.listaTiempo.length > 2) {
      this.tiempoEstimadoLoteAux = '';
      this.tiempoEstimadoLoteAux = this.listaTiempo[2].toString();
      this.listaTiempo2 = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 1440 + parseInt(this.listaTiempo2[0].toString()) * 60 + parseInt(this.listaTiempo2[1].toString());
    } else {
      this.listaTiempo = this.tiempoEstimadoLoteAux.split(':');
      this.tiempoEstimadoLote = parseInt(this.listaTiempo[0].toString()) * 60 + parseInt(this.listaTiempo[1]);
    }

    this.desviacionLote = this.tiempoAcumulado - this.tiempoEstimadoLote;

    if (this.desviacionLote < 0) {
      if (Math.abs(this.desviacionLote) > 60) {
        this.loteDesviacionMap.set(maquina.id, '- ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote) * 60));
      } else {
        this.loteDesviacionMap.set(maquina.id, '- ' + Math.abs(this.desviacionLote) + "min")
      }
    } else {
      if (Math.abs(this.desviacionLote) > 60) {
        this.loteDesviacionMap.set(maquina.id, '+ ' + this.segundosAFormatoHora(Math.abs(this.desviacionLote) * 60));
      } else {
        this.loteDesviacionMap.set(maquina.id, '+ ' + Math.abs(this.desviacionLote) + "min")
      }
    }

  }

  tiempoFormatoDiasAMinutos(tiempo: string) {
    var tiempoAux = tiempo.split(' ');
    // tratar dias
    var dias = tiempoAux[0];
    dias = dias.replace('d', '');
    var diasMinutos = parseInt(dias) * 24 * 60;
    // tratar horas
    if (tiempo.includes(':')) {
      var horasAux = tiempoAux[2].split(':');
      var horas = horasAux[0];
      var horasMinutos = parseInt(horas) * 60;
    } else {
      var horas = tiempoAux[1].replace('h', '');
      var horasMinutos = parseInt(horas) * 60;
    }

    return diasMinutos + horasMinutos;
  }

  tiempoFormatoHorasAMinutos(tiempo: string) {
    if (tiempo == undefined) {
      return 0;
    }
    var tiempoAux = tiempo.split(':');
    // Tratar horas
    var horasMinutos = parseInt(tiempoAux[0]) * 60;
    // Tratar minutos
    var minutos = parseInt(tiempoAux[1]);
    return horasMinutos + minutos;
  }

  segundosAFormatoHora(tiempo: number) {
    // if (tiempo < 86400) {
      var horas = this.pad(Math.floor(tiempo / 3600));
      var minutos = this.pad(Math.floor((tiempo % 3600) / 60));
      var segundos = this.pad(Math.floor(((tiempo % 3600) % 60) / 1));
      var formatoHora = horas + ":" + minutos + ":" + segundos;
    // } else {
    //   var dias = Math.floor((tiempo / 3600) / 24);
    //   var horas = this.pad(Math.floor((tiempo / 3600) % 24));
    //   var formatoHora = dias + "d " + horas + "h"
    // }
    return formatoHora;
  }

  pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  HanPasadoDias(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    var dt1 = new Date(fechaOrig);
    var dt2 = new Date(ahora);
    var difSegundos = (dt2.getTime() - dt1.getTime()) / 1000;
    difSegundos = difSegundos + tiempoAcumuladoOperacion;
    return Math.floor(difSegundos / (60 * 60 * 24));
  }

  HanPasadoTiempo(fechaOrig, ahora, tiempoAcumuladoOperacion) {
    let diffInMilliSeconds = Math.abs(fechaOrig - ahora) / 1000;
    diffInMilliSeconds = diffInMilliSeconds + tiempoAcumuladoOperacion;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = Math.floor(diffInMilliSeconds % 60);
    diffInMilliSeconds -= minutes * 60;

    let difference = '';

    if (hours < 10) difference = "0" + hours.toString();
    else difference = hours.toString();

    difference = difference + ":";
    if (minutes < 10) difference = difference + "0" + minutes.toString();
    else difference = difference + minutes.toString();

    difference = difference + ":";
    if (seconds < 10) difference = difference + "0" + seconds.toString();
    else difference = difference + seconds.toString();

    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
      difference = '--:--:--';
    }

    return difference;
  }

  // END HISTORICO PROCESOS

  pintarGraficos(numCampos) {

    var th = this;

    var thresholdOpts = {
      boxSize: 14,
      boxFill: false,
      strokeWidth: 4,
      strokeColor: 'white',
      fontSize: "8pt"
    };

    var ids = ["graficoCampo1_hmiinst", "graficoCampo2_hmiinst", "graficoCampo3_hmiinst", "graficoCampo4_hmiinst", "graficoCampo5_hmiinst",
      "graficoCampo6_hmiinst", "graficoCampo7_hmiinst", "graficoCampo8_hmiinst", "graficoCampo9_hmiinst", "graficoCampo10_hmiinst", ];

    ids.forEach(function (id, i) {

      if (i < numCampos) {

        if (i == 0) var rango = { min: th.instalacion.campos[0].minimo, max: th.instalacion.campos[0].maximo };
        if (i == 1) var rango = { min: th.instalacion.campos[1].minimo, max: th.instalacion.campos[1].maximo };
        if (i == 2) var rango = { min: th.instalacion.campos[2].minimo, max: th.instalacion.campos[2].maximo };
        if (i == 3) var rango = { min: th.instalacion.campos[3].minimo, max: th.instalacion.campos[3].maximo };
        if (i == 4) var rango = { min: th.instalacion.campos[4].minimo, max: th.instalacion.campos[4].maximo };
        if (i == 5) var rango = { min: th.instalacion.campos[5].minimo, max: th.instalacion.campos[5].maximo };
        if (i == 6) var rango = { min: th.instalacion.campos[6].minimo, max: th.instalacion.campos[6].maximo };
        if (i == 7) var rango = { min: th.instalacion.campos[7].minimo, max: th.instalacion.campos[7].maximo };
        if (i == 8) var rango = { min: th.instalacion.campos[8].minimo, max: th.instalacion.campos[8].maximo };
        if (i == 9) var rango = { min: th.instalacion.campos[9].minimo, max: th.instalacion.campos[9].maximo };

        var opts = {
          bindto: "#" + ids[i] + "_gauge",
          data: {
            columns: [
              ['data', rango.min]
            ],
            type: 'gauge',
          },
          gauge: {
            label: {
              format: function (value, ratio) {
                return "";
              }
            },
            min: rango.min,
            max: rango.max
          },
          legend: {
            show: false
          },
          tooltip: {
            show: false
          },
          color: {
            pattern: ['#02BABD']
          },
          onrendered: function () {
            th.pintarLineasRangos(this, thresholdOpts, opts);
            th.pintarTextosRangos(this, thresholdOpts, opts);
          },
          onresized: function () {
            th.pintarLineasRangos(this, thresholdOpts, opts);
            th.pintarTextosRangos(this, thresholdOpts, opts);
          },
          min: rango.min,
          max: rango.max
        };

        var chartValor = c3.generate(opts);

        var chartHistorico = c3.generate({
          bindto: "#" + ids[i] + "_hist",
          data: {
            x: 'x',
            columns: [
              ['x'],
              ['data']
            ],
            types: {
              data: 'area',
            }
          },
          axis: {
            x: {
              type: 'timeseries',
              tick: {
                values: function (x) {
                  return d3.scaleTime().domain(x).ticks(2);
                },
                format: '%H:%M:%S'
              },
              padding: 0
            },
            y: {
              tick: {
                values: function (x) {
                  return d3.scaleLinear().domain(x).ticks(5);
                }
              }
            }
          },
          legend: {
            show: false
          },
          tooltip: {
            format: {
              name: function (d) { return th.translateService.instant('valor'); }
            }
          },
          padding: {
            bottom: 3
          },
          transition: {
            duration: 500
          },
          point: {
            show: false
          }
        });

        //GUARDAMOS LOS GRAFICOS EN EL CAMPO PARA LUEGO ACTUALIZARLOS
        th.instalacion.campos[i].graficoValor = chartValor;
        th.instalacion.campos[i].graficoHistorico = chartHistorico;

        th.camposNum[i].esEstado = th.instalacion.campos[i].esEstado;
        th.camposNum[i].nombre = th.instalacion.campos[i].nombre;
        th.camposNum[i].id = th.instalacion.campos[i].id;

      }

    })

    this.cargarDatos();

  }

  pintarLineasRangos(chart, thOpts, chOpts) {

    var divId = chart.config.bindto;

    d3.selectAll(divId + " line.myline").remove();

    var radius = chart.radius
    var iradius = chart.innerRadius;
    var min = chOpts.min;
    var max = chOpts.max;
    var espacioEntreRangos = (max - min) / 5;
    var rangos = [min + espacioEntreRangos, min + (espacioEntreRangos * 2), min + (espacioEntreRangos * 3), min + (espacioEntreRangos * 4)];
    var angles = [(Math.PI / 5) * 1, (Math.PI / 5) * 2, (Math.PI / 5) * 3, (Math.PI / 5) * 4];
    for (var i in rangos) {
      var v = rangos[i];
      //var angle = Math.PI * (v) / 100;
      var angle = angles[i];
      var x0 = (iradius * Math.cos(angle)) * 0.95;
      var y0 = (iradius * Math.sin(angle)) * 0.95;
      var x1 = (radius * Math.cos(angle)) * 1.05;
      var y1 = (radius * Math.sin(angle)) * 1.05;
      d3.select(divId + " .c3-chart-arcs").append("line")
        .attr('x1', -x0)
        .attr('y1', -y0)
        .attr('x2', -x1)
        .attr('y2', -y1)
        .attr('class', 'myline')
        .style("stroke-width", thOpts.strokeWidth)
        .style("stroke", thOpts.strokeColor);

    }

  }

  pintarTextosRangos(chart, thOpts, chOpts) {

    var divId = chart.config.bindto;
    d3.selectAll(divId + " text.mytxt").remove();

    var radius = chart.radius
    var min = chOpts.min;
    var max = chOpts.max;
    var espacioEntreRangos = (max - min) / 5;
    var rangos = [min + espacioEntreRangos, min + (espacioEntreRangos * 2), min + (espacioEntreRangos * 3), min + (espacioEntreRangos * 4)];
    var angles = [(Math.PI / 5) * 1, (Math.PI / 5) * 2, (Math.PI / 5) * 3, (Math.PI / 5) * 4];
    for (var i in rangos) {
      var v = rangos[i];
      //var angle = Math.PI * (v) / 100;
      var angle = angles[i];
      var x1 = ((radius + thOpts.boxSize) * Math.cos(angle)) + thOpts.boxSize / 2;
      var y1 = ((radius + thOpts.boxSize) * Math.sin(angle)) + thOpts.boxSize / 2;
      var text = d3.select(divId + " .c3-chart-arcs").append("text")
        .attr('x', -x1)
        .attr('y', -y1 + 14)
        .attr('font-size', thOpts.fontSize)
        .attr('class', 'mytxt')
        .text(v);
    }

  }

  cargarDatos() {

    var th = this;

    this.instalacionesService.Get_instalaciones_temporal_byId(this.idinstalacion).subscribe((result) => {

      var res: any = result;

      if (res.length > 0) {
        var row = res[0];
        if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 1) this.pestannaColor = "pesta�aprocesos colorejecucion";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 2) this.pestannaColor = "pesta�aprocesos colorparada";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 3) this.pestannaColor = "pesta�aprocesos colorpreparacion";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 4) this.pestannaColor = "pesta�aprocesos colormantenimiento";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 6) this.pestannaColor = "pesta�aprocesos coloralarma";
        else if (row.tieneHistorico_Procesos && row.idProcesos_Tipo == 8) this.pestannaColor = "pesta�aprocesos colorapagada";
        else this.pestannaColor = "pesta�aprocesos colorejecucion";
      }

      this.instalacion.campos.forEach(function (campo, i) {

        if (!campo.esEstado) {

          var datosDeCampo = res.find(x => x.idReferencia === campo.idReferencia);
          if (datosDeCampo == undefined) datosDeCampo = { valor: 0, unidad: "", fechas: "", valoresHistoricos: "" };

          //ACTUALIZAR GRAFICO VALOR
          campo.graficoValor.load({ columns: [['data', datosDeCampo.valor]] });
          campo.valorConUnidad = datosDeCampo.valor + " " + datosDeCampo.unidad;
          th.camposNum[i].valorConUnidad = campo.valorConUnidad;

          //ACTUALIZAR GRAFICO HISTORICO (LE DAMOS LA VUELTA)
          var fechasHistoricos = datosDeCampo.fechas.split(",").reverse().map(function (e) { return th.myFunctions.sqlToJsDate(e); });
          var arrayValoresHistoricos = datosDeCampo.valoresHistoricos.split(",").reverse().map(Number);
          fechasHistoricos.unshift("x");
          arrayValoresHistoricos.unshift("data");
          campo.graficoHistorico.load({
            columns: [fechasHistoricos, arrayValoresHistoricos]
          });

        }        

      }, this);

    });

  }

  refreshData() {
    // if (this.router.url == '/procesosinstalacion/' + this.idinstalacion) {
    //   this.cargarDatos();
    // } else {
    //   clearInterval(this.interval);
    // }

    if (this.router.url == '/procesosinstalacion/' + this.idinstalacion) {
      this.instalacionesService.get_instalaciones_datos(this.idinstalacion).subscribe(
        result => {
          this.infInstalaciones = result;
          this.cargarHistoricoProcesos();
          this.actualizarGraficos();
        });
    } else {
      clearInterval(this.interval);
    }
    

  }

  RedirigirHistoricoDatos(idCampo) {
    this.router.navigate(["historicodatos/" + this.idinstalacion + "/" + idCampo]);
  }

  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.esRojo) {
      case false:
        return "";
      case true:
        return "gridFilaRoja";
      default:
        return "";
    }
  }

  // estructura dimanima
  // funcion para crear la estructura de la columna
  crearEstructura(tipo: number, columna: number) {
    switch (tipo) {
      case 1:
        this.infColumnas[columna].colAgregar = true;    
        break;
      case 2:
        this.infColumnas[columna].colIzquierda = true;  
        this.infColumnas[columna].colDerecha = true;  
        break;
      case 3: 
        this.infColumnas[columna].rowArriba = true;
        this.infColumnas[columna].rowAbajo = true;
        break;
      case 4:
        this.infColumnas[columna].cuadradosAbajo = true;
        this.infColumnas[columna].cuadradosArriba = true;
        break;
      case 5:
        this.infColumnas[columna].cuadradosIzquierda = true;
        this.infColumnas[columna].colDerecha = true;
        break;
      case 6:
        this.infColumnas[columna].cuadradosDerecha = true;
        this.infColumnas[columna].colIzquierda = true;
        break;
      case 7:
        this.infColumnas[columna].rowArriba = true;
        this.infColumnas[columna].cuadradosAbajo = true;
        break;
      case 8:
        this.infColumnas[columna].rowAbajo = true;
        this.infColumnas[columna].cuadradosArriba = true;
        break;
      default:
        break;
    }
  }

  crearGraficos() {
    // columnas
    var j = 0;
    this.infColumnasArray.forEach(element => {
      if (element.m1.nombre != '' && element.m1.id_tipoGrafico_DAT != -1 && element.m1.id_tipoDato_DAT != -1) {
        switch (element.m1.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.dibujarBarras(j, 1);
            break;
          case 2: // Circular
            this.dibujarDonut(j, 1);
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 4: // Lineas
            this.dibujarLineas(j, 1, element.estructura);
            break;
          case 5: // Presion
            this.dibujarPresion(j, 1);
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 8: // Dato
            this.graficoDato(j, 1);
            break;
          case 9: // Resumen semana
            this.dibujarResumenSemana(j, 1);
            break;
          case 10: // Resumen KWh por dia
            this.dibujarBarras(j, 1, 1);
            break;
          case 11: // Resumen KWh por semana
            this.dibujarBarras(j, 1, 2);
            break;
          case 12: // Resumen KWh por mes
            this.dibujarBarras(j, 1, 3);
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 1, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 1, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 1, 3);
            break;
          default:
            break;
        }
      }  

      if (element.m2.nombre != '' && element.m2.id_tipoGrafico_DAT != -1 && element.m2.id_tipoDato_DAT != -1) {
        switch (element.m2.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.dibujarBarras(j, 2);
            break;
          case 2: // Circular
            this.dibujarDonut(j, 2);
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 4: // Lineas
            this.dibujarLineas(j, 2, element.estructura);
            break;
          case 5: // Presion
            this.dibujarPresion(j, 2);
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 8: // Dato
            this.graficoDato(j, 2);
            break;
          case 9: // Resumen semana
            this.dibujarResumenSemana(j, 2);
            break;
          case 10: // Resumen KWh por dia
            this.dibujarBarras(j, 2, 1);
            break;
          case 11: // Resumen KWh por semana
            this.dibujarBarras(j, 2, 2);
            break;
          case 12: // Resumen KWh por mes
            this.dibujarBarras(j, 2, 3);
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 2, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 2, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 2, 3);
            break;
          default:
            break;
        }
      }  

      if (element.m3.nombre != '' && element.m3.id_tipoGrafico_DAT != -1 && element.m3.id_tipoDato_DAT != -1) {
        switch (element.m3.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.dibujarBarras(j, 3);
            break;
          case 2: // Circular
            this.dibujarDonut(j, 3);
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 3);
            break;
          case 4: // Lineas
            this.dibujarLineas(j, 3, element.estructura);
            break;
          case 5: // Presion
            this.dibujarPresion(j, 3);
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 3);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 3);
            break;
          case 8: // Dato
            this.graficoDato(j, 3);
            break;
          case 9: // Resumen semana
            this.dibujarResumenSemana(j, 3);
            break;
          case 10: // Resumen KWh por dia
            this.dibujarBarras(j, 3, 1);
            break;
          case 11: // Resumen KWh por semana
            this.dibujarBarras(j, 3, 2);
            break;
          case 12: // Resumen KWh por mes
            this.dibujarBarras(j, 3, 3);
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 3, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 3, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 3, 3);
            break;
          default:
            break;
        }
      } 
      
      if (element.m4.nombre != '' && element.m4.id_tipoGrafico_DAT != -1 && element.m4.id_tipoDato_DAT != -1) {
        switch (element.m4.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.dibujarBarras(j, 4);
            break;
          case 2: // Circular
            this.dibujarDonut(j, 4);
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 4);
            break;
          case 4: // Lineas
            this.dibujarLineas(j, 4, element.estructura);
            break;
          case 5: // Presion
            this.dibujarPresion(j, 4);
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 4);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 4);
            break;
          case 8: // Dato
            this.graficoDato(j, 4);
            break;
          case 9: // Resumen semana
            this.dibujarResumenSemana(j, 4);
            break;
          case 10: // Resumen KWh por dia
            this.dibujarBarras(j, 4, 1);
            break;
          case 11: // Resumen KWh por semana
            this.dibujarBarras(j, 4, 2);
            break;
          case 12: // Resumen KWh por mes
            this.dibujarBarras(j, 4, 3);
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 4, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 4, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 4, 3);
            break;
          default:
            break;
        }
      }  

      j++;
    });  
  }

  actualizarGraficos() {
    // columnas
    var j = 0;

    var barrasCantidad = 0;
    var lineasCantidad = 0;
    var presionCantidad = 0;
    var resumenSemanaCantidad = 0;

    this.infColumnasArray.forEach(element => {
      if (element.m1.nombre != '' && element.m1.id_tipoGrafico_DAT != -1 && element.m1.id_tipoDato_DAT != -1) {
        switch (element.m1.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.cargarDatosParaGraficosBarras(j, 'm1');
            this.cargarDatosBarras(barrasCantidad);
            barrasCantidad++;
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 4: // Lineas
            var xValue = this.cargarDatosParaGraficosLineas(j, 'm1');
            this.cargarDatosLineas(xValue, lineasCantidad);
            lineasCantidad++;
            break;
          case 5: // Presion
            this.cargarDatosParaGraficoPresion(j, 'm1');
            this.cargarDatosPresion(presionCantidad);
            presionCantidad++;
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 1);
            break;
          case 8: // Dato
            this.graficoDato(j, 1);
            break;
          case 9: // Resumen semana
            this.cargarDatosParaGraficoResumenSemana(j, 'm1');
            this.cargarDatosResumenSemana(resumenSemanaCantidad);
            resumenSemanaCantidad++;
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 1, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 1, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 1, 3);
            break;
          default:
            break;
        }
      }  

      if (element.m2.nombre != '' && element.m2.id_tipoGrafico_DAT != -1 && element.m2.id_tipoDato_DAT != -1) {
        switch (element.m2.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.cargarDatosParaGraficosBarras(j, 'm2');
            this.cargarDatosBarras(barrasCantidad);
            barrasCantidad++;
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 4: // Lineas
            var xValue = this.cargarDatosParaGraficosLineas(j, 'm2');
            this.cargarDatosLineas(xValue, lineasCantidad);
            lineasCantidad++;
            break;
          case 5: // Presion
            this.cargarDatosParaGraficoPresion(j, 'm2');
            this.cargarDatosPresion(presionCantidad);
            presionCantidad++;
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 2);
            break;
          case 8: // Dato
            this.graficoDato(j, 2);
            break;
          case 9: // Resumen semana
            this.cargarDatosParaGraficoResumenSemana(j, 'm2');
            this.cargarDatosResumenSemana(resumenSemanaCantidad);
            resumenSemanaCantidad++;
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 2, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 2, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 2, 3);
            break;
          default:
            break;
        }
      }  

      if (element.m3.nombre != '' && element.m3.id_tipoGrafico_DAT != -1 && element.m3.id_tipoDato_DAT != -1) {
        switch (element.m3.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.cargarDatosParaGraficosBarras(j, 'm3');
            this.cargarDatosBarras(barrasCantidad);
            barrasCantidad++;
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 3);
            break;
          case 4: // Lineas
            var xValue = this.cargarDatosParaGraficosLineas(j, 'm3');
            this.cargarDatosLineas(xValue, lineasCantidad);
            lineasCantidad++;
            break;
          case 5: // Presion
            this.cargarDatosParaGraficoPresion(j, 'm3');
            this.cargarDatosPresion(presionCantidad);
            presionCantidad++;
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 3);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 3);
            break;
          case 8: // Dato
            this.graficoDato(j, 3);
            break;
          case 9: // Resumen semana
            this.cargarDatosParaGraficoResumenSemana(j, 'm3');
            this.cargarDatosResumenSemana(resumenSemanaCantidad);
            resumenSemanaCantidad++;
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 3, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 3, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 3, 3);
            break;
          default:
            break;
        }
      } 
      
      if (element.m4.nombre != '' && element.m4.id_tipoGrafico_DAT != -1 && element.m4.id_tipoDato_DAT != -1) {
        switch (element.m4.id_tipoGrafico_DAT) {
          case 1: // Barras
            this.cargarDatosParaGraficosBarras(j, 'm4');
            this.cargarDatosBarras(barrasCantidad);
            barrasCantidad++;
            break;
          case 2: // Circular
            break;
          case 3: // Nivel
            this.infTemperaturaPosicionNivel(j, 4);
            break;
          case 4: // Lineas
            var xValue = this.cargarDatosParaGraficosLineas(j, 'm4');
            this.cargarDatosLineas(xValue, lineasCantidad);
            lineasCantidad++;
            break;
          case 5: // Presion
            this.cargarDatosParaGraficoPresion(j, 'm4');
            this.cargarDatosPresion(presionCantidad);
            presionCantidad++;
            break;
          case 6: // Temperatura
            this.infTemperaturaPosicionNivel(j, 4);
            break;
          case 7: // Posicion
            this.infTemperaturaPosicionNivel(j, 4);
            break;
          case 8: // Dato
            this.graficoDato(j, 4);
            break;
          case 9: // Resumen semana
            this.cargarDatosParaGraficoResumenSemana(j, 'm4');
            this.cargarDatosResumenSemana(resumenSemanaCantidad);
            resumenSemanaCantidad++;
            break;
          case 13: // Dato KWh por dia
            this.graficoDatoPorResumen(j, 4, 1);
            break;
          case 14: // Dato KWh por semana
            this.graficoDatoPorResumen(j, 4, 2);
            break;
          case 15: // Dato KWh por mes
            this.graficoDatoPorResumen(j, 4, 3);
            break;
          default:
            break;
        }
      }  

      j++;
    });  
  }

  // BARRAS
  /* 
    * tipoInformacion:
    *   -1 => normal
    *   1 => resumen KWh por dia
    *   2 => resumen KWh por semana
    *   3 => resumen KWh por mes
    */
  dibujarBarras(index, m, tipoInformacion = -1) {
    var that = this;
    
    if (tipoInformacion != -1)
      this.graficoBarrasArray.push(c3.generate({
        bindto: '#graficoBarras_'+index+'_'+m,
        data: {
            x: 'x',
            columns: [['x']],
            type: 'bar'
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              centered: true,
              format: function(d) {
                if (tipoInformacion == 2) {
                  var weekNumber = that.translateService.instant('semana') + ' ' + that.myFunctions.getNumberOfWeek(d) + ' (' + that.myFunctions.dateToYYYY_MM_DD_guion(new Date(d)) + ')';
                  return weekNumber
                } else if (tipoInformacion == 3) {
                  return that.myFunctions.dateTo_Mes_YYYY(d);
                } else if (tipoInformacion == 1) {
                  var mes = d.getMonth() + 1;
                  return d.getFullYear() + "/" + that.myFunctions.addZero(mes) + "/" + d.getDate()
                }
              }
            }
          },
          y: {
            tick: {
              count: 10,
              format: function(d) {
                if (d % 5 == 0) return d;
              }
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
              var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
              return auxiString1;
          }
        }
      }));
    else
      this.graficoBarrasArray.push(c3.generate({
        bindto: '#graficoBarras_'+index+'_'+m,
        data: {
            x: 'x',
            columns: [['x']],
            type: 'bar'
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              centered: true,
              count: 10,
              format: function(d) {
                  return that.myFunctions.addZero(d.getHours()) + ':' + that.myFunctions.addZero(d.getMinutes());
              }
            }
          },
          y: {
            tick: {
              count: 10,
              format: function(d) {
                if (d % 5 == 0) return d;
              }
            }
          }
        },
        legend: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
              var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
              return auxiString1;
          }
        }
      }));

    if (tipoInformacion == -1) {
      var lag = 'm'+m;
      this.cargarDatosParaGraficosBarras(index, lag);
      this.cargarDatosBarras(this.graficoBarrasArray.length-1);
    }
    else if (tipoInformacion == 1) 
      this.cargarDatosParaGraficosBarrasPorDia(index, lag);
    else if (tipoInformacion == 2)
      this.cargarDatosParaGraficosBarrasPorSemana(index, lag);
    else if (tipoInformacion == 3)
      this.cargarDatosParaGraficosBarrasPorMes(index, lag);    
    
  }

  cargarDatosParaGraficosBarras(col, m) {
    var inf = this.infInstalaciones.filter(f => (f.idCampo == this.infColumnasArray[col][m].idCampo))[0]; 

    // procesar los datos (si el valor es -1 entonces hay que eliminarlo)
    var valoresHist = inf?.valoresHistoricos.split(",").reverse();
    var fechasHist = inf?.fechas.split(",").reverse();
    var i = 0;
    var fechasToRemove = [];
    valoresHist.forEach(element => {
      if (element == "-1.000000")
        fechasToRemove.push(fechasHist[i]);
      i++
    });
    valoresHist = valoresHist.filter(f => f != "-1.000000");
    fechasHist = fechasHist.filter(f => !fechasToRemove.includes(f));

    // fechas
    var fechas = fechasHist;
    var fechasGrafico: any = ['x'];
    fechas.forEach(element => {
      fechasGrafico.push(new Date(element));
    });
    
    // valores
    var valoresGrafico = [this.infColumnasArray[col][m].nombre]
    var valores = valoresHist;
    valoresGrafico.push(...valores);
    this.fechasG = fechasGrafico.slice(0,10);
    this.valoresG = valoresGrafico.slice(0,10);
  }

  cargarDatosParaGraficosBarrasPorDia(col, m) {
    var index = this.graficoBarrasArray.length-1
    this.instalacionesService.get_consumo_agrupado(this.idinstalacion, 1).subscribe(
      (result: any) => {
        var valoresHist = [];
        var fechasHist: any = [];
        result.forEach(element => {
          valoresHist.push(element.valor);
          fechasHist.push(new Date(element.dia));
        });
        
        // valores
        var valoresGrafico = ['KWh']
        var valores = valoresHist.reverse();
        valoresGrafico.push(...valores);
        var fechasGrafico = ['x']
        fechasHist = fechasHist.reverse();
        fechasGrafico.push(...fechasHist);
        this.fechasG = fechasGrafico.slice(0,8);
        this.valoresG = valoresGrafico.slice(0,8);
        this.cargarDatosBarras(index);
      });
  }

  cargarDatosParaGraficosBarrasPorSemana(col, m) {
    var index = this.graficoBarrasArray.length-1
    this.instalacionesService.get_consumo_agrupado(this.idinstalacion, 2).subscribe(
      (result: any) => {
        var valoresHist = [];
        var fechasHist: any = [];
        result.forEach(element => {
          var anno = element.anno.split(';')[0];
          valoresHist.push(element.valor);
          fechasHist.push(this.myFunctions.getDatefromWeek(element.semana, anno));
        });
        
        // valores
        var valoresGrafico = ['KWh']
        var valores = valoresHist.reverse();
        valoresGrafico.push(...valores);
        var fechasGrafico = ['x']
        fechasHist = fechasHist.reverse();
        fechasGrafico.push(...fechasHist);
        this.fechasG = fechasGrafico.slice(0,8);
        this.valoresG = valoresGrafico.slice(0,8);
        this.cargarDatosBarras(index);
      });
  }

  cargarDatosParaGraficosBarrasPorMes(col, m) {
    var index = this.graficoBarrasArray.length-1
    this.instalacionesService.get_consumo_agrupado(this.idinstalacion, 3).subscribe(
      (result: any) => {
        var valoresHist = [];
        var fechasHist: any = [];
        result.forEach(element => {
          var anno = element.anno.split(';')[0];
          valoresHist.push(element.valor);
          fechasHist.push(new Date(anno, element.mes - 1, 1));
        });
        
        // valores
        var valoresGrafico = ['KWh']
        var valores = valoresHist.reverse();
        valoresGrafico.push(...valores);
        var fechasGrafico = ['x']
        fechasHist = fechasHist.reverse();
        fechasGrafico.push(...fechasHist);
        this.fechasG = fechasGrafico.slice(0,8);
        this.valoresG = valoresGrafico.slice(0,8);
        this.cargarDatosBarras(index);
      });
  }

  cargarDatosBarras(i) {   
    this.graficoBarrasArray[i].load({
      unload: true,
      x: 'x',
      columns : [this.fechasG, this.valoresG]
    });
  }

  // LINEAS
  dibujarLineas(index, m, estructura) {
    var that = this;
    var isPequenno = this.comprobarTamanno(m, estructura);

    let xCount = 15;
    if (isPequenno) xCount = 5;

    this.graficoLineasArray.push(c3.generate({
      bindto: '#graficoLineas_'+index+'_'+m,
      data: {
          x: 'x',
          columns: [['x']],
          // type: 'bar'
      },
      color: {
        pattern: ['#1FB5B5', '#00F6FF']
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            centered: true,
            count: xCount,
            format: function(d) {
              return that.myFunctions.addZero(d.getHours()) + ':' + that.myFunctions.addZero(d.getMinutes());
            }
          }
        },
        y: {
          tick: {
            count: 10,
            format: function(d) {
              return that.nFormatter(d, 1)
            }
          }
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
            var auxiString1 = "<div class=\"tooltip-graf-home\"><span>" + d[0].value.toFixed(2) + "</span></div>";
            return auxiString1;
        }
      }
    }));

    var lag = 'm'+m;
    var xValue = this.cargarDatosParaGraficosLineas(index, lag);
    this.cargarDatosLineas(xValue, this.graficoLineasArray.length-1);
  }

  // con esta funcion comprobamos el tamanno del modulo para poder adecuar el eje x
  comprobarTamanno(mod, estructura) {
    switch (mod) {
      case 1:   
        if (estructura == 2 || estructura == 4 || estructura == 5 || estructura == 6 || estructura == 8) 
          return true;     
        break;
      case 2:
        if (estructura == 2 || estructura == 4 || estructura == 5 || estructura == 6 || estructura == 8) 
          return true; 
        break;
      case 3:
        if (estructura == 4 || estructura == 5 || estructura == 7) 
          return true; 
        break;
      case 4: 
        if (estructura == 4 || estructura == 6 || estructura == 7) 
          return true; 
        break;
    
      default:
        break;
    }
    return false;
  }

  cargarDatosParaGraficosLineas(col, m) {
    var inf = this.infInstalaciones.filter(f => (f.idCampo == this.infColumnasArray[col][m].idCampo))[0]; 

    // procesar los datos (si el valor es -1 entonces hay que eliminarlo)
    var valoresHist = inf?.valoresHistoricos.split(",").reverse();
    var fechasHist = inf?.fechas.split(",").reverse();
    var i = 0;
    var fechasToRemove = [];
    valoresHist.forEach(element => {
      if (element == "-1.000000")
        fechasToRemove.push(fechasHist[i]);
      i++
    });
    valoresHist = valoresHist.filter(f => f != "-1.000000");
    fechasHist = fechasHist.filter(f => !fechasToRemove.includes(f));

    // fechas
    var fechas = fechasHist;
    var fechasGrafico: any = ['x'];
    fechas.forEach(element => {
      fechasGrafico.push(new Date(element));
    });
    
    // valores
    var valoresGrafico = [this.infColumnasArray[col][m].nombre]

    var xValue = {};
    xValue[this.infColumnasArray[col][m].nombre] = 'x';
    xValue[this.translateService.instant('media')] = 'x'

    var valores = valoresHist;
    valoresGrafico.push(...valores);

    var mCalc = 0;
    valores.forEach(element => {
      mCalc += parseInt(element);
    });
    mCalc = mCalc / valores.length;
    var media: any = [this.translateService.instant('media')];
    for (var j = 1; j<valoresGrafico.length; j++) {
      media.push(mCalc);
    }

    // this.fechasG = fechasGrafico;
    this.valoresG = [fechasGrafico.slice(0,10), valoresGrafico.slice(0,10), media];

    return xValue;
  }

  cargarDatosLineas(xValue, i) {
    this.graficoLineasArray[i].load({
      unload: true,
      xs: xValue,
      columns : [this.valoresG[0], this.valoresG[1]]
    });

    this.graficoLineasArray[i].ygrids.remove();
    this.graficoLineasArray[i].ygrids.add([
      {value: this.valoresG[2][1], class: "graficoLineas-media"}
    ])
  }

  // PRESION
  dibujarPresion(index, m) {

    this.graficoPresionArray.push(c3.generate({
      bindto: '#graficoPresion_'+index+'_'+m,
      data: {
        columns: [['x']],
        type: "donut",
        order: null,
        hide:[],
        colors: {
        },
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      donut: {
        label: {
          show: false
        }
      }
      
    }));

    var lag = 'm'+m;
    this.cargarDatosParaGraficoPresion(index, lag);
    this.cargarDatosPresion(this.graficoPresionArray.length-1);
  }

  
  cargarDatosPresion(i) {
    this.graficoPresionArray[i].load({
      unload: true,
      columns : this.valoresG,
      order: null,
      hide:['hide'],
      colors: {
        hide: '#transparent',
        data: '#22C4C4',
        rest: '#E1E1E1'
      },
    });
  }

  cargarDatosParaGraficoPresion(col, m) {
    var inf = this.infInstalaciones.filter(f => (f.idCampo == this.infColumnasArray[col][m].idCampo))[0]; 
    
    // valores
    var porcen = (inf.valor - inf.minimo) * 100 / (inf.maximo - inf.minimo); 

    // %80 es el 100% (para que quede en forma de presion)
    var porcenLag = porcen * 80 / 100
    var valoresGrafico = [['hide', 20], ['data', porcenLag],  ['rest', 80-porcenLag]];
    this.valoresG = valoresGrafico;

    d3.select('#graficoPresion_'+col+'_'+m[1] +' .c3-chart-arcs-title').attr("transform", "rotate(216)")
    d3.select('#graficoPresion_'+col+'_'+m[1] +' .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(inf.valor?.toFixed(2));
  }

  // TEMPERATURA Y POSICION
  infTemperaturaPosicionNivel(col, m) { 
    var lag = 'm'+m;
    var inf = this.infInstalaciones.filter(f => (f.idCampo == this.infColumnasArray[col][lag].idCampo))[0]; 

    this.infColumnasArray[col][lag].min = inf.minimo;
    this.infColumnasArray[col][lag].max = inf.maximo;
    this.infColumnasArray[col][lag].value = inf.valor?.toFixed(2);
    this.infColumnasArray[col][lag].unidad = inf.unidad;
  }

  // DATOS
  graficoDato(col, m) {
    var lag = 'm'+m;
    var inf_icono = this.iconosDatos.filter(f => (f.id ==this.infColumnasArray[col][lag].id_tipoDato_DAT))[0];
    var inf = this.infInstalaciones.filter(f => (f.idCampo == this.infColumnasArray[col][lag].idCampo))[0]; 

    this.infColumnasArray[col][lag].icono = inf_icono?.icono;
    var intValueMore5 = parseInt(inf?.valor).toString().length >= 5

    // para dividirlo en puntos
    var number = [];
    if (intValueMore5) {
      this.infColumnasArray[col][lag].value = Math.abs(inf?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse()
      this.infColumnasArray[col][lag].value.forEach(element => {
        number.push(element.split("").reverse().join(""));
      });
      this.infColumnasArray[col][lag].value = number.join('.');
    } else {
      var numberLag: any = Math.abs(inf?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse();
      numberLag.forEach(element => {
        number.push(element.split("").reverse().join(""));
      });
      numberLag = number.join('.');
      var comma = "," + inf?.valor?.toFixed(2).toString().split('.')[1];
      this.infColumnasArray[col][lag].value = numberLag + comma;
    }

    if (inf?.valor?.toString().includes('-'))
      this.infColumnasArray[col][lag].value = "-" + this.infColumnasArray[col][lag].value;
    this.infColumnasArray[col][lag].unidad = inf?.unidad;
  }

  graficoDatoPorResumen(col, m, tipoAgrupado) {
    var lag = 'm' + m;
    var inf_icono = this.iconosDatos.filter(f => (f.id == this.infColumnasArray[col][lag].id_tipoDato_DAT))[0];
    var inf = this.infInstalaciones.filter(f => (f.idCampo == this.infColumnasArray[col][lag].idCampo))[0];

    this.instalacionesService.get_consumo_agrupado(inf.id, tipoAgrupado, true).subscribe(
      (result: any) => {
        this.infColumnasArray[col][lag].icono = inf_icono?.icono;
        var intValueMore5 = parseInt(result[0]?.valor).toString().length >= 5

        // para dividirlo en puntos
        var number = [];
        if (intValueMore5) {
          this.infColumnasArray[col][lag].value = Math.abs(result[0]?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse()
          this.infColumnasArray[col][lag].value.forEach(element => {
            number.push(element.split("").reverse().join(""));
          });
          this.infColumnasArray[col][lag].value = number.join('.');
        } else {
          var numberLag: any = Math.abs(result[0]?.valor)?.toFixed(0).toString().split("").reverse().join("").match(/.{1,3}/g).reverse();
          numberLag.forEach(element => {
            number.push(element.split("").reverse().join(""));
          });
          numberLag = number.join('.');
          var comma = "," + result[0]?.valor?.toFixed(2).toString().split('.')[1];
          this.infColumnasArray[col][lag].value = numberLag + comma;
        }

        if (result[0]?.valor?.toString().includes('-'))
          this.infColumnasArray[col][lag].value = "-" + this.infColumnasArray[col][lag].value;

        this.infColumnasArray[col][lag].unidad = inf?.unidad;
    });
  }

  // RESUMEN SEMANA
  dibujarResumenSemana(index, m) {
    var that = this;
    this.graficoResumenSemanaArray.push(c3.generate({
      bindto: '#graficoResumenSemana_'+index+'_'+m,
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
      data: {
        columns: [
          [that.translateService.instant("ejecucion"), 0],
          [that.translateService.instant("parada"), 0],
          [that.translateService.instant("microparada"), 0],
          [that.translateService.instant("preparacion"), 0],
          [that.translateService.instant("mantenimiento"), 0],
          [that.translateService.instant("alarma"), 0],
          [that.translateService.instant("apagada"), 0]
        ],
        type: 'donut',
        order: null,
      },
      transition: {
        duration: 500
      },
      color: {
        pattern: ['#c0eada', '#e7cb68', '#cf8729', '#096844', '#99afc6', '#d33737', '#424242']
      },
      axis: {
        y: {
          show: false
        },
        x: {
          show: false
        }
      },
      bar: {
        width: {
          ratio: 1
        },
        space: 0.1
      },
      tooltip: {
        format: {
          title: function (d) { return 'Resumen semana' },
          value: function (value, ratio, id) {
            return value + '%';
          }
        }
      },
      legend: {
        show: false
      }
    }));

    var lag = 'm'+m;
    this.cargarDatosParaGraficoResumenSemana(index, lag);
    this.cargarDatosResumenSemana(this.graficoResumenSemanaArray.length-1);
  }
  
  cargarDatosParaGraficoResumenSemana(col, m) {
    this.valoresG = [[this.translateService.instant("ejecucion"), this.maquina.tiempoPorSemana[0].porcentaje],
                    [this.translateService.instant("parada"), this.maquina.tiempoPorSemana[1].porcentaje],
                    [this.translateService.instant("microparada"), this.maquina.tiempoPorSemana[2].porcentaje],
                    [this.translateService.instant("preparacion"), this.maquina.tiempoPorSemana[3].porcentaje],
                    [this.translateService.instant("mantenimiento"), this.maquina.tiempoPorSemana[4].porcentaje],
                    [this.translateService.instant("alarma"), this.maquina.tiempoPorSemana[5].porcentaje],
                    [this.translateService.instant("apagada"), this.maquina.tiempoPorSemana[6].porcentaje]];
    console.log("valores", this.valoresG)
  }

  cargarDatosResumenSemana(i) {
    this.graficoResumenSemanaArray[i].load({
      unload: true,
      columns : this.valoresG,
    });
  }

  nFormatter(num, digits) {
    if (num.toString().length > 3) {
      const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
      ];
      const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
      var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
      });
      if (num.toString()[0] == 0)
        return num.toFixed(2);

      return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    } else {
      return num;
    }

  }

  dibujarDonut(index, m) {

  }

  // redirigir a historico datos
  clickCampo(idCampo) {
    this.router.navigate(["historicodatos/" + this.idinstalacion + "/" + idCampo]);
  }

  // end estructura dimamica

}
