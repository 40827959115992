<form [formGroup]="form">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <!--Nombre-->
          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate }}">
              <div class="caja">
                <kendo-textbox formControlName="nombre"></kendo-textbox>
              </div>
            </kendo-label>
          </div>
          <div class="row">
            <div class="col-md-4">
              <!--Perdida-->
              <div class="form-group">
                <kendo-label text="{{ 'asignarPerdidaA' | translate }}">
                  <div class="caja">
                    <kendo-dropdownlist formControlName="idPerdida" [data]="perdidas" [textField]="'nombre'"
                                        [valueField]="'id'" [value]="perdida_selectedItem"
                                        (selectionChange)="perdidas_selectionChange($event)" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <!--TIPOS MANTENIMIENTOS-->
              <div class="form-group">
                <label class="control-label">{{ 'tipo' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist formControlName="idTipoFechaTiempoMantenimiento_DAT"
                                      [data]="tiposFechaTiempoMantenimientos_DAT" [textField]="'nombre'" [valueField]="'id'"
                                      (selectionChange)="selectionFechaTiempoMantenimientoChange($event)"
                                      [value]="selectedFechaTiempoMantenimiento" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <!--Frecuencia-->
              <div class="form-group"
                   *ngIf="idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3">
                <kendo-label text="{{ 'frecuencia' | translate }}">
                  <div class="caja">
                    <!--formControlName="idTipo"-->
                    <kendo-dropdownlist [data]="tipos" [textField]="'nombre'" [valueField]="'id'"
                                        [value]="tipo_selectedItem" (selectionChange)="tipos_selectionChange($event)"
                                        class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </kendo-label>
              </div>
              <!--Dia
              <div class="form-group" *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipo > 1">
                <kendo-label text="{{ 'dia' | translate}}">
                  <div class="caja">
                    <kendo-numerictextbox class="form-control" formControlName="dia" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>-->
              <!--DIAS (SEMANA)-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipo == 2">
                <label class="control-label">{{ 'dias' | translate }}</label>
                <div class="caja">
                  <!-- <kendo-numerictextbox formControlName="dias" [format]="'n'" [decimals]="decimals" [min]="1" [max]="maxDias" [autoCorrect]="autoCorrect">
                  </kendo-numerictextbox> -->

                  <kendo-dropdownlist [data]="listaDias" [textField]="'nombre'" [value]="dias"
                                      (selectionChange)="selectDiaChange($event)" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>

                </div>
              </div>

              <!--DIAS (MES)-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipo == 3">
                <label class="control-label">{{ 'dia' | translate }}</label>
                <div class="caja">
                  <kendo-numerictextbox [value]="dias" [format]="'n'" [decimals]="decimals" [min]="1" [max]="28"
                                        [autoCorrect]="autoCorrect" (valueChange)="selectDia($event)">
                  </kendo-numerictextbox>
                </div>
              </div>

              <!--MES DIAS (ANUAL)-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipo == 4">
                <label class="control-label">{{ 'mes' | translate }}</label>

                <div class="caja">
                  <kendo-dropdownlist [data]="listaMeses" [textField]="'nombre'" [value]="mes"
                                      (selectionChange)="selectMesChange($event)" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>
                </div>

                <label class="control-label">{{ 'dia' | translate }}</label>

                <div class="caja">
                  <kendo-numerictextbox [value]="dias" [format]="'n'" [decimals]="decimals" [min]="1" [max]="31"
                                        [autoCorrect]="autoCorrect" (valueChange)="selectDia($event)">
                  </kendo-numerictextbox>
                </div>


              </div>

              <!--TURNOS -->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipo == 5">
                <label class="control-label">{{ 'turno' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist [data]="listaTurnos" [textField]="'nombre'" [valueField]="'id'" [(value)]="turnosSeleccionados" class="form-control" style="width: 250px">
                  </kendo-dropdownlist>

                  <!-- <kendo-multiselect kendoMultiSelectSummaryTag class="form-control" [(data)]="listaTurnos" [textField]="'nombre'"
                  [valueField]="'id'" [autoClose]="false"
                  [(value)]="turnosSeleccionados" placeholder="{{ 'turnos' | translate }}" style="width: 250px;"
                  [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">
                        {{ dataItems.length }} {{ 'turnos' | translate }}
                      </ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect> -->
                </div>
              </div>


              <!-- TIEMPO ENTRE MANTENIMIENTOS -->
              <div *ngIf="idTipoFechaTiempoMantenimiento_DAT == 2 || idTipoFechaTiempoMantenimiento_DAT ==3">
                <div class="form-group">
                  <label class="control-label">{{ 'tiempoEntreMantenimientos' | translate }}</label>
                  <div class="caja">
                    <!--<kendo-maskedtextbox [mask]="mask" [value]="tiempo" (valueChange)="onMaskValueChange($event)" formControlName="tiempo"></kendo-maskedtextbox>-->
                    <kendo-numerictextbox formControlName="tiempo" [format]="'n'" [decimals]="0" [value]="value"
                                          [min]="0" [max]="99999" [autoCorrect]="true"></kendo-numerictextbox>
                  </div>
                </div>
              </div>

              <!--TIEMPO ESTIMADO-->
              <div class="form-group">
                <label ID="lTinePartes" class="control-label">{{ 'tiempoestimado' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="tiempoEstimado"
                                 [ngClass]="{ 'is-invalid': submitted && f.tiempoEstimado.errors }"></kendo-textbox>
                  <div *ngIf="submitted && f.tiempoEstimado.errors" class="invalid-feedback">
                    <div *ngIf="f.tiempoEstimado.errors.pattern">
                      {{
 'formatoInadecuado' |
                      translate
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- CONTAR DESDE ULTIMO -->
              <div *ngIf="idTipoFechaTiempoMantenimiento_DAT == 2 || idTipoFechaTiempoMantenimiento_DAT==3">
                <div class="form-group form-check">
                  <label class="form-check-label">{{ 'contarDesdeUltimo' | translate}}</label>
                  <div class="caja">
                    <kendo-switch formControlName="contarDesdeUltimo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <!--Frecuencia-->
              <div class="form-group"
                   *ngIf="idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3">
                <kendo-label text="{{ 'frecuencia' | translate}}">
                  <div class="caja">
                    <kendo-numerictextbox class="form-control" formControlName="frecuencia" [min]="1" [max]="9999"
                                          [autoCorrect]="true" [format]="'n'" [ngClass]="{ 'is-invalid': submitted && f.frecuencia.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
              <!--Hora-->
              <div class="form-group"
                   *ngIf="(idTipoFechaTiempoMantenimiento_DAT==1 || idTipoFechaTiempoMantenimiento_DAT==3) && idTipo != 5">
                <kendo-label text="{{ 'hora' | translate}}">
                  <div class="caja">
                    <kendo-timepicker formControlName="hora">
                      <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                                                 acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                                                 cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                                                 nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
                      </kendo-timepicker-messages>
                    </kendo-timepicker>
                  </div>
                </kendo-label>
              </div>

              <!--  TIPOS PROCESOS QUE CONTAR -->
              <div *ngIf="idTipoFechaTiempoMantenimiento_DAT == 2 || idTipoFechaTiempoMantenimiento_DAT==3">
                <!--<label>{{ 'contar' | translate}}</label>-->
                <div class="row">
                  <!-- EJECUCION -->
                  <div class="col-md-4">
                    <div class="form-group form-check">
                      <label class="form-check-label">
                        {{ 'ejecucion' | translate}}
                        <div class="caja">
                          <kendo-switch formControlName="contarEjecucion" [onLabel]="' '"
                                        [offLabel]="' '"></kendo-switch>
                        </div>
                      </label>
                    </div>
                  </div>
                  <!-- PARADA -->
                  <div class="col-md-4">
                    <div class="form-group form-check">
                      <label class="form-check-label">
                        {{ 'parada' | translate}}
                        <div class="caja">
                          <kendo-switch formControlName="contarParada" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- PREPARACION -->
                  <div class="col-md-4">
                    <div class="form-group form-check">
                      <label class="form-check-label">
                        {{ 'preparacion' | translate}}
                        <div class="caja">
                          <kendo-switch formControlName="contarPreparacion" [onLabel]="' '"
                                        [offLabel]="' '"></kendo-switch>
                        </div>
                      </label>
                    </div>
                  </div>
                  <!-- MANTENIMIENTO -->
                  <div class="col-md-4">
                    <div class="form-group form-check">
                      <label class="form-check-label">
                        {{ 'mantenimiento' | translate}}
                        <div class="caja">
                          <kendo-switch formControlName="contarMantenimiento" [onLabel]="' '"
                                        [offLabel]="' '"></kendo-switch>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- ALARMA -->
                  <div class="col-md-4">
                    <div class="form-group form-check">
                      <label class="form-check-label">
                        {{ 'alarma' | translate}}
                        <div class="caja">
                          <kendo-switch formControlName="contarAlarma" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </div>
                      </label>
                    </div>
                  </div>
                  <!-- APAGADA -->
                  <div class="col-md-4">
                    <div class="form-group form-check">
                      <label class="form-check-label">
                        {{ 'apagada' | translate}}
                        <div class="caja">
                          <kendo-switch formControlName="contarApagada" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">{{ 'tipoAcceso' | translate }}</label>
              <kendo-buttongroup selection="single" width="100%">
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(0)" iconClass="far fa-file"
                        title="modoArchivo" [selected]="idTipoAcceso === 0">
                  {{ 'archivo' | translate }}
                </button>
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(1)" iconClass="fas fa-link"
                        title="modoLink" [selected]="idTipoAcceso === 1">
                  {{
                  'link' | translate
                  }}
                </button>
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(2)" iconClass="fas fa-route"
                        title="modoRuta" [selected]="idTipoAcceso === 2">
                  {{
                  'ruta' | translate
                  }}
                </button>
              </kendo-buttongroup>
            </div>
          </div>
          <div class="row" *ngIf="idTipoAcceso == 2">
            <!--ES DIRECOTORIO-->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'esDirectorio' | translate}}</label>
                <div class="caja">
                  <kendo-switch id="esDirectorio" formControlName="esDirectorio" [onLabel]="' '"
                                [offLabel]="' '"></kendo-switch>
                </div>
                <kendo-textbox class="mt-3" formControlName="nombreRuta" [ngClass]="{ 'is-invalid': submitted && rutaInvalida }"></kendo-textbox>
              </div>
            </div>
          </div>
          <!--Link-->
          <div class="form-group" *ngIf="idTipoAcceso == 1">
            <kendo-label text="{{ 'link' | translate }}">
              <div class="caja">
                <kendo-textbox formControlName="link"></kendo-textbox>
              </div>
            </kendo-label>
          </div>
          <!--Documento-->
          <div *ngIf="idTipoAcceso == 0">
            <kendo-label text="{{ 'documento' | translate}}">
              <kendo-textbox formControlName="pdf" [disabled]="true"></kendo-textbox>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false"
                                    (select)="selectEventHandler($event)" (remove)="removeEventHandler($event)">
                    <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                                           remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                           retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
            </kendo-label>
            <button type="button"
                    *ngIf="!isLink && !isUploaded && form.value.pdf!=null && form.value.pdf!=undefined && form.value.pdf!=''"
                    class="btn btn-primary" (click)=" btnBorrarDoc()" [disabled]="this.user.maquinas<2">
              {{
 'eliminardocmant' |
              translate
              }}
            </button>
          </div>

          <!--Tarea de mantenimiento-->
          <div class="form-group">
            <kendo-label text="{{ 'tareaMantenimiento' | translate }}">
              <div class="caja">
                <textarea formControlName="tareaMantenimiento" kendoTextArea [autoSize]="true"> </textarea>
              </div>
            </kendo-label>
          </div>
          <!--BOTONES-->
          <div class="form-group">
            <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="onSubmit()"
                    [disabled]="this.user.maquinas<2">
              {{ 'guardar' | translate}}
            </button>
            <button class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
