<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card w-50">
    <div class="card-header">
      <h3 *ngIf="isAddMode">{{ 'creartipomaterial' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'editartipomaterial' | translate}}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <!--NOMBRE-->
      <div class="form-group">
        <kendo-label text="{{ 'nombre' | translate}}">
          <input kendoTextBox formControlName="nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
          </div>
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.maxlength">{{ 'nombre200caracteres' | translate}}</div>
          </div>
        </kendo-label>
      </div>

      <div class="form-row">
      <!--DUREZA-->
        <div class="form-group mr-2" [style.width]="'30%'">
          <kendo-label text="{{ 'dureza' | translate}}">
            <kendo-numerictextbox formControlName="dureza" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dureza.errors }" [format]="'# \\\%'" [min]="0" [max]="100"></kendo-numerictextbox>
            <div *ngIf="submitted && f.dureza.errors" class="invalid-feedback">
              <div *ngIf="f.dureza.errors.required">{{ 'durezarequerida' | translate}}</div>
            </div>
          </kendo-label>
        </div>

        <!--REFERENCIA-->
        <div class="form-group mr-2" [style.width]="'30%'">
          <kendo-label text="{{ 'Referencia' | translate}}">
            <input kendoTextBox [style.width.px]="150" formControlName="referencia" class="form-control" />
          </kendo-label>
        </div>

        <!--KC-->
        <div class="form-group mr-2" [style.width]="'30%'">
          <kendo-label text="{{ 'kc' | translate}}">
            <kendo-numerictextbox formControlName="kc" formControlName="kc" class="form-control" [max]="999999999" [min]="0" [step]="0.1"></kendo-numerictextbox>
          </kendo-label>
        </div>

      </div>

      <div class="form-group">
        <button kendoButton  class="btn mr-1  btn-primary" [disabled]="loading || this.user.materiales<2">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'guardar' | translate}}
        </button>
        <a routerLink="/materialestipo" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
      </div>

    </div>
  </div>
</form>
