import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService, AreasProductivasService, UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-areaProductiva',
  templateUrl: './areaProductiva.component.html'
})
export class AreaProductivaComponent implements OnInit {
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;

  submitted= false;
  form: FormGroup;
  dataSecciones: any[];
  mySelection: number[] = [];
  seccionNombre: string = "";
  idSeccion: number = 0;
  idAreaProductiva: number = 0;
  formValid = true;
  telefonoInvalido = false;
  public user = this.userService.userValue;

  //CARGA
  constructor(private areasProductivasService: AreasProductivasService,
    private formBuilder: FormBuilder,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private modalService: NgbModal) {


    this.menuService.titulo = this.translateService.instant('areaProductiva');


    this.idAreaProductiva = this.route.snapshot.params['id']

    this.cargarDatos();
  }
  cargarDatos() {
    //Para evitar errores
    this.form = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', ),
      email: new FormControl('', [Validators.email]),
      telefono: new FormControl('', ),
      direccion: new FormControl('',),
      codigoPostal: new FormControl('', ),
      provincia: new FormControl('', ),
      pais: new FormControl('',)
    });
    if (this.idAreaProductiva > 0)
      this.areasProductivasService.Get(this.idAreaProductiva).subscribe(
        (result) => {
          this.form = this.formBuilder.group({
            nombre: new FormControl(result[0].nombre, [Validators.required]),
            descripcion: new FormControl(result[0].descripcion, ),
            email: new FormControl(result[0].email, [Validators.email]),
            telefono: new FormControl(result[0].telefono, ),
            direccion: new FormControl(result[0].direccion, ),
            codigoPostal: new FormControl(result[0].codigoPostal, ),
            provincia: new FormControl(result[0].provincia, ),
            pais: new FormControl(result[0].pais, )
          });
          
        });
    else{ //Si es uno nuevo se crea vac�o
      this.form = this.formBuilder.group({
        nombre: new FormControl('', [Validators.required]),
        descripcion: new FormControl('', ),
        email: new FormControl('', [Validators.email]),
        telefono: new FormControl('', ),
        direccion: new FormControl('', ),
        codigoPostal: new FormControl('', ),
        provincia: new FormControl('', ),
        pais: new FormControl('', )
      });
    }

    this.cargarGrid();
  }
  cargarGrid() {
    this.mySelection = [];
    this.areasProductivasService.Seccion_Get_ByAP(this.idAreaProductiva, 0).subscribe(
      result => {
        var an: any = result;
        this.dataSecciones = an;
      });
  }
  ngOnInit(): void {
  }
  //RADGRID
  cellClick(e) {
    if (this.mySelection[0] > 0) {
      this.dataSecciones.forEach(
        seccion => {
          if (this.mySelection[0] == seccion.id) {
            this.seccionNombre = seccion.nombre;
            this.idSeccion = seccion.id;
            this.router.navigate(['seccion/'+ this.idAreaProductiva + "/"+ this.idSeccion]);
          }
        });
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.dataSecciones.forEach(
        seccion => {
          if (this.mySelection[0] == seccion.id) {
            this.seccionNombre = seccion.nombre;
            this.idSeccion = seccion.id;
            this.router.navigate(['seccion/'+ this.idAreaProductiva + "/"+ this.idSeccion]);
          }
        });
    }
  }
  onClickNuevo() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid || !this.formValid) {
      return;
    }
    var id: number = this.idAreaProductiva;
    if (id == 0) {
      this.areasProductivasService.Insert(this.form.value.nombre, this.form.value.descripcion, this.form.value.email, this.form.value. telefono,
        this.form.value.direccion, this.form.value.codigoPostal, this.form.value.provincia, this.form.value.pais).subscribe(
        (result) => {
          var an: any = result;
          this.idAreaProductiva = an;
          this.router.navigate(['seccion/'+ this.idAreaProductiva + "/0"]);
        });
    } else {
      this.areasProductivasService.Update(this.idAreaProductiva, this.form.value.nombre, this.form.value.descripcion, this.form.value.email, this.form.value. telefono,
        this.form.value.direccion, this.form.value.codigoPostal, this.form.value.provincia, this.form.value.pais).subscribe(
        (result) => {
          this.router.navigate(['seccion/'+ this.idAreaProductiva + "/0"]);
        });
    }
  }
  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  
  //POPUP BORRAR
  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        this.mySelection.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.areasProductivasService.Seccion_Delete(ids).subscribe(() => {
          this.isDeleting = false;
          this.cargarGrid();
        });
      }
      this.modalReference.close();
    });
  }
  //BOTONES PRINCIPALES
  guardarClick() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid || !this.formValid) {
      return;
    }
    var id: number = this.idAreaProductiva;
    if (id == 0) {
      this.areasProductivasService.Insert(this.form.value.nombre, this.form.value.descripcion, this.form.value.email, this.form.value. telefono,
        this.form.value.direccion, this.form.value.codigoPostal, this.form.value.provincia, this.form.value.pais).subscribe(
        (result) => {
          this.router.navigate(['areasProductivas']);
        });
    } else {
      this.areasProductivasService.Update(id, this.form.value.nombre, this.form.value.descripcion, this.form.value.email, this.form.value. telefono,
        this.form.value.direccion, this.form.value.codigoPostal, this.form.value.provincia, this.form.value.pais).subscribe(
        (result) => {
          this.router.navigate(['areasProductivas']);
        });
    }
  }
  cancelarClick() {
    this.router.navigate(['areasProductivas']);
  }


  onChangeTextCorrect(element: any){
    if(!element.srcElement.value.match("^[A-Za-z]+$")){
      element.srcElement.value = element.srcElement.value.replace(/[0-9]/g, '');
    }
  }

  onChangeNumberCorrect(element: any){
    if(!element.srcElement.value.match("^\\+?[0-9]{9,12}$")){
      this.telefonoInvalido = true;
      this.formValid = false;
    } else{
      this.formValid = true;
      this.telefonoInvalido = false;
    }
  }
}
