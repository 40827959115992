import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/herramientas`;

@Injectable()
export class HerramientasService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetPlacaById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/placa/" + id);
  }

  public GetPlacaUnidadById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/getplacaunidadbyid/" + id);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAllPlacasUnidades(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/placasunidades");
  }

  public GetAllPlacas(action: string = '', param?: string, data?: ''): Observable<any> {
    return this.http.get(baseUrl + "/placas");
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/herramientas");
  }

  insert(data, logo, archivo) {
    return this.http.post<any>(baseUrl + "/crear", { id: -1, nombre: data.nombre, vida: data.vida, diametro: data.diametro, longitud: data.longitud, angulo: data.angulo, numfiltros: data.numfiltros, salto: data.salto, iderp: data.iderp, numherramientas: data.numherramienta, referencia: data.referencia, archivo: archivo, logo: logo }, { withCredentials: true });
  }

  insertPlaca(data, logo, archivo) {
    return this.http.post<any>(baseUrl + "/crearplacas", { id: -1, nombre: data.nombre, vida: data.vida }, { withCredentials: true });
  }

  insertPlacaUnidad(data, idTipoHerramienta, logo, archivo) {
    return this.http.post<any>(baseUrl + "/crearplacasunidad", { id: -1, nombre: data.nombre, vida: data.vida, cantidad: data.cantidad, idTipoPieza: idTipoHerramienta }, { withCredentials: true });
  }

  update(data, logo, imagen) {
    return this.http.post<any>(baseUrl + "/editar", { id: data.id, nombre: data.nombre, vida: data.vida, diametro: data.diametro, longitud: data.longitud, angulo: data.angulo, numfiltros: data.numfiltros, salto: data.salto, iderp: data.iderp, numherramienta: data.numherramienta, referencia: data.referencia, archivo: imagen, logo: logo }, { withCredentials: true });
  }

  updatePlaca(data, logo, imagen) {
    return this.http.post<any>(baseUrl + "/editarplacas", { id: data.id, nombre: data.nombre, vida: data.vida }, { withCredentials: true });
  }

  updatePlacaUnidad(data, idTipoPlaca, logo, imagen) {
    return this.http.post<any>(baseUrl + "/editarplacasunidad", { id: data.id, nombre: data.nombre, vida: data.vida, cantidad: data.cantidad, idTipoPieza: idTipoPlaca }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });

  }

  deleteHerramientasUnidad(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/deleteherramientasunidad", formData, { withCredentials: true });

  }


  deletePlaca(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminarplaca", formData, { withCredentials: true });

  }

  deletePlacaUnidad(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminarplacaunidad", formData, { withCredentials: true });

  }

  public download(idMarca): Observable<any> {
    return this.http.get(baseUrl + "/logo/download/" + idMarca);
  }

  /*TIPOS DE HERRAMIENTAS*/
  public get_tiposHerramientas() {
    return this.http.post<any>(baseUrl + "/get_tiposHerramientas", {}, { withCredentials: true });
  } 
  public get_COMBO_tiposHerramientas() {
    return this.http.post<any>(baseUrl + "/get_COMBO_tiposHerramientas", {}, { withCredentials: true });
  } 
  public get_tiposHerramientas_byID(id: number) {
    return this.http.post<any>(baseUrl + "/get_tiposHerramientas_byID", { id }, { withCredentials: true });
  }
  insert_tiposHerramientas(data) {
    return this.http.post<any>(baseUrl + "/insert_tiposHerramientas", { id: -1, nombre: data.nombre }, { withCredentials: true });
  }
  update_tiposHerramientas(id : number, data) {
    return this.http.post<any>(baseUrl + "/update_tiposHerramientas", { id: id, nombre: data.nombre }, { withCredentials: true });
  }

  /*HERRAMIENTAS 1*/
  public GetAllHerramientasUnidades(): Observable<any> {
    return this.http.get(baseUrl + "/herramientasunidades");
  }
  public GetHerramientaUnidadById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/getHerramientaunidadbyid/" + id);
  }

  insertHerramientasUnidad(data) {

    return this.http.post<any>(baseUrl + "/crearherramientasunidad", {
      id: -1,
      idTipoHerramienta: data.tipoHerramienta.id , nombre: data.nombre,
      vida: data.vidaUtil, diametro: data.diametro, longitud: data.longitud,
      angulo: data.angulo, numPlacas: data.numPlacas, salto: data.salto,
      idERP: data.idERP, referencia: data.referencia, descripcion: data.descripcion,
      coste: data.coste, idFabricante: data.fabricante.id, tieneNserie: data.tieneNserie
    }, { withCredentials: true });
  }
  updateHerramientasUnidad(data) {
    return this.http.post<any>(baseUrl + "/editarherramientasunidad", {
      id: data.id,
      idTipoHerramienta: data.tipoHerramienta.id, nombre: data.nombre,
      vida: data.vidaUtil, diametro: data.diametro, longitud: data.longitud,
      angulo: data.angulo, numPlacas: data.numPlacas, salto: data.salto,
      idERP: data.idERP, referencia: data.referencia, descripcion: data.descripcion,
      coste: data.coste, idFabricante: data.fabricante.id, tieneNserie: data.tieneNserie
    }, { withCredentials: true });
  }

}
