<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6 col-lg-4">
      <div class="card">
        <div class="card-header">
          <h3 *ngIf="isAddMode">{{ 'crearasignaturareto' | translate}}</h3>
          <h3 *ngIf="!isAddMode">{{ 'editarasignaturareto' | translate}}</h3>
        </div>
        <div class="card-body">
          <!--NOMBRE-->
          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate}}">
             <!-- <input kendoTextBox formControlName="nombreAsignatura" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" /> -->
              <input kendoTextBox [value]="asignaturaReto.nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && errorNombreVacio }"  (change)="nombreChange($event)"/>              
            </kendo-label>
          </div>
  
          <!--ACTIVO-->
          <div class="form-group form-check" *ngIf="!form.value.tieneSubtipos">
            <kendo-label class="form-check-label" text="{{ 'activo' | translate}}">
              <div class="caja">
                <kendo-switch [checked]="asignaturaReto.activo" [(value)]="asignaturaReto.activo" [onLabel]="' '" [offLabel]="' '">
                </kendo-switch>
              </div>
            </kendo-label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <button kendoButton  class="btn mr-1  btn-primary" [disabled]="loading || this.user.materiales<2">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'guardar' | translate}}
        </button>
        <a routerLink="/eskola_asignaturasretos" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
      </div> 
    </div>
    <div class="col-md-6 col-lg-8">
      <div class="card">
        <div class="card-body">
          <h3 class="pt-3">{{ 'docentes' | translate}}</h3>
          <div class="form-row">

            <!--DOCENTES-->
            <div class="card" *ngIf="!form.value.tieneSubtipos">
              <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td">
                <kendo-grid [data]="docentesList" 
                  [rowClass]="rowCallback" 
                  [selectable]="{enabled: true}"
                  [selectedKeys]="mySelection" 
                  kendoGridSelectBy="docentesID" 
                  [navigable]="true" 
                  [sortable]="true"
                  scrollable="virtual" 
                  [rowHeight]="36" 
                  [height]="500" 
                  [pageSize]="50" 
                  filterable="menu"
                  [resizable]="true" 
                  (cellClick)="cellClickHandler($event)">
          
                  <!-- CHECKBOX 
                  <kendo-grid-checkbox-column width="4%">
                    <ng-template kendoGridHeaderTemplate>
                      <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                            (selectAllChange)="myFunctions.onSelectAllChangeGrid($event, piezasSelecteds, gridView, 'id')">
                      <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                    </ng-template>
                  </kendo-grid-checkbox-column>-->
                  
                  <!--botoiak--><!--, checkboxOnly: false, drag: true-->
                  <ng-template kendoGridToolbarTemplate position="top">
                    <div class="float-left">
                      <button type="button" (click)="checkAllDocentes()" class="btn btn-success btn-sm mr-1">{{ buttonAllDocentesSelectName }}</button>
                    </div>
                  </ng-template>

                  <kendo-grid-checkbox-column  [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}"
                  width="10%">
                    <ng-template let-dataItem="rowIndex">
                      <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" enabled />
                    </ng-template>
                  </kendo-grid-checkbox-column>
                  <kendo-grid-column field="nombreDocente" title="{{ 'docentes' | translate}}" width="90%">
                  </kendo-grid-column>
          
                  <!--TRADUCCIÓN TEXTOS DEL GRID-->
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

      

  



  </form>
  



