<div class="card">
    <div class="card-body">
        <div class="clearfix">
            <!-- PERIODO -->
            <div class="float-left mr-2">
                <div class="form-group">
                    <kendo-label text="{{ 'periodo' | translate }}">
                        <div class="caja">
                            <kendo-datepicker [weekNumber]="true" [readonly]="false" [(value)]="fechaInicio"
                                (valueChange)="onChange($event)">
                                <kendo-calendar-messages today="{{ 'hoy' | translate }}">
                                </kendo-calendar-messages>
                            </kendo-datepicker>
                        </div>
                    </kendo-label>
                </div>
            </div>
            <!-- AREA PRODUCTIVA -->
            <div class="float-left mr-2"  style="width: 400px;">
                <div class="form-group">
                    <kendo-label text="{{ 'areaProductiva' | translate }} / {{ 'seccion' | translate }}">
                        <div class="caja">
                            <kendo-multiselect [data]="areasProductivas" [textField]="'nombreSeccion'"
                                [valueField]="'idSeccion'" [(ngModel)]="selectedAreaSeccion"
                                (valueChange)="cambiarAreaSeccion()">
                                <ng-template kendoMultiSelectGroupTemplate let-groupName>
                                    <strong>{{ groupName }}</strong>
                                </ng-template>
                            </kendo-multiselect>
                        </div>
                    </kendo-label>
                </div>
            </div>
            <!-- NUMERO SEMANA -->
            <!-- <div class="float-left mr-2">
                <div class="form-group">
                    <kendo-label text="{{ 'areaProductiva' | translate }} / {{ 'seccion' | translate }}">
                        <div class="caja">
                            <kendo-multiselect [data]="areasProductivas" [textField]="'nombreSeccion'"
                                [valueField]="'idSeccion'" [(ngModel)]="selectedAreaSeccion"
                                (valueChange)="cambiarAreaSeccion()">
                                <ng-template kendoMultiSelectGroupTemplate let-groupName>
                                    <strong>{{ groupName }}</strong>
                                </ng-template>
                            </kendo-multiselect>
                        </div>
                    </kendo-label>
                </div>
            </div> -->
            <!-- IMPRIMIR ezkerretan-->
            <div class="float-right">
                <div class="form-group">
                    <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupImprimir()">
                        {{ 'imprimir' | translate}}
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- GRID -->
<div class="card">
    <div *ngIf="ensenarTabla == true" class="griddiv">
        <div *ngFor="let maquina of tablaMaquinas; let i = index">
            <!-- CREAR HEADER -->
            <div *ngIf="i == 0">
                <!-- <label class="float-left"> {{ 'horario' | translate }} </label> -->
                <!-- <div *ngFor="let dia of maquina.dias">
                    <div>
                        <label class="float-left"> {{ dia.nombreDia }} </label>
                    </div>
                </div> -->
                <div class="linea-griddiv">
                    <div class="zutabe-makina-turnosOperarios">
                        <label> <strong>{{ 'horario' | translate }} {{ 'semana' | translate }} {{weekNo}}</strong></label>
                    </div>
                    <div class="linea-griddiv-inner zutabe-eguna-turnosOperarios">
                        <div *ngFor="let dia of maquina.dias" class="col-griddiv header-eguna-turnoOperario">
                            <div class="clearfix">
                                <label class="eguna-label-turnoOperario"> {{ dia.nombreDia }} </label>
                            </div>
                            <div class="linea-griddiv-inner">
                                <div *ngFor="let turno of dia.turnosDia" class="col-griddiv">
                                    <label> {{ turno.nombreTurno }} </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <!-- ROW MAQUINA -->
            <div class="linea-griddiv">
                <div class="zutabe-makina-turnosOperarios col-griddiv"><label> {{ maquina.nombreMaquina }} </label>
                </div>
                <div class="linea-griddiv-inner zutabe-eguna-turnosOperarios">
                    <div *ngFor="let dia of maquina.dias" class="col-griddiv">
                        <div class="linea-griddiv-inner">

                            <div *ngFor="let turno of dia.turnosDia" class="col-griddiv" style="position: relative;">
                                <!-- <div class="row"> -->

                                <!-- *ngIf="turno.operarios != undefined && (turno.showMultiselect == true || turno.operarios.length == 0)" -->
                                <div class="w-100 usuarios-turno" style="  position: absolute; top: 0; left: 0;">
                                    <kendo-multiselect [data]="listaNombreValorOperario" [(ngModel)]="turno.operarios"
                                        [textField]="'nombreCompleto'" [valueField]="'id'" autoWidth="false"
                                        [autoClose]="true" (close)="turno.showMultiselect = false" style="width: 100%;"
                                         class="selec-operario" (opened)="open()" (valueChange)="selectionChange($event, turno.operarios)">
                                        <ng-template kendoMultiSelectTagTemplate let-dataItems>
                                        </ng-template>
                                    </kendo-multiselect>
                                    <!-- <kendo-dropdownlist [kendoDropDownFilter]="{operator: 'contains'}" [data]="listaNombreValorOperario" [(ngModel)]="turno.operarios"
                                        [textField]="'nombreCompleto'" [valueField]="'id'" autoWidth="false" style="width: 100%;"
                                         class="selec-operario" (opened)="open()">
                                         <ng-template kendoDropDownListValueTemplate let-dataItem>
                                            {{dataItem}}
                                                <span *ngIf="dataItem != undefined && dataItem != null" class="grid-circulo"
                                                    [ngStyle]="{'background-color': dataItem.color}">{{dataItem.iniciales}}</span>
                                                <span *ngIf="dataItem == undefined || dataItem == null"></span>
                                          </ng-template>
                                    </kendo-dropdownlist> -->
                                </div>

                                <div *ngIf="turno.operarios != undefined"
                                    style="position: absolute; top: 0; left: 0; pointer-events: none;">
                                    <div class="tipo-grid-col col-grupotrabajo">
                                        <ng-container *ngIf="turno.operarios.length < 3">
                                            <ng-container *ngFor="let item of turno.operarios | slice:0:5;">
                                                <span (click)="turno.showMultiselect = true" class="grid-circulo"
                                                    [ngStyle]="{'background-color': item.color}">{{item.iniciales}}</span>
                                            </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="turno.operarios.length > 2">
                                            <span class="grid-circulo"
                                                [ngStyle]="{'background-color': '#1f7ec2'}">{{turno.operarios.length}}</span>
                                        </ng-container>
                                    </div>
                                </div>



                                <!-- </div> -->

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- SI NO HAY NINGUNA SEMANA SELECCIONADA -->
    <div *ngIf="ensenarTabla == false && turnoNoCreado == false">
        <div class="center"><label> {{ 'seleccioneUnaFecha' | translate }} </label></div>
    </div>
    <!-- SI LA SEMANA SELECCIONADA NO TIENE TURNO CREADO -->
    <div *ngIf="ensenarTabla == false && turnoNoCreado == true">
        <div class="center"><label> {{ 'turnoNoCreado' | translate }} </label></div>
    </div>

    <!-- <pre>{{proba | json}}</pre> -->
</div>

<!-- BOTONES -->
<div class="card">
    <div class="card-body">
        <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" (click)="onSubmit()" [disabled]="this.user.turnos <2">
                {{ 'guardar' | translate}}
            </button>
            <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupDuplicar()">
                {{ 'duplicar' | translate}}
            </button>
            <!-- <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
                {{ 'cancelar' | translate}}
            </button> -->
            <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupCopiarDias()">
                {{ 'popupCopiarTurnos' | translate}}
            </button>
            <button type="button" class="btn btn-danger btn-sm mr-1" (click)="vaciar()">
                {{ 'vaciar' | translate}}
            </button>
        </div>
    </div>
</div>

<!-- POPUPS -->

<!--POPUP COPIAR DIAS-->
<ng-template #popupCopiarDias let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{ 'popupCopiarTurnos' | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- turno a copiar -->
        <div class="row">
            <div class="col">
                <p>{{ 'turnoACopiar' | translate }}</p>
                <!-- <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="1" [(ngModel)]="turnoACopiarSeleccionado"  kendoRadioButton />
                    <kendo-label>{{ 'lunes' | translate }}</kendo-label>  
                </div>
                <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="2" [(ngModel)]="turnoACopiarSeleccionado" kendoRadioButton />
                    <kendo-label>{{ 'martes' | translate }}</kendo-label> 
                </div>
                <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="3" [(ngModel)]="turnoACopiarSeleccionado" kendoRadioButton />
                    <kendo-label>{{ 'miercoles' | translate }}</kendo-label> 
                </div>
                <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="4" [(ngModel)]="turnoACopiarSeleccionado" kendoRadioButton />
                    <kendo-label>{{ 'jueves' | translate }}</kendo-label> 
                </div>
                <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="5" [(ngModel)]="turnoACopiarSeleccionado"  kendoRadioButton />
                    <kendo-label>{{ 'viernes' | translate }}</kendo-label> 
                </div>
                <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="6" [(ngModel)]="turnoACopiarSeleccionado" kendoRadioButton />
                    <kendo-label>{{ 'sabado' | translate }}</kendo-label> 
                </div>
                <div class="col-md-3">
                    <input type="radio" name="turnoParaCopiar" value="7" [(ngModel)]="turnoACopiarSeleccionado" kendoRadioButton />
                    <kendo-label>{{ 'domingo' | translate }}</kendo-label> 
                </div> -->
                <kendo-dropdownlist [data]="turnoACopiar" [textField]="'nombre'" [valueField]="'id'"
                                  [(value)]="turnoACopiarSeleccionado">
              </kendo-dropdownlist>
            </div>

            <!-- turno para ser copiado-->
            <div class="col">
                <p>{{ 'turnosParaCopiar' | translate }}</p>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="lunesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==1"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'lunes' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="martesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==2"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'martes' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="miercolesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==3"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'miercoles' | translate }}</kendo-label>  <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="juevesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==4"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'jueves' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="viernesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==5"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'viernes' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="sabadoSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==6"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'sabado' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="domingoSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==7"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'domingo' | translate }}</kendo-label> 
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-copiar" (click)="copiarDias()" [disabled]="this.user.turnos <2">{{ 'copiar'
            |
            translate }}</button>
    </div>
</ng-template>

<!-- POPUP VACIAR -->
<ng-template #popupVaciar let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{ 'popupVaciarTurnos' | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!--Numero semana-->
        <div class="form-group">
            <kendo-label>{{ 'maquina' | translate }}</kendo-label>
            <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaMaquinas" [textField]="'maquina'"
                    [valueField]="'idMaquina'" placeholder="{{ 'seleccioneMaquina' | translate }}" [autoClose]="false"
                    style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}"
                    [(ngModel)]="maquinasVaciarSeleccionadas">
                    <ng-template kendoMultiSelectHeaderTemplate>
                        <span style="margin-left:11px">
                            <input type="checkbox" id="chk" kendoCheckBox [checked]="isCheckedVaciarMaquina"
                                [indeterminate]="isIndet" (click)="onClickVaciarMaquina()" />
                            <kendo-label for="chk">{{ 'selectAll' | translate}}</kendo-label>
                        </span>
                    </ng-template>
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].maquina}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                            'maquinasSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                    </kendo-multiselect-messages>
                </kendo-multiselect>
            </div>
        </div>
        <div class="form-group">
            <kendo-label>{{ 'turnos' | translate }}</kendo-label>
            <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="turnos" [textField]="'nombreTurno'"
                    valueField="idTurno" placeholder="{{ 'seleccioneTurno' | translate }}" [autoClose]="false"
                    style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}"
                    [(ngModel)]="turnosVaciarSeleccionados">
                    <ng-template kendoMultiSelectHeaderTemplate>
                        <span style="margin-left:11px">
                            <input type="checkbox" id="chk" kendoCheckBox [checked]="isCheckedVaciarTurnos"
                                [indeterminate]="isIndet" (click)="onClickVaciarTurnos()" />
                            <kendo-label for="chk">{{ 'selectAll' | translate}}</kendo-label>
                        </span>
                    </ng-template>
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreTurno}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                            'turnosSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                    </kendo-multiselect-messages>
                </kendo-multiselect>
            </div>
        </div>

        <div class="form-group">
            <kendo-label>{{ 'diasSemana' | translate }}</kendo-label>
            <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="diasSemana" [textField]="'nombreDia'"
                    [valueField]="'idDia'" placeholder="{{ 'seleccioneDia' | translate }}" [autoClose]="false"
                    style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}"
                    [(ngModel)]="diasVaciarSemanaSeleccionados">
                    <ng-template kendoMultiSelectHeaderTemplate>
                        <span style="margin-left:11px">
                            <input type="checkbox" id="chk" kendoCheckBox [checked]="isCheckedVaciarDiaSemana"
                                [indeterminate]="isIndet" (click)="onClickVaciarDiaSemana()" />
                            <kendo-label for="chk">{{ 'selectAll' | translate}}</kendo-label>
                        </span>
                    </ng-template>
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreDia}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                            'diasSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                    </kendo-multiselect-messages>
                </kendo-multiselect>
            </div>
        </div>

        <div *ngIf="loadingDuplicar" class="k-i-loading"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="vaciarDia($event)" [disabled]="this.user.turnos <2">{{
            'vaciar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP IMPRIMIR -->
<ng-template #popupImprimir let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{ 'imprimir' | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group center">
            <kendo-label>{{ 'diasSemana' | translate }}</kendo-label>
            <div class="caja">
                <kendo-dropdownlist [data]="diasSemana" [textField]="'nombreDia'" [valueField]="'idDia'"
                    [(value)]="diaImprimir">
                </kendo-dropdownlist>
            </div>
        </div>
    </div>
    <div class="center">
        <button type="button" class="btn btn-primary" (click)="imprimir($event)" [disabled]="this.user.turnos <2">{{
            'imprimir' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP SELECCIONAR OPERARIOS -->
<ng-template #popupSeleccionarOperario let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{ 'seleccionaOperario' | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group center">
            <kendo-label>{{ 'operarios' | translate }}</kendo-label>
            <div class="caja">
                <kendo-dropdownlist [data]="listaNombreValorOperario" [textField]="'nombreCompleto'" [valueField]="'id'"
                    [(value)]="operariosSeleccionadosPOPUP">
                </kendo-dropdownlist>
            </div>
        </div>
    </div>
    <div class="center">
        <button type="button" class="btn btn-primary" (click)="imprimir($event)" [disabled]="this.user.turnos <2">{{
            'guardar' | translate }}</button>
    </div>
</ng-template>

<!--POPUP DUPLICAR-->
<ng-template #popupDuplicar let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{ 'popupDuplicarTurnos' | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'popupduplicarsemanaTurnosOperarios' | translate }}</p>

        <!--Numero semana-->
        <div class="col-md-3">
            <div class="form-group">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaTurnosPosteriores" [textField]="'turno'" [valueField]="'id'"
                    [(ngModel)]="selectedTurnosPosteriores" [autoClose]="false">
                    <ng-template kendoMultiSelectHeaderTemplate>
                        <span style="margin-left:11px">
                            <input type="checkbox" id="chk" kendoCheckBox [checked]="isChecked"
                                [indeterminate]="isIndet" (click)="onClick()" />
                            <kendo-label for="chk">{{ 'selectAll' | translate}}</kendo-label>
                        </span>
                    </ng-template>
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].turno}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{'turnosSeleccionados' |
                            translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
            </div>
        </div>
        <div *ngIf="loadingDuplicar" class="k-i-loading"></div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-copiar" (click)="duplicarSemana()" [disabled]="this.user.turnos <2">{{
            'duplicar' | translate }}</button>
    </div>
</ng-template>