import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { ActivatedRoute } from '@angular/router';
import { FabricantesService } from '@app/_services';

import { AlmacenesService } from '@app/_services/almacenes.service';

import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-historicoConsumible',
  templateUrl: 'historicoConsumible.component.html'
})

export class HistoricoConsumible {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //VARIABLES DB: TIPO
  public idTipo: number = 0;
  public nombreTipo: string = '';
  public descripcion: string = '';
  public solicitarVidaUtil: boolean = false;
  public solicitarDiametro: boolean = false;
  public solicitarLongitud: boolean = false;
  public solicitarAngulo: boolean = false;
  public solicitarNumContenido: boolean = false;
  public solicitarSalto: boolean = false;
  public solicitarIdErp: boolean = false;
  public solicitarCoste: boolean = false;

  //VARIABLES DB: CONSUMIBLE
  public idConsumible: number = 0;
  public nombreConsumible: string = '';
  public descripcionConsumible: string = '';
  public tieneNserie: boolean = false;
  public numContenido: number = 0;
  public vidaUtil: number = 0;
  public fiabilidad: number = 0;
  public diametro: number = 0;
  public longitud: number = 0;
  public angulo: number = 0;
  public salto: number = 0;
  public coste: number = 0;
  public idErp: number = 0;

  //COMBOS
  public JconsumiblesTipos: any;
  public JconsumiblesTiposSelected: any = {
    descripcion: "",
    id: 0,
    nombre: "",
    solicitar_angulo: false,
    solicitar_coste: false,
    solicitar_diametro: false,
    solicitar_idERP: false,
    solicitar_longitud: false,
    solicitar_numContenido: false,
    solicitar_salto: false,
    solicitar_vidaUtil: false
  };
  public JconsumiblesFabricantes: any;
  public JconsumiblesFabricantesSelected: any;
  public fabricante: any;


  //GRID: consumibles Tipos
  public historicoconsumibles: any;
  public seleccionados: number[] = [];

  //POPUP: deshechar
  public popupDeshechar: boolean = false;

  //POPUP:  nuevo
  public popupMover: boolean = false; //POPUP: mover
  public popupNuevo: boolean = false;
  public cantidad: number = 1;
  public Jalmacenes: any;
  public JalmacenesMostrar: any;
  public JalmacenesSelected: any;
  //POPUP: nSerie
  public popupNserie: boolean = false;
  public JnSeries: any = { id: 0, nombre: '' };
  //POPUP: Eliminar
  public popupEliminar: boolean = false;
  public popupErrorAlEliminar: boolean = false;
  //POPUP: Usar (aratz: falta usos entre consumibles)
  public popupUsar: boolean = false;
  public JconsumibelesMostrar: any;
  public JconsumibelesSelected: any;
  user = this.userService.userValue;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public fabricantesService: FabricantesService,
    private userService: UsuariosService,
    public almacenesService: AlmacenesService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('historicoConsumibles').toUpperCase();
  }

  ngOnInit() {
    this.cargarCombos();
  }

  cargarCombos() {
    var r1, r2, r3: boolean = false;

    this.consumiblesService.Get_consumiblesTipos().subscribe(
      (json) => {
        this.JconsumiblesTipos = json;
        this.JconsumiblesTiposSelected = this.JconsumiblesTipos[0];

        r1 = true;
        if (r1 && r2 && r3)
          this.cargarDatos();
      });

    this.fabricantesService.get_COMBO().subscribe(
      (json) => {
        this.JconsumiblesFabricantes = json.data; //Prefiero hace esto sin data
        this.JconsumiblesFabricantesSelected = this.JconsumiblesFabricantes[0];
        this.fabricante = this.JconsumiblesFabricantesSelected.nombre;

        r2 = true;
        if (r1 && r2 && r3)
          this.cargarDatos();
      });

    this.almacenesService.GetAll().subscribe(
      (result) => {
        this.Jalmacenes = result.data; //Prefiero hace esto sin data
        this.JalmacenesSelected = this.Jalmacenes[0];

        r3 = true;
        if (r1 && r2 && r3)
          this.cargarDatos();
      });
  }

  cargarDatos() {
    this.loadingPanel = true;

    var r1, r2, r3: boolean = false;
    this.idTipo = this.route.snapshot.params['idTipo'];
    if (this.idTipo > 0) {
      this.consumiblesService.Get_consumiblesTipos_byID(this.idTipo).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            this.nombreTipo = json[0].nombre;
            this.descripcion = json[0].descripcion;
            this.solicitarVidaUtil = json[0].solicitar_vidaUtil;
            this.solicitarDiametro = json[0].solicitar_diametro;
            this.solicitarLongitud = json[0].solicitar_longitud;
            this.solicitarAngulo = json[0].solicitar_angulo;
            this.solicitarNumContenido = json[0].solicitar_numContenido;
            this.solicitarSalto = json[0].solicitar_salto;
            this.solicitarIdErp = json[0].solicitar_idERP;
            this.solicitarCoste = json[0].solicitar_coste;
          }

          r1 = true;

          if (r1 && r2 && r3)
            this.loadingPanel = false;
        });
    }
    else {
      r1 = true;
      if (r1 && r2 && r3)
        this.loadingPanel = false;
    }

    this.idConsumible = this.route.snapshot.params['idConsumible'];
    if (this.idConsumible > 0) {
      this.consumiblesService.Get_consumibles_byID(this.idConsumible).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            this.JconsumiblesTiposSelected = this.JconsumiblesTipos.filter((f) => f.id == json[0].idConsumibles_tipos)[0];
            this.JconsumiblesFabricantesSelected = this.JconsumiblesFabricantes.filter((f) => f.id == json[0].idConsumibles_fabricantes)[0];
            this.nombreConsumible = json[0].nombre;
            this.descripcionConsumible = json[0].descripcion;
            this.tieneNserie = json[0].tieneNserie;
            this.numContenido = json[0].numContenido;
            this.vidaUtil = json[0].vidaUtil;
            this.fiabilidad = json[0].fiabilidad;
            this.diametro = json[0].diametro;
            this.longitud = json[0].longitud;
            this.angulo = json[0].angulo;
            this.salto = json[0].salto;
            this.coste = json[0].coste;
            this.idErp = json[0].idERP;
          }

          r2 = true;
          if (r1 && r2 && r3)
            this.loadingPanel = false;
        });
    }
    else {
      r2 = true;
      if (r1 && r2 && r3)
        this.loadingPanel = false;
    }

    this.consumiblesService.Get_historico_consumibles_grid_by_consumible(this.idConsumible).subscribe(
      (json) => {
        this.historicoconsumibles = json;

        r3 = true;
        if (r1 && r2 && r3)
          this.loadingPanel = false;
      });
  }

  onClick_Deshechar() {
    if (this.seleccionados[0] > 0) {
      this.popupDeshechar = true;
    }
  }
  deshechar() {
    var ids: any = this.seleccionados;

    //todo: eliminar hijos false jarri diogu
    this.consumiblesService.Deshechar_historico_consumible_ids(ids, false).subscribe(
      (result) => {
        this.cargarDatos();
        this.popupDeshechar = false;
      });
  }

  onClick_Mover() {
    if (this.seleccionados[0] > 0) {
      this.popupMover = true;

      this.JalmacenesMostrar = this.Jalmacenes;

      this.JalmacenesSelected = this.Jalmacenes[0];
    }
  }
  mover() {
    var ids: any = this.seleccionados;

    //TODOOOO! POSICION -1 JARRI DIOGU!!!
    this.consumiblesService.Mover_historico_consumibles(ids, this.JalmacenesSelected.id, -1).subscribe(
      (result) => {
        this.cargarDatos();
        this.popupMover = false;
      });
  }

  onClick_Usar() {
    this.popupUsar
  }
  usar() {
  }

  onClick_Nuevo() {
    this.cantidad = 1;
    this.popupNuevo = true;

    this.JalmacenesMostrar = this.Jalmacenes;

    this.JalmacenesSelected = this.Jalmacenes[0];
  }
  crear_nuevo() {
    if (this.tieneNserie) {
      var nss: any = [];
      for (var _i = 0; _i < this.cantidad; _i++) {
        var json: any = {};
        json['id'] = _i;
        json['nombre'] = '';
        nss.push(json);
      }

      this.JnSeries = nss;

      this.popupNuevo = false;
      this.popupNserie = true;
    } else {
      var nss: any = [];
      for (var _i = 0; _i < this.cantidad; _i++) {
        var ns: string = this.idConsumible + '_NS_' + (_i + this.historicoconsumibles.length);
        nss.push(ns);
      }

      this.consumiblesService.Insert_historico_consumibles(this.idConsumible, this.JalmacenesSelected.id, nss).subscribe(
        (result) => {
          this.cargarDatos();
          this.popupNuevo = false;
        });
    }
  }
  rellenar_nSerie() {
    var nss: any = [];
    for (var _i = 0; _i < this.cantidad; _i++) {
      var json: any = {};
      json['id'] = _i;
      json['nombre'] = this.idConsumible + '_NS_' + (_i + this.historicoconsumibles.length);
      nss.push(json);
    }

    this.JnSeries.forEach(
      (nSerie) => {
        if (nSerie.nombre == '') {
          nSerie.nombre = nss[nSerie.id].nombre;
        }
      });
  }
  vaciar_nSerie() {
    var nss: any = [];
    for (var _i = 0; _i < this.cantidad; _i++) {
      var json: any = {};
      json['id'] = _i;
      json['nombre'] = '';
      nss.push(json);
    }

    this.JnSeries = nss;
  }

  crear_nSerie() {
    var nss: any = [];
    this.JnSeries.forEach(
      (nSerie) => {
        var ns: string = nSerie.nombre;
        nss.push(ns);
      });

    this.consumiblesService.Insert_historico_consumibles(this.idConsumible, this.JalmacenesSelected.id, nss).subscribe(
      (result) => {
        this.cargarDatos();
        this.popupNserie = false;
      });
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      var url = '';
      url = this.router.serializeUrl(this.router.parseUrl('#/historicoConsumibleNserie/' + this.idTipo + '/' + this.idConsumible + '/' + e.dataItem.id));
      window.open(url, '_blank');
    }
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
