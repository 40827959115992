<style>
  :host /deep/ .c3-chart-arcs .c3-chart-arcs-background {
    stroke: #e9f0f6;
  }

  :host /deep/ .c3 path.c3-shape.c3-shape.c3-arc {
    stroke: #e9f0f6 !important;
  }
</style>

<div class="row">

  <div class="col-md-7">
    <div class="row">

      <!--panel maquina (de home)-->
      <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

      <!--GAUGES-->
      <div class="col-lg-7" style="height: 350px;">
        <div class="panel-procesos">
          <div class="titulo-panel-procesos">
            <label class="titulo-panel-procesos-texto">{{ 'temperaturas' | translate }}</label>
          </div>
          <div class="contenido-panel-procesos" style="padding: 0 0 0 0;">
            <div class="row" style="padding: 5px 15px 0 15px;">

              <!--tempAnilloEntrada-->
              <div class="col-4" style="margin-bottom: 15px;">
                <label class="nombre-grafico-temperaturas">{{ 'tempAnilloEntrada' | translate }}</label>
                <div id="graficoTempAnilloEntrada_hmiext" class="grafico-temperaturas"></div>
                <label class="valor-grafico-temperaturas">{{ labelTempAnilloEntrada }}</label>
              </div>

              <!--tempIBCEntrada-->
              <div class="col-4" style="margin-bottom: 15px;">
                <label class="nombre-grafico-temperaturas">{{ 'tempIBCEntrada' | translate }}</label>
                <div id="graficoTempIBCEntrada_hmiext" class="grafico-temperaturas"></div>
                <label class="valor-grafico-temperaturas">{{ labelTempIBCDeEntrada }}</label>
              </div>

              <!--TempRodilloCalandra-->
              <div class="col-4" style="margin-bottom: 15px;">
                <label class="nombre-grafico-temperaturas">{{ 'TempRodilloCalandra' | translate }}</label>
                <div id="graficoTempRodilloCalandra_hmiext" class="grafico-temperaturas"></div>
                <label class="valor-grafico-temperaturas">{{ labelTempRodilloCalandra }}</label>
              </div>

              <!--TempRodilloHalador-->
              <div class="col-4" style="margin-bottom: 15px;">
                <label class="nombre-grafico-temperaturas">{{ 'TempRodilloHalador' | translate }}</label>
                <div id="graficoTempRodilloHalador_hmiext" class="grafico-temperaturas"></div>
                <label class="valor-grafico-temperaturas">{{ labelTempRodilloHalador }}</label>
              </div>

              <!--kwhTotales-->
              <div class="col-4" style="margin-bottom: 15px;">
                <label class="nombre-grafico-temperaturas">{{ 'kwhTotales' | translate }}</label>
                <div id="graficoKwhTotales_hmiext" class="grafico-temperaturas"></div>
                <label class="valor-grafico-temperaturas">{{ labelKwhTotales }}</label>
              </div>

              <!--potenciaInstantanea-->
              <div class="col-4" style="margin-bottom: 15px;">
                <label class="nombre-grafico-temperaturas">{{ 'potenciaInstantanea' | translate }}</label>
                <div id="graficoPotenciaInstantanea_hmiext" class="grafico-temperaturas"></div>
                <label class="valor-grafico-temperaturas">{{ labelPotenciaInstantanea }}</label>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="col-md-5">
    <div class="row">

      <!--OF-->
      <div class="col-lg-6" style="height: 350px;">
        <div class="panel-procesos">
          <div class="titulo-panel-procesos">
            <label class="titulo-panel-procesos-texto">{{ 'of' | translate }}</label>
          </div>
          <div class="contenido-panel-procesos">

            <!--OF-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'of' | translate }}: </label><label class="of-label-text">{{ of }}</label>
              </div>
            </div>

            <!--CLIENTE-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'cliente' | translate }}: </label><label class="of-label-text">{{ cliente }}</label>
              </div>
            </div>

            <!--PIEZA-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'pieza' | translate }}: </label><label class="of-label-text">{{ pieza }}</label>
              </div>
            </div>

            <!--PARTE-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'parte' | translate }}: </label><label class="of-label-text">{{ parte }}</label>
              </div>
            </div>

            <!--OPERACION-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'operacion' | translate }}: </label><label class="of-label-text">{{ operacion }}</label>
              </div>
            </div>

            <!--N SERIE DE LA PIEZA-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'nSeriePieza' | translate }}: </label><label class="of-label-text">{{ nSeriePieza }}</label>
              </div>
            </div>

            <!--CANTIDAD-->
            <div class="row of-label-linea">
              <div class="col-12">
                <label class="of-label-titulo">{{ 'cantidad2' | translate }}: </label><label class="of-label-text">{{ realizadas }}</label>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--RESUMEN SEMANA-->
      <div class="col-lg-6" (click)="RedirigirRendimiento()" style="height: 350px; cursor: pointer;">
        <div class="panel-procesos">
          <div class="titulo-panel-procesos">
            <label class="titulo-panel-procesos-texto">{{ 'resumenSemana' | translate }}</label>
          </div>
          <div class="contenido-panel-procesos">

            <div id="graficoResumenSemana_hmiext" style="height: 250px;" [ngClass]="{'d-none': ensennarResumenSemana ? false : true}"></div>
            <div class="row" style="height: 250px; position: relative;" [ngClass]="{'d-none': !ensennarResumenSemana ? false : true}">
              <a style="position: absolute; top: 45%; width: 100%; text-align: center;">{{ 'noDatosParaMostrar' | translate}}</a>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<!--TABS-->
<kendo-tabstrip [keepTabContent]="true">
  <kendo-tabstrip-tab id="tabCapas" title="{{ 'capas' | translate}}" [selected]="true">
    <ng-template kendoTabContent>
      <div class="row" style="margin-left: -10px; margin-right: -10px; margin-top: 10px; height: 450px;">

        <!--CAPAS-->
        <div class="col-lg-4 capa-container" *ngFor="let capa of maquina.capas">
          <label class="title-capa">{{ capa.nombre }}</label>
          <div class="row capa-graficos-container">

            <!--PRESION FILTROS-->
            <div class="col-3" style="padding: 0 5px 0 5px;">
              <label class="title-grafico-capas">{{ 'presionFiltros' | translate }}</label>
              <div [attr.id]="'graficoCapaPresionFiltros_hmiext_' + capa.id" class="grafico-capas"></div>
              <label class="valor-grafico-capas">{{ capa.presion }} bar</label>
            </div>

            <!--RENDIMIENTO/TASA DE TRANSPORTE-->
            <div class="col-3" style="padding: 0 5px 0 5px;">
              <label class="title-grafico-capas">{{ 'rendimientoTasaDeTransporte' | translate }}</label>
              <div class="grafico-capas">
                <label class="label-capa-valor">{{ capa.rendimientoTasaDeTransporte.toFixed(2) }}</label>
                <label class="label-capa-valor-unidad">kh/h/rpm</label>
              </div>
            </div>

            <!--CARGA MOTOR-->
            <div class="col-3" style="padding: 0 5px 0 5px;">
              <label class="title-grafico-capas">{{ 'cargaMotor' | translate }}</label>
              <div class="grafico-capas">
                <label class="label-capa-valor">{{ capa.cargaMotor.toFixed(0) }}</label>
                <label class="label-capa-valor-unidad">A</label>
              </div>
            </div>

            <!--REVOLUCIONES MOTOR-->
            <div class="col-3" style="padding: 0 5px 0 5px;">
              <label class="title-grafico-capas">{{ 'revolucionesMotor' | translate }}</label>
              <div [attr.id]="'graficoCapaRevolucionesMotor_hmiext_' + capa.id" class="grafico-capas"></div>
              <label class="valor-grafico-capas">{{ capa.revolucionesMotor }} rpm</label>
            </div>

          </div>
        </div>

      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab id="tabProcesos" title="{{ 'procesos' | translate}}">
    <ng-template kendoTabContent>
      <div class="row" style="margin-left: -10px; margin-right: -10px; height: 450px;">
        <div class="procesdos col-sm-12 col-md-4">
          <div class="proces-titulo">
            {{ 'historicoProcesos' | translate | uppercase}}
          </div>
          <div class="clearfix">
            <div class="processcroll">
              <div class="historial" *ngFor="let historico of historicoProcesos">

                <div class={{historico.divcss}}>
                  <div class="clearfix">
                    <div class="proces-fecha-cont">
                      <div id="horaIni" class="horaIni">
                        <label>{{historico.horaini}}</label>
                      </div>
                      <div id="diaIni" class="diaIni">
                        <label>{{historico.diaini}}</label>
                      </div>
                    </div>

                    <div class="proces-info-cont">
                      <div id="tipoProceso" *ngIf="historico.idProcesos_Tipo!=6">
                        <label><span class="proces-titulo-uno">{{historico.tipo}}:</span> {{historico.nombre}}</label>
                      </div>
                      <div *ngIf="historico.idProcesos_Tipo==6">
                        <label><span class="proces-titulo-uno">{{historico.tipo}}:</span> {{historico.tipoAlarNombre}}</label>
                      </div>
                      <div id="tiempos">
                        <label><span class="proces-titulo-uno">{{ 'tiempoReal' | translate}}:</span> {{historico.tiemporeal}} <span class="proces-titulo-uno" *ngIf="historico.idProcesos_Tipo!=2">{{ 'tiempoEstimado' | translate}}:</span><span *ngIf="historico.idProcesos_Tipo!=2"> {{historico.tiempoteorico}}</span></label>
                      </div>
                    </div>
                  </div>
                  <div id="informacionOF" class="informacionOF">
                    <label><span class="proces-titulo-dos">OF:</span> {{historico.OF}} <span class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{historico.cliente}} <span class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{historico.parte}} <span class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{historico.pieza}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="procesdos col-sm-12 col-md-4">
          <div class="proces-titulo">
            {{ 'alarmas' | translate | uppercase}}
          </div>
          <div class="clearfix">
            <div class="processcroll">
              <div class="historial" *ngFor="let alarma of alarmas">

                <div class={{alarma.divcss}}>

                  <div class="clearfix">
                    <div class="proces-fecha-cont">

                      <div id="horaIni">
                        <label>{{alarma.horaini}}</label>
                      </div>
                      <div id="diaIni">
                        <label>{{alarma.diaini}}</label>
                      </div>

                    </div>

                    <div class="proces-info-cont">
                      <div id="numeroAlarma">
                        <label>{{alarma.numeroAlarma}}</label> - <label>{{alarma.descripcion.replace('ALARMA: ', '')}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="procesdos col-sm-12 col-md-4">
          <div class="proces-titulo">
            {{ 'planificado' | translate | uppercase}}
          </div>
          <div class="clearfix">
            <div class="processcroll">
              <div class="historial" *ngFor="let planificado of planificados">

                <div class={{planificado.divcss}}>

                  <div class="clearfix">

                    <div class="proces-fecha-cont">
                      <div id="tiempos" class="horaIni">
                        <label>{{planificado.horaini}}</label>
                      </div>

                      <div id="tiempos" class="diaIni">
                        <label>{{planificado.diaini}}</label>
                      </div>
                    </div>

                    <div class="proces-info-cont">
                      <div id="operacion">
                        <label id="operacion"><span class="proces-titulo-uno">{{ 'operacion' | translate}}:</span> {{planificado.operacion}}</label>
                      </div>
                      <div id="tiempoTeorico">
                        <label><span class="proces-titulo-uno">{{ 'tiempoEstimado' | translate}}:</span> {{planificado.tiempoteorico}}</label>
                      </div>
                    </div>
                  </div>
                  <div id="informacionOF" class="informacionOF">
                    <label><span class="proces-titulo-dos">OF:</span> {{planificado.OF}} <span class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{planificado.cliente}} <span class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{planificado.parte}} <span class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{planificado.pieza}}</label>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
    <ng-template kendoTabContent>
      <div class="row cont-mantenimientos" style="margin-left: -10px; margin-right: -10px; height: 450px;">
        <div class="col-md-6">
          {{ 'porFechas' | translate | uppercase}}
          <div class="">
            <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true" [rowClass]="rowCallback">
              <kendo-grid-column field="switch" width="17%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="texto" width="41%">
              </kendo-grid-column>
              <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span>{{ dataItem.tipo | translate }}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fecha" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="variacionicono" title="" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                    <span class="k-icon k-i-file-pdf"></span>
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
            <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
          </div>
        </div>
        <div class="col-md-6">
          {{ 'porTiempos' | translate | uppercase}}
          <div class="">
            <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true" [rowClass]="rowCallback">
              <kendo-grid-column field="switch" width="17%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="texto" width="51%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="variacionicono" title="" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                    <span class="k-icon k-i-file-pdf"></span>
                  </button>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
            <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab id="tabNotas" title="{{ 'notas' | translate}}">
    <ng-template kendoTabContent>
      <div class="row" style="margin-left: -10px; margin-right: -10px;  height: 450px;">
        <kendo-grid [data]="notas" style="height: 400px">
          <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
          </kendo-grid-column>
          <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
          </kendo-grid-column>
          <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
          </kendo-grid-column>
          <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
