import { Component } from '@angular/core';
import { AlertService, UsuariosService, MaquinasService, MenuService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-maquina-detalle-subestados',
    templateUrl: './maquinaDetalleSubEstados.component.html'
})

export class MaquinaDetalleSubEstadosComponent {

    //VARIABLES BASICAS
    private translate: TranslateService;
    public loadingPanel: any = false;
    public user = this.userService.userValue;

    // COMBO PROCEOS
    public JProcesos_Tipo: any =
        [
            { "id": "1", "nombre": "" + this.translateService.instant('ejecucion') + "" },
            { "id": "2", "nombre": "" + this.translateService.instant('parada') + "" },
            { "id": "3", "nombre": "" + this.translateService.instant('preparacion') + "" },
            { "id": "4", "nombre": "" + this.translateService.instant('mantenimiento') + "" },
            { "id": "6", "nombre": "" + this.translateService.instant('alarma') + "" },
            { "id": "8", "nombre": "" + this.translateService.instant('apagada') + "" }
        ];
    public JProcesos_Tipo_subEstados: any = [];
    public JMaquinas_SubEstados: any = [];
    //VARIABLES: SUB-ESTADOS
    public id: number = 0;
    public idMaquina: number = 0;
    public JProcesos_Tipo_Selected: any;
    public JProcesos_Tipo_subEstados_Selected: any;
    public JMaquinas_SubEstadosSelected: any;
    public orden: number = 1;
    public nombre: string = "";
    public color: string = "";
    public tiempoPerdida: number = 0; // EN CASO DE SER PARADA, CUANTO TIEMPO TIENE QUE PASAR ANTES DE SOLICITAR UNA PERDIDA
    public activo: boolean = true;
    public tieneHijos: boolean = false;
    public bloqueado: boolean = false; // BLOQUEAR LOS SUBESTADOS CREADOS POR ZITU

    public tiempoCambioDeEstado: number = 0;
    public JMaquinas_proximo_SubEstados_Selected: any;
    public JMaquinas_proximo_SubEstados: any;

    constructor(
        private maquinasService: MaquinasService,
        public router: Router,
        private route: ActivatedRoute,
        private userService: UsuariosService,
        private menuService: MenuService,
        private translateService: TranslateService,
        private alertService: AlertService) {

        this.translate = this.translate;
    }

    ngOnInit() {
        this.idMaquina = this.route.snapshot.params['idMaquina'];
        this.id = this.route.snapshot.params['id'];

        this.cargarCombos();
    }

    cargarCombos() {
        this.JProcesos_Tipo_Selected = this.JProcesos_Tipo.filter(f => (f.id == '1'))[0];
        var r1, r2 = false;
        this.maquinasService.Get_Procesos_Tipo_subEstados_disponibles_porMaquina(this.idMaquina, this.id).subscribe(
            (j) => {
                j.forEach(
                    (proceso_subEstado) => {
                        proceso_subEstado.nombre = this.translateService.instant(proceso_subEstado.nombre);
                        proceso_subEstado.Procesos_Tipo = this.JProcesos_Tipo_Selected = this.JProcesos_Tipo.filter(f => (f.id == proceso_subEstado.idProcesos_Tipo))[0];
                    });

                this.JProcesos_Tipo_subEstados = j;
                this.JProcesos_Tipo_subEstados_Selected = this.JProcesos_Tipo_subEstados.filter(f => (f.id == '1'))[0];

                r1 = true;
                if (r1 && r2)
                    this.cargarDatos();
            });
        this.maquinasService.Get_subestados(this.idMaquina).subscribe(
            (j) => {
                j.forEach(
                    (subEstado) => {
                        subEstado.nombre = this.translateService.instant(subEstado.nombre);
                    });

                this.JMaquinas_SubEstados = j.filter(f => f.idMaquinas_subEstados < 0 && f.id != this.id);
                this.JMaquinas_SubEstadosSelected = this.JMaquinas_SubEstados.filter(f => (f.value == '1'))[0];

                this.JMaquinas_proximo_SubEstados = j.filter(f => f.idMaquinas_subEstados < 0 && f.id != this.id);
                this.JMaquinas_proximo_SubEstados_Selected = this.JMaquinas_SubEstados.filter(f => (f.value == '1'))[0];

                r2 = true;
                if (r1 && r2)
                    this.cargarDatos();
            });
    }

    cargarDatos() {
        if (this.id == undefined) {
            this.id = 0;
            this.maquinasService.Get_subestados_maxOrden(this.idMaquina).subscribe(
                (orden) => {
                    console.log(orden)
                    if (orden.length > 0)
                        this.orden = orden[0].orden + 1;
                    else
                        this.orden = 1;
                });
        } else {
            this.maquinasService.Get_subestado(this.id).subscribe(
                (j) => {
                    if (j.length > 0) {
                        var subestado = j[0]
                        this.JProcesos_Tipo_subEstados_Selected = this.JProcesos_Tipo_subEstados.filter(f => f.id == subestado.idProcesos_Tipo_subEstados)[0];
                        this.JMaquinas_SubEstadosSelected = this.JMaquinas_SubEstados.filter(f => f.id == subestado.idMaquinas_subEstados)[0];
                        this.orden = subestado.orden;
                        this.tiempoPerdida = subestado.tiempoPerdida; // EN CASO DE SER PARADA, CUANTO TIEMPO TIENE QUE PASAR ANTES DE SOLICITAR UNA PERDIDA
                        this.activo = subestado.activo;
                        this.tieneHijos = subestado.tieneHijos;
                        this.bloqueado = subestado.bloqueado; // BLOQUEAR LOS SUBESTADOS CREADOS POR ZITU
                        this.tiempoCambioDeEstado = subestado.tiempoCambioDeEstado;
                        this.JMaquinas_proximo_SubEstados_Selected = this.JMaquinas_SubEstados.filter(f => f.id == subestado.idSiguienteEstado)[0];
                    }
                });
        }
    }

    // //AÑADE NUEVO ESTADO AL GRID
    clickGuardar() {
        console.log(this.activo)
        if (this.activo)
            console.log("ok")
        if (this.JProcesos_Tipo_subEstados_Selected != undefined) {
            var idMaquinas_subEstados = -1;
            if (this.JMaquinas_SubEstadosSelected != undefined)
                idMaquinas_subEstados = this.JMaquinas_SubEstadosSelected.id
            var idSiguienteEstado = -1;
            if (this.JMaquinas_proximo_SubEstados_Selected != undefined)
            idSiguienteEstado = this.JMaquinas_proximo_SubEstados_Selected.id
            if (this.id == 0) {
                /* INSERT */
                this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
                    var data: any = result.data;
                    this.maquinasService.Insert_subestados(this.idMaquina
                        , this.JProcesos_Tipo_subEstados_Selected.id
                        , idMaquinas_subEstados
                        , this.orden
                        , this.tiempoPerdida
                        , this.activo
                        , this.bloqueado
                        , this.tiempoCambioDeEstado
                        , idSiguienteEstado).subscribe(
                            (r) => {
                                console.log(data);
                                if (data[0].esMaquina == 1) {
                                    this.router.navigate(['maquinas/editar/' + this.idMaquina]);
                                } else if (data[0].esInstalacion == 1) {
                                    this.router.navigate(['instalaciones/editar/' + this.idMaquina]);
                                }
                            });
                });

            } else {
                /* UPDATE */
                this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
                    var data: any = result.data;
                    this.maquinasService.Update_subestados(this.id
                        , this.idMaquina
                        , this.JProcesos_Tipo_subEstados_Selected.id
                        , idMaquinas_subEstados
                        , this.orden
                        , this.tiempoPerdida
                        , this.activo
                        , this.bloqueado
                        , this.tiempoCambioDeEstado
                        , idSiguienteEstado).subscribe(
                            (r) => {
                                if (data[0].esMaquina == 1) {
                                    this.router.navigate(['maquinas/editar/' + this.idMaquina]);
                                } else if (data[0].esInstalacion == 1) {
                                    this.router.navigate(['instalaciones/editar/' + this.idMaquina]);
                                }
                            });
                });
            }
            this.maquinasService.Update_color_subEstado(this.JProcesos_Tipo_subEstados_Selected.id, this.JProcesos_Tipo_subEstados_Selected.color).subscribe();
        }
    }
    clickAtras() {
        this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
            var data: any = result.data;
            if (data[0].esMaquina == 1) {
                this.router.navigate(['maquinas/editar/' + this.idMaquina]);
            } else if (data[0].esInstalacion == 1) {
                this.router.navigate(['instalaciones/editar/' + this.idMaquina]);
            }
        });
    }
}