<div class="grafico-posicion">
  <div class="marcador-triangulo" [ngStyle]="{'left': calcularTriangulo()}">
<span class="icon-flechaabajo"></span>
  </div>
  
  <div class="barra-cont">
   <div class="barra-inner" [ngStyle]="{'left': calcularLeftBarra(), 'right': calcularRightBarra()}">    
  </div>
  </div>
  
  <div class="barra-inicio"[ngStyle]="{'left': calcularLeftBarra(), 'display': showInicio()}"></div>
  <div class="barra-fin" [ngStyle]="{'left': calcularBarraFin(), 'display': showFin()}"></div>
  
  <div class="numeros-graf-cont">
    <label class="numero-graf" *ngFor="let i of array">{{i}}</label>  
  </div>
  
</div>
    
    