import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/bloqueoRangos`;

@Injectable()
export class BloqueoRangosService {

  constructor(private http: HttpClient) { }

  GetBloqueoRangos() {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos`, { withCredentials: true });
  }

  GetBloqueoRangos_Maquinas() {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos_Maquinas`, { withCredentials: true });
  }

  GetBloqueoRangos_Operarios() {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos_Operarios`, { withCredentials: true });
  }

  GetBloqueoRangos_Tipos_Dat() {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos_Tipos_Dat`, { withCredentials: true });
  }

  InsertBloqueoRangos(selectedTipoBloqueo, selectedTipoPerdida, selectedTurno, nombre, descripcion, recurrente, fechaIniUnico, fechaFinUnico, frecuencia, hora, tiempoEstimado, dia, mes, idMaquinas, idOperarios) {
    return this.http.post<JSON>(`${baseUrl}/InsertBloqueoRangos`, {
      idTipoBloqueoRango: selectedTipoBloqueo, idDiccionarioPerdidas: selectedTipoPerdida,
      idTipoTurno: selectedTurno, nombre: nombre, descripcion: descripcion, recurrente: recurrente, fechaInicio: fechaIniUnico, fechaFin: fechaFinUnico,
      frecuencia: frecuencia, hora: hora, tiempoEstimado: tiempoEstimado, dia: dia, mes: mes, idMaquinas: idMaquinas, idOperarios: idOperarios
    }, { withCredentials: true });
  }

  GetBloqueoRangos_byId(id) {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos_byId`, { id }, { withCredentials: true });
  }

  GetBloqueoRangos_Maquinas_byId(id) {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos_Maquinas_byId`, { id }, { withCredentials: true });
  }

  GetBloqueoRangos_Operarios_byId(id) {
    return this.http.post<JSON>(`${baseUrl}/GetBloqueoRangos_Operarios_byId`, { id }, { withCredentials: true });
  }

  DeleteBloqueoRangos(listaIds) {
    return this.http.post<JSON>(`${baseUrl}/DeleteBloqueoRangos`, { listaIds }, { withCredentials: true });
  }

  UpdateBloqueoRangos(id, selectedTipoBloqueo, selectedTipoPerdida, selectedTurno, nombre, descripcion, recurrente, fechaIniUnico, fechaFinUnico, frecuencia, hora, tiempoEstimado, dia, mes, idMaquinas, idOperarios) {
    return this.http.post<JSON>(`${baseUrl}/UpdateBloqueoRangos`, {
      id: id, idTipoBloqueoRango: selectedTipoBloqueo, idDiccionarioPerdidas: selectedTipoPerdida,
      idTipoTurno: selectedTurno, nombre: nombre, descripcion: descripcion, recurrente: recurrente, fechaInicio: fechaIniUnico, fechaFin: fechaFinUnico,
      frecuencia: frecuencia, hora: hora, tiempoEstimado: tiempoEstimado, dia: dia, mes: mes, idMaquinas: idMaquinas, idOperarios: idOperarios
    }, { withCredentials: true });
  }

  DuplicarBloqueoRangos(id) {
    return this.http.post<JSON>(`${baseUrl}/DuplicarBloqueoRangos`, { id }, { withCredentials: true });
  }

}
