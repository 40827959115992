<!--FILTRO-->
<div class="cont-filtro-maquinas-areas clearfix">
  <!-- FILTRO GRUPOS -->
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">
        <div class="row">
          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
      <div *ngFor="let maquina of maquinasMostradas" (click)="selectedMaquina = maquina.id; desplegarMaquinasClick(); cargarInforme();" class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>
  <!-- FILTRO TURNOS Y FECHAS -->
  <div class="card cont-filtro-informes">
    <div class="card-body">
      <div class="clearfix">
        <!--Turnos-->
        <div class="diasemana-turno ">
          <div class="form-group">
            <label class="control-label">{{ 'turno' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [(value)]="comboTurnosSeleccion" formControlName="idTurno" [placeholder]="turnos_placeHolder" [data]="turnosCombo" [textField]="'nombre'" [valueField]="'id'"
                                 (valueChange)="turnos_onValueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"></kendo-multiselect>
            </div>
          </div>
        </div>
        <!--Dia/Semana-->
        <div class="float-left">
          <div class="btn mr-1" [class.btn-success]="filtroDiaSemana" [class.btn-outline-success]="!filtroDiaSemana">
            <input type="radio" name="diaSemanaGroup" id="btnDia" class="k-radio" (click)="btnDia()" [checked]="filtroDiaSemana" kendoRadioButton>
            <label class="k-radio-label" for="btnDia">{{ 'dia' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!filtroDiaSemana" [class.btn-outline-success]="filtroDiaSemana">
            <input type="radio" name="diaSemanaGroup" id="btnSemana" class="k-radio" (click)="btnSemana()" [checked]="!filtroDiaSemana" kendoRadioButton>
            <label class="k-radio-label" for="btnSemana">{{ 'semana' | translate }}</label>
          </div>
        </div>
      </div>

      <!--Fechas-->
      <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'fechas' | translate }}</label>
        <div class="caja">
          <kendo-datepicker [weekNumber]="true" class="filtro-informes-data-1" [(value)]="fechaInicio" (valueChange)="fechaInicio_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
          <!--<kendo-datepicker [weekNumber]="true" class="filtro-informes-data-2" [readonly]="true" [(value)]="fechaFin" (valueChange)="fechaFin_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>-->
        </div>
      </div>
    </div>
  </div>
</div>


<!--GRAFICOS-->
<div id="divGraficos">
  <!--LINEA 1-->
  <div class="row">
    <!--Resumen Semana-->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'resumenSemana' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartDonutResumenSemana_informeEjecuciones"> </div>
        </div>
        <div *ngIf="loadingResumenSemana" class="k-i-loading"></div>
      </div>
    </div>
    <!--Distancia recorrida-->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'avancesEstimadosDistanciaRecorrida' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartAvancesEstimados" style="min-height: 320px;">
            <!--Labels-->
            <div class="graf-barr-horas-title">
              <label>{{ 'milimetros' | translate }}</label>
            </div>
            <div class="graf-barr-labels">
              <div class="clearfix">
                <div class="cinco-porciento"></div>
                <label>{{labelMetros1}}</label>
                <label>{{labelMetros2}}</label>
                <label>{{labelMetros3}}</label>
                <label>{{labelMetros4}}</label>
                <label>{{labelMetros5}}</label>
                <label>{{labelMetros6}}</label>
                <label>{{labelMetros7}}</label>
                <label>{{labelMetros8}}</label>
                <label>{{labelMetros9}}</label>
                <div class="cinco-porciento"></div>
              </div>
              <div class="graf-barr-labels-linea"></div>
            </div>
            <!--Barras-->
            <div class="graf-barr">
              <div class="diez-porciento"></div>
              <div class="graf-bar-centro">
                <!-- BARRA eje1-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra1" [style.display]="(visibleEje1)">
                  <div class="textobarra">
                    <Label>{{labelEje1}}</Label>
                    <Label>{{labelDatoEje1}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje1" [style.width.%]="(valorEje1)"></div>
                </div>
                <!-- BARRA eje2-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra2" [style.display]="(visibleEje2)">
                  <div class="textobarra">
                    <Label>{{labelEje2}}</Label>
                    <Label>{{labelDatoEje2}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje2" [style.width.%]="(valorEje2)"></div>
                </div>
                <!-- BARRA eje3-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra3" [style.display]="(visibleEje3)">
                  <div class="textobarra">
                    <Label>{{labelEje3}}</Label>
                    <Label>{{labelDatoEje3}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje3" [style.width.%]="(valorEje3)"></div>
                </div>
                <!-- BARRA eje4-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra4" [style.display]="(visibleEje4)">
                  <div class="textobarra">
                    <Label>{{labelEje4}}</Label>
                    <Label>{{labelDatoEje4}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje4" [style.width.%]="(valorEje4)"></div>
                </div>
                <!-- BARRA eje5-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra5" [style.display]="(visibleEje5)">
                  <div class="textobarra">
                    <Label>{{labelEje5}}</Label>
                    <Label>{{labelDatoEje5}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje5" [style.width.%]="(valorEje5)"></div>
                </div>
                <!-- BARRA eje6-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra6" [style.display]="(visibleEje6)">
                  <div class="textobarra">
                    <Label>{{labelEje6}}</Label>
                    <Label>{{labelDatoEje6}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje6" [style.width.%]="(valorEje6)"></div>
                </div>
                <!-- BARRA eje7-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra7" [style.display]="(visibleEje7)">
                  <div class="textobarra">
                    <Label>{{labelEje7}}</Label>
                    <Label>{{labelDatoEje7}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje7" [style.width.%]="(valorEje7)"></div>
                </div>
                <!-- BARRA eje8-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra8" [style.display]="(visibleEje8)">
                  <div class="textobarra">
                    <Label>{{labelEje8}}</Label>
                    <Label>{{labelDatoEje8}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje8" [style.width.%]="(valorEje8)"></div>
                </div>
                <!-- BARRA eje9-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra9" [style.display]="(visibleEje9)">
                  <div class="textobarra">
                    <Label>{{labelEje9}}</Label>
                    <Label>{{labelDatoEje9}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje9" [style.width.%]="(valorEje9)"></div>
                </div>
                <!-- BARRA eje10-->
                <div class="graf-barr-part-cont" style="background-color: #ABA8A8" id="barra10" [style.display]="(visibleEje10)">
                  <div class="textobarra">
                    <Label>{{labelEje10}}</Label>
                    <Label>{{labelDatoEje10}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraEje10" [style.width.%]="(valorEje10)"></div>
                </div>
              </div>

              <div class="diez-porciento"></div>
            </div>
          </div>
        </div>
        <div *ngIf="loadingAvancesEstimados" class="k-i-loading"></div>
      </div>
    </div>
  </div>

  <!--LINEA 2-->
  <div class="row">
    <!--Ejecucion-->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'ejecucion' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartDonutEjecucion"></div>
        </div>
        <div *ngIf="loadingVelocidadCabezalYPotens" class="k-i-loading"></div>
      </div>
    </div>
    <!--Velocidad cabezal-->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'velocidadCabezal' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartDonutVelocidadCabezal"></div>
        </div>
        <div *ngIf="loadingVelocidadCabezalYPotens" class="k-i-loading"></div>
      </div>
    </div>
    <!--Avances-->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'potenciometroAvances' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartDonutAvances"></div>
        </div>
        <div *ngIf="loadingVelocidadCabezalYPotens" class="k-i-loading"></div>
      </div>
    </div>
    <!--Cabezal-->
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'ponteciometroCabezal' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartDonutCabezal"></div>
        </div>
        <div *ngIf="loadingVelocidadCabezalYPotens" class="k-i-loading"></div>
      </div>
    </div>
  </div>
</div>
