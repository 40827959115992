import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/notas`;

@Injectable()
export class NotasService {

  constructor(private http: HttpClient) { }

  public GetAll() {
    return this.http.post<JSON>(`${baseUrl}/get_notas`, {}, { withCredentials: true });
  }
  public GetUsuariosColores() {
    return this.http.post<JSON>(`${baseUrl}/get_usuarios_colores`, {}, { withCredentials: true });
  }
  public GetTipos() {
    return this.http.post<JSON>(`${baseUrl}/get_tipos`, {}, { withCredentials: true });
  }
  public GetReferencia(tipo: number) {
    return this.http.post<JSON>(`${baseUrl}/get_referencia`, { tipo }, { withCredentials: true });
  }
  public GetOFs() {
    return this.http.post<JSON>(`${baseUrl}/get_OFs`, {}, { withCredentials: true });
  }
  public GetPiezasByOF(OF: number) {
    return this.http.post<JSON>(`${baseUrl}/get_piezas_by_OF`, { OF }, { withCredentials: true });
  }
  public GetPartesByPieza(pieza: number) {
    return this.http.post<JSON>(`${baseUrl}/get_partes_by_pieza`, { pieza }, { withCredentials: true });
  }
  public GetRutasByParte(parte: number) {
    return this.http.post<JSON>(`${baseUrl}/get_rutas_by_parte`, { parte }, { withCredentials: true });
  }
  public GetOperacionesByRuta(ruta: number) {
    return this.http.post<JSON>(`${baseUrl}/get_operaciones_by_ruta`, { ruta }, { withCredentials: true });
  }
  public InsertNota(tipo: number, referencia: number, usuario:number, comentario: string, permanente: boolean) {
    return this.http.post<JSON>(`${baseUrl}/insert_nota`, { tipo, referencia, usuario, comentario, permanente }, { withCredentials: true });
  }
  public UpdateNota(idNota: number, tipo: number, referencia: number, usuario:number, comentario: string, permanente: boolean) {
    return this.http.post<JSON>(`${baseUrl}/update_nota`, { idNota, tipo, referencia, usuario, comentario, permanente }, { withCredentials: true });
  }
  public GetNotaByID(idNota: number) {
    return this.http.post<JSON>(`${baseUrl}/get_nota_by_id`, { idNota }, { withCredentials: true });
  }
  public ArchivarNotas(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/archivar_notas`, { ids }, { withCredentials: true });
  }
  public EliminarNotas(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/eliminar_notas`, { ids }, { withCredentials: true });
  }
  public GetDatosOperacion(operacion: number) {
    return this.http.post<JSON>(`${baseUrl}/get_datos_operacion`, { operacion }, { withCredentials: true });
  }
  public GetDatosPieza(pieza: number) {
    return this.http.post<JSON>(`${baseUrl}/get_datos_pieza`, { pieza }, { withCredentials: true });
  }
}
