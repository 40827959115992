import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router"

import { SubcontratadoService } from '@app/_services/subcontratado.service';

import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../_services';

import { UsuariosService, AlertService } from '@app/_services';

import { FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';

import { GroupResult, groupBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-subcontratado',
  templateUrl: 'subcontratado.component.html'
})

export class SubcontratadoComponent {
  private translate: TranslateService;
  navigationSubscription;
  public id: number = 0;

  public nombre: string = '';
  public nombreRequerido: boolean = false;

  public idSeccion: any;
  public secciones: any;
  public groupedSeccion: any;
  public seccionRequerido: boolean = false;

  public capacidadSemanal: number = 0;
  public capacidadSemanalRequerido: boolean = false;
  public envioPorDefecto: number = 0;
  public envioPorDefectoRequerido: boolean = false;
  public costeHora: number = 0;
  public costeHoraRequerido: boolean = false;

  public nombreArchivo: string = "";
  public imageToShow: string = "";
  public imagen: any = ['',];
  public imagenBase64: string = '';
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };
  user = this.userService.userValue;

  constructor(private subcontratadoService: SubcontratadoService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private route: ActivatedRoute) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('subcontratado').toUpperCase();

  }
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];

    this.userService.getComboSecciones().subscribe(
      json => {
        this.secciones = json;
        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

        this.cargarDatos();
      });
  }
  cargarDatos() {
    if (this.id > 0)
      this.subcontratadoService.get(this.id).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            var an: any = json[0];

            this.nombre = an.nombre;
            this.idSeccion = {id: an.idSeccion};
            this.capacidadSemanal = an.capacidadSemanal_horas;
            this.envioPorDefecto = an.envioPorDefecto;
            this.costeHora = an.costeHora;

            this.nombreArchivo = an.imagen;
            this.imagen = an.imagen;
            this.imagenBase64 = an.imagenBase64;
            this.imageToShow = an.imagenBase64;
          }
        });
  }

  //AZABAL: IMAGEN NUEVO
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.nombreArchivo = e.files[0].name;

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.imagen !== "" && th.imagen !== null)
        archivoBase64 = await th.toBase64(th.imagen[0]);
      else archivoBase64 = "";
      th.imageToShow = archivoBase64;
      th.imagenBase64 = archivoBase64;
    }, 500);
  }
  archivoEliminado(e: RemoveEvent) {
    this.nombreArchivo = "";
    this.imagenBase64 = "";
    this.imageToShow = "";
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  onClickGuardar() {
    this.nombreRequerido = (this.nombre == '');
    this.seccionRequerido = (this.idSeccion == undefined);
    this.capacidadSemanalRequerido = (this.capacidadSemanal == null);
    this.envioPorDefectoRequerido = (this.envioPorDefecto == null);
    this.costeHoraRequerido = (this.costeHora == null);

    var correcto: boolean = !this.nombreRequerido && !this.seccionRequerido && !this.capacidadSemanalRequerido && !this.envioPorDefectoRequerido && !this.costeHoraRequerido;

    if (correcto) {
      if (this.id == 0) {
        this.subcontratadoService.insert(this.idSeccion.id, this.nombre, this.capacidadSemanal, this.envioPorDefecto, this.nombreArchivo, this.imagenBase64, this.costeHora,0,0,0,0,0,false).subscribe(
          (result) => {
            this.router.navigate(['subcontratados']);
          });
      }
      else {
        this.subcontratadoService.update(this.id, this.idSeccion.id, this.nombre, this.capacidadSemanal, this.envioPorDefecto, this.nombreArchivo, this.imagenBase64, this.costeHora,0,0,0,0,0,false).subscribe(
          (result) => {
            this.router.navigate(['subcontratados']);
          });
      }
    }
  }
  onClickCancelar() {
    this.router.navigate(['subcontratados']);
  }
}
