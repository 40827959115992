
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- INFO TIPO -->
<div class="card k-hidden">
  <div class="card-body col-md-8">
    <div class="row">
      <!-- DERECHA -->
      <div class="col-md-4">
        <div class="row">
          <!-- NOMBRE -->
          <div class="col-md-9">
            <div class="form-group">
              <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-textbox [(value)]="nombreTipo" [maxlength]="50" disabled="false"></kendo-textbox>
              </div>
            </div>
          </div>
        </div>
        <!-- SOLICITARES -->
        <div class="row">
          <!--VIDA UTIL-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'vidaUtil' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarVidaUtil" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!--DIAMETRO-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'diametro' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarDiametro" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- LONGITUD -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'longitud' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarLongitud" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- ANGULO -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'angulo' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarAngulo" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- NUM CONTENIDO -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'numContenido' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarNumContenido" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- SALTO -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'salto' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarSalto" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- ID ERP -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'idErp' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarIdErp" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
          <!-- COSTE -->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'coste' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="solicitarCoste" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DESCRIPCION -->
      <div class="col-md-7">
        <div class="form-group">
          <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="descripcion" [maxlength]="2000" disabled="false"></kendo-textbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- INFO CONSUMIBLE -->
<div class="card k-hidden">
  <div class="card-body col-md-8">
    <div class="row">
      <!-- DERECHA -->
      <div class="col-md-4">
        <div class="row">
          <!-- TIPO CONSUMIBLE -->
          <div class="float-left">
            <label class="crontol-label float-left m-1">{{ 'tipo' | translate }}</label>
            <div class="caja float-left" style="width: 150px">
              <kendo-combobox [data]="JconsumiblesTipos"
                              [textField]="'nombre'"
                              [valueField]="'id'"
                              [(value)]="JconsumiblesTiposSelected"
                              [kendoDropDownFilter]="{operator: 'contains'}"
                              disabled="false">
              </kendo-combobox>
            </div>
          </div>
          <!-- FABRICANTE -->
          <div class="float-left">
            <label class="crontol-label float-left m-1">{{ 'fabricante' | translate }}</label>
            <div class="caja float-left" style="width: 150px">
              <kendo-combobox [data]="JconsumiblesFabricantes"
                              [textField]="'nombre'"
                              [valueField]="'id'"
                              [(value)]="JconsumiblesFabricantesSelected"
                              [kendoDropDownFilter]="{operator: 'contains'}"
                              disabled="false">
              </kendo-combobox>
            </div>
          </div>
          <!-- NOMBRE -->
          <div class="col-md-9">
            <div class="form-group">
              <kendo-label text="{{ 'nombre' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-textbox [(value)]="nombreConsumible" [maxlength]="50" disabled="false"></kendo-textbox>
              </div>
            </div>
          </div>
          <!-- TIEN E NSERIE -->
          <div class="col-md-9">
            <div class="form-group">
              <kendo-label text="{{ 'tieneNserie' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-switch [(ngModel)]="tieneNserie" [onLabel]="' '" [offLabel]="' '" disabled="false"></kendo-switch>
              </div>
            </div>
          </div>
        </div>
        <!-- DATOS -->
        <div class="row">
          <!--VIDA UTIL-->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_vidaUtil">
            <div class="form-group">
              <label>{{ 'vidaUtil' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox format="0" [min]="0" [max]="9999999" [(value)]="vidaUtil" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!--FIABILIDAD-->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_vidaUtil">
            <div class="form-group">
              <label>{{ 'fiabilidad' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox [min]="0" [max]="9999999" [(value)]="fiabilidad" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!--DIAMETRO-->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_diametro">
            <div class="form-group">
              <label>{{ 'diametro' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="diametro" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!-- LONGITUD -->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_longitud">
            <div class="form-group">
              <label>{{ 'longitud' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="longitud" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!-- ANGULO -->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_angulo">
            <div class="form-group">
              <label>{{ 'angulo' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="angulo" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- NUM CONTENIDO -->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_numContenido">
            <div class="form-group">
              <label>{{ 'numContenido' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox format="0" [min]="0" [max]="999999" [(value)]="numContenido" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!-- SALTO -->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_salto">
            <div class="form-group">
              <label>{{ 'salto' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox format="0" [min]="0" [max]="999999" [(value)]="salto" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!-- ID ERP -->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_idERP">
            <div class="form-group">
              <label>{{ 'idErp' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox format="0" [min]="0" [max]="999999" [(value)]="idErp" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
          <!-- COSTE -->
          <div class="float-left mr-2" [hidden]="!this.JconsumiblesTiposSelected.solicitar_coste">
            <div class="form-group">
              <label>{{ 'coste' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-numerictextbox [min]="0" [max]="999999" [(value)]="coste" disabled="false"></kendo-numerictextbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- DESCRIPCION -->
      <div class="col-md-7">
        <div class="form-group">
          <kendo-label text="{{ 'descripcion' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="descripcionConsumible" [maxlength]="2000"></kendo-textbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- INFO NSERIE -->
<div class="card k-hidden">
  <div class="card-body col-md-8">
    <div class="row">
      <!-- DATOS -->
      <div class="col-md-4">
        <div class="row">
          <!-- N SERIE -->
          <div class="col-md-9">
            <div class="form-group">
              <kendo-label text="{{ 'Nserie' | translate }}"></kendo-label>
              <div class="caja">
                <kendo-textbox [(value)]="Nserie" [maxlength]="50" disabled="false"></kendo-textbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- INFO VISIBLE -->
<div class="card">
  <div class="card-header">
    <h3>
      <label>{{ 'consumible' | translate}}</label>
    </h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <!-- CONTENIDO -->
    <div class="row">
      <!-- TIPO -->
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'tipo' | translate }}:</label>
        <label class="bloques-info-valor">{{nombreTipo}}</label>
      </div>
      <!-- CONSUMIBLE -->
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'consumible' | translate }}:</label>
        <label class="bloques-info-valor">{{nombreConsumible}}</label>
      </div>
      <!-- FABRICANTE -->
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'fabricante' | translate }}:</label>
        <label class="bloques-info-valor">{{fabricante}}</label>
      </div>
      <!-- USA NSERIE -->
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'tieneNserie' | translate }}:</label>
        <label class="bloques-info-valor" *ngIf="tieneNserie">{{ 'si' | translate }}</label>
        <label class="bloques-info-valor" *ngIf="!tieneNserie">{{ 'no' | translate }}</label>
      </div>
      <!-- VIDA UTIL -->
      <div class="bloques-info">
        <label class="bloques-info-label">{{ 'vidaUtil' | translate }}:</label>
        <label class="bloques-info-valor" *ngIf="solicitarVidaUtil">{{ secondsToHms(vidaUtil) }}</label>
        <label class="bloques-info-valor" *ngIf="!solicitarVidaUtil">{{ 'noTiene' | translate }}</label>
      </div>
      <!-- FIABILIDAD -->
      <div class="bloques-info" *ngIf="solicitarVidaUtil">
        <label class="bloques-info-label">{{ 'fiabilidad' | translate }}:</label>
        <label class="bloques-info-valor">{{fiabilidad}}</label>
      </div>
      <!-- FIABILIDAD -->
      <div class="bloques-info" *ngIf="solicitarDiametro">
        <label class="bloques-info-label">{{ 'diametro' | translate }}:</label>
        <label class="bloques-info-valor">{{diametro}} Ø</label>
      </div>
      <!-- LONGITUD -->
      <div class="bloques-info" *ngIf="solicitarLongitud">
        <label class="bloques-info-label">{{ 'longitud' | translate }}:</label>
        <label class="bloques-info-valor">{{longitud}}</label>
      </div>
      <!-- ANGULO -->
      <div class="bloques-info" *ngIf="solicitarAngulo">
        <label class="bloques-info-label">{{ 'angulo' | translate }}:</label>
        <label class="bloques-info-valor">{{angulo}} º</label>
      </div>
      <!-- NUM CONTENIDO -->
      <div class="bloques-info" *ngIf="solicitarNumContenido">
        <label class="bloques-info-label">{{ 'numContenido' | translate }}:</label>
        <label class="bloques-info-valor">{{numContenido}}</label>
      </div>
      <!-- SALTO -->
      <div class="bloques-info" *ngIf="solicitarSalto">
        <label class="bloques-info-label">{{ 'salto' | translate }}:</label>
        <label class="bloques-info-valor">{{salto}}</label>
      </div>
      <!-- ID ERP -->
      <div class="bloques-info" *ngIf="solicitarIdErp">
        <label class="bloques-info-label">{{ 'idErp' | translate }}:</label>
        <label class="bloques-info-valor">{{idErp}}</label>
      </div>
      <!-- COSTE -->
      <div class="bloques-info" *ngIf="solicitarCoste">
        <label class="bloques-info-label">{{ 'coste' | translate }}:</label>
        <label class="bloques-info-valor">{{coste}} €</label>
      </div>
      <!-- DESCRIPCION -->
      <div class="bloques-info" *ngIf="descripcionConsumible.length > 0">
        <label class="bloques-info-label">{{ 'descripcion' | translate }}:</label>
        <label class="bloques-info-valor">{{descripcionConsumible}}</label>
      </div>
    </div>
  </div>
</div>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<!-- GRID -->
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <kendo-grid [kendoGridBinding]="jMovimientos"
              [sortable]="true"
              [navigable]="true"
              filterable="menu"
              [rowHeight]="36"
              [height]="750"
              [pageSize]="30"
              kendoGridSelectBy="id"
              scrollable="virtual"
              [selectedKeys]="seleccionados">
    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">
      <button (click)="onClick_Deshechar()" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.consumibles<2">{{ 'baja' | translate}}</button>
      <button (click)="onClick_Mover()" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.consumibles<2">{{ 'mover' | translate}}</button>
    </ng-template>
    <!--ubicacion-->
    <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="ubicacion" title="{{ 'ubicacion' | translate}}"></kendo-grid-column>
    <!--posicion-->
    <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="posicion" title="{{ 'posicion' | translate}}"></kendo-grid-column>
    <!--fechaIni-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="fechaIni" title="{{ 'fechainicio' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaIni | kendoDate }} {{dataItem.fechaIni | kendoDate:'HH:mm:ss' }}</ng-template>
    </kendo-grid-column>
    <!--fechaFin-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="fechaFin" title="{{ 'fechafin' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaFin | kendoDate }} {{dataItem.fechaFin | kendoDate:'HH:mm:ss' }}</ng-template>
    </kendo-grid-column>
    <!--tUsado-->
    <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="tUsado" title="{{ 'tUsado' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ngcontainer> {{ secondsToHms(dataItem.tUsado) }}</ngcontainer>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>

<!-- POPUP: DESHECHAR -->
<div class="popup-cont" *ngIf="popupDeshechar">
  <div class="popup" style="width: 300px;">
    <div class="popup-header">
      <h3>
        <label>{{'baja' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label>{{'bajaseguro_ns' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-center justify-content-end mt-2">
      <button type="button" class="btn btn-primary" (click)="deshechar()" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
      <button type="button" class="btn btn-danger mr-1" (click)="this.popupDeshechar = false;">{{ 'cancelar' | translate }}</button>
    </div>
  </div>
</div>
<!-- POPUP: MOVER -->
<div class="popup-cont" *ngIf="popupMover">
  <div class="popup" style="width: 300px;">
    <div class="popup-header">
      <h3>
        <label>{{'nuevo' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <!-- ALMACEN -->
        <div class="clearfix">
          <label class="crontol-label m-1">{{ 'almacen' | translate }}</label>
          <div class="caja" style="width: 150px">
            <kendo-combobox [data]="JalmacenesMostrar"
                            [textField]="'nombre'"
                            [valueField]="'id'"
                            [(value)]="JalmacenesSelected"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-footer text-center justify-content-end mt-2">
      <button type="button" class="btn btn-primary" (click)="mover()" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
      <button type="button" class="btn btn-danger mr-1" (click)="this.popupMover = false;">{{ 'cancelar' | translate }}</button>
    </div>
  </div>
</div>
<!-- POPUP: Eliminar -->
<div class="popup-cont" *ngIf="popupEliminar">
  <div class="popup" style="width: 300px;">
    <div class="popup-header">
      <h3>
        <label>{{'eliminar' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'preguntaeliminarpopup' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-danger mr-1" (click)="this.popupEliminar = false;">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="eliminarRegistro()" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
<!-- POPUP: ERROR Eliminar -->
<div class="popup-cont" *ngIf="popupErrorAlEliminar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlEliminar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlEliminar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
