import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuService, DisponibilidadService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-turnos-disponibilidad',
  templateUrl: 'turnosDisponibilidad.component.html'
})

export class TurnosDisponibilidadComponent {

  public navigationSubscription;
  public turnos: [];
  public seleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  public user = this.userService.userValue;


  constructor(
    private disponibilidadService: DisponibilidadService,
    private translateService: TranslateService,
    private menuService: MenuService,
    public modalService: NgbModal,
    private userService: UsuariosService,
    public router: Router
  ) {

    this.menuService.titulo = this.translateService.instant('calendarioDisponibilidad').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/turnosDisponibilidad') {
          this.disponibilidadService.Get_Turnos().subscribe((result) => {
            this.turnos = result.data;
          });
        } else {
        }
      }
    });
  }

  ngOnInit() {
    this.disponibilidadService.Get_Turnos().subscribe((result) => {
      this.turnos = result.data;
    });
  }

  onClickNuevo() {
    this.router.navigate(['turnosDisponibilidad/crear/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['turnosDisponibilidad/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['turnosDisponibilidad/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.disponibilidadService.Delete_Turnos({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.disponibilidadService.Get_Turnos().subscribe((result) => {
            this.turnos = result.data;
          });
        }
        this.modalReferenceloading.close();
      }
    );
    this.seleccionados = [];
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
  }

}
