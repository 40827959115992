import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';


const baseUrl = `${environment.apiUrl}/repercusiones`;

@Injectable()
export class RepercusionesService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  public getData(action: string = '', data:string= ''): Observable<any> {
    this.loading = true;
    var d = this.http.get(baseUrl + action + data);
    return d;
  }

  public get_Acciones_Perdidas(idPlanAccion: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_Acciones_Perdidas/`, { idPlanAccion: idPlanAccion }, { withCredentials: true });
  }

  public get_All_Perdidas_Justificadas(fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_All_Perdidas_Justificadas/`, { fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }

  public get_All_Tiempos_Perdidas_Con_No_Justificadas(fechaInicioPeriodoActual: string, fechaFinPeriodoActual: string, fechaInicioPeriodoAnterior: string, fechaFinPeriodoAnterior: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_All_Tiempos_Perdidas_Con_No_Justificadas/`, { fechaInicioPeriodoActual: fechaInicioPeriodoActual, fechaFinPeriodoActual: fechaFinPeriodoActual, fechaInicioPeriodoAnterior: fechaInicioPeriodoAnterior, fechaFinPeriodoAnterior: fechaFinPeriodoAnterior }, { withCredentials: true });
  }

  public get_Acciones_Maquinas(idPlanAccion: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_Acciones_Maquinas/`, { idPlanAccion: idPlanAccion }, { withCredentials: true });
  }

  public get_All_Maquinas_Justificadas(fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_All_Maquinas_Justificadas/`, { fechaInicio: fechaInicio, fechaFin: fechaFin }, { withCredentials: true });
  }
  
}
