import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TurnosService, MenuService, UsuariosService, MaquinasService, AreasProductivasService, OFService, ConfiguracionService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IntlService } from '@progress/kendo-angular-intl';
import { MyFunctions } from '@app/_helpers';
import { GroupResult } from '@progress/kendo-data-query';
import { HostListener } from '@angular/core';
import { MultiSelectComponent } from "@progress/kendo-angular-dropdowns";
import { CellClickEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-turno-detalle',
  templateUrl: './turnoDetalle.component.html'
})
export class TurnoDetalleComponent {
  public id: number;
  public ano_readonly: boolean;
  public numSemana_readonly: boolean;
  public numSemana: number = 1;
  public maxSemanas: number = 53;
  public fechaInicio: Date;
  public fechaFin: Date;
  public horario: any;
  public backspaceIsPressed: boolean = false;

  public time = new Date("10:00");

  public seleccionarTodasMaquinas: boolean = false;

  public valuesEliminar: number[] = [];
  public valuesEliminarAux: number[] = [];
  public botonSeleccionarTodo: string = '';
  public vaciarMaquinas: boolean = false
  //AREA PRODUCTIVA / SECCION
  public secciones: any = [];
  public areasProductivas: any = [];
  public selectedAreaSeccion: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any = [];
  public gruposSeccion: any;
  public selectedGrupo: any;
  public maquinasSecciones: any = [];

  horasDesvio = 1;

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  diasSemana =
    [
      { text: this.translateService.instant('lunes'), value: 'lunes' },
      { text: this.translateService.instant('martes'), value: 'martes' },
      { text: this.translateService.instant('miercoles'), value: 'miercoles' },
      { text: this.translateService.instant('jueves'), value: 'jueves' },
      { text: this.translateService.instant('viernes'), value: 'viernes' },
      { text: this.translateService.instant('sabado'), value: 'sabado' },
      { text: this.translateService.instant('domingo'), value: 'domingo' }
    ];
  turnos =
    [
      { text: this.translateService.instant('manana'), value: 'manana' },
      { text: this.translateService.instant('tarde'), value: 'tarde' },
      { text: this.translateService.instant('noche'), value: 'noche' }
    ];
  todos: any = "todos"
  maquinas = [];
  maquinasEliminarTurno = [];
  listaMaquinasSeccion = [];
  public maquinasEliminarSeleccionadas: any[] = [];
  public turnosSeleccionados: any[] = [];
  public diasSemanaSeleccionados: any[] = [];
  public value: any = [];

  selectedMachine;

  maquinasSeleccionadas = [];

  selectedItem = { text: this.translateService.instant('lunes'), value: 'lunes' };
  selectedItemTurno = { text: this.translateService.instant('manana'), value: 'manana' };
  selectedItemMaq = { text: this.translateService.instant('todos'), value: 'todos' };

  lunesSelected = false;
  martesSelected = false;
  miercolesSelected = false;
  juevesSelected = false;
  viernesSelected = false;
  sabadoSelected = false;
  domingoSelected = false;

  public turnoACopiarSeleccionado: any;
  public turnoACopiar: any = [];

  @ViewChild('popupCopiarDias') popupCopiarDias: NgbModalRef;
  @ViewChild('popupCopiarMaquina') popupCopiarMaquina: NgbModalRef;

  //LOADING
  public loadingDuplicar: boolean = false;

  

  constructor(
    private formBuilder: FormBuilder,
    private turnosService: TurnosService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private intlService: IntlService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private areasProductivasService: AreasProductivasService,
    private oFService: OFService,
    public myFunctions: MyFunctions,
    private configuracionService: ConfiguracionService) {

    this.menuService.titulo = this.translateService.instant('turnos').toUpperCase();

    this.turnoACopiar = [
      { id: 1, nombre: this.translateService.instant("lunes"), key: "lunes" },
      { id: 2, nombre: this.translateService.instant("martes"), key: "martes" },
      { id: 3, nombre: this.translateService.instant("miercoles"), key: "miercoles" },
      { id: 4, nombre: this.translateService.instant("jueves"), key: "jueves" },
      { id: 5, nombre: this.translateService.instant("viernes"), key: "viernes" },
      { id: 6, nombre: this.translateService.instant("sabado"), key: "sabado" },
      { id: 7, nombre: this.translateService.instant("domingo"), key: "domingo" },
    ]
    this.turnoACopiarSeleccionado = this.turnoACopiar[0];
  }
  //
  public horarioPredefinido = [
    {
      lunesMannanaInicio: new Date(2000, 1, 1, 0, 0),
      lunesMannanaFin: new Date(2000, 1, 1, 8, 0),
      lunesTardeInicio: new Date(2000, 1, 1, 8, 0),
      lunesTardeFin: new Date(2000, 1, 1, 16, 0),
      lunesNocheInicio: new Date(2000, 1, 1, 16, 0),
      lunesNocheFin: new Date(2000, 1, 1, 0, 0),

      martesMannanaInicio: new Date(2000, 1, 1, 0, 0),
      martesMannanaFin: new Date(2000, 1, 1, 8, 0),
      martesTardeInicio: new Date(2000, 1, 1, 8, 0),
      martesTardeFin: new Date(2000, 1, 1, 16, 0),
      martesNocheInicio: new Date(2000, 1, 1, 16, 0),
      martesNocheFin: new Date(2000, 1, 1, 0, 0),

      miercolesMannanaInicio: new Date(2000, 1, 1, 0, 0),
      miercolesMannanaFin: new Date(2000, 1, 1, 8, 0),
      miercolesTardeInicio: new Date(2000, 1, 1, 8, 0),
      miercolesTardeFin: new Date(2000, 1, 1, 16, 0),
      miercolesNocheInicio: new Date(2000, 1, 1, 16, 0),
      miercolesNocheFin: new Date(2000, 1, 1, 0, 0),

      juevesMannanaInicio: new Date(2000, 1, 1, 0, 0),
      juevesMannanaFin: new Date(2000, 1, 1, 8, 0),
      juevesTardeInicio: new Date(2000, 1, 1, 8, 0),
      juevesTardeFin: new Date(2000, 1, 1, 16, 0),
      juevesNocheInicio: new Date(2000, 1, 1, 16, 0),
      juevesNocheFin: new Date(2000, 1, 1, 0, 0),

      viernesMannanaInicio: new Date(2000, 1, 1, 0, 0),
      viernesMannanaFin: new Date(2000, 1, 1, 8, 0),
      viernesTardeInicio: new Date(2000, 1, 1, 8, 0),
      viernesTardeFin: new Date(2000, 1, 1, 16, 0),
      viernesNocheInicio: new Date(2000, 1, 1, 16, 0),
      viernesNocheFin: new Date(2000, 1, 1, 0, 0),

      sabadoMannanaInicio: new Date(2000, 1, 1, 0, 0),
      sabadoMannanaFin: new Date(2000, 1, 1, 8, 0),
      sabadoTardeInicio: new Date(2000, 1, 1, 8, 0),
      sabadoTardeFin: new Date(2000, 1, 1, 16, 0),
      sabadoNocheInicio: new Date(2000, 1, 1, 16, 0),
      sabadoNocheFin: new Date(2000, 1, 1, 0, 0),

      domingoMannanaInicio: new Date(2000, 1, 1, 0, 0),
      domingoMannanaFin: new Date(2000, 1, 1, 8, 0),
      domingoTardeInicio: new Date(2000, 1, 1, 8, 0),
      domingoTardeFin: new Date(2000, 1, 1, 16, 0),
      domingoNocheInicio: new Date(2000, 1, 1, 16, 0),
      domingoNocheFin: new Date(2000, 1, 1, 0, 0),
    }
  ];
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      ano: ['2020', Validators.required],
      numSemana: ['1', Validators.required],
      numDuplicarSemana: [1, Validators.required],
    });

    this.getDateOfISOWeek(this.form.value.numSemana, this.form.value.ano)

    this.valuesEliminar = [];
    this.valuesEliminarAux = [];
    this.seleccionarTodasMaquinas = false;
    this.botonSeleccionarTodo = this.translateService.instant('seleccionarTodo');
    if (this.isAddMode) {
      this.ano_readonly = false;
      this.numSemana_readonly = false;
      this.configuracionService.getConfiguracionTurnos().subscribe((res: any) => {
        if(res != null && res.length > 0){
          var turnos = res[0];
          this.horarioPredefinido[0].lunesMannanaInicio = new Date(2000, 1, 1, turnos.lunesMannanaInicio.split(":")[0], turnos.lunesMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].lunesMannanaFin = new Date(2000, 1, 1, turnos.lunesMannanaFin.split(":")[0], turnos.lunesMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].lunesTardeInicio = new Date(2000, 1, 1, turnos.lunesTardeInicio.split(":")[0], turnos.lunesTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].lunesTardeFin = new Date(2000, 1, 1, turnos.lunesTardeFin.split(":")[0], turnos.lunesTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].lunesNocheInicio = new Date(2000, 1, 1, turnos.lunesNocheInicio.split(":")[0], turnos.lunesNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].lunesNocheFin = new Date(2000, 1, 1, turnos.lunesNocheFin.split(":")[0], turnos.lunesNocheFin.split(":")[1], 0);
  
          this.horarioPredefinido[0].martesMannanaInicio = new Date(2000, 1, 1, turnos.martesMannanaInicio.split(":")[0], turnos.martesMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].martesMannanaFin = new Date(2000, 1, 1, turnos.martesMannanaFin.split(":")[0], turnos.martesMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].martesTardeInicio = new Date(2000, 1, 1, turnos.martesTardeInicio.split(":")[0], turnos.martesTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].martesTardeFin = new Date(2000, 1, 1, turnos.martesTardeFin.split(":")[0], turnos.martesTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].martesNocheInicio = new Date(2000, 1, 1, turnos.martesNocheInicio.split(":")[0], turnos.martesNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].martesNocheFin = new Date(2000, 1, 1, turnos.martesNocheFin.split(":")[0], turnos.martesNocheFin.split(":")[1], 0);
  
          this.horarioPredefinido[0].miercolesMannanaInicio = new Date(2000, 1, 1, turnos.miercolesMannanaInicio.split(":")[0], turnos.miercolesMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].miercolesMannanaFin = new Date(2000, 1, 1, turnos.miercolesMannanaFin.split(":")[0], turnos.miercolesMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].miercolesTardeInicio = new Date(2000, 1, 1, turnos.miercolesTardeInicio.split(":")[0], turnos.miercolesTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].miercolesTardeFin = new Date(2000, 1, 1, turnos.miercolesTardeFin.split(":")[0], turnos.miercolesTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].miercolesNocheInicio = new Date(2000, 1, 1, turnos.miercolesNocheInicio.split(":")[0], turnos.miercolesNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].miercolesNocheFin = new Date(2000, 1, 1, turnos.miercolesNocheFin.split(":")[0], turnos.miercolesNocheFin.split(":")[1], 0);
  
          this.horarioPredefinido[0].juevesMannanaInicio = new Date(2000, 1, 1, turnos.juevesMannanaInicio.split(":")[0], turnos.juevesMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].juevesMannanaFin = new Date(2000, 1, 1, turnos.juevesMannanaFin.split(":")[0], turnos.juevesMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].juevesTardeInicio = new Date(2000, 1, 1, turnos.juevesTardeInicio.split(":")[0], turnos.juevesTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].juevesTardeFin = new Date(2000, 1, 1, turnos.juevesTardeFin.split(":")[0], turnos.juevesTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].juevesNocheInicio = new Date(2000, 1, 1, turnos.juevesNocheInicio.split(":")[0], turnos.juevesNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].juevesNocheFin = new Date(2000, 1, 1, turnos.juevesNocheFin.split(":")[0], turnos.juevesNocheFin.split(":")[1], 0);
  
          this.horarioPredefinido[0].viernesMannanaInicio = new Date(2000, 1, 1, turnos.viernesMannanaInicio.split(":")[0], turnos.viernesMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].viernesMannanaFin = new Date(2000, 1, 1, turnos.viernesMannanaFin.split(":")[0], turnos.viernesMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].viernesTardeInicio = new Date(2000, 1, 1, turnos.viernesTardeInicio.split(":")[0], turnos.viernesTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].viernesTardeFin = new Date(2000, 1, 1, turnos.viernesTardeFin.split(":")[0], turnos.viernesTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].viernesNocheInicio = new Date(2000, 1, 1, turnos.viernesNocheInicio.split(":")[0], turnos.viernesNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].viernesNocheFin = new Date(2000, 1, 1, turnos.viernesNocheFin.split(":")[0], turnos.viernesNocheFin.split(":")[1], 0);
  
          this.horarioPredefinido[0].sabadoMannanaInicio = new Date(2000, 1, 1, turnos.sabadoMannanaInicio.split(":")[0], turnos.sabadoMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].sabadoMannanaFin = new Date(2000, 1, 1, turnos.sabadoMannanaFin.split(":")[0], turnos.sabadoMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].sabadoTardeInicio = new Date(2000, 1, 1, turnos.sabadoTardeInicio.split(":")[0], turnos.sabadoTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].sabadoTardeFin = new Date(2000, 1, 1, turnos.sabadoTardeFin.split(":")[0], turnos.sabadoTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].sabadoNocheInicio = new Date(2000, 1, 1, turnos.sabadoNocheInicio.split(":")[0], turnos.sabadoNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].sabadoNocheFin = new Date(2000, 1, 1, turnos.sabadoNocheFin.split(":")[0], turnos.sabadoNocheFin.split(":")[1], 0);
  
          this.horarioPredefinido[0].domingoMannanaInicio = new Date(2000, 1, 1, turnos.domingoMannanaInicio.split(":")[0], turnos.domingoMannanaInicio.split(":")[1], 0);
          this.horarioPredefinido[0].domingoMannanaFin = new Date(2000, 1, 1, turnos.domingoMannanaFin.split(":")[0], turnos.domingoMannanaFin.split(":")[1], 0);
          this.horarioPredefinido[0].domingoTardeInicio = new Date(2000, 1, 1, turnos.domingoTardeInicio.split(":")[0], turnos.domingoTardeInicio.split(":")[1], 0);
          this.horarioPredefinido[0].domingoTardeFin = new Date(2000, 1, 1, turnos.domingoTardeFin.split(":")[0], turnos.domingoTardeFin.split(":")[1], 0);
          this.horarioPredefinido[0].domingoNocheInicio = new Date(2000, 1, 1, turnos.domingoNocheInicio.split(":")[0], turnos.domingoNocheInicio.split(":")[1], 0);
          this.horarioPredefinido[0].domingoNocheFin = new Date(2000, 1, 1, turnos.domingoNocheFin.split(":")[0], turnos.domingoNocheFin.split(":")[1], 0);
        }
        this.getMaquinasInstalaciones();
      })
      

    } else {
      this.ano_readonly = true;
      this.numSemana_readonly = true;

      this.turnosService.GetById(this.id).pipe().subscribe((result) => {
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          ano: [result.data[0].anno, Validators.required],
          numSemana: [result.data[0].numSemana, Validators.required],
          numDuplicarSemana: [1, Validators.required],
        });
        this.numSemana = result.data[0].numSemana;

        this.fechaInicio = new Date(result.data[0].fechaInicio);
        this.fechaFin = new Date(result.data[0].fechaFin);

        this.horasDesvio = this.user.desvioTimezone - 1;//Le restamos una hora porque la hora de los turnos en la bd se guarda en el horario central europeo
        //6-14, 14-22

        this.turnosService.Get_Turno(this.id).subscribe((result) => {

          var an: any = result.data;
          an.forEach(f => {
            this.maquinas.push({ text: f.maquina, value: f.maquina, selected: false });
            this.maquinasEliminarTurno.push({ text: f.maquina, value: f.maquina });
            this.listaMaquinasSeccion.push({ text: f.maquina, value: f.maquina });

            this.maquinasSeleccionadas.push(false);

            f.lunesMannanaInicio = new Date(2000, 1, 1, f.lunesMannanaInicio.split(":")[0], f.lunesMannanaInicio.split(":")[1], 0);
            f.lunesMannanaFin = new Date(2000, 1, 1, f.lunesMannanaFin.split(":")[0], f.lunesMannanaFin.split(":")[1], 0);
            f.lunesTardeInicio = new Date(2000, 1, 1, f.lunesTardeInicio.split(":")[0], f.lunesTardeInicio.split(":")[1], 0);
            f.lunesTardeFin = new Date(2000, 1, 1, f.lunesTardeFin.split(":")[0], f.lunesTardeFin.split(":")[1], 0);
            f.lunesNocheInicio = new Date(2000, 1, 1, f.lunesNocheInicio.split(":")[0], f.lunesNocheInicio.split(":")[1], 0);
            f.lunesNocheFin = new Date(2000, 1, 1, f.lunesNocheFin.split(":")[0], f.lunesNocheFin.split(":")[1], 0);

            f.martesMannanaInicio = new Date(2000, 1, 1, f.martesMannanaInicio.split(":")[0], f.martesMannanaInicio.split(":")[1], 0);
            f.martesMannanaFin = new Date(2000, 1, 1, f.martesMannanaFin.split(":")[0], f.martesMannanaFin.split(":")[1], 0);
            f.martesTardeInicio = new Date(2000, 1, 1, f.martesTardeInicio.split(":")[0], f.martesTardeInicio.split(":")[1], 0);
            f.martesTardeFin = new Date(2000, 1, 1, f.martesTardeFin.split(":")[0], f.martesTardeFin.split(":")[1], 0);
            f.martesNocheInicio = new Date(2000, 1, 1, f.martesNocheInicio.split(":")[0], f.martesNocheInicio.split(":")[1], 0);
            f.martesNocheFin = new Date(2000, 1, 1, f.martesNocheFin.split(":")[0], f.martesNocheFin.split(":")[1], 0);

            f.miercolesMannanaInicio = new Date(2000, 1, 1, f.miercolesMannanaInicio.split(":")[0], f.miercolesMannanaInicio.split(":")[1], 0);
            f.miercolesMannanaFin = new Date(2000, 1, 1, f.miercolesMannanaFin.split(":")[0], f.miercolesMannanaFin.split(":")[1], 0);
            f.miercolesTardeInicio = new Date(2000, 1, 1, f.miercolesTardeInicio.split(":")[0], f.miercolesTardeInicio.split(":")[1], 0);
            f.miercolesTardeFin = new Date(2000, 1, 1, f.miercolesTardeFin.split(":")[0], f.miercolesTardeFin.split(":")[1], 0);
            f.miercolesNocheInicio = new Date(2000, 1, 1, f.miercolesNocheInicio.split(":")[0], f.miercolesNocheInicio.split(":")[1], 0);
            f.miercolesNocheFin = new Date(2000, 1, 1, f.miercolesNocheFin.split(":")[0], f.miercolesNocheFin.split(":")[1], 0);

            f.juevesMannanaInicio = new Date(2000, 1, 1, f.juevesMannanaInicio.split(":")[0], f.juevesMannanaInicio.split(":")[1], 0);
            f.juevesMannanaFin = new Date(2000, 1, 1, f.juevesMannanaFin.split(":")[0], f.juevesMannanaFin.split(":")[1], 0);
            f.juevesTardeInicio = new Date(2000, 1, 1, f.juevesTardeInicio.split(":")[0], f.juevesTardeInicio.split(":")[1], 0);
            f.juevesTardeFin = new Date(2000, 1, 1, f.juevesTardeFin.split(":")[0], f.juevesTardeFin.split(":")[1], 0);
            f.juevesNocheInicio = new Date(2000, 1, 1, f.juevesNocheInicio.split(":")[0], f.juevesNocheInicio.split(":")[1], 0);
            f.juevesNocheFin = new Date(2000, 1, 1, f.juevesNocheFin.split(":")[0], f.juevesNocheFin.split(":")[1], 0);

            f.viernesMannanaInicio = new Date(2000, 1, 1, f.viernesMannanaInicio.split(":")[0], f.viernesMannanaInicio.split(":")[1], 0);
            f.viernesMannanaFin = new Date(2000, 1, 1, f.viernesMannanaFin.split(":")[0], f.viernesMannanaFin.split(":")[1], 0);
            f.viernesTardeInicio = new Date(2000, 1, 1, f.viernesTardeInicio.split(":")[0], f.viernesTardeInicio.split(":")[1], 0);
            f.viernesTardeFin = new Date(2000, 1, 1, f.viernesTardeFin.split(":")[0], f.viernesTardeFin.split(":")[1], 0);
            f.viernesNocheInicio = new Date(2000, 1, 1, f.viernesNocheInicio.split(":")[0], f.viernesNocheInicio.split(":")[1], 0);
            f.viernesNocheFin = new Date(2000, 1, 1, f.viernesNocheFin.split(":")[0], f.viernesNocheFin.split(":")[1], 0);

            f.sabadoMannanaInicio = new Date(2000, 1, 1, f.sabadoMannanaInicio.split(":")[0], f.sabadoMannanaInicio.split(":")[1], 0);
            f.sabadoMannanaFin = new Date(2000, 1, 1, f.sabadoMannanaFin.split(":")[0], f.sabadoMannanaFin.split(":")[1], 0);
            f.sabadoTardeInicio = new Date(2000, 1, 1, f.sabadoTardeInicio.split(":")[0], f.sabadoTardeInicio.split(":")[1], 0);
            f.sabadoTardeFin = new Date(2000, 1, 1, f.sabadoTardeFin.split(":")[0], f.sabadoTardeFin.split(":")[1], 0);
            f.sabadoNocheInicio = new Date(2000, 1, 1, f.sabadoNocheInicio.split(":")[0], f.sabadoNocheInicio.split(":")[1], 0);
            f.sabadoNocheFin = new Date(2000, 1, 1, f.sabadoNocheFin.split(":")[0], f.sabadoNocheFin.split(":")[1], 0);

            f.domingoMannanaInicio = new Date(2000, 1, 1, f.domingoMannanaInicio.split(":")[0], f.domingoMannanaInicio.split(":")[1], 0);
            f.domingoMannanaFin = new Date(2000, 1, 1, f.domingoMannanaFin.split(":")[0], f.domingoMannanaFin.split(":")[1], 0);
            f.domingoTardeInicio = new Date(2000, 1, 1, f.domingoTardeInicio.split(":")[0], f.domingoTardeInicio.split(":")[1], 0);
            f.domingoTardeFin = new Date(2000, 1, 1, f.domingoTardeFin.split(":")[0], f.domingoTardeFin.split(":")[1], 0);
            f.domingoNocheInicio = new Date(2000, 1, 1, f.domingoNocheInicio.split(":")[0], f.domingoNocheInicio.split(":")[1], 0);
            f.domingoNocheFin = new Date(2000, 1, 1, f.domingoNocheFin.split(":")[0], f.domingoNocheFin.split(":")[1], 0);

            //Le cambiamos la hora segun el timezon (le sumamos el desvio)
            //f.lunesMannanaInicio.setTime(f.lunesMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.lunesMannanaFin.setTime(f.lunesMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.lunesTardeInicio.setTime(f.lunesTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.lunesTardeFin.setTime(f.lunesTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.lunesNocheInicio.setTime(f.lunesNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.lunesNocheFin.setTime(f.lunesNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

            //f.martesMannanaInicio.setTime(f.martesMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.martesMannanaFin.setTime(f.martesMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.martesTardeInicio.setTime(f.martesTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.martesTardeFin.setTime(f.martesTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.martesNocheInicio.setTime(f.martesNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.martesNocheFin.setTime(f.martesNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

            //f.miercolesMannanaInicio.setTime(f.miercolesMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.miercolesMannanaFin.setTime(f.miercolesMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.miercolesTardeInicio.setTime(f.miercolesTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.miercolesTardeFin.setTime(f.miercolesTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.miercolesNocheInicio.setTime(f.miercolesNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.miercolesNocheFin.setTime(f.miercolesNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

            //f.juevesMannanaInicio.setTime(f.juevesMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.juevesMannanaFin.setTime(f.juevesMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.juevesTardeInicio.setTime(f.juevesTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.juevesTardeFin.setTime(f.juevesTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.juevesNocheInicio.setTime(f.juevesNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.juevesNocheFin.setTime(f.juevesNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

            //f.viernesMannanaInicio.setTime(f.viernesMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.viernesMannanaFin.setTime(f.viernesMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.viernesTardeInicio.setTime(f.viernesTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.viernesTardeFin.setTime(f.viernesTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.viernesNocheInicio.setTime(f.viernesNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.viernesNocheFin.setTime(f.viernesNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

            //f.sabadoMannanaInicio.setTime(f.sabadoMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.sabadoMannanaFin.setTime(f.sabadoMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.sabadoTardeInicio.setTime(f.sabadoTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.sabadoTardeFin.setTime(f.sabadoTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.sabadoNocheInicio.setTime(f.sabadoNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.sabadoNocheFin.setTime(f.sabadoNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

            //f.domingoMannanaInicio.setTime(f.domingoMannanaInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.domingoMannanaFin.setTime(f.domingoMannanaFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.domingoTardeInicio.setTime(f.domingoTardeInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.domingoTardeFin.setTime(f.domingoTardeFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.domingoNocheInicio.setTime(f.domingoNocheInicio.getTime() + (this.horasDesvio * 60 * 60 * 1000));
            //f.domingoNocheFin.setTime(f.domingoNocheFin.getTime() + (this.horasDesvio * 60 * 60 * 1000));

          });
          this.selectedMachine = this.maquinas[0];
          this.areasProductivasService.Get_AreasConSecciones(this.user.id).subscribe((json) => {

            var areas: any = json;
            var data = [];
            this.selectedAreaSeccion = [];

            areas.forEach(area => {
              // var areaSeccion = {
              //   idArea: area.idAreaProductiva,
              //   nombreArea: area.nombreAreaProductiva,
              //   idSeccion: area.idSeccion,
              //   nombreSeccion: area.nombreSeccion
              // }
              // data.push(areaSeccion);
              this.areasProductivas.push(area);
            });
            this.selectedAreaSeccion.push(this.areasProductivas[0]);

            this.oFService.GetGruposMaquinas_SeguimientoProyecto().subscribe(data => {
              var gruposAux: any = data;
              var dato = [];
              this.selectedGrupo = [];

              gruposAux.forEach(grupo => {
                var areaSeccion = {
                  idGrupo: grupo.id,
                  nombreGrupo: grupo.nombre
                }
                dato.push(areaSeccion);
                this.grupos.push(grupo);
              });
              this.selectedGrupo.push(this.grupos[0]);
              //this.grupos = dato;

              this.maquinasService.Get_maquinas_instalaciones_seccion().subscribe(result => {
                var dataMaquinas: any = result;
                var aurk = false;
                dataMaquinas.forEach(maquina => {
                  aurk = false;
                  if (this.maquinasSecciones.length > 0) {
                    this.maquinasSecciones.forEach(listaFinal => {
                      if (listaFinal.id == maquina.id) {
                        aurk = true;
                        if (!listaFinal.idGrupo.toString().includes("," + maquina.idGrupo.toString() + ",")) {
                          listaFinal.idGrupo = listaFinal.idGrupo.toString() + maquina.idGrupo.toString() + ",";
                        }
                      }
                    });
                  }
                  if (!aurk) {
                    maquina.idGrupo = "," + maquina.idGrupo.toString() + ",";
                    this.maquinasSecciones.push(maquina);
                  }
                });
                // this.maquinasSecciones = dataMaquinas;

                this.cambiarAreaSeccion();
              });

            });
          });
          console.log(this.maquinasEliminarTurno)
          console.log(this.maquinas)
          this.horario = result.data;
        });
      })
    }
  }

  getMaquinasInstalaciones(){
    this.maquinasService.GetMaquinasInstalacionesTurnos().subscribe((json) => {

      var horario = [];

      var an: any = json;
      an.forEach(f => {
        this.maquinas.push({ text: f.nombre, value: f.nombre, selected: false });
        this.maquinasEliminarTurno.push({ text: f.nombre, value: f.nombre });
        this.listaMaquinasSeccion.push({ text: f.nombre, value: f.nombre });
        this.maquinasSeleccionadas.push(false);
        var maquina = {
          id: 0,
          idCalendarioSemanas: 0,
          idMaquina: f.id,
          maquina: f.nombre,

          lunesMannanaInicio: this.horarioPredefinido[0].lunesMannanaInicio,
          lunesMannanaFin: this.horarioPredefinido[0].lunesMannanaFin,
          lunesTardeInicio: this.horarioPredefinido[0].lunesTardeInicio,
          lunesTardeFin: this.horarioPredefinido[0].lunesTardeFin,
          lunesNocheInicio: this.horarioPredefinido[0].lunesNocheInicio,
          lunesNocheFin: this.horarioPredefinido[0].lunesNocheFin,

          martesMannanaInicio: this.horarioPredefinido[0].martesMannanaInicio,
          martesMannanaFin: this.horarioPredefinido[0].martesMannanaFin,
          martesTardeInicio: this.horarioPredefinido[0].martesTardeInicio,
          martesTardeFin: this.horarioPredefinido[0].martesTardeFin,
          martesNocheInicio: this.horarioPredefinido[0].martesNocheInicio,
          martesNocheFin: this.horarioPredefinido[0].martesNocheFin,

          miercolesMannanaInicio: this.horarioPredefinido[0].miercolesMannanaInicio,
          miercolesMannanaFin: this.horarioPredefinido[0].miercolesMannanaFin,
          miercolesTardeInicio: this.horarioPredefinido[0].miercolesTardeInicio,
          miercolesTardeFin: this.horarioPredefinido[0].miercolesTardeFin,
          miercolesNocheInicio: this.horarioPredefinido[0].miercolesNocheInicio,
          miercolesNocheFin: this.horarioPredefinido[0].miercolesNocheFin,

          juevesMannanaInicio: this.horarioPredefinido[0].juevesMannanaInicio,
          juevesMannanaFin: this.horarioPredefinido[0].juevesMannanaFin,
          juevesTardeInicio: this.horarioPredefinido[0].juevesTardeInicio,
          juevesTardeFin: this.horarioPredefinido[0].juevesTardeFin,
          juevesNocheInicio: this.horarioPredefinido[0].juevesNocheInicio,
          juevesNocheFin: this.horarioPredefinido[0].juevesNocheFin,

          viernesMannanaInicio: this.horarioPredefinido[0].viernesMannanaInicio,
          viernesMannanaFin: this.horarioPredefinido[0].viernesMannanaFin,
          viernesTardeInicio: this.horarioPredefinido[0].viernesTardeInicio,
          viernesTardeFin: this.horarioPredefinido[0].viernesTardeFin,
          viernesNocheInicio: this.horarioPredefinido[0].viernesNocheInicio,
          viernesNocheFin: this.horarioPredefinido[0].viernesNocheFin,

          sabadoMannanaInicio: this.horarioPredefinido[0].sabadoMannanaInicio,
          sabadoMannanaFin: this.horarioPredefinido[0].sabadoMannanaFin,
          sabadoTardeInicio: this.horarioPredefinido[0].sabadoTardeInicio,
          sabadoTardeFin: this.horarioPredefinido[0].sabadoTardeFin,
          sabadoNocheInicio: this.horarioPredefinido[0].sabadoNocheInicio,
          sabadoNocheFin: this.horarioPredefinido[0].sabadoNocheFin,

          domingoMannanaInicio: this.horarioPredefinido[0].domingoMannanaInicio,
          domingoMannanaFin: this.horarioPredefinido[0].domingoMannanaFin,
          domingoTardeInicio: this.horarioPredefinido[0].domingoTardeInicio,
          domingoTardeFin: this.horarioPredefinido[0].domingoTardeFin,
          domingoNocheInicio: this.horarioPredefinido[0].domingoNocheInicio,
          domingoNocheFin: this.horarioPredefinido[0].domingoNocheFin,
        };

        horario.push(maquina)
      });
      this.horario = horario;
      this.selectedMachine = this.maquinas[0];
      this.areasProductivasService.Get_AreasConSecciones(this.user.id).subscribe((json) => {

        var areas: any = json;
        var data = [];
        this.selectedAreaSeccion = [];

        areas.forEach(area => {
          // var areaSeccion = {
          //   idArea: area.idAreaProductiva,
          //   nombreArea: area.nombreAreaProductiva,
          //   idSeccion: area.idSeccion,
          //   nombreSeccion: area.nombreSeccion
          // }
          // data.push(areaSeccion);
          this.areasProductivas.push(area);
        });
        this.selectedAreaSeccion.push(this.areasProductivas[0]);

        this.oFService.GetGruposMaquinas_SeguimientoProyecto().subscribe(data => {
          var grupos: any = data;
          var dato = [];
          this.selectedGrupo = [];

          grupos.forEach(grupo => {
            var areaSeccion = {
              idGrupo: grupo.id,
              nombreGrupo: grupo.nombre
            }
            dato.push(areaSeccion);
            this.grupos.push(grupo);
          });
          this.selectedGrupo.push(this.grupos[0]);
          //this.grupos = dato;
          this.maquinasService.Get_maquinas_instalaciones_seccion().subscribe(result => {
            var dataMaquinas: any = result;

            this.maquinasSecciones = data;

            this.cambiarAreaSeccion();
          });
        });
      });

    });
  }
  eliminarDia(event: any) {
    this.horario.forEach(maquina => {
      this.valuesEliminar.forEach(maquinaSeleccionada => {
        if (maquina.maquina == maquinaSeleccionada) {
          var keys = Object.keys(maquina);
          this.diasSemanaSeleccionados.forEach(diaSeleccionado => {
            this.turnosSeleccionados.forEach(turnoSeleccionado => {
              if (turnoSeleccionado.value == 'manana') {
                maquina[diaSeleccionado.value + "MannanaInicio"] = new Date(2000, 1, 1, 0, 0, 0);
                maquina[diaSeleccionado.value + "MannanaFin"] = new Date(2000, 1, 1, 0, 0, 0);
              } else if (turnoSeleccionado.value == 'tarde') {
                maquina[diaSeleccionado.value + "TardeInicio"] = new Date(2000, 1, 1, 0, 0, 0);
                maquina[diaSeleccionado.value + "TardeFin"] = new Date(2000, 1, 1, 0, 0, 0);
              } else if (turnoSeleccionado.value == 'noche') {
                maquina[diaSeleccionado.value + "NocheInicio"] = new Date(2000, 1, 1, 0, 0, 0);
                maquina[diaSeleccionado.value + "NocheFin"] = new Date(2000, 1, 1, 0, 0, 0);
              }

            });
          });
        }
      });
    });
    this.modalReference.close();
  }

  copiarDias() {
    this.horario.forEach(maquina => {
      var keysMaquina = Object.keys(maquina);
      var keysOfMonday = keysMaquina.filter(x => x.includes(this.turnoACopiarSeleccionado.key));
      keysOfMonday.forEach(key => {
        if (this.lunesSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'lunes');
          maquina[auxKey] = maquina[key];
        }
        if (this.martesSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'martes');
          maquina[auxKey] = maquina[key];
        }
        if (this.miercolesSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'miercoles');
          maquina[auxKey] = maquina[key];
        }
        if (this.juevesSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'jueves');
          maquina[auxKey] = maquina[key];
        }
        if (this.viernesSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'viernes');
          maquina[auxKey] = maquina[key];
        }
        if (this.sabadoSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'sabado');
          maquina[auxKey] = maquina[key];
        }
        if (this.domingoSelected) {
          var auxKey = key.replace(this.turnoACopiarSeleccionado.key, 'domingo');
          maquina[auxKey] = maquina[key];
        }
      });
    });
    this.modalReference.close();
  }

  copiarMaquinas() {
    var maquinaSelected = this.horario.filter(x => x.maquina == this.selectedMachine.value)[0];
    var keysMaquina = Object.keys(maquinaSelected);
    keysMaquina = keysMaquina.filter(key => key.includes("Mannana") || key.includes("Tarde") || key.includes("Noche"));
    this.horario.forEach((maquina, index) => {
      if (maquinaSelected.maquina !== maquina.maquina) {
        this.maquinas.forEach(maquinaLista => {
          if (maquina.maquina == maquinaLista.text && maquinaLista.selected) { //si está seleccionada para copiarse en ella los datos de la otra
            keysMaquina.forEach(key => {
              maquina[key] = maquinaSelected[key];
            });
          }
        });
      }
    });
    //   if (this.maquinasSeleccionadas[index]) { }
    this.modalReference.close();
  }

  seleccionarTodas(e) {
    if (!e) {
      this.seleccionarTodasMaquinas = false;
      // this.maquinasSeleccionadas.forEach((maquina, index) => {
      //   this.maquinasSeleccionadas[index] = false;
      // });
      this.maquinas.forEach(
        (maquina) => {
          maquina.selected = false;
        });
    } else {
      this.seleccionarTodasMaquinas = true;
      // this.maquinasSeleccionadas.forEach((maquina, index) => {
      //   this.maquinasSeleccionadas[index] = true;
      // });
      this.maquinas.forEach(
        (maquina) => {
          maquina.selected = true;
        });
    }
  }

  cambioSeleccionCopiar() {
    var dif = false;
    if (this.seleccionarTodasMaquinas) {
      this.maquinas.forEach(maquina => {
        if (maquina.selected == false)
          dif = true;
      });
      if (dif) {
        this.seleccionarTodasMaquinas = false;
      }
    } else {
      this.maquinas.forEach(maquina => {
        if (maquina.selected == false)
          dif = true;
      });
      if (!dif) {
        this.seleccionarTodasMaquinas = true;
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    this.form.value.numSemana = this.numSemana;

    this.turnosService.Insert(this.form.value.ano, this.form.value.numSemana, this.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
      if (result.error == false) {

        var dt: any = [];

        this.horario.forEach(f => {

          //Le cambiamos la hora segun el timezon (le restamos el desvio)
          //f.lunesMannanaInicio.setTime(f.lunesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.lunesMannanaFin.setTime(f.lunesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.lunesTardeInicio.setTime(f.lunesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.lunesTardeFin.setTime(f.lunesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.lunesNocheInicio.setTime(f.lunesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.lunesNocheFin.setTime(f.lunesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          //f.martesMannanaInicio.setTime(f.martesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.martesMannanaFin.setTime(f.martesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.martesTardeInicio.setTime(f.martesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.martesTardeFin.setTime(f.martesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.martesNocheInicio.setTime(f.martesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.martesNocheFin.setTime(f.martesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          //f.miercolesMannanaInicio.setTime(f.miercolesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.miercolesMannanaFin.setTime(f.miercolesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.miercolesTardeInicio.setTime(f.miercolesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.miercolesTardeFin.setTime(f.miercolesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.miercolesNocheInicio.setTime(f.miercolesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.miercolesNocheFin.setTime(f.miercolesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          //f.juevesMannanaInicio.setTime(f.juevesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.juevesMannanaFin.setTime(f.juevesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.juevesTardeInicio.setTime(f.juevesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.juevesTardeFin.setTime(f.juevesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.juevesNocheInicio.setTime(f.juevesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.juevesNocheFin.setTime(f.juevesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          //f.viernesMannanaInicio.setTime(f.viernesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.viernesMannanaFin.setTime(f.viernesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.viernesTardeInicio.setTime(f.viernesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.viernesTardeFin.setTime(f.viernesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.viernesNocheInicio.setTime(f.viernesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.viernesNocheFin.setTime(f.viernesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          //f.sabadoMannanaInicio.setTime(f.sabadoMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.sabadoMannanaFin.setTime(f.sabadoMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.sabadoTardeInicio.setTime(f.sabadoTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.sabadoTardeFin.setTime(f.sabadoTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.sabadoNocheInicio.setTime(f.sabadoNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.sabadoNocheFin.setTime(f.sabadoNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          //f.domingoMannanaInicio.setTime(f.domingoMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.domingoMannanaFin.setTime(f.domingoMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.domingoTardeInicio.setTime(f.domingoTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.domingoTardeFin.setTime(f.domingoTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.domingoNocheInicio.setTime(f.domingoNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
          //f.domingoNocheFin.setTime(f.domingoNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

          dt.push(
            {
              idCalendarioSemanas: result.id,
              idMaquina: f.idMaquina,
              lunesMannanaInicio: this.dateToHHmm(f.lunesMannanaInicio),
              lunesMannanaFin: this.dateToHHmm(f.lunesMannanaFin),
              lunesTardeInicio: this.dateToHHmm(f.lunesTardeInicio),
              lunesTardeFin: this.dateToHHmm(f.lunesTardeFin),
              lunesNocheInicio: this.dateToHHmm(f.lunesNocheInicio),
              lunesNocheFin: this.dateToHHmm(f.lunesNocheFin),
              martesMannanaInicio: this.dateToHHmm(f.martesMannanaInicio),
              martesMannanaFin: this.dateToHHmm(f.martesMannanaFin),
              martesTardeInicio: this.dateToHHmm(f.martesTardeInicio),
              martesTardeFin: this.dateToHHmm(f.martesTardeFin),
              martesNocheInicio: this.dateToHHmm(f.martesNocheInicio),
              martesNocheFin: this.dateToHHmm(f.martesNocheFin),
              miercolesMannanaInicio: this.dateToHHmm(f.miercolesMannanaInicio),
              miercolesMannanaFin: this.dateToHHmm(f.miercolesMannanaFin),
              miercolesTardeInicio: this.dateToHHmm(f.miercolesTardeInicio),
              miercolesTardeFin: this.dateToHHmm(f.miercolesTardeFin),
              miercolesNocheInicio: this.dateToHHmm(f.miercolesNocheInicio),
              miercolesNocheFin: this.dateToHHmm(f.miercolesNocheFin),
              juevesMannanaInicio: this.dateToHHmm(f.juevesMannanaInicio),
              juevesMannanaFin: this.dateToHHmm(f.juevesMannanaFin),
              juevesTardeInicio: this.dateToHHmm(f.juevesTardeInicio),
              juevesTardeFin: this.dateToHHmm(f.juevesTardeFin),
              juevesNocheInicio: this.dateToHHmm(f.juevesNocheInicio),
              juevesNocheFin: this.dateToHHmm(f.juevesNocheFin),
              viernesMannanaInicio: this.dateToHHmm(f.viernesMannanaInicio),
              viernesMannanaFin: this.dateToHHmm(f.viernesMannanaFin),
              viernesTardeInicio: this.dateToHHmm(f.viernesTardeInicio),
              viernesTardeFin: this.dateToHHmm(f.viernesTardeFin),
              viernesNocheInicio: this.dateToHHmm(f.viernesNocheInicio),
              viernesNocheFin: this.dateToHHmm(f.viernesNocheFin),
              sabadoMannanaInicio: this.dateToHHmm(f.sabadoMannanaInicio),
              sabadoMannanaFin: this.dateToHHmm(f.sabadoMannanaFin),
              sabadoTardeInicio: this.dateToHHmm(f.sabadoTardeInicio),
              sabadoTardeFin: this.dateToHHmm(f.sabadoTardeFin),
              sabadoNocheInicio: this.dateToHHmm(f.sabadoNocheInicio),
              sabadoNocheFin: this.dateToHHmm(f.sabadoNocheFin),
              domingoMannanaInicio: this.dateToHHmm(f.domingoMannanaInicio),
              domingoMannanaFin: this.dateToHHmm(f.domingoMannanaFin),
              domingoTardeInicio: this.dateToHHmm(f.domingoTardeInicio),
              domingoTardeFin: this.dateToHHmm(f.domingoTardeFin),
              domingoNocheInicio: this.dateToHHmm(f.domingoNocheInicio),
              domingoNocheFin: this.dateToHHmm(f.domingoNocheFin)
            }
          );

        })

        this.turnosService.Insert_Turno_Bulk(dt).subscribe((result) => {
          if (result.error == false) {
            var idCalendarioSemanas = result.id
            this.turnosService.Actualizar_Tabla_Turnos(idCalendarioSemanas, 0).subscribe((result) => {});

            this.router.navigate(['turnos']);
          } else {

          }
        });

      } else {

      }
    });
  }

  private update() {

    var borrarUnaVez = true;
    var index = 0;

    this.horario.forEach(f => {

      //Le cambiamos la hora segun el timezon (le restamos el desvio)
      //f.lunesMannanaInicio.setTime(f.lunesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.lunesMannanaFin.setTime(f.lunesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.lunesTardeInicio.setTime(f.lunesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.lunesTardeFin.setTime(f.lunesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.lunesNocheInicio.setTime(f.lunesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.lunesNocheFin.setTime(f.lunesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      //f.martesMannanaInicio.setTime(f.martesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.martesMannanaFin.setTime(f.martesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.martesTardeInicio.setTime(f.martesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.martesTardeFin.setTime(f.martesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.martesNocheInicio.setTime(f.martesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.martesNocheFin.setTime(f.martesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      //f.miercolesMannanaInicio.setTime(f.miercolesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.miercolesMannanaFin.setTime(f.miercolesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.miercolesTardeInicio.setTime(f.miercolesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.miercolesTardeFin.setTime(f.miercolesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.miercolesNocheInicio.setTime(f.miercolesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.miercolesNocheFin.setTime(f.miercolesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      //f.juevesMannanaInicio.setTime(f.juevesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.juevesMannanaFin.setTime(f.juevesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.juevesTardeInicio.setTime(f.juevesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.juevesTardeFin.setTime(f.juevesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.juevesNocheInicio.setTime(f.juevesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.juevesNocheFin.setTime(f.juevesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      //f.viernesMannanaInicio.setTime(f.viernesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.viernesMannanaFin.setTime(f.viernesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.viernesTardeInicio.setTime(f.viernesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.viernesTardeFin.setTime(f.viernesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.viernesNocheInicio.setTime(f.viernesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.viernesNocheFin.setTime(f.viernesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      //f.sabadoMannanaInicio.setTime(f.sabadoMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.sabadoMannanaFin.setTime(f.sabadoMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.sabadoTardeInicio.setTime(f.sabadoTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.sabadoTardeFin.setTime(f.sabadoTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.sabadoNocheInicio.setTime(f.sabadoNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.sabadoNocheFin.setTime(f.sabadoNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      //f.domingoMannanaInicio.setTime(f.domingoMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.domingoMannanaFin.setTime(f.domingoMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.domingoTardeInicio.setTime(f.domingoTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.domingoTardeFin.setTime(f.domingoTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.domingoNocheInicio.setTime(f.domingoNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
      //f.domingoNocheFin.setTime(f.domingoNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

      this.turnosService.Update_Turno(f).subscribe((result) => {
        if (result.error == false) {

          //BORRAMOS EL HISTORICO TURNOS DESDE LA FECHA MAS PEQUEÑA DEL TURNO EDITADO
          if (this.fechaInicio != undefined && this.fechaInicio != null && borrarUnaVez) {
            borrarUnaVez = false;
            this.turnosService.DeleteHistoricoTurnos(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio)).subscribe((result) => {
              this.router.navigate(['turnos']);
            });
          }

          // Para actualizar solo una vez la tabla de turnos
          if (index == this.horario.length - 1) {
            var idCalendarioSemanas = f.idCalendarioSemanas
            this.turnosService.Actualizar_Tabla_Turnos(idCalendarioSemanas, 0).subscribe((result) => {});
          }
          index++;

        }
      })
    })
  }

  public atras() {
    this.router.navigate(['turnos']);
  }

  public abrirPopupDuplicar(popup) {
    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public abrirPopupCopiarDias() {
    this.modalReference = this.modalService.open(this.popupCopiarDias, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public abrirPopupCopiarMaquina() {
    this.modalReference = this.modalService.open(this.popupCopiarMaquina, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public abrirPopupEliminar(popup) {
    this.valuesEliminar = [];
    this.valuesEliminarAux = [];
    this.selectedAreaSeccion = [];
    this.selectedGrupo = [];
    this.botonSeleccionarTodo = this.translateService.instant('seleccionarTodo');
    this.selectedAreaSeccion.push(this.areasProductivas[0]);
    this.selectedGrupo.push(this.grupos[0]);
    this.cambiarAreaSeccion();
    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public duplicarSemana() {

    this.loadingDuplicar = true;

    this.turnosService.Get_Last_Turno().subscribe((result) => {
      var turno = result.data;
      this.form.value.numSemana = this.numSemana;
      var idCalendarioSemanasArray = [];

      for (let i = 0; i <= this.form.value.numDuplicarSemana - 1; i++) {

        var anno: any;
        var numSemanaDuplicar: any;
        idCalendarioSemanasArray[i] = -1;

        if (turno.length > 0) {
          anno = turno[0].anno;
          numSemanaDuplicar = turno[0].numSemana + i;
        } else {
          anno = this.form.value.ano;
          numSemanaDuplicar = this.form.value.numSemana + i;
        }

        var fechaInicioDuplicar: Date;
        var fechaFinDuplicar: Date;

        // obtenemos el primer dia del año
        var primerdia = new Date(anno, 0, 1);
        // obtenemos la corrección necesaria
        var correccion = 6 - primerdia.getDay();
        // obtenemos el lunes y domingo de la semana especificada
        fechaInicioDuplicar = new Date(anno, 0, (numSemanaDuplicar - 1) * 7 + 3 + correccion);
        fechaFinDuplicar = new Date(anno, 0, (numSemanaDuplicar - 1) * 7 + 9 + correccion);

        this.turnosService.Insert(this.getWeekNumber(fechaInicioDuplicar)[0], this.getWeekNumber(fechaInicioDuplicar)[1], this.dateToYYYYMMDDtHHmmSSz(fechaInicioDuplicar), this.dateToYYYYMMDDtHHmmSSz(fechaFinDuplicar)).subscribe((result) => {

          var dt: any = [];

          if (result.error == false) {
            this.horario.forEach(f => {

              //Le cambiamos la hora segun el timezon (le restamos el desvio)
              //f.lunesMannanaInicio.setTime(f.lunesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.lunesMannanaFin.setTime(f.lunesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.lunesTardeInicio.setTime(f.lunesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.lunesTardeFin.setTime(f.lunesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.lunesNocheInicio.setTime(f.lunesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.lunesNocheFin.setTime(f.lunesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              //f.martesMannanaInicio.setTime(f.martesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.martesMannanaFin.setTime(f.martesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.martesTardeInicio.setTime(f.martesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.martesTardeFin.setTime(f.martesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.martesNocheInicio.setTime(f.martesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.martesNocheFin.setTime(f.martesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              //f.miercolesMannanaInicio.setTime(f.miercolesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.miercolesMannanaFin.setTime(f.miercolesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.miercolesTardeInicio.setTime(f.miercolesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.miercolesTardeFin.setTime(f.miercolesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.miercolesNocheInicio.setTime(f.miercolesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.miercolesNocheFin.setTime(f.miercolesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              //f.juevesMannanaInicio.setTime(f.juevesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.juevesMannanaFin.setTime(f.juevesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.juevesTardeInicio.setTime(f.juevesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.juevesTardeFin.setTime(f.juevesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.juevesNocheInicio.setTime(f.juevesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.juevesNocheFin.setTime(f.juevesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              //f.viernesMannanaInicio.setTime(f.viernesMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.viernesMannanaFin.setTime(f.viernesMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.viernesTardeInicio.setTime(f.viernesTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.viernesTardeFin.setTime(f.viernesTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.viernesNocheInicio.setTime(f.viernesNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.viernesNocheFin.setTime(f.viernesNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              //f.sabadoMannanaInicio.setTime(f.sabadoMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.sabadoMannanaFin.setTime(f.sabadoMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.sabadoTardeInicio.setTime(f.sabadoTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.sabadoTardeFin.setTime(f.sabadoTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.sabadoNocheInicio.setTime(f.sabadoNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.sabadoNocheFin.setTime(f.sabadoNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              //f.domingoMannanaInicio.setTime(f.domingoMannanaInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.domingoMannanaFin.setTime(f.domingoMannanaFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.domingoTardeInicio.setTime(f.domingoTardeInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.domingoTardeFin.setTime(f.domingoTardeFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.domingoNocheInicio.setTime(f.domingoNocheInicio.getTime() - (this.horasDesvio * 60 * 60 * 1000));
              //f.domingoNocheFin.setTime(f.domingoNocheFin.getTime() - (this.horasDesvio * 60 * 60 * 1000));

              dt.push(
                {
                  idCalendarioSemanas: result.id,
                  idMaquina: f.idMaquina,
                  lunesMannanaInicio: this.dateToHHmm(f.lunesMannanaInicio),
                  lunesMannanaFin: this.dateToHHmm(f.lunesMannanaFin),
                  lunesTardeInicio: this.dateToHHmm(f.lunesTardeInicio),
                  lunesTardeFin: this.dateToHHmm(f.lunesTardeFin),
                  lunesNocheInicio: this.dateToHHmm(f.lunesNocheInicio),
                  lunesNocheFin: this.dateToHHmm(f.lunesNocheFin),
                  martesMannanaInicio: this.dateToHHmm(f.martesMannanaInicio),
                  martesMannanaFin: this.dateToHHmm(f.martesMannanaFin),
                  martesTardeInicio: this.dateToHHmm(f.martesTardeInicio),
                  martesTardeFin: this.dateToHHmm(f.martesTardeFin),
                  martesNocheInicio: this.dateToHHmm(f.martesNocheInicio),
                  martesNocheFin: this.dateToHHmm(f.martesNocheFin),
                  miercolesMannanaInicio: this.dateToHHmm(f.miercolesMannanaInicio),
                  miercolesMannanaFin: this.dateToHHmm(f.miercolesMannanaFin),
                  miercolesTardeInicio: this.dateToHHmm(f.miercolesTardeInicio),
                  miercolesTardeFin: this.dateToHHmm(f.miercolesTardeFin),
                  miercolesNocheInicio: this.dateToHHmm(f.miercolesNocheInicio),
                  miercolesNocheFin: this.dateToHHmm(f.miercolesNocheFin),
                  juevesMannanaInicio: this.dateToHHmm(f.juevesMannanaInicio),
                  juevesMannanaFin: this.dateToHHmm(f.juevesMannanaFin),
                  juevesTardeInicio: this.dateToHHmm(f.juevesTardeInicio),
                  juevesTardeFin: this.dateToHHmm(f.juevesTardeFin),
                  juevesNocheInicio: this.dateToHHmm(f.juevesNocheInicio),
                  juevesNocheFin: this.dateToHHmm(f.juevesNocheFin),
                  viernesMannanaInicio: this.dateToHHmm(f.viernesMannanaInicio),
                  viernesMannanaFin: this.dateToHHmm(f.viernesMannanaFin),
                  viernesTardeInicio: this.dateToHHmm(f.viernesTardeInicio),
                  viernesTardeFin: this.dateToHHmm(f.viernesTardeFin),
                  viernesNocheInicio: this.dateToHHmm(f.viernesNocheInicio),
                  viernesNocheFin: this.dateToHHmm(f.viernesNocheFin),
                  sabadoMannanaInicio: this.dateToHHmm(f.sabadoMannanaInicio),
                  sabadoMannanaFin: this.dateToHHmm(f.sabadoMannanaFin),
                  sabadoTardeInicio: this.dateToHHmm(f.sabadoTardeInicio),
                  sabadoTardeFin: this.dateToHHmm(f.sabadoTardeFin),
                  sabadoNocheInicio: this.dateToHHmm(f.sabadoNocheInicio),
                  sabadoNocheFin: this.dateToHHmm(f.sabadoNocheFin),
                  domingoMannanaInicio: this.dateToHHmm(f.domingoMannanaInicio),
                  domingoMannanaFin: this.dateToHHmm(f.domingoMannanaFin),
                  domingoTardeInicio: this.dateToHHmm(f.domingoTardeInicio),
                  domingoTardeFin: this.dateToHHmm(f.domingoTardeFin),
                  domingoNocheInicio: this.dateToHHmm(f.domingoNocheInicio),
                  domingoNocheFin: this.dateToHHmm(f.domingoNocheFin)
                }
              );

            })


            idCalendarioSemanasArray[i] = result.id
            this.turnosService.Insert_Turno_Bulk(dt).subscribe((result) => {
              this.turnosService.Actualizar_Tabla_Turnos(idCalendarioSemanasArray[i], 0).subscribe((result) => {});
              if (result.error == false && i == this.form.value.numDuplicarSemana - 1) {

                this.router.navigate(['turnos']);
                this.modalReference.close();
              } else if (result.error == true && i == this.form.value.numDuplicarSemana - 1) {//ERROR
                this.loadingDuplicar = false;
              }
            })

          } else {//ERROR
            this.loadingDuplicar = false;
          }

        });
      }

    });

  }


  public ano_onValueChange(e) {

    if (this.form.value.ano >= 1000) {
      this.maxSemanas = this.numeroSemanas(this.form.value.ano)
      if (this.form.value.numSemana > this.maxSemanas) {
        this.form.value.numSemana = this.maxSemanas;
        this.numSemana = this.maxSemanas;
      }

      if (this.form.value.ano != "" && this.form.value.numSemana != "") {
        this.getDateOfISOWeek(this.form.value.numSemana, this.form.value.ano);
      }
    }
  }

  numeroSemanas(ano: number): number {
    var simple = new Date(ano, 11, 31);
    var jueves = simple;
    jueves.setDate(simple.getDate() + 4 - simple.getDay() /* LA SEMANA PERTENECE A DONDE ESTA EL JUEVES */);
    var fechaInicio = this.myFunctions.dateCopy(jueves);
    if (fechaInicio.getFullYear() == ano) 
      return 53
    
    return 52
  }

  public numSemana_onValueChange(e) {
    if (this.form.value.ano != "" && this.form.value.numSemana != "") {
      this.getDateOfISOWeek(this.form.value.numSemana, this.form.value.ano);
    }
  }

  // public FirstDateOfWeekISO8601(year, weekOfYear) {
  //   // obtenemos el primer dia del año
  //   var primerdia = new Date(year, 0, 1);
  //   // obtenemos la corrección necesaria
  //   var correccion = 6 - primerdia.getDay();
  //   // obtenemos el lunes y domingo de la semana especificada
  //   var primer = new Date(year, 0, (weekOfYear - 1) * 7 + 3 + correccion);
  //   var ultimo = new Date(year, 0, (weekOfYear - 1) * 7 + 9 + correccion);

  //   this.fechaInicio = primer;
  //   this.fechaFin = ultimo;
  // }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    // lunes
    this.fechaInicio = this.myFunctions.dateCopy(ISOweekStart);
    // domingo
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 7)
    this.fechaFin = ISOweekStart;
  }

  //dateToYYYYMMDDtHHmmSSz
  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  public dateToHHmm(fecha: Date) {
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    return this.addZero(hora) + ':' + this.addZero(minutos);
  }

  //addZero
  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  //getWeekNumber
  public getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  public valueChangeDia(value: any): void {
    this.diasSemanaSeleccionados = value;
  }
  public valueChangeTurno(value: any): void {
    this.turnosSeleccionados = value;
  }


  public cambiarAreaSeccion() {
    this.listaMaquinasSeccion = [];
    // Filtroa hutsa badago makina denak erakutsi
    if (this.selectedAreaSeccion.length == 0 && this.selectedGrupo.length == 0) {
      this.maquinasSecciones.forEach(maquinas => {
        this.listaMaquinasSeccion.push(maquinas);
      });
    } else {
      this.vaciarMaquinas = false;
      this.maquinasSecciones.forEach(listaMaquinas => {
        // Sekzioren bat aukeratuta dagoen begiratu
        if (this.selectedAreaSeccion.length > 0) {
          this.selectedAreaSeccion.forEach(selectedArea => {
            // Berdina grupoekin
            if (this.selectedGrupo.length > 0) {
              this.selectedGrupo.forEach(grupo => {
                if (listaMaquinas.idSeccion == selectedArea.idSeccion && listaMaquinas.idGrupo.includes("," + grupo.id + ",")) {
                  this.maquinasEliminarTurno.forEach(maquinas => {
                    if (maquinas.text == listaMaquinas.nombre) {
                      this.listaMaquinasSeccion.push(listaMaquinas);
                    }
                  });
                }
              });
            } else {
              if (listaMaquinas.idSeccion == selectedArea.idSeccion) {
                this.maquinasEliminarTurno.forEach(maquinas => {
                  if (maquinas.text == listaMaquinas.nombre) {
                    this.listaMaquinasSeccion.push(listaMaquinas);
                  }
                });
              }
            }
          });
          // Sekziorik ez badago aukeratuta ez hartu kontuan filtratzeko
        } else {
          this.selectedGrupo.forEach(grupo => {
            if (listaMaquinas.idGrupo.includes("," + grupo.id + ",")) {
              this.maquinasEliminarTurno.forEach(maquinas => {
                if (maquinas.text == listaMaquinas.nombre) {
                  this.listaMaquinasSeccion.push(listaMaquinas);
                }
              });
            }
          });
        }
      });
    }
  }

  // public cellClickEliminar(e){
  //   var a =this.valuesEliminar;
  //   console.log(e.dataItem);

  // }

  public cellClickEliminar(e: CellClickEvent): void {
    if (this.valuesEliminarAux.includes(e.dataItem.nombre)) {
      //remove id from myselection
      const index = this.valuesEliminarAux.indexOf(e.dataItem.nombre, 0);
      if (index > -1) {
        this.valuesEliminarAux.splice(index, 1);
      }
    }
    else {
      this.valuesEliminarAux.push(e.dataItem.nombre)
    }
    this.valuesEliminar = Object.assign([], this.valuesEliminarAux);
    console.table(this.valuesEliminar);
    if (this.botonSeleccionarTodo == this.translateService.instant('deseleccionarTodo') && this.valuesEliminar.length < this.listaMaquinasSeccion.length) {
      this.botonSeleccionarTodo = this.translateService.instant('seleccionarTodo')
    }
  }

  public checkAllMaquinasEliminar() {
    if (this.listaMaquinasSeccion.length <= this.valuesEliminarAux.length) {
      this.valuesEliminarAux = [];
      this.botonSeleccionarTodo = this.translateService.instant('seleccionarTodo');
    }
    else {
      this.valuesEliminarAux = [];
      this.listaMaquinasSeccion.forEach(d => {
        this.valuesEliminarAux.push(d.nombre);
      });
      this.botonSeleccionarTodo = this.translateService.instant('deseleccionarTodo');
    }
    this.valuesEliminar = Object.assign([], this.valuesEliminarAux);
  }

}
