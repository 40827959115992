import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService, AreasProductivasService, MaquinasService, UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-secciones-detalle',
  templateUrl: './secciones-detalle.component.html',
  styleUrls: ['./secciones-detalle.component.less']
})
export class SeccionesDetalleComponent {
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;

  form: FormGroup;
  dataGrupos: any[];
  mySelection: number[] = [];
  idAreaProductiva: number = 0;
  idSeccion: number = 0;
  formValid = true;
  submitted= false;
  telefonoInvalido = false;
  user = this.userService.userValue;

  constructor(private areasProductivasService: AreasProductivasService, private maquinasService: MaquinasService,
    private formBuilder: FormBuilder,
    private menuService: MenuService, public router: Router,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalService: NgbModal) {


      this.menuService.titulo = this.translateService.instant('seccion');
      
  
      this.idSeccion = this.route.snapshot.params['idSeccion']
      this.idAreaProductiva = this.route.snapshot.params['idAreaProductiva']
      this.cargarDatos();
  }

  cargarDatos() {
    //Para evitar errores
    this.form = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required]),
      descripcion: new FormControl(''),
      email: new FormControl('', [Validators.email]),
      telefono: new FormControl('')
    });
    if (this.idSeccion > 0)
      this.areasProductivasService.Seccion_Get_ById(this.idSeccion).subscribe(
        (result) => {
          this.form = this.formBuilder.group({
            nombre: new FormControl(result[0].nombre),
            descripcion: new FormControl(result[0].descripcion),
            email: new FormControl(result[0].email, [Validators.email]),
            telefono: new FormControl(result[0].telefono)
          });
          
        });
    else{ //Si es uno nuevo se crea vacío
      this.form = this.formBuilder.group({
        nombre: new FormControl('', [Validators.required]),
        descripcion: new FormControl(''),
        email: new FormControl('', [Validators.email]),
        telefono: new FormControl('')
      });
    }

    this.cargarGrid();

  }

  cargarGrid() {
    this.mySelection = [];
    this.areasProductivasService.Seccion_Get_GrupoMaquinas(this.idSeccion).subscribe(
      result => {
        var an: any = result;
        this.dataGrupos = an;
      });
  }

  onChangeNumberCorrect(element: any){
    if(!element.srcElement.value.match("^\\+?[0-9]{9,12}$")){
      this.telefonoInvalido = true;
      this.formValid = false;
    } else{
      this.formValid = true;
      this.telefonoInvalido = false;
    }
  }

   //BOTONES PRINCIPALES
   guardarClick() {
    this.submitted= true;
    // stop here if form is invalid
    if (this.form.invalid || !this.formValid) {
      return;
    }
    var id: number = this.idSeccion;
    if (id == 0) {
      this.areasProductivasService.Seccion_Insert(this.idAreaProductiva, this.form.value.nombre, this.form.value.descripcion, this.form.value.email, 
        this.form.value. telefono).subscribe(
        (result) => {
          this.router.navigate(['areaProductiva/'+ this.idAreaProductiva]);
        });
    } else {
      this.areasProductivasService.Seccion_Update(id, this.form.value.nombre, this.form.value.descripcion, this.form.value.email, this.form.value. telefono).subscribe(
        (result) => {
          this.router.navigate(['areaProductiva/'+ this.idAreaProductiva]);
        });
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['maquinasgrupos/editar/', this.mySelection[0]]);
    }
  }

  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['maquinasgrupos/editar/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.submitted= true;
    // stop here if form is invalid
    if (this.form.invalid || !this.formValid) {
      return;
    }
    this.router.navigate(['maquinasgrupos/crear',   { seccion: this.idSeccion } ]);}

  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        this.form = this.formBuilder.group({
          idGrupo: element
        });
        this.maquinasService.deleteGrupoMaquinas(this.form.value)
          .pipe(first())
          .subscribe(() => {
            this.isDeleting = false;
            this.dataGrupos = this.dataGrupos.filter(x => x.id !== element)
          });
      }
      this.modalReference.close();
    });
  }


  cancelarClick() {
    this.router.navigate(['areaProductiva/'+ this.idAreaProductiva]);
  }
}
