import { Component } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MaquinasService, MenuService, ComboService, DiccionarioPerdidasService, ConfiguracionService } from '@app/_services';

import { ActivatedRoute, Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';
import { UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Accion, TipoAlarma, TipoPerdida_DAT} from '@app/_models';

@Component({
  selector: 'app-maquina-detalle-alarmas-controladas',
  templateUrl: './maquinaDetalleAlarmasControladas.component.html'
})

export class MaquinaDetalleAlarmasControladasComponent {
  //#region "CARGA"
  public id: number = -1;
  public idAlarma: number = 0;
  public idAccion: number = 0;
  public idMaquina: number = -1;
  public tipoMaquina: any;
  public acciones: Accion[];
  public accion_selectedItem: Accion;
  public tiposAlarmas: TipoAlarma[];
  public tipoAlarma_selectedItem: TipoAlarma;
  public alarmasRepercutenA: TipoPerdida_DAT[];
  public alarmasRepercutenASelected: TipoPerdida_DAT;

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;
  alarmasDentroDeOperacion: any;
  tenemosConfig = false;

  constructor(
    private formBuilder: FormBuilder, private diccionarioPerdidasService: DiccionarioPerdidasService,
    private maquinasService: MaquinasService, public translate: TranslateService,
    private comboService: ComboService, private configuracionService: ConfiguracionService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService) {
    
    this.menuService.titulo = this.translateService.instant('alarmaControlada').toUpperCase();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.idMaquina = this.route.snapshot.params['idMaquina'];
    this.idAlarma = -1;
    this.idAccion = -1;
    this.isAddMode = !this.id;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
    });

    this.comboService.Get_Acciones().pipe(first()).subscribe(
      (result: any) => {
        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translateService.instant(f.nombre);
        });
        this.acciones = an;
        this.idAccion = this.acciones[0].id;
        this.accion_selectedItem = new Accion(this.acciones[0].id, "");
      }
    );

    this.comboService.Get_TiposAlarmas().pipe(first()).subscribe(
      (result: any) => {
        this.tiposAlarmas = result.data;
      }
    );

     //Se reutiliza de diccionario pérdidas, ya que devuelve el mismo dato
     this.diccionarioPerdidasService.GetAll_TiposPerdidas_DAT().pipe(first()).subscribe(
      (result: any) => {

        var an: any = result.data;
        an.forEach(f => {
          f.nombre = this.translate.instant(f.nombre)
        });

        this.alarmasRepercutenA = result.data;
      }
    );
    //Lo repetimos aunque se sobreescriba para evitar errores
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      textoBusqueda: ['', Validators.required],
      descripcion: [''],
      alarmasDentroDeOperacion: this.alarmasDentroDeOperacion,
      perdidaAlarmaPorDefecto: -1
    });


    this.configuracionService.get_configuracion().subscribe(result => {
      var configuracion: any = result[0];
      this.alarmasRepercutenASelected = new TipoPerdida_DAT(configuracion.perdidaAlarmaPorDefecto == null?1:configuracion.perdidaAlarmaPorDefecto, ""); //si en configuración es null, por defecto es disponibilidad
      this.alarmasDentroDeOperacion = configuracion.alarmasDentroDeOperacion;
      this.tenemosConfig = true;
      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        textoBusqueda: ['', Validators.required],
        descripcion: [''],
        alarmasDentroDeOperacion: this.alarmasDentroDeOperacion,
        perdidaAlarmaPorDefecto: -1
      });
  
      if (this.id > 0) {
        this.maquinasService.Get_Control_Alarma(this.id).pipe().subscribe((result) => {
          this.idAlarma = result.data[0].idTipoAlarma;
          this.idAccion = result.data[0].iDdat_tipo_accion;
          this.accion_selectedItem = new Accion(result.data[0].iDdat_tipo_accion, "");
          this.tipoAlarma_selectedItem = new TipoAlarma(result.data[0].idTipoAlarma, "");
  
          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            textoBusqueda: [result.data[0].texto_busqueda, Validators.required],
            descripcion: [result.data[0].descripcion],
            //idAccion: [result.data[0].iDdat_tipo_accion,],
            //idTipoAlarma: [result.data[0].idTipoAlarma,],
            alarmasDentroDeOperacion: result.data[0].alarmasDentroDeOperacion== null? this.alarmasDentroDeOperacion: result.data[0].alarmasDentroDeOperacion,
            perdidaAlarmaPorDefecto: -1
          })
          this.alarmasDentroDeOperacion = result.data[0].alarmasDentroDeOperacion== null? this.alarmasDentroDeOperacion: result.data[0].alarmasDentroDeOperacion;
          this.alarmasRepercutenASelected = new TipoPerdida_DAT(result.data[0].perdidaAlarma == null?this.alarmasRepercutenASelected.id:result.data[0].perdidaAlarma, "");
        });
      }
    });
  }
  //#endregion

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    if(this.alarmasDentroDeOperacion == null)
      this.alarmasDentroDeOperacion = false;
    this.maquinasService.Insert_Control_Alarma(this.form.value, this.idMaquina, this.idAlarma, this.idAccion, this.alarmasRepercutenASelected.id, this.alarmasDentroDeOperacion).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  private update() {
    this.form.value.id = this.id;
    if(this.alarmasDentroDeOperacion == null)
      this.alarmasDentroDeOperacion = false;
    this.maquinasService.Update_Control_Alarma(this.form.value, this.id, this.idMaquina, this.idAlarma, this.idAccion, this.alarmasRepercutenASelected.id,this.alarmasDentroDeOperacion).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  public atras() {
    this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
      var data: any = result.data;
      if (this.idMaquina > 0) {
        if (data[0].esMaquina == 1) {
          if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
          if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
          if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
          if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
          if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
          if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
          if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
        } else if (data[0].esInstalacion == 1) {
          this.router.navigate(['instalaciones/editar/' + this.idMaquina]); //INSTALACION
        }
      }
    });
  }

  //#region "COMBO"
  public acciones_selectionChange(value: any): void {
    this.idAccion = value.id;
    //this.form.controls['idAccion'].setValue(value.id);
  }

  public tiposAlarmas_selectionChange(value: any): void {
    this.idAlarma = value.id;
   // this.form.controls['idTipoAlarma'].setValue(value.id);
  }
  //#endregion
}
