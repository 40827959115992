<!-- <div (click)="cerrarMenu()" style="position:fixed; top:0; left:0; right:0; bottom:0; display:block;"></div> -->

<div>

  <div class="eliminar-elemento-grupo" *ngIf="dragMode" (mouseover)="borrarElemento(1)" (mouseout)="borrarElemento(0)">

    <i class="icon-papelera"></i>

  </div>

  <!-- FILTRO -->
  <div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
    <div class="card-header">
      <h3>{{ 'filtro' | translate}}</h3>
      <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i
          class="fas fa-times"></i></button>
    </div>
    <div class="filtro-desplegable card-body flt">

      <!-- Filtro: CON GRUPOS -->
      <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
      <ng-template #recursiveListTmpl let-filtross="filtros">
        <div *ngFor="let filtro of filtross" class="mt-2">
          <!-- GRUPO -->
          <div *ngFor="let row of filtro.group" class="flt-grupo">
            <!-- LINEA FILTRO -->
            <div *ngIf="row.group == undefined" class="clearfix flt-linea">
              <!-- AND / OR -->
              <div class="flt-andor">
                <!-- = 1ª ROW NO TIENE NI OR, NI AND-->
                <label class="float-left" *ngIf="row == filtro.group[0]"> {{ 'where' | translate}} </label>
                <!-- = 2ª ROW -->
                <kendo-dropdownlist (selectionChange)="refiltrarFiltro(filtro)" *ngIf="row == filtro.group[1]"
                  [data]="andOr" [textField]="'nombre'" [valueField]="'id'" [(value)]="filtro.logic">
                </kendo-dropdownlist>
                <!-- > 2ª ROW -->
                <label class="d-flex justify-content-center"
                  *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 0"> {{ 'y' | translate}}
                </label>
                <label class="d-flex justify-content-center"
                  *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 1"> {{ 'o' | translate}}
                </label>
              </div>
              <!-- TIPO -->
              <div class="flt-tipo">
                <kendo-dropdownlist (selectionChange)="filtroTipoChanged(filtro, row, $event)" [data]="columnasFiltro"
                  [textField]="'nombre'" [valueField]="'id'" [class.flt-sin-seleccionar]="row.columna.id == 0"
                  [(value)]="row.columna"></kendo-dropdownlist>
              </div>
              <!-- CONTENEDOR FILTRO DE LINEA -->
              <div class="flt-filtro-linea">
                <!-- Filtro: FECHA -->
                <div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
                  <!-- OPCION -->
                  <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionDate" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <!-- DATO(s) -->
                  <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <!-- DATO 1 (tenemos un control especial para las fechas dobles) -->
                    <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1"
                      [(value)]="row.fechaIni"></kendo-datepicker>
                    <!-- DATO 1 / 2 -->
                    <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                      <div class="calendarFiltro">
                        <!-- CONTROL -->
                        <div class="calendarFiltro-input" (click)="showCalendar(row)">
                          <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni |
                            kendoDate}}</label>
                          <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni |
                            kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                          <i class="k-icon k-i-calendar"></i>
                        </div>
                        <!-- CONTROL EXPANDIDO -->
                        <div class="calendarFiltro-calendario" style="position: absolute !important; width: 367px;"
                          [ngClass]="row.mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                          <div class="row">
                            <div class="col-mes">
                              <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic"
                                (click)="valueClickCalendar(row, 0, $event)">
                                <ng-template kendoCalendarMonthCellTemplate let-date>
                                  <span class="calendarFiltro-calendario-contenido-dia"
                                    [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(row, date)"> {{
                                    date.getDate() }} </span>
                                </ng-template>
                                <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                  <div> {{ title }} </div>
                                </ng-template>
                              </kendo-calendar>
                            </div>
                            <div class="col-mes-botones">
                              <div class="calendarFiltro-botones">
                                <div class="clearfix text-center">
                                  <!-- style="margin-left: -100px; margin-top: 5px; position: absolute;" -->
                                  <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                  <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                  <button (click)="cargarMeses()">{{'hoy' | translate}}</button>
                                </div>
                                <!-- <div>
                                  <div class="clearfix"><button (click)="ultimas24HButton(row)">{{'ultimas24H' |
                                      translate}}</button></div>
                                  <div class="clearfix"><button (click)="ultimos7DiasButton(row)">{{'ultimos7Dias' |
                                      translate}}</button></div>
                                  <div class="clearfix"><button (click)="ultimos30DiasButton(row)">{{'ultimos30Dias' |
                                      translate}}</button></div>
                                  <div class="clearfix"><button (click)="ultimos60DiasButton(row)">{{'ultimos60Dias' |
                                      translate}}</button></div>
                                  <div class="clearfix"><button (click)="ultimos90DiasButton(row)">{{'ultimos90Dias' |
                                      translate}}</button></div>
                                </div> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
                <div *ngIf="row.columna.tipo == 'dateTime'" class="flt-cont-tipo">
                  <!-- OPCION -->
                  <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionDateTime" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <!-- DATO(s) -->
                  <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <!-- DATO -->
                    <div class="flt-dato1">
                      <kendo-datetimepicker [(value)]="row.fechaIni"> </kendo-datetimepicker>
                    </div>
                  </div>
                </div>
                <!-- Filtro: NUMEROS ENTEROS -->
                <div *ngIf="row.columna.tipo == 'numeric'">
                  <!-- OPCION -->
                  <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <!-- DATO(s) -->
                  <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <div class="flt-dato1">
                      <!-- DATO 1 -->
                      <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999"
                        [(value)]="row.numberMin"></kendo-numerictextbox>
                    </div>
                    <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                      <!-- DATO 2 -->
                      <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999"
                        [(value)]="row.numberMax"></kendo-numerictextbox>
                    </div>
                  </div>
                </div>
                <!-- Filtro: DECIMALES -->
                <div *ngIf="row.columna.tipo == 'decimal'">
                  <div class="flt-opcion">
                    <!-- OPCION -->
                    <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <!-- DATO(s) -->
                  <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <div class="flt-dato1">
                      <!-- DATO 1 -->
                      <kendo-numerictextbox [format]="row.decimalformat" [min]="-9999999" [max]="9999999"
                        [(value)]="row.decimalMin"></kendo-numerictextbox>
                    </div>
                    <div class="flt-dato2">
                      <!-- DATO 2 -->
                      <kendo-numerictextbox *ngIf="row.operator.dobleValor" [format]="row.decimalformat"
                        [min]="-9999999" [max]="9999999" [(value)]="row.decimalMax"></kendo-numerictextbox>
                    </div>
                  </div>
                </div>
                <!-- Filtro: TEXTO -->
                <div *ngIf="row.columna.tipo == 'string'">
                  <div class="flt-opcion">
                    <!-- OPCION -->
                    <kendo-dropdownlist [data]="opcionString" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <div class="flt-datos">
                    <div class="flt-dato1 float-left mr-2">
                      <!-- DATO -->
                      <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                    </div>
                  </div>
                </div>
                <!-- Filtro: COMBO ESTATICO -->
                <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                  <div class="flt-opcion">
                    <!-- OPCION -->
                    <kendo-dropdownlist [data]="opcionComboEstricto" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <!-- DATO(s) -->
                  <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <div class="flt-dato1">
                      <!-- DATO 1 -->
                      <kendo-dropdownlist *ngIf="!row.operator.dobleValor" [data]="row.combo" [textField]="'nombre'"
                        [valueField]="'id'" [(value)]="row.comboSelected" style="width: 220px;">
                      </kendo-dropdownlist>
                    </div>
                    <div class="flt-dato2">
                      <!-- DATO 2 -->
                      <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo"
                        [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'" [autoClose]="false"
                        style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                          <span class="k-icon k-i-arrow-s"></span>
                          <ng-container *ngIf="dataItems.length == 1">{{
                            this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                          <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' |
                            translate }}</ng-container>
                        </ng-template>
                        <kendo-multiselect-messages
                          noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                      </kendo-multiselect>
                    </div>

                  </div>
                </div>
                <!-- Filtro: COMBO FLEXIBLE -->
                <div *ngIf="row.columna.tipo == 'comboFlexible'">
                  <!-- OPCION -->
                  <div class="flt-opcion">
                    <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="row.operator"></kendo-dropdownlist>
                  </div>
                  <!-- DATO(s) -->
                  <div *ngIf="row.operator.id > 0" class="flt-datos">
                    <!-- DATO 1 -->
                    <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4" class="mr-2 float-left"
                      [data]="row.combo" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.comboSelected"
                      style="width: 220px;"></kendo-dropdownlist>
                    <!-- DATO 1 -->
                    <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag [data]="row.combo"
                      [(ngModel)]="row.comboSelecteds" [textField]="'nombre'" [valueField]="'id'" [autoClose]="false"
                      style="width: 220px;" [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                      <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{
                          this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seleccionados' | translate
                          }}</ng-container>
                      </ng-template>
                      <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                    </kendo-multiselect>
                    <!-- DATO 1 -->
                    <div *ngIf="!row.operator.dobleValor && row.operator.id > 4" class="flt-dato1 float-left mr-2">
                      <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                    </div>
                  </div>
                </div>
                <!-- Filtro: CHECK -->
                <div *ngIf="row.columna.tipo == 'check'" class="flt-dato1">
                  <!-- SERIA MEJOR CON 2 BOTONES SI / NO ? TEXTO PARA VERDADERO / FALSO? -->
                  <kendo-switch [(ngModel)]="row.check" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </div>
              </div>
              <!-- ELIMINAR -->
              <button
                *ngIf=" !((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico "
                Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i class="fas fa-times"></i>
              </button>
            </div>
            <!-- SUB GRUPO -->
            <div *ngIf="row.group != undefined">
              <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
            </div>
          </div>
          <!-- BOTON: AÑADIR LINEA (SOLO EN LA ULTIMA LINEA, o primer subgrupo) -->
          <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro && !annadirAutomatico">
            <button Class="btnEditatu btn btn-success btn-sm mr-1" (click)="annadirLineaFiltro(filtro)"> <i
                class="fas fa-plus"></i> {{ 'agregarCondicion' | translate }} </button>
          </div>
          <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro">
            <hr>
          </div>
        </div>
      </ng-template>
      <div class="clearfix">
        <button class="flt-btnanadirgrupo" (click)="annadirGrupoFiltro()"> <i class="fas fa-plus"></i> {{ 'agregarGrupo'
          | translate }} </button>
      </div>
      <hr>
      <div class="clearfix">
        <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()"> {{ 'filtrar' | translate
          }}</button>
        <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton' |
          translate }}</button>
      </div>

    </div>
  </div>


  <!-- FILTRO END -->


  <div class="cont-filtro-desplegable card" [ngClass]="pantallaListaOp ? 'filtro-desplegado' : 'filtro-oculto'">
    <div class="card-header">
      <h3>{{ 'operacionesPendientes' | translate}}</h3>
      <button type="button" class="flt-cerrar " (click)="reiniciarFiltroOF()"><i class="fas fa-times"></i></button>
    </div>
    <div class="filtro-desplegable card-body flt">

      <div class="row">
        <div class="col-8">
          <!-- FILTRAR OF -->
          <kendo-textbox placeholder="{{ 'buscar' | translate }} {{ 'of' | translate }}"
            (valueChange)="onChangeFiltroOF($event)" [maxlength]="50"></kendo-textbox>
        </div>
        <!-- <div class="col-2">
          <button type="button" class="btn btn-danger m-1" (click)="limpiarBuscador()">{{'limpiarBuscador' | translate
            }}</button>
        </div> -->
        <div class="col-4">
          <!-- ANNADIR OPERACION A GRUPO -->
          <button type="button" class="btn btn-primary m-1" (click)="onClick_Agregar()">{{'agregarAGrupo' | translate
            }}</button>
        </div>
      </div>

      <kendo-grid [data]="listaOperacionesFiltrado" [height]="800" [resizable]="true" [sortable]="true"
        filterable="none" class="grid-font-10" kendoGridSelectBy="idOperacion" [selectedKeys]="operacionesSelected"
        [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
        <kendo-grid-checkbox-column width="5%"></kendo-grid-checkbox-column>
        <kendo-grid-column field="idOperacion" hidden="hidden" title="ID" width="%1"></kendo-grid-column>
        <kendo-grid-column field="numeroOF" title="{{ 'OF' | translate }}" width="15%"
          [style]="{'text-align': 'center'}"></kendo-grid-column>
        <kendo-grid-column field="referenciaPieza" title="{{ 'pieza' | translate }}" width="15%"></kendo-grid-column>
        <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate }}"
          width="15%"></kendo-grid-column>
        <kendo-grid-column field="fechaFin" title="{{ 'entrega' | translate }}" width="10%"></kendo-grid-column>
        <kendo-grid-column field="peso" title="{{ 'peso' | translate }}" width="8%"></kendo-grid-column>
        <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempo' | translate }}" width="8%"></kendo-grid-column>
        <kendo-grid-column field="sumaCantidadesoperativas" title="{{ 'cantidad' | translate }}"
          width="8%"></kendo-grid-column>
        <kendo-grid-command-column field="paraHacer" title="" width="10%">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-numerictextbox [style.width.px]="50" [autoCorrect]="true" [min]="1" [max]="dataItem.cantidadSobrante"
              [decimals]="0" [(value)]="dataItem.paraHacer"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-command-column>
      </kendo-grid>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <button type="button" class="btn btn-primary float-right mr-1" (click)="plegarAll()"><i
              class="fa-light fa-arrows-to-line"></i>
            <div *ngIf="plegarAllDat">{{ 'desplegarTodos' | translate }}</div>
            <div *ngIf="!plegarAllDat">{{ 'plegarTodos' | translate }}</div>
          </button>
          <button type="button" class="btn btn-danger float-right mr-1" (click)="sugerirGrupos()" [disabled]="filtroActivado">{{ 'sugerir' |
            translate }}
          </button>
          <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i
              class="fas fa-filter mr-2"></i>{{ 'filtro' | translate }}</button>
          <button type="button" class="btn btn-primary float-right mr-1" (click)="guardar()" [disabled]="filtroActivado">{{ 'guardar' | translate
            }}</button>
          <label class="float-left mr-1">{{ 'version'| translate}}</label>
          <kendo-dropdownlist class="float-left mr-1" [data]="versionList" [textField]="'text'" [valueField]="'value'"
            [(value)]="versionSelected" (valueChange)="versionChange($event)"></kendo-dropdownlist>
          <button type="button" class="btn btn-success float-left mr-1" (click)="copiarVersion()">{{ 'copiar' |
            translate
            }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="grupos-cont">
    <div class="grupos-scroll">

      <!-- <div class="grupos-col-maquina" *ngFor="let maquina of datosRoot.children"> -->
      <div class="grupos-col" *ngFor="let maquina of datosRoot.children">

        <div class="item grupo-maquina" cdkDropList [cdkDropListData]="maquina.children"
          (cdkDropListDropped)="onDragDrop($event)" [cdkDropListConnectedTo]="listaConnectToG"
          [cdkDropListSortPredicate]="sortPredicate" [id]="maquina.uId">
          <div class="clearfix cabecera-grupos-maquina">
            <div class="nombre-maquina">{{maquina.name}}</div>
            <div class="cont-btn-sticky">
              <button type="button" class="btn btn-sticky" (click)="sugerirGrupos()">
                <i class="icon-selector"></i>
              </button>
            </div>
          </div>
          <div *ngFor="let node of maquina.children" class="sub-item cont-grupo"
            [ngClass]="{' corto' : node.semanaPlanificador.id == 0,' largo' : node.semanaPlanificador.id != 0 && node.semanaPlanificador.id != -1}"
            cdkDrag (cdkDragStarted)="dragStart(node)" [cdkDragDisabled]="node.editarTiempoEst">

            <button class="bloquear-grupo" (click)="bloquear(node.uId)" *ngIf="node.type != 'annadir'"><i
                class="icon-bloqueado"></i></button>

            <ng-container *ngTemplateOutlet="tmplNode; context: { node: node }"></ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>

  <ng-template #tmplNode let-node="node">
    <div class="item" cdkDropList [id]="node.uId" (cdkDropListDropped)="onDragDrop($event)"
      [cdkDropListConnectedTo]="listaConnectToO" (click)="annadirOperacion(node.name, node.idMaquina)"
      [cdkDropListSortPredicate]="sortPredicate" [cdkDropListData]="node.children">
      <div class="cont-grupo-header" *ngIf="node.name != 'annadir'">
        <button type="button" class="btn btn-plegar" (click)="plegar(node.uId)">
          <i class="icon-plegar"></i>
        </button>

        <label class="col-nombregrupo">{{node.name}}</label>

        <div class="col-cant-grupo">
          <label class="cant1-grupo"><i class="icon-listado"></i>{{node.children.length}}</label>
          <label class="cant2-grupo"><i class="icon-cubo"></i>{{node.sumaCantidad}}</label>
        </div>

        <button type="button" class="btn btn-desplegar"
          (click)="ensenarPlanificadorSemanas(node.uId)">{{node.semanaPlanificador.nombreCabecera1}}</button>

      </div>
      <div class="bloke-anadir" *ngIf="node.name == 'annadir'">

        <div class="cont-grupo-header"><label class="col-nombregrupo">NUEVO GRUPO</label></div>
        <div class="tarea-cont">
          <i class="icon-mas-alt"></i>
          <!--<label class="col">{{node.name}}</label>-->
        </div>
      </div>


      <!-- OPERAZIOAK -->
      <div *ngIf="!node.ensenarPlanificador">
        <ng-container *ngFor="let sub of node.children">
          <div [ngClass]="!sub.bloqueado ? 'sub-item tarea-cont' : 'sub-item tarea-cont bloqueado'" cdkDrag
            (cdkDragStarted)="dragStart(sub)" [cdkDragData]="node.uId" [cdkDragDisabled]="sub.disabled">
            <button class="bloquear-tarea" (click)="bloaquearTarea(sub.uId, node.uId)"><i
                class="icon-bloqueado"></i></button>
            <ng-container *ngTemplateOutlet="tmplOpe; context: { node: sub }">
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- PLANIFIKATZEILEKO ASTEAK -->
      <div *ngIf="node.ensenarPlanificador" class="cont-opciones-plan">
        <div *ngFor="let lista of listaSemanasPlanificador" class="opcion-plan"
          [ngClass]="{' corto' : lista.id == 0,  
                                                                                                    ' largo' : lista.id != 0 && lista.id != -1}">
          <label
            (click)="elegirPlanificador(node.uId, lista)">{{lista.nombreCabecera1}}<span>{{lista.nombreCabecera2}}</span></label>
        </div>
      </div>

      <!-- GRUPO AZPIKO INFO -->
      <div class="datos-grupo" *ngIf="node.name != 'annadir'">
        <label class="grupo-sumapeso"><i class="icon-peso"></i>{{(node.sumaPeso)}}/{{node.pesoMaquina}}</label>
        <label class="grupo-sumaunidades"><i
            class="icon-equivalencia"></i>{{(node.sumaUnidades)}}/{{node.capacidadUnidadesMaximo}}</label>
        <div class="grupo-tiempoest" *ngIf="!node.editarTiempoEst">
          <label class="" (click)="clickEditarTiempoEst(node.uId)"><i class="icon-reloj"></i>{{node.tiempoEstFormato}}</label>
        </div>
        <div class="grupo-tiempoest-editar" *ngIf="node.editarTiempoEst">
          <kendo-textbox [(value)]="node.tiempoEstFormato" [maxlength]="9"></kendo-textbox>
          <button type="button" class="btn btn-primary" (click)="clickEditarTiempoEst(node.uId)"><i
              class="icon-guardar"></i></button>
        </div>
        <div class="grupo-temp" *ngIf="!node.editarTemp">
          <label class="" (click)="clickEditarTemp(node.uId)"><i
              class="icon-termometro2"></i>{{node.temperatura}}º</label>
        </div>
        <div class="grupo-temp-editar" *ngIf="node.editarTemp">
          <kendo-numerictextbox [style.width.px]="100" [autoCorrect]="autoCorrect" [min]="node.temperaturaMinMaquina"
            [max]="node.temperaturaMaxMaquina" [decimals]="0" [(value)]="node.temperatura"></kendo-numerictextbox>
          <button type="button" class="btn btn-primary" (click)="clickEditarTemp(node.uId)"><i
              class="icon-guardar"></i></button>
        </div>
      </div>
    </div>

  </ng-template>

  <ng-template #tmplOpe let-node="node">
    <div cdkDropList [id]="node.uId" (cdkDropListDropped)="onDragDrop($event)"
      (click)="annadirOperacion(node.name, node.idMaquina)">

      <div class="cont-inner plegado" *ngIf="node.plegar;else noplegado">

        <div class="col-indicador"></div>
        <div class="col-cantidad">
          <label>{{node.paraHacer}}</label>
        </div>

        <div class="col-info">
          <label class="info-of">{{node.numeroOF}}</label>
        </div>
        <div class="col-info2">
          <label class="info2-peso">{{node.peso}}<i class="icon-peso"></i></label>
          <label class="info2-unidades">{{node.unidades}}<i class="icon-equivalencia"></i></label>
        </div>

      </div>
      <ng-template #noplegado>

        <div class="cont-inner">

          <div class="col-indicador"></div>
          <div class="col-cantidad">
            <label>{{node.paraHacer}}</label>
          </div>

          <div class="col-info">
            <label class="info-of">{{node.numeroOF}}</label>
            <label class="info-refpieza">{{node.referenciaPieza}}</label>
            <label class="info-operacion">{{node.nombreOperacion}}</label>
          </div>

          <div class="col-info2">
            <label class="info2-peso">{{node.peso}}<i class="icon-peso"></i></label>
            <label class="info2-unidades">{{node.unidades}}<i class="icon-equivalencia"></i></label>
            <label class="info2-testimado">{{node.tiempoEstimadoFormat}}<i class="icon-reloj"></i></label>
            <label class="info2-temp">{{node.temperatura}}º<i class="icon-termometro2"></i></label>
          </div>

        </div>
      </ng-template>


    </div>
  </ng-template>

</div>


<!-- POPUP: Copiar planning a version -->
<ng-template #popupCopiarAVersion let-modal>
  <div class="modal-header">
    <h4> <label>{{'aQueVersion' | translate }}</label> </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- ORIGINAL / VERSION DE SIMULADOR -->
    <div class="float-left">
      <label class="crontol-label float-left m-1">{{ 'planificacion' | translate }}</label>
      <!-- <div class="caja float-left" style="width: 150px"> -->
      <kendo-combobox [data]="versionList" [textField]="'text'" [valueField]="'value'" [(value)]="versionCopiarSelected"
        [kendoDropDownFilter]="{operator: 'contains'}">
      </kendo-combobox>
      <!-- </div> -->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="btnCopiarAVersionAceptar()"
      [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>