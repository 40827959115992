import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { ClasificacionesService, MenuService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-clasificaciones-editar',
  templateUrl: './clasificaciones-editar.component.html',
})

export class ClasificacionesEditarComponent {

  //Variables basicas
  private translate: TranslateService;
  public idClasificacion: number;
  public clasificacion: any;

  public nombre = "";
  public idPadreSeleccionado: any = {};
  public idPadre = -1
  public nombreError = false;

  public clasificaciones: any = []
  public clasificacionesAux: any = [];

  //Constructor
  constructor(
    translate: TranslateService,
    private menuService: MenuService, 
    public router: Router,
    public route: ActivatedRoute,
    private clasificacionesService: ClasificacionesService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('clasificacion').toUpperCase();
    this.idClasificacion = parseInt(this.route.snapshot.params['id']);
  }

  /**
   * Funcion ngOnInit, cargar todos los datos
   */
  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    // Datos de las clasificaciones creadas
    this.clasificacionesService.GetAll().subscribe(
      (result) => {
        this.clasificacionesAux = this.myFunctions.copy(result);
        var clasificaciones: any = result;

        //#region AGRUPAR LAS CLASIFICACIONES
        this.clasificaciones = this.clasificacionesAux.filter(f => f.idPadre == null);
        var arrayHijos = this.clasificacionesAux.filter(f => f.idPadre != null);
        while (arrayHijos.length != 0) {
          var idHijos = [];
          arrayHijos.forEach(element => {
            this.clasificaciones.forEach(row => {
              if (row.id == element.idPadre || row.idSubHijos?.includes(element.idPadre)) {
                idHijos.push(element.id)
                if (!row['clasificacionInferior']) {
                  row.clasificacionInferior = [];
                  row.idSubHijos = [];
                }
                
                row.idSubHijos.push(element.id)
                if(row.id == element.idPadre) row.clasificacionInferior.push(this.myFunctions.copy(element));
                else row.clasificacionInferior = this.setHijos(row.clasificacionInferior, element);
              }
            });
          });
          arrayHijos = arrayHijos.filter(f => !idHijos.includes(f.id))
        }

        //#endregion

         this.cargarClasificacionById();

      });
  }

  // Funcion recursiva para encontrar el padre 
  setHijos(clasificacionInferiorArray, hijo) {
    clasificacionInferiorArray.every(element => {
      // si el elemento es el padre
      if (element.id == hijo.idPadre) {
        if (!element['clasificacionInferior']) {
           element.clasificacionInferior = []
           element.idSubHijos = [];
        }
        element.clasificacionInferior.push(this.myFunctions.copy(hijo))
        element.idSubHijos.push(hijo.id);
        return false;
      }
      // si el elemento contiene un hijo que sea su padre
      else if (element.idSubHijos?.includes(hijo.idPadre)) {
        element.clasificacionInferior = this.setHijos(element.clasificacionInferior, hijo)
        return false;
      }

      return true
    });

    return clasificacionInferiorArray;
  }

  cargarClasificacionById() {
    // Datos de la clasificacion guardada
    if (this.idClasificacion > 0) {
      this.clasificacionesService.GetClasificacionByID(this.idClasificacion).subscribe(
        (result) => {
          this.clasificacion = result[0];
          this.nombre = this.clasificacion.nombre;
          this.idPadreSeleccionado = this.clasificacionesAux.filter(f => f.id == this.clasificacion.idPadre);
          if (this.idPadreSeleccionado.length > 0) {
            this.idPadreSeleccionado = this.idPadreSeleccionado[0];
            this.idPadre = this.idPadreSeleccionado.id;
          } else {
            this.idPadreSeleccionado = {id: -1, nombre: ''}
            this.idPadre = -1;
          }
        });
    }
  }

  cellClick(event) {
    if (event == undefined)
      this.idPadre = -1
    else  
      this.idPadre = event.id;
  }

  /**
   * Al clicar en el boton de Guardar, actualiza o inserta la clasificacion en la DB
   */
  onSubmit() {
    if (this.nombre.length == 0) {
      this.nombreError = true
      return;
    }

    // update
    if (this.idClasificacion > 0)
      this.clasificacionesService.UpdateClasificacion(this.idClasificacion, this.nombre, this.idPadre).subscribe(
        (result) => {
          console.log("clasificacion actualizada");
          this.router.navigate(['clasificaciones']);
        });
    // insert
    else
      this.clasificacionesService.InsertClasificacion(this.nombre, this.idPadre).subscribe(
        (result) => {
          console.log("clasificacion añadida");
          this.router.navigate(['clasificaciones']);
        });

  }

  groupBy (xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
}