import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { UsuariosService, AlertService, MenuService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({selector: 'app-profile-update', templateUrl: 'update.component.html' })
export class UpdateComponent implements OnInit {
    user = this.usuariosService.userValue;
    form: FormGroup;
    form2: FormGroup;
    loading = false;
    submitted = false;
    deleting = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private usuariosService: UsuariosService,
        private alertService: AlertService,
        private translateService: TranslateService,
        private menuService: MenuService
    ) { 
        translateService.setDefaultLang(this.user.idioma);
    }

    ngOnInit() {
        this.menuService.titulo = this.translateService.instant('actualizarusuario');
        this.form = this.formBuilder.group({
            id: this.user.id,
            idDb: this.user.idDb,
            nombreUsuario: new FormControl(this.user.nombreUsuario, [Validators.required]),
            nombre: new FormControl(this.user.nombre, [Validators.required]),
            apellido1: new FormControl(this.user.apellido1, [Validators.required]),
            apellido2: new FormControl(this.user.apellido2),
            usuarios_TiposId: [this.user.usuarios_TiposId],
            email: new FormControl(this.user.email, [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.minLength(6)]),
            confirmPassword: new FormControl('')
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
    }

 
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        this.user.nombreUsuario = this.form.get('nombreUsuario').value;
        this.user.nombre = this.form.get('nombre').value;
        this.user.apellido1 = this.form.get('apellido1').value;
        this.user.apellido2 = this.form.get('apellido2').value;
        this.user.email = this.form.get('email').value;
        this.loading = true;
        this.usuariosService.updateUsuarioPropio(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
                   
                    this.router.navigate(['../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    onDelete() {
        if (confirm(this.translateService.instant('estasseguro'))) {
            this.deleting = true;
            this.form2 = this.formBuilder.group({
                id: this.user.id
            });
            this.usuariosService.delete(this.user.id,this.form2)
                .pipe(first())
                .subscribe(() => {
                    this.alertService.success(this.translateService.instant('eliminadocorrecto'), { keepAfterRouteChange: true });
                });
        }
    }
}