<style>
  :host /deep/ .c3-chart-arcs-background {
    fill: #1A5F60;
    stroke: none;
  }

  :host /deep/ .c3-chart-arcs path {
    stroke-width: 0px;
  }
</style>

<div *ngIf="listaMaquinasEnPantalla.length > 0" class="h-100">
  <div [ngClass]="tipoPantalla == 2 ? 'row home-maquinas-cont' : 'h-100'" style="padding: 8px 5px 0px 10px;">
    <ng-container *ngFor="let maquina of listaMaquinasEnPantalla">

      <div [ngClass]="tipoPantalla == 2 ? 'form-horizontal col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 panelosoa tv-grande-cuatro'
                            : 'panelosoa tv-grande-uno'" id="panelosoa" *ngIf="maquina != undefined">
        <!-- DISEÑO 1 OEE -->
        <div>

        </div>
        <!-- <div [ngClass]="tipoPantalla == 2 ? 'inner-panel disenoTipo1' : 'inner-panel disenoTipo1'"
            *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true && ((disenoSelected == 1 && maquina.tipo_maquina != 5)|| (disenoSelected == 2 && maquina.tipo_maquina == 1) )"> -->
        <div [ngClass]="tipoPantalla == 2 ? 'inner-panel disenoTipo1' : 'inner-panel disenoTipo1'"
          *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true && disenoSelected == 1">

          <div [ngClass]="transicionMakinaAgertu ? 'agertu-makina' : '' ">
            <!-- Pestaña de color-->
            <div id="pestanaColor" class="{{maquina.css}}"></div>

            <div class="row ">
              <!-- COL 1 -->
              <div class="col-6">
                <!-- <div class="clearfix"> -->
                <!-- ROW 1.1 -->
                <div class="d-flex">
                  <!-- Imagen de las maquinas-->
                  <!-- <div ID="maquina" class="maquina"> -->
                  <div ID="maquina" class="">
                    <div id="estado">
                      <div class="fotomaquina pqn">
                        <img ID="fotomaquina" src="{{maquina.logo}}" />
                      </div>
                    </div>
                  </div>


                  <!-- <div class="header-home-maquina clearfix"> -->
                  <div class="header-home-maquina-tv">
                    <!-- Nombre de la maquina -->
                    <div class="clearfix nombre-maquina-tv">

                      <label ID="lmodelo">{{maquina.nombre}}</label>

                    </div>

                    <div class="clearfix tiempo-maquina-tv">
                      <kendo-label text="{{tiempoEjecucion.get(maquina.id)}}"></kendo-label>
                    </div>

                    <!-- Estado de ejecucion -->
                    <div class="clearfix estado-maquina-tv">
                      <div *ngIf="maquina?.ejecuciones != undefined">
                        <div class="{{maquina.ejecuciones[0].procesos_Tipo}}">
                          <kendo-label text="{{maquina.procesos_Tipostr?.toUpperCase()}}"></kendo-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- ROW 2.1 -->
                <div class="clearfix">
                  <!--PANEL OF PARA MAQUINAS QUE NO SON INYECTORAS NI HORNOS-->
                  <div *ngIf="maquina?.tipo_maquina != 4 && maquina?.tipo_maquina != 5" class="">
                    <!--<label ID="Ldatos" class="titulo-seccion-maquina">{{ 'datos' | translate}}</label>-->
                    <!-- LISTA DATOS -->
                    <div
                      *ngIf="maquina.ejecuciones[0]?.refOF!=translateService.instant('noproceso') && maquina.ejecuciones[0]?.procesos_Tipo != 'apagada'"
                      class="">
                      <div id="divDatosof" class="datos-of-tv">
                        <ul>
                          <li>
                            <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                              title="{{maquina.ejecuciones[0].refOF}}">
                              <!-- <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}} -->
                              <strong>{{'of' |translate}} </strong>
                              {{maquina.ejecuciones[0].refOF}}
                            </label>
                          </li>
                          <li *ngIf="maquina.ejecuciones[0].cliente!=''">
                            <label ID="ldatocliente" style="font-size:25px;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.ejecuciones[0].cliente}}">
                              {{maquina.ejecuciones[0].cliente}}
                            </label>
                          </li>
                          <li *ngIf="maquina.ejecuciones[0].pieza!=''">
                            <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                              title="{{maquina.ejecuciones[0].pieza}}">
                              {{maquina.ejecuciones[0].pieza}}
                            </label>
                          </li>
                          <!-- <li *ngIf="maquina.tipo_maquina == 9 && maquina.nombreMaterial!=''">
                              <label ID="lDatoMaterial" style="font-size:25px;"
                                  data-toggle="tooltip" data-placement="bottom"
                                  title="{{maquina.nombreMaterial}}">
                                  {{maquina.nombreMaterial}}
                              </label>
                          </li>
                          <li *ngIf="maquina.ejecuciones[0].parte != ''">
                              <label ID="ldatoparte" style="font-size:25px;" data-toggle="tooltip"
                                  data-placement="bottom"
                                  title="{{maquina.ejecuciones[0].parte}}">
                                  {{maquina.ejecuciones[0].parte}}
                              </label>
                          </li> -->
                          <li *ngIf="maquina.ejecuciones[0].operacion != ''">
                            <label ID="ldatoOperacion" style="font-size:25px;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.ejecuciones[0].operacion}}">
                              {{maquina.ejecuciones[0].operacion}}
                            </label>
                          </li>
                          <li *ngIf="maquina.ejecuciones[0].numeroSerie != ''">
                            <label ID="ldatoNumSerie" style="font-size:25px;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.ejecuciones[0].numeroSerie}}">
                              <strong>{{ 'nserie' | translate }} </strong>
                              {{maquina.ejecuciones[0].numeroSerie}}
                            </label>
                          </li>
                          <li>
                            <label ID="LdatoCantidad" style="font-size:25px;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.ejecuciones[0].realizadas}}">
                              <strong>{{ 'pieza' | translate }} </strong>
                              {{maquina.ejecuciones[0].hechas}} /
                              {{maquina.ejecuciones[0].cantidad}}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      *ngIf="maquina.ejecuciones[0].refOF==translateService.instant('noproceso') || maquina.ejecuciones[0].procesos_Tipo == 'apagada'"
                      class="label-datos-home-noidentificado">
                      <label ID="ldatocodof" data-toggle="tooltip" data-placement="bottom" style="font-size:25px;">
                        {{ 'noproceso' | translate }}
                      </label>
                    </div>
                  </div>

                  <!--PANEL OF PARA INYECTORAS-->
                  <div class="pdatos-of-inner" *ngIf="maquina.tipo_maquina == 4">

                    <!--MAQUINA-->
                    <label ID="Lmaquina" class="titulo-seccion-maquina">
                      {{
                      'maquina' |
                      translate
                      }}
                    </label>

                    <div id="divMaquinaInyec" class="datos-of-content">
                      <ul>
                        <li *ngIf="maquina.tonelaje!=''">
                          <label ID="ldatotonelaje" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                            data-placement="bottom" title="{{maquina.tonelaje}}">
                            {{maquina.tonelaje}}
                          </label>
                        </li>
                        <li *ngIf="maquina.unidadInyeccion!=''">
                          <label ID="ldatounidadInyeccion" class="label-datos-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.unidadInyeccion}}">
                            {{maquina.unidadInyeccion}}
                          </label>
                        </li>
                        <li *ngIf="maquina.numMaquina!=''">
                          <label ID="ldatonumeroMaquina" class="label-datos-home" style="width: 60%;"
                            data-toggle="tooltip" data-placement="bottom" title="{{maquina.numMaquina}}">
                            {{maquina.numMaquina}}
                          </label>
                        </li>
                      </ul>
                    </div>

                    <!--DATOS-->
                    <div
                      *ngIf="maquina.ejecuciones[0].refOF!=translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada'">
                      <div id="divDatosInyec" class="datos-of-content">
                        <ul>
                          <li *ngIf="maquina.ejecuciones[0].refOF!=translateService.instant('noproceso')">
                            <label ID="ldatocodof" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.ejecuciones[0].refOF}}">
                              <span class="of-label">
                                {{
                                'of'
                                |translate
                                }}
                              </span>{{maquina.ejecuciones[0].refOF}}
                            </label>
                          </li>
                          <li *ngIf="maquina.nCavidades!=''">
                            <label ID="ldatonCavidades" class="label-datos-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom" title="{{maquina.nCavidades}}">
                              {{maquina.nCavidades}}
                            </label>
                          </li>
                          <li *ngIf="maquina.material!=''">
                            <label ID="lmaterial" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.material}}">
                              {{maquina.material}}
                            </label>
                          </li>
                          <li *ngIf="maquina.refMaterial!=''">
                            <label ID="ldatorefMaterial" class="label-datos-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom" title="{{maquina.refMaterial}}">
                              {{maquina.refMaterial}}
                            </label>
                          </li>
                          <li *ngIf="maquina.refMolde!=''">
                            <label ID="ldatorefMolde" class="label-datos-home" style="width: 60%;" data-toggle="tooltip"
                              data-placement="bottom" title="{{maquina.refMolde}}">
                              {{maquina.refMolde}}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      *ngIf="maquina.ejecuciones[0].refOF==translateService.instant('noproceso') || maquina.ejecuciones[0].procesos_Tipo == 'apagada'"
                      class="label-datos-home-noidentificado">
                      <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                        {{ 'noproceso' | translate }}
                      </label>
                    </div>
                  </div>

                  <!--PANEL OF PARA HORNOS-->
                  <div class="pdatos-of-inner" *ngIf="maquina.tipo_maquina == 5">

                    <!--DATOS-->
                    <div
                      *ngIf="maquina.ejecuciones[0].refOF!=translateService.instant('noproceso') && maquina.ejecuciones[0].procesos_Tipo != 'apagada'">
                      <div id="divDatosInyec" class="datos-of-content">
                        <ul>
                          <li>
                            <label ID="ldatocodof" class="label-datos-valor-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].refOF}}">
                              <strong>{{'of' |translate}}</strong>
                              {{maquina.ejecuciones[0].refOF}}
                            </label>
                          </li>
                          <li>
                            <label ID="ldatoCliente" class="label-datos-valor-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].cliente}}">
                              {{ maquina.ejecuciones[0].cliente }}
                            </label>
                          </li>
                          <li>
                            <label ID="ldatoPieza" class="label-datos-valor-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom" title="{{maquina.ejecuciones[0].pieza}}">
                              {{ maquina.ejecuciones[0].pieza }}
                            </label>
                          </li>
                          <li>
                            <label ID="ldatoTarea" class="label-datos-valor-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom"
                              title="{{maquina.ejecuciones[0].operacion}}">
                              {{maquina.ejecuciones[0].operacion}}
                            </label>
                          </li>
                          <li>
                            <label ID="ldatoPiezas" class="label-datos-valor-home" style="width: 60%;"
                              data-toggle="tooltip" data-placement="bottom" title="{{ maquina.ejecuciones[0].hechas }}">
                              <strong>{{ 'pieza' | translate }}</strong> {{
                              maquina.ejecuciones[0].cuantasHechas
                              }} /
                              {{maquina.ejecuciones[0].cantidad }}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      *ngIf="maquina.ejecuciones[0].refOF==translateService.instant('noproceso') || maquina.ejecuciones[0].procesos_Tipo == 'apagada'"
                      class="label-datos-home-noidentificado">
                      <label ID="ldatocodof" class="label-datos-home" data-toggle="tooltip" data-placement="bottom">
                        {{ 'noproceso' | translate }}
                      </label>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
                <!-- </div> -->

              </div>

              <!-- COL 2 -->
              <div class="col-6">
                <!-- CUTRO DONUTS-->
                <div class="oee-cont-tv">
                  <div class="row">
                    <!-- OEE -->
                    <div class="col-md-12">
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 1"
                        id="{{'graficoDonutOEE_OEE_' + maquina.id}}" style="height: 250px;">
                      </div>
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 2"
                        id="{{'graficoDonutOEE_OEE_' + maquina.id}}" style="height: 120px;">
                      </div>
                      <p ID="lFiltro" class="oee-donut-label">{{ 'oee' | translate}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <!-- DISPONIBILIDAD -->
                    <div class="col-md-4">
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 1"
                        id="{{'graficoDonutDisponibilidad_OEE_' + maquina.id}}" style="height: 250px;">
                      </div>
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 2"
                        id="{{'graficoDonutDisponibilidad_OEE_' + maquina.id}}" style="height: 120px;">
                      </div>
                      <p ID="lFiltro" class="oee-donut-label">
                        {{ 'disponibilidad' | translate}}
                      </p>
                    </div>
                    <!-- RENDIMIENTO -->
                    <div class="col-md-4">
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 1"
                        id="{{'graficoDonutRendimiento_OEE_' + maquina.id}}" style="height: 250px;">
                      </div>
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 2"
                        id="{{'graficoDonutRendimiento_OEE_' + maquina.id}}" style="height: 120px;">
                      </div>
                      <p ID="lFiltro" class="oee-donut-label">
                        {{ 'rendimiento' | translate}}
                      </p>
                    </div>
                    <!-- CALIDAD -->
                    <div class="col-md-4">
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 1"
                        id="{{'graficoDonutCalidad_OEE_' + maquina.id}}" style="height: 250px;">
                      </div>
                      <div class="row circulo-oee-home-tv" *ngIf="tipoPantalla == 2"
                        id="{{'graficoDonutCalidad_OEE_' + maquina.id}}" style="height: 120px;">
                      </div>
                      <p ID="lFiltro" class="oee-donut-label">{{ 'calidad' | translate}}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <!-- DISEÑO 2 PRECIO/HORA SIN TIEMPO -->
        <!-- <div [ngClass]="tipoPantalla == 2 ? 'inner-panel disenoTipo2' : 'inner-panel disenoTipo2'"
            *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true  && ((disenoSelected == 1 && maquina.tipo_maquina == 5)|| (disenoSelected == 2 && maquina.tipo_maquina == 5))"> -->
        <div [ngClass]="tipoPantalla == 2 ? 'inner-panel disenoTipo2' : 'inner-panel disenoTipo2'"
          *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true  && disenoSelected == 2">

          <div [ngClass]="transicionMakinaAgertu ? 'agertu-makina' : '' ">
            <!-- Pestaña de color-->
            <div id="pestanaColor" class="{{maquina.css}}"></div>
            <!-- ROW 1 -->
            <div class="row">
              <!-- ROW 1.1 -->
              <div class="d-flex">
                <!-- Imagen de las maquinas-->
                <!-- <div ID="maquina" class="maquina"> -->
                <div ID="maquina" class="">
                  <div id="estado">
                    <div class="fotomaquina pqn">
                      <img ID="fotomaquina" src="{{maquina.logo}}" />
                    </div>
                  </div>
                </div>


                <!-- <div class="header-home-maquina clearfix"> -->
                <div class="header-home-maquina-tv">
                  <!-- Nombre de la maquina -->
                  <div class="clearfix nombre-maquina-tv">

                    <label ID="lmodelo">{{maquina.nombre}}</label>

                  </div>
                </div>
              </div>
            </div>
            <div class="margen-borde-inferior"></div>
            <!-- ROW 2 -->
            <div class="row cont-datos-alineados">
              <!-- COL 1 -->
              <div class="col-6">
                <!-- <div class="clearfix"> -->
                <!-- ROW 2.1 -->
                <!--PANEL OF PARA MAQUINAS QUE NO SON INYECTORAS NI HORNOS-->
                <!-- TIEMPOS CALIDAD / MANTENIMIENTOS -->
                <!-- <div *ngIf="maquina.ejecuciones[0]?.refOF!=translateService.instant('noproceso') && maquina.ejecuciones[0]?.procesos_Tipo != 'apagada'"> -->
                <div class="tiemposhorno">
                  <!-- TIEMPO RESTANTE CALIDAD -->
                  <div class="row d-flex justify-content-center">
                    <label class="tiemposhorno-titulo">{{'tRestCalidad' | translate}}</label>
                  </div>
                  <div class="row d-flex justify-content-center">
                    <label class="tiemposhorno-tiempo">00:00:00</label>
                  </div>
                  <!-- TIEMPO RESTANTE MANTENIMIENTO -->
                  <div class="row d-flex justify-content-center">
                    <label class="tiemposhorno-titulo">{{'tRestMantenimiento' | translate}}</label>
                  </div>
                  <div *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                    <div class="row d-flex justify-content-center">
                      <label class="tiemposhorno-tiempo">
                        {{faltaMantenimientoST.get(maquina.id)}}</label>
                    </div>
                  </div>
                  <div *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                    <div class="row d-flex justify-content-center">
                      <label class="tiemposhorno-sin-mant">{{ 'noMantenimientosCorto' | translate}}</label>
                    </div>
                  </div>


                </div>
                <!-- <div *ngIf="maquina.ejecuciones[0].refOF==translateService.instant('noproceso') || maquina.ejecuciones[0].procesos_Tipo == 'apagada'"
                    class="label-datos-home-noidentificado">
                    <label ID="ldatocodof" data-toggle="tooltip" data-placement="bottom"
                        style="font-size:25px;">
                        {{ 'noproceso' | translate }}
                    </label>
                </div> -->
                <!-- </div> -->

              </div>

              <!-- COL 2 -->
              <div class="col-6">
                <div class="row cont-datos-alineados">
                  <!-- COL2 COL1 -->
                  <div class="col-7">
                    <div class="euroshorahorno">
                      <!-- EUROS HORA -->
                      <div class="row d-flex justify-content-center">
                        <label class="euroshorahorno-titulo">€/h</label>
                      </div>
                      <div class="row d-flex justify-content-center">
                        <label class="euroshorahorno-valor">{{maquina.eurosHora}}</label>
                      </div>
                      <!--<div class="row d-flex justify-content-center">
                          <label class="euroshorahorno-valor">{{maquina.eurosHoraEst}}</label>
                      </div>-->
                      <!-- PIEZAS HORA -->
                      <div class="row d-flex justify-content-center">
                        <label class="euroshorahorno-titulo">{{'piezash'|translate}}</label>
                      </div>
                      <div class="row d-flex justify-content-center">
                        <label class="euroshorahorno-valor">{{maquina.piezasHora}}</label>
                      </div>
                      <!--<div class="row d-flex justify-content-center">
                          <label class="euroshorahorno-valor">{{maquina.piezasHoraEst}}</label>
                      </div>-->
                    </div>
                  </div>

                  <!-- COL2 COL2 -->
                  <!-- FLETXA PORTZENTAIA -->
                  <!-- <div class="col-5">
                      <div class="row d-flex justify-content-center">
                          <label>FLETXA</label>
                      </div>
                      <div class="row d-flex justify-content-center">
                          <label>XXX %</label>
                      </div>
                  </div> -->
                  <div class="col-5 cont-porcentaje-flecha-horno">
                    <!-- PORCENTAJE PIEZAS HORA -->
                    <div class="row d-flex justify-content-center">
                      <div *ngIf="maquina.porcenPiezasHora <= 100">
                        <i class="icon-flechaabajo-alt-2"></i>
                      </div>
                      <div *ngIf="maquina.porcenPiezasHora > 100">
                        <i class="icon-flechaarriba-alt-2"></i>
                      </div>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <kendo-label class="porcentaje-horno" text="{{maquina.porcenPiezasHora}}%"></kendo-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- DISEÑO 3 PRECIO/HORA CON TIEMPO -->
        <div [ngClass]="tipoPantalla == 2 ? 'inner-panel disenoTipo3' : 'inner-panel disenoTipo3'"
          *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true  && disenoSelected == 3">

          <div [ngClass]="transicionMakinaAgertu ? 'agertu-makina' : '' ">
            <!-- Pestaña de color-->
            <div id="pestanaColor" class="{{maquina.css}}"></div>
            <!-- ROW 1 -->
            <div class="row">
              <!-- Imagen de las maquinas-->
              <div ID="maquina" class="col-3">
                <div id="estado">
                  <div class="fotomaquina pqn">
                    <img ID="fotomaquina" src="{{maquina.logo}}" />
                  </div>
                </div>
              </div>


              <div class="col-9 cabecera-maquina-tv">
                <!-- Nombre de la maquina -->
                <div class="modelo-operario-cont">
                  <div class="nombre-modelo-maquina">
                    <div class="modelo-maquina">
                      <label ID="lmodelo">{{maquina.nombre}}</label>
                    </div>
                  </div>
                  <!-- Nombre del operario-->
                  <div class="nombre-operario-maquina">
                    <div ID="persona" class="usuario">
                      <!--<img id="fotopersona" src="assets/user-cm.png" />-->
                      <i class="icon-usuario"></i>
                      <label ID="nompersona">{{maquina.operario}}</label>
                    </div>
                  </div>
                </div>

                <div class="maquina-tiempo-estado-cont">
                  <!-- Tiempo ejecucion -->
                  <div *ngIf="maquina != undefined && maquina?.ejecuciones != undefined">
                    <div class="tiempo-ejecucion-maquina">
                      <kendo-label text="{{tiempoEjecucion.get(maquina.id)}}"></kendo-label>
                    </div>
                    <div class="estado-maquina">
                      <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != undefined">
                        <div class="{{maquina.ejecuciones[0].procesos_Tipo}}">
                          <kendo-label text="{{maquina.procesos_Tipostr?.toUpperCase()}}"></kendo-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Nombre programa -->
                <!-- <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != 'apagada'">
                    <div class="programa-ejecucion-maquina" *ngIf="maquina.tipo_maquina!=5">
                        <kendo-label text="{{maquina.programa}}"></kendo-label>
                    </div>
                    <div class="programa-ejecucion-maquina" *ngIf="maquina.tipo_maquina==5">
                        <kendo-label
                            text="{{ 'tRestCalidad' | translate }} {{tRestCalidad_HHmmSS}}"></kendo-label>
                    </div>
                </div> -->
              </div>
            </div>
            <div class="margen-borde-inferior"></div>
            <!-- ROW 2 -->
            <div class="row ">
              <!-- COL 1 -->
              <div class="col-6">
                <!-- <div class="clearfix"> -->
                <!-- ROW 2.1 -->
                <!-- TIEMPOS CALIDAD / MANTENIMIENTOS -->

                <div id="divDatosof" class="datos-of-tv">
                  <ul>
                    <li *ngIf="maquina.ejecuciones[0].refOF!=''">
                      <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].refOF}}">
                        <!-- <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}} -->
                        <strong>{{'of' |translate}} </strong>
                        {{maquina.ejecuciones[0].refOF}}
                      </label>
                    </li>
                    <li *ngIf="maquina.ejecuciones[0].refOF==''">
                      <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].refOF}}">
                        <!-- <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}} -->
                        <strong>{{'of' |translate}} </strong>
                        --
                      </label>
                    </li>
                    <li *ngIf="maquina.ejecuciones[0].pieza!=''">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'pieza' |translate}} </strong>
                        {{maquina.ejecuciones[0].pieza}}
                      </label>
                    </li>
                    <li *ngIf="maquina.ejecuciones[0].pieza==''">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'pieza' |translate}} </strong>
                        --
                      </label>
                    </li>
                    <li>
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'tRestCalidad' | translate}}</strong>
                        00:00:00
                      </label>
                    </li>
                    <!-- <li>
                      <div *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                        <div class="row d-flex justify-content-center">
                          <kendo-label class="tiemposhorno-tiempo" text="{{faltaMantenimientoST.get(maquina.id)}}"></kendo-label>
                        </div>
                      </div>
                      <div *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                        <div class="row d-flex justify-content-center">
                          <label class="tiemposhorno-sin-mant">{{ 'noMantenimientosCorto' | translate}}</label>
                        </div>
                      </div>
                    </li> -->

                    <li *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'tRestMantenimiento' | translate}} </strong>
                        {{faltaMantenimientoST.get(maquina.id)}}
                      </label>
                    </li>
                    <li *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'tRestMantenimiento' | translate}} </strong>
                        {{ 'noMantenimientosCorto' | translate}}
                      </label>
                    </li>

                  </ul>
                </div>
              </div>

              <!-- COL 2 -->
              <div class="col-6 cont-porcentaje-flecha-horno">
                <!-- COL2 ROW1 -->
                <div class="row row d-flex align-items-center">
                  <!-- EUROS/HORA -->
                  <div class="col-4 euroshorahorno">
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-titulo">€/h</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-valor">{{maquina.eurosHora}}</label>
                    </div>
                    <!--<div class="row d-flex justify-content-center">
                        <label>{{maquina.eurosHoraEst}}</label>
                    </div>-->
                  </div>
                  <!-- FLETXA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <div *ngIf="maquina.porcenPiezasHora <= 100">
                        <i class="icon-flechaabajo-alt-2"></i>
                      </div>
                      <div *ngIf="maquina.porcenPiezasHora > 100">
                        <i class="icon-flechaarriba-alt-2"></i>
                      </div>
                    </div>
                  </div>
                  <!-- PIEZAS/HORA -->
                  <div class="col-4 euroshorahorno">
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-titulo">{{'piezash'|translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-valor">{{maquina.piezasHora}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="euroshorahorno-valor">{{maquina.piezasHoraEst}}</label>
                    </div>
                  </div>

                </div>

                <div class="margen-borde-inferior"></div>

                <!-- COL2 ROW2 -->
                <div class="row d-flex align-items-center lote-actual-cont">

                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{lotePorcentajeMap.get(maquina.id)}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{maquina.estadoimg}}" />
                      </div>
                    </div>
                  </div>

                  <!-- TIEMPO LOTE ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{'loteActual'| translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{tiempoActualLote.get(maquina.id)}}</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">Total time</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{loteDesviacionMap.get(maquina.id)}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- DISEÑO 4 LOTE/PIEZA -->
        <div [ngClass]="tipoPantalla == 2 ? 'inner-panel disenoTipo4' : 'inner-panel disenoTipo4'"
          *ngIf="appComponent.selectedGrupo.idMaquinas.includes(maquina.id) && !maquina.borrado && r1 == true && r2 == true  && disenoSelected == 4">

          <div [ngClass]="transicionMakinaAgertu ? 'agertu-makina' : '' ">
            <!-- Pestaña de color-->
            <div id="pestanaColor" class="{{maquina.css}}"></div>
            <!-- ROW 1 -->
            <div class="row">
              <!-- Imagen de las maquinas-->
              <div ID="maquina" class="col-3">
                <div id="estado">
                  <div class="fotomaquina pqn">
                    <img ID="fotomaquina" src="{{maquina.logo}}" />
                  </div>
                </div>
              </div>


              <div class="col-9  cabecera-maquina-tv">
                <!-- Nombre de la maquina -->
                <div class="modelo-operario-cont">
                  <div class="nombre-modelo-maquina">
                    <div class="modelo-maquina">
                      <label ID="lmodelo">{{maquina.nombre}}</label>
                    </div>
                  </div>
                  <!-- Nombre del operario-->
                  <div class="nombre-operario-maquina">
                    <div ID="persona" class="usuario">
                      <!--<img id="fotopersona" src="assets/user-cm.png" />-->
                      <i class="icon-usuario"></i>
                      <label ID="nompersona">{{maquina.operario}}</label>
                    </div>
                  </div>
                </div>

                <div class="maquina-tiempo-estado-cont">
                  <!-- Tiempo ejecucion -->
                  <div *ngIf="maquina != undefined && maquina?.ejecuciones != undefined">
                    <div class="tiempo-ejecucion-maquina">
                      <kendo-label text="{{tiempoEjecucion.get(maquina.id)}}"></kendo-label>
                    </div>
                    <div class="estado-maquina">
                      <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != undefined">
                        <div class="{{maquina.ejecuciones[0].procesos_Tipo}}">
                          <kendo-label text="{{maquina.procesos_Tipostr?.toUpperCase()}}"></kendo-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Nombre programa -->
                <!-- <div *ngIf="maquina?.ejecuciones[0]?.procesos_Tipo != 'apagada'">
                    <div class="programa-ejecucion-maquina" *ngIf="maquina.tipo_maquina!=5">
                        <kendo-label text="{{maquina.programa}}"></kendo-label>
                    </div>
                    <div class="programa-ejecucion-maquina" *ngIf="maquina.tipo_maquina==5">
                        <kendo-label
                            text="{{ 'tRestCalidad' | translate }} {{tRestCalidad_HHmmSS}}"></kendo-label>
                    </div>
                </div> -->
              </div>
            </div>
            <div class="margen-borde-inferior"></div>
            <!-- ROW 2 -->
            <div class="row ">
              <!-- COL 1 -->
              <div class="col-6">
                <!-- <div class="clearfix"> -->
                <!-- ROW 2.1 -->
                <!-- TIEMPOS CALIDAD / MANTENIMIENTOS -->

                <div id="divDatosof" class="datos-of-tv">
                  <ul>
                    <li *ngIf="maquina.ejecuciones[0].refOF!=''">
                      <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].refOF}}">
                        <!-- <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}} -->
                        <strong>{{'of' |translate}} </strong>
                        {{maquina.ejecuciones[0].refOF}}
                      </label>
                    </li>
                    <li *ngIf="maquina.ejecuciones[0].refOF==''">
                      <label ID="ldatocodof" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].refOF}}">
                        <!-- <span class="of-label">{{'of' |translate}}</span>{{maquina.ejecuciones[0].refOF}} -->
                        <strong>{{'of' |translate}} </strong>
                        --
                      </label>
                    </li>
                    <li *ngIf="maquina.ejecuciones[0].pieza!=''">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'pieza' |translate}} </strong>
                        {{maquina.ejecuciones[0].pieza}}
                      </label>
                    </li>
                    <li *ngIf="maquina.ejecuciones[0].pieza==''">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'pieza' |translate}} </strong>
                        --
                      </label>
                    </li>
                    <li>
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'tRestCalidad' | translate}}</strong>
                        00:00:00
                      </label>
                    </li>
                    <li *ngIf="segundosFaltaMantenimiento.has(maquina.id)">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'tRestMantenimiento' | translate}}</strong>
                        {{faltaMantenimientoST.get(maquina.id)}}
                      </label>
                    </li>
                    <li *ngIf="!segundosFaltaMantenimiento.has(maquina.id)">
                      <label ID="lDatoPieza" style="font-size:25px;" data-toggle="tooltip" data-placement="bottom"
                        title="{{maquina.ejecuciones[0].pieza}}">
                        <strong>{{'tRestMantenimiento' | translate}}</strong>
                        {{ 'noMantenimientosCorto' | translate}}
                      </label>
                    </li>

                  </ul>
                </div>
              </div>

              <!-- COL 2 -->
              <div class="col-6 cont-porcentaje-flecha-horno"
                *ngIf="verLote == 0 && maquina.ejecuciones[0].cantidad > 1">

                <!-- COL2 ROW1 -->
                <div class="row d-flex align-items-center lote-actual-cont">
                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{piezaPorcentajeMap.get(maquina.id)}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{piezaimgMap.get(maquina.id)}}" />
                      </div>
                    </div>
                  </div>

                  <!-- TIEMPO LOTE ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'piezaActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{tiempoActualPieza.get(maquina.id)}}</label>
                    </div>
                    <!-- <div class="row d-flex justify-content-center"
                        *ngIf="maquina.ejecuciones[0].hechas == 0">
                        <label>{{tiempoActualLote.get(maquina.id)}}</label>
                    </div> -->
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">Cycle time</label>
                    </div>

                    <div
                      *ngIf="piezaTiempoMedioMap.get(maquina.id)!= 0 && maquina.ejecuciones[0].cantidad > 1 && tiempoActualLote.get(maquina.id) != '--:--:--'">
                      <div class="cycle-time-desviacion">
                        <kendo-label class="lote-actual-valor"
                          text="{{piezaTiempoMedioMap.get(maquina.id)}}"></kendo-label>
                      </div>
                      <!-- Estimatutako denborarekiko desbiderapena pieza -->
                      <div class="cycle-time-desviacion" *ngIf="!maquina.loading">
                        <div *ngIf="piezaDesviacionMap.get(maquina.id)?.toString().includes('+')">
                          <label style="color:red">
                            <kendo-label class="pieza-desviacion-valor"
                              text="{{piezaDesviacionMap.get(maquina.id)}}"></kendo-label>
                          </label>
                        </div>
                        <div *ngIf="piezaDesviacionMap.get(maquina.id)?.toString().includes('-')">
                          <kendo-label class="pieza-desviacion-valor"
                            text="{{piezaDesviacionMap.get(maquina.id)}}"></kendo-label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div class="margen-borde-inferior"></div>

                <!-- COL2 ROW2 -->
                <div class="row d-flex align-items-center lote-actual-cont">

                  <!-- PORTZENTAIA -->
                  <div class="col-3">
                    <!-- Grafiko borobila lote -->
                    <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{lotePorcentajeMap.get(maquina.id)}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{maquina.estadoimg}}" />
                      </div>
                    </div>
                  </div>

                  <!-- TIEMPO LOTE ACTUAL -->
                  <div class="col-5">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'loteActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{tiempoActualLote.get(maquina.id)}}</label>
                    </div>
                  </div>

                  <!-- DESBIDERAPENA -->
                  <div class="col-4">
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">Total time</label>
                    </div>
                    <div class="row d-flex justify-content-center"
                      *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('+')">
                      <label style="color:red">
                        <kendo-label class="lote-actual-valor"
                          text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                      </label>
                    </div>
                    <div class="row d-flex justify-content-center"
                      *ngIf="loteDesviacionMap.get(maquina.id)?.toString().includes('-')">
                      <kendo-label class="lote-actual-valor" text="{{loteDesviacionMap.get(maquina.id)}}"></kendo-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 cont-porcentaje-flecha-horno"
                *ngIf="verLote == 1 || maquina.ejecuciones[0].cantidad < 2">

                <!-- ROW 1 -->
                <div class="row d-flex align-items-center lote-actual-cont">
                  <div class="col-6">
                    <!-- Grafiko borobila lote -->
                    <label ID="lhora" *ngIf="" class="estado-fecha d-none">{{maquina.ejecuciones[0].fechaini}}</label>
                    <label ID="lfecha" class="estado-hora d-none">{{maquina.ejecuciones[0].horaini}}</label>
                    <div ID="enjecucion" class="porcent-cont-outer">
                      <div class="porcent-cont">
                        <label ID="lPorcentajeEjecucion" class="donut-porcent">
                          {{piezaPorcentajeMap.get(maquina.id)}}
                        </label>
                        <img ID="imgPorcentajeEjecucion" src="{{piezaimgMap.get(maquina.id)}}" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <!-- TIEMPO LOTE ACTUAL -->
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-titulo">{{ 'piezaActual' | translate}}</label>
                    </div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-valor">{{tiempoActualPieza.get(maquina.id)}}</label>
                    </div>
                    <!-- <div class="row d-flex justify-content-center"
                        *ngIf="maquina.ejecuciones[0].hechas == 0">
                        <label>{{tiempoActualLote.get(maquina.id)}}</label>
                    </div> -->
                  </div>
                </div>

                <div class="margen-borde-inferior"></div>

                <!-- ROW 2 -->
                <!-- <div class="lote-actual-cont">
                  <div>
                    <div class="row d-flex justify-content-center">
                      <label class="lote-actual-totaltime">Cycle time</label>
                    </div>

                    <div
                      *ngIf="piezaTiempoMedioMap.get(maquina.id)!= 0"
                      class="row">
                      <div class="col-5 cycle-time-desviacion">
                        <kendo-label class="lote-actual-valor"
                          text="{{piezaTiempoMedioMap.get(maquina.id)}}"></kendo-label>
                      </div>
                      <div class="col-2"></div>
                      <div class="col-5 cycle-time-desviacion" *ngIf="!maquina.loading">
                        <div *ngIf="piezaDesviacionMap.get(maquina.id)?.toString().includes('+')">
                          <label style="color:red">
                            <kendo-label class="lote-actual-valor"
                              text="{{piezaDesviacionMap.get(maquina.id)}}"></kendo-label>
                          </label>
                        </div>
                        <div *ngIf="piezaDesviacionMap.get(maquina.id)?.toString().includes('-')">
                          <kendo-label class="lote-actual-valor"
                            text="{{piezaDesviacionMap.get(maquina.id)}}"></kendo-label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div> -->

              </div>
            </div>
          </div>

        </div>

      </div>
    </ng-container>
  </div>

</div>