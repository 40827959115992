<kendo-grid [kendoGridBinding]="plantas"
            [sortable]="true"
            [navigable]="true"
            filterable="menu"
            kendoGridSelectBy="id"
            [selectedKeys]="seleccionados"
            (cellClick)="cellClick($event)">
  <!--BOTONES ARRIBA-->
  <ng-template kendoGridToolbarTemplate position="top">
    <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button (click)="onClickNuevo(planta_nueva)" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.plantasIsometrico<2">{{ 'eliminar' | translate}}</button>
  </ng-template>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--id-->
  <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--nombre-->
  <kendo-grid-column width="20%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <!--nombreSeccion-->
  <kendo-grid-column width="20%" field="nombreSeccion" title="{{ 'seccion' | translate}}"></kendo-grid-column>
  <!--nombreAreaProductiva-->
  <kendo-grid-column width="20%" field="nombreAreaProductiva" title="{{ 'areaProductiva' | translate}}"></kendo-grid-column>
  <!--alto-->
  <kendo-grid-column width="20%" field="alto" title="{{ 'alto' | translate}}"></kendo-grid-column>
  <!--ancho-->
  <kendo-grid-column width="20%" field="ancho" title="{{ 'ancho' | translate}}"></kendo-grid-column>

  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>

<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)"  [disabled]="this.user.plantasIsometrico<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<!--POPUP CREAR PLANTA -->
<ng-template #planta_nueva let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'textoCrearPlanta' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="caja">
        <kendo-textbox style="width: 98% !important;" [(ngModel)]="nombrePlantaNueva"></kendo-textbox>
        <label style="margin-left: 9px; color: red;" *ngIf="nombrePlantaNueva == ''">*</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="crearPlanta(contentloading)" [disabled]="nombrePlantaNueva == ''"  [disabled]="this.user.plantasIsometrico<2">{{ 'crear' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
  </div>
</ng-template>

<router-outlet></router-outlet>
