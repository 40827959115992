<!-- GRID -->
<kendo-grid [kendoGridBinding]="planesaccion" [height]="410" kendoGridSelectBy="id" filterable="menu" [selectedKeys]="planesseleccionado" (cellClick)="cellClick($event)" *ngIf="router.url === '/planesaccion'">
  <ng-template kendoGridToolbarTemplate position="top">
    <button id="btneditar" Class="btnEditatu btn btn-success btn-sm mr-1" (click)="onClickEditar()">{{ 'editar' | translate }}</button>
    <button id="btnnuevo" (click)="onClickNuevo()" Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</button>
    <button id="btneliminar" Class="btnEzabatu btn btn-danger btn-sm mr-1" (click)="onClickEliminar(content)" 
            [disabled]="this.user.planesDeAccion<2">{{ 'eliminar' | translate }}</button>
  </ng-template>
  <kendo-grid-checkbox-column showSelectAll="true" width="15%"></kendo-grid-checkbox-column>
  <kendo-grid-column field="id" hidden="hidden" title="ID" width="40"></kendo-grid-column>
  <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="250"></kendo-grid-column>
  <kendo-grid-column field="fechaini" title="{{ 'fechainicio' | translate }}" width="80"></kendo-grid-column>
  <kendo-grid-column field="fechafin" title="{{ 'fechafin' | translate }}" width="80"></kendo-grid-column>
  <kendo-grid-column field="responsable" title="{{ 'responsable' | translate }}" width="80"></kendo-grid-column>
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>
<router-outlet></router-outlet>

<!-- POPUP: Eliminar seguro? -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="Eliminar(contentloading)" [disabled]="this.user.planesDeAccion<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Eliminado -->
<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
