import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { HerramientasService, MenuService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService } from '@app/_services';
import { FileRestrictions, SelectEvent} from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-placas-detalle',
  templateUrl: './placasDetalle.component.html'
})
export class PlacasDetalleComponent {

  private translate: TranslateService;
  public events: string[] = [];
  public imagePreviews: any[] = [];
  public id: number;
  public requerido: boolean;
  public logo: string;
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
    maxFileSize: 1048576
  };
 

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;


  constructor(
    private formBuilder: FormBuilder,
    private herramientasService: HerramientasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private menuService: MenuService,
    translate: TranslateService) {

    this.translate = translate;

  }

  ngOnInit() {


    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.menuService.titulo = this.translate.instant('placatipos').toUpperCase();
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      archivo: new FormControl(''),
      vida: [0, ]
    });
   

    if (this.id > 0) {

      this.herramientasService.GetPlacaById(this.id).pipe().subscribe((result) => {
      
        if (result.data[0].imagenBase64 != "" && result.data[0].imagenBase64 != null) {
          this.imageToShow = result.data[0].imagenBase64;
        }

       
       
        this.logo = result.data[0].logo;
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre, Validators.required],
          archivo: new FormControl(''),
          vida: [result.data[0].vida]

        }); 
      })
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private async insert() {

    var filename = "";
   
    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }
    
   
    this.herramientasService.insertPlaca(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        {
          if (result.error == false) {
            this.router.navigate(['herramientas']);
          }
        }
      }
      );
  }

  private async update() {
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }
    this.form.value.id = this.id;
    this.herramientasService.updatePlaca(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        if (result.error == false) {
          this.router.navigate(['herramientas']);
        }
        else {
        }
      });
  }



  public atras() {
    this.router.navigate(['herramientas']);
  }
  //#region IMAGEN
  public myFiles = [];

  imageToShow: any;
  isImageLoading: boolean;

  public onUpload(ev): void {

    var re = /(?:\.([^.]+))?$/;
    this.isImageLoading = true;
    this.herramientasService.download(this.id + "." + re.exec(ev.files[0].name)[1]).subscribe(data => {

      var an: any = data
      this.createImageFromBase64(an.imagen);
      this.logo = an.imagen;
    }, error => {
    });
    
  }

  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  public onRemove(ev): void {
    this.myFiles = [];
    this.imageToShow = "";
    this.logo = "";
  }
  //#endregion
  public async selectEventHandler(e: SelectEvent) {
    const that = this;
   var file = e.files[0];
    e.files.forEach((file) => {
      that.log(`File selected: ${file.name}`);

      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });
    
    this.imagePreviews.forEach(element => { that.imageToShow = element.src;});
    this.imagePreviews = [];
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }
}

