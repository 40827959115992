import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TipoUsuario } from '@app/_models';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
const baseUrl = `${environment.apiUrl}/usuariostipos`;

@Injectable({
  providedIn: 'root'
})
export class UsuariosTiposService {

  constructor(private http: HttpClient) {
  }

  create(params, permisos) {
    permisos['nombre'] = params['nombre'];
    permisos['id'] = params['id'];
    permisos['id'] = params['id'];
    return this.http.post(baseUrl, permisos);
  }

  update(params, permisos) {
    permisos['nombre'] = params['nombre'];
    permisos['id'] = params['id'];
    permisos['id'] = params['id']; 
    return this.http.post(baseUrl + "/update", permisos);
  }

  getAll() {
    return this.http.get<TipoUsuario[]>(baseUrl);
  }
  public getById(id: string): Observable<any> {

    return this.http.get(baseUrl + "/" + id);
  }



  //delete(id: number) {
  // //var  data = { 'id': id };
  // // return this.http.post(baseUrl + "/delete", data);
  //  return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  //}
  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(`${baseUrl}/delete`, formData, { withCredentials: true });
  }
}
