<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)">

  <!-- TODO -->
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <!--PIEZA CARD-->
      <div id="divDatosPieza" class="card">
        <div class="card-header">
          <h3><label>{{ 'pieza' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <!--REFERENCIAS-->
              <div class="form-group">
                <label ID="lReferenciaPieza" class="control-label">{{ 'referencias' | translate }}</label>
                <div class="caja">
                  <kendo-combobox *ngIf="isAddMode" valuePrimitive="true" [allowCustom]="true"
                    formControlName="referencia" maxlength="80" [data]="referencias"
                    (selectionChange)="selectionChange($event)" [value]="referencia" class="form-control"
                    textField="referencia" valueField="referencia" [kendoDropDownFilter]="{operator: 'contains'}"
                    [class.is-invalid]="submitted && f.referencia.errors">
                  </kendo-combobox>
                  <kendo-textbox *ngIf="!isAddMode || user.ocultarParte" maxlength="80" formControlName="referencia"
                    [class.is-invalid]="submitted && f.referencia.errors" [readonly]="espieza==false"></kendo-textbox>
                  <div *ngIf="submitted && f.referencia.errors" class="invalid-feedback">
                    <div *ngIf="f.referencia.errors.required">{{ 'referenciaEsRequerida' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5">
              <!--NOMBRE-->
              <div class="form-group">
                <label ID="lNombrePieza" class="control-label"> {{ 'nombre' | translate }}</label>
                <div class="caja">
                  <kendo-textbox maxlength="80" formControlName="nombre" [readonly]="espieza==false"
                    [class.is-invalid]="submitted && f.nombre.errors"></kendo-textbox>
                  <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">{{ 'nombreEsrequerido' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-2">
              <!--NUMERO DE PLANO-->
              <div class="form-group">
                <label ID="lnumeroPlano" class="control-label"> {{ 'numplano' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="numplano" maxlength="50" [readonly]="espieza==false"
                    [class.is-invalid]="submitted && f.numplano.errors"></kendo-textbox>
                  <div *ngIf="submitted && f.numplano.errors" class="invalid-feedback">
                    <div *ngIf="f.numplano.errors.required">{{ 'numplanoRequerido' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <!--ID ERP-->
              <div class="form-group">
                <label ID="lERPPieza" class="control-label"> {{ 'iderp' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="idERPpieza" maxlength="50" [readonly]="espieza==false">
                  </kendo-textbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!--TIPO DE PIEZA-->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'tipopieza' | translate }}</label>
                <div class="caja">
                  <kendo-combobox [data]="tipoPiezaLista" [textField]="'nombre'" class="form-control"
                    [(ngModel)]="tipoPieza" [valueField]="'id'" formControlName="tipo_pieza"
                    (valueChange)="tipo_pieza_valueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"
                    [readonly]="espieza==false"></kendo-combobox>
                </div>
              </div>
            </div>


            <!--CHK ES PIEZA-->
            <div class="col">
              <div class="form-group">

                <label class="control-label">{{ 'tipo' | translate }}</label>
                <div class="caja">

                  <div class="btn mr-1" [class.btn-success]="espieza" [class.btn-outline-success]="!espieza">
                    <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnPieza()"
                      [checked]="espieza" kendoRadioButton [disabled]="true">
                    <label class="k-radio-label" for="btnEstimado">{{ 'pieza' | translate }}</label>
                  </div>
                  <div *ngIf="!user.ocultarParte" class="btn mr-1" [class.btn-success]="!espieza"
                    [class.btn-outline-success]="espieza">
                    <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnParte()"
                      [checked]="!espieza" kendoRadioButton [disabled]="true">
                    <label class="k-radio-label" for="btnPredictivo">{{ 'parte' | translate }}</label>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>

      <!--DIV parte-->
      <div *ngIf="!user.ocultarParte" class="" [hidden]="espieza==true">
        <div id="divDatosParte" class="card ">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left mr-2">
                <!--REFERENCIA PARTE-->
                <div class="form-group">
                  <label ID="lReferenciaParte" class="control-label">{{ 'referenciaparte' | translate }}</label>
                  <div class="caja">

                    <kendo-textbox formControlName="refparte" maxlength="50"
                      [class.is-invalid]="submitted && errorReferenciaParte"></kendo-textbox>
                    <div *ngIf="submitted && errorReferenciaParte" class="invalid-feedback">
                      <div *ngIf="errorReferenciaParte">{{ 'referenciaRequerida' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left mr-2">
                <!--PARTE-->
                <div class="form-group">
                  <label ID="lNombreParte" class="control-label">{{ 'parte' | translate }}</label>
                  <div class="caja">

                    <kendo-textbox formControlName="parte" maxlength="80"
                      [class.is-invalid]="submitted && errorNombreParte"></kendo-textbox>
                    <div *ngIf="submitted && errorNombreParte" class="invalid-feedback">
                      <div *ngIf="errorNombreParte">{{ 'parteRequerida' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left mr-2">
                <!--ORDEN-->
                <div class="form-group">
                  <label ID="lOrden" class="control-label">{{ 'orden' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="orden" [decimals]="decimals" [min]="0" [format]="'n'"
                      [class.is-invalid]="submitted && errorOrdenParte" [autoCorrect]="autoCorrect">
                    </kendo-numerictextbox>
                    <div *ngIf="submitted && errorOrdenParte" class="invalid-feedback">
                      <div *ngIf="errorOrdenParte">{{ 'ordenRequerido' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left mr-2">
                <div class="form-group">
                  <label ID="lERPParte" class="control-label">{{ 'iderp' | translate }}</label>
                  <div class="caja">
                    <kendo-textbox formControlName="idERPparte" maxlength="500"></kendo-textbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <!--DIV DATOS-->
      <div id="divDatosGlobales" class="card">
        <div class="card-header">
          <h3><label>{{ 'datos' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <!--CANTIDAD-->
            <!-- <div class="form-group col">
              <label class="control-label">{{ 'cantidad' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="cantidad" (valueChange)="onCantidadChange($event)"
                                      [decimals]="0" [min]="1" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div> -->
            <!--CANTIDAD AGRUPADA-->
            <!-- <div class="form-group col">
              <label class="control-label">{{ 'cantidadagrupada' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="cantidadAgrupada" (valueChange)="onCantidadChange($event)"
                                      [decimals]="0" [min]="0" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div> -->
            <!--HECHOS-->
            <!-- <div class="form-group col">
              <label class="control-label">{{ 'hechos' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="hechos" [decimals]="0" [min]="0" [format]="'n'"
                                      [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div> -->
            <!--COSTE-->
            <div class="form-group col-3">
              <label class="control-label">{{ 'coste' | translate }}</label>
              <div class="caja">


                <kendo-numerictextbox formControlName="coste" [decimals]="decimals" [min]="0" [format]="'n2'"
                  [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
            <!--DUREZA-->
            <div class="form-group col-3">
              <label class="control-label">{{ 'dureza' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="dureza" [decimals]="0" [min]="0" [max]="100" [format]="'n'"
                  [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
          </div>

          <div class="form-row">

            <!--USA LOTES-->
            <div class="form-group mr-2">
              <label class="control-label">{{ 'usaLotes' | translate }}</label>
              <div class="caja">
                <kendo-switch formControlName="usaLotes" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </div>


            <!--RUTAS MULTIPLES-->
            <div class="form-group mr-2">

              <!--
              <input type="checkbox" formControlName="tienerutas" [(ngModel)]="tienerutas" kendoCheckBox class="custom-control-input" id="customSwitch3" />
              -->
              <label class="control-label">{{ 'tienerutas' | translate }}</label>
              <div class="caja">
                <kendo-switch id="activarLink" formControlName="tienerutas" [onLabel]="' '" [offLabel]="' '"
                  (valueChange)="tieneRutasChange()" [(ngModel)]="tienerutas"></kendo-switch>
              </div>


            </div>
            <!--cantidad Lote-->
            <!-- <div class="form-group col">
              <label class="control-label">{{ 'cantidadLote' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="cantidadLote" [decimals]="0" [min]="0"
                                      [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div> -->

          </div>
          <div class="form-row">
            <!--IMAGEN-->
            <div class="img-up">
              <label ID="lCaracteristicas" Class="">{{ 'imagen' | translate }}</label>
              <ng-template #noImageFound>
                <img src="fallbackImage.png">
              </ng-template>
              <div class="form-group">
                <div *ngIf="imagePreviews.length; else initImage">
                  <img *ngFor="let image of imagePreviews" [src]="image.src" alt="image preview"
                    style="width: 200px; margin: 10px;" />
                </div>
                <ng-template #initImage>
                  <img [src]="imageToShow" style="max-height: 113px">
                </ng-template>
                <div class="" style="margin-bottom: 4em;">
                  <div class="caja">
                    <kendo-fileselect formControlName="archivo" class="form-control" [restrictions]="restrictions"
                      [multiple]="false" (select)="selectEventHandler($event)">
                      <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                        clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                        dropFilesHere="{{ 'dropFilesHere' | translate }}"
                        externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                        fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                        fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                        filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                        filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                        filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                        headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                        headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                        headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                        invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                        invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                        invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                        remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                        retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                        uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                      </kendo-upload-messages>
                    </kendo-fileselect>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!--DESCRIPCION-->
          <div class="form-group">
            <label ID="lDescripcion" class="control-label  descripcion-label-mant">{{ 'descripcion' | translate
              }}</label>

            <textarea kendoTextArea formControlName="tadescripcion" [autoSize]="false" maxlength="500"></textarea>
          </div>
        </div>
      </div>

    </div>


  </div>
  <!--TABS-->
  <div *ngIf="isAddMode==false">
    <kendo-tabstrip #tabstrip [keepTabContent]="true" (tabSelect)="onTabSelected($event)">
      <!-- OPERACIONES -->
      <kendo-tabstrip-tab
        *ngIf="(!espieza || (espieza && (comboPartes == undefined || comboPartes.length == 0 ))) && !form.value.tienerutas"
        id="taboperaciones" [title]="operacionessstr">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [height]="410" [data]="operaciones" (cellClick)="cellClick($event)" kendoGridSelectBy="id"
              [selectedKeys]="operacionesseleccionados" filterable="menu" [navigable]="true" [sortable]="true"
              [resizable]="true">
              <ng-template kendoGridToolbarTemplate position="top">
                <button [disabled]="!botonesActivados" id="btneditar" Class="btnEditatu btn btn-success btn-sm mr-1"
                  (click)="onClickEditarOperacion($event)">{{
                  'editar' | translate }}</button>
                <a id="btnnuevo" (click)="onClickNuevaOperacion()" Class="btnBerria btn btn-primary btn-sm mr-1">{{
                  'nuevo' | translate }}</a>
                <a id="btnnuevopred" (click)="onClickNuevaOperacionPredefinida()"
                  Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'anadirpred' | translate }}</a>
                <button id="btneliminar" [disabled]="!botonesActivados || this.user.piezasPredefinidas < 2"
                  Class="btnEzabatu btn btn-danger btn-sm mr-1" (click)="onClickEliminarOperacion(contentEliminarOperacion, $event)">{{
                  'eliminar' | translate }}</button>
                <button id="btnexportar" [disabled]="!botonesActivados" kendoGridExcelCommand
                  Class="btnExcel btn btn-excel btn-sm mr-1">{{
                  'exportarexcel' | translate }}</button>
                  <div class="form-group mb-0 float-right"
                    style="float: right; margin-left: 20px; margin-right: 20px">
                    <div class="form-row">
                      <label class="control-label mt-1 mr-2">{{ 'importarCotas' | translate }}</label>
                      <kendo-upload (upload)="onUploadButtonClick($event,contentloadingupload)"
                        [restrictions]="{allowedExtensions: ['.xlsx']  }" [(value)]="myFiles2"
                        [disabled]="this.user.calidad<2">
                        <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                          clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                          dropFilesHere="{{ 'dropFilesHere' | translate }}"
                          externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                          fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                          fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                          filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                          filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                          filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                          headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                          headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                          headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                          invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                          invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                          invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                          pause="{{ 'pause' | translate }}" remove="{{ 'remove' | translate }}"
                          resume="{{ 'resume' | translate }}" retry="{{ 'retry' | translate }}"
                          select="{{ 'select' | translate }}"
                          uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-upload>
                  </div>
              </div>
              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
              <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%"
                [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%"
                [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="16%">
              </kendo-grid-column>
              <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="16%"
                class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span *ngIf="dataItem.maquina!=null" class="tooltip-consumibles-contenido">
                    <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                      <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'"
                        class="grid-circulo" style="background-color:white!important;"
                        [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                      <span *ngIf="dataItem.maquinasC && dataItem.maquinasC !='undefined' && dataItem.maquinasC.split(';and;')[i]=='null' &&  maquina!='undefined'"
                        class="grid-circulo"
                        [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
                    </ng-container>
                    <span class="tooltiptext">
                      <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                        <label *ngIf="maquina!='undefined'">
                          {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
                        </label>
                        <label *ngIf="maquina=='undefined'">
                          {{'desconocido' | translate}}
                        </label>
                      </ng-container>
                    </span>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%">
              </kendo-grid-column>
              <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}"
                width="17%"> </kendo-grid-column>
              <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}"
                width="17%"> </kendo-grid-column>
              <kendo-grid-excel fileName="{{ 'operaciones' | translate }}.xlsx"  [fetchData]="allData">
                <kendo-excelexport-column field="orden" title="{{ 'orden' | translate }}"> </kendo-excelexport-column>
                <kendo-excelexport-column field="secuencia" title="{{ 'secuencia' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="operacion" title="{{ 'operacion' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="tipo2" title="{{ 'tipoOperacion' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}">
                </kendo-excelexport-column>
                <kendo-excelexport-column field="tiempoEstimadoPreparacion"
                  title="{{ 'tiempoEstimadoPreparacion' | translate }}"> </kendo-excelexport-column>
                <kendo-excelexport-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}">
                </kendo-excelexport-column>
              </kendo-grid-excel>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- RUTAS -->
      <kendo-tabstrip-tab
        *ngIf="(!espieza || (espieza && (comboPartes == undefined || comboPartes.length == 0 ))) && form.value.tienerutas"
        id="tabrutas" [title]="rutasstr">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [height]="410" [data]="rutas" (cellClick)="cellClickRutas($event)" kendoGridSelectBy="id"
              [selectedKeys]="rutasseleccionados" filterable="menu" [navigable]="true" [sortable]="true"
              [resizable]="true">

              <ng-template kendoGridToolbarTemplate position="top">
                <a id="btneditar" [disabled]="!botonesActivados" Class="btnEditatu btn btn-success btn-sm mr-1"
                  (click)="onClickEditarRuta()">{{
                  'editar' | translate }}</a>
                <a id="btnnuevo" (click)="onClickNuevaRuta()" Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'nuevo' |
                  translate }}</a>
                <button id="btneliminar" [disabled]="unicaRutaPrincipal || this.user.piezasPredefinidas < 2"
                  Class="btnEzabatu btn btn-danger btn-sm mr-1"
                  (click)="onClickEliminarRuta(contenteliminarRuta, $event)">{{ 'eliminar' | translate }}</button>

              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="10%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="id" hidden="hidden" title="ID" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="100%">
              </kendo-grid-column>

              <kendo-grid-column field="principal" width="15%" title="{{ 'principal' | translate }} "
                [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <input type="checkbox" [checked]="dataItem.principal" disabled />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- DEPENDENCIAS -->
      <kendo-tabstrip-tab *ngIf="espieza && !(comboPartes == undefined || comboPartes.length == 0 )"
        id="tabdependencias" title="{{ 'dependencias' | translate }}">
        <ng-template kendoTabContent [height]="410">
          <!-- TITULOS -->
          <div class="clearfix">
            <div class="dependencias-titulo-orden">
              <label class=""></label>
            </div>
            <div class="dependencias-titulo-padre">
              <label class="">{{ 'parte' | translate }}</label>
            </div>
            <div class="dependencias-titulo-hijo">
              <label class="">{{ 'subParte' | translate }}</label>
            </div>
            <div class="dependencias-titulo-cantidad">
              <label class="">{{ 'cantidad' | translate }}</label>
            </div>
          </div>
          <!-- TABLA DE CONTENIDO -->
          <div *ngIf="espieza && dependenciasPieza.length > 0">
            <div *ngFor="let dependencia of dependenciasPieza" class="clearfix">
              <div class="dependencias-orden">
                <label class="dependencias-orden-label">{{ indexOf(dependenciasPieza, dependencia) + 1 }} . </label>
              </div>
              <div class="dependencias-padre">
                <kendo-dropdownlist
                  (selectionChange)="parteDependenciaSeleccionada('padre', dependencia, $event); dependencia.id = -1;"
                  [data]="cargaropcionesCombo('padre',dependencia)" [textField]="'nombre'" [valueField]="'id'"
                  [(value)]="dependencia.padre"></kendo-dropdownlist>
              </div>
              <div class="dependencias-hijo">
                <kendo-dropdownlist
                  (selectionChange)="parteDependenciaSeleccionada('hijo', dependencia,  $event); dependencia.id = -1;"
                  [data]="cargaropcionesCombo('hijo', dependencia)" [textField]="'nombre'" [valueField]="'id'"
                  [(value)]="dependencia.hijo"></kendo-dropdownlist>
              </div>
              <div class="dependencias-cantidad">
                <kendo-numerictextbox (valueChange)="dependencia.id = -1;" format="0" [min]="-9999999" [max]="9999999"
                  [(value)]="dependencia.cantidad"></kendo-numerictextbox>
              </div>
              <!-- ELIMINAR -->
              <button *ngIf="dependencia.padre.id > 0 && dependencia.hijo.id > 0"
                Class="flt-eliminar dependencias-eliminar mt-1" (click)="borrarDependencia(dependencia)"
                [disabled]="this.user.piezasPredefinidas < 2"> <i class="fas fa-times"></i> </button>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- CAMBIOS -->
      <kendo-tabstrip-tab id="tabhistoricocambios" [title]="historicocambiosstr">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [height]="410" [data]="historicocambios">
              <kendo-grid-column field="id" hidden="hidden" title="ID" width="90">
              </kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="operario" title="{{ 'operador' | translate }}" width="20%">
              </kendo-grid-column>
              <kendo-grid-column field="campo" title="{{ 'campo' | translate }}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span>{{ dataItem.campo.split("_")[0] | translate }} {{ dataItem.campo.split("_")[1] }}
                    {{ dataItem.campo.split("_")[2] | translate }} {{ dataItem.campo.split("_")[3] | translate }}
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valorAnterior" title="{{ 'valoranterior' | translate }}" width="15%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span *ngIf="dataItem.valorAnteriorTrad == 'traducir'">{{ dataItem.valorAnterior | translate}}</span>
                  <span *ngIf="dataItem.valorAnteriorTrad == 'fecha'">{{ dataItem.valorAnterior | kendoDate}}</span>
                  <span *ngIf="dataItem.valorAnteriorTrad != 'traducir' && dataItem.valorAnteriorTrad != 'fecha'">{{
                    dataItem.valorAnterior}}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="valorNuevo" title="{{ 'valornuevo' | translate }}" width="15%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span *ngIf="dataItem.valorNuevoTrad == 'traducir'">{{ dataItem.valorNuevo | translate}}</span>
                  <span *ngIf="dataItem.valorNuevoTrad == 'fecha'">{{ dataItem.valorNuevo | kendoDate}}</span>
                  <span *ngIf="dataItem.valorNuevoTrad != 'traducir' && dataItem.valorNuevoTrad != 'fecha'">{{
                    dataItem.valorNuevo}}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate }}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span>{{ dataItem.fecha | kendoDate }} {{ dataItem.fecha | kendoDate:'HH:mm:ss' }}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>

            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- DESTION DOCUMENTAL -->
      <kendo-tabstrip-tab id="tabgestiondocumental" [title]="gestiondocumentalstr">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [kendoGridBinding]="gestiondocumental" [sortable]="true" kendoGridSelectBy="id"
              [navigable]="true" filterable="menu" (cellClick)="onCellClickGestionDocumental($event)"
              [selectedKeys]="mySelectionGestionDocumental">
              <ng-template kendoGridToolbarTemplate position="top">
                <button kendoButton (click)="onClickEditarGestionDocumental($event)"
                  [disabled]="loadingGestionDocumental || (gestiondocumental && gestiondocumental.length == 0)"
                  class="btn mr-1  btn-success btn-sm mr-1"> {{
                  'editar' | translate}} </button>
                <button kendoButton (click)="onClickNuevoGestionDocumental($event)"
                  class="btn mr-1  btn-primary btn-sm mr-1">
                  {{
                  'nuevo' | translate}} </button>
                <button kendoButton (click)="onClickEliminarGestionDocumental($event)"
                  class="btn mr-1  btn-danger btn-sm mr-1"
                  [disabled]="loadingGestionDocumental || (gestiondocumental && gestiondocumental.length == 0) || this.user.piezasPredefinidas < 2">
                  {{ 'eliminar' | translate}} </button>
              </ng-template>
              <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
              <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}">
              </kendo-grid-column>
              <kendo-grid-column width="40%" field="fichero" title="{{ 'fichero' | translate}}">
              </kendo-grid-column>
              <!-- <kendo-grid-column [style]="{'text-align': 'right'}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn-icon" (click)="viewPDF(dataItem)"><span
                          class="k-icon k-i-file-pdf"></span></button>
                </ng-template>
              </kendo-grid-column> -->
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>

          <div *ngIf="loadingGestionDocumental" class="k-i-loading"></div>
        </ng-template>
      </kendo-tabstrip-tab>
      <!-- TAB LIMITES -->
      <!-- <kendo-tabstrip-tab id="tablimites" [title]="limitesstr">
        <ng-template kendoTabContent> -->
      <!--DIV LIMITES-->
      <!-- <div id="DIVLimites">
            <label ID="LLimites" class="control-label bloque-titulo"></label>
            <div class="form-group">
              <div class="caja">
                <div class="clearfix"> -->
      <!-- PRIORIDAD -->
      <!-- <div class="float-left mr-2">
                    <div class="form-group">
                      <label ID="Lprioridad" class="control-label">{{ 'prioridad' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="prioridad" formControlName="prioridad" [format]="'n'"
                                              [decimals]="decimals" [min]="0" [max]="100" [autoCorrect]="autoCorrect">
                        </kendo-numerictextbox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab> -->
    </kendo-tabstrip>
  </div>
  <div class="form-group mt-2">
    <div *ngIf="isAddMode == true">
      <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.piezasPredefinidas < 2">
        {{ 'guardar' | translate}}
      </button>
      <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="Atras()">
        {{ 'cancelar' | translate}}
      </button>
      <!-- <a class="btn btn-copiar btn-sm mr-1" (click)="onClickDuplicarPieza(contentloading)"
        [class.disabled]="this.user.piezasPredefinidas < 2">
        {{ 'duplicar' | translate}}
      </a> -->
    </div>
    <div *ngIf="isAddMode == false">
      <button (click)="onClickGuardar(content, $event)" class="btn btn-primary btn-sm mr-1"
        [disabled]="this.user.piezasPredefinidas < 2">
        {{ 'guardar' | translate}}
      </button>
      <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="Atras()">
        {{ 'cancelar' | translate}}
      </button>
      <a class="btn btn-copiar btn-sm mr-1" (click)="onClickDuplicarPieza(contentloading)"
        [class.disabled]="this.user.piezasPredefinidas < 2">
        {{ 'duplicar' | translate}}
      </a>
    </div>

  </div>

</form>



<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'editarPiezasRelacionadas' | translate}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="actualizar2(contentloading, 1)"
      [disabled]="this.user.piezasPredefinidas < 2">{{'siTodasPiezas' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="actualizar2(contentloading, 0)"
      [disabled]="this.user.piezasPredefinidas < 2">{{'siPiezasNoFinalizadas' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="actualizar2(contentloading, 2)"
    [disabled]="this.user.piezasPredefinidas < 2">{{'no' | translate}} </button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"
      [disabled]="this.user.piezasPredefinidas < 2">{{ 'cancelar' | translate }}</button>
  </div>
</ng-template>



<!--POPUP ELIMINAR

<button (click)="onClickEliminar(content, $event)" [disabled]="!botonesActivados || this.user.piezasPredefinidas < 2" class="btn btn-danger btn-sm mr-1" >{{ 'eliminar' | translate}}</button>

<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading, contentNotErased)" 
            [disabled]="this.user.piezasPredefinidas < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>-->

<ng-template #contentEliminarOperacion let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="EliminarOperacion(contentloading)"
      [disabled]="this.user.piezasPredefinidas < 2">{{ 'si' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #contenteliminarRuta let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="EliminarRuta(contentloading)"
      [disabled]="this.user.piezasPredefinidas < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup"></p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<ng-template #contentloadingupload let-modal>

  <div class="modal-body">
    <p>{{ 'importando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'importando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<!-- POPUP: -->
<ng-template #anadirOperacionPred let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid style="height: 780px" [kendoGridBinding]="operacionesPred" [sortable]="true" [navigable]="true"
      filterable="menu" [resizable]="true" kendoGridSelectBy="id" [selectedKeys]="operacionesPredSelected"
      scrollable="virtual" [rowHeight]="36" [pageSize]="50" [height]="500">
      <kendo-grid-checkbox-column showSelectAll="true" width="5%"></kendo-grid-checkbox-column>
      <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
      <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}">
      </kendo-grid-column>
      <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%"
        [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="16%">
      </kendo-grid-column>
      <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="16%" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.maquina!=null" class="tooltip-consumibles-contenido">
            <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
              <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo"
                style="background-color:white!important;"
                [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
              <span *ngIf="dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined'" class="grid-circulo"
                [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
            </ng-container>
            <span class="tooltiptext">
              <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                <label *ngIf="maquina!='undefined'">
                  {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
                </label>
                <label *ngIf="maquina=='undefined'">
                  {{'desconocido' | translate}}
                </label>
              </ng-container>
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
      </kendo-grid-column>
      <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%">
      </kendo-grid-column>
      <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}"
        width="17%"> </kendo-grid-column>
      <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}" width="17%">
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click');onClickCancelar()">{{ 'cancelar'
      | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="onClickAceptarAnadirPred()"
      [disabled]="this.user.piezasPredefinidas < 2">
      {{ 'aceptar' | translate}}</button>
  </div>
</ng-template>

<ng-template #popupBorrarDocumentos let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntaeliminarpopup' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="btnBorrarDocumentos()"
      [disabled]="this.user.piezasPredefinidas < 2">{{ 'aceptar' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
  </div>
</ng-template>