import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, MaterialesTipoService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { validateLocaleAndSetLanguage } from 'typescript';

@Component({
  selector: 'app-materialesTipoDetalle',
  templateUrl: './materialesTipoDetalle.component.html'
})
export class MaterialesTipoDetalleComponent implements OnInit {

  navigationSubscription;

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  tipoOeeLista: any;
  tipoOeeSeleccionado: any;
  user = this.userService.userValue;

  constructor(
    private materialesTipoService: MaterialesTipoService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private alertService: AlertService,
    private userService: UsuariosService,
    private menuService: MenuService
  ) {

    this.menuService.titulo = this.translateService.instant('materialestipo').toUpperCase();

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      nombre: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      dureza: new FormControl(0, [Validators.required]),
      referencia: new FormControl(''),
      kc: new FormControl(0),
    });

    if (!this.isAddMode) {
      this.materialesTipoService.getById(this.id).pipe(first()).subscribe((result) => {
        this.form.patchValue(result);
      });
    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearTipoMaterial();
    } else {
      this.updateTipoMaterial();
    }

  }

  private crearTipoMaterial() {
    this.materialesTipoService.create(-1, this.form.value.nombre, this.form.value.dureza, this.form.value.referencia, this.form.value.kc)
      .pipe(first()) 
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['materialestipo/']);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateTipoMaterial() {
    this.materialesTipoService.update(this.form.value.id, this.form.value.nombre, this.form.value.dureza, this.form.value.referencia, this.form.value.kc)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['materialestipo/']);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

}
