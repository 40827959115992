<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <!--LINEA 1-->
  <div class="card">
    <div class="card-body">
      <div class="clearfix">

        <div class="float-left ml-2">
          <!--Nombre-->
          <kendo-label text="{{ 'nombre' | translate }}">
            <kendo-textbox formControlName="nombre"></kendo-textbox>
          </kendo-label>
        </div>

        <div class="float-left ml-2">
          <!--Orden-->
          <kendo-label text="{{ 'orden' | translate}}">
            <kendo-numerictextbox class="form-control" formControlName="orden" [min]="1" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
          </kendo-label>
        </div>

        <div class="float-left ml-3 mt-4">
          <!--Motor-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="motor" kendoCheckBox class="custom-control-input" id="motorId" />
              <label class="custom-control-label" for="motorId">{{ 'motor' | translate }}</label>
            </div>
          </div>
        </div>

        <div class="float-left ml-3 mt-4">
          <!--Principal-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="principal" kendoCheckBox class="custom-control-input" id="principalId" />
              <label class="custom-control-label" for="principalId">{{ 'principal' | translate }}</label>
            </div>
          </div>
        </div>

        <div class="float-left ml-3 mt-4">
          <!--Visible-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="visible" kendoCheckBox class="custom-control-input" id="visibleId" />
              <label class="custom-control-label" for="visibleId">{{ 'visible' | translate }}</label>
            </div>
          </div>
        </div>

        <div class="float-left ml-3 mt-4">
          <!--Activo-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="activo" kendoCheckBox class="custom-control-input" id="activoId" />
              <label class="custom-control-label" for="activoId">{{ 'activo' | translate }}</label>
            </div>
          </div>
        </div>

        <div class="float-left ml-2">
          <!--Posicion del eje en la maquina-->
          <kendo-label text="{{ 'posicionDelEjeEnLaMaquina' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="posicionDelEjeEnLaMaquina" [min]="0" [max]="99" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
          </kendo-label>
        </div>

        <div class="float-left ml-2">
          <!--Canal-->
          <kendo-label text="{{ 'canal' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="canal" [min]="-1" [max]="10" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
          </kendo-label>
        </div>

        <div class="float-left ml-2">
          <!--Posicion canal-->
          <kendo-label text="{{ 'posCanal' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="posCanal" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
          </kendo-label>
        </div>

      </div>
    </div>
  </div>

  <!--LINEA 2-->
  <div class="row">
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'feed' | translate }}</label>
          </h3>
        </div>
        <div class="card-body">

          <!--Feed-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="feed" kendoCheckBox class="custom-control-input" id="feedId" />
              <label class="custom-control-label" for="feedId">{{ 'feed' | translate }}</label>
            </div>
          </div>

          <!--Visible-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="feedVisible" kendoCheckBox class="custom-control-input" id="feedVisibleId" />
              <label class="custom-control-label" for="feedVisibleId">{{ 'visible' | translate }}</label>
            </div>
          </div>

          <h5>{{ 'rango' | translate }}</h5>

          <!--Minimo-->
          <kendo-label text="{{ 'minimo' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="feedMinimo" [min]="0" [max]="100000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango1-->
          <kendo-label text="{{ 'rango1' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="feedRango1" [min]="0" [max]="100000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango2-->
          <kendo-label text="{{ 'rango2' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="feedRango2" [min]="0" [max]="100000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango3-->
          <kendo-label text="{{ 'rango3' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="feedRango3" [min]="0" [max]="100000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Maximo-->
          <kendo-label text="{{ 'maximo' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="feedMaximo" [min]="0" [max]="100000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'consumo' | translate }}</label>
          </h3>
        </div>
        <div class="card-body">

          <!--Consumo-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="consumo" kendoCheckBox class="custom-control-input" id="consumoId" />
              <label class="custom-control-label" for="consumoId">{{ 'consumo' | translate }}</label>
            </div>
          </div>

          <!--Visible-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="consumoVisible" kendoCheckBox class="custom-control-input" id="consumoVisibleId" />
              <label class="custom-control-label" for="consumoVisibleId">{{ 'visible' | translate }}</label>
            </div>
          </div>

          <!--posConsumo1-->
          <div class="form-group">
            <kendo-label text="{{ 'posConsumo1' | translate}}">
              <kendo-numerictextbox class="form-control" formControlName="posConsumo1" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>

          <!--posConsumo2-->
          <div class="form-group">
            <kendo-label text="{{ 'posConsumo2' | translate}}">
              <kendo-numerictextbox class="form-control" formControlName="posConsumo2" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>

          <h5>{{ 'rango' | translate }}</h5>

          <!--Minimo-->
          <kendo-label text="{{ 'minimo' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="consumoMinimo" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango1-->
          <kendo-label text="{{ 'rango1' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="consumoRango1" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango2-->
          <kendo-label text="{{ 'rango2' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="consumoRango2" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango3-->
          <kendo-label text="{{ 'rango3' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="consumoRango3" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Maximo-->
          <kendo-label text="{{ 'maximo' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="consumoMaximo" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'temperatura' | translate }}</label>
          </h3>
        </div>
        <div class="card-body">

          <!--Temperatura-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="temperatura" kendoCheckBox class="custom-control-input" id="temperaturaId" />
              <label class="custom-control-label" for="temperaturaId">{{ 'temperatura' | translate }}</label>
            </div>
          </div>

          <!--Visible-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="temperaturaVisible" kendoCheckBox class="custom-control-input" id="temperaturaVisibleId" />
              <label class="custom-control-label" for="temperaturaVisibleId">{{ 'visible' | translate }}</label>
            </div>
          </div>

          <!--posTemperatura1-->
          <div class="form-group">
            <kendo-label text="{{ 'posTemperatura1' | translate}}">
              <kendo-numerictextbox class="form-control" formControlName="posTemperatura1" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>

          <!--posTemperatura2-->
          <div class="form-group">
            <kendo-label text="{{ 'posTemperatura2' | translate}}">
              <kendo-numerictextbox class="form-control" formControlName="posTemperatura2" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
            </kendo-label>
          </div>

          <h5>{{ 'rango' | translate }}</h5>

          <!--Minimo-->
          <kendo-label text="{{ 'minimo' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="temperaturaMinimo" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango1-->
          <kendo-label text="{{ 'rango1' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="temperaturaRango1" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango2-->
          <kendo-label text="{{ 'rango2' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="temperaturaRango2" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Rango3-->
          <kendo-label text="{{ 'rango3' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="temperaturaRango3" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

          <!--Maximo-->
          <kendo-label text="{{ 'maximo' | translate }}">
            <kendo-numerictextbox class="form-control" formControlName="temperaturaMaximo" [min]="0" [max]="1000" [autoCorrect]="true" [format]="'n2'"></kendo-numerictextbox>
          </kendo-label>

        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'posicion' | translate }}</label>
          </h3>
        </div>
        <div class="card-body">

          <!--Posicion-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="posicion" kendoCheckBox class="custom-control-input" id="posicionId" />
              <label class="custom-control-label" for="posicionId">{{ 'posicion' | translate }}</label>
            </div>
          </div>

          <!--Visible-->
          <div class="form-group">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="posicionVisible" kendoCheckBox class="custom-control-input" id="posicionVisibleId" />
              <label class="custom-control-label" for="posicionVisibleId">{{ 'visible' | translate }}</label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

  <!--BOTONES-->
  <div class="form-group">
    <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">
      {{ 'guardar' | translate}}
    </button>
    <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
      {{ 'cancelar' | translate}}
    </button>
  </div>

</form>
