<kendo-grid [kendoGridBinding]="dataToleranciasTipo"
                [sortable]="true"
                [navigable]="true"
                filterable="menu"
                kendoGridSelectBy="id"
                [selectedKeys]="seleccionados"
                (cellClick)="cellClick($event)"
                *ngIf="router.url === '/toleranciastipo'">
      <ng-template kendoGridToolbarTemplate position="top">
        <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
        <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
        <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.tolerancias < 2">{{ 'eliminar' | translate}}</button>
      </ng-template>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--id-->
  <kendo-grid-column  field="id" hidden="hidden" title="id"></kendo-grid-column>
  <kendo-grid-column width="25%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <kendo-grid-column width="25%" field="tipoNombre" title="{{ 'tipo' | translate}}"></kendo-grid-column>
  <kendo-grid-column width="25%" field="tipoValor" title="{{ 'tipoValor' | translate}}"></kendo-grid-column>
  <kendo-grid-column width="15%" field="simbolo" title="{{ 'simbolo' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <img *ngIf="dataItem.simboloBase64 != ''" src="{{ dataItem.simboloBase64 }}" height="30" width="30" alt="{{ dataItem.simbolo }}"/>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="7%" field="activo" title="{{ 'activo' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.activo" disabled/>
    </ng-template>
  </kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>
  
<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading, contentNotErased)"  [disabled]="this.user.tolerancias < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
  
<router-outlet></router-outlet>
  