<form [formGroup]="form">
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <!--DIV DATOS-->
            <div class="card">
                <div class="card-header">
                    <h3><label>{{ 'datos' | translate }}</label></h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 30%">
                            <kendo-label text="{{ 'referencia' | translate}}">
                                <div class="caja">
                                    <kendo-textbox formControlName="referencia" maxlength="150"
                                        [ngClass]="{ 'is-invalid': submitted && f.referencia.errors }">
                                    </kendo-textbox>
                                    <div *ngIf="submitted && f.referencia.errors" class="invalid-feedback">
                                        <div *ngIf="f.referencia.errors.required">{{ 'referenciaEsRequerida' |
                                            translate}}</div>
                                    </div>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group mr-2" style="width: 30%">
                            <kendo-label text="{{ 'nombre' | translate}}">
                                <div class="caja">
                                    <kendo-textbox formControlName="nombre" maxlength="150"
                                        [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                                    </kendo-textbox>
                                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                        <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' |
                                            translate}}</div>
                                    </div>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group mr-2" style="width: 30%">
                            <kendo-label text="{{ 'nserie' | translate}}">
                                <div class="caja">
                                    <kendo-textbox formControlName="numeroSerie" maxlength="150">
                                    </kendo-textbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <!--OBSERVACIONES-->
                        <div class="form-group col">
                            <label>{{ 'descripcion' | translate }}</label>
                            <div class="caja">
                                <textarea formControlName="descripcion" class="form-control"
                                    style="height: 7em;"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 20%">
                            <kendo-label text="{{ 'tiposActivo' | translate}}">
                                <div class="caja">
                                    <kendo-combobox clearButton="true" valuePrimitive="true" [disabled]="!activosTipos"
                                        [data]="activosTipos" [allowCustom]="false" [(value)]="selectedTipoActivo"
                                        class="form-control" textField="nombre" valueField="id">
                                    </kendo-combobox>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group mr-2" style="width: 20%">
                            <kendo-label text="{{ 'jerarquiaActivos' | translate}}">
                                <div class="caja">
                                    <kendo-combobox clearButton="true" valuePrimitive="true"
                                        [disabled]="!jerarquiaActivos" [(value)]="selectedJerarquiaActivo"
                                        [data]="jerarquiaActivos" [allowCustom]="false" class="form-control"
                                        textField="tipo" valueField="id">
                                    </kendo-combobox>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group mr-2" style="width: 10%">
                            <kendo-label text="{{ 'vidaUtil' | translate}}">
                                <div class="caja">
                                    <kendo-numerictextbox class="form-control" formControlName="vidaUtil" [min]="0.00" (valueChange)="recalcularFecha()"
                                        [max]="999.00" [autoCorrect]="true" [step]="0.1">
                                    </kendo-numerictextbox>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group" style="width: 10%">
                            <label>{{ 'stock' | translate }}</label>
                            <div class="caja" style="margin: 0 0 0 7px;">
                                <kendo-switch [(ngModel)]="form.value.stock" formControlName="stock" [onLabel]="' '"
                                    [offLabel]="' '"></kendo-switch>
                            </div>
                        </div>
                        <div class="form-group mr-2" style="width: 35%">
                            <kendo-label text="{{ 'EAN' | translate}}">
                                <div class="caja">
                                    <kendo-textbox formControlName="codigoEAN" maxlength="150">
                                    </kendo-textbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <!--DIV datos2-->
            <div class="card">
                <div class="card-header">
                    <h3>
                        <label>{{ 'datos' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 45%">
                            <kendo-label text="{{ 'fechaCompra' | translate}}">
                                <div class="caja">
                                    <kendo-datepicker formControlName="fechaCompra" 
                                        class="form-control">
                                    </kendo-datepicker>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group" style="width: 45%">
                            <kendo-label text="{{ 'seccion' | translate}}">
                                <div class="caja">
                                    <kendo-combobox clearButton="true" valuePrimitive="true" [disabled]="!secciones"
                                        [data]="secciones" [allowCustom]="false" [(value)]="selectedSection"
                                        class="form-control" textField="nombre" valueField="id">
                                    </kendo-combobox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 45%">
                            <kendo-label text="{{ 'fechaPuestaEnServicio' | translate}}">
                                <div class="caja">
                                    <kendo-datepicker formControlName="fechaPuestoEnServicio" (valueChange)="recalcularFecha()"
                                        class="form-control">
                                    </kendo-datepicker>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group" style="width: 45%">
                            <kendo-label text="{{ 'proveedor' | translate}}">
                                <div class="caja">
                                    <kendo-combobox clearButton="true" valuePrimitive="true" [disabled]="!proveedores"
                                        [data]="proveedores" [allowCustom]="false" [(value)]="selectedProveedor"
                                        class="form-control" textField="nombre" valueField="id">
                                    </kendo-combobox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 45%">
                            <kendo-label text="{{ 'fechaBajaEstimada' | translate}}">
                                <div class="caja">
                                    <kendo-datepicker formControlName="fechaBajaEstimada" [(ngModel)]="this.form.value.fechaBajaEstimada" [disabled]="true"
                                        class="form-control">
                                    </kendo-datepicker>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group" style="width: 50%">
                            <kendo-label text="{{ 'precio' | translate}}">
                                <div class="caja">
                                    <kendo-numerictextbox class="form-control"  [min]="0.0"
                                        [max]="999.0" [autoCorrect]="true" [step]="0.1" formControlName="precio">
                                    </kendo-numerictextbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 45%">
                            <kendo-label text="{{ 'baja' | translate}}">
                                <div class="caja">
                                    <kendo-switch [(ngModel)]="form.value.baja" formControlName="baja" [onLabel]="' '"
                                    [offLabel]="' '"></kendo-switch>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="form-group" style="width: 50%">
                            <kendo-label text="{{ 'garantia' | translate}}">
                                <div class="caja">
                                    <kendo-numerictextbox class="form-control"  [min]="0" formControlName="garantia" 
                                        [max]="999" [autoCorrect]="true" [step]="1"  [format]="'n'">
                                    </kendo-numerictextbox>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group mr-2" style="width: 45%">
                            <kendo-label text="{{ 'fechaBaja' | translate}}">
                                <div class="caja">
                                    <kendo-datepicker formControlName="fechaBaja" 
                                        class="form-control">
                                    </kendo-datepicker>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <!--DIV imagen-->
            <div class="card">
                <div class="card-header">
                    <h3>
                        <label>{{ 'imagen' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <!--IMAGEN VIEJA-->
                  <div>
                    <img [src]="imageToShow" style="max-height: 113px">
                  </div>
                  <div class="form-group w-50">
                    <div class="caja">
      
                      <kendo-fileselect formControlName="archivo" [restrictions]="restrictionsImage" [multiple]="false" (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                        <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'filesBatchStatus' | translate }}" 
                                               filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                               pause="{{ 'pause' | translate }}"
                                               remove="{{ 'remove' | translate }}"
                                               resume="{{ 'resume' | translate }}"
                                               retry="{{ 'retry' | translate }}"
                                               select="{{ 'select' | translate }}"
                                               uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
      
                    </div>
                  </div>
                </div>
            </div>
            <!--ISOMETRICO-->
            <div class="card">
                <div class="card-header">
                    <h3>
                        <label>{{ 'isometrico' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                    <div class="activos-detalle-isometrico clearfix" [ngStyle]="{'text-align':'center'}">
                        <img class="clickable" (click)="abrirModal()"
                                        src="{{selectedDibujo.imagen}}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loadingForm || loadingForm0" class="k-i-loading"></div>
    <div id="tabs" *ngIf="idActivo != 0">
        <kendo-tabstrip [keepTabContent]="true">
            <kendo-tabstrip-tab id="tabArbolActivo" title="{{ 'arbolActivo' | translate}}" [disabled]="true">
                <ng-template kendoTabContent>
                    ÁRBOL ACTIVOS
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabGestionDocumental" title="{{ 'gestiondocumental' | translate}}" [selected]="true">
                <ng-template kendoTabContent>
                    <kendo-grid [kendoGridBinding]="dataGestionDocumental" [sortable]="true"
                        kendoGridSelectBy="id" [navigable]="true" filterable="menu" (cellClick)="onCellClickGestionDocumental($event)"
                        [selectedKeys]="mySelectionGestionDocumental">
                        <ng-template kendoGridToolbarTemplate position="top">
                            <button kendoButton (click)="onClickEditarGestionDocumental()"
                                class="btn mr-1  btn-success btn-sm mr-1"> {{
                                'editar' | translate}} </button>
                            <button kendoButton (click)="onClickNuevoGestionDocumental()"
                                class="btn mr-1  btn-primary btn-sm mr-1"> {{
                                'nuevo' | translate}} </button>
                            <button kendoButton (click)="onClickEliminarGestionDocumental()"
                                class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeletingGestionDocumental || this.user.activos<2"> {{ 'eliminar' |
                                translate}} </button>
                        </ng-template>
                        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
                        <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}">
                        </kendo-grid-column>
                        <kendo-grid-column width="40%" field="ficheroLink" title="{{ 'fichero' | translate}}">
                        </kendo-grid-column>
                        <kendo-grid-column [style]="{'text-align': 'right'}"  width="10%">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <button type="button" class="btn-icon" (click)="viewPDF(dataItem)" ><span class="k-icon k-i-file-pdf"></span></button>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                          filterContainsOperator="{{'filterContainsOperator' | translate}}"
                          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                    <div *ngIf="loadingGestionDocumental" class="k-i-loading"></div>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabTareasPreventivas" title="{{ 'tareasPreventivas' | translate}}" [disabled]="true">
                <ng-template kendoTabContent>
                    TAREAS PREVENTIVAS
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabActivosUpdate" title="{{ 'activosUpdate' | translate}}">
                <ng-template kendoTabContent>
                    <kendo-grid [kendoGridBinding]="dataUpdates" [sortable]="true"
                    kendoGridSelectBy="id" [navigable]="true" filterable="menu" (cellClick)="onCellClickUpdates($event)"
                    [selectedKeys]="mySelectionUpdates">
                    <ng-template kendoGridToolbarTemplate position="top">
                        <button kendoButton (click)="onClickEditarUpdates()"
                            class="btn mr-1  btn-success btn-sm mr-1"> {{
                            'editar' | translate}} </button>
                        <button kendoButton (click)="onClickNuevoUpdates()"
                            class="btn mr-1  btn-primary btn-sm mr-1"> {{
                            'nuevo' | translate}} </button>
                        <button kendoButton (click)="onClickEliminarUpdates()"
                            class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeletingUpdates || this.user.activos<2"> {{ 'eliminar' |
                            translate}} </button>
                    </ng-template>
                    <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
                    <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column width="40%" field="descripcion" title="{{ 'descripcion' | translate}}">
                    </kendo-grid-column>
                    <kendo-grid-column width="15%" field="fecha" title="{{ 'fecha' | translate}}" [style]="{'text-align': 'center'}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            {{dataItem.fecha | kendoDate}}</ng-template>
                    </kendo-grid-column>
                    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingUpdates" class="k-i-loading"></div>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabHistoricoAcciones" title="{{ 'historicoAcciones' | translate}}" [disabled]="true">
                <ng-template kendoTabContent>
                    Histórico Acciones
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
    <div class="form-group mt-2">

        <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="guardarClick()"  [disabled]="this.user.activos<2"  >
            {{ 'guardar' | translate}}
        </button>
        <button type="submit" class="btn btn-danger btn-sm mr-1" [routerLink]="['/activos']">
            {{ 'cancelar' | translate}}
        </button>
    </div>
</form>

<ng-template #modalIsometrico let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'isometrico' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <kendo-tabstrip [keepTabContent]="true" class="tabstrip-activos-isometricos">
            <kendo-tabstrip-tab id="tabMaquinasIsometrico" title="{{ 'maquinas' | translate}}" [selected]="true">
                <ng-template kendoTabContent>
                    <!-- DIBUJOS MÁQUINAS ISOMÉTRICO -->
                    <div *ngIf="dibujosMaquinas">
                        <div class="activos-detalle-isometrico row">
                            <ng-container *ngFor="let iconoMaq of dibujosMaquinas;">
                                <div class="col-2">
                                    <img class="clickable" [ngClass]="{'actual': iconoMaq.id == selectedAuxiliarDibujo.id}" (click)="selectedAuxiliarDibujo= iconoMaq;"
                                        src="{{iconoMaq.imagen}}" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab id="tabObjetosIsometrico" title="{{ 'objetos' | translate}}">
                <ng-template kendoTabContent>
                    <!-- DIBUJOS OBJETOS ISOMÉTRICO -->
                    <div *ngIf="dibujosObjetos">
                        <div class="activos-detalle-isometrico row">
                            <ng-container *ngFor="let iconoObj of dibujosObjetos;">
                                <div class="col-2">
                                    <img class="clickable" [ngClass]="{'actual': iconoObj.id == selectedAuxiliarDibujo.id}" (click)="selectedAuxiliarDibujo= iconoObj;"
                                        src="{{iconoObj.imagen}}" />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="guardarIsometrico()"  [disabled]="this.user.activos<2">{{ 'guardar' |
            translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Borrar -->
<ng-template #popupBorrarProveedores let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()" [disabled]="this.user.activos<2">{{ 'aceptar' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
    </div>
</ng-template>

<ng-template #popupBorrarRelaciones let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptarRelaciones()" [disabled]="this.user.activos<2">{{ 'aceptar' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
    </div>
</ng-template>

<ng-template #popupBorrarDocumentos let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="btnBorrarDocumentos()" [disabled]="this.user.activos<2">{{ 'aceptar' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
    </div>
</ng-template>

<ng-template #popupBorrarUpdates let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="btnBorrarUpdates()" [disabled]="this.user.activos<2">{{ 'aceptar' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Update -->
<ng-template #popupUpdate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ 'update' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); limpiarVariables()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate}}">
                <div class="caja">
                    <kendo-textbox [(value)]="updateNombre" maxlength="150"
                        [ngClass]="{ 'is-invalid': submitted2 && errorNombre }">
                    </kendo-textbox>
                    <div *ngIf="submitted2 && errorNombre" class="invalid-feedback">
                        <div *ngIf="errorNombre">{{ 'nombrerequerido' |
                            translate}}</div>
                    </div>
                </div>
            </kendo-label>
        </div>
        <div class="form-group">
            <label>{{ 'descripcion' | translate }}</label>
            <div class="caja">
                <textarea [(ngModel)]="updateDescripcion" class="form-control"
                    style="height: 7em;"></textarea>
            </div>
        </div>
        <div class="form-group">
            <kendo-label text="{{ 'fecha' | translate}}">
                <div class="caja">
                    <kendo-datepicker [(value)]="updateFecha"
                        class="form-control">
                    </kendo-datepicker>
                </div>
            </kendo-label>
        </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="updateGuardarClick()" [disabled]="this.user.activos<2">{{ 'guardar' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click'); limpiarVariables()">{{ 'cancelar' | translate }}</button>
    </div>
  </ng-template>
