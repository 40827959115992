<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }
</style>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <kendo-grid style="height: 780px"
              [kendoGridBinding]="operacionesPredefinidas"
              [sortable]="true"
              [navigable]="true"
              filterable="menu"
              [resizable]="true"
              kendoGridSelectBy="id"
              [selectedKeys]="seleccionados"
              (cellClick)="cellClick($event)"
              scrollable="virtual"
              [rowHeight]="36"
              [pageSize]="50"
              *ngIf="router.url === '/operacionespredefinidas'">
    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">
      <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
      <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
      <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" 
              [disabled]="isDeleting || this.user.operacionesPredefinidas<2">
              {{'eliminar' | translate}}</button>
    </ng-template>
    <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
    <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
    <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="8%"
                       [style]="{'text-align': 'right'}"></kendo-grid-column>
    <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="12%">
    </kendo-grid-column>
    <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="12%"
                       class="celda-tooltip-externo">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.maquina!=null" class="tooltip-consumibles-contenido">
          <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
            <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo"
                  style="background-color:white!important;"
                  [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
            <span *ngIf="dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined'" class="grid-circulo"
                  [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
          </ng-container>
          <span class="tooltiptext">
            <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
              <label *ngIf="maquina!='undefined'">
                {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
              </label>
              <label *ngIf="maquina=='undefined'">
                {{'desconocido' | translate}}
              </label>
            </ng-container>
          </span>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
    </kendo-grid-column>
    <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%">
    </kendo-grid-column>
    <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}"
                       width="17%"> </kendo-grid-column>
    <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}" width="17%">
    </kendo-grid-column>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>
<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading, contentNotErased)" 
           [disabled]="this.user.operacionesPredefinidas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP NO SE PUDO ELIMINAR-->
<ng-template #contentNotErased let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'registrosnoeliminadas' | translate }}</p>
  </div>

</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>