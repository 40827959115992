import { Component, Renderer2, NgZone, AfterViewInit, OnDestroy, ViewEncapsulation, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InstalacionesService, MenuService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, AlertService } from '@app/_services';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { State, process } from '@progress/kendo-data-query';
import { fromEvent, Subscription } from 'rxjs';
import { RemoveEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { take, tap } from 'rxjs/operators';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

const tableRow = node => node.tagName.toLowerCase() === 'tr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { thumbnailsDownIcon } from '@progress/kendo-svg-icons';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-instalacion-detalle',
  templateUrl: './instalacionDetalle.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`.k-grid tr.dragging {
                /*background-color: #00b8bd;*/
            };`]
})

export class InstalacionDetalleComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('popupIsometrico') modalIsometrico: NgbModalRef;
  public selectedDibujoMaquina: any;
  public dibujosMaquinas: any[] = [];

  public state: State = {
    skip: 0,
    take: 100
  };
  public gridData: any = process([], this.state);
  public gridCamposPLC: any = [];//JSON;
  public listaCampos: any = [];
  private currentSubscription: Subscription;

  mySelection: number[] = [];
  mySelectionPLC: number[] = [];

  public submitable:boolean;
  public id: number;
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public imageToShow: any;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  @ViewChild('estructura1') estructura1: NgbModalRef;
  @ViewChild('estructura2') estructura2: NgbModalRef;
  @ViewChild('estructura3') estructura3: NgbModalRef;
  modalReference: NgbModalRef;

  // CREAR ESTRUCTURA DE HMI

  public menuAbierto: boolean = false;

  // columna 1
  public col1Agregar: boolean = true;
  public col1Izquierda = false;  
  public col1Derecha = false;  
  public row1Arriba = false;
  public row1Abajo = false;
  public cuadradosIzquierda = false;
  public cuadradosDerecha = false;
  public cuadradosArriba = false;
  public cuadradosAbajo = false; 
  // columna 2
  public col2Agregar = true;  
  public col2Izquierda = false;  
  public col2Derecha = false;  
  public row2Arriba = false;
  public row2Abajo = false;
  public cuadrados2Izquierda = false;
  public cuadrados2Derecha = false;
  public cuadrados2Arriba = false;
  public cuadrados2Abajo = false; 
  // columna 3
  public col3Agregar = true;  
  public col3Izquierda = false;  
  public col3Derecha = false;  
  public row3Arriba = false;
  public row3Abajo = false;
  public cuadrados3Izquierda = false;
  public cuadrados3Derecha = false;
  public cuadrados3Arriba = false;
  public cuadrados3Abajo = false;

  public tiposGrafico: any = [];
  public cardSeleccionado: any;
  public colActual: number;
  public estructuraActual: number;
  public posActual: number;
  public infColumnas: any = {
    1: {
      idInstalacion: this.route.snapshot.params['id'],
      columna: 1,
      estructura: 1,
      m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1}
    },
    2: {
      idInstalacion: this.route.snapshot.params['id'],
      columna: 2,
      estructura: 1,
      m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1},
      m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1},
      m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1},
      m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1}
    },
    3: {
      idInstalacion: this.route.snapshot.params['id'],
      columna: 3,
      estructura: 1,
      m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1},
      m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1},
      m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1},
      m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1}
    }
  };

  public resumenSemanaInf = {
    icon: "icon-circular-individual",
    id: 0,
    idInstalacion: -1,
    idTipo_Campo_Instalacion: 0,
    idTipo_Grafico: 9,
    idTipo_Grafico_Dato: 0,
    nombre: "Resumen Semana",
  }
  public tieneHistorico_Procesos: boolean;

  // END ESTRUCTURA HMI

  // ESTRUCTURA HOME

  public colIzquierdaHome: boolean = true;
  public colDerechaHome: boolean = false;

  public infHome: any = {
    1: {
      idInstalacion: this.route.snapshot.params['id'],
      estructura: 1,
      m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1}
    }
  };

  public isHome: boolean = false; // sirve para saber si estamos seleccionando un grafico para home o hmi

  // END ESTRUCTURA HOME

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private instalacionesService: InstalacionesService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private zone: NgZone,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('instalacion').toUpperCase();

  }

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.submitable = true;

    this.userService.getAllSecciones().subscribe(json => {
      this.userService.secciones = json;
      var an1: any = this.userService.secciones;
      this.secciones = an1.filter(f => f.activo === true);

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
      this.cargarFormulario();
    });

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['',],
      idSeccion: new FormControl(undefined, [Validators.required]),
      freq_captura_datos: [0,],
      ip: ['',],
      port: ['',],
      rack: ['',],
      slot: ['',],
      orden: [0,],
      activo: [true,],
      tieneHistorico_Procesos: [true,],
      nombreArchivo: ['',],
      archivo: ['',],
      archivoBase64: ['',],
      abreviatura: ['',]
    });    

    // traer informacion de los graficos creados
    this.instalacionesService.Get_TiposGraficoCreados(this.id).subscribe(
      (result: any) => {
        this.tiposGrafico.push(...result);
      });

    // obtener la informacion de la estructura creada en el hmi
    this.instalacionesService.get_instalaciones_columnas(this.id).subscribe(
      result => {
        var data: any = result;
        this.instalacionesService.get_instalaciones_modulos().subscribe(
          json => {
            var dataModulos: any = json;
            
            data.forEach(element => {
              this.crearEstructura(element.estructura, element.columna);
              // rellenar la informacion de las columnas
              this.infColumnas[element.columna]['estructura'] = element.estructura;

              var m1 = dataModulos.filter(f => f.id == element.m1);
              var m2 = dataModulos.filter(f => f.id == element.m2);
              var m3 = dataModulos.filter(f => f.id == element.m3);
              var m4 = dataModulos.filter(f => f.id == element.m4);
              if (m1.length > 0) {
                this.infColumnas[element.columna]['m1'] = {nombre: m1[0].nombre, id_tipoGrafico_DAT: m1[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m1[0].id_tipoDato_DAT, idCampo: m1[0].idCampo};
              }              
              if (m2.length > 0) {
                this.infColumnas[element.columna]['m2'] = {nombre: m2[0].nombre, id_tipoGrafico_DAT: m2[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m2[0].id_tipoDato_DAT, idCampo: m2[0].idCampo};
              }
              if (m3.length > 0) {
                this.infColumnas[element.columna]['m3'] = {nombre: m3[0].nombre, id_tipoGrafico_DAT: m3[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m3[0].id_tipoDato_DAT, idCampo: m3[0].idCampo};
              }              
              if (m4.length > 0) {
                this.infColumnas[element.columna]['m4'] = {nombre: m4[0].nombre, id_tipoGrafico_DAT: m4[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m4[0].id_tipoDato_DAT, idCampo: m4[0].idCampo};
              } 
            });    
          });
      });

    // obtener la informacion de la estructura creada en el home
    this.instalacionesService.get_instalaciones_home(this.id).subscribe(
      result => {
        var data: any = result;
        this.instalacionesService.get_instalaciones_modulos().subscribe(
          json => {
            var dataModulos: any = json;
            
            data.forEach(element => {
              this.crearEstructuraHome(element.estructura);
              // rellenar la informacion de las columnas
              this.infHome[1]['estructura'] = element.estructura;

              var m1 = dataModulos.filter(f => f.id == element.m1);
              var m2 = dataModulos.filter(f => f.id == element.m2);
              if (m1.length > 0) {
                this.infHome[1]['m1'] = {nombre: m1[0].nombre, id_tipoGrafico_DAT: m1[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m1[0].id_tipoDato_DAT, idCampo: m1[0].idCampo};
              }              
              if (m2.length > 0) {
                this.infHome[1]['m2'] = {nombre: m2[0].nombre, id_tipoGrafico_DAT: m2[0].id_tipoGrafico_DAT, id_tipoDato_DAT: m2[0].id_tipoDato_DAT, idCampo: m2[0].idCampo};
              }
            });    
          });
      });

      this.dibujosMaquinas = [
        /* MAZAK 1x1            */ { id: 1, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/mazak-miniatura-02.png" },
        /* MAZAK 2x1            */ { id: 2, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/mazak-miniatura-02.png" },
        /* GEMINIS 3x1          */ { id: 3, dimensiones: "3x1", imagen: "../../../assets/isometrico/iconos/miniatura/geminis-gt5i-miniatura-02.png" },
        /* DURMA LASER 2x1      */ { id: 4, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/durma-laser-cut-hdf-3015-miniatura-04.png" },
        /* EXTRUSORA 2x1        */ { id: 5, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/extrusora2x1_miniatura-01.png" },
        /* EXTRUSORA 3x1        */ { id: 6, dimensiones: "3x1", imagen: "../../../assets/isometrico/iconos/miniatura/extrusora_3_cubos_vista_miniatura-01.png" },
        /* ONAAF25              */ { id: 7, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/onaaf25-01.png" },
        /* IKASMAK              */ { id: 8, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/ikasmak-01.png" },
        /* DOOSAN PUMA          */ { id: 9, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/doosanpuma700-miniatura-01.png" },
        /* KONDIA               */ { id: 10, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/kondia-miniatura-01.png" },
        /* LAGUN                */ { id: 11, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/lagun-01.png" },
        /* PINACHO              */ { id: 12, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/pinacho-miniatura-01.png" },
        /* MANDRI. JUARISTI     */ { id: 13, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/mandrinadora-juaristi-miniatura-01.png" },
        /* HORNO BONIFICADO     */ { id: 14, dimensiones: "3x1", imagen: "../../../assets/isometrico/iconos/miniatura/horno-bonificado-miniatura-01.png" },
        /* H. BONIFICADO DOBLE  */ { id: 15, dimensiones: "6x1", imagen: "../../../assets/isometrico/iconos/miniatura/horno-bonificado-doble-miniatura-01.png" },
        /* Deckel maho dmu 70 evolution  */ { id: 16, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/Deckel-maho-dmu-70-evolution-miniatura-01.png" },
        /* DMG MORI DMC 1150 V  */ { id: 17, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/dgm-mori-dmc-1150V-01.png" },
        /* SECO WARWICK         */ { id: 18, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/seco_miniatura-01.png" },
        /* ADIRA                */ { id: 19, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/adira_miniatura-01.png" },
        /* HORNO                */ { id: 20, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/horno_miniatura-01.png" },
        /* SECO WARWICK HANDIA  */ { id: 21, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/seco_handia_miniatura-01.png" },
        /* HORNO HANDIA         */ { id: 22, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/horno_miniatura-01.png" },
      ];
  
      if (this.dibujosMaquinas.length > 0) {
        this.selectedDibujoMaquina = this.dibujosMaquinas[0];
      }

  }

  cargarFormulario() {

    if (this.id > 0) {

      this.instalacionesService.GetById(this.id).pipe().subscribe((result) => {
        this.tieneHistorico_Procesos = result.data[0].tieneHistorico_Procesos;
        if (this.tieneHistorico_Procesos)
          this.tiposGrafico.push(this.resumenSemanaInf);

        this.imageToShow = result.data[0].imagenBase64;

        var dibujoMaquinaSelec = this.dibujosMaquinas.find(x => x.id == result.data[0].idDibujoMaquina)

        if (dibujoMaquinaSelec != undefined) {
          this.selectedDibujoMaquina = dibujoMaquinaSelec;
        }

        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre,],
          idSeccion: new FormControl(this.secciones.find(x => x.id == result.data[0].idSeccion), [Validators.required]),
          freq_captura_datos: [result.data[0].freq_captura_datos,],
          ip: [result.data[0].ip,],
          port: [result.data[0].port,],
          rack: [result.data[0].rack,],
          slot: [result.data[0].slot,],
          orden: [result.data[0].orden,],
          activo: [result.data[0].activo,],
          tieneHistorico_Procesos: [result.data[0].tieneHistorico_Procesos,],
          nombreArchivo: [result.data[0].imagen,],
          archivo: ['',],
          archivoBase64: ['',],
          abreviatura: [result.data[0].abreviatura,]
        });

      })

      this.instalacionesService.GetCamposById(this.id).pipe().subscribe((result) => {
        this.listaCampos = result.data;
        //this.recargarGrid();
        
      });

      this.cargargridCamposPLC();

    }

  }

  public ngAfterViewInit(): void {
    //this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    //this.currentSubscription.unsubscribe();
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid || !this.submitable) {
      this.submitable = true;      
      return;
    }
    this.loading = true;
    var r1 = false, r2 = false, r3 = false;
    if (this.isAddMode) {
      this.insertInstalacion();
    } else {
      this.updateInstalacion();
    }

    // guardar estructura hmi
    this.onClickGuardar();
    // guardar estructura home
    this.onClickGuardarHome();

    // volver a la pagina principal
    this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
    this.router.navigate(['instalaciones']);
  }

  updateInstalacion()
  {
    this.instalacionesService.updateInstalacion(this.id, this.form.value.nombre, this.form.value.idSeccion.id, this.form.value.orden, this.form.value.activo, this.form.value.tieneHistorico_Procesos,
                                                     this.form.value.freq_captura_datos, this.form.value.ip, this.form.value.port, this.form.value.rack, this.form.value.slot,
                                                     this.form.value.nombreArchivo, this.form.value.archivoBase64, this.form.value.abreviatura, this.selectedDibujoMaquina.id).subscribe((result) => {
        // if (!result.error) 
        // {
        //   this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        // this.router.navigate(['instalaciones']);
        // }
        // else this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
      });
  }

  private insertInstalacion()
  {
    this.instalacionesService.insertInstalacion(this.form.value.nombre, this.form.value.idSeccion.id, this.form.value.orden, this.form.value.activo, this.form.value.tieneHistorico_Procesos,
                                                     this.form.value.freq_captura_datos, this.form.value.ip, this.form.value.port, this.form.value.rack, this.form.value.slot,
                                                     this.form.value.nombreArchivo, this.form.value.archivoBase64, this.form.value.abreviatura, this.selectedDibujoMaquina.id).subscribe((result) => {
      // if (!result.error) 
      //   { this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
      //     this.router.navigate(['instalaciones']);
      //                                                }
      //  else this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
      });
  }


  public guardar() {
    this.onSubmit();
  }

  public atras() {
    this.router.navigate(['instalaciones']);
  }

  public rowCallback(context: RowClassArgs) {

    context.dataItem.orden = context.index + 1;

    return {
      dragging: context.dataItem.dragging
    };
  }

  // private handleDragAndDrop(): Subscription {

  //   const sub = new Subscription(() => { });
  //   let draggedItemIndex;
  //   const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
  //   tableRows.forEach(item => {
  //     this.renderer.setAttribute(item, 'draggable', 'true');
  //     const dragStart = fromEvent<DragEvent>(item, 'dragstart');
  //     const dragOver = fromEvent(item, 'dragover');
  //     const dragEnd = fromEvent(item, 'dragend');

  //     sub.add(dragStart.pipe(
  //       tap(({ dataTransfer }) => {
  //         try {
  //           const dragImgEl = document.createElement('span');
  //           dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
  //           document.body.appendChild(dragImgEl);
  //           dataTransfer.setDragImage(dragImgEl, 0, 0);
  //         } catch (err) {
  //           // IE doesn't support setDragImage
  //         }
  //       })
  //     ).subscribe(({ target }) => {
  //       const row: HTMLTableRowElement = <HTMLTableRowElement>target;
  //       draggedItemIndex = row.rowIndex;
  //       const dataItem = this.gridData.data[draggedItemIndex];
  //       dataItem.dragging = true;
  //     }));

  //     sub.add(dragOver.subscribe((e: any) => {
  //       e.preventDefault();
  //       const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
  //       const dropIndex = closest(e.target, tableRow).rowIndex;
  //       const dropItem = this.gridData.data[dropIndex];

  //       draggedItemIndex = dropIndex;
  //       this.zone.run(() =>
  //         this.gridData.data.splice(dropIndex, 0, dataItem)
  //       );
  //     }));

  //     sub.add(dragEnd.subscribe((e: any) => {
  //       e.preventDefault();
  //       const dataItem = this.gridData.data[draggedItemIndex];
  //       dataItem.dragging = false;
  //     }));
  //   });

  //   return sub;
  // }

  // onClickNuevoCampo() {

  //   if (this.listaCampos.length < 10) {

  //     this.listaCampos.push(
  //       {
  //         nombre: "",
  //         minimo: 0,
  //         maximo: 0,
  //         freq_captura_datos: 0,
  //         ip: "",
  //         port: "",
  //         rack: "",
  //         slot: "",
  //         tipoPlc: "",
  //         unidad: "",
  //         variable: "",
  //         activo: true
  //       }
  //     );

  //     this.recargarGrid();

  //   }

  // }

  // onClickEliminarCampo() {  //EZABATZEKO

  //   var nuevoListaCampos = [];

  //   this.listaCampos.forEach(function (lc, i) {
  //     if (!this.mySelection.includes(lc.id)) {
  //       nuevoListaCampos.push(lc);
  //     }
  //   }, this);

  //   this.listaCampos = nuevoListaCampos;

  //   this.mySelection = [];

  //   this.recargarGrid();

  // }

  onClickEditarCampoPLC()
  {
    this.router.navigate(['instalaciones/editar/' + this.id + '/editarcampo/'+ this.mySelectionPLC[0]]);
  }
  onClickNuevoCampoPLC ()
  {
    if (this.gridCamposPLC.length<10)
        if (!this.id)
            this.alertService.error(this.translateService.instant('crearInstalacion'), { keepAfterRouteChange: true });
        else
          this.router.navigate(['instalaciones/editar/' + this.id + '/crearcampo']);
    else 
    this.alertService.error(this.translateService.instant('maxCampos'), { keepAfterRouteChange: true });
  }

  onClickEliminarCampoPLC()
  {
    let idstrings = '';
    this.mySelectionPLC.forEach(idCampo => {
      if (idstrings.length==0) idstrings=idCampo.toString();
      else idstrings = idstrings+', '+idCampo.toString();
    });
    if (idstrings.length>0) this.instalacionesService.Delete_CampoPLC(idstrings).subscribe((result) => {
      this.mySelectionPLC = [];
      this.cargargridCamposPLC();
    });
  }

  onClickDuplicarCampoPLC()
  {
  
    let idstrings = '';
    let luzera = this.gridCamposPLC.length;

    if (luzera + this.mySelectionPLC.length<=10)
    {

        this.mySelectionPLC.forEach(idCampo => {
        if (idstrings.length==0) idstrings=idCampo.toString();
        else idstrings = idstrings+', '+idCampo.toString();
      });
      if (idstrings.length>0) 
      this.instalacionesService.Duplicate_CampoPLC(idstrings).subscribe((result) => {
        if (result['message']=='maxCampos')
          this.alertService.error(this.translateService.instant('maxCampos'), { keepAfterRouteChange: true }); 
            
        this.mySelectionPLC = [];
        this.cargargridCamposPLC();
      });
    }
    else
    this.alertService.error(this.translateService.instant('maxCampos'), { keepAfterRouteChange: true }); 

  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      //this.router.navigate(['/editarcampo/'+ this.mySelectionPLC[0]]); e.dataItem.id
      this.router.navigate(['instalaciones/editar/' + this.id + '/editarcampo/'+ e.dataItem.id]); 

    }
  }

  // recargarGrid() {

  //   this.listaCampos.forEach(function (lc, i) {
  //     lc.id = i;
  //   });

  //   this.gridData = process(this.listaCampos, this.state);
  //   this.currentSubscription.unsubscribe();
  //   this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  // }

  cargargridCamposPLC()
  {
    // this.instalacionesService.GetCamposPLCById(this.id).subscribe(
    //   json => {
    //     this.gridCamposPLC = json;
    //   });

      this.instalacionesService.GetCamposPLCById(this.id).pipe().subscribe((result) => {
        this.gridCamposPLC = result.data;
      })

  }

  //AZABAL: IMAGEN NUEVO
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.form.controls['nombreArchivo'].setValue(e.files[0].name);

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
      th.imageToShow = archivoBase64;
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
    this.imageToShow = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  public onTabSelect(e: SelectEvent) {
    switch (e['index']) {
      case 0:   
        this.isHome = false;   
        break;
      case 1: 
        this.isHome = false;     
        break;
      case 2:  
        this.isHome = true;    
        break;
    
      default:
        break;
    }
  }

  // ESTRUCTURA DE HMI

  // funcion para crear la estructura de la columna
  crearEstructura(tipo: number, columna: number) {

    switch (columna) {
      case 1: // columna 1
        this.valoresAFalse1();
        switch (tipo) {
          case 1:
            this.col1Agregar = true;    
            break;
          case 2:
            this.col1Izquierda = true;  
            this.col1Derecha = true;  
            break;
          case 3: 
            this.row1Arriba = true;
            this.row1Abajo = true;
            break;
          case 4:
            this.cuadradosAbajo = true;
            this.cuadradosArriba = true;
            break;
          case 5:
            this.cuadradosIzquierda = true;
            this.col1Derecha = true;
            break;
          case 6:
            this.cuadradosDerecha = true;
            this.col1Izquierda = true;
            break;
          case 7:
            this.row1Arriba = true;
            this.cuadradosAbajo = true;
            break;
          case 8:
            this.row1Abajo = true;
            this.cuadradosArriba = true;
            break;
          default:
            break;
        }
        break;
      case 2: // columna 2
        this.valoresAFalse2();
        switch (tipo) {
          case 1:
            this.col2Agregar = true;    
            break;
          case 2:
            this.col2Izquierda = true;  
            this.col2Derecha = true;  
            break;
          case 3: 
            this.row2Arriba = true;
            this.row2Abajo = true;
            break;
          case 4:
            this.cuadrados2Abajo = true;
            this.cuadrados2Arriba = true;
            break;
          case 5:
            this.cuadrados2Izquierda = true;
            this.col2Derecha = true;
            break;
          case 6:
            this.cuadrados2Derecha = true;
            this.col2Izquierda = true;
            break;
          case 7:
            this.row2Arriba = true;
            this.cuadrados2Abajo = true;
            break;
          case 8:
            this.row2Abajo = true;
            this.cuadrados2Arriba = true;
            break;
          default:
            break;
        }
        break;
      case 3: // columna 3
        this.valoresAFalse3();
        switch (tipo) {
          case 1:
            this.col3Agregar = true;    
            break;
          case 2:
            this.col3Izquierda = true;  
            this.col3Derecha = true;  
            break;
          case 3: 
            this.row3Arriba = true;
            this.row3Abajo = true;
            break;
          case 4:
            this.cuadrados3Abajo = true;
            this.cuadrados3Arriba = true;
            break;
          case 5:
            this.cuadrados3Izquierda = true;
            this.col3Derecha = true;
            break;
          case 6:
            this.cuadrados3Derecha = true;
            this.col3Izquierda = true;
            break;
          case 7:
            this.row3Arriba = true;
            this.cuadrados3Abajo = true;
            break;
          case 8:
            this.row3Abajo = true;
            this.cuadrados3Arriba = true;
            break;
          default:
            break;
        }
        break;

      default:
        break;
    }

    this.infColumnas[columna] = {
      idInstalacion: this.id,
      columna: columna,
      estructura: tipo,
      m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m3: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m4: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1}
    }
   
  }

  valoresAFalse1() {
    // columna 1
    this.col1Agregar = false;  
    this.col1Izquierda = false;  
    this.col1Derecha = false;  
    this.row1Arriba = false;
    this.row1Abajo = false;
    this.cuadradosIzquierda = false;
    this.cuadradosDerecha = false;
    this.cuadradosArriba = false;
    this.cuadradosAbajo = false;     
  }

  valoresAFalse2() {
    // columna 2
    this.col2Agregar = false;  
    this.col2Izquierda = false;  
    this.col2Derecha = false;  
    this.row2Arriba = false;
    this.row2Abajo = false;
    this.cuadrados2Izquierda = false;
    this.cuadrados2Derecha = false;
    this.cuadrados2Arriba = false;
    this.cuadrados2Abajo = false; 
  }

  valoresAFalse3() {
    // columna 3
    this.col3Agregar = false;  
    this.col3Izquierda = false;  
    this.col3Derecha = false;  
    this.row3Arriba = false;
    this.row3Abajo = false;
    this.cuadrados3Izquierda = false;
    this.cuadrados3Derecha = false;
    this.cuadrados3Arriba = false;
    this.cuadrados3Abajo = false;
  }

  // funcion para elegir el tipo de grafico e informacion de la casilla
  annadirModulo(c, col, pos) {
    this.menuAbierto = true;
    this.cardSeleccionado = c;
    this.colActual = col;
    this.posActual = pos;
  }

  // funcion para asignar el grafico en el card
  agregarGrafico(tipoGrafico) {
    this.menuAbierto = false;

    var infModulos = {
      nombre: tipoGrafico.nombre,
      id_tipoGrafico_DAT: tipoGrafico.idTipo_Grafico,
      id_tipoDato_DAT: tipoGrafico.idTipo_Grafico_Dato,
      idCampo: tipoGrafico.id
    }
   
    if (!this.isHome) {
      this.infColumnas[this.colActual]['m' + this.posActual] = infModulos;
    } else {
      this.infHome[1]['m' + this.posActual] = infModulos;
    }

    console.log("infHome", this.infHome);

  }

  // funcion para cerrar el filtro
  cerrarMenu() {
    this.menuAbierto = false;
  }

  onClickGuardar() {
    var columnas = [this.infColumnas[1], this.infColumnas[2], this.infColumnas[3]]

    this.instalacionesService.Insert_estructura(columnas).subscribe(
      result => {
        // console.log("Estructura guardada");
        // this.alertService.success(this.translateService.instant('ok'));
        return 1
      }
    )
  }

  iconCell(icon) {
    switch (icon) {
      case 1: 
      case 10:
      case 11:
      case 12:
        return 'icon-grafico-barras'      

      case 2:  
        return 'icon-circular-individual'

      case 3:  
        return 'icon-nivel'

      case 4:  
        return 'icon-grafico'

      case 5:  
        return 'icon-presion'

      case 6: 
          return 'icon-termometro'

      case 7: 
        return 'icon-posicion'

      case 13:
      case 14:
      case 15:
      case 8: 
        return 'icon-dato'

      case 9:  
        return 'icon-circular-individual'
    
      default:
        return 'icon-mas-alt'
    }
  }

  // END ESTRUCTURA DE HMI

  // ESTRUCTURA DE HOME

  crearEstructuraHome(tipo: number) {
    switch (tipo) {
      case 1:
        this.colDerechaHome = false;
        break;
      
      case 2:
        this.colDerechaHome = true;
        break;
    
      default:
        break;
    }

    this.infHome[1] = {
      idInstalacion: this.id,
      estructura: tipo,
      m1: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1},
      m2: {nombre: '', id_tipoGrafico_DAT: -1, id_tipoDato_DAT: -1, idCampo: -1}
    }
  }

  onClickGuardarHome() {
    var columnas = [this.infHome[1]];

    this.instalacionesService.Insert_estructura_home(columnas).subscribe(
      result => {
        // console.log("Estructura guardada");
        // this.alertService.success(this.translateService.instant('ok'));
        return 1
      }
    )
  }

  // END ESTRUCTURA DE HOME

  //#region POPUP ISOMETRICO
  onClickAbrirPopup() {
    this.modalReference = this.modalService.open(this.modalIsometrico, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  onClickCerrarPopup() {
    this.modalReference.close();
  }
  //#endregion

}
