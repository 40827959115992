<!-- FILTRO -->
<div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-body">
  
            <!--FECHA INICIO-->
            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'fechainicio' | translate }}</label>
                <div class="caja">
                  <kendo-datepicker [(value)]="fechaIni" (valueChange)="onChangeFechaIni($event)" class="form-control"></kendo-datepicker>
                </div>
              </div>
            </div>
  
            <!--FECHA FIN-->
            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'fechafin' | translate }}</label>
                <div class="caja">
                  <kendo-datepicker [(value)]="fechaFin" (valueChange)="onChangeFechaFin($event)" class="form-control"></kendo-datepicker>
                </div>
              </div>
            </div>
  
            <!--LISTA OFS-->
            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'of' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOfs" [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'" [valueField]="'idOf'"
                                     placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false" style="width: 275px;" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>
  
            <!--LISTA CLIENTES-->
            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'cliente' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaClientes" [(ngModel)]="clientesSeleccionados" [textField]="'nombreCliente'" [valueField]="'idCliente'"
                                     placeholder="{{ 'seleccioneCliente' | translate }}" [autoClose]="false" style="width: 275px;" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>
  
            <!--LISTA PIEZAS-->
            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'pieza' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                                     placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 275px;" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>

            <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
            <div class="float-left mr-2" *ngIf="!user.ocultarParte">
              <div class="form-group">
                <label>{{ 'parte' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPartes" [(ngModel)]="partesSeleccionadas" [textField]="'nombreParte'" [valueField]="'idParte'" placeholder="{{ 'seleccioneParte' | translate }}" [autoClose]="false" style="width: 295px;">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreParte}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'partesSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>
  
            <!--BOTON FILTRAR-->
            <div class="caja">
  
              <button type="button" [disabled]="!hayDatosFiltro" class="btn btn-danger mr-1 float-left nolabel" (click)="btnLimpiarFiltro()">{{ 'limpiarFiltro' | translate }}</button>
              <button type="button" [disabled]="!hayDatosFiltro" class="btn btn-primary float-left nolabel" (click)="cargarDatosInforme()">{{ 'filtrar' | translate }}</button>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <!--GRID pieza-->
<div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'pieza' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <ng-template #template let-anchor>
            <span>{{ anchor.nativeElement.innerText }}</span>
          </ng-template>
          <div kendoTooltip
               showOn="none"
               [tooltipTemplate]="template"
               filter=".k-grid td"
               (mouseover)="showGridTooltip($event)">
            <kendo-grid [kendoGridBinding]="dataInforme"
                        [resizable]="true"
                        [pageable]="true"
                        [pageSize]="10"
                        [skip]="skip"
                        [navigable]="true"
                        [sortable]="true"
                        [reorderable]="true"
                        [columnMenu]="{ filter: true }"
                        (cellClick)="cellClick($event)">
              <kendo-grid-column-group title="">
                <kendo-grid-column field="numeroOF" title="{{ 'OF' | translate }}" [style]="{'text-align': 'left'}"></kendo-grid-column>
                <kendo-grid-column *ngIf="!user.ocultarParte" field="nombreParte" title="{{ 'parte' | translate }}" [style]="{'text-align': 'left'}"></kendo-grid-column>
                <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" [style]="{'text-align': 'left'}"></kendo-grid-column>
                <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate }}" [style]="{'text-align': 'left'}"></kendo-grid-column>
                <kendo-grid-column field="cantidadOperacionesTerminadas" title="{{ 'numeroOperaciones' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              </kendo-grid-column-group>
              <kendo-grid-column-group title="{{'horas' | translate}}" [headerStyle]="{'text-align': 'center'}">
                <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'horasEstimadas' | translate }}"  [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoEstimadoTotal)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tiempoPredictivoTotal" title="{{ 'horasPredictivas' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoPredictivoTotal)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tiempoTotal" title="{{ 'horasReales' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoTotal)}}</ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>
              <kendo-grid-column-group title="{{'desvio' | translate}}" [headerStyle]="{'text-align': 'center'}">
                <kendo-grid-column field="desvio" title="{{ 'estimadoReal' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.desvio)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="porcenDesvio" title="{{ '%' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenDesvio.toFixed(1)}}%</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="desvioPredictivo" title="{{ 'predictivoReal' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.desvioPredictivo)}}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="porcenDesvioPredictivo" title="{{ '%' | translate }}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenDesvioPredictivo.toFixed(1)}}%</ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>
              <kendo-grid-column-group title="" [headerStyle]="{'text-align': 'center'}">
                <kendo-grid-column title="{{ 'historico' | translate }}" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <button type="button" class="btn"><i class="fa fa-history" aria-hidden="true"></i></button>
                  </ng-template>
                  
                </kendo-grid-column>
              </kendo-grid-column-group>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
            <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--GRAFICO ESTIMADO-REAL-->
  <div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'estimadoReal' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
            <div class="row" id="graficoEstimadoReal_BoxplotOperaciones" style="height: 230px;" [ngStyle]="{'display': tieneDatos ? 'block' : 'none'}">
              <div class= "tooltip-bloxpot" id="boxplot-tooltip1Operaciones" style="visibility: hidden;"></div>
            </div>
            <div class="row" style="height: 160px; position: relative;" [ngStyle]="{'display': !tieneDatos ? 'block' : 'none'}">
              <a style="position: absolute; top: 39%; width: 100%; text-align: center;">{{ 'norecords' | translate}}</a>
            </div>
          </div>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </div>
    </div>
  </div>
  
  <!--GRAFICO Predictivo-REAL-->
  <div class="row">
    <div class="col-md-12">
      <div class="clearfix">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'predictivoReal' | translate}}</label>
            </h3>
            <div class="plegarpanel"></div>
            <div class="row" id="graficoPredictivoReal_BoxplotOperaciones" style="height: 230px;" [ngStyle]="{'display': tieneDatos ? 'block' : 'none'}">
              <div class= "tooltip-bloxpot" id="boxplot-tooltip2Operaciones" style="visibility: hidden;"></div>
            </div>
            <div class="row" style="height: 160px; position: relative;" [ngStyle]="{'display': !tieneDatos ? 'block' : 'none'}">
              <a style="position: absolute; top: 39%; width: 100%; text-align: center;">{{ 'norecords' | translate}}</a>
            </div>
          </div>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </div>
    </div>
  </div>
  
