import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, AutomatizacionesService } from '@app/_services';
import { MyFunctions } from '../_helpers';

@Component({
  selector: 'app-automatizacion',
  templateUrl: 'automatizacionDetalle.component.html'
})

export class AutomatizacionDetalleComponent {

    public automatizaciones = [];

    constructor(
        public translateService: TranslateService,
        public router: Router,
        private menuService: MenuService,
        private automatizacionesService: AutomatizacionesService,
        private myFunctions: MyFunctions,
        private modalService: NgbModal) {
            this.menuService.titulo = this.translateService.instant('automatizacion').toUpperCase();
        }

    ngOnInit() {
    }

}