<script src="https://cdnjs.cloudflare.com/ajax/libs/d3/5.16.0/d3.js"></script>
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .cont-filtro-maquinas-areas .cont-select-maquinas-out.desplegar {
    width: calc(100% - 240px) !important;
  }
</style>

<!-- DIV MAQUINAS -->
<div class="cont-filtro-maquinas-areas clearfix">
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">

        <div class="row">

          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>

          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
      <div *ngFor="let maquina of maquinasMostradas" (click)="maquinaClicked(maquina);" class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>

</div>

<div class="card">
  <!-- FECHA -->
  <div class="row">
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos">
        <div class="form-group">
          <label>{{ 'fecha' | translate }}</label>
          <div class="caja">
            <kendo-datepicker [(value)]="fecha"
                              class="form-control"
                              (valueChange)="dateChanged($event)">
            </kendo-datepicker>
          </div>
        </div>
      </div>
    </div>
    <!-- FALTAS -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos  ">
        <label>{{ 'faltas' | translate }}</label>
        <label class="valor">{{Tfaltas}}</label>
      </div>
    </div>

    <!-- EJECUCIONES -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'ejecucion' | translate }}</label>
        <label class="valor">{{Tejecucion}}</label>
      </div>
    </div>
    <!-- PARADAS -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'parada' | translate }}</label>
        <label class="valor">{{Tparada}}</label>
      </div>
    </div>
    <!-- MICRO PARADAS -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'microparada' | translate }}</label>
        <label class="valor">{{TmicroparadaHHMMSS}}</label>
      </div>
    </div>

    <!-- PREPARACIONES -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'preparacion' | translate }}</label>
        <label class="valor">{{Tpreparacion}}</label>
      </div>
    </div>
    <!-- MANTENIMIENTOS -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'mantenimiento' | translate }}</label>
        <label class="valor">{{Tmantenimiento}}</label>
      </div>
    </div>
    <!-- ALARMAS -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'alarma' | translate }}</label>
        <label class="valor">{{Talarma}}</label>
      </div>
    </div>
    <!-- APAGADAS -->
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <label>{{ 'apagada' | translate }}</label>
        <label class="valor">{{Tapagada}}</label>
      </div>
    </div>
    <div class="col col-xl col-sm-2">
      <div class="bloques-datos ">
        <!--[disabled]="!validable"-->
        <button type="button" class="btn btn-success d-block pt-0 pb-0 w-100 mb-1" (click)="guardarValidar()" [disabled]="this.user.validador < 2">{{ 'validar' | translate }}</button>
        <button type="button" class="btn btn-primary d-block pt-0 pb-0 w-100" (click)="guardarActualizar()" [disabled]="this.user.validador < 2">{{ 'actualizar' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- GANTT -->
<div class="card">
  <div id="gantt-chart" ></div>
</div>

<!-- GRID -->
<div class="card">
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div kendoTooltip
       showOn="none"
       [tooltipTemplate]="template"
       filter=".k-grid td"
       (mouseover)="showGridTooltip($event)">
    <kendo-grid #grid [kendoGridBinding]="dataValidador_grid"
                kendoGridSelectBy="numFila"
                filterable="menu"
                [selectedKeys]="mySelection"
                [navigable]="true"
                [sortable]="true"
                scrollable="virtual"
                [rowHeight]="36"
                [height]="500"
                [resizable]="true"
                (dataStateChange)="dataStateChange($event)"
                (pageChange)="pageChange($event)"
                [pageSize]="50"
                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">

      <ng-template kendoGridToolbarTemplate position="top">
        <div class="float-left">
          <button [disabled]="mySelection?.length == 0" (click)="clickEditar(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
          <button [disabled]="mySelection?.length == 0" (click)="clickEditarOF(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editarOF' | translate}}</button>
          <button [disabled]="mySelection?.length == 0" (click)="clickeditarUsuario(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editarOperario' | translate}}</button>
          <button [disabled]="mySelection?.length == 0" (click)="clickEditarTipo(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editarTipo' | translate}}</button>
          <button [disabled]="mySelection?.length == 0" (click)="clickEditarPerdida(popupEditar)" class="btn btn-success btn-sm mr-1">{{ 'editarPerdida' | translate}}</button>
          <button (click)="clickAnnadirOperacion(popupAnnadirOperacion)" class="btn btn-success btn-sm mr-1">{{ 'annadirOperacion' | translate}}</button>
          <button [disabled]="mySelection?.length != 1" (click)="clickAsignarPerdida(popupAsignarPerdida)" class="btn btn-success btn-sm mr-1">{{ 'asignarPerdida' | translate}}</button>
        </div>

        <div class="float-right">
          <button (click)="clickTodo()" class="btn btn-success btn-sm mr-1">{{ 'todo' | translate}}</button>
          <button (click)="clickSinOF(grid)" [ngClass]="!sinOf ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'sinOF' | translate}}</button>
          <button (click)="clickSinOperario(grid)" [ngClass]="!sinOperario ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'sinOperario' | translate}}</button>
          <button (click)="clickSinPerdida(grid)" [ngClass]="!sinPerdida ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'sinPerdida' | translate}}</button>
          <button (click)="clickEjecuciones(grid)" [ngClass]="!soloEjecuciones ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'ejecuciones' | translate}}</button>
          <button (click)="clickParadas(grid)" [ngClass]="!soloParadas ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'paradas' | translate}}</button>
          <button (click)="clickMicroParadas(grid)" [ngClass]="!soloMicroParadas ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'microparadas' | translate}}</button>
          <button (click)="clickPreparaciones(grid)" [ngClass]="!soloPreparaciones ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'preparaciones' | translate}}</button>
          <button (click)="cickMantenimientos(grid)" [ngClass]="!soloMantenimientos ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'mantenimientos' | translate}}</button>
          <button (click)="clickAlarmas(grid)" [ngClass]="!soloAlarmas ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'alarmas' | translate}}</button>
          <button (click)="clickApagadas(grid)" [ngClass]="!soloApagadas ? 'btn btn-success btn-sm mr-1': 'btn btn-sm mr-1'">{{ 'apagadas' | translate}}</button>
        </div>
      </ng-template>

      <!--checkbox-->
      <kendo-grid-checkbox-column width="5%">
        <ng-template kendoGridHeaderTemplate>
          <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                 [state]="selectAllState"
                 (selectAllChange)="onSelectAllChange($event)">
          <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
        </ng-template>
      </kendo-grid-checkbox-column>

      <!--<kendo-grid-checkbox-column showSelectAll="true" width="3%"></kendo-grid-checkbox-column>-->
      <kendo-grid-column field="procesos_Tipo" title="" width="3%" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="cuadrado-color-grid" [ngClass]="dataItem.procesos_Tipo">{{ dataItem.procesos_Tipo | translate }}
            <div class="cuadrado-color-grid-inner" [style.borderColor]="dataItem.backgroundColor" >{{ dataItem.procesos_Tipo_subEstados | translate }}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="8%"></kendo-grid-column>
      <kendo-grid-column field="refOF" title="{{ 'refOF' | translate }}" width="6%"></kendo-grid-column>
      <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column field="parte" title="{{ 'parte' | translate }}" width="10%" *ngIf="!user.ocultarParte"></kendo-grid-column>
      <kendo-grid-column field="programa" title="{{ 'operacion' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column field="nombrePrograma" title="{{ 'nombrePrograma' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column field="numeroSerie" *ngIf="user.verNSerie" title="{{ 'nserie' | translate }}" width="7%"></kendo-grid-column>
      <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="10%"></kendo-grid-column>

      <!--<kendo-grid-column field="tiempoEstHH" title="{{ 'tiempoEstimado' | translate }}" width="5%"></kendo-grid-column>-->
      <kendo-grid-column field="tiempoRealHH" title="{{ 'tReal' | translate }}" width="7%"></kendo-grid-column>
      <kendo-grid-column field="horaIni" title="{{ 'inicio' | translate }}" width="7%">

        <!--<ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini | kendoDate:'HH:mm:ss' }}</ng-template>-->
      </kendo-grid-column>
      <kendo-grid-column field="horaFin" title="{{ 'fin' | translate }}" width="7%">

        <!--<ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin | kendoDate:'HH:mm:ss' }}</ng-template>-->
      </kendo-grid-column>
      <kendo-grid-column field="descripcion" title="{{ 'descripcion' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column field="perdida" title="{{ 'perdida' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column width="5%" field="validado" [style]="{'text-align': 'left'}" title="{{ 'validado' | translate }}" filter="boolean" [headerStyle]="{'text-align': 'center'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.validado" disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
</div>

<!-- POPUP -->
<ng-template #popupEditar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- ROW 1 -->
    <div class="row" >
      <div class="col-md-6" [hidden]="!visibleTipo">
        <div class="form-group">
          <label>{{ 'tipo' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jtipos"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JtiposSelected"
                            (valueChange)="tipoChanged($event)"
                            placeholder="{{ 'tipo' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6" [hidden]="!visiblePerdida">
        <div class="form-group">
          <label>{{ 'perdida' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jperdidas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JperdidasSelected"
                            placeholder="{{ 'perdida' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6" [hidden]="!visibleTipo || !jSubEstados_personalizados.length > 0">
        <div class="form-group">
          <label>{{ 'subEstado' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="jSubEstados_personalizados"
                            [textField]="'nombre'"
                            [valueField]="'idProcesos_Tipo_subEstados'"
                            [(value)]="jSubEstados_personalizadosSelected"
                            placeholder="{{ 'tipo' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 1.1 -->
    <div class="row" [hidden]="!visibleTipo">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'inicio' | translate }}</label>
          <div class="caja">
            <kendo-datetimepicker [(value)]="fechaIni"
                                  class="form-control"
                                  [disabled]="true">
            </kendo-datetimepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'fin' | translate }}</label>
          <div class="caja">
            <kendo-datetimepicker [(value)]="fechaFin"
                                  class="form-control"
                                  [disabled]="true">
            </kendo-datetimepicker>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 2 -->
    <div class="row" [hidden]="!visibleProyecto">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'of' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jproyectos"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ProyectoValueChanged($event)"
                            [(value)]="JproyectoSelected"
                            [class.requerido]="requireProyecto"
                            placeholder="{{ 'proyecto' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'pieza' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpiezas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="PiezaValueChanged($event)"
                            [(value)]="JpiezasSelected"
                            [class.requerido]="requirePieza"
                            placeholder="{{ 'pieza' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 2.2 -->
    <div class="row" [hidden]="!visibleProyecto">
      <div class="col-md-6" *ngIf="!user.ocultarParte">
        <div class="form-group">
          <label>{{ 'parte' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpartes"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ParteValueChanged($event)"
                            [(value)]="JpartesSelected"
                            [class.requerido]="requireParte"
                            placeholder="{{ 'parte' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'operacion' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jprogramas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ProgramaValueChanged($event)"
                            [(value)]="JprogramasSelected"
                            [class.requerido]="requireOperacion"
                            placeholder="{{ 'programa' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 2.2.2 -->
    <div class="row" [hidden]="!visibleProyecto" *ngIf="verNSerie">
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'nSerie' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="JnSerie"
                            [textField]="'nSerie'"
                            [valueField]="'rn'"
                            [(value)]="JnSerieSelected"
                            placeholder="{{ 'nSerie' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="(nSerieTextInput) && (JnSerieSelected == undefined)">
        <div class="form-group">
          <kendo-label text="{{ 'nuevonSerie' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nuevoNserie" [maxlength]="50"></kendo-textbox>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="verLotes">
        <div class="form-group">
          <kendo-label text="{{ 'cantidadLote' | translate }}"></kendo-label>
          <div class="caja">
            <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [value]="cantidadLote"></kendo-numerictextbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 3 -->
    <div class="row" [hidden]="!visibleOperario">
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'operario' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Joperarios"
                            [textField]="'text'"
                            [valueField]="'valor'"
                            [(value)]="JoperariosSelected"
                            placeholder="{{ 'operario' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 4 -->
    <div class="row" [hidden]="!visibleObservaciones">
      <div class="col-md-12">
        <label>{{ 'descripcion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jdescripcion"></textarea>
      </div>
    </div>
    <!-- ROW 5 -->
    <div class="row" [hidden]="!(JperdidasSelected && visibleTipo)">
      <div class="col-md-12">
        <label>{{ 'observacion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
      </div>
    </div>
  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="actualizarJValidador()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP ANNADIR OPERACION -->
<ng-template #popupAnnadirOperacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'annadirOperacion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- FECHA -->
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'inicio' | translate }}</label>
          <div class="caja">
            <kendo-timepicker [(value)]="fechaIni"
                                  class="form-control"
                                  (valueChange)="periodoValido()">
            </kendo-timepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'fin' | translate }}</label>
          <div class="caja">
            <kendo-timepicker [(value)]="fechaFin"
                                  class="form-control"
                                  (valueChange)="periodoValido()">
            </kendo-timepicker>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!esSoloParada">
      <div class="col-md-12">
        <span> * {{ 'noAnnadirOperacion' | translate }} </span>
      </div>
    </div>

    <!-- OPERARIO -->
    <div class="row" *ngIf="esSoloParada">
      <!-- OF -->
      <div class="col-md-12">
          <label>{{ 'operario' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Joperarios"
                            [textField]="'text'"
                            [valueField]="'valor'"
                            [(value)]="JoperariosSelected"
                            placeholder="{{ 'operario' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
      </div>

    </div>

    <!-- OF, PIEZA, PARTE, OPERACION -->
    <div class="row" *ngIf="esSoloParada">
      <!-- OF -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'of' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jproyectos"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ofValueChanged($event)"
                            [(value)]="JproyectoSelected"
                            [class.requerido]="requireProyecto"
                            placeholder="{{ 'proyecto' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>

      <!-- PIEZA -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'pieza' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpiezas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="PiezaValueChangedAnnadirOp($event)"
                            [(value)]="JpiezasSelected"
                            [class.requerido]="requirePieza"
                            placeholder="{{ 'pieza' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>

      <!-- PARTE -->
      <div class="col-md-3" *ngIf="!user.ocultarParte">
        <div class="form-group">
          <label>{{ 'parte' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jpartes"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="ParteValueChangedAnnadirOp($event)"
                            [(value)]="JpartesSelected"
                            [class.requerido]="requireParte"
                            placeholder="{{ 'parte' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>

      <!-- OPERACION -->
      <div class="col-md-3">
        <div class="form-group">
          <label>{{ 'operacion' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jprogramas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            (valueChange)="operacionValueChanged($event)"
                            [(value)]="JprogramasSelected"
                            [class.requerido]="requireOperacion"
                            placeholder="{{ 'programa' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>

    <!-- CANTIDADES -->
    <div class="row" *ngIf="esSoloParada && verCantidades">
      <div class="col-md-3">
        <kendo-label text="{{ 'cantidadTerminada' | translate }}"></kendo-label>
        <div class="caja">
          <div class="caja">
            <kendo-numerictextbox [decimals]="0" [min]="0" [max]="cantidadTerminadaMax" maxlength="50" [(value)]="PPcantidadTerminada" (valueChange)="onChangeCantidad()"></kendo-numerictextbox>
            <kendo-label text="&nbsp;/&nbsp;{{cantidadTotal}}"></kendo-label>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <kendo-label text="{{ 'cantidadAchatarrada' | translate }}"></kendo-label>
        <div class="caja">
          <div class="caja">
            <kendo-numerictextbox [decimals]="0" [min]="0" [max]="cantidadAchatarradaMax" maxlength="50" [(value)]="PPcantidadAchatarrada" (valueChange)="onChangeCantidad()"></kendo-numerictextbox> 
            
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <kendo-label text="{{ 'cantidadApartada' | translate }}"></kendo-label>
        <div class="caja">
          <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="0" [max]="cantidadApartadaMax" maxlength="50" [(value)]="PPcantidadApartada" (valueChange)="onChangeCantidad()"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <div class="col-md-2">
          <kendo-label text="{{'terminado' |translate}}"></kendo-label>
          <div class="caja">
            <kendo-switch id="terminado" [(ngModel)]="terminado" [onLabel]="' '" [offLabel]="' '" [(value)]="isTerminado"> </kendo-switch>
          </div>
      </div>
    </div>

    <!--  TIPO -->
    <div class="row" *ngIf="esSoloParada && isOffline">
      <div class="col-md-6">
        <div class="form-group">
          <label>{{ 'tipo' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="JtiposAnnadirOperacion"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JtiposSelected"
                            (valueChange)="tipoChanged($event)"
                            placeholder="{{ 'tipo' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
    </div>

    <!-- NSERIE, LOTE, COLADA -->
    <div class="row" *ngIf="esSoloParada">
      <!-- NSERIE -->
      <div class="col-md-3" *ngIf="verNSerie">
        <div class="form-group">
          <label>{{ 'nSerie' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="JnSerie"
                            [textField]="'nSerie'"
                            [valueField]="'rn'"
                            [(value)]="JnSerieSelected"
                            placeholder="{{ 'nSerie' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="col-md-3" *ngIf="(nSerieTextInput) && (JnSerieSelected == undefined)">
        <div class="form-group">
          <kendo-label text="{{ 'nuevonSerie' | translate }}"></kendo-label>
          <div class="caja">
            <kendo-textbox [(value)]="nuevoNserie" [maxlength]="50"></kendo-textbox>
          </div>
        </div>
      </div>
      <!-- CANTIDAD LOTE -->
      <div class="col-md-3" *ngIf="verLotes">
        <div class="form-group">
          <kendo-label text="{{ 'cantidadLote' | translate }}"></kendo-label>
          <div class="caja">
            <div class="caja">
              <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [value]="cantidadLote"></kendo-numerictextbox>
            </div>
          </div>
        </div>
      </div>
      <!-- COLADA -->
      <div class="col-md-3" *ngIf="verColada">
        <div class="form-group">
          <kendo-label text="{{ 'colada' | translate }}"></kendo-label>
          <div class="caja">
              <!-- <kendo-numerictextbox [decimals]="0" [min]="1" [max]="999999" maxlength="50" [value]="cantidadLote"></kendo-numerictextbox> 
              <kendo-textbox [(value)]="colada" [maxlength]="50"></kendo-textbox> -->
              <kendo-combobox [data]="JnColada"
                          [textField]="'colada'"
                          [valueField]="'colada'"
                          [(value)]="JnColadaSelected"
                          placeholder="{{ 'colada' | translate }}"
                          [kendoDropDownFilter]="{operator: 'contains'}"
                          [allowCustom]="true"
                          (valueChange)="coladaChanged($event)">
          </kendo-combobox>
          </div>
        </div>
      </div>
    </div>

  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="annadirOperacion()" *ngIf="verAceptar" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP ASIGNAR PERDIDA -->
<ng-template #popupAsignarPerdida let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'asignarPerdida' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="esParadaSinJustificar">
      <div class="row">
        <!-- fecha ini -->
        <div class="col-md-3"><label>{{ 'inicio' | translate }}</label></div>
        <!-- fecha fin -->
        <div class="col-md-3"><label>{{ 'fin' | translate }}</label></div>
        <!-- observacion -->
        <div class="col-md-4"><label>{{ 'perdidas' | translate }}</label></div>
      </div>
      <div *ngFor="let perdida of asignarMultiperdida">
        <div class="row">
          <!-- fecha ini -->
          <div class="col-md-3">
            <kendo-timepicker [(value)]="perdida.fechaIni"
                      class="form-control"
                      [disabled]="true">
            </kendo-timepicker>
          </div>
          <!-- fecha fin -->
          <div class="col-md-3">
            <kendo-timepicker [(value)]="perdida.fechaFin"
                      class="form-control"
                      [min]="perdida.fechaMin"
                      [max]="perdida.fechaMax"
                      [disabled]="false"
                      (valueChange)="addPerdida(perdida)">
            </kendo-timepicker>
          </div>
          <!-- observacion -->
          <div class="col-md-4">
            <button *ngIf="perdida.idPerdida == -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdida)">{{ 'sinAsignar' | translate }}</button>
            <button *ngIf="perdida.idPerdida != -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdida)">{{ perdida.perdida }}</button>
          </div>
          <!-- eliminar linea -->
          <div class="col-md-2">  
            <button Class="flt-eliminar" (click)="eliminar(perdida)" *ngIf="perdida.id != 0"> <i class="fas fa-times"></i> </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="esParadaJustificada">
      <!-- observacion -->
      <div class="row">
        <div class="col-md-4"><label>{{ 'observacion' | translate }}</label></div>
        <div class="col-md-4">
          <button *ngIf="perdidaAsignada.idPerdida == -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdidaAsignada)">{{ 'sinAsignar' | translate }}</button>
          <button *ngIf="perdidaAsignada.idPerdida != -1" type="button" class="btn" (click)="clickEnsennarPerdidas(popupEnsennarPerdidas, perdidaAsignada)">{{ perdidaAsignada.perdida }}</button>
        </div>
        <!-- eliminar linea -->
        <div class="col-md-2">  
          <button Class="flt-eliminar" (click)="eliminar(perdidaAsignada)" *ngIf="perdidaAsignada.idPerdida != -1"> <i class="fas fa-times"></i> </button>
        </div>
      </div>
    </div>
    <!-- si no es parada sin justificar -->
    <div class="row" *ngIf="!esParadaSinJustificar && !esParadaJustificada">
      <div class="col-md-12">
        <span> * {{ 'soloParadaSinJustificar' | translate }} </span>
      </div>
    </div>
  </div>
    <div class="modal-footer">
      <div class="float-left" >
        <div *ngIf="ensennarJustificarHastaFin">
          <kendo-switch class="kendo-switch-tipo2" style="width: 265px;" [(ngModel)]="justificarHastaFinProceso"
                        [onLabel]="justificarHastaFin" [offLabel]="justificarHastaFin">
          </kendo-switch>
        </div>
      </div>
        <div  class="float-left" >
          <button type="button" class="btn btn-danger mr-1" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
          <button type="button" class="btn btn-primary" (click)="validarPerdidas()" [disabled]="this.user.validador < 2">{{ 'validar' | translate }}</button>
        </div>
      
    </div>
</ng-template>

<!-- POPUP POSIBLES PERDIDAS -->
<ng-template #popupEnsennarPerdidas let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'perdidas' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid [kendoGridBinding]="dataPerdidasGrid" [sortable]="true" [navigable]="true"
                filterable="menu" (cellClick)="elegirPerdida($event, popupRequiereObservacion); modal.dismiss('cancel click')" >
      <kendo-grid-column width="10%" field="grupoPerdida" title="{{ 'perdidasGrupo' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="perdida" title="{{ 'perdidas' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="subperdida" title="{{ 'subperdidas' | translate}}"> </kendo-grid-column>
      <kendo-grid-column width="10%" field="observacion" title="{{ 'observacion' | translate}}"> </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>         
  
  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" [disabled]="this.user.validador < 2">{{ 'validar' | translate }}</button>
    </div>
</ng-template>

<!-- POPUP REQUIERE OBSERVACION LA PERDIDA -->
<ng-template #popupRequiereObservacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'requiereObservacion' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <textarea kendoTextArea [(value)]="observacionPerdida"></textarea>
  </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="guardarObservacion()" [disabled]="this.user.validador < 2">{{ 'aceptar' | translate }}</button>
    </div>
</ng-template>

<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div><div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
