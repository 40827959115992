import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ActivatedRoute } from '@angular/router';
import { FabricantesService } from '@app/_services';
import { AlmacenesService } from '@app/_services/almacenes.service';
import { MyFunctions } from '@app/_helpers';
import { FormGroup, FormControl } from "@angular/forms";
import { UsuariosService } from '@app/_services';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-gestion-consumibles',
  templateUrl: './gestion-consumibles.component.html',
})

export class GestionConsumiblesComponent {

  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  
  //GRID: consumibles
  public consumiblesAlta: any;
  public consumiblesBaja: any;
  public consumiblesBajaSeleccion: any;
  public consumiblesMover: any;
  public seleccionados: any[] = [];
  public seleccionadosEnteros: any[] = [];
  public seleccionadosTipoSeleccion: any[] = [];
  public seleccionadosPosicion: any[] = [];
  public consumiblesAltaOriginal: any;

  //Booleanos
  tieneNserie: boolean = false;
  tieneHijos: boolean = false;
  errorDiferentes: boolean = false;
  errorAlmacen: boolean = false;
  errorNserie: boolean = false;
  eliminarHijos: boolean = false;
  tienePosiciones: boolean = false;

  //Modals
  modalReference: NgbModalRef;
  modalReferenceLoading: NgbModalRef;

  //POPUP:  alta 
  public popupAlta: boolean = false;

  //POPUP:  baja 
  public popupBaja: boolean = false;

  //POPUP:  mover 
  public popupMover: boolean = false;

  //POPUP: nSerie
  public popupNserie: boolean = false;

  //Variables popup
  tipoConsumible: any;
  consumible: any;
  almacen: any;
  almacenMover: any;
  cantidad: number = 0;
  maxCantidad: number = 999;
  maxCantidadAlta: number = 999;

  //Listas popup
  listaAlmacenes: any;
  listaAlmacenesMover: any = [];
  listaAlmacenesAlta: any = [];
  listaTipoConsumibles: any;
  listaConsumibles: any;
  listaNSerie: any = [];
  consumiblesPosiciones: any;

  //Punteros
  offset: number = 0;
  index: number = 0;

  //FILTRO *START*
  status: boolean = false; //para abrir o cerrar el filtro *se carga abierto siempre!* si se autofiltra, se cierra solo.

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs *START*

  //en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];

  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];

  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];

  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    //{ id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    //{ id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];

  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs *END*

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias

  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    ]
  };

  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };

  //ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, comboFlexibleOperarioMaquina check, numeric, decimal, string
  public columnasFiltro = [
    { id: 1, nombre: this.translateService.instant('nombre'), field: "nombre", sqlfield: "hb.nombre", tipo: 'comboFlexible' },
    { id: 2, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "do.nSerie", tipo: 'comboFlexible' },
    { id: 3, nombre: this.translateService.instant('tipo'), field: "nombreT", sqlfield: "do.nombreT", tipo: 'comboEstrincto' },
    { id: 4, nombre: this.translateService.instant('almacen'), field: "almacen", sqlfield: "do.almacen", tipo: 'comboEstrincto' },
    { id: 5, nombre: this.translateService.instant('coste'), field: "costeOriginal", sqlfield: "do.coste", tipo: 'numeric' },
    { id: 6, nombre: this.translateService.instant('total'), field: "totalOriginal", sqlfield: "hb.total", tipo: 'numeric' },
  ];

  //LOADING filtro
  public actualizarVisible: boolean = false;

  //FILTRO *END*

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public route: ActivatedRoute,
    public fabricantesService: FabricantesService,
    public almacenesService: AlmacenesService,
    private usuariosService: UsuariosService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('Gestión Consumibles').toUpperCase();

    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
  }

  //El FormGroup para validar
  public myForm: FormGroup = new FormGroup({
    tipo: new FormControl(),
    consumible: new FormControl(),
    almacen: new FormControl(),
  });

  user = this.usuariosService.userValue;

  ngOnInit() {
    this.consumiblesService.Get_consumibles_alta().subscribe(
      (json) => {
        this.consumiblesAlta = json;
        this.consumiblesAlta.forEach(c => {
          c.costeOriginal = c.coste;
          c.total = c.cantidad * c.coste;
          c.totalOriginal = c.total;
          c.coste = c.coste + '€';
          c.total = c.total + '€';
        });
        this.consumiblesAltaOriginal = this.consumiblesAlta;
      });

    this.consumiblesService.Get_almacenes().subscribe(
      (result) => {
        this.listaAlmacenes = result["almacenesTodos"];
        this.listaAlmacenes.forEach(a => {
          if (!a.tienePosiciones) this.listaAlmacenesAlta.push(a);
        });
      });

    this.consumiblesService.Get_consumiblesTipos().subscribe(
      (json) => {
        this.listaTipoConsumibles = json;
        this.listaTipoConsumibles = this.listaTipoConsumibles.filter(function (item) {
          return item.nombre !== 'COMPLETO';
        });
      });
    this.consumiblesService.Get_consumibles().subscribe(
      (json) => {
        this.listaConsumibles = json;
        console.log(this.listaConsumibles);
      });
  }

  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);

    // COMBO ESTRICTO -
    if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }

  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
    }
  }

  /**
   * Al clicar en el boton para dar de alta
   */
  onClick_Alta() {
    this.cantidad = 1;
    if (this.seleccionados.length === 0) {
      this.consumible = undefined;
      this.tipoConsumible = undefined;
      this.almacen = { 'id': -1, 'nombre': '' };
      this.tieneNserie = false;
      this.tienePosiciones = false;
      this.almacenMover = undefined;
    }
    console.log(this.almacen);
    this.popupAlta = true;
  }

  /**
   * Al clicar en el boton para dar de baja
   */
  onClick_Baja() {
    this.seleccionadosTipoSeleccion = [];
    if (this.seleccionados.length > 0) {
      this.errorDiferentes = false;
      this.errorAlmacen = false;
      var nConsumible = this.seleccionadosEnteros[0].nombre;
      var nTipo = this.seleccionadosEnteros[0].nombreT;
      var nAlmacen = this.seleccionadosEnteros[0].almacen;
      this.seleccionadosEnteros.forEach(s => {
        if (s.nombre !== nConsumible || s.nombreT !== nTipo) this.errorDiferentes = true;
        if (!this.errorDiferentes && s.almacen !== nAlmacen) this.errorAlmacen = true;
      });
      this.comprobarBaja();
    } else {
      this.popupBaja = true;
      this.maxCantidad = 999;
      this.consumible = undefined;
      this.tipoConsumible = undefined;
      this.almacen = { 'id': -1, 'nombre': '' };
      this.tieneNserie = false;
      this.tienePosiciones = false;
      this.almacenMover = undefined;
    }
  }

  /**
   * Al clicar en el boton para mover
   */
  onClick_Mover() {
    if (this.seleccionados.length > 0) {
      this.errorDiferentes = false;
      this.errorAlmacen = false;
      this.almacenMover = undefined;
      this.tienePosiciones = false;
      var nConsumible = this.seleccionadosEnteros[0].nombre;
      var nTipo = this.seleccionadosEnteros[0].nombreT;
      var nAlmacen = this.seleccionadosEnteros[0].almacen;
      this.seleccionadosEnteros.forEach(s => {
        if (s.nombre !== nConsumible || s.nombreT !== nTipo) this.errorDiferentes = true;
        if (!this.errorDiferentes && s.almacen !== nAlmacen) this.errorAlmacen = true;
      });
      this.comprobarMover();
    } else {
      this.maxCantidad = 999;
      this.popupMover = true;
      this.consumible = undefined;
      this.tipoConsumible = undefined;
      this.almacen = { 'id': -1, 'nombre': '' };
      this.tieneNserie = false;
      this.tienePosiciones = false;
      this.almacenMover = undefined;
    }
  }

  /**
   * Comprobar si al dar de baja los elementos seleccionados tienen el mismo
   * almacen y son de las mismas caracteristicas
   */
  comprobarBaja() {
    if (!this.errorDiferentes && !this.errorAlmacen) {
      this.popupBaja = true;
      this.almacen = this.listaAlmacenes[this.listaAlmacenes.indexOf(this.almacen)];
      this.cantidad = this.seleccionadosEnteros.length;
      if (this.seleccionadosEnteros[0].nSerie == '') this.tieneNserie = false;
      this.listaTipoConsumibles.forEach(t => {
        if (t.nombre == this.seleccionadosEnteros[0].nombreT) this.tipoConsumible = t;
      });
      this.listaConsumibles.forEach(c => {
        if (c.nombre == this.seleccionadosEnteros[0].nombre) this.consumible = c;
      });
      this.maxCantidad = this.consumiblesAlta[this.index].cantidad;
    } else if (this.errorDiferentes) {
      this.alertService.error(this.translateService.instant('diferentesError'), { keepAfterRouteChange: true });
    } else if (this.errorAlmacen) {
      this.alertService.error(this.translateService.instant('almacenError'), { keepAfterRouteChange: true });
    }
  }

  /**
   * Comprobar si al mover los elementos seleccionados tienen el mismo 
   * almacen y son de las mismas caracteristicas
   */
  comprobarMover() {
    if (!this.errorDiferentes && !this.errorAlmacen) {
      this.popupMover = true;
      this.cantidad = this.seleccionadosEnteros.length;
      if (this.seleccionadosEnteros[0].nSerie == '') this.tieneNserie = false;
      this.listaTipoConsumibles.forEach(t => {
        if (t.nombre == this.seleccionadosEnteros[0].nombreT) this.tipoConsumible = t;
      });
      this.listaConsumibles.forEach(c => {
        if (c.nombre == this.seleccionadosEnteros[0].nombre) this.consumible = c;
      });
      this.maxCantidad = this.consumiblesAlta[this.index].cantidad;
    } else if (this.errorDiferentes) {
      this.alertService.error(this.translateService.instant('diferentesError'), { keepAfterRouteChange: true });
    } else if (this.errorAlmacen) {
      this.alertService.error(this.translateService.instant('almacenError'), { keepAfterRouteChange: true });
    }
  }

  /**
   * Dar de alta los elementos especificados
   */
  dar_de_alta() {
    this.myForm.controls.tipo.markAsTouched();
    this.myForm.controls.consumible.markAsTouched();
    this.myForm.controls.almacen.markAsTouched();
    if (this.myForm.controls.tipo.valid && this.myForm.controls.consumible.valid && this.myForm.controls.almacen.valid) {
      var numeros: string[] = [];
      this.altocompletar();
      //Extraer solo nSeries
      this.listaNSerie.forEach(n => {
        numeros.push(n.nombre);
      });
      this.consumiblesAlta.forEach(c => {
        numeros.forEach(n => {
          if (c.nSerie == n && c.nSerie !== '') this.errorNserie = true;
        });
      });
      console.log(this.errorNserie);
      if (this.errorNserie) {
        this.alertService.error(this.translateService.instant('nserieError'), { keepAfterRouteChange: true });
        this.errorNserie = false;
      } else {
        this.consumiblesService.Insert_historico_consumibles(this.consumible.id, this.almacen.id, numeros).subscribe(
          (result) => {
            this.popupAlta = false;
            this.alertService.success(this.translateService.instant('altacorrecta'), { keepAfterRouteChange: true });
            this.consumiblesService.Get_consumibles_alta().subscribe(
              (json) => {
                this.consumiblesAlta = json;
                this.consumiblesAlta.forEach(c => {
                  c.costeOriginal = c.coste;
                  c.total = c.cantidad * c.coste;
                  c.totalOriginal = c.total;
                  c.coste = c.coste + '€';
                  c.total = c.total + '€';
                });
              });
          });
      }
    }
  }

  /**
   * Dar de baja los elementos seleccionados o especificados
   * NO DA DE BAJA SI user.tipo_baja_consumibles ES undefined
   * @param contentloading para el modal de carga
   */
  dar_de_baja(contentloading) {
    this.modalService.dismissAll();
    this.modalReferenceLoading = this.modalService.open(contentloading, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    var ids: number[] = [];
    if(this.user.tipo_baja_consumibles == 2) this.tieneNserie = true;
    if (this.tieneNserie) {
      //Extraer solo ids
      if (this.seleccionados.length > 0 && this.user.tipo_baja_consumibles == 1) {
        this.seleccionados.forEach(s => {
          ids.push(s);
        });
      } else if(this.seleccionados.length > 0 && this.user.tipo_baja_consumibles == 2 && this.seleccionadosTipoSeleccion.length == 0){
        this.seleccionados.forEach(s => {
          ids.push(s);
        });
      } else if(this.user.tipo_baja_consumibles == 2 && this.seleccionadosTipoSeleccion.length > 0){
        this.seleccionadosTipoSeleccion.forEach(s => {
          ids.push(s);
        });
      } /*else{
        this.consumiblesBaja.forEach(c => {
          ids.push(c.id);
        });
      }*/
      this.consumiblesService.Deshechar_historico_consumible_ids(ids, this.eliminarHijos).subscribe(
        (result) => {
          this.popupBaja = false;
          this.alertService.success(this.translateService.instant('bajacorrecta'), { keepAfterRouteChange: true });
          this.consumiblesService.Get_consumibles_alta().subscribe(
            (json) => {
              this.consumiblesAlta = json;
              this.consumiblesAlta.forEach(c => {
                c.costeOriginal = c.coste;
                c.total = c.cantidad * c.coste;
                c.totalOriginal = c.total;
                c.coste = c.coste + '€';
                c.total = c.total + '€';
              });
            });
        });
    } else {
      this.consumiblesService.Get_consumibles_ids(this.cantidad, this.consumible.id, this.almacen.id).subscribe(
        (json) => {
          ids = json.map(function (x) {
            return x.id
          });
          this.consumiblesService.Deshechar_historico_consumible_ids(ids, this.eliminarHijos).subscribe(
            (result) => {
              this.popupBaja = false;
              this.alertService.success(this.translateService.instant('bajacorrecta'), { keepAfterRouteChange: true });
              this.consumiblesService.Get_consumibles_alta().subscribe(
                (json) => {
                  this.consumiblesAlta = json;
                  this.consumiblesAlta.forEach(c => {
                    c.costeOriginal = c.coste;
                    c.total = c.cantidad * c.coste;
                    c.totalOriginal = c.total;
                    c.coste = c.coste + '€';
                    c.total = c.total + '€';
                  });
                });
            });
        });
    }
    this.seleccionados.forEach(s => {
      this.seleccionados.pop();
    });
    this.seleccionadosEnteros.forEach(e => {
      this.seleccionadosEnteros.pop();
    });
    this.seleccionadosTipoSeleccion.forEach(e => {
      this.seleccionadosTipoSeleccion.pop();
    });
    this.eliminarHijos = false;
    this.modalReferenceLoading.close();
  }

  /**
   * Para mover de un almacen a otro los elementos especificados
   * @param contentloading para el modal de carga
   */
   moverConsumible(contentloading) {
    this.modalService.dismissAll();
    this.modalReferenceLoading = this.modalService.open(contentloading, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    var ids: number[] = [];
    var posicion: number = -1;
    if (this.tienePosiciones) {
      posicion = this.seleccionadosPosicion[0];
    } else{
      posicion = -1;
    }
    if (this.tieneNserie) {
      //Extraer solo ids
      if (this.seleccionados.length > 0) {
        this.seleccionados.forEach(s => {
          ids.push(s);
        });
      } else {
        this.consumiblesMover.forEach(c => {
          ids.push(c.id);
        });
      }
      this.consumiblesService.Mover_historico_consumibles(ids, this.almacenMover.id, posicion).subscribe(
        (result) => {
          this.popupMover = false;
          this.alertService.success(this.translateService.instant('movercorrecto'), { keepAfterRouteChange: true });
          this.consumiblesService.Get_consumibles_alta().subscribe(
            (json) => {
              this.consumiblesAlta = json;
              this.consumiblesAlta.forEach(c => {
                c.costeOriginal = c.coste;
                c.total = c.cantidad * c.coste;
                c.totalOriginal = c.total;
                c.coste = c.coste + '€';
                c.total = c.total + '€';
              });
            });
        });
    } else {
      this.consumiblesService.Get_consumibles_ids(this.cantidad, this.consumible.id, this.almacen.id).subscribe(
        (json) => {
          ids = json.map(function (x) {
            return x.id
          });
          this.consumiblesService.Mover_historico_consumibles(ids, this.almacenMover.id, posicion).subscribe(
            (result) => {
              this.popupMover = false;
              this.alertService.success(this.translateService.instant('movercorrecto'), { keepAfterRouteChange: true });
              this.consumiblesService.Get_consumibles_alta().subscribe(
                (json) => {
                  this.consumiblesAlta = json;
                  this.consumiblesAlta.forEach(c => {
                    c.costeOriginal = c.coste;
                    c.total = c.cantidad * c.coste;
                    c.totalOriginal = c.total;
                    c.coste = c.coste + '€';
                    c.total = c.total + '€';
                  });
                });
            });
        });
    }
    this.seleccionados.forEach(s => {
      this.seleccionados.pop();
    });
    this.seleccionadosEnteros.forEach(e => {
      this.seleccionadosEnteros.pop();
    });
    this.modalReferenceLoading.close();
  }

  /**
   * Al clicar el boton aceptar en el modal para dar de baja
   * @param baja modal de confirmacion de eliminacion
   * @param contentloading modal de carga
   */
  aceptar_baja(baja, contentloading) {
    if (this.consumible.numContenido > 0) {
      this.modalReference = this.modalService.open(baja, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    } else {
      this.dar_de_baja(contentloading);
    }
  }

  /**
   * Al clicar el boton aceptar en el modal para mover
   * @param mover modal de confirmacion de movimiento
   * @param contentloading modal de carga
   */
  aceptar_mover(mover, contentloading) {
    if (this.consumible.numContenido > 0) {
      this.modalReference = this.modalService.open(mover, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    } else {
      this.moverConsumible(contentloading);
    }
  }

  /**
   * Si se ha seleciconado dar de baja tambien a los hijos
   * de los consumibles a dar de baja
   * @param contentloading 
   */
  bajaHijos(contentloading) {
    this.eliminarHijos = true;
    if (this.user.tipo_baja_consumibles == 2) this.tieneNserie = false; 
    //Baja hijos
    if (this.tieneNserie) {
      this.consumiblesService.get_ids_hijos_activos(this.seleccionados[0]).subscribe(
        (result) => {
          console.log(result);
          result.forEach(s => {
            this.consumiblesService.Dar_De_Baja_Hijos(s.id).subscribe(
              (result) => {
                console.log("Hijos dados de baja correctamente: " + s);
                this.dar_de_baja(contentloading);
              });
          });
          if (result.length == 0) this.dar_de_baja(contentloading);
          this.modalService.dismissAll();
        });
    } else {
      if (this.user.tipo_baja_consumibles == 2 && this.seleccionadosTipoSeleccion.length > 0){
        this.seleccionadosTipoSeleccion.forEach(id => {
          this.consumiblesService.Dar_De_Baja_Hijos(id).subscribe(
            (result) => {
              console.log("Hijos dados de baja correctamente: " + id);
              this.modalService.dismissAll();
              this.dar_de_baja(contentloading);
            });
        });
      } else{
        this.consumiblesService.Get_consumibles_ids(this.cantidad, this.consumible.id, this.almacen.id).subscribe(
          (json) => {
            var ids: number[] = [];
            ids = json.map(function (x) {
              return x.id
            });
            ids.forEach(id => {
              this.consumiblesService.Dar_De_Baja_Hijos(id).subscribe(
                (result) => {
                  console.log("Hijos dados de baja correctamente: " + id);
                  this.modalService.dismissAll();
                  this.dar_de_baja(contentloading);
                });
            });
          });
      }
    }
  }

  /**
 * Si se ha seleciconado mover tambien a los hijos
 * de los consumibles a mover
 * @param contentloading para la carga
 */
  moverHijos(contentloading) {
    //Mover hijos
    if (this.tieneNserie) {
      this.consumiblesService.get_ids_hijos_activos(this.seleccionados[0]).subscribe(
        (result) => {
          result.forEach(s => {
            this.consumiblesService.Mover_A_Almacen_Hijos(s.id, this.almacenMover.id).subscribe(
              (result) => {
                console.log("Hijos movidos correctamente: " + s);
                this.modalService.dismissAll();
              });
          });
        });
        this.moverConsumible(contentloading);
    } else {
      this.consumiblesService.Get_consumibles_ids(this.cantidad, this.consumible.id, this.almacen.id).subscribe(
        (json) => {
          var ids: number[] = [];
          ids = json.map(function (x) {
            return x.id
          });
          ids.forEach(id => {
            this.consumiblesService.Mover_A_Almacen_Hijos(id, this.almacenMover.id).subscribe(
              (result) => {
                console.log("Hijos movidos correctamente: " + id);
                this.modalService.dismissAll();
                this.moverConsumible(contentloading);
              });
          });
        });
    }
  }

  /**
   * Autocompleta los numeros de serie de los elementos a 
   * dar de alta partiendo del numero maximo de la DB
   */
  altocompletar() {
    this.offset = 0;
    this.consumiblesService.Get_consumible_nSerie(this.consumible.id).subscribe(
      (offset) => {
        this.offset = offset;
        this.update();
      });
  }

  /**
   * Actualiza los numeros de serie de la lista de estos,
   * partiendo del offset especificado anteriormente
   */
  update() {
    console.log(this.offset);
    for (let i = 0; i < this.cantidad; i++) {
      var id = i + 1 + this.offset;
      this.listaNSerie[i].nombre = this.consumible.id + "_NS_" + id;
    }
  }

  /**
   * Vaciar la lista de numeros de serie a mostrar en el
   * apartado de dar de alta
   */
  vaciar() {
    this.listaNSerie.forEach(n => {
      n.nombre = '';
    });
  }

  /**
   * Al clickar en cada celda sin usar la funcionalidad del checkbox,
   * fija el elemento seleccionado al elemento actual
   * @param value el consumible seleccionado
   */
  cellClick(value: any) {
    if (value.dataItem.nSerie !== '') {
      this.tieneNserie = true;
    } else {
      this.tieneNserie = false;
    }
    console.log(value.dataItem);
    this.listaTipoConsumibles.forEach(t => {
      if (t.nombre == value.dataItem.nombreT) this.tipoConsumible = t;
    });
    this.listaConsumibles.forEach(c => {
      if (c.nombre == value.dataItem.nombre) this.consumible = c;
    });
    this.listaAlmacenes.forEach(a => {
      if (a.nombre == value.dataItem.almacen) this.almacen = a;
    });
  }

  /**
   * Al usar la funcionalidad de checkbox para elegir el elemento,
   * fija el elemento seleccionado al elemento actual
   * @param e elemento seleccionado
   */
  selectionChange(e: any): void {
    console.log(e);
    if (e.selectedRows.length == 1) {
      if (e.deselectedRows.length > 0) {
        for (let i = 0; i < e.deselectedRows.length; i++) {
          this.seleccionadosEnteros.pop();
        }
      }
      this.index = e.selectedRows[0].index;
      this.seleccionadosEnteros.push(this.consumiblesAlta[e.selectedRows[0].index]);
      if (e.selectedRows[0].dataItem.nSerie !== '') {
        this.tieneNserie = true;
      } else {
        this.tieneNserie = false;
      }
      this.listaTipoConsumibles.forEach(t => {
        if (t.nombre == e.selectedRows[0].dataItem.nombreT) this.tipoConsumible = t;
      });
      this.listaConsumibles.forEach(c => {
        if (c.nombre == e.selectedRows[0].dataItem.nombre) this.consumible = c;
      });
      this.listaAlmacenes.forEach(a => {
        if (a.nombre == e.selectedRows[0].dataItem.almacen) this.almacen = a;
      });
      this.valueChangeAlmacen(this.almacen);
    } else if (e.deselectedRows.length == 1) {
      this.seleccionadosEnteros.splice(this.seleccionadosEnteros.indexOf(this.consumiblesAlta[e.deselectedRows[0].index]), 1);
    } else if (e.deselectedRows.length > 1) {
      this.seleccionadosEnteros = [];
    }
    if (this.seleccionadosEnteros.length == 0) this.consumiblesBajaSeleccion = [];
    console.log(this.seleccionadosEnteros);
  }

  /**
   * Al usar la funcionalidad de checkbox para elegir el elemento,
   * fija el elemento seleccionado al elemento actual
   * @param e elemento seleccionado
   */
  selectionChangeTipoSeleccion(e: any): void {
    console.log(this.seleccionadosTipoSeleccion);
  }

  /**
   * Al cambiar la seleccion en el grid de elegir la posicion 
   * en el almacen a mover
   * @param e 
   */
  selectionChangePosicion(e: any): void {
    console.log(this.seleccionadosPosicion);
  }

  /**
   * Para poner la fila del grid deshabilitada cuando hay
   * un elemente en esa posicion del almacen
   * @param args el elemente de la fila del grid
   * @returns la clase disabled de kendo
   */
  rowCallback(args: any) {
    return {'k-state-disabled': args.dataItem.nSerie != '-' || args.dataItem.herramienta != '-' || args.dataItem.tipoHerramienta != '-'};
  }

  /**
   * Al cambiar el tipo de consumible elegido en el combobox
   * @param value 
   */
  valueChangeTipo(value: any) {
    this.listaNSerie = [];
    this.consumiblesBaja = [];
    this.consumiblesBajaSeleccion = [];
    this.consumiblesMover = [];
    this.consumible = undefined;
    this.myForm.controls.consumible.patchValue(this.consumible);
    console.log(value.id);
    this.consumiblesService.Get_consumibles_byTipoID(value.id).subscribe(
      (json) => {
        this.listaConsumibles = json;
      });
  }

  /**
   * Al cambiar el almacen elegido en el combobox
   * @param value el almacen elegido
   */
  valueChangeAlmacen(value: any) {
    this.maxCantidad = this.consumiblesAlta[this.index].cantidad;
    this.consumiblesBaja = [];
    this.consumiblesBajaSeleccion = [];
    this.consumiblesMover = [];
    this.listaAlmacenesMover = [];
    this.consumiblesAlta.forEach(ca => {
      if (ca.nombre == this.consumible.nombre && ca.almacen == this.almacen.nombre && ca.nombreT == this.tipoConsumible.nombre) {
        this.consumiblesBaja.push(ca);
        this.consumiblesMover.push(ca);
      }
    });
    this.listaAlmacenes.forEach(a => {
      if (a !== this.almacen) this.listaAlmacenesMover.push(a);
    });
    console.log(this.consumiblesBaja);
    this.consumiblesService.Get_historico_consumibles_para_baja_seleccion(this.consumible.id, this.tipoConsumible.id, value.id).subscribe(
      (json) => {
        console.log(json);
        this.consumiblesBajaSeleccion = json;
      });
  }

  /**
   * Al cambiar el almacen a mover elegido en el combobox
   * @param value el almacen elegido
   */
  valueChangeAlmacenMover(value: any){
    if(value.tienePosiciones){
      this.maxCantidad = 1;
      this.cantidad = 1;
      this.consumiblesService.Get_almacenes_con_consumibles_activos(value.id).subscribe(
        (json) => {
          this.consumiblesPosiciones = json['consumiblesActivos'];
          console.log(this.consumiblesPosiciones);
          this.tienePosiciones = true;
        });
    } else{
      this.maxCantidad = 999;
      this.tienePosiciones = false;
    }
  }
  
  valueChangeAlmacenAlta(value: any){
    var cantidadEnAlmacen = 0;
    this.consumiblesAlta.forEach(c => {
      if (c.almacen == value.nombre) cantidadEnAlmacen += c.cantidad;
    });
    this.maxCantidadAlta = value.capacidad - cantidadEnAlmacen;
    if (this.maxCantidadAlta == 0) {
      this.alertService.error(this.translateService.instant('almacenlleno'), { keepAfterRouteChange: true });
      this.cantidad = 0;
      this.cantidadChange(0);
    }
  }

  /**
   * Al cambiar el consumible elegido en el combobox
   * @param value el consumible elegido
   */
  valueChange(value: any) {
    this.listaNSerie = [];
    this.consumiblesBaja = [];
    this.consumiblesBajaSeleccion = [];
    this.consumiblesMover = [];
    this.cantidadChange(1);
    this.consumible = value
    this.consumiblesAlta.forEach(ca => {
      if (ca.nombre == this.consumible.nombre && ca.almacen == this.almacen.nombre && ca.nombreT == this.tipoConsumible.nombre) {
        this.consumiblesBaja.push(ca);
        this.consumiblesMover.push(ca);
      }
    });
    this.consumiblesService.Get_historico_consumibles_para_baja_seleccion(value.id, this.tipoConsumible.id, this.almacen.id).subscribe(
      (json) => {
        this.consumiblesBajaSeleccion = json;
      });
    this.listaAlmacenes.forEach(a => {
      if (a !== this.almacen) this.listaAlmacenesMover.push(a);
    });
    if (value.tieneNserie) {
      this.tieneNserie = true;
    } else {
      this.tieneNserie = false;
    }
  }

  /**
   * Al cambiar el valor de la cantidad a dar de alta/baja/mover 
   * @param value el valor de la cantidad
   */
  cantidadChange(value: any) {
    if (this.popupAlta) {
      if (value < this.listaNSerie.length) {
        var i = this.listaNSerie.length - 1;
        while (value < this.listaNSerie.length) {
          this.listaNSerie = this.listaNSerie.filter(x => x.id != i);
          i--;
        }
      }
      else if (value > this.listaNSerie.length) {
        var i = this.listaNSerie.length - 1;
        while (value > this.listaNSerie.length) {
          this.listaNSerie.push({ 'id': i + 1, 'nombre': '' });
          i++;
        }
      }
      this.cantidad = value;
    }
  }

  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];
      // cargar los combos
      if (row.columna.id == 3) { // tipos FILTRO!
        var combo = [];
        var lag = [];
        this.listaTipoConsumibles.forEach(
          tipo => {
            if (!lag.includes(tipo)) {
              lag.push(tipo);
              var js = { id: tipo.id, nombre: tipo.nombre };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 4) { // almacenes FILTRO!
        var combo = [];
        var lag = [];
        this.listaAlmacenes.forEach(
          almacen => {
            if (!lag.includes(almacen)) {
              lag.push(almacen);
              var js = { id: almacen.id, nombre: almacen.nombre };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
    }
  }

  onFilter() {
    // si se miran las proximas funciones se vera como se aplica el filtro. 

    this.consumiblesAlta = filterBy(this.consumiblesAltaOriginal, this.filtroToKendo());
    console.log(this.filtroToKendo());
    this.status = true;
    this.actualizarVisible = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo(): CompositeFilterDescriptor {

    // NUESTRO         public datosFiltro = {                                       => KENDO		public value: CompositeFilterDescriptor = {
    // NUESTRO           logic: { id: 0, nombre: 'and' },                           => KENDO		    logic: "or",
    // NUESTRO           group: [                                                   => KENDO		    filters: [
    // NUESTRO             {                                                        => KENDO		      { field: "budget", operator: "gt", value: 60 },
    // NUESTRO               logic: { id: 0, nombre: 'and' },                       => KENDO		      { field: "country", operator: "contains" },
    // NUESTRO               group: [                                               => KENDO		      { field: "discontinued", operator: "eq", value: true },
    // NUESTRO                 {                                                    => KENDO		      {
    // NUESTRO                   columna: { id: 0, nombre: ' ', tipo: '' },         => KENDO		        logic: "and",
    // NUESTRO                   operator: { id: 0, nombre: '' },                   => KENDO		        filters: [
    // NUESTRO                   fechaIni: this.myFunctions.getDateNow(),           => KENDO		          { field: "ordered on", operator: "lt", value: new Date(Date.now()) },
    // NUESTRO                   fechaFin: this.myFunctions.getDateNow(),           => KENDO		        ],
    // NUESTRO                   mostrarCalendario: false,                          => KENDO		      },
    // NUESTRO                   text: '',                                          => KENDO		    ],
    // NUESTRO                   numberMin: 0,                                      => KENDO		  };
    // NUESTRO                   numberMax: 0,                                      => KENDO
    // NUESTRO                   decimalformat: '0.000',                            => KENDO
    // NUESTRO                   decimalMin: 0.0,                                   => KENDO
    // NUESTRO                   decimalMax: 0.0,                                   => KENDO
    // NUESTRO                   check: false,                                      => KENDO
    // NUESTRO                   combo: [                                           => KENDO
    // NUESTRO                     {id: 1, nombre: "opcion 1 "},                    => KENDO
    // NUESTRO                     {id: 2, nombre: "opcion 2"},                     => KENDO
    // NUESTRO                     {id: 3, nombre: "opcion 3"},                     => KENDO
    // NUESTRO                     {id: 4, nombre: "opcion 4"},                     => KENDO
    // NUESTRO                     {id: 5, nombre: "opcion 5"}                      => KENDO
    // NUESTRO                   ],                                                 => KENDO
    // NUESTRO                   comboSelected: {},                                 => KENDO
    // NUESTRO                   comboSelecteds: []                                 => KENDO
    // NUESTRO                 }                                                    => KENDO
    // NUESTRO               ]                                                      => KENDO
    // NUESTRO             }                                                        => KENDO
    // NUESTRO           ]                                                          => KENDO
    // NUESTRO         };                                                           => KENDO

    var em: CompositeFilterDescriptor = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }

  filtroToKendo_recursivo(jFiltro): CompositeFilterDescriptor {

    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {

      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: "or" | "and" = "and";

      if (jFiltro.logic.id == 1)
        logica = "or"

      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR" as unknown as CompositeFilterDescriptor)
            filtro.push(newRow);
        });

      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {} as CompositeFilterDescriptor;

      // COMBO ESTRICTO -
      if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.nombre;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.nombre;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ]; 
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR" as unknown as CompositeFilterDescriptor;
    }
  }

  //FILTRO
  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    )
  }

  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    //this.cargarPiezas();
    this.consumiblesAlta = this.consumiblesAltaOriginal;
  }

    // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
    filtroToCount(jFiltro = this.datosFiltro) {
      var em = this.filtroToCount_recursivo(jFiltro);
      return em;
    }
  
    filtroToCount_recursivo(jFiltro) {
      // es una linea o es un grupo?
      if (jFiltro.group != undefined) {
        // GRUPO
        var count = 0;
        //por cada grupo
        jFiltro.group.forEach(
          linea => {
            var newRow = this.filtroToCount_recursivo(linea);
            if (newRow != undefined && newRow > 0)
              count += newRow;
          });
        return count;
      }
      else if (jFiltro.columna != undefined) {
        // LINEA
        var count = 0;
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
            count = 1;
          }
          else {
            if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
              count = 1;
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
            count = 1;
          }
          else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
            count = 1;
          }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
            count = 1;
          }
          else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
            count = 1;
          }
          else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
            count = 1;
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          count = 1;
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
            count = 1;
          }
          else {
            if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
              count = 1;
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
            count = 1;
          }
          else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
            count = 1;
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
          count = 1;
        }
        return count;
      }
      else {
        return 0;
      }
    }

    borrarLineaFiltro(row, filtro) {
      if (filtro.group.length > 1) {
        //ELIMINAR LINEA DE FILTRO
        let index = filtro.group.findIndex(d => d === row);
        filtro.group.splice(index, 1);
        this.actualizarVisible = true;
      } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
        //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
        let index = this.datosFiltro.group.findIndex(d => d === filtro);
        this.datosFiltro.group.splice(index, 1);
        this.actualizarVisible = true;
      } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
        //ELIMINAR LINEA DE FILTRO
        let index = filtro.group.findIndex(d => d === row);
        filtro.group.splice(index, 1);
        //Se borra y se añade una linea nueva vacia
        filtro.group.push({
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        })
        this.actualizarVisible = true;
      }
    }

  /**
   * Convertir la cantidad de segundos a formato HH:MM:SS 
   * @param seconds Cantidad en segundos
   * @returns formato HH:MM:SS
   */
  secondsToHm(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 16);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }

  /**
   * 
   */
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
