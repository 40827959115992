<kendo-grid [kendoGridBinding]="capas" [selectedKeys]="capas_seleccionados" [sortable]="true" [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true" filterable="menu" kendoGridSelectBy="id" (cellClick)="capas_cellClick($event)">
  <ng-template kendoGridToolbarTemplate position="top">
    <button type="button" (click)="capas_onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button type="button" (click)="capas_onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
  </ng-template>
  <!--id-->
  <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--orden-->
  <kendo-grid-column width="5%" field="orden" title="{{ 'orden' | translate}}" [style]="{'text-align': 'right'}" filter="numeric"></kendo-grid-column>
  <!--nombre-->
  <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <!--Presion-->
  <kendo-grid-column field="presion_activo" title="{{ 'presion' | translate }}" width="7%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.presion_activo" class="k-icon k-i-check"></span>
      <span *ngIf="!dataItem.presion_activo" class="k-icon k-i-close"></span>
    </ng-template>
  </kendo-grid-column>
  <!--Rendimiento/Tasa de transporte-->
  <kendo-grid-column field="rendimientoTasaDeTransporte_activo" title="{{ 'rendimientoTasaDeTransporte' | translate }}" width="15%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.rendimientoTasaDeTransporte_activo" class="k-icon k-i-check"></span>
      <span *ngIf="!dataItem.rendimientoTasaDeTransporte_activo" class="k-icon k-i-close"></span>
    </ng-template>
  </kendo-grid-column>
  <!--Revoluciones motor-->
  <kendo-grid-column field="revolucionesMotor_activo" title="{{ 'revolucionesMotor' | translate }}" width="10%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.revolucionesMotor_activo" class="k-icon k-i-check"></span>
      <span *ngIf="!dataItem.revolucionesMotor_activo" class="k-icon k-i-close"></span>
    </ng-template>
  </kendo-grid-column>
  <!--Carga motor-->
  <kendo-grid-column field="cargaMotor_activo" title="{{ 'cargaMotor' | translate }}" width="8%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.cargaMotor_activo" class="k-icon k-i-check"></span>
      <span *ngIf="!dataItem.cargaMotor_activo" class="k-icon k-i-close"></span>
    </ng-template>
  </kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>
