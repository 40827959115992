import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlasignacionesService } from '@app/_services/controlasignaciones.service';
import { UsuariosService, MenuService, MaquinasService, ClasificacionesService } from '@app/_services';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import * as d3 from 'd3';
import * as c3 from 'c3';
import { Router } from '@angular/router';
import { groupBy } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { MultiSelectTreeCheckableSettings } from "@progress/kendo-angular-dropdowns";

@Component({
  selector: 'app-graficos-data',
  templateUrl: 'controlasignaciones.html'
})

export class ControlasignacionesComponent {

  // Esto se utiliza para no tener en cuenta los cambios de fichajes a menos de 5 minutos
  public maxDifEntreFichajes = 300;

  public checkableSettings: MultiSelectTreeCheckableSettings = {
    checkChildren: true,
    checkOnClick: true,
  };

  public hoy: Date;
  private fIni: Date;
  private fFin: Date;
  public fechaini: Date;
  public fechafin: Date;

  public data: any;
  public operarios: any;
  public selectedOperarios: any;
  public maquinas: any;
  public selectedMaquinas: any;

  public graficoejecucion;
  public graficoparada;

  public justificadoEjecucionTiempo = "00:00:00";
  public justificadoEjecucionPorcentaje = "";
  public noJustificadoEjecucionTiempo = "00:00:00";
  public noJustificadoEjecucionPorcentaje = "";

  public justificadoParadaTiempo = "00:00:00";
  public justificadoParadaPorcentaje = "";
  public noJustificadoParadaTiempo = "00:00:00";
  public noJustificadoParadaPorcentaje = "";

  public tiempoAlarmas = "00:00:00";
  public tiempoMantenimientos = "00:00:00";
  public tiempoApagadas = "00:00:00";
  public tiempoTotal = "00:00:00";

  public loadingDatos = false;

  private translate: TranslateService;

  public infoGraficoSinOperariosControlAsignaciones = "";
  public infoGraficoMaquinasControlAsignaciones = "";

  // GRAFICOS DE BARRAS
  public topN: number = 10;
  public chartOperarios: any;
  public chartMaquinas: any;
  public dataOperarios: any = {};
  public dataMaquinas: any = {};
  public dataMaquinasInf: any = {};
  public dataGraficoFiltrada: any;
  public dataGraficoFiltradaMaquinas: any;
  public dataSelectedGraficoMaquinas = [];
  public dataIdSelectedGraficoMaquinas = [];

  public btnPorDiaOperarioSelected: boolean = false;
  public btnPorDiaMaquinaSelected: boolean = false;
  public tituloSwitchDia = "";
  public lenMax = { 10: 15, 9: 22, 8: 29, 7: 36, 6: 43, 5: 50, 4: 57, 3: 64, 2: 71, 1: 78 }
  // END GRAFICOS DE BARRAS

  // FILTRO
  public secciones: any = [];
  public groupedSeccion: any = [];
  public seccionesSeleccionadas: any = [];
  public grupos: any = [];
  public gruposCargados: any = [];
  public gruposSeleccionados: any = [];
  public clasificacion: any = [];
  public clasificacionSeleccionados: any = [];
  public maquinas_clasificaciones: any = [];
  public relacionesClasificaciones = new Map();

  public eligiendoFechas = false;
  public mostrarCalendario = false;
  public DaysInMonths;
  // END FILTRO

  user = this.userService.userValue;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(
    private maquinasService: MaquinasService,
    private controlasignacionesservice: ControlasignacionesService,
    translate: TranslateService,
    private userService: UsuariosService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    public clasificacionesService: ClasificacionesService,
    private router: Router) {

    this.cargarSeccionesGrupo();
    this.translate = translate;
    this.hoy = this.myFunctions.getDateNow();
    this.fechaini = this.myFunctions.getDateNow();
    this.fechafin = this.myFunctions.getDateNow();
    this.fIni = this.myFunctions.getDateNow();
    this.fFin = this.myFunctions.getDateNow();
    this.menuService.titulo = this.translate.instant('controlAsignaciones').toUpperCase();
    this.tituloSwitchDia = this.translate.instant("porDia");
    this.infoGraficoSinOperariosControlAsignaciones = this.translate.instant("infoGraficoSinOperariosControlAsignaciones");
    this.infoGraficoMaquinasControlAsignaciones = this.translate.instant("infoGraficoMaquinasControlAsignaciones");
  }

  tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }

  cargarSeccionesGrupo() {
    var r1 = false, r2 = false, r3 = false;
    // SECCIONES
    this.userService.getSecciones().subscribe(json => {
      this.userService.secciones = json;
      //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
      var an1: any = this.userService.secciones;
      this.secciones = an1.filter(f => f.activo === true);

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });
      r1 = true
      if (r1 && r2 && r3) this.cargarInf();
    });

    // GRUPO MAQUINAS
    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.grupos.forEach(element => {
        if (element.activo) this.gruposSeleccionados.push(element);
      });
      this.gruposCargados = true;
      r2 = true;
      if (r1 && r2 && r3) this.cargarInf();
    });

    // clasificaciones
    this.clasificacionesService.GetAll().subscribe(
      (result) => {
        var clasificacionesAux: any = this.myFunctions.copy(result);

        //#region AGRUPAR LAS CLASIFICACIONES
        this.clasificacion = clasificacionesAux.filter(f => f.idPadre == null);
        var arrayHijos = clasificacionesAux.filter(f => f.idPadre != null);
        while (arrayHijos.length != 0) {
          var idHijos = [];
          arrayHijos.forEach(element => {
            this.clasificacion.forEach(row => {
              if (row.id == element.idPadre || row.idSubHijos?.includes(element.idPadre)) {
                idHijos.push(element.id)
                if (!row['clasificacionInferior']) {
                  row.clasificacionInferior = [];
                  row.idSubHijos = [];
                }

                row.idSubHijos.push(element.id)
                if (row.id == element.idPadre) row.clasificacionInferior.push(this.myFunctions.copy(element));
                else row.clasificacionInferior = this.setHijos(row.clasificacionInferior, element);
              }
            });
          });
          arrayHijos = arrayHijos.filter(f => !idHijos.includes(f.id))
        }

        //#endregion

        // definir un map para saber de cada clasificacion cual es su hijo
        clasificacionesAux.forEach(element => {
          this.relacionesClasificaciones.set(element.id, [element.id]);

          if (this.clasificacion.filter(f => f.idPadre == element.id).length > 0)
            this.relacionesClasificaciones.set(element.id, this.setHijosArray(element.id, [element.id]));
        });

        this.clasificacionesService.GetMaquinasClasificaciones().subscribe((json: any) => {
          this.maquinas_clasificaciones = json;
          r3 = true;
          if (r1 && r2 && r3) this.cargarInf();
        });
        

      });
  }

  ngOnInit() {

    this.maquinas = [];
    this.operarios = [];
    this.graficoejecucion = c3.generate({
      bindto: '#graficejecucion_controlasignaciones',
      data: {
        columns: [
          ['completo', 0],
          ['nocompleto', 100],
        ],
        type: 'donut',
        order: null
      },
      donut: {
        title: "0",
        width: 10,
        label: { show: false }
      },
      color: {
        pattern: ['#18d6b0', '#ececec']
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: 500
      }
    });

    this.graficoparada = c3.generate({
      bindto: '#graficoparada_controlasignaciones',
      data: {
        columns: [
          ['completo', 0],
          ['nocompleto', 100],
        ],
        type: 'donut',
        order: null
      },
      donut: {
        title: "0",
        width: 10,
        label: { show: false }
      },
      color: {
        pattern: ['#18d6b0', '#ececec']
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: 500
      }
    });

    d3.selectAll("#graficejecucion_controlasignaciones .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#graficoparada_controlasignaciones .c3-chart-arcs path").style("stroke-width", "0px");

    d3.select('#graficejecucion_controlasignaciones .c3-chart-arcs-title').text("").style("font-size", "20px").attr("fill", "#18d6b0");
    d3.select('#graficoparada_controlasignaciones .c3-chart-arcs-title').text("").style("font-size", "20px").attr("fill", "#18d6b0");

    this.cargarMeses()

  }

  // Funcion recursiva para crear el map de las clasificaciones
  setHijos(clasificacionInferiorArray, hijo) {
    clasificacionInferiorArray.every(element => {
      // si el elemento es el padre
      if (element.id == hijo.idPadre) {
        if (!element['clasificacionInferior']) {
          element.clasificacionInferior = []
          element.idSubHijos = [];
        }
        element.clasificacionInferior.push(this.myFunctions.copy(hijo))
        element.idSubHijos.push(hijo.id);
        return false;
      }
      // si el elemento contiene un hijo que sea su padre
      else if (element.idSubHijos?.includes(hijo.idPadre)) {
        element.clasificacionInferior = this.setHijos(element.clasificacionInferior, hijo)
        return false;
      }

      return true
    });

    return clasificacionInferiorArray;
  }
  setHijosArray(id, array) {
    var idHijos = this.clasificacion.filter(f => f.idPadre == id);
    if (idHijos.length > 0)
      idHijos.forEach(element => {
        array.push(element.id)
        this.setHijos(element.id, array);
      });

    return array;
  }

  cargarInf() {
    this.dibujarGraficoOperarios();
    this.dibujarGraficoMaquinas();

    this.CargarDatos();
  }

  public onChangeFIni(value: Date): void {
    this.fIni = value;
  }

  public onChangeFFin(value: Date): void {
    this.fFin = value;
  }

  public CargarDatos() {

    if (this.fIni == undefined || this.fFin == undefined || this.fIni.getTime() > this.fFin.getTime()) {
      return;
    }

    this.loadingDatos = true;

    var fecha = this.fechaini.getFullYear() + "-" + (this.fechaini.getMonth() + 1) + "-" + this.fechaini.getDate() + "/" + this.fechafin.getFullYear() + "-" + (this.fechafin.getMonth() + 1) + "-" + this.fechafin.getDate();

    this.controlasignacionesservice.getData(fecha).pipe(first()).subscribe((data: any) => {
      this.data = this.juntarTablas(data);
      console.log(this.data);
      this.data = this.data.filter(f => {
        var result = true;
        this.seccionesSeleccionadas.every(s => {
          if (f.idSeccion == s.id)
            result = false
          return result;
        })
        var result2 = true;
        this.gruposSeleccionados.every(s => {
          if (f.idMaquinaGrupo.includes(s.id))
            result2 = false
          return result2;
        })
        return !result && !result2;
      });

      // para filtrar clasificaciones
      if (this.clasificacionSeleccionados.length > 0) {
        var idClasificacionesAFiltrar = []
        this.clasificacionSeleccionados.forEach(element => {
          var aux = this.relacionesClasificaciones.get(element.id);
          idClasificacionesAFiltrar.push(...aux);
        });
        var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
        idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
        this.data = this.data.filter(f => idMaquinas.includes(f.idMaquina));
      }

      this.prepararDatos()
      this.rellenarGrids();
      this.loadingDatos = false;
    });

  }

  juntarTablas(json) {
    // table => consulta base
    var base = json.table;
    // table1 => maquinas
    var infMaquinas = json.table1;
    // table2 => operarios
    var infOperarios = json.table2;
    // table3 => inf fichajes
    // var infFichajes = json.table3;
    var infFichajes = this.procesarFichajes(base);
    // tablas juntas
    var result = [];
    // resultado agrupado
    var resultAgrupado = [];

    // se utiliza para controlar si es necesario insertar una nueva fila o no
    var lagAnterior: any = {};
    var tablasJuntas: any = {};
    var cambio: boolean = true; var primeraVez: boolean = true;
    var i = 0 // se utiliza para comprobar las microparadas/microejecuciones entre procesos

    base.forEach(element => {

      //#region JUNTAR TABLAS
      let maqArray = infMaquinas.filter(f => f.idMaquina == element.idMaquina);
      let operarios = infOperarios.filter(f => f.idOperario == element.idOperario)[0];
      let fichajes = infFichajes.filter(f => f.idOperario == element.idOperario)[0];

      let maq;
      if (maqArray.length == 0)
        maq = { idMaquina: -1, nombreMaquina: this.translate.instant('sinAsignar'), tiempoMicroEjecucion: 0, tiempoMicroParada: 0, idMaquinaGrupo: [-1], maquinaGrupo: '', idSeccion: -1, seccion: '' };
      else {
        let idGrupos = []
        maqArray.forEach(element => {
          idGrupos.push(element.idMaquinaGrupo);
        });
        maq = this.myFunctions.copy(maqArray[0]);
        maq.idMaquinaGrupo = idGrupos;
      }
      
      if (operarios == undefined)
        operarios = { idOperario: -1, nombreOperario: this.translate.instant('sinAsignar') };
      if (fichajes == undefined)
        fichajes = { tiempoFichado: 0 };

      // tablasJuntas = Object.assign({}, element, maq);
      // tablasJuntas = Object.assign({}, tablasJuntas, operarios);
      // tablasJuntas = Object.assign({}, tablasJuntas, fichajes);

      tablasJuntas = { 
        // base
        fechafin: element.fechafin,
        fechaini: element.fechaini,
        fechaTurno: element.fechaTurno,
        idHistoricoOperaciones: element.idHistoricoOperaciones,
        idMaquina: element.idMaquina,
        idOperacion: element.idOperacion,
        idOperario: element.idOperario,
        idPerdidaHistoricoBase: element.idPerdidaHistoricoBase,
        idProcesos_Tipo: element.idProcesos_Tipo,
        idProcesos_Tipo_subEstados: element.idProcesos_Tipo_subEstados,
        microejecucion: element.microejecucion,
        microparada: element.microparada,
        operacion: element.operacion,
        tiemporeal: element.tiemporeal,
        tipoTurno: element.tipoTurno,
        // maquinas
        idMaquinaGrupo: maq.idMaquinaGrupo,
        idSeccion: maq.idSeccion,
        maquinaGrupo: maq.maquinaGrupo,
        nombreMaquina: maq.nombreMaquina,
        seccion: maq.seccion,
        tiempoMicroEjecucion: maq.tiempoMicroEjecucion,
        tiempoMicroParada: maq.tiempoMicroParada,
        // operarios  
        nombreOperario: operarios.nombreOperario,
        // fichajes     
        tiempoFichado: fichajes.tiempoFichado, 
        tiempoFichadoActual: fichajes.tiempoFichadoActual,
      }
      //#endregion

      //#region CONVERTIR idProcesos_tipo (alarma, apagada => parada // preparacion => ejecucion)
      if (tablasJuntas.idProcesos_Tipo == 3)
        tablasJuntas.idProcesos_Tipo = 1;
      else if (tablasJuntas.idProcesos_Tipo == 6 || tablasJuntas.idProcesos_Tipo == 7 || tablasJuntas.idProcesos_Tipo == 8)
        tablasJuntas.idProcesos_Tipo = 2;
      //#endregion

      tablasJuntas.tEjecucionesJustificadas = 0; tablasJuntas.tEjecucionesNoJustificadas = 0; tablasJuntas.tParadasJustificadas = 0; tablasJuntas.tParadasNoJustificadas = 0;
      tablasJuntas.tPreparaciones = 0; tablasJuntas.tAlarmas = 0; tablasJuntas.tMantenimientos = 0; tablasJuntas.tApagadas = 0; tablasJuntas.tiempoTotal = 0;
      tablasJuntas.cambiarTipoProceso = false;

      result.push(tablasJuntas)

    });
    result.sort((a, b) => {
      if (a.idMaquina > b.idMaquina) return 1
      else if (b.idMaquina > a.idMaquina) return -1
      else if (a.fechaini > b.fechaini) return 1
      else if (a.fechaini < b.fechaini) return -1
      else return 0
    });
    var lag: any = result[0];
    result.forEach(element => {

      if (cambio) {
        lagAnterior = this.myFunctions.copy(lag);
      }

      //#region COMPROBAR SI ES NECESARIO CAMBIAR
      var boolOperario = lagAnterior.idOperario != element.idOperario;
      var boolMaquina = lagAnterior.idMaquina != element.idMaquina;
      var boolIdHO = lagAnterior.idHistoricoOperaciones != element.idHistoricoOperaciones;

      // var bool1 = lagAnterior.idProcesos_Tipo == 2 && element.idProcesos_Tipo != 2 && element.microejecucion == 0;
      // var bool2 = lagAnterior.idProcesos_Tipo == 1 && element.idProcesos_Tipo != 1 && element.microparada == 0;

      var bool3 = false;
      if (lagAnterior.idProcesos_Tipo == 1 && element.idProcesos_Tipo == 2)
        bool3 = this.esMicro(lagAnterior.idProcesos_Tipo, element.tiempoMicroParada, /*result.slice(i)*/ this.removeRows(result, i), element.tipoTurno, false, element.idHistoricoOperaciones, element.idOperario);
      if (lagAnterior.idProcesos_Tipo == 2 && element.idProcesos_Tipo == 1)
        bool3 = this.esMicro(lagAnterior.idProcesos_Tipo, element.tiempoMicroEjecucion, /*result.slice(i)*/ this.removeRows(result, i), element.tipoTurno, true, element.idHistoricoOperaciones, element.idOperario);

      // pasar de ejecucion no identificada <=> a ejecucion identificada
      var bool4_1 = (lagAnterior.idProcesos_Tipo == 1 && lagAnterior.operacion == 1) && (element.idProcesos_Tipo == 1 && element.operacion == 0)
      var bool4_2 = (lagAnterior.idProcesos_Tipo == 1 && lagAnterior.operacion == 0) && (element.idProcesos_Tipo == 1 && element.operacion == 1)
      var bool4 = bool4_1 || bool4_2

      // pasar de parada no justificada <=> a parada justificada
      var bool5_1 = (lagAnterior.idProcesos_Tipo == 2 && lagAnterior.idPerdidaHistoricoBase > 0) && (element.idProcesos_Tipo == 2 && element.idPerdidaHistoricoBase <= 0)
      var bool5_2 = (lagAnterior.idProcesos_Tipo == 2 && lagAnterior.idPerdidaHistoricoBase <= 0) && (element.idProcesos_Tipo == 2 && element.idPerdidaHistoricoBase > 0)
      var bool5 = bool5_1 || bool5_2

      // cambio de turno
      var bool6 = false;
      if (element.tipoTurno != lagAnterior.tipoTurno)
        bool6 = true;

      // cambio de dia
      var bool7 = false;
      if (element.fechaTurno != lagAnterior.fechaTurno)
        bool7 = true;

      // si la perdida es diferente
      var boolPerdida = false;
      if (lagAnterior.idPerdidaHistoricoBase != element.idPerdidaHistoricoBase)
          boolPerdida = true;

          // Si es mantenimiento
      var boolMantenimiento = false;
      if ((element.idProcesos_Tipo == 4 || lagAnterior.idProcesos_Tipo == 4) && element.idProcesos_Tipo!=lagAnterior.idProcesos_Tipo)
        boolMantenimiento = true;

      if (boolOperario || boolMaquina || boolIdHO /*|| bool1 || bool2*/ || bool3 || bool4 || bool5 || bool6 || bool7 || boolPerdida || boolMantenimiento /*|| i == result.length - 1*/)
        element.cambiarTipoProceso = true;

      //#endregion

      if (!element.cambiarTipoProceso) cambio = false;

      // si no es necesario annadir una linea entonces se suman los valores
      if (element.cambiarTipoProceso) {
        // con esta funcion nos aseguramos que la parada o ejecucion sean suficientemente larga. Esto es sobretodo por si al principio hay una microparada o microejecucion
        var esLarga = this.esSuficientementeLarga(lagAnterior);
        if (esLarga || boolIdHO || boolOperario || bool6 || boolPerdida || bool7 || boolMantenimiento) {
          if (lagAnterior.tiemporeal > lagAnterior.tiempoMicroParada) lagAnterior.microparada = false;
          resultAgrupado.push(lagAnterior);
          lag = this.myFunctions.copy(element);
          lag.cambiarTipoProceso = false;
          lagAnterior = this.myFunctions.copy(lag);
          cambio = true;
        } else if (element.tipoTurno == lagAnterior.tipoTurno && element.idMaquina == lagAnterior.idMaquina) { // si esta en el mismo turno se agrupa con el siguiente proceso
          lag = this.myFunctions.copy(element);
          lag.cambiarTipoProceso = false;
          lag.tiemporeal += lagAnterior.tiemporeal;
          lag.fechaini = lagAnterior.fechaini;
          lagAnterior = this.myFunctions.copy(lag);
          cambio = false;
        } else if (resultAgrupado.length > 0 && element.idMaquina == lagAnterior.idMaquina) { 
          if (resultAgrupado[resultAgrupado.length - 1].idMaquina != element.idMaquina
            || resultAgrupado[resultAgrupado.length - 1].idOperario != element.idOperario
            || resultAgrupado[resultAgrupado.length - 1].idHistorico_operaciones != element.idHistorico_operaciones) { // si la ultima fila insertada tiene diferente idMaquina/idOperario/idHO no se puede agrupar con la anterior fila
            lag = this.myFunctions.copy(element);
            lag.cambiarTipoProceso = false;
            lag.tiemporeal += lagAnterior.tiemporeal;
            lagAnterior = this.myFunctions.copy(lag);
            cambio = false;
          } else { // si esta en el mismo turno se agrupa con el anterior proceso
            resultAgrupado[resultAgrupado.length - 1].tiemporeal += lagAnterior.tiemporeal;
            lag = this.myFunctions.copy(element);
            lag.cambiarTipoProceso = false;
            cambio = true;
          }
        } else {
          if (lagAnterior.tiemporeal > lagAnterior.tiempoMicroParada) lagAnterior.microparada = false;
          resultAgrupado.push(lagAnterior);
          lag = this.myFunctions.copy(element);
          lag.cambiarTipoProceso = false;
          lagAnterior = this.myFunctions.copy(lag);
          cambio = true;
        }
      } else if (!primeraVez) {
        lagAnterior.tiemporeal += element.tiemporeal;
        lagAnterior.fechafin = element.fechafin;

        if (element.idPerdidaHistoricoBase > 0)
          lagAnterior.idPerdidaHistoricoBase = element.idPerdidaHistoricoBase;
      }
      // if (element.cambiarTipoProceso) {
      //   // con esta funcion nos aseguramos que la parada o ejecucion sean suficientemente larga. Esto es sobretodo por si al principio hay una microparada o microejecucion
      //   var esLarga = this.esSuficientementeLarga(lagAnterior);
      //   if (esLarga || boolIdHO || boolOperario || bool6) {
      //     resultAgrupado.push(lagAnterior);
      //     lag = this.myFunctions.copy(element);
      //     lag.cambiarTipoProceso = false;
      //     cambio = true;
      //   } else if (element.tipoTurno == lagAnterior.tipoTurno && element.idHistoricoOperaciones == lagAnterior.idHistoricoOperaciones) { // si esta en el mismo turno se agrupa con el siguiente proceso
      //     lag = this.myFunctions.copy(element);
      //     lag.cambiarTipoProceso = false;
      //     lag.tiemporeal += lagAnterior.tiemporeal;
      //     lagAnterior = this.myFunctions.copy(lag);
      //   } else if (resultAgrupado.length > 0 && element.idHistoricoOperaciones == lagAnterior.idHistoricoOperaciones) { // si no esta en el mismo turno se agrupa con el anterior proceso
      //     resultAgrupado[resultAgrupado.length - 1].tiemporeal += lagAnterior.tiemporeal;
      //     lag = this.myFunctions.copy(element);
      //     lag.cambiarTipoProceso = false;
      //     cambio = true;
      //   } else {
      //     resultAgrupado.push(lagAnterior);
      //     lag = this.myFunctions.copy(element);
      //     lag.cambiarTipoProceso = false;
      //     cambio = true;
      //   }
      // } else if (!primeraVez) {
      //   lagAnterior.tiemporeal += element.tiemporeal;
      //   lagAnterior.fechafin = element.fechafin;

      //   if (element.idPerdidaHistoricoBase > 0)
      //     lagAnterior.idPerdidaHistoricoBase = element.idPerdidaHistoricoBase;
      // }

      if (i == result.length - 1) { // insertar la fila
        if (element.cambiarTipoProceso && !primeraVez && element.fechafin != lagAnterior.fechafin) lagAnterior.tiemporeal += element.tiemporeal;
        if (lagAnterior.tiemporeal > lagAnterior.tiempoMicroParada) lagAnterior.microparada = false;
          resultAgrupado.push(lagAnterior);
      }

      primeraVez = false;

      i++;
    });
    return resultAgrupado;
  }

  procesarFichajes(inf) {
    var result = {};

    var listaOrdenada = this.myFunctions.copy(inf);
    // Ordenar la lista por fechaini ascendente
    listaOrdenada.sort((a, b) => {
      if (a.fechaini > b.fechaini) return 1
      else if (b.fechaini > a.fechaini) return -1
      else return 0
    });

    listaOrdenada.forEach(element => {
      if (!result[element.idOperario]) {
        var seg = Math.abs(new Date(element.fechafin).getTime() - new Date(element.fechaini).getTime()) / 1000;
        result[element.idOperario] = {
          idOperario: element.idOperario
          , fechaini: element.fechaini
          , fechafin: element.fechafin
          , tiempoFichado: 0
          , tiempoFichadoActual: seg
        }
      } else {
        var fechainiRow = new Date(element.fechaini);
        var fechafinRow = new Date(element.fechafin);

        var fechaini = new Date(result[element.idOperario].fechaini);
        var fechafin = new Date(result[element.idOperario].fechafin);
        // los turnos estan solapados por lo que no hay que contar x2 el tiempo (se contara el tiempo mayor)
        // if (fechaini <= fechainiRow && fechainiRow <= fechafin) {
        if (fechaini.getTime() -  fechainiRow.getTime() <= 0 && fechainiRow.getTime() - fechafin.getTime() <= 0) {
          if (fechafinRow.getTime() - fechafin.getTime() > 0)
            result[element.idOperario].fechafin = element.fechafin;

          result[element.idOperario].tiempoFichadoActual = Math.abs(new Date(result[element.idOperario].fechafin).getTime() - new Date(result[element.idOperario].fechaini).getTime()) / 1000;
        }
        // hay un paron de menos de x tiempo por lo que se considerara todo seguido
        else if (Math.abs(fechainiRow.getTime() - fechafin.getTime()) / 1000 <= this.maxDifEntreFichajes) {
          result[element.idOperario].fechafin = element.fechafin;
          result[element.idOperario].tiempoFichadoActual = Math.abs(new Date(result[element.idOperario].fechafin).getTime() - new Date(result[element.idOperario].fechaini).getTime()) / 1000;
        }
        // hay un paron de mas de x tiempo por lo que no hay que considerar como tiempo fichado lo del medio
        else if (Math.abs(fechainiRow.getTime() - fechafin.getTime()) / 1000 > this.maxDifEntreFichajes) {
          result[element.idOperario].fechaini = element.fechaini;
          result[element.idOperario].fechafin = element.fechafin;
          result[element.idOperario].tiempoFichado += result[element.idOperario].tiempoFichadoActual;
          result[element.idOperario].tiempoFichadoActual = Math.abs(new Date(result[element.idOperario].fechafin).getTime() - new Date(result[element.idOperario].fechaini).getTime()) / 1000;
        }

      }
    });

    var resultNew = [];
    var that = this;
    Object.keys(result).forEach(function (element) {
      result[element].tiempoFichado += result[element].tiempoFichadoActual;
      if (element == '-1')
        result[element].tiempoFichado = 0;
      resultNew.push(that.myFunctions.copy(result[element]));
    });
    return resultNew;
  }

  // funcion auxiliar para procesar unicamente las lineas necesarias
  removeRows(tabla, index) {
    var result = [];

    if (tabla.length >= index) {
      result.push(tabla[index]);
    }
    for (var j = index + 1; j<tabla.length; j++) {
      if (tabla[j].tiempoMicroEjecucion < tabla[j].tiemporeal && tabla.idProcesos_Tipo == 1
        || tabla[j].tiempoMicroParada < tabla[j].tiemporeal && tabla.idProcesos_Tipo == 2) {
        result.push(tabla[j]);
        break;
      }
      result.push(tabla[j]);
    }
    return result;
  }

  // esta funcion comprueba que si hay alguna microparada/microejecucion entre procesos no se cambie de estado
  esMicro(idProcesosTipo, tiempoMin, array, tipoTurno, microParadaEjecucion, idHO, idOperario) {
    var tiempoAcumulado = 0;
    var result = false;
    var minParadaAConsiderar = 0;
    if (minParadaAConsiderar > tiempoMin) {
      minParadaAConsiderar = tiempoMin;
    }
    var lagIdHoAnterior = idHO;
    var lagIdOperarioAnterior = idOperario;
    if ((idProcesosTipo == 1 && array[0]?.tiemporeal >= minParadaAConsiderar) || idProcesosTipo != 1) {
      array.every(element => {
        var isMicro;
        if (idProcesosTipo == 1) isMicro = element.microejecucion
        else isMicro = element.microparada

        if (element.tipoTurno != tipoTurno) {
          result = true;
          lagIdHoAnterior = element.idHistoricoOperaciones;
          lagIdOperarioAnterior = element.idOperario;
          return false;
        } else if (element.idHistoricoOperaciones != lagIdHoAnterior || element.idOperario != lagIdOperarioAnterior) {
          result = false;
          lagIdHoAnterior = element.idHistoricoOperaciones;
          lagIdOperarioAnterior = element.idOperario;
          return false;
        } else if (element.idProcesos_Tipo != idProcesosTipo) { // si es 1 != 2 y micro entonces seguir sumando el tiempo acumulado
          tiempoAcumulado += element.tiemporeal;
          if (tiempoAcumulado > tiempoMin) // si el tiempo acumulado supera el minimo entonces ya se debe de cambiar de linea
            result = true;
          lagIdHoAnterior = element.idHistoricoOperaciones;
          lagIdOperarioAnterior = element.idOperario;
          return !result
        } else if (element.idProcesos_Tipo == idProcesosTipo && isMicro && !microParadaEjecucion) { // si el proceso vuelve a tener el mismo proceso pero el tiempo es menor al minimo
          tiempoAcumulado += element.tiemporeal;
          if (tiempoAcumulado > tiempoMin) // si el tiempo acumulado supera el minimo entonces ya se debe de cambiar de linea
            result = true;
          lagIdHoAnterior = element.idHistoricoOperaciones;
          lagIdOperarioAnterior = element.idOperario;
          return !result
        } else if (element.idProcesos_Tipo == idProcesosTipo) { // si el proceso vuelve a tener el mismo id entonces no 
          result = false;
          lagIdHoAnterior = element.idHistoricoOperaciones;
          lagIdOperarioAnterior = element.idOperario;
          return false;
        }
        lagIdHoAnterior = element.idHistoricoOperaciones;
        lagIdOperarioAnterior = element.idOperario;
        return true;
      })
    }

    return result;
  }

  esSuficientementeLarga(linea) {
    if (linea.idProcesos_Tipo == 1 && linea.tiemporeal < linea.tiempoMicroEjecucion) {
      return false;
    } else if (linea.idProcesos_Tipo == 2 && linea.tiemporeal < linea.tiempoMicroParada) {
      return false;
    }
    return true;
  }

  prepararDatos() {
    var paradasNoJustificadasArray = [];
    this.data.forEach(lag => {
      // EjecucionesJustificadas
      if ((lag.idProcesos_Tipo == 1 && lag.operacion == 1) /*|| (lag.microparada && lag.idPerdidaHistoricoBase <= 0)*/)
        lag.tEjecucionesJustificadas += lag.tiemporeal;
      // EjecucionesNoJustificadas
      else if ((lag.idProcesos_Tipo == 1 && lag.operacion == 0) /*|| (lag.microparada && lag.idPerdidaHistoricoBase <= 0)*/)
        lag.tEjecucionesNoJustificadas += lag.tiemporeal;
      // ParadasJustificadas
      else if (([2, 6, 7, 8].includes(lag.idProcesos_Tipo) && (lag.idPerdidaHistoricoBase > 0 || lag.microparada) ))
        lag.tParadasJustificadas += lag.tiemporeal;
      // ParadasNoJustificadas
      else if (([2, 6, 7, 8].includes(lag.idProcesos_Tipo)) && (lag.idPerdidaHistoricoBase <= 0 || !lag.microparada)) {
        lag.tParadasNoJustificadas += lag.tiemporeal;
        paradasNoJustificadasArray.push(lag);
      }// Mantenimientos
      if (lag.idProcesos_Tipo == 4)
        lag.tMantenimientos += lag.tiemporeal;
      // Total
      lag.tiempoTotal += lag.tEjecucionesJustificadas + lag.tEjecucionesNoJustificadas + lag.tParadasJustificadas + lag.tParadasNoJustificadas + lag.tMantenimientos;
    });
  }

  rellenarGrids() {

    //GROUP BY POR OPERARIO
    var groupByPorOperario = [];

    this.data.forEach(function (a) {
      if (!this[a.idOperario]) {
        this[a.idOperario] = {
          idOperario: a.idOperario, nombreOperario: a.nombreOperario, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
          tMantenimientos: 0, tTotal: 0, tiempoFichado: a.tiempoFichado
        };
        groupByPorOperario.push(this[a.idOperario]);
      }
      this[a.idOperario].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
      this[a.idOperario].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
      this[a.idOperario].tParadasJustificadas += a.tParadasJustificadas;
      this[a.idOperario].tParadasNoJustificadas += a.tParadasNoJustificadas;
      this[a.idOperario].tMantenimientos += a.tMantenimientos;
      this[a.idOperario].tTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;

      if (this[a.idOperario].tEjecucionesNoJustificadas >= 60)
        this[a.idOperario].classEjecucionesNoJustificadas = 'textorojo';
      else
        this[a.idOperario].classEjecucionesNoJustificadas = '';

      if (this[a.idOperario].tParadasNoJustificadas >= 60)
        this[a.idOperario].classParadasNoJustificadas = 'textorojo';
      else
        this[a.idOperario].classParadasNoJustificadas = '';

    }, Object.create(null));

    this.operarios = groupByPorOperario;
    this.selectedOperarios = [];

    //GROUP BY POR MAQUINA
    var groupByPorMaquina = [];

    this.data.forEach(function (a) {
      if (!this[a.idMaquina]) {
        this[a.idMaquina] = {
          idMaquina: a.idMaquina, nombreMaquina: a.nombreMaquina, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
          tMantenimientos: 0, tTotal: 0
        };
        groupByPorMaquina.push(this[a.idMaquina]);
      }
      this[a.idMaquina].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
      this[a.idMaquina].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
      this[a.idMaquina].tParadasJustificadas += a.tParadasJustificadas;
      this[a.idMaquina].tParadasNoJustificadas += a.tParadasNoJustificadas;
      this[a.idMaquina].tMantenimientos += a.tMantenimientos;
      this[a.idMaquina].tTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;

      if (this[a.idMaquina].tEjecucionesNoJustificadas >= 60)
        this[a.idMaquina].classEjecucionesNoJustificadas = 'textorojo';
      else
        this[a.idMaquina].classEjecucionesNoJustificadas = '';

      if (this[a.idMaquina].tParadasNoJustificadas >= 60)
        this[a.idMaquina].classParadasNoJustificadas = 'textorojo';
      else
        this[a.idMaquina].classParadasNoJustificadas = '';

    }, Object.create(null));

    this.maquinas = groupByPorMaquina;
    this.selectedMaquinas = [];

    this.cargarGraficos();
    this.cargarGraficosBarras();
    this.cargarGraficoBarrasPorDia();

  }

  public onSelectOperario(e): void {

    this.selectedMaquinas = [];

    this.rellenarGridsConFiltroOperarios();

  }

  public onSelectMaquinas(e): void {

    this.selectedOperarios = [];

    this.rellenarGridsConFiltroMaquinas();
  }

  rellenarGridsConFiltroOperarios() {

    //GROUP BY POR OPERARIO CON DATA ORIGINAL
    var groupByPorOperario = [];

    this.data.forEach(function (a) {
      if (!this[a.idOperario]) {
        this[a.idOperario] = {
          idOperario: a.idOperario, nombreOperario: a.nombreOperario, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
          tMantenimientos: 0, tTotal: 0, tiempoFichado: a.tiempoFichado
        };
        groupByPorOperario.push(this[a.idOperario]);
      }

      this[a.idOperario].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
      this[a.idOperario].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
      this[a.idOperario].tParadasJustificadas += a.tParadasJustificadas;
      this[a.idOperario].tParadasNoJustificadas += a.tParadasNoJustificadas;
      this[a.idOperario].tMantenimientos += a.tMantenimientos;
      this[a.idOperario].tTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;

      if (this[a.idOperario].tEjecucionesNoJustificadas >= 60)
        this[a.idOperario].classEjecucionesNoJustificadas = 'textorojo';
      else
        this[a.idOperario].classEjecucionesNoJustificadas = '';

      if (this[a.idOperario].tParadasNoJustificadas >= 60)
        this[a.idOperario].classParadasNoJustificadas = 'textorojo';
      else
        this[a.idOperario].classParadasNoJustificadas = '';

    }, Object.create(null));

    this.operarios = groupByPorOperario;

    var th = this;

    var dataFiltrada = [];
    if (th.selectedOperarios.length == 0) {
      dataFiltrada = this.data;
    } else {
      this.data.forEach(function (a) {
        if (th.selectedOperarios.includes(a.idOperario)) {
          dataFiltrada.push(a);
        }
      });
    }

    //GROUP BY POR MAQUINA CON DATOS FILTRADOS
    var groupByPorMaquina = [];

    dataFiltrada.forEach(function (a) {
      if (!this[a.idMaquina]) {
        this[a.idMaquina] = {
          idMaquina: a.idMaquina, nombreMaquina: a.nombreMaquina, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
          tMantenimientos: 0, tTotal: 0
        };
        groupByPorMaquina.push(this[a.idMaquina]);
      }
      this[a.idMaquina].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
      this[a.idMaquina].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
      this[a.idMaquina].tParadasJustificadas += a.tParadasJustificadas;
      this[a.idMaquina].tParadasNoJustificadas += a.tParadasNoJustificadas;
      this[a.idMaquina].tMantenimientos += a.tMantenimientos;
      this[a.idMaquina].tTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;

      if (this[a.idMaquina].tEjecucionesNoJustificadas >= 60)
        this[a.idMaquina].classEjecucionesNoJustificadas = 'textorojo';
      else
        this[a.idMaquina].classEjecucionesNoJustificadas = '';

      if (this[a.idMaquina].tParadasNoJustificadas >= 60)
        this[a.idMaquina].classParadasNoJustificadas = 'textorojo';
      else
        this[a.idMaquina].classParadasNoJustificadas = '';

    }, Object.create(null));

    this.maquinas = groupByPorMaquina;

    this.cargarGraficos();
    this.cargarGraficosBarras(th.selectedOperarios, true);
    this.cargarGraficoBarrasPorDia();

  }

  rellenarGridsConFiltroMaquinas() {

    //GROUP BY POR MAQUINA CON DATOS ORIGINALES
    var groupByPorMaquina = [];

    this.data.forEach(function (a) {
      if (!this[a.idMaquina]) {
        this[a.idMaquina] = {
          idMaquina: a.idMaquina, nombreMaquina: a.nombreMaquina, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
          tMantenimientos: 0, tTotal: 0
        };
        groupByPorMaquina.push(this[a.idMaquina]);
      }
      this[a.idMaquina].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
      this[a.idMaquina].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
      this[a.idMaquina].tParadasJustificadas += a.tParadasJustificadas;
      this[a.idMaquina].tParadasNoJustificadas += a.tParadasNoJustificadas;
      this[a.idMaquina].tMantenimientos += a.tMantenimientos;
      this[a.idMaquina].tTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;

      if (this[a.idMaquina].tEjecucionesNoJustificadas >= 60)
        this[a.idMaquina].classEjecucionesNoJustificadas = 'textorojo';
      else
        this[a.idMaquina].classEjecucionesNoJustificadas = '';

      if (this[a.idMaquina].tParadasNoJustificadas >= 60)
        this[a.idMaquina].classParadasNoJustificadas = 'textorojo';
      else
        this[a.idMaquina].classParadasNoJustificadas = '';

    }, Object.create(null));

    this.maquinas = groupByPorMaquina;

    var th = this;

    var dataFiltrada = [];
    if (th.selectedMaquinas.length == 0) {
      dataFiltrada = this.data;
    } else {
      this.data.forEach(function (a) {
        if (th.selectedMaquinas.includes(a.idMaquina)) {
          dataFiltrada.push(a);
        }
      });
    }

    //GROUP BY POR OPERARIO CON DATOS FILTRADOS
    var groupByPorOperario = [];

    dataFiltrada.forEach(function (a) {
      if (!this[a.idOperario]) {
        this[a.idOperario] = {
          idOperario: a.idOperario, nombreOperario: a.nombreOperario, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
          tMantenimientos: 0, tTotal: 0, tiempoFichado: a.tiempoFichado
        };
        groupByPorOperario.push(this[a.idOperario]);
      }
      this[a.idOperario].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
      this[a.idOperario].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
      this[a.idOperario].tParadasJustificadas += a.tParadasJustificadas;
      this[a.idOperario].tParadasNoJustificadas += a.tParadasNoJustificadas;
      this[a.idOperario].tMantenimientos += a.tMantenimientos;
      this[a.idOperario].tTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;

      if (this[a.idOperario].tEjecucionesNoJustificadas >= 60)
        this[a.idOperario].classEjecucionesNoJustificadas = 'textorojo';
      else
        this[a.idOperario].classEjecucionesNoJustificadas = '';

      if (this[a.idOperario].tParadasNoJustificadas >= 60)
        this[a.idOperario].classParadasNoJustificadas = 'textorojo';
      else
        this[a.idOperario].classParadasNoJustificadas = '';

    }, Object.create(null));

    this.operarios = groupByPorOperario;

    this.cargarGraficos();
    this.cargarGraficosBarras(th.selectedMaquinas);
    this.cargarGraficoBarrasPorDia();

  }

  cargarGraficos() {

    var th = this;

    var ejecucionesjustificados = 0;
    var ejecucionesnojustificados = 0;
    var paradasjustificadas = 0;
    var paradasnojustificadas = 0;

    var tiempoAlarmas = 0;
    var tiempoMantenimientos = 0;
    var tiempoApagadas = 0;
    var tiempoTotal = 0;

    var porcentajeejecucion = 0;
    var porcentajeparada = 0;

    if (th.selectedOperarios.length == 0) {
      this.operarios.forEach(function (a) {
        ejecucionesjustificados += a.tEjecucionesJustificadas;
        ejecucionesnojustificados += a.tEjecucionesNoJustificadas;
        paradasjustificadas += a.tParadasJustificadas;
        paradasnojustificadas += a.tParadasNoJustificadas;
        tiempoAlarmas += a.tAlarmas;
        tiempoMantenimientos += a.tMantenimientos;
        tiempoApagadas += a.tApagadas;
        tiempoTotal += a.tTotal;
      });
    } else {
      this.operarios.forEach(function (a) {
        if (th.selectedOperarios.includes(a.idOperario)) {
          ejecucionesjustificados += a.tEjecucionesJustificadas;
          ejecucionesnojustificados += a.tEjecucionesNoJustificadas;
          paradasjustificadas += a.tParadasJustificadas;
          paradasnojustificadas += a.tParadasNoJustificadas;
          tiempoAlarmas += a.tAlarmas;
          tiempoMantenimientos += a.tMantenimientos;
          tiempoApagadas += a.tApagadas;
          tiempoTotal += a.tTotal;
        }
      });
    }

    if (ejecucionesjustificados == 0 && ejecucionesnojustificados == 0) porcentajeejecucion = 0;
    else porcentajeejecucion = ((ejecucionesjustificados / (ejecucionesjustificados + ejecucionesnojustificados)) * 100);

    if (paradasjustificadas == 0 && paradasnojustificadas == 0) porcentajeparada = 0;
    else porcentajeparada = ((paradasjustificadas / (paradasjustificadas + paradasnojustificadas)) * 100);

    this.justificadoEjecucionTiempo = this.myFunctions.secondsTo_HH_MM_SS(ejecucionesjustificados);
    this.justificadoEjecucionPorcentaje = "(" + Math.round(porcentajeejecucion) + "%)";
    this.noJustificadoEjecucionTiempo = this.myFunctions.secondsTo_HH_MM_SS(ejecucionesnojustificados);
    this.noJustificadoEjecucionPorcentaje = "(" + (100 - Math.round(porcentajeejecucion)) + "%)";

    this.justificadoParadaTiempo = this.myFunctions.secondsTo_HH_MM_SS(paradasjustificadas);
    this.justificadoParadaPorcentaje = "(" + Math.round(porcentajeparada) + "%)";
    this.noJustificadoParadaTiempo = this.myFunctions.secondsTo_HH_MM_SS(paradasnojustificadas);
    this.noJustificadoParadaPorcentaje = "(" + (100 - Math.round(porcentajeparada)) + "%)";

    this.tiempoAlarmas = this.myFunctions.secondsTo_HH_MM_SS(tiempoAlarmas);
    this.tiempoMantenimientos = this.myFunctions.secondsTo_HH_MM_SS(tiempoMantenimientos);
    this.tiempoApagadas = this.myFunctions.secondsTo_HH_MM_SS(tiempoApagadas);
    this.tiempoTotal = this.myFunctions.secondsTo_HH_MM_SS(tiempoTotal);

    this.graficoejecucion.load({
      columns: [["completo", porcentajeejecucion], ["nocompleto", 100 - porcentajeejecucion]]
    });

    d3.select('#graficejecucion_controlasignaciones .c3-chart-arcs-title').transition().duration(250).style("font-size", "0px").style("opacity", "0").transition().duration(250).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(porcentajeejecucion) + "%");

    this.graficoparada.load({
      columns: [["completo", porcentajeparada], ["nocompleto", 100 - porcentajeparada]]
    })

    d3.select('#graficoparada_controlasignaciones .c3-chart-arcs-title').transition().duration(250).style("font-size", "0px").style("opacity", "0").transition().duration(250).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(porcentajeparada) + "%");

  }

  cargarGraficosBarras(filtro = [], isFiltroOperario = false) {

    // comprobar si hay algun filtro
    this.dataGraficoFiltrada = this.myFunctions.copy(this.data);
    if (filtro.length != 0) {
      if (isFiltroOperario) {
        this.dataGraficoFiltradaMaquinas = this.myFunctions.copy(this.dataGraficoFiltrada);
        this.dataGraficoFiltrada = this.dataGraficoFiltrada.filter(f => filtro.includes(f.idOperario));
      } else {
        this.dataGraficoFiltrada = this.dataGraficoFiltrada.filter(f => filtro.includes(f.idMaquina));
        this.dataGraficoFiltradaMaquinas = this.dataGraficoFiltrada.filter(f => filtro.includes(f.idMaquina));
      }
    } else {
      this.dataGraficoFiltradaMaquinas = this.myFunctions.copy(this.dataGraficoFiltrada);
    }

    // primero es necesario obtener el maximo de cada fila de las tres posibilidades
    this.dataGraficoFiltrada.forEach(element => {
      element.max = Math.max(element.tEjecucionesNoJustificadas, element.tParadasNoJustificadas, element.tApagadas);
    });

    this.procesarMaquinas();
    if (!isFiltroOperario)
      this.procesarOperarios();

  }

  procesarOperarios(porDia = false) {
    // obtener topN de ejecuciones sin justificar y paradas sin justificar
    var dataTopN = [];

    // groupByMaquinas
    var groupByPorMaquina = [];

    this.dataGraficoFiltradaMaquinas.forEach(function (a) {
      if (a.idOperario == -1 && porDia) {
        if (!this[a.idMaquina + "_" + a.fechaTurno]) {
          this[a.idMaquina + "_" + a.fechaTurno] = {
            idMaquina: a.idMaquina, nombreMaquina: a.nombreMaquina, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
            tMantenimientos: 0, tiempoTotal: 0
          };
          groupByPorMaquina.push(this[a.idMaquina + "_" + a.fechaTurno]);
        }
        this[a.idMaquina + "_" + a.fechaTurno].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
        this[a.idMaquina + "_" + a.fechaTurno].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
        this[a.idMaquina + "_" + a.fechaTurno].tParadasJustificadas += a.tParadasJustificadas;
        this[a.idMaquina + "_" + a.fechaTurno].tParadasNoJustificadas += a.tParadasNoJustificadas;
        this[a.idMaquina + "_" + a.fechaTurno].tMantenimientos += a.tMantenimientos;
        this[a.idMaquina + "_" + a.fechaTurno].tiempoTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;
        this[a.idMaquina + "_" + a.fechaTurno].fechaTurno = a.fechaTurno;
      } else if (a.idOperario == -1) {
        if (!this[a.idMaquina]) {
          this[a.idMaquina] = {
            idMaquina: a.idMaquina, nombreMaquina: a.nombreMaquina, tEjecucionesJustificadas: 0, tEjecucionesNoJustificadas: 0, tParadasJustificadas: 0, tParadasNoJustificadas: 0,
            tMantenimientos: 0, tiempoTotal: 0
          };
          groupByPorMaquina.push(this[a.idMaquina]);
        }
        this[a.idMaquina].tEjecucionesJustificadas += a.tEjecucionesJustificadas;
        this[a.idMaquina].tEjecucionesNoJustificadas += a.tEjecucionesNoJustificadas;
        this[a.idMaquina].tParadasJustificadas += a.tParadasJustificadas;
        this[a.idMaquina].tParadasNoJustificadas += a.tParadasNoJustificadas;
        this[a.idMaquina].tMantenimientos += a.tMantenimientos;
        this[a.idMaquina].tiempoTotal += a.tEjecucionesJustificadas + a.tEjecucionesNoJustificadas + a.tParadasJustificadas + a.tParadasNoJustificadas + a.tMantenimientos;
        this[a.idMaquina].fechaTurno = a.fechaTurno;
      }
    }, Object.create(null));

    if (porDia) {
      // ordenar por maximo
      var dataLag = groupByPorMaquina.sort((a, b) => {
        return (b.tiempoTotal - a.tiempoTotal)
      });

      // quedarnos solo con el primer valor de cada fecha
      var fechas = [];
      dataLag.forEach(element => {
        if (!fechas.includes(element.fechaTurno)) {
          dataTopN.push(element);
          fechas.push(element.fechaTurno);
        }
      });
      dataTopN = dataTopN.slice(0, this.topN);
    } else {
      dataTopN = groupByPorMaquina.sort((a, b) => {
        return (b.tiempoTotal - a.tiempoTotal)
      }).slice(0, this.topN);
    }

    // preparar la informacion 
    var cOperariosMaquinas: any = [["tiempo"]];
    var nOperariosMaquinas = {
      "tiempo": this.translate.instant("tiempoTotal")
    };
    var xOperarios = ['x']; // nombres del eje x
    var i = 0; // esto se utiliza para que el grafico no agrupe la informacion
    var len = dataTopN.length;
    dataTopN.forEach(element => {
      xOperarios.push(this.myFunctions.stringMaxLength(element.nombreMaquina, this.lenMax[len]) + ";and;" + element.fechaTurno + ";and;" + i);
      cOperariosMaquinas[0].push(element.tiempoTotal);
      i++;
    });

    // cargar los datos en grafico operarios
    this.dataOperarios = { xLabel: xOperarios, columns: cOperariosMaquinas };
    this.chartOperarios.load({
      unload: true,
      x: 'x',
      columns: [xOperarios, ...cOperariosMaquinas],
      names: nOperariosMaquinas,
      groups: [["tiempo"]],
      colors: {
        'tiempo': '#22C4C4'
      }
    });
  }

  procesarMaquinas(filtroGrafico = false, porDia = false) {

    var dataLag; var dataTopN = [];
    if (!filtroGrafico)
      // obtener topN de ejecuciones sin justificar y paradas sin justificar
      dataLag = this.dataGraficoFiltrada.sort((a, b) => {
        return (b.max - a.max)
      })
    else {
      dataLag = this.dataGraficoFiltrada.filter(f => !this.dataIdSelectedGraficoMaquinas.includes(f.idProcesos_Tipo));
      dataLag = dataLag.sort((a, b) => {
        return (b.max - a.max)
      })
    }

    if (porDia) {
      // quedarnos solo con el primer valor de cada fecha
      var fechas = [];
      dataLag.forEach(element => {
        if (!fechas.includes(element.fechaTurno)) {
          dataTopN.push(element);
          fechas.push(element.fechaTurno);
        }
      });
      dataTopN = dataTopN.slice(0, this.topN);
    } else {
      dataTopN = dataLag.slice(0, this.topN);;
    }
    this.dataMaquinasInf = this.myFunctions.copy(dataTopN);

    // preparar la informacion 
    var cOperariosMaquinas: any = [["ejecucion"], ["parada"]];
    var nOperariosMaquinas = {
      "ejecucion": this.translate.instant("ejecucion"),
      "parada": this.translate.instant("parada")
    };
    var xMaquinas = ['x']; // nombres del eje x
    var i = 0; // esto se utiliza para que el grafico no agrupe la informacion
    var len = dataTopN.length;
    dataTopN.forEach(element => {
      xMaquinas.push(this.myFunctions.stringMaxLength(element.nombreMaquina, this.lenMax[len]) + ";and;" + element.fechaTurno + ";and;" + i);

      cOperariosMaquinas[0].push(0); cOperariosMaquinas[1].push(0)
      if (element.idProcesos_Tipo == 1)
        cOperariosMaquinas[0][i + 1] = element.max;
      else if (element.idProcesos_Tipo == 2)
        cOperariosMaquinas[1][i + 1] = element.max;

      i++;
    });

    // cargar los datos en grafico maquinas
    this.dataMaquinas = { xLabel: xMaquinas, columns: cOperariosMaquinas };
    this.chartMaquinas.load({
      unload: true,
      x: 'x',
      type: 'bar',
      columns: [xMaquinas, ...cOperariosMaquinas],
      names: nOperariosMaquinas,
      groups: [["ejecucion", "parada"]],
      colors: {
        'ejecucion': '#22C4C4',
        'parada': '#E7CB68'
      }
    });

  }

  cargarGraficoBarrasPorDia() {

    if (this.btnPorDiaOperarioSelected)
      this.procesarOperarios(true);
    if (this.btnPorDiaMaquinaSelected)
      this.procesarMaquinas(false, true);

  }

  cargarGraficoBarrasPorDiaClick(opSeleccionado = false, maqSeleccionado = false, isClickOperario = false, isClickMaquinas = false) {

    if (opSeleccionado && isClickOperario)
      this.procesarOperarios(true);
    else if (isClickOperario)
      this.procesarOperarios()
    else if (maqSeleccionado && isClickMaquinas)
      this.procesarMaquinas(false, true);
    else if (isClickMaquinas)
      this.procesarMaquinas()

  }

  dibujarGraficoOperarios() {
    var that = this;
    this.chartOperarios = c3.generate({
      bindto: '#graficoOperarios_controlAsignaciones',
      data: {
        x: 'x',
        columns: [['x']],
        type: 'bar',
        groups: [["ejecucion", "parada", "apagada"]],
        colors: {
          'ejecucion': '#22C4C4',
          'parada': '#E7CB68',
          'apagada': '#404040'
        },
        selection: {
          draggable: false
        }
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            centered: true,
            format: function (d) {
              var value = that.dataOperarios?.xLabel[d + 1];
              if (value != undefined) {
                var operario = value.toString().split(';and;')[0];
                var fecha = new Date(value.toString().split(';and;')[1]);
                var resultFecha = that.myFunctions.addZero(fecha.getDate()) + "/" + that.myFunctions.addZero(fecha.getMonth() + 1) + "/" + that.myFunctions.addZero(fecha.getFullYear());
                var result;
                if (that.btnPorDiaOperarioSelected)
                  result = operario + ' ' + resultFecha;
                else
                  result = operario;
                return result;
              }
            }
          }
        },
        y: {
          tick: {
            count: 7,
            format: function (d) {
              return that.myFunctions.secondsTo_HH_MM(d);
            }
          }
        }
      },
      legend: {
        show: true
      },
      tooltip: {
        format: {
          value: function (value, ratio, id) {
            return that.myFunctions.secondsTo_HH_MM(value);
          }
        }
      },
      padding: {
        bottom: 20 //adjust chart padding bottom
      }
    });
  }

  dibujarGraficoMaquinas() {
    var that = this;
    this.chartMaquinas = c3.generate({
      bindto: '#graficoMaquinas_controlAsignaciones',
      data: {
        x: 'x',
        columns: [['x']],
        type: 'bar',
        groups: [["ejecucion", "parada"]],
        colors: {
          'ejecucion': '#22C4C4',
          'parada': '#E7CB68'
        },
        onclick: function (a, b) {
          var id = that.dataMaquinasInf[a.index]?.idMaquina;
          var fecha = new Date(that.dataMaquinasInf[a.index]?.fechaTurno);
          const url = that.router.serializeUrl(that.router.parseUrl('#/bonos/' + id + '/' + that.myFunctions.dateToYYYY_MM_DD(fecha) + '/' + that.myFunctions.dateToYYYY_MM_DD(fecha)));
          window.open(url, '_blank');
        }
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            centered: true,
            format: function (d) {
              var value = that.dataMaquinas?.xLabel[d + 1];
              if (value != undefined) {
                var maquina = value.toString().split(';and;')[0];
                var fecha = new Date(value.toString().split(';and;')[1]);
                var resultFecha = that.myFunctions.addZero(fecha.getDate()) + "/" + that.myFunctions.addZero(fecha.getMonth() + 1) + "/" + that.myFunctions.addZero(fecha.getFullYear());
                var result = maquina + ' ' + resultFecha;
                return result;
              }
            }
          }
        },
        y: {
          tick: {
            count: 7,
            format: function (d) {
              return that.myFunctions.secondsTo_HH_MM(d);
            }
          }
        }
      },
      legend: {
        show: true,
        item: {
          onclick: function (d) {
            if (!that.dataSelectedGraficoMaquinas.includes(d)) {
              that.dataSelectedGraficoMaquinas.push(d);
              if (d == 'parada')
                that.dataIdSelectedGraficoMaquinas.push(2);
              else
                that.dataIdSelectedGraficoMaquinas.push(1);

              that.procesarMaquinas(true);
              setTimeout(function () {
                that.chartMaquinas.hide(d);
              }, 500);
            } else {
              that.dataSelectedGraficoMaquinas = that.dataSelectedGraficoMaquinas.filter(f => f != d);
              if (d == 'parada')
                that.dataIdSelectedGraficoMaquinas = that.dataIdSelectedGraficoMaquinas.filter(f => f != 2);
              else
                that.dataIdSelectedGraficoMaquinas = that.dataIdSelectedGraficoMaquinas.filter(f => f != 1);

              that.procesarMaquinas(true);
              setTimeout(function () {
                that.chartMaquinas.show(d);
              }, 500);
            }
          }
        },
      },
      tooltip: {
        format: {
          value: function (value, ratio, id) {
            return that.myFunctions.secondsTo_HH_MM(value);
          }
        }
      },
      padding: {
        bottom: 25 //adjust chart padding bottom
      },
      onItemDropped: (e, semana) => {
        var id = that.dataMaquinasInf[e.subject.index]?.idMaquina;
        var fecha = new Date(that.dataMaquinasInf[e.subject.index]?.fechaTurno);
        const url = that.router.serializeUrl(that.router.parseUrl('#/bonos/' + id + '/' + that.myFunctions.dateToYYYY_MM_DD(fecha) + '/' + that.myFunctions.dateToYYYY_MM_DD(fecha)));
        window.open(url, '_blank');
      }
    });
  }

  btnPorDiaOperario() {
    // this.btnPorDiaOperarioSelected = !this.btnPorDiaOperarioSelected;
    if (!this.btnPorDiaOperarioSelected)
      this.cargarGraficoBarrasPorDiaClick(false, false, true);
    else
      this.cargarGraficoBarrasPorDiaClick(true, false, true);

  }

  btnPorDiaMaquina() {
    // this.btnPorDiaMaquinaSelected = !this.btnPorDiaMaquinaSelected;
    if (!this.btnPorDiaMaquinaSelected)
      this.cargarGraficoBarrasPorDiaClick(false, false, false, true);
    else
      this.cargarGraficoBarrasPorDiaClick(false, true, false, true);
  }


  public showGridTooltip1(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && (element.offsetWidth < element.scrollWidth || element.classList.contains('columna-justificada') || element.classList.contains('columna-no-justificada'))) {

      this.tooltipDir.toggle(element);

    } else {
      this.tooltipDir.hide();
    }
  }

  public showGridTooltip2(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  // DOBLE CALENDARIO
  //    Función para que el calendario se muestre o no
  showCalendar() {
    if (!this.mostrarCalendario) {
      this.mostrarCalendario = true;
      this.eligiendoFechas = true;
    }
    else {
      this.mostrarCalendario = false;
      this.eligiendoFechas = false;
      if (this.fechaini != undefined && this.fechafin == undefined) {
        this.fechafin = this.fechaini;
      }
      else if (this.fechaini == undefined && this.fechafin == undefined) {
        this.fechaini = this.myFunctions.getDateNow();
        this.fechafin = this.fechaini;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }

  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.fechaini != undefined && this.fechafin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.fechafin = this.DaysInMonths[month];
        this.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
        this.eligiendoFechas = false;
      } else {
        if (this.fechaini == undefined && this.fechafin == undefined) {
          this.fechaini = this.DaysInMonths[month];
        }
        else if (this.fechaini != undefined && this.fechafin == undefined) {
          this.fechafin = this.DaysInMonths[month];
          if (this.fechaini > this.fechafin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.fechaini.getTime());
            this.fechaini = new Date(this.fechafin.getTime());
            this.fechafin = aux;
          }
          this.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          this.eligiendoFechas = false;
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.fechaini = this.DaysInMonths[month];
          this.fechafin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.fechaini == undefined && this.fechafin == undefined) {
      return false;
    } else if (this.fechaini != undefined && this.fechafin == undefined) {
      return date.getFullYear() == this.fechaini.getFullYear() && date.getMonth() == this.fechaini.getMonth() && date.getDate() == this.fechaini.getDate();
    } else if (this.fechaini != undefined && this.fechafin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.fechaini.getFullYear(), this.fechaini.getMonth(), this.fechaini.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.fechafin.getFullYear(), this.fechafin.getMonth(), this.fechafin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton() {
    var today = this.myFunctions.getDateNow();
    this.fechaini = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    this.fechafin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton() {
    this.fechafin = this.myFunctions.getDateNow();
    this.fechaini = new Date(this.fechafin.getFullYear(), this.fechafin.getMonth(), this.fechafin.getDate() - 7);
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton() {
    this.fechafin = this.myFunctions.getDateNow();
    this.fechaini = new Date(this.fechafin.getFullYear(), this.fechafin.getMonth() - 1, this.fechafin.getDate());
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton() {
    this.fechafin = this.myFunctions.getDateNow();
    this.fechaini = new Date(this.fechafin.getFullYear(), this.fechafin.getMonth() - 2, this.fechafin.getDate());
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos90DiasButton() {
    this.fechafin = this.myFunctions.getDateNow();
    this.fechaini = new Date(this.fechafin.getFullYear(), this.fechafin.getMonth() - 3, this.fechafin.getDate());
    this.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    this.eligiendoFechas = false;
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  // END DOBLE CALENDARIO


}
