import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosTiposService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
const is = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);
import { GridDataResult, DataStateChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, DataResult } from '@progress/kendo-data-query';

@Component({
  selector: 'app-editar-crear-usuario-tipo',
  templateUrl: './editar-crear-usuario-tipo.component.html'
})
export class EditarCrearUsuarioTipoComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  permisos = {
    'home': 0,
    'gestionUsuarios':0,
    'proyectos': 0,
    'validador': 0,
    'proyectosH': 0,
    'operacionesPredefinidas': 0,
    'piezasPredefinidas': 0,
    'seguimiento': 0,
    'controlDeAsignaciones': 0,
    'historicoPiezas': 0,
    'planificador': 0,
    'vistoLargoMaquina': 0,
    'vistoLargoTodos': 0,
    'planificadorCorto': 0,
    'informes': 0,
    'analisisDeRendimiento': 0,
    'comparativaRendimiento': 0,
    'informeEjecuciones': 0,
    'informeTecnicoEjecucion': 0,
    'informeDeConsumo': 0,
    'oEE': 0,
    'informeOEE': 0,
    'informeDisponibilidad': 0,
    'informeRendimiento': 0,
    'informeCalidad': 0,
    'planesDeAccion': 0,
    'planesDeAccionH': 0,
    'maquinas': 0,
    'perdidas': 0,
    'disponibilidad': 0,
    'informesIncidencias': 0,
    'calendarioDisponibilidad': 0,
    'informeCalculoDisponibilidad': 0,
    'ficheros': 0,
    'administracion': 0,
    'configuracion': 0,
    'activos': 0,
    'activosMaestro': 0,
    'alarmasTipo': 0,
    'almacenes': 0,
    'analiticaAvanzadaEjecuciones': 0,
    'areaProductiva': 0,
    'asignacionTiempos': 0,
    'bonos': 0,
    'clientes': 0,
    'comparativaTiempos': 0,
    'consumibles': 0,
    'controles': 0,
    'diccionarioPerdidas': 0,
    'turnos': 0,
    'fabricantes': 0,
    'herramientas': 0,
    'placas': 0,
    'historicoMantenimientos': 0,
    'historicoOperaciones': 0,
    'historicoProcesos': 0,
    'historicoParametros': 0,
    'instalaciones': 0,
    'mantenimientosPredefinidos': 0,
    'marcas': 0,
    'materiales': 0,
    'operaciones': 0,
    'operacionesTipo': 0,
    'piezas': 0,
    'piezasTipo': 0,
    'planificadorHerramientas': 0,
    'planificadorLargo': 0,
    'planificadorLista': 0,
    'plantasIsometrico': 0,
    'profiles': 0,
    'proveedores': 0,
    'operacionesFinalizadas': 0,
    'rutas': 0,
    'rutasPredefinidas': 0,
    'secciones': 0,
    'stock': 0,
    'subcontratado': 0,
    'activosTipos': 0,
    'tolerancias': 0,
    'recetas': 0,
    'usuarios': 0,
    'calidad': 0,
  }
  //Estructura de arbol, si se introduce un nuevo permiso, hay que actualizar tambien la funcion refreshTree.
  //hauek falta dira:       app.component-en komentatuta daude.
      // ,[activos]
      // ,[activosMaestro]
      // ,[activosTipos]
      // ,[almacenes]
      // ,[comparativaTiempos]
      // ,[consumibles]
      // ,[fabricantes]
      // ,[herramientas]
      // ,[placas]
      // ,[historicoOperaciones]
      // ,[historicoProcesos]
      // ,[historicoParametros]
      // ,[operaciones]
      // ,[piezas]

      // ,[proveedores]
      // ,[OperacionesFinalizadas]
      // ,[rutas]
      // ,[rutasPredefinidas]
      // ,[secciones]
      // ,[stock]
      // ,[recetas]

  public data: any[] = [
    {
      text: this.translateService.instant("home"), id: this.permisos['home'], nombre: 'home',
    },
    {
      text: this.translateService.instant("proyectos"), id: this.permisos['proyectos'], nombre:'proyectos',
        items: [
          { text: this.translateService.instant("proyectos"), id: this.permisos['proyectosH'], nombre: 'proyectosH' },
          { text: this.translateService.instant("operacionespredefinidas"), id: this.permisos['operacionesPredefinidas'], nombre: 'operacionesPredefinidas' },
          { text: this.translateService.instant("piezaspredefinidas"), id: this.permisos['piezasPredefinidas'], nombre: 'piezasPredefinidas' },
          { text: this.translateService.instant("asignacionTiempos"), id: this.permisos['asignacionTiempos'], nombre: 'asignacionTiempos' }
      ]
    },
    
    {
      text: this.translateService.instant("seguimiento"), id: this.permisos['seguimiento'], nombre: 'seguimiento',
        items: [
          { text: this.translateService.instant("validador"), id: this.permisos['validador'], nombre: 'validador'},
          { text: this.translateService.instant("bonos"), id: this.permisos['bonos'], nombre: 'bonos'},
          { text: this.translateService.instant("controlAsignaciones"), id: this.permisos['controlDeAsignaciones'], nombre: 'controlDeAsignaciones' },
          { text: this.translateService.instant("historicoPiezas"), id: this.permisos['historicoPiezas'], nombre: 'historicoPiezas' }
      ]
    },
    {
      text: this.translateService.instant("planificador"), id: this.permisos['planificador'], nombre: 'planificador', items: [
        { text: this.translateService.instant("largoMaquina"), id: this.permisos['vistoLargoMaquina'], nombre: 'vistoLargoMaquina' },
        { text: this.translateService.instant("largoMaquinas"), id: this.permisos['vistoLargoTodos'], nombre: 'vistoLargoTodos' },
        { text: this.translateService.instant("largo"), id: this.permisos['planificadorLargo'], nombre: 'planificadorLargo' },
        { text: this.translateService.instant("corto"), id: this.permisos['planificadorCorto'], nombre: 'planificadorCorto' },
        { text: this.translateService.instant("Lista"), id: this.permisos['planificadorLista'], nombre: 'planificadorLista' },
        { text: this.translateService.instant("Herramientas"), id: this.permisos['planificadorHerramientas'], nombre: 'planificadorHerramientas' }
    ]
    },
    {
      text: this.translateService.instant("informes"), id: this.permisos['informes'], nombre: 'informes', items: [
        { text: this.translateService.instant("analisisRendimiento"), id: this.permisos['analisisDeRendimiento'], nombre: 'analisisDeRendimiento' },
        { text: this.translateService.instant("comparativaRendimiento"), id: this.permisos['comparativaRendimiento'], nombre: 'comparativaRendimiento' },
        { text: this.translateService.instant("informeEjecuciones"), id: this.permisos['informeEjecuciones'], nombre: 'informeEjecuciones' },
        { text: this.translateService.instant("informetecnicoejecucion"), id: this.permisos['informeTecnicoEjecucion'], nombre: 'informeTecnicoEjecucion' },
        { text: this.translateService.instant("informeConsumo"), id: this.permisos['informeDeConsumo'], nombre: 'informeDeConsumo' },
        { text: this.translateService.instant("informeProyectos"), id: this.permisos['informes'], nombre: 'informes' },
        { text: this.translateService.instant("analiticaAvanzadaEjecuciones"), id: this.permisos['analiticaAvanzadaEjecuciones'], nombre: 'analiticaAvanzadaEjecuciones' },
        { text: this.translateService.instant("informeComparativaProyectos"), id: this.permisos['informes'], nombre: 'informes' },
      ]
    },
    {
      text: this.translateService.instant("oee"), id: this.permisos['oEE'], nombre: 'oEE', items: [
        { text: this.translateService.instant("tituloOee"), id: this.permisos['informeOEE'], nombre: 'informeOEE' },
        { text: this.translateService.instant("tituloDisponibilidad"), id: this.permisos['informeDisponibilidad'], nombre: 'informeDisponibilidad' },
        { text: this.translateService.instant("tituloRendimiento"), id: this.permisos['informeRendimiento'], nombre: 'informeRendimiento' },
        { text: this.translateService.instant("tituloCalidad"), id: this.permisos['informeCalidad'], nombre: 'informeCalidad' }
      ]
    },
    {
      text: this.translateService.instant("planesDeAccion"), id: this.permisos['planesDeAccion'], nombre: 'planesDeAccion', items: [
        { text: this.translateService.instant("planesDeAccion"), id: this.permisos['planesDeAccionH'], nombre: 'planesDeAccionH' },
        { text: this.translateService.instant("maquinas"), id: this.permisos['maquinas'], nombre: 'maquinas' },
        { text: this.translateService.instant("perdidas"), id: this.permisos['perdidas'], nombre: 'perdidas'}
      ]
    },
    {
      text: this.translateService.instant("disponibilidad"), id: this.permisos['disponibilidad'], nombre: 'disponibilidad', items: [
        { text: this.translateService.instant("informesIncidencias"), id: this.permisos['informesIncidencias'], nombre: 'informesIncidencias' },
        { text: this.translateService.instant("calendarioDisponibilidad"), id: this.permisos['calendarioDisponibilidad'], nombre: 'calendarioDisponibilidad' },
        { text: this.translateService.instant("informeCalculoDisponibilidad"), id: this.permisos['informeCalculoDisponibilidad'], nombre: 'informeCalculoDisponibilidad'}
      ]
    },
    {
      text: this.translateService.instant("mantenimiento"), id: this.permisos['mantenimientosPredefinidos'], nombre: 'mantenimientosPredefinidos', items: [
        { text: this.translateService.instant("historicomantenimientos"), id: this.permisos['historicoMantenimientos'], nombre: 'historicoMantenimientos' }
      ]
    },
    {
      text: this.translateService.instant("configuracion"), id: this.permisos['configuracion'], nombre: 'configuracion', items: [
        { text: this.translateService.instant("ficheros"), id: this.permisos['ficheros'], nombre: 'ficheros', items:[
          { text: this.translateService.instant("disenadorPlanta"), id: this.permisos['plantasIsometrico'], nombre: 'plantasIsometrico'},
          { text: this.translateService.instant("diccionarioPerdidas"), id: this.permisos['diccionarioPerdidas'], nombre: 'diccionarioPerdidas'},
          { text: this.translateService.instant("perdidasGrupo"), id: this.permisos['diccionarioPerdidas'], nombre: 'diccionarioPerdidas'},
          { text: this.translateService.instant("subcontratado"), id: this.permisos['subcontratado'], nombre: 'subcontratado'},
          { text: this.translateService.instant("instalaciones"), id: this.permisos['instalaciones'], nombre: 'instalaciones'},
          { text: this.translateService.instant("controles"), id: this.permisos['controles'], nombre: 'controles'},
          { text: this.translateService.instant("mantenimientospredefinidos"), id: this.permisos['mantenimientosPredefinidos'], nombre: 'mantenimientosPredefinidos'},
          { text: this.translateService.instant("clientes"), id: this.permisos['clientes'], nombre: 'clientes'},
          { text: this.translateService.instant("turnos"), id: this.permisos['turnos'], nombre: 'turnos'},
          { text: this.translateService.instant("marcas"), id: this.permisos['marcas'], nombre: 'marcas'},
          { text: this.translateService.instant("materialestipo"), id: this.permisos['materiales'], nombre: 'materiales'},
          { text: this.translateService.instant("alarmastipo"), id: this.permisos['alarmasTipo'], nombre: 'alarmasTipo'},
          { text: this.translateService.instant("piezastipo"), id: this.permisos['piezasTipo'], nombre: 'piezasTipo'},
          { text: this.translateService.instant("operacionestipo"), id: this.permisos['operacionesTipo'], nombre: 'operacionesTipo'},
          { text: this.translateService.instant("tipoTolerancia"), id: this.permisos['tolerancias'], nombre: 'tolerancias'},
          { text: this.translateService.instant("areasProductivas"), id: this.permisos['areaProductiva'], nombre: 'areaProductiva'},
          //{ text: this.translateService.instant("calidad"), id: this.permisos['calidad'], nombre: 'calidad'},

                ]},
        { text: this.translateService.instant("administracion"), id: this.permisos['administracion'], nombre: 'administracion',
            items: [
              {text: this.translateService.instant("gestionusuarios"), id: this.permisos['gestionUsuarios'], nombre: 'gestionUsuarios'},
              {text: this.translateService.instant("tiposusuario"), id: this.permisos['usuarios'], nombre: 'usuarios'},
              ]},
        { text: this.translateService.instant("configuracion"), id: this.permisos['configuracion'], nombre: 'configuracion' }

      ]
     
    },
    { text: this.translateService.instant("calidad"), id: this.permisos['calidad'], nombre: 'calidad' }
  ];


  public iconClass({ text, items, id }: any): any {
   
    return {
      'k-i-lock': id =='0',
      'k-i-eye': id=='1',
      'k-i-pencil': id == '2',
      'k-icon': true
    };
  }
  user = this.usuariosService.userValue;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private usuariosTiposService: UsuariosTiposService,
    private alertService: AlertService,
    private usuariosService: UsuariosService,
    private menuService: MenuService
  ) { 
    
  }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('tipousuario');
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      nombre: new FormControl('', [Validators.required]),
      gestionUsuarios: new FormControl(0, [Validators.required])
    });

    if (!this.isAddMode) {
      /*this.usuariosTiposService.getById(this.id)
        .pipe(first())
        .subscribe(x => this.form.patchValue(x));*/
      this.usuariosTiposService.getById(this.id).subscribe((result) => {
       
        this.form = this.formBuilder.group({

          id: this.route.snapshot.params['id'],
          nombre: new FormControl(result.tipousuario[0].nombre, [Validators.required]),         
        });
        this.permisos = {
          'home': result.tipousuario[0].home,
          'gestionUsuarios': result.tipousuario[0].gestionUsuarios,
          'proyectos': result.tipousuario[0].proyectos,
          'validador': result.tipousuario[0].validador,
          'proyectosH': result.tipousuario[0].proyectosH,
          'operacionesPredefinidas': result.tipousuario[0].operacionesPredefinidas,
          'piezasPredefinidas': result.tipousuario[0].piezasPredefinidas,
          'seguimiento': result.tipousuario[0].seguimiento,
          'controlDeAsignaciones': result.tipousuario[0].controlDeAsignaciones,
          'historicoPiezas': result.tipousuario[0].historicoPiezas,
          'planificador': result.tipousuario[0].planificador,
          'vistoLargoMaquina': result.tipousuario[0].vistoLargoMaquina,
          'vistoLargoTodos': result.tipousuario[0].vistoLargoTodos,
          'planificadorCorto': result.tipousuario[0].planificadorCorto,
          'informes': result.tipousuario[0].informes,
          'analisisDeRendimiento': result.tipousuario[0].analisisDeRendimiento,
          'comparativaRendimiento': result.tipousuario[0].comparativaRendimiento,
          'informeEjecuciones': result.tipousuario[0].informeEjecuciones,
          'informeTecnicoEjecucion': result.tipousuario[0].informeTecnicoEjecucion,
          'informeDeConsumo': result.tipousuario[0].informeDeConsumo,
          'oEE': result.tipousuario[0].oEE,
          'informeOEE': result.tipousuario[0].informeOEE,
          'informeDisponibilidad': result.tipousuario[0].informeDisponibilidad,
          'informeRendimiento': result.tipousuario[0].informeRendimiento,
          'informeCalidad': result.tipousuario[0].informeCalidad,
          'planesDeAccion': result.tipousuario[0].planesDeAccion,
          'planesDeAccionH': result.tipousuario[0].planesDeAccionH,
          'maquinas': result.tipousuario[0].maquinas,
          'perdidas': result.tipousuario[0].perdidas,
          'disponibilidad': result.tipousuario[0].disponibilidad,
          'informesIncidencias': result.tipousuario[0].informesIncidencias,
          'calendarioDisponibilidad': result.tipousuario[0].calendarioDisponibilidad,
          'informeCalculoDisponibilidad': result.tipousuario[0].informeCalculoDisponibilidad,
          'ficheros': result.tipousuario[0].ficheros,
          'administracion': result.tipousuario[0].administracion,
          'configuracion': result.tipousuario[0].configuracion,
          'activos': result.tipousuario[0].activos,
          'activosMaestro': result.tipousuario[0].activosMaestro,
          'alarmasTipo': result.tipousuario[0].alarmasTipo,
          'almacenes': result.tipousuario[0].almacenes,
          'analiticaAvanzadaEjecuciones': result.tipousuario[0].analiticaAvanzadaEjecuciones,
          'areaProductiva': result.tipousuario[0].areaProductiva,
          'asignacionTiempos': result.tipousuario[0].asignacionTiempos,
          'bonos': result.tipousuario[0].bonos,
          'clientes': result.tipousuario[0].clientes,
          'comparativaTiempos': result.tipousuario[0].comparativaTiempos,
          'consumibles': result.tipousuario[0].consumibles,
          'controles': result.tipousuario[0].controles,
          'diccionarioPerdidas': result.tipousuario[0].diccionarioPerdidas,
          'turnos': result.tipousuario[0].turnos,
          'fabricantes': result.tipousuario[0].fabricantes,
          'herramientas': result.tipousuario[0].herramientas,
          'placas': result.tipousuario[0].placas,
          'historicoMantenimientos': result.tipousuario[0].historicoMantenimientos,
          'historicoOperaciones': result.tipousuario[0].historicoOperaciones,
          'historicoProcesos': result.tipousuario[0].historicoProcesos,
          'historicoParametros': result.tipousuario[0].historicoParametros,
          'instalaciones': result.tipousuario[0].instalaciones,
          'mantenimientosPredefinidos': result.tipousuario[0].mantenimientosPredefinidos,
          'marcas': result.tipousuario[0].marcas,
          'materiales': result.tipousuario[0].materiales,
          'operaciones': result.tipousuario[0].operaciones,
          'operacionesTipo': result.tipousuario[0].operacionesTipo,
          'piezas': result.tipousuario[0].piezas,
          'piezasTipo': result.tipousuario[0].piezasTipo,
          'planificadorHerramientas': result.tipousuario[0].planificadorHerramientas,
          'planificadorLargo': result.tipousuario[0].planificadorLargo,
          'planificadorLista': result.tipousuario[0].planificadorLista,
          'plantasIsometrico': result.tipousuario[0].plantasIsometrico,
          'profiles': result.tipousuario[0].profiles,
          'proveedores': result.tipousuario[0].proveedores,
          'operacionesFinalizadas': result.tipousuario[0].operacionesFinalizadas,
          'rutas': result.tipousuario[0].rutas,
          'rutasPredefinidas': result.tipousuario[0].rutasPredefinidas,
          'secciones': result.tipousuario[0].secciones,
          'stock': result.tipousuario[0].stock,
          'subcontratado': result.tipousuario[0].subcontratado,
          'activosTipos': result.tipousuario[0].activosTipos,
          'tolerancias': result.tipousuario[0].tolerancias,
          'recetas': result.tipousuario[0].recetas,
          'usuarios': result.tipousuario[0].usuarios,
          'calidad': result.tipousuario[0].calidad,

        }
        console.log(this.permisos);
        this.refreshTree();
      });
      
    }
  
  }

  public refreshTree() {
    this.data = [
      {
        text: this.translateService.instant("home"), id: this.permisos['home'], nombre: 'home',
      },
      {
        text: this.translateService.instant("proyectos"), id: this.permisos['proyectos'], nombre: 'proyectos',
        items: [
          { text: this.translateService.instant("proyectos"), id: this.permisos['proyectosH'], nombre: 'proyectosH' },
          { text: this.translateService.instant("operacionespredefinidas"), id: this.permisos['operacionesPredefinidas'], nombre: 'operacionesPredefinidas' },
          { text: this.translateService.instant("piezaspredefinidas"), id: this.permisos['piezasPredefinidas'], nombre: 'piezasPredefinidas' },
          { text: this.translateService.instant("asignacionTiempos"), id: this.permisos['asignacionTiempos'], nombre: 'asignacionTiempos' }

        ]
      },

      {
        text: this.translateService.instant("seguimiento"), id: this.permisos['seguimiento'], nombre: 'seguimiento',
        items: [
          { text: this.translateService.instant("validador"), id: this.permisos['validador'], nombre: 'validador' },
          { text: this.translateService.instant("bonos"), id: this.permisos['bonos'], nombre: 'bonos'},
          { text: this.translateService.instant("controlAsignaciones"), id: this.permisos['controlDeAsignaciones'], nombre: 'controlDeAsignaciones' },
          { text: this.translateService.instant("historicoPiezas"), id: this.permisos['historicoPiezas'], nombre: 'historicoPiezas' }
        ]
      },
      {
        text: this.translateService.instant("planificador"), id: this.permisos['planificador'], nombre: 'planificador', items: [
          { text: this.translateService.instant("largoMaquina"), id: this.permisos['vistoLargoMaquina'], nombre: 'vistoLargoMaquina' },
          { text: this.translateService.instant("largoMaquinas"), id: this.permisos['vistoLargoTodos'], nombre: 'vistoLargoTodos' },
          { text: this.translateService.instant("largo"), id: this.permisos['planificadorLargo'], nombre: 'planificadorLargo' },
          { text: this.translateService.instant("corto"), id: this.permisos['planificadorCorto'], nombre: 'planificadorCorto' },
          { text: this.translateService.instant("Lista"), id: this.permisos['planificadorLista'], nombre: 'planificadorLista' },
          { text: this.translateService.instant("Herramientas"), id: this.permisos['planificadorHerramientas'], nombre: 'planificadorHerramientas' }
        ]
      },
      {
        text: this.translateService.instant("informes"), id: this.permisos['informes'], nombre: 'informes', items: [
          { text: this.translateService.instant("analisisRendimiento"), id: this.permisos['analisisDeRendimiento'], nombre: 'analisisDeRendimiento' },
          { text: this.translateService.instant("comparativaRendimiento"), id: this.permisos['comparativaRendimiento'], nombre: 'comparativaRendimiento' },
          { text: this.translateService.instant("informeEjecuciones"), id: this.permisos['informeEjecuciones'], nombre: 'informeEjecuciones' },
          { text: this.translateService.instant("informetecnicoejecucion"), id: this.permisos['informeTecnicoEjecucion'], nombre: 'informeTecnicoEjecucion' },
          { text: this.translateService.instant("informeConsumo"), id: this.permisos['informeDeConsumo'], nombre: 'informeDeConsumo' },
          { text: this.translateService.instant("informeProyectos"), id: this.permisos['informes'], nombre: 'informes' },
          { text: this.translateService.instant("analiticaAvanzadaEjecuciones"), id: this.permisos['analiticaAvanzadaEjecuciones'], nombre: 'analiticaAvanzadaEjecuciones' },
          { text: this.translateService.instant("informeComparativaProyectos"), id: this.permisos['informes'], nombre: 'informes' },
        ]
      },
      {
        text: this.translateService.instant("oee"), id: this.permisos['oEE'], nombre: 'oEE', items: [
          { text: this.translateService.instant("tituloOee"), id: this.permisos['informeOEE'], nombre: 'informeOEE' },
          { text: this.translateService.instant("tituloDisponibilidad"), id: this.permisos['informeDisponibilidad'], nombre: 'informeDisponibilidad' },
          { text: this.translateService.instant("tituloRendimiento"), id: this.permisos['informeRendimiento'], nombre: 'informeRendimiento' },
          { text: this.translateService.instant("tituloCalidad"), id: this.permisos['informeCalidad'], nombre: 'informeCalidad' }
        ]
      },
      {
        text: this.translateService.instant("planesDeAccion"), id: this.permisos['planesDeAccion'], nombre: 'planesDeAccion', items: [
          { text: this.translateService.instant("planesDeAccion"), id: this.permisos['planesDeAccionH'], nombre: 'planesDeAccionH' },
          { text: this.translateService.instant("maquinas"), id: this.permisos['maquinas'], nombre: 'maquinas' },
          { text: this.translateService.instant("perdidas"), id: this.permisos['perdidas'], nombre: 'perdidas'}
        ]
      },
      {
        text: this.translateService.instant("disponibilidad"), id: this.permisos['disponibilidad'], nombre: 'disponibilidad', items: [
          { text: this.translateService.instant("informesIncidencias"), id: this.permisos['informesIncidencias'], nombre: 'informesIncidencias' },
          { text: this.translateService.instant("calendarioDisponibilidad"), id: this.permisos['calendarioDisponibilidad'], nombre: 'calendarioDisponibilidad' },
          { text: this.translateService.instant("informeCalculoDisponibilidad"), id: this.permisos['informeCalculoDisponibilidad'], nombre: 'informeCalculoDisponibilidad'}
        ]
      },
      {
        text: this.translateService.instant("mantenimiento"), id: this.permisos['mantenimientosPredefinidos'], nombre: 'mantenimientosPredefinidos', items: [
          { text: this.translateService.instant("historicomantenimientos"), id: this.permisos['historicoMantenimientos'], nombre: 'historicoMantenimientos' }
        ]
      },
      {
        text: this.translateService.instant("configuracion"), id: this.permisos['configuracion'], nombre: 'configuracion', 
           items: [
                { text: this.translateService.instant("ficheros"), id: this.permisos['ficheros'], nombre: 'ficheros', items:[
                  { text: this.translateService.instant("disenadorPlanta"), id: this.permisos['plantasIsometrico'], nombre: 'plantasIsometrico'},
                  { text: this.translateService.instant("diccionarioPerdidas"), id: this.permisos['diccionarioPerdidas'], nombre: 'diccionarioPerdidas'},
                  { text: this.translateService.instant("perdidasGrupo"), id: this.permisos['diccionarioPerdidas'], nombre: 'diccionarioPerdidas'},
                  { text: this.translateService.instant("subcontratado"), id: this.permisos['subcontratado'], nombre: 'subcontratado'},
                  { text: this.translateService.instant("instalaciones"), id: this.permisos['instalaciones'], nombre: 'instalaciones'},
                  { text: this.translateService.instant("controles"), id: this.permisos['controles'], nombre: 'controles'},
                  { text: this.translateService.instant("mantenimientospredefinidos"), id: this.permisos['mantenimientosPredefinidos'], nombre: 'mantenimientosPredefinidos'},
                  { text: this.translateService.instant("clientes"), id: this.permisos['clientes'], nombre: 'clientes'},
                  { text: this.translateService.instant("turnos"), id: this.permisos['turnos'], nombre: 'turnos'},
                  { text: this.translateService.instant("marcas"), id: this.permisos['marcas'], nombre: 'marcas'},
                  { text: this.translateService.instant("materialestipo"), id: this.permisos['materiales'], nombre: 'materiales'},
                  { text: this.translateService.instant("alarmastipo"), id: this.permisos['alarmasTipo'], nombre: 'alarmasTipo'},
                  { text: this.translateService.instant("piezastipo"), id: this.permisos['piezasTipo'], nombre: 'piezasTipo'},
                  { text: this.translateService.instant("operacionestipo"), id: this.permisos['operacionesTipo'], nombre: 'operacionesTipo'},
                  { text: this.translateService.instant("tipoTolerancia"), id: this.permisos['tolerancias'], nombre: 'tolerancias'},
                  { text: this.translateService.instant("areasProductivas"), id: this.permisos['areaProductiva'], nombre: 'areaProductiva'},
                        ]},
          { text: this.translateService.instant("administracion"), id: this.permisos['administracion'], nombre: 'administracion',
            items: [
                    {text: this.translateService.instant("gestionusuarios"), id: this.permisos['gestionUsuarios'], nombre: 'gestionUsuarios'},
                    {text: this.translateService.instant("tiposusuario"), id: this.permisos['usuarios'], nombre: 'usuarios'},
                    ]},
          { text: this.translateService.instant("configuracion"), id: this.permisos['configuracion'], nombre: 'configuracion' }
          
        ]
      },
      { text: this.translateService.instant("calidad"), id: this.permisos['calidad'], nombre: 'calidad' }
    ];
    this.data.slice();
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearTipoUsuario();
    } else {
      this.updateTipoUsuario();
    }

  }

  private crearTipoUsuario() {
    this.usuariosTiposService.create(this.form.value, this.permisos)
      .pipe(first())
      .subscribe({
        next: () => {

          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });

        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateTipoUsuario() {
    this.usuariosTiposService.update(this.form.value, this.permisos)
        .pipe(first())
        .subscribe({
            next: () => {
                this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
                this.router.navigate(['../../'], { relativeTo: this.route });
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });
  }

  public onNodeClick(e: any): void {
    var nombre = e.item.dataItem.nombre;
    var id = e.item.dataItem.id;
    if (id == 2) {
      e.item.dataItem.id = 0;
    } else { 
      e.item.dataItem.id = id + 1;
    }
    this.permisos[nombre] = e.item.dataItem.id;
    if (e.item.dataItem.items != undefined) {
      this.actualizaHijos(e.item.dataItem.items, e.item.dataItem.id);
     
    }
  }
  public actualizaHijos(hijos, id) {
    hijos.forEach((element) => {

      var nombre = element.nombre;
      var idHijo = element.id;
      //Descomentar si se requiere que el hijo nunca tenga mas permisos que el padre
      //if (idHijo > id) {
        element.id = id;
        this.permisos[nombre] = id;
     // } 
      if (element.items != undefined) {
        this.actualizaHijos(element.items, id);
      }

    });

  }

}
