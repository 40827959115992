import { Component, Input, ViewChild } from '@angular/core';
import { MaquinasService, MenuService, UsuariosService ,AlertService, ConfiguracionService, SubidaFicherosService } from '@app/_services';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupDescriptor, DataResult, process } from "@progress/kendo-data-query";
import { SelectableSettings } from '@progress/kendo-angular-treelist';


@Component({
  selector: 'app-maquina-secciones-manuales',
  templateUrl: './maquinaDetalleManuales.component.html'
})

export class MaquinaDetalleSeccionesManualesComponent {

  public settings: SelectableSettings = {
    mode: "row",
    multiple: true,
    drag: false,
    enabled: true,
    readonly: false
  };

  @Input() id: string;

  public manuales: any = [];
  public manuales_seleccionados: any[] = [];
  public manuales_modalReference: NgbModalRef;
  public manuales_modalReferenceLoading: NgbModalRef;

  @ViewChild('popupEliminarManual') popupEliminarManual: NgbModalRef;
  @ViewChild('popupEliminarCarpeta') popupEliminarCarpeta: NgbModalRef;

  user = this.userService.userValue;

  public groups: GroupDescriptor[] = [{ field: "carpeta" }];
  // private carpetaPrincipal = { // el que se cree esta carpeta hace que el contenido no sea editable despues
  //   carpeta: null,
  //   esLink: false,
  //   id: 0,
  //   idCarpeta: 0,
  //   idManualCarpeta: "0_0",
  //   idPadre: null,
  //   idTipo: 0,
  //   pdf: "",
  //   texto: "principal",
  //   tipo: ""
  // };
  constructor(
    private maquinasService: MaquinasService,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private configuracionService: ConfiguracionService,
    private subidaFicherosService: SubidaFicherosService) {
    this.menuService.titulo = this.translateService.instant('maquina').toUpperCase();
  }

  ngOnInit() {
    this.maquinasService.GetAll_Manuales(this.id).subscribe((result) => {
      this.manuales = result.data;
      
      // this.manuales.push(this.carpetaPrincipal);
      this.modificarLabelCarpetas(); //karpeten izenei aurretik 'Carpeta:' jarri
      console.table(this.manuales)
    });

  }

  private processData(data: any[]): any[] {
    const map = new Map<number, any>();

    data.forEach(item => {
      map.set(item.id, { ...item, items: [] });
    });

    const roots: any[] = [];

    data.forEach(item => {
      if (item.idPadre === null) {
        roots.push(map.get(item.id));
      } else {
        const parent = map.get(item.idPadre);
        if (parent) {
          parent.items.push(map.get(item.id));
        }
      }
    });

    return roots;
  }

  modificarLabelCarpetas() {
    if (this.id != undefined) {
      this.manuales.forEach(manual => {
        
        if (manual.idPadre == null && !manual.texto.includes(this.translateService.instant('carpeta')))
          manual.texto = this.translateService.instant('carpeta') + ': ' + manual.texto;
      });
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  manuales_onClickNuevo() {
    if (this.id != undefined) {
      this.router.navigate(['maquinas/manual/crear/' + this.id]);
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  manuales_onClickNuevaCarpeta() {
    if (this.id != undefined) {
      this.router.navigate(['maquinas/manualCarpeta/crear/' + this.id]);
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  onItemSelected(selectedKeys): void {
    debugger
    selectedKeys.items.forEach(element => {
      this.manuales_seleccionados.push(element.dataItem);
    });
    debugger
    // Lógica para obtener los objetos seleccionados basados en las claves de ítem
    //this.manuales_seleccionados = this.manuales.filter(item => selectedKeys.items.includes(item.id));
  }
  manuales_onClickEditar() {
    if (this.id != undefined) {
      let idCarpeta = 0;
      if (this.manuales_seleccionados[0].id > 0) {
        idCarpeta = this.manuales.filter(x => x.id == this.manuales_seleccionados[0].id)[0].idPadre;
        if (idCarpeta == null)
          this.router.navigate(['maquinas/manualCarpeta/editar/' + this.id + '/' + this.manuales_seleccionados[0].id]);
        else
          this.router.navigate(['maquinas/manual/editar/' + this.id + '/' + this.manuales_seleccionados[0].id]);
      }
    }
  }

  manuales_cellClick(e) {
    if (this.id != undefined) {
      if (e.columnIndex > 0) {
        let manual = this.manuales.filter(x => x.id == e.dataItem.id)[0];
        console.log(manual);
        let idCarpeta = 0;
        idCarpeta = manual.idPadre;
        // if (manual.texto === 'Carpeta: principal') {
        //   return;
        // }
        if (idCarpeta == null)
          this.router.navigate(['maquinas/manualCarpeta/editar/' + this.id + '/' + e.dataItem.id]);
        else
          this.router.navigate(['maquinas/manual/editar/' + this.id + '/' + e.dataItem.id]);
      }
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  manuales_onClickEliminar(e) {
    debugger
    if (this.id != undefined) {
      e.preventDefault();
      let idCarpeta = 0;
      if (this.manuales_seleccionados[0].id > 0) {
        idCarpeta = this.manuales.filter(x => x.id == this.manuales_seleccionados[0].id)[0].idPadre;
        if (idCarpeta == null)
          this.manuales_modalReference = this.modalService.open(this.popupEliminarCarpeta, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        else
          this.manuales_modalReference = this.modalService.open(this.popupEliminarManual, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
      //this.router.navigate(['maquinas/editar/' + this.id]);
    }
  }
  carpetas_eliminarRegistro() {
    if (this.id != undefined) {
      var auxSeleccionados = [];
      this.manuales_seleccionados.forEach(element => auxSeleccionados.push(element.itemKey));
      this.maquinasService.Delete_ManualCarpeta({ ids: auxSeleccionados }).subscribe(
        (data) => {
          if (data.error == false) {
            this.maquinasService.GetAll_Manuales(this.id).subscribe((result) => {
              this.manuales = result.data;
              // this.manuales(this.carpetaPrincipal);
              this.modificarLabelCarpetas();
              this.manuales_seleccionados = [];
              this.manuales_modalReference.close();
              this.manuales_modalReferenceLoading.close();
            }
            );
          }
        }
      );
    }
  }

  manuales_eliminarRegistro() {
    if (this.id != undefined) {
      var auxSeleccionados = [];
      this.manuales_seleccionados.forEach(element => auxSeleccionados.push(element.itemKey));

      var auxManuales = [];
      this.manuales.forEach(element => {
        if (auxSeleccionados.includes(element.id)) {
          auxManuales.push(element.pdf);
        }
      });
      //this.eliminarURLsPorTipo(auxManuales);
      this.maquinasService.Delete_Manual({ ids: auxSeleccionados, manuales: auxManuales, idMaquina: this.id }).subscribe(
        (data) => {
          if (data.error == false) {
            this.maquinasService.GetAll_Manuales(this.id).subscribe((result) => {
              this.manuales = result.data;
              // this.manuales.push(this.carpetaPrincipal);
              this.modificarLabelCarpetas();
            }
            );
          }
          this.manuales_seleccionados = [];
          this.manuales_modalReferenceLoading.close();
        }
      );
      this.manuales_modalReference.close();
      //this.router.navigate(['maquinas/editar/' + this.id]);
      // this.manuales_modalReference.close();
      // this.manuales_modalReferenceLoading = this.modalService.open(contentLoading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    }
  }

  eliminarURLsPorTipo(auxManuales){
    console.log(auxManuales);
    var urls = auxManuales;
    
    //Get configuracion definida
    this.configuracionService.get_configuracion_gestion_documental().subscribe(res => {
      var config:any = res;
      
      if(config != null){
        switch(config[0].idTipo){
          case 0: {
            this.subidaFicherosService.borrarArchivosDeAzure(urls).subscribe(res => {
              console.log(res);
            })
            break;
          }
          case 2: {
            this.subidaFicherosService.borrarArchivosDeFTP(urls).subscribe(res => {
              console.log(res);
            })
            break;
          }
          //Azure externo
          case 3: {
            this.subidaFicherosService.borrarArchivosDeAzureExterno(urls).subscribe(res => {
              console.log(res);
            })
            break;
          }
          //Google Cloud
          case 5: {
            this.subidaFicherosService.borrarArchivosDeGoogleCloud(urls).subscribe(res => {
              console.log(res);
            })
            break;
          }
          //Azure
          case 6: {
            this.subidaFicherosService.borrarArchivosDeAzure(urls).subscribe(res => {
              console.log(res);
            })
            break;
          }
        }
      }
    })
  }

}
