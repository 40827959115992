import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { AlertService, AccionesService, MaquinasService, PlanesAccionService, DiccionarioPerdidasService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PerdidasService, UsuariosService, MenuService } from '@app/_services';
import { Usuario } from '@app/_models';
import { MyFunctions } from '@app/_helpers';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

@Component({
  selector: 'app-planacciondetalle-data',
  templateUrl: 'accionDetalle.html'
})

export class AccionDetalleComponent {

  private translate: TranslateService;

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any[] = [];

  //Grupos de perdidas
  public gruposPerdidas: any;
  public grupoPerdidasConPerdidas: any;
  public gruposperdidasseleccionadas: any[] = [];

  //CARGA DE SECCIONES Y GRUPOS
  public perdidas: any;
  public maquinas: any;
  public responsables: Usuario[];
  public accionesseleccionado = [];
  public fechaini: Date;
  public fechafin: Date;
  public nombre: string;
  public descripcion: string;
  public idResponsable: number;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  public linkcancelar: string;
  public accionesEliminar: [];
  private accioneseleccionado = [];
  closeResult = '';
  public idPlan: number;
  public idAccion: number;
  form: FormGroup;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  public perdidasseleccionadas: number[] = [];
  public maquinasseleccionadas: number[] = [];

  public gridViewPerdidas: GridDataResult;
  public gridViewMaquinas: GridDataResult;
  public pageSize = 20;
  public skip = 0;
  public pageableMaquinas: Boolean;
  public pageablePerdidas: Boolean;

  constructor(
    private formBuilder: FormBuilder,
    private planesaccionservice: PlanesAccionService,
    private accionesservice: AccionesService,
    private maquinasservice: MaquinasService,
    private perdidasservice: PerdidasService,
    private diccionarioPerdidasService: DiccionarioPerdidasService,
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private route: ActivatedRoute,
    protected alertService: AlertService,
    public router: Router,
    private menuService: MenuService,
    translate: TranslateService,
    private myFunctions: MyFunctions,
    private modalService: NgbModal) {

    this.translate = translate;

    var nav = this.router.getCurrentNavigation();

    if (nav.extras.state != undefined) {
      this.idPlan = nav.extras.state.idplan;
      this.idAccion = nav.extras.state.idaccion;
    }

    this.menuService.titulo = this.translate.instant('accion').toUpperCase();;
    this.pageablePerdidas = false;
    this.pageableMaquinas = false;

  }

  ngOnInit() {

    this.linkcancelar = "/planesaccion/editar/" + this.idPlan;
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      fechaini: ['', Validators.required],
      fechafin: ['', Validators.required],
      secciones: [[]],
      grupos: [[]],
      gruposPerdidas: [[]],
      idresponsable: ['',],
      descripcion: ['',]
    });

    this.isAddMode = !this.idAccion;

    if (this.idPlan == undefined) this.router.navigate(['planesaccion']);

    // this.userService.getAll().subscribe(result => {
    //   this.responsables = result;
    //   this.responsables.forEach((element: any) => {
    //     element.nombrecompleto = element.nombre + " " + element.apellido1;
    //   });

      this.userService.getAllUsers(0).pipe().subscribe((result) => {
        this.responsables = result['data'];
        this.responsables.forEach((element: any) => {
          element.nombrecompleto = element.nombre + " " + element.apellido1;
        });
    });

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        if (this.maquinas.length >= this.pageSize) this.pageableMaquinas = true;
        this.loadItemsMaquinas();
      })
    } else {
      this.maquinas = maquinas_model;
      if (this.maquinas.length >= this.pageSize) this.pageableMaquinas = true;
      this.loadItemsMaquinas();
    }

    this.perdidasservice.GetAll().subscribe((result) => {
      var p: any = result.data;
      this.perdidas = p;
      this.loadItemsPerdidas();
      if (this.perdidas.length >= this.pageSize) this.pageablePerdidas = true;
    });

    this.diccionarioPerdidasService.GetAllGruposWithPerdidas().subscribe((result) => {
      var p1: any = result.data;
      var p2: any = result.data2;
      this.grupoPerdidasConPerdidas = p2;
      this.gruposPerdidas = p1;
    });

    this.cargarAreasProductivas();

    if (!this.isAddMode) {

      this.accionesservice.getById(this.idAccion).subscribe((result) => {

        this.nombre = result.accion[0].nombre;
        this.fechaini = new Date(Date.parse(result.accion[0].fechaini));
        this.fechafin = new Date(Date.parse(result.accion[0].fechafin));
        this.descripcion = result.accion[0].descripcion;
        this.idResponsable = parseInt(result.accion[0].id1);

        for (var i = 0; i < result.accionesmaquinas.length; i++)
          this.maquinasseleccionadas.push(result.accionesmaquinas[i].id);

        for (var i = 0; i < result.accionesperdidas.length; i++)
          this.perdidasseleccionadas.push(result.accionesperdidas[i].id);

        this.form.patchValue({ fechaini: this.fechaini, fechafin: this.fechafin, nombre: this.nombre, descripcion: this.descripcion });
      });
    } else {
      this.planesaccionservice.GetByID(this.idPlan).pipe().subscribe((result) => {

        this.fechaini = new Date(Date.parse(result.data[2]));
        this.fechafin = new Date(Date.parse(result.data[3]));
        result.data.fechaini = this.fechaini;
        result.data.fechafin = this.fechafin;

        this.form.patchValue({ fechaini: this.fechaini, fechafin: this.fechafin, nombre: this.nombre, descripcion: this.descripcion });
      });
    }
  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    var seccionesUsuario = [];

    if (an1 != undefined) seccionesUsuario = an1.filter(f => f.activo === true);

    var idsAreasProductivasSeleccionadas = [...new Set(seccionesUsuario.map(a => a.idAreaProductiva))];

    this.userService.getComboSecciones().subscribe((json: any) => {

      //Añadimos solo las secciones de las areas productivas seleccionadas en el usuario (Con una seccion de una area productiva seleccionada, se mostrarian todas las secciones de esa area productiva)
      var seccionesDeAreaProductiva = [];
      json.forEach(seccion => {
        if (idsAreasProductivasSeleccionadas.includes(seccion.idAreaProductiva)) seccionesDeAreaProductiva.push(seccion);
      });

      this.secciones = seccionesDeAreaProductiva;
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
    });

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
    });

  }

  public filtrarMaquinasPorSeccionGrupo(value: any): void {
    if (value.length > 0) {
      //FLTRO POR SECCIONES
      var idsSeccionesSelecteds: any = [];
      if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
        this.seccionesSeleccionadas.forEach(seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
      } else {
        this.secciones.forEach(seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
      }

      //FILTRO POR GRUPOS
      var idsGruposSelecteds: any = [];
      if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
        this.gruposSeleccionados.forEach(grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
      } else {
        this.grupos.forEach(grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
      }
      var maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

      if (maquinasMostradas.length > 0) this.maquinasseleccionadas = maquinasMostradas.map(a => a.id);
      else this.maquinasseleccionadas = [];
    } else {
      this.maquinasseleccionadas = [];
    }

  }

  public filtrarPerdidasPorGrupo(value: any): void {
    var perdidasAMostrar = [];
    if (value.length > 0) {
      //FILTRO POR GRUPOS
      var idsGruposSelecteds: any = [];
      if (this.gruposperdidasseleccionadas && this.gruposperdidasseleccionadas.length > 0) {
        this.gruposperdidasseleccionadas.forEach(grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
      } else {
        this.gruposPerdidas.forEach(grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
      }
      idsGruposSelecteds.forEach(index => {
        perdidasAMostrar = perdidasAMostrar.concat(this.grupoPerdidasConPerdidas.filter(g => g.idGrupo === index));
      });
      perdidasAMostrar = perdidasAMostrar.map(a => a.idPerdida);
    }

    var perdidasMostradas = this.perdidas.filter(f => perdidasAMostrar.includes(f.id));

    if (perdidasMostradas.length > 0) this.perdidasseleccionadas = perdidasMostradas.map(a => a.id);
    else this.perdidasseleccionadas = [];

  }

  public pageChangePerdidas(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItemsPerdidas();
  }


  public pageChangeMaquinas(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItemsMaquinas();
  }

  private loadItemsPerdidas(): void {
    this.gridViewPerdidas = {
      data: this.perdidas.slice(this.skip, this.skip + this.pageSize),
      total: this.perdidas.length
    };
  }

  private loadItemsMaquinas(): void {

    this.gridViewMaquinas = {
      data: this.maquinas.slice(this.skip, this.skip + this.pageSize),
      total: this.maquinas.length
    };

  }

  get f() { return this.form.controls; }

  onSubmit(contentloading, e) {

    this.submitted = true;

    e.preventDefault();

    if (this.form.value.descripcion == '')
      this.form.value.descripcion = ' ';

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    var titulo = document.getElementById("text_loading_popup");

    if (titulo != undefined) {
      titulo.innerText = this.translate.instant("guardando");
    }

    this.form.patchValue({ perdidas: this.perdidasseleccionadas, maquinas: this.maquinasseleccionadas });

    if (this.isAddMode) {
      this.nuevo();
    } else {
      this.actualizar();
    }
  }

  actualizar() {
    this.form.value.idplan = this.idPlan;
    this.form.value.idaccion = this.idAccion;
    this.form.value.maquinas = this.maquinasseleccionadas;
    this.form.value.perdidas = this.perdidasseleccionadas;
    this.accionesservice.update(this.form.value).pipe().subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
    });
  }

  nuevo() {
    this.form.value.idplan = this.idPlan;
    this.form.value.maquinas = this.maquinasseleccionadas;
    this.form.value.perdidas = this.perdidasseleccionadas;
    this.form.value.grupoPerdidas = this.gruposperdidasseleccionadas;
    this.accionesservice.create(this.form.value).pipe().subscribe((result) => {
      this.modalReferenceloading.close();
      {
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          this.Atras();
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      }
    });
  }

  Atras() {
    this.router.navigate(['planesaccion/editar/' + this.idPlan]);
  }

}
