import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
const baseUrl = `${environment.apiUrl}/maquinas`;

@Injectable()
export class MaquinasService {

  public grupos: any | boolean = false;
  public grupoSeleccionado: number = -1;
  public maquinas: Array<any> | boolean = false;
  public instalaciones: Array<any> | boolean = false;
  public marcas: Array<any> | boolean = false;
  public maquinas_planificador: Array<any> | boolean = false;
  public maquinas_Y_subcontratado_planificador: Array<any> | boolean = false;

  constructor(
    private http: HttpClient
  ) {
  }

  getMaquinasPlanificador() {
    return this.http.post<JSON>(`${baseUrl}/GetMaquinasPlanificador`, {}, { withCredentials: true });
  }
  getMaquinasPlanificadorTodas() {
    return this.http.post<JSON>(`${baseUrl}/GetMaquinasPlanificadorTodas`, {}, { withCredentials: true });
  }


  public get_grupos_model(): Array<any> | boolean {
    return this.grupos;
  }

  public set_grupos_model(grupos: any): void {
    this.grupos = grupos;
  }

  public get_grupo_seleccionado_model(): number {
    return this.grupoSeleccionado;
  }

  public set_grupo_seleccionado_model(grupoSeleccionado: any): void {
    this.grupoSeleccionado = grupoSeleccionado;
  }

  public get_maquinas_model(): Array<any> | boolean {
    return this.maquinas;
  }

  public set_maquinas_model(maquinas: any): void {
    if (maquinas) {
      maquinas.forEach(element => {
        if (element.imagen == '') {
          switch (element.tipo_maquina) {
            case 1: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // mecanizado
            case 2: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // extrusora
            case 3: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // impresora
            case 4: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-07.png"; break; // inyectora
            case 5: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-06.png"; break; // horno
            case 9: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-05.png"; break; // aditivo
            case 11: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-09.png"; break; // puesto manual
            case 12: element.imagenBase64 = "../../../assets/imgMaquinas/" + "maquina-06.png"; break; // horno temple
          }
        }
      });
      this.maquinas = maquinas;
    }
  }

  public get_instalaciones_model(): Array<any> | boolean {
    return this.instalaciones;
  }

  public set_instalaciones_model(instalaciones: any): void {
    this.instalaciones = instalaciones;
  }

  public get_marcas_model(): Array<any> | boolean {
    return this.marcas;
  }

  public set_marcas_model(marcas: any): void {
    this.marcas = marcas;
  }

  public get_maquinas_planificador_model(): Array<any> | boolean {
    return this.maquinas_planificador;
  }
  public set_maquinas_planificador_model(maquinas_plani: any): void {
    this.maquinas_planificador = maquinas_plani;
  }

  public get_maquinas_Y_subcontratado_planificador_model(): Array<any> | boolean {
    return this.maquinas_Y_subcontratado_planificador;
  }
  public set_maquinas_Y_subcontratado_planificador_model(maquinas_Y_subcontratado_plani: any): void {
    this.maquinas_Y_subcontratado_planificador = maquinas_Y_subcontratado_plani;
  }

  get() {
    return this.http.post<JSON>(`${baseUrl}/Get`, {}, { withCredentials: true });
  }

  GetInstalaciones() {
    return this.http.post<JSON>(`${baseUrl}/GetInstalaciones`, {}, { withCredentials: true });
  }

  GetTiposMaquina() {
    return this.http.post<JSON>(`${baseUrl}/GetTiposMaquina`, {}, { withCredentials: true });
  }

  GetTipoMaquinaById(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/tipoMaquina/" + idMaquina);
  }

  GetMaximosYMinimosById(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/GetMaximosYMinimosById/" + idMaquina);
  }

  getMarcas() {
    return this.http.post<JSON>(`${baseUrl}/GetMarcas`, {}, { withCredentials: true });
  }

  getMaquinasPermisos() {
    return this.http.post<JSON>(`${baseUrl}/GetMaquinasPermisos`, {}, { withCredentials: true });
  }

  getProximoTurno(idMaquina, turnos, hora, frecuenciaMantenimientos) {
    return this.http.post<JSON>(`${baseUrl}/getProximoTurno`, { idMaquina, turnos, hora, frecuenciaMantenimientos }, { withCredentials: true });
  }

  compatibilidades() {
    return this.http.post<any>(`${baseUrl}/compatibilidades`, {}, { withCredentials: true });
  }

  guardarcompatibilidades(data) {
    return this.http.post<any>(`${baseUrl}/compatibilidades/registrar`, data, { withCredentials: true });
  }

  public GetById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetExtrusoraById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/extrusora/" + id);
  }

  public GetImpresoraPlasticoById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/impresoraPlastico/" + id);
  }

  public GetInyectoraById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/inyectora/" + id);
  }

  public GetHornoById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/horno/" + id);
  }

  public GetAditivoById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/aditivo/" + id);
  }

  public GetHornoTempleById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/hornoTemple/" + id);
  }

  public GetCapasById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/GetCapasById/" + id);
  }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl + "/GetAll");
  }

  public GetMaxOrden(): Observable<any> {
    return this.http.get(baseUrl + "/GetMaxOrden");
  }


  GetDecodificaciones(): Observable<any> {
    return this.http.get(baseUrl + "/getDecodificaciones", { withCredentials: true });
  }

  GetTempladoHornoByProcesoId(id: number): Observable<any> {
    return this.http.get(baseUrl + "/hornotemplado/" + id, { withCredentials: true });
  }

  // public GetModelosActivosHmi(): Observable<any> {
  //   return this.http.get(baseUrl + "/GetModelosActivosHmi");
  // }

  insert(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;
    debugger
    return this.http.post<any>(baseUrl + "/crear", {
      modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocoloSeleccionado, idSeccion: data.idSeccion.id,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      ip: data.ip, puerto: data.puerto, usuario: "", contrasena: "", tiempoRecogidaDatos: data.tiempoSincrSeg, potenMax: data.maximaDelPotenciometro, potenMax2: data.maximaDelPotenciometro2,
      maximoHerramientas: data.maxHerramientas, potenciometro1Visible: data.visible1, potenciometro2Visible: data.visible2, linkCamara: data.urlCamara, puestoOffline: data.maquinaPuestoOffline,
      tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId, nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, idClasificacion: data.idClasificacion
      , abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  insertExtrusora(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    if (data.kgH_min == null) data.kgH_min = 0;
    if (data.kgH_max == null) data.kgH_max = 0;
    if (data.anchoLamina_min == null) data.anchoLamina_min = 0;
    if (data.anchoLamina_max == null) data.anchoLamina_max = 0;
    if (data.infoControlEspesores_min == null) data.infoControlEspesores_min = 0;
    if (data.infoControlEspesores_max == null) data.infoControlEspesores_max = 0;
    if (data.rendimientoSopladores_min == null) data.rendimientoSopladores_min = 0;
    if (data.rendimientoSopladores_max == null) data.rendimientoSopladores_max = 0;
    if (data.tempAnilloEntrada_min == null) data.tempAnilloEntrada_min = 0;
    if (data.tempAnilloEntrada_max == null) data.tempAnilloEntrada_max = 0;
    if (data.tempIBCDeEntrada_min == null) data.tempIBCDeEntrada_min = 0;
    if (data.tempIBCDeEntrada_max == null) data.tempIBCDeEntrada_max = 0;
    if (data.tempRodilloCalandra_min == null) data.tempRodilloCalandra_min = 0;
    if (data.tempRodilloCalandra_max == null) data.tempRodilloCalandra_max = 0;
    if (data.tempRodilloHalador_min == null) data.tempRodilloHalador_min = 0;
    if (data.tempRodilloHalador_max == null) data.tempRodilloHalador_max = 0;
    if (data.kwhTotales_min == null) data.kwhTotales_min = 0;
    if (data.kwhTotales_max == null) data.kwhTotales_max = 0;
    if (data.potenciaInstantanea_min == null) data.potenciaInstantanea_min = 0;
    if (data.potenciaInstantanea_max == null) data.potenciaInstantanea_max = 0;

    return this.http.post<any>(baseUrl + "/crearExtrusora", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, idSeccion: data.idSeccion.id,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, kgH_activo: data.kgH_activo, kgH_min: data.kgH_min, kgH_max: data.kgH_max,
      anchoLamina_activo: data.anchoLamina_activo, anchoLamina_min: data.anchoLamina_min, anchoLamina_max: data.anchoLamina_max,
      infoControlEspesores_activo: data.infoControlEspesores_activo, infoControlEspesores_min: data.infoControlEspesores_min, infoControlEspesores_max: data.infoControlEspesores_max,
      rendimientoSopladores_activo: data.rendimientoSopladores_activo, rendimientoSopladores_min: data.rendimientoSopladores_min, rendimientoSopladores_max: data.rendimientoSopladores_max,
      tempAnilloEntrada_activo: data.tempAnilloEntrada_activo, tempAnilloEntrada_min: data.tempAnilloEntrada_min, tempAnilloEntrada_max: data.tempAnilloEntrada_max,
      tempIBCDeEntrada_activo: data.tempIBCDeEntrada_activo, tempIBCDeEntrada_min: data.tempIBCDeEntrada_min, tempIBCDeEntrada_max: data.tempIBCDeEntrada_max,
      tempRodilloCalandra_activo: data.tempRodilloCalandra_activo, tempRodilloCalandra_min: data.tempRodilloCalandra_min, tempRodilloCalandra_max: data.tempRodilloCalandra_max,
      tempRodilloHalador_activo: data.tempRodilloHalador_activo, tempRodilloHalador_min: data.tempRodilloHalador_min, tempRodilloHalador_max: data.tempRodilloHalador_max,
      kwhTotales_activo: data.kwhTotales_activo, kwhTotales_min: data.kwhTotales_min, kwhTotales_max: data.kwhTotales_max,
      potenciaInstantanea_activo: data.potenciaInstantanea_activo, potenciaInstantanea_min: data.potenciaInstantanea_min, potenciaInstantanea_max: data.potenciaInstantanea_max,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  insertImpresoraPlastico(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    if (data.desbobinadora_min == null) data.desbobinadora_min = 0;
    if (data.desbobinadora_max == null) data.desbobinadora_max = 0;
    if (data.tomaDeAlimentacion_min == null) data.tomaDeAlimentacion_min = 0;
    if (data.tomaDeAlimentacion_max == null) data.tomaDeAlimentacion_max = 0;
    if (data.accionadorRodilloEnfriamiento_min == null) data.accionadorRodilloEnfriamiento_min = 0;
    if (data.accionadorRodilloEnfriamiento_max == null) data.accionadorRodilloEnfriamiento_max = 0;
    if (data.bobinadora_min == null) data.bobinadora_min = 0;
    if (data.bobinadora_max == null) data.bobinadora_max = 0;
    if (data.secadorTempBCD_min == null) data.secadorTempBCD_min = 0;
    if (data.secadorTempBCD_max == null) data.secadorTempBCD_max = 0;
    if (data.secadorTunelTempBCD_min == null) data.secadorTunelTempBCD_min = 0;
    if (data.secadorTunelTempBCD_max == null) data.secadorTunelTempBCD_max = 0;
    if (data.kwhTotales_min == null) data.kwhTotales_min = 0;
    if (data.kwhTotales_max == null) data.kwhTotales_max = 0;
    if (data.potenciaInstantanea_min == null) data.potenciaInstantanea_min = 0;
    if (data.potenciaInstantanea_max == null) data.potenciaInstantanea_max = 0;

    return this.http.post<any>(baseUrl + "/crearImpresoraPlastico", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, idSeccion: data.idSeccion.id,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, desbobinadora_activo: data.desbobinadora_activo, desbobinadora_min: data.desbobinadora_min, desbobinadora_max: data.desbobinadora_max,
      tomaDeAlimentacion_activo: data.tomaDeAlimentacion_activo, tomaDeAlimentacion_min: data.tomaDeAlimentacion_min, tomaDeAlimentacion_max: data.tomaDeAlimentacion_max,
      accionadorRodilloEnfriamiento_activo: data.accionadorRodilloEnfriamiento_activo, accionadorRodilloEnfriamiento_min: data.accionadorRodilloEnfriamiento_min, accionadorRodilloEnfriamiento_max: data.accionadorRodilloEnfriamiento_max,
      bobinadora_activo: data.bobinadora_activo, bobinadora_min: data.bobinadora_min, bobinadora_max: data.bobinadora_max,
      secadorTempBCD_activo: data.secadorTempBCD_activo, secadorTempBCD_min: data.secadorTempBCD_min, secadorTempBCD_max: data.secadorTempBCD_max,
      secadorTunelTempBCD_activo: data.secadorTunelTempBCD_activo, secadorTunelTempBCD_min: data.secadorTunelTempBCD_min, secadorTunelTempBCD_max: data.secadorTunelTempBCD_max,
      kwhTotales_activo: data.kwhTotales_activo, kwhTotales_min: data.kwhTotales_min, kwhTotales_max: data.kwhTotales_max,
      potenciaInstantanea_activo: data.potenciaInstantanea_activo, potenciaInstantanea_min: data.potenciaInstantanea_min, potenciaInstantanea_max: data.potenciaInstantanea_max,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  insertInyectora(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.tempCamaraCaliente_maxZonas == null) data.tempCamaraCaliente_maxZonas = 0;
    if (data.tempHusillo_maxZonas == null) data.tempHusillo_maxZonas = 0;
    if (data.cascada_maxZonas == null) data.cascada_maxZonas = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    return this.http.post<any>(baseUrl + "/crearInyectora", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, idSeccion: data.idSeccion.id, ip: data.ip,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      puerto: data.puerto, tiempoRecogidaDatos: data.tiempoSincrSeg, linkCamara: data.urlCamara, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64,
      tonelaje: data.tonelaje, unidadInyeccion: data.unidadInyeccion, tiempoCiclo: data.tiempoCiclo, tiempoInyeccion: data.tiempoInyeccion,
      tiempoPresionRemanente: data.tiempoPresionRemanente, tiempoEnfriamiento: data.tiempoEnfriamiento, tiempoDosificacionPlastificacion: data.tiempoDosificacionPlastificacion,
      presionInyeccionMax: data.presionInyeccionMax, presionRemanente: data.presionRemanente, contrapresion: data.contrapresion, ptoConmutacion: data.ptoConmutacion,
      paradaDosificado: data.paradaDosificado, retrocesoHusillo: data.retrocesoHusillo, paradaMolde: data.paradaMolde, cojin: data.cojin, revolucionesHusillo: data.revolucionesHusillo,
      tempPresionFija: data.tempPresionFija, tempPresionMovil: data.tempPresionMovil, fuerzaDeCierre: data.fuerzaDeCierre, temperaturaMolde: data.temperaturaMolde,
      tempCamaraCaliente_activo: data.tempCamaraCaliente_activo, tempCamaraCaliente_maxZonas: data.tempCamaraCaliente_maxZonas, tempHusillo_activo: data.tempHusillo_activo,
      tempHusillo_maxZonas: data.tempHusillo_maxZonas, cascada_activo: data.cascada_activo, cascada_maxZonas: data.cascada_maxZonas, mostrarEnGrafico_activo: data.mostrarEnGrafico_activo,
      mostrarEnGrafico_desgloseTiempos: data.mostrarEnGrafico_desgloseTiempos, mostrarEnGrafico_cascadas: data.mostrarEnGrafico_cascadas, abreviatura: data.abreviatura,
      agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  insertHorno(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    if (data.energia_min == null) data.energia_min = 0;
    if (data.energia_max == null) data.energia_max = 0;
    if (data.potencia_min == null) data.potencia_min = 0;
    if (data.potencia_max == null) data.potencia_max = 0;
    if (data.intensidad_min == null) data.intensidad_min = 0;
    if (data.intensidad_max == null) data.intensidad_max = 0;
    if (data.voltaje_min == null) data.voltaje_min = 0;
    if (data.voltaje_max == null) data.voltaje_max = 0;
    if (data.frecuencia_min == null) data.frecuencia_min = 0;
    if (data.frecuencia_max == null) data.frecuencia_max = 0;
    if (data.caudal_min == null) data.caudal_min = 0;
    if (data.caudal_max == null) data.caudal_max = 0;
    if (data.temperaturaPieza_min == null) data.temperaturaPieza_min = 0;
    if (data.temperaturaPieza_max == null) data.temperaturaPieza_max = 0;
    if (data.presionBombaDucha_min == null) data.presionBombaDucha_min = 0;
    if (data.presionBombaDucha_max == null) data.presionBombaDucha_max = 0;
    if (data.temperaturaPolimero_min == null) data.temperaturaPolimero_min = 0;
    if (data.temperaturaPolimero_max == null) data.temperaturaPolimero_max = 0;
    if (data.porcenPolimero_min == null) data.porcenPolimero_min = 0;
    if (data.porcenPolimero_max == null) data.porcenPolimero_max = 0;
    if (data.tiempoCiclo_min == null) data.tiempoCiclo_min = 0;
    if (data.tiempoCiclo_max == null) data.tiempoCiclo_max = 0;
    if (data.tiempoCalentamientoTotal_min == null) data.tiempoCalentamientoTotal_min = 0;
    if (data.tiempoCalentamientoTotal_max == null) data.tiempoCalentamientoTotal_max = 0;
    if (data.tiempoEnfriamientoTotal_min == null) data.tiempoEnfriamientoTotal_min = 0;
    if (data.tiempoEnfriamientoTotal_max == null) data.tiempoEnfriamientoTotal_max = 0;
    if (data.velocidadRotacion_min == null) data.velocidadRotacion_min = 0;
    if (data.velocidadRotacion_max == null) data.velocidadRotacion_max = 0;
    if (data.posicionEje1_min == null) data.posicionEje1_min = 0;
    if (data.posicionEje1_max == null) data.posicionEje1_max = 0;
    if (data.posicionEje2_min == null) data.posicionEje2_min = 0;
    if (data.posicionEje2_max == null) data.posicionEje2_max = 0;

    return this.http.post<any>(baseUrl + "/crearHorno", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, idSeccion: data.idSeccion.id,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos,
      energia_activo: data.energia_activo, energia_min: data.energia_min, energia_max: data.energia_max,
      potencia_activo: data.potencia_activo, potencia_min: data.potencia_min, potencia_max: data.potencia_max,
      intensidad_activo: data.intensidad_activo, intensidad_min: data.intensidad_min, intensidad_max: data.intensidad_max,
      voltaje_activo: data.voltaje_activo, voltaje_min: data.voltaje_min, voltaje_max: data.voltaje_max,
      frecuencia_activo: data.frecuencia_activo, frecuencia_min: data.frecuencia_min, frecuencia_max: data.frecuencia_max,
      caudal_activo: data.caudal_activo, caudal_min: data.caudal_min, caudal_max: data.caudal_max,
      temperaturaPieza_activo: data.temperaturaPieza_activo, temperaturaPieza_min: data.temperaturaPieza_min, temperaturaPieza_max: data.temperaturaPieza_max,
      presionBombaDucha_activo: data.presionBombaDucha_activo, presionBombaDucha_min: data.presionBombaDucha_min, presionBombaDucha_max: data.presionBombaDucha_max,
      temperaturaPolimero_activo: data.temperaturaPolimero_activo, temperaturaPolimero_min: data.temperaturaPolimero_min, temperaturaPolimero_max: data.temperaturaPolimero_max,
      porcenPolimero_activo: data.porcenPolimero_activo, porcenPolimero_min: data.porcenPolimero_min, porcenPolimero_max: data.porcenPolimero_max,
      tiempoCiclo_activo: data.tiempoCiclo_activo, tiempoCiclo_min: data.tiempoCiclo_min, tiempoCiclo_max: data.tiempoCiclo_max,
      tiempoCalentamientoTotal_activo: data.tiempoCalentamientoTotal_activo, tiempoCalentamientoTotal_min: data.tiempoCalentamientoTotal_min, tiempoCalentamientoTotal_max: data.tiempoCalentamientoTotal_max,
      tiempoEnfriamientoTotal_activo: data.tiempoEnfriamientoTotal_activo, tiempoEnfriamientoTotal_min: data.tiempoEnfriamientoTotal_min, tiempoEnfriamientoTotal_max: data.tiempoEnfriamientoTotal_max,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax,
      velocidadRotacion_activo: data.velocidadRotacion_activo, velocidadRotacion_min: data.velocidadRotacion_min, velocidadRotacion_max: data.velocidadRotacion_max,
      posicionEje1_activo: data.posicionEje1_activo, posicionEje1_min: data.posicionEje1_min, posicionEje1_max: data.posicionEje1_max,
      posicionEje2_activo: data.posicionEje2_activo, posicionEje2_min: data.posicionEje2_min, posicionEje2_max: data.posicionEje2_max
      
      //#region VALORES TECNOLOGICOS
      , OP_activar_soplador_1: data.OP_activar_soplador_1
      , OP_activar_soplador_2: data.OP_activar_soplador_2
      , OP_activar_soplador_3: data.OP_activar_soplador_3
      , OP_activar_soplador_4: data.OP_activar_soplador_4
      , OP_activar_soplador_5: data.OP_activar_soplador_5
      , OP_activar_soplador_6: data.OP_activar_soplador_6
      , OP_activar_soplador_7: data.OP_activar_soplador_7
      , OP_activar_soplador_8: data.OP_activar_soplador_8
      , OP_activar_soplador_9: data.OP_activar_soplador_9
      , OP_activar_soplador_10: data.OP_activar_soplador_10
      , OP_activar_soplador_11: data.OP_activar_soplador_11
      , OP_activar_soplador_12: data.OP_activar_soplador_12
      , OP_activar_soplador_13: data.OP_activar_soplador_13
      , OP_activar_salida_cuba: data.OP_activar_salida_cuba
      , OP_control_paso_pieza: data.OP_control_paso_pieza
      , OP_rodillo_introductor: data.OP_rodillo_introductor
      , OP_giro_plato: data.OP_giro_plato
      , OP_control_energia_activado: data.OP_control_energia_activado
      , OP_control_caudal_activado: data.OP_control_caudal_activado
      , OP_control_temperatura_activado: data.OP_control_temperatura_activado
      , OP_control_presion_activado: data.OP_control_presion_activado
      , GEN_configuracion_seleccionada: data.GEN_configuracion_seleccionada
      , SP_vibrador_1: data.SP_vibrador_1
      , SP_mantenimiento_vibrador_2: data.SP_mantenimiento_vibrador_2
      , SP_lenta_vibrador_2: data.SP_lenta_vibrador_2
      , SP_recuperacion_vibrador_2: data.SP_recuperacion_vibrador_2
      , SP_vibrador_3: data.SP_vibrador_3
      , CV_cinta_salida: data.CV_cinta_salida
      , CV_proceso_giro: data.CV_proceso_giro
      , CV_rapida_giro: data.CV_rapida_giro
      , consigna_calor: data.consigna_calor
      , velocidad_rapida_subida_volteador: data.velocidad_rapida_subida_volteador
      , velocidad_lenta_subida_volteador: data.velocidad_lenta_subida_volteador
      , velocidad_bajada_volteador: data.velocidad_bajada_volteador
      , SP_consigna_velocidad: data.SP_consigna_velocidad
      , OP_piezas_por_giro: data.OP_piezas_por_giro
      , SP_presion_soplador_1: data.SP_presion_soplador_1
      , SP_presion_soplador_2: data.SP_presion_soplador_2
      , SP_presion_soplador_3: data.SP_presion_soplador_3
      , SP_presion_soplador_4: data.SP_presion_soplador_4
      , SP_presion_soplador_5: data.SP_presion_soplador_5
      , SP_presion_soplador_6: data.SP_presion_soplador_6
      , SP_presion_soplador_7: data.SP_presion_soplador_7
      , SP_presion_soplador_8: data.SP_presion_soplador_8
      , SP_presion_soplador_9: data.SP_presion_soplador_9
      , SP_presion_soplador_10: data.SP_presion_soplador_10
      , SP_presion_soplador_11: data.SP_presion_soplador_11
      , SP_presion_soplador_12: data.SP_presion_soplador_12
      , SP_presion_soplador_13: data.SP_presion_soplador_13
      , OP_sector_anchura_expulsor: data.OP_sector_anchura_expulsor
      , OP_sector_continuar_antes_parada_auto: data.OP_sector_continuar_antes_parada_auto
      , OP_sector_continuar_calentado_en_parada: data.OP_sector_continuar_calentado_en_parada
      , OP_sector_expulsion_defectuosos: data.OP_sector_expulsion_defectuosos
      , OP_activar_expulsor: data.OP_activar_expulsor
      , OP_Tmp_parada_atasco: data.OP_Tmp_parada_atasco
      , Tmp_vaciado_cinta_salida: data.Tmp_vaciado_cinta_salida
      , Tmp_falta_Material_vibrador_lineal: data.Tmp_falta_Material_vibrador_lineal
      , Tmp_parada_control_falta_material: data.Tmp_parada_control_falta_material
      , Tmp_recuperacion_falta_material_lineal: data.Tmp_recuperacion_falta_material_lineal
      , Tmp_filtro_defecto_material_vibrador_2: data.Tmp_filtro_defecto_material_vibrador_2
      , Tmp_presencia_material_vibrador_2: data.Tmp_presencia_material_vibrador_2
      , Tmp_peticion_pieza_vibrador_1: data.Tmp_peticion_pieza_vibrador_1
      , Tmp_presencia_pieza_vibrador_1: data.Tmp_presencia_pieza_vibrador_1
      , SP_expulsion_salida_cuba_rapida: data.SP_expulsion_salida_cuba_rapida
      , SP_expulsion_salida_cuba_lenta: data.SP_expulsion_salida_cuba_lenta
      , OP_TMP_salida_vibrador_cuba_saturada: data.OP_TMP_salida_vibrador_cuba_saturada
      , Tmp_vibrador_lineal_completo: data.Tmp_vibrador_lineal_completo
      , Tmp_def_material_m_alt_vibrador_lineal: data.Tmp_def_material_m_alt_vibrador_lineal
      , Tmp_pres_material_m_alt_vibrador_lineal: data.Tmp_pres_material_m_alt_vibrador_lineal
      , SP_salida_paso_pieza: data.SP_salida_paso_pieza
      , TMP_giro_plato_inicial_giro: data.TMP_giro_plato_inicial_giro
      , TMP_giro_plato_control: data.TMP_giro_plato_control
      , consigna_energia: data.consigna_energia
      , margen_energia: data.margen_energia
      , calculo_energia_pos: data.calculo_energia_pos
      , calculo_energia_neg: data.calculo_energia_neg
      , consigna_caudal_ducha: data.consigna_caudal_ducha
      , margen_caudal_ducha: data.margen_caudal_ducha
      , calculo_caudal_ducha_pos: data.calculo_caudal_ducha_pos
      , calculo_caudal_ducha_neg: data.calculo_caudal_ducha_neg
      , consigna_temperatura_ducha: data.consigna_temperatura_ducha
      , margen_temperatura_ducha: data.margen_temperatura_ducha
      , calculo_temperatura_ducha_pos: data.calculo_temperatura_ducha_pos
      , calculo_temperatura_ducha_neg: data.calculo_temperatura_ducha_neg
      , consigna_presion_ducha: data.consigna_presion_ducha
      , margen_presion_ducha: data.margen_presion_ducha
      , calculo_presion_ducha: data.calculo_presion_ducha
      , calculo_presion_ducha_neg: data.calculo_presion_ducha_neg

      , ip: data.ip, puerto: data.puerto, tiempoRecogidaDatos: data.tiempoSincrSeg, linkCamara: data.urlCamara, puestoOffline: data.maquinaPuestoOffline
      //#endregion
    }, { withCredentials: true });
  }

  insertAditivo(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    return this.http.post<any>(baseUrl + "/crearAditivo", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, idSeccion: data.idSeccion.id,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura
      , agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  insertHornoTemple(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    return this.http.post<any>(baseUrl + "/crearHornoTemple", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocoloSeleccionado,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      idSeccion: data.idSeccion.id, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
      , temperaturaProgramada_activo: data.temperaturaProgramada_activo
      , temperaturaActual_activo: data.temperaturaActual_activo
      , consumo_activo: data.consumo_activo
      , nivelO2_activo: data.nivelO2_activo
      , temperaturaInterior_activo: data.temperaturaInterior_activo
      , potencialCarbono_activo: data.potencialCarbono_activo
      , temperaturaProgramada_min: data.temperaturaProgramada_min
      , temperaturaActual_min: data.temperaturaActual_min
      , consumo_min: data.consumo_min
      , nivelO2_min: data.nivelO2_min
      , temperaturaInterior_min: data.temperaturaInterior_min
      , potencialCarbono_min: data.potencialCarbono_min
      , temperaturaProgramada_max: data.temperaturaProgramada_max
      , temperaturaActual_max: data.temperaturaActual_max
      , consumo_max: data.consumo_max
      , nivelO2_max: data.nivelO2_max
      , temperaturaInterior_max: data.temperaturaInterior_max
      , potencialCarbono_max: data.potencialCarbono_max

      , ritmo: data.ritmo, activarRitmo: data.activarRitmo
      , ip: data.ip, puerto: data.puerto, tiempoRecogidaDatos: data.tiempoSincrSeg, linkCamara: data.urlCamara, puestoOffline: data.maquinaPuestoOffline
    }, { withCredentials: true });
  }

  update(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    return this.http.post<any>(baseUrl + "/editar", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo, idSeccion: data.idSeccion.id,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      ip: data.ip, puerto: data.puerto, usuario: "", contrasena: "", tiempoRecogidaDatos: data.tiempoSincrSeg, potenMax: data.maximaDelPotenciometro, potenMax2: data.maximaDelPotenciometro2,
      maximoHerramientas: data.maxHerramientas, potenciometro1Visible: data.visible1, potenciometro2Visible: data.visible2, linkCamara: data.urlCamara, puestoOffline: data.maquinaPuestoOffline,
      tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId, nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, idClasificacion: data.idClasificacion
      , abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  updateExtrusora(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    if (data.kgH_min == null) data.kgH_min = 0;
    if (data.kgH_max == null) data.kgH_max = 0;
    if (data.anchoLamina_min == null) data.anchoLamina_min = 0;
    if (data.anchoLamina_max == null) data.anchoLamina_max = 0;
    if (data.infoControlEspesores_min == null) data.infoControlEspesores_min = 0;
    if (data.infoControlEspesores_max == null) data.infoControlEspesores_max = 0;
    if (data.rendimientoSopladores_min == null) data.rendimientoSopladores_min = 0;
    if (data.rendimientoSopladores_max == null) data.rendimientoSopladores_max = 0;
    if (data.tempAnilloEntrada_min == null) data.tempAnilloEntrada_min = 0;
    if (data.tempAnilloEntrada_max == null) data.tempAnilloEntrada_max = 0;
    if (data.tempIBCDeEntrada_min == null) data.tempIBCDeEntrada_min = 0;
    if (data.tempIBCDeEntrada_max == null) data.tempIBCDeEntrada_max = 0;
    if (data.tempRodilloCalandra_min == null) data.tempRodilloCalandra_min = 0;
    if (data.tempRodilloCalandra_max == null) data.tempRodilloCalandra_max = 0;
    if (data.tempRodilloHalador_min == null) data.tempRodilloHalador_min = 0;
    if (data.tempRodilloHalador_max == null) data.tempRodilloHalador_max = 0;
    if (data.kwhTotales_min == null) data.kwhTotales_min = 0;
    if (data.kwhTotales_max == null) data.kwhTotales_max = 0;
    if (data.potenciaInstantanea_min == null) data.potenciaInstantanea_min = 0;
    if (data.potenciaInstantanea_max == null) data.potenciaInstantanea_max = 0;

    return this.http.post<any>(baseUrl + "/editarExtrusora", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      idSeccion: data.idSeccion.id, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, kgH_activo: data.kgH_activo, kgH_min: data.kgH_min, kgH_max: data.kgH_max,
      anchoLamina_activo: data.anchoLamina_activo, anchoLamina_min: data.anchoLamina_min, anchoLamina_max: data.anchoLamina_max,
      infoControlEspesores_activo: data.infoControlEspesores_activo, infoControlEspesores_min: data.infoControlEspesores_min, infoControlEspesores_max: data.infoControlEspesores_max,
      rendimientoSopladores_activo: data.rendimientoSopladores_activo, rendimientoSopladores_min: data.rendimientoSopladores_min, rendimientoSopladores_max: data.rendimientoSopladores_max,
      tempAnilloEntrada_activo: data.tempAnilloEntrada_activo, tempAnilloEntrada_min: data.tempAnilloEntrada_min, tempAnilloEntrada_max: data.tempAnilloEntrada_max,
      tempIBCDeEntrada_activo: data.tempIBCDeEntrada_activo, tempIBCDeEntrada_min: data.tempIBCDeEntrada_min, tempIBCDeEntrada_max: data.tempIBCDeEntrada_max,
      tempRodilloCalandra_activo: data.tempRodilloCalandra_activo, tempRodilloCalandra_min: data.tempRodilloCalandra_min, tempRodilloCalandra_max: data.tempRodilloCalandra_max,
      tempRodilloHalador_activo: data.tempRodilloHalador_activo, tempRodilloHalador_min: data.tempRodilloHalador_min, tempRodilloHalador_max: data.tempRodilloHalador_max,
      kwhTotales_activo: data.kwhTotales_activo, kwhTotales_min: data.kwhTotales_min, kwhTotales_max: data.kwhTotales_max,
      potenciaInstantanea_activo: data.potenciaInstantanea_activo, potenciaInstantanea_min: data.potenciaInstantanea_min, potenciaInstantanea_max: data.potenciaInstantanea_max,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  updateImpresoraPlastico(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    if (data.desbobinadora_min == null) data.desbobinadora_min = 0;
    if (data.desbobinadora_max == null) data.desbobinadora_max = 0;
    if (data.tomaDeAlimentacion_min == null) data.tomaDeAlimentacion_min = 0;
    if (data.tomaDeAlimentacion_max == null) data.tomaDeAlimentacion_max = 0;
    if (data.accionadorRodilloEnfriamiento_min == null) data.accionadorRodilloEnfriamiento_min = 0;
    if (data.accionadorRodilloEnfriamiento_max == null) data.accionadorRodilloEnfriamiento_max = 0;
    if (data.bobinadora_min == null) data.bobinadora_min = 0;
    if (data.bobinadora_max == null) data.bobinadora_max = 0;
    if (data.secadorTempBCD_min == null) data.secadorTempBCD_min = 0;
    if (data.secadorTempBCD_max == null) data.secadorTempBCD_max = 0;
    if (data.secadorTunelTempBCD_min == null) data.secadorTunelTempBCD_min = 0;
    if (data.secadorTunelTempBCD_max == null) data.secadorTunelTempBCD_max = 0;
    if (data.kwhTotales_min == null) data.kwhTotales_min = 0;
    if (data.kwhTotales_max == null) data.kwhTotales_max = 0;
    if (data.potenciaInstantanea_min == null) data.potenciaInstantanea_min = 0;
    if (data.potenciaInstantanea_max == null) data.potenciaInstantanea_max = 0;

    return this.http.post<any>(baseUrl + "/editarImpresoraPlastico", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      idSeccion: data.idSeccion.id, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, desbobinadora_activo: data.desbobinadora_activo, desbobinadora_min: data.desbobinadora_min, desbobinadora_max: data.desbobinadora_max,
      tomaDeAlimentacion_activo: data.tomaDeAlimentacion_activo, tomaDeAlimentacion_min: data.tomaDeAlimentacion_min, tomaDeAlimentacion_max: data.tomaDeAlimentacion_max,
      accionadorRodilloEnfriamiento_activo: data.accionadorRodilloEnfriamiento_activo, accionadorRodilloEnfriamiento_min: data.accionadorRodilloEnfriamiento_min, accionadorRodilloEnfriamiento_max: data.accionadorRodilloEnfriamiento_max,
      bobinadora_activo: data.bobinadora_activo, bobinadora_min: data.bobinadora_min, bobinadora_max: data.bobinadora_max,
      secadorTempBCD_activo: data.secadorTempBCD_activo, secadorTempBCD_min: data.secadorTempBCD_min, secadorTempBCD_max: data.secadorTempBCD_max,
      secadorTunelTempBCD_activo: data.secadorTunelTempBCD_activo, secadorTunelTempBCD_min: data.secadorTunelTempBCD_min, secadorTunelTempBCD_max: data.secadorTunelTempBCD_max,
      kwhTotales_activo: data.kwhTotales_activo, kwhTotales_min: data.kwhTotales_min, kwhTotales_max: data.kwhTotales_max,
      potenciaInstantanea_activo: data.potenciaInstantanea_activo, potenciaInstantanea_min: data.potenciaInstantanea_min, potenciaInstantanea_max: data.potenciaInstantanea_max,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  updateInyectora(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.tempCamaraCaliente_maxZonas == null) data.tempCamaraCaliente_maxZonas = 0;
    if (data.tempHusillo_maxZonas == null) data.tempHusillo_maxZonas = 0;
    if (data.cascada_maxZonas == null) data.cascada_maxZonas = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    return this.http.post<any>(baseUrl + "/editarInyectora", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo, idSeccion: data.idSeccion.id, ip: data.ip,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      puerto: data.puerto, tiempoRecogidaDatos: data.tiempoSincrSeg, linkCamara: data.urlCamara, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos, nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64,
      tonelaje: data.tonelaje, unidadInyeccion: data.unidadInyeccion, tiempoCiclo: data.tiempoCiclo, tiempoInyeccion: data.tiempoInyeccion,
      tiempoPresionRemanente: data.tiempoPresionRemanente, tiempoEnfriamiento: data.tiempoEnfriamiento, tiempoDosificacionPlastificacion: data.tiempoDosificacionPlastificacion,
      presionInyeccionMax: data.presionInyeccionMax, presionRemanente: data.presionRemanente, contrapresion: data.contrapresion, ptoConmutacion: data.ptoConmutacion,
      paradaDosificado: data.paradaDosificado, retrocesoHusillo: data.retrocesoHusillo, paradaMolde: data.paradaMolde, cojin: data.cojin, revolucionesHusillo: data.revolucionesHusillo,
      tempPresionFija: data.tempPresionFija, tempPresionMovil: data.tempPresionMovil, fuerzaDeCierre: data.fuerzaDeCierre, temperaturaMolde: data.temperaturaMolde,
      tempCamaraCaliente_activo: data.tempCamaraCaliente_activo, tempCamaraCaliente_maxZonas: data.tempCamaraCaliente_maxZonas, tempHusillo_activo: data.tempHusillo_activo,
      tempHusillo_maxZonas: data.tempHusillo_maxZonas, cascada_activo: data.cascada_activo, cascada_maxZonas: data.cascada_maxZonas, mostrarEnGrafico_activo: data.mostrarEnGrafico_activo,
      mostrarEnGrafico_desgloseTiempos: data.mostrarEnGrafico_desgloseTiempos, mostrarEnGrafico_cascadas: data.mostrarEnGrafico_cascadas, abreviatura: data.abreviatura
      , agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  updateHorno(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    if (data.energia_min == null) data.energia_min = 0;
    if (data.energia_max == null) data.energia_max = 0;
    if (data.potencia_min == null) data.potencia_min = 0;
    if (data.potencia_max == null) data.potencia_max = 0;
    if (data.intensidad_min == null) data.intensidad_min = 0;
    if (data.intensidad_max == null) data.intensidad_max = 0;
    if (data.voltaje_min == null) data.voltaje_min = 0;
    if (data.voltaje_max == null) data.voltaje_max = 0;
    if (data.frecuencia_min == null) data.frecuencia_min = 0;
    if (data.frecuencia_max == null) data.frecuencia_max = 0;
    if (data.caudal_min == null) data.caudal_min = 0;
    if (data.caudal_max == null) data.caudal_max = 0;
    if (data.temperaturaPieza_min == null) data.temperaturaPieza_min = 0;
    if (data.temperaturaPieza_max == null) data.temperaturaPieza_max = 0;
    if (data.presionBombaDucha_min == null) data.presionBombaDucha_min = 0;
    if (data.presionBombaDucha_max == null) data.presionBombaDucha_max = 0;
    if (data.temperaturaPolimero_min == null) data.temperaturaPolimero_min = 0;
    if (data.temperaturaPolimero_max == null) data.temperaturaPolimero_max = 0;
    if (data.porcenPolimero_min == null) data.porcenPolimero_min = 0;
    if (data.porcenPolimero_max == null) data.porcenPolimero_max = 0;
    if (data.tiempoCiclo_min == null) data.tiempoCiclo_min = 0;
    if (data.tiempoCiclo_max == null) data.tiempoCiclo_max = 0;
    if (data.tiempoCalentamientoTotal_min == null) data.tiempoCalentamientoTotal_min = 0;
    if (data.tiempoCalentamientoTotal_max == null) data.tiempoCalentamientoTotal_max = 0;
    if (data.tiempoEnfriamientoTotal_min == null) data.tiempoEnfriamientoTotal_min = 0;
    if (data.tiempoEnfriamientoTotal_max == null) data.tiempoEnfriamientoTotal_max = 0;
    if (data.velocidadRotacion_min == null) data.velocidadRotacion_min = 0;
    if (data.velocidadRotacion_max == null) data.velocidadRotacion_max = 0;
    if (data.posicionEje1_min == null) data.posicionEje1_min = 0;
    if (data.posicionEje1_max == null) data.posicionEje1_max = 0;
    if (data.posicionEje2_min == null) data.posicionEje2_min = 0;
    if (data.posicionEje2_max == null) data.posicionEje2_max = 0;

    return this.http.post<any>(baseUrl + "/editarHorno", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      idSeccion: data.idSeccion.id, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos,
      energia_activo: data.energia_activo, energia_min: data.energia_min, energia_max: data.energia_max,
      potencia_activo: data.potencia_activo, potencia_min: data.potencia_min, potencia_max: data.potencia_max,
      intensidad_activo: data.intensidad_activo, intensidad_min: data.intensidad_min, intensidad_max: data.intensidad_max,
      voltaje_activo: data.voltaje_activo, voltaje_min: data.voltaje_min, voltaje_max: data.voltaje_max,
      frecuencia_activo: data.frecuencia_activo, frecuencia_min: data.frecuencia_min, frecuencia_max: data.frecuencia_max,
      caudal_activo: data.caudal_activo, caudal_min: data.caudal_min, caudal_max: data.caudal_max,
      temperaturaPieza_activo: data.temperaturaPieza_activo, temperaturaPieza_min: data.temperaturaPieza_min, temperaturaPieza_max: data.temperaturaPieza_max,
      presionBombaDucha_activo: data.presionBombaDucha_activo, presionBombaDucha_min: data.presionBombaDucha_min, presionBombaDucha_max: data.presionBombaDucha_max,
      temperaturaPolimero_activo: data.temperaturaPolimero_activo, temperaturaPolimero_min: data.temperaturaPolimero_min, temperaturaPolimero_max: data.temperaturaPolimero_max,
      porcenPolimero_activo: data.porcenPolimero_activo, porcenPolimero_min: data.porcenPolimero_min, porcenPolimero_max: data.porcenPolimero_max,
      tiempoCiclo_activo: data.tiempoCiclo_activo, tiempoCiclo_min: data.tiempoCiclo_min, tiempoCiclo_max: data.tiempoCiclo_max,
      tiempoCalentamientoTotal_activo: data.tiempoCalentamientoTotal_activo, tiempoCalentamientoTotal_min: data.tiempoCalentamientoTotal_min, tiempoCalentamientoTotal_max: data.tiempoCalentamientoTotal_max,
      tiempoEnfriamientoTotal_activo: data.tiempoEnfriamientoTotal_activo, tiempoEnfriamientoTotal_min: data.tiempoEnfriamientoTotal_min, tiempoEnfriamientoTotal_max: data.tiempoEnfriamientoTotal_max,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax,
      velocidadRotacion_activo: data.velocidadRotacion_activo, velocidadRotacion_min: data.velocidadRotacion_min, velocidadRotacion_max: data.velocidadRotacion_max,
      posicionEje1_activo: data.posicionEje1_activo, posicionEje1_min: data.posicionEje1_min, posicionEje1_max: data.posicionEje1_max,
      posicionEje2_activo: data.posicionEje2_activo, posicionEje2_min: data.posicionEje2_min, posicionEje2_max: data.posicionEje2_max

      //#region VALORES TECNOLOGICOS
      , OP_activar_soplador_1: data.OP_activar_soplador_1
      , OP_activar_soplador_2: data.OP_activar_soplador_2
      , OP_activar_soplador_3: data.OP_activar_soplador_3
      , OP_activar_soplador_4: data.OP_activar_soplador_4
      , OP_activar_soplador_5: data.OP_activar_soplador_5
      , OP_activar_soplador_6: data.OP_activar_soplador_6
      , OP_activar_soplador_7: data.OP_activar_soplador_7
      , OP_activar_soplador_8: data.OP_activar_soplador_8
      , OP_activar_soplador_9: data.OP_activar_soplador_9
      , OP_activar_soplador_10: data.OP_activar_soplador_10
      , OP_activar_soplador_11: data.OP_activar_soplador_11
      , OP_activar_soplador_12: data.OP_activar_soplador_12
      , OP_activar_soplador_13: data.OP_activar_soplador_13
      , OP_activar_salida_cuba: data.OP_activar_salida_cuba
      , OP_control_paso_pieza: data.OP_control_paso_pieza
      , OP_rodillo_introductor: data.OP_rodillo_introductor
      , OP_giro_plato: data.OP_giro_plato
      , OP_control_energia_activado: data.OP_control_energia_activado
      , OP_control_caudal_activado: data.OP_control_caudal_activado
      , OP_control_temperatura_activado: data.OP_control_temperatura_activado
      , OP_control_presion_activado: data.OP_control_presion_activado
      , GEN_configuracion_seleccionada: data.GEN_configuracion_seleccionada
      , SP_vibrador_1: data.SP_vibrador_1
      , SP_mantenimiento_vibrador_2: data.SP_mantenimiento_vibrador_2
      , SP_lenta_vibrador_2: data.SP_lenta_vibrador_2
      , SP_recuperacion_vibrador_2: data.SP_recuperacion_vibrador_2
      , SP_vibrador_3: data.SP_vibrador_3
      , CV_cinta_salida: data.CV_cinta_salida
      , CV_proceso_giro: data.CV_proceso_giro
      , CV_rapida_giro: data.CV_rapida_giro
      , consigna_calor: data.consigna_calor
      , velocidad_rapida_subida_volteador: data.velocidad_rapida_subida_volteador
      , velocidad_lenta_subida_volteador: data.velocidad_lenta_subida_volteador
      , velocidad_bajada_volteador: data.velocidad_bajada_volteador
      , SP_consigna_velocidad: data.SP_consigna_velocidad
      , OP_piezas_por_giro: data.OP_piezas_por_giro
      , SP_presion_soplador_1: data.SP_presion_soplador_1
      , SP_presion_soplador_2: data.SP_presion_soplador_2
      , SP_presion_soplador_3: data.SP_presion_soplador_3
      , SP_presion_soplador_4: data.SP_presion_soplador_4
      , SP_presion_soplador_5: data.SP_presion_soplador_5
      , SP_presion_soplador_6: data.SP_presion_soplador_6
      , SP_presion_soplador_7: data.SP_presion_soplador_7
      , SP_presion_soplador_8: data.SP_presion_soplador_8
      , SP_presion_soplador_9: data.SP_presion_soplador_9
      , SP_presion_soplador_10: data.SP_presion_soplador_10
      , SP_presion_soplador_11: data.SP_presion_soplador_11
      , SP_presion_soplador_12: data.SP_presion_soplador_12
      , SP_presion_soplador_13: data.SP_presion_soplador_13
      , OP_sector_anchura_expulsor: data.OP_sector_anchura_expulsor
      , OP_sector_continuar_antes_parada_auto: data.OP_sector_continuar_antes_parada_auto
      , OP_sector_continuar_calentado_en_parada: data.OP_sector_continuar_calentado_en_parada
      , OP_sector_expulsion_defectuosos: data.OP_sector_expulsion_defectuosos
      , OP_activar_expulsor: data.OP_activar_expulsor
      , OP_Tmp_parada_atasco: data.OP_Tmp_parada_atasco
      , Tmp_vaciado_cinta_salida: data.Tmp_vaciado_cinta_salida
      , Tmp_falta_Material_vibrador_lineal: data.Tmp_falta_Material_vibrador_lineal
      , Tmp_parada_control_falta_material: data.Tmp_parada_control_falta_material
      , Tmp_recuperacion_falta_material_lineal: data.Tmp_recuperacion_falta_material_lineal
      , Tmp_filtro_defecto_material_vibrador_2: data.Tmp_filtro_defecto_material_vibrador_2
      , Tmp_presencia_material_vibrador_2: data.Tmp_presencia_material_vibrador_2
      , Tmp_peticion_pieza_vibrador_1: data.Tmp_peticion_pieza_vibrador_1
      , Tmp_presencia_pieza_vibrador_1: data.Tmp_presencia_pieza_vibrador_1
      , SP_expulsion_salida_cuba_rapida: data.SP_expulsion_salida_cuba_rapida
      , SP_expulsion_salida_cuba_lenta: data.SP_expulsion_salida_cuba_lenta
      , OP_TMP_salida_vibrador_cuba_saturada: data.OP_TMP_salida_vibrador_cuba_saturada
      , Tmp_vibrador_lineal_completo: data.Tmp_vibrador_lineal_completo
      , Tmp_def_material_m_alt_vibrador_lineal: data.Tmp_def_material_m_alt_vibrador_lineal
      , Tmp_pres_material_m_alt_vibrador_lineal: data.Tmp_pres_material_m_alt_vibrador_lineal
      , SP_salida_paso_pieza: data.SP_salida_paso_pieza
      , TMP_giro_plato_inicial_giro: data.TMP_giro_plato_inicial_giro
      , TMP_giro_plato_control: data.TMP_giro_plato_control
      , consigna_energia: data.consigna_energia
      , margen_energia: data.margen_energia
      , calculo_energia_pos: data.calculo_energia_pos
      , calculo_energia_neg: data.calculo_energia_neg
      , consigna_caudal_ducha: data.consigna_caudal_ducha
      , margen_caudal_ducha: data.margen_caudal_ducha
      , calculo_caudal_ducha_pos: data.calculo_caudal_ducha_pos
      , calculo_caudal_ducha_neg: data.calculo_caudal_ducha_neg
      , consigna_temperatura_ducha: data.consigna_temperatura_ducha
      , margen_temperatura_ducha: data.margen_temperatura_ducha
      , calculo_temperatura_ducha_pos: data.calculo_temperatura_ducha_pos
      , calculo_temperatura_ducha_neg: data.calculo_temperatura_ducha_neg
      , consigna_presion_ducha: data.consigna_presion_ducha
      , margen_presion_ducha: data.margen_presion_ducha
      , calculo_presion_ducha: data.calculo_presion_ducha
      , calculo_presion_ducha_neg: data.calculo_presion_ducha_neg

      , ip: data.ip, puerto: data.puerto, tiempoRecogidaDatos: data.tiempoSincrSeg, linkCamara: data.urlCamara, puestoOffline: data.maquinaPuestoOffline
      //#endregion
    }, { withCredentials: true });
  }

  updateAditivo(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {

    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    return this.http.post<any>(baseUrl + "/editarAditivo", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      idSeccion: data.idSeccion.id, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura
      , agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
    }, { withCredentials: true });
  }

  updateHornoTemple(data, idMarca, idControl, idProtocolo, dibujoMaquinaId) {
    if (data.tiempoMicroParada == null) data.tiempoMicroParada = 0;
    if (data.tiempoMicroEjecucion == null) data.tiempoMicroEjecucion = 0;

    if (data.freq_captura_datos == null) data.freq_captura_datos = 0;

    return this.http.post<any>(baseUrl + "/editarHornoTemple", {
      id: data.id, modelo: data.nombre, numSerie: data.numSerie, nmodelo: data.numeroModelo, orden: data.ordenModelo, activo: data.activo, marca: idMarca, control: idControl, protocolo: idProtocolo,
      idTimezone: data.idTimezone.id, desvioTimezone: data.idTimezone.desvioHoras, timeZone: data.idTimezone.timeZoneJS, timeZoneSQL: data.idTimezone.timeZoneSQL,
      idSeccion: data.idSeccion.id, tiempoMicroParada: data.tiempoMicroParada, tiempoMicroEjecucion: data.tiempoMicroEjecucion, dibujoMaquinaId,
      freq_captura_datos: data.freq_captura_datos,
      nombreArchivo: data.nombreArchivo, archivoBase64: data.archivoBase64, abreviatura: data.abreviatura, agruparProcesos: data.agruparProcesos, capacidadPesoMin: data.capacidadPesoMin, capacidadPesoMax: data.capacidadPesoMax, capacidadUnidadesMin: data.capacidadUnidadesMin, capacidadUnidadesMax: data.capacidadUnidadesMax
      , temperaturaProgramada_activo: data.temperaturaProgramada_activo
      , temperaturaActual_activo: data.temperaturaActual_activo
      , consumo_activo: data.consumo_activo
      , nivelO2_activo: data.nivelO2_activo
      , temperaturaInterior_activo: data.temperaturaInterior_activo
      , potencialCarbono_activo: data.potencialCarbono_activo
      , temperaturaProgramada_min: data.temperaturaProgramada_min
      , temperaturaActual_min: data.temperaturaActual_min
      , consumo_min: data.consumo_min
      , nivelO2_min: data.nivelO2_min
      , temperaturaInterior_min: data.temperaturaInterior_min
      , potencialCarbono_min: data.potencialCarbono_min
      , temperaturaProgramada_max: data.temperaturaProgramada_max
      , temperaturaActual_max: data.temperaturaActual_max
      , consumo_max: data.consumo_max
      , nivelO2_max: data.nivelO2_max
      , temperaturaInterior_max: data.temperaturaInterior_max
      , potencialCarbono_max: data.potencialCarbono_max

      , ritmo: data.ritmo, activarRitmo: data.activarRitmo
      , ip: data.ip, puerto: data.puerto, tiempoRecogidaDatos: data.tiempoSincrSeg, linkCamara: data.urlCamara, puestoOffline: data.maquinaPuestoOffline
    }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  public GetAll_Ejes(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/ejes/" + idMaquina);
  }

  public GetAll_AlarmasControladas(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/alarmasControladas/" + idMaquina);
  }

  public GetAll_AlarmasPasivas(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/alarmasPasivas/" + idMaquina);
  }

  public GetAll_Mantenimiento(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/mantenimiento/" + idMaquina);
  }

  public GetAll_Mantenimiento_DAT(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/mantenimientodat/" + idMaquina);
  }

  public GetAll_Mantenimientos(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/mantenimientos/" + idMaquina);
  }

  public GetAll_Manuales(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/manuales/" + idMaquina);
  }

  public GetAll_ManualesCarpetas(): Observable<any> {
    return this.http.get(baseUrl + "/manualesCarpetas");
  }



  public GetAll_Capas(idMaquina): Observable<any> {
    return this.http.get(baseUrl + "/capas/" + idMaquina);
  }

  public GetCapaById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/capa/" + id);
  }

  public insertCapa(data) {

    if (data.presion_min == null) data.presion_min = 0;
    if (data.presion_max == null) data.presion_max = 0;
    if (data.rendimientoTasaDeTransporte_min == null) data.rendimientoTasaDeTransporte_min = 0;
    if (data.rendimientoTasaDeTransporte_max == null) data.rendimientoTasaDeTransporte_max = 0;
    if (data.revolucionesMotor_min == null) data.revolucionesMotor_min = 0;
    if (data.revolucionesMotor_max == null) data.revolucionesMotor_max = 0;
    if (data.cargaMotor_min == null) data.cargaMotor_min = 0;
    if (data.cargaMotor_max == null) data.cargaMotor_max = 0;

    return this.http.post<any>(baseUrl + "/insertCapa", {
      id: data.id, idMaquina: data.idMaquina, nombre: data.nombre,
      presion_activo: data.presion_activo, presion_min: data.presion_min, presion_max: data.presion_max,
      rendimientoTasaDeTransporte_activo: data.rendimientoTasaDeTransporte_activo, rendimientoTasaDeTransporte_min: data.rendimientoTasaDeTransporte_min, rendimientoTasaDeTransporte_max: data.rendimientoTasaDeTransporte_max,
      revolucionesMotor_activo: data.revolucionesMotor_activo, revolucionesMotor_min: data.revolucionesMotor_min, revolucionesMotor_max: data.revolucionesMotor_max,
      cargaMotor_activo: data.cargaMotor_activo, cargaMotor_min: data.cargaMotor_min, cargaMotor_max: data.cargaMotor_max
    }, { withCredentials: true });
  }

  public updateCapa(data) {

    if (data.presion_min == null) data.presion_min = 0;
    if (data.presion_max == null) data.presion_max = 0;
    if (data.rendimientoTasaDeTransporte_min == null) data.rendimientoTasaDeTransporte_min = 0;
    if (data.rendimientoTasaDeTransporte_max == null) data.rendimientoTasaDeTransporte_max = 0;
    if (data.revolucionesMotor_min == null) data.revolucionesMotor_min = 0;
    if (data.revolucionesMotor_max == null) data.revolucionesMotor_max = 0;
    if (data.cargaMotor_min == null) data.cargaMotor_min = 0;
    if (data.cargaMotor_max == null) data.cargaMotor_max = 0;

    return this.http.post<any>(baseUrl + "/updateCapa", {
      id: data.id, idMaquina: data.idMaquina, nombre: data.nombre,
      presion_activo: data.presion_activo, presion_min: data.presion_min, presion_max: data.presion_max,
      rendimientoTasaDeTransporte_activo: data.rendimientoTasaDeTransporte_activo, rendimientoTasaDeTransporte_min: data.rendimientoTasaDeTransporte_min, rendimientoTasaDeTransporte_max: data.rendimientoTasaDeTransporte_max,
      revolucionesMotor_activo: data.revolucionesMotor_activo, revolucionesMotor_min: data.revolucionesMotor_min, revolucionesMotor_max: data.revolucionesMotor_max,
      cargaMotor_activo: data.cargaMotor_activo, cargaMotor_min: data.cargaMotor_min, cargaMotor_max: data.cargaMotor_max
    }, { withCredentials: true });
  }

  public Importar_Manual(data, idMaquina): Observable<any> {
    return this.http.post<any>(baseUrl + "/imagen/importar/" + idMaquina, data, { withCredentials: true });
  }

  public Get_ConfiguracionHMI(id: number, idMaquina: number): Observable<any> {
    return this.http.post(baseUrl + "/configuracionhmi", { id, idMaquina }, { withCredentials: true });
  }

  public Insert_ConfiguracionHMI(data, idMaquina) {
    return this.http.post<any>(baseUrl + "/configuracionhmi/crear", {
      idMaquina: idMaquina, menuInicio: data.hmiInicio, menuFueraTaller: data.hmiFueraTaller, menuCambioMonitor: data.hmiCambioMonitor,
      menuMes: data.hmiMes, menuHerramientas: data.hmiHerramientas, menuMantenimiento: data.hmiMantenimiento2, menuManualUsuario: data.hmiManualUsuario, menuVisualizadorPlanos: data.hmiVisualizadorDePlanos,
      menuLibrerias: data.hmiLibrerias, menuAutodiagnostico: data.hmiAutodiagYCompens, roturaHerramienta: data.hmiRoturaHerramienta, cambioPlaca: data.hmiCambioPlaca, progPlanificados: data.hmiProgPlanificados,
      herramientaPlanificada: data.hmiHerramPlanificada, mantenimiento: data.hmiMantenimiento, preparacion: data.hmiPreparacion, alarma: data.hmiAlarma, ejecucion: data.hmiEjecucion
      , controlRepeticiones: data.controlRepeticiones, asignarOperaciones: data.asignarOperaciones, verReportInciden: data.verReportInciden, perdidas: data.perdidas, calidad: data.calidad
      , herramientas: data.herramientas, cabezal: data.cabezal, gestorDocumental: data.gestorDocumental, camara: data.camara, observaciones: data.observaciones, escaneoDePegatinas: data.escaneoDePegatinas
    }, { withCredentials: true });
  }

  public Update_ConfiguracionHMI(data) {
    return this.http.post<any>(baseUrl + "/configuracionhmi/editar", {
      id: data.id, menuInicio: data.hmiInicio, menuFueraTaller: data.hmiFueraTaller, menuCambioMonitor: data.hmiCambioMonitor,
      menuMes: data.hmiMes, menuHerramientas: data.hmiHerramientas, menuMantenimiento: data.hmiMantenimiento2, menuManualUsuario: data.hmiManualUsuario, menuVisualizadorPlanos: data.hmiVisualizadorDePlanos,
      menuLibrerias: data.hmiLibrerias, menuAutodiagnostico: data.hmiAutodiagYCompens, roturaHerramienta: data.hmiRoturaHerramienta, cambioPlaca: data.hmiCambioPlaca, progPlanificados: data.hmiProgPlanificados,
      herramientaPlanificada: data.hmiHerramPlanificada, mantenimiento: data.hmiMantenimiento, preparacion: data.hmiPreparacion, alarma: data.hmiAlarma, ejecucion: data.hmiEjecucion
      , controlRepeticiones: data.controlRepeticiones, asignarOperaciones: data.asignarOperaciones, verReportInciden: data.verReportInciden, perdidas: data.perdidas, calidad: data.calidad
      , herramientas: data.herramientas, cabezal: data.cabezal, gestorDocumental: data.gestorDocumental, camara: data.camara, observaciones: data.observaciones, escaneoDePegatinas: data.escaneoDePegatinas
    }, { withCredentials: true });
  }

  //#region EJES
  public GetEjesDeMaquina(idMaquina): Observable<any> {
    return this.http.post(baseUrl + "/GetEjesDeMaquina", { idMaquina }, { withCredentials: true });
  }
  public Get_Count_Eje(idMaquina: number): Observable<any> {
    return this.http.post(baseUrl + "/counteje", { idMaquina }, { withCredentials: true });
  }

  public Get_Info_Eje(idEje: number): Observable<any> {
    return this.http.post(baseUrl + "/infoeje", { idEje }, { withCredentials: true });
  }

  public Get_Variables_Eje(idEje: number, idTipo: number): Observable<any> {
    return this.http.post(baseUrl + "/variableseje", { idEje, idTipo }, { withCredentials: true });
  }

  public Insert_Eje(data, idMaquina) {
    return this.http.post<any>(baseUrl + "/eje/crear", {
      IDmaquina: idMaquina, nombre: data.nombre, orden: data.orden, motor: data.motor, visible: data.visible,
      activo: data.activo, principal: data.principal, IDcanales: data.canal, ordenMaquina: data.posicionDelEjeEnLaMaquina,
      posConsumo1: data.posConsumo1, posConsumo2: data.posConsumo2, posTemperatura1: data.posTemperatura1, posTemperatura2: data.posTemperatura2, posCanal: data.posCanal
    }, { withCredentials: true });
  }

  public Update_Eje(data, id, idMaquina) {
    return this.http.post<any>(baseUrl + "/eje/editar", {
      id: id, IDmaquina: idMaquina, nombre: data.nombre, orden: data.orden, motor: data.motor, visible: data.visible,
      activo: data.activo, principal: data.principal, IDcanales: data.canal, ordenMaquina: data.posicionDelEjeEnLaMaquina,
      posConsumo1: data.posConsumo1, posConsumo2: data.posConsumo2, posTemperatura1: data.posTemperatura1, posTemperatura2: data.posTemperatura2, posCanal: data.posCanal
    }, { withCredentials: true });
  }

  public Insert_Variables_Eje(idEje, idTipo, minimo, cambioColor1, cambioColor2, cambioColor3, cambioColor4, maximo, visible, activo) {
    return this.http.post<any>(baseUrl + "/variableseje/crear", { idEje: idEje, idTipo: idTipo, minimo: minimo, cambioColor1: cambioColor1, cambioColor2: cambioColor2, cambioColor3: cambioColor3, cambioColor4: cambioColor4, maximo: maximo, visible: visible, activo: activo }, { withCredentials: true });
  }

  public Update_Variables_Eje(idVariable, minimo, cambioColor1, cambioColor2, cambioColor3, cambioColor4, maximo, visible) {
    return this.http.post<any>(baseUrl + "/variableseje/editar", { id: idVariable, minimo: minimo, cambioColor1: cambioColor1, cambioColor2: cambioColor2, cambioColor3: cambioColor3, cambioColor4: cambioColor4, maximo: maximo, visible: visible }, { withCredentials: true });
  }

  public Update_Variables_Eje_No_Activo(idVariable) {
    return this.http.post<any>(baseUrl + "/variableseje/noactivo", { id: idVariable }, { withCredentials: true });
  }
  //#endregion

  //#region ALARMA CONTROLADA
  public Get_Control_Alarma(idControlAlarma: number): Observable<any> {
    return this.http.post(baseUrl + "/controlalarma", { idControlAlarma }, { withCredentials: true });
  }

  public Insert_Control_Alarma(data, idMaquina, idAlarma, idAccion, idAlarmaRepercuteEn, alarmasDentroDeOperacion) {
      return this.http.post<any>(baseUrl + "/controlalarma/crear", {
      codcontrol: idMaquina, descripcion: data.descripcion, textobusqueda: data.textoBusqueda, textolineaant: "", alarma: 1, fichero: 1, accion: idAccion, idTipoAlarma: idAlarma,
      alarmaRepercuteEn: idAlarmaRepercuteEn, alarmasDentroDeOperacion: alarmasDentroDeOperacion
    }, { withCredentials: true });
  }

  public Update_Control_Alarma(data, id, idMaquina, idAlarma, idAccion, idAlarmaRepercuteEn, alarmasDentroDeOperacion) {
    return this.http.post<any>(baseUrl + "/controlalarma/editar", {
      id: id, codcontrol: idMaquina, descripcion: data.descripcion, textobusqueda: data.textoBusqueda,
      textolineaant: "", alarma: 1, fichero: 1, accion: idAccion, idTipoAlarma: idAlarma, alarmaRepercuteEn: idAlarmaRepercuteEn, alarmasDentroDeOperacion: alarmasDentroDeOperacion
    }, { withCredentials: true });
  }

  public Delete_Control_Alarma(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/controlalarma/eliminar", formData, { withCredentials: true });
  }
  //#endregion

  //#region ALARMA PASIVA
  public Get_Alarma_Pasiva(idAlarmaPasiva: number): Observable<any> {
    return this.http.post(baseUrl + "/alarmapasiva", { idAlarmaPasiva }, { withCredentials: true });
  }

  public Insert_Alarma_Pasiva(data, idMaquina, idTipoLeve, idTipoGrave) {
    return this.http.post<any>(baseUrl + "/alarmapasiva/crear", { idMaquina: idMaquina, tiempoPrograma: data.tiempoProgramaHastaMin, Aviso: data.alarmaLeve, idTipoAviso: idTipoLeve, Parada: data.alarmaGrave, idTipoParada: idTipoGrave }, { withCredentials: true });
  }

  public Update_Alarma_Pasiva(data, id, idMaquina, idTipoLeve, idTipoGrave) {
    return this.http.post<any>(baseUrl + "/alarmapasiva/editar", { id: id, idMaquina: idMaquina, tiempoPrograma: data.tiempoProgramaHastaMin, Aviso: data.alarmaLeve, idTipoAviso: idTipoLeve, Parada: data.alarmaGrave, idTipoParada: idTipoGrave }, { withCredentials: true });
  }

  public Delete_Alarma_Pasiva(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/alarmapasiva/eliminar", formData, { withCredentials: true });
  }
  //#endregion

  //#region MANTENIMIENTO
  public Get_Mantenimiento(idMantenimiento: number): Observable<any> {
    return this.http.post(baseUrl + "/mantenimiento", { idMantenimiento }, { withCredentials: true });
  }
  public Get_Mantenimientos(idMantenimiento: number): Observable<any> {
    return this.http.post(baseUrl + "/mantenimiento", { idMantenimiento }, { withCredentials: true });
  }
  public Get_Mantenimiento_predefinido(idMaquina: number): Observable<any> {
    return this.http.post(baseUrl + "/mantenimiento/predefinidos/" + idMaquina, {}, { withCredentials: true });
  }

  public Insert_Mantenimiento(data, idMaquina, idPerdida, idTipo, idTipoTiempoFecha, filename, fichero, isLink, sTiempo, tiempoEstimado) {
    debugger
    return this.http.post<any>(baseUrl + "/mantenimiento/crear", {
      idMaquina: idMaquina, text: data.tareaMantenimiento, pdf: filename, idTipoPerdida: idPerdida, idTipo: idTipo,
      idTipoTiempoFecha: idTipoTiempoFecha, frecuenciaMantenimientos: data.frecuencia, dia: data.dia,
      hora: data.hora, link: data.link, nombre: data.nombre, archivo: fichero, isLink: isLink,
      tiempo: sTiempo,
      contarDesdeUltimo: data.contarDesdeUltimo,
      contarEjecucion: data.contarEjecucion,
      contarParada: data.contarParada,
      contarPreparacion: data.contarPreparacion,
      contarMantenimiento: data.contarMantenimiento,
      contarAlarma: data.contarAlarma,
      contarApagada: data.contarApagada,
      tiempoEstimado: tiempoEstimado,
      nombreRuta: data.nombreRuta,
      esDirectorio: data.esDirectorio,
      turnos: data.turnos
    }, { withCredentials: true });
  }

  public Insert_Mantenimiento_Predifinido(idMaquina, idMantenimientos) {
    return this.http.post<any>(baseUrl + "/mantenimiento/predifinido/crear", { idMaquina: idMaquina, idMantenimientos: idMantenimientos }, { withCredentials: true });
  }

  public Update_Mantenimiento(data, id, idPerdida, idTipo, idTipoTiempoFecha, filename, borrarDoc, fichero, isLink, sTiempo, tiempoEstimado, idMaquina) {
    borrarDoc = (borrarDoc) ? 1 : 0;
    return this.http.post<any>(baseUrl + "/mantenimiento/editar", {
      id: id, text: data.tareaMantenimiento, pdf: filename, idTipoPerdida: idPerdida, idTipo: idTipo, frecuenciaMantenimientos: data.frecuencia, dia: data.dia,
      hora: data.hora, link: data.link, nombre: data.nombre, borrarDoc: borrarDoc, idTipoTiempoFecha: idTipoTiempoFecha,
      tiempo: sTiempo, archivo: fichero, isLink: isLink,
      contarDesdeUltimo: data.contarDesdeUltimo,
      contarEjecucion: data.contarEjecucion,
      contarParada: data.contarParada,
      contarPreparacion: data.contarPreparacion,
      contarMantenimiento: data.contarMantenimiento,
      contarAlarma: data.contarAlarma,
      contarApagada: data.contarApagada,
      tiempoEstimado: tiempoEstimado,
      nombreRuta: data.nombreRuta,
      esDirectorio: data.esDirectorio,
      turnos: data.turnos,
      idMaquina: idMaquina
    }, { withCredentials: true });
  }

  public Delete_Mantenimiento(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/mantenimiento/eliminar", formData, { withCredentials: true });
  }
  //#endregion

  //#region MANUAL
  public Get_Manual(idManual: number): Observable<any> {
    return this.http.post(baseUrl + "/manual/" + idManual, {}, { withCredentials: true });
  }

  public Insert_Manual(data, idMaquina, idTipo, idCarpeta) {
    var nombreArchivo = "";
    var archivoBase64 = "";
    if (data.activarLink) {
      nombreArchivo = data.link;
    } else {
      nombreArchivo = data.nombreArchivo;
      archivoBase64 = data.archivoBase64;
    }
    return this.http.post<any>(baseUrl + "/manual/crear", {
      idMaquina: idMaquina, texto: data.nombre, tipo: idTipo, esLink: data.activarLink,
      nombreArchivo: nombreArchivo, archivoBase64: archivoBase64, idCarpeta: idCarpeta, nombreRuta: data.nombreRuta, esDir: data.esDirectorio
    }, { withCredentials: true });
  }

  public Update_Manual(data, id, idMaquina, idTipo, idCarpeta) {
    var nombreArchivo = "";
    var archivoBase64 = "";
    if (data.activarLink) {
      nombreArchivo = data.link;
    } else {
      nombreArchivo = data.nombreArchivo;
      archivoBase64 = data.archivoBase64;
    }
    return this.http.post<any>(baseUrl + "/manual/editar", {
      id: id, idMaquina: idMaquina, texto: data.nombre, tipo: idTipo, esLink: data.activarLink,
      nombreArchivo: nombreArchivo, archivoBase64: archivoBase64, idCarpeta: idCarpeta, nombreRuta: data.nombreRuta, esDir: data.esDirectorio
    }, { withCredentials: true });
  }

  public Delete_Manual(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);
    formData.append("manuales", data.manuales);
    formData.append("idMaquina", data.idMaquina);

    return this.http.post<JSON>(baseUrl + "/manual/eliminar", formData, { withCredentials: true });
  }

  public Get_ManualCarpeta(idCarpeta: number): Observable<any> {
    return this.http.post(baseUrl + "/carpeta/" + idCarpeta, {}, { withCredentials: true });
  }

  public Insert_ManualCarpeta(data) {

    return this.http.post<any>(baseUrl + "/carpeta/crear", {
      nombre: data.nombre,
    }, { withCredentials: true });
  }

  public Update_ManualCarpeta(data, id, idMaquina) {
    return this.http.post<any>(baseUrl + "/carpeta/editar", {
      id: id, idMaquina: idMaquina, nombre: data.nombre
    }, { withCredentials: true });
  }

  public Delete_ManualCarpeta(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);
    return this.http.post<JSON>(baseUrl + "/carpeta/eliminar", formData, { withCredentials: true });
  }


  //#endregion


  public guardarPrioridadMaquina(idof, idpieza, idparte, idruta, idoperacion, idmaquina, prioridad) {

    return this.http.post<any>(baseUrl + "/guardarPrioridadMaquina", { idof: idof, idpieza: idpieza, idparte: idparte, idruta: idruta, idoperacion: idoperacion, idmaquina: idmaquina, prioridad: prioridad }, { withCredentials: true });

  }



  public EliminarPrioridadMaquina(idof, idpieza, idparte, idruta, idoperacion, idmaquina) {

    return this.http.post<any>(baseUrl + "/eliminarPrioridadMaquina", { idof: idof, idpieza: idpieza, idparte: idparte, idruta: idruta, idoperacion: idoperacion, idmaquina: idmaquina }, { withCredentials: true });

  }

  //#region GRUPOS MAQUINAS
  getGruposMaquinas(): Observable<any> {
    return this.http.get(baseUrl + "/getGruposMaquinas", { withCredentials: true });
  }
  GetGruposMaquinasConSeccion(): Observable<any> {
    return this.http.get(baseUrl + "/GetGruposMaquinasConSeccion", { withCredentials: true });
  }
  getGruposMaquinasTodos(): Observable<any> {
    return this.http.get(baseUrl + "/getGruposMaquinasTodos", { withCredentials: true });
  }

  getGrupoMaquinasById(idGrupo: string): Observable<any> {
    return this.http.get(`${baseUrl}/getGrupoMaquinasById/${idGrupo}`, { withCredentials: true });
  }

  createGrupoMaquinas(params) {
    return this.http.post(baseUrl + "/createGrupoMaquinas", params);
  }

  updateGrupoMaquinas(params) {
    return this.http.post(baseUrl + "/updateGrupoMaquinas", params);
  }

  deleteGrupoMaquinas(params) {
    return this.http.post(baseUrl + "/deleteGrupoMaquinas", params);
  }

  public GetAll_Protocolos_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/protocolos");
  }

  //#endregion
  getGruposMaquinasPlanificador(): Observable<any> {
    return this.http.get(baseUrl + "/getGruposMaquinasPlanificador", { withCredentials: true });
  }
  getGruposMaquinasPlanificadorTodos(): Observable<any> {
    return this.http.get(baseUrl + "/getGruposMaquinasPlanificadorTodos", { withCredentials: true });
  }
  getGrupoMaquinasPlanificadorById(idGrupo: string): Observable<any> {
    return this.http.get(`${baseUrl}/getGrupoMaquinasPlanificadorById/${idGrupo}`, { withCredentials: true });
  }

  getGrupoMaquinas_MaquinasPlanificadorById(idGrupo: string): Observable<any> {
    return this.http.get(`${baseUrl}/getGrupoMaquinas_MaquinasPlanificadorById/${idGrupo}`, { withCredentials: true });
  }

  createGrupoMaquinasPlanificador(params) {// como se han a�adido subcontratas, estas se tratan igual que las maquinas en el grupo de maquinas planificador. Por eso se tratan en esta misma llamada
    return this.http.post(baseUrl + "/createGrupoMaquinasPlanificador", params);
  }

  updateGrupoMaquinasPlanificador(params) {// como se han a�adido subcontratas, estas se tratan igual que las maquinas en el grupo de maquinas planificador. Por eso se tratan en esta misma llamada
    return this.http.post(baseUrl + "/updateGrupoMaquinasPlanificador", params);
  }

  deleteGrupoMaquinasPlanificador(params) {
    return this.http.post(baseUrl + "/deleteGrupoMaquinasPlanificador", params);
  }



  //#region SUBESTADOS
  public Get_Procesos_Tipo_subEstados(): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_Procesos_Tipo_subEstados`, {}, { withCredentials: true });
  }
  public Get_Procesos_Tipo_subEstados_disponibles_porMaquina(idMaquina, id): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_Procesos_Tipo_subEstados_disponibles_porMaquina`, { idMaquina: idMaquina, id: id }, { withCredentials: true });
  }
  public Get_subestado(id: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_subestado`, { id: id }, { withCredentials: true });
  }
  public Get_subestados(idMaquina: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_subestados`, { idMaquina: idMaquina }, { withCredentials: true });
  }
  public Get_subestados_activos(idMaquina: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_subestados_activos`, { idMaquina: idMaquina }, { withCredentials: true });
  }
  public Get_subestados_maxOrden(idMaquina: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Get_subestados_maxOrden`, { idMaquina: idMaquina }, { withCredentials: true });
  }
  public Insert_subestados(idMaquina: number
    , idProcesos_Tipo_subEstados: number
    , idMaquinas_subEstados: number
    , orden: number
    , tiempoPerdida: number
    , activo: boolean
    , bloqueado: boolean
    , tiempoCambioDeEstado: number
    , idSiguienteEstado: number): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/Insert_subestados`, {
      idMaquina: idMaquina
      , idProcesos_Tipo_subEstados: idProcesos_Tipo_subEstados
      , idMaquinas_subEstados: idMaquinas_subEstados
      , orden: orden
      , tiempoPerdida: tiempoPerdida
      , activo: activo
      , bloqueado: bloqueado
      , tiempoCambioDeEstado: tiempoCambioDeEstado
      , idSiguienteEstado: idSiguienteEstado
    }, { withCredentials: true });
  }
  public Update_subestados(id: number
    , idMaquina: number
    , idProcesos_Tipo_subEstados: number
    , idMaquinas_subEstados: number
    , orden: number
    , tiempoPerdida: number
    , activo: boolean
    , bloqueado: boolean
    , tiempoCambioDeEstado: number
    , idSiguienteEstado: number) {
    console.log(activo)
    return this.http.post<JSON>(`${baseUrl}/Update_subestados`, {
      id: id
      , idMaquina: idMaquina
      , idProcesos_Tipo_subEstados: idProcesos_Tipo_subEstados
      , idMaquinas_subEstados: idMaquinas_subEstados
      , orden: orden
      , tiempoPerdida: tiempoPerdida
      , activo: activo
      , bloqueado: bloqueado
      , tiempoCambioDeEstado: tiempoCambioDeEstado
      , idSiguienteEstado: idSiguienteEstado
    }, { withCredentials: true });
  }


  public Update_color_subEstado(id: number, color: string) {
    return this.http.post<JSON>(`${baseUrl}/Update_color_subEstado`, {
      id: id
      , color: color
    }, { withCredentials: true });
  }

  public Delete_subestados(ids: number[], idMaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/Delete_subestados`, { ids, idMaquina }, { withCredentials: true });
  }
  //#End region

  public insertCompatibilidadesBulk(dt) {
    return this.http.post<any>(baseUrl + "/insertCompatibilidadesBulk", { dt }, { withCredentials: true });
  }

  public deleteCompatibilidades(listaIds) {
    return this.http.post<any>(baseUrl + "/deleteCompatibilidades", { ids: listaIds }, { withCredentials: true });
  }

  public updateCompatibilidades(dt) {
    return this.http.post<any>(baseUrl + "/updateCompatibilidades", { dt }, { withCredentials: true });
  }

  public GetMaquinasInstalacionesTurnos() {
    return this.http.post<JSON>(`${baseUrl}/GetMaquinasInstalacionesTurnos`, {}, { withCredentials: true });
  }

  public SaberSiEsMaquinaOInstalacion(id: string): Observable<any> {
    return this.http.get(`${baseUrl}/SaberSiEsMaquinaOInstalacion/${id}`, { withCredentials: true });
  }

  public Get_maquinas_instalaciones_seccion() {
    return this.http.post<JSON>(`${baseUrl}/Get_maquinas_instalaciones_seccion`, {}, { withCredentials: true });
  }

  public Insert_Clasificaciones(idMaquina, idClasificaciones) {

    return this.http.post<any>(baseUrl + "/insert_clasificaciones", {
      idMaquina: idMaquina, idClasificaciones: idClasificaciones
    }, { withCredentials: true });
  }

  public Insert_Atributos(idMaquina, idAtributos) {

    return this.http.post<any>(baseUrl + "/insert_atributos", {
      idMaquina: idMaquina, idAtributos: idAtributos
    }, { withCredentials: true });
  }

  public getMaquinasAgruparProcesos() {
    return this.http.post<JSON>(`${baseUrl}/getMaquinasAgruparProcesos`, { withCredentials: true });
  }

  public getHomeSeleccionById(id, idTipo_Maquina, tipoAditivo = -1) {
    return this.http.post<JSON>(`${baseUrl}/getHomeSeleccionById`, { id, idTipo_Maquina, tipoAditivo }, { withCredentials: true });
  }

  public actualizarHomeSeleccion(idMaquina, idHomeSeleccionIzq, idHomeSeleccionDer
    , idEje1, idVariable_Tipo1, isGeneral1
    , idEje2, idVariable_Tipo2, isGeneral2
    , idEje3, idVariable_Tipo3, isGeneral3
    , idEje4, idVariable_Tipo4, isGeneral4) {
    return this.http.post<JSON>(`${baseUrl}/actualizarHomeSeleccion`, {
      idMaquina, idHomeSeleccionIzq, idHomeSeleccionDer
      , idEje1, idVariable_Tipo1, isGeneral1
      , idEje2, idVariable_Tipo2, isGeneral2
      , idEje3, idVariable_Tipo3, isGeneral3
      , idEje4, idVariable_Tipo4, isGeneral4
    }, { withCredentials: true });
  }
  
  public actualizarMaquinasContadorConfig(idMaquina, id_contador_tipo_DAT, mostrarSumatorio, mostrarSumatorio_param1_id_contador_tipo_DAT, mostrarSumatorio_param2_id_contador_tipo_DAT
    , id_contador_turno_tipo_DAT, mostrarAutomatico, cantidadesTerminadasEnPreparacion) {
    return this.http.post<JSON>(`${baseUrl}/Actualizar_Maquinas_Contador_Config`, {
      idMaquina, id_contador_tipo_DAT, mostrarSumatorio, mostrarSumatorio_param1_id_contador_tipo_DAT, mostrarSumatorio_param2_id_contador_tipo_DAT
    , id_contador_turno_tipo_DAT, mostrarAutomatico, cantidadesTerminadasEnPreparacion
    }, { withCredentials: true });
  }
  public getSecciones() {
    return this.http.post<JSON>(`${baseUrl}/getSecciones`, { withCredentials: true });
  }

  public getGruposMaquinasGrupos() {
    return this.http.post<JSON>(`${baseUrl}/getGruposMaquinasGrupos`, { withCredentials: true });
  }

  public getTemperaturas_rangos(idMaquina) {
    return this.http.post<JSON>(`${baseUrl}/Get_temperaturas_rangos`, { idMaquina }, { withCredentials: true });
  }

  public updateTemperaturas_rangos(temperaturaRangos) {
    return this.http.post<JSON>(`${baseUrl}/Update_temperaturas_rangos`, { temperaturaRangos }, { withCredentials: true });
  }
  public Get_Maquinas_Consumos() {
    return this.http.post<JSON>(`${baseUrl}/Get_Maquinas_Consumos`, {}, { withCredentials: true });
  }
  public Get_Instalaciones_Consumos() {
    return this.http.post<JSON>(`${baseUrl}/Get_Instalaciones_Consumos`, {}, { withCredentials: true });
  }
}