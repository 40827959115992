
export class TipoUsuario {
  id: number;
  nombre: string;
  gestionUsuarios: number;
  home: number;
  validador: number;
  proyectos: number;
  proyectosH: number;
  operacionesPredefinidas: number;
  piezasPredefinidas: number;
  seguimiento: number;
  controlDeAsignaciones: number;
  historicoPiezas: number;
  planificador: number;
  vistoLargoMaquina: number;
  vistoLargoTodos: number;
  planificadorCorto: number;
  informes: number;
  analisisDeRendimiento: number;
  comparativaRendimiento: number;
  informeEjecuciones: number;
  informeTecnicoEjecucion: number;
  informeDeConsumo: number;
  oEE: number;
  informeOEE: number;
  informeDisponibilidad: number;
  informeRendimiento: number;
  informeCalidad: number;
  planesDeAccion: number;
  planesDeAccionH: number;
  maquinas: number;
  perdidas: number;
  disponibilidad: number;
  informesIncidencias: number;
  calendarioDisponibilidad: number;
  informeCalculoDisponibilidad: number;
  ficheros: number;
  administracion: number;
  configuracion: number;
  activos: number;
  activosMaestro: number;
  alarmasTipo: number;
  almacenes: number;
  analiticaAvanzadaEjecuciones: number;
  areaProductiva: number;
  asignacionTiempos: number;
  bonos: number;
  clientes: number;
  comparativaTiempos: number;
  consumibles: number;
  controles: number;
  diccionarioPerdidas: number;
  turnos: number;
  fabricantes: number;
  herramientas: number; 
  placas: number;
  historicoMantenimientos: number;
  historicoOperaciones: number;
  historicoProcesos: number;
  historicoParametros: number;
  instalaciones: number;
  mantenimientosPredefinidos: number;
  marcas: number;
  materiales: number;
  operaciones: number;
  operacionesTipo: number;
  piezas: number;
  piezasTipo: number;
  planificadorHerramientas: number;
  planificadorLargo: number;
  planificadorLista : number;
  plantasIsometrico : number;
  profiles : number;
  proveedores : number;
  OperacionesFinalizadas : number;
  rutas : number;
  rutasPredefinidas : number;
  secciones : number;
  stock : number;
  subcontratado : number;
  activosTipos : number;
  tolerancias : number;
  recetas : number;
  usuarios : number;
  calidad : number;


  constructor(id: number,
    nombre: string,
    gestionUsuarios: number,
   home: number,
   validador: number,
   proyectos: number,
   proyectosH: number,
   operacionesPredefinidas: number,
   piezasPredefinidas: number,
   seguimiento: number,
   controlDeAsignaciones: number,
   historicoPiezas: number,
   planificador: number,
   vistoLargoMaquina: number,
   vistoLargoTodos: number,
   planificadorCorto: number,
   informes: number,
   analisisDeRendimiento: number,
   comparativaRendimiento: number,
   informeEjecuciones: number,
   informeTecnicoEjecucion: number,
   informeDeConsumo: number,
   oEE: number,
   informeOEE: number,
   informeDisponibilidad: number,
   informeRendimiento: number,
   informeCalidad: number,
   planesDeAccion: number,
   planesDeAccionH: number,
   maquinas: number,
   perdidas: number,
   disponibilidad: number,
   informesIncidencias: number,
   calendarioDisponibilidad: number,
   informeCalculoDisponibilidad: number,
   ficheros: number,
   administracion: number,
   configuracion: number,
   activos: number,
  activosMaestro: number,
  alarmasTipo: number,
  almacenes: number,
  analiticaAvanzadaEjecuciones: number,
  areaProductiva: number,
  asignacionTiempos: number,
  bonos: number,
  clientes: number,
  comparativaTiempos: number,
  consumibles: number,
  controles: number,
  diccionarioPerdidas: number,
  turnos: number,
  fabricantes: number,
  herramientas: number, 
  placas: number,
  historicoMantenimientos: number,
  historicoOperaciones: number,
  historicoProcesos: number,
  historicoParametros: number,
  instalaciones: number,
  mantenimientosPredefinidos: number,
  marcas: number,
  materiales: number,
  operaciones: number,
  operacionesTipo: number,
  piezas: number,
  piezasTipo: number,
  planificadorHerramientas: number,
  planificadorLargo: number,
  planificadorLista : number,
  plantasIsometrico : number,
  profiles : number,
  proveedores : number,
  OperacionesFinalizadas : number,
  rutas : number,
  rutasPredefinidas : number,
  secciones : number,
  stock : number,
  subcontratado : number,
  activosTipos : number,
  tolerancias : number,
  recetas : number,
  usuarios : number,
  calidad : number

  ) {
    this.id = id;
    this.nombre = nombre;
    this.gestionUsuarios = gestionUsuarios;
    this.home = home;
    this.validador = validador;
    this.proyectos = proyectos;
    this.proyectosH = proyectosH;
    this.operacionesPredefinidas = operacionesPredefinidas;
    this.piezasPredefinidas = piezasPredefinidas;
    this.seguimiento = seguimiento;
    this.controlDeAsignaciones = controlDeAsignaciones;
    this.historicoPiezas = historicoPiezas;
    this.planificador = planificador;
    this.vistoLargoMaquina = vistoLargoMaquina;
    this.vistoLargoTodos = vistoLargoTodos;
    this.planificadorCorto = planificadorCorto;
    this.informes = informes;
    this.analisisDeRendimiento = analisisDeRendimiento;
    this.comparativaRendimiento = comparativaRendimiento;
    this.informeEjecuciones = informeEjecuciones;
    this.informeTecnicoEjecucion = informeTecnicoEjecucion;
    this.informeDeConsumo = informeDeConsumo;
    this.oEE = oEE;
    this.informeOEE = informeOEE;
    this.informeDisponibilidad = informeDisponibilidad;
    this.informeRendimiento =  informeRendimiento;
    this.informeCalidad = informeCalidad;
    this.planesDeAccion = planesDeAccion
    this.planesDeAccionH = planesDeAccionH
    this.maquinas = maquinas;
    this.perdidas = perdidas;
    this.disponibilidad = disponibilidad;
    this.informesIncidencias = informes;
    this.calendarioDisponibilidad = calendarioDisponibilidad;
    this.informeCalculoDisponibilidad = informeCalculoDisponibilidad;
    this.ficheros = ficheros;
    this.administracion = administracion;
    this.configuracion = configuracion;
    this.activos = activos;
    this.activosMaestro  = activosMaestro;
    this.alarmasTipo = alarmasTipo;
    this.almacenes = almacenes;
    this.analiticaAvanzadaEjecuciones=analiticaAvanzadaEjecuciones;
    this.areaProductiva = areaProductiva;
    this.asignacionTiempos = asignacionTiempos;
    this.bonos = bonos;
    this.clientes = clientes;
    this.comparativaTiempos = comparativaTiempos;
    this.consumibles = consumibles;
    this.controles = controles;
    this.diccionarioPerdidas = diccionarioPerdidas;
    this.turnos = turnos;
    this.fabricantes = fabricantes;
    this.herramientas= herramientas; 
    this.placas = placas;
    this.historicoMantenimientos= historicoMantenimientos;
    this.historicoOperaciones = historicoOperaciones;
    this.historicoProcesos = historicoProcesos;
    this.historicoParametros=historicoParametros;
    this.instalaciones = instalaciones;
    this.mantenimientosPredefinidos=mantenimientosPredefinidos;
    this.marcas=marcas;
    this.materiales=materiales;
    this.operaciones=operaciones;
    this.operacionesTipo=operacionesTipo;

    this.piezas=piezas;
    this.piezasTipo=piezasTipo;
    this.planificadorHerramientas=planificadorHerramientas;
    this.planificadorLargo=planificadorLargo;
    this.planificadorLista=planificadorLista;
    this.plantasIsometrico=plantasIsometrico;
    this.profiles=profiles;
    this.proveedores=proveedores;
    this.OperacionesFinalizadas=OperacionesFinalizadas;

    this.rutas=rutas;
    this.rutasPredefinidas=rutasPredefinidas;
    this.secciones= secciones;
    this.stock =stock;
    this.subcontratado =subcontratado;
    this.activosTipos=activosTipos ;
    this.tolerancias=tolerancias ;
    this.recetas=recetas ;
    this.usuarios=usuarios ;
    this.calidad=calidad ;
  }
}
