import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MenuService, MaquinasService, UsuariosService, DisponibilidadService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';

import { GroupResult, groupBy } from '@progress/kendo-data-query';

import { MyFunctions } from '@app/_helpers';

interface ItemCombo {
  nombre: string,
  id: number
}

@Component({
  selector: 'app-informe-calculo-disponibilidad',
  templateUrl: 'informeCalculoDisponibilidad.component.html'
})

export class InformeCalculoDisponibilidadComponent {

  public kendoGridData;

  public user = this.userService.userValue;
  //public jsonMaquinas: any;
  public form: FormGroup;
  public ano: number = 0;
  public meses: Array<ItemCombo> = [];
  public meses2: Array<ItemCombo> = [];
  public meses_selectedItem: ItemCombo;
  public loading: boolean = false;
  public loadingIncid: boolean = false;

  public min = 2021;
  public max = 2055;
  public value = 2021;

  // Variables auxiliares globales para calcular horas totales por mes y horas totales de todo el informe
  public totalInac: number = 0;
  public totalAct: number = 0;
  public totalInfAct: number = 0;
  public totalInfInac: number = 0;

  public cambioMaqMesAno: boolean = false; // Flag para abortar la actual ejecución de la creación del informe, se evalua a verdadero cuando se cambia máquina, mes o año

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  public Jmaquinas: any;
  public JmaquinasMostradas: any;
  public selectedMaquina: number = 0;

  public aparecer = false;

  constructor(
    private disponibilidadService: DisponibilidadService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private router: Router,
    private menuService: MenuService,
    public myFunctions: MyFunctions,
    private modalService: NgbModal
  ) {

    this.menuService.titulo = this.translateService.instant('informeCalculoDisponibilidad').toUpperCase();

    //#region FILTRO
    //maquinas
    //var maquinas_model = this.maquinasService.get_maquinas_model();
    //if (maquinas_model == false) {
    //  this.maquinasService.get().subscribe(json => {
    //    this.maquinasService.set_maquinas_model(json);
    //    this.Jmaquinas = this.maquinasService.get_maquinas_model();
    //    this.cargarMaquinas();
    //  })
    //} else {
    //  this.Jmaquinas = maquinas_model;
    //  this.cargarMaquinas();
    //}

    //meses
    this.cargarMeses();
    //#endregion


    this.cargarGrupos();
    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
        });
    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
    }

    //MAQUINAS 
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.Jmaquinas = this.maquinasService.get_maquinas_model();

        //FLTRO POR SECCIONES
        var idsSeccionesSelecteds: any = [];
        if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
          this.seccionesSeleccionadas.forEach(
            seccion => {
              idsSeccionesSelecteds.push(seccion.id);
            });
        } else {
          this.secciones.forEach(
            seccion => {
              idsSeccionesSelecteds.push(seccion.id);
            });
        }
        this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

        if (this.selectedMaquina == 0)
          this.selectedMaquina = this.JmaquinasMostradas[0].id;
        this.cargarInforme();
        //this.cargarMaquinas(); //AHORA SE USA NGFOR!
      })
    } else {
      this.Jmaquinas = maquinas_model;

      //FLTRO POR SECCIONES
      var idsSeccionesSelecteds: any = [];
      if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
        this.seccionesSeleccionadas.forEach(
          seccion => {
            idsSeccionesSelecteds.push(seccion.id);
          });
      } else {
        this.secciones.forEach(
          seccion => {
            idsSeccionesSelecteds.push(seccion.id);
          });
      }
      this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

      if (this.selectedMaquina == 0)
        this.selectedMaquina = this.JmaquinasMostradas[0].id;

      this.cargarInforme();
    }
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
    });

  }
  seccionChanged() {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    } else {
      this.grupos.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }

    this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))))

  }
  ngOnInit() {
    this.kendoGridData = [
      { numSemana: 'horasDisponibles', lunes: 'lunes', martes: 'martes', miercoles: 'miercoles', jueves: 'jueves', viernes: 'viernes', sabado: 'sabado', domingo: 'domingo' },
      { numSemana: 'horasInactivas', lunes: 'lunes', martes: 'martes', miercoles: 'miercoles', jueves: 'jueves', viernes: 'viernes', sabado: 'sabado', domingo: 'domingo' },
      { numSemana: 'totalHorasInactivasPorSemana', jueves: 'jueves' },
      { numSemana: 'totalHorasDisponiblesPorSemana', jueves: 'jueves' },
    ]

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idMaquina: ['',],
      ano: [(this.myFunctions.getDateNow()).getFullYear(),],
      mes: ['',],
      inversion: ['',],
      numeroInventario: [,],
      disponibilidadPorcentajeContratoCompra: ['',],
      mesAno: ['',],
      projectManager: ['',],
      fechaFirma: [this.myFunctions.getDateNow(),],
      mesesPorInforme: ['',]
    });

    /* 
                    response.idMaquina, response.ano, response.mes, response.numeroInventario,
                    response.disponibilidadPorcentajeContratoCompra, response.mesAno, response.projectManager, response.fechaFirma);
    */

  }

  //#region FILTRO
  //#region maquinas
  //public async cargarMaquinas() {
  //  //===============================================================
  //  //== por cada maquina se añade uun elemento al div de maquinas ==
  //  //===============================================================

  //  //CUANDO CARGA LAS MAQUINAS DEL SERVICE LAS CARGA TAN RAPIDO QUE EL DIV AUN NO SE HA CREADO EN EL HTML, POR ESO SE LE PONE UN TIMEOUT HASTA QUE EL DIV EXISTA
  //  while (document.getElementById("divMaquinas_informeConsumo") == null) {
  //    await new Promise(r => setTimeout(r, 100));
  //  }

  //  var primera = true;
  //  for (let maquina of this.Jmaquinas) {
  //    var divMaquina = document.createElement("div"); //crear el div
  //    divMaquina.setAttribute("class", 'clickable'); //añadirle un atributo. Ej: clase

  //    if (primera) {
  //      divMaquina.setAttribute("class", 'clickable actual'); //añadirle un atributo. Ej: clase
  //      this.selectedMaquina = maquina.id;
  //      primera = false;
  //    }

  //    divMaquina.addEventListener('click', (self) => this.maquinaClicked(maquina.id, self)); //añadirle el evento de click con sus parametros (se puede meter el propio control! (como sender) ej: this.maquinaClicked(divMaquina))
  //    divMaquina.id = maquina.id + '_maquina' //añadirle un ID al elemento // En el viejo eris se necesitaba este nombre + '~/idcontent/' + '6' + '/modelos/' + maquina.imagen

  //    var labelMaquina = document.createElement("label"); //se crea el label que tendra el nombre de la maquina
  //    labelMaquina.innerText = maquina.nombre + '';//se le añade el texto que se enseñara al "boton" //le sumo '' para convertirlo en String

  //    var imageMaquina = document.createElement("img"); //se crea la imagen que tendra el control
  //    imageMaquina.setAttribute("src", maquina.imagenBase64); //se añade un src a la imagen

  //    divMaquina.appendChild(labelMaquina); //se añade el label al div
  //    divMaquina.appendChild(imageMaquina); //se añade la imagen al div
  //    document.getElementById("divMaquinas_informeConsumo").appendChild(divMaquina); //se añade el div donde queramos
  //  };

  //  this.cargarInforme();
  //}

  private maquinaClicked(idMaquina, sender) {
    //this.cambioMaqMesAno = true;
    //document.getElementsByClassName("actual")[0].classList.toggle("actual");
    //if (sender.path[0].toString().includes("object HTMLDivElement")) {
    //  sender.path[0].setAttribute("class", 'clickable actual');
    //} else {
    //  sender.path[1].setAttribute("class", 'clickable actual');
    //}
    //this.selectedMaquina = idMaquina;

    ////this.cargarInforme();
    this.cargarInforme();
  }
  //#endregion

  //#region ano
  public ano_onValueChange(event) {
    if(event!=(new Date()).getFullYear()){
      this.meses = [
        { nombre: this.translateService.instant("enero"), id: 0 },
        { nombre: this.translateService.instant("febrero"), id: 1 },
        { nombre: this.translateService.instant("marzo"), id: 2 },
        { nombre: this.translateService.instant("abril"), id: 3 },
        { nombre: this.translateService.instant("mayo"), id: 4 },
        { nombre: this.translateService.instant("junio"), id: 5 },
        { nombre: this.translateService.instant("julio"), id: 6 },
        { nombre: this.translateService.instant("agosto"), id: 7 },
        { nombre: this.translateService.instant("septiembre"), id: 8 },
        { nombre: this.translateService.instant("octubre"), id: 9 },
        { nombre: this.translateService.instant("noviembre"), id: 10 },
        { nombre: this.translateService.instant("diciembre"), id: 11 }
      ];
    }else{
      var fecha = new Date();
      switch(fecha.getMonth()){
        case 0:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 }
          ];
          break;
        }
        case 1:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 }
          ];
          break;
        }
        case 2:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 }
          ];
          break;
        }
        case 3:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 }
          ];
          break;
        }
        case 4:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 }
          ];
          break;
        }
        case 5: {
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 }
          ];
          break;
        }
        case 6:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 },
            { nombre: this.translateService.instant("julio"), id: 6 }
          ];
          break;
        }
        case 7:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 },
            { nombre: this.translateService.instant("julio"), id: 6 },
            { nombre: this.translateService.instant("agosto"), id: 7 }
          ];
          break;
        }
        case 8:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 },
            { nombre: this.translateService.instant("julio"), id: 6 },
            { nombre: this.translateService.instant("agosto"), id: 7 },
            { nombre: this.translateService.instant("septiembre"), id: 8 }
          ];
          break;
        }
        case 9:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 },
            { nombre: this.translateService.instant("julio"), id: 6 },
            { nombre: this.translateService.instant("agosto"), id: 7 },
            { nombre: this.translateService.instant("septiembre"), id: 8 },
            { nombre: this.translateService.instant("octubre"), id: 9 }
          ];
          break;
        }
        case 10:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 },
            { nombre: this.translateService.instant("julio"), id: 6 },
            { nombre: this.translateService.instant("agosto"), id: 7 },
            { nombre: this.translateService.instant("septiembre"), id: 8 },
            { nombre: this.translateService.instant("octubre"), id: 9 },
            { nombre: this.translateService.instant("noviembre"), id: 10 }
          ];
          break;
        }
        case 11:{
          this.meses = [
            { nombre: this.translateService.instant("enero"), id: 0 },
            { nombre: this.translateService.instant("febrero"), id: 1 },
            { nombre: this.translateService.instant("marzo"), id: 2 },
            { nombre: this.translateService.instant("abril"), id: 3 },
            { nombre: this.translateService.instant("mayo"), id: 4 },
            { nombre: this.translateService.instant("junio"), id: 5 },
            { nombre: this.translateService.instant("julio"), id: 6 },
            { nombre: this.translateService.instant("agosto"), id: 7 },
            { nombre: this.translateService.instant("septiembre"), id: 8 },
            { nombre: this.translateService.instant("octubre"), id: 9 },
            { nombre: this.translateService.instant("noviembre"), id: 10 },
            { nombre: this.translateService.instant("diciembre"), id: 11 }
          ];
          break;
        }
      }
    }
    this.cambioMaqMesAno = true;
    //this.cargarInforme();
  }
  //#endregion

  //#region meses
  private cargarMeses() {
    var fecha = new Date();
    switch(fecha.getMonth()){
      case 0:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 }
        ];
        break;
      }
      case 1:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 }
        ];
        break;
      }
      case 2:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 }
        ];
        break;
      }
      case 3:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 }
        ];
        break;
      }
      case 4:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 }
        ];
        break;
      }
      case 5: {
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 }
        ];
        break;
      }
      case 6:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 },
          { nombre: this.translateService.instant("julio"), id: 6 }
        ];
        break;
      }
      case 7:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 },
          { nombre: this.translateService.instant("julio"), id: 6 },
          { nombre: this.translateService.instant("agosto"), id: 7 }
        ];
        break;
      }
      case 8:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 },
          { nombre: this.translateService.instant("julio"), id: 6 },
          { nombre: this.translateService.instant("agosto"), id: 7 },
          { nombre: this.translateService.instant("septiembre"), id: 8 }
        ];
        break;
      }
      case 9:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 },
          { nombre: this.translateService.instant("julio"), id: 6 },
          { nombre: this.translateService.instant("agosto"), id: 7 },
          { nombre: this.translateService.instant("septiembre"), id: 8 },
          { nombre: this.translateService.instant("octubre"), id: 9 }
        ];
        break;
      }
      case 10:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 },
          { nombre: this.translateService.instant("julio"), id: 6 },
          { nombre: this.translateService.instant("agosto"), id: 7 },
          { nombre: this.translateService.instant("septiembre"), id: 8 },
          { nombre: this.translateService.instant("octubre"), id: 9 },
          { nombre: this.translateService.instant("noviembre"), id: 10 }
        ];
        break;
      }
      case 11:{
        this.meses = [
          { nombre: this.translateService.instant("enero"), id: 0 },
          { nombre: this.translateService.instant("febrero"), id: 1 },
          { nombre: this.translateService.instant("marzo"), id: 2 },
          { nombre: this.translateService.instant("abril"), id: 3 },
          { nombre: this.translateService.instant("mayo"), id: 4 },
          { nombre: this.translateService.instant("junio"), id: 5 },
          { nombre: this.translateService.instant("julio"), id: 6 },
          { nombre: this.translateService.instant("agosto"), id: 7 },
          { nombre: this.translateService.instant("septiembre"), id: 8 },
          { nombre: this.translateService.instant("octubre"), id: 9 },
          { nombre: this.translateService.instant("noviembre"), id: 10 },
          { nombre: this.translateService.instant("diciembre"), id: 11 }
        ];
        break;
      }
    }
    this.meses2 = [
      { nombre: this.translateService.instant("enero"), id: 0 },
      { nombre: this.translateService.instant("febrero"), id: 1 },
      { nombre: this.translateService.instant("marzo"), id: 2 },
      { nombre: this.translateService.instant("abril"), id: 3 },
      { nombre: this.translateService.instant("mayo"), id: 4 },
      { nombre: this.translateService.instant("junio"), id: 5 },
      { nombre: this.translateService.instant("julio"), id: 6 },
      { nombre: this.translateService.instant("agosto"), id: 7 },
      { nombre: this.translateService.instant("septiembre"), id: 8 },
      { nombre: this.translateService.instant("octubre"), id: 9 },
      { nombre: this.translateService.instant("noviembre"), id: 10 },
      { nombre: this.translateService.instant("diciembre"), id: 11 }
    ];

    var auxi = (this.myFunctions.getDateNow()).getMonth();
    this.meses_selectedItem = this.meses[auxi];
  }

  public meses_selectionChange(event) {
    this.cambioMaqMesAno = true;
    this.meses_selectedItem = event;
    //this.cargarInforme();
  }
  //#endregion
  //#endregion

  //#region CONTENIDO
  public async cargarInforme() {

    var ano: number = this.form.value.ano;
    var mes: number = this.meses_selectedItem.id+1;
    var mesFinal: number = 0;
    var mesesPorInforme: number = 0;

    this.ano = ano;
    this.totalAct = 0;
    this.totalInac = 0;
    this.totalInfAct = 0;
    this.totalInfInac = 0;

    this.disponibilidadService.Get_Configuracion().subscribe(async (result) => {

      this.form.controls['disponibilidadPorcentajeContratoCompra'].setValue((Math.round(result[0].porcentajeDisponibilidadContratoCompra * 100) / 100).toFixed(2) + " % ");
      this.form.controls['mesAno'].setValue(this.meses_selectedItem.nombre + ' ' + ano + ' (' + result[0].mesesPorInforme + ' ' + this.translateService.instant('mesesPorInforme').toLowerCase() + ')');
      this.form.controls['mesesPorInforme'].setValue(result[0].mesesPorInforme);
      this.form.controls['idMaquina'].setValue(this.selectedMaquina);
      this.form.controls['mes'].setValue(mes);

      mesesPorInforme = result[0].mesesPorInforme;
      mesFinal = mes + mesesPorInforme - 1;


      document.getElementById("divInforme").innerHTML = '';

      this.loadingIncid = true; // Deshabilitamos el botón mientras se carga el informe y contemplamos que no habrá cambio en los parámetros
      this.cambioMaqMesAno = false;
      this.disponibilidadService.Get_CalculoDispInac_Informe(ano, mes, mesesPorInforme, this.selectedMaquina).subscribe((result) => {
        
        if (!result.error) {
          var mesActual = result.data[0].mes; // Asignamos el primer mes
          for (var i = 0; i < result.data.length; i++) {
            if (this.cambioMaqMesAno) {
              this.cambioMaqMesAno = false;
              this.loadingIncid = false; // Rehabilitamos el botón
              return;
            }

            this.crearEstructuraSemana(Number(result.data[i].mes - 1), Number(result.data[i].numSemana), Number(result.data[i].lunesDisponibles), Number(result.data[i].martesDisponibles),
              Number(result.data[i].miercolesDisponibles), Number(result.data[i].juevesDisponibles), Number(result.data[i].viernesDisponibles), Number(result.data[i].sabadoDisponibles),
              Number(result.data[i].domingoDisponibles), Number(result.data[i].lunesInactivas), Number(result.data[i].martesInactivas), Number(result.data[i].miercolesInactivas),
              Number(result.data[i].juevesInactivas), Number(result.data[i].viernesInactivas), Number(result.data[i].sabadoInactivas), Number(result.data[i].domingoInactivas));

            // Colocamos la fórmula del mes actual y actualizamos el mes actual en caso de que en el siguiente registro cambie
            if (i + 1 < result.data.length && result.data[i + 1].mes > mesActual) {
              var divTotalMesFormula = document.createElement("div");
              this.colocaFormula(divTotalMesFormula, this.totalAct, this.totalInac, mesActual - 1);
              document.getElementById("divInforme").appendChild(divTotalMesFormula);

              // Sumamos las horas disponibles/inactivas totales de los meses para obtener el total
              this.totalInfAct += this.totalAct;
              this.totalInfInac += this.totalInac;

              this.totalAct = 0;
              this.totalInac = 0;
              mesActual = result.data[i + 1].mes;

            }
          }

          // Ponemos la fórmula del último mes del informe (o la única si solo fue un informe de un mes)
          var divTotalMesFormula = document.createElement("div");
          this.colocaFormula(divTotalMesFormula, this.totalAct, this.totalInac, mesActual - 1);
          document.getElementById("divInforme").appendChild(divTotalMesFormula);

          // Sumamos las horas del último mes al total
          this.totalInfAct += this.totalAct;
          this.totalInfInac += this.totalInac;

          if (mesesPorInforme > 1) {
            // Colocamos la fórmula final del informe
            var divTotalFormula = document.createElement("div");
            this.colocaFormula(divTotalFormula, this.totalInfAct, this.totalInfInac, mes - 1);
            document.getElementById("divInforme").appendChild(divTotalFormula);
          }

          this.loadingIncid = false; // Rehabilitamos el botón
          this.cambioMaqMesAno = false;
        }
      });
    });
  }

  private crearEstructuraSemana(numMes, numSemana,
    lunesDisponibles, martesDisponibles, miercolesDisponibles, juevesDisponibles, viernesDisponibles, sabadoDisponibles, domingoDisponibles,
    lunesInactivas, martesInactivas, miercolesInactivas, juevesInactivas, viernesInactivas, sabadoInactivas, domingoInactivas) {

    // Estilos
    var numSemanaEstilo = 'text-align: center;';
    var diasEstilo = 'text-align: center; padding: 6pt;';
    var diasSemanaEstilo = 'text-align: center; padding: 4pt;';
    var horasColEstilo = 'padding: 6pt;';
    var horasEstilo = 'text-align: center;'

    var divSemana = document.createElement("div"); //crear div; divSemana
    divSemana.style.cssText = 'padding-bottom: 1%;';

    var tableSemana = document.createElement("table"); //crear table; tableSemana
    tableSemana.id = "tablaSemana_" + numMes + numSemana;

    tableSemana.setAttribute("class", 'semanaDisponibilidad table table-bordered table-hover'); //añadir clase a tableSemana; semanaDisponibilidad

    var trCabecera1 = document.createElement("tr"); //crear tr; trCabecera1

    var thNumSemana = document.createElement("th"); //crear th; thNumSemana
    thNumSemana.rowSpan = 2; //combinar 2 lineas
    thNumSemana.style.cssText = numSemanaEstilo;
    thNumSemana.innerText = this.translateService.instant('numSemana') + ": " + numSemana; //añadir texto a thNumSemana
    trCabecera1.appendChild(thNumSemana);//añadir th; thNumSemana -> trCabecera1

    var thDiasSemana = document.createElement("th"); //crear th; thDiasSemana
    thDiasSemana.colSpan = 7; //combinar 7 columnas
    thDiasSemana.innerText = this.translateService.instant('diasSemana'); //añadir texto a thDiasSemana
    thDiasSemana.style.cssText = diasSemanaEstilo;
    trCabecera1.appendChild(thDiasSemana);//añadir th; thDiasSemana -> trCabecera1

    tableSemana.appendChild(trCabecera1);//añadir tr; trCabecera1 -> tableSemana

    var trCabecera2 = document.createElement("tr"); //crear tr; trCabecera2

    var thLunes = document.createElement("th"); //crear th; thLunes
    thLunes.innerText = this.translateService.instant('lunes'); //añadir texto a thLunes
    thLunes.style.cssText = diasEstilo;
    trCabecera2.appendChild(thLunes);//añadir th; thLunes -> trCabecera2

    var thMartes = document.createElement("th"); //crear th; thMartes
    thMartes.innerText = this.translateService.instant('martes') //añadir texto a thMartes
    thMartes.style.cssText = diasEstilo;
    trCabecera2.appendChild(thMartes);//añadir th; thMartes -> trCabecera2

    var thMiercoles = document.createElement("th"); //crear th; thMiercoles
    thMiercoles.innerText = this.translateService.instant('miercoles') //añadir texto a thMiercoles
    thMiercoles.style.cssText = diasEstilo;
    trCabecera2.appendChild(thMiercoles);//añadir th; thMiercoles -> trCabecera2

    var thJueves = document.createElement("th"); //crear th; thJueves
    thJueves.innerText = this.translateService.instant('jueves') //añadir texto a thJueves
    thJueves.style.cssText = diasEstilo;
    trCabecera2.appendChild(thJueves);//añadir th; thJueves -> trCabecera2

    var thViernes = document.createElement("th"); //crear th; thViernes
    thViernes.innerText = this.translateService.instant('viernes') //añadir texto a thViernes
    thViernes.style.cssText = diasEstilo;
    trCabecera2.appendChild(thViernes);//añadir th; thViernes -> trCabecera2

    var thSabado = document.createElement("th"); //crear th; thSabado
    thSabado.innerText = this.translateService.instant('sabado') //añadir texto a thSabado
    thSabado.style.cssText = diasEstilo;
    trCabecera2.appendChild(thSabado);//añadir th; thSabado -> trCabecera2

    var thDomingo = document.createElement("th"); //crear th; thDomingo
    thDomingo.innerText = this.translateService.instant('domingo') //añadir texto a thDomingo
    thDomingo.style.cssText = diasEstilo;
    trCabecera2.appendChild(thDomingo);//añadir th; thDomingo -> trCabecera2

    tableSemana.appendChild(trCabecera2);//añadir tr; trCabecera2 -> tableSemana

    var trContenido1 = document.createElement("tr"); //crear tr; trContenido1

    var td11 = document.createElement("td"); //crear td; td11
    td11.innerText = this.translateService.instant('horasDisponibles') //añadir texto a td11
    td11.style.cssText = horasColEstilo;
    trContenido1.appendChild(td11);//añadir th; td11 -> trContenido1

    var td12 = document.createElement("td"); //crear td; td12
    td12.innerText = String((lunesDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td12
    td12.style.cssText = horasEstilo;
    trContenido1.appendChild(td12);//añadir th; td12 -> trContenido1

    var td13 = document.createElement("td"); //crear td; td13
    td13.innerText = String((martesDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td13
    td13.style.cssText = horasEstilo;
    trContenido1.appendChild(td13);//añadir th; td13 -> trContenido1

    var td14 = document.createElement("td"); //crear td; td14
    td14.innerText = String((miercolesDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td14
    td14.style.cssText = horasEstilo;
    trContenido1.appendChild(td14);//añadir th; td14 -> trContenido1

    var td15 = document.createElement("td"); //crear td; td15
    td15.innerText = String((juevesDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td15
    td15.style.cssText = horasEstilo;
    trContenido1.appendChild(td15);//añadir th; td15 -> trContenido1

    var td16 = document.createElement("td"); //crear td; td16
    td16.innerText = String((viernesDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td16
    td16.style.cssText = horasEstilo;
    trContenido1.appendChild(td16);//añadir th; td16 -> trContenido1

    var td17 = document.createElement("td"); //crear td; td17
    td17.innerText = String((sabadoDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td17
    td17.style.cssText = horasEstilo;
    trContenido1.appendChild(td17);//añadir th; td17 -> trContenido1

    var td18 = document.createElement("td"); //crear td; td18
    td18.innerText = String((domingoDisponibles).toFixed(2)).replace('.', ','); //añadir texto a td18
    td18.style.cssText = horasEstilo;
    trContenido1.appendChild(td18);//añadir th; td18 -> trContenido1

    tableSemana.appendChild(trContenido1);//añadir tr; trContenido1 -> tableSemana

    var trContenido2 = document.createElement("tr"); //crear tr; trContenido2

    var td21 = document.createElement("td"); //crear td; td21
    td21.innerText = this.translateService.instant('horasInactivas') //añadir texto a td21
    td21.style.cssText = horasColEstilo;
    trContenido2.appendChild(td21);//añadir th; td21 -> trContenido2

    var td22 = document.createElement("td"); //crear td; td22
    td22.innerText = String((lunesInactivas).toFixed(2)).replace('.', ','); //añadir texto a td22
    td22.style.cssText = horasEstilo;
    trContenido2.appendChild(td22);//añadir th; td22 -> trContenido2

    var td23 = document.createElement("td"); //crear td; td23
    td23.innerText = String((martesInactivas).toFixed(2)).replace('.', ','); //añadir texto a td23
    td23.style.cssText = horasEstilo;
    trContenido2.appendChild(td23);//añadir th; td23 -> trContenido2

    var td24 = document.createElement("td"); //crear td; td24
    td24.innerText = String((miercolesInactivas).toFixed(2)).replace('.', ','); //añadir texto a td24
    td24.style.cssText = horasEstilo;
    trContenido2.appendChild(td24);//añadir th; td24 -> trContenido2

    var td25 = document.createElement("td"); //crear td; td25
    td25.innerText = String((juevesInactivas).toFixed(2)).replace('.', ','); //añadir texto a td25
    td25.style.cssText = horasEstilo;
    trContenido2.appendChild(td25);//añadir th; td25 -> trContenido2

    var td26 = document.createElement("td"); //crear td; td26
    td26.innerText = String((viernesInactivas).toFixed(2)).replace('.', ','); //añadir texto a td26
    td26.style.cssText = horasEstilo;
    trContenido2.appendChild(td26);//añadir th; td26 -> trContenido2

    var td27 = document.createElement("td"); //crear td; td27
    td27.innerText = String((sabadoInactivas).toFixed(2)).replace('.', ','); //añadir texto a td27
    td27.style.cssText = horasEstilo;
    trContenido2.appendChild(td27);//añadir th; td27 -> trContenido2

    var td28 = document.createElement("td"); //crear td; td28
    td28.innerText = String((domingoInactivas).toFixed(2)).replace('.', ','); //añadir texto a td28
    td28.style.cssText = horasEstilo;
    trContenido2.appendChild(td28);//añadir th; td28 -> trContenido2

    tableSemana.appendChild(trContenido2);//añadir tr; trContenido2 -> tableSemana

    var trContenido3 = document.createElement("tr"); //crear tr; trContenido3

    var td31 = document.createElement("td"); //crear td; td31
    td31.innerText = this.translateService.instant('totalHorasInactivasPorSemana') //añadir texto a td31
    td31.style.cssText = horasColEstilo;
    trContenido3.appendChild(td31);//añadir th; td31 -> trContenido3

    var td32 = document.createElement("td"); //crear td; td32
    td32.colSpan = 7; //combinar 7 columnas
    var totalInacSemana = lunesInactivas + martesInactivas + miercolesInactivas + juevesInactivas + viernesInactivas + sabadoInactivas + domingoInactivas;
    td32.innerText = String((totalInacSemana).toFixed(2)).replace('.', ','); //añadir texto a td32
    td32.style.cssText = horasEstilo;
    trContenido3.appendChild(td32);//añadir th; td32 -> trContenido3

    tableSemana.appendChild(trContenido3);//añadir tr; trContenido3 -> tableSemana

    var trContenido4 = document.createElement("tr"); //crear tr; trContenido4

    var td41 = document.createElement("td"); //crear td; td41
    td41.innerText = this.translateService.instant('totalHorasDisponiblesPorSemana') //añadir texto a td41
    td41.style.cssText = horasColEstilo;
    trContenido4.appendChild(td41);//añadir th; td41 -> trContenido4

    var td42 = document.createElement("td"); //crear td; td42
    td42.colSpan = 7; //combinar 7 columnas
    var totalActSemana = lunesDisponibles + martesDisponibles + miercolesDisponibles + juevesDisponibles + viernesDisponibles + sabadoDisponibles + domingoDisponibles;
    td42.innerText = String((totalActSemana).toFixed(2)).replace('.', ','); //añadir texto a td42
    td42.style.cssText = horasEstilo;
    trContenido4.appendChild(td42);//añadir th; td42 -> trContenido4

    tableSemana.appendChild(trContenido4);//añadir tr; trContenido4 -> tableSemana

    divSemana.appendChild(tableSemana);//añadir table; tableSemana -> divSemana

    this.totalAct += totalActSemana;
    this.totalInac += totalInacSemana;

    document.getElementById("divInforme").appendChild(divSemana); //añadir div; divSemana -> divInforme
  }

  private colocaFormula(divConForm: HTMLDivElement, totHorasDisp, totHorasInac, mes) {
    var divFormula = document.createElement("div");
    var subDiv = document.createElement("div");
    var subDiv1 = document.createElement("div");
    var subDiv2 = document.createElement("div");
    var subDiv3 = document.createElement("div");

    divFormula.style.cssText = 'padding-bottom: 2%;';

    // Total horas disponibles
    subDiv.innerHTML = '<label class="label-control"><b>' + this.translateService.instant('totalHorasDisponibles') + '</b></label><br>';
    subDiv.innerHTML += '<span>' + String((totHorasDisp).toFixed(2)).replace('.', ','); + '</span>';
    divFormula.appendChild(subDiv);

    // Technical downtime
    subDiv1.innerHTML = '<label class="label-control"><b>' + this.translateService.instant('mesInactividad') + ' '
      + this.meses2[mes].nombre + ' / ' + this.translateService.instant('año').toLowerCase() + ' ' + this.ano + ' ' + this.translateService.instant('acordeLog') + '</b></label><br>';
    subDiv1.innerHTML += '<span> TAT = ' + String((totHorasInac).toFixed(2)).replace('.', ',') + ' Std. </span>';
    divFormula.appendChild(subDiv1);

    // Determination of the technical rate
    subDiv2.innerHTML = '<label class="label-control"><b>' + this.translateService.instant('deTecnica') + '</b></label>';
    if (totHorasDisp > 0) { // Cuando el total de horas disponibles es 0, no habrá manera de determinar la valoración técnica
      subDiv2.innerHTML += '<div class="eq-c">' +
        'AT = 100 - <div class="frac"> <span>' + String((totHorasInac).toFixed(2)).replace('.', ',') + ' * 100%</span> <span class="symbol">/</span> <span class="bottom">' + String((totHorasDisp).toFixed(2)).replace('.', ',') + '</span> </div>' +
        ' = 100 - <div class="frac"> <span>' + String((totHorasInac * 100).toFixed(2)).replace('.', ',') + '</span> <span class="symbol">/</span> <span class="bottom">' + String((totHorasDisp).toFixed(2)).replace('.', ',') + '</span> </div>' +
        ' = 100 - ' + String(((totHorasInac * 100) / totHorasDisp).toFixed(2)).replace('.', ',') + ' = ' + String((100 - ((totHorasInac * 100) / totHorasDisp)).toFixed(2)).replace('.', ',') + ' </div>';
    } else {
      subDiv2.innerHTML += '<br><label class="label-control"><i>' + this.translateService.instant('deTecnicaSin') + '</i></label>';
    }
    divFormula.appendChild(subDiv2);

    // Note
    subDiv3.innerHTML = '<label class="label-control"><b>' + this.translateService.instant('notaTecnica') + '</b></label><br>';
    subDiv3.innerHTML += '<label class="label-control">' + this.translateService.instant('notaTecDetalle1') + '</label><br>';
    subDiv3.innerHTML += '<label class="label-control">' + this.translateService.instant('notaTecDetalle2') + '</label>';
    divFormula.appendChild(subDiv3);

    divConForm.appendChild(divFormula);
  }

  //getWeekNumber
  public getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
  //#endregion

  //FirstDateOfWeekISO8601
  public FirstDateOfWeekISO8601(year, weekOfYear) {
    /*
    // obtenemos el primer dia del año
    var primerdia = new Date(year, 0, 1);
    // obtenemos la corrección necesaria
    var correccion = 6 - primerdia.getDay();
    // obtenemos el lunes y domingo de la semana especificada
    return new Date(year, 0, (weekOfYear - 1) * 7 + 3 + correccion);
    */
    var simple = new Date(year, 0, 1 + (weekOfYear - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
  //#endregion

  //#region FORMATO FECHA
  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }
  //#endregion
  //#endregion

  // Parte imprimir
  onClickImprimir() {
    this.loading = true;
    this.disponibilidadService.Imprimir_Disponibilidad(this.form.value).subscribe(
      (result) => {
        if (!result.error) {
          var data = this.base64ToBlob(result.impreso); // Conversión de base 64 a blob
          var blob = new Blob([data], { type: 'application/pdf' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          var today = this.myFunctions.getDateNow()
          var date = "" + today.getFullYear() + (today.getMonth() + 1) + today.getDate();
          var time = "" + today.getHours() + today.getMinutes() + today.getSeconds();
          link.href = downloadURL;
          link.download = this.translateService.instant('informeCalculoDisponibilidad') + "_" + date + time;
          link.click();
          link.remove();
          this.loading = false;
        }
      });

  }

  base64ToBlob(base64str) {
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    return view;
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

}
