<!-- Datos bajo cabecera-->
<div class="card">
    <div class="card-body">
      <div class="clearfix">
        <div class="row">
          <div class="bloques-datos">
              <label>{{ 'of' | translate}}</label>
              <label #of></label>
          </div>
          <div class="bloques-datos">
            <label>{{ 'pieza' | translate}}</label>
            <label #pieza></label>
          </div>
          <div class="bloques-datos" >
            <label>{{ 'parte' | translate}}</label>
            <label #parte></label>
          </div>
          <div class="bloques-datos" >
            <label>{{ 'operacion' | translate}}</label>
            <label #operacion></label>
          </div>
          <div class="bloques-datos" >
            <button type="button" class="btn btn-info m-1 float-left" (click)="openSelectPiezaModal($event)">{{ 'selectPieza' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <form *ngIf="formInstantiated" [formGroup]="formIncidencia">
    <div>
            <!-- Datos incidencia -->
            <div class="card">
                <div class="card-header">
                    <h3>{{ 'datos' | translate}}</h3>
                </div>
                <div class="card-body">
                    <div class="clearfix">
                        <!-- <div class="col-md-2">
                            <kendo-label text="Id">
                                <kendo-textbox formControlName="id" [value]="id" disabled>
                                </kendo-textbox>
                            </kendo-label>
                        </div> -->
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'fecha' | translate }}">
                                <div class="form-group">
                                    <kendo-datetimepicker class="form-control"
                                        formControlName="fechaIncidencia" disabled >
                                    </kendo-datetimepicker>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'operario' | translate }}">
                                <kendo-textbox formControlName="operarioIncidencia" disabled>
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'afectaProduccion' | translate }}">
                                <div class="form-group">
                                    <kendo-switch [disabled]="bloqueado" [onLabel]="' '" [offLabel]="' '" formControlName="afectaProduccion">
                                    </kendo-switch>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="float-left mr-2">
                            <label kendoLabel>{{ 'causa' | translate }}</label>
                            <div class="form-check">
                                <input class="form-check-input" [attr.disabled]="bloqueado ? 'true' : null" type="radio" value="1" formControlName="causa"
                                    kendoRadioButton />
                                <label class="form-check-label">
                                    {{ 'operario' | translate }}
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="radio-inline">
                                    <input class="form-check-input" [attr.disabled]="bloqueado ? 'true' : null" type="radio" value="2" formControlName="causa"
                                        kendoRadioButton />
                                    <label class="form-check-label">
                                        {{ 'maquina' | translate }}
                                    </label>
                                </label>
                            </div>
                        </div>
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'cliente' | translate }}">
                                <kendo-textbox formControlName="cliente">
                                </kendo-textbox>
                            </kendo-label>
                        </div>
                    </div>

                    <div class="clearfix">
                        <div class="float-left mr-2">
                            <!--LISTA GRUPOS PERDIDAS-->
                            <div class="float-left mr-2">
                                <div class="form-group">
                                    <label>{{ 'perdidasGrupo' | translate }}</label>
                                    <div class="caja">
                                        <kendo-combobox #grupos clearButton="true" [disabled]="gruposIncidencias === undefined"
                                        [disabled]="bloquearGrupos" [kendoDropDownFilter]="{operator: 'contains'}"
                                            [data]="gruposIncidenciasView" (selectionChange)="onGruposIncidenciasChange(grupos,$event,'grupos')"
                                            [(value)]="gruposIncSeleccionado" [textField]="'nombre'"
                                            [valueField]="'id'" placeholder="{{ 'seleccionadGrupoIncidencia' | translate }}"
                                            [autoClose]="true" style="width: 295px;">
                                            <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                            </kendo-combobox-messages>
                                        </kendo-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="float-left mr-2">
                            <!--LISTA PERDIDAS-->
                            <div class="float-left mr-2">
                                <div class="form-group">
                                    <label>{{ 'perdidas' | translate }}</label>
                                    <div class="caja">
                                        <kendo-combobox #incidencias clearButton="true" (selectionChange)="onGruposIncidenciasChange(incidencias,$event, 'perdidas')" 
                                        [disabled]="bloquearPerdidas"[kendoDropDownFilter]="{operator: 'contains'}"
                                                [data]="tiposIncidenciaView"
                                            [(value)]="incidenciasSeleccionadas" [textField]="'nombrePerdida'"
                                            [valueField]="'idPerdida'" placeholder="{{ 'seleccionaIncidencia' | translate }}"
                                            [autoClose]="true" style="width: 295px;">
                                            <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                            </kendo-combobox-messages>
                                        </kendo-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="float-left mr-2">
                            <!--LISTA SUBPERDIDAS-->
                            <div class="float-left mr-2">
                                <div class="form-group">
                                    <label>{{ 'subperdidas' | translate }}</label>
                                    <div class="caja">
                                        <kendo-combobox #subincidencias clearButton="true" (selectionChange)="onGruposIncidenciasChange(subincidencias,$event,'subperdidas')" 
                                        [disabled]="bloquearSubPerdidas" [kendoDropDownFilter]="{operator: 'contains'}"
                                                [data]="tiposSubIncidenciasView"
                                            [(value)]="subincidenciasSeleccionadas" [textField]="'nombrePerdida'"
                                            [valueField]="'idPerdida'" placeholder="{{ 'seleccionaIncidencia' | translate }}"
                                            [autoClose]="true" style="width: 295px;">
                                            <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                            </kendo-combobox-messages>
                                        </kendo-combobox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="float-left mr-2">
                            <div class="nolabel">
                                <button class="btn btn-danger btn-sm mr-1" (click)="limpiarSeleccion()">{{ 'limpiarSeleccion' | translate}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix">
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'gravedad' | translate }}">
                                <div class="caja">
                                    <kendo-dropdownlist 
                                                [data]="tiposGravedad_DAT"
                                            [(value)]="tiposGravedad_Seleccionada" [textField]="'nombre'"
                                            [valueField]="'id'"
                                            [autoClose]="false" style="width: 295px;">
                                        </kendo-dropdownlist>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'seguimiento' | translate }}">
                                <div class="form-group">
                                    <kendo-switch [onLabel]="' '" [offLabel]="' '" formControlName="seguimiento" [(ngModel)]="seguimiento">
                                    </kendo-switch>
                                </div>
                            </kendo-label>
                        </div>
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'observacion' | translate }}">
                                <div class="form-group">
                                    <kendo-switch [onLabel]="' '" [offLabel]="' '" formControlName="observaciones" [(ngModel)]="observaciones">
                                    </kendo-switch>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="clearfix" *ngIf="observaciones">
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'antesInterrupcion' | translate }}">
                                <textarea kendoTextArea style="resize: none;" rows="5" formControlName="descripcion">
                                </textarea>
                            </kendo-label>
                        </div>
                        <div class="float-left mr-2">
                            <kendo-label text="{{ 'accionesRectificar' | translate }}">
                                <textarea kendoTextArea style="resize: none;" rows="5" formControlName="acciones">
                                </textarea>
                            </kendo-label>
                        </div>
                    </div>
                </div>
            </div>
    </div>

<!--     TABS -->
<div *ngIf="seguimiento">
    <kendo-tabstrip  tabPosition="top" [keepTabContent]="true" (tabSelect)="onTabSelected($event)">
        <kendo-tabstrip-tab  title="{{ 'accionesTomadas' | translate}}" [selected]="seguimiento">
          <ng-template kendoTabContent>
            <!-- Datos acciones tomadas -->
            <div class="">
                <div class="card-body">
                    <h3>{{ 'accionesTomadas' | translate}}</h3>
                </div>
                <div class="card-body">
                    <kendo-grid [kendoGridBinding]="incidenciasAcciones">
                        <ng-template kendoGridToolbarTemplate position="top">
                            <button (click)="onClickNuevaAcc()" class="btn btn-success btn-sm mr-1">{{ 'anadirAccion' |
                                translate}}</button>
                        </ng-template>
                        <!-- Accion -->
                        <kendo-grid-column field="accion" title="{{ 'accion' | translate}}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <input kendoTextBox type="text" id="accion_{{dataItem.id}}" [value]="dataItem.accion">
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Operario -->
                        <kendo-grid-column field="operario" title="{{ 'operario' | translate}}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <kendo-textbox *ngIf="dataItem.operario === null" disabled
                                    value="{{ 'desconocido' | translate}}">
                                </kendo-textbox>
                                <kendo-textbox *ngIf="dataItem.operario != null" disabled [value]="dataItem.operario">
                                </kendo-textbox>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Fecha -->
                        <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <kendo-datetimepicker class="form-control" disabled [value]="creaFecha(dataItem.fecha)"
                                    [format]="formatDate">
                                </kendo-datetimepicker>
                            </ng-template>
                        </kendo-grid-column>
                        <!--TRADUCCIÓN TEXTOS DEL GRID-->
                        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab *ngIf="!isAddMode" title="{{ 'archivosAdjuntos' | translate}}" [selected]="!seguimiento">
          <ng-template kendoTabContent>
            <!-- Datos archivos adjuntos -->
            <div class="">
                <div class="card-header">
                    <h3>{{ 'archivosAdjuntos' | translate}}</h3>
                </div>
                <div class="card-body">
                    <kendo-grid [kendoGridBinding]="incidenciasFicheros" [sortable]="true" [navigable]="true"
                        kendoGridSelectBy="id" [selectedKeys]="seleccionadosFicheros"
                        (cellClick)="cellClickArcAdj($event, contentFichero)">
                        <ng-template kendoGridToolbarTemplate position="top">
                            <button (click)="onClickNuevoArcAdj(contentFichero)" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' |
                                translate}}</button>
                            <button (click)="onClickEditarArcAdj(contentFichero)" class="btn btn-success btn-sm mr-1">{{ 'editar' |
                                translate}}</button>
                            <button (click)="onClickEliminarArcAdj(content)" class="btn btn-danger btn-sm mr-1"
                                [disabled]="isDeleting || this.user.informesIncidencias<2">{{ 'eliminar' | translate}}</button>
                        </ng-template>
                        <kendo-grid-checkbox-column width="40%" showSelectAll="true"></kendo-grid-checkbox-column>
                        <kendo-grid-column field="id" hidden="hidden" title="id"></kendo-grid-column>
                        <!-- Nombre -->
                        <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
                        <!-- Ruta -->
                        <kendo-grid-column field="ruta" title="{{ 'fichero' | translate}}"></kendo-grid-column>
                        <!-- Descarga -->
                        <kendo-grid-column  field="id" title="">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <i class="fa fa-file icono-ficheros-incidencias"></i>
                            </ng-template>
                        </kendo-grid-column>
                        <!--TRADUCCIÓN TEXTOS DEL GRID-->
                        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>    
</div>

<div *ngIf="!seguimiento">
    <kendo-tabstrip *ngIf="!isAddMode" tabPosition="top" [keepTabContent]="true" (tabSelect)="onTabSelected($event)">
        <kendo-tabstrip-tab title="{{ 'archivosAdjuntos' | translate}}" [selected]="!seguimiento">
          <ng-template kendoTabContent>
            <!-- Datos archivos adjuntos -->
            <div class="">
                <div class="card-header">
                    <h3>{{ 'archivosAdjuntos' | translate}}</h3>
                </div>
                <div class="card-body">
                    <kendo-grid [kendoGridBinding]="incidenciasFicheros" [sortable]="true" [navigable]="true"
                        kendoGridSelectBy="id" [selectedKeys]="seleccionadosFicheros"
                        (cellClick)="cellClickArcAdj($event, contentFichero)">
                        <ng-template kendoGridToolbarTemplate position="top">
                            <button (click)="onClickNuevoArcAdj(contentFichero)" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' |
                                translate}}</button>
                            <button (click)="onClickEditarArcAdj(contentFichero)" class="btn btn-success btn-sm mr-1">{{ 'editar' |
                                translate}}</button>
                            <button (click)="onClickEliminarArcAdj(content)" class="btn btn-danger btn-sm mr-1"
                            [disabled]="isDeleting || this.user.informesIncidencias<2">{{ 'eliminar' | translate}}</button>
                        </ng-template>
                        <kendo-grid-checkbox-column width="40%" showSelectAll="true"></kendo-grid-checkbox-column>
                        <kendo-grid-column field="id" hidden="hidden" title="id"></kendo-grid-column>
                        <!-- Nombre -->
                        <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
                        <!-- Ruta -->
                        <kendo-grid-column field="ruta" title="{{ 'fichero' | translate}}"></kendo-grid-column>
                        <!-- Descarga -->
                        <kendo-grid-column *ngIf="!isAddMode" field="id" title="">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <a *ngIf="dataItem.ruta != ''" [routerLink]=""
                                queryParamsHandling="preserve"
                                (click)="descargarFichero(dataItem.ruta)">{{ 'descargar' | translate}}</a>
                            </ng-template>
                        </kendo-grid-column>
                        <!--TRADUCCIÓN TEXTOS DEL GRID-->
                        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>    
</div>

    <div class="form-group text-center">
        <button kendoButton [disabled]="loading || this.user.informesIncidencias<2" (click)="onSubmit(afectaProdEm)" class="btn mr-1  btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            {{ 'guardar' | translate}}
        </button>
        <button kendoButton [disabled]="isAddMode || this.user.informesIncidencias<2" (click)="onCopiar()" class="btn mr-1  btn-secondary">
            {{ 'copiar' | translate}}
        </button>
        <button kendoButton *ngIf="!isAddMode"  (click)="onCerrarIncid()" [disabled]="isClosed || this.user.informesIncidencias<2"
            class="btn mr-1  btn-dark">
            {{ 'cerrarIncidencia' | translate}}
        </button>
        <button kendoButton [disabled]="isAddMode || !isClosed ||this.user.informesIncidencias<2" (click)="onReabrir()" 
         class="btn mr-1  btn-warning">
            {{ 'reabrir' | translate}}
        </button>
        <a [routerLink]="['/informesincidencias']" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
    </div>
</form>

<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'preguntaeliminarpopup' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' |
            translate }}</button>
        <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)" [disabled]="this.user.informesIncidencias<2">{{ 'si' | translate
            }}</button>
    </div>
</ng-template>

<ng-template #contentloading let-modal>
    <div class="modal-body">
        <p>{{ 'eliminando' | translate }}</p>
        <div class="spinner-border" role="status">
            <span class="sr-only">{{ 'eliminando' | translate }}...</span>
        </div>
    </div>
</ng-template>

<!-- POPUP AÑADIR / EDITAR FICHERO-->
<ng-template #contentFichero let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="form-group">
                <kendo-label text="{{ 'nombre' | translate }}">
                    <kendo-textbox [(value)]="nombreModal">
                    </kendo-textbox>
                </kendo-label>
                <div *ngIf="submitArc==true && errorNombreModal==true" class="invalid-feedback-modal">
                    <div *ngIf="errorNombreModal==true">{{ 'nombreArcReq' | translate}}</div>
                </div>
                <kendo-label text="{{ 'fichero' | translate }}">
                    <kendo-fileselect [(ngModel)]="archivoAgregar" class="form-control" [restrictions]="restrictions" [multiple]="false"
                     (select)="selectEventHandlerFile($event)"
                     (remove)="removeEventHandlerFile($event)">
                        <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                        dropFilesHere="{{ 'dropFich' | translate }}"
                        invalidFileExtension="{{ 'extFich' | translate }}"
                        invalidMaxFileSize="{{ 'invalidMax' | translate }}" remove="{{ 'remFich' | translate }}"
                        select="{{ 'slFich' | translate }}">
                      </kendo-upload-messages>
                    </kendo-fileselect>
                </kendo-label>
                <div *ngIf="submitArc==true && errorArchivoModal==true" class="invalid-feedback-modal">
                    <div *ngIf="errorArchivoModal==true">{{ 'fileStatusFailed' | translate}}</div>
                </div>
            </div>
    </div>
    <div class="modal-footer" style="margin-top:50px">
        <button type="button" class="btn btn-primary" (click)="crearActualizarArchivo()" [disabled]="this.user.informesIncidencias<2" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>{{ 'guardar' | translate
            }}</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' |
            translate }}</button>
    </div>
</ng-template>

<!-- POPUP AFECTAPROD -->
<ng-template #afectaProdEm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span>{{ 'textenviarEmailFabricanteOperario' | translate }}</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="guardarAfProdEnvioEmail(true)" [disabled]="this.user.informesIncidencias<2">
            {{ 'si' | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="guardarAfProdEnvioEmail(false)" [disabled]="this.user.informesIncidencias<2">
            {{ 'no' | translate }}
        </button>
    </div>
</ng-template>

<!--POPUP SELECCIONAR PIEZA-->
<ng-template #selectPiezaTabla let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="card-body">
                <p>{{ 'selectDataFilterPieza' | translate }}</p>
                <!--LISTA OFS-->
                <div class="float-left mr-2">
                    <div class="form-group">
                        <label>{{ 'of' | translate }}</label>
                        <div class="caja">
                            <kendo-combobox (valueChange)="CambioFiltro()" [data]="listaOfs"
                                [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'" [valueField]="'idOf'"
                                placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false" style="width: 275px;"
                                [kendoDropDownFilter]="{operator: 'contains'}">
                                <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                </kendo-combobox-messages>
                            </kendo-combobox>
                        </div>
                    </div>
                </div>

                <!--LISTA PIEZAS-->
                <div class="float-left mr-2">
                    <div class="form-group">
                        <label>{{ 'pieza' | translate }}</label>
                        <div class="caja">
                            <kendo-combobox [disabled]="!ofsListas" (valueChange)="CambioFiltro()" [data]="listaPiezas"
                                [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                                placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false"
                                style="width: 275px;" [kendoDropDownFilter]="{operator: 'contains'}">
                                <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                </kendo-combobox-messages>
                            </kendo-combobox>
                        </div>
                    </div>
                </div>

                <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
                <div class="float-left mr-2" *ngIf="!user.ocultarParte">
                    <div class="form-group">
                        <label>{{ 'parte' | translate }}</label>
                        <div class="caja">
                            <kendo-combobox [disabled]="!piezasListas" [kendoDropDownFilter]="{operator: 'contains'}"
                                (valueChange)="CambioFiltro()" [data]="listaPartes" [(ngModel)]="partesSeleccionadas"
                                [textField]="'nombreParte'" [valueField]="'idParte'"
                                placeholder="{{ 'seleccioneParte' | translate }}" [autoClose]="false"
                                style="width: 295px;">
                                <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                </kendo-combobox-messages>
                            </kendo-combobox>
                        </div>
                    </div>
                </div>

                <!--LISTA RUTAS -->
                <div class="float-left mr-2">
                    <div class="form-group">
                        <label>{{ 'ruta' | translate }}</label>
                        <div class="caja">
                            <kendo-combobox [disabled]="!partesListas" [kendoDropDownFilter]="{operator: 'contains'}"
                                (valueChange)="CambioFiltro()" [data]="listaRutas" [(ngModel)]="rutasSeleccionadas"
                                [textField]="'nombreRuta'" [valueField]="'idRuta'"
                                placeholder="{{ 'seleccioneRuta' | translate }}" [autoClose]="false"
                                style="width: 295px;">
                                <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                </kendo-combobox-messages>
                            </kendo-combobox>
                        </div>
                    </div>
                </div>

                <!--LISTA OPERACIONES-->
                <div class="float-left mr-2">
                    <div class="form-group">
                        <label>{{ 'operacion' | translate }}</label>
                        <div class="caja">
                            <kendo-combobox [disabled]="!rutasListas" [kendoDropDownFilter]="{operator: 'contains'}"
                                (valueChange)="CambioFiltro()" [data]="listaOperaciones"
                                [(ngModel)]="operacionesSeleccionadas" [textField]="'nombreOperacion'"
                                [valueField]="'idOperacion'" placeholder="{{ 'seleccioneOperacion' | translate }}"
                                [autoClose]="false" style="width: 295px;">
                                <kendo-combobox-messages noDataText="{{'norecords' | translate}}">
                                </kendo-combobox-messages>
                            </kendo-combobox>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="cancelarFiltrado($event)">{{ 'cancel'| translate }}</button>
        <button type="button" class="btn btn-danger" [disabled]="!hayDatosFiltro"  (click)="openPiezaGridPopUp($event)">{{ 'filtrar' |
            translate
            }}</button>
    </div>
</ng-template>

<!--POPUP SELECCIONAR PIEZA2-->
<ng-template #selectPiezaTabla2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="volverPopUpPieza1($event)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
            <div class="card-header">
                <p>{{ 'selectPiezaTabla' | translate }}</p>
            </div>
            <!-- GRID -->
                <kendo-grid [kendoGridBinding]="dataGrid"
                    kendoGridSelectBy="idHo"
                    [selectedKeys]="operacionesSelecteds"
                    [navigable]="true"
                    [sortable]="true"
                    (cellClick)="cellClick($event)"
                    filterable="menu"
                    scrollable="virtual"
                    [rowHeight]="28"
                    [height]="500"
                    [resizable]="true"
                    [pageable]="true"
                    [pageSize]="pageSize"
                    [skip]="skip"
                    class="grid-font-10"
                    [rowClass]="rowCallback">

        <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="14%"></kendo-grid-column>

        <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" width="12%" *ngIf="usuarioIdDb!=usuarioIdDbGamesa"></kendo-grid-column><!-- OPERARIOS: PARA GAMESA ENSEÑARLO MAS ADELANTE -->

        <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="12%"></kendo-grid-column>

        <kendo-grid-column field="cantidadLote" title="{{ 'lote' | translate }}" width="8%">
            <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.cantidadLoteHechas }} / {{dataItem.cantidadLote }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="nSerie" title="{{ 'numSerie' | translate }}" width="10%">
            <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.nSerie }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="colada" title="{{ 'pedircolada' | translate }}" width="8%">
            <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.colada }}</ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="claseRespuesta" title="{{ 'estado' | translate }}" width="8%" [style]="{'text-align': 'center'}">
            <ng-template kendoGridCellTemplate let-dataItem>
            <span class="icono-estado " [ngClass]="dataItem.claseRespuesta"></span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="volverPopUpPieza1($event)">{{ 'cancel'| translate }}</button>
        <button type="button" class="btn btn-danger" [disabled]="!hayDatosFiltro || this.user.informesIncidencias<2"  (click)="closePopUp()">{{ 'aceptar' | translate
            }}</button>
    </div>
</ng-template>
