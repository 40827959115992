import { UsuariosService } from '@app/_services';
import { TeardownLogic } from 'rxjs';

export function appInitializer(userService: UsuariosService) {
  return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate
        //var an : any = resolve;
        userService.refreshToken()
            .subscribe()
             .add(resolve);
            //.add(an);
    });
}
