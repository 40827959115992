import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivosService, AlertService, MenuService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-activosMaestros-detalle',
  templateUrl: './activosMaestros-detalle.component.html',
  styleUrls: ['./activosMaestros-detalle.component.less']
})
export class ActivosMaestrosDetalleComponent implements OnInit {
  @ViewChild('modalIsometrico') modalIsometrico: NgbModalRef;
  @ViewChild('popupBorrarProveedores') popupBorrarProveedores: NgbModalRef;
  @ViewChild('popupBorrarRelaciones') popupBorrarRelaciones: NgbModalRef;
  @ViewChild('popupBorrarDocumentos') popupBorrarDocumentos: NgbModalRef;
  form: FormGroup;
  activosTipos: any;
  jerarquiaActivos: any;
  submitted = false;
  idActivoMaestro: number;
  stock = false;
  public dibujosMaquinas: any;
  public dibujosObjetos: any;
  public selectedDibujo: any;
  public selectedAuxiliarDibujo: any;
  selectedJerarquiaActivo: any = {};
  selectedTipoActivo: any = {};
  dataAsociadosPadres: any;
  loadingAsociadosPadres = true;
  dataAsociadosHijos: any;
  loadingAsociadosHijos = true;
  mySelectionHijos: any = [];
  loadingForm0 = true;
  loadingForm = true;
  mySelectionProveedores: any = [];
  dataProveedores: any;
  loadingProveedores = true;
  dataGestionDocumental: any;
  mySelectionGestionDocumental: any = [];
  loadingGestionDocumental = true;
  modalReference: NgbModalRef;
  isDeletingProveedores;
  isDeletingHijos;
  isDeletingGestionDocumental;
  public imageToShow: any;
  user = this.userService.userValue;

  public restrictionsImage: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };
  constructor(private formBuilder: FormBuilder, private menuService: MenuService, public router: Router, private modalService: NgbModal,
    private userService: UsuariosService,
    private translateService: TranslateService, private activosService: ActivosService, private route: ActivatedRoute, protected alertService: AlertService) {

    this.menuService.titulo = this.translateService.instant('activoMaestro');
    this.idActivoMaestro = Number(this.route.snapshot.params['id']);
    this.stock = false;
    //Cargar datos isometrico
    this.dibujosObjetos = [
      { id: 1, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-1.png" },
      { id: 2, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-1.png" },
      { id: 3, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-2.png" },
      { id: 4, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-tipo-2.png" },
      { id: 5, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-tipo-3.png" },
      { id: 6, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-madera-1.png" },
      { id: 7, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-deposito-liquidos.png" },
      { id: 8, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-barras-metal.png" },
      { id: 9, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-bidones.png" },
      { id: 10, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-cajas-carton.png" },
      { id: 11, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-cofres-01.png" },
      { id: 12, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-cofres-02.png" },
      { id: 13, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-tipo1-1.png" },
      { id: 14, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-tipo1-2.png" },
      { id: 15, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-fenwick.png" },
      { id: 16, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-1.png" },
      { id: 17, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-2.png" },
      { id: 18, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-3.png" },
      { id: 19, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-4.png" },
      { id: 20, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-palet-sacos.png" },
      { id: 21, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-puesto-1.png" },
      { id: 22, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-puesto-2.png" },
      { id: 23, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-rollos-1.png" },
      { id: 24, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-transpaleta.png" },
      { id: 25, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-tubos-estanterias.png" },
    ];
    this.dibujosMaquinas = [
      { id: 26, imagen: "../../../assets/isometrico/iconos/miniatura/durma-laser-cut-hdf-3015-miniatura-04.png" },
      { id: 27, imagen: "../../../assets/isometrico/iconos/miniatura/extrusora_3_cubos_vista_miniatura-04.png" },
      { id: 28, imagen: "../../../assets/isometrico/iconos/miniatura/extrusora2x1_miniatura-01.png" },
      { id: 29, imagen: "../../../assets/isometrico/iconos/miniatura/geminis-gt5i-miniatura-01.png" },
      { id: 30, imagen: "../../../assets/isometrico/iconos/miniatura/mazak-miniatura-02.png" }
    ];
    this.selectedDibujo = { id: -1, imagen: "../../../assets/isometrico/iconos/noimagen.png" };
    this.loadingForm0 = false;

    var r1 = false, r2 = false;
    this.activosService.getJerarquia().subscribe((response: any) => {
      this.jerarquiaActivos = response;
      this.selectedJerarquiaActivo = -1;
      r1 = true;
      if (r1 && r2)
        this.cargarDatos();
    });
    this.activosService.getAllTipos().subscribe((response: any) => {
      this.activosTipos = response;
      this.selectedTipoActivo = -1;
      r2 = true;
      if (r1 && r2)
        this.cargarDatos();
    });
  }

  ngOnInit(): void {
    //Para evitar errores
    this.form = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required]),
      referencia: new FormControl('', [Validators.required]),
      codigoEAN: new FormControl(''),
      vidaUtil: new FormControl(0.0),
      stock: new FormControl(false),
      observaciones: new FormControl(''),
      nombreArchivo: ['',],
      archivo: ['',],
      archivoBase64: ['',]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }


  cargarDatos() {
    if (this.idActivoMaestro != 0) {
      this.activosService.GetActivoMaestroById(this.idActivoMaestro).subscribe((response: any) => {
        this.form = this.formBuilder.group({
          nombre: new FormControl(response[0].nombre, [Validators.required]),
          referencia: new FormControl(response[0].referencia, [Validators.required]),
          codigoEAN: new FormControl(response[0].codigoEAN),
          vidaUtil: new FormControl(response[0].vidaUtil),
          stock: new FormControl(response[0].stock),
          observaciones: new FormControl(response[0].observaciones),
          nombreArchivo: [response[0].imagen,],
          archivo: ['',],
          archivoBase64: ['',]
        });
        //isométrico
        var aux = this.dibujosObjetos.filter(x => x.id == response[0].idIsometrico)[0];
        if (aux == undefined) {
          aux = this.dibujosMaquinas.filter(x => x.id == response[0].idIsometrico)[0];
          if (aux != undefined) {
            this.selectedDibujo = aux;
          }
        }
        if (aux != undefined) {
          this.selectedDibujo = aux;
        }
        //jerarquia y tipo activo
        if (this.jerarquiaActivos.filter(x => x.tipo == response[0].jerarquiaActivo)[0] !== undefined) {
          this.selectedJerarquiaActivo = this.jerarquiaActivos.filter(x => x.tipo == response[0].jerarquiaActivo)[0].id;
        } else {
          this.selectedJerarquiaActivo = -1;
        }
        if (this.activosTipos.filter(x => x.nombre == response[0].tipoActivo)[0] !== undefined) {
          this.selectedTipoActivo = this.activosTipos.filter(x => x.nombre == response[0].tipoActivo)[0].id
        } else {
          this.selectedTipoActivo = -1;
        }
        this.imageToShow = response[0].imagenBase64;
        this.loadingForm = false;
      });

    } else { this.loadingForm = false; }
    this.activosService.getActivosMaestrosAsociados(this.idActivoMaestro).subscribe((response: any) => {
      this.dataAsociadosPadres = response;
      this.loadingAsociadosPadres = false;
    });
    this.loadActivosMaestrosAsociados();
    this.loadActivosMaestrosProveedores();
    this.loadGestionDocumental();
  }

  loadActivosMaestrosProveedores(){
    this.activosService.getActivosMaestrosProveedores(this.idActivoMaestro).subscribe((response: any) => {
      this.dataProveedores = response;
      this.loadingProveedores = false;
    });
  }

  loadActivosMaestrosAsociados(){
    this.activosService.getMaestrosArbolInferior(this.idActivoMaestro).subscribe((response: any) => {
      this.dataAsociadosHijos = response;
      this.loadingAsociadosHijos = false;
    });
  }

  loadGestionDocumental(){
    this.activosService.getAllDocumentos(this.idActivoMaestro).subscribe((response: any) => {
      this.dataGestionDocumental = response;
      this.loadingGestionDocumental = false;
    });
  }

  //funciones árboles
  viewUpperTree(e:any){
    window.open('#/activoMaestro/'+this.idActivoMaestro + '/arbolCompleto/'+ e.id, "_blank");
  }

  viewLowerTree(e: any){
    window.open('#/activoMaestro/'+this.idActivoMaestro + '/arbolInferior/'+ e.id, "_blank");
  }


  //funciones imagen
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    var auxNombre = 'activoMaestro_' + this.idActivoMaestro + e.files[0].extension;
    this.form.controls['nombreArchivo'].setValue(auxNombre);
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
      th.imageToShow = archivoBase64;
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
    this.imageToShow = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //BOTONES ASOCIADOS(ARBOL)
  onClickAsignarRelacion(){
    this.router.navigate(['activoMaestro/'+this.idActivoMaestro+'/asignarRelacion/0']);
  }
  onClickNuevoActivo(){
    window.open('#/activoMaestro/0', "_blank");
  }
  onClickEliminarRelaciones(){
    if (this.mySelectionHijos.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrarRelaciones, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptarRelaciones() {
    this.mySelectionHijos.forEach(element => {
      if (element > 0) {
        this.isDeletingHijos = true;
        var ids: string = "";
        this.mySelectionHijos.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.activosService.deleteRelaciones(ids).subscribe(() => {
          this.isDeletingHijos = false;
          this.loadActivosMaestrosAsociados();
        });
      }
      this.modalReference.close();
    });
  }

  //BOTONES PROVEEDORES
  onClickAsignarProveedor() {
    this.router.navigate(['activoMaestro/' + this.idActivoMaestro +'/proveedor/0']);
  }

  onClickNuevoProveedor() {
    window.open('#/proveedor/0', "_blank");
  }

  onCellClickProveedor(e: any){
    if (e.columnIndex > 0) {
      this.router.navigate(['activoMaestro/' + this.idActivoMaestro +'/proveedor/'+this.mySelectionProveedores[0]]);
    }
  }

  onClickEliminarProveedor() {
    if (this.mySelectionProveedores.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrarProveedores, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptar() {
    this.mySelectionProveedores.forEach(element => {
      if (element > 0) {
        this.isDeletingProveedores = true;
        var ids: string = "";
        this.mySelectionProveedores.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.activosService.deleteProveedoresActivoMaestro(ids, this.idActivoMaestro).subscribe(() => {
          this.isDeletingProveedores = false;
          this.loadActivosMaestrosProveedores();
        });
      }
      this.modalReference.close();
    });
  }

  //BOTONES GESTIÓN DOCUMENTAL
  onCellClickGestionDocumental(e: any){
    if (e.columnIndex > 0) {
      this.router.navigate(['/activoMaestro/' + this.idActivoMaestro + '/documento/'+ this.mySelectionGestionDocumental[0]]);
    }
  }
  
  onClickEditarGestionDocumental() {
    if(this.mySelectionGestionDocumental.length > 0 ){
      this.router.navigate(['/activoMaestro/' + this.idActivoMaestro + '/documento/'+ this.mySelectionGestionDocumental[0]]);
    }
  }

  onClickNuevoGestionDocumental() {
    this.router.navigate(['/activoMaestro/' + this.idActivoMaestro + '/documento/0']);
  }

  onClickEliminarGestionDocumental() {
    this.modalReference = this.modalService.open(this.popupBorrarDocumentos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarDocumentos() {
    this.mySelectionGestionDocumental.forEach(element => {
      if (element > 0) {
        this.isDeletingGestionDocumental = true;
        var ids: string = "";
        this.mySelectionGestionDocumental.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.activosService.deleteDocumentos(ids).subscribe(() => {
          this.isDeletingGestionDocumental = false;
          this.loadGestionDocumental();
        });
      }
      this.modalReference.close();
    });
  }

  viewPDF(element: any){
    if(element.esLink){
      window.open(element.ficheroLink, '_blank');
    } else{
      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + element.pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = element.ficheroLink;
      }, 0);
    }
  }

  //BOTONES PRINCIPALES
  guardarClick() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    if(this.selectedJerarquiaActivo == null){
      this.selectedJerarquiaActivo = -1;
    }
    if(this.selectedTipoActivo == null){
      this.selectedTipoActivo = -1;
    }
    if (this.idActivoMaestro == 0) {
      this.activosService.InsertActivoMaestro(this.form.value.nombre, this.form.value.referencia, this.selectedJerarquiaActivo, this.form.value.vidaUtil,
        this.form.value.stock, this.selectedTipoActivo, this.form.value.codigoEAN
        , this.form.value.nombreArchivo, this.form.value.archivoBase64, this.selectedDibujo.id, this.form.value.observaciones).subscribe(
          (result: any) => {
            if (result >= 0) {
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
              this.router.navigate(['activosMaestros']);
            } else {
              this.alertService.error(this.translateService.instant('error'));
            }
          });
    } else {
      this.activosService.UpdateActivoMaestro(this.idActivoMaestro, this.form.value.nombre, this.form.value.referencia, this.selectedJerarquiaActivo, this.form.value.vidaUtil,
        this.form.value.stock, this.selectedTipoActivo, this.form.value.codigoEAN
        , this.form.value.nombreArchivo, this.form.value.archivoBase64, this.selectedDibujo.id, this.form.value.observaciones).subscribe(
          (result: any) => {
            if (result >= 0) {
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
              this.router.navigate(['activosMaestros']);
            } else {
              this.alertService.error(this.translateService.instant('error'));
            }
          });
    }
  }

  //Isometrico
  abrirModal() {
    this.selectedAuxiliarDibujo = this.selectedDibujo;
    this.modalReference = this.modalService.open(this.modalIsometrico, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true, scrollable: true });
  }

  guardarIsometrico() {
    this.selectedDibujo = this.selectedAuxiliarDibujo;
    this.modalReference.close();
  }



}
