<style>
  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div class="clearfix">

  <div class="ca-izq">
    <div class="card fechas-control-de-asignaciones">
      <div class="card-body">

        <div class="row">
          <!-- Filtro: FECHA -->
          <div class="col">
            <label> {{ 'periodo' | translate}} </label>
            <!-- DATO(s) -->
            <div class="caja">
            <div class="calendarFiltro">
              <!-- CONTROL -->
              <div class="calendarFiltro-input" (click)="showCalendar()">
                <label>{{fechaini | kendoDate}} - {{fechafin | kendoDate}}</label>
                <i class="k-icon k-i-calendar"></i>
              </div>
              <!-- CONTROL EXPANDIDO -->
              <div class="calendarFiltro-calendario" style="position: relative !important; width: 367px;" [ngClass]="mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                <div class="row">
                  <div class="col-mes">
                    <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic" (click)="valueClickCalendar(0, $event)">
                      <ng-template kendoCalendarMonthCellTemplate let-date>
                        <span class="calendarFiltro-calendario-contenido-dia" [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(date)"> {{ date.getDate() }} </span>
                      </ng-template>
                      <ng-template kendoCalendarHeaderTitleTemplate let-title>
                        <div> {{ title }} </div>
                      </ng-template>
                    </kendo-calendar>
                  </div>
                  <div class="col-mes-botones">
                    <div class="calendarFiltro-botones">
                      <div class="clearfix text-center">
                        <!-- style="margin-left: -100px; margin-top: 5px; position: absolute;" -->
                        <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                        <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                        <button (click)="cargarMeses()">{{'hoy' | translate}}</button>
                      </div>
                      <div>
                        <div class="clearfix"><button (click)="ultimas24HButton()">{{'ultimas24H' | translate}}</button></div>
                        <div class="clearfix"><button (click)="ultimos7DiasButton()">{{'ultimos7Dias' | translate}}</button></div>
                        <div class="clearfix"><button (click)="ultimos30DiasButton()">{{'ultimos30Dias' | translate}}</button></div>
                        <div class="clearfix"><button (click)="ultimos60DiasButton()">{{'ultimos60Dias' | translate}}</button></div>
                        <div class="clearfix"><button (click)="ultimos90DiasButton()">{{'ultimos90Dias' | translate}}</button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>

          <!-- <div class="col">
    <div class="clearfix">
      <label>{{ 'fechainicio' | translate }}</label>
      <div class="caja">
        <kendo-datepicker (valueChange)="onChangeFIni($event)" [(value)]="fechaini" class="form-control"></kendo-datepicker>
      </div>
    </div>
  </div>
  <div class="col" style="padding-left: 0;">
    <div class="clearfix">
      <label>{{ 'fechafin' | translate }}</label>
      <div class="caja">
        <kendo-datepicker (valueChange)="onChangeFFin($event)" [(value)]="fechafin" class="form-control"></kendo-datepicker>
      </div>
    </div>
  </div> -->
          <!--AREAS PRODUCTIVAS-->
          <div class="col">
            <div class="clearfix">
              <label>{{ 'secciones' | translate }}</label>
              <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                   [textField]="'nombre'"
                                   [valueField]="'id'"
                                   [autoClose]="false"
                                   [(ngModel)]="seccionesSeleccionadas"
                                   placeholder="{{ 'secciones' | translate }}"
                              
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          <!--GRUPOS-->
          <div class="col">
            <div class="clearfix">
              <label>{{ 'grupoMaquinas' | translate }}</label>
              <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                                   [textField]="'nombre'"
                                   [valueField]="'id'"
                                   [autoClose]="false"
                                   [(ngModel)]="gruposSeleccionados"
                                   placeholder="{{ 'grupoMaquinas' | translate }}"
                                   
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
                  </ng-template>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          </div>
          <div class="row">

            <!--CLASIFICACION-->
            <div class="col">
              <div class="clearfix">
                <label>{{ 'clasificacion' | translate }}</label>
                <div class="caja">
                  <kendo-multiselecttree kendoMultiSelectTreeExpandable
                                      [kendoMultiSelectTreeHierarchyBinding]="clasificacion"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [childrenField]="'clasificacionInferior'"
                                      [(value)]="clasificacionSeleccionados"
                                      [checkableSettings]="checkableSettings"
                                      placeholder="{{ 'clasificacion' | translate }}"
                                      [tagMapper]="tagMapper"
                                      style="background: white;">
                      <ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
                        <span class="k-icon k-i-arrow-s"></span>
                        <ng-container *ngIf="dataItems.length == 1">{{ dataItem[0].nombre }}</ng-container>
                        <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clasificaciones' | translate }}</ng-container>
                      </ng-template>
                  </kendo-multiselecttree>
                </div>
              </div>
            </div>
  
            <div class="col">
              </div>

            <div class="col" style="margin: 10px 0px 0px 5px;">
              <button type="button" class="btn btn-primary w-50 float-right" [disabled]="mostrarCalendario" (click)="CargarDatos()">{{ 'filtrar' | translate }}</button>
            </div>
          </div>

        </div>
    </div>
  </div>

  <!--GRAFICOS EJECUCION Y PARADA -->
  <div class="ca-der">

    <div class="card cont-graf-ca">

      <div class="card-body">

        <div class="d-flex">

          <!--EJECUCION-->
          <div class="ca-infocol">
        

              <div class="ca-condonut">


                <div class="ca-condonut-izq">

                <div class="clearfix">
                  <label class="label-grafico-asignaciones ejecucion">{{'operaciones' | translate}}</label>
                </div>

             

                <div id="graficejecucion_controlasignaciones" style="height:90px;"></div>

                <div class="d-none" id="graficoperariosejecucion" style="height:120px;"></div>

            

                </div>



                <div class="ca-condonut-der">

                  <div class="clearfix">

                    <div class="mr-2 float-left">

                      <div class="clearfix">
                        <label class="ca-label-titulo">{{ 'identificado' | translate | uppercase }}</label>
                      </div>
                      <div class="clearfix">
                        <label class="ca-label-valor"><strong>{{ justificadoEjecucionTiempo }}</strong> {{ justificadoEjecucionPorcentaje }}</label>
                      </div>

                    </div>

                    <div class="float-left">

                      <div class="clearfix">
                        <label class="ca-label-titulo">{{ 'noIdentificado' | translate | uppercase }}</label>
                      </div>

                      <div class="clearfix">
                        <label class="ca-label-valor"><strong>{{ noJustificadoEjecucionTiempo }}</strong> {{ noJustificadoEjecucionPorcentaje }}</label>
                      </div>


                    </div>

                  </div>


                 </div>



              </div>

           
          </div>

          <!--PARADA-->
          <div class="ca-infocol">
            <div class="ca-condonut">

              <div class="ca-condonut-izq">

                <div class="clearfix">
                  <label class="label-grafico-asignaciones parada">{{'perdidas' | translate}}</label>
                </div>

                <div id="graficoparada_controlasignaciones" style="height:90px;"></div>


              </div>

              <div class="ca-condonut-der">

                <div class="mr-2 float-left">

                  <div class="clearfix">
                    <label class="ca-label-titulo">{{ 'justificado' | translate | uppercase }}</label>
                  </div>

                  <div class="clearfix">
                    <label class="ca-label-valor"><strong>{{ justificadoParadaTiempo }}</strong> {{ justificadoParadaPorcentaje }}</label>
                  </div>

                </div>


                <div class="float-left">
                  <div class="clearfix">
                    <label class="ca-label-titulo">{{ 'nojustificado' | translate | uppercase }}</label>
                  </div>

                  <div class="clearfix">
                    <label class="ca-label-valor"><strong>{{ noJustificadoParadaTiempo }}</strong> {{ noJustificadoParadaPorcentaje }}</label>
                  </div>

                </div>




                </div>

              </div>
          </div>



          <!--TIEMPOS ALARMAS, MANTENIMIENTOS, APAGADAS, TOTAL-->
          <div class="ca-infocol">

            <div class="valores-totales-asignaciones">

            <!-- <div class="valor-total-asignaciones">
              <label class="label-grafico-asignaciones alarmas">{{ 'alarma' | translate }}</label>
              <label class="label-valor-total-asignaciones">{{ tiempoAlarmas }}</label>
            </div> -->

            <div class="valor-total-asignaciones">
              <label class="label-grafico-asignaciones mantenimientos">{{ 'mant' | translate }}</label>
              <label class="label-valor-total-asignaciones">{{ tiempoMantenimientos }}</label>
            </div>

            <!-- <div class="valor-total-asignaciones">
              <label class="label-grafico-asignaciones apagadas">{{ 'apagada' | translate }}</label>
              <label class="label-valor-total-asignaciones">{{ tiempoApagadas }}</label>
            </div> -->

            <div class="valor-total-asignaciones">
              <label class="label-grafico-asignaciones ">{{ 'total' | translate }}</label>
              <label class="label-valor-total-asignaciones">{{ tiempoTotal }}</label>
            </div>

            </div>


          </div>

        </div>

      </div>
      <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
    </div>
  </div>
</div>

<!-- TOOLTIP GRIDS-->
<ng-template #template1 let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template1"
     filter=".k-grid td"
     (mouseover)="showGridTooltip1($event)">

  <div class="row">

    <div class="col-md-6">
      <div class="nuevo-panel">
        <div class="card">
          <div class="card-header">
            <h3>
              <label ID="loperarios" class="">{{ 'operarios' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <!-- GRID OPERARIOS-->
          <kendo-grid [data]="operarios" kendoGridSelectBy="idOperario" [selectedKeys]="selectedOperarios" [height]="450" (selectionChange)="onSelectOperario($event)" [resizable]="true" class="gridcontroldeasignaciones"
                      [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: true, mode: 'multiple'}">
            <kendo-grid-checkbox-column width="10%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="idOperario" hidden="hidden" title="ID" width="40">
            </kendo-grid-column>
            <kendo-grid-column field="nombreOperario" title="{{ 'operario' | translate }}" [headerStyle]="{'text-align': 'center'}" width="31%">
            </kendo-grid-column>
            <kendo-grid-column-group title="{{ 'ejecuciones' | translate }}" width="20" [headerStyle]="{'text-align': 'center'}" [locked]="false">
              <kendo-grid-column width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-justificada">{{ 'justificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tEjecucionesJustificadas)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-no-justificada">{{ 'nojustificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem><span [ngClass]="dataItem.classEjecucionesNoJustificadas">{{ myFunctions.secondsTo_HH_MM(dataItem.tEjecucionesNoJustificadas) }}</span></ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="{{ 'parada' | translate }}" [locked]="false" [headerStyle]="{'text-align': 'center'}">
              <kendo-grid-column field="tParadasJustificadas" width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-justificada">{{ 'justificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{ myFunctions.secondsTo_HH_MM(dataItem.tParadasJustificadas) }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tParadasNoJustificadas" width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-no-justificada">{{ 'nojustificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem><span [ngClass]="dataItem.classParadasNoJustificadas">{{ myFunctions.secondsTo_HH_MM(dataItem.tParadasNoJustificadas) }}</span></ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <!-- <kendo-grid-column field="tAlarmas" title="{{ 'alarmas' | translate }}" width="20%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tAlarmas)}}</ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column field="tMantenimientos" title="{{ 'mantenimiento' | translate }}" [headerStyle]="{'text-align': 'center'}" width="20%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tMantenimientos)}}</ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column field="tApagadas" title="{{ 'apagadas' | translate }}" width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tApagadas)}}</ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column field="tTotal" title="{{ 'total' | translate }}" width="20%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tTotal)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tiempoFichado" title="{{ 'tiempoFichado' | translate }}" width="20%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tiempoFichado)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="nuevo-panel">
        <div class="card">
          <div class="card-header">
            <h3>
              <label ID="loperarios" class="">{{ 'maquinas' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>
          <!-- GRID MAQUINAS-->
          <kendo-grid [data]="maquinas" kendoGridSelectBy="idMaquina" [selectedKeys]="selectedMaquinas" [height]="450" (selectionChange)="onSelectMaquinas($event)" [resizable]="true" class="gridcontroldeasignaciones"
                      [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: true, mode: 'multiple'}">
            <kendo-grid-checkbox-column width="10%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="idMaquina" hidden="hidden" title="ID" width="40" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
            </kendo-grid-column>
            <kendo-grid-column field="nombreMaquina" title="{{ 'maquinas' | translate }}" width="40%" [headerStyle]="{'text-align': 'center'}">
            </kendo-grid-column>
            <kendo-grid-column-group title="{{ 'ejecuciones' | translate }}" width="20" [headerStyle]="{'text-align': 'center'}" [locked]="false">
              <kendo-grid-column width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-justificada">{{ 'justificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tEjecucionesJustificadas)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-no-justificada">{{ 'nojustificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem><span [ngClass]="dataItem.classEjecucionesNoJustificadas">{{ myFunctions.secondsTo_HH_MM(dataItem.tEjecucionesNoJustificadas) }}</span></ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="{{ 'parada' | translate }}" [locked]="false" [headerStyle]="{'text-align': 'center'}">
              <kendo-grid-column field="tParadasJustificadas" width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-justificada">{{ 'justificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{ myFunctions.secondsTo_HH_MM(dataItem.tParadasJustificadas) }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tParadasNoJustificadas" width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
                <ng-template kendoGridHeaderTemplate><span class="columna-no-justificada">{{ 'nojustificado' | translate }}</span></ng-template>
                <ng-template kendoGridCellTemplate let-dataItem><span [ngClass]="dataItem.classParadasNoJustificadas">{{ myFunctions.secondsTo_HH_MM(dataItem.tParadasNoJustificadas) }}</span></ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <!-- <kendo-grid-column field="tAlarmas" title="{{ 'alarmas' | translate }}" width="20%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tAlarmas)}}</ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column field="tMantenimientos" title="{{ 'mantenimiento' | translate }}" width="20%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tMantenimientos)}}</ng-template>
            </kendo-grid-column>
            <!-- <kendo-grid-column field="tApagadas" title="{{ 'apagadas' | translate }}" width="15%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tApagadas)}}</ng-template>
            </kendo-grid-column> -->
            <kendo-grid-column field="tTotal" title="{{ 'total' | translate }}" width="20%" [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tTotal)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
        <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
      </div>
    </div>

  </div>

  <!-- graficos -->
  <div class="row">
    <!-- operarios -->
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <!-- informacion del grafico -->
          <div class="cont-tooltip-grafico">
            <label>
              <span class="tooltip-consumibles-contenido"><i class="fas fa-info-circle mr-2"></i><span class="tooltipTextHeader" [attr.contenido]="infoGraficoSinOperariosControlAsignaciones"></span></span>
            </label>
          </div>
          <!-- grafico -->
          <div class="grafico-OperariosMaquinas-controlAsignaciones"><div id="graficoOperarios_controlAsignaciones" ></div></div>
          <div class="btn-cont-pordia">
            <!-- <button type="button" class="btn btn-success m-1" (click)="btnPorDiaOperario()">{{'porDia' | translate }}</button> -->
            <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="btnPorDiaOperarioSelected" [onLabel]="tituloSwitchDia" [offLabel]="tituloSwitchDia" (valueChange)="btnPorDiaOperario()">
            </kendo-switch>
          </div>
        </div>
      </div>
    </div>
    <!-- maquinas -->
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <!-- informacion del grafico -->
          <div class="cont-tooltip-grafico">
            <label>
              <span class="tooltip-consumibles-contenido"><i class="fas fa-info-circle mr-2"></i><span class="tooltipTextHeader" [attr.contenido]="infoGraficoMaquinasControlAsignaciones"></span></span>
            </label>
          </div>
          <!-- grafico -->
          <div class="grafico-OperariosMaquinas-controlAsignaciones"><div id="graficoMaquinas_controlAsignaciones" ></div></div>
          <div class="btn-cont-pordia">
            <!-- <button type="button" class="btn btn-success m-1" (click)="btnPorDiaMaquina()">{{'porDia' | translate }}</button> -->
            <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="btnPorDiaMaquinaSelected" [onLabel]="tituloSwitchDia" [offLabel]="tituloSwitchDia" (valueChange)="btnPorDiaMaquina()">
            </kendo-switch>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
