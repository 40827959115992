import { Component, ViewChild } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as c3 from 'c3';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

/* SERVICES*/
import { MaquinasService, UsuariosService, PlanificadorService, MenuService, HistoricoOperacionesService, ConfiguracionService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

import { ActivatedRoute } from '@angular/router';

import { Router } from '@angular/router';

import { PageChangeEvent, GridDataResult, GroupModule } from '@progress/kendo-angular-grid';

import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { DragAndDropEditingDirective } from '@progress/kendo-angular-treeview';
import { MyFunctions } from '@app/_helpers';
import * as moment from 'moment';
import { RowEditingDirectiveBase } from '@progress/kendo-angular-treelist';
import { limit } from '@progress/kendo-data-query/dist/npm/array.operators';
import { MantenimientosPredefinidosComponent } from '@app/mantenimientosPredefinidos/mantenimientosPredefinidos.component';
import { OperacionesDetalleComponent } from '@app/operaciones/operacionesDetalle.component';


@Component({
  selector: 'app-planificadorLargoMaquina',
  templateUrl: './planificadorLargoMaquina.component.html'
})

export class PlanificadorLargoMaquinaComponent {
  public aparecer = false;

  private maquinaPreSeleccionada: number = 0;

  public requiereMaquina: boolean = false;
  public requiereSemana: boolean = false;

  private selectedMaquina: number = 0;
  private selectedMaquinaTipo: number = 1;
  public Jmaquinas: any;
  public JmaquinasMostradas: any;
  public JmaquinasMostradasSinSubcontratas: any;
  public Jplanificador: any;
  public dtOperacionesPlanificador: any;
  public dtTiemposMaquinas: any;
  public Jturnos: any;
  public Jmantenimientos: any;
  private JfechaMin: Date;
  private JfechaMax: Date;

  private JplanificadorDentroTaller: any;
  private JplanificadorFueraTaller: any;

  public aplicarTiempoEstimado: boolean = true; // si no se usa el tiempo estimado, se usa el tiempo predictivo
  public aplicarIneficiencias: boolean = false; // si se añade o no se añade el tiempo de ineficiencia

  public Jplanificadores: any;
  public JplanificadoresSelected: any;

  public DatOperaciones: any;//TODOS PROGRAMAS 
  public DatOperaciones_grupos_operaciones: any;//TODOS PROGRAMAS QUE ESTAN EN GRUPOS
  public DatOperaciones_grupos: any;//TODOS PROGRAMAS QUE ESTAN EN GRUPOS
  public Joperaciones: any;//GRID PROGRAMAS 
  public JoperacionesVal: any;//GRID PROGRAMAS (para mostrar)
  public operacionesSelected = [];//GRID PROGRAMAS SELECCIONADOS
  public Jgrupos: any;//GRID GRUPOS 
  public gruposSelected = [];//GRID GRUPOS SELECCIONADOS

  public JoperacionesYgrupos: any;//GRID OPERACIONES + GRUPOS 

  public Jsemanas = [];
  public JsemanaSelected: any;
  public JmaquinaSelected: any;

  public visibleInfo: boolean = false;
  public visibleInfoGrupo: boolean = false;
  public desactivarBottom: boolean = true;
  public infoOF: string;
  public infoCliente: string;
  public infoProyecto: string;
  public infoPlano: string;
  public infoRefPieza: string;
  public infoPieza: string;
  public infoParte: string;
  public infoFechaLimite: string;

  public idOperacionesGrupos: number;
  public nombreGrupo: string;
  public temperaturaGrupo: string;
  public tiempoEstimadoGrupo: string;
  public pesoGrupo: string;
  public unidadesGrupo: string;

  user = this.userService.userValue;
  modalReference: NgbModalRef;

  modalReferenceloading: NgbModalRef;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  private dataFiltro: any;
  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public listaPiezas: any;
  public piezasSeleccionados: any;

  public pageSize = 40;
  public skip = 0;

  public loadingPanel: boolean = false;

  tiemposCorto: any;
  operacionesCorto: any;
  operacionesOrdenadasEjecucionFueraCorto: any;

  popupReorganizandoVersion: boolean = false;

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  private draggedOperacionPartida: any;
  private semanaDraggedOperacionPartida: any;
  private semanaActualOperacionPartida: any;
  private semanaOperacionPartida: any;

  //DATOS DE CONFIGURACION
  public dtConfiguracion: any = {};
  public dtConfiguracionPlanificador: any = {};
  public multiplicadorTiempos: number = 0.0;
  public ocultarParte: number = 0;


  public prioridadCliente: number;
  public prioridadFacturacion: number;
  public prioridadOF: number;
  public prioridadMaquina: number;
  public maxCapacidadMaquina: number;
  public pasoCapacidadMaquina: number;
  public prioridadFecha: number;
  public prioridadTemperatura: number;
  public dias_reserva: number;
  public percent_cap_maquina: number;
  public optimizarCuelloBotella: boolean;
  public metodologiasDePlanificacion: any;
  public metodologiasDePlanificacionSelected: any;
  public replanificarTiempoEstimado: boolean;
  public replanificarIneficiencias: boolean;
  public planificarSoloNoPlanificadas: boolean;
  public asap_maximoMesesParaIniciarAntesDeFechaEntrega: number;


  private moverOperaciones_e;
  private moverOperaciones_semana;
  private moverOperaciones_todas: boolean;

  private cadenas_Maquinas = [];

  @ViewChild('popupAtrasarFijados') popupAtrasarFijados: NgbModalRef;
  @ViewChild('popupOperacionPartida') popupOperacionPartida: NgbModalRef;
  @ViewChild('popupReorganizar') popupReorganizar: NgbModalRef;
  @ViewChild('popupMoverOperaciones') popupMoverOperaciones: NgbModalRef;
  @ViewChild('popupVeteAlCorto') popupVeteAlCorto: NgbModalRef;

  //"LOAD"
  constructor(private maquinasService: MaquinasService, private userService: UsuariosService, private translateService: TranslateService,
    private planificadorService: PlanificadorService, public myFunctions: MyFunctions,
    public route: ActivatedRoute, private menuService: MenuService, public router: Router, private historicoOperacionesService: HistoricoOperacionesService,
    private modalService: NgbModal, private configuracionService: ConfiguracionService) {

    this.loadingPanel = true;

    this.menuService.titulo = this.translateService.instant('largoMaquina').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.cargarGrupos();
    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
        });
    }
    else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
    }

    //this.maquinasService.get().subscribe(
    //  json => {
    //    this.Jmaquinas = json;
    //    this.cargarMaquinas();
    //  }
    //)

    //MAQUINAS
    //ARATZ (10/12/2020): en esta pagina no siempre se pasa IDmaquina, y si se pasa 0 no funciona el primer click en el grafico.
    //                    La maquina viene cargada en el OnInit()
    if (this.selectedMaquina == 0) {
      this.maquinaPreSeleccionada = this.route.snapshot.params['id'];
      this.selectedMaquina = this.route.snapshot.params['id'];
      this.selectedMaquinaTipo = this.route.snapshot.params['tipo'];
    }

    var maquinas_planificador_model = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();
    if (maquinas_planificador_model == false) {
      this.planificadorService.GetMaquinasYSubcontratasPlanificador().subscribe(
        json => {
          this.maquinasService.set_maquinas_Y_subcontratado_planificador_model(json);
          this.Jmaquinas = this.maquinasService.get_maquinas_Y_subcontratado_planificador_model();

          //FLTRO POR SECCIONES
          var idsSeccionesSelecteds: any = [];
          if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
            this.seccionesSeleccionadas.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          }
          else {
            this.secciones.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          }

          this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))
          this.JmaquinasMostradasSinSubcontratas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)

          //this.cargarMaquinas(); //AHORA SE USA NGFOR!
        })
    }
    else {
      this.Jmaquinas = maquinas_planificador_model;

      //FLTRO POR SECCIONES
      var idsSeccionesSelecteds: any = [];
      if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
        this.seccionesSeleccionadas.forEach(
          seccion => {
            idsSeccionesSelecteds.push(seccion.id);
          });
      }
      else {
        this.secciones.forEach(
          seccion => {
            idsSeccionesSelecteds.push(seccion.id);
          });
      }
      this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))
      this.JmaquinasMostradasSinSubcontratas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && f.tipo == 1)

      //this.cargarMaquinas(); //AHORA SE USA NGFOR!
    }

    this.planificadorService.getPlanificadoresActivos(this.translateService.instant('real')).subscribe(
      json => {
        this.Jplanificadores = json;

        var version = this.route.snapshot.params['idVersion'];

        this.JplanificadoresSelected = json[version];

        var r1, r2, r3, r4, r5 = false; // esperar a las 2 respuestas
        this.planificadorService.GetFechaSemanasLimite().subscribe(
          (json) => {
            if (Object.keys(json).length > 0) {
              var a: any = json[0];

              var fecha = this.startOfWeek(this.myFunctions.getDateNow())
              var semana = this.getNumberOfWeek(fecha);
              var año = fecha.getFullYear();

              var fechaMax = a.fechaMax;
              var añoMax = a.año;
              var semanaMax = a.semana;

              var index = 0;
              while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
                //crear linea Json para añadir al "grid"
                var fecha2 = new Date(fecha);
                var js: any = {};
                js['value'] = index;
                js['fecha'] = fecha2;
                var fechaAnno = new Date(fecha);
                fechaAnno.setDate(fechaAnno.getDate() + 3);
                año = fechaAnno.getFullYear();
                js['año'] = año;
                js['semana'] = semana;
                js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                this.Jsemanas.push(js);

                //actualizar variables para proximo loop
                index++;
                fecha.setDate(fecha.getDate() + 7);
                semana = this.getNumberOfWeek(fecha);
                año = fecha.getFullYear();
              }
            } else {
              var fecha = this.startOfWeek(this.myFunctions.getDateNow());
              var semana = this.getNumberOfWeek(fecha);
              var año = fecha.getFullYear();


              var index = 0;
              //crear linea Json para añadir al "grid"
              var fecha2 = new Date(fecha);
              var js: any = {};
              js['value'] = index;
              js['fecha'] = fecha2;
              var fechaAnno = new Date(fecha);
              fechaAnno.setDate(fechaAnno.getDate() + 3);
              año = fechaAnno.getFullYear();
              js['año'] = año;
              js['semana'] = semana;
              js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
              this.Jsemanas.push(js);
            }
            r1 = true
            if (r1 && r2 && r3 && r4 && r5) {
              this.getGrafico();

              this.operacionesSelected = [];
              this.JmaquinaSelected = undefined;

              this.Jmaquinas.forEach(f => {
                if (f.id == this.selectedMaquina) {
                  this.JmaquinaSelected = f;
                }
              });

              this.JsemanaSelected = this.Jsemanas[0];
            }
          }
        );
        this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
          (json) => {

            this.DatOperaciones = json;

            var an: any = json;
            this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)
            this.operacionesSelected = [];

            r2 = true
            if (r1 && r2 && r3 && r4 && r5) {
              this.getGrafico();

              this.operacionesSelected = [];
              this.JmaquinaSelected = undefined;

              this.Jmaquinas.forEach(f => {
                if (f.id == this.selectedMaquina) {
                  this.JmaquinaSelected = f;
                }
              });

              this.JsemanaSelected = this.Jsemanas[0];
            }
          }
        );

        // CONFIGURACION DE PLANIFICADOR
        this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(result => {
          this.dtConfiguracionPlanificador = result[0];

          this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
          this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
          this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;

          this.planificadorService.Get_tiempos_planificador_corto(0, this.JplanificadoresSelected.value, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
              this.tiemposCorto = json;
              this.planificadorService.Get_tiempos_ejecutados(this.JplanificadoresSelected.value).subscribe(
                json => {
                  //CARGAR TIEMPOS EJECUTADOS START 
                  //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                  var an: any = json;
                  var operacionesOrdenadas2 = {};
                  var operacionesEnEjecucion = {};
                  an.forEach(
                    a => {
                      if (operacionesOrdenadas2[a['idOFs_Operacion']])
                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                      else
                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                      operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                      operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                    });
                  var an2: any = this.tiemposCorto;
                  //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                  an2.forEach(
                    row => {
                      // añadir las 3 nuevas variables
                      row['tiempoEjecutado'] = 0;
                      row['operacionEmpezada'] = false;
                      row['enEjecucion'] = false;

                      //se ponen las que estan en ejecucion en este momento
                      var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                      if (enEjecucion) {
                        if (enEjecucion == 1) {
                          row['enEjecucion'] = true;
                        }
                      }

                      //se descuenta el tiempo por maquina y operacion
                      var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                      if (tiempoEjecutado) {
                        //se le pone que esta empezada
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                  an2.forEach(
                    row => {
                      var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                      if (tiempoEjecutado) {
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //CARGAR TIEMPOS EJECUTADOS END
                  //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                  var an3: any = an2;
                  var jsonTiemposCorto: any = {};
                  var maquinas: any = [];
                  an3.forEach(
                    row => {
                      if (jsonTiemposCorto[row.idMaquina]) {
                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                      }
                      else {
                        maquinas.push(row.idMaquina);
                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                      }
                    });
                  var valoresFinales: any = [];
                  maquinas.forEach(
                    maquina => {
                      var js: any = {};
                      js.idMaquina = maquina;
                      js.tiempo = jsonTiemposCorto[maquina];
                      valoresFinales.push(js);
                    });
                  this.operacionesCorto = an3;
                  this.tiemposCorto = valoresFinales;
                  this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                  //CAMBIAR EL FORMATO AL RESULTADO END

                  r3 = true;

                  if (r1 && r2 && r3 && r4 && r5) {
                    this.getGrafico();

                    this.operacionesSelected = [];
                    this.JmaquinaSelected = undefined;

                    this.Jmaquinas.forEach(f => {
                      if (f.id == this.selectedMaquina) {
                        this.JmaquinaSelected = f;
                      }
                    });

                    this.JsemanaSelected = this.Jsemanas[0];
                  }
                }
              );

            }
          );
        });
        this.configuracionService.get_configuracion().subscribe(result => {
          // this.dtConfiguracion = result[0]; //los datos por los que se cogia esta tabla ya no se cargan desde aqui, sino que se cargan desde configuracion_variables_planificador
          this.ocultarParte = this.dtConfiguracion.ocultarParte;
          r4 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.getGrafico();

            this.operacionesSelected = [];
            this.JmaquinaSelected = undefined;

            this.Jmaquinas.forEach(f => {
              if (f.id == this.selectedMaquina) {
                this.JmaquinaSelected = f;
              }
            });

            this.JsemanaSelected = this.Jsemanas[0];
          }
        });
        this.planificadorService.get_grupos(version).subscribe(
          (json) => {

            this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

            var grupos = (json as any).datOperaciones_grupos
            grupos.forEach(
              grupo => {
                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
              });

            this.Jgrupos = grupos;
            this.gruposSeleccionados = [];

            this.DatOperaciones_grupos = grupos;

            r5 = true
            if (r1 && r2 && r3 && r4 && r5) {
              this.getGrafico();

              this.operacionesSelected = [];
              this.JmaquinaSelected = undefined;

              this.Jmaquinas.forEach(f => {
                if (f.id == this.selectedMaquina) {
                  this.JmaquinaSelected = f;
                }
              });

              this.JsemanaSelected = this.Jsemanas[0];
            }
          }
        );
      });

    this.JsemanaSelected = this.Jsemanas[0];

    this.configuracionService.get_metodologiaDePlanificacion().subscribe(result => {
      var an: any = result;
      an.forEach(element => {
        element.nombre = this.translateService.instant(element.nombre);
      });
      this.metodologiasDePlanificacion = an;

      this.metodologiasDePlanificacionSelected = an[0];

    });


    this.cargarDatosFiltro();
  }

  cargarGrupos() {
    this.maquinasService.getGruposMaquinasPlanificadorTodos().subscribe(
      json => {
        this.grupos = json.data;
      });
  }

  ngOnInit() {
    this.planificadorService.get_maquinas_encadenadas().subscribe(
      (json) => {
        this.cadenas_Maquinas = this.myFunctions.conseguir_cadenasDeMaquinas(json);
      }
    );
  }

  private maquinaClicked() {
    var r1, r2, r3: boolean = false;

    this.JmaquinaSelected = undefined;

    this.Jmaquinas.forEach(f => {
      if (f.id == this.selectedMaquina) {
        this.JmaquinaSelected = f;
      }
    });

    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      (json) => {
        this.DatOperaciones = json;
        var an: any = json;
        this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

        r1 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    var version = this.JplanificadoresSelected.value;
    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.tiemposCorto = json;

        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          json => {
            //CARGAR TIEMPOS EJECUTADOS START 
            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            var an: any = json;
            var operacionesOrdenadas2 = {};
            var operacionesEnEjecucion = {};
            an.forEach(
              a => {
                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                else
                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
              });
            var an2: any = this.tiemposCorto;
            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            an2.forEach(
              row => {
                // añadir las 3 nuevas variables
                row['tiempoEjecutado'] = 0;
                row['operacionEmpezada'] = false;
                row['enEjecucion'] = false;

                //se ponen las que estan en ejecucion en este momento
                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                if (enEjecucion) {
                  if (enEjecucion == 1) {
                    row['enEjecucion'] = true;
                  }
                }

                //se descuenta el tiempo por maquina y operacion
                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                if (tiempoEjecutado) {
                  //se le pone que esta empezada
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            an2.forEach(
              row => {
                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                if (tiempoEjecutado) {
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //CARGAR TIEMPOS EJECUTADOS END
            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            var an3: any = an2;
            var jsonTiemposCorto: any = {};
            var maquinas: any = [];
            an3.forEach(
              row => {
                if (jsonTiemposCorto[row.idMaquina]) {
                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                }
                else {
                  maquinas.push(row.idMaquina);
                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                }
              });
            var valoresFinales: any = [];
            maquinas.forEach(
              maquina => {
                var js: any = {};
                js.idMaquina = maquina;
                js.tiempo = jsonTiemposCorto[maquina];
                valoresFinales.push(js);
              });
            this.operacionesCorto = an3;
            this.tiemposCorto = valoresFinales;
            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //CAMBIAR EL FORMATO AL RESULTADO END


            r2 = true;
            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );

      }
    );
    this.planificadorService.get_grupos(version).subscribe(
      (json) => {

        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

        var grupos = (json as any).datOperaciones_grupos
        grupos.forEach(
          grupo => {
            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          });

        this.Jgrupos = grupos;
        this.gruposSeleccionados = [];

        this.DatOperaciones_grupos = grupos;

        r3 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );

    this.visibleInfo = false;
    this.visibleInfoGrupo = false;

    this.cargarDatosFiltro();
  }

  //BOTONES "FILTRO"
  btnTiempoEstimado() {

    this.loadingPanel = true;

    this.aplicarTiempoEstimado = true;

    var r1, r2, r3: boolean = false;
    //this.getGrafico();
    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      (json) => {
        this.DatOperaciones = json;
        var an: any = json;
        this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

        r1 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    var version = this.JplanificadoresSelected.value;
    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.tiemposCorto = json;

        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          json => {
            //CARGAR TIEMPOS EJECUTADOS START 
            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            var an: any = json;
            var operacionesOrdenadas2 = {};
            var operacionesEnEjecucion = {};
            an.forEach(
              a => {
                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                else
                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
              });
            var an2: any = this.tiemposCorto;
            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            an2.forEach(
              row => {
                // añadir las 3 nuevas variables
                row['tiempoEjecutado'] = 0;
                row['operacionEmpezada'] = false;
                row['enEjecucion'] = false;

                //se ponen las que estan en ejecucion en este momento
                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                if (enEjecucion) {
                  if (enEjecucion == 1) {
                    row['enEjecucion'] = true;
                  }
                }

                //se descuenta el tiempo por maquina y operacion
                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                if (tiempoEjecutado) {
                  //se le pone que esta empezada
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            an2.forEach(
              row => {
                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                if (tiempoEjecutado) {
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //CARGAR TIEMPOS EJECUTADOS END
            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            var an3: any = an2;
            var jsonTiemposCorto: any = {};
            var maquinas: any = [];
            an3.forEach(
              row => {
                if (jsonTiemposCorto[row.idMaquina]) {
                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                }
                else {
                  maquinas.push(row.idMaquina);
                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                }
              });
            var valoresFinales: any = [];
            maquinas.forEach(
              maquina => {
                var js: any = {};
                js.idMaquina = maquina;
                js.tiempo = jsonTiemposCorto[maquina];
                valoresFinales.push(js);
              });
            this.operacionesCorto = an3;
            this.tiemposCorto = valoresFinales;
            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //CAMBIAR EL FORMATO AL RESULTADO END

            r2 = true;
            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );

      }
    );
    this.planificadorService.get_grupos(version).subscribe(
      (json) => {

        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

        var grupos = (json as any).datOperaciones_grupos
        grupos.forEach(
          grupo => {
            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          });

        this.Jgrupos = grupos;
        this.gruposSeleccionados = [];

        this.DatOperaciones_grupos = grupos;

        r3 = true
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }

      }
    );
    //this.getGrafico();
  }
  btnPredictivo() {

    this.loadingPanel = true;

    this.aplicarTiempoEstimado = false;

    var r1, r2, r3: boolean = false;
    //this.getGrafico();
    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      (json) => {
        this.DatOperaciones = json;
        var an: any = json;
        this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

        r1 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    var version = this.JplanificadoresSelected.value;
    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.tiemposCorto = json;


        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          json => {
            //CARGAR TIEMPOS EJECUTADOS START 
            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            var an: any = json;
            var operacionesOrdenadas2 = {};
            var operacionesEnEjecucion = {};
            an.forEach(
              a => {
                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                else
                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
              });
            var an2: any = this.tiemposCorto;
            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            an2.forEach(
              row => {
                // añadir las 3 nuevas variables
                row['tiempoEjecutado'] = 0;
                row['operacionEmpezada'] = false;
                row['enEjecucion'] = false;

                //se ponen las que estan en ejecucion en este momento
                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                if (enEjecucion) {
                  if (enEjecucion == 1) {
                    row['enEjecucion'] = true;
                  }
                }

                //se descuenta el tiempo por maquina y operacion
                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                if (tiempoEjecutado) {
                  //se le pone que esta empezada
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            an2.forEach(
              row => {
                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                if (tiempoEjecutado) {
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //CARGAR TIEMPOS EJECUTADOS END
            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            var an3: any = an2;
            var jsonTiemposCorto: any = {};
            var maquinas: any = [];
            an3.forEach(
              row => {
                if (jsonTiemposCorto[row.idMaquina]) {
                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                }
                else {
                  maquinas.push(row.idMaquina);
                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                }
              });
            var valoresFinales: any = [];
            maquinas.forEach(
              maquina => {
                var js: any = {};
                js.idMaquina = maquina;
                js.tiempo = jsonTiemposCorto[maquina];
                valoresFinales.push(js);
              });
            this.operacionesCorto = an3;
            this.tiemposCorto = valoresFinales;
            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //CAMBIAR EL FORMATO AL RESULTADO END

            r2 = true;

            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );

      }
    );
    this.planificadorService.get_grupos(version).subscribe(
      (json) => {

        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

        var grupos = (json as any).datOperaciones_grupos
        grupos.forEach(
          grupo => {
            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          });

        this.Jgrupos = grupos;
        this.gruposSeleccionados = [];

        this.DatOperaciones_grupos = grupos;

        r3 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    //this.getGrafico();
  }
  btnIneficiencia() {

    this.loadingPanel = true;

    this.aplicarIneficiencias = !this.aplicarIneficiencias;


    var r1, r2, r3: boolean = false;
    //this.getGrafico();
    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      (json) => {
        this.DatOperaciones = json;
        var an: any = json;
        this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

        r1 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    var version = this.JplanificadoresSelected.value;
    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.tiemposCorto = json;


        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          json => {
            //CARGAR TIEMPOS EJECUTADOS START 
            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            var an: any = json;
            var operacionesOrdenadas2 = {};
            var operacionesEnEjecucion = {};
            an.forEach(
              a => {
                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                else
                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
              });
            var an2: any = this.tiemposCorto;
            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            an2.forEach(
              row => {
                // añadir las 3 nuevas variables
                row['tiempoEjecutado'] = 0;
                row['operacionEmpezada'] = false;
                row['enEjecucion'] = false;

                //se ponen las que estan en ejecucion en este momento
                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                if (enEjecucion) {
                  if (enEjecucion == 1) {
                    row['enEjecucion'] = true;
                  }
                }

                //se descuenta el tiempo por maquina y operacion
                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                if (tiempoEjecutado) {
                  //se le pone que esta empezada
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            an2.forEach(
              row => {
                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                if (tiempoEjecutado) {
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //CARGAR TIEMPOS EJECUTADOS END
            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            var an3: any = an2;
            var jsonTiemposCorto: any = {};
            var maquinas: any = [];
            an3.forEach(
              row => {
                if (jsonTiemposCorto[row.idMaquina]) {
                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                }
                else {
                  maquinas.push(row.idMaquina);
                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                }
              });
            var valoresFinales: any = [];
            maquinas.forEach(
              maquina => {
                var js: any = {};
                js.idMaquina = maquina;
                js.tiempo = jsonTiemposCorto[maquina];
                valoresFinales.push(js);
              });
            this.operacionesCorto = an3;
            this.tiemposCorto = valoresFinales;
            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //CAMBIAR EL FORMATO AL RESULTADO END

            r2 = true;

            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );

      }
    );
    this.planificadorService.get_grupos(version).subscribe(
      (json) => {

        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

        var grupos = (json as any).datOperaciones_grupos
        grupos.forEach(
          grupo => {
            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          });

        this.Jgrupos = grupos;
        this.gruposSeleccionados = [];

        this.DatOperaciones_grupos = grupos;

        r3 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    //this.getGrafico();
  }
  //CAMBIO DE VERSION
  versionChanged(event) {

    this.loadingPanel = true;

    this.JplanificadoresSelected = event;

    this.cargarDatosFiltro();

    this.configuracionService.get_configuracion_planificador_V(this.JplanificadoresSelected.value).subscribe(
      result => {
        this.dtConfiguracionPlanificador = result[0];

        this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
        this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
        this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;

        var r1, r2, r3: boolean = false;
        //this.getGrafico();
        this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
          (json) => {
            this.DatOperaciones = json;
            var an: any = json;
            this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo);
            r1 = true;
            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );
        var version = this.JplanificadoresSelected.value;
        this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          json => {
            this.tiemposCorto = json;

            this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
              json => {
                //CARGAR TIEMPOS EJECUTADOS START 
                //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                var an: any = json;
                var operacionesOrdenadas2 = {};
                var operacionesEnEjecucion = {};
                an.forEach(
                  a => {
                    if (operacionesOrdenadas2[a['idOFs_Operacion']])
                      operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                    else
                      operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                    operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                    operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                  });
                var an2: any = this.tiemposCorto;
                //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                an2.forEach(
                  row => {
                    // añadir las 3 nuevas variables
                    row['tiempoEjecutado'] = 0;
                    row['operacionEmpezada'] = false;
                    row['enEjecucion'] = false;

                    //se ponen las que estan en ejecucion en este momento
                    var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                    if (enEjecucion) {
                      if (enEjecucion == 1) {
                        row['enEjecucion'] = true;
                      }
                    }

                    //se descuenta el tiempo por maquina y operacion
                    var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                    if (tiempoEjecutado) {
                      //se le pone que esta empezada
                      if (tiempoEjecutado > 0) {
                        row['operacionEmpezada'] = true;
                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                        //se descuenta el tiempo de las 3 partes que afecta 
                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                          row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                          operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                          operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                        } else {
                          operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                          operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                          row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                        }
                      }
                    }
                  });
                //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                an2.forEach(
                  row => {
                    var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                    if (tiempoEjecutado) {
                      if (tiempoEjecutado > 0) {
                        row['operacionEmpezada'] = true;
                        //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                        //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                        var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                        //se descuenta el tiempo de las 3 partes que afecta 
                        if (tiempoEjecutado <= maximoTiempoAppointment) {
                          row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                          operacionesOrdenadas2[row['idOperacion']] = 0;
                        } else {
                          operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                          row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                        }
                      }
                    }
                  });
                //CARGAR TIEMPOS EJECUTADOS END
                //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                var an3: any = an2;
                var jsonTiemposCorto: any = {};
                var maquinas: any = [];
                an3.forEach(
                  row => {
                    if (jsonTiemposCorto[row.idMaquina]) {
                      jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                    }
                    else {
                      maquinas.push(row.idMaquina);
                      jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                    }
                  });
                var valoresFinales: any = [];
                maquinas.forEach(
                  maquina => {
                    var js: any = {};
                    js.idMaquina = maquina;
                    js.tiempo = jsonTiemposCorto[maquina];
                    valoresFinales.push(js);
                  });
                this.operacionesCorto = an3;
                this.tiemposCorto = valoresFinales;
                this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                //CAMBIAR EL FORMATO AL RESULTADO END

                r2 = true;
                if (r1 && r2 && r3) {
                  this.operacionesSelected = [];
                  this.getGrafico();
                }
              }
            );


          }
        );
        this.planificadorService.get_grupos(version).subscribe(
          (json) => {

            this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

            var grupos = (json as any).datOperaciones_grupos
            grupos.forEach(
              grupo => {
                grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
              });

            this.Jgrupos = grupos;
            this.gruposSeleccionados = [];

            this.DatOperaciones_grupos = grupos;

            r3 = true;
            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );
        //this.getGrafico();
      });
  }

  private getGrafico() {
    this.actualizarReorganizandoVersion();
    //this.planificadorService.GetOperacionesPlanificadas().subscribe(
    //  (json) => {
    //    this.DatOperaciones = json;
    //var an: any = json;
    //this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)
    //  }
    //);

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    // var listaIdOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
    // var listaIdClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.idCliente);
    //var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.idPieza);
    var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.ids);
    listaIdPiezas = listaIdPiezas.reduce((acc, curVal) => { return acc.concat(curVal) }, []); //flatten array of arrays

    //todas las OF tienen que tener un valor asignado para cada semana. En caso de tener que marcar algo en rojo tambien se tedria que añadir aparte
    // LEEME   POR FIN TENEMOS FUERA DE TALLER!!!!
    //          el fuera de taller no se hace con la columna fuera de taller, esta se ha quedado OBSOLETA
    //          se hace con tipo 1 o tipo 2. tipo 1 hacer referencia a maquinas, tipo 2 a subcontratas
    var r1, r2, r3 = false
    this.planificadorService.get_largoMaquina(this.selectedMaquina, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias, this.selectedMaquinaTipo).subscribe(
      json => {

        this.dtOperacionesPlanificador = (json as any).dtOperaciones;
        this.dtTiemposMaquinas = (json as any).dtTiempos;

        this.Jplanificador = [];
        var dtOperaciones = this.myFunctions.copy(this.dtOperacionesPlanificador);
        dtOperaciones.forEach(operacion => {
          // Cogemos el tiempo estimado de esa operacion para esa maquina
          var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacion.idMaquina &&
            ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
              (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
          // ** en caso de que esa operacino no tenga tiempo en esa maquina, se cogera el primer tiempo estimado de cualquier otra maquina
          if (tiempos.length == 0) {
            tiempos = this.dtTiemposMaquinas.filter(f => ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
              (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
          }
          if (tiempos.length > 0) {
            // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
            // 1. tiempo estimado o predictivo
            // 2. tiene preparacion si es principal = 1
            var tiempo = tiempos[0];
            if (this.aplicarTiempoEstimado) {
              operacion.tiempoEstimado = tiempo.tiempoEstimadoEjecucion * operacion.cantidadPlanificada
              if (operacion.principal)
                operacion.tiempoEstimado += tiempo.tiempoEstimadoPreparacion
            } else {
              operacion.tiempoEstimado = tiempo.tiempoPredictivoEjecucion * operacion.cantidadPlanificada
              if (operacion.principal)
                operacion.tiempoEstimado += tiempo.tiempoPredictivoPreparacion
            }
            // 3. se aplica infeciciencia en caso de ser necesario
            operacion.tiempoIneficiencia = 0;
            if (this.aplicarIneficiencias) {
              operacion.tiempoIneficiencia = (operacion.tiempoEstimado * tiempo.ineficiencia / 100);
            }
            operacion.tiempo = operacion.tiempoEstimado + operacion.tiempoIneficiencia;
          }
          // // Si la maquina permite agrupadas, SOLO permite agrupadas, sino SOLO permite operaciones
          // if ((this.JmaquinaSelected.agruparProcesos && operacion.idOperacionesGrupos > 0) || !this.JmaquinaSelected.agruparProcesos && operacion.idOperacion > 0 ){
          this.Jplanificador.push(operacion);
          // }
        });


        // ahora que ya tenemos los verdaderos tiempos por maquina, sacaremos los tiempos para mostrar en el grid inferior
        this.JoperacionesYgrupos = [];

        this.Joperaciones.forEach(
          operacion => {
            operacion.numFila = this.JoperacionesYgrupos.length;

            // Cogemos el tiempo estimado de esa operacion para esa maquina
            var tiempos = this.dtTiemposMaquinas.filter(f => f.idMaquina == operacion.idMaquina &&
              ((f.idOperacion == operacion.idOperacion && operacion.idOperacion > 0) ||
                (f.idOperacionesGrupos == operacion.idOperacionesGrupos && operacion.idOperacionesGrupos > 0)))
            if (tiempos.length > 0) {
              // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
              // 1. tiempo estimado o predictivo
              // 2. tiene preparacion si es principal = 1
              var tiempo = tiempos[0];
              if (this.aplicarTiempoEstimado) {
                operacion.tiempoEstimados = tiempo.tiempoEstimadoEjecucion
                operacion.tiempoEstimadoPreparacions = tiempo.tiempoEstimadoPreparacion
              } else {
                operacion.tiempoEstimados = tiempo.tiempoPredictivoEjecucion
                operacion.tiempoEstimadoPreparacions = tiempo.tiempoPredictivoPreparacion
              }
              // 3. se aplica infeciciencia en caso de ser necesario
              operacion.tiempoIneficiencia = 0;
              if (this.aplicarIneficiencias) {
                operacion.tiempoIneficiencia = (operacion.tiempoEstimados * tiempo.ineficiencia / 100);
              }
            }

            var operacionYgrupo = {
              año: operacion.año,
              cantidad: operacion.cantidad,
              cantidadAgrupada: operacion.cantidadAgrupada,
              dividida: operacion.dividida,
              fechaIni: operacion.fechaIni,
              fechaLimite: operacion.fechaLimite,
              grupoPrincipal: operacion.grupoPrincipal,
              numFila: operacion.numFila,
              secuencia: operacion.secuencia,
              nombre: '',
              idMaquina: operacion.idMaquina,
              idOf: operacion.idOf,
              idPieza: operacion.idPieza,
              idParte: operacion.idParte,
              idRuta: operacion.idRuta,
              idOperacion: operacion.idOperacion,
              idOperacionesGrupos: -1,
              peso: null,
              temperatura: null,
              unidades: null,
              principal: operacion.principal,
              orden: operacion.orden,
              semana: operacion.semana,
              total: operacion.total,
              tiempoEstimadoPreparacions: operacion.tiempoEstimadoPreparacions,
              tiempoEstimados: operacion.tiempoEstimados,
              tipo: operacion.tipo,
              refOF: operacion.refOf,
              operacion: operacion.operacion
            }

            if (operacion.cliente != '')
              operacionYgrupo.nombre += ' - ' + operacion.cliente
            if (operacion.plano != '')
              operacionYgrupo.nombre += ' - ' + operacion.plano
            if (operacion.pieza != '')
              operacionYgrupo.nombre += ' - ' + operacion.pieza
            if (operacion.pieza != '')
              operacionYgrupo.nombre += ' - ' + operacion.pieza
            // if(operacion.plano != '') 
            // operacionYgrupo.nombre += ' - ' + operacion.plano
            if (operacion.parte != '' && !this.ocultarParte)
              operacionYgrupo.nombre += ' - ' + operacion.parte

            if (operacionYgrupo.nombre != "")
              operacionYgrupo.nombre = operacionYgrupo.nombre.substring(2, operacionYgrupo.nombre.length)


            this.JoperacionesYgrupos.push(operacionYgrupo);
          });
        this.Jgrupos.forEach(
          grupo => {
            //los grupos  no se tratan igual que las operaciones, pues las operaciones se usan para cargar el grafico y ademas mostrar cuando son clicadas. en el caso de grpos tambien estan las operaciones internas para mostrar
            // pero los grupos para cargar los tiempos. por esta razon, las operaciones encima vienen filtradas por maquina pero estos grupos NO! y se muestran en todas las maquinas todos los grupos. 
            // Antes de añadir un grupo al grid, lo filtraremos en este momento. 
            if (grupo.idMaquina == this.JmaquinaSelected.id) {
              grupo.numFila = this.JoperacionesYgrupos.length;


              // Cogemos el tiempo estimado de esa operacion para esa maquina
              var tiempos = this.dtTiemposMaquinas.filter(f => (f.idMaquina == grupo.idMaquina &&
                (f.idOperacion == grupo.idOperacion && grupo.idOperacion > 0) ||
                (f.idOperacionesGrupos == grupo.idOperacionesGrupos && grupo.idOperacionesGrupos > 0)))

              if (tiempos.length > 0) {
                // una vez tenemos los tiempos estimados de la operacion, se coge la primera linea y se tendra en cuenta:
                // 1. tiempo estimado o predictivo
                // 2. tiene preparacion si es principal = 1
                var tiempo = tiempos[0];
                if (this.aplicarTiempoEstimado) {
                  grupo.tiempoEstimado = tiempo.tiempoEstimadoEjecucion
                } else {
                  grupo.tiempoEstimado = tiempo.tiempoPredictivoEjecucion
                }
                // 3. se aplica infeciciencia en caso de ser necesario
                grupo.tiempoIneficiencia = 0;
                if (this.aplicarIneficiencias) {
                  grupo.tiempoIneficiencia = (grupo.tiempoEstimado * tiempo.ineficiencia / 100);
                }
              }

              var operacionYgrupo = {
                año: grupo.año,
                cantidad: grupo.cantidad,
                cantidadAgrupada: grupo.cantidad,
                dividida: grupo.dividida,
                fechaIni: grupo.fechaIni,
                fechaLimite: grupo.fechaLimite,
                grupoPrincipal: grupo.grupoPrincipal,
                numFila: grupo.numFila,
                secuencia: '',
                nombre: grupo.nombre,
                idMaquina: grupo.idMantenimiento,
                idOf: -1,
                idPieza: -1,
                idParte: -1,
                idRuta: -1,
                idOperacion: -1,
                idOperacionesGrupos: grupo.idOperacionesGrupos,
                peso: grupo.peso,
                temperatura: grupo.temperatura,
                unidades: grupo.unidades,
                principal: grupo.principal,
                orden: null,
                semana: grupo.semana,
                total: grupo.total,
                tiempoEstimadoPreparacions: 0,
                tiempoEstimados: grupo.tiempoEstimado,
                tipo: -1,
                refOF: '',
                operacion: ''
              }

              this.JoperacionesYgrupos.push(operacionYgrupo);
            } else {
              grupo.numFila = this.JoperacionesYgrupos.length;
            }
          });

        this.JoperacionesYgrupos.sort((s1, s2) => {
          if (s1.machine > s2.machine) return 1
          else if (s1.machine < s2.machine) return -1
          else if (s1.año > s2.año) return 1
          else if (s1.año < s2.año) return -1
          else if (s1.semana > s2.semana) return 1
          else if (s1.semana < s2.semana) return -1
          else return 0
        });

        r1 = true;
        if (r1 && r2 && r3)
          this.cargarGrafico();
      });
    this.planificadorService.get_turnos_en_fechas(this.selectedMaquina).subscribe(
      json => {
        (json as any).forEach(
          turno => {
            turno.fechaIni = new Date(turno.fechaIni);
            turno.fechaFin = new Date(turno.fechaFin);

            turno.fechaIniTeorico = new Date(turno.fechaIniTeorico);
            turno.fechaFinTeorico = new Date(turno.fechaFinTeorico);
            // turno.fechaIni = this.myFunctions.sqlToJsDate(turno.fechaIni);
            // turno.fechaFin = this.myFunctions.sqlToJsDate(turno.fechaFin);

            // turno.fechaIniTeorico = this.myFunctions.sqlToJsDateT(turno.fechaIniTeorico);
            // turno.fechaFinTeorico = this.myFunctions.sqlToJsDateT(turno.fechaFinTeorico);
          });

        this.Jturnos = json;

        r2 = true;
        if (r1 && r2 && r3)
          this.cargarGrafico();
      });
    this.planificadorService.bloqueos_por_mantenimientos(this.selectedMaquina).subscribe(
      json => {
        // se calculan todos los bloqueos que crearan los mantenimientos
        (json as any).forEach(
          mantenimiento => {
            mantenimiento.fechaIni = this.myFunctions.datetimeToDate(new Date(mantenimiento.fechaIni));
            mantenimiento.fechaFin = this.myFunctions.datetimeToDate(new Date(mantenimiento.fechaFin));
          });

        this.Jmantenimientos = json;

        r3 = true;
        if (r1 && r2 && r3)
          this.cargarGrafico();
      });
  }

  private cargarGrafico() {
    /* SE CALCULA EL TIEMPO DE BLOQUEOS */
    if (false) {
      var bloqueosFechas_mantenimientos = [];
      this.Jmantenimientos.forEach(
        mantenimiento => {
          var fechaIni_mantenimiento: Date = this.myFunctions.dateAddSeconds(this.myFunctions.dateCopy(mantenimiento.fechaIni), this.myFunctions.HH_MM_ToSeconds(mantenimiento.horaIni))
          var fechaFin_mantenimiento: Date = this.myFunctions.dateAddSeconds(this.myFunctions.dateCopy(fechaIni_mantenimiento), mantenimiento.duracion)

          switch (mantenimiento.idTipo) {
            case 1: //DIARIO                    
              while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                var bloqueoFechas_mantenimiento = {
                  fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                  fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                  idMantenimiento: mantenimiento.idMantenimiento,
                  idMaquina: mantenimiento.idMaquina
                }
                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                fechaIni_mantenimiento = this.myFunctions.dateAddDays(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento);
                fechaFin_mantenimiento = this.myFunctions.dateAddDays(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento);
              }
              break;
            case 2://SEMANAL       
              while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                var bloqueoFechas_mantenimiento = {
                  fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                  fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                  idMantenimiento: mantenimiento.idMantenimiento,
                  idMaquina: mantenimiento.idMaquina
                }
                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                fechaIni_mantenimiento = this.myFunctions.dateAddDays(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento * 7);
                fechaFin_mantenimiento = this.myFunctions.dateAddDays(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento * 7);
              }
              break;
            case 3://MENSUAL
              while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                var bloqueoFechas_mantenimiento = {
                  fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                  fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                  idMantenimiento: mantenimiento.idMantenimiento,
                  idMaquina: mantenimiento.idMaquina
                }
                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                fechaIni_mantenimiento = this.myFunctions.dateAddMonths(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento);
                fechaFin_mantenimiento = this.myFunctions.dateAddMonths(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento);
              }
              break;
            case 4://ANUAL
              while (this.myFunctions.datetimeToDate(fechaIni_mantenimiento) <= mantenimiento.fechaFin) {
                var bloqueoFechas_mantenimiento = {
                  fechaFin: this.myFunctions.dateCopy(fechaFin_mantenimiento),
                  fechaini: this.myFunctions.dateCopy(fechaIni_mantenimiento),
                  idMantenimiento: mantenimiento.idMantenimiento,
                  idMaquina: mantenimiento.idMaquina
                }
                bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                fechaIni_mantenimiento = this.myFunctions.dateAddMonths(fechaIni_mantenimiento, mantenimiento.frecuenciaMantenimiento * 12);
                fechaFin_mantenimiento = this.myFunctions.dateAddMonths(fechaFin_mantenimiento, mantenimiento.frecuenciaMantenimiento * 12);
              }
              break;
            case 5://POR TURNOS
              var turnos_que_afectan = this.Jturnos.filter(f => f.idMaquina == mantenimiento.idMaquina && f.idTipoTurno == mantenimiento.idTurno);

              var cont = 0;
              turnos_que_afectan.forEach(
                turno => {
                  cont++; // se suma 1 al principio prara checkear la frecuencia directamente
                  if (cont >= mantenimiento.frecuenciaMantenimiento) {
                    var bloqueoFechas_mantenimiento = {
                      fechaFin: this.myFunctions.dateAddSeconds(this.myFunctions.dateCopy(turno.fechaIniTeorico), mantenimiento.duracion),
                      fechaini: this.myFunctions.dateCopy(turno.fechaIniTeorico),
                      idMantenimiento: mantenimiento.idMantenimiento,
                      idMaquina: mantenimiento.idMaquina
                    }
                    bloqueosFechas_mantenimientos.push(bloqueoFechas_mantenimiento);
                    cont = 0;
                  }
                });

              break;
          }
        });
    }
    var tiemposTurnos = [];
    var tiempoTurno = {
      año: 0
      , capacidadTurno: 0
      , cliente: ''
      , dividida: 0
      , fechaIni: ''
      , fechaLimite: ''
      , fueraDeTiempo: 0
      , fueraTaller: false
      , grupoPrincipal: -1
      , idCliente: -1
      , idOperacionesGrupos: -1
      , idMaquina: -1
      , idOF: -1
      , idOperacion: -1
      , idOperacionVisible: -1
      , idParte: -1
      , idPieza: -1
      , maquina: ''
      , operacionVisible: ''
      , parte: ''
      , pieza: ''
      , plano: ''
      , operacionEmpezada: false
      , preTiempo: 0
      , principal: 1
      , proyecto: ''
      , refOF: ''
      , refPieza: ''
      , semana: 0
      , tiempo: 0
      , tiempoEjecutado: 0
      , tiempoIneficiencia: 0
      , tiempoSinBloqueos: 0
      , tipo: 1
    }
    this.Jturnos.forEach(
      turno => {
        if (tiempoTurno.semana != turno.semana) {
          if (tiempoTurno.semana > 0) tiemposTurnos.push(this.myFunctions.copy(tiempoTurno)); // para evitar la linea 0
          tiempoTurno = {
            año: turno.año
            , capacidadTurno: 0
            , cliente: ''
            , dividida: 0
            , fechaIni: turno.fechaPlanificado
            , fechaLimite: turno.fechaPlanificado
            , fueraDeTiempo: 0
            , fueraTaller: false
            , grupoPrincipal: -1
            , idCliente: -1
            , idOperacionesGrupos: -1
            , idMaquina: turno.idMaquina
            , idOF: -1
            , idOperacion: -1
            , idOperacionVisible: -1
            , idParte: -1
            , idPieza: -1
            , maquina: ''
            , operacionVisible: ''
            , parte: ''
            , pieza: ''
            , plano: ''
            , operacionEmpezada: false
            , preTiempo: 0
            , principal: 1
            , proyecto: ''
            , refOF: ''
            , refPieza: ''
            , semana: turno.semana
            , tiempo: 0
            , tiempoEjecutado: 0
            , tiempoIneficiencia: 0
            , tiempoSinBloqueos: (turno.fechaFin.getTime() - turno.fechaIni.getTime()) / 1000
            , tipo: 1
          }
        }
        if (false) {
          var bloqueos_turno = bloqueosFechas_mantenimientos.filter(f => f.fechaini < turno.fechaFinTeorico && f.fechaFin > turno.fechaIniTeorico && f.idMaquina == turno.idMaquina);
          bloqueos_turno.sort((s1, s2) => {
            if (s1.fechaini > s2.fechaini) return 1
            else if (s1.fechaIni < s2.fechaIni) return -1
            else return 0
          });
        }
        var fechaMin = turno.fechaIni;
        if (false) {
          bloqueos_turno.forEach(
            bloqueo => {
              if (bloqueo.fechaini > fechaMin) {
                var dateDiff = (bloqueo.fechaini.getTime() - fechaMin.getTime()) / 1000
                tiempoTurno.preTiempo += dateDiff;
                tiempoTurno.tiempo += dateDiff;
                tiempoTurno.capacidadTurno += dateDiff * turno.capacidadPorDefectoPlanificador / 100;
              }
              fechaMin = this.myFunctions.Max_date(bloqueo.fechaFin, fechaMin);
            });
        }
        if (turno.fechaFin > fechaMin) {
          var dateDiff = (turno.fechaFin.getTime() - fechaMin.getTime()) / 1000
          tiempoTurno.preTiempo += dateDiff;
          tiempoTurno.tiempo += dateDiff;
          tiempoTurno.capacidadTurno += dateDiff * turno.capacidadPorDefectoPlanificador / 100;
        }
      });
    if (tiempoTurno.semana > 0)
      tiemposTurnos.push(this.myFunctions.copy(tiempoTurno));

    (tiemposTurnos as any).forEach(
      element => {
        this.Jplanificador.push(element);
      });

    /* ANTES DE NADA! DESCONTAR EL TIEMPO EN EJECUCION QUE HA SOBRADO DEL CORTO START */
    this.Jplanificador.forEach(
      row => {
        row['tiempoEjecutado'] = 0;
        row['operacionEmpezada'] = false;
        row['enEjecucion'] = false;

        //se descuenta el tiempo por maquina y operacion
        var tiempoEjecutado = this.operacionesOrdenadasEjecucionFueraCorto[row['idMaquina'] + '_' + row['idOperacion']];
        if (tiempoEjecutado) {
          //se le pone que esta empezada
          if (tiempoEjecutado > 0) {
            row['operacionEmpezada'] = true;
            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //se descuenta el tiempo de las 3 partes que afecta 
            if (tiempoEjecutado <= maximoTiempoAppointment) {
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
              this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] - tiempoEjecutado;
              this.operacionesOrdenadasEjecucionFueraCorto[row['idMaquina'] + '_' + row['idOperacion']] = 0;
            } else {
              this.operacionesOrdenadasEjecucionFueraCorto[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
              this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] - maximoTiempoAppointment;
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            }
          }
        }
      });
    //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
    this.Jplanificador.forEach(
      row => {
        var tiempoEjecutado = this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']];
        if (tiempoEjecutado) {
          if (tiempoEjecutado > 0) {
            row['operacionEmpezada'] = true;
            //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //se descuenta el tiempo de las 3 partes que afecta 
            if (tiempoEjecutado <= maximoTiempoAppointment) {
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
              this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = 0;
            } else {
              this.operacionesOrdenadasEjecucionFueraCorto[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
              row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            }
          }
        }
      });
    //   se descuenta lo ejecutado al tiempo total
    this.Jplanificador.forEach(
      row => {
        if (row['tiempoEjecutado'] > 0) {
          row['tiempo'] = row['tiempo'] - row['tiempoEjecutado'];
          row['preTiempo'] = row['preTiempo'] - row['tiempoEjecutado'];
        }

      });

    /* ANTES DE NADA! DESCONTAR EL TIEMPO EN EJECUCION QUE HA SOBRADO DEL CORTO END*/

    this.JplanificadorDentroTaller = this.Jplanificador.filter(function (i, n) {
      return i.fueraTaller == 0;
    });
    this.JplanificadorFueraTaller = this.Jplanificador.filter(function (i, n) {
      return i.fueraTaller == 1;
    });

    if (Object.keys(this.Jplanificador).length > 0) {
      //segun el año hay que sacar en que dia empezo y se le resta a la suma de semanas completas para tener el inicio de esa semana.
      //var inicioAñoIni = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
      //this.JfechaMin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
      //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
      //  ((inicioAñoIni.getDay() + 6) * (1000 * 60 * 60 * 24)));

      this.JfechaMin = this.startOfWeek(this.myFunctions.getDateNow());

      var Jfin = this.Jplanificador.filter(function (i, n) {
        return i.idParte != "-1";
      });
      if (Object.keys(Jfin).length == 0) {
        //var inicioAñoFin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
        //this.JfechaMax = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
        //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
        //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

        this.JfechaMax = this.startOfWeek(this.myFunctions.getDateNow());
        this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * 8));
      }
      else {
        // var inicioAñoFin = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0));
        // this.JfechaMax = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0).getTime() +
        //   (Jfin[Object.keys(Jfin).length - 1].semana * 7 * (1000 * 60 * 60 * 24)) -
        //   ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));
        // //var fecha: Date = Jfin[Object.keys(Jfin).length - 1].fechaIni;
        // //this.JfechaMax = this.startOfWeek(fecha);

        // var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
        // if (weeks < 9)
        //   this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * (9 - weeks)));

        var inicioAñoFin = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0));

        var añoMax = 0
        var semanaMax = 0
        Jfin.forEach(
          row => {
            if (row.año > añoMax || (row.año == añoMax && row.semana > semanaMax)) {
              añoMax = row.año
              semanaMax = row.semana
            }
          });

        // ESTA FORMA NO FUNCIONA SI LA ULTIMA MAQUINA NO TIENE NADA PLANIFICADO O ESTA MENOS PLANIFICADA QUE LAS DEMAS!
        //this.JfechaMax = new Date(new Date(Jfin[Object.keys(Jfin).length - 1].año, 0, 0).getTime() +
        //  (Jfin[Object.keys(Jfin).length - 1].semana * 7 * (1000 * 60 * 60 * 24)) -
        //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));
        this.JfechaMax = new Date(new Date(añoMax, 0, 0).getTime() +
          (semanaMax * 7 * (1000 * 60 * 60 * 24)) -
          ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

        //var fecha: Date = Jfin[Object.keys(Jfin).length - 1].fechaIni;
        //this.JfechaMax = this.startOfWeek(fecha);

        var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
        if (weeks < 8)
          this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * (8 - weeks)));

      }
    }
    else {
      //si nos igual da igual la semana que sea.
      //var inicioAñoIni = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
      //this.JfechaMin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
      //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
      //  ((inicioAñoIni.getDay() + 6) * (1000 * 60 * 60 * 24)));

      this.JfechaMin = this.startOfWeek(this.myFunctions.getDateNow());

      //var inicioAñoFin = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
      //this.JfechaMax = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
      //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
      //  ((inicioAñoFin.getDay() + 6) * (1000 * 60 * 60 * 24)));

      this.JfechaMax = this.startOfWeek(this.myFunctions.getDateNow());

      this.JfechaMax.setDate(this.JfechaMax.getDate() + (7 * 8));
    }

    this.cargarGraficoDentro(200);
  }

  //CARGAR DATOS MAQUINAS Y SUBCONTRATAS!
  private cargarGraficoDentro(height) {
    //Numero de semanas que vamos a mostrar
    var weeks = Math.round((this.JfechaMax.getTime() - this.JfechaMin.getTime()) / 604800000) + 1;
    var weekArray = [];
    weekArray.push("parte");
    for (let i = 0; i <= weeks; i++) {
      weekArray.push(0);
    }

    var data = [];
    var colores: any = {};
    var tipos: any = {};
    var grupos = [];
    var gruposArray = [];

    var listaIdOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
    var listaIdClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.idCliente);
    //var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.idPieza);
    var listaIdPiezas = (this.piezasSeleccionados === undefined) ? [] : this.piezasSeleccionados.map(a => a.ids);
    listaIdPiezas = listaIdPiezas.reduce((acc, curVal) => { return acc.concat(curVal) }, []); //flatten array of arrays

    /*   CARGAR TURNOS Y CAPACIDADES  */
    //se añade al grupo para que vayan todos juntos en una sola columna
    var parte = this.translateService.instant('horasSemana');
    gruposArray.push(parte);
    var parteCapacidad = this.translateService.instant('capacidad');
    gruposArray.push(parteCapacidad);
    //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
    colores[parte] = '#edb90e';//'#a2a2a2';
    colores[parteCapacidad] = '#ee8625';
    //se añade a la tabla de semanas
    var weekArray = [];
    weekArray.push(parte);
    //se añaden los tiempos
    for (let i = 0; i <= weeks; i++) {
      weekArray.push(0);
    }
    data.push(weekArray)
    var weekArrayCapacidad = [];
    weekArrayCapacidad.push(parteCapacidad);
    //se añaden los tiempos
    for (let i = 0; i <= weeks; i++) {
      weekArrayCapacidad.push(0);
    }
    data.push(weekArrayCapacidad)



    if (Object.keys(this.JplanificadorDentroTaller).length > 0) {
      this.JplanificadorDentroTaller.forEach(
        row => {
          //si el OF es nuevo se añade al sistema de tablas
          if (row.idParte == '-1' && row.idOperacionesGrupos == "-1") {
            if (row.tipo == 1) {
              //MAQUINAS!
              var indexSemanas = 0;
              this.Jsemanas.forEach(f => {
                if (f.semana == row.semana && f.año == row.año)
                  indexSemanas = f.value + 1;
              });
              for (let idParte of data) {
                if (idParte[0] == parte)
                  if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                    idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                  }
                if (idParte[0] == parteCapacidad)
                  if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                    idParte[indexSemanas] = idParte[indexSemanas] + row.capacidadTurno; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                  }
              }
            }
            else {
              //SUBCONTRATADOS!! (esto es especial)
              var indexSemanas = 0;
              weekArray.forEach(
                week => {
                  indexSemanas++;
                  for (let idParte of data) {
                    if (idParte[0] == parte)
                      if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                        idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                      }
                    if (idParte[0] == parteCapacidad)
                      if (indexSemanas <= weeks + 1 && indexSemanas > 0) {
                        idParte[indexSemanas] = idParte[indexSemanas] + row.capacidadTurno; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
                      }
                  }
                });
            }
          }
        }
      );
    }
    /*   CARGAR TURNOS Y CAPACIDADES END   */

    tipos[this.translateService.instant('horasSemana')] = 'step';
    tipos[this.translateService.instant('capacidad')] = 'step';

    // tipos[this.translateService.instant('planificadorCorto')] = 'bar';




    /*   CARGAR CORTO  POR OPERACION */
    if (data[0]) {
      var TtotalenSemanaCorto = 0
      var semanaCorto = 1
      var quedanTurnos = true

      this.operacionesCorto.forEach(
        row => {
          // se mira que mauqina es
          if (row.idMaquina == this.selectedMaquina) {

            //si el OF es nuevo #se añade al sistema de tablas
            //row.idParte + ' - ' + 
            var parte = row.idOperacionVisible + ' - ' + row.refOF + ' - ' + row.parte + ' - ' + row.operacionVisible;

            //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
            if ((listaIdOfs.includes(row.idOF) || listaIdOfs.length == 0) &&
              (listaIdClientes.includes(row.idCliente) || listaIdClientes.length == 0) &&
              (listaIdPiezas.includes(row.idPieza) || listaIdPiezas.length == 0)) {
              colores[parte + " (" + this.translateService.instant('corto') + ")"] = '#afe0e8';
            } else {
              colores[parte + " (" + this.translateService.instant('corto') + ")"] = '#e4eeee';
            }

            gruposArray.push(parte + " (" + this.translateService.instant('corto') + ")");

            var weekArray = [];
            weekArray.push(parte + " (" + this.translateService.instant('corto') + ")");
            for (let i = 0; i <= weeks; i++) {
              weekArray.push(0);
            }

            //check si hay turnos y avanzar a proxima semana con tiempos START
            if (data[1][semanaCorto] == 0 && quedanTurnos) {
              quedanTurnos = false
              for (let i = semanaCorto; i < data[1].length; i++) {
                if (data[1][i] > 0)
                  quedanTurnos = true;
              }
            }
            var limite = 0;
            while (limite < 10 && data[1][semanaCorto] == 0 && quedanTurnos) {
              semanaCorto += 1;
              limite += 1;
            }
            //check si hay turnos y avanzar a proxima semana con tiempos END

            var tiempoOperacionAMeter = row.tiempo + 0; // tiempo a meter de la operacion actual.
            while (tiempoOperacionAMeter > 0) { // se hace un loop por si se corta en mas de 1 semana
              //SemanaCorto: en que semana esta metiendo el planificador corto.
              var tiempoTurnoEnSemana = data[1][semanaCorto]; // cuanto tiempo de turno (con capacidad) dispone la semana en la que estamos metiendo el corto.
              //TtotalenSemanaCorto; // cuanto tiempo hemos metido del corto en la semana en la que estamos metiendo el corto.              
              // weekArray[semanaCorto] : // row para meter en el grafico de la operacion actual con los tiempos por semana
              if (TtotalenSemanaCorto + tiempoOperacionAMeter < tiempoTurnoEnSemana || !quedanTurnos) {
                // La semana tiene espacio suficiente para la operacion actual.
                weekArray[semanaCorto] = tiempoOperacionAMeter;
                TtotalenSemanaCorto += tiempoOperacionAMeter;
                tiempoOperacionAMeter = 0;
              } else {
                // La semana NO tiene espacio suficiente para la operacion actual.
                var tempoDeOperacionQueEntraEnSemana = tiempoTurnoEnSemana - TtotalenSemanaCorto;
                weekArray[semanaCorto] = tempoDeOperacionQueEntraEnSemana;
                tiempoOperacionAMeter = tiempoOperacionAMeter - tempoDeOperacionQueEntraEnSemana;
                // Se pasa a la siguiente semana y se resetea el tiempo total.
                semanaCorto += 1;
                tiempoTurnoEnSemana = data[1][semanaCorto];
                //check si hay turnos y avanzar a proxima semana con tiempos START
                if (tiempoTurnoEnSemana == 0 && quedanTurnos) {
                  quedanTurnos = false
                  for (let i = semanaCorto; i < data[1].length; i++) {
                    if (data[1][i] > 0)
                      quedanTurnos = true;
                  }
                }
                var limite = 0;
                while (limite < 100 && tiempoTurnoEnSemana == 0 && quedanTurnos) {
                  semanaCorto += 1;
                  tiempoTurnoEnSemana = data[1][semanaCorto];
                  limite += 1;
                }
                //check si hay turnos y avanzar a proxima semana con tiempos END
                TtotalenSemanaCorto = 0; //se reinicia el tiempo de la semana 
              }
            }
            // Se añade la linea de la operacion al grafico
            data.push(weekArray);
          }
        });
    }
    /*   CARGAR CORTO END   */

    /*   CARGAR LARGO   */
    if (Object.keys(this.JplanificadorDentroTaller).length > 0) {
      this.JplanificadorDentroTaller.forEach(
        row => {
          //si el OF es nuevo #se añade al sistema de tablas
          var negativo: string = '';
          if (row.fueraDeTiempo == 2)
            negativo = '-';
          //row.idParte + ' - ' 
          var parte = negativo + row.idOperacionVisible + ' - ' + row.refOF + ' - ' + row.parte + ' - ' + row.operacionVisible;

          if (row.idParte != '-1') {
            if (!gruposArray.includes(parte)) {
              //se añade al grupo para que vayan todos juntos en una sola columna
              gruposArray.push(parte);
              //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
              if ((listaIdOfs.includes(row.idOF) || listaIdOfs.length == 0) &&
                (listaIdClientes.includes(row.idCliente) || listaIdClientes.length == 0) &&
                (listaIdPiezas.includes(row.idPieza) || listaIdPiezas.length == 0)) {
                if (row.fueraDeTiempo == 0) {
                  colores[parte] = '#3289a8'; //AZUL
                } else if (row.fueraDeTiempo == 1) {
                  colores[parte] = '#eb8f34'; //NARANJA
                } else {
                  colores[parte] = '#EA4335'; //ROJO
                }
              } else {
                if (row.fueraDeTiempo == 0) {
                  colores[parte] = '#D6E7EE'; //AAZUL
                } else if (row.fueraDeTiempo == 1) {
                  colores[parte] = '#FBE9D6'; //NARANJA
                } else {
                  colores[parte] = '#F4D6D8'; //ROJO
                }
              }
              //se añade a la tabla de semanas
              var weekArray = [];
              weekArray.push(parte);
              for (let i = 0; i <= weeks; i++) {
                weekArray.push(0);
              }
              data.push(weekArray)
            }

            var indexSemanas = 0;
            this.Jsemanas.forEach(f => {
              if (f.semana == row.semana && f.año == row.año)
                indexSemanas = f.value + 1;
            });

            for (let idParte of data)
              if (idParte[0] == parte)
                if (indexSemanas <= weeks + 1 && indexSemanas > 0)
                  idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1
          } else if (row.idOperacionesGrupos != '-1') {
            parte = "Grupo " + row.idOperacionesGrupos;

            if (!gruposArray.includes(parte)) {
              //se añade al grupo para que vayan todos juntos en una sola columna
              gruposArray.push(parte);
              //se le da color en funcion de su estado! SI ALGO SE FILTRA SIEMPRE SE VERA GRIS! da igual su estado.
              if ((listaIdOfs.includes(row.idOF) || listaIdOfs.length == 0) &&
                (listaIdClientes.includes(row.idCliente) || listaIdClientes.length == 0) &&
                (listaIdPiezas.includes(row.idPieza) || listaIdPiezas.length == 0)) {
                if (row.fueraDeTiempo == 0) {
                  colores[parte] = '#3289a8'; //AZUL
                } else if (row.fueraDeTiempo == 1) {
                  colores[parte] = '#eb8f34'; //NARANJA
                } else {
                  colores[parte] = '#EA4335'; //ROJO
                }
              } else {
                if (row.fueraDeTiempo == 0) {
                  colores[parte] = '#D6E7EE'; //AAZUL
                } else if (row.fueraDeTiempo == 1) {
                  colores[parte] = '#FBE9D6'; //NARANJA
                } else {
                  colores[parte] = '#F4D6D8'; //ROJO
                }
              }
              //se añade a la tabla de semanas
              var weekArray = [];
              weekArray.push(parte);
              for (let i = 0; i <= weeks; i++) {
                weekArray.push(0);
              }
              data.push(weekArray)
            }

            var indexSemanas = 0;
            this.Jsemanas.forEach(f => {
              if (f.semana == row.semana && f.año == row.año)
                indexSemanas = f.value + 1;
            });

            for (let idParte of data)
              if (idParte[0] == parte)
                if (indexSemanas <= weeks + 1 && indexSemanas > 0)
                  idParte[indexSemanas] = idParte[indexSemanas] + row.tiempo; //se hace +1 por el indice, el primero siempre es la parte, se le hace  +1 por la semna, la 0 es la 1

          }
        }
      );

    }
    else {
      //si el OF es nuevo se añade al sistema de tablas
      var titulo: string = this.translateService.instant('dentroDeTaller');
      if (!gruposArray.includes(titulo)) {
        //se añade al grupo para que vayan todos juntos en una sola columna
        gruposArray.push(titulo);
        //se le da color en funcion de su estado        
        colores[titulo] = '#afe0e8';

        //se añade a la tabla de semanas
        var weekArray = [];
        weekArray.push(titulo);
        for (let i = 0; i <= weeks; i++) {
          weekArray.push(0);
        }
        data.push(weekArray)
      }
    }
    /*   CARGAR LARGO  END  */

    //Este tiene que ser un array de arrays, tiene opcion de agruar en cachos para hacer diferentes torres
    grupos.push(gruposArray.filter(f => f != this.translateService.instant('horasSemana') && f != this.translateService.instant('capacidad')));

    var max = this.conseguir_maximo(data);

    var th = this;
    var chartDentro = c3.generate({
      size: {
        height: height
      },
      bindto: '#graficoCargaMaquina',
      data: {
        type: 'bar',
        columns: data,
        groups: grupos,
        colors: colores,
        types: tipos,
        order: null,
        onclick: (e) => {
          this.clickGrafico(e);
        },
        selection: {
          draggable: true
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      legend: {
        show: false
      },
      axis: {
        y: {
          max: max,
          padding: {
            bottom: 10,
            top: -20
          },
          tick: {
            values: [40 * 3600, 2 * 40 * 3600, 3 * 40 * 3600],
            format: function (d) { return Math.round(d / 3600) + ' h'; }
          }
        },
        x: {
          tick: {
            format: (d) => {
              var semana = '';
              this.Jsemanas.forEach(f => {
                if (f.value == d)
                  semana = this.translateService.instant('semana') + ' ' + f.text;
              });
              return semana;
            }
          }
        }
      },
      tooltip: {
        grouped: false,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          // Use default rendering
          if (d.length == 0)
            return "";
          else {//return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
            d = d.sort((a, b) => a.tooltipOpder - b.tooltipOpder);

            // Use default rendering
            if (d.length == 0)
              return "";
            else {
              //let auxiString1 = '<div class="c3-tooltip" style="overflow-y: scroll; height: 200px;">'; <!--todo mari, scroll tooltip-->
              let auxiString1 = '<div class="c3-tooltip">';
              auxiString1 += "<table><tbody>";
              auxiString1 += "<tr><th colspan='2'>" + th.translateService.instant('semana') + ' ' + th.Jsemanas[d[0].x].text + "</th></tr>";

              d.forEach(f => {
                var color = "<span style='background-color:" + colores[f.id] + "'></span>";
                auxiString1 += "<tr class=c3-tooltip-name--" + f.id.replace(' ', '-') + "'>";
                auxiString1 += "<td class='name'>" + color + f.name + "</td><td class='value'> " + Math.round(f.value / 3600) + "h. </td>";
                auxiString1 += "</tr>"
              });
              auxiString1 += "</tbody></table>";
              auxiString1 += "</div>"
              return auxiString1;

            }
          }
        }
      },
      transition: {
        duration: 2000
      },
      zoom: {
        enabled: true,
        justY: true
      },
      onItemDropped: (e, semana) => {
        this.dropGrafico(e, semana);
      }
    });

    this.loadingPanel = false;
  }

  conseguir_maximo(DatOperaciones) {
    var JmaquinasSemanas: any = {};
    var j = 0;
    DatOperaciones.forEach(
      row => {
        if (j != 1) //las capacidades no hay que tenerlas en cuenta, ya tenemos los turnos para este calculo
          if (j != 0) { //EL 0 LO TRATAMOS APARTE PORQUE SON LAS HORAS SEMANALES
            var i = 0
            row.forEach(
              cell => {
                if (i != 0) //EL 0 ES EL NOMBRE DE LA COLUMNA, NO ES UN VALOR NUMERICO
                  if (JmaquinasSemanas[i + '_Planificado'])
                    JmaquinasSemanas[i + '_Planificado'] += cell;
                  else
                    JmaquinasSemanas[i + '_Planificado'] = cell;
                i++;
              });
          } else { //EL 0 LO TRATAMOS APARTE PORQUE SON LAS HORAS SEMANALES
            var i = 0
            row.forEach(
              cell => {
                if (i != 0) //EL 0 ES EL NOMBRE DE LA COLUMNA, NO ES UN VALOR NUMERICO
                  if (JmaquinasSemanas[i + '_Turno'])
                    JmaquinasSemanas[i + '_Turno'] += cell;
                  else
                    JmaquinasSemanas[i + '_Turno'] = cell;
                i++;
              });
          }
        j++;
      }
    );
    var max = 40 * 3600; //minimo un turno de 40h

    // 19_9
    var an: any = Object.keys(JmaquinasSemanas);
    an.forEach(
      col => {
        if (max < JmaquinasSemanas[col]) {
          max = JmaquinasSemanas[col];
        }
      });

    return max * 1.2;
    //return max * 0.9;
  }

  clickGrafico(e) {
    this.operacionesSelected = [];
    this.JmaquinaSelected = undefined;
    this.Jmaquinas.forEach(f => {
      if (f.id == this.selectedMaquina) {
        this.JmaquinaSelected = f;
      }
    });

    this.JsemanaSelected = undefined;

    var semana = 0;
    var año = 0;

    this.Jsemanas.forEach(f => {
      if (f.value == e.index) {
        semana = f.semana;
        año = f.año;
        this.JsemanaSelected = f;
      }
    });

    var semanaActual = this.Jsemanas[0].semana;
    var añoActual = this.Jsemanas[0].año;

    var intentos = 0;

    this.JoperacionesVal = [];

    if (e.id.includes(" (" + this.translateService.instant('corto') + ")")) {
      this.operacionesCorto.forEach(
        f => {
          //f.idParte + ' - ' +
          var parte = f.idOperacionVisible + ' - ' + f.refOF + ' - ' + f.parte + ' - ' + f.operacionVisible + " (" + this.translateService.instant('corto') + ")";

          if (parte == e.id) {

            this.visibleInfo = true;
            this.visibleInfoGrupo = false;
            this.desactivarBottom = true;

            this.infoOF = f.refOF;
            this.infoCliente = f.cliente;
            this.infoProyecto = f.proyecto;
            this.infoPlano = f.plano;
            this.infoRefPieza = f.refPieza;
            this.infoPieza = f.pieza;
            this.infoParte = f.parte;

            if (f.fechaLimite != null)
              this.infoFechaLimite = this.dateToYYYYMMDD(new Date(f.fechaLimite));
            else
              this.infoFechaLimite = '';

            this.Joperaciones = this.operacionesCorto.filter(r => r == f);

            this.JoperacionesVal = this.Joperaciones;
          }
        });
    }
    else {
      while ((this.JoperacionesVal.length == 0 && semanaActual <= semana && añoActual <= año) || intentos > 50) {
        this.Jplanificador.forEach(
          f => {
            var negativo: string = '';
            if (f.fueraDeTiempo == 2)
              negativo = '-';
            var parte = negativo + f.idParte + ' - ' + f.idOperacionVisible + ' - ' + f.refOF + ' - ' + f.parte + ' - ' + f.operacionVisible;

            var parteGrupo = this.translateService.instant("grupo") + " " + f.idOperacionesGrupos;

            if ((parte == e.id || parteGrupo == e.id) &&
              f.semana == semana &&
              f.año == año) {
              if (f.idParte != '-1') {
                this.Joperaciones = this.DatOperaciones.filter(r => this.selectedMaquina == r.idMaquina && f.idParte == r.idParte && r.semana == semana && r.año == año && (f.idOperacionVisible == 0 || f.idOperacionVisible == r.idOperacion))
                this.JoperacionesVal = this.Joperaciones;

                this.visibleInfo = true;
                this.visibleInfoGrupo = false;
                this.desactivarBottom = false;

                this.infoOF = f.refOF;
                this.infoCliente = f.cliente;
                this.infoProyecto = f.proyecto;
                this.infoPlano = f.plano;
                this.infoRefPieza = f.refPieza;
                this.infoPieza = f.pieza;
                this.infoParte = f.parte;

                if (f.fechaLimite != null)
                  this.infoFechaLimite = this.dateToYYYYMMDD(new Date(f.fechaLimite));
                else
                  this.infoFechaLimite = '';
              }
              else if (f.idOperacionesGrupos != '-1') {
                // parte = "Grupo " + f.idOperacionesGrupos;
                this.Joperaciones = this.DatOperaciones_grupos_operaciones.filter(r => this.selectedMaquina == r.idMaquina && f.idOperacionesGrupos == r.idOperacionesGrupos && r.semana == semana && r.año == año)
                this.JoperacionesVal = this.Joperaciones;

                this.visibleInfo = true;
                this.visibleInfoGrupo = true;
                this.desactivarBottom = false;

                var grupo = this.DatOperaciones_grupos.filter(r => f.idOperacionesGrupos == r.idOperacionesGrupos && r.semana == semana && r.año == año)[0];

                this.idOperacionesGrupos = grupo.idOperacionesGrupos;
                this.nombreGrupo = grupo.nombre;
                this.temperaturaGrupo = grupo.temperatura + " º";
                this.tiempoEstimadoGrupo = this.myFunctions.secondsTo_HH_MM(grupo.tiempoEstimado);
                this.pesoGrupo = grupo.peso + " kg";
                this.unidadesGrupo = grupo.unidades + " u.";

              }
            }
          });
        semana--;
        if (semana < 0) {
          año--;
          semana = 53;
        }

        intentos++;
      }
    }
  }

  dropGrafico(e, semana) {
    // preparamos las variables para un codigo mas comprensible
    var splitted = e.subject.id.split(" - ", 2);
    var id: number = 0;
    if (splitted.length > 0)
      id = splitted[0].replace("-", "");
    var idOperacionVisible: number = 0;
    if (splitted.length > 1)
      idOperacionVisible = splitted[1].replace("-", "");


    var semanaActual = this.getNumberOfWeek(new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate()));
    var semanaDragged = e.subject.index
    //var dragged = this.DatOperaciones.find(f => f.idParte == id && f.semana == semanaDragged + semanaActual && f.idMaquina == this.selectedMaquina);
    //var dragged = this.DatOperaciones.filter(f => f.idParte == id && f.semana == semanaDragged + semanaActual && f.idMaquina == this.selectedMaquina && (idOperacionVisible == 0 || f.idOperacion == idOperacionVisible))

    if (semana != semanaDragged) {
      if (e.subject.id.includes(" (" + this.translateService.instant('corto') + ")")) {
        this.modalReference = this.modalService.open(this.popupVeteAlCorto, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
      }
      else {
        this.moverOperaciones_e = e;
        this.moverOperaciones_semana = semana;

        this.modalReference = this.modalService.open(this.popupMoverOperaciones, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
      }
    }
    else {
      this.clickGrafico(e.subject)
    }
  }
  //BOTONES "FIJAR" START
  btnFijarTodo() {
    var rowsSelecteds: any = this.DatOperaciones.filter(f => f.idMaquina == this.selectedMaquina);

    var idsOperaciones: number[] = [];
    rowsSelecteds.forEach(f => {
      idsOperaciones.push(f.idOperacion);
    });
    if (idsOperaciones.length > 0)
      this.planificadorService.UpdateFijadoOperacionesTodas(this.JplanificadoresSelected.value, this.selectedMaquina, idsOperaciones, true)
  }
  btnDesfijarTodo() {
    var rowsSelecteds: any = this.DatOperaciones.filter(f => f.idMaquina == this.selectedMaquina);

    var idsOperaciones: number[] = [];
    rowsSelecteds.forEach(f => {
      idsOperaciones.push(f.idOperacion);
    });
    if (idsOperaciones.length > 0)
      this.planificadorService.UpdateFijadoOperacionesTodas(this.JplanificadoresSelected.value, this.selectedMaquina, idsOperaciones, false)
  }
  //BOTONES "FIJAR" END

  //BOTONES REORGANIZAR START
  btnReorganizar() {
    this.prioridadCliente = 0;
    this.prioridadFacturacion = 0;
    this.prioridadOF = 0;
    this.prioridadMaquina = 0;
    this.prioridadFecha = 0;
    this.prioridadTemperatura = 0;

    this.metodologiasDePlanificacionSelected = this.metodologiasDePlanificacion[0];

    this.dias_reserva = 0;
    this.percent_cap_maquina = 0;
    this.optimizarCuelloBotella = true;
    this.replanificarTiempoEstimado = true;
    this.replanificarIneficiencias = false;
    this.planificarSoloNoPlanificadas = false;
    this.asap_maximoMesesParaIniciarAntesDeFechaEntrega = 6;


    this.prioridadCliente = this.dtConfiguracionPlanificador.prioridadCliente;
    this.prioridadFacturacion = this.dtConfiguracionPlanificador.prioridadFacturacion;
    this.prioridadOF = this.dtConfiguracionPlanificador.prioridadOF;
    this.prioridadMaquina = this.dtConfiguracionPlanificador.prioridadMaquina;
    this.prioridadFecha = this.dtConfiguracionPlanificador.prioridadFecha;
    this.prioridadTemperatura = this.dtConfiguracionPlanificador.prioridadTemperatura;

    this.metodologiasDePlanificacionSelected = this.metodologiasDePlanificacion.find(f => f.id == this.dtConfiguracionPlanificador.metodologiaPlanificacion);;

    this.dias_reserva = this.dtConfiguracionPlanificador.dias_reserva;
    this.percent_cap_maquina = this.dtConfiguracionPlanificador.percent_cap_maquina;
    this.maxCapacidadMaquina = this.dtConfiguracionPlanificador.capacidadMaquinaPlanificador200 ? 200 : 100;
    this.pasoCapacidadMaquina = this.dtConfiguracionPlanificador.capacidadMaquinaPlanificador200 ? 20 : 10;
    this.optimizarCuelloBotella = this.dtConfiguracionPlanificador.optimizarCuelloBotella;
    this.replanificarTiempoEstimado = this.dtConfiguracionPlanificador.replanificarTiempoEstimado;
    this.replanificarIneficiencias = this.dtConfiguracionPlanificador.replanificarIneficiencias;
    this.planificarSoloNoPlanificadas = this.dtConfiguracionPlanificador.planificarSoloNoPlanificadas;
    this.asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.dtConfiguracionPlanificador.asap_maximoMesesParaIniciarAntesDeFechaEntrega;

    this.modalReference = this.modalService.open(this.popupReorganizar, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnReorganizarTiempoEstimado() {
    this.replanificarTiempoEstimado = true;
  }
  btnReorganizarTiempoPredictivo() {
    this.replanificarTiempoEstimado = false;
  }
  btnReorganizarIneficiencia() {
    this.replanificarIneficiencias = !this.replanificarIneficiencias;
  }
  btnReorganizarAceptar() {
    var prioridadCliente: number = 0;
    var prioridadFacturacion: number = 0;
    var prioridadOF: number = 0;
    var prioridadMaquina: number = 0;
    var prioridadFecha: number = 0;
    var prioridadTemperatura: number = 0;

    var metodologiaPlanificacion: number = 0;

    var dias_reserva: number = 0;
    var percent_cap_maquina: number = 0;
    var optimizarCuelloBotella: boolean = true;
    var replanificarTiempoEstimado = true;
    var replanificarIneficiencias = false;

    var planificarSoloNoPlanificadas = false;
    var asap_maximoMesesParaIniciarAntesDeFechaEntrega: number = 6;

    var total: number = 0;

    total = this.prioridadCliente + this.prioridadFacturacion + this.prioridadOF + this.prioridadMaquina + this.prioridadFecha + this.prioridadTemperatura;

    prioridadCliente = this.reglaDeTres(this.prioridadCliente, total, 100);
    prioridadFacturacion = this.reglaDeTres(this.prioridadFacturacion, total, 100);
    prioridadOF = this.reglaDeTres(this.prioridadOF, total, 100);
    prioridadMaquina = this.reglaDeTres(this.prioridadMaquina, total, 100);
    prioridadFecha = this.reglaDeTres(this.prioridadFecha, total, 100);
    prioridadTemperatura = this.reglaDeTres(this.prioridadTemperatura, total, 100);

    metodologiaPlanificacion = this.metodologiasDePlanificacionSelected.id;

    dias_reserva = this.dias_reserva;
    percent_cap_maquina = this.percent_cap_maquina;

    replanificarTiempoEstimado = this.replanificarTiempoEstimado;
    replanificarIneficiencias = this.replanificarIneficiencias;

    planificarSoloNoPlanificadas = this.planificarSoloNoPlanificadas;
    asap_maximoMesesParaIniciarAntesDeFechaEntrega = this.asap_maximoMesesParaIniciarAntesDeFechaEntrega;

    optimizarCuelloBotella = this.optimizarCuelloBotella;

    var tiempo = 1;
    if (!this.aplicarTiempoEstimado)
      tiempo = 2;

    this.planificadorService.Reorganizar_Skootik(2, this.JplanificadoresSelected.value, tiempo,// la maquina no se usa por ahora
      prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina, dias_reserva, percent_cap_maquina, prioridadFecha,
      prioridadTemperatura, metodologiaPlanificacion, optimizarCuelloBotella, this.selectedMaquina,
      !replanificarTiempoEstimado, replanificarIneficiencias, planificarSoloNoPlanificadas, asap_maximoMesesParaIniciarAntesDeFechaEntrega).subscribe(
        json => {
          var r1, r2, r3 = false;
          var version = this.JplanificadoresSelected.value;
          this.planificadorService.GetOperacionesPlanificadas(version).subscribe(
            (json) => {

              this.DatOperaciones = json;

              r1 = true;
              if (r1 && r2 && r3) {
                this.getGrafico();

                this.operacionesSelected = [];
                this.JmaquinaSelected = undefined;
                this.Jmaquinas.forEach(f => {
                  if (f.id == this.selectedMaquina) {
                    this.JmaquinaSelected = f;
                  }
                });

                this.JsemanaSelected = this.Jsemanas[0];
              }
            }
          );

          this.planificadorService.GetFechaSemanasLimite().subscribe(
            (json) => {

              if (Object.keys(json).length > 0) {
                var a: any = json[0];
                //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                var fecha = this.startOfWeek(this.myFunctions.getDateNow());

                var semana = this.getNumberOfWeek(fecha);
                var año = fecha.getFullYear();

                var fechaMax = a.fechaMax;
                var añoMax = a.año;
                var semanaMax = a.semana;

                var index = 0;
                while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
                  //crear linea Json para añadir al "grid"
                  var fecha2 = new Date(fecha);
                  var js: any = {};
                  js['value'] = index;
                  js['fecha'] = fecha2;
                  var fechaAnno = new Date(fecha);
                  fechaAnno.setDate(fechaAnno.getDate() + 3);
                  año = fechaAnno.getFullYear();
                  js['año'] = año;
                  js['semana'] = semana;
                  js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                  this.Jsemanas.push(js);

                  //actualizar variables para proximo loop
                  index++;
                  fecha.setDate(fecha.getDate() + 7);
                  semana = this.getNumberOfWeek(fecha);
                  año = fecha.getFullYear();
                }
              } else {
                //var inicioAño = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0));
                //var fecha = new Date(new Date(this.myFunctions.getDateNow().getFullYear(), 0, 0).getTime() +
                //  ((this.getNumberOfWeek(this.myFunctions.getDateNow()) - 1) * 7 * (1000 * 60 * 60 * 24)) -
                //  ((inicioAño.getDay() + 6) * (1000 * 60 * 60 * 24)));
                var fecha = this.startOfWeek(this.myFunctions.getDateNow());
                var semana = this.getNumberOfWeek(fecha);
                var año = fecha.getFullYear();


                var index = 0;
                //crear linea Json para añadir al "grid"
                var fecha2 = new Date(fecha);
                var js: any = {};
                js['value'] = index;
                js['fecha'] = fecha2;
                var fechaAnno = new Date(fecha);
                fechaAnno.setDate(fechaAnno.getDate() + 3);
                año = fechaAnno.getFullYear();
                js['año'] = año;
                js['semana'] = semana;
                js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
                this.Jsemanas.push(js);
              }

              r2 = true;
              if (r1 && r2 && r3) {
                this.getGrafico();

                this.operacionesSelected = [];
                this.JmaquinaSelected = undefined;
                this.Jmaquinas.forEach(f => {
                  if (f.id == this.selectedMaquina) {
                    this.JmaquinaSelected = f;
                  }
                });

                this.JsemanaSelected = this.Jsemanas[0];
              }
            }
          );

          this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
              this.tiemposCorto = json;

              this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                json => {
                  //CARGAR TIEMPOS EJECUTADOS START 
                  //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                  var an: any = json;
                  var operacionesOrdenadas2 = {};
                  var operacionesEnEjecucion = {};
                  an.forEach(
                    a => {
                      if (operacionesOrdenadas2[a['idOFs_Operacion']])
                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                      else
                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                      operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                      operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                    });
                  var an2: any = this.tiemposCorto;
                  //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                  an2.forEach(
                    row => {
                      // añadir las 3 nuevas variables
                      row['tiempoEjecutado'] = 0;
                      row['operacionEmpezada'] = false;
                      row['enEjecucion'] = false;

                      //se ponen las que estan en ejecucion en este momento
                      var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                      if (enEjecucion) {
                        if (enEjecucion == 1) {
                          row['enEjecucion'] = true;
                        }
                      }

                      //se descuenta el tiempo por maquina y operacion
                      var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                      if (tiempoEjecutado) {
                        //se le pone que esta empezada
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                  an2.forEach(
                    row => {
                      var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                      if (tiempoEjecutado) {
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //CARGAR TIEMPOS EJECUTADOS END
                  //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                  var an3: any = an2;
                  var jsonTiemposCorto: any = {};
                  var maquinas: any = [];
                  an3.forEach(
                    row => {
                      if (jsonTiemposCorto[row.idMaquina]) {
                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                      }
                      else {
                        maquinas.push(row.idMaquina);
                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                      }
                    });
                  var valoresFinales: any = [];
                  maquinas.forEach(
                    maquina => {
                      var js: any = {};
                      js.idMaquina = maquina;
                      js.tiempo = jsonTiemposCorto[maquina];
                      valoresFinales.push(js);
                    });
                  this.operacionesCorto = an3;
                  this.tiemposCorto = valoresFinales;
                  this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                  //CAMBIAR EL FORMATO AL RESULTADO END

                  r3 = true;
                  if (r1 && r2 && r3) {
                    this.getGrafico();

                    this.operacionesSelected = [];
                    this.JmaquinaSelected = undefined;
                    this.Jmaquinas.forEach(f => {
                      if (f.id == this.selectedMaquina) {
                        this.JmaquinaSelected = f;
                      }
                    });

                    this.JsemanaSelected = this.Jsemanas[0];
                  }
                }
              );

              r3 = true;
              if (r1 && r2 && r3) {
                this.getGrafico();

                this.operacionesSelected = [];
                this.JmaquinaSelected = undefined;
                this.Jmaquinas.forEach(f => {
                  if (f.id == this.selectedMaquina) {
                    this.JmaquinaSelected = f;
                  }
                });

                this.JsemanaSelected = this.Jsemanas[0];
              }
            }
          );
          this.planificadorService.get_grupos(version).subscribe(
            (json) => {

              this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

              var grupos = (json as any).datOperaciones_grupos
              grupos.forEach(
                grupo => {
                  grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                });

              this.Jgrupos = grupos;
              this.gruposSeleccionados = [];

              this.DatOperaciones_grupos = grupos;
            }
          );

        }
      );

    this.modalReference.close();
  }
  //BOTONES REORGANIZAR END

  //BOTONES INFERIORES
  btnLargoMaquinas() {

  }
  btnFijar() {
    var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
    var idsOperaciones: number[] = [];
    var año = 0;
    var semana = 0;
    rowsSelecteds.forEach(f => {
      año = f.año;
      semana = f.semana;
      idsOperaciones.push(f.idOperacion);
    });
    if (idsOperaciones.length > 0)
      this.planificadorService.UpdateFijadoOperaciones(this.JplanificadoresSelected.value, this.selectedMaquina, this.getDateOfISOWeek(semana, año), idsOperaciones, true);
  }
  btnDesfijar() {
    var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
    var idsOperaciones: number[] = [];
    var año = 0;
    var semana = 0;
    rowsSelecteds.forEach(f => {
      año = f.año;
      semana = f.semana;
      idsOperaciones.push(f.idOperacion);
    });
    if (idsOperaciones.length > 0)
      this.planificadorService.UpdateFijadoOperaciones(this.JplanificadoresSelected.value, this.selectedMaquina, this.getDateOfISOWeek(semana, año), idsOperaciones, false);
  }
  btnSacar() {
    //#region "OLD"
    // var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
    // if (rowsSelecteds.length == 0) // grupos seleccionados grid
    //   rowsSelecteds = this.Jgrupos.filter(f => this.operacionesSelected.includes(f.numFila));
    // if (this.visibleInfoGrupo) // click en grupo y desde dentro
    //   rowsSelecteds = this.Jgrupos.filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

    // var req: any = [];
    // rowsSelecteds.forEach(f => {
    //   var r: any = {};
    //   r.idMaquina = this.selectedMaquina;
    //   r.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(f.semana, f.año));
    //   r.idOperacion = f.idOperacion;
    //   r.idOperacionesGrupos = f.idOperacionesGrupos;
    //   r.nuevaCantidad = f.total - f.cantidad;
    //   r.version = this.JplanificadoresSelected.value;
    //   req.push(r);
    // });

    // if (req.length > 0) {
    //   this.loadingPanel = true;
    //   /* AQUI SOLO SE ELIMINAN */
    //   this.planificadorService.UpdateCantidades(req).subscribe(r => {
    //     //cerrar popup
    //     this.visibleInfo = false;
    //     this.visibleInfoGrupo = false;

    //     var r1, r2, r3: boolean = false;
    //     //this.getGrafico();
    //     this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
    //       (json) => {
    //         this.DatOperaciones = json;
    //         var an: any = json;
    //         this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

    //         r1 = true;
    //         if (r1 && r2 && r3) {
    //           this.operacionesSelected = [];
    //           this.getGrafico();
    //         }
    //       }
    //     );
    //     var version = this.JplanificadoresSelected.value;
    //     this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
    //       json => {
    //         this.tiemposCorto = json;


    //         this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
    //           json => {
    //             //CARGAR TIEMPOS EJECUTADOS START 
    //             //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
    //             var an: any = json;
    //             var operacionesOrdenadas2 = {};
    //             var operacionesEnEjecucion = {};
    //             an.forEach(
    //               a => {
    //                 if (operacionesOrdenadas2[a['idOFs_Operacion']])
    //                   operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
    //                 else
    //                   operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

    //                 operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
    //                 operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
    //               });
    //             var an2: any = this.tiemposCorto;
    //             //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
    //             an2.forEach(
    //               row => {
    //                 // añadir las 3 nuevas variables
    //                 row['tiempoEjecutado'] = 0;
    //                 row['operacionEmpezada'] = false;
    //                 row['enEjecucion'] = false;

    //                 //se ponen las que estan en ejecucion en este momento
    //                 var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
    //                 if (enEjecucion) {
    //                   if (enEjecucion == 1) {
    //                     row['enEjecucion'] = true;
    //                   }
    //                 }

    //                 //se descuenta el tiempo por maquina y operacion
    //                 var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
    //                 if (tiempoEjecutado) {
    //                   //se le pone que esta empezada
    //                   if (tiempoEjecutado > 0) {
    //                     row['operacionEmpezada'] = true;
    //                     //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
    //                     var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
    //                     //se descuenta el tiempo de las 3 partes que afecta 
    //                     if (tiempoEjecutado <= maximoTiempoAppointment) {
    //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
    //                       operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
    //                       operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
    //                     } else {
    //                       operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
    //                       operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
    //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
    //                     }
    //                   }
    //                 }
    //               });
    //             //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
    //             an2.forEach(
    //               row => {
    //                 var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
    //                 if (tiempoEjecutado) {
    //                   if (tiempoEjecutado > 0) {
    //                     row['operacionEmpezada'] = true;
    //                     //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
    //                     //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
    //                     var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
    //                     //se descuenta el tiempo de las 3 partes que afecta 
    //                     if (tiempoEjecutado <= maximoTiempoAppointment) {
    //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
    //                       operacionesOrdenadas2[row['idOperacion']] = 0;
    //                     } else {
    //                       operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
    //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
    //                     }
    //                   }
    //                 }
    //               });
    //             //CARGAR TIEMPOS EJECUTADOS END
    //             //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
    //             var an3: any = an2;
    //             var jsonTiemposCorto: any = {};
    //             var maquinas: any = [];
    //             an3.forEach(
    //               row => {
    //                 if (jsonTiemposCorto[row.idMaquina]) {
    //                   jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
    //                 }
    //                 else {
    //                   maquinas.push(row.idMaquina);
    //                   jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
    //                 }
    //               });
    //             var valoresFinales: any = [];
    //             maquinas.forEach(
    //               maquina => {
    //                 var js: any = {};
    //                 js.idMaquina = maquina;
    //                 js.tiempo = jsonTiemposCorto[maquina];
    //                 valoresFinales.push(js);
    //               });
    //             this.operacionesCorto = an3;
    //             this.tiemposCorto = valoresFinales;
    //             this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
    //             //CAMBIAR EL FORMATO AL RESULTADO END

    //             r2 = true;

    //             if (r1 && r2 && r3) {
    //               this.operacionesSelected = [];
    //               this.getGrafico();
    //             }
    //           }
    //         );

    //       }
    //     );
    //     this.planificadorService.get_grupos(version).subscribe(
    //       (json) => {

    //         this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

    //         var grupos = (json as any).datOperaciones_grupos
    //         grupos.forEach(
    //           grupo => {
    //             grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
    //           });

    //         this.Jgrupos = grupos;
    //         this.gruposSeleccionados = [];

    //         this.DatOperaciones_grupos = grupos;

    //         r3 = true;
    //         if (r1 && r2 && r3) {
    //           this.operacionesSelected = [];
    //           this.getGrafico();
    //         }
    //       }
    //     );
    //     //this.getGrafico();
    //   });
    // }
    //#endregion

    var salir = false;
    if (this.JmaquinaSelected == undefined) {
      this.requiereMaquina = true;
      salir = true;
    }
    if (this.JsemanaSelected == undefined) {
      this.requiereSemana = true;
      salir = true;
    }

    if (!salir) {
      var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
      if (rowsSelecteds.length == 0) // grupos seleccionados grid
        rowsSelecteds = this.Jgrupos.filter(f => this.operacionesSelected.includes(f.numFila));
      if (this.visibleInfoGrupo) // click en grupo y desde dentro
        rowsSelecteds = this.Jgrupos.filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

      var idsOperaciones: any = [];
      rowsSelecteds.forEach(f => {
        // if (f.idOperacionSimultanea > 0)
          idsOperaciones.push(f.idOperacion);
      });

      if (idsOperaciones.length > 0) {
        this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
          json => {
            var opsSimultaneas: any = json;

            var reqSacar: any = [];
            var idMaquinaSeleccionada: number = this.JmaquinaSelected.id

            //PREPARAMOS LOS JSON DE INSERT Y UPDATE (contando simultaneas)
            rowsSelecteds.forEach(
              operacion => {
                // Operaciones para sacar de plannig
                reqSacar.push({
                  idMaquina: this.selectedMaquina,
                  fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(operacion.semana, operacion.año)),
                  idOperacion: operacion.idOperacion,
                  idOperacionesGrupos: operacion.idOperacionesGrupos,
                  nuevaCantidad: 0,
                  version: this.JplanificadoresSelected.value,
                });

                // Se buscan sus simultanias si tiene
                if (operacion.idOperacionSimultanea > 0) {
                  // SI TIENE OP SIMULTANEAS
                  var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
                  // Las ordenamos en el orden requerido.
                  ops_simultaneas.sort((s1, s2) => {
                    if (s1.orden > s2.orden) return 1
                    else if (s1.orden < s2.orden) return -1
                    else return 0
                  });

                  // Sacamos el orden de simultanea que tiene la operacion seleccionada
                  var i = 0;
                  var index = 0;
                  ops_simultaneas.forEach(
                    op => {
                      if (op.idOperacion == operacion.idOperacion) index = i;
                      i++;
                    });

                  // Sacamos la primera ruta que nos coincida con la maquina 
                  var cadenaEncontrada = [];
                  this.cadenas_Maquinas.forEach(
                    cadena => {
                      if (cadena[index] == idMaquinaSeleccionada) {
                        cadenaEncontrada = cadena;
                      }
                    });

                  // Las añadimos para el insert
                  var ordenSimultanea = 0
                  ops_simultaneas.forEach(
                    op => {
                      // Si no es una de las operaciones originales se añade a donde le toca
                      if (!(idsOperaciones.indexOf(op.idOperacion) >= 0)) {
                        // La actualizamos si estaba planificada
                        if (op.idMaquina > 0) {
                          // Operaciones para sacar de plannig
                          reqSacar.push({
                            idMaquina: op.idMaquina,
                            fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)),
                            idOperacion: op.idOperacion,
                            idOperacionesGrupos: operacion.idOperacionesGrupos,
                            nuevaCantidad: 0,
                            version: this.JplanificadoresSelected.value,
                          });
                        }
                      }
                      // Incrementar el indice 
                      ordenSimultanea++;
                    });
                }
              });

            // if (reqInsertar.length > 0) {
            //   this.loadingPanel = true;
            //   this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
            //     if (reqSacar.length > 0) {
            //       this.loadingPanel = true;

            //       this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
            //         //cerrar popup
            //         this.visibleInfo = false;
            //         this.visibleInfoGrupo = false;
            //         var r11, r22, r33: boolean = false;
            //         //this.getGrafico();
            //         this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            //           (json) => {
            //             this.DatOperaciones = json;
            //             var an: any = json;
            //             this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

            //             r11 = true;
            //             if (r11 && r22 && r33) {
            //               this.operacionesSelected = [];
            //               this.getGrafico();
            //             }
            //           }
            //         );
            //         var version = this.JplanificadoresSelected.value;
            //         this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            //           json => {
            //             this.tiemposCorto = json;


            //             this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
            //               json => {
            //                 //CARGAR TIEMPOS EJECUTADOS START 
            //                 //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            //                 var an: any = json;
            //                 var operacionesOrdenadas2 = {};
            //                 var operacionesEnEjecucion = {};
            //                 an.forEach(
            //                   a => {
            //                     if (operacionesOrdenadas2[a['idOFs_Operacion']])
            //                       operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
            //                     else
            //                       operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

            //                     operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
            //                     operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
            //                   });
            //                 var an2: any = this.tiemposCorto;
            //                 //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            //                 an2.forEach(
            //                   row => {
            //                     // añadir las 3 nuevas variables
            //                     row['tiempoEjecutado'] = 0;
            //                     row['operacionEmpezada'] = false;
            //                     row['enEjecucion'] = false;

            //                     //se ponen las que estan en ejecucion en este momento
            //                     var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
            //                     if (enEjecucion) {
            //                       if (enEjecucion == 1) {
            //                         row['enEjecucion'] = true;
            //                       }
            //                     }

            //                     //se descuenta el tiempo por maquina y operacion
            //                     var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
            //                     if (tiempoEjecutado) {
            //                       //se le pone que esta empezada
            //                       if (tiempoEjecutado > 0) {
            //                         row['operacionEmpezada'] = true;
            //                         //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                         var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                         //se descuenta el tiempo de las 3 partes que afecta 
            //                         if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                           operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
            //                           operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
            //                         } else {
            //                           operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                           operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                         }
            //                       }
            //                     }
            //                   });
            //                 //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            //                 an2.forEach(
            //                   row => {
            //                     var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
            //                     if (tiempoEjecutado) {
            //                       if (tiempoEjecutado > 0) {
            //                         row['operacionEmpezada'] = true;
            //                         //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                         //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            //                         var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                         //se descuenta el tiempo de las 3 partes que afecta 
            //                         if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                           operacionesOrdenadas2[row['idOperacion']] = 0;
            //                         } else {
            //                           operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                         }
            //                       }
            //                     }
            //                   });
            //                 //CARGAR TIEMPOS EJECUTADOS END
            //                 //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            //                 var an3: any = an2;
            //                 var jsonTiemposCorto: any = {};
            //                 var maquinas: any = [];
            //                 an3.forEach(
            //                   row => {
            //                     if (jsonTiemposCorto[row.idMaquina]) {
            //                       jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
            //                     }
            //                     else {
            //                       maquinas.push(row.idMaquina);
            //                       jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
            //                     }
            //                   });
            //                 var valoresFinales: any = [];
            //                 maquinas.forEach(
            //                   maquina => {
            //                     var js: any = {};
            //                     js.idMaquina = maquina;
            //                     js.tiempo = jsonTiemposCorto[maquina];
            //                     valoresFinales.push(js);
            //                   });
            //                 this.operacionesCorto = an3;
            //                 this.tiemposCorto = valoresFinales;
            //                 this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //                 //CAMBIAR EL FORMATO AL RESULTADO END

            //                 r22 = true;

            //                 if (r11 && r22 && r33) {
            //                   this.operacionesSelected = [];
            //                   this.getGrafico();
            //                 }
            //               }
            //             );

            //           }
            //         );
            //         this.planificadorService.get_grupos(version).subscribe(
            //           (json) => {

            //             this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

            //             var grupos = (json as any).datOperaciones_grupos
            //             grupos.forEach(
            //               grupo => {
            //                 grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
            //               });

            //             this.Jgrupos = grupos;
            //             this.gruposSeleccionados = [];

            //             this.DatOperaciones_grupos = grupos;

            //             r33 = true;
            //             if (r11 && r22 && r33) {
            //               this.operacionesSelected = [];
            //               this.getGrafico();
            //             }
            //           }
            //         );
            //       });
            //     } else {
            //       this.operacionesSelected = [];
            //       this.getGrafico();
            //     }
            //   });
            // } else {
            //   if (reqSacar.length > 0) {
            //     this.loadingPanel = true;
            //     this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
            //       this.visibleInfo = false;
            //       this.visibleInfoGrupo = false;
            //       var r11, r22, r33: boolean = false;
            //       //this.getGrafico();
            //       this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            //         (json) => {
            //           this.DatOperaciones = json;
            //           var an: any = json;
            //           this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

            //           r11 = true;
            //           if (r11 && r22 && r33) {
            //             this.operacionesSelected = [];
            //             this.getGrafico();
            //           }
            //         }
            //       );
            //       var version = this.JplanificadoresSelected.value;
            //       this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            //         json => {
            //           this.tiemposCorto = json;


            //           this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
            //             json => {
            //               //CARGAR TIEMPOS EJECUTADOS START 
            //               //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            //               var an: any = json;
            //               var operacionesOrdenadas2 = {};
            //               var operacionesEnEjecucion = {};
            //               an.forEach(
            //                 a => {
            //                   if (operacionesOrdenadas2[a['idOFs_Operacion']])
            //                     operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
            //                   else
            //                     operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

            //                   operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
            //                   operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
            //                 });
            //               var an2: any = this.tiemposCorto;
            //               //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            //               an2.forEach(
            //                 row => {
            //                   // añadir las 3 nuevas variables
            //                   row['tiempoEjecutado'] = 0;
            //                   row['operacionEmpezada'] = false;
            //                   row['enEjecucion'] = false;

            //                   //se ponen las que estan en ejecucion en este momento
            //                   var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
            //                   if (enEjecucion) {
            //                     if (enEjecucion == 1) {
            //                       row['enEjecucion'] = true;
            //                     }
            //                   }

            //                   //se descuenta el tiempo por maquina y operacion
            //                   var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
            //                   if (tiempoEjecutado) {
            //                     //se le pone que esta empezada
            //                     if (tiempoEjecutado > 0) {
            //                       row['operacionEmpezada'] = true;
            //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                       //se descuenta el tiempo de las 3 partes que afecta 
            //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
            //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
            //                       } else {
            //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                       }
            //                     }
            //                   }
            //                 });
            //               //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            //               an2.forEach(
            //                 row => {
            //                   var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
            //                   if (tiempoEjecutado) {
            //                     if (tiempoEjecutado > 0) {
            //                       row['operacionEmpezada'] = true;
            //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                       //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                       //se descuenta el tiempo de las 3 partes que afecta 
            //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                         operacionesOrdenadas2[row['idOperacion']] = 0;
            //                       } else {
            //                         operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                       }
            //                     }
            //                   }
            //                 });
            //               //CARGAR TIEMPOS EJECUTADOS END
            //               //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            //               var an3: any = an2;
            //               var jsonTiemposCorto: any = {};
            //               var maquinas: any = [];
            //               an3.forEach(
            //                 row => {
            //                   if (jsonTiemposCorto[row.idMaquina]) {
            //                     jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
            //                   }
            //                   else {
            //                     maquinas.push(row.idMaquina);
            //                     jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
            //                   }
            //                 });
            //               var valoresFinales: any = [];
            //               maquinas.forEach(
            //                 maquina => {
            //                   var js: any = {};
            //                   js.idMaquina = maquina;
            //                   js.tiempo = jsonTiemposCorto[maquina];
            //                   valoresFinales.push(js);
            //                 });
            //               this.operacionesCorto = an3;
            //               this.tiemposCorto = valoresFinales;
            //               this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //               //CAMBIAR EL FORMATO AL RESULTADO END

            //               r22 = true;

            //               if (r11 && r22 && r33) {
            //                 this.operacionesSelected = [];
            //                 this.getGrafico();
            //               }
            //             }
            //           );

            //         }
            //       );
            //       this.planificadorService.get_grupos(version).subscribe(
            //         (json) => {

            //           this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

            //           var grupos = (json as any).datOperaciones_grupos
            //           grupos.forEach(
            //             grupo => {
            //               grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
            //             });

            //           this.Jgrupos = grupos;
            //           this.gruposSeleccionados = [];

            //           this.DatOperaciones_grupos = grupos;

            //           r33 = true;
            //           if (r11 && r22 && r33) {
            //             this.operacionesSelected = [];
            //             this.getGrafico();
            //           }
            //         }
            //       );
            //     });
            //   } else {
            //     this.operacionesSelected = [];
            //     this.getGrafico();
            //   }
            // }
            //#endregion 
            //#region "LLAMADAS PARA ELIMINAR"
            if (reqSacar.length > 0) {
              this.loadingPanel = true;
              this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
                //cerrar popup
                this.visibleInfo = false;
                this.visibleInfoGrupo = false;
                var r11, r22, r33: boolean = false;
                //this.getGrafico();
                this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                  (json) => {
                    this.DatOperaciones = json;
                    var an: any = json;
                    this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                    r11 = true;
                    if (r11 && r22 && r33) {
                      this.operacionesSelected = [];
                      this.getGrafico();
                    }
                  }
                );
                var version = this.JplanificadoresSelected.value;
                this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                  json => {
                    this.tiemposCorto = json;


                    this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                      json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                          a => {
                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                              operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                            else
                              operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                          });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                          row => {
                            // añadir las 3 nuevas variables
                            row['tiempoEjecutado'] = 0;
                            row['operacionEmpezada'] = false;
                            row['enEjecucion'] = false;

                            //se ponen las que estan en ejecucion en este momento
                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                            if (enEjecucion) {
                              if (enEjecucion == 1) {
                                row['enEjecucion'] = true;
                              }
                            }

                            //se descuenta el tiempo por maquina y operacion
                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                            if (tiempoEjecutado) {
                              //se le pone que esta empezada
                              if (tiempoEjecutado > 0) {
                                row['operacionEmpezada'] = true;
                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                //se descuenta el tiempo de las 3 partes que afecta 
                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                } else {
                                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                }
                              }
                            }
                          });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                          row => {
                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                            if (tiempoEjecutado) {
                              if (tiempoEjecutado > 0) {
                                row['operacionEmpezada'] = true;
                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                //se descuenta el tiempo de las 3 partes que afecta 
                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                  operacionesOrdenadas2[row['idOperacion']] = 0;
                                } else {
                                  operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                }
                              }
                            }
                          });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                          row => {
                            if (jsonTiemposCorto[row.idMaquina]) {
                              jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                            }
                            else {
                              maquinas.push(row.idMaquina);
                              jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                            }
                          });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                          maquina => {
                            var js: any = {};
                            js.idMaquina = maquina;
                            js.tiempo = jsonTiemposCorto[maquina];
                            valoresFinales.push(js);
                          });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END

                        r22 = true;

                        if (r11 && r22 && r33) {
                          this.operacionesSelected = [];
                          this.getGrafico();
                        }
                      }
                    );

                  }
                );
                this.planificadorService.get_grupos(version).subscribe(
                  (json) => {

                    this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                    var grupos = (json as any).datOperaciones_grupos
                    grupos.forEach(
                      grupo => {
                        grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                      });

                    this.Jgrupos = grupos;
                    this.gruposSeleccionados = [];

                    this.DatOperaciones_grupos = grupos;

                    r33 = true;
                    if (r11 && r22 && r33) {
                      this.operacionesSelected = [];
                      this.getGrafico();
                    }
                  }
                );

              });

            }
            else {
              this.operacionesSelected = [];
              this.getGrafico();
            }
            //#endregion 

          });
      }




    }
  }
  btnMover() {
    var salir = false;
    if (this.JmaquinaSelected == undefined) {
      this.requiereMaquina = true;
      salir = true;
    }
    if (this.JsemanaSelected == undefined) {
      this.requiereSemana = true;
      salir = true;
    }
    if (!salir) {
      var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
      if (rowsSelecteds.length == 0) // grupos seleccionados grid
        rowsSelecteds = this.Jgrupos.filter(f => this.operacionesSelected.includes(f.numFila));
      if (this.visibleInfoGrupo) // click en grupo y desde dentro
        rowsSelecteds = this.Jgrupos.filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

      var idsOperaciones: any = [];
      rowsSelecteds.forEach(f => {
        // if (f.idOperacionSimultanea > 0)
          idsOperaciones.push(f.idOperacion);
      });

      if (idsOperaciones.length > 0) {
        this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
          json => {
            var opsSimultaneas: any = json;

            var reqSacar: any = [];
            var reqInsertar: any = [];
            var idMaquinaSeleccionada: number = this.JmaquinaSelected.id

            //PREPARAMOS LOS JSON DE INSERT Y UPDATE (contando simultaneas)
            rowsSelecteds.forEach(
              operacion => {
                // Operaciones para sacar de plannig
                reqSacar.push({
                  idMaquina: this.selectedMaquina,
                  fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(operacion.semana, operacion.año)),
                  idOperacion: operacion.idOperacion,
                  idOperacionesGrupos: operacion.idOperacionesGrupos,
                  nuevaCantidad: operacion.total - operacion.cantidad,
                  version: this.JplanificadoresSelected.value,
                });
                // Operaciones para añadir de plannig
                reqInsertar.push({
                  idMaquina: idMaquinaSeleccionada,
                  fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                  idOperacion: operacion.idOperacion,
                  idOperacionesGrupos: operacion.idOperacionesGrupos,
                  cantidad: operacion.cantidad,
                  version: this.JplanificadoresSelected.value,
                  orden: operacion.ordenPlanificador,
                });

                // Se buscan sus simultanias si tiene
                if (operacion.idOperacionSimultanea > 0) {
                  // SI TIENE OP SIMULTANEAS
                  var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
                  // Las ordenamos en el orden requerido.
                  ops_simultaneas.sort((s1, s2) => {
                    if (s1.orden > s2.orden) return 1
                    else if (s1.orden < s2.orden) return -1
                    else return 0
                  });

                  // Sacamos el orden de simultanea que tiene la operacion seleccionada
                  var i = 0;
                  var index = 0;
                  ops_simultaneas.forEach(
                    op => {
                      if (op.idOperacion == operacion.idOperacion) index = i;
                      i++;
                    });

                  // Sacamos la primera ruta que nos coincida con la maquina 
                  var cadenaEncontrada = [];
                  this.cadenas_Maquinas.forEach(
                    cadena => {
                      if (cadena[index] == idMaquinaSeleccionada) {
                        cadenaEncontrada = cadena;
                      }
                    });

                  // Las añadimos para el insert
                  var ordenSimultanea = 0
                  ops_simultaneas.forEach(
                    op => {
                      // Si no es una de las operaciones originales se añade a donde le toca
                      if (!(idsOperaciones.indexOf(op.idOperacion) >= 0)) {
                        // La actualizamos si estaba planificada
                        if (op.idMaquina > 0) {
                          // Operaciones para sacar de plannig
                          reqSacar.push({
                            idMaquina: op.idMaquina,
                            fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)),
                            idOperacion: op.idOperacion,
                            idOperacionesGrupos: operacion.idOperacionesGrupos,
                            nuevaCantidad: operacion.total - operacion.cantidad,
                            version: this.JplanificadoresSelected.value,
                          });
                        }

                        // Operaciones para añadir de plannig
                        reqInsertar.push({
                          idMaquina: cadenaEncontrada[ordenSimultanea],
                          fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                          idOperacion: op.idOperacion,
                          idOperacionesGrupos: operacion.idOperacionesGrupos,
                          cantidad: operacion.cantidad,
                          version: this.JplanificadoresSelected.value,
                          orden: operacion.ordenPlanificador,
                        });
                      }
                      // Incrementar el indice 
                      ordenSimultanea++;
                    });
                }
              });


            /*************************************************************************/
            /*****   POR LAS SIMULTANEAS SE TIENE QUE AÑADIR ANTES DE ELIMINAR   *****/
            /*************************************************************************/
            //#region "LLAMADAS SERIADAS"
            // if (reqInsertar.length > 0) {
            //   this.loadingPanel = true;
            //   this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
            //     if (reqSacar.length > 0) {
            //       this.loadingPanel = true;

            //       this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
            //         //cerrar popup
            //         this.visibleInfo = false;
            //         this.visibleInfoGrupo = false;
            //         var r11, r22, r33: boolean = false;
            //         //this.getGrafico();
            //         this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            //           (json) => {
            //             this.DatOperaciones = json;
            //             var an: any = json;
            //             this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

            //             r11 = true;
            //             if (r11 && r22 && r33) {
            //               this.operacionesSelected = [];
            //               this.getGrafico();
            //             }
            //           }
            //         );
            //         var version = this.JplanificadoresSelected.value;
            //         this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            //           json => {
            //             this.tiemposCorto = json;


            //             this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
            //               json => {
            //                 //CARGAR TIEMPOS EJECUTADOS START 
            //                 //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            //                 var an: any = json;
            //                 var operacionesOrdenadas2 = {};
            //                 var operacionesEnEjecucion = {};
            //                 an.forEach(
            //                   a => {
            //                     if (operacionesOrdenadas2[a['idOFs_Operacion']])
            //                       operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
            //                     else
            //                       operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

            //                     operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
            //                     operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
            //                   });
            //                 var an2: any = this.tiemposCorto;
            //                 //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            //                 an2.forEach(
            //                   row => {
            //                     // añadir las 3 nuevas variables
            //                     row['tiempoEjecutado'] = 0;
            //                     row['operacionEmpezada'] = false;
            //                     row['enEjecucion'] = false;

            //                     //se ponen las que estan en ejecucion en este momento
            //                     var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
            //                     if (enEjecucion) {
            //                       if (enEjecucion == 1) {
            //                         row['enEjecucion'] = true;
            //                       }
            //                     }

            //                     //se descuenta el tiempo por maquina y operacion
            //                     var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
            //                     if (tiempoEjecutado) {
            //                       //se le pone que esta empezada
            //                       if (tiempoEjecutado > 0) {
            //                         row['operacionEmpezada'] = true;
            //                         //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                         var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                         //se descuenta el tiempo de las 3 partes que afecta 
            //                         if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                           operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
            //                           operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
            //                         } else {
            //                           operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                           operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                         }
            //                       }
            //                     }
            //                   });
            //                 //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            //                 an2.forEach(
            //                   row => {
            //                     var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
            //                     if (tiempoEjecutado) {
            //                       if (tiempoEjecutado > 0) {
            //                         row['operacionEmpezada'] = true;
            //                         //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                         //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            //                         var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                         //se descuenta el tiempo de las 3 partes que afecta 
            //                         if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                           operacionesOrdenadas2[row['idOperacion']] = 0;
            //                         } else {
            //                           operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                         }
            //                       }
            //                     }
            //                   });
            //                 //CARGAR TIEMPOS EJECUTADOS END
            //                 //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            //                 var an3: any = an2;
            //                 var jsonTiemposCorto: any = {};
            //                 var maquinas: any = [];
            //                 an3.forEach(
            //                   row => {
            //                     if (jsonTiemposCorto[row.idMaquina]) {
            //                       jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
            //                     }
            //                     else {
            //                       maquinas.push(row.idMaquina);
            //                       jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
            //                     }
            //                   });
            //                 var valoresFinales: any = [];
            //                 maquinas.forEach(
            //                   maquina => {
            //                     var js: any = {};
            //                     js.idMaquina = maquina;
            //                     js.tiempo = jsonTiemposCorto[maquina];
            //                     valoresFinales.push(js);
            //                   });
            //                 this.operacionesCorto = an3;
            //                 this.tiemposCorto = valoresFinales;
            //                 this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //                 //CAMBIAR EL FORMATO AL RESULTADO END

            //                 r22 = true;

            //                 if (r11 && r22 && r33) {
            //                   this.operacionesSelected = [];
            //                   this.getGrafico();
            //                 }
            //               }
            //             );

            //           }
            //         );
            //         this.planificadorService.get_grupos(version).subscribe(
            //           (json) => {

            //             this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

            //             var grupos = (json as any).datOperaciones_grupos
            //             grupos.forEach(
            //               grupo => {
            //                 grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
            //               });

            //             this.Jgrupos = grupos;
            //             this.gruposSeleccionados = [];

            //             this.DatOperaciones_grupos = grupos;

            //             r33 = true;
            //             if (r11 && r22 && r33) {
            //               this.operacionesSelected = [];
            //               this.getGrafico();
            //             }
            //           }
            //         );
            //       });
            //     } else {
            //       this.operacionesSelected = [];
            //       this.getGrafico();
            //     }
            //   });
            // } else {
            //   if (reqSacar.length > 0) {
            //     this.loadingPanel = true;
            //     this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
            //       this.visibleInfo = false;
            //       this.visibleInfoGrupo = false;
            //       var r11, r22, r33: boolean = false;
            //       //this.getGrafico();
            //       this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            //         (json) => {
            //           this.DatOperaciones = json;
            //           var an: any = json;
            //           this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

            //           r11 = true;
            //           if (r11 && r22 && r33) {
            //             this.operacionesSelected = [];
            //             this.getGrafico();
            //           }
            //         }
            //       );
            //       var version = this.JplanificadoresSelected.value;
            //       this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            //         json => {
            //           this.tiemposCorto = json;


            //           this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
            //             json => {
            //               //CARGAR TIEMPOS EJECUTADOS START 
            //               //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            //               var an: any = json;
            //               var operacionesOrdenadas2 = {};
            //               var operacionesEnEjecucion = {};
            //               an.forEach(
            //                 a => {
            //                   if (operacionesOrdenadas2[a['idOFs_Operacion']])
            //                     operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
            //                   else
            //                     operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

            //                   operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
            //                   operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
            //                 });
            //               var an2: any = this.tiemposCorto;
            //               //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            //               an2.forEach(
            //                 row => {
            //                   // añadir las 3 nuevas variables
            //                   row['tiempoEjecutado'] = 0;
            //                   row['operacionEmpezada'] = false;
            //                   row['enEjecucion'] = false;

            //                   //se ponen las que estan en ejecucion en este momento
            //                   var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
            //                   if (enEjecucion) {
            //                     if (enEjecucion == 1) {
            //                       row['enEjecucion'] = true;
            //                     }
            //                   }

            //                   //se descuenta el tiempo por maquina y operacion
            //                   var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
            //                   if (tiempoEjecutado) {
            //                     //se le pone que esta empezada
            //                     if (tiempoEjecutado > 0) {
            //                       row['operacionEmpezada'] = true;
            //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                       //se descuenta el tiempo de las 3 partes que afecta 
            //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
            //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
            //                       } else {
            //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                       }
            //                     }
            //                   }
            //                 });
            //               //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            //               an2.forEach(
            //                 row => {
            //                   var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
            //                   if (tiempoEjecutado) {
            //                     if (tiempoEjecutado > 0) {
            //                       row['operacionEmpezada'] = true;
            //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
            //                       //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
            //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
            //                       //se descuenta el tiempo de las 3 partes que afecta 
            //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
            //                         operacionesOrdenadas2[row['idOperacion']] = 0;
            //                       } else {
            //                         operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
            //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
            //                       }
            //                     }
            //                   }
            //                 });
            //               //CARGAR TIEMPOS EJECUTADOS END
            //               //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            //               var an3: any = an2;
            //               var jsonTiemposCorto: any = {};
            //               var maquinas: any = [];
            //               an3.forEach(
            //                 row => {
            //                   if (jsonTiemposCorto[row.idMaquina]) {
            //                     jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
            //                   }
            //                   else {
            //                     maquinas.push(row.idMaquina);
            //                     jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
            //                   }
            //                 });
            //               var valoresFinales: any = [];
            //               maquinas.forEach(
            //                 maquina => {
            //                   var js: any = {};
            //                   js.idMaquina = maquina;
            //                   js.tiempo = jsonTiemposCorto[maquina];
            //                   valoresFinales.push(js);
            //                 });
            //               this.operacionesCorto = an3;
            //               this.tiemposCorto = valoresFinales;
            //               this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //               //CAMBIAR EL FORMATO AL RESULTADO END

            //               r22 = true;

            //               if (r11 && r22 && r33) {
            //                 this.operacionesSelected = [];
            //                 this.getGrafico();
            //               }
            //             }
            //           );

            //         }
            //       );
            //       this.planificadorService.get_grupos(version).subscribe(
            //         (json) => {

            //           this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

            //           var grupos = (json as any).datOperaciones_grupos
            //           grupos.forEach(
            //             grupo => {
            //               grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
            //             });

            //           this.Jgrupos = grupos;
            //           this.gruposSeleccionados = [];

            //           this.DatOperaciones_grupos = grupos;

            //           r33 = true;
            //           if (r11 && r22 && r33) {
            //             this.operacionesSelected = [];
            //             this.getGrafico();
            //           }
            //         }
            //       );
            //     });
            //   } else {
            //     this.operacionesSelected = [];
            //     this.getGrafico();
            //   }
            // }
            //#endregion 
            //#region "LLAMADAS PARALELAS"
            var r1 = false;
            var r2 = false;
            if (reqSacar.length > 0) {
              this.loadingPanel = true;
              this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
                r1 = true;
                //cerrar popup
                if (r1 && r2) {
                  this.visibleInfo = false;
                  this.visibleInfoGrupo = false;
                  var r11, r22, r33: boolean = false;
                  //this.getGrafico();
                  this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                    (json) => {
                      this.DatOperaciones = json;
                      var an: any = json;
                      this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                      r11 = true;
                      if (r11 && r22 && r33) {
                        this.operacionesSelected = [];
                        this.getGrafico();
                      }
                    }
                  );
                  var version = this.JplanificadoresSelected.value;
                  this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                    json => {
                      this.tiemposCorto = json;


                      this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                        json => {
                          //CARGAR TIEMPOS EJECUTADOS START 
                          //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                          var an: any = json;
                          var operacionesOrdenadas2 = {};
                          var operacionesEnEjecucion = {};
                          an.forEach(
                            a => {
                              if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                              else
                                operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                              operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                              operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                            });
                          var an2: any = this.tiemposCorto;
                          //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                          an2.forEach(
                            row => {
                              // añadir las 3 nuevas variables
                              row['tiempoEjecutado'] = 0;
                              row['operacionEmpezada'] = false;
                              row['enEjecucion'] = false;

                              //se ponen las que estan en ejecucion en este momento
                              var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                              if (enEjecucion) {
                                if (enEjecucion == 1) {
                                  row['enEjecucion'] = true;
                                }
                              }

                              //se descuenta el tiempo por maquina y operacion
                              var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                              if (tiempoEjecutado) {
                                //se le pone que esta empezada
                                if (tiempoEjecutado > 0) {
                                  row['operacionEmpezada'] = true;
                                  //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                  var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                  //se descuenta el tiempo de las 3 partes que afecta 
                                  if (tiempoEjecutado <= maximoTiempoAppointment) {
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                  } else {
                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                  }
                                }
                              }
                            });
                          //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                          an2.forEach(
                            row => {
                              var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                              if (tiempoEjecutado) {
                                if (tiempoEjecutado > 0) {
                                  row['operacionEmpezada'] = true;
                                  //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                  //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                  var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                  //se descuenta el tiempo de las 3 partes que afecta 
                                  if (tiempoEjecutado <= maximoTiempoAppointment) {
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                    operacionesOrdenadas2[row['idOperacion']] = 0;
                                  } else {
                                    operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                  }
                                }
                              }
                            });
                          //CARGAR TIEMPOS EJECUTADOS END
                          //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                          var an3: any = an2;
                          var jsonTiemposCorto: any = {};
                          var maquinas: any = [];
                          an3.forEach(
                            row => {
                              if (jsonTiemposCorto[row.idMaquina]) {
                                jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                              }
                              else {
                                maquinas.push(row.idMaquina);
                                jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                              }
                            });
                          var valoresFinales: any = [];
                          maquinas.forEach(
                            maquina => {
                              var js: any = {};
                              js.idMaquina = maquina;
                              js.tiempo = jsonTiemposCorto[maquina];
                              valoresFinales.push(js);
                            });
                          this.operacionesCorto = an3;
                          this.tiemposCorto = valoresFinales;
                          this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                          //CAMBIAR EL FORMATO AL RESULTADO END

                          r22 = true;

                          if (r11 && r22 && r33) {
                            this.operacionesSelected = [];
                            this.getGrafico();
                          }
                        }
                      );

                    }
                  );
                  this.planificadorService.get_grupos(version).subscribe(
                    (json) => {

                      this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                      var grupos = (json as any).datOperaciones_grupos
                      grupos.forEach(
                        grupo => {
                          grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                        });

                      this.Jgrupos = grupos;
                      this.gruposSeleccionados = [];

                      this.DatOperaciones_grupos = grupos;

                      r33 = true;
                      if (r11 && r22 && r33) {
                        this.operacionesSelected = [];
                        this.getGrafico();
                      }
                    }
                  );
                }
              });
            } else {
              r1 = true;
            }
            if (reqInsertar.length > 0) {
              this.loadingPanel = true;
              this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
                r2 = true;
                //cerrar popup
                if (r1 && r2) {
                  this.visibleInfo = false;
                  this.visibleInfoGrupo = false;
                  var r11, r22, r33: boolean = false;
                  //this.getGrafico();
                  this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                    (json) => {
                      this.DatOperaciones = json;
                      var an: any = json;
                      this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                      r11 = true;
                      if (r11 && r22 && r33) {
                        this.operacionesSelected = [];
                        this.getGrafico();
                      }
                    }
                  );
                  var version = this.JplanificadoresSelected.value;
                  this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                    json => {
                      this.tiemposCorto = json;


                      this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                        json => {
                          //CARGAR TIEMPOS EJECUTADOS START 
                          //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                          var an: any = json;
                          var operacionesOrdenadas2 = {};
                          var operacionesEnEjecucion = {};
                          an.forEach(
                            a => {
                              if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                              else
                                operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                              operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                              operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                            });
                          var an2: any = this.tiemposCorto;
                          //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                          an2.forEach(
                            row => {
                              // añadir las 3 nuevas variables
                              row['tiempoEjecutado'] = 0;
                              row['operacionEmpezada'] = false;
                              row['enEjecucion'] = false;

                              //se ponen las que estan en ejecucion en este momento
                              var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                              if (enEjecucion) {
                                if (enEjecucion == 1) {
                                  row['enEjecucion'] = true;
                                }
                              }

                              //se descuenta el tiempo por maquina y operacion
                              var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                              if (tiempoEjecutado) {
                                //se le pone que esta empezada
                                if (tiempoEjecutado > 0) {
                                  row['operacionEmpezada'] = true;
                                  //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                  var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                  //se descuenta el tiempo de las 3 partes que afecta 
                                  if (tiempoEjecutado <= maximoTiempoAppointment) {
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                  } else {
                                    operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                    operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                  }
                                }
                              }
                            });
                          //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                          an2.forEach(
                            row => {
                              var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                              if (tiempoEjecutado) {
                                if (tiempoEjecutado > 0) {
                                  row['operacionEmpezada'] = true;
                                  //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                  //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                  var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                  //se descuenta el tiempo de las 3 partes que afecta 
                                  if (tiempoEjecutado <= maximoTiempoAppointment) {
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                    operacionesOrdenadas2[row['idOperacion']] = 0;
                                  } else {
                                    operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                    row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                  }
                                }
                              }
                            });
                          //CARGAR TIEMPOS EJECUTADOS END
                          //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                          var an3: any = an2;
                          var jsonTiemposCorto: any = {};
                          var maquinas: any = [];
                          an3.forEach(
                            row => {
                              if (jsonTiemposCorto[row.idMaquina]) {
                                jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                              }
                              else {
                                maquinas.push(row.idMaquina);
                                jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                              }
                            });
                          var valoresFinales: any = [];
                          maquinas.forEach(
                            maquina => {
                              var js: any = {};
                              js.idMaquina = maquina;
                              js.tiempo = jsonTiemposCorto[maquina];
                              valoresFinales.push(js);
                            });
                          this.operacionesCorto = an3;
                          this.tiemposCorto = valoresFinales;
                          this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                          //CAMBIAR EL FORMATO AL RESULTADO END

                          r22 = true;
                          if (r11 && r22 && r33) {
                            this.operacionesSelected = [];
                            this.getGrafico();
                          }
                        }
                      );

                    }
                  );
                  this.planificadorService.get_grupos(version).subscribe(
                    (json) => {

                      this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                      var grupos = (json as any).datOperaciones_grupos
                      grupos.forEach(
                        grupo => {
                          grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                        });

                      this.Jgrupos = grupos;
                      this.gruposSeleccionados = [];

                      this.DatOperaciones_grupos = grupos;

                      r33 = true;
                      if (r11 && r22 && r33) {
                        this.operacionesSelected = [];
                        this.getGrafico();
                      }
                    }
                  );
                }
              });
            } else {
              r2 = true;
            }

            if (r1 && r2) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
            //#endregion 

          });
      }
    }
  }
  btnMandarACorto() {
    // var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
    // rowsSelecteds = rowsSelecteds.concat(this.Jgrupos.filter(f => this.operacionesSelected.includes(f.numFila)));

    // var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
    // if (rowsSelecteds.length == 0) // grupos seleccionados grid
    //   rowsSelecteds = this.Jgrupos.filter(f => this.operacionesSelected.includes(f.numFila));
    // if (this.visibleInfoGrupo) // click en grupo y desde dentro
    //   rowsSelecteds = this.Jgrupos.filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

    // var reqSacar: any = [];
    // var reqInsertar: any = [];
    // rowsSelecteds.forEach(f => {
    //   // Operaciones para sacar de plannig
    //   var r: any = {};
    //   r.idMaquina = this.selectedMaquina;
    //   r.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(f.semana, f.año));
    //   r.idOperacion = f.idOperacion;
    //   r.idOperacionesGrupos = f.idOperacionesGrupos;
    //   r.nuevaCantidad = f.total - f.cantidad;
    //   r.version = this.JplanificadoresSelected.value;
    //   reqSacar.push(r);
    //   // Operaciones para añadir de plannig
    //   var r2: any = {};
    //   var num: number = this.JmaquinaSelected.id
    //   r2.idMaquina = num;
    //   r2.fecha = f.fechaIni;
    //   r2.idOperacion = f.idOperacion;
    //   r2.idOperacionesGrupos = f.idOperacionesGrupos;
    //   r2.cantidad = f.cantidad;
    //   r2.version = this.JplanificadoresSelected.value;
    //   r2.orden = f.ordenPlanificador;
    //   reqInsertar.push(r2);
    // });

    var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila)); // operaciones
    if (rowsSelecteds.length == 0) // grupos seleccionados grid
      rowsSelecteds = this.Jgrupos.filter(f => this.operacionesSelected.includes(f.numFila));
    if (this.visibleInfoGrupo) // click en grupo y desde dentro
      rowsSelecteds = this.Jgrupos.filter(f => this.idOperacionesGrupos == f.idOperacionesGrupos);

    var idsOperaciones: any = [];
    var idMaquinaSeleccionada: number = this.JmaquinaSelected.id
    rowsSelecteds.forEach(f => {
      // if (f.idOperacionSimultanea > 0) {
        idsOperaciones.push(f.idOperacion);
        idMaquinaSeleccionada = f.idMaquina ;
      // }
    });

    if (idsOperaciones.length > 0) {
      this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
        json => {
          var opsSimultaneas: any = json;

          var reqSacar: any = [];
          var reqInsertar: any = [];

          //PREPARAMOS LOS JSON DE INSERT Y UPDATE (contando simultaneas)
          rowsSelecteds.forEach(
            operacion => {
              // Operaciones para sacar de plannig
              reqSacar.push({
                idMaquina: operacion.idMaquina,
                fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(operacion.semana, operacion.año)),
                idOperacion: operacion.idOperacion,
                idOperacionesGrupos: operacion.idOperacionesGrupos,
                nuevaCantidad: operacion.total - operacion.cantidad,
                version: this.JplanificadoresSelected.value,
              });
              // Operaciones para añadir de plannig
              reqInsertar.push({
                idMaquina: operacion.idMaquina,
                fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(1, 2020)), //+ 1
                idOperacion: operacion.idOperacion,
                idOperacionesGrupos: operacion.idOperacionesGrupos,
                cantidad: operacion.cantidad,
                version: this.JplanificadoresSelected.value,
                orden: operacion.ordenPlanificador,
              });

              // Se buscan sus simultanias si tiene
              if (operacion.idOperacionSimultanea > 0) {
                // SI TIENE OP SIMULTANEAS
                var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
                // Las ordenamos en el orden requerido.
                ops_simultaneas.sort((s1, s2) => {
                  if (s1.orden > s2.orden) return 1
                  else if (s1.orden < s2.orden) return -1
                  else return 0
                });

                // Sacamos el orden de simultanea que tiene la operacion seleccionada
                var i = 0;
                var index = 0;
                ops_simultaneas.forEach(
                  op => {
                    if (op.idOperacion == operacion.idOperacion) index = i;
                    i++;
                  });

                // Sacamos la primera ruta que nos coincida con la maquina 
                var cadenaEncontrada = [];
                this.cadenas_Maquinas.forEach(
                  cadena => {
                    if (cadena[index] == idMaquinaSeleccionada) {
                      cadenaEncontrada = cadena;
                    }
                  });

                // Las añadimos para el insert
                var ordenSimultanea = 0
                ops_simultaneas.forEach(
                  op => {
                    // Si no es una de las operaciones originales se añade a donde le toca
                    if (!(idsOperaciones.indexOf(op.idOperacion) >= 0)) {
                      // La actualizamos si estaba planificada
                      if (op.idMaquina > 0) {
                        // Operaciones para sacar de plannig
                        reqSacar.push({
                          idMaquina: op.idMaquina,
                          fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)),
                          idOperacion: op.idOperacion,
                          idOperacionesGrupos: operacion.idOperacionesGrupos,
                          nuevaCantidad: operacion.total - operacion.cantidad,
                          version: this.JplanificadoresSelected.value,
                        });
                      }

                      // Operaciones para añadir de plannig
                      reqInsertar.push({
                        idMaquina: cadenaEncontrada[ordenSimultanea],
                        fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                        idOperacion: op.idOperacion,
                        idOperacionesGrupos: operacion.idOperacionesGrupos,
                        cantidad: operacion.cantidad,
                        version: this.JplanificadoresSelected.value,
                        orden: operacion.ordenPlanificador,
                      });
                    }
                    // Incrementar el indice 
                    ordenSimultanea++;
                  });
              }
            });

          /*************************************************************************/
          /*****   POR LAS SIMULTANEAS SE TIENE QUE AÑADIR ANTES DE ELIMINAR   *****/
          /*************************************************************************/
          //#region "LLAMADAS SERIADAS"
          // if (reqInsertar.length > 0) {
          //   this.loadingPanel = true;
          //   this.planificadorService.InsertOperacionesPlanificadasACorto(reqInsertar).subscribe(r => {

          //     if (reqSacar.length > 0) {
          //       this.loadingPanel = true;
          //       this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
          //         this.visibleInfo = false;
          //         this.visibleInfoGrupo = false;
          //         var r1, r2, r3: boolean = false;
          //         //this.getGrafico();
          //         this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
          //           (json) => {
          //             this.DatOperaciones = json;
          //             var an: any = json;
          //             this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

          //             r1 = true;
          //             if (r1 && r2 && r3) {
          //               this.operacionesSelected = [];
          //               this.getGrafico();
          //             }
          //           }
          //         );

          //         var version = this.JplanificadoresSelected.value;
          //         this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          //           json => {
          //             this.tiemposCorto = json;

          //             this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          //               json => {
          //                 //CARGAR TIEMPOS EJECUTADOS START 
          //                 //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
          //                 var an: any = json;
          //                 var operacionesOrdenadas2 = {};
          //                 var operacionesEnEjecucion = {};
          //                 an.forEach(
          //                   a => {
          //                     if (operacionesOrdenadas2[a['idOFs_Operacion']])
          //                       operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
          //                     else
          //                       operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

          //                     operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
          //                     operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
          //                   });
          //                 var an2: any = this.tiemposCorto;
          //                 //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
          //                 an2.forEach(
          //                   row => {
          //                     // añadir las 3 nuevas variables
          //                     row['tiempoEjecutado'] = 0;
          //                     row['operacionEmpezada'] = false;
          //                     row['enEjecucion'] = false;

          //                     //se ponen las que estan en ejecucion en este momento
          //                     var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
          //                     if (enEjecucion) {
          //                       if (enEjecucion == 1) {
          //                         row['enEjecucion'] = true;
          //                       }
          //                     }

          //                     //se descuenta el tiempo por maquina y operacion
          //                     var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
          //                     if (tiempoEjecutado) {
          //                       //se le pone que esta empezada
          //                       if (tiempoEjecutado > 0) {
          //                         row['operacionEmpezada'] = true;
          //                         //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
          //                         var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
          //                         //se descuenta el tiempo de las 3 partes que afecta 
          //                         if (tiempoEjecutado <= maximoTiempoAppointment) {
          //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
          //                           operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
          //                           operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
          //                         } else {
          //                           operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
          //                           operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
          //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
          //                         }
          //                       }
          //                     }
          //                   });
          //                 //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
          //                 an2.forEach(
          //                   row => {
          //                     var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
          //                     if (tiempoEjecutado) {
          //                       if (tiempoEjecutado > 0) {
          //                         row['operacionEmpezada'] = true;
          //                         //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
          //                         //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
          //                         var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
          //                         //se descuenta el tiempo de las 3 partes que afecta 
          //                         if (tiempoEjecutado <= maximoTiempoAppointment) {
          //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
          //                           operacionesOrdenadas2[row['idOperacion']] = 0;
          //                         } else {
          //                           operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
          //                           row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
          //                         }
          //                       }
          //                     }
          //                   });
          //                 //CARGAR TIEMPOS EJECUTADOS END
          //                 //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
          //                 var an3: any = an2;
          //                 var jsonTiemposCorto: any = {};
          //                 var maquinas: any = [];
          //                 an3.forEach(
          //                   row => {
          //                     if (jsonTiemposCorto[row.idMaquina]) {
          //                       jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
          //                     }
          //                     else {
          //                       maquinas.push(row.idMaquina);
          //                       jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
          //                     }
          //                   });
          //                 var valoresFinales: any = [];
          //                 maquinas.forEach(
          //                   maquina => {
          //                     var js: any = {};
          //                     js.idMaquina = maquina;
          //                     js.tiempo = jsonTiemposCorto[maquina];
          //                     valoresFinales.push(js);
          //                   });
          //                 this.operacionesCorto = an3;
          //                 this.tiemposCorto = valoresFinales;
          //                 this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
          //                 //CAMBIAR EL FORMATO AL RESULTADO END

          //                 r2 = true;
          //                 if (r1 && r2 && r3) {
          //                   this.operacionesSelected = [];
          //                   this.getGrafico();
          //                 }
          //               }
          //             );

          //           }
          //         );
          //         this.planificadorService.get_grupos(version).subscribe(
          //           (json) => {

          //             this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

          //             var grupos = (json as any).datOperaciones_grupos
          //             grupos.forEach(
          //               grupo => {
          //                 grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          //               });

          //             this.Jgrupos = grupos;
          //             this.gruposSeleccionados = [];

          //             this.DatOperaciones_grupos = grupos;

          //             r3 = true;
          //             if (r1 && r2 && r3) {
          //               this.operacionesSelected = [];
          //               this.getGrafico();
          //             }
          //           }
          //         );
          //       });
          //     }
          //   });
          // } else {
          //   if (reqSacar.length > 0) {
          //     this.loadingPanel = true;
          //     this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
          //       this.visibleInfo = false;
          //       this.visibleInfoGrupo = false;
          //       var r1, r2, r3: boolean = false;
          //       //this.getGrafico();
          //       this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
          //         (json) => {
          //           this.DatOperaciones = json;
          //           var an: any = json;
          //           this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

          //           r1 = true;
          //           if (r1 && r2 && r3) {
          //             this.operacionesSelected = [];
          //             this.getGrafico();
          //           }
          //         }
          //       );

          //       var version = this.JplanificadoresSelected.value;
          //       this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          //         json => {
          //           this.tiemposCorto = json;

          //           this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          //             json => {
          //               //CARGAR TIEMPOS EJECUTADOS START 
          //               //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
          //               var an: any = json;
          //               var operacionesOrdenadas2 = {};
          //               var operacionesEnEjecucion = {};
          //               an.forEach(
          //                 a => {
          //                   if (operacionesOrdenadas2[a['idOFs_Operacion']])
          //                     operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
          //                   else
          //                     operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

          //                   operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
          //                   operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
          //                 });
          //               var an2: any = this.tiemposCorto;
          //               //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
          //               an2.forEach(
          //                 row => {
          //                   // añadir las 3 nuevas variables
          //                   row['tiempoEjecutado'] = 0;
          //                   row['operacionEmpezada'] = false;
          //                   row['enEjecucion'] = false;

          //                   //se ponen las que estan en ejecucion en este momento
          //                   var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
          //                   if (enEjecucion) {
          //                     if (enEjecucion == 1) {
          //                       row['enEjecucion'] = true;
          //                     }
          //                   }

          //                   //se descuenta el tiempo por maquina y operacion
          //                   var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
          //                   if (tiempoEjecutado) {
          //                     //se le pone que esta empezada
          //                     if (tiempoEjecutado > 0) {
          //                       row['operacionEmpezada'] = true;
          //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
          //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
          //                       //se descuenta el tiempo de las 3 partes que afecta 
          //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
          //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
          //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
          //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
          //                       } else {
          //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
          //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
          //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
          //                       }
          //                     }
          //                   }
          //                 });
          //               //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
          //               an2.forEach(
          //                 row => {
          //                   var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
          //                   if (tiempoEjecutado) {
          //                     if (tiempoEjecutado > 0) {
          //                       row['operacionEmpezada'] = true;
          //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
          //                       //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
          //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
          //                       //se descuenta el tiempo de las 3 partes que afecta 
          //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
          //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
          //                         operacionesOrdenadas2[row['idOperacion']] = 0;
          //                       } else {
          //                         operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
          //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
          //                       }
          //                     }
          //                   }
          //                 });
          //               //CARGAR TIEMPOS EJECUTADOS END
          //               //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
          //               var an3: any = an2;
          //               var jsonTiemposCorto: any = {};
          //               var maquinas: any = [];
          //               an3.forEach(
          //                 row => {
          //                   if (jsonTiemposCorto[row.idMaquina]) {
          //                     jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
          //                   }
          //                   else {
          //                     maquinas.push(row.idMaquina);
          //                     jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
          //                   }
          //                 });
          //               var valoresFinales: any = [];
          //               maquinas.forEach(
          //                 maquina => {
          //                   var js: any = {};
          //                   js.idMaquina = maquina;
          //                   js.tiempo = jsonTiemposCorto[maquina];
          //                   valoresFinales.push(js);
          //                 });
          //               this.operacionesCorto = an3;
          //               this.tiemposCorto = valoresFinales;
          //               this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
          //               //CAMBIAR EL FORMATO AL RESULTADO END

          //               r2 = true;
          //               if (r1 && r2 && r3) {
          //                 this.operacionesSelected = [];
          //                 this.getGrafico();
          //               }
          //             }
          //           );

          //         }
          //       );
          //       this.planificadorService.get_grupos(version).subscribe(
          //         (json) => {

          //           this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

          //           var grupos = (json as any).datOperaciones_grupos
          //           grupos.forEach(
          //             grupo => {
          //               grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          //             });

          //           this.Jgrupos = grupos;
          //           this.gruposSeleccionados = [];

          //           this.DatOperaciones_grupos = grupos;

          //           r3 = true;
          //           if (r1 && r2 && r3) {
          //             this.operacionesSelected = [];
          //             this.getGrafico();
          //           }
          //         }
          //       );

          //     });
          //   }
          // }
          //#endregion
          //#region "LLAMADAS PARALELAS"
          var r1_ = false;
          var r2_ = false;
          if (reqSacar.length > 0) {
            this.loadingPanel = true;
            this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
              r1_ = true;
              //cerrar popup
              if (r1_ && r2_) {
                this.visibleInfo = false;
                this.visibleInfoGrupo = false;
                var r1, r2, r3: boolean = false;
                //this.getGrafico();
                this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                  (json) => {
                    this.DatOperaciones = json;
                    var an: any = json;
                    this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                    r1 = true;
                    if (r1 && r2 && r3) {
                      this.operacionesSelected = [];
                      this.getGrafico();
                    }
                  }
                );

                var version = this.JplanificadoresSelected.value;
                this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                  json => {
                    this.tiemposCorto = json;

                    this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                      json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                          a => {
                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                              operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                            else
                              operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                          });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                          row => {
                            // añadir las 3 nuevas variables
                            row['tiempoEjecutado'] = 0;
                            row['operacionEmpezada'] = false;
                            row['enEjecucion'] = false;

                            //se ponen las que estan en ejecucion en este momento
                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                            if (enEjecucion) {
                              if (enEjecucion == 1) {
                                row['enEjecucion'] = true;
                              }
                            }

                            //se descuenta el tiempo por maquina y operacion
                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                            if (tiempoEjecutado) {
                              //se le pone que esta empezada
                              if (tiempoEjecutado > 0) {
                                row['operacionEmpezada'] = true;
                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                //se descuenta el tiempo de las 3 partes que afecta 
                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                } else {
                                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                }
                              }
                            }
                          });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                          row => {
                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                            if (tiempoEjecutado) {
                              if (tiempoEjecutado > 0) {
                                row['operacionEmpezada'] = true;
                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                //se descuenta el tiempo de las 3 partes que afecta 
                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                  operacionesOrdenadas2[row['idOperacion']] = 0;
                                } else {
                                  operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                }
                              }
                            }
                          });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                          row => {
                            if (jsonTiemposCorto[row.idMaquina]) {
                              jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                            }
                            else {
                              maquinas.push(row.idMaquina);
                              jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                            }
                          });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                          maquina => {
                            var js: any = {};
                            js.idMaquina = maquina;
                            js.tiempo = jsonTiemposCorto[maquina];
                            valoresFinales.push(js);
                          });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END

                        r2 = true;
                        if (r1 && r2 && r3) {
                          this.operacionesSelected = [];
                          this.getGrafico();
                        }
                      }
                    );

                  }
                );
                this.planificadorService.get_grupos(version).subscribe(
                  (json) => {

                    this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                    var grupos = (json as any).datOperaciones_grupos
                    grupos.forEach(
                      grupo => {
                        grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                      });

                    this.Jgrupos = grupos;
                    this.gruposSeleccionados = [];

                    this.DatOperaciones_grupos = grupos;

                    r3 = true;
                    if (r1 && r2 && r3) {
                      this.operacionesSelected = [];
                      this.getGrafico();
                    }
                  }
                );
              }
            });
          } else {
            r1_ = true
          }
          if (reqInsertar.length > 0) {
            this.loadingPanel = true;
            this.planificadorService.InsertOperacionesPlanificadasACorto(reqInsertar).subscribe(r => {
              r2_ = true;
              //cerrar popup
              if (r1_ && r2_) {
                this.visibleInfo = false;
                this.visibleInfoGrupo = false;
                var r1, r2, r3: boolean = false;
                //this.getGrafico();
                this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                  (json) => {
                    this.DatOperaciones = json;
                    var an: any = json;
                    this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                    r1 = true;
                    if (r1 && r2 && r3) {
                      this.operacionesSelected = [];
                      this.getGrafico();
                    }
                  }
                );
                var version = this.JplanificadoresSelected.value;
                this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                  json => {
                    this.tiemposCorto = json;

                    this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                      json => {
                        //CARGAR TIEMPOS EJECUTADOS START 
                        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                        var an: any = json;
                        var operacionesOrdenadas2 = {};
                        var operacionesEnEjecucion = {};
                        an.forEach(
                          a => {
                            if (operacionesOrdenadas2[a['idOFs_Operacion']])
                              operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                            else
                              operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                          });
                        var an2: any = this.tiemposCorto;
                        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                        an2.forEach(
                          row => {
                            // añadir las 3 nuevas variables
                            row['tiempoEjecutado'] = 0;
                            row['operacionEmpezada'] = false;
                            row['enEjecucion'] = false;

                            //se ponen las que estan en ejecucion en este momento
                            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                            if (enEjecucion) {
                              if (enEjecucion == 1) {
                                row['enEjecucion'] = true;
                              }
                            }

                            //se descuenta el tiempo por maquina y operacion
                            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                            if (tiempoEjecutado) {
                              //se le pone que esta empezada
                              if (tiempoEjecutado > 0) {
                                row['operacionEmpezada'] = true;
                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                //se descuenta el tiempo de las 3 partes que afecta 
                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                } else {
                                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                }
                              }
                            }
                          });
                        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                        an2.forEach(
                          row => {
                            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                            if (tiempoEjecutado) {
                              if (tiempoEjecutado > 0) {
                                row['operacionEmpezada'] = true;
                                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                //se descuenta el tiempo de las 3 partes que afecta 
                                if (tiempoEjecutado <= maximoTiempoAppointment) {
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                  operacionesOrdenadas2[row['idOperacion']] = 0;
                                } else {
                                  operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                }
                              }
                            }
                          });
                        //CARGAR TIEMPOS EJECUTADOS END
                        //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                        var an3: any = an2;
                        var jsonTiemposCorto: any = {};
                        var maquinas: any = [];
                        an3.forEach(
                          row => {
                            if (jsonTiemposCorto[row.idMaquina]) {
                              jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                            }
                            else {
                              maquinas.push(row.idMaquina);
                              jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                            }
                          });
                        var valoresFinales: any = [];
                        maquinas.forEach(
                          maquina => {
                            var js: any = {};
                            js.idMaquina = maquina;
                            js.tiempo = jsonTiemposCorto[maquina];
                            valoresFinales.push(js);
                          });
                        this.operacionesCorto = an3;
                        this.tiemposCorto = valoresFinales;
                        this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                        //CAMBIAR EL FORMATO AL RESULTADO END

                        r2 = true;
                        if (r1 && r2 && r3) {
                          this.operacionesSelected = [];
                          this.getGrafico();
                        }
                      }
                    );

                  }
                );
                this.planificadorService.get_grupos(version).subscribe(
                  (json) => {

                    this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                    var grupos = (json as any).datOperaciones_grupos
                    grupos.forEach(
                      grupo => {
                        grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                      });

                    this.Jgrupos = grupos;
                    this.gruposSeleccionados = [];

                    this.DatOperaciones_grupos = grupos;

                    r3 = true;
                    if (r1 && r2 && r3) {
                      this.operacionesSelected = [];
                      this.getGrafico();
                    }
                  }
                );
              }
            });
          } else {
            r2_ = true
          }
          //#endregion

        });
    }

  }

  btnComparativaVersiones() {
    const url = this.router.serializeUrl(this.router.parseUrl('#/planificadorLargoComparativaVersiones'));
    window.open(url, '_blank');
  }

  btnOperaciones() {
    this.operacionesSelected = [];
    this.JmaquinaSelected = undefined;
    this.Jmaquinas.forEach(f => {
      if (f.id == this.selectedMaquina) {
        this.JmaquinaSelected = f;
      }
    });

    this.JsemanaSelected = this.Jsemanas[0];

    this.visibleInfo = false;
    this.visibleInfoGrupo = false;

    var an: any = this.DatOperaciones;
    this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina)


  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  btnAtras() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value - 1;
    this.router.navigate(['planificadorLargoMaquinas/' + version]);
  }

  cargarDatosFiltro() {
    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_filtro_Ofs(version, 0, this.selectedMaquina).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByOperacion = [];
      var groupByParte = [];

      //GROUP BY POR OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

      //GROUP BY POR PIEZA
      var kontagailua = 1;
      var listaPiezasSinRepetidos = [];
      data.forEach(function (a) {
        if (listaPiezasSinRepetidos.filter(x => x.nombrePieza == a.nombrePieza).length == 0 && a.idPieza != -1) {
          listaPiezasSinRepetidos.push({ idPieza: kontagailua, nombrePieza: a.nombrePieza, ids: [a.idPieza] });
          kontagailua++;
        }
        else {
          listaPiezasSinRepetidos.filter(x => x.nombrePieza == a.nombrePieza)[0].ids.push(a.idPieza);
        }
        // if (!this[a.idPieza]) {
        //   this[a.idPieza] = {
        //     idPieza: a.idPieza, nombrePieza: a.nombrePieza,
        //   };
        //   groupByPieza.push(this[a.idPieza]);
        // }
      }, Object.create(null));

      //this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
      this.listaPiezas = listaPiezasSinRepetidos;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));


    });

  }
  CambioFiltro() {

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_filtro_Ofs(version, 0, this.selectedMaquina).subscribe((data: any) => {

      this.dataFiltro = data;

      var data: any = this.dataFiltro;

      var idsOFs = [];
      if (this.ofsSeleccionados != undefined)
        this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

      var idsClientes = [];
      if (this.clientesSeleccionados != undefined)
        this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

      var idsPiezas = [];
      if (this.piezasSeleccionados != undefined)
        this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.idPieza));

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];

      //GROUP BY POR OF
      var lag: any = {};
      data.forEach(
        row => {
          if (!lag[row.idOf]
            && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
            && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
          ) {
            lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
            groupByOf.push(lag[row.idOf]);
          }
        });

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR CLIENTE
      lag = {};
      data.forEach(
        row => {
          if (!lag[row.idCliente]
            && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
            && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
          ) {
            lag[row.idCliente] = {
              idCliente: row.idCliente, nombreCliente: row.nombreCliente,
            };
            groupByCliente.push(lag[row.idCliente]);
          }
        });

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

      //GROUP BY POR PIEZA
      lag = {};
      var kontagailua = 1;
      var listaPiezasSinRepetidos = [];
      data.forEach(
        row => {
          if (//!lag[row.idPieza]
            //listaPiezasSinRepetidos.filter(x => x.nombrePieza==row.nombrePieza).length==0 && 
            (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
            && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          ) {
            // lag[row.idPieza] = {
            //   idPieza: row.idPieza, nombrePieza: row.nombrePieza,
            // };
            // groupByPieza.push(lag[row.idPieza]);

            if (listaPiezasSinRepetidos.filter(x => x.nombrePieza == row.nombrePieza).length == 0) {
              listaPiezasSinRepetidos.push({ idPieza: kontagailua, nombrePieza: row.nombrePieza, ids: [row.idPieza] });
              kontagailua++;
            }
            else listaPiezasSinRepetidos.filter(x => x.nombrePieza == row.nombrePieza)[0].ids.push(row.idPieza);
          }
        });

      //this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
      this.listaPiezas = listaPiezasSinRepetidos.filter(item => (item.idPieza != -1));;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    });
  }
  seccionChanged() {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    } else {
      this.grupos.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }

    this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))))
    this.JmaquinasMostradasSinSubcontratas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))) && f.tipo == 1)
  }

  btnFiltrar() {

    this.loadingPanel = true;

    //this.JplanificadoresSelected = event;

    this.cargarDatosFiltro();

    var r1, r2, r3: boolean = false;
    //this.getGrafico();
    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      (json) => {
        this.DatOperaciones = json;
        var an: any = json;
        this.Joperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo);
        r1 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    var version = this.JplanificadoresSelected.value;
    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.tiemposCorto = json;

        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
          json => {
            //CARGAR TIEMPOS EJECUTADOS START 
            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
            var an: any = json;
            var operacionesOrdenadas2 = {};
            var operacionesEnEjecucion = {};
            an.forEach(
              a => {
                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                else
                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
              });
            var an2: any = this.tiemposCorto;
            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
            an2.forEach(
              row => {
                // añadir las 3 nuevas variables
                row['tiempoEjecutado'] = 0;
                row['operacionEmpezada'] = false;
                row['enEjecucion'] = false;

                //se ponen las que estan en ejecucion en este momento
                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                if (enEjecucion) {
                  if (enEjecucion == 1) {
                    row['enEjecucion'] = true;
                  }
                }

                //se descuenta el tiempo por maquina y operacion
                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                if (tiempoEjecutado) {
                  //se le pone que esta empezada
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
            an2.forEach(
              row => {
                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                if (tiempoEjecutado) {
                  if (tiempoEjecutado > 0) {
                    row['operacionEmpezada'] = true;
                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                    //se descuenta el tiempo de las 3 partes que afecta 
                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                      operacionesOrdenadas2[row['idOperacion']] = 0;
                    } else {
                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                    }
                  }
                }
              });
            //CARGAR TIEMPOS EJECUTADOS END
            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
            var an3: any = an2;
            var jsonTiemposCorto: any = {};
            var maquinas: any = [];
            an3.forEach(
              row => {
                if (jsonTiemposCorto[row.idMaquina]) {
                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                }
                else {
                  maquinas.push(row.idMaquina);
                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                }
              });
            var valoresFinales: any = [];
            maquinas.forEach(
              maquina => {
                var js: any = {};
                js.idMaquina = maquina;
                js.tiempo = jsonTiemposCorto[maquina];
                valoresFinales.push(js);
              });
            this.operacionesCorto = an3;
            this.tiemposCorto = valoresFinales;
            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
            //CAMBIAR EL FORMATO AL RESULTADO END

            r2 = true;
            if (r1 && r2 && r3) {
              this.operacionesSelected = [];
              this.getGrafico();
            }
          }
        );


      }
    );
    this.planificadorService.get_grupos(version).subscribe(
      (json) => {

        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

        var grupos = (json as any).datOperaciones_grupos
        grupos.forEach(
          grupo => {
            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
          });

        this.Jgrupos = grupos;
        this.gruposSeleccionados = [];

        this.DatOperaciones_grupos = grupos;

        r3 = true;
        if (r1 && r2 && r3) {
          this.operacionesSelected = [];
          this.getGrafico();
        }
      }
    );
    //this.getGrafico();
  }

  btnActualizarReorganizandoVersion() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.actualizarReorganizandoVersion();
  }

  actualizarReorganizandoVersion() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_ultima_reorganizacion(2, version).subscribe(
      json => {
        var an: any = json;
        if (an.length == 0) {
          if (this.popupReorganizandoVersion)
            this.getGrafico();
          this.popupReorganizandoVersion = false;
        } else {
          this.popupReorganizandoVersion = true;
          if (this.router.url.includes('/planificadorLargoMaquina')) {
            setTimeout((d) => this.actualizarReorganizandoVersion(), 1000);
          }
        }
      });


  }


  /*ATRASAR UNA SEMANA START*/
  btnAtrasarUnaSemana() {
    this.modalReference = this.modalService.open(this.popupAtrasarFijados, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnAtrasarFijadosCancelar() {
    this.loadingPanel = true;
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.AtrasarUnaSemana(version, false, this.selectedMaquina).subscribe(
      e => {
        this.modalReference.close();
        this.getGrafico();

        this.operacionesSelected = [];
        this.JmaquinaSelected = undefined;
        this.Jmaquinas.forEach(f => {
          if (f.id == this.selectedMaquina) {
            this.JmaquinaSelected = f;
          }
        });

        this.JsemanaSelected = this.Jsemanas[0];
      });
  }
  btnAtrasarFijadosAceptar() {
    this.loadingPanel = true;
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.AtrasarUnaSemana(version, true, this.selectedMaquina).subscribe(
      e => {
        this.modalReference.close();
        this.getGrafico();

        this.operacionesSelected = [];
        this.JmaquinaSelected = undefined;
        this.Jmaquinas.forEach(f => {
          if (f.id == this.selectedMaquina) {
            this.JmaquinaSelected = f;
          }
        });

        this.JsemanaSelected = this.Jsemanas[0];
      });
  }
  /*ATRASAR UNA SEMANA END*/

  /*POPUP MOVER TODAS OPERACIONES START*/
  btnMoverOperacionesCancelar() {
    this.moverOperaciones_todas = false;

    this.modalReference.close();
    if (this.user.planificador == 2) this.moverOperacion_Check_operacionPartida();  //Dejar mover operaciones sólo si el usuario tiene permisos
  }

  btnMoverOperacionesAceptar() {
    this.moverOperaciones_todas = true;

    this.modalReference.close();

    if (this.user.planificador == 2) this.moverOperacion_Check_operacionPartida();  //Dejar mover operaciones sólo si el usuario tiene permisos
  }

  moverOperacion_Check_operacionPartida() {
    var e = this.moverOperaciones_e;
    var semana = this.moverOperaciones_semana;
    // preparamos las variables para un codigo mas comprensible
    var splitted = e.subject.id.split(" - ", 2);
    var id = '0';
    if (splitted.length > 0)
      id = splitted[0].replace("-", "");
    var idOperacionVisible: number = 0;
    if (splitted.length > 1)
      idOperacionVisible = splitted[1].replace("-", "");


    var semanaActual = this.getNumberOfWeek(new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate()));
    var semanaDragged = e.subject.index
    //var dragged = this.DatOperaciones.find(f => f.idParte == id && f.semana == semanaDragged + semanaActual && f.idMaquina == this.selectedMaquina);

    var dragged = [];
    var añoActual = this.Jsemanas[0].año;
    var intentos = 0;
    this.JoperacionesVal = [];
    var semanaDraggedTemp = semanaDragged;
    while (dragged.length == 0 && intentos < 120) {

      var semanaParaProbar = semanaDraggedTemp + semanaActual

      if (semanaParaProbar < 0)
        semanaParaProbar += 53 //algo mas que las semanas maximas por año... porseacaso

      if (!id.includes(this.translateService.instant("grupo")))
        dragged = this.DatOperaciones.filter(f => f.idParte == id && f.semana == semanaParaProbar && f.idMaquina == this.selectedMaquina && (idOperacionVisible == 0 || f.idOperacion == idOperacionVisible))
      else
        dragged = this.DatOperaciones_grupos.filter(f => this.translateService.instant("grupo") + " " + f.idOperacionesGrupos == id && f.semana == semanaParaProbar && f.idMaquina == this.selectedMaquina && (idOperacionVisible == 0 || f.idOperacion == idOperacionVisible))

      semanaDraggedTemp--;
      intentos++;
    }

    if (semana != semanaDragged && dragged.length > 0) {
      if (dragged[0].dividida < 2) {
        // se crea la estructura necesaria para la API
        var idsDragOperaciones = [];
        var idsDragGrupos = [];
        dragged.forEach(
          dragRow => {

            idsDragOperaciones.push(dragRow.idOperacion);
            idsDragGrupos.push(dragRow.idOperacionesGrupos);

          });

        var rowsSelecteds: any = this.Joperaciones.filter(f => idsDragOperaciones.includes(f.idOperacion)); // operaciones
        if (rowsSelecteds.length == 0) // grupos seleccionados grid
          rowsSelecteds = this.Jgrupos.filter(f => idsDragGrupos.includes(f.idGrupo));

        var idsOperaciones: any = [];
        rowsSelecteds.forEach(f => {
          // if (f.idOperacionSimultanea > 0)
            idsOperaciones.push(f.idOperacion);
        });

        if (idsOperaciones.length > 0) {
          this.planificadorService.get_operacionesSimultaneas(this.JplanificadoresSelected.value, idsOperaciones).subscribe(
            json => {
              var opsSimultaneas: any = json;

              var reqSacar: any = [];
              var reqInsertar: any = [];
              var idMaquinaSeleccionada: number = this.JmaquinaSelected.id

              //PREPARAMOS LOS JSON DE INSERT Y UPDATE (contando simultaneas)
              rowsSelecteds.forEach(
                operacion => {
                  // Operaciones para sacar de plannig
                  reqSacar.push({
                    idMaquina: this.selectedMaquina,
                    fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(operacion.semana, operacion.año)),
                    idOperacion: operacion.idOperacion,
                    idOperacionesGrupos: operacion.idOperacionesGrupos,
                    nuevaCantidad: 0,
                    version: this.JplanificadoresSelected.value,
                  });
                  // Operaciones para añadir de plannig
                  reqInsertar.push({
                    idMaquina: this.selectedMaquina,
                    fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                    idRuta: operacion.idRuta, //para mover relacionadas
                    idOperacion: operacion.idOperacion,
                    idOperacionesGrupos: operacion.idOperacionesGrupos,
                    cantidad: operacion.total,
                    version: this.JplanificadoresSelected.value,
                    orden: operacion.ordenPlanificador,
                  });

                  // Se buscan sus simultanias si tiene
                  if (operacion.idOperacionSimultanea > 0) {
                    // SI TIENE OP SIMULTANEAS
                    var ops_simultaneas = opsSimultaneas.filter(f => f.idOperacionSimultanea == operacion.idOperacionSimultanea);
                    // Las ordenamos en el orden requerido.
                    ops_simultaneas.sort((s1, s2) => {
                      if (s1.orden > s2.orden) return 1
                      else if (s1.orden < s2.orden) return -1
                      else return 0
                    });

                    // Sacamos el orden de simultanea que tiene la operacion seleccionada
                    var i = 0;
                    var index = 0;
                    ops_simultaneas.forEach(
                      op => {
                        if (op.idOperacion == operacion.idOperacion) index = i;
                        i++;
                      });

                    // Sacamos la primera ruta que nos coincida con la maquina 
                    var cadenaEncontrada = [];
                    this.cadenas_Maquinas.forEach(
                      cadena => {
                        if (cadena[index] == idMaquinaSeleccionada) {
                          cadenaEncontrada = cadena;
                        }
                      });

                    // Las añadimos para el insert
                    var ordenSimultanea = 0
                    ops_simultaneas.forEach(
                      op => {
                        // Si no es una de las operaciones originales se añade a donde le toca
                        if (!(idsOperaciones.indexOf(op.idOperacion) >= 0)) {
                          // La actualizamos si estaba planificada
                          if (op.idMaquina > 0) {
                            // Operaciones para sacar de plannig
                            reqSacar.push({
                              idMaquina: op.idMaquina,
                              fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(op.semana, op.año)),
                              idOperacion: op.idOperacion,
                              idOperacionesGrupos: operacion.idOperacionesGrupos,
                              nuevaCantidad: operacion.total - operacion.cantidad,
                              version: this.JplanificadoresSelected.value,
                            });
                          }

                          // Operaciones para añadir de plannig
                          reqInsertar.push({
                            idMaquina: cadenaEncontrada[ordenSimultanea],
                            fecha: this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año)), //+ 1
                            idOperacion: op.idOperacion,
                            idOperacionesGrupos: operacion.idOperacionesGrupos,
                            cantidad: operacion.cantidad,
                            version: this.JplanificadoresSelected.value,
                            orden: operacion.ordenPlanificador,
                          });
                        }
                        // Incrementar el indice 
                        ordenSimultanea++;
                      });
                  }
                });


              if (reqSacar.length > 0 && reqInsertar.length > 0 && this.user.planificador == 2) {
                /*************************************************************************/
                /*****   POR LAS SIMULTANEAS SE TIENE QUE AÑADIR ANTES DE ELIMINAR   *****/
                /*************************************************************************/
                //#region "LLAMADAS SERIADAS"
                // if (req2.length > 0) {
                //   this.planificadorService.InsertOperacionesPlanificadas(req2).subscribe(r => {
                //     this.planificadorService.UpdateCantidades(req).subscribe(r => {

                //       this.visibleInfo = false;
                //       this.visibleInfoGrupo = false;
                //       var r1, r2, r3: boolean = false;
                //       //this.getGrafico();
                //       this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                //         (json) => {
                //           this.DatOperaciones = json;
                //           var an: any = json;
                //           this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                //           r1 = true;
                //           if (r1 && r2 && r3) {
                //             this.operacionesSelected = [];
                //             this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //             this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //             this.getGrafico();
                //           }
                //         }
                //       );
                //       var version = this.JplanificadoresSelected.value;
                //       this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                //         json => {
                //           this.tiemposCorto = json;


                //           this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                //             json => {
                //               //CARGAR TIEMPOS EJECUTADOS START 
                //               //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                //               var an: any = json;
                //               var operacionesOrdenadas2 = {};
                //               var operacionesEnEjecucion = {};
                //               an.forEach(
                //                 a => {
                //                   if (operacionesOrdenadas2[a['idOFs_Operacion']])
                //                     operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                //                   else
                //                     operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                //                   operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                //                   operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                //                 });
                //               var an2: any = this.tiemposCorto;
                //               //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                //               an2.forEach(
                //                 row => {
                //                   // añadir las 3 nuevas variables
                //                   row['tiempoEjecutado'] = 0;
                //                   row['operacionEmpezada'] = false;
                //                   row['enEjecucion'] = false;

                //                   //se ponen las que estan en ejecucion en este momento
                //                   var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                //                   if (enEjecucion) {
                //                     if (enEjecucion == 1) {
                //                       row['enEjecucion'] = true;
                //                     }
                //                   }

                //                   //se descuenta el tiempo por maquina y operacion
                //                   var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                //                   if (tiempoEjecutado) {
                //                     //se le pone que esta empezada
                //                     if (tiempoEjecutado > 0) {
                //                       row['operacionEmpezada'] = true;
                //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                //                       //se descuenta el tiempo de las 3 partes que afecta 
                //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
                //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                //                       } else {
                //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                //                       }
                //                     }
                //                   }
                //                 });
                //               //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                //               an2.forEach(
                //                 row => {
                //                   var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                //                   if (tiempoEjecutado) {
                //                     if (tiempoEjecutado > 0) {
                //                       row['operacionEmpezada'] = true;
                //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                //                       //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                //                       //se descuenta el tiempo de las 3 partes que afecta 
                //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
                //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                //                         operacionesOrdenadas2[row['idOperacion']] = 0;
                //                       } else {
                //                         operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                //                       }
                //                     }
                //                   }
                //                 });
                //               //CARGAR TIEMPOS EJECUTADOS END
                //               //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                //               var an3: any = an2;
                //               var jsonTiemposCorto: any = {};
                //               var maquinas: any = [];
                //               an3.forEach(
                //                 row => {
                //                   if (jsonTiemposCorto[row.idMaquina]) {
                //                     jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                //                   }
                //                   else {
                //                     maquinas.push(row.idMaquina);
                //                     jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                //                   }
                //                 });
                //               var valoresFinales: any = [];
                //               maquinas.forEach(
                //                 maquina => {
                //                   var js: any = {};
                //                   js.idMaquina = maquina;
                //                   js.tiempo = jsonTiemposCorto[maquina];
                //                   valoresFinales.push(js);
                //                 });
                //               this.operacionesCorto = an3;
                //               this.tiemposCorto = valoresFinales;
                //               this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                //               //CAMBIAR EL FORMATO AL RESULTADO END

                //               r2 = true;

                //               if (r1 && r2 && r3) {
                //                 this.operacionesSelected = [];
                //                 this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //                 this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //                 this.getGrafico();
                //               }
                //             }
                //           );

                //         }
                //       );
                //       this.planificadorService.get_grupos(version).subscribe(
                //         (json) => {

                //           this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                //           var grupos = (json as any).datOperaciones_grupos
                //           grupos.forEach(
                //             grupo => {
                //               grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                //             });

                //           this.Jgrupos = grupos;
                //           this.gruposSeleccionados = [];

                //           this.DatOperaciones_grupos = grupos;

                //           r3 = true;
                //           if (r1 && r2 && r3) {
                //             this.operacionesSelected = [];
                //             this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //             this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //             this.getGrafico();
                //           }
                //         }
                //       );
                //     });
                //   });
                // } else {
                //   this.planificadorService.UpdateCantidades(req).subscribe(r => {

                //     this.visibleInfo = false;
                //     this.visibleInfoGrupo = false;
                //     var r1, r2, r3: boolean = false;
                //     //this.getGrafico();
                //     this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                //       (json) => {
                //         this.DatOperaciones = json;
                //         var an: any = json;
                //         this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                //         r1 = true;
                //         if (r1 && r2 && r3) {
                //           this.operacionesSelected = [];
                //           this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //           this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //           this.getGrafico();
                //         }
                //       }
                //     );
                //     var version = this.JplanificadoresSelected.value;
                //     this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                //       json => {
                //         this.tiemposCorto = json;


                //         this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                //           json => {
                //             //CARGAR TIEMPOS EJECUTADOS START 
                //             //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                //             var an: any = json;
                //             var operacionesOrdenadas2 = {};
                //             var operacionesEnEjecucion = {};
                //             an.forEach(
                //               a => {
                //                 if (operacionesOrdenadas2[a['idOFs_Operacion']])
                //                   operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                //                 else
                //                   operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                //                 operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                //                 operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                //               });
                //             var an2: any = this.tiemposCorto;
                //             //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                //             an2.forEach(
                //               row => {
                //                 // añadir las 3 nuevas variables
                //                 row['tiempoEjecutado'] = 0;
                //                 row['operacionEmpezada'] = false;
                //                 row['enEjecucion'] = false;

                //                 //se ponen las que estan en ejecucion en este momento
                //                 var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                //                 if (enEjecucion) {
                //                   if (enEjecucion == 1) {
                //                     row['enEjecucion'] = true;
                //                   }
                //                 }

                //                 //se descuenta el tiempo por maquina y operacion
                //                 var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                //                 if (tiempoEjecutado) {
                //                   //se le pone que esta empezada
                //                   if (tiempoEjecutado > 0) {
                //                     row['operacionEmpezada'] = true;
                //                     //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                //                     var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                //                     //se descuenta el tiempo de las 3 partes que afecta 
                //                     if (tiempoEjecutado <= maximoTiempoAppointment) {
                //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                //                       operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                //                       operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                //                     } else {
                //                       operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                //                       operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                //                     }
                //                   }
                //                 }
                //               });
                //             //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                //             an2.forEach(
                //               row => {
                //                 var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                //                 if (tiempoEjecutado) {
                //                   if (tiempoEjecutado > 0) {
                //                     row['operacionEmpezada'] = true;
                //                     //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                //                     //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                //                     var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                //                     //se descuenta el tiempo de las 3 partes que afecta 
                //                     if (tiempoEjecutado <= maximoTiempoAppointment) {
                //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                //                       operacionesOrdenadas2[row['idOperacion']] = 0;
                //                     } else {
                //                       operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                //                     }
                //                   }
                //                 }
                //               });
                //             //CARGAR TIEMPOS EJECUTADOS END
                //             //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                //             var an3: any = an2;
                //             var jsonTiemposCorto: any = {};
                //             var maquinas: any = [];
                //             an3.forEach(
                //               row => {
                //                 if (jsonTiemposCorto[row.idMaquina]) {
                //                   jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                //                 }
                //                 else {
                //                   maquinas.push(row.idMaquina);
                //                   jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                //                 }
                //               });
                //             var valoresFinales: any = [];
                //             maquinas.forEach(
                //               maquina => {
                //                 var js: any = {};
                //                 js.idMaquina = maquina;
                //                 js.tiempo = jsonTiemposCorto[maquina];
                //                 valoresFinales.push(js);
                //               });
                //             this.operacionesCorto = an3;
                //             this.tiemposCorto = valoresFinales;
                //             this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                //             //CAMBIAR EL FORMATO AL RESULTADO END

                //             r2 = true;

                //             if (r1 && r2 && r3) {
                //               this.operacionesSelected = [];
                //               this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //               this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //               this.getGrafico();
                //             }
                //           }
                //         );

                //       }
                //     );
                //     this.planificadorService.get_grupos(version).subscribe(
                //       (json) => {

                //         this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                //         var grupos = (json as any).datOperaciones_grupos
                //         grupos.forEach(
                //           grupo => {
                //             grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                //           });

                //         this.Jgrupos = grupos;
                //         this.gruposSeleccionados = [];

                //         this.DatOperaciones_grupos = grupos;

                //         r3 = true;
                //         if (r1 && r2 && r3) {
                //           this.operacionesSelected = [];
                //           this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //           this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                //           this.getGrafico();
                //         }
                //       }
                //     );
                //   });

                // }
                //#endregion
                //#region "LLAMADAS PARALELAS"
                this.loadingPanel = true;
                var r11, r12: boolean = false;
                this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
                  r11 = true;
                  if (r11 && r12) {
                    this.visibleInfo = false;
                    this.visibleInfoGrupo = false;
                    var r1, r2, r3: boolean = false;
                    //this.getGrafico();
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                      (json) => {
                        this.DatOperaciones = json;
                        var an: any = json;
                        this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                        r1 = true;
                        if (r1 && r2 && r3) {
                          this.operacionesSelected = [];
                          this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.getGrafico();
                        }
                      }
                    );
                    var version = this.JplanificadoresSelected.value;
                    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                      json => {
                        this.tiemposCorto = json;


                        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                          json => {
                            //CARGAR TIEMPOS EJECUTADOS START 
                            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                            var an: any = json;
                            var operacionesOrdenadas2 = {};
                            var operacionesEnEjecucion = {};
                            an.forEach(
                              a => {
                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                else
                                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                              });
                            var an2: any = this.tiemposCorto;
                            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                            an2.forEach(
                              row => {
                                // añadir las 3 nuevas variables
                                row['tiempoEjecutado'] = 0;
                                row['operacionEmpezada'] = false;
                                row['enEjecucion'] = false;

                                //se ponen las que estan en ejecucion en este momento
                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                if (enEjecucion) {
                                  if (enEjecucion == 1) {
                                    row['enEjecucion'] = true;
                                  }
                                }

                                //se descuenta el tiempo por maquina y operacion
                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                if (tiempoEjecutado) {
                                  //se le pone que esta empezada
                                  if (tiempoEjecutado > 0) {
                                    row['operacionEmpezada'] = true;
                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                    //se descuenta el tiempo de las 3 partes que afecta 
                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                    } else {
                                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                    }
                                  }
                                }
                              });
                            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                            an2.forEach(
                              row => {
                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                if (tiempoEjecutado) {
                                  if (tiempoEjecutado > 0) {
                                    row['operacionEmpezada'] = true;
                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                    //se descuenta el tiempo de las 3 partes que afecta 
                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                      operacionesOrdenadas2[row['idOperacion']] = 0;
                                    } else {
                                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                    }
                                  }
                                }
                              });
                            //CARGAR TIEMPOS EJECUTADOS END
                            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                            var an3: any = an2;
                            var jsonTiemposCorto: any = {};
                            var maquinas: any = [];
                            an3.forEach(
                              row => {
                                if (jsonTiemposCorto[row.idMaquina]) {
                                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                }
                                else {
                                  maquinas.push(row.idMaquina);
                                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                }
                              });
                            var valoresFinales: any = [];
                            maquinas.forEach(
                              maquina => {
                                var js: any = {};
                                js.idMaquina = maquina;
                                js.tiempo = jsonTiemposCorto[maquina];
                                valoresFinales.push(js);
                              });
                            this.operacionesCorto = an3;
                            this.tiemposCorto = valoresFinales;
                            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                            //CAMBIAR EL FORMATO AL RESULTADO END

                            r2 = true;

                            if (r1 && r2 && r3) {
                              this.operacionesSelected = [];
                              this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                              this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                              this.getGrafico();
                            }
                          }
                        );

                      }
                    );
                    this.planificadorService.get_grupos(version).subscribe(
                      (json) => {

                        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                        var grupos = (json as any).datOperaciones_grupos
                        grupos.forEach(
                          grupo => {
                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                          });

                        this.Jgrupos = grupos;
                        this.gruposSeleccionados = [];

                        this.DatOperaciones_grupos = grupos;

                        r3 = true;
                        if (r1 && r2 && r3) {
                          this.operacionesSelected = [];
                          this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.getGrafico();
                        }
                      }
                    );
                  }
                });

                this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {
                  r12 = true;
                  if (r11 && r12) {
                    this.visibleInfo = false;
                    this.visibleInfoGrupo = false;
                    var r1, r2, r3: boolean = false;
                    //this.getGrafico();
                    this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
                      (json) => {
                        this.DatOperaciones = json;
                        var an: any = json;
                        this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

                        r1 = true;
                        if (r1 && r2 && r3) {
                          this.operacionesSelected = [];
                          this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.getGrafico();
                        }
                      }
                    );
                    var version = this.JplanificadoresSelected.value;
                    this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                      json => {
                        this.tiemposCorto = json;

                        this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                          json => {
                            //CARGAR TIEMPOS EJECUTADOS START 
                            //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                            var an: any = json;
                            var operacionesOrdenadas2 = {};
                            var operacionesEnEjecucion = {};
                            an.forEach(
                              a => {
                                if (operacionesOrdenadas2[a['idOFs_Operacion']])
                                  operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                                else
                                  operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                                operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                                operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                              });
                            var an2: any = this.tiemposCorto;
                            //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                            an2.forEach(
                              row => {
                                // añadir las 3 nuevas variables
                                row['tiempoEjecutado'] = 0;
                                row['operacionEmpezada'] = false;
                                row['enEjecucion'] = false;

                                //se ponen las que estan en ejecucion en este momento
                                var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                                if (enEjecucion) {
                                  if (enEjecucion == 1) {
                                    row['enEjecucion'] = true;
                                  }
                                }

                                //se descuenta el tiempo por maquina y operacion
                                var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                                if (tiempoEjecutado) {
                                  //se le pone que esta empezada
                                  if (tiempoEjecutado > 0) {
                                    row['operacionEmpezada'] = true;
                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                    //se descuenta el tiempo de las 3 partes que afecta 
                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                                    } else {
                                      operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                      operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                    }
                                  }
                                }
                              });
                            //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                            an2.forEach(
                              row => {
                                var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                                if (tiempoEjecutado) {
                                  if (tiempoEjecutado > 0) {
                                    row['operacionEmpezada'] = true;
                                    //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                                    //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                                    var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                                    //se descuenta el tiempo de las 3 partes que afecta 
                                    if (tiempoEjecutado <= maximoTiempoAppointment) {
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                                      operacionesOrdenadas2[row['idOperacion']] = 0;
                                    } else {
                                      operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                                      row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                                    }
                                  }
                                }
                              });
                            //CARGAR TIEMPOS EJECUTADOS END
                            //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                            var an3: any = an2;
                            var jsonTiemposCorto: any = {};
                            var maquinas: any = [];
                            an3.forEach(
                              row => {
                                if (jsonTiemposCorto[row.idMaquina]) {
                                  jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                                }
                                else {
                                  maquinas.push(row.idMaquina);
                                  jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                                }
                              });
                            var valoresFinales: any = [];
                            maquinas.forEach(
                              maquina => {
                                var js: any = {};
                                js.idMaquina = maquina;
                                js.tiempo = jsonTiemposCorto[maquina];
                                valoresFinales.push(js);
                              });
                            this.operacionesCorto = an3;
                            this.tiemposCorto = valoresFinales;
                            this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                            //CAMBIAR EL FORMATO AL RESULTADO END

                            r2 = true;

                            if (r1 && r2 && r3) {
                              this.operacionesSelected = [];
                              this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                              this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                              this.getGrafico();
                            }
                          }
                        );


                      }
                    );
                    this.planificadorService.get_grupos(version).subscribe(
                      (json) => {

                        this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

                        var grupos = (json as any).datOperaciones_grupos
                        grupos.forEach(
                          grupo => {
                            grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                          });

                        this.Jgrupos = grupos;
                        this.gruposSeleccionados = [];

                        this.DatOperaciones_grupos = grupos;

                        r3 = true;
                        if (r1 && r2 && r3) {
                          this.operacionesSelected = [];
                          this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                          this.getGrafico();
                        }
                      }
                    );
                  }
                });
                //#endregion
                if (this.moverOperaciones_todas) {
                  this.planificadorService.mover_operacionesRelacionadas(semana - semanaDragged, reqInsertar).subscribe();
                }
              }

            });
        }
      }
      else {
        this.draggedOperacionPartida = dragged;
        this.semanaDraggedOperacionPartida = semanaDragged;
        this.semanaActualOperacionPartida = semanaActual;
        this.semanaOperacionPartida = semana;
        this.modalReference = this.modalService.open(this.popupOperacionPartida, { backdrop: 'static', size: 's', keyboard: false, centered: true });
      }
    }
    else {
      this.clickGrafico(e.subject)
    }
  }
  /*POPUP MOVER TODAS OPERACIONES END*/

  btnOperacionPartidaAceptar() {
    // se crea la estructura necesaria para la API
    //    Sacar
    var req: any = [];
    var req2: any = [];

    var dragged = this.draggedOperacionPartida;
    var semanaDragged = this.semanaDraggedOperacionPartida;
    var semanaActual = this.semanaActualOperacionPartida;
    var semana = this.semanaOperacionPartida;

    dragged.forEach(
      dragRow => {
        var r: any = {};
        r.idMaquina = this.selectedMaquina;
        r.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(semanaDragged + semanaActual /*+ 1*/, dragRow.año)); //si no se pasa a fecha asi se le quitan 2horas en la API //si aqui se le pone +1 no borra nada. 
        r.idOperacion = dragRow.idOperacion;
        r.idOperacionesGrupos = dragRow.idOperacionesGrupos;// en principio los grupos no se deverian de partir nunca! se baja la cantidad y el tiempo interno!
        r.nuevaCantidad = 0;
        r.version = this.JplanificadoresSelected.value;
        req.push(r);
        //    Meter
        var r_2: any = {};
        r_2.idMaquina = this.selectedMaquina;
        r_2.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(semana + semanaActual /*+ 1*/, dragRow.año));//si no se pasa a fecha asi se le quitan 2horas en la API // SI ES LUNES - 2 HACE MAL Y -1 BIEN //si se le pone +1 añade una semana mas tarde
        r_2.idRuta = dragRow.idRuta;
        r_2.idOperacion = dragRow.idOperacion;
        r_2.idOperacionesGrupos = dragRow.idOperacionesGrupos;// en principio los grupos no se deverian de partir nunca! se baja la cantidad y el tiempo interno!
        r_2.cantidad = dragRow.total; // dragRow.cantidad; AL HACER D&D DESPUES DE CAMBIAR LA CANTIDAD EN EL GRID, SOLO MOVIA LA CANTIDAD MOSTRADA Y NO EL TOTAL
        r_2.version = this.JplanificadoresSelected.value;
        req2.push(r_2);
      });

    if (req.length > 0 && req2.length > 0) {
      /*************************************************************************/
      /*****   POR LAS SIMULTANEAS SE TIENE QUE AÑADIR ANTES DE ELIMINAR   *****/
      /*************************************************************************/
      //#region "LLAMADAS SERIADAS"
      // if (req2.length > 0) {
      //   this.planificadorService.InsertOperacionesPlanificadas(req2).subscribe(r => {
      //     this.planificadorService.UpdateCantidades(req).subscribe(r => {
      //       this.visibleInfo = false;
      //       this.visibleInfoGrupo = false;
      //       var r1, r2, r3: boolean = false;
      //       //this.getGrafico();
      //       this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      //         (json) => {
      //           this.DatOperaciones = json;
      //           var an: any = json;
      //           this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

      //           r1 = true;
      //           if (r1 && r2 && r3) {
      //             this.operacionesSelected = [];
      //             this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //             this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //             this.getGrafico();
      //           }
      //         }
      //       );
      //       var version = this.JplanificadoresSelected.value;
      //       this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      //         json => {
      //           this.tiemposCorto = json;


      //           this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
      //             json => {
      //               //CARGAR TIEMPOS EJECUTADOS START 
      //               //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
      //               var an: any = json;
      //               var operacionesOrdenadas2 = {};
      //               var operacionesEnEjecucion = {};
      //               an.forEach(
      //                 a => {
      //                   if (operacionesOrdenadas2[a['idOFs_Operacion']])
      //                     operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
      //                   else
      //                     operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

      //                   operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
      //                   operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
      //                 });
      //               var an2: any = this.tiemposCorto;
      //               //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
      //               an2.forEach(
      //                 row => {
      //                   // añadir las 3 nuevas variables
      //                   row['tiempoEjecutado'] = 0;
      //                   row['operacionEmpezada'] = false;
      //                   row['enEjecucion'] = false;

      //                   //se ponen las que estan en ejecucion en este momento
      //                   var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
      //                   if (enEjecucion) {
      //                     if (enEjecucion == 1) {
      //                       row['enEjecucion'] = true;
      //                     }
      //                   }

      //                   //se descuenta el tiempo por maquina y operacion
      //                   var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
      //                   if (tiempoEjecutado) {
      //                     //se le pone que esta empezada
      //                     if (tiempoEjecutado > 0) {
      //                       row['operacionEmpezada'] = true;
      //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
      //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
      //                       //se descuenta el tiempo de las 3 partes que afecta 
      //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
      //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
      //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
      //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
      //                       } else {
      //                         operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
      //                         operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
      //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
      //                       }
      //                     }
      //                   }
      //                 });
      //               //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
      //               an2.forEach(
      //                 row => {
      //                   var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
      //                   if (tiempoEjecutado) {
      //                     if (tiempoEjecutado > 0) {
      //                       row['operacionEmpezada'] = true;
      //                       //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
      //                       //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
      //                       var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
      //                       //se descuenta el tiempo de las 3 partes que afecta 
      //                       if (tiempoEjecutado <= maximoTiempoAppointment) {
      //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
      //                         operacionesOrdenadas2[row['idOperacion']] = 0;
      //                       } else {
      //                         operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
      //                         row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
      //                       }
      //                     }
      //                   }
      //                 });
      //               //CARGAR TIEMPOS EJECUTADOS END
      //               //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
      //               var an3: any = an2;
      //               var jsonTiemposCorto: any = {};
      //               var maquinas: any = [];
      //               an3.forEach(
      //                 row => {
      //                   if (jsonTiemposCorto[row.idMaquina]) {
      //                     jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
      //                   }
      //                   else {
      //                     maquinas.push(row.idMaquina);
      //                     jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
      //                   }
      //                 });
      //               var valoresFinales: any = [];
      //               maquinas.forEach(
      //                 maquina => {
      //                   var js: any = {};
      //                   js.idMaquina = maquina;
      //                   js.tiempo = jsonTiemposCorto[maquina];
      //                   valoresFinales.push(js);
      //                 });
      //               this.operacionesCorto = an3;
      //               this.tiemposCorto = valoresFinales;
      //               this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
      //               //CAMBIAR EL FORMATO AL RESULTADO END

      //               r2 = true;
      //               if (r1 && r2 && r3) {
      //                 this.operacionesSelected = [];
      //                 this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //                 this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //                 this.getGrafico();
      //               }
      //             }
      //           );

      //         }
      //       );
      //       this.planificadorService.get_grupos(version).subscribe(
      //         (json) => {

      //           this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

      //           var grupos = (json as any).datOperaciones_grupos
      //           grupos.forEach(
      //             grupo => {
      //               grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
      //             });

      //           this.Jgrupos = grupos;
      //           this.gruposSeleccionados = [];

      //           this.DatOperaciones_grupos = grupos;

      //           r3 = true;
      //           if (r1 && r2 && r3) {
      //             this.operacionesSelected = [];
      //             this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //             this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //             this.getGrafico();
      //           }
      //         }
      //       );



      //     });
      //   });
      // } else {
      //   this.planificadorService.UpdateCantidades(req).subscribe(r => {
      //     this.visibleInfo = false;
      //     this.visibleInfoGrupo = false;
      //     var r1, r2, r3: boolean = false;
      //     //this.getGrafico();
      //     this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
      //       (json) => {
      //         this.DatOperaciones = json;
      //         var an: any = json;
      //         this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

      //         r1 = true;
      //         if (r1 && r2 && r3) {
      //           this.operacionesSelected = [];
      //           this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //           this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //           this.getGrafico();
      //         }
      //       }
      //     );
      //     var version = this.JplanificadoresSelected.value;
      //     this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      //       json => {
      //         this.tiemposCorto = json;


      //         this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
      //           json => {
      //             //CARGAR TIEMPOS EJECUTADOS START 
      //             //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
      //             var an: any = json;
      //             var operacionesOrdenadas2 = {};
      //             var operacionesEnEjecucion = {};
      //             an.forEach(
      //               a => {
      //                 if (operacionesOrdenadas2[a['idOFs_Operacion']])
      //                   operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
      //                 else
      //                   operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

      //                 operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
      //                 operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
      //               });
      //             var an2: any = this.tiemposCorto;
      //             //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
      //             an2.forEach(
      //               row => {
      //                 // añadir las 3 nuevas variables
      //                 row['tiempoEjecutado'] = 0;
      //                 row['operacionEmpezada'] = false;
      //                 row['enEjecucion'] = false;

      //                 //se ponen las que estan en ejecucion en este momento
      //                 var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
      //                 if (enEjecucion) {
      //                   if (enEjecucion == 1) {
      //                     row['enEjecucion'] = true;
      //                   }
      //                 }

      //                 //se descuenta el tiempo por maquina y operacion
      //                 var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
      //                 if (tiempoEjecutado) {
      //                   //se le pone que esta empezada
      //                   if (tiempoEjecutado > 0) {
      //                     row['operacionEmpezada'] = true;
      //                     //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
      //                     var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
      //                     //se descuenta el tiempo de las 3 partes que afecta 
      //                     if (tiempoEjecutado <= maximoTiempoAppointment) {
      //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
      //                       operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
      //                       operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
      //                     } else {
      //                       operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
      //                       operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
      //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
      //                     }
      //                   }
      //                 }
      //               });
      //             //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
      //             an2.forEach(
      //               row => {
      //                 var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
      //                 if (tiempoEjecutado) {
      //                   if (tiempoEjecutado > 0) {
      //                     row['operacionEmpezada'] = true;
      //                     //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
      //                     //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
      //                     var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
      //                     //se descuenta el tiempo de las 3 partes que afecta 
      //                     if (tiempoEjecutado <= maximoTiempoAppointment) {
      //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
      //                       operacionesOrdenadas2[row['idOperacion']] = 0;
      //                     } else {
      //                       operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
      //                       row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
      //                     }
      //                   }
      //                 }
      //               });
      //             //CARGAR TIEMPOS EJECUTADOS END
      //             //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
      //             var an3: any = an2;
      //             var jsonTiemposCorto: any = {};
      //             var maquinas: any = [];
      //             an3.forEach(
      //               row => {
      //                 if (jsonTiemposCorto[row.idMaquina]) {
      //                   jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
      //                 }
      //                 else {
      //                   maquinas.push(row.idMaquina);
      //                   jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
      //                 }
      //               });
      //             var valoresFinales: any = [];
      //             maquinas.forEach(
      //               maquina => {
      //                 var js: any = {};
      //                 js.idMaquina = maquina;
      //                 js.tiempo = jsonTiemposCorto[maquina];
      //                 valoresFinales.push(js);
      //               });
      //             this.operacionesCorto = an3;
      //             this.tiemposCorto = valoresFinales;
      //             this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
      //             //CAMBIAR EL FORMATO AL RESULTADO END

      //             r2 = true;
      //             if (r1 && r2 && r3) {
      //               this.operacionesSelected = [];
      //               this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //               this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //               this.getGrafico();
      //             }
      //           }
      //         );

      //       }
      //     );
      //     this.planificadorService.get_grupos(version).subscribe(
      //       (json) => {

      //         this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

      //         var grupos = (json as any).datOperaciones_grupos
      //         grupos.forEach(
      //           grupo => {
      //             grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
      //           });

      //         this.Jgrupos = grupos;
      //         this.gruposSeleccionados = [];

      //         this.DatOperaciones_grupos = grupos;

      //         r3 = true;
      //         if (r1 && r2 && r3) {
      //           this.operacionesSelected = [];
      //           this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //           this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
      //           this.getGrafico();
      //         }
      //       }
      //     );



      //   });
      // }
      //#endregion
      //#region "LLAMADAS PARALELAS"
      this.loadingPanel = true;
      var r11, r12: boolean = false;
      this.planificadorService.UpdateCantidades(req).subscribe(r => {
        r11 = true;
        if (r11 && r12) {
          this.visibleInfo = false;
          this.visibleInfoGrupo = false;
          var r1, r2, r3: boolean = false;
          //this.getGrafico();
          this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            (json) => {
              this.DatOperaciones = json;
              var an: any = json;
              this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

              r1 = true;
              if (r1 && r2 && r3) {
                this.operacionesSelected = [];
                this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.getGrafico();
              }
            }
          );
          var version = this.JplanificadoresSelected.value;
          this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
              this.tiemposCorto = json;


              this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                json => {
                  //CARGAR TIEMPOS EJECUTADOS START 
                  //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                  var an: any = json;
                  var operacionesOrdenadas2 = {};
                  var operacionesEnEjecucion = {};
                  an.forEach(
                    a => {
                      if (operacionesOrdenadas2[a['idOFs_Operacion']])
                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                      else
                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                      operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                      operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                    });
                  var an2: any = this.tiemposCorto;
                  //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                  an2.forEach(
                    row => {
                      // añadir las 3 nuevas variables
                      row['tiempoEjecutado'] = 0;
                      row['operacionEmpezada'] = false;
                      row['enEjecucion'] = false;

                      //se ponen las que estan en ejecucion en este momento
                      var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                      if (enEjecucion) {
                        if (enEjecucion == 1) {
                          row['enEjecucion'] = true;
                        }
                      }

                      //se descuenta el tiempo por maquina y operacion
                      var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                      if (tiempoEjecutado) {
                        //se le pone que esta empezada
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                  an2.forEach(
                    row => {
                      var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                      if (tiempoEjecutado) {
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //CARGAR TIEMPOS EJECUTADOS END
                  //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                  var an3: any = an2;
                  var jsonTiemposCorto: any = {};
                  var maquinas: any = [];
                  an3.forEach(
                    row => {
                      if (jsonTiemposCorto[row.idMaquina]) {
                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                      }
                      else {
                        maquinas.push(row.idMaquina);
                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                      }
                    });
                  var valoresFinales: any = [];
                  maquinas.forEach(
                    maquina => {
                      var js: any = {};
                      js.idMaquina = maquina;
                      js.tiempo = jsonTiemposCorto[maquina];
                      valoresFinales.push(js);
                    });
                  this.operacionesCorto = an3;
                  this.tiemposCorto = valoresFinales;
                  this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                  //CAMBIAR EL FORMATO AL RESULTADO END

                  r2 = true;
                  if (r1 && r2 && r3) {
                    this.operacionesSelected = [];
                    this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                    this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                    this.getGrafico();
                  }
                }
              );

            }
          );
          this.planificadorService.get_grupos(version).subscribe(
            (json) => {

              this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

              var grupos = (json as any).datOperaciones_grupos
              grupos.forEach(
                grupo => {
                  grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                });

              this.Jgrupos = grupos;
              this.gruposSeleccionados = [];

              this.DatOperaciones_grupos = grupos;

              r3 = true;
              if (r1 && r2 && r3) {
                this.operacionesSelected = [];
                this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.getGrafico();
              }
            }
          );


        }
      });
      this.planificadorService.InsertOperacionesPlanificadas(req2).subscribe(r => {
        r12 = true;
        if (r11 && r12) {
          this.visibleInfo = false;
          this.visibleInfoGrupo = false;
          var r1, r2, r3: boolean = false;
          //this.getGrafico();
          this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            (json) => {
              this.DatOperaciones = json;
              var an: any = json;
              this.DatOperaciones = an.filter(f => f.idMaquina == this.selectedMaquina && f.tipo == this.selectedMaquinaTipo)

              r1 = true;
              if (r1 && r2 && r3) {
                this.operacionesSelected = [];
                this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.getGrafico();
              }
            }
          );
          var version = this.JplanificadoresSelected.value;
          this.planificadorService.Get_tiempos_planificador_corto(0, version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
            json => {
              this.tiemposCorto = json;

              this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
                json => {
                  //CARGAR TIEMPOS EJECUTADOS START 
                  //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
                  var an: any = json;
                  var operacionesOrdenadas2 = {};
                  var operacionesEnEjecucion = {};
                  an.forEach(
                    a => {
                      if (operacionesOrdenadas2[a['idOFs_Operacion']])
                        operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
                      else
                        operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

                      operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
                      operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
                    });
                  var an2: any = this.tiemposCorto;
                  //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
                  an2.forEach(
                    row => {
                      // añadir las 3 nuevas variables
                      row['tiempoEjecutado'] = 0;
                      row['operacionEmpezada'] = false;
                      row['enEjecucion'] = false;

                      //se ponen las que estan en ejecucion en este momento
                      var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
                      if (enEjecucion) {
                        if (enEjecucion == 1) {
                          row['enEjecucion'] = true;
                        }
                      }

                      //se descuenta el tiempo por maquina y operacion
                      var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];
                      if (tiempoEjecutado) {
                        //se le pone que esta empezada
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
                  an2.forEach(
                    row => {
                      var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
                      if (tiempoEjecutado) {
                        if (tiempoEjecutado > 0) {
                          row['operacionEmpezada'] = true;
                          //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                          //var maximoTiempoAppointment = row['tiempoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimado']) - row['tiempoEjecutado'];
                          var maximoTiempoAppointment = row['tiempo'] - row['tiempoEjecutado'];
                          //se descuenta el tiempo de las 3 partes que afecta 
                          if (tiempoEjecutado <= maximoTiempoAppointment) {
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                            operacionesOrdenadas2[row['idOperacion']] = 0;
                          } else {
                            operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                            row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                          }
                        }
                      }
                    });
                  //CARGAR TIEMPOS EJECUTADOS END
                  //CAMBIAR EL FORMATO AL RESULTADO START (para que todo lo demas sigua igual)
                  var an3: any = an2;
                  var jsonTiemposCorto: any = {};
                  var maquinas: any = [];
                  an3.forEach(
                    row => {
                      if (jsonTiemposCorto[row.idMaquina]) {
                        jsonTiemposCorto[row.idMaquina] = jsonTiemposCorto[row.idMaquina] + (row.tiempo - row.tiempoEjecutado);
                      }
                      else {
                        maquinas.push(row.idMaquina);
                        jsonTiemposCorto[row.idMaquina] = (row.tiempo - row.tiempoEjecutado);
                      }
                    });
                  var valoresFinales: any = [];
                  maquinas.forEach(
                    maquina => {
                      var js: any = {};
                      js.idMaquina = maquina;
                      js.tiempo = jsonTiemposCorto[maquina];
                      valoresFinales.push(js);
                    });
                  this.operacionesCorto = an3;
                  this.tiemposCorto = valoresFinales;
                  this.operacionesOrdenadasEjecucionFueraCorto = operacionesOrdenadas2;
                  //CAMBIAR EL FORMATO AL RESULTADO END

                  r2 = true;
                  if (r1 && r2 && r3) {
                    this.operacionesSelected = [];
                    this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                    this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                    this.getGrafico();
                  }
                }
              );


            }
          );
          this.planificadorService.get_grupos(version).subscribe(
            (json) => {

              this.DatOperaciones_grupos_operaciones = (json as any).datOperaciones_grupos_operaciones;

              var grupos = (json as any).datOperaciones_grupos
              grupos.forEach(
                grupo => {
                  grupo.nombre = this.translateService.instant("grupo") + " " + grupo.idOperacionesGrupos;
                });

              this.Jgrupos = grupos;
              this.gruposSeleccionados = [];

              this.DatOperaciones_grupos = grupos;

              r3 = true;
              if (r1 && r2 && r3) {
                this.operacionesSelected = [];
                this.Joperaciones = this.DatOperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.JoperacionesVal = this.Joperaciones; // ARATZ: siempre que se recarga el grafico se actualiza la tabla inferior
                this.getGrafico();
              }
            }
          );
        }
      });
      //#endregion
      if (this.moverOperaciones_todas) {
        this.planificadorService.mover_operacionesRelacionadas(semana - semanaDragged, req2).subscribe();
      }
    }

    this.draggedOperacionPartida = undefined;
    this.semanaDraggedOperacionPartida = undefined;
    this.semanaActualOperacionPartida = undefined;
    this.semanaOperacionPartida = undefined;

    this.modalReference.close();

  }
  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  btnIrAOperacion(dataItem) {
    const url = this.router.serializeUrl(this.router.parseUrl('#/operaciones/editar/' + dataItem.idOf + "/" + dataItem.idPieza + "/" + dataItem.idParte + "/" + dataItem.idRuta + "/" + dataItem.idOperacion + "/1"));
    window.open(url, '_blank');
  }

  /*HELLPERS START*/
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  getNumberOfWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }
  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  dateToYYYYMMDD(fecha: Date) {
    //2020-10-25
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return año + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }
  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }
  reglaDeTres(valor: number, total: number, sobre: number) {
    if (total == 0)
      return 0;
    else {
      return Number(Math.floor(sobre * valor / total));
    }
  }
  /*HELLPERS END*/
}
