import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { BoxplotService, HistoricoOperacionesService, InformeProyectosService, MaquinasService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { first } from 'rxjs/operators';
import { GroupResult, groupBy } from '@progress/kendo-data-query';


@Component({
  selector: 'app-comparativa-tiempos-boxplot',
  templateUrl: './comparativa-tiempos-boxplot.component.html'
})
export class ComparativaTiemposBoxplotComponent implements OnInit {

  user = this.userService.userValue;

  //Para saber cuando dibujar cosas o cuando cargar círculo cargando
  public loadingDatos: boolean = true;
  public tieneDatos: boolean = true;

  //For filter
  public hayDatosFiltro: boolean = false;

  private hayDatosGenerales: boolean = false;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public terminados: boolean = false;

  public listaOperaciones: any; //POR TEXTO
  public operacionesSeleccionadas: any;

  public fechaIni: Date;
  public fechaFin: Date;

  public listaPiezas: any;
  public piezasSeleccionadas: any;

  public listaPartes: any; 
  public partesSeleccionadas: any;

  public listaMaquinas: any;
  public listaMaquinasMostradas: any;
  public maquinasSeleccionadas: any;
  private hayGruposMaquinas: boolean = false;

  public listaOfs: any;
  public ofsSeleccionados: any;

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];
  private haySecciones: boolean = false;

  //GRUPOS DE MAQUINAS
  public JgruposMaquinasCombo: any;
  public JgruposMaquinasSelected: any;
  public JgruposMaquinasMaquinas: any;
  private hayMaquinas: boolean = false;

  //Datos completos del filtro
  private dataFiltro: any;

  //Datos grid
  public dataInforme: any;
  private dataTabla: any;

  //PARA CONTROLAR PAGINACION GRID
  public skip = 0;

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private informeProyectosService: InformeProyectosService,
    private userService: UsuariosService,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions,
    private boxplotService: BoxplotService,
    private maquinasService: MaquinasService) { 

    //GET GRUPOS
    this.maquinasService.getGruposMaquinas().subscribe(
      json => {
        this.JgruposMaquinasCombo = json.data;
        this.JgruposMaquinasSelected = undefined;
        this.hayGruposMaquinas = true;
      }
    );

    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined;

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {
      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
          this.haySecciones = true;
          this.cargarDatosFiltro();
        });
    } else {
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
      this.haySecciones = true;
      this.cargarDatosFiltro();
    }

    this.hayDatosFiltro = false;

    this.menuService.titulo = this.translateService.instant('comparativa-boxplot').toUpperCase();
    this.userService.user.subscribe(x => this.user = x);
  }

  ngOnInit(): void {
  }

  public showGridTooltip(e: MouseEvent): void { //para mostrar un tooltip con el dato al pasar el ratón por encima cuando el título o contenido no cabe
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  // Si cambian la fecha de inicio o final ponerles las horas
  public onChangeFechaIni(value: Date): void {
    if (value == null) this.fechaIni = undefined;
    else this.fechaIni.setHours(0, 0, 0);
  }

  public onChangeFechaFin(value: Date): void {
    if (value == null) this.fechaFin = undefined;
    else this.fechaFin.setHours(0, 0, 0);
  }

  CambioFiltro(): void { //si los datos del filtro cambian actualizar cuáles son los datos seleccionados

    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.nombrePieza));

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.nombreOperacion));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.nombreParte));

    var groupByCliente = [];
    var groupByPieza = [];
    var groupByOf = [];
    var groupByOperacion = [];
    var groupByParte = [];

    //GROUP BY OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
        ) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

    //GROUP BY PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));
    
    //GROUP BY POR OPERACION
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreOperacion]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          ) {
          lag[row.nombreOperacion] = {
            idOperacion: row.idOperacion, nombreOperacion: row.nombreOperacion,
          };
          groupByOperacion.push(lag[row.nombreOperacion]);
        }
      });

    this.listaOperaciones = groupByOperacion.filter(item => (item.nombreOperacion != ''));
    this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    //GROUP BY PARTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreParte]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombreParte] = {
            idParte: row.idParte, nombreParte: row.nombreParte,
          };
          groupByParte.push(lag[row.nombreParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.nombreParte !== ""));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

    this.cargarDatosInforme();
  }

  seccionChanged() { //Cuando cambian las secciones desde el panel de usuario actualizar la web y actualizar el filtro (solo con las secciones por estar también asociadas al perfil del usuario)
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.JgruposMaquinasSelected) {
      this.JgruposMaquinasSelected.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }

    this.listaMaquinasMostradas = this.listaMaquinas.filter(f => {
      var enGrupo = false

      f.idsGrupos.forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });

      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });
  }

  btnFiltrar(){
    this.cargarDatosInforme();
  }

  btnLimpiarFiltro(): void { //si se quiere limpiar el filtro vaciar los seleccionados y volver a cargar los datos

    this.maquinasSeleccionadas = undefined;
    this.ofsSeleccionados = undefined;
    this.clientesSeleccionados = undefined;
    this.piezasSeleccionadas = undefined;
    this.operacionesSeleccionadas = undefined;
    this.seccionesSeleccionadas = undefined;
    this.JgruposMaquinasSelected = undefined;
    this.operacionesSeleccionadas = undefined;
    this.partesSeleccionadas = undefined;
    this.terminados = false;

    this.CambioFiltro();

  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //Primero se asignan las fechas
    this.fechaIni = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000)); //tomamos la fecha actual y echamos atrás seis días
    this.fechaFin = this.myFunctions.getDateNow()
    this.fechaIni.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);

    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        this.listaMaquinas = mostra;
        this.seccionChanged();
        this.hayMaquinas = true;
        if (this.hayDatosGenerales && this.hayGruposMaquinas && this.hayMaquinas && this.haySecciones) // si tenemos todos los datos del filtro
          this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar
      });

    //se toman los datos de los clientes, piezas, ofs y operaciones
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes().pipe(first()).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByOperacion = [];
      var groupByParte = [];

      //GROUP BY CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));;
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0))

      //GROUP POR PARTE
      data.forEach(function (a) {
        if (!this[a.nombreParte]) {
          this[a.nombreParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.nombreParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.nombreParte !== ""));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));
      
      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR OPERACION
      data.forEach(function (a) {
        if (!this[a.nombreOperacion]) {
          this[a.nombreOperacion] = {
            idOperacion: a.idOperacion, nombreOperacion: a.nombreOperacion,
          };
          groupByOperacion.push(this[a.nombreOperacion]);
        }
      }, Object.create(null));

      this.listaOperaciones = groupByOperacion.filter(item => (item.nombreOperacion != ''));
      this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

      this.hayDatosGenerales = true;
      if (this.hayDatosGenerales && this.hayGruposMaquinas && this.hayMaquinas && this.haySecciones) // si tenemos todos los datos del filtro
        this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar

      this.cargarDatosInforme(); //cargar la tabla y los datos
    });
  }

  cellClick(e: any): void{ //si se clica en la row

  }

  cargarDatosInforme(): void {

    this.loadingDatos = true; //se están cargando los datos, mostrar barra de carga

    //Cogemos los datos para la llamada de los seleccionados en el filtro
    var listaNombresClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.nombreCliente);
    var listaNombresPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => a.nombrePieza);
    var listaNombresOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.nombreOf);
    var listaPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => a.nombreParte);
    var fechaInicio = (this.fechaIni === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaIni);
    var fechaFin = (this.fechaFin === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    var terminado;
    if (this.terminados){
      terminado = 1;
    } else {
      terminado = 2;
    }
    //Tomar datos piezas para tabla
    this.boxplotService.GetInformeTablaPiezas(fechaInicio, fechaFin, listaNombresClientes.join("&"), listaNombresPiezas.join("&"), listaNombresOfs.join("&"), listaPartes.join("&"), "", "", "", "", terminado).pipe(first()).subscribe(
      (result: any) => {
        this.dataTabla = result.data;
        if (this.dataTabla !== undefined && this.dataTabla !== null){ //si tenemos los datos de la tabla, los ordenamos (si no había datos podía devolvernos un null)
          this.dataInforme = this.dataTabla.sort((a, b) => {
            // Create the dates
            var keyA = a.pieza,
              keyB = b.pieza;
            // Compare the 2 dates
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });;
        } 
    
        this.loadingDatos = false; //ya no se están cargando los datos
        //Ponemos el skip a 0 para mandar el grid a la primera pagina
        this.skip = 0;
        this.mostrarDatosDivs();
      });
  }

  mostrarDatosDivs(): void{
    var sumHorasEstimadas = 0;
    var sumHorasEstimadasReales = 0;
    var sumHorasEstimadasDesvio = 0;
    var sumHorasEstimadasPorcen = 0;
    var sumHorasPredictivas = 0;
    var sumHorasPredictivasDesvio = 0;
    var sumHorasPredictivasPorcen = 0;
    this.dataInforme.forEach(elem => {
      sumHorasEstimadas += elem.tiempoEstimadoTotal;
      sumHorasEstimadasReales += elem.tiempoTotal;
      sumHorasEstimadasDesvio += elem.desvio;
      sumHorasEstimadasPorcen += elem.porcenDesvio;
      sumHorasPredictivas += elem.tiempoPredictivoTotal;
      sumHorasPredictivasDesvio += elem.desvioPredictivo;
      sumHorasPredictivasPorcen += elem.porcenDesvioPredictivo;
    });

    //ESTIMADAS

    var bloqueHorasEstimadas = document.getElementsByClassName('horasEstimadas-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasEstimadas.children.length; i++) {
      if (bloqueHorasEstimadas.children[i].classList.contains('horasEstimadas-horas')){
        bloqueHorasEstimadas.children[i].innerHTML = this.myFunctions.secondsTo_HH_MM(sumHorasEstimadas).toString();
      }
    }
    var bloqueHorasEstimadasReales = document.getElementsByClassName('horasReales-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasEstimadasReales.children.length; i++) {
      if (bloqueHorasEstimadasReales.children[i].classList.contains('horasreales-horas')){
        bloqueHorasEstimadasReales.children[i].innerHTML = this.myFunctions.secondsTo_HH_MM(sumHorasEstimadasReales).toString();
      }
    }

    var bloqueHorasEstimadasDesvio = document.getElementsByClassName('desvio-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasEstimadasDesvio.children.length; i++) {
      if (bloqueHorasEstimadasDesvio.children[i].classList.contains('desvio-horas')){
        bloqueHorasEstimadasDesvio.children[i].innerHTML = this.myFunctions.secondsTo_HH_MM(sumHorasEstimadasDesvio).toString();
      }
    }

    var bloqueHorasEstimadasPorcen = document.getElementsByClassName('porcentaje-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasEstimadasPorcen.children.length; i++) {
      if (bloqueHorasEstimadasPorcen.children[i].classList.contains('porcentaje-horas')){
        bloqueHorasEstimadasPorcen.children[i].innerHTML = sumHorasEstimadasPorcen.toFixed(1).toString();
      }
    }

    //PREDICTIVAS

    var bloqueHorasPredictivas = document.getElementsByClassName('horasPredictivas-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasPredictivas.children.length; i++) {
      if (bloqueHorasPredictivas.children[i].classList.contains('horasEstimadas-horas')){
        bloqueHorasPredictivas.children[i].innerHTML = this.myFunctions.secondsTo_HH_MM(sumHorasPredictivas).toString();
      }
    }
    var bloqueHorasEstimadasReales = document.getElementsByClassName('horasPredctivasReales-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasEstimadasReales.children.length; i++) {
      if (bloqueHorasEstimadasReales.children[i].classList.contains('horasreales-horas')){
        bloqueHorasEstimadasReales.children[i].innerHTML = this.myFunctions.secondsTo_HH_MM(sumHorasEstimadasReales).toString();
      }
    }

    var bloqueHorasPredictivasDesvio = document.getElementsByClassName('desvioPredictivas-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasPredictivasDesvio.children.length; i++) {
      if (bloqueHorasPredictivasDesvio.children[i].classList.contains('desvio-horas')){
        bloqueHorasPredictivasDesvio.children[i].innerHTML = this.myFunctions.secondsTo_HH_MM(sumHorasPredictivasDesvio).toString();
      }
    }

    var bloqueHorasPredictivasPorcen = document.getElementsByClassName('porcentajePredictivas-bloque')[0].children[0].children[0];
    for (var i = 0; i < bloqueHorasPredictivasPorcen.children.length; i++) {
      if (bloqueHorasPredictivasPorcen.children[i].classList.contains('porcentaje-horas')){
        bloqueHorasPredictivasPorcen.children[i].innerHTML = sumHorasPredictivasPorcen.toFixed(1).toString();
      }
    }

    
  }

}
