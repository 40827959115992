import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, Eskola_AsignaturasRetosService, UsuariosService, OperariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-asignaturasretos',
  templateUrl: './eskola_asignaturasretos.component.html'
  })
export class Eskola_AsignaturasRetosComponent implements OnInit{

  form: FormGroup;
  dataAsignaturasRetos: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  public docentes: any = [];
  numeroCirculos: number = 5;

  modalReference: NgbModalRef;
  user = this.userService.userValue;

  constructor(private asignaturasRetosService: Eskola_AsignaturasRetosService,
    private alertService: AlertService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('asignaturasretos').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/eskola_asignaturasretos') {
          this.cargarDatos();
        }
      }
    });
  
  }


  cargarDatos() {
    this.asignaturasRetosService.getAll().subscribe((result) => {
      this.dataAsignaturasRetos = result;
      if(this.dataAsignaturasRetos != null && this.dataAsignaturasRetos.length > 0)
        this.dataAsignaturasRetos.forEach(element => {
          //Procesamos los docentes
          if(element.docentes != null){
            var docentes = element.docentes.split(",");
            var docentesAux = [];
            var docentesAux2 = [];
            if( element.colores != null){
              var colores = element.colores.split(",");
            }
            var docentesC = [];
            docentes.forEach(docente => {
              var nombre;
              var apellido;
              if (docente.split(";").length > 0) {
                if (docente == this.translateService.instant("desconocido")){
                  docentesAux.push("undefinied");
                  docentesAux2.push("undefinied");
                } else {
                  nombre = docente.split(';')[0];
                  apellido = docente.split(';')[1];
                  if (apellido != undefined){
                    docentesAux2.push(nombre + " " + apellido);
                  } else {
                    docentesAux2.push(nombre);
                  } 
                  if (nombre != undefined){
                    nombre = nombre.trim().substring(0, 1).toUpperCase();
                  }
                  if(apellido != undefined){
                    apellido = apellido.trim().substring(0, 1).toUpperCase();
                  }
                  docentesAux.push(nombre+apellido);
                }
              }
            }
            );
            if( element.colores != null){
              colores.forEach(color => {
                docentesC.push(color);
              });
            }
            element.docentesAux2 = docentesAux2.join(",");
            element.docentesAux = docentesAux.join(",");
            element.docentesC = docentesC.join(",");
          }

          

        })
    });

  }

    ngOnInit(): void {

  }

  cellClick(e) {
      if (e.columnIndex > 0) {
        this.router.navigate(['eskola_asignaturasretos/editar/', this.mySelection[0]]);
      }
    }

  onClickEditar() {
      if (this.mySelection[0] > 0) {
        this.router.navigate(['eskola_asignaturasretos/editar/', this.mySelection[0]]);
      }
  }

  onClickNuevo() {
    this.router.navigate(['eskola_asignaturasretos/crear']);
  } 

  onClickEliminar(content) {
  if (this.mySelection[0] > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }
  }

  eliminarRegistro() {

      this.mySelection.forEach(element => {
        if (element > 0) {
          this.isDeleting = true;
          this.form = this.formBuilder.group({
            id: element
          });
          this.asignaturasRetosService.delete(this.form.value.id).subscribe({
            next: () => {
              this.dataAsignaturasRetos = this.dataAsignaturasRetos.filter(x => x.id !== element);
              this.isDeleting = false;
              this.alertService.success(this.translateService.instant('eliminadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../eskola_asignaturasretos'], { relativeTo: this.route });
            },
            error: error => {
              this.isDeleting = false;
              this.alertService.error(error);
            }
          });
        }
      });
  
      this.modalReference.close();
      this.mySelection = [];
  
    }

}
