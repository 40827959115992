import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivosService, MenuService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-arbol-activos-maestros',
  templateUrl: './arbol-activos-maestros.component.html',
  styleUrls: ['./arbol-activos-maestros.component.less']
})
export class ArbolActivosMaestrosComponent implements OnInit {

  public data: any;
  public stock: boolean;
  public listaTiposActivo: any;
  public tiposActivosSeleccionados: any;
  public listaJerarquiaActivo: any;
  public jerarquiaActivosSeleccionados: any;
  public listaReferencias: any = [];
  public referenciasSeleccionadas: any;
  public listaCodigosBarras: any = [];
  public codigosBarrasSeleccionados: any;
  public hayDatosFiltro: boolean;
  constructor(private activosService: ActivosService,
    private menuService: MenuService, public router: Router, private translateService: TranslateService) {
      this.menuService.titulo = this.translateService.instant('arbolActivosMaestros');
     }
    
  ngOnInit(): void {
    //Inicializar variables filtro
    this.hayDatosFiltro = false;
    this.cargarDatosFiltro();

    this.activosService.getArbolCompleto().subscribe((response: any)=>{
      this.data = response;
    });
  }

  cargarDatosFiltro(){
    this.stock = false;

    var r1= false, r2= false, r3= false;
    this.activosService.getAllTipos().subscribe((response: any)=>{
      this.listaTiposActivo = response;
      r1= true;
      if(r1 && r2 && r3)
        this.hayDatosFiltro = true;
    });

    this.activosService.getJerarquia().subscribe((response: any)=>{
      this.listaJerarquiaActivo = response;
      r2= true;
      if(r1 && r2 && r3)
        this.hayDatosFiltro = true;
    });

    this.activosService.GetAllReferenciasCodigoBarras().subscribe((response: any)=>{
      var count = 0; //Les ponemos ids por si acaso
      response.forEach(element => {
        if(element.referencia != null && element.referencia != ''){
          let aux = {id: count, referencia: element.referencia};
          this.listaReferencias.push(aux);
        }
        if(element.codigoEAN != null && element.codigoEAN != ''){
          let aux = {id: count, codigoBarras: element.codigoEAN};
          this.listaCodigosBarras.push(aux);
        }
        count++;
      });
      r3= true;
      if(r1 && r2 && r3)
        this.hayDatosFiltro = true;
    });
  }

  btnFiltrar(){
    var codigosBarras = ""; var idsTipos = ""; var referencias = ""; var idsJerarquias = "";
    if(this.jerarquiaActivosSeleccionados){
      this.jerarquiaActivosSeleccionados.forEach(element => {
        if(idsJerarquias == ""){
          idsJerarquias += element.id;
        }else{
          idsJerarquias += "," + element.id;
        }
      });
    }
    if(this.tiposActivosSeleccionados){
      this.tiposActivosSeleccionados.forEach(element => {
        if(idsTipos == ""){
          idsTipos += element.id;
        }else{
          idsTipos += "," + element.id;
        }
      });
    }
    if(this.referenciasSeleccionadas){
      this.referenciasSeleccionadas.forEach(element => {
        if(referencias == ""){
          referencias += "'" + element.referencia + "'";
        }else{
          referencias += ", '" + element.referencia + "'";
        }
      });
    }
    if(this.codigosBarrasSeleccionados){
      this.codigosBarrasSeleccionados.forEach(element => {
        if(codigosBarras == ""){
          codigosBarras += "'" + element.codigoBarras + "'";
        }else{
          codigosBarras += ", '" + element.codigoBarras + "'";
        }
      });
    }
    this.activosService.getArbolFiltrado(idsJerarquias, idsTipos, referencias, codigosBarras, this.stock).subscribe((response: any)=>{
      this.data = response;
      this.data.forEach(element => {
        var aux = this.data.filter(x =>x.id == element.idPadre);
        if (aux.length == 0){
          element.idPadre = null;
        }
      });
    });
  }

  btnLimpiarFiltro(){
    this.tiposActivosSeleccionados = undefined;
    this.jerarquiaActivosSeleccionados = undefined;
    this.codigosBarrasSeleccionados = undefined;
    this.referenciasSeleccionadas = undefined;
    this.btnFiltrar();
  }

}
