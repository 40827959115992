import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { InstalacionesService } from '@app/_services/instalaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, AlertService, UsuariosService } from '../_services';

@Component({
  selector: 'app-instalaciones',
  templateUrl: 'instalaciones.component.html'
})

export class InstalacionesComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public instalaciones: any = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private instalacionesService: InstalacionesService,
    translate: TranslateService,
    private alertService: AlertService,
    public router: Router,
    private userService: UsuariosService,
    private menuService: MenuService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('instalaciones').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/instalaciones') {
          this.cargarDatos();
        } else {
        }
      }
    });

  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {

    this.instalacionesService.GetAll().subscribe((result) => {
      this.instalaciones = result.data;
    });

  }

  onClickNuevo() {
    this.router.navigate(['instalaciones/crear']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['instalaciones/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['instalaciones/editar/' + this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.instalacionesService.deleteInstalacion(this.seleccionados.join(',')).subscribe((data) => {
      if (data.error == false) {
        this.instalacionesService.GetAll().subscribe((result) => {
          this.instalaciones = result.data;
        });
      } else {
        this.alertService.error(this.translate.instant('error'), { keepAfterRouteChange: true });
      }
      this.modalReferenceloading.close();
    });
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
