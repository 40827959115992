import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation, Renderer2, NgZone, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UsuariosService, AlertService, MenuService, DiccionarioPerdidasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { State, process } from '@progress/kendo-data-query';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { RowArgs, CellClickEvent } from "@progress/kendo-angular-grid";
import { TooltipDirective } from '@progress/kendo-angular-tooltip';




const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-editar-crear-diccionario-perdidas-grupos',
  templateUrl: './editar-crear-diccionario-perdidas-grupos.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`.k-grid tr.dragging {
                background-color: #00b8bd;
            };`]})

export class EditarCrearDiccionarioPerdidasGruposComponent implements OnInit {

  private translate: TranslateService;


  public state: State = {
    skip: 0,
    take: 100
  };
  public gridData: any; //datos del diccionario de pérdidas a mostrar en el grid
  private currentSubscription: Subscription;

  form: FormGroup; //formulario con datos grupo
  id: string;
  isAddMode: boolean; //para saber si es modo de edición o creación
  checkscargados: boolean;
  loading = false;
  submitted = false;
  user = this.usuariosService.userValue;
  dataGrupo: any; //datos del grupo
  dataDiccionarioPerdidasGrupo: any[]; //datos del grupo de diccionario de pérdidas
  diccionarioPerdida: any; //lista datos diccionariopérdida
  row: any;
  mySelection2: number[] = [];
  mySelection2Aux: number[] =[];

  public fromAllPerdidasSelect: boolean= false;
  public buttonAllPerdidasSelectName: string='';

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private alertService: AlertService,
    translate: TranslateService,
    private menuService: MenuService,
    private diccionarioPerdidasService: DiccionarioPerdidasService,
    private renderer: Renderer2,
    private zone: NgZone
  ) {
    this.translate = translate;

 //   if (this.gridData.length<=this.mySelection2Aux.length)      this.buttonAllPerdidasSelectName=this.translate.instant('seleccionarTodo');
 //   else    this.buttonAllPerdidasSelectName=this.translate.instant('deseleccionarTodo');

    this.gridData = process([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}], this.state);
    if (this.gridData.length<=this.mySelection2Aux.length)      this.buttonAllPerdidasSelectName=this.translate.instant('seleccionarTodo');
    else    this.buttonAllPerdidasSelectName=this.translate.instant('deseleccionarTodo');
  }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('perdidasGrupo');
    //ver si es modo de edición o creación
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.checkscargados = true;

     this.form = this.formBuilder.group({ //se crea el formulario
      idGrupo: this.route.snapshot.params['id'],
      idDb: this.user.idDb,
      nombre: new FormControl('', [Validators.required]),
      activo: new FormControl(true),
      idDiccionarioPerdidas: ''
    });

    // se toman las pérdidas pertenecintes al grupo seleccionado
    this.diccionarioPerdidasService.getGrupoDiccionarioPerdidasById(this.id).subscribe(json => {
      this.dataGrupo = json.data[0];
      this.form.patchValue(this.dataGrupo);
      this.dataDiccionarioPerdidasGrupo = json.data2;
      this.gridData = process(json.data2, this.state);
      this.gridData.data.forEach(element => {
        var a = element;
        element.tipoPerdida = this.translate.instant(element.tipoPerdida);
      });
      console.log(this.gridData);
      this.selectDiccionarios();
    }); 

  }

  public ngAfterViewInit(): void {    
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  selectDiccionarios() { //función para obtener la selecciçon de pérdidas
    this.mySelection2 = [];
    for (this.diccionarioPerdida of this.dataDiccionarioPerdidasGrupo) {
      if (this.diccionarioPerdida.seleccionado == true){
        this.mySelection2.push(this.diccionarioPerdida.id);
      }
    }
    this.mySelection2Aux = Object.assign([], this.mySelection2); //kopia
    if (this.mySelection2.length<this.gridData.data.length)      this.buttonAllPerdidasSelectName=this.translate.instant('seleccionarTodo');
    else    this.buttonAllPerdidasSelectName=this.translate.instant('deseleccionarTodo');
  } 

 guardarDiccionarioPerdidas() { //función para guardar el nuevo diccionario de pérdidas o el editado
    var i = 0;
    for (this.diccionarioPerdida of this.dataDiccionarioPerdidasGrupo) {
      this.dataDiccionarioPerdidasGrupo[i].seleccionado = false;
      i = i + 1;
    }
    
    for (this.row of this.mySelection2) {
      i = 0
      for (this.diccionarioPerdida of this.dataDiccionarioPerdidasGrupo) {
        if(this.diccionarioPerdida.id == this.row){
          this.dataDiccionarioPerdidasGrupo[i].seleccionado = true;
          break;
        }
        i = i + 1;
      }
      
    }
    var id = 0;
    for (this.diccionarioPerdida of this.dataDiccionarioPerdidasGrupo) {
      if (id == 0) {
        if (this.diccionarioPerdida.seleccionado == true){
          this.form.get('idDiccionarioPerdidas').setValue(this.diccionarioPerdida.id);
          id = 1;
        }
      } else {
        if (this.diccionarioPerdida.seleccionado == true){
          this.form.get('idDiccionarioPerdidas').setValue(this.form.get('idDiccionarioPerdidas').value + ',' + this.diccionarioPerdida.id);
        }
      }
    }
  }
 


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() { 
    if (!this.fromAllPerdidasSelect)
    {
    this.submitted = true;
    this.guardarDiccionarioPerdidas()
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.crearGrupo();
    } else {
      this.updateGrupo();
    }
    }
    else this.fromAllPerdidasSelect=false;

  }

 

  private crearGrupo() {
    this.diccionarioPerdidasService.createGrupoDiccionarioPerdidas(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../'], { relativeTo: this.route });

        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateGrupo() {
    this.diccionarioPerdidasService.updateGrupoDiccionarioPerdidas(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['../../'], { relativeTo: this.route });
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  public rowCallback(context: RowClassArgs) {
    return {
      dragging: context.dataItem.dragging
    };
  }

  private handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  } 


  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  private checkAllPerdidas() {
    if (this.gridData.total<=this.mySelection2Aux.length && this.gridData.total<=this.mySelection2.length)
    {
      this.mySelection2Aux=[];
      this.buttonAllPerdidasSelectName=this.translate.instant('seleccionarTodo');
    }
    else
    {
      this.gridData.data.forEach(f => {
        //f.seleccionado = true;
        this.mySelection2Aux.push(f.id);
      });
      this.buttonAllPerdidasSelectName=this.translate.instant('deseleccionarTodo');
    }
    this.fromAllPerdidasSelect=true;
    //this.mySelectionAux=this.mySelection;
    this.mySelection2 = Object.assign([], this.mySelection2Aux);
}

  public cellClickHandler(e: CellClickEvent): void 
{
      if (this.gridData.length==this.mySelection2.length) this.gridData.forEach(f => {this.mySelection2Aux.push(f.id);});
      if (this.mySelection2.length==0) this.mySelection2Aux=[];

      if (this.mySelection2Aux.includes(e.dataItem.id)) 
      {
        //remove id from myselection
        const index = this.mySelection2Aux.indexOf(e.dataItem.id, 0);
        if (index > -1) {
          this.mySelection2Aux.splice(index, 1);
        }
        this.buttonAllPerdidasSelectName=this.translate.instant('seleccionarTodo');
      }
      else 
      {
        this.mySelection2Aux.push(e.dataItem.id)
      }
      this.mySelection2 = Object.assign([], this.mySelection2Aux);
}

public clickCheckBox(dataItem): void
{
  // if (this.mySelection2.length<this.gridData.data.length)      this.buttonAllPerdidasSelectName=this.translate.instant('seleccionarTodo');
  // else    this.buttonAllPerdidasSelectName=this.translate.instant('deseleccionarTodo');
}


}
