<div class="card">
    <div class="card-header">
        <h3>{{ 'filtro' | translate}}</h3>
        <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
        <div class="clearfix">

            <!--LISTA Tipos activo-->
            <div class="float-left mr-2">
                <div class="form-group">
                    <label>{{ 'tipoActivo' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaTiposActivo"
                            [(ngModel)]="tiposActivosSeleccionados" [textField]="'nombre'" [valueField]="'id'"
                            placeholder="{{ 'seleccioneTipoActivo' | translate }}" [autoClose]="false"
                            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'tipoActivosSeleccionados' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--LISTA Jerarquía activos-->
            <div class="float-left mr-2">
                <div class="form-group">
                    <label>{{ 'jerarquiaActivos' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaJerarquiaActivo"
                            [(ngModel)]="jerarquiaActivosSeleccionados" [textField]="'tipo'" [valueField]="'id'"
                            placeholder="{{ 'seleccioneJerarquía' | translate }}" [autoClose]="false"
                            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].tipo}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'jerarquiasSeleccionadas' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--LISTA referencias-->
            <div class="float-left mr-2">
                <div class="form-group">
                    <label>{{ 'referencia' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaReferencias"
                            [(ngModel)]="referenciasSeleccionadas" [textField]="'referencia'" [valueField]="'id'"
                            placeholder="{{ 'seleccioneReferencia' | translate }}" [autoClose]="false"
                            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].referencia}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'referenciasSeleccionadas' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>

            <!--LISTA código barras-->
            <div class="float-left mr-2">
                <div class="form-group">
                    <label>{{ 'EAN' | translate }}</label>
                    <div class="caja">
                        <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaCodigosBarras"
                            [(ngModel)]="codigosBarrasSeleccionados" [textField]="'codigoBarras'" [valueField]="'id'"
                            placeholder="{{ 'seleccioneCodigosBarras' | translate }}" [autoClose]="false"
                            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].codigoBarras}}
                                </ng-container>
                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                    'codigosBarrasSeleccionados' | translate }}</ng-container>
                            </ng-template>
                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                            </kendo-multiselect-messages>
                        </kendo-multiselect>
                    </div>
                </div>
            </div>



            <!--Stock-->
            <div class="float-left mr-2">
                <div class="form-group">
                    <label>{{ 'stock' | translate }}</label>
                    <div class="caja" style="margin: 0 0 0 7px;">
                        <kendo-switch [(ngModel)]="stock" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </div>
                </div>
            </div>
            <div class="caja">
                <button type="button" [disabled]="!hayDatosFiltro" class="btn btn-danger mr-1 float-left nolabel" (click)="btnLimpiarFiltro()">{{ 'limpiarFiltro' | translate
                }}</button>
                <button type="button" [disabled]="!hayDatosFiltro" class="btn btn-primary float-left nolabel" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <kendo-treelist [kendoTreeListFlatBinding]="data" kendoTreeListExpandable idField="id"
            [initiallyExpanded]="true" parentIdField="idPadre">
            <kendo-treelist-column [expandable]="true" field="nombre" title="{{ 'activo' | translate}}" width="45%">
                <ng-template kendoTreeListCellTemplate let-dataItem>
                    <span>
                        <i *ngIf="dataItem.tipoActivo == 'Activo'" class="fa fa-shapes mr-2 activo-ico"
                            aria-hidden="true"></i>
                        <i *ngIf="dataItem.tipoActivo == 'Parte'" class="fa fa-circle mr-2 parte-ico"
                            aria-hidden="true"></i>
                        <i *ngIf="dataItem.tipoActivo == 'Pieza'" class="fa fa-square mr-2 pieza-ico"
                            aria-hidden="true"></i>
                        <i *ngIf="dataItem.tipoActivo == 'Repuesto'" class="fa fa-play mr-2 recambio-ico fa-rotate-270"
                            aria-hidden="true"></i>
                        {{dataItem.nombre}}
                    </span>
                </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column field="tipoActivo" title="{{ 'tipoActivo' | translate }}" width="45%">
                <ng-template kendoTreeListCellTemplate let-dataItem>
                    <span ngClass="{{dataItem.id == idActivoMaestro ? 
                        'arbol-activo': ''}}">
                        {{dataItem.tipoActivo}}
                    </span>
                </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column field="stock" title="{{ 'stock' | translate}}" width="10%">
                <ng-template kendoTreeListCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.stock" disabled />
                </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-messages loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
            </kendo-treelist-messages>
        </kendo-treelist>
    </div>
</div>