import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FabricantesService, MenuService, UsuariosService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { MyFunctions } from '@app/_helpers';

import { HerramientasService } from '@app/_services/herramientas.service';


@Component({
  selector: 'app-tiposHerramientas-detalle',
  templateUrl: './tiposHerramientasDetalle.component.html'
})
export class TiposHerramientasDetalleComponent {

  private translate: TranslateService;
  public events: string[] = [];
  public id: number;
  public requerido: boolean;

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  constructor(
    private formBuilder: FormBuilder,
    private herramientasService: HerramientasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    translate: TranslateService) {

    this.translate = translate;

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.menuService.titulo = this.translate.instant('tiposHerramientas');
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required]
    });

    if (this.id > 0) {
      this.herramientasService.get_tiposHerramientas_byID(this.id).pipe().subscribe(
        (result) => {
          var an: any = result.data[0];
          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            nombre: [an.nombre, Validators.required]
          });
        })
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private async insert() {

    this.herramientasService.insert_tiposHerramientas(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.router.navigate(['tiposHerramientas']);
      }
    });

  }

  private async update() {
    this.herramientasService.update_tiposHerramientas(this.id, this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.router.navigate(['tiposHerramientas']);
      } else {
      }
    });

  }

  public atras() {
    this.router.navigate(['tiposHerramientas']);
  }
}
