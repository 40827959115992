import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';
import { LocalizedNumericTextBoxMessagesDirective } from '@progress/kendo-angular-inputs';

const baseUrl = `${environment.apiUrl}/planificador`;

@Injectable()
export class PlanificadorService {

  constructor(
    private http: HttpClient
  ) {
  }

  GetMaquinasYSubcontratasPlanificador() {
    return this.http.post<JSON>(`${baseUrl}/GetMaquinasYSubcontratasPlanificador`, {}, { withCredentials: true });
  }
  GetMaquinasYSubcontratasPlanificadorTodas() {
    return this.http.post<JSON>(`${baseUrl}/GetMaquinasYSubcontratasPlanificadorTodas`, {}, { withCredentials: true });
  }
  getGrupoMaquinas_MaquinasYSubcontratasPlanificadorById(idGrupo: number) {
    return this.http.post<JSON>(`${baseUrl}/getGrupoMaquinas_MaquinasYSubcontratasPlanificadorById`, { idGrupo }, { withCredentials: true });
  }


  //SELECT
  LargoMaquinaGet(idMaquina: number, version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean, tipo: number) {
    return this.http.post<JSON>(`${baseUrl}/LargoMaquinaGet`, { idMaquina, version, aplicarTiempoEstimado, aplicarIneficiencias, tipo}, { withCredentials: true });
  }

  GruposMaquinasGet() {
    return this.http.post<JSON>(`${baseUrl}/GruposMaquinasGet`, {}, { withCredentials: true });
  }

  //GET planificador o simulaciones activas
  getPlanificadoresActivos(tReal: string) {
    return this.http.post<JSON>(`${baseUrl}/GetPlanificadoresActivos`, { tReal }, { withCredentials: true });
  }

  //Output: Devuelve todas las operaciones planificadas segun la maquina, semana, operacion
  GetOperacionesPlanificadas(version: number) {
    return this.http.post<JSON>(`${baseUrl}/GetOperacionesPlanificadas`, { version }, { withCredentials: true });
  }

  GetBySinPlanificar_piezas(version: number) {
    return this.http.post<JSON>(`${baseUrl}/GetBySinPlanificar_piezas`, { version }, { withCredentials: true });
  }

  GetBySinPlanificar_operaciones(version: number) {
    return this.http.post<JSON>(`${baseUrl}/GetBySinPlanificar_operaciones`, { version }, { withCredentials: true });
  }

  GetFechaSemanasLimite() {
    return this.http.post<JSON>(`${baseUrl}/GetFechaSemanasLimite`, {}, { withCredentials: true });
  }

  UpdateFijadoOperacionesTodas(version: number, idMaquina: number, idOperaciones: number[], fijado: boolean) {
    return this.http.post<JSON>(`${baseUrl}/UpdateFijadoOperacionesTodas`, { version, idMaquina, idOperaciones, fijado }, { withCredentials: true }).subscribe();
  }

  UpdateFijadoOperaciones(version: number, idMaquina: number, fecha: Date, idOperaciones: number[], fijado: boolean) {
    return this.http.post<JSON>(`${baseUrl}/UpdateFijadoOperaciones`, { version, idMaquina, fecha, idOperaciones, fijado }, { withCredentials: true }).subscribe();
  }

  UpdateCantidades(req: any) {//vdersion
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/UpdateCantidades`, { json }, { withCredentials: true });
  }

  InsertOperacionesPlanificadas(req: any) {//vdersion
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/InsertOperacionesPlanificadas`, { json }, { withCredentials: true });
  }

  InsertOperacionesPlanificadasACorto(req: any) {//vdersion
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/InsertOperacionesPlanificadasACorto`, { json }, { withCredentials: true });
  }

  GetCorto(version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean) {
    return this.http.post<JSON>(`${baseUrl}/GetCorto`, { version, aplicarTiempoEstimado, aplicarIneficiencias }, { withCredentials: true });
  }

  GetCorto_temporal(version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean) {
    return this.http.post<JSON>(`${baseUrl}/GetCorto_temporal`, { version, aplicarTiempoEstimado, aplicarIneficiencias }, { withCredentials: true });
  }

  GetPlanificador_herramientas(version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean) {
    return this.http.post<JSON>(`${baseUrl}/GetPlanificador_herramientas`, { version, aplicarTiempoEstimado, aplicarIneficiencias }, { withCredentials: true });
  }

  GetCorto_temporal_hechas() {
    return this.http.post<JSON>(`${baseUrl}/GetCorto_temporal_hechas`, {}, { withCredentials: true });
  }

  Get_turnos(now) {
    return this.http.post<JSON>(`${baseUrl}/Get_turnos`, { now }, { withCredentials: true });
  }

  Update_corto(dt: JSON) {//version
    return this.http.post<JSON>(`${baseUrl}/Update_corto`, { dt }, { withCredentials: true });
  }

  GetPlanificadoSinCorto(version: number) {
    return this.http.post<JSON>(`${baseUrl}/GetPlanificadoSinCorto`, { version }, { withCredentials: true });
  }

  Reorganizar_Skootik(tipo: number, version: number, tiempo: number,
    prioridadCliente: number, prioridadFacturacion: number, prioridadOF: number, prioridadMaquina: number,
    dias_reserva: number, percent_cap_maquina: number, prioridadFecha: number, prioridadTemperatura: number,metodologiaPlanificacion: number,
    optimizarCuelloBotella: boolean,
    idMaquina: number, predictivo: boolean, ineficiencia: boolean,
    planificarSoloNoPlanificados: boolean, 
    asap_maximoMesesParaIniciarAntesDeFechaEntrega: number) {
      var a = { 
        tipo, version, tiempo, prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina, dias_reserva, 
        percent_cap_maquina, prioridadFecha, prioridadTemperatura, metodologiaPlanificacion, optimizarCuelloBotella, 
        idMaquina, predictivo, ineficiencia, planificarSoloNoPlanificados, asap_maximoMesesParaIniciarAntesDeFechaEntrega };
    return this.http.post<JSON>(`${baseUrl}/Reorganizar_Skootik`, { 
      tipo, version, tiempo, prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina, dias_reserva, 
      percent_cap_maquina, prioridadFecha, prioridadTemperatura, metodologiaPlanificacion, optimizarCuelloBotella, 
      idMaquina, predictivo, ineficiencia, planificarSoloNoPlanificados, asap_maximoMesesParaIniciarAntesDeFechaEntrega }, { withCredentials: true });
  }

  Reorganizar_GruposTemporal_Alex() {
    return this.http.post<JSON>(`${baseUrl}/Reorganizar_GruposTemporal_Alex`, { withCredentials: true });
  }

  Reorganizar_Planificador(tipo: number, version: string, prioridadMaquina: boolean, metodologiaPlanificacion: number, capacidadMaquinas: number, prioridadCliente
    , prioridadFacturacion, prioridadOF, prioridadFecha, prioridadTemperatura, replanificarTiempoEstimado, dias_reserva, optimizarCuelloBotella) {
    return this.http.post<JSON>(`${baseUrl}/Reorganizar_Planificador`, { tipo, version, prioridadMaquina, metodologiaPlanificacion, capacidadMaquinas, prioridadCliente
      , prioridadFacturacion, prioridadOF, prioridadFecha, prioridadTemperatura, replanificarTiempoEstimado, dias_reserva, optimizarCuelloBotella }, { withCredentials: true });
  }

  copiarAVersion(versionDe: number, versionA: number, tipo: number) {
    return this.http.post<JSON>(`${baseUrl}/copiarAVersion`, { tipo, versionDe, versionA }, { withCredentials: true });
  }

  borrarVersion(version: number, tipo: number) {
    return this.http.post<JSON>(`${baseUrl}/BorrarVersion`, { version, tipo }, { withCredentials: true });
  }

  CalificarVersion(version: number, aplicarTiempoEstimado: boolean) {
    return this.http.post<JSON>(`${baseUrl}/CalificarVersion`, { version, aplicarTiempoEstimado }, { withCredentials: true });
  }

  CalificarVersionConfiguracion() {
    return this.http.post<JSON>(`${baseUrl}/CalificarVersionConfiguracion`, {}, { withCredentials: true });
  }

  GetTodo(version: number, mostrarCorto: boolean, mostrarLargo: boolean, idMaquinas) {
    var tipo = 1;
    if (mostrarCorto && mostrarLargo)
      tipo = 3
    else
      if (mostrarLargo)
        tipo = 2
    // 1 = corto 
    // 2 = largo
    // 3 = TODO
    return this.http.post<JSON>(`${baseUrl}/GetTodo`, { version, tipo, idMaquinas }, { withCredentials: true });
  }

  public GetfPieszasOfs(terminado: boolean): Observable<any> {

    var data = { "terminado": terminado };
    return this.http.post<any>(baseUrl + "/ofspiezasinicio", data, { withCredentials: true });
  }

  Get_filtro_Ofs(version: number, tipo: number, idMaquina: number) {
    // 1 = corto
    // 2 = largo
    return this.http.post<JSON>(`${baseUrl}/Get_filtro_Ofs`, { version, tipo, idMaquina }, { withCredentials: true });
  }

  Get_tiempos_planificador_corto(idMaquina: number, version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean) {
    // 1 = corto
    // 2 = largo
    return this.http.post<JSON>(`${baseUrl}/Get_tiempos_planificador_corto`, { idMaquina, version, aplicarTiempoEstimado, aplicarIneficiencias }, { withCredentials: true });
  }

  Get_ultima_reorganizacion(tipo: number, version: number) {
    // 1 = corto
    // 2 = largo
    return this.http.post<JSON>(`${baseUrl}/Get_ultima_reorganizacion`, { tipo, version }, { withCredentials: true });
  }

  Get_tiempos_ejecutados(version: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_tiempos_ejecutados`, { version }, { withCredentials: true });
  }
  
  Get_cantidadesOperacionesActual(version: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_cantidadesOperacionesActual`, { version }, { withCredentials: true });
  }

  ReplanificarTodoUltimaSemana(version: number) {
    return this.http.post<JSON>(`${baseUrl}/ReplanificarTodoUltimaSemana`, { version }, { withCredentials: true });
  }

  AtrasarUnaSemana(version: number, fijadosTambien: boolean, idMaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/AtrasarUnaSemana`, { version, fijadosTambien, idMaquina }, { withCredentials: true });
  }

  delete_corto(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/delete_corto`, { json }, { withCredentials: true });
  }

  mover_operacionesRelacionadas(cantSemanas: number, req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/mover_operacionesRelacionadas`, { cantSemanas, json }, { withCredentials: true });
  }

  getConfPlanificadorHerramientas() {
    return this.http.post<JSON>(`${baseUrl}/getConfPlanificadorHerramientas`, { }, { withCredentials: true });
  }

  obtenerDireccionRuta(idRuta: number){
    return this.http.post<any>(baseUrl + "/obtenerDireccionRuta", { idRuta } , { withCredentials: true });
  }

  bloqueos_por_mantenimientos(idMaquina: number){
    return this.http.post<JSON>(baseUrl + "/bloqueos_por_mantenimientos", { idMaquina } , { withCredentials: true });
  }

  get_largoMaquina(idMaquina: number, version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean, tipo: number) {
    return this.http.post<JSON>(`${baseUrl}/get_largoMaquina`, { idMaquina, version, aplicarTiempoEstimado, aplicarIneficiencias, tipo}, { withCredentials: true });
  }
  get_cortoMaquina(idMaquina: number, version: number, aplicarTiempoEstimado: boolean, aplicarIneficiencias: boolean) {
    return this.http.post<JSON>(`${baseUrl}/get_cortoMaquina`, { idMaquina, version, aplicarTiempoEstimado, aplicarIneficiencias}, { withCredentials: true });
  }
  get_turnos_en_fechas(idMaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/get_turnos_en_fechas`, { idMaquina }, { withCredentials: true });
  }
  get_grupos(version: number) {
    return this.http.post<JSON>(`${baseUrl}/get_grupos`, { version }, { withCredentials: true });
  }
  get_maquinas_tiemposEstimados(version: number) {
    return this.http.post<JSON>(`${baseUrl}/get_maquinas_tiemposEstimados`, { version }, { withCredentials: true });
  }

  get_PlanificadorLargo(version: number) {

    return this.http.post<JSON>(`${baseUrl}/get_PlanificadorLargo`, { version }, { withCredentials: true });

  }

  get_PlanificadorCorto(version: number) {

    return this.http.post<JSON>(`${baseUrl}/get_PlanificadorCorto`, { version }, { withCredentials: true });

  }


  get_maquinas_temperaturas_rangos(){
    
    return this.http.post<JSON>(`${baseUrl}/get_maquinas_temperaturas_rangos`, { }, { withCredentials: true });

  }

  get_maquinas_encadenadas(){    
    return this.http.post<JSON>(`${baseUrl}/get_maquinas_encadenadas`, { }, { withCredentials: true });
  }

  get_operacionesSimultaneas(version: number, idsOperaciones: number[]){    
    return this.http.post<JSON>(`${baseUrl}/get_operacionesSimultaneas`, { version, idsOperaciones }, { withCredentials: true });
  }
}
