<!--FILTRO-->
<div class="cont-filtro-maquinas-areas clearfix">
  <!-- FILTRO GRUPOS -->
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">
        <div class="row">
          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
      <div *ngFor="let maquina of maquinasMostradas" (click)="selectedMaquina = maquina.id; maquinasDesplegadas = ! maquinasDesplegadas; desplegarMaquinasClick(); cargarInforme();" class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>
  <!-- FILTRO TURNOS Y FECHAS -->
  <div class="card cont-filtro-informes">
    <div class="card-body">
      <!--Turnos-->
      <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'turno' | translate }}</label>
        <div class="caja" [formGroup]="form">
            <kendo-multiselect [(value)]="comboTurnosSeleccion" formControlName="idTurno" [placeholder]="turnos_placeHolder" [data]="turnosCombo" [textField]="'nombre'" [valueField]="'id'"
                               (valueChange)="turnos_onValueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"></kendo-multiselect>
          </div>
        </div>
      <!--Fechas-->
      <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'fechas' | translate }}</label>
        <div class="caja">
          <kendo-datepicker class="filtro-informes-data-1" [(value)]="fechaInicio" (valueChange)="fechaInicio_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
          <kendo-datepicker class="filtro-informes-data-2" [readonly]="true" [(value)]="fechaFin" (valueChange)="fechaFin_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
</div>

<!--GRAFICOS-->
<!--LINEA 1-->
<div class="clearfix">
  <div class="card">
    <div id="chartGanttTimeline" style="min-height:100px;"></div>
    <div *ngIf="loadingGantt" class="k-i-loading"></div>
  </div>
</div>

<!--LINEA 2.1-->
<div class="row">
  <!--Resumen Semana-->
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label ID="lResumenSemana" CssClass="">{{ 'resumenSemana' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="chartDonutResumenSemana"></div>
      </div>
      <div *ngIf="loadingResumenSemana" class="k-i-loading"></div>
    </div>
  </div>
  <!--Horas Dia-->
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label ID="lHorasDia" CssClass="">{{ 'horasDia' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="chartAreaHorasDia"></div>
      </div>
      <div *ngIf="loadingHorasDia" class="k-i-loading"></div>
    </div>
  </div>
</div>
<!--LINEA 2.2-->
<div class="row" [ngClass]="{'d-none': !this.tieneSubEstados_personalizados}">
  <!--Resumen Semana-->
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label ID="lResumenSemana_subEstados" CssClass="">{{ 'resumenSemana' | translate }} ({{ 'subestados' | translate }})</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="chartDonutResumenSemana_subEstados"></div>
      </div>
      <div *ngIf="loadingResumenSemana_subEstados" class="k-i-loading"></div>
    </div>
  </div>
  <!--Horas Dia-->
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label ID="lHorasDia_subEstados" CssClass="">{{ 'horasDia' | translate }} ({{ 'subestados' | translate }})</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="chartAreaHorasDia_subEstados"></div>
      </div>
      <div *ngIf="loadingHorasDia_subEstados" class="k-i-loading"></div>
    </div>
  </div>
</div>

<!--LINEA 3-->
<div class="row">
  <!--OEE-->
  <div class="col-md-4 col-lg-3 col-xl-3">
    <div class="card">
      <div class="card-header">
        <h3>
          <label ID="lOEE" CssClass="">{{ 'oee' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="row">
          <!--OEE-->
          <div class="col-lg-6 col-xxl-12" style="padding: 0 5px">
            <div id="chartDonutOEE_InformeRendimiento" style="height: 140px"></div>
            <p class="oee-donut-label">{{ 'oee' | translate }}</p>
          </div>
          <!--Disponibilidad-->
          <div class="col-lg-6 col-xxl-4" style="padding: 0 5px">
            <div id="chartDonutOEEDisponibilidad_InformeRendimiento" style="height: 140px"></div>
            <p class="oee-donut-label">{{ 'disponibilidad' | translate }}</p>
          </div>
          <!--Rendimiento-->
          <div class="col-lg-6 col-xxl-4" style="padding: 0 5px">
            <div id="chartDonutOEERendimiento_InformeRendimiento" style="height: 140px"></div>
            <p class="oee-donut-label">{{ 'rendimiento' | translate }}</p>
          </div>
          <!--Calidad-->
          <div class="col-lg-6 col-xxl-4" style="padding: 0 5px">
            <div id="chartDonutOEECalidad_InformeRendimiento" style="height: 140px"></div>
            <p class="oee-donut-label">{{ 'calidad' | translate }}</p>
          </div>
        </div>
      </div>
      <div *ngIf="loadingOeeHoras" class="k-i-loading"></div>
    </div>
  </div>
  <div class="col-md-8 col-lg-9 col-xl-9">
    <!--Horas-->
    <div class="card">
      <div class="card-header">
        <h3>
          <label ID="lHoras" CssClass="">{{ 'horas' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="chartBarHoras_InformeRendimiento"></div>
      </div>
      <div *ngIf="loadingOeeHoras" class="k-i-loading"></div>
    </div>
  </div>
</div>

<!--LINEA 4-->
<div class="row">
  <!--Paradas-->
  <div class="col-md-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'perdidas' | translate }}</label>
        </h3>
        <!-- AGRUPAR POR: -->
        <div id="checkboxGrupos" class="position-absolute clearfix" style="top: 8px; right: 45px;">
          <!-- Grupo -->
          <div class="float-left mr-2">
            <input type="radio" name="groupData" class="k-radio" (change)="checkboxChange(2)" [checked]="agrupado == 2" kendoRadioButton>
            <label for="groupData"> {{ 'grupos' | translate }}</label><br>
          </div>
          <!-- Perdida -->
          <div class="float-left mr-2">
            <input type="radio" name="groupData" class="k-radio" (change)="checkboxChange(1)" [checked]="agrupado == 1" kendoRadioButton>
            <label for="groupData"> {{ 'perdidas' | translate }}</label><br>
          </div>
          <!-- Sub-perdida -->
          <div class="float-left mr-2">
            <input type="radio" name="groupData" class="k-radio" (change)="checkboxChange(0)" [checked]="agrupado == 0" kendoRadioButton>
            <label for="groupData"> {{ 'subperdidas' | translate }}</label><br>
          </div>
        </div>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="row" id="chartDonutParadas" style="height: 300px;">
        </div>
        <div class="row" id="lblNoParadas_InfRend" style="height: 300px; position: relative; display:none;">
          <a style="position: absolute; top: 45%; width: 100%; text-align: center;">{{ 'noDatosParadas' | translate}}</a>
        </div>
      </div>
      <div *ngIf="loadingPerdidas" class="k-i-loading"></div>
    </div>
  </div>
  <!--Mantenimientos-->
  <div class="col-md-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'mantenimientos' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="row" id="chartDonutMantenimientos" style="height: 300px;">
        </div>
        <div class="row" id="lblNoMantenimientos_InfRend" style="height: 300px; position: relative; display:none;">
          <a style="position: absolute; top: 45%; width: 100%; text-align: center;">{{ 'noDatosMantenimientos' | translate}}</a>
        </div>
      </div>
      <div *ngIf="loadingPerdidas" class="k-i-loading"></div>
    </div>
  </div>
  <!--Alarmas-->
  <div class="col-md-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'alarmas' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="row" id="chartDonutAlarmas" style="height: 300px;">
        </div>
        <div class="row" id="lblNoAlarmas_InfRend" style="height: 300px; position: relative; display:none;">
          <a style="position: absolute; top: 45%; width: 100%; text-align: center;">{{ 'noDatosAlarmas' | translate}}</a>
        </div>
      </div>
      <div *ngIf="loadingPerdidas" class="k-i-loading"></div>
    </div>
  </div>
</div>
