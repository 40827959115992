import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";
import { MenuService, ContenedoresService } from '@app/_services';

@Component({
    selector: 'app-informeContenedores',
    templateUrl: 'informeContenedores.component.html'
  })

export class InformeContenedoresComponent {

    public translate;

    public informeContenedores: any = [];
    public seleccionados = [];

    public loadingPanel = false;

    constructor(
        translate: TranslateService,
        private menuService: MenuService,
        private contenedoresService: ContenedoresService,
        public router: Router) { 
            
        this.translate = translate;
        this.menuService.titulo = this.translate.instant('informeContenedores').toUpperCase();
    }

    ngOnInit() {
        this.cargarDatos();
    }

    cargarDatos() {
        this.loadingPanel = true;
        this.contenedoresService.GetContenedorById(-1).subscribe((result: any) => {
            var contenedores = result;
            contenedores.forEach(element => {
                if (element.maquinasOrigen != null)
                    element.maquinasOrigenArray = element.maquinasOrigen?.split(',').map(function(x) { return parseInt(x) });
                else
                    element.maquinasOrigenArray = []
                if (element.maquinasDestino != null)
                    element.maquinasDestinoArray = element.maquinasDestino?.split(',').map(function(x) { return parseInt(x) });
                else
                    element.maquinasDestinoArray = []
                element.cantidad = 0;
            });
            this.contenedoresService.GetInformeContenedoresAll().subscribe((json: any) => { 
                // table => cantidad de las operaciones sin terminar
                var maquinas = json.table
                // table1 => informacion de las operaciones terminadas
                var inf = json.table1

                inf.forEach(element => {
                    contenedores.forEach(cont => {
                        // tiene maquina origen y proxima
                        if (cont.maquinasOrigenArray.includes(element.idMaquina_anterior) && cont.maquinasDestinoArray.includes(element.idMaquina_proxima))
                            cont.cantidad += element.stock
                        // solo tiene maquina origen
                        else if (cont.maquinasOrigenArray.includes(element.idMaquina_anterior))
                            cont.cantidad += element.stock
                        // solo tiene maquina destino
                        else if (cont.maquinasDestinoArray.includes(element.idMaquina_proxima))
                            cont.cantidad += element.stock
                    });
                });

                this.informeContenedores = contenedores;

                this.informeContenedores.push({
                    id: 0
                    , nombre: this.translate.instant("maquinas")
                    , cantidad: maquinas[0].stock
                })

                this.loadingPanel = false;
            });
        });
    }

    cellClick(event) {
        var id = this.seleccionados[0];
        this.router.navigate(['informeContenedores/' + id]);


    }

}