<!-- Botones para editar un grupo/crear un nuevo grupo/ eliminar un nuevo grupo y tabla con grupos existentes -->
<ul class="list-group list-group-flush" *ngIf="router.url === '/perdidasGrupos'">
    <kendo-grid [kendoGridBinding]="dataGruposDiccionarioPerdidas" 
                [sortable]="true" 
                kendoGridSelectBy="id" 
                [navigable]="true"
                filterable="menu" 
                (cellClick)="cellClick($event)" 
                [selectedKeys]="mySelection">
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickEditar()"
                class="btn mr-1  btn-success btn-sm mr-1">
          {{ 'editar' | translate}}
        </button>
        <button kendoButton (click)="onClickNuevo()"
                class="btn mr-1  btn-primary btn-sm mr-1">
          {{ 'nuevo' | translate}}
        </button>
        <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1"
                [disabled]="isDeleting || this.user.diccionarioPerdidas<2">
          {{ 'eliminar' | translate}}
        </button>
      </ng-template>
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column width="80%" field="nombre" title="{{ 'nombre' | translate}}">
      </kendo-grid-column>
      <kendo-grid-column field="activo" title="{{ 'activo' | translate}}" width="10%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.activo" disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </ul>
  
  
  <!-- POPUP: Borrar grupo -->
  <ng-template #popupBorrar let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()"  W >{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <router-outlet></router-outlet>