<style>
  .originalClass {
    font-weight: bold;
    color: #f00;
  }
</style>

<!-- FILTRO -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
  <div class="card-header">
    <h3>{{ 'filtro' | translate}}</h3>
    <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i class="fas fa-times"></i></button>
  </div>
  <div class="filtro-desplegable card-body flt">
    <!-- Filtro: CON GRUPOS -->
    <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
    <ng-template #recursiveListTmpl let-filtross="filtros">
      <div *ngFor="let filtro of filtross" class="mt-2">
        <!-- GRUPO -->
        <div *ngFor="let row of filtro.group" class="flt-grupo">
          <!-- LINEA FILTRO -->
          <div *ngIf="row.group == undefined" class="clearfix flt-linea">
            <!-- PERIODO -->
            <div class="flt-filtro-linea">
              <!-- Filtro: FECHA -->
              <div *ngIf="row.columna.tipo == 'date'" class="flt-cont-tipo">
                <label class="tags-filtro-label"> {{ 'periodo' | translate}} </label> <br>
                <!-- DATO(s) -->
                <div *ngIf="row.operator.id > 0" class="flt-datos">
                  <!-- DATO 1 (tenemos un control especial para las fechas dobles) -->
                  <kendo-datepicker *ngIf="!row.operator.dobleValor" class="flt-dato1" [(value)]="row.fechaIni"></kendo-datepicker>
                  <!-- DATO 1 / 2 -->
                  <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                    <div class="calendarFiltro">
                      <!-- CONTROL -->
                      <div class="calendarFiltro-input" (click)="showCalendar(row)">
                        <label *ngIf="row.fechaIni != undefined && row.fechaFin == undefined">{{row.fechaIni | kendoDate}}</label>
                        <label *ngIf="row.fechaFin != undefined && row.fechaFin != undefined">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                        <i class="k-icon k-i-calendar"></i>
                      </div>
                      <!-- CONTROL EXPANDIDO -->
                      <div class="calendarFiltro-calendario" style="position: relative !important; width: 367px;" [ngClass]="row.mostrarCalendario ? 'mostrarCalendario' : 'ocultarCalendario'">
                        <div class="row">
                          <div class="col-mes">
                            <kendo-calendar id="calendario-0" [(value)]="DaysInMonths[0]" type="classic" (click)="valueClickCalendar(row, 0, $event)">
                              <ng-template kendoCalendarMonthCellTemplate let-date>
                                <span class="calendarFiltro-calendario-contenido-dia" [class.calendarFiltro-calendarioSeleccionado]="isDateSelected(row, date)"> {{ date.getDate() }} </span>
                              </ng-template>
                              <ng-template kendoCalendarHeaderTitleTemplate let-title>
                                <div> {{ title }} </div>
                              </ng-template>
                            </kendo-calendar>
                          </div>
                          <div class="col-mes-botones">
                            <div class="calendarFiltro-botones">
                              <div class="clearfix text-center">
                                <!-- style="margin-left: -100px; margin-top: 5px; position: absolute;" -->
                                <button (click)="cambiarMeses(-1)"><i class="fa fa-chevron-left"></i></button>
                                <button (click)="cambiarMeses(1)"><i class="fa fa-chevron-right"></i></button>
                                <button (click)="cargarMeses()">{{'hoy' | translate}}</button>
                              </div>
                              <div>
                                <div class="clearfix"><button (click)="ultimas24HButton(row)">{{'ultimas24H' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos7DiasButton(row)">{{'ultimos7Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos30DiasButton(row)">{{'ultimos30Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos60DiasButton(row)">{{'ultimos60Dias' | translate}}</button></div>
                                <div class="clearfix"><button (click)="ultimos90DiasButton(row)">{{'ultimos90Dias' | translate}}</button></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <!-- OF -->
            <div class="flt-filtro-linea">
              <div *ngIf="row.columna.tipo == 'of'" class="flt-cont-tipo">
                <label class="tags-filtro-label">{{ 'of' | translate }}</label>
                <div class="flt-of">
                  <kendo-dropdownlist [data]="opcionOf" [textField]="'nombre'" [valueField]="'id'" [(value)]="row.of"></kendo-dropdownlist>
                </div>
              </div>
            </div>
            <!-- RANGO DE CICLOS -->
            <div class="">
              <div *ngIf="row.columna.tipo == 'rangoCiclos'" class="flt-cont-tipo">
                <label class="tags-filtro-label">{{ 'rangoCiclos' | translate }}</label> <br>
                <div>
                  <kendo-rangeslider
                    [(ngModel)]="value"
                    [min]="0"
                    [max]="1000"
                    [largeStep]="1"
                    [smallStep]="50"
                    class="w-100"
                  ></kendo-rangeslider>
                </div> <br>
                <div class="float-left mr-2">
                  <kendo-textbox [(ngModel)]="value[0]" (change)="updateRangoCiclos(value)" [maxlength]="4"></kendo-textbox>
                </div>
                <div class="float-left">
                  <kendo-textbox [(ngModel)]="value[1]" (change)="updateRangoCiclos(value)" [maxlength]="4"></kendo-textbox>
                </div>
              </div>
            </div>
            <!-- SOLO CICLOS FUERA DE PARAMETRO -->
            <div class="flt-filtro-linea">
              <div *ngIf="row.columna.tipo == 'fueraParametro'" class="flt-cont-tipo">
                <label class="tags-filtro-label">{{ 'soloFueraParametro' | translate }}</label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="row.fueraParametro" [onLabel]="' '" [offLabel]="' '" [(value)]="row.fueraParametro"></kendo-switch>
                </div>
              </div>
            </div>
            <!-- SOLO CICLOS CON EDICION DE RECETA -->
            <div class="flt-filtro-linea">
              <div *ngIf="row.columna.tipo == 'edicionReceta'" class="flt-cont-tipo">
                <label class="tags-filtro-label">{{ 'soloConEdicionReceta' | translate }}</label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="row.conEdicionReceta" [onLabel]="' '" [offLabel]="' '" [(value)]="row.conEdicionReceta"></kendo-switch>
                </div>
              </div>
            </div>
            <!-- SOLO CICLOS CON FALLO DE CALIDAD -->
            <div class="flt-filtro-linea">
              <div *ngIf="row.columna.tipo == 'falloCalidad'" class="flt-cont-tipo">
                <label class="tags-filtro-label">{{ 'soloConFalloCalidad' | translate }}</label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="row.conFalloCalidad" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </div>
              </div>
            </div>
            <!-- SOLO CICLOS CON CONTROL DE CALIDAD -->
            <div class="flt-filtro-linea">
              <div *ngIf="row.columna.tipo == 'controlCalidad'" class="flt-cont-tipo">
                <label class="tags-filtro-label">{{ 'soloConControlCalidad' | translate }}</label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="row.conControlCalidad" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                </div>
              </div>
            </div>
          </div>
          <!-- SUB GRUPO -->
          <div *ngIf="row.group != undefined">
            <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="clearfix">
      <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()"> {{ 'filtrar' | translate }}</button>
      <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton' | translate }}</button>
    </div>
  </div>
</div>
<!--   CARD CON OPCIONES SELECCIONADAS FILTRO -->
<div class="card">
  <div class="card-body">
    <div class="cont-tags-filtro" id="tagsFiltro">
      <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [datosFiltro] }"></ng-container>
      <ng-template #recursiveListTmpl2 let-filtross="filtros">
        <div *ngFor="let filtro of filtross" class="uno">
          <div class="flt-separador" *ngIf="filtro.group != undefined && filtro != datosFiltro && filtro!=datosFiltro.group[0] && filtroToCount(filtro) > 0"></div>
          <!-- GRUPO -->
          <div *ngFor="let row of filtro.group" class="dos">
            <!-- LINEA FILTRO -->
            <div *ngIf="row.group == undefined &&
            ((row.columna.tipo == 'date') || 
             (row.columna.tipo == 'of') ||  
             (row.columna.tipo == 'rangoCiclos') ||
             (row.columna.tipo == 'fueraParametro') ||    
             (row.columna.tipo == 'edicionReceta') ||  
             (row.columna.tipo == 'falloCalidad') ||  
             (row.columna.tipo == 'controlCalidad') ||  
            (row.columna.tipo == 'string' && row.text != '' ))" class="tags-filtro">
              <label *ngIf="false"> {{ row.columna.nombre }} </label>
              <label *ngIf="false"> {{ row.operator.nombre }} </label>
              <!-- -->
              <!-- FILTRO -->
              <div class="tags-filtro-label">
                <!-- OF -->
                <div *ngIf="row.columna.tipo == 'of'">
                  <label class="tags-filtro-label" *ngIf="row.of != -1">{{ 'of' | translate }}: {{row.of}}</label>
                </div>
                <!-- Filtro: FECHA -->
                <div *ngIf="row.columna.tipo == 'date'">
                  <div *ngIf="row.operator.id > 0">
                    <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                    <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin == undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}}</label>
                    <label class="tags-filtro-label" *ngIf="row.fechaFin != undefined && row.fechaFin != undefined && row.operator.dobleValor">{{row.fechaIni | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                  </div>
                </div>
                <!-- RANGO DE CICLOS -->
                <div *ngIf="row.columna.tipo == 'rangoCiclos'">
                  <label class="tags-filtro-label">{{ 'ciclo' | translate }}: {{value[0]}}-{{value[1]}}</label>
                </div>
                <!-- SOLO CICLOS FUERA DE PARAMETRO -->
                <div *ngIf="row.columna.tipo == 'fueraParametro'">
                  <label class="tags-filtro-label" *ngIf="row.fueraParametro">{{ 'soloFueraParametro' | translate }}</label>
                </div>
                <!-- SOLO CICLOS CON EDICION DE RECETA -->
                <div *ngIf="row.columna.tipo == 'edicionReceta'">
                  <label class="tags-filtro-label" *ngIf="row.conEdicionReceta">{{ 'soloConEdicionReceta' | translate }}</label>
                </div>
                <!-- SOLO CICLOS CON FALLO DE CALIDAD -->
                <div *ngIf="row.columna.tipo == 'falloCalidad'">
                  <label class="tags-filtro-label" *ngIf="row.conFalloCalidad">{{ 'soloConFalloCalidad' | translate }}</label>
                </div>
                <!-- SOLO CICLOS CON CONTROL DE CALIDAD -->
                <div *ngIf="row.columna.tipo == 'controlCalidad'">
                  <label class="tags-filtro-label" *ngIf="row.conControlCalidad">{{ 'soloConControlCalidad' | translate }}</label>
                </div>
              </div>
            </div>
            <!-- SUB GRUPO -->
            <div *ngIf="row.group != undefined">
              <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros:  [row] }"></ng-container>
            </div>
          </div>
        </div>
      </ng-template>
      <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }} ( {{ filtroToCount() }}
        )</button>
      <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button>
      <button *ngIf="actualizarVisible" Class="btn btn-success float-right mr-1" (click)="onFilter()"><i class="fas fa-sync-alt mr-1"></i>{{ 'actualizar' | translate }}</button>
    </div>

  </div>

  <div *ngIf="loadingFiltro" class="k-i-loading"></div>
</div>
<!-- END FILTRO -->

<div class="row">

  <!-- CICLOS -->
  <div class="col-md-2">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'ciclos' | translate}}</h3>
      </div>
      <div class="card-body">
        <!-- GRID -->
        <ng-template #template let-anchor>
          <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <div kendoTooltip
        showOn="none"
        [tooltipTemplate]="template"
        filter=".k-grid td"
        (mouseover)="showGridTooltip($event)">
        <kendo-grid [kendoGridBinding]="ciclos"
                [sortable]="true"
                [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: true, mode: 'multiple'}"
                [navigable]="true"
                [rowHeight]="36"
                [height]="750"
                [pageSize]="30"
                kendoGridSelectBy="idCiclo"
                scrollable="virtual"
                (change)="onChange()"
                [selectedKeys]="seleccionados">
        <!--checkbox-->
        <kendo-grid-checkbox-column width="11%">
        <!-- <ng-template kendoGridHeaderTemplate>
          <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
        </ng-template> -->
        </kendo-grid-checkbox-column>
        <!--nombre-->
        <kendo-grid-column width="50%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
        <!--original ==? , editado, calidad -->
        <kendo-grid-column width="35%" [style]="{'text-align': 'left'}" field="dif" title="">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="float-left celda-completa icon-engranaje ciclos-icono" *ngIf="dataItem.dif == true"></div>
            <div class="float-left celda-completa icon-editar ciclos-icono" *ngIf="dataItem.edited == true"></div>
            <div class="float-left celda-completa icon-check ciclos-icono" *ngIf="dataItem.calidad == true"></div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
        </div>        
      </div>
    </div>
  </div>

  <!-- EDITAR RECETA -->
  <div class="col-md-10">
    <div class="card">
      <div class="card-header">
          <h3>
            <span class="float-left">
              {{ 'editarReceta' | translate}}
            </span>
            <span class="float-right">
              <button type="button" class="btn btn-success" id="btn-mostrarTodos" (click)="mostrarTodos()"><i id="i-mostrarTodos" class=" icon-equis"></i> {{'mostrarTodos' | translate }}</button>
            </span>
          </h3>
      </div>
      <div class="card-body">
        <!-- GRID -->
        <ng-template #template let-anchor>
          <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <div kendoTooltip
        showOn="none"
        [tooltipTemplate]="template"
        filter=".k-grid td"
        (mouseover)="showGridTooltip($event)">
        <kendo-grid [kendoGridBinding]="recetaInf"
                [sortable]="true"
                [navigable]="true"
                [rowHeight]="36"
                [height]="750"
                [pageSize]="30"
                kendoGridSelectBy="idHistorico_Procesos"
                scrollable="virtual">
          <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="campo" title="{{ 'campo' | translate}}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="celda-completa" *ngIf="!dataItem.campo.includes('zona', 0)"> {{dataItem.campo | translate}} </span>
              <span class="celda-completa" *ngIf="dataItem.campo.includes('zona', 0) && !dataItem.campo.includes('thusillo', 0)"> {{ 'zona' | translate}} {{ dataItem.campo.substring(4) }} </span>
              <span class="celda-completa" *ngIf="dataItem.campo.includes('thusillozona', 0)"> {{ 'tHusillo' | translate}} {{ 'zona' | translate}} {{ dataItem.campo.substring(12) }} </span>
            </ng-template>
          </kendo-grid-column>
          <!--receta original-->
          <kendo-grid-column width="10%" [style]="{'text-align': 'rigth'}" field="original" title="{{ 'recetaOriginal' | translate }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span class="celda-completa"> {{dataItem.original}} </span>
            </ng-template>
          </kendo-grid-column>
        
          <!--ciclo1-->
          <kendo-grid-column width="10%" *ngIf="lengthColumnas>=1" [style]="{'text-align': 'left'}" field="ciclo0" title="{{ titulos[0] }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>

              <span class="celda-completa" *ngIf="(dataItem.original - dataItem.tolerancia <= dataItem.ciclo0 && dataItem.ciclo0 <= dataItem.original + dataItem.tolerancia)" class=""> {{dataItem.ciclo0}} </span>
              <span class="celda-completa" *ngIf="!(dataItem.original - dataItem.tolerancia <= dataItem.ciclo0 && dataItem.ciclo0 <= dataItem.original + dataItem.tolerancia)" class="receta-desigual"> {{dataItem.ciclo0}} </span>
            </ng-template>
          </kendo-grid-column>
          
          <!--ciclo2-->
          <kendo-grid-column width="10%" *ngIf="lengthColumnas>=2" [style]="{'text-align': 'left'}" field="ciclo1" title="{{ titulos[1] }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>

              <span class="celda-completa" *ngIf="(dataItem.original - dataItem.tolerancia <= dataItem.ciclo1 && dataItem.ciclo1 <= dataItem.original + dataItem.tolerancia)" class=""> {{dataItem.ciclo1}} </span>
              <span class="celda-completa" *ngIf="!(dataItem.original - dataItem.tolerancia <= dataItem.ciclo1 && dataItem.ciclo1 <= dataItem.original + dataItem.tolerancia)" class="receta-desigual"> {{dataItem.ciclo1}} </span>
            </ng-template>
          </kendo-grid-column>

          <!--ciclo3-->
          <kendo-grid-column width="10%" *ngIf="lengthColumnas>=3" [style]="{'text-align': 'left'}" field="ciclo2" title="{{ titulos[2] }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>

              <span class="celda-completa" *ngIf="(dataItem.original - dataItem.tolerancia <= dataItem.ciclo2 && dataItem.ciclo2 <= dataItem.original + dataItem.tolerancia)" class=""> {{dataItem.ciclo2}} </span>
              <span class="celda-completa" *ngIf="!(dataItem.original - dataItem.tolerancia <= dataItem.ciclo2 && dataItem.ciclo2 <= dataItem.original + dataItem.tolerancia)" class="receta-desigual"> {{dataItem.ciclo2}} </span>
            </ng-template>
          </kendo-grid-column>

          <!--ciclo4-->
          <kendo-grid-column width="10%" *ngIf="lengthColumnas>=4" [style]="{'text-align': 'left'}" field="ciclo3" title="{{ titulos[3] }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>

              <span class="celda-completa" *ngIf="(dataItem.original - dataItem.tolerancia <= dataItem.ciclo3 && dataItem.ciclo3 <= dataItem.original + dataItem.tolerancia)" class=""> {{dataItem.ciclo3}} </span>
              <span class="celda-completa" *ngIf="!(dataItem.original - dataItem.tolerancia <= dataItem.ciclo3 && dataItem.ciclo3 <= dataItem.original + dataItem.tolerancia)" class="receta-desigual"> {{dataItem.ciclo3}} </span>
            </ng-template>
          </kendo-grid-column>

          <!--ciclo5-->
          <kendo-grid-column width="10%" *ngIf="lengthColumnas>=5" [style]="{'text-align': 'left'}" field="ciclo4" title="{{ titulos[4] }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>

              <span class="celda-completa" *ngIf="(dataItem.original - dataItem.tolerancia <= dataItem.ciclo4 && dataItem.ciclo4 <= dataItem.original + dataItem.tolerancia)" class=""> {{dataItem.ciclo4}} </span>
              <span class="celda-completa" *ngIf="!(dataItem.original - dataItem.tolerancia <= dataItem.ciclo4 && dataItem.ciclo4 <= dataItem.original + dataItem.tolerancia)" class="receta-desigual"> {{dataItem.ciclo4}} </span>
            </ng-template>
          </kendo-grid-column>

          <!--media-->
          <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="media" title="{{ 'media' | translate }}" class="p-0">
            <ng-template kendoGridCellTemplate let-dataItem>

                <span class="celda-completa" *ngIf="(dataItem.original - dataItem.tolerancia <= dataItem.media && dataItem.media <= dataItem.original + dataItem.tolerancia)" class=""> {{dataItem.media}} </span> 
                <span class="celda-completa" *ngIf="!(dataItem.original - dataItem.tolerancia <= dataItem.media && dataItem.media <= dataItem.original + dataItem.tolerancia)" class="receta-desigual"> {{dataItem.media}} </span>  
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
        </div>         
      </div>
    </div>
  </div>

</div>

<div *ngIf="loading" class="k-i-loading" style="z-index: 3;"></div>
