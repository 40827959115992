import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService, UsuariosService, MantenimientosPredefinidosService, DiccionarioPerdidasService, MaquinasService, OperariosService, BloqueoRangosService, ClasificacionesService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { GroupDescriptor } from '@progress/kendo-data-query';


@Component({
  selector: 'app-bloqueo-rangos-detalle',
  templateUrl: './bloqueoRangosDetalle.component.html'
})


export class BloqueoRangosDetalleComponent {

  public user;
  public id;
  public isAddMode: boolean;
  public nombre: any;
  public listaMaquinas: any = [];
  public maquinasSelected: any = [];
  public listaOperarios: any = [];
  public operariosSelected: any = [];
  public tiposPerdidasList: any = [];
  public selectedTipoPerdida: any;
  public recurrente: boolean = false;
  public listaTurnos: any = [];
  public turnosSelected: any = [];
  public fechaIniUnico: Date;
  public horaIniUnico: any;
  public fechaFinUnico: Date;
  public horaFinUnico: any;
  public bloqueoRangos_Tipos_Dat: any = [];
  public selectedTipoBloqueo: any;
  public frecuencia: any;
  public hora: any;
  public tiempoEstimado: any = ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)];
  public diasSemana: any = [{ id: 1, nombre: this.translateService.instant('lunes') }
    , { id: 2, nombre: this.translateService.instant('martes') }
    , { id: 3, nombre: this.translateService.instant('miercoles') }
    , { id: 4, nombre: this.translateService.instant('jueves') }
    , { id: 5, nombre: this.translateService.instant('viernes') }
    , { id: 6, nombre: this.translateService.instant('sabado') }
    , { id: 7, nombre: this.translateService.instant('domingo') }];
  public selectedDiaSemana: number = 1;
  public dia: number = 1;
  public maxDias: number = 31;
  public selectedDia: number = 1;
  public meses: any = [{ id: 1, nombre: this.translateService.instant('enero') }
    , { id: 2, nombre: this.translateService.instant('febrero') }
    , { id: 3, nombre: this.translateService.instant('marzo') }
    , { id: 4, nombre: this.translateService.instant('abril') }
    , { id: 5, nombre: this.translateService.instant('mayo') }
    , { id: 6, nombre: this.translateService.instant('junio') }
    , { id: 7, nombre: this.translateService.instant('julio') }
    , { id: 8, nombre: this.translateService.instant('agosto') }
    , { id: 9, nombre: this.translateService.instant('septiembre') }
    , { id: 10, nombre: this.translateService.instant('octubre') }
    , { id: 11, nombre: this.translateService.instant('noviembre') }
    , { id: 12, nombre: this.translateService.instant('diciembre') }];

  public selectedMes: number = 1;
  public turnos: any = [{ id: 1, nombre: this.translateService.instant('manana') }
    , { id: 2, nombre: this.translateService.instant('tarde') }
    , { id: 3, nombre: this.translateService.instant('noche') }];
  public selectedTurno: number = 1;
  public descripcion: string = "";
  public maskTiempoEstimado = "00:00:00";

  // SELECTOR MAQUINAS
  public selectorMaquinas: any = [];
  public agregarMaquina: boolean = false;
  public ensennarFiltroMaquinas: boolean = false;
  public ensennarMaquinas: boolean = false;
  public showSecciones: boolean = false;
  public seccionesSeleccionadas: any = [];
  public groupedSeccion: any = [];
  public showGrupos: boolean = false;
  public grupos: any = [];
  public clasificaciones: any = [];
  public showClasificaciones: boolean = false;
  public gruposSeleccionados: any = [];
  public clasificacionesSeleccionados: any = [];
  public filtro: any = [];
  public filtroString: string = "";
  public maquinasAgregar: any = [];
  public maquinasOriginal;
  public relacionesClasificaciones = new Map();
  public maquinas_clasificaciones: any = [];
  public agregarSeleccionados = [];
  public isAgruparPorGrupos = false;
  public isAgruparPorSecciones = true;
  public isAgruparPorClasificaciones = false;
  public maquinaParaGrupos: any = [];
  public agregarMaquinas: any = []
  public secciones: any = [];
  public gruposCargados: boolean = false;

  public groupsGrupos: GroupDescriptor[] = [{ field: "nombreGrupo" }];
  public groupsSecciones: GroupDescriptor[] = [{ field: "nombreSeccion" }];

  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private userService: UsuariosService,
    public route: ActivatedRoute,
    public mantenimientosPredefinidosService: MantenimientosPredefinidosService,
    public diccionarioPerdidasService: DiccionarioPerdidasService,
    public maquinasService: MaquinasService,
    public operariosService: OperariosService,
    public bloqueoRangosService: BloqueoRangosService,
    public clasificacionesService: ClasificacionesService,
    public myFunctions: MyFunctions) {

    this.translateService = translate;
    this.menuService.titulo = this.translateService.instant('bloqueoRangos').toUpperCase();
  }


  ngOnInit() {
    this.user = this.userService.userValue
    this.id = this.route.snapshot.params['id'];
    if (this.id == 0) {
      this.isAddMode = true;
    } else {
      this.isAddMode = false;
    }
    this.hora = new Date();
    this.horaIniUnico = new Date();
    this.horaFinUnico = new Date();
    this.hora.setHours(0, 0, 0);
    this.horaIniUnico.setHours(0, 0, 0);
    this.horaFinUnico.setHours(0, 0, 0);
    this.fechaIniUnico = new Date();
    this.fechaFinUnico = new Date();
    this.fechaIniUnico.setHours(0, 0, 1);
    this.fechaFinUnico.setHours(0, 0, 1);
    this.nombre = "";
    this.tiempoEstimado = this.tiempoEstimado[0];
    this.cargarDatos();

  }

  public cargarDatos() {
    this.bloqueoRangosService.GetBloqueoRangos_Tipos_Dat().pipe(first()).subscribe((result: any) => {
      var data: any = result;
      this.selectedTipoBloqueo = data[0].id;
      data.forEach(tipoMantenimiento => {
        tipoMantenimiento.nombre = this.translateService.instant(tipoMantenimiento.nombre);
      });
      data.forEach(tipoMantenimiento => {
        this.bloqueoRangos_Tipos_Dat.push(tipoMantenimiento);
      });
      this.diccionarioPerdidasService.GetAll().subscribe(data => {
        var perdidas: any = data.data;
        if (perdidas.length > 0) {
          // this.selectedTipoPerdida = perdidas[0].id;
          perdidas.forEach(perdida => {
            var per = {
              id: perdida.id,
              nombre: perdida.nombre
            }
            this.tiposPerdidasList.push(per);
          });
        }
        this.operariosService.getAllUsuariosConColor().subscribe(element => {
          var operarios: any = element;
          operarios.forEach(operario => {
            var ope = {
              id: operario.idUsuario,
              nombre: operario.nombreCompleto
            };
            this.listaOperarios.push(ope);
          });
          this.maquinasService.get().subscribe(maq => {
            var maquinas: any = maq;
            maquinas.forEach(maquina => {
              this.listaMaquinas.push(maquina);
              //this.agregarMaquinas.push(maquina);
            });
            if (this.id != 0) {
              this.cargarSeccionesGrupo();
            }
          });
        });
      });
    });
  }

  public cargarDatosEditar() {
    this.bloqueoRangosService.GetBloqueoRangos_byId(this.id).subscribe(result => {
      var bloqueos: any = result[0];
      this.nombre = bloqueos.nombre;
      this.descripcion = bloqueos.descripcion;
      this.selectedTipoPerdida = bloqueos.idDiccionarioPerdidas;
      this.recurrente = bloqueos.recurrente;
      this.turnosSelected = bloqueos.idTipoTurno;
      this.fechaIniUnico = new Date(bloqueos.fechaInicio);
      this.fechaFinUnico = new Date(bloqueos.fechaFin);
      this.horaIniUnico = new Date(bloqueos.fechaInicio);
      this.horaFinUnico = new Date(bloqueos.fechaFin);
      this.selectedTipoBloqueo = bloqueos.idTipoBloqueoRango;
      this.tiempoEstimado = this.segungosFormatoMask(bloqueos.tiempoEstimado);
      var a: any = bloqueos.hora.split(":");
      this.hora = new Date();
      this.hora.setHours(parseInt(a[0]), parseInt(a[1]), parseInt(a[2]));
      this.selectedDia = bloqueos.dia;
      this.selectedMes = bloqueos.mes;
      this.calcularDiasMes();
      this.frecuencia = bloqueos.frecuencia;

      this.bloqueoRangosService.GetBloqueoRangos_Maquinas_byId(this.id).subscribe(datosMaq => {
        var maquinas: any = datosMaq;
        var a = this.listaMaquinas;
        this.maquinasSelected = [];
        this.selectorMaquinas = [];
        this.maquinasAgregar = [];
        this.agregarMaquinas = [];
        this.selectorMaquinas = this.myFunctions.copy(this.listaMaquinas);
        this.maquinasAgregar = this.myFunctions.copy(this.listaMaquinas);
        this.agregarMaquinas = this.myFunctions.copy(this.listaMaquinas);
        this.listaMaquinas.forEach(listaMaq => {
          listaMaq.nombreSeccion = this.secciones.filter(f => f.id == listaMaq.idSeccion)[0]?.nombre;
          if (listaMaq.nombreSeccion == undefined)
            listaMaq.nombreSeccion = this.translateService.instant('sinAsignar');
          this.selectorMaquinas.push(listaMaq);
          this.maquinasAgregar.push(listaMaq);
          this.agregarMaquinas.push(listaMaq);
          maquinas.forEach(maquina => {
            if (listaMaq.id == maquina.idMaquinas) {
              this.maquinasSelected.push(listaMaq);
              this.agregarSeleccionados.push(listaMaq.id);
            }
          });
        });

        this.bloqueoRangosService.GetBloqueoRangos_Operarios_byId(this.id).subscribe(datosOp => {
          var operarios: any = datosOp;
          this.listaOperarios.forEach(listaOp => {
            operarios.forEach(operario => {
              if (listaOp.id == operario.idOperario) {
                this.operariosSelected.push(listaOp);
              }
            });
          });
        });
      });
    });
  }


  cargarSeccionesGrupo() {
    var r1 = false, r2 = false, r3 = false;
    // SECCIONES
    this.userService.getSecciones().subscribe(json => {
      this.userService.secciones = json;
      //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
      var an1: any = this.userService.secciones;
      this.secciones = an1.filter(f => f.activo === true);

      var i = 100 // esto se utiliza para generar un nuevo grid en las secciones para que no sean las mismas que las areas
      let siglasAux = "";
      this.secciones.forEach(element => {
        if (this.groupedSeccion.filter(f => f.nombre == element.areaProductiva).length == 0) {
          // crear las siglas de las areas productivas
          if (element.areaProductiva.split(" ").length > 1) {
            let aux = element.areaProductiva.split(" ");
            siglasAux = aux[0][0] + aux[1][0]
          } else {
            siglasAux = element.areaProductiva[0] + element.areaProductiva[1];
          }

          var newElement = {
            idGrid: element.idAreaProductiva,
            nombre: element.areaProductiva,
            idRelacionado: null,
            isSeleccionado: false,
            isSeleccionadoDestino: false,
            siglas: siglasAux
          }
          this.groupedSeccion.push(newElement);
        }

        element.idGrid = i;
        element.idRelacionado = element.idAreaProductiva
        element.isSeleccionado = false;
        element.isSeleccionadoDestino = false;
        // crear las siglas de las secciones
        if (element.nombre.split(" ").length > 1) {
          let aux = element.nombre.split(" ");
          siglasAux = aux[0][0] + aux[1][0]
        } else {
          siglasAux = element.nombre[0] + element.nombre[1];
        }
        element.siglas = siglasAux;

        this.groupedSeccion.push(element);
        i++
      });
      r1 = true
      if (r1 && r2 && r3) this.cargarDatosEditar();
    });

    // GRUPO MAQUINAS
    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.grupos.forEach(element => {
        // crear las siglas de los grupos
        if (element.nombre.split(" ").length > 1) {
          let aux = element.nombre.split(" ");
          element.siglas = aux[0][0] + aux[1][0]
        } else {
          element.siglas = element.nombre[0] + element.nombre[1];
        }
      });
      this.gruposCargados = true;
      r2 = true;
      if (r1 && r2 && r3) this.cargarDatosEditar();
    });

    // CLASIFICACIONES
    this.clasificacionesService.GetAll().subscribe(
      (result) => {
        this.clasificaciones = result;

        this.clasificaciones.forEach(element => {
          // crear las siglas de las secciones
          if (element.nombre.split(" ").length > 1) {
            let aux = element.nombre.split(" ");
            element.siglas = aux[0][0] + aux[1][0]
          } else {
            element.siglas = element.nombre[0] + element.nombre[1];
          }
        });

        this.clasificacionesService.GetMaquinasClasificaciones().subscribe((json: any) => {
          this.maquinas_clasificaciones = json;
          r3 = true;
          if (r1 && r2 && r3) this.cargarDatosEditar();
        });

        // definir un map para saber de cada clasificacion cual es su hijo
        this.clasificaciones.forEach(element => {
          this.relacionesClasificaciones.set(element.id, [element.id]);

          if (this.clasificaciones.filter(f => f.idPadre == element.id).length > 0)
            this.relacionesClasificaciones.set(element.id, this.setHijos(element.id, [element.id]));
        });
      });
  }

  // Funcion recursiva para crear el map de las clasificaciones
  setHijos(id, array) {
    var idHijos = this.clasificaciones.filter(f => f.idPadre == id);
    if (idHijos.length > 0)
      idHijos.forEach(element => {
        array.push(element.id)
        this.setHijos(element.id, array);
      });

    return array;
  }


  public selectionChangeTipoPerdida(e) {
    this.selectedTipoPerdida = e.id;
  }
  public selectionTipoBloqueoChange(e) {
    this.selectedTipoBloqueo = e.id;
    if (this.selectedTipoBloqueo == 2) {
      if (this.selectedDia > 7) {
        this.selectedDia = 1;
      }
    }
  }
  public selectionChangeDias(e) {
    this.selectedDiaSemana = e.id;
  }
  public selectDia(e) {
    this.selectedDia = e.id;
  }
  public selectionChangeMeses(e) {
    this.selectedMes = e.id;
    this.calcularDiasMes();
  }
  public selectionChangeTurnos(e) {
    this.selectedTurno = e.id;
  }

  public onSubmit() {

    if (!this.recurrente) {
      this.selectedTipoBloqueo = 1;
      this.frecuencia = 1;
      this.hora.setHours(0, 0, 0);
      this.tiempoEstimado = "000000";
      this.selectedDia = 1;
      this.selectedMes = 1;
      var horaIni = this.horaIniUnico;
      this.fechaIniUnico.setHours(this.horaIniUnico.getHours(), this.horaIniUnico.getMinutes(), this.horaIniUnico.getSeconds());
      this.fechaFinUnico.setHours(this.horaFinUnico.getHours(), this.horaFinUnico.getMinutes(), this.horaFinUnico.getSeconds());
      this.fechaIniUnico = new Date(Date.UTC(this.fechaIniUnico.getFullYear(), this.fechaIniUnico.getMonth(), this.fechaIniUnico.getDate(), this.fechaIniUnico.getHours(), this.fechaIniUnico.getMinutes(), this.fechaIniUnico.getSeconds()));
      this.fechaFinUnico = new Date(Date.UTC(this.fechaFinUnico.getFullYear(), this.fechaFinUnico.getMonth(), this.fechaFinUnico.getDate(), this.fechaFinUnico.getHours(), this.fechaFinUnico.getMinutes(), this.fechaFinUnico.getSeconds()));
    } else {
      this.fechaIniUnico = new Date();
      this.fechaFinUnico = new Date();
      this.fechaIniUnico.setHours(0, 0, 0);
      this.fechaFinUnico.setHours(0, 0, 0);
    }
    var a = this.maquinasSelected;
    var maquinasST = "";
    this.maquinasSelected.forEach(maquina => {
      if (maquinasST == "") {
        maquinasST = maquina.id.toString();
      } else {
        maquinasST = maquinasST + "," + maquina.id.toString();
      }
    });

    var operariosST = "";
    this.operariosSelected.forEach(operario => {
      if (operariosST == "") {
        operariosST = operario.id.toString();
      } else {
        operariosST = operariosST + "," + operario.id.toString();
      }
    });

    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  public update() {
    var maquinasST = "";
    this.maquinasSelected.forEach(maquina => {
      if (maquinasST == "") {
        maquinasST = maquina.id.toString();
      } else {
        maquinasST = maquinasST + "," + maquina.id.toString();
      }
    });

    var operariosST = "";
    this.operariosSelected.forEach(operario => {
      if (operariosST == "") {
        operariosST = operario.id.toString();
      } else {
        operariosST = operariosST + "," + operario.id.toString();
      }
    });

    var horaFormato = this.pad(this.hora.getHours()).toString() + ":" + this.pad(this.hora.getMinutes()).toString() + ":" + this.pad(this.hora.getSeconds()).toString()
    this.bloqueoRangosService.UpdateBloqueoRangos(this.id, this.selectedTipoBloqueo, this.selectedTipoPerdida, this.selectedTurno, this.nombre, this.descripcion, this.recurrente
      , this.myFunctions.datetimeToSQL(this.fechaIniUnico), this.myFunctions.datetimeToSQL(this.fechaFinUnico), this.frecuencia, horaFormato, this.formatoHoraASegundos(this.tiempoEstimado), this.selectedDia, this.selectedMes, maquinasST, operariosST).subscribe(result => {
        var res: any = result;
        if (res != -1) {
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          this.atras();
        } else {
          this.alertService.error(this.translateService.instant('error'));
        }

      });
  }

  public insert() {
    var maquinasST = "";
    this.maquinasSelected.forEach(maquina => {
      if (maquinasST == "") {
        maquinasST = maquina.id.toString();
      } else {
        maquinasST = maquinasST + "," + maquina.id.toString();
      }
    });

    var operariosST = "";
    this.operariosSelected.forEach(operario => {
      if (operariosST == "") {
        operariosST = operario.id.toString();
      } else {
        operariosST = operariosST + "," + operario.id.toString();
      }
    });

    var horaFormato = this.pad(this.hora.getHours()).toString() + ":" + this.pad(this.hora.getMinutes()).toString() + ":" + this.pad(this.hora.getSeconds()).toString()
    this.bloqueoRangosService.InsertBloqueoRangos(this.selectedTipoBloqueo, this.selectedTipoPerdida, this.selectedTurno, this.nombre, this.descripcion, this.recurrente
      , this.fechaIniUnico, this.fechaFinUnico, this.frecuencia, horaFormato, this.formatoHoraASegundos(this.tiempoEstimado), this.selectedDia, this.selectedMes, maquinasST, operariosST).subscribe(result => {
        var res: any = result;
        if (res != -1) {
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          this.atras();
        } else {
          this.alertService.error(this.translateService.instant('error'));
        }

      });

  }

  public formatoHoraASegundos(tiempo: string) {

    var tiempoInt = parseInt(tiempo);
    var segundos = Math.floor(tiempoInt % 100);
    var minutos = Math.floor((Math.floor(tiempoInt / 100)) % 100);
    var horas = Math.floor(tiempoInt / 10000);

    return horas * 3600 + minutos * 60 + segundos;
  }

  public segungosFormatoMask(tiempo) {
    var horas;
    var minutos;
    var segundos;
    if (tiempo > 3600) {
      horas = Math.floor(tiempo / 3600);
      minutos = Math.floor(Math.floor(tiempo % 3600) / 60);
      segundos = Math.floor(Math.floor(tiempo % 3600) % 60);
      return this.pad(horas).toString() + this.pad(minutos).toString() + this.pad(segundos).toString();
    } else if (tiempo > 60) {
      horas = "00";
      minutos = Math.floor(tiempo / 60);
      segundos = Math.floor(tiempo % 60);
      return horas + this.pad(minutos).toString() + this.pad(segundos).toString();
    } else {
      return "0000" + this.pad(tiempo).toString();
    }
  }

  public calcularDiasMes() {
    var month = this.selectedMes;
    var fecha = new Date();
    var anno = fecha.getFullYear();
    var d = new Date(anno, month, 0);
    this.maxDias = d.getUTCDate() + 1;
    if (this.selectedDia > this.maxDias) {
      this.selectedDia = this.maxDias;
    }
  }


  public atras() {
    this.router.navigate(['bloqueoRangos']);
  }
  public pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }

  public eliminarMaquinaSelected(id) {
    this.maquinasSelected = this.maquinasSelected.filter(f => f.id != id);
  }

  showAgregar() {
    this.agregarMaquina = true;
  }

  showFiltro() {
    this.ensennarFiltroMaquinas = true;
    this.ensennarMaquinas = false;
  }

  selectAllMaquinas() {
    if (this.showSecciones) {
      var arrayIds = [];
      if (this.seccionesSeleccionadas.length == this.groupedSeccion.filter(f => f.idRelacionado != null).length) {
        this.annadirSeccionesSeleccionadas(null, arrayIds);
      } else {
        this.groupedSeccion.forEach(element => {
          arrayIds.push(element.idGrid);
        });
        this.annadirSeccionesSeleccionadas(null, arrayIds);
      }
    } else if (this.showGrupos) {
      var arrayIds = [];
      if (this.gruposSeleccionados.length == this.grupos.length) {
        this.annadirGruposSeleccionados(null, arrayIds);
      } else {
        this.grupos.forEach(element => {
          arrayIds.push(element.id);
        });
        this.annadirGruposSeleccionados(null, arrayIds);
      }
    } else if (this.showClasificaciones) {
      var arrayIds = [];
      if (this.clasificacionesSeleccionados.length == this.clasificaciones.length) {
        this.annadirClasificacionesSeleccionadas(null, arrayIds);
      } else {
        this.clasificaciones.forEach(element => {
          arrayIds.push(element.id);
        });
        this.annadirClasificacionesSeleccionadas(null, arrayIds);
      }
    }
  }

  annadirSeccionesSeleccionadas(event = null, arrayIds = null) {
    // al seleccionar select all
    if (arrayIds != null) {
      this.seccionesSeleccionadas = [];
      if (arrayIds.length == 0) this.filtro = this.filtro.filter(f => f.idSeccion == -1);
      else if (arrayIds.length != 0) {
        arrayIds.forEach(element => {
          let row = this.groupedSeccion.filter(f => f.idGrid == element)[0];
          if (row.idRelacionado != null) {
            this.seccionesSeleccionadas.push(row.id);
            if (this.filtro.filter(f => f.idSeccion == row.id).length == 0) {
              var newFiltroRow = {
                idGrupo: -1,
                idSeccion: row.id,
                idClasificacion: -1,
                nombre: row.nombre
              }
              this.filtro.push(newFiltroRow);
            }
          }
        });
      }
    }
    // al clickar en el grid
    else {
      if (event.dataItem.idRelacionado != null) {
        if (this.seccionesSeleccionadas.filter(f => f == event.dataItem.id).length > 0) {
          this.seccionesSeleccionadas = this.seccionesSeleccionadas.filter(f => f != event.dataItem.id);
          this.filtro = this.filtro.filter(f => f.idSeccion != event.dataItem.id);
        } else {
          this.seccionesSeleccionadas.push(event.dataItem.id);

          var newFiltroRow = {
            idGrupo: -1,
            idSeccion: event.dataItem.id,
            idClasificacion: -1,
            nombre: event.dataItem.nombre
          }
          this.filtro.push(newFiltroRow);
        }
      }
    }

    this.groupedSeccion.forEach(element => {
      if (this.seccionesSeleccionadas.includes(element.id))
        element.isSeleccionado = true
      else
        element.isSeleccionado = false
    });

    var array = this.filtro.map(function (value) { return value.nombre })
    this.filtroString = array.join(', ');

    this.filtrarMaquinas();
  }

  annadirGruposSeleccionados(event, arrayIds = null) {
    // al seleccionar select all
    if (arrayIds != null) {
      this.gruposSeleccionados = [];
      if (arrayIds.length == 0) this.filtro = this.filtro.filter(f => f.idGrupo == -1);
      else if (arrayIds.length != 0) {
        arrayIds.forEach(element => {
          let row = this.grupos.filter(f => f.id == element)[0];
          this.gruposSeleccionados.push(row.id);
          if (this.filtro.filter(f => f.idGrupo == row.id).length == 0) {
            var newFiltroRow = {
              idGrupo: row.id,
              idSeccion: -1,
              idClasificacion: -1,
              nombre: row.nombre
            }
            this.filtro.push(newFiltroRow);
          }
        });
      }
    }
    // al clickar en el grid
    else {
      if (this.gruposSeleccionados.includes(event.dataItem.id)) {
        this.gruposSeleccionados = this.gruposSeleccionados.filter(f => f != event.dataItem.id);
        this.filtro = this.filtro.filter(f => f.idGrupo != event.dataItem.id);
      } else {
        this.gruposSeleccionados.push(event.dataItem.id);

        var newFiltroRow = {
          idGrupo: event.dataItem.id,
          idSeccion: -1,
          idClasificacion: -1,
          nombre: event.dataItem.nombre
        }
        this.filtro.push(newFiltroRow);
      }
    }

    this.grupos.forEach(element => {
      if (this.gruposSeleccionados.includes(element.id))
        element.isSeleccionado = true
      else
        element.isSeleccionado = false
    });

    var array = this.filtro.map(function (value) { return value.nombre })
    this.filtroString = array.join(', ');

    this.filtrarMaquinas();
  }

  annadirClasificacionesSeleccionadas(event = null, arrayIds = null) {
    // al seleccionar select all
    if (arrayIds != null) {
      this.clasificacionesSeleccionados = [];
      if (arrayIds.length == 0) this.filtro = this.filtro.filter(f => f.idClasificacion == -1);
      else if (arrayIds.length != 0) {
        arrayIds.forEach(element => {
          let row = this.clasificaciones.filter(f => f.id == element)[0];
          this.clasificacionesSeleccionados.push(row.id);
          if (this.filtro.filter(f => f.idClasificacion == row.id).length == 0) {
            var newFiltroRow = {
              idGrupo: -1,
              idSeccion: -1,
              idClasificacion: row.id,
              nombre: row.nombre
            }
            this.filtro.push(newFiltroRow);
          }
        });
      }
    }
    // al clickar en el grid
    else {
      if (this.clasificacionesSeleccionados.filter(f => f == event.dataItem.id).length > 0) {
        this.clasificacionesSeleccionados = this.clasificacionesSeleccionados.filter(f => f != event.dataItem.id);
        this.filtro = this.filtro.filter(f => f.idClasificacion != event.dataItem.id);
      } else {
        this.clasificacionesSeleccionados.push(event.dataItem.id);

        var newFiltroRow = {
          idGrupo: -1,
          idSeccion: -1,
          idClasificacion: event.dataItem.id,
          nombre: event.dataItem.nombre
        }
        this.filtro.push(newFiltroRow);
      }
    }

    this.clasificaciones.forEach(element => {
      if (this.clasificacionesSeleccionados.includes(element.id))
        element.isSeleccionado = true
      else
        element.isSeleccionado = false
    });

    var array = this.filtro.map(function (value) { return value.nombre })
    this.filtroString = array.join(', ');

    this.filtrarMaquinas();

  }

  filtrarMaquinas() {

    if (this.gruposSeleccionados.length == 0 && this.seccionesSeleccionadas.length == 0 && this.clasificacionesSeleccionados.length == 0) {
      this.maquinasAgregar = this.myFunctions.copy(this.listaMaquinas);
      this.setMaquinasSeleccionadas();
      return;
    }

    // para filtrar secciones y grupos
    this.maquinasAgregar = this.listaMaquinas.filter(f => {
      // secciones
      var result = true;
      if (this.seccionesSeleccionadas.length > 0)
        this.seccionesSeleccionadas.every(s => {
          if (f.idSeccion == s)
            result = false
          return result;
        })
      else
        result = false;
      // grupos
      var result2 = true;
      if (this.gruposSeleccionados.length > 0)
        this.gruposSeleccionados.every(s => {
          if (f.idsGrupos.split(',').includes(s.toString()))
            result2 = false
          return result2;
        })
      else
        result2 = false;
      return !result && !result2;
    });

    // para filtrar clasificaciones
    if (this.clasificacionesSeleccionados.length > 0) {
      var idClasificacionesAFiltrar = []
      this.clasificacionesSeleccionados.forEach(element => {
        var aux = this.relacionesClasificaciones.get(element);
        idClasificacionesAFiltrar.push(...aux);
      });
      var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
      idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
      this.maquinasAgregar = this.maquinasAgregar.filter(f => idMaquinas.includes(f.id));
    }

    this.setMaquinasSeleccionadas();
  }

  setMaquinasSeleccionadas() {
    this.maquinasAgregar.forEach(element => {
      if (this.agregarSeleccionados.includes(element.id))
        element.isSeleccionado = true;
      else
        element.isSeleccionado = false;
    })

    if (this.isAgruparPorGrupos)
      this.agruparPorGrupos();
  }

  agruparPorSecciones() {
    this.isAgruparPorSecciones = true;
    this.isAgruparPorGrupos = false;
    this.isAgruparPorClasificaciones = false;
  }

  agruparPorGrupos() {

    this.isAgruparPorSecciones = false;
    this.isAgruparPorGrupos = true;
    this.isAgruparPorClasificaciones = false;

    this.maquinaParaGrupos = [];
    this.maquinasAgregar.forEach(element => {
      let aux = element.idsGrupos.split(',')
      aux.forEach(row => {
        if (this.gruposSeleccionados.includes(parseInt(row)) || this.gruposSeleccionados.length == 0) {
          element.idGrupo = parseInt(row);
          element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
          if (element.nombreGrupo == undefined)
            element.nombreGrupo = this.translateService.instant('sinAsignar');
          this.maquinaParaGrupos.push(this.myFunctions.copy(element));
        }
      });
    })

  }

  agruparPorClasificaciones() {
    this.isAgruparPorSecciones = false;
    this.isAgruparPorGrupos = false;
    this.isAgruparPorClasificaciones = true;
  }


  annadirMaquina(event) {

    //#region annadir seleccionado
    if (this.agregarSeleccionados.includes(event.dataItem.id)) {
      this.agregarSeleccionados = this.agregarSeleccionados.filter(f => f != event.dataItem.id);
      this.maquinasSelected = this.maquinasSelected.filter(f => f.id != event.dataItem.id);
      this.agregarMaquinas = this.agregarMaquinas.filter(f => f.id != event.dataItem.id);
    } else {
      this.agregarSeleccionados.push(event.dataItem.id);
      this.agregarMaquinas.push(this.listaMaquinas.filter(f => f.id == event.dataItem.id)[0]);
      this.maquinasSelected.push(this.listaMaquinas.filter(f => f.id == event.dataItem.id)[0]);
    }

    this.setMaquinasSeleccionadas();
    //#endregion

    this.maquinasSelected = this.listaMaquinas.filter(f => this.agregarSeleccionados?.includes(f.id));
  }
  eliminarMaquina(id) {
    this.agregarSeleccionados = this.agregarSeleccionados.filter(f => f != id);
    this.maquinasSelected = this.maquinasSelected.filter(f => f.id != id);
    this.agregarMaquinas = this.agregarMaquinas.filter(f => f.id != id);

    this.setMaquinasSeleccionadas();
  }

  cerrarMenu() {
    if (this.agregarMaquina && (this.ensennarFiltroMaquinas || this.ensennarMaquinas)) {
      this.ensennarFiltroMaquinas = false;
      this.ensennarMaquinas = false;
    } else {
      this.agregarMaquina = false;
    }
  }


}