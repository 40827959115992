import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FileInfo, FileRestrictions, SelectEvent, UploadEvent } from '@progress/kendo-angular-upload';
import { first, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  UsuariosService, AlertService, OperacionesService, PiezasService, MenuService, MaquinasService,
  InformeProyectosService, ConsumiblesService, RecetasService, ConfiguracionService, AtributosService, ProgramasService
} from '@app/_services';
import { MyFunctions } from '@app/_helpers';
import { LayoutModule, TabStripComponent } from '@progress/kendo-angular-layout';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Renderer2, NgZone } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process, groupBy, GroupResult } from "@progress/kendo-data-query";
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

interface ItemHerramienta {
  nombre: string,
  id: number
}
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';

@Component({
  selector: 'app-operacionesDetalle-data',
  templateUrl: 'operacionesDetalle.html'
})
export class OperacionesDetalleComponent {

  @ViewChild('tabstrip') public tabstrip: TabStripComponent;

  mySelection: number[] = [];
  modalReferenceRemove: NgbModalRef;
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef; //referencia al popup de borrado

  modalReferenceCopy: NgbModalRef;
  @ViewChild('popupCopiar') popupCopiar: NgbModalRef; //referencia al popup de copiar

  modalReferenceCopyAdvice: NgbModalRef;
  @ViewChild('popupCopiarAviso') popupCopiarAviso: NgbModalRef; //referencia al popup de copiar

  modalReferencepopupAddHerramienta: NgbModalRef;
  @ViewChild('popupAddHerramienta') popupAddHerramienta: NgbModalRef; //referencia al popup de popupAddHerramienta

  modalReferencepopupAddPrograma: NgbModalRef;
  @ViewChild('popupAddPrograma') popupAddPrograma: NgbModalRef; //referencia al popup de popupAddPrograma

  modalReferencepopupFrecuencias: NgbModalRef;
  @ViewChild('popupFrecuencias') popupFrecuencias: NgbModalRef; //referencia al popup de popupFrecuencias

  modalReferencepopupDocumentoCalidad: NgbModalRef;
  @ViewChild('popupDocumentoCalidad') popupDocumentoCalidad: NgbModalRef; //referencia al popup de popupDocumentoCalidad

  //Datos para modal valores
  esFuncion_modal: any;
  funcion_modal: any;
  imagen_modal: any;
  ruta_modal: any = "";
  utilMedicionData: any;
  utilMedicionSelected: any;
  valores_modal: any = [];
  elementoSeleccionado_modal: any;
  traduccionResto = this.translate.instant("resto");
  utillajeModal: any = [];
  selectedUtillaje: any;
  submitted2: any;
  errorRutaModal: any;

  //Datos para subida excel cotas
  public myFiles2: Array<FileInfo> = [];
  private _kendoFiles: FileInfo[];
  modalReferenceloadingupload: NgbModalRef;

  //Datos completos para filtro
  ofsListas = false;
  piezasListas = false;
  partesListas = false;
  rutasListas = false;
  operacionesListas = false;
  private dataFiltro: any;

  public listaPiezas: any;
  public piezasSeleccionadas: any;

  public listaPartes: any;
  public partesSeleccionadas: any;

  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaRutas: any;
  public rutasSeleccionadas: any;

  public listaOperaciones: any;
  public operacionesSeleccionadas: any;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
    maxFileSize: 1048576
  };
  public loadingGraficoTresEjes: any = false;
  public imagePreviews: any[] = [];
  public events: string[] = [];
  public percentajes: string[] = [];
  public tipoOperacionLista: any = [];
  public histCambiosAntes: String;
  public histCambiosDespues: String;
  public histCambiosCampos: String;
  public logo: string;
  public selectedItems: ItemHerramienta[] = [];
  public idOperacion: number;
  /* public herramientasCombo: Array<ItemHerramienta> = [];
   public herramientasComboSelected: string = "";
   public herramientas_placeHolder: string = "";*/
  public datosTolerancia: any = [];
  public datosControl: any = [];
  public valores: any = [];
  public valoresFinales: any = [];
  // public referencias: [];
  public idruta: number;
  public idMaquinas: any[];
  public estadoMaquinas: any[];
  public maquinasEstados: {};
  public idParte: number;
  public idOF: number;
  public idPieza: number;
  public maqPrep: boolean;
  public datosPrep: boolean;
  public idSeccionOf: number = -1;



  //historico cambios
  nombreHisto: String;
  idErpHisto: String;
  secuenciaHisto: String;
  tipooperacionSuperiorHisto: number;
  ordenHistoric: number;
  subordenHistoric: number;
  tipooperacionHistoric: number;
  obligatoriaHistoric: number;
  edicionHistoric: number;
  loteHistoric: number;
  nSerieHistoric: number;
  coladaHistoric: number;
  terminadoHistoric: number;
  calidadHistoric: number;
  piezasseguidasHistoric: number;
  ladoHisto: number;
  tiempoEstimadoHisto: string;
  tiempoEstimadoPreparacionHisto: string;
  costeHisto: number;
  cantidadHisto: number;
  realizadosHisto: number;
  imagenHisto: string;
  observacionesHisto: string;
  calidadCantidadHisto: number;
  calidadProcesarHisto: number;
  calidadRestoHisto: number;
  calidadObservacionesHisto: string;
  calidadResetearFrecuenciaHisto: boolean;
  calidadSolicitarCotaPiezaNoOkNoCriticasHisto: any;
  calidadSolicitarCotaPiezaNoOkCriticasHisto: any;
  calidadSolicitarObservacionHisto: any;
  calidadNumPrimerasPiezasOkHisto: number;
  calidadSoloDecimalesHisto: boolean;
  mostrarValoresNoOKOperarioHisto: boolean;
  confirmacionMensajeHisto: string;
  confirmacionRetrocederHisto: string;
  limitesFechaIniHisto: string;
  limitesFechaFinHisto: string;
  limitesProcesaFueraTallerHisto: number;
  limitesProcesarFueraTurnoHisto: number;
  limitesInterrumpirHisto: number;
  escandalloHisto: string;
  escandalloCantidadHisto: number;
  valoresHisto = undefined;
  herramientasHisto: any = [];
  programasHisto: any = [];

  tiempoCambio: number;
  idReferencia: number;
  nombre: String;
  orden: number;
  lado: number;
  suborden: number;
  tiempoestimado: number;
  tiempoEstimadoPreparacion: number;
  coste: number;
  observaciones: String;
  iderp: String;
  limitado: Boolean;
  imagen: String;
  public tipooperacion: number;
  public tipooperacionCalidadLista: any = [];

  public tiposControladoresCotasCriticas: any = [];
  public tiposControladoresCotasNoCriticas: any = [];
  ddControladorCotasNoCriticasChanged: boolean = false;
  ddControladorCotasCriticasChanged: boolean = false;
  public tiposObservacionPiezas: any = [];
  ddObservacionPiezasChanged: boolean = false;


  cantidad: number;
  hechos: number;
  calidadCantidad: number;
  procesar: number;
  resto: number;
  mensaje: String;
  retroceder: String;
  escandallo: any = [];
  cantidadescandallo: number;
  //herramientas: String;
  idRuta: number;
  fechainilimite: Date;
  fechafinlimite: Date;
  escandallomin: number;
  tipooperacionValor: boolean;
  tipooperacionConfirmacion: boolean;
  tipooperacionValormaquina: boolean;
  tipooperacionInformativo: boolean;
  public mask = '00:00:00';
  public piezasseleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  isAddMode: boolean;
  public botonesDisbled: string;
  form: FormGroup;
  user = this.userService.userValue;

  closeResult = '';
  tituloPopUpPregunta: any;

  fechaHoyStr: string;
  tituloPopUp: any;

  maquinas: any;
  //maquinas: any[];
  cambiomaquina: any;
  ineficencia: any;
  continuo: any;
  prioridad: any;
  partido: any;
  fueraturno: any;
  fuerataller: any;

  private editedRowIndex: number;
  public formGroup: FormGroup;
  public discard: String;
  sanitizer: any;

  //For tabs
  calidadstr: String = '';
  limitesstr: String = '';
  escandallostr: String = '';
  herramientasstr: String = '';
  recetasstr: String = '';
  programasstr : String = '';

  calidadActivo = false; //to know if Calidad's switch is actived
  public hayDatosFiltro: boolean = false; // for copiar form
  public submitted = false;
  public idVista: any; //Para saber a dónde hay que ir después con botón cancelar, a qué vista
  public operacionesHermanas: any = [];

  public tienePrograma: boolean = false;
  public nPrograma: boolean = false;
  public rutaPrograma: string = '';
  public rutaProgramaEsDir: boolean = false;


  //Variables para nueva versión herramientas
  public herramientas: any = [];
  public herramientasFinales: any = [];
  public herramientasSelected: any = [];
  public herramientasTotales: any = [];
  public herramientasTotalesSeleccionadas: any = [];
  private currentSubscription: Subscription;


  //Variables para nueva versión programas
  public programas: any = [];
  public programasFinales: any = [];
  public programasSelected: any = [];
  public programasTotales: any = [];
  public programasTotalesSeleccionadas: any = [];
  private currentSubscriptionProgramas: Subscription;

  public state: State = {
    skip: 0,
    take: 100,
  };

  public gridData: any = process([], this.state);

  public listaRecetas: [] = [];
  public recetasSelected: any = [];

  public cantidadTotal: any;

  public atributos: any;
  public groupedAtributo: GroupResult[];
  public atributosSeleccionados: any[] = [];


  //Programas
  public gridProgramas: any = process([], this.state);
  
  constructor(
    private configuracionService: ConfiguracionService,
    private formBuilder: FormBuilder,
    private userService: UsuariosService,
    private consumiblesService: ConsumiblesService,
    private programasService: ProgramasService,
    private route: ActivatedRoute,
    private operacionesService: OperacionesService,
    private piezasService: PiezasService,
    private menuService: MenuService,
    public router: Router,
    private maquinasService: MaquinasService,
    private translate: TranslateService,
    private modalService: NgbModal,
    protected alertService: AlertService,
    private informeProyectosService: InformeProyectosService,
    private myFunctions: MyFunctions,
    private renderer: Renderer2,
    private zone: NgZone,
    private recetasService: RecetasService,
    private atributosService: AtributosService) {

    this.menuService.titulo = this.translate.instant('operaciones').toUpperCase();
    this.calidadstr = this.translate.instant('calidad');
    this.limitesstr = this.translate.instant('limites');
    this.escandallostr = this.translate.instant('escandallo');
    this.herramientasstr = this.translate.instant('herramientas');
    this.recetasstr = this.translate.instant('recetas');
    
    this.programasstr = this.translate.instant('programas');
  }

  ngOnInit() {



    this.tipooperacionCalidadLista = [{ id: 1, nombre: this.translate.instant('informativo') },
    { id: 2, nombre: this.translate.instant('valor') },
    { id: 3, nombre: this.translate.instant('confirmacion') },
    { id: 4, nombre: this.translate.instant('valormaquina') },];

    this.idMaquinas = [];
    this.estadoMaquinas = [];
    this.percentajes = ["0-", "25-", "50-", "75-", "100-"];
    this.idPieza = this.route.snapshot.params.idpieza;
    this.idOF = this.route.snapshot.params.idof;
    this.idParte = this.route.snapshot.params.idparte;
    this.idRuta = this.route.snapshot.params.idruta;
    this.idVista = this.route.snapshot.params.idVista;
    this.idOperacion = this.route.snapshot.params.idoperacion;
    this.escandallomin = 0;
    this.submitted = false;
    this.tituloPopUp = document.getElementById("text_loading_popup");
    this.botonesDisbled = "disabled";
    this.isAddMode = !this.idOperacion || this.idOperacion == undefined;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idruta: this.idRuta,
      idparte: this.idParte,
      idpieza: this.idPieza,
      idof: this.idOF,
      idReferencia: ['',],
      nombre: ['', Validators.required],
      tipo_operacion: [undefined,],
      tipo_operacion_id: [undefined,],
      orden: [1,],
      lado: [0,],
      suborden: [0,],
      tiempoestimado: ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      tiempoEstimadoPreparacion: ['00:00:00', Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
      coste: [0.0000],
      observaciones: ['',],
      iderp: ['',],
      limitado: [false,],
      imagen: [''],
      informativo: [true,],
      valor: ['',],
      confirmacion: ['',],
      solicitar: ['',],
      cantidad: [1,],
      hechos: [0,],
      calidadCantidad: [0,],
      procesar: [0,],
      resto: [0,],
      solicitaredicion: ['',],
      mensaje: ['',],
      retroceder_data: [{}],
      retroceder: [null,],
      escandallo: [0,],
      cantidadescandallo: [0,],
      solicitarNSerie: ['',],
      solicitarColada: ['',],
      terminado: [false,],
      solicitarLote: ['',],
      fueraturno: [''],
      fuerataller: [''],
      cambiomaquina: [''],
      fechainilimite: [''],
      fechafinlimite: [''],
      tiemposParadasEst: ['',],
      ineficencia: ['',],
      secuencia: ['',],
      procesocontinuo: ['',],
      prioridad: [0,],
      tiempoentremaquinas: ['',],
      piezasseguidas: [false,],
      obligatoria: [false,],
      partido: [''],
      contino: ['',],
      fechainicio: [''],
      fechafin: [''],
      continuo: ['',],
      tipooperacion: [undefined,],
      tiempocambiomaquina: [0,],
      tipooperacionValor: [false,],
      tipooperacionConfirmacion: [false,],
      tipooperacionValormaquina: [false,],
      tipooperacionInformativo: [false,],
      archivo: new FormControl(''),
      //herramientas: new FormControl([]),
      calidadObservaciones: new FormControl(''),
      resetearFrecuencia: [false,],
      idControlarCotasNoCriticas: [1,],
      idControlarCotasCriticas: [1,],
      primerasPiezasNum: [0,],
      idObservacionPiezas: [1,],
      inputarSoloDecimales: [false,],
      mostrarValoresNoOKOperario: [false,],
      atributos: [[]],
      temperatura: [0.0000],
    });


    this.operacionesService.getdatosControl().pipe().subscribe((resultControl) => {
      this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
      var r1, r2, r3, r4, r5, r6, r7, r8, r9: boolean = false;
      r1 = true;
      this.operacionesService.Get_TipoOperacion_Combo().pipe(first()).subscribe((result: any) => {
        this.tipoOperacionLista = result;
        r2 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });

      var maquinas_validador_model = this.maquinasService.get_maquinas_model();
      if (maquinas_validador_model == false) {
        this.maquinasService.get().subscribe(json => {
          this.maquinasService.set_maquinas_model(json);
          this.maquinas = this.maquinasService.get_maquinas_model();
          r3 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
            if (this.idOperacion > 0) {
              this.isAddMode = false;
              this.CargarDatos();
            } else {
              this.isAddMode = true;
            }
          }
        });
      } else {
        this.maquinas = maquinas_validador_model;
        r3 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      }

      // this.operacionesService.GetReferencias().pipe().subscribe((result) => {
      //   this.referencias = result.operaciones;
      //   r4 = true;
      //   console.log("r4");
      //   if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
      //     if (this.idOperacion > 0) {
      //       this.isAddMode = false;
      //       this.CargarDatos();
      //     } else {
      //       this.isAddMode = true;
      //     }
      //   }
      // });
      r4 = true

      this.operacionesService.Get_CantidadPartePadre(this.idParte).pipe(first()).subscribe((result: any) => {
        this.form.controls.cantidad.setValue(result[0].cantidad);
        r5 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });
      if (this.idOperacion < 0 || this.idOperacion == null || this.idOperacion == undefined) {
        this.piezasService.GetByID({ idOF: this.idOF, idpieza: this.idPieza, idparte: this.idParte }).pipe(first()).subscribe((result: any) => {
          this.operacionesHermanas = result.operaciones; //agregamos todas, ya que esta operación aún no ha sido creada
          this.escandallo = result.bom;
          r6 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
            if (this.idOperacion > 0) {
              this.isAddMode = false;
              this.CargarDatos();
            } else {
              this.isAddMode = true;
            }
          }
        });
      }
      else {
        this.piezasService.GetByID({ idOF: this.idOF, idpieza: this.idPieza, idparte: this.idParte }).pipe(first()).subscribe((result: any) => {
          result.operaciones.forEach(element => {
            if (Number(element.id) != Number(this.idOperacion)) //No enseñamos la operación actual
              this.operacionesHermanas.push(element);
          });
          this.escandallo = result.bom;
          r6 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
            if (this.idOperacion > 0) {
              this.isAddMode = false;
              this.CargarDatos();
            } else {
              this.isAddMode = true;
            }
          }
        });
      }
      this.operacionesService.getdatosUtillaje().pipe().subscribe((resultUtillaje) => {
        this.utillajeModal = resultUtillaje;
        r7 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });


      this.configuracionService.get_controlarCotas_DAT().subscribe((result: any) => {
        result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
        this.tiposControladoresCotasCriticas = result;
        this.tiposControladoresCotasNoCriticas = result.filter(x => x.id != 3);
        r8 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });

      this.configuracionService.get_observacionPiezas_DAT().subscribe((result: any) => {
        result.forEach(function (element) { element.nombre = this.translate.instant(element.nombre); }, this);
        this.tiposObservacionPiezas = result;
        r9 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9) {
          if (this.idOperacion > 0) {
            this.isAddMode = false;
            this.CargarDatos();
          } else {
            this.isAddMode = true;
          }
        }
      });

    });

    this.atributosService.getComboAtributos().subscribe(json => {
      this.atributos = json;
      if (this.atributos.length == 1)
        this.form.controls['atributos'].setValue(this.atributos);
      var an: any = this.atributos;
      this.groupedAtributo = groupBy(an, [{ field: 'atributo' }]);
    });
  }

  public onStateChange(e) {

    this.CargarDatos();

  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //se toman los datos
    this.informeProyectosService.Get_OfsPiezasOperacionesPartesRutas().pipe(first()).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByPieza = [];
      var groupByOf = [];
      var groupByParte = [];
      var groupByRuta = [];
      var groupByOperacion = [];


      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));

      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0))

      //GROUP POR PARTE
      data.forEach(function (a) {
        if (!this[a.nombreParte]) {
          this[a.nombreParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.nombreParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY RUTA
      data.forEach(function (a) {
        if (!this[a.idRuta]) {
          this[a.idRuta] = {
            idRuta: a.idRuta, nombreRuta: a.nombreRuta,
          };
          groupByRuta.push(this[a.idRuta]);
        }
      }, Object.create(null));

      this.listaRutas = groupByRuta.filter(item => (item.idRuta != -1));
      this.listaRutas.sort((a, b) => (a.nombreRuta > b.nombreRuta) ? 1 : ((b.nombreRuta > a.nombreRuta) ? -1 : 0));

      //GROUP BY Operaciones
      data.forEach(function (a) {
        if (!this[a.nombreOperacion]) {
          this[a.nombreOperacion] = {
            idOperacion: a.idOperacion, nombreOperacion: a.nombreOperacion,
          };
          groupByOperacion.push(this[a.nombreOperacion]);
        }
      }, Object.create(null));

      this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1));
      this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    });
  }

  CambioFiltro(): void { //si los datos del filtro cambian actualizar cuáles son los datos seleccionados

    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      idsOFs.push(this.ofsSeleccionados.idOf);

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      idPiezas.push(this.piezasSeleccionadas.nombrePieza);

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      idPartes.push(this.partesSeleccionadas.nombreParte);

    var idRutas = [];
    if (this.rutasSeleccionadas != undefined)
      idRutas.push(this.rutasSeleccionadas.nombreRuta);

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      idOperaciones.push(this.operacionesSeleccionadas.nombreOperacion);

    var groupByPieza = [];
    var groupByOf = [];
    var groupByOperacion = [];
    var groupByParte = [];
    var groupByRuta = [];

    //GROUP BY OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });
    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    //GROUP BY PARTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreParte]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombreParte] = {
            idParte: row.idParte, nombreParte: row.nombreParte,
          };
          groupByParte.push(lag[row.nombreParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

    //GROUP BY RUTA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreRuta]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombreRuta] = {
            idRuta: row.idRuta, nombreRuta: row.nombreRuta,
          };
          groupByRuta.push(lag[row.nombreRuta]);
        }
      });

    this.listaRutas = groupByRuta.filter(item => (item.idRuta != -1));
    this.listaRutas.sort((a, b) => (a.nombreRuta > b.nombreRuta) ? 1 : ((b.nombreRuta > a.nombreRuta) ? -1 : 0));

    //GROUP BY POR OPERACION
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreOperacion]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idRutas.includes(row.nombreRuta) || idRutas[0] == undefined)) {
          lag[row.nombreOperacion] = {
            idOperacion: row.idOperacion, nombreOperacion: row.nombreOperacion,
          };
          groupByOperacion.push(lag[row.nombreOperacion]);
        }
      });

    this.listaOperaciones = groupByOperacion.filter(item => (item.idOperacion != -1));
    this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    if ((this.listaOfs.length > 0 && this.ofsSeleccionados !== undefined))
      this.ofsListas = true;
    else
      this.ofsListas = false;
    if ((this.listaPiezas.length > 0 && this.piezasSeleccionadas !== undefined))
      this.piezasListas = true;
    else
      this.piezasListas = false;
    if ((this.listaPartes.length > 0 && this.partesSeleccionadas !== undefined))
      this.partesListas = true;
    else
      this.partesListas = false;
    if ((this.listaRutas.length > 0 && this.rutasSeleccionadas !== undefined))
      this.rutasListas = true;
    else
      this.rutasListas = false;
    if ((this.listaOperaciones.length > 0 && this.operacionesSeleccionadas !== undefined))
      this.operacionesListas = true;
    else
      this.operacionesListas = false;

    if (this.ofsListas && this.piezasListas && this.partesListas && this.rutasListas && this.operacionesListas)
      this.hayDatosFiltro = true; //ya están cargados los datos del filtro, los botones se pueden habilitar
    else
      this.hayDatosFiltro = false;
  }

  public copyData(event: any) {
    event.preventDefault(); //prevent submit
    this.cargarDatosFiltro();
    this.modalReferenceCopy = this.modalService.open(this.popupCopiar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  public removeHandler({ dataItem }) {
    if (this.mySelection.length > 0) {
      this.modalReferenceRemove = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
  }

  public copyElements() {
    this.operacionesService.GetByID(this.ofsSeleccionados.idOf, this.piezasSeleccionadas.idPieza, this.partesSeleccionadas.idParte, this.rutasSeleccionadas.idRuta, this.operacionesSeleccionadas.idOperacion).subscribe((result) => {
      this.valores = result.valores;
      if (this.valores) {
        //Para solventar error selección
        this.valores.forEach(element => {
          element.tipoControl = this.datosControl.filter(x => x.id == element.tipoControl)[0];
          if (element.tipoControl.id != -1) {
            element.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == element.tipoControl.id);
            if (element.datosTolerancia.length == 0) {
              element.datosTolerancia = [];
              element.tipoTolerancia = { nombre: "", id: -1 };
            } else {
              element.tipoTolerancia = element.datosTolerancia.filter(x => x.id == element.tipoTolerancia)[0];
            }
          } else {
            element.datosTolerancia = [];
            element.tipoTolerancia = { nombre: "", id: -1 };
          }
          element.idNuevo = element.id;
        });
        this.valoresHisto = [];
        this.valores.forEach(val => this.valoresHisto.push(val)); //Hacemos una copia del array
      }
      this.valoresFinales = this.valores;
    });

    this.modalReferenceCopyAdvice.close();
  }

  public adviceCopyElements() {
    this.modalReferenceCopy.close();
    this.modalReferenceCopyAdvice = this.modalService.open(this.popupCopiarAviso, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  public volverACopyElements() {
    this.limpiarFiltro();
    this.modalReferenceCopyAdvice.close();
    this.modalReferenceCopy = this.modalService.open(this.popupCopiar, { backdrop: 'static', size: 's', keyboard: false, centered: true });

  }

  public cancelarCopia() {
    this.limpiarFiltro();
    this.modalReferenceCopy.close();
  }

  public limpiarFiltro() {
    this.ofsSeleccionados = undefined;
    this.piezasSeleccionadas = undefined;
    this.partesSeleccionadas = undefined;
    this.rutasSeleccionadas = undefined;
    this.operacionesSeleccionadas = undefined;
    this.CambioFiltro();
  }

  public removeElements() {

    this.mySelection.forEach(id => {
      this.valoresFinales.filter(x => x.idNuevo === id).forEach(element => {
        element.id = -1;
      });
      this.valoresHisto.filter(x => x.idNuevo === id).forEach(element => {
        element.id = -1;
      });
    });
    this.valores = this.valoresFinales.filter(x => x.id !== -1);
    this.valoresHisto = this.valoresHisto.filter(x => x.id !== -1);
    this.modalReferenceRemove.close();
  }

  private CargarDatos() {
    this.loadingGraficoTresEjes = true;
    this.operacionesService.GetByID(this.idOF, this.idPieza, this.idParte, this.idRuta, this.idOperacion).pipe().subscribe((result) => {

      if (result.operacion[0]?.imagenBase64 != undefined && result.operacion[0]?.imagenBase64 != "" && result.operacion[0]?.imagenBase64 != null) {
        this.imageToShow = result.operacion[0].imagenBase64;
      }

      if (result.operacion[0].no_Correcto_Retroceder_a != undefined) {
        var retroceder_data = this.operacionesHermanas.find(x => x.id === result.operacion[0].no_Correcto_Retroceder_a);
        if (retroceder_data == undefined) retroceder_data = {};
      }


      if (result.idSeccionOf.length > 0)
        this.idSeccionOf = result.idSeccionOf[0].idSeccion;
      else
        this.idSeccionOf = 0;

      this.prioridad = 0;
      this.partido = false;
      this.fueraturno = false;
      this.fuerataller = false;
      this.cambiomaquina = false;

      this.ineficencia = false;
      this.continuo = false;
      this.tiempoCambio = 0;
      this.fechainilimite = null;
      this.fechafinlimite = null;
      // this.fechafinlimite.setMonth(this.fechafinlimite.getMonth() + 1);
      if (result.operacion[0].fechaIni != undefined)
        this.fechainilimite = new Date(result.operacion[0].fechaIni);
      if (result.operacion[0].fechaFin != undefined)
        this.fechafinlimite = new Date(result.operacion[0].fechaFin);

      this.nombreHisto = result.operacion[0].operacion;
      this.idErpHisto = result.operacion[0].idERP;
      this.ordenHistoric = result.operacion[0].orden;
      this.subordenHistoric = result.operacion[0].subOrden;
      this.secuenciaHisto = result.operacion[0].secuencia;
      if (result.operacion[0].limitado != null) {
        this.limitado = (result.operacion[0].limitado == 1);
      }

      this.tipooperacion = result.operacion[0].idOperacion_Tipo;
      this.tipooperacionHistoric = this.tipooperacion;
      this.obligatoriaHistoric = result.operacion[0].obligatorio;
      this.edicionHistoric = result.operacion[0].solicitar_edicion;
      this.loteHistoric = result.operacion[0].solicitar_Lote;
      this.nSerieHistoric = result.operacion[0].solicitar_N_Serie;
      this.coladaHistoric = result.operacion[0].solicitar_Colada;
      this.terminadoHistoric = result.operacion[0].terminado;
      this.calidadHistoric = result.operacion[0].calidad != null ? result.operacion[0].calidad : false;
      this.piezasseguidasHistoric = result.operacion[0].operarVariasPiezasAlMismoTiempo;
      this.ladoHisto = result.operacion[0].lado;
      this.tiempoEstimadoHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS);
      this.tiempoEstimadoPreparacionHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS);
      this.costeHisto = result.operacion[0].coste;
      this.cantidadHisto = result.operacion[0].cantidad;
      this.realizadosHisto = result.operacion[0].hechos;
      this.imagenHisto = result.operacion[0].imagen;
      this.observacionesHisto = result.operacion[0].observaciones;
      this.calidadCantidadHisto = result.operacion[0].calidadCantidad;
      this.calidadProcesarHisto = result.operacion[0].calidadProcesar;
      this.calidadRestoHisto = result.operacion[0].calidadResto;
      this.calidadObservacionesHisto = result.operacion[0].calidadObservaciones;
      this.confirmacionMensajeHisto = result.operacion[0].no_Correcto_Mensaje;
      if (retroceder_data?.nombre != undefined)
        this.confirmacionRetrocederHisto = retroceder_data.nombre;
      else {
        this.confirmacionRetrocederHisto = "sinAsignar";
      }
      if (this.fechainilimite != null && this.fechainilimite != undefined)
        this.limitesFechaIniHisto = this.fechainilimite.toString();
      else
        this.limitesFechaIniHisto = "sinAsignar";
      if (this.fechafinlimite != null && this.fechafinlimite != undefined)
        this.limitesFechaFinHisto = this.fechafinlimite.toString();
      else
        this.limitesFechaFinHisto = "sinAsignar";
      this.limitesProcesaFueraTallerHisto = result.operacion[0].subcontratado;
      this.limitesProcesarFueraTurnoHisto = result.operacion[0].planificarFueraDeTurno;
      this.limitesInterrumpirHisto = result.operacion[0].cortarAlFinalizarTurno;
      var tipo_operacion = this.tipoOperacionLista.find(x => x.id === result.operacion[0].tipo_operacion);
      if (tipo_operacion == undefined) tipo_operacion = { id: undefined };
      this.tipooperacionSuperiorHisto = tipo_operacion.nombre;

      //Histo config calidad
      this.calidadResetearFrecuenciaHisto = result.operacion[0].resetearFrecuencia;
      this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto = result.operacion[0].idControlarCotasNoCriticas;
      this.calidadSolicitarCotaPiezaNoOkCriticasHisto = result.operacion[0].idControlarCotasCriticas;
      this.calidadSolicitarObservacionHisto = result.operacion[0].idObservacionPiezas;
      this.calidadNumPrimerasPiezasOkHisto = result.operacion[0].primerasPiezasNum;
      this.calidadSoloDecimalesHisto = result.operacion[0].inputarSoloDecimales;
      this.mostrarValoresNoOKOperarioHisto = result.operacion[0].mostrarValoresNoOKOperario;

      var atributosCargados = [];

        if (result.atributos != undefined){
          result.atributos.forEach(a => {
            atributosCargados.push(this.atributos.find(x => x.id == a.idAtributo));
          });
        }

      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        idruta: this.idRuta,
        idparte: this.idParte,
        idpieza: this.idPieza,
        idof: this.idOF,
        idReferencia: [result.operacion[0].operacion,],
        nombre: [result.operacion[0].operacion, Validators.required],
        tipo_operacion: [tipo_operacion,],
        tipo_operacion_id: [tipo_operacion.id,],
        orden: [result.operacion[0].orden,],
        lado: [result.operacion[0].lado,],
        suborden: [result.operacion[0].subOrden,],
        tiempoestimado: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        tiempoEstimadoPreparacion: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        coste: [result.operacion[0].coste],
        observaciones: [result.operacion[0].observaciones,],
        iderp: [result.operacion[0].idERP,],
        limitado: [result.operacion[0].limitado],
        informativo: ['',],
        valor: ['',],
        confirmacion: ['',],
        solicitar: ['',],
        cantidad: [result.operacion[0].cantidad,],
        hechos: [result.operacion[0].hechos,],
        calidadCantidad: [result.operacion[0].calidadCantidad,],
        procesar: [result.operacion[0].calidadProcesar,],
        resto: [result.operacion[0].calidadResto,],
        mensaje: [result.operacion[0].no_Correcto_Mensaje,],
        retroceder_data: [retroceder_data],
        retroceder: [result.operacion[0].no_Correcto_Retroceder_a,],
        secuencia: [result.operacion[0].secuencia,],
        escandallo: [result.operacion[0].idBOM,],
        cantidadescandallo: [result.operacion[0].cantidadBOM,],
        solicitarNSerie: [result.operacion[0].solicitar_N_Serie,],
        solicitarColada: [result.operacion[0].solicitar_Colada,],
        terminado: [result.operacion[0].terminado,],
        solicitarLote: [result.operacion[0].solicitar_Lote,],
        solicitaredicion: [result.operacion[0].solicitar_edicion,],
        fueraturno: [result.operacion[0].planificarFueraDeTurno],
        fuerataller: [result.operacion[0].subcontratado,],
        cambiomaquina: [this.cambiomaquina,],
        tiempocambiomaquina: [this.tiempoCambio,],
        fechainicio: this.fechainilimite,
        fechafin: this.fechafinlimite,
        tiemposParadasEst: ['',],
        ineficencia: [this.ineficencia,],
        procesocontinuo: [result.operacion[0].cortarAlFinalizarTurno],
        prioridad: [1,],
        tiempoentremaquinas: [this.tiempoCambio,],
        piezasseguidas: [result.operacion[0].operarVariasPiezasAlMismoTiempo,],
        obligatoria: [result.operacion[0].obligatorio,],
        partido: [this.partido,],
        continuo: [result.operacion[0].cortarAlFinalizarTurno,],
        tipooperacion: [result.operacion[0].idOperacion_Tipo],
        tipooperacionValor: [false,],
        tipooperacionConfirmacion: [false,],
        tipooperacionValormaquina: [false,],
        tipooperacionInformativo: [false,],
        archivo: new FormControl(''),
        //herramientas: new FormControl(this.selectedItems),
        calidadObservaciones: new FormControl(result.operacion[0].calidadObservaciones),
        resetearFrecuencia: [result.operacion[0].resetearFrecuencia],
        idControlarCotasNoCriticas: [result.operacion[0].idControlarCotasNoCriticas],
        idControlarCotasCriticas: [result.operacion[0].idControlarCotasCriticas],
        primerasPiezasNum: [result.operacion[0].primerasPiezasNum],
        idObservacionPiezas: [result.operacion[0].idObservacionPiezas],
        inputarSoloDecimales: [result.operacion[0].inputarSoloDecimales],
        mostrarValoresNoOKOperario: [result.operacion[0].mostrarValoresNoOKOperario],
        atributos: new FormControl(atributosCargados),
        temperatura: [result.operacion[0].temperatura]
      }
      );
      this.calidadActivo = result.operacion[0].calidad;
      if (this.calidadActivo) this.calidadActivo = (this.user.calidad > 0);

      this.idMaquinas = result.operacion[0].idsMaquinas.split(",");
      this.estadoMaquinas = result.operacion[0].prioridadesMaquinas.split(",");

      this.tipooperacion = result.operacion[0].idOperacion_Tipo;

      this.valores = result.valores;

      this.tienePrograma = result.operacion[0].tienePrograma;
      this.nPrograma = result.operacion[0].nPrograma;
      this.rutaPrograma = result.operacion[0].rutaPrograma;
      this.rutaProgramaEsDir = result.operacion[0].rutaProgramaEsDir;

      this.operacionesService.getdatosControl().pipe().subscribe((resultControl) => {
        this.operacionesService.getdatosTolerancia().pipe().subscribe((resultTolerancia) => {
          this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
          this.datosTolerancia = resultTolerancia; //Aqui guardamos la general y luego las filtramos
          //Para solventar error seleccion
          if (this.valores) {
            this.valores.forEach(element => {
              if (element.tipoControl != -1)
                element.tipoControl = this.datosControl.filter(x => x.id == element.tipoControl)[0];
              else {
                element.tipoControl = { nombre: "", id: -1 };
              }
              if (element.tipoControl != undefined && element.tipoControl.id != -1) {
                element.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == element.tipoControl.id);
                if (element.datosTolerancia.length == 0) {
                  element.datosTolerancia = [];
                  element.tipoTolerancia = { nombre: "", id: -1 };
                } else {
                  element.tipoTolerancia = element.datosTolerancia.filter(x => x.id == element.tipoTolerancia)[0];
                }
              } else {
                element.datosTolerancia = [];
                element.tipoTolerancia = { nombre: "", id: -1 };
              }
              element.idNuevo = element.id;
              element.ruta = (element.ruta == null || element.ruta == undefined) ? "" : element.ruta;
              //element.visible= Boolean(element.visible);
            });
            this.valoresFinales = this.valores;
            this.valoresHisto = [];
            this.valores.forEach(val => this.valoresHisto.push(Object.assign({}, val))); //Hacemos una copia del array
          }
        });
      });
      if (result.herramientas != null && result.herramientas.length > 0) {
        this.herramientas = result.herramientas;
        console.log("estas son las caracteristicas de las herramientas", result.herramientas[0]);
        this.herramientasHisto = [];
        result.herramientas.forEach(element => { //lo hacemos así para crear una copia, pues si solo se asigna al cambiar un dato de uno se cambia el del otro
          this.herramientasHisto.push(element);
        });
        this.herramientas.forEach(herramienta => {
          herramienta.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(herramienta.tiempoUso);
          herramienta.porcenUso = herramienta.porcentajeUso;
          //herramienta.id = herramienta.id; //porque no es una inserción, ya existía
          herramienta.idOperacion = Number(this.idOperacion);
          herramienta.idNuevo = herramienta.id; //para solventar error insercion
        });
        this.herramientasFinales = this.herramientas;
        this.recargarGrid();
      }

      if(result.programas != null && result.programas.length > 0){
        this.programas = result.programas;
        this.programasHisto = [];
        
        console.log("estas son las caracteristicas de los programas", result.programas[0]);

        result.programas.forEach(element => { //lo hacemos así para crear una copia, pues si solo se asigna al cambiar un dato de uno se cambia el del otro
          this.programasHisto.push(element);
        });
        this.programas.forEach(programa => {
          programa.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(programa.tiempoUso);
          programa.porcenUso = programa.porcentajeUso;
          //programa.id = programa.id; //porque no es una inserción, ya existía
          programa.idOperacion = Number(this.idOperacion);
          programa.idNuevo = programa.id; //para solventar error insercion
        });
        this.programasFinales = this.programas;
        this.recargarGridProgramas();
      }

      this.listaRecetas = result.recetas;

      this.escandallo = result.escandallo;
      var escandallo_selected;
      if (this.escandallo.find(x => x.id === this.form.value.escandallo))
        escandallo_selected = this.escandallo.find(x => x.id === this.form.value.escandallo).nombre;
      if (escandallo_selected == undefined) escandallo_selected = "sinAsignar";
      this.escandalloHisto = escandallo_selected;
      this.escandalloCantidadHisto = result.operacion[0].cantidadBOM;
      this.loadingGraficoTresEjes = false;

    });
  }

  public tipo_operacion_valueChange(value: any): void {
    if (value == undefined) this.form.controls['tipo_operacion_id'].setValue(undefined);
    else this.form.controls['tipo_operacion_id'].setValue(value.id);
  }

  public cancelHandler({ sender, rowIndex }) {

    sender.closeRow(rowIndex);
    this.closeEditor(sender, rowIndex);
  }

  initMaquinas() {
    var auxIds = [];
    this.maquinas.forEach(element => {
      auxIds.push(element.id);
    });
    this.idMaquinas.forEach(Element => {
      if (!auxIds.includes(Number(Element))) return;
      if (Number(Element) > 0) {
        var mid;
        var value = '1';
        this.idMaquinas.forEach((element, index) => {
          if (element == Element) {
            value = this.estadoMaquinas[index];
          }
        });
        switch (parseInt(value)) {
          case 0:
            mid = "0-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 25:
            mid = "25-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 50:
            mid = "50-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 75:
            mid = "75-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          case 100:
            mid = "100-" + Element;
            var emptySpan = document.getElementById(mid);
            emptySpan.style.display = "block";
            break;
          default:
            break;
        }
      }
    });
  }

  onClickPrioridadMaquina(e) {
    var spanseleccionado = e.srcElement.children[0];

    if (spanseleccionado == undefined) {
      spanseleccionado = e.srcElement;
      spanseleccionado.style.width = "20px";
      spanseleccionado.style.display = "";
      return;

    }
    this.percentajes.forEach(Element => {
      let id = Element + spanseleccionado.parentElement.parentElement.parentElement.firstChild.value;
      var emptySpan = document.getElementById(id);
      emptySpan.style.display = "";
    });
    var idmaquina = spanseleccionado.parentElement.parentElement.parentElement.firstChild.value;
    var span = document.getElementsByClassName("prioridad-seleccionada") as HTMLCollectionOf<HTMLElement>;;

    for (var i = 0; i < span.length; i++) {
      var idmaquinaOtros = span[i].parentElement.parentElement.parentElement.firstChild.nodeValue;
      if (span[i] != spanseleccionado && idmaquina == idmaquinaOtros)
        //span[i].style.display = "";
        span[i].setAttribute("style", "display: none;");

    }


    if (spanseleccionado == undefined)
      spanseleccionado = e.srcElement;

    spanseleccionado.style.width = "20px";
    var idmaquina = spanseleccionado.parentElement.parentElement.parentElement.firstChild.value;
    if (spanseleccionado.style.display == "") {
      var porcentaje = spanseleccionado.parentElement.dataset.porcentaje;
      spanseleccionado.style.display = "block";
      this.GuardarPorcentaje(idmaquina, porcentaje);
    }

    else {
      spanseleccionado.style.display = "";
      this.EliminarPorcentaje(idmaquina);
    }
  }

  GuardarPorcentaje(idmaquina: any, porcentaje: any) {
    // this.initMaquinas();
    var id = idmaquina.toString();
    var prioridad = porcentaje.toString();

    var changed = 0;
    var deleteMaquinaIndex = -1;
    var length = this.idMaquinas.length;
    this.idMaquinas.forEach((element, index) => {

      if (element == id) {
        if (prioridad == '0'){
          deleteMaquinaIndex = index;
        }
        this.estadoMaquinas[index] = prioridad;
        changed = 1;
      }

    });

    if (changed == 0) {
      this.idMaquinas.push(id);
      this.estadoMaquinas.push(prioridad)
    }

    if (deleteMaquinaIndex != -1){
      this.idMaquinas.splice(deleteMaquinaIndex, 1);
      this.estadoMaquinas.splice(deleteMaquinaIndex, 1);
    }

    /*this.maquinasService.guardarPrioridadMaquina(this.idOF, this.idPieza, this.idParte, this.idRuta, this.idOperacion, idmaquina, porcentaje).subscribe((result) => {
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });*/
  }


  EliminarPorcentaje(idmaquina: any) {
    this.maquinasService.EliminarPrioridadMaquina(this.idOF, -1, -1, -1, -1, idmaquina).subscribe((result) => {
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }


  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    //grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public onClickNuevoValor(event: any) {

    this.configuracionService.get_configuracion_calidad_frecuencias().subscribe(
      json => {
        var dataFrecuencias: any = json;
        var maxId;
        if (this.valoresFinales.length === 0)
          maxId = -1;
        else
          maxId = Math.max.apply(Math, this.valoresFinales.map(value => value.idNuevo));
        var auxLength = (this.valoresFinales.filter(x => x.id !== -1)).length;

        var dt: any = {
          //'visible': false,
          'activo': false,
          'imagen': false,
          'conLimite': false,
          'eje': "",
          'esFuncion': false,
          'funcion': "",
          'id': 0,
          'idNuevo': maxId + 1,
          'idOperacion': Number(this.idOperacion),
          'toleranciaMax': 0.0,
          'toleranciaMin': 0.0,
          'nombre': "",
          'orden': auxLength + 1,
          //'referencia': "",
          'referenciada': "false",
          'unidades': "",
          'varmaquina': "",
          'vnominal': 0,
          'critico': false,
          'porDefecto': true,
          'cantidad': this.form.value.calidadCantidad,
          'procesar': this.form.value.procesar,
          'resto': this.form.value.resto,
          //'descripcion': "",
          'tipoTolerancia': { nombre: "", id: -1 },
          'tipoControl': { nombre: "", id: -1 },
          'datosTolerancia': [],
          'frecuencia1_cant': json[0].cantidad,
          'frecuencia1_porcen1': json[0].porcen1,
          'frecuencia1_porcen2': json[0].porcen2,
          'frecuencia2_cant': json[1].cantidad,
          'frecuencia2_porcen1': json[1].porcen1,
          'frecuencia2_porcen2': json[1].porcen2,
          'frecuencia3_cant': json[2].cantidad,
          'frecuencia3_porcen1': json[2].porcen1,
          'frecuencia3_porcen2': json[2].porcen2,
          'frecuencia4_cant': json[3].cantidad,
          'frecuencia4_porcen1': json[3].porcen1,
          'frecuencia4_porcen2': json[3].porcen2,
          'frecuencia5_cant': json[4].cantidad,
          'frecuencia5_porcen1': json[4].porcen1,
          'frecuencia5_porcen2': json[4].porcen2,
          'resto_cant': json[5].cantidad,
          'resto_porcen1': json[5].porcen1,
          'resto_porcen2': json[5].porcen2,
          'cambioOperario_cant': json[6].cantidad,
          'cambioOperario_porcen1': json[6].porcen1,
          'cambioOperario_porcen2': json[6].porcen2,
          'ruta': "",
          'idUtillaje': -1
        }

        var tot = 0; // para controlar las piezas que llevamos contadas
        var i = 1; // para controlar el key
        var cantTot;
        if (this.cantidadTotal != undefined)
          cantTot = this.cantidadTotal;
        else
          cantTot = this.form.value.cantidad

        var i = 0;
        dataFrecuencias.forEach(element => {
          if (element.nombre != 'resto' && element.nombre != 'cambioCritico') {
            if ((element.cantidad + tot) > cantTot) {
              if ((cantTot - tot) > 0) {
                dt['frecuencia' + i + '_cant'] = cantTot - tot;
                dt['frecuencia' + i + '_porcen1'] = element.porcen1;
                dt['frecuencia' + i + '_porcen2'] = element.porcen2;
                tot = cantTot; // asi nos aseguramos que no entra mas aqui
              } else {
                dt['frecuencia' + i + '_cant'] = 0;
                dt['frecuencia' + i + '_porcen1'] = 0;
                dt['frecuencia' + i + '_porcen2'] = 0;
              }

            } else {
              tot += element.cantidad;
              dt['frecuencia' + i + '_cant'] = element.cantidad;
              dt['frecuencia' + i + '_porcen1'] = element.porcen1;
              dt['frecuencia' + i + '_porcen2'] = element.porcen2;
            }
            i++;
          }

          this.valores_modal[i] = {
            idNuevo: element.id,
            nombre: element.nombre,
            cantidad: element.cantidad,
            porcen1: element.porcen1,
            porcen2: element.porcen2,
            porcen3: element.porcen3,
            disabled: element.desactivado
          }
          i++;
        });


        if (tot < cantTot) {
          dt['resto_cant'] = cantTot - tot;
        } else {
          dt['resto_cant'] = 0
        }


        this.valoresFinales.push(dt);
        this.valores = this.valoresFinales.filter(x => x.id !== -1);
        if (!this.isAddMode) {
          //Como se está metiendo un valor nuevo, es decir, que al principio de entrar a la vista no existía, hay que añadirlo como un vacío en el histórico de valores
          this.valoresHisto.push({
            //'visible': "sinAsignar",
            'imagen': "sinAsignar",
            'eje': "sinAsignar",
            'esFuncion': "sinAsignar",
            'funcion': "sinAsignar",
            'id': 0,
            'idNuevo': maxId + 1,
            'toleranciaMax': "sinAsignar",
            'toleranciaMin': "sinAsignar",
            'nombre': "sinAsignar",
            'orden': "sinAsignar",
            'referencia': "sinAsignar",
            'unidades': "sinAsignar",
            'vnominal': "sinAsignar",
            'critico': "sinAsignar",
            'porDefecto': "sinAsignar",
            'cantidad': "sinAsignar",
            'procesar': "sinAsignar",
            'resto': "sinAsignar",
            //'descripcion': "sinAsignar",
            'tipoTolerancia': "sinAsignar",
            'tipoControl': "sinAsignar",
            'frecuencia1_cant': "sinAsignar",
            'frecuencia1_porcen1': "sinAsignar",
            'frecuencia1_porcen2': "sinAsignar",
            'frecuencia2_cant': "sinAsignar",
            'frecuencia2_porcen1': "sinAsignar",
            'frecuencia2_porcen2': "sinAsignar",
            'frecuencia3_cant': "sinAsignar",
            'frecuencia3_porcen1': "sinAsignar",
            'frecuencia3_porcen2': "sinAsignar",
            'frecuencia4_cant': "sinAsignar",
            'frecuencia4_porcen1': "sinAsignar",
            'frecuencia4_porcen2': "sinAsignar",
            'frecuencia5_cant': "sinAsignar",
            'frecuencia5_porcen1': "sinAsignar",
            'frecuencia5_porcen2': "sinAsignar",
            'resto_cant': "sinAsignar",
            'resto_porcen1': "sinAsignar",
            'resto_porcen2': "sinAsignar",
            'cambioOperario_cant': "sinAsignar",
            'cambioOperario_porcen1': "sinAsignar",
            'cambioOperario_porcen2': "sinAsignar",
            'ruta': "",
            'idUtillaje': "sinAsignar"
          });
        }
      });

  }

  public reorderData(event: any) {
    event.preventDefault(); //prevent submit
    //se reordena el json segun el nuevo orden
    this.valoresFinales = this.valoresFinales.sort(function (elem1, elem2) {
      // Get data
      var keyA = elem1.orden, keyB = elem2.orden;
      // Compare the 2 elems
      if (keyA > keyB) return 1;
      if (keyA < keyB) return -1;
      return 0;
    });
    //se consolida el orden y se hace único
    this.valoresFinales.forEach((elem: any, index) => {
      elem.orden = index + 1;
    });
    this.valores = this.valoresFinales.filter(x => x.id !== -1);
  }


  onClickEliminarOperacion(content) {
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("popup.preguntaeliminarpopupdetallecompleto");
    }
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  addHandler(e, content) {

    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  onSubmitValor(contentloading, e) {
    //e.preventDefault();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.nuevoValor();
  }

  async nuevoValor() {
    var idsmaquinas = "";
    var estadosmaquinas = "";
    this.estadoMaquinas.forEach((element, index) => {
      if (element != "" && element != 1) {
        idsmaquinas = idsmaquinas + "," + this.idMaquinas[index];
        estadosmaquinas = estadosmaquinas + "," + element;
      }
    });
    var filename = "";
    var fecha = "";
    if (this.form.value.fechafin != null)
      fecha = this.dateToYYYYMMDDtHHmmSSz(this.form.value.fechafin);
    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.id = this.idOperacion;
    this.form.value.idRuta = this.idRuta;
    this.form.value.tipooperacion = this.tipooperacion;
    this.operacionesService.create(this.form.value, filename, archivoBase64, fecha, idsmaquinas, estadosmaquinas)
      .subscribe((result) => {
        //this.modalReferenceloading.close();
        {

          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            var idgenerado = result.id;
            this.router.navigate(['operaciones/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/" + idgenerado + "/" + this.idVista]);
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      });
  }


  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }


  onSubmit(contentloading, e) {
    if (this.form.value.retroceder_data != null && this.form.value.retroceder_data != undefined)
      this.form.value.retroceder = this.form.value.retroceder_data.id;
    else {
      this.form.value.retroceder = -1;
    }
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) return;
    if (this.herramientas.length > 0) {//si hay herramientas mirar si alguna tiene un error 
      var hayErrores = false;
      this.herramientas.forEach(element => {
        if (element.errorTiempoEstimado || element.errorTiempos) {
          hayErrores = true;
        }
        //también aprovechamos para corregir datos para el guardado en la base de datos
        if (!Number(element.tiempoUso))
          element.tiempoUso = this.HMSToSeconds(element.tiempoUso);
        //Les ponemos cero porque para nuestro caso nos dan igual esos valores
        if (element.numContenido == "-") {
          element.numContenido = 0;
        }
        if (element.vidaUtil == "-") {
          element.vidaUtil = 0;
        }
        if (element.fiabilidad == "-") {
          element.fiabilidad = 0;
        }
        if (element.diametro == "-") {
          element.diametro = 0;
        }
        if (element.longitud == "-") {
          element.longitud = 0;
        }
        if (element.angulo == "-") {
          element.angulo = 0;
        }
        if (element.salto == "-") {
          element.salto = 0;
        }
        if (element.idERP == "-") {
          element.idERP = 0;
        }
        if (element.coste == "-") {
          element.coste = 0;
        }
        if (element.vc == "-") {
          element.vc = 0;
        }
        if (element.f == "-") {
          element.f = 0;
        }
        if (element.ap_ini == "-") {
          element.ap_ini = 0;
        }
        if (element.ap == "-") {
          element.ap = 0;
        }
        if (element.d_min == "-") {
          element.d_min = 0;
        }
      });
      if (hayErrores) //lo tiene, salir
      {
        return;
      }

    }

    if(this.programas.length>0){//si hay programas mirar si alguna tiene un error 
      var hayErrores= false;
      this.programas.forEach(element => { 
        if (element.Tmedio = ""){
          element.Tmedio = 0;
        }
        if (element.MejorT = ""){
          element.MejorT = 0;
        }
        if(!Number(element.Tmedio))
          element.Tmedio = this.HMSToSeconds(element.Tmedio);
          if(!Number(element.MejorT))
          element.MejorT = this.HMSToSeconds(element.MejorT);
      });
    }

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

    if (this.isAddMode) {
      this.nuevo();
      this.initMaquinas();
    } else {
      this.actualizar();
    }

  }

  async actualizar() {

    if (this.tipooperacion == 1 || this.tipooperacion == 3) {
      this.valores = [];
      this.valoresFinales = [];
    }

    var fecha = "";
    if (this.form.value.fechafin != null)
      fecha = this.dateToYYYYMMDDtHHmmSSz(this.form.value.fechafin);
    var idsmaquinas = "";
    var estadosmaquinas = "";
    this.estadoMaquinas.forEach((element, index) => {
      if (element != "" && element != 1) {
        idsmaquinas = idsmaquinas + "," + this.idMaquinas[index];
        estadosmaquinas = estadosmaquinas + "," + element;
      }
    });
    this.histCambiosAntes = "";
    this.histCambiosDespues = "";
    this.histCambiosCampos = "";

    if (this.form.value.fechafin != null)
      var fecha = this.dateToYYYYMMDDtHHmmSSz(this.form.value.fechafin);

    //CAMBIOS PARA HISTORICO
    if (this.herramientas != this.herramientasHisto) {
      this.histCambiosAntes = this.histCambiosAntes.concat("haHabidoCambios");
      this.histCambiosDespues = this.histCambiosDespues.concat("-");
      this.histCambiosCampos = this.histCambiosCampos.concat("herramientas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.programas != this.programasHisto) {
      this.histCambiosAntes = this.histCambiosAntes.concat("haHabidoCambios");
      this.histCambiosDespues = this.histCambiosDespues.concat("-");
      this.histCambiosCampos = this.histCambiosCampos.concat("programas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.nombreHisto != this.form.value.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.nombreHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("nombre");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.idErpHisto != this.form.value.iderp) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.idErpHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.iderp.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("idERP");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    var auxi;
    if (this.tipooperacionSuperiorHisto == undefined) {
      auxi = "sinAsignar";
    } else {
      auxi = this.tipooperacionSuperiorHisto.toString();
    }

    var auxi2;
    if (this.form.value.tipo_operacion?.nombre == undefined) {
      auxi2 = "sinAsignar";
    } else {
      auxi2 = this.form.value.tipo_operacion.nombre.toString();
    }

    if (auxi != auxi2) {
      this.histCambiosAntes = this.histCambiosAntes.concat(auxi);
      this.histCambiosDespues = this.histCambiosDespues.concat(auxi2);
      this.histCambiosCampos = this.histCambiosCampos.concat("tipooperacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.tipooperacionHistoric != this.tipooperacion) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.tipooperacionHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.tipooperacion.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("idOperacion_Tipo");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.ordenHistoric != this.form.value.orden) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.ordenHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.orden.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("orden");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.subordenHistoric != this.form.value.suborden) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.subordenHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.suborden.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("suborden");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.secuenciaHisto != this.form.value.secuencia) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.secuenciaHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.secuencia.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("secuencia");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.edicionHistoric != this.form.value.solicitaredicion) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.edicionHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitaredicion.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("edicion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.nSerieHistoric != this.form.value.solicitarNSerie) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.nSerieHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitarNSerie.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("nserie");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.terminadoHistoric != this.form.value.terminado) {
      if (this.terminadoHistoric == null) {
        this.terminadoHistoric = 0;
      }
      this.histCambiosAntes = this.histCambiosAntes.concat(this.terminadoHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.terminado.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("terminado");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (Boolean(this.calidadHistoric) != Boolean(this.calidadActivo)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.calidadActivo.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.coladaHistoric != this.form.value.solicitarColada) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.coladaHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitarColada.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("pedircolada");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.loteHistoric != this.form.value.solicitarLote) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.loteHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.solicitarLote.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("lote");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.obligatoriaHistoric != this.form.value.obligatoria) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.obligatoriaHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.obligatoria.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("operacionobligatoria");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.piezasseguidasHistoric != this.form.value.piezasseguidas) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.piezasseguidasHistoric.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.piezasseguidas.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("piezasseguidas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.ladoHisto != this.form.value.lado) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.ladoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.lado.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("lado");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.tiempoEstimadoHisto != this.form.value.tiempoestimado) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.tiempoEstimadoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.tiempoestimado.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("tiempoEstimado");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.tiempoEstimadoPreparacionHisto != this.form.value.tiempoEstimadoPreparacion) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.tiempoEstimadoPreparacionHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.tiempoEstimadoPreparacion.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("tiempoEstimadoPreparacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.costeHisto != this.form.value.coste) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.costeHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.coste.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("coste");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.cantidadHisto != this.form.value.cantidad) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.cantidadHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.cantidad.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("cantidad2");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.realizadosHisto != this.form.value.hechos) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.realizadosHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.hechos.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("hechos");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    if (this.observacionesHisto != this.form.value.observaciones) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.observacionesHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.observaciones.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("observacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadCantidadHisto != this.form.value.calidadCantidad) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadCantidadHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.calidadCantidad.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_cantidad2");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadProcesarHisto != this.form.value.procesar) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadProcesarHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.procesar.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_procesar");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadRestoHisto != this.form.value.resto) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadRestoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.resto.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_resto");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadObservacionesHisto != this.form.value.calidadObservaciones) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadObservacionesHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.calidadObservaciones.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("calidad_observacion");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.confirmacionMensajeHisto != this.form.value.mensaje) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.confirmacionMensajeHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.mensaje.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("confirmacion_mensaje");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }
    var auxi;
    if (this.form.value.retroceder_data?.nombre == undefined) {
      auxi = "sinAsignar";
    } else {
      auxi = this.form.value.retroceder_data.nombre.toString();
    }

    if (this.confirmacionRetrocederHisto != auxi) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.confirmacionRetrocederHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.retroceder_data.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("confirmacion_retroceder");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    var auxi;
    if (this.form.value.fechainicio == null) {
      auxi = "sinAsignar";
    } else {
      auxi = this.form.value.fechainicio.toString();
    }

    if (this.limitesFechaIniHisto != auxi) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesFechaIniHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.fechainicio.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_fechainicio");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    var auxi;
    if (this.form.value.fechafin == null) {
      auxi = "sinAsignar";
    } else {
      auxi = this.form.value.fechafin.toString();
    }
    if (this.limitesFechaFinHisto != auxi) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesFechaFinHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.fechafin.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_fechafin");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (Boolean(this.limitesProcesaFueraTallerHisto) != Boolean(this.form.value.fuerataller)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesProcesaFueraTallerHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.fuerataller.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_fuerataller");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (Boolean(this.limitesProcesarFueraTurnoHisto) != Boolean(this.form.value.fueraturno)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesProcesarFueraTurnoHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.fueraturno.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_fueraturno");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (Boolean(this.limitesInterrumpirHisto) != Boolean(this.form.value.continuo)) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.limitesInterrumpirHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.continuo.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("limites_procesocontinuo");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    var escandallo_selected;
    if (this.escandallo.find(x => x.id === this.form.value.escandallo))
      escandallo_selected = this.escandallo.find(x => x.id === this.form.value.escandallo).nombre;
    if (escandallo_selected == undefined) escandallo_selected = "sinAsignar";

    if (this.escandalloHisto != escandallo_selected) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.escandalloHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(escandallo_selected.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("escandallo");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.escandalloCantidadHisto != this.form.value.cantidadescandallo) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.escandalloCantidadHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.cantidadescandallo.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("escandallo_cantidad2");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.form.value.resetearFrecuencia == undefined || this.form.value.resetearFrecuencia == null) {
      this.form.value.resetearFrecuencia = false;
    }

    if (this.calidadResetearFrecuenciaHisto == undefined || this.calidadResetearFrecuenciaHisto == null) {
      this.calidadResetearFrecuenciaHisto = false;
    }

    if (this.calidadResetearFrecuenciaHisto != this.form.value.resetearFrecuencia) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadResetearFrecuenciaHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.resetearFrecuencia.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("resetearFrecuencia");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto == undefined || this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto <= 0) {
      this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto = { id: -1, nombre: 'sinAsignar' }
    } else if (this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto.id == undefined) {
      this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto = this.tiposControladoresCotasNoCriticas.filter(x => x.id == this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto)[0];
    }

    if (this.form.value.idControlarCotasNoCriticas == undefined || this.form.value.idControlarCotasNoCriticas <= 0) {
      this.form.value.idControlarCotasNoCriticas = { id: -1, nombre: 'sinAsignar' }
      this.ddControladorCotasNoCriticasChanged = true;
    } else if (this.form.value.idControlarCotasNoCriticas.id == undefined) {
      this.form.value.idControlarCotasNoCriticas = this.tiposControladoresCotasNoCriticas.filter(x => x.id == this.form.value.idControlarCotasNoCriticas)[0];
      this.ddControladorCotasNoCriticasChanged = true;
    }

    if (this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto.nombre != this.form.value.idControlarCotasNoCriticas.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSolicitarCotaPiezaNoOkNoCriticasHisto.nombre.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.idControlarCotasNoCriticas.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("controlarCotasDesde0NoCriticas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadSolicitarCotaPiezaNoOkCriticasHisto == undefined || this.calidadSolicitarCotaPiezaNoOkCriticasHisto <= 0) {
      this.calidadSolicitarCotaPiezaNoOkCriticasHisto = { id: -1, nombre: 'sinAsignar' }
    } else if (this.calidadSolicitarCotaPiezaNoOkCriticasHisto.id == undefined) {
      this.calidadSolicitarCotaPiezaNoOkCriticasHisto = this.tiposControladoresCotasCriticas.filter(x => x.id == this.calidadSolicitarCotaPiezaNoOkCriticasHisto)[0];
    }

    if (this.form.value.idControlarCotasCriticas == undefined || this.form.value.idControlarCotasCriticas <= 0) {
      this.form.value.idControlarCotasCriticas = { id: -1, nombre: 'sinAsignar' }
      this.ddControladorCotasCriticasChanged = true;
    } else if (this.form.value.idControlarCotasCriticas.id == undefined) {
      this.form.value.idControlarCotasCriticas = this.tiposControladoresCotasCriticas.filter(x => x.id == this.form.value.idControlarCotasCriticas)[0];
      this.ddControladorCotasCriticasChanged = true;
    }

    if (this.calidadSolicitarCotaPiezaNoOkCriticasHisto.nombre != this.form.value.idControlarCotasCriticas.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSolicitarCotaPiezaNoOkCriticasHisto.nombre.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.idControlarCotasCriticas.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("controlarCotasDesde0Criticas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadSolicitarObservacionHisto == undefined || this.calidadSolicitarObservacionHisto <= 0) {
      this.calidadSolicitarObservacionHisto = { id: -1, nombre: 'sinAsignar' }
    } else if (this.calidadSolicitarObservacionHisto.id == undefined) {
      this.calidadSolicitarObservacionHisto = this.tiposObservacionPiezas.filter(x => x.id == this.calidadSolicitarObservacionHisto)[0];
    }

    if (this.form.value.idObservacionPiezas == undefined || this.form.value.idObservacionPiezas <= 0) {
      this.form.value.idObservacionPiezas = { id: -1, nombre: 'sinAsignar' }
      this.ddObservacionPiezasChanged = true;
    } else if (this.form.value.idObservacionPiezas.id == undefined) {
      this.form.value.idObservacionPiezas = this.tiposObservacionPiezas.filter(x => x.id == this.form.value.idObservacionPiezas)[0];
      this.ddObservacionPiezasChanged = true;
    }

    if (this.calidadSolicitarObservacionHisto.nombre != this.form.value.idObservacionPiezas.nombre) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSolicitarObservacionHisto.nombre.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.idObservacionPiezas.nombre.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("observacionPiezas");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadNumPrimerasPiezasOkHisto == undefined || this.calidadNumPrimerasPiezasOkHisto == null) {
      this.calidadNumPrimerasPiezasOkHisto = 0;
    }
    if (this.form.value.primerasPiezasNum == undefined || this.form.value.primerasPiezasNum == null) {
      this.form.value.primerasPiezasNum = 0;
    }

    if (this.calidadNumPrimerasPiezasOkHisto != this.form.value.primerasPiezasNum) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadNumPrimerasPiezasOkHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.primerasPiezasNum.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("primerasPiezasNum");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.calidadSoloDecimalesHisto == undefined || this.calidadSoloDecimalesHisto == null) {
      this.calidadSoloDecimalesHisto = false;
    }
    if (this.form.value.inputarSoloDecimales == undefined || this.form.value.inputarSoloDecimales == null) {
      this.form.value.inputarSoloDecimales = false;
    }

    if (this.calidadSoloDecimalesHisto != this.form.value.inputarSoloDecimales) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.calidadSoloDecimalesHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.inputarSoloDecimales.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("inputarSoloDecimales");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    if (this.mostrarValoresNoOKOperarioHisto == undefined || this.mostrarValoresNoOKOperarioHisto == null) {
      this.mostrarValoresNoOKOperarioHisto = false;
    }
    if (this.form.value.mostrarValoresNoOKOperario == undefined || this.form.value.mostrarValoresNoOKOperario == null) {
      this.form.value.mostrarValoresNoOKOperario = false;
    }

    if (this.mostrarValoresNoOKOperarioHisto! = this.form.value.mostrarValoresNoOKOperario) {
      this.histCambiosAntes = this.histCambiosAntes.concat(this.mostrarValoresNoOKOperarioHisto.toString());
      this.histCambiosDespues = this.histCambiosDespues.concat(this.form.value.mostrarValoresNoOKOperario.toString());
      this.histCambiosCampos = this.histCambiosCampos.concat("mostrarValoresNoOKOperario");
      this.histCambiosAntes = this.histCambiosAntes.concat(',');
      this.histCambiosDespues = this.histCambiosDespues.concat(',');
      this.histCambiosCampos = this.histCambiosCampos.concat(',');
    }

    //Sus length deberían ser iguales, aunque el contenido pueda ser distinto
    if (this.valoresFinales.length > 0 && this.valoresHisto.length == this.valores.length && this.valoresHisto !== this.valores) {
      var elementoNuevo;
      this.valoresHisto.forEach(elemento => {
        elementoNuevo = this.valores.filter(x => x.idNuevo == elemento.idNuevo)[0]; //Si existe en uno debería existir en el otro
        //agregar histórico por cada cambio
        if (elemento.orden != elementoNuevo.orden) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.orden.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.orden.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_orden_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        /* if (elemento.visible != elementoNuevo.visible) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.visible.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.visible.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_visible_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }  */
        /* if (elemento.referencia != elementoNuevo.referencia) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.referencia.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.referencia.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_referencia_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }  */
        if (elemento.nombre != elementoNuevo.nombre) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.nombre.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.nombre.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_nombre_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.eje != elementoNuevo.eje) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.eje.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.eje.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_eje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.vnominal != elementoNuevo.vnominal) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.vnominal.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.vnominal.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_valornominal_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.toleranciaMax != elementoNuevo.toleranciaMax) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.toleranciaMax.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.toleranciaMax.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_maxTolerancia_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.toleranciaMin != elementoNuevo.toleranciaMin) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.toleranciaMin.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.toleranciaMin.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_minTolerancia_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.unidades != elementoNuevo.unidades) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.unidades.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.unidades.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_unidad_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.esFuncion != elementoNuevo.esFuncion) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.esFuncion.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.esFuncion.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_esfuncion_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.funcion != elementoNuevo.funcion) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.funcion.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.funcion.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_funcion_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        if (elemento.ruta != elementoNuevo.ruta) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.ruta.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.ruta.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_ruta_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.imagen != elementoNuevo.imagen) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.imagen.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.imagen.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_imagen_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
        if (elemento.critico == null || elemento.critico == undefined)
          elemento.critico = false;

        if (elemento.critico != elementoNuevo.critico) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.critico.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.critico.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_critico_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        /*  //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.porDefecto == null || elemento.porDefecto == undefined) 
           elemento.porDefecto = false;

         if (elemento.porDefecto != elementoNuevo.porDefecto) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.porDefecto.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.porDefecto.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_porDefecto_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.cantidad == null || elemento.cantidad == undefined) 
           elemento.cantidad = -1;

         if (elemento.cantidad != elementoNuevo.cantidad) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.cantidad.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.cantidad.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_cantidad_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.procesar == null || elemento.procesar == undefined) 
           elemento.procesar = -1;

         if (elemento.procesar != elementoNuevo.procesar) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.procesar.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.procesar.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_procesar_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         } 
         //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
         if (elemento.resto == null || elemento.resto == undefined) 
           elemento.resto = -1;

         if (elemento.resto != elementoNuevo.resto) {
           this.histCambiosAntes = this.histCambiosAntes.concat(elemento.resto.toString());
           this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.resto.toString());
           this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_resto_actualizado");
           this.histCambiosAntes = this.histCambiosAntes.concat(',');
           this.histCambiosDespues = this.histCambiosDespues.concat(',');
           this.histCambiosCampos = this.histCambiosCampos.concat(',');
         }  */

        //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
        /* if (elementoNuevo.descripcion == null || elementoNuevo.descripcion == undefined) 
          elementoNuevo.descripcion = "";

        if (elemento.descripcion != elementoNuevo.descripcion) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.descripcion.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.descripcion.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_"+elementoNuevo.nombre+"_descripcion_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }  */
        //Cuando comiencen a usarse ambos serán null al comienzo, por lo que los editamos
        if (elemento.tipoTolerancia == null || elemento.tipoTolerancia == undefined)
          elemento.tipoTolerancia = -1;
        if (elementoNuevo.tipoTolerancia == null || elementoNuevo.tipoTolerancia == undefined)
          elementoNuevo.tipoTolerancia = -1;

        if (elemento.tipoTolerancia != elementoNuevo.tipoTolerancia) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.tipoTolerancia.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.tipoTolerancia.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_tipoTolerancia_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
        if (elemento.tipoControl == null || elemento.tipoControl == undefined)
          elemento.tipoControl = { nombre: "", id: -1 };

        if (elementoNuevo.tipoControl == null || elementoNuevo.tipoControl == undefined)
          elementoNuevo.tipoControl = { nombre: "", id: -1 };

        if (elemento.tipoControl != elementoNuevo.tipoControl) {
          this.histCambiosAntes = this.histCambiosAntes.concat(elemento.tipoControl.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.tipoControl.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_tipoControl_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia1_cant == undefined || elemento.frecuencia1_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia1_cant;
        }
        if (elementoNuevo.frecuencia1_cant == undefined || elementoNuevo.frecuencia1_cant == null) {
          elementoNuevo.frecuencia1_cant = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia1_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia1_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia1__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia1_porcen1 == undefined || elemento.frecuencia1_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia1_porcen1;
        }

        var auxi;
        if (elemento.frecuencia1_porcen2 == undefined || elemento.frecuencia1_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.frecuencia1_porcen2;
        }

        if (elementoNuevo.frecuencia1_porcen1 == undefined || elementoNuevo.frecuencia1_porcen1 == null) {
          elementoNuevo.frecuencia1_porcen1 = "sinAsignar";
        }

        if (elementoNuevo.frecuencia1_porcen2 == undefined || elementoNuevo.frecuencia1_porcen2 == null) {
          elementoNuevo.frecuencia1_porcen2 = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia1_porcen1 || auxi != elementoNuevo.frecuencia1_porcen2) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia1_porcen1.toString() + "/" + elementoNuevo.frecuencia1_porcen1.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia1__porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia2_cant == undefined || elemento.frecuencia2_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia2_cant;
        }

        if (elementoNuevo.frecuencia2_cant == undefined || elementoNuevo.frecuencia2_cant == null) {
          elementoNuevo.frecuencia2_cant = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia2_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia2_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia2__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia2_porcen1 == undefined || elemento.frecuencia2_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia2_porcen1;
        }

        var auxi;
        if (elemento.frecuencia2_porcen2 == undefined || elemento.frecuencia2_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.frecuencia2_porcen2;
        }

        if (elementoNuevo.frecuencia2_porcen1 == undefined || elementoNuevo.frecuencia2_porcen1 == null) {
          elementoNuevo.frecuencia2_porcen1 = "sinAsignar";
        }

        if (elementoNuevo.frecuencia2_porcen2 == undefined || elementoNuevo.frecuencia2_porcen2 == null) {
          elementoNuevo.frecuencia2_porcen2 = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia2_porcen1 || auxi != elementoNuevo.frecuencia2_porcen2) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia2_porcen1.toString() + "/" + elementoNuevo.frecuencia2_porcen2.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia2__porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia3_cant == undefined || elemento.frecuencia3_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia3_cant;
        }

        if (elementoNuevo.frecuencia3_cant == undefined || elementoNuevo.frecuencia3_cant == null) {
          elementoNuevo.frecuencia3_cant = "sinAsignar";
        }


        if (auxi2 != elementoNuevo.frecuencia3_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia3_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia3__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia3_porcen1 == undefined || elemento.frecuencia3_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia3_porcen1;
        }

        var auxi;
        if (elemento.frecuencia3_porcen2 == undefined || elemento.frecuencia3_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.frecuencia3_porcen2;
        }

        if (elementoNuevo.frecuencia3_porcen1 == undefined || elementoNuevo.frecuencia3_porcen1 == null) {
          elementoNuevo.frecuencia3_porcen1 = "sinAsignar";
        }
        if (elementoNuevo.frecuencia3_porcen2 == undefined || elementoNuevo.frecuencia3_porcen2 == null) {
          elementoNuevo.frecuencia3_porcen2 = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia3_porcen1 || auxi != elementoNuevo.frecuencia3_porcen2) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia3_porcen1.toString() + "/" + elementoNuevo.frecuencia3_porcen2.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia3__porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia4_cant == undefined || elemento.frecuencia4_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia4_cant;
        }

        if (elementoNuevo.frecuencia4_cant == undefined || elementoNuevo.frecuencia4_cant == null) {
          elementoNuevo.frecuencia4_cant = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia4_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia4_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia4__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia4_porcen1 == undefined || elemento.frecuencia4_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia4_porcen1;
        }

        var auxi;
        if (elemento.frecuencia4_porcen2 == undefined || elemento.frecuencia4_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.frecuencia4_porcen2;
        }

        if (elementoNuevo.frecuencia4_porcen1 == undefined || elementoNuevo.frecuencia4_porcen1 == null) {
          elementoNuevo.frecuencia4_porcen1 = "sinAsignar";
        }

        if (elementoNuevo.frecuencia4_porcen2 == undefined || elementoNuevo.frecuencia4_porcen2 == null) {
          elementoNuevo.frecuencia4_porcen2 = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia4_porcen1 || auxi != elementoNuevo.frecuencia4_porcen2) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia4_porcen1.toString() + "/" + elementoNuevo.frecuencia4_porcen2.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia4__porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia5_cant == undefined || elemento.frecuencia5_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia5_cant;
        }

        if (elementoNuevo.frecuencia5_cant == undefined || elementoNuevo.frecuencia5_cant == null) {
          elementoNuevo.frecuencia5_cant = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.frecuencia5_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia5_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia5__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.frecuencia5_porcen1 == undefined || elemento.frecuencia5_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.frecuencia5_porcen1;
        }

        var auxi;
        if (elemento.frecuencia5_porcen2 == undefined || elemento.frecuencia5_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.frecuencia5_porcen2;
        }

        if (elementoNuevo.frecuencia5_porcen1 == undefined || elementoNuevo.frecuencia5_porcen1 == null) {
          elementoNuevo.frecuencia5_porcen1 = "sinAsignar";
        }

        if (elementoNuevo.frecuencia5_porcen2 == undefined || elementoNuevo.frecuencia5_porcen2 == null) {
          elementoNuevo.frecuencia5_porcen2 = "sinAsignar";
        }

        if ((auxi2 != elementoNuevo.frecuencia5_porcen1) || (auxi != elementoNuevo.frecuencia5_porcen2)) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.frecuencia5_porcen1.toString() + "/" + elementoNuevo.frecuencia5_porcen2.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_frecuencia5__porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.resto_cant == undefined || elemento.resto_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.resto_cant;
        }

        if (elementoNuevo.resto_cant == undefined || elementoNuevo.resto_cant == null) {
          elementoNuevo.resto_cant = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.resto_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.resto_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_resto__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.resto_porcen1 == undefined || elemento.resto_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.resto_porcen1;
        }

        var auxi;
        if (elemento.resto_porcen2 == undefined || elemento.resto_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.resto_porcen2;
        }

        if (elementoNuevo.resto_porcen1 == undefined || elementoNuevo.resto_porcen1 == null) {
          elementoNuevo.resto_porcen1 = "sinAsignar";
        }

        if (elementoNuevo.resto_porcen2 == undefined || elementoNuevo.resto_porcen2 == null) {
          elementoNuevo.resto_porcen2 = "sinAsignar";
        }

        if ((auxi2 != elementoNuevo.resto_porcen1) || (auxi != elementoNuevo.resto_porcen2)) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.resto_porcen1.toString() + "/" + elementoNuevo.resto_porcen2.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_resto_porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.cambioOperario_cant == undefined || elemento.cambioOperario_cant == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.cambioOperario_cant;
        }

        if (elementoNuevo.cambioOperario_cant == undefined || elementoNuevo.cambioOperario_cant == null) {
          elementoNuevo.cambioOperario_cant = "sinAsignar";
        }

        if (auxi2 != elementoNuevo.cambioOperario_cant) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.cambioOperario_cant.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_cambioCritico__cantidad2_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }

        var auxi2;
        if (elemento.cambioOperario_porcen1 == undefined || elemento.cambioOperario_porcen1 == null) {
          auxi2 = "sinAsignar";
        } else {
          auxi2 = elemento.cambioOperario_porcen1;
        }

        var auxi;
        if (elemento.cambioOperario_porcen2 == undefined || elemento.cambioOperario_porcen2 == null) {
          auxi = "sinAsignar";
        } else {
          auxi = elemento.cambioOperario_porcen2;
        }

        if (elementoNuevo.cambioOperario_porcen1 == undefined || elementoNuevo.cambioOperario_porcen1 == null) {
          elementoNuevo.cambioOperario_porcen1 = "sinAsignar";
        }

        if (elementoNuevo.cambioOperario_porcen2 == undefined || elementoNuevo.cambioOperario_porcen2 == null) {
          elementoNuevo.cambioOperario_porcen2 = "sinAsignar";
        }

        if ((auxi2 != elementoNuevo.cambioOperario_porcen1) || (auxi != elementoNuevo.cambioOperario_porcen2)) {
          this.histCambiosAntes = this.histCambiosAntes.concat(auxi2.toString() + "/" + auxi.toString());
          this.histCambiosDespues = this.histCambiosDespues.concat(elementoNuevo.cambioOperario_porcen1.toString() + "/" + elementoNuevo.cambioOperario_porcen2.toString());
          this.histCambiosCampos = this.histCambiosCampos.concat("valor_" + elementoNuevo.nombre + "_cambioCritico_porcentaje_actualizado");
          this.histCambiosAntes = this.histCambiosAntes.concat(',');
          this.histCambiosDespues = this.histCambiosDespues.concat(',');
          this.histCambiosCampos = this.histCambiosCampos.concat(',');
        }
      });
    }

    var filename = "";
    this.form.value.tipooperacion = this.tipooperacion;
    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      try {
        var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
        filename = this.form.value.archivo[0].name;
      } catch (error) {
        var archivoBase64: any = "";
      }

    } else {
      var archivoBase64: any = "";
    }
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.id = this.idOperacion;
    this.form.value.idRuta = this.idRuta;
    this.form.value.calidad = this.calidadActivo;

    //Necesitamos hacer estos cambios aquí por el historico de cambios
    if (this.herramientas.length > 0) {
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement = {
        angulo: 0.1,
        coste: 10.1,
        //descripcion: "",
        diametro: 15.1,
        errorTiempoEstimado: false,
        errorTiempos: false,
        fiabilidad: 0.1,
        fiabilidadConsumible: 20.1,
        id: 5,
        idConsumible: 8,
        idConsumibles_fabricantes: 12,
        idConsumibles_tipos: 2,
        idERP: "",
        idNuevo: 5,
        idOperacion: 2029,
        longitud: 10.1,
        nombre: "Broca 15",
        numContenido: 5,
        orden: 1,
        porcenUso: 0.1,
        porcentajeUso: 0.1,
        salto: 0.1,
        tiempoUso: 480,
        tieneNserie: true,
        vidaUtil: 3600,
        vc: 0.0,
        f: 0.0,
        ap_ini: 0.0,
        d_min: 0.0,
        ap: 0.0
      }

      this.herramientas.unshift(auxiElement); //Lo agregamos al inicio del array 
    }

    if(this.programas.length>0){
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement3 = {
        version: 1,
        fecha: '14/11/2023',
        tipo: 1,
        pieza: 'PIEZA 1',
        operacion: '',
        cliente: '',
        of: 234,
        operario: 'Fran Perez',
        maquina: 'Reductora',
        tmedio: 535,
        mejort: 746,
        cantidad: 3,
        percenok: 65.3
      }
      this.programas.unshift(auxiElement3); //Lo agregamos al inicio del array 
    }

    var valoresNoCompletados = false;
    if (this.valoresFinales.length > 0) {
      for (var n = 0; n < this.valoresFinales.length; n++) {
        var element = this.valoresFinales[n];
        if (element.tipoTolerancia == -1) {
          element.tipoTolerancia = { nombre: '', id: -1 };
        }
        if (element.tipoControl == -1) {
          element.tipoControl = { nombre: '', id: -1 };
        }
        //Y ahora comprobamos los obligatorios
        if (element.id != -1 && ((element.nombre == null || element.nombre == "") || (element.tipoControl != undefined && element.tipoControl.id == -1) ||
          (element.datosTolerancia.length > 0 &&
            (element.tipoTolerancia != undefined && element.tipoTolerancia.id == -1)))) {
          valoresNoCompletados = true;
          //break;
        }
        if (element.funcion != null && element.funcion != "" && element.funcion != undefined) {
          element.esFuncion = true;
        } else {
          element.esFuncion = false;
        }
        //Y corregimos los sinAsignar
        if (element.frecuencia1_cant == "sinAsignar")
          element.frecuencia1_cant = null;
        if (element.frecuencia1_porcen1 == "sinAsignar")
          element.frecuencia1_porcen1 = null;
        if (element.frecuencia1_porcen2 == "sinAsignar")
          element.frecuencia1_porcen2 = null;

        if (element.frecuencia2_cant == "sinAsignar")
          element.frecuencia2_cant = null;
        if (element.frecuencia2_porcen1 == "sinAsignar")
          element.frecuencia2_porcen1 = null;
        if (element.frecuencia2_porcen2 == "sinAsignar")
          element.frecuencia2_porcen2 = null;

        if (element.frecuencia3_cant == "sinAsignar")
          element.frecuencia3_cant = null;
        if (element.frecuencia3_porcen1 == "sinAsignar")
          element.frecuencia3_porcen1 = null;
        if (element.frecuencia3_porcen2 == "sinAsignar")
          element.frecuencia3_porcen2 = null;

        if (element.frecuencia4_cant == "sinAsignar")
          element.frecuencia4_cant = null;
        if (element.frecuencia4_porcen1 == "sinAsignar")
          element.frecuencia4_porcen1 = null;
        if (element.frecuencia4_porcen2 == "sinAsignar")
          element.frecuencia4_porcen2 = null;

        if (element.frecuencia5_cant == "sinAsignar")
          element.frecuencia5_cant = null;
        if (element.frecuencia5_porcen1 == "sinAsignar")
          element.frecuencia5_porcen1 = null;
        if (element.frecuencia5_porcen2 == "sinAsignar")
          element.frecuencia5_porcen2 = null;

        if (element.resto_cant == "sinAsignar")
          element.resto_cant = null;
        if (element.resto_porcen1 == "sinAsignar")
          element.resto_porcen1 = null;
        if (element.resto_porcen2 == "sinAsignar")
          element.resto_porcen2 = null;

        if (element.cambioOperario_cant == "sinAsignar")
          element.cambioOperario_cant = null;
        if (element.cambioOperario_porcen1 == "sinAsignar")
          element.cambioOperario_porcen1 = null;
        if (element.cambioOperario_porcen2 == "sinAsignar")
          element.cambioOperario_porcen2 = null;

      }
      if (valoresNoCompletados) {
        this.alertService.error(this.translate.instant('valoresObligatorios'));
        this.modalReferenceloading.close();
        return;
      }
      //Necesitamos crear un valor auxiliar que insertaremos al inicio y que especificará qué datos son decimals, igual que sucede en herramientas
      var auxiElement2 = { //le damos valores aleatorios, pues no lo vamos a procesar
        activo: false,
        conLimite: false,
        eje: "A",
        esFuncion: true,
        funcion: "",
        id: -1,
        idNuevo: -1,
        idOperacion: 2029,
        imagen: false,
        maximo: 0.1,
        minimo: 0.1,
        nombre: "",
        orden: 1,
        //referencia: "Ref",
        referenciada: "false",
        toleranciaMax: 0.1,
        toleranciaMin: 0.1,
        unidades: "mm",
        varmaquina: "",
        //visible: true,
        vnominal: 0.1,
        critico: false,
        porDefecto: true,
        cantidad: 0,
        procesar: 0,
        resto: 0,
        //descripcion: "des",
        tipoTolerancia: { nombre: "", id: -1 },
        tipoControl: { nombre: "", id: -1 },
        datosTolerancia: [],
        frecuencia1_cant: 1,
        frecuencia1_porcen1: 1,
        frecuencia1_porcen2: 1,
        frecuencia2_cant: 1,
        frecuencia2_porcen1: 1,
        frecuencia2_porcen2: 1,
        frecuencia3_cant: 1,
        frecuencia3_porcen1: 1,
        frecuencia3_porcen2: 1,
        frecuencia4_cant: 1,
        frecuencia4_porcen1: 1,
        frecuencia4_porcen2: 1,
        frecuencia5_cant: 1,
        frecuencia5_porcen1: 1,
        frecuencia5_porcen2: 1,
        resto_cant: 1,
        resto_porcen1: 1,
        resto_porcen2: 1,
        cambioOperario_cant: 1,
        cambioOperario_porcen1: 1,
        cambioOperario_porcen2: 1,
        ruta: "",
        idUtillaje: 1
      }
      this.valoresFinales.unshift(auxiElement2); //Lo agregamos al inicio del array
      this.valoresFinales.forEach(element => {
        element.datosTolerancia = ""; //Así evitamos errores al guardar, ya que no necesitamos este elemento al guardar
      });
    }

    if (this.ddControladorCotasNoCriticasChanged) this.form.value.idControlarCotasNoCriticas = this.form.value.idControlarCotasNoCriticas.id;
    if (this.ddControladorCotasCriticasChanged) this.form.value.idControlarCotasCriticas = this.form.value.idControlarCotasCriticas.id;
    if (this.ddObservacionPiezasChanged) this.form.value.idObservacionPiezas = this.form.value.idObservacionPiezas.id;

    this.form.value.tienePrograma = this.tienePrograma;
    this.form.value.nPrograma = this.nPrograma;
    this.form.value.rutaProgramaEsDir = this.rutaProgramaEsDir;
    this.form.value.rutaPrograma = this.rutaPrograma;

    this.operacionesService.update(this.form.value, filename, archivoBase64, idsmaquinas, estadosmaquinas, this.histCambiosAntes, this.histCambiosDespues, this.histCambiosCampos, fecha)
      .subscribe((result) => {
        this.modalReferenceloading.close();

        this.programas.forEach(element => {
          console.log(element.tmedio);
          if (element.tmedio != null){
            console.log(element.tmedio.toString().includes(':'));
            if(element.tmedio.toString().includes(':')){
              element.tmedio = this.HMSToSeconds(element.tmedio);
              console.log(element.tmedio);
            }
          }
          if (element.tmejor != null) {
            if(element.mejort.toString().includes(':')){
              element.mejort = this.HMSToSeconds(element.mejort);
              console.log(element.mejort);
            }
          }
        });

        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          console.log("update herramientas", this.herramientas);
          this.operacionesService.update_herramientas(this.herramientas).subscribe();
          console.table(this.valoresFinales);
          this.operacionesService.update_valores(this.valoresFinales).subscribe();
          console.log("update programas", this.programas);
          this.operacionesService.update_programas(this.programas).subscribe();
          this.Atras();
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }
        this.histCambiosAntes = "";
        this.histCambiosDespues = "";
        this.histCambiosCampos = "";

      });

    if (this.form.value.archivo)
      this.form.value.archivo = null;
  }

  async nuevo() {
    var fecha = null;
    var idsmaquinas = "";
    var estadosmaquinas = "";
    this.estadoMaquinas.forEach((element, index) => {
      if (element != "" && element != 1) {
        idsmaquinas = idsmaquinas + "," + this.idMaquinas[index];
        estadosmaquinas = estadosmaquinas + "," + element;
      }
    });
    this.form.value.tipooperacion = this.tipooperacion;
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }

    this.form.value.calidad = this.calidadActivo;
    if (this.ddControladorCotasNoCriticasChanged) this.form.value.idControlarCotasNoCriticas = this.form.value.idControlarCotasNoCriticas.id;
    if (this.ddControladorCotasCriticasChanged) this.form.value.idControlarCotasCriticas = this.form.value.idControlarCotasCriticas.id;
    if (this.ddObservacionPiezasChanged) this.form.value.idObservacionPiezas = this.form.value.idObservacionPiezas.id;

    //Necesitamos hacer estos cambios aquí por el historico de cambios
    if (this.herramientas.length > 0) {
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement = {
        angulo: 0,
        coste: 10,
        //descripcion: "",
        diametro: 15,
        errorTiempoEstimado: false,
        errorTiempos: false,
        fiabilidad: 0.1,
        fiabilidadConsumible: 20,
        id: 5,
        idConsumible: 8,
        idConsumibles_fabricantes: 12,
        idConsumibles_tipos: 2,
        idERP: "",
        idNuevo: 5,
        idOperacion: 2029,
        longitud: 10,
        nombre: "Broca 15",
        numContenido: 5,
        orden: 1,
        porcenUso: 0.1,
        porcentajeUso: 0.1,
        salto: 0,
        tiempoUso: 480,
        tieneNserie: true,
        vidaUtil: 3600,
        vc: 0.0,
        f: 0.0,
        ap_ini: 0.0,
        d_min: 0.0,
        ap: 0.0
      }
      this.herramientas.unshift(auxiElement); //Lo agregamos al inicio del array 
    }

    if(this.programas.length>0){
      //Agregamos un dato al inicio del array que luego no se usará, solo es para al parsearlo a json que sepa qué formato van a tener los datos
      //(que no tome los decimals por ints)
      //Como no se puede crear una copia del elemento del array, pues esto modifica también el propio elemento aparte del copiado,
      //lo creamos a mano
      var auxiElement3 = {
        nombrePrograma: "0123",
        id: 1,
        version: 1,
        fechaVersion : '2023-11-14 13:33:21.973',
        tipo: 1,
        fechaIni : '2023-11-14 13:33:21.973',
        fechaFin : '2023-11-14 13:33:21.973',
        treal : 433.16,
        nombreMaquina : 'TORNO HEIDENHAIN',
        operario: 'XABI PRIETO',
        operarioSiglas: 'XP',
        operarioColor: '#fa5600',
        cliente: 'ULMA',
        numeroOF: 12345,
        pieza: 'PIEZA 1',
        parte: '',
        nombreOperacion : 'OP-1',
        ordenVersiones: 2,
        tMedio: 433.16,
        tMejor: 388.96,
        cantidad: 20,
        rutaPrograma: 'C:\IEEP\IEEP\IEEP',
        identificador : 'X0243&327&42',
        observaciones: '-',
        idControl: 1,
        idPiezaPredefinida: 90,
        idOperacion: 400,
        tiempoEstimadoEjecucion: 1000,
        porcenCalidad: 90.4
        // errorTiempoEstimado: false,
        // errorTiempos: false,
      }
      this.programas.unshift(auxiElement3); //Lo agregamos al inicio del array 
    }

    var valoresNoCompletados = false;
    if (this.valoresFinales.length > 0) {
      for (var n = 0; n < this.valoresFinales.length; n++) {
        var element = this.valoresFinales[n];
        //Y ahora comprobamos los obligatorios
        if ((element.nombre == null || element.nombre == "") || (element.tipoControl.id == -1) ||
          (element.datosTolerancia.length > 0 && element.tipoTolerancia.id == -1 && element.id != -1)) {
          valoresNoCompletados = true;
          //break;
        }
        if (element.funcion != null && element.funcion != "" && element.funcion != undefined) {
          element.esFuncion = true;
        }
        else {
          element.esFuncion = false;
        }

        //Y corregimos los sinAsignar
        if (element.frecuencia1_cant == "sinAsignar")
          element.frecuencia1_cant = null;
        if (element.frecuencia1_porcen1 == "sinAsignar")
          element.frecuencia1_porcen1 = null;
        if (element.frecuencia1_porcen2 == "sinAsignar")
          element.frecuencia1_porcen2 = null;

        if (element.frecuencia2_cant == "sinAsignar")
          element.frecuencia2_cant = null;
        if (element.frecuencia2_porcen1 == "sinAsignar")
          element.frecuencia2_porcen1 = null;
        if (element.frecuencia2_porcen2 == "sinAsignar")
          element.frecuencia2_porcen2 = null;

        if (element.frecuencia3_cant == "sinAsignar")
          element.frecuencia3_cant = null;
        if (element.frecuencia3_porcen1 == "sinAsignar")
          element.frecuencia3_porcen1 = null;
        if (element.frecuencia3_porcen2 == "sinAsignar")
          element.frecuencia3_porcen2 = null;

        if (element.frecuencia4_cant == "sinAsignar")
          element.frecuencia4_cant = null;
        if (element.frecuencia4_porcen1 == "sinAsignar")
          element.frecuencia4_porcen1 = null;
        if (element.frecuencia4_porcen2 == "sinAsignar")
          element.frecuencia4_porcen2 = null;

        if (element.frecuencia5_cant == "sinAsignar")
          element.frecuencia5_cant = null;
        if (element.frecuencia5_porcen1 == "sinAsignar")
          element.frecuencia5_porcen1 = null;
        if (element.frecuencia5_porcen2 == "sinAsignar")
          element.frecuencia5_porcen2 = null;

        if (element.resto_cant == "sinAsignar")
          element.resto_cant = null;
        if (element.resto_porcen1 == "sinAsignar")
          element.resto_porcen1 = null;
        if (element.resto_porcen2 == "sinAsignar")
          element.resto_porcen2 = null;

        if (element.cambioOperario_cant == "sinAsignar")
          element.cambioOperario_cant = null;
        if (element.cambioOperario_porcen1 == "sinAsignar")
          element.cambioOperario_porcen1 = null;
        if (element.cambioOperario_porcen2 == "sinAsignar")
          element.cambioOperario_porcen2 = null;

      }
      if (valoresNoCompletados) {
        this.alertService.error(this.translate.instant('valoresObligatorios'));
        this.modalReferenceloading.close();
        return;
      }
      //Necesitamos crear un valor auxiliar que insertaremos al inicio y que especificará qué datos son decimals, igual que sucede en herramientas
      var auxiElement2 = { //le damos valores aleatorios, pues no lo vamos a procesar
        activo: false,
        conLimite: false,
        eje: "A",
        esFuncion: true,
        funcion: "",
        id: -1,
        idNuevo: -1,
        idOperacion: 2029,
        imagen: false,
        maximo: 0.1,
        minimo: 0.1,
        nombre: "",
        orden: 1,
        //referencia: "Ref",
        referenciada: "false",
        toleranciaMax: 0.1,
        toleranciaMin: 0.1,
        unidades: "mm",
        varmaquina: "",
        //visible: true,
        vnominal: 0.1,
        critico: false,
        porDefecto: true,
        cantidad: 0,
        procesar: 0,
        resto: 0,
        //descripcion: "des",
        tipoTolerancia: -1,
        tipoControl: { nombre: "", id: -1 },
        datosTolerancia: [],
        frecuencia1_cant: 1,
        frecuencia1_porcen1: 1,
        frecuencia1_porcen2: 1,
        frecuencia2_cant: 1,
        frecuencia2_porcen1: 1,
        frecuencia2_porcen2: 1,
        frecuencia3_cant: 1,
        frecuencia3_porcen1: 1,
        frecuencia3_porcen2: 1,
        frecuencia4_cant: 1,
        frecuencia4_porcen1: 1,
        frecuencia4_porcen2: 1,
        frecuencia5_cant: 1,
        frecuencia5_porcen1: 1,
        frecuencia5_porcen2: 1,
        resto_cant: 1,
        resto_porcen1: 1,
        resto_porcen2: 1,
        cambioOperario_cant: 1,
        cambioOperario_porcen1: 1,
        cambioOperario_porcen2: 1,
        ruta: "",
        idUtillaje: 1
      }
      this.valoresFinales.unshift(auxiElement2); //Lo agregamos al inicio del array
      this.valoresFinales.forEach(element => {
        element.datosTolerancia = ""; //Así evitamos errores al guardar, ya que no necesitamos este elemento al guardar
      });
    }

    this.operacionesService.create(this.form.value, filename, archivoBase64, fecha, idsmaquinas, estadosmaquinas).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.valoresFinales.forEach(element => {
          element.idOperacion = result.id;
        });
        this.herramientas.forEach(element => {
          element.idOperacion = result.id;
        });
        this.programas.forEach(programa => {
          programa.idOperacion = result.id;
        })
        this.operacionesService.update_herramientas(this.herramientas).subscribe();
        this.operacionesService.update_programas(this.programas).subscribe();
        console.table(this.valoresFinales);
        this.operacionesService.update_valores(this.valoresFinales).subscribe();
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      } else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }

  onClickDuplicarOperacion(contentloading, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.form.value.id = this.idOperacion;
    this.form.value.idRuta = this.idRuta;
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.operacionesService.duplicar(this.form.value).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }

  Atras() {
    if (this.idVista == "1") {
      this.router.navigate(['piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte]);
    } else if (this.idVista == "0") {
      this.router.navigate(['rutas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta]);
    }
  }

  public onContinuoValueChange(value: any): void {
    this.continuo = value;
  }
  public onIneficenciaValueChange(value: any): void {
    this.ineficencia = value;
  }
  public onCambiomaquinaValueChange(value: any): void {
    this.cambiomaquina = value;
  }

  private Eliminar(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }
    this.operacionesService.delete({ idoperacion: this.idOperacion }).subscribe(

      (data) => {
        this.modalReferenceloading.close();


        if (data.error == false) {
          this.operacionesService.updateOrdenes(this.idRuta).subscribe();
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });

        }
        else {

          this.alertService.success(this.translate.instant("error"), { keepAfterRouteChange: true });
        }
        this.Atras();


      }

    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }


  cambioTipoOperacionCalidad(cambio) {
    if (cambio == undefined) {
      //Si no encaja con ninguno, ponemos el que estuviese antes
      //this.tipooperacion no se cambia porque se mantiene 
      this.form.controls['tipooperacion'].setValue(this.tipooperacionCalidadLista.filter(x => x.id == this.tipooperacion)[0]);
    } else {
      if (cambio.id == 1) this.tipooperacion = 1;
      else if (cambio.id == 2) this.tipooperacion = 2;
      else if (cambio.id == 3) this.tipooperacion = 3;
      else if (cambio.id == 4) this.tipooperacion = 4;
    }

  }

  // public onInformativoValueChange(value: any): void {
  //   if (value)
  //     this.tipooperacion = 1;
  //   else {
  //     this.tipooperacion = 5; //no hay ninguna seleccionada, así que ponemos un 5 que no es el de ninguna
  //   }
  //   //this.valores = []; //update funtziora eraman dut
  //   //this.valoresFinales = []; //update funtziora eraman dut
  // }

  // public onValorValueChange(value: any): void {
  //   if (value)
  //     this.tipooperacion = 2;
  //   else {
  //     this.tipooperacion = 5; //no hay ninguna seleccionada, así que ponemos un 5 que no es el de ninguna
  //   }
  //   //this.valores = []; //update funtziora eraman dut
  //   //this.valoresFinales = []; //update funtziora eraman dut
  // }

  // public onConfirmacionValueChange(value: any): void {
  //   if (value)
  //     this.tipooperacion = 3;
  //   else {
  //     this.tipooperacion = 5; //no hay ninguna seleccionada, así que ponemos un 5 que no es el de ninguna
  //   }
  //   //this.valores = []; //update funtziora eraman dut
  //   //this.valoresFinales = []; //update funtziora eraman dut
  // }

  // public onValormaquinaValueChange(value: any): void {
  //   if (value)
  //     this.tipooperacion = 4;
  //   else {
  //     this.tipooperacion = 5; //no hay ninguna seleccionada, así que ponemos un 5 que no es el de ninguna
  //   }
  //   //this.valores = []; //update funtziora eraman dut
  //   //this.valoresFinales = []; //update funtziora eraman dut
  // }

  public onCalidadValueChange(value: any): void {
    if (this.calidadActivo) {
      this.calidadActivo = false;
    } else {
      this.calidadActivo = true;
      //Hay que marcar la configuracion
      this.configuracionService.get_configuracion_calidad().subscribe(result => {
        var dtConfiguracionCalidad = result[0];
        this.form.controls['resetearFrecuencia'].setValue(dtConfiguracionCalidad.resetearFrecuencia);
        this.form.controls['idControlarCotasNoCriticas'].setValue(dtConfiguracionCalidad.idControlarCotasNoCriticas);
        this.form.controls['idControlarCotasCriticas'].setValue(dtConfiguracionCalidad.idControlarCotasCriticas);
        this.form.controls['primerasPiezasNum'].setValue(dtConfiguracionCalidad.primerasPiezasNum);
        this.form.controls['idObservacionPiezas'].setValue(dtConfiguracionCalidad.idObservacionPiezas);
        this.form.controls['inputarSoloDecimales'].setValue(dtConfiguracionCalidad.inputarSoloDecimales);
        this.form.controls['mostrarValoresNoOKOperario'].setValue(dtConfiguracionCalidad.mostrarValoresNoOKOperario);
        this.ddControladorCotasCriticasChanged = true;
        this.ddControladorCotasNoCriticasChanged = true;
        this.ddObservacionPiezasChanged = true;
        this.form.controls['tipooperacion'].setValue(this.tipooperacionCalidadLista[0]);
        this.tipooperacion = 1; //Para que ponga una por defecto
      });
    }
    this.tabstrip.selectTab(0);
  }

  public myFiles = [];

  imageToShow: any;
  isImageLoading: boolean;



  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  public onRemove(ev): void {
    this.myFiles = [];
    this.imageToShow = "";
    this.logo = "";
  }
  //#endregion
  public async selectEventHandler(e: SelectEvent) {
    const that = this;
    var file = e.files[0];
    e.files.forEach((file) => {
      that.log(`File selected: ${file.name}`);

      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });

    this.imagePreviews.forEach(element => { that.imageToShow = element.src; });
    this.imagePreviews = [];
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }
  //Herramientas
  /* public herramientas_onValueChange(value: any): void {
     this.herramientasComboSelected = "";
 
     for (let turno of value) {
       this.herramientasComboSelected += turno.id + ", ";
     }
 
     this.herramientasComboSelected = this.herramientasComboSelected.substring(0, this.herramientasComboSelected.length - 2);
 
     if (this.herramientasComboSelected == "") {
       this.herramientasComboSelected = "";
     }
 
   }*/

  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  corregirHoras(hora) {
    if (hora.length > 8) {
      return hora.replace(" ", '');
    } else {
      return hora.replace(' ', '0');
    }
  }

  selectionChange(e) {

    if (e == undefined || e.id == undefined) {
      this.isAddMode == true;
      return;
    }

    var id = e.id;

    var idparte = e.idparte;

    this.operacionesService.GetByIDSelectedCombo({ idoperacion: id }).subscribe((result) => {
      if (result.operacion[0].imagenBase64 != "" && result.operacion[0].imagenBase64 != null) {
        this.imageToShow = result.operacion[0].imagenBase64;
      }

      var retroceder_data = this.operacionesHermanas.find(x => x.id === result.operacion[0].no_Correcto_Retroceder_a);
      if (retroceder_data == undefined) retroceder_data = {};

      if (result.operacion[0].prioridad == null) {
        this.prioridad = 0;
      } else {
        this.prioridad = result.operacion[0].prioridad;
      }
      this.partido = false;
      this.fueraturno = false;
      this.fuerataller = false;
      this.cambiomaquina = false;

      this.ineficencia = false;
      this.continuo = false;
      this.tiempoCambio = 0;
      this.fechainilimite = null;
      this.fechafinlimite = null;
      // this.fechafinlimite.setMonth(this.fechafinlimite.getMonth() + 1);
      if (result.operacion[0].fechaIni != undefined)
        this.fechainilimite = new Date(result.operacion[0].fechaIni);
      if (result.operacion[0].fechaFin != undefined)
        this.fechafinlimite = new Date(result.operacion[0].fechaFin);
      this.nombreHisto = result.operacion[0].operacion;
      this.idErpHisto = result.operacion[0].idERP;
      this.ordenHistoric = result.operacion[0].orden;
      this.secuenciaHisto = result.operacion[0].secuencia;
      this.subordenHistoric = result.operacion[0].subOrden;
      if (result.operacion[0].limitado != null) {
        this.limitado = (result.operacion[0].limitado == 1);
      }
      this.tipooperacion = result.operacion[0].idOperacion_Tipo;
      this.tipooperacionHistoric = this.tipooperacion;
      this.obligatoriaHistoric = result.operacion[0].obligatorio;

      this.piezasseguidasHistoric = result.operacion[0].operarVariasPiezasAlMismoTiempo;
      this.edicionHistoric = result.operacion[0].solicitar_edicion;
      this.loteHistoric = result.operacion[0].solicitar_Lote;
      this.nSerieHistoric = result.operacion[0].solicitar_N_Serie;
      this.coladaHistoric = result.operacion[0].solicitar_Colada;
      this.terminadoHistoric = result.operacion[0].terminado;
      this.calidadHistoric = result.operacion[0].calidad;
      this.ladoHisto = result.operacion[0].lado;
      this.tiempoEstimadoHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS);
      this.tiempoEstimadoPreparacionHisto = this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS);
      this.costeHisto = result.operacion[0].coste;
      this.cantidadHisto = result.operacion[0].cantidad;
      this.realizadosHisto = result.operacion[0].hechos;
      this.imagenHisto = result.operacion[0].imagen;
      this.observacionesHisto = result.operacion[0].observaciones;
      this.calidadCantidadHisto = result.operacion[0].calidadCantidad;
      this.calidadProcesarHisto = result.operacion[0].calidadProcesar;
      this.calidadRestoHisto = result.operacion[0].calidadResto;
      this.calidadObservacionesHisto = result.operacion[0].calidadObservaciones;
      this.confirmacionMensajeHisto = result.operacion[0].no_Correcto_Mensaje;
      if (retroceder_data?.nombre != undefined)
        this.confirmacionRetrocederHisto = retroceder_data.nombre;
      else {
        this.confirmacionRetrocederHisto = "sinAsignar";
      }
      if (this.fechainilimite != null && this.fechainilimite != undefined)
        this.limitesFechaIniHisto = this.fechainilimite.toString();
      else
        this.limitesFechaIniHisto = "sinAsignar";
      if (this.fechafinlimite != null && this.fechafinlimite != undefined)
        this.limitesFechaFinHisto = this.fechafinlimite.toString();
      else
        this.limitesFechaFinHisto = "sinAsignar";
      this.limitesProcesaFueraTallerHisto = result.operacion[0].subcontratado;
      this.limitesProcesarFueraTurnoHisto = result.operacion[0].planificarFueraDeTurno;
      this.limitesInterrumpirHisto = result.operacion[0].cortarAlFinalizarTurno;

      var tipo_operacion = this.tipoOperacionLista.find(x => x.id === result.operacion[0].tipo_operacion);
      if (tipo_operacion == undefined) tipo_operacion = this.tipoOperacionLista[0];

      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        idruta: this.idRuta,
        idparte: this.idParte,
        idpieza: this.idPieza,
        idof: this.idOF,
        idReferencia: [result.operacion[0].operacion,],
        nombre: [result.operacion[0].operacion, Validators.required],
        tipo_operacion: [tipo_operacion,],
        tipo_operacion_id: [tipo_operacion.id,],
        orden: [result.operacion[0].orden,],
        lado: [result.operacion[0].lado,],
        suborden: [result.operacion[0].subOrden,],
        tiempoestimado: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        tiempoEstimadoPreparacion: [this.myFunctions.secondsTo_HH_MM_SS(result.operacion[0].tiempoEstimadoPreparacionS), Validators.pattern(/^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$/)],
        coste: [result.operacion[0].coste, Validators.required],
        observaciones: [result.operacion[0].observaciones,],
        iderp: [result.operacion[0].idERP,],
        // limitado: [result.operacion[0].limitado, Validators.required],
        imagen: [result.operacion[0].imagen],
        informativo: ['',],
        valor: ['',],
        confirmacion: ['',],
        solicitar: ['',],
        cantidad: [result.operacion[0].cantidad,],
        hechos: [result.operacion[0].hechos,],
        calidadCantidad: [result.operacion[0].calidadCantidad,],
        procesar: [result.operacion[0].calidadProcesar,],
        resto: [result.operacion[0].calidadResto,],
        mensaje: ['',],
        retroceder: ['',],
        escandallo: [result.operacion[0].idBOM,],
        cantidadescandallo: [result.operacion[0].cantidadBOM,],
        solicitarNSerie: [result.operacion[0].solicitar_N_Serie,],
        solicitarColada: [result.operacion[0].solicitar_Colada,],
        terminado: [result.operacion[0].terminado,],
        solicitarLote: [result.operacion[0].solicitar_Lote,],
        solicitaredicion: [result.operacion[0].solicitar_edicion,],
        fueraturno: [result.operacion[0].planificarFueraDeTurno],
        fuerataller: [result.operacion[0].subcontratado,],
        cambiomaquina: [this.cambiomaquina,],
        tiempocambiomaquina: [this.tiempoCambio,],
        fechainicio: this.fechainilimite,
        fechafin: this.fechafinlimite,
        tiemposParadasEst: ['',],
        ineficencia: [this.ineficencia,],
        procesocontinuo: [result.operacion[0].cortarAlFinalizarTurno],
        prioridad: [this.prioridad,],
        tiempoentremaquinas: [this.tiempoCambio,],
        piezasseguidas: [result.operacion[0].operarVariasPiezasAlMismoTiempo,],
        obligatoria: [result.operacion[0].obligatorio,],
        partido: [this.partido,],
        continuo: [this.continuo,],
        tipooperacion: [result.operacion[0].idOperacion_Tipo],
        tipooperacionValor: [false,],
        tipooperacionConfirmacion: [false,],
        tipooperacionValormaquina: [false,],
        tipooperacionInformativo: [false,],
        archivo: new FormControl(''),
        //herramientas: new FormControl(this.selectedItems)
      }
      );
      this.idMaquinas = result.operacion[0].idsMaquinas.split(",");
      this.estadoMaquinas = result.operacion[0].prioridadesMaquinas.split(",");
      this.calidadActivo = result.operacion[0].calidad;
      //this.tienePrograma = result.operacion[0].calidad;
      if (this.calidadActivo) this.calidadActivo = (this.user.calidad > 0);

      this.tipooperacion = result.operacion[0].idOperacion_Tipo;

      this.tienePrograma = result.operacion[0].tienePrograma;
      this.nPrograma = result.operacion[0].nPrograma;
      this.rutaPrograma = result.operacion[0].rutaPrograma;
      this.rutaProgramaEsDir = result.operacion[0].rutaProgramaEsDir;

      this.valores = result.valores;
      this.operacionesService.getdatosControl().pipe().subscribe((resultControl) => {
        this.operacionesService.getdatosTolerancia().pipe().subscribe((resultTolerancia) => {
          this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
          this.datosTolerancia = resultTolerancia; //Aqui guardamos la general y luego las filtramos
          //Para solventar error seleccion
          if (this.valores) {
            this.valores.forEach(element => {
              if (element.tipoControl != -1)
                element.tipoControl = this.datosControl.filter(x => x.id == element.tipoControl)[0];
              else {
                element.tipoControl = { nombre: "", id: -1 };
              }
              if (element.tipoControl.id != -1) {
                element.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == element.tipoControl.id);
                if (element.datosTolerancia.length == 0) {
                  element.datosTolerancia = [];
                  element.tipoTolerancia = { nombre: "", id: -1 };
                } else {
                  element.tipoTolerancia = element.datosTolerancia.filter(x => x.id == element.tipoTolerancia)[0];
                }
              } else {
                element.datosTolerancia = [];
                element.tipoTolerancia = { nombre: "", id: -1 };
              }
              element.idNuevo = element.id;
              //element.visible= Boolean(element.visible);
            });
            this.valoresFinales = this.valores;
            this.valoresHisto = [];
            this.valores.forEach(val => this.valoresHisto.push(Object.assign({}, val))); //Hacemos una copia del array
          }
        });
      });


      this.escandallo = result.escandallo;
      var escandallo_selected;
      if (this.escandallo.find(x => x.id === this.form.value.escandallo))
        escandallo_selected = this.escandallo.find(x => x.id === this.form.value.escandallo).nombre;
      if (escandallo_selected == undefined) escandallo_selected = "sinAsignar";
      this.escandalloHisto = escandallo_selected;
      this.escandalloCantidadHisto = result.operacion[0].cantidadBOM;
      //this.limitado = (result.operacion[0].limitado == "True");
      //this.initMaquinas();
    }
    );

  }

  //Funciones herramientas
  agregarHerramienta(e) {
    e.preventDefault(); //prevent submit
    this.herramientasTotalesSeleccionadas = []; //quitamos las que estaban seleccionadas antes, por si ya hemos agregado alguna.
    //Cargar datos herramientas antes de desplegar el modal (se vuelven a cargar, aunque ya hubiese datos previamente por si acaso se ha insertado alguna nueva por detrás)
    this.consumiblesService.Get_consumibles_grid().subscribe((result: any) => {
      this.herramientasTotales = result;
    });
    console.log("bbbb", this.herramientasTotales);
    this.modalReferencepopupAddHerramienta = this.modalService.open(this.popupAddHerramienta, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    console.log("cccc", this.modalReferencepopupAddHerramienta);
  }

  eliminarHerramienta(event) {
    event.preventDefault(); //prevent submit
    var herramientas = this.herramientas.filter(x => this.herramientasSelected.includes(x.idNuevo));
    var herramientasFinales = []
    herramientas.forEach(element => {
      if (element.id != 0) {
        element.id = -1; //Era una insercion, ahora es eliminacion
        herramientasFinales.push(element);
      }

    });
    herramientas = this.herramientas.filter(x => !this.herramientasSelected.includes(x.idNuevo));
    herramientas.forEach(element => { //insertamos los que no fueron seleccionados
      herramientasFinales.push(element);
    });
    this.herramientas = herramientasFinales;
    this.herramientasFinales = this.herramientas.filter(x => x.id != -1);
    this.recargarGrid();
  }

  agregarHerramientaModal() {
    //Herramientas a agregar a la operacion, se agrega solo el id enlazado, así que con esto nos vale
    //en operacion deberíamos tener un atributo herramientas donde insertar los ids de las herramientas nuevas que se le han agregado
    if (this.herramientas == undefined) {
      this.herramientas = [];
    }
    this.herramientasTotalesSeleccionadas.forEach(element => {
      var maxId;
      if (this.herramientasFinales.length === 0)
        maxId = -1;
      else
        maxId = Math.max.apply(Math, this.herramientasFinales.map(value => value.idNuevo));
      var maxOrden;
      if (this.herramientasFinales.length === 0)
        maxOrden = -1;
      else
        maxOrden = Math.max.apply(Math, this.herramientasFinales.map(value => value.orden));
      //Primero se va a calcular el porcentaje que reste y el tiempo estimado, para asignarselo a la nueva herramienta
      var tiempoUsoTotal = 0.0;
      var porcentajeTotal = 0.0;
      this.herramientas.forEach(element => {
        tiempoUsoTotal += this.HMSToSeconds(element.tiempoUso);
        porcentajeTotal += element.porcenUso;
      });
      var herramienta = this.herramientasTotales.filter(x => x.id == element)[0];
      herramienta.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(this.HMSToSeconds(this.form.value.tiempoestimado) - tiempoUsoTotal); //Aquí hay que asignarle el que reste
      herramienta.porcenUso = 100.0 - porcentajeTotal; //Aquí hay que asignarle el que reste
      herramienta.idConsumible = herramienta.id;
      herramienta.id = 0; //porque es una inserción
      herramienta.idOperacion = Number(this.idOperacion);
      herramienta.orden = maxOrden + 1;
      herramienta.idNuevo = maxId + 1;
      herramienta.errorTiempos = false; //al principio el error siempre será falso
      console.log(herramienta);
      this.herramientas.push(herramienta);
    });
    this.herramientasFinales = this.herramientas.filter(x => x.id != -1);
    this.recargarGrid();
    this.modalReferencepopupAddHerramienta.close();
  }

  cancelarSeleccionHerramienta() {
    this.modalReferencepopupAddHerramienta.close();
    this.herramientasTotalesSeleccionadas = []; //lo vaciamos porque no se ha aceptado
  }

  updateDatosHerramienta(dataItem, cambio, porcen) {
    //aquí hay que revisar que si se cambia el tiempoUso también se debe cambiar el de los demás y lo mismo con el porcentaje
    //también comprobar que no se pasen del tope entre todos los objetos
    if (porcen == 0) {//ha cambiado el tiempo
      var pattern = new RegExp('^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$');
      var result = pattern.test(cambio);
      if (result == false) {
        dataItem.errorTiempoEstimado = true;
      }
      else {
        //No hay error en el formato
        var nuevoPorcen = 100.0 * (this.HMSToSeconds(cambio) / this.HMSToSeconds(this.form.value.tiempoestimado));
        dataItem.porcenUso = nuevoPorcen;
        dataItem.errorTiempoEstimado = false;
        //Revisamos tras agregar los cambios que ninguno se pase
        var tiempoUsoTotal = 0.0;
        var porcentajeTotal = 0.0;
        this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
          tiempoUsoTotal += this.HMSToSeconds(element.tiempoUso);
          porcentajeTotal += element.porcenUso;
        });
        this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
          if (porcentajeTotal > 100.0 || tiempoUsoTotal > this.HMSToSeconds(this.form.value.tiempoestimado)) {
            element.errorTiempos = true;
          } else {
            element.errorTiempos = false;
          }
        });
      }
    } else {
      //ha cambiado el porcentaje
      var nuevoTiempo = this.myFunctions.secondsTo_HH_MM_SS((this.HMSToSeconds(this.form.value.tiempoestimado)) * (cambio / 100.0));
      dataItem.tiempoUso = nuevoTiempo;
      dataItem.errorTiempoEstimado = false; //se autocorrige al cambiar el porcentaje
      //Revisamos tras agregar los cambios que ninguno se pase
      var tiempoUsoTotal = 0.0;
      var porcentajeTotal = 0.0;
      this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
        tiempoUsoTotal += this.HMSToSeconds(element.tiempoUso);
        porcentajeTotal += element.porcenUso;
      });
      this.herramientas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
        if (porcentajeTotal > 100.0 || tiempoUsoTotal > this.HMSToSeconds(this.form.value.tiempoestimado)) {
          element.errorTiempos = true;
        } else {
          element.errorTiempos = false;
        }
      });
    }
    this.herramientasFinales = this.herramientas.filter(x => x.id != -1);
    this.recargarGrid();

  }




  //Funciones programas
  agregarPrograma(e) {
    e.preventDefault(); //prevent submit
    this.programasTotalesSeleccionadas = []; //quitamos las que estaban seleccionadas antes, por si ya hemos agregado alguna.
    //Cargar datos programas antes de desplegar el modal (se vuelven a cargar, aunque ya hubiese datos previamente por si acaso se ha insertado alguna nueva por detrás)
    this.programasService.GetProgramasTotales().subscribe((result: any) => {
      this.programasTotales = result.data;
    });
    console.log("bbbb", this.programasTotales);
    this.modalReferencepopupAddPrograma = this.modalService.open(this.popupAddPrograma, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    console.log("cccc", this.modalReferencepopupAddPrograma);
  }

  eliminarPrograma(event) {
    event.preventDefault(); //prevent submit
    var programas = this.programas.filter(x => this.programasSelected.includes(x.idNuevo));
    var programasFinales = []
    programas.forEach(element => {
      if (element.id != 0) {
        element.id = -1; //Era una insercion, ahora es eliminacion
        programasFinales.push(element);
      }

    });
    programas = this.programas.filter(x => !this.programasSelected.includes(x.idNuevo));
    programas.forEach(element => { //insertamos los que no fueron seleccionados
      programasFinales.push(element);
    });
    this.programas = programasFinales;
    this.programasFinales = this.programas.filter(x => x.id != -1);
    this.recargarGridProgramas();
  }

  agregarProgramaModal() {
    //Programas a agregar a la operacion, se agrega solo el id enlazado, así que con esto nos vale
    //en operacion deberíamos tener un atributo programas donde insertar los ids de las programas nuevas que se le han agregado
    if (this.programas == undefined) {
      this.programas = [];
    }
    this.programasTotalesSeleccionadas.forEach(element => {
      var maxId;
      if (this.programasFinales.length === 0)
        maxId = -1;
      else
        maxId = Math.max.apply(Math, this.programasFinales.map(value => value.idNuevo));
      var maxOrden;
      if (this.programasFinales.length === 0)
        maxOrden = -1;
      else
        maxOrden = Math.max.apply(Math, this.programasFinales.map(value => value.orden));
      //Primero se va a calcular el porcentaje que reste y el tiempo estimado, para asignarselo a la nueva programa
      // var tiempoUsoTotal = 0.0;
      // var porcentajeTotal = 0.0;
      // this.programas.forEach(element => {
      //   tiempoUsoTotal += this.HMSToSeconds(element.tiempoUso);
      //   porcentajeTotal += element.porcenUso;
      // });
      var programa = this.programasTotales.filter(x => x.id == element)[0];
      //programa.tiempoUso = this.myFunctions.secondsTo_HH_MM_SS(this.HMSToSeconds(this.form.value.tiempoestimado) - tiempoUsoTotal); //Aquí hay que asignarle el que reste
      //programa.porcenUso = 100.0 - porcentajeTotal; //Aquí hay que asignarle el que reste
      //programa.idConsumible = programa.id;
      programa.id = 0; //porque es una inserción
      programa.idOperacion = Number(this.idOperacion);
      //programa.orden = maxOrden + 1;
      programa.idNuevo = maxId + 1;
      //programa.errorTiempos = false; //al principio el error siempre será falso
      console.log(programa);
      this.programas.push(programa);
    });
    this.programasFinales = this.programas.filter(x => x.id != -1);
    this.recargarGridProgramas();
    this.modalReferencepopupAddPrograma.close();
  }

  cancelarSeleccionPrograma() {
    this.modalReferencepopupAddPrograma.close();
    this.programasTotalesSeleccionadas = []; //lo vaciamos porque no se ha aceptado
  }

  // updateDatosPrograma(dataItem, cambio, porcen) {
  //   //aquí hay que revisar que si se cambia el tiempoUso también se debe cambiar el de los demás y lo mismo con el porcentaje
  //   //también comprobar que no se pasen del tope entre todos los objetos
  //   if (porcen == 0) {//ha cambiado el tiempo
  //     var pattern = new RegExp('^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$');
  //     var result = pattern.test(cambio);
  //     if (result == false) {
  //       dataItem.errorTiempoEstimado = true;
  //     }
  //     else {
  //       //No hay error en el formato
  //       var nuevoPorcen = 100.0 * (this.HMSToSeconds(cambio) / this.HMSToSeconds(this.form.value.tiempoestimado));
  //       dataItem.porcenUso = nuevoPorcen;
  //       dataItem.errorTiempoEstimado = false;
  //       //Revisamos tras agregar los cambios que ninguno se pase
  //       var tiempoUsoTotal = 0.0;
  //       var porcentajeTotal = 0.0;
  //       this.programas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
  //         tiempoUsoTotal += this.HMSToSeconds(element.tiempoUso);
  //         porcentajeTotal += element.porcenUso;
  //       });
  //       this.programas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
  //         if (porcentajeTotal > 100.0 || tiempoUsoTotal > this.HMSToSeconds(this.form.value.tiempoestimado)) {
  //           element.errorTiempos = true;
  //         } else {
  //           element.errorTiempos = false;
  //         }
  //       });
  //     }
  //   } else {
  //     //ha cambiado el porcentaje
  //     var nuevoTiempo = this.myFunctions.secondsTo_HH_MM_SS((this.HMSToSeconds(this.form.value.tiempoestimado)) * (cambio / 100.0));
  //     dataItem.tiempoUso = nuevoTiempo;
  //     dataItem.errorTiempoEstimado = false; //se autocorrige al cambiar el porcentaje
  //     //Revisamos tras agregar los cambios que ninguno se pase
  //     var tiempoUsoTotal = 0.0;
  //     var porcentajeTotal = 0.0;
  //     this.programas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
  //       tiempoUsoTotal += this.HMSToSeconds(element.tiempoUso);
  //       porcentajeTotal += element.porcenUso;
  //     });
  //     this.programas.forEach(element => { //las recorremos para hacer la suma y luego para darles los errores, pues si se hace en el mismo el primero no pillará
  //       if (porcentajeTotal > 100.0 || tiempoUsoTotal > this.HMSToSeconds(this.form.value.tiempoestimado)) {
  //         element.errorTiempos = true;
  //       } else {
  //         element.errorTiempos = false;
  //       }
  //     });
  //   }
  //   this.programasFinales = this.programas.filter(x => x.id != -1);
  //   this.recargarGridProgramas();

  // }








  //Funciones recetas
  recetaClick(event) {
    this.router.navigate(['operaciones/editarReceta/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + this.idOperacion + '/' + this.idVista + '/' + event.dataItem.id]);
  }

  agregarReceta(event) {
    this.router.navigate(['operaciones/crearReceta/' + this.idOF + '/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + this.idOperacion + '/' + this.idVista]);
  }

  eliminarReceta(contentRecetas) {
    if (this.recetasSelected.length > 0) {
      this.modalReference = this.modalService.open(contentRecetas, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  eliminarRecetaConfirmar() {
    this.recetasService.delete(this.recetasSelected.join(",")).subscribe(
      (data: any) => {
        this.CargarDatos();
        this.modalReference.close();
      }
    );
    this.recetasSelected = [];
  }

  HMSToSeconds(tiempoEstimado) {
    var horas, minutos, segundos;
    horas = Number(tiempoEstimado.split(":")[0]);
    minutos = Number(tiempoEstimado.split(":")[1]);
    segundos = Number(tiempoEstimado.split(":")[2]);
    return horas * 3600 + minutos * 60 + segundos;

  }


  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showTooltip(e: MouseEvent): void {
    /*const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide(); 
    }*/
  }

  //Drag and drop del grid de herramientas
  public rowCallback(context: RowClassArgs) {
    context.dataItem.orden = context.index + 1;
    return {
      dragging: context.dataItem.dragging
    };
  }

  private handleDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.grid-herramientas.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }

  recargarGrid() {
    this.gridData = process(this.herramientasFinales, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }

  recargarGridProgramas(){
    this.gridProgramas = process(this.programasFinales, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }

  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  onChangePorDefecto(dataItem, value) {
    if (value == true) {
      dataItem.cantidad = this.form.value.calidadCantidad;
      dataItem.procesar = this.form.value.procesar;
      dataItem.resto = this.form.value.resto;
    }
  }

  cambioTolerancia(elemento, cambio) {
    elemento.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == cambio.id);
    elemento.tipoTolerancia = { 'nombre': '', id: -1 }
  }

  abrirModalFrecuencias(elemento, e) {
    e.preventDefault();
    this.elementoSeleccionado_modal = elemento;
    //Cargamos los datos de todo primero
    /* this.esFuncion_modal = this.elementoSeleccionado_modal.esFuncion;
    this.funcion_modal = this.elementoSeleccionado_modal.funcion;
    this.imagen_modal = this.elementoSeleccionado_modal.imagen;
    this.ruta_modal = this.elementoSeleccionado_modal.ruta;
    if (this.utillajeModal.length > 0) {
      this.selectedUtillaje = this.utillajeModal.filter(x => x.id == this.elementoSeleccionado_modal.idUtillaje)[0];
    } */
    this.valores_modal = [{
      idNuevo: 1,
      nombre: this.translate.instant("frecuencia1"),
      cantidad: this.elementoSeleccionado_modal.frecuencia1_cant,
      porcen1: this.elementoSeleccionado_modal.frecuencia1_porcen1,
      porcen2: this.elementoSeleccionado_modal.frecuencia1_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia1_porcen1, this.elementoSeleccionado_modal.frecuencia1_porcen2),
      disabled: false
    },
    {
      idNuevo: 2,
      nombre: this.translate.instant("frecuencia2"),
      cantidad: this.elementoSeleccionado_modal.frecuencia2_cant,
      porcen1: this.elementoSeleccionado_modal.frecuencia2_porcen1,
      porcen2: this.elementoSeleccionado_modal.frecuencia2_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia2_porcen1, this.elementoSeleccionado_modal.frecuencia2_porcen2),
      disabled: this.elementoSeleccionado_modal.frecuencia1_cant > 0 ? false : true
    },
    {
      idNuevo: 3,
      nombre: this.translate.instant("frecuencia3"),
      cantidad: this.elementoSeleccionado_modal.frecuencia3_cant,
      porcen1: this.elementoSeleccionado_modal.frecuencia3_porcen1,
      porcen2: this.elementoSeleccionado_modal.frecuencia3_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia3_porcen1, this.elementoSeleccionado_modal.frecuencia3_porcen2),
      disabled: this.elementoSeleccionado_modal.frecuencia2_cant > 0 ? false : true
    },
    {
      idNuevo: 4,
      nombre: this.translate.instant("frecuencia4"),
      cantidad: this.elementoSeleccionado_modal.frecuencia4_cant,
      porcen1: this.elementoSeleccionado_modal.frecuencia4_porcen1,
      porcen2: this.elementoSeleccionado_modal.frecuencia4_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia4_porcen1, this.elementoSeleccionado_modal.frecuencia4_porcen2),
      disabled: this.elementoSeleccionado_modal.frecuencia3_cant > 0 ? false : true
    },
    {
      idNuevo: 5,
      nombre: this.translate.instant("frecuencia5"),
      cantidad: this.elementoSeleccionado_modal.frecuencia5_cant,
      porcen1: this.elementoSeleccionado_modal.frecuencia5_porcen1,
      porcen2: this.elementoSeleccionado_modal.frecuencia5_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.frecuencia5_porcen1, this.elementoSeleccionado_modal.frecuencia5_porcen2),
      disabled: this.elementoSeleccionado_modal.frecuencia4_cant > 0 ? false : true
    },
    {
      idNuevo: 6,
      nombre: this.translate.instant("resto"),
      cantidad: this.elementoSeleccionado_modal.resto_cant,
      porcen1: this.elementoSeleccionado_modal.resto_porcen1,
      porcen2: this.elementoSeleccionado_modal.resto_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.resto_porcen1, this.elementoSeleccionado_modal.resto_porcen2),
      disabled: false
    },
    {
      idNuevo: 7,
      nombre: this.translate.instant("cambioCritico"),
      cantidad: this.elementoSeleccionado_modal.cambioOperario_cant,
      porcen1: this.elementoSeleccionado_modal.cambioOperario_porcen1,
      porcen2: this.elementoSeleccionado_modal.cambioOperario_porcen2,
      porcen3: this.porcentaje(this.elementoSeleccionado_modal.cambioOperario_porcen1, this.elementoSeleccionado_modal.cambioOperario_porcen2),
      disabled: false
    }];
    this.modalReferencepopupFrecuencias = this.modalService.open(this.popupFrecuencias, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  // cantidadChange(dataItem, event) { 

  //   // nota: esto se hace de esta manera porque sino con el kendo-numerictextbox no funciona adecuadamente al cambiar con la misma cantidad

  //   // actualizar la cantidad de dataItem
  //   dataItem.cantidad = parseInt(document.getElementById(dataItem.idNuevo)['value']);

  //   // si la cantidad de una frecuencia cambia a 0 entonces ponerle el valor de la siguiente frecuencia
  //   if (dataItem.cantidad == 0 && dataItem.idNuevo < 5) {

  //     // actualizar la fila actual con los valores de la siguiente
  //     var nombre = "frecuencia" + dataItem.idNuevo;
  //     this.valores_modal[dataItem.idNuevo - 1] = this.myFunctions.copy(this.valores_modal[dataItem.idNuevo]);
  //     this.valores_modal[dataItem.idNuevo - 1].idNuevo = dataItem.idNuevo;
  //     this.valores_modal[dataItem.idNuevo - 1].nombre = this.translate.instant(nombre);

  //     // actualizar el valor del type='number' del html
  //     var auxId = dataItem.idNuevo + 1;
  //     document.getElementById(dataItem.idNuevo)['value'] = this.valores_modal[dataItem.idNuevo].cantidad;

  //     // actualizar el valor de la siguiente linea a 0 para que en la siguiente llamada recursiva entre en esta condicion
  //     document.getElementById(auxId)['value'] = 0;
  //     this.cantidadChange(this.valores_modal[dataItem.idNuevo], event);    
  //   }
  //   else if(dataItem.cantidad == 0 && dataItem.idNuevo == 5) {
  //     this.valores_modal[dataItem.idNuevo - 1] = 
  //     {
  //       idNuevo: 5,
  //       nombre: this.translate.instant("frecuencia5"), 
  //       cantidad: 0,
  //       porcen1: 1, 
  //       porcen2: 1,
  //       porcen3: 100,
  //       disabled: true
  //     };
  //   }

  //   // activar o desactivar la siguiente frecuencia
  //   if (dataItem.cantidad != undefined && dataItem.cantidad != "" && dataItem.cantidad > 0 && dataItem.idNuevo != 7 && dataItem.idNuevo != 6) {
  //     this.valores_modal[dataItem.idNuevo].disabled = false;
  //   }


  //   var cantidadTotal = this.form.value.cantidad;
  //   if (dataItem.nombre != this.translate.instant("resto") && dataItem.nombre != this.translate.instant("cambioCritico")) {
  //     var cantidadFrecuencias = 0;
  //     this.valores_modal.forEach(element => {
  //       if (element.nombre != this.translate.instant("resto") && element.nombre != this.translate.instant("cambioCritico")
  //         && element.cantidad != undefined) {
  //         cantidadFrecuencias += element.cantidad;
  //       }
  //     });
  //     var cantidadesResto = cantidadTotal - cantidadFrecuencias;
  //     if (cantidadesResto < 0)
  //       cantidadesResto = 0;
  //     this.valores_modal.filter(x => x.nombre == this.translate.instant("resto"))[0].cantidad = cantidadesResto;
  //   }
  // }

  cantidadChange(dataItem, event) {

    // nota: esto se hace de esta manera porque sino con el kendo-numerictextbox no funciona adecuadamente al cambiar con la misma cantidad

    // actualizar la cantidad de dataItem
    this.valores_modal[dataItem.idNuevo - 1].cantidad = parseInt(event.target.value);

    // si la cantidad de una frecuencia cambia a 0 entonces ponerle el valor de la siguiente frecuencia
    if (this.valores_modal[dataItem.idNuevo - 1].cantidad == 0 && dataItem.idNuevo < 5) {

      // actualizar la fila actual con los valores de la siguiente
      var nombre = "frecuencia" + dataItem.idNuevo;
      this.valores_modal[dataItem.idNuevo - 1] = this.myFunctions.copy(this.valores_modal[dataItem.idNuevo]);
      this.valores_modal[dataItem.idNuevo - 1].idNuevo = dataItem.idNuevo;
      this.valores_modal[dataItem.idNuevo - 1].nombre = this.translate.instant(nombre);

      // actualizar el valor del type='number' del html
      event.target.value = this.valores_modal[dataItem.idNuevo].cantidad;

      // actualizar el valor de la siguiente linea a 0 para que en la siguiente llamada recursiva entre en esta condicion
      this.valores_modal[dataItem.idNuevo].cantidad = 0;
      let auxEventValue = event.target.value;
      event.target.value = 0;
      this.cantidadChange(this.valores_modal[dataItem.idNuevo], event);
      event.target.value = auxEventValue;
    }
    else if (this.valores_modal[dataItem.idNuevo - 1].cantidad == 0 && dataItem.idNuevo == 5) {
      this.valores_modal[dataItem.idNuevo - 1] =
      {
        idNuevo: 5,
        nombre: this.translate.instant("frecuencia5"),
        cantidad: 0,
        porcen1: 1,
        porcen2: 1,
        porcen3: 100,
        disabled: true
      };
    }

    // activar o desactivar la siguiente frecuencia
    if (dataItem.cantidad != undefined && dataItem.cantidad != "" && dataItem.cantidad > 0 && dataItem.idNuevo != 7 && dataItem.idNuevo != 6) {
      this.valores_modal[dataItem.idNuevo].disabled = false;
    }


    var cantidadTotal = this.form.value.cantidad;
    if (dataItem.nombre != this.translate.instant("resto") && dataItem.nombre != this.translate.instant("cambioCritico")) {
      var cantidadFrecuencias = 0;
      this.valores_modal.forEach(element => {
        if (element.nombre != this.translate.instant("resto") && element.nombre != this.translate.instant("cambioCritico")
          && element.cantidad != undefined) {
          cantidadFrecuencias += element.cantidad;
        }
      });
      var cantidadesResto = cantidadTotal - cantidadFrecuencias;
      if (cantidadesResto < 0)
        cantidadesResto = 0;
      this.valores_modal.filter(x => x.nombre == this.translate.instant("resto"))[0].cantidad = cantidadesResto;
    }
  }

  // comprobar que todos los inputs de frecuencias de calidad son mayores a 0
  isPositive(event, isPorcen2) {
    if (event.target.value < 0) {
      event.target.value = 1;
    }

    if (isPorcen2) {
      if (event.target.value == 0) {
        event.target.value = 1;
      }
    }
  }

  restablecerCantidades(primeraVez) {
    if (primeraVez)
      this.valores_modal.forEach(element => {
        if (element.cantidad > 0 && element.idNuevo != 6 && element.idNuevo != 7)
          element.cantidad -= 1;
      });
  }

  aceptarGuardadoFrecuencias(e) {
    e.preventDefault();
    for (var i = 0; i < this.valores_modal.length; i++) {
      var dataItem = this.valores_modal[i];
      if ((dataItem.cantidad != undefined || dataItem.cantidad != null) && ((dataItem.porcen1 == undefined || dataItem.porcen1 == null)
        || (dataItem.porcen2 == undefined || dataItem.porcen2 == null))) {
        this.alertService.error(this.translate.instant('valoresObligatorios'));
        return;
      }
    };

    var auxi = this.valores.filter(x => x.idNuevo == this.elementoSeleccionado_modal.idNuevo)[0];
    /* auxi.imagen = this.imagen_modal;
    auxi.esFuncion = this.esFuncion_modal;
    auxi.funcion = this.funcion_modal;
    auxi.ruta = this.ruta_modal;
    auxi.idUtillaje = this.selectedUtillaje; */
    //Ahora metemos lo del array, que siempre estará en el mismo orden, así que no tenemos problema
    auxi.frecuencia1_cant = this.valores_modal[0].cantidad;
    auxi.frecuencia1_porcen1 = this.valores_modal[0].porcen1;
    auxi.frecuencia1_porcen2 = this.valores_modal[0].porcen2;
    auxi.frecuencia2_cant = this.valores_modal[1].cantidad;
    auxi.frecuencia2_porcen1 = this.valores_modal[1].porcen1;
    auxi.frecuencia2_porcen2 = this.valores_modal[1].porcen2;
    auxi.frecuencia3_cant = this.valores_modal[2].cantidad;
    auxi.frecuencia3_porcen1 = this.valores_modal[2].porcen1;
    auxi.frecuencia3_porcen2 = this.valores_modal[2].porcen2;
    auxi.frecuencia4_cant = this.valores_modal[3].cantidad;
    auxi.frecuencia4_porcen1 = this.valores_modal[3].porcen1;
    auxi.frecuencia4_porcen2 = this.valores_modal[3].porcen2;
    auxi.frecuencia5_cant = this.valores_modal[4].cantidad;
    auxi.frecuencia5_porcen1 = this.valores_modal[4].porcen1;
    auxi.frecuencia5_porcen2 = this.valores_modal[4].porcen2;
    auxi.resto_cant = this.valores_modal[5].cantidad;
    auxi.resto_porcen1 = this.valores_modal[5].porcen1;
    auxi.resto_porcen2 = this.valores_modal[5].porcen2;
    auxi.cambioOperario_cant = this.valores_modal[6].cantidad;
    auxi.cambioOperario_porcen1 = this.valores_modal[6].porcen1;
    auxi.cambioOperario_porcen2 = this.valores_modal[6].porcen2;
    this.modalReferencepopupFrecuencias.close();
  }

  onUploadButtonClick(e: UploadEvent, content) {
    e.preventDefault();

    this._kendoFiles = e.files;
    this.myFiles2 = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append('idOperacion', this.idOperacion.toString());
    formData.append('cantidad', this.form.value.cantidad.toString());


    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.operacionesService.importarCotas(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
      this.myFiles2 = [];
      this.CargarDatosValores();
    });
  }

  CargarDatosValores() {
    this.operacionesService.GetByID(this.idOF, this.idPieza, this.idParte, this.idRuta, this.idOperacion).pipe().subscribe((result) => {
      this.valores = result.valores;

      this.operacionesService.getdatosControl().pipe().subscribe((resultControl) => {
        this.operacionesService.getdatosTolerancia().pipe().subscribe((resultTolerancia) => {
          this.datosControl = resultControl; //Este nunca va a cambiar, pero la tolerancia va a ser distinta para cada valor
          this.datosTolerancia = resultTolerancia; //Aqui guardamos la general y luego las filtramos
          //Para solventar error seleccion
          if (this.valores) {
            this.valores.forEach(element => {
              if (element.tipoControl != -1)
                element.tipoControl = this.datosControl.filter(x => x.id == element.tipoControl)[0];
              else {
                element.tipoControl = { nombre: "", id: -1 };
              }
              if (element.tipoControl != undefined && element.tipoControl.id != -1) {
                element.datosTolerancia = this.datosTolerancia.filter(x => x.idTolerancia == element.tipoControl.id);
                if (element.datosTolerancia.length == 0) {
                  element.datosTolerancia = [];
                  element.tipoTolerancia = { nombre: "", id: -1 };
                } else {
                  element.tipoTolerancia = element.datosTolerancia.filter(x => x.id == element.tipoTolerancia)[0];
                }
              } else {
                element.datosTolerancia = [];
                element.tipoTolerancia = { nombre: "", id: -1 };
              }
              element.idNuevo = element.id;
              element.ruta = (element.ruta == null || element.ruta == undefined) ? "" : element.ruta;
              //element.visible= Boolean(element.visible);
            });
            this.valoresFinales = this.valores;
            this.valoresHisto = [];
            this.valores.forEach(val => this.valoresHisto.push(Object.assign({}, val))); //Hacemos una copia del array
          }
        });
      });
    });
  }

  clickModalRuta(dataItem, e) {
    e.preventDefault();
    this.submitted2 = false;
    this.ruta_modal = dataItem.ruta;
    this.elementoSeleccionado_modal = dataItem;
    this.modalReferencepopupDocumentoCalidad = this.modalService.open(this.popupDocumentoCalidad, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  aceptarGuardadoDocumento(e) {
    e.preventDefault();
    this.submitted2 = true;
    if (this.ruta_modal == null || this.ruta_modal == undefined || this.ruta_modal == "") {
      this.errorRutaModal = true;
      return;
    } else {
      this.errorRutaModal = false;
    }
    this.elementoSeleccionado_modal.ruta = this.ruta_modal;
    this.modalReferencepopupDocumentoCalidad.close();
  }


  public idControlarCotasNoCriticas_selectionChange(value: any): void {
    this.ddControladorCotasNoCriticasChanged = true;
  }

  public idControlarCotasCriticas_selectionChange(value: any): void {
    this.ddControladorCotasCriticasChanged = true;
  }

  public idObservacionPiezas_selectionChange(value: any): void {
    this.ddObservacionPiezasChanged = true;
  }

  public recalcularFracciones(numElem, dataItem, e, idElemento) {
    var valor = e.target.value;
    switch (parseInt(numElem)) {
      case 1:
        // guardar los porcentajes
        this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen1 = valor;
        this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen3 = Number(Number(valor) * 100 / Number(dataItem.porcen2));
        break;
      case 2:
        // guardar los porcentajes
        if (Number(valor) == 0) {
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen2 = 1;
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen3 = Number(Number(dataItem.porcen1) * 100 / 1);
        } else {
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen2 = valor;
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen3 = Number(Number(dataItem.porcen1) * 100 / Number(valor));
        }
        break;
      case 3:
        // guardar los porcentajes
        let pc1 = Number(valor);
        let pc2 = 100;
        if (pc1 == 0) {
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen1 = 0;
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen2 = 1;
        } else {
          let mcd = this.EuclidesMCD(pc1, pc2);
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen1 = pc1 / mcd;
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen2 = pc2 / mcd;
          this.valores_modal.filter(x => x.idNuevo == idElemento)[0].porcen3 = valor;
        }
        break;

      default:
        break;
    }

    // al cambiar el porcentaje si la cantidad es 0 automaticamente se convierte en 1
    if (dataItem.cantidad == 0) {
      this.valores_modal.filter(x => x.idNuevo == idElemento)[0].cantidad = 1;
    }
  }

  public EuclidesMCD(a, b) {
    var iaux; //auxiliar
    a = Math.abs(a); //tomamos valor absoluto
    b = Math.abs(b);
    var i1 = Math.max(a, b); //i1 = el más grande
    var i2 = Math.min(a, b); //i2 = el más pequeño

    do {
      iaux = i2; //guardar divisor
      i2 = i1 % i2; //resto pasa a divisor
      i1 = iaux; //divisor pasa a dividendo
    } while (i2 !== 0);
    return i1; //ultimo resto no nulo
  }

  public porcentaje(a, b) {
    if (b == null) return 0;
    if (b > 0)
      return a * 100 / b;
    else return 0
  }

  onClickResetear() {

    this.valores_modal.forEach(element => {
      element.cantidad = 0;
      element.porcen1 = 1;
      element.porcen2 = 1;
      element.porcen3 = 100;
    });

  }

  onClickPredefinido() {
    this.configuracionService.get_configuracion_calidad_frecuencias().subscribe(
      json => {
        var dataFrecuencias: any = json;

        var tot = 0;
        var j = 0;
        var cantTot;

        if (this.cantidadTotal != undefined)
          cantTot = this.cantidadTotal;
        else
          cantTot = this.form.value.cantidad

        this.valores_modal.forEach(element => {
          if (dataFrecuencias[j].nombre != 'resto' && dataFrecuencias[j].nombre != "cambioCritico") {
            if ((dataFrecuencias[j].cantidad + tot) > cantTot) {
              if ((cantTot - tot) > 0) {
                element.cantidad = cantTot - tot;
                element.porcen1 = dataFrecuencias[j].porcen1;
                element.porcen2 = dataFrecuencias[j].porcen2;
                element.porcen3 = dataFrecuencias[j].porcen3;

                tot = cantTot; // asi nos aseguramos que no entra mas aqui
              } else {
                element.cantidad = 0;
                element.porcen1 = 0;
                element.porcen2 = 0;
                element.porcen3 = 0;
              }
            } else {
              tot += dataFrecuencias[j].cantidad;
              element.cantidad = dataFrecuencias[j].cantidad;
              element.porcen1 = dataFrecuencias[j].porcen1;
              element.porcen2 = dataFrecuencias[j].porcen2;
              element.porcen3 = dataFrecuencias[j].porcen3;
            }

            j++;
          }

          if (dataFrecuencias[j].nombre == 'resto' && dataFrecuencias[j].nombre == "cambioCritico") {
            element.cantidad = dataFrecuencias[j].cantidad;
            element.porcen1 = dataFrecuencias[j].porcen1;
            element.porcen2 = dataFrecuencias[j].porcen2;
            element.porcen3 = dataFrecuencias[j].porcen3;
          }
        });

        if (tot < cantTot) {
          this.valores_modal[5].cantidad = cantTot - tot;
        } else {
          this.valores_modal[5].cantidad = 0
        }

      });
  }

  // esta funcion sirve para que cuando se de enter en un combo o textbox no aparezca la pantalla de herramientas
  onKeyPress(event) {
    if (event.charCode == 13) {
      event.preventDefault();
    }
  }

  tieneProgramaChange(e: any) {
    if (this.tienePrograma == false) {
      this.tienePrograma = true;
    } else {
      this.tienePrograma = false;
    }
  }

  nProgramaChange(e: any) {
    this.nPrograma =  e.target.value;
  }

  onClick(tipo: number) {
    if (tipo == 0) {
      this.rutaProgramaEsDir = false;
    } else if(tipo == 1) {
      this.rutaProgramaEsDir = true;
    }
  }

  rutaProgramaChange(e: any) {
    this.rutaPrograma =  e.target.value;
  }
}


