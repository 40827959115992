import { Component } from '@angular/core';
import { Router } from "@angular/router"
import * as c3 from 'c3';
import * as d3 from 'd3';
import { OFService } from '@app/_services/of.service';
import { PiezasService } from '@app/_services/piezas.service';
import { UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../_services';
import { MyFunctions } from '@app/_helpers';
import { TipoMantenimiento_DAT } from '@app/_models';
import * as moment from 'moment';

interface ColumnSetting {
  field: any;
  title: any;
  format?: string;
  type?: 'text' | 'numeric' | 'boolean' | 'date';
}

@Component({
  selector: 'app-seguimientosproyectos',
  templateUrl: 'seguimientosproyectos.component.html'
})
export class SeguimientosProyectosComponent {
  
  user = this.userService.userValue;


  public dataCombos: any;
  public gruposMaquinas: any;
  public proyectoActual: TipoMantenimiento_DAT;
  public piezasSeleccionadas: any;

  public categoriasstr: any[];
  public desviosgridstr: string = '';
  public alarmasstr: string = '';
  public recursosstr: string = '';
  public nuevasstr: string = '';
  public incidenciasstr: string = '';
  public chartProcesoMaquinasDatos;
  public chartProcesoMaquinas: any;
  public pieza: any;
  public gridData: any[];
  public chartBarHoras: any;
  public donutColumns: any;
  public dataGraficoHoras: any;
  private translate: TranslateService;
  public chartDonutResumenSemana;
  public proyectos: any[];
  public piezas: any[];
  public cliente: string;

  public estado: string;
  public fechainiciostring: any;
  public horasestimadas: any;
  public nprocesos;

  public hreales: any;
  public porcentajeCompletado: any;

  public dataPendientes: any;

  public piezasOF: any[];

  public desviosGrid: any;
  public desviosGrid2: any;
  public fechaImportacionGrid: any;
  public incidenciasGrid: any;

  constructor(private proyectosService: OFService,
    translate: TranslateService,
    public myFunctions: MyFunctions,
    private piezasService: PiezasService,
    private userService: UsuariosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,) {

    this.chartProcesoMaquinasDatos = [];
    this.donutColumns = [];
    this.categoriasstr = [];
    this.dataGraficoHoras = [];
    this.porcentajeCompletado = 0;
    this.hreales = '';    
    this.chartProcesoMaquinasDatos = [];
    this.hreales = 0;
    this.cliente = "";
    this.estado = "";
    this.fechainiciostring = "";
    this.horasestimadas = "0";
    this.nprocesos = "0";
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('seguimientosproyectos').toUpperCase();

  }

  ngOnInit() {

    this.desviosgridstr = this.translate.instant('restimado50');
    this.alarmasstr      = this.translate.instant('iprealestimado');
    this.recursosstr     = this.translate.instant('fechaimportacion');
    this.nuevasstr = this.translate.instant('nuevas');
    this.incidenciasstr = this.translate.instant('incidencias');

    this.proyectosService.GetProyectosPiezasClientes().subscribe((result: any) => {

      this.dataCombos = result;

      var groupByProyecto = [];
      //GROUP BY PROYECTO
      result.forEach(function (a) {
        if (!this[a.idOF]) {
          this[a.idOF] = {
            id: a.idOF, nomOf: a.numeroOF
          };
          groupByProyecto.push(this[a.idOF]);
        }
      }, Object.create(null));

      this.proyectos = groupByProyecto;
      this.proyectoActual = { 'id': "-1", 'nombre': 'Proyecto' };

    });

    this.piezasService.GetAll().subscribe((result) => {
      this.piezas = result.piezas;
    });

    this.chartProcesoMaquinas = c3.generate({
      bindto: '#chartAreaHorasDia_proyectos',
      data: {
        x: 'x',
        columns: [],
        order: null
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%Y-%m-%d',
            culling: {
              max: 5
            }
          }
        },
        y: {
          min: 0,
          max: 10,
          tick: {
            values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      point: {
        show: false
      }
    });

    this.chartBarHoras = c3.generate({
      data: {
        columns: this.dataGraficoHoras,
        order: null,
        type: 'bar'
      },
      color: {
        pattern: [['#05ad6a'], ['#44e3c4']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: this.categoriasstr
        },
        y: {
          show: false,
        },
        rotated: false
      },
      transition: {
        duration: 2000
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      legend: {
        show: false
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      bindto: '#chartBarHoras_seguimientoProyectos'
    });

    //GRAFICO DONUT CALIDAD
    this.chartDonutResumenSemana = c3.generate({
      bindto: '#chartDonutEstimacionHorasLineas',
      data: {
        columns: this.donutColumns,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null',
        onmouseover: function (d) {
          d3.select('#chartDonutEstimacionHorasLineas .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutEstimacionHorasLineas .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutEstimacionHorasLineas .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "",
        width: 22,
        label: { show: false }
      },
      legend: {
        position: 'down'
      },
      tooltip: {
        show: false
      }
    });

  }

  reiniciarVariables() {

    this.gridData = [];
   
    this.chartProcesoMaquinasDatos = [];
    this.dataGraficoHoras = [];
    this.porcentajeCompletado = "";
    this.chartProcesoMaquinasDatos = [];
    this.hreales = 0;
    
    this.estado = "";
    this.fechainiciostring = "";
    this.horasestimadas = "0";
    this.nprocesos = "0"
    
  }

  selectionProyectosChange(e) {

    if (e != undefined) {
      var idOF = e.id;
    } else {
      idOF = -1;
    }

    this.piezasSeleccionadas = [];

    var groupByPieza = [];
    //GROUP BY PROYECTO
    this.dataCombos.forEach(function (a) {
      if (!this[a.idPieza] && a.idOF == idOF) {
        this[a.idPieza] = {
          id: a.idPieza, refPieza: a.nombrePieza
        };
        groupByPieza.push(this[a.idPieza]);
      }
    }, Object.create(null));

    this.piezasOF = groupByPieza;

    var cliente = this.dataCombos.find(x => x.idOF === idOF);

    if (cliente != undefined) {
      this.cliente = cliente.nombreCliente;
    } else {
      this.cliente = "";
    }

  }

  clickFiltrar() {

    this.reiniciarVariables();

    var idPiezasSeleccionadas = this.piezasSeleccionadas.map(function (item) { return item['id']; }).join(",");

    this.cargarDatosGenerales(idPiezasSeleccionadas);
    this.cargarGridEstado(idPiezasSeleccionadas);
    this.cargarGraficosEstimadosReales(idPiezasSeleccionadas);
    this.cargarDatosTabs(idPiezasSeleccionadas);
    this.cargarGraficoIntensidadSeccion(idPiezasSeleccionadas);

  }

  cargarDatosGenerales(idPiezasSeleccionadas) {

    this.proyectosService.GetDatosGenerales_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {
      var dataMin = this.myFunctions.getDateNow()
      var dataGordea = false;
      var tEstimado = 0;
      var cantidad = 0;
      var nOperaciones = 0;
      var cantidadCortoTotal = 0;
      var cantidadLargoTotal = 0;
      var countTerminados = 0;

      result.forEach(function (registro) {
        tEstimado += registro.tEstimado * registro.cantidad;
        cantidad += registro.cantidad;
        nOperaciones += registro.countHistorico;
        cantidadCortoTotal += registro.cantidadCortoTotal;
        cantidadLargoTotal += registro.cantidadLargoTotal;
        countTerminados += registro.countTerminados;
        if (dataMin >= new Date(registro.minFecha) && registro.minFecha != null) {
          dataMin = new Date(registro.minFecha);
          dataGordea = true;
        }
      }, this);

      if (dataGordea) this.fechainiciostring = dataMin;
      else this.fechainiciostring = "";
      //this.horasestimadas = tEstimado;
      this.nprocesos = nOperaciones;

      if (nOperaciones > 0 && countTerminados > cantidad && countTerminados == nOperaciones) this.estado = this.translate.instant('terminado');
      else if (nOperaciones > 0) this.estado = this.translate.instant('enProceso');
      else if (cantidadCortoTotal > 0 || cantidadLargoTotal > 0) this.estado = this.translate.instant('planificado');
      else this.estado = this.translate.instant('sinPlanificar');

    });

  }

  cargarGridEstado(idPiezasSeleccionadas) {

    var r1, r2, r3: boolean = false;
    var grupos, operacionesHechas, operacionesPlanificada: any;

    this.proyectosService.GetGruposMaquinas_SeguimientoProyecto().subscribe((result: any) => {

      this.gruposMaquinas = result;
      grupos = result;
      grupos.push({ id: 0, nombre: this.translateService.instant('otros')});
      r1 = true;
      if (r1 && r2 && r3) this.cargarGridEstado2(grupos, operacionesHechas, operacionesPlanificada);

    });
    this.proyectosService.GetOperacionesHechasPorGrupo_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      operacionesHechas = result;
      r2 = true;
      if (r1 && r2 && r3) this.cargarGridEstado2(grupos, operacionesHechas, operacionesPlanificada);

    });
    this.proyectosService.GetOperacionesPlanificadasPorGrupo_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      operacionesPlanificada = result;
      r3 = true;
      if (r1 && r2 && r3) this.cargarGridEstado2(grupos, operacionesHechas, operacionesPlanificada);

    });
  }

  cargarGridEstado2(grupos, operacionesHechas, operacionesPlanificada) {

    //this.gridData = [
    //  { nombre: "NS", g_1: 2, g_2: 2 },
    //  { nombre: "IP", g_1: 2, g_2: 2 },
    //  { nombre: "CO", g_1: 2, g_2: 2 }
    //];
    this.gridData = [];


    grupos.forEach(function (grupo) {
      grupo.countHistorico = 0;
      grupo.countTerminados = 0;
      grupo.planificados = 0;

      operacionesHechas.forEach(function (o) {
        if (o.idGrupo == grupo.id) {
          grupo.countHistorico += o.countHistorico;
          grupo.countTerminados += o.countTerminados;
        }
      });

      operacionesPlanificada.forEach(function (o) {
        if (o.idGrupo == grupo.id) {
          grupo.planificados += o.planificados;
        }
      });

    });

    this.gridData = [
      { nombre: "NS" }, //[0]
      { nombre: "IP" }, //[1]
      { nombre: "CO" }  //[2]
    ];

    grupos.forEach(function (grupo) {

      var NS = grupo.planificados - grupo.countHistorico;
      var IP = grupo.countHistorico - grupo.countTerminados;
      var CO = grupo.countTerminados;

      if (NS < 0) NS = 0;

      this.gridData[0]["g_" + grupo.id] = NS;
      this.gridData[1]["g_" + grupo.id] = IP;
      this.gridData[2]["g_" + grupo.id] = CO;

    }, this);

  }

  cargarGraficosEstimadosReales(idPiezasSeleccionadas) {

    var r1, r2, r3: boolean = false;
    var grupos, tiempos, planificados: any;

    this.proyectosService.GetGruposMaquinasConMaquinas_SeguimientoProyecto().subscribe((result: any) => {

      result.forEach(function (registro) {
        registro.idMaquinas = registro.idMaquinas.split(',');
        registro.idMaquinas = registro.idMaquinas.filter(entry => entry.trim() != '');//ELIMINAR VACIOS
        registro.tEstimado = 0;
        registro.tReal = 0;
      }, this);

      grupos = result;

      r1 = true;
      if (r1 && r2 && r3) {
        this.cargarGraficosEstimadosReales2(grupos, tiempos, planificados);
        this.cargarGridPendiente(grupos, tiempos, planificados);
      }

    });
    this.proyectosService.GetTiemposEstimadosPorMaquinas_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      result.forEach(function (registro) {
        registro.idMaquinaPO = registro.idMaquinaPO.split(',');
        registro.idMaquinaPO = registro.idMaquinaPO.filter(entry => entry.trim() != '');//ELIMINAR VACIOS
        registro.contado = false;
      }, this);

      planificados = result;

      r2 = true;
      if (r1 && r2 && r3) {
        this.cargarGraficosEstimadosReales2(grupos, tiempos, planificados);
        this.cargarGridPendiente(grupos, tiempos, planificados);
      }

    });
    this.proyectosService.GetTiemposRealesPorMaquinas_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      result.forEach(function (registro) {
        registro.idMaquinaPO = registro.idMaquinaPO.split(',');
        registro.idMaquinaPO = registro.idMaquinaPO.filter(entry => entry.trim() != '');//ELIMINAR VACIOS
        registro.contado = false;
      }, this);

      tiempos = result;

      r3 = true;
      if (r1 && r2 && r3) {
        this.cargarGraficosEstimadosReales2(grupos, tiempos, planificados);
        this.cargarGridPendiente(grupos, tiempos, planificados);
      }

    });

  }

  cargarGraficosEstimadosReales2(grupos, tiempos, planificados) {

    grupos.forEach(function (gru) {

      tiempos.forEach(function (ti) {
        if (gru.idMaquinas.includes(ti.idMaquina.toString()) && !ti.contado) {
          ti.contado = true;
          gru.tEstimado += ti.countHistoricoOperaciones * ti.tEstimado;
          gru.tReal += ti.tReal;
        }
      }, this);

      planificados.forEach(function (pl) {
        if (gru.idMaquinas.includes(pl.idMaquina.toString()) && !pl.contado) {
          pl.contado = true;
          gru.tEstimado += pl.planificadas * pl.tEstimado;
        }
      }, this);

    }, this);

    var tEstimadoOtros = 0;
    var tRealOtros = 0;

    tiempos.forEach(function (ti) {
      if (!ti.contado) {
        ti.contado = true;
        tEstimadoOtros += ti.countHistoricoOperaciones * ti.tEstimado;
        tRealOtros += ti.tReal;
      }
    }, this);

    planificados.forEach(function (pl) {
      if (!pl.contado) {
        pl.contado = true;
        tEstimadoOtros += pl.planificadas * pl.tEstimado;
      }
    }, this);

    grupos.push({ id: 0, nombre: this.translateService.instant('otros'), tEstimado: tEstimadoOtros, tReal: tRealOtros, idMaquinas: [] });

    //CREAMOS UN ARRAY CON LOS IDS DE OPERACION (tiempos+planificados)
    var idOperacionesTiempos = tiempos.map(function (item) { return item['idOperacion']; });
    var idOperacionesPlanificadas = planificados.map(function (item) { return item['idOperacion']; });
    var idOperaciones = [...new Set(idOperacionesTiempos.concat(idOperacionesPlanificadas))];

    //MIRAMOS SI HAY ALGUNA SIN HACER Y SIN PLANIFICAR (CANTIDAD-PLANIFICADAS-COUNTHISTORICOPERACIONES)
    var desgloseOperaciones = [];
    idOperaciones.forEach(function (ido) {

      var planificadas = 0;
      var acabadas = 0;
      var cantidad = 0;
      var tEstimado = 0;
      var idMaquinaPO = [];

      tiempos.forEach(function (ti) {
        if (ido == ti.idOperacion) {
          acabadas += ti.countHistoricoOperaciones;
          cantidad = ti.cantidad;
          idMaquinaPO = ti.idMaquinaPO;
          tEstimado = ti.tEstimado;
        }
      }, this);

      planificados.forEach(function (pl) {
        if (ido == pl.idOperacion) {
          planificadas += pl.planificadas;
          cantidad = pl.cantidad;
          idMaquinaPO = pl.idMaquinaPO;
          tEstimado = pl.tEstimado;
        }
      }, this);

      desgloseOperaciones.push({ id: ido, planificadas: planificadas, acabadas: acabadas, cantidad: cantidad, idMaquinaPO: idMaquinaPO, tEstimado: tEstimado, contado: false });

    }, this);


    desgloseOperaciones.forEach(function (dop) {
      var cantidadSinHacer = dop.cantidad - dop.acabadas - dop.planificadas;
      grupos.forEach(function (gru) {
        if (dop.idMaquinaPO.every(ai => gru.idMaquinas.includes(ai)) && !dop.contado && cantidadSinHacer > 0) {
          dop.contado = true;
          gru.tEstimado += cantidadSinHacer * dop.tEstimado;
        }
      }, this);
    }, this);

    //METER A OTROS
    desgloseOperaciones.forEach(function (dop) {
      var cantidadSinHacer = dop.cantidad - dop.acabadas - dop.planificadas;
      if (!dop.contado && cantidadSinHacer > 0) {
        dop.contado = true;
        grupos[grupos.length - 1].tEstimado += cantidadSinHacer * dop.tEstimado;
      }
    }, this);

    //DATOS PANEL GENERAL

    //GRID PANEL GENERAL

    //GRAFICO PANEL GENERAL
    var dataDonut = [];
    grupos.forEach(function (gru) {
      dataDonut.push([gru.nombre, gru.tEstimado]);
    }, this);

    var oldcolumns = this.donutColumns;
    this.donutColumns = dataDonut;
    this.updateDonutLeyenda(this.chartDonutResumenSemana, oldcolumns, this.donutColumns);

    //GRAFICO AVANCE
    var horasestimadas = 0;
    var hreales = 0;

    var oldcolumns = this.dataGraficoHoras;

    this.dataGraficoHoras = [[this.translateService.instant("horasReales")], [this.translateService.instant("horasEstimadas")]];
    var categories = [];

    grupos.forEach(function (gru) {
      horasestimadas += gru.tEstimado;
      hreales += gru.tReal;
      this.dataGraficoHoras[0].push(gru.tReal);
      this.dataGraficoHoras[1].push(gru.tEstimado);
      if (gru.tEstimado != 0) var porcentaje = ((gru.tReal * 100) / gru.tEstimado);
      else var porcentaje = 0;
      gru.porcentajeGraficoAvance = porcentaje;
      categories.push(gru.nombre + " - " + porcentaje.toFixed(0) + "%");
    }, this);

    this.updateDonutLeyenda(this.chartBarHoras, oldcolumns, this.dataGraficoHoras, false);
    this.chartBarHoras.load({ categories: categories });

    d3.selectAll('#chartBarHoras_seguimientoProyectos .c3-axis-x text').style("fill", function (texto, index) {
      if (index>0 && grupos[index-1].porcentajeGraficoAvance > 100) {
        return "red";
      } else {
        return "#22c4c4";
      }
    });

    if (horasestimadas != 0) var porcentajeTotal = ((hreales * 100) / horasestimadas);
    else var porcentajeTotal = 0;

    this.horasestimadas = horasestimadas;
    this.hreales = hreales;
    this.porcentajeCompletado = porcentajeTotal.toFixed(0) + "%";

  }

  cargarGridPendiente(grupos, tiempos, planificados) {

    this.dataPendientes = [];

    //CREAMOS UN ARRAY CON LOS IDS DE OPERACION (tiempos+planificados)
    var idOperacionesTiempos = tiempos.map(function (item) { return item['idOperacion']; });
    var idOperacionesPlanificadas = planificados.map(function (item) { return item['idOperacion']; });
    var idOperaciones = [...new Set(idOperacionesTiempos.concat(idOperacionesPlanificadas))];

    //MIRAMOS SI HAY ALGUNA SIN HACER Y SIN PLANIFICAR (CANTIDAD-PLANIFICADAS-COUNTHISTORICOPERACIONES)
    var desgloseOperaciones = [];
    idOperaciones.forEach(function (ido) {

      var tEstimado = 0;
      var cantidad = 0;
      var countHistoricoOperaciones = 0;
      var countHistoricoOperacionesSinTerminar = 0;
      var countHistoricoOperacionesTerminadas = 0;
      var tReal = 0;
      var tRealOperacionesSinTerminar = 0;
      var tRealOperacionesTerminadas = 0;
      var planificadas = 0;

      tiempos.forEach(function (ti) {
        if (ido == ti.idOperacion) {
          tEstimado = ti.tEstimado;
          cantidad = ti.cantidad;
          countHistoricoOperaciones += ti.countHistoricoOperaciones;
          countHistoricoOperacionesSinTerminar += ti.countHistoricoOperacionesSinTerminar;
          countHistoricoOperacionesTerminadas += ti.countHistoricoOperacionesTerminadas;
          tReal += ti.tReal;
          tRealOperacionesSinTerminar += ti.tRealOperacionesSinTerminar;
          tRealOperacionesTerminadas += ti.tRealOperacionesTerminadas;
        }
      }, this);

      planificados.forEach(function (pl) {
        if (ido == pl.idOperacion) {
          tEstimado = pl.tEstimado;
          planificadas += pl.planificadas;
          cantidad = pl.cantidad;
        }
      }, this);

      desgloseOperaciones.push({
        id: ido,
        tEstimado: tEstimado,
        cantidad: cantidad,
        countHistoricoOperaciones: countHistoricoOperaciones,
        countHistoricoOperacionesSinTerminar: countHistoricoOperacionesSinTerminar,
        countHistoricoOperacionesTerminadas: countHistoricoOperacionesTerminadas,
        tReal: tReal,
        tRealOperacionesSinTerminar: tRealOperacionesSinTerminar,
        tRealOperacionesTerminadas: tRealOperacionesTerminadas,
        planificadas: planificadas
      });

    }, this);

    //NS (No empezado): Tiempo estimado de tareas NS (Las que no estan en historico operaciones)
    var tiempoNS = 0;

    desgloseOperaciones.forEach(function (dop) {
      var cantidadSinTocar = dop.cantidad - dop.planificadas - dop.countHistoricoOperaciones; //SIN HACER Y SIN PLANIFICAR
      if (cantidadSinTocar < 0) cantidadSinTocar = 0;
      var cantidadNS = cantidadSinTocar + dop.planificadas;
      tiempoNS += cantidadNS * dop.tEstimado;
    }, this);

    this.dataPendientes.push({ id: 1, nombre: this.translateService.instant("ns"), valor: this.myFunctions.secondsTo_HH_MM_SS(tiempoNS) });

    //IP (En proceso): % de avance de las tareas en curso (no terminadas)
    var tiempoEstimadoIP = 0;
    var tiempoRealIP = 0;

    desgloseOperaciones.forEach(function (dop) {
      tiempoEstimadoIP += dop.countHistoricoOperacionesSinTerminar * dop.tEstimado;
      tiempoRealIP += dop.tRealOperacionesSinTerminar;
    }, this);

    var porcentajeIP = 0;
    if (tiempoEstimadoIP > 0) porcentajeIP = ((tiempoRealIP * 100) / tiempoEstimadoIP);
    else porcentajeIP = 0;

    this.dataPendientes.push({ id: 2, nombre: this.translateService.instant("ips"), valor: porcentajeIP.toFixed(0) + "%" });

    //FALTA (Operaciones no empezadas + Restante IP): Tiempo estimado de las NS (planificadas y sin hacer) + Las de historicoOperaciones que estan en curso
    var tiempoNSyIP = 0;

    desgloseOperaciones.forEach(function (dop) {
      var cantidadSinTocar = dop.cantidad - dop.planificadas - dop.countHistoricoOperaciones; //SIN HACER Y SIN PLANIFICAR
      if (cantidadSinTocar < 0) cantidadSinTocar = 0;
      var cantidadNSyIP = cantidadSinTocar + dop.planificadas + dop.countHistoricoOperacionesSinTerminar;
      tiempoNSyIP += cantidadNSyIP * dop.tEstimado;
    }, this);

    this.dataPendientes.push({ id: 3, nombre: this.translateService.instant("nsp"), valor: this.myFunctions.secondsTo_HH_MM_SS(tiempoNSyIP) });

    //PTE (Pendiente respecto a estimado):
    //Por ejemplo:
    //  IP: 5h hechas, 20h estimadas
    //  NS: 30h estimadas
    //  Calcular porcentaje 45h sobre 50h
    var tiempoNS = 0;
    var tRealIp = 0;
    var tEstimadoIp = 0;

    desgloseOperaciones.forEach(function (dop) {
      var cantidadSinTocar = dop.cantidad - dop.planificadas - dop.countHistoricoOperaciones; //SIN HACER Y SIN PLANIFICAR
      if (cantidadSinTocar < 0) cantidadSinTocar = 0;
      var cantidadNS = cantidadSinTocar + dop.planificadas;
      tiempoNS += cantidadNS * dop.tEstimado;
      tRealIp += dop.tRealOperacionesSinTerminar;
      tEstimadoIp += dop.countHistoricoOperacionesSinTerminar * dop.tEstimado;

    }, this);

    var porcentajePTE = 0;
    if ((tiempoNS + tEstimadoIp) > 0) porcentajePTE = (tRealIp * 100) / (tiempoNS + tEstimadoIp);
    else porcentajePTE = 0;

    if (porcentajePTE > 100) porcentajePTE = 0;
    else porcentajePTE = 100 - porcentajePTE;

    this.dataPendientes.push({ id: 4, nombre: this.translateService.instant("pte"), valor: porcentajePTE.toFixed(0) + "%" });

  }

  cargarDatosTabs(idPiezasSeleccionadas) {

    this.proyectosService.GetTareas_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      this.desviosGrid = [];
      this.desviosGrid2 = [];

      result.forEach(function (registro) {

        if (registro.tReal > (registro.tEstimado * 1.5)) {
          registro.porcentaje = (((registro.tReal - registro.tEstimado) * 100) / registro.tEstimado).toFixed(1) + "%";//desvio
          this.desviosGrid.push(registro);
        }

        if (registro.tReal > registro.tEstimado && registro.respuesta) {
          registro.porcentaje = (((registro.tReal - registro.tEstimado) * 100) / registro.tEstimado).toFixed(1) + "%";//desvio
          this.desviosGrid2.push(registro);
        }

      }, this);

    });

    this.proyectosService.GetTareasFechaImportacion_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      this.fechaImportacionGrid = [];

      result.forEach(function (registro) {

        if (registro.fechaImportacionERPparte != registro.fechaImportacionERPoperacion) {

          if (registro.fechaImportacionERPparte != null) registro.fechaImportacionERPparte = new Date(registro.fechaImportacionERPparte)
          if (registro.fechaImportacionERPoperacion != null) registro.fechaImportacionERPoperacion = new Date(registro.fechaImportacionERPoperacion)

          this.fechaImportacionGrid.push(registro);

        }

      }, this);

    });

    this.incidenciasGrid = [];

  }  

  cargarGraficoIntensidadSeccion(idPiezasSeleccionadas) {

    this.proyectosService.GetIntensidadPorSeccion_SeguimientoProyecto(this.proyectoActual.id, idPiezasSeleccionadas).subscribe((result: any) => {

      var fechas = result.map(function (item) { return item['fechaTurno'] });
      var minFecha: any = fechas[0];
      var maxFecha: any = fechas[fechas.length - 1];

      var fechasstr = [];

      fechas = this.getDaysArray(new Date(minFecha), new Date(maxFecha));
      fechas.map((v) => v.toISOString().slice(0, 10)).join("");
      fechas.forEach(function (fecha) {
        fechasstr.push(this.myFunctions.dateToYYYY_MM_DD_guion(fecha));
      }, this);

      var gruposRepetidos = result.map(function (item) { return { id: item['idGrupo'], nombre: item['nomberGrupo'], valores: [] } });

      //GUARDAMOS LOS NO REPETIDOS
      var grupos = gruposRepetidos.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.id === thing.id && t.nombre === thing.nombre
        ))
      )

      fechasstr.forEach(function (fecha) {

        grupos.forEach(function (grupo) {

          var row = result.find(x => (x.fechaTurno == fecha && x.idGrupo == grupo.id));

          var numeroMaquinas: any;

          if (row == undefined) numeroMaquinas = 0;
          else numeroMaquinas = row.numeroMaquinas;

          grupo.valores.push(numeroMaquinas);

        }, this);

      }, this);

      var datosGrafico = [];

      grupos.forEach(function (grupo) {

        var valores = [grupo.nombre];

        grupo.valores.forEach(function (valor) {
          valores.push(valor);
        }, this);

        datosGrafico.push(valores);

      }, this);

      fechasstr.unshift("x");
      datosGrafico.unshift(fechasstr);

      this.chartProcesoMaquinas.load({ columns: datosGrafico });
    });

  }

  public updateDonutLeyenda(grafico, oldData, newData, anadirHora=true) {

    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }

    var names = {};
    if (anadirHora) {
      newData.forEach(function (a) {
        names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
      }, this);
    }

    grafico.load({
      columns: newData,
      names: names
    });
   
    grafico.unload({
      ids: nombresAEliminar
    });
  }

  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

}
