<form [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <!-- DATOS ÁREA PRODUCTIVA -->
        <div class="card">
          <div class="card-header">
            <h3 class="pt-3">{{ 'datos' | translate}}</h3>
          </div>
          <div class="card-body">
            <div class="form-group ">
              <kendo-label text="{{ 'nombre' | translate}}">
                <div class="caja">
                  <kendo-textbox maxlength="150" formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && form.controls.nombre.errors }">
                  </kendo-textbox>
                  <div *ngIf="submitted && form.controls.nombre.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.nombre.errors.required">{{ 'nombreEsrequerido' | translate}}</div>
                  </div>
                </div>
              </kendo-label>
              <div class="form-group ">
                <kendo-label text="{{ 'descripcion' | translate}}">
                  <div class="caja">
                    <textarea kendoTextArea style="resize: none;" rows="5" formControlName="descripcion" >
                  </textarea>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12">
            <!-- DATOS CONTACTO ÁREA PRODUCTIVA -->
            <div class="card">
              <div class="card-header">
                <h3 class="pt-3">{{ 'contacto' | translate}}</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group ">
                      <kendo-label text="{{ 'email' | translate}}">
                        <div class="caja">
                            <kendo-textbox type="email" maxlength="150" formControlName="email">
                            </kendo-textbox>
                        </div>
                      </kendo-label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group ">
                      <kendo-label text="{{ 'telefono' | translate}}">
                        <div class="caja">
                            <kendo-maskedtextbox formControlName="telefono" (focusout)="onChangeNumberCorrect($event)" [ngClass]="{ 'is-invalid': (submitted && telefonoInvalido)}">
                            </kendo-maskedtextbox>
                            <div *ngIf="submitted && telefonoInvalido" class="invalid-feedback">
                              <div *ngIf="telefonoInvalido">{{ 'telefonoEsValido' | translate}}</div>
                            </div>
                        </div>
                      </kendo-label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- GRUPOS MÁQUINAS -->
    <!-- <div class="card">
      <div class="card-body">
        <h3 class="pt-3">{{ 'maquinasgrupos' | translate}}</h3>
        <kendo-grid [kendoGridBinding]="dataGrupos" [sortable]="true" kendoGridSelectBy="idGrupoMaquina" [navigable]="true"
          filterable="menu" (cellClick)="cellClick($event)" [selectedKeys]="mySelection">
          <ng-template kendoGridToolbarTemplate position="top">
            <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1"> {{ 'editar' |
              translate}} </button>
            <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1"> {{ 'nuevo' |
              translate}}
            </button>
            <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1"
              [disabled]="isDeleting || this.user.secciones < 2"> {{ 'eliminar' | translate}} </button>
          </ng-template>
          <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
          <kendo-grid-column width="95%" field="nombreGrupoMaquina" title="{{ 'nombre' | translate}}"> </kendo-grid-column>
                                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                 filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                 filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                 filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                 filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                 filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                 filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                 filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                 filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                 filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                 filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                 filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                 filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                 filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                 filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                 filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                 filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                 filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                 filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                 filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                 groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                 noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </div> -->
    <button type="button" class="btn btn-primary" (click)="guardarClick()"  [disabled]="this.user.secciones < 2">{{ 'guardar' | translate }}</button>
    <button type="button" class="btn btn-danger ml-2" (click)="cancelarClick()">{{ 'cancelar' | translate }}</button> 
  
    <!-- POPUP: Borrar -->
   <ng-template #popupBorrar let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
          }}</button>
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()" [disabled]="this.user.secciones < 2">{{ 'aceptar' | translate }}</button>
      </div>
    </ng-template>
  </form>