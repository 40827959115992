import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService, MaquinasService, UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maquinasGruposPlanificador',
  templateUrl: './maquinasGruposPlanificador.component.html'
})
export class MaquinasGruposPlanificadorComponent implements OnInit {
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  form: FormGroup;
  dataGruposMaquinas: any[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;
  user = this.usuariosService.userValue;

  constructor(private maquinasService: MaquinasService,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private modalService: NgbModal) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/maquinasgruposplanificador') {
          this.menuService.titulo = this.translateService.instant('maquinasgruposPlanificador');
          this.cargarDatos();
        } else {
          if (this.router.url == '/maquinasgruposplanificador') {
            this.menuService.titulo = this.translateService.instant('maquinasgruposPlanificador');
          }
        }
      }
    });

    //this.menuService.titulo = this.translateService.instant('maquinasgruposPlanificador');
    //this.cargarDatos();
  }

  cargarDatos() {
    this.maquinasService.getGruposMaquinasPlanificador().subscribe(
      (result) => {
        this.dataGruposMaquinas = result.data;
      });
  }

  ngOnInit(): void {
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['maquinasgruposplanificador/editar/', e.dataItem.id]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['maquinasgruposplanificador/editar/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['maquinasgruposplanificador/crear']);
  }
  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        this.form = this.formBuilder.group({
          idGrupo: element
        });
        this.maquinasService.deleteGrupoMaquinasPlanificador(this.form.value)
          .pipe(first())
          .subscribe(() => {
            this.isDeleting = false;
            this.dataGruposMaquinas = this.dataGruposMaquinas.filter(x => x.id !== element)
          });
      }
      this.modalReference.close();
    });
    this.mySelection = [];
  }

}
