import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
//import * as archivos_tutorial from '../../assets/videos_tutorial.json';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html'
})
export class TutorialComponent {

  user = this.userService.userValue;

  video1: string= "";
  video2: string= "";
  video3: string= "";
  video4: string= "";
  video5: string= "";
  video6: string= "";
  video7: string= "";
  video8: string= "";
  video9: string= "";

  videoUrl: any;
  videoUrl2: any;

  constructor(private menuService: MenuService,
    private userService: UsuariosService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer  ) {

    this.menuService.titulo = this.translate.instant('tutorial').toUpperCase();

    //this.videos = this.archivos.default.videos;

    //this.video1 = this.videos[0];
    //this.video2 = this.videos[1];
    //this.video3 = this.videos[2];
    //this.video4 = this.videos[3];
    //this.video5 = this.videos[4];
    //this.video6 = this.videos[5];
    //this.video7 = this.videos[6];
    //this.video8 = this.videos[7];
    //this.video9 = this.videos[8];


    if (this.user.idioma == 'en-GB' || this.user.idioma == 'en'){
      this.video1 = "../../assets/tutoriales/eng/Eris-Platform-ENG.mp4";
      this.video2 = "../../assets/tutoriales/eng/Eris-Home-ENG.mp4";
      this.video3 = "../../assets/tutoriales/eng/Eris-Editor-de-planta-ENG.mp4";
      this.video4 = "../../assets/tutoriales/eng/Eris-Proyectos.mp4";
      this.video5 = "../../assets/tutoriales/eng/Eris-Turnos-ENG.mp4";
      this.video6 = "../../assets/tutoriales/eng/Eris-OEE-eng.mp4";
      this.video7 = "../../assets/tutoriales/eng/Eris-Informes-eng.mp4";
      this.video8 = "../../assets/tutoriales/eng/Eris-Planner-ENG.mp4";
      this.video9 = "../../assets/tutoriales/eng/Eris-Action-plans-ENG.mp4";
    } 
    else{
      this.video1 = "../../assets/tutoriales/esp/Eris-Plataforma.mp4";
      this.video2 = "../../assets/tutoriales/esp/Home.mp4";
      this.video3 = "../../assets/tutoriales/esp/Eris-disenador de planta.mp4";
      this.video4 = "../../assets/tutoriales/esp/Eris-Proyectos.mp4";
      this.video5 = "../../assets/tutoriales/esp/Eris-Turnos.mp4";
      this.video6 = "../../assets/tutoriales/esp/Eris-OEE.mp4";
      this.video7 = "../../assets/tutoriales/esp/Eris-Informes.mp4";
      this.video8 = "../../assets/tutoriales/esp/Eris - Planificador.mp4";
      this.video9 = "../../assets/tutoriales/esp/Eris - Planes de accion.mp4";

    }      
  }

  ngOnInit(): void {
    
  }
}
