<style>
  .form-horizontal.col-xs-12.col-sm-12.col-md-6.col-lg-6.col-xl-4.panelosoa {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .reldiv1 {
    height: 0px;
    width: 0px;
    position: relative;
  }

  /* :host /deep/ .c3-drag-zoom {
    transform: translate(124.5px, 4.5px);
  } */

  :host /deep/ .ocultarGrafico .c3-chart-lines {
    visibility: hidden;
  }

  :host /deep/ .ocultarGrafico .c3-axis-y {
    visibility: hidden;
    display: none;
  }

  :host /deep/ .ocultarGrafico .c3-axis-y2 {
    visibility: hidden;
    display: none;
  }

  /*GRAFICOS NUEVOS*/
  :host /deep/ #grafico2_hmiHor .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico3_hmiHor .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico4_hmiHor .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico5_hmiHor .c3-axis-x {
    display: none;
  }

  /* :host /deep/ #grafico1_hmiHor .c3-axis-y {
    transform: translate(0px, 0px);
  }

  :host /deep/ #grafico2_hmiHor .c3-axis-y {
    transform: translate(-32px, 0px);
  }

  :host /deep/ #grafico3_hmiHor .c3-axis-y {
    transform: translate(-64px, 0px);
  }

  :host /deep/ #grafico4_hmiHor .c3-axis-y {
    transform: translate(-96px, 0px);
  }

  :host /deep/ #grafico4_hmiHor .c3-axis-y {
    transform: translate(-96px, 0px);
  }

  :host /deep/ #grafico5_hmiHor .c3-axis-y {
    transform: translate(-128px, 0px);
  }

  :host /deep/ #grafico5_hmiHor .c3-axis-y {
    transform: translate(-128px, 0px);
  } */

  :host /deep/ #grafico1_hmiHor .c3-region.regionTiempoInyeccion {
    fill: #ac5cad;
  }

  :host /deep/ #grafico1_hmiHor .c3-region.regionTiempoPresionRemanente {
    fill: #57a9aa;
  }

  :host /deep/ #grafico1_hmiHor .c3-region.regionTiempoPlastificacion {
    fill: #adadad;
  }

  /*FIN GRAFICOS NUEVOS*/
</style>

<div class="row hmi-hornos-cont">
  <!-- PANEL HOME -->
  <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

  <div class="col-md-4 card">
    <label ID="lResumenSemana">{{ 'resumenSemana' | translate }}</label>
    <div class="donut-resumen-semana-hmi">
      <div id="chartDonutResumenSemana_hmi"></div>
    </div>
  </div>

  <div class="col-md-4">
    <div class="row">

      <!--RECETAS-->
      <div class="col-lg-12" style="height: 356px;">
        <div class="panel-procesos">
          <div class="contenido-panel-procesos" style=" height: 339px; padding: 0;">

            <div class="datos-of">

              <div class="pdatos-of-inner">

                <!--LINEA TITULO-->
                <div class="datos-of-content">
                  <ul style="margin-bottom: 0;">
                    <li class="datos-li-titulo">
                      <label class="label-datos-valor-home" style="width: 15%;">{{ 'nCiclo' | translate }}</label>
                      <label class="label-datos-valor-home" style="width: 21%;">{{ 'piezas' | translate }}</label>
                      <label class="label-datos-valor-home" style="width: 32%;">{{ 'tiempoCiclo' | translate }}</label>
                      <label class="label-datos-valor-home" style="width: 32%;">{{ 'fechaHora' | translate }}</label>
                    </li>
                  </ul>
                </div>

                <!--LISTA-->
                <div class="datos-of-content">
                  <ul style="margin-bottom: 0;">
                    <li class="linea-ciclos-inyectora" *ngFor="let receta of listaRecetas"
                      [ngClass]="{'ciclo-seleccionado': receta.seleccionado ? true : false, 'ciclo-rojo': receta.rojo == 1 ? true : false }"
                      (click)="clickReceta(receta)">
                      <label class="label-datos-valor-home" style="width: 15%;">{{receta.nReceta}}</label>
                      <label class="label-datos-valor-home" style="width: 21%;">{{receta.piezas}}</label>
                      <label class="label-datos-valor-home"
                        style="width: 32%;">{{myFunctions.secondsTo_HH_MM_SS(receta.tiempoInyeccion)}}</label>
                      <label class="label-datos-valor-home"
                        style="width: 32%;">{{myFunctions.dateToString(receta.fechaIni)}}</label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<!--PESTANAS-->
<div class="row">
  <div class="col">

    <kendo-tabstrip [keepTabContent]="true">

      <!--DATOS-->
      <kendo-tabstrip-tab title="{{ 'datos' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">

            <!--VALORES TECNOLOGICOS-->
            <div class="col-md-3">
              <div class="datos-of">

                <div class="pdatos-of-inner">

                  <!--LINEA TITULO-->
                  <div class="datos-of-content">
                    <ul style="margin-bottom: 0;">
                      <li class="datos-li-titulo">
                        <label class="label-datos-valor-home" style="width: 40%;">{{ 'valoresTecnologicos' |
                          translate}}</label>
                        <label class="label-datos-valor-home" style="width: 12%;">{{ 'dim' | translate}}</label>
                        <label class="label-datos-valor-home" style="width: 12%;">{{ 'toler' | translate}} %</label>
                        <label class="label-datos-valor-home" style="width: 18%;">{{ 'programado' | translate}}</label>
                        <label class="label-datos-valor-home" style="width: 18%;">{{ 'actual' | translate}}</label>
                      </li>
                    </ul>
                  </div>

                  <!--LISTA-->
                  <div class="datos-of-content">
                    <ul style="margin-bottom: 0;">
                      <li class="linea-ciclos-inyectora" *ngFor="let valor of listaValoresTecnologicos"
                        [ngClass]="{'ciclo-rojo': valor.lineaRoja ? true : false}">
                        <label class="label-datos-valor-home" [title]="valor.nombre"
                          style="width: 40%;">{{valor.nombre}}</label>
                        <label class="label-datos-valor-home" style="width: 12%;">{{valor.dim}}</label>
                        <label class="label-datos-valor-home" style="width: 12%;">±{{valor.toler}}</label>
                        <label class="label-datos-valor-home" style="width: 18%;">{{valor.programado}}</label>
                        <label class="label-datos-valor-home" style="width: 18%;">{{valor.actual}}</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <!--GRAFICO-->
            <div class="col-md-6 grafico-multiardatza" oncontextmenu="return false;">

              <div style="height: 310px;">
                <button kendoButton look="flat" [icon]="'zoom-out'"
                  style="float: right; z-index: 2; margin-right: 10px;" (click)="onClickMostrarTodos()">{{
                  'mostrarTodos' | translate }}</button>
                <div class="reldiv1" id="grafico5_hmiHor" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[4]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico4_hmiHor" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[3]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico3_hmiHor" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[2]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico2_hmiHor" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[1]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico1_hmiHor" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[0]"
                  style="z-index: 1;"></div>
              </div>

              <!--BOTONES LEYENDA-->
              <div class="row" style="min-height: 50px; margin-top: -10px;">

                <div class="col-md-11">
                  <div class="leyendas-chart row justify-content-md-center">

                    <ng-container *ngFor="let campo of listaTooltip">
                      <div class="leyenda-chart col-auto" (click)="clicklLeyenda(campo)"
                        style="cursor: pointer; z-index: 1;" *ngIf="campo.ensennar">
                        <div class="leyenda-chart-color" *ngIf="campo.seleccionadoLeyenda"
                          [style]="'background-color:' + campo.color"></div>
                        <div class="leyenda-chart-color" *ngIf="!campo.seleccionadoLeyenda"></div>
                        <button class="leyenda-chart-label">{{ campo.nombre | translate }}</button>
                      </div>
                    </ng-container>

                  </div>
                </div>

              </div>

            </div>

            <!--PESTANAS RECETA-->
            <div class="col-md-3" *ngIf="maquina.tipoMaquinaInduccion != 2">
              <kendo-tabstrip>

                <!--SOPLADORES-->
                <kendo-tabstrip-tab title="{{ 'sopladores' | translate}}" [selected]="true">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">

                      <div class="pdatos-of-inner">

                        <!--LINEA TITULO-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="datos-li-titulo">
                              <label class="label-datos-valor-home" style="width: 50%;">{{ 'nombre' | translate
                                }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'recetaOriginal' | translate
                                }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'recetaModificada' |
                                translate }}</label>
                            </li>
                          </ul>
                        </div>

                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <ng-container *ngFor="let valor of listaReceta">
                              <li class="linea-ciclos-inyectora" *ngIf="valor.estaEnPestanna == 1 && valor.ensennar"
                                [ngClass]="{'ciclo-rojo': (valor.original != valor.modificado && valor.original != '-') ? true : false}">
                                <label class="label-datos-valor-home" [title]="valor.nombre"
                                  style="width: 50%;">{{valor.nombre}}</label>
                                <label class="label-datos-valor-home"
                                  [ngClass]="{'icon-check color-verde': valor.original, 'icon-equis color-rojo': !valor.original}"
                                  style="width: 25%;"></label>
                                <label class="text-center"
                                  [ngClass]="{'icon-check color-verde': valor.modificado, 'icon-equis color-rojo': !valor.modificado}"
                                  style="width: 25%;"></label>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

                <!--PORCENTAJES-->
                <kendo-tabstrip-tab title="{{ 'porcentajes' | translate}}">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">

                      <div class="pdatos-of-inner">

                        <!--LINEA TITULO-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="datos-li-titulo">
                              <label class="label-datos-valor-home" style="width: 60%;"></label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'recetaOriginal' | translate
                                }}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'recetaModificada' |
                                translate }}</label>
                            </li>
                          </ul>
                        </div>

                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <ng-container *ngFor="let valor of listaReceta">
                              <li class="linea-ciclos-inyectora" *ngIf="valor.estaEnPestanna == 2 && valor.ensennar"
                                [ngClass]="{'ciclo-rojo': (valor.original != valor.modificado && valor.original != '-') ? true : false}">
                                <label class="label-datos-valor-home" [title]="valor.nombre"
                                  style="width: 60%;">{{valor.nombre}}</label>
                                <label class="label-datos-valor-home" style="width: 20%;">{{valor.original}}
                                  {{valor.unidad}}</label>
                                <label class="label-datos-valor-home" style="width: 20%;">{{valor.modificado}}
                                  {{valor.unidad}}</label>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

                <!--TIEMPOS-->
                <kendo-tabstrip-tab title="{{ 'tiempos' | translate}}">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">

                      <div class="pdatos-of-inner">

                        <!--LINEA TITULO-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="datos-li-titulo">
                              <label class="label-datos-valor-home" style="width: 60%;"></label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'recetaOriginal' | translate
                                }}</label>
                              <label class="label-datos-valor-home" style="width: 20%;">{{ 'recetaModificada' |
                                translate }}</label>
                            </li>
                          </ul>
                        </div>

                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <ng-container *ngFor="let valor of listaReceta">
                              <li class="linea-ciclos-inyectora" *ngIf="valor.estaEnPestanna == 3 && valor.ensennar"
                                [ngClass]="{'ciclo-rojo': (valor.original != valor.modificado && valor.original != '-') ? true : false}">
                                <label class="label-datos-valor-home" [title]="valor.nombre"
                                  style="width: 60%;">{{valor.nombre}}</label>
                                <label class="label-datos-valor-home" style="width: 20%;">{{valor.original}}
                                  {{valor.unidad}}</label>
                                <label class="label-datos-valor-home" style="width: 20%;">{{valor.modificado}}
                                  {{valor.unidad}}</label>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

                <!--DUCHAS-->
                <kendo-tabstrip-tab title="{{ 'duchas' | translate}}">
                  <ng-template kendoTabContent>
                    <div class="datos-of" style="height: 300px;">

                      <div class="pdatos-of-inner">

                        <!--LINEA TITULO-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <li class="datos-li-titulo">
                              <label class="label-datos-valor-home" style="width: 50%;"></label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'recetaOriginal' | translate
                                }}</label>
                              <label class="label-datos-valor-home" style="width: 25%;">{{ 'recetaModificada' |
                                translate }}</label>
                            </li>
                          </ul>
                        </div>

                        <!--LISTA-->
                        <div class="datos-of-content">
                          <ul style="margin-bottom: 0;">
                            <ng-container *ngFor="let valor of listaReceta">
                              <li class="linea-ciclos-inyectora" *ngIf="valor.estaEnPestanna == 4 && valor.ensennar"
                                [ngClass]="{'ciclo-rojo': (valor.original != valor.modificado && valor.original != '-') ? true : false}">
                                <label class="label-datos-valor-home" [title]="valor.nombre"
                                  style="width: 50%;">{{valor.nombre}}</label>
                                <label class="label-datos-valor-home" style="width: 25%;">{{valor.original}}
                                  {{valor.unidad}}</label>
                                <label class="label-datos-valor-home" style="width: 25%;">{{valor.modificado}}
                                  {{valor.unidad}}</label>
                              </li>
                            </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </kendo-tabstrip-tab>

              </kendo-tabstrip>

            </div>

          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--PIEZAS-->
      <!-- <kendo-tabstrip-tab title="{{ 'piezas' | translate }}">
        <ng-template kendoTabContent>
          <div>

          </div>
        </ng-template>
      </kendo-tabstrip-tab> -->

      <!--PROCESOS-->
      <kendo-tabstrip-tab id="tabProcesos" title="{{ 'procesos' | translate}}"
        [selected]="datosGraficoArray.length == 0">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'historicoProcesos' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let historico of historicoProcesos">
                    <div class={{historico.divcss}}>
                      <div class="clearfix">
                        <div class="proces-fecha-cont">
                          <div id="horaIni" class="horaIni">
                            <label>{{historico.horaini}}</label>
                          </div>
                          <div id="diaIni" class="diaIni">
                            <label>{{historico.diaini}}</label>
                          </div>
                        </div>

                        <div class="proces-info-cont">
                          <div id="tipoProceso" *ngIf="historico.idProcesos_Tipo!=6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.nombre}}</label>
                          </div>
                          <div *ngIf="historico.idProcesos_Tipo==6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.tipoAlarNombre}}</label>
                          </div>
                          <div id="tiempos">
                            <label><span class="proces-titulo-uno">{{ 'tiempoReal' | translate}}:</span>
                              {{historico.tiemporeal}} <span class="proces-titulo-uno"
                                *ngIf="historico.idProcesos_Tipo!=2">{{ 'tiempoEstimado' | translate}}:</span><span
                                *ngIf="historico.idProcesos_Tipo!=2"> {{historico.tiempoteorico}}</span></label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{historico.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{historico.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{historico.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{historico.pieza}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'alarmas' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let alarma of alarmas">

                    <div class={{alarma.divcss}}>

                      <div class="clearfix">
                        <div class="proces-fecha-cont">

                          <div id="horaIni">
                            <label>{{alarma.horaini}}</label>
                          </div>
                          <div id="diaIni">
                            <label>{{alarma.diaini}}</label>
                          </div>

                        </div>

                        <div class="proces-info-cont">
                          <div id="numeroAlarma">
                            <label>{{alarma.numeroAlarma}}</label> - <label>{{alarma.descripcion.replace('ALARMA: ',
                              '')}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'planificado' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let planificado of planificados">

                    <div class={{planificado.divcss}}>

                      <div class="clearfix">

                        <div class="proces-fecha-cont">
                          <div id="tiempos" class="horaIni">
                            <label>{{planificado.horaini}}</label>
                          </div>

                          <div id="tiempos" class="diaIni">
                            <label>{{planificado.diaini}}</label>
                          </div>
                        </div>

                        <div class="proces-info-cont">
                          <div id="operacion">
                            <label id="operacion"><span class="proces-titulo-uno">{{ 'operacion' | translate}}:</span>
                              {{planificado.operacion}}</label>
                          </div>
                          <div id="tiempoTeorico">
                            <label><span class="proces-titulo-uno">{{ 'tiempoEstimado' | translate}}:</span>
                              {{planificado.tiempoteorico}}</label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{planificado.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{planificado.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{planificado.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{planificado.pieza}}</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--MANTENIMIENTOS-->
      <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row cont-mantenimientos">
            <div class="col-md-6">
              {{ 'porFechas' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-messages noRecords="{{'noMantenimientosDos' | translate}}"></kendo-grid-messages>
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="41%">
                  </kendo-grid-column>
                  <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span>{{ dataItem.tipo | translate }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}"
                    filterAfterOperator="{{'filterAfterOperator' | translate}}"
                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                    filterAndLogic="{{'filterAndLogic' | translate}}"
                    filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                    filterBooleanAll="{{'filterBooleanAll' | translate}}"
                    filterClearButton="{{'filterClearButton' | translate}}"
                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                    filterDateToday="{{'filterDateToday' | translate}}"
                    filterDateToggle="{{'filterDateToggle' | translate}}"
                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                    filterEqOperator="{{'filterEqOperator' | translate}}"
                    filterFilterButton="{{'filterFilterButton' | translate}}"
                    filterGtOperator="{{'filterGtOperator' | translate}}"
                    filterGteOperator="{{'filterGteOperator' | translate}}"
                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                    filterIsFalse="{{'filterIsFalse' | translate}}"
                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                    filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                    filterLteOperator="{{'filterLteOperator' | translate}}"
                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                    filterOrLogic="{{'filterOrLogic' | translate}}"
                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                    loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                    lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
              </div>
            </div>
            <div class="col-md-6">
              {{ 'porTiempos' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="51%">
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}"
                    filterAfterOperator="{{'filterAfterOperator' | translate}}"
                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                    filterAndLogic="{{'filterAndLogic' | translate}}"
                    filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                    filterBooleanAll="{{'filterBooleanAll' | translate}}"
                    filterClearButton="{{'filterClearButton' | translate}}"
                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                    filterDateToday="{{'filterDateToday' | translate}}"
                    filterDateToggle="{{'filterDateToggle' | translate}}"
                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                    filterEqOperator="{{'filterEqOperator' | translate}}"
                    filterFilterButton="{{'filterFilterButton' | translate}}"
                    filterGtOperator="{{'filterGtOperator' | translate}}"
                    filterGteOperator="{{'filterGteOperator' | translate}}"
                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                    filterIsFalse="{{'filterIsFalse' | translate}}"
                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                    filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                    filterLteOperator="{{'filterLteOperator' | translate}}"
                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                    filterOrLogic="{{'filterOrLogic' | translate}}"
                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                    loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                    lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--NOTAS-->
      <kendo-tabstrip-tab id="tabNotas" title="{{ 'notas' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <kendo-grid [data]="notas" style="height: 400px">
              <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
    <div *ngIf="loadingPestannas" class="k-i-loading" style="z-index: 2;"></div>

  </div>
</div>