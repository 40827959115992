<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>


  <!-- TODO -->
  <div class="clearfix">
    <div class="card">
      <div class="card-body">
        <!--BOTONES Y COMBO DE ARRIBA-->
        <div class="row ml-2">
          <div class="float-left mr-2">
            <button kendoButton class=" btn btn-primary btn-sm mr-1" (click)="nuevoClick()">{{ 'nuevo' |
              translate}}</button>
          </div>
          <div class="float-left mr-2" style="width:200px">
            <kendo-combobox [data]="Jsemanas" [textField]="'text'" [valueField]="'value'" [(value)]="JsemanaSelected"
              (valueChange)="semanaSelectedChange()" [clearButton]="false"></kendo-combobox>
          </div>
          <div class="float-left mr-2">
            <button kendoButton class=" btn btn-success btn-sm mr-1" (click)="vistaAnualClick()">{{ 'vistaAnual' |
              translate}} </button>
          </div>
        </div>

        
        <!--GANTT -->


        <div class="clearfix">
          <div class="gantt-horariosSecciones-maquinas">
            <table>
              <tr>
                <td>
                  <div  id="gantt-chart" style="min-height:250px;"></div>
                </td>
              </tr>
            </table>
          </div>


          <div class="gantt-horariosSecciones-dias" style="overflow-x:scroll;">
            <table>
              <tr>

                <td>
                  <div id="gantt-chart-lunes" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:20px"></div>
                </td>
                <td>
                  <div id="gantt-chart-martes" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:20px"></div>
                </td>
                <td>
                  <div id="gantt-chart-miercoles" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:20px"></div>
                </td>
                <td>
                  <div id="gantt-chart-jueves" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:20px"></div>
                </td>
                <td>
                  <div id="gantt-chart-viernes" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:20px"></div>
                </td>
                <td>
                  <div id="gantt-chart-sabado" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
                <td>
                  <div style="width:20px"></div>
                </td>
                <td>
                  <div id="gantt-chart-domingo" style="min-height:250px; max-width: 400px;"></div>
                  <div *ngIf="loadingGantt" class="k-i-loading"></div>
                </td>
              </tr>
            </table>
          </div>
        </div>
       

        <!--BOTONES DE ABAJO-->
        <div class="row ml-2 mt-2">
          <div class="float-left mr-2">
            <button kendoButton class=" btn btn-copiar"
              (click)="seleccionarReplicarSemanaClick()">{{'replicarSemana'|translate}}</button>
          </div>
          <div class="float-left mr-2">
            <button kendoButton class=" btn btn-danger"
              (click)="seleccionarEliminarClick()">{{'seleccionHorariosEliminar'|translate}}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!--MENU LATERAL-->
  <div class="panel-menu" [ngClass]="{ 'desplegado': menuDesplegado }"
    [ngStyle]="{ 'width': menuDesplegado ? '55%' : '0px'}" #menuLateral>
    <div class="card-header">
      <h3 *ngIf="JsemanaSelected!=undefined && user.idioma!='eu'">{{'reserva' | translate}} {{JsemanaSelected.text}}
      </h3>
      <h3 *ngIf="JsemanaSelected!=undefined  && user.idioma=='eu'">{{'reserva' | translate}} {{JsemanaSelected.text}}
      </h3>

    </div>
    <div class="card-body">
      <div *ngIf="!avisoPeriodoFuera && !avisosHorasFuera">
        <div>
          <label class="control-label">{{ 'grupo' | translate }}</label>
          <div class="caja">
            <kendo-dropdownlist id="grupoDropdown" [data]="grupos" [(value)]="grupoSelected" [textField]="'nombre'" [valueField]="'id'"
              (valueChange)="grupoSelectedChange($event)" (click)="onClickSelectedGrupo()" [filterable]="true" [kendoDropDownFilter]="{operator: 'contains'}">
         
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                <div [style.background]="dataItem.color" class="dropdownItem p-0" [style.text-align]="center">
                  {{ dataItem.nombre }}
                </div>
              </ng-template>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <div [style.background]="dataItem.color" class="dropdownItem p-0">
                  {{ dataItem.nombre}}
                </div>
              </ng-template>


            </kendo-dropdownlist>
          </div>
        </div>
        <table *ngIf="grupoSelected.length!=0 && horariosSeccionesGrupo.length!=0">
          <tr>
            <td><kendo-label class="form-check-label" text="{{ 'dia' | translate}}"> </kendo-label></td>
            <td><kendo-label class="form-check-label" text="{{ 'inicio' | translate}}"> </kendo-label></td>
            <td><kendo-label class="form-check-label" text="{{ 'fin' | translate}}"> </kendo-label></td>
            <td><kendo-label class="form-check-label" text="{{ 'maquinasgrupo' | translate}}"> </kendo-label></td>
            <td><kendo-label class="form-check-label" text="{{ 'maquinasaReservar' | translate}}"> </kendo-label></td>
            <td></td>
          </tr>

          <ng-container *ngFor="let hs of horariosSeccionesGrupo">
            <tr>
              <td> <kendo-dropdownlist [data]="diasSemana" [textField]="'dia'" [valueField]="'fecha'"
                  [(value)]="hs.diaItem"></kendo-dropdownlist> </td>
              <td><kendo-timepicker [formatPlaceholder]="{ hour: '00', minute: '00'}" [(value)]="hs.startDate">
                <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
              </kendo-timepicker-messages>  
              </kendo-timepicker></td>
              <td> <kendo-timepicker [formatPlaceholder]="{ hour: '00', minute: '00'}" [(value)]="hs.endDate">
                <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
              </kendo-timepicker-messages>  
              </kendo-timepicker></td>
              <td><kendo-dropdownlist [data]="gruposMaquinas" [textField]="'nombre'" [valueField]="'id'"
                  [(value)]="hs.gMaquina"></kendo-dropdownlist></td>
              <td> <kendo-numerictextbox [(value)]="hs.cantidadMaquinas" type="number" format="n0" [min]="1"
                  [max]="hs.gMaquina.maquinasKop" [autoCorrect]="true">
                </kendo-numerictextbox></td>
              <td><button Class="flt-eliminar" (click)="annadirEliminado(hs.id)"> <i class="fas fa-times"></i> </button>
              </td>
            </tr>
            <tr>
              <p class="m-0" *ngIf="hs.startDate >= hs.endDate" style="color:red;">{{ 'horasMal' | translate}}</p>
            </tr>
          </ng-container>



        </table>
        <div *ngIf="grupoSelected.length!=0" class="agregar-elemento" (click)="annadirLinea()">
          <i class="icon-mas-alt"></i>
        </div>
        <div *ngIf="grupoSelected.length!=0" class="row ml-2">
          <div class="float-left mr-2">
            <kendo-label class="form-check-label" text="{{ 'replicar' | translate}}"> </kendo-label>
            <div class="caja">
              <kendo-switch [onLabel]="' '" [offLabel]="' '" id="replicarSwitch"
                [(ngModel)]="replicarHorario"></kendo-switch>
            </div>
          </div>
          <div *ngIf="grupoSelected.length!=0" class="float-left mr-2">
            <kendo-label class="form-check-label" text="{{ 'periodo' | translate}}"> </kendo-label>
            <filtro-fechas [fechaIni]="inicioMenuLateral" [fechaFin]="finMenuLateral"
              (newFechaInicio)="actualizarFechaInicioMenuLateral($event)"
              (newFechaFin)="actualizarFechaFinMenuLateral($event)"></filtro-fechas>
          </div>
          <div class="float-left mr-2">
            <kendo-label class="form-check-label" text="{{ 'repeticion' | translate}}"> </kendo-label>
            <div class="caja">
              <div class="btn mr-1 ml-1" [class.btn-success]="repSemanal" [class.btn-outline-success]="!repSemanal"
                (click)="repSemanal=true">
                <label class="k-radio-label">{{ 'semanal' | translate }}</label>
              </div>
              <div class="btn mr-1" [class.btn-success]="!repSemanal" [class.btn-outline-success]="repSemanal"
                (click)="repSemanal=false">
                <label class="k-radio-label">{{ 'semanasAlternas' | translate }}</label>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="errorFechasUndefined || errorHoras || errorHorasExisten || errorHorasFueraCentro">
          <ul class="listaerrores">
            <li style="color:red" *ngIf="errorFechasUndefined">{{ 'periodoRequerido' | translate}}</li>
            <li style="color:red" *ngIf="errorHoras">{{ 'horasMal' | translate}}</li>
            <li style="color:red" *ngIf="errorHorasExisten">{{ 'horasExisten' | translate}}</li>
            <li style="color:red" *ngIf="errorHorasFueraCentro">{{ 'errorHorasFueraCentro' | translate}}</li>
          </ul>

        </div>

      </div>
      <div *ngIf="avisoPeriodoFuera || avisosHorasFuera" style="text-align:center;">
        <p *ngIf="avisoPeriodoFuera"><span>{{'periodoFueraDeCurso'| translate}}</span></p>
        <p *ngIf="avisosHorasFuera"><span>{{'horasFueraDeCurso'| translate}}</span></p>
        <p><span>{{'quieresContinuar' | translate}}</span></p>
      </div>
      <div style="text-align: center;">
        <button kendoButton class="btn-primary" (click)="guardarClick()">{{'aceptar'|translate}}</button>
        <button kendoButton class="btn-danger" (click)="cancelarClick()">{{'cancelar'|translate}}</button>
      </div>
    </div>
  </div>

  <!--MODAL ELIMINAR HORARIO-->
  <ng-template #popupEliminar let-modal>
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">{{'eliminarHorario' | translate}}</h3>
      <button type="button" class="close" aria-label="Close" (click)=" cancelarModalEliminarClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="!resumen" class="modal-body">

      <!--GRUPOS-->
      <div class="col form-group">
        <kendo-label class="form-check-label" text="{{ 'grupo' | translate}}"> </kendo-label>
        <kendo-multiselect [data]="grupos" [(value)]="grupoSelectedModalEliminar"
          (valueChange)="valueChangeDropdownEliminarModal($event,'grupo')" [textField]="'nombre'" [valueField]="'id'" [kendoDropDownFilter]="{operator: 'contains'}" (click)="onClickSelectedGrupo()">
          <ng-template kendoMultiSelectTagTemplate let-dataItem>
            <div [style.background]="dataItem.color" class="dropdownItem p-0" [style.text-align]="center">
              {{ dataItem.nombre }}
            </div>
          </ng-template>
          <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <div [style.background]="dataItem.color" class="dropdownItem p-0">
              {{ dataItem.nombre}}
            </div>
          </ng-template>

        </kendo-multiselect>
      </div>
      <!--PERIODO-->
      <div class="col form-group">
        <kendo-label class="form-check-label" text="{{ 'periodo' | translate}}"> </kendo-label>
        <filtro-fechas [fechaIni]="inicioModalEliminar" [fechaFin]="finModalEliminar"
          (newFechaInicio)="actualizarFechaInicioModalEliminar($event)"
          (newFechaFin)="actualizarFechaFinModalEliminar($event)"></filtro-fechas>
      </div>
      <!--DIAS DE LA SEMANA-->
      <div class="col form-group">
        <kendo-label class="form-check-label" text="{{ 'diasSemana' | translate}}"> </kendo-label>
        <kendo-multiselect [data]="diasDeLaSemanaDropdown" [(value)]="diasSemanaSelectedModalEliminar"
          (valueChange)="valueChangeDropdownEliminarModal($event, 'semana')" [textField]="'nombre'" [valueField]="'id'">
        </kendo-multiselect>
      </div>
      <!--GRUPOS DE MÁQUINAS-->
      <div class="col form-group">
        <kendo-label class="form-check-label" text="{{ 'maquinasgrupos' | translate}}"> </kendo-label>
        <kendo-multiselect [data]="gruposMaquinas" [(value)]="gruposMaquinasSelectedModalEliminar"
          (valueChange)="valueChangeDropdownEliminarModal($event, 'grupoM')" [textField]="'nombre'" [valueField]="'id'">
        </kendo-multiselect>
      </div>
      <div class="col form-group">
       <p *ngIf = "vacio" style="color:red">{{'seleccioneEliminacion' | translate}}</p>
      </div>

    </div>
    <div *ngIf="resumen && !vacio" class="modal-body">
      <div class="col form-group">
        <p>{{'seguroEliminarPeriodo' | translate}}</p>
        <!--resumen grupos-->
        <p *ngIf="grupoSelectedModalEliminar.length>0"><span>{{'grupoS' | translate}} </span>
          <ng-container *ngFor="let grupo of grupoSelectedModalEliminar; let i = index ">
            <b *ngIf="i<grupoSelectedModalEliminar.length-1">{{grupo.nombre}}, </b>
            <b *ngIf="i==grupoSelectedModalEliminar.length-1">{{grupo.nombre}}</b>
          </ng-container>
        </p>
        <!--rsumen periodo-->
        <p *ngIf="inicioModalEliminar != undeffined && finModalEliminar !=undefined && user.idioma!='eu'">
          <span>{{'deDesde' | translate}} </span>
          <b>{{myFunctions.datetimeToSQL(inicioModalEliminar).split(' ')[0]}}</b>
          <span>{{'a' | translate}} </span>
          <b>{{myFunctions.datetimeToSQL(finModalEliminar).split(' ')[0]}}</b>
        </p>
        <p *ngIf="inicioModalEliminar != undeffined && finModalEliminar !=undefined && user.idioma=='eu'">
          <b>{{myFunctions.datetimeToSQL(inicioModalEliminar).split(' ')[0]}}</b><span>{{'deDesde' | translate}} </span>
          <b>{{myFunctions.datetimeToSQL(finModalEliminar).split(' ')[0]}}</b> <span>{{'a' | translate}} </span>
        </p>

        <!--resumen dias semana-->
        <p *ngIf="diasSemanaSelectedModalEliminar.length>0">
          <ng-container *ngFor="let dia of diasSemanaSelectedModalEliminar; let i = index ">
            <b *ngIf="i<diasSemanaSelectedModalEliminar.length-1">{{dia.nombre}}, </b>
            <b *ngIf="i==diasSemanaSelectedModalEliminar.length-1">{{dia.nombre}}</b>
          </ng-container>
        </p>
        <!--resumen grupos de maquinas-->
        <p *ngIf="gruposMaquinasSelectedModalEliminar.length>0"><span>{{'maquinasgrupos' | translate}} </span>
          <ng-container *ngFor="let gm of gruposMaquinasSelectedModalEliminar; let i = index ">
            <b *ngIf="i<gruposMaquinasSelectedModalEliminar.length-1">{{gm.nombre}}, </b>
            <b *ngIf="i==gruposMaquinasSelectedModalEliminar.length-1">{{gm.nombre}}</b>
          </ng-container>
        </p>
        <p>{{'quieresContinuar' | translate}}</p>
      </div>


    </div>
    <div class="modal-footer modal-botoiak-erdian">
      <button type="button" class="btn btn-danger" (click)="cancelarModalEliminarClick()">{{ 'cancelar' | translate
        }}</button>
      <button type="button" class="btn btn-primary" (click)="aceptarModalEminarClick()">{{ 'aceptar' | translate
        }}</button>
    </div>
  </ng-template>

  <!--MODAL REPLICAR SEMANA-->
  <ng-template #popupReplicar let-modal>

    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">{{'replicarSemana' | translate}}</h3>
      <button type="button" class="close" aria-label="Close" (click)=" cancelarModalReplicarSemanaClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="!resumenReplicar" class="modal-body">
      <kendo-label class="form-check-label" text="{{ 'periodo' | translate}}"> </kendo-label>
      <filtro-fechas [fechaIni]="inicioModalReplicar" [fechaFin]="finModalReplicar"
        (newFechaInicio)="actualizarFechaInicioModalReplicar($event)"
        (newFechaFin)="actualizarFechaFinModalReplicar($event)"></filtro-fechas>
      <p style="color:red;" *ngIf="errorFechasUndefined">{{ 'periodoRequerido' | translate}}</p>

    </div>
    <div *ngIf="resumenReplicar && inicioModalReplicar!=undefined && finModalReplicar!=undefined" class="modal-body"
      style="text-align:center;">
      <div class="col form-group">
        <p><span>{{'sobreescribirPeriodos' | translate}}</span></p>
        <p><b>{{myFunctions.datetimeToSQL(inicioModalReplicar).split(' ')[0]}}</b>
          -
          <b>{{myFunctions.datetimeToSQL(finModalReplicar).split(' ')[0]}}</b>
        </p>
        <p><span>{{'quieresContinuar' | translate}}</span></p>

      </div>

    </div>
    <div class="modal-footer modal-botoiak-erdian">
      <button type="button" class="btn btn-danger" (click)="cancelarModalReplicarSemanaClick()">{{ 'cancelar' |
        translate
        }}</button>
      <button type="button" class="btn btn-primary" (click)="aceptarModalReplicarSemanaClick()">{{ 'aceptar' | translate
        }}</button>
    </div>
  </ng-template>

<router-outlet></router-outlet>