import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, ConfiguracionService,  InformeOeeService, MenuService, MaquinasService, InformeProyectosService, OperariosService } from '@app/_services';
import { MyFunctions } from '@app/_helpers';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-informerendimientooee-data',
  templateUrl: 'informeRendimientoOEE.html'
})

export class InformeRendimientoOEEComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public loading1Finished = false;
  public loading2Finished = false;
  public loading3Finished = false;
  public loading4Finished = false;

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  user = this.userService.userValue;

  private dataGraficoDonutRendimiento: any;
  private dataGraficoHoras: any;
  private dataGraficoDonutParadas: any;
  private dataGraficoDonutMantenimientos: any;
  private dataGraficoDonutAlarmas: any;
  private dataGraficoClientes: any;
  private tickGraficoClientes: any;

  private graficoDonutRendimiento: any;
  private graficoHoras: any;
  private graficoDonutParadas: any;
  private graficoDonutMantenimientos: any;
  private graficoDonutAlarmas: any;
  private graficoClientes: any;

  public maquinas: any;
  public maquinasJ: any;
  public maquinasSeleccionadas: string[] = [];
  public datosJ: any;
  public datosOF: any = [];

  //public dpFechaIni: Date;
  //public dpFechaFin: Date;
  public AnnoMesSemanaDia: number;

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeccion: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //SOLO PARA CAF
  public usuarioIdDbCAF: any;

  // GRID PERDIDAS
  public datosPerdidasGlobal: any = [];
  public datosPerdidas: any = [];

  // TURNO
  private turno: number = -1;

  //FILTRO NUEVO
  public mostrarCalendario1: boolean = false;
  public hoy = new Date();
  public dpFechaIni: Date = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate() - 7);
  public dpFechaFin: Date = this.hoy;
  public DaysInMonths = this.hoy;
  public listaOperarios: any = [];
  public operariosSeleccionados: any = [];
  public seleccionarTodo: boolean = false;
  public listaOperariosSTR: string = "";
  public listaMaquinasAux: any = [];
  public listaOperariosAux: any = [];

  constructor(
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private informeOeeService: InformeOeeService,
    private informeProyectosService: InformeProyectosService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private operariosService: OperariosService,
    private configuracionService: ConfiguracionService
  ) {
    this.usuarioIdDbCAF = userService.userValue.idDb == 7 ? true : false;
    // configuracionService.get_configuracion_global().subscribe(
    //   j=>{
    //     this.config = j;
    //     console.log(j)
    // });
    if (this.configuracionService.get_config_global() == undefined)
      this.configuracionService.load_config_global().subscribe(j=> this.configuracionService.set_config_global(j[0]));
  }

  // DISPLAYS (estaban metidos con get element by id...)
  public checkboxGruposVisible: boolean = false;
  public lblNoParadas_RendVisible: boolean = true;

  public agrupado: number = 0; // 0 perdidas, 1 padres, 2 grupos

  ngOnInit() {
    this.loading1Finished = true;
    this.loading2Finished = true;
    this.loading3Finished = true;
    this.loading4Finished = true;

    this.menuService.titulo = this.translateService.instant('tituloRendimiento');

    this.dpFechaIni = this.informeOeeService.get_fechaIni_model();
    this.dpFechaFin = this.informeOeeService.get_fechaFin_model();
    this.AnnoMesSemanaDia = this.informeOeeService.get_AnnoMesSemanaDia_model();
    if (this.dpFechaIni === undefined) {
      // this.dpFechaIni = new Date(this.myFunctions.getDateNow().getTime() - (6 * 24 * 60 * 60 * 1000));//7 egun atzera
      // this.dpFechaFin = this.myFunctions.getDateNow()
      // this.dpFechaIni.setHours(0, 0, 0);
      // this.dpFechaFin.setHours(0, 0, 0);
      this.cargarFechas();
      this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
      this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
      this.AnnoMesSemanaDia = 4;
      this.informeOeeService.set_AnnoMesSemanaDia_model(this.AnnoMesSemanaDia);
    }

    this.cargarGrupos();
    this.cargarAreasProductivas();

    var bukatua = new Promise<void>((resolve, reject) => {

      var maquinas_model = this.informeOeeService.get_maquinas_model();
      var maquinasSeleccionadas_model = this.informeOeeService.get_maquinasSeleccionadas_model();
      var seccionesSeleccionadas_model = this.informeOeeService.get_seccionesSeleccionadas_model();
      var maquinasCompletas_model = this.informeOeeService.get_maquinasCompletas_model();
      var gruposSeleccionados_model = this.informeOeeService.get_gruposSeleccionados_model();

      if (maquinas_model == false) {
        this.informeOeeService.get_maquinas().subscribe(
          json => {
            this.maquinasJ = json;
            this.informeOeeService.set_maquinasCompletas_model(this.maquinasJ); //Necesitamos tenerlo al filtrar, pero lo guardamos en el servicio para no estar llamándolo siempre
            this.cargarMaquinas();
            resolve();
          }
        )
      } else {
        this.maquinas = maquinas_model;
        this.listaMaquinasAux = maquinas_model;
        this.maquinasSeleccionadas = maquinasSeleccionadas_model;
        this.seccionesSeleccionadas = seccionesSeleccionadas_model;
        this.gruposSeleccionados = gruposSeleccionados_model;
        this.maquinasJ = maquinasCompletas_model;
        this.cargarMaquinas2();
        resolve();
      }
      this.operariosService.getAllUsuariosConColor().subscribe(json => {
        var lag: any = json;
        lag.forEach(element => {
          this.listaOperarios.push(element);
          this.listaOperariosAux.push(element);
          //this.operariosSeleccionados.push(element.idUsuario);
        });
        this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
      });
    });

    bukatua.then(() => {
      this.cargarDonuts();
      this.cargarHoras();
      this.cargarDonutsLeyenda();
      this.cargarClientes();
      this.cargarGraficos();
    });

  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.dpFechaIni == undefined) {
      this.dpFechaIni = this.myFunctions.getDateNow()
    }
    fechaIni = this.dpFechaIni
    if (fechaIni.getDay() == 3) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.dpFechaIni);
    this.dpFechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposSeccion = this.grupos;
      this.gruposCargados = true;

      var aurk = false;
      this.gruposSeccion = [];
      this.grupos.forEach(grupo => {
        aurk = false;
        this.seccionesSeleccionadas.forEach(seccion => {
          if ((grupo.idSeccion == seccion.id) && !aurk) {
            aurk = true;
            this.gruposSeccion.push(grupo);
          }
        });
      });
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

    }

  }

  cargarMaquinas() {
    this.maquinas = [];
    this.listaMaquinasAux = [];
    var primeraMaquina = true;

    for (let maquina of this.maquinasJ) {
      var m = { id: maquina.id, nombre: maquina.nombre };
      this.maquinas.push(m);
      this.listaMaquinasAux.push(m);

      if (primeraMaquina) {
        this.maquinasSeleccionadas.push(maquina.id);
        primeraMaquina = false;
      }

    }

    this.informeOeeService.set_maquinas_model(this.maquinas);
    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
    this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
  }
  cargarMaquinas2() {
    var listaMaquinas: any;
    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        listaMaquinas = mostra;
        var maquinasAux = [];

        var seccionesSeleccionadas = [];
        this.seccionesSeleccionadas?.forEach(elem => seccionesSeleccionadas.push(elem.id));
        var gruposSeleccionados = [];
        this.gruposSeleccionados?.forEach(elem => gruposSeleccionados.push(elem.id));
        if (this.maquinasJ) {
          for (let maquina of this.maquinasJ) {
            var aux = listaMaquinas.filter(f => f.idMaquina === maquina.id)[0];
            if ((this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) || (this.gruposSeleccionados && this.gruposSeleccionados.length > 0)) {
              if (seccionesSeleccionadas.includes(aux?.idSeccion) || aux?.idsGrupos.filter(r => gruposSeleccionados.includes(Number(r))).length > 0) {
                var m = { id: maquina.id, nombre: maquina.nombre };
                maquinasAux.push(m);
              }

            } else {
              var m = { id: maquina.id, nombre: maquina.nombre };
              maquinasAux.push(m);
            }
            this.maquinas = maquinasAux;
            this.listaMaquinasAux = maquinasAux;

          }
          this.maquinasSeleccionadas.forEach(elem => {
            var aux = this.maquinas.filter(e => e.id === elem);
            if (aux.length === 0)
              this.maquinasSeleccionadas.splice(this.maquinasSeleccionadas.indexOf(elem), 1);
          });
          if (this.maquinasSeleccionadas.length === 0) {
            this.maquinasSeleccionadas.push(this.maquinas[0].id);
          }
        }

        this.informeOeeService.set_maquinas_model(this.maquinas);
        this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
        this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
        this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
      });
  }

  cargarDonuts() {
    this.dataGraficoDonutRendimiento = [['completo', 0], ['nocompleto', 100]];

    //GRAFICO DONUT RENDIMIENTO
    this.graficoDonutRendimiento = c3.generate({
      bindto: '#graficoDonutRendimiento_Rend',
      data: {
        columns: this.dataGraficoDonutRendimiento,
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: 2000
      },
      donut: {
        title: "0",
        width: 22,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });

    d3.select('#graficoDonutRendimiento_Rend .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#graficoDonutRendimiento_Rend .c3-chart-arcs path").style("stroke-width", "0px");

  }

  cargarHoras() {
    this.dataGraficoHoras = [[this.translateService.instant('total'), 0, 0, 0, 0],
    [this.translateService.instant('rendimiento'), 0, 0, 0, 0],
    [this.translateService.instant('paradas'), 0, 0, 0, 0],
    [this.translateService.instant('mantenimientos'), 0, 0, 0, 0],
    [this.translateService.instant('alarmas'), 0, 0, 0, 0],
    [this.translateService.instant('apagadas'), 0, 0, 0, 0],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, 0]];

    //GRAFICO HORAS BARRAS
    this.graficoHoras = c3.generate({
      data: {
        columns: this.dataGraficoHoras,
        order: null,
        type: 'bar',
        groups: [[this.translateService.instant('total'), this.translateService.instant('paradas'), this.translateService.instant('mantenimientos'),
        this.translateService.instant('alarmas'), this.translateService.instant('apagadas'), this.translateService.instant('rendimiento'), this.translateService.instant('perdidasCalidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#f99f4d'], ['#ebe078'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ffa8a8']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translateService.instant('tiempoTotal'), this.translateService.instant('disponibilidad'),
          this.translateService.instant('rendimiento'), this.translateService.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 0
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      onrendered: function () {
        d3.selectAll("#graficoHoras_Rend .c3-bar")
          .style("opacity", function (d) {
            if (d.index === 2) {
              return 1;
            } else {
              return 0.4
            }
          });
      },
      bindto: '#graficoHoras_Rend'
    });
  }



  cargarDonutsLeyenda() {

    this.dataGraficoDonutParadas = [];
    this.dataGraficoDonutMantenimientos = [];
    this.dataGraficoDonutAlarmas = [];
    var that = this;

    //GRAFICO DONUT PARADAS
    this.graficoDonutParadas = c3.generate({
      data: {
        columns: this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#donutLeyendaParadas_Rend .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#donutLeyendaParadas_Rend .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#donutLeyendaParadas_Rend .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right',
        item: {
          onclick: function (d) {
            //Primero miramos si esta oculto o mostrandose en el chart
            var auxiString = 'c3-legend-item-' + d;
            auxiString = auxiString.split(' ').join('-'); //Asi quitamos todos los espacios y los reemplazamos por '-', ya que no deja usar replaceAll
            var donutLeyenda = document.getElementById("donutLeyendaParadas_Disp").children[0].children[2];
            var nodos = donutLeyenda.getElementsByClassName("c3-legend-item");
            var nodo = undefined;
            for (let i = 0; i < nodos.length; i++) {
              const element = nodos[i];
              if (element.classList.contains(auxiString)) {
                nodo = element;
                break;
              }
            }
            if (nodo.classList.contains("c3-legend-item-hidden"))
              that.graficoDonutParadas.show(d);
            else
              that.graficoDonutParadas.hide(d);
            //Filtrar los datos que no tengan el hide en el grid
            //Primero hay que ver cuales hay que ocultar
            var elementos = donutLeyenda.getElementsByClassName("c3-legend-item-hidden");
            var listaElementosAQuitar = [];
            for (let i = 0; i < elementos.length; i++) {
              var element = elementos[i];
              //Borramos las clases que no nos interesan
              element.classList.remove("c3-legend-item");
              element.classList.remove("c3-legend-item-hidden");
              element.classList.remove("c3-legend-item-focused");
              //Ahora solo deberia quedar la clase que nos interesa para extraer el nombre
              if (element.classList.length > 0) {
                var idElemento = element.classList[0].split("c3-legend-item-")[1];
                if (idElemento.includes('.')) {
                  idElemento = idElemento.substring(0, idElemento.length - 3); //Para quitar los tres puntitos
                }
                idElemento = idElemento.split('-').join(' '); //Volvemos a ponerle los espacios
                listaElementosAQuitar.push(idElemento);
              }
              //Y ahora los volvemos a agregar para que no le cambien los estilos
              element.classList.add("c3-legend-item");
              element.classList.add("c3-legend-item-hidden");
              element.classList.add("c3-legend-item-focused");
            }
            //Ahora filtramos
            if (listaElementosAQuitar.length > 0) {
              switch (that.agrupado) {
                case 0:
                  that.datosPerdidas = that.datosPerdidasGlobal.filter((x) => {
                    var incluye = false;
                    for (let i = 0; i < listaElementosAQuitar.length; i++) {
                      if (x.perdida.includes(listaElementosAQuitar[i])) {
                        incluye = true;
                      }
                    }
                    if (!incluye) return x;
                    else return;
                  });
                  break;
                case 1:
                  that.datosPerdidas = that.datosPerdidasGlobal.filter((x) => {
                    var incluye = false;
                    for (let i = 0; i < listaElementosAQuitar.length; i++) {
                      if (x.perdidaPadre.includes(listaElementosAQuitar[i])) {
                        incluye = true;
                      }
                    }
                    if (!incluye) return x;
                    else return;
                  });
                  break;
                case 2:
                  that.datosPerdidas = that.datosPerdidasGlobal.filter((x) => {
                    var incluye = false;
                    for (let i = 0; i < listaElementosAQuitar.length; i++) {
                      if (x.grupoPerdida.includes(listaElementosAQuitar[i])) {
                        incluye = true;
                      }
                    }
                    if (!incluye) return x;
                    else return;
                  });
                  break;
              }
            }
            else {
              that.datosPerdidas = that.datosPerdidasGlobal;
            }
          }
        }
      },
      tooltip: {
        show: false
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      bindto: '#donutLeyendaParadas_Rend'
    });

    //GRAFICO DONUT MANTENIMIENTOS
    this.graficoDonutMantenimientos = c3.generate({
      data: {
        columns: this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#donutLeyendaMantenimientos_Rend .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#donutLeyendaMantenimientos_Rend .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#donutLeyendaMantenimientos_Rend .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right'
      },
      tooltip: {
        show: false
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      bindto: '#donutLeyendaMantenimientos_Rend'
    });

    //GRAFICO DONUT ALARMAS
    this.graficoDonutAlarmas = c3.generate({
      data: {
        columns: this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#donutLeyendaAlarmas_Rend .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#donutLeyendaAlarmas_Rend .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#donutLeyendaAlarmas_Rend .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right'
      },
      tooltip: {
        show: false
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      bindto: '#donutLeyendaAlarmas_Rend'
    });

    d3.selectAll("#donutLeyendaParadas_Rend .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#donutLeyendaMantenimientos_Rend .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#donutLeyendaAlarmas_Rend .c3-chart-arcs path").style("stroke-width", "0px");

  }

  cargarClientes() {
    this.dataGraficoClientes = [[' '], [this.translateService.instant('desvio'), null]];
    this.tickGraficoClientes = [0];

    //GRAFICO CLIENTES BARRAS
    var colors = ['#ffb63d', '#ff7666', '#FF89BC', '#bb8eff', '#68e0ff', '#92dcdc', '#50df88', '#87ed77', '#eaea62', '#ffb976'];
    var selectedItemsBarras = [];

    var dummyData = this.dataGraficoClientes.map(function (i) {
      return {
        "x": i.x,
        "y": 0
      }
    });

    //var th = this;
    //setTimeout(function() {
    this.graficoClientes = c3.generate({
      // padding: {
      //   top: 0,
      //   right: 0,
      //   bottom: -13,
      //   left: 50,
      // },
      data: {
        x: 'x',
        columns: this.sortData(this.dataGraficoClientes),
        //columns: this.dataGraficoClientes,
        type: 'bar',
        color: function (color, d) {
          return colors[d.index % 10];
        },
        selection: {
          grouped: true
        },
        onclick: function (d1) {
          //if (selectedItemsBarras.indexOf(d1) === -1) {
          if (selectedItemsBarras.map(function (e) { return e.x; }).indexOf(d1.x) === -1) {
            if (d1.value !== null) {
              selectedItemsBarras.push(d1);
            }
          } else {
            selectedItemsBarras.splice(selectedItemsBarras.map(function (e) { return e.x; }).indexOf(d1.x), 1);
          }
          // d3.selectAll("#GraficoClientes_Rend .c3-chart-bars")
          //  .style("opacity", function (d) {
          //    if (selectedItemsBarras.length == 0) {
          //      return 1;
          //    } else {
          //      if (selectedItemsBarras.map(function (e) { return e.x; }).indexOf(d.x) === -1) {
          //        return 0.25;
          //      } else {
          //        return 1;
          //      }
          //    }
          //  });
          //var combo = <HTMLSelectElement>document.getElementById("radBarraGrafikoAukeratuak");
          //var clienteIzenak = this.sortData(this.dataGraficoClientes)[0].slice(1);
          //for (var i = combo.options.length - 1; i >= 0; i--) {
          //  combo.options[i] = null;
          //}
          //for (let i of selectedItemsBarras) {
          //  var option = document.createElement("option");
          //  option.text = i.index;
          //  combo.add(option);
          //}
          //document.getElementById('<%= btKlikGrafikoBarras.ClientID %>').click();
        }
      },
      axis: {
        x: {
          type: 'category'
        },
        y: {
          tick: {
            values: this.tickGraficoClientes,
            format: function (value) {
              return Math.floor(value / 3600) + "h"

            }
          }
        }
      },

      tooltip: {
        format: {
          value: function (value) {
            if (value >= 0) {
              return Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
            } else {
              return "-" + Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
            }
          }
        }
      },
      transition: {
        duration: 0,//2000
      },

      bar: {
        width: {
          ratio: 0.75
        }
      },
      legend: {
        show: false
      },
      onrendered: function () {
        d3.selectAll("#GraficoClientes_Rend .c3-bar")
          .style("opacity", function (d) {
            if (d.index === 2) {
              return 0;
            } else {
              return 0;
            }
          }).transition().duration(0).style("opacity", 1);
      },
      bindto: '#GraficoClientes_Rend'
    });
    //}, 2000);

    //d3.selectAll("#GraficoClientes_Rend .c3-chart-arcs path").style("stroke-width", "0px");


    //d3.selectAll("#GraficoClientes_Rend .c3-chart-bars path").transition().duration(2000).style("stroke-width", "0px"); //ez du funtzionatzen!!!!
    //d3.select('#GraficoClientes_Rend').selectAll('path').transition().duration(2000).style("opacity",1);
    //d3.select('#GraficoClientes_Rend  .c3-bar').transition().duration(2000).style("opacity",1)

  }

  /* FILTRO */
  btnAnno_Click(event) {
    this.AnnoMesSemanaDia = 1;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setFullYear(this.myFunctions.getDateNow().getFullYear() - 10));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnMes_Click(event) {
    this.AnnoMesSemanaDia = 2;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setMonth(this.myFunctions.getDateNow().getMonth() - 12));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnSemana_Click(event) {
    this.AnnoMesSemanaDia = 3;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().setMonth(this.myFunctions.getDateNow().getMonth() - 3));
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnDias_Click(event) {
    this.AnnoMesSemanaDia = 4;
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), 1);
    this.dpFechaFin = this.myFunctions.getDateNow()
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }
  btnTodos_Click(event) {
    this.turno = -1;
  }
  btnManana_Click(event) {
    this.turno = 1;
  }
  btnTarde_Click(event) {
    this.turno = 2;
  }
  btnNoche_Click(event) {
    this.turno = 3;
  }
  /* BTN FILTRAR */
  public cargarDatosInforme() {

    this.loading1Finished = true;
    this.loading2Finished = true;
    this.loading3Finished = true;
    this.loading4Finished = true;

    this.cargarGraficos();
    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
    this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
    this.informeOeeService.set_AnnoMesSemanaDia_model(this.AnnoMesSemanaDia);
  }


  public onChangeF(value: Date): void {
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);

    if (this.dpFechaIni > this.dpFechaFin) {
      var f = this.dateCopy(this.dpFechaFin);
      this.dpFechaFin = this.dateCopy(this.dpFechaIni);
      this.dpFechaIni = f;
    }

    //this.cargarGraficos();
  }

  cargarGraficos() {

    this.cargarGridYGraficoBarrasYDonuts();
    this.cargarPerdidas();

  }

  cargarGridYGraficoBarrasYDonuts() {

    var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaIni);
    this.dpFechaFin.setHours(23, 59, 59, 0);
    var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaFin);

    this.listaOperariosSTR = "-";
    this.operariosSeleccionados.forEach((operario, i) => {
      if (i == 0 && this.operariosSeleccionados.length > 1) {
        this.listaOperariosSTR = operario
      } else if (this.operariosSeleccionados.length == 1) {
        this.listaOperariosSTR = operario
      } else {
        this.listaOperariosSTR = this.listaOperariosSTR + "," + operario
      }
    });

    this.informeOeeService.Get_Oee_Of_Cliente_Pieza(fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno.toString(), this.listaOperariosSTR).subscribe(json => {
      var data: any = json;

      var tEjecucion = 0;
      var tParadaDisponibilidad = 0;
      var tMantenimientoDisponibilidad = 0;
      var tAlarmaDisponibilidad = 0;
      var tApagadaDisponibilidad = 0;
      var tParadaRendimiento = 0;
      var tMantenimientoRendimiento = 0;
      var tAlarmaRendimiento = 0;
      var tApagadaRendimiento = 0;
      var tParadaCalidad = 0;
      var tMantenimientoCalidad = 0;
      var tAlarmaCalidad = 0;
      var tApagadaCalidad = 0;
      var tNegativoCalidad = 0;
      var tEstimado = 0;

      this.datosOF = [];

      data.forEach(
        (a) => {
          if (a.idOf > 0) {
            //DATOS GRID        
            //  var GRID_tReal = a.tEjecucion + a.tNegativoCalidad;
            // var GRID_tEstimado = a.tEstimado;
            var GRID_tReal = a.tTotalHistoricoPieza;
            if (GRID_tReal == null)
              GRID_tReal = a.tEjecucion + a.tNegativoCalidad;
            var GRID_tEstimado = a.tEst;

            var renG = 0;
            var perdidasRendimientoG = a.tParadaRendimiento + a.tMantenimientoRendimiento + a.tAlarmaRendimiento + a.tApagadaRendimiento;
            var renDividendoG: number = a.tEstimado;
            var renDivisorG: number = a.tEjecucion + perdidasRendimientoG;
            if (renDivisorG == 0) renG = 0;
            else renG = (renDividendoG / renDivisorG) * 100;

            this.datosOF.push(
              {
                "idHistorico_piezas": a.idHistorico_piezas,
                "idHistoricoOperaciones": a.idHistoricoOperaciones,
                "idOF": a.idOf,
                "idCliente": a.idCliente,
                "idPieza": a.piezaId,
                "OF": a.refof,
                "cliente": a.cliente,
                "pieza": a.pieza,
                "idOperacion": a.idOperacion,
                "operacion": a.operacion,
                "horasEstimadas": this.secondsTo_HH_MM_SS(GRID_tEstimado),//this.secondsTo_HH_MM_SS(renDividendoG),
                "horasReales": this.secondsTo_HH_MM_SS(GRID_tReal),//this.secondsTo_HH_MM_SS(renDivisorG),
                "desvio": this.secondsTo_HH_MM_SS(GRID_tReal - GRID_tEstimado),//this.secondsTo_HH_MM_SS(renDivisorG - renDividendoG),
                "desvioEnSegundos": (GRID_tReal - GRID_tEstimado),//(renDivisorG - renDividendoG),
                "rendimiento": renG.toFixed(1) + "%",
                "porcenDesvio": ((GRID_tReal - GRID_tEstimado) * 100 / GRID_tEstimado).toFixed(2) + "%",
                "terminado": a.terminado,
                "nTerminados": a.nTerminados,
                "nSerie": a.nSerie,
                "lote": a.lote,
                "colada": a.colada,
                "tEst": this.secondsTo_HH_MM_SS(GRID_tEstimado), // EL QUE SE MUESTRA EN EL GRID ESTE ERA a.tEst ANTES DEL CAMBIO INCLUSO
                "operacionCount": a.operacionCount
              }
            );

          }
          //SUMS
          tEjecucion += a.tEjecucion;
          tParadaDisponibilidad += a.tParadaDisponibilidad;
          tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
          tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
          tApagadaDisponibilidad += a.tApagadaDisponibilidad;
          tParadaRendimiento += a.tParadaRendimiento;
          tMantenimientoRendimiento += a.tMantenimientoRendimiento;
          tAlarmaRendimiento += a.tAlarmaRendimiento;
          tApagadaRendimiento += a.tApagadaRendimiento;
          tParadaCalidad += a.tParadaCalidad;
          tMantenimientoCalidad += a.tMantenimientoCalidad;
          tAlarmaCalidad += a.tAlarmaCalidad;
          tApagadaCalidad += a.tApagadaCalidad;
          tNegativoCalidad += a.tNegativoCalidad;
          tEstimado += a.tEstimado;

        }, this);

      //CALCULAR DONUTS
      var oee: number = 0;
      var cal: number = 0;
      var ren: number = 0;
      var dis: number = 0;

      var perdidasDisponibilidad = tParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
      var perdidasRendimiento = tParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;
      var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

      var disDividendo: number = tEjecucion + perdidasRendimiento;
      var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      var renDividendo: number = tEstimado;
      var renDivisor: number = tEjecucion + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      var calDividendo: number = tEstimado - tNegativoCalidad - perdidasCalidad;
      var calDivisor: number = tEstimado;
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);

      if (oee > 100) oee = 100;
      if (dis > 100) dis = 100;
      if (ren > 100) ren = 100;
      if (cal > 100) cal = 100;

      d3.select('#graficoDonutRendimiento_Rend .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "30px").style("opacity", "1")
        .text(Math.round(ren) + "%");

      this.dataGraficoDonutRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
      this.graficoDonutRendimiento.load({ columns: this.dataGraficoDonutRendimiento });

      //CALCULAR BARRAS
      var tRendimiento: number = 0;
      if (tEstimado > tEjecucion) {
        tRendimiento = 0;
        tEstimado = tEjecucion;
      } else {
        tRendimiento = tEjecucion - tEstimado;
      }

      this.dataGraficoHoras = [[this.translateService.instant('total'), tRendimiento + tEstimado + perdidasRendimiento + perdidasDisponibilidad, tRendimiento + tEstimado + perdidasRendimiento, tEstimado, tEstimado - tNegativoCalidad - perdidasCalidad],
      [this.translateService.instant('rendimiento'), 0, 0, tRendimiento, 0],
      [this.translateService.instant('paradas'), 0, tParadaDisponibilidad, tParadaRendimiento, tParadaCalidad],
      [this.translateService.instant('mantenimientos'), 0, tMantenimientoDisponibilidad, tMantenimientoRendimiento, tMantenimientoCalidad],
      [this.translateService.instant('alarmas'), 0, tAlarmaDisponibilidad, tAlarmaRendimiento, tAlarmaCalidad],
      [this.translateService.instant('apagadas'), 0, tApagadaDisponibilidad, tApagadaRendimiento, tApagadaCalidad],
      [this.translateService.instant('perdidasCalidad'), 0, 0, 0, tNegativoCalidad]];

      this.graficoHoras.load({ columns: this.dataGraficoHoras });

      //CALCULAR GRAFICO CLIENTES (Con datos del grid)
      //GROUP BY POR CLIENTES
      var groupByCliente = [];

      var laguntzailea = {};
      groupByCliente = this.datosOF.reduce(function (r, o) {
        var key = o.cliente;

        if (!laguntzailea[key]) {
          laguntzailea[key] = Object.assign({}, o); // create a copy of o
          r.push(laguntzailea[key]);
        } else {
          laguntzailea[key].desvioEnSegundos += o.desvioEnSegundos;
        }
        return r;
      }, []);

      var nombresClientes = "";
      var tiemposClientes = "";
      var contadorBarras = 0;

      groupByCliente.forEach(function (obj) {
        nombresClientes = nombresClientes + "'" + obj.cliente + "', ";
        tiemposClientes = tiemposClientes + obj.desvioEnSegundos + ", ";
        contadorBarras = contadorBarras + 1;
      });

      if (contadorBarras < 7) {
        for (var _i = 1; _i <= 7 - contadorBarras; _i++) {
          nombresClientes = nombresClientes + "'" + ' '.repeat(_i) + "',";
          tiemposClientes = tiemposClientes + "null,";
        }
      }

      this.dataGraficoClientes = (new Function("return [[" + nombresClientes + "],['" + this.translateService.instant('desvio') + "'," + tiemposClientes + "]];")());
      this.tickGraficoClientes = this.getChartAxisRange((new Function("return [[" + nombresClientes + "],['" + this.translateService.instant('desvio') + "'," + tiemposClientes + "]];")()));

      this.graficoClientes.internal.config.axis_y_tick_values = this.tickGraficoClientes;
      this.dataGraficoClientes = this.sortData(this.dataGraficoClientes);

      this.graficoClientes.load({ columns: this.dataGraficoClientes });

      this.loading1Finished = false;
    });

  }

  cargarPerdidas() {
    this.datosPerdidas = this.datosPerdidasGlobal;
    var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaIni);
    this.dpFechaFin.setHours(23, 59, 59, 0);
    var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.dpFechaFin);

    this.listaOperariosSTR = "-";
    this.operariosSeleccionados.forEach((operario, i) => {
      if (i == 0 && this.operariosSeleccionados.length > 1) {
        this.listaOperariosSTR = operario
      } else if (this.operariosSeleccionados.length == 1) {
        this.listaOperariosSTR = operario
      } else {
        this.listaOperariosSTR = this.listaOperariosSTR + "," + operario
      }
    });

    if (this.agrupado == 2) { //si las pérdidas están agrupadas por grupos tomar los datos de estas agrupadas por grupos
      this.informeOeeService.Get_Perdidas_PorGrupo_tipoPerdida(2, fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.translateService.instant("sinGrupo"), this.turno, this.listaOperariosSTR).subscribe(data => {

        document.getElementById('lblNoParadas_Rend').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';

        this.lblNoParadas_RendVisible = true;
        this.checkboxGruposVisible = false;
        document.getElementById('donutLeyendaParadas_Rend').style.display = 'none';


        var dataPerdidas: any = data;

        var donutDataParadas: any = [];


        dataPerdidas.forEach(function (obj) {
          //PARADAS (idProcesos_Tipo = 1, 2)
          if (obj.tiempoPerdida > 0) {
            donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoParadas_Rend').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            this.lblNoParadas_RendVisible = false;
            this.checkboxGruposVisible = true;
            document.getElementById('donutLeyendaParadas_Rend').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }))

        d3.selectAll("#donutLeyendaParadas_Rend .c3-chart-arcs path").style("stroke-width", "0px");

        this.loading2Finished = false;
      });
    }
    else if (this.agrupado == 1) {
      this.informeOeeService.Get_Perdidas_PorPadres_tipoPerdida(2, fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(data => {

        document.getElementById('lblNoParadas_Rend').style.display = 'block';
        document.getElementById('checkboxGrupos').style.display = 'none';
        this.lblNoParadas_RendVisible = true;
        this.checkboxGruposVisible = false;
        document.getElementById('donutLeyendaParadas_Rend').style.display = 'none';


        var dataPerdidas: any = data;

        var donutDataParadas: any = [];


        dataPerdidas.forEach(function (obj) {
          //PARADAS (idProcesos_Tipo = 1, 2)
          if (obj.tiempoPerdida > 0) {
            donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoParadas_Rend').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            this.lblNoParadas_RendVisible = false;
            this.checkboxGruposVisible = true;
            document.getElementById('donutLeyendaParadas_Rend').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
        this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

        this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }))

        d3.selectAll("#donutLeyendaParadas_Rend .c3-chart-arcs path").style("stroke-width", "0px");

        this.loading2Finished = false;
      });
    }


    this.informeOeeService.Get_Perdidas_Rendimiento(fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(data => {

      document.getElementById('lblNoParadas_Rend').style.display = 'block';
      document.getElementById('checkboxGrupos').style.display = 'none';
      this.lblNoParadas_RendVisible = true;
      this.checkboxGruposVisible = false;
      document.getElementById('lblNoMantenimientos_Rend').style.display = 'block';
      document.getElementById('lblNoAlarmas_Rend').style.display = 'block';

      document.getElementById('donutLeyendaParadas_Rend').style.display = 'none';
      document.getElementById('donutLeyendaMantenimientos_Rend').style.display = 'none';
      document.getElementById('donutLeyendaAlarmas_Rend').style.display = 'none';

      var dataPerdidas: any = data;

      var donutDataParadas: any = [];
      var donutDataMantenimientos: any = [];
      var donutDataAlarmas: any = [];

      dataPerdidas.forEach(function (obj) {
        //PARADAS (idProcesos_Tipo = 1, 2)
        if ((obj.idProcesos_Tipo == 1 || obj.idProcesos_Tipo == 2) && obj.tiempoPerdida > 0) {
          donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoParadas_Rend').style.display = 'none';
          document.getElementById('checkboxGrupos').style.display = 'block';
          this.lblNoParadas_RendVisible = false;
          this.checkboxGruposVisible = true;
          document.getElementById('donutLeyendaParadas_Rend').style.display = 'block';
        }

        //MANTENIMIENTOS (idProcesos_Tipo = 4, 10)
        if ((obj.idProcesos_Tipo == 4 || obj.idProcesos_Tipo == 10) && obj.tiempoPerdida > 0) {
          donutDataMantenimientos.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoMantenimientos_Rend').style.display = 'none';
          document.getElementById('donutLeyendaMantenimientos_Rend').style.display = 'block';
        }

        //ALARMAS (idProcesos_Tipo = 6, 7)
        if ((obj.idProcesos_Tipo == 6 || obj.idProcesos_Tipo == 7) && obj.tiempoPerdida > 0) {
          donutDataAlarmas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoAlarmas_Rend').style.display = 'none';
          document.getElementById('donutLeyendaAlarmas_Rend').style.display = 'block';
        }

      }, this);

      var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
      this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

      var oldDataGraficoDonutMantenimientos = this.dataGraficoDonutMantenimientos;
      this.dataGraficoDonutMantenimientos = donutDataMantenimientos.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutMantenimientos = this.acortarDatosGraficoDonuts(this.dataGraficoDonutMantenimientos);

      var oldDataGraficoDonutAlarmas = this.dataGraficoDonutAlarmas;
      this.dataGraficoDonutAlarmas = donutDataAlarmas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutAlarmas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutAlarmas);

      this.updateDonutLeyenda(this.graficoDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }))
      this.updateDonutLeyenda(this.graficoDonutMantenimientos, oldDataGraficoDonutMantenimientos, this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }))
      this.updateDonutLeyenda(this.graficoDonutAlarmas, oldDataGraficoDonutAlarmas, this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }))

      d3.selectAll("#donutLeyendaParadas_Rend .c3-chart-arcs path").style("stroke-width", "0px");
      d3.selectAll("#donutLeyendaMantenimientos_Rend .c3-chart-arcs path").style("stroke-width", "0px");
      d3.selectAll("#donutLeyendaAlarmas_Rend .c3-chart-arcs path").style("stroke-width", "0px");

      this.loading2Finished = false;
      this.loading3Finished = false;
    });

    this.informeOeeService.Get_Perdidas_Rend(fini, ffin, this.maquinasSeleccionadas.toString(), this.translateService.instant("sinAsignar"), this.turno, this.listaOperariosSTR).subscribe(
      (json: any) => {

        var dict: any = {};
        //Tenemos las imagenes, creamos el diccionario
        json.imagenes.forEach(
          element => {
            dict[element.imagen] = element.imagenBASE64;
          });

        json.data.forEach(
          element => {
            //Primero procesamos los operarios
            if (element.operario != null) {
              var operarios = element.operario.split(",");
              var operariosAuxi = [];
              var operariosAuxi2 = [];
              operarios.forEach(operario => {
                var nombre;
                var apellido;
                if (operario == this.translateService.instant("desconocido")) {
                  operariosAuxi.push("undefined");
                  operariosAuxi2.push("undefined");
                } else {
                  if (operario.split(';').length > 0) {
                    nombre = operario.split(';')[0];
                    apellido = operario.split(';')[1];
                    if (apellido != undefined)
                      operariosAuxi2.push(nombre + " " + apellido);
                    else
                      operariosAuxi2.push(nombre);
                    if (nombre != undefined)
                      nombre = nombre.trim().substring(0, 1).toUpperCase();
                    if (apellido != undefined)
                      apellido = apellido.trim().substring(0, 1).toUpperCase();
                    operariosAuxi.push(nombre + apellido);
                  }
                }
              });
              element.operariosAuxi2 = operariosAuxi2.join(";");
              element.operariosAuxi = operariosAuxi.join(",");
            } else {
              element.operariosAuxi2 = "";
              element.operariosAuxi = "";
            }
            //Ahora seguimos con las maquinas
            if (element.maquina == "") {
              element.maquinas = this.translateService.instant('desconocido');
            }
            if (element.maquina != null) {
              var maquinas = element.maquina.split(",");
              var maquinasAuxi = [];
              var maquinasAuxi2 = [];
              maquinas.forEach(maquina => {
                if (maquina == this.translateService.instant("desconocido")) {
                  maquinasAuxi2.push("undefined");
                  maquinasAuxi.push("undefined");
                } else {
                  var nombre = maquina.trim().substring(0, 1).toUpperCase();
                  var apellido = maquina.trim().substring(1, 2).toUpperCase();
                  maquinasAuxi2.push(maquina);
                  maquinasAuxi.push(nombre + apellido);
                }
              });
              element.maquinasAuxi2 = maquinasAuxi2.join(";");
              element.maquinasAuxi = maquinasAuxi.join(",");
              //Ahora hay que corregir las imagenes de las maquinas
              var imagenes = element.maquinaIm.split(';and;');
              var auxiImagenes = "";
              imagenes.forEach(imagen => {
                auxiImagenes += dict[imagen] + ";and;";
              });
              element.maquinaIm = auxiImagenes;
            } else {
              element.maquinasAuxi2 = "";
              element.maquinasAuxi = "";
              element.maquinasIm = "";
            }
          });


        var an = [];
        var row: any;

        json.data.forEach(
          element => {
            if (row == undefined) { //si no existe una linea que llevamos a rastras, la creamos
              row = this.myFunctions.copy(element);
            } else if (element.fechafin == row.fechaini &&
              element.maquina == row.maquina &&
              element.operario == row.operario &&
              element.lote == row.lote &&
              element.nSerie == row.nSerie &&
              element.colada == row.colada &&
              element.observacion == row.observacion &&
              element.perdida == row.perdida) { //si la linea se continua, le alargamos la fecha fin
              row.fechaini = this.myFunctions.copy(element.fechaini);
            } else {//insertamos la linea actual que llevamos y la que estamos leyendo la dejamos como la actual         
              row.horasReales = (new Date(row.fechafin).getTime() - new Date(row.fechaini).getTime()) / 1000;
              an.push(this.myFunctions.copy(row));
              row = this.myFunctions.copy(element);
            }
          });

        if (json.data.length > 0)
          an.push(this.myFunctions.copy(row));

        this.datosPerdidasGlobal = an;
        this.datosPerdidas = an; //Al inicio los datos no estan filtrados

        //this.datosPerdidas = json.data;

        this.loading4Finished = false;
      });
  }

  cellClick(event: CellClickEvent) {
    var row = event.dataItem;    
    //path: 'analiticaavanzadaejecuciones/:fini/:ffin/:idcliente/:idpieza/:idof/:terminados/:idHistoricoPieza/:idHistoricoOperacion'
    window.open('#/analiticaavanzadaejecuciones/0/0/0/0/0/0/' + row.idHistorico_piezas + '/' + row.idHistoricoOperaciones , '_blank');

  }

  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnseñar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnseñar.push(['Otros', totalOtros]);
      return arrayParaEnseñar;
    }
  }

  public updateDonutLeyenda(grafico, oldData, newData) {

    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      grafico.show(value);
      var nombre = "c3-legend-item-" + value
      nombre = nombre.split(' ').join('-'); //Asi quitamos todos los espacios y los reemplazamos por '-', ya que no deja usar replaceAll
      var elemento: any = document.getElementsByClassName(nombre)[0];
      elemento.classList.remove("c3-legend-item-hidden");
      elemento.style.opacity = "1";
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }

    var names = {};
    newData.forEach(function (a) {
      names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
    }, this);

    grafico.load({
      columns: newData,
      names: names
    });
    grafico.unload({
      ids: nombresAEliminar
    });
  }

  public sortData(unsortedData) {
    var sorted = unsortedData.map(function (row) {
      return row.slice();
    })
    var name = sorted[1].splice(0, 1);
    var datapoints = sorted[1].map(function (d, i) {
      return [sorted[0][i], d];
    });
    var sortedData = datapoints.sort(function (a, b) {
      return b[1] - a[1];
    });
    sorted[1] = sortedData.map(function (point, i) {
      sorted[0][i] = point[0];
      return point[1];
    });
    sorted[1] = name.concat(sorted[1]);
    sorted[0].splice(0, 0, 'x');
    return sorted;
  }

  public range(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1
    return Array(len).fill(0).map((_, idx) => start + (idx * step))
  }

  public getChartAxisRange(data) {
    data[1].shift();

    var nmax = Math.max.apply(null, data[1]) / 3600
    var smax = Math.abs(nmax).toString().split('.')
    var dmax = Math.pow(10, smax[0].length - 1)
    var nmin = Math.min.apply(null, data[1]) / 3600
    var smin = Math.abs(nmin).toString().split('.')
    var dmin = Math.pow(10, smin[0].length - 1)

    if (nmin > 0) {
      nmin = 0
    }

    if (dmax > dmin) {
      var result = this.range(Math.round((nmin - dmax) / dmax) * dmax, nmax + dmax, dmax);
      return (result.map(x => x * 3600));
    }
    if (dmax < dmin) {
      var result = this.range(Math.round((nmin - dmin) / dmin) * dmin, nmax + dmin, dmin);
      return (result.map(x => x * 3600));
    }
    if (dmax == dmin) {
      var result = this.range(Math.round((nmin - dmin) / dmin) * dmin, Math.round((nmax + dmin) / dmax) * dmax, dmin);
      return (result.map(x => x * 3600));
    }
  }

  public secondsTo_HH_MM_SS(segundos) {
    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }

  public cortarLeyenda(inputText, trimmed = false) {

    var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var width = context.measureText(inputText).width;

    if (width > 140) {
      return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
    } else if (trimmed) {
      return inputText + "...";
    } else {
      return inputText;
    }

  }


  public checkboxChange(tipo: number): void { //función para saber si las pérdidas están agrupadas por grupos

    this.agrupado = tipo;

    this.cargarPerdidas();

  }

  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  showCalendar() {
    if (!this.mostrarCalendario1) {
      this.mostrarCalendario1 = true;
    }
    else {
      this.mostrarCalendario1 = false;
      if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
        this.dpFechaFin = this.dpFechaIni;
      }
      else if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
        this.dpFechaIni = this.myFunctions.getDateNow();
        this.dpFechaFin = this.dpFechaIni;
      }

    }
    // this.mostrarCalendario1 = true; // activa el click de fondo!
  }
  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.dpFechaIni != undefined && this.dpFechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.dpFechaFin = this.DaysInMonths[month];
        this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
          this.dpFechaIni = this.DaysInMonths[month];
        }
        else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
          this.dpFechaFin = this.DaysInMonths[month];
          if (this.dpFechaIni > this.dpFechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.dpFechaIni.getTime());
            this.dpFechaIni = new Date(this.dpFechaFin.getTime());
            this.dpFechaFin = aux;
          }
          this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.dpFechaIni = this.DaysInMonths[month];
          this.dpFechaFin = undefined;
        }
      }
    }
  }

  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
      return false;
    } else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
      return date.getFullYear() == this.dpFechaIni.getFullYear() && date.getMonth() == this.dpFechaIni.getMonth() && date.getDate() == this.dpFechaIni.getDate();
    } else if (this.dpFechaIni != undefined && this.dpFechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.dpFechaIni.getFullYear(), this.dpFechaIni.getMonth(), this.dpFechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.dpFechaFin.getFullYear(), this.dpFechaFin.getMonth(), this.dpFechaFin.getDate());
    }
  }


  insertMaquinasSelected(id, e) {
    var aurk = false;
    this.maquinasSeleccionadas.forEach((maquina, index) => {
      if (maquina == id) {
        this.maquinasSeleccionadas.splice(index, 1);
        aurk = true;
      }
    });
    if (!aurk) {
      this.maquinasSeleccionadas.push(id);
    }
  }

  selectAllUsuarios() {
    if (this.operariosSeleccionados.length == this.listaOperarios.length) {
      this.seleccionarTodo = true;
      this.operariosSeleccionados = [];
    } else {
      this.seleccionarTodo = false;
      this.operariosSeleccionados = [];
      this.listaOperarios.forEach(operario => {
        this.operariosSeleccionados.push(operario.numOperario);
      });
    }
    this.operariosSeleccionados;
  }

  filtroMaquinas(e: any) {
    this.listaMaquinasAux = [];
    if (e == '') {
      this.listaMaquinasAux = this.maquinas;
    } else {
      this.maquinas.forEach(maquina => {
        if (maquina.nombre.toLowerCase().includes(e.toLowerCase())) {
          this.listaMaquinasAux.push(maquina);
        }
      });
    }
  }

  filtroOperarios(e: any) {
    this.listaOperariosAux = [];
    if (e == '') {
      this.listaOperariosAux = this.listaOperarios;
    } else {
      this.listaOperarios.forEach(operario => {
        if (operario.nombreCompleto.toLowerCase().includes(e.toLowerCase())) {
          this.listaOperariosAux.push(operario);
        }
      });
    }
    this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
  }

  imprimirXls(e, grid: GridComponent) {
    console.log("________________________________________________________________________________")
    console.log(grid) 
    e.preventDefault();
    setTimeout(function () {
      grid.saveAsExcel();
    });
  }

}
