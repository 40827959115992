import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivosService, AlertService, MenuService, ProveedoresService, UsuariosService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activos-maestros-proveedor',
  templateUrl: './activos-maestros-proveedor.component.html',
  styleUrls: ['./activos-maestros-proveedor.component.less']
})
export class ActivosMaestrosProveedorComponent implements OnInit {

  dataProveedores: any;
  proveedorObservaciones: any;
  proveedorPrecio: any;
  proveedorPlazoEntrega: any;
  proveedorGarantia: any;
  proveedorSeleccionado: any;
  idActivoMaestro: any;
  idProveedoresTabla: any;
  public user: any;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private menuService: MenuService, public router: Router, private modalService: NgbModal,
    private userService: UsuariosService,
    private translateService: TranslateService, private proveedoresService: ProveedoresService, private route: ActivatedRoute, protected alertService: AlertService,
    private activosService: ActivosService) { 
      this.user = this.userService.userValue;
      this.menuService.titulo = this.translateService.instant('proveedor');
      this.idActivoMaestro = Number(this.route.snapshot.params['idActivo']);
      this.idProveedoresTabla = Number(this.route.snapshot.params['idProveedor']);
    }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cargarDatos(){
    if(this.idProveedoresTabla != 0){
      this.proveedoresService.getAll().subscribe((response:any)=>{
        this.dataProveedores = response;
        this.activosService.GetProveedoresByIdActivoIdProveedor(this.idActivoMaestro, this.idProveedoresTabla).subscribe((response:any)=>{
          this.proveedorSeleccionado = this.dataProveedores.filter(x=> x.id == response[0].idProveedor)[0].id;
          this.proveedorObservaciones=response[0].observaciones;
          this.proveedorPrecio=response[0].precio;
          this.proveedorPlazoEntrega=response[0].plazoEntrega;
          this.proveedorGarantia=response[0].garantia;
        });
      });
    } else{
      this.proveedoresService.GetAllExceptSelected(this.idActivoMaestro).subscribe((response:any)=>{
        this.dataProveedores = response;
      });
      this.proveedorObservaciones='';
      this.proveedorPrecio=0.0;
      this.proveedorPlazoEntrega=0;
      this.proveedorGarantia=0;
    }
  }

  onClickGuardar(){
    this.submitted = true;
    if(this.proveedorSeleccionado == undefined)return;
    if(this.idProveedoresTabla != 0){
      this.activosService.updateProveedorActivoMaestro(this.idActivoMaestro, this.proveedorSeleccionado, this.proveedorPrecio, this.proveedorPlazoEntrega, 
        this.proveedorGarantia, this.proveedorObservaciones).subscribe((response: any)=>{
          if (response >= 0) {
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.router.navigate(['/activoMaestro/'+this.idActivoMaestro]);
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
    } else{
      this.activosService.insertProveedorActivoMaestro(this.idActivoMaestro, this.proveedorSeleccionado, this.proveedorPrecio, this.proveedorPlazoEntrega, 
        this.proveedorGarantia, this.proveedorObservaciones).subscribe((response: any)=>{
          if (response >= 0) {
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.router.navigate(['/activoMaestro/'+this.idActivoMaestro]);
          } else {
            this.alertService.error(this.translateService.instant('error'));
          }
        });
    }
  }

  atras(){
    this.router.navigate(['/activoMaestro/'+this.idActivoMaestro]);
  }

}
