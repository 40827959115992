<div class="row">

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'erisPlataforma' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video1" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'home' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video2" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'disenadorPlanta' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video3" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'proyectos' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video4" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'turnos' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video5" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'oee' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video6" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'informes' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video7" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'planificador' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video8" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

  <div class="col-6">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'planesDeAccion' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body" style="text-align:center">
        <video controls [src]="video9" controlsList="nodownload" style="width: 95%; padding: 1rem;"></video>
      </div>
    </div>
  </div>

</div>
