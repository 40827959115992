<style>
  :host /deep/ .c3-area {
    opacity: 0.8 !important;
  }

  :host /deep/ .c3-line-media {
    stroke-width: 3px !important;
  }

  :host /deep/ .c3-line-valor {
    stroke-width: 3px !important;
  }
</style>


<div class="cont-filtro-maquinas-areas clearfix">
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">

        <div class="row">

          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>

          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
      <div id="divMensajeSeleccionar" *ngIf="selectedMaquina==undefined || selectedMaquina==0" class="text-select-maquinas">
        <div>
          {{ 'seleccioneMaquina' | translate }}
        </div>
      </div>
      <div *ngFor="let maquina of maquinasMostradas" (click)="selectedMaquina=maquina; desplegarMaquinasClick(); identificarMaquinaYCargarDatos();" class="clickable" [ngClass]="{'actual': maquina==selectedMaquina}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>

  <!--FECHAS-->
  <div class="card cont-filtro-informes" style="width: 290px; height: 90px; margin-left: 10px;">
    <div class="card-body">
      <div class="form-group" style="margin-top: 5px;">
        <label class="control-label">{{ 'inicio' | translate }}</label>
        <div class="caja">
          <kendo-datepicker [weekNumber]="true" style="width: 120px;" [(value)]="fechaInicio" (valueChange)="onChangeFechaIni($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label">{{ 'fin' | translate }}</label>
        <div class="caja">
          <kendo-datepicker [weekNumber]="true" style="width: 120px;" [(value)]="fechaFin" (valueChange)="onChangeFechaFin($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
          <button type="button" class="btn btn-primary" style="margin-left: 12px;" (click)="identificarMaquinaYCargarDatos()">{{ 'filtrar' | translate }}</button>
        </div>
      </div>
    </div>
  </div>

</div>

<!--BOTONES SUPERIOR-->
<div class="card" *ngIf="ensennarBotonesSuperior">
  <div class="card-body">
    <div class="clearfix horizontal-scroll">
      <kendo-buttongroup [selection]="'single'">
        <button kendoButton *ngFor="let boton of listaBotonesSuperior" type="button" class="form-control mr-2" 
        [selected]="boton.selected" [toggleable]="true" (selectedChange)="botonSuperiorChange($event, boton)">
          {{ boton.nombre }}
        </button>
      </kendo-buttongroup>
    </div>
  </div>
</div>

<!--GRAFICO-->
<div class="card">
  <div class="card-header">
    <h3>{{ tituloGrafico }} <Label *ngIf="unidadGrafico != ''" >({{unidadGrafico}})</Label></h3>
  </div>
  <div class="row">
    <div class="col-md-12" id="barra-zoom" style="margin-left: 40px;">

    </div>
    <div class="col-md-12">
      <button kendoButton (click)="onMostrarTodosZoomClick()" look="flat" [icon]="'zoom-out'" style="position: absolute; z-index: 1; right:2%; top:2%">{{ 'mostrarTodo' | translate }}</button>
      <div id="grafico_historicoDatos" oncontextmenu="return false;" style="height: 400px;"></div>
    </div>
  </div>
  <div *ngIf="secciones == undefined || secciones.length == 0" class="k-i-loading" style="z-index: 1;">
    <div class="texto-loading-panel">
      <Label>{{ 'noTienesSeccionesAsignadas' | translate }}</Label>
    </div>
  </div>
  <div *ngIf="cargandoGrafico" class="k-i-loading" style="z-index: 1;"></div>
</div>

<!--BOTONES INFERIOR-->
<div class="card">
  <div class="card-body">

    <div class="clearfix horizontal-scroll">

      <kendo-buttongroup [selection]="'single'">
        <button kendoButton *ngFor="let boton of listaBotonesFiltroGrafico" type="button" class="form-control mr-2" [selected]="boton.selected" [toggleable]="true" 
        (selectedChange)="botonFiltroChange($event, boton)">
          {{ boton.nombre }}
        </button>
      </kendo-buttongroup>

    </div>

  </div>
</div>
