import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({ selector: 'app-profile', templateUrl: 'details.component.html' })
export class DetailsComponent implements OnInit  {
    user = this.usuariosService.userValue;
    navigationSubscription;

    constructor(private usuariosService: UsuariosService, 
        private translateService: TranslateService,private menuService: MenuService,public router: Router) { 
            this.navigationSubscription = this.router.events.subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                  if (this.router.url == '/profile' ){
                    this.cargarDatos();
                  }else if(this.router.url == '/profile/update' ){
                    this.menuService.titulo = this.translateService.instant('actualizarusuario');
                  }
                }
              });     

        }
        cargarDatos(){
            this.user = this.usuariosService.userValue;
            this.menuService.titulo = this.translateService.instant('miusuario');
          }
        ngOnInit() {
            this.menuService.titulo = this.translateService.instant('miusuario');

        }
        onClickEditar() {
           this.router.navigate(['profile/update']);
          }


}

