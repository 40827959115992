<style>
  /*:host /deep/ .c3-axis-x .tick {
    display: none;
  }*/
  :host /deep/ .c3-chart-arcs-background {
    fill: #1A5F60;
    stroke: none;
  }

  :host /deep/ .c3-chart-arcs path {
    stroke-width: 0px;
  }
</style>

<div class="planta ui-draggable ui-draggable-handle" style="position: relative;">

  <div class="isometrico suelo contenedor1" [ngStyle]="{'height.px': alto*100, 'width.px': ancho*100}">

    <ng-container *ngFor="let valdosa of valdosas">

      <!--VALDOSA CON OBJETO-->
      <div *ngIf="valdosa.esObjeto" class="tile" [attr.tilenum]="valdosa.tilenum" [attr.nomaq]="valdosa.nomaq" [attr.pareja]="valdosa.pareja"
           (click)="clickValdosa(valdosa, $event)" (mouseenter)="mouseEnterValdosa(valdosa)" (mouseleave)="mouseLeaveValdosa(valdosa)" (mousedown)="onMouseDown($event)"
           [ngClass]="valdosa.clase" 
           [class.tile-seleccionado]="valdosa.tileSeleccionado && valdosa.idTipoObjeto == 1"
           [ngStyle]="{ 'background-image': (valdosa.idTipoObjeto == 2 && valdosa.carretera != '') ? 'url(../../assets/isometrico/iconos/carreteras/' + valdosa.carretera + ')' : '',
                          'cursor': (valdosa.idTipoObjeto == 1) ? 'pointer' : ''}">
        <img *ngIf="valdosa.pared == 1" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande01.png">
        <img *ngIf="valdosa.pared == 2" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande02.png">
        <img *ngIf="valdosa.pared == 3" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande03.png">
        <div *ngIf="(valdosa.idTipoObjeto == 1 || valdosa.idTipoObjeto == 4)" class="sombra"></div>
        <img *ngIf="(valdosa.idTipoObjeto == 1 || valdosa.idTipoObjeto == 3 || valdosa.idTipoObjeto == 4)" src="{{ '../../assets/isometrico/iconos/' + valdosa.src}}">

        <ng-container *ngFor="let datoPanel of valdosa.datosPanel">

          <div class="perspectiva {{datoPanel.procesosTipo}}" *ngIf="valdosa.idTipoObjeto == 1 && valdosa.ensennarEstado">
            <div class="contenedor-cubo">
              <div class="cubo">
                <div class="lado fondo"></div>
                <div class="lado fondo"></div>
                <div class="lado fondo"></div>
                <div class="lado fondo"></div>
                <div class="techo fondo"></div>
                <div class="suelo fondo"></div>
                <div class="sombracubo"></div>
              </div>

              <!--ICONO ALARMA-->
              <label class="cubo-label" *ngIf="datoPanel.procesosTipo == 'alarma'">
                <i class="fas fa-lg fa-exclamation-triangle"></i>
              </label>

              <!--TEXTO EJECUCION Y PREPARACION-->
              <label class="cubo-label" *ngIf="datoPanel.procesosTipo == 'ejecucion' || datoPanel.procesosTipo == 'preparacion'"
                     [innerHTML]="datoPanel.porcentaje">
                <!--[innerHTML]="'&nbsp;&nbsp;9'">-->
              </label>

            </div>
          </div>

        </ng-container>

      </div>

      <!--VALDOSA SIN OBJETO-->
      <div *ngIf="!valdosa.esObjeto" class="tile" [attr.tilenum]="valdosa.tilenum"
           (click)="clickValdosa(valdosa, $event)" (mousedown)="onMouseDown($event)"
           [ngStyle]="{'background-image': (valdosa.vistaPrevia  && valdosa.idTipoObjeto == 2 && valdosa.carretera != '') ? 'url(../../assets/isometrico/iconos/carreteras/' + valdosa.carretera + ')' : '' }">
        <img *ngIf="valdosa.pared == 1" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande01.png">
        <img *ngIf="valdosa.pared == 2" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande02.png">
        <img *ngIf="valdosa.pared == 3" class="limite-img" src="../../assets/isometrico/iconos/paredes/pared-grande03.png">
        <img *ngIf="valdosa.vistaPrevia && (valdosa.idTipoObjeto == 1 || valdosa.idTipoObjeto == 3 || valdosa.idTipoObjeto == 4)" src="{{ '../../assets/isometrico/iconos/' + valdosa.src }}" style="opacity: 0.8">
      </div>

    </ng-container>

    <!--VALDOSAS EXTRA (PARA OBJETOS DE MAS DE UN PISO-->
    <ng-container *ngFor="let valdosa of valdosasExtra">

      <div class="tile" style="position: absolute; outline: 0px;" [attr.tilenum]="valdosa.tilenum" [attr.nomaq]="valdosa.nomaq" [attr.pareja]="valdosa.pareja"
           [ngStyle]="{ 'top': valdosa.posTop + 'px', 'right': valdosa.posRight + 'px' }"
           [ngClass]="{ 'tile-seleccionado': valdosa.tileSeleccionado, 'sobre':valdosa.sobre, 'tile-verde': valdosa.tileVerde, 'tile-roja': valdosa.tileRoja }">
        <img src="{{ '../../assets/isometrico/iconos/' + valdosa.src}}">

        <ng-container *ngFor="let datoPanel of valdosa.datosPanel">

          <div class="perspectiva {{datoPanel.procesosTipo}}" *ngIf="valdosa.idTipoObjeto == 1 && valdosa.ensennarEstado">
            <div class="contenedor-cubo">
              <div class="cubo">
                <div class="lado fondo"></div>
                <div class="lado fondo"></div>
                <div class="lado fondo"></div>
                <div class="lado fondo"></div>
                <div class="techo fondo"></div>
                <div class="suelo fondo"></div>
                <div class="sombracubo"></div>
              </div>

              <!--ICONO ALARMA-->
              <label class="cubo-label" *ngIf="datoPanel.procesosTipo == 'alarma'">
                <i class="fas fa-lg fa-exclamation-triangle"></i>
              </label>

              <!--TEXTO EJECUCION Y PREPARACION-->
              <label class="cubo-label" *ngIf="datoPanel.procesosTipo == 'ejecucion' || datoPanel.procesosTipo == 'preparacion'"
                     [innerHTML]="datoPanel.porcentaje">
                <!--[innerHTML]="'&nbsp;&nbsp;9'">-->
              </label>

            </div>
          </div>

        </ng-container>

      </div>

    </ng-container>

  </div>

</div>

<!--MENU LATERAL MAQUINAS-->
<div class="panel-menu" [ngClass]="{ 'desplegado': menuDesplegadoMaquina }" style="overflow-x: hidden; overflow-y: auto; height: calc(100% - 56px);">

  <!--TITULO MAQUINA-->
  <div class="panel-menu-inner">
    <div class="bloque-innermenu">
      <h3 class="titulo-maquina">{{ nombreMaquina }}</h3>
    </div>
  </div>

  <!--CONTENIDO MAQUINA-->
  <div class="panel-menu-inner">

    <!--OPERARIO-->
    <div class="bloque-innermenu-operario">
      <div class="row">
        <div class="col" style="padding-left: 16px; padding-top: 7px; padding-bottom: 7px;">

          <span class="fa-stack fa-lg">
            <i class="fa fa-square-full fa-stack-2x" style="color:#e1e1e1;"></i>
            <i class="fa fa-user fa-lg fa-stack-1x" style="color: white;"></i>
          </span>

          <label class="label-operario-desplegable">{{ operario }}</label>

        </div>
      </div>
    </div>

    <div class="bloque-innermenu-white">

      <!--CONTENEDOR TIEMPOS-->
      <div class="contenedor-eje estado-{{ procesosTipo }}" (click)="redirigirHmi()">
        <div class="datos-de-tiempo">
          <div id="divEjec">

            <div class="datos-de-tiempo-inner">
              <div class="dt-izq">
                <div class="estado-titulo">
                  <label>{{ procesosTipo.toUpperCase() }}</label>
                </div>
                <div class="porcent-cont-outer">
                  <div class="porcent-cont">
                    <label class="donut-porcent">{{ porcentaje }}</label>
                    <img src="{{ estadoimg }}" />
                  </div>
                </div>
              </div>

              <div class="dt-cent">
                <div class="tiempoestimado-datos">
                  <label class="estado-tiempo-titulo">{{ 'tiempo' | translate}}</label>
                  <label class="estado-tiempo">{{ tiempopasado }}</label>
                </div>
              </div>

              <div class="cont-desvalter dt-der" *ngIf="procesosTipo.toLowerCase()!='preparacion'">
                <div class="desvalter desvalter-1">
                  <label class="estado-tiempo-titulo">{{ 'est.' | translate}}</label>
                  <label class="estado-tiempo">{{ tiempoEstEjec }}</label>
                </div>
                <div class="desvalter desvalter-2">
                  <label class="estado-tiempo-titulo">{{ 'pred.' | translate}}</label>
                  <label class="estado-tiempo">{{ tiempoPred }}</label>
                </div>
              </div>

              <div class="cont-desvalter dt-der" *ngIf="procesosTipo.toLowerCase()=='preparacion'">
                <div class="desvalter desvalter-1">
                  <label class="estado-tiempo-titulo">{{ 'est.' | translate}}</label>
                  <label class="estado-tiempo">{{ tiempoEstPrep }}</label>
                </div>
                <div class="desvalter desvalter-2">
                  <label class="estado-tiempo-titulo">{{ 'pred.' | translate}}</label>
                  <label class="estado-tiempo">{{ tiempoPred }}</label>
                </div>
              </div>

            </div>

            <!--LABEL INFORMACION ALARMA O PARADA-->
            <!-- labelAlarma text-center ng-star-inserted -->
            <div class="text-center">
              <label class="" *ngIf="procesosTipo.toLowerCase()=='alarma' || procesosTipo.toLowerCase()=='parada' || procesosTipo.toLowerCase()=='mantenimiento'"
                     [ngClass]="{ 'labelAlarma': procesosTipo.toLowerCase()=='alarma', 'labelParada': procesosTipo.toLowerCase()=='parada', 'labelMant': procesosTipo.toLowerCase()=='mantenimiento' }">
                {{ incidencia }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <!--CICLO (PARA INYECTORAS)-->
      <div class="row" *ngIf="tipo_maquina==4">
        <div class="col" style="margin-bottom: -4px;">
          <div style="height: 40px;" class="panel-of">
            <div class="of-inner">
              <div class="of-content">
                <label class="label-proceso" data-toggle="tooltip" data-placement="bottom" title="{{cicloInyectora}}">{{ 'ciclo' | translate }}: {{cicloInyectora}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--CICLO (PARA HORNOS)-->
      <div class="row" *ngIf="tipo_maquina==5">
        <div class="col" style="margin-bottom: -4px;">
          <div style="height: 40px;" class="panel-of">
            <div class="of-inner">
              <div class="of-content">
                <label class="label-proceso" data-toggle="tooltip" data-placement="bottom" title="{{cicloHorno}}">{{ 'ciclo' | translate }}: {{cicloHorno}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--MAQUINA (PARA INYECTORAS)-->
      <div class="row" *ngIf="tipo_maquina==4">
        <div class="col" >
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'maquina' | translate }}</label>
              </h3>
            </div>

            <div class="datos-of">

              <div class="pdatos-of-inner">

                <div id="divDatosof" class="datos-of-content">
                  <ul>
                    <li>
                      <label ID="Ltonelaje" class="label-datos-home" style="width: 40%;">{{ 'tonelaje' | translate}}</label>
                      <label ID="ldatotonelaje" class="label-datos-valor-home" style="width: 60%;" data-toggle="tooltip" data-placement="bottom" title="{{tonelaje}}">
                        {{tonelaje}}
                      </label>
                    </li>
                    <li>
                      <label ID="LunidadInyeccion" class="label-datos-home" style="width: 40%;">{{ 'unidadInyeccion' | translate}}</label>
                      <label ID="ldatounidadInyeccion" class="label-datos-valor-home" style="width: 60%;" data-toggle="tooltip" data-placement="bottom" title="{{unidadInyeccion}}">
                        {{unidadInyeccion}}
                      </label>
                    </li>
                    <li>
                      <label ID="LnumeroMaquina" class="label-datos-home" style="width: 40%;">{{ 'numeroMaquina' | translate}}</label>
                      <label ID="ldatonumeroMaquina" class="label-datos-valor-home" style="width: 60%;" data-toggle="tooltip" data-placement="bottom" title="{{numMaquina}}">
                        {{numMaquina}}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--DATOS-->
      <div class="row" (click)="redirigirHmi()">
        <div class="col">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'datos' | translate }}</label>
              </h3>
            </div>

            <div class="datos-of">

              <div class="pdatos-of-inner">

                <div id="divDatosof" class="datos-of-content">
                  <ul>
                    <li>
                      <label ID="LcodOf" class="label-datos-home">{{ 'of' | translate}}</label>
                      <label ID="ldatocodof" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ refOF }}">
                        {{ refOF }}
                      </label>
                    </li>
                    <li>
                      <label ID="LcodOf" class="label-datos-home">{{ 'cliente' | translate}}</label>
                      <label ID="ldatocodof" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ cliente }}">
                        {{ cliente }}
                      </label>
                    </li>
                    <li>
                      <label ID="LcodOf" class="label-datos-home">{{ 'pieza' | translate}}</label>
                      <label ID="ldatocodof" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ pieza }}">
                        {{ pieza }}
                      </label>
                    </li>
                    <li *ngIf="parte != '' && parte != ' ' && parte != '  '">
                      <label ID="LcodOf" class="label-datos-home">{{ 'parte' | translate}}</label>
                      <label ID="ldatocodof" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ parte }}">
                        {{ parte }}
                      </label>
                    </li>
                    <li>
                      <label ID="LcodOf" class="label-datos-home" *ngIf="tipo_maquina != 12">{{ 'operacion' | translate}}</label>
                      <label ID="LcodOf" class="label-datos-home" *ngIf="tipo_maquina == 12">{{ 'tratamiento' | translate}}</label>

                      <label ID="ldatocodof" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ operacion }}">
                        {{ operacion }}
                      </label>
                    </li>
                    <li *ngIf="tipo_maquina != 12">
                      <label ID="LnumSerie" class="label-datos-home">{{ 'nserie' | translate}}</label>
                      <label ID="ldatoNumSerie" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ numeroSerie }}">
                        {{ numeroSerie }}
                      </label>
                    </li>
                    <li>
                      <label ID="Lcantidad" class="label-datos-home" *ngIf="tipo_maquina != 12">{{ 'cantidad2' | translate}}</label>
                      <label ID="Lcantidad" class="label-datos-home" *ngIf="tipo_maquina == 12">{{ 'numpiezas' | translate}}</label>
                      <label ID="LdatoCantidad" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{ realizadas }}">
                        {{ realizadas }}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--MAQUINA (PARA HORNOS)-->
      <div class="row" *ngIf="tipo_maquina==5">
        <div class="col" >
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'maquina' | translate }}</label>
              </h3>
            </div>

            <div class="datos-of">

              <div class="pdatos-of-inner">

                <div id="divDatosof" class="datos-of-content">
                  <ul>
                    <li>
                      <label ID="LeurosHora" class="label-datos-home" style="width: 40%;">{{ 'eurosHora' | translate}}</label>
                      <label ID="ldatoeurosHora" class="label-datos-valor-home" style="width: 40%;" data-toggle="tooltip" data-placement="bottom" title="{{eurosHora}}">
                        {{eurosHora}} �/h
                      </label>
                      <label ID="ldatoporcenEurosHora" class="label-datos-valor-home" style="width: 20%;" data-toggle="tooltip" data-placement="bottom" title="{{porcenEurosHora}}">
                        {{porcenEurosHora}}%
                      </label>
                    </li>
                    <li>
                      <label ID="LpiezasHora" class="label-datos-home" style="width: 40%;">{{ 'piezasHora' | translate}}</label>
                      <label ID="ldatopiezasHora" class="label-datos-valor-home" style="width: 40%;" data-toggle="tooltip" data-placement="bottom" title="{{piezasHora}}">
                        {{piezasHora}} {{ 'piezas' | translate}}/h
                      </label>
                      <label ID="ldatoporcenPiezasHora" class="label-datos-valor-home" style="width: 20%;" data-toggle="tooltip" data-placement="bottom" title="{{porcenPiezasHora}}">
                        {{porcenPiezasHora}}%
                      </label>
                    </li>
                    <li>
                      <label ID="LtRestCalidad" class="label-datos-home" style="width: 40%;">{{ 'tRestCalidad' | translate}}</label>
                      <label ID="ldatotRestCalidad" class="label-datos-valor-home" style="width: 60%;" data-toggle="tooltip" data-placement="bottom" title="{{tRestCalidad}}">
                        {{tRestCalidad}}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--ALERTAS KW Y TEMPERATURA PARA HORNO-->
      <ng-container *ngIf="tipo_maquina == 5">
        <div *ngIf="kilowatio" class="alerta-kw" style="right: 15px; top: 14px;">Kw</div>
        <div *ngIf="temperatura" class="alerta-temperatura" style="right: 15px; top: 14px;"><i class="fas fa-thermometer-full" style="margin-top:3px; font-size: 20px;"></i></div>
      </ng-container>

      <!--EJES (PARA MECANIZADO)-->
      <div class="row" *ngIf="tipo_maquina==1" (click)="redirigirHmi()">
        <div class="col">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'ejes' | translate }}</label>
              </h3>
            </div>

            <div class="ejes-grupo-home">

              <!--DONUTS-->
              <div class="donut-grupo-cm" *ngFor="let eje of ejes">
                <div class="donut-porcent-cont">
                  <div class="alerticonos-linea">
                    <div class="alerticonos-cont" *ngIf="eje.alertaTemperatura">
                      <img src="{{ eje.logoalarmatemp }}" />
                    </div>
                    <div class="alerticonos-cont" *ngIf="eje.alertaConsumo">
                      <img src="{{ eje.logoalarmaconsum }}" />
                    </div>
                  </div>
                  <label class="donut-porcent">{{ eje.valor }}</label>
                  <label class="donut-porcent-rpm">{{ eje.nombre }}</label>
                </div>
                <img class="donut-img" src="assets/DC48Verde/dc{{ eje.valorGrafico }}.png" />
              </div>

            </div>

          </div>
        </div>
      </div>

      <!--TEMPERATURAS (PARA EXTRUSORAS)-->
      <div class="row" *ngIf="tipo_maquina==2" (click)="redirigirHmi()">
        <div class="col">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'temperaturas' | translate }}</label>
              </h3>
            </div>

            <div class="ejes-grupo-home">
              <div class="row">
                <div class="col-6">
                  <div class="row" style="background-color: #f0f5ff; margin: 0 -5px 0 0; padding: 6px 0px 6px 0px;">
                    <div class="col-12">
                      <label class="panel-ext-label-title-home">{{ 'rendSopladores' | translate }}</label>
                    </div>
                    <div class="col-12">
                      <label class="panel-ext-label-text">{{ rendSopladores.toFixed(1) }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row" style="background: #f0f5ff; margin: 0 10px 0 -12px; padding: 6px 0px 6px 0px;">
                    <div class="col-12">
                      <label class="panel-ext-label-title-home">{{ 'controlEsp' | translate }}</label>
                    </div>
                    <div class="col-12">
                      <label class="panel-ext-label-text">{{ controlEsp.toFixed(1) }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--DONUTS-->
            <div class="ejes-grupo-home">
              <div class="donut-grupo-cm" *ngFor="let temp of temperaturasExtrusora">
                <div class="donut-porcent-cont">
                  <div class="alerticonos-linea" style="min-height: 15px;">
                  </div>
                  <label class="donut-porcent">{{ temp.valor }}</label>
                  <label class="donut-porcent-rpm">{{ temp.nombre }}</label>
                </div>
                <img class="donut-img" src="assets/DC48Verde/dc{{ temp.valorGrafico }}.png" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--PRESIONES (PARA IMPRESORAS DE PLASTICO)-->
      <div class="row" *ngIf="tipo_maquina==3" (click)="redirigirHmi()">
        <div class="col">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'valores' | translate }}</label>
              </h3>
            </div>

            <!-- M/MIN-->
            <div class="row panel-mmin" style="margin-left: 15px;">
              <label class="label-mmin">{{ speed }}</label><label class="label-mmin-unidad"> m/min</label>
            </div>

            <!--SEC-->
            <div class="ejes-grupo-home">
              <div class="row">
                <div class="col-6">
                  <div class="row" style="background-color: #f0f5ff; margin: 0 -5px 0 0; padding: 6px 0px 6px 0px;">
                    <div class="col-12">
                      <label class="panel-ext-label-title-home">{{ 'secTempBCD' | translate }}</label>
                    </div>
                    <div class="col-12">
                      <label class="panel-ext-label-text">{{ secTempBCD.toFixed(1) }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row" style="background: #f0f5ff; margin: 0 10px 0 -12px; padding: 6px 0px 6px 0px;">
                    <div class="col-12">
                      <label class="panel-ext-label-title-home">{{ 'secTunelTempBCD' | translate }}</label>
                    </div>
                    <div class="col-12">
                      <label class="panel-ext-label-text">{{ secTunelTempBCD.toFixed(1) }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--DONUTS-->
            <div class="ejes-grupo-home">
              <div class="donut-grupo-cm" *ngFor="let pres of presionesImpresora">
                <div class="donut-porcent-cont">
                  <div class="alerticonos-linea" style="min-height: 15px;">
                  </div>
                  <label class="donut-porcent">{{ pres.valor }}</label>
                  <label class="donut-porcent-rpm">{{ pres.nombre }}</label>
                </div>
                <img class="donut-img" src="assets/DC48Verde/dc{{ pres.valorGrafico }}.png" />
              </div>
            </div>

          </div>
        </div>
      </div>

      <!--HORNOS TEMPLE-->
      <!--temperaturas-->
      <div class="row" *ngIf="tipo_maquina==12" (click)="redirigirHmi()">
        <div class="col">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'temperaturas' | translate }}</label>
              </h3>
            </div>

            <div class="ejes-grupo-home">
              <div class="donut-grupo-cm" *ngFor="let temp of temperaturasHornoTemple">
                <div class="donut-porcent-cont">
                  <div class="alerticonos-linea" style="min-height: 15px;">
                  </div>
                  <label class="donut-porcent">{{ temp.valor }}</label>
                  <label class="donut-porcent-rpm">{{ temp.nombre }}</label>
                </div>
                <img class="donut-img" src="assets/DC48Verde/dc{{ temp.valorGrafico }}.png" />
              </div>
            </div>
          </div>
        </div>
      </div>



      <!--RESUMEN SEMANA-->
      <div class="row" (click)="redirigirInformeRendimiento()">
        <div class="col" style=" margin-top: 15px;">
          <div class="card-menu-desplegado">

            <div class="card-header">
              <h3>
                <label>{{ 'resumenSemana' | translate }}</label>
              </h3>
            </div>

            <div id="graficoResumenSemana_Isometrico"></div>

          </div>
        </div>
      </div>

      <!--OEE-->
      <div class="row" (click)="redirigirOee()">
        <div class="col">
          <div class="card-menu-desplegado">
            <!-- LOADING PANEL -->
            <div *ngIf="esperadnoOEE && primeraCarga" class="k-i-loading" style="z-index: 3;">
            </div>

            <div class="card-header">
              <h3>
                <label>{{ 'oee' | translate }}</label>
              </h3>
            </div>

            <div class="row">
              <!--OEE-->
              <div class="col-md-12">
                <div id="graficoDonutOee_Isometrico" style="height: 100px; "></div>
                <p class="oee-donut-label">{{ 'oee' | translate }}</p>
              </div>
            </div>

            <div class="row">
              <!--Disponibilidad-->
              <div class="col-md-4">
                <div id="graficoDonutDisponibilidad_Isometrico" style="height: 90px; "></div>
                <p class="oee-donut-label">{{ 'disponibilidad' | translate }}</p>
              </div>
              <!--Rendimiento-->
              <div class="col-md-4">
                <div id="graficoDonutRendimiento_Isometrico" style="height: 90px; "></div>
                <p class="oee-donut-label">{{ 'rendimiento' | translate }}</p>
              </div>
              <!--Calidad-->
              <div class="col-md-4">
                <div id="graficoDonutCalidad_Isometrico" style="height: 90px; "></div>
                <p class="oee-donut-label">{{ 'calidad' | translate }}</p>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>

</div>


<!--MENU LATERAL INSTALACIONES-->
<div class="panel-menu" [ngClass]="{ 'desplegado': menuDesplegadoInstalaciones }" style="overflow-x: hidden; overflow-y: auto; height: calc(100% - 56px);">

  <!--TITULO INSTALACION-->
  <div class="panel-menu-inner">
    <div class="bloque-innermenu">
      <h3 class="titulo-maquina">{{ instalacionMomentanea.nombre }}</h3>
    </div>
  </div>

  <!--CONTENIDO INSTALACION-->
  <div class="panel-menu-inner">

    <div class="bloque-innermenu-white">

      <!--CAMPOS-->
      <div class="row" *ngFor="let campo of campos; let i = index" [ngClass]="{ 'd-none': !campo.visible }" (click)="redirigirHmiInstalacion()">

        <div class="col" style="margin-bottom: -15px;">
          <div class="card-menu-desplegado" style="background-color: #f3f3f3;">

            <div class="card-header">
              <h3>
                <label>{{ campo.nombre }}</label>
              </h3>
            </div>

            <div class="row">
              <div class="col-md-3" style="padding-left: 22px;">
                <div [attr.id]="'graficoValorInstalacion_' + (i+1)" style="margin: 0 0px -8px 0px;"></div>
                <label style="text-align: center; width: 105px; color: #777777; font-size: 15px;">{{ campo.valor }}</label>
              </div>
              <div class="col-md-9">
                <div [attr.id]="'graficoHistoricoInstalacion_' + (i+1)"></div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</div>
