import { Component, OnInit } from '@angular/core';
import { MaquinasService, MenuService, UsuariosService, AreasProductivasService, OFService } from '@app/_services';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-compatibilidadmaquinas',
  templateUrl: './compatibilidadmaquinas.component.html'
})

export class CompatibilidadmaquinasComponent implements OnInit {

  maquinasfilas: any[];
  maquinascolumnas: any[];
  compatibilidades: any[];
  modalReferenceloading: NgbModalRef;
  cargado: Boolean;
  percentagesGrid: any;
  percentageInput: any;
  columna: number = 0;
  fila: number = 0;
  user = this.userService.userValue;

  //for tabs
  real: String = 'Real';
  v1: String = 'V1';
  v2: String = 'V2';
  v3: String = 'V3';

  //arrays para guardar datos por versiones
  versionReal: any[] = [];
  versionV1: any[] = [];
  versionV2: any[] = [];
  versionV3: any[] = [];

  //indicador de version para guardar datos
  indexVersion: number = 1;

  pruebaCarga: Boolean = false;

  // TABLA NUEVA
  public dataMaquinasCompatibilidades: any = [];
  public listaMaquinas: any = [];
  public listaMaquinasComp: any = [];
  public tablaMaquinas: any = [];
  public versionSelected: number = 1;
  public listaEliminar: string = '';
  public listaGuardar = [];
  public listaEditar = [];

  // FILTRO AREAS
  public areasProductivas = [];
  public grupos = [];
  public selectedAreaSeccion: any;
  public selectedGrupo: any;
  public maquinas = [];
  public listaMaquinasSeccion = [];
  public infoMaquinas = [];
  public vaciarMaquinas: boolean = false;

  constructor(private maquinasService: MaquinasService, private menuService: MenuService, public router: Router, private translate: TranslateService,
    private modalService: NgbModal, private userService: UsuariosService,
    private areasProductivasService: AreasProductivasService,
    private oFService: OFService, protected alertService: AlertService) {

    this.cargado = false;
    this.menuService.titulo = this.translate.instant('compatibilidadMaquinas');
  }

  ngOnInit(): void {
    this.compatibilidades = [];
    this.maquinasService.compatibilidades().subscribe((result) => {
      //separar datos por versiones
      var dataMaquinas: any = result;
      this.dataMaquinasCompatibilidades = dataMaquinas
      //Filtro area productiva
      this.areasProductivasService.Get_AreasConSecciones(this.user.id).subscribe((json) => {
        var areas: any = json;
        var data = [];
        this.selectedAreaSeccion = [];

        areas.forEach(area => {
          // var areaSeccion = {
          //   idArea: area.idAreaProductiva,
          //   nombreArea: area.nombreAreaProductiva,
          //   idSeccion: area.idSeccion,
          //   nombreSeccion: area.nombreSeccion
          // }
          // data.push(areaSeccion);
          this.areasProductivas.push(area);
        });
        this.selectedAreaSeccion.push(this.areasProductivas[0]);
        //this.areasProductivas = groupBy(data, [{ field: "nombreArea" }])

        this.maquinasService.get().subscribe((json) => {
          var maquinasValues: any = json;
          var maquinaLista = [];
          maquinasValues.forEach(maquinaValue => {
            this.maquinas.push({ text: maquinaValue.nombre, value: maquinaValue.nombre });
            var maquina = {
              idMaquina: maquinaValue.id,
              maquina: maquinaValue.nombre,
              idSeccion: maquinaValue.idSeccion,
              idsGrupos: maquinaValue.idsGrupos
            };
            maquinaLista.push(maquina);
          });
          this.listaMaquinas = maquinaLista;

          this.listaMaquinas.forEach(listaMaquinas => {
            this.selectedAreaSeccion.forEach(selectedArea => {
              if (listaMaquinas.idSeccion == selectedArea.idSeccion) {
                this.listaMaquinasSeccion.push(listaMaquinas);
              }
              // Taula berria
              this.listaMaquinasComp = dataMaquinas.maquinascolumnas;
              this.tablaMaquinas = [];
            });
          });

          this.oFService.GetGruposMaquinas_SeguimientoProyecto().subscribe(data => {
            var grupos: any = data;
            var dato = [];
            this.selectedGrupo = [];

            grupos.forEach(grupo => {
              var areaSeccion = {
                idGrupo: grupo.id,
                nombreGrupo: grupo.nombre
              }
              dato.push(areaSeccion);
              this.grupos.push(grupo);
            });
            this.selectedGrupo.push(this.grupos[0]);
            //this.grupos = dato;
            this.cambiarAreaSeccion();
          });

        });
      });
    });
  }

  ngAfterViewChecked() {
    if (this.cargado == false) {
      this.fila = 0;
      //this.cargarDatos(this.indexVersion);

    }
  }

  //cambio de ngAfterViewChecked a cargar datos para poder actualizar los datos manualmente
  cargarDatos(version) {
    //if (this.cargado == false) {
    //this.indexVersion = 1;
    this.fila = 0;
    this.percentagesGrid = document.getElementsByClassName("chk_compatible");
    var maxIndex = this.maquinascolumnas?.length;
    //var columna= 0, fila = 0;
    if (this.percentagesGrid != undefined && this.maquinasfilas != undefined && this.maquinasfilas?.length > 0 && this.maquinascolumnas?.length > 0) {
      for (this.percentageInput of this.percentagesGrid) {
        if (this.maquinasfilas[this.fila] !== undefined && this.maquinasfilas[this.fila].id !== undefined) {
          var idMaquina = this.maquinasfilas[this.fila].id;
          if (this.maquinascolumnas)
            var idMaquinaCompatible = this.maquinascolumnas[this.columna].id;
          if (idMaquina === idMaquinaCompatible) {
            this.percentageInput.disabled = true;
            //this.percentageInput.children.value = 100;
            this.percentageInput.children[0].children[0].value = 100;
            // this.percentageInput.children[0].children[0].disabled=true;
            // this.percentageInput.children[0].disabled=true;
            // this.percentageInput.disabled=true;
            this.percentageInput.classList.add("k-state-disabled");
          } else {
            //this.percentageInput.classList.remove("k-state-disabled");

            /* if(this.compatibilidades){
              var index = this.compatibilidades.findIndex((element) => element.idMaquina == idMaquina && element.idMaquinaCompatible == idMaquinaCompatible);
              if (index >= 0) {
                this.percentageInput.children[0].children[0].value=Number(this.compatibilidades[index].porcenCompatibilidad);
              } 
            }else{
                this.percentageInput.children[0].children[0].value=0;
            } */

            //cambio para cargar datos por versiones
            switch (version) {
              case 1:
                if (this.versionReal) {
                  var index = this.versionReal.findIndex((element) => element.idMaquina == idMaquina && element.idMaquinaCompatible == idMaquinaCompatible);
                  if (index >= 0) {
                    this.percentageInput.children[0].children[0].value = Number(this.versionReal[index].porcenCompatibilidad);
                  }
                } else {
                  this.percentageInput.children[0].children[0].value = 0;
                }
                break;
              case 2:
                if (this.versionV1) {
                  var index = this.versionV1.findIndex((element) => element.idMaquina == idMaquina && element.idMaquinaCompatible == idMaquinaCompatible);
                  if (index >= 0) {
                    this.percentageInput.children[0].children[0].value = Number(this.versionV1[index].porcenCompatibilidad);
                  }
                } else {
                  this.percentageInput.children[0].children[0].value = 0;
                }
                break;
              case 3:
                if (this.versionV2) {
                  var index = this.versionV2.findIndex((element) => element.idMaquina == idMaquina && element.idMaquinaCompatible == idMaquinaCompatible);
                  if (index >= 0) {
                    this.percentageInput.children[0].children[0].value = Number(this.versionV2[index].porcenCompatibilidad);
                  }
                } else {
                  this.percentageInput.children[0].children[0].value = 0;
                }
                break;
              case 4:
                if (this.versionV3) {
                  var index = this.versionV3.findIndex((element) => element.idMaquina == idMaquina && element.idMaquinaCompatible == idMaquinaCompatible);
                  if (index >= 0) {
                    this.percentageInput.children[0].children[0].value = Number(this.versionV3[index].porcenCompatibilidad);
                  }
                } else {
                  this.percentageInput.children[0].children[0].value = 0;
                }
                break;
              default:
                break;
            }
          }
          this.cargado = true;
        }
        if (this.columna == maxIndex - 1) {
          this.columna = 0;
          this.fila++;
        } else {
          this.columna++;
        }
      }

    }
    //}
  }

  correctValues(event: any) {
    event.target.value = Math.floor(event.target.value);
    if (event.target.value < 0)
      event.target.value = 0
    else if (event.target.value > 100)
      event.target.value = 100;
  }

  tabSelected(version: any) {
    this.versionSelected = version.index + 1;
  }

  onSubmit(contentloading, e) {
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.compatibilidades = []
    var datosNuevosCompatibilidad = [];
    this.tablaMaquinas[this.versionSelected - 1].datos.forEach(maquina => {
      maquina.compatibles.forEach(compatible => {
        if (compatible.porcenCompatibilidad != 0 && compatible.porcenCompatibilidad != '') {
          var compatibilidad = {
            idmaquina: maquina.idMaquina,
            idmaquinacompatible: compatible.idMaquinaCompatible,
            porcenCompatibilidad: compatible.porcenCompatibilidad,
            version: this.versionSelected,
            idDB: compatible.idDB
          };
          //this.compatibilidades.push(compatibilidad);
          datosNuevosCompatibilidad.push(compatibilidad);
        }
      });
    });
    var a = this.compatibilidades;
    this.compararDatosGuardar(datosNuevosCompatibilidad);
    var r1 = false;
    var r2 = false;
    var r3 = false;
    if (this.listaEliminar != '') {
      this.maquinasService.deleteCompatibilidades(this.listaEliminar).subscribe((result1) => {
        r1 = true;
        if (r1 && r2 && r3) {
          this.ngOnInit();
          this.modalReferenceloading.close();
        }
      });
    } else {
      r1 = true;
      if (r1 && r2 && r3) {
        this.ngOnInit();
        this.modalReferenceloading.close();
      }
    }
    if (this.listaGuardar.length > 0) {
      this.maquinasService.insertCompatibilidadesBulk(this.listaGuardar).subscribe((result2) => {
        r2 = true;
        if (r1 && r2 && r3) {
          this.ngOnInit();
          this.modalReferenceloading.close();
        }
      });
    } else {
      r2 = true;
      if (r1 && r2 && r3) {
        this.ngOnInit();
        this.modalReferenceloading.close();
      }
    }
    if (this.listaEditar.length > 0) {
      this.maquinasService.updateCompatibilidades(this.listaEditar).subscribe((result3) => {
        r3 = true;
        if (r1 && r2 && r3) {
          this.ngOnInit();
          this.modalReferenceloading.close();
        }
      });
    } else {
      r3 = true;
      if (r1 && r2 && r3) {
        this.ngOnInit();
        this.modalReferenceloading.close();
      }
    }
  }

  onSubmit1(contentloading, e) {

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

    this.compatibilidades = [];
    var maxIndex = this.maquinascolumnas.length;
    var columna = 0, fila = 0;
    this.percentagesGrid = document.getElementsByClassName("chk_compatible");

    for (this.percentageInput of this.percentagesGrid) {
      var idMaquina = this.maquinasfilas[fila].id;
      var idMaquinaCompatible = this.maquinascolumnas[columna].id;
      var compatibilidad;
      if (Number(this.percentageInput.children[0].children[0].value.split(',')[0]) > 0) {
        compatibilidad = { idmaquina: idMaquina, idmaquinacompatible: idMaquinaCompatible, porcenCompatibilidad: Number(Number(this.percentageInput.children[0].children[0].value.split(',')[0])), version: this.indexVersion };
        this.compatibilidades.push(compatibilidad);
      }
      if (columna == maxIndex - 1) {
        columna = 0;
        if (fila < maxIndex - 1) {
          fila++;
        }

      } else {
        columna++;
      }
    }
    this.maquinasService.guardarcompatibilidades(this.compatibilidades).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
        this.router.navigate(['compatibilidadmaquinas']);
      }
      else {
        this.alertService.success(this.translate.instant("error"), { keepAfterRouteChange: true });
      }
    });
    window.location.reload();
  }


  public cargarTablaCompatibilidades(listaMaquinasNueva: any) {

    this.tablaMaquinas = [];
    if (!this.vaciarMaquinas) {
      for (var i = 1; i < 5; i++) {
        var tablaMaquinasVersion = [];
        listaMaquinasNueva.forEach(maquinaRow => {
          var compa = [];
          listaMaquinasNueva.forEach(maquinaCol => {
            var compFind = false;
            this.dataMaquinasCompatibilidades.compatibilidades.forEach(compatibilidad => {
              if (maquinaRow.idMaquina == compatibilidad.idMaquina && maquinaCol.idMaquina == compatibilidad.idMaquinaCompatible && compatibilidad.version == i && !compFind) {
                var data
                if (compatibilidad.porcenCompatibilidad == 0) {
                  data = {
                    idMaquinaCompatible: compatibilidad.idMaquinaCompatible,
                    nombreMaquinaCompatible: maquinaCol.maquina,
                    porcenCompatibilidad: '',
                    idDB: compatibilidad.idDB
                  };
                } else {
                  data = {
                    idMaquinaCompatible: compatibilidad.idMaquinaCompatible,
                    nombreMaquinaCompatible: maquinaCol.maquina,
                    porcenCompatibilidad: compatibilidad.porcenCompatibilidad,
                    idDB: compatibilidad.idDB
                  };
                }
                compFind = true;
                compa.push(data);
              }
            });
            if (!compFind) {
              var data
              if (maquinaRow.idMaquina == maquinaCol.idMaquina) {
                data = {
                  idMaquinaCompatible: maquinaCol.idMaquina,
                  nombreMaquinaCompatible: maquinaCol.maquina,
                  porcenCompatibilidad: 100,
                  idDB: -1
                };
              } else {
                data = {
                  idMaquinaCompatible: maquinaCol.idMaquina,
                  nombreMaquinaCompatible: maquinaCol.maquina,
                  porcenCompatibilidad: '',
                  idDB: -1
                };
              }

              compa.push(data);
            }
          });
          var data2 = {
            idMaquina: maquinaRow.idMaquina,
            nombreMaquina: maquinaRow.maquina,
            compatibles: compa
          };
          tablaMaquinasVersion.push(data2);
        });
        var data3 = {
          version: i,
          datos: tablaMaquinasVersion
        };
        this.tablaMaquinas.push(data3);
      }
    }

  }

  public cambiarAreaSeccion() {
    //var selection: any = this.selectedAreaSeccion;
    this.listaMaquinasSeccion = [];
    if (this.selectedAreaSeccion.length == 0 || this.selectedGrupo.length == 0) {
      this.vaciarMaquinas = true;
      this.cargarTablaCompatibilidades(this.listaMaquinasSeccion);
    } else {
      this.vaciarMaquinas = false;
      this.listaMaquinas.forEach(listaMaquinas => {
        this.selectedAreaSeccion.forEach(selectedArea => {
          this.selectedGrupo.forEach(grupo => {
            if (listaMaquinas.idSeccion == selectedArea.idSeccion && listaMaquinas.idsGrupos.includes(grupo.id)) {
              this.listaMaquinasSeccion.push(listaMaquinas);
            }
          });
        });
      });
      this.cargarTablaCompatibilidades(this.listaMaquinasSeccion);
    }


  }

  public onChange(e: any, data: any, i: any) {
    var a = e;
    // this.tablaMaquinas[this.versionSelected - 1].datos.forEach(maquina => {
    //   if(maquina.idMaquina == data.idMaquina){
    //     maquina.compatibles[i]
    //   }
    // });

  }

  // Comparar datos originales de la base de datos con las nuevas para sacar las compatibilidades a eliminar/guardara/editar
  public compararDatosGuardar(datosAGuardar) {
    this.listaEliminar = '';
    this.listaGuardar = [];
    this.listaEditar = [];
    // Comparamos la lista nueva con la original para ver los que ya no estan
    this.dataMaquinasCompatibilidades.compatibilidades.forEach(datoOriginal => {
      var aurk = false;
      // Verificar que la compatibilidad es de la version en la que estamos trabajando
      if (datoOriginal.version == this.versionSelected) {
        var buk = true;
        var kont = 0;
        var areaGrupoCorrecto = false;
        // Comprobamos que las maquinas que filtramos son del grupo y la seccion correcta
        while (buk) {
          this.selectedAreaSeccion.forEach(selectedArea => {
            this.selectedGrupo.forEach(grupo => {
              if (datoOriginal.idMaquina == this.listaMaquinasSeccion[kont].idMaquina
                && this.listaMaquinasSeccion[kont].idSeccion == selectedArea.idSeccion
                && this.listaMaquinasSeccion[kont].idsGrupos.includes(grupo.id)) {
                buk = false;
                areaGrupoCorrecto = true;
              }
            });

          });

          kont += 1;
          if (kont >= this.listaMaquinasSeccion.length) {
            buk = false;
          }
        }
        if (areaGrupoCorrecto) {
          datosAGuardar.forEach(datosNuevos => {
            if (datoOriginal.idMaquina == datosNuevos.idmaquina && datoOriginal.idMaquinaCompatible == datosNuevos.idmaquinacompatible) {
              // Si los ids coinciden pero el dato no, actualizamos
              if (datoOriginal.porcenCompatibilidad != datosNuevos.porcenCompatibilidad) {
                var datoUpdate = {
                  idDB: datosNuevos.idDB,
                  porcenCompatibilidad: datosNuevos.porcenCompatibilidad
                }
                this.listaEditar.push(datoUpdate);
              }
              aurk = true;
            }
          });
          // Si el dato esta en la original pero no en la nueva, eliminar
          if (!aurk) {
            if (this.listaEliminar.length == 0) {
              this.listaEliminar = datoOriginal.idDB.toString();
            } else {
              this.listaEliminar = this.listaEliminar + ',' + datoOriginal.idDB.toString();
            }
          }
        }

      }
    });
    // Comparamos la nueva con la original para saber cuales son las nuevas
    datosAGuardar.forEach(datosNuevos => {
      var aurk2 = false;
      this.dataMaquinasCompatibilidades.compatibilidades.forEach(datoOriginal => {
        if (datoOriginal.version == datosNuevos.version) {
          if (datoOriginal.idMaquina == datosNuevos.idmaquina && datoOriginal.idMaquinaCompatible == datosNuevos.idmaquinacompatible) {
            aurk2 = true;
          }
        }
      });
      // Si existe en la nueva pero no en la original, guardar
      if (!aurk2) {
        var data = {
          idMaquina: datosNuevos.idmaquina,
          idMaquinaCompatible: datosNuevos.idmaquinacompatible,
          porcenCompatibilidad: datosNuevos.porcenCompatibilidad,
          version: datosNuevos.version
        }
        this.listaGuardar.push(data)
      }
    });
  }
}
