import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UsuariosService, MenuService, IncidenciasService, MaquinasService } from '@app/_services';
import { MyFunctions } from '@app/_helpers';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import * as c3 from 'c3';
import * as d3 from 'd3';

@Component({
  selector: 'app-informes-incidencias',
  templateUrl: 'informesIncidencias.component.html'
})

export class InformesIncidenciasComponent {

  public fechaini: Date;
  public fechafin: Date;

  public numIncidencias: number = 0;
  public numIncidenciasPeriodoAnterior: number = 0;
  public tiempoIncidencias: number = 0;
  public tiempoIncidenciasPeriodoAnterior: number = 0;

  public grafico_donuts: any;
  public grafico_barras: any;

  public user = this.userService.userValue;
  public navigationSubscription;
  public incidencias: any = [];
  public incidenciasDonut: any = [];
  public incidenciasPeriodoAnterior: any = [];
  public seleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  public abierto: boolean = true;
  public jsonMaquinas: any;
  public loading: boolean = false;

  formatDate = 'yyyy-MM-dd HH:mm:ss';

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  public Jmaquinas: any;
  public JmaquinasMostradas: any;
  public selectedMaquina: number[] = [];

  public aparecer = false;

  ngOnInit() {
    this.dibujarGraficos();
  }

  constructor(
    private maquinasService: MaquinasService,
    private incidenciasService: IncidenciasService,
    private translateService: TranslateService,
    public myFunctions: MyFunctions,
    private menuService: MenuService,
    public modalService: NgbModal,
    public router: Router,
    private userService: UsuariosService,
    private cdr: ChangeDetectorRef
  ) {
    this.menuService.titulo = this.translateService.instant('informesIncidencias').toUpperCase();

    this.fechaini = new Date(this.myFunctions.getDateNow().getTime() - (7 * 24 * 60 * 60 * 1000));//7 egun atzera
    this.fechafin = this.myFunctions.getDateNow()
    this.fechaini.setHours(0, 0, 0);
    this.fechafin.setHours(23, 59, 59);

    // Se ejecuta la primera vez
    if (this.router.url == '/informesincidencias') {

      this.cargarGrupos();
      /*CARGAR AREAS PRODUCTIVAS*/
      var an1: any = this.userService.secciones;
      this.secciones == undefined

      if (an1 != undefined)
        this.secciones = an1.filter(f => f.activo === true);
      if (this.secciones == undefined) {

        this.userService.getSecciones().subscribe(
          json => {
            this.userService.secciones = json;

            //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);

            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

            an.forEach(
              row => {
                if (row.activo)
                  this.seccionesSeleccionadas.push(row);
              });
          });
      } else {

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

        an.forEach(
          row => {
            if (row.activo)
              this.seccionesSeleccionadas.push(row);
          });
      }

      //MAQUINAS 
      var maquinas_model = this.maquinasService.get_maquinas_model();
      if (maquinas_model == false) {
        this.maquinasService.get().subscribe(json => {
          this.maquinasService.set_maquinas_model(json);
          this.Jmaquinas = this.maquinasService.get_maquinas_model();

          //FLTRO POR SECCIONES
          var idsSeccionesSelecteds: any = [];
          if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
            this.seccionesSeleccionadas.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          } else {
            this.secciones.forEach(
              seccion => {
                idsSeccionesSelecteds.push(seccion.id);
              });
          }
          this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

          if (this.selectedMaquina.length == 0)
            this.selectedMaquina.push(this.JmaquinasMostradas[0].id);

          this.cargarIncidencias();
          //this.cargarMaquinas(); //AHORA SE USA NGFOR!
        })
      } else {
        this.Jmaquinas = maquinas_model;

        //FLTRO POR SECCIONES
        var idsSeccionesSelecteds: any = [];
        if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
          this.seccionesSeleccionadas.forEach(
            seccion => {
              idsSeccionesSelecteds.push(seccion.id);
            });
        } else {
          this.secciones.forEach(
            seccion => {
              idsSeccionesSelecteds.push(seccion.id);
            });
        }
        this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0))

        if (this.selectedMaquina.length == 0)
          this.selectedMaquina.push(this.JmaquinasMostradas[0].id);

        this.cargarIncidencias();
      }
    }

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  dibujarGraficos() {

    var th = this;

    //grafico_donuts
    this.grafico_donuts = c3.generate({
      data: {
        columns: [],
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#grafico_donuts .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#grafico_donuts .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#grafico_donuts .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        position: 'right'
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      tooltip: {
        show: false
      },
      bindto: '#grafico_donuts'
    });

    d3.selectAll("#grafico_donuts .c3-chart-arcs path").style("stroke-width", "0px");

    //grafico_barras
    this.grafico_barras = c3.generate({
      data: {
        x: 'x',
        xFormat: '%d/%m/%Y/%H',
        columns: [],
        type: 'bar',
        colors: {
          leve: '#fcce03',
          medio: '#f0913e',
          grave: '#f4423c'
        },
        groups: [
          ['leve', 'medio', 'grave']
        ],
        order: null
      },
      axis: {
        x: {
          type: 'timeseries',
          localtime: false,
          tick: {
            format: '%d/%m/%Y'
          }
        },
        y: {
          tick: {
            format: function (d) {
              if (Math.floor(d) != d) {
                return;
              }
              return d;
            }
          },
        }
      },
      tooltip: {
        format: {
          name: function (d) { return th.translateService.instant(d); }
          //value: function (value, ratio, id) {
          //  var format = id === 'data1' ? d3.format(',') : d3.format('$');
          //  return format(value);
          //}
        }
      },
      bindto: '#grafico_barras'
    });    
    
    d3.selectAll("#grafico_barras .c3-chart-arcs path").style("stroke-width", "0px");
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
    });

  }

  seccionChanged() {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    } else {
      this.grupos.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }

    this.JmaquinasMostradas = this.Jmaquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    var idsMaquinasMostradas = this.JmaquinasMostradas.map(x => x.id);
    this.selectedMaquina = this.selectedMaquina.filter(x => idsMaquinasMostradas.includes(x));

  }
  
  onClickNuevo() {
    if (this.selectedMaquina.length > 0) {
      this.router.navigate(['informesincidencias/crear/', this.selectedMaquina[0]]);
    }
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['informesincidencias/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['informesincidencias/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickImprimir() {
    if (this.seleccionados.length > 0) {
      this.loading = true;
      this.incidenciasService.Imprimir_Incidencias({ ids: this.seleccionados }, this.selectedMaquina.join(",")).subscribe(
        (result) => {
          if (!result.error) {
            var data = this.base64ToBlob(result.impreso); // Conversión de base 64 a blob
            var blob = new Blob([data], { type: 'application/pdf' });
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            var today = this.myFunctions.getDateNow()
            var date = "" + today.getFullYear() + (today.getMonth() + 1) + today.getDate();
            var time = "" + today.getHours() + today.getMinutes() + today.getSeconds();
            link.href = downloadURL;
            link.download = this.translateService.instant('informesIncidencias') + "_" + date + time;
            link.click();
            link.remove();
            this.loading = false;
          }
        });
    }
  }

  base64ToBlob(base64str) {
    var binary = atob(base64str.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    return view;
  }

  onClickCopiar() {
    if (this.seleccionados.length > 0) {
      this.incidenciasService.Copiar_Incidencias({ ids: this.seleccionados }).subscribe(
        (data) => {
          if (data.error == false) {
            this.cargarIncidencias();
          }
        }
      );
    }
  }

  private eliminarRegistro(contentloading) {
    this.incidenciasService.Delete_Incidencias({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.cargarIncidencias();
        }
        this.modalReferenceloading.close();
      }
    );

    this.seleccionados = [];
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
  }

  // Parte switch abierto
  public onChangeAbierto(value): void {
    this.cargarIncidencias();
  }

  private maquinaClicked(maquina) {
    if (this.selectedMaquina.includes(maquina.id)) {
      this.selectedMaquina = this.selectedMaquina.filter(x => x != maquina.id);
    } else {
      this.selectedMaquina.push(maquina.id);
    }
  }

  cargarIncidencias() {

    if (this.fechaini == undefined || this.fechafin == undefined || this.fechaini.getTime() > this.fechafin.getTime() || this.selectedMaquina.length == 0) {
      return;
    }

    var fechainiPeriodoAnterior = new Date(this.fechaini.getTime() - (this.fechafin.getTime() - this.fechaini.getTime()) );

    var fini = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaini);
    var ffin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechafin);
    var finiPeriodoAnterior = this.myFunctions.dateToYYYYMMDDtHHmmSSz(fechainiPeriodoAnterior);

    this.loading = true;
    this.incidenciasService.Get_Incidencias(-1, this.selectedMaquina.join(","), !this.abierto, fini, ffin, finiPeriodoAnterior).subscribe((result) => {
      this.loading = false;
      this.incidencias = result.data;
      this.incidenciasPeriodoAnterior = result.dataPeriodoAnterior;
      this.incidenciasDonut = result.datosDonut;
      //si no hay incidencias limpiar gráfico viejo para que no se solape enl mensaje con el viejo gráfico
      if(this.incidencias.length == 0){
        this.grafico_donuts.unload();
        this.grafico_barras.unload();
      }else{
        this.cargarDatosGrafico();
      }
      
    });
  }

  cargarDatosGrafico() {
    
    //PANEL
    this.numIncidencias = this.incidencias.length;
    this.numIncidenciasPeriodoAnterior = this.incidenciasPeriodoAnterior.length;
    this.tiempoIncidencias = this.incidencias.map(x => x.duracionSegundos).reduce(function (a, b) { return a + b; }, 0);
    this.tiempoIncidenciasPeriodoAnterior = this.incidenciasPeriodoAnterior.map(x => x.duracionSegundos).reduce(function (a, b) { return a + b; }, 0);

    //GRAFICOS
    //DONUTS
    var datosDonuts = [];
    var nombresIncidencias = [...new Set(this.incidenciasDonut.map(x => x.nombre))];
    nombresIncidencias.forEach(function (nombre) {
      var incidencias = this.incidenciasDonut.filter(x => x.nombre == nombre);
      var numIncidencias = incidencias.length;
      var tiempoIncidencias = incidencias.map(x => x.diferenciaHoras).reduce(function (a, b) { return a + b; }, 0);
      datosDonuts.push([nombre + "(" + this.myFunctions.secondsTo_HH_MM_SS(tiempoIncidencias) + ")", numIncidencias]);
    }, this);

    this.grafico_donuts.load({ columns: datosDonuts });

    //BARRAS
    var datosFechas: any = ['x'];
    var datosLeves: any = ['leve'];
    var datosMedias: any = ['medio'];
    var datosGraves: any = ['grave'];

    var fechasIncidencias = [...new Set(this.incidencias.map(x => x.fechaIncidenciaGraf))];
    fechasIncidencias.forEach(function (fecha) {
      var incidenciasLeves = this.incidencias.filter(x => x.fechaIncidenciaGraf == fecha && x.gravedad == 1);
      var incidenciasMedias = this.incidencias.filter(x => x.fechaIncidenciaGraf == fecha && x.gravedad == 2);
      var incidenciasGraves = this.incidencias.filter(x => x.fechaIncidenciaGraf == fecha && x.gravedad == 3);
      datosFechas.push(fecha + "/10");
      datosLeves.push(incidenciasLeves.length);
      datosMedias.push(incidenciasMedias.length);
      datosGraves.push(incidenciasGraves.length);
    }, this);

    var datosBarras = [datosFechas, datosLeves, datosMedias, datosGraves];

    var max = Math.max(Math.max(datosLeves.length-1, datosMedias.length-1), datosGraves.length-1);
    this.grafico_barras.axis.max(max*10)
    if(this.numIncidencias > 0)
      this.grafico_barras.load({ columns: datosBarras });

    //this.grafico_barras.load({
    //  columns: [
    //    ['x', '01/01/2021/10', '02/01/2021/10', '03/01/2021/10', '04/01/2021/10', '05/01/2021/10', '06/01/2021/10', '07/01/2021/10', '08/01/2021/10', '09/01/2021/10', '10/01/2021/10'],
    //    ['leve', 5, 10, 15, 5, 20, 0, 15, 5, 20, 0],
    //    ['medio', 10, 20, 2, 4, 15, 50, 10, 20, 15, 50],
    //    ['grave', 44, 2, 10, 22, 44, 2, 10, 22, 22, 22]
    //  ]
    //});

  }

  formatingDate(fecha) {
    if (fecha) {
      return String(fecha).substring(0, this.formatDate.length).replace("T", " ");
    } else {
      return '';
    }
  }

  public onChangeFIni(value: Date): void {

  }

  public onChangeFFin(value: Date): void {

  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

}
