import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuService, UsuariosService, MaquinasService, DisponibilidadService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IntlService } from '@progress/kendo-angular-intl';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-turno-disponibilidad-detalle',
  templateUrl: 'turnoDisponibilidadDetalle.component.html'
})
export class TurnoDisponibilidadoDetalleComponent {
  public id: number;
  public ano_readonly: boolean;
  public numSemana_readonly: boolean;
  public fechaInicio: Date;
  public fechaFin: Date;
  public horario: any;

  public time = new Date("10:00");

  horasDesvio = 1;

  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  diasSemana = 
  [
    {text: this.translateService.instant('lunes'), value: 'lunes'},
    {text: this.translateService.instant('martes'), value: 'martes'},
    {text: this.translateService.instant('miercoles'), value: 'miercoles'},
    {text: this.translateService.instant('jueves'), value: 'jueves'},
    {text: this.translateService.instant('viernes'), value: 'viernes'},
    {text: this.translateService.instant('sabado'), value: 'sabado'},
    {text: this.translateService.instant('domingo'), value: 'domingo'}
  ];

  maquinas = [];

  selectedMachine;

  maquinasSeleccionadas = [];

  selectedItem = {text: this.translateService.instant('lunes'), value: 'lunes'};

  martesSelected = false;
  miercolesSelected = false;
  juevesSelected = false;
  viernesSelected = false;
  sabadoSelected = false;
  domingoSelected = false;

  @ViewChild('popupCopiarDias') popupCopiarDias: NgbModalRef;
  @ViewChild('popupCopiarMaquina') popupCopiarMaquina: NgbModalRef;
  
  //LOADING
  public loadingDuplicar: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private disponibilidadService: DisponibilidadService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private intlService: IntlService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    public myFunctions: MyFunctions) {

    this.menuService.titulo = this.translateService.instant('calendarioDisponibilidad').toUpperCase();
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      ano: ['', Validators.required],
      numSemana: ['', Validators.required],
      numDuplicarSemana: [1, Validators.required],
    });

    if (this.isAddMode) {
      this.ano_readonly = false;
      this.numSemana_readonly = false;

      this.maquinasService.get().subscribe((json) => {

        var horario = [];

        var an: any = json;
        this.disponibilidadService.Get_Turno_Defecto().subscribe((result) => {
        var def = result.data;
        an.forEach(f => {
          this.maquinas.push({text: f.nombre, value: f.nombre});
          this.maquinasSeleccionadas.push(false);
          var maquina = {
            id: 0,
            idCalendarioSemanas: 0,
            idMaquina: f.id,
            maquina: f.nombre,

            lunesMannanaInicio: new Date(1950, 1, 1, def[0].lunesMannanaInicio.split(":")[0], def[0].lunesMannanaInicio.split(":")[1], 0),
            lunesMannanaFin: new Date(1950, 1, 1, def[0].lunesMannanaFin.split(":")[0], def[0].lunesMannanaFin.split(":")[1], 0),
            lunesTardeInicio: new Date(1950, 1, 1, def[0].lunesTardeInicio.split(":")[0], def[0].lunesTardeInicio.split(":")[1], 0),
            lunesTardeFin: new Date(1950, 1, 1, def[0].lunesTardeFin.split(":")[0], def[0].lunesTardeFin.split(":")[1], 0),
            lunesNocheInicio: new Date(1950, 1, 1, def[0].lunesNocheInicio.split(":")[0], def[0].lunesNocheInicio.split(":")[1], 0),
            lunesNocheFin: new Date(1950, 1, 1, def[0].lunesNocheFin.split(":")[0], def[0].lunesNocheFin.split(":")[1], 0),

            martesMannanaInicio: new Date(1950, 1, 1, def[0].martesMannanaInicio.split(":")[0], def[0].martesMannanaInicio.split(":")[1], 0),
            martesMannanaFin: new Date(1950, 1, 1, def[0].martesMannanaFin.split(":")[0], def[0].martesMannanaFin.split(":")[1], 0),
            martesTardeInicio: new Date(1950, 1, 1, def[0].martesTardeInicio.split(":")[0], def[0].martesTardeInicio.split(":")[1], 0),
            martesTardeFin: new Date(1950, 1, 1, def[0].martesTardeFin.split(":")[0], def[0].martesTardeFin.split(":")[1], 0),
            martesNocheInicio: new Date(1950, 1, 1, def[0].martesNocheInicio.split(":")[0], def[0].martesNocheInicio.split(":")[1], 0),
            martesNocheFin: new Date(1950, 1, 1, def[0].martesNocheFin.split(":")[0], def[0].martesNocheFin.split(":")[1], 0),

            miercolesMannanaInicio: new Date(1950, 1, 1, def[0].miercolesMannanaInicio.split(":")[0], def[0].miercolesMannanaInicio.split(":")[1], 0),
            miercolesMannanaFin: new Date(1950, 1, 1, def[0].miercolesMannanaFin.split(":")[0], def[0].miercolesMannanaFin.split(":")[1], 0),
            miercolesTardeInicio: new Date(1950, 1, 1, def[0].miercolesTardeInicio.split(":")[0], def[0].miercolesTardeInicio.split(":")[1], 0),
            miercolesTardeFin: new Date(1950, 1, 1, def[0].miercolesTardeFin.split(":")[0], def[0].miercolesTardeFin.split(":")[1], 0),
            miercolesNocheInicio: new Date(1950, 1, 1, def[0].miercolesNocheInicio.split(":")[0], def[0].miercolesNocheInicio.split(":")[1], 0),
            miercolesNocheFin: new Date(1950, 1, 1, def[0].miercolesNocheFin.split(":")[0], def[0].miercolesNocheFin.split(":")[1], 0),

            juevesMannanaInicio: new Date(1950, 1, 1, def[0].juevesMannanaInicio.split(":")[0], def[0].juevesMannanaInicio.split(":")[1], 0),
            juevesMannanaFin: new Date(1950, 1, 1, def[0].juevesMannanaFin.split(":")[0], def[0].juevesMannanaFin.split(":")[1], 0),
            juevesTardeInicio: new Date(1950, 1, 1, def[0].juevesTardeInicio.split(":")[0], def[0].juevesTardeInicio.split(":")[1], 0),
            juevesTardeFin: new Date(1950, 1, 1, def[0].juevesTardeFin.split(":")[0], def[0].juevesTardeFin.split(":")[1], 0),
            juevesNocheInicio: new Date(1950, 1, 1, def[0].juevesNocheInicio.split(":")[0], def[0].juevesNocheInicio.split(":")[1], 0),
            juevesNocheFin: new Date(1950, 1, 1, def[0].juevesNocheFin.split(":")[0], def[0].juevesNocheFin.split(":")[1], 0),

            viernesMannanaInicio: new Date(1950, 1, 1, def[0].viernesMannanaInicio.split(":")[0], def[0].viernesMannanaInicio.split(":")[1], 0),
            viernesMannanaFin: new Date(1950, 1, 1, def[0].viernesMannanaFin.split(":")[0], def[0].viernesMannanaFin.split(":")[1], 0),
            viernesTardeInicio: new Date(1950, 1, 1, def[0].viernesTardeInicio.split(":")[0], def[0].viernesTardeInicio.split(":")[1], 0),
            viernesTardeFin: new Date(1950, 1, 1, def[0].viernesTardeFin.split(":")[0], def[0].viernesTardeFin.split(":")[1], 0),
            viernesNocheInicio: new Date(1950, 1, 1, def[0].viernesNocheInicio.split(":")[0], def[0].viernesNocheInicio.split(":")[1], 0),
            viernesNocheFin: new Date(1950, 1, 1, def[0].viernesNocheFin.split(":")[0], def[0].viernesNocheFin.split(":")[1], 0),

            sabadoMannanaInicio: new Date(1950, 1, 1, def[0].sabadoMannanaInicio.split(":")[0], def[0].sabadoMannanaInicio.split(":")[1], 0),
            sabadoMannanaFin: new Date(1950, 1, 1, def[0].sabadoMannanaFin.split(":")[0], def[0].sabadoMannanaFin.split(":")[1], 0),
            sabadoTardeInicio: new Date(1950, 1, 1, def[0].sabadoTardeInicio.split(":")[0], def[0].sabadoTardeInicio.split(":")[1], 0),
            sabadoTardeFin: new Date(1950, 1, 1, def[0].sabadoTardeFin.split(":")[0], def[0].sabadoTardeFin.split(":")[1], 0),
            sabadoNocheInicio: new Date(1950, 1, 1, def[0].sabadoNocheInicio.split(":")[0], def[0].sabadoNocheInicio.split(":")[1], 0),
            sabadoNocheFin: new Date(1950, 1, 1, def[0].sabadoNocheFin.split(":")[0], def[0].sabadoNocheFin.split(":")[1], 0),

            domingoMannanaInicio: new Date(1950, 1, 1, def[0].domingoMannanaInicio.split(":")[0], def[0].domingoMannanaInicio.split(":")[1], 0),
            domingoMannanaFin: new Date(1950, 1, 1, def[0].domingoMannanaFin.split(":")[0], def[0].domingoMannanaFin.split(":")[1], 0),
            domingoTardeInicio: new Date(1950, 1, 1, def[0].domingoTardeInicio.split(":")[0], def[0].domingoTardeInicio.split(":")[1], 0),
            domingoTardeFin: new Date(1950, 1, 1, def[0].domingoTardeFin.split(":")[0], def[0].domingoTardeFin.split(":")[1], 0),
            domingoNocheInicio: new Date(1950, 1, 1, def[0].domingoNocheInicio.split(":")[0], def[0].domingoNocheInicio.split(":")[1], 0),
            domingoNocheFin: new Date(1950, 1, 1, def[0].domingoNocheFin.split(":")[0], def[0].domingoNocheFin.split(":")[1], 0),
          };

          horario.push(maquina)
        })

        this.horario = horario;
        });
      });

    } else {
      this.ano_readonly = true;
      this.numSemana_readonly = true;

      this.disponibilidadService.Get_Turno(this.id).pipe().subscribe((result) => {
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          ano: [result.data[0].ano, Validators.required],
          numSemana: [result.data[0].numSemana, Validators.required],
          numDuplicarSemana: [1, Validators.required],
        });

        this.fechaInicio = new Date(result.data[0].fechaInicio);
        this.fechaFin = new Date(result.data[0].fechaFin);

        this.disponibilidadService.Get_Turno_Detalle(this.id).subscribe((result) => {

          var an: any = result.data;
          an.forEach(f => {
            this.maquinas.push({text: f.maquina, value: f.maquina});
            this.maquinasSeleccionadas.push(false);

            f.lunesMannanaInicio = new Date(1950, 1, 1, f.lunesMannanaInicio.split(":")[0], f.lunesMannanaInicio.split(":")[1], 0);
            f.lunesMannanaFin = new Date(1950, 1, 1, f.lunesMannanaFin.split(":")[0], f.lunesMannanaFin.split(":")[1], 0);
            f.lunesTardeInicio = new Date(1950, 1, 1, f.lunesTardeInicio.split(":")[0], f.lunesTardeInicio.split(":")[1], 0);
            f.lunesTardeFin = new Date(1950, 1, 1, f.lunesTardeFin.split(":")[0], f.lunesTardeFin.split(":")[1], 0);
            f.lunesNocheInicio = new Date(1950, 1, 1, f.lunesNocheInicio.split(":")[0], f.lunesNocheInicio.split(":")[1], 0);
            f.lunesNocheFin = new Date(1950, 1, 1, f.lunesNocheFin.split(":")[0], f.lunesNocheFin.split(":")[1], 0);

            f.martesMannanaInicio = new Date(1950, 1, 1, f.martesMannanaInicio.split(":")[0], f.martesMannanaInicio.split(":")[1], 0);
            f.martesMannanaFin = new Date(1950, 1, 1, f.martesMannanaFin.split(":")[0], f.martesMannanaFin.split(":")[1], 0);
            f.martesTardeInicio = new Date(1950, 1, 1, f.martesTardeInicio.split(":")[0], f.martesTardeInicio.split(":")[1], 0);
            f.martesTardeFin = new Date(1950, 1, 1, f.martesTardeFin.split(":")[0], f.martesTardeFin.split(":")[1], 0);
            f.martesNocheInicio = new Date(1950, 1, 1, f.martesNocheInicio.split(":")[0], f.martesNocheInicio.split(":")[1], 0);
            f.martesNocheFin = new Date(1950, 1, 1, f.martesNocheFin.split(":")[0], f.martesNocheFin.split(":")[1], 0);

            f.miercolesMannanaInicio = new Date(1950, 1, 1, f.miercolesMannanaInicio.split(":")[0], f.miercolesMannanaInicio.split(":")[1], 0);
            f.miercolesMannanaFin = new Date(1950, 1, 1, f.miercolesMannanaFin.split(":")[0], f.miercolesMannanaFin.split(":")[1], 0);
            f.miercolesTardeInicio = new Date(1950, 1, 1, f.miercolesTardeInicio.split(":")[0], f.miercolesTardeInicio.split(":")[1], 0);
            f.miercolesTardeFin = new Date(1950, 1, 1, f.miercolesTardeFin.split(":")[0], f.miercolesTardeFin.split(":")[1], 0);
            f.miercolesNocheInicio = new Date(1950, 1, 1, f.miercolesNocheInicio.split(":")[0], f.miercolesNocheInicio.split(":")[1], 0);
            f.miercolesNocheFin = new Date(1950, 1, 1, f.miercolesNocheFin.split(":")[0], f.miercolesNocheFin.split(":")[1], 0);

            f.juevesMannanaInicio = new Date(1950, 1, 1, f.juevesMannanaInicio.split(":")[0], f.juevesMannanaInicio.split(":")[1], 0);
            f.juevesMannanaFin = new Date(1950, 1, 1, f.juevesMannanaFin.split(":")[0], f.juevesMannanaFin.split(":")[1], 0);
            f.juevesTardeInicio = new Date(1950, 1, 1, f.juevesTardeInicio.split(":")[0], f.juevesTardeInicio.split(":")[1], 0);
            f.juevesTardeFin = new Date(1950, 1, 1, f.juevesTardeFin.split(":")[0], f.juevesTardeFin.split(":")[1], 0);
            f.juevesNocheInicio = new Date(1950, 1, 1, f.juevesNocheInicio.split(":")[0], f.juevesNocheInicio.split(":")[1], 0);
            f.juevesNocheFin = new Date(1950, 1, 1, f.juevesNocheFin.split(":")[0], f.juevesNocheFin.split(":")[1], 0);

            f.viernesMannanaInicio = new Date(1950, 1, 1, f.viernesMannanaInicio.split(":")[0], f.viernesMannanaInicio.split(":")[1], 0);
            f.viernesMannanaFin = new Date(1950, 1, 1, f.viernesMannanaFin.split(":")[0], f.viernesMannanaFin.split(":")[1], 0);
            f.viernesTardeInicio = new Date(1950, 1, 1, f.viernesTardeInicio.split(":")[0], f.viernesTardeInicio.split(":")[1], 0);
            f.viernesTardeFin = new Date(1950, 1, 1, f.viernesTardeFin.split(":")[0], f.viernesTardeFin.split(":")[1], 0);
            f.viernesNocheInicio = new Date(1950, 1, 1, f.viernesNocheInicio.split(":")[0], f.viernesNocheInicio.split(":")[1], 0);
            f.viernesNocheFin = new Date(1950, 1, 1, f.viernesNocheFin.split(":")[0], f.viernesNocheFin.split(":")[1], 0);

            f.sabadoMannanaInicio = new Date(1950, 1, 1, f.sabadoMannanaInicio.split(":")[0], f.sabadoMannanaInicio.split(":")[1], 0);
            f.sabadoMannanaFin = new Date(1950, 1, 1, f.sabadoMannanaFin.split(":")[0], f.sabadoMannanaFin.split(":")[1], 0);
            f.sabadoTardeInicio = new Date(1950, 1, 1, f.sabadoTardeInicio.split(":")[0], f.sabadoTardeInicio.split(":")[1], 0);
            f.sabadoTardeFin = new Date(1950, 1, 1, f.sabadoTardeFin.split(":")[0], f.sabadoTardeFin.split(":")[1], 0);
            f.sabadoNocheInicio = new Date(1950, 1, 1, f.sabadoNocheInicio.split(":")[0], f.sabadoNocheInicio.split(":")[1], 0);
            f.sabadoNocheFin = new Date(1950, 1, 1, f.sabadoNocheFin.split(":")[0], f.sabadoNocheFin.split(":")[1], 0);

            f.domingoMannanaInicio = new Date(1950, 1, 1, f.domingoMannanaInicio.split(":")[0], f.domingoMannanaInicio.split(":")[1], 0);
            f.domingoMannanaFin = new Date(1950, 1, 1, f.domingoMannanaFin.split(":")[0], f.domingoMannanaFin.split(":")[1], 0);
            f.domingoTardeInicio = new Date(1950, 1, 1, f.domingoTardeInicio.split(":")[0], f.domingoTardeInicio.split(":")[1], 0);
            f.domingoTardeFin = new Date(1950, 1, 1, f.domingoTardeFin.split(":")[0], f.domingoTardeFin.split(":")[1], 0);
            f.domingoNocheInicio = new Date(1950, 1, 1, f.domingoNocheInicio.split(":")[0], f.domingoNocheInicio.split(":")[1], 0);
            f.domingoNocheFin = new Date(1950, 1, 1, f.domingoNocheFin.split(":")[0], f.domingoNocheFin.split(":")[1], 0);
          });

          this.horario = result.data;
          this.selectedMachine = this.maquinas[0];
        });
      })
    }
  }

  eliminarDia(event: any){
    this.horario.forEach(maquina => {
      var keys = Object.keys(maquina);
      var keysOfSelectedDay = keys.filter(x => x.includes(this.selectedItem.value));
      keysOfSelectedDay.forEach(key =>{
        maquina[key] = new Date(2000, 1, 1, 0, 0, 0);
      });
    });
  }

  copiarDias(){
    this.horario.forEach(maquina => {
      var keysMaquina = Object.keys(maquina);
      var keysOfMonday = keysMaquina.filter(x => x.includes('lunes'));
      keysOfMonday.forEach(key =>{
        if(this.martesSelected){
          var auxKey = key.replace('lunes','martes');
          maquina[auxKey] = maquina[key];
        }
        if(this.miercolesSelected){
          var auxKey = key.replace('lunes','miercoles');
          maquina[auxKey] = maquina[key];
        }
        if(this.juevesSelected){
          var auxKey = key.replace('lunes','jueves');
          maquina[auxKey] = maquina[key];
        }
        if(this.viernesSelected){
          var auxKey = key.replace('lunes','viernes');
          maquina[auxKey] = maquina[key];
        }
        if(this.sabadoSelected){
          var auxKey = key.replace('lunes','sabado');
          maquina[auxKey] = maquina[key];
        }
        if(this.domingoSelected){
          var auxKey = key.replace('lunes','domingo');
          maquina[auxKey] = maquina[key];
        }
      });
    });
    this.modalReference.close();
  }

  copiarMaquinas(){
    var maquinaSelected = this.horario.filter(x=> x.maquina == this.selectedMachine.value)[0];
    var keysMaquina = Object.keys(maquinaSelected);
    keysMaquina = keysMaquina.filter(key => key.includes("Mannana") || key.includes("Tarde") || key.includes("Noche"));
    this.horario.forEach((maquina, index) => {
      if (maquinaSelected.maquina !== maquina.maquina){
        if (this.maquinasSeleccionadas[index]){ //si está seleccionada para copiarse en ella los datos de la otra
          keysMaquina.forEach(key => {
            maquina[key]= maquinaSelected[key];
          });
        };
      }
    });
    this.modalReference.close();
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {

    this.disponibilidadService.Insert_Turnos(this.form.value.ano, this.form.value.numSemana, this.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
      {
        if (result.error == false) {
          this.horario.forEach(f => {
            this.disponibilidadService.Insert_Turno_Detalle(result.id, f).subscribe((result) => {
              if (result.error == false) {
                this.router.navigate(['turnosDisponibilidad']);
              } else {
              }
            })
          })
        } else {
        }
      }
    });
  }

  private update() {

    this.horario.forEach(f => {
      this.disponibilidadService.Update_Turno_Detalle(f).subscribe((result) => {
        if (result.error == false) {
          this.router.navigate(['turnosDisponibilidad']);
        } else {
        }
      })
    })
  }

  public atras() {
    this.router.navigate(['turnosDisponibilidad']);
  }

  public abrirPopupDuplicar(popup) {
    this.modalReference = this.modalService.open(popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public abrirPopupCopiarDias() {
    this.modalReference = this.modalService.open(this.popupCopiarDias, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public abrirPopupCopiarMaquina() {
    this.modalReference = this.modalService.open(this.popupCopiarMaquina, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  public duplicarSemana() {
    var duplicado: boolean = true;
    for (let i = 0; i < this.form.value.numDuplicarSemana; i++) {
      var numSemanaDuplicar: number = (Number(this.form.value.numSemana) + 1) + i
      var fechaInicioDuplicar: Date
      var fechaFinDuplicar: Date

      fechaInicioDuplicar = this.getDateOfISOWeek(numSemanaDuplicar, Number(this.form.value.ano));
      fechaFinDuplicar = new Date(fechaInicioDuplicar.getTime() + (1000 * 60 * 60 * 24 * 6));

      duplicado = false;

      this.disponibilidadService.Insert_Turnos(this.getWeekNumber(fechaInicioDuplicar)[0], this.getWeekNumber(fechaInicioDuplicar)[1], this.dateToYYYYMMDDtHHmmSSz(fechaInicioDuplicar), this.dateToYYYYMMDDtHHmmSSz(fechaFinDuplicar)).subscribe((result) => {
       if (result.error == false) {
         this.horario.forEach(f => {
           this.disponibilidadService.Insert_Turno_Detalle(result.id, f).subscribe((result) => {
             if (result.error == false) {
              this.modalReference.close();
               this.router.navigate(['turnosDisponibilidad']);
             } else {
               duplicado = false;
             }
           })
         })
       } else {
         duplicado = false;
       }
      });
    }

    if (duplicado) {
      this.router.navigate(['turnosDisponibilidad']);
    }
  }


  public ano_onValueChange(e) {
    if (this.form.value.ano != "" && this.form.value.numSemana != "") {

      this.fechaInicio = this.getDateOfISOWeek(this.form.value.numSemana, this.form.value.ano);
      this.fechaFin = new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 6));
    }
  }

  public numSemana_onValueChange(e) {
    if (this.form.value.ano != "" && this.form.value.numSemana != "") {

      this.fechaInicio = this.getDateOfISOWeek(this.form.value.numSemana, this.form.value.ano);
      this.fechaFin = new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 6));
    }
  }

  // Semana calc
  public getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}
  //


  /*
  public FirstDateOfWeekISO8601(year, weekOfYear) {
    // obtenemos el primer dia del año
    var primerdia = new Date(year, 0, 1);
    // obtenemos la corrección necesaria
    var correccion = 6 - primerdia.getDay();
    // obtenemos el lunes y domingo de la semana especificada
   return new Date(year, 0, (weekOfYear - 1) * 7 + 3 + correccion);
  }*/

  //dateToYYYYMMDDtHHmmSSz
  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  //addZero
  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  //getWeekNumber
  public getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }
}
