import { Component, ComponentFactoryResolver } from '@angular/core';
import { InformeRendimientoService, MaquinasService, UsuariosService, HmiService, InformeOeeService, MenuService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import * as c3 from 'c3';
import * as d3 from 'd3';
// import * as moment from 'moment';

import { GroupResult, groupBy } from '@progress/kendo-data-query';


interface ItemTurno {
  nombre: string,
  id: number
}
interface ItemTurnoGantt {
  min: Date,
  max: Date,
  clase: string
}

declare var GanttChartClick: any;
@Component({
  selector: 'app-informe-rendimiento',
  templateUrl: './informeRendimiento.component.html'
})

export class InformeRendimientoComponent {
  user = this.userService.userValue;

  public maquinaPreSeleccionada: number;
  public aparecer = false;

  public form: FormGroup;
  public idTurno: number;

  public Jmaquinas: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  public JdatosGANTT: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  public JdatosResumenSemana: any; //se usa esta variable para guardar los datos de las maquinas que nos llegan desde la API
  public selectedMaquina: number = 0;
  public turnosCombo: Array<ItemTurno> = [];
  // public turnosComboSelected: string = ""; // solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
  public comboTurnosSeleccion: any = [];
  public turnos_placeHolder: string = "";
  public fechaInicio: Date;
  public fechaFin: Date;

  public turnos: JSON;
  public turnosArray: Array<ItemTurnoGantt> = [];
  public chartGanttTimeline;
  public visibleTipo: boolean = true;
  public chartDonutResumenSemana;
  public chartDonutResumenSemana_subEstados;
  public dataResumenSemana: any;
  public chartAreaHorasDia;
  public chartAreaHorasDia_subEstados;
  public dataHorasDiaLunes: any;
  public dataHorasDiaMartes: any;
  public dataHorasDiaMiercoles: any;
  public dataHorasDiaJueves: any;
  public dataHorasDiaViernes: any;
  public dataHorasDiaSabado: any;
  public dataHorasDiaDomingo: any;
  public chartDonutOEE;
  public dataChartDonutOEE: any;
  public chartDonutOEEDisponibilidad;
  public dataChartDonutDisponibilidad: any;
  public chartDonutOEERendimiento;
  public dataChartDonutRendimiento: any;
  public chartDonutOEECalidad;
  public dataChartDonutCalidad: any;
  public chartBarHoras;
  private dataGraficoHoras: any;
  public dataOeeOffline: any;
  public chartDonutParadas;
  public dataGraficoDonutParadas = [];
  public dataGraficoDonutParadasParaTooltip: any = {};
  public chartDonutMantenimientos;
  public dataGraficoDonutMantenimientos = [];
  public dataGraficoDonutMantenimientosParaTooltip: any = {};
  public chartDonutAlarmas;
  public dataGraficoDonutAlarmas = [];
  public dataGraficoDonutAlarmasParaTooltip: any = {};
  public dataPerdidasOffline: any;
  public dataPerdidas: any;

  public tieneSubEstados_personalizados = false; //subestados > 100
  public jSubEstados = [];

  public loadingGantt: boolean = false;
  public loadingResumenSemana: boolean = false;
  public loadingResumenSemana_subEstados: boolean = false;
  public loadingHorasDia: boolean = false;
  public loadingHorasDia_subEstados: boolean = false;
  public loadingOeeHoras: boolean = false;
  public loadingPerdidas: boolean = false;

  private tiempoTransition: number = 2000;

  // LOS COLORES AHORA VIENEN DE SUBESTADOS
  // private colorPreparacion: String = "#096844";
  // private colorEjecucion: String = "#C0EADA";
  // private colorParada: String = "#E7CB68";
  // private colorMicroparada: String = "#cf8729";
  // private colorMantenimiento: String = "#99AFCC";
  // private colorAlarma: String = "#D33737";
  // private colorApagada: String = "#424242";
  // private colorSinActividad: String = "#BDBEC3";
  // private colorRendimiento: String = "#FFA8A8";
  // private colorVerdeOscuro: String = "#256A67";
  // private colorVerdeClaro: String = "#44E3C4";
  private colorOEEActivo: String = "#18D6B0";
  private colorOEENoActivo: String = "#3D6063";

  private selectedOperariosGantt: any = [];
  private esOffline: boolean;

  //PANEL MAQUINAS
  //private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  agrupado: number;

  maquinasDesplegadas = false;
  maquinasDesplegadasCount = 0; //para que solo se realice una vez

  public fechaMin: Date;
  public fechaMax: Date;

  // TURNO
  // private turno: number = -1;
  public listaIdTurnos: any;
  public turnoInt: number = -1;


  constructor(private InformeRendimientoService: InformeRendimientoService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private hmiService: HmiService,
    private informeOeeService: InformeOeeService,
    private formBuilder: FormBuilder,
    private menuService: MenuService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private myFunctions: MyFunctions) {

    this.userService.user.subscribe(x => this.user = x);

    //this.maquinaPreSeleccionada = this.route.snapshot.params['idMaquina'];

    this.menuService.titulo = this.translate.instant('analisisRendimiento').toUpperCase();

    this.cargarGrupos();
    // this.cargarAreasProductivas();

    this.cargarTurnosCombo();
    this.cargarFechas();
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      this.cargarAreasProductivas();
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }
  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    //ARATZ (01/09/2021): en esta pagina no siempre se pasa IDmaquina, y si se pasa 0 no funciona el primer click en el grafico.
    //                    La maquina viene cargada en el OnInit()

    if (this.selectedMaquina == 0) {
      this.maquinaPreSeleccionada = this.route.snapshot.params['idMaquina'];
      this.selectedMaquina = this.route.snapshot.params['idMaquina'];
    }

    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2 && this.maquinas.length > 0) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2 && this.instalaciones.length > 0) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }
  }
  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }
    else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }
    else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0 && !(this.selectedMaquina > 0)) this.selectedMaquina = this.maquinasMostradas[0].id;

    if (cargarDatos) {
      this.cargarInforme();
      //this.cargarValidador();
    }
    //La primera vez queremos que solo se muestre la seleccionada, así que hacemos que entre solo una vez en este if
    //if(!this.maquinasDesplegadas && this.maquinasDesplegadasCount == 0){
    //  this.maquinasMostradas = this.maquinas.filter(f =>this.selectedMaquina === f.id);
    //  this.maquinasDesplegadasCount++;
    //}
  }
  cargarInforme() {
    this.cargarInformeRendimiento();
  }

  private cargarTurnosCombo() {
    this.turnos_placeHolder = this.translate.instant("seleccioneTurnos")
    this.turnosCombo = [
      { nombre: this.translate.instant("manana"), id: 1 },
      { nombre: this.translate.instant("tarde"), id: 2 },
      { nombre: this.translate.instant("noche"), id: 3 }
    ];
  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.fechaInicio == undefined) {
      this.fechaInicio = this.myFunctions.getDateNow()
    }
    fechaIni = this.fechaInicio
    if (fechaIni.getDay() == 3) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.fechaInicio);
    this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.fechaInicio.setHours(0, 0, 0);
    this.fechaFin.setHours(0, 0, 0);
  }

  ngOnInit() {
    //TRADUCCIÓN AUTOMATICA
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.cargarTurnosCombo();
    });

    this.agrupado = 1;


    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTurno: ['',]
    });

    //RESUMEN SEMANA
    this.chartDonutResumenSemana = c3.generate({
      bindto: '#chartDonutResumenSemana',
      data: {
        columns: [
          ['sinDatos', 0],
        ],
        names: {
          sinDatos: this.translate.instant("sinDatos"),
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    //HORAS/DÍA
    this.chartAreaHorasDia = c3.generate({
      bindto: '#chartAreaHorasDia',
      data: {
        x: 'x',
        columns: [
          ['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")],
          ['sinDatos', 0, 0, 0, 0, 0, 0, 0],
        ],
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [['sinDatos']],
        names: {
          sinDatos: this.translate.instant("sinDatos"),
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });

    //RESUMEN SUBESTADOS SEMANA: este grafico se RECARGA por completo mas adelante, cuando ya se tengan los subestados y sus colores. Pero se carga para una primera visualizacion.
    this.chartDonutResumenSemana_subEstados = c3.generate({
      bindto: '#chartDonutResumenSemana_subEstados',
      data: {
        columns: [
          ['sinDatos', 0],
        ],

        names: {
          sinDatos: this.translate.instant("sinDatos"),
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_subEstados .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    //SUBESTADOS HORAS/DÍA: este grafico se RECARGA por completo mas adelante, cuando ya se tengan los subestados y sus colores. Pero se carga para una primera visualizacion.
    this.chartAreaHorasDia_subEstados = c3.generate({
      bindto: '#chartAreaHorasDia_subEstados',
      data: {
        x: 'x',
        columns: [
          ['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")],
          ['sinDatos', 0, 0, 0, 0, 0, 0, 0],
        ],
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [['sinDatos']],
        names: {
          sinDatos: this.translate.instant("sinDatos"),
        }
      },
      color: {
        pattern: ['#C2C5CC']
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });

    //OEE
    this.dataChartDonutOEE = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEE = c3.generate({
      bindto: '#chartDonutOEE_InformeRendimiento',
      data: {
        columns: this.dataChartDonutOEE,
        type: 'donut',
        names: {
          oee: this.translate.instant("oee"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });

    //DISPONIBILIDAD
    this.dataChartDonutDisponibilidad = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEEDisponibilidad = c3.generate({
      bindto: '#chartDonutOEEDisponibilidad_InformeRendimiento',
      data: {
        columns: this.dataChartDonutDisponibilidad,
        type: 'donut',
        names: {
          disponibilidad: this.translate.instant("disponibilidad"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });

    //RENDIMIENTO
    this.dataChartDonutRendimiento = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEERendimiento = c3.generate({
      bindto: '#chartDonutOEERendimiento_InformeRendimiento',
      data: {
        columns: this.dataChartDonutRendimiento,
        type: 'donut',
        names: {
          rendimiento: this.translate.instant("rendimiento"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });

    //CALIDAD
    this.dataChartDonutCalidad = [['completo', 0], ['nocompleto', 100]];
    this.chartDonutOEECalidad = c3.generate({
      bindto: '#chartDonutOEECalidad_InformeRendimiento',
      data: {
        columns: this.dataChartDonutCalidad,
        type: 'donut',
        names: {
          calidad: this.translate.instant("calidad"),
        },
        order: 'null'
      },
      donut: {
        title: "0",
        width: 15,
        label: { show: false }
      },
      color: {
        pattern: [this.colorOEEActivo, this.colorOEENoActivo],
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      },
      transition: {
        duration: this.tiempoTransition
      }
    });

    //HORAS
    this.dataGraficoHoras = [[this.translate.instant('total'), 0, 0, 0, 0],
    [this.translate.instant('rendimiento'), 0, 0, 0, 0],
    [this.translate.instant('paradas'), 0, 0, 0, 0],
    [this.translate.instant('mantenimientos'), 0, 0, 0, 0],
    [this.translate.instant('alarmas'), 0, 0, 0, 0],
    [this.translate.instant('apagadas'), 0, 0, 0, 0],
    [this.translate.instant('perdidasCalidad'), 0, 0, 0, 0]];

    //GRAFICO HORAS BARRAS
    this.chartBarHoras = c3.generate({
      data: {
        columns: this.dataGraficoHoras,
        order: null,
        type: 'bar',
        groups: [[this.translate.instant('total'), this.translate.instant('paradas'), this.translate.instant('mantenimientos'),
        this.translate.instant('alarmas'), this.translate.instant('apagadas'), this.translate.instant('rendimiento'), this.translate.instant('perdidasCalidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#f99f4d'], ['#ebe078'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ffa8a8']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translate.instant('tiempoTotal'), this.translate.instant('disponibilidad'),
          this.translate.instant('rendimiento'), this.translate.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: 2000
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      onrendered: function () {
        d3.selectAll("#graficoHoras_Disp .c3-bar")
          .style("opacity", function (d) {
            if (d.index === 1) {
              return 1;
            } else {
              return 0.4
            }
          });
      },
      bindto: '#chartBarHoras_InformeRendimiento'
    });
    var that = this;
    this.chartDonutParadas = c3.generate({
      data: {
        columns: this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutParadas .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutParadas .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutParadas .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      tooltip: {
        show: true,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if (that.dataGraficoDonutParadasParaTooltip[d[0].id] != undefined)
            d[0].name = that.dataGraficoDonutParadasParaTooltip[d[0].id];
          return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
        }
      },
      bindto: '#chartDonutParadas'
    });


    //MANTENIMIENTOS
    this.chartDonutMantenimientos = c3.generate({
      data: {
        columns: this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutMantenimientos .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutMantenimientos .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutMantenimientos .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      tooltip: {
        show: true,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if (that.dataGraficoDonutMantenimientosParaTooltip[d[0].id] != undefined)
            d[0].name = that.dataGraficoDonutMantenimientosParaTooltip[d[0].id];
          return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
        }
      },
      bindto: '#chartDonutMantenimientos'
    });


    this.chartDonutAlarmas = c3.generate({
      data: {
        columns: this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutAlarmas .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutAlarmas .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);

        },
        onmouseout: function (d) {
          d3.select('#chartDonutAlarmas .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: 2000
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      tooltip: {
        show: true,
        contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
          if (that.dataGraficoDonutAlarmasParaTooltip[d[0].id] != undefined)
            d[0].name = that.dataGraficoDonutAlarmasParaTooltip[d[0].id];
          return this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
        }
      },
      bindto: '#chartDonutAlarmas'
    });

    d3.selectAll("#chartDonutResumenSemana .c3-chart-arcs path").style("stroke-width", "0px");

    d3.selectAll("#chartDonutOEE_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#chartDonutOEEDisponibilidad_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#chartDonutOEERendimiento_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#chartDonutOEECalidad_InformeRendimiento .c3-chart-arcs path").style("stroke-width", "0px");

    d3.selectAll("#chartDonutParadas .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#chartDonutMantenimientos .c3-chart-arcs path").style("stroke-width", "0px");
    d3.selectAll("#chartDonutAlarmas .c3-chart-arcs path").style("stroke-width", "0px");
  }
  private cargarInformeRendimiento() {

    this.loadingGantt = true;
    this.loadingResumenSemana = true;
    this.loadingResumenSemana_subEstados = true;
    this.loadingHorasDia = true;
    this.loadingHorasDia_subEstados = true;
    this.loadingOeeHoras = true;
    this.loadingPerdidas = true;

    this.get_Graficos_conSubestados();

    var listaIdsTurnos = (this.comboTurnosSeleccion === undefined) ? [] : this.comboTurnosSeleccion.map(a => a.id);

    this.turnoInt = -1;
    var lag: string = "";
    if (listaIdsTurnos.length == 0) {
      this.turnoInt = -1;
    } else {
      listaIdsTurnos.forEach(turno => {
        lag = lag + turno.toString();
      });
      this.turnoInt = +lag;
    }
    this.listaIdTurnos = listaIdsTurnos;
    this.informeOeeService.Get_Oee_Of_Cliente_Pieza(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), listaIdsTurnos.join(","), "-").subscribe(json => {

      var data: any = json;

      var tEjecucion = 0;
      var tParadaDisponibilidad = 0;
      var tMantenimientoDisponibilidad = 0;
      var tAlarmaDisponibilidad = 0;
      var tApagadaDisponibilidad = 0;
      var tParadaRendimiento = 0;
      var tMantenimientoRendimiento = 0;
      var tAlarmaRendimiento = 0;
      var tApagadaRendimiento = 0;
      var tParadaCalidad = 0;
      var tMantenimientoCalidad = 0;
      var tAlarmaCalidad = 0;
      var tApagadaCalidad = 0;
      var tNegativoCalidad = 0;
      var tEstimado = 0;

      data.forEach(function (a) {
        tEjecucion += a.tEjecucion;
        tParadaDisponibilidad += a.tParadaDisponibilidad;
        tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
        tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
        tApagadaDisponibilidad += a.tApagadaDisponibilidad;
        tParadaRendimiento += a.tParadaRendimiento;
        tMantenimientoRendimiento += a.tMantenimientoRendimiento;
        tAlarmaRendimiento += a.tAlarmaRendimiento;
        tApagadaRendimiento += a.tApagadaRendimiento;
        tParadaCalidad += a.tParadaCalidad;
        tMantenimientoCalidad += a.tMantenimientoCalidad;
        tAlarmaCalidad += a.tAlarmaCalidad;
        tApagadaCalidad += a.tApagadaCalidad;
        tNegativoCalidad += a.tNegativoCalidad;
        tEstimado += a.tEstimado;
      });

      //CALCULAR DONUTS
      var oee: number = 0;
      var cal: number = 0;
      var ren: number = 0;
      var dis: number = 0;

      var perdidasDisponibilidad = tParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
      var perdidasRendimiento = tParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;

      var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

      var disDividendo: number = tEjecucion + perdidasRendimiento;
      var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      var renDividendo: number = tEstimado;
      var renDivisor: number = tEjecucion + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      var calDividendo: number = tEstimado - tNegativoCalidad - perdidasCalidad;
      var calDivisor: number = tEstimado;
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);

      d3.select('#chartDonutOEE_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(oee) + "%");
      d3.select('#chartDonutOEEDisponibilidad_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(dis) + "%");
      d3.select('#chartDonutOEERendimiento_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(ren) + "%");
      d3.select('#chartDonutOEECalidad_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(cal) + "%");

      if (oee > 100) oee = 100;
      if (dis > 100) dis = 100;
      if (ren > 100) ren = 100;
      if (cal > 100) cal = 100;

      var dataGraficoDonutOEE = [['completo', oee], ['nocompleto', 100 - oee]];
      var dataGraficoDonutDisponibilidad = [['completo', dis], ['nocompleto', 100 - dis]];
      var dataGraficoDonutRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
      var dataGraficoDonutCalidad = [['completo', cal], ['nocompleto', 100 - cal]];

      this.chartDonutOEE.load({ columns: dataGraficoDonutOEE });
      this.chartDonutOEEDisponibilidad.load({ columns: dataGraficoDonutDisponibilidad });
      this.chartDonutOEERendimiento.load({ columns: dataGraficoDonutRendimiento });
      this.chartDonutOEECalidad.load({ columns: dataGraficoDonutCalidad });

      //CALCULAR BARRAS
      var tRendimiento: number = 0;
      if (tEstimado > tEjecucion) {
        tRendimiento = 0;
        tEstimado = tEjecucion;
      } else {
        tRendimiento = tEjecucion - tEstimado;
      }

      this.dataGraficoHoras = [[this.translate.instant('total'), tRendimiento + tEstimado + perdidasRendimiento + perdidasDisponibilidad, tRendimiento + tEstimado + perdidasRendimiento, tEstimado, tEstimado - tNegativoCalidad - perdidasCalidad],
      [this.translate.instant('rendimiento'), 0, 0, tRendimiento, 0],
      [this.translate.instant('paradas'), 0, tParadaDisponibilidad, tParadaRendimiento, tParadaCalidad],
      [this.translate.instant('mantenimientos'), 0, tMantenimientoDisponibilidad, tMantenimientoRendimiento, tMantenimientoCalidad],
      [this.translate.instant('alarmas'), 0, tAlarmaDisponibilidad, tAlarmaRendimiento, tAlarmaCalidad],
      [this.translate.instant('apagadas'), 0, tApagadaDisponibilidad, tApagadaRendimiento, tApagadaCalidad],
      [this.translate.instant('perdidasCalidad'), 0, 0, 0, tNegativoCalidad]];

      this.chartBarHoras.load({ columns: this.dataGraficoHoras });

      this.loadingOeeHoras = false;

    });

    // GRAFICOS ALARMAS Y MANTENIMIENTOS
    this.informeOeeService.Get_Alarmas_Mantenimientos(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), listaIdsTurnos.join(",")).subscribe(
      data => {
        var dataAlarmasMantenimientos: any = data;
        var donutDataMantenimientos: any = [];
        var donutDataAlarmas: any = [];

        document.getElementById('lblNoAlarmas_InfRend').style.display = 'block';
        document.getElementById('chartDonutAlarmas').style.display = 'none';
        document.getElementById('lblNoMantenimientos_InfRend').style.display = 'block';
        document.getElementById('chartDonutMantenimientos').style.display = 'none';

        dataAlarmasMantenimientos.forEach(function (obj) {


          //MANTENIMIENTOS (idProcesos_Tipo = 4, 10)
          if ((obj.idProcesos_Tipo == 4 || obj.idProcesos_Tipo == 10) && obj.tiempoPerdida > 0) {
            this.dataGraficoDonutMantenimientosParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataMantenimientos.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoMantenimientos_InfRend').style.display = 'none';
            document.getElementById('chartDonutMantenimientos').style.display = 'block';
          }

          //ALARMAS (idProcesos_Tipo = 6, 7)
          if ((obj.idProcesos_Tipo == 6 || obj.idProcesos_Tipo == 7) /*&& obj.tiempoPerdida > 0*/) {
            this.dataGraficoDonutAlarmasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataAlarmas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoAlarmas_InfRend').style.display = 'none';
            document.getElementById('chartDonutAlarmas').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutMantenimientos = this.dataGraficoDonutMantenimientos;
        this.dataGraficoDonutMantenimientos = donutDataMantenimientos.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutMantenimientos = this.acortarDatosGraficoDonuts(this.dataGraficoDonutMantenimientos);

        var oldDataGraficoDonutAlarmas = this.dataGraficoDonutAlarmas;
        this.dataGraficoDonutAlarmas = donutDataAlarmas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutAlarmas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutAlarmas);


        this.updateDonutLeyenda(this.chartDonutMantenimientos, oldDataGraficoDonutMantenimientos, this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }))
        this.updateDonutLeyenda(this.chartDonutAlarmas, oldDataGraficoDonutAlarmas, this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }))

        d3.selectAll("#chartDonutMantenimientos .c3-chart-arcs path").style("stroke-width", "0px");
        d3.selectAll("#chartDonutAlarmas .c3-chart-arcs path").style("stroke-width", "0px");


      }
    );

    // GRAFICOS PERDIDAS
    this.informeOeeService.Get_Perdidas_Todas(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), this.listaIdTurnos.join(",")).subscribe(data => {
      this.dataPerdidas = data;
      this.cargarDatosPerdidas();
    });
  }

  public turnos_onValueChange(value: any): void {
    // this.turnosComboSelected = ""; // solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato

    // for (let turno of value) {// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    //   this.turnosComboSelected += turno.id + ", ";// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    // }// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato

    // this.turnosComboSelected = this.turnosComboSelected.substring(0, this.turnosComboSelected.length - 2);// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato

    // if (this.turnosComboSelected == "") {// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    //   this.turnosComboSelected = "";// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    // }// solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    this.filtrarInformeRendimiento();
  }

  public fechaInicio_dateChanged(value: Date): void {
    this.cargarFechas();
    this.cargarInformeRendimiento();
  }

  public fechaFin_dateChanged(value: Date): void {
    this.cargarInformeRendimiento();
  }

  filtrarInformeRendimiento() {
    // esta funcion no deveria de ser igual que la carga, una vez se tienen los datos deveriamos preparar el informe para filtrar sin tener que volver a cargar datos ( si no sa cambia de maquina )
    this.loadingGantt = true;
    this.loadingResumenSemana = true;
    this.loadingResumenSemana_subEstados = true;
    this.loadingHorasDia = true;
    this.loadingHorasDia_subEstados = true;
    this.loadingOeeHoras = true;
    this.loadingPerdidas = true;

    var listaIdsTurnos = (this.comboTurnosSeleccion === undefined) ? [] : this.comboTurnosSeleccion.map(a => a.id);

    this.turnoInt = -1;
    var lag: string = "";
    if (listaIdsTurnos.length == 0) {
      this.turnoInt = -1;
    } else {
      listaIdsTurnos.forEach(turno => {
        lag = lag + turno.toString();
      });
      this.turnoInt = +lag;
    }
    this.listaIdTurnos = listaIdsTurnos;

    this.mostrarDatos_ChartGanttTimeline_conSubestados();
    this.mostrarGraficosResumenSubEstados();

    this.informeOeeService.Get_Oee_Of_Cliente_Pieza(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), listaIdsTurnos.join(","), "-").subscribe(json => {

      var data: any = json;

      var tEjecucion = 0;
      var tParadaDisponibilidad = 0;
      var tMantenimientoDisponibilidad = 0;
      var tAlarmaDisponibilidad = 0;
      var tApagadaDisponibilidad = 0;
      var tParadaRendimiento = 0;
      var tMantenimientoRendimiento = 0;
      var tAlarmaRendimiento = 0;
      var tApagadaRendimiento = 0;
      var tParadaCalidad = 0;
      var tMantenimientoCalidad = 0;
      var tAlarmaCalidad = 0;
      var tApagadaCalidad = 0;
      var tNegativoCalidad = 0;
      var tEstimado = 0;

      data.forEach(function (a) {
        tEjecucion += a.tEjecucion;
        tParadaDisponibilidad += a.tParadaDisponibilidad;
        tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
        tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
        tApagadaDisponibilidad += a.tApagadaDisponibilidad;
        tParadaRendimiento += a.tParadaRendimiento;
        tMantenimientoRendimiento += a.tMantenimientoRendimiento;
        tAlarmaRendimiento += a.tAlarmaRendimiento;
        tApagadaRendimiento += a.tApagadaRendimiento;
        tParadaCalidad += a.tParadaCalidad;
        tMantenimientoCalidad += a.tMantenimientoCalidad;
        tAlarmaCalidad += a.tAlarmaCalidad;
        tApagadaCalidad += a.tApagadaCalidad;
        tNegativoCalidad += a.tNegativoCalidad;
        tEstimado += a.tEstimado;
      });

      //CALCULAR DONUTS
      var oee: number = 0;
      var cal: number = 0;
      var ren: number = 0;
      var dis: number = 0;

      var perdidasDisponibilidad = tParadaDisponibilidad + tMantenimientoDisponibilidad + tAlarmaDisponibilidad + tApagadaDisponibilidad;
      var perdidasRendimiento = tParadaRendimiento + tMantenimientoRendimiento + tAlarmaRendimiento + tApagadaRendimiento;

      var perdidasCalidad = tParadaCalidad + tMantenimientoCalidad + tAlarmaCalidad + tApagadaCalidad;

      var disDividendo: number = tEjecucion + perdidasRendimiento;
      var disDivisor: number = tEjecucion + perdidasRendimiento + perdidasDisponibilidad;
      if (disDivisor == 0) dis = 0;
      else dis = (disDividendo / disDivisor) * 100;

      var renDividendo: number = tEstimado;
      var renDivisor: number = tEjecucion + perdidasRendimiento;
      if (renDivisor == 0) ren = 0;
      else ren = (renDividendo / renDivisor) * 100;

      var calDividendo: number = tEstimado - tNegativoCalidad - perdidasCalidad;
      var calDivisor: number = tEstimado;
      if (calDivisor == 0) cal = 0;
      else cal = (calDividendo / calDivisor) * 100;

      oee = cal * ren * dis / (10000);

      d3.select('#chartDonutOEE_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(oee) + "%");
      d3.select('#chartDonutOEEDisponibilidad_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(dis) + "%");
      d3.select('#chartDonutOEERendimiento_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(ren) + "%");
      d3.select('#chartDonutOEECalidad_InformeRendimiento .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
        .text(Math.round(cal) + "%");

      if (oee > 100) oee = 100;
      if (dis > 100) dis = 100;
      if (ren > 100) ren = 100;
      if (cal > 100) cal = 100;

      var dataGraficoDonutOEE = [['completo', oee], ['nocompleto', 100 - oee]];
      var dataGraficoDonutDisponibilidad = [['completo', dis], ['nocompleto', 100 - dis]];
      var dataGraficoDonutRendimiento = [['completo', ren], ['nocompleto', 100 - ren]];
      var dataGraficoDonutCalidad = [['completo', cal], ['nocompleto', 100 - cal]];

      this.chartDonutOEE.load({ columns: dataGraficoDonutOEE });
      this.chartDonutOEEDisponibilidad.load({ columns: dataGraficoDonutDisponibilidad });
      this.chartDonutOEERendimiento.load({ columns: dataGraficoDonutRendimiento });
      this.chartDonutOEECalidad.load({ columns: dataGraficoDonutCalidad });

      //CALCULAR BARRAS
      var tRendimiento: number = 0;
      if (tEstimado > tEjecucion) {
        tRendimiento = 0;
        tEstimado = tEjecucion;
      } else {
        tRendimiento = tEjecucion - tEstimado;
      }

      this.dataGraficoHoras = [[this.translate.instant('total'), tRendimiento + tEstimado + perdidasRendimiento + perdidasDisponibilidad, tRendimiento + tEstimado + perdidasRendimiento, tEstimado, tEstimado - tNegativoCalidad - perdidasCalidad],
      [this.translate.instant('rendimiento'), 0, 0, tRendimiento, 0],
      [this.translate.instant('paradas'), 0, tParadaDisponibilidad, tParadaRendimiento, tParadaCalidad],
      [this.translate.instant('mantenimientos'), 0, tMantenimientoDisponibilidad, tMantenimientoRendimiento, tMantenimientoCalidad],
      [this.translate.instant('alarmas'), 0, tAlarmaDisponibilidad, tAlarmaRendimiento, tAlarmaCalidad],
      [this.translate.instant('apagadas'), 0, tApagadaDisponibilidad, tApagadaRendimiento, tApagadaCalidad],
      [this.translate.instant('perdidasCalidad'), 0, 0, 0, tNegativoCalidad]];

      this.chartBarHoras.load({ columns: this.dataGraficoHoras });

      this.loadingOeeHoras = false;

    });

    // GRAFICOS ALARMAS Y MANTENIMIENTOS
    this.informeOeeService.Get_Alarmas_Mantenimientos(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), listaIdsTurnos.join(",")).subscribe(
      data => {
        var dataAlarmasMantenimientos: any = data;
        var donutDataMantenimientos: any = [];
        var donutDataAlarmas: any = [];

        document.getElementById('lblNoAlarmas_InfRend').style.display = 'block';
        document.getElementById('chartDonutAlarmas').style.display = 'none';
        document.getElementById('lblNoMantenimientos_InfRend').style.display = 'block';
        document.getElementById('chartDonutMantenimientos').style.display = 'none';

        dataAlarmasMantenimientos.forEach(function (obj) {

          //MANTENIMIENTOS (idProcesos_Tipo = 4, 10)
          if ((obj.idProcesos_Tipo == 4 || obj.idProcesos_Tipo == 10) && obj.tiempoPerdida > 0) {
            this.dataGraficoDonutMantenimientosParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataMantenimientos.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoMantenimientos_InfRend').style.display = 'none';
            document.getElementById('chartDonutMantenimientos').style.display = 'block';
          }

          //ALARMAS (idProcesos_Tipo = 6, 7)
          if ((obj.idProcesos_Tipo == 6 || obj.idProcesos_Tipo == 7) && obj.tiempoPerdida > 0) {
            this.dataGraficoDonutAlarmasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataAlarmas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
            document.getElementById('lblNoAlarmas_InfRend').style.display = 'none';
            document.getElementById('chartDonutAlarmas').style.display = 'block';
          }

        }, this);

        var oldDataGraficoDonutMantenimientos = this.dataGraficoDonutMantenimientos;
        this.dataGraficoDonutMantenimientos = donutDataMantenimientos.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutMantenimientos = this.acortarDatosGraficoDonuts(this.dataGraficoDonutMantenimientos);

        var oldDataGraficoDonutAlarmas = this.dataGraficoDonutAlarmas;
        this.dataGraficoDonutAlarmas = donutDataAlarmas.sort(function (a, b) { return b[1] - a[1]; });
        this.dataGraficoDonutAlarmas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutAlarmas);


        this.updateDonutLeyenda(this.chartDonutMantenimientos, oldDataGraficoDonutMantenimientos, this.dataGraficoDonutMantenimientos.sort(function (a, b) { return b[1] - a[1]; }))
        this.updateDonutLeyenda(this.chartDonutAlarmas, oldDataGraficoDonutAlarmas, this.dataGraficoDonutAlarmas.sort(function (a, b) { return b[1] - a[1]; }))

        d3.selectAll("#chartDonutMantenimientos .c3-chart-arcs path").style("stroke-width", "0px");
        d3.selectAll("#chartDonutAlarmas .c3-chart-arcs path").style("stroke-width", "0px");


      }
    );

    // GRAFICOS PERDIDAS
    this.informeOeeService.Get_Perdidas_Todas(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), this.listaIdTurnos.join(",")).subscribe(data => {
      this.dataPerdidas = data;
      this.cargarDatosPerdidas();
    });
  }

  cargarDatosPerdidas() {
    if (this.agrupado == 2) { //si las pérdidas están agrupadas por grupos tomar los datos agrupados
      // this.informeOeeService.Get_Perdidas_PorGrupo(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), this.listaIdTurnos.join(",")).subscribe(data => {
      document.getElementById('lblNoParadas_InfRend').style.display = 'block';
      document.getElementById('checkboxGrupos').style.display = 'none';
      document.getElementById('chartDonutParadas').style.display = 'none';

      var dataPerdidas: any = this.dataPerdidas.filter(function (f) {
        if (/*f.idProcesos_Tipo == 2 &&*/ f.idSubperdida == -1 && f.idPerdida == -1 && f.idGrupoPerdida == -1) {
          return true;
        } else if (f.idGrupoPerdida != -1) {
          f["nombrePerdida"] = f["grupoPerdida"];
          return true;
        } else {
          return false;
        }
      });

      var donutDataParadas: any = [];

      dataPerdidas.forEach(function (obj) {
        if (obj.tiempoPerdida > 0) {
          this.dataGraficoDonutParadasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
          donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoParadas_InfRend').style.display = 'none';
          document.getElementById('checkboxGrupos').style.display = 'block';
          document.getElementById('chartDonutParadas').style.display = 'block';
        }
      }, this);

      var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
      this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

      this.updateDonutLeyenda(this.chartDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));

      d3.selectAll("#chartDonutParadas .c3-chart-arcs path").style("stroke-width", "0px");
      this.loadingPerdidas = false;
      // });
    }

    if (this.agrupado == 1) {
      // this.informeOeeService.Get_Perdidas_PorSubPerdida(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), this.listaIdTurnos.join(",")).subscribe(data => {
      document.getElementById('lblNoParadas_InfRend').style.display = 'block';
      document.getElementById('checkboxGrupos').style.display = 'none';
      document.getElementById('chartDonutParadas').style.display = 'none';

      var dataPerdidas: any = this.dataPerdidas.filter(function (f) {
        /*if (f.idProcesos_Tipo == 2 && f.idSubperdida == -1 && f.idPerdida == -1 && f.idGrupoPerdida == -1) {
          return true;
        } else*/ if (f.idPerdida != -1) {
          f["nombrePerdida"] = f["perdida"];
          return true;
        } else {
          return false;
        }
      });

      var perdidasSinAsginar = this.dataPerdidas.filter(function (f) {
        if (f.idSubperdida == -1 && f.idPerdida == -1 && f.idGrupoPerdida == -1 /*&& f.idProcesos_Tipo == 2*/) {
          return true;
        }
      });

      if (perdidasSinAsginar.length > 0){
        var rowSinPerdida =
        {
          idProcesos_Tipo: 2,
          nombrePerdida: this.translate.instant('sinAsignar'),
          tiempoPerdida: perdidasSinAsginar.map(f => f.tiempoPerdida).reduce((a, b) => { return a + b }),
          idSubperdida: -1,
          subPerdida: '',
          idPerdida: -1,
          perdida: '',
          idGrupoPerdida: -1,
          grupoPerdida: ''
        }
      }
      
      dataPerdidas.push(rowSinPerdida);

      var donutDataParadas: any = {};

      dataPerdidas.forEach(function (obj) {
        if (obj.tiempoPerdida > 0) {
          if (!donutDataParadas[obj.nombrePerdida]) {
            this.dataGraficoDonutParadasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
            donutDataParadas[obj.nombrePerdida] = [this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida];
            document.getElementById('lblNoParadas_InfRend').style.display = 'none';
            document.getElementById('checkboxGrupos').style.display = 'block';
            document.getElementById('chartDonutParadas').style.display = 'block';
          } else {
            donutDataParadas[obj.nombrePerdida][1] += obj.tiempoPerdida;
          }
        }

      }, this);
      donutDataParadas = Object.values(donutDataParadas);

      var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
      this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

      this.updateDonutLeyenda(this.chartDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));

      d3.selectAll("#chartDonutParadas .c3-chart-arcs path").style("stroke-width", "0px");
      this.loadingPerdidas = false;
      // });
    }

    //GRAFICOS PERDIDAS
    if (this.agrupado == 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
      // this.informeOeeService.Get_Perdidas_Todas(this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), this.selectedMaquina.toString(), this.translate.instant("sinAsignar"), this.listaIdTurnos.join(",")).subscribe(data => {

      document.getElementById('lblNoParadas_InfRend').style.display = 'block';
      document.getElementById('checkboxGrupos').style.display = 'none';
      document.getElementById('chartDonutParadas').style.display = 'none';

      var donutDataParadas: any = [];

      var dataPerdidas: any = this.dataPerdidas.filter(function (f) {
        if (/*f.idProcesos_Tipo == 2 && */f.idSubperdida == -1 && f.idPerdida == -1 && f.idGrupoPerdida == -1) {
          return true;
        } else if (f.idSubperdida != -1) {
          f["nombrePerdida"] = f["subPerdida"];
          return true;
        } else {
          return false;
        }
      });;

      dataPerdidas.forEach(function (obj) {
        //PARADAS (idProcesos_Tipo = 1, 2)
        if ((obj.idProcesos_Tipo == 1 || obj.idProcesos_Tipo == 2) && obj.tiempoPerdida > 0) { //si no están agrupadas las pérdidas tomar los datos de las pérdidas sin agrupar
          this.dataGraficoDonutParadasParaTooltip[this.cortarLeyenda(obj.nombrePerdida)] = obj.nombrePerdida;
          donutDataParadas.push([this.cortarLeyenda(obj.nombrePerdida), obj.tiempoPerdida]);
          document.getElementById('lblNoParadas_InfRend').style.display = 'none';
          document.getElementById('checkboxGrupos').style.display = 'block';
          document.getElementById('chartDonutParadas').style.display = 'block';
        }


      }, this);

      var oldDataGraficoDonutParadas = this.dataGraficoDonutParadas;
      this.dataGraficoDonutParadas = donutDataParadas.sort(function (a, b) { return b[1] - a[1]; });
      this.dataGraficoDonutParadas = this.acortarDatosGraficoDonuts(this.dataGraficoDonutParadas);

      this.updateDonutLeyenda(this.chartDonutParadas, oldDataGraficoDonutParadas, this.dataGraficoDonutParadas.sort(function (a, b) { return b[1] - a[1]; }));

      d3.selectAll("#chartDonutParadas .c3-chart-arcs path").style("stroke-width", "0px");

      this.loadingPerdidas = false;

      // });
    }
  }

  public checkboxChange(tipo): void { //función para saber si las pérdidas están agrupadas por grupos
    this.agrupado = tipo;
    this.cargarDatosPerdidas();
  }

  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnseñar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnseñar.push(['Otros', totalOtros]);
      return arrayParaEnseñar;
    }
  }

  public updateDonutLeyenda(grafico, oldData, newData) {

    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }

    var names = {};
    newData.forEach(function (a) {
      names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
    }, this);

    grafico.load({
      columns: newData,
      names: names
    });
    grafico.unload({
      ids: nombresAEliminar
    });
  }

  public cortarLeyenda(inputText, trimmed = false) {

    var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var width = context.measureText(inputText).width;

    if (width > 160) {
      return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
    } else if (trimmed) {
      return inputText + "...";
    } else {
      return inputText;
    }

  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  // MAQUINAS SUBESTADOS   
  get_Graficos_conSubestados() {
    this.maquinasService.Get_subestados_activos(this.selectedMaquina).subscribe(
      (j) => {
        this.jSubEstados = j;

        this.cargarGraficosResumenSemanaSinDatos();

        this.get_Gantt(); //CARGAR GANT VA DENTRO DE LOS TURNOS
        this.get_GraficosResumenSubEstados() //CARGAR GANT VA DENTRO DE LOS TURNOS
      });
  }
  // CARGA LOS GRAFICOS VACIOS PARA DESPUES AÑADIR LOS DATOS 
  cargarGraficosResumenSemanaSinDatos() {
    // Sub Estados
    var columnasVacias = []; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos. 
    var nombresVacios = {}; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos.
    var colores = [];
    // El grafico de lineas mostrara siempre 7 dias en este caso, de lunes a domingo de la misma semana, en caso de necesitar mas dias, aqui se deverian introducir las fechas de los dias a mostrar.
    var columnasVacias_Diario = [['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")]];
    var grupos = []

    // Sub Estados: AGRUPADOS
    var columnasVacias_agrupados = []; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos. 
    var nombresVacios_agrupados = {}; //Para que la primera carga sea mas elegante y que no aparezca de 0, se hace una carga a 0 y luego se le actualizan los datos.
    var colores_agrupados = [];
    // El grafico de lineas mostrara siempre 7 dias en este caso, de lunes a domingo de la misma semana, en caso de necesitar mas dias, aqui se deverian introducir las fechas de los dias a mostrar.
    var columnasVacias_Diario_agrupados = [['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")]];
    var grupos_agrupados = []
    // Si todos los estados son padres, no es necesario mostrar la segunda linea de graficos
    this.tieneSubEstados_personalizados = false
    this.jSubEstados.forEach(
      subestado => {
        // SubEstado
        subestado.tiempoLunes = 0;
        subestado.tiempoMartes = 0;
        subestado.tiempoMiercoles = 0;
        subestado.tiempoJueves = 0;
        subestado.tiempoViernes = 0;
        subestado.tiempoSabado = 0;
        subestado.tiempoDomingo = 0;
        // SubEstado: agrupado
        subestado.tiempoLunes_agrupados = 0;
        subestado.tiempoMartes_agrupados = 0;
        subestado.tiempoMiercoles_agrupados = 0;
        subestado.tiempoJueves_agrupados = 0;
        subestado.tiempoViernes_agrupados = 0;
        subestado.tiempoSabado_agrupados = 0;
        subestado.tiempoDomingo_agrupados = 0;

        try {
          subestado.nombre = this.translate.instant(subestado.nombre);
        } catch {
          subestado.nombre = subestado.nombre;
        }

        if (subestado.idProcesos_Tipo_subEstados > 99) {
          // Sub Estados
          // Variables para cargar el grafico vacio (DONUT)
          columnasVacias.push([subestado.nombre, 0]);
          nombresVacios[subestado.nombre] = subestado.nombre;
          colores.push(subestado.color);
          // Variables para cargar el grafico vacio (LINEAS POR DIAS), si el grafico mostrara mas de 7 dias (lunes-domingo), el numero de "",0" se deveria aumentar al mismo numero de dias que se muestre.
          columnasVacias_Diario.push([subestado.nombre, 0, 0, 0, 0, 0, 0, 0]);
          grupos.push(subestado.nombre);
          this.tieneSubEstados_personalizados = true;
        } else {
          // Sub Estados: AGRUPADOS EN PROCESOS PRINCIPALES! si la maquina los tiene asignados
          // Variables para cargar el grafico vacio (DONUT)
          columnasVacias_agrupados.push([subestado.nombre, 0]);
          nombresVacios_agrupados[subestado.nombre] = subestado.nombre;
          colores_agrupados.push(subestado.color);
          // Variables para cargar el grafico vacio (LINEAS POR DIAS), si el grafico mostrara mas de 7 dias (lunes-domingo), el numero de "",0" se deveria aumentar al mismo numero de dias que se muestre.
          columnasVacias_Diario_agrupados.push([subestado.nombre, 0, 0, 0, 0, 0, 0, 0]);
          grupos_agrupados.push(subestado.nombre);
        }
      });
    //RESUMEN SEMANA
    this.chartDonutResumenSemana = c3.generate({
      bindto: '#chartDonutResumenSemana',
      data: {
        columns: this.myFunctions.copy(columnasVacias_agrupados),
        names: this.myFunctions.copy(nombresVacios_agrupados),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: this.myFunctions.copy(colores_agrupados)
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });
    //HORAS/DÍA
    this.chartAreaHorasDia = c3.generate({
      bindto: '#chartAreaHorasDia',
      data: {
        x: 'x',
        columns: this.myFunctions.copy(columnasVacias_Diario_agrupados)
        ,
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [grupos_agrupados],
        names: this.myFunctions.copy(nombresVacios_agrupados)
      },
      color: {
        pattern: colores_agrupados
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });

    //RESUMEN SUBESTADOS SEMANA
    this.chartDonutResumenSemana_subEstados = c3.generate({
      bindto: '#chartDonutResumenSemana_subEstados',
      data: {
        columns: this.myFunctions.copy(columnasVacias),
        names: this.myFunctions.copy(nombresVacios),
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').append("tspan").attr("font-size", "35").text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_subEstados .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_subEstados .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: this.myFunctions.copy(colores)
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });
    //SUBESTADOS HORAS/DÍA
    this.chartAreaHorasDia_subEstados = c3.generate({
      bindto: '#chartAreaHorasDia_subEstados',
      data: {
        x: 'x',
        columns: this.myFunctions.copy(columnasVacias_Diario)
        ,
        //type: 'area', 'area-spline',
        type: 'area',
        groups: [grupos],
        names: this.myFunctions.copy(nombresVacios)
      },
      color: {
        pattern: this.myFunctions.copy(colores)
      },
      axis: {
        x: {
          type: 'category',
          padding: 0
        },
        y: {
          min: 0,
          max: 86400,
          padding: { right: 0, bottom: 0, left: 0 },
          tick: {
            values: [0, 10800, 21600, 32400, 43200, 54000, 64800, 75600, 86400],
            format: function (d) {
              const sec = parseInt(d, 10);
              let hours = Math.floor(sec / 3600);
              let minutes = Math.floor((sec - (hours * 3600)) / 60);
              let seconds = sec - (hours * 3600) - (minutes * 60);
              return hours
            }
          }
        }
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      grid: {
        y: {
          show: true
        }
      }
    });
  }

  // GANTT
  get_Gantt() {

    var r1, r2: boolean = false;

    var fechafinPRATURNOS: Date = new Date(this.fechaFin)


    this.InformeRendimientoService.get_turnos(this.selectedMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio)
      , this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(fechafinPRATURNOS.setDate(fechafinPRATURNOS.getDate() + 1)))).subscribe(
        json => {
          this.turnosArray = [];
          var an: any = json;
          //an.forEach(f => {
          //  this.turnosArray.push({ min: new Date(f.min), max: new Date(f.max), clase: f.clase })
          //});

          this.fechaMin = this.fechaInicio;
          this.fechaMax = this.fechaFin;

          // se cargan los horarios de la maquina como "por defecto" para marcar el inicio y fin del dia en caso de que no se cubra 100% con turnos
          var horarioFueraTurno = an.filter(f => f.clase == 'fuera')
          if (horarioFueraTurno.length > 0) {
            this.fechaMin = new Date(horarioFueraTurno[0].min);
            this.fechaMax = new Date(horarioFueraTurno[0].max);
          }
          an.forEach(f => {
            var d = new Date(f.turno);
            if (f.clase != 'fuera') {
              if (d.getTime() >= this.fechaInicio.getTime() && d.getTime() <= this.fechaFin.getTime()) {
                this.turnosArray.push({ min: new Date(f.min), max: new Date(f.max), clase: f.clase });
                if (new Date(f.min) < this.fechaMin)
                  this.fechaMin = new Date(f.min);
                if (new Date(f.max) > this.fechaMax)
                  this.fechaMax = new Date(f.max);
              }
              else if (d < this.fechaInicio)
                this.fechaMin = new Date(f.max);
              else if (d > this.fechaFin)
                this.fechaMax = new Date(f.min);
            }
          });

          r1 = true;
          if (r1 && r2)
            this.mostrarDatos_ChartGanttTimeline_conSubestados();

        });
    // this.turnosComboSelected ahora es "" ... solo se usaba para el gant y resumen semana, ahora se carga entero y se filtra al cargar el dato
    if (!this.esOffline)
      this.InformeRendimientoService.get_gantt_subEstados_activos(this.selectedMaquina, "", this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe(
        json => {
          this.JdatosGANTT = json;
          r2 = true;
          if (r1 && r2)
            this.mostrarDatos_ChartGanttTimeline_conSubestados();
        });
    else
      this.InformeRendimientoService.get_gantt_offline(this.selectedMaquina, "", this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe(
        json => {
          this.JdatosGANTT = json;
          r2 = true;
          if (r1 && r2)
            this.mostrarDatos_ChartGanttTimeline_conSubestados();
        });

  }
  //ESTA FUNCION FILTRA LOS DATOS LEIDOS DESDE LA BASE DE DATOS
  mostrarDatos_ChartGanttTimeline_conSubestados() {
    // SE GENERA LA ESTRUCTURA BASE
    let data = {
      machines: [
      ],
      tasks: []
    };

    var orden = 1; // para que el gantt se mantenga en orden despues de agrupar los subestados

    this.jSubEstados.forEach(
      (subestado) => {
        if (subestado.idMaquinas_subEstados < 0) {
          // id: subestado.idProcesos_Tipo_subEstados,     
          var machine =
          {
            id: orden,
            name: subestado.nombre,
            image: ''
          }
          orden += 1;
          data.machines.push(machine);
        }
      });

    var a: any = this.JdatosGANTT;
    var an: any = a.filter(f => this.listaIdTurnos.length == 0 || this.listaIdTurnos.includes(f.tipoTurno));

    var idDb = this.user.idDb;

    an.forEach(f => {

      if (idDb == 33) {
        if (f.idProcesos_Tipo == 2 && f.fueraDeTiempo) {
          f['filter'] = {
            src: 'assets/img/diagonal-paradas.png',
            size: [95, 50], // original image w and h
            animated: false
          };
          f.backgroundColor = "#096844";
        }
      }

      var tooltip = ``;

      tooltip = tooltip + `<div class="tooltipa"><p><strong><span class="tooltip-negrita">`;
      tooltip = tooltip + `${this.translate.instant(f.procesos_Tipo_subEstados).toUpperCase()}`;

      tooltip = tooltip + `</strong></label></p>`;

      if (f.nombrePrograma != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${f.nombrePrograma}<br></span></p>`;

      if (f.descripcion != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("descripcion").toUpperCase()}: </span><span class="tooltip-valor">${f.descripcion}<br></span></p>`;


      tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${f.operario}</span></p>`;

      tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

      if (f.refOF != "" && f.idProcesos_Tipo != 4) {
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("of").toUpperCase()}: </span><span class="tooltip-valor">${f.of}<br></span></p>`;
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("cliente").toUpperCase()}: </span><span class="tooltip-valor">${f.cliente}<br></span></p>`;
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("refPieza").toUpperCase()}: </span><span class="tooltip-valor">${f.refPieza}<br></span></p>`;
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("pieza").toUpperCase()}: </span><span class="tooltip-valor">${f.pieza}<br></span></p>`;
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("operacion").toUpperCase()}: </span><span class="tooltip-valor">${f.programa}<br></span></p>`;
        if (f.colada != "")
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("colada").toUpperCase()}: </span><span class="tooltip-valor">${f.colada}<br></span></p>`;
        if (f.lote != "")
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("lote").toUpperCase()}: </span><span class="tooltip-valor">${f.lote}<br></span></p>`;
        if (f.numeroSerie != "")
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${f.numeroSerie}<br></span></p>`;
        if (f.contador)
          tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("contador").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidad}<br></span></p>`;
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("confirmadas").toUpperCase()}: </span><span class="tooltip-valor">${f?.cantidadTerminadas}/${f?.cantidadTotal}<br></span></p>`;
      } else if (f.idProcesos_Tipo != 4) {
        tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("operacionNoEncontrada").toUpperCase()}<br></span></p>`;
      } else {
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("mantenimiento").toUpperCase()}: </span><span class="tooltip-valor">${f.nombreMantenimiento}<br></span></p>`;
      }

      if (f.perdida != "") {
        tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
        tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("perdida").toUpperCase()}: </span><span class="tooltip-valor">${f.perdida}<br></span></p>`;
      }

      tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

      var fIni = new Date(f.fechaini);
      var fFin = new Date(f.fechafin);
      var dif = this.myFunctions.secondsTo_HH_MM_SS((fFin.getTime() - fIni.getTime()) / 1000);

      tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("duracion").toUpperCase()}: </span><span class="tooltip-valor">${dif}<br></span></p>`;
      tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("inicio2").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fIni)}<br></span></p>`;
      tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("fin").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fFin)}<br></span></p>`;

      tooltip = tooltip + `</div>`;

      f.detail2 = tooltip;

      if (this.visibleTipo) {
        f.detail = (d) => f.detail2;
      }
    });
    data.tasks = an;
    //MAXIMO:  1 linea = 60 altura 
    //MINIMO:  10 lineas = 35 altur
    // RESTO: dinamico
    this.chartGanttTimeline = new GanttChartClick('chartGanttTimeline', {
      width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: 50 + ((64 - Math.min((4 * Object.keys(data.machines).length), 29)) * Object.keys(data.machines).length),
      startDate: this.fechaMin,
      endDate: this.fechaMax,
      shifts: this.turnosArray,
      data: data,
    }, this.translate)
      .on('task_clicked', (id, data) => {
        //this.ganttClicked(id);
      });

    this.loadingGantt = false;
  }

  // RESUMEN SEMANA: SubEstados Y SubEstados Agrupados
  get_GraficosResumenSubEstados() {
    // var listaIdsTurnos = (this.comboTurnosSeleccion === undefined) ? [] : this.comboTurnosSeleccion.map(a => a.id);
    // listaIdsTurnos.join(","), ... ANTES se pasaba esto para filtrar el turno en la consulta, ahora se filtra al cargar
    this.InformeRendimientoService.get_procesos_porDias_subEstados_activos(this.selectedMaquina, "", this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 7)))).subscribe(
      (json: any) => {
        this.JdatosResumenSemana = json;
        this.mostrarGraficosResumenSubEstados();
      }
    );
  }
  //ESTA FUNCION FILTRA LOS DATOS LEIDOS DESDE LA BASE DE DATOS
  mostrarGraficosResumenSubEstados() {

    this.cargarGraficosResumenSemanaSinDatos(); // se recargan vacios para no sumar los tiempos nuevos al grafico ya cargado.

    console.log(this.listaIdTurnos);
    var a: any = this.JdatosResumenSemana;
    var an: any = a.filter(f => this.listaIdTurnos.length == 0 || this.listaIdTurnos.includes(f.tipoTurno));

    // Se suman los tiempos por subestado y en sus agrupados
    an.forEach(
      row => {
        // Sub Estado
        var a = this.jSubEstados.filter(f => f.idProcesos_Tipo_subEstados == row.idProcesos_Tipo_subEstados)
        if (a.length > 0) {
          var subestado = a[0];
          // Sub Estado: Agrupados
          // Si es un proceso principal: idProcesos_tipos_subEstados < 99 se añade a si mismo
          //                       sino: se le añade a su maquina_subEstados perteneciente a ese idProcesos_Tipos_subEstados                 
          if (subestado.idProcesos_Tipo_subEstados > 99) {
            // es un subestado especifico, se añaden los tiempos para el grafico de subestados
            switch (this.myFunctions.dateToString(this.myFunctions.sqlToJsDateT(row.fechaTurno))) {
              case this.myFunctions.dateToString(this.fechaInicio): {
                subestado.tiempoLunes += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 1))): {
                subestado.tiempoMartes += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 2))): {
                subestado.tiempoMiercoles += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 3))): {
                subestado.tiempoJueves += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 4))): {
                subestado.tiempoViernes += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 5))): {
                subestado.tiempoSabado += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 6))): {
                subestado.tiempoDomingo += row.tiempo;
                break;
              }
            }
            // se coge el subestado principal de ese tipo de proceso de la maqina: el idprocesostipo es independiente al subestado
            // a = this.jSubEstados.filter(f=> f.idProcesos_Tipo_subEstados < 99 && f.idProcesos_Tipo == subestado.idProcesos_Tipo && f.porDefecto != this.myFunctions.intToBool(row.micro))
          }
          var a = this.jSubEstados.filter(f => f.idProcesos_Tipo_subEstados < 99 && f.idProcesos_Tipo == row.idProcesos_Tipo
                                           && f.porDefecto != (this.myFunctions.intToBool(row.micro) && row.idProcesos_Tipo == 2 ))
          if (a.length > 0) {
            var subestado = a[0];
            switch (this.myFunctions.dateToString(this.myFunctions.sqlToJsDateT(row.fechaTurno))) {
              case this.myFunctions.dateToString(this.fechaInicio): {
                subestado.tiempoLunes_agrupados += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 1))): {
                subestado.tiempoMartes_agrupados += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 2))): {
                subestado.tiempoMiercoles_agrupados += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 3))): {
                subestado.tiempoJueves_agrupados += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 4))): {
                subestado.tiempoViernes_agrupados += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 5))): {
                subestado.tiempoSabado_agrupados += row.tiempo;
                break;
              }
              case this.myFunctions.dateToString(new Date(this.fechaInicio.getTime() + (1000 * 60 * 60 * 24 * 6))): {
                subestado.tiempoDomingo_agrupados += row.tiempo;
                break;
              }
            }
          }
        }
      });
    // Se genera la estructura necesaria para el grafico
    // Sub Estados
    //    Donut
    var columnas_Donut = [];
    var nombres_Donut = {};
    //     Diario
    var columnas_Diario = [['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")]];
    var nombres_Diario = {};
    // Sub Estados: AGRUPADOS
    //    Donut
    var columnas_Donut_agrupados = [];
    var nombres_Donut_agrupados = {};
    //    Diario
    var columnas_Diario_agrupados = [['x', this.translate.instant("lunes"), this.translate.instant("martes"), this.translate.instant("miercoles"), this.translate.instant("jueves"), this.translate.instant("viernes"), this.translate.instant("sabado"), this.translate.instant("domingo")]];
    var nombres_Diario_agrupados = {};

    // Se genera la estructura de datos para el grafico
    this.jSubEstados.forEach(
      (subestado) => {
        if (subestado.idProcesos_Tipo_subEstados > 99) {
          // Sub Estados
          //    Donut
          columnas_Donut.push([subestado.nombre, subestado.tiempoLunes + subestado.tiempoMartes + subestado.tiempoMiercoles + subestado.tiempoJueves + subestado.tiempoViernes + subestado.tiempoSabado + subestado.tiempoDomingo]);
          nombres_Donut[subestado.nombre] = subestado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(subestado.tiempoLunes + subestado.tiempoMartes + subestado.tiempoMiercoles + subestado.tiempoJueves + subestado.tiempoViernes + subestado.tiempoSabado + subestado.tiempoDomingo) + ")";
          //    Diario                       
          columnas_Diario.push([subestado.nombre, subestado.tiempoLunes, subestado.tiempoMartes, subestado.tiempoMiercoles, subestado.tiempoJueves, subestado.tiempoViernes, subestado.tiempoSabado, subestado.tiempoDomingo]);
          nombres_Diario[subestado.nombre] = subestado.nombre;
        } else {
          // Sub Estados: AGRUPADOS
          //    Donut
          columnas_Donut_agrupados.push([subestado.nombre, subestado.tiempoLunes_agrupados + subestado.tiempoMartes_agrupados + subestado.tiempoMiercoles_agrupados + subestado.tiempoJueves_agrupados + subestado.tiempoViernes_agrupados + subestado.tiempoSabado_agrupados + subestado.tiempoDomingo_agrupados]);
          nombres_Donut_agrupados[subestado.nombre] = subestado.nombre + " (" + this.myFunctions.secondsTo_HH_MM_SS(subestado.tiempoLunes_agrupados + subestado.tiempoMartes_agrupados + subestado.tiempoMiercoles_agrupados + subestado.tiempoJueves_agrupados + subestado.tiempoViernes_agrupados + subestado.tiempoSabado_agrupados + subestado.tiempoDomingo_agrupados) + ")";
          //    Diario                       
          columnas_Diario_agrupados.push([subestado.nombre, subestado.tiempoLunes_agrupados, subestado.tiempoMartes_agrupados, subestado.tiempoMiercoles_agrupados, subestado.tiempoJueves_agrupados, subestado.tiempoViernes_agrupados, subestado.tiempoSabado_agrupados, subestado.tiempoDomingo_agrupados]);
          nombres_Diario_agrupados[subestado.nombre] = subestado.nombre;
        }
      });

    // Sub Estados
    //    Donut
    var data_Donut = {
      columns: columnas_Donut,
      names: nombres_Donut
    }
    this.chartDonutResumenSemana_subEstados.load(data_Donut);
    this.loadingResumenSemana_subEstados = false;
    //    Diario
    var data_Diario = {
      columns: columnas_Diario,
      names: nombres_Diario
    }
    this.chartAreaHorasDia_subEstados.load(data_Diario);
    this.loadingHorasDia_subEstados = false;
    // Sub Estados: AGRUPADOS
    //    Donut
    var data_Donut_agrupados = {
      columns: columnas_Donut_agrupados,
      names: nombres_Donut_agrupados
    }
    console.log("get_procesos_porDias_subEstados_activos");
    console.log(an);
    console.log(data_Donut_agrupados);
    this.chartDonutResumenSemana.load(data_Donut_agrupados);
    this.loadingResumenSemana = false;
    //    Diario
    var data_Diario_agrupados = {
      columns: columnas_Diario_agrupados,
      names: nombres_Diario_agrupados
    }
    this.chartAreaHorasDia.load(data_Diario_agrupados);
    this.loadingHorasDia = false;

    // console.log("___________________________________________")
    // console.log(this.jSubEstados)
    // console.log(json)
    // console.log("___________________________________________")
    // console.log("data_Donut")
    // console.log(data_Donut)
    // console.log("___________________________________________")
    // console.log("data_Diario")
    // console.log(data_Diario)
    // console.log("___________________________________________")
    // console.log("data_Donut_agrupados")
    // console.log(data_Donut_agrupados)
    // console.log("___________________________________________")
    // console.log("data_Diario_agrupados")
    // console.log(data_Diario_agrupados)

  }
}
