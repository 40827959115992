import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-generador-pedido',
  templateUrl: './generador-pedido.component.html',
  styleUrls: ['./generador-pedido.component.less']
})
export class GeneradorPedidoComponent implements OnInit {

  public data: any;
  public mySelection: any;
  constructor(private menuService: MenuService, public router: Router, private translateService: TranslateService) {
    this.menuService.titulo = this.translateService.instant('generadorPedido');
   }

  ngOnInit(): void {
  }

}
