<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">

      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'marca' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <!--NOMBRE-->
          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate }}">
              <div class="caja">
                <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
              </div>
            </kendo-label>
          </div>

          <!--IMAGEN-->

          <label ID="lCaracteristicas" CssClass="">{{ 'imagen' | translate }}</label>
          <ng-template #noImageFound>
            <img src="fallbackImage.png">
          </ng-template>
          <div class="form-group w-50">
            <div *ngIf="imagePreviews.length; else initImage">

              <img *ngFor="let image of imagePreviews"
                   [src]="image.src"
                   alt="image preview"
                   style="width: 200px; margin: 10px;" />
            </div>
            <ng-template #initImage>
              <img [src]="imageToShow" style="max-height: 113px">
            </ng-template>
            <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false" (select)="selectEventHandler($event)">
              <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                     clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                     dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                     externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                     fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                     fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                     filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                     filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                     filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                     headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                     headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                     headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                     invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                     invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                     invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                     pause="{{ 'pause' | translate }}"
                                     remove="{{ 'remove' | translate }}"
                                     resume="{{ 'resume' | translate }}"
                                     retry="{{ 'retry' | translate }}"
                                     select="{{ 'select' | translate }}"
                                     uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
              </kendo-upload-messages>
            </kendo-fileselect>
          </div>
          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.marcas<2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
