<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-header">
                <h3>{{ 'editar nota' | translate }}</h3>
            </div>
            <div class="card-body">
                <!-- FORM -->
                <form [formGroup]="formNota">
                    <div class="row mt-2">
                        <!-- TIPO DE REFERENCIA -->
                        <div class="col-lg-6">
                            <kendo-label text="{{ 'Tipo de referencia' | translate }}"><br>
                                <kendo-combobox style="width: 100%;" [data]="listaTipos"
                                    (valueChange)="tipoChange($event)" [value]="this.tipoRef" textField="nombre"
                                    valueField="id" [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="tipoRefForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                        <!-- REFERENCIA -->
                        <div class="col-lg-6" *ngIf="mostrarRef">
                            <!-- OPERARIO -->
                            <kendo-label *ngIf="this.tipoRef.id == 1" text="{{ 'Seleccione Operario' | translate }}">
                                <kendo-combobox style="width: 100%;" [data]="listaReferencias"
                                    (valueChange)="referenciaChange($event)" 
                                    [value]="this.referencia" textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="referenciaForm" required>
                                </kendo-combobox>
                            </kendo-label>
                            <!-- MAQUINA -->
                            <kendo-label *ngIf="this.tipoRef.id == 2" text="{{ 'Seleccione Maquina' | translate }}">
                                <kendo-combobox style="width: 100%;" [data]="listaReferencias"
                                    (valueChange)="referenciaChange($event)"
                                    [value]="this.referencia" textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="referenciaForm" required>
                                </kendo-combobox>
                            </kendo-label>
                            <!-- HERRAMIENTA -->
                            <kendo-label *ngIf="this.tipoRef.id == 5" text="{{ 'Seleccione Herramienta' | translate }}">
                                <kendo-combobox style="width: 100%;" [data]="listaReferencias"
                                    (valueChange)="referenciaChange($event)"
                                    [value]="this.referencia" textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="referenciaForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row mt-1" *ngIf="mostrarHastaPieza || mostrarTodo">
                        <!-- OF -->
                        <div class="form-group col-lg-6">
                            <kendo-label text="{{ 'OF' | translate }}"><br>
                                <kendo-combobox style="width: 100%;" [data]="listaOFs" (valueChange)="OFChange($event)"
                                    [value]="this.OF" textField="proyecto" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="OFForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                        <!-- PIEZA -->
                        <div class="form-group col-lg-6">
                            <kendo-label text="{{ 'Pieza' | translate }}"><br>
                                <kendo-combobox style="width: 100%;" [data]="listaPiezas"
                                    (valueChange)="piezaChange($event)" [value]="this.pieza"
                                    textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="piezaForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row mt-1" *ngIf="mostrarTodo">
                        <!-- PARTE -->
                        <div class="form-group col-lg-6">
                            <kendo-label text="{{ 'Parte' | translate }}"><br>
                                <kendo-combobox style="width: 100%;" [data]="listaPartes"
                                    (valueChange)="parteChange($event)" [value]="this.parte"
                                    textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="parteForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                        <!-- RUTA -->
                        <div class="form-group col-lg-6">
                            <kendo-label text="{{ 'Ruta' | translate }}"><br>
                                <kendo-combobox style="width: 100%;" [data]="listaRutas"
                                    (valueChange)="rutaChange($event)" [value]="this.ruta"
                                    textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="rutaForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row mt-1" *ngIf="mostrarTodo">
                        <!-- OPERACIÓN -->
                        <div class="form-group col-lg-6">
                            <kendo-label text="{{ 'Operacion' | translate }}"><br>
                                <kendo-combobox style="width: 100%;" [data]="listaOperaciones"
                                    (valueChange)="operacionChange($event)"
                                    [value]="this.operacion" textField="nombre" valueField="id"
                                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'contains'}"
                                    formControlName="operacionForm" required>
                                </kendo-combobox>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <!-- COMENTARIO -->
                        <div class="form-group col-lg-12">
                            <kendo-label text="{{ 'Comentario' | translate }}" [for]="comments"></kendo-label><br>
                            <kendo-textarea #comments style="width: 100%;" resizable="vertical" [rows]="5"
                                formControlName="comentarioForm" required></kendo-textarea>
                        </div>
                    </div>
                </form>
                <div class="row mt-1">
                    <!-- SWITCH PERMANENTE -->
                    <div class="col-lg-12">
                        <kendo-label text="{{ 'Permanente' | translate }}"><br>
                            <kendo-switch [(ngModel)]="this.permanente" [onLabel]="' '" [offLabel]="' '">
                            </kendo-switch>
                        </kendo-label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="form-group text-left">
    <!-- BOTÓN SUBMIT -->
    <button kendoButton (click)="onSubmit()" class="btn mr-1  btn-primary">
        {{ 'guardar' | translate}}
    </button>
    <!-- VOLVER A NOTAS -->
    <a [routerLink]="['/notas']" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
</div>