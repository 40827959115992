<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  :host /deep/ .k-grid tr.azul .codeColumn {
    background-color: #a4e9e9;
  }
  :host /deep/ .k-grid tr.azul:hover .codeColumn {
    background-color: #94e5e5;
  }
  :host /deep/ .k-grid tr.azul.k-state-selected td .codeColumn {
    background-color: #84e1e1;
  }
  :host /deep/ .k-grid tr.rojo .codeColumn {
    background-color: #ff9d9d;
  }
  :host /deep/ .k-grid tr.rojo:hover .codeColumn {
    background-color: #ff8989;
  }
  :host /deep/ .k-grid tr.rojo.k-state-selected td .codeColumn {
    background-color: #ff7676;
  }
  :host /deep/ .k-grid tr.amarillo .codeColumn {
    background-color: #ffdd77;
  }
  :host /deep/ .k-grid tr.amarillo:hover .codeColumn {
    background-color: #ffd863;
  }
  :host /deep/ .k-grid tr.amarillo.k-state-selected td .codeColumn {
    background-color: #ffd350;
  }
</style>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <!-- FILTRO -->
  <div>
    <div class="clearfix">
      <!-- Estimado / predictivo / Ineficiencias -->
      <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
            <div class="btn mr-1" [class.btn-success]="aplicarTiempoEstimado" [class.btn-outline-success]="!aplicarTiempoEstimado">
              <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnTiempoEstimado()" [checked]="aplicarTiempoEstimado" kendoRadioButton>
              <label class="k-radio-label" for="btnEstimado">{{ 'estimado' | translate }}</label>
            </div>
            <div class="btn mr-1" [class.btn-success]="!aplicarTiempoEstimado" [class.btn-outline-success]="aplicarTiempoEstimado">
              <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnPredictivo()" [checked]="!aplicarTiempoEstimado" kendoRadioButton>
              <label class="k-radio-label" for="btnPredictivo">{{ 'predictivo' | translate }}</label>
            </div>
            <!-- Ineficiencias -->
            <button type="button" class="btn d-block mt-2 w-100" [class.btn-success]="aplicarIneficiencias" 
                    [class.btn-outline-success]="!aplicarIneficiencias" (click)="btnIneficiencia()" [disabled]="this.user.planificadorHerramientas<2">{{ 'ineficiencia' | translate }}</button>
          </div>
        </div>
      </div>
      <!-- ORIGINAL / VERSION DE SIMULADOR -->
      <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
            <label class="crontol-label float-left m-1">{{ 'version' | translate }}</label>
            <kendo-dropdownlist [data]="Jplanificadores"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="JplanificadoresSelected"
                                (valueChange)="versionChanged($event)"
                                style="width: 100px;">
            </kendo-dropdownlist>
            <button type="button" class="btn btn-success ml-1 mr-1" (click)="btnBorrarVersion()" 
                    [hidden]="!visibleUsarVersion" [disabled]="this.user.planificadorHerramientas<2">{{ 'borrarVersion' | translate }}</button>
            <button type="button" class="btn btn-success mt-2 d-block w-100" (click)="btnCopiarAVersion()"
                    [disabled]="this.user.planificadorHerramientas<2">{{ 'copiarAVersion' | translate }}</button>
          </div>
        </div>
      </div>

      <div class="float-left mr-2">
        <div class="card">
          <div class="card-body">
            <div class="clearfix">
              <!--LISTA OFS-->
              <div class="float-left mr-2">
                <!--<label>{{ 'of' | translate }}</label>-->
                <kendo-multiselect (valueChange)="CambioFiltro()"
                                   kendoMultiSelectSummaryTag
                                   [data]="listaOfs"
                                   [(ngModel)]="ofsSeleccionados"
                                   [textField]="'nombreOf'"
                                   [valueField]="'idOf'"
                                   placeholder="{{ 'seleccioneOf' | translate }}"
                                   [autoClose]="false"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
              <!--LISTA CLIENTES-->
              <div class="float-left mr-2">
                <!--<label>{{ 'cliente' | translate }}</label>-->
                <kendo-multiselect (valueChange)="CambioFiltro()"
                                   kendoMultiSelectSummaryTag
                                   [data]="listaClientes"
                                   [(ngModel)]="clientesSeleccionados"
                                   [textField]="'nombreCliente'"
                                   [valueField]="'idCliente'"
                                   placeholder="{{ 'seleccioneCliente' | translate }}"
                                   [autoClose]="false"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
              <!--LISTA PIEZAS-->
              <div class="float-left mr-2">

                <!--<label>{{ 'pieza' | translate }}</label>-->
                <div class="caja">
                  <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionados" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                                     placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 270px;" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
            </div>

            <div class="clearfix mt-1">
              <!-- GRUPOS DE MAQUINAS -->
              <div class="float-left mr-2">
                <kendo-multiselect [data]="JgruposMaquinas"
                                   kendoMultiSelectSummaryTag
                                   [textField]="'nombre'"
                                   [valueField]="'id'"
                                   [(ngModel)]="JgruposMaquinasSelected"
                                   placeholder="{{ 'grupoMaquinas' | translate }}"
                                   [autoClose]="false"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                </kendo-multiselect>
              </div>
              <!-- ÁREA PRODUCTIVA / SECCIÓN -->
              <div class="float-left mr-2">
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                                   [textField]="'nombre'"
                                   [valueField]="'id'"
                                   [autoClose]="false"
                                   [(ngModel)]="seccionesSeleccionadas"
                                   (close)="seccionChanged()"
                                   style="width: 200px;"
                                   [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
              <button type="button" class="btn ml-1 mr-1 btn-primary float-right" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>
            </div>
          </div>
        </div>
      </div>

      <!-- BOTONES TOP -->
      <div class="float-right mr-2">
        <div class="clearfix" style="display:none">
          <!-- BOTONES TOP -->
          <div class="float-left mr-2">
            <div class="float-left clearfix">
              <div class="card  mb-1">
                <div class="card-body">
                  <button type="button" class="btn btn-secondary mr-1" (click)="btnReorganizar()" 
                          [disabled]="this.user.planificadorHerramientas<2">{{ 'reorganizar' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="float-left mr-2">
            <div class="float-left clearfix">
              <div class="card  mb-1">
                <div class="card-body">
                  <button type="button" class="btn btn-success mr-1" (click)="btnGuardar()" [disabled]="this.user.planificadorHerramientas<2">{{ 'guardar' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix" style="width: 300px;">
          <!--LEYENDA-->
          <div class="leyendas leyendas-top leyendas-herramientas">
            <div class="graf-barr-leyenda ">
              <span class="colorleyenda" style="background-color: #22c4c4;"></span>
              <Label style="width: 150px;">{{ 'preparado' | translate }}</Label>
            </div>
            <div class="graf-barr-leyenda ">
              <span class="colorleyenda" style="background-color: #F44336; "></span>
              <Label style="width: 150px;">{{ 'faltaMenos1Hora' | translate }}</Label>
            </div>
            <div class="graf-barr-leyenda ">
              <span class="colorleyenda" style="background-color: #FFC107; "></span>
              <Label style="width: 150px;">{{ 'faltaMenos2Horas' | translate }}</Label>
            </div>
            <div class="graf-barr-leyenda ">
              <span class="colorleyenda" style="background-color: #424242; "></span>
              <Label style="width: 150px;">{{ 'faltaMas2Horas' | translate }}</Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TODO -->
  <div>
    <!-- GANTT -->
    <div class="card">
      <div>
        <div id="gantt-chart" class="gantt-chart-planificadorcorto" style="height: 500px;"></div>
      </div>
    </div>
    <!-- GRID -->
    <div class="card" [hidden]="!visibleInfo" style="display:none">

      <div class="row">
        <!-- INFO-->
        <div class="col-sm-4 col-lg-3 col-xl-2">
          <button type="button" class="btn btn-info mr-1" (click)="btnTraerDesdeLargo()" [disabled]="this.user.planificadorHerramientas<2">{{ 'traerDesdeLargo' | translate }}</button>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'of' | translate }}</label>
            <label class="bloques-info-valor">{{infoOF}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'cliente' | translate }}</label>
            <label class="bloques-info-valor">{{infoCliente}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'proyecto' | translate }}</label>
            <label class="bloques-info-valor">{{infoProyecto}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'plano' | translate }}</label>
            <label class="bloques-info-valor">{{infoPlano}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'refPieza' | translate }}</label>
            <label class="bloques-info-valor">{{infoRefPieza}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
            <label class="bloques-info-valor">{{infoPieza}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'parte' | translate }}</label>
            <label class="bloques-info-valor">{{infoParte}}</label>
          </div>
        </div>
        <!-- ACCIONES -->
        <div class="col-sm-8 col-lg-9 col-xl-10">
          <div class="clearfix mt-1">
            <div class="float-left mr-1">
              <div class="card">
                <div class="clearfix">
                  <div class="m-1 float-left">
                    <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                    <div class="caja float-left" style="width: 300px">
                      <kendo-combobox [data]="Jmaquinas"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [(value)]="JmaquinaSelected"
                                      [class.requerido]="requiereMaquina"
                                      placeholder="{{ 'maquina' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                  <div class="m-1 float-left">
                    <button type="button" class="btn btn-success mr-1" (click)="btnMover()" 
                           [disabled]="this.user.planificadorHerramientas<2">{{ 'mover' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="float-left mr-1">
              <div class="card">
                <div class="clearfix">
                  <div class="m-1 float-left">
                    <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                    <div class="caja float-left" style="width: 300px">
                      <kendo-combobox [data]="JmaquinasLargo"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [(value)]="JmaquinaSelectedLargo"
                                      [class.requerido]="requiereMaquinaLargo"
                                      placeholder="{{ 'maquina' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                  <div class="m-1 float-left">
                    <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
                    <div class="caja float-left" style="width: 300px">
                      <kendo-combobox [data]="Jsemanas"
                                      [textField]="'text'"
                                      [valueField]="'value'"
                                      [(value)]="JsemanaSelected"
                                      [class.requerido]="requiereSemana"
                                      placeholder="{{ 'semana' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                  <div class="float-left">
                    <button type="button" class="btn btn-info m-1" (click)="btnMandarALargo()" 
                            [disabled]="this.user.planificadorHerramientas<2">{{ 'guardarYMandarALargo' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="float-right mr-1">
              <div class="card">
                <div class="clearfix">
                  <button type="button" class="btn btn-danger m-1" (click)="btnSacar()" 
                          [disabled]="this.user.planificadorHerramientas<2">{{ 'sacar' | translate }}</button>
                </div>
              </div>
            </div>
          </div>

          <!-- GRID -->
          <div class="clearfix">
            <kendo-grid [kendoGridBinding]="Joperaciones"
                        kendoGridSelectBy="numFila"
                        [selectedKeys]="operacionesSelected"
                        filterable="menu"
                        [resizable]="true"
                        [pageable]="false"
                        scrollable="virtual"
                        [rowHeight]="36"
                        [height]="500"
                        [pageSize]="20">
              <kendo-grid-checkbox-column showSelectAll="true" width="5%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="ordenOperacion" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="60%"></kendo-grid-column>
              <!--<kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="10%"></kendo-grid-column>-->

              <kendo-grid-column field="tiempoPreparacionHH" title="{{ 'tPreparacion' | translate }}" width="10%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="10%" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="10%" [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <!--<ngcontainer [hidden]="dataItem.cantidadProv == 0">{{ secondsToHms(dataItem.tiempoPreparacion + dataItem.tiempoEstimado * dataItem.cantidadProv) }}</ngcontainer>
                  <ngcontainer [hidden]="dataItem.cantidadProv > 0"> {{ secondsToHms(dataItem.tiempoEstimado * dataItem.cantidadProv) }}</ngcontainer>-->
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="total" title="{{ 'restantes' | translate }}" width="15%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.cantidadProv" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
  </div>

  <!-- POPUP -->
  <div class="card" [hidden]="visibleInfo" style="display:none">
    <div class="clearfix">
      <div class="float-left">
        <button type="button" class="btn btn-info" (click)="btnMandarACorto()" [disabled]="this.user.planificadorHerramientas<2">{{ 'guardarYMandarACorto' | translate }}</button>
      </div>

      <div class="card float-right">
        <div class="clearfix">
          <div class="m-1 float-left">
            <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
            <div class="caja float-left" style="width: 300px">
              <kendo-multiselect kendoMultiSelectSummaryTag
                                 [data]="JmaquinasLargo"
                                 [textField]="'nombre'"
                                 [valueField]="'id'"
                                 [(ngModel)]="JmaquinasSelectedLargo"
                                 [class.requerido]="requiereMaquinaLargo"
                                 placeholder="{{ 'maquina' | translate }}"
                                 [autoClose]="false"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems.length }} {{ 'maquinaSeleccionada' | translate }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                </ng-template>
              </kendo-multiselect>
            </div>
          </div>
          <div class="m-1 float-left">
            <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
            <div class="caja float-left" style="width: 300px">
              <kendo-multiselect kendoMultiSelectSummaryTag
                                 [data]="Jsemanas"
                                 [textField]="'text'"
                                 [valueField]="'value'"
                                 [(ngModel)]="JsemanasSelected"
                                 [class.requerido]="requiereSemana"
                                 placeholder="{{ 'semana' | translate }}"
                                 [autoClose]="false"
                                 [kendoDropDownFilter]="{operator: 'contains'}">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems.length }} {{ 'semanaSeleccionada' | translate }}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'semanasSeleccionadas' | translate }}</ng-container>
                </ng-template>
              </kendo-multiselect>
            </div>
          </div>

          <div class="m-1 float-left">
            <button type="button" class="btn btn-info" (click)="recargarGrid()">{{ 'filtrar' | translate }}</button>
          </div>
        </div>
      </div>
    </div>

    <kendo-grid [kendoGridBinding]="JOperacionesSinCorto"
                kendoGridSelectBy="idPlanificador"
                [selectedKeys]="JOperacionesSinCortoSelecteds"
                filterable="menu"
                [resizable]="true"
                [pageable]="false"
                scrollable="virtual"
                [rowHeight]="36"
                [height]="500"
                [pageSize]="pageSize"
                [skip]="skip"
                (pageChange)="pageChange($event)">

      <kendo-grid-checkbox-column showSelectAll="true" width="3%%"></kendo-grid-checkbox-column>

      <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="5%"></kendo-grid-column>
      <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="20%"></kendo-grid-column>

      <kendo-grid-column field="ordenOperacion" title="{{ 'orden' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
      <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="22%"></kendo-grid-column>
      <!--<kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>-->

      <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="10%"></kendo-grid-column>
      <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="10%"></kendo-grid-column>

      <kendo-grid-column field="tiempoPreparacionHH" title="{{ 'tPreparacion' | translate }}" width="7%" [style]="{'text-align': 'right'}">
      </kendo-grid-column>
      <kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="7%" [style]="{'text-align': 'right'}">
      </kendo-grid-column>
      <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="7%" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ngcontainer [hidden]="dataItem.cantidad == 0">{{ secondsToHms(dataItem.tiempoPreparacion + dataItem.tiempoEstimado * dataItem.cantidad) }}</ngcontainer>
          <ngcontainer [hidden]="dataItem.cantidad > 0"> {{ secondsToHms(dataItem.tiempoEstimado * dataItem.cantidad) }}</ngcontainer>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="total" title="{{ 'restantes' | translate }}" width="8%">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-numerictextbox [(value)]="dataItem.cantidad" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
          </kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>

  <!-- POPUP: Reorganizar -->
  <ng-template #popupReorganizar let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <!-- ROW 1 -->
      <div class="row ml-2">
        <div class="form-group">
          <label>{{ 'prioridadCliente' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="prioridadCliente" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- ROW 2 -->
      <div class="row ml-2">
        <div class="form-group">
          <label>{{ 'prioridadFacturacion' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="prioridadFacturacion" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>

            <!--<kendo-slider  [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="1" [largeStep]="20" [(ngModel)]="prioridadFacturacion">
              <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
            </kendo-slider>
            <p>{{ prioridadFacturacion }}</p>-->
          </div>
        </div>
      </div>
      <!-- ROW 3 -->
      <div class="row ml-2">
        <div class="form-group">
          <label>{{ 'prioridadOF' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="prioridadOF" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          </div>
        </div>
      </div>
      <!-- ROW 4 -->
      <div class="row ml-2">
        <div class="form-group">
          <label>{{ 'prioridadMaquina' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="prioridadMaquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnReorganizarAceptar()" [disabled]="this.user.planificadorHerramientas<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Usar planning -->
  <ng-template #popupUsarVersion let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'estasSeguroUsarVersion' | translate }}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnUsarVersionAceptar()" [disabled]="this.user.planificadorHerramientas<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Borrar planning -->
  <ng-template #popupBorrarVersion let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <label>{{'borrarVersion' | translate }}</label>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnBorrarVersionAceptar()" [disabled]="this.user.planificadorHerramientas<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Copiar planning a version -->
  <ng-template #popupCopiarAVersion let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          <label>{{'aQueVersion' | translate }}</label>
          <div class="clearfix">
            <div class="float-left">
              <label class="crontol-label float-left m-1">{{ 'planificacion' | translate }}</label>
              <div class="caja float-left" style="width: 150px">
                <kendo-combobox [data]="JplanificadoresSinOriginal"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="JplanificadoresSelectedCopiar"
                                [kendoDropDownFilter]="{operator: 'contains'}">
                </kendo-combobox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnCopiarAVersionAceptar()" [disabled]="this.user.planificadorHerramientas<2">{{ 'aceptar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP: Copiar planning a version -->
  <ng-template #popupVistaTemporal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body gantt-popup">
      <div class="card">
        <div class="card-body">
          <!--<label>{{'vistaTemporal' | translate }}</label>-->
          <div class="clearfix">
            <div id="gantt-chart-temporal" class="gantt-chart-planificadorcorto"></div>
            <!--LEYENDA-->
            <div class="leyendas leyendas-informe-potencia">
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #3289a8;"></span>
                <Label>{{ 'todoVaBien' | translate }}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #ebdb34;"></span>
                <Label>{{ 'sePuedeEmpezarPeroNoacabar' | translate }}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #eb8f34;"></span>
                <Label>{{ 'noSePodraEmpezar' | translate }}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" style="background-color: #EA4335;"></span>
                <Label>{{ 'fueraDePlazo' | translate }}</Label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    </div>
  </ng-template>
</div>

<!--MENU LATERAL-->
<div class="panel-menu" [ngClass]="{ 'desplegado': menuDesplegado }" [ngStyle]="{ 'width': menuDesplegado ? '55%' : '0px'}" #menuLateral>
  <kendo-grid [kendoGridBinding]="herramientasGridData"
              [resizable]="true"
              [navigable]="true"
              [sortable]="true"
              [reorderable]="true"
              [rowClass]="rowCallback"
              style="height: 500px; margin: 10px; border: 1px solid #EBEBEB; padding: 0;">
    <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="18%"></kendo-grid-column>
    <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="26%"></kendo-grid-column>
    <kendo-grid-column field="consumible" title="{{ 'herramienta' | translate }}" width="18%" [class]="{ codeColumn: true }"></kendo-grid-column>
    <kendo-grid-column *ngIf="tiemposPorOperacion" field="tOperacion" title="{{ 'tOper' | translate }}" width="12%" [class]="{ codeColumn: true }" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{myFunctions.secondsTo_HH_MM_SS(dataItem.tOperacion)}}
        <!--<span class="k-icon k-i-zoom k-i-search" (click)="clickGridTiempo(dataItem)"></span>-->
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="tiemposPorHerramienta" field="tiempo" title="{{ 'tiempo' | translate }}" width="12%" [class]="{ codeColumn: true }" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempo)}}
        <!--<span class="k-icon k-i-zoom k-i-search" (click)="clickGridTiempo(dataItem)"></span>-->
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="porcenFiabilidadIni" title="{{ 'porcenFiabilidadIni' | translate }}" width="6%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
      <ng-template kendoGridHeaderTemplate>
        <div style="width: 100%;">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15.194 16.988">
            <path d="M3.073,16.92a7.822,7.822,0,0,1-1.495-1.025A4,4,0,0,1,0,12.949V10.572a.645.645,0,0,1,.419-.6l1.873-.7V3.226A1.937,1.937,0,0,1,4.227,1.291h1.29V.645a.645.645,0,1,1,1.291,0v.645h3.871V.645a.645.645,0,1,1,1.29,0v.645h1.291a1.937,1.937,0,0,1,1.935,1.935v9.032a1.937,1.937,0,0,1-1.935,1.936H6.453a4.912,4.912,0,0,1-1.309,1.7A7.832,7.832,0,0,1,3.65,16.92a.64.64,0,0,1-.289.068A.634.634,0,0,1,3.073,16.92Zm-1.782-5.9v1.93a2.86,2.86,0,0,0,1.117,1.957,6.776,6.776,0,0,0,.953.7,6.674,6.674,0,0,0,.953-.7,3.5,3.5,0,0,0,1.019-1.388V10.983l-.576-.216c-.211-.051-.394-.088-.566-.123a5.675,5.675,0,0,1-1.091-.3ZM13.259,12.9a.646.646,0,0,0,.645-.645V4.676a1.5,1.5,0,0,1-.86.27h-8.6a1.5,1.5,0,0,1-.86-.27V8.949l.005,0,1.551.581c.289.072.631.171,1.05.316a.642.642,0,0,1,.327.252.645.645,0,0,1,.206.473V12.9Z" fill="#424242" />
          </svg>&nbsp;%
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="porcenFiabilidadFin" title="{{ 'porcenFiabilidadFin' | translate }}" width="6%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
      <ng-template kendoGridHeaderTemplate>
        <div style="width: 100%;">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15.194 16.988">
            <path d="M11.545,16.92a7.832,7.832,0,0,1-1.494-1.025,4.917,4.917,0,0,1-1.31-1.7H1.935A1.938,1.938,0,0,1,0,12.257V3.226A1.937,1.937,0,0,1,1.935,1.291H3.226V.645a.645.645,0,1,1,1.291,0v.645H8.387V.645a.645.645,0,1,1,1.29,0v.645h1.291A1.937,1.937,0,0,1,12.9,3.226V9.266l1.873.7a.645.645,0,0,1,.418.6v2.377a4,4,0,0,1-1.578,2.946,7.832,7.832,0,0,1-1.494,1.025.642.642,0,0,1-.577,0Zm-.664-2.013a6.725,6.725,0,0,0,.952.7,6.674,6.674,0,0,0,.953-.7A2.857,2.857,0,0,0,13.9,12.949V11.02l-1.81-.678a5.674,5.674,0,0,1-1.091.3c-.172.035-.355.072-.565.123l-.577.216v2.533A3.49,3.49,0,0,0,10.881,14.907Zm-9.59-2.65a.645.645,0,0,0,.644.645H8.473v-2.33a.646.646,0,0,1,.205-.472.642.642,0,0,1,.327-.253c.42-.145.761-.243,1.05-.316l1.551-.581.005,0V4.676a1.5,1.5,0,0,1-.86.27H2.15a1.5,1.5,0,0,1-.859-.27Z" fill="#424242" />
          </svg>&nbsp;%
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vidaUtil" title="{{ 'vidaUtil' | translate }}" width="8%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}"></kendo-grid-column>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>

