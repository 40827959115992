import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/InformeCalidad`;

@Injectable({
  providedIn: 'root'
})
export class InformeCalidadService {

  constructor(private http: HttpClient) { }

  //SELECT
  GetInformeTablas(fechaInicio, fechaFin, listaNombresClientes, listaNombresPiezas, listaNombresOfs, listaIdsTurnos, listaIdsOperarios, listaIdsHerramientas, 
    listaIdsSecciones, listaIdsMaquinas, listaIdsGrupos) {
      return this.http.post<JSON>(`${baseUrl}/Get_informes`, {
        fechaInicio: fechaInicio, fechaFin: fechaFin,
        listaNombresClientes: listaNombresClientes, listaNombresPiezas: listaNombresPiezas, listaNombresOfs: listaNombresOfs, listaIdsTurnos: listaIdsTurnos,
        listaIdsOperarios: listaIdsOperarios, listaIdsSecciones: listaIdsSecciones, listaIdsMaquinas: listaIdsMaquinas, listaIdsGrupos: listaIdsGrupos, 
        listaIdsHerramientas: listaIdsHerramientas}, { withCredentials: true });
  }

}
