import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { InformeProyectosService, MenuService, ProgramasService, UsuariosService} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-visor-programas',
  templateUrl: './visor-programas.component.html'
})
export class VisorProgramasComponent implements OnInit {

  user = this.userService.userValue;
  private listaProgramas: any;

  private listaVersiones: any;

  public listaFinal: any = []; //lista con programas y versiones

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private menuService: MenuService, public router: Router, private programasService: ProgramasService, public myFunctions: MyFunctions, private informeProyectosService: InformeProyectosService,
    private translateService: TranslateService, private userService: UsuariosService) 
  {
      this.menuService.titulo = this.translateService.instant('visor-programas').toUpperCase(); // for title of page at header
  }

  ngOnInit(): void {
    this.cargarProgramas(); //load programs
  }

  cargarProgramas(): void {
    var r1, r2: boolean = false; //se añade la variable para la respuesta de las 2 consultas
    //tomar datos programas (tabla externa)
    this.programasService.GetProgramas().subscribe((result) => {
      this.listaProgramas = result;
      r1 = true; // respuesta 1 recibida
      if (r1 && r2){ // si se han recibido ambas respuestas procesar datos
        this.procesarDatos();}
    });

    //tomar datos versiones (tabla interna)
    this.programasService.GetVersiones().subscribe((result) => {
      this.listaVersiones = result;
      r2 = true; // respuesta 2 recibida
      if (r1 && r2){ // si se han recibido ambas respuestas procesar datos
        this.procesarDatos();}
    });
  }

  procesarDatos(): void{
    this.listaProgramas = this.listaProgramas.data.sort(function(date1, date2) { // sort data by date at fechaVersion
      // Create the dates
      var keyA = new Date(date1.fechaVersion.split("T")[0]),
        keyB = new Date(date2.fechaVersion.split("T")[0]);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    this.listaVersiones = this.listaVersiones.data.sort(function(elem1, elem2) { // sort data by nombrePrograma and idOperaciones_Predefinidas
      // Create the dates
      var keyA = elem1.nombrePrograma, keyB = elem2.nombrePrograma, keyC = elem1.idOperaciones_Predefinida, keyD = elem2.idOperaciones_Predefinida, keyE = elem1.idControl, keyF = elem2.idControl;
      // Compare the 2 elems
      if (keyA === keyB && keyC === keyD && keyE === keyF) return -1;
      if (keyA != keyB || keyC != keyD || keyE != keyF) return 1;
      return 0;
    });
    //crear una lista que tenga enlazadas las tablas internas con las externas
    var auxLista = [];
    this.listaProgramas.forEach((programa, index) => {
      programa.versiones = [];
      var auxIndexVersion = this.listaVersiones.findIndex(x => x.nombrePrograma === programa.nombrePrograma && x.idOperaciones_Predefinida === programa.idOperaciones_Predefinida && x.idControl === programa.idControl);
      programa.versiones = this.listaVersiones.slice(auxIndexVersion, auxIndexVersion + programa.nVersiones); // As listaVersiones is ordered by nombrePrograma, we can add all versions at once
      auxLista.push(programa);
    });
    this.listaFinal = auxLista;
  }

  parseDate(date: String): any{ //parsear la fecha para mostrarla adecuadamente en tabla
    return new Date(date.split("T")[0]);
  }

  redirectToVersionVisor(event: any): void{ //redirigir al visor de versiones por idprograma e idversion
    this.router.navigate(['/visorVersion/:idP' + event.dataItem.nombrePrograma + '/:idV' + event.dataItem.version]);
  }

  public showGridTooltip1(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && (element.offsetWidth < element.scrollWidth || element.classList.contains('columna-justificada') || element.classList.contains('columna-no-justificada'))) {

      this.tooltipDir.toggle(element);   

    } else {
      this.tooltipDir.hide();
    }
  }

}
