<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

<div class="cont-filtro-maquinas-areas clearfix">
  <div class="filtro-areaproductiva-cont">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="seccionChanged()"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="seccionChanged()"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out mr-2" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas">
      <div *ngFor="let maquina of JmaquinasMostradas" (click)="selectedMaquina = maquina.id; selectedMaquinaTipo = maquina.tipo; maquinasDesplegadas = ! maquinasDesplegadas; desplegarMaquinasClick(); maquinaClicked();" class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina && maquina.tipo==selectedMaquinaTipo}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>
  <!-- Estimado / predictivo / Ineficiencias -->
  <div class="float-left mr-2">
    <div class="card">
      <div class="card-body">
        <div class="btn mr-1" [class.btn-success]="aplicarTiempoEstimado" [class.btn-outline-success]="!aplicarTiempoEstimado">
          <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnTiempoEstimado()" [checked]="aplicarTiempoEstimado" kendoRadioButton>
          <label class="k-radio-label" for="btnEstimado">{{ 'estimado' | translate }}</label>
        </div>
        <div class="btn mr-1" [class.btn-success]="!aplicarTiempoEstimado" [class.btn-outline-success]="aplicarTiempoEstimado">
          <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnPredictivo()" [checked]="!aplicarTiempoEstimado" kendoRadioButton>
          <label class="k-radio-label" for="btnPredictivo">{{ 'predictivo' | translate }}</label>
        </div>
        <!-- Ineficiencias -->
        <button type="button" class="btn d-block mt-2 w-100" [class.btn-success]="aplicarIneficiencias"
                [class.btn-outline-success]="!aplicarIneficiencias" (click)="btnIneficiencia()">{{ 'ineficiencia' | translate }}</button>
      </div>
    </div>
  </div>
  <!-- ORIGINAL / VERSION DE SIMULADOR -->
  <div class="float-left mr-2">
    <div class="card">
      <div class="card-body">
        <label class="crontol-label float-left m-1">{{ 'version' | translate }}</label>
        <kendo-dropdownlist [data]="Jplanificadores"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JplanificadoresSelected"
                            (valueChange)="versionChanged($event)"
                            style="width: 65px">
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
  <!-- FILTRO -->
  <div class="float-left">
    <div class="card">
      <div class="card-body">
        <!--LISTA OFS-->
        <div class="float-left mr-2">
          <!--<label>{{ 'of' | translate }}</label>-->
          <div class="caja">
            <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOfs" [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'" [valueField]="'idOf'"
                               placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false" style="width: 170px;" [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
        <!--LISTA CLIENTES-->
        <div class="float-left mr-2">

          <!--<label>{{ 'cliente' | translate }}</label>-->
          <div class="caja">
            <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaClientes" [(ngModel)]="clientesSeleccionados" [textField]="'nombreCliente'" [valueField]="'idCliente'"
                               placeholder="{{ 'seleccioneCliente' | translate }}" [autoClose]="false" style="width: 270px;" [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
        <!--LISTA PIEZAS-->
        <div class="float-left mr-2">
          <!--<label>{{ 'pieza' | translate }}</label>-->
          <div class="caja">
            <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionados" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                               placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 230px;" [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionados' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
        </div>
        <button type="button" class="btn ml-1 mr-1 btn-primary" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>
      </div>
    </div>
  </div>
  <!-- BOTONES -->
  <div class="float-right">
    <div class="card">
      <div class="card-body">
        <button type="button" class="btn btn-secondary mr-1 " (click)="btnReorganizar()">{{ 'reorganizar' | translate }}</button>
        <button type="button" class="btn btn-info mr-1 " (click)="btnAtrasarUnaSemana()">{{ 'atrasar' | translate }}</button>
        <button type="button" class="btn btn-danger mr-1" (click)="btnAtras()">{{ 'maquinas' | translate }}</button>
        <button type="button" class="btn btn-success mr-1" (click)="btnFijarTodo()" [disabled]="this.user.planificadorLargo<2">{{ 'fijarTodo' | translate }}</button>
        <button type="button" class="btn btn-primary mr-1" (click)="btnDesfijarTodo()" [disabled]="this.user.planificadorLargo<2">{{ 'desfijarTodo' | translate }}</button>
        <button type="button" class="btn btn-primary mr-1" (click)="btnComparativaVersiones()">{{ 'comparativaVersiones' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- GRAFICOS -->
<div class="card">
  <!-- DONUTS -->
  <div>

  </div>
  <!-- BARRAS -->
  <div>
    <div id="graficoFueraTaller">

    </div>
    <div id="graficoCargaMaquina">

    </div>
  </div>

  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
</div>

<!-- GRID -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <!-- GRID (con operacion seleccionada) -->
  <div class="card" [hidden]="!visibleInfo">
    <div class="row">
      <!-- INFO-->
      <div class="col-sm-4 col-lg-3 col-xl-2" [hidden]="visibleInfoGrupo">
        <button type="button" class="btn btn-secondary m-1 " (click)="btnOperaciones()">{{ 'operaciones' | translate }}</button>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'of' | translate }}</label>
          <label class="bloques-info-valor">{{infoOF}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'cliente' | translate }}</label>
          <label class="bloques-info-valor">{{infoCliente}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'proyecto' | translate }}</label>
          <label class="bloques-info-valor">{{infoProyecto}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'plano' | translate }}</label>
          <label class="bloques-info-valor">{{infoPlano}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'refPieza' | translate }}</label>
          <label class="bloques-info-valor">{{infoRefPieza}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
          <label class="bloques-info-valor">{{infoPieza}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'parte' | translate }}</label>
          <label class="bloques-info-valor">{{infoParte}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'fechaentrega' | translate }}</label>
          <label class="bloques-info-valor">{{infoFechaLimite}}</label>
        </div>
      </div>
      <div class="col-sm-4 col-lg-3 col-xl-2" [hidden]="!visibleInfoGrupo">
        <button type="button" class="btn btn-secondary m-1 " (click)="btnOperaciones()">{{ 'operaciones' | translate }}</button>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'grupo' | translate }}</label>
          <label class="bloques-info-valor">{{nombreGrupo}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'temperatura' | translate }}</label>
          <label class="bloques-info-valor">{{temperaturaGrupo}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'tiempoEstimado' | translate }}</label>
          <label class="bloques-info-valor">{{tiempoEstimadoGrupo}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'peso' | translate }}</label>
          <label class="bloques-info-valor">{{pesoGrupo}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'unidades' | translate }}</label>
          <label class="bloques-info-valor">{{unidadesGrupo}}</label>
        </div>
      </div>
      <!-- ACCIONES -->
      <div class="col-sm-8 col-lg-9 col-xl-10">
        <div class="clearfix mt-1" [hidden]="desactivarBottom">
          <div class="float-left mr-1">
            <div class="clearfix">
              <!-- MAQUINA -->
              <div class="m-1 float-left">
                <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
                <div class="caja float-left" style="width: 300px">
                  <kendo-combobox [data]="JmaquinasMostradasSinSubcontratas"
                                  [textField]="'nombre'"
                                  [valueField]="'id'"
                                  [(value)]="JmaquinaSelected"
                                  [class.requerido]="requiereMaquina"
                                  placeholder="{{ 'maquina' | translate }}"
                                  [kendoDropDownFilter]="{operator: 'contains'}">
                  </kendo-combobox>
                </div>
              </div>
              <!-- SEMANA -->
              <div class="m-1 float-left">
                <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
                <div class="caja float-left" style="width: 300px">
                  <kendo-combobox [data]="Jsemanas"
                                  [textField]="'text'"
                                  [valueField]="'value'"
                                  [(value)]="JsemanaSelected"
                                  [class.requerido]="requiereSemana"
                                  placeholder="{{ 'semana' | translate }}"
                                  [kendoDropDownFilter]="{operator: 'contains'}">
                  </kendo-combobox>
                </div>
              </div>
              <!-- BOTON (MOVER) -->
              <div class="m-1 float-left">
                <button type="button" class="btn btn-success mr-1" (click)="btnMover()" [disabled]="this.user.planificadorLargo<2">{{ 'mover' | translate }}</button>
              </div>
            </div>
          </div>
          <!-- BOTONES -->
          <div class="float-right">
            <div class="clearfix">
              <button type="button" class="btn btn-secondary m-1 float-left" (click)="btnFijar()" [disabled]="this.user.planificadorLargo<2">{{ 'fijar' | translate }}</button>
              <button type="button" class="btn btn-primary m-1 float-left" (click)="btnDesfijar()" [disabled]="this.user.planificadorLargo<2">{{ 'desfijar' | translate }}</button>
              <button type="button" class="btn btn-info m-1 float-left" (click)="btnSacar()" [disabled]="this.user.planificadorLargo<2">{{ 'sacar' | translate }}</button>
              <button type="button" class="btn btn-success m-1 float-left" (click)="btnMandarACorto()" [disabled]="this.user.planificadorLargo<2">{{ 'mandarACorto' | translate }}</button>
            </div>
          </div>
        </div>
        <!-- GRID -->

        <div class="clearfix">
          <kendo-grid [kendoGridBinding]="JoperacionesVal"
                      kendoGridSelectBy="numFila"
                      [selectedKeys]="operacionesSelected"
                      [navigable]="true"
                      [sortable]="true"
                      filterable="menu"
                      [height]="300"
                      [resizable]="true"
                      [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">
            <kendo-grid-checkbox-column [hidden]="visibleInfoGrupo" showSelectAll="true" width="5%%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="55%">
            </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tPreparacion' | translate }}" width="10%" [style]="{'text-align': 'right'}">
            </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimado" title="{{ 'tEstimado' | translate }}" width="10%" [style]="{'text-align': 'right'}">
            </kendo-grid-column>
            <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="10%" [style]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ngcontainer [hidden]="dataItem.cantidad == 0">{{ secondsToHms(dataItem.tiempoEstimadoPreparacions + dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
                <ngcontainer [hidden]="dataItem.cantidad > 0"> {{ secondsToHms(dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="total" title="{{ 'restantes' | translate }}" width="20%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-numerictextbox [(value)]="dataItem.cantidad" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas"
                                      [(disabled)]="desactivarBottom || visibleInfoGrupo">
                </kendo-numerictextbox>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="dataItem" width="7%" title="">
              <!-- <ng-template kendoGridHeaderTemplate let-column>
                <span class="tooltip-consumibles-contenido">
                  <label><i class="fas fa-info-circle mr-2"></i></label>
                  <span class="tooltipTextHeader">{{ 'irAOperacion' | translate }}</span>
                </span>
              </ng-template> -->
              <ng-template kendoGridCellTemplate let-dataItem>
                <a *ngIf="dataItem.idOperacionesGrupos <= 0" (click)="btnIrAOperacion(dataItem)"> <i class="icon-documento-alt"></i></a>               
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                 filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                 filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                 filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                 filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                 filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                 filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                 filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                 filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                 filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                 filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                 filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                 filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                 filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                 filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                 filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                 filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                 filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                 filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                 filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                 groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                 noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </div>
    </div>
  </div>
  <!-- GRID (SIN operacion seleccionada, primera carga) -->
  <div class="card" [hidden]="visibleInfo">
    <div class="clearfix mt-1">
      <div class="float-left mr-1">
        <div class="clearfix">
          <!-- MAQUINA -->
          <div class="m-1 float-left">
            <label class="control-label m-1 float-left">{{ 'maquina' | translate }}</label>
            <div class="caja float-left" style="width: 300px">
              <kendo-combobox [data]="JmaquinasMostradasSinSubcontratas"
                              [textField]="'nombre'"
                              [valueField]="'id'"
                              [(value)]="JmaquinaSelected"
                              [class.requerido]="requiereMaquina"
                              placeholder="{{ 'maquina' | translate }}"
                              [kendoDropDownFilter]="{operator: 'contains'}">
              </kendo-combobox>
            </div>
          </div>
          <!-- SEMANA -->
          <div class="m-1 float-left">
            <label class="control-label m-1 float-left">{{ 'semana' | translate }}</label>
            <div class="caja float-left" style="width: 300px">
              <kendo-combobox [data]="Jsemanas"
                              [textField]="'text'"
                              [valueField]="'value'"
                              [(value)]="JsemanaSelected"
                              [class.requerido]="requiereSemana"
                              placeholder="{{ 'semana' | translate }}"
                              [kendoDropDownFilter]="{operator: 'contains'}">
              </kendo-combobox>
            </div>
          </div>
          <!-- BOTON (MOVER) -->
          <div class="m-1 float-left">
            <button type="button" class="btn btn-success mr-1" (click)="btnMover()" [disabled]="this.user.planificadorLargo<2">{{ 'mover' | translate }}</button>
          </div>
        </div>
      </div>
      <!-- BOTONES -->
      <div class="float-right">
        <div class="clearfix">
          <button type="button" class="btn btn-secondary m-1 float-left" (click)="btnFijar()" [disabled]="this.user.planificadorLargo<2">{{ 'fijar' | translate }}</button>
          <button type="button" class="btn btn-primary m-1 float-left" (click)="btnDesfijar()" [disabled]="this.user.planificadorLargo<2">{{ 'desfijar' | translate }}</button>
          <button type="button" class="btn btn-info m-1 float-left" (click)="btnSacar()" [disabled]="this.user.planificadorLargo<2">{{ 'sacar' | translate }}</button>
          <button type="button" class="btn btn-success m-1 float-left" (click)="btnMandarACorto()" [disabled]="this.user.planificadorLargo<2">{{ 'mandarACorto' | translate }}</button>
        </div>
      </div>
    </div>
    <!-- GRID -->

    <div class="card" [hidden]="visibleInfo">
      
        <kendo-grid [kendoGridBinding]="JoperacionesYgrupos"
                    kendoGridSelectBy="numFila"
                    [selectedKeys]="operacionesSelected"
                    [navigable]="true"
                    [sortable]="true"
                    [resizable]="true"
                    scrollable="virtual"
                    [rowHeight]="45.56"
                    filterable="menu"
                    [height]="300"
                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">
          <kendo-grid-checkbox-column showSelectAll="true" width="5%%"></kendo-grid-checkbox-column>
          <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="15%"> </kendo-grid-column>
          <kendo-grid-column field="nombre" title="{{ 'referencia' | translate }}" width="38%"> </kendo-grid-column>
          <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="30%"></kendo-grid-column>
          <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
          <!-- <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="5%" [style]="{'text-align': 'right'}"></kendo-grid-column> ORDEN DE OPERACION-->
          <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="15%" [style]="{'text-align': 'right'}">
            <ng-template kendoGridCellTemplate let-dataItem> ({{ dataItem.fechaIni | kendoDate }}) - {{dataItem.semana}} </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="fechaLimite" title="{{ 'fechaentrega' | translate }}" width="20%">
            <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaLimite | kendoDate }}</ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="7%" [style]="{'text-align': 'right'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <ngcontainer [hidden]="dataItem.cantidad == 0">{{ secondsToHms(dataItem.tiempoEstimadoPreparacions + dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
              <ngcontainer [hidden]="dataItem.cantidad > 0"> {{ secondsToHms(dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="cantidad" title="{{ 'restantes' | translate }}" width="8%">
            <ng-template kendoGridCellTemplate let-dataItem>
              <kendo-numerictextbox [hidden]="dataItem.idOperacionesGrupos > 0" [(value)]="dataItem.cantidad" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
              </kendo-numerictextbox>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="dataItem" width="7%" title="">
          <!-- <ng-template kendoGridHeaderTemplate let-column>
            <span class="tooltip-consumibles-contenido">
              <label><i class="fas fa-info-circle mr-2"></i></label>
              <span class="tooltipTextHeader">{{ 'irAOperacion' | translate }}</span>
            </span>
          </ng-template> -->
            <ng-template kendoGridCellTemplate let-dataItem>
              <a *ngIf="dataItem.idOperacionesGrupos <= 0" (click)="btnIrAOperacion(dataItem)"> <i class="icon-documento-alt"></i></a>               
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                               filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                               filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                               filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                               filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                               filterContainsOperator="{{'filterContainsOperator' | translate}}"
                               filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                               filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                               filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                               filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                               filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                               filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                               filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                               filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                               filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                               filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                               filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                               filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                               filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                               filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                               groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                               noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>


      <kendo-tabstrip tabPosition="top" [keepTabContent]="true" [hidden]="true">
        <!-- <div [hidden]="false || this.JmaquinaSelected?.agruparProcesos"> -->
          <kendo-tabstrip-tab title="{{ 'operaciones' | translate}}" [selected]="true">
            <ng-template kendoTabContent>
              <kendo-grid [kendoGridBinding]="Joperaciones"
                          kendoGridSelectBy="numFila"
                          [selectedKeys]="operacionesSelected"
                          [navigable]="true"
                          [sortable]="true"
                          [resizable]="true"
                          scrollable="virtual"
                          [rowHeight]="45.56"
                          filterable="menu"
                          [height]="300"
                          [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">
                <kendo-grid-checkbox-column showSelectAll="true" width="5%%"></kendo-grid-checkbox-column>
                <kendo-grid-column field="refOf" title="{{ 'of' | translate }}" width="5%"></kendo-grid-column>
                <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="8%"></kendo-grid-column>
                <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="15%"></kendo-grid-column>
                <kendo-grid-column [hidden]="ocultarParte == 1" field="parte" title="{{ 'parte' | translate }}" width="9%"></kendo-grid-column>

                <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="25%"></kendo-grid-column>
                <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="11%" [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem> ({{ dataItem.fechaIni | kendoDate }}) - {{dataItem.semana}} </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="fechaLimite" title="{{ 'fechaentrega' | translate }}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaLimite | kendoDate }}</ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tPreparacion' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="tiempoEstimado" title="{{ 'tEstimado' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="dataItem" title="{{ 'tTotal' | translate }}" width="7%" [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <ngcontainer [hidden]="dataItem.cantidad == 0">{{ secondsToHms(dataItem.tiempoEstimadoPreparacions + dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
                    <ngcontainer [hidden]="dataItem.cantidad > 0"> {{ secondsToHms(dataItem.tiempoEstimados * dataItem.cantidad) }}</ngcontainer>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cantidad" title="{{ 'restantes' | translate }}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-numerictextbox [(value)]="dataItem.cantidad" [format]="'n'" [min]="0" [max]="dataItem.total" [autoCorrect]="true" [step]="dataItem.cantidadAgrupada" class="numerico-soloflechas">
                    </kendo-numerictextbox>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                     filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                     filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                     filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                     filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                     filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                     filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                     filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                     filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                     filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                     filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                     filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                     filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                     filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                     filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                     filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                     filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                     filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                     filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                     filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                     groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                     noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </ng-template>
          </kendo-tabstrip-tab>
        <!-- </div> -->
        <!-- <div [hidden]="false || !this.JmaquinaSelected?.agruparProcesos"> -->
          <kendo-tabstrip-tab title="{{ 'grupos' | translate}}">
            <ng-template kendoTabContent>
              <kendo-grid [kendoGridBinding]="Jgrupos"
                          kendoGridSelectBy="numFila"
                          [selectedKeys]="gruposSelected"
                          [navigable]="true"
                          [sortable]="true"
                          [resizable]="true"
                          scrollable="virtual"
                          [rowHeight]="45.56"
                          filterable="menu"
                          [height]="300"
                          [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}">
                <kendo-grid-checkbox-column showSelectAll="true" width="5%%"></kendo-grid-checkbox-column>
                <kendo-grid-column field="nombre" title="{{ 'grupo' | translate }}" width="40%"></kendo-grid-column>
                <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="11%" [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem> ({{ dataItem.fechaIni | kendoDate }}) - {{dataItem.semana}} </ng-template></kendo-grid-column>
                <kendo-grid-column field="fechaLimite" title="{{ 'fechaentrega' | translate }}" width="10%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaLimite | kendoDate }}</ng-template></kendo-grid-column>
                <kendo-grid-column field="temperatura" title="{{ 'temperatura' | translate }}" width="8%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.temperatura }} º</ng-template></kendo-grid-column>
                <kendo-grid-column field="peso" title="{{ 'peso' | translate }}" width="8%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.peso }} kg</ng-template></kendo-grid-column>
                <kendo-grid-column field="unidades" title="{{ 'unidades' | translate }}" width="8%">
                  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.unidades }} u.</ng-template></kendo-grid-column>
                <kendo-grid-column field="tiempoEstimado" title="{{ 'tEstimado' | translate }}" width="7%" [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{ secondsToHms(dataItem.tiempoEstimado) }}</ng-template></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                     filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                     filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                     filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                     filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                     filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                     filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                     filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                     filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                     filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                     filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                     filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                     filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                     filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                     filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                     filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                     filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                     filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                     filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                     filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                     groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                     noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </ng-template>
          </kendo-tabstrip-tab>
        <!-- </div> -->
      </kendo-tabstrip>
    </div>

  </div>
</div>

<!-- POPUP: Reorganizar -->
<ng-template #popupReorganizar let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">
      <label>{{ 'prioridades' | translate }}</label>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- ROW 1 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadCliente" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadCliente" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 2 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadFacturacion" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <!--<p>{{ prioridadFacturacion }}</p>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadFacturacion" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 3 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadOF" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadOF" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 4 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadMaquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadMaquina" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 5 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadFecha" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadFecha" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 6 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="prioridadTemperatura" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" [(ngModel)]="prioridadTemperatura" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 7 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
        <div class="caja">
          <!--<kendo-numerictextbox [(value)]="percent_cap_maquina" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>-->
          <kendo-slider [vertical]="false" [showButtons]="false" [min]="0" [max]="maxCapacidadMaquina" [smallStep]="pasoCapacidadMaquina" [largeStep]="1" [(ngModel)]="percent_cap_maquina" class="w-100">
            <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
          </kendo-slider>
        </div>
      </div>
    </div>
    <!-- ROW 8 -->
    <div class="clearfix">
      <div class="form-group">
        <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
        <div class="caja">
          <kendo-numerictextbox [(value)]="dias_reserva" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          <div class="btn mr-1 ml-1" [class.btn-success]="replanificarTiempoEstimado" [class.btn-outline-success]="!replanificarTiempoEstimado">
            <input type="radio" name="informesGroup2" class="k-radio" [checked]="replanificarTiempoEstimado" (click)="btnReorganizarTiempoEstimado()" kendoRadioButton>
            <label class="k-radio-label" for="btnReorganizarTiempoEstimado">{{ 'estimado' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!replanificarTiempoEstimado" [class.btn-outline-success]="replanificarTiempoEstimado">
            <input type="radio" name="informesGroup2" class="k-radio" [checked]="!replanificarTiempoEstimado" (click)="btnReorganizarTiempoPredictivo()" kendoRadioButton>
            <label class="k-radio-label" for="btnReorganizarTiempoPredictivo">{{ 'predictivo' | translate }}</label>
          </div>
          <button type="button" class="btn mr-1" [class.btn-success]="replanificarIneficiencias"
                  [class.btn-outline-success]="!replanificarIneficiencias" (click)="btnReorganizarIneficiencia()">{{ 'ineficiencia' | translate }}</button>
        </div>
      </div>
    </div>
    <!-- ROW 9 -->
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="label-color1">{{ 'optimizarCuelloBotella' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="optimizarCuelloBotella" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
          <div class="caja">
            <kendo-dropdownlist [data]="metodologiasDePlanificacion"
                                [textField]="'nombre'"
                                [valueField]="'id'"
                                [(value)]="metodologiasDePlanificacionSelected"
                                style="width: 100px">
            </kendo-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <!-- ROW 10 -->    
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label class="label-color1">{{ 'planificarSoloNoPlanificadas' | translate }}</label>
          <div class="caja">
            <kendo-switch [(ngModel)]="planificarSoloNoPlanificadas" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group" *ngIf="metodologiasDePlanificacionSelected.id == 1">
          <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
          <div class="caja">
            <kendo-numerictextbox [(value)]="asap_maximoMesesParaIniciarAntesDeFechaEntrega" [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"></kendo-numerictextbox>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnReorganizarAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Reorganizando version -->
<div class="popup-cont " *ngIf="popupReorganizandoVersion">
  <div class="popup " style="width: 215px;">
    <div class="popup-header">
      <h3>
        <label>{{'reorganizandoVersion' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div class="clearfix text-center">
        <div class="position-relative" style="height:100px">
          <div class="k-i-loading" style="z-index: 3;"></div>
        </div>
      </div>
      <div>
        <label class="crontol-label m-1">{{ 'version' | translate }}</label>
        <div class="caja">
          <kendo-combobox [data]="Jplanificadores"
                          [textField]="'text'"
                          [valueField]="'value'"
                          [(value)]="JplanificadoresSelected"
                          (valueChange)="versionChanged($event)"
                          style="width: 100px"
                          [kendoDropDownFilter]="{operator: 'contains'}">
          </kendo-combobox>
        </div>
      </div>
    </div>
    <div class="popup-footer text-rigth">
      <button type="button" class="btn btn-primary" (click)="btnActualizarReorganizandoVersion()" [disabled]="this.user.planificadorLargo<2">{{ 'actualizar' | translate }}</button>
    </div>
  </div>
</div>

<!-- POPUP: Atrasar una semana FIJADOS tambien -->
<ng-template #popupAtrasarFijados let-modal>
  <div class="modal-header">
    <h3>
      <label>{{'atrasarUnaSemana' | translate }}</label>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'atrasarFijados' | translate }}</label>
    <div class="text-danger">
      <label>{{'accionNoReversible' | translate }}</label>
    </div>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger float-left" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="btnAtrasarFijadosCancelar()" [disabled]="this.user.planificadorLargo<2">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnAtrasarFijadosAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: MOVER OPERACION PARTIDA -->
<ng-template #popupOperacionPartida let-modal>
  <div class="modal-header">
    <h3>
      <!--<label>{{'titulo' | translate }}</label>-->
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'arrastrarOperacionRepartidaEnSemanas' | translate }}</label>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnOperacionPartidaAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: MOVER OPERACIONES -->
<ng-template #popupMoverOperaciones let-modal>
  <div class="modal-header">
    <h3>
      <!--<label>{{'titulo' | translate }}</label>-->
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'moverTodasOperacionesRelacionadas' | translate }}</label>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger" (click)="btnMoverOperacionesCancelar()">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="btnMoverOperacionesAceptar()" [disabled]="this.user.planificadorLargo<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: VETE AL CORTO -->
<ng-template #popupVeteAlCorto let-modal>
  <div class="modal-header">
    <h3>
      <!--<label>{{'titulo' | translate }}</label>-->
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'veteAlCorto' | translate }}</label>
  </div>
  <div class="modal-footer clear">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>