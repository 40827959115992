import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, MenuService, MaquinasService, UsuariosService, InformeProyectosService, RecetasInduccionService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { MyFunctions } from '@app/_helpers';
import { GroupResult } from '@progress/kendo-data-query';
import { ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-recetasInduccion',
  templateUrl: './recetasInduccion.component.html'
})
export class RecetasInduccionComponent implements OnInit {

  navigationSubscription;

  idof: any;
  idpieza: any;
  idparte: string;
  idruta: string;
  idOperacion: string;
  idVista: string;
  id: string;
  idMaquina: number;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  idReceta: number;

  // fechas seleccionadas en el filtro
  public fechaIni: Date;
  public fechaFin: Date;

  // GRID CICLOS
  ciclos: any;
  ciclosMap = new Map();
  seleccionados: any = [];
  seleccionadosMap = new Map();
  lastEditado: any = 'original';
  ciclosLag = [];
  ciclosOriginal = [];

  // GRID EDITAR RECETAS
  recetaInfReset: any;
  recetaInf: any = [
    { campo: "OP_activar_soplador_1", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_1", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_2", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_2", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_3", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_3", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_4", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_4", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_5", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_5", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_6", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_6", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_7", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_7", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_8", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_8", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_9", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_9", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_10", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_10", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_11", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_11", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_12", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_12", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_13", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_13", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_salida_cuba", bool: true, comprobarDiferencia: true },
    { campo: "SP_expulsion_salida_cuba_rapida", bool: false, comprobarDiferencia: true },
    { campo: "SP_expulsion_salida_cuba_lenta", bool: false, comprobarDiferencia: true },
    { campo: "OP_TMP_salida_vibrador_cuba_saturada", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_paso_pieza", bool: true, comprobarDiferencia: true },
    { campo: "SP_salida_paso_pieza", bool: false, comprobarDiferencia: true },
    { campo: "OP_rodillo_introductor", bool: true, comprobarDiferencia: true },
    { campo: "OP_giro_plato", bool: true, comprobarDiferencia: true },
    { campo: "TMP_giro_plato_inicial_giro", bool: false, comprobarDiferencia: true },
    { campo: "TMP_giro_plato_control", bool: false, comprobarDiferencia: true },
    { campo: "CV_proceso_giro", bool: false, comprobarDiferencia: true },
    { campo: "CV_rapida_giro", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_energia_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_energia", bool: false, comprobarDiferencia: false },
    { campo: "margen_energia", bool: false, comprobarDiferencia: true },
    { campo: "calculo_energia_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_energia_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_caudal_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_caudal_ducha", bool: false, comprobarDiferencia: true },
    { campo: "margen_caudal_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_caudal_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_caudal_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_temperatura_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_temperatura_ducha", bool: false, comprobarDiferencia: false },
    { campo: "margen_temperatura_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_temperatura_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_temperatura_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_presion_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_presion_ducha", bool: false, comprobarDiferencia: true },
    { campo: "margen_presion_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_presion_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_presion_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "GEN_configuracion_seleccionada", bool: true, comprobarDiferencia: true },
    { campo: "SP_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "SP_mantenimiento_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_lenta_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_recuperacion_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_vibrador_3", bool: false, comprobarDiferencia: true },
    { campo: "CV_cinta_salida", bool: false, comprobarDiferencia: true },
    { campo: "consigna_calor", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_rapida_subida_volteador", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_lenta_subida_volteador", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_bajada_volteador", bool: false, comprobarDiferencia: true },
    { campo: "SP_consigna_velocidad", bool: true, comprobarDiferencia: true },
    { campo: "OP_piezas_por_giro", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_anchura_expulsor", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_continuar_antes_parada_auto", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_continuar_calentado_en_parada", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_expulsion_defectuosos", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_expulsor", bool: false, comprobarDiferencia: true },
    { campo: "OP_Tmp_parada_atasco", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_vaciado_cinta_salida", bool: true, comprobarDiferencia: true },
    { campo: "Tmp_falta_Material_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_parada_control_falta_material", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_recuperacion_falta_material_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_filtro_defecto_material_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_presencia_material_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_peticion_pieza_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_presencia_pieza_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_vibrador_lineal_completo", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_def_material_m_alt_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_pres_material_m_alt_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "cantidadPiezasEsteCiclo", bool: false, comprobarDiferencia: true },
 ];
  lengthColumnas: number = 0;
  titulos = [];
  recetaInfLag: any = [
    { campo: "OP_activar_soplador_1", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_1", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_2", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_2", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_3", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_3", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_4", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_4", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_5", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_5", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_6", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_6", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_7", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_7", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_8", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_8", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_9", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_9", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_10", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_10", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_11", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_11", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_12", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_12", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_13", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_13", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_salida_cuba", bool: true, comprobarDiferencia: true },
    { campo: "SP_expulsion_salida_cuba_rapida", bool: false, comprobarDiferencia: true },
    { campo: "SP_expulsion_salida_cuba_lenta", bool: false, comprobarDiferencia: true },
    { campo: "OP_TMP_salida_vibrador_cuba_saturada", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_paso_pieza", bool: true, comprobarDiferencia: true },
    { campo: "SP_salida_paso_pieza", bool: false, comprobarDiferencia: true },
    { campo: "OP_rodillo_introductor", bool: true, comprobarDiferencia: true },
    { campo: "OP_giro_plato", bool: true, comprobarDiferencia: true },
    { campo: "TMP_giro_plato_inicial_giro", bool: false, comprobarDiferencia: true },
    { campo: "TMP_giro_plato_control", bool: false, comprobarDiferencia: true },
    { campo: "CV_proceso_giro", bool: false, comprobarDiferencia: true },
    { campo: "CV_rapida_giro", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_energia_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_energia", bool: false, comprobarDiferencia: false },
    { campo: "margen_energia", bool: false, comprobarDiferencia: true },
    { campo: "calculo_energia_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_energia_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_caudal_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_caudal_ducha", bool: false, comprobarDiferencia: true },
    { campo: "margen_caudal_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_caudal_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_caudal_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_temperatura_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_temperatura_ducha", bool: false, comprobarDiferencia: false },
    { campo: "margen_temperatura_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_temperatura_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_temperatura_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_presion_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_presion_ducha", bool: false, comprobarDiferencia: true },
    { campo: "margen_presion_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_presion_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_presion_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "GEN_configuracion_seleccionada", bool: true, comprobarDiferencia: true },
    { campo: "SP_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "SP_mantenimiento_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_lenta_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_recuperacion_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_vibrador_3", bool: false, comprobarDiferencia: true },
    { campo: "CV_cinta_salida", bool: false, comprobarDiferencia: true },
    { campo: "consigna_calor", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_rapida_subida_volteador", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_lenta_subida_volteador", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_bajada_volteador", bool: false, comprobarDiferencia: true },
    { campo: "SP_consigna_velocidad", bool: true, comprobarDiferencia: true },
    { campo: "OP_piezas_por_giro", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_anchura_expulsor", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_continuar_antes_parada_auto", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_continuar_calentado_en_parada", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_expulsion_defectuosos", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_expulsor", bool: false, comprobarDiferencia: true },
    { campo: "OP_Tmp_parada_atasco", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_vaciado_cinta_salida", bool: true, comprobarDiferencia: true },
    { campo: "Tmp_falta_Material_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_parada_control_falta_material", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_recuperacion_falta_material_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_filtro_defecto_material_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_presencia_material_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_peticion_pieza_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_presencia_pieza_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_vibrador_lineal_completo", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_def_material_m_alt_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_pres_material_m_alt_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "cantidadPiezasEsteCiclo", bool: false, comprobarDiferencia: true },
 ];
  recetasDif: any;
  mostrarTodo = true;
  recetaInfOriginal: any = [
    { campo: "OP_activar_soplador_1", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_1", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_2", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_2", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_3", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_3", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_4", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_4", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_5", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_5", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_6", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_6", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_7", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_7", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_8", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_8", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_9", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_9", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_10", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_10", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_11", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_11", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_12", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_12", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_soplador_13", bool: true, comprobarDiferencia: true },
    { campo: "SP_presion_soplador_13", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_salida_cuba", bool: true, comprobarDiferencia: true },
    { campo: "SP_expulsion_salida_cuba_rapida", bool: false, comprobarDiferencia: true },
    { campo: "SP_expulsion_salida_cuba_lenta", bool: false, comprobarDiferencia: true },
    { campo: "OP_TMP_salida_vibrador_cuba_saturada", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_paso_pieza", bool: true, comprobarDiferencia: true },
    { campo: "SP_salida_paso_pieza", bool: false, comprobarDiferencia: true },
    { campo: "OP_rodillo_introductor", bool: true, comprobarDiferencia: true },
    { campo: "OP_giro_plato", bool: true, comprobarDiferencia: true },
    { campo: "TMP_giro_plato_inicial_giro", bool: false, comprobarDiferencia: true },
    { campo: "TMP_giro_plato_control", bool: false, comprobarDiferencia: true },
    { campo: "CV_proceso_giro", bool: false, comprobarDiferencia: true },
    { campo: "CV_rapida_giro", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_energia_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_energia", bool: false, comprobarDiferencia: false },
    { campo: "margen_energia", bool: false, comprobarDiferencia: true },
    { campo: "calculo_energia_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_energia_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_caudal_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_caudal_ducha", bool: false, comprobarDiferencia: true },
    { campo: "margen_caudal_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_caudal_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_caudal_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_temperatura_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_temperatura_ducha", bool: false, comprobarDiferencia: false },
    { campo: "margen_temperatura_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_temperatura_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_temperatura_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "OP_control_presion_activado", bool: true, comprobarDiferencia: true },
    { campo: "consigna_presion_ducha", bool: false, comprobarDiferencia: true },
    { campo: "margen_presion_ducha", bool: false, comprobarDiferencia: true },
    { campo: "calculo_presion_ducha_maximo", bool: false, comprobarDiferencia: true },
    { campo: "calculo_presion_ducha_minimo", bool: false, comprobarDiferencia: true },
    { campo: "GEN_configuracion_seleccionada", bool: true, comprobarDiferencia: true },
    { campo: "SP_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "SP_mantenimiento_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_lenta_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_recuperacion_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "SP_vibrador_3", bool: false, comprobarDiferencia: true },
    { campo: "CV_cinta_salida", bool: false, comprobarDiferencia: true },
    { campo: "consigna_calor", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_rapida_subida_volteador", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_lenta_subida_volteador", bool: false, comprobarDiferencia: true },
    { campo: "velocidad_bajada_volteador", bool: false, comprobarDiferencia: true },
    { campo: "SP_consigna_velocidad", bool: true, comprobarDiferencia: true },
    { campo: "OP_piezas_por_giro", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_anchura_expulsor", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_continuar_antes_parada_auto", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_continuar_calentado_en_parada", bool: false, comprobarDiferencia: true },
    { campo: "OP_sector_expulsion_defectuosos", bool: false, comprobarDiferencia: true },
    { campo: "OP_activar_expulsor", bool: false, comprobarDiferencia: true },
    { campo: "OP_Tmp_parada_atasco", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_vaciado_cinta_salida", bool: true, comprobarDiferencia: true },
    { campo: "Tmp_falta_Material_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_parada_control_falta_material", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_recuperacion_falta_material_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_filtro_defecto_material_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_presencia_material_vibrador_2", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_peticion_pieza_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_presencia_pieza_vibrador_1", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_vibrador_lineal_completo", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_def_material_m_alt_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "Tmp_pres_material_m_alt_vibrador_lineal", bool: false, comprobarDiferencia: true },
    { campo: "cantidadPiezasEsteCiclo", bool: false, comprobarDiferencia: true },
 ];

  // Fechas para controlar el rango de si hacer o no consulta nueva
  public fechaIniUltimaConsulta: any;
  public fechaFinUltimaConsulta: any;

  // para controlar si es la primera consulta
  public esPrimeraConsulta: boolean = true;

  // Rango de ciclos
  value: any = [0, 600]; // [row.rangoCiclos.min, row.rangoCiclos.max]
  soloEdicion: any = false;

  vieneDeOperacionPred: boolean;

  public soloFueraParametros: boolean = false;

  user = this.userService.userValue;

  // VARIABLES input del filtro
  private dataFiltro: any;

  public opcionOf: any = [];

  //AREA PRODUCTIVA / SECCION
  public groupedSeccion: GroupResult[];
  //GRUPOS DE MAQUINAS
  public grupos: any;

  public fini: any;
  public ffin: any;
  public idcliente: any;
  // public idpieza: any;
  // public idof: any;
  public nSerie: any;
  // public idOperacion: any;

  public loadingDatos: boolean = false;
  public tieneDatos: boolean = true;

  status: boolean = true;
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
  // DATOS INICIALES DEL FILTRO
  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: ["fechaIni", "fechaFin"], tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: new Date(this.route.snapshot.params['fechaIni'].replace(/_/g, "-")), // this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: new Date(this.route.snapshot.params['fechaFin'].replace(/_/g, "-")), // this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 3, nombre: this.translateService.instant('of'), sqlfield: 'numeroOF', tipo: 'of' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 600, max: 700 },
            of: this.route.snapshot.params['numeroOF']
          },
          {
            columna: { id: 4, nombre: this.translateService.instant('rangoCiclos'), sqlfield: "row_num", tipo: 'rangoCiclos' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: this.value[0], max: this.value[1] },
            of: -1
          },
          {
            columna: { id: 5, nombre: this.translateService.instant('soloFueraParametro'), sqlfield: 'valorFueraLimites', tipo: 'fueraParametro' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 6, nombre: this.translateService.instant('soloConEdicionReceta'), tipo: 'edicionReceta' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 7, nombre: this.translateService.instant('soloConFalloCalidad'), tipo: 'falloCalidad' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 8, nombre: this.translateService.instant('soloConControlCalidad'), tipo: 'controlCalidad' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          }
        ]
      }
    ]
  }; 
  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };  
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },     
    { id: 3, nombre: this.translateService.instant('of'), field: "of", sqlfield: "hb.fechaTurno", tipo: 'of' },
    { id: 4, nombre: this.translateService.instant('rangoCiclos'), field: "rangoCiclos", sqlfield: "hb.fechaTurno", tipo: 'rangoCiclos' },
    { id: 5, nombre: this.translateService.instant('soloFueraParametro'), field: "soloFueraParametro", sqlfield: "hb.fechaTurno", tipo: 'soloFueraParametro' },
    { id: 6, nombre: this.translateService.instant('soloConEdicionReceta'), field: "soloConEdicionReceta", sqlfield: "hb.fechaTurno", tipo: 'soloConEdicionReceta' },
    { id: 7, nombre: this.translateService.instant('soloConFalloCalidad'), field: "soloConFalloCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConFalloCalidad' },
    { id: 8, nombre: this.translateService.instant('soloConControlCalidad'), field: "soloConControlCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConControlCalidad' },                      
    // { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },                 
    // { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "m.idSeccion", tipo: 'comboEstrincto' },              
    // { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },  
    // { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },             
    // { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },          
    // { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },                     
    // { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },      
    // { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },           
    // { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },                           
    // { id: 12, nombre: this.translateService.instant('parte'), field: "nombreParte", sqlfield: "do.nombreParte", tipo: 'comboFlexible' },           
    // { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
    // { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "do.operacionTerminada", tipo: 'check' }             
  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;
  //END FILTRO


  constructor(
    private recetasInduccionService: RecetasInduccionService,
    private route: ActivatedRoute,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private maquinasService: MaquinasService,
    private informeProyectosService: InformeProyectosService
  ) {

    this.menuService.titulo = this.translateService.instant('recetas').toUpperCase();
    this.cargar_Filtro();

  }

  ngOnInit() {
    this.idof = this.route.snapshot.params['idof'];
    this.idpieza = this.route.snapshot.params['idpieza'];
    this.idparte = this.route.snapshot.params['idparte'];
    this.idruta = this.route.snapshot.params['idruta'];
    this.idOperacion = this.route.snapshot.params['idOperacion'];
    this.idVista = this.route.snapshot.params['idVista'];
    this.id = this.route.snapshot.params['id'];
    this.idMaquina = this.route.snapshot.params['idMaquina'];
    this.isAddMode = !this.id;
    this.idReceta = parseInt(this.route.snapshot.params['idReceta']);

    this.vieneDeOperacionPred = !this.idof && !this.idpieza && !this.idparte && !this.idruta && !this.idVista;

    this.maquinasService.GetHornoById(this.idMaquina).pipe().subscribe((result) => {
      this.recetaInfReset = [
        { campo: "OP_activar_soplador_1", bool: true, ensennar: result.data[0].op_activar_soplador_1, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_1", bool: false, ensennar: result.data[0].sp_presion_soplador_1, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_2", bool: true, ensennar: result.data[0].op_activar_soplador_2, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_2", bool: false, ensennar: result.data[0].sp_presion_soplador_2, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_3", bool: true, ensennar: result.data[0].op_activar_soplador_3, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_3", bool: false, ensennar: result.data[0].sp_presion_soplador_3, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_4", bool: true, ensennar: result.data[0].op_activar_soplador_4, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_4", bool: false, ensennar: result.data[0].sp_presion_soplador_4, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_5", bool: true, ensennar: result.data[0].op_activar_soplador_5, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_5", bool: false, ensennar: result.data[0].sp_presion_soplador_5, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_6", bool: true, ensennar: result.data[0].op_activar_soplador_6, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_6", bool: false, ensennar: result.data[0].sp_presion_soplador_6, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_7", bool: true, ensennar: result.data[0].op_activar_soplador_7, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_7", bool: false, ensennar: result.data[0].sp_presion_soplador_7, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_8", bool: true, ensennar: result.data[0].op_activar_soplador_8, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_8", bool: false, ensennar: result.data[0].sp_presion_soplador_8, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_9", bool: true, ensennar: result.data[0].op_activar_soplador_9, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_9", bool: false, ensennar: result.data[0].sp_presion_soplador_9, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_10", bool: true, ensennar: result.data[0].op_activar_soplador_10, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_10", bool: false, ensennar: result.data[0].sp_presion_soplador_10, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_11", bool: true, ensennar: result.data[0].op_activar_soplador_11, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_11", bool: false, ensennar: result.data[0].sp_presion_soplador_11, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_12", bool: true, ensennar: result.data[0].op_activar_soplador_12, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_12", bool: false, ensennar: result.data[0].sp_presion_soplador_12, comprobarDiferencia: true },
        { campo: "OP_activar_soplador_13", bool: true, ensennar: result.data[0].op_activar_soplador_13, comprobarDiferencia: true },
        { campo: "SP_presion_soplador_13", bool: false, ensennar: result.data[0].sp_presion_soplador_13, comprobarDiferencia: true },
        { campo: "OP_activar_salida_cuba", bool: true, ensennar: result.data[0].op_activar_salida_cuba, comprobarDiferencia: true },
        { campo: "SP_expulsion_salida_cuba_rapida", bool: false, ensennar: result.data[0].sp_expulsion_salida_cuba_rapida, comprobarDiferencia: true },
        { campo: "SP_expulsion_salida_cuba_lenta", bool: false, ensennar: result.data[0].sp_expulsion_salida_cuba_lenta, comprobarDiferencia: true },
        { campo: "OP_TMP_salida_vibrador_cuba_saturada", bool: false, ensennar: result.data[0].op_tmp_salida_vibrador_cuba_saturada, comprobarDiferencia: true },
        { campo: "OP_control_paso_pieza", bool: true, ensennar: result.data[0].op_control_paso_pieza, comprobarDiferencia: true },
        { campo: "SP_salida_paso_pieza", bool: false, ensennar: result.data[0].sp_salida_paso_pieza, comprobarDiferencia: true },
        { campo: "OP_rodillo_introductor", bool: true, ensennar: result.data[0].op_rodillo_introductor, comprobarDiferencia: true },
        { campo: "OP_giro_plato", bool: true, ensennar: result.data[0].op_giro_plato, comprobarDiferencia: true },
        { campo: "TMP_giro_plato_inicial_giro", bool: false, ensennar: result.data[0].tmp_giro_plato_inicial_giro, comprobarDiferencia: true },
        { campo: "TMP_giro_plato_control", bool: false, ensennar: result.data[0].tmp_giro_plato_control, comprobarDiferencia: true },
        { campo: "CV_proceso_giro", bool: false, ensennar: result.data[0].cv_proceso_giro, comprobarDiferencia: true },
        { campo: "CV_rapida_giro", bool: false, ensennar: result.data[0].cv_rapida_giro, comprobarDiferencia: true },
        { campo: "OP_control_energia_activado", bool: true, ensennar: result.data[0].op_control_energia_activado, comprobarDiferencia: true },
        { campo: "consigna_energia", bool: false, ensennar: result.data[0].consigna_energia, comprobarDiferencia: false },
        { campo: "margen_energia", bool: false, ensennar: result.data[0].margen_energia, comprobarDiferencia: true },
        { campo: "calculo_energia_maximo", bool: false, ensennar: result.data[0].calculo_energia_pos, comprobarDiferencia: true },
        { campo: "calculo_energia_minimo", bool: false, ensennar: result.data[0].calculo_energia_neg, comprobarDiferencia: true },
        { campo: "OP_control_caudal_activado", bool: true, ensennar: result.data[0].op_control_caudal_activado, comprobarDiferencia: true },
        { campo: "consigna_caudal_ducha", bool: false, ensennar: result.data[0].consigna_caudal_ducha, comprobarDiferencia: true },
        { campo: "margen_caudal_ducha", bool: false, ensennar: result.data[0].margen_caudal_ducha, comprobarDiferencia: true },
        { campo: "calculo_caudal_ducha_maximo", bool: false, ensennar: result.data[0].calculo_caudal_ducha_pos, comprobarDiferencia: true },
        { campo: "calculo_caudal_ducha_minimo", bool: false, ensennar: result.data[0].calculo_caudal_ducha_neg, comprobarDiferencia: true },
        { campo: "OP_control_temperatura_activado", bool: true, ensennar: result.data[0].op_control_temperatura_activado, comprobarDiferencia: true },
        { campo: "consigna_temperatura_ducha", bool: false, ensennar: result.data[0].consigna_temperatura_ducha, comprobarDiferencia: false },
        { campo: "margen_temperatura_ducha", bool: false, ensennar: result.data[0].margen_temperatura_ducha, comprobarDiferencia: true },
        { campo: "calculo_temperatura_ducha_maximo", bool: false, ensennar: result.data[0].calculo_temperatura_ducha_pos, comprobarDiferencia: true },
        { campo: "calculo_temperatura_ducha_minimo", bool: false, ensennar: result.data[0].calculo_temperatura_ducha_neg, comprobarDiferencia: true },
        { campo: "OP_control_presion_activado", bool: true, ensennar: result.data[0].op_control_presion_activado, comprobarDiferencia: true },
        { campo: "consigna_presion_ducha", bool: false, ensennar: result.data[0].consigna_presion_ducha, comprobarDiferencia: true },
        { campo: "margen_presion_ducha", bool: false, ensennar: result.data[0].margen_presion_ducha, comprobarDiferencia: true },
        { campo: "calculo_presion_ducha_maximo", bool: false, ensennar: result.data[0].calculo_presion_ducha, comprobarDiferencia: true },
        { campo: "calculo_presion_ducha_minimo", bool: false, ensennar: result.data[0].calculo_presion_ducha, comprobarDiferencia: true },
        { campo: "GEN_configuracion_seleccionada", bool: true, ensennar: result.data[0].gen_configuracion_seleccionada, comprobarDiferencia: true },
        { campo: "SP_vibrador_1", bool: false, ensennar: result.data[0].sp_vibrador_1, comprobarDiferencia: true },
        { campo: "SP_mantenimiento_vibrador_2", bool: false, ensennar: result.data[0].sp_mantenimiento_vibrador_2, comprobarDiferencia: true },
        { campo: "SP_lenta_vibrador_2", bool: false, ensennar: result.data[0].sp_lenta_vibrador_2, comprobarDiferencia: true },
        { campo: "SP_recuperacion_vibrador_2", bool: false, ensennar: result.data[0].sp_recuperacion_vibrador_2, comprobarDiferencia: true },
        { campo: "SP_vibrador_3", bool: false, ensennar: result.data[0].sp_vibrador_3, comprobarDiferencia: true },
        { campo: "CV_cinta_salida", bool: false, ensennar: result.data[0].cv_cinta_salida, comprobarDiferencia: true },
        { campo: "consigna_calor", bool: false, ensennar: result.data[0].consigna_calor, comprobarDiferencia: true },
        { campo: "velocidad_rapida_subida_volteador", bool: false, ensennar: result.data[0].velocidad_rapida_subida_volteador, comprobarDiferencia: true },
        { campo: "velocidad_lenta_subida_volteador", bool: false, ensennar: result.data[0].velocidad_lenta_subida_volteador, comprobarDiferencia: true },
        { campo: "velocidad_bajada_volteador", bool: false, ensennar: result.data[0].velocidad_bajada_volteador, comprobarDiferencia: true },
        { campo: "SP_consigna_velocidad", bool: true, ensennar: result.data[0].sp_consigna_velocidad, comprobarDiferencia: true },
        { campo: "OP_piezas_por_giro", bool: false, ensennar: result.data[0].op_piezas_por_giro, comprobarDiferencia: true },
        { campo: "OP_sector_anchura_expulsor", bool: false, ensennar: result.data[0].op_sector_anchura_expulsor, comprobarDiferencia: true },
        { campo: "OP_sector_continuar_antes_parada_auto", bool: false, ensennar: result.data[0].op_sector_continuar_antes_parada_auto, comprobarDiferencia: true },
        { campo: "OP_sector_continuar_calentado_en_parada", bool: false, ensennar: result.data[0].op_sector_continuar_calentado_en_parada, comprobarDiferencia: true },
        { campo: "OP_sector_expulsion_defectuosos", bool: false, ensennar: result.data[0].op_sector_expulsion_defectuosos, comprobarDiferencia: true },
        { campo: "OP_activar_expulsor", bool: false, ensennar: result.data[0].op_activar_expulsor, comprobarDiferencia: true },
        { campo: "OP_Tmp_parada_atasco", bool: false, ensennar: result.data[0].op_tmp_parada_atasco, comprobarDiferencia: true },
        { campo: "Tmp_vaciado_cinta_salida", bool: true, ensennar: result.data[0].tmp_vaciado_cinta_salida, comprobarDiferencia: true },
        { campo: "Tmp_falta_Material_vibrador_lineal", bool: false, ensennar: result.data[0].tmp_falta_material_vibrador_lineal, comprobarDiferencia: true },
        { campo: "Tmp_parada_control_falta_material", bool: false, ensennar: result.data[0].tmp_parada_control_falta_material, comprobarDiferencia: true },
        { campo: "Tmp_recuperacion_falta_material_lineal", bool: false, ensennar: result.data[0].tmp_recuperacion_falta_material_lineal, comprobarDiferencia: true },
        { campo: "Tmp_filtro_defecto_material_vibrador_2", bool: false, ensennar: result.data[0].tmp_filtro_defecto_material_vibrador_2, comprobarDiferencia: true },
        { campo: "Tmp_presencia_material_vibrador_2", bool: false, ensennar: result.data[0].tmp_presencia_material_vibrador_2, comprobarDiferencia: true },
        { campo: "Tmp_peticion_pieza_vibrador_1", bool: false, ensennar: result.data[0].tmp_peticion_pieza_vibrador_1, comprobarDiferencia: true },
        { campo: "Tmp_presencia_pieza_vibrador_1", bool: false, ensennar: result.data[0].tmp_presencia_pieza_vibrador_1, comprobarDiferencia: true },
        { campo: "Tmp_vibrador_lineal_completo", bool: false, ensennar: result.data[0].tmp_vibrador_lineal_completo, comprobarDiferencia: true },
        { campo: "Tmp_def_material_m_alt_vibrador_lineal", bool: false, ensennar: result.data[0].tmp_def_material_m_alt_vibrador_lineal, comprobarDiferencia: true },
        { campo: "Tmp_pres_material_m_alt_vibrador_lineal", bool: false, ensennar: result.data[0].tmp_pres_material_m_alt_vibrador_lineal, comprobarDiferencia: true },
        { campo: "cantidadPiezasEsteCiclo", bool: false, ensennar: true, comprobarDiferencia: true },
];
      this.recetaInfReset = this.recetaInfReset.filter(f => f.ensennar);
      this.recetaInf = this.myFunctions.copy(this.recetaInfReset);
      this.recetaInfOriginal = this.myFunctions.copy(this.recetaInfReset);
      this.recetaInfLag = this.myFunctions.copy(this.recetaInfReset);

      this.cargarDatos();   
    });
  
  }

  // cargar datos
  cargarDatos() {
    this.loading = true;
    // PARAMS
    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;

    var r1, r2: boolean = false;
    this.recetasInduccionService.Get_receta_original(idHistorico_operaciones).subscribe((result) => {
      this.getRecetaOriginal(result);     
      r1 = true;
      if (r1 && r2) {
        this.crearMostrarTodos();
        this.ciclosGrid();
      } 
    });

    this.recetasInduccionService.Get_historico_recetas(idHistorico_operaciones).subscribe((result) => {
      this.getHistoricoRecetas(result);
      r2 = true;
      if (r1 && r2) {
        this.crearMostrarTodos();
        this.ciclosGrid();
      }

    });

  }

  // get receta original
  getRecetaOriginal(result) {
    // convertir en minuscula todas las keys
    this.lastEditado = "original";
    result[0] = this.toLowerKeys(result[0]);
    this.recetaInfOriginal.forEach(row => {
      row["original"] = result[0][row["campo"].toLowerCase()];
    });
  }

  // get todas las recetas
  getHistoricoRecetas(result) {
    this.ciclos = result.sort(function(a,b) {return a.row_num - b.row_num});
    this.ciclos.forEach(row => {
      row['nombre'] = this.translateService.instant('ciclo') + " " + row["row_num"];
      row['number'] = row["row_num"];
      row['dif'] = false;
      row['edited'] = false;
      row['metido'] = false;
      row['calidad'] = false;
      this.ciclosMap.set(row["id"], row);

      // of combo
      if (this.opcionOf.indexOf(row['numeroOF']) == -1) this.opcionOf.push(row['numeroOF']);

    });
    this.ciclosOriginal = this.ciclos;

    // al principio ensennar todos los ciclos en el filtro
    this.value = [0, this.ciclosMap.size];
    // cargar las fechas dependiendo de los ciclos cargardos 
    this.datosFiltro.group[0].group[0].fechaIni = new Date(this.ciclos[0].fechaIni);
    this.datosFiltro.group[0].group[0].fechaFin = this.myFunctions.dateAddDays(new Date(this.ciclos[this.ciclosMap.size - 1].fechaFin), 1);

    if (this.esPrimeraConsulta) {
      this.fechaIniUltimaConsulta = new Date(this.ciclos[0].fechaIni);
      this.fechaFinUltimaConsulta = this.myFunctions.dateAddDays(new Date(this.ciclos[this.ciclosMap.size - 1].fechaFin), 1);
      this.esPrimeraConsulta = false;
    }

    // Calcular media
    this.recetaInfLag.forEach(row => {
      var total = 0;
      this.ciclos.forEach(element => {
        element = this.toLowerKeys(element);
        row["ciclo"+row["row_num"]] = element[row["campo"].toLowerCase()];
        total += row["ciclo"+row["row_num"]];
      });
      if (this.ciclos.length != 0) row["media"] = Math.round(total / this.ciclos.length * 100) / 100;
      else row["media"] = "-";
    });  
    
    var j = 0
    this.recetaInfOriginal.forEach(element => {
      element["media"] = this.recetaInfLag[j]["media"];
      j++
    });
  }
  
  // Reiniciar los parametros
  reiniciarParametros(json) {
    // GRID CICLOS
    this.ciclosMap = new Map();
    this.seleccionados = [];
    this.seleccionadosMap = new Map();
    this.lastEditado = "original";
    this.ciclosLag = [];
    this.ciclosOriginal = [];

    // GRID EDITAR RECETAS
    this.lengthColumnas = 0;
    this.titulos = [];
    this.recetaInf = this.myFunctions.copy(this.recetaInfReset);
    this.recetaInfOriginal = this.myFunctions.copy(this.recetaInfReset);
    this.recetaInfLag = this.myFunctions.copy(this.recetaInfReset);

    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;
    
    this.recetasInduccionService.Get_receta_original(idHistorico_operaciones).subscribe((result) => {
      this.getRecetaOriginal(result);   
      this.getHistoricoRecetas(json);
      this.crearMostrarTodos();
      this.ciclosGrid();  
    });
  }

  updateRangoCiclos(value) {
    this.value = [parseInt(value[0]), parseInt(value[1])];
  }

  onChange(e = null) {
    var grid = [];
    this.titulos = [];
    this.seleccionados.forEach(row => {
      grid.push(this.ciclosMap.get(row));
    });

    grid.forEach(row => {
      this.titulos.push(row["number"]);
    })

    this.recetaInfOriginal.forEach(row => {
      var j = 0;
      grid.forEach(element => {
        element = this.toLowerKeys(element);
        row["ciclo"+j] = element[row["campo"].toLowerCase()];
        j++;
      });
    });
    this.lengthColumnas = this.seleccionados.length;
    this.crearMostrarTodos();
  }

  toLowerKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  // Esta funcion crea las figuras del grid del ciclo, es decir, saber si la receta del ciclo esta editada o es diferente de la original
  ciclosGrid() {

    // receta original == receta
    var recetaLag = this.recetaInfOriginal
    recetaLag.forEach(row => {
      this.ciclosMap.forEach(element => {
        element = this.toLowerKeys(element);
        row[element["number"]] = element[row["campo"].toLowerCase()];
      });
    });
    recetaLag.forEach(row => {
      let original = row["original"];
      // if (row.campo != 'consigna_energia') {
      if (row.comprobarDiferencia) {

        for (var key in row) {
          if (key != "campo" && key != "media" && key != "original" && key != "bool") {
            let value = row[key];
            if (value != original) {
              this.cicloDiferenteAlOriginal(key)
            }
          }
        }
      } else {
        console.log(row);
      }
    });

    // receta editada
    this.ciclosLag = []
    for (var j = 0; j<this.ciclos.length; j++) {
      recetaLag.forEach(row => {
        // if (row.campo != 'consigna_energia') {
        if (row.comprobarDiferencia) {

          let original = row[this.lastEditado];
          if (row[this.ciclos[j]["row_num"]] != original) {
            this.lastEditado = this.ciclos[j]["row_num"];
            this.ciclos[j]["edited"] = true;
            if (!this.ciclos[j]["metido"]) {
              this.ciclos[j]["metido"] = true;
              this.ciclosLag.push(this.ciclos[j]);
            }
          }
        }
      });
    }

    if (this.soloEdicion) this.ciclos = this.ciclosLag;
    else this.ciclos = this.ciclosOriginal;

    if (this.soloFueraParametros)
      this.ciclos = this.ciclos.filter(f=> f.dif == true);

    // para seleccionar el ciclo seleccionado en la pagina de historico proceso
    if (this.ciclosMap.get(this.idReceta) != undefined) {
      this.seleccionados.push(this.idReceta);
      this.onChange(); 
    }

    this.loading = false;
  }

  cicloDiferenteAlOriginal(row_num_ciclo) {
    this.ciclos.forEach(element => {
      if (element["row_num"] == row_num_ciclo) {
        element['dif'] = true;
        return;
      }
    });
  }

  crearMostrarTodos() {
    this.recetaInf = this.recetaInfOriginal;
    this.recetasDif = [];

    // Comprobar si es igual que la receta original
    this.recetaInfOriginal.forEach(row => {
      let dif = false;
      let original = row["original"];
      let campo = row["campo"];
      // if (campo != 'consigna_energia') {
      if (row.comprobarDiferencia) {
        for (var key in row) {
          if (key != "campo" && key != "media" && key != "original" && key != "bool") {
            let value = row[key];
            if (value != original) {
              dif = true;
              row["dif"] = true;
              break;
            }
          }
        }
        if (dif) {
          this.recetasDif.push(row);
        }
      } else {
        console.log(row);
      }
      
    });
    if (this.mostrarTodo) this.recetaInf = this.recetaInfOriginal;
    else this.recetaInf = this.recetasDif;
  }

  mostrarTodos() {
    var btn = document.getElementById("btn-mostrarTodos");
    var i = document.getElementById("i-mostrarTodos");
    if (this.mostrarTodo) {
      this.recetaInf = this.recetasDif;
      btn.className = "btn";
      i.className = " icon-check"
      this.mostrarTodo = false;
    } else {
      this.recetaInf = this.recetaInfOriginal;
      btn.className = "btn btn-success";
      i.className = " icon-equis"
      this.mostrarTodo = true;
    }
  }


  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  //FILTRO
  borrarFiltro() {
    this.value = [0, this.ciclosMap.size];
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -6));
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow());
    this.cargarDatos();
    
  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo() {
    var em = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          // public opcionDate = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            // if (jFiltro.operator.id == 7) {
            //   sqlFilter = jFiltro.columna.sqlfield[0] + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield[1] + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            // }
            // else if (jFiltro.operator.id == 8) {
            //   sqlFilter = jFiltro.columna.sqlfield[0] + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield[1] + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            // }
            this.fechaIni = jFiltro.fechaIni;
            this.fechaFin = jFiltro.fechaFin;
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield[0] + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield[0] + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield[0] + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield[0] + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield[0] + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield[0] + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // OF
        if (jFiltro.columna.tipo == 'of') {
            sqlFilter = jFiltro.columna.sqlfield + " = " + jFiltro.of;
        }
        // RANGO CICLOS
        if (jFiltro.columna.tipo == 'rangoCiclos') {
          sqlFilter = jFiltro.columna.sqlfield + " BETWEEN " + this.value[0] + " AND " + this.value[1];
        }
        // FUERA DE PARAMETROS
        if (jFiltro.columna.tipo == 'fueraParametro') {
          if (jFiltro.fueraParametro) this.soloFueraParametros = true; //sqlFilter = jFiltro.columna.sqlfield + " >= 1 ";
          else this.soloFueraParametros = false; //sqlFilter = jFiltro.columna.sqlfield + " = 0";
          
        }
        // EDICION DE RECETA
        if (jFiltro.columna.tipo == 'edicionReceta') {
          this.soloEdicion = jFiltro.conEdicionReceta;
        }

        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 7);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
      { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
      { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
      { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
    ];


    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES
    
    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        { id: 3, nombre: this.translateService.instant('of'), field: "of", sqlfield: "hb.fechaTurno", tipo: 'of' },
        { id: 4, nombre: this.translateService.instant('rangoCiclos'), field: "rangoCiclos", sqlfield: "hb.fechaTurno", tipo: 'rangoCiclos' },
        { id: 5, nombre: this.translateService.instant('soloFueraParametro'), field: "soloFueraParametro", sqlfield: "hb.fechaTurno", tipo: 'soloFueraParametro' },
        { id: 6, nombre: this.translateService.instant('soloConEdicionReceta'), field: "soloConEdicionReceta", sqlfield: "hb.fechaTurno", tipo: 'soloConEdicionReceta' },
        { id: 7, nombre: this.translateService.instant('soloConFalloCalidad'), field: "soloConFalloCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConFalloCalidad' },
        { id: 8, nombre: this.translateService.instant('soloConControlCalidad'), field: "soloConControlCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConControlCalidad' },
        // { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
        // { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "m.idSeccion", tipo: 'comboEstrincto' },
        // { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
        // { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
        // { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
        // { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },
        // { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },
        // { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },
        // { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
        // { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
        // { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "do.operacionTerminada", tipo: 'check' }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      
  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id == 3) { // turnos
        row.combo = this.filtro_listaTurnos;
      }
      else if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // operarios 
        row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.nombreOf)) {
              lag.push(of.nombreOf);
              var js = { id: of.nombreOf, nombre: of.nombreOf };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
              lag.push(operacion.nombreOperacion);
              var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {

    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;
    var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    var filtroCompleto: any = this.filtroToSQL(); // filtro completo
    this.status = true;
    this.actualizarVisible = false;

    this.loading = true;

    var fechaInicio = this.datosFiltro.group[0].group[0].fechaIni
    var fechaFin = this.datosFiltro.group[0].group[0].fechaFin

    if (fechaInicio < this.fechaIniUltimaConsulta || fechaFin > this.fechaFinUltimaConsulta) { // hay que hacer la consulta
      this.fechaIniUltimaConsulta = fechaInicio;
      this.fechaFinUltimaConsulta = fechaFin;
      
      this.recetasInduccionService.Get_historico_recetas_filtrado(idHistorico_operaciones,this.fechaIni, this.fechaFin, filtroCompleto).subscribe((result) => {
        this.reiniciarParametros(result);    
      });
    } else {
      this.ciclos = this.ciclosOriginal.filter(f => {
        var inRango = this.value[0] <= f.row_num && f.row_num <= this.value[1];
        var isOf = f.numeroOF == this.datosFiltro.group[0].group[1].of;

        var edicion;
        if (this.soloEdicion) {
          edicion = f.edited == true
        } else {
          edicion = true;
        }

        var fueraParametro;
        if (this.soloFueraParametros) {
          fueraParametro = f.dif == true;
        } else {
          fueraParametro = true
        }

        if (inRango && isOf && edicion && fueraParametro) {
          return true;
        }
      });

      this.loading = false;
    }

  }
  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
      // Historico Piezas:
      //   - fechaIni , fechaFin *fijo*
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

    }
    else {
      // Informe Proyectos:
      //   - fechaIni , fechaFin *fijo*
      //   - idcliente (posible extra)
      //   - idpieza (posible extra)
      //   - idof (posible extra)
      //   - terminados (posible extra)
      // FECHAS (INICIO, FIN)
      /**/this.fini = this.route.snapshot.params['fini'];
      /**/this.ffin = this.route.snapshot.params['ffin'];
      if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
        && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
      // OF
      /**/this.idof = Number.parseInt(this.route.snapshot.params['idof']);
      var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idof']);
      if (rowOFs.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
      }
      // CLIENTE
      /**/this.idcliente = Number.parseInt(this.route.snapshot.params['idcliente']);
      // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
      var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idcliente']);
      if (rowClientes.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
      }
      // PIEZA
      /**/this.idpieza = Number.parseInt(this.route.snapshot.params['idpieza']);
      var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idpieza']);
      if (rowPiezas.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
      }
      // TERMINADO 
      // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
      // * solo lo voy a filtrar si es 1
      /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
      if (this.route.snapshot.params['terminados'] == '1') {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable: false,
          tipoNoVisible: false,
          fueraParametro: false,
          conEdicionReceta: false,
          conFalloCalidad: false,
          conControlCalidad: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
          operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable: false,
          tipoNoVisible: false, 
          fueraParametro: false,
          conEdicionReceta: false,
          conFalloCalidad: false,
          conControlCalidad: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  // END FILTRO

}
