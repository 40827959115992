import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, CalendariosService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';




@Component({
  selector: 'app-calendariosDetalle',
  templateUrl: 'eskola_calendariosDetalle.component.html',
})
export class CalendariosDetalleComponent implements OnInit {
  navigationSubscription;
  private translate: TranslateService;

  form: FormGroup;

  isAddMode: boolean;
  loading = false;
  submitted = false;

  user = this.userService.userValue;

  public id: number;
  public nombre: string;
  public activo: boolean;
  public diasActivos;
  public diasInactivos;
  public lunes = false;
  public martes = false;
  public miercoles = false;
  public jueves = false;
  public viernes = false;
  public sabado = false;
  public domingo = false;
  public year = false;
  public calendarioInicio:Date;
  public calendarioFin:Date;
  public listItemsCalendario: any;
  public calendarioSelected: any;
  public calendarioSelectedModal: any;
  public ultimoId: number;
  public DaysInMonths;
  public calendario;
  public mostrarCalendario: boolean = false;
  public requiereNombre:boolean = true;
  public requiereFechas:boolean = true;


  @ViewChild('popupCopiar') popupCopiar: NgbModalRef;
  modalReference: NgbModalRef;

  constructor(
    private myFunctions: MyFunctions,
    private eskolaCalendariosService: CalendariosService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private alertService: AlertService,
    private userService: UsuariosService,
    private menuService: MenuService,
    private modalService: NgbModal
  ) {

    this.menuService.titulo = this.translateService.instant('calendarios').toUpperCase();

  }
 /*
  ngAfterViewInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.cargarMeses();
    this.cargarCalendario();
  }*/
  
  ngOnInit() {
    //como aqui se cargan mas cosas que solo los datos ponemos la carga desde el inicio
    //this.loadingPanel = true
    this.id = this.route.snapshot.params['id'];
    if(this.nombre ==undefined){
      this.nombre ="";
    }
    if(this.id==undefined){
      this.isAddMode=true;
      this.requiereNombre=true;
      this.requiereFechas = true;
    }
    this.cargarMeses();
    this.cargarCalendario();
    this.requiereNombre=false;
    this.requiereFechas = false;
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: new FormControl('', [Validators.required, Validators.maxLength(200)]),
  });   

  }
  cargarCalendario() {
    //if (this.id != 0) {
      this.eskolaCalendariosService.getById(this.id).subscribe(
        (json) => {


          this.calendario = json[0];
          this.calendario.mostrarCalendario = false;
          this.nombre = json[0].nombre;
          this.activo = json[0].activo;
          this.lunes = json[0].dia1;
          this.martes = json[0].dia2;
          this.miercoles = json[0].dia3
          this.jueves = json[0].dia4;
          this.viernes = json[0].dia5;
          this.sabado = json[0].dia6;
          this.domingo = json[0].dia7;
          this.calendarioInicio = new Date(json[0].inicio);
          this.calendarioFin = new Date(json[0].fin);
        });
    
    //}
    this.listItemsCalendario = [];
    this.eskolaCalendariosService.getAll().subscribe(
      (json: any) => {
        if (json.length > 0) {
          json.forEach(element => {
            this.listItemsCalendario.push({ id: element.id, nombre: element.nombre });

          });
          this.calendarioSelected = this.listItemsCalendario[0].id;
          this.ultimoId = this.listItemsCalendario[this.listItemsCalendario.length - 1].id;
        }

      });

  }

  actualizarDiasInactivos(valueEmitted) {
    if (this.diasInactivos != valueEmitted) {
      this.diasInactivos = valueEmitted;
    }
  }

  actualizarDiasActivos(valueEmitted) {
    if (this.diasActivos != valueEmitted) {
      this.diasActivos = valueEmitted;
    }
  }
  actualizarAno(valueEmitted) {
    if (this.year != valueEmitted) {
      this.year = valueEmitted;
    }
  }
  actualizarFechaInicio(valueEmitted) {
    if (this.calendarioInicio != valueEmitted) {
      this.calendarioInicio = valueEmitted;
    
    }
  }
  actualizarFechaFin(valueEmitted) {
    if (this.calendarioFin != valueEmitted) {
      this.calendarioFin = valueEmitted;
 
    }
  }
  cambioLunes() {
    this.lunes = !this.lunes;

  }
  cambioMartes() {
    this.martes = !this.martes;
  }
  cambioMiercoles() {
    this.miercoles = !this.miercoles;
  }
  cambioJueves() {
    this.jueves = !this.jueves;

  }
  cambioViernes() {
    this.viernes = !this.viernes;

  }
  cambioSabado() {
    this.sabado = !this.sabado;
  
  }

  cambioDomingo() {
    this.domingo = !this.domingo;
  }

  cambioActivo() {
    this.activo = !this.activo;

  }
  onClickCopiarCalendario() {
    this.calendarioSelected = this.calendarioSelectedModal;
    this.modalReference = this.modalService.open(this.popupCopiar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnAceptar() {
    this.calendarioSelected = this.calendarioSelectedModal;
    this.eskolaCalendariosService.getById(this.calendarioSelected).subscribe(
      (calendarioSelected: any) => {
        if (calendarioSelected.length === 0) return;
        console.log(calendarioSelected);
        console.log(calendarioSelected[0].inicio);
        this.eskolaCalendariosService.insertEskolaCalendario(true,
          calendarioSelected[0].nombre, calendarioSelected[0].inicio, calendarioSelected[0].fin, calendarioSelected[0].activo,
          calendarioSelected[0].dia1, calendarioSelected[0].dia2, calendarioSelected[0].dia3, calendarioSelected[0].dia4,
          calendarioSelected[0].dia5, calendarioSelected[0].dia6, calendarioSelected[0].dia7).subscribe((result=>{
            if(result.error){
              this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
              this.router.navigate(['calendarios']);  
            }else{
              this.alertService.success(this.translateService.instant('duplicadocorrecto'), { keepAfterRouteChange: true });
              this.router.navigate(['calendarios']);  
            }
          }));
    
      });
    //guardamos los periodos
    this.eskolaCalendariosService.getPeriodosDeCalendario(this.calendarioSelected).subscribe(
      (json: any) => {
        if (json.length > 0) {
          json.forEach(element => {
            var aux1: string = element.fechainicio
            var aux12: string = aux1.split('T')[0];
            var aux2: string= element.fechafin;
            var aux22: string = aux2.split('T')[0];
            let auxFechaIni = new Date(Number(aux12.split('-')[0]), Number(aux12.split('-')[1]) - 1, Number(aux12.split('-')[2]));
            let auxFechaFin = new Date(Number(aux22.split('-')[0]), Number(aux22.split('-')[1]) - 1, Number(aux22.split('-')[2]));

            this.eskolaCalendariosService.insertEskolaCalendarioPeriodos(this.ultimoId + 1, element.ano ,auxFechaIni,auxFechaFin, element.activo).subscribe((result=>{
              if(result.error){
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                this.router.navigate(['calendarios']);  
              }else{
                this.alertService.success(this.translateService.instant('duplicadocorrecto'), { keepAfterRouteChange: true });
                this.router.navigate(['calendarios']);  
              }
            }));
          });

        }

      });
    /*Gaudra los nuevos checkes 
    this.eskolaCalendariosService.updateReglaCalendario1(this.year, this.comedorSelected, this.comidaSelected, this.lunes, this.martes,
      this.miercoles, this.jueves, this.viernes, this.sabado, this.domingo).subscribe((result: any) => {
    });*/
    this.modalReference.close();
  }




  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su d�a correcto, los pondremos en el d�a actual y el primer d�a del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  
 
  actualizarNombreVacio(valueEmitted){
    if (this.requiereNombre != valueEmitted) {
      this.requiereNombre = valueEmitted;
  }
}
  actualizarFechasVacias(valueEmitted){
    if (this.requiereFechas != valueEmitted) {
      this.requiereFechas = valueEmitted;
  }
  }

}