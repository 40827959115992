<style>
  .reldiv1 {
    height: 0px;
    width: 0px;
    position: relative;
  }

  :host /deep/ .c3-drag-zoom {
    transform: translate(124.5px, 4.5px);
  }

  :host /deep/ .ocultarGrafico .c3-chart-lines {
    visibility: hidden;
  }

  :host /deep/ .ocultarGrafico .c3-axis-y {
    visibility: hidden;
    display: none;
  }

  /*GRAFICOS NUEVOS*/
  :host /deep/ #grafico2_hmiAdi .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico3_hmiAdi .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico4_hmiAdi .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico1_hmiAdi .c3-axis-y {
    transform: translate(0px, 0px);
  }

  :host /deep/ #grafico2_hmiAdi .c3-axis-y {
    transform: translate(-32px, 0px);
  }

  :host /deep/ #grafico3_hmiAdi .c3-axis-y {
    transform: translate(-64px, 0px);
  }

  :host /deep/ #grafico4_hmiAdi .c3-axis-y {
    transform: translate(-96px, 0px);
  }

  :host /deep/ #progresoGrafico_hmiAdi .c3-shapes-dataAzul>path,
  :host /deep/ #progresoGrafico_hmiAdi .c3-shapes-dataBlanco>path {
    stroke: #22C4C4 !important;
    stroke-width: 1px !important;
    stroke-opacity: 1 !important;
  }

  :host /deep/ #materialGrafico_hmiAdi .c3-shapes-dataAzulMarino>path,
  :host /deep/ #materialGrafico_hmiAdi .c3-shapes-dataAzulClaro>path,
  :host /deep/ #materialGrafico_hmiAdi .c3-shapes-dataBlanco>path {
    stroke: #18416a !important;
    stroke-width: 1px !important;
    stroke-opacity: 1 !important;
  }

  :host /deep/ #materialGrafico_hmiAdi .c3-shapes-dataRayaRoja>path {
    fill: rgb(255, 255, 255, 0) !important;
    stroke-opacity: 0 !important;
    stroke-width: 0px !important;
  }

  :host /deep/ .panelMaterialAdi .c3 line {
    stroke: #ff0000 !important;
    stroke-width: 2px !important;
  }

  /*FIN GRAFICOS NUEVOS*/
</style>

<div class="row hmi-hornos-cont">
  <!-- PANEL HOME -->
  <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

  <!--FOTOS-->
  <div class="col-md-3">
    <kendo-tabstrip [keepTabContent]="true">

      <!--ESCENA TEORICA-->
      <kendo-tabstrip-tab title="{{ 'escenaTeorica' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="aditivoImg">
            <img [src]="imgEscenaTeorica" />
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--CAPA TERMICA-->
      <kendo-tabstrip-tab title="{{ 'capaTermica' | translate}}">
        <ng-template kendoTabContent>
          <div class="aditivoImg">
            <img [src]="imgCapaTermica" />
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--TEORICA REAL-->
      <kendo-tabstrip-tab title="{{ 'teoricaReal' | translate}}">
        <ng-template kendoTabContent>
          <div class="aditivoImg">
            <img [src]="imgTeoricaReal" />
          </div>
        </ng-template>
      </kendo-tabstrip-tab>



    </kendo-tabstrip>
  </div>

  <!--PROGRESO-->
  <div class="col-md-2">
    <div class="row">

      <div class="col-lg-12" style="height: 370px;">
        <div class="panel-procesos panel-con-resumen-semana">
          <div class="contenido-panel-procesos panel-con-resumen-semana" style=" height: 339px; padding: 0;">

            <label class="titulo-seccion-maquina">{{ 'progreso' | translate }}</label>

            <div class="row">
              <!--VALORES-->
              <div class="col-md-6">
                <div style="padding: 10px 0px 0px 30px;">

                  <label class="row" style="font-size: 14px; height: 16px; font-weight: 700; color: #22c4c4;">{{
                    alturaActual }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'alturaActual' | translate }}</label>

                  <label class="row" style="font-size: 14px; height: 16px; font-weight: 700; color: #22c4c4;">{{
                    alturaImpresion }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'alturaImpresion' | translate }}</label>

                  <label class="row" style="font-size: 14px; height: 16px; font-weight: 700; color: #22c4c4;">{{
                    capaActual }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'capaActual' | translate }}</label>

                  <label class="row" style="font-size: 14px; height: 16px; font-weight: 700; color: #22c4c4;">{{
                    myFunctions.secondsTo_HH_MM_SS(tiempoTotal) }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'tiempoTotal' | translate }}</label>

                  <label class="row" style="font-size: 14px; height: 16px; font-weight: 700; color: #22c4c4;">{{
                    myFunctions.secondsTo_HH_MM_SS(tiempoActual) }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'tiempoActual' | translate }}</label>

                  <label class="row" style="font-size: 14px; height: 16px; font-weight: 700; color: #22c4c4;">{{
                    myFunctions.secondsTo_HH_MM_SS(tiempoRestante) }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'tiempoRestante' | translate }}</label>

                  <label class="row" style="font-size: 14px; height: 38px; font-weight: 700; color: #22c4c4;">{{
                    myFunctions.dateToString(fin) }}</label>
                  <label class="row" style="margin-bottom: 5px;">{{ 'fin' | translate }}</label>

                </div>
              </div>
              <!--GRAFICO-->
              <div class="col-md-6">
                <div id="progresoGrafico_hmiAdi" style="height: 290px;">

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>

  <!--MATERIAL Y RESUMEN SEMANA-->
  <div class="col-md-3">
    <div class="row">

      <div class="col-lg-12" style="height: 370px;">
        <div class="panel-procesos panel-con-resumen-semana">
          <div class="contenido-panel-procesos panel-con-resumen-semana" style=" height: 339px; padding: 0;">

            <!--MATERIAL-->
            <div class="panelMaterialAdi">
              <label class="titulo-seccion-maquina">{{ 'material' | translate }}</label>

              <div id="materialGrafico_hmiAdi" style="height: 120px; cursor: pointer;">

              </div>
              <div class="leyendaGraficoMaterialAditivo" style="height: 40px;">
                <span class="colorleyenda"
                  style="background-color: #A5C6E6; width: 12px; height: 12px; margin: 0 5px 0 10px; "></span>
                <Label style="font-size: 11px;">{{ 'materialActual2' | translate }}</Label>
                <span class="colorleyenda"
                  style="background-color: red; width: 2px; height: 15px; margin: 0 5px 0 10px; "></span>
                <Label style="font-size: 11px;">{{ 'materialNecesario2' | translate }}</Label>
                <span class="colorleyenda"
                  style="background-color: #4287CC; width: 12px; height: 12px; margin: 0 5px 0 10px; "></span>
                <Label style="font-size: 11px;">{{ 'materialConsumido2' | translate }}</Label>
                <span class="colorleyenda"
                  style="background-color: #FFFFFF; width: 13px; height: 13px; margin: 0 5px 0 10px; border: 1px solid #18416a;"></span>
                <Label style="font-size: 11px;">{{ 'contenedor' | translate }}</Label>
              </div>
            </div>

            <!--RESUMEN SEMANA-->
            <div>
              <label class="titulo-seccion-maquina">{{ 'resumenSemana' | translate }}</label>

              <div id="resumenSemanaGrafico_hmiAdi" style="height: 120px; cursor: pointer;"
                [routerLink]="['/informerendimiento/'+idmaquina]">

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>

</div>

<!--PESTANAS-->
<div class="row">
  <div class="col">

    <kendo-tabstrip [keepTabContent]="true">

      <!--HMI-->
      <kendo-tabstrip-tab title="{{ 'hmi' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">

            <!--VALORES-->
            <div class="col-md-3">
              <kendo-grid [data]="listaTooltip" kendoGridSelectBy="nombre" [selectedKeys]="listaTooltipSeleccionados"
                (selectionChange)="clickGridLeyenda($event)" [height]="400"
                [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
                <kendo-grid-checkbox-column width="10"></kendo-grid-checkbox-column>
                <kendo-grid-column field="nombreTraducido" title="{{ 'nombre' | translate }}"
                  width="40%"></kendo-grid-column>
                <!-- <kendo-grid-column field="dim" title="{{ 'dim' | translate }}" width="20"></kendo-grid-column> -->
                <kendo-grid-column field="color" title="{{ 'color' | translate }}" width="15%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="colorleyenda" [ngStyle]="{'background-color': dataItem.color }"></span>
                  </ng-template>
                </kendo-grid-column>

                <!-- <kendo-grid-column field="actual" title="{{ 'actual' | translate }}" width="20">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span >{{ dataItem.actual }}</span>
                    </ng-template>
                </kendo-grid-column> -->

                <kendo-grid-column field="actual" title="{{ 'actual' | translate }}" width="23%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <!-- <p>{{dataItem.actual}}</p> -->
                    <span *ngIf="dataItem.tipoDato != 'bit'">{{ dataItem.actual }}</span>
                    <input *ngIf="dataItem.tipoDato == 'bit'" type="checkbox" kendoCheckBox
                      [(ngModel)]="dataItem.actual" disabled />
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>

            <!--GRAFICO-->
            <div class="col-md-9" oncontextmenu="return false;">

              <div style="height: 500px;">
                <button kendoButton look="flat" [icon]="'zoom-out'"
                  style="float: right; z-index: 2; margin-right: 10px;" (click)="onClickMostrarTodos()">{{
                  'mostrarTodos' | translate }}</button>
                <!-- <div class="reldiv1" id="grafico10_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[9]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico9_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[8]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico8_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[7]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico7_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[6]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico6_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[5]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico5_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[4]" style="z-index: 1;"></div> -->
                <div class="reldiv1" id="grafico4_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[3]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico3_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[2]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico2_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[1]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico1_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[0]"
                  style="z-index: 1;"></div>
              </div>

            </div>

          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--MANTENIMIENTOS-->
      <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row cont-mantenimientos">
            <div class="col-md-6">
              {{ 'porFechas' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="41%">
                  </kendo-grid-column>
                  <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span>{{ dataItem.tipo | translate }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
              </div>
            </div>
            <div class="col-md-6">
              {{ 'porTiempos' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="51%">
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
    <div *ngIf="loadingPestannas" class="k-i-loading" style="z-index: 2;"></div>

  </div>
</div>