import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/AsignacionTiempos`;

@Injectable({
  providedIn: 'root'
})
export class AsignacionTiemposService {

  constructor(private http: HttpClient) {}

  //SELECT
  GetInformeTabla(listaNombresClientes, listaNombresPiezas, listaNombresOfs, listaNombresPartes, listaNombresOperacion, listanumerosPlano, listaIdsMaquinas, terminado) {
    return this.http.post<JSON>(`${baseUrl}/Get_informeTabla`, {
        listaNombresClientes: listaNombresClientes, listaNombresPiezas: listaNombresPiezas, listaNombresOfs: listaNombresOfs, listaNombresPartes: listaNombresPartes,
        listaNombresOperacion: listaNombresOperacion, listanumerosPlano: listanumerosPlano, listaIdsMaquinas: listaIdsMaquinas,  
        operacionTerminada: terminado}, { withCredentials: true });
    }
  updateData(req: any) {
    var json: JSON = JSON.parse(JSON.stringify(req));
    return this.http.post<JSON>(`${baseUrl}/UpdateData`, {json}, { withCredentials: true });
    }
}
