<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="row d-none">

    <!--DATOS-->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>
            <label>{{ 'datos' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>

        <div class="card-body">
        </div>
      </div>
    </div>

    <!--COLORES-->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>
            <label>{{ 'colores' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>

        <div class="card-body">
          <div class="row">

            <!--RPM-->
            <div class="col-md-6">

              <div class="form-group">
                <label style="font-size: 16px; color: #24ab95;">{{ 'rpm' | translate }}</label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango1c' | translate }}">
                  <kendo-colorpicker formControlName="colorFeed1" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango2c' | translate }}">
                  <kendo-colorpicker formControlName="colorFeed2" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango3c' | translate }}">
                  <kendo-colorpicker formControlName="colorFeed3" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango4c' | translate }}">
                  <kendo-colorpicker formControlName="colorFeed4" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango5c' | translate }}">
                  <kendo-colorpicker formControlName="colorFeed5" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

            </div>

            <!--CONSUMO-->
            <div class="col-md-6">

              <div class="form-group">
                <label style="font-size: 16px; color: #24ab95;">{{ 'consumo' | translate }}</label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango1c' | translate }}">
                  <kendo-colorpicker formControlName="colorConsumo1" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango2c' | translate }}">
                  <kendo-colorpicker formControlName="colorConsumo2" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango3c' | translate }}">
                  <kendo-colorpicker formControlName="colorConsumo3" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'rango4c' | translate }}">
                  <kendo-colorpicker formControlName="colorConsumo4" [format]="'hex'"
                    [gradientSettings]="{ opacity: false }" style="margin-left: 10px;"></kendo-colorpicker>
                </kendo-label>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</form>

<!-- PESTAÑAS -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showGridTooltip($event)">

  <div class="example-wrapper">
    <kendo-tabstrip tabPosition="top" [keepTabContent]="true">

      <!-- CARACTERISTICAS -->
      <kendo-tabstrip-tab title="{{ 'caracteristicas' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="row">

              <div class="col-md-3">
                <!--Disponibilidad en % por contrato de compra-->
                <div class="form-group">
                  <kendo-label text="{{ 'porcentajeDisponibilidadContratoCompra' | translate }}">
                    <kendo-numerictextbox formControlName="porcentajeDisponibilidadContratoCompra" class="form-control"
                      [format]="'# \\\%'" [min]="0" [max]="100"></kendo-numerictextbox>
                  </kendo-label>
                </div>
              </div>

              <div class="col-md-3">
                <!--Meses por informe de disponibilidad-->
                <div class="form-group">
                  <kendo-label text="{{ 'mesesPorInformeDisp' | translate }}">
                    <kendo-numerictextbox formControlName="mesesPorInforme" class="form-control" [min]="0" [max]="99999"
                      [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                  </kendo-label>
                </div>
              </div>

              <div class="col-md-3">
                <!--Mes de inicio del informe de disponibilidad-->
                <div class="form-group">
                  <label class="control-label">{{ 'disponibilidadMesInicio' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="meses" [textField]="'nombre'" [valueField]="'valor'"
                      formControlName="disponibilidadMesInicio" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--Alarmas repercuten a:-->
                <div class="form-group">
                  <label class="control-label">{{ 'alarmasRepercutenA' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="alarmasRepercutenA" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="alarmasRepercutenASelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--Parada sin justificar repercuten a:-->
                <div class="form-group">
                  <label class="control-label">{{ 'paradaSinJustificarRepercutenA' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="paradaSinJustificarRepercutenA" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="paradaSinJustificarRepercutenASelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--Microparada sin justificar repercuten a:-->
                <div class="form-group">
                  <label class="control-label">{{ 'microparadaSinJustificarRepercutenA' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="microparadaSinJustificarRepercutenA" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="microparadaSinJustificarRepercutenASelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--Alarmas dentro de operacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'alarmasDentroDeOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="alarmasDentroDeOperacion" formControlName="alarmasDentroDeOperacion"
                      [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaAlarmaPorDefectoSinOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaAlarmaPorDefectoSinOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaAlarmaPorDefectoSinOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaAlarmaPorDefectoSinOperacionSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaParadaPorDefectoSinOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaParadaPorDefectoSinOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaParadaPorDefectoSinOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaParadaPorDefectoSinOperacionSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaMicroparadaPorDefectoSinOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaMicroparadaPorDefectoSinOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaMicroparadaPorDefectoSinOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaMicroparadaPorDefectoSinOperacionSelected"
                      class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaApagadaPorDefectoSinOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaApagadaPorDefectoSinOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaApagadaPorDefectoSinOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaApagadaPorDefectoSinOperacionSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaAlarmaPorDefectoConOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaAlarmaPorDefectoConOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaAlarmaPorDefectoConOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaAlarmaPorDefectoConOperacionSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaParadaPorDefectoConOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaParadaPorDefectoConOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaParadaPorDefectoConOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaParadaPorDefectoConOperacionSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaMicroparadaPorDefectoConOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaMicroparadaPorDefectoConOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaMicroparadaPorDefectoConOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaMicroparadaPorDefectoConOperacionSelected"
                      class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--perdidaApagadaPorDefectoConOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'perdidaApagadaPorDefectoConOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="perdidaApagadaPorDefectoConOperacion" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="perdidaApagadaPorDefectoConOperacionSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--procesoIdentificadoSinTiempoEstimadoRepercuteOEE-->
                <div class="form-group">
                  <label class="control-label">{{ 'procesoIdentificadoSinTiempoEstimadoRepercuteOEE' | translate
                    }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="procesoIdentificadoSinTiempoEstimadoRepercuteOEE" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="procesoIdentificadoSinTiempoEstimadoRepercuteOEESelected"
                      class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--ejecucionSinOperacionRepercuteOEE-->
                <div class="form-group">
                  <label class="control-label">{{ 'ejecucionSinOperacionRepercuteOEE' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="ejecucionSinOperacionRepercuteOEE" [textField]="'nombre'"
                      [valueField]="'id'" [(value)]="ejecucionSinOperacionRepercuteOEESelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--continuarOperacionConParada-->
                <div class="form-group">
                  <label class="control-label">{{ 'continuarOperacionConParada' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="continuarOperacionConParada"
                      formControlName="continuarOperacionConParada" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--continuarOperacionConApagada-->
                <div class="form-group">
                  <label class="control-label">{{ 'continuarOperacionConApagada' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="continuarOperacionConApagada"
                      formControlName="continuarOperacionConApagada" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>


              <div class="col-md-3">
                <!--continuarOperacionConAlarma-->
                <div class="form-group">
                  <label class="control-label">{{ 'continuarOperacionConAlarma' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="continuarOperacionConAlarma"
                      formControlName="continuarOperacionConAlarma" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--segundosParaAsignarParadasAOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'segundosParaAsignarParadasAOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="segundosParaAsignarParadasAOperacion" class="form-control"
                      [min]="1" [autoCorrect]="true" [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--segundosParaAsignarApagadasAOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'segundosParaAsignarApagadasAOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="segundosParaAsignarApagadasAOperacion" class="form-control"
                      [min]="1" [autoCorrect]="true" [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <!--segundosParaAsignarAlarmasAOperacion-->
                <div class="form-group">
                  <label class="control-label">{{ 'segundosParaAsignarAlarmasAOperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="segundosParaAsignarAlarmasAOperacion" class="form-control"
                      [min]="1" [autoCorrect]="true" [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <!--visorTV-->
                <div class="form-group">
                  <label class="control-label">{{ 'tamanoTV' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="verHomeTV" [textField]="'text'" [valueField]="'id'"
                      [(value)]="tamanoTVSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>


            </div>
            <div class="row">
              <div class="col-md-3">
                <!--verColada-->
                <div class="form-group">
                  <label class="control-label">{{ 'verColada' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="verColada" formControlName="verColada" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <!--verNSerie-->
                <div class="form-group">
                  <label class="control-label">{{ 'verNSerie' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="verNSerie" formControlName="verNSerie" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <!--verLote-->
                <div class="form-group">
                  <label class="control-label">{{ 'verLote' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [(ngModel)]="verLote" formControlName="verLote" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <!--segundosCicloMaquinasTV-->
                <div class="form-group">
                  <label class="control-label">{{ 'segundosCicloMaquinasTV' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="segundosCicloMaquinasTV" class="form-control" [min]="1"
                      [autoCorrect]="true" [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-3">

              </div>
              <div class="col-md-3">

              </div>
              <!-- SEGUIMIENTO OFS MESES -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label class="control-label">{{ 'seguimientoOFsUltimosMeses' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="seguimientoOFsUltimosMeses" class="form-control"
                      [min]="1" [autoCorrect]="true" [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div> -->
              <div class="col-md-3">
                <!-- home tipo calculo lote-->
                <div class="form-group">
                  <label class="control-label">{{ 'tipoCalculoLote' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="tipoCalculoLote" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="tipoCalculoLoteSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <!-- seleccionar diseño tv-->
                <div class="form-group">
                  <label class="control-label">{{ 'disenoTv' | translate }}</label>
                  <div class="caja">
                    <kendo-dropdownlist [data]="disenoTv" [textField]="'nombre'" [valueField]="'id'"
                      [(value)]="disenoSelected" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                </div>
              </div>

            </div>

          </form>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- INCIDENCIAS -->
      <kendo-tabstrip-tab title="{{ 'incidencias' | translate}}">
        <ng-template kendoTabContent>

          <div class="row" style="margin-top: 10px;">

            <!--COLUMNA FABRICANTE-->
            <div class="col-md-6">

              <h3>
                <label>{{ 'fabricante' | translate }}</label>
              </h3>

              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <!--EMAIL-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'email' | translate }}">
                        <kendo-textbox formControlName="email"></kendo-textbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--USUARIO-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'usuario' | translate }}">
                        <kendo-textbox formControlName="usuario"></kendo-textbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--SMTP-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'Smtp' | translate }}">
                        <kendo-textbox formControlName="smtp"></kendo-textbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--PUERTO-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'puerto' | translate }}">
                        <kendo-numerictextbox formControlName="puerto" class="form-control" [min]="0" [max]="99999"
                          [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--CONTRASENA-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'contrasena' | translate }}">
                        <input kendoTextBox type="password" class="form-control" formControlName="contrasena"
                          [ngClass]="{ 'is-invalid': submitted && f.contrasena.errors }" name="new-password"
                          id="new-password" autocomplete="new-password" />
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--CONFIRMAR CONTRASENA-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'confirmarcontrasena' | translate }}">
                        <input kendoTextBox type="password" class="form-control" formControlName="confContrasena"
                          [ngClass]="{ 'is-invalid': submitted && f.confContrasena.errors }" />
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--ANADIR EMAIL FABRICANTE-->
                <div class="row" style="margin-top: 10px;">
                  <div class="col-11">
                    <div class="form-group">
                      <button type="button" class="btn btn-primary btn-sm mr-1"
                        (click)="onClickAnnadirEmailFabricante()">
                        {{ 'anadirEmailFabricante' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <!--EMAILS FABRICANTE-->
              <div class="row">
                <div class="col-11">
                  <div class="form-group">
                    <label>
                      {{ 'emailFabricante' | translate }}
                      <kendo-textbox *ngFor="let email of emailsFabricantes" [(ngModel)]="email.email"
                        style="margin-top: 5px;">
                        <ng-template kendoTextBoxSuffixTemplate>
                          <button kendoButton [look]="'clear'" [icon]="'close'"
                            (click)="onClickEliminarEmailFabricante(email)"></button>
                        </ng-template>
                      </kendo-textbox>
                    </label>
                  </div>
                </div>
              </div>

            </div>

            <!--COLUMNA OPERARIO-->
            <div class="col-md-6">

              <h3>
                <label>{{ 'operario' | translate }}</label>
              </h3>

              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <!--EMAIL-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'email' | translate }}">
                        <kendo-textbox formControlName="emailBuhler"></kendo-textbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--USUARIO-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'usuario' | translate }}">
                        <kendo-textbox formControlName="usuarioBuhler"></kendo-textbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--SMTP-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'Smtp' | translate }}">
                        <kendo-textbox formControlName="smtpBuhler"></kendo-textbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--PUERTO-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'puerto' | translate }}">
                        <kendo-numerictextbox formControlName="puertoBuhler" class="form-control" [min]="0"
                          [max]="99999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--CONTRASENA-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'contrasena' | translate }}">
                        <input kendoTextBox type="password" class="form-control" formControlName="contrasenaBuhler"
                          [ngClass]="{ 'is-invalid': submitted && f.contrasenaBuhler.errors }" name="new-password"
                          id="new-password" autocomplete="new-password" />
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--CONFIRMAR CONTRASENA-->
                <div class="row">
                  <div class="col-11">
                    <div class="form-group">
                      <kendo-label text="{{ 'confirmarcontrasena' | translate }}">
                        <input kendoTextBox type="password" class="form-control" formControlName="confContrasenaBuhler"
                          [ngClass]="{ 'is-invalid': submitted && f.confContrasenaBuhler.errors }" />
                      </kendo-label>
                    </div>
                  </div>
                </div>

                <!--ANADIR EMAIL OPERARIO-->
                <div class="row" style="margin-top: 10px;">
                  <div class="col-11">
                    <div class="form-group">
                      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="onClickAnnadirEmailOperario()">
                        {{ 'anadirEmailOperario' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              <!--EMAILS OPERARIO-->
              <div class="row">
                <div class="col-11">
                  <div class="form-group">
                    <label>
                      {{ 'emailOperario' | translate }}
                      <kendo-textbox *ngFor="let email of emailsOperarios" [(ngModel)]="email.email"
                        style="margin-top: 5px">
                        <ng-template kendoTextBoxSuffixTemplate>
                          <button kendoButton [look]="'clear'" [icon]="'close'"
                            (click)="onClickEliminarEmailOperario(email)"></button>
                        </ng-template>
                      </kendo-textbox>
                    </label>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </ng-template>
      </kendo-tabstrip-tab>

      <!-- TURNOS -->
      <kendo-tabstrip-tab title="{{ 'turnos' | translate}}">
        <ng-template kendoTabContent>
          <p>
            <!-- TURNOS -->
            <kendo-grid [kendoGridBinding]="horario" kendoGridSelectBy="idMaquina" class="turnos-grid">

              <kendo-grid-column-group title="{{ 'lunes' | translate}}">
                <kendo-grid-column field="lunesMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [style]="{'text-align': 'right'}"
                      [(value)]="dataItem.lunesMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.lunesMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="lunesTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.lunesTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.lunesTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="lunesNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.lunesNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.lunesNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <kendo-grid-column-group title="{{ 'martes' | translate}}">
                <kendo-grid-column field="martesMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.martesMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.martesMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="martesTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.martesTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.martesTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="martesNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.martesNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.martesNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <kendo-grid-column-group title="{{ 'miercoles' | translate}}">
                <kendo-grid-column field="miercolesMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.miercolesMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.miercolesMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="miercolesTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.miercolesTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.miercolesTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="miercolesNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.miercolesNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.miercolesNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <kendo-grid-column-group title="{{ 'jueves' | translate}}">
                <kendo-grid-column field="juevesMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.juevesMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.juevesMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="juevesTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.juevesTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.juevesTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="juevesNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.juevesNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.juevesNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <kendo-grid-column-group title="{{ 'viernes' | translate}}">
                <kendo-grid-column field="viernesMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.viernesMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.viernesMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="viernesTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.viernesTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.viernesTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="viernesNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.viernesNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.viernesNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <kendo-grid-column-group title="{{ 'sabado' | translate}}">
                <kendo-grid-column field="sabadoMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.sabadoMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.sabadoMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="sabadoTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.sabadoTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.sabadoTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="sabadoNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.sabadoNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.sabadoNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <kendo-grid-column-group title="{{ 'domingo' | translate}}">
                <kendo-grid-column field="domingoMannana" title="{{ 'manana' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.domingoMannanaInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.domingoMannanaFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="domingoTarde" title="{{ 'tarde' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.domingoTardeInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.domingoTardeFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="domingoNoche" title="{{ 'noche' | translate}}" width="5%"
                  [style]="{'text-align': 'center'}">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-timepicker [(value)]="dataItem.domingoNocheInicio"></kendo-timepicker>
                    <kendo-timepicker [(value)]="dataItem.domingoNocheFin"></kendo-timepicker>
                  </ng-template>
                </kendo-grid-column>
              </kendo-grid-column-group>

              <!--TRADUCCI�N TEXTOS DEL GRID-->
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>

            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- ETIQUETAS -->
      <kendo-tabstrip-tab title="{{ 'etiquetas' | translate}}">
        <ng-template kendoTabContent>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="clearfix etiquetas-cont">
              <!-- QR/CB Y SEP -->
              <div class="float-left mr-3">
                <div class="form-group">
                  <div class="clearfix">
                    <!-- QR Y CB -->
                    <kendo-buttongroup selection="single">
                      <button kendoButton title="{{ 'qr' | translate }}" [primary]="isQR" (click)="QRorCD()">{{ 'qr' |
                        translate }}</button>
                      <button kendoButton title="{{ 'codigoBarras' | translate }}" [primary]="isCB"
                        (click)="QRorCD()">{{ 'codigoBarras' | translate }}</button>
                    </kendo-buttongroup>
                  </div>

                  <div class="clearfix">
                    <!--SEP-->
                    <div class="form-group">
                      <label class="control-label">{{ 'abrSeptiembre' | translate }}</label>
                      <div class="caja">
                        <div>
                          <kendo-switch [(ngModel)]="tieneSeparador" formControlName="tieneSeparador" [onLabel]="' '"
                            [offLabel]="' '">
                          </kendo-switch>
                        </div>
                        <div *ngIf="tieneSeparador">
                          <input kendoTextBox formControlName="sep" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- PARAMETROS -->
              <div class="float-left">
                <!--Param 1-->


                <div class="row mb-2">
                  <div class="col">
                    <label class="control-label">{{ 'tipoparametro' | translate }}</label>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <label class="control-label">{{ 'cantidad' | translate }}</label>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <label class="control-label">{{ 'indefinido' | translate }}</label>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col">
                    <kendo-dropdownlist [disabled]="form?.value?.sep=='' && tieneSeparador" [data]="parameters"
                      (valueChange)="annadirValor($event, 1)" [(ngModel)]="param1Seleccionado" [textField]="'nombre'"
                      [valueField]="'valor'" formControlName="param1" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-numerictextbox formControlName="param1Length" class="form-control" [min]="0"
                      (valueChange)="annadirValorLength($event, 1)" [disabled]="esIndefinido[0]" [autoCorrect]="true"
                      [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-switch [(ngModel)]="esIndefinido[0]" formControlName="param1Indefinido" [onLabel]="' '"
                      [offLabel]="' '" (valueChange)="esIndefinidoChange(1)">
                    </kendo-switch>
                  </div>
                  <div class="float-left cont-eliminar">
                    <button Class="flt-eliminar" (click)="annadirEliminado(1)"
                      *ngIf="param2Seleccionado.valor==-1 && param1Seleccionado.valor!=-1"> <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>



                <!--Param 2-->

                <div class="row mb-2">
                  <div class="col">
                    <kendo-dropdownlist [data]="parameters" (valueChange)="annadirValor($event, 2)"
                      [disabled]="param1Seleccionado.valor==-1" [(ngModel)]="param2Seleccionado" [textField]="'nombre'"
                      [valueField]="'valor'" formControlName="param2" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-numerictextbox formControlName="param2Length" class="form-control" [min]="0"
                      (valueChange)="annadirValorLength($event, 2)"
                      [disabled]="param1Seleccionado.valor==-1 || esIndefinido[1]" [autoCorrect]="true" [decimals]="0"
                      [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-switch [(ngModel)]="esIndefinido[1]" formControlName="param2Indefinido" [onLabel]="' '"
                      [offLabel]="' '" (valueChange)="esIndefinidoChange(2)">
                    </kendo-switch>
                  </div>
                  <div class="float-left cont-eliminar">
                    <button Class="flt-eliminar" (click)="annadirEliminado(2)"
                      *ngIf="param3Seleccionado.valor==-1 && param2Seleccionado.valor!=-1"> <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>


                <!--Param 3-->

                <div class="row mb-2">
                  <div class="col">
                    <kendo-dropdownlist [data]="parameters" (valueChange)="annadirValor($event, 3)"
                      [disabled]="param2Seleccionado.valor==-1" [(ngModel)]="param3Seleccionado" [textField]="'nombre'"
                      [valueField]="'valor'" formControlName="param3" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-numerictextbox formControlName="param3Length" class="form-control" [min]="0"
                      (valueChange)="annadirValorLength($event, 3)"
                      [disabled]="param2Seleccionado.valor==-1 || esIndefinido[2]" [autoCorrect]="true" [decimals]="0"
                      [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-switch [(ngModel)]="esIndefinido[2]" formControlName="param3Indefinido" [onLabel]="' '"
                      [offLabel]="' '" (valueChange)="esIndefinidoChange(3)">
                    </kendo-switch>
                  </div>
                  <div class="float-left cont-eliminar">
                    <button Class="flt-eliminar" (click)="annadirEliminado(3)"
                      *ngIf="param4Seleccionado.valor==-1 && param3Seleccionado.valor!=-1"> <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>

                <!--Param 4-->

                <div class="row mb-2">
                  <div class="col">
                    <kendo-dropdownlist [data]="parameters" (valueChange)="annadirValor($event, 4)"
                      [disabled]="param3Seleccionado.valor==-1" [(ngModel)]="param4Seleccionado" [textField]="'nombre'"
                      [valueField]="'valor'" formControlName="param4" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-numerictextbox formControlName="param4Length" class="form-control" [min]="0"
                      (valueChange)="annadirValorLength($event, 4)"
                      [disabled]="param3Seleccionado.valor==-1 || esIndefinido[3]" [autoCorrect]="true" [decimals]="0"
                      [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-switch [(ngModel)]="esIndefinido[3]" formControlName="param4Indefinido" [onLabel]="' '"
                      [offLabel]="' '" (valueChange)="esIndefinidoChange(4)">
                    </kendo-switch>
                  </div>
                  <div class="float-left cont-eliminar">
                    <button Class="flt-eliminar" (click)="annadirEliminado(4)"
                      *ngIf="param5Seleccionado.valor==-1 && param4Seleccionado.valor!=-1"> <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>


                <!--Param 5-->

                <div class="row mb-2">
                  <div class="col">
                    <kendo-dropdownlist [data]="parameters" (valueChange)="annadirValor($event, 5)"
                      [disabled]="param4Seleccionado.valor==-1" [(ngModel)]="param5Seleccionado" [textField]="'nombre'"
                      [valueField]="'valor'" formControlName="param5" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-numerictextbox formControlName="param5Length" class="form-control" [min]="0"
                      (valueChange)="annadirValorLength($event, 5)"
                      [disabled]="param4Seleccionado.valor==-1 || esIndefinido[4]" [autoCorrect]="true" [decimals]="0"
                      [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-switch [(ngModel)]="esIndefinido[4]" formControlName="param5Indefinido" [onLabel]="' '"
                      [offLabel]="' '" (valueChange)="esIndefinidoChange(5)">
                    </kendo-switch>
                  </div>
                  <div class="float-left cont-eliminar">
                    <button Class="flt-eliminar" (click)="annadirEliminado(5)"
                      *ngIf="param6Seleccionado.valor==-1 && param5Seleccionado.valor!=-1"> <i class="fas fa-times"></i>
                    </button>
                  </div>
                </div>


                <!--Param 6-->

                <div class="row mb-2">
                  <div class="col">
                    <kendo-dropdownlist [data]="parameters" (valueChange)="annadirValor($event, 6)"
                      (selectionChange)="annadirLinea(6)" [disabled]="param5Seleccionado.valor==-1"
                      [(ngModel)]="param6Seleccionado" [textField]="'nombre'" [valueField]="'valor'"
                      formControlName="param6" class="form-control">
                    </kendo-dropdownlist>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-numerictextbox formControlName="param6Length" class="form-control" [min]="0"
                      (valueChange)="annadirValorLength($event, 6)"
                      [disabled]="param5Seleccionado.valor==-1 || esIndefinido[5]" [autoCorrect]="true" [decimals]="0"
                      [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                  <div class="col" *ngIf="!tieneSeparador">
                    <kendo-switch [(ngModel)]="esIndefinido[5]" formControlName="param6Indefinido" [onLabel]="' '"
                      [offLabel]="' '" (valueChange)="esIndefinidoChange(6)">
                    </kendo-switch>
                  </div>
                  <div class="float-left cont-eliminar">
                    <button Class="flt-eliminar" (click)="annadirEliminado(6, true)"
                      *ngIf="param6Seleccionado.valor!=-1 && parametrosArray[6]?.valor==-1"> <i
                        class="fas fa-times"></i> </button>
                  </div>
                </div>

                <!-- Dinamicamente crear parametros -->
                <div *ngFor="let p of parametrosExtras; let i = index" [attr.data-index]="i">
                  <!--Param extras-->

                  <div class="row">
                    <div class="col">
                      <kendo-dropdownlist [data]="parameters" formControlName="param7"
                        (selectionChange)="annadirLinea(i+7)" (valueChange)="annadirValor($event, i+7)"
                        [(ngModel)]="parametrosArray[i+6]" [textField]="'nombre'" [valueField]="'valor'">
                      </kendo-dropdownlist>
                    </div>
                    <div class="col" *ngIf="!tieneSeparador">
                      <kendo-numerictextbox formControlName="param7Length" class="form-control" [min]="0"
                        (valueChange)="annadirValorLength($event, i+7)" [disabled]="esIndefinido[i+6]"
                        [autoCorrect]="true" [decimals]="0" [format]="'n'">
                      </kendo-numerictextbox>
                    </div>
                    <div class="col" *ngIf="!tieneSeparador">
                      <kendo-switch [(ngModel)]="esIndefinido[i+6]" formControlName="param7Indefinido" [onLabel]="' '"
                        [offLabel]="' '" (valueChange)="esIndefinidoChange(i+7)">
                      </kendo-switch>
                    </div>
                    <div class="float-left cont-eliminar">
                      <button Class="flt-eliminar" (click)="annadirEliminado(i+7, true)"
                        *ngIf="parametrosArray[i+6]?.valor!=-1 && parametrosArray[i+7]?.valor==-1"> <i
                          class="fas fa-times"></i> </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- OEE -->
      <kendo-tabstrip-tab title="{{ 'OEE' | translate}}">
        <ng-template kendoTabContent>
          <div class="row">
            <!--EJECUCION-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'ejecucion' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="ejecucionFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="ejecucionEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.ejecucionEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">                    
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="ejecucionConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="ejecucionConOperacionRepercuteA != x.id"
                        (click)="ejecucionConOperacionRepercuteA = x.id">
                        <input type="radio" name="ejecucionConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="ejecucionConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="ejecucionSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="ejecucionSinOperacionRepercuteA != x.id"
                        (click)="ejecucionSinOperacionRepercuteA = x.id">
                        <input type="radio" name="ejecucionSinOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="ejecucionSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row ">
                    <!--EJECUCION SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="ejecucionSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--PREPARACION-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'preparacion' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="preparacionFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="preparacionEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.preparacionEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="preparacionConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="preparacionConOperacionRepercuteA != x.id"
                        (click)="preparacionConOperacionRepercuteA = x.id">
                        <input type="radio" name="preparacionConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="preparacionConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="preparacionSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="preparacionSinOperacionRepercuteA != x.id"
                        (click)="preparacionSinOperacionRepercuteA = x.id">
                        <input type="radio" name="preparacionSinOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="preparacionSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row ">
                    <!--PREPARACION SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="preparacionSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--PARADA-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'parada' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="paradaFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="paradaEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.paradaEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="paradaConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="paradaConOperacionRepercuteA != x.id"
                        (click)="paradaConOperacionRepercuteA = x.id">
                        <input type="radio" name="paradaConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="paradaConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="paradaSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="paradaSinOperacionRepercuteA != x.id"
                        (click)="paradaSinOperacionRepercuteA = x.id">
                        <input type="radio" name="paradaSinOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="paradaSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row ">
                    <!--PARADA SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="paradaSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!--MICROPARADA-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'microparada' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="microparadaFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="microparadaEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.microparadaEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="microParadaConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="microParadaConOperacionRepercuteA != x.id"
                        (click)="microParadaConOperacionRepercuteA = x.id">
                        <input type="radio" name="microParadaConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="microParadaConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="microParadaSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="microParadaSinOperacionRepercuteA != x.id"
                        (click)="microParadaSinOperacionRepercuteA = x.id">
                        <input type="radio" name="microParadaSinOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="microParadaSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>  
                  <div class="row ">
                    <!--MICRO-PARADA SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="microparadaSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--ALARMA-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'alarma' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="alarmaFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="alarmaEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.alarmaEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="alarmaConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="alarmaConOperacionRepercuteA != x.id"
                        (click)="alarmaConOperacionRepercuteA = x.id">
                        <input type="radio" name="alarmaConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="alarmaConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="alarmaSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="alarmaSinOperacionRepercuteA != x.id"
                        (click)="alarmaSinOperacionRepercuteA = x.id">
                        <input type="radio" name="alarmaSinOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="alarmaSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row ">
                    <!--ALARMA SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="alarmaSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--MANTENIMIENTO-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'mantenimiento' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="mantenimientoFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="mantenimientoEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.mantenimientoEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="mantenimientoConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="mantenimientoConOperacionRepercuteA != x.id"
                        (click)="mantenimientoConOperacionRepercuteA = x.id">
                        <input type="radio" name="mantenimientoConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="mantenimientoConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="mantenimientoSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="mantenimientoSinOperacionRepercuteA != x.id"
                        (click)="mantenimientoSinOperacionRepercuteA = x.id">
                        <input type="radio" name="mantenimientoSinOperacionRepercuteA" [value]="x.id" class="k-radio"
                          [checked]="mantenimientoSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row ">
                    <!--MANTENIMIENTOS SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="mantenimientoSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!--APAGADA-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'apagada' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--FUERA DE TURNO ES OPERACION-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label"></label>
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="apagadaFueraturnoEsOperacion"
                            [onLabel]="fueraTurnoEsOperacion" [offLabel]="fueraTurnoEsOperacion">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                    <!--T.MAX-->
                    <div class="col-md-6">
                      <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                          <label class="control-label">{{ 'tiempomax' | translate }}</label>
                          <div class="caja">
                            <kendo-textbox formControlName="apagadaEsOperacionSegundos" style="width:100%;"
                              [ngClass]="{ 'is-invalid': submitted && form.controls.apagadaEsOperacionSegundos.errors }"></kendo-textbox>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="row">
                    <!--CON OPERACION REPERCUTE A-->
                    <div class="col-md-12 mb-2">
                      <label class="control-label">{{ 'conOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="apagadaConOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="apagadaConOperacionRepercuteA != x.id"
                        (click)="apagadaConOperacionRepercuteA = x.id">
                        <input type="radio" name="apagadaConOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="apagadaConOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label">{{ x.nombre }}</label>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!--SIN OPERACION REPERCUTE A-->
                    <div class="col-md-12">
                      <label class="control-label">{{ 'sinOperacionRepercuteA' | translate }}</label>
                      <div class="cont-repercutea">
                      <div *ngFor="let x of repercuteDAT" class="btn btn-repercutea"
                        [class.btn-success]="apagadaSinOperacionRepercuteA == x.id"
                        [class.btn-outline-success]="apagadaSinOperacionRepercuteA != x.id"
                        (click)="apagadaSinOperacionRepercuteA = x.id">
                        <input type="radio" name="apagadaSinOperacionRepercuteA" [value]="x.id" class="k-radio "
                          [checked]="apagadaSinOperacionRepercuteA == x.id" kendoRadioButton>
                        <label class="k-radio-label" [attr.for]="x.id">{{ x.nombre }}</label>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div class="row ">
                    <!--APAGADAS SIN OPERACION FUERA DE TURNO REPERCUTE OEE-->
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">{{ 'sinOperarioFueraTurnoRepercuteOEE' | translate }}</label>
                        <div class="caja">
                          <kendo-switch [(ngModel)]="apagadaSinOperarioFueraTurnoRepercuteOEE" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--TIEMPOS-->
            <div class="col-md-12 col-lg-6 col-xl-6 col-xxl-4">
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'tiempos' | translate}} </label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <!--QUITAR TIEMPOS DE DISPONIBILIDAD A LA OPERACION-->
                    <div class="col-md-9">
                      <div class="form-group">
                        <div class="caja">
                          <kendo-switch class="kendo-switch-tipo2" [(ngModel)]="quitarDisponibilidadDeOperacion"
                            [onLabel]="quitarDisponibilidadDeOperacionLabel"
                            [offLabel]="quitarDisponibilidadDeOperacionLabel">
                          </kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!-- SEGUIMIENTO OFs -->
      <kendo-tabstrip-tab title="{{ 'seguimientoOFs' | translate}}">
        <ng-template kendoTabContent>
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-3">
                <!-- SEGUIMIENTO OFS MESES -->
                <div class="form-group">
                  <label class="control-label">{{ 'seguimientoOFsUltimosMeses' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="seguimientoOFsUltimosMeses" class="form-control" [min]="1"
                      [autoCorrect]="true" [decimals]="0" [format]="'n'">
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>
              <div class="col-md-3">

              </div>
            </div>
          </form>
        </ng-template>
      </kendo-tabstrip-tab>
            <!-- GESTION DOCUMENTAL -->
            <kendo-tabstrip-tab title="{{ 'gestiondocumental' | translate}}">
              <ng-template kendoTabContent>
                <form [formGroup]="form">
                  <div class="row">
                    <div class="col-md-3">
                      <!-- ELEGIR EL DESTINO DE LOS FICHEROS -->
                      <div class="form-group">
                        <kendo-label text="Elige el metodo de subir datos:">
                          <kendo-combobox
                            formControlName="comboGestionDocumental"
                            [data]="listItems"
                            textField="text"
                            valueField="id"
                            (valueChange)="valorTipoSubidaChange($event)"
                            [(ngModel)]="selectedValue"
                          >
                          </kendo-combobox>
                        </kendo-label>
                      </div>
                      <!-- GET PARAMETROS DE CONEXION SEGUN LA ELECCION -->
                      <div [ngSwitch] = 'valorSelected'>
                          <!-- Local -->
                          <div *ngSwitchCase = 1>
                            <label>Local selected</label>
                          </div>
                          <!-- FTP -->
                          <div *ngSwitchCase = 2>
                            <div class="form-group">
                              <kendo-label text="Servidor FTP">
                                <kendo-textbox formControlName="servidorFTP" (valueChange)="guardarServidorFTP($event)" style="width:100%;"></kendo-textbox>
                              </kendo-label>
                            </div>
                            <div class="form-group">
                              <kendo-label text="Puerto">
                                <kendo-textbox formControlName="puertoFTP" (valueChange)="guardarPuertoFTP($event)" style="width:100%;"></kendo-textbox>
                              </kendo-label>
                            </div>
                            <div class="form-group">
                              <kendo-label text="Usuario">
                                <kendo-textbox formControlName="usuarioFTP" (valueChange)="guardarUsuarioFTP($event)" style="width:100%;"></kendo-textbox>
                              </kendo-label>
                            </div>
                            <div class="form-group">
                              <kendo-label text="Contraseña">
                                <kendo-textbox formControlName="passwordFTP" type="password" (valueChange)="guardarPasswordFTP($event)" style="width:100%;"></kendo-textbox>
                              </kendo-label>
                            </div>
                          </div>
                          <!-- Azure externo -->
                          <div *ngSwitchCase = 3>
                            <div class="form-group">
                              <kendo-label text="Cadena de conexion">
                                <kendo-textbox formControlName="azureConnectionString" style="width:100%;" (valueChange)="guardarAzureConString($event)"></kendo-textbox>
                              </kendo-label>
                            </div>
                            <div class="form-group">
                              <kendo-label text="Nombre del contenedor">
                                <kendo-textbox formControlName="azureContainerName" style="width:100%;" (valueChange)="guardarAzureContainerName($event)"></kendo-textbox>
                              </kendo-label>
                            </div>
                          </div>

                          <!-- Google Cloud -->
                          <div *ngSwitchCase = 5>
                            <div class="form-group">
                              <kendo-label text="Nombre de bucket">
                                <kendo-textbox formControlName="cloudNombreBucket" (valueChange)="guardarCloudNombreBucket($event)" style="width:100%;"></kendo-textbox>
                              </kendo-label>
                            </div>
                            <div class="form-group">
                              <kendo-label text="Fichero de credenciales json">
                                <input formControlName="ficheroCredenciales" id="ficheroJsonCredenciales" type="file"/>
                              </kendo-label>
                              
                            </div>
                          </div>
                      </div>
      
                      <!-- <input id="fichero" type="file"/> -->
                    </div>
                    <div class="col-md-3">
                    </div>
                  </div>
                </form>
              </ng-template>
            </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <button class="btn btn-primary btn-sm mt-2" [disabled]="this.user.configuracion<2">
    {{ 'guardar' | translate }}
  </button>
</form>