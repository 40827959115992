import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/operacionestipo`;

@Injectable()
export class OperacionesTipoService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<any>(`${baseUrl}/${id}`);
  }

  create(id: number, nombre: string, tipoId: number) {
    return this.http.post<JSON>(`${baseUrl}/create`, { id, nombre, tipoId }, { withCredentials: true });
  }

  update(id: number, nombre: string, tipoId: number) {
    return this.http.post<JSON>(`${baseUrl}/update`, { id, nombre, tipoId }, { withCredentials: true });
  }

  delete(id: number) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  }

  Get_TipoOperacionTipo_Combo() {
    return this.http.post<JSON>(`${baseUrl}/Get_TipoOperacionTipo_Combo`, {}, { withCredentials: true });
  }

}
