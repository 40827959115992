import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MyFunctions } from '@app/_helpers';
import { AlertService, MenuService, AppComponent, UsuariosService, MaquinasService, ClasificacionesService, SeguimientoOFsService } from '@app/_services';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SelectableSettings } from '@progress/kendo-angular-treelist';

@Component({
    selector: 'app-seguimiento-OFs-Configurador-Detalle',
    templateUrl: './seguimientoOFsConfiguradorDetalle.component.html'
})

export class SeguimientoOFsConfiguradorDetalleComponent {

    private translate: TranslateService;

    //El FormGroup para validar
    public formTitulo: FormGroup = new FormGroup({
        titulo: new FormControl()
    });

    public isAddMode: boolean;

    //Seguimientos
    public tituloSeguimiento: string;

    public tituloValido = true;

    // Lista donde se guardaran las lineas del seguimiento
    public lineas: any[] = [];
    // Desplegar configurador de contenedor
    public menuAbierto = false;
    // Selecciones
    public lineaIdSelected: number = -1;
    public lineaIndexSelected: number = -1;
    public columnaSelected: number = -1;
    // Valores para cambiarlos en el menu
    public nombreEdit: string = "";
    public vinculadoEdit: boolean = false;
    public restoEdit: boolean = false;
    public cortoEdit: boolean = false;
    public largoEdit: boolean = false;
    public manualEdit: boolean = false;
    public origenEdit: any[] = [];
    public destinoEdit: any[] = [];
    public actualEdit: any[] = [];
    public semanaEdit: number = 10;
    public piezaMinEdit: number = -1;
    public piezaMaxEdit: number = -1;
    public piezaOptimaEdit: number = -1;
    public numeroSemanasVista: number = 10;
    public selectedListaSemanaVista: any;
    public listaSemanasVista: any[] = [];
    public clickFlecha: boolean = false;

    // AGREGAR MAQUINAS
    public id;

    public maquinas;
    public maquinasOriginal;
    public maquinasAgregarOrigen = [];
    public maquinasAgregarDestino = [];
    public maquinasAgregarActual = [];
    public maquinasOrigen = [];
    public maquinasDestino = [];
    public maquinasActual = [];
    public maquinasOrigenOriginal = [];
    public maquinasDestinoOriginal = [];
    public maquinasActualOriginal = [];
    public seleccionadosOrigen = [];
    public seleccionadosDestino = [];
    public seleccionadosActual = [];

    public agregarSeleccionadosOrigen = [];
    public agregarSeleccionadosDestino = [];
    public agregarSeleccionadosActual = [];
    public secciones: any = [];
    public groupedSeccion: any = [];
    public grupos: any = [];
    public gruposCargados: any = [];
    public seccionesSeleccionadasOrigen: any = [];
    public gruposSeleccionadosOrigen: any = [];
    public seccionesSeleccionadasDestino: any = [];
    public gruposSeleccionadosDestino: any = [];
    public seccionesSeleccionadasActual: any = [];
    public gruposSeleccionadosActual: any = [];
    public clasificaciones: any = [];
    public clasificacionesSeleccionadosOrigen: any = [];
    public clasificacionesSeleccionadosDestino: any = [];
    public clasificacionesSeleccionadosActual: any = [];
    public relacionesClasificaciones = new Map();
    public maquinas_clasificaciones: any = [];
    public groupedFiltro: any = [];
    public groupedFiltroOriginal: any = [];
    public maquinaParaGrupos: any = [];

    // MAQUINAS FILTRO

    public agregarOrigen = false;
    public agregarDestino = false;
    public agregarActual = false;
    public filtroOrigen: any = [];
    public filtroStringOrigen: any = "";
    public filtroDestino: any = [];
    public filtroStringDestino: any = "";
    public filtroActual: any = [];
    public filtroStringActual: any = "";
    public ensennarFiltroOrigen: boolean = false;
    public ensennarFiltroDestino: boolean = false;
    public ensennarFiltroActual: boolean = false;
    public ensennarMaquinasOrigen: boolean = false;
    public ensennarMaquinasDestino: boolean = false;
    public ensennarMaquinasActual: boolean = false;
    public agregarOrigenMaquinas: any = []
    public agregarDestinoMaquinas: any = []
    public agregarActualMaquinas: any = []

    public isAgruparPorSeccionesOrigen = true;
    public isAgruparPorGruposOrigen = false;
    public isAgruparPorClasificacionesOrigen = false;

    public isAgruparPorSeccionesDestino = true;
    public isAgruparPorGruposDestino = false;
    public isAgruparPorClasificacionesDestino = false;

    public isAgruparPorSeccionesActual = true;
    public isAgruparPorGruposActual = false;
    public isAgruparPorClasificacionesActual = false;

    public showSeccionesOrigen;
    public showGruposOrigen;
    public showClasificacionesOrigen;

    public showSeccionesDestino;
    public showGruposDestino;
    public showClasificacionesDestino;

    public showSeccionesActual;
    public showGruposActual;
    public showClasificacionesActual;

    public groupsGrupos: GroupDescriptor[] = [{ field: "nombreGrupo" }];
    public groupsSecciones: GroupDescriptor[] = [{ field: "nombreSeccion" }];
    public groupsFiltro: GroupDescriptor[] = [{ field: "grouped" }];

    public settings: SelectableSettings = {
        mode: "row",
        multiple: true,
        drag: true,
        enabled: true,
        readonly: false
    };


    constructor(
        translate: TranslateService,
        private alertService: AlertService,
        private userService: UsuariosService,
        private menuService: MenuService, public router: Router,
        public route: ActivatedRoute,
        public myFunctions: MyFunctions,
        public maquinasService: MaquinasService,
        public clasificacionesService: ClasificacionesService,
        public seguimientoOFsService: SeguimientoOFsService,
        private modalService: NgbModal,
        private appComponent: AppComponent) {

        this.translate = translate;
        this.menuService.titulo = this.translate.instant('crearSeguimiento').toUpperCase();
        this.cargarSeccionesGrupo();
    }

    ngOnInit() {

        this.id = this.route.snapshot.params['id'];
        if (this.id == -1)
            this.isAddMode = true;
        else
            this.isAddMode = false;

        for (let i = 1; i <= this.numeroSemanasVista; i++) {
            var nombre = i.toString() //+ ' ' + this.translate.instant('semanas');
            this.listaSemanasVista.push(i);
        }
    }

    cargarDatosSeguimientos() {
        this.seguimientoOFsService.GetSeguimiento_byId(this.id).subscribe(result => {
            var data: any = result;
            this.tituloSeguimiento = data[0]?.nombre;
            this.seguimientoOFsService.GetSeguimiento_Linea(this.id).subscribe(resultLin => {
                var dataLinea: any = resultLin;
                dataLinea.forEach(linea => {
                    var listaColumnas = []
                    this.seguimientoOFsService.GetSeguimiento_Columna(linea.id).subscribe(resultCol => {
                        var dataColumn: any = resultCol
                        dataColumn.forEach(columna => {
                            var origenParte = columna.idsMaquinasOrigen.split(",");
                            var idsOrigen = [];
                            if (origenParte.length > 0) {
                                origenParte.forEach(parte => {
                                    this.maquinas.forEach(maq => {
                                        if (maq.id == parte) {
                                            idsOrigen.push(maq)
                                        }
                                    });
                                });
                            }
                            var destinoParte = columna.idsMaquinasDestino.split(",");
                            var idsDestino = [];
                            if (destinoParte.length > 0) {
                                destinoParte.forEach(parte => {
                                    this.maquinas.forEach(maq => {
                                        if (maq.id == parte) {
                                            idsDestino.push(maq)
                                        }
                                    });
                                });
                            }
                            var ActualParte = columna.idsMaquinasActual.split(",");
                            var idsActual = [];
                            if (ActualParte.length > 0) {
                                ActualParte.forEach(parte => {
                                    this.maquinas.forEach(maq => {
                                        if (maq.id == parte) {
                                            idsActual.push(maq)
                                        }
                                    });
                                });
                            }

                            // console.log(idsOrigen)
                            // console.log(idsDestino)

                            var col = {
                                nombre: columna.nombre,
                                piezasMinimas: columna.piezasMin,
                                piezasOptimas: columna.piezasOptimas,
                                piezasMaximas: columna.piezasMax,
                                vinculado: columna.vinculado,
                                resto: columna.resto,
                                corto: columna.corto,
                                largo: columna.largo,
                                manual: columna.manual,
                                semana: columna.semana,
                                origen: idsOrigen,
                                destino: idsDestino,
                                actual: idsActual
                            }
                            listaColumnas.push(col);
                        });
                        var auxLin = {
                            oculto: linea.oculto,
                            orden: linea.orden,
                            idLinea: linea.id,
                            colNum: listaColumnas.length,
                            infoColumnas: listaColumnas
                        }
                        this.lineas.push(auxLin);
                        this.lineas.sort((a, b) => {
                            if (a.orden > b.orden)
                                return 1
                            else if (a.orden < b.orden)
                                return -1
                            else
                                return 0
                        });
                    });
                });
            });
        });
    }

    guardarSeguimiento(modo: number) {
        if (modo == 0) {
            if (this.isAddMode) {
                // nombre
                this.seguimientoOFsService.Add_Seguimiento(this.tituloSeguimiento).subscribe(result => {
                    var idSeguimiento: any = result;
                    // lineas
                    // console.log("Gordetako jarraipena: " + idSeguimiento)
                    this.lineas.forEach((linea, index) => {
                        var nombreLinea = "linea" + (index + 1).toString();
                        // console.log("Linea izena: " + nombreLinea)
                        this.seguimientoOFsService.Add_Seguimiento_Linea(idSeguimiento, nombreLinea, linea.oculto, index + 1).subscribe(resultLinea => {
                            var idSeguimientoLinea: any = resultLinea;
                            // console.log("Id linea: " + idSeguimientoLinea)
                            // columnak
                            var orden = 0;
                            linea.infoColumnas.forEach(columna => {
                                orden++;
                                var listaOrigen = "";
                                columna.origen.forEach(origen => {
                                    if (listaOrigen == "") {
                                        listaOrigen = origen.id;
                                    } else {
                                        listaOrigen = listaOrigen + "," + origen.id;
                                    }
                                });
                                var listaDestino = "";
                                columna.destino.forEach(destino => {
                                    if (listaDestino == "") {
                                        listaDestino = destino.id;
                                    } else {
                                        listaDestino = listaDestino + "," + destino.id;
                                    }
                                });
                                var listaActual = "";
                                columna.actual.forEach(actual => {
                                    if (listaActual == "") {
                                        listaActual = actual.id;
                                    } else {
                                        listaActual = listaActual + "," + actual.id;
                                    }
                                });
                                this.seguimientoOFsService.Add_Seguimiento_Linea_Columna(idSeguimientoLinea, columna.nombre, columna.vinculado, columna.resto, columna.corto, columna.largo, columna.semana, columna.piezasMinimas, columna.piezasMaximas, columna.piezasOptimas, listaOrigen, listaDestino, orden, columna.manual, listaActual).subscribe(resultColumna => {

                                });
                            });
                        });
                    });
                    this.router.navigate(['/seguimientoOFsConfigurador']);
                });
            } else {
                this.seguimientoOFsService.Update_Seguimiento(this.id, this.tituloSeguimiento).subscribe(result => {
                    this.lineas.forEach((linea, index) => {
                        var nombreLinea = "linea" + (index + 1).toString();
                        // console.log("Linea izena: " + nombreLinea)
                        this.seguimientoOFsService.Add_Seguimiento_Linea(this.id, nombreLinea, linea.oculto, index + 1).subscribe(resultLinea => {
                            var idSeguimientoLinea: any = resultLinea;
                            // console.log("Id linea: " + idSeguimientoLinea)
                            // columnak
                            var orden = 0;
                            console.log(linea.infoColumnas)
                            linea.infoColumnas.forEach(columna => {
                                orden++;
                                var listaOrigen = "";
                                var origenAux: any = columna.origen
                                origenAux.forEach(origen => {
                                    if (listaOrigen == "") {
                                        listaOrigen = origen.id;
                                    } else {
                                        listaOrigen = listaOrigen + "," + origen.id;
                                    }
                                });
                                var listaDestino = "";
                                var destinoAux: any = columna.destino
                                destinoAux.forEach(destino => {
                                    if (listaDestino == "") {
                                        listaDestino = destino.id;
                                    } else {
                                        listaDestino = listaDestino + "," + destino.id;
                                    }
                                });
                                var listaActual = "";
                                var actualAux: any = columna.actual
                                actualAux.forEach(actual => {
                                    if (listaActual == "") {
                                        listaActual = actual.id;
                                    } else {
                                        listaActual = listaActual + "," + actual.id;
                                    }
                                });
                                // console.log(columna.nombre + " - Origen: " + listaOrigen)
                                // console.log(columna.nombre + " - Destino: " + listaDestino)
                                console.log(columna.nombre + " - Actual: " + listaActual)
                                console.log(columna.nombre + " - Actual: " + columna.actual)
                                this.seguimientoOFsService.Add_Seguimiento_Linea_Columna(idSeguimientoLinea, columna.nombre, columna.vinculado, columna.resto, columna.corto, columna.largo, columna.semana, columna.piezasMinimas, columna.piezasMaximas, columna.piezasOptimas, listaOrigen, listaDestino, orden, columna.manual, listaActual).subscribe(resultColumna => {

                                });
                            });
                        });
                    });
                });
                this.router.navigate(['/seguimientoOFsConfigurador']);
            }
        } else if (modo == 1) {
            // nombre
            var nombreCopia = this.tituloSeguimiento + "_" + this.translate.instant('copia');
            this.seguimientoOFsService.Add_Seguimiento(nombreCopia).subscribe(result => {
                var idSeguimiento: any = result;
                // lineas
                // console.log("Gordetako jarraipena: " + idSeguimiento)
                this.lineas.forEach((linea, index) => {
                    var nombreLinea = "linea" + (index + 1).toString();
                    // console.log("Linea izena: " + nombreLinea)
                    this.seguimientoOFsService.Add_Seguimiento_Linea(idSeguimiento, nombreLinea, linea.oculto, index + 1).subscribe(resultLinea => {
                        var idSeguimientoLinea: any = resultLinea;
                        // console.log("Id linea: " + idSeguimientoLinea)
                        // columnak
                        var orden = 0;
                        linea.infoColumnas.forEach(columna => {
                            orden++;
                            var listaOrigen = "";
                            columna.origen.forEach(origen => {
                                if (listaOrigen == "") {
                                    listaOrigen = origen.id;
                                } else {
                                    listaOrigen = listaOrigen + "," + origen.id;
                                }
                            });
                            var listaDestino = "";
                            columna.destino.forEach(destino => {
                                if (listaDestino == "") {
                                    listaDestino = destino.id;
                                } else {
                                    listaDestino = listaDestino + "," + destino.id;
                                }
                            });
                            var listaActual = "";
                            columna.actual.forEach(actual => {
                                if (listaActual == "") {
                                    listaActual = actual.id;
                                } else {
                                    listaActual = listaActual + "," + actual.id;
                                }
                            });
                            this.seguimientoOFsService.Add_Seguimiento_Linea_Columna(idSeguimientoLinea, columna.nombre, columna.vinculado, columna.resto, columna.corto, columna.largo, columna.semana, columna.piezasMinimas, columna.piezasMaximas, columna.piezasOptimas, listaOrigen, listaDestino, orden, columna.manual, listaActual).subscribe(resultColumna => {

                            });
                        });
                    });
                });
                this.router.navigate(['/seguimientoOFsConfigurador']);
            });
        }


    }

    anadirLinea(colNum: number) {
        var columnasInfo = [];
        var idNueva = 1;

        for (let i = 0; i < colNum; i++) {
            columnasInfo.push(
                {
                    nombre: '',
                    piezasMinimas: 0,
                    piezasOptimas: 0,
                    piezasMaximas: 0,
                    vinculado: false,
                    resto: false,
                    corto: false,
                    largo: false,
                    manual: false,
                    semana: -1,
                    origen: [],
                    destino: [],
                    actual: []
                }
            );
        }
        if (this.lineas.length == 0) {
            idNueva = 1;
        } else {
            idNueva = this.lineas[this.lineas.length - 1].id + 1
        }

        this.lineas.push({
            oculto: false,
            idLinea: idNueva,
            colNum: colNum,
            infoColumnas: columnasInfo
        });
    }

    cambiarCantidadColumnas(index: number, colNum: number) {
        for (let i = 0; i < this.lineas.length; i++) {
            if (i == index) {
                // Miramos si son mas o menos lines que antes
                if (this.lineas[i].colNum > colNum) {
                    // Si son menos, quitamos los sobrantes desde atras
                    var dif = this.lineas[i].colNum - colNum;
                    for (let j = 0; j < dif; j++) {
                        this.lineas[i].infoColumnas.pop();
                    }
                    // Cambiamos el registro de columnas de la linea
                    this.lineas[i].colNum = colNum;
                } else if (this.lineas[i].colNum < colNum) {
                    // Si son mas, annadimos columnas nuevas con valores por defecto
                    var dif = colNum - this.lineas[i].colNum;
                    for (let j = 0; j < dif; j++) {
                        this.lineas[i].infoColumnas.push(
                            {
                                nombre: '',
                                piezasMinimas: 0,
                                piezasOptimas: 0,
                                piezasMaximas: 0,
                                vinculado: false,
                                resto: false,
                                corto: false,
                                largo: false,
                                manual: false,
                                semana: -1,
                                origen: [],
                                destino: [],
                                actual: []
                            }
                        );
                    }
                    // Cambiamos el registro de columnas de la linea
                    this.lineas[i].colNum = colNum;
                }
            }
        }
    }

    duplicarLinea(linea: any) {
        // Hacemos una copia de la linea
        var copiaLinea = this.myFunctions.copy(linea);
        // Cambiamos el id por uno nuevo
        copiaLinea.id = this.lineas[this.lineas.length - 1].id + 1;
        // Guardamos la linea
        this.lineas.push(copiaLinea);
    }

    eliminarLinea(index) {
        this.lineas.splice(index, 1);
    }

    botonAnnadirInfo(col: number, lineaId: number, lineaIndex: number) {
        if (!this.clickFlecha) {
            this.menuAbierto = !this.menuAbierto;
            this.lineaIdSelected = lineaId;
            this.lineaIndexSelected = lineaIndex;
            this.columnaSelected = col;

            this.nombreEdit = this.lineas[lineaIndex].infoColumnas[col].nombre;
            this.vinculadoEdit = this.lineas[lineaIndex].infoColumnas[col].vinculado;
            this.restoEdit = this.lineas[lineaIndex].infoColumnas[col].resto;
            this.cortoEdit = this.lineas[lineaIndex].infoColumnas[col].corto;
            this.largoEdit = this.lineas[lineaIndex].infoColumnas[col].largo;
            this.manualEdit = this.lineas[lineaIndex].infoColumnas[col].manual;
            this.origenEdit = this.lineas[lineaIndex].infoColumnas[col].origen;
            this.destinoEdit = this.lineas[lineaIndex].infoColumnas[col].destino;
            this.actualEdit = this.lineas[lineaIndex].infoColumnas[col].actual;
            this.semanaEdit = this.lineas[lineaIndex].infoColumnas[col].semana;
            this.piezaMinEdit = this.lineas[lineaIndex].infoColumnas[col].piezasMinimas;
            this.piezaMaxEdit = this.lineas[lineaIndex].infoColumnas[col].piezasMaximas;
            this.piezaOptimaEdit = this.lineas[lineaIndex].infoColumnas[col].piezasOptimas;
            this.agregarOrigenMaquinas = [];
            this.agregarSeleccionadosOrigen = [];
            this.agregarDestinoMaquinas = [];
            this.agregarSeleccionadosDestino = [];


            var listaSeleccionadosAux = []
            this.lineas[lineaIndex].infoColumnas[col].origen.forEach(origen => {
                listaSeleccionadosAux.push(origen.id);
            });
            this.agregarSeleccionadosOrigen = this.myFunctions.copy(listaSeleccionadosAux);
            this.agregarOrigenMaquinas = this.myFunctions.copy(this.lineas[lineaIndex].infoColumnas[col].origen);

            var listaSeleccionadosAux = []
            this.lineas[lineaIndex].infoColumnas[col].destino.forEach(destino => {
                listaSeleccionadosAux.push(destino.id);
            });
            this.agregarSeleccionadosDestino = this.myFunctions.copy(listaSeleccionadosAux);
            this.agregarDestinoMaquinas = this.lineas[lineaIndex].infoColumnas[col].destino;

            var listaSeleccionadosAux = []
            this.lineas[lineaIndex].infoColumnas[col].actual.forEach(actual => {
                listaSeleccionadosAux.push(actual.id);
            });
            this.agregarSeleccionadosActual = this.myFunctions.copy(listaSeleccionadosAux);
            this.agregarActualMaquinas = this.lineas[lineaIndex].infoColumnas[col].actual;
        }

    }

    cerrarMenu() {
        if (!this.agregarOrigen && !this.agregarDestino && !this.agregarActual) {
            this.menuAbierto = false;
            this.lineaIdSelected = -1;
            this.lineaIndexSelected = -1;
            this.columnaSelected = -1;
            this.nombreEdit = ''
            this.vinculadoEdit = false;
            this.restoEdit = false;
            this.cortoEdit = false;
            this.largoEdit = false;
            this.manualEdit = false;
            this.origenEdit = [];
            this.destinoEdit = [];
            this.actualEdit = [];
            this.semanaEdit = 10;
            this.piezaMinEdit = 0;
            this.piezaMaxEdit = 0;
            this.piezaOptimaEdit = 0;
            this.agregarOrigenMaquinas = [];
            this.agregarSeleccionadosOrigen = [];
            this.agregarDestinoMaquinas = [];
            this.agregarSeleccionadosDestino = [];
            this.agregarActualMaquinas = [];
            this.agregarSeleccionadosActual = [];
        }

        if (this.agregarDestino && (this.ensennarFiltroDestino || this.ensennarMaquinasDestino)) {
            this.ensennarFiltroDestino = false;
            this.ensennarMaquinasDestino = false;
        } else if (this.agregarOrigen && (this.ensennarFiltroOrigen || this.ensennarMaquinasOrigen)) {
            this.ensennarFiltroOrigen = false;
            this.agregarOrigen = false;
        } else if (this.agregarActual && (this.ensennarFiltroActual || this.ensennarMaquinasActual)) {
            this.ensennarFiltroActual = false;
            this.agregarActual = false;
        } else {
            this.ensennarMaquinasOrigen = false;
            this.agregarDestino = false;
            this.agregarOrigen = false;
            this.agregarActual = false;
        }
    }

    valueChangeNombre(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].nombre = e;
        this.nombreEdit = e;
    }
    valueChangeVinculado(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].vinculado = e;
        this.vinculadoEdit = e;
    }
    valueChangeResto(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].resto = e;
        this.restoEdit = e;
    }
    valueChangeCorto(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].corto = e;
        this.cortoEdit = e;
    }
    valueChangeLargo(e) {
        if (e) {
            this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].largo = e;
            this.largoEdit = e;
            this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].vinculado = e;
            this.vinculadoEdit = e;
        } else {
            this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].largo = e;
            this.largoEdit = e;
        }
    }
    valueChangeManual(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].manual = e;
        this.manualEdit = e;
    }
    valueChangeMin(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].piezasMinimas = e;
        this.piezaMinEdit = e;
    }
    valueChangeOptimo(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].piezasOptimas = e;
        this.piezaOptimaEdit = e;
    }
    valueChangeMax(e) {
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].piezasMaximas = e;
        this.piezaMaxEdit = e;
    }
    subirLinea(index) {
        if (index > 0) {
            var lineaAux = this.lineas[index];
            this.lineas[index] = this.lineas[index - 1];
            this.lineas[index - 1] = lineaAux;
        }
    }
    bajarLinea(index) {
        if (index < this.lineas.length - 1) {
            var lineaAux = this.lineas[index];
            this.lineas[index] = this.lineas[index + 1];
            this.lineas[index + 1] = lineaAux;
        }
    }
    columnaIzquierda(lineIndex, colIndex) {
        // this.clickFlecha = true;
        if (colIndex > 0) {
            var colAux = this.lineas[lineIndex].infoColumnas[colIndex];
            this.lineas[lineIndex].infoColumnas[colIndex] = this.lineas[lineIndex].infoColumnas[colIndex - 1];
            this.lineas[lineIndex].infoColumnas[colIndex - 1] = colAux;
        }
        // this.clickFlecha = false;
    }
    columnaDerecha(lineIndex, colIndex) {
        // this.clickFlecha = true;
        if (colIndex < this.lineas[lineIndex].infoColumnas.length - 1) {
            var colAux = this.lineas[lineIndex].infoColumnas[colIndex];
            this.lineas[lineIndex].infoColumnas[colIndex] = this.lineas[lineIndex].infoColumnas[colIndex + 1];
            this.lineas[lineIndex].infoColumnas[colIndex + 1] = colAux;
        }
        // this.clickFlecha = false;
    }

    valueChangeSemanaVista(e) {
        if (e) {
            var semanaNum = e.toString().split(" ");
            this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].semana = e;
            this.semanaEdit = e;
            console.log(e)
        } else {
            this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].semana = e;
            this.semanaEdit = e;
            console.log(e)
        }
    }

































    ////// CARGAR MAQUINAS
    cargarSeccionesGrupo() {
        var r1 = false, r2 = false, r3 = false;
        // SECCIONES
        this.userService.getSecciones().subscribe(json => {
            this.userService.secciones = json;
            //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
            this.secciones.forEach(element => {
                element.grouped = this.translate.instant("secciones");
                element.isSeleccionado = false;
                element.isSeleccionadoDestino = false;
                element.idGrid = "0." + element.id
                this.groupedFiltro.push(this.myFunctions.copy(element));
            });
            r1 = true
            if (r1 && r2 && r3) this.cargarMaquinas();
        });

        // GRUPO MAQUINAS
        this.maquinasService.getGruposMaquinas().subscribe(json => {
            this.grupos = json.data;
            this.grupos.forEach(element => {
                element.grouped = this.translate.instant("xgrupos");
                element.isSeleccionado = false;
                element.isSeleccionadoDestino = false;
                element.idGrid = "1." + element.id
                this.groupedFiltro.push(this.myFunctions.copy(element));
            });
            this.gruposCargados = true;
            r2 = true;
            if (r1 && r2 && r3) this.cargarMaquinas();
        });

        // CLASIFICACIONES
        this.clasificacionesService.GetAll().subscribe(
            (result) => {
                this.clasificaciones = result;

                this.clasificaciones.forEach(element => {
                    element.grouped = this.translate.instant("clasificaciones");
                    element.isSeleccionado = false;
                    element.isSeleccionadoDestino = false;
                    element.idGrid = "2." + element.id
                    this.groupedFiltro.push(this.myFunctions.copy(element));
                });

                this.clasificacionesService.GetMaquinasClasificaciones().subscribe((json: any) => {
                    this.maquinas_clasificaciones = json;
                    r3 = true;
                    if (r1 && r2 && r3) this.cargarMaquinas();
                });

                // definir un map para saber de cada clasificacion cual es su hijo
                this.clasificaciones.forEach(element => {
                    this.relacionesClasificaciones.set(element.id, [element.id]);

                    if (this.clasificaciones.filter(f => f.idPadre == element.id).length > 0)
                        this.relacionesClasificaciones.set(element.id, this.setHijos(element.id, [element.id]));
                });
            });
    }

    cargarMaquinas() {

        // COPIA DE FILTRO
        this.groupedFiltroOriginal = this.myFunctions.copy(this.groupedFiltro);
        //MAQUINAS
        var maquinas_model = this.maquinasService.get_maquinas_model();
        if (maquinas_model == false) {
            this.maquinasService.get().subscribe(json => {
                this.maquinasService.set_maquinas_model(json);
                this.maquinas = this.maquinasService.get_maquinas_model();
                this.maquinas.forEach(element => {
                    element.nombreSeccion = this.secciones.filter(f => f.id == element.idSeccion)[0]?.nombre;
                    if (element.nombreSeccion == undefined)
                        element.nombreSeccion = this.translate.instant('sinAsignar');

                    // crear las siglas de las secciones
                    if (element.nombre.split(" ").length > 1) {
                        let aux = element.nombre.split(" ");
                        element.siglas = aux[0][0] + aux[1][0]
                    } else {
                        element.siglas = element.nombre[0] + element.nombre[1];
                    }


                });
                this.maquinasOriginal = this.myFunctions.copy(this.maquinas);
                this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinas);
                this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinas);
                this.maquinasAgregarActual = this.myFunctions.copy(this.maquinas);
                // if (this.id > 0)
                //     this.cargarDatos();
                this.cargarDatosSeguimientos();
            })
        } else {
            this.maquinas = maquinas_model;
            this.maquinas.forEach(element => {
                element.nombreSeccion = this.secciones.filter(f => f.id == element.idSeccion)[0]?.nombre;
                if (element.nombreSeccion == undefined)
                    element.nombreSeccion = this.translate.instant('sinAsignar');

                // crear las siglas de las secciones
                if (element.nombre.split(" ").length > 1) {
                    let aux = element.nombre.split(" ");
                    element.siglas = aux[0][0] + aux[1][0]
                } else {
                    element.siglas = element.nombre[0] + element.nombre[1];
                }
            });
            this.maquinasOriginal = this.myFunctions.copy(this.maquinas);
            this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinas);
            this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinas);
            this.maquinasAgregarActual = this.myFunctions.copy(this.maquinas);
            // if (this.id > 0)
            //     this.cargarDatos();
            this.cargarDatosSeguimientos();
        }
    }

    // cargarDatos() {
    //     this.contenedoresService.GetContenedorById(this.id).subscribe(json => {
    //         var data: any = json[0];

    //         this.nombreContenedor = data.nombre;
    //         this.isActivo = data.activo;
    //         this.descripcionContenedor = data.descripcion;
    //         this.stockMinimo = data.stockMinimo;
    //         this.stockMaximo = data.stockMaximo;

    //         var maquinasOrigen = data.maquinasOrigen?.split(',');
    //         var maquinasDestino = data.maquinasDestino?.split(',');

    //         this.maquinasOrigen = this.maquinas.filter(f => maquinasOrigen?.includes(f.id.toString()));
    //         this.maquinasDestino = this.maquinas.filter(f => maquinasDestino?.includes(f.id.toString()));

    //         maquinasOrigen?.forEach(element => { this.agregarSeleccionadosOrigen.push(parseInt(element)) });
    //         maquinasDestino?.forEach(element => { this.agregarSeleccionadosDestino.push(parseInt(element)) });

    //         this.maquinasOrigenOriginal = this.myFunctions.copy(this.maquinasOrigen);
    //         this.maquinasDestinoOriginal = this.myFunctions.copy(this.maquinasDestino);

    //         this.agregarOrigenMaquinas.push(...this.maquinasOriginal.filter(f => this.agregarSeleccionadosOrigen.includes(f.id)));
    //         this.agregarDestinoMaquinas.push(...this.maquinasOriginal.filter(f => this.agregarSeleccionadosDestino.includes(f.id)));

    //         this.setMaquinasSeleccionadasOrigen();
    //         this.setMaquinasSeleccionadasDestino();

    //     });
    // }

    // Funcion recursiva para crear el map de las clasificaciones

    setHijos(id, array) {
        var idHijos = this.clasificaciones.filter(f => f.idPadre == id);
        if (idHijos.length > 0)
            idHijos.forEach(element => {
                array.push(element.id)
                this.setHijos(element.id, array);
            });

        return array;
    }


    ////// SELECCION MAQUINAS ORIGEN
    showOrigen() {
        this.agregarOrigen = true;
        this.ensennarMaquinasOrigen = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    eliminarMaquinaOrigen(id) {
        this.agregarSeleccionadosOrigen = this.agregarSeleccionadosOrigen.filter(f => f != id);
        this.maquinasOrigen = this.maquinasOrigen.filter(f => f.id != id);
        this.agregarOrigenMaquinas = this.agregarOrigenMaquinas.filter(f => f.id != id);

        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].origen = this.myFunctions.copy(this.agregarOrigenMaquinas)

        this.setMaquinasSeleccionadasOrigen();
    }

    setMaquinasSeleccionadasOrigen() {
        // console.log(this.agregarSeleccionadosOrigen)
        this.maquinasAgregarOrigen.forEach(element => {
            if (this.agregarSeleccionadosOrigen.includes(element.id))
                element.isSeleccionado = true;
            else
                element.isSeleccionado = false;
        })

        if (this.isAgruparPorGruposOrigen)
            this.agruparPorGruposOrigen();
    }

    agruparPorGruposOrigen() {

        this.isAgruparPorSeccionesOrigen = false;
        this.isAgruparPorGruposOrigen = true;
        this.isAgruparPorClasificacionesOrigen = false;

        this.maquinaParaGrupos = [];
        this.maquinasAgregarOrigen.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if (this.gruposSeleccionadosOrigen.includes(parseInt(row)) || this.gruposSeleccionadosOrigen.length == 0) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })

    }

    buscarChangeOrigen(event) {
        if (event == "") this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinasOriginal);
        else this.maquinasAgregarOrigen = this.maquinasOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));

        this.maquinaParaGrupos = [];
        this.maquinasAgregarOrigen.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if ((this.gruposSeleccionadosOrigen.includes(parseInt(row)) || this.gruposSeleccionadosOrigen.length == 0) && (element.nombre.toLowerCase().includes(event.toLowerCase()) || event == "")) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })
    }

    showFiltroOrigen() {
        this.ensennarFiltroOrigen = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    eliminarFiltroOrigen(nombre) {
        //#region obtener el id del grupo/seccion/clasificacion
        var filtro = this.filtroOrigen.filter(f => f.nombre == nombre)[0];
        if (filtro.idGrupo != -1) this.annadirFiltroSeleccionadoOrigen({ dataItem: { id: filtro.idGrupo, grouped: this.translate.instant("xgrupos") } });
        else if (filtro.idSeccion != -1) this.annadirFiltroSeleccionadoOrigen({ dataItem: { id: filtro.idSeccion, grouped: this.translate.instant("secciones") } });
        else if (filtro.idClasificacion != -1) this.annadirFiltroSeleccionadoOrigen({ dataItem: { id: filtro.idClasificacion, grouped: this.translate.instant("clasificaciones") } });
        //#endregion
        // this.filtroOrigen = this.filtroOrigen.filter(f => f.nombre == nombre);
    }

    annadirFiltroSeleccionadoOrigen(event = null, dataItem = null) {
        // al clickar en el grid
        if (event.type == 'change') {
            event.dataItem = dataItem;
        }

        if (event.dataItem.grouped != null) {
            if (this.seccionesSeleccionadasOrigen.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("secciones")) {
                this.seccionesSeleccionadasOrigen = this.seccionesSeleccionadasOrigen.filter(f => f != event.dataItem.id);
                this.filtroOrigen = this.filtroOrigen.filter(f => f.idSeccion != event.dataItem.id);
            } else if (this.gruposSeleccionadosOrigen.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("xgrupos")) {
                this.gruposSeleccionadosOrigen = this.gruposSeleccionadosOrigen.filter(f => f != event.dataItem.id);
                this.filtroOrigen = this.filtroOrigen.filter(f => f.idGrupo != event.dataItem.id);
            } else if (this.clasificacionesSeleccionadosOrigen.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                this.clasificacionesSeleccionadosOrigen = this.clasificacionesSeleccionadosOrigen.filter(f => f != event.dataItem.id);
                this.filtroOrigen = this.filtroOrigen.filter(f => f.idClasificacion != event.dataItem.id);
            } else {
                var idGrupo = -1, idSeccion = -1, idClasificacion = -1;
                if (event.dataItem.grouped == this.translate.instant("secciones")) {
                    this.seccionesSeleccionadasOrigen.push(event.dataItem.id);
                    idSeccion = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("xgrupos")) {
                    this.gruposSeleccionadosOrigen.push(event.dataItem.id);
                    idGrupo = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                    this.clasificacionesSeleccionadosOrigen.push(event.dataItem.id);
                    idClasificacion = event.dataItem.id;
                }

                var newFiltroRow = {
                    idGrupo: idGrupo,
                    idSeccion: idSeccion,
                    idClasificacion: idClasificacion,
                    nombre: event.dataItem.nombre
                }
                this.filtroOrigen.push(newFiltroRow);
            }
        }


        this.groupedFiltro.forEach(element => {
            if ((this.seccionesSeleccionadasOrigen.includes(element.id) && element.grouped == this.translate.instant("secciones"))
                || (this.gruposSeleccionadosOrigen.includes(element.id) && element.grouped == this.translate.instant("xgrupos"))
                || (this.clasificacionesSeleccionadosOrigen.includes(element.id) && element.grouped == this.translate.instant("clasificaciones")))
                element.isSeleccionado = true
            else
                element.isSeleccionado = false
        });

        var array = this.filtroOrigen.map(function (value) { return value.nombre })
        this.filtroStringOrigen = array.join(', ');

        this.filtrarMaquinasOrigen();
    }

    filtrarMaquinasOrigen() {

        if (this.gruposSeleccionadosOrigen.length == 0 && this.seccionesSeleccionadasOrigen.length == 0 && this.clasificacionesSeleccionadosOrigen.length == 0) {
            this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinasOriginal);
            this.setMaquinasSeleccionadasOrigen();
            return;
        }

        // para filtrar secciones y grupos
        this.maquinasAgregarOrigen = this.maquinasOriginal.filter(f => {
            // secciones
            var result = true;
            if (this.seccionesSeleccionadasOrigen.length > 0)
                this.seccionesSeleccionadasOrigen.every(s => {
                    if (f.idSeccion == s)
                        result = false
                    return result;
                })
            else
                result = false;
            // grupos
            var result2 = true;
            if (this.gruposSeleccionadosOrigen.length > 0)
                this.gruposSeleccionadosOrigen.every(s => {
                    if (f.idsGrupos.split(',').includes(s.toString()))
                        result2 = false
                    return result2;
                })
            else
                result2 = false;
            return !result && !result2;
        });

        // para filtrar clasificaciones
        if (this.clasificacionesSeleccionadosOrigen.length > 0) {
            var idClasificacionesAFiltrar = []
            this.clasificacionesSeleccionadosOrigen.forEach(element => {
                var aux = this.relacionesClasificaciones.get(element);
                idClasificacionesAFiltrar.push(...aux);
            });
            var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
            idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
            this.maquinasAgregarOrigen = this.maquinasAgregarOrigen.filter(f => idMaquinas.includes(f.id));
        }

        this.setMaquinasSeleccionadasOrigen();
    }

    buscarFiltroChangeOrigen(event) {
        if (event == "") this.groupedFiltro = this.myFunctions.copy(this.groupedFiltroOriginal);
        else this.groupedFiltro = this.groupedFiltroOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));
    }

    agruparPorSeccionesOrigen() {
        this.isAgruparPorSeccionesOrigen = true;
        this.isAgruparPorGruposOrigen = false;
        this.isAgruparPorClasificacionesOrigen = false;
    }

    agruparPorClasificacionesOrigen() {
        this.isAgruparPorSeccionesOrigen = false;
        this.isAgruparPorGruposOrigen = false;
        this.isAgruparPorClasificacionesOrigen = true;
    }

    annadirMaquinaOrigen(event) {
        //#region annadir seleccionado
        if (this.agregarSeleccionadosOrigen.includes(event.dataItem.id)) {
            this.agregarSeleccionadosOrigen = this.agregarSeleccionadosOrigen.filter(f => f != event.dataItem.id);
            this.agregarOrigenMaquinas = this.agregarOrigenMaquinas.filter(f => f.id != event.dataItem.id);
        } else {
            this.agregarSeleccionadosOrigen.push(event.dataItem.id);
            this.agregarOrigenMaquinas.push(this.maquinasOriginal.filter(f => f.id == event.dataItem.id)[0]);
        }

        // console.log(this.agregarOrigenMaquinas)
        // console.log(this.agregarSeleccionadosOrigen)
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].origen = this.myFunctions.copy(this.agregarOrigenMaquinas);

        this.setMaquinasSeleccionadasOrigen();
        //#endregion
        this.maquinasOrigen = this.maquinas.filter(f => this.agregarSeleccionadosOrigen?.includes(f.id));
    }

    ////// SELECCION MAQUINAS DESTINO

    showDestino() {
        this.agregarDestino = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    eliminarMaquinaDestino(id) {
        this.agregarSeleccionadosDestino = this.agregarSeleccionadosDestino.filter(f => f != id);
        this.maquinasDestino = this.maquinasDestino.filter(f => f.id != id);
        this.agregarDestinoMaquinas = this.agregarDestinoMaquinas.filter(f => f.id != id);

        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].destino = this.myFunctions.copy(this.agregarDestinoMaquinas)

        this.setMaquinasSeleccionadasDestino();
    }

    setMaquinasSeleccionadasDestino() {
        this.maquinasAgregarDestino.forEach(element => {
            if (this.agregarSeleccionadosDestino.includes(element.id))
                element.isSeleccionadoDestino = true;
            else
                element.isSeleccionadoDestino = false;
        })
        if (this.isAgruparPorGruposDestino)
            this.agruparPorGruposDestino();
    }

    agruparPorGruposDestino() {
        this.isAgruparPorSeccionesDestino = false;
        this.isAgruparPorGruposDestino = true;
        this.isAgruparPorClasificacionesDestino = false;

        this.maquinaParaGrupos = [];
        this.maquinasAgregarDestino.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if (this.gruposSeleccionadosDestino.includes(parseInt(row)) || this.gruposSeleccionadosDestino.length == 0) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })
    }


    buscarChangeDestino(event) {
        if (event == "") this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinasOriginal);
        else this.maquinasAgregarDestino = this.maquinasOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));

        this.maquinaParaGrupos = [];
        this.maquinasAgregarDestino.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if ((this.gruposSeleccionadosDestino.includes(parseInt(row)) || this.gruposSeleccionadosDestino.length == 0) && (element.nombre.toLowerCase().includes(event.toLowerCase()) || event == "")) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })
    }

    showFiltroDestino() {
        this.ensennarFiltroDestino = true;
        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    eliminarFiltroDestino(nombre) {
        //#region obtener el id del grupo/seccion/clasificacion
        var filtro = this.filtroDestino.filter(f => f.nombre == nombre)[0];
        if (filtro.idGrupo != -1) this.annadirFiltroSeleccionadoDestino({ dataItem: { id: filtro.idGrupo, grouped: this.translate.instant("xgrupos") } });
        else if (filtro.idSeccion != -1) this.annadirFiltroSeleccionadoDestino({ dataItem: { id: filtro.idSeccion, grouped: this.translate.instant("secciones") } });
        else if (filtro.idClasificacion != -1) this.annadirFiltroSeleccionadoDestino({ dataItem: { id: filtro.idClasificacion, grouped: this.translate.instant("clasificaciones") } });
        //#endregion
        // this.filtroDestino = this.filtroDestino.filter(f => f.nombre == nombre);
    }

    annadirFiltroSeleccionadoDestino(event = null, dataItem = null) {
        // al clickar en el grid
        if (event.type == 'change') {
            event.dataItem = dataItem;
        }

        if (event.dataItem.grouped != null) {
            if (this.seccionesSeleccionadasDestino.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("secciones")) {
                this.seccionesSeleccionadasDestino = this.seccionesSeleccionadasDestino.filter(f => f != event.dataItem.id);
                this.filtroDestino = this.filtroDestino.filter(f => f.idSeccion != event.dataItem.id);
            } else if (this.gruposSeleccionadosDestino.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("xgrupos")) {
                this.gruposSeleccionadosDestino = this.gruposSeleccionadosDestino.filter(f => f != event.dataItem.id);
                this.filtroDestino = this.filtroDestino.filter(f => f.idGrupo != event.dataItem.id);
            } else if (this.clasificacionesSeleccionadosDestino.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                this.clasificacionesSeleccionadosDestino = this.clasificacionesSeleccionadosDestino.filter(f => f != event.dataItem.id);
                this.filtroDestino = this.filtroDestino.filter(f => f.idClasificacion != event.dataItem.id);
            } else {
                var idGrupo = -1, idSeccion = -1, idClasificacion = -1;
                if (event.dataItem.grouped == this.translate.instant("secciones")) {
                    this.seccionesSeleccionadasDestino.push(event.dataItem.id);
                    idSeccion = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("xgrupos")) {
                    this.gruposSeleccionadosDestino.push(event.dataItem.id);
                    idGrupo = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                    this.clasificacionesSeleccionadosDestino.push(event.dataItem.id);
                    idClasificacion = event.dataItem.id;
                }

                var newFiltroRow = {
                    idGrupo: idGrupo,
                    idSeccion: idSeccion,
                    idClasificacion: idClasificacion,
                    nombre: event.dataItem.nombre
                }
                this.filtroDestino.push(newFiltroRow);
            }
        }


        this.groupedFiltro.forEach(element => {
            if (this.seccionesSeleccionadasDestino.includes(element.id) && element.grouped == this.translate.instant("secciones")
                || this.gruposSeleccionadosDestino.includes(element.id) && element.grouped == this.translate.instant("xgrupos")
                || this.clasificacionesSeleccionadosDestino.includes(element.id) && element.grouped == this.translate.instant("clasificaciones"))
                element.isSeleccionadoDestino = true
            else
                element.isSeleccionadoDestino = false
        });

        var array = this.filtroDestino.map(function (value) { return value.nombre })
        this.filtroStringDestino = array.join(', ');

        this.filtrarMaquinasDestino();
    }

    filtrarMaquinasDestino() {

        if (this.gruposSeleccionadosDestino.length == 0 && this.seccionesSeleccionadasDestino.length == 0 && this.clasificacionesSeleccionadosDestino.length == 0) {
            this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinasOriginal);
            this.setMaquinasSeleccionadasDestino();
            return;
        }

        // para filtrar secciones y grupos
        this.maquinasAgregarDestino = this.maquinasOriginal.filter(f => {
            // secciones
            var result = true;
            if (this.seccionesSeleccionadasDestino.length > 0)
                this.seccionesSeleccionadasDestino.every(s => {
                    if (f.idSeccion == s)
                        result = false
                    return result;
                })
            else
                result = false;
            // grupos
            var result2 = true;
            if (this.gruposSeleccionadosDestino.length > 0)
                this.gruposSeleccionadosDestino.every(s => {
                    if (f.idsGrupos.split(',').includes(s.toString()))
                        result2 = false
                    return result2;
                })
            else
                result2 = false;
            return !result && !result2;
        });

        // para filtrar clasificaciones
        if (this.clasificacionesSeleccionadosDestino.length > 0) {
            var idClasificacionesAFiltrar = []
            this.clasificacionesSeleccionadosDestino.forEach(element => {
                var aux = this.relacionesClasificaciones.get(element);
                idClasificacionesAFiltrar.push(...aux);
            });
            var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
            idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
            this.maquinasAgregarDestino = this.maquinasAgregarDestino.filter(f => idMaquinas.includes(f.id));
        }

        this.setMaquinasSeleccionadasDestino();
    }

    buscarFiltroChangeDestino(event) {
        if (event == "") this.groupedFiltro = this.myFunctions.copy(this.groupedFiltroOriginal);
        else this.groupedFiltro = this.groupedFiltroOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));
    }

    agruparPorSeccionesDestino() {
        this.isAgruparPorSeccionesDestino = true;
        this.isAgruparPorGruposDestino = false;
        this.isAgruparPorClasificacionesDestino = false;
    }

    annadirMaquinaDestino(event) {

        //#region annadir seleccionado
        if (this.agregarSeleccionadosDestino.includes(event.dataItem.id)) {
            this.agregarSeleccionadosDestino = this.agregarSeleccionadosDestino.filter(f => f != event.dataItem.id);
            this.agregarDestinoMaquinas = this.agregarDestinoMaquinas.filter(f => f.id != event.dataItem.id);
        } else {
            this.agregarSeleccionadosDestino.push(event.dataItem.id);
            this.agregarDestinoMaquinas.push(this.maquinasOriginal.filter(f => f.id == event.dataItem.id)[0]);
        }

        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].destino = this.myFunctions.copy(this.agregarDestinoMaquinas)

        this.setMaquinasSeleccionadasDestino();
        //#endregion

        this.maquinasDestino = this.maquinas.filter(f => this.agregarSeleccionadosDestino?.includes(f.id));
    }


    ////// SELECCION MAQUINAS ACTUAL
    showActual() {
        this.agregarActual = true;
        this.ensennarMaquinasActual = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    eliminarMaquinaActual(id) {
        this.agregarSeleccionadosActual = this.agregarSeleccionadosActual.filter(f => f != id);
        this.maquinasActual = this.maquinasActual.filter(f => f.id != id);
        this.agregarActualMaquinas = this.agregarActualMaquinas.filter(f => f.id != id);

        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].actual = this.myFunctions.copy(this.agregarActualMaquinas)

        this.setMaquinasSeleccionadasActual();
    }

    setMaquinasSeleccionadasActual() {
        // console.log(this.agregarSeleccionadosActual)
        this.maquinasAgregarActual.forEach(element => {
            if (this.agregarSeleccionadosActual.includes(element.id))
                element.isSeleccionado = true;
            else
                element.isSeleccionado = false;
        })

        if (this.isAgruparPorGruposActual)
            this.agruparPorGruposActual();
    }

    agruparPorGruposActual() {

        this.isAgruparPorSeccionesActual = false;
        this.isAgruparPorGruposActual = true;
        this.isAgruparPorClasificacionesActual = false;

        this.maquinaParaGrupos = [];
        this.maquinasAgregarActual.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if (this.gruposSeleccionadosActual.includes(parseInt(row)) || this.gruposSeleccionadosActual.length == 0) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })

    }

    buscarChangeActual(event) {
        if (event == "") this.maquinasAgregarActual = this.myFunctions.copy(this.maquinasOriginal);
        else this.maquinasAgregarActual = this.maquinasOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));

        this.maquinaParaGrupos = [];
        this.maquinasAgregarActual.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if ((this.gruposSeleccionadosActual.includes(parseInt(row)) || this.gruposSeleccionadosActual.length == 0) && (element.nombre.toLowerCase().includes(event.toLowerCase()) || event == "")) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })
    }

    showFiltroActual() {
        this.ensennarFiltroActual = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    eliminarFiltroActual(nombre) {
        //#region obtener el id del grupo/seccion/clasificacion
        var filtro = this.filtroActual.filter(f => f.nombre == nombre)[0];
        if (filtro.idGrupo != -1) this.annadirFiltroSeleccionadoActual({ dataItem: { id: filtro.idGrupo, grouped: this.translate.instant("xgrupos") } });
        else if (filtro.idSeccion != -1) this.annadirFiltroSeleccionadoActual({ dataItem: { id: filtro.idSeccion, grouped: this.translate.instant("secciones") } });
        else if (filtro.idClasificacion != -1) this.annadirFiltroSeleccionadoActual({ dataItem: { id: filtro.idClasificacion, grouped: this.translate.instant("clasificaciones") } });
        //#endregion
        // this.filtroActual = this.filtroActual.filter(f => f.nombre == nombre);
    }

    annadirFiltroSeleccionadoActual(event = null, dataItem = null) {
        // al clickar en el grid
        if (event.type == 'change') {
            event.dataItem = dataItem;
        }

        if (event.dataItem.grouped != null) {
            if (this.seccionesSeleccionadasActual.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("secciones")) {
                this.seccionesSeleccionadasActual = this.seccionesSeleccionadasActual.filter(f => f != event.dataItem.id);
                this.filtroActual = this.filtroActual.filter(f => f.idSeccion != event.dataItem.id);
            } else if (this.gruposSeleccionadosActual.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("xgrupos")) {
                this.gruposSeleccionadosActual = this.gruposSeleccionadosActual.filter(f => f != event.dataItem.id);
                this.filtroActual = this.filtroActual.filter(f => f.idGrupo != event.dataItem.id);
            } else if (this.clasificacionesSeleccionadosActual.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                this.clasificacionesSeleccionadosActual = this.clasificacionesSeleccionadosActual.filter(f => f != event.dataItem.id);
                this.filtroActual = this.filtroActual.filter(f => f.idClasificacion != event.dataItem.id);
            } else {
                var idGrupo = -1, idSeccion = -1, idClasificacion = -1;
                if (event.dataItem.grouped == this.translate.instant("secciones")) {
                    this.seccionesSeleccionadasActual.push(event.dataItem.id);
                    idSeccion = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("xgrupos")) {
                    this.gruposSeleccionadosActual.push(event.dataItem.id);
                    idGrupo = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                    this.clasificacionesSeleccionadosActual.push(event.dataItem.id);
                    idClasificacion = event.dataItem.id;
                }

                var newFiltroRow = {
                    idGrupo: idGrupo,
                    idSeccion: idSeccion,
                    idClasificacion: idClasificacion,
                    nombre: event.dataItem.nombre
                }
                this.filtroActual.push(newFiltroRow);
            }
        }


        this.groupedFiltro.forEach(element => {
            if ((this.seccionesSeleccionadasActual.includes(element.id) && element.grouped == this.translate.instant("secciones"))
                || (this.gruposSeleccionadosActual.includes(element.id) && element.grouped == this.translate.instant("xgrupos"))
                || (this.clasificacionesSeleccionadosActual.includes(element.id) && element.grouped == this.translate.instant("clasificaciones")))
                element.isSeleccionado = true
            else
                element.isSeleccionado = false
        });

        var array = this.filtroActual.map(function (value) { return value.nombre })
        this.filtroStringActual = array.join(', ');

        this.filtrarMaquinasActual();
    }

    filtrarMaquinasActual() {

        if (this.gruposSeleccionadosActual.length == 0 && this.seccionesSeleccionadasActual.length == 0 && this.clasificacionesSeleccionadosActual.length == 0) {
            this.maquinasAgregarActual = this.myFunctions.copy(this.maquinasOriginal);
            this.setMaquinasSeleccionadasActual();
            return;
        }

        // para filtrar secciones y grupos
        this.maquinasAgregarActual = this.maquinasOriginal.filter(f => {
            // secciones
            var result = true;
            if (this.seccionesSeleccionadasActual.length > 0)
                this.seccionesSeleccionadasActual.every(s => {
                    if (f.idSeccion == s)
                        result = false
                    return result;
                })
            else
                result = false;
            // grupos
            var result2 = true;
            if (this.gruposSeleccionadosActual.length > 0)
                this.gruposSeleccionadosActual.every(s => {
                    if (f.idsGrupos.split(',').includes(s.toString()))
                        result2 = false
                    return result2;
                })
            else
                result2 = false;
            return !result && !result2;
        });

        // para filtrar clasificaciones
        if (this.clasificacionesSeleccionadosActual.length > 0) {
            var idClasificacionesAFiltrar = []
            this.clasificacionesSeleccionadosActual.forEach(element => {
                var aux = this.relacionesClasificaciones.get(element);
                idClasificacionesAFiltrar.push(...aux);
            });
            var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
            idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
            this.maquinasAgregarActual = this.maquinasAgregarActual.filter(f => idMaquinas.includes(f.id));
        }

        this.setMaquinasSeleccionadasActual();
    }

    buscarFiltroChangeActual(event) {
        if (event == "") this.groupedFiltro = this.myFunctions.copy(this.groupedFiltroOriginal);
        else this.groupedFiltro = this.groupedFiltroOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));
    }

    agruparPorSeccionesActual() {
        this.isAgruparPorSeccionesActual = true;
        this.isAgruparPorGruposActual = false;
        this.isAgruparPorClasificacionesActual = false;
    }

    agruparPorClasificacionesActual() {
        this.isAgruparPorSeccionesActual = false;
        this.isAgruparPorGruposActual = false;
        this.isAgruparPorClasificacionesActual = true;
    }

    annadirMaquinaActual(event) {
        //#region annadir seleccionado
        if (this.agregarSeleccionadosActual.includes(event.dataItem.id)) {
            this.agregarSeleccionadosActual = this.agregarSeleccionadosActual.filter(f => f != event.dataItem.id);
            this.agregarActualMaquinas = this.agregarActualMaquinas.filter(f => f.id != event.dataItem.id);
        } else {
            this.agregarSeleccionadosActual.push(event.dataItem.id);
            this.agregarActualMaquinas.push(this.maquinasOriginal.filter(f => f.id == event.dataItem.id)[0]);
        }
        this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].actual = this.myFunctions.copy(this.agregarActualMaquinas);

        console.log(this.lineas[this.lineaIndexSelected].infoColumnas[this.columnaSelected].actual)

        this.setMaquinasSeleccionadasActual();
        //#endregion
        this.maquinasActual = this.maquinas.filter(f => this.agregarSeleccionadosActual?.includes(f.id));
    }

    // cerrarFiltroMaquinas() {
    //     if (!this.agregarDestino) {
    //         this.ensennarFiltroDestino = false;
    //         this.ensennarMaquinasDestino = false;
    //         this.agregarDestino = false;
    //     }
    //     if (!this.agregarOrigen) {
    //         this.ensennarFiltroOrigen = false;
    //         this.ensennarMaquinasOrigen = false;
    //         this.agregarOrigen = false;
    //     }
    //     if (!this.agregarActual) {
    //         this.ensennarFiltroActual = false;
    //         this.ensennarMaquinasActual = false;
    //         this.agregarActual = false;
    //     }
    // }

}