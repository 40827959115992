
<div class="row">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-9">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'cotas' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>

          <div class="card-body">

            <form [formGroup]="form" (ngSubmit)="onSubmit()">

              <div class="row">
              
                <div class="col-md-3">
                  <!--resetearFrecuencia-->
                  <div class="form-group">
                    <kendo-label text="{{ 'resetearFrecuencia' | translate }}">
                      <div class="caja">
                        <kendo-switch formControlName="resetearFrecuencia" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              
                <div class="col-md-3">
                  <!--IdControlarCotasNoCriticas-->
                  <div class="form-group">
                    <label class="control-label">{{ 'controlCotaNoCritica' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist [data]="tiposControladoresCotasNoCriticas" [textField]="'nombre'" [valueField]="'id'"
                        formControlName="idControlarCotasNoCriticas" class="form-control"
                        (selectionChange)="idControlarCotasNoCriticas_selectionChange($event)">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <!--IdControlarCotasCriticas-->
                  <div class="form-group">
                    <label class="control-label">{{ 'controlCotaCritica' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist [data]="tiposControladoresCotasCriticas" [textField]="'nombre'" [valueField]="'id'"
                        formControlName="idControlarCotasCriticas" class="form-control"
                        (selectionChange)="idControlarCotasCriticas_selectionChange($event)">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>


                <div class="col-md-3">
                  <!--IdObservacionPiezas-->
                  <div class="form-group">
                    <label class="control-label">{{ 'observacionPiezas' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist [data]="tiposObservacionPiezas" [textField]="'nombre'" [valueField]="'id'"
                        formControlName="idObservacionPiezas" class="form-control"
                        (selectionChange)="idObservacionPiezas_selectionChange($event)">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>
              
              </div>
              
              
              <div class="row">
              
                <div class="col-md-3">
                  <!--primerasPiezasNum-->
                  <div class="form-group">
                    <kendo-label text="{{ 'primerasPiezasNum' | translate }}">
                      <kendo-numerictextbox [decimals]="0" [format]="'n'" formControlName="primerasPiezasNum" class="form-control" [min]="0" [max]="1000">
                      </kendo-numerictextbox>
                    </kendo-label>
                  </div>
                </div>

                <div class="col-md-3">
                  <!--inputarSoloDecimales-->
                  <div class="form-group">
                    <kendo-label text="{{ 'inputarSoloDecimales' | translate }}">
                      <div class="caja">
                        <kendo-switch formControlName="inputarSoloDecimales" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </div>
                    </kendo-label>
                  </div>
                </div>

                <div class="col-md-3">
                  <!--mostrarValoresNoOKOperario-->
                  <div class="form-group">
                    <kendo-label text="{{ 'mostrarValoresNoOKOperario' | translate }}">
                      <div class="caja">
                        <kendo-switch formControlName="mostrarValoresNoOKOperario" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              
              </div>


            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FRECUENCIA -->
<div class="row">
  <div class="col-md-9">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'frecuencias' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>

      <div class="card-body">

        <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->

          <kendo-grid position="top" [(data)]="valores_modal" [resizable]="true"
            [selectedKeys]="mySelection" kendoGridSelectBy="id" [scrollable]="true" class="tabla-frecuencias-calidad">
            
            <kendo-grid-column hidden ngModel="dataFromABC" width="0%"></kendo-grid-column>
            <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="15%"></kendo-grid-column>
            <kendo-grid-column editor="numeric" title="{{ 'cantidad' | translate }}" width="10%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="row">
                  <div class="col">
                    <input type="number" [id]="dataItem.id" name="quantity" [disabled]="dataItem.desactivado || this.user.calidad<2" [(value)]="dataItem.cantidad" 
                    min="0" (change)="isPositive($event, false); cantidadChange(dataItem, $event);" >
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="{{ 'frecuencia' | translate }}" width="50%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="row">
                  <div class="col-3">
                    <input #porcen1 type="number" [disabled]="dataItem.desactivado || this.user.calidad<2" [(value)]="dataItem.porcen1" min="0" 
                              (change)="isPositive($event, false); recalcularFracciones(1, dataItem, $event, dataItem.id);">
                  </div>
                  <div class="col-0">
                    <p>/</p>
                  </div>
                  <div class="col-3">
                    <input #porcen2 type="number" [disabled]="dataItem.desactivado || this.user.calidad<2" [(value)]="dataItem.porcen2" min="0" 
                              (change)="isPositive($event, true); recalcularFracciones(2, dataItem, $event, dataItem.id);">
                  </div>
                <div class="col-3" >
                  <input #porcen3 type="number" [disabled]="dataItem.desactivado || this.user.calidad<2" [(value)]="dataItem.porcen3" min="0" 
                  (change)="isPositive($event, false); recalcularFracciones(3, dataItem, $event, dataItem.id);">
                </div>
                <div class="col-0">
                  <p>%</p>
                </div>
              </div>
                
                </ng-template>
            </kendo-grid-column>
              
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>            
        <!-- </form> -->

      </div>
    </div>
  </div>
</div>



<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.configuracion<2">
    {{ 'guardar' | translate }}
  </button>
</form>
