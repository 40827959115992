import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivosService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectableSettings } from "@progress/kendo-angular-treelist";

@Component({
  selector: 'app-activos-maestros-vist-arbol',
  templateUrl: './activos-maestros-vist-arbol.component.html',
  styleUrls: ['./activos-maestros-vist-arbol.component.less']
})
export class ActivosMaestrosVistArbolComponent implements OnInit {

  idActivoMaestro: number;
  idActivoSeleccionado: number;
  data: any;
  dataActivo: any;
  arbolCompleto: boolean;
  arbolAsignar: boolean;
  public user: any;
  mySelection: number[] = [];
  public settings: SelectableSettings = {
    enabled: true,
    mode: "row",
    multiple: true,
    drag: true,
    readonly: false,
  };
  constructor(private menuService: MenuService, public router: Router, private route: ActivatedRoute, 
    private userService: UsuariosService,
    private translateService: TranslateService, private activosService: ActivosService) {
      this.user = this.userService.userValue;
    this.idActivoMaestro = Number(this.route.snapshot.params['idActivo']);
    this.idActivoSeleccionado = Number(this.route.snapshot.params['idActivoSelected']);
    if(this.route.snapshot.url[2].toString() == 'arbolCompleto'){
      this.arbolCompleto = true;
      this.arbolAsignar = false;
    }else if(this.route.snapshot.url[2].toString() == 'arbolInferior'){
      this.arbolCompleto = false;
      this.arbolAsignar = false;
    }else if(this.route.snapshot.url[2].toString() == 'asignarRelacion'){
      this.arbolAsignar = true;
    }
   }

  ngOnInit(): void {
    if(this.route.snapshot.url[2].toString() == 'arbolCompleto'){
      this.menuService.titulo = this.translateService.instant('activosAsociados');
      this.activosService.getArbolCompleto().subscribe((response: any)=>{
        this.data = response;
      });
    }else if(this.route.snapshot.url[2].toString() == 'arbolInferior'){
      this.menuService.titulo = this.translateService.instant('arbolInferior');
      this.activosService.GetActivoMaestroById(this.idActivoSeleccionado).subscribe((response: any)=>{
        this.dataActivo = response[0];
        this.activosService.getArbolParcial(this.idActivoSeleccionado).subscribe((result: any)=>{
          this.data = result;
          this.data.forEach(element => {
            if(element.idPadre == this.idActivoSeleccionado){
              element.idPadre = null;
            }
          });
        });
      });
    }else if(this.route.snapshot.url[2].toString() == 'asignarRelacion'){
      this.menuService.titulo = this.translateService.instant('asignarActivo');
      this.activosService.getArbolCompleto().subscribe((response: any)=>{
        this.data = response;
      });
    }
  }

  atras(){
    this.router.navigate(['activoMaestro/' + this.idActivoMaestro]);
  }

  guardarRelacion(){
    var ids: string = "";
    this.mySelection.forEach((element: any) => {
      if (element.itemKey > 0) {        
        if (ids == "")
          ids += element.itemKey;
        else
          ids += ',' + element.itemKey;
      }
    });
    this.activosService.insertRelaciones(ids, this.idActivoMaestro).subscribe(() => {
      this.router.navigate(['activoMaestro/' + this.idActivoMaestro]);
    });
  }

}
