<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>
<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)">


  <div class="row">

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12">
      <!--OPERACION-->
      <div class="card">
        <div class="card-header">
          <h3><label>{{ 'operacion' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>


        <div class="card-body">

          <div class="row">

            <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7">
              <!--NOMBRE-->
              <div class="form-row">
                <div class="form-group col-md-6">

                  <label ID="lReferenciaPieza" class="control-label">{{ 'nombre' | translate }}</label>
                  <div class="caja">
                    <input kendoTextBox formControlName="nombre" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" (keypress)="onKeyPress($event)" />
                    <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                      <div *ngIf="f.nombre.errors.required">{{ 'nombreEsrequerido' |
                        translate}}</div>
                    </div>
                  </div>
                </div>

                <!--ID ERP-->
                <div class="form-group col-md-2">
                  <label class="check-label">{{ 'iderp' | translate }}</label>
                  <div class="checkbox">
                    <input kendoTextBox formControlName="iderp" class="form-control" (keypress)="onKeyPress($event)" />
                  </div>
                </div>

                <!--TIPO OPERACION-->
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">{{ 'tipooperacion' | translate }}</label>
                    <div class="caja">
                      <kendo-combobox [data]="tipoOperacionLista" [textField]="'nombre'" class="form-control"
                        [valueField]="'id'" formControlName="tipo_operacion"
                        (valueChange)="tipo_operacion_valueChange($event)"
                        [kendoDropDownFilter]="{operator: 'contains'}" (keypress)="onKeyPress($event)"></kendo-combobox>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">

                <!--Secuencia-->
                <div class="form-group col">
                  <label ID="lSecuencia" class="control-label"> {{ 'secuencia' | translate }}</label>
                  <div class="caja">
                    <input kendoTextBox formControlName="secuencia" class="form-control"
                      (keypress)="onKeyPress($event)" />
                  </div>
                </div>

                <!--ORDEN-->
                <div class="form-group col">
                  <label ID="lNombrePieza" class="control-label"> {{ 'orden' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="orden" [max]="999999999" [value]="orden" [decimals]="0"
                      [min]="0" [format]="'n'" (keypress)="onKeyPress($event)">
                    </kendo-numerictextbox>
                  </div>
                </div>

                <!--SUBORDEN-->
                <div class="form-group col">
                  <label ID="lERPPieza" class="control-label"> {{ 'suborden' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="suborden" [max]="999999999" [value]="suborden" [decimals]="0"
                      [min]="0" [format]="'n'" (keypress)="onKeyPress($event)">
                    </kendo-numerictextbox>
                  </div>
                </div>

              </div>

              <div class="row">

                <!--LADO-->
                <div class="form-group col">
                  <label ID="lnumeroPlano" class="control-label"> {{ 'lado' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox [max]="999999999" formControlName="lado" [value]="lado" [decimals]="0"
                      [min]="0" [format]="'n'" (keypress)="onKeyPress($event)">
                    </kendo-numerictextbox>
                  </div>
                </div>

                <!--TIEMPO ESTIMADO-->
                <div class="form-group col">
                  <label ID="lTinePartes" class="check-label">{{ 'tiempoestimado' | translate }}</label>
                  <div class="checkbox">
                    <kendo-textbox [value]="tiempoestimado" formControlName="tiempoestimado"
                      [ngClass]="{ 'is-invalid': submitted && f.tiempoestimado.errors }"
                      (keypress)="onKeyPress($event)"></kendo-textbox>
                    <div *ngIf="submitted && f.tiempoestimado.errors" class="invalid-feedback">
                      <div *ngIf="f.tiempoestimado.errors.pattern">{{ 'formatoInadecuado' |
                        translate}}</div>
                    </div>
                  </div>
                </div>

                <!--TIEMPO PREDICTIVO-->
                <div class="form-group col">
                  <label ID="lTinePartes" class="check-label">{{ 'tiempopredictivo' | translate }}</label>
                  <div class="checkbox">
                    <kendo-textbox formControlName="tiempoEstimadoPreparacion"
                      [ngClass]="{ 'is-invalid': submitted && f.tiempoEstimadoPreparacion.errors }"
                      (keypress)="onKeyPress($event)"></kendo-textbox>
                    <div *ngIf="submitted && f.tiempoEstimadoPreparacion.errors" class="invalid-feedback">
                      <div *ngIf="f.tiempoEstimadoPreparacion.errors.pattern">{{ 'formatoInadecuado' |
                        translate}}</div>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row">

                <!--COSTE-->
                <div class="form-group col">
                  <label class="check-label">{{ 'coste' | translate }}</label>
                  <div class="checkbox">
                    <kendo-numerictextbox formControlName="coste" [max]="99999999999999" [value]="coste" [decimals]="2"
                      [min]="0" [format]="'n'" (keypress)="onKeyPress($event)">
                    </kendo-numerictextbox>
                  </div>
                </div>

                <!--CANTIDAD-->
                <div class="form-group col">
                  <label class="control-label">{{ 'cantidad2' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="cantidad" [max]="999999999" [decimals]="0" [min]="0"
                      [format]="'n'" [(value)]="cantidadTotal" (keypress)="onKeyPress($event)">
                    </kendo-numerictextbox>
                  </div>
                </div>

                <!--HECHOS-->
                <div class="form-group col">
                  <label class="control-label">{{ 'hechos' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="hechos" [max]="999999999" [decimals]="0" [min]="0"
                      [format]="'n'" (keypress)="onKeyPress($event)">
                    </kendo-numerictextbox>
                  </div>
                </div>

              </div>
              <div class="row">
                <!-- ATRIBUTOS -->
                <div class="col" [style.width]="'20%'">
                  <div class="form-group">
                    <kendo-label text="{{ 'atributos' | translate }}">
                      
                      <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedAtributo"
                                          [textField]="'nombre'"
                                          [valueField]="'id'"
                                          [autoClose]="false"
                                          formControlName="atributos"
                                          [(ngModel)]="atributosSeleccionados"
                                          placeholder="{{ 'atributos' | translate }}"
                                          [kendoDropDownFilter]="{operator: 'contains'}">
                          <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                            <span class="k-icon k-i-arrow-s"></span>
                            <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                            <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'atributosSeleccionados' | translate }}</ng-container>
                          </ng-template>
                          <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                        </kendo-multiselect>
                        
                    </kendo-label>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- TEMPERATURA -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label class="control-label">{{ 'temperatura' | translate }}</label><br>
                      <kendo-numerictextbox format="#.## ºC" formControlName="temperatura"></kendo-numerictextbox>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5">
              <div class="form-row">
                <!--PIEZAS SEGUIDAS-->
                <div class="form-group mr-2">
                  <label class="control-label">{{ 'piezasseguidas' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="piezasseguidas" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>
                <!--OPERACION OBLIGATORIA-->
                <div class="form-group mr-2">
                  <label class="control-label">{{ 'operacionobligatoria' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="obligatoria" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>

                </div>

              </div>


              <div class="row">
                <!--EDICION-->
                <div class="form-group col mr-1">
                  <label class="control-label">{{ 'edicion' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="solicitaredicion" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>

                <!--LOTE-->
                <div class="form-group col mr-1">
                  <label class="control-label">{{ 'lote' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="solicitarLote" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>

                <!--NSERIE-->
                <div class="form-group col mr-1">
                  <label class="control-label">{{ 'nserie' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="solicitarNSerie" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>

                <!--COLLADA-->
                <div class="form-group col mr-1">
                  <label class="control-label">{{ 'pedircolada' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="solicitarColada" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>

                <!--TERMINADO-->
                <div class="form-group col mr-1">
                  <label class="control-label">{{ 'terminado' | translate }}</label>
                  <div class="caja">
                    <kendo-switch formControlName="terminado" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                  </div>
                </div>
              </div>
              <div class="row">
                <!--Calidad switch-->
                <div class="form-group col mr-1">
                  <label class="control-label">{{ 'calidad' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [onLabel]="' '" [offLabel]="' '" (valueChange)="onCalidadValueChange($event)"
                      [checked]="calidadActivo" [disabled]="this.user.calidad<1">
                    </kendo-switch>
                  </div>
                </div>

                <div class="form-group col mr-1" *ngIf="calidadActivo">
                  <label class="control-label">{{ 'tipooperacion' | translate }}</label>
                  <div class="caja">
                    <kendo-combobox [data]="tipooperacionCalidadLista" class="form-control sin-boton-x"
                      [textField]="'nombre'" [valueField]="'id'" formControlName="tipooperacion"
                      (valueChange)="cambioTipoOperacionCalidad($event)"
                      [kendoDropDownFilter]="{operator: 'contains'}"></kendo-combobox>
                  </div>
                </div>

                <!--INFORMATIVO-->
                <!-- <div class="form-group mr-2" *ngIf="calidadActivo">
                  <label class="control-label">{{ 'informativo' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [checked]="tipooperacion == 1" [onLabel]="' '" [offLabel]="' '"
                      (valueChange)="onInformativoValueChange($event)" [disabled]="this.user.calidad<2"></kendo-switch>
                  </div>
                </div> -->
                <!--CONFIRMACION-->
                <!-- <div class="form-group  mr-2" *ngIf="calidadActivo">
                  <label class="control-label">{{ 'confirmacion' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [checked]="tipooperacion == 3" [onLabel]="' '" [offLabel]="' '"
                      (valueChange)="onConfirmacionValueChange($event)" [disabled]="this.user.calidad<2"></kendo-switch>
                  </div>
                </div> -->
                <!--VALOR-->
                <!-- <div class="form-group mr-2" *ngIf="calidadActivo">
                  <label class="control-label">{{ 'valor' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [checked]="tipooperacion == 2" [onLabel]="' '" [offLabel]="' '"
                      (valueChange)="onValorValueChange($event)" [disabled]="this.user.calidad<2"></kendo-switch>
                  </div>
                </div> -->
                <!--VALOR MAQUINA-->
                <!-- <div class="form-group mr-2" *ngIf="calidadActivo">
                  <label class="control-label">{{ 'valormaquina' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [checked]="tipooperacion == 4" [onLabel]="' '" [offLabel]="' '"
                      (valueChange)="onValormaquinaValueChange($event)" [disabled]="this.user.calidad<2"></kendo-switch>
                  </div>
                </div> -->

              </div>

              <!-- PROGRAMA -->
              <div class="row">
                <div class="form-group col-2 mr-1">
                  <label class="control-label">{{ 'tienePrograma' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [onLabel]="' '" [offLabel]="' '" (valueChange)="tieneProgramaChange($event)"
                      [checked]="tienePrograma">
                    </kendo-switch>
                  </div>
                </div>

                <div class="form-group col-5 mr-1" *ngIf="tienePrograma">
                  <label class="control-label">{{ 'nPrograma' | translate }}</label>
                  <div class="caja">
                    <input kendoTextBox [value]="nPrograma" class="form-control" (change)="nProgramaChange($event)" placeholder="{{ 'introducePrograma' | translate }}"/>
                  </div>
                </div>
                <div class="col-5"></div>
              </div>
              <div class="row">
                <div class="form-group col-4 mr-1" *ngIf="tienePrograma">
                  <!-- <label class="control-label">{{ 'esDirectorio' | translate }}</label>
                  <div class="caja">
                    <kendo-switch [onLabel]="' '" [offLabel]="' '" (valueChange)="esDirChange($event)"
                      [checked]="rutaProgramaEsDir">
                    </kendo-switch>
                  </div> -->

                  <label class="control-label">{{ 'rutaPrograma' | translate }}</label>
                  <kendo-buttongroup selection="single" width="100%">
                    <button kendoButton type="button" [toggleable]="true" (click)="onClick(0)" iconClass="far fa-file"
                            title="modoArchivo" [selected]="!rutaProgramaEsDir">
                      {{ 'archivo' | translate }}
                    </button>
                    <button kendoButton type="button" [toggleable]="true" (click)="onClick(2)" iconClass="fas fa-route"
                            title="modoRuta" [selected]="rutaProgramaEsDir">
                      {{
                      'directorio' | translate
                      }}
                    </button>
                  </kendo-buttongroup>



                </div>
                <div class="form-group mt-4 col mr-1" *ngIf="tienePrograma">
                  <div class="caja">
                    <input kendoTextBox [value]="rutaPrograma" class="form-control"
                    placeholder="{{ 'introduceRuta' | translate }}" (change)="rutaProgramaChange($event)" />
                  </div>
                </div>
                <div class="col-1"></div>
              </div>
              <div class="form-group">
                <label ID="Limagen" class="control-label">{{ 'imagen' | translate }}</label>

                <div class="form-group ">
                  <div *ngIf="imagePreviews.length; else initImage">

                    <img *ngFor="let image of imagePreviews" [src]="image.src" alt="image preview"
                      style="width: 200px; margin: 10px;" />
                  </div>
                  <ng-template #initImage>
                    <img [src]="imageToShow" style="width: 200px; margin: 10px;">
                  </ng-template>
                  <kendo-fileselect formControlName="archivo" class="form-control" [restrictions]="restrictions"
                    [multiple]="false" (select)="selectEventHandler($event)">
                    <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                      clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                      dropFilesHere="{{ 'dropFilesHere' | translate }}"
                      externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                      fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                      fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                      filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                      filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                      filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                      headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                      headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                      headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                      invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                      invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                      invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                      remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                      retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                      uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
            </div>
            <!--OBSERVACIONES-->
            <div class="form-group col-6">
              <label class="check-label">{{ 'observacion' | translate }}</label>
              <div class="checkbox">
                <!-- <textarea formControlName="observaciones" class="form-control" style="height: 7em;"></textarea> -->
                <textarea kendoTextArea formControlName="observaciones" [autoSize]="false" maxlength="500"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12">
      <kendo-tabstrip #tabstrip [keepTabContent]="true">
        <kendo-tabstrip-tab id="tabCalidad" [title]="calidadstr" [selected]="calidadActivo" *ngIf="calidadActivo"
          [disabled]="this.user.calidad<1">
          <ng-template kendoTabContent>
            <div class="clearfix">
              <div class="" [hidden]="tipooperacion == 1 || tipooperacion == 3">
                <div class="card-header">
                  <h3><label>{{ 'configuracion' | translate }}</label></h3>
                  <div class="plegarpanel"></div>
                </div>
                <div class="card-body">
                  <div class="row">

                    <div class="col-md-3">
                      <!--resetearFrecuencia-->
                      <div class="form-group">
                        <kendo-label text="{{ 'resetearFrecuencia' | translate }}">
                          <div class="caja">
                            <kendo-switch formControlName="resetearFrecuencia" [onLabel]="' '" [offLabel]="' '">
                            </kendo-switch>
                          </div>
                        </kendo-label>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!--IdControlarCotasNoCriticas-->
                      <div class="form-group">
                        <label class="control-label">{{ 'controlarCotasDesde0NoCriticas' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [data]="tiposControladoresCotasNoCriticas" [textField]="'nombre'"
                            [valueField]="'id'" formControlName="idControlarCotasNoCriticas" class="form-control"
                            (selectionChange)="idControlarCotasNoCriticas_selectionChange($event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!--IdControlarCotasCriticas-->
                      <div class="form-group">
                        <label class="control-label">{{ 'controlarCotasDesde0Criticas' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [data]="tiposControladoresCotasCriticas" [textField]="'nombre'"
                            [valueField]="'id'" formControlName="idControlarCotasCriticas" class="form-control"
                            (selectionChange)="idControlarCotasCriticas_selectionChange($event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!--IdObservacionPiezas-->
                      <div class="form-group">
                        <label class="control-label">{{ 'observacionPiezas' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [data]="tiposObservacionPiezas" [textField]="'nombre'" [valueField]="'id'"
                            formControlName="idObservacionPiezas" class="form-control"
                            (selectionChange)="idObservacionPiezas_selectionChange($event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!--primerasPiezasNum-->
                      <div class="form-group">
                        <kendo-label text="{{ 'primerasPiezasNum' | translate }}">
                          <kendo-numerictextbox formControlName="primerasPiezasNum" class="form-control" [min]="0"
                            [max]="1000" [decimals]="0" [format]="'n'">
                          </kendo-numerictextbox>
                        </kendo-label>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!--inputarSoloDecimales-->
                      <div class="form-group">
                        <kendo-label text="{{ 'inputarSoloDecimales' | translate }}">
                          <div class="caja">
                            <kendo-switch formControlName="inputarSoloDecimales" [onLabel]="' '" [offLabel]="' '">
                            </kendo-switch>
                          </div>
                        </kendo-label>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <!--mostrarValoresNoOKOperario-->
                      <div class="form-group">
                        <kendo-label text="{{ 'mostrarValoresNoOKOperario' | translate }}">
                          <div class="caja">
                            <kendo-switch formControlName="mostrarValoresNoOKOperario" [onLabel]="' '" [offLabel]="' '">
                            </kendo-switch>
                          </div>
                        </kendo-label>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix">
              <div class="form-group" *ngIf="tipooperacion == 3">
                <!--MENSAJE-->
                <div class="form-group">
                  <label ID="lNombrePieza" class="control-label"> {{ 'mensaje' | translate }}</label>
                  <div class="caja">
                    <textarea formControlName="mensaje" class="form-control"></textarea>

                  </div>
                </div>
                <!--RETROCEDER-->
                <div class="form-group">
                  <div class="caja">
                    <kendo-label text="{{ 'retroceder' | translate }}">
                      <kendo-dropdownlist [data]="operacionesHermanas" clearButton="true"
                        formControlName="retroceder_data" [textField]="'nombre'" [valueField]="'id'"
                        class="form-control"></kendo-dropdownlist>
                    </kendo-label>
                  </div>
                </div>

              </div>
            </div>
            <div class="" [hidden]="tipooperacion == 1 || tipooperacion == 3">
              <div class="card-header">
                <h3><label>{{ 'valores' | translate }}</label></h3>
                <div class="plegarpanel"></div>
              </div>
              <div class="card-body">
                <div class="caja">
                  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
                    (mouseover)="showTooltip($event)">
                    <kendo-grid position="top" [(data)]="valores" [resizable]="true" (remove)="removeHandler($event)"
                      (add)="onClickNuevoValor($event)" (dataStateChange)="onStateChange($event)"
                      [selectedKeys]="mySelection" kendoGridSelectBy="idNuevo" [scrollable]="true">
                      <ng-template kendoGridToolbarTemplate>
                        <button id="bNuevo" kendoGridAddCommand class="btn btn-primary btn-sm mr-1"
                          [disabled]="this.user.calidad<2">{{ 'nuevo' |
                          translate
                          }}</button>
                        <button id="bEliminar" kendoGridRemoveCommand class="btn btn-danger btn-sm mr-1"
                          [disabled]="this.user.calidad<2">{{ 'eliminar' |
                          translate }}</button>
                        <button id="bExportar" [disabled]="this.user.calidad<2" kendoGridExcelCommand class="btn btn-excel btn-sm mr-1" style="color: white;"
                          >{{ 'exportar' |
                          translate }}</button>
                        <button id="bReordenar" (click)="reorderData($event)" class="btn btn-naranja btn-sm mr-1"
                          style="color: white;" [disabled]="this.user.calidad<2">{{ 'reordenar' | translate }}</button>
                        <button id="bCopiar" (click)="copyData($event)" class="btn btn-copiar btn-sm mr-1"
                          [disabled]="this.user.calidad<2">{{ 'copiar' |
                          translate }}</button>
                        <!-- <div class="form-group mb-0 float-right"
                          style="float: right; margin-left: 20px; margin-right: 20px">
                          <div class="form-row">
                            <label class="control-label mt-1 mr-2">{{ 'importarCotas' | translate }}</label>
                            <kendo-upload (upload)="onUploadButtonClick($event,contentloadingupload)"
                              [restrictions]="{allowedExtensions: ['.xlsx']  }" [(value)]="myFiles2"
                              [disabled]="this.user.calidad<2">
                              <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                pause="{{ 'pause' | translate }}" remove="{{ 'remove' | translate }}"
                                resume="{{ 'resume' | translate }}" retry="{{ 'retry' | translate }}"
                                select="{{ 'select' | translate }}"
                                uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                              </kendo-upload-messages>
                            </kendo-upload>
                          </div>
                        </div> -->
                      </ng-template>
                      <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
                      <kendo-grid-column field="id" hidden="hidden" title="ID"></kendo-grid-column>
                      <kendo-grid-column editor="numeric" title="{{ 'orden' | translate }}" width="8%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox [(value)]="dataItem.orden" [decimals]="0" [format]="'n'" [min]="1"
                            [max]="999999" [disabled]="this.user.calidad<2"></kendo-numerictextbox>
                        </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column editor="boolean" title="{{ 'visible' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-switch [(checked)]="dataItem.visible" [(value)]="dataItem.visible" [onLabel]="' '"
                            [offLabel]="' '"></kendo-switch>
                        </ng-template>
                      </kendo-grid-column> -->
                      <!-- <kendo-grid-column title="{{ 'referencia' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-textbox [(value)]="dataItem.referencia"></kendo-textbox>
                        </ng-template>
                      </kendo-grid-column> -->
                      <kendo-grid-column title="{{ 'nombre' | translate }}" width="12%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-textbox [(value)]="dataItem.nombre" [disabled]="this.user.calidad<2"></kendo-textbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="tipoControl" title="{{ 'tipoControl' | translate }}" width="12%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-dropdownlist style="width: 100%;" [data]="datosControl" [textField]="'nombre'"
                            [valueField]="'id'" [(value)]="dataItem.tipoControl"
                            (valueChange)="cambioTolerancia(dataItem, $event)" [disabled]="this.user.calidad<2">
                          </kendo-dropdownlist>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="tipoTolerancia" title="{{ 'tipoTolerancia' | translate }}" width="12%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-dropdownlist style="width: 100%;" [data]="dataItem.datosTolerancia"
                            [textField]="'nombre'" [valueField]="'id'" [(value)]="dataItem.tipoTolerancia"
                            [disabled]="this.user.calidad<2">
                          </kendo-dropdownlist>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column *ngIf="tipooperacion == 4" field="eje" title="{{ 'eje' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-textbox [(value)]="dataItem.eje" [disabled]="this.user.calidad<2"></kendo-textbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="valornominal" title="{{ 'valornominal' | translate }}" width="8%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox [(value)]="dataItem.vnominal" [step]="0.1" [min]="0" [max]="999999"
                            [disabled]="this.user.calidad<2">
                          </kendo-numerictextbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="minTolerancia" title="{{ 'minTolerancia' | translate }}" width="8%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox *ngIf="dataItem.tipoTolerancia.idValor_Tolerancia !== undefined && dataItem.tipoTolerancia.idValor_Tolerancia === 2"
                            [(value)]="dataItem.toleranciaMin" [step]="0.1" [min]="0" [max]="999999"
                            [disabled]="this.user.calidad<2">
                          </kendo-numerictextbox>
                          <span *ngIf="dataItem.tipoTolerancia.idValor_Tolerancia !== undefined && dataItem.tipoTolerancia.idValor_Tolerancia === 1">{{'okNoOk' | translate}}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="maxTolerancia" title="{{ 'maxTolerancia' | translate }}" width="8%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox *ngIf="dataItem.tipoTolerancia.idValor_Tolerancia !== undefined && dataItem.tipoTolerancia.idValor_Tolerancia === 2"
                            [(value)]="dataItem.toleranciaMax" [step]="0.1" [min]="0" [max]="999999"
                            [disabled]="this.user.calidad<2">
                          </kendo-numerictextbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="unidad" title="{{ 'unidad' | translate }}" width="7%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-textbox *ngIf="dataItem.tipoTolerancia.idValor_Tolerancia !== undefined && dataItem.tipoTolerancia.idValor_Tolerancia === 2"
                            [(value)]="dataItem.unidades" [disabled]="this.user.calidad<2"></kendo-textbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="critico" editor="boolean" title="{{ 'critico' | translate }}" width="6%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-switch [(checked)]="dataItem.critico" [(value)]="dataItem.critico" [onLabel]="' '"
                            [offLabel]="' '" [disabled]="this.user.calidad<2"></kendo-switch>
                        </ng-template>
                      </kendo-grid-column>
                      <!--                       <kendo-grid-column title="{{ 'descripcion' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-textbox [(value)]="dataItem.descripcion"></kendo-textbox>
                        </ng-template>
                      </kendo-grid-column> -->
                      <!--  <kendo-grid-column editor="boolean" title="{{ 'esfuncion' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-switch [(checked)]="dataItem.esFuncion" [(value)]="dataItem.esFuncion" [onLabel]="' '"
                            [offLabel]="' '"></kendo-switch>
                        </ng-template>
                      </kendo-grid-column>-->
                      <kendo-grid-column field="funcion" title="{{ 'funcion' | translate }}" width="10%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-textbox [(value)]="dataItem.funcion" [disabled]="this.user.calidad<2"></kendo-textbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column editor="boolean"  title="{{ 'imagen' | translate }}" width="6%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-switch [(checked)]="dataItem.imagen" [(value)]="dataItem.imagen" [onLabel]="' '"
                            [offLabel]="' '" [disabled]="this.user.calidad<2"></kendo-switch>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="utillaje" title="{{ 'utillaje' | translate }}" width="12%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-combobox clearButton="true" valuePrimitive="true" [data]="utillajeModal"
                            [allowCustom]="false" [(value)]="dataItem.idUtillaje" class="form-control"
                            textField="nombre" valueField="id" [disabled]="this.user.calidad<2">
                          </kendo-combobox>
                        </ng-template>
                      </kendo-grid-column>
                      <!-- <kendo-grid-column editor="boolean" title="{{ 'porDefecto' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-switch [(checked)]="dataItem.porDefecto" [(value)]="dataItem.porDefecto"
                            (valueChange)="onChangePorDefecto(dataItem, $event)" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column title="{{ 'cantidad' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox [disabled]="dataItem.porDefecto" [(value)]="dataItem.cantidad"
                            [max]="999999999" [decimals]="0" [min]="0" [format]="'n'" [step]="1">
                          </kendo-numerictextbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column title="{{ 'procesar' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox [disabled]="dataItem.porDefecto" [(value)]="dataItem.procesar"
                            [max]="999999999" [decimals]="0" [min]="0" [format]="'n'" [step]="1">
                          </kendo-numerictextbox>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column title="{{ 'resto' | translate }}" width="5%">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <kendo-numerictextbox [disabled]="dataItem.porDefecto" [(value)]="dataItem.resto"
                            [max]="999999999" [decimals]="0" [min]="0" [format]="'n'" [step]="1">
                          </kendo-numerictextbox>
                        </ng-template>
                      </kendo-grid-column>  -->
                      <kendo-grid-column title="{{ 'frecuencias' | translate }}" width="7%" class="p-0">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <button class="btn btn-primary btn-sm mr-1"
                            (click)="abrirModalFrecuencias(dataItem, $event)">{{ 'frecuenciaAcortado' | translate }}
                          </button>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column title="{{ 'documento' | translate }}" width="5%" class="p-0">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <button type="button" class="btn-success btn" (click)="clickModalRuta(dataItem, $event)"
                            [disabled]="this.user.calidad<2"><i class="fas fa-link"></i></button>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-excel fileName="{{ 'valores' | translate }}.xlsx"  [fetchData]="allData">
                        <kendo-excelexport-column field="orden" title="{{ 'orden' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="nombre" title="{{ 'nombre' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="tipoControl" title="{{ 'tipoControl' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="tipoTolerancia" title="{{ 'tipoTolerancia' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="eje" title="{{ 'eje' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="valornominal" title="{{ 'valornominal' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="minTolerancia" title="{{ 'minTolerancia' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="maxTolerancia" title="{{ 'maxTolerancia' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="unidad" title="{{ 'unidad' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="critico" title="{{ 'critico' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="funcion" title="{{ 'funcion' | translate }}"> </kendo-excelexport-column>
                        <kendo-excelexport-column field="utillaje" title="{{ 'utillaje' | translate }}"> </kendo-excelexport-column>
                      </kendo-grid-excel>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                      filterContainsOperator="{{'filterContainsOperator' | translate}}"
                      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                    </kendo-grid>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix">
              <!--OBSERVACIONES-->
              <div class="form-group" *ngIf="(tipooperacion == 1 || tipooperacion == 3)">
                <label class="check-label">{{ 'observacion' | translate }}</label>
                <div class="checkbox">
                  <textarea class="form-control" formControlName="calidadObservaciones" style="height: 7em;"></textarea>
                </div>
              </div>
            </div>

          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab id="tabLimites" [title]="limitesstr" [selected]="!calidadActivo">
          <ng-template kendoTabContent>
            <div class="card">
              <div class="card-body">
                <div class="row">

                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">

                    <div class="row">
                      <!-- FECHA DE INICIO -->
                      <div class="col">

                        <div class="form-group">
                          <label ID="Lfechaini" class="control-label">{{ 'fechainicio' | translate}}</label>
                          <div class="caja">
                            <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..."
                              formControlName="fechainicio" id="rntfechainicio" class="form-control">
                            </kendo-datepicker>
                          </div>
                        </div>
                      </div>
                      <!-- FECHA FINAL -->
                      <div class="col">
                        <div class="form-group">
                          <label ID="Lfechafin" class="control-label">{{ 'fechaentrega' | translate}}</label>
                          <div class="caja">
                            <kendo-datepicker placeholder="{{ 'fechaentregap' | translate}}..."
                              formControlName="fechafin" id="rntfechafin" class="form-control">
                            </kendo-datepicker>
                          </div>
                        </div>
                      </div>
                      <!-- PRIORIDAD -->
                      <!--<div class="col">
                  <div class="form-group">
                    <label ID="Lprioridad" class="control-label">{{ 'prioridad' | translate }}</label>
                    <div class="caja">
                      <kendo-numerictextbox [(value)]="prioridad" formControlName="prioridad" [format]="'n'" [decimals]="0" [min]="0" [max]="100" [autoCorrect]="true">
                      </kendo-numerictextbox>
                    </div>
                  </div>
                </div>-->
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                      <!-- FUERA DE TALLER -->
                      <div class="col">
                        <div class="form-group form-check">
                          <kendo-label for="customSwitch11" class="form-check-label"
                            text="{{ 'fuerataller' | translate }}">
                          </kendo-label>
                          <div class="caja">
                            <kendo-switch id="customSwitch11" formControlName="fuerataller" [onLabel]="' '"
                              [offLabel]="' '"> </kendo-switch>
                          </div>
                        </div>
                      </div>

                      <!-- FUERA DE TURNO -->
                      <div class="col">
                        <div class="form-group form-check">
                          <kendo-label for="customSwitch12" class="form-check-label"
                            text="{{ 'fueraturno' | translate }}">
                          </kendo-label>
                          <div class="caja">
                            <kendo-switch id="customSwitch12" formControlName="fueraturno" [onLabel]="' '"
                              [offLabel]="' '"> </kendo-switch>
                          </div>
                        </div>
                      </div>
                      <!-- PROCESO CONTINUO -->
                      <div class="col">
                        <div class="form-group form-check">
                          <kendo-label for="customSwitch13" class="form-check-label"
                            text="{{ 'procesocontinuo' | translate }}">
                          </kendo-label>
                          <div class="caja">
                            <kendo-switch id="customSwitch13" formControlName="continuo" [onLabel]="' '"
                              [offLabel]="' '" (valueChange)="onContinuoValueChange($event)"> </kendo-switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div class="row" *ngIf="false">
                  <!-- INEFIFICENCIA -->
                  <div class="col">
                    <div class="form-group form-check">
                      <kendo-label for="customSwitch14" class="form-check-label" text="{{ 'ineficencia' | translate }}">
                      </kendo-label>
                      <div class="caja">
                        <kendo-switch id="customSwitch14" formControlName="ineficencia" [onLabel]="' '" [offLabel]="' '"
                          (valueChange)="onIneficenciaValueChange($event)"> </kendo-switch>
                      </div>
                    </div>
                  </div>
                  <!-- CAMBIO ENTRE MAQUINAS -->
                  <div class="col">
                    <div class="form-group form-check">
                      <kendo-label for="customSwitch15" class="form-check-label"
                        text="{{ 'cambiomaquinas' | translate }}">
                      </kendo-label>
                      <div class="caja">
                        <kendo-switch id="customSwitch15" formControlName="cambiomaquina" [onLabel]="' '"
                          [offLabel]="' '" (valueChange)="onCambiomaquinaValueChange($event)"> </kendo-switch>
                      </div>
                    </div>
                  </div>
                  <!-- TIEMPO ENTRE MAQUINAS -->
                  <div class="col">
                    <div class="form-group" *ngIf="cambiomaquina==true">

                      <label ID="Lprioridad" class="control-label">{{ 'tiempoentremaquinas' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="tiempocambiomaquina" formControlName="tiempocambiomaquina"
                          [format]="'n'" [decimals]="0" [min]="0" [max]="100" [autoCorrect]="true">
                        </kendo-numerictextbox>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="false">
                  <label ID="Label1" class="title-conf"></label>
                </div>
                <!-- DIV MAQUINAS -->
                <div id="divMaquinas" class="clearfix">


                  <label ID="LprioridadesMaquinas" class="control-label  bloque-titulo"></label>
                  <!-- DIV TODAS LAS MAQUINAS -->
                  <div id="maquinas" class="planificador-limite-proyecto-maquinas">
                    <div class="clickable maquinaLimites" *ngFor="let maquina of maquinas; let last = last">
                      <label title="{{maquina.nombre}}">{{maquina.nombre}}</label>
                      <img src="{{maquina.imagenBase64}}" />
                      <div>
                        <ul>
                          <input type="hidden" value="{{maquina.id}}" />
                          <li class="prioridadMaquina">
                            <a (click)="onClickPrioridadMaquina($event)" data-porcentaje="0" title="0 %"
                              style="height:20px;width:20px;background-image:url(assets/rating0.png);background-position:0px 0px;">
                              <span *ngIf="maquina.prioridad!=0" class="prioridad-seleccionada"
                                id="{{'0-' + maquina.id}}"
                                style="    height: 20px;background-image: url(assets/rating0.png); background-position: 0px 0px;"></span>

                            </a>
                          </li>
                          <li class="prioridadMaquina">
                            <a (click)="onClickPrioridadMaquina($event)" data-porcentaje="25" title="25 %"
                              style="height:20px;width:20px;background-image:url(assets/rating25.png);background-position:0px 0px;">
                              <span *ngIf="maquina.prioridad!=25" class="prioridad-seleccionada"
                                id="{{'25-' + maquina.id}}"
                                style="height:20px;background-image:url(assets/rating25s.png);background-position:0px 0px;"></span>

                            </a>
                          </li>
                          <li class="prioridadMaquina">
                            <a (click)="onClickPrioridadMaquina($event)" data-porcentaje="50" title="50 %"
                              style="height:20px;width:20px;background-image:url(assets/rating50.png);background-position:0px 0px;">

                              <span *ngIf="maquina.prioridad!=50" class="prioridad-seleccionada"
                                id="{{'50-' + maquina.id}}"
                                style="height:20px;background-image:url(assets/rating50s.png);background-position:0px 0px;"></span>

                            </a>
                          </li>
                          <li class="prioridadMaquina">
                            <a (click)="onClickPrioridadMaquina($event)" data-porcentaje="75" title="75 %"
                              style="height:20px;width:20px;background-image:url(assets/rating75.png);background-position:0px 0px;">
                              <span *ngIf="maquina.prioridad!=75" class="prioridad-seleccionada"
                                id="{{'75-' + maquina.id}}"
                                style="height:20px;background-image:url(assets/rating75s.png);background-position:0px 0px;"></span>

                            </a>
                          </li>
                          <li class="prioridadMaquina">
                            <a (click)="onClickPrioridadMaquina($event)" data-porcentaje="100" title="100 %"
                              style="height:20px;width:20px;background-image:url(assets/rating100.png);background-position:0px 0px;">
                              <span *ngIf="maquina.prioridad!=100" class="prioridad-seleccionada"
                                id="{{'100-' + maquina.id}}"
                                style="height:20px;background-image:url(assets/rating100s.png);background-position:0px 0px;"></span>

                            </a>
                          </li>
                          <ng-container *ngIf="last && !contentPrinted">
                            {{ initMaquinas() }}
                          </ng-container>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab id="tabEscandallo" [title]="escandallostr">
          <ng-template kendoTabContent>
            <!--ESCANDALLO-->
            <div class="card">
              <div class="card-body">

                <div class="row">
                  <!--ESCANDALLO-->
                  <div class="form-group col">
                    <label ID="lReferenciaPieza" class="control-label">{{ 'escandallo' | translate }}</label>
                    <div class="caja">
                      <!--<input kendoTextBox formControlName="escandallo" class="form-control" [value]="escandallo" />-->
                      <kendo-dropdownlist defaultItem="" valuePrimitive="true" clearButton="true"
                        formControlName="escandallo" [data]="escandallo" class="form-control" textField="nombre"
                        valueField="id">
                      </kendo-dropdownlist>

                    </div>
                  </div>
                  <!--CANTIDAD-->
                  <div class="form-group col">

                    <label class="control-label">{{ 'cantidad2' | translate }}</label>
                    <div class="caja">
                      <kendo-numerictextbox formControlName="cantidadescandallo" [value]="cantidadescandallo"
                        [decimals]="0" [min]="0" [format]="'n'">
                      </kendo-numerictextbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab id="tabHerramientas" [title]="herramientasstr">
          <ng-template kendoTabContent>
            <kendo-grid [data]="gridData" [rowClass]="rowCallback" [selectedKeys]="herramientasSelected"
              class="grid-herramientas" [selectable]="{enabled: true, checkboxOnly: true}" kendoGridSelectBy="idNuevo"
              [navigable]="true" [sortable]="true" scrollable="virtual" [rowHeight]="36" [height]="500" [pageSize]="50"
              filterable="menu" [resizable]="true">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button class="btn btn-primary btn-sm mr-1" (click)="agregarHerramienta($event)">
                  {{ 'agregarHerramienta' | translate}}
                </button>
                <button class="btn btn-danger btn-sm mr-1" (click)="eliminarHerramienta($event)"
                  [disabled]="this.user.operaciones<2">
                  {{ 'eliminar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="10%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="referencia" title="{{ 'referencia' | translate }}"
                width="10%"></kendo-grid-column>
              <kendo-grid-column width="20%" field="nombre" title="{{ 'nombre' | translate}}"
                [style]="{'text-align': 'left'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.nombre}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="20%"></kendo-grid-column>
              <kendo-grid-column title="{{ 'tiempoUso' | translate }}" field="tiempoUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-textbox [(value)]="dataItem.tiempoUso"
                    (valueChange)="updateDatosHerramienta(dataItem, $event, 0)"
                    [ngClass]="{ 'is-invalid': dataItem.errorTiempoEstimado || dataItem.errorTiempos }"></kendo-textbox>
                  <div *ngIf="dataItem.errorTiempoEstimado" class="invalid-feedback">
                    <div *ngIf="dataItem.errorTiempoEstimado">
                      {{
                      'formatoInadecuado' |
                      translate
                      }}
                    </div>
                  </div>
                  <div *ngIf="dataItem.errorTiempos" class="invalid-feedback">
                    <div *ngIf="dataItem.errorTiempos">
                      {{
                      'tiempoPorcenUsoNoPuedenSuperarTope' |
                      translate
                      }}
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="{{ 'porcentajeUso' | translate }}" field="porcenUso" width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.porcenUso" [step]="0.1" [min]="0.0"
                    [ngClass]="{ 'is-invalid': dataItem.errorTiempos }" [max]="100.0" [autoCorrect]="true"
                    (valueChange)="updateDatosHerramienta(dataItem, $event, 1)">
                  </kendo-numerictextbox>
                  <div *ngIf="dataItem.errorTiempos" class="invalid-feedback">
                    <div *ngIf="dataItem.errorTiempos">
                      {{
                      'tiempoPorcenUsoNoPuedenSuperarTope' |
                      translate
                      }}
                    </div>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="{{ 'fiabilidadAceptable' | translate }}" field="fiabilidadAceptable"
                width="20%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.fiabilidad" [step]="0.1" [min]="0.0" [max]="100.0"
                    [autoCorrect]="true">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="{{ 'vc' | translate }}" field="vc" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.vc" [step]="10" [min]="0" [max]="1000.0"
                    [autoCorrect]="true">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="{{ 'f' | translate }}" field="f" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.f" [step]="0.1" [min]="0" [max]="100.0"
                    [autoCorrect]="true">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="{{ 'ap_ini' | translate }}" field="ap_ini" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.ap_ini" [step]="0.1" [min]="0" [max]="100.0"
                    [autoCorrect]="true">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="{{ 'Δap' | translate }}" field="ap" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.ap" [step]="0.1" [min]="0" [max]="100.0"
                    [autoCorrect]="true">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="{{ 'd_min' | translate }}" field="d_min" width="12%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-numerictextbox [(value)]="dataItem.d_min" [step]="10" [min]="0" [max]="1000.0"
                    [autoCorrect]="true">
                  </kendo-numerictextbox>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </ng-template>
        </kendo-tabstrip-tab>



        <kendo-tabstrip-tab id="tabProgramas" [title]="programasstr">
          <ng-template kendoTabContent>
            <kendo-grid [data]="gridProgramas" [selectedKeys]="programasSelected"
              class="grid-programas" [selectable]="{enabled: true, checkboxOnly: true}" kendoGridSelectBy="idNuevo"
              [navigable]="true" [sortable]="true" scrollable="virtual" [rowHeight]="36" [height]="500" [pageSize]="50"
              filterable="menu" [resizable]="true">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button class="btn btn-primary btn-sm mr-1" (click)="agregarPrograma($event)">
                  {{ 'agregarPrograma' | translate}}
                </button>
                <button class="btn btn-danger btn-sm mr-1" (click)="eliminarPrograma($event)"
                  [disabled]="this.user.operaciones<2">
                  {{ 'eliminar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="10%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="nombrePrograma" title="{{ 'nombrePrograma' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="version" title="{{ 'version' | translate}}"  width="30%" [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{myFunctions.sumarleLaVALaVersion(dataItem.version)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechaVersion" title="{{ 'fecha' | translate}}"  width="30%"
                [style]="{'text-align': 'center'}">
                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                    {{myFunctions.dateToDayString(parseDate(dataItem.fechaVersion))}}</ng-template> -->
              </kendo-grid-column>
              <kendo-grid-column field="tipo" title="{{ 'tipo' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.tipoToText(dataItem.tipo)}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="pieza" title="{{ 'pieza' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate}}" width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="cliente" title="{{ 'cliente' | translate}}" width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="numeroOF" title="{{ 'of' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column *ngIf="!user.ocultarParte" field="parte" title="{{ 'parte' | translate}}"  width="30%"
                [style]="{'text-align': 'left'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="operario" title="{{ 'operario' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}" class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <!-- <span class="tooltip-consumibles-contenido"> -->
                      <ng-container >
                        <span class="grid-circulo"
                              [style.background-color]="dataItem.operarioColor+ '!important'">{{dataItem.operarioSiglas}}</span>
                        </ng-container>
                      <!-- <span class="tooltiptext">
                        <ng-container >
                          <label> {{dataItem.operario}} </label>
                        </ng-container>
                      </span> -->
                    <!-- </span> -->
                  </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="nombreMaquina" title="{{ 'maquina' | translate}}" width="30%"
                [style]="{'text-align': 'left'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="tMedio" title="{{ 'tiempo_medio' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tMedio)}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tMejor" title="{{ 'mejor_tiempo' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tMejor)}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="cantidad" title="{{ 'cantidad2' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="porcenCalidad" title="{{ '%OK' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              </kendo-grid-column>
              
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages> 
            </kendo-grid>
          </ng-template>
        </kendo-tabstrip-tab> 



        <kendo-tabstrip-tab id="tabRecetas" [title]="recetasstr" *ngIf="f.tipo_operacion_id.value == 2 && !isAddMode">
          <ng-template kendoTabContent>
            <kendo-grid [data]="listaRecetas" [selectedKeys]="recetasSelected" class="grid-herramientas"
              (cellClick)="recetaClick($event)" [selectable]="{enabled: true, checkboxOnly: true}"
              kendoGridSelectBy="id" [navigable]="true" [sortable]="true" [height]="500" filterable="menu"
              [resizable]="true">
              <!--BOTONES ARRIBA-->
              <ng-template kendoGridToolbarTemplate position="top">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="agregarReceta($event)">
                  {{ 'agregarReceta' | translate}}
                </button>
                <button type="button" class="btn btn-danger btn-sm mr-1" (click)="eliminarReceta(contentRecetas)"
                  [disabled]="loading || this.user.operaciones<2">
                  {{ 'eliminar' | translate}}
                </button>
              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="96%"></kendo-grid-column>

              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>



  </div>

  <script>
    window.onload = "inidtMaquinas()";
  </script>











  <div class="form-group">

    <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.operaciones<2">
      {{ 'guardar' | translate}}
    </button>
    <a class="btn btn-danger btn-sm mr-1" (click)="Atras()">
      {{ 'cancelar' | translate}}
    </a>
    <!-- <a class="btn btn-danger btn-sm mr-1" *ngIf="isAddMode==false" (click)="onClickEliminarOperacion(content)">
     {{ 'eliminar' | translate}}
   </a>-->
    <button class="btn btn-copiar btn-sm mr-1" [disabled]="isAddMode || this.user.operaciones<2"
      (click)="onClickDuplicarOperacion(contentloading, $event)">
      {{ 'duplicar' | translate}}
    </button>
  </div>
</form>

<ng-template #content let-modal>
  <form (submit)="onSubmit($event)">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' |
        translate }}</button>
      <button type="button" class="btn btn-danger" (click)="Eliminar(contentloading)"
        [disabled]="this.user.operaciones<2">{{ 'si' | translate }}</button>
    </div>
  </form>
</ng-template>

<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup"></p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<!-- POPUP: Borrar elem lista -->
<ng-template #popupBorrar let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntaeliminarpopup' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="removeElements()" [disabled]="this.user.operaciones<2">{{
      'aceptar' | translate }}</button>
  </div>
</ng-template>


<!-- POPUP: Copiar elem lista -->
<ng-template #popupCopiar let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'copiarDe' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarCopia()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="">
      <!-- <div class="card-header">
        <h3><label>{{ 'copiarDe' | translate }}</label></h3>
      </div> -->
      <div class="card-body">
        <!--LISTA OFS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'of' | translate }}</label>
            <div class="caja">
              <kendo-combobox (valueChange)="CambioFiltro()" [data]="listaOfs" [(ngModel)]="ofsSeleccionados"
                [textField]="'nombreOf'" [valueField]="'idOf'" placeholder="{{ 'seleccioneOf' | translate }}"
                [autoClose]="false" style="width: 275px;" [kendoDropDownFilter]="{operator: 'contains'}">
                <kendo-combobox-messages noDataText="{{'norecords' | translate}}"></kendo-combobox-messages>
              </kendo-combobox>
            </div>
          </div>
        </div>

        <!--LISTA PIEZAS-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'pieza' | translate }}</label>
            <div class="caja">
              <kendo-combobox [disabled]="!ofsListas" (valueChange)="CambioFiltro()" [data]="listaPiezas"
                [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 275px;"
                [kendoDropDownFilter]="{operator: 'contains'}">
                <kendo-combobox-messages noDataText="{{'norecords' | translate}}"></kendo-combobox-messages>
              </kendo-combobox>
            </div>
          </div>
        </div>

        <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
        <div class="float-left mr-2" *ngIf="!user.ocultarParte">
          <div class="form-group">
            <label>{{ 'parte' | translate }}</label>
            <div class="caja">
              <kendo-combobox [disabled]="!piezasListas" [kendoDropDownFilter]="{operator: 'contains'}"
                (valueChange)="CambioFiltro()" [data]="listaPartes" [(ngModel)]="partesSeleccionadas"
                [textField]="'nombreParte'" [valueField]="'idParte'" placeholder="{{ 'seleccioneParte' | translate }}"
                [autoClose]="false" style="width: 295px;">
                <kendo-combobox-messages noDataText="{{'norecords' | translate}}"></kendo-combobox-messages>
              </kendo-combobox>
            </div>
          </div>
        </div>

        <!--LISTA RUTAS -->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'ruta' | translate }}</label>
            <div class="caja">
              <kendo-combobox [disabled]="!partesListas" [kendoDropDownFilter]="{operator: 'contains'}"
                (valueChange)="CambioFiltro()" [data]="listaRutas" [(ngModel)]="rutasSeleccionadas"
                [textField]="'nombreRuta'" [valueField]="'idRuta'" placeholder="{{ 'seleccioneRuta' | translate }}"
                [autoClose]="false" style="width: 295px;">
                <kendo-combobox-messages noDataText="{{'norecords' | translate}}"></kendo-combobox-messages>
              </kendo-combobox>
            </div>
          </div>
        </div>

        <!--LISTA OPERACIONES-->
        <div class="float-left mr-2">
          <div class="form-group">
            <label>{{ 'operacion' | translate }}</label>
            <div class="caja">
              <kendo-combobox [disabled]="!rutasListas" [kendoDropDownFilter]="{operator: 'contains'}"
                (valueChange)="CambioFiltro()" [data]="listaOperaciones" [(ngModel)]="operacionesSeleccionadas"
                [textField]="'nombreOperacion'" [valueField]="'idOperacion'"
                placeholder="{{ 'seleccioneOperacion' | translate }}" [autoClose]="false" style="width: 295px;">
                <kendo-combobox-messages noDataText="{{'norecords' | translate}}"></kendo-combobox-messages>
              </kendo-combobox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="cancelarCopia()">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" [disabled]="!hayDatosFiltro" (click)="adviceCopyElements()"
      [disabled]="this.user.operaciones<2">{{
      'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: copiar elem lista aviso-->
<ng-template #popupCopiarAviso let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="volverACopyElements()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntaavisocopiarvalores' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="volverACopyElements()">{{ 'cancelar' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="copyElements()" [disabled]="this.user.operaciones<2">{{
      'aceptar' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Agregar herramienta -->
<ng-template #popupAddHerramienta let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'seleccionaHerramienta' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarSeleccionHerramienta()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <!-- <div class="card-header">
        <h3><label>{{ 'seleccionaHerramienta' | translate }}</label></h3>
      </div> -->
      <div class="card-body">
        <!-- GRID -->
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
          (mouseover)="showTooltip($event)">
          <kendo-grid [kendoGridBinding]="herramientasTotales" [sortable]="true" [navigable]="true" filterable="menu"
            [rowHeight]="36" [height]="750" [pageSize]="30" kendoGridSelectBy="id" scrollable="virtual"
            [selectedKeys]="herramientasTotalesSeleccionadas">
            <!--checkbox-->
            <kendo-grid-checkbox-column width="5%">
              <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox [state]="selectAllState">
                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
              </ng-template>
            </kendo-grid-checkbox-column>
            <!--nombre-->
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="nombre"
              title="{{ 'nombre' | translate}}">
            </kendo-grid-column>
            <!--tipo-->
            <kendo-grid-column width="9%" [style]="{'text-align': 'left'}" field="tipo" title="{{ 'tipo' | translate}}">
            </kendo-grid-column>
            <!--fabricante-->
            <kendo-grid-column width="9%" [style]="{'text-align': 'left'}" field="fabricante"
              title="{{ 'fabricante' | translate}}"></kendo-grid-column>
            <!--vida-->
            <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="vidaUtil"
              title="{{ 'vidaUtil' | translate}}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <ngcontainer> {{ secondsToHms(dataItem.vidaUtil) }}</ngcontainer>
              </ng-template>
            </kendo-grid-column>
            <!--diametro-->
            <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="diametro"
              title="{{ 'diametro' | translate}}">
            </kendo-grid-column>
            <!--longitud-->
            <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="longitud"
              title="{{ 'longitud' | translate}}">
            </kendo-grid-column>
            <!--Angulo-->
            <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="angulo"
              title="{{ 'angulo' | translate}}">
            </kendo-grid-column>
            <!--nfiltros-->
            <kendo-grid-column width="7%" [style]="{'text-align': 'right'}" field="numContenido"
              title="{{ 'capacidad' | translate}}"></kendo-grid-column>
            <!--salto-->
            <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="salto"
              title="{{ 'saltos' | translate}}">
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="agregarHerramientaModal()"
          [disabled]="this.user.operaciones<2">{{
          'aceptar' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="cancelarSeleccionHerramienta()">{{ 'cancelar' |
          translate
          }}</button>
      </div>
    </div>
  </div>
</ng-template>



<!-- POPUP: Agregar programa -->
<ng-template #popupAddPrograma let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'seleccionaPrograma' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarSeleccionPrograma()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <!-- <div class="card-header">
        <h3><label>{{ 'seleccionaPrograma' | translate }}</label></h3>
      </div> -->
      <div class="card-body">
        <!-- GRID -->
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
          (mouseover)="showTooltip($event)">
          <kendo-grid [kendoGridBinding]="programasTotales" [sortable]="true" [navigable]="true" filterable="menu"
            [rowHeight]="36" [height]="750" [pageSize]="30" kendoGridSelectBy="id" scrollable="virtual"
            [selectedKeys]="programasTotalesSeleccionadas">
            <!--checkbox-->
            <kendo-grid-checkbox-column width="10%">
              <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox [state]="selectAllState">
                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
              </ng-template>
            </kendo-grid-checkbox-column>
            <kendo-grid-column field="nombrePrograma" title="{{ 'nombrePrograma' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="version" title="{{ 'version' | translate}}"  width="30%" [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{myFunctions.sumarleLaVALaVersion(dataItem.version)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="fechaVersion" title="{{ 'fecha' | translate}}"  width="30%"
                [style]="{'text-align': 'center'}">
                <!-- <ng-template kendoGridCellTemplate let-dataItem>
                    {{myFunctions.dateToDayString(parseDate(dataItem.fechaVersion))}}</ng-template> -->
            </kendo-grid-column>
            <kendo-grid-column field="tipo" title="{{ 'tipo' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.tipoToText(dataItem.tipo)}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="pieza" title="{{ 'pieza' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate}}" width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="cliente" title="{{ 'cliente' | translate}}" width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="numeroOF" title="{{ 'of' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column *ngIf="!user.ocultarParte" field="parte" title="{{ 'parte' | translate}}"  width="30%"
                [style]="{'text-align': 'left'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="operario" title="{{ 'operario' | translate}}"  width="30%" [style]="{'text-align': 'left'}"
                [headerStyle]="{'text-align': 'right'}" class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <!-- <span class="tooltip-consumibles-contenido"> -->
                      <ng-container >
                        <span class="grid-circulo"
                              [style.background-color]="dataItem.operarioColor+ '!important'">{{dataItem.operarioSiglas}}</span>
                        </ng-container>
                      <!-- <span class="tooltiptext">
                        <ng-container >
                          <label> {{dataItem.operario}} </label>
                        </ng-container>
                      </span> -->
                    <!-- </span> -->
                  </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="nombreMaquina" title="{{ 'maquina' | translate}}" width="30%"
                [style]="{'text-align': 'left'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="tMedio" title="{{ 'tiempo_medio' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tMedio)}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tMejor" title="{{ 'mejor_tiempo' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tMejor)}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="cantidad" title="{{ 'cantidad2' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="porcenCalidad" title="{{ '%OK' | translate}}"  width="30%"
                [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="agregarProgramaModal()"
          [disabled]="this.user.operaciones<2">{{
          'aceptar' | translate }}</button>
        <button type="button" class="btn btn-danger" (click)="cancelarSeleccionPrograma()">{{ 'cancelar' |
          translate
          }}</button>
      </div>
    </div>
  </div>
</ng-template>




<!--POPUP ELIMINAR RECETA-->
<ng-template #contentRecetas let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'preguntaeliminarpopup' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div> -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">
      {{ 'no' | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="eliminarRecetaConfirmar()"
      [disabled]="this.user.operaciones<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Frecuencias -->
<ng-template #popupFrecuencias let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'frecuencias' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <div>
      <div class="card-body">
        <div class="clearfix">
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'esfuncion' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="esFuncion_modal" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </div>
          </div>
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'funcion' | translate }}</label>
              <div class="caja">
                <kendo-textbox [disabled]="!esFuncion_modal" [(ngModel)]="funcion_modal" maxlength="150"></kendo-textbox>
              </div>
            </div>
          </div>
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'imagen' | translate }}</label>
              <div class="caja" style="margin: 0 0 0 7px;">
                <kendo-switch [(ngModel)]="imagen_modal" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </div>
          </div>
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'ruta' | translate }}</label>
              <div class="caja">
                <kendo-textbox [(ngModel)]="ruta_modal" maxlength="150"></kendo-textbox>
              </div>
            </div>
          </div>
          <div class="float-left mr-2" style="width:217px">
            
          </div>
        </div>
      </div>
    </div> -->
    <div>
      <!-- <div class="card-header">
        <h3><label>{{ 'frecuencias' | translate }}</label></h3>
      </div> -->

      <button kendoButton (click)="onClickResetear()" class="btn mr-1  btn-success btn-sm mr-1"> {{ 'resetear' |
        translate}} </button>
      <button kendoButton (click)="onClickPredefinido()" class="btn mr-1  btn-primary btn-sm mr-1"> {{
        'valoresPredefinidos' | translate}} </button>

      <div class="card-body">
        <kendo-grid position="top" [(data)]="valores_modal" [resizable]="true" [selectedKeys]="mySelection"
          kendoGridSelectBy="idNuevo" [scrollable]="true" class="tabla-frecuencias-calidad">
          <!-- <kendo-grid-column hidden ngModel="pc1">{{dataItem.porcen1}}</kendo-grid-column>
                      <kendo-grid-column hidden ngModel="pc2">{{dataItem.porcen2}}</kendo-grid-column>
                      <kendo-grid-column hidden ngModel="pc3">{{ (dataItem.porcen1*100 / dataItem.porcen2).toFixed(0)}}</kendo-grid-column>
                      <kendo-grid-column hidden ngModel="dataFromDB">true</kendo-grid-column> -->
          <kendo-grid-column hidden ngModel="dataFromABC" width="0%"></kendo-grid-column>
          <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="15%"></kendo-grid-column>
          <kendo-grid-column editor="numeric" title="{{ 'cantidad' | translate }}" width="10%">
            <ng-template kendoGridCellTemplate let-dataItem>
              <!-- <kendo-numerictextbox type="number" [disabled]="dataItem.disabled || this.user.calidad<2" [(value)]="dataItem.cantidad" [(ngModel)]="dataItem.cantidad" [decimals]="0" [autoCorrect]="true" format="n0" [min]="0" (valueChange)="cantidadChange(dataItem, $event)" 
                            [max]="999999"></kendo-numerictextbox>  -->
              <div class="row">
                <div class="col">
                  <input type="number" [id]="dataItem.idNuevo" name="quantity"
                    [disabled]="dataItem.disabled || this.user.calidad<2" [(value)]="dataItem.cantidad" min="0"
                    (change)="isPositive($event, false); cantidadChange(dataItem, $event);">
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="{{ 'frecuencia' | translate }}" width="50%">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="row">
                <div class="col-3">
                  <!-- <kendo-numerictextbox #porcen1 style="width: 33%" [decimals]="0" [autoCorrect]="true" format="n0" [min]="1" [max]="999999" 
                              [disabled]="dataItem.disabled || this.user.calidad<2" 
                              (valueChange)="recalcularFracciones(1, dataItem, $event, dataItem.idNuevo); "
                              [value]="dataItem.porcen1"></kendo-numerictextbox> -->
                  <input #porcen1 type="number" [disabled]="dataItem.disabled || this.user.calidad<2"
                    [(value)]="dataItem.porcen1" min="0"
                    (change)="isPositive($event, false); recalcularFracciones(1, dataItem, $event, dataItem.idNuevo);">
                </div>
                <div class="col-0">
                  <p>/</p>
                </div>
                <div class="col-3">
                  <!-- <kendo-numerictextbox #porcen2 style="width: 33%" 
                              [decimals]="0" [autoCorrect]="true" format="n0" [min]="1" [max]="999999" 
                              [disabled]="dataItem.disabled || this.user.calidad<2" 
                              (valueChange)="recalcularFracciones(2, dataItem, $event, dataItem.idNuevo);" 
                              [value]="dataItem.porcen2"></kendo-numerictextbox> -->
                  <input #porcen2 type="number" [disabled]="dataItem.disabled || this.user.calidad<2"
                    [(value)]="dataItem.porcen2" min="0"
                    (change)="isPositive($event, true); recalcularFracciones(2, dataItem, $event, dataItem.idNuevo);">
                </div>
                <div class="col-3">
                  <!-- <kendo-numerictextbox #porcen3 style="width: 33%" 
                                          [decimals]="0" [autoCorrect]="true" format="n0" [min]="1" [max]="100" 
                                          [disabled]="dataItem.disabled || this.user.calidad<2" 
                                          (valueChange)="recalcularFracciones(3, dataItem, $event, dataItem.idNuevo);" 
                                          [value]="dataItem.porcen3"></kendo-numerictextbox> -->
                  <input #porcen3 type="number" [disabled]="dataItem.disabled || this.user.calidad<2"
                    [(value)]="dataItem.porcen3" min="0"
                    (change)="isPositive($event, false); recalcularFracciones(3, dataItem, $event, dataItem.idNuevo);">
                </div>
                <div class="col-0">
                  <p>%</p>
                </div>
              </div>

            </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="aceptarGuardadoFrecuencias($event)"
      [disabled]="this.user.operaciones<2 || this.user.calidad<2">{{ 'aceptar' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #popupDocumentoCalidad let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'documento' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="card-header">
        <!-- <h3><label>{{ 'documento' | translate }}</label></h3> -->
      </div>
      <div class="card-body">
        <div class="form-group mr-2">
          <kendo-label text="{{ 'insertarRutaDocumento' | translate}}:">
            <div class="caja">
              <kendo-textbox [(value)]="ruta_modal" maxlength="150"
                [ngClass]="{ 'is-invalid': submitted2 && errorRutaModal }">
              </kendo-textbox>
              <div *ngIf="submitted2 && errorRutaModal" class="invalid-feedback">
                <div *ngIf="errorRutaModal">{{ 'rutaEsObligatoria' |
                  translate}}</div>
              </div>
            </div>
          </kendo-label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="aceptarGuardadoDocumento($event)"
      [disabled]="this.user.operaciones<2">{{ 'aceptar' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #contentloadingupload let-modal>

  <div class="modal-body">
    <p>{{ 'importando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'importando' | translate }}...</span>
    </div>
  </div>
</ng-template>