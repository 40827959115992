<kendo-grid [kendoGridBinding]="maquinas"
            [selectedKeys]="seleccionados"
            [sortable]="true"
            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true"
            kendoGridSelectBy="id"
            filterable="menu"
            (cellClick)="cellClick($event)"
            *ngIf="router.url === '/maquinas'">
  <!--BOTONES ARRIBA-->
  <ng-template kendoGridToolbarTemplate position="top">
    <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button (click)="onClickNuevo(maquina_nueva_tipo_maquina)" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button (click)="onClickCopiar()" class="btn btn-copiar btn-sm mr-1">{{ 'copiar' | translate}}</button>
    <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.maquinas<2" >{{ 'eliminar' | translate}}</button>
  </ng-template>
  <!--id-->
  <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--orden-->
  <kendo-grid-column width="6%" field="ordenModelo" [style]="{'text-align': 'right'}" title="{{ 'orden' | translate}}" filter="numeric"></kendo-grid-column>
  <!--nombre-->
  <kendo-grid-column width="30%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <!--marca-->
  <kendo-grid-column width="14%" field="marca" title="{{ 'marca' | translate}}"></kendo-grid-column>
  <!--control-->
  <kendo-grid-column width="14%" field="control" title="{{ 'control' | translate}}"></kendo-grid-column>
  <!--tipo maquina-->
  <kendo-grid-column width="14%" field="tipo_maquina_nombre" title="{{ 'tipoMaquina' | translate}}"></kendo-grid-column>
  <!--clasificacion-->
  <kendo-grid-column width="10%" field="clasificacion_nombre" title="{{ 'clasificacion' | translate}}"></kendo-grid-column>
  <!--ip-->
  <kendo-grid-column width="8%" field="ip" title="{{ 'ip' | translate}}"></kendo-grid-column>
  <!--imagen-->
  <kendo-grid-column field="tieneImagen" [style]="{'text-align': 'center'}" title="{{ 'imagen' | translate}}" width="6%" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.tieneImagen" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>

<!--POPUP CREAR: TIPO DE MAQUINA -->
<ng-template #maquina_nueva_tipo_maquina let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'textoCrearMaquinaTipos' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="caja">
        <kendo-combobox style="width: 98%" [(ngModel)]="selectedMaquinasTipos" [data]="maquinasTipos" [textField]="'nombre'" [valueField]="'id'" [kendoDropDownFilter]="{operator: 'contains'}">
        </kendo-combobox>
        <label style="margin-left: 9px; color: red;" *ngIf="selectedMaquinasTipos == undefined">*</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="crearMaquina()">{{ 'crear' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
  </div>
</ng-template>

<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'preguntaeliminarpopup'| translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)" [disabled]="this.user.maquinas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP ELIMINANDO-->
<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>
