<kendo-grid [kendoGridBinding]="contenedores"
            [selectedKeys]="seleccionados"
            [sortable]="true"
            [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true"
            kendoGridSelectBy="id"
            (cellClick)="cellClick($event)">
  <!--BOTONES ARRIBA-->
  <ng-template kendoGridToolbarTemplate position="top">
    <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button (click)="onClickEliminar()" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.seleccionados.length == 0" >{{ 'eliminar' | translate}}</button>
  </ng-template>

  <!--checkbox-->
  <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--nombre-->
  <kendo-grid-column width="20%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <!--descripcion-->
  <kendo-grid-column width="50%" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-grid-column>
  <!--stock minimo-->
  <kendo-grid-column width="100%" field="stockMinimo" title="{{ 'stockMinimo' | translate}}"></kendo-grid-column>
  <!--stock maximo-->
  <kendo-grid-column width="100%" field="stockMaximo" title="{{ 'stockMaximo' | translate}}"></kendo-grid-column>
  <!--activo-->
  <kendo-grid-column width="10%" field="activo" title="{{ 'activo' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" [checked]="dataItem.activo" disabled />
    </ng-template>
  </kendo-grid-column>

  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>