import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { InformeProyectosService, MenuService, ProgramasService, UsuariosService} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-visor-version',
  templateUrl: './visor-version.component.html',
  styleUrls: ['./visor-version.component.less']
})
export class VisorVersionComponent implements OnInit {

  public listaSubVersiones: any; //lista de subversiones a mostrar
  user = this.userService.userValue;

  //Referencias a los elementos de la vista
  @ViewChild('programa') programa: ElementRef<HTMLInputElement>;
  @ViewChild('version') version: ElementRef<HTMLInputElement>;
  @ViewChild('fecha') fecha: ElementRef<HTMLInputElement>;

  constructor(private menuService: MenuService, private userService: UsuariosService,
    public route: ActivatedRoute, private translateService: TranslateService, private programasService: ProgramasService, private informeProyectosService: InformeProyectosService,
   public myFunctions: MyFunctions) { 
    this.menuService.titulo = this.translateService.instant('version').toUpperCase(); // for title of page at header
  }

  ngOnInit(): void {
    this.cargarProgramas();
  }

  cargarProgramas(): void {
    //Obtener tabla subversion con el id del programa y el id de la version
    this.programasService.GetSubVersiones(this.route.snapshot.params.idP.split("idP")[1], Number(this.route.snapshot.params.idV.split("idV")[1])).subscribe((result) => {
      this.procesarDatos(result); // procesar los datos con el resultado de la llamada
      this.loadDataHeader(); // cargar datos div bajo cabecera
    });
  }

  procesarDatos(listaDatos: any): void{
    this.listaSubVersiones= listaDatos.data.sort(function(date1, date2) { // sort data by date at fechaVersion
      // Create the dates
      var keyA = new Date(date1.fechaVersion.split("T")[0]),
        keyB = new Date(date2.fechaVersion.split("T")[0]);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  }

  loadDataHeader(): void{ //cargar datos div bajo cabecera
    this.programa.nativeElement.innerHTML = this.listaSubVersiones[0].nombrePrograma;
    this.version.nativeElement.innerHTML = this.listaSubVersiones[0].version;
    this.fecha.nativeElement.innerHTML = this.myFunctions.dateToDayString(this.parseDate(this.listaSubVersiones[0].fechaVersion));
  }

  parseDate(date: String): any{ //parsear la fecha para mostrarla adecuadamente en la tabla
    return new Date(date.split("T")[0]);
  }


  showFile():void{
    
  }
  
}
