import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/controles`;

@Injectable()
export class ControlesService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAll_Controles_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/controles");
  }

  insert(data, idProtocolo_DAT) {
    return this.http.post<any>(baseUrl + "/crear", { id: -1, nombre: data.nombre, IDprotocolo: idProtocolo_DAT }, { withCredentials: true });
  }

  update(data, idProtocolo_DAT) {
    return this.http.post<any>(baseUrl + "/editar", { id: data.id, nombre: data.nombre, IDprotocolo: idProtocolo_DAT}, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }
}
