import { Component, OnInit, AfterViewInit, ViewChild, Renderer2, DefaultIterableDiffer } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, UsuariosService, Eskola_CursosLectivosService, Eskola_MaquinasService, Eskola_HorariosSeccionesService, EskolaGruposService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';
import * as _ from 'lodash';


interface ItemTurno {
  nombre: string,
  id: number
}
interface ItemTurnoGantt {
  min: Date,
  max: Date
}
declare var GanttChartMultiRow: any;
declare var GanttChartMultiRowLunes: any;
@Component({
  selector: 'app-lanzadorOf',
  templateUrl: './eskola_horariosSecciones.component.html'
})
export class Eskola_HorariosSecciones implements AfterViewInit, OnInit {

  modalReference: NgbModalRef;
  @ViewChild('popupEliminar') popupEliminar: NgbModalRef;
  @ViewChild('popupReplicar') popupReplicar: NgbModalRef;

  user = this.userService.userValue;
  loadingPanel: any = false;

  // TABLAS PARA EL GANTT
  machinesGantt: any[] = [];
  tasksGantt: any[] = [];
  overlappLineGantt: any[] = [];
  
  ganttList:any[] = [];

  // TABLAS PRINCIPALES DB
  dtCursos: any;
  dtHorarios: any;
  dtGruposMaquinas: any;
  dtHorariosSecciones: any;
  dtGrupos: any;

  cursos: any;
  horarios: any;
  gruposMaquinas: any;
  horariosSecciones: any;
  horariosSeccionesGrupo: any = [];
  grupos: any;
  horariosSeccionesEliminados: any = [];

  public existenDatos: boolean = false;


  //COMBOBOX SEMANA
  Jsemanas: any;
  JsemanaSelected: any;

  fechasSemana: any;
  diasSemana: any = [];


  //MODAL ELIMINAR
  inicioModalEliminar: Date;
  finModalEliminar: Date;
  grupoSelectedModalEliminar: any = [];
  diasSemanaSelectedModalEliminar: any = [];
  gruposMaquinasSelectedModalEliminar: any = [];
  diasDeLaSemanaDropdown: any = [
    { nombre: this.translateService.instant('todos'), id: -1 },
    { nombre: this.translateService.instant('lunes'), id: 1 },
    { nombre: this.translateService.instant('martes'), id: 2 },
    { nombre: this.translateService.instant('miercoles'), id: 3 },
    { nombre: this.translateService.instant('jueves'), id: 4 },
    { nombre: this.translateService.instant('viernes'), id: 5 }



  ];
  resumen: boolean = false;

  // MODAL REPLICAR SEMANA
  resumenReplicar: boolean = false;
  inicioModalReplicar: Date;
  finModalReplicar: Date;

  //AYUDAS FECHAS
  private today: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
  private now: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate(), this.myFunctions.getDateNow().getHours(), this.myFunctions.getDateNow().getMinutes(), this.myFunctions.getDateNow().getSeconds());

  vacio:boolean = false;

  fechaInicioCurso: Date;
  fechaFinCurso: Date;
  fechaInicio;
  fechaFin;
  horaMin;
  horaMax;
  horaMaxLunes;
  horaMinLunes;
  horaMaxMartes;
  horaMinMartes;
  horaMaxMiercoles;
  horaMinMiercoles
  horaMaxJueves;
  horaMinJueves;
  horaMaxViernes;
  horaMinViernes;
  horaMaxSabado;
  horaMinSabado;
  horaMaxDomingo;
  horaMinDomingo;
  //GANTT
  public chartGanttTimeline;
  public chartGanttTimelineLunes;
  private GanttChartMultiRow: any;
  loadingGantt: boolean;
  turnosArray: Array<ItemTurnoGantt> = [];

  //Menu lateral
  menuDesplegado: boolean = false;
  grupoSelected: any = [];
  nuevo: boolean;
  replicarHorario: boolean = false;
  repSemanal: boolean = true;
  inicioMenuLateral: Date;
  finMenuLateral: Date;
  errorFechasUndefined: boolean;
  avisoPeriodoFuera: boolean;
  errorHoras: boolean;
  errorHorasExisten: boolean;
  avisosHorasFuera: boolean;
  errorHorasFueraCentro: boolean;
  TasksLunes: any[];
  TasksMartes: any[];
  TasksMiercoles: any[];
  TasksJueves: any[];
  TasksViernes: any[];
  TasksSabado: any[];
  TasksDomingo: any[];
  maquinasLibresLunes: any[] = [];
  maquinasLibresMartes: any[] = [];
  maquinasLibresMiercoles: any = [];
  maquinasLibresJueves: any = [];
  maquinasLibresViernes: any[] = [];
  maquinasLibresSabado: any[] = [];
  maquinasLibresDomingo: any[] = [];


  constructor(
    private menuService: MenuService,
    private eskola_gruposService: EskolaGruposService,
    private eskola_cursosService: Eskola_CursosLectivosService,
    private eskola_maquinasService: Eskola_MaquinasService,
    private eskola_horariosSeccionesService: Eskola_HorariosSeccionesService,
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private myFunctions: MyFunctions,
    private modalService: NgbModal,
    private renderer: Renderer2,
    protected alertService: AlertService) {

    this.menuService.titulo = this.translateService.instant('horariosSecciones').toUpperCase();


  }

  ngAfterViewInit(): void {
    this.loadingGantt = true;
    if (this.route.snapshot.params['fechaInicio'] && this.route.snapshot.params['fechaFin']) {
      this.fechaInicio = new Date(this.route.snapshot.params['fechaInicio']);
      this.fechaFin = new Date(this.route.snapshot.params['fechaFin']);
    }
    this.cargarDatos();
    // this.generarChartGanttTimeline();

  }
  ngOnInit(): void {

  }

  cargarDatos() {
    this.existenDatos = false;
    let r1: boolean = false;
    let r2: boolean = false;
    let r3: boolean = false;
    let r4: boolean = false;
    let r5: boolean = false;
    let r6: boolean = false;
    let r7: boolean = false;
    let r8: boolean = false;
    let r9: boolean = false;
    let r10: boolean = false;
    let r11: boolean = false;
    let r12: boolean = false;
    let r13: boolean = false;

    this.loadingPanel = false;

    this.eskola_cursosService.getAll().subscribe(res => {
      this.dtCursos = res;
      this.cursos = res;
      r1 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6) {
        this.cargarComboSemanas();
      }
    });
    this.eskola_cursosService.getHorariosDelCurso(-1).subscribe(res => {
      this.dtHorarios = res;
      this.horarios = res;
      r2 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }
    });
    this.eskola_cursosService.getHorasMaxyMin(-1, -1).subscribe(res => {
      this.horaMax = res[0].horaMax;
      this.horaMin = res[0].horaMin;
      this.fechaInicioCurso = new Date(res[0].inicioMin);
      this.fechaFinCurso = new Date(res[0].finMax);
      r3 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }
    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 1).subscribe(res => {
      this.horaMaxLunes = res[0].horaMax;
      this.horaMinLunes = res[0].horaMin;
      r7 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }
      

    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 2).subscribe(res => {
      this.horaMaxMartes = res[0].horaMax;
      this.horaMinMartes = res[0].horaMin;
      r8 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }

    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 3).subscribe(res => {
      this.horaMaxMiercoles = res[0].horaMax;
      this.horaMinMiercoles = res[0].horaMin;
      r9 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }

    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 4).subscribe(res => {
      this.horaMaxJueves = res[0].horaMax;
      this.horaMinJueves = res[0].horaMin;
      r10 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }

    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 5).subscribe(res => {
      this.horaMaxViernes = res[0].horaMax;
      this.horaMinViernes = res[0].horaMin;
      r11 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }

    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 6).subscribe(res => {
      this.horaMaxSabado = res[0].horaMax;
      this.horaMinSabado = res[0].horaMin;
      r12 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }

    });
    this.eskola_cursosService.getHorasMaxyMin(-1, 7).subscribe(res => {
      this.horaMaxDomingo = res[0].horaMax;
      this.horaMinDomingo = res[0].horaMin;
      r13 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }


    });
    this.eskola_maquinasService.getGruposMaquinas().subscribe(res => {
      debugger;
      this.dtGruposMaquinas = res;
      this.gruposMaquinas = res,
        r4 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }
    });
    this.eskola_horariosSeccionesService.getHorariosSeccionesCurso(-1).subscribe(result => {
      this.dtHorariosSecciones = result;
      this.horariosSecciones = result;
      r5 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }
    });
    this.eskola_gruposService.getAll().subscribe(res => {
      this.grupos = res;
      this.dtGrupos = res;
      r6 = true;
      if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8 && r9 && r10 && r11 && r12 && r13) {
        this.cargarComboSemanas();
      }


    });

  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }


  cargarComboSemanas() {
    //this.existenDatos = true;
    this.Jsemanas = [];
    var fecha = this.startOfWeek(this.dateCopy(this.fechaInicioCurso))
    var semana = this.getNumberOfWeek(fecha);
    var año = fecha.getFullYear();

    var fechaMax = this.dateCopy(this.fechaFinCurso);
    var añoMax = fechaMax.getFullYear();
    var semanaMax = this.getNumberOfWeek(fechaMax);


    var index = 1;
    while (año < añoMax || (año == añoMax && semana <= semanaMax)) {
      //crear linea Json
      var fecha2 = new Date(fecha);
      var fecha3 = this.dateCopy(fecha);
      fecha3.setDate(fecha.getDate() + 6);
      fecha3.setHours(23);
      fecha3.setMinutes(59);
      var js: any = {};
      js['value'] = index;
      js['fechaInicio'] = fecha2;
      js['fechaFin'] = fecha3;
      js['año'] = año;
      js['semana'] = semana;
      if (this.user.idioma != "eu") {
        js['text'] = this.translateService.instant('semana') + " " + semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';

      } else {
        js['text'] = semana + " " + this.translateService.instant('semana') + ' (' + this.dateToYYYYMMDD(fecha2) + ')';

      }
      if (fecha2.getFullYear() == this.today.getFullYear() && this.getNumberOfWeek(fecha2) == this.getNumberOfWeek(this.today)) {
        js['presentWeek'] = true;
      } else {
        js['presentWeek'] = false;
      }
      this.Jsemanas.push(js);

      //actualizar variables para proximo loop
      index++;
      fecha.setDate(fecha.getDate() + 7);
      semana = this.getNumberOfWeek(fecha);
      año = fecha.getFullYear();
    }
    // si es la primera vez que carga seleccionamos la semana actual
    if (this.fechaInicio == undefined && this.fechaFin == undefined) {
      this.JsemanaSelected = this.Jsemanas.filter(f => f.presentWeek == true)[0];
      if(this.JsemanaSelected != undefined){
        this.fechaInicio = this.JsemanaSelected.fechaInicio;
        this.fechaFin = this.JsemanaSelected.fechaFin;
      }else{
        this.fechaFin = this.Jsemanas[0].fechaFin;
        this.fechaInicio = this.Jsemanas[0].fechaInicio;
      }
    } else if (this.JsemanaSelected == undefined) {
      // venimos de la url
      this.JsemanaSelected = this.Jsemanas.filter(f => f.fechaInicio.getFullYear() == this.fechaInicio.getFullYear() && f.fechaInicio.getMonth() == this.fechaInicio.getMonth() && f.fechaInicio.getDate() == this.fechaInicio.getDate())[0];
    }
    this.actualizarDiasSemana();
    this.generarChartGanttTimeline();




  }
  semanaSelectedChange() {
    this.fechaInicio = this.JsemanaSelected.fechaInicio;
    this.fechaFin = this.JsemanaSelected.fechaFin;
    this.reiniciar();


  }
  repeticionChange() {
    this.repSemanal = !this.repSemanal;
  }
  actualizarDiasSemana() {
    this.diasSemana = [];
    var fecha = this.dateCopy(this.fechaInicio);
    while (fecha <= this.fechaFin) {
      var diaSemana = {
        dia: this.asignarDiaSemana(fecha).dia,
        id: this.asignarDiaSemana(fecha).id,
        fecha: this.dateCopy(fecha)
      }
      this.diasSemana.push(diaSemana);
      fecha.setDate(fecha.getDate() + 1);


    }

  }
  annadirEliminado(id) {
    if (id != -1) {
      // si el horario seccion ya existe el la base de datos añadirlo a la lista para eliminar
      this.horariosSeccionesEliminados.push(id);
    }
    let index = this.horariosSeccionesGrupo.findIndex(d => d.id === id);
    this.horariosSeccionesGrupo.splice(index, 1);



  }
  annadirLinea() {
    var newstartDate = this.dateCopy(this.fechaInicio);
    var newendDate = this.dateCopy(this.fechaInicio);
    this.asignarHoraInicioYFinDia(newstartDate,newendDate,1);
    let nuevoHorario = {
      id: -1,
      id_cursoLectivo: this.grupoSelected.id_cursoLectivo,
      startDate: newstartDate,
      endDate: newendDate,
      id_grupoMaquinas: this.gruposMaquinas[0].id,
      gMaquina: this.gruposMaquinas[0],
      cantidadMaquinas: 1,
      id_grupo: this.grupoSelected.id,
      id_curso: this.grupoSelected.id_cursoLectivo,
      maquinasMax: this.gruposMaquinas[0].maquinasKop,
      diaItem: {
        id: this.asignarDiaSemana(newstartDate).id,
        dia: this.asignarDiaSemana(newstartDate).dia,
        fecha: this.fechaInicio
      }

    }
    this.horariosSeccionesGrupo.push(nuevoHorario);

  }
  reiniciar() {
    this.loadingGantt = true;
    // menu lateral
    this.grupoSelected = [];
    this.horariosSecciones = [];
    this.horariosSeccionesGrupo = [];
    this.menuDesplegado = false;
    this.inicioMenuLateral = undefined;
    this.finMenuLateral = undefined;
    this.replicarHorario = false;
    this.horariosSeccionesEliminados = [];
    this.repSemanal = true;
    this.replicarHorario = false;
    this.errorHorasFueraCentro = false;

    //replicar semana popup
    this.inicioModalReplicar = undefined;
    this.finModalReplicar = undefined;
    this.resumenReplicar = false;


    // seleccion horarios a eliminar popup
    this.grupoSelectedModalEliminar = [];
    this.inicioModalEliminar = undefined;
    this.finModalEliminar = undefined;
    this.diasSemanaSelectedModalEliminar = [];
    this.gruposMaquinasSelectedModalEliminar = [];
    this.resumen = false;


    // gantt
    this.horariosSecciones;
    this.chartGanttTimeline = [];
    this.tasksGantt = [];
    this.machinesGantt = [];
    this.overlappLineGantt = [];
    this.horariosSeccionesEliminados = [];

    this.TasksLunes = [];
    this.maquinasLibresLunes = [];
    this.TasksMartes = [];
    this.maquinasLibresMartes = [];
    this.TasksMiercoles = [];
    this.maquinasLibresMiercoles = [];
    this.TasksJueves = [];
    this.maquinasLibresJueves = [];
    this.TasksViernes = [];
    this.maquinasLibresViernes = [];
    this.TasksSabado = [];
    this.maquinasLibresSabado = [];
    this.TasksDomingo = [];
    this.maquinasLibresDomingo = [];

    // validacion
    this.errorFechasUndefined = false;
    this.errorHoras = false;
    this.errorHorasExisten = false;
    this.avisoPeriodoFuera = false;
    this.avisosHorasFuera = false;

    this.cargarDatos();



  }
  private generarChartGanttTimeline() {
    this.prepararGantt();
    let data = {
      machines: this.machinesGantt,
      //of = talderen izena machine = grupo de maquinas
      tasks: this.tasksGantt,
      overlappingPeriods: this.overlappLineGantt,
      hidden: true,
    };

    // gantt-solo grupos de maquinas
    var gantt = new GanttChartMultiRow('gantt-chart', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: this.fechaInicio,
      endDate: this.fechaFin,
      shifts: this.turnosArray,
      data: data,


    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });





    // gantt-lunes
    if(this.horaMaxLunes!=undefined && this.horaMinLunes!=undefined){
    var newFechaInicioLunes = this.dateCopy(this.diasSemana[0].fecha)
    var newFechaFinLunes = this.dateCopy(this.diasSemana[0].fecha)
    this.asignarHoraInicioYFinDia(newFechaInicioLunes, newFechaFinLunes, 1);
    var gantt_lunes = new GanttChartMultiRow('gantt-chart-lunes', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioLunes,
      endDate: newFechaFinLunes,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksLunes,
        overlappingPeriods: this.maquinasLibresLunes,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });
    }

    // gantt-martes
    if(this.horaMaxMartes!=undefined && this.horaMinMartes !=undefined){
    var newFechaInicioMartes = this.dateCopy(this.diasSemana[1].fecha)
    var newFechaFinMartes = this.dateCopy(this.diasSemana[1].fecha)
    this.asignarHoraInicioYFinDia(newFechaInicioMartes, newFechaFinMartes, 2);
    var gantt_martes = new GanttChartMultiRow('gantt-chart-martes', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioMartes,
      endDate: newFechaFinMartes,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksMartes,
        overlappingPeriods: this.maquinasLibresMartes,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });
    }

    // gantt-miercoles
    if(this.horaMaxMiercoles!=undefined && this.horaMinMiercoles !=undefined){
    var newFechaInicioMiercoles = this.dateCopy(this.diasSemana[2].fecha)
    var newFechaFinMiercoles = this.dateCopy(this.diasSemana[2].fecha)
    this.asignarHoraInicioYFinDia(newFechaInicioMiercoles, newFechaFinMiercoles, 3);
    var gantt_miercoles = new GanttChartMultiRow('gantt-chart-miercoles', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioMiercoles,
      endDate: newFechaFinMiercoles,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksMiercoles,
        overlappingPeriods: this.maquinasLibresMiercoles,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });
    }

    // gantt-jueves
    if(this.horaMaxJueves!=undefined && this.horaMinJueves!=undefined){
    var newFechaInicioJueves = this.dateCopy((this.diasSemana[3].fecha))
    var newFechaFinJueves = this.dateCopy(this.diasSemana[3].fecha)
    this.asignarHoraInicioYFinDia(newFechaInicioJueves, newFechaFinJueves, 4);
    var gantt_jueves = new GanttChartMultiRow('gantt-chart-jueves', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioJueves,
      endDate: newFechaFinJueves,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksJueves,
        overlappingPeriods: this.maquinasLibresJueves,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });

    }
    // gantt-viernes
    if(this.horaMaxViernes!=undefined && this.horaMinViernes!=undefined){
    var newFechaInicioViernes = this.dateCopy(this.diasSemana[4].fecha)
    var newFechaFinViernes = this.dateCopy(this.diasSemana[4].fecha)
    this.asignarHoraInicioYFinDia(newFechaInicioViernes, newFechaFinViernes, 5);
    var gantt_viernes = new GanttChartMultiRow('gantt-chart-viernes', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioViernes,
      endDate: newFechaFinViernes,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksViernes,
        overlappingPeriods: this.maquinasLibresViernes,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });
    }

    // gantt-sabado
    if(this.horaMaxSabado!=undefined && this.horaMinSabado !=undefined){
    var newFechaInicioSabado = this.dateCopy(this.diasSemana[5].fecha)
    var newFechaFinSabado = this.dateCopy(this.diasSemana[5].fecha)
    this.asignarHoraInicioYFinDia(newFechaInicioSabado, newFechaFinSabado, 6);
    var gantt_sabado = new GanttChartMultiRow('gantt-chart-sabado', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioSabado,
      endDate: newFechaFinSabado,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksSabado,
        overlappingPeriods: this.maquinasLibresSabado,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });
    }

    // gantt-domingo
if(this.horaMaxDomingo!=undefined && this.horaMinDomingo !=undefined){
    var newFechaInicioDomingo = this.dateCopy(this.diasSemana[6].fecha)
    var newFechaFinDomingo = this.dateCopy((this.diasSemana[6].fecha))
    this.asignarHoraInicioYFinDia(newFechaInicioDomingo, newFechaFinDomingo, 7);
    var gantt_domingo = new GanttChartMultiRow('gantt-chart-domingo', {
      width: '400', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: '350',
      startDate: newFechaInicioDomingo,
      endDate: newFechaFinDomingo,
      shifts: this.turnosArray,
      data: {
        machines: this.machinesGantt,
        //of = talderen izena machine = grupo de maquinas
        tasks: this.TasksDomingo,
        overlappingPeriods: this.maquinasLibresDomingo,
        visible: true

      }

    }, this.translateService)
      .on('task-ctrl-clicked', (id_grupo) => {
        this.nuevo = false;
        this.ganttClicked(id_grupo);
      });

    }
    this.loadingGantt = false;
    this.chartGanttTimeline = gantt;
    // this.chartGanttTimelineLunes = gantt_lunes;


  }
  // prepara todos los datos para usarlos en el Gantt
  prepararGantt() {
    debugger;
    this.asignarHoraInicioYFin(this.fechaInicio, this.fechaFin);
    this.arreglarTasksGantt();
    this.arreglarMachinesGantt();
    this.crearLineaMaquinasLibres();
    this.calcularMaquinasDisponibles();
  }
  arreglarTasksGantt() {
    //seleccionamos solo los horariosSecciones de la semana escogida
    this.horariosSecciones = this.dtHorariosSecciones.filter(f => new Date(f.fechaInicio) >= this.fechaInicio && new Date(f.fechaFin) <= this.fechaFin);
    this.horariosSecciones.forEach(horarioSeccion => {
      var grupoM = this.gruposMaquinas.filter(f => f.id == horarioSeccion.id_grupoMaquinas)[0];
      horarioSeccion.gMaquina = grupoM;
      var newTask = horarioSeccion;

      newTask.of = newTask.nombreGrupo;
      newTask.backgroundColor = newTask.colorGrupo;
      newTask.startDate = new Date(newTask.fechaInicio);
      newTask.endDate = new Date(newTask.fechaFin);
      newTask.machine = newTask.id_grupoMaquinas;
      newTask.overlap = false;
      newTask.overlapping = 1;
      newTask.overlapWith = [];
      newTask.diaItem = {
        dia: this.asignarDiaSemana(newTask.startDate).dia,
        id: this.asignarDiaSemana(newTask.startDate).id,
        fecha: newTask.startDate,
      };
      // newTask.maquinasMax = grupoM.maquinasKop;


      //TOOLTIP
      var tooltip = ` <p><span class="tooltip-title">` + newTask.nombreGrupo + " | " + `</span> <span class="tooltip-valor">` + newTask.alumnosGrupoKop + `</span>  <span class="tooltip-valor"> ` + this.translateService.instant('alumnoK') + `</span></p>
     <p><span class="tooltip-title">` + this.translateService.instant('cantidadMaquinas') + `:</span>   <span class="tooltip-valor"> ` + newTask.cantidadMaquinas.toString() + `/` + grupoM.maquinasKop.toString() + `</span>  </p>`;

      var tooltip2 = `
      <p><span class="tooltip-title">` + this.translateService.instant('grupoMaquinas') + `:</span>  <span class="tooltip-valor"> ` + grupoM.nombre + `</span>  </p>
      <p><span class="tooltip-title">` + this.translateService.instant('inicio') + `:</span>  <span class="tooltip-valor"> ` + newTask.startDate.toLocaleTimeString() + `</span>  </p>
      <p><span class="tooltip-title">` + this.translateService.instant('fin') + `:</span>  <span class="tooltip-valor"> ` + newTask.endDate.toLocaleTimeString() + `</span>  </p>`;
      newTask.detail = (d) => tooltip + tooltip2 + `
      <p><span class="tooltip-valor">${d.startDate.toLocaleDateString()} ${d.startDate.toLocaleTimeString()} - ${d.endDate.toLocaleDateString()} ${d.endDate.toLocaleTimeString()}</span> </p>`;
      // END TOOLTIP

      this.tasksGantt.push(newTask);
    });
    this.tasksGantt.forEach(task => {
      this.updateOverlapping(task);
    });
    /*
    this.tasksGantt.forEach(task => {
      this.updateOverlapping2(task);
    });*/
    this.TasksLunes = this.tasksGantt.filter(f => f.diaItem.id == 1);
    this.TasksMartes = this.tasksGantt.filter(f => f.diaItem.id == 2);
    this.TasksMiercoles = this.tasksGantt.filter(f => f.diaItem.id == 3);
    this.TasksJueves = this.tasksGantt.filter(f => f.diaItem.id == 4);
    this.TasksViernes = this.tasksGantt.filter(f => f.diaItem.id == 5);
    this.TasksSabado = this.tasksGantt.filter(f => f.diaItem.id == 6);
    this.TasksDomingo = this.tasksGantt.filter(f => f.diaItem.id == 0);

  }

  //devuelve el dia de la semana que corresponde a la fecha lunes-domingo
  //@param la fecha a la que asignar su dia de la semana
  asignarDiaSemana(date): { dia: string, id: number } {

    var dia: string = "";
    var id: number;
    if (date.getDay() == 1) {
      dia = this.translateService.instant('lunes');
      id = 1;
    }
    if (date.getDay() == 2) {
      dia = this.translateService.instant('martes');
      id = 2;
    }
    if (date.getDay() == 3) {
      dia = this.translateService.instant('miercoles');
      id = 3;
    }
    if (date.getDay() == 4) {
      dia = this.translateService.instant('jueves');
      id = 4;
    }
    if (date.getDay() == 5) {
      dia = this.translateService.instant('viernes');
      id = 5;
    }
    if (date.getDay() == 6) {
      dia = this.translateService.instant('sabado');
      id = 6;
    }
    if (date.getDay() == 0) {
      dia = this.translateService.instant('domingo');
      id = 7
    }
    return { dia, id };
  }
  asignarHoraInicioYFin(fechaInicio, fechaFin) {
    fechaInicio.setHours(this.horaMin.split(':')[0]);
    fechaInicio.setMinutes(this.horaMin.split(':')[1]);
    fechaInicio.setSeconds(0);
    fechaFin.setHours(this.horaMax.split(':')[0]);
    fechaFin.setMinutes(this.horaMax.split(':')[1]);
    fechaFin.setSeconds(0);

  }
  asignarHoraInicioYFinDia(fechaInicio, fechaFin, dia) {
    switch (dia) {
      case 1:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinLunes.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinLunes.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxLunes.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxLunes.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 2:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinMartes.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinMartes.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxMartes.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxMartes.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 3:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinMiercoles.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinMiercoles.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxMiercoles.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxMiercoles.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 4:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinJueves.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinJueves.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxJueves.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxJueves.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 5:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinViernes.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinViernes.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxViernes.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxViernes.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 6:
        if (fechaInicio != undefined) {
          fechaInicio.setHours(this.horaMinSabado.split(':')[0]);
          fechaInicio.setMinutes(this.horaMinSabado.split(':')[1]);
          fechaInicio.setSeconds(0);
        }
        if (fechaFin != undefined) {
          fechaFin.setHours(this.horaMaxSabado.split(':')[0]);
          fechaFin.setMinutes(this.horaMaxSabado.split(':')[1]);
          fechaFin.setSeconds(0);
        }
        break;
      case 7:
        if(this.horaMaxDomingo!=undefined && this.horaMinDomingo!=undefined){
          if (fechaInicio != undefined) {
            fechaInicio.setHours(this.horaMinDomingo.split(':')[0]);
            fechaInicio.setMinutes(this.horaMinDomingo.split(':')[1]);
            fechaInicio.setSeconds(0);
          }
          if (fechaFin != undefined) {
            fechaFin.setHours(this.horaMaxDomingo.split(':')[0]);
            fechaFin.setMinutes(this.horaMaxDomingo.split(':')[1]);
            fechaFin.setSeconds(0);
          }
          break;
        }
    

    }



  }
  //tratamo los grupos de maquinas recividos de la DB para darle el formato que necesita en el Gantt
  arreglarMachinesGantt() {
    this.gruposMaquinas.forEach(grupoMaquina => {
      debugger;
      var newMachine = grupoMaquina;
      newMachine.image = '';
      newMachine.name = newMachine.nombre;
      this.machinesGantt.push(newMachine);
    });
  }

  crearLineaMaquinasLibres() {

    this.gruposMaquinas.forEach(element => {
      var tasksDelGrupo: any = this.tasksGantt.filter(f => f.id_grupoMaquinas == element.id);
      //this.crearLineaMaquinasLibres2(tasksDelGrupo, element);
      this.diasSemana.forEach(dia => {
        var tasksDia = tasksDelGrupo.filter(f => f.diaItem.id == dia.id);
        this.crearLineaMaquinasLibres2(tasksDia, element, dia);
      });
    });
  }

  crearLineaMaquinasLibres2(tasks, grupoMaquina, dia) {
    //creamos la linea del lunes
    // var fini: Date = this.fechaInicio;
    //var ffin: Date = this.fechaFin;
    /*
    if (tasks.length == 0) {
      var newPeriodo: any = {
        startDate: fini,
        endDate: ffin,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id

      };
      this.overlappLineGantt.push(newPeriodo);
      return; 
    }*/
    var fini: Date = this.dateCopy(dia.fecha);
    var ffin: Date = this.dateCopy(dia.fecha);
    this.asignarHoraInicioYFinDia(fini, ffin, dia.id);


    if (tasks.length == 0 && dia.id == 1) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 1);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresLunes.push(newPeriodo);
    }
    if (tasks.length == 0 && dia.id == 2) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 2);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresMartes.push(newPeriodo);
    }

    if (tasks.length == 0 && dia.id == 3) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 3);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresMiercoles.push(newPeriodo);
    }

    if (tasks.length == 0 && dia.id == 4) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 4);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresJueves.push(newPeriodo);
    }

    if (tasks.length == 0 && dia.id == 5) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 5);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresViernes.push(newPeriodo);
    }

    if (tasks.length == 0 && dia.id == 6) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 6);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresSabado.push(newPeriodo);
    }

    if (tasks.length == 0 && dia.id == 7) {
      var startDate = this.dateCopy(dia.fecha);
      var endDate = this.dateCopy(dia.fecha);
      this.asignarHoraInicioYFinDia(startDate, endDate, 7);
      var newPeriodo: any = {
        startDate: startDate,
        endDate: endDate,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id
      };
      this.maquinasLibresDomingo.push(newPeriodo);
    }
    //creamos un array con todas las fechas de ese grupo de maquinas
    var dateArray: any = [];
    tasks.forEach(element => {

      dateArray.push(element.startDate);
      dateArray.push(element.endDate);
    });
    //ordenamos array
    var dateArraySorted = dateArray.slice().sort((a, b) => a - b);
    dateArraySorted.forEach(date => {

      var newPeriodo: any = {
        startDate: fini,
        endDate: date,
        machinesLeft: String(grupoMaquina.maquinasKop),
        machine: grupoMaquina.id

      };


      this.overlappLineGantt.push(newPeriodo);


      if (newPeriodo.startDate.getDay() == 1) {
        this.maquinasLibresLunes.push(newPeriodo);
      }
      if (newPeriodo.startDate.getDay() == 2) {
        this.maquinasLibresMartes.push(newPeriodo);
      }
      if (newPeriodo.startDate.getDay() == 3) {
        this.maquinasLibresMiercoles.push(newPeriodo);
      }
      if (newPeriodo.startDate.getDay() == 4) {
        this.maquinasLibresJueves.push(newPeriodo);
      }
      if (newPeriodo.startDate.getDay() == 5) {
        this.maquinasLibresViernes.push(newPeriodo);
      }
      if (newPeriodo.startDate.getDay() == 6) {
        this.maquinasLibresSabado.push(newPeriodo);
      }
      if (newPeriodo.startDate.getDay() == 0) {
        this.maquinasLibresDomingo.push(newPeriodo);
      }

      fini = date;

    });

    // la ultima fecha hasta el final
    var newPeriodo: any = {
      startDate: fini,
      endDate: ffin,
      machinesLeft: String(grupoMaquina.maquinasKop),
      machine: grupoMaquina.id

    };

    if (dia.id == 1) {
      this.maquinasLibresLunes.push(newPeriodo);
    }
    if (dia.id == 2) {
      this.maquinasLibresMartes.push(newPeriodo);
    }
    if (dia.id == 3) {
      this.maquinasLibresMiercoles.push(newPeriodo);
    }
    if (dia.id == 4) {
      this.maquinasLibresJueves.push(newPeriodo);
    }
    if (dia.id == 5) {
      this.maquinasLibresViernes.push(newPeriodo);
    }
    if (dia.id == 6) {
      this.maquinasLibresSabado.push(newPeriodo);
    }
    if (dia.id == 0) {
      this.maquinasLibresDomingo.push(newPeriodo);
    }
    this.overlappLineGantt.push(newPeriodo);

  }

  updateOverlapping3(idGgrupoM, myTask) {
    var tasksDelGrupoDeMaquinas: any = this.tasksGantt.filter(t => t.id_grupoMaquinas == idGgrupoM);
    tasksDelGrupoDeMaquinas.forEach(task => {
      task.overlapping = Math.max(task.overlapping, myTask.overlapWith.length + 1);
    });

  }
  updateOverlapping2(myTask) {

    if (myTask.zatitzeko == undefined) {
      var zatitzeko = myTask.overlapping;
      var tasksDelGrupoDeMaquinas: any = this.tasksGantt.filter(t => t.id_grupoMaquinas == myTask.gMaquina.id);

      if (myTask.overlapping == 2) {
        zatitzeko = 2;

      } else {
        myTask.overlapWith.forEach(id => {
          let task = this.tasksGantt.filter(f => f.id == id)[0];
          if (task.overlapWith.length != myTask.overlapWith.length) {
            zatitzeko = myTask.overlapping - 1;
          }
        });
      }

      tasksDelGrupoDeMaquinas.forEach(task => {
        task.zatitzeko = zatitzeko;

      });



    }

  }
  // actualiza el numero de tasks solapadas en un grupo de maquinas
  //@param task
  updateOverlapping(task) {
    var tasksDelGrupoDeMaquinas: any = this.tasksGantt.filter(t => t.id_grupoMaquinas == task.id_grupoMaquinas && t.id != task.id);

    //si algun periodo interfiere con otro subiemos el overlapping 
    for (let i = 0; i < tasksDelGrupoDeMaquinas.length; i++) {
      /* if (task.overlap == true) {
         return
       }*/

      if (tasksDelGrupoDeMaquinas[i].endDate > task.startDate) {
        if (tasksDelGrupoDeMaquinas[i].startDate < task.endDate) {
          task.overlap = true;
          //task.overlapping ++;
          task.overlapWith.push(tasksDelGrupoDeMaquinas[i].id);
          //tasksDelGrupoDeMaquinas[i].overlapWith.push(task.id);
          tasksDelGrupoDeMaquinas[i].overlap = true;
        //  this.updateOverlapping3(task.id_grupoMaquinas, task);


        }
      } else if (tasksDelGrupoDeMaquinas[i].startDate < task.endDate) {
        if (tasksDelGrupoDeMaquinas[i].endDate > task.startDate) {
          task.overlap = true;
          //task.overlapping ++;
          task.overlapWith.push(tasksDelGrupoDeMaquinas[i].id);
          //tasksDelGrupoDeMaquinas[i].push(task.id);
          tasksDelGrupoDeMaquinas[i].overlap = true;
          //this.updateOverlapping3(task.id_grupoMaquinas, task);

        }

      }

    }


  }
  calcularMaquinasDisponibles() {
    this.gruposMaquinas.forEach(grupo => {
      this.calcularMaquinasDisponibles2(grupo);
    });
  }
  calcularMaquinasDisponibles2(grupoMaquinas) {
    var numeroTotalMaquinasDisponibles: number = grupoMaquinas.maquinasKop;
    var periodosDeGrupo = this.overlappLineGantt.filter(f => f.machine == grupoMaquinas.id);
    periodosDeGrupo.forEach(periodo => {
      var maquinasOcupadas: number = 0;
      var tasksDentroDeEsePeriodo: any = this.tasksGantt.filter(f => f.id_grupoMaquinas == grupoMaquinas.id && (
        ((periodo.startDate < f.endDate) && (periodo.endDate > f.startDate) || (periodo.startDate == f.endDate) && (periodo.endDate == f.startDate))))
      tasksDentroDeEsePeriodo.forEach(task => {
        maquinasOcupadas += task.cantidadMaquinas;
      });
      var maquinasLibresPeriodo = numeroTotalMaquinasDisponibles - maquinasOcupadas;
      periodo.machinesLeft = String(maquinasLibresPeriodo);
    });

  }

  grupoSelectedChange(e) {
    let tasksDelGrupo = this.tasksGantt.filter(f => f.id_grupo == this.grupoSelected.id);
    this.horariosSeccionesGrupo = _.cloneDeep(tasksDelGrupo);

  }

  vistaAnualClick() {
    this.router.navigate(['eskola_horariosSecciones_vistaAnual']);

  }
  //MENU LATERAL
  ganttClicked(id_grupo) {

    var th = this;
    let tasksDelGrupo = this.tasksGantt.filter(f => f.id_grupo == id_grupo);
    this.horariosSeccionesGrupo = _.cloneDeep(tasksDelGrupo);
    this.grupoSelected = this.grupos.filter(f => f.id == id_grupo)[0];

    setTimeout(() => {

      th.mostrarMenuLateral();

    }, 200);


  }

  onClickSelectedGrupo(){
    var kendolist = document.getElementsByTagName("kendo-list");
    if (kendolist.length > 0) {
      kendolist[0].className = 'dropdown-colores';
    }
  }


  mostrarMenuLateral() {
    this.menuDesplegado = true;

  }

  ocultarMenuLateral() {

    if (this.chartGanttTimeline != undefined) {
      this.menuDesplegado = false;
      this.nuevo = false;
    }
  }
  actualizarFechaInicioMenuLateral(valueEmitted) {
    if (this.inicioMenuLateral != valueEmitted) {
      this.inicioMenuLateral = valueEmitted;
    }
  }
  actualizarFechaFinMenuLateral(valueEmitted) {
    if (this.finMenuLateral != valueEmitted) {
      this.finMenuLateral = valueEmitted;

    }
  }
  nuevoClick() {
    this.nuevo = true;
    this.grupoSelected = [];
    this.horariosSeccionesGrupo = [];
    this.horariosSeccionesEliminados = [];
    this.mostrarMenuLateral();
  }
  guardarClick() {
    if (this.grupoSelected.length == 0) {
      return;
    }

    if (this.avisoPeriodoFuera || this.avisosHorasFuera) {
      // estamos en la pantalla de aviso si acepta guardamos los cambios
      // eliminamos 
      this.horariosSeccionesEliminados.forEach(hsIdEliminar => {
        this.eliminarHorariosSeccion(hsIdEliminar);
        // vaciamos lista de hs para eliminar
        this.horariosSeccionesEliminados = [];
      });

      if (!this.replicarHorario && this.horariosSeccionesGrupo.length > 0) {
        this.guardarCambiosHorariosGrupo();
      } else if (this.horariosSeccionesGrupo.length > 0) {
        this.guardarCambiosHorariosGrupoConReplica();


      }
      setTimeout(() => {
        this.reiniciar();
      }, 200);

    } else if (!this.validarMenuLateral().avisos && this.validarMenuLateral().correcto) {
      // no hay errores ni avisos, guardarmos los cambios
      // eliminamos 
      this.horariosSeccionesEliminados.forEach(hsIdEliminar => {
        this.eliminarHorariosSeccion(hsIdEliminar);
        // vaciamos lista de hs para eliminar
        this.horariosSeccionesEliminados = [];
      });

      if (!this.replicarHorario) {
        this.guardarCambiosHorariosGrupo();
      } else if (this.horariosSeccionesGrupo.length > 0) {
        this.guardarCambiosHorariosGrupoConReplica();
      }
      setTimeout(() => {
        this.reiniciar();
      }, 200);
    } else {
      // hay errores
      return;
    }






  }
  // guarda los cambios en los horarios/secciones con la opcion de replicar
  guardarCambiosHorariosGrupoConReplica() {
    var endDate = this.dateCopy(this.finMenuLateral);
    endDate.setHours(23);
    endDate.setMinutes(59);
    var fecha: Date = this.dateCopy(this.inicioMenuLateral);

    if (this.repSemanal) {
      while (fecha <= endDate) {
        var paraInsertar: any = [];
        if (fecha.getDay() == 1) {
          // si es lunes le metemos todos los horarios de los lunes
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 1);
        } else if (fecha.getDay() == 2) {
          // si es martes le metemos todos los horarios de los martes
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 2);

        } else if (fecha.getDay() == 3) {
          // si es miercoles le metemos todos los horarios de los miercoles
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 3);

        } else if (fecha.getDay() == 4) {
          // si es jueves le metemos todos los horarios de los jueves
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 4);

        } else if (fecha.getDay() == 5) {
          // si es viernes le metemos todos los horarios de los viernes
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 5);

        } else if (fecha.getDay() == 6) {
          // si es sabado le metemos todos los horarios de los sabado
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 6);

        } else if (fecha.getDay() == 0) {
          // si es domingo le metemos todos los horarios de los domingo
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 7);

        }

        // insertamos 
        paraInsertar.forEach(horario => {
          var newFechaInicio: Date = this.dateCopy(fecha);
          newFechaInicio.setHours(horario.startDate.getHours());
          newFechaInicio.setMinutes(horario.startDate.getMinutes());
          var newFechaFin: Date = this.dateCopy(fecha);
          newFechaFin.setHours(horario.endDate.getHours());
          newFechaFin.setMinutes(horario.endDate.getMinutes());

          horario.fechaInicio = newFechaInicio;
          horario.fechaFin = newFechaFin;

          this.insertarHorarioSeccion(horario);
        });

        fecha.setDate(fecha.getDate() + 1);

      }

    } else {
      var weeks: any = [];

      var fechaWeeks = this.dateCopy(this.inicioMenuLateral);
      // guardamos las semanas en las que insertaremos
      while (fechaWeeks <= endDate) {
        weeks.push(this.getNumberOfWeek(fechaWeeks));
        fechaWeeks.setDate(fechaWeeks.getDate() + 14);
      }
      while (fecha <= endDate) {
        var paraInsertar: any = [];
        if (fecha.getDay() == 1 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es lunes le metemos todos los horarios de los lunes
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 1);
        } else if (fecha.getDay() == 2 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es martes le metemos todos los horarios de los martes
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 2);

        } else if (fecha.getDay() == 3 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es miercoles le metemos todos los horarios de los miercoles
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 3);

        } else if (fecha.getDay() == 4 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es jueves le metemos todos los horarios de los jueves
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 4);

        } else if (fecha.getDay() == 5 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es viernes le metemos todos los horarios de los viernes
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 5 && weeks.includes(this.getNumberOfWeek(fecha)));

        } else if (fecha.getDay() == 6 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es sabado le metemos todos los horarios de los sabado
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 6 && weeks.includes(this.getNumberOfWeek(fecha)));

        } else if (fecha.getDay() == 0 && weeks.includes(this.getNumberOfWeek(fecha))) {
          // si es domingo le metemos todos los horarios de los domingo
          paraInsertar = this.horariosSeccionesGrupo.filter(f => f.diaItem.id == 7 && weeks.includes(this.getNumberOfWeek(fecha)));

        }

        // insertamos 
        paraInsertar.forEach(horario => {
          var newFechaInicio: Date = this.dateCopy(fecha);
          newFechaInicio.setHours(horario.startDate.getHours());
          newFechaInicio.setMinutes(horario.startDate.getMinutes());
          var newFechaFin: Date = this.dateCopy(fecha);
          newFechaFin.setHours(horario.endDate.getHours());
          newFechaFin.setMinutes(horario.endDate.getMinutes());

          horario.fechaInicio = newFechaInicio;
          horario.fechaFin = newFechaFin;

          this.insertarHorarioSeccion(horario);
        });

        fecha.setDate(fecha.getDate() + 1);

      }

    }
  }
  // guarda los cambios en los horarios/secciones sin la opcion de replicar
  guardarCambiosHorariosGrupo() {
    // insertamos los nuevos
    this.horariosSeccionesGrupo.forEach(hsInsertar => {
      //arreglamos las fechas de inicio y fin
      var newFechaInicio: Date = this.dateCopy(hsInsertar.diaItem.fecha);
      newFechaInicio.setHours(hsInsertar.startDate.getHours());
      newFechaInicio.setMinutes(hsInsertar.startDate.getMinutes());
      var newFechaFin: Date = this.dateCopy(hsInsertar.diaItem.fecha);
      newFechaFin.setHours(hsInsertar.endDate.getHours());
      newFechaFin.setMinutes(hsInsertar.endDate.getMinutes());
      hsInsertar.fechaInicio = this.dateCopy(newFechaInicio);
      hsInsertar.fechaFin = this.dateCopy(newFechaFin);
      // si es nuevo insertamos
      if (hsInsertar.id == -1) {
        this.insertarHorarioSeccion(hsInsertar);
      } else {
        // si ya existía hacemos update
        this.updateHorarioSeccion(hsInsertar);
      }


    });

  }
  cancelarClick() {
    if (this.avisoPeriodoFuera || this.avisosHorasFuera) {
      this.avisoPeriodoFuera = false;
      this.avisosHorasFuera = false;
    } else {
      this.ocultarMenuLateral();
      this.grupoSelected = [];
      this.horariosSeccionesGrupo = [];
      this.horariosSeccionesEliminados = [];

      this.errorHoras = false;
      this.errorHorasExisten = false;
      this.errorFechasUndefined = false;
      this.errorHorasFueraCentro = false;

      this.replicarHorario = false;
      this.repSemanal = true;

    }


  }

  hayErrores(): boolean {
    this.errorHoras = false;
    this.errorHorasExisten = false;
    this.errorFechasUndefined = false;
    this.errorHorasFueraCentro = false;
    var hayErroes = false;

    // si quiere replicar horario, asegurarse de que hay una fecha de inicio y de fin
    if (this.replicarHorario && (this.inicioMenuLateral == undefined || this.finMenuLateral == undefined)) {
      this.errorFechasUndefined = true;
      hayErroes = true;

    }

    // comprobamos que las horas esten bien
    if (this.horariosSeccionesGrupo.length > 1) {

      for (let j = 0; j < this.horariosSeccionesGrupo.length - 1; j++) {
        var horario = this.horariosSeccionesGrupo[j];
        //comprobamos que las horas estén bien
        if (horario.startDate >= horario.endDate) {

          this.errorHoras = true;
          hayErroes = true;
          break;
          // miramos que las horas estén dentro de las horas minimas y maximas del centro
        } else {
        
          var dia = horario.diaItem.id;
          var horaInicio = this.myFunctions.dateToHHMM(horario.startDate);
          var horaFin = this.myFunctions.dateToHHMM(horario.endDate);
          switch (dia) {
            case 1:
              if (horaInicio < this.horaMinLunes || horaFin > this.horaMaxLunes) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;

            case 2:
              if (horaInicio < this.horaMinMartes || horaFin > this.horaMaxMartes) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 3:
              if (horaInicio < this.horaMinMiercoles || horaFin > this.horaMaxMiercoles) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 4:
              if (horaInicio < this.horaMinJueves || horaFin > this.horaMaxJueves) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 5:
              if (horaInicio < this.horaMinViernes || horaFin > this.horaMaxViernes) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 6:
              if (horaInicio < this.horaMinSabado || horaFin > this.horaMaxSabado) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 7:
              if (horaInicio < this.horaMinDomingo || horaFin > this.horaMaxDomingo) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
          }

        }
        //comprobamos que ningun periodo interfiera con las horas de otro 
        for (let i = j + 1; i < this.horariosSeccionesGrupo.length; i++) {
          if (this.horariosSeccionesGrupo[i].startDate >= this.horariosSeccionesGrupo[i].endDate) {
            this.errorHoras = true;
            hayErroes = true;
            break;

            // miramos que las horas estén dentro de las horas minimas y maximas del centro
          } else {
          var dia = this.horariosSeccionesGrupo[i].diaItem.id;
          var horaInicio = this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[i].startDate);
          var horaFin = this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[i].endDate);
          switch (dia) {
            case 1:
              if (horaInicio < this.horaMinLunes || horaFin > this.horaMaxLunes) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;

            case 2:
              if (horaInicio < this.horaMinMartes || horaFin > this.horaMaxMartes) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 3:
              if (horaInicio < this.horaMinMiercoles || horaFin > this.horaMaxMiercoles) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 4:
              if (horaInicio < this.horaMinJueves || horaFin > this.horaMaxJueves) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 5:
              if (horaInicio < this.horaMinViernes || horaFin > this.horaMaxViernes) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 6:
              if (horaInicio < this.horaMinSabado || horaFin > this.horaMaxSabado) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
            case 7:
              if (horaInicio < this.horaMinDomingo || horaFin > this.horaMaxDomingo) {
                this.errorHorasFueraCentro = true;
                hayErroes = true;
              }
              break;
          }

          }
          if (this.horariosSeccionesGrupo[i].diaItem.id == horario.diaItem.id && (this.horariosSeccionesGrupo[i].gMaquina.id == horario.gMaquina.id)) {

            if (this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[i].endDate) > this.myFunctions.dateToHHMM(horario.startDate)) {
              if (this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[i].startDate) < this.myFunctions.dateToHHMM(horario.endDate)) {
                this.errorHorasExisten = true;
                hayErroes = true;
                break;
              }
            } else if (this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[i].startDate) < this.myFunctions.dateToHHMM(horario.endDate)) {
              if (this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[i].endDate) > this.myFunctions.dateToHHMM(horario.startDate)) {
                this.errorHorasExisten = true;
                hayErroes = true;
                break;
              }

            }
          }
        }
      }
    } else if (this.horariosSeccionesGrupo.length == 1) {

      if (this.horariosSeccionesGrupo[0].startDate >= this.horariosSeccionesGrupo[0].endDate) {
        this.errorHoras = true;
        hayErroes = true;

      } else    
      
      var dia = this.horariosSeccionesGrupo[0].diaItem.id;
      var horaInicio = this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[0].startDate);
      var horaFin = this.myFunctions.dateToHHMM(this.horariosSeccionesGrupo[0].endDate);
      switch (dia) {
        case 1:
          if (horaInicio < this.horaMinLunes || horaFin > this.horaMaxLunes) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;

        case 2:
          if (horaInicio < this.horaMinMartes || horaFin > this.horaMaxMartes) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;
        case 3:
          if (horaInicio < this.horaMinMiercoles || horaFin > this.horaMaxMiercoles) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;
        case 4:
          if (horaInicio < this.horaMinJueves || horaFin > this.horaMaxJueves) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;
        case 5:
          if (horaInicio < this.horaMinViernes || horaFin > this.horaMaxViernes) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;
        case 6:
          if (horaInicio < this.horaMinSabado || horaFin > this.horaMaxSabado) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;
        case 7:
          if (horaInicio < this.horaMinDomingo || horaFin > this.horaMaxDomingo) {
            this.errorHorasFueraCentro = true;
            hayErroes = true;
          }
          break;
      }
    }

    return hayErroes;

  }

  hayAvisos(cursoGrupo, horariosCursoGrupo): boolean {

    var hayAvisos: boolean = false;
    this.avisoPeriodoFuera = false;
    this.avisosHorasFuera = false;
    if (this.replicarHorario && (this.inicioMenuLateral < new Date(cursoGrupo.inicio) || this.finMenuLateral > new Date(cursoGrupo.fin))) {
      // vemos si el periodo seleccionado está dentro del curso
      this.avisoPeriodoFuera = true;
      hayAvisos = true;
    }

    //miramos si las horas elegidas están dentrod el horario del curso
    for (var i = 0; i < this.horariosSeccionesGrupo.length; i++) {
      let hs = this.horariosSeccionesGrupo[i];
      var horariosDentroDeHorario: any = [];
      if (hs.diaItem.id == 1) {
        //miramos si los lunes están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 1 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      } else if (hs.diaItem.id == 2) {
        //miramos si los martes están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 2 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      } else if (hs.diaItem.id == 3) {
        //miramos si los miercoles están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 3 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      } else if (hs.diaItem.id == 4) {
        //miramos si los jueves están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 4 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      } else if (hs.diaItem.id == 5) {
        //miramos si los viernes están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 5 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      } else if (hs.diaItem.id == 6) {
        //miramos si los sabados están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 6 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      } else if (hs.diaItem.id == 7) {
        //miramos si los domingos están bien
        horariosDentroDeHorario = horariosCursoGrupo.filter(f => f.dia == 7 && (this.myFunctions.dateToHHMM(hs.startDate) >= f.horaInicio && this.myFunctions.dateToHHMM(hs.endDate) <= f.horaFin));

      }
      // si no devuelve ninguno sabemos que está fuera de horario
      if (horariosDentroDeHorario.length == 0) {
        hayAvisos = true;
        this.avisosHorasFuera = true;
        break;


      }
    };

    return hayAvisos;

  }
  validarMenuLateral(): { correcto: boolean, avisos: boolean } {
    var correcto: boolean = true;
    var avisar: boolean = false;
    var cursoGrupo = this.cursos.filter(f => f.id == this.grupoSelected.id_cursoLectivo)[0];
    var horariosCursoGrupo: any = this.horarios.filter(f => f.id_cursoLectivo == cursoGrupo.id);
    if (this.hayErrores()) {
      correcto = false;
    } else if (this.hayAvisos(cursoGrupo, horariosCursoGrupo)) {
      avisar = true;
    }


    return { correcto: correcto, avisos: avisar };


  }

  //END MENU LATERAL




  // MODAL ELIMINAR
  seleccionarEliminarClick() {
    this.vacio = false;
    //añadir la opcion de seleccionar todos
    this.grupos.unshift({ nombre: this.translateService.instant('todos'), id: -1 });
    this.gruposMaquinas.unshift({ nombre: this.translateService.instant('todos'), id: -1 });

    this.modalReference = this.modalService.open(this.popupEliminar, { backdrop: 'static', size: 's', keyboard: false, centered: true });

  }
  actualizarFechaInicioModalEliminar(valueEmitted) {
    if (this.inicioModalEliminar != valueEmitted) {
      this.inicioModalEliminar = valueEmitted;
    }
  }
  actualizarFechaFinModalEliminar(valueEmitted) {
    if (this.finModalEliminar != valueEmitted) {
      this.finModalEliminar = valueEmitted;

    }
  }
  aceptarModalEminarClick() {
    this.vacio = false;
    if(this.grupoSelectedModalEliminar.length==0 && this.diasSemanaSelectedModalEliminar.length==0 && this.gruposMaquinasSelectedModalEliminar.length==0 && 
      (this.inicioModalEliminar == undefined || this.finModalEliminar == undefined)){
      this.vacio = true;
    }

    if(this.vacio){
      return;
    } else if (!this.resumen) {
      // vamos a la ventada de resumen
      this.resumen = true;

    } else {
      if (this.finModalEliminar != undefined && this.inicioModalEliminar != undefined) {
        var endDate: Date = this.dateCopy(this.finModalEliminar);
        endDate.setHours(23);
        endDate.setMinutes(59);
      }
  
      var s1: boolean = false, s4: boolean = false, s2: boolean = false, s3: boolean = false;

      // filtramos los horarios
      var horariosParaEliminar = this.dtHorariosSecciones.filter(f => {
        // filtramos grupos
        if (this.grupoSelectedModalEliminar.length > 0) {
          (this.grupoSelectedModalEliminar.filter(g => g.id == f.id_grupo).length > 0) ? s1 = true : s1 = false;
        } else {
          s1 = true;
        
        }
  
        // filtramos periodo
        if (this.inicioModalEliminar != undefined && this.finModalEliminar != undefined) {
          (new Date(f.fechaInicio) >= this.inicioModalEliminar && new Date(f.fechaFin) <= endDate) ? s2 = true : s2 = false;
        } else {
          s2 = true;
          
        }
  
        // filtramos dia de la semana
        if (this.diasSemanaSelectedModalEliminar.length > 0) {
          (this.diasSemanaSelectedModalEliminar.filter(g => g.id == new Date(f.fechaInicio).getDay()).length > 0) ? s3 = true : s3 = false;
        } else {
          s3 = true;
        
        }
        // filtramos grupos de maquinas
        if (this.gruposMaquinasSelectedModalEliminar.length > 0) {
          (this.gruposMaquinasSelectedModalEliminar.filter(g => g.id == f.id_grupoMaquinas).length > 0) ? s4 = true : s4 = false;
        } else {
          s4 = true;
         
        }
        if (s1 && s2 && s3 && s4 && !this.vacio) {
          return f;
        }
  
      });
      // ya estamos en la pantalla de resumen así que eliminamos
      horariosParaEliminar.forEach(horario => {
        this.eliminarHorariosSeccion(horario.id);
      });
      this.modalReference.dismiss('cancel click');
      setTimeout(() => {
        this.reiniciar();
      }, 200);
    }



  }
  cancelarModalEliminarClick() {
    if (this.resumen) {
      // vamos a la ventana resumen
      this.resumen = false;
    } else {
      // cerramos popup
      this.modalReference.dismiss('cancel click');
      // limpiamos todo lo referente al popup eliminar
      this.finModalEliminar = undefined;
      this.inicioModalEliminar = undefined;
      this.diasSemanaSelectedModalEliminar = [];
      this.grupoSelectedModalEliminar = [];
      this.gruposMaquinasSelectedModalEliminar = [];
      this.resumen = false;
      this.grupos.shift();
      this.gruposMaquinas.shift();
    }



  }
  valueChangeDropdownEliminarModal(e, hitza) {
    if (this.diasSemanaSelectedModalEliminar.length > 0 && hitza == 'semana') {
      if (e.filter(f => f.id == -1).length > 0) {
        this.diasSemanaSelectedModalEliminar = this.diasDeLaSemanaDropdown.filter(f => f.id != -1);
      } else {
        this.diasSemanaSelectedModalEliminar = e;
      }
    }
    if (this.grupoSelectedModalEliminar.length > 0 && hitza == 'grupo') {
      if (e.filter(f => f.id == -1).length > 0) {
        this.grupoSelectedModalEliminar = this.grupos.filter(f => f.id != -1);
      } else {
        this.grupoSelectedModalEliminar = e;
      }
    }
    if (this.gruposMaquinasSelectedModalEliminar.length > 0 && hitza == 'grupoM') {
      if (e.filter(f => f.id == -1).length > 0) {
        this.gruposMaquinasSelectedModalEliminar = this.gruposMaquinas.filter(f => f.id != -1);
      } else {
        this.gruposMaquinasSelectedModalEliminar = e;
      }
    }
  }
  //END MODAL ELIMINAR

  // MODAL REPLICAR
  seleccionarReplicarSemanaClick() {
    this.modalReference = this.modalService.open(this.popupReplicar, { backdrop: 'static', size: 's', keyboard: false, centered: true });


  }
  cancelarModalReplicarSemanaClick() {
    if (this.resumenReplicar) {
      this.resumenReplicar = false;
    } else {
      // cerramos popup
      this.modalReference.dismiss('cancel click');
      // limpiamos todo lo referente al popup de replicar
      this.inicioModalReplicar = undefined;
      this.finModalReplicar = undefined;
    }
  }


  aceptarModalReplicarSemanaClick() {
    if (this.inicioModalReplicar == undefined || this.finModalReplicar == undefined) {
      this.errorFechasUndefined = true;
    } else if (!this.resumenReplicar) {
      this.resumenReplicar = true;
      this.errorFechasUndefined = false;
    } else {
      var endDate = this.dateCopy(this.finModalReplicar);
      endDate.setHours(23);
      endDate.setMinutes(59);

      // se sobreescribirán los horarios que estén en ese periodo
      var horariosParaEliminar = this.dtHorariosSecciones.filter(f => new Date(f.fechaInicio) >= this.inicioModalReplicar && new Date(f.fechaFin) <= endDate);

      horariosParaEliminar.forEach(horario => {
        this.eliminarHorariosSeccion(horario.id);

      });
      // replicamos los horarios de esa semana dentro del periodo seleccionado
      var fecha: Date = this.dateCopy(this.inicioModalReplicar);

      while (fecha <= endDate) {
        var paraInsertar: any = [];
        if (fecha.getDay() == 1) {
          // si es lunes le metemos todos los horarios de los lunes
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 1);
        } else if (fecha.getDay() == 2) {
          // si es martes le metemos todos los horarios de los martes
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 2);

        } else if (fecha.getDay() == 3) {
          // si es miercoles le metemos todos los horarios de los miercoles
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 3);

        } else if (fecha.getDay() == 4) {
          // si es jueves le metemos todos los horarios de los jueves
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 4);

        } else if (fecha.getDay() == 5) {
          // si es viernes le metemos todos los horarios de los viernes
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 5);

        } else if (fecha.getDay() == 6) {
          // si es sabado le metemos todos los horarios de los sabado
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 6);

        } else if (fecha.getDay() == 0) {
          // si es domingo le metemos todos los horarios de los domingo
          paraInsertar = this.tasksGantt.filter(f => f.diaItem.id == 7);

        }

        // insertamos 
        paraInsertar.forEach(horario => {
          var newFechaInicio: Date = this.dateCopy(fecha);
          newFechaInicio.setHours(horario.startDate.getHours());
          newFechaInicio.setMinutes(horario.startDate.getMinutes());
          var newFechaFin: Date = this.dateCopy(fecha);
          newFechaFin.setHours(horario.endDate.getHours());
          newFechaFin.setMinutes(horario.endDate.getMinutes());

          horario.fechaInicio = newFechaInicio;
          horario.fechaFin = newFechaFin;

          this.insertarHorarioSeccion(horario);
        });

        fecha.setDate(fecha.getDate() + 1);

      }
      this.modalReference.dismiss('cancel click');
      setTimeout(() => {
        this.reiniciar();
      }, 200);
    }


  }

  actualizarFechaInicioModalReplicar(valueEmitted) {
    if (this.inicioModalReplicar != valueEmitted) {
      this.inicioModalReplicar = valueEmitted;
    }
  }
  actualizarFechaFinModalReplicar(valueEmitted) {
    if (this.finModalReplicar != valueEmitted) {
      this.finModalReplicar = valueEmitted;

    }
  }
  //END MODAL REPLICAR
  // FUNCIONES LLAMADA SERVICE
  eliminarHorariosSeccion(id) {
    this.eskola_horariosSeccionesService.eliminarHorarioSeccion(id).subscribe();
  }
  insertarHorarioSeccion(hsInsertar) {
    this.eskola_horariosSeccionesService.insertHorarioSeccionGrupo(hsInsertar.fechaInicio, hsInsertar.fechaFin, hsInsertar.gMaquina.id, hsInsertar.cantidadMaquinas, hsInsertar.id_grupo).subscribe();
  }
  updateHorarioSeccion(hsUpdate) {
    this.eskola_horariosSeccionesService.updateHorarioSeccionGrupo(hsUpdate.id, hsUpdate.fechaInicio, hsUpdate.fechaFin, hsUpdate.gMaquina.id, hsUpdate.cantidadMaquinas, hsUpdate.id_grupo).subscribe();

  }
  // END FUNCIONES SERVICE

  //UTILS
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  getNumberOfWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }
  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.myFunctions.addZero(mes) + '-' + this.myFunctions.addZero(dia) + 'T' + this.myFunctions.addZero(hora) + ':' + this.myFunctions.addZero(minutos) + ':' + this.myFunctions.addZero(segundos) + 'Z';
  }
  dateToYYYYMMDD(fecha: Date) {
    //2020-10-25
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return año + '/' + this.myFunctions.addZero(mes) + '/' + this.myFunctions.addZero(dia);
  }

  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds());
  }
  // END UTILS


}
