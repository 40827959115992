import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricoOperacionesService, AlertService, MenuService, RecetasService, UsuariosService, InformeProyectosService, RecetasInduccionService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { MyFunctions } from '@app/_helpers';
import { GroupResult } from '@progress/kendo-data-query';
import { ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-recetasInyeccion',
  templateUrl: './recetasInyeccion.component.html'
})
export class RecetasInyeccionComponent implements OnInit {

  navigationSubscription;

  idof: any;
  idpieza: any;
  idparte: string;
  idruta: string;
  idOperacion: string;
  idVista: string;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  idReceta: any;

  // GRID CICLOS
  ciclos: any;
  ciclosMap = new Map();
  seleccionados: any = [];
  seleccionadosMap = new Map();
  lastEditado: any = 'original';
  ciclosLag = [];
  ciclosOriginal = [];
  ciclosDiferentes = []; // esto se utiliza para que no si ya se ha comprobado que es diferente el ciclo no se vuelva a comprobar con otro valor

  // GRID EDITAR RECETAS
  recetaInf: any = [
    { campo: "tiempociclo" },
    { campo: "tiempoinyeccion" },
    { campo: "tiempopresionremanente" },
    { campo: "tiempoenfriamiento" },
    { campo: "tiempodosificacionplastificacion" },
    { campo: "presioninyeccionmax" },
    { campo: "presionremanente" },
    { campo: "contrapresion" },
    { campo: "ptoconmutacion" },
    { campo: "paradadosificado" },
    { campo: "retrocesohusillo" },
    { campo: "paradamolde" },
    { campo: "cojin" },
    { campo: "revolucioneshusillo" },
    { campo: "fuerzadecierre" },
    // { campo: "temperaturacamaracaliente" },
    { campo: "temppresionfija" },
    { campo: "temppresionmovil" },
    { campo: "temperaturamolde" },
  ];
  lengthColumnas: number = 0;
  titulos = [];
  // recetaInfLag: any = [
  //   { campo: "tiempociclo" },
  //   { campo: "tiempoinyeccion" },
  //   { campo: "tiempopresionremanente" },
  //   { campo: "tiempoenfriamiento" },
  //   { campo: "tiempodosificacionplastificacion" },
  //   { campo: "presioninyeccionmax" },
  //   { campo: "presionremanente" },
  //   { campo: "contrapresion" },
  //   { campo: "ptoconmutacion" },
  //   { campo: "paradadosificado" },
  //   { campo: "retrocesohusillo" },
  //   { campo: "paradamolde" },
  //   { campo: "cojin" },
  //   { campo: "revolucioneshusillo" },
  //   { campo: "fuerzadecierre" },
  //   // { campo: "temperaturacamaracaliente" },
  //   { campo: "temppresionfija" },
  //   { campo: "temppresionmovil" },
  //   { campo: "temperaturamolde" },
  // ];
  recetasDif: any;
  mostrarTodo = true;
  recetaInfOriginal: any = [
    { campo: "tiempociclo" },
    { campo: "tiempoinyeccion" },
    { campo: "tiempopresionremanente" },
    { campo: "tiempoenfriamiento" },
    { campo: "tiempodosificacionplastificacion" },
    { campo: "presioninyeccionmax" },
    { campo: "presionremanente" },
    { campo: "contrapresion" },
    { campo: "ptoconmutacion" },
    { campo: "paradadosificado" },
    { campo: "retrocesohusillo" },
    { campo: "paradamolde" },
    { campo: "cojin" },
    { campo: "revolucioneshusillo" },
    { campo: "fuerzadecierre" },
    // { campo: "temperaturacamaracaliente" },
    { campo: "temppresionfija" },
    { campo: "temppresionmovil" },
    { campo: "temperaturamolde" },
  ];
  esPrimeraVuelta: boolean = true;
  esPrimeraVueltaHusillo: boolean = true;

  // Rango de ciclos
  value: any = [0, 600]; // [row.rangoCiclos.min, row.rangoCiclos.max]
  soloEdicion: any = false;

  vieneDeOperacionPred: boolean;

  soloFueraParametros: boolean = false;

  fechaini: Date;
  fechafin: Date;

  //TABS
  listaTCamaraCaliente = [];
  listaTHusillo = [];
  listaCascadaAbrir1 = [];
  listaCascadaCerrar1 = [];
  listaCascadaAbrir2 = [];
  listaCascadaCerrar2 = [];

  user = this.userService.userValue;

  // VARIABLES input del filtro
  private dataFiltro: any;

  public opcionOf: any = [];

  //AREA PRODUCTIVA / SECCION
  public groupedSeccion: GroupResult[];
  //GRUPOS DE MAQUINAS
  public grupos: any;

  public fini: any;
  public ffin: any;
  public idcliente: any;
  // public idpieza: any;
  // public idof: any;
  public nSerie: any;
  // public idOperacion: any;

  public loadingDatos: boolean = false;
  public tieneDatos: boolean = true;

  status: boolean = true;
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
  // DATOS INICIALES DEL FILTRO
  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: ["fechaini", "fechafin"], tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: new Date(this.route.snapshot.params['fechaIni'].replace(/_/g, "-")), // this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: new Date(this.route.snapshot.params['fechaFin'].replace(/_/g, "-")), // this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 3, nombre: this.translateService.instant('of'), sqlfield: 'numeroOF', tipo: 'of' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 600, max: 700 },
            of: this.route.snapshot.params['numeroOF']
          },
          {
            columna: { id: 4, nombre: this.translateService.instant('rangoCiclos'), sqlfield: "row_num", tipo: 'rangoCiclos' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: true,
            tipoNoVisible: true,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: this.value[0], max: this.value[1] },
            of: -1
          },
          {
            columna: { id: 5, nombre: this.translateService.instant('soloFueraParametro'), sqlfield: 'valorFueraLimites', tipo: 'fueraParametro' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 6, nombre: this.translateService.instant('soloConEdicionReceta'), tipo: 'edicionReceta' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 7, nombre: this.translateService.instant('soloConFalloCalidad'), tipo: 'falloCalidad' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          },
          {
            columna: { id: 8, nombre: this.translateService.instant('soloConControlCalidad'), tipo: 'controlCalidad' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            tipoNoEditable: false,
            tipoNoVisible: false,
            fueraParametro: false,
            conEdicionReceta: false,
            conFalloCalidad: false,
            conControlCalidad: false,
            rangoCiclos: { min: 0, max: 0 },
            of: -1
          }
        ]
      }
    ]
  }; 
  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };  
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },     
    { id: 3, nombre: this.translateService.instant('of'), field: "of", sqlfield: "hb.fechaTurno", tipo: 'of' },
    { id: 4, nombre: this.translateService.instant('rangoCiclos'), field: "rangoCiclos", sqlfield: "hb.fechaTurno", tipo: 'rangoCiclos' },
    { id: 5, nombre: this.translateService.instant('soloFueraParametro'), field: "soloFueraParametro", sqlfield: "hb.fechaTurno", tipo: 'soloFueraParametro' },
    { id: 6, nombre: this.translateService.instant('soloConEdicionReceta'), field: "soloConEdicionReceta", sqlfield: "hb.fechaTurno", tipo: 'soloConEdicionReceta' },
    { id: 7, nombre: this.translateService.instant('soloConFalloCalidad'), field: "soloConFalloCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConFalloCalidad' },
    { id: 8, nombre: this.translateService.instant('soloConControlCalidad'), field: "soloConControlCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConControlCalidad' },                      
    // { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },                 
    // { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "m.idSeccion", tipo: 'comboEstrincto' },              
    // { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },  
    // { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },             
    // { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },          
    // { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },                     
    // { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },      
    // { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },           
    // { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },                           
    // { id: 12, nombre: this.translateService.instant('parte'), field: "nombreParte", sqlfield: "do.nombreParte", tipo: 'comboFlexible' },           
    // { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
    // { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "do.operacionTerminada", tipo: 'check' }             
  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;
  //END FILTRO


  constructor(
    private recetasService: RecetasService,
    private recetasInduccionService: RecetasInduccionService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private informeProyectosService: InformeProyectosService,
    private historicoOperacionesService: HistoricoOperacionesService,
  ) {
    this.menuService.titulo = this.translateService.instant('recetas').toUpperCase();
    this.cargar_Filtro();

  }
 
  ngOnInit() {
    this.idof = this.route.snapshot.params['idof'];
    this.idpieza = this.route.snapshot.params['idpieza'];
    this.idparte = this.route.snapshot.params['idparte'];
    this.idruta = this.route.snapshot.params['idruta'];
    this.idOperacion = this.route.snapshot.params['idOperacion'];
    this.idVista = this.route.snapshot.params['idVista'];
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.idReceta = parseInt(this.route.snapshot.params['idReceta']);

    this.vieneDeOperacionPred = !this.idof && !this.idpieza && !this.idparte && !this.idruta && !this.idVista;

    this.cargarDatos();   
  
  }

  // cargar datos
  cargarDatos() {
    this.loading = true;
    // PARAMS
    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;

    // es necesario el anno 1950 porque de esta manera no filtrara las fechas
    this.historicoOperacionesService.Get_procesos_inyeccion_conRowNumer(idHistorico_operaciones, new Date(1950, 1, 1), new Date(1950, 1, 1)).subscribe((result) => {
      this.ciclos = result;
      
      // obtener la receta original
      this.getRecetaOriginal(result[0]);

      // obtener en el formato adecuado todas las recetas
      this.getHistoricoRecetas(result);

      // crear informacion que se va a enseñar al pulsar el boton de mostrar todos
      this.crearMostrarTodos();

      // crear las figuras que se ensennan en el grid de los ciclos
      this.ciclosGrid();

      this.loading = false;
    });

  }

  // get receta original
  getRecetaOriginal(result) {
    // convertir en minuscula todas las keys
    this.lastEditado = "original";
    this.recetaInfOriginal.forEach(row => {
      row["original"] = result[row["campo"]+'real'];
      row["tolerancia"] = result[row["campo"]+'tolerancia'];
    });
  }

  // get todas las recetas
  getHistoricoRecetas(result) {
    this.ciclos = result.sort(function(a,b) {return a.row_num - b.row_num});;
    this.ciclos.forEach(row => {
      this.datosCamaraCaliente(row);
      this.datosHusillo(row);
      row['nombre'] = this.translateService.instant('ciclo') + " " + row["nCiclo"];
      row['number'] = row["row_num"];
      row['dif'] = false;
      row['edited'] = false;
      row['metido'] = false;
      row['calidad'] = false;
      this.ciclosMap.set(row["idCiclo"], row);

      // of combo
      if (this.opcionOf.indexOf(row['numeroOF']) == -1) this.opcionOf.push(row['numeroOF']);
    });
    this.ciclosOriginal = this.ciclos;

    // al principio ensennar todos los ciclos en el filtro
    this.value = [0, this.ciclosMap.size];
    // cargar las fechas dependiendo de los ciclos cargardos 
    this.datosFiltro.group[0].group[0].fechaIni = new Date(this.ciclos[0].fechaini);
    this.datosFiltro.group[0].group[0].fechaFin = this.myFunctions.dateAddDays(new Date(this.ciclos[this.ciclosMap.size - 1].fechafin), 1);

    // Calcular media
    this.recetaInfOriginal.forEach(row => {
      var total = 0;
      this.ciclos.forEach(element => {
        total += element[row["campo"]];
      });
      if (this.ciclos.length != 0) row["media"] = Math.round(total / this.ciclos.length * 100) / 100;
      else row["media"] = "-";
    });  
    
    
  }

  // preparar datos de camara caliente
  datosCamaraCaliente(row) {
    var arrayDatos = row.tcamaracaliente.split(/\r\n,|\r\n/);
    arrayDatos = arrayDatos.slice(1, -1);

    var original = row.tcamaracalientereal;
    var tolerancia = row.tcamaracalientetolerancia;

    var j = 1;
    arrayDatos.forEach(element => {
      var value = parseInt(element);

      row['zona' + j] = value;
      row['zona' + j + 'tolerancia'] = tolerancia;
      row['zona' + j + 'real'] = original;

      if (this.esPrimeraVuelta) { // esto es para que unicamente se inserten una vez los campos de las camaras en las recetas
        this.recetaInf.push({ campo: 'zona' + j, original: original, tolerancia: tolerancia });
        // this.recetaInfLag.push({ campo: 'zona' + j, original: original, tolerancia: tolerancia });
        this.recetaInfOriginal.push({ campo: 'zona' + j, original: original, tolerancia: tolerancia });

      }

      j++;
    });

    this.esPrimeraVuelta = false;
  }

  // preparar datos tHusillo
  datosHusillo(row) {
    var arrayDatos = row.thusillo.split(/\r\n,|\r\n/);
    arrayDatos = arrayDatos.slice(1, -1);

    // brida
    row['thusillobrida'] = parseInt(arrayDatos.pop());
    row['thusillobridatolerancia'] = row.thusillobridatolerancia;
    row['thusillobridareal'] = row.thusillobridareal;
    if (this.esPrimeraVueltaHusillo) {
      this.recetaInf.push({ campo: 'thusillobrida', original: row.thusillobridareal, tolerancia: row.thusillobridatolerancia });
      // this.recetaInfLag.push({ campo: 'thusillobrida', original: row.thusillobridareal, tolerancia: row.thusillobridatolerancia });
      this.recetaInfOriginal.push({ campo: 'thusillobrida', original: row.thusillobridareal, tolerancia: row.thusillobridatolerancia });
    }
    

    // zona
    var original;
    var tolerancia;
    var value;
    var j = 1;
    arrayDatos.forEach(element => { 
      value = parseInt(element);
      original = row['thusillozona'+j+'real'];
      tolerancia = row['thusillozona'+j+'tolerancia'];

      row['thusillozona' + j] = value;
      row['thusillozona' + j + 'tolerancia'] = tolerancia;
      row['thusillozona' + j + 'real'] = original;

      if (this.esPrimeraVueltaHusillo) { // esto es para que unicamente se inserten una vez los campos de las camaras en las recetas
        this.recetaInf.push({ campo: 'thusillozona' + j, original: original, tolerancia: tolerancia });
        // this.recetaInfLag.push({ campo: 'thusillozona' + j, original: original, tolerancia: tolerancia });
        this.recetaInfOriginal.push({ campo: 'thusillozona' + j, original: original, tolerancia: tolerancia });
      }
      j++;
    });

    this.esPrimeraVueltaHusillo = false;
  }
  
  // Reiniciar los parametros
  reiniciarParametros(json) {
    // GRID CICLOS
    this.ciclosMap = new Map();
    this.seleccionados = [];
    this.seleccionadosMap = new Map();
    this.lastEditado = "original";
    this.ciclosLag = [];
    this.ciclosOriginal = [];

    // GRID EDITAR RECETAS
    this.recetaInf = [
      { campo: "tiempoinyeccion" },
      { campo: "tiempopresionremanente" },
      { campo: "tiempoenfriamiento" },
      { campo: "tiempodosificacionplastificacion" },
      { campo: "presioninyeccionmax" },
      { campo: "presionremanente" },
      { campo: "contrapresion" },
      { campo: "ptoconmutacion" },
      { campo: "paradadosificado" },
      { campo: "retrocesohusillo" },
      { campo: "paradamolde" },
      { campo: "cojin" },
      { campo: "revolucioneshusillo" },
      { campo: "fuerzadecierre" },
      { campo: "temperaturacamaracaliente" },
      { campo: "temppresionfija" },
      { campo: "temppresionmovil" },
      { campo: "temperaturamolde" },
    ];
    this.lengthColumnas = 0;
    this.titulos = [];
    this.recetaInfOriginal = [
      { campo: "tiempoinyeccion" },
      { campo: "tiempopresionremanente" },
      { campo: "tiempoenfriamiento" },
      { campo: "tiempodosificacionplastificacion" },
      { campo: "presioninyeccionmax" },
      { campo: "presionremanente" },
      { campo: "contrapresion" },
      { campo: "ptoconmutacion" },
      { campo: "paradadosificado" },
      { campo: "retrocesohusillo" },
      { campo: "paradamolde" },
      { campo: "cojin" },
      { campo: "revolucioneshusillo" },
      { campo: "fuerzadecierre" },
      { campo: "temperaturacamaracaliente" },
      { campo: "temppresionfija" },
      { campo: "temppresionmovil" },
      { campo: "temperaturamolde" },
    ];

    this.esPrimeraVuelta = true;
    this.esPrimeraVueltaHusillo = true;


    this.getRecetaOriginal(json[0]);   
    this.getHistoricoRecetas(json);
    this.crearMostrarTodos();
    this.ciclosGrid();  

  }

  updateRangoCiclos(value) {
    this.value = [parseInt(value[0]), parseInt(value[1])];
  }

  onChange() {
    var grid = [];
    this.titulos = [];
    this.seleccionados.forEach(row => {
      grid.push(this.ciclosMap.get(row));
    });

    grid.forEach(row => {
      this.titulos.push(row["nCiclo"]);
    })

    this.recetaInfOriginal.forEach(row => {
      var j = 0;
      grid.forEach(element => {
        element = this.toLowerKeys(element);
        row["ciclo"+j] = element[row["campo"].toLowerCase()];
        j++;
      });
    });
    this.lengthColumnas = this.seleccionados.length;
    this.crearMostrarTodos();
  }

  toLowerKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  // Esta funcion crea las figuras del grid del ciclo, es decir, saber si la receta del ciclo esta editada o es diferente de la original
  ciclosGrid() {

    // receta original == receta
    var recetaLag = [];// = this.recetaInfOriginal
    recetaLag = this.myFunctions.copy(this.recetaInfOriginal);

    recetaLag.forEach(row => {
      this.ciclosMap.forEach(element => {
        row[element["number"]] = element[row["campo"]];
      });
    });


    for (let i = 1; i <= Object.keys(recetaLag[0]).length - 5 ; i++) {
      recetaLag.every(
        variable => {
          var original = variable[i];
          var tolerancia = variable['tolerancia'];
          var value = variable['original'];
          if (!(original-tolerancia <= value &&  value <= original + tolerancia)) {
            this.cicloDiferenteAlOriginal(i);
            return false;
          }

          return true; // es importante poner true sino sale del every
        });
    }

    // receta editada
    this.ciclosLag = []
    for (var j = 0; j<this.ciclos.length; j++) {
      recetaLag.forEach(row => {
        let original = row[this.lastEditado];
        if (row[this.ciclos[j]["row_num"]] != original) {
          this.lastEditado = this.ciclos[j]["row_num"];
          this.ciclos[j]["edited"] = true;
          if (!this.ciclos[j]["metido"]) {
            this.ciclos[j]["metido"] = true;
            this.ciclosLag.push(this.ciclos[j]);
          }
        }
      });
    }

    if (this.soloEdicion) this.ciclos = this.ciclosLag;
    else this.ciclos = this.ciclosOriginal;

    if (this.soloFueraParametros)
      this.ciclos = this.ciclos.filter(f=> f.dif == true);

    // para seleccionar el ciclo seleccionado en la pagina de historico proceso
    if (this.ciclosMap.get(this.idReceta) != undefined) {
      this.seleccionados.push(this.idReceta);
      this.onChange(); 
    }

    this.loading = false;
  }

  cicloDiferenteAlOriginal(row_num_ciclo) {
    this.ciclos.forEach(element => {
      if (element["row_num"] == row_num_ciclo) {
        element['dif'] = true;
        return;
      }
    });
  }

  crearMostrarTodos() {
    this.recetaInf = this.recetaInfOriginal;
    this.recetasDif = [];

    // Comprobar si es igual que la receta original
    this.recetaInfOriginal.forEach(row => {
      let dif = false;
      let original = row["original"];
      for (var key in row) {
        if (key != "campo" && key != "media" && key != "original") {
          let tolerancia = this.ciclos[0][row["campo"]+'tolerancia'];
          let value = row[key];
          if (!(original-tolerancia <= value &&  value <= original + tolerancia)) {
            dif = true;
            row["dif"] = true;
            break;
          }
        }
      }
      if (dif) {
        this.recetasDif.push(row);
      }
    });
    if (this.mostrarTodo) this.recetaInf = this.recetaInfOriginal;
    else this.recetaInf = this.recetasDif;
  }

  mostrarTodos() {
    var btn = document.getElementById("btn-mostrarTodos");
    var i = document.getElementById("i-mostrarTodos");
    if (this.mostrarTodo) {
      this.recetaInf = this.recetasDif;
      btn.className = "btn";
      i.className = " icon-check"
      this.mostrarTodo = false;
    } else {
      this.recetaInf = this.recetaInfOriginal;
      btn.className = "btn btn-success";
      i.className = " icon-equis"
      this.mostrarTodo = true;
    }
  }


  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  //FILTRO
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -6));
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow());
    
    
  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo() {
    var em = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          // if (jFiltro.operator.dobleValor) {
          //   if (jFiltro.operator.id == 7) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield[1] + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
          //   }
          //   else if (jFiltro.operator.id == 8) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield[1] + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
          //   }
          // }
          // else {
          //   if (jFiltro.operator.id == 1) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          //   }
          //   else if (jFiltro.operator.id == 2) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          //   }
          //   else if (jFiltro.operator.id == 3) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          //   }
          //   else if (jFiltro.operator.id == 4) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          //   }
          //   else if (jFiltro.operator.id == 5) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          //   }
          //   else if (jFiltro.operator.id == 6) {
          //     sqlFilter = jFiltro.columna.sqlfield[0] + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
          //   }
          // }
          this.fechaini = jFiltro.fechaIni;
          this.fechafin = jFiltro.fechaFin;
        }
        // OF
        if (jFiltro.columna.tipo == 'of') {
            sqlFilter = jFiltro.columna.sqlfield + " = " + jFiltro.of;
        }
        // RANGO CICLOS
        if (jFiltro.columna.tipo == 'rangoCiclos') {
          sqlFilter = jFiltro.columna.sqlfield + " BETWEEN " + this.value[0] + " AND " + this.value[1];
        }
        // FUERA DE PARAMETROS
        if (jFiltro.columna.tipo == 'fueraParametro') {
          if (jFiltro.fueraParametro) this.soloFueraParametros = true; //sqlFilter = jFiltro.columna.sqlfield + " >= 1 ";
          else this.soloFueraParametros = false;
          
        }
        // EDICION DE RECETA
        if (jFiltro.columna.tipo == 'edicionReceta') {
          this.soloEdicion = jFiltro.conEdicionReceta;
        }

        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 7);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
      { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
      { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
      { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
    ];


    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES
    
    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
        { id: 3, nombre: this.translateService.instant('of'), field: "of", sqlfield: "hb.fechaTurno", tipo: 'of' },
        { id: 4, nombre: this.translateService.instant('rangoCiclos'), field: "rangoCiclos", sqlfield: "hb.fechaTurno", tipo: 'rangoCiclos' },
        { id: 5, nombre: this.translateService.instant('soloFueraParametro'), field: "soloFueraParametro", sqlfield: "hb.fechaTurno", tipo: 'soloFueraParametro' },
        { id: 6, nombre: this.translateService.instant('soloConEdicionReceta'), field: "soloConEdicionReceta", sqlfield: "hb.fechaTurno", tipo: 'soloConEdicionReceta' },
        { id: 7, nombre: this.translateService.instant('soloConFalloCalidad'), field: "soloConFalloCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConFalloCalidad' },
        { id: 8, nombre: this.translateService.instant('soloConControlCalidad'), field: "soloConControlCalidad", sqlfield: "hb.fechaTurno", tipo: 'soloConControlCalidad' },
        // { id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto' },
        // { id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "m.idSeccion", tipo: 'comboEstrincto' },
        // { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto' },
        // { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto' },
        // { id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto' },
        // { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },
        // { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },
        // { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },
        // { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
        // { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
        // { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "do.operacionTerminada", tipo: 'check' }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      
  }
  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id == 3) { // turnos
        row.combo = this.filtro_listaTurnos;
      }
      else if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // operarios 
        row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.nombreOf)) {
              lag.push(of.nombreOf);
              var js = { id: of.nombreOf, nombre: of.nombreOf };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
              lag.push(operacion.nombreOperacion);
              var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {

    var idHistorico_operaciones = this.route.snapshot.params.idHistorico_operaciones;
    var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    var filtroCompleto: any = this.filtroToSQL(); // filtro completo
    this.status = true;
    this.actualizarVisible = false;
    //FECHAS
    var fini = this.fechaini;
    var ffin = this.fechafin;

    this.loading = true;
    // this.recetasInduccionService.Get_historico_recetas_filtrado(idHistorico_procesos, filtroCompleto).subscribe((result) => {
    //   this.reiniciarParametros(result);    
    // });

    this.historicoOperacionesService.Get_procesos_inyeccion_conRowNumer(idHistorico_operaciones, fini, ffin, filtroCompleto).subscribe((result) => {
      this.reiniciarParametros(result);   
    });

  }
  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
      // Historico Piezas:
      //   - fechaIni , fechaFin *fijo*
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

    }
    else {
      // Informe Proyectos:
      //   - fechaIni , fechaFin *fijo*
      //   - idcliente (posible extra)
      //   - idpieza (posible extra)
      //   - idof (posible extra)
      //   - terminados (posible extra)
      // FECHAS (INICIO, FIN)
      /**/this.fini = this.route.snapshot.params['fini'];
      /**/this.ffin = this.route.snapshot.params['ffin'];
      if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
        && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
      // OF
      /**/this.idof = Number.parseInt(this.route.snapshot.params['idof']);
      var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idof']);
      if (rowOFs.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
      }
      // CLIENTE
      /**/this.idcliente = Number.parseInt(this.route.snapshot.params['idcliente']);
      // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
      var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idcliente']);
      if (rowClientes.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
      }
      // PIEZA
      /**/this.idpieza = Number.parseInt(this.route.snapshot.params['idpieza']);
      var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idpieza']);
      if (rowPiezas.length > 0) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
      }
      // TERMINADO 
      // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
      // * solo lo voy a filtrar si es 1
      /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
      if (this.route.snapshot.params['terminados'] == '1') {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable: false,
          tipoNoVisible: false,
          fueraParametro: false,
          conEdicionReceta: false,
          conFalloCalidad: false,
          conControlCalidad: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string' },
          operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          tipoNoEditable: false,
          tipoNoVisible: false, 
          fueraParametro: false,
          conEdicionReceta: false,
          conFalloCalidad: false,
          conControlCalidad: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  // END FILTRO

}
