<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card w-50">
    <div class="card-header">
      <h3 *ngIf="isAddMode">{{ 'creartipopieza' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'editartipopieza' | translate}}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">


      <!--NOMBRE-->
      <div class="form-group">
        <kendo-label text="{{ 'nombre' | translate}}">
          <input kendoTextBox formControlName="nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
          </div>
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.maxlength">{{ 'nombre200caracteres' | translate}}</div>
          </div>
        </kendo-label>
      </div>

      <!--DUREZA-->
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <kendo-label text="{{ 'tipo_material' | translate}}">
              <kendo-combobox [data]="tipoMaterialLista" [textField]="'nombre'" class="form-control" [valueField]="'id'" formControlName="tipo_material"
                              [ngClass]="{ 'is-invalid': submitted && f.tipo_material.errors }" [kendoDropDownFilter]="{operator: 'contains'}"></kendo-combobox>
              <div *ngIf="submitted && f.tipo_material.errors" class="invalid-feedback">
                <div *ngIf="f.tipo_material.errors.required">{{ 'tipomaterialrequerido' | translate}}</div>
              </div>
            </kendo-label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary" [disabled]="this.user.piezasTipo < 2">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          {{ 'guardar' | translate}}
        </button>
        <a routerLink="/piezastipo" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
      </div>

    </div>
  </div>
</form>
