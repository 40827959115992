import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/fabricantes`;

@Injectable()
export class FabricantesService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  
  public get_COMBO(): Observable<any> {
    return this.http.get(baseUrl + "/get_COMBO");
  }


  public GetFabricantesConImagen() {
    return this.http.post<JSON>(`${baseUrl}/GetFabricantesConImagen/`, {}, { withCredentials: true });
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/fabricantes");
  }

  insert(data, logo, archivo) {
    return this.http.post<any>(baseUrl + "/crear", { id: -1, nombre: data.nombre, logo: logo, archivo: archivo, descripcion: data.descripcion }, { withCredentials: true });
  }

  update(data, logo, imagen) {
    return this.http.post<any>(baseUrl + "/editar", { id: data.id, nombre: data.nombre, logo: logo, archivo: imagen, descripcion: data.descripcion }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);
    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  public download(idMarca): Observable<any> {
    return this.http.get(baseUrl + "/logo/download/" + idMarca);
  }

}
