import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-consumibleTipo',
  templateUrl: 'consumibleTipo.component.html'
})

export class ConsumibleTipoComponent {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //VARIABLES DB
  public id: number = 0;
  public nombre: string = '';
  public descripcion: string = '';
  public solicitarVidaUtil: boolean = false;
  public solicitarDiametro: boolean = false;
  public solicitarLongitud: boolean = false;
  public solicitarAngulo: boolean = false;
  public solicitarNumContenido: boolean = false;
  public solicitarSalto: boolean = false;
  public solicitarIdErp: boolean = false;
  public solicitarCoste: boolean = false;
  public solicitarVc: boolean = false;
  public solicitarF: boolean = false;
  public idHijos: any[] = [];
  public consumiblesTipos: any;

  //REQUIERED
  public requiereNombre: boolean = false;

  //POPUP: error al guardar
  public popupErrorAlGuardar: boolean; false;
  user = this.userService.userValue;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService,
    public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    private userService: UsuariosService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('consumibleTipo').toUpperCase();
  }

  ngOnInit() {
    this.cargarCombos();
  }

  cargarCombos() {
    this.id = this.route.snapshot.params['id'];
    this.loadingPanel = true;
    this.consumiblesService.Get_consumiblesTipos().subscribe(
      (json) => {
        this.consumiblesTipos = json;
        this.idHijos = [];
        this.cargarDatos();
      });
  }

  cargarDatos() {
    this.loadingPanel = true;
    if (this.id > 0) {
      var r1, r2: boolean = false;
      this.consumiblesService.Get_consumiblesTipos_byID(this.id).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            this.nombre = json[0].nombre;
            this.descripcion = json[0].descripcion;
            this.solicitarVidaUtil = json[0].solicitar_vidaUtil;
            this.solicitarDiametro = json[0].solicitar_diametro;
            this.solicitarLongitud = json[0].solicitar_longitud;
            this.solicitarAngulo = json[0].solicitar_angulo;
            this.solicitarNumContenido = json[0].solicitar_numContenido;
            this.solicitarSalto = json[0].solicitar_salto;
            this.solicitarIdErp = json[0].solicitar_idERP;
            this.solicitarCoste = json[0].solicitar_coste;
            this.solicitarVc = json[0].solicitar_vc;
            this.solicitarF = json[0].solicitar_f;
            console.log(this.solicitarCoste);
            console.log(this.solicitarVc);
          }

          r1 = true;
          if (r1 && r2)
            this.loadingPanel = false;
        });

      this.consumiblesService.Get_HIJOS_consumiblesTipos_byID(this.id).subscribe(
        (json) => {
          var an: any = json;
          var idHijos: any = [];
          an.forEach(
            (row) => {
              var a = this.consumiblesTipos.filter(f => f.id === row.idHijo);
              if (a.length > 0)
                idHijos.push(a[0]);
            });
          this.idHijos = idHijos;

          r2 = true;
          if (r1 && r2)
            this.loadingPanel = false;
        });
    }
    else {
      this.loadingPanel = false;
    }
  }

  //BOTONES
  guardar() {
    this.requiereNombre = false;

    if (this.nombre == '') {
      this.requiereNombre = true;
    }

    if (!this.requiereNombre) {
      var an: any = this.idHijos;
      var idHijos: any = [];
      an.forEach(
        (row) => {
          idHijos.push(row.id);
        });
      this.consumiblesService.Guardar_consumibleTipo(this.id,
        this.nombre, this.descripcion,
        this.solicitarVidaUtil, this.solicitarDiametro, this.solicitarLongitud, this.solicitarAngulo,
        this.solicitarNumContenido, this.solicitarSalto, this.solicitarIdErp, this.solicitarCoste, this.solicitarVc, this.solicitarF, idHijos).subscribe(
          (json) => {
            var an: any = json;
            if (an > 0) {
              this.router.navigate(['consumiblesTipos']);
            }
            else {
              this.popupErrorAlGuardar = true;
            }
          });
    }
  }

  cancelar() {
    this.router.navigate(['consumiblesTipos']);
  }


  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
