import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/clasificaciones`;

@Injectable()
export class ClasificacionesService {

  constructor(private http: HttpClient) { }

  public GetAll() {
    return this.http.post<JSON>(`${baseUrl}/get_clasificaciones`, {}, { withCredentials: true });
  }
  public InsertClasificacion(nombre: string, idPadre: number) {
    return this.http.post<JSON>(`${baseUrl}/insert_clasificacion`, { nombre, idPadre }, { withCredentials: true });
  }
  public UpdateClasificacion(idClasificacion: number, nombre: string, idPadre: number) {
    return this.http.post<JSON>(`${baseUrl}/update_clasificacion`, { idClasificacion, nombre, idPadre }, { withCredentials: true });
  }
  public GetClasificacionByID(idClasificacion: number) {
    return this.http.post<JSON>(`${baseUrl}/get_clasificacion_by_id`, { idClasificacion }, { withCredentials: true });
  }
  public EliminarClasificaciones(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/eliminar_clasificaciones`, { ids }, { withCredentials: true });
  }
  public GetMaquinasClasificacionById(idMaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/getMaquinasClasificacionById`, { idMaquina }, { withCredentials: true });
  }
  public GetMaquinasClasificaciones() {
    return this.http.post<JSON>(`${baseUrl}/getMaquinasClasificaciones`, { }, { withCredentials: true });
  }

}
