import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { MarcasService } from '@app/_services/marcas.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';

@Component({
  selector: 'app-marcas',
  templateUrl: 'marcas.component.html'
})

export class MarcasComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public marcas: [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.usuariosService.userValue;

  constructor(private marcasService: MarcasService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('marcas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/marcas') {
          this.menuService.titulo = this.translateService.instant('marcas');
          this.marcasService.GetAll().subscribe((result) => {
            this.marcas = result.data;
          }
          );
        } else {
         
        }
      }
    });
  }

  ngOnInit() {
    this.marcasService.GetAll().subscribe((result) => {
      this.marcas = result.data;
      }
    );
  }

  onClickNuevo() {
    this.router.navigate(['marcas/crear/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['marcas/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['marcas/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading, contentNotErased) {
   
    this.marcasService.delete({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.marcasService.GetAll().subscribe((result) => {
            this.marcas = result.data;
          }
          );
        }
        if (data.undeletedElements != "") {
          
          this.modalService.open(contentNotErased, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }
}
