import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { MaquinasService } from '@app/_services/maquinas.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, AlertService, UsuariosService, ClasificacionesService } from '../_services';
import { Maquina } from '@app/_models';

@Component({
  selector: 'app-maquinas',
  templateUrl: 'maquinas.component.html'
})

export class MaquinasComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public maquinas: any = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;

  public maquinasTipos_modalReference: NgbModalRef;
  public maquinasTipos: any;
  public selectedMaquinasTipos: any;

  public user = this.userService.userValue;
  public timeZonesList: any = [];
  public secciones: any;

  constructor(private maquinasService: MaquinasService,
    translate: TranslateService,
    private alertService: AlertService,
    public router: Router,
    private menuService: MenuService,
    public clasificacionesService: ClasificacionesService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('maquinas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/maquinas') {
          this.cargarDatos();
        } else {
        }
      }
    });

  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {

    this.maquinasService.GetAll().subscribe((result) => {
      this.maquinas = result.data;

      this.clasificacionesService.GetMaquinasClasificaciones().subscribe(json => {
        var clasificaciones: any = json;
        this.maquinas.forEach(function (m) {
          m.tipo_maquina_nombre = this.translate.instant(m.tipo_maquina_nombre);
          var auxClasificaciones = clasificaciones.filter(f => f.idMaquina == m.id);
          auxClasificaciones = auxClasificaciones.map(f => f.nombre).join(",");
          m.clasificacion_nombre = auxClasificaciones;
        }, this);
      })

    });

    this.maquinasService.GetTiposMaquina().subscribe((result) => {
      this.maquinasTipos = result;
      this.maquinasTipos.forEach(function (mt) {
        mt.nombre = this.translate.instant(mt.nombre);
      }, this);
    });

    var timezones_model = this.userService.get_timezones_model();
    if (timezones_model == false) {
      this.userService.getTimezones().subscribe((result) => {
        this.timeZonesList = result;
      });
    } else {
      this.timeZonesList = timezones_model;
    }
    this.userService.getComboSecciones().subscribe(json => {
      this.secciones = json;
      var an: any = this.secciones;
    });

  }

  onClickNuevo(content) {
    this.maquinasTipos_modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  crearMaquina() {

    if (this.selectedMaquinasTipos == undefined) { } //NINGUNA SELECCIONADA

    else if (this.selectedMaquinasTipos.id == 1) { //MECANIZADO
      this.router.navigate(['maquinas/crear/']);
      this.maquinasTipos_modalReference.close();
    }

    else if (this.selectedMaquinasTipos.id == 2) { //EXTRUSORA
      this.router.navigate(['extrusoras/crear/']);
      this.maquinasTipos_modalReference.close();
    }

    else if (this.selectedMaquinasTipos.id == 3) { //IMPRESORA PLASTICO
      this.router.navigate(['impresorasPlastico/crear/']);
      this.maquinasTipos_modalReference.close();
    }

    else if (this.selectedMaquinasTipos.id == 4) { //INYECTORA
      this.router.navigate(['inyectoras/crear/']);
      this.maquinasTipos_modalReference.close();
    }

    else if (this.selectedMaquinasTipos.id == 5) { //HORNO
      this.router.navigate(['hornos/crear/']);
      this.maquinasTipos_modalReference.close();
    }

    else if (this.selectedMaquinasTipos.id == 9) { //ADITIVO
      this.router.navigate(['aditivo/crear/']);
      this.maquinasTipos_modalReference.close();
    }

    else if (this.selectedMaquinasTipos.id == 12) { //HORNOS TEMPLE
      this.router.navigate(['hornosTemple/crear/']);
      this.maquinasTipos_modalReference.close();
    }

  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {

      var idMaq = this.seleccionados[0];
      var maq = this.maquinas.find(x => x.id === idMaq);

      if (maq.tipo_maquina == -1) this.router.navigate(['maquinas/editar/' + idMaq]); //NO TIENE TIPO DE MAQUINA, CREAR MECANIZADO POR DEFECTO

      if (maq.tipo_maquina == 1) this.router.navigate(['maquinas/editar/' + idMaq]); //MECANIZADO

      if (maq.tipo_maquina == 2) this.router.navigate(['extrusoras/editar/' + idMaq]); //EXTRUSORA

      if (maq.tipo_maquina == 3) this.router.navigate(['impresorasPlastico/editar/' + idMaq]); //IMPRESORA PLASTICO

      if (maq.tipo_maquina == 4) this.router.navigate(['inyectoras/editar/' + idMaq]); //INYECTORA

      if (maq.tipo_maquina == 5) this.router.navigate(['hornos/editar/' + idMaq]); //HORNO

      if (maq.tipo_maquina == 9) this.router.navigate(['aditivo/editar/' + idMaq]); //ADITIVO

      if (maq.tipo_maquina == 12) this.router.navigate(['hornosTemple/editar/' + idMaq]); //HORNOS TEMPLE

    }
  }

  onClickCopiar() {
    if (this.seleccionados[0] > 0) {
      var idMaq = this.seleccionados[0];
      var maq = this.maquinas.find(x => x.id === idMaq);

      if (maq.tipo_maquina == -1) this.router.navigate(['maquinas/copiar/' + idMaq]); //NO TIENE TIPO DE MAQUINA, CREAR MECANIZADO POR DEFECTO

      if (maq.tipo_maquina == 1) this.router.navigate(['maquinas/copiar/' + idMaq]); //MECANIZADO
      // var maquina = new Maquina();
      // var newMaquina: any = {};
      // var maquinaSelected = this.maquinas.filter(m => m.id == this.seleccionados[0]);
      // this.maquinasService.GetById(this.seleccionados[0]).pipe().subscribe((result)=>{
      //   console.log("Resultado: ");
      //   console.log(result.data[0]);
      //   debugger
      //   newMaquina = result.data[0];
      //   newMaquina.id = 0;
      //   newMaquina.tipo_maquina = maquinaSelected[0].tipo_maquina;
      //   newMaquina.idSeccion = this.secciones.find(x => x.id == result.data[0].idSeccion);
      //   newMaquina.idTimezone = this.timeZonesList.find(x => x.id == result.data[0].idTimezone)
      //   newMaquina.maxHerramientas = result.data[0].maximoHerramientas;
      //   newMaquina.visible1 = result.data[0].potenVisible;
      //   newMaquina.visible2 = result.data[0].poten2Visible;
      //   newMaquina.urlCamara = result.data[0].linkCamara;
      //   newMaquina.maquinaPuestoOffline = result.data[0].puestoOffline;
      //   newMaquina.maximaDelPotenciometro = result.data[0].potenMax;
      //   newMaquina.maximaDelPotenciometro2 = result.data[0].potenMax2;
      //   newMaquina.tiempoSincrSeg = result.data[0].tiempoRecogidaDatos;
      //   //insert(data, idMarca, idControl, idProtocoloSeleccionado, dibujoMaquinaId)
      //   this.maquinasService.insert(newMaquina,newMaquina.idmarca,newMaquina.idcontrol,newMaquina.idprotocolo,newMaquina.idDibujoMaquina).subscribe(res => {
      //     console.log("Respuesta del insert");
      //     console.log(res);
      //     if(!res.error){
      //       this.maquinasService.GetEjesDeMaquina(this.seleccionados[0].toString()).subscribe(res2 => {
      //         var listaEjes = res2;
      //         console.log("Ejes");
      //         console.log(listaEjes);
              
      //         if(listaEjes.data.length > 0){
      //           listaEjes.data.forEach(eje => {
      //             var i=1;
      //             var newEje = eje;
      //             this.maquinasService.Insert_Eje(eje,res.id).subscribe(res3 => {
      //               console.log("Insertado: ",res3);
      //               if(!res3.error){
      //                 for(i;i<4;i++){
      //                   this.maquinasService.Get_Variables_Eje(eje.id, i).subscribe(er => {
      //                     var newVarEje = er.data;
      //                     newVarEje.idEje = res3.id;
      //                     this.maquinasService.Insert_Variables_Eje(res3.id,i,er.data.minimo,er.data.cambioColor1,er.data.cambioColor2,
      //                       er.data.cambioColor3,er.data.cambioColor4,er.data.maximo,er.data.visible,er.data.activo).subscribe(vari => {
      //                       console.log("Var"+vari);
      //                     })
      //                   })
      //                 }
      //               }
      //             })
      //           });
      //         }
      //       })
      //     }
          
      //   })

       
        
        
      // })

      
      //Get ejes 
      // this.maquinasService.GetAll_Ejes(this.id).subscribe((result) => {
      //   this.ejes = result.data;
      // });
      // console.log("Maquina seleccionada: ");
      // console.log(maquinaSelected);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {

      var idMaq = this.seleccionados[0];
      var maq = this.maquinas.find(x => x.id === idMaq);

      if (maq.tipo_maquina == -1) this.router.navigate(['maquinas/editar/' + idMaq]); //NO TIENE TIPO DE MAQUINA, CREAR MECANIZADO POR DEFECTO

      if (maq.tipo_maquina == 1) this.router.navigate(['maquinas/editar/' + idMaq]); //MECANIZADO

      if (maq.tipo_maquina == 2) this.router.navigate(['extrusoras/editar/' + idMaq]); //EXTRUSORA

      if (maq.tipo_maquina == 3) this.router.navigate(['impresorasPlastico/editar/' + idMaq]); //IMPRESORA PLASTICO

      if (maq.tipo_maquina == 4) this.router.navigate(['inyectoras/editar/' + idMaq]); //INYECTORA

      if (maq.tipo_maquina == 5) this.router.navigate(['hornos/editar/' + idMaq]); //HORNO

      if (maq.tipo_maquina == 9) this.router.navigate(['aditivo/editar/' + idMaq]); //ADITIVO

      if (maq.tipo_maquina == 12) this.router.navigate(['hornosTemple/editar/' + idMaq]); //HORNO TEMPLE
      
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.maquinasService.delete({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.maquinasService.GetAll().subscribe((result) => {
            this.maquinas = result.data;
          });
        } else {
          this.alertService.error(this.translate.instant('error'), { keepAfterRouteChange: true });
        }
        this.modalReferenceloading.close();
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
