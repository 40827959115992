
<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<!-- GRID -->
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <kendo-grid [kendoGridBinding]="consumibles"
              [sortable]="true"
              [navigable]="true"
              filterable="menu"
              [rowHeight]="36"
              [height]="750"
              [pageSize]="30"
              kendoGridSelectBy="id"
              scrollable="virtual"
              [selectedKeys]="seleccionados"
              (cellClick)="cellClick($event)">
    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">
      <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
      <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
      <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.consumibles<2">{{ 'eliminar' | translate}}</button>
    </ng-template>
    <!--checkbox-->
    <kendo-grid-checkbox-column width="5%">
      <ng-template kendoGridHeaderTemplate>
        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
               [state]="selectAllState">
        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
      </ng-template>
    </kendo-grid-checkbox-column>
    <!--referencia-->
    <kendo-grid-column width="10%"[style]="{'text-align': 'left'}" field="referencia" title="{{ 'referencia' | translate}}"></kendo-grid-column>
    <!--nombre-->
    <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
    <!--tipo-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="tipo" title="{{ 'tipo' | translate}}"></kendo-grid-column>
    <!--fabricante-->
    <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="fabricante" title="{{ 'fabricante' | translate}}"></kendo-grid-column>
    <!--vida-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="vidaUtil" title="{{ 'vidaUtil' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>
        <ngcontainer> {{ secondsToHms(dataItem.vidaUtil) }}</ngcontainer>
      </ng-template>
    </kendo-grid-column>
    <!--diametro-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="diametro" title="{{ 'diametro' | translate}}"></kendo-grid-column>
    <!--longitud-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="longitud" title="{{ 'longitud' | translate}}"></kendo-grid-column>
    <!--Angulo-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="angulo" title="{{ 'angulo' | translate}}"></kendo-grid-column>
    <!--nfiltros-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="numContenido" title="{{ 'capacidad' | translate}}"></kendo-grid-column>
    <!--salto-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="salto" title="{{ 'saltos' | translate}}"></kendo-grid-column>
    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>


<!-- POPUP: Eliminar -->
<div class="popup-cont" *ngIf="popupEliminar">
  <div class="popup" style="width: 300px;">
    <div class="popup-header">
      <h3>
        <label>{{'eliminar' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'preguntaeliminarpopup' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-danger mr-1" (click)="this.popupEliminar = false;">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="eliminarRegistro()" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>

<!-- POPUP: ERROR Eliminar -->
<div class="popup-cont" *ngIf="popupErrorAlEliminar">
  <div class="popup" style="width: 400px;">
    <div class="popup-header">
      <h3>
        <label>{{'error' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div>
        <label class="crontol-label m-1">{{ 'errorAlEliminar' | translate }}</label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex  justify-content-end mt-2">
      <button type="button" class="btn btn-primary" (click)="this.popupErrorAlEliminar = false;" [disabled]="this.user.consumibles<2">{{ 'aceptar' | translate }}</button>
    </div>
  </div>
</div>
