<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card w-50">
    <div class="card-header">
      <h3 *ngIf="isAddMode">{{ 'creartipoalarma' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'editartipoalarma' | translate}}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">


      <!--NOMBRE-->
      <div class="form-group">
        <kendo-label text="{{ 'nombre' | translate}}">
          <input kendoTextBox formControlName="nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
          </div>
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.maxlength">{{ 'nombre200caracteres' | translate}}</div>
          </div>
        </kendo-label>
      </div>

      <div class="form-group">
        <button [disabled]="loading || this.user.alarmasTipo < 2 " class="btn mr-1  btn-primary"> <!--TODO MARI: honek errorea ematen du, zergatik??? || this.user.alarmasTipo<2-->
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1" ></span>
          {{ 'guardar' | translate}}
        </button>
        <a routerLink="/alarmastipo" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
      </div>

    </div>
  </div>
</form>
