import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/activos`;

@Injectable()
export class ActivosService {

  constructor(private http: HttpClient) {  }

  //Tipos activo
  public getAllTipos(): Observable<any> {
    return this.http.get(baseUrl + "/tipos");
  }

  GetTiposById(id): Observable<any> {
    return this.http.get(`${baseUrl}/tipos_byId/${id}`, { withCredentials: true });
  }

  Insert_tipo(nombre: string, descripcion: string, activo: boolean) {
    return this.http.post<JSON>(`${baseUrl}/insert_tipo`, { nombre: nombre, descripcion: descripcion,
    activo: activo }, { withCredentials: true });
  }
  Update_tipo(idTipoActivo: number, nombre: string, descripcion: string, activo: boolean) {
    return this.http.post<JSON>(`${baseUrl}/update_tipo`, { idTipoActivo: idTipoActivo, nombre: nombre, descripcion: descripcion,
      activo: activo }, { withCredentials: true });
  }

  Delete_tipos(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/delete_tipos`, { ids }, { withCredentials: true });
  }

  //Activos Maestros
  public getAllActivosMaestros(): Observable<any> {
    return this.http.get(baseUrl + "/activosMaestros");
  }

  GetActivoMaestroById(id): Observable<any> {
    return this.http.get(`${baseUrl}/activoMaestro_byId/${id}`, { withCredentials: true });
  }

  InsertActivoMaestro(nombre, referencia, idTipoJerarquia, vidaUtil, stock, idActivoTipo, codigoEAN, nombreArchivo, archivoBase64, idIsometrico, observaciones) {
    return this.http.post<JSON>(`${baseUrl}/insert_activoMaestro`, { nombre: nombre, referencia: referencia, idTipoJerarquia: idTipoJerarquia,
    vidaUtil: vidaUtil, stock: stock, idActivoTipo: idActivoTipo, codigoEAN: codigoEAN, nombreArchivo: nombreArchivo, archivoBase64: archivoBase64, idIsometrico: idIsometrico, observaciones: observaciones }, { withCredentials: true });
  }
  UpdateActivoMaestro(idTipoActivo: number, nombre, referencia, idTipoJerarquia, vidaUtil, stock, idActivoTipo, codigoEAN, nombreArchivo, archivoBase64, idIsometrico, observaciones) {
    return this.http.post<JSON>(`${baseUrl}/update_activoMaestro`, { idActivoMaestro: idTipoActivo, nombre: nombre, referencia: referencia, idTipoJerarquia: idTipoJerarquia,
      vidaUtil: vidaUtil, stock: stock, idActivoTipo: idActivoTipo, codigoEAN: codigoEAN, nombreArchivo: nombreArchivo, archivoBase64: archivoBase64, idIsometrico: idIsometrico, observaciones: observaciones }, { withCredentials: true });
  }

  DeleteActivosMaestros(ids: string, images) {
    return this.http.post<JSON>(`${baseUrl}/delete_activosMaestros`, { ids, images }, { withCredentials: true });
  }

  GetAllReferenciasCodigoBarras(): Observable<any> {
    return this.http.get(`${baseUrl}/GetAllReferenciasCodigoBarras`, { withCredentials: true });
  }

  //Maestros asociados
  getMaestrosArbolInferior(id): Observable<any> { //idPadre
    return this.http.get(`${baseUrl}/getMaestrosArbolInferior/${id}`, { withCredentials: true });
  }
  getActivosMaestrosAsociados(id): Observable<any> { //idActivoComoHijo
    return this.http.get(`${baseUrl}/getActivosMaestrosAsociados/${id}`, { withCredentials: true });
  }

  getArbolCompleto(): Observable<any> {
    return this.http.get(`${baseUrl}/getArbolCompleto`, { withCredentials: true });
  }

  getArbolFiltrado(idsJerarquias, idsTipos, referencias, codigosBarras, stock) {
    return this.http.post<JSON>(`${baseUrl}/getArbolFiltrado`, { idsJerarquias: idsJerarquias, idsTipos: idsTipos, referencias: referencias,
    codigosBarras: codigosBarras, stock: stock }, { withCredentials: true });
  }

  getArbolParcial(id): Observable<any> { //idActivoComoHijo
    return this.http.get(`${baseUrl}/getArbolParcial/${id}`, { withCredentials: true });
  }

  insertRelaciones(ids: string, idActivo) {
    return this.http.post<JSON>(`${baseUrl}/insertRelaciones`, { ids: ids, idActivo: idActivo }, { withCredentials: true });
  }

  deleteRelaciones(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/deleteRelaciones`, { ids }, { withCredentials: true });
  }

  //Maestros proveedores
  getActivosMaestrosProveedores(id): Observable<any> { //idActivoComoHijo
    return this.http.get(`${baseUrl}/getActivosMaestrosProveedores/${id}`, { withCredentials: true });
  }

  GetProveedoresByIdActivoIdProveedor(idActivoMaestro, idActivoProveedor) {
    return this.http.post<JSON>(`${baseUrl}/GetProveedoresByIdActivoIdProveedor`, { idActivoMaestro: idActivoMaestro, idActivoProveedor: idActivoProveedor }, { withCredentials: true });
  }

  insertProveedorActivoMaestro(idActivoMaestro, idActivoProveedor, precio, plazoEntrega, garantia,observaciones) {
    return this.http.post<JSON>(`${baseUrl}/insertProveedorActivoMaestro`, { idActivoMaestro: idActivoMaestro, idActivoProveedor: idActivoProveedor, precio: precio,
      plazoEntrega: plazoEntrega, garantia: garantia, observaciones: observaciones }, { withCredentials: true });
  }
  updateProveedorActivoMaestro(idActivoMaestro, idActivoProveedor, precio, plazoEntrega, garantia,observaciones) {
    return this.http.post<JSON>(`${baseUrl}/updateProveedorActivoMaestro`, { idActivoMaestro: idActivoMaestro, idActivoProveedor: idActivoProveedor, precio: precio,
      plazoEntrega: plazoEntrega, garantia: garantia, observaciones: observaciones }, { withCredentials: true });
  }

  deleteProveedoresActivoMaestro(ids: string, idActivo) {
    return this.http.post<JSON>(`${baseUrl}/deleteProveedoresActivoMaestro`, { ids: ids, idActivo: idActivo }, { withCredentials: true });
  }

  //Jerarquia
  public getJerarquia(): Observable<any> {
    return this.http.get(baseUrl + "/jerarquia");
  }

  //gestion documental
  crearDocumento(idActivo, nombre, ficheroLink, esLink) {
    return this.http.post<JSON>(`${baseUrl}/documento/crear`, { idActivo: idActivo, nombre: nombre,
      ficheroLink: ficheroLink, esLink: esLink }, { withCredentials: true });
  }

  editarDocumento(antesFichero, ficheroViejo, idDocumento, idActivo, nombre, ficheroLink, esLink) {
    return this.http.post<JSON>(`${baseUrl}/documento/editar`, { antesFichero: antesFichero, ficheroViejo: ficheroViejo, id: idDocumento, idActivo: idActivo, nombre: nombre,
      ficheroLink: ficheroLink, esLink: esLink }, { withCredentials: true });
  }

  deleteDocumentos(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/deleteDocumentos`, { ids: ids }, { withCredentials: true });
  }

  getAllDocumentos(idActivo) {
    return this.http.get(`${baseUrl}/getAllDocumentos/${idActivo}`, { withCredentials: true });
  }
  getDocumentoById(idDocumento) {
    return this.http.get(`${baseUrl}/getDocumentoById/${idDocumento}`, { withCredentials: true });
  }



  //////////activos (no maestros)
  getAllActivos() {
    return this.http.get(`${baseUrl}/getAllActivos`, { withCredentials: true });
  }

  getActivoById(id) {
    return this.http.get(`${baseUrl}/getActivo/${id}`, { withCredentials: true });
  }

  deleteActivos(ids: string, images, idsActivos) {
    return this.http.post<JSON>(`${baseUrl}/delete_activos`, { ids: ids, images: images, idsActivos: idsActivos }, { withCredentials: true });
  }

  insertActivo(nombre, referencia, numeroSerie, descripcion, nombreArchivo, idIsometrico, idTipo, idJerarquia, vidaUtil, 
    stock, codigoBarras, fechaCompra, fechaPuestoEnServicio, 
    baja, fechaBaja, idSeccion,
    idProveedor, precio, garantia, idGrupo, archivoBase64) {
    return this.http.post<JSON>(`${baseUrl}/insert_activo`, { nombre: nombre, referencia: referencia, numeroSerie: numeroSerie, descripcion: descripcion, 
      nombreArchivo: nombreArchivo, idIsometrico: idIsometrico, idTipo: idTipo, idJerarquia: idJerarquia, vidaUtil: vidaUtil, stock: stock,
      codigoBarras: codigoBarras, fechaCompra: fechaCompra, fechaPuestoEnServicio: fechaPuestoEnServicio, baja: baja, fechaBaja: fechaBaja,
      idSeccion: idSeccion, idProveedor: idProveedor, precio: precio, garantia: garantia,  idGrupo: idGrupo, archivoBase64: archivoBase64
     }, { withCredentials: true });
  }
  updateActivo(id, nombre, referencia, numeroSerie, descripcion, nombreArchivo, idIsometrico, idTipo, idJerarquia, vidaUtil, 
    stock, codigoBarras, fechaCompra, fechaPuestoEnServicio, 
    baja, fechaBaja, idSeccion,
    idProveedor, precio, garantia, idGrupo, archivoBase64) {
    return this.http.post<JSON>(`${baseUrl}/update_activo`, { idActivo: id, nombre: nombre, referencia: referencia, numeroSerie: numeroSerie, descripcion: descripcion, 
      nombreArchivo: nombreArchivo, idIsometrico: idIsometrico, idTipo: idTipo, idJerarquia: idJerarquia, vidaUtil: vidaUtil, stock: stock,
      codigoBarras: codigoBarras, fechaCompra: fechaCompra, fechaPuestoEnServicio: fechaPuestoEnServicio, baja: baja, fechaBaja: fechaBaja,
      idSeccion: idSeccion, idProveedor: idProveedor, precio: precio, garantia: garantia,  idGrupo: idGrupo, archivoBase64: archivoBase64
     }, { withCredentials: true });
  }

   //gestion documental
   crearDocumentoActivo(idActivo, nombre, ficheroLink, esLink) {
    return this.http.post<JSON>(`${baseUrl}/documentoActivo/crear`, { idActivo: idActivo, nombre: nombre,
      ficheroLink: ficheroLink, esLink: esLink }, { withCredentials: true });
  }

  editarDocumentoActivo(antesFichero, ficheroViejo, idDocumento, idActivo, nombre, ficheroLink, esLink) {
    return this.http.post<JSON>(`${baseUrl}/documentoActivo/editar`, { antesFichero: antesFichero, ficheroViejo: ficheroViejo, id: idDocumento, idActivo: idActivo, nombre: nombre,
      ficheroLink: ficheroLink, esLink: esLink }, { withCredentials: true });
  }

  deleteDocumentosActivos(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/deleteDocumentosActivos`, { ids: ids }, { withCredentials: true });
  }

  getAllDocumentosActivos(idActivo) {
    return this.http.get(`${baseUrl}/getAllDocumentosActivos/${idActivo}`, { withCredentials: true });
  }
  getDocumentoActivoById(idDocumento) {
    return this.http.get(`${baseUrl}/getDocumentoActivoById/${idDocumento}`, { withCredentials: true });
  }

  //updates
  crearUpdate(idActivo, nombre, descripcion, fecha) {
    return this.http.post<JSON>(`${baseUrl}/Insert_Update`, { idActivo: idActivo, nombre: nombre,
      descripcion: descripcion, fecha: fecha }, { withCredentials: true });
  }

  editarUpdate(idUpdate, idActivo, nombre, descripcion, fecha) {
    return this.http.post<JSON>(`${baseUrl}/Editar_Update`, { id: idUpdate, idActivo: idActivo, nombre: nombre,
      descripcion: descripcion, fecha: fecha }, { withCredentials: true });
  }

  deleteUpdates(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/deleteUpdates`, { ids: ids }, { withCredentials: true });
  }

  getAllUpdates(idUpdate) {
    return this.http.get(`${baseUrl}/getAllUpdates/${idUpdate}`, { withCredentials: true });
  }
  getUpdateById(idDocumento) {
    return this.http.get(`${baseUrl}/getUpdateById/${idDocumento}`, { withCredentials: true });
  }
}
