import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/seguimientoOFs`;

@Injectable()
export class SeguimientoOFsService {

    public seguimientos: Array<any>;

    constructor(private http: HttpClient) { }

    /* PRUEBA INFORMES */
    get_seguimientos(): Array<any> {
        return this.seguimientos;
    }
    set_seguimientos(seguimientoNuevo) {
        this.seguimientos = seguimientoNuevo;
    }

    GetSeguimiento() {
        return this.http.post<JSON>(`${baseUrl}/Get_Seguimiento`, { withCredentials: true })
    }
    GetSeguimiento_byId(id: number) {
        return this.http.post<JSON>(`${baseUrl}/Get_Seguimiento_byId`, { id }, { withCredentials: true })
    }
    GetSeguimiento_Linea(id: number) {
        return this.http.post<JSON>(`${baseUrl}/Get_Seguimiento_Linea`, { id }, { withCredentials: true })
    }
    GetSeguimiento_Columna(id: number) {
        return this.http.post<JSON>(`${baseUrl}/Get_Seguimiento_Columna`, { id }, { withCredentials: true })
    }
    GetInformeSeguimiento() {
        return this.http.post<JSON>(`${baseUrl}/Get_InformeSeguimiento`, { withCredentials: true })
    }
    Add_Seguimiento(nombre: string) {
        return this.http.post<JSON>(`${baseUrl}/Add_Seguimiento`, { nombre }, { withCredentials: true })
    }
    Add_Seguimiento_Linea(idSeguimiento: number, nombre: string, oculto: boolean, orden: number) {
        return this.http.post<JSON>(`${baseUrl}/Add_Seguimiento_Linea`, { idSeguimiento, nombre, oculto, orden }, { withCredentials: true })
    }
    Add_Seguimiento_Linea_Columna(idLinea: number, nombreColumna: string, vinculado: boolean, resto: boolean, corto: boolean, largo: boolean, semana: number, piezasMin: number, piezasMax: number, piezasOptimas: number, listaMaquinasOrigen: string, listaMaquinasDestino: string, orden: number, manual: boolean, listaMaquinasActual: string) {
        return this.http.post<JSON>(`${baseUrl}/Add_Seguimiento_Linea_Columna`, { idLinea, nombreColumna, vinculado, resto, corto, largo, semana, piezasMin, piezasMax, piezasOptimas, listaMaquinasOrigen, listaMaquinasDestino, orden, manual, listaMaquinasActual }, { withCredentials: true })
    }
    Update_Seguimiento(id:number, nombre: string){
        return this.http.post<JSON>(`${baseUrl}/Update_Seguimiento`, { id, nombre }, { withCredentials: true })
    }
    Delete_Seguimiento(id:number){
        return this.http.post<JSON>(`${baseUrl}/Delete_Seguimiento`, { id }, { withCredentials: true })
    }
    Duplicar_Seguimiento(id:number, nombre: string){
        return this.http.post<JSON>(`${baseUrl}/Duplicar_Seguimiento`, { id, nombre }, { withCredentials: true })
    }

}