<div class="row">

  <!--PANEL PRINCIPAL SI NO TIENE PROCESOS-->
  <div class="col-md-3" style="height: 350px;" *ngIf="!tieneHistorico_Procesos">
    <div class="panel-procesos" style="margin-left: 7px;">
      <div id="pestanaColor" class="{{ pestannaColor }}"></div>
      <div class="titulo-panel-procesos" style="margin-left: 10px;">
        <label class="titulo-panel-procesos-texto">{{ instalacion.nombre }}</label>
      </div>
      <div class="contenido-panel-procesos">

        <div style="width: 92%;">
          <div ID="maquina" class="maquina">
            <div id="estado">
              <div class="fotomaquina pqn">
                <img ID="fotomaquina" src="{{ instalacion.imagenBase64 }}" />
              </div>
              <div class="fotomarca">
                <img id="fotomarca" src="" />
              </div>
            </div>
          </div>
        </div>

        <div class="inner-panel-der d-none">

          <!--Kg/h-->
          <div class="row panel-mmin">
            <label class="label-mmin">0</label><label class="label-mmin-unidad"></label>
          </div>

          <!--RENDIMIENTO SOPLADORES-->
          <div class="row fila-panel-ext">
            <div class="col-12">
              <label class="panel-ext-label-title">{{ 'rendimientoSopladores' | translate }}</label>
            </div>
            <div class="col-12" style="margin-top: -5px;">
              <label class="panel-ext-label-text">-</label>
            </div>
          </div>

          <!--INFO CONTROL ESPESORES-->
          <div class="row fila-panel-ext">
            <div class="col-12">
              <label class="panel-ext-label-title">{{ 'infoControlEspesores' | translate }}</label>
            </div>
            <div class="col-12" style="margin-top: -5px;">
              <label class="panel-ext-label-text">-</label>
            </div>
          </div>

          <!--ANCHO LAMINA-->
          <div class="row fila-panel-ext">
            <div class="col-12">
              <label class="panel-ext-label-title">{{ 'anchoLamina' | translate }}</label>
            </div>
            <div class="col-12" style="margin-top: -5px;">
              <label class="panel-ext-label-text">-</label>
            </div>
          </div>

          <!--PRESION FILTROS-->
          <div class="row fila-panel-ext">
            <div class="col-12">
              <label class="panel-ext-label-title">{{ 'presionFiltros' | translate }}</label>
            </div>
            <div class="col-12" style="margin-top: -5px;">
              <label class="panel-ext-label-text">-</label>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

  <!--PANEL PRINCIPAL SI TIENE PROCESOS-->
  <!--panel maquina (de home)-->
  <hmi-panel class="hmi-panel col-4" [idMaquina]="idinstalacion" *ngIf="tieneHistorico_Procesos"></hmi-panel>

  <!-- COLUMNAS -->
  <div [ngClass]="!tieneHistorico_Procesos? 'hmi-personalizado-cont': 'hmi-personalizado-cont tiene-procesos'">
    <ng-container *ngFor="let col of infColumnasArray; let i = index">
      <div class="hmi-personalizado-col" *ngIf="col?.ensenar">
        <!-- un cuadrado -->
        <div class="hmi-personalizado-int" *ngIf="col.colAgregar" (click)="clickCampo(col['m1']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{ col['m1'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m1'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_1'}}"></div>          
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_1'}}"></div>
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_1'}}"></div>
              <div class="grafico-presion" *ngIf="col['m1'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_1'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m1'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m1'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m1'].id_tipoGrafico_DAT == 7"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m1'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [minBarra]="col['m1'].min" [maxBarra]="col['m1'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m1'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m1'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m1'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m1'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m1'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_1'}}"></div>

            </div>
          </div>                
        </div>
        <!-- col izquierda -->
        <div #columna class="hmi-personalizado-int-vert-izq" *ngIf="col.colIzquierda && (col.m1.ensenar || col.estructura != 2)" (click)="clickCampo(col['m1']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3>{{col['m1'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m1'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_1'}}"></div>          
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_1'}}"></div>
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_1'}}"></div>
              <div class="grafico-presion" *ngIf="col['m1'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_1'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m1'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m1'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m1'].id_tipoGrafico_DAT == 7"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>

                <grafico-posicion *ngIf="col['m1'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [minBarra]="col['m1'].min" [maxBarra]="col['m1'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m1'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m1'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m1'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m1'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m1'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_1'}}"></div>
            
            </div>
          </div>   
        </div>       
        <!-- cuadrados izquierda -->
        <div *ngIf="col.cuadradosIzquierda">
          <div class="hmi-personalizado-int-1" (click)="clickCampo(col['m1']?.idCampo)">
            <div class="card">
              <div class="card-header">
                <h3 >{{col['m1'].nombre}}</h3>
              </div>
              <div class="card-body">
                <!-- informacion -->   
                <div *ngIf="[1, 10, 11, 12].includes(col['m1'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_1'}}"></div>          
                <div *ngIf="col['m1'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_1'}}"></div>
                <div *ngIf="col['m1'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_1'}}"></div>
                <div class="grafico-presion" *ngIf="col['m1'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_1'}}"></div>
                
                <div class="cont-grafico-temperatura-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 6">
                  <grafico-temperatura *ngIf="col['m1'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [isPequeno]="true"> </grafico-temperatura>
                  <div class="cont-grafico-temperatura-info">
                    <div class="grafico-temperatura-icono">
                      <i class="icon-temperatura"></i>
                    </div>
                    <div class="grafico-temperatura-labels">  
                      <label> {{ col['m1'].value }} </label>
                      <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                    </div>
                  </div>
                </div>

                <div class="cont-grafico-posicion-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 7">
                  <div class="cont-grafico-posicion-info">
                    <div class="grafico-posicion-icono">
                      <i *ngIf="col['m1'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                    </div>
                    <div class="grafico-posicion-labels">
                      <label *ngIf="col['m1'].id_tipoGrafico_DAT == 7"> {{ col['m1'].value }} </label>
                      <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                    </div>
                  </div>
                  <grafico-posicion *ngIf="col['m1'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [minBarra]="col['m1'].min" [maxBarra]="col['m1'].max"> </grafico-posicion>
                </div>

                <div class="cont-grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3">
                  <grafico-nivel *ngIf="col['m1'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-nivel>
                  
                  <div class="cont-grafico-nivel-info">
                    <div class="grafico-nivel-labels">
                      <label class="grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3"> {{ col['m1'].value }} </label>
                      <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                    </div>
                  </div>
                </div>

                <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m1'].id_tipoGrafico_DAT)">
                  <div class="grafico-dato-icono">
                    <i class="{{ col['m1'].icono }}"></i>
                    
                  </div>
                  <div class="grafico-dato-labels">
                    <label>{{col['m1'].value}}</label>
                    <label class="grafico-dato-unidad">{{ col['m1'].unidad | translate }}</label>
                  </div>
                </div>

                <div *ngIf="col['m1'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_1'}}"></div>
              
              </div>
            </div>   
          </div>
            <div class="hmi-personalizado-int-3" (click)="clickCampo(col['m3']?.idCampo)">
              <div class="card">
                <div class="card-header">
                  <h3 >{{col['m3'].nombre}}</h3>
                </div>
                <div class="card-body">
                  <!-- informacion -->   
                  <div *ngIf="[1, 10, 11, 12].includes(col['m3'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_3'}}"></div>          
                  <div *ngIf="col['m3'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_3'}}"></div>
                  <div *ngIf="col['m3'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_3'}}"></div>
                  <div class="grafico-presion" *ngIf="col['m3'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_3'}}"></div>
                  
                  <div class="cont-grafico-temperatura-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 6">
                    <grafico-temperatura *ngIf="col['m3'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value" [isPequeno]="true"> </grafico-temperatura>
                    <div class="cont-grafico-temperatura-info">
                      <div class="grafico-temperatura-icono">
                        <i class="icon-temperatura"></i>
                      </div>
                      <div class="grafico-temperatura-labels">  
                        <label> {{ col['m3'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="cont-grafico-posicion-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 7">
                    <div class="cont-grafico-posicion-info">
                      <div class="grafico-posicion-icono">
                        <i *ngIf="col['m3'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                      </div>
                      <div class="grafico-posicion-labels">
                        <label *ngIf="col['m3'].id_tipoGrafico_DAT == 7"> {{ col['m3'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                      </div>
                    </div>
                    <grafico-posicion *ngIf="col['m3'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value" [minBarra]="col['m3'].min" [maxBarra]="col['m3'].max"> </grafico-posicion>
                  </div>

                  <div class="cont-grafico-nivel-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 3">
                    <grafico-nivel *ngIf="col['m3'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value"> </grafico-nivel>
                    
                    <div class="cont-grafico-nivel-info">
                      <div class="grafico-nivel-labels">
                        <label class="grafico-nivel-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 3"> {{ col['m3'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m3'].id_tipoGrafico_DAT)">
                    <div class="grafico-dato-icono">
                      <i class="{{ col['m1'].icono }}"></i>
                      
                    </div>
                    <div class="grafico-dato-labels">
                      <label>{{col['m1'].value}}</label>
                      <label class="grafico-dato-unidad">{{ col['m3'].unidad | translate }}</label>
                    </div>
                  </div>

                  <div *ngIf="col['m3'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_3'}}"></div>
                
                </div>
              </div>  
            </div>
        </div>
  
        <!-- col derecha -->
        <div class="hmi-personalizado-int-vert-der" *ngIf="col.colDerecha && (col.m2.ensenar || col.estructura != 2)" (click)="clickCampo(col['m2']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m2'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m2'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_2'}}"></div>          
              <div *ngIf="col['m2'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_2'}}"></div>
              <div *ngIf="col['m2'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_2'}}"></div>
              <div class="grafico-presion" *ngIf="col['m2'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_2'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m2'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value"> </grafico-temperatura>           
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">    
                    <label> {{ col['m2'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m2'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m2'].id_tipoGrafico_DAT == 7"> {{ col['m2'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m2'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value" [minBarra]="col['m2'].min" [maxBarra]="col['m2'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m2'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 3"> {{ col['m2'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m2'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m2'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m2'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m2'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m2'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_2'}}"></div>
            
            </div> 
          </div>
        </div>             
        <!-- cuadrados derecha -->
        <div *ngIf="col.cuadradosDerecha">
          <div class="row">
            <div class="hmi-personalizado-int-2" (click)="clickCampo(col['m2']?.idCampo)">
              <div class="card">
                <div class="card-header">
                  <h3 >{{col['m2'].nombre}}</h3>
                </div>
                <div class="card-body">
                  <!-- informacion -->   
                  <div *ngIf="[1, 10, 11, 12].includes(col['m2'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_2'}}"></div>          
                  <div *ngIf="col['m2'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_2'}}"></div>
                  <div *ngIf="col['m2'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_2'}}"></div>
                  <div class="grafico-presion" *ngIf="col['m2'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_2'}}"></div>
                  
                  <div class="cont-grafico-temperatura-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 6">
                    <grafico-temperatura *ngIf="col['m2'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value" [isPequeno]="true"> </grafico-temperatura>
                    <div class="cont-grafico-temperatura-info">
                      <div class="grafico-temperatura-icono">
                        <i class="icon-temperatura"></i>
                      </div>
                      <div class="grafico-temperatura-labels">  
                        <label> {{ col['m2'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="cont-grafico-posicion-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 7">
                    <div class="cont-grafico-posicion-info">
                      <div class="grafico-posicion-icono">
                        <i *ngIf="col['m2'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                      </div>
                      <div class="grafico-posicion-labels">
                        <label *ngIf="col['m1'].id_tipoGrafico_DAT == 7"> {{ col['m2'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                      </div>
                    </div>
                    <grafico-posicion *ngIf="col['m2'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value" [minBarra]="col['m2'].min" [maxBarra]="col['m2'].max"> </grafico-posicion>
                  </div>

                  <div class="cont-grafico-nivel-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 3">
                    <grafico-nivel *ngIf="col['m2'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value"> </grafico-nivel>
                    
                    <div class="cont-grafico-nivel-info">
                      <div class="grafico-nivel-labels">
                        <label class="grafico-nivel-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 3"> {{ col['m2'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m2'].id_tipoGrafico_DAT)">
                    <div class="grafico-dato-icono">
                      <i class="{{ col['m2'].icono }}"></i>
                      
                    </div>
                    <div class="grafico-dato-labels">
                      <label>{{col['m2'].value}}</label>
                      <label class="grafico-dato-unidad">{{ col['m2'].unidad | translate }}</label>
                    </div>
                  </div>

                  <div *ngIf="col['m2'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_2'}}"></div>
                
                </div>
              </div>  
            </div>
          </div>
          <div class="row">
            <div class="hmi-personalizado-int-4" (click)="clickCampo(col['m4']?.idCampo)">
              <div class="card">
                <div class="card-header">
                  <h3 >{{col['m4'].nombre}}</h3>
                </div>
                <div class="card-body">
                  <!-- informacion -->   
                  <div *ngIf="[1, 10, 11, 12].includes(col['m4'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_4'}}"></div>          
                  <div *ngIf="col['m4'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_4'}}"></div>
                  <div *ngIf="col['m4'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_4'}}"></div>
                  <div class="grafico-presion" *ngIf="col['m4'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_4'}}"></div>
                  
                  <div class="cont-grafico-temperatura-label" *ngIf="col['m4'].id_tipoGrafico_DAT == 6">
                    <grafico-temperatura *ngIf="col['m4'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_4'}}" [min]="col['m4'].min" [max]="col['m4'].max" [value]="col['m4'].value" [isPequeno]="true"> </grafico-temperatura>
                    <div class="cont-grafico-temperatura-info">
                      <div class="grafico-temperatura-icono">
                        <i class="icon-temperatura"></i>
                      </div>
                      <div class="grafico-temperatura-labels">  
                        <label> {{ col['m4'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m4']?.unidad | translate }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="cont-grafico-posicion-label" *ngIf="col['m4'].id_tipoGrafico_DAT == 7">
                    <div class="cont-grafico-posicion-info">
                      <div class="grafico-posicion-icono">
                        <i *ngIf="col['m4'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                      </div>
                      <div class="grafico-posicion-labels">
                        <label *ngIf="col['m4'].id_tipoGrafico_DAT == 7"> {{ col['m4'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m4']?.unidad | translate }}</label>
                      </div>
                    </div>
                    <grafico-posicion *ngIf="col['m4'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_4'}}" [min]="col['m4'].min" [max]="col['m4'].max" [value]="col['m4'].value" [minBarra]="col['m4'].min" [maxBarra]="col['m4'].max"> </grafico-posicion>
                  </div>

                  <div class="cont-grafico-nivel-label" *ngIf="col['m4'].id_tipoGrafico_DAT == 3">
                    <grafico-nivel *ngIf="col['m4'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_4'}}" [min]="col['m4'].min" [max]="col['m4'].max" [value]="col['m4'].value"> </grafico-nivel>
                    
                    <div class="cont-grafico-nivel-info">
                      <div class="grafico-nivel-labels">
                        <label class="grafico-nivel-label"  *ngIf="col['m4'].id_tipoGrafico_DAT == 3"> {{ col['m4'].value }} </label>
                        <label class="grafico-dato-unidad">{{ col['m4']?.unidad | translate }}</label>
                      </div>
                    </div>      
                  </div>

                  <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m4'].id_tipoGrafico_DAT)">
                    <div class="grafico-dato-icono">
                      <i class="{{ col['m4'].icono }}"></i>
                      
                    </div>
                    <div class="grafico-dato-labels">
                      <label>{{col['m4'].value}}</label>
                      <label class="grafico-dato-unidad">{{ col['m4'].unidad | translate }}</label>
                    </div>
                  </div>

                  <div *ngIf="col['m4'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_4'}}"></div>
                
                </div>
              </div> 
            </div>
          </div>     
        </div>
  
        <!-- row arriba -->
        <div class="hmi-personalizado-int-top" *ngIf="col.rowArriba" (click)="clickCampo(col['m1']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m1'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m1'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_1'}}"></div>          
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_1'}}"></div>
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_1'}}"></div>
              <div class="grafico-presion" *ngIf="col['m1'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_1'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m1'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [isPequeno]="true"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m1'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m1'].id_tipoGrafico_DAT == 7"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>

                <grafico-posicion *ngIf="col['m1'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [minBarra]="col['m1'].min" [maxBarra]="col['m1'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m1'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label"  *ngIf="col['m1'].id_tipoGrafico_DAT == 3"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m1'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m1'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m1'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m1'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_1'}}"></div>

            </div>
          </div> 
        </div>        
        <!-- cuadrados arriba -->
        <div class="hmi-personalizado-int-1" *ngIf="col.cuadradosArriba" (click)="clickCampo(col['m1']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m1'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m1'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_1'}}"></div>          
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_1'}}"></div>
              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_1'}}"></div>
              <div class="grafico-presion" *ngIf="col['m1'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_1'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m1'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [isPequeno]="true"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m1'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m1'].id_tipoGrafico_DAT == 7"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m1'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value" [minBarra]="col['m1'].min" [maxBarra]="col['m1'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m1'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m1'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_1'}}" [min]="col['m1'].min" [max]="col['m1'].max" [value]="col['m1'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label"  *ngIf="col['m1'].id_tipoGrafico_DAT == 3"> {{ col['m1'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m1']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m1'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m1'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m1'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m1'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m1'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_1'}}"></div>
            
            </div>
          </div> 
        </div>
        <div class="hmi-personalizado-int-2" *ngIf="col.cuadradosArriba" (click)="clickCampo(col['m2']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m2'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m2'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_2'}}"></div>          
              <div *ngIf="col['m2'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_2'}}"></div>
              <div *ngIf="col['m2'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_2'}}"></div>
              <div class="grafico-presion" *ngIf="col['m2'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_2'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m2'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value" [isPequeno]="true"> </grafico-temperatura>
                
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m2'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m2'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m2'].id_tipoGrafico_DAT == 7"> {{ col['m2'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m2'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value" [minBarra]="col['m2'].min" [maxBarra]="col['m2'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m2'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m2'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_2'}}" [min]="col['m2'].min" [max]="col['m2'].max" [value]="col['m2'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label"  *ngIf="col['m2'].id_tipoGrafico_DAT == 3"> {{ col['m2'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m2']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m2'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m2'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m2'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m2'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m2'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_2'}}"></div>
            
            </div>
          </div> 
        </div> 
        
        <!-- row abajo -->
        <div class="hmi-personalizado-int-bottom" *ngIf="col.rowAbajo" (click)="clickCampo(col['m3']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m3'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m3'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_3'}}"></div>          
              <div *ngIf="col['m3'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_3'}}"></div>
              <div *ngIf="col['m3'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_3'}}"></div>
              <div class="grafico-presion" *ngIf="col['m3'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_3'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m3'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value" [isPequeno]="true"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m3'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m3'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m3'].id_tipoGrafico_DAT == 7"> {{ col['m3'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m3'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value" [minBarra]="col['m3'].min" [maxBarra]="col['m3'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m3'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 3"> {{ col['m3'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m3'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m3'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m3'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m3'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m3'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_3'}}"></div>
            
            </div>
          </div> 
        </div>
        <!-- cuadrados abajo -->
        <div class="hmi-personalizado-int-3" *ngIf="col.cuadradosAbajo" (click)="clickCampo(col['m3']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m3'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m3'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_3'}}"></div>          
              <div *ngIf="col['m3'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_3'}}"></div>
              <div *ngIf="col['m3'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_3'}}"></div>
              <div class="grafico-presion" *ngIf="col['m3'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_3'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m3'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value" [isPequeno]="true"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">  
                    <label> {{ col['m3'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m3'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m3'].id_tipoGrafico_DAT == 7"> {{ col['m3'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m3'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value" [minBarra]="col['m3'].min" [maxBarra]="col['m3'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m3'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_3'}}" [min]="col['m3'].min" [max]="col['m3'].max" [value]="col['m3'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label" *ngIf="col['m3'].id_tipoGrafico_DAT == 3"> {{ col['m3'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m3']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m3'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m3'].icono }}"></i>
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m3'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m3'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m3'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_3'}}"></div>
            
            </div>
          </div> 
        </div>
        <div class="hmi-personalizado-int-4" *ngIf="col.cuadradosAbajo" (click)="clickCampo(col['m4']?.idCampo)">
          <div class="card">
            <div class="card-header">
              <h3 >{{col['m4'].nombre}}</h3>
            </div>
            <div class="card-body">
              <!-- informacion -->   
              <div *ngIf="[1, 10, 11, 12].includes(col['m4'].id_tipoGrafico_DAT)" id="{{'graficoBarras_'+i+'_4'}}"></div>          
              <div *ngIf="col['m4'].id_tipoGrafico_DAT == 2" id="{{'graficoDonut_'+i+'_4'}}"></div>
              <div *ngIf="col['m4'].id_tipoGrafico_DAT == 4" id="{{'graficoLineas_'+i+'_4'}}"></div>
              <div class="grafico-presion" *ngIf="col['m4'].id_tipoGrafico_DAT == 5" id="{{'graficoPresion_'+i+'_4'}}"></div>
              
              <div class="cont-grafico-temperatura-label" *ngIf="col['m4'].id_tipoGrafico_DAT == 6">
                <grafico-temperatura *ngIf="col['m4'].id_tipoGrafico_DAT == 6" id="{{'graficoTemperatura_'+i+'_4'}}" [min]="col['m4'].min" [max]="col['m4'].max" [value]="col['m4'].value" [isPequeno]="true"> </grafico-temperatura>
                <div class="cont-grafico-temperatura-info">
                  <div class="grafico-temperatura-icono">
                    <i class="icon-temperatura"></i>
                  </div>
                  <div class="grafico-temperatura-labels">
                    <label> {{ col['m4'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m4']?.unidad | translate }}</label>
                  </div>
                </div>
              </div>

              <div class="cont-grafico-posicion-label" *ngIf="col['m4'].id_tipoGrafico_DAT == 7">
                <div class="cont-grafico-posicion-info">
                  <div class="grafico-posicion-icono">
                    <i *ngIf="col['m4'].id_tipoGrafico_DAT == 7" class="icon-objetivo"></i>
                  </div>
                  <div class="grafico-posicion-labels">
                    <label *ngIf="col['m4'].id_tipoGrafico_DAT == 7"> {{ col['m4'].value }} </label>
                    <label class="grafico-dato-unidad">{{ col['m4']?.unidad | translate }}</label>
                  </div>
                </div>
                <grafico-posicion *ngIf="col['m4'].id_tipoGrafico_DAT == 7" id="{{'graficoPosicion_'+i+'_4'}}" [min]="col['m4'].min" [max]="col['m4'].max" [value]="col['m4'].value" [minBarra]="col['m4'].min" [maxBarra]="col['m4'].max"> </grafico-posicion>
              </div>

              <div class="cont-grafico-nivel-label" *ngIf="col['m4'].id_tipoGrafico_DAT == 3">
                <grafico-nivel *ngIf="col['m4'].id_tipoGrafico_DAT == 3" id="{{'graficoNivel_'+i+'_4'}}" [min]="col['m4'].min" [max]="col['m4'].max" [value]="col['m4'].value"> </grafico-nivel>
                
                <div class="cont-grafico-nivel-info">
                  <div class="grafico-nivel-labels">
                    <label class="grafico-nivel-label"  *ngIf="col['m4'].id_tipoGrafico_DAT == 3"> {{ col['m4'].value }} </label>           
                    <label class="grafico-dato-unidad">{{ col['m4']?.unidad | translate }}</label>
                  </div>
                </div>              
              </div>

              <div class="cont-grafico-dato-label" *ngIf="[8, 13, 14, 15].includes(col['m4'].id_tipoGrafico_DAT)">
                <div class="grafico-dato-icono">
                  <i class="{{ col['m4'].icono }}"></i>
                  
                </div>
                <div class="grafico-dato-labels">
                  <label>{{col['m4'].value}}</label>
                  <label class="grafico-dato-unidad">{{ col['m4'].unidad | translate }}</label>
                </div>
              </div>

              <div *ngIf="col['m4'].id_tipoGrafico_DAT == 9" id="{{'graficoResumenSemana_'+i+'_4'}}"></div>
            
            </div>
          </div> 
        </div>
      </div>
    </ng-container>
  </div>

</div>

  <!-- PESTANAS -->
  <div class="">
    <kendo-tabstrip>
      <kendo-tabstrip-tab id="tabProcesos" title="{{ tituloTab }}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <div class="procesdos col-sm-12 col-md-4" *ngIf="tieneHistorico_Procesos">
              <div class="proces-titulo">
                {{ 'historicoProcesos' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngIf="historicoProcesos.length==0">
                    <span >{{'noDatosParaMostrar' | translate}}</span>
                  </div>
                  <div class="historial" *ngFor="let historico of historicoProcesos">
                    <div class={{historico.divcss}}>
                      <div class="clearfix">
                        <div class="proces-fecha-cont">
                          <div id="horaIni" class="horaIni">
                            <label>{{historico.horaini}}</label>
                          </div>
                          <div id="diaIni" class="diaIni">
                            <label>{{historico.diaini}}</label>
                          </div>
                        </div>
  
                        <div class="proces-info-cont">
                          <div id="tipoProceso" *ngIf="historico.idProcesos_Tipo!=6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span> {{historico.nombre}}</label>
                          </div>
                          <div *ngIf="historico.idProcesos_Tipo==6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.tipoAlarNombre}}</label>
                          </div>
                          <div id="tiempos">
                            <label><span class="proces-titulo-uno">{{ 'tiempoReal' | translate}}:</span>
                              {{historico.tiemporeal}} <span class="proces-titulo-uno"
                                *ngIf="historico.idProcesos_Tipo!=2">{{ 'tiempoEstimado' | translate}}:</span><span
                                *ngIf="historico.idProcesos_Tipo!=2"> {{historico.tiempoteorico}}</span></label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{historico.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{historico.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{historico.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{historico.pieza}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div [ngClass]="{'procesdos col-sm-12 col-md-4': tieneHistorico_Procesos,
                             'procesdos col-sm-12': !tieneHistorico_Procesos}">
              <div class="proces-titulo">
                {{ 'alarmas' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngIf="alarmas.length==0">
                    <span >{{'noDatosParaMostrar' | translate}}</span>
                  </div>
                  <div class="historial" *ngFor="let alarma of alarmas">
  
                    <div class={{alarma.divcss}}>
  
                      <div class="clearfix">
                        <div class="proces-fecha-cont">
  
                          <div id="horaIni">
                            <label>{{alarma.horaini}}</label>
                          </div>
                          <div id="diaIni">
                            <label>{{alarma.diaini}}</label>
                          </div>
  
                        </div>
  
                        <div class="proces-info-cont">
                          <div id="numeroAlarma">
                            <label>{{alarma.numeroAlarma}}</label> - <label>{{alarma.descripcion.replace('ALARMA: ',
                              '')}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4" *ngIf="tieneHistorico_Procesos">
              <div class="proces-titulo">
                {{ 'planificado' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngIf="planificados.length==0">
                    <span >{{'noDatosParaMostrar' | translate}}</span>
                  </div>
                  <div class="historial" *ngFor="let planificado of planificados">
  
                    <div class={{planificado.divcss}}>
  
                      <div class="clearfix">
  
                        <div class="proces-fecha-cont">
                          <div id="tiempos" class="horaIni">
                            <label>{{planificado.horaini}}</label>
                          </div>
  
                          <div id="tiempos" class="diaIni">
                            <label>{{planificado.diaini}}</label>
                          </div>
                        </div>
  
                        <div class="proces-info-cont">
                          <div id="operacion">
                            <label id="operacion"><span class="proces-titulo-uno">{{ 'operacion' | translate}}:</span>
                              {{planificado.operacion}}</label>
                          </div>
                          <div id="tiempoTeorico">
                            <label><span class="proces-titulo-uno">{{ 'tiempoEstimado' | translate}}:</span>
                              {{planificado.tiempoteorico}}</label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{planificado.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{planificado.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{planificado.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{planificado.pieza}}</label>
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row cont-mantenimientos">
            <div class="col-md-6">
              {{ 'porFechas' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="41%">
                  </kendo-grid-column>
                  <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span>{{ dataItem.tipo | translate }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
              </div>
            </div>
            <div class="col-md-6">
              {{ 'porTiempos' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="51%">
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab id="tabNotas" title="{{ 'notas' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <kendo-grid [data]="notas" style="height: 400px">
              <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
