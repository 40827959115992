<!-- FILTROS -->
<div class="row" style="margin-top: 10px;">
  <div class="col-lg-3" *ngFor="let proyecto of listaProyectos" style="margin-top: 10px;">
    <div class="clearfix">
      <div class="card">
        <!-- TITULO -->
        <div class="card-header">
          <h3>
            <label>{{ proyecto.nombre }}</label>
          </h3>
          <!-- EXPANDIR -->
          <div class="plegarpanel-2">
            <div *ngIf="proyecto.estadoBloque==3">
              <button class="fa fa-angle-down" (click)="clickAbrirFiltro(proyecto);"></button>
            </div>
            <div *ngIf="proyecto.estadoBloque==4">
              <button class="fa fa-angle-up" (click)="clickCerrarrFiltro(proyecto);"></button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <!-- (+) AÑADIR PROYECTO -->
          <div style="height: 195px; cursor: pointer;" *ngIf="proyecto.estadoBloque==1" (click)="agregarProyecto(proyecto);">
            <div class="row" style="height: 195px; position: relative;">
              <div style="position: absolute; top: 25%; width: 100%; text-align: center;">
                <a style="font-size: 20px;">{{ 'agregarProyecto' | translate}}</a><br />
                <span class="fa-stack fa-2x">
                  <i class="fas fa-circle fa-stack-2x" style="color:#f2f2f2;"></i>
                  <i class="fas fa-plus fa-stack-1x fa-inverse" style="color:#18d6b0;"></i>
                </span>
              </div>
            </div>
          </div>
          <!-- FILTRO -->
          <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==3 || proyecto.estadoBloque==4">
            <div>
              <!-- FECHAS (siempre visibles) -->
              <div class="row">
                <!--FECHA INICIO-->
                <div class="col-6">
                  <label class="control-label">{{ 'fechainicio' | translate }}</label>
                  <div class="caja">
                    <kendo-datepicker [(value)]="proyecto.fechaIni" (valueChange)="onChangeFechaIni($event, proyecto)" class="form-control" [max]="proyecto.fechaFin"></kendo-datepicker>
                  </div>
                </div>
                <!--FECHA FIN-->
                <div class="col-6">
                  <label class="control-label">{{ 'fechafin' | translate }}</label>
                  <div class="caja">
                    <kendo-datepicker [(value)]="proyecto.fechaFin" (valueChange)="onChangeFechaFin($event, proyecto)" class="form-control" [min]="proyecto.fechaIni"></kendo-datepicker>
                  </div>
                </div>
              </div>
              <div style="margin-top: 20px;"></div>
              <!--OPERARIO-->
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 &&  proyecto.operarioSeleccionado.length > 0)" class="form-group">
                <label class="control-label">{{ 'operario' | translate }}</label>
                <div class="caja">
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaOperarios"
                                     [(ngModel)]="proyecto.operarioSeleccionado"
                                     [textField]="'nombreOperario'"
                                     [valueField]="'idOperario'"
                                     placeholder="{{ 'seleccioneOperario' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderOperario"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperario}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'operarioSeleccionado' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--MAQUINA-->
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.maquinaSeleccionada.length > 0)" class="form-group">
                <label class="control-label">{{ 'maquina' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaMaquinas" [(ngModel)]="proyecto.maquinaSeleccionada" [textField]="'nombreMaquina'" [valueField]="'idMaquina'" [defaultItem]="placeHolderMaquina" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaMaquinas"
                                     [(ngModel)]="proyecto.maquinaSeleccionada"
                                     [textField]="'nombreMaquina'"
                                     [valueField]="'idMaquina'"
                                     placeholder="{{ 'seleccioneMaquina2' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderMaquina"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreMaquina}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--HERRAMIENTA-->
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.herramientaSeleccionada.length > 0)">
                <label class="control-label">{{ 'herramienta' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaHerramientas" [(ngModel)]="proyecto.herramientaSeleccionada" [textField]="'nombreHerramienta'" [valueField]="'idHerramienta'" [defaultItem]="placeHolderHerramienta" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaHerramientas"
                                     [(ngModel)]="proyecto.herramientaSeleccionada"
                                     [textField]="'nombreHerramienta'"
                                     [valueField]="'idHerramienta'"
                                     placeholder="{{ 'seleccioneHerramienta' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderHerramienta"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreHerramienta}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'herramientasSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--OF-->
              <div style="margin-top: 20px;"></div>
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.ofSeleccionado.length > 0)">
                <label class="control-label">{{ 'of' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaOfs" [(ngModel)]="proyecto.ofSeleccionado" [textField]="'nombreOf'" [valueField]="'idOf'" [defaultItem]="placeHolderOf" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaOfs"
                                     [(ngModel)]="proyecto.ofSeleccionado"
                                     [textField]="'nombreOf'"
                                     [valueField]="'idOf'"
                                     placeholder="{{ 'seleccioneOf' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderOf"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'xofs' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--CLIENTE-->
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.clienteSeleccionado.length > 0)">
                <label class="control-label">{{ 'cliente' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaClientes" [(ngModel)]="proyecto.clienteSeleccionado" [textField]="'nombreCliente'" [valueField]="'idCliente'" [defaultItem]="placeHolderCliente" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaClientes"
                                     [(ngModel)]="proyecto.clienteSeleccionado"
                                     [textField]="'nombreCliente'"
                                     [valueField]="'idCliente'"
                                     placeholder="{{ 'seleccioneCliente' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderCliente"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--PIEZA-->
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.piezaSeleccionada.length > 0)">
                <label class="control-label">{{ 'pieza' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaPiezas" [(ngModel)]="proyecto.piezaSeleccionada" [textField]="'nombrePieza'" [valueField]="'idPieza'" [defaultItem]="placeHolderPieza" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaPiezas"
                                     [(ngModel)]="proyecto.piezaSeleccionada"
                                     [textField]="'nombrePieza'"
                                     [valueField]="'idPieza'"
                                     placeholder="{{ 'seleccionePieza' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderPieza"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--PARTE-->
              <div *ngIf="!user.ocultarParte && (proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.parteSeleccionada.length > 0))">
                <label class="control-label">{{ 'parte' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaPartes" [(ngModel)]="proyecto.parteSeleccionada" [textField]="'nombreParte'" [valueField]="'idParte'" [defaultItem]="placeHolderParte" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaPartes"
                                     [(ngModel)]="proyecto.parteSeleccionada"
                                     [textField]="'nombreParte'"
                                     [valueField]="'idParte'"
                                     placeholder="{{ 'seleccioneParte' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderParte"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreParte}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'partesSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--OPERACION-->
              <div *ngIf="proyecto.estadoBloque==2 || proyecto.estadoBloque==4 || (proyecto.estadoBloque==3 && proyecto.operacionSeleccionada.length > 0)">
                <label class="control-label">{{ 'operacion' | translate }}</label>
                <div class="caja">
                  <!--<kendo-dropdownlist [data]="listaOperaciones" [(ngModel)]="proyecto.operacionSeleccionada" [textField]="'nombreOperacion'" [valueField]="'idOperacion'" [defaultItem]="placeHolderOperacion" class="form-control">
                    <kendo-dropdownlist-messages noDataText="{{'norecords' | translate}}"></kendo-dropdownlist-messages>
                  </kendo-dropdownlist>-->
                  <kendo-multiselect kendoMultiSelectSummaryTag
                                     [data]="listaOperaciones"
                                     [(ngModel)]="proyecto.operacionSeleccionada"
                                     [textField]="'nombreOperacion'"
                                     [valueField]="'idOperacion'"
                                     placeholder="{{ 'seleccioneOperacion' | translate }}"
                                     [autoClose]="false"
                                     [defaultItem]="placeHolderOperacion"
                                     class="form-control"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperacion}}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'operacionesSeleccionadas' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                </div>
              </div>
              <!--BOTONES-->
              <div class="row" style="margin-top: 20px;">
                <!-- FILTRAR -->
                <div class="col-4" style="padding-left: 7px; padding-right: 7px;" *ngIf="proyecto.estadoBloque==2">
                  <button class="btn btn-success btn-sm mr-1" (click)="clickBtnFiltrar(proyecto);" style="width: 100%; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ 'filtrar' | translate}}</button>
                </div>
                <!-- RE-FILTRAR -->
                <div class="col-4" style="padding-left: 7px; padding-right: 7px;" *ngIf="proyecto.estadoBloque==3 || proyecto.estadoBloque==4">
                  <button class="btn btn-success btn-sm mr-1" (click)="clickBtnRE_Filtrar(proyecto);" style="width: 100%; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"> {{ 'filtrar' | translate}}</button>
                </div>
                <!-- LIMPIAR FILTRO -->
                <div class="col-4" style="padding-left: 7px; padding-right: 7px;">
                  <button class="btn btn-primary btn-sm mr-1" (click)="clickBtnLimpiarFiltro(proyecto);" style="width: 100%; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ 'limpiarFiltro' | translate}}</button>
                </div>
                <!-- CANCELAR -->
                <div class="col-4" style="padding-left: 7px; padding-right: 7px;" *ngIf="proyecto.estadoBloque==2">
                  <button class="btn btn-danger btn-sm mr-1" (click)="clickBtnCancelar(proyecto);" style="width: 100%; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ 'cancelar' | translate}}</button>
                </div>
                <!--BOTON DE ELIMINAR (X)-->
                <div class="col-4" style="padding-left: 7px; padding-right: 7px;" *ngIf="proyecto.estadoBloque==3 || proyecto.estadoBloque==4">
                  <button class="btn btn-danger btn-sm mr-1" (click)="clickBtnEliminar(proyecto);" style="width: 100%; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ 'cancelar' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="proyecto.cargandoDatos" class="k-i-loading" style="z-index: 3;">  </div>
  </div>
</div>

<!-- GANTT -->
<div class="row">
  <div class="col-lg-12">
    <div class="clearfix">
      <div class="card">
        <div class="card-header">
          <h3>
            <label>{{ 'oee' | translate }}</label>
          </h3>
        </div>
        <div class="card-body">
          <div class="row" *ngFor="let proyecto of listaProyectos">
            <div class="col" [attr.id]="'graficoBarra_' + proyecto.id"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PANELES DE INFORMACION -->
<div class="row">
  <div class="col-lg-3" *ngFor="let proyecto of listaProyectos">
    <div class="clearfix" *ngIf="(proyecto.estadoBloque==3 || proyecto.estadoBloque==4)">
      <div class="card">
        <!-- TITULO -->
        <div class="card-header">
          <h3>
            <label>{{ 'datos' | translate }}</label>
          </h3>
        </div>
        <!--GRAFICO DONUT-->
        <div class="card-body">
          <div class="row" style="height: 170px;">
            <div class="col" [attr.id]="'graficoDonut_' + proyecto.id"></div>
          </div>
        </div>
        <!--DISPONIBILIDAD-->
        <div class="row">
          <div class="col bloques-container-cp">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'disponibilidad' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.disponibilidad.toFixed(1) }}%</label>
            </div>
          </div>
        </div>
        <!--RENDIMIENTO-->
        <div class="row">
          <div class="col bloques-container-cp">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'rendimiento' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.rendimiento.toFixed(1) }}%</label>
            </div>
          </div>
        </div>
        <!--CALIDAD-->
        <div class="row">
          <div class="col bloques-container-cp">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'calidad' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.calidad.toFixed(1) }}%</label>
            </div>
          </div>
        </div>
        <div class="card-header">
          <h3>
            <label>{{ 'tiempos' | translate }}</label>
          </h3>
        </div>
        <!--HORAS ESTIMADAS-->
        <div class="row">
          <div class="col bloques-container-cp">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'horasEstimadas' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ myFunctions.secondsTo_HH_MM(proyecto.horasEstimadas) }}</label>
            </div>
            <div class="bloques-info-cp doslineas" style="margin-top: -15px !important;">
              <label class="bloques-info-label-izquierda-cp">{{ 'desvio' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ myFunctions.secondsTo_HH_MM(proyecto.desvioHorasEstimado) }}</label>
            </div>
          </div>
        </div>
        <!--HORAS PREVISTAS-->
        <div class="row">
          <div class="col bloques-container-cp">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'horasPrevistas' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ myFunctions.secondsTo_HH_MM(proyecto.horasPrevistas) }}</label>
            </div>
            <div class="bloques-info-cp doslineas" style="margin-top: -15px !important;">
              <label class="bloques-info-label-izquierda-cp">{{ 'desvio' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ myFunctions.secondsTo_HH_MM(proyecto.desvioHorasPrevistas) }}</label>
            </div>
          </div>
        </div>
        <!--N PROYECTOS-->
        <!--<div class="row">
          <div class="col bloques-container-cp">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'nProyectos' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">26</label>
            </div>
          </div>
        </div>-->
        <!--N PIEZAS-->
        <div class="row">
          <div class="col" style="margin: 0px 15px 0px 15px;">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'nPiezas' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.nPiezas }}</label>
            </div>
          </div>
        </div>
        <!--N ALARMAS-->
        <div class="row">
          <div class="col" style="margin: 0px 15px 0px 15px;">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'nAlarmas' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.nAlarmas }}</label>
            </div>
          </div>
        </div>
        <!--N OPERACIONES-->
        <div class="row">
          <div class="col" style="margin: 0px 15px 0px 15px;">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'nOperaciones' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.nOperaciones }}</label>
            </div>
          </div>
        </div>
        <div class="card-header d-none">
          <h3>
            <label>{{ 'datos' | translate }}</label>
          </h3>
        </div>
        <!--AVANCE ACUMULADO-->
        <div class="row d-none">
          <div class="col" style="margin: 0px 15px 0px 15px;">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'avanceAcumulado' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.avanceAcumulado.toFixed(2) }}</label>
            </div>
          </div>
        </div>
        <!--KW ACUMULADOS-->
        <div class="row d-none">
          <div class="col" style="margin: 0px 15px 0px 15px;">
            <div class="bloques-info-cp doslineas">
              <label class="bloques-info-label-izquierda-cp">{{ 'kwAcumulados' | translate}}</label>
              <label class="bloques-info-label-derecha-cp">{{ proyecto.kwAcumulados.toFixed(2) }}</label>
            </div>
          </div>
        </div>
        <div style="height: 15px;"></div>
      </div>
    </div>
    <div *ngIf="proyecto.cargandoDatos" class="k-i-loading" style="z-index: 3;">  </div>
  </div>
</div>
