
<ul class="list-group list-group-flush" *ngIf="router.url === '/eskola_cursoslectivos'">


 
    <kendo-grid [kendoGridBinding]="dataCursosLectivos"
                kendoGridSelectBy="id"
                [pageable]="true"
                [sortable]="true"
                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                [navigable]="true"
                filterable="menu"
                [resizable]="true"
                (cellClick)="cellClick($event)"
                [selectedKeys]="mySelection"
                [pageSize]="25">
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-primary btn-sm mr-1">{{ 'editar' | translate}}</button>
        <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-success btn-sm mr-1">{{ 'nuevo' | translate}}</button>
        <button kendoButton (click)="onClickDuplicar()" class="btn mr-1  btn-morado btn-sm mr-1">{{ 'duplicar' | translate}}</button>
        <button kendoButton (click)="onClickEliminar(content)" class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.materiales<2">{{ 'eliminar' | translate}}</button>
      </ng-template>
      <kendo-grid-checkbox-column showSelectAll="true" width="5%" [style]="{'text-align': 'left'}" [columnMenu]="false"></kendo-grid-checkbox-column>
      <kendo-grid-column width="25%" field="nombre"  [style]="{'text-align': 'left'}"  title="{{ 'nombre' | translate}}"></kendo-grid-column>
      <kendo-grid-column width="10%" field="inicio"  [style]="{'text-align': 'left'}"  title="{{ 'inicio' | translate}}"></kendo-grid-column>
      <kendo-grid-column width="10%" field="fin"  [style]="{'text-align': 'left'}"  title="{{ 'fin' | translate}}"></kendo-grid-column>
      <kendo-grid-column width="50%" field="observaciones"  [style]="{'text-align': 'left'}"  title="{{ 'observaciones' | translate}}"></kendo-grid-column>

      
      <!--TRADUCCIÓN TEXTOS DEL GRID-->
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid> 
  </ul>
  
  
  <!--POPUP ELIMINAR-->
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'preguntaeliminarpopup' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="eliminarRegistro()" [disabled]="this.user.materiales<2">{{ 'si' | translate }}</button>
    </div>
  </ng-template>
  
  <router-outlet></router-outlet>
