<style>
  .cont-select-maquinas-out.desplegar {
    width: calc(100% - 650px) !important;
  }
</style>

<div class="cont-filtro-maquinas-areas clearfix" *ngIf="router.url === '/informesincidencias'">
  <div class="filtro-areaproductiva-cont">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="seccionChanged()"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="seccionChanged()"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="no-contraer-click-maquina cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas">
      <div id="divMensajeSeleccionar" *ngIf="selectedMaquina.length==0" class="text-select-maquinas">
        <div>
          {{ 'seleccioneMaquina' | translate }}
        </div>
      </div>
      <div *ngFor="let maquina of JmaquinasMostradas" (click)="maquinaClicked(maquina)" class="clickable" [ngClass]="{'actual': selectedMaquina.includes(maquina.id)}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
      <div *ngIf="loading" class="k-i-loading" style="z-index: 3;"></div>
    </div>

  </div>
  <!--FECHAS-->
  <div class="filtro-areaproductiva-cont" style="width: 370px; float:right !important;" >
    <div class="card" style="height: 92px;">
      <div class="card-body">
        <div class="row">
          <div class="col-5">
            <div class="clearfix">
              <label>{{ 'fechainicio' | translate }}</label>
              <div class="caja">
                <kendo-datepicker (valueChange)="onChangeFIni($event)" [(value)]="fechaini" class="form-control"></kendo-datepicker>
              </div>
            </div>
          </div>
          <div class="col-5" style="padding-left: 0;">
            <div class="clearfix">
              <label>{{ 'fechafin' | translate }}</label>
              <div class="caja">
                <kendo-datepicker (valueChange)="onChangeFFin($event)" [(value)]="fechafin" class="form-control"></kendo-datepicker>
              </div>
            </div>
          </div>
          <div style="margin: 25px 0px 0px 0px;">
            <button type="button" class="btn btn-primary float-left" (click)="cargarIncidencias()">{{ 'filtrar' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" >
  <div class="col-md-2">
  <!--BLOQUES NUM Y TIEMPO INCIDENCIAS-->
  <div class="card">
    <div class="card-header">
      <h3>
        <label>{{ 'progresion' | translate}}</label>
      </h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      
        <div class="row">
          <div class="perdidas-periodo perdidas-bloque col-md-12">
            <div class="card">
              <div class="card-body">
                <label ID="lvariacionperiodo" class="perdida-bloque-titulo">{{ 'numIncidencias' | translate }}</label>
                <label *ngIf="numIncidencias==0" ID="lporcentajevariacionesperiodotiempo" class="perdida-bloque-horas-sin-incidencias">
                  {{ 'sinIncidencias' | translate }}
                </label>
                <label *ngIf="numIncidencias>0" ID="lporcentajevariacionesperiodotiempo" class="perdida-bloque-horas">
                  {{ numIncidencias }}
                  <span class="k-icon k-i-arrow-chevron-up icono-med-pa" *ngIf="numIncidencias > numIncidenciasPeriodoAnterior"></span>
                  <span class="k-icon k-i-equal icono-med-pa" *ngIf="numIncidencias == numIncidenciasPeriodoAnterior"></span>
                  <span class="k-icon k-i-arrow-chevron-down icono-med-pa" *ngIf="numIncidencias < numIncidenciasPeriodoAnterior"></span>
                </label>
                <label *ngIf="numIncidencias>0" ID="lvalorvariacionperiodotiempo" class="perdida-bloque-porcentaje">{{ numIncidenciasPeriodoAnterior }}</label>
              </div>
            </div>
          </div>
          <div class="perdidas-global perdidas-bloque col-md-12">
            <div class="card">
              <div class="card-body">
                <label ID="lvariacionperiodo" class="perdida-bloque-titulo">{{ 'tiempoIncidencias' | translate }}</label>
                <label *ngIf="numIncidencias==0" ID="lporcentajevariacionesperiodotiempo" class="perdida-bloque-horas-sin-horas">
                  {{ myFunctions.secondsTo_HH_MM(0) }}
                </label>
                <label *ngIf="numIncidencias>0" ID="lporcentajevariacionesperiodotiempo" class="perdida-bloque-horas">
                  {{ myFunctions.secondsTo_HH_MM(tiempoIncidencias) }}
                  <span class="k-icon k-i-arrow-chevron-up icono-med-pa" *ngIf="tiempoIncidencias > tiempoIncidenciasPeriodoAnterior"></span>
                  <span class="k-icon k-i-equal icono-med-pa" *ngIf="tiempoIncidencias == tiempoIncidenciasPeriodoAnterior"></span>
                  <span class="k-icon k-i-arrow-chevron-down icono-med-pa" *ngIf="tiempoIncidencias < tiempoIncidenciasPeriodoAnterior"></span>
                </label>
                <label *ngIf="tiempoIncidencias>0" ID="lvalorvariacionperiodotiempo" class="perdida-bloque-porcentaje">{{ myFunctions.secondsTo_HH_MM(tiempoIncidenciasPeriodoAnterior) }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!--GRAFICO DONUTS-->
  <div class="col-md-3">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'tiposIncidencia' | translate}}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="grafico_donuts" style="height: 235px;">
        </div>
        <a *ngIf="incidencias.length==0" style="position: absolute; top: 45%; width: 100%; text-align: center;">{{ 'sinIncidencias' | translate}}</a>
      </div>
    </div>
  </div>
  <!--GRAFICO BARRAS-->
  <div class="col-md-7">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'gravedad' | translate}}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="grafico_barras" style="height: 235px;">
        </div>
        <a *ngIf="incidencias.length==0" style="position: absolute; top: 45%; width: 100%; text-align: center;">{{ 'sinIncidencias' | translate}}</a>
      </div>
    </div>
  </div>
</div>

<kendo-grid [kendoGridBinding]="incidencias"
            [sortable]="true"
            [navigable]="true"
            filterable="menu"
            kendoGridSelectBy="id"
            [selectedKeys]="seleccionados"
            (cellClick)="cellClick($event)"
            *ngIf="router.url === '/informesincidencias'">
  <!--BOTONES ARRIBA-->
  <ng-template kendoGridToolbarTemplate position="top">
    <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button (click)="onClickCopiar(content)" class="btn btn-secondary btn-sm mr-1" [disabled]="this.user.informesIncidencias<2">{{ 'copiar' | translate}}</button>
    <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.informesIncidencias<2">{{ 'eliminar' | translate}}</button>
    <button (click)="onClickImprimir()" class="btn btn-primary btn-sm mr-1" [disabled]="selectedMaquina.length>1">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      <i class="fa fa-print"></i> {{ 'imprimir' | translate}}
    </button>
    {{ 'abierto' | translate}} <kendo-switch [(ngModel)]="abierto" [onLabel]="' '" [offLabel]="' '" (valueChange)="onChangeAbierto($event)"></kendo-switch>
  </ng-template>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="70%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--id-->
  <kendo-grid-column field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--causa-->
  <kendo-grid-column field="descripcion" title="{{ 'causa' | translate}}"></kendo-grid-column>
  <!--Gravedad-->
  <kendo-grid-column field="gravedad" title="{{ 'gravedad' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.gravedad == 1">{{ 'leve' | translate}}</span>
      <span *ngIf="dataItem.gravedad == 2">{{ 'medio' | translate}}</span>
      <span *ngIf="dataItem.gravedad == 3">{{ 'grave' | translate}}</span>
    </ng-template>
  </kendo-grid-column>
  <!--OF-->
  <kendo-grid-column field="_OF" title="{{ 'of' | translate}}"></kendo-grid-column>
  <!--Cliente-->
  <kendo-grid-column field="cliente" title="{{ 'cliente' | translate}}"></kendo-grid-column>
  <!--Pieza-->
  <kendo-grid-column field="pieza" title="{{ 'pieza' | translate}}"></kendo-grid-column>
  <!--Operacion-->
  <kendo-grid-column field="operacion" title="{{ 'operacion' | translate}}"></kendo-grid-column>
  <!--Maquina-->
  <kendo-grid-column field="maquina" title="{{ 'maquina' | translate}}"></kendo-grid-column>
  <!--operario-->
  <kendo-grid-column field="operarioIncidencia" title="{{ 'operario' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.operarioIncidencia === null || dataItem.operarioIncidencia === ''">{{ 'desconocido' | translate}}</span>
      <span *ngIf="dataItem.operarioIncidencia != null && dataItem.operarioIncidencia != ''">{{ dataItem.operarioIncidencia }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--tipoincidencia-->
  <!-- <kendo-grid-column field="tipoIncidencia" title="{{ 'tipo' | translate}}"></kendo-grid-column> -->
  <!--afecta-->
  <kendo-grid-column field="afectaProduccion" title="{{ 'afectaProduccion' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.afectaProduccion === true">{{ 'si' | translate }}</span>
      <span *ngIf="dataItem.afectaProduccion === false">{{ 'no' | translate }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--fechaIncid-->
  <kendo-grid-column field="fechaIncidencia" title="{{ 'fechaIncidencia' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ formatingDate(dataItem.fechaIncidencia) }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--fechaRes-->
  <kendo-grid-column field="fechaResuelto" title="{{ 'fechaResuelto' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{ formatingDate(dataItem.fechaResuelto) }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--operario res-->
  <kendo-grid-column field="operarioResuelto" title="{{ 'operarioResuelto' | translate}}">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="dataItem.operarioResuelto === null || dataItem.operarioResuelto === ''">{{ 'desconocido' | translate}}</span>
      <span *ngIf="dataItem.operarioResuelto != null && dataItem.operarioResuelto != ''">{{ dataItem.operarioResuelto }}</span>
    </ng-template>
  </kendo-grid-column>
  <!--causaStr-->
  <kendo-grid-column field="causaStr" title="{{ 'causa' | translate}}"></kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>

<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)" [disabled]="this.user.informesIncidencias<2" >{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>
