import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MenuService, ProveedoresService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-proveedores',
  templateUrl: './proveedores.component.html',
  styleUrls: ['./proveedores.component.less']
})
export class ProveedoresComponent implements OnInit {

  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  dataProveedores: any[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;
  proveedorNombre: string = '';
  proveedorDescripcion: string = '';
  activo: boolean;
  idSelected: number;
  submitted = false;
  errorNombre = false;
  user = this.usuariosService.userValue;

  constructor(private proveedoresService: ProveedoresService,
    private usuariosService: UsuariosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    protected alertService: AlertService) {
    this.menuService.titulo = this.translateService.instant('proveedores');
    this.cargarDatos();
  }
  cargarDatos() {
    this.proveedoresService.getAll().subscribe(
      result => {
        var an: any = result;
        this.dataProveedores = an;
      });
  }

  ngOnInit(): void {
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.idSelected = e.dataItem.id;
      this.router.navigate(['/proveedor/' + this.idSelected]);      
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.idSelected = this.mySelection[0];
      this.router.navigate(['/proveedor/' + this.idSelected]);          
    }
  }
  onClickNuevo() {
    this.router.navigate(['/proveedor/0']);    
  }
  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        this.mySelection.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.proveedoresService.deleteProveedores(ids).subscribe(() => {
          this.isDeleting = false;
          this.cargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

  

}
