import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { AsignacionTiemposService, InformeProyectosService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';

@Component({
  selector: 'app-asignacion-tiempos',
  templateUrl: './asignacion-tiempos.component.html'
})
export class AsignacionTiemposComponent implements OnInit {

  public loadingPanel: boolean = true;
  private dataFiltro: any;
  public valoresGrid: any;
  user = this.userService.userValue;

  //For filter
  public hayDatosFiltro: boolean = false;

  public listaOfs: any; //POR TEXTO
  public ofsSeleccionados: any;

  public listaClientes: any; //POR TEXTO
  public clientesSeleccionados: any;

  public listaPiezas: any; //POR TEXTO
  public piezasSeleccionadas: any;

  public ocultarPartes: boolean;
  public listaPartes: any; //POR TEXTO
  public partesSeleccionadas: any;

  public listaOperaciones: any; //POR TEXTO
  public operacionesSeleccionadas: any;

  public listaPlanos: any; //POR TEXTO
  public planosSeleccionados: any;

  public listaMaquinas: any;
  public listaMaquinasMostradas: any;
  public maquinasSeleccionadas: any;

  public terminados: boolean = false;


  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public JgruposMaquinasCombo: any;
  public JgruposMaquinasSelected: any;
  public JgruposMaquinasMaquinas: any;

  public variablesSeleccionadas: number[] = [];
  public variablesSeleccionadas2: number[] = [];
  public tabSelected = 0;

  public mask = "000:00:00";

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  constructor(private informeProyectosService: InformeProyectosService,
    private router: Router,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private asignacionTiemposService: AsignacionTiemposService,
    public myFunctions: MyFunctions) {

    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined;

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {
      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
          this.cargarDatosFiltro();
        });
    } else {
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
      this.cargarDatosFiltro();
    }

    this.hayDatosFiltro = false;

    this.menuService.titulo = this.translateService.instant('asignacionTiempos').toUpperCase();
    this.userService.user.subscribe(x => this.user = x);

  }

  ngOnInit(){}


  onTabSelected(evt: any){
    this.tabSelected = evt.index;
    this.variablesSeleccionadas2 = [];
    this.variablesSeleccionadas = [];
  }

  btnLimpiarFiltro(): void { //si se quiere limpiar el filtro vaciar los seleccionados y volver a cargar los datos

    this.maquinasSeleccionadas = undefined;
    this.ofsSeleccionados = undefined;
    this.clientesSeleccionados = undefined;
    this.piezasSeleccionadas = undefined;
    this.operacionesSeleccionadas = undefined;
    this.seccionesSeleccionadas = undefined;
    this.JgruposMaquinasSelected = undefined;
    this.operacionesSeleccionadas = undefined;
    this.partesSeleccionadas = undefined;
    this.planosSeleccionados = undefined;
    this.terminados = false;

    this.CambioFiltro();

  }

  btnFiltrar() {
    this.loadingPanel = true;
    //Cogemos los datos para la llamada de los seleccionados en el filtro
    var listaNombresClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.nombreCliente);
    var listaNombresPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => a.nombrePieza);
    var listaNombresOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.nombreOf);
    var listaPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => a.nombreParte);
    var listaOperaciones = (this.operacionesSeleccionadas === undefined) ? [] : this.operacionesSeleccionadas.map(a => a.nombreOperacion);
    var listaPlanos = (this.planosSeleccionados === undefined) ? [] : this.planosSeleccionados.map(a => a.numeroPlano);
    var listaMaquinas = (this.maquinasSeleccionadas === undefined) ? [] : this.maquinasSeleccionadas.map(a => a.idMaquina);

    var that = this;
    this.asignacionTiemposService.GetInformeTabla(listaNombresClientes.join("&"), listaNombresPiezas.join("&"), listaNombresOfs.join("&"), listaPartes.join("&"), listaOperaciones.join("&"), listaPlanos.join("&"), listaMaquinas.join("&"), Number(this.terminados)).subscribe(json => {
      this.valoresGrid = json;
      this.valoresGrid = this.valoresGrid.data;
      var aux = 0;
      this.valoresGrid.forEach(element => {
        element.id = aux;
        element.auxTiempoSinPreparacion = "";
        element.auxTiempoPreparacion = "";
        aux++;
      });
      this.dataGrid1 = this.valoresGrid;
      this.dataGrid2 = this.valoresGrid;
      that.loadingPanel = false;
    });
  }

  public showGridTooltip(e: MouseEvent): void { //para mostrar un tooltip con el dato al pasar el ratón por encima cuando el título o contenido no cabe
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  public btnSave(){
    this.valoresGrid.forEach(element => {
      element.nuevoTiempoSinPreparacion = Number(element.nuevoTiempoSinPreparacion);
      element.nuevoTiempoPreparacion = Number(element.nuevoTiempoPreparacion);
    });
    this.asignacionTiemposService.updateData(this.valoresGrid).subscribe(json => {});
  }

  public btnMejorTiempo() {
    if(this.tabSelected === 0){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas.includes(element.id)){
          element.nuevoTiempoSinPreparacion = element.mejorTiempoSinPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.mejorTiempoSinPreparacion).split(":")[0])>=100){
            element.auxTiempoSinPreparacion = (this.myFunctions.secondsTo_HH_MM_SS(element.mejorTiempoSinPreparacion).toString().replace(':', ''));
          } else{
            element.auxTiempoSinPreparacion = "0" +(this.myFunctions.secondsTo_HH_MM_SS(element.mejorTiempoSinPreparacion).toString().replace(':', ''));
          }
        }
      });
    } else if(this.tabSelected === 1){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas2.includes(element.id)){
          element.nuevoTiempoPreparacion = element.mejorTiempoPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.mejorTiempoPreparacion).split(":")[0])>=100){
            element.auxTiempoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.mejorTiempoPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.mejorTiempoPreparacion).toString().replace(':', '');
          }
        }
      });
    }
  }
  public btnTiempoPred() {
    if(this.tabSelected === 0){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas.includes(element.id)){
          element.nuevoTiempoSinPreparacion = element.tiempoPredictivoSinPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.tiempoPredictivoSinPreparacion).split(":")[0])>=100){
            element.auxTiempoSinPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.tiempoPredictivoSinPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoSinPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.tiempoPredictivoSinPreparacion).toString().replace(':', '');
          }
          
        }
      });
    } else if(this.tabSelected === 1){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas2.includes(element.id)){
          element.nuevoTiempoPreparacion = element.tiempoPredictivoPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.tiempoPredictivoPreparacion).split(":")[0])>=100){
            element.auxTiempoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.tiempoPredictivoPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.tiempoPredictivoPreparacion).toString().replace(':', '');
          }
        }
      });
    }
  }
  public btnTiempoMedio() {
    if(this.tabSelected === 0){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas.includes(element.id)){
          element.nuevoTiempoSinPreparacion = element.mediaTiempoSinPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.mediaTiempoSinPreparacion).split(":")[0])>=100){
            element.auxTiempoSinPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.mediaTiempoSinPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoSinPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.mediaTiempoSinPreparacion).toString().replace(':', '');
          }
        }
      });
    } else if(this.tabSelected === 1){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas2.includes(element.id)){
          element.nuevoTiempoPreparacion = element.mediaTiempoPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.mediaTiempoPreparacion).split(":")[0])>=100){
            element.auxTiempoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.mediaTiempoPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.mediaTiempoPreparacion).toString().replace(':', '');
          }
         
        }
      });
    }
  }
  public btnPeorTiempo() {
    if(this.tabSelected === 0){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas.includes(element.id)){
          element.nuevoTiempoSinPreparacion = element.peorTiempoSinPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.peorTiempoSinPreparacion).split(":")[0])>=100){
            element.auxTiempoSinPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.peorTiempoSinPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoSinPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.peorTiempoSinPreparacion).toString().replace(':', '');
          }
        }
      });
    } else if(this.tabSelected === 1){
      this.valoresGrid.forEach(element => {
        if (this.variablesSeleccionadas2.includes(element.id)){
          element.nuevoTiempoPreparacion = element.peorTiempoPreparacion;
          if (Number(this.myFunctions.secondsTo_HH_MM(element.peorTiempoPreparacion).split(":")[0])>=100){
            element.auxTiempoSinPreparacion = this.myFunctions.secondsTo_HH_MM_SS(element.peorTiempoPreparacion).toString().replace(':', '');
          } else{
            element.auxTiempoSinPreparacion = "0" + this.myFunctions.secondsTo_HH_MM_SS(element.peorTiempoPreparacion).toString().replace(':', '');
          }
        }
      });
    }
  }

  public focusOut(evt: any, dataItem: any){
    if(this.tabSelected === 0){
      //add seconds to time variable 
      var h = Number(this.valoresGrid[dataItem.id].auxTiempoSinPreparacion.toString().substring(0,3));
      var min = Number(this.valoresGrid[dataItem.id].auxTiempoSinPreparacion.toString().substring(3,5));
      var sec = Number(this.valoresGrid[dataItem.id].auxTiempoSinPreparacion.toString().substring(5,7));
      this.valoresGrid[dataItem.id].nuevoTiempoSinPreparacion = h *3600 + min*60 + sec;
      //add zeros
      if (h === 0 && min === 0 && sec === 0){
        //no hagas nada
      } else if (h<100){
        this.valoresGrid[dataItem.id].auxTiempoSinPreparacion = "0" + (this.myFunctions.secondsTo_HH_MM_SS(this.valoresGrid[dataItem.id].nuevoTiempoSinPreparacion).toString().replace(':', ''));
      } else{
        this.valoresGrid[dataItem.id].auxTiempoSinPreparacion = this.myFunctions.secondsTo_HH_MM_SS(this.valoresGrid[dataItem.id].nuevoTiempoSinPreparacion).toString().replace(':', '');
      }
    } else if(this.tabSelected === 1){
      //add seconds to time variable 
      var h = Number(this.valoresGrid[dataItem.id].auxTiempoPreparacion.toString().substring(0,3));
      var min = Number(this.valoresGrid[dataItem.id].auxTiempoPreparacion.toString().substring(3,5));
      var sec = Number(this.valoresGrid[dataItem.id].auxTiempoPreparacion.toString().substring(5,7));
      this.valoresGrid[dataItem.id].nuevoTiempoPreparacion = h *3600 + min*60 + sec;
      //add zeros
      if (h === 0 && min === 0 && sec === 0){
        //no hagas nada
      }
      else if (h<100){
        this.valoresGrid[dataItem.id].auxTiempoPreparacion = "0" + (this.myFunctions.secondsTo_HH_MM_SS(this.valoresGrid[dataItem.id].nuevoTiempoPreparacion).toString().replace(':', ''));
      } else{
        this.valoresGrid[dataItem.id].auxTiempoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(this.valoresGrid[dataItem.id].nuevoTiempoPreparacion).toString().replace(':', '');
      }
      
    } 
  }

  public redirect(dataItem: any){
    var aux = this.dataFiltro.filter(x => x.numeroOf === dataItem.numeroOf && x.nombrePieza === dataItem.nombrePieza && x.nombreParte === dataItem.nombreParte &&
      x.nombreOperacion === dataItem.nombreOperacion);
    aux = aux[0];
    this.router.navigate(['/operaciones/editar/' +  aux.idOf + '/' + aux.idPieza + '/'  + aux.idParte + '/' + dataItem.idRuta + '/' + aux.idOperacion + '/2']);
  }

  CambioFiltro() {
    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.nombrePieza));

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.nombreOperacion));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.nombreParte));

    var numeroPlanos = [];
    if (this.planosSeleccionados != undefined)
      this.planosSeleccionados.forEach(plano => numeroPlanos.push(plano.numeroPlano));


    var groupByCliente = [];
    var groupByPieza = [];
    var groupByOf = [];
    var groupByOperacion = [];
    var groupByParte = [];
    var groupByPlano = [];

    //GROUP BY OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
          && (numeroPlanos.includes(row.numeroPlano) || numeroPlanos[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
          && (numeroPlanos.includes(row.numeroPlano) || numeroPlanos[0] == undefined)
        ) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

    //GROUP BY PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
          && (numeroPlanos.includes(row.numeroPlano) || numeroPlanos[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    //GROUP BY POR OPERACION
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreOperacion]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (numeroPlanos.includes(row.numeroPlano) || numeroPlanos[0] == undefined)
        ) {
          lag[row.nombreOperacion] = {
            idOperacion: row.idOperacion, nombreOperacion: row.nombreOperacion,
          };
          groupByOperacion.push(lag[row.nombreOperacion]);
        }
      });

    this.listaOperaciones = groupByOperacion.filter(item => (item.nombreOperacion != ''));
    this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

    //GROUP BY PARTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreParte]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
          && (numeroPlanos.includes(row.numeroPlano) || numeroPlanos[0] == undefined)) {
          lag[row.nombreParte] = {
            idParte: row.idParte, nombreParte: row.nombreParte,
          };
          groupByParte.push(lag[row.nombreParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.nombreParte !== ""));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));


    //GROUP BY PLANO
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.numeroPlano]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)) {
          lag[row.numeroPlano] = {
            numeroPlano: row.numeroPlano,
          };
          groupByPlano.push(lag[row.numeroPlano]);
        }
      });

    this.listaPlanos = groupByPlano.filter(item => (item.numeroPlano != ''));
    this.listaPlanos.sort((a, b) => (a.numeroPlano > b.numeroPlano) ? 1 : ((b.numeroPlano > a.numeroPlano) ? -1 : 0));

  }

  seccionChanged() { //Cuando cambian las secciones desde el panel de usuario actualizar la web y actualizar el filtro (solo con las secciones por estar también asociadas al perfil del usuario)
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.JgruposMaquinasSelected) {
      this.JgruposMaquinasSelected.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }

    this.listaMaquinasMostradas = this.listaMaquinas.filter(f => {
      var enGrupo = false

      f.idsGrupos.forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });

      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });
  }

  cargarDatosFiltro(): void { //tomar datos para filtro

    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        this.listaMaquinas = mostra;
        this.seccionChanged();
      });

    //se toman los datos de los clientes, piezas, ofs y operaciones
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes().pipe(first()).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByOperacion = [];
      var groupByParte = [];
      var groupByPlano = [];

      //GROUP BY CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));;
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0))

      //GROUP POR PARTE
      data.forEach(function (a) {
        if (!this[a.nombreParte]) {
          this[a.nombreParte] = {
            idParte: a.idParte, nombreParte: a.nombreParte,
          };
          groupByParte.push(this[a.nombreParte]);
        }
      }, Object.create(null));

      this.listaPartes = groupByParte.filter(item => (item.idParte != -1 && item.nombreParte !== ""));
      this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

      //GROUP POR PIEZA
      data.forEach(function (a) {
        if (!this[a.nombrePieza]) {
          this[a.nombrePieza] = {
            idPieza: a.idPieza, nombrePieza: a.nombrePieza,
          };
          groupByPieza.push(this[a.nombrePieza]);
        }
      }, Object.create(null));

      this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

      //GROUP POR PLANO
      data.forEach(function (a) {
        if (!this[a.numeroPlano]) {
          this[a.numeroPlano] = {
            numeroPlano: a.numeroPlano,
          };
          groupByPlano.push(this[a.numeroPlano]);
        }
      }, Object.create(null));

      this.listaPlanos = groupByPlano;
      this.listaPlanos.sort((a, b) => (a.numeroPlano > b.numeroPlano) ? 1 : ((b.numeroPlano > a.numeroPlano) ? -1 : 0))

      //GROUP BY OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR OPERACION
      data.forEach(function (a) {
        if (!this[a.nombreOperacion]) {
          this[a.nombreOperacion] = {
            idOperacion: a.idOperacion, nombreOperacion: a.nombreOperacion,
          };
          groupByOperacion.push(this[a.nombreOperacion]);
        }
      }, Object.create(null));

      this.listaOperaciones = groupByOperacion.filter(item => (item.nombreOperacion != ''));
      this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));


      this.btnFiltrar();
    });

  }

  //VIRTUAIZATION
  public dataGrid1: any;
  public skip1 = 0;
  public dataGrid2: any;
  public skip2 = 0;

  public pageChange1(event: PageChangeEvent): void {
    this.skip1 = event.skip;
    this.loadProducts1();
  }

  private loadProducts1(): void {
    this.dataGrid1 = {
      data: this.valoresGrid.slice(this.skip1, this.skip1 + 50),
      total: this.valoresGrid.length,
    };
  }

  public pageChange2(event: PageChangeEvent): void {
    this.skip2 = event.skip;
    this.loadProducts2();
  }

  private loadProducts2(): void {
    this.dataGrid2 = {
      data: this.valoresGrid.slice(this.skip2, this.skip2 + 50),
      total: this.valoresGrid.length,
    };
  } 

}
