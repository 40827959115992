import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first, subscribeOn } from 'rxjs/operators';
import { UsuariosService, AlertService, MenuService, UsuariosTiposService, MaquinasService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { TranslateService } from '@ngx-translate/core';

import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';
import { TiposHerramientasDetalleComponent } from '@app/herramientas/tiposHerramientasDetalle/tiposHerramientasDetalle.component';
@Component({
  selector: 'app-eskola-docentes-detalle',
  templateUrl: './eskola_docentesDetalle.component.html'
})
export class Eskola_DocentesDetalleComponent implements OnInit {

  id: number;
  docente: any;
  isAddMode: boolean;
  loading: false;
  user = this.usuariosService.userValue;

  nombre: string;

  //validacion
  errorNombreVacio: boolean;
  errorNombreUsuarioVacio: boolean;
  errorApellido1Vacio: boolean;
  errorApellido2Vacio: boolean;
  errorEmailVacio: boolean;
  errorEmailNoValido: boolean;
  errorTelefonoVacio: boolean;
  errorContrasennaVacia: boolean;
  errorContrasennasNoCoinciden: boolean;
  errorTelefonoNoValido: boolean;
  errorConfirmarContrasenaVacia: boolean;
  errorContrasenna6caracteres: boolean;




  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private usuariosTiposService: UsuariosTiposService,
    private alertService: AlertService,
    private menuService: MenuService,
    private maquinasService: MaquinasService
  ) {


  }

  ngOnInit() {
    this.errorNombreVacio = false;
    this.errorNombreUsuarioVacio = false;
    this.errorApellido1Vacio = false;
    this.errorApellido2Vacio = false;
    this.errorEmailVacio = false;
    this.errorEmailNoValido = false;
    this.errorTelefonoVacio = false;
    this.errorContrasennaVacia = false;
    this.errorContrasennasNoCoinciden = false;
    this.errorConfirmarContrasenaVacia = false;
    this.errorTelefonoNoValido = false;
    this.errorContrasenna6caracteres = false;

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.docente = {
      
      id: -1,
      NombreUsuario: "",
      Nombre: "",
      Apellido1: "",
      Apellido2: "",
      Email: "",
      telefono: "",
      Activo: false,
      Password: "",
      ConfirmPassword: "",
      Usuarios_tiposId: 7,
      UsuarioTipo: "Docente",
      IdDb: this.user.idDb,
      Idioma: this.user.idioma,
      Tema: this.user.tema,
      MenuExpandido: this.user.menuExpandido,
      CondicionesAceptadas: true,
      GestionUsuarios: 1,
      administrador: false,
      activos:0,

    }


    if (!this.isAddMode) {
      this.usuariosService.getUsuarioDocente(this.id.toString()).subscribe(res => {
        this.docente = res[0];
        this.docente.id = res[0].id;
        this.docente.NombreUsuario = res[0].nombreUsuario;
        this.docente.Nombre = res[0].nombre;
        this.docente.Apellido1 = res[0].apellido1;
        this.docente.Apellido2 = res[0].apellido2;
        this.docente.Email = res[0].email;
        this.docente.Activo = res[0].activo;
        this.docente.Password ="";
        this.docente.ConfirmPassword = "";
        this.docente.Usuarios_tiposId = res[0].usuarios_TiposId;
        this.docente.UsuarioTipo = res[0].usuarioTipo;
        this.docente.IdDb = res[0].idDb;
        this.docente.Tema = res[0].tema;
        this.docente.MenuExpandido = res[0].menuExpandido;
        this.docente.CondicionesAceptadas = true;
        this.docente.GestionDocumentalService = res[0].gestionUsuarios;      
        this.docente.telefono = res[0].telefono;
        if(res[0].activo){
          this.docente.activos = 1;
        }else{
          this.docente.activos = 0;
        }
      });
    }

  }

  guardarClick() {
    // if (this.isAddMode) {
    //   if(this.docente.Email != ""){
    //     this.docente.NombreUsuario = this.docente.Email;
    //   }else{
    //     this.docente.NombreUsuario = this.docente.Nombre;
    //   }
      
    // }
    if (this.validar()) {
      if (this.isAddMode) {     
        this.docente.Password = this.docente.NombreUsuario;
        this.docente.ConfirmPassword = this.docente.NombreUsuario;
        debugger;
        this.usuariosService.create(this.docente).subscribe(res => {
          this.usuariosService.assignColour(res['id']).subscribe(r => {
            this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['../'], { relativeTo: this.route });
          });
        });
      } else {
        if(this.docente.Password == ""){
          this.usuariosService.updateDocente(this.docente).subscribe(res => {
            this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
            this.router.navigate(['../'], { relativeTo: this.route });
          });
        }else{
          this.usuariosService.update(this.docente).subscribe(res => {
            this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
            this.router.navigate(['../'], { relativeTo: this.route });
          });
        }
      }
    }    
  }

  validar(): boolean {
    this.errorNombreVacio = false;
    this.errorNombreUsuarioVacio = false;
    this.errorApellido1Vacio = false;
    this.errorApellido2Vacio = false;
    this.errorEmailVacio = false;
    this.errorEmailNoValido = false;
    this.errorTelefonoVacio = false;
    this.errorContrasennaVacia = false;
    this.errorConfirmarContrasenaVacia = false;
    this.errorContrasennasNoCoinciden = false;
    this.errorTelefonoNoValido = false;
    this.errorContrasenna6caracteres = false;
    var correcto = true;

    if (this.docente.NombreUsuario == "" || this.docente.NombreUsuario == undefined) {
      this.errorNombreUsuarioVacio = true;
      correcto = false;
    }

    if (this.docente.Nombre == "" || this.docente.Nombre == undefined) {
      this.errorNombreVacio = true;
      correcto = false;
    }
    if (this.docente.Apellido1 == "" || this.docente.Apellido1 == undefined) {
      this.errorApellido1Vacio = true;
      correcto = false;
    }
    // if (this.docente.Apellido2 == "" || this.docente.Apellido2 == undefined) {
    //   this.errorApellido2Vacio = true;
    //   correcto = false;
    // }

    // if (this.docente.Email == "" || this.docente.Email == undefined) {
    //   this.errorEmailVacio = true;
    //   correcto = false;
    // } else 
    if (this.docente.Email == "" || this.docente.Email == undefined) {
      this.errorEmailVacio = true;
      correcto = false;
    } else if (!this.docente.Email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      this.errorEmailNoValido = true;
      correcto = false;
    }

    if ((this.docente.telefono != "") && !this.docente.telefono.match("^\\+?[0-9]{9,12}$")) {
      this.errorTelefonoVacio = true;
      correcto = false;
    }


    // } else {
    //   if (!this.docente.telefono.match("^\\+?[0-9]{9,12}$")) {
    //     this.errorTelefonoNoValido = true;
    //     correcto = false;
    //   }

    
    debugger;
    if (this.isAddMode) {

      // if (this.docente.Password == "" || this.docente.Password == undefined) {
      //   this.errorContrasennaVacia = true;
      //   correcto = false;
      // } else if (this.docente.Password.length < 6) {
      //   this.errorContrasenna6caracteres = true;
      //   correcto = false;
      // } else if (this.docente.ConfirmPassword == "" || this.docente.Password == undefined) {
      //   this.errorConfirmarContrasenaVacia = true;
      //   correcto = false;
      // } else if (this.docente.Password != this.docente.ConfirmPassword) {
      //   this.errorContrasennasNoCoinciden = true;
      //   correcto = false;
      // }


    } else {
      if ((this.docente.Password == "" || this.docente.Password == undefined) && (this.docente.ConfirmPassword == "" || this.docente.Password == undefined)) {
        correcto = true;
      } else if (this.docente.Password == "" || this.docente.Password == undefined) {
        this.errorContrasennaVacia = true;
        correcto = false;
      } else if (this.docente.Password.length < 6) {
        this.errorContrasenna6caracteres = true;
        correcto = false;
      } else if (this.docente.ConfirmPassword == "" || this.docente.Password == undefined) {
        this.errorConfirmarContrasenaVacia = true;
        correcto = false;
      } else if (this.docente.Password != this.docente.ConfirmPassword) {
        this.errorContrasennasNoCoinciden = true;
        correcto = false;
      }

      return correcto;
    }

    return correcto;
  }


}
