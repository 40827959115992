import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/proveedores`;

@Injectable()
export class ProveedoresService {

  constructor(private http: HttpClient) {  }

  public getAll(): Observable<any> {
    return this.http.get(baseUrl);
  }

  GetById(id): Observable<any> {
    return this.http.get(`${baseUrl}/get_byId/${id}`, { withCredentials: true });
  }

  GetAllExceptSelected(id): Observable<any> {
    return this.http.get(`${baseUrl}/GetAllExceptSelected/${id}`, { withCredentials: true });
  }

  insertProveedor(nombre: string, descripcion: string, activo: boolean) {
    return this.http.post<JSON>(`${baseUrl}/insertProveedor`, { nombre: nombre, descripcion: descripcion,
    activo: activo }, { withCredentials: true });
  }
  updateProveedor(idProveedor: number, nombre: string, descripcion: string, activo: boolean) {
    return this.http.post<JSON>(`${baseUrl}/updateProveedor`, { idProveedor: idProveedor, nombre: nombre, descripcion: descripcion,
      activo: activo }, { withCredentials: true });
  }

  deleteProveedores(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/deleteProveedores`, { ids }, { withCredentials: true });
  }
}
