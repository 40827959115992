import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { DiccionarioPerdidasService } from '@app/_services/diccionarioPerdidas.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { SelectableSettings } from '@progress/kendo-angular-treelist';

import { Subscription, fromEvent, Observable } from 'rxjs'; // D&D
import { Renderer2, NgZone, AfterViewInit, OnDestroy, ViewChild } from '@angular/core'; // D&D
import { take } from 'rxjs/operators'; // D&D

@Component({
  selector: 'app-diccionario-perdidas',
  templateUrl: 'diccionarioPerdidas.component.html'
})

export class DiccionarioPerdidasComponent {
  public settings: SelectableSettings = {
    mode: "row",
    multiple: true,
    drag: false,
    enabled: true,
    readonly: false
  };

  public selected: any[] = [];
  private translate: TranslateService;
  public diccionarioperdidas: {};
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private diccionarioPerdidasService: DiccionarioPerdidasService,
    translate: TranslateService,
    private renderer: Renderer2,// D&D
    private zone: NgZone, // D&D
    public router: Router,
    private menuService: MenuService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('diccionarioPerdidas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/diccionarioperdidas') {
          this.diccionarioPerdidasService.GetAll().subscribe((result) => {
            this.diccionarioperdidas = result.data;
          }
          );
        } else {
        }
      }
    });
  }

  ngOnInit() {
    this.diccionarioPerdidasService.GetAll().subscribe((result) => {
      this.diccionarioperdidas = result.data;
      setTimeout(() => {
        this.setDraggableRows();
      }, 10); // Se tiene que cargar el grid antes de añadir el evento de D&D
    });
  }

  onClickNuevo() {
    this.router.navigate(['diccionarioperdidascrear/-1']);
  }

  onClickEditar() {
    if (this.selected[0].itemKey > 0) {
      this.router.navigate(['diccionarioperdidaseditar/' + this.selected[0].itemKey + "/-1"]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['diccionarioperdidaseditar/' + e.dataItem.id + "/-1"]);
    }
  }

  onClickEliminar(content) {
    if (this.selected.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    var auxSeleccionados = [];
    this.selected.forEach(element => auxSeleccionados.push(element.itemKey));
    this.diccionarioPerdidasService.delete({ ids: auxSeleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.diccionarioPerdidasService.GetAll().subscribe((result) => {
            this.diccionarioperdidas = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.selected = [];
  }


  //#region D&D
  private currentSubscription!: Subscription;
  public draggedRowEl!: HTMLTableRowElement;
  public draggedItem!: any; // MI CLASE
  public targetedItem;
  public newidPadre!: any;
  public isParentDragged: boolean = false;

  public setDraggableRows(): void {
    this.currentSubscription = this.handleDragAndDrop();
    const tableRows: HTMLTableRowElement[] = Array.from(document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody tr'));
    tableRows.forEach((row) => {
      6
      this.renderer.setAttribute(row, 'draggable', 'true');
    });
  }

  public onToggle(): void {
    this.zone.onStable.pipe(take(1)).subscribe(() => {
      this.currentSubscription.unsubscribe();
      this.setDraggableRows();
    });
  }
  private handleDragAndDrop(): Subscription {
    const table: HTMLElement[] = Array.from(document.querySelectorAll('.k-grid-content .k-grid-table-wrap tbody'));
    const sub = new Subscription(() => { });
    const dragStart: Observable<DragEvent> = fromEvent<DragEvent>(table, 'dragstart');
    const dragOver: Observable<DragEvent> = fromEvent<DragEvent>(table, 'dragover');
    const dragEnd: Observable<DragEvent> = fromEvent<DragEvent>(table, 'dragend');

    // 1 . START
    sub.add(
      dragStart.subscribe((e: DragEvent) => {
        this.draggedRowEl = <HTMLTableRowElement>e.target;
        if (this.draggedRowEl.tagName === 'TR') {
          this.draggedItem = this.findDataItem(this.diccionarioperdidas, this.draggedRowEl);
        }
      })
    );
    // 2 . MOSE OVER
    sub.add(
      dragOver.subscribe((e: DragEvent) => {
        e.preventDefault();
        this.removeDropHint(this.draggedRowEl);

        const element: HTMLElement = <HTMLElement>e.target;

        if (element.tagName === 'TD' || element.tagName === 'SPAN') {
          const currentRow = <HTMLTableRowElement>this.closest(element, this.tableRow);
          var a = this.findDataItem(this.diccionarioperdidas, currentRow);
          this.targetedItem = this.findDataItem(this.diccionarioperdidas, currentRow);

          // Prevent dragging parent row in its children
          let row = this.targetedItem;
          this.isParentDragged = false;

          var none = false;
          while (row!.idPadre! >= 1) {
            const parentRow = (this.diccionarioperdidas as any).find((item) => item.id === row!.idPadre);

            none = none || (parentRow!.id === this.draggedItem.id);

            row = parentRow;
          }
          none = none || (this.isSameRow(this.draggedItem, this.targetedItem));
          none = none || (this.draggedItem.idPadre != this.targetedItem.idPadre && this.draggedItem.idPadre > 0);

          if (this.isParentDragged || none) {
            e.dataTransfer!.dropEffect = 'none';
          } else {
            const containerOffest = { top: 0, left: 0 };
            this.getDropPosition(currentRow, e.clientY, containerOffest);
            this.draggedRowEl = currentRow;
          }
        }
      })
    );
    // 3 . DROP
    sub.add(
      dragEnd.subscribe((e: DragEvent) => {
        e.preventDefault();

        this.removeDropHint(this.draggedRowEl);

        if (this.draggedItem.id !== this.targetedItem.id &&
          (this.draggedItem.idPadre == this.targetedItem.idPadre || this.draggedItem.idPadre == null)) {

          var old_order = { orden: this.draggedItem.orden, suborden: this.draggedItem.suborden }; // Por si el original se actualiza, se guardan los ordenes originales

          var ordenOsuborden = 'orden'; if (this.draggedItem.suborden > 0) ordenOsuborden = 'suborden' // Para no replicar mismo codigo, se mira si tenemos que actualizar ordenes o subordenes

          var nuevoORden = this.targetedItem[ordenOsuborden]; //si es Befor es este, si es after es este +1
          var reducirOrden = nuevoORden < old_order[ordenOsuborden]; //si el orden ha subido o bajado

          // if(this.targetedItem.idPadre != null && this.draggedItem.idPadre == null) this.isAfter = false // Si se suelta en un padre sobre un hijo, se cuenta como soltado al final, siempre se pone despues del ultimo hijo de ese padre

          // Se hacen los desplazamientos necesarios.
          if (this.isAfter) nuevoORden += 1;
          if (!reducirOrden) nuevoORden -= 1;

          (this.diccionarioperdidas as any).forEach(
            perdida => {
              // Se actualizan los ordenes afectados
              if (perdida[ordenOsuborden] == old_order[ordenOsuborden]) perdida[ordenOsuborden] = nuevoORden;
              else if (perdida[ordenOsuborden] >= nuevoORden && perdida[ordenOsuborden] < old_order[ordenOsuborden] && reducirOrden) perdida[ordenOsuborden] = perdida[ordenOsuborden] + 1;
              else if (perdida[ordenOsuborden] > old_order[ordenOsuborden] && perdida[ordenOsuborden] <= nuevoORden && !reducirOrden) perdida[ordenOsuborden] = perdida[ordenOsuborden] - 1;

              // Se actualiza el texto a mostrar en el grid
              if (perdida.suborden == 0) perdida.ordengrid = perdida.orden;
              else perdida.ordengrid = perdida.suborden;
            });

          // Se fuerza una recarga del contenido del
          this.zone.run(() => (this.diccionarioperdidas = [...(this.diccionarioperdidas as any)]));

          this.diccionarioPerdidasService.update_ordenesBULK(this.diccionarioperdidas).subscribe((result) => { });
        }
      })
    );
    return sub;
  }

  public isBefore = false;
  public isAfter = false;
  public isOverTheSame = false;
  public getDropPosition(target: HTMLTableRowElement, clientY: number, containerOffset: { top: number; left: number }): void {
    const item: HTMLElement | null = this.closestWithMatch(target, '.k-grid-table-wrap tbody tr');
    const content: HTMLElement | null = this.getContentElement(item!);

    const itemViewPortCoords: DOMRect = content!.getBoundingClientRect();
    const itemDivisionHeight: number = itemViewPortCoords.height / 2;
    const pointerPosition: number = clientY - containerOffset.top;
    const itemTop: number = itemViewPortCoords.top - containerOffset.top;

    this.isBefore = pointerPosition < itemTop + itemDivisionHeight;
    this.isAfter = pointerPosition >= itemTop + itemViewPortCoords.height - itemDivisionHeight;
    this.isOverTheSame = this.draggedItem.id === this.targetedItem.id;

    if (this.targetedItem.idPadre != null && this.draggedItem.idPadre == null) this.isAfter = true; // Si se suelta en un padre sobre un hijo, se cuenta como soltado al final, siempre se pone despues del ultimo hijo de ese padre
    if (this.isAfter) this.isBefore = false; // Si es after, no puede ser before

    this.removeDropHint(this.draggedRowEl);
    this.focusRow(target);

    const draggedRowidPadre: any = this.draggedItem.idPadre;
    const currentRowidPadre: any = this.targetedItem.idPadre;

    if (this.isBefore) {
      this.showDropHint(target, 'before');
      this.reorderRows(0);
      if (draggedRowidPadre !== currentRowidPadre) {
        this.newidPadre = currentRowidPadre;
      }
    }

    if (this.isAfter) {
      this.showDropHint(target, 'after');
      this.reorderRows(1);
      if (draggedRowidPadre !== currentRowidPadre) {
        this.newidPadre = currentRowidPadre;
      }
    }

    if (!this.isBefore && !this.isAfter) {
      var a = 1;
      if (!this.isOverTheSame) {
        this.newidPadre = this.targetedItem.id;
      }
    }
  }



  public reorderRows(index: number): void {
    const draggedIndex: number = (this.diccionarioperdidas as any).indexOf(this.draggedItem);
    (this.diccionarioperdidas as any).splice(draggedIndex, 1);

    const targetedIndex: number = (this.diccionarioperdidas as any).indexOf(this.targetedItem);
    (this.diccionarioperdidas as any).splice(targetedIndex + index, 0, this.draggedItem);

    this.newidPadre = this.draggedItem.idPadre;
  }







  // UTILITIS

  tableRow = (node: HTMLElement) => node.tagName.toLowerCase() === 'tr';

  isSameRow = (draggedItem, targetedItem) => {
    return draggedItem.id === targetedItem.id;
  };

  closest = (node: Node | null, predicate: any) => {
    while (node && !predicate(node)) {
      node = node.parentNode;
    }
    return node;
  };

  match = (element: any, selector: string): boolean => {
    const matcher = element.matches || element.msMatchesSelector || element.webkitMatchesSelector;

    if (!matcher) {
      return false;
    }

    return matcher.call(element, selector);
  };

  closestWithMatch = (element: any, selector: string): HTMLElement | null => {
    if (!document.documentElement.contains(element)) {
      return null;
    }

    let parent = element;

    while (parent !== null && parent.nodeType === 1) {
      if (this.match(parent, selector)) {
        return parent;
      }

      parent = parent.parentElement || parent.parentNode;
    }

    return null;
  };

  isPresent: Function = (value: any): boolean => value !== null && value !== undefined;

  getContentElement = (parent: HTMLElement): HTMLElement | null => {
    if (!this.isPresent(parent)) {
      return null;
    }

    const selector = '.k-grid-table-wrap tbody tr';
    if (this.match(parent, selector)) {
      return parent;
    }

    return parent.querySelector(selector);
  };

  findDataItem = (data, row: HTMLTableRowElement) => {
    var id = row.cells[0].textContent!.trim();
    return data.find((item) => item.id.toString() == id);
  };

  focusRow = (row: HTMLTableRowElement) => {
    row.setAttribute('style', 'background-color: #c2bebe');
  };

  showDropHint = (row: HTMLTableRowElement, position: 'before' | 'after') => {
    let rowTds = row.querySelectorAll('td');
    if (position === 'before') {
      rowTds.forEach((td) => {
        td.setAttribute('style', 'border-top: solid 2px #0275d8');
      });
    }

    if (position === 'after') {
      rowTds.forEach((td) => {
        td.setAttribute('style', 'border-bottom: solid 2px #ff6358');
      });
    }
  };

  removeDropHint = (row: HTMLTableRowElement) => {
    row.removeAttribute('style');
    const tds = row.querySelectorAll('td');
    tds.forEach((td) => {
      td.removeAttribute('style');
    });
  };

  //#endregion

}
