import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/informeproyectos`;

@Injectable()
export class InformeProyectosService {

  constructor(private http: HttpClient) { }

  public Get_Information(fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_information/`, { fechaInicio, fechaFin }, { withCredentials: true });
  }

  public Get_Information_filtrado(fechaInicio: string, fechaFin: string, filtroCompleto: string) {
    return this.http.post<JSON>(`${baseUrl}/Get_information_filtrado/`, { fechaInicio, fechaFin, filtroCompleto }, { withCredentials: true });
  }

  public Get_ParteOculta() {
    return this.http.post<JSON>(`${baseUrl}/Get_ParteOculta/`, {}, { withCredentials: true });
  }

  public Get_ClientesPiezasOfsOperacionesPartes() { //al actualizar todos los filtros a monday, esta funcion deveria de terminar desapareciendo
    return this.http.post<JSON>(`${baseUrl}/Get_ClientesPiezasOfsOperacionesPartes/`, {}, { withCredentials: true });
  }
  
  public Get_ClientesPiezasOfsOperacionesPartes_entreFechas(fechaInicio, fechaFin) {
    return this.http.post<JSON>(`${baseUrl}/Get_ClientesPiezasOfsOperacionesPartes_entreFechas/`, { fechaInicio, fechaFin }, { withCredentials: true });
  }
  
  public Get_ClientesPiezasOfsOperacionesPartes_simple() {
    return this.http.post<JSON>(`${baseUrl}/Get_ClientesPiezasOfsOperacionesPartes_simple/`, {}, { withCredentials: true });
  }

  public Get_OfsPiezasOperacionesPartesRutas() {
    return this.http.post<JSON>(`${baseUrl}/Get_OfsPiezasOperacionesPartesRutas/`, {}, { withCredentials: true });
  }

  public Get_OfsPiezasOperacionesPartesRutasDesdeHistorico() {
    return this.http.post<JSON>(`${baseUrl}/Get_OfsPiezasOperacionesPartesRutasDesdeHistorico/`, {}, { withCredentials: true });
  }

  public Get_Maquinas() {
    return this.http.post<JSON>(`${baseUrl}/Get_Maquinas/`, {}, { withCredentials: true });
  }

  public Get_Herramientas() {
    return this.http.post<JSON>(`${baseUrl}/Get_Herramientas/`, {}, { withCredentials: true });
  }

  public Get_Operarios() {
    return this.http.post<JSON>(`${baseUrl}/Get_Operarios/`, {}, { withCredentials: true });
  }

  public Get_Operarios_simple() {
    return this.http.post<JSON>(`${baseUrl}/Get_Operarios_simple/`, {}, { withCredentials: true });
  }

  public Get_Informe_Of_Cliente_Pieza(fechaInicio, fechaFin, listaIdClientes, listaIdPiezas, listaIdOfs, terminados, idsSecciones) {
    return this.http.post<JSON>(`${baseUrl}/Get_Informe_Of_Cliente_Pieza/`, { fechaInicio: fechaInicio, fechaFin: fechaFin, listaIdClientes: listaIdClientes, listaIdPiezas: listaIdPiezas, listaIdOfs: listaIdOfs, terminados: terminados, idsSecciones: idsSecciones }, { withCredentials: true });
  }

  public Get_Informe_Of_Cliente_Pieza_Avanzado(fechaInicio, fechaFin, listaIdClientes, listaIdPiezas, listaIdOfs, listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios, listaIdPartes, listaIdOperaciones, listaIdNserie, terminados) {
    return this.http.post<JSON>(`${baseUrl}/Get_Informe_Of_Cliente_Pieza_Avanzado/`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaIdClientes: listaIdClientes, listaIdPiezas: listaIdPiezas, listaIdOfs: listaIdOfs,
      listaIdTurnos: listaIdTurnos, listaIdMaquinas: listaIdMaquinas, listaIdHerramientas: listaIdHerramientas,
      listaIdOperarios: listaIdOperarios, listaIdPartes: listaIdPartes, listaIdOperaciones: listaIdOperaciones, listaIdNserie: listaIdNserie,
      terminados: terminados
    }, { withCredentials: true });
  }
  public Get_Informe_Of_Cliente_Pieza_Avanzado_filtrado(filtroFechas, filtroCompleto) {
    return this.http.post<JSON>(`${baseUrl}/Get_Informe_Of_Cliente_Pieza_Avanzado_filtrado/`, { filtroFechas, filtroCompleto }, { withCredentials: true });
  }

  public Get_DatosInforme_Comparativa(fechaInicio, fechaFin, listaIdClientes, listaIdPiezas, listaIdOfs, listaIdPartes, listaIdOperaciones) {
    return this.http.post<JSON>(`${baseUrl}/Get_DatosInforme_Comparativa/`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaIdClientes: listaIdClientes, listaIdPiezas: listaIdPiezas, listaIdOfs: listaIdOfs,
      listaIdPartes: listaIdPartes, listaIdOperaciones: listaIdOperaciones
    }, { withCredentials: true });
  }

}
