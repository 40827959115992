import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { AtributosService, MenuService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-atributos-editar',
  templateUrl: './editarAtributos.component.html',
})

export class EditarAtributosComponent {

  //Variables basicas
  private translate: TranslateService;
  public idatributo: number;
  public atributo: any;

  public nombre = "";
  public idPadreSeleccionado: any = {};
  public idPadre = -1
  public nombreError = false;

  public atributos: any = []
  public atributosAux: any = [];

  //Constructor
  constructor(
    translate: TranslateService,
    private menuService: MenuService, 
    public router: Router,
    public route: ActivatedRoute,
    private atributosService: AtributosService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('atributo').toUpperCase();
    this.idatributo = parseInt(this.route.snapshot.params['id']);
  }

  /**
   * Funcion ngOnInit, cargar todos los datos
   */
  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    // Datos de las atributos creadas
    this.atributosService.getAtributos().subscribe(
      (result) => {
        this.atributosAux = this.myFunctions.copy(result);
        var atributos: any = result;

        //#region AGRUPAR LAS atributos
        this.atributos = this.atributosAux.filter(f => f.idPadre == null);
        var arrayHijos = this.atributosAux.filter(f => f.idPadre != null);
        while (arrayHijos.length != 0) {
          var idHijos = [];
          arrayHijos.forEach(element => {
            this.atributos.forEach(row => {
              if (row.id == element.idPadre || row.idSubHijos?.includes(element.idPadre)) {
                idHijos.push(element.id)
                if (!row['atributoInferior']) {
                  row.atributoInferior = [];
                  row.idSubHijos = [];
                }
                
                row.idSubHijos.push(element.id)
                if(row.id == element.idPadre) row.atributoInferior.push(this.myFunctions.copy(element));
                else row.atributoInferior = this.setHijos(row.atributoInferior, element);
              }
            });
          });
          arrayHijos = arrayHijos.filter(f => !idHijos.includes(f.id))
        }

        //#endregion

         this.cargaratributoById();

      });
  }

  // Funcion recursiva para encontrar el padre 
  setHijos(atributoInferiorArray, hijo) {
    atributoInferiorArray.every(element => {
      // si el elemento es el padre
      if (element.id == hijo.idPadre) {
        if (!element['atributoInferior']) {
           element.atributoInferior = []
           element.idSubHijos = [];
        }
        element.atributoInferior.push(this.myFunctions.copy(hijo))
        element.idSubHijos.push(hijo.id);
        return false;
      }
      // si el elemento contiene un hijo que sea su padre
      else if (element.idSubHijos?.includes(hijo.idPadre)) {
        element.atributoInferior = this.setHijos(element.atributoInferior, hijo)
        return false;
      }

      return true
    });

    return atributoInferiorArray;
  }

  cargaratributoById() {
    // Datos de la atributo guardada
    if (this.idatributo > 0) {
      this.atributosService.getAtributoById(this.idatributo).subscribe(
        (result) => {
          this.atributo = result[0];
          this.nombre = this.atributo.nombre;
          this.idPadreSeleccionado = this.atributosAux.filter(f => f.id == this.atributo.idPadre);
          if (this.idPadreSeleccionado.length > 0) {
            this.idPadreSeleccionado = this.idPadreSeleccionado[0];
            this.idPadre = this.idPadreSeleccionado.id;
          } else {
            this.idPadreSeleccionado = {id: -1, nombre: ''}
            this.idPadre = -1;
          }
        });
    }
  }

  cellClick(event) {
    if (event == undefined)
      this.idPadre = -1
    else  
      this.idPadre = event.id;
  }

  /**
   * Al clicar en el boton de Guardar, actualiza o inserta la atributo en la DB
   */
  onSubmit() {
    if (this.nombre.length == 0) {
      this.nombreError = true
      return;
    }

    // update
    if (this.idatributo > 0)
      this.atributosService.editarAtributo(this.idatributo, this.nombre, this.idPadre).subscribe(
        (result) => {
          console.log("atributo actualizada");
          this.router.navigate(['atributos']);
        });
    // insert
    else
      this.atributosService.crearAtributo(this.nombre, this.idPadre).subscribe(
        (result) => {
          console.log("atributo añadida");
          this.router.navigate(['atributos']);
        });

  }

  groupBy (xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
}