import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/atributos`;

@Injectable()
export class AtributosService {

  constructor(private http: HttpClient) {}

  getAtributos() {
    return this.http.get(baseUrl);
  }
  getAtributoById(id: number) {
    return this.http.get(baseUrl+"/"+id);
  }
  getSubatributosByAtributoId(id: number) {
    return this.http.get(baseUrl+"/sub/"+id);
  }
  getComboAtributos() {
    return this.http.post<JSON>(`${baseUrl}/getComboAtributos`, {}, { withCredentials: true })
  }
  crearAtributo(nombre, idPadre) {
    return this.http.post<any>(`${baseUrl}/crear`, {nombre, idPadre}, { withCredentials: true });
  }
  editarAtributo(id, nombre, idPadre) {
    return this.http.post<JSON>(`${baseUrl}/editar`, {id, nombre, idPadre}, { withCredentials: true });
  }
  removeAtributo(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/remove`, { ids }, { withCredentials: true });
  }
  GetMaquinasAtributosById(idMaquina: number) {
    return this.http.post<JSON>(`${baseUrl}/getMaquinasAtributosById`, { idMaquina }, { withCredentials: true });
  }
  GetAtributosDiccionarioPerdidas(id: number) {
    return this.http.post<JSON>(`${baseUrl}/GetAtributosDiccionarioPerdidas`, { id }, { withCredentials: true });
  }
}
