<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)">


  <div class="row">

    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <div class="card">

        <div class="card-body">

          <div class="row">
            <!--NOMBRE-->
            <div class="form-group col">

              <div class="caja">
                <kendo-label text="{{ 'nombre' | translate }}">
                  <kendo-textbox formControlName="nombre" maxlength="80" [class.is-invalid]="submitted && f.nombre.errors"></kendo-textbox>
                  <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">{{ 'nombreEsrequerido' | translate}}</div>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--PRINCIPAL-->
            <div class="form-group col">
              <label class="control-label">{{ 'principal' | translate }}</label>
              <div class="caja">
                <kendo-switch formControlName="principal" [onLabel]="' '" [offLabel]="' '" [disabled]="esPrincipal"></kendo-switch>
              </div>
            </div>

            <!--ID ERP-->
            <div class="form-group col">
              <label class="control-label">{{ 'iderp' | translate }}</label>
              <div class="caja">
                <kendo-textbox maxlength="50" formControlName="idERPRuta" [value]="idERPRuta"></kendo-textbox>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isAddMode==false">
    <kendo-tabstrip>
      <kendo-tabstrip-tab id="taboperaciones" [title]="operacionessstr" [selected]="true">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [height]="410" [data]="operaciones" (cellClick)="cellClick($event)"   kendoGridSelectBy="id"
            [navigable]="true" filterable="menu"
            [sortable]="true" [resizable]="true"
            [selectedKeys]="operacionesseleccionados">
              <ng-template kendoGridToolbarTemplate position="top">
                <button id="btneditar" [disabled]="!botonesActivados" Class="btnEditatu btn btn-success btn-sm mr-1" (click)="onClickEditarOperacion()">{{
                  'editar' | translate }}</button>
                <a id="btnnuevo" (click)="onClickNuevaOperacion($event)" Class="btnBerria btn btn-primary btn-sm mr-1">{{
                  'nuevo' | translate }}</a>
                <a id="btnnuevopred" *ngIf="!isMultiple" (click)="onClickNuevaOperacionPredefinida()"
                  Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'anadirpred' | translate }}</a>
                <button id="btneliminar" [disabled]="!botonesActivados || this.user.proyectos<2" Class="btnEzabatu btn btn-danger btn-sm mr-1"
                  (click)="onClickEliminarOperacion(content, $event)">{{ 'eliminar' | translate }}</button>
                <button id="btnexportar" [disabled]="!botonesActivados" kendoGridExcelCommand Class="btnExcel btn btn-excel btn-sm mr-1">{{
                  'exportarexcel' | translate }}</button>
              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
            <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="16%">
            </kendo-grid-column>
            <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="16%"
            class="celda-tooltip-externo">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.maquina!=null && dataItem.maquinasIm!=null" class="tooltip-consumibles-contenido">
                  <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                    <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo"
                      style="background-color:white!important;"
                      [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                  </ng-container>
                  <span class="tooltiptext">
                    <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                      <label *ngIf="maquina!='undefined'">
                        {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
                      </label>
                      <label *ngIf="maquina=='undefined'">
                        {{'desconocido' | translate}}
                      </label>
                    </ng-container>
                  </span>
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
            </kendo-grid-column>
            <kendo-grid-column field="fechaEntrega" title="{{ 'fechaentrega' | translate }}" width="10%" [headerStyle]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaEntrega | kendoDate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%"> </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}" width="17%"> </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}" width="17%"> </kendo-grid-column>
            <kendo-grid-column field="hechos" title="{{ 'hechos' | translate }}" width="8%"> </kendo-grid-column>
            <kendo-grid-column field="terminado" width="5%" title="{{ 't' | translate }} " [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" [checked]="dataItem.terminado" disabled />
              </ng-template>
            </kendo-grid-column>
              <kendo-grid-excel fileName="{{ 'operaciones' | translate }}.xlsx"  [fetchData]="allData">
                <kendo-excelexport-column field="orden" title="{{ 'orden' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="secuencia" title="{{ 'secuencia' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="operacion" title="{{ 'operacion' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tipo2" title="{{ 'tipoOperacion' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="fechaEntrega" title="{{ 'fechaentrega' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="hechos" title="{{ 'hechos' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="terminado" title="{{ 'terminado' | translate }}"> </kendo-excelexport-column>
              </kendo-grid-excel>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab id="tabhistoricocambios" [title]="historicocambiosstr">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [height]="410" [data]="historicocambios"
            [navigable]="true" filterable="menu"
            [sortable]="true" [resizable]="true"
            >
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="90">
            </kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="20%">
            </kendo-grid-column>
            <kendo-grid-column field="fechaEntrega" title="{{ 'operador' | translate }}" width="20%">
            </kendo-grid-column>
            <kendo-grid-column field="campo" title="{{ 'campo' | translate }}" width="10%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.campo.split("_")[0] | translate }} {{ dataItem.campo.split("_")[1] }} 
                  {{ dataItem.campo.split("_")[2] | translate }} {{ dataItem.campo.split("_")[3] | translate }}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="valorAnterior" title="{{ 'valoranterior' | translate }}" width="15%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.valorAnteriorTrad == 'traducir'">{{ dataItem.valorAnterior | translate}}</span>
                <span *ngIf="dataItem.valorAnteriorTrad == 'fecha'">{{ dataItem.valorAnterior | kendoDate}}</span>
                <span *ngIf="dataItem.valorAnteriorTrad != 'traducir' && dataItem.valorAnteriorTrad != 'fecha'">{{ dataItem.valorAnterior}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="valorNuevo" title="{{ 'valornuevo' | translate }}" width="15%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.valorNuevoTrad == 'traducir'">{{ dataItem.valorNuevo | translate}}</span>
                <span *ngIf="dataItem.valorNuevoTrad == 'fecha'">{{ dataItem.valorNuevo | kendoDate}}</span>
                <span *ngIf="dataItem.valorNuevoTrad != 'traducir' && dataItem.valorNuevoTrad != 'fecha'">{{ dataItem.valorNuevo}}</span>
              </ng-template>
            </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate }}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span>{{ dataItem.fecha | kendoDate }} {{ dataItem.fecha | kendoDate:'HH:mm:ss' }}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab id="tabbom" [title]="bomstr">
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [height]="410" [data]="bom" 
            [navigable]="true" filterable="menu"
            [sortable]="true" [resizable]="true">
              <ng-template kendoGridToolbarTemplate position="top" *ngIf="!tenemosBOM">
                <kendo-upload (upload)="onUploadBOMButtonClick($event,contentloadingupload)">
                  <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                    clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                    dropFilesHere="{{ 'dropFilesHere' | translate }}"
                    externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                    fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                    fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                    filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                    filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                    filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                    headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                    headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                    headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                    invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                    invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                    invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                    remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                    retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                    uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-upload>

              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="3%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="id" hidden="hidden" title="ID" width="35%">
              </kendo-grid-column>
              <kendo-grid-column field="referencia" title="{{ 'referencia' | translate }}" width="15%"
                [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
              </kendo-grid-column>
              <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="37%"
                [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
              </kendo-grid-column>
              <kendo-grid-column field="fichero" title="{{ 'fichero' | translate }}" width="15%"
                [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
              </kendo-grid-column>
              <kendo-grid-column field="necesarios" title="{{ 'necesarios' | translate }}" width="15%"
                [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="usados" title="{{ 'usados' | translate }}" width="10%"
                [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'right'}">
              </kendo-grid-column>
              <kendo-grid-column field="resto" title="{{ 'resto' | translate }}" [headerStyle]="{'text-align': 'left'}"
                [style]="{'text-align': 'right'}" width="10%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab id="tabgestiondocumental" [title]="gestiondocumentalstr" >
        <ng-template kendoTabContent>
          <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
            (mouseover)="showTooltip($event)">
            <kendo-grid [kendoGridBinding]="gestiondocumental" [sortable]="true" kendoGridSelectBy="id"
              [navigable]="true" filterable="menu" (cellClick)="onCellClickGestionDocumental($event)"
              [selectedKeys]="mySelectionGestionDocumental">
              <ng-template kendoGridToolbarTemplate position="top">
                <button kendoButton (click)="onClickEditarGestionDocumental($event)"
                  class="btn mr-1  btn-success btn-sm mr-1"> {{
                  'editar' | translate}} </button>
                <button kendoButton (click)="onClickNuevoGestionDocumental($event)" class="btn mr-1  btn-primary btn-sm mr-1">
                  {{
                  'nuevo' | translate}} </button>
                <button kendoButton (click)="onClickEliminarGestionDocumental($event)"
                  class="btn mr-1  btn-danger btn-sm mr-1" 
                  [disabled]="loadingGestionDocumental || (gestiondocumental && gestiondocumental.length == 0) || this.user.proyectos<2"> {{ 'eliminar' |
                  translate}} </button>
              </ng-template>
              <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
              <kendo-grid-column width="40%" field="nombre" title="{{ 'nombre' | translate}}">
              </kendo-grid-column>
              <kendo-grid-column width="40%" field="fichero" title="{{ 'fichero' | translate}}">
              </kendo-grid-column>
             <!--  <kendo-grid-column [style]="{'text-align': 'right'}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn-icon" (click)="viewPDF(dataItem)"><span
                      class="k-icon k-i-file-pdf"></span></button>
                </ng-template>
              </kendo-grid-column> -->
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>

          <div *ngIf="loadingGestionDocumental" class="k-i-loading"></div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

  <div class="form-group">

      <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.proyectos<2">
      {{ 'guardar' | translate}}
    </button>
    <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="Atras()">
      {{ 'cancelar' | translate}}
    </button>
    <button type="submit" *ngIf="isAddMode==false" class="btn btn-success btn-sm mr-1"  [disabled]="this.user.proyectos"
      (click)="onClickDuplicarOperacion(contentloading)">
      {{ 'duplicar' | translate}}
    </button>
  </div>
</form>

<ng-template #content let-modal>
  <form (submit)="onSubmit($event)">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' |
        translate }}</button>
      <button type="button" class="btn btn-danger" (click)="Eliminar(contentloading)" [disabled]="this.user.proyectos<2">{{ 'si' | translate
        }}</button>
    </div>
  </form>
</ng-template>

<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup"></p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'popup.eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<ng-template #contentloadingupload let-modal>

  <div class="modal-body">
    <p>{{ 'importando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'importando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<ng-template #anadirOperacionPred let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid style="height: 780px" [kendoGridBinding]="operacionesPred" [sortable]="true" [navigable]="true"
                filterable="menu" [resizable]="true" kendoGridSelectBy="id" [selectedKeys]="operacionesPredSelected"
                scrollable="virtual" [rowHeight]="36" [pageSize]="50" [height]="500">
                <kendo-grid-checkbox-column showSelectAll="true" width="5%"></kendo-grid-checkbox-column>
                <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
                <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="16%">
                </kendo-grid-column>
                <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="16%"
                class="celda-tooltip-externo">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.maquina!=null" class="tooltip-consumibles-contenido">
                      <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                        <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo"
                          style="background-color:white!important;"
                          [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                        <span *ngIf="dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined'" class="grid-circulo"
                          [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
                      </ng-container>
                      <span class="tooltiptext">
                        <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                          <label *ngIf="maquina!='undefined'">
                            {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
                          </label>
                          <label *ngIf="maquina=='undefined'">
                            {{'desconocido' | translate}}
                          </label>
                        </ng-container>
                      </span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
                </kendo-grid-column>
                <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%"> </kendo-grid-column>
                <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}" width="17%"> </kendo-grid-column>
                <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}" width="17%"> </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click');onClickCancelar()">{{ 'cancelar'
      | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="onClickAceptarAnadirPred()" [disabled]="this.user.proyectos<2">{{ 'aceptar' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #popupBorrarDocumentos let-modal>
  <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <label>{{'preguntaeliminarpopup' | translate }}</label>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="btnBorrarDocumentos()" [disabled]="this.user.proyectos<2">{{ 'aceptar' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
          }}</button>
  </div>
</ng-template>