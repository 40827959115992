<form [formGroup]="form">
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-6">
    <div class="card">
      <div class="card-header">
        <h3 *ngIf="isAddMode">{{ 'creartipotolerancia' | translate}}</h3>
        <h3 *ngIf="!isAddMode">{{ 'editartipotolerancia' | translate}}</h3>
      </div>
      <div class="card-body">
            <div class="form-group">
              <label class="control-label">{{ 'nombre' | translate}}</label>
              <div class="caja">
                <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' | translate}}</div>
                </div>
              </div>
            </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label class="control-label">{{ 'tipoValor' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist formControlName="idTipoValor" [data]="toleranciasTipoValorItems" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="toleranciasTipoValor_selectionChange($event)" [value]="toleranciasTipoValorItems[0]" class="form-control">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label class="control-label">{{ 'toleranciatipo' | translate }}</label>
                    <div class="caja">
                      <kendo-dropdownlist formControlName="idTipoTolerancia" [data]="toleranciasTipoItems" [textField]="'nombre'"  [valueField]="'id'" (selectionChange)="toleranciasTipo_selectionChange($event)" [value]="toleranciasTipoItems[0]" class="form-control">
                      </kendo-dropdownlist>
                    </div>
                  </div>
                </div>
              </div>
              <!--IMAGEN VIEJA-->
              <div class="form-group">
                <label class="control-label">{{ 'simbolo' | translate }}</label>
                <div>
                    <img [src]="imageToShow" style="max-height: 113px">
                </div>
                  <div class="caja">
                    <kendo-fileselect formControlName="simbolo" [restrictions]="restrictions" [multiple]="false" (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                      <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                              clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                              dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                              externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                              fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                              fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                              filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                              filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                              filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                              headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                              headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                              headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                              invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                              invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                              invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                              pause="{{ 'pause' | translate }}"
                                              remove="{{ 'remove' | translate }}"
                                              resume="{{ 'resume' | translate }}"
                                              retry="{{ 'retry' | translate }}"
                                              select="{{ 'select' | translate }}"
                                              uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                      </kendo-upload-messages>
                    </kendo-fileselect>
                  </div>
              </div>
              <div class="form-group">
                  <label class="control-label">{{ 'descripcion' | translate}}</label>
                  <div class="caja">
                      <textarea kendoTextArea style="resize: none;" rows="5" formControlName="descripcion" maxlength='240'>
                      </textarea>
                  </div>
              </div>
              <div class="form-group">
                <label class="control-label">{{ 'activo' | translate}}</label>
                <div class="caja">
                    <kendo-switch formControlName="activo" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                </div>
              </div>
      </div>
    </div>
    <div class="form-group">
      <button kendoButton [disabled]="loading || this.user.tolerancias < 2" (click)="onSubmit()" class="btn mr-1  btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ 'guardar' | translate}}
      </button>
      <a routerLink="/toleranciastipo" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
    </div>
    </div>
    </div>
</form>