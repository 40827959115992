import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { MyFunctions } from '@app/_helpers/';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, Eskola_CursosLectivosService, UsuariosService } from '@app/_services';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { LanguageServiceMode } from 'typescript';
import { TimeSelectorComponent } from '@progress/kendo-angular-dateinputs';
import { JsonPipe } from '@angular/common';
import { NumericFilterCellComponent } from '@progress/kendo-angular-treelist';
import { async } from 'rxjs';

@Component({
    selector: 'app-cursosLectivosDetalle',
    templateUrl: './eskola_cursosLectivosDetalle.component.html'
})
export class Eskola_CursosLectivosDetalleComponent implements OnInit {

    navigationSubscription;

    private translate: TranslateService;
    form: FormGroup;
    public id: number;
    isAddMode: boolean;
    loading = false;
    submitted = false;

    tipoOeeLista: any;
    tipoOeeSeleccionado: any;
    user = this.userService.userValue;

    public activo: boolean;

    public cursos: any = [];
    public cursosLectivos: any = [];
    public horariosCurso: any = [];
    mySelection: number[] = [];
    mySelectionAux: number[] = [];
    curso: any;
    horarioPorDefecto: any;
    public dateAux: any = [];
    fechaIni: string;
    fechaF: string;
    public horariosEliminados: number[] = [];
    public diasSemanaDropdown: any[] = [{ diaN: this.translateService.instant('lunes'), value: 1, },
    { diaN: this.translateService.instant('martes'), value: 2, },
    { diaN:this.translateService.instant('miercoles'), value: 3 },
    { diaN:this.translateService.instant('jueves'), value: 4, },
    { diaN: this.translateService.instant('viernes'), value: 5 },
    { diaN: this.translateService.instant('sabado'), value: 6 },
    { diaN: this.translateService.instant('domingo'), value: 7 }];

    public JDiaSemanaSelected: any;
    public timePickerDisabled: boolean = true;



    //Variables de validacion
    errorNombreVacio: boolean;
    errorFechasVacias: boolean;
    errorHorasExisten: boolean;
    errorHoras: boolean;
    DaysInMonths: any[];

    constructor(
        private myFunctions: MyFunctions,
        private cursosLectivosService: Eskola_CursosLectivosService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private alertService: AlertService,
        private userService: UsuariosService,
        private menuService: MenuService,
        private renderer: Renderer2
    ) {

        this.menuService.titulo = this.translateService.instant('cursoslectivos').toUpperCase();
        //this.cargarDatos()
    }

    async ngOnInit() {
        //Inicializacion variables
        this.errorNombreVacio = false;
        this.errorFechasVacias = false;
        this.errorHorasExisten = false;
        this.errorHoras = false;
        //Creamos objeto docentes auxiliar
        this.curso = { id: -1, nombre: '', inicio: undefined, fin: undefined, observaciones: '' };
        this.horarioPorDefecto = {
            id: -1,
            id_cursoLectivo: this.curso.id,
            horaInicio: new Date(2000, 1, 1, 0o0, 0o0, 0),
            horaFin: new Date(2000, 1, 1, 0o0, 0o0, 0),
            dia: null,
            diaN: this.translateService.instant('seleccionar'),
            diaItem: { diaN: this.translateService.instant('seleccionar'), value: null }
        }
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            nombre: new FormControl('', [Validators.required, Validators.maxLength(200)]),
            docentes: new FormControl('', [Validators.required, Validators.maxLength(200)]),
            activo: [false,],
        });



        if (!this.isAddMode) {
            await this.getCurso(this.id);
            this.getHorarios(this.id);
        } else {
            this.annadirLinea();
        }
    }


    public rowCallback(context: RowClassArgs) {
        return {
            dragging: context.dataItem.dragging
        };
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        var correcto: boolean=true;
        this.submitted = true;
        this.errorFechasVacias= false;
        this.errorHoras = false;
        this.errorHorasExisten = false,
        this.errorNombreVacio = false;

        // reset alerts on submit
        this.alertService.clear();

        //form text validator
        if (this.curso.nombre == null || this.curso.nombre == undefined || this.curso.nombre == '') {
            this.errorNombreVacio = true;
            correcto=false;
        }
        if (this.curso.inicio == null || this.curso.inicio == undefined || this.curso.inicio == '') {
            this.errorFechasVacias = true;
            correcto=false;
        }
        if (this.curso.fin == null || this.curso.fin == undefined ||this.curso.fin == '') {
            this.errorFechasVacias = true;
            correcto=false;
        }

        if(this.horariosCurso.length>1){
        for(let j = 0; j<this.horariosCurso.length-1;j++){
            var horario = this.horariosCurso[j];
            //comprobamos que las horas estén bien
            if(horario.horaInicio>=horario.horaFin){
                
                this.errorHoras = true;
                correcto=false;
                break;
            }
            //comprobamos que ningun periodo interfiera con las horas de otro 
            for(let i = j+1; i<this.horariosCurso.length;i++){
                if(this.horariosCurso[i].horaInico>=this.horariosCurso[i].horaFin){
                    this.errorHoras = true;
                    correcto=false;
                    break;
                 
                }      
                if(this.horariosCurso[i].dia==horario.dia){
                    console.log("+++++++++++++++++++++++++++++++++")
                    console.log(horario);
                    console.log(this.horariosCurso[i])
                    if(this.horariosCurso[i].horaFin > horario.horaInicio){
                        if(this.horariosCurso[i].horaInicio < horario.horaFin){
                            this.errorHorasExisten = true;
                            correcto = false;
                            break;
                        }                            
                    }else if(this.horariosCurso[i].horaInicio<horario.horaFin){
                        if(this.horariosCurso[i].fin > horario.horaInicio){
                            this.errorHorasExisten = true;
                            correcto = false;
                            break;
                        }

                    }
                }
            }
        }
        }
        
        if(!correcto){
            console.log("--------nombre");
            console.log(this.errorNombreVacio);
            console.log("--------fechas");
            console.log(this.errorFechasVacias);
            console.log("--------periodomal");
            console.log(this.errorHoras);
            console.log("---------horasinter");
            console.log(this.errorHorasExisten);
            return;
        }

        this.loading = true;
        if (this.isAddMode) {
            this.crearCursoLectivo();
        } else {
            this.updateCursoLectivo();
        }

    }

    nombreChange(e) {
        this.curso.nombre = e.target.value;
    }
    diaChange(e, h) {
        var lagDia = h.dia;
        //actualizar valores de la linea actual
        h.dia = e.value;
        h.diaN = e.diaN;
        //añadir linea vacia al final
        if (lagDia == null) {
            this.annadirLinea();
        }
    }
    observacionChange(e) {
        this.curso.observaciones = e.target.value;
    }

    private crearCursoLectivo() {
        this.cursosLectivosService.create(-1, this.curso.nombre, this.curso.inicio, this.curso.fin, this.curso.observaciones).subscribe(json=>{
                if(!json.error){
                    // el id del horario que acabamos de insertar
                    var ultimoId:number = json.id; 
                    // quitamos el último horario porque está vacío                
                    if (this.horariosCurso.length > 0) {
                        this.horariosCurso.forEach(horario => {
                            if(horario.dia!=null){
                            horario.id_cursoLectivo =ultimoId;
                            this.insertHorario(horario);
                            }
                        });
                    }
                    this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                    this.router.navigate(['eskola_cursoslectivos/']);
                    this.loading = false;
                }else{
                    this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                    this.router.navigate(['eskola_cursoslectivos/']);
                    this.loading = false;
                }
            });
    }

    private updateCursoLectivo() {

        this.cursosLectivosService.update(this.curso.id, this.curso.nombre, this.curso.inicio, this.curso.fin, this.curso.observaciones).subscribe({
            next: () => {
                //quitamos el últmo horario porque está vacío
                this.horariosCurso.pop();
                if (this.horariosCurso.length > 0) {
                    this.horariosCurso.forEach(horario => {
                        if(horario.id==-1){
                            // para insertar
                            horario.id_cursoLectivo = this.curso.id;
                            this.insertHorario(horario);
                        }else{
                            //para actualizar
                            this.updateHorario(horario);
                        }                       
                    });
                }
                if (this.horariosEliminados.length > 0) {
                    this.horariosEliminados.forEach(horarioId => {
                        this.eliminarHorario(horarioId);
                    });
                    //vacíamos lista de horarios eliminados
                    this.horariosEliminados = [];
                }
                this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;
            },
            error: error => {
                this.alertService.error(error);
                this.loading = false;
            }
        });

        this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
        this.router.navigate(['eskola_cursoslectivos/']);
        this.loading = false;
    }
    private updateHorario(horario) {

        this.cursosLectivosService.updateHorario(horario.id, horario.dia, horario.horaInicio, horario.horaFin).subscribe(json => {
            if (json.error) {
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;

            } else {
                this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;
            }
        });
    }
    private insertHorario(horario) {

        this.cursosLectivosService.insertHorario(horario.id_cursoLectivo, horario.dia, horario.horaInicio, horario.horaFin, false).subscribe(json => {
            if (json.error) {
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;

            } else {
                this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;
            }
        });
    }
    private eliminarHorario(id) {
        this.cursosLectivosService.deleteHorario(id).subscribe(json => {
            if (json.error) {
                this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;
            } else {
                this.alertService.success(this.translateService.instant('actualizadocorrecto'), { keepAfterRouteChange: true });
                this.router.navigate(['eskola_cursoslectivos/']);
                this.loading = false;
            }
        });
    }
    async getCurso(id) {
        this.cursosLectivosService.getById(id).subscribe(json => {
            this.cursosLectivos = json;
            this.curso = this.cursosLectivos[0];
            this.curso.inicio = new Date(json[0].inicio);
            this.curso.fin = new Date(json[0].fin);

            this.horarioPorDefecto = {
                id: -1,
                id_cursoLectivo: json[0].id,
                horaInicio: new Date(2000, 1, 1, 0o0, 0o0, 0),
                horaFin: new Date(2000, 1, 1, 0o0, 0o0, 0),
                dia: null,
                diaN: this.translateService.instant('seleccionar'),
                diaItem: { diaN: this.translateService.instant('seleccionar'), value: null }
            }


        });
    }
    async getHorarios(id) {
        this.cursosLectivosService.getHorariosDelCurso(id).subscribe(result => {
            this.horariosCurso = result;
            this.horariosCurso.forEach(element => {
                if (element.dia == 1) {
                    element.diaN = this.translateService.instant('lunes');
                } else if (element.dia == 2) {
                    element.diaN = this.translateService.instant('martes');
                } else if (element.dia == 3) {
                    element.diaN = this.translateService.instant('miercoles');
                } else if (element.dia == 4) {
                    element.diaN = this.translateService.instant('jueves');
                } else if (element.dia == 5) {
                    element.diaN = this.translateService.instant('viernes');
                }
                element.diaItem = { diaN: element.diaN, value: element.dia }
                element.horaInicio = new Date(2000, 1, 1, element.horaInicio.split(":")[0], element.horaInicio.split(":")[1], 0);
                element.horaFin = new Date(2000, 1, 1, element.horaFin.split(":")[0], element.horaFin.split(":")[1], 0);
            });
            //añadir linea vacia al final
            this.annadirLinea();

            console.log(this.horariosCurso);

        });
    }
    annadirLinea() {
        let nuevoHorario = {
            id: -1,
            id_cursoLectivo: this.curso.id,
            horaInicio: new Date(2000, 1, 1, 0o0, 0o0, 0),
            horaFin: new Date(2000, 1, 1, 0o0, 0o0, 0),
            dia: null,
            diaN: this.translateService.instant('seleccionar'),
            diaItem: { diaN: this.translateService.instant('seleccionar'), value: null }
        }
        this.horariosCurso.push(nuevoHorario);
    }
    annadirEliminado(id) {
        if (id != -1) {
            // si el horario ya existe el la base de datos añadirlo a la lista para eliminar
            this.horariosEliminados.push(id);
        }
        let index = this.horariosCurso.findIndex(d => d.id === id);
        this.horariosCurso.splice(index, 1);


    }
    actualizarFechaInicio(valueEmitted) {
        if (this.curso.inicio != valueEmitted) {
            this.curso.inicio = valueEmitted;

        }
    }
    actualizarFechaFin(valueEmitted) {
        if (this.curso.fin != valueEmitted) {
            this.curso.fin = valueEmitted;

        }
    }
    cargarMeses() {
        this.DaysInMonths = [];
        //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
        this.DaysInMonths.push(this.myFunctions.getDateNow());
    }

}
