import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, InformeOeeService, MenuService, MaquinasService, InformeProyectosService, OperariosService, HistoricoMaquinasDatosService } from '@app/_services';
import { MyFunctions, MyFilter, MyCharts } from '@app/_helpers';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { createUnparsedSourceFile } from 'typescript';

@Component({
  selector: 'app-informeoee-data',
  templateUrl: 'informeOEE_V2.html'
})

export class InformeOEEComponent_V2 implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  user = this.userService.userValue;

  loading: boolean = true;

  // private dataGraficoDonutOEE: any;
  // private dataGraficoDonutDisponibilidad: any;
  // private dataGraficoDonutRendimiento: any;
  // private dataGraficoDonutCalidad: any;
  // private dataGraficoEvolucion: any;
  // private dataGraficoHoras: any;

  private graficoDonutOEE: any;
  private graficoDonutDisponibilidad: any;
  private graficoDonutRendimiento: any;
  private graficoDonutCalidad: any;
  private graficoLinealOEE: any;
  private graficoBarrasOEE: any;

  public maquinas: any;
  public maquinasJ: any;
  public maquinasSeleccionadas: string[] = [];
  public datosJ: any;
  public data: any;

  // FILTRO CALENDARIO
  public rangoFechasFiltro = {
    start: new Date(),
    end: new Date()
  };
  public mostrarCalendario1: boolean = false;
  public hoy = new Date();
  public dpFechaIni: Date = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate() - 7);
  public dpFechaFin: Date = this.hoy;
  public DaysInMonths = this.hoy;

  // // // //

  // FILTRO MAQUINAS
  public aparecer = false;
  public selectedMaquina: number = 0;
  public maquinasDesplegadas = false;
  public maquinasDesplegadasCount = 0; //para que solo se realice una vez
  public maquinasMostradas: any = [];

  public listaOperarios: any = [];
  public operariosSeleccionados: any = [];
  public seleccionarTodo: boolean = false;
  public listaOperariosSTR: string = "";
  public listaMaquinasAux: any = [];
  public listaOperariosAux: any = [];
  // // // //

  public objetivoOee: number = 70;

  private turno: number = -1;


  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeccion: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  constructor(
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private informeOeeService: InformeOeeService,
    private informeProyectosService: InformeProyectosService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private myFilter: MyFilter,
    private myCharts: MyCharts,
    private operariosService: OperariosService,
    private historicoMaquinasDatosService: HistoricoMaquinasDatosService
  ) {

  }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('tituloOee');
    this.dpFechaIni = new Date(this.myFunctions.getDateNow().getTime() - (30 * 24 * 60 * 60 * 1000));//30 egun atzera
    this.dpFechaFin = this.myFunctions.getDateNow()

    this.dpFechaIni = this.informeOeeService.get_fechaIni_model();
    this.dpFechaFin = this.informeOeeService.get_fechaFin_model();
    if (this.dpFechaIni === undefined) {
      this.cargarFechas();
      this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
      this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
    }

    this.informeOeeService.get_objetivo_oee().subscribe(
      json => {
        this.objetivoOee = json[0].objetivoOee;
      }
    )

    this.cargarGrupos();
    this.cargarAreasProductivas();

    this.dibujarGraficoBarras();
    this.dibujarGraficoLineal();
    this.dibujarGraficosDonuts();

    var bukatua = new Promise<void>((resolve, reject) => {

      var maquinas_model = this.informeOeeService.get_maquinas_model();
      var maquinasSeleccionadas_model = this.informeOeeService.get_maquinasSeleccionadas_model();
      var seccionesSeleccionadas_model = this.informeOeeService.get_seccionesSeleccionadas_model();
      var maquinasCompletas_model = this.informeOeeService.get_maquinasCompletas_model();
      var gruposSeleccionados_model = this.informeOeeService.get_gruposSeleccionados_model();

      if (maquinas_model == false) {
        this.informeOeeService.get_maquinas().subscribe(
          json => {
            this.maquinasJ = json;
            this.informeOeeService.set_maquinasCompletas_model(this.maquinasJ); //Necesitamos tenerlo al filtrar, pero lo guardamos en el servicio para no estar llamándolo siempre
            this.cargarMaquinas();
            resolve();
          }
        )
      } else {
        this.maquinas = maquinas_model;
        this.listaMaquinasAux = maquinas_model;
        this.maquinasSeleccionadas = maquinasSeleccionadas_model;
        this.seccionesSeleccionadas = seccionesSeleccionadas_model;
        this.gruposSeleccionados = gruposSeleccionados_model;
        this.maquinasJ = maquinasCompletas_model;
        this.cargarMaquinas2();
        resolve();
      }

      this.operariosService.getAllUsuariosConColor().subscribe(json => {
        var lag: any = json;
        lag.forEach(element => {
          this.listaOperarios.push(element);
          this.listaOperariosAux.push(element);
          //this.operariosSeleccionados.push(element.idUsuario);
        });
        this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
      });

    });

    bukatua.then(() => {
      this.cargar_historicoMaquinas();
    });


  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.dpFechaIni == undefined) {
      this.dpFechaIni = this.myFunctions.getDateNow()
    }
    fechaIni = this.dpFechaIni
    if (fechaIni.getDay() == 3) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.dpFechaIni);
    this.dpFechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {

    this.maquinasService.GetGruposMaquinasConSeccion().subscribe(json => {
      this.grupos = json.data;
      this.gruposSeccion = this.grupos;
      this.gruposCargados = true;

      var aurk = false;
      this.gruposSeccion = [];
      this.grupos.forEach(grupo => {
        aurk = false;
        this.seccionesSeleccionadas.forEach(seccion => {
          if ((grupo.idSeccion == seccion.id) && !aurk) {
            aurk = true;
            this.gruposSeccion.push(grupo);
          }
        });
      });


    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

    }

  }

  cargarMaquinas() {
    this.maquinasService.get().subscribe(json => {
      this.maquinas = [];
      this.listaMaquinasAux = [];
      var primeraMaquina = true;
      this.maquinasService.set_maquinas_model(json);
      var maquinas1: any;
      maquinas1 = this.maquinasService.get_maquinas_model();

      for (let maquina of this.maquinasJ) {
        maquinas1.forEach(element => {
          if (element.id == maquina.id) {
            var m = { id: maquina.id, nombre: maquina.nombre, imgBase64: element.imagenBase64 };
            this.maquinas.push(m);
            this.listaMaquinasAux.push(m);

            if (primeraMaquina) {
              this.maquinasSeleccionadas.push(maquina.id);
              primeraMaquina = false;
            }
          }
        });
      }
      this.informeOeeService.set_maquinas_model(this.maquinas);
      this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
      this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
      this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
      // this.cargarGraficos();
    });
  }
  cargarMaquinas2() {
    var listaMaquinas: any;
    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        listaMaquinas = mostra;
        var maquinasAux = [];

        var seccionesSeleccionadas = [];
        this.seccionesSeleccionadas?.forEach(elem => seccionesSeleccionadas.push(elem.id));
        var gruposSeleccionados = [];
        this.gruposSeleccionados?.forEach(elem => gruposSeleccionados.push(elem.id));
        if (this.maquinasJ) {
          for (let maquina of this.maquinasJ) {
            var aux = listaMaquinas.filter(f => f.idMaquina === maquina.id)[0];
            if ((this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) || (this.gruposSeleccionados && this.gruposSeleccionados.length > 0)) {
              if (seccionesSeleccionadas.includes(aux?.idSeccion) || aux?.idsGrupos.filter(r => gruposSeleccionados.includes(Number(r))).length > 0) {
                var m = { id: maquina.id, nombre: maquina.nombre };
                maquinasAux.push(m);
              }

            } else {
              var m = { id: maquina.id, nombre: maquina.nombre };
              maquinasAux.push(m);
            }
            this.maquinas = maquinasAux;
            this.listaMaquinasAux = maquinasAux;
          }
          this.maquinasSeleccionadas.forEach(elem => {
            var aux = this.maquinas.filter(e => e.id === elem);
            if (aux.length === 0)
              this.maquinasSeleccionadas.splice(this.maquinasSeleccionadas.indexOf(elem), 1);
          });
          if (this.maquinasSeleccionadas.length === 0) {
            this.maquinasSeleccionadas.push(this.maquinas[0].id);
          }
        }

        this.informeOeeService.set_maquinas_model(this.maquinas);
        this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
        this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
        this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
        // this.cargarGraficos();
      });
  }

  //#region FILTROs
  //#region FECHA DOBLE
  groupByPorAnno(groupByPorFecha) {

    groupByPorFecha.forEach(function (a) {
      //SACAR EL PRIMER DIA DEL AÑO DE CADA FECHA
      var date = new Date(a.fechaTurno);
      var firstDay = new Date(date.getFullYear(), 0, 1);
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(firstDay);
    }, this);

    var groupByPorAnno = [];

    //AGRUPAMOS POR SEMANA
    groupByPorFecha.forEach(function (a) {
      if (!this[a.fechaGrafico]) {
        this[a.fechaGrafico] = {
          fechaGrafico: a.fechaGrafico, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
        };
        groupByPorAnno.push(this[a.fechaGrafico]);
      }
      this[a.fechaGrafico].tEjecucion += a.tEjecucion;
      this[a.fechaGrafico].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.fechaGrafico].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.fechaGrafico].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.fechaGrafico].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.fechaGrafico].tParadaRendimiento += a.tParadaRendimiento;
      this[a.fechaGrafico].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.fechaGrafico].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.fechaGrafico].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.fechaGrafico].tParadaCalidad += a.tParadaCalidad;
      this[a.fechaGrafico].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.fechaGrafico].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.fechaGrafico].tApagadaCalidad += a.tApagadaCalidad;
      this[a.fechaGrafico].tNegativoCalidad += a.tNegativoCalidad;
      this[a.fechaGrafico].tEstimado += a.tEstimado;
    }, Object.create(null));

    return groupByPorAnno;

  }
  groupByPorMes(groupByPorFecha) {

    groupByPorFecha.forEach(function (a) {
      //SACAR EL PRIMER DIA DEL MES DE CADA FECHA
      var date = new Date(a.fechaTurno);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(firstDay);
    }, this);

    var groupByPorMes = [];

    //AGRUPAMOS POR SEMANA
    groupByPorFecha.forEach(function (a) {
      if (!this[a.fechaGrafico]) {
        this[a.fechaGrafico] = {
          fechaGrafico: a.fechaGrafico, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
        };
        groupByPorMes.push(this[a.fechaGrafico]);
      }
      this[a.fechaGrafico].tEjecucion += a.tEjecucion;
      this[a.fechaGrafico].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.fechaGrafico].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.fechaGrafico].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.fechaGrafico].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.fechaGrafico].tParadaRendimiento += a.tParadaRendimiento;
      this[a.fechaGrafico].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.fechaGrafico].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.fechaGrafico].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.fechaGrafico].tParadaCalidad += a.tParadaCalidad;
      this[a.fechaGrafico].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.fechaGrafico].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.fechaGrafico].tApagadaCalidad += a.tApagadaCalidad;
      this[a.fechaGrafico].tNegativoCalidad += a.tNegativoCalidad;
      this[a.fechaGrafico].tEstimado += a.tEstimado;
    }, Object.create(null));

    return groupByPorMes;

  }
  groupByPorSemana(groupByPorFecha) {

    groupByPorFecha.forEach(function (a) {
      //SACAR EL PRIMER DIA DE LA SEMANA DE CADA FECHA
      var d = new Date(a.fechaTurno);
      var day = d.getDay();
      var diff = d.getDate() - day + (day == 0 ? -6 : 1);
      var firstDay = new Date(d.setDate(diff));
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(firstDay);
    }, this);

    var groupByPorSemana = [];

    //AGRUPAMOS POR SEMANA
    groupByPorFecha.forEach(function (a) {
      if (!this[a.fechaGrafico]) {
        this[a.fechaGrafico] = {
          fechaGrafico: a.fechaGrafico, tEjecucion: 0, tParadaDisponibilidad: 0, tMantenimientoDisponibilidad: 0, tAlarmaDisponibilidad: 0, tApagadaDisponibilidad: 0,
          tParadaRendimiento: 0, tMantenimientoRendimiento: 0, tAlarmaRendimiento: 0, tApagadaRendimiento: 0, tParadaCalidad: 0, tMantenimientoCalidad: 0,
          tAlarmaCalidad: 0, tApagadaCalidad: 0, tNegativoCalidad: 0, tEstimado: 0
        };
        groupByPorSemana.push(this[a.fechaGrafico]);
      }
      this[a.fechaGrafico].tEjecucion += a.tEjecucion;
      this[a.fechaGrafico].tParadaDisponibilidad += a.tParadaDisponibilidad;
      this[a.fechaGrafico].tMantenimientoDisponibilidad += a.tMantenimientoDisponibilidad;
      this[a.fechaGrafico].tAlarmaDisponibilidad += a.tAlarmaDisponibilidad;
      this[a.fechaGrafico].tApagadaDisponibilidad += a.tApagadaDisponibilidad;
      this[a.fechaGrafico].tParadaRendimiento += a.tParadaRendimiento;
      this[a.fechaGrafico].tMantenimientoRendimiento += a.tMantenimientoRendimiento;
      this[a.fechaGrafico].tAlarmaRendimiento += a.tAlarmaRendimiento;
      this[a.fechaGrafico].tApagadaRendimiento += a.tApagadaRendimiento;
      this[a.fechaGrafico].tParadaCalidad += a.tParadaCalidad;
      this[a.fechaGrafico].tMantenimientoCalidad += a.tMantenimientoCalidad;
      this[a.fechaGrafico].tAlarmaCalidad += a.tAlarmaCalidad;
      this[a.fechaGrafico].tApagadaCalidad += a.tApagadaCalidad;
      this[a.fechaGrafico].tNegativoCalidad += a.tNegativoCalidad;
      this[a.fechaGrafico].tEstimado += a.tEstimado;
    }, Object.create(null));

    return groupByPorSemana;

  }
  groupByPorDia(groupByPorFecha) {
    groupByPorFecha.forEach(function (a) {
      a.fechaGrafico = this.myFunctions.dateToDD_MM_YYYY_forwardslash(a.fechaTurno);
    }, this);

    return groupByPorFecha;
  }
  showCalendar() {
    if (!this.mostrarCalendario1) {
      this.mostrarCalendario1 = true;
    }
    else {
      this.mostrarCalendario1 = false;
      if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
        this.dpFechaFin = this.dpFechaIni;
      }
      else if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
        this.dpFechaIni = this.myFunctions.getDateNow();
        this.dpFechaFin = this.dpFechaIni;
      }

    }
    // this.mostrarCalendario1 = true; // activa el click de fondo!
  }
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.dpFechaIni != undefined && this.dpFechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.dpFechaFin = this.DaysInMonths[month];
        this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
          this.dpFechaIni = this.DaysInMonths[month];
        }
        else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
          this.dpFechaFin = this.DaysInMonths[month];
          if (this.dpFechaIni > this.dpFechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.dpFechaIni.getTime());
            this.dpFechaIni = new Date(this.dpFechaFin.getTime());
            this.dpFechaFin = aux;
          }
          this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.dpFechaIni = this.DaysInMonths[month];
          this.dpFechaFin = undefined;
        }
      }
    }
  }
  // Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
      return false;
    } else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
      return date.getFullYear() == this.dpFechaIni.getFullYear() && date.getMonth() == this.dpFechaIni.getMonth() && date.getDate() == this.dpFechaIni.getDate();
    } else if (this.dpFechaIni != undefined && this.dpFechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.dpFechaIni.getFullYear(), this.dpFechaIni.getMonth(), this.dpFechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.dpFechaFin.getFullYear(), this.dpFechaFin.getMonth(), this.dpFechaFin.getDate());
    }
  }
  public onChangeF(value: Date): void {
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);

    if (this.dpFechaIni > this.dpFechaFin) {
      var f = this.myFunctions.dateCopy(this.dpFechaFin);
      this.dpFechaFin = this.myFunctions.dateCopy(this.dpFechaIni);
      this.dpFechaIni = f;
    }
    //this.cargarGraficos();
  }

  //#endregion
 
  // TURNOS
  btnTodos_Click(event) {
    this.turno = -1;
  }
  btnManana_Click(event) {
    this.turno = 1;
  }
  btnTarde_Click(event) {
    this.turno = 2;
  }
  btnNoche_Click(event) {
    this.turno = 3;
  }

  // GRIDS MAQUINAS/OPERARIOS
  insertMaquinasSelected(id, e) {
    var aurk = false;
    this.maquinasSeleccionadas.forEach((maquina, index) => {
      if (maquina == id) {
        this.maquinasSeleccionadas.splice(index, 1);
        aurk = true;
      }
    });
    if (!aurk) {
      this.maquinasSeleccionadas.push(id);
    }
  }
  selectAllUsuarios() {
    if (this.operariosSeleccionados.length == this.listaOperarios.length) {
      this.seleccionarTodo = true;
      this.operariosSeleccionados = [];
    } else {
      this.seleccionarTodo = false;
      this.operariosSeleccionados = [];
      this.listaOperarios.forEach(operario => {
        this.operariosSeleccionados.push(operario.idUsuario);
      });
    }
    this.operariosSeleccionados;
  }
  filtroMaquinas(e: any) {
    this.listaMaquinasAux = [];
    if (e == '') {
      this.listaMaquinasAux = this.maquinas;
    } else {
      this.maquinas.forEach(maquina => {
        if (maquina.nombre.toLowerCase().includes(e.toLowerCase())) {
          this.listaMaquinasAux.push(maquina);
        }
      });
    }
  }
  filtroOperarios(e: any) {
    this.listaOperariosAux = [];
    if (e == '') {
      this.listaOperariosAux = this.listaOperarios;
    } else {
      this.listaOperarios.forEach(operario => {
        if (operario.nombreCompleto.toLowerCase().includes(e.toLowerCase())) {
          this.listaOperariosAux.push(operario);
        }
      });
    }
    this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
  }
  //#endregion

  // CARGA DE GRAFICOS VACIOS ---------------------------------------------------------------------------------------------------------------------
  dibujarGraficoBarras() {
    this.graficoBarrasOEE = this.myCharts.dibujarGraficoBarrasOEE('chartBarHoras') 
  }
  dibujarGraficoLineal() {
    this.graficoLinealOEE = c3.generate({
      bindto: '#graficoEvolucion_OEE',
      padding: {
        top: 0,
        right: 30,
        bottom: 0,
        left: 40,
      },
      data: {
        x: 'x',
        xFormat: '%d/%m/%Y',
        columns: [['x'], ['disponibilidad'], ['rendimiento'], ['calidad'], ['oee'], ['objetivo']],
        types: {disponibilidad: 'line', rendimiento: 'line', calidad: 'line', oee: 'area', objetivo: 'area'},
        names: {disponibilidad: this.translateService.instant('disponibilidad')
              , rendimiento: this.translateService.instant('rendimiento')
              , calidad: this.translateService.instant('calidad')
              , oee: this.translateService.instant('oee')
              , objetivo: this.translateService.instant('objetivo')}
      },
      axis: {
        x: {
          type: 'timeseries'
        },
        y: {
          max: 100,
          min: 0,
          tick: {
            values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            outer: false
          },
          padding: { top: 10, bottom: 0 }
        }
      },
      transition: {
        duration: this.myCharts.tiempoTransition
      },
      tooltip: {
        format: {
          value: function (value) {
            return Math.round(value);
          }
        }
      }
    });
  }
  dibujarGraficosDonuts() {

    this.graficoDonutOEE = this.myCharts.dibujarGraficoDonut_simple('graficoDonutOEE_OEE', false);
    this.graficoDonutDisponibilidad = this.myCharts.dibujarGraficoDonut_simple('graficoDonutDisponibilidad_OEE', false);
    this.graficoDonutRendimiento = this.myCharts.dibujarGraficoDonut_simple('graficoDonutRendimiento_OEE', false);
    this.graficoDonutCalidad = this.myCharts.dibujarGraficoDonut_simple('graficoDonutCalidad_OEE', false);

  }

  // RECARGAR PAGINA
  recargarPagina(){
    this.loading = true;// hay que tener en cuenta que si no se re-hace la consulta del historico, no se llega a ver el icono de carga

    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
    this.informeOeeService.set_fechaFin_model(this.dpFechaFin);

     setTimeout(() => { // PARA QUE SALGA EL ICONO DE CARGA
      this.cargar_historicoMaquinas();
     }, 0); 
  }  
  // CARGAR DATOS
  cargar_historicoMaquinas(){   
    var filtro = this.myFilter.crearFiltro_rapido_OEE([this.dpFechaIni, this.dpFechaFin], this.turno, this.maquinasSeleccionadas, this.operariosSeleccionados)

    var MinMax = this.myFilter.filtroFechas_MIN_MAX(filtro, true);
    var fechaInicio = MinMax[0];
    var fechaFin = MinMax[1];
    
    if (!this.historicoMaquinasDatosService.util(fechaInicio, fechaFin)) {
      this.historicoMaquinasDatosService.cargar_historico_completo(fechaInicio, fechaFin).subscribe(
        j => {
          this.historicoMaquinasDatosService.set_historico_datos(j, fechaInicio, fechaFin);
          this.cargado_historicoMaquinas();
        });
    } else {
  
      this.cargado_historicoMaquinas();
    }
  }  
  cargado_historicoMaquinas(){
        
    // Como en esta pagina no hay un filtro nuevo, se apaña creando la misma estructura desde atras.
    var filtro = this.myFilter.crearFiltro_rapido_OEE([this.dpFechaIni, this.dpFechaFin], this.turno, this.maquinasSeleccionadas, this.operariosSeleccionados)
    // Se combierte en filtro Kendo para usar sobre JSON
    var filtro_kendo : any = this.myFilter.filtroToKendo(filtro)
    // Aplicamos el filtro a los datos obtenidos en la clase (que se ha devido actualizar antes de llamar a esta funcion).
    var historico_completo_filtrado = this.historicoMaquinasDatosService.get_historicoMauqinas_filtrado(filtro_kendo);

    // Se trabaja con los datos filtrados.
    this.cargarGraficos(historico_completo_filtrado);

    this.loading = false;
  }
  // CARGA DE DATOS EN GRAFICOS
  cargarGraficos(historico_completo_filtrado){

    this.cargarGraficosDonuts(historico_completo_filtrado);
    this.cargarGraficosBarras(historico_completo_filtrado);
    this.cargarGraficosLineas(historico_completo_filtrado);

  }
  cargarGraficosDonuts(historico_completo_filtrado){

    var OEE_donut_calculado: any = this.historicoMaquinasDatosService.get_OEE_data_Donut_prefiltrado(historico_completo_filtrado);

    d3.select('#graficoDonutOEE_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.OEE_porcentaje) + "%");
    d3.select('#graficoDonutDisponibilidad_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.disponibilidad_porcentaje) + "%");
    d3.select('#graficoDonutRendimiento_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.rendimiento_porcentaje) + "%");
    d3.select('#graficoDonutCalidad_OEE .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.calidad_porcentaje) + "%");
      
    this.graficoDonutOEE.load(OEE_donut_calculado.OEE);
    this.graficoDonutDisponibilidad.load(OEE_donut_calculado.disponibilidad);
    this.graficoDonutRendimiento.load(OEE_donut_calculado.rendimiento);
    this.graficoDonutCalidad.load(OEE_donut_calculado.calidad);

  }
  cargarGraficosBarras(historico_completo_filtrado){

    var data = this.historicoMaquinasDatosService.get_OEE_data_Barras_prefiltrado(historico_completo_filtrado);
    this.graficoBarrasOEE.load(data);
    
  }
  cargarGraficosLineas(historico_completo_filtrado){
    
    var filtro = this.myFilter.crearFiltro_rapido_OEE([this.dpFechaIni, this.dpFechaFin], this.turno, this.maquinasSeleccionadas, this.operariosSeleccionados)

    var MinMax = this.myFilter.filtroFechas_MIN_MAX(filtro, false);
    var fechaInicio = MinMax[0];
    var fechaFin = MinMax[1]; 

    this.historicoMaquinasDatosService.load_OEE_Lineal_prefiltrado(this.graficoLinealOEE, fechaInicio, fechaFin, historico_completo_filtrado, this.objetivoOee);

  }
}
