import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';


const baseUrl = `${environment.apiUrl}/ToleranciasTipo`;

@Injectable()
export class ToleranciasTipoService {

  constructor(private http: HttpClient) { }

  public GetAll(): Observable<any> {
    return this.http.get(baseUrl, { withCredentials: true });
  }

  getById(id: string): Observable<any> {
    return this.http.get(`${baseUrl}/${id}`, { withCredentials: true });
  }

  public GetTipos(): Observable<any> {
    return this.http.get(`${baseUrl}/tipos`, { withCredentials: true });
  }

  public GetValores(): Observable<any> {
    return this.http.get(`${baseUrl}/valores`, { withCredentials: true });
  }
  
  insert(data) {
    return this.http.post<any>(`${baseUrl}/crear`, {
      id: data.id, nombre: data.nombre, idTipoTolerancia: data.idTipoTolerancia, idTipoValor: data.idTipoValor, descripcion: data.descripcion,
      nombreSimbolo: data.nombreSimbolo, simboloBase64: data.simboloBase64, activo: data.activo}, { withCredentials: true });
  }

  update(data, id) {
    return this.http.post<any>(`${baseUrl}/editar/${id}`, {
      id: data.id, nombre: data.nombre, idTipoTolerancia: data.idTipoTolerancia, idTipoValor: data.idTipoValor, descripcion: data.descripcion,
      nombreSimbolo: data.nombreSimbolo, simboloBase64: data.simboloBase64, activo: data.activo}, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(`${baseUrl}/eliminar`, formData, { withCredentials: true });
  }


}
