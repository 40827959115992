import { Component } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { GridDataResult, DataStateChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, DataResult } from '@progress/kendo-data-query';

import { Observable } from 'rxjs';



import { ActivatedRoute, Router } from "@angular/router";



import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService, AlertService, RutasPredefinidasService, PiezasService, MenuService, BomService, OperacionesPredefinidasService, GestionDocumentalService } from '@app/_services';
import { Usuario } from '@app/_models';
import { MustMatch, MyFunctions } from '@app/_helpers';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';



@Component({
  selector: 'app-rutasPredefinidas',
  templateUrl: 'rutasPredefinidasDetalle.html'
})

export class RutasPredefinidasDetalleComponent {



  public idruta: number;

  public rutas: [];
  public referencias: [];

  public idParte: number;
  public idOF: number ;
  public idPieza: number;
  orden: number;
  pricipal: Boolean;
  iderp: String;
  public operaciones: any[];
  public historicocambios: any[];
  public bom: any[];

  _kendoFiles: any;

  public operacionessstr: String = '';
  public historicocambiosstr: String = '';
  public bomstr: String = '';

  public piezasseleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  isAddMode: boolean;
  public botonesDisbled: string;
  form: FormGroup;
  user = this.userService.userValue;


  closeResult = '';
  tituloPopUpPregunta: any;

  fechaHoyStr: string;
  tituloPopUp: any;

  nombre: String;
  principal: Boolean;
  idERPRuta: String

  modalReferenceloadingupload: NgbModalRef;
  operacionesseleccionados: number[] = [];

  esPrincipal: Boolean = false;

  public botonesActivados: Boolean;

  public submitted = false;

  public gestiondocumental: any = [];
  public gestiondocumentalstr: String = '';
  mySelectionGestionDocumental: any = [];
  loadingGestionDocumental = true;
  isDeletingGestionDocumental;

  @ViewChild('popupBorrarDocumentos') popupBorrarDocumentos: NgbModalRef;

  @ViewChild('anadirOperacionPred') anadirOperacionPred: NgbModalRef;

  public operacionesPred: any = []; //Tiene el listado de operacionesPredefinidas con sus datos
  public operacionesPredSelected: any = []; //Tiene las operacionesPredefinidas seleccionadas

  constructor(

    private formBuilder: FormBuilder,
    private userService: UsuariosService,
    public myFunctions: MyFunctions,
    private route: ActivatedRoute,
    private rutasService: RutasPredefinidasService,
    public router: Router,
    private translate: TranslateService,
    private operacionesService: OperacionesPredefinidasService,
    private gestionDocumentalService: GestionDocumentalService,
    private modalService: NgbModal,
    private bomService: BomService,
    private menuService: MenuService,
    protected alertService: AlertService) {

    this.menuService.titulo = this.translate.instant('rutaspredefinida').toUpperCase();
  }
  ngOnInit() {

    this.operacionessstr = this.translate.instant('operaciones');
    this.historicocambiosstr = this.translate.instant('historicocambios');
    this.gestiondocumentalstr = this.translate.instant('gestiondocumental');
    this.bomstr = "BOM";
    this.botonesActivados = false;

    this.idPieza = this.route.snapshot.params.idpieza;
    this.idOF = this.route.snapshot.params.idof;
    this.idParte = this.route.snapshot.params.idparte;
    this.idruta = this.route.snapshot.params.idruta;


    this.tituloPopUp = document.getElementById("text_loading_popup");

    this.botonesDisbled = "disabled";
    this.isAddMode = !this.idruta;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      principal: [false, Validators.required],
      idERPRuta: ['',],
      idparte: [this.idParte, Validators.required]

    }
    );

    if (this.idruta > 0) {
      this.CargarDatos();
    }

  }
  CargarDatos() {
    this.rutasService.GetByID(this.idParte, this.idruta).subscribe((result) => {
      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        nombre: [result.ruta[0].nombre, Validators.required],
        principal: [result.ruta[0].principal, Validators.required],
        idERPRuta: [result.ruta[0].idERP,],
        idparte: [this.idParte, Validators.required],
        idruta: [this.idruta, Validators.required]
      });

      if (result.ruta[0].principal)
        this.esPrincipal = true;
      else
        this.esPrincipal = false;
      this.historicocambios = result.historicoCambios;
      this.historicocambios.forEach(element => {
        element.fecha = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
        if(element.valorAnterior instanceof Date){
          element.valorAnterior = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
          element.valorAnteriorTrad = "fecha";
        }else if(element.valorAnterior == "sinAsignar" || element.valorAnterior == "true" || element.valorAnterior == "false"){
          element.valorAnteriorTrad = "traducir"
        }
        if(element.valorNuevo instanceof Date){
          element.valorNuevo= this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
          element.valorNuevoTrad = "fecha";
        }else if(element.valorNuevo == "sinAsignar" || element.valorNuevo == "true" || element.valorNuevo == "false"){
          element.valorNuevoTrad = "traducir"
        }
      });
      this.bom = result.bom;
      //GRIDS

      var dict: any = {};

      if (result.imagenesMaquinas.length > 0) {
        //Tenemos las imagenes, creamos el diccionario
        result.imagenesMaquinas.forEach(element => {
          dict[element.imagen] = element.imagenBASE64;
        });
      }

      result.operaciones.forEach(function (o) {
          //TIEMPOS
          o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
          o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
          o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
          o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
          o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
          o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
          //FIN TIEMPOS

          if (o.idsMaquinas) {
            var idsMaquinas: any = o.idsMaquinas.split(',');
            
            var maquinas = [];
            var maquinasImagenes = [];
            var prioridadesSeparadas =  o.prioridadesMaquinas.split(',');
            var prioridadesFinal = [];
            idsMaquinas.forEach((idm, index) => {
              if (idm != "" && idm != "-1" && idm != "0") {
                prioridadesFinal.push(prioridadesSeparadas[index]);
                var m = result.nombresMaquinas.find(x => x.id == idm);
                if (m != undefined){
                  maquinas.push(m.nombre);
                  maquinasImagenes.push(m.urlImagen);
                } 
              }
            });
            var auxDictPrioridades = {}
            prioridadesFinal.forEach((prioridad, index) => {
              auxDictPrioridades[maquinas[index]] = prioridad;
            });
            o.prioridadMaquinasAux = auxDictPrioridades;
            o.maquina = maquinas.join(', ');
             //Ahora seguimos con las maquinas
            var maquinasAuxi = [];
            var maquinasAuxi2 = [];
            maquinas.sort((a, b) => 
              (Number(auxDictPrioridades[a]) >Number(auxDictPrioridades[b])) ? -1 : 
              ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
            );
            maquinas.forEach(maquina => {
              if (maquina == this.translate.instant("desconocido")) {
                maquinasAuxi2.push("undefined");
                maquinasAuxi.push("undefined");
              } else {
                var nombre = maquina.trim().substring(0, 1).toUpperCase();
                var apellido = maquina.trim().substring(1, 2).toUpperCase();
                maquinasAuxi2.push(maquina);
                maquinasAuxi.push(nombre + apellido);
              }
            });
            o.maquinasAuxi2 = maquinasAuxi2.join(";");
            o.maquinasAuxi = maquinasAuxi.join(",");
            //Ahora hay que corregir las imagenes de las maquinas
            var imagenes = maquinasImagenes;
            var auxiImagenes = "";
            imagenes.forEach(imagen => {
              auxiImagenes += dict[imagen] + ";and;";
            });
            o.maquinasIm = auxiImagenes;
          }
          else {
            o.maquina = null;
          }
        }, this);
      this.operaciones = result.operaciones;
      this.botonesActivados = result.operaciones.length > 0;

      this.gestionDocumentalService.getAllDocumentosPredefinidas(this.idruta).pipe().subscribe((result: any) => {
        this.gestiondocumental = result;
        this.loadingGestionDocumental = false;
      });
    });
  }


  onClickEliminarOperacion(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("popup.preguntaeliminarpopupdetallecompleto");
    }
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }


  onUploadBOMButtonClick(e, content) {

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append("idruta", this.idruta.toString());
    this.bomService.importar(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.CargarDatos();

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }


  onClickNuevaOperacion() {
    this.router.navigate(['operacionespredefinidas/crear/' + this.idPieza + "/" + this.idParte + "/" + this.idruta + '/0']);
  }

  onClickNuevaOperacionPredefinida() {
    this.operacionesPredSelected = [];
    this.modalReference = this.modalService.open(this.anadirOperacionPred, { backdrop: 'static', size: 'xl', keyboard: false, centered: true });
    this.operacionesService.GetAll().subscribe((result: any) => {
      var dict: any = {};

      if (result.imagenesMaquinas.length > 0) {
        //Tenemos las imagenes, creamos el diccionario
        result.imagenesMaquinas.forEach(element => {
          dict[element.imagen] = element.imagenBASE64;
        });
      }

      result.operaciones.forEach(function (o) {
        //TIEMPOS
        o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
        o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
        o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
        o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
        o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
        o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
        //FIN TIEMPOS

        if (o.idsMaquinas) {
          var idsMaquinas: any = o.idsMaquinas.split(',');
          
          var maquinas = [];
          var maquinasImagenes = [];
          var prioridadesSeparadas =  o.prioridadesMaquinas.split(',');
          var prioridadesFinal = [];
          idsMaquinas.forEach((idm, index) => {
            if (idm != "" && idm != "-1" && idm != "0") {
              prioridadesFinal.push(prioridadesSeparadas[index]);
              var m = result.nombresMaquinas.find(x => x.id == idm);
              if (m != undefined){
                maquinas.push(m.nombre);
                maquinasImagenes.push(m.urlImagen);
              } 
            }
          });
          var auxDictPrioridades = {}
          prioridadesFinal.forEach((prioridad, index) => {
            auxDictPrioridades[maquinas[index]] = prioridad;
          });
          o.prioridadMaquinasAux = auxDictPrioridades;
          o.maquina = maquinas.join(', ');
           //Ahora seguimos con las maquinas
          var maquinasAuxi = [];
          var maquinasAuxi2 = [];
          maquinas.sort((a, b) => 
            (Number(auxDictPrioridades[a]) >Number(auxDictPrioridades[b])) ? -1 : 
            ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
          );
          maquinas.forEach(maquina => {
            if (maquina == this.translate.instant("desconocido")) {
              maquinasAuxi2.push("undefined");
              maquinasAuxi.push("undefined");
            } else {
              var nombre = maquina.trim().substring(0, 1).toUpperCase();
              var apellido = maquina.trim().substring(1, 2).toUpperCase();
              maquinasAuxi2.push(maquina);
              maquinasAuxi.push(nombre + apellido);
            }
          });
          o.maquinasAuxi2 = maquinasAuxi2.join(";");
          o.maquinasAuxi = maquinasAuxi.join(",");
          //Ahora hay que corregir las imagenes de las maquinas
          var imagenes = maquinasImagenes;
          var auxiImagenes = "";
          imagenes.forEach(imagen => {
            auxiImagenes += dict[imagen] + ";and;";
          });
          o.maquinasIm = auxiImagenes;
        }
        else {
          o.maquina = null;
        }
      }, this);
      this.operacionesPred = result.operaciones;
    });
  }

  onClickAceptarAnadirPred() {
    var auxOperacionPred = this.operacionesPred.filter(x => this.operacionesPredSelected.includes(x.id));
    auxOperacionPred.forEach(element => {
      this.operacionesService.creardepredefinido(element.id, this.idruta).subscribe((result) => {
        this.modalReference.close();
        if (result.error == false) {
          this.CargarDatos();
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      });
    });
  }

  onClickCancelar() {
    this.operacionesPredSelected = [];
  }


  onClickEditarOperacion(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.operacionesseleccionados[0] > 0) {
      this.router.navigate(['operacionespredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + this.idruta + "/" + this.operacionesseleccionados[0] + '/0']);
    }
  }

  cellClick(e) {
    this.idruta = e.dataItem.idRuta;
    this.idParte = e.dataItem.idParte;
    this.idPieza = e.dataItem.idPieza;
    if (e.columnIndex > 0) {
      this.router.navigate(['operacionespredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + this.idruta + "/" + this.operacionesseleccionados[0] + '/0']);
    }

  }
  onSubmit(contentloading, e) {

    this.submitted = true;


    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });




    if (this.isAddMode) {
      this.nuevo();
    } else {
      this.actualizar();
    }
  }
  actualizar() {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }

    this.form.value.idruta = this.idruta;
    if (this.idOF != undefined && this.idOF >= 0) {
      this.rutasService.update(this.form.value)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            var idgenerado = result.id;
            this.CargarDatos();
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
          //this.router.navigate(['./'], { relativeTo: this.route });

        });

    } else {
      this.rutasService.update(this.form.value)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            var idgenerado = result.id;
            this.CargarDatos();
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
          //this.router.navigate(['./'], { relativeTo: this.route });

        });


    }


  }
  nuevo() {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    if (this.idOF != undefined && this.idOF >= 0) {
      this.rutasService.create(this.form.value)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {

            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              var idgenerado = result.id;
              this.router.navigate(['rutaspredefinidas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + idgenerado]);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );
    } else {
      this.rutasService.create(this.form.value)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          {

            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              var idgenerado = result.id;
              this.router.navigate(['rutaspredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + idgenerado]);
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          }
        }
        );


    }
  }

  Atras() {
    this.router.navigate(['piezaspredefinidas/editar/' + this.idParte]);
  }

  private Eliminar(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }
    this.operacionesService.delete({ ids: this.operacionesseleccionados }).subscribe(

      (data) => {
        this.modalReferenceloading.close();
        if (data.error == false) {
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
          this.CargarDatos();
        }
        else {
          this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
        }


      }

    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  onClickDuplicarOperacion(contentloading) {

    this.form.value.id = this.idruta;
    this.form.value.idof = this.idOF;
    this.form.value.idpieza = this.idPieza;
    this.form.value.idparte = this.idParte;
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.rutasService.duplicar(this.form.value).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  //BOTONES GESTI�N DOCUMENTAL
  onCellClickGestionDocumental(e: any) {
    if (e.columnIndex > 0) {
      this.router.navigate(['/piezaspredefinidas/documento/' + this.idPieza + '/' + this.idParte + '/' + this.idruta + '/' + this.mySelectionGestionDocumental[0] + "/0"]);
    }
  }

  onClickEditarGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.mySelectionGestionDocumental.length > 0) {
      this.router.navigate(['/piezaspredefinidas/documento/' + this.idPieza + '/' + this.idParte + '/' + this.idruta + '/' + this.mySelectionGestionDocumental[0] + "/0"]);
    }
  }

  onClickNuevoGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.router.navigate(['/piezaspredefinidas/documento/' + this.idPieza + '/' + this.idParte + '/' + this.idruta + '/-1/0']);
  }

  onClickEliminarGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.modalReference = this.modalService.open(this.popupBorrarDocumentos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarDocumentos() {
    this.mySelectionGestionDocumental.forEach(element => {
      if (element > 0) {
        this.isDeletingGestionDocumental = true;
        var ids: string = "";
        var idsRutas: string = "";
        var files: string = "";
        this.mySelectionGestionDocumental.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
            var auxi = this.gestiondocumental.filter(x => x.id == ap)[0];
            var auxFile = auxi.fichero;
            if (files == "")
              files += auxFile;
            else
              files += '&1sep1&' + auxFile;
            if (idsRutas == "")
              idsRutas += auxi.idRuta;
            else
              idsRutas += ',' + auxi.idRuta;
          }
        );
        this.gestionDocumentalService.deleteDocumentosPredefinidas(ids, files, idsRutas).subscribe(() => {
          this.isDeletingGestionDocumental = false;
          this.CargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

  viewPDF(element: any) {
    if (element.esLink) {
      window.open(element.fichero, '_blank');
    } else {
      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + element.pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = element.fichero;
      }, 0);
    }
  }


}
