import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/informerendimiento`;
const baseUrlPersonalizado = `${environment.apiUrl}/informepersonalizado`;

@Injectable()
export class InformePersonalizadoService {

  public informes:  Array<any>;

  constructor(private http: HttpClient) { }

  /* PRUEBA INFORMES */
  get_informes(): Array<any>{
    return this.informes;
  }
  set_informes(informesNuevo){
    this.informes = informesNuevo;
  }

  public getAll(fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrlPersonalizado}/getAll`, { fechaInicio, fechaFin }, { withCredentials: true });
  }

  public get_informePersonalizado(id: number) {
    return this.http.post<JSON>(`${baseUrlPersonalizado}/get_informePersonalizado`, { id }, { withCredentials: true });
  }

  public get_modulos() {
    return this.http.post<JSON>(`${baseUrlPersonalizado}/get_modulos`, { }, { withCredentials: true });
  }

  public get_tiposColumna() {
    return this.http.post<JSON>(`${baseUrlPersonalizado}/get_tiposColumna`, { }, { withCredentials: true });
  }
  
  public get_columnasGrid() {
    return this.http.post<JSON>(`${baseUrlPersonalizado}/get_columnasGrid`, { }, { withCredentials: true });
  }
}
