<!--FILTRO-->
<div class="cont-filtro-maquinas-areas clearfix">
  <!-- FILTRO GRUPOS -->
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">
        <div class="row">
          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="no-contraer-click-maquina cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas">
      <div id="divMensajeSeleccionar" *ngIf="selectedMaquinas.length==0" class="text-select-maquinas">
        <div>
          {{ 'seleccioneMaquina' | translate }}
        </div>
      </div>
      <div *ngFor="let maquina of maquinasMostradas" (click)="maquinaClicked(maquina.id, $event);" class="clickable" [ngClass]="{'actual': selectedMaquinas.includes(maquina.id)}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>
  <!-- FILTRO TURNOS Y FECHAS -->
  <div class="card cont-filtro-informes">
    <div class="card-body">
      <!--Turnos-->
      <!-- <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'turno' | translate }}</label>
        <div class="caja">
          <kendo-multiselect formControlName="idTurno" [placeholder]="turnos_placeHolder" [data]="turnosCombo" [textField]="'nombre'" [valueField]="'id'"
                             (valueChange)="turnos_onValueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"></kendo-multiselect>
        </div>
      </div> -->
      <!--Fechas-->
      <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'fechas' | translate }}</label>
        <div class="caja">
          <kendo-datepicker [weekNumber]="false" class="filtro-informes-data-1" [(value)]="fechaInicio" (valueChange)="fechaInicio_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
          <kendo-datepicker [weekNumber]="false" class="filtro-informes-data-2" [readonly]="true" [(value)]="fechaFin" (valueChange)="fechaFin_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
</div>

<!--GRAFICOS-->
<div id="divGraficos">
</div>
