import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Reserva } from "@app/_models/reserva";

const baseUrl = `${environment.apiUrl}/eskola_reservaMaquinas`;

@Injectable()
export class Eskola_ReservaMaquinasService {

  constructor(private http: HttpClient) { }



  insert_reservaMaquina(reserva:Reserva):Observable<any>{
    return this.http.post<JSON>(`${baseUrl}/insert_reservaMaquina`, reserva, { withCredentials: true });
  }

  insert_reservaMaquinaAlumno(Id_reserva: number, Id_alumno:number){
    return this.http.post<JSON>(`${baseUrl}/insert_reservaMaquinaAlumno`, { Id_reserva, Id_alumno}, { withCredentials: true });
  }

  getAll(){
    return this.http.get<any>(`${baseUrl}`,{ withCredentials: true });
  }

  test(){
    return this.http.get<JSON>(`${baseUrl}/test`);
  }

 



}