export class ResumenPerdidas {
  enperiodo: {
    horas: string,
    porcentajetiempo: string,
    porcentajenumero: string,
    numeroperdidas: string,
    horasvariacion: string,
    porcentajevariaciontiempo: string,
    porcentajevariacionnumero: string,
    numeroperdidasvariacion: string
  }
  globales: {
    horas: string,
    porcentajetiempo: string,
    porcentajenumero: string,
    numeroperdidas: string,
    horasvariacion: string,
    porcentajevariaciontiempo: string,
    porcentajevariacionnumero: string,
    numeroperdidasvariacion: string
  }
  comparativa: any
  evolucion: any

  constructor() {
    this.enperiodo = {
      horas: "",
      porcentajetiempo: "",
      porcentajenumero: "",
      numeroperdidas: "",
      horasvariacion: "",
      porcentajevariaciontiempo: "",
      porcentajevariacionnumero: "",
      numeroperdidasvariacion: ""
    }
    this.globales = {
      horas: "",
      porcentajetiempo: "",
      porcentajenumero: "",
      numeroperdidas: "",
      horasvariacion: "",
      porcentajevariaciontiempo: "",
      porcentajevariacionnumero: "",
      numeroperdidasvariacion: ""
    }
  }
}
