<kendo-grid [kendoGridBinding]="alarmasPasivas" [selectedKeys]="alarmasPasivas_seleccionados" [sortable]="true" [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true" filterable="menu" kendoGridSelectBy="id" (cellClick)="alarmasPasivas_cellClick($event)">
  <ng-template kendoGridToolbarTemplate position="top">
    <button type="button" (click)="alarmasPasivas_onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button type="button" (click)="alarmasPasivas_onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button type="button" (click)="alarmasPasivas_onClickEliminar(alarmasPasivas_content)" class="btn btn-danger btn-sm mr-1" 
        [disabled]="isDeleting || this.user.maquinas<2">{{ 'eliminar' | translate}}</button>
    <!--<button (click)="alarmasPasivas_onClickCopiarDe(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting">{{ 'copiarDe' | translate}}</button>-->
  </ng-template>
  <!--id-->
  <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--tiempo programa hasta min-->
  <kendo-grid-column width="32%" field="tiempoPrograma" title="{{ 'tiempoProgramaHastaMin' | translate}}"></kendo-grid-column>
  <!--alarma leve-->
  <kendo-grid-column width="32%" field="alarmaLeve" title="{{ 'alarmaLeve' | translate}}"></kendo-grid-column>
  <!--alarma grave-->
  <kendo-grid-column width="32%" field="alarmaGrave" title="{{ 'alarmaGrave' | translate}}"></kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>

<!--POPUP ELIMINAR-->
<ng-template #alarmasPasivas_content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="alarmasPasivas_eliminarRegistro(alarmasPasivas_contentloading)" [disabled]="this.user.maquinas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<ng-template #alarmasPasivas_contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
