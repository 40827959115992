<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <div class="card">
        <div class="card-body">

          <!--Nombre-->
          <div class="form-group">
            <label class="control-label">{{ 'nombre' | translate }}</label>
            <div class="caja">
              <input kendoTextBox formControlName="nombre" Class="form-control" />
            </div>
          </div>
        
      </div>
      <!--BOTONES-->
      <div class="form-group d-flex justify-content-center">
        <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">{{ 'guardar' | translate}}</button>
        <button type="button" class="btn btn-danger btn-sm mr-1" (click)="atras()">{{ 'cancelar' | translate}}</button>
      </div>
    </div>
  </div>
  </div>
</form>
