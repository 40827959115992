import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
const baseUrl = `${environment.apiUrl}/eskola_calendarios`;

@Injectable()
export class CalendariosService {

  constructor(private http: HttpClient,private client:HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }
  insertEskolaCalendario(Formato: boolean, Nombre: string, FechaInicio: Date, FechaFin: Date, Activo: boolean, Dia1: boolean, Dia2: boolean, Dia3: boolean, Dia4: boolean, Dia5: boolean, Dia6: boolean, Dia7: boolean):Observable<any> {

    
    let mes, dia,ano;
    let auxiFechaInicio = null;
    let auxiFechaFin = null;
    if(!Formato){
    if (FechaInicio != null && FechaInicio != undefined) {
      ano = FechaInicio.getFullYear();
      mes = FechaInicio.getMonth() + 1;
      dia = FechaInicio.getDate();
      auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
    }
    if (FechaFin != null && FechaFin != undefined) {
      ano = FechaFin.getFullYear();
      mes = FechaFin.getMonth() + 1;
      dia = FechaFin.getDate();
      auxiFechaFin = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T23:59:59' + 'Z';
    }
    }else{
       auxiFechaInicio = FechaInicio;
       auxiFechaFin = FechaFin;
    }
    return this.http.post<any>(baseUrl + '/insert_EskolaCalendario', { Nombre, FechaInicio: auxiFechaInicio, FechaFin: auxiFechaFin, Activo, Dia1, Dia2, Dia3, Dia4, Dia5, Dia6, Dia7 }, { withCredentials: true });
   
  }
  test2():Observable<any>{
    return this.client.get(baseUrl + '/test2');
  }
  getById(Id: number) {
    return this.http.post<any[]>(`${baseUrl}/get_calendario_id`, { Id }, { withCredentials: true });
  }

  getPeriodosAnoActual(Ano: number, Id_calendario: number) {
    return this.http.post<any[]>(`${baseUrl}/get_periodos_ano_actual`, { Ano, Id_calendario }, { withCredentials: true });
  }
  getPeriodosDeCalendario(Id_calendario: number) {
    return this.http.post<any[]>(`${baseUrl}/get_periodos_de_calendario`, { Id_calendario }, { withCredentials: true });
  }
  insertEskolaCalendarioPeriodos(Id_calendario, Ano, FechaInicio, FechaFin, Activo):Observable<any> {
    // hay que corregir las fechas
    console.log(FechaInicio+"***********"+ FechaFin);
    /*
    let mes, dia;
    let auxiFechaInicio = null;
    let auxiFechaFin = null;
    if (FechaInicio != null && FechaInicio != '') {
      Ano = FechaInicio.getFullYear();
      mes = FechaInicio.getMonth() + 1;
      dia = FechaInicio.getDate();
      auxiFechaInicio = Ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
    }
    if (FechaFin != null && FechaFin != '') {
      Ano = FechaFin.getFullYear();
      mes = FechaFin.getMonth() + 1;
      dia = FechaFin.getDate();
      auxiFechaFin = Ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T23:59:59' + 'Z';
    }*/

    return this.http.post<any>(baseUrl + '/insert_EskolaCalendarioPeriodo', { Id_calendario, Ano, FechaInicio, FechaFin, Activo }, { withCredentials: true });
  }

  deleteEskolaCalendariosPeriodo(Id): Observable<any> {
    return this.http.get(`${baseUrl}/delete_EskolaCalendariosPeriodo/${Id}`, { withCredentials: true });
  }
  public updateEskolaCalendario(Id, Nombre, FechaInicio, FechaFin, Activo, Dia1, Dia2, Dia3, Dia4, Dia5, Dia6, Dia7) {

    let mes, dia,ano;
    let auxiFechaInicio = null;
    let auxiFechaFin = null;
    if (FechaInicio != null && FechaInicio != undefined) {
      ano = FechaInicio.getFullYear();
      mes = FechaInicio.getMonth() + 1;
      dia = FechaInicio.getDate();
      auxiFechaInicio = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T00:00:00' + 'Z';
    }
    if (FechaFin != null && FechaFin != undefined) {
      ano = FechaFin.getFullYear();
      mes = FechaFin.getMonth() + 1;
      dia = FechaFin.getDate();
      auxiFechaFin = ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T23:59:59' + 'Z';
    }
    return this.http.post<any>(baseUrl + '/update_EskolaCalendario', { Id, Nombre, FechaInicio: auxiFechaInicio, FechaFin:auxiFechaFin, Activo, Dia1, Dia2, Dia3, Dia4, Dia5, Dia6, Dia7 }, { withCredentials: true });
  }

  delete(id: number) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  }

  // Auxiliar functions
  addZero(n: number) {
    if (n < 10) {
      return '0' + n.toString();
    }
    else {
      return n.toString();
    }
  }



}
