<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">

      <div class="card">
        <div class="card-body">
          <!--NOMBRE-->
          <div class="form-group">
            <kendo-label text="{{ 'nombre' | translate }}">
              <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
            </kendo-label>
          </div>

         

          <div class="row">

          

            <div class="col-md-4">
              <!--ASIGNAR PERDIDA A-->
              <div class="form-group">
                <label ID="lAsignarPerdidaA" class="control-label">{{  'protocolo' | translate }}</label>
                <div class="caja">
                  <kendo-dropdownlist formControlName="idProtocolo_DAT" [data]="controles_DAT" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionChange($event)" [value]="selectedItem" class="form-control">
                  </kendo-dropdownlist>
                </div>
              </div>

            </div>


          </div>


          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.controles<2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()" >
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
