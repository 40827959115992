import { Component, Renderer2, NgZone,  ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InstalacionesService, MaquinasService, MenuService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, AlertService } from '@app/_services';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { State, process } from '@progress/kendo-data-query';
import { fromEvent, Subscription } from 'rxjs';
import { RemoveEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { take, tap } from 'rxjs/operators';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { Location } from '@angular/common';
import { MyFunctions } from '@app/_helpers';

const tableRow = node => node.tagName.toLowerCase() === 'tr';

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};

@Component({
  selector: 'app-campo-detalle',
  templateUrl: './campoDetalle.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`.k-grid tr.dragging {
                /*background-color: #00b8bd;*/
            };`]
})

export class CampoDetalleComponent implements OnInit {

  public state: State = {
    skip: 0,
    take: 100
  };
  public gridData: any = process([], this.state);
  public gridCamposPLC: JSON;
  public listaCampos: any = [];
  private currentSubscription: Subscription;

  mySelection: number[] = [];
  mySelectionPLC: number[] = [];

  public tipoPLC_Snap7: boolean=false;
  public tipoPLC_Modbus: boolean=false;
  public tipoPLC_Backnet: boolean=false;


  public JTipoCampo: any;
  public JTipoCampoSelected: any;
  public JDatTipoCampo: any;

  public JTipoDato: any;
  public JTipoDatoSelected: any;
  public JDatTipoDato: any;

  public JTipoGrafico: any;
  public JTipoGraficoOriginal: any;
  public JTipoGraficoSelected: any;
  public JDatTipoGrafico: any;

  public JTipoUnidad: any;
  public JTipoUnidadSelected: any;
  public JDatTipoUnidad: any;

  public JTipoPLC: any;
  public JTipoPLCSelected: any;
  public JDatTipoPLC: any;

  public JDatArea: any;
  public JArea_S7: any;
  public JArea_S7Selected: any;
  public JArea_Modbus: any;
  public JArea_ModbusSelected: any;

  public JDatTipoRegistro: any;
  public JTipoRegistro_S7: any;
  public JTipoRegistro_Modbus: any;
  public JTipoRegistro_Backnet: any;
  public JTipoRegistroSelected_S7: any;
  public JTipoRegistroSelected_Modbus: any;
  public JTipoRegistroSelected_Backnet: any;



  public submitable:boolean;
  public id: number;
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public imageToShow: any;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  // tipo de grafico es dato
  public tipoGraficoDato: boolean = false;
  public JTipoIcono: any;
  public JTipoIconoSelected: any;

  // public tieneHistorico_Procesos: any;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private instalacionesService: InstalacionesService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    private location: Location,
    private maquinasService: MaquinasService,
    private myFunctions: MyFunctions,
    private zone: NgZone) {

    this.menuService.titulo = this.translateService.instant('instalacion').toUpperCase();

  }

  ngOnInit() {

    this.id = this.route.snapshot.params['idCampo'];
    this.isAddMode = !this.id;
    this.submitable = true;

    // this.cargarInstalacion();
    

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['',],
      idTipo_Campo_Instalacion: [0,],
      freq_captura_datos: [0,],
      minimo: [0,],
      maximo: [0,],
      idTipo_Dato_Campo_DAT: [0,],
      restarAnterior: [false,],
      activo: [true,],
      idTipo_PLC_DAT: [0,],
      idTipo_Grafico: [0,],
      idTipoRegistro_PLC_DAT: [0,],
      idTipo_Area_PLC: [0,],

      ipAddress_S7: ['',],
      port_S7: [0,],
      rack_S7: [0,],
      slot_S7: [0,],
      tipo_S7: [0,],
      dbNumber_S7: [0,],
      start_S7: [0,],
      amount_S7: [0,],
      position_S7: [0,],
      size_S7: [0,],

      ipAddress_Modbus: ['',],
      port_Modbus: [0,],
      unitIdentifier_Modbus: [0,],
      startingAddress_Modbus: [0,],
      quantity_Modbus: [0,],
      position_Modbus: [0,],

      port_Backnet: [0,],
      useExclusibePort_Backnet: [false,],
      dontFragment_Backnet: [false,],
      localEndpointIP_Backnet: ['',],
      lowLimit_Backnet: [0,],
      high_limit_Backnet:[0,],
      instance_Backnet: [0,],
      device_id_Backnet: [0,],
      maxPayload_Backnet: [0,],

      posColumnaHistorico: [0,],
      idInstalacion: [0,],
      orden: [0,],

    });   
    
    this.cargarCombos();

  }

  // cargarInstalacion() {
  //   //INSTALACIONES
  //   var instalaciones_model = this.maquinasService.get_instalaciones_model();
  //   var instalacionesModel: any;
  //   if (instalaciones_model == false) {
  //     this.maquinasService.GetInstalaciones().subscribe(json => {
  //       this.maquinasService.set_instalaciones_model(json);
  //       instalacionesModel = this.maquinasService.get_instalaciones_model();
  //       var idInstalacion = this.route.snapshot.params['idInstalacion']
  //       var instalacion = instalacionesModel.find(x => x.id == idInstalacion);
  //       this.tieneHistorico_Procesos = instalacion.tieneHistorico_Procesos;
  //       this.cargarCombos();
  //     })
  //   } else {
  //     instalacionesModel = instalaciones_model;
  //     var idInstalacion = this.route.snapshot.params['idInstalacion']
  //     var instalacion = instalacionesModel.find(x => x.id == idInstalacion);
  //     this.tieneHistorico_Procesos = instalacion.tieneHistorico_Procesos;
  //     this.cargarCombos();
  //   }

    
  // }

  cargarFormulario() {
    this.JTipoCampoSelected=undefined;
    this.JTipoDatoSelected=undefined;
    this.JTipoGraficoSelected=undefined;
    this.JTipoPLCSelected=undefined;
    this.JArea_S7Selected=undefined;
    this.JTipoRegistroSelected_S7=undefined;
    this.JTipoRegistroSelected_Modbus=undefined;
    this.JTipoRegistroSelected_Backnet=undefined;
    this.JTipoIconoSelected=undefined;

    if (this.id > 0) {

      this.instalacionesService.GetCamposPLCByCampoId(this.id).pipe().subscribe((result) => {


        this.form = this.formBuilder.group({
          id: this.id,
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre,],
          idTipo_Campo_Instalacion: [result.data[0].idTipo_Campo_Instalacion,],
          freq_captura_datos: [result.data[0].freq_captura_datos,],
          minimo: [result.data[0].minimo,],
          maximo: [result.data[0].maximo,],
          tipoGrafico: ['????',],
          idTipo_Dato_Campo_DAT: [result.data[0].idTipo_Dato_Campo_DAT,],
          restarAnterior: [result.data[0].restarAnterior,],
          activo: [result.data[0].activo,],
          idTipo_PLC_DAT: [result.data[0].idTipo_PLC_DAT,],
          idTipo_Grafico: [result.data[0].idTipo_Grafico,],
          idTipoRegistro_PLC_DAT: [result.data[0].idTipoRegistro_PLC_DAT,],
          idTipo_Area_PLC: [result.data[0].idTipo_Area_PLC,],
          idTipo_Grafico_Dato: [result.data[0].idTipo_Grafico_Dato,],
          idTipo_Unidad: [result.data[0].idTipo_Unidad,],

          ipAddress_S7: [result.data[0].ipAddress_S7,],
          port_S7: [result.data[0].port_S7,],
          rack_S7: [result.data[0].rack_S7,],
          slot_S7: [result.data[0].slot_S7,],
          tipo_S7: ['???',],
          //area_S7: [result.data[0].idTipo_Area_PLC,],
          dbNumber_S7: [result.data[0].dbNumber_S7,],
          start_S7: [result.data[0].start_S7,],
          amount_S7: [result.data[0].amount_S7,],
          position_S7: [result.data[0].position_S7,],
          size_S7: [result.data[0].size_S7,],

          ipAddress_Modbus: [result.data[0].ipAddress_Modbus,],
          port_Modbus: [result.data[0].port_Modbus,],
          unitIdentifier_Modbus: [result.data[0].unitIdentifier_Modbus,],
          startingAddress_Modbus: [result.data[0].startingAddress_Modbus,],
          quantity_Modbus: [result.data[0].quantity_Modbus,],
          position_Modbus: [result.data[0].position_Modbus,],

          port_Backnet: [result.data[0].port_Backnet,],
          useExclusibePort_Backnet: [result.data[0].useExclusibePort_Backnet,],
          dontFragment_Backnet: [result.data[0].dontFragment_Backnet,],
          localEndpointIP_Backnet: [result.data[0].localEndpointIP_Backnet,],
          lowLimit_Backnet: [result.data[0].lowLimit_Backnet,],
          high_limit_Backnet: [result.data[0].high_limit_Backnet,],
          instance_Backnet: [result.data[0].instance_Backnet,],
          device_id_Backnet: [result.data[0].device_id_Backnet,],
          maxPayload_Backnet: [result.data[0].maxPayload_Backnet,],

          posColumnaHistorico: [result.data[0].posColumnaHistorico,],
          idInstalacion: [result.data[0].idInstalacion,],
          orden: [result.data[0].orden,],
          // ,[idInstalacion]
          // ,[position]
          // ,[size]
          // ,[posColumnaHistorico]
          // ,[divisor]
          // ,[orden]

        });

        //Selected values in combos
        console.log("result data", result);
        if (this.form.value.idTipo_Campo_Instalacion>0)
          this.JTipoCampoSelected = this.JTipoCampo.filter(f => f.id == result.data[0].idTipo_Campo_Instalacion)[0];
          // this.JTipoCampoSelected = this.JTipoCampo.filter(f => f.id == [result.data[0].idTipo_Campo_Instalacion,])[0];

        if (this.form.value.idTipo_Dato_Campo_DAT>0)
          this.JTipoDatoSelected = this.JTipoDato.filter(f => f.id == result.data[0].idTipo_Dato_Campo_DAT)[0];
          // this.JTipoDatoSelected = this.JTipoDato.filter(f => f.id == [result.data[0].idTipo_Dato_Campo_DAT,])[0];

        if (this.form.value.idTipo_Grafico>0) {
          this.JTipoGraficoSelected = this.JTipoGrafico.filter(f => f.id == result.data[0].idTipo_Grafico)[0];
          // this.JTipoGraficoSelected = this.JTipoGrafico.filter(f => f.id == [result.data[0].idTipo_Grafico,])[0];
          if (this.JTipoGraficoSelected.id == 8 || this.JTipoGraficoSelected.id == 13 || this.JTipoGraficoSelected.id == 14 || this.JTipoGraficoSelected.id == 15)
            this.tipoGraficoDato = true;
        }

        if (this.form.value.idTipo_Grafico_Dato>0) 
          this.JTipoIconoSelected = this.JTipoIcono.filter(f => f.id == result.data[0].idTipo_Grafico_Dato)[0];

        if (this.form.value.idTipo_Unidad>0) 
          this.JTipoUnidadSelected = this.JTipoUnidad.filter(f => f.id == result.data[0].idTipo_Unidad)[0];

        if (this.form.value.idTipo_PLC_DAT>0)
          {
            this.JTipoPLCSelected = this.JTipoPLC.filter(f => f.id == result.data[0].idTipo_PLC_DAT)[0];
            // this.JTipoPLCSelected = this.JTipoPLC.filter(f => f.id == [result.data[0].idTipo_PLC_DAT,])[0];
            this.actualizarTipoPLC();
          }
        
        if (result.data[0].idTipo_Area_PLC>0)
          {
            this.JArea_S7Selected = this.JArea_S7.filter(f => f.id == result.data[0].idTipo_Area_PLC)[0];
            this.JArea_ModbusSelected = this.JArea_Modbus.filter(f => f.id == result.data[0].idTipo_Area_PLC)[0];
            // this.JArea_S7Selected = this.JArea_S7.filter(f => f.id == [result.data[0].idTipo_Area_PLC,])[0];
            // this.JArea_ModbusSelected = this.JArea_Modbus.filter(f => f.id == [result.data[0].idTipo_Area_PLC,])[0];
            //this.JArea_BacknetSelected = this.JArea_Backnet.filter(f => f.id == [result.data[0].idTipo_Area_PLC,])[0];
          }

        if (result.data[0].idTipoRegistro_PLC_DAT>0)
          {
            this.JTipoRegistroSelected_S7 = this.JTipoRegistro_S7.filter(f => f.id == result.data[0].idTipoRegistro_PLC_DAT)[0];
            this.JTipoRegistroSelected_Modbus = this.JTipoRegistro_Modbus.filter(f => f.id == result.data[0].idTipoRegistro_PLC_DAT)[0];
            this.JTipoRegistroSelected_Backnet = this.JTipoRegistro_Backnet.filter(f => f.id == result.data[0].idTipoRegistro_PLC_DAT)[0];
            // this.JTipoRegistroSelected_S7 = this.JTipoRegistro_S7.filter(f => f.id == [result.data[0].idTipoRegistro_PLC_DAT,])[0];
            // this.JTipoRegistroSelected_Modbus = this.JTipoRegistro_Modbus.filter(f => f.id == [result.data[0].idTipoRegistro_PLC_DAT,])[0];
            // this.JTipoRegistroSelected_Backnet = this.JTipoRegistro_Backnet.filter(f => f.id == [result.data[0].idTipoRegistro_PLC_DAT,])[0];
          }

      });

      


    }

  }


  public cargarCombos() {

    var r1 = false, r2 = false, r3= false, r4= false, r5= false, r6= false, r7=false, r8=false;
    //tipos campos
    this.instalacionesService.Get_TiposCampos_All().subscribe(
      json => {
        this.JDatTipoCampo = json;
        this.JTipoCampo = this.JDatTipoCampo.data;
        r1=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );
    //tipos datos
    this.instalacionesService.Get_TiposDatos_All().subscribe(
      json => {
        this.JDatTipoDato = json;
        this.JTipoDato = this.JDatTipoDato.data;
        r2=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );
    //tipos grafico
    this.instalacionesService.Get_TiposGraficos_All().subscribe(
      json => {
        this.JDatTipoGrafico = json;
        // if (!this.tieneHistorico_Procesos)
        //   this.JTipoGrafico = this.JDatTipoGrafico.data.filter(f=> (f.id != 9));
        // else 
        this.JTipoGrafico = this.JDatTipoGrafico.data.filter(f=> (f.id != 9));
        this.JTipoGraficoOriginal = this.myFunctions.copy(this.JTipoGrafico);
        r3=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );
    //tipos de unidad
    this.instalacionesService.get_tiposUnidad_all().subscribe(
      json => {
        this.JTipoUnidad = json;
        r8=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );
    // tipos iconos de grafico de datos
    this.instalacionesService.get_iconos_graficoDatos().subscribe(
      json => {
        this.JTipoIcono = json;
        r7 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
        
      }
    )
    //tipo PLC
    this.instalacionesService.Get_TiposPLC_All().subscribe(
      json => {
        this.JDatTipoPLC = json;
        this.JTipoPLC = this.JDatTipoPLC.data;
        r4=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );
    //Area_S7 JArea_S7
    this.instalacionesService.Get_Areas_All().subscribe(
      json => {
        this.JDatArea = json;
        this.JArea_S7 = this.JDatArea.data.filter(f => f.id_Tipo_PLC == 1);
        this.JArea_Modbus = this.JDatArea.data.filter(f => f.id_Tipo_PLC == 2);
        //this.JArea_Backnet = this.JDatArea.data.filter(f => f.id_Tipo_PLC == 3);
        r5=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );

    //tipoRegistro
    this.instalacionesService.Get_TiposRegistro_All().subscribe(
      json => {
        this.JDatTipoRegistro = json;
        this.JTipoRegistro_S7 = this.JDatTipoRegistro.data.filter(f => f.id_Tipo_PLC == 1);
        this.JTipoRegistro_Modbus = this.JDatTipoRegistro.data.filter(f => f.id_Tipo_PLC == 2);
        this.JTipoRegistro_Backnet = this.JDatTipoRegistro.data.filter(f => f.id_Tipo_PLC == 3);
        r6=true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7 && r8)
        {
          this.userService.getAllSecciones().subscribe(json => {
            this.userService.secciones = json;
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
      
            var an: any = this.secciones;
            this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
            this.cargarFormulario();
          });
        }
      }
    );

  }

  public actualizarTipoPLC():void
  {
    this.tipoPLC_Snap7=false;
    this.tipoPLC_Modbus=false;
    this.tipoPLC_Backnet=false;
    if (this.JTipoPLCSelected.id==1) this.tipoPLC_Snap7=true;
    if (this.JTipoPLCSelected.id==2) this.tipoPLC_Modbus=true;
    if (this.JTipoPLCSelected.id==3) this.tipoPLC_Backnet=true;
  }

  public tipoCampoChanged(value: String): void
  {
    //this.alertService.error(this.translateService.instant('EGIN GABE'), { keepAfterRouteChange: true });
  }
  
  public tipoDatoChanged(value: String): void
  {
    //this.alertService.error(this.translateService.instant('EGIN GABE'), { keepAfterRouteChange: true });
  }

  
  public tipoGraficoChanged(value: String): void
  {
    //this.alertService.error(this.translateService.instant('EGIN GABE'), { keepAfterRouteChange: true });
    if (this.JTipoGraficoSelected.id == 8 || this.JTipoGraficoSelected.id == 13 || this.JTipoGraficoSelected.id == 14 || this.JTipoGraficoSelected.id == 15) {
      this.tipoGraficoDato = true;
    } else {
      this.tipoGraficoDato = false;
    }
    console.log("unidad", this.JTipoUnidadSelected);
  }

  public tipoPLCChanged(value: String): void
  {
    //this.alertService.error(this.translateService.instant('EGIN GABE'), { keepAfterRouteChange: true });
    this.actualizarTipoPLC();
  }

  public ngAfterViewInit(): void {
    //this.currentSubscription = this.handleDragAndDrop();
  }



  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid || !this.submitable) {
      this.submitable = true;      
      return;
    }
    this.loading = true;
   //TODOOOOOOOOOOOOOOOOOOOOO
    if (this.isAddMode) {
      this.insertCampoPLC();
    } else {
      this.updateCampoPLC();
    }
  }

  private insertCampoPLC()
  {
    let tipoRegistro=0;
    let tipoArea=0;
    if (this.tipoPLC_Snap7) 
    {
      if (this.JTipoRegistroSelected_S7!=undefined) tipoRegistro=this.JTipoRegistroSelected_S7.id;
      if (this.JArea_S7Selected!=undefined) tipoArea=this.JArea_S7Selected.id;
    }
    if (this.tipoPLC_Modbus)
    {
      if (this.JTipoRegistroSelected_Modbus!=undefined) tipoRegistro=this.JTipoRegistroSelected_Modbus.id;
      if (this.JArea_ModbusSelected!=undefined) tipoArea=this.JArea_ModbusSelected.id;
    }
    if (this.tipoPLC_Backnet)
    {
      if (this.JTipoRegistroSelected_Backnet!=undefined) tipoRegistro=this.JTipoRegistroSelected_Backnet.id;
      //tipoArea=this.JArea_BacknetSelected;
    }

    //inicializar valores nulos
    let TipoPLC = 0;
    let TipoDato =0, TipoCampo=0, TipoGrafico=0, TipoGraficoDato=0, TipoUnidad=-1;
    if (this.JTipoPLCSelected!=undefined) TipoPLC=this.JTipoPLCSelected.id;
    if (this.JTipoDatoSelected!=undefined) TipoDato=this.JTipoDatoSelected.id;
    if (this.JTipoCampoSelected!=undefined) TipoCampo=this.JTipoCampoSelected.id;
    if (this.JTipoGraficoSelected!=undefined) TipoGrafico=this.JTipoGraficoSelected.id;
    if (this.JTipoIconoSelected!=undefined) TipoGraficoDato=this.JTipoIconoSelected.id;
    if (this.JTipoUnidadSelected!=undefined) TipoUnidad=this.JTipoUnidadSelected.id;

    this.instalacionesService.Insert_CampoPLC(this.form.value.nombre, this.route.snapshot.params['idInstalacion'],
                    TipoPLC, tipoRegistro, TipoDato,
                    TipoCampo, TipoGrafico, tipoArea, this.form.value.posColumnaHistorico,
                    this.form.value.orden, this.form.value.maximo, this.form.value.minimo,
                    this.form.value.restarAnterior, this.form.value.activo, this.form.value.freq_captura_datos,
                    this.form.value.ipAddress_S7, this.form.value.port_S7, this.form.value.rack_S7, this.form.value.slot_S7,
                    this.form.value.dbNumber_S7, this.form.value.start_S7, this.form.value.amount_S7,
                    this.form.value.position_S7, this.form.value.size_S7,
                    this.form.value.ipAddress_Modbus, this.form.value.port_Modbus, this.form.value.unitIdentifier_Modbus,
                    this.form.value.startingAddress_Modbus, this.form.value.quantity_Modbus, this.form.value.position_Modbus,
                    this.form.value.port_Backnet, this.form.value.useExclusibePort_Backnet, this.form.value.dontFragment_Backnet,
                    this.form.value.maxPayload_Backnet, this.form.value.localEndpointIP_Backnet,
                    this.form.value.lowLimit_Backnet, this.form.value.high_limit_Backnet,
                    this.form.value.instance_Backnet, this.form.value.device_id_Backnet,
                    TipoGraficoDato, TipoUnidad
    ).subscribe((result) => {
      //this.cargarFormulario();

      if (!result['error']) 
        { 
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        this.router.navigate([this.router.url.split('/crearcampo')[0]]);
         }
       else
       {
         if (result['message']=='maxCampos')
          this.alertService.error(this.translateService.instant('maxCampos'), { keepAfterRouteChange: true }); 
         else
           this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });     
       }

    //   if (result)
    //   {this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        
    //   }
    //   else this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true }); 
     });
  } 

  
  
  updateCampoPLC()
  {
    
    let tipoRegistro=0;
    let tipoArea=0;
    if (this.tipoPLC_Snap7) 
    {
      if (this.JTipoRegistroSelected_S7!=undefined) tipoRegistro=this.JTipoRegistroSelected_S7.id;
      if (this.JArea_S7Selected!=undefined) tipoArea=this.JArea_S7Selected.id;
    }
    if (this.tipoPLC_Modbus)
    {
      if (this.JTipoRegistroSelected_Modbus!=undefined) tipoRegistro=this.JTipoRegistroSelected_Modbus.id;
      if (this.JArea_ModbusSelected!=undefined) tipoArea=this.JArea_ModbusSelected.id;
    }
    if (this.tipoPLC_Backnet)
    {
      if (this.JTipoRegistroSelected_Backnet!=undefined) tipoRegistro=this.JTipoRegistroSelected_Backnet.id;
      //tipoArea=this.JArea_BacknetSelected;
    }
     //inicializar valores nulos
     let TipoPLC = 0;
     let TipoDato =0, TipoCampo=0, TipoGrafico=0, TipoIcono=0, TipoUnidad=-1;
     if (this.JTipoPLCSelected!=undefined) TipoPLC=this.JTipoPLCSelected.id;
     if (this.JTipoDatoSelected!=undefined) TipoDato=this.JTipoDatoSelected.id;
     if (this.JTipoCampoSelected!=undefined) TipoCampo=this.JTipoCampoSelected.id;
     if (this.JTipoGraficoSelected!=undefined) TipoGrafico=this.JTipoGraficoSelected.id;
     if (this.JTipoIconoSelected!=undefined) TipoIcono=this.JTipoIconoSelected.id;
     if (this.JTipoUnidadSelected!=undefined) TipoUnidad=this.JTipoUnidadSelected.id;

     this.actualizarValoresSegunTipoPLC();
    
     this.instalacionesService.Update_CampoPLC(Number(this.form.value.id), this.form.value.nombre, this.form.value.idInstalacion,
                    TipoPLC, tipoRegistro, TipoDato,
                    TipoCampo, TipoGrafico, tipoArea, this.form.value.posColumnaHistorico,
                    this.form.value.orden, this.form.value.maximo, this.form.value.minimo,
                    this.form.value.restarAnterior, this.form.value.activo, this.form.value.freq_captura_datos,
                    this.form.value.ipAddress_S7, this.form.value.port_S7, this.form.value.rack_S7, this.form.value.slot_S7,
                    this.form.value.dbNumber_S7, this.form.value.start_S7, this.form.value.amount_S7,
                    this.form.value.position_S7, this.form.value.size_S7,
                    this.form.value.ipAddress_Modbus, this.form.value.port_Modbus, this.form.value.unitIdentifier_Modbus,
                    this.form.value.startingAddress_Modbus, this.form.value.quantity_Modbus, this.form.value.position_Modbus,
                    this.form.value.port_Backnet, this.form.value.useExclusibePort_Backnet, this.form.value.dontFragment_Backnet,
                    this.form.value.maxPayload_Backnet, this.form.value.localEndpointIP_Backnet,
                    this.form.value.lowLimit_Backnet, this.form.value.high_limit_Backnet,
                    this.form.value.instance_Backnet, this.form.value.device_id_Backnet, TipoIcono, TipoUnidad
    ).subscribe((result) => {
      //this.cargarFormulario();
      if (!result['error']) 
        { this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        this.router.navigate([this.router.url.split('/editarcampo')[0]]);
        }
       else
        if (result['message']=='maxCampos')
        this.alertService.error(this.translateService.instant('maxCampos'), { keepAfterRouteChange: true }); 
        else
          this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });                                         

    });
  
  }

  actualizarValoresSegunTipoPLC()
  {
    if (this.tipoPLC_Snap7) 
    {
      this.vaciarModbus();
      this.vaciarBacknet();
    }
    if (this.tipoPLC_Modbus)
    {
      this.vaciarS7();
      this.vaciarBacknet()
    }
    if (this.tipoPLC_Backnet)
    {
      this.vaciarS7();
      this.vaciarModbus();
    }
  }

  vaciarS7()
  {
    this.form.value.ipAddress_S7='';
      this.form.value.port_S7=0;
      this.form.value.rack_S7=0;
      this.form.value.slot_S7=0;
      this.form.value.dbNumber_S7=0;
      this.form.value.start_S7=0;
      this.form.value.amount_S7 =0;
      this.form.value.position_S7=0;
      this.form.value.size_S7=0;
  }

  vaciarModbus()
  {
    this.form.value.ipAddress_Modbus='';
      this.form.value.port_Modbus=0;
      this.form.value.unitIdentifier_Modbus=0;
      this.form.value.startingAddress_Modbus=0;
      this.form.value.quantity_Modbus=0;
      this.form.value.position_Modbus=0;
  }

  vaciarBacknet()
  {
    this.form.value.port_Backnet=0;
      this.form.value.useExclusibePort_Backnet=false;
      this.form.value.dontFragment_Backnet=false;
      this.form.value.maxPayload_Backnet=0;
      this.form.value.localEndpointIP_Backnet=0;
      this.form.value.lowLimit_Backnet=0;
      this.form.value.high_limit_Backnet=0;
      this.form.value.instance_Backnet=0;
      this.form.value.device_id_Backnet=0;
  }

  public atras()
  {
    //this.location.back();
    if (this.router.url.includes('crearcampo') )
      this.router.navigate([this.router.url.split('/crearcampo')[0]]);
    else this.router.navigate([this.router.url.split('/editarcampo')[0]]);
  }



  public guardar() {
    this.onSubmit();
  }

 classKendoList() {
  var elem = document.getElementsByTagName('kendo-list');
  if (elem.length > 0)
    elem[0].className = 'cont-selector-icono';
 }

 clickTipoGrafico() {
    if (this.form.value.nombre.toLowerCase().includes("kwh")) {
      this.JTipoGrafico = this.myFunctions.copy(this.JTipoGraficoOriginal);
    } else {
      this.JTipoGrafico = this.JTipoGraficoOriginal.filter(f=> (f.id != 10 && f.id != 11 && f.id != 12 && f.id != 13 && f.id != 14 && f.id != 15))
    }
    console.log(this.form.value.nombre);
 }







}
