import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/acciones`;

@Injectable()
export class AccionesService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  public getAll(): Observable<any> {
    this.loading = true;   
    return this.http.get(baseUrl);
  }

  public getById(id:Number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl+"/"+id);
  }

  public getByIdIDplan(idplan:Number): Observable<any> {
    this.loading = true;
    var url=baseUrl+"/idplan/"+idplan;
    return this.http.get(url);
  }

  public delete(data?: any): Observable<any> {
    return this.http.post<any>(baseUrl + "/eliminar", data,{ withCredentials: true });     
  }

  create(data) {
    var fIni: Date = data.fechaini;
    var fFin: Date = data.fechafin;
    return this.http.post<any>(baseUrl + "/crear", { idplan: data.idplan,Nombre: data.nombre, Descripcion:data.descripcion,Fechainicio:fIni,Fechafin:fFin,idresponsable:data.idresponsable,maquinas:data.maquinas,perdidas:data.perdidas }, { withCredentials: true });
  }

  update(data) {
    var fIni: Date = data.fechaini;
    var fFin: Date = data.fechafin;
    return this.http.post<any>(baseUrl + "/editar", { Id: data.idaccion, idplan: data.idplan, Nombre: data.nombre, Descripcion: data.descripcion, Fechainicio: fIni, Fechafin: fFin, idresponsable: data.idresponsable, maquinas: data.maquinas, perdidas: data.perdidas }, { withCredentials: true });
  }

}
