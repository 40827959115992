import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { UsuariosService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private router: Router,
    private usuariosService: UsuariosService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.usuariosService.userValue;
    if (user) {
      // check if route is restricted by permisos

      if (route.data.permisos && route.data.permisos.includes("home==2")) 
      {
        if (user.home < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      }  
      else
      if (route.data.permisos && route.data.permisos.includes("home==1")) 
      {
        if (user.home < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      }  
      else
      if (route.data.permisos && route.data.permisos.includes("GestionUsuarios==2")) 
      {
        if (user.gestionUsuarios < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("GestionUsuarios==1")) 
      {
        if (user.gestionUsuarios < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("validador==2")) 
      {
        if (user.validador < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("validador==1")) 
      {
        if (user.validador < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("bonos==2")) 
      {
        if (user.bonos < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("bonos==1")) 
      {
        if (user.bonos < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("perdidas==2")) 
      {
        if (user.perdidas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("diccionarioPerdidas==1")) 
      {
        if (user.diccionarioPerdidas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("diccionarioPerdidas==2")) 
      {
        if (user.diccionarioPerdidas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("perdidas==1")) 
      {
        if (user.perdidas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("PermisoMaquinas==2")) 
      {
        if (user.maquinas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("PermisoMaquinas==1"))
       {
        if (user.maquinas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("controlDeAsignaciones==2")) 
      {
        if (user.controlDeAsignaciones < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("controlDeAsignaciones==1")) 
      {
        if (user.controlDeAsignaciones < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planesDeAccion==2")) 
      {
        if (user.planesDeAccion < 2)
         {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planesDeAccion==1")) 
      {
        if (user.planesDeAccion < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("historicoPiezas==2")) 
      {
        if (user.historicoPiezas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("historicoPiezas==1")) 
      {
        if (user.historicoPiezas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificador==2")) 
      {
        if (user.planificador < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificador==1")) 
      {
        if (user.planificador < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorLargo==2")) 
      {
          if (user.planificadorLargo < 2) 
          {
            this.router.navigate(['/']);
            return false;
          }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorLargo==1")) 
      {
          if (user.planificadorLargo < 1) 
          {
            this.router.navigate(['/']);
            return false;
          }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorCorto==2")) 
      {
        if (user.planificadorCorto < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorCorto==1")) 
      {
        if (user.planificadorCorto < 1)
         {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorLista==2")) 
      {
        if (user.planificadorLista < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorLista==1")) 
      {
        if (user.planificadorLista < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorHerramientas==2")) 
      {
        if (user.planificadorHerramientas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("planificadorHerramientas==1")) 
      {
        if (user.planificadorHerramientas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("proyectos==2")) 
      {
        if (user.proyectos < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("proyectos==1")) 
      {
        if (user.proyectos < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeOEE==2")) 
      {
        if (user.informeOEE < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeOEE==1")) 
      {
        if (user.informeOEE < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeDisponibilidad==2")) 
      {
        if (user.informeDisponibilidad < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeDisponibilidad==1")) 
      {
        if (user.informeDisponibilidad < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeRendimiento==2")) 
      {
        if (user.informeRendimiento < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeRendimiento==1")) 
      {
        if (user.informeRendimiento < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeCalidad==2")) 
      {
        if (user.informeCalidad < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeCalidad==1")) 
      {
        if (user.informeCalidad < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("operacionesPredefinidas==2")) 
      {
        if (user.operacionesPredefinidas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("operacionesPredefinidas==1")) 
      {
        if (user.operacionesPredefinidas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeRendimiento==2")) 
      {
        if (user.informeRendimiento < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeRendimiento==1")) 
      {
        if (user.informeRendimiento < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeTecnicoEjecucion==2")) 
      {
        if (user.informeTecnicoEjecucion < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeTecnicoEjecucion==1")) 
      {
        if (user.informeTecnicoEjecucion < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("maquinas==2")) 
      {
        if (user.maquinas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("maquinas==1")) 
      {
        if (user.maquinas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("comparativaRendimiento==2")) 
      {
        if (user.comparativaRendimiento < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("comparativaRendimiento==1")) 
      {
        if (user.comparativaRendimiento < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("comparativaTiempos==2")) 
      {
        if (user.comparativaTiempos < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("comparativaTiempos==1")) 
      {
        if (user.comparativaTiempos < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      }
      else
      if (route.data.permisos && route.data.permisos.includes("informeEjecuciones==2")) 
      {
        if (user.informeEjecuciones < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeEjecuciones==1")) 
      {
        if (user.informeEjecuciones < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeDeConsumo==2")) 
      {
        if (user.informeDeConsumo < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informeDeConsumo==1")) 
      {
        if (user.informeDeConsumo < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informesIncidencias==2")) 
      {
        if (user.informesIncidencias < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informesIncidencias==1")) 
      {
        if (user.informesIncidencias < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informes==2")) 
      {
        if (user.informes < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("informes==1")) 
      {
        if (user.informes < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("calendarioDisponibilidad==2")) 
      {
        if (user.calendarioDisponibilidad < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("calendarioDisponibilidad==1")) 
      {
        if (user.calendarioDisponibilidad < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("piezasPredefinidas==2"))
       {
        if (user.piezasPredefinidas < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
      if (route.data.permisos && route.data.permisos.includes("piezasPredefinidas==1")) 
      {
        if (user.piezasPredefinidas < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
      }
      else
        if (route.data.permisos && route.data.permisos.includes("mantenimientosPredefinidos==2"))
         {
          if (user.mantenimientosPredefinidos < 2) 
          {
            this.router.navigate(['/']);
            return false;
          }
        } 
        else
         if (route.data.permisos && route.data.permisos.includes("mantenimientosPredefinidos==1"))
         {
          if (user.mantenimientosPredefinidos < 1) 
          {
            this.router.navigate(['/']);
            return false;
          }
      } 
      else
       if (route.data.permisos && route.data.permisos.includes("analiticaAvanzadaEjecuciones==2")) 
      {
        if (user.analiticaAvanzadaEjecuciones < 2) 
        {
          this.router.navigate(['/']);
          return false;
        }
      } 
      else
       if (route.data.permisos && route.data.permisos.includes("analiticaAvanzadaEjecuciones==1")) 
      {
        if (user.analiticaAvanzadaEjecuciones < 1) 
        {
          this.router.navigate(['/']);
          return false;
        }
    } 
    else
    if (route.data.permisos && route.data.permisos.includes("controles==2")) 
    {
      if (user.controles < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("controles==1")) 
    {
      if (user.controles < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  } 
  else
  if (route.data.permisos && route.data.permisos.includes("marcas==2")) 
    {
      if (user.marcas < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("marcas==1")) 
    {
      if (user.marcas < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  } 
  else
  if (route.data.permisos && route.data.permisos.includes("historicoParametros==2")) 
    {
      if (user.historicoParametros < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("historicoParametros==1")) 
    {
      if (user.historicoParametros < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("seguimiento==2")) 
    {
      if (user.seguimiento < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("seguimiento==1")) 
    {
      if (user.seguimiento < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("historicoMantenimientos==2")) 
    {
      if (user.historicoMantenimientos < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("historicoMantenimientos==1")) 
    {
      if (user.historicoMantenimientos < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("almacenes==2")) 
    {
      if (user.almacenes < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("almacenes==1")) 
    {
      if (user.almacenes < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("fabricantes==2")) 
    {
      if (user.fabricantes < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("fabricantes==1")) 
    {
      if (user.fabricantes < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("consumibles==2")) 
    {
      if (user.consumibles < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("consumibles==1")) 
    {
      if (user.consumibles < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("herramientas==2")) 
    {
      if (user.herramientas < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("herramientas==1")) 
    {
      if (user.herramientas < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("placas==2")) 
    {
      if (user.placas < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("placas==1")) 
    {
      if (user.placas < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("turnos==2")) 
    {
      if (user.turnos < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("turnos==1")) 
    {
      if (user.turnos < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("alarmasTipo==2")) 
    {
      if (user.alarmasTipo < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("alarmasTipo==1")) 
    {
      if (user.alarmasTipo < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("materiales==2")) 
    {
      if (user.materiales < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("materiales==1")) 
    {
      if (user.materiales < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("piezasTipo==2")) 
    {
      if (user.piezasTipo < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("piezasTipo==1")) 
    {
      if (user.piezasTipo < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("piezas==2")) 
    {
      if (user.piezas < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("piezas==1")) 
    {
      if (user.piezas < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("tolerancias==2")) 
    {
      if (user.tolerancias < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("tolerancias==1")) 
    {
      if (user.tolerancias < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("calidad==2")) 
    {
      if (user.calidad < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("calidad==1")) 
    {
      if (user.calidad < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else 
  if (route.data.permisos && route.data.permisos.includes("operacionesTipo==2")) 
    {
      if (user.operacionesTipo < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("operacionesTipo==1")) 
    {
      if (user.operacionesTipo < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("operaciones==2")) 
    {
      if (user.operaciones < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("operaciones==1")) 
    {
      if (user.operaciones < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("plantasIsometrico==2")) 
    {
      if (user.plantasIsometrico < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("plantasIsometrico==1")) 
    {
      if (user.plantasIsometrico < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("areaProductiva==2")) 
    {
      if (user.areaProductiva < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("areaProductiva==1")) 
    {
      if (user.areaProductiva < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("asignacionTiempos==2")) 
    {
      if (user.asignacionTiempos < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("asignacionTiempos==1")) 
    {
      if (user.asignacionTiempos < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("clientes==2")) 
    {
      if (user.clientes < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("clientes==1")) 
    {
      if (user.clientes < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("activosTipos==2")) 
    {
      if (user.activosTipos < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("activosTipos==1")) 
    {
      if (user.activosTipos < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("activos==2")) 
    {
      if (user.activos < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("activos==1")) 
    {
      if (user.activos < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("activosMaestro==2")) 
    {
      if (user.activosMaestro < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("activosMaestro==1")) 
    {
      if (user.activosMaestro < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("secciones==2")) 
    {
      if (user.secciones < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("secciones==1")) 
    {
      if (user.secciones < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("proveedores==2")) 
    {
      if (user.proveedores < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("proveedores==1")) 
    {
      if (user.proveedores < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("stock==2")) 
    {
      if (user.stock < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("stock==1")) 
    {
      if (user.stock < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else
  if (route.data.permisos && route.data.permisos.includes("instalaciones==2")) 
    {
      if (user.instalaciones < 2) 
      {
        this.router.navigate(['/']);
        return false;
      }
    } 
    else
     if (route.data.permisos && route.data.permisos.includes("instalaciones==1")) 
    {
      if (user.instalaciones < 1) 
      {
        this.router.navigate(['/']);
        return false;
      }
  }
  else  
     if (route.data.permisos) {
        //role not authorized so redirect to home page
        this.router.navigate(['/']);
        return false;
      }
      // authorized so return true
      return true;
    }

    // not logged in so redirect to login page with the return url 
    this.router.navigate(['login/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
