<ul class="list-group list-group-flush" *ngIf="router.url === '/clientes'">
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
    (mouseover)="showGridTooltip($event)">
    <kendo-grid [kendoGridBinding]="clientes" [selectedKeys]="clientesSeleccionados" [sortable]="true"
      [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}" [navigable]="true"
      kendoGridSelectBy="id" filterable="menu" (cellClick)="cellClick($event)">
      <!--BOTONES ARRIBA-->
      <ng-template kendoGridToolbarTemplate position="top">
        <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
        <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
        <button (click)="onClickEliminar()" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.clientes < 2">{{ 'eliminar' |
          translate}}</button>
      </ng-template>
      <!--id-->
      <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
      <!--checkbox-->
      <kendo-grid-checkbox-column width="3%" showSelectAll="true"></kendo-grid-checkbox-column>
      <!--nombre-->
      <kendo-grid-column width="35%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
      <!--priridad-->
      <kendo-grid-column width="14%" field="prioridad" title="{{ 'prioridad' | translate}}"></kendo-grid-column>
      <!--prioridad facturación-->
      <kendo-grid-column width="14%" field="prioridadFacturacion" title="{{ 'prioridadFacturacion2' | translate}}">
      </kendo-grid-column>
      <!--grupo-->
      <kendo-grid-column width="14%" field="grupo" title="{{ 'grupo' | translate}}"></kendo-grid-column>
      <!--TRADUCCIÓN TEXTOS DEL GRID-->
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
    <div *ngIf="!datosCargados" class="k-i-loading" style="z-index: 3;"></div>
  </div>
</ul>

<!-- POPUP: Borrar clientes -->
<ng-template #popupBorrar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntaeliminarpopup' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()" [disabled]="this.user.clientes < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>

<router-outlet></router-outlet>