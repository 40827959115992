import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/almacenes`;

@Injectable()
export class AlmacenesService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {
    return this.http.get(baseUrl + "/" + id);
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAlmacenesConImagen() {
    return this.http.post<JSON>(`${baseUrl}/GetAlmacenesConImagen/`, {}, { withCredentials: true });
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/almacenes");
  }

  insert(data) {
    var idTipoAlmacen = 0
    if (data.tipoAlmacenSeleccionado != undefined && data.tipoAlmacenSeleccionado != null) idTipoAlmacen = data.tipoAlmacenSeleccionado.id;
    var idTipoPuesto = 0
    if (data.tiposPuestossSeleccionados != undefined && data.tiposPuestossSeleccionados != null) idTipoPuesto = data.tiposPuestossSeleccionados.id;
    var idPuesto = 0
    if (data.puestosSeleccionados != undefined && data.puestosSeleccionados != null) idPuesto = data.puestosSeleccionados.id;
    var idSeccion = 0
    if (data.seccionesSeleccionadas != undefined && data.seccionesSeleccionadas != null) idSeccion = data.seccionesSeleccionadas.id;

    return this.http.post<any>(baseUrl + "/crear", {
      id: -1,
      idTipoAlmacen: idTipoAlmacen,
      idTipoPuesto: idTipoPuesto,
      idPuesto: idPuesto,
      idSeccion: idSeccion,
      nombre: data.nombre,
      capacidad: data.capacidad,
      descripcion: data.descripcion,
      nombreArchivo: data.nombreArchivo,
      archivoBase64: data.archivoBase64,
      tienePosiciones: data.tienePosiciones
    }, { withCredentials: true });
  }

  update(data) {
    var idTipoAlmacen = 0
    if (data.tipoAlmacenSeleccionado != undefined && data.tipoAlmacenSeleccionado != null) idTipoAlmacen = data.tipoAlmacenSeleccionado.id;
    var idTipoPuesto = 0
    if (data.tiposPuestossSeleccionados != undefined && data.tiposPuestossSeleccionados != null) idTipoPuesto = data.tiposPuestossSeleccionados.id;
    var idPuesto = 0
    if (data.puestosSeleccionados != undefined && data.puestosSeleccionados != null) idPuesto = data.puestosSeleccionados.id;
    var idSeccion = 0
    if (data.seccionesSeleccionadas != undefined && data.seccionesSeleccionadas != null) idSeccion = data.seccionesSeleccionadas.id;

    return this.http.post<any>(baseUrl + "/editar", {
      id: data.id,
      idTipoAlmacen: idTipoAlmacen,
      idTipoPuesto: idTipoPuesto,
      idPuesto: idPuesto,
      idSeccion: idSeccion,
      nombre: data.nombre,
      capacidad: data.capacidad,
      descripcion: data.descripcion,
      nombreArchivo: data.nombreArchivo,
      archivoBase64: data.archivoBase64,
      tienePosiciones: data.tienePosiciones
    }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("ids", data.ids);
    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

}
