<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <div class="card">
    <div class="card-body">
      <div class="clearfix">
        <!--Año-->
        <div class="float-left mr-2">
          <div class="form-group">
            <kendo-label text="{{ 'ano' | translate }}">
              <div class="caja">
                
                <kendo-numerictextbox formControlName="ano" 
                                      [autoCorrect]="true"
                                      [spinners]="false"
                                      format="0"
                                      [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"
                                      [readonly]="ano_readonly"
                                      [min]="0"
                                      [max]="9999"
                                      (valueChange)="ano_onValueChange($event)">
                </kendo-numerictextbox>
              </div>
            </kendo-label>
          </div>
        </div>
        <!--Numero semana-->
        <div class="float-left mr-2">
          <div class="form-group">
            <kendo-label text="{{ 'numSemana' | translate }}">
              <div class="caja">

                <kendo-numerictextbox formControlName="numSemana"
                [autoCorrect]="true"
                [spinners]="false"
                format="0"
                [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }" 
                [readonly]="numSemana_readonly"
                [min]="1"
                [max]="maxSemanas"
                [(ngModel)]="numSemana"
                (valueChange)="numSemana_onValueChange($event)">
              </kendo-numerictextbox>
            </div>
              
            </kendo-label>
          </div>
        </div>
        <!--Fechas-->
        <div class="float-left mr-2">
          <div class="form-group">
            <kendo-label text="{{ 'fechainicio' | translate }}">
              <div class="caja">
                <kendo-datepicker [weekNumber]="true" [readonly]="true" [(value)]="fechaInicio">
                  <kendo-calendar-messages today="{{ 'hoy' | translate }}">
                  </kendo-calendar-messages>
                </kendo-datepicker>
              </div>
            </kendo-label>
          </div>
        </div>
        <div class="float-left mr-2">
          <div class="form-group">
            <kendo-label text="{{ 'fechafin' | translate }}">
              <div class="caja">
                <kendo-datepicker [weekNumber]="true" [readonly]="true" [(value)]="fechaFin">
                  <kendo-calendar-messages today="{{ 'hoy' | translate }}">
                  </kendo-calendar-messages>
                </kendo-datepicker>
              </div>
            </kendo-label>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!--GRID-->
  <div class="card">
    <kendo-grid [kendoGridBinding]="horario" kendoGridSelectBy="idMaquina" class="turnos-grid" width="100%">
      <!--</kendo-grid>style="min-width: 1425px;"-->
      <!--id-->
      <kendo-grid-column width="100%" field="idMaquina" hidden="hidden" title="id"></kendo-grid-column>
      <!--horario-->
      <kendo-grid-column width="150px" field="maquina" title="{{ 'horario' | translate}}" [sticky]="true">
      </kendo-grid-column>

      <kendo-grid-column-group title="{{ 'lunes' | translate}}" width="195px">
        <kendo-grid-column field="lunesMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [style]="{'text-align': 'right'}" [(value)]="dataItem.lunesMannanaInicio"
              [formatPlaceholder]="{ hour: '0', minute: '00'}"></kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.lunesMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="lunesTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.lunesTardeInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.lunesTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="lunesNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.lunesNocheInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.lunesNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'martes' | translate}}" width="195px">
        <kendo-grid-column field="martesMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.martesMannanaInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.martesMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="martesTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.martesTardeInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.martesTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="martesNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.martesNocheInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.martesNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'miercoles' | translate}}" width="195px">
        <kendo-grid-column field="miercolesMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.miercolesMannanaInicio"
              [formatPlaceholder]="{ hour: '0', minute: '00'}"></kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.miercolesMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="miercolesTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.miercolesTardeInicio"
              [formatPlaceholder]="{ hour: '0', minute: '00'}"></kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.miercolesTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="miercolesNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.miercolesNocheInicio"
              [formatPlaceholder]="{ hour: '0', minute: '00'}"></kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.miercolesNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'jueves' | translate}}" width="195px">
        <kendo-grid-column field="juevesMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.juevesMannanaInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.juevesMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="juevesTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.juevesTardeInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.juevesTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="juevesNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.juevesNocheInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.juevesNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'viernes' | translate}}" width="195px">
        <kendo-grid-column field="viernesMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.viernesMannanaInicio"
              [formatPlaceholder]="{ hour: '0', minute: '00'}"></kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.viernesMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="viernesTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.viernesTardeInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.viernesTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="viernesNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.viernesNocheInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.viernesNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'sabado' | translate}}" width="195px">
        <kendo-grid-column field="sabadoMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.sabadoMannanaInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.sabadoMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="sabadoTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.sabadoTardeInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.sabadoTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="sabadoNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.sabadoNocheInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.sabadoNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'domingo' | translate}}" width="195px">
        <kendo-grid-column field="domingoMannana" title="{{ 'manana' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.domingoMannanaInicio"
              [formatPlaceholder]="{ hour: '0', minute: '00'}"></kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.domingoMannanaFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="domingoTarde" title="{{ 'tarde' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.domingoTardeInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.domingoTardeFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="domingoNoche" title="{{ 'noche' | translate}}" width="65px"
          [style]="{'text-align': 'center'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-timepicker [(value)]="dataItem.domingoNocheInicio" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
            <kendo-timepicker [(value)]="dataItem.domingoNocheFin" [formatPlaceholder]="{ hour: '0', minute: '00'}">
            </kendo-timepicker>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <!--TRADUCCIÓN TEXTOS DEL GRID-->
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
        filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
        unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}" pagerItems=""
        pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>

  <div class="card">
    <div class="card-body">
      <!--BOTONES-->
      <div class="form-group">
        <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.turnos <2">
          {{ 'guardar' | translate}}
        </button>
        <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupDuplicar(popupDuplicar)">
          {{ 'duplicar' | translate}}
        </button>
        <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
          {{ 'cancelar' | translate}}
        </button>
        <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupCopiarDias()">
          {{ 'popupCopiarTurnos' | translate}}
        </button>
        <button type="button" class="btn btn-copiar btn-sm mr-1" (click)="abrirPopupCopiarMaquina()">
          {{ 'copiarTurnosMaquina' | translate}}
        </button>
        <button type="button" class="btn btn-danger btn-sm mr-1" (click)="abrirPopupEliminar(popupEliminar)"
          [disabled]="this.user.turnos <2">
          {{ 'eliminar' | translate}}
        </button>

      </div>
    </div>
  </div>

  <!--POPUP DUPLICAR-->
  <ng-template #popupDuplicar let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'popupduplicarsemana' | translate }}</p>

      <!--Numero semana-->
      <div class="col-md-3">
        <div class="form-group">
          <kendo-textbox formControlName="numDuplicarSemana"></kendo-textbox>
        </div>
      </div>
      <div *ngIf="loadingDuplicar" class="k-i-loading"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
        }}</button>
      <button type="button" class="btn btn-copiar" (click)="duplicarSemana()" [disabled]="this.user.turnos <2">{{
        'duplicar' | translate }}</button>
    </div>
  </ng-template>

  <!-- POPUP ELIMINAR -->
  <ng-template #popupEliminar let-modal>
    <div class="modal-header">
      <h3 class="modal-title" id="modal-basic-title">{{ 'popupeliminar' | translate }}</h3>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <div class="form-group">
        <div class="row">
          <div class="col">
            <kendo-label>{{ 'turnos' | translate }}</kendo-label>


            <div class="caja">
              <kendo-multiselect kendoMultiSelectSummaryTag [data]="turnos" [textField]="'text'" valueField="value"
                placeholder="{{ 'seleccioneTurno' | translate }}" [autoClose]="false" style="width: 295px;"
                [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="valueChangeTurno($event)">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].text}}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                    'turnosSeleccionados' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                </kendo-multiselect-messages>
              </kendo-multiselect>
            </div>
          </div>


          <div class="col">
            <kendo-label>{{ 'diasSemana' | translate }}</kendo-label>

            <div class="caja">
              <kendo-multiselect kendoMultiSelectSummaryTag [data]="diasSemana" [textField]="'text'"
                [valueField]="'value'" placeholder="{{ 'seleccioneDia' | translate }}" [autoClose]="false"
                style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}"
                (valueChange)="valueChangeDia($event)">
                <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                  <span class="k-icon k-i-arrow-s"></span>
                  <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].text}}</ng-container>
                  <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                    'diasSeleccionados' | translate }}</ng-container>
                </ng-template>
                <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                </kendo-multiselect-messages>
              </kendo-multiselect>
            </div>

          </div>
        </div>
      </div>

      <div class="form-group">

        <!-- <kendo-label>{{ 'maquina' | translate }}</kendo-label>

        <div class="caja">
          <kendo-multiselect [data]="maquinasEliminarTurno" [textField]="'text'" [valueField]="'value'"
            [(ngModel)]="valuesEliminar" placeholder="{{ 'seleccioneMaquina' | translate }}" [autoClose]="false"
            style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="valueChangeMaquina()">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].text}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                'maquinasSeleccionadas' | translate }}</ng-container>
            </ng-template>

            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
            </kendo-multiselect-messages>
          </kendo-multiselect>
        </div> -->

        <kendo-label>{{ 'maquina' | translate }}</kendo-label>


        <!-- FILTRO GRUPOS -->
        <div class="row">
          <div class="col">
            <div class="form-group">
              <kendo-label text="{{ 'areaProductiva' | translate }} / {{ 'seccion' | translate }}">
                <div class="caja">
                  <kendo-multiselect [data]="areasProductivas" [textField]="'nombreSeccion'" [valueField]="'idSeccion'"
                    [(value)]="selectedAreaSeccion" (valueChange)="cambiarAreaSeccion()">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreSeccion}}
                      </ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                        'seccionesSeleccionadas'
                        |
                        translate }}</ng-container>
                    </ng-template>
                  </kendo-multiselect>
                </div>
              </kendo-label>
            </div>
          </div>
          <!-- GRUPO MAQUINAS -->
          <div class="col">
            <div class="form-group">
              <kendo-label text="{{ 'grupoMaquinas' | translate }}">
                <div class="caja">
                  <kendo-multiselect [data]="grupos" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="selectedGrupo" (valueChange)="cambiarAreaSeccion()">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}
                      </ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                        'seccionesSeleccionadas'
                        |
                        translate }}</ng-container>
                    </ng-template>
                  </kendo-multiselect>
                </div>
              </kendo-label>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col">
            <kendo-grid [kendoGridBinding]="listaMaquinasSeccion" [rowClass]="rowCallback"
              [selectable]="{enabled: true}" [selectedKeys]="valuesEliminar" [navigable]="true" [sortable]="true"
              kendoGridSelectBy="nombre" filterable="menu" [height]="300" [width]="80"
              (cellClick)="cellClickEliminar($event)">

              <ng-template kendoGridToolbarTemplate position="top">
                <div class="float-left">
                  <button type="button" (click)="checkAllMaquinasEliminar()" class="btn btn-success btn-sm mr-1">{{
                    botonSeleccionarTodo }}</button>
                </div>
              </ng-template>

              <kendo-grid-column field="nombre" title="{{'maquina' | translate}}" width="80"> </kendo-grid-column>

              <!--TRADUCCIÓN TEXTOS DEL GRID-->
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>

            </kendo-grid>
            <div class="col"></div>
          </div>
        </div>
      </div>




      <div *ngIf="loadingDuplicar" class="k-i-loading"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
        }}</button>
      <button type="button" class="btn btn-primary" (click)="eliminarDia($event)" [disabled]="this.user.turnos <2">{{
        'eliminar' | translate }}</button>
    </div>
  </ng-template>


  <!--POPUP COPIAR DIAS-->
  <ng-template #popupCopiarDias let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">{{ 'popupCopiarTurnos' | translate }}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- turno a copiar -->
        <div class="row">
            <div class="col">
                <p>{{ 'turnoACopiar' | translate }}</p>
                <kendo-dropdownlist [data]="turnoACopiar" [textField]="'nombre'" [valueField]="'id'"
                                  [(value)]="turnoACopiarSeleccionado">
              </kendo-dropdownlist>
            </div>

            <!-- turno para ser copiado-->
            <div class="col">
                <p>{{ 'turnosParaCopiar' | translate }}</p>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="lunesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==1"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'lunes' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="martesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==2"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'martes' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="miercolesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==3"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'miercoles' | translate }}</kendo-label>  <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="juevesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==4"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'jueves' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="viernesSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==5"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'viernes' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="sabadoSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==6"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'sabado' | translate }}</kendo-label> <br>
                <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="domingoSelected" [onLabel]="' '" [disabled]="this.turnoACopiarSeleccionado.id==7"
                    [offLabel]="' '"></kendo-switch>
                <kendo-label>{{ 'domingo' | translate }}</kendo-label> 
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-copiar" (click)="copiarDias()" [disabled]="this.user.turnos <2">{{ 'copiar'
            |
            translate }}</button>
    </div>
  </ng-template>

  <!--POPUP COPIAR MAQUINA-->
  <ng-template #popupCopiarMaquina let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ 'copiarTurnosMaquina' | translate }}</p>
      <div class="row">
        <div class="col">
          <kendo-label>{{ 'seleccionarMaquina' | translate }}</kendo-label> <br />
          <kendo-dropdownlist [data]="maquinas" [textField]="'text'" [valueField]="'value'" [(value)]="selectedMachine">
          </kendo-dropdownlist>
        </div>
        <div class="col">
          <kendo-label>{{ 'seleccionarMaquina2' | translate }}</kendo-label> <br />

          <kendo-switch [(value)]="seleccionarTodasMaquinas" [(checked)]="seleccionarTodasMaquinas"
            (valueChange)="seleccionarTodas($event)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
          <kendo-label *ngIf="!seleccionarTodasMaquinas">{{ 'seleccionarTodo' | translate }}</kendo-label>
          <kendo-label *ngIf="seleccionarTodasMaquinas">{{ 'deseleccionarTodo' | translate }}</kendo-label>
          <br />
          <ng-container *ngFor="let maquina of maquinas; let i= index;">
            <div *ngIf="selectedMachine.value == maquina.value">
              <kendo-switch [ngModelOptions]="{standalone: true}"  [disabled]="true"
                (valueChange)="cambioSeleccionCopiar()" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              <kendo-label>{{ maquina.value | translate }}</kendo-label>
              <br />
            </div>
            <div *ngIf="selectedMachine.value != maquina.value">
              <kendo-switch [ngModelOptions]="{standalone: true}" [(ngModel)]="maquina.selected"
                (valueChange)="cambioSeleccionCopiar()" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              <kendo-label>{{ maquina.value | translate }}</kendo-label>
              <br />
            </div>

          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
        }}</button>
      <button type="button" class="btn btn-copiar" (click)="copiarMaquinas()" [disabled]="this.user.turnos <2">{{
        'copiar' | translate }}</button>
    </div>
  </ng-template>

</form>