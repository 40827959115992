import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MaquinasService, ComboService, MenuService, ClasificacionesService, AtributosService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Marca, Control, Protocolos_DAT } from '@app/_models';
import { FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { MyFunctions } from '@app/_helpers';
import { MultiSelectTreeCheckableSettings } from "@progress/kendo-angular-dropdowns";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RowClassArgs } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-hornoTemple-detalle',
  templateUrl: './hornoTempleDetalle.component.html'
})

export class HornoTempleDetalleComponent {

  @ViewChild('popupIsometrico') modalIsometrico: NgbModalRef;
  modalReference: NgbModalRef;

  public submitable:boolean;
  public protocolos: Protocolos_DAT[];
  public selectedProtocolo: Protocolos_DAT;
  public idProtocoloSeleccionado: number;
  public events: string[] = [];
  public id: number;
  public marcas: Marca[];
  public marca_selectedItem: Marca;
  public idMarca: number;
  public controles: Control[];
  public control_selectedItem: Control;
  public idControl: number;
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public homeSeleccion: any = [];
  public homeSeleccionSeleccionado: any = [];
  public homeSeleccionParametros: any = [];
  public dibujosMaquinas: any[] = [];
  public selectedDibujoMaquina: any;
  public imageToShow: any = "../../../assets/imgMaquinas/maquina-09.png";
  public timeZonesList: any = [];
  //CLASIFICACIONES
  public clasificaciones: any = [];
  public idPadreSeleccionado: any = [];
  public checkableSettings: MultiSelectTreeCheckableSettings = {
    checkChildren: true,
    checkOnClick: true
  };
  public clasificacionesSeleccionadas = [];
  public updateClasificaciones = false;
  //ATRIBUTOS
  public atributos: any = [];
  public atributosSeleccionadas = [];
  public updateAtributos = false

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  //PROCESOS AGRUPADOS
  public agruparProcesosSwitch: boolean = false;

  // GRID RANGOS ENFRIAMIENTO/CALENTAMIENTO
  public gridRangosEnfCal = [];

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private maquinasService: MaquinasService,
    private comboService: ComboService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    public translateService: TranslateService,
    private modalService: NgbModal,
    private clasificacionesService: ClasificacionesService,
    private atributosService: AtributosService,
    public myFunctions: MyFunctions) {

    this.menuService.titulo = this.translateService.instant('hornoTemple').toUpperCase();
  }

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.submitable = true;
    if (this.id == undefined)
      this.id = -1;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['',],
      numSerie: ['',],
      numeroModelo: ['',],
      ordenModelo: ['',],
      protocolo: ['',],
      ip: ['',],
      puerto: ['',],
      tiempoSincrSeg: [0,],
      urlCamara: ['',],
      maquinaPuestoOffline: [false,],
      activo: [false,],
      hmiInicio: [true,],
      hmiManualUsuario: [true,],
      hmiProgPlanificados: [true,],
      hmiFueraTaller: [true,],
      hmiVisualizadorDePlanos: [true,],
      hmiCambioMonitor: [true,],
      hmiLibrerias: [true,],
      hmiMantenimiento: [true,],
      hmiMes: [true,],
      hmiAutodiagYCompens: [true,],
      hmiPreparacion: [true,],
      hmiAlarma: [true,],
      hmiMantenimiento2: [true,],
      hmiCambioPlaca: [true,],
      hmiEjecucion: [true,],
      idSeccion: new FormControl(undefined, [Validators.required]),
      idTimezone: new FormControl(undefined, [Validators.required]),

      tiempoMicroParada: [0,],
      tiempoMicroEjecucion: [0,],

      freq_captura_datos: [0,],

      nombreArchivo: ['',],
      archivo: ['',],
      archivoBase64: ['',],

      abreviatura: ['',],
      agruparProcesos: [false,],
      capacidadPesoMin: [0,],
      capacidadPesoMax: [0,],
      capacidadUnidadesMin: [0,],
      capacidadUnidadesMax: [0,],
      homeModIzq: {id: this.homeSeleccionSeleccionado.idHomeSeleccionIzq},
      homeModDer: {id: this.homeSeleccionSeleccionado.idHomeSeleccionDer},
      homeModParametro1: {id: this.homeSeleccionSeleccionado.idEje1},
      homeModParametro2: {id: this.homeSeleccionSeleccionado.idEje2},
      homeModParametro3: {id: this.homeSeleccionSeleccionado.idEje3},
      homeModParametro4: {id: this.homeSeleccionSeleccionado.idEje4},
      homeModParametro1_1: {id: -1},
      homeModParametro2_1: {id: -1},
      homeModParametro3_1: {id: -1},
      homeModParametro4_1: {id: -1},

      // VALORES TECNOLOGICOS
      temperaturaProgramada_activo: 0,
      temperaturaProgramada_min: 0,
      temperaturaProgramada_max: 100,
      temperaturaActual_activo: 0,
      temperaturaActual_min: 0,
      temperaturaActual_max: 100,
      temperaturaInterior_activo: 0,
      temperaturaInterior_min: 0,
      temperaturaInterior_max: 100,
      consumo_activo: 0,
      consumo_min: 0,
      consumo_max: 100,
      nivelO2_activo: 0,
      nivelO2_min: 0,
      nivelO2_max: 100,
      potencialCarbono_activo: 0,
      potencialCarbono_min: 0,
      potencialCarbono_max: 100,

      ritmo: 0,
      activarRitmo: [false,],
      tiempoMinCambioTemperatura: [0,],

      controlRepeticiones: [true,] 
      , asignarOperaciones: [true,] 
      , verReportInciden: [false,] 
      , perdidas: [true,] 
      , calidad: [false,]
      , herramientas: [false,] 
      , cabezal: [false,] 
      , gestorDocumental: [true,] 
      , camara: [true,] 
      , observaciones: [true,] 
      , escaneoDePegatinas: [false,]

      , id_contador_tipo_DAT: 2
      , mostrarSumatorio: true
      , mostrarSumatorio_param1_id_contador_tipo_DAT: 1
      , mostrarSumatorio_param2_id_contador_tipo_DAT: 2
      , id_contador_turno_tipo_DAT: 1
      , mostrarAutomatico: true
      , cantidadesTerminadasEnPreparacion: 0

    });

    this.dibujosMaquinas = [
      /* MAZAK 1x1            */ { id: 1 , dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/mazak-miniatura-02.png" },
      /* MAZAK 2x1            */ { id: 2 , dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/mazak-miniatura-02.png" },
      /* GEMINIS 3x1          */ { id: 3 , dimensiones: "3x1", imagen: "../../../assets/isometrico/iconos/miniatura/geminis-gt5i-miniatura-02.png" },
      /* DURMA LASER 2x1      */ { id: 4 , dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/durma-laser-cut-hdf-3015-miniatura-04.png" },
      /* EXTRUSORA 2x1        */ { id: 5 , dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/extrusora2x1_miniatura-01.png" },
      /* EXTRUSORA 3x1        */ { id: 6 , dimensiones: "3x1", imagen: "../../../assets/isometrico/iconos/miniatura/extrusora_3_cubos_vista_miniatura-01.png" },
      /* ONAAF25              */ { id: 7 , dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/onaaf25-01.png" },
      /* IKASMAK              */ { id: 8 , dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/ikasmak-01.png" },
      /* DOOSAN PUMA          */ { id: 9 , dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/doosanpuma700-miniatura-01.png" },
      /* KONDIA               */ { id: 10, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/kondia-miniatura-01.png" },
      /* LAGUN                */ { id: 11, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/lagun-01.png" },
      /* PINACHO              */ { id: 12, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/pinacho-miniatura-01.png" },
      /* MANDRI. JUARISTI     */ { id: 13, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/mandrinadora-juaristi-miniatura-01.png" },
      /* HORNO BONIFICADO     */ { id: 14, dimensiones: "3x1", imagen: "../../../assets/isometrico/iconos/miniatura/horno-bonificado-miniatura-01.png" },
      /* H. BONIFICADO DOBLE  */ { id: 15, dimensiones: "6x1", imagen: "../../../assets/isometrico/iconos/miniatura/horno-bonificado-doble-miniatura-01.png" },
      /* Deckel maho dmu 70 evolution  */ { id: 16, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/Deckel-maho-dmu-70-evolution-miniatura-01.png" },
      /* DMG MORI DMC 1150 V  */ { id: 17, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/dgm-mori-dmc-1150V-01.png" },
      /* SECO WARWICK         */ { id: 18, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/seco_miniatura-01.png" },
      /* ADIRA                */ { id: 19, dimensiones: "2x1", imagen: "../../../assets/isometrico/iconos/miniatura/adira_miniatura-01.png" },
      /* HORNO                */ { id: 20, dimensiones: "1x1", imagen: "../../../assets/isometrico/iconos/miniatura/horno_miniatura-01.png" },
      /* SECO WARWICK HANDIA  */ { id: 21, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/seco_handia_miniatura-01.png" },
      /* HORNO HANDIA         */ { id: 22, dimensiones: "2x2", imagen: "../../../assets/isometrico/iconos/miniatura/horno_miniatura-01.png" },
    ];

    if (this.dibujosMaquinas.length > 0) {
      this.selectedDibujoMaquina = this.dibujosMaquinas[0];
    }

    this.maquinasService.GetMaxOrden().pipe(first()).subscribe(
      (resultOrden: any) => {

      this.form = this.formBuilder.group({
        idDb: this.user.idDb,
        nombre: ['',],
        numSerie: ['',],
        numeroModelo: ['',],
        ordenModelo: [resultOrden,],
        protocolo: ['',],
        activo: [false,],
        ip: ['',],
        puerto: ['',],
        tiempoSincrSeg: [0,],
        urlCamara: ['',],
        maquinaPuestoOffline: [false,],
        hmiInicio: [true,],
        hmiManualUsuario: [true,],
        hmiProgPlanificados: [true,],
        hmiFueraTaller: [true,],
        hmiVisualizadorDePlanos: [true,],
        hmiCambioMonitor: [true,],
        hmiLibrerias: [true,],
        hmiMantenimiento: [true,],
        hmiMes: [true,],
        hmiAutodiagYCompens: [true,],
        hmiPreparacion: [true,],
        hmiAlarma: [true,],
        hmiMantenimiento2: [true,],
        hmiCambioPlaca: [true,],
        hmiEjecucion: [true,],
        idSeccion: new FormControl(undefined, [Validators.required]),
        idTimezone: new FormControl(undefined, [Validators.required]),

        tiempoMicroParada: [0,],
        tiempoMicroEjecucion: [0,],

        freq_captura_datos: [0,],

        nombreArchivo: ['',],
        archivo: ['',],
        archivoBase64: ['',],

        abreviatura: ['',],
        agruparProcesos: [false,],
        capacidadPesoMin: [0,],
        capacidadPesoMax: [0,],
        capacidadUnidadesMin: [0,],
        capacidadUnidadesMax: [0,],
        homeModIzq: {id: this.homeSeleccionSeleccionado.idHomeSeleccionIzq},
        homeModDer: {id: this.homeSeleccionSeleccionado.idHomeSeleccionDer},
        homeModParametro1: {id: this.homeSeleccionSeleccionado.idEje1},
        homeModParametro2: {id: this.homeSeleccionSeleccionado.idEje2},
        homeModParametro3: {id: this.homeSeleccionSeleccionado.idEje3},
        homeModParametro4: {id: this.homeSeleccionSeleccionado.idEje4},
        homeModParametro1_1: {id: -1},
        homeModParametro2_1: {id: -1},
        homeModParametro3_1: {id: -1},
        homeModParametro4_1: {id: -1},

        // VALORES TECNOLOGICOS
        temperaturaProgramada_activo: 0,
        temperaturaProgramada_min: 0,
        temperaturaProgramada_max: 100,
        temperaturaActual_activo: 0,
        temperaturaActual_min: 0,
        temperaturaActual_max: 100,
        temperaturaInterior_activo: 0,
        temperaturaInterior_min: 0,
        temperaturaInterior_max: 100,
        consumo_activo: 0,
        consumo_min: 0,
        consumo_max: 100,
        nivelO2_activo: 0,
        nivelO2_min: 0,
        nivelO2_max: 100,
        potencialCarbono_activo: 0,
        potencialCarbono_min: 0,
        potencialCarbono_max: 100,

        ritmo: 0,
        activarRitmo: [false,],
        tiempoMinCambioTemperatura: [0,],
        
        controlRepeticiones: [true,] 
        , asignarOperaciones: [true,] 
        , verReportInciden: [false,] 
        , perdidas: [true,] 
        , calidad: [false,]
        , herramientas: [false,] 
        , cabezal: [false,] 
        , gestorDocumental: [true,] 
        , camara: [true,] 
        , observaciones: [true,] 
        , escaneoDePegatinas: [false,]

        , id_contador_tipo_DAT: 2
        , mostrarSumatorio: true
        , mostrarSumatorio_param1_id_contador_tipo_DAT: 1
        , mostrarSumatorio_param2_id_contador_tipo_DAT: 2
        , id_contador_turno_tipo_DAT: 1
        , mostrarAutomatico: true
        , cantidadesTerminadasEnPreparacion: 0

      });

      
      var r1, r2, r3, r4, r5, r6, r7: boolean = false;

      this.comboService.Get_Marcas().pipe(first()).subscribe(
        (result: any) => {
          this.marcas = result.data;
          r1 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
        }
      );

      this.comboService.Get_Controles().pipe(first()).subscribe(
        (result: any) => {
          this.controles = result.data;
          r2 = true; 
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
        }
      );

      this.maquinasService.GetAll_Protocolos_DAT().pipe(first()).subscribe(
        (result: any) => {
          this.protocolos = result.data;
          r3 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
        }
      );

      this.userService.getComboSecciones().subscribe(json => {
        this.secciones = json;
        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        r4 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
      });

      var timezones_model = this.userService.get_timezones_model();
      if (timezones_model == false) {
        this.userService.getTimezones().subscribe((result) => {
          this.timeZonesList = result;
          r5 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
        });
      } else {
        this.timeZonesList = timezones_model;
        r5 = true;
        if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
      }

      this.maquinasService.getHomeSeleccionById(this.id, 5).pipe(first()).subscribe(
        (result: any) => {
          result.opciones.forEach(element => {
            element.nombre = this.translateService.instant(element.nombre);
            element.agrupado = this.translateService.instant(element.agrupado);
          });
          this.homeSeleccion = groupBy(result.opciones, [{ field: 'agrupado' }]);
          this.homeSeleccionParametros = result.valoresTecnologicos;
          
          if (result.seleccionado.length > 0)
            this.homeSeleccionSeleccionado = result.seleccionado[0];
          else 
            this.homeSeleccionSeleccionado = {id: -1, idHomeSeleccionIzq: 7, idHomeSeleccionDer: 4
                                                , idEje1: -1, idVariable_Tipo1: -1, idEje2: -1, idVariable_Tipo2: -1
                                                , idEje3: -1, idVariable_Tipo3: -1, idEje4: -1, idVariable_Tipo4: -1
                                              }

          this.form.value["homeModIzq"] = {id: this.homeSeleccionSeleccionado.idHomeSeleccionIzq};
          this.form.value["homeModDer"] = {id: this.homeSeleccionSeleccionado.idHomeSeleccionDer};

          r6 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
        }
      );

      this.maquinasService.getTemperaturas_rangos(this.id).pipe(first()).subscribe(
        (result: any) => {
          this.gridRangosEnfCal = result;
          this.gridRangosEnfCal.forEach(f => {
            f.delete = false;
          })
          if (this.gridRangosEnfCal.length == 0) { // esto se debe hacer para que cuando se añada una fila exista un id minimo
            this.gridRangosEnfCal.push({      
              id: 0
              , idMaquina: this.id
              , temperaturaMin: 0
              , temperaturaMax: 0
              , subida: false
              , gradosPorMinuto: 0
              , delete: true})
          }

          r7 = true;
          if (r1 && r2 && r3 && r4 && r5 && r6 && r7) this.cargarFormulario();
        }
      );

      this.cargarClasificaciones();
      this.cargarAtributos();
      
    });

  }

  //#region CLASIFICACIONES Y ATRIBUTOS
    cargarClasificaciones() {
      this.clasificacionesService.GetAll().subscribe(result => {
        var clasificacionesAux: any = result
  
        //#region OBTENER LAS CLASIFICACIONES SELECCIONADAS
        if (!this.isAddMode)
          this.clasificacionesService.GetMaquinasClasificacionById(this.id).subscribe(json => {
            var inf: any = json;
            inf.forEach(element => {
              this.clasificacionesSeleccionadas.push(clasificacionesAux.filter(f => f.id == element.idClasificacion)[0]);
            });
            this.clasificacionesSeleccionadas = this.myFunctions.copy(this.clasificacionesSeleccionadas);  // esto es necesario porque sino no identifica que se le ha cambiado el valor
          });
        //#endregion
  
        this.clasificaciones = clasificacionesAux.filter(f => f.idPadre == null);
        var arrayHijos = clasificacionesAux.filter(f => f.idPadre != null);
        while (arrayHijos.length != 0) {
          var idHijos = [];
          arrayHijos.forEach(element => {
            this.clasificaciones.forEach(row => {
              if (row.id == element.idPadre || row.idSubHijos?.includes(element.idPadre)) {
                idHijos.push(element.id)
                if (!row['clasificacionInferior']) {
                  row.clasificacionInferior = [];
                  row.idSubHijos = [];
                }
                
                row.idSubHijos.push(element.id)
                if(row.id == element.idPadre) row.clasificacionInferior.push(this.myFunctions.copy(element));
                else row.clasificacionInferior = this.setHijos(row.clasificacionInferior, element, 'clasificacionInferior');
              }
            });
          });
          arrayHijos = arrayHijos.filter(f => !idHijos.includes(f.id))
        }
      });
    }
    cargarAtributos() {
      this.atributosService.getAtributos().subscribe(
        (result) => {
          var atributosAux: any = result;
  
          //#region OBTENER LAS ATRIBUTOS SELECCIONADAS
          if (!this.isAddMode)
            this.atributosService.GetMaquinasAtributosById(this.id).subscribe(json => {
              var inf: any = json;
              inf.forEach(element => {
                this.atributosSeleccionadas.push(atributosAux.filter(f => f.id == element.idAtributo)[0]);
              });
              this.atributosSeleccionadas = this.myFunctions.copy(this.atributosSeleccionadas); // esto es necesario porque sino no identifica que se le ha cambiado el valor
            });
          //#endregion
  
          //#region AGRUPAR LAS atributos
          this.atributos = atributosAux.filter(f => f.idPadre == null);
          var arrayHijos = atributosAux.filter(f => f.idPadre != null);
          while (arrayHijos.length != 0) {
            var idHijos = [];
            arrayHijos.forEach(element => {
              this.atributos.forEach(row => {
                if (row.id == element.idPadre || row.idSubHijos?.includes(element.idPadre)) {
                  idHijos.push(element.id)
                  if (!row['atributoInferior']) {
                    row.atributoInferior = [];
                    row.idSubHijos = [];
                  }
                  
                  row.idSubHijos.push(element.id)
                  if(row.id == element.idPadre) row.atributoInferior.push(this.myFunctions.copy(element));
                  else row.atributoInferior = this.setHijos(row.atributoInferior, element, 'atributoInferior');
                }
              });
            });
            arrayHijos = arrayHijos.filter(f => !idHijos.includes(f.id))
          }
  
          //#endregion
  
  
        });
    }
    // Funcion recursiva para encontrar el padre 
    setHijos(clasificacionInferiorArray, hijo, nombreInferior) {
      clasificacionInferiorArray.every(element => {
        // si el elemento es el padre
        if (element.id == hijo.idPadre) {
          if (!element[nombreInferior]) {
              element.clasificacionInferior = []
              element.idSubHijos = [];
          }
          element.clasificacionInferior.push(this.myFunctions.copy(hijo))
          element.idSubHijos.push(hijo.id);
          return false;
        }
        // si el elemento contiene un hijo que sea su padre
        else if (element.idSubHijos?.includes(hijo.idPadre)) {
          element.clasificacionInferior = this.setHijos(element.clasificacionInferior, hijo, nombreInferior)
          return false;
        }
  
        return true
      });
  
      return clasificacionInferiorArray;
    }
    
    tagMapper(tags: any[]): any[] {
      return tags.length < 2 ? tags : [tags];
    }
    isNodeExpanded() {
      return true
    }
  
    // esta funcion se utiliza para saber si se ha cabiado la seleccion de las clasificaciones o atributos
    // en caso de haberse cambiado, entonces se insertara en la DB
    // tipo == 1 => clasificacion
    // tipo == 2 => atributo
    valueChange(event, tipo) {
      if (tipo == 1) this.updateClasificaciones = true
      else this.updateAtributos = true
    }
    //#endregion

  cargarFormulario() {
    if (this.id > 0) {
      this.maquinasService.GetHornoTempleById(this.id).pipe().subscribe((result) => {
        this.marca_selectedItem = new Marca(result.data[0].idmarca, "");
        this.idMarca = result.data[0].idmarca;
        this.control_selectedItem = new Control(result.data[0].idcontrol, "");
        this.idControl = result.data[0].idcontrol;
        if (result.data[0].idprotocolo != undefined) {
          this.selectedProtocolo = new Protocolos_DAT(result.data[0].idprotocolo, "");
          this.idProtocoloSeleccionado = result.data[0].idprotocolo;
        }
        if (result.data[0].imagenBase64)
          this.imageToShow = result.data[0].imagenBase64;
        else 
          this.imageToShow = "../../../assets/imgMaquinas/maquina-09.png";

        var dibujoMaquinaSelec = this.dibujosMaquinas.find(x => x.id == result.data[0].idDibujoMaquina)

        if (dibujoMaquinaSelec != undefined) {
          this.selectedDibujoMaquina = dibujoMaquinaSelec;
        }

        this.maquinasService.Get_ConfiguracionHMI(-1, this.id).pipe().subscribe((result_hmi) => {
          if (result_hmi.data.length == 0) {
            result_hmi.data = [{
              menuInicio: 1
              , menuManualUsuario: 1
              , progPlanificados: 1
              , menuFueraTaller: 1
              , menuVisualizadorPlanos: 1
              , herramientaPlanificada: 1
              , menuCambioMonitor: 1
              , menuLibrerias: 1
              , menuMantenimiento: 1
              , menuMes: 1
              , menuAutodiagnostico: 1
              , preparacion: 1
              , menuHerramientas: 1
              , roturaHerramienta: 1
              , alarma: 1
              , mantenimiento: 1
              , cambioPlaca: 1
              , ejecucion: 1
              , controlRepeticiones: 1 
              , asignarOperaciones: 1 
              , verReportInciden: 0 
              , perdidas: 1 
              , calidad: 0
              , herramientas: 0 
              , cabezal: 0 
              , gestorDocumental: 1 
              , camara: 1 
              , observaciones: 1 
              , escaneoDePegatinas: 0
            }]
          }

          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            nombre: [result.data[0].nombre,],
            numSerie: [result.data[0].numSerie,],
            numeroModelo: [result.data[0].numeroModelo,],
            ordenModelo: [result.data[0].ordenModelo,],
            protocolo: [result.data[0].protocolo,],
            activo: [result.data[0].activo,],
            ip: [result.data[0].ip,],
            puerto: [result.data[0].puerto,],
            tiempoSincrSeg: [result.data[0].tiempoRecogidaDatos,],
            urlCamara: [result.data[0].linkCamara,],
            maquinaPuestoOffline: [result.data[0].puestoOffline,],
            hmiInicio: [result_hmi.data[0].menuInicio,],
            hmiManualUsuario: [result_hmi.data[0].menuManualUsuario,],
            hmiProgPlanificados: [result_hmi.data[0].progPlanificados,],
            hmiFueraTaller: [result_hmi.data[0].menuFueraTaller,],
            hmiVisualizadorDePlanos: [result_hmi.data[0].menuVisualizadorPlanos,],
            hmiCambioMonitor: [result_hmi.data[0].menuCambioMonitor,],
            hmiLibrerias: [result_hmi.data[0].menuLibrerias,],
            hmiMantenimiento: [result_hmi.data[0].menuMantenimiento,],
            hmiMes: [result_hmi.data[0].menuMes,],
            hmiAutodiagYCompens: [result_hmi.data[0].menuAutodiagnostico,],
            hmiPreparacion: [result_hmi.data[0].preparacion,],
            hmiAlarma: [result_hmi.data[0].alarma,],
            hmiMantenimiento2: [result_hmi.data[0].mantenimiento,],
            hmiCambioPlaca: [result_hmi.data[0].cambioPlaca,],
            hmiEjecucion: [result_hmi.data[0].ejecucion,],
            idSeccion: new FormControl(this.secciones.find(x => x.id == result.data[0].idSeccion), [Validators.required]),
            idTimezone: new FormControl(this.timeZonesList.find(x => x.id == result.data[0].idTimezone), [Validators.required]),

            tiempoMicroParada: [result.data[0].tiempoMicroParada,],
            tiempoMicroEjecucion: [result.data[0].tiempoMicroEjecucion,],

            freq_captura_datos: [result.data[0].freq_captura_datos,],

            nombreArchivo: [result.data[0].imagen,],
            archivo: ['',],
            archivoBase64: ['',],

            abreviatura: [result.data[0].abreviatura,],
            agruparProcesos: [result.data[0].agruparProcesos,],
            capacidadPesoMin: [result.data[0].capacidadPesoMinimo,],
            capacidadPesoMax: [result.data[0].capacidadPesoMaximo,],
            capacidadUnidadesMin: [result.data[0].capacidadUnidadesMinimo,],
            capacidadUnidadesMax: [result.data[0].capacidadUnidadesMaximo,],
            homeModIzq: {id: this.homeSeleccionSeleccionado.idHomeSeleccionIzq},
            homeModDer: {id: this.homeSeleccionSeleccionado.idHomeSeleccionDer},
            homeModParametro1: {id: this.homeSeleccionSeleccionado.idEje1},
            homeModParametro2: {id: this.homeSeleccionSeleccionado.idEje2},
            homeModParametro3: {id: this.homeSeleccionSeleccionado.idEje3},
            homeModParametro4: {id: this.homeSeleccionSeleccionado.idEje4},
            homeModParametro1_1: {id: -1},
            homeModParametro2_1: {id: -1},
            homeModParametro3_1: {id: -1},
            homeModParametro4_1: {id: -1},

            // VALORES TECNOLOGICOS
            temperaturaProgramada_activo: [result.data[0].temperaturaProgramada_activo,],
            temperaturaProgramada_min: [result.data[0].temperaturaProgramada_min,],
            temperaturaProgramada_max: [result.data[0].temperaturaProgramada_max,],
            temperaturaActual_activo: [result.data[0].temperaturaActual_activo,],
            temperaturaActual_min: [result.data[0].temperaturaActual_min,],
            temperaturaActual_max: [result.data[0].temperaturaActual_max,],
            temperaturaInterior_activo: [result.data[0].temperaturaInterior_activo,],
            temperaturaInterior_min: [result.data[0].temperaturaInterior_min,],
            temperaturaInterior_max: [result.data[0].temperaturaInterior_max,],
            consumo_activo: [result.data[0].consumo_activo,],
            consumo_min: [result.data[0].consumo_min,],
            consumo_max: [result.data[0].consumo_max,],
            nivelO2_activo: [result.data[0].nivelO2_activo,],
            nivelO2_min: [result.data[0].nivelO2_min,],
            nivelO2_max: [result.data[0].nivelO2_max,],
            potencialCarbono_activo: [result.data[0].potencialCarbono_activo,],
            potencialCarbono_min: [result.data[0].potencialCarbono_min,],
            potencialCarbono_max: [result.data[0].potencialCarbono_max,],

            ritmo: [result.data[0].ritmo,],
            activarRitmo: [result.data[0].activarRitmo,],
            tiempoMinCambioTemperatura: [result.data[0].tiempoMinCambioTemperatura,],
            
            controlRepeticiones: [result_hmi.data[0].controlRepeticiones,] 
            , asignarOperaciones: [result_hmi.data[0].asignarOperaciones,] 
            , verReportInciden: [result_hmi.data[0].verReportInciden,] 
            , perdidas: [result_hmi.data[0].perdidas,] 
            , calidad: [result_hmi.data[0].calidad,]
            , herramientas: [result_hmi.data[0].herramientas,] 
            , cabezal: [result_hmi.data[0].cabezal,] 
            , gestorDocumental: [result_hmi.data[0].gestorDocumental,] 
            , camara: [result_hmi.data[0].camara,] 
            , observaciones: [result_hmi.data[0].observaciones,] 
            , escaneoDePegatinas: [result_hmi.data[0].escaneoDePegatinas,]

            , id_contador_tipo_DAT: 2
            , mostrarSumatorio: true
            , mostrarSumatorio_param1_id_contador_tipo_DAT: 1
            , mostrarSumatorio_param2_id_contador_tipo_DAT: 2
            , id_contador_turno_tipo_DAT: 1
            , mostrarAutomatico: true
            , cantidadesTerminadasEnPreparacion: 0
          });

          this.agruparProcesosSwitch = result.data[0].agruparProcesos;


        })
      })

    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid || !this.submitable) {
      this.submitable = true;
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    if (!this.form.value.agruparProcesos) {
      this.form.value.capacidadPesoMin = 0;
      this.form.value.capacidadPesoMax = 0;
      this.form.value.capacidadUnidadesMin = 0;
      this.form.value.capacidadUnidadesMax = 0;
    }
    if (this.form.value.puerto == "")
      this.form.value.puerto = 0;
    this.maquinasService.insertHornoTemple(this.form.value, this.idMarca, this.idControl, this.idProtocoloSeleccionado, this.selectedDibujoMaquina.id).subscribe((result) => {
      if (result.error == false) {
        this.maquinasService.Insert_ConfiguracionHMI(this.form.value, result.id).subscribe((result) => {
          if (result.error == false) {
            this.router.navigate(['maquinas']);
          } else {
            this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
          }
        });

        //#region INSERT CLASIFICACIONES Y ATRIBUTOS SOLO SI ES NECESARIO
        if (this.updateClasificaciones) {
          var idClasificaciones = [];
          this.clasificacionesSeleccionadas.forEach(element => {
            idClasificaciones.push(element.id);
          });
          this.maquinasService.Insert_Clasificaciones(result.id, idClasificaciones).subscribe((result) => {
          });
        }
        if (this.updateAtributos) {
          var idAtributos = [];
          this.atributosSeleccionadas.forEach(element => {
            idAtributos.push(element.id);
          });
          this.maquinasService.Insert_Atributos(result.id, idAtributos).subscribe((result) => {
          });
        }
        //#endregion

        this.maquinasService.actualizarHomeSeleccion(result.id, this.form.value["homeModIzq"].id,this.form.value["homeModDer"].id
                                                    , this.form.value["homeModParametro1"].id, this.form.value["homeModParametro1_1"].id, 0
                                                    , this.form.value["homeModParametro2"].id, this.form.value["homeModParametro2_1"].id, 0
                                                    , this.form.value["homeModParametro3"].id, this.form.value["homeModParametro3_1"].id, 0
                                                    , this.form.value["homeModParametro4"].id, this.form.value["homeModParametro4_1"].id, 0).subscribe((result2) => {
        });

        this.maquinasService.actualizarMaquinasContadorConfig(result.id, this.form.value["id_contador_tipo_DAT"], this.form.value["mostrarSumatorio"], this.form.value["mostrarSumatorio_param1_id_contador_tipo_DAT"], this.form.value["mostrarSumatorio_param2_id_contador_tipo_DAT"]
          , this.form.value["id_contador_turno_tipo_DAT"],this.form.value[" mostrarAutomatico"], this.form.value["cantidadesTerminadasEnPreparacion"]).subscribe((result2) => {
          });

        this.gridRangosEnfCal.forEach(f => {
          f.idMaquina = result.id;
        })
        this.maquinasService.updateTemperaturas_rangos(this.gridRangosEnfCal).subscribe((result2) => {
        });
      } else {
      }
    });

  }

  private update() {
    if (!this.form.value.agruparProcesos) {
      this.form.value.capacidadPesoMin = 0;
      this.form.value.capacidadPesoMax = 0;
      this.form.value.capacidadUnidadesMin = 0;
      this.form.value.capacidadUnidadesMax = 0;
    }

    this.form.value.id = this.id;
    this.maquinasService.updateHornoTemple(this.form.value, this.idMarca, this.idControl, this.idProtocoloSeleccionado, this.selectedDibujoMaquina.id).subscribe((result) => {
      if (result.error == false) {

        this.maquinasService.Update_ConfiguracionHMI(this.form.value).subscribe((result) => {
          if (result.error == false) {
            this.router.navigate(['maquinas']);
          } else {
            this.alertService.error(this.translateService.instant('error'), { keepAfterRouteChange: true });
          }
        });

        //#region INSERT CLASIFICACIONES Y ATRIBUTOS SOLO SI ES NECESARIO
        if (this.updateClasificaciones) {
          var idClasificaciones = [];
          this.clasificacionesSeleccionadas.forEach(element => {
            idClasificaciones.push(element.id);
          });
          this.maquinasService.Insert_Clasificaciones(this.id, idClasificaciones).subscribe((result) => {
          });
        }
        if (this.updateAtributos) {
          var idAtributos = [];
          this.atributosSeleccionadas.forEach(element => {
            idAtributos.push(element.id);
          });
          this.maquinasService.Insert_Atributos(this.id, idAtributos).subscribe((result) => {
          });
        }
        //#endregion

        this.maquinasService.actualizarHomeSeleccion(this.id, this.form.value["homeModIzq"].id,this.form.value["homeModDer"].id
                                                    , this.form.value["homeModParametro1"].id, this.form.value["homeModParametro1_1"].id, 0
                                                    , this.form.value["homeModParametro2"].id, this.form.value["homeModParametro2_1"].id, 0
                                                    , this.form.value["homeModParametro3"].id, this.form.value["homeModParametro3_1"].id, 0
                                                    , this.form.value["homeModParametro4"].id, this.form.value["homeModParametro4_1"].id, 0).subscribe((result2) => {
        });

        this.maquinasService.actualizarMaquinasContadorConfig(this.id, this.form.value["id_contador_tipo_DAT"], this.form.value["mostrarSumatorio"], this.form.value["mostrarSumatorio_param1_id_contador_tipo_DAT"], this.form.value["mostrarSumatorio_param2_id_contador_tipo_DAT"]
          , this.form.value["id_contador_turno_tipo_DAT"],this.form.value[" mostrarAutomatico"], this.form.value["cantidadesTerminadasEnPreparacion"]).subscribe((result2) => {
          });

        this.maquinasService.updateTemperaturas_rangos(this.gridRangosEnfCal).subscribe((result2) => {
        });
      }
    });
  }

  public guardar() {
    this.onSubmit();
  }

  public atras() {
    this.router.navigate(['maquinas']);
  }

  //#region COMBOBOX
  public marcas_selectionChange(value: any): void {
    this.idMarca = value.id
  }

  public controles_selectionChange(value: any): void {
    this.idControl = value.id
  }
  public protocolos_selectionChange(value: any): void {
    this.idProtocoloSeleccionado = value.id
  }
  //#endregion

  //AZABAL: IMAGEN NUEVO
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.form.controls['nombreArchivo'].setValue(e.files[0].name);

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
      th.imageToShow = archivoBase64;
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
    this.imageToShow = "../../../assets/imgMaquinas/maquina-09.png";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //#region POPUP ISOMETRICO
  onClickAbrirPopup() {
  this.modalReference = this.modalService.open(this.modalIsometrico, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  onClickCerrarPopup() {
  this.modalReference.close();
  }
  //#endregion

  onChangeAgruparProcesos(e) {
    this.agruparProcesosSwitch = e;
    this.form.value.agruparProcesos = e;
  }

  //#region onChange Valores tecnologicos
  public onChangetemperaturaProgramada(value) {
    if (!value) {
      this.form.controls['temperaturaProgramada_min'].setValue(0);
      this.form.controls['temperaturaProgramada_max'].setValue(0);
    }
  }
  public onChangetemperaturaActual(value) {
    if (!value) {
      this.form.controls['temperaturaActual_min'].setValue(0);
      this.form.controls['temperaturaActual_max'].setValue(0);
    }
  }
  public onChangetemperaturaInterior(value) {
    if (!value) {
      this.form.controls['temperaturaInterior_min'].setValue(0);
      this.form.controls['temperaturaInterior_max'].setValue(0);
    }
  }
  public onChangeconsumo(value) {
    if (!value) {
      this.form.controls['consumo_min'].setValue(0);
      this.form.controls['consumo_max'].setValue(0);
    }
  }
  public onChangenivelO2(value) {
    if (!value) {
      this.form.controls['nivelO2_min'].setValue(0);
      this.form.controls['nivelO2_max'].setValue(0);
    }
  }
  public onChangepotencialCarbono(value) {
    if (!value) {
      this.form.controls['potencialCarbono_min'].setValue(0);
      this.form.controls['potencialCarbono_max'].setValue(0);
    }
  }
  //#endregion

  //#region RANGOS TEMPERATURAS
  eliminarRangoTemperatura(id) {
    this.gridRangosEnfCal.filter(f => f.id == id)[0].delete = true;
  }
  public rowCallback(context: RowClassArgs) {
    switch (context.dataItem.delete) {
      case false:
        return "";
      case true:
        return "d-none";
      default:
        return "";
    }
  }
  botonAnnadirRangoTemperatura() {
    var idMin = Math.min(...this.gridRangosEnfCal.map(f => f.id));
    this.gridRangosEnfCal.push({
      id: idMin - 1
      , idMaquina: this.id
      , temperaturaMin: 0
      , temperaturaMax: 0
      , subida: false
      , gradosPorMinuto: 0
      , delete: false
    })
  }
  onChangeTemperatura(id) {
    var inf = this.gridRangosEnfCal.filter(f => f.id == id)[0];
    if (inf?.temperaturaMin > inf.temperaturaMax) {
      this.gridRangosEnfCal.filter(f => f.id == id)[0].subida = false;
    } else {
      this.gridRangosEnfCal.filter(f => f.id == id)[0].subida = true;
    }
  }
  //#endregion
}