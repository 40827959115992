import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, Eskola_ReservaMaquinasService, MenuService, Eskola_CursosLectivosService, UsuariosService, Eskola_AlumnosService, EskolaGruposService, Eskola_AsignaturasRetosService, Eskola_OfsService, Eskola_OfsPiezasService, Eskola_HorariosSeccionesService, Eskola_MaquinasService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';
import { Reserva } from "@app/_models/reserva";
import { NotEqualFilterOperatorComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-informeAlumno',
  templateUrl: './eskola_informeAlumno.component.html'
})
export class Eskola_InformeAlumnoComponent implements OnInit {

  //variables grupo
  misGrupos: any = [];
  jGrupoFiltro: any = [];

  //variables horariossecciones
  dtHorariosSecciones: any = [];
  horariosSecciones: any = [];
  misHorariosSeccionesGrupo: any = [];

  dtCursos;
  cursos;

  dtHorarios;
  horarios;


  grupos;
  dtGrupos;

  maquinas;
  dtMaquinas;

  dtAlumnos;
  alumnos;

  dtOfs;
  ofs:any = [];

  dtReservas:any;
  reservas: any;

  status:boolean = true;
  loadingFiltro:boolean = false;
  loadingGantt: boolean = false;
  
  loadingPanel: boolean = false;

  constructor(private cursosLectivosService: Eskola_CursosLectivosService,
    private reservasService: Eskola_ReservaMaquinasService,
    private alumnosService: Eskola_AlumnosService,
    private gruposService: EskolaGruposService,
    private horariosService: Eskola_HorariosSeccionesService,
    private eskola_maquinasService: Eskola_MaquinasService,
    private eskola_cursosService: Eskola_CursosLectivosService,
    private eskolaOfsService: Eskola_OfsService,
    private eskolaOfsPiezasService: Eskola_OfsPiezasService,
    private alertService: AlertService,
    private asignaturasRetosService: Eskola_AsignaturasRetosService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private myFunctions: MyFunctions,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('informeAlumno').toUpperCase();


  }

  ngOnInit(): void {
    this.loadingPanel = true;
    this.cargarDatos();
  }

  // carga los datos necesarios de la db
  cargarDatos() {


  }



 
}
