<style>
  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* :host /deep/ .k-grid tr.rojo {
    background-color: #ff634780;
  }

  :host /deep/ .k-grid tr.rojo:hover {
    background-color: #ff634780;
  }

  :host /deep/ .k-grid tr.rojo.k-state-selected td {
    background-color: #ff634780;
  } */

  /* :host /deep/ .k-grid tr.naranja {
    background-color: #ee862580;
  }

  :host /deep/ .k-grid tr.naranja:hover {
    background-color: #ee862580;
  }

  :host /deep/ .k-grid tr.naranja.k-state-selected td {
    background-color: #ee862580;
  } */
</style>

<!-- FILTRO -->
<div class="card d-none">
  <div class="card-header">
    <h3>
      <label>{{ 'filtro' | translate}}</label>
    </h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">

    <!--FECHA INICIO-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'fechainicio' | translate }}</label>
        <div class="caja">
          <kendo-datepicker [(value)]="fechaIni" class="form-control" style="max-width: 170px;"></kendo-datepicker>
        </div>
      </div>
    </div>

    <!--FECHA FIN-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'fechafin' | translate }}</label>
        <div class="caja">
          <kendo-datepicker [(value)]="fechaFin" class="form-control" style="max-width: 170px;"></kendo-datepicker>
        </div>
      </div>
    </div>

    <!--LISTA TURNOS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'turno' | translate }}</label>
        <div class="caja">
          <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaTurnos" [(ngModel)]="turnosSeleccionados"
                             [textField]="'nombreTurno'" [valueField]="'idTurno'"
                             placeholder="{{ 'seleccioneTurno' | translate }}"
                             [autoClose]="false" style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreTurno }}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'turnosSeleccionados' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA MAQUINAS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'maquina' | translate }}</label>
        <div class="caja">
          <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaMaquinas" [(ngModel)]="maquinasSeleccionadas"
                             [textField]="'nombreMaquina'" [valueField]="'idMaquina'"
                             placeholder="{{ 'seleccioneMaquina2' | translate }}" [autoClose]="false"
                             style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreMaquina}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'maquinasSeleccionadas' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA HERRAMIENTAS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'herramienta' | translate }}</label>
        <div class="caja">
          <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaHerramientas"
                             [(ngModel)]="herramientasSeleccionadas" [textField]="'nombreHerramienta'"
                             [valueField]="'idHerramienta'"
                             placeholder="{{ 'seleccioneHerramienta' | translate }}" [autoClose]="false"
                             style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreHerramienta}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'herramientasSeleccionadas' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA OPERARIOS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'operario' | translate }}</label>
        <div class="caja">
          <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaOperarios" [(ngModel)]="operariosSeleccionados"
                             [textField]="'nombreOperario'" [valueField]="'idOperario'"
                             placeholder="{{ 'seleccioneOperario' | translate }}" [autoClose]="false"
                             style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperario}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'operariosSeleccionados' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA OFS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'of' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOfs"
                             [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'" [valueField]="'idOf'"
                             placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false" style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'ofsSeleccionadas' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA CLIENTES-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'cliente' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaClientes"
                             [(ngModel)]="clientesSeleccionados" [textField]="'nombreCliente'"
                             [valueField]="'idCliente'"
                             placeholder="{{ 'seleccioneCliente' | translate }}" [autoClose]="false"
                             style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'clientesSeleccionados' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA PIEZAS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'pieza' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPiezas"
                             [(ngModel)]="piezasSeleccionadas" [textField]="'nombrePieza'" [valueField]="'idPieza'"
                             placeholder="{{ 'seleccionePieza' | translate }}" [autoClose]="false" style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'piezasSeleccionadas' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA PARTES (SI !ocultarParte no enseñar) -->
    <div class="float-left mr-2" *ngIf="!user.ocultarParte">
      <div class="form-group">
        <label>{{ 'parte' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaPartes"
                             [(ngModel)]="partesSeleccionadas" [textField]="'nombreParte'" [valueField]="'idParte'"
                             placeholder="{{ 'seleccioneParte' | translate }}" [autoClose]="false" style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreParte}}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'partesSeleccionadas' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--LISTA PROCESOS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'operacion' | translate }}</label>
        <div class="caja">
          <kendo-multiselect (valueChange)="CambioFiltro()" kendoMultiSelectSummaryTag [data]="listaOperaciones"
                             [(ngModel)]="operacionesSeleccionadas" [textField]="'nombreOperacion'"
                             [valueField]="'idOperacion'"
                             placeholder="{{ 'seleccioneOperacion' | translate }}" [autoClose]="false"
                             style="width: 295px;"
                             [kendoDropDownFilter]="{operator: 'contains'}">
            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
              <span class="k-icon k-i-arrow-s"></span>
              <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperacion }}</ng-container>
              <ng-container *ngIf="dataItems.length > 1">
                {{ dataItems.length }} {{ 'operacionesSeleccionadas' | translate }}
              </ng-container>
            </ng-template>
            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
          </kendo-multiselect>
        </div>
      </div>
    </div>

    <!--TERMINADOS-->
    <div class="float-left mr-2">
      <div class="form-group">
        <label>{{ 'terminados' | translate }}</label>
        <div class="caja" style="margin: 0 0 0 7px;">
          <kendo-switch [(ngModel)]="terminados" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
        </div>
      </div>
    </div>

    <button type="button" class="btn btn-danger mr-1 float-left nolabel" (click)="btnLimpiarFiltro()"> {{
      'limpiarFiltro' | translate }} </button>
    <button type="button" class="btn btn-primary float-left nolabel" (click)="btnFiltrar()"> {{ 'filtrar' | translate }}
    </button>

  </div>
</div>
<!-- INFO PIEZA -->
<div class="card historico">
  <div class="card-header">
    <h3>
      <label>{{ 'pieza' | translate}}</label>
    </h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <!-- CONTENIDO -->
    <div class="clearfix">
      <div class="row">
        <!-- OF -->

        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'of' | translate }}</label>
          <label class="bloques-info-valor">{{infoOF}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'cliente' | translate }}</label>
          <label class="bloques-info-valor">{{infoCliente}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'proyecto' | translate }}</label>
          <label class="bloques-info-valor">{{infoProyecto}}</label>
        </div>

        <!-- PIEZA -->

        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'plano' | translate }}</label>
          <label class="bloques-info-valor">{{infoPlano}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'refPieza' | translate }}</label>
          <label class="bloques-info-valor">{{infoRefPieza}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
          <label class="bloques-info-valor">{{infoPieza}}</label>
        </div>

        <div class="bloques-info" *ngIf="user.verColada">
          <label class="bloques-info-label">{{ 'pedircolada' | translate }}</label>
          <label class="bloques-info-valor">{{coladaCAF}}</label>
        </div>
        <!-- N SERIE -->
        <div class="bloques-info" *ngIf="user.verNSerie">
          <label class="bloques-info-label">{{ 'nserie' | translate }}</label>
          <label class="bloques-info-valor">{{infonserie}}</label>
        </div>
        <div class="bloques-info" *ngIf="user.verLote">
          <label class="bloques-info-label">{{ 'lote' | translate }}</label>
          <label class="bloques-info-valor">{{ loteCAF }}</label>
        </div>
        <div class="bloques-info">
          <label class="bloques-info-label">{{ 'cantidad' | translate }}</label>
          <label class="bloques-info-valor"> {{ cantidadLoteHechas }} / {{cantidadLote }} </label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'tEstimado' | translate }}</label>
          <label class="bloques-info-valor">{{infoTiempoEstimado}}</label>
        </div>
        <div class="bloques-info">
          <label class="bloques-info-label">
            <span class="tooltip-consumibles-contenido">
              <i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="tiempoAsigandoPiezaNoPreparacion" class="tooltipTextHeader"></span>
            </span>
            {{ 'tiempo' | translate }}
          </label>
          <label class="bloques-info-valor">{{infoTiempoRealEjecucion}}</label>
        </div>
        <div class="bloques-info" *ngIf="usuarioIdDb!=usuarioIdDbGamesa">
          <!-- NO ENSEÑAR PARA GAMESA -->
          <label class="bloques-info-label">{{ 'tPreparacion' | translate }}</label>
          <label class="bloques-info-valor">{{infoTiempoRealPreparacion}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'desvio' | translate }}</label>
          <label class="bloques-info-valor">{{infoDesvio}}</label>
        </div>
        <div class="bloques-info ">
          <label class="bloques-info-label">{{ 'desvioPorcentaje' | translate }}</label>
          <label class="bloques-info-valor">{{infoPorcenDesvio}}</label>
        </div>
        <div class="bloques-info">
          <label class="bloques-info-label">{{ 'estado' | translate }}</label>
          <label class="bloques-info-valor" *ngIf="idEstado==1">{{'enProceso' | translate}}</label>
          <label class="bloques-info-valor" *ngIf="idEstado==2">{{'acabada' | translate}}</label>
          <label class="bloques-info-valor" *ngIf="idEstado==3">{{'validada' | translate}}</label>
          <label class="bloques-info-valor" *ngIf="idEstado==4">{{'apartada' | translate}}</label>
          <label class="bloques-info-valor" *ngIf="idEstado==5">{{'chatarra' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- INFO OPERACION -->
<div class="card historico">
  <div class="card-header">
    <h3>
      <label>{{ 'operacion' | translate}}</label>
    </h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <div class="clearfix">
      <button type="button" class="btn btn-info m-1 float-left" (click)="btnAnaliticaAvanzada()">{{ 'analiticaAvanzadaEjecuciones' | translate }}</button>

      <button type="button" class="btn btn-info m-1 float-left" (click)="btnInformeTecnico()">{{ 'informetecnicoejecucion' | translate }}</button>

      <div class="form-group mb-0 float-left" style="float: left; margin-left: 20px; margin-right: 20px" *ngIf="exportarExcel">
        <div class="form-row">
          <label class="control-label mr-2">{{ 'subirarchivoexcel' | translate }}</label>
          <kendo-upload (upload)="onUploadButtonClick($event,contentloadingupload)" [restrictions]="{allowedExtensions: ['.xlsx']  }" [(ngModel)]="myFiles">
            <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                   clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                   dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                   externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                   fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                   fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                   filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                   filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                   filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                   headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                   headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                   headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                   invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                   invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                   invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                   pause="{{ 'pause' | translate }}"
                                   remove="{{ 'remove' | translate }}"
                                   resume="{{ 'resume' | translate }}"
                                   retry="{{ 'retry' | translate }}"
                                   select="{{ 'select' | translate }}"
                                   uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
            </kendo-upload-messages>
          </kendo-upload>
        </div>
      </div>
    </div>
    <div class="clearfix">
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'orden' | translate }}</label>
        <label class="bloques-info-valor">{{ numeroOperacion }}</label>
      </div>
      <div class="bloques-info">
        <label class="bloques-info-label">{{ 'operacion' | translate }}</label>
        <label class="bloques-info-valor">{{ nombreOperacion }}</label>
      </div>
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'tipo' | translate }}</label>
        <label class="bloques-info-valor">{{ tipoOperacion }}</label>
      </div>

      <!-- <div class="bloques-info" *ngIf="user.verColada">
          <label class="bloques-info-label">{{ 'pedircolada' | translate }}</label>
          <label class="bloques-info-valor">{{coladaCAF}}</label>
        </div>
        <div class="bloques-info" *ngIf="user.verNSerie">
          <label class="bloques-info-label">{{ 'nserie' | translate }}</label>
          <label class="bloques-info-valor">{{infonserie}}</label>
        </div> -->
      <!-- <div class="bloques-info" *ngIf="user.verLote">
          <label class="bloques-info-label">{{ 'lote' | translate }}</label>
          <label class="bloques-info-valor">{{ loteCAFOperacion }}</label>
        </div> -->
      <div class="bloques-info">
        <label class="bloques-info-label">{{ 'cantidad' | translate }}</label>
        <label class="bloques-info-valor"> {{ cantidadLoteHechasOperacion }} / {{cantidadLoteOperacion }} </label>
      </div>

      <!-- TIEMPOS ESTIMADOS Y REAL -->
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'tEstimado' | translate }}</label>
        <label class="bloques-info-valor">{{infoTiempoEstimadoOperacion}}</label>
      </div>
      <div class="bloques-info">
        <label class="bloques-info-label">
          <span class="tooltip-consumibles-contenido">
            <i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="tiempoAsigandoPiezaNoPreparacion" class="tooltipTextHeader"></span>
          </span>
          {{ 'tiempo' | translate }}
        </label>
        <label class="bloques-info-valor">{{infoTiempoRealEjecucionOperacion}}</label>
      </div>
      <div class="bloques-info" *ngIf="usuarioIdDb!=usuarioIdDbGamesa">
        <!-- NO ENSEÑAR PARA GAMESA -->
        <label class="bloques-info-label">{{ 'tPreparacion' | translate }}</label>
        <label class="bloques-info-valor">{{infoTiempoRealPreparacionOperacion}}</label>
      </div>
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'desvio' | translate }}</label>
        <label class="bloques-info-valor">{{infoDesvioOperacion}}</label>
      </div>
      <div class="bloques-info ">
        <label class="bloques-info-label">{{ 'desvioPorcentaje' | translate }}</label>
        <label class="bloques-info-valor">{{infoPorcenDesvioOperacion}}</label>
      </div>
      <div class="bloques-info">
        <label class="bloques-info-label">{{ 'estado' | translate }}</label>
        <label class="bloques-info-valor" *ngIf="terminado==0">{{'enProceso' | translate}}</label>
        <label class="bloques-info-valor" *ngIf="terminado==1">{{'acabada' | translate}}</label>
      </div>

    </div>
    <!-- TODOS LOS DATOS -->
    <div class="d-none">
      <!-- SOLICITAR -->
      <div [hidden]="!solicitarVisible">
        <!-- EDICION -->
        <div class="bloques-info" [hidden]="!solicitarEdicionVisible">
          <div class="form-group">
            <kendo-label text="{{ 'edicion' | translate }}">
              <kendo-textbox [(value)]="solicitarEdicionValor"></kendo-textbox>
            </kendo-label>
          </div>
        </div>
        <!-- LOTE -->
        <div class="bloques-info" [hidden]="!solicitarLoteVisible">
          <div class="form-group">
            <kendo-label text="{{ 'lote' | translate }}">
              <kendo-textbox [(value)]="solicitarLoteValor"></kendo-textbox>
            </kendo-label>
          </div>
        </div>
        <!-- N SERIE -->
        <div class="bloques-info" [hidden]="!solicitarNserieVisible">
          <div class="form-group">
            <kendo-label text="{{ 'nSerie' | translate }}">
              <kendo-textbox [(value)]="solicitarNserieValor"></kendo-textbox>
            </kendo-label>
          </div>
        </div>
      </div>
      <!-- CONFIRMACION -->
      <div [hidden]="!confirmacionVisible">
        <label class="">{{ 'confirmacion' | translate }}:</label>
        <label class="">{{ respuestaConfirmacion }}</label>
      </div>
    </div>
  </div>
</div>


<!-- TOOLTIP DE GRIDS -->
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<!-- GRIDS -->
<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showGridTooltip($event)">

  <div class="example-wrapper">
    <kendo-tabstrip tabPosition="top" [keepTabContent]="true">
      <kendo-tabstrip-tab title="{{ 'procesos' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <p>
            <!-- GRID: PROCESOS -->
            <kendo-grid [kendoGridBinding]="JprocesosView" [resizable]="true" [navigable]="true" [sortable]="true"
                        filterable="menu" scrollable="virtual" [rowHeight]="36" [height]="500" [pageable]="true"
                        [pageSize]="pageSize" [skip]="skip3" (pageChange)="pageChange3($event)" class="grid-font-10">
              <kendo-grid-column field="procesos_Tipo" title="" width="2%" [filterable]="false">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="cuadrado-color-grid" [ngClass]="dataItem.procesos_Tipo">{{ dataItem.procesos_Tipo | translate }}</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="procesos_Tipo" title="{{ 'tipoProceso' | translate }}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.procesos_Tipo | translate }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="nombrePrograma" title="{{ 'nombrePrograma' | translate }}" width="10%">
              </kendo-grid-column>
              <!--<kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="10%"></kendo-grid-column>-->
              <!--<kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="10%"></kendo-grid-column>-->
              <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" width="5%"
                                 class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="tooltip-consumibles-contenido">
                    <ng-container *ngIf="dataItem.operariosAuxi!=''">
                      <ng-container *ngFor="let operario of dataItem.operariosAuxi.split(','); let i = index">
                        <span *ngIf="operario!='undefined' && operario!='Dundefined'" class="grid-circulo"
                              [style.background-color]="dataItem.operariosC + '!important'">{{operario}}</span>
                        <span *ngIf="operario=='undefined' || operario=='Dundefined'" class="grid-circulo"><i
                             class="fas fa-user-alt"></i></span>
                      </ng-container>
                      <span class="tooltiptext">
                        <ng-container *ngFor="let operario of dataItem.operariosAuxi2.split(';'); let i = index">
                          <label *ngIf="operario !='undefined'"> {{operario}} </label>
                          <label *ngIf="operario =='undefined'"> {{'desconocido' | translate}} </label>
                        </ng-container>
                      </span>
                    </ng-container>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="5%" class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="tooltip-consumibles-contenido">
                    <ng-container *ngIf="dataItem.maquinasAuxi!=''">
                      <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                        <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'"
                              class="grid-circulo"
                              style="background-color:white!important;"
                              [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                        <span *ngIf="dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined'"
                              class="grid-circulo"
                              [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
                      </ng-container>
                      <span class="tooltiptext">
                        <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                          <label *ngIf="maquina!='undefined'"> {{maquina}} </label>
                          <label *ngIf="maquina=='undefined'"> {{'desconocido' | translate}} </label>
                        </ng-container>
                      </span>
                    </ng-container>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tiempoRealHH" title="{{ 'tReal' | translate }}" width="5%"></kendo-grid-column>
              <!--<kendo-grid-column field="tiempoEstHH" title="{{ 'tEstimado' | translate }}" width="5%"></kendo-grid-column>-->
              <kendo-grid-column field="fechaini" title="{{ 'inicio' | translate }}" width="8%">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini | kendoDate:'HH:mm:ss' }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechafin" title="{{ 'fin' | translate }}" width="8%">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin | kendoDate:'HH:mm:ss' }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="perdida" title="{{ 'perdida' | translate }}" width="10%"></kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                                                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                                                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                                                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                                                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                                                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                                                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                                                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                                                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                                                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                                                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                                                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                                                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                                                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                                                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                                                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                                                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                                                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                                                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                                                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                                                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                                                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="{{ 'calidad' | translate}}" *ngIf="JvaloresView" [disabled]="this.user.calidad < 1">
        <ng-template kendoTabContent>
          <p>
            <!-- GRID: CALIDAD -->
            <!-- (excelExport)="onExcelExport($event)" 
            (excelExport)="onExcelExportOneLine($event)" -->
            <kendo-grid [kendoGridBinding]="JvaloresView" 
                        [resizable]="true" 
                        [navigable]="true" 
                        [sortable]="true"
                        filterable="menu" 
                        [height]="500" 
                        [width]="1090"
                        [skip]="skip3" 
                        (pageChange)="pageChange3($event)" 
                        class="grid-font-10"
                        [rowClass]="rowBackgroundColor" 
                        (excelExport)="onExcelExportOneLine($event)"
                        [selectable]="true" kendoGridSelectBy="index" 
                        [selectedKeys]="calidadSeleccionadas"
                      >
              <ng-template kendoGridToolbarTemplate position="top">
                <button class="btn mr-1  btn-copiar btn-sm mr-1" kendoGridExcelCommand>{{ 'exportar' | translate}}</button>
                <button class="btn btn-primary btn-sm mr-1" (click)="validarOperacion($event)" [disabled]="this.user.calidad < 2" style="color: white;">{{ 'validar' | translate }}</button>
                <button class="btn btn-success m-1 float-left" (click)="btnGuardar()" [disabled]="this.user.historicoProcesos < 2 || this.user.calidad < 2">{{ 'guardar' | translate }}</button>
                <button class="btn btn-success m-1 float-left" (click)="btnCrearCotas()" [disabled]="this.user.historicoProcesos < 2 || this.user.calidad < 2">{{ 'crearCotas' | translate }}</button>
              </ng-template>
              <kendo-grid-checkbox-column showSelectAll="true" width="12%" [hidden]="this.user.calidad < 2"></kendo-grid-checkbox-column>
              <!-- cota de calidad: rojo o naranja -->
              <kendo-grid-column field="claseValorFueraLimites" title="" width="15%" [style]="{'text-align': 'center'}" class="p-0">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <!-- <span *ngIf="dataItem.cotaPieza == 'naranja' || dataItem.cotaPieza == 'rojo'" class="celda-completa" [ngClass]="{'bg-amarillo': dataItem.cotaPieza == 'naranja', 'bg-rojo': dataItem.cotaPieza == 'rojo'}">
                    <span class="icono-valores" [ngClass]="dataItem.iconoCota"></span>
                  </span> -->
                  <!-- <span class="celda-completa"
                        [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icono-estado-valorFueraLimites-null',
                              'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                              'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera-critico',
                              'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icono-estado-valorFueraLimites-dentro'}">
                    <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites" title="{{ valores | translate }}">
                    </span> -->
                    <span class="celda-completa"
                        [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icon-menos-alt',
                                    'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                                    'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icon-equis',
                                    'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icon-check'}">
                    <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites" title="{{ valores | translate }}">
                    </span>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="cantidad" title="{{ 'pieza' | translate }}" [width]="150" [style]="{'text-align': 'center'}"></kendo-grid-column>
              <kendo-grid-column field="esPrimera" title="{{ 'esPrimerasPieza' | translate }}" [width]="60" [style]="{'text-align': 'center'}">
                <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                  <input type="checkbox" [checked]="dataItem.esPrimera" disabled />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="auditoria" title="{{ 'auditoria' | translate }}" [width]="60" [style]="{'text-align': 'center'}">
                <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                  <input type="checkbox" [checked]="dataItem.auditoria" disabled />
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" [width]="60" class="celda-tooltip-externo">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="tooltip-consumibles-contenido">
                    <ng-container *ngFor="let operario of dataItem.operariosAuxi.split(','); let i = index">
                      <span *ngIf="operario!='undefined' && operario!='Dundefined'" class="grid-circulo" [style.background-color]="dataItem.operariosC.split(',')[i]+ '!important'">{{operario}}</span>
                      <span *ngIf="operario=='undefined' || operario=='Dundefined'" class="grid-circulo"><i class="fas fa-user-alt"></i></span>
                    </ng-container>
                    <span class="tooltiptext">
                      <ng-container *ngFor="let operario of dataItem.operariosAuxi2.split(';'); let i = index">
                        <label *ngIf="operario !='Dundefined' && operario !='undefined'"> {{operario}} </label>
                        <label *ngIf="operario =='Dundefined' || operario =='undefined'"> {{'desconocido' | translate}} </label>
                      </ng-container>
                    </span>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="criticosOk" title="{{ 'criticosOk' | translate }}" [width]="46" [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="otrosOk" title="{{ 'otrosOk' | translate }}" [width]="45" [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="totalOk" title="{{ 'totalOk' | translate }}" [width]="45" [style]="{'text-align': 'right'}"></kendo-grid-column>
              <kendo-grid-column field="fechaValidacionOrden" title="{{ 'fechaValidacion' | translate }}" [width]="80" class="celda-tooltip-externo"><ng-template kendoGridCellTemplate let-dataItem>
                  <span *ngIf="dataItem.validadas>0" class="tooltip-consumibles-contenido">
                    <label>
                      {{this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(dataItem.fechaValidacion.replace('T', ' ')))}}
                      {{this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(dataItem.fechaValidacion.replace('T', ' ')))}}
                    </label>
                    <span class="tooltiptext">{{dataItem.fechaValidacion | kendoDate}} {{dataItem.fechaValidacion | kendoDate:'HH:mm:ss'}}</span>
                  </span>
                  <span *ngIf="dataItem.validadas==0" class="tooltip-consumibles-contenido">
                    <label>{{'sinValidar' | translate}}</label>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="observacionResponsable" title="{{ 'observacionResponsable' | translate }}" width="220" class="p-0">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-textbox *ngIf="this.user.calidad==2 && dataItem.validadas<dataItem.numMedidas" field="dataItem" [(value)]="dataItem.observacionResponsable"></kendo-textbox>
                  <span *ngIf="this.user.calidad<2 || dataItem.validadas>=dataItem.numMedidas" class="tooltip-consumibles-contenido">
                    <label>{{dataItem.observacionResponsable}}</label>
                    <span class="tooltiptext">{{dataItem.observacionResponsable}}</span>
                  </span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                                   filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                   filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                   filterAndLogic="{{'filterAndLogic' | translate}}"
                                   filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                   filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                   filterBooleanAll="{{'filterBooleanAll' | translate}}"
                                   filterClearButton="{{'filterClearButton' | translate}}"
                                   filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                   filterDateToday="{{'filterDateToday' | translate}}"
                                   filterDateToggle="{{'filterDateToggle' | translate}}"
                                   filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                   filterEqOperator="{{'filterEqOperator' | translate}}"
                                   filterFilterButton="{{'filterFilterButton' | translate}}"
                                   filterGtOperator="{{'filterGtOperator' | translate}}"
                                   filterGteOperator="{{'filterGteOperator' | translate}}"
                                   filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                                   filterIsFalse="{{'filterIsFalse' | translate}}"
                                   filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                   filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                   filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                                   filterIsTrue="{{'filterIsTrue' | translate}}"
                                   filterLtOperator="{{'filterLtOperator' | translate}}"
                                   filterLteOperator="{{'filterLteOperator' | translate}}"
                                   filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                   filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                   filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                   filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                                   filterOrLogic="{{'filterOrLogic' | translate}}"
                                   filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                                   loading="{{'loading' | translate}}"
                                   groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                                   lock="{{'lock' | translate}}" noRecords="{{'norecords' | translate}}">
              </kendo-grid-messages>
              <!--Set list of intern data, field should be the same as the attribute we want-->
              <ng-container *ngFor="let resumen of JvaloresView"></ng-container>
              <!--GRID INTERNO-->
              <div *kendoGridDetailTemplate="let interna">
                <kendo-grid [kendoGridBinding]="interna.interna" [resizable]="true" [navigable]="true" [sortable]="true"
                            filterable="menu" [skip]="skip3" (pageChange)="pageChange3($event)" class="grid-font-10" [rowClass]="rowCallback">
                  <!-- cota de calidad: rojo o naranja -->
                  <kendo-grid-column field="claseValorFueraLimites" title="" width="4%" [style]="{'text-align': 'center'}" class="p-0">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <!-- <span class="celda-completa" 
                       [ngClass]="{'fas fa-times icono-estado-valorFueraLimites-fuera-critico': dataItem.cotaPieza == 'noOK critico'
                                 , 'fas fa-times icono-estado-valorFueraLimites-fuera': dataItem.cotaPieza == 'noOK'
                                 , 'fas fa-ban icono-estado-valorFueraLimites-null':  dataItem.cotaPieza == 'null'
                                 , 'fas fa-check icono-estado-valorFueraLimites-dentro': dataItem.cotaPieza == 'ok'}">
                        <span class="icono-valores"></span>
                      </span> -->
                      <span class="celda-completa"
                          [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icon-menos-alt',
                                      'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                                      'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icon-equis',
                                      'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icon-check'}">
                      <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites" title="{{ valores | translate }}">
                      </span>
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="critico" title="{{ 'critico' | translate }}" width="10%" [style]="{'text-align': 'center'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span *ngIf="dataItem.critico"><i class="fas fa-exclamation"></i></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="valor" title="{{ 'valor' | translate }}" [headerStyle]="{'text-align': 'center'}" width="12%">
                  </kendo-grid-column>
                  <kendo-grid-column field="icono64" title="{{ 'tipo' | translate }}" width="10%" class="p-0">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span *ngIf="dataItem.icono64!=''"><img [src]="dataItem.icono64" style="max-height:30px;" /></span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="valorNominal" title="{{ 'valornominal' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                  <kendo-grid-column field="toleranciaMin" title="{{ 'minTolerancia' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                  <kendo-grid-column field="toleranciaMax" title="{{ 'maxTolerancia' | translate }}" width="8%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                  <kendo-grid-column field="utillajeNombre" title="{{ 'utillaje' | translate }}" width="8%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}"></kendo-grid-column>
                  <kendo-grid-column field="nserieutillaje" title="{{ 'nSerieUtillaje' | translate }}" width="8%" [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-combobox *ngIf="dataItem.tieneNSerieUtillaje" [data]="dataItem.numSerieUtillaje" textField="nSerie" valueField="id"
                                      class="form-control" [(value)]="dataItem.selectedItem" [disabled]=" this.user.calidad < 2 || dataItem.operacionValidada"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="medicion" title="{{ 'medicion' | translate }}" width="8%" [style]="{'text-align': 'center'}" class="p-0">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-numerictextbox *ngIf="dataItem.tipoValorNumerico==true" class="form-control" [(value)]="dataItem.medicion"
                                            [autoCorrect]="true" [step]="0.1" (valueChange)="recalcularCotasOk(dataItem);"
                                            [disabled]="this.user.calidad < 2 || dataItem.operacionValidada">
                      </kendo-numerictextbox>
                      <kendo-textbox *ngIf="dataItem.tipoValorTexto" [(value)]="dataItem.medicion"></kendo-textbox>
                      <div *ngIf="dataItem.tipoValorBooleano==true" class="caja">
                        <div class="btn mr-1" [class.btn-success]="dataItem.medicion==1" [class.btn-outline-success]="dataItem.medicion!=1">
                          <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnSi(dataItem)" [checked]="dataItem.medicion==1"
                                 kendoRadioButton [disabled]="this.user.calidad < 2 || dataItem.operacionValidada" (click)="recalcularCotasOk(dataItem);">
                          <label class="k-radio-label" for="btnEstimado">{{ 'si' | translate }}</label>
                        </div>
                        <div class="btn mr-1" [class.btn-success]="dataItem.medicion==0" [class.btn-outline-success]="dataItem.medicion!=0">
                          <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnNo(dataItem)" [checked]="dataItem.medicion==0"
                                 kendoRadioButton [disabled]="this.user.calidad < 2 || dataItem.operacionValidada " (click)="recalcularCotasOk(dataItem);">
                          <label class="k-radio-label" for="btnPredictivo">{{ 'no' | translate }}</label>
                        </div>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fotoPath" width="6%" title="{{ 'foto' | translate }}" class="celda-tooltip-externo-header p-0" [style]="{'text-align': 'right'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button type="button" class="btn-success btn" *ngIf="dataItem.dejaImagen" (click)="clickFoto(dataItem)" [disabled]="this.user.calidad < 2 || dataItem.operacionValidada"><i class="fas fa-camera"></i></button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="observacion" title="{{ 'observacion' | translate }}" width="12%" class="p-0">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-textbox *ngIf="this.user.calidad==2 && !dataItem.operacionValidada  " field="dataItem" [(value)]="dataItem.observacion"></kendo-textbox>
                      <span *ngIf="this.user.calidad<2 || dataItem.operacionValidada " class="tooltip-consumibles-contenido">
                        <label>{{dataItem.observacion}}</label>
                        <span class="tooltiptext">{{dataItem.observacion}}
                        </span>
                      </span>
                    </ng-template>
                  </kendo-grid-column>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                       filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                       filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                       filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                       filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                       filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                       filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                       filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                       filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                       filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                       filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                       filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                       filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                       filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                       filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                       filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                       filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                       filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                       filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                       filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                       groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                       noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>

                </kendo-grid>
              </div>
              <!--fin GRID INTERNO-->

              <kendo-grid-excel fileName="CALIDAD_{{todayDate | date:'dd_MM_YYYY'}}.xlsx" [fetchData]="allData">

                <!-- <kendo-excelexport-column field="cantidad" title="{{ 'cantidad' | translate}}">
                      </kendo-excelexport-column>
                      <kendo-excelexport-column field="operarios" title="{{ 'operarios' | translate}}">
                      </kendo-excelexport-column>

                      <kendo-excelexport-column field="criticosOk" title="{{ 'criticosOk' | translate}}">
                      </kendo-excelexport-column>

                      <kendo-excelexport-column field="otrosOk" title="{{ 'otrosOk' | translate}}">
                      </kendo-excelexport-column>

                      <kendo-excelexport-column field="totalOk" title="{{ 'totalOk' | translate}}">
                      </kendo-excelexport-column>

                      <kendo-excelexport-column field="fechaValidacionOrden" title="{{ 'fechaValidacion' | translate}}">
                      </kendo-excelexport-column> -->
                <!-- <ng-container *ngFor="let resumen of JvaloresView">
                            <div *kendoGridDetailTemplate="let interna">
                              <kendo-excelexport-column field="interna.observacion" title="{{ 'observacion' | translate}}"></kendo-excelexport-column>

                            </div>
                        </ng-container>    -->
              </kendo-grid-excel>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                                                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                                                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                                                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                                                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                                                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                                                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                                                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                                                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                                                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                                                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                                                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                                                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                                                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                                                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                                                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                                                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                                                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                                                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                                                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                                                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                                                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/">
                  </kendo-grid-messages>
            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="{{ 'incidencias' | translate}}">
        <ng-template kendoTabContent>
          <p>
            <!-- GRID: INCIDENCIAS -->
            <kendo-grid [kendoGridBinding]="JincidenciasView" [resizable]="true" [navigable]="true" [sortable]="true"
                        filterable="menu" scrollable="virtual" [rowHeight]="36" [height]="500" [pageable]="true"
                        [pageSize]="pageSize" [skip]="skip2" (pageChange)="pageChange2($event)" class="grid-font-10">
              <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="15%"></kendo-grid-column>
              <kendo-grid-column field="idIncidencia" title="{{ 'incidencia' | translate }}" width="15%">
              </kendo-grid-column>
              <kendo-grid-column field="textoIncidencia" title="{{ 'observacion' | translate }}" width="40%">
              </kendo-grid-column>
              <kendo-grid-column field="fechaIni" width="10%" title="{{ 'fechainicio' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.fechaIni | kendoDate }} {{dataItem.fechaIni | kendoDate:'HH:mm:ss' }} </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechaFin" width="10%" title="{{ 'fechafin' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.fechaFin | kendoDate }} {{dataItem.fechaFin | kendoDate:'HH:mm:ss'}} </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="duracion" title="{{ 'duracion' | translate }}" width="10%"
                                 [style]="{'text-align': 'right'}"></kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                                                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                                                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                                                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                                                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                                                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                                                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                                                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                                                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                                                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                                                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                                                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                                                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                                                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                                                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                                                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                                                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                                                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                                                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                                                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                                                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                                                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="{{ 'operarios' | translate}}">
        <ng-template kendoTabContent>
          <p>
            <!-- GRID: OPERARIOS -->
            <kendo-grid [kendoGridBinding]="JoperariosView" [resizable]="true" [navigable]="true" [sortable]="true"
                        filterable="menu" scrollable="virtual" [rowHeight]="36" [height]="500" [pageable]="true"
                        [pageSize]="pageSize" [skip]="skip" (pageChange)="pageChange($event)" class="grid-font-10">
              <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="70%"></kendo-grid-column>
              <kendo-grid-column field="fechaini" width="10%" title="{{ 'fechainicio' | translate }}"
                                 [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini |
                  kendoDate:'HH:mm:ss' }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="fechafin" width="10%" title="{{ 'fechafin' | translate }}"
                                 [style]="{'text-align': 'right'}">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin |
                  kendoDate:'HH:mm:ss' }}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="duracion" title="{{ 'duracion' | translate }}" width="10%"
                                 [style]="{'text-align': 'right'}"></kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                                                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                                                    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                                                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                                                    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                                                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                                                    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                                                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                                                    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                                                    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                                                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                                                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                                                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                                                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                                                    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                                                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                                                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                                                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                                                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                                                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                                                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                                                    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="{{ 'imagenes' | translate}}">
        <ng-template kendoTabContent>
          <div id="imagenes" class="imagenes-histoperacion">
          </div>
          <p>
          </p>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>

</div>

<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>
<div class="card">
  <div class="card-body">
    <button type="button" class="btn btn-danger m-1 float-left" (click)="btnVolver()"> {{ 'atras' | translate }}
    </button>
    <!-- <button type="button" [hidden]="!visibleCancelar" class="btn btn-secondary m-1 float-left" (click)="btnCancelar()" [disabled]="this.user.historicoProcesos < 2">
      {{ 'cancelarOperacion' | translate }} </button>
    <button type="button" [hidden]="!visibleLiberar" class="btn btn-secondary m-1 float-left" (click)="btnLiberar()" [disabled]="this.user.historicoProcesos < 2"> {{
      'liberar' | translate }} </button>
    <button type="button" [hidden]="!visibleRespuesta" class="btn btn-secondary m-1 float-left"
            (click)="btnRespuesta()" [disabled]="this.user.historicoProcesos < 2">{{ 'conRespuesta' | translate }}</button>
     -->
  </div>
</div>

<div id="auxDiv" [hidden]="true"></div>

<!--<iframe id="serviceFrameSend" src="../assets/dcg0.png" width="1000" height="1000" frameborder="0"></iframe>
<iframe id="serviceFrameSend" src="../assets/proba.html" width="1000" height="1000" frameborder="0"></iframe>
<iframe id="serviceFrameSend" src="C:/Users/zitu08/Desktop/proba.html" width="1000" height="1000" frameborder="0"></iframe>-->
<!-- POPUP: Subir imagen -->
<ng-template #popupSubiImagen let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'subirimagen' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarSubida()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <!-- <div class="card-header">
        <h3><label>{{ 'subirimagen' | translate }}</label></h3>
      </div> -->
      <div class="card-body">
        <!--IMAGEN-->
        <div *ngIf="imagePreviews.length>0">
          <img *ngFor="let image of imagePreviews" [src]="image.src" alt="image preview" style="max-height: 113px;" />
        </div>
        <div *ngIf="irudiZaharraErakutsi">
          <img [src]="imageToShow" style="max-height: 113px">
          <ng-template #initImage>
          </ng-template>
        </div>

        <ng-template #noImageFound>
          <img src="fallbackImage.png">
        </ng-template>

        <div class="form-group w-50">
          <div class="caja">
            <kendo-upload class="form-control" [restrictions]="restrictions" [saveUrl]="uploadSaveUrl"
                          [multiple]="false" (remove)="onRemove($event)" (select)="selectEventHandler($event)">
              <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                     clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                     dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                     externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                     fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                     fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                     filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                     filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                     filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                     headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                     headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                     headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                     invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                     invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                     invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                     pause="{{ 'pause' | translate }}"
                                     remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                     retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                     uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
              </kendo-upload-messages>
            </kendo-upload>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="cancelarSubida()">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" [disabled]="!hayImagen" (click)="btnAceptar()" [disabled]="this.user.historicoProcesos < 2"> {{ 'aceptar' |
          translate }} </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentloadingupload let-modal>

  <div class="modal-body">
    <p>{{ 'importando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'importando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<!-- POPUP: Subir imagen calidad -->
<ng-template #popupImagenCalidad let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'subirimagen' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelarModalCalidad()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="">
      <!-- <div class="card-header">
        <h3><label>{{ 'subirimagen' | translate }}</label></h3>
      </div> -->
      <div class="card-body">
        <!--IMAGEN-->
        <div *ngIf="mostrarImagenCalidad">
          <img [src]="imageToShowCalidad" style="max-height: 113px">
          <ng-template #initImage>
          </ng-template>
        </div>

        <div class="form-group">
          <div class="caja">
            <kendo-fileselect [(ngModel)]="archivoAgregar" [restrictions]="restrictions" [multiple]="false"
                              (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
              <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                     clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                     dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                     externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                     fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                     fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                     filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                     filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                     filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                     headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                     headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                     headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                     invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                     invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                     invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                     pause="{{ 'pause' | translate }}"
                                     remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                     retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                     uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
              </kendo-upload-messages>
            </kendo-fileselect>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="cancelarModalCalidad()">{{ 'cancelar' | translate }}</button>
        <button type="button" class="btn btn-primary" [disabled]="!mostrarImagenCalidad" (click)="aceptarModalCalidad()" [disabled]="this.user.historicoProcesos < 2"> {{ 'aceptar' |
          translate }} </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- POPUP: GENERAR COTAS DE CALDIAD AUDITORIA-->
<div class="popup-cont " *ngIf="popupCrearCotas">
  <div class="popup " style="width: 215px;">
    <div class="popup-header">
      <h3>
        <label>{{'crearCotas' | translate }}</label>
      </h3>
    </div>
    <div class="popup-body">
      <div class="form-group">
        <kendo-label text="{{ 'pieza' | translate }}">
          <!-- <kendo-textbox [(value)]="pieza_crearCotas" maxLength="50" [ngClass]="{ 'is-invalid': FALTA_pieza_crearCotas }"></kendo-textbox> -->
          <kendo-numerictextbox class="form-control" [(value)]="pieza_crearCotas" [autoCorrect]="true" format="n" [step]="1" [ngClass]="{ 'is-invalid': FALTA_pieza_crearCotas }" max="999999">
          </kendo-numerictextbox>
        </kendo-label>
      </div>
    </div>
    <div class="popup-footer text-rigth d-flex justify-content-around">
      <button type="button" class="btn btn-primary" (click)="btnCrearCotas_crear()" [disabled]="this.user.calidad<2">{{ 'crear' | translate }}</button>
      <button type="button" class="btn btn-danger" (click)="btnCrearCotas_cancelar()">{{ 'cancelar' | translate }}</button>
    </div>
  </div>
</div>