import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuService, UsuariosService, MaquinasService, ComboService, ConfiguracionService, SubidaFicherosService } from '@app/_services';
import { Combo } from '@app/_models';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { FileRestrictions, FileInfo, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-maquina-detalle-manual',
  templateUrl: './maquinaDetalleManual.component.html'
})

export class MaquinaDetalleManualComponent {
  public idTipoAcceso: number = 0;

  // VALIDATOR MANUAL
  public tipoRequerido: boolean = false;
  public carpetaRequerida: boolean = false;
  public nombreRequerido: boolean = false;

  //#region "CARGA"
  public id: number = -1;
  public idMaquina: number = -1;
  public tipoMaquina: any;
  
  public tipos: Combo[];
  public tipos_value: Combo;
  public idTipo: number;

  public carpetas: Combo[];
  public carpetas_value: Combo;
  public idCarpeta: number;

  public isLink: boolean = false;//true
  public uploadSaveUrl;
  public uploadRemoveUrl;
  public fichero: String = "";
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 20048576
  };

  public closeResult = '';
  public form: FormGroup;
  public loading: boolean = false;
  public submitted: boolean = false;
  public isAddMode: boolean;
  public user = this.userService.userValue;
  public alertService: any;

  public myFiles: FileInfo[];

  public errorFichero = false;

  //Fichero
  public ficheroOriginal: any;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private menuService: MenuService,
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private comboService: ComboService,
    private configuracionService: ConfiguracionService,
    private subidaFicherosService: SubidaFicherosService) {

    this.menuService.titulo = this.translateService.instant('manual').toUpperCase();
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    this.idMaquina = this.activatedRoute.snapshot.params['idMaquina'];

    this.isAddMode = !this.id;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
    });

    var r1, r2, r3 = false
    this.comboService.Get_TiposManuales_DAT().pipe(first()).subscribe((result: any) => {
      var an: any = result.data;
      an.forEach(f => {
        f.nombre = this.translateService.instant(f.nombre)
      });
      this.tipos = result.data;

      r1= true;
      if(r1 && r3)
        this.cargarTextoTipos();
    });

    this.comboService.Get_TiposManualesCarpetas_DAT().pipe(first()).subscribe((result: any) => {
      var an: any = result.data;
      an.forEach(f => {
        f.nombre = this.translateService.instant(f.nombre)
      });
      this.carpetas = result.data;

      r2= true;
      if(r2 && r3)
        this.cargarTextoCarpetas();
    });



    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      idTipo: [undefined,],
      idCarpeta: [undefined,],
      nombre: ['',],
      activarLink: [false,],
      link: ['',],
      nombreArchivo: ['',],
      archivo: ['',],
      archivoBase64: ['',],
      nombreRuta: ['',],
      esDirectorio: [false,]
    });

    if (this.id > 0) {
      this.maquinasService.Get_Manual(this.id).pipe().subscribe((result) => {
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          idTipo: [result.data[0].idTipo, [Validators.required]],
          idCarpeta: [result.data[0].idCarpeta, [Validators.required]],
          nombre: [result.data[0].texto, [Validators.required]],
          activarLink: [result.data[0].esLink,],
          link: [result.data[0].pdf,],
          nombreArchivo: [result.data[0].pdf,],
          archivo: ['',],
          archivoBase64: ['',],
          nombreRuta: [result.data[0].nombreRuta,],
          esDirectorio: [result.data[0].esDir,]
        });
        this.idTipoAcceso = this.getTipoAcceso();
        this.tipos_value = new Combo(result.data[0].idTipo, "");
        this.idTipo = result.data[0].idTipo;
        this.carpetas_value = new Combo(result.data[0].idCarpeta, "");
        this.idCarpeta = result.data[0].idCarpeta;
        this.isLink = result.data[0].esLink;
        if (!result.data[0].esLink) {
          this.fichero = result.data[0].pdf;
          this.form.controls['link'].setValue("");
        } else {
          this.form.controls['nombreArchivo'].setValue("");
          this.form.controls['archivo'].setValue("");
          this.form.controls['archivoBase64'].setValue("");
        }

        r3= true;
        if(r1 && r3)
          this.cargarTextoTipos();
        if(r2 && r3)
          this.cargarTextoCarpetas();
      });
    }else {      
      this.tipos_value = new Combo(1, "");
      this.idTipo = 1;
      this.carpetas_value = new Combo(1, "");
      this.idCarpeta = 1;
      
      r3= true;
      if(r1 && r3)
        this.cargarTextoTipos();
      if(r2 && r3)
        this.cargarTextoCarpetas();
    }
  }
  cargarTextoTipos(){
    this.tipos.forEach(f => 
    {
      if(f.id = this.idTipo)
        this.tipos_value = f;
    });
  }
  cargarTextoCarpetas(){
    this.carpetas.forEach(f => 
    {
      if(f.id = this.idCarpeta)
        this.carpetas_value = f;
    });
  }

  getTipoAcceso(): number {
    if (this.form.value.nombreRuta !== '') return 2
    if (this.form.value.esLink) return 1
    return 0
  }
  //#endregion

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
      
    this.tipoRequerido = false;
    this.carpetaRequerida = false;
    this.nombreRequerido = false;
    
    this.form.value.activarLink = this.idTipoAcceso == 1;
    
    if (this.form.invalid) {
      return;
    }
    if (this.idTipo <= 0 || this.idTipo == undefined){
      this.tipoRequerido = true;
    }
    if (this.idCarpeta <= 0 || this.idCarpeta == undefined){
      this.carpetaRequerida = true;
    }
    if (this.form.value.nombre == ''){
      this.nombreRequerido = true;
    }

    if(this.tipoRequerido || this.carpetaRequerida || this.nombreRequerido){
      return;
    }

    this.loading = true;


    if (this.isAddMode) {
      // this.newInsert();
      this.insert();
    } else {
      // this.newUpdate();
      this.update();
    }
  }
  private newInsert() {
    if(this.idTipoAcceso == 0 || this.idTipoAcceso == 1){ this.form.value.activarLink = true; } //Al subir a FTP, Azure o Google Cloud devuelve un link
    else this.form.value.activarLink = false;
    if(this.idTipoAcceso == 0){
      this.configuracionService.get_configuracion_gestion_documental().subscribe((res)=>{
        if(res != null){
          switch(res[0].idTipo){
          //FTP
            case 2:{
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              debugger
              this.subidaFicherosService.uploadFileFTP(file[0],carpeta[0].nombre).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.insert();
                }
                console.log("Erantzuna FTP");
                console.log(r);
              })
              break;
            }
          //Azure externo
            case 3:{
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileAzureExterno(file[0],carpeta[0].nombre).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  if(!msg.error){
                    this.form.controls['link'].setValue(msg.message);
                    this.insert();
                  } 
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          //Google cloud
            case 5:{
              debugger
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              var fichero = this.ficheroOriginal;
              debugger
              this.subidaFicherosService.uploadFileAGoogleCloud(carpeta[0].nombre,file[0]).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.insert();
                }
                console.log("Erantzuna GOOGLE CLOUD");
                console.log(r);
              })
              break;
            }
          //Azure interno
            case 6:{
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFile(file[0].name,file[0],carpeta[0].nombre).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  if(!msg.error){
                    this.form.controls['link'].setValue(msg.message);
                    this.insert();
                  } 
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          
            
          }
        } 
      })
    }
    else{
      this.insert();
    }
  }

  private newUpdate() {
    if(this.idTipoAcceso == 0){
      this.configuracionService.get_configuracion_gestion_documental().subscribe((res)=>{
        if(res != null){
          switch(res[0].idTipo){
          //FTP
            case 2:{
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileFTP(file[0],carpeta[0].nombre).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.update();
                }
                console.log("Erantzuna FTP");
                console.log(r);
              })
              break;
            }
          //Azure externo
            case 3:{
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFileAzureExterno(file[0],carpeta[0].nombre).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  debugger
                  this.update();
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          //Google cloud
            case 5:{
              debugger
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              var fichero = this.ficheroOriginal;
              debugger
              this.subidaFicherosService.uploadFileAGoogleCloud(carpeta[0].nombre,file[0]).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.update();
                }
                console.log("Erantzuna GOOGLE CLOUD");
                console.log(r);
              })
              break;
            }
          //Azure interno
            case 6:{
              var carpeta = this.carpetas.filter(f=> f.id == this.idCarpeta);
              var file = this.form.value.archivo;
              this.subidaFicherosService.uploadFile(file[0].name,file[0],carpeta[0].nombre).subscribe(r => {
                if(r != null){
                  var msg:any = r;
                  //Guardar en el parametro link el resultado, no en nombreArchivo, porque sino no se guarda como link
                  //this.form.controls['nombreArchivo'].setValue(msg.message);
                  this.form.controls['link'].setValue(msg.message);
                  this.update();
                }
                console.log("Erantzuna AZURE");
                console.log(r);
              })
              break;
            }
          
            
          }
        } 
      })
    }
    else{
      this.update();
    }
  }
  private insert() {
    // if(this.idTipoAcceso == 0 || this.idTipoAcceso == 1){ this.form.value.activarLink = true; } //Al subir a FTP, Azure o Google Cloud devuelve un link
    // else this.form.value.activarLink = false;
    // debugger
    this.maquinasService.Insert_Manual(this.form.value, this.idMaquina, this.idTipo, this.idCarpeta).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  private update() {
    // if(this.idTipoAcceso == 0 || this.idTipoAcceso == 1){ this.form.value.activarLink = true; } //Al subir a FTP, Azure o Google Cloud devuelve un link
    // else this.form.value.activarLink = false;
    this.form.value.id = this.id;
    this.maquinasService.Update_Manual(this.form.value, this.id, this.idMaquina, this.idTipo, this.idCarpeta).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  public atras() {
    this.maquinasService.SaberSiEsMaquinaOInstalacion(this.idMaquina.toString()).subscribe((result) => {
      var data: any = result.data;
      if (this.idMaquina > 0) {
        if (data[0].esMaquina == 1) {
          if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
          if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
          if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
          if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
          if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
          if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
          if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
        } else if (data[0].esInstalacion == 1) {
          this.router.navigate(['instalaciones/editar/' + this.idMaquina]); //INSTALACION
        }
      }
    });
  }
  errorEventHandler(e: ErrorEvent) {
    this.alertService.error(this.translateService.instant('error'));
  }


  //#region COMBO
  public tipos_selectionChange(value: any): void {
    this.idTipo = value.id
  }
  //#endregion

    //#region COMBO
    public carpetas_selectionChange(value: any): void {
      this.idCarpeta = value.id
    }
    //#endregion

  //#Kregion CHECKBOX
  public onValueChange(value: any): void {
    this.isLink = value;
  }
  //#endregion

  //AZABAL: PDF NUEVO
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    this.ficheroOriginal = e.files[0];
    this.form.controls['nombreArchivo'].setValue(e.files[0].name);
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  public onClick(tipoAcceso: number): void {
    this.idTipoAcceso = tipoAcceso;
  }
}
