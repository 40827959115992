<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12">
    <div class="row">
      <div class="col-md-6">
        <kendo-tabstrip #tabstrip>
          <kendo-tabstrip-tab id="real" [title]="real" [selected]="true">
            <ng-template kendoTabContent>
              <!--PRIORIDADES -->
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'prioridades' | translate }}</label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>

                <div class="card-body">
                  <!-- ROW 1 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).prioridadCliente" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 2 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).prioridadFacturacion" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 3 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).prioridadOF" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 4 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).prioridadMaquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 5 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).prioridadFecha" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 6 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).prioridadTemperatura" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 7 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(1).percent_cap_maquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="10" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 8 -->
                  <div class="clearfix row" style="margin: 20px 0 0 0;">

                    <div class="form-group">
                      <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(1).dias_reserva" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.dias_reserva.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(1).asap_maximoMesesParaIniciarAntesDeFechaEntrega" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.asap_maximoMesesParaIniciarAntesDeFechaEntrega.errors }"></kendo-numerictextbox>
                      </div>
                    </div>
                    
                    <div class="col-md-3">
                      <kendo-label text="{{ 'visibilidad' | translate}}">
                        <br />
                        <kendo-buttongroup selection="single">
                          <button type="button" kendoButton [selected]="replanificarTiempoEstimadoMap.get(1)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(1,$event)">{{
                            'estimado' |
                            translate}}</button>
                          <button type="button" kendoButton [selected]="!replanificarTiempoEstimadoMap.get(1)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(1,!($event))">{{
                            'predictivo' |
                            translate}}</button>
                        </kendo-buttongroup>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'ineficiencia' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(1).replanificarIneficiencias" [onLabel]="' '"
                          [offLabel]="' '" [checked]="versionDataMap.get(1).replanificarIneficiencias">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'optimizarCuelloBotella' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(1).optimizarCuelloBotella" [onLabel]="' '"
                          [offLabel]="' '" [checked]="versionDataMap.get(1).optimizarCuelloBotella"></kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'agruparOperacionesPorColorPlanificadorLargoMaquinas' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(1).agruparOperacionesPorColorPlanificadorLargoMaquinas" [onLabel]="' '"
                          [offLabel]="' '" [checked]="versionDataMap.get(1).agruparOperacionesPorColorPlanificadorLargoMaquinas"></kendo-switch>
                      </kendo-label>
                    </div>

                  </div>
                  <!-- ROW 9 -->
                  <div class="row" style="margin-top: 10px;">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">{{ 'criteriosParaAnnadirOperacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="criteriosParaAnnadirOperacion"
                            [valueField]="'id'" class="form-control" [value]="criterioAnnadirOperacionMap.get(1)"
                            (selectionChange)="criteriosParaAnnadirOperacion_selectionChange(1,$event)"
                            [ngClass]="{ 'is-invalid': submitted && criterioAnnadirOperacion == undefined }">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="metodologiasDePlanificacion"
                            [valueField]="'id'" class="form-control" [value]="metodologiaPlanificacionMap.get(1)"
                            (selectionChange)="metodologiasDePlanificacion_selectionChange(1,$event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!--CONFIGURACION-->
              <div class="clearfix">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label>{{ 'configuracion' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>

                  <div class="card-body">

                    <div class="row">

                      <div class="col-md-5">
                        <kendo-label text="{{ 'visibilidad' | translate}}">
                          <br />
                          <kendo-buttongroup selection="single">
                            <button type="button" kendoButton [selected]="verPorOperacionMap.get(1)" [toggleable]="true"
                              icon="gear" (selectedChange)="selectedChange(1,$event)">{{
                              'verPorOperacion' | translate}}</button>
                            <button type="button" kendoButton [selected]="!verPorOperacionMap.get(1)"
                              [toggleable]="true" icon="wrench" (selectedChange)="selectedChange(1,!($event))">{{
                              'verPorPieza' |
                              translate}}</button>
                          </kendo-buttongroup>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'tiempoparada' | translate}}">
                          <kendo-numerictextbox [(value)]="versionDataMap.get(1).tiempoparada" class="form-control"
                            [min]="0" [max]="999999" [autoCorrect]="true" [format]="'n'"
                            [ngClass]="{ 'is-invalid': submitted && f.tiempoparada.errors }">
                          </kendo-numerictextbox>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'ocultarParte' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(1).ocultarParte" [onLabel]="' '" [offLabel]="' '"
                            [checked]="versionDataMap.get(1).ocultarParte"></kendo-switch>
                        </kendo-label>
                      </div>

                    </div>

                    <div class="row" style="margin-top: 10px;">

                      <div class="col-md-3">
                        <kendo-label text="{{ 'operarioPuedeCambiarOperacionAutomatica' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(1).operario_puede_cambiar_operacion_automatica"
                            [onLabel]="' '" [offLabel]="' '"
                            [checked]="versionDataMap.get(1).operario_puede_cambiar_operacion_automatica">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'cambioOperacion' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(1).cambioOperacion" [onLabel]="' '"
                            [offLabel]="' '" [checked]="versionDataMap.get(1).cambioOperacion"></kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-3">
                        <kendo-label text="{{ 'limitarMovimientosMaquinasCompatibles' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(1).limitarMovimientosMaquinasCompatibles"
                            [onLabel]="' '" [offLabel]="' '"
                            [checked]="versionDataMap.get(1).limitarMovimientosMaquinasCompatibles">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab id="v1" [title]="v1">
            <ng-template kendoTabContent>
              <!--PRIORIDADES-->
              <!--PRIORIDADES -->

              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'prioridades' | translate }}</label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>

                <div class="card-body">
                  <!-- ROW 1 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).prioridadCliente" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 2 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).prioridadFacturacion" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 3 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).prioridadOF" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 4 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).prioridadMaquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 5 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).prioridadFecha" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 6 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).prioridadTemperatura" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 7 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(2).percent_cap_maquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="10" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 8 -->
                  <div class="clearfix row" style="margin: 20px 0 0 0;">

                    <div class="form-group">
                      <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(2).dias_reserva" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.dias_reserva.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(2).asap_maximoMesesParaIniciarAntesDeFechaEntrega" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.asap_maximoMesesParaIniciarAntesDeFechaEntrega.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'visibilidad' | translate}}">
                        <br />
                        <kendo-buttongroup selection="single">
                          <button type="button" kendoButton [selected]="replanificarTiempoEstimadoMap.get(2)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(2,$event)">{{
                            'estimado' |
                            translate}}</button>
                          <button type="button" kendoButton [selected]="!replanificarTiempoEstimadoMap.get(2)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(2,!($event))">{{
                            'predictivo' |
                            translate}}</button>
                        </kendo-buttongroup>
                      </kendo-label>
                    </div>

                    <div class="col-md-2">
                      <kendo-label text="{{ 'ineficiencia' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(2).replanificarIneficiencias"
                          [checked]="versionDataMap.get(2).replanificarIneficiencias" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'optimizarCuelloBotella' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(2).optimizarCuelloBotella"
                          [checked]="versionDataMap.get(2).optimizarCuelloBotella" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'agruparOperacionesPorColorPlanificadorLargoMaquinas' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(2).agruparOperacionesPorColorPlanificadorLargoMaquinas"
                          [checked]="versionDataMap.get(2).agruparOperacionesPorColorPlanificadorLargoMaquinas" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                  </div>
                  <!-- ROW 9 -->
                  <div class="row" style="margin-top: 10px;">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">{{ 'criteriosParaAnnadirOperacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="criteriosParaAnnadirOperacion"
                            [valueField]="'id'" class="form-control" [value]="criterioAnnadirOperacionMap.get(2)"
                            (selectionChange)="criteriosParaAnnadirOperacion_selectionChange(2,$event)"
                            [ngClass]="{ 'is-invalid': submitted && criterioAnnadirOperacion == undefined }">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="metodologiasDePlanificacion"
                            [valueField]="'id'" class="form-control" [value]="metodologiaPlanificacionMap.get(2)"
                            (selectionChange)="metodologiasDePlanificacion_selectionChange(2,$event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!--CONFIGURACION-->
              <div class="clearfix">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label>{{ 'configuracion' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>

                  <div class="card-body">

                    <div class="row">

                      <div class="col-md-5">
                        <kendo-label text="{{ 'visibilidad' | translate}}">
                          <br />
                          <kendo-buttongroup selection="single">
                            <button type="button" kendoButton [selected]="verPorOperacionMap.get(2)" [toggleable]="true"
                              icon="gear" (selectedChange)="selectedChange(2,$event)">{{
                              'verPorOperacion' | translate}}</button>
                            <button type="button" kendoButton [selected]="!verPorOperacionMap.get(2)"
                              [toggleable]="true" icon="wrench" (selectedChange)="selectedChange(2,!($event))">{{
                              'verPorPieza' |
                              translate}}</button>
                          </kendo-buttongroup>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'tiempoparada' | translate}}">
                          <kendo-numerictextbox [(value)]="versionDataMap.get(2).tiempoparada" class="form-control"
                            [min]="0" [max]="999999" [autoCorrect]="true" [format]="'n'"
                            [ngClass]="{ 'is-invalid': submitted && f.tiempoparada.errors }">
                          </kendo-numerictextbox>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'ocultarParte' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(2).ocultarParte"
                            [checked]="versionDataMap.get(2).ocultarParte" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>

                    <div class="row" style="margin-top: 10px;">

                      <div class="col-md-3">
                        <kendo-label text="{{ 'operarioPuedeCambiarOperacionAutomatica' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(2).operario_puede_cambiar_operacion_automatica"
                            [checked]="versionDataMap.get(2).operario_puede_cambiar_operacion_automatica"
                            [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'cambioOperacion' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(2).cambioOperacion"
                            [checked]="versionDataMap.get(2).cambioOperacion" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-3">
                        <kendo-label text="{{ 'limitarMovimientosMaquinasCompatibles' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(2).limitarMovimientosMaquinasCompatibles"
                            [checked]="versionDataMap.get(2).limitarMovimientosMaquinasCompatibles" [onLabel]="' '"
                            [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab id="v2" [title]="v2">
            <ng-template kendoTabContent>
              <!--PRIORIDADES-->
              <!--PRIORIDADES -->
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'prioridades' | translate }}</label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>

                <div class="card-body">
                  <!-- ROW 1 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).prioridadCliente" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 2 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).prioridadFacturacion" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 3 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).prioridadOF" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 4 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).prioridadMaquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 5 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).prioridadFecha" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 6 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).prioridadTemperatura" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 7 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(3).percent_cap_maquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="10" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 8 -->
                  <div class="clearfix row" style="margin: 20px 0 0 0;">

                    <div class="form-group">
                      <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(3).dias_reserva" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.dias_reserva.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(3).asap_maximoMesesParaIniciarAntesDeFechaEntrega" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.asap_maximoMesesParaIniciarAntesDeFechaEntrega.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'visibilidad' | translate}}">
                        <br />
                        <kendo-buttongroup selection="single">
                          <button type="button" kendoButton [selected]="replanificarTiempoEstimadoMap.get(3)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(3,$event)">{{
                            'estimado' |
                            translate}}</button>
                          <button type="button" kendoButton [selected]="!replanificarTiempoEstimadoMap.get(3)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(3,!($event))">{{
                            'predictivo' |
                            translate}}</button>
                        </kendo-buttongroup>
                      </kendo-label>
                    </div>

                    <div class="col-md-2">
                      <kendo-label text="{{ 'ineficiencia' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(3).replanificarIneficiencias"
                          [checked]="versionDataMap.get(3).replanificarIneficiencias" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'optimizarCuelloBotella' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(3).optimizarCuelloBotella"
                          [checked]="versionDataMap.get(3).optimizarCuelloBotella" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'agruparOperacionesPorColorPlanificadorLargoMaquinas' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(3).agruparOperacionesPorColorPlanificadorLargoMaquinas"
                          [checked]="versionDataMap.get(3).agruparOperacionesPorColorPlanificadorLargoMaquinas" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                  </div>
                  <!-- ROW 9 -->
                  <div class="row" style="margin-top: 10px;">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">{{ 'criteriosParaAnnadirOperacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="criteriosParaAnnadirOperacion"
                            [valueField]="'id'" class="form-control" [value]="criterioAnnadirOperacionMap.get(3)"
                            (selectionChange)="criteriosParaAnnadirOperacion_selectionChange(3,$event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="metodologiasDePlanificacion"
                            [valueField]="'id'" class="form-control" [value]="metodologiaPlanificacionMap.get(3)"
                            (selectionChange)="metodologiasDePlanificacion_selectionChange(3,$event)"
                            [ngClass]="{ 'is-invalid': submitted && metodologiaPlanificacion == undefined }">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!--CONFIGURACION-->
              <div class="clearfix">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label>{{ 'configuracion' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>

                  <div class="card-body">

                    <div class="row">

                      <div class="col-md-5">
                        <kendo-label text="{{ 'visibilidad' | translate}}">
                          <br />
                          <kendo-buttongroup selection="single">
                            <button type="button" kendoButton [selected]="verPorOperacionMap.get(3)" [toggleable]="true"
                              icon="gear" (selectedChange)="selectedChange(3,$event)">{{
                              'verPorOperacion' | translate}}</button>
                            <button type="button" kendoButton [selected]="!verPorOperacionMap.get(3)"
                              [toggleable]="true" icon="wrench" (selectedChange)="selectedChange(3,!($event))">{{
                              'verPorPieza' |
                              translate}}</button>
                          </kendo-buttongroup>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'tiempoparada' | translate}}">
                          <kendo-numerictextbox [(value)]="versionDataMap.get(3).tiempoparada" class="form-control"
                            [min]="0" [max]="999999" [autoCorrect]="true" [format]="'n'"
                            [ngClass]="{ 'is-invalid': submitted && f.tiempoparada.errors }">
                          </kendo-numerictextbox>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'ocultarParte' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(3).ocultarParte"
                            [checked]="versionDataMap.get(3).ocultarParte" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>

                    <div class="row" style="margin-top: 10px;">

                      <div class="col-md-3">
                        <kendo-label text="{{ 'operarioPuedeCambiarOperacionAutomatica' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(3).operario_puede_cambiar_operacion_automatica"
                            [checked]="versionDataMap.get(3).operario_puede_cambiar_operacion_automatica"
                            [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'cambioOperacion' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(3).cambioOperacion"
                            [checked]="versionDataMap.get(3).cambioOperacion" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-3">
                        <kendo-label text="{{ 'limitarMovimientosMaquinasCompatibles' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(3).limitarMovimientosMaquinasCompatibles"
                            [checked]="versionDataMap.get(3).limitarMovimientosMaquinasCompatibles" [onLabel]="' '"
                            [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab id="v3" [title]="v3">
            <ng-template kendoTabContent>
              <!--PRIORIDADES-->
              <!--PRIORIDADES -->
              <div class="card">
                <div class="card-header">
                  <h3>
                    <label>{{ 'prioridades' | translate }}</label>
                  </h3>
                  <div class="plegarpanel"></div>
                </div>

                <div class="card-body">
                  <!-- ROW 1 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadCliente' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).prioridadCliente" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 2 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFacturacion' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).prioridadFacturacion" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 3 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadOF' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).prioridadOF" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 4 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadMaquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).prioridadMaquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 5 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadFecha' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).prioridadFecha" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 6 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'prioridadTemperatura' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).prioridadTemperatura" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="25" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 7 -->
                  <div class="clearfix">
                    <div class="form-group">
                      <label class="label-color1">{{ 'percent_cap_maquina' | translate }}</label>
                      <div class="caja" style="margin: 7px 10px;">
                        <kendo-slider [(value)]="versionDataMap.get(4).percent_cap_maquina" [vertical]="false"
                          [showButtons]="false" [min]="0" [max]="100" [smallStep]="10" [largeStep]="1" class="w-100">
                          <kendo-slider-messages increment="Right" decrement="Left"></kendo-slider-messages>
                        </kendo-slider>
                      </div>
                    </div>
                  </div>
                  <!-- ROW 8 -->
                  <div class="clearfix row" style="margin: 20px 0 0 0;">

                    <div class="form-group">
                      <label class="label-color1">{{ 'dias_reserva' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(4).dias_reserva" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.dias_reserva.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="label-color1">{{ 'asap_maximoMesesParaIniciarAntesDeFechaEntrega' | translate }}</label>
                      <div class="caja">
                        <kendo-numerictextbox [(value)]="versionDataMap.get(4).asap_maximoMesesParaIniciarAntesDeFechaEntrega" class="form-control"
                          [format]="'n'" [min]="0" [max]="100" [autoCorrect]="true" [step]="1"
                          [ngClass]="{ 'is-invalid': submitted && f.asap_maximoMesesParaIniciarAntesDeFechaEntrega.errors }"></kendo-numerictextbox>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'visibilidad' | translate}}">
                        <br />
                        <kendo-buttongroup selection="single">
                          <button type="button" kendoButton [selected]="replanificarTiempoEstimadoMap.get(4)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(4,$event)">{{
                            'estimado' |
                            translate}}</button>
                          <button type="button" kendoButton [selected]="!replanificarTiempoEstimadoMap.get(4)"
                            [toggleable]="true" (selectedChange)="selectedChangeEstimadoPredictivo(4,!($event))">{{
                            'predictivo' |
                            translate}}</button>
                        </kendo-buttongroup>
                      </kendo-label>
                    </div>

                    <div class="col-md-2">
                      <kendo-label text="{{ 'ineficiencia' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(4).replanificarIneficiencias"
                          [checked]="versionDataMap.get(4).replanificarIneficiencias" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'optimizarCuelloBotella' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(4).optimizarCuelloBotella"
                          [checked]="versionDataMap.get(4).optimizarCuelloBotella" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                    <div class="col-md-3">
                      <kendo-label text="{{ 'agruparOperacionesPorColorPlanificadorLargoMaquinas' | translate}}">
                        <br />
                        <kendo-switch [(value)]="versionDataMap.get(4).agruparOperacionesPorColorPlanificadorLargoMaquinas"
                          [checked]="versionDataMap.get(4).agruparOperacionesPorColorPlanificadorLargoMaquinas" [onLabel]="' '" [offLabel]="' '">
                        </kendo-switch>
                      </kendo-label>
                    </div>

                  </div>
                  <!-- ROW 9 -->
                  <div class="row" style="margin-top: 10px;">

                    <div class="col-md-6">
                      <div class="form-group">
                        <label class="control-label">{{ 'criteriosParaAnnadirOperacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="criteriosParaAnnadirOperacion"
                            [valueField]="'id'" class="form-control" [value]="criterioAnnadirOperacionMap.get(4)"
                            (selectionChange)="criteriosParaAnnadirOperacion_selectionChange(4,$event)">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group">
                        <label class="control-label">{{ 'metodologiasDePlanificacion' | translate }}</label>
                        <div class="caja">
                          <kendo-dropdownlist [textField]="'nombre'" [data]="metodologiasDePlanificacion"
                            [valueField]="'id'" class="form-control" [value]="metodologiaPlanificacionMap.get(4)"
                            (selectionChange)="metodologiasDePlanificacion_selectionChange(4,$event)"
                            [ngClass]="{ 'is-invalid': submitted && metodologiaPlanificacion == undefined }">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!--CONFIGURACION-->
              <div class="clearfix">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      <label>{{ 'configuracion' | translate }}</label>
                    </h3>
                    <div class="plegarpanel"></div>
                  </div>

                  <div class="card-body">

                    <div class="row">

                      <div class="col-md-5">
                        <kendo-label text="{{ 'visibilidad' | translate}}">
                          <br />
                          <kendo-buttongroup selection="single">
                            <button type="button" kendoButton [selected]="verPorOperacionMap.get(4)" [toggleable]="true"
                              icon="gear" (selectedChange)="selectedChange(4,$event)">{{
                              'verPorOperacion' | translate}}</button>
                            <button type="button" kendoButton [selected]="!verPorOperacionMap.get(4)"
                              [toggleable]="true" icon="wrench" (selectedChange)="selectedChange(4,!($event))">{{
                              'verPorPieza' |
                              translate}}</button>
                          </kendo-buttongroup>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'tiempoparada' | translate}}">
                          <kendo-numerictextbox [(value)]="versionDataMap.get(4).tiempoparada" class="form-control"
                            [min]="0" [max]="999999" [autoCorrect]="true" [format]="'n'"
                            [ngClass]="{ 'is-invalid': submitted && f.tiempoparada.errors }">
                          </kendo-numerictextbox>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'ocultarParte' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(4).ocultarParte"
                            [checked]="versionDataMap.get(4).ocultarParte" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>

                    <div class="row" style="margin-top: 10px;">

                      <div class="col-md-3">
                        <kendo-label text="{{ 'operarioPuedeCambiarOperacionAutomatica' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(4).operario_puede_cambiar_operacion_automatica"
                            [checked]="versionDataMap.get(4).operario_puede_cambiar_operacion_automatica"
                            [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-2">
                        <kendo-label text="{{ 'cambioOperacion' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(4).cambioOperacion"
                            [checked]="versionDataMap.get(4).cambioOperacion" [onLabel]="' '" [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                      <div class="col-md-3">
                        <kendo-label text="{{ 'limitarMovimientosMaquinasCompatibles' | translate}}">
                          <br />
                          <kendo-switch [(value)]="versionDataMap.get(4).limitarMovimientosMaquinasCompatibles"
                            [checked]="versionDataMap.get(4).limitarMovimientosMaquinasCompatibles" [onLabel]="' '"
                            [offLabel]="' '">
                          </kendo-switch>
                        </kendo-label>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
      <!--CUELLO DE BOTELLA-->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h3>
              <label>{{ 'cuelloDeBotella' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>

          <div class="card-body">
            <div class="form-row">
              <kendo-grid [data]="gridData" [selectable]="{enabled: true, checkboxOnly: true}"
                [selectedKeys]="gridSelection" kendoGridSelectBy="idMaquina">
                <kendo-grid-column field="nombre" title="{{ 'nombre' | translate}}" width="85%">
                </kendo-grid-column>
                <kendo-grid-checkbox-column field="seleccionado" title="{{ 'seleccionado' | translate}}" width="15%">
                  <ng-template kendoGridCellTemplate let-dataItem="rowIndex">
                    <input type="checkbox" [kendoGridSelectionCheckbox]="dataItem" enabled />
                  </ng-template>
                </kendo-grid-checkbox-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.configuracion<2">
    {{ 'guardar' | translate }}
  </button>


</form>