import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MaquinasService, MenuService, ContenedoresService, AlertService, UsuariosService, ClasificacionesService } from '@app/_services';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { MyFunctions } from '@app/_helpers';
import { SelectableSettings } from '@progress/kendo-angular-treelist';

@Component({
    selector: 'app-contenedorDetalle',
    templateUrl: 'contenedorDetalle.component.html'
})

export class ContenedorDetalleComponent {

    public translate;

    public id;
    public isAddMode = false;

    // informacion contenedores
    public nombreContenedor = "";
    public descripcionContenedor = "";
    public isActivo = true;
    public stockMinimo = 0;
    public stockMaximo = 1000;

    public maquinas;
    public maquinasOriginal;
    public maquinasAgregarOrigen = [];
    public maquinasAgregarDestino = [];
    public maquinasOrigen = [];
    public maquinasDestino = [];
    public maquinasOrigenOriginal = [];
    public maquinasDestinoOriginal = [];
    public seleccionadosOrigen = [];
    public seleccionadosDestino = [];

    // agregar maquinas
    public agregarSeleccionadosOrigen = [];
    public agregarSeleccionadosDestino = [];
    public secciones: any = [];
    public groupedSeccion: any = [];
    public grupos: any = [];
    public gruposCargados: any = [];
    public seccionesSeleccionadasOrigen: any = [];
    public gruposSeleccionadosOrigen: any = [];
    public seccionesSeleccionadasDestino: any = [];
    public gruposSeleccionadosDestino: any = [];
    public clasificaciones: any = [];
    public clasificacionesSeleccionadosOrigen: any = [];
    public clasificacionesSeleccionadosDestino: any = [];
    public relacionesClasificaciones = new Map();
    public maquinas_clasificaciones: any = [];

    public groupedFiltro: any = [];
    public groupedFiltroOriginal: any = [];

    public maquinaParaGrupos: any = [];

    public agregarOrigen = false;
    public agregarDestino = false;

    // FILTRO MAQUINAS
    public filtroOrigen: any = [];
    public filtroStringOrigen: any = "";
    public filtroDestino: any = [];
    public filtroStringDestino: any = "";
    public ensennarFiltroOrigen: boolean = false;
    public ensennarFiltroDestino: boolean = false;
    public ensennarMaquinasOrigen: boolean = false;
    public ensennarMaquinasDestino: boolean = false;
    public agregarOrigenMaquinas: any = []
    public agregarDestinoMaquinas: any = []

    public isAgruparPorSeccionesOrigen = true;
    public isAgruparPorGruposOrigen = false;
    public isAgruparPorClasificacionesOrigen = false;

    public isAgruparPorSeccionesDestino = true;
    public isAgruparPorGruposDestino = false;
    public isAgruparPorClasificacionesDestino = false;

    public showSeccionesOrigen;
    public showGruposOrigen;
    public showClasificacionesOrigen;

    public showSeccionesDestino;
    public showGruposDestino;
    public showClasificacionesDestino;

    public groupsGrupos: GroupDescriptor[] = [{ field: "nombreGrupo" }];
    public groupsSecciones: GroupDescriptor[] = [{ field: "nombreSeccion" }];
    public groupsFiltro: GroupDescriptor[] = [{ field: "grouped" }];

    public settings: SelectableSettings = {
        mode: "row",
        multiple: true,
        drag: true,
        enabled: true,
        readonly: false
    };

    constructor(
        translate: TranslateService,
        private alertService: AlertService,
        private userService: UsuariosService,
        private menuService: MenuService,
        private contenedoresService: ContenedoresService,
        private route: ActivatedRoute,
        public router: Router,
        public maquinasService: MaquinasService,
        public clasificacionesService: ClasificacionesService,
        public myFunctions: MyFunctions) {

        this.id = this.route.snapshot.params['id'];
        if (this.id == 0) this.isAddMode = true;

        this.translate = translate;
        this.menuService.titulo = this.translate.instant('contenedor').toUpperCase();
        this.cargarSeccionesGrupo();
    }

    ngOnInit() {
    }

    cargarSeccionesGrupo() {
        var r1 = false, r2 = false, r3 = false;
        // SECCIONES
        this.userService.getSecciones().subscribe(json => {
            this.userService.secciones = json;
            //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
            var an1: any = this.userService.secciones;
            this.secciones = an1.filter(f => f.activo === true);
            this.secciones.forEach(element => {
                element.grouped = this.translate.instant("secciones");
                element.isSeleccionado = false;
                element.isSeleccionadoDestino = false;
                element.idGrid = "0." + element.id
                this.groupedFiltro.push(this.myFunctions.copy(element));
            });
            r1 = true
            if (r1 && r2 && r3) this.cargarMaquinas();
        });

        // GRUPO MAQUINAS
        this.maquinasService.getGruposMaquinas().subscribe(json => {
            this.grupos = json.data;
            this.grupos.forEach(element => {
                element.grouped = this.translate.instant("xgrupos");
                element.isSeleccionado = false;
                element.isSeleccionadoDestino = false;
                element.idGrid = "1." + element.id
                this.groupedFiltro.push(this.myFunctions.copy(element));
            });
            this.gruposCargados = true;
            r2 = true;
            if (r1 && r2 && r3) this.cargarMaquinas();
        });

        // CLASIFICACIONES
        this.clasificacionesService.GetAll().subscribe(
            (result) => {
                this.clasificaciones = result;

                this.clasificaciones.forEach(element => {
                    element.grouped = this.translate.instant("clasificaciones");
                    element.isSeleccionado = false;
                    element.isSeleccionadoDestino = false;
                    element.idGrid = "2." + element.id
                    this.groupedFiltro.push(this.myFunctions.copy(element));
                });

                this.clasificacionesService.GetMaquinasClasificaciones().subscribe((json: any) => {
                    this.maquinas_clasificaciones = json;
                    r3 = true;
                    if (r1 && r2 && r3) this.cargarMaquinas();
                });

                // definir un map para saber de cada clasificacion cual es su hijo
                this.clasificaciones.forEach(element => {
                    this.relacionesClasificaciones.set(element.id, [element.id]);

                    if (this.clasificaciones.filter(f => f.idPadre == element.id).length > 0)
                        this.relacionesClasificaciones.set(element.id, this.setHijos(element.id, [element.id]));
                });
            });
    }

    // Funcion recursiva para crear el map de las clasificaciones
    setHijos(id, array) {
        var idHijos = this.clasificaciones.filter(f => f.idPadre == id);
        if (idHijos.length > 0)
            idHijos.forEach(element => {
                array.push(element.id)
                this.setHijos(element.id, array);
            });

        return array;
    }

    cargarMaquinas() {

        // COPIA DE FILTRO
        this.groupedFiltroOriginal = this.myFunctions.copy(this.groupedFiltro);
        //MAQUINAS
        var maquinas_model = this.maquinasService.get_maquinas_model();
        if (maquinas_model == false) {
            this.maquinasService.get().subscribe(json => {
                this.maquinasService.set_maquinas_model(json);
                this.maquinas = this.maquinasService.get_maquinas_model();
                this.maquinas.forEach(element => {
                    element.nombreSeccion = this.secciones.filter(f => f.id == element.idSeccion)[0]?.nombre;
                    if (element.nombreSeccion == undefined)
                        element.nombreSeccion = this.translate.instant('sinAsignar');

                    // crear las siglas de las secciones
                    if (element.nombre.split(" ").length > 1) {
                        let aux = element.nombre.split(" ");
                        element.siglas = aux[0][0] + aux[1][0]
                    } else {
                        element.siglas = element.nombre[0] + element.nombre[1];
                    }


                });
                this.maquinasOriginal = this.myFunctions.copy(this.maquinas);
                this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinas);
                this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinas);
                if (this.id > 0)
                    this.cargarDatos()
            })
        } else {
            this.maquinas = maquinas_model;
            this.maquinas.forEach(element => {
                element.nombreSeccion = this.secciones.filter(f => f.id == element.idSeccion)[0]?.nombre;
                if (element.nombreSeccion == undefined)
                    element.nombreSeccion = this.translate.instant('sinAsignar');

                // crear las siglas de las secciones
                if (element.nombre.split(" ").length > 1) {
                    let aux = element.nombre.split(" ");
                    element.siglas = aux[0][0] + aux[1][0]
                } else {
                    element.siglas = element.nombre[0] + element.nombre[1];
                }
            });
            this.maquinasOriginal = this.myFunctions.copy(this.maquinas);
            this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinas);
            this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinas);
            if (this.id > 0)
                this.cargarDatos();
        }
    }

    cargarDatos() {
        this.contenedoresService.GetContenedorById(this.id).subscribe(json => {
            var data: any = json[0];

            this.nombreContenedor = data.nombre;
            this.isActivo = data.activo;
            this.descripcionContenedor = data.descripcion;
            this.stockMinimo = data.stockMinimo;
            this.stockMaximo = data.stockMaximo;

            var maquinasOrigen = data.maquinasOrigen?.split(',');
            var maquinasDestino = data.maquinasDestino?.split(',');

            this.maquinasOrigen = this.maquinas.filter(f => maquinasOrigen?.includes(f.id.toString()));
            this.maquinasDestino = this.maquinas.filter(f => maquinasDestino?.includes(f.id.toString()));

            maquinasOrigen?.forEach(element => { this.agregarSeleccionadosOrigen.push(parseInt(element)) });
            maquinasDestino?.forEach(element => { this.agregarSeleccionadosDestino.push(parseInt(element)) });

            this.maquinasOrigenOriginal = this.myFunctions.copy(this.maquinasOrigen);
            this.maquinasDestinoOriginal = this.myFunctions.copy(this.maquinasDestino);

            this.agregarOrigenMaquinas.push(...this.maquinasOriginal.filter(f => this.agregarSeleccionadosOrigen.includes(f.id)));
            this.agregarDestinoMaquinas.push(...this.maquinasOriginal.filter(f => this.agregarSeleccionadosDestino.includes(f.id)));

            this.setMaquinasSeleccionadasOrigen();
            this.setMaquinasSeleccionadasDestino();

        });
    }

    setMaquinasSeleccionadasOrigen() {
        this.maquinasAgregarOrigen.forEach(element => {
            if (this.agregarSeleccionadosOrigen.includes(element.id))
                element.isSeleccionado = true;
            else
                element.isSeleccionado = false;
        })

        if (this.isAgruparPorGruposOrigen)
            this.agruparPorGruposOrigen();
    }

    setMaquinasSeleccionadasDestino() {
        this.maquinasAgregarDestino.forEach(element => {
            if (this.agregarSeleccionadosDestino.includes(element.id))
                element.isSeleccionadoDestino = true;
            else
                element.isSeleccionadoDestino = false;
        })

        if (this.isAgruparPorGruposDestino)
            this.agruparPorGruposDestino();
    }

    showOrigen() {
        this.agregarOrigen = true;
        this.ensennarMaquinasOrigen = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }
    buscarChangeOrigen(event) {
        if (event == "") this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinasOriginal);
        else this.maquinasAgregarOrigen = this.maquinasOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));
        
        this.maquinaParaGrupos = [];
        this.maquinasAgregarOrigen.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if ((this.gruposSeleccionadosOrigen.includes(parseInt(row)) || this.gruposSeleccionadosOrigen.length == 0) && (element.nombre.toLowerCase().includes(event.toLowerCase()) || event == "")) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })
    }
    buscarFiltroChangeOrigen(event) {
        if (event == "") this.groupedFiltro = this.myFunctions.copy(this.groupedFiltroOriginal);
        else this.groupedFiltro = this.groupedFiltroOriginal.filter(f => f.nombre.toLowerCase().includes(event.toLowerCase()));
    }

    showDestino() {
        this.agregarDestino = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }

    cerrarMenu() {
        if (this.agregarDestino && (this.ensennarFiltroDestino || this.ensennarMaquinasDestino)) {
            this.ensennarFiltroDestino = false;
            this.ensennarMaquinasDestino = false;
        } else if (this.agregarOrigen && (this.ensennarFiltroOrigen)) {
            this.ensennarFiltroOrigen = false;
        } else {
            this.ensennarMaquinasOrigen = false;
            this.agregarDestino = false;
            this.agregarOrigen = false;
        }
    }

    onClickGuardar() {
        if (this.isAddMode)
            this.insertContenedor();
        else
            this.updateContenedor();
    }

    insertContenedor() {
        var maqOrigen = [];
        var maqDestino = [];
        this.maquinasOrigen.forEach(element => maqOrigen.push(element.id));
        this.maquinasDestino.forEach(element => maqDestino.push(element.id));
        this.contenedoresService.InsertContenedor(this.nombreContenedor, this.isActivo, this.descripcionContenedor, this.stockMinimo, this.stockMaximo, maqOrigen, maqDestino).subscribe((json: any) => {
            if (json > 0) {
                this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                this.router.navigate(['contenedores/']);
            } else {
                this.alertService.error(this.translate.instant('error'));
            }
        });
    }

    updateContenedor() {
        var that = this;
        var maquinasOrigenInsertAux = this.maquinasOrigen.filter(function (obj) {
            var array = that.maquinasOrigenOriginal.filter(f => f.id == obj.id);
            if (array.length == 0) return true
            else return false
        });
        var maquinasOrigenDeleteAux = this.maquinasOrigenOriginal.filter(function (obj) {
            var array = that.maquinasOrigen.filter(f => f.id == obj.id);
            if (array.length == 0) return true
            else return false
        });
        var maquinasDestinoInsertAux = this.maquinasDestino.filter(function (obj) {
            var array = that.maquinasDestinoOriginal.filter(f => f.id == obj.id);
            if (array.length == 0) return true
            else return false
        });
        var maquinasDestinoDeleteAux = this.maquinasDestinoOriginal.filter(function (obj) {
            var array = that.maquinasDestino.filter(f => f.id == obj.id);
            if (array.length == 0) return true
            else return false
        });

        var maquinasOrigenInsert = [];
        var maquinasOrigenDelete = [];
        var maquinasDestinoInsert = [];
        var maquinasDestinoDelete = [];

        maquinasOrigenInsertAux.forEach(element => maquinasOrigenInsert.push(element.id));
        maquinasOrigenDeleteAux.forEach(element => maquinasOrigenDelete.push(element.id));
        maquinasDestinoInsertAux.forEach(element => maquinasDestinoInsert.push(element.id));
        maquinasDestinoDeleteAux.forEach(element => maquinasDestinoDelete.push(element.id));

        this.contenedoresService.UpdateContenedor(this.id, this.nombreContenedor, this.isActivo, this.descripcionContenedor, this.stockMinimo, this.stockMaximo,
            maquinasOrigenInsert, maquinasOrigenDelete, maquinasDestinoInsert, maquinasDestinoDelete).subscribe((json: any) => {
                if (json > 0) {
                    this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
                    this.router.navigate(['contenedores/']);
                } else {
                    this.alertService.error(this.translate.instant('error'));
                }
            });
    }

    onClickCancelar() {
        this.router.navigate(['contenedores/']);
    }

    //#region FILTRO GRUPO, SECCION, CLASIFICACIONES MAQUINAS ORIGEN
    showFiltroOrigen() {
        this.ensennarFiltroOrigen = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }
    annadirFiltroSeleccionadoOrigen(event = null, dataItem = null) {
        // al clickar en el grid
        if (event.type == 'change') {
            event.dataItem = dataItem;
        }
        
        if (event.dataItem.grouped != null) {
            if (this.seccionesSeleccionadasOrigen.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("secciones")) {
                this.seccionesSeleccionadasOrigen = this.seccionesSeleccionadasOrigen.filter(f => f != event.dataItem.id);
                this.filtroOrigen = this.filtroOrigen.filter(f => f.idSeccion != event.dataItem.id);
            } else if (this.gruposSeleccionadosOrigen.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("xgrupos")) {
                this.gruposSeleccionadosOrigen = this.gruposSeleccionadosOrigen.filter(f => f != event.dataItem.id);
                this.filtroOrigen = this.filtroOrigen.filter(f => f.idGrupo != event.dataItem.id);
            } else if (this.clasificacionesSeleccionadosOrigen.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                this.clasificacionesSeleccionadosOrigen = this.clasificacionesSeleccionadosOrigen.filter(f => f != event.dataItem.id);
                this.filtroOrigen = this.filtroOrigen.filter(f => f.idClasificacion != event.dataItem.id);
            } else {
                var idGrupo = -1, idSeccion = -1, idClasificacion = -1;
                if (event.dataItem.grouped == this.translate.instant("secciones")) {
                     this.seccionesSeleccionadasOrigen.push(event.dataItem.id);
                     idSeccion = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("xgrupos")) {
                    this.gruposSeleccionadosOrigen.push(event.dataItem.id);
                    idGrupo = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                    this.clasificacionesSeleccionadosOrigen.push(event.dataItem.id);
                    idClasificacion = event.dataItem.id;
                }

                var newFiltroRow = {
                    idGrupo: idGrupo,
                    idSeccion: idSeccion,
                    idClasificacion: idClasificacion,
                    nombre: event.dataItem.nombre
                }
                this.filtroOrigen.push(newFiltroRow);
            }
        }


        this.groupedFiltro.forEach(element => {
            if ((this.seccionesSeleccionadasOrigen.includes(element.id) && element.grouped == this.translate.instant("secciones"))
                || (this.gruposSeleccionadosOrigen.includes(element.id) && element.grouped == this.translate.instant("xgrupos"))
                || (this.clasificacionesSeleccionadosOrigen.includes(element.id) && element.grouped == this.translate.instant("clasificaciones")))
                element.isSeleccionado = true
            else
                element.isSeleccionado = false
        });

        var array = this.filtroOrigen.map(function (value) { return value.nombre })
        this.filtroStringOrigen = array.join(', ');

        this.filtrarMaquinasOrigen();
    }

    filtrarMaquinasOrigen() {

        if (this.gruposSeleccionadosOrigen.length == 0 && this.seccionesSeleccionadasOrigen.length == 0 && this.clasificacionesSeleccionadosOrigen.length == 0) {
            this.maquinasAgregarOrigen = this.myFunctions.copy(this.maquinasOriginal);
            this.setMaquinasSeleccionadasOrigen();
            return;
        }

        // para filtrar secciones y grupos
        this.maquinasAgregarOrigen = this.maquinasOriginal.filter(f => {
            // secciones
            var result = true;
            if (this.seccionesSeleccionadasOrigen.length > 0)
                this.seccionesSeleccionadasOrigen.every(s => {
                    if (f.idSeccion == s)
                        result = false
                    return result;
                })
            else
                result = false;
            // grupos
            var result2 = true;
            if (this.gruposSeleccionadosOrigen.length > 0)
                this.gruposSeleccionadosOrigen.every(s => {
                    if (f.idsGrupos.split(',').includes(s.toString()))
                        result2 = false
                    return result2;
                })
            else
                result2 = false;
            return !result && !result2;
        });

        // para filtrar clasificaciones
        if (this.clasificacionesSeleccionadosOrigen.length > 0) {
            var idClasificacionesAFiltrar = []
            this.clasificacionesSeleccionadosOrigen.forEach(element => {
                var aux = this.relacionesClasificaciones.get(element);
                idClasificacionesAFiltrar.push(...aux);
            });
            var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
            idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
            this.maquinasAgregarOrigen = this.maquinasAgregarOrigen.filter(f => idMaquinas.includes(f.id));
        }

        this.setMaquinasSeleccionadasOrigen();
    }
    //#endregion

    //#region FILTRO GRUPO, SECCION, CLASIFICACIONES MAQUINAS Destino
    showFiltroDestino() {
        this.ensennarFiltroDestino = true;

        setTimeout(() => {
            var element = Array.from(document.getElementsByClassName("k-group-col"));
            element.forEach(col => {
                col["style"]["width"] = "0px";
            });
        }, 1);
    }
    annadirFiltroSeleccionadoDestino(event = null, dataItem = null) {
        // al clickar en el grid
        if (event.type == 'change') {
            event.dataItem = dataItem;
        }
        
        if (event.dataItem.grouped != null) {
            if (this.seccionesSeleccionadasDestino.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("secciones")) {
                this.seccionesSeleccionadasDestino = this.seccionesSeleccionadasDestino.filter(f => f != event.dataItem.id);
                this.filtroDestino = this.filtroDestino.filter(f => f.idSeccion != event.dataItem.id);
            } else if (this.gruposSeleccionadosDestino.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("xgrupos")) {
                this.gruposSeleccionadosDestino = this.gruposSeleccionadosDestino.filter(f => f != event.dataItem.id);
                this.filtroDestino = this.filtroDestino.filter(f => f.idGrupo != event.dataItem.id);
            } else if (this.clasificacionesSeleccionadosDestino.filter(f => f == event.dataItem.id).length > 0 && event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                this.clasificacionesSeleccionadosDestino = this.clasificacionesSeleccionadosDestino.filter(f => f != event.dataItem.id);
                this.filtroDestino = this.filtroDestino.filter(f => f.idClasificacion != event.dataItem.id);
            } else {
                var idGrupo = -1, idSeccion = -1, idClasificacion = -1;
                if (event.dataItem.grouped == this.translate.instant("secciones")) {
                     this.seccionesSeleccionadasDestino.push(event.dataItem.id);
                     idSeccion = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("xgrupos")) {
                    this.gruposSeleccionadosDestino.push(event.dataItem.id);
                    idGrupo = event.dataItem.id;
                } else if (event.dataItem.grouped == this.translate.instant("clasificaciones")) {
                    this.clasificacionesSeleccionadosDestino.push(event.dataItem.id);
                    idClasificacion = event.dataItem.id;
                }

                var newFiltroRow = {
                    idGrupo: idGrupo,
                    idSeccion: idSeccion,
                    idClasificacion: idClasificacion,
                    nombre: event.dataItem.nombre
                }
                this.filtroDestino.push(newFiltroRow);
            }
        }


        this.groupedFiltro.forEach(element => {
            if (this.seccionesSeleccionadasDestino.includes(element.id) && element.grouped == this.translate.instant("secciones")
                || this.gruposSeleccionadosDestino.includes(element.id) && element.grouped == this.translate.instant("xgrupos")
                || this.clasificacionesSeleccionadosDestino.includes(element.id) && element.grouped == this.translate.instant("clasificaciones"))
                element.isSeleccionadoDestino = true
            else
                element.isSeleccionadoDestino = false
        });

        var array = this.filtroDestino.map(function (value) { return value.nombre })
        this.filtroStringDestino = array.join(', ');

        this.filtrarMaquinasDestino();
    }

    filtrarMaquinasDestino() {

        if (this.gruposSeleccionadosDestino.length == 0 && this.seccionesSeleccionadasDestino.length == 0 && this.clasificacionesSeleccionadosDestino.length == 0) {
            this.maquinasAgregarDestino = this.myFunctions.copy(this.maquinasOriginal);
            this.setMaquinasSeleccionadasDestino();
            return;
        }

        // para filtrar secciones y grupos
        this.maquinasAgregarDestino = this.maquinasOriginal.filter(f => {
            // secciones
            var result = true;
            if (this.seccionesSeleccionadasDestino.length > 0)
                this.seccionesSeleccionadasDestino.every(s => {
                    if (f.idSeccion == s)
                        result = false
                    return result;
                })
            else
                result = false;
            // grupos
            var result2 = true;
            if (this.gruposSeleccionadosDestino.length > 0)
                this.gruposSeleccionadosDestino.every(s => {
                    if (f.idsGrupos.split(',').includes(s.toString()))
                        result2 = false
                    return result2;
                })
            else
                result2 = false;
            return !result && !result2;
        });

        // para filtrar clasificaciones
        if (this.clasificacionesSeleccionadosDestino.length > 0) {
            var idClasificacionesAFiltrar = []
            this.clasificacionesSeleccionadosDestino.forEach(element => {
                var aux = this.relacionesClasificaciones.get(element);
                idClasificacionesAFiltrar.push(...aux);
            });
            var idMaquinas = this.maquinas_clasificaciones.filter(f => idClasificacionesAFiltrar.includes(f.idClasificacion));
            idMaquinas = idMaquinas.map(function (value) { return value.idMaquina })
            this.maquinasAgregarDestino = this.maquinasAgregarDestino.filter(f => idMaquinas.includes(f.id));
        }

        this.setMaquinasSeleccionadasDestino();
    }
    //#endregion

    //#region MAQUINAS ORIGEN
    annadirMaquinaOrigen(event) {

        //#region annadir seleccionado
        if (this.agregarSeleccionadosOrigen.includes(event.dataItem.id)) {
            this.agregarSeleccionadosOrigen = this.agregarSeleccionadosOrigen.filter(f => f != event.dataItem.id);
            this.agregarOrigenMaquinas = this.agregarOrigenMaquinas.filter(f => f.id != event.dataItem.id);
        } else {
            this.agregarSeleccionadosOrigen.push(event.dataItem.id);
            this.agregarOrigenMaquinas.push(this.maquinasOriginal.filter(f => f.id == event.dataItem.id)[0]);
        }

        this.setMaquinasSeleccionadasOrigen();
        //#endregion

        this.maquinasOrigen = this.maquinas.filter(f => this.agregarSeleccionadosOrigen?.includes(f.id));
    }
    eliminarMaquinaOrigen(id) {
        this.agregarSeleccionadosOrigen = this.agregarSeleccionadosOrigen.filter(f => f != id);
        this.maquinasOrigen = this.maquinasOrigen.filter(f => f.id != id);
        this.agregarOrigenMaquinas = this.agregarOrigenMaquinas.filter(f => f.id != id);

        this.setMaquinasSeleccionadasOrigen();
    }
    eliminarFiltroOrigen(nombre) {
        //#region obtener el id del grupo/seccion/clasificacion
        var filtro = this.filtroOrigen.filter(f => f.nombre == nombre)[0];
        if (filtro.idGrupo != -1)  this.annadirFiltroSeleccionadoOrigen({dataItem: { id: filtro.idGrupo, grouped: this.translate.instant("xgrupos") }});
        else if (filtro.idSeccion != -1) this.annadirFiltroSeleccionadoOrigen({dataItem: { id: filtro.idSeccion, grouped: this.translate.instant("secciones") }});
        else if (filtro.idClasificacion != -1) this.annadirFiltroSeleccionadoOrigen({dataItem: { id: filtro.idClasificacion, grouped: this.translate.instant("clasificaciones") }});
        //#endregion
        // this.filtroOrigen = this.filtroOrigen.filter(f => f.nombre == nombre);
    }
    agruparPorSeccionesOrigen() {

        this.isAgruparPorSeccionesOrigen = true;
        this.isAgruparPorGruposOrigen = false;
        this.isAgruparPorClasificacionesOrigen = false;

    }
    agruparPorGruposOrigen() {

        this.isAgruparPorSeccionesOrigen = false;
        this.isAgruparPorGruposOrigen = true;
        this.isAgruparPorClasificacionesOrigen = false;

        this.maquinaParaGrupos = [];
        this.maquinasAgregarOrigen.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if (this.gruposSeleccionadosOrigen.includes(parseInt(row)) || this.gruposSeleccionadosOrigen.length == 0) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })

    }
    agruparPorClasificacionesOrigen() {

        this.isAgruparPorSeccionesOrigen = false;
        this.isAgruparPorGruposOrigen = false;
        this.isAgruparPorClasificacionesOrigen = true;

    }
    selectAllMaquinasOrigen() {
        
        if (this.agregarSeleccionadosOrigen.length != this.maquinas.length) {
            this.agregarSeleccionadosOrigen = []
            this.maquinasOriginal.forEach(element => {
                this.agregarSeleccionadosOrigen.push(element.id);
                this.agregarOrigenMaquinas.push(element);
            });
        }  else this.agregarSeleccionadosOrigen = []

        this.setMaquinasSeleccionadasOrigen();

        this.maquinasOrigen = this.maquinas.filter(f => this.agregarSeleccionadosOrigen?.includes(f.id));
    }
    //#endregion

    //#region MAQUINAS Destino
    annadirMaquinaDestino(event) {

        //#region annadir seleccionado
        if (this.agregarSeleccionadosDestino.includes(event.dataItem.id)) {
            this.agregarSeleccionadosDestino = this.agregarSeleccionadosDestino.filter(f => f != event.dataItem.id);
            this.agregarDestinoMaquinas = this.agregarDestinoMaquinas.filter(f => f.id != event.dataItem.id);
        } else {
            this.agregarSeleccionadosDestino.push(event.dataItem.id);
            this.agregarDestinoMaquinas.push(this.maquinasOriginal.filter(f => f.id == event.dataItem.id)[0]);
        }

        this.setMaquinasSeleccionadasDestino();
        //#endregion

        this.maquinasDestino = this.maquinas.filter(f => this.agregarSeleccionadosDestino?.includes(f.id));
    }
    eliminarMaquinaDestino(id) {
        this.agregarSeleccionadosDestino = this.agregarSeleccionadosDestino.filter(f => f != id);
        this.maquinasDestino = this.maquinasDestino.filter(f => f.id != id);
        this.agregarDestinoMaquinas = this.agregarDestinoMaquinas.filter(f => f.id != id);

        this.setMaquinasSeleccionadasDestino();
    }
    eliminarFiltroDestino(nombre) {
        //#region obtener el id del grupo/seccion/clasificacion
        var filtro = this.filtroDestino.filter(f => f.nombre == nombre)[0];
        if (filtro.idGrupo != -1)  this.annadirFiltroSeleccionadoDestino({dataItem: { id: filtro.idGrupo, grouped: this.translate.instant("xgrupos") }});
        else if (filtro.idSeccion != -1) this.annadirFiltroSeleccionadoDestino({dataItem: { id: filtro.idSeccion, grouped: this.translate.instant("secciones") }});
        else if (filtro.idClasificacion != -1) this.annadirFiltroSeleccionadoDestino({dataItem: { id: filtro.idClasificacion, grouped: this.translate.instant("clasificaciones") }});
        //#endregion
        // this.filtroDestino = this.filtroDestino.filter(f => f.nombre == nombre);
    }
    agruparPorSeccionesDestino() {

        this.isAgruparPorSeccionesDestino = true;
        this.isAgruparPorGruposDestino = false;
        this.isAgruparPorClasificacionesDestino = false;

    }
    agruparPorGruposDestino() {

        this.isAgruparPorSeccionesDestino = false;
        this.isAgruparPorGruposDestino = true;
        this.isAgruparPorClasificacionesDestino = false;

        this.maquinaParaGrupos = [];
        this.maquinasAgregarDestino.forEach(element => {
            let aux = element.idsGrupos.split(',')
            aux.forEach(row => {
                if (this.gruposSeleccionadosDestino.includes(parseInt(row)) || this.gruposSeleccionadosDestino.length == 0) {
                    element.idGrupo = parseInt(row);
                    element.nombreGrupo = this.grupos.filter(f => f.id == element.idGrupo)[0]?.nombre;
                    if (element.nombreGrupo == undefined)
                        element.nombreGrupo = this.translate.instant('sinAsignar');
                    this.maquinaParaGrupos.push(this.myFunctions.copy(element));
                }
            });
        })

    }
    agruparPorClasificacionesDestino() {

        this.isAgruparPorSeccionesDestino = false;
        this.isAgruparPorGruposDestino = false;
        this.isAgruparPorClasificacionesDestino = true;

    }
    selectAllMaquinasDestino() {
        
        if (this.agregarSeleccionadosDestino.length != this.maquinas.length) {
            this.agregarSeleccionadosDestino = []
            this.maquinasOriginal.forEach(element => {
                this.agregarSeleccionadosDestino.push(element.id);
                this.agregarDestinoMaquinas.push(element);
            });
        }  else this.agregarSeleccionadosDestino = []

        this.setMaquinasSeleccionadasDestino();

        this.maquinasDestino = this.maquinas.filter(f => this.agregarSeleccionadosDestino?.includes(f.id));
    }
    //#endregion

}