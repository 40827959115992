import { Component } from '@angular/core';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { UsuariosService, MaquinasService, MenuService, InformeEjecucionesService, InformeRendimientoService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
//import { FormGroup, FormBuilder } from '@angular/forms'; //ARATZ

import { MyFunctions } from '@app/_helpers';

import { GroupResult, groupBy } from '@progress/kendo-data-query';

interface ItemTurno {
  nombre: string,
  id: number
}

@Component({
  selector: 'app-informe-ejecuciones',
  templateUrl: './informeEjecuciones.component.html'
})

export class InformeEjecuciones {
  //#region VARIABLES
  user = this.userService.userValue;

 // public form: FormGroup;//ARATZ
  public idTurno: number;
  public motor: number = 0;

  public jsonMaquinas: any;
  public selectedMaquina: number = 0;
  public turnosCombo: Array<ItemTurno> = [];
  public turnosComboSelected: string = "";
  public comboTurnosSeleccion: any = [];
  public turnos_placeHolder: string = "";
  public fechasTitulo: string = this.translateService.instant('fecha');
  public fechaInicio: Date;
  public fechaFin: Date;
  public fechaFinDisplay = "none";
  public filtroDiaSemana: boolean = true;

  public loadingResumenSemana: boolean = true;
  private dataResumenSemana;
  public loadingAvancesEstimados: boolean = true;
  private dataEjecucion;
  public loadingVelocidadCabezalYPotens: boolean = true;
  private dataVelocidadCabezal;
  private dataAvances;
  private dataCabezal;

  public labelMetros1: string = "";
  public labelMetros2: string = "";
  public labelMetros3: string = "";
  public labelMetros4: string = "";
  public labelMetros5: string = "";
  public labelMetros6: string = "";
  public labelMetros7: string = "";
  public labelMetros8: string = "";
  public labelMetros9: string = "";

  public visibleEje1: string = "none";
  public labelEje1: string = "";
  public labelDatoEje1: string = "";
  public valorEje1: number = 0;
  public visibleEje2: string = "none";
  public labelEje2: string = "";
  public valorEje2: number = 0;
  public labelDatoEje2: string = "";
  public visibleEje3: string = "none";
  public labelEje3: string = "";
  public valorEje3: number = 0;
  public labelDatoEje3: string = "";
  public visibleEje4: string = "none";
  public labelEje4: string = "";
  public valorEje4: number = 0;
  public labelDatoEje4: string = "";
  public visibleEje5: string = "none";
  public labelEje5: string = "";
  public valorEje5: number = 0;
  public labelDatoEje5: string = "";
  public visibleEje6: string = "none";
  public labelEje6: string = "";
  public valorEje6: number = 0;
  public labelDatoEje6: string = "";
  public visibleEje7: string = "none";
  public labelEje7: string = "";
  public valorEje7: number = 0;
  public labelDatoEje7: string = "";
  public visibleEje8: string = "none";
  public labelEje8: string = "";
  public valorEje8: number = 0;
  public labelDatoEje8: string = "";
  public visibleEje9: string = "none";
  public labelEje9: string = "";
  public valorEje9: number = 0;
  public labelDatoEje9: string = "";
  public visibleEje10: string = "none";
  public labelEje10: string = "";
  public valorEje10: number = 0;
  public labelDatoEje10: string = "";

  public chartDonutResumenSemana;
  public chartDonutEjecucion;
  public chartDonutVelocidadCabezal;
  public tituloMin: String = "";
  public tituloMedia: String = "";
  public tituloMax: String = "";
  public minimo: number = 0;
  public suave: number = 250;
  public media: number = 500;
  public maximo: number = 2000;
  public chartDonutAvances;
  public chartDonutCabezal;

  private tiempoTransition: number = 2000;

  private colorPreparacion: String = "#096844";
  private colorEjecucion: String = "#C0EADA";
  private colorMicroparada: String = "#cf8729";
  private colorParada: String = "#E7CB68";
  private colorMantenimiento: String = "#99AFCC";
  private colorAlarma: String = "#D33737";
  private colorApagada: String = "#424242";

  private colorAzul: String = "#58B5D4";
  private colorVerde: String = "#3CB371";
  private colorAmarillo: String = "#F7E253";
  private colorNaranja: String = "#FC8B11";
  private colorRojo: String = "#CD3834";

  private colorMin: String = "#59B4D9";
  private colorMedia: String = "#3CB371";
  private colorMax: String = "#F4E44D";
  //#endregion

  //PANEL MAQUINAS
  //private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  public aparecer = false;

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //#region CARGA
  constructor(private informeEjecucionesService: InformeEjecucionesService,
    private InformeRendimientoService: InformeRendimientoService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private menuService: MenuService,
    //private formBuilder: FormBuilder,
    public myFunctions: MyFunctions,
    private translateService: TranslateService) {
    
    this.filtroDiaSemana = true
    this.menuService.titulo = this.translateService.instant('informeEjecuciones').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.cargarTurnosCombo();
    this.cargarFechas();


    this.cargarGrupos();
    this.cargarAreasProductivas();
  }


  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }
  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }
  cargarMaquinas() {
    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    }
    else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }
  }
  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }
    else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }
    else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0 && !(this.selectedMaquina > 0)) this.selectedMaquina = this.maquinasMostradas[0].id;

    if (cargarDatos) {
      this.cargarInforme();
    }
  }
  cargarInforme() {
    this.cargarGraficos();
  }


  ngOnInit() {

    this.informeEjecucionesService.get_colores().subscribe(
      json => {
        var an: any = json;
        this.colorMin = an.data[0].colorFeed1;
        this.colorMedia = an.data[0].colorFeed3;
        this.colorMax = an.data[0].colorFeed5;
      }
    )

    this.dibujarDonutResumenSemana();
    this.dibujarDonutEjecucion();
    this.dibujarDonutVelocidadCabezal();
    this.dibujarDonutAvances();
    this.dibujarDonutCabezal();

  }

  dibujarDonutResumenSemana() {
    this.chartDonutResumenSemana = c3.generate({
      bindto: '#chartDonutResumenSemana_informeEjecuciones',
      data: {
        columns: [
          ['preparacion', 0],
          ['ejecucion', 0],
          ['microparada', 0],
          ['parada', 0],
          ['mantenimiento', 0],
          ['alarma', 0],
          ['apagada', 0]
        ],
        names: {
          preparacion: this.translateService.instant("preparacion"),
          ejecucion: this.translateService.instant("ejecucion"),
          microparada: this.translateService.instant("microparada"),
          parada: this.translateService.instant("parada"),
          mantenimiento: this.translateService.instant("mantenimiento"),
          alarma: this.translateService.instant("alarma"),
          apagada: this.translateService.instant("apagada")
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutResumenSemana_informeEjecuciones .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutResumenSemana_informeEjecuciones .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutResumenSemana_informeEjecuciones .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: [
          this.colorPreparacion,
          this.colorEjecucion,
          this.colorMicroparada,
          this.colorParada,
          this.colorMantenimiento,
          this.colorAlarma,
          this.colorApagada
        ]
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#chartDonutResumenSemana_informeEjecuciones .c3-chart-arcs path").style("stroke-width", "0px");

  }

  dibujarDonutEjecucion() {
    this.chartDonutEjecucion = c3.generate({
      bindto: '#chartDonutEjecucion',
      data: {
        columns: [
          ['manual', 0],
          ['automatico', 0],
          ['mdi', 0],
          ['jog',0],
          ['otros', 0]
        ],
        names: {
          manual: this.translateService.instant("manual"),
          automatico: this.translateService.instant("automatico"),
          mdi: this.translateService.instant("mdi"),
          jog: this.translateService.instant("jog"),
          otros: this.translateService.instant("otros")
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutEjecucion .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutEjecucion .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutEjecucion .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: [
          this.colorVerde,
          this.colorAzul,
          this.colorAmarillo,
          this.colorNaranja,
          this.colorRojo
        ]
      },
      legend: {
        //bottom, right, inset
        position: 'bottom'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#chartDonutEjecucion .c3-chart-arcs path").style("stroke-width", "0px");

  }

  dibujarDonutVelocidadCabezal() {
    this.chartDonutVelocidadCabezal = c3.generate({
      bindto: '#chartDonutVelocidadCabezal',
      data: {
        columns: [
          ['min', 0],
          ['media', 0],
          ['max', 0]
        ],
        names: {
          min: this.tituloMin,
          media: this.tituloMedia,
          max: this.tituloMax
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutVelocidadCabezal .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutVelocidadCabezal .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutVelocidadCabezal .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: [
          this.colorMin,
          this.colorMedia,
          this.colorMax
        ]
      },
      legend: {
        //bottom, right, inset
        position: 'bottom'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#chartDonutVelocidadCabezal .c3-chart-arcs path").style("stroke-width", "0px");

  }

  dibujarDonutAvances() {
    this.chartDonutAvances = c3.generate({
      bindto: '#chartDonutAvances',
      data: {
        columns: [
          ['1', 0],
          ['2', 0],
          ['3', 0],
          ['4', 0],
          ['5', 0]
        ],
        names: {
          1: "0 - 49",
          2: "50 - 79",
          3: "80 - 99",
          4: "100",
          5: "> 100"
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutAvances .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutAvances .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutAvances .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: [
          this.colorAzul,
          this.colorVerde,
          this.colorAmarillo,
          this.colorNaranja,
          this.colorRojo
        ]
      },
      legend: {
        //bottom, right, inset
        position: 'bottom'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#chartDonutAvances .c3-chart-arcs path").style("stroke-width", "0px");

  }

  dibujarDonutCabezal() {
    this.chartDonutCabezal = c3.generate({
      bindto: '#chartDonutCabezal',
      data: {
        columns: [
          ['1', 0],
          ['2', 0],
          ['3', 0],
          ['4', 0],
          ['5', 0]
        ],
        names: {
          1: "0 - 49",
          2: "50 - 79",
          3: "80 - 99",
          4: "100",
          5: "> 100"
        },
        type: 'donut',
        onmouseover: function (d) {
          d3.select('#chartDonutCabezal .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select("#chartDonutCabezal .c3-chart-arcs-title")
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.name);
        },
        onmouseout: function (d) {
          d3.select('#chartDonutCabezal .c3-chart-arcs-title').node().innerHTML = "";
        }
      },
      color: {
        pattern: [
          this.colorAzul,
          this.colorVerde,
          this.colorAmarillo,
          this.colorNaranja,
          this.colorRojo
        ]
      },
      legend: {
        //bottom, right, inset
        position: 'bottom'
      },
      transition: {
        duration: this.tiempoTransition
      },
      tooltip: {
        show: false
      }
    });

    d3.selectAll("#chartDonutCabezal .c3-chart-arcs path").style("stroke-width", "0px");

  }

  private cargarTurnosCombo() {
    this.turnos_placeHolder = this.translateService.instant("seleccioneTurnos")
    this.turnosCombo = [
      { nombre: this.translateService.instant("manana"), id: 1 },
      { nombre: this.translateService.instant("tarde"), id: 2 },
      { nombre: this.translateService.instant("noche"), id: 3 }
    ];
  }

  private cargarFechas() {

    //FILTRO dia
    if (this.filtroDiaSemana) {
      var fechaIni;
      //calcular fecha inicio
      if (this.fechaInicio == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow()
      }

      //calcular fecha fin
      fechaIni = new Date(this.fechaInicio);
      this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 1));

      this.fechaInicio.setHours(0, 0, 0);
      this.fechaFin.setHours(0, 0, 0);
      //FILTRO semana
    } else {
      var fechaIni;
      //calcular fecha inicio
      if (this.fechaInicio == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow()
      }
      fechaIni = this.fechaInicio
      if (fechaIni.getDay() == 3) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
      } else if (fechaIni.getDay() == 4) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
      } else if (fechaIni.getDay() == 5) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
      } else if (fechaIni.getDay() == 6) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
      } else if (fechaIni.getDay() == 0) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
      } else if (fechaIni.getDay() == 2) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
      }
      //calcular fecha fin
      fechaIni = new Date(this.fechaInicio);
      this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 7));

      this.fechaInicio.setHours(0, 0, 0);
      this.fechaFin.setHours(0, 0, 0);
    }
  }

  //#region GRAFICOS
  public cargarGraficos() {
    
    this.informeEjecucionesService.get_avances_info(this.selectedMaquina).subscribe(
      json => {

        if (Object.keys(json).length > 0) {
          var an: any = json;
          if(an.data.length>0){
            this.motor = an.data[0].id;
            this.minimo = an.data[0].minimo;
            this.suave = an.data[0].cambioColor1;
            this.media = an.data[0].cambioColor2;
            this.maximo = an.data[0].maximo;
          }
          
        }

        this.tituloMin = this.minimo + " - " + this.suave + " r.p.m.";
        this.tituloMedia = this.suave + " - " + this.media + " r.p.m.";
        this.tituloMax = this.media + " - " + this.maximo + " r.p.m.";

        this.cargarGraficosSinCargarMaquina();

      }
    )
  }

  public cargarGraficosSinCargarMaquina() {

    this.loadingResumenSemana = true;
    this.loadingAvancesEstimados = true;
    this.loadingVelocidadCabezalYPotens = true;

    this.cargarResumenSemana();
    this.cargarAvancesEstimadosDistanciaRecorrida();
    this.cargarVelocidadCabezalYPotens();

  }

  public cargarResumenSemana() {

    var fechaInicio = this.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.dateToYYYYMMDDtHHmmSSz(this.fechaFin);

    this.InformeRendimientoService.get_procesos_completo(this.selectedMaquina, this.turnosComboSelected, fechaInicio, fechaFin).subscribe(
      json => {
        this.loadingResumenSemana = false;
        var data = json;

        this.dataResumenSemana = {
          columns: [
            ['preparacion', data[0].tPreparacion],
            ['ejecucion', data[0].tEjecucion],
            ['microparada', data[0].tMicroParadas],
            ['parada', data[0].tParada],
            ['mantenimiento', data[0].tMantenimiento],
            ['alarma', data[0].tAlarma],
            ['apagada', data[0].tApagado]
          ],
          names: {
            preparacion: this.translateService.instant("preparacion") + " (" + this.secondsToTotalHorasMin(data[0].tPreparacion) + ")",
            ejecucion: this.translateService.instant("ejecucion") + " (" + this.secondsToTotalHorasMin(data[0].tEjecucion) + ")",
            microparada: this.translateService.instant("microparada") + " (" + this.secondsToTotalHorasMin(data[0].tMicroParadas) + ")",
            parada: this.translateService.instant("parada") + " (" + this.secondsToTotalHorasMin(data[0].tParada) + ")",
            mantenimiento: this.translateService.instant("mantenimiento") + " (" + this.secondsToTotalHorasMin(data[0].tMantenimiento) + ")",
            alarma: this.translateService.instant("alarma") + " (" + this.secondsToTotalHorasMin(data[0].tAlarma) + ")",
            apagada: this.translateService.instant("apagada") + " (" + this.secondsToTotalHorasMin(data[0].tApagado) + ")",
          }
        }

        this.chartDonutResumenSemana.load(this.dataResumenSemana);

      }
    )
  }

  public cargarAvancesEstimadosDistanciaRecorrida() {

    var fechaInicio = this.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    var now = this.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow());

    this.informeEjecucionesService.get_avances_estimados_distancia_recorrida(this.selectedMaquina, this.turnosComboSelected, fechaInicio, fechaFin, now).subscribe((json: any) => {

        this.loadingAvancesEstimados = false;

        var maximo = 0;
        var an: any = json.data;
        an.forEach(f => {
          if (f.avanceM > maximo) {
            maximo = f.avanceM;
          }
        });

        if (maximo != parseInt(maximo.toString())) {
          if (maximo > parseInt(maximo.toString())) {
            maximo = parseInt(maximo.toString()) + 1;
          } else {
            maximo = parseInt(maximo.toString());
          }
        }

        while (maximo % 9 != 0) {
          maximo = maximo + 1;
        }

        this.visibleEje1 = "none";
        this.visibleEje2 = "none";
        this.visibleEje3 = "none";
        this.visibleEje4 = "none";
        this.visibleEje5 = "none";
        this.visibleEje6 = "none";
        this.visibleEje7 = "none";
        this.visibleEje8 = "none";
        this.visibleEje9 = "none";
        this.visibleEje10 = "none";

        this.labelMetros9 = (maximo).toString();
        this.labelMetros8 = (maximo - (maximo / 9)).toString();
        this.labelMetros7 = (maximo - (2 * (maximo / 9))).toString();
        this.labelMetros6 = (maximo - (3 * (maximo / 9))).toString();
        this.labelMetros5 = (maximo - (4 * (maximo / 9))).toString();
        this.labelMetros4 = (maximo - (6 * (maximo / 9))).toString();
        this.labelMetros3 = (maximo - (7 * (maximo / 9))).toString();
        this.labelMetros2 = (maximo - (8 * (maximo / 9))).toString();
        this.labelMetros1 = (0).toString();

        
        if (json.data.length > 0) {
          this.visibleEje1 = "block";
          this.labelEje1 = json.data[0].nombre;
          this.labelDatoEje1 = json.data[0].avanceM;
          this.valorEje1 = this.calcularPorcentaje(json.data[0].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 1) {
          this.visibleEje2 = "block";
          this.labelEje2 = json.data[1].nombre;
          this.labelDatoEje2 = json.data[1].avanceM;
          this.valorEje2 = this.calcularPorcentaje(json.data[1].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 2) {
          this.visibleEje3 = "block";
          this.labelEje3 = json.data[2].nombre;
          this.labelDatoEje3 = json.data[2].avanceM;
          this.valorEje3 = this.calcularPorcentaje(json.data[2].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 3) {
          this.visibleEje4 = "block";
          this.labelEje4 = json.data[3].nombre;
          this.labelDatoEje4 = json.data[3].avanceM;
          this.valorEje4 = this.calcularPorcentaje(json.data[3].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 4) {
          this.visibleEje5 = "block";
          this.labelEje5 = json.data[4].nombre;
          this.labelDatoEje5 = json.data[4].avanceM;
          this.valorEje5 = this.calcularPorcentaje(json.data[4].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 5) {
          this.visibleEje6 = "block";
          this.labelEje6 = json.data[5].nombre;
          this.labelDatoEje6 = json.data[5].avanceM;
          this.valorEje6 = this.calcularPorcentaje(json.data[5].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 6) {
          this.visibleEje7 = "block";
          this.labelEje7 = json.data[6].nombre;
          this.labelDatoEje7 = json.data[6].avanceM;
          this.valorEje7 = this.calcularPorcentaje(json.data[6].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 7) {
          this.visibleEje8 = "block";
          this.labelEje8 = json.data[7].nombre;
          this.labelDatoEje8 = json.data[7].avanceM;
          this.valorEje8 = this.calcularPorcentaje(json.data[7].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 8) {
          this.visibleEje9 = "block";
          this.labelEje9 = json.data[8].nombre;
          this.labelDatoEje9 = json.data[8].avanceM;
          this.valorEje9 = this.calcularPorcentaje(json.data[8].avanceM, 100, maximo, 2, true);
        }
        if (json.data.length > 9) {
          this.visibleEje10 = "block";
          this.labelEje10 = json.data[9].nombre;
          this.labelDatoEje10 = json.data[9].avanceM;
          this.valorEje10 = this.calcularPorcentaje(json.data[9].avanceM, 100, maximo, 2, true);
        }
      });
  }

  public cargarVelocidadCabezalYPotens() {

    var fechaInicio = this.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    var now = this.dateToYYYYMMDDtHHmmSSz(this.myFunctions.getDateNow());
    var listaIdsTurnos = (this.comboTurnosSeleccion === undefined) ? [] : this.comboTurnosSeleccion.map(a => a.id);

    this.informeEjecucionesService.get_velocidades(this.selectedMaquina, listaIdsTurnos.join(","), fechaInicio, fechaFin, now, this.motor, this.minimo, this.suave, this.media, this.maximo).subscribe((json: any) => {
        this.loadingVelocidadCabezalYPotens = false;

        var ejecucionManual = 0;
        var ejecucionAutomatico = 0;
        var ejecucionMdi = 0;
        var ejecucionOtros = 0;
        var ejecucionJog = 0;
        var ejecucionTotal = 0;

        var avanceRPMrango1 = 0;
        var avanceRPMrango2 = 0;
        var avanceRPMrango3 = 0;

        var poten1rango1 = 0;
        var poten1rango2 = 0;
        var poten1rango3 = 0;
        var poten1rango4 = 0;
        var poten1rango5 = 0;

        var poten2rango1 = 0;
        var poten2rango2 = 0;
        var poten2rango3 = 0;
        var poten2rango4 = 0;
        var poten2rango5 = 0;

        if (Object.keys(json).length > 0) {
          var an: any = json;

          ejecucionManual = an.data[0].ejecucionManual;
          ejecucionAutomatico = an.data[0].ejecucionAutomatico;
          ejecucionMdi = an.data[0].ejecucionMdi;
          ejecucionJog = an.data[0].ejecucionJog;
          ejecucionOtros = an.data[0].ejecucionOtros;
          ejecucionTotal = ejecucionManual + ejecucionAutomatico + ejecucionMdi + ejecucionJog + ejecucionOtros;

          avanceRPMrango1 = an.data[0].avanceRPMrango1;
          avanceRPMrango2 = an.data[0].avanceRPMrango2;
          avanceRPMrango3 = an.data[0].avanceRPMrango3;

          poten1rango1 = an.data[0].poten1rango1;
          poten1rango2 = an.data[0].poten1rango2;
          poten1rango3 = an.data[0].poten1rango3;
          poten1rango4 = an.data[0].poten1rango4;
          poten1rango5 = an.data[0].poten1rango5;

          poten2rango1 = an.data[0].poten2rango1;
          poten2rango2 = an.data[0].poten2rango2;
          poten2rango3 = an.data[0].poten2rango3;
          poten2rango4 = an.data[0].poten2rango4;
          poten2rango5 = an.data[0].poten2rango5;

        }

        //EJECUCIONES
        this.dataEjecucion = {
          columns: [
            ['manual', ejecucionManual],
            ['automatico', ejecucionAutomatico],
            ['mdi', ejecucionMdi],
            ['jog', ejecucionJog],
            ['otros',ejecucionOtros]
          ],
          names: {
            manual: this.translateService.instant("manual") + " (" + this.secondsToTotalHorasMin(ejecucionManual) + ")",
            automatico: this.translateService.instant("automatico") + " (" + this.secondsToTotalHorasMin(ejecucionAutomatico) + ")",
            mdi: this.translateService.instant("mdi") + " (" + this.secondsToTotalHorasMin(ejecucionMdi) + ")",
            jog: this.translateService.instant("jog") + " (" + this.secondsToTotalHorasMin(ejecucionJog) + ")",
            otros: this.translateService.instant("otros") + "(" + this.secondsToTotalHorasMin(ejecucionOtros) + ")",
          }
        }

        //VELOCIDAD CABEZAL
        this.dataVelocidadCabezal = {
          columns: [
            ['min', avanceRPMrango1],
            ['media', avanceRPMrango2],
            ['max', avanceRPMrango3]
          ],
          names: {
            min: this.tituloMin + " (" + this.secondsToTotalHorasMin(avanceRPMrango1) + ")",
            media: this.tituloMedia + " (" + this.secondsToTotalHorasMin(avanceRPMrango2) + ")",
            max: this.tituloMax + " (" + this.secondsToTotalHorasMin(avanceRPMrango3) + ")",
          },
        }

        //POTENCIOMETRO AVANCES
        this.dataAvances = {
          columns: [
            ['1', poten1rango1],
            ['2', poten1rango2],
            ['3', poten1rango3],
            ['4', poten1rango4],
            ['5', poten1rango5]
          ],
          names: {
            1: "0 - 49 (" + this.secondsToTotalHorasMin(poten1rango1) + ")",
            2: "50 - 79 (" + this.secondsToTotalHorasMin(poten1rango2) + ")",
            3: "80 - 99 (" + this.secondsToTotalHorasMin(poten1rango3) + ")",
            4: "100 (" + this.secondsToTotalHorasMin(poten1rango4) + ")",
            5: "> 100 (" + this.secondsToTotalHorasMin(poten1rango5) + ")",
          }
        }

        //POTENCIOMETRO CABEZAL
        this.dataCabezal = {
          columns: [
            ['1', poten2rango1],
            ['2', poten2rango2],
            ['3', poten2rango3],
            ['4', poten2rango4],
            ['5', poten2rango5]
          ],
          names: {
            1: "0 - 49 (" + this.secondsToTotalHorasMin(poten2rango1) + ")",
            2: "50 - 79 (" + this.secondsToTotalHorasMin(poten2rango2) + ")",
            3: "80 - 99 (" + this.secondsToTotalHorasMin(poten2rango3) + ")",
            4: "100 (" + this.secondsToTotalHorasMin(poten2rango4) + ")",
            5: "> 100 (" + this.secondsToTotalHorasMin(poten2rango5) + ")",
          }
        }

        this.chartDonutEjecucion.load(this.dataEjecucion);
        this.chartDonutVelocidadCabezal.load(this.dataVelocidadCabezal);
        this.chartDonutAvances.load(this.dataAvances);
        this.chartDonutCabezal.load(this.dataCabezal);

      });
  }

  //#endregion

  //#region maquinaClicked
  private maquinaClicked(idMaquina, sender) {
    document.getElementsByClassName("actual")[0].classList.toggle("actual");
    if (sender.path[0].toString().includes("object HTMLDivElement")) {
      sender.path[0].setAttribute("class", 'clickable actual');
    } else {
      sender.path[1].setAttribute("class", 'clickable actual');
    }
    this.selectedMaquina = idMaquina;
    this.cargarGraficos();
  }
  //#endregion

  //#region TURNOS
  public turnos_onValueChange(value: any): void {
    this.turnosComboSelected = "";

    for (let turno of value) {
      this.turnosComboSelected += turno.id + ", ";
    }

    this.turnosComboSelected = this.turnosComboSelected.substring(0, this.turnosComboSelected.length - 2);

    // if (this.turnosComboSelected == "") {
    //   this.turnosComboSelected = "1, 2, 3";
    // }

    //this.cargarGraficos();
    this.cargarGraficosSinCargarMaquina();
  }
  //#endregion

  //#region FECHA
  public fechaInicio_dateChanged(value: Date): void {
    this.cargarFechas();
    //this.cargarGraficos();
    this.cargarGraficosSinCargarMaquina();
  }

  public fechaFin_dateChanged(value: Date): void {
  }
  //#endregion

  //#region FILTRO DIS/SEMANA
  public btnDia() {
    this.filtroDiaSemana = true;
    this.fechasTitulo = this.translateService.instant('fecha')
    this.fechaFinDisplay = "none";
    this.cargarFechas();

    //this.cargarGraficos();
    this.cargarGraficosSinCargarMaquina();
  }

  public btnSemana() {
    this.filtroDiaSemana = false;
    this.fechasTitulo = this.translateService.instant('fechas')
    this.fechaFinDisplay = "block";
    this.cargarFechas();

    //this.cargarGraficos();
    this.cargarGraficosSinCargarMaquina();
  }
  //#endregion

  //#region FORMATO FECHA
  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

  public secondsToTotalHorasMin(seconds: number): string {
    seconds = Number(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);

    return this.addZero(h).toString() + ":" + this.addZero(m).toString();
  }
  //#endregion

  //#region CALCULAR PORCENTAJE
  public calcularPorcentaje(valor: number, sobre: number, de: number, decimales: number, limitado: boolean): number {
    var porcentaje: number = 0;

    if (de != 0) {
      var d: number = 10 ^ decimales;
      var porcen: number = 0
      porcen = valor * sobre * d / de
      if (porcen > sobre * d && limitado) {
        porcen = sobre * d
      }
      porcentaje = porcen / d
    } else {
      if (valor > 0 && limitado) {
        porcentaje = sobre
      } else if (!limitado) {
        porcentaje = -1
      }
    }

    if (porcentaje < 0) {
      porcentaje = 0
    }

    return porcentaje;
  }
  //#endregion

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

}
