import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HerramientasService, MenuService, FabricantesService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService } from '@app/_services';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ElementoCombo } from '@app/_models';

@Component({
  selector: 'app-herramientassUnidades-detalle',
  templateUrl: './herramientasUnidadesDetalle.component.html'
})
export class HerramientasUnidadesDetalleComponent {
  public selectedTipoHerramienta: ElementoCombo;
  private translate: TranslateService;
  public events: string[] = [];
  public herramientas: [];
  public imagePreviews: any[] = [];
  public id: number;
  public idTipoHerramienta: number;
  public requerido: boolean;
  public logo: string;
  public loadingGraficoTresEjes: any = false;
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
    maxFileSize: 1048576
  };


  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  form: FormGroup;
  loading = false;
  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;
  alertService: any;

  tipoHerramienta: any = [];
  fabricante: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private herramientasService: HerramientasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private sanitizer: DomSanitizer,
    private menuService: MenuService,
    translate: TranslateService,
    private fabricantesService: FabricantesService) {
    this.translate = translate;
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }


  ngOnInit() {
    // Se carga unformulario vacio para que no casque en la primera carga 
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,

      tipoHerramienta: [{ id: 0 }],
      fabricante: [{ id: 0 }],
      nombre: [""],
      referencia: [""],
      tieneNserie: [false],

      vidaUtil: [0],
      diametro: [0],
      longitud: [0],
      angulo: [0],
      numPlacas: [0],

      salto: [0],
      idERP: [0],
      coste: [0],
      descripcion: [""],
    });

    this.cargarCombos();
  }
  cargarCombos() {
    //tipoHerramienta
    this.herramientasService.get_COMBO_tiposHerramientas().subscribe(
      (json: any) => {
        this.tipoHerramienta = json.data;
      });
    //fabricante
    this.fabricantesService.get_COMBO().subscribe(
      (json: any) => {
        this.fabricante = json.data;
      });

    this.cargarDatos();
  }
  cargarDatos() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.herramientasService.GetHerramientaUnidadById(this.id).subscribe(
      json => {
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,

          tipoHerramienta: [{ id: json.data[0].idTipoHerramienta }],
          fabricante: [{ id: json.data[0].idFabricante }],
          nombre: [json.data[0].nombre],
          referencia: [json.data[0].referencia],
          tieneNserie: [json.data[0].tieneNserie],

          vidaUtil: [json.data[0].vida],
          diametro: [json.data[0].diametro],
          longitud: [json.data[0].longitud],
          angulo: [json.data[0].angulo],
          numPlacas: [json.data[0].numPlacas],

          salto: [json.data[0].salto],
          idERP: [json.data[0].idERP],
          coste: [json.data[0].coste],
          descripcion: [json.data[0].descripcion],
        });
      });


  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }
  private async insert() {
    this.herramientasService.insertHerramientasUnidad(this.form.value)
      .subscribe((result) => {
        {
          if (result.error == false) {
            this.router.navigate(['herramientasunidades']);
          }
        }
      }
      );
  }
  private async update() {
    this.form.value.id = this.id;
    this.herramientasService.updateHerramientasUnidad(this.form.value)
      .subscribe((result) => {
        if (result.error == false) {
          this.router.navigate(['herramientasunidades']);
        }
        else {
        }
      });
  }


  public atras() {
    this.router.navigate(['herramientasunidades']);
  }
}

