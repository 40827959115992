import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, PiezasTipoService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-piezasTipoDetalle',
  templateUrl: './piezasTipoDetalle.component.html'
})
export class PiezasTipoDetalleComponent implements OnInit {

  navigationSubscription;

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  public user: any;

  tipoMaterialLista: any;
  tipoMaterialSeleccionado: any;

  constructor(
    private piezasTipoService: PiezasTipoService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsuariosService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService
  ) {

    this.user = this.userService.userValue;
    this.menuService.titulo = this.translateService.instant('piezastipo').toUpperCase();

  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      nombre: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      tipo_material: new FormControl(undefined, [Validators.required]),
    });

    this.piezasTipoService.Get_TipoMaterial_Combo().subscribe((result) => {
      var datuak: any = result;
      this.tipoMaterialLista = datuak;
      this.tipoMaterialSeleccionado = this.tipoMaterialLista[0];
      this.form.controls['tipo_material'].setValue(this.tipoMaterialSeleccionado);

      if (!this.isAddMode) {
        this.piezasTipoService.getById(this.id).pipe(first()).subscribe((result) => {
          this.tipoMaterialSeleccionado = this.tipoMaterialLista.find(x => x.id === result.tipo_material);
          this.form.patchValue(result);
          this.form.controls['tipo_material'].setValue(this.tipoMaterialSeleccionado);
        });
      }

    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    if (this.isAddMode) {
      this.crearTipoMaterial();
    } else {
      this.updateTipoMaterial();
    }

  }

  private crearTipoMaterial() {
    this.piezasTipoService.create(-1, this.form.value.nombre, this.form.value.tipo_material.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['piezastipo/']);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  private updateTipoMaterial() {
    this.piezasTipoService.update(this.form.value.id, this.form.value.nombre, this.form.value.tipo_material.id)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['piezastipo/']);
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

}
