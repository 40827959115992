<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-9">

      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'almacen' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body ">
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <!--NOMBRE-->
                <div class="form-group col-md-12">
                  <kendo-label text="{{ 'nombre' | translate }}">
                    <div class="caja">
                      <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                    </div>
                  </kendo-label>
                </div>
              </div>
              <div class="row">
                <!-- TIPO DE ALMACEN -->
                <div class="float-left col-md-3">
                  <div class="form-group">
                    <label>{{ 'tipoAlmacen' | translate }}</label>
                    <div class="caja">
                      <kendo-combobox [data]="listaTipoAlmacenes"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [(value)]="idSelected"
                                      formControlName="tipoAlmacenSeleccionado"
                                      placeholder="{{ 'tipoAlmacen' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}"
                                      (valueChange)="tipoAlmacenChanged($event)">
                      </kendo-combobox>
                    </div>
                  </div>
                </div>
                <!--AREAS PRODUCTIVAS-->
                <div class="float-left col-md-3">
                  <div class="form-group">
                    <label>{{ 'secciones' | translate }}</label>
                    <div class="caja">
                      <kendo-combobox [data]="groupedSeccion"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      formControlName="seccionesSeleccionadas"
                                      [(value)]="idSeccionSelected"
                                      (valueChange)="filtrarMaquinasPorAreaProductivaYGrupo()"
                                      placeholder="{{ 'secciones' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}">
                      </kendo-combobox>
                    </div>
                  </div>
                </div>
                <!--TIENE POSICIONES-->
                <div class="float-left col-md-3">
                  <div class="form-group">
                    <label>{{ 'tienePosiciones' | translate }}</label>
                    <div class="caja" style="margin: 0 0 0 7px;">
                      <kendo-switch [(ngModel)]="form.value.tienePosiciones" formControlName="tienePosiciones" [onLabel]="' '"
                          [offLabel]="' '" [disabled]="!enableSwicthPosiciones"></kendo-switch>
                  </div>
                  </div>
                </div>
                <!-- CAPACIDAD -->
                <div class="float-left">
                  <kendo-label text="{{ 'capacidad' | translate }}">
                    <div class="caja">
                      <kendo-textbox formControlName="capacidad" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                    </div>
                  </kendo-label>
                </div>
              </div>
              <div class="row" *ngIf="idSelected != undefined">
                <div *ngIf=" idSelected.id == 1">
                  <!--GRUPOS-->
                  <div class="float-left col-md-3">
                    <div class="form-group">
                      <label>{{ 'grupos' | translate }}</label>
                      <div class="caja">
                        <kendo-combobox [data]="grupos"
                                        [textField]="'nombre'"
                                        [valueField]="'id'"
                                        (valueChange)="filtrarMaquinasPorAreaProductivaYGrupo()"
                                        formControlName="gruposSeleccionados"
                                        placeholder="{{ 'grupoMaquinas' | translate }}"
                                        [kendoDropDownFilter]="{operator: 'contains'}">
                        </kendo-combobox>
                      </div>
                    </div>
                  </div>
                  <!-- TIPO DE PUESTO -->
                  <div class="float-left col-md-4">
                    <div class="form-group">
                      <label>{{ 'tipoPuesto' | translate }}</label>
                      <div class="caja">
                        <kendo-combobox [data]="listaTipoPuesto"
                                        formControlName="tiposPuestossSeleccionados"
                                        [textField]="'nombre'"
                                        [valueField]="'id'"
                                        placeholder="{{ 'seleccioneTipoPuesto' | translate }}"
                                        (valueChange)="filtrarMaquinasPorAreaProductivaYGrupo()"
                                        [kendoDropDownFilter]="{operator: 'contains'}">
                        </kendo-combobox>
                      </div>
                    </div>
                  </div>
                  <!--LISTA PUESTO-->
                  <div class="float-left col-md-4">
                    <div class="form-group">
                      <label>{{ 'puesto' | translate }}</label>
                      <div class="caja">
                        <kendo-combobox [data]="listaPuestos"
                                        formControlName="puestosSeleccionados"
                                        [textField]="'nombre'"
                                        [valueField]="'id'"
                                        placeholder="{{ 'seleccionePuesto' | translate }}"
                                        [kendoDropDownFilter]="{operator: 'contains'}">
                        </kendo-combobox>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <!-- DESCRIPCION -->
                <div class="col-md-12">
                  <label>{{ 'descripcion' | translate }}</label>
                  <textarea kendoTextArea formControlName="descripcion"></textarea>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <!--IMAGEN-->
                <label ID="lCaracteristicas" CssClass="">{{ 'imagen' | translate }}</label>
                <!--IMAGEN VIEJA-->
                <div>
                  <img [src]="imageToShow" style="max-height: 113px">
                </div>
                <div class="form-group">
                  <div class="caja">

                    <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false" (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                      <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                             clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                             dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                             externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                             fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                             fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                             filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                             filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                             filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                             headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                             headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                             headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                             invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                             invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                             invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                             pause="{{ 'pause' | translate }}"
                                             remove="{{ 'remove' | translate }}"
                                             resume="{{ 'resume' | translate }}"
                                             retry="{{ 'retry' | translate }}"
                                             select="{{ 'select' | translate }}"
                                             uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                      </kendo-upload-messages>
                    </kendo-fileselect>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--BOTONES-->
          <div class="form-group" style="margin: 15px 0 0 0;">
            <button kendoButton class="btn btn-primary btn-sm mr-1" [disabled]="user.almacenes < 2">{{ 'guardar' | translate}}</button> <!--TODO MARI: ERROREA: [disabled]="this.user.almacenes<2"-->
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">{{ 'cancelar' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
