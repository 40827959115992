import { Component, Input } from '@angular/core';
import { AlertService, MantenimientosPredefinidosService, MaquinasService, MenuService, UsuariosService } from '@app/_services';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Perdida } from '@app/_models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-maquina-secciones-mantenimientos',
  templateUrl: './maquinaDetalleMantenimientos.component.html'
})

export class MaquinaDetalleSeccionesMantenimientosComponent {

  @Input() id: string;

  public mantenimiento: any = [];
  public mantenimiento_seleccionados: number[] = [];
  public mantenimiento_modalReference: NgbModalRef;
  public mantenimiento_modalReferenceLoading: NgbModalRef;
  public popupMantenimientoPredifinido: Perdida[];
  public popupMantenimientoPredifinido_selectedItems: any;
  user = this.userService.userValue;

  constructor(
    private maquinasService: MaquinasService, private mantenimientosPredefinidosService: MantenimientosPredefinidosService,
    public router: Router,
    private menuService: MenuService, private alertService: AlertService,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('maquina').toUpperCase();
  }

  ngOnInit() {

    if (Number(this.id) > 0) {

      this.mantenimientosPredefinidosService.GetAll().pipe(first()).subscribe((result: any) => {
        this.popupMantenimientoPredifinido = result.data;
        this.popupMantenimientoPredifinido_selectedItems = [];
        this.maquinasService.GetAll_Mantenimiento(this.id).subscribe((result) => {
          this.mantenimiento = result.data;
          console.log("Mantenimientos:", result.data)
        });
      });


    }

  }

  public mantenimiento_predifinido_onValueChange(value: any): void {

    /* this.popupMantenimientoPredifinido_selectedItem = "";
    for (let turno of value) {
      this.popupMantenimientoPredifinido_selectedItem += turno.id + ", ";
    }

    this.popupMantenimientoPredifinido_selectedItem = this.popupMantenimientoPredifinido_selectedItem.substring(0, this.popupMantenimientoPredifinido_selectedItem.length - 2);
 */  }

  mantenimiento_seleccionarRegistro() {



    if (this.popupMantenimientoPredifinido_selectedItems != undefined && this.popupMantenimientoPredifinido_selectedItems.length > 0) {
      var auxLista = [];
      this.popupMantenimientoPredifinido_selectedItems.forEach(element => {
        auxLista.push(element);
      });

      this.maquinasService.Insert_Mantenimiento_Predifinido(this.id, auxLista.join(",")).subscribe((result) => {

        if (result.error == false) {
          this.maquinasService.GetAll_Mantenimiento(this.id).subscribe((result) => {
            this.mantenimiento = result.data;
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.mantenimiento_modalReference.close();
          });
        }
        else {
          this.alertService.error(this.translateService.instant('error'));
        }
      });

    }
  }

  mantenimiento_crearRegistro() {
    this.mantenimiento_modalReference.close();
    if (this.id != undefined) {
      this.router.navigate(['maquinas/mantenimiento/crear/' + this.id]);
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  mantenimiento_onClickEditar() {
    if (this.mantenimiento_seleccionados[0] > 0) {
      if (this.id != undefined) {
        this.router.navigate(['maquinas/mantenimiento/editar/' + this.id + '/' + this.mantenimiento_seleccionados[0]]);
      }
    }
  }

  mantenimiento_onClickNuevo(content) {
    if (this.id != undefined) {
      this.mantenimientosPredefinidosService.GetAll().pipe(first()).subscribe((result: any) => {
        this.popupMantenimientoPredifinido = result.data;
        this.popupMantenimientoPredifinido_selectedItems = [];
        this.mantenimiento_modalReference = this.modalService.open(content, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
      });
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }

  }

  mantenimiento_onClickCrearRegistro() {
    if (this.id != undefined) {
      this.router.navigate(['maquinas/mantenimiento/crear/' + this.id]);
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  mantenimiento_onClickCrearRegistroPredefinido(content) {
    if (this.id != undefined) {
      this.mantenimiento_modalReference = this.modalService.open(content, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }


  mantenimiento_cellClick(e) {
    if (e.columnIndex > 0) {
      if (this.id != undefined) {
        this.router.navigate(['maquinas/mantenimiento/editar/' + this.id + '/' + e.dataItem.id]);
      }
    }
  }

  mantenimiento_onClickEliminar(content) {
    if (this.mantenimiento_seleccionados.length > 0) {
      this.mantenimiento_modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  mantenimiento_eliminarRegistro(contentLoading) {
    this.maquinasService.Delete_Mantenimiento({ ids: this.mantenimiento_seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.maquinasService.GetAll_Mantenimiento(this.id).subscribe((result) => {
            this.mantenimiento = result.data;
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          });
        }
        else {
          this.alertService.error(this.translateService.instant('error'));
        }
        this.mantenimiento_seleccionados = [];
        this.mantenimiento_modalReferenceLoading.close();
      }
    );
    this.mantenimiento_modalReference.close();
    this.mantenimiento_modalReferenceLoading = this.modalService.open(contentLoading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
  }

}
