<div class="card">
    <kendo-grid [kendoGridBinding]="JSubestados"
                [sortable]="true"
                [navigable]="false"
                filterable="menu"
                [rowHeight]="36"
                [height]="500"
                [pageSize]="30"
                kendoGridSelectBy="id"
                scrollable="virtual"
                [selectedKeys]="seleccionados"
                (cellClick)="cellClick($event)">
        <!--BOTON NUEVO-->
        <ng-template kendoGridToolbarTemplate position="top">
            <button type="button" (click)="clickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
            <button type="button" (click)="clickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
            <button type="button" (click)="clickEliminar()" class="btn btn-danger btn-sm mr-1" [disabled]="this.user.maquinas<2">{{ 'eliminar' | translate}}</button>
        </ng-template>
        <!--checkbox-->
        <kendo-grid-checkbox-column width="5%">
        <ng-template kendoGridHeaderTemplate>
            <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox >
            <label class="k-checkbox-label" for="selectAllCheckboxId"></label>            
        </ng-template>
        <!--columnas que quieres añadir-->
        </kendo-grid-checkbox-column>
        <!--orden-->
        <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="orden" title="{{ 'orden' | translate}}"></kendo-grid-column>
        <!--nombre-->
        <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}">          
            <ng-template kendoGridCellTemplate let-dataItem>
                <span> {{ dataItem.nombre | translate }}</span>
            </ng-template>            
        </kendo-grid-column>
        <!--procesosTipo-->
        <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="procesos_Tipo" title="{{ 'tipoProceso' | translate}}">            
            <ng-template kendoGridCellTemplate let-dataItem>
                <span> {{ get_nombre_Proceso(dataItem.idProcesos_Tipo) }}</span>
            </ng-template>            
        </kendo-grid-column>
        <!--color-->
        <kendo-grid-column width="40%" [style]="{'text-align': 'left'}" field="color" title="{{ 'Color' | translate}}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="colorleyenda" [ngStyle]="{'background-color': dataItem.color }"></span>
            </ng-template>
        </kendo-grid-column>
        <!--activo-->
        <kendo-grid-column  width="10%" [style]="{'text-align': 'center'}" field="activo" title="{{ 'activo' | translate}}" filter="boolean">
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" [checked]="dataItem.activo" disabled />
          </ng-template>
        </kendo-grid-column>
        <!--TRANSLATE -->
        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
</div>

<!--POPUP: Eliminar-->
<div class="popup-cont overflow-auto" *ngIf="popup_Eliminar">
    <div class="popup" style="width: 600px;">
        <div class="popup-body">
            <label>{{'subestadosEliminar' | translate }}</label>
            <!-- ¿Seguro que desea eliminar los estados seleccionados? -->
        </div>
        <div class="popup-footer text-center justify-content-end mt-2">
            <button type="button" class="btn btn-info mr-1" (click)="confirmarEliminar()">{{ 'aceptar' | translate }}</button>
            <button type="button" class="btn btn-danger mr-1" (click)="this.popup_Eliminar = false;">{{ 'cancelar' | translate }}</button>
        </div>
    </div>
</div>


