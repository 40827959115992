import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivosService, MenuService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-activosMaestros',
  templateUrl: './activosMaestros.component.html',
  styleUrls: ['./activosMaestros.component.less']
})
export class ActivosMaestrosComponent implements OnInit {

  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  dataActivos: any[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;

  user = this.userService.userValue;

  constructor(private activosService: ActivosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {
    this.menuService.titulo = this.translateService.instant('activosMaestros');

    this.cargarDatos();

  }

  cargarDatos() {
    this.activosService.getAllActivosMaestros().subscribe(
      result => {
        var an: any = result;
        this.dataActivos = an;
      });
  }

  ngOnInit(): void {
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['activoMaestro/', this.mySelection[0]]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['activoMaestro/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['activoMaestro/0']);
  }
  onClickEliminar() {
    if (this.mySelection.length > 0)
      this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        var images: string = "";
        this.mySelection.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
            var auxImage = this.dataActivos.filter(x=> x.id == ap)[0].imagen;
            if (images == "")
              images += auxImage;
            else
              images += '&1sep1&' + auxImage;
          }
        );
        this.activosService.DeleteActivosMaestros(ids, images).subscribe(() => {
          this.isDeleting = false;
          this.cargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

}
