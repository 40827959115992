<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <div class="card">
        <div class="card-body">
          <!--Tipo-->
          <div class="form-group">
            <label class="control-label">{{ 'tipo' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist formControlName="idTipo" class="form-control" [data]="tipos" [textField]="'nombre'"
                [valueField]="'id'" [value]="tipos_value" (selectionChange)="tipos_selectionChange($event)"
                [ngClass]="{ 'is-invalid': tipoRequerido }">
              </kendo-dropdownlist>
            </div>
          </div>

          <!--Carpeta-->
          <div class="form-group">
            <label class="control-label">{{ 'carpeta' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist formControlName="idCarpeta" class="form-control" [data]="carpetas"
                [textField]="'nombre'" [valueField]="'id'" [value]="carpetas_value"
                (selectionChange)="carpetas_selectionChange($event)"
                [ngClass]="{ 'is-invalid': carpetaRequerida }">
              </kendo-dropdownlist>
            </div>
          </div>

          <!--Nombre-->
          <div class="form-group">
            <label class="control-label">{{ 'nombre' | translate }}</label>
            <div class="caja">
              <input kendoTextBox formControlName="nombre" Class="form-control" 
              [ngClass]="{ 'is-invalid': nombreRequerido }"/>
            </div>
          </div>

          <!-- Tipo de acceso -->
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">{{ 'tipoAcceso' | translate }}</label>
              <kendo-buttongroup selection="single" width="100%">
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(0)" iconClass="far fa-file"
                  title="modoArchivo" [selected]="idTipoAcceso === 0">
                  {{ 'archivo' | translate }}
                </button>
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(1)" iconClass="fas fa-link"
                  title="modoLink" [selected]="idTipoAcceso === 1">
                  {{
                  'link' | translate
                  }}
                </button>
                <button kendoButton type="button" [toggleable]="true" (click)="onClick(2)" iconClass="fas fa-route"
                  title="modoRuta" [selected]="idTipoAcceso === 2">
                  {{
                  'ruta' | translate
                  }}
                </button>
              </kendo-buttongroup>
            </div>
          </div>

          <!-- Activar link
          <div class="form-group form-check">
            <kendo-label class="form-check-label" text="{{ 'activarLink' | translate}}">
              <div class="caja">
                <kendo-switch id="activarLink" formControlName="activarLink" [onLabel]="' '" [offLabel]="' '"
                  (valueChange)="onValueChange($event)"></kendo-switch>
              </div>
            </kendo-label>
          </div> -->

          <div class="form-group" [hidden]="idTipoAcceso !== 2">
            <label class="control-label">{{ 'esDirectorio' | translate}}</label>
            <div class="checkbox">
              <kendo-switch id="esDirectorio" formControlName="esDirectorio" [onLabel]="' '"
                            [offLabel]="' '"></kendo-switch>
            </div>
            <kendo-textbox class="mt-3" formControlName="nombreRuta"></kendo-textbox>
          </div>

          <!--PDF-->
          <div class="form-group" [hidden]="idTipoAcceso !== 0">
            <kendo-label text="{{ 'pdf' | translate}}">
              <div class="caja">
                <kendo-textbox formControlName="nombreArchivo" [disabled]="true"></kendo-textbox>
                <kendo-fileselect formControlName="archivo" [restrictions]="restrictions" [multiple]="false"
                  (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                  <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                    clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                    dropFilesHere="{{ 'dropFilesHere' | translate }}"
                    externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                    fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                    fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                    filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                    filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                    filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                    headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                    headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                    headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                    invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                    invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                    invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                    remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                    retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                    uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
              </div>
            </kendo-label>
          </div>

          <!--Link-->
          <div class="form-group" [hidden]="idTipoAcceso !== 1">
            <label class="control-label">{{ 'link' | translate }}</label>
            <div class="caja">
              <input kendoTextBox formControlName="link" Class="form-control" />
            </div>
          </div>
          <div class="mb-5">
          </div>
        </div>

      </div>
      <!--BOTONES-->
      <div class="form-group d-flex justify-content-center">
        <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">{{ 'guardar' |
          translate}}</button>
        <button type="button" class="btn btn-danger btn-sm mr-1" (click)="atras()">{{ 'cancelar' | translate}}</button>
      </div>
    </div>
  </div>
</form>