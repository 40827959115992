import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService, UsuariosService, HerramientasService, AlmacenesService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-vista-herramientas-placas',
  templateUrl: './vistaHerramientasPlacas.html'
})

export class VistaHerramientasPlacasComponent {

  user = this.usuariosService.userValue;

  public listaAlmacenes: any = [];

  public listaHerramientas: any = [];
  public listaPlacas: any = [];

  constructor(private usuariosService: UsuariosService,
    private menuService: MenuService,
    public router: Router,
    private herramientasService: HerramientasService,
    private almacenesService: AlmacenesService,
    private translateService: TranslateService) {

    this.menuService.titulo = this.translateService.instant('vistaHerramientasPlacas').toUpperCase();

  }

  ngOnInit(): void {

    this.cargarAlmacenes();
    
  }

  cargarAlmacenes() {

    this.almacenesService.GetAlmacenesConImagen().subscribe((result) => {
      
      this.listaAlmacenes = result;

      this.listaAlmacenes.forEach(function (a) {
        a['vida'] = 'vida_' + a.id;
        a['cantidad'] = 'cantidad_' + a.id;
      });

      this.listaAlmacenes.push({ id: 0, nombre: this.translateService.instant('total'), vida: "vida_0", cantidad: "cantidad_0" });

      this.cargarHerramientas();
      this.cargarPlacas();

    });

  }

  cargarHerramientas() {

    var tiposHerramienta: any = [];
    var herramientas: any = [];

    var r1, r2: boolean = false;

    //TIPOS HERRAMIENTA
    this.herramientasService.GetAll().subscribe((result) => {
      tiposHerramienta = result.data;
      tiposHerramienta.forEach(function (th) { th.details = []; });
      r1 = true;
      if (r1 && r2) this.cargarTodasLasHerramientas(tiposHerramienta, herramientas);
    });

    //HERRAMIENTAS
    this.herramientasService.GetAllHerramientasUnidades().subscribe((result) => {
      herramientas = result.data;
      r2 = true;
      if (r1 && r2) this.cargarTodasLasHerramientas(tiposHerramienta, herramientas);
    });

  }

  cargarTodasLasHerramientas(tiposHerramienta, herramientas) {

    herramientas.forEach(function (h) {
      var tipoHerramienta = tiposHerramienta.find(x => x.id === h.idTipoHerramienta);
      tipoHerramienta.details.push(h);
    });

    //CALCULAR LAS SUMAS DE CADA ALMACEN Y EL TOTAL
    tiposHerramienta.forEach(function (th) {

      this.listaAlmacenes.forEach(function (a) {
        th[a.vida] = 0;
        th[a.cantidad] = 0;
      });

      th.details.forEach(function (d) {
        th["vida_" + d.idAlmacen] += d.vidaEnAlmacen;
        th["cantidad_" + d.idAlmacen] += d.unidadesEnAlmacen;
        //TOTALES
        th["vida_0"] += d.vidaEnAlmacen;
        th["cantidad_0"] += d.unidadesEnAlmacen;
      });

    }, this);

    this.listaHerramientas = tiposHerramienta;

  }

  cargarPlacas() {

    var tiposPlaca: any = [];
    var placas: any = [];

    var r1, r2: boolean = false;

    //TIPOS PLACA
    this.herramientasService.GetAllPlacas().subscribe((result) => {
      tiposPlaca = result.data;
      tiposPlaca.forEach(function (tp) { tp.details = []; });
      r1 = true;
      if (r1 && r2) this.cargarTodasLasPlacas(tiposPlaca, placas);
    });

    //PLACAS
    this.herramientasService.GetAllPlacasUnidades().subscribe((result) => {
      placas = result.data;
      r2 = true;
      if (r1 && r2) this.cargarTodasLasPlacas(tiposPlaca, placas);
    });

  }

  cargarTodasLasPlacas(tiposPlaca, placas) {

    placas.forEach(function (p) {
      var tipoPlaca = tiposPlaca.find(x => x.id === p.idTipoPlaca);
      tipoPlaca.details.push(p);
    });

    //CALCULAR LAS SUMAS DE CADA ALMACEN Y EL TOTAL
    tiposPlaca.forEach(function (tp) {

      this.listaAlmacenes.forEach(function (a) {
        tp[a.vida] = 0;
        tp[a.cantidad] = 0;
      });

      tp.details.forEach(function (d) {
        tp["vida_" + d.idAlmacen] += d.vidaEnAlmacen;
        tp["cantidad_" + d.idAlmacen] += d.unidadesEnAlmacen;
        //TOTALES
        tp["vida_0"] += d.vidaEnAlmacen;
        tp["cantidad_0"] += d.unidadesEnAlmacen;
      });

    }, this);

    this.listaPlacas = tiposPlaca;
    
  }
     
}
