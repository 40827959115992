import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/AreasProductivas`;
@Injectable()
export class AreasProductivasService extends BehaviorSubject<any> {

  public loading = false;
  

  constructor(private http: HttpClient) {
    super(null);
  }

  Get(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get`, { id }, { withCredentials: true });
  }
  Insert(nombre, descripcion, email, telefono, direccion, codigoPostal, provincia, pais) {
    return this.http.post<JSON>(`${baseUrl}/Insert`, { nombre: nombre, descripcion: descripcion, email: email, telefono: telefono, direccion: direccion,
    codigoPostal: codigoPostal, provincia: provincia, pais: pais }, { withCredentials: true });
  }
  Update(id: number, nombre, descripcion, email, telefono, direccion, codigoPostal, provincia, pais) {
    return this.http.post<JSON>(`${baseUrl}/Update`, { id: id, nombre: nombre, descripcion: descripcion, email: email, telefono: telefono, direccion: direccion,
      codigoPostal: codigoPostal, provincia: provincia, pais: pais }, { withCredentials: true });
  }
  Delete(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/Delete`, { ids }, { withCredentials: true });
  }

  Seccion_Get_ByAP(idAreaProductiva: number, idSeccion: number) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_Get_ByAP`, { idAreaProductiva, idSeccion }, { withCredentials: true });
  }

  Seccion_Get_ById(idSeccion: number) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_Get_ById`, { idSeccion }, { withCredentials: true });
  }
  Seccion_Insert(idAreaProductiva: number, nombre: string, descripcion: string, email: string, telefono: string) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_Insert`, { idAreaProductiva: idAreaProductiva, nombre: nombre, descripcion: descripcion,
    email: email, telefono: telefono }, { withCredentials: true });
  }
  Seccion_Update(idSeccion: number, nombre: string, descripcion: string, email: string, telefono: string) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_Update`, { idSeccion: idSeccion, nombre: nombre, descripcion: descripcion,
      email: email, telefono: telefono }, { withCredentials: true });
  }
  Seccion_Delete(ids: string) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_Delete`, { ids }, { withCredentials: true });
  }

  Seccion_Get_GrupoMaquinas(idSeccion: number) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_Get_GrupoMaquinas`, { idSeccion }, { withCredentials: true });
  }

  Seccion_GetAll_GrupoMaquinas(idSeccion: number) {
    return this.http.post<JSON>(`${baseUrl}/Seccion_GetAll_GrupoMaquinas`, { idSeccion }, { withCredentials: true });
  }

  Seccion_Get_All() {
    return this.http.get(`${baseUrl}/Secciones_Get`, { withCredentials: true });
  }

  Get_AreasConSecciones(id: number) {
    return this.http.get(`${baseUrl}/Get_AreasConSecciones/`+id, { withCredentials: true });
  }
}
