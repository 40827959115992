<style>
    .k-text-error {
        display: none;
    }

    :host /deep/ .k-grid tbody td {
        white-space: nowrap;
        line-height: 20px;
        padding: 8px 12px;
    }

    :host /deep/ .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<!-- PANEL DE CARGA -->
<div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<!-- FILTRO -->
<div class="cont-filtro-desplegable card" [ngClass]="status ? 'filtro-oculto' : 'filtro-desplegado'">
    <div class="card-header">
        <h3>{{ 'filtro' | translate}}</h3>
        <button type="button" class="flt-cerrar " (click)="this.status = !this.status;"><i
                class="fas fa-times"></i></button>
    </div>
    <div class="filtro-desplegable card-body flt">
        <!-- Filtro: CON GRUPOS -->
        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros: [datosFiltro] }"></ng-container>
        <ng-template #recursiveListTmpl let-filtross="filtros">
            <div *ngFor="let filtro of filtross" class="mt-2">
                <!-- GRUPO -->
                <div *ngFor="let row of filtro.group" class="flt-grupo">
                    <!-- LINEA FILTRO -->
                    <div *ngIf="row.group == undefined" class="clearfix flt-linea">
                        <!-- AND / OR -->
                        <div class="flt-andor">
                            <!-- = 1ª ROW NO TIENE NI OR, NI AND -->
                            <label class="float-left" *ngIf="row == filtro.group[0]"> {{ 'where' | translate}} </label>
                            <!-- = 2ª ROW -->
                            <kendo-dropdownlist (selectionChange)="refiltrarFiltro(filtro)"
                                *ngIf="row == filtro.group[1]" [data]="andOr" [textField]="'nombre'" [valueField]="'id'"
                                [(value)]="filtro.logic">
                            </kendo-dropdownlist>
                            <!-- > 2ª ROW -->
                            <label class="d-flex justify-content-center"
                                *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 0"> {{ 'y'
                                | translate}} </label>
                            <label class="d-flex justify-content-center"
                                *ngIf="row != filtro.group[0] && row != filtro.group[1] && filtro.logic.id == 1"> {{ 'o'
                                | translate}} </label>
                        </div>
                        <!-- TIPO -->
                        <div class="flt-tipo" *ngIf="row.columna.tipo != 'date'">
                            <kendo-dropdownlist (selectionChange)="filtroTipoChanged(filtro, row, $event)"
                                [data]="columnasFiltro" [textField]="'nombre'" [valueField]="'id'"
                                [class.flt-sin-seleccionar]="row.columna.id == 0" [(value)]="row.columna"></kendo-dropdownlist>
                        </div>
                        <!-- CONTENEDOR FILTRO DE LINEA -->
                        <div class="flt-filtro-linea">
                            <!-- Filtro: NUMEROS ENTEROS -->
                            <div *ngIf="row.columna.tipo == 'numeric'">
                                <!-- OPCION -->
                                <div class="flt-opcion">
                                    <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'"
                                        [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                                </div>
                                <!-- DATO(s) -->
                                <div *ngIf="row.operator.id > 0" class="flt-datos">
                                    <div class="flt-dato1">
                                        <!-- DATO 1 -->
                                        <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999"
                                            [(value)]="row.numberMin"></kendo-numerictextbox>
                                    </div>
                                    <div *ngIf="row.operator.dobleValor" class="flt-dato2">
                                        <!-- DATO 2 -->
                                        <kendo-numerictextbox format="0" [min]="-9999999" [max]="9999999"
                                            [(value)]="row.numberMax"></kendo-numerictextbox>
                                    </div>
                                </div>
                            </div>
                            <!-- Filtro: DECIMALES -->
                            <div *ngIf="row.columna.tipo == 'decimal'">
                                <div class="flt-opcion">
                                    <!-- OPCION -->
                                    <kendo-dropdownlist [data]="opcionNumericDecimal" [textField]="'nombre'"
                                        [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                                </div>
                                <!-- DATO(s) -->
                                <div *ngIf="row.operator.id > 0" class="flt-datos">
                                    <div class="flt-dato1">
                                        <!-- DATO 1 -->
                                        <kendo-numerictextbox [format]="row.decimalformat" [min]="-9999999"
                                            [max]="9999999" [(value)]="row.decimalMin"></kendo-numerictextbox>
                                    </div>
                                    <div class="flt-dato2">
                                        <!-- DATO 2 -->
                                        <kendo-numerictextbox *ngIf="row.operator.dobleValor"
                                            [format]="row.decimalformat" [min]="-9999999" [max]="9999999"
                                            [(value)]="row.decimalMax"></kendo-numerictextbox>
                                    </div>
                                </div>
                            </div>
                            <!-- Filtro: TEXTO -->
                            <div *ngIf="row.columna.tipo == 'string'">
                                <div class="flt-opcion">
                                    <!-- OPCION -->
                                    <kendo-dropdownlist [data]="opcionString" [textField]="'nombre'" [valueField]="'id'"
                                        [(value)]="row.operator"></kendo-dropdownlist>
                                </div>
                                <div class="flt-datos">
                                    <div class="flt-dato1 float-left mr-2">
                                        <!-- DATO -->
                                        <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                                    </div>
                                </div>
                            </div>
                            <!-- Filtro: COMBO ESTATICO -->
                            <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                                <div class="flt-opcion">
                                    <!-- OPCION -->
                                    <kendo-dropdownlist [data]="opcionComboEstricto" [textField]="'nombre'"
                                        [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                                </div>
                                <!-- DATO(s) -->
                                <div *ngIf="row.operator.id > 0" class="flt-datos">
                                    <div class="flt-dato1">
                                        <!-- DATO 1 -->
                                        <kendo-dropdownlist *ngIf="!row.operator.dobleValor" [data]="row.combo"
                                            [textField]="'nombre'" [valueField]="'id'" [(value)]="row.comboSelected"
                                            style="width: 220px;">
                                        </kendo-dropdownlist>
                                    </div>
                                    <div class="flt-dato2">
                                        <!-- DATO 2 -->
                                        <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag
                                            [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'"
                                            [valueField]="'nombre'" [autoClose]="false" style="width: 220px;"
                                            [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                                            <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                                <span class="k-icon k-i-arrow-s"></span>
                                                <ng-container *ngIf="dataItems.length == 1">{{
                                                    this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}
                                                </ng-container>
                                                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                                    'seleccionados' | translate }}</ng-container>
                                            </ng-template>
                                            <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                                            </kendo-multiselect-messages>
                                        </kendo-multiselect>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- Filtro: COMBO FLEXIBLE -->
                            <div *ngIf="row.columna.tipo == 'comboFlexible'">
                                <!-- OPCION -->
                                <div class="flt-opcion">
                                    <kendo-dropdownlist [data]="opcionComboFlexible" [textField]="'nombre'"
                                        [valueField]="'id'" [(value)]="row.operator"></kendo-dropdownlist>
                                </div>
                                <!-- DATO(s) -->
                                <div *ngIf="row.operator.id > 0" class="flt-datos">
                                    <!-- DATO 1 -->
                                    <kendo-dropdownlist *ngIf="!row.operator.dobleValor && row.operator.id <= 4"
                                        class="mr-2 float-left" [data]="row.combo" [textField]="'nombre'"
                                        [valueField]="'id'" [(value)]="row.comboSelected" style="width: 220px;">
                                    </kendo-dropdownlist>
                                    <!-- DATO 1 -->
                                    <kendo-multiselect *ngIf="row.operator.dobleValor" kendoMultiSelectSummaryTag
                                        [data]="row.combo" [(ngModel)]="row.comboSelecteds" [textField]="'nombre'"
                                        [valueField]="'id'" [autoClose]="false" style="width: 220px;"
                                        [kendoDropDownFilter]="{operator: 'contains'}" class="flt-dato2">
                                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                            <span class="k-icon k-i-arrow-s"></span>
                                            <ng-container *ngIf="dataItems.length == 1">{{
                                                this.myFunctions.stringMaxLength(dataItems[0].nombre, 16)}}
                                            </ng-container>
                                            <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                                'seleccionados' | translate }}</ng-container>
                                        </ng-template>
                                        <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                                        </kendo-multiselect-messages>
                                    </kendo-multiselect>
                                    <!-- DATO 1 -->
                                    <div *ngIf="!row.operator.dobleValor && row.operator.id > 4"
                                        class="flt-dato1 float-left mr-2">
                                        <kendo-textbox [(value)]="row.text" [maxlength]="50"></kendo-textbox>
                                    </div>
                                </div>
                            </div>
                            <!-- Filtro: CHECK -->
                            <div *ngIf="row.columna.tipo == 'check'" class="flt-dato1">
                                <!-- SERIA MEJOR CON 2 BOTONES SI / NO ? TEXTO PARA VERDADERO / FALSO? -->
                                <kendo-switch [(ngModel)]="row.check" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                            </div>
                        </div>
                        <!-- ELIMINAR -->
                        <button
                            *ngIf="row.columna.tipo != 'date' && !((row.columna.id == 0 && filtro == datosFiltro.group[0] && datosFiltro.group.length == 1 && !permitirFiltroVacio ) || (row.columna.id == 0 && row != filtro.group[0])) || !annadirAutomatico "
                            Class="flt-eliminar" (click)="borrarLineaFiltro(row, filtro, datPlanos)"> <i
                                class="fas fa-times"></i> </button>
                    </div>
                    <!-- SUB GRUPO -->
                    <div *ngIf="row.group != undefined">
                        <ng-container *ngTemplateOutlet="recursiveListTmpl; context:{ filtros:  [row] }"></ng-container>
                    </div>
                </div>
                <!-- BOTON: AÑADIR LINEA (SOLO EN LA ULTIMA LINEA, o primer subgrupo) -->
                <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro && !annadirAutomatico">
                    <button Class="btnEditatu btn btn-success btn-sm mr-1" (click)="annadirLineaFiltro(filtro)"> <i
                            class="fas fa-plus"></i> {{ 'agregarCondicion' | translate }} </button>
                </div>
                <div class=" mt-1" *ngIf="filtro.group != undefined && filtro != datosFiltro">
                    <hr>
                </div>
            </div>
        </ng-template>
        <div class="clearfix">
            <button class="flt-btnanadirgrupo" (click)="annadirGrupoFiltro()"> <i class="fas fa-plus"></i> {{
                'agregarGrupo' | translate }} </button>
        </div>
        <hr>
        <div class="clearfix">
            <button Class="btn btn-primary btn-sm mt-1 float-right" (click)="onFilter()"> {{ 'filtrar' | translate
                }}</button>
            <button Class="btn-danger btn btn-sm mt-1 float-right mr-1" (click)="borrarFiltro()"> {{ 'filterClearButton'
                | translate }}</button>
        </div>
    </div>
</div>
<!-- CARD CON OPCIONES SELECCIONADAS FILTRO -->
<div class="card">
    <div class="card-body">
        <div class="cont-tags-filtro" id="tagsFiltro">
            <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [datosFiltro] }"></ng-container>
            <ng-template #recursiveListTmpl2 let-filtross="filtros">
                <div *ngFor="let filtro of filtross" class="uno">
                    <div class="flt-separador"
                        *ngIf="filtro.group != undefined && filtro != datosFiltro && filtro!=datosFiltro.group[0] && filtroToCount(filtro) > 0">
                    </div>
                    <!-- GRUPO -->
                    <div *ngFor="let row of filtro.group" class="dos">
                        <!-- LINEA FILTRO -->
                        <div *ngIf="row.group == undefined &&
              ((row.columna.tipo == 'date') || 
               (row.columna.tipo == 'dateTime') || 
              (row.columna.tipo == 'comboEstrincto' && row.comboSelecteds.length > 0 ) || 
              (row.columna.tipo == 'comboFlexible'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
              (row.columna.tipo == 'comboFlexibleOperarioMaquina'  && ((row.operator.id <= 4 && row.comboSelecteds.length > 0 ) ||( row.operator.id > 4 && row.text != ''))) || 
              (row.columna.tipo == 'check') || 
              (row.columna.tipo == 'numeric') || 
              (row.columna.tipo == 'decimal') || 
              (row.columna.tipo == 'string' && row.text != '' ))" class="tags-filtro">
                            <label *ngIf="false"> {{ row.columna.nombre }} </label>
                            <label *ngIf="false"> {{ row.operator.nombre }} </label>
                            <!-- FILTRO -->
                            <div class="tags-filtro-label">
                                <!-- Filtro: FECHA -->
                                <div *ngIf="row.columna.tipo == 'date'">
                                    <div *ngIf="row.operator.id > 0">
                                        <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">{{row.fechaIni
                                            | kendoDate}}</label>
                                        <label class="tags-filtro-label"
                                            *ngIf="row.fechaFin != undefined && row.fechaFin == undefined && row.operator.dobleValor">{{row.fechaIni
                                            | kendoDate}}</label>
                                        <label class="tags-filtro-label"
                                            *ngIf="row.fechaFin != undefined && row.fechaFin != undefined && row.operator.dobleValor">{{row.fechaIni
                                            | kendoDate}} - {{row.fechaFin | kendoDate}}</label>
                                    </div>
                                </div>
                                <!-- Filtro: FECHA y HORA (el caso ENTRE no esta diseñado aun) -->
                                <div *ngIf="row.columna.tipo == 'dateTime'">
                                    <div *ngIf="row.operator.id > 0">
                                        <label class="tags-filtro-label" *ngIf="!row.operator.dobleValor">
                                            {{row.fechaIni | kendoDate}} </label>
                                    </div>
                                </div>
                                <!-- Filtro: NUMEROS ENTEROS -->
                                <div *ngIf="row.columna.tipo == 'numeric'">
                                    <div *ngIf="row.operator.id > 0">
                                        <label class="tags-filtro-label"> {{row.numberMin}} </label>
                                        <label class="tags-filtro-label" *ngIf="row.operator.dobleValor">
                                            {{row.numberMax}} </label>
                                    </div>
                                </div>
                                <!-- Filtro: DECIMALES -->
                                <div *ngIf="row.columna.tipo == 'decimal'">
                                    <div *ngIf="row.operator.id > 0">
                                        <label class="tags-filtro-label"> {{row.decimalMin}} </label>
                                        <label class="tags-filtro-label" *ngIf="row.operator.dobleValor">
                                            {{row.decimalMax}} </label>
                                    </div>
                                </div>
                                <!-- Filtro: TEXTO -->
                                <div *ngIf="row.columna.tipo == 'string'">
                                    <label class="tags-filtro-label"> {{row.text}} </label>
                                </div>
                                <!-- Filtro: COMBO ESTATICO -->
                                <div *ngIf="row.columna.tipo == 'comboEstrincto'">
                                    <div *ngIf="row.operator.id > 0">
                                        <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{
                                            row.comboSelecteds[0].nombre}}</label>
                                        <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{
                                            row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                                    </div>
                                </div>
                                <!-- Filtro: COMBO FLEXIBLE -->
                                <div *ngIf="row.columna.tipo == 'comboFlexible'">
                                    <div *ngIf="row.operator.id > 0">
                                        <div *ngIf="row.operator.id <= 4">
                                            <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{
                                                row.comboSelecteds[0].nombre}}</label>
                                            <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{
                                                row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                                        </div>
                                        <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}}
                                        </label>
                                    </div>
                                </div>
                                <!-- Filtro: COMBO FLEXIBLE OPERARIO / MAQUINA -->
                                <div *ngIf="row.columna.tipo == 'comboFlexibleOperarioMaquina'">
                                    <div *ngIf="row.operator.id > 0">
                                        <div *ngIf="row.operator.id <= 4">
                                            <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length == 1">{{
                                                row.comboSelecteds[0].nombre}}</label>
                                            <label class="tags-filtro-label" *ngIf="row.comboSelecteds.length > 1">{{
                                                row.columna.nombre }} ({{ row.comboSelecteds.length }})</label>
                                        </div>
                                        <label class="tags-filtro-label" *ngIf="row.operator.id > 4"> {{row.text}}
                                        </label>
                                    </div>
                                </div>
                                <!-- Filtro: CHECK -->
                                <div *ngIf="row.columna.tipo == 'check'">
                                    <label class="tags-filtro-label" *ngIf="row.check"> {{ row.columna.nombre }} : {{
                                        'si' | translate }} </label>
                                    <label class="tags-filtro-label" *ngIf="!row.check"> {{ row.columna.nombre }} : {{
                                        'no' | translate }} </label>
                                </div>
                            </div>
                            <!-- ELIMINAR -->
                            <button (click)="borrarLineaFiltro(row, filtro, datPlanos)" class="fas fa-times"
                                style="cursor:pointer"></button>
                        </div>
                        <!-- SUB GRUPO -->
                        <div *ngIf="row.group != undefined">
                            <ng-container *ngTemplateOutlet="recursiveListTmpl2; context:{ filtros: [row] }">
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-template>
            <button type="button" class="btn btn-primary float-right mr-1" (click)="this.status = !this.status;"><i
                    class="fas fa-filter mr-1"></i>{{ 'filtro' | translate }} ( {{ filtroToCount() }} )</button>
            <button Class="btn btn-danger float-right mr-1" (click)="borrarFiltro()"><i
                    class="fas fa-backspace mr-1"></i>{{ 'filterClearButton' | translate }}</button>
            <button *ngIf="actualizarVisible" Class="btn btn-success float-right mr-1" (click)="onFilter()"><i
                    class="fas fa-sync-alt mr-1"></i>{{ 'actualizar' | translate }}</button>
        </div>
    </div>
</div>

<!-- GRID -->
<div class="card">
    <div class="card-body">

        <!-- BOTONES ARRIBA -->
        <button type="button" class="btn btn-primary m-1" (click)="onClick_Alta()">{{'alta' | translate }}</button>
        <button type="button" class="btn btn-verde m-1" (click)="onClick_Baja()">{{ 'baja' | translate }}</button>
        <button type="button" class="btn btn-morado m-1" (click)="onClick_Mover()">{{ 'mover' | translate }}</button>

        <kendo-grid 
            [kendoGridBinding]="consumiblesAlta" 
            [sortable]="true" 
            [navigable]="true"
            [selectable]="{enabled: true, mode: 'multiple'}" 
            filterable="menu" 
            [rowHeight]="36" 
            [height]="850"
            [pageSize]="140" 
            scrollable="virtual" 
            kendoGridSelectBy="id" 
            [selectedKeys]="seleccionados"
            (selectionChange)="selectionChange($event)" 
            class="grid-font-10">

            <!-- CHECKBOX -->
            <kendo-grid-checkbox-column width="5%">
                <ng-template kendoGridHeaderTemplate>
                    <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                        (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,consumiblesAlta, 'id')">
                    <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
            </kendo-grid-checkbox-column>
            <!-- REFERENCIA -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="referencia"
                title="{{ 'referencia' | translate}}"></kendo-grid-column>
            <!-- NOMBRE -->
            <kendo-grid-column width="13%" [style]="{'text-align': 'left'}" field="nombre"
                title="{{ 'nombre' | translate}}"></kendo-grid-column>
            <!-- nSerie -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="nSerie"
                title="{{ 'nserie' | translate}}"></kendo-grid-column>
            <!-- TIPO -->
            <kendo-grid-column width="13%" [style]="{'text-align': 'left'}" field="nombreT"
                title="{{ 'tipoConsumible' | translate}}"></kendo-grid-column>
            <!-- ALMACEN -->
            <kendo-grid-column width="19%" [style]="{'text-align': 'left'}" field="almacen"
                title="{{ 'ubicacion' | translate}}">
            </kendo-grid-column>
            <!-- CANTIDAD -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'right'}" field="cantidad"
                title="{{ 'cantidad' | translate}}"></kendo-grid-column>
            <!-- TIEMPO USO -->
            <kendo-grid-column width="11%" [style]="{'text-align': 'right'}" field="vidaUtil"
                title="{{ 'tiempouso' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <ngcontainer *ngIf="dataItem.tieneNserie"> {{ secondsToHm(dataItem.tUsado) }} / {{
                        secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    <ngcontainer *ngIf="!dataItem.tieneNserie"> {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                </ng-template>
            </kendo-grid-column>
            <!-- ASIGNADOS -->
            <kendo-grid-column width="9%" [style]="{'text-align': 'right'}" field="consumiblesAlta"
                title="{{ 'asignados' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <ngcontainer *ngIf="dataItem.tieneNserie"> {{ dataItem.asignado1 }} / {{ dataItem.asignado2 }}
                    </ngcontainer>
                </ng-template>
            </kendo-grid-column>
            <!-- COSTE -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'right'}" field="coste"
                title="{{ 'coste' | translate}}"></kendo-grid-column>
            <!-- TOTAL -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'right'}" field="total"
                title="{{ 'total' | translate}}"></kendo-grid-column>

                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
    </div>
</div>

<!-- POPUP: ALTA -->
<div class="popup-cont overflow-auto" *ngIf="popupAlta">
    <div class="popup" style="width: 600px;">
        <div class="popup-header">
            <h3>
                <label>{{'alta' | translate }}</label>
            </h3>
        </div>
        <div class="popup-body">
            <form [formGroup]="myForm">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <label class="crontol-label m-1">{{ 'tipoConsumible' | translate }}</label><br>
                            <kendo-combobox [data]="listaTipoConsumibles" [textField]="'nombre'" [valueField]="'id'"
                                [(value)]="tipoConsumible" [kendoDropDownFilter]="{operator: 'contains'}"
                                (valueChange)="valueChangeTipo($event)" formControlName="tipo" required>
                            </kendo-combobox>
                        </div>
                        <div class="col">
                            <label class="crontol-label m-1">{{ 'consumible' | translate }}</label><br>
                            <kendo-combobox [data]="listaConsumibles" [textField]="'nombre'" [valueField]="'id'"
                                [(value)]="consumible" [kendoDropDownFilter]="{operator: 'contains'}"
                                (selectionChange)="valueChange($event)" formControlName="consumible" required>
                            </kendo-combobox>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <label class="crontol-label m-1">{{ 'almacen' | translate }}</label><br>
                            <kendo-combobox [data]="listaAlmacenesAlta" [textField]="'nombre'" [valueField]="'id'"
                                (selectionChange)="valueChangeAlmacenAlta($event)" [(value)]="almacen"
                                [kendoDropDownFilter]="{operator: 'contains'}" formControlName="almacen" required>
                            </kendo-combobox>
                        </div>
                        <div class="col">
                            <label class="crontol-label m-1">{{ 'cantidad' | translate }}</label><br>
                            <kendo-numerictextbox [value]="cantidad" [min]="0" [max]="maxCantidadAlta"
                                [autoCorrect]="true" (valueChange)="cantidadChange($event)">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="popup-header mt-4" *ngIf="tieneNserie">
            <h3>
                <label>{{'numeros de serie' | translate }}</label>
            </h3>
        </div>
        <div class="popup-body" *ngIf="tieneNserie">
            <div>
                <div>
                    <ng-container *ngFor="let nserie of listaNSerie">
                        <div class="clearfix">
                            <label class="float-left col-md-1 mt-2">{{ nserie.id + 1 }}</label>
                            <kendo-textbox [(value)]="nserie.nombre" [maxlength]="50" class="float-right col-md-10">
                            </kendo-textbox>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="popup-footer text-center justify-content-end mt-2">
            <button type="button" class="btn btn-info mr-1" (click)="dar_de_alta()">{{ 'aceptar' | translate }}</button>
            <button type="button" class="btn btn-danger mr-1" (click)="this.popupAlta = false;">{{ 'cancelar' |
                translate }}</button>
            <button type="button" class="btn btn-primary mr-1" (click)="altocompletar()" *ngIf="tieneNserie">{{
                'Autocompletar' | translate }}</button>
            <button type="button" class="btn btn-primary mr-1" (click)="vaciar()" *ngIf="tieneNserie">{{ 'Vaciar' |
                translate }}</button>
        </div>
    </div>
</div>

<!-- POPUP: BAJA -->
<div class="popup-cont overflow-auto" *ngIf="popupBaja">
    <div class="popup" style="width: 700px;">
        <div class="popup-header">
            <h3>
                <label>{{'baja' | translate }}</label>
            </h3>
        </div>
        <div class="popup-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <label class="crontol-label m-1">{{ 'Tipo Consumible' | translate }}</label><br>
                        <kendo-combobox [data]="listaTipoConsumibles" [textField]="'nombre'" [valueField]="'id'"
                            [(value)]="tipoConsumible" [kendoDropDownFilter]="{operator: 'contains'}"
                            (valueChange)="valueChangeTipo($event)">
                        </kendo-combobox>
                    </div>
                    <div class="col">
                        <label class="crontol-label m-1">{{ 'consumible' | translate }}</label><br>
                        <kendo-combobox [data]="listaConsumibles" [textField]="'nombre'" [valueField]="'id'"
                            [(value)]="consumible" [kendoDropDownFilter]="{operator: 'contains'}"
                            (selectionChange)="valueChange($event)">
                        </kendo-combobox>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <label class="crontol-label m-1">{{ 'almacen' | translate }}</label><br>
                        <kendo-combobox [data]="listaAlmacenes" [textField]="'nombre'" [valueField]="'id'"
                            [(value)]="almacen" [kendoDropDownFilter]="{operator: 'contains'}"
                            (valueChange)="valueChangeAlmacen($event)">
                        </kendo-combobox>
                    </div>
                    <div class="col" *ngIf="!tieneNserie && this.user.tipo_baja_consumibles == 1">
                        <label class="crontol-label m-1">{{ 'cantidad' | translate }}</label><br>
                        <kendo-numerictextbox [(value)]="cantidad" [autoCorrect]="true" [min]="1" [max]="maxCantidad"
                            (valueChange)="cantidadChange($event)">
                        </kendo-numerictextbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-body" *ngIf="tieneNserie">
            <kendo-grid [kendoGridBinding]="consumiblesBaja" [height]="300" [pageSize]="30" scrollable="scrollable"
                [sortable]="true" [navigable]="true" filterable="menu" kendoGridSelectBy="id"
                [selectedKeys]="seleccionados" (selectionChange)="selectionChange($event)">

                <!-- CHECKBOX -->
                <kendo-grid-checkbox-column width="7%">
                    <ng-template kendoGridHeaderTemplate>
                        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                            (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,consumiblesBaja, 'id')">
                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                    </ng-template>
                </kendo-grid-checkbox-column>
                <!-- nSerie -->
                <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nSerie"
                    title="{{ 'nserie' | translate}}"></kendo-grid-column>
                <!-- ALMACEN -->
                <kendo-grid-column width="40%" [style]="{'text-align': 'left'}" field="almacen"
                    title="{{ 'ubicacion' | translate}}">
                </kendo-grid-column>
                <!-- VIDA UTIL -->
                <kendo-grid-column width="29%" [style]="{'text-align': 'right'}" field="vidaUtil"
                    title="{{ 'tiempouso' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ngcontainer *ngIf="dataItem.tieneNserie"> {{ secondsToHm(dataItem.tUsado) }} / {{
                            secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                        <ngcontainer *ngIf="!dataItem.tieneNserie"> {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </div>
        <div class="popup-body" *ngIf="this.user.tipo_baja_consumibles == 2 && !tieneNserie">
            <kendo-grid [kendoGridBinding]="consumiblesBajaSeleccion" [sortable]="true" [navigable]="true"
                filterable="menu" [pageSize]="10" [pageable]="true" kendoGridSelectBy="id"
                [selectedKeys]="seleccionadosTipoSeleccion" (selectionChange)="selectionChangeTipoSeleccion($event)">

                <!-- CHECKBOX -->
                <kendo-grid-checkbox-column width="7%">
                    <ng-template kendoGridHeaderTemplate>
                        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                            (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionadosTipoSeleccion,consumiblesBajaSeleccion, 'id')">
                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                    </ng-template>
                </kendo-grid-checkbox-column>
                <!-- nSerie -->
                <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nSerie"
                    title="{{ 'nserie' | translate}}"></kendo-grid-column>
                <!-- ALMACEN -->
                <kendo-grid-column width="40%" [style]="{'text-align': 'left'}" field="almacen"
                    title="{{ 'ubicacion' | translate}}">
                </kendo-grid-column>
                <!-- VIDA UTIL -->
                <kendo-grid-column width="29%" [style]="{'text-align': 'right'}" field="vidaUtil"
                    title="{{ 'tiempouso' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ngcontainer *ngIf="dataItem.tieneNserie"> {{ secondsToHm(dataItem.tUsado) }} / {{
                            secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                        <ngcontainer *ngIf="!dataItem.tieneNserie"> {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </div>
        <div class="popup-footer text-center justify-content-end mt-2">
            <button type="button" class="btn btn-info mr-1" (click)="aceptar_baja(baja, contentloading)">{{ 'aceptar' |
                translate }}</button>
            <button type="button" class="btn btn-danger mr-1" (click)="this.popupBaja = false;this.cantidad=1;">{{
                'cancelar' | translate }}</button>
        </div>
    </div>
</div>

<!-- POPUP: MOVER -->
<div class="popup-cont overflow-auto" *ngIf="popupMover">
    <div class="popup" style="width: 700px;">
        <div class="popup-header">
            <h3>
                <label>{{'mover' | translate }}</label>
            </h3>
        </div>
        <div class="popup-body">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <label class="crontol-label m-1">{{ 'Tipo Consumible' | translate }}</label><br>
                        <kendo-combobox [data]="listaTipoConsumibles" [textField]="'nombre'" [valueField]="'id'"
                            [(value)]="tipoConsumible" [kendoDropDownFilter]="{operator: 'contains'}"
                            (valueChange)="valueChangeTipo($event)">
                        </kendo-combobox>
                    </div>
                    <div class="col">
                        <label class="crontol-label m-1">{{ 'consumible' | translate }}</label><br>
                        <kendo-combobox [data]="listaConsumibles" [textField]="'nombre'" [valueField]="'id'"
                            [(value)]="consumible" [kendoDropDownFilter]="{operator: 'contains'}"
                            (selectionChange)="valueChange($event)">
                        </kendo-combobox>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <label class="crontol-label m-1">{{ 'almacen' | translate }}</label><br>
                        <kendo-combobox [data]="listaAlmacenes" [textField]="'nombre'" [valueField]="'id'"
                            [(value)]="almacen" [kendoDropDownFilter]="{operator: 'contains'}"
                            (valueChange)="valueChangeAlmacen($event)">
                        </kendo-combobox>
                    </div>
                    <div class="col" *ngIf="!tieneNserie">
                        <label class="crontol-label m-1">{{ 'cantidad' | translate }}</label><br>
                        <kendo-numerictextbox [(value)]="cantidad" [min]="1" [max]="maxCantidad" [autoCorrect]="true"
                            (valueChange)="cantidadChange($event)">
                        </kendo-numerictextbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="popup-body" *ngIf="tieneNserie">
            <kendo-grid [kendoGridBinding]="consumiblesMover" [rowHeight]="36" [height]="200" [pageSize]="30"
                scrollable="virtual" [sortable]="true" [navigable]="true" filterable="menu" kendoGridSelectBy="id"
                [selectedKeys]="seleccionados" (selectionChange)="selectionChange($event)">

                <!-- CHECKBOX -->
                <kendo-grid-checkbox-column width="7%">
                    <ng-template kendoGridHeaderTemplate>
                        <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                            (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,consumiblesMover, 'id')">
                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                    </ng-template>
                </kendo-grid-checkbox-column>
                <!-- nSerie -->
                <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="nSerie"
                    title="{{ 'nserie' | translate}}"></kendo-grid-column>
                <!-- ALMACEN -->
                <kendo-grid-column width="40%" [style]="{'text-align': 'left'}" field="almacen"
                    title="{{ 'ubicacion' | translate}}">
                </kendo-grid-column>
                <!-- VIDA UTIL -->
                <kendo-grid-column width="29%" [style]="{'text-align': 'right'}" field="vidaUtil"
                    title="{{ 'tiempouso' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ngcontainer *ngIf="dataItem.tieneNserie"> {{ secondsToHm(dataItem.tUsado) }} / {{
                            secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                        <ngcontainer *ngIf="!dataItem.tieneNserie"> {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <label class="crontol-label m-1">{{ 'Mover a' | translate }}</label><br>
                    <kendo-combobox [data]="listaAlmacenesMover" [textField]="'nombre'" [valueField]="'id'"
                        [(value)]="almacenMover" [kendoDropDownFilter]="{operator: 'contains'}"
                        (valueChange)="valueChangeAlmacenMover($event)">
                    </kendo-combobox>
                </div>
                <div class="col">
                </div>
            </div>
        </div>
        <div class="popup-body" *ngIf="tienePosiciones">
            <kendo-grid [kendoGridBinding]="consumiblesPosiciones" [rowHeight]="36" [height]="200" [pageSize]="30"
                scrollable="virtual" [sortable]="true" [navigable]="true" filterable="menu"
                [selectable]="{ checkboxOnly: false, mode: 'single' }" kendoGridSelectBy="posicion"
                [selectedKeys]="seleccionadosPosicion" (selectionChange)="selectionChangePosicion($event)"
                [rowClass]="rowCallback">

                <!-- CHECKBOX -->
                <kendo-grid-checkbox-column width="7%">
                    <ng-template kendoGridHeaderTemplate>
                        <input class="k-checkbox" style="pointer-events: none" id="selectAllCheckboxId"
                            kendoGridSelectAllCheckbox
                            (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,consumiblesMover, 'id')">
                        <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                    </ng-template>
                </kendo-grid-checkbox-column>
                <!-- POSICIÓN -->
                <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="posicion"
                    title="{{ 'posicion' | translate}}"></kendo-grid-column>
                <!-- HERRAMIENTA -->
                <kendo-grid-column width="25%" [style]="{'text-align': 'left'}" field="herramienta"
                    title="{{ 'herramienta' | translate}}"></kendo-grid-column>
                <!-- TIPO -->
                <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="tipoHerramienta"
                    title="{{ 'tipo' | translate}}"></kendo-grid-column>
                <!-- nSerie -->
                <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="nSerie"
                    title="{{ 'nserie' | translate}}">
                </kendo-grid-column>
                <!-- VIDA UTIL -->
                <kendo-grid-column width="20%" [style]="{'text-align': 'right'}" field="vidaUtil"
                    title="{{ 'tiempouso' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <ngcontainer *ngIf="dataItem.nSerie != '-' && dataItem.vidaUtil != null"> {{
                            secondsToHm(dataItem.tUsado) }} / {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                        <ngcontainer *ngIf="dataItem.nSerie == '-' && dataItem.vidaUtil != null"> {{
                            secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
        </div>
        <div class="popup-footer text-center justify-content-end mt-2">
            <button type="button" class="btn btn-info mr-1" (click)="aceptar_mover(mover, contentloading)">{{ 'aceptar'
                | translate }}</button>
            <button type="button" class="btn btn-danger mr-1" (click)="this.popupMover = false;this.cantidad=1;">{{
                'cancelar' | translate }}</button>
        </div>
    </div>
</div>

<!-- MODAL: Eliminar Hijos -->
<ng-template #baja let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ "eliminarhijos" | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="dar_de_baja(contentloading)">
            {{ "noeliminarhijos" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="bajaHijos(contentloading)">
            {{ "sieliminarhijos" | translate }}
        </button>
    </div>
</ng-template>

<!-- MODAL: Mover Hijos-->
<ng-template #mover let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ "moverhijos" | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="moverConsumible(contentloading)">
            {{ "nomoverhijos" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="moverHijos(contentloading)">
            {{ "simoverhijos" | translate }}
        </button>
    </div>
</ng-template>

<!--MODAL: Carga-->
<ng-template #contentloading let-modal>
    <div class="modal-body">
        <p>{{ "Eliminando seleccionados..." | translate }}</p>
        <div class="spinner-border" role="status">
            <span class="sr-only">{{ "Eliminando seleccionados" | translate }}...</span>
        </div>
    </div>
</ng-template>