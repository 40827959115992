import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, ToleranciasTipoService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-toleranciasTipo',
  templateUrl: './toleranciasTipo.component.html'
})
export class ToleranciasTipoComponent implements OnInit {

  form: FormGroup;
  dataToleranciasTipo: any;
  seleccionados: number[] = [];
  pathFolderSimbolos: string;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private toleranciasTipoService: ToleranciasTipoService,
    private alertService: AlertService,
    private menuService: MenuService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UsuariosService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('toleranciastipo').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/toleranciastipo') {
          this.menuService.titulo = this.translateService.instant('toleranciastipo').toUpperCase();
          this.cargarDatos();
        }
      }
    });

  }

  cargarDatos() {
    this.toleranciasTipoService.GetAll().subscribe((result) => {
      if (!result.error) {
        this.dataToleranciasTipo = result.data;
      }
    });
    

  }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['toleranciastipo/editar/', this.seleccionados[0]]);
    }
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['toleranciastipo/editar/', this.seleccionados[0]]);
    }
  }

  onClickNuevo() {
    this.router.navigate(['toleranciastipo/crear']);
  }

  onClickEliminar(content) {
    if (this.seleccionados[0] > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
   
    this.toleranciasTipoService.delete({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.toleranciasTipoService.GetAll().subscribe((result) => {
            this.dataToleranciasTipo = result.data;
          }
          );
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

}
