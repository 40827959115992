<kendo-grid [kendoGridBinding]="ejes" [selectedKeys]="ejes_seleccionados" [sortable]="true" [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
            [navigable]="true" filterable="menu" kendoGridSelectBy="id" (cellClick)="ejes_cellClick($event)">
  <ng-template kendoGridToolbarTemplate position="top">
    <button type="button" (click)="ejes_onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button type="button" (click)="ejes_onClickNuevo()" class="btn btn-primary btn-sm mr-1" [disabled]="isAddMode">{{ 'nuevo' | translate}}</button>
  </ng-template>
  <!--id-->
  <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
  <!--checkbox-->
  <kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>
  <!--orden-->
  <kendo-grid-column width="5%" field="orden" title="{{ 'orden' | translate}}" [style]="{'text-align': 'right'}" filter="numeric"></kendo-grid-column>
  <!--nombre-->
  <kendo-grid-column width="35%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
  <!--principal-->
  <kendo-grid-column width="7%" field="principal" title="{{ 'principal' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.principal" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--motor-->
  <kendo-grid-column width="7%" field="motor" title="{{ 'motor' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.motor" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--feed-->
  <kendo-grid-column width="7%" field="feed" title="{{ 'feed' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.feed" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--consumo-->
  <kendo-grid-column width="7%" field="consumo" title="{{ 'consumo' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.consumo" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--temperatura-->
  <kendo-grid-column width="7%" field="temperatura" title="{{ 'temperatura' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.temperatura" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--posicion-->
  <kendo-grid-column width="7%" field="posicion" title="{{ 'posicion' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.posicion" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--visible-->
  <kendo-grid-column width="7%" field="visible" title="{{ 'visible' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.visible" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--activo-->
  <kendo-grid-column width="7%" field="activo" title="{{ 'activo' | translate}}" [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.activo" disabled />
    </ng-template>
  </kendo-grid-column>
  <!--TRADUCCIÓN TEXTOS DEL GRID-->
  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
  filterContainsOperator="{{'filterContainsOperator' | translate}}"
  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
</kendo-grid>
