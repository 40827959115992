<div class="grafico-temperatura">

    <div class="barra-cont">
     <div class="barra-inner" style="height: 0%" [ngStyle]="{'height': calcularAltura()}">  
    </div>
    </div>
    
    <div class="numeros-graf-cont">
      <label class="numero-graf" *ngFor="let i of array" [ngStyle]="{'bottom': i[1]}">{{i[0]}}</label>  
    </div>
   
</div>
    
    