<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 4px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :host /deep/ .k-grid tr.rojo {
    background-color: #ff9d9d;
  }

  :host /deep/ .k-grid tr.rojo:hover {
    background-color: #ff8989;
  }

  :host /deep/ .k-grid tr.rojo.k-state-selected td {
    background-color: #ff7676;
  }
</style>

<!-- TODO -->
<div class="historico historicooperaciones">
  <!-- INFO-->
  <div class="clearfix">

    <!-- OF Y CLIENTE-->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{infoOF}}"></kendo-label>
          <div class="cliente-historicoOperaciones"><kendo-label text="{{infoCliente}}"></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- RECETA -->
    <div class="float-left mr-2" *ngIf="tipo_maquina==5">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'receta' | translate}}"></kendo-label>
          <div class="plano-historicoOperaciones"><kendo-label text="{{infoPlano}}"></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- REF PIEZA Y TAREA -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <div><kendo-label text="{{ 'refpieza' | translate}}"><span class="refPieza-historicoOperaciones">
                {{infoRefPieza}}</span></kendo-label></div>
          <div><kendo-label text="{{ 'tarea' | translate}}"><span class="tarea-historicoOperaciones">
                {{operacion}}</span></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- CANTIDAD -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'cantidad' | translate}}"></kendo-label>
          <div class="cantidad-historicoOperaciones"><kendo-label
              text="{{ cantidadAcumulada }} / {{cantidadLote }}"></kendo-label></div>
        </div>
      </div>
    </div>

    <!-- TIEMPO EST EJEC PREP -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">

          <div class="float-left mr-1">
            <kendo-label text="{{ 'tEstimado' | translate}}"></kendo-label>
            <div class="tEstimado-historicoOperaciones"><kendo-label text="{{infoTiempoEstimado}}"></kendo-label></div>
          </div>

          <div class="float-left mr-1">
            <kendo-label text="{{ 'tEjecucion' | translate}}"></kendo-label>
            <div class="tEjecucion-historicoOperaciones"><kendo-label text="{{infoTiempoRealEjecucion}}"></kendo-label>
            </div>
          </div>

          <div class="float-left mr-1">
            <kendo-label text="{{ 'tPreparacion' | translate}}"></kendo-label>
            <div class="tPreparacion-historicoOperaciones"><kendo-label
                text="{{infoTiempoRealPreparacion}}"></kendo-label></div>
          </div>
        </div>
      </div>
    </div>

    <!-- DESVIO -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'desvio' | translate}}"></kendo-label>
          <div class="desvio-historicoOperaciones">
            <div class="float-left mr-2">
              <div class="desvioTiempo-historicoOperaciones"><kendo-label text="{{infoDesvio}}"></kendo-label></div>
            </div>
            <div class="float-left mr-2">
              <div class="desvioPorcen-historicoOperaciones"><kendo-label text="{{infoPorcenDesvio}}"></kendo-label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ESTADO -->
    <div class="float-left mr-2">
      <div class="card">
        <div class="card-body">
          <kendo-label text="{{ 'estado' | translate}}"></kendo-label>
          <div *ngIf="idEstado==1" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'enProceso' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==2" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'acabada' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==3" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'validada' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==4" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'apartada' | translate}}"></kendo-label>
          </div>
          <div *ngIf="idEstado==5" class="estado-historicoOperaciones">
            <kendo-label text="{{ 'chatarra' | translate}}"></kendo-label>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- PESTAÑAS -->
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <!-- GRIDS -->
  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
    (mouseover)="showGridTooltip($event)">

    <div class="example-wrapper">
      <kendo-tabstrip tabPosition="top" [keepTabContent]="true">

        <!-- PROCESOS -->
        <kendo-tabstrip-tab title="{{ 'procesos' | translate}}" [selected]="true">
          <ng-template kendoTabContent>
            <p>

              <!-- GRID SI ES INDUCCION -->
              <kendo-grid *ngIf="tipo_maquina==5" [kendoGridBinding]="dataProcesos" [height]="500" [resizable]="true"
                [navigable]="true" [sortable]="true" kendoGridSelectBy="id" [selectedKeys]="operacionesSelecteds"
                (cellClick)="cellClick($event)" scrollable="virtual" [rowHeight]="28" [pageSize]="50">
                <!-- <kendo-grid-checkbox-column width="4%"></kendo-grid-checkbox-column> -->
                <kendo-grid-column field="number" title="{{ 'ciclo' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="numPiezas" title="{{ 'nPiezas' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="fechaIni" title="{{ 'inicio' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="fechaIniH" title="{{ 'hInicio' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="fechaFinH" title="{{ 'hFin' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="" title="{{ 'piezasOK' | translate }}" width="6%"></kendo-grid-column>
                <!-- si es induccion -->
                <kendo-grid-column field="receta" title="{{ 'receta' | translate }}" width="6%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="float-left">{{dataItem.receta}}</div>
                    <div class="float-left celda-completa icon-engranaje" *ngIf="dataItem.dif == true"></div>
                    <div class="float-left celda-completa icon-editar" *ngIf="dataItem.edited == true"></div>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="" title="{{ 'calidad' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>

              <!-- GRID SI ES INYECCION -->
              <kendo-grid *ngIf="tipo_maquina==4" [kendoGridBinding]="dataProcesosInyeccion" [height]="500"
                [resizable]="true" [navigable]="true" [sortable]="true" kendoGridSelectBy="idCiclo"
                [selectedKeys]="operacionesSelecteds" (cellClick)="cellClick($event)" scrollable="virtual"
                [rowHeight]="28" [pageSize]="50">
                <!-- <kendo-grid-checkbox-column width="4%"></kendo-grid-checkbox-column> -->
                <kendo-grid-column field="nCiclo" title="{{ 'ciclo' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="numPiezas" title="{{ 'nPiezas' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="fechaIni" title="{{ 'inicio' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="fechaIniH" title="{{ 'hInicio' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="fechaFinH" title="{{ 'hFin' | translate }}" width="6%"></kendo-grid-column>
                <!-- si es inyectora -->
                <kendo-grid-column field="tolerancia" title="{{ 'tolerancia' | translate }}" width="6%"
                  class="celda-tooltip-externo-header p-0">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="celda-completa text-center" [ngClass]="{'bg-rojo': dataItem.tolerancia == false,
                      'bg-verde': dataItem.tolerancia == true}">
                      <span class="icon-equis" *ngIf="dataItem.tolerancia == false"></span>
                      <span class="icon-check" *ngIf="dataItem.tolerancia == true"></span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-column field="" title="{{ 'calidad' | translate }}" width="6%"></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </p>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- CALIDAD -->

        <kendo-tabstrip-tab title="{{ 'calidad' | translate}}" *ngIf="JvaloresView" [disabled]="this.user.calidad < 1">
          <ng-template kendoTabContent>
            <p>
              <!-- GRID: CALIDAD -->
              <kendo-grid [kendoGridBinding]="JvaloresView" [resizable]="true" [navigable]="true" [sortable]="true"
                filterable="menu" [height]="500" [width]="1090" [skip]="skip3" (pageChange)="pageChange3($event)"
                class="grid-font-10" [rowClass]="rowBackgroundColor" (excelExport)="onExcelExportOneLine($event)"
                [selectable]="true" kendoGridSelectBy="index" [selectedKeys]="calidadSeleccionadas">
                <ng-template kendoGridToolbarTemplate position="top">
                  <button class="btn mr-1  btn-copiar btn-sm mr-1" kendoGridExcelCommand>{{ 'exportar' |
                    translate}}</button>
                  <button class="btn btn-primary btn-sm mr-1" (click)="validarOperacion($event)"
                    [disabled]="this.user.calidad < 2" style="color: white;">{{ 'validar' | translate }}</button>
                  <button class="btn btn-success m-1 float-left" (click)="btnGuardar()"
                    [disabled]="this.user.historicoProcesos < 2 || this.user.calidad < 2">{{ 'guardar' | translate
                    }}</button>
                  <button class="btn btn-success m-1 float-left" (click)="btnCrearCotas()"
                    [disabled]="this.user.historicoProcesos < 2 || this.user.calidad < 2">{{ 'crearCotas' | translate
                    }}</button>
                </ng-template>
                <kendo-grid-checkbox-column showSelectAll="true" width="12%"
                  [hidden]="this.user.calidad < 2"></kendo-grid-checkbox-column>
                <!-- cota de calidad: rojo o naranja -->
                <kendo-grid-column field="claseValorFueraLimites" title="" width="15%"
                  [style]="{'text-align': 'center'}" class="p-0">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="celda-completa" [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icon-menos-alt',
                                    'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                                    'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icon-equis',
                                    'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icon-check'}">
                      <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites"
                        title="{{ valores | translate }}">
                      </span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="cantidad" title="{{ 'frecuencia' | translate }}" [width]="150">
                </kendo-grid-column>
                <kendo-grid-column field="esPrimera" title="{{ 'esPrimerasPieza' | translate }}" [width]="60"
                  [style]="{'text-align': 'center'}">
                  <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.esPrimera" disabled />
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="auditoria" title="{{ 'auditoria' | translate }}" [width]="60"
                  [style]="{'text-align': 'center'}">
                  <ng-template [style]="{'text-align': 'center'}" kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [checked]="dataItem.auditoria" disabled />
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" [width]="60"
                  class="celda-tooltip-externo">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span class="tooltip-consumibles-contenido">
                      <ng-container *ngFor="let operario of dataItem.operariosAuxi.split(','); let i = index">
                        <span *ngIf="operario!='undefined' && operario!='Dundefined'" class="grid-circulo"
                          [style.background-color]="dataItem.operariosC.split(',')[i]+ '!important'">{{operario}}</span>
                        <span *ngIf="operario=='undefined' || operario=='Dundefined'" class="grid-circulo"><i
                            class="fas fa-user-alt"></i></span>
                      </ng-container>
                      <span class="tooltiptext">
                        <ng-container *ngFor="let operario of dataItem.operariosAuxi2.split(';'); let i = index">
                          <label *ngIf="operario !='Dundefined' && operario !='undefined'"> {{operario}} </label>
                          <label *ngIf="operario =='Dundefined' || operario =='undefined'"> {{'desconocido' |
                            translate}} </label>
                        </ng-container>
                      </span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="criticosOk" title="{{ 'criticosOk' | translate }}" [width]="46"
                  [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="otrosOk" title="{{ 'otrosOk' | translate }}" [width]="45"
                  [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="totalOk" title="{{ 'totalOk' | translate }}" [width]="45"
                  [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="fechaValidacionOrden" title="{{ 'fechaValidacion' | translate }}" [width]="80"
                  class="celda-tooltip-externo"><ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.validadas>0" class="tooltip-consumibles-contenido">
                      <label>
                        {{this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(dataItem.fechaValidacion.replace('T',
                        ' ')))}}
                        {{this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(dataItem.fechaValidacion.replace('T',
                        ' ')))}}
                      </label>
                      <span class="tooltiptext">{{dataItem.fechaValidacion | kendoDate}} {{dataItem.fechaValidacion |
                        kendoDate:'HH:mm:ss'}}</span>
                    </span>
                    <span *ngIf="dataItem.validadas==0" class="tooltip-consumibles-contenido">
                      <label>{{'sinValidar' | translate}}</label>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="observacionResponsable" title="{{ 'observacionResponsable' | translate }}"
                  width="220" class="p-0">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <kendo-textbox *ngIf="this.user.calidad==2 && dataItem.validadas<dataItem.numMedidas"
                      field="dataItem" [(value)]="dataItem.observacionResponsable"></kendo-textbox>
                    <span *ngIf="this.user.calidad<2 || dataItem.validadas>=dataItem.numMedidas"
                      class="tooltip-consumibles-contenido">
                      <label>{{dataItem.observacionResponsable}}</label>
                      <span class="tooltiptext">{{dataItem.observacionResponsable}}</span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" noRecords="{{'norecords' | translate}}">
                </kendo-grid-messages>
                <!--Set list of intern data, field should be the same as the attribute we want-->
                <ng-container *ngFor="let resumen of JvaloresView"></ng-container>
                <!--GRID INTERNO-->
                <div *kendoGridDetailTemplate="let interna">
                  <kendo-grid [kendoGridBinding]="interna.interna" [resizable]="true" [navigable]="true"
                    [sortable]="true" filterable="menu" [skip]="skip3" (pageChange)="pageChange3($event)"
                    class="grid-font-10" [rowClass]="rowCallback">
                    <!-- cota de calidad: rojo o naranja -->
                    <kendo-grid-column field="claseValorFueraLimites" title="" width="4%"
                      [style]="{'text-align': 'center'}" class="p-0">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <!-- <span class="celda-completa" 
                       [ngClass]="{'fas fa-times icono-estado-valorFueraLimites-fuera-critico': dataItem.cotaPieza == 'noOK critico'
                                 , 'fas fa-times icono-estado-valorFueraLimites-fuera': dataItem.cotaPieza == 'noOK'
                                 , 'fas fa-ban icono-estado-valorFueraLimites-null':  dataItem.cotaPieza == 'null'
                                 , 'fas fa-check icono-estado-valorFueraLimites-dentro': dataItem.cotaPieza == 'ok'}">
                        <span class="icono-valores"></span>
                      </span> -->
                        <span class="celda-completa" [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icon-menos-alt',
                                      'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                                      'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icon-equis',
                                      'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icon-check'}">
                          <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites"
                            title="{{ valores | translate }}">
                          </span>
                        </span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="critico" title="{{ 'critico' | translate }}" width="8%"
                      [style]="{'text-align': 'center'}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.critico"><i class="fas fa-exclamation"></i></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="valor" title="{{ 'valor' | translate }}"
                      [headerStyle]="{'text-align': 'center'}" width="12%">
                    </kendo-grid-column>
                    <kendo-grid-column field="icono64" title="{{ 'tipo' | translate }}" width="10%" class="p-0">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.icono64!=''"><img [src]="dataItem.icono64"
                            style="max-height:30px;" /></span>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="valorNominal" title="{{ 'valornominal' | translate }}" width="8%"
                      [style]="{'text-align': 'right'}"></kendo-grid-column>
                    <kendo-grid-column field="toleranciaMin" title="{{ 'minTolerancia' | translate }}" width="8%"
                      [style]="{'text-align': 'right'}"></kendo-grid-column>
                    <kendo-grid-column field="toleranciaMax" title="{{ 'maxTolerancia' | translate }}" width="8%"
                      [style]="{'text-align': 'right'}"></kendo-grid-column>
                    <kendo-grid-column field="utillajeNombre" title="{{ 'utillaje' | translate }}" width="8%"
                      [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}"></kendo-grid-column>
                    <kendo-grid-column field="nserieutillaje" title="{{ 'nSerieUtillaje' | translate }}" width="8%"
                      [style]="{'text-align': 'right'}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-combobox *ngIf="dataItem.tieneNSerieUtillaje" [data]="dataItem.numSerieUtillaje"
                          textField="nSerie" valueField="id" class="form-control" [(value)]="dataItem.selectedItem"
                          [disabled]=" this.user.calidad < 2 || dataItem.operacionValidada"
                          [kendoDropDownFilter]="{operator: 'contains'}">
                        </kendo-combobox>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="medicion" title="{{ 'medicion' | translate }}" width="10%"
                      [style]="{'text-align': 'center'}" class="p-0">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-numerictextbox *ngIf="dataItem.tipoValorNumerico==true" class="form-control"
                          [(value)]="dataItem.medicion" [autoCorrect]="true" [step]="0.1"
                          (valueChange)="recalcularCotasOk(dataItem);"
                          [disabled]="this.user.calidad < 2 || dataItem.operacionValidada">
                        </kendo-numerictextbox>
                        <kendo-textbox *ngIf="dataItem.tipoValorTexto" [(value)]="dataItem.medicion"></kendo-textbox>
                        <div *ngIf="dataItem.tipoValorBooleano==true" class="caja">
                          <div class="btn mr-1" [class.btn-success]="dataItem.medicion==1"
                            [class.btn-outline-success]="dataItem.medicion!=1">
                            <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio"
                              (click)="btnSi(dataItem)" [checked]="dataItem.medicion==1" kendoRadioButton
                              [disabled]="this.user.calidad < 2 || dataItem.operacionValidada"
                              (click)="recalcularCotasOk(dataItem);">
                            <label class="k-radio-label" for="btnEstimado">{{ 'OK' | translate }}</label>
                          </div>
                          <div class="btn mr-1" [class.btn-success]="dataItem.medicion==0"
                            [class.btn-outline-success]="dataItem.medicion!=0">
                            <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio"
                              (click)="btnNo(dataItem)" [checked]="dataItem.medicion==0" kendoRadioButton
                              [disabled]="this.user.calidad < 2 || dataItem.operacionValidada "
                              (click)="recalcularCotasOk(dataItem);">
                            <label class="k-radio-label" for="btnPredictivo">{{ 'NOOK' | translate }}</label>
                          </div>
                        </div>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="fotoPath" width="6%" title="{{ 'foto' | translate }}"
                      class="celda-tooltip-externo-header p-0" [style]="{'text-align': 'right'}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <button type="button" class="btn-success btn" *ngIf="dataItem.dejaImagen"
                          (click)="clickFoto(dataItem)"
                          [disabled]="this.user.calidad < 2 || dataItem.operacionValidada"><i
                            class="fas fa-camera"></i></button>
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="observacion" title="{{ 'observacion' | translate }}" width="12%"
                      class="p-0">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-textbox *ngIf="this.user.calidad==2 && !dataItem.operacionValidada  " field="dataItem"
                          [(value)]="dataItem.observacion"></kendo-textbox>
                        <span *ngIf="this.user.calidad<2 || dataItem.operacionValidada "
                          class="tooltip-consumibles-contenido">
                          <label>{{dataItem.observacion}}</label>
                          <span class="tooltiptext">{{dataItem.observacion}}
                          </span>
                        </span>
                      </ng-template>
                    </kendo-grid-column>
                        <kendo-grid-messages filter="{{'filter' | translate}}"
                      filterAfterOperator="{{'filterAfterOperator' | translate}}"
                      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                      filterAndLogic="{{'filterAndLogic' | translate}}"
                      filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                      filterBooleanAll="{{'filterBooleanAll' | translate}}"
                      filterClearButton="{{'filterClearButton' | translate}}"
                      filterContainsOperator="{{'filterContainsOperator' | translate}}"
                      filterDateToday="{{'filterDateToday' | translate}}"
                      filterDateToggle="{{'filterDateToggle' | translate}}"
                      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                      filterEqOperator="{{'filterEqOperator' | translate}}"
                      filterFilterButton="{{'filterFilterButton' | translate}}"
                      filterGtOperator="{{'filterGtOperator' | translate}}"
                      filterGteOperator="{{'filterGteOperator' | translate}}"
                      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                      filterIsFalse="{{'filterIsFalse' | translate}}"
                      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                      filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                      filterIsTrue="{{'filterIsTrue' | translate}}"
                      filterLtOperator="{{'filterLtOperator' | translate}}"
                      filterLteOperator="{{'filterLteOperator' | translate}}"
                      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                      filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                      filterOrLogic="{{'filterOrLogic' | translate}}"
                      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                      loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                      lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>

                  </kendo-grid>
                </div>
                <!--fin GRID INTERNO-->

                <kendo-grid-excel fileName="CALIDAD_{{todayDate | date:'dd_MM_YYYY'}}.xlsx" [fetchData]="allData">
                </kendo-grid-excel>
                    <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                                  
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                                   filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                                  
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                                   filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                                                                  
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                                   filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"                                                 
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                                   filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                                                                   filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                                                                  
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"                                                 
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                                  
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                                  
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}"                                                 
                  filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                                                                  
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                                  
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                                  
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                                  
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"                                                 
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}"                                                 
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                  unlock="{{'unlock' | translate}}"                                                 
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </p>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- INCIDENCIAS -->
        <kendo-tabstrip-tab title="{{ 'incidencias' | translate}}">
          <ng-template kendoTabContent>
            <p>
              <kendo-grid [kendoGridBinding]="JincidenciasView" [resizable]="true" [navigable]="true" [sortable]="true"
                scrollable="virtual" [rowHeight]="36" [height]="500" [pageable]="true" [pageSize]="pageSize"
                [skip]="skip2" (pageChange)="pageChange2($event)" class="grid-font-10">
                <kendo-grid-column field="operario" title="{{ 'operario' | translate }}"
                  width="15%"></kendo-grid-column>
                <kendo-grid-column field="idIncidencia" title="{{ 'incidencia' | translate }}" width="15%">
                </kendo-grid-column>
                <kendo-grid-column field="textoIncidencia" title="{{ 'observacion' | translate }}" width="40%">
                </kendo-grid-column>
                <kendo-grid-column field="fechaIni" width="10%" title="{{ 'fechainicio' | translate }}">
                  <ng-template kendoGridCellTemplate let-dataItem> </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechaFin" width="10%" title="{{ 'fechafin' | translate }}">
                  <ng-template kendoGridCellTemplate let-dataItem> </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="duracion" title="{{ 'duracion' | translate }}" width="10%"
                  [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </p>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- OPERARIOS -->
        <kendo-tabstrip-tab title="{{ 'operarios' | translate}}">
          <ng-template kendoTabContent>
            <p>
              <!-- GRID: OPERARIOS -->
              <kendo-grid [kendoGridBinding]="JoperariosView" [resizable]="true" [navigable]="true" [sortable]="true"
                scrollable="virtual" [rowHeight]="36" [height]="500" [pageable]="true" [pageSize]="pageSize"
                [skip]="skip" (pageChange)="pageChange($event)" class="grid-font-10">
                <kendo-grid-column field="" title="{{ 'operario' | translate }}" width="70%"></kendo-grid-column>
                <kendo-grid-column field="fechaini" width="10%" title="{{ 'fechainicio' | translate }}"
                  [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini
                    |
                    kendoDate:'HH:mm:ss' }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="fechafin" width="10%" title="{{ 'fechafin' | translate }}"
                  [style]="{'text-align': 'right'}">
                  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin
                    |
                    kendoDate:'HH:mm:ss' }}</ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="duracion" title="{{ 'duracion' | translate }}" width="10%"
                  [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}"
                  filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}"
                  filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}"
                  filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}"
                  filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}"
                  filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}"
                  filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                  filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                  filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                  filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                  filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                  loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                  lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </p>
          </ng-template>
        </kendo-tabstrip-tab>

        <!-- IMAGENES -->
        <kendo-tabstrip-tab title="{{ 'imagenes' | translate}}">
          <ng-template kendoTabContent>
            <div id="imagenes" class="imagenes-histoperacion">
            </div>
            <p>
            </p>
          </ng-template>
        </kendo-tabstrip-tab>

      </kendo-tabstrip>
    </div>
  </div>

  <div *ngIf="loadingPanel || loadingInyeccion || loadingInduccion" class="k-i-loading" style="z-index: 3;"></div>
</div>




<!-- POPUP -->
<ng-template #popup let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{tituloModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <label>{{ 'observacion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptar()"
      [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP -->
<ng-template #popupRutas let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <kendo-grid [kendoGridBinding]="rutasOperaciones">
          <kendo-grid-column field="ruta" title="{{ 'ruta' | translate }}" width="100%"></kendo-grid-column>
          <div *kendoGridDetailTemplate="let dataItem">
            <kendo-grid [data]="dataItem.dtOperaciones" kendoGridSelectBy="id" [selectedKeys]="rutasOperacionesSelected"
              [selectable]="{ mode:'single'}">
              <kendo-grid-checkbox-column showSelectAll="true" width="7%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="lado" title="{{ 'lado' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="suborden" title="{{ 'suborden' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="78%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}"
                filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}"
                filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}"
                filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}"
                filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}"
                filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}"
                filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                filterIsTrue="{{'filterIsTrue' | translate}}" filterLtOperator="{{'filterLtOperator' | translate}}"
                filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}"
                pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
          <kendo-grid-messages filter="{{'filter' | translate}}"
            filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}"
            filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}"
            filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}"
            filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}"
            filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
            filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}"
            filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
            filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
            unlock="{{'unlock' | translate}}" noRecords="{{'norecords' | translate}}" pagerItems=""
            pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptarRuta()"
      [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>
<!--POPUP MARCAR COMO ESTANDAR (GAMESA)-->
<ng-template #contentMarcarComoEstandar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntamarcarcomoestandarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')"> {{ 'no' | translate
      }} </button>
    <button type="button" class="btn btn-danger" (click)="cambiarTiemposEstimados()"
      [disabled]="this.user.historicoOperaciones < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
<!--NO HAY OPERACIONES PARA MARCAR COMO ESTANDAR (GAMESA)-->
<ng-template #contentNoHayOperacionesMarcarComoEstandar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'nohayoperacionesparamarcarcomoestandar' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss('cancel click')"> {{ 'aceptar' | translate }}
    </button>
  </div>
</ng-template>
<ng-template #popupAnalitica>
  <div class="content">
    <div class="dropdown-menu dropdown-usuario show">
      <div class="cont-linea">
        <div class="inner-usuario-logout"><a class="btn btn-success w-100" (click)="clickAnalitica(-1)">{{
            'analiticaAvanzadaEjecuciones' | translate }}</a></div>
      </div>
      <div class="cont-linea">
        <div class="inner-usuario-logout"><a class="btn btn-success w-100" (click)="clickTemplado()">{{
            'informeTemplado' | translate }}</a></div>
      </div>
    </div>
  </div>
</ng-template>