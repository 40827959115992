<form [formGroup]="form">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card">
          <div class="card-body">
            <!--Nombre-->
            <div class="form-group">
              <label class="control-label">{{ 'nombre' | translate }}</label>
              <div class="caja">
                <kendo-textbox formControlName="nombre" maxlength="150"
                  [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                  <div *ngIf="f.nombre.errors.required">{{ 'nombrerequerido' |
                    translate}}</div>
                </div>
              </div>
            </div>
  
            <!--Activar link-->
            <div class="form-group form-check">
              <kendo-label class="form-check-label" text="{{ 'activarLink' | translate}}">
                <div class="caja">
                  <kendo-switch [(ngModel)]="form.value.isLink" formControlName="isLink" [onLabel]="' '" [offLabel]="' '" (valueChange)="isLinkChanges()">
                  </kendo-switch>
                </div>
              </kendo-label>
            </div>
  
            <!--Fichero-->
            <div class="form-group" [style.display]="form.value.isLink ? 'none' : 'block'">
              <kendo-label text="{{ 'fichero' | translate}}">
                <kendo-textbox formControlName="fichero" [disabled]="true"
                  [ngClass]="{ 'is-invalid': submitted && errorFichero }"></kendo-textbox>
                <div *ngIf="submitted && errorFichero" class="invalid-feedback">
                  <div *ngIf="errorFichero">{{ 'ficheroRequerido' |
                    translate}}</div>
                </div>
                <div class="caja">
                  <kendo-upload #upload class="form-control upload-sinBotones" [restrictions]="restrictions" [saveUrl]="uploadSaveUrl" [autoUpload]="false"
                    [removeUrl]="uploadRemoveUrl" [multiple]="false" (ngModel)="myFiles" (select)="onSelect($event)" (error)="errorEventHandler($event)">
                    <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                      clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                      dropFilesHere="{{ 'dropFilesHere' | translate }}"
                      externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                      fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                      fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                      filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                      filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                      filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                      headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                      headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                      headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                      invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                      invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                      invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                      remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                      retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                      uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-upload>
                </div>
              </kendo-label>
            </div>
  
            <!--Link-->
            <div class="form-group" [style.display]="form.value.isLink ? 'block' : 'none'">
              <label class="control-label">{{ 'link' | translate }}</label>
              <div class="caja">
                <input kendoTextBox formControlName="link" Class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && errorLink }" />
                <div *ngIf="submitted && errorLink" class="invalid-feedback">
                  <div *ngIf="errorLink">{{ 'linkRequerido' |
                    translate}}</div>
                </div>
              </div>
            </div>
            <div class="mb-5"></div>
          </div>
        </div>
        <!--BOTONES-->
        <div class="form-group d-flex justify-content-center">
          <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="guardarDocumento(upload)" [disabled]="this.user.activos<2">{{ 'guardar' |
            translate}}</button>
          <button type="button" class="btn btn-danger btn-sm mr-1" (click)="atras()">{{ 'cancelar' |
            translate}}</button>
        </div>
      </div>
    </div>
  </form>
