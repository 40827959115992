import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UsuariosService, AlertService, MenuService, UsuariosTiposService, MaquinasService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { TranslateService } from '@ngx-translate/core';
import { TipoUsuario, Usuario } from '@app/_models';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
@Component({
  selector: 'app-editar-crear-usuario',
  templateUrl: './editar-crear-usuario.component.html'
})
export class EditarCrearUsuarioComponent implements OnInit {
  public tiposUsuarios_DAT: TipoUsuario[];
  public selectedItem: TipoUsuario;
  private usuarioTipo: Usuario;
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  checkscargados: boolean;
  loading = false;
  submitted = false;
  user = this.usuariosService.userValue;
  dataMaquinasPermisos: any;
  maquina: any;
  checkboxGrid: any;
  checkbox: any;
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public errorSeccion = false;
  public errorUsuario = false;

  public allSelectedLectura: boolean = false;
  public allSelectedEscritura: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService,
    private usuariosTiposService: UsuariosTiposService,
    private alertService: AlertService,
    private menuService: MenuService,
    private maquinasService: MaquinasService
  ) { }

  ngOnInit() {
    this.menuService.titulo = this.translateService.instant('usuario');
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.checkscargados = true;

    //SECCIONES
    this.usuariosService.getComboSecciones().subscribe(json => {
      this.secciones = json;
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
      this.cargarFormulario();
    });

    //Para evitar errores
    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombreUsuario: new FormControl('', [Validators.required]),
      nombre: new FormControl('', [Validators.required]),
      apellido1: new FormControl('',),
      apellido2: new FormControl(''),
      email: new FormControl(''),
      usuarios_TiposId: 1,
      UsuarioTipo: new FormControl(''),
      password: new FormControl('', [Validators.minLength(4), this.isAddMode ? Validators.required : Validators.nullValidator]),
      confirmPassword: new FormControl(''),
      idioma: this.user.idioma,
      tema: this.user.tema,
      menuExpandido: this.user.menuExpandido,
      activo: new FormControl(true),
      numOperario: new FormControl(1),
      idERP: new FormControl(''),
      coste: new FormControl(0.00),
      idMaquinas: '',
      permisoMaquinas: '',
      gestionUsuarios: 1,
      condicionesAceptadas: true,
      seccionesSeleccionadas: [],
      id: this.route.snapshot.params['id'],
      
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

  }


  cargarFormulario() {

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombreUsuario: new FormControl('', [Validators.required, this.nombreUsuarioFormatoValido()]),
      nombre: new FormControl('', [Validators.required]),
      apellido1: new FormControl('',),
      apellido2: new FormControl(''),
      email: new FormControl(''),
      usuarios_TiposId: 1,
      UsuarioTipo: new FormControl(''),
      password: new FormControl('', [Validators.minLength(4), this.isAddMode ? Validators.required : Validators.nullValidator]),
      confirmPassword: new FormControl(''),
      idioma: this.user.idioma,
      tema: this.user.tema,
      menuExpandido: this.user.menuExpandido,
      activo: new FormControl(true),
      numOperario: new FormControl(1),
      idERP: new FormControl(''),
      coste: new FormControl(0.00),
      idMaquinas: '',
      permisoMaquinas: '',
      gestionUsuarios: 1,
      condicionesAceptadas: true,
      seccionesSeleccionadas: [],
      id: this.route.snapshot.params['id'],
      
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    //Si solo hay una sección ponerla automáticamente
    if(this.groupedSeccion.length == 1 && this.groupedSeccion[0].items.length == 1)
      this.form.controls['seccionesSeleccionadas'].setValue([this.groupedSeccion[0].items[0]]);

    if (!this.isAddMode) {

      //Conseguir usuario
      this.usuariosTiposService.getAll().pipe(first()).subscribe((result2: any) => {
        this.tiposUsuarios_DAT = result2;
        this.usuariosService.getById(this.id).pipe().subscribe((result: any) => {
          this.usuarioTipo = result;
          var auxi = this.tiposUsuarios_DAT.filter(x=>x.id==result.usuarios_TiposId);
          var auxiSoloLectura = this.tiposUsuarios_DAT.filter(x=>x.nombre=='SoloLectura');
          var tipoUsuarioSeleccionado: TipoUsuario;
          if(auxi.length>0){
            tipoUsuarioSeleccionado = auxi[0];
          }else{
            tipoUsuarioSeleccionado = auxiSoloLectura[0];
          }
          
          this.selectedItem = new TipoUsuario(
            tipoUsuarioSeleccionado.id,
            tipoUsuarioSeleccionado.nombre,
            tipoUsuarioSeleccionado.gestionUsuarios,
            tipoUsuarioSeleccionado.home,
            tipoUsuarioSeleccionado.proyectos,
            tipoUsuarioSeleccionado.proyectosH,
            tipoUsuarioSeleccionado.operacionesPredefinidas,
            tipoUsuarioSeleccionado.piezasPredefinidas,
            tipoUsuarioSeleccionado.seguimiento,
            tipoUsuarioSeleccionado.controlDeAsignaciones,
            tipoUsuarioSeleccionado.historicoPiezas,
            tipoUsuarioSeleccionado.planificador,
            tipoUsuarioSeleccionado.vistoLargoMaquina,
            tipoUsuarioSeleccionado.vistoLargoTodos,
            tipoUsuarioSeleccionado.planificadorCorto,
            tipoUsuarioSeleccionado.informes,
            tipoUsuarioSeleccionado.analisisDeRendimiento,
            tipoUsuarioSeleccionado.comparativaRendimiento,
            tipoUsuarioSeleccionado.informeEjecuciones,
            tipoUsuarioSeleccionado.informeTecnicoEjecucion,
            tipoUsuarioSeleccionado.informeDeConsumo,
            tipoUsuarioSeleccionado.oEE,
            tipoUsuarioSeleccionado.informeOEE,
            tipoUsuarioSeleccionado.informeDisponibilidad,
            tipoUsuarioSeleccionado.informeRendimiento,
            tipoUsuarioSeleccionado.informeCalidad,
            tipoUsuarioSeleccionado.planesDeAccion,
            tipoUsuarioSeleccionado.planesDeAccionH,
            tipoUsuarioSeleccionado.maquinas,
            tipoUsuarioSeleccionado.perdidas,
            tipoUsuarioSeleccionado.disponibilidad,
            tipoUsuarioSeleccionado.informesIncidencias,
            tipoUsuarioSeleccionado.calendarioDisponibilidad,
            tipoUsuarioSeleccionado.informeCalculoDisponibilidad,
            tipoUsuarioSeleccionado.ficheros,
            tipoUsuarioSeleccionado.administracion,
            tipoUsuarioSeleccionado.configuracion,
            tipoUsuarioSeleccionado.validador,
            tipoUsuarioSeleccionado.activos,
            tipoUsuarioSeleccionado.activosMaestro,
            tipoUsuarioSeleccionado.alarmasTipo,
            tipoUsuarioSeleccionado.almacenes,
            tipoUsuarioSeleccionado.analiticaAvanzadaEjecuciones,
            tipoUsuarioSeleccionado.areaProductiva,
            tipoUsuarioSeleccionado.asignacionTiempos,
            tipoUsuarioSeleccionado.bonos,
            tipoUsuarioSeleccionado.clientes,
            tipoUsuarioSeleccionado.comparativaTiempos,
            tipoUsuarioSeleccionado.consumibles,
            tipoUsuarioSeleccionado.controles,
            tipoUsuarioSeleccionado.diccionarioPerdidas,
            tipoUsuarioSeleccionado.turnos,
            tipoUsuarioSeleccionado.fabricantes,
            tipoUsuarioSeleccionado.herramientas,
            tipoUsuarioSeleccionado.placas,
            tipoUsuarioSeleccionado.historicoMantenimientos,
            tipoUsuarioSeleccionado.historicoOperaciones,
            tipoUsuarioSeleccionado.historicoProcesos,
            tipoUsuarioSeleccionado.historicoParametros,
            tipoUsuarioSeleccionado.instalaciones,
            tipoUsuarioSeleccionado.mantenimientosPredefinidos,
            tipoUsuarioSeleccionado.marcas,
            tipoUsuarioSeleccionado.materiales,
            tipoUsuarioSeleccionado.operaciones,
            tipoUsuarioSeleccionado.operacionesTipo,
            tipoUsuarioSeleccionado.piezas,
            tipoUsuarioSeleccionado.piezasTipo,
            tipoUsuarioSeleccionado.planificadorHerramientas,
            tipoUsuarioSeleccionado.planificadorLargo,
            tipoUsuarioSeleccionado.planificadorLista,
            tipoUsuarioSeleccionado.plantasIsometrico,
            tipoUsuarioSeleccionado.profiles,
            tipoUsuarioSeleccionado.proveedores,
            tipoUsuarioSeleccionado.OperacionesFinalizadas,
            tipoUsuarioSeleccionado.rutas,
            tipoUsuarioSeleccionado.rutasPredefinidas,
            tipoUsuarioSeleccionado.secciones,
            tipoUsuarioSeleccionado.stock,
            tipoUsuarioSeleccionado.subcontratado,
            tipoUsuarioSeleccionado.activosTipos,
            tipoUsuarioSeleccionado.tolerancias,
            tipoUsuarioSeleccionado.recetas,
            tipoUsuarioSeleccionado.usuarios,
            tipoUsuarioSeleccionado.calidad)

            

          this.form.patchValue(this.usuarioTipo);

          this.maquinasService.getMaquinasPermisos().subscribe(json => {
            this.dataMaquinasPermisos = json;
            this.cargarPermisos();
          });

          //Conseguir secciones de usuario
          this.usuariosService.getSeccionesByIdUsuario(this.id).pipe().subscribe((result: any) => {
            var idSeccionesUsuario = result.map(x => x.id);
            var seccionesUsuario = this.secciones.filter(x => idSeccionesUsuario.includes(x.id));
            if (seccionesUsuario != undefined)
              this.form.controls['seccionesSeleccionadas'].setValue(seccionesUsuario);
          });

        });
      });

    } else {
      this.usuariosTiposService.getAll().pipe(first()).subscribe((result: any) => {
        this.tiposUsuarios_DAT = result;
        this.maquinasService.getMaquinasPermisos().subscribe(json => {
          this.dataMaquinasPermisos = json;
        })
      });
    }

  }

  cargarPermisos() {
    var idMaquinas = this.form.get('idMaquinas').value;
    var permisoMaquinas = this.form.get('permisoMaquinas').value;
    var numMaquinas = idMaquinas.toString().split(",").length;
    for (let j = 0; j < this.dataMaquinasPermisos.length; j++) {
      for (let i = 0; i < numMaquinas; i++) {
        if (idMaquinas.toString().split(",")[i] == this.dataMaquinasPermisos[j].id.toString()) {
          if (permisoMaquinas.toString().split(",")[i] == "0") {
            this.dataMaquinasPermisos[j].lectura = false;
            this.dataMaquinasPermisos[j].escritura = false;
          } else if (permisoMaquinas.split(",")[i] == "1") {
            this.dataMaquinasPermisos[j].lectura = true;
            this.dataMaquinasPermisos[j].escritura = false;
          } else {
            this.dataMaquinasPermisos[j].lectura = true;
            this.dataMaquinasPermisos[j].escritura = true;
          }
        }
      }
    }
    var lecturaCount: number = 0;
    var escrituraCount: number = 0;
    this.dataMaquinasPermisos.forEach(element => {
      if(element.lectura == true){
        lecturaCount += 1;
      }
      if(element.escritura == true){
        escrituraCount += 1;
      }
    });
    if(lecturaCount == (this.dataMaquinasPermisos.length)){
      this.allSelectedLectura = true;
    }
    if(escrituraCount == (this.dataMaquinasPermisos.length)){
      this.allSelectedEscritura = true;
    }
    this.checkscargados = false;
   
  }
  guardarPermisos() {
    this.dataMaquinasPermisos.forEach((m) => {
      if (m.escritura) {
        m.permiso = '2';
      } else if (m.lectura) {
        m.permiso = '1';
      } else {
        m.permiso = '0';
      }
    });
 
    var id = 0;
    this.dataMaquinasPermisos.forEach((m) => {
      
      if (id == 0) {
        this.form.get('idMaquinas').setValue(m.id);
        this.form.get('permisoMaquinas').setValue(m.permiso);
        id = 1;
      } else {
        this.form.get('idMaquinas').setValue(this.form.get('idMaquinas').value + ',' + m.id);
        this.form.get('permisoMaquinas').setValue(this.form.get('permisoMaquinas').value + ',' + m.permiso);
      }
    });
  }

  public valueChange(value: any): void {
    this.selectedItem = value
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    this.guardarPermisos();
    // reset alerts on submit
    this.alertService.clear();

    if(this.form.value.seccionesSeleccionadas == null){
      this.errorSeccion = true;
    }else{
      this.errorSeccion = false;
    }

    if(this.selectedItem == undefined || this.selectedItem == null)
      this.errorUsuario= true;
    else
      this.errorUsuario= false;
    
    // stop here if form is invalid
    if (this.form.invalid || this.errorSeccion || this.errorUsuario) {
      return;
    }
    //Para el tipo de usuario
    //Hay que darle al usuarios los permisos correspondientes
    var auxDict = this.form.value;
    Object.keys(this.selectedItem).forEach(key=>{
      if(key!="nombre" && key!="id")
        auxDict[key] = this.selectedItem[key];
    });
    auxDict['usuarios_TiposId'] = this.selectedItem.id;
    auxDict['UsuarioTipo'] = this.selectedItem.nombre;

    this.loading = true;
    if (this.isAddMode) {
      this.createUser(auxDict);
    } else {
      this.updateUser(auxDict);
    }
  }

  nombreUsuarioFormatoValido(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const nombreUsuario: string = control.value;
      // Aquí puedes definir tu lógica de validación para el formato del nombre de usuario
      const formatoCorrecto = /^[a-zA-Z0-9ñÑ._-]+$/.test(nombreUsuario); // Ejemplo de formato: solo letras, números y guiones
  
      return formatoCorrecto ? null : { 'formatoInvalido': { value: control.value } };
    };
  }

  private createUser(auxDict) {
    this.usuariosService.create(auxDict).pipe(first()).subscribe({
      next: (result: any) => {
        //Guardar secciones
        var idUsuario = result.id;
        var seccionesSeleccionadas: any = this.form.controls['seccionesSeleccionadas'].value;
        var idSeccionesUsuarioStr = seccionesSeleccionadas.map(x => x.id).toString();
        this.usuariosService.updateSeccionesUsuario(idUsuario, idSeccionesUsuarioStr).pipe(first()).subscribe({
          next: () => {
            this.usuariosService.assignColour(idUsuario).pipe(first()).subscribe({
              next: (result2: any) => {
              this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
              this.router.navigate(['../'], { relativeTo: this.route });
              }
            });
           
          }
        });
      },
      error: error => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  private updateUser(auxDict) {
    this.usuariosService.update(auxDict).pipe(first()).subscribe({
      next: () => {
        //Guardar secciones
        var seccionesSeleccionadas: any = this.form.controls['seccionesSeleccionadas'].value;
        var idSeccionesUsuarioStr = seccionesSeleccionadas.map(x => x.id).toString();
        this.usuariosService.updateSeccionesUsuario(this.id, idSeccionesUsuarioStr).pipe(first()).subscribe({
          next: () => {
            this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
            this.router.navigate(['../../'], { relativeTo: this.route });
          }
        });
      },
      error: error => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }

  public checkValueEscritura(dataItem) {
    dataItem.escritura = !dataItem.escritura;
    if (dataItem.escritura) {
      dataItem.lectura = true;
    }
    this.comprobarCheckAll();
  }

  public checkValueLectura(dataItem) {
    dataItem.lectura = !dataItem.lectura;
    if (!dataItem.lectura) {
      dataItem.escritura = false;
    }
    this.comprobarCheckAll(); 
  }

  public checkAllValueLectura(){
    if(this.allSelectedLectura == true){
      this.dataMaquinasPermisos.forEach(element => {
        element.lectura = false;
        element.escritura = false;
      });
    }else{
      this.dataMaquinasPermisos.forEach(element => {
        element.lectura = true;
      });
    }
    this.comprobarCheckAll();
  }

  public checkAllValueEscritura(){
    if(this.allSelectedEscritura == true){
      this.dataMaquinasPermisos.forEach(element => {
        element.escritura = false;
      });
    }else{
      this.dataMaquinasPermisos.forEach(element => {
        element.escritura = true;
        element.lectura = true;
      });
    }
    this.comprobarCheckAll();
  }

  public comprobarCheckAll(){
    var lecturaCount: number = 0;
    var escrituraCount: number = 0;
    this.dataMaquinasPermisos.forEach(element => {
      if(element.lectura == true){
        lecturaCount += 1;
      }
      if(element.escritura == true){
        escrituraCount += 1;
      }
    });
    if(lecturaCount == (this.dataMaquinasPermisos.length)){
      this.allSelectedLectura = true;
    }else{
      this.allSelectedLectura = false;
    }
    if(escrituraCount == (this.dataMaquinasPermisos.length)){
      this.allSelectedEscritura = true;
    }else{
      this.allSelectedEscritura = false;
    }
  }

}
