<!-- al clickar fuera del menu se cerrara -->
<div (click)="cerrarMenu()" style="position:fixed; top:0; left:0; right:0; bottom:0; display:block;"></div>

<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label ID="maquinas" CssClass="">{{ 'bloqueoRangos' | translate }}</label>
                </h3>
            </div>
            <div class="card-body">
                <!--NOMBRE-->
                <div class="caja">
                    <label>{{ 'nombre' | translate }}</label>
                    <kendo-textbox [(value)]="nombre" maxlength="80"></kendo-textbox>
                </div>
                <!-- MAKINA -->
                <!-- <div class="caja">
                    <label>{{ 'maquinas' | translate }}</label>
                    <kendo-multiselect [data]="listaMaquinas" textField="nombre" valueField="id"
                        [(value)]="maquinasSelected" [autoClose]="false"></kendo-multiselect>
                </div> -->
                <!-- OPERARIO -->
                <label>{{ 'operario' | translate }}</label>
                <div class="caja">
                    <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaOperarios"
                        [(ngModel)]="operariosSelected" textField="nombre" valueField="id" [filterable]="true"
                        [autoClose]="false">


                        <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                            <span class="k-icon k-i-arrow-s"></span>
                            <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}
                            </ng-container>
                            <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{
                                'operarios'
                                |
                                translate }}</ng-container>
                        </ng-template>
                        <kendo-multiselect-messages noDataText="{{'norecords' | translate}}">
                        </kendo-multiselect-messages>
                    </kendo-multiselect>
                </div>
                <!-- TIPO PERDIDA -->
                <label class="control-label">{{ 'tipoPerdidas' | translate }}</label>
                <div class="caja">
                    <kendo-dropdownlist [data]="tiposPerdidasList" [textField]="'nombre'" [valueField]="'id'"
                        (selectionChange)="selectionChangeTipoPerdida($event)" [value]="selectedTipoPerdida">
                    </kendo-dropdownlist>
                </div>
                <!-- RECURRENTE -->
                <label class="control-label">{{ 'recurrente' | translate }}</label>
                <div class="caja">
                    <kendo-switch id="recurrente" [(ngModel)]="recurrente" [onLabel]="' '" [offLabel]="' '">
                    </kendo-switch>
                </div>
                <!-- NO RECURRENTE -->
                <div *ngIf="!recurrente">
                    <!-- TURNOS -->
                    <label>{{ 'turno' | translate }}</label>
                    <div class="caja">
                        <kendo-dropdownlist [data]="turnos" [textField]="'nombre'" [valueField]="'id'"
                            (selectionChange)="selectionChangeTurnos($event)" [value]="selectedTurno">
                        </kendo-dropdownlist>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <!-- FECHA INICIO UNICO -->
                            <label>{{ 'fechainicio' | translate }}</label>
                            <div class="caja">
                                <kendo-datepicker [(value)]="fechaIniUnico"></kendo-datepicker>
                            </div>
                        </div>
                        <div class="col-6">
                            <!-- HORA INICIO UNICO -->
                            <label>{{ 'hInicio' | translate }}</label>
                            <div class="caja">
                                <kendo-timepicker [(value)]="horaIniUnico"></kendo-timepicker>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <!-- FECHA FIN UNICO -->
                            <label>{{ 'fechafin' | translate }}</label>
                            <div class="caja">
                                <kendo-datepicker [(value)]="fechaFinUnico"></kendo-datepicker>
                            </div>
                        </div>
                        <div class="col-6">
                            <!-- HORA FIN UNICO -->
                            <label>{{ 'hFin' | translate }}</label>
                            <div class="caja">
                                <kendo-timepicker [(value)]="horaFinUnico"></kendo-timepicker>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- RECURRENTE -->
                <div *ngIf="recurrente">
                    <div class="row">
                        <div class="col-4">
                            <label class="control-label">{{ 'frecuencia' | translate }}</label>
                            <div class="caja">
                                <kendo-dropdownlist [data]="bloqueoRangos_Tipos_Dat" [textField]="'nombre'"
                                    [valueField]="'id'" (selectionChange)="selectionTipoBloqueoChange($event)"
                                    [value]="selectedTipoBloqueo" class="form-control" style="width: 250px">
                                </kendo-dropdownlist>
                            </div>

                        </div>
                        <!-- FRECUENCIA -->
                        <div class="col-md-4">
                            <label>{{ 'frecuencia' | translate }}</label>
                            <div class="caja">
                                <kendo-numerictextbox [value]="frecuencia" [format]="'n'" [decimals]="decimals"
                                    [min]="1" [max]="1000" [autoCorrect]="autoCorrect"
                                    [ngClass]="{ 'is-invalid': submitted && f.frecuencia.errors }">
                                </kendo-numerictextbox>
                            </div>
                        </div>
                        <!-- HORA -->
                        <div class="col-md-4" *ngIf="selectedTipoBloqueo != 5">
                            <label>{{ 'hora' | translate }}</label>
                            <div class="caja">
                                <kendo-timepicker [(value)]="hora">
                                    <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                                        acceptLabel="{{ 'acceptLabel' | translate }}"
                                        cancel="{{ 'cancel' | translate }}"
                                        cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                                        nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
                                    </kendo-timepicker-messages>
                                </kendo-timepicker>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row"> -->
                    <!-- SEMANAL -->
                    <div *ngIf="selectedTipoBloqueo == 2">
                        <label>{{ 'dias' | translate }}</label>
                        <div class="caja">
                            <kendo-dropdownlist [data]="diasSemana" [textField]="'nombre'" [valueField]="'id'"
                                (selectionChange)="selectionChangeDias($event)" [value]="selectedDia">
                            </kendo-dropdownlist>
                        </div>

                    </div>
                    <!-- MENSUAL -->
                    <div *ngIf="selectedTipoBloqueo == 3">
                        <label>{{ 'dia' | translate }}</label>
                        <div class="caja">
                            <kendo-numerictextbox [value]="selectedDia" [format]="'n'" [decimals]="decimals" [min]="1"
                                [max]="maxDias" [autoCorrect]="autoCorrect">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                    <!-- ANUAL -->
                    <div *ngIf="selectedTipoBloqueo == 4">
                        <label>{{ 'mes' | translate }}</label>
                        <div class="caja">
                            <kendo-dropdownlist [data]="meses" [textField]="'nombre'" [valueField]="'id'"
                                (selectionChange)="selectionChangeMeses($event)" [value]="selectedMes">
                            </kendo-dropdownlist>
                        </div>

                        <label>{{ 'dia' | translate }}</label>
                        <div class="caja">
                            <kendo-numerictextbox [value]="selectedDia" [format]="'n'" [decimals]="decimals" [min]="1"
                                [max]="maxDias" [autoCorrect]="true">
                            </kendo-numerictextbox>
                        </div>
                    </div>
                    <!-- POR TURNOS -->
                    <div *ngIf="selectedTipoBloqueo == 5">
                        <label>{{ 'turno' | translate }}</label>
                        <div class="caja">
                            <kendo-dropdownlist [data]="turnos" [textField]="'nombre'" [valueField]="'id'"
                                (selectionChange)="selectionChangeTurnos($event)" [value]="selectedTurno">
                            </kendo-dropdownlist>
                        </div>
                    </div>

                    <!-- </div> -->
                    <!-- <div class="row"> -->
                    <label>{{ 'tiempoestimado' | translate }}</label>
                    <div class="caja">
                        <kendo-maskedtextbox [(value)]="tiempoEstimado"
                            [mask]="maskTiempoEstimado"></kendo-maskedtextbox>
                    </div>

                    <!-- </div> -->

                </div>
                <!-- DESCRIPCION -->
                <label>{{ 'descripcion' | translate }}</label>
                <div class="caja">
                    <textarea [(value)]="descripcion" maxlength="280" kendoTextArea [autoSize]="true"></textarea>
                </div>
                <!--BOTONES-->
                <div class="caja">
                    <button type="button" class="btn btn-primary btn-sm mr-1" (click)="onSubmit()"
                        [disabled]="this.user.planificador<2">
                        {{ 'guardar' | translate}}
                    </button>
                    <button type="button" class="btn btn-danger btn-sm mr-1" (click)="atras()">
                        {{ 'cancelar' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>


    <!-- MAKINAK -->
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label ID="maquinas" CssClass="">{{ 'maquinas' | translate }}</label>
                </h3>
            </div>
            <div class="card-body">
                <kendo-grid [kendoGridBinding]="maquinasSelected" [sortable]="true"
                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                    [navigable]="true" filterable="menu">
                    <!--nombre-->
                    <kendo-grid-column width="95%" field="nombre" title="{{ 'nombre' | translate}}">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="float-left">{{ dataItem.nombre }}</div>
                            <div class="float-right">
                                <div (click)="eliminarMaquinaSelected(dataItem.id)"><i class="icon-equis"></i></div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>

                    <!--TRADUCCIÓN TEXTOS DEL GRID-->
                    <kendo-grid-messages filter="{{'filter' | translate}}"
                        filterAfterOperator="{{'filterAfterOperator' | translate}}"
                        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                        filterAndLogic="{{'filterAndLogic' | translate}}"
                        filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                        filterBooleanAll="{{'filterBooleanAll' | translate}}"
                        filterClearButton="{{'filterClearButton' | translate}}"
                        filterContainsOperator="{{'filterContainsOperator' | translate}}"
                        filterDateToday="{{'filterDateToday' | translate}}"
                        filterDateToggle="{{'filterDateToggle' | translate}}"
                        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                        filterEqOperator="{{'filterEqOperator' | translate}}"
                        filterFilterButton="{{'filterFilterButton' | translate}}"
                        filterGtOperator="{{'filterGtOperator' | translate}}"
                        filterGteOperator="{{'filterGteOperator' | translate}}"
                        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                        filterIsFalse="{{'filterIsFalse' | translate}}"
                        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                        filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                        filterIsTrue="{{'filterIsTrue' | translate}}"
                        filterLtOperator="{{'filterLtOperator' | translate}}"
                        filterLteOperator="{{'filterLteOperator' | translate}}"
                        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                        filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                        filterOrLogic="{{'filterOrLogic' | translate}}"
                        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                        loading="{{'loading' | translate}}" groupPanelEmpty="{{'groupPanelEmpty' | translate}}"
                        lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>

                <!-- AGREGAR MAQUINAS -->
                <div class="agregar-elemento agregar-buscar" (click)="showAgregar()">
                    <span>{{ 'buscar' | translate }}</span>
                    <i class="icon-buscar"></i>
                </div>

                <div class="card" style="position: absolute !important; left: 0; right: 0;" *ngIf="agregarMaquina">
                    <div class="card-body">
                        <!-- FILTRO-->
                        <div class="agregar-elemento" [ngClass]="{ 'filtro-parametros': filtro.length != 0 }"
                            (click)="showFiltro(); showSecciones = true; showGrupos = false; showClasificaciones = false">
                            <span *ngIf="filtro.length == 0">{{'filtro' | translate}}</span>
                            <div *ngIf="filtro.length != 0">
                                <span>{{ filtroString }} </span>
                            </div>
                        </div>
                        <div class="card" style="position: absolute !important; left: 0; right: 0;"
                            *ngIf="ensennarFiltroMaquinas">
                            <div class="card-body">
                                <div class="button-contenedores-filtro">
                                    <span class="icon-check" (click)="selectAllMaquinas()"></span>
                                    <button type="button"
                                        [ngClass]="{'btn btn-success btn-sm': showSecciones, 'btn btn-sm': !showSecciones }"
                                        (click)="showSecciones = true; showGrupos = false; showClasificaciones = false">{{
                                        'secciones' | translate }}</button>
                                    <button type="button"
                                        [ngClass]="{'btn btn-success btn-sm': showGrupos, 'btn btn-sm': !showGrupos }"
                                        (click)="showSecciones = false; showGrupos = true; showClasificaciones = false">{{
                                        'grupos' | translate }}</button>
                                    <button type="button"
                                        [ngClass]="{'btn btn-success btn-sm': showClasificaciones, 'btn btn-sm': !showClasificaciones }"
                                        (click)="showSecciones = false; showGrupos = false; showClasificaciones = true">{{
                                        'clasificaciones' | translate }}</button>
                                </div>

                                <!-- SECCIONES -->
                                <div *ngIf="showSecciones">
                                    <kendo-treelist kendoTreeListExpandable [kendoTreeListFlatBinding]="groupedSeccion"
                                        [sortable]="true" [navigable]="true" idField="idGrid"
                                        parentIdField="idRelacionado" kendoTreeListSelectable
                                        [selectedItems]="seccionesSeleccionadas"
                                        (cellClick)="annadirSeccionesSeleccionadas($event)"
                                        [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                        [initiallyExpanded]="true">
                                        <!-- Columna para saber si esta seleccionado -->
                                        <kendo-treelist-column width="5%" [style]="{'text-align': 'left'}"
                                            field="nombre" title="" class="p-0">
                                            <ng-template kendoTreeListCellTemplate let-dataItem>
                                                <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                </div>
                                            </ng-template>
                                        </kendo-treelist-column>
                                        <!-- Imagen -->
                                        <kendo-treelist-column field="imagen" title="" width="8%"
                                            class="celda-tooltip-externo" [filterable]="false">
                                            <ng-template kendoTreeListCellTemplate let-dataItem>
                                                <span class="tooltip-consumibles-contenido">
                                                    <ng-container>
                                                        <span class="grid-circulo">{{dataItem.siglas}}</span>
                                                    </ng-container>
                                                </span>
                                            </ng-template>
                                        </kendo-treelist-column>
                                        <!-- NOMBRE -->
                                        <kendo-treelist-column [expandable]="true" width="80%"
                                            [style]="{'text-align': 'left'}" field="nombre"
                                            title="{{ 'nombre' | translate}}"></kendo-treelist-column>

                                    </kendo-treelist>
                                </div>

                                <!-- GRUPOS -->
                                <div *ngIf="showGrupos">
                                    <kendo-grid class="filtroGrid" [kendoGridBinding]="grupos"
                                        [selectedKeys]="gruposSeleccionados" [sortable]="true"
                                        [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                        [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                        (change)="filtrarMaquinas()" (cellClick)="annadirGruposSeleccionados($event)">
                                        <!-- Columna para saber si esta seleccionado -->
                                        <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="nombre"
                                            title="" class="p-0">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                </div>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <!-- Imagen -->
                                        <kendo-grid-column field="imagen" title="" width="8%"
                                            class="celda-tooltip-externo" [filterable]="false">
                                            <ng-template kendoGridCellTemplate let-dataItem>
                                                <span class="tooltip-consumibles-contenido">
                                                    <ng-container>
                                                        <span class="grid-circulo">{{dataItem.siglas}}</span>
                                                    </ng-container>
                                                </span>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column width="95%" field="nombre"
                                            title="{{ 'nombre' | translate}}"></kendo-grid-column>
                                    </kendo-grid>
                                </div>

                                <!-- CLASIFICACIONES -->
                                <div *ngIf="showClasificaciones">
                                    <kendo-treelist kendoTreeListExpandable [kendoTreeListFlatBinding]="clasificaciones"
                                        [sortable]="true" [navigable]="true" idField="id" parentIdField="idPadre"
                                        kendoTreeListSelectable [(selectedItems)]="clasificacionesSeleccionados"
                                        (cellClick)="annadirClasificacionesSeleccionadas($event)"
                                        [selectable]="{cell:false, checkboxOnly: true, drag: true, enabled: false, mode: 'multiple'}"
                                        [initiallyExpanded]="true" parentIdField="idPadre">
                                        <!-- Columna para saber si esta seleccionado -->
                                        <kendo-treelist-column width="5%" [style]="{'text-align': 'left'}"
                                            field="nombre" title="" class="p-0">
                                            <ng-template kendoTreeListCellTemplate let-dataItem>
                                                <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado">
                                                </div>
                                            </ng-template>
                                        </kendo-treelist-column>
                                        <!-- Imagen -->
                                        <kendo-treelist-column field="imagen" title="" width="8%"
                                            class="celda-tooltip-externo" [filterable]="false">
                                            <ng-template kendoTreeListCellTemplate let-dataItem>
                                                <span class="tooltip-consumibles-contenido">
                                                    <ng-container>
                                                        <span class="grid-circulo">{{dataItem.siglas}}</span>
                                                    </ng-container>
                                                </span>
                                            </ng-template>
                                        </kendo-treelist-column>
                                        <!-- NOMBRE -->
                                        <kendo-treelist-column [expandable]="true" width="95%"
                                            [style]="{'text-align': 'left'}" field="nombre"
                                            title="{{ 'nombre' | translate}}"></kendo-treelist-column>

                                    </kendo-treelist>
                                </div>
                            </div>

                        </div>
                        <!-- MAQUINAS -->
                        <div class="agregar-elemento"
                            (click)="ensennarFiltroMaquinas = false; ensennarMaquinas = true;">
                            <span *ngIf="agregarSeleccionados.length == 0">{{'maquinas' | translate}}</span>
                            <div *ngIf="agregarSeleccionados.length != 0">
                                <div class="cont-tags-filtro">
                                    <div *ngFor="let maq of maquinasSelected">
                                        <div class="tags-filtro">
                                            <label class="tags-filtro-label">{{ maq.nombre }}</label>
                                            <!-- ELIMINAR -->
                                            <button (click)="eliminarMaquina(maq.id)" class="fas fa-times"
                                                style="cursor:pointer"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="position: absolute !important; left: 0; right: 0;"
                            *ngIf="ensennarMaquinas">
                            <!-- AGRUPAR POR ... -->
                            <div class="button-contenedores-filtro">
                                <button type="button"
                                    [ngClass]="{'btn btn-success btn-sm': isAgruparPorSecciones, 'btn btn-sm': !isAgruparPorSecciones }"
                                    (click)="agruparPorSecciones()">{{ 'secciones' | translate }}</button>
                                <button type="button"
                                    [ngClass]="{'btn btn-success btn-sm': isAgruparPorGrupos, 'btn btn-sm': !isAgruparPorGrupos }"
                                    (click)="agruparPorGrupos()">{{ 'grupos' | translate }}</button>
                                <button type="button"
                                    [ngClass]="{'btn btn-success btn-sm': isAgruparPorClasificaciones, 'btn btn-sm': !isAgruparPorClasificaciones }"
                                    (click)="agruparPorClasificaciones()">{{ 'clasificaciones' | translate
                                    }}</button>
                            </div>

                            <!-- LISTA MAQUINAS -->
                            <div *ngIf="isAgruparPorSecciones" class="grid-agrupado">
                                <kendo-grid [kendoGridBinding]="maquinasAgregar" [selectedKeys]="agregarSeleccionados"
                                    [sortable]="true"
                                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                    [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                    (cellClick)="annadirMaquina($event)" [groupable]="true" [group]="groupsSecciones"
                                    [filterable]="true">
                                    <!-- Columna para saber si esta seleccionado -->
                                    <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                        title="" class="p-0" [filterable]="false">
                                        <ng-template kendoGridHeaderTemplate let-column>
                                            <span class="icon-check" (click)="selectAllMaquinas()"></span>
                                        </ng-template>
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado"></div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <!-- Imagen -->
                                    <kendo-grid-column field="maquinas" title="" width="8%"
                                        class="celda-tooltip-externo" [filterable]="false">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <span class="tooltip-consumibles-contenido">
                                                <ng-container>
                                                    <span class="grid-circulo" style="background-color:white!important;"
                                                        [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                </ng-container>
                                            </span>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                    <kendo-grid-column field="nombreSeccion" width="50%"
                                        title="{{ 'seccion' | translate}}" hidden="hidden"></kendo-grid-column>
                                </kendo-grid>
                            </div>

                            <div *ngIf="isAgruparPorGrupos" class="grid-agrupado">
                                <kendo-grid [kendoGridBinding]="maquinaParaGrupos" [selectedKeys]="agregarSeleccionados"
                                    [sortable]="true"
                                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                    [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                    (cellClick)="annadirMaquina($event)" [groupable]="true" [group]="groupsGrupos"
                                    [filterable]="true">
                                    <!-- Columna para saber si esta seleccionado -->
                                    <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                        title="" class="p-0" [filterable]="false">
                                        <ng-template kendoGridHeaderTemplate let-column>
                                            <span class="icon-check" (click)="selectAllMaquinas()"></span>
                                        </ng-template>
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado"></div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <!-- Imagen -->
                                    <kendo-grid-column field="maquinas" title="" width="8%"
                                        class="celda-tooltip-externo" [filterable]="false">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <span class="tooltip-consumibles-contenido">
                                                <ng-container>
                                                    <span class="grid-circulo" style="background-color:white!important;"
                                                        [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                </ng-container>
                                            </span>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                    <kendo-grid-column field="nombreGrupo" width="50%" title="{{ 'grupo' | translate}}"
                                        hidden="hidden"></kendo-grid-column>
                                </kendo-grid>
                            </div>

                            <div *ngIf="isAgruparPorClasificaciones" class="grid-agrupado">
                                <kendo-grid [kendoGridBinding]="maquinasAgregar" [selectedKeys]="agregarSeleccionados"
                                    [sortable]="true"
                                    [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: false, mode: 'multiple'}"
                                    [navigable]="true" kendoGridSelectBy="id" [height]="270"
                                    (cellClick)="annadirMaquina($event)" [filterable]="true">
                                    <!-- Columna para saber si esta seleccionado -->
                                    <kendo-grid-column width="5%" [style]="{'text-align': 'left'}" field="select"
                                        title="" class="p-0" [filterable]="false">
                                        <ng-template kendoGridHeaderTemplate let-column>
                                            <span class="icon-check" (click)="selectAllMaquinas()"></span>
                                        </ng-template>
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <div class="grid-row-seleccionado" *ngIf="dataItem.isSeleccionado"></div>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <!-- Imagen -->
                                    <kendo-grid-column field="maquinas" title="" width="8%"
                                        class="celda-tooltip-externo" [filterable]="false">
                                        <ng-template kendoGridCellTemplate let-dataItem>
                                            <span class="tooltip-consumibles-contenido">
                                                <ng-container>
                                                    <span class="grid-circulo" style="background-color:white!important;"
                                                        [ngStyle]="{'background-image': 'url(' + dataItem.imagenBase64 + ')'}"></span>
                                                </ng-container>
                                            </span>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column width="95%" field="nombre" title=""></kendo-grid-column>
                                </kendo-grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>