import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService, ConfiguracionService, InformeOeeService, MenuService, MaquinasService, InformeProyectosService, OperariosService, HistoricoMaquinasDatosService } from '@app/_services';
import { MyFunctions, MyFilter, MyCharts } from '@app/_helpers';
import { CellClickEvent } from '@progress/kendo-angular-grid';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-informecalidadoee-data',
  templateUrl: 'informeCalidadOEE_V2.html'
})

export class InformeCalidadOEEComponent_V2 implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public loading = false;

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  user = this.userService.userValue;

  // TRADUCCIONES GRID INFO
  public infoTTotalGridHeader = this.translateService.instant('infoTTotalGridHeader');
  public infoTRealGridHeader = this.translateService.instant('infoTRealGridHeader');
  
  // private dataGraficoDonutCalidad: any;
  // private dataGraficoHoras: any;
  // private dataGraficoDonutParadas: any;
  // private dataGraficoDonutMantenimientos: any;
  // private dataGraficoDonutAlarmas: any;
  // private dataGraficoClientes: any;

  private graficoDonutCalidad: any;
  private graficoBarrasOEE: any;
  private graficoClientes: any;
  
  public graficoDonutPerdidas;
  public sinPerdidas = false;
  public graficoDonutMantenimientos;
  public sinMantenimientos = false;
  public graficoDonutAlarmas;
  public sinAlarmas = false;

  public maquinas: any;
  public maquinasJ: any;
  public maquinasSeleccionadas: string[] = [];
  public datosJ: any;
  public datosOF: any = [];




  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeccion: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //SOLO PARA CAF
  public usuarioIdDbCAF: any;

  // DISPLAYS (estaban metidos con get element by id...)
  public checkboxGruposVisible: boolean = false;
  public lblNoParadas_CalVisible: boolean = true;

  public agrupado: number = 0; // 0 perdidas, 1 padres, 2 grupofs

  // GRID PERDIDAS
  public datosPerdidasGlobal: any = [];
  public datosPerdidas: any = [];

  //TURNO
  private turno: number = -1;

  //FILTRO NUEVO
  public mostrarCalendario1: boolean = false;
  public hoy = new Date();
  public dpFechaIni: Date = new Date(this.hoy.getFullYear(), this.hoy.getMonth(), this.hoy.getDate() - 7);
  public dpFechaFin: Date = this.hoy;
  public DaysInMonths = this.hoy;
  public listaOperarios: any = [];
  public operariosSeleccionados: any = [];
  public seleccionarTodo: boolean = false;
  public listaOperariosSTR: string = ""
  public listaMaquinasAux: any = [];
  public listaOperariosAux: any = [];

  constructor(
    private userService: UsuariosService,
    private maquinasService: MaquinasService,
    private informeProyectosService: InformeProyectosService,
    private informeOeeService: InformeOeeService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private myFunctions: MyFunctions,
    private myFilter: MyFilter,
    private myCharts: MyCharts,
    private operariosService: OperariosService,
    private configuracionService: ConfiguracionService,
    private historicoMaquinasDatosService: HistoricoMaquinasDatosService
  ) {
    this.usuarioIdDbCAF = userService.userValue.idDb == 7 ? true : false;

    if (this.configuracionService.get_config_global() == undefined)
      this.configuracionService.load_config_global().subscribe(
        j => {
          this.configuracionService.set_config_global(j[0])
        });

  }

  ngOnInit() {
    this.loading = true;

    this.menuService.titulo = this.translateService.instant('tituloCalidad');

    this.dpFechaIni = this.informeOeeService.get_fechaIni_model();
    this.dpFechaFin = this.informeOeeService.get_fechaFin_model();
    if (this.dpFechaIni === undefined) {
      this.cargarFechas();
      this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
      this.informeOeeService.set_fechaFin_model(this.dpFechaFin);
    }

    this.cargarGrupos();
    this.cargarAreasProductivas();

    this.dibujarGraficoBarras();
    this.dibujarGraficosDonuts();

    var bukatua = new Promise<void>((resolve, reject) => {

      var maquinas_model = this.informeOeeService.get_maquinas_model();
      var maquinasSeleccionadas_model = this.informeOeeService.get_maquinasSeleccionadas_model();
      var seccionesSeleccionadas_model = this.informeOeeService.get_seccionesSeleccionadas_model();
      var maquinasCompletas_model = this.informeOeeService.get_maquinasCompletas_model();
      var gruposSeleccionados_model = this.informeOeeService.get_gruposSeleccionados_model();

      if (maquinas_model == false) {
        this.informeOeeService.get_maquinas().subscribe(
          json => {
            this.maquinasJ = json;
            this.informeOeeService.set_maquinasCompletas_model(this.maquinasJ); //Necesitamos tenerlo al filtrar, pero lo guardamos en el servicio para no estar llamándolo siempre
            this.cargarMaquinas();
            resolve();
          }
        )
      } else {
        this.maquinas = maquinas_model;
        this.listaMaquinasAux = maquinas_model;
        this.maquinasSeleccionadas = maquinasSeleccionadas_model;
        this.seccionesSeleccionadas = seccionesSeleccionadas_model;
        this.maquinasJ = maquinasCompletas_model;
        this.gruposSeleccionados = gruposSeleccionados_model;
        this.cargarMaquinas2();
        resolve();
      }
      this.operariosService.getAllUsuariosConColor().subscribe(json => {
        var lag: any = json;
        lag.forEach(element => {
          this.listaOperarios.push(element);
          this.listaOperariosAux.push(element);
          //this.operariosSeleccionados.push(element.idUsuario);
        });
        this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
      })
    });

    bukatua.then(() => {
      this.cargar_historicoMaquinas();
    });

  }

  private cargarFechas() {
    var fechaIni;

    //calcular fecha inicio
    if (this.dpFechaIni == undefined) {
      this.dpFechaIni = this.myFunctions.getDateNow()
    }
    fechaIni = this.dpFechaIni
    if (fechaIni.getDay() == 3) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
    } else if (fechaIni.getDay() == 4) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
    } else if (fechaIni.getDay() == 5) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
    } else if (fechaIni.getDay() == 6) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
    } else if (fechaIni.getDay() == 0) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
    } else if (fechaIni.getDay() == 2) {
      this.dpFechaIni = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
    }

    //calcular fecha fin
    fechaIni = new Date(this.dpFechaIni);
    this.dpFechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

    //calcular horas
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposSeccion = this.grupos;
      this.gruposCargados = true;

      var aurk = false;
      this.gruposSeccion = [];
      this.grupos.forEach(grupo => {
        aurk = false;
        this.seccionesSeleccionadas.forEach(seccion => {
          if ((grupo.idSeccion == seccion.id) && !aurk) {
            aurk = true;
            this.gruposSeccion.push(grupo);
          }
        });
      });

    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones = undefined;

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

    }

  }

  cargarMaquinas() {
    this.maquinas = [];
    this.listaMaquinasAux = [];
    var primeraMaquina = true;

    for (let maquina of this.maquinasJ) {
      var m = { id: maquina.id, nombre: maquina.nombre };
      this.maquinas.push(m);
      this.listaMaquinasAux.push(m);

      if (primeraMaquina) {
        this.maquinasSeleccionadas.push(maquina.id);
        primeraMaquina = false;
      }

    }

    this.informeOeeService.set_maquinas_model(this.maquinas);
    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
    this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
  }
  cargarMaquinas2() {
    var listaMaquinas: any;
    //MAQUINAS
    this.informeProyectosService.Get_Maquinas().subscribe(
      (data: any) => {
        //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
        var mostra: any = [];
        data.forEach(
          row => {
            row["idsGrupos"] = row.idGrupos.split(',');
            var an: any = this.userService.secciones;
            var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
            if (listaIdsecciones.includes(row.idSeccion)) {
              mostra.push(row)
            }
          });
        listaMaquinas = mostra;
        var maquinasAux = [];

        var seccionesSeleccionadas = [];
        this.seccionesSeleccionadas?.forEach(elem => seccionesSeleccionadas.push(elem.id));
        var gruposSeleccionados = [];
        this.gruposSeleccionados?.forEach(elem => gruposSeleccionados.push(elem.id));
        if (this.maquinasJ) {
          for (let maquina of this.maquinasJ) {
            var aux = listaMaquinas.filter(f => f.idMaquina === maquina.id)[0];
            if ((this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) || (this.gruposSeleccionados && this.gruposSeleccionados.length > 0)) {
              if (seccionesSeleccionadas.includes(aux?.idSeccion) || aux?.idsGrupos.filter(r => gruposSeleccionados.includes(Number(r))).length > 0) {
                var m = { id: maquina.id, nombre: maquina.nombre };
                maquinasAux.push(m);
              }

            } else {
              var m = { id: maquina.id, nombre: maquina.nombre };
              maquinasAux.push(m);
            }
            this.maquinas = maquinasAux;
            this.listaMaquinasAux = maquinasAux;

          }
          this.maquinasSeleccionadas.forEach(elem => {
            var aux = this.maquinas.filter(e => e.id === elem);
            if (aux.length === 0)
              this.maquinasSeleccionadas.splice(this.maquinasSeleccionadas.indexOf(elem), 1);
          });
          if (this.maquinasSeleccionadas.length === 0) {
            this.maquinasSeleccionadas.push(this.maquinas[0].id);
          }
        }

        this.informeOeeService.set_maquinas_model(this.maquinas);
        this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
        this.informeOeeService.set_seccionesSeleccionadas_model(this.seccionesSeleccionadas);
        this.informeOeeService.set_gruposSeleccionados_model(this.gruposSeleccionados);
      });
  }

  /* FILTRO */
  btnTodos_Click(event) {
    this.turno = -1;
  }
  btnManana_Click(event) {
    this.turno = 1;
  }
  btnTarde_Click(event) {
    this.turno = 2;
  }
  btnNoche_Click(event) {
    this.turno = 3;
  }
  /* BTN FILTRAR */
  public onChangeF(value: Date): void {
    this.dpFechaIni.setHours(0, 0, 0);
    this.dpFechaFin.setHours(0, 0, 0);

    if (this.dpFechaIni > this.dpFechaFin) {
      var f = this.dateCopy(this.dpFechaFin);
      this.dpFechaFin = this.dateCopy(this.dpFechaIni);
      this.dpFechaIni = f;
    }

    //this.cargarGraficos();
  }

  cellClick(event: CellClickEvent) {
    var row = event.dataItem;
    // var fini = this.myFunctions.dateToYYYY_MM_DD(this.dpFechaIni); //FORMATO: YYYY_MM_DD 
    // this.dpFechaFin.setHours(23, 59, 59, 0);
    // var ffin = this.myFunctions.dateToYYYY_MM_DD(this.dpFechaFin); //FORMATO: YYYY_MM_DD
    //this.router.navigate(['analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + row.idCliente + '/' + row.idPieza + '/' + row.idOF]);
    // window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + row.idCliente + '/' + row.idPieza + '/' + row.idOF + '/0/0/0', '_blank');
    window.open('#/analiticaavanzadaejecuciones/0/0/0/0/0/0/' + row.idHistorico_piezas + '/' + row.idHistoricoOperaciones, '_blank');
  }

  public acortarDatosGraficoDonuts(arrayDatos) {
    //Si hay valores repetidos se agrupan
    var nuevoArray = [];
    arrayDatos.forEach(function (elementArray, indexArray) {
      var contains = false;
      var i = -1;
      nuevoArray.forEach(function (elementNuevoArray, indexNuevoArray) {
        if (elementArray[0] == elementNuevoArray[0]) {
          contains = true;
          i = indexNuevoArray;
        }
      });
      if (contains) {
        nuevoArray[i][1] = nuevoArray[i][1] + elementArray[1];
      } else {
        nuevoArray.push([elementArray[0], elementArray[1]]);
      }
    });

    //Acortar los datos
    if (nuevoArray.length < 10) {
      return nuevoArray;
    } else {
      var arrayParaEnseñar = nuevoArray.slice(0, 9);
      var arrayParaAgrupar = nuevoArray.slice(9, nuevoArray.length);
      var totalOtros = 0;
      arrayParaAgrupar.forEach(function (obj) {
        totalOtros = totalOtros + obj[1];
      });
      arrayParaEnseñar.push(['Otros', totalOtros]);
      return arrayParaEnseñar;
    }
  }
  public updateDonutLeyenda(grafico, oldData, newData) {

    var nombresOldData = oldData.map(function (x) { return x[0]; });
    var nombresNewData = newData.map(function (x) { return x[0]; });

    var nombresAEliminar = [];
    for (let value of nombresOldData) {
      grafico.show(value);
      var nombre = "c3-legend-item-" + value
      nombre = nombre.split(' ').join('-'); //Asi quitamos todos los espacios y los reemplazamos por '-', ya que no deja usar replaceAll
      var elemento: any = document.getElementsByClassName(nombre)[0];
      elemento.classList.remove("c3-legend-item-hidden");
      elemento.style.opacity = "1";
      if (nombresNewData.indexOf(value) === -1) {
        nombresAEliminar.push(value);
      }
    }

    var names = {};
    newData.forEach(function (a) {
      names[a[0]] = a[0] + " (" + this.myFunctions.secondsTo_HH_MM_SS(a[1]) + ")";
    }, this);

    grafico.load({
      columns: newData,
      names: names
    });
    grafico.unload({
      ids: nombresAEliminar
    });
  }
  public sortData(unsortedData) {
    var sorted = unsortedData.map(function (row) {
      return row.slice();
    })
    var name = sorted[1].splice(0, 1);
    var datapoints = sorted[1].map(function (d, i) {
      return [sorted[0][i], d];
    });
    var sortedData = datapoints.sort(function (a, b) {
      return b[1] - a[1];
    });
    sorted[1] = sortedData.map(function (point, i) {
      sorted[0][i] = point[0];
      return point[1];
    });
    sorted[1] = name.concat(sorted[1]);
    sorted[0].splice(0, 0, 'x');
    return sorted;
  }
  public secondsTo_HH_MM_SS(segundos) {
    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }
  public cortarLeyenda(inputText, trimmed = false) {

    var font = "12px sans-serif"; //DIMESIONES LEYENDA C3

    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    context.font = font;
    var width = context.measureText(inputText).width;

    if (width > 140) {
      return this.cortarLeyenda(inputText.substring(0, inputText.length - 1), true);
    } else if (trimmed) {
      return inputText + "...";
    } else {
      return inputText;
    }

  }


  public checkboxChange(tipo: number): void { //función para saber si las pérdidas están agrupadas por grupos

    this.agrupado = tipo;

    // this.cargarPerdidas();

  }


  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }



  exportarExcel() {
    console.log('exportar excel()...');

  }

  showCalendar() {
    if (!this.mostrarCalendario1) {
      this.mostrarCalendario1 = true;
      console.log("TRUE");
    }
    else {
      this.mostrarCalendario1 = false;
      console.log("FALSE");
      if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
        this.dpFechaFin = this.dpFechaIni;
      }
      else if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
        this.dpFechaIni = this.myFunctions.getDateNow();
        this.dpFechaFin = this.dpFechaIni;
      }

    }
    // this.mostrarCalendario1 = true; // activa el click de fondo!
  }


  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && this.dpFechaIni != undefined && this.dpFechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        this.dpFechaFin = this.DaysInMonths[month];
        this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
          this.dpFechaIni = this.DaysInMonths[month];
        }
        else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
          this.dpFechaFin = this.DaysInMonths[month];
          if (this.dpFechaIni > this.dpFechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(this.dpFechaIni.getTime());
            this.dpFechaIni = new Date(this.dpFechaFin.getTime());
            this.dpFechaFin = aux;
          }
          this.mostrarCalendario1 = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          this.dpFechaIni = this.DaysInMonths[month];
          this.dpFechaFin = undefined;
        }
      }
    }
  }

  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(date) {
    if (this.dpFechaIni == undefined && this.dpFechaFin == undefined) {
      return false;
    } else if (this.dpFechaIni != undefined && this.dpFechaFin == undefined) {
      return date.getFullYear() == this.dpFechaIni.getFullYear() && date.getMonth() == this.dpFechaIni.getMonth() && date.getDate() == this.dpFechaIni.getDate();
    } else if (this.dpFechaIni != undefined && this.dpFechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(this.dpFechaIni.getFullYear(), this.dpFechaIni.getMonth(), this.dpFechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(this.dpFechaFin.getFullYear(), this.dpFechaFin.getMonth(), this.dpFechaFin.getDate());
    }
  }


  insertMaquinasSelected(id, e) {
    var aurk = false;
    this.maquinasSeleccionadas.forEach((maquina, index) => {
      if (maquina == id) {
        this.maquinasSeleccionadas.splice(index, 1);
        aurk = true;
      }
    });
    if (!aurk) {
      this.maquinasSeleccionadas.push(id);
    }
    console.log(this.maquinasSeleccionadas);
  }

  selectAllUsuarios() {
    if (this.operariosSeleccionados.length == this.listaOperarios.length) {
      this.seleccionarTodo = true;
      this.operariosSeleccionados = [];
    } else {
      this.seleccionarTodo = false;
      this.operariosSeleccionados = [];
      this.listaOperarios.forEach(operario => {
        this.operariosSeleccionados.push(operario.idUsuario);
      });
    }
    this.operariosSeleccionados;
  }

  filtroMaquinas(e: any) {
    this.listaMaquinasAux = [];
    if (e == '') {
      this.listaMaquinasAux = this.maquinas;
    } else {
      this.maquinas.forEach(maquina => {
        if (maquina.nombre.toLowerCase().includes(e.toLowerCase())) {
          this.listaMaquinasAux.push(maquina);
        }
      });
    }
  }

  filtroOperarios(e: any) {
    this.listaOperariosAux = [];
    if (e == '') {
      this.listaOperariosAux = this.listaOperarios;
    } else {
      this.listaOperarios.forEach(operario => {
        if (operario.nombreCompleto.toLowerCase().includes(e.toLowerCase())) {
          this.listaOperariosAux.push(operario);
        }
      });
    }
    this.listaOperariosAux.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase()) ? 1 : ((b.nombre.toLowerCase() > a.nombre.toLowerCase()) ? -1 : 0))
  }

  imprimirXls(e, grid: GridComponent) {
    console.log("________________________________________________________________________________")
    console.log(grid)
    e.preventDefault();
    setTimeout(function () {
      grid.saveAsExcel();
    });
  }





  // CARGA DE GRAFICOS VACIOS ---------------------------------------------------------------------------------------------------------------------
  dibujarGraficoBarras() {

    this.graficoBarrasOEE = this.myCharts.dibujarGraficoBarrasOEE('chartBarHoras_Cal', 3);

    this.graficoClientes = this.myCharts.dibujarGraficoBarras('GraficoClientes_Cal');

  }
  dibujarGraficosDonuts() {

    this.graficoDonutCalidad = this.myCharts.dibujarGraficoDonut_simple('chartDonutCalidad_OEE_Cal', true);

    this.graficoDonutPerdidas = this.myCharts.dibujarGraficoDonut_INFO('chartPerdidas_Cal');
    this.graficoDonutMantenimientos = this.myCharts.dibujarGraficoDonut_INFO('chartMantenimientos_Cal');
    this.graficoDonutAlarmas = this.myCharts.dibujarGraficoDonut_INFO('chartAlarmas_Cal');

  }

  // RECARGAR PAGINA
  recargarPagina() {
    this.loading = true;// hay que tener en cuenta que si no se re-hace la consulta del historico, no se llega a ver el icono de carga

    this.informeOeeService.set_maquinasSeleccionadas_model(this.maquinasSeleccionadas);
    this.informeOeeService.set_fechaIni_model(this.dpFechaIni);
    this.informeOeeService.set_fechaFin_model(this.dpFechaFin);

    setTimeout(() => { // PARA QUE SALGA EL ICONO DE CARGA
      this.cargar_historicoMaquinas();
    }, 0);
  }

  // CARGAR DATOS
  cargar_historicoMaquinas() {
    var filtro = this.myFilter.crearFiltro_rapido_OEE([this.dpFechaIni, this.dpFechaFin], this.turno, this.maquinasSeleccionadas, this.operariosSeleccionados)

    var MinMax = this.myFilter.filtroFechas_MIN_MAX(filtro, true);
    var fechaInicio = MinMax[0];
    var fechaFin = MinMax[1];

    if (!this.historicoMaquinasDatosService.util(fechaInicio, fechaFin)) {
      this.historicoMaquinasDatosService.cargar_historico_completo(fechaInicio, fechaFin).subscribe(
        j => {
          this.historicoMaquinasDatosService.set_historico_datos(j, fechaInicio, fechaFin);
          this.cargado_historicoMaquinas();
        });
    } else {

      this.cargado_historicoMaquinas();
    }
  }
  cargado_historicoMaquinas() {

    // Como en esta pagina no hay un filtro nuevo, se apaña creando la misma estructura desde atras.
    var filtro = this.myFilter.crearFiltro_rapido_OEE([this.dpFechaIni, this.dpFechaFin], this.turno, this.maquinasSeleccionadas, this.operariosSeleccionados)
    // Se combierte en filtro Kendo para usar sobre JSON
    var filtro_kendo: any = this.myFilter.filtroToKendo(filtro)
    // Aplicamos el filtro a los datos obtenidos en la clase (que se ha devido actualizar antes de llamar a esta funcion).
    var historico_completo_filtrado = this.historicoMaquinasDatosService.get_historicoMauqinas_filtrado(filtro_kendo);
    var historico_completo_filtradocalidad = this.historicoMaquinasDatosService.filtrar_historico_completo_filtrado_OEE(historico_completo_filtrado, 3);

    // Se trabaja con los datos filtrados.
    this.cargarGraficos(historico_completo_filtrado, historico_completo_filtradocalidad);

    // Se carga el grid inferior
    this.cargarGrids(historico_completo_filtradocalidad);

    this.loading = false;
  }
  cargarGraficos(historico_completo_filtrado, historico_completo_filtradocalidad) {

    this.cargarGraficosDonuts(historico_completo_filtrado, historico_completo_filtradocalidad);
    this.cargarGraficosBarras(historico_completo_filtrado);

    var datosClientes = this.historicoMaquinasDatosService.get_grid_clientes_prefiltrado(historico_completo_filtradocalidad);
    this.myCharts.cargarGraficoBarras_Grid(this.graficoClientes, datosClientes, 'clientes', 'desvioEstimadoParcialSegundos', 'desvio');

  }

  // CARGA DE GRAFICOS 
  cargarGraficosDonuts(historico_completo_filtrado, historico_completo_filtradocalidad) {

    var OEE_donut_calculado: any = this.historicoMaquinasDatosService.get_OEE_data_Donut_prefiltrado(historico_completo_filtrado);

    d3.select('#chartDonutCalidad_OEE_Cal .c3-chart-arcs-title').transition().duration(1000).style("font-size", "0px").style("opacity", "0").transition().duration(1000).style("font-size", "20px").style("opacity", "1")
      .text(Math.round(OEE_donut_calculado.calidad_porcentaje) + "%");

    this.graficoDonutCalidad.load(OEE_donut_calculado.calidad);

    this.sinPerdidas = !this.historicoMaquinasDatosService.load_Donut_perdidas_prefiltrado(this.graficoDonutPerdidas, historico_completo_filtrado, 3);//SE HA PEDIDO FILTRAR EN CADA INFORME SUS ALARMAS
    this.sinMantenimientos = !this.historicoMaquinasDatosService.load_Donut_mantenimientos_prefiltrado(this.graficoDonutMantenimientos, historico_completo_filtrado, 3);//SE HA PEDIDO FILTRAR EN CADA INFORME SUS ALARMAS
    this.sinAlarmas = !this.historicoMaquinasDatosService.load_Donut_alarmas_prefiltrado(this.graficoDonutAlarmas, historico_completo_filtrado, 3);//SE HA PEDIDO FILTRAR EN CADA INFORME SUS ALARMAS

  }
  cargarGraficosBarras(historico_completo_filtrado) {

    var data = this.historicoMaquinasDatosService.get_OEE_data_Barras_prefiltrado(historico_completo_filtrado);
    this.graficoBarrasOEE.load(data);

  }

  // CARGA DE GRIDS   
  cargarGrids(historico_completo_filtradocalidad) {
    var historico_completo_filtrado_disponibilidad_filtrado = this.historicoMaquinasDatosService.filtrar_historico_completo_filtrado_solo(historico_completo_filtradocalidad, "idPerdida");
    this.datosPerdidas = this.historicoMaquinasDatosService.get_grid_compacto_perdidas_prefiltrado(historico_completo_filtrado_disponibilidad_filtrado, 3)

    var dict: any = {};
    //Tenemos las imagenes, creamos el diccionario
    this.maquinas.forEach(
      element => {
        dict[element.imagen] = element.imagenBASE64;
      });

    this.datosPerdidas.forEach(
      element => {
        //Ahora maquinas
        if (element.maquina == "") {
          element.maquinas = this.translateService.instant('desconocido');
        }
        if (element.maquina != null) {
          var maquinas = element.maquina.split(",");
          var maquinasAuxi = [];
          var maquinasAuxi2 = [];
          maquinas.forEach(maquina => {
            if (maquina == this.translateService.instant("desconocido")) {
              maquinasAuxi2.push("undefined");
              maquinasAuxi.push("undefined");
            } else {
              var nombre = maquina.trim().substring(0, 1).toUpperCase();
              var apellido = maquina.trim().substring(1, 2).toUpperCase();
              maquinasAuxi2.push(maquina);
              maquinasAuxi.push(nombre + apellido);
            }
          });
          element.maquinasAuxi2 = maquinasAuxi2.join(";");
          element.maquinasAuxi = maquinasAuxi.join(",");
          //Ahora hay que corregir las imagenes de las maquinas
          var imagenes = element.maquina.split(';and;');
          var auxiImagenes = "";
          imagenes.forEach(imagen => {
            auxiImagenes += dict[imagen];//+ ";and;";
          });
          element.maquinaIm = auxiImagenes;
        } else {
          element.maquinasAuxi2 = "";
          element.maquinasAuxi = "";
          element.maquinasIm = "";
        }
      });

    // this.datosOF = this.historicoMaquinasDatosService.get_grid_operaciones_prefiltrado(historico_completo_filtradocalidad);
    this.datosOF = this.historicoMaquinasDatosService.get_grid_nSerie_prefiltrado(historico_completo_filtradocalidad, 3);

    /* SE PREPARAN LOS GRID PARA SER LOS MISMOS DATOS QUE SE EXPORTEN */
    this.datosPerdidas.forEach(
      element => {
        element.duracion_HH_mm_ss = this.myFunctions.secondsTo_HH_MM_SS(element.duracion);
      });

    this.datosOF.forEach(
      element => {
        element.desvioEstimadoPorcen = element.desvioEstimadoPorcen.toFixed(2);
        element.tTotalParcial_HH_mm = this.myFunctions.secondsTo_HH_MM(element.tTotalParcial);
        element.tEstimadoParcial_HH_mm = this.myFunctions.secondsTo_HH_MM(element.tEstimadoParcial);
        element.tTotal_HH_mm = this.myFunctions.secondsTo_HH_MM(element.tTotal);
        element.tEstimadoTotal_HH_mm = this.myFunctions.secondsTo_HH_MM(element.tEstimadoTotal);
        element.cantidadMalas = element.cantidadAchatarrada + element.cantidadApartada;

      });
  }

}
