<div class="home-inf-conf-col-izquierda">
  <div class="home-cuatro-graficos donuts-argiak">
    <div class="cont-home-donuts">
      <div class="home-donut" [id]="idHTML" style="width: 300px; overflow: hidden; transform: rotate(-90deg); margin-top: 33px;"></div>
      <div class="valores-home-donut">
      </div>
    </div>
  </div>
</div>
    
    