import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/informeconsumo`;

@Injectable()
export class InformeConsumoService {

  constructor(private http: HttpClient) { }

  public get_colores() {
    return this.http.get<JSON>(`${baseUrl}/colores/`);
  }

  public get_rangosEjes(idEje: number) {
    return this.http.get<JSON>(`${baseUrl}/rangosEjes/` + idEje);
  }

  public get_distribucionTiempo(idMaquina: number, idEje: number, fechaInicio: string, fechaFin: string, fechaAhora: string, idsTurnos: string,
    minimoAvance: number, cambioColor1Avance: number, cambioColor2Avance: number, cambioColor3Avance: number, maximoAvance: number,
    minimoPotencia: number, cambioColor1Potencia: number, cambioColor2Potencia: number, cambioColor3Potencia: number, maximoPotencia: number) {
    return this.http.post<JSON>(`${baseUrl}/distribucionTiempo/`,
      {
        idMaquina: idMaquina, idEje: idEje, fechaInicio: fechaInicio, fechaFin: fechaFin, fechaAhora: fechaAhora, idsTurnos: idsTurnos,
        minimoAvance: minimoAvance, cambioColor1Avance: cambioColor1Avance, cambioColor2Avance: cambioColor2Avance, cambioColor3Avance: cambioColor3Avance, maximoAvance: maximoAvance,
        minimoPotencia: minimoPotencia, cambioColor1Potencia: cambioColor1Potencia, cambioColor2Potencia: cambioColor2Potencia, cambioColor3Potencia: cambioColor3Potencia, maximoPotencia: maximoPotencia
      }, { withCredentials: true });
  }

  public get_visorDatos(idMaquina: number, idEje: number, fechaInicio: string, fechaFin: string, fechaAhora: string, idsTurnos: string) {
    return this.http.post<JSON>(`${baseUrl}/visorDatos/`, { idMaquina: idMaquina, idEje: idEje, fechaInicio: fechaInicio, fechaFin: fechaFin, fechaAhora: fechaAhora, idsTurnos: idsTurnos }, { withCredentials: true });
  }

  public getDatosConsumo(filtro) {    
    return this.http.post<JSON>(baseUrl + "/getDatosConsumoEriscore" , {filtro},{ withCredentials: true });     
  }

  public getDatosConsumoMes(filtro, anno, mes) {    
    return this.http.post<JSON>(baseUrl + "/getDatosConsumoMes" , {filtro, anno, mes},{ withCredentials: true });     
  }

  public getDatosConsumoDia(filtro, anno, mes, dia) {    
    return this.http.post<JSON>(baseUrl + "/getDatosConsumoDia" , {filtro, anno, mes, dia},{ withCredentials: true });     
  }

  public getMaquinasConsumo(filtro) {    
    return this.http.post<JSON>(baseUrl + "/getMaquinasConsumoEriscore" , {filtro}, { withCredentials: true });     
  }

  public getOFPiezaOperacionConsumo() {    
    return this.http.post<JSON>(baseUrl + "/getOFPiezaOperacionConsumo" , { withCredentials: true });     
  }
  
  public getRangoFechasConsumo(filtro) {    
    return this.http.post<JSON>(baseUrl + "/getRangoFechasConsumo" , {filtro}, { withCredentials: true });     
  }
}
