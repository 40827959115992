<div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-header">
                <h3>{{ 'datos' | translate}}</h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-8">
                        <div class="form-group ">
                            <kendo-label text="{{ 'nombre' | translate}}">
                                <div class="caja">
                                    <kendo-textbox [(value)]="proveedorNombre" maxlength="150"
                                        [ngClass]="{ 'is-invalid': submitted && errorNombre }">
                                    </kendo-textbox>
                                    <div *ngIf="submitted && errorNombre" class="invalid-feedback">
                                        {{ 'nombrerequerido' |
                                        translate}}
                                    </div>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="form-group form-check ">
                            <kendo-label for="activo" class="form-check-label" text="{{ 'activo' | translate}}">
                            </kendo-label>
                            <div class="caja">
                                <kendo-switch [(ngModel)]="activo" id="activo" [onLabel]="' '" [offLabel]="' '"> </kendo-switch>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!--OBSERVACIONES-->
                        <div class="form-group">
                            <kendo-label text="{{ 'descripcion' | translate }}">
                                <div class="caja">
                                    <textarea kendoTextArea class="form-control" [(value)]="proveedorDescripcion"
                                        style="height: 7em;"></textarea>
                                </div>
                            </kendo-label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>



<button type="button" class="btn mr-2 btn-primary" (click)="proveedorGuardarClick()" [disabled]="this.user.proveedores<2">{{ 'guardar' | translate
    }}</button>
<button type="button" class="btn mr-2 btn-danger" [routerLink]="['/proveedores']">{{ 'cancelar' | translate
    }}</button>