import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PlanesAccionService } from '@app/_services/planesaccion.service';
import { AccionesService } from '@app/_services/acciones.service';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService, AlertService, MenuService } from '@app/_services';
import { Usuario } from '@app/_models';

import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-planacciondetalle-data',
  templateUrl: 'plandeaccionDetalle.html'
})

export class PlandeaccionDetalleComponent {

  private translate: TranslateService;

  public idPlan: number;

  public acciones: [];
  public responsables: [];

  public fechaini: Date;
  public fechafin: Date;
  public nombre: string;
  public descripcion: string;
  public idResponsable: number;
  public accionesseleccionadas: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  isAddMode: boolean;
  public botonesVisible: Boolean;
  form: FormGroup;

  public accionesEliminar: [];
  private accioneseleccionado = [];
  closeResult = '';
  user = this.userService.userValue;

  tituloPopUp: any;

  //requerimientos
  public nombreRequerido:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private planesaccionservice: PlanesAccionService,
    private accionesservice: AccionesService,
    private userService: UsuariosService,
    public myFunctions: MyFunctions,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    translate: TranslateService,
    private modalService: NgbModal,
    protected alertService: AlertService) {
    this.translate = translate;

    this.menuService.titulo = this.translate.instant('planDeAccion').toUpperCase();

  }

  ngOnInit() {
    this.userService.getAllUsers(0).pipe().subscribe((result) => {
       this.responsables = result['data'];
       this.responsables.forEach((element: any) => {
         element.nombrecompleto = element.nombre + " " + element.apellido1;
       });
    });

    this.tituloPopUp = document.getElementById("text_loading_popup");

    this.botonesVisible = true;
    this.idPlan = this.route.snapshot.params['id'];
    this.isAddMode = !this.idPlan;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      fechaini: ['', Validators.required],
      fechafin: ['', Validators.required],
      idresponsable: ['',],
      descripcion: ['',]
    });

    if (this.idPlan > 0) {
      this.cargarDatos();
    } else {
      this.fechaini = this.myFunctions.getDateNow()
      this.fechafin = this.myFunctions.getDateNow()
      this.form.patchValue({ fechaini: this.fechaini, fechafin: this.fechafin });
    }
  }

  cargarDatos() {

    this.planesaccionservice.GetByID(this.idPlan).pipe().subscribe((result) => {

      this.fechaini = new Date(Date.parse(result.data[2]));
      this.fechafin = new Date(Date.parse(result.data[3]));
      this.nombre = result.data[1];
      this.descripcion = result.data[4];
      this.idResponsable = parseInt(result.data[6]);
      this.acciones = result.acciones;
      result.data.fechaini = this.fechaini;
      result.data.fechafin = this.fechafin;
      this.form.patchValue({ nombre: this.nombre, fechaini: this.fechaini, fechafin: this.fechafin, idresponsable: this.idResponsable, descripcion: this.descripcion });
      if (this.acciones.length == 0) this.botonesVisible = false;

    });

  }

  onClickEliminarAccion(content, e) {
    if (this.accionesseleccionadas.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['acciones/editar/' + this.accionesseleccionadas[0]], { state: { idplan: this.idPlan, idaccion: this.accionesseleccionadas[0] } });
    }
  }

  onClickEditarAccion() {
    if (this.accionesseleccionadas[0] > 0) {
      this.router.navigate(['acciones/editar/' + this.accionesseleccionadas[0]], { state: { idplan: this.idPlan, idaccion: this.accionesseleccionadas[0] } });
    }
  }

  onClickNuevaAccion() {
    this.router.navigate(['acciones/crear/'], { state: { idplan: this.idPlan } });
  }

  onSubmit(contentloading, e) {
    e.preventDefault();
    if (this.form.value.nombre == ''){
      this.nombreRequerido = true;
    }
    // stop here if form is invalid
    if (this.form.invalid) {
      console.log("No se ha introducido ningun nombre");
      console.log(this.form.value);
      console.log("Descripcion: ",this.form.value.descripcion);
      
      return;
    }

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

    if (this.isAddMode) {
      this.nuevo();
    } else {
      this.actualizar();
    }
  }

  actualizar() {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.idplan = this.idPlan;
    this.planesaccionservice.update(this.form.value)
      .subscribe((result) => {
        this.modalReferenceloading.close();
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          var idgenerado = result.id;
          this.router.navigate(['planesaccion/editar/' + this.idPlan]);
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }
      });
  }

  nuevo() {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("guardando");
    }
    this.planesaccionservice.create(this.form.value)
      .subscribe((result) => {
        this.modalReferenceloading.close();
        {

          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            var idgenerado = result.id;
            this.router.navigate(['planesaccion/editar/' + result.id]);
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      });
  }

  Atras() {
    this.router.navigate(['planesaccion']);
  }

  private Eliminar(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }
    this.accionesservice.delete(this.accionesseleccionadas).subscribe((data) => {

      this.modalReferenceloading.close();

      if (data.error == false) {
        this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
        this.cargarDatos();
      } else {
        this.alertService.success(this.translate.instant("error"), { keepAfterRouteChange: true });
      }
    });

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

}
