import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/contenedores`;

@Injectable()
export class ContenedoresService {

  constructor(private http: HttpClient) { }

  GetContenedorById(id) {
    return this.http.post<JSON>(`${baseUrl}/Get_contenedor_byId`, { id }, { withCredentials: true });
  }

  GetAll() {
    return this.http.post<JSON>(`${baseUrl}/GetAll`, { }, { withCredentials: true });
  }

  GetInformeContenedorById(ids) {
    return this.http.post<JSON>(`${baseUrl}/GetInformeContenedor_ById`, { ids }, { withCredentials: true });
  }

  GetInformeContenedoresAll() {
    return this.http.post<JSON>(`${baseUrl}/GetInformeContenedoresAll`, { }, { withCredentials: true });
  }

  InsertContenedor(nombre, activo, descripcion, stockMinimo, stockMaximo, maquinasOrigen, maquinasDestino) {
    return this.http.post<JSON>(`${baseUrl}/Insert_Contenedor`, { nombre, activo, descripcion, stockMinimo, stockMaximo, maquinasOrigen, maquinasDestino }, { withCredentials: true });
  }

  UpdateContenedor(id, nombre, activo, descripcion, stockMinimo, stockMaximo, maquinasOrigenInsert, maquinasOrigenDelete, maquinasDestinoInsert, maquinasDestinoDelete) {
    return this.http.post<JSON>(`${baseUrl}/Update_Contenedor`, { id, nombre, activo, descripcion, stockMinimo, stockMaximo, maquinasOrigenInsert, maquinasOrigenDelete, maquinasDestinoInsert, maquinasDestinoDelete }, { withCredentials: true });
  }

  DeleteContenedor(ids) {
    return this.http.post<JSON>(`${baseUrl}/Delete_Contenedor`, { ids }, { withCredentials: true });
  }

}
