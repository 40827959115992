import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/histogramaTiemposPieza`;
@Injectable()
export class HistogramaTiemposPiezaService extends BehaviorSubject<any> {

  constructor(private http: HttpClient) {
    super(null);
  }

  public getDatosFiltrados(listaOfs, listaPiezas, listaPartes, listaOperaciones, listaRutas, fechaInicio, fechaFin, listaProcesos,
     graficoSeleccionado, operacionesConDesvio, listaMaquinas): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/getDatosFiltrados`, { listaOfs: listaOfs, listaPiezas: listaPiezas, listaPartes: listaPartes, 
      listaOperaciones: listaOperaciones, listaRutas: listaRutas, fechaInicio: fechaInicio, fechaFin: fechaFin, listaProcesos: listaProcesos, 
      graficoSeleccionado: graficoSeleccionado, operacionesConDesvio: operacionesConDesvio, listaMaquinas: listaMaquinas }, { withCredentials: true });
  }
}
