import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { DateTimePickerActiveTab } from '@progress/kendo-angular-dateinputs';

const baseUrl = `${environment.apiUrl}/consumibles`;
const baseUrlGrupos = `${environment.apiUrl}/cursoslectivosgrupos`

@Injectable()
export class CursosLectivosGruposService {

  constructor(private http: HttpClient) { }

  // CURSOS LECTIVOS
  public Get_grupos() {
    return this.http.post<JSON>(`${baseUrlGrupos}/Get_grupos`, {}, { withCredentials: true });
  }

  public Get_alumnosByid(id: number) {
    return this.http.post<JSON>(`${baseUrlGrupos}/Get_alumnosbyid`, { id }, { withCredentials: true });
  }
  
  public Get_grupoByid(id: number) {
    return this.http.post<JSON>(`${baseUrlGrupos}/Get_grupobyid`, { id }, { withCredentials: true });
  }

  public Get_cursosLectivos() {
    return this.http.post<JSON>(`${baseUrlGrupos}/Get_cursosLectivos`, {}, { withCredentials: true });
  }

  public Get_tipoGrupos() {
    return this.http.post<JSON>(`${baseUrlGrupos}/Get_tipoGrupos`, {}, { withCredentials: true });
  }

  public Create(nombre: string, cursoLectivo: string, inicio: string, fin: string, calendario: string, tipo: string, activo: number, color: string) {
    return this.http.post<JSON>(`${baseUrlGrupos}/create_curso_lectivo_grupos`, { nombre, cursoLectivo, inicio, fin, calendario, tipo, activo, color }, { withCredentials: true });
  }

  public Delete(id: number) {
    return this.http.post<JSON>(`${baseUrlGrupos}/delete_curso_lectivo_grupos`, { id }, { withCredentials: true });
  }

  public UpdateAlumnoGrupo(id: number) {
    return this.http.post<JSON>(`${baseUrlGrupos}/update_alumno_grupo`, { id }, { withCredentials: true });
  }

  public Update(id: number, nombre: string, cursoLectivo: string, inicio: string, fin: string, calendario: string, tipo: string, activo: number, color: string) {
    return this.http.post<JSON>(`${baseUrlGrupos}/update_curso_lectivo_grupos`, { id, nombre, cursoLectivo, inicio, fin, calendario, tipo, activo, color }, { withCredentials: true });
  }

  //CONSUMIBLES TIPOS
  public Get_consumiblesTipos() {
    return this.http.post<JSON>(`${baseUrl}/Get_consumiblesTipos`, {}, { withCredentials: true });
  }
  public Get_consumiblesTipos_conHijos() {
    return this.http.post<JSON>(`${baseUrl}/Get_consumiblesTipos_conHijos`, {}, { withCredentials: true });
  }
  public Get_consumiblesTipos_byID(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_consumiblesTipos_byID`, { id }, { withCredentials: true });
  }
  public Get_HIJOS_consumiblesTipos_byID(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_HIJOS_consumiblesTipos_byID`, { id }, { withCredentials: true });
  }
  public Guardar_consumibleTipo(id: number, nombre: string, descripcion: string,
    solicitar_vidaUtil: boolean, solicitar_diametro: boolean, solicitar_longitud: boolean, solicitar_angulo: boolean,
    solicitar_numContenido: boolean, solicitar_salto: boolean, solicitar_idErp: boolean, solicitar_coste: boolean, idHijos: number[]) {
    return this.http.post<JSON>(`${baseUrl}/Guardar_consumibleTipo`, {
      id, nombre, descripcion,
      solicitar_vidaUtil, solicitar_diametro, solicitar_longitud, solicitar_angulo,
      solicitar_numContenido, solicitar_salto, solicitar_idErp, solicitar_coste, idHijos
    }, { withCredentials: true });
  }
  public Delete_consumibleTipo_ids(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/Delete_consumibleTipo_ids`, { ids: ids.join() }, { withCredentials: true });
  }

  //CONSUMIBLES 
  public Get_consumibles() {
    return this.http.post<JSON>(`${baseUrl}/Get_consumibles`, {}, { withCredentials: true });
  }
  public Get_consumibles_grid() {
    return this.http.post<JSON>(`${baseUrl}/Get_consumibles_grid`, {}, { withCredentials: true });
  }
  public Get_consumibles_grid_by_tipo(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_consumibles_grid_by_tipo`, { id }, { withCredentials: true });
  }
  public Get_consumibles_byID(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_consumibles_byID`, { id }, { withCredentials: true });
  }
  public Get_consumibles_byTipoID(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_consumibles_byTipoID`, { id }, { withCredentials: true });
  }
  public Get_consumible_activo_by_idHistoricoConsumibles(id: number) {
    return this.http.post<JSON>(`${baseUrl}/get_consumible_activo_by_idHistoricoConsumibles`, { id }, { withCredentials: true });
  }


  public Guardar_consumible(id: number, idConsumibles_tipos: number, idConsumibles_fabricantes: number,
    nombre: string, descripcion: string, tieneNserie: boolean,
    numContenido: number, vidaUtil: number, fiabilidad: number, diametro: number, longitud: number,
    angulo: number, salto: number, idERP: number, coste: number) {
    return this.http.post<JSON>(`${baseUrl}/Guardar_consumible`, {
      id, idConsumibles_tipos, idConsumibles_fabricantes,
      nombre, descripcion, tieneNserie,
      numContenido, vidaUtil, fiabilidad, diametro, longitud,
      angulo, salto, idERP, coste
    }, { withCredentials: true });
  }
  public Delete_consumible_ids(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/Delete_consumible_ids`, { ids: ids.join() }, { withCredentials: true });
  }

  // CONSUMIBLES CONTENIDO
  public Get_consumibles_contenido(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_consumibles_contenido`, { id }, { withCredentials: true });
  }
  public Guardar_consumible_contenido(id: number, dt: JSON) {
    return this.http.post<JSON>(`${baseUrl}/Guardar_consumible_contenido`, { id, dt }, { withCredentials: true });
  }
  public Delete_consumible_contenido_ids(ids: number[]) {
    return this.http.post<JSON>(`${baseUrl}/Delete_consumible_contenido_ids`, { ids: ids.join() }, { withCredentials: true });
  }


  public Get_consumible_nSerie(id: number) {
    return this.http.post<number>(`${baseUrl}/Get_consumible_nSerie`, { id }, { withCredentials: true });
  }
  public Dar_De_Baja_Hijos(id: number) {
    return this.http.post<number>(`${baseUrl}/Dar_De_Baja_Hijos`, { id }, { withCredentials: true });
  }
  public Get_consumibles_ids(n: number, idConsumible: number, idAlmacen: number) {
    return this.http.post<any>(`${baseUrl}/Get_consumibles_ids`, { n, idConsumible, idAlmacen }, { withCredentials: true });
  }
  public Get_historico_consumibles_para_baja_seleccion(idConsumible: number, idTipo: number, idAlmacen: number) {
    return this.http.post<any>(`${baseUrl}/Get_historico_consumibles_para_baja_seleccion`, { idConsumible, idTipo, idAlmacen }, { withCredentials: true });
  }
  public get_ids_hijos_activos(id: number) {
    return this.http.post<any>(`${baseUrl}/get_ids_hijos_activos`, { id }, { withCredentials: true });
  }
  public Get_Consumible_Con_Hijos(id: number) {
    return this.http.post<any>(`${baseUrl}/Get_Consumible_Con_Hijos`, { id }, { withCredentials: true });
  }



  //HISTORICO
  public Get_historico_consumibles(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_historico_consumibles`, { id }, { withCredentials: true });
  }



  public Get_historico_consumibles_grid_by_consumible(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_historico_consumibles_grid_by_consumible`, { id }, { withCredentials: true });
  }
  public Insert_historico_consumibles(idConsumible: number, idAlmacen: number, nSeries: string[]) {
    return this.http.post<JSON>(`${baseUrl}/Insert_historico_consumibles`, { idConsumible, idAlmacen, nSeries }, { withCredentials: true });
  }
  public Deshechar_historico_consumible_ids(ids: number[], eliminarHijos: boolean) {
    return this.http.post<JSON>(`${baseUrl}/Deshechar_historico_consumible_ids`, { ids, eliminarHijos }, { withCredentials: true });
  }
  public Mover_historico_consumibles(ids: number[], idAlmacen: number, posicion: number) {
    return this.http.post<JSON>(`${baseUrl}/Mover_historico_consumibles`, { ids, idAlmacen, posicion }, { withCredentials: true });
  }

  //HISTORICO MOVIMIENTOS
  public Get_historico_consumibles_movimientos(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_historico_consumibles_movimientos`, { id }, { withCredentials: true });
  }


  public Mover_A_Almacen_Hijos(id: number, idAlmacen: number) {
    return this.http.post<JSON>(`${baseUrl}/Mover_A_Almacen_Hijos`, { id, idAlmacen }, { withCredentials: true });
  }
  public Get_almacenes_con_consumibles_activos(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_almacenes_con_consumibles_activos`, { id }, { withCredentials: true });
  }
  public Get_almacenes() {
    return this.http.post<JSON>(`${baseUrl}/Get_almacenes`, { }, { withCredentials: true });
  }

  public Get_consumibles_alta() {
    return this.http.post<JSON>(`${baseUrl}/get_alta`, {}, { withCredentials: true });
  }
  public Get_consumibles_alta_by_IdAlmacen(idAlmacen: number) {
    return this.http.post<JSON>(`${baseUrl}/get_consumibles_alta_by_IdAlmacen`, { idAlmacen }, { withCredentials: true });
  }

  

}
