<!-- LOADING PANEL -->
<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<!-- TODO -->
<form [formGroup]="form" (submit)="onSubmit(contentloading,$event)">
  <!-- DATOS -->
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <!-- PIEZA -->
      <div id="divDatosPieza" class="card">
        <div class="card-header">
          <h3><label>{{ 'pieza' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <!--REFERENCIAS-->
              <div class="form-group">
                <label ID="lReferenciaPieza" class="control-label">{{ 'referencias' | translate }}</label>
                <div class="caja">
                  <kendo-combobox *ngIf="isAddMode" valuePrimitive="true" [allowCustom]="true"
                                  formControlName="referencia" maxlength="80" [data]="referencias"
                                  (selectionChange)="selectionChange($event)" [value]="referencia" class="form-control"
                                  textField="referencia" valueField="referencia" [kendoDropDownFilter]="{operator: 'contains'}"
                                  [class.is-invalid]="submitted && f.referencia.errors">
                  </kendo-combobox>
                  <kendo-textbox *ngIf="!isAddMode" maxlength="80" formControlName="referencia" [class.is-invalid]="submitted && f.referencia.errors" [disabled]="!espieza"></kendo-textbox>
                  <div *ngIf="submitted && f.referencia.errors" class="invalid-feedback">
                    <div *ngIf="f.referencia.errors.required">{{ 'referenciaEsRequerida' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <!--VERSION-->
              <div class="form-group">
                <label class="control-label"> {{ 'version' | translate }}</label>
                <div class="caja">
                  <kendo-textbox maxlength="80" formControlName="textoVersion" [disabled]="!espieza"></kendo-textbox>
                </div>
              </div>
            </div>
            <div class="col-5">
              <!--NOMBRE-->
              <div class="form-group">
                <label ID="lNombrePieza" class="control-label"> {{ 'nombre' | translate }}</label>
                <div class="caja">
                  <kendo-textbox maxlength="80" formControlName="nombre" [disabled]="!espieza" [class.is-invalid]="submitted && f.nombre.errors"></kendo-textbox>
                  <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                    <div *ngIf="f.nombre.errors.required">{{ 'nombreEsrequerido' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <!--NUMERO DE PLANO-->
              <div class="form-group">
                <label ID="lnumeroPlano" class="control-label"> {{ 'numplano' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="numplano" maxlength="50" [disabled]="!espieza" [class.is-invalid]="submitted && f.numplano.errors"></kendo-textbox>
                  <div *ngIf="submitted && f.numplano.errors" class="invalid-feedback">
                    <div *ngIf="f.numplano.errors.required">{{ 'numplanoRequerido' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2">
              <!--ID ERP-->
              <div class="form-group">
                <label ID="lERPPieza" class="control-label"> {{ 'iderp' | translate }}</label>
                <div class="caja">
                  <kendo-textbox formControlName="idERPpieza" maxlength="500" [disabled]="!espieza">
                  </kendo-textbox>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!--TIPO DE PIEZA-->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'tipopieza' | translate }}</label>
                <div class="caja">
                  <kendo-combobox [data]="tipoPiezaLista" [textField]="'nombre'" class="form-control"
                                  [valueField]="'id'" formControlName="tipo_pieza" [(ngModel)]="tipoPieza"
                                  (valueChange)="tipo_pieza_valueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"
                                  [disabled]="!espieza">
                  </kendo-combobox>
                </div>
              </div>
            </div>
            <!--CHK ES PIEZA-->
            <div class="col">
              <div class="form-group pe-none">
                <label class="control-label">{{ 'tipo' | translate }}</label>
                <div class="caja">
                  <div class="btn mr-1" [class.btn-success]="espieza" [class.btn-outline-success]="!espieza">
                    <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btnPieza()" [checked]="espieza" kendoRadioButton [disabled]="true">
                    <label class="k-radio-label" for="btnEstimado">{{ 'pieza' | translate }}</label>
                  </div>
                  <div *ngIf="!user.ocultarParte" class="btn mr-1" [class.btn-success]="!espieza" [class.btn-outline-success]="espieza">
                    <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btnParte()" [checked]="!espieza" kendoRadioButton [disabled]="true">
                    <label class="k-radio-label" for="btnPredictivo">{{ 'parte' | translate }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- ATRIBUTOS -->
          <div class="col" [style.width]="'20%'">
            <div class="form-group">
              <kendo-label text="{{ 'atributos' | translate }}">
                
                <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedAtributo"
                                     [textField]="'nombre'"
                                     [valueField]="'id'"
                                     [autoClose]="false"
                                     formControlName="atributos"
                                     [(ngModel)]="atributosSeleccionados"
                                     placeholder="{{ 'atributos' | translate }}"
                                     [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                      <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'atributosSeleccionados' | translate }}</ng-container>
                    </ng-template>
                    <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                  </kendo-multiselect>
                  
              </kendo-label>
            </div>
          </div>
          </div>
          <div class="row">
            <!-- PESO -->
            <div class="col-md-4">
              <div class="form-group">
                <label class="control-label">{{ 'peso' | translate }}</label><br>
                  <kendo-numerictextbox format="#.### kg" formControlName="peso"></kendo-numerictextbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PARTE -->
      <div class="" *ngIf="!espieza">
        <div id="divDatosParte" class="card ">
          <div class="card-body">
            <div class="clearfix">
              <div class="float-left mr-2">
                <!--REFERENCIA PARTE-->
                <div class="form-group">
                  <label ID="lReferenciaParte" class="control-label">{{ 'referenciaparte' | translate }}</label>
                  <div class="caja">
                    <kendo-textbox formControlName="refparte" maxlength="50" [class.is-invalid]="submitted && errorReferenciaParte"></kendo-textbox>
                    <div *ngIf="submitted && errorReferenciaParte" class="invalid-feedback">
                      <div *ngIf="errorReferenciaParte">{{ 'referenciaRequerida' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left mr-2">
                <!--PARTE-->
                <div class="form-group">
                  <label ID="lNombreParte" class="control-label">{{ 'parte' | translate }}</label>
                  <div class="caja">
                    <kendo-textbox formControlName="parte" maxlength="80" [class.is-invalid]="submitted && errorNombreParte"></kendo-textbox>
                    <div *ngIf="submitted && errorNombreParte" class="invalid-feedback">
                      <div *ngIf="errorNombreParte">{{ 'parteRequerida' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left mr-2">
                <!--ORDEN-->
                <div class="form-group">
                  <label ID="lOrden" class="control-label">{{ 'orden' | translate }}</label>
                  <div class="caja">
                    <kendo-numerictextbox formControlName="orden" [decimals]="0" [min]="0" [format]="'n'" [class.is-invalid]="submitted && errorOrdenParte" [autoCorrect]="autoCorrect">
                    </kendo-numerictextbox>
                    <div *ngIf="submitted && errorOrdenParte" class="invalid-feedback">
                      <div *ngIf="errorOrdenParte">{{ 'ordenRequerido' | translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-left mr-2">
                <!--ID ERP parte-->
                <div class="form-group">
                  <label ID="lERPParte" class="control-label"> {{ 'iderp' | translate }}</label>
                  <div class="caja">
                    <kendo-textbox formControlName="idERPparte" maxlength="50"></kendo-textbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
      <!--DIV DATOS-->
      <div id="divDatosGlobales" class="card" *ngIf=" showDetails ">
        <div class="card-header">
          <h3><label>{{ 'datos' | translate }}</label></h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <!--CANTIDAD-->
            <div class="form-group col">
              <label class="control-label">{{ 'cantidad' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="cantidad" (valueChange)="onCantidadChange($event)" [decimals]="0" [min]="1" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
            <!--CANTIDAD AGRUPADA-->
            <div class="form-group col">
              <label class="control-label">{{ 'cantidadagrupada' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="cantidadAgrupada" (valueChange)="onCantidadChange($event)" [decimals]="0" [min]="0" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
            <!--HECHOS-->
            <div class="form-group col">
              <label class="control-label">{{ 'hechos' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="hechos" [decimals]="0" [min]="0" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
            <!--COSTE-->
            <div class="form-group col">
              <label class="control-label">{{ 'coste' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="coste" [decimals]="decimals" [min]="0" [format]="'n2'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
            <!--DUREZA-->
            <div class="form-group col">
              <label class="control-label">{{ 'dureza' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="dureza" [decimals]="0" [min]="0" [max]="100" [format]="'n'" [autoCorrect]="autoCorrect"> </kendo-numerictextbox>
              </div>
            </div>
          </div>
          <div class="form-row">
            <!--USA LOTES-->
            <div class="form-group mr-2">
              <label class="control-label">{{ 'usaLotes' | translate }}</label>
              <div class="caja">
                <kendo-switch formControlName="usaLotes" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
              </div>
            </div>
            <!--RUTAS MULTIPLES-->
            <div class="form-group mr-2">
              <label class="control-label">{{ 'tienerutas' | translate }}</label>
              <div class="caja">
                <kendo-switch id="activarLink" formControlName="tienerutas" [onLabel]="' '" [offLabel]="' '" (valueChange)="tieneRutasChange()" [(ngModel)]="tienerutas"></kendo-switch>
              </div>
            </div>
            <!--TERMINADO-->
            <div class="form-group mr-3">
              <label class="control-label">{{ 'terminado' | translate }}</label>
              <div class="caja">
                <kendo-switch formControlName="terminado" [onLabel]="' '" [offLabel]="' '" [(ngModel)]="terminado"> </kendo-switch>
              </div>
            </div>
            <!--cantidad Lote-->
            <div class="form-group col">
              <label class="control-label">{{ 'cantidadLote' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox formControlName="cantidadLote" [decimals]="0" [min]="0" [format]="'n'" [autoCorrect]="autoCorrect"> </kendo-numerictextbox>
              </div>
            </div>
          </div>
          <div class="form-row">
            <!--IMAGEN-->
            <div class="img-up">
              <label ID="lCaracteristicas" Class="">{{ 'imagen' | translate }}</label>
              <ng-template #noImageFound>
                <img src="fallbackImage.png">
              </ng-template>
              <div>
                <div *ngIf="imagePreviews.length; else initImage">
                  <img *ngFor="let image of imagePreviews" [src]="image.src" alt="image preview" style="width: 200px; margin: 10px;" />
                </div>
                <ng-template #initImage>
                  <img [src]="imageToShow" style="max-height: 113px">
                </ng-template>
                <div class="" style="margin-bottom: 4em;">
                  <div class="caja">
                    <kendo-fileselect formControlName="archivo" class="form-control" [restrictions]="restrictions"
                                      [multiple]="false" (select)="selectEventHandler($event)">
                      <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                             clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                             dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                             externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                             fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                             fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                             filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                             filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                             filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                             headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                             headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                             headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                             invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                             invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                             invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                                             remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                             retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                             uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                      </kendo-upload-messages>
                    </kendo-fileselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--DESCRIPCION-->
          <div class="form-group">
            <label ID="lDescripcion" class="control-label  descripcion-label-mant">{{ 'descripcion' | translate }}</label>
            <textarea kendoTextArea formControlName="tadescripcion" [autoSize]="false" maxlength="500"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- TABS -->
  <kendo-tabstrip *ngIf="!isAddMode" #tabstrip [keepTabContent]="true" (tabSelect)="onTabSelected($event)">
    <!-- OPERACIONES -->
    <kendo-tabstrip-tab *ngIf="(!espieza || (espieza && (comboPartes == undefined || comboPartes.length == 0 ))) && !form.value.tienerutas" id="taboperaciones" title="{{ 'operaciones' | translate }}">
      <ng-template kendoTabContent>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
          <kendo-grid [height]="410" [data]="operaciones" (cellClick)="cellClick($event)" kendoGridSelectBy="id"
                      [selectedKeys]="operacionesseleccionados" filterable="menu" [navigable]="true" [sortable]="true"
                      [resizable]="true">
            <ng-template kendoGridToolbarTemplate position="top">
              <button id="btneditar" [disabled]="!botonesActivados" Class="btnEditatu btn btn-success btn-sm mr-1" (click)="onClickEditarOperacion($event)">{{ 'editar' | translate }}</button>
              <a id="btnnuevo" (click)="onClickNuevaOperacion()" Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</a>
              <a id="btnnuevopred" (click)="onClickNuevaOperacionPredefinida()" Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'anadirpred' | translate }}</a>
              <button id="btneliminar" [disabled]="!botonesActivados || this.user.piezas<2" Class="btnEzabatu btn btn-danger btn-sm mr-1" 
                     (click)="onClickEliminarOperacion(content, $event)">{{ 'eliminar' | translate }}</button>
              <button id="btnexportar" [disabled]="!botonesActivados" kendoGridExcelCommand Class="btnExcel btn btn-excel btn-sm mr-1">{{ 'exportarexcel' | translate }}</button>
              <button id="btnterminar" [disabled]="!botonesActivados" Class="btnBerria btn btn-primary btn-sm mr-1" (click)="onClickTerminarOperacion($event)">{{ 'terminar' | translate }}</button>
              <div class="form-group mb-0 float-right"
                    style="float: right; margin-left: 20px; margin-right: 20px">
                    <div class="form-row">
                        <label class="control-label mt-1 mr-2">{{ 'importarCotas' | translate }}</label>
                        <kendo-upload (upload)="onUploadButtonClick($event,contentloadingupload)"
                          [restrictions]="{allowedExtensions: ['.xlsx']  }" [(value)]="myFiles2"
                          >
                          <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                            clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                            dropFilesHere="{{ 'dropFilesHere' | translate }}"
                            externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                            fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                            fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                            filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                            filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                            filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                            headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                            headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                            headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                            invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                            invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                            invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                            pause="{{ 'pause' | translate }}" remove="{{ 'remove' | translate }}"
                            resume="{{ 'resume' | translate }}" retry="{{ 'retry' | translate }}"
                            select="{{ 'select' | translate }}"
                            uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                          </kendo-upload-messages>
                        </kendo-upload>
                    </div>
              </div>
            </ng-template>
            <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
            <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="16%">
            </kendo-grid-column>
            <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="16%"
            class="celda-tooltip-externo">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.maquina!=null && dataItem.maquinasIm!=null" class="tooltip-consumibles-contenido">
                  <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                    <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo"
                      style="background-color:white!important;"
                      [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                  </ng-container>
                  <span class="tooltiptext">
                    <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                      <label *ngIf="maquina!='undefined'">
                        {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
                      </label>
                      <label *ngIf="maquina=='undefined'">
                        {{'desconocido' | translate}}
                      </label>
                    </ng-container>
                  </span>
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
            </kendo-grid-column>
            <kendo-grid-column field="fechaEntrega" title="{{ 'fechaentrega' | translate }}" width="10%" [headerStyle]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaEntrega | kendoDate }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%"> </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}" width="17%"> </kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}" width="17%"> </kendo-grid-column>
            <kendo-grid-column field="hechos" title="{{ 'hechos' | translate }}" width="8%"> </kendo-grid-column>
            <kendo-grid-column field="terminado" width="5%" title="{{ 't' | translate }} " [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" [checked]="dataItem.terminado" disabled />
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="{{ 'operaciones' | translate }}.xlsx"  [fetchData]="allData">
              <kendo-excelexport-column field="orden" title="{{ 'orden' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="secuencia" title="{{ 'secuencia' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="operacion" title="{{ 'operacion' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tipo2" title="{{ 'tipoOperacion' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="fechaEntrega" title="{{ 'fechaentrega' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="hechos" title="{{ 'hechos' | translate }}"> </kendo-excelexport-column>
              <kendo-excelexport-column field="terminado" title="{{ 'terminado' | translate }}"> </kendo-excelexport-column>
            </kendo-grid-excel>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- RUTAS -->
    <kendo-tabstrip-tab *ngIf="(!espieza || (espieza && (comboPartes == undefined || comboPartes.length == 0 ))) && form.value.tienerutas" id="tabrutas" title="{{ 'rutas' | translate }}">
      <ng-template kendoTabContent>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
          <kendo-grid [height]="410" [data]="rutas" (cellClick)="cellClickRutas($event)" kendoGridSelectBy="id"
                      [selectedKeys]="rutasseleccionados" filterable="menu" [navigable]="true" [sortable]="true"
                      [resizable]="true">
            <ng-template kendoGridToolbarTemplate position="top">
              <button id="btneditar" Class="btnEditatu btn btn-success btn-sm mr-1" (click)="onClickEditarRuta($event)">{{ 'editar' | translate }}</button>
              <button id="btnnuevo" (click)="onClickNuevaRuta($event)" Class="btnBerria btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate }}</button>
              <button id="btneliminar" [disabled]="unicaRutaPrincipal || this.user.piezas<2" 
                       Class="btnEzabatu btn btn-danger btn-sm mr-1" (click)="onClickEliminarRuta(contenteliminarRuta, $event)">{{ 'eliminar' | translate }}
              </button>
            </ng-template>
            <kendo-grid-checkbox-column showSelectAll="true" width="10%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="20%"> </kendo-grid-column>
            <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="100%"> </kendo-grid-column>
            <kendo-grid-column field="principal" width="15%" title="{{ 'principal' | translate }} " [headerStyle]="{'text-align': 'center'}" [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" [checked]="dataItem.principal" disabled />
              </ng-template>
            </kendo-grid-column>
            <!--<kendo-grid-excel fileName="{{'operaciones'| translate }}.xlsx"></kendo-grid-excel>-->
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- DEPENDENCIAS -->
    <kendo-tabstrip-tab *ngIf="espieza && !(comboPartes == undefined || comboPartes.length == 0 )" id="tabdependencias" title="{{ 'dependencias' | translate }}">
      <ng-template kendoTabContent [height]="410">
        <!-- TITULOS -->
        <div class="clearfix">
          <div class="dependencias-titulo-orden">
            <label class=""></label>
          </div>
          <div class="dependencias-titulo-padre">
            <label class="">{{ 'parte' | translate }}</label>
          </div>
          <div class="dependencias-titulo-hijo">
            <label class="">{{ 'subParte' | translate }}</label>
          </div>
          <div class="dependencias-titulo-cantidad">
            <label class="">{{ 'cantidad' | translate }}</label>
          </div>
        </div>
        <!-- TABLA DE CONTENIDO -->
        <div *ngIf="espieza && dependenciasPieza.length > 0">
          <div *ngFor="let dependencia of dependenciasPieza" class="clearfix">
            <div class="dependencias-orden" >
              <label class="dependencias-orden-label">{{ indexOf(dependenciasPieza, dependencia) + 1 }} . </label>
            </div>
            <div class="dependencias-padre" >
              <kendo-dropdownlist (selectionChange)="parteDependenciaSeleccionada('padre', dependencia, $event); dependencia.id = -1;" [data]="cargaropcionesCombo('padre',dependencia)" [textField]="'nombre'" [valueField]="'id'" [(value)]="dependencia.padre"></kendo-dropdownlist>
            </div>
            <div class="dependencias-hijo" >
              <kendo-dropdownlist (selectionChange)="parteDependenciaSeleccionada('hijo', dependencia,  $event); dependencia.id = -1;"  [data]="cargaropcionesCombo('hijo', dependencia)" [textField]="'nombre'" [valueField]="'id'" [(value)]="dependencia.hijo"></kendo-dropdownlist>
            </div>
            <div class="dependencias-cantidad" >
              <kendo-numerictextbox (valueChange)="dependencia.id = -1;"  format="0" [min]="-9999999" [max]="9999999" [(value)]="dependencia.cantidad"></kendo-numerictextbox>
            </div>
            <!-- ELIMINAR -->
            <button *ngIf="dependencia.padre.id > 0 && dependencia.hijo.id > 0" Class="flt-eliminar dependencias-eliminar mt-1" 
                    (click)="borrarDependencia(dependencia)" [disabled]="this.user.piezas<2"> <i class="fas fa-times"></i> </button>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- CAMBIOS -->
    <kendo-tabstrip-tab id="tabhistoricocambios" title="{{ 'historicocambios' | translate }}" [selected]="false">
      <ng-template kendoTabContent>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid [height]="410" [data]="historicocambios" filterable="menu" [navigable]="true" [sortable]="true"
                      [resizable]="true">
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="90">
            </kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="20%">
            </kendo-grid-column>
            <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="20%">
            </kendo-grid-column>
            <kendo-grid-column field="campo" title="{{ 'campo' | translate }}" width="10%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.campo.split("_")[0] | translate }} {{ dataItem.campo.split("_")[1] }}
                  {{ dataItem.campo.split("_")[2] | translate }} {{ dataItem.campo.split("_")[3] | translate }}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="valorAnterior" title="{{ 'valoranterior' | translate }}" width="15%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.valorAnteriorTrad == 'traducir'">{{ dataItem.valorAnterior | translate}}</span>
                <span *ngIf="dataItem.valorAnteriorTrad == 'fecha'">{{ dataItem.valorAnterior | kendoDate}}</span>
                <span *ngIf="dataItem.valorAnteriorTrad != 'traducir' && dataItem.valorAnteriorTrad != 'fecha'">{{ dataItem.valorAnterior}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="valorNuevo" title="{{ 'valornuevo' | translate }}" width="15%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.valorNuevoTrad == 'traducir'">{{ dataItem.valorNuevo | translate}}</span>
                <span *ngIf="dataItem.valorNuevoTrad == 'fecha'">{{ dataItem.valorNuevo | kendoDate}}</span>
                <span *ngIf="dataItem.valorNuevoTrad != 'traducir' && dataItem.valorNuevoTrad != 'fecha'">{{ dataItem.valorNuevo}}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="fecha" title="{{ 'fecha' | translate }}" width="10%">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.fecha | kendoDate }} {{ dataItem.fecha | kendoDate:'HH:mm:ss' }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>

          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- BOOM -->
    <kendo-tabstrip-tab id="tabbom" title="{{ 'BOM' | translate }}">
      <ng-template kendoTabContent>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid [height]="410" [data]="bom" filterable="menu" [navigable]="true" [sortable]="true"
                      [resizable]="true">
            <ng-template kendoGridToolbarTemplate position="top" *ngIf="!tenemosBOM">
              <kendo-upload (upload)="onUploadBOMButtonClick($event,contentloadingupload)">
                <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                       clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                       dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                       externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                       fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                       fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                       filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                       filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                       filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                       headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                       headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                       headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                       invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                       invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                       invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                                       remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                       retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                       uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                </kendo-upload-messages>
              </kendo-upload>
            </ng-template>
            <kendo-grid-checkbox-column showSelectAll="true" width="4%"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="3%">
            </kendo-grid-column>
            <kendo-grid-column field="nombre" title="{{ 'nombre' | translate }}" width="44%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
            </kendo-grid-column>
            <kendo-grid-column field="referencia" title="{{ 'referencia' | translate }}" width="10%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
            </kendo-grid-column>
            <kendo-grid-column field="fichero" title="{{ 'fichero' | translate }}" width="10%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
            </kendo-grid-column>
            <kendo-grid-column field="necesarios" title="{{ 'necesarios' | translate }}" width="7%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'right'}">
            </kendo-grid-column>
            <kendo-grid-column field="usados" title="{{ 'usados' | translate }}" width="7%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'right'}">
            </kendo-grid-column>
            <kendo-grid-column field="resto" title="{{ 'resto' | translate }}" [headerStyle]="{'text-align': 'left'}"
                               [style]="{'text-align': 'right'}" width="7%">
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- GESTION DOCUMENTAL -->
    <kendo-tabstrip-tab id="tabgestiondocumental" title="{{ 'gestiondocumental' | translate }}">
      <ng-template kendoTabContent>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid [kendoGridBinding]="gestiondocumental" [sortable]="true" kendoGridSelectBy="id"
                      [navigable]="true" filterable="menu" (cellClick)="onCellClickGestionDocumental($event)"
                      [selectedKeys]="mySelectionGestionDocumental">
            <ng-template kendoGridToolbarTemplate position="top">
              <button kendoButton (click)="onClickEditarGestionDocumental($event)"
                      class="btn mr-1 btn-success btn-sm mr-1"> {{ 'editar' | translate}} </button>
              <button kendoButton (click)="onClickNuevoGestionDocumental($event)" class="btn mr-1 btn-primary btn-sm mr-1">
                {{ 'nuevo' | translate}} </button>
              <button kendoButton (click)="onClickEliminarGestionDocumental($event)"
                      class="btn mr-1 btn-danger btn-sm mr-1" 
                      [disabled]="loadingGestionDocumental || (gestiondocumental && gestiondocumental.length == 0) || this.user.piezas<2"> 
                        {{ 'eliminar' | translate}} </button>
            </ng-template>
            <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
            <kendo-grid-column width="25%" field="nombre" title="{{ 'nombre' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column width="25%" field="fichero" title="{{ 'fichero' | translate}}">
            </kendo-grid-column>
            <kendo-grid-column width="45%" field="" title="">
            </kendo-grid-column>
            <!--<kendo-grid-column [style]="{'text-align': 'right'}" width="10%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn-icon" (click)="viewPDF(dataItem)"><span
                      class="k-icon k-i-file-pdf"></span></button>
                </ng-template>
              </kendo-grid-column>-->
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>

        <div *ngIf="loadingGestionDocumental" class="k-i-loading"></div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- N SERIES -->
    <kendo-tabstrip-tab id="tabnserie" title="{{ 'series' | translate }}">
      <ng-template kendoTabContent>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid [height]="410" [data]="series" [rowClass]="rowCallback" [selectedKeys]="mySelectionSerie" 
                      [selectable]="{enabled: true, checkboxOnly: true}" kendoGridSelectBy="id" 
                      filterable="menu" [navigable]="true" [sortable]="true"
                      [resizable]="true" (cellClick)="onCellClickSerie($event)"  >
            <ng-template kendoGridToolbarTemplate position="top">
              <button kendoButton (click)="onClickNuevoSerie($event)" class="btn mr-1 btn-primary btn-sm mr-1">
                {{ 'nuevo' | translate}} </button>
              <button kendoButton (click)="onClickAutoGenerarSeries(popupAutogenerarSeries,$event)" class="btn mr-1 btn-success btn-sm mr-1">
                {{ 'autogenerar' | translate}} </button>
              <button kendoButton (click)="onClickEliminarSerie(popupBorrarSerie, $event)"
                      class="btn mr-1 btn-danger btn-sm mr-1" 
                      [disabled]="loadingSeries || (serie && serie.length == 0) || this.user.piezas<2"> 
                        {{ 'eliminar' | translate}} </button>
              <kendo-upload class="form-control upload-sinBotones"  *ngIf="!tenemosSeries" [autoUpload]="false"
                            (select)="onUploadSeriesButtonClick($event,contentloadingupload)">
                <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                       clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                       dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                       externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                       fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                       fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                       filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                       filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                       filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                       headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                       headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                       headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                       invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                       invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                       invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}" pause="{{ 'pause' | translate }}"
                                       remove="{{ 'remove' | translate }}" resume="{{ 'resume' | translate }}"
                                       retry="{{ 'retry' | translate }}" select="{{ 'select' | translate }}"
                                       uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                </kendo-upload-messages>
              </kendo-upload>
            </ng-template>
            <kendo-grid-checkbox-column showSelectAll="true" width="4%" [headerStyle]="{'text-align': 'left'}"
                                        [style]="{'text-align': 'center'}"></kendo-grid-checkbox-column>
            <kendo-grid-column field="id" hidden="hidden" title="ID" width="90">
            </kendo-grid-column>
            <kendo-grid-column field="numero" title="{{ 'numero' | translate }}" width="10%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'right'}">
            </kendo-grid-column>
            <kendo-grid-column field="numeroSerie" title="{{ 'numeroserie' | translate }}" width="43%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                  <kendo-textbox [(value)]="dataItem.numeroSerie">
                                  </kendo-textbox>
                                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="numeroSerieCliente" title="{{ 'numeroseriecliente' | translate }}" width="43%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                  <kendo-textbox [(value)]="dataItem.numeroSerieCliente">
                                  </kendo-textbox>
                                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="colada" title="{{ 'colada' | translate }}" width="43%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
                               <ng-template kendoGridCellTemplate let-dataItem>
                                  <kendo-textbox [(value)]="dataItem.colada">
                                  </kendo-textbox>
                                </ng-template>
              </kendo-grid-column>
            <kendo-grid-column field="lote" title="{{ 'lote' | translate }}" width="43%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'left'}">
                               <ng-template kendoGridCellTemplate let-dataItem>
                                <kendo-textbox [(value)]="dataItem.lote">
                                </kendo-textbox>
                              </ng-template>
            </kendo-grid-column> <!-- EDITABLE -->
            <kendo-grid-column field="estadoURL" title="{{ 'estado' | translate }}" width="10%"
                               [headerStyle]="{'text-align': 'left'}" [style]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <i class="{{dataItem.estadoURL}}"></i>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <!-- TAB LIMITES -->
    <kendo-tabstrip-tab id="tablimites" title="{{ 'limites' | translate }}">
      <ng-template kendoTabContent>
        <!--DIV LIMITES-->
        <div id="DIVLimites">

          <label ID="LLimites" class="control-label bloque-titulo"></label>
          <div class="form-group">
            <div class="caja">
              <div class="clearfix">
                <!-- FECHA DE INICIO -->
                <div class="float-left mr-2">
                  <div class="form-group">
                    <label ID="Lfechaini" class="control-label">{{ 'fechainicio' | translate}}</label>
                    <div class="caja">
                      <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..." formControlName="fechainicio"
                                        id="rntfechainicio" class="form-control">
                      </kendo-datepicker>
                    </div>
                  </div>
                </div>
                <!-- FECHA FINAL -->
                <div class="float-left mr-2">
                  <div class="form-group">
                    <label ID="Lfechafin" class="control-label">{{ 'fechaentrega' | translate}}</label>
                    <div class="caja">
                      <kendo-datepicker placeholder="{{ 'fechaentregap' | translate}}..." formControlName="fechafin"
                                        id="rntfechafin" class="form-control">
                      </kendo-datepicker>
                    </div>
                  </div>
                </div>
                <!-- PRIORIDAD -->
                <div class="float-left mr-2">
                  <div class="form-group">
                    <label ID="Lprioridad" class="control-label">{{ 'prioridad' | translate }}</label>
                    <div class="caja">
                      <kendo-numerictextbox [(value)]="prioridad" formControlName="prioridad" [format]="'n'"
                                            [decimals]="0" [min]="0" [max]="100" [autoCorrect]="autoCorrect">
                      </kendo-numerictextbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
  <!-- BOTONES -->
  <div class="form-group mt-2">
    <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.piezas<2">{{ 'guardar' | translate}}</button>
    <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="Atras()">{{ 'cancelar' | translate}}</button>
    <button class="btn btn-copiar btn-sm mr-1" (click)="onClickDuplicarPieza(contentloading, $event)" 
              [disabled]="isAddMode || this.user.piezas<2">{{ 'duplicar' | translate}}</button>
  </div>
</form>
<!-- POPUPs -->
<!-- POPUP: -->
<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="EliminarOperacion(contentloading)" [disabled]="this.user.piezas<2"> {{ 'si' | translate }}</button>
  </div>
</ng-template>
<!-- POPUPs -->
<ng-template #contenteliminarRuta let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="EliminarRuta(contentloading)" [disabled]="this.user.piezas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP: -->
<ng-template #contenteliminarpieza let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="EliminarPieza(contentloading)" [disabled]="this.user.piezas<2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP: -->
<ng-template #contentloading let-modal>

  <div class="modal-body">
    <p id="text_loading_popup"></p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>
<!-- POPUP: -->
<ng-template #contentloadingupload let-modal>

  <div class="modal-body">
    <p>{{ 'importando' | translate }}</p>

    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'importando' | translate }}...</span>
    </div>
  </div>
</ng-template>
<!-- POPUP: -->
<ng-template #anadirOperacionPred let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-grid style="height: 780px" [kendoGridBinding]="operacionesPred" [sortable]="true" [navigable]="true"
                filterable="menu" [resizable]="true" kendoGridSelectBy="id" [selectedKeys]="operacionesPredSelected"
                scrollable="virtual" [rowHeight]="36" [pageSize]="50" [height]="500">
                <kendo-grid-checkbox-column showSelectAll="true" width="5%"></kendo-grid-checkbox-column>
                <kendo-grid-column field="id" hidden="hidden" title="ID" width="90"></kendo-grid-column>
                <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="secuencia" title="{{ 'secuencia' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
                <kendo-grid-column field="operacion" title="{{ 'nombre' | translate }}" width="16%">
                </kendo-grid-column>
                <kendo-grid-column field="maquina" title="{{ 'maquina' | translate }}" width="16%"
                class="celda-tooltip-externo">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.maquina!=null" class="tooltip-consumibles-contenido">
                      <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                        <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo"
                          style="background-color:white!important;"
                          [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                        <span *ngIf="dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined'" class="grid-circulo"
                          [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'">{{maquina}}</span>
                      </ng-container>
                      <span class="tooltiptext">
                        <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                          <label *ngIf="maquina!='undefined'">
                            {{maquina}} ({{dataItem.prioridadMaquinasAux[maquina]}})
                          </label>
                          <label *ngIf="maquina=='undefined'">
                            {{'desconocido' | translate}}
                          </label>
                        </ng-container>
                      </span>
                    </span>
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="tipo2" title="{{ 'tipoOperacion' | translate }}" width="10%">
                </kendo-grid-column>
                <kendo-grid-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate }}" width="17%"> </kendo-grid-column>
                <kendo-grid-column field="tiempoEstimadoPreparacion" title="{{ 'tiempoEstimadoPreparacion' | translate }}" width="17%"> </kendo-grid-column>
                <kendo-grid-column field="tiempoEstimadoTotal" title="{{ 'tiempoEstimadoTotal' | translate }}" width="17%"> </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click');onClickCancelar()">{{ 'cancelar'
      | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="onClickAceptarAnadirPred()" [disabled]="this.user.piezas<2">{{ 'aceptar' |
      translate}}</button>
  </div>
</ng-template>
<!-- POPUP: -->
<ng-template #popupBorrarDocumentos let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntaeliminarpopup' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="btnBorrarDocumentos()" [disabled]="this.user.piezas<2">{{ 'aceptar' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP: -->
<ng-template #popupBorrarSerie let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p id="titulo_modal_pregunta">{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="EliminarSerie(contentloading)" [disabled]="this.user.piezas<2"> {{ 'si' | translate }}</button>
  </div>
</ng-template>


<!-- POPUP: -->
<ng-template #popupAutogenerarSeries let-modal>
  
          
  <form [formGroup]="formAutogenerar"> 


    <div class="modal-header">
      <h4 class="modal-title">{{ 'autogenerartitulo' | translate }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <!--COLADA-->
              <div class="form-group">
                <label ID="lColada" class="control-label">{{ 'colada' | translate }}</label>
                <div class="caja">
                  <kendo-textbox  maxlength="80"  formControlName="coladaF" ></kendo-textbox>
                </div>
              </div>
            </div>
            <div class="col-6">
              <!--LOTE-->
              <div class="form-group">
                <label ID="lLote" class="control-label">{{ 'lote' | translate }}</label>
                <div class="caja">
                  <kendo-textbox  maxlength="80"  formControlName="loteF" ></kendo-textbox>
                </div>
              </div>
            </div>
          </div>
          
              <!--CANTIDAD-->
          <div class="row">
            <div class="form-group col">
              <label class="control-label">{{ 'cantidad' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox  formControlName="cantidadF" [decimals]="0" [min]="1" [max]="this.form.value.cantidad - this.series.length" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
          </div>

          <h4 class="modal-title mt-3 mb-2">Patrón</h4>

          <!--NUMERO SERIE-->
          <div class="row">
            <div class="col-6" style="margin-left: 26px;">
              <div class="form-group">
                <label ID="lNumeroSerie" class="control-label">{{ 'numeroserie' | translate }}</label>
                <div class="d-flex align-items-center">
                  <kendo-textbox class="mr-2" maxlength="80" formControlName="numeroserieF1" (keydown)="preventBackspace($event)" ></kendo-textbox>
                </div>
              </div>
            </div>
            <div>
              <label ID="lNumeroSerieDigitos" class="control-label">{{ 'digitos' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox  formControlName="cantidadDigitosNSF" [decimals]="0" [min]="1"  [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
          </div>

          <!--NUMERO SERIE CLIENTE-->
          <div class="d-flex align-items-center" style="gap: 10px;">
            <div class=" d-flex align-items-center justify-content-center">
              <input style="margin-top: 8px;" type="checkbox" formControlName="checkboxNumeroSerieClienteF" id="preventBackspaceCheckbox" class="h-100" (change)="handleCheckboxNumeroSerieClienteChange($event)">
            </div>
            <div class="col-6">
              <div class="form-group">
                <label ID="lNumeroSerieCliente" class="control-label">{{ 'numeroseriecliente' | translate }}</label>
                <div class="d-flex align-items-center">
                  <kendo-textbox class="mr-2" maxlength="80" formControlName="numeroserieclienteF1" (keydown)="preventBackspace($event)" [disabled]="!formAutogenerar.get('checkboxNumeroSerieClienteF').value" ></kendo-textbox>
                </div>
              </div>
            </div>
            <div class="col-6">
              <label ID="lNumeroSerieClienteDigitos" class="control-label">{{ 'digitos' | translate }}</label>
              <div class="caja">
                <kendo-numerictextbox  formControlName="cantidadDigitosNSCF" [decimals]="0" [min]="1" [format]="'n'" [autoCorrect]="autoCorrect">
                </kendo-numerictextbox>
              </div>
            </div>
          </div>

          
          <h5 class="text-nota ">Nota: El símbolo '*' es donde irá el número identificativo de cada número de serie generado. Puedes escribir antes y después de él.</h5>

      <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="autogenerarSeries()"
            [disabled]="this.user.operaciones<2">{{
            'aceptar' | translate }}</button>
          <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' |
            translate
            }}</button>
      </div>
    </div>
          
  </form>

</ng-template>

<ng-template #terminarPiezaOperacion let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntarCerrarOperacion' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="closeElements()" [disabled]="this.user.operaciones<2">{{
      'aceptar' | translate }}</button>
  </div>
</ng-template>

<ng-template #catidadHechasOperacion let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'preguntarCantidadHechasOperacion' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="closeElementsHechas(0)">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="closeElementsHechas(1)" [disabled]="this.user.operaciones<2">{{
      'si' | translate }}</button>
  </div>
</ng-template>

<!-- POPUP: Terminar operacion -->
<ng-template #popupTerminarOperacion let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"></h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body">
      <label>{{'cantidadesTeoricasoActuales' | translate }}</label>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'cancelar' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnTerminarOperacion_CantidadesTeoricas()" [disabled]="this.user.activos<2">{{ 'cantidadesTeoricas' | translate }}</button>
      <button type="button" class="btn btn-primary" (click)="btnTerminarOperacion_CantidadesActuales()" [disabled]="this.user.activos<2">{{ 'cantidadesActuales' | translate }}</button>
  </div>
</ng-template>

