import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { HistoricoAlarmasService } from '@app/_services/historicoAlarmas.service';
import { UsuariosService, HistoricoParametrosService } from '@app/_services';
import { Usuario, ElementoCombo } from '@app/_models';
import { MaquinasService} from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';

import * as moment from 'moment';

@Component({
  selector: 'app-historicoparametros',
  templateUrl: 'historicoparametros.component.html'
})

export class HistoricoParametrosComponent {

  user = this.userService.userValue;


  //Grupos Maquinas Combo
  public idGrupoMaquina: number;
  public gruposMaquinas: any;
  public selectedGrupo: ElementoCombo;
  public idGruposMaquinas: number;
  //Maquinas Combo
  public idMaquina: number;
  public Jmaquinas: any;
  public maquinasVisibles: any;
  public selectedMaquinaC: ElementoCombo;
  //FechaIni
  public fechaIniValue: Date;
  public fechaIni: Date;
  //FechaFin
  public fechaFinValue: Date;
  public fechaFin: Date;
  // ejesnombreEje
  public  showEjes: boolean ;
  public idEje: 0;
  public nombreEje: '';
  public ejes: any;
  public selectedEje: ElementoCombo;
  public ejesMaquinas: any;
  public ejesVisibles: any;
  public limitesEjes: any;
  // tiposVariables
  public idTipoVariable: number;
  public tiposVariables: any;
  public selectedVariable: ElementoCombo;
  // Alarmas
  public alarmasSeleccionadas: any;
  //Estado
  public r1: boolean;
  public r2: boolean;
  public r3: boolean;
  public r4: boolean;
  public estadosMantenimientos_DAT: any;
  public selectedTipo: ElementoCombo;
  public loadingGraficoTresEjes: any = false;
  public seleccionados: number[] = [];
  private translate: TranslateService;
  public historicoparametros: any[];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;

  constructor(private historicoParametrosService: HistoricoParametrosService,
    translate: TranslateService,
    private maquinasService: MaquinasService,
    private myFunctions: MyFunctions,
    private userService: UsuariosService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('historicoparametros').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/historicoparametros') {
          this.menuService.titulo = this.translateService.instant('historicoparametros');
          this.historicoParametrosService.GetAll().subscribe((result) => {
            this.historicoparametros = result.data;
          }
          );
        } else {
         
        }
      }
    });
  }
  loadingEnd() {
    if (this.r1 && this.r2 && this.r3 && this.r4) {
      this.loadingGraficoTresEjes = false;
      this.onFilterClck();
    }
  }
  ngOnInit() {
    this.r1 = this.r2 = this.r3 = this.r4 = false;
    this.showEjes = false;
    this.fechaIni = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth() - 1, this.myFunctions.getDateNow().getDate());
    this.fechaFin = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
    this.fechaIniValue = this.fechaIni;
    this.fechaFinValue = this.fechaFin;
    this.idMaquina = -1;
    this.idEje = 0;
    this.nombreEje = '';
    this.idTipoVariable = -1;
    this.idGrupoMaquina = -1;
    this.loadingGraficoTresEjes = false;
    this.maquinasService.getGruposMaquinasTodos().subscribe((result) => {
      this.gruposMaquinas = result.data;
      this.idGruposMaquinas = null;
      this.gruposMaquinas.forEach((element) => {
        element['nombre'] = this.translate.instant(element['nombre']);
      });
      this.selectedGrupo = { 'id': -1, 'nombre': this.translate.instant('todos') };
      this.r2 = true;
      this.loadingEnd();
    });
   
    this.maquinasService.getMaquinasPlanificadorTodas().subscribe((result) => {
      this.maquinasVisibles = result;
      this.maquinasVisibles.forEach((element) => {
        element['nombre'] = this.translate.instant(element['nombre']);
        this.selectedMaquinaC = { 'id': -1, 'nombre': this.translate.instant('todos') };
      });
      this.Jmaquinas = this.maquinasVisibles;
      this.r1 = true;
      this.loadingEnd();

    });
    this.historicoParametrosService.GetAllTiposVariables().subscribe((result) => {
      this.tiposVariables = result['data'];
      this.tiposVariables.forEach((element) => {
        element['nombre'] = this.translate.instant(element['nombre']);
      });
      this.selectedVariable = { 'id': -1, 'nombre': this.translate.instant('todos') };
      this.r3 = true;
      this.loadingEnd();
    });
    this.historicoParametrosService.GetAllEjesMaquinas().subscribe((result) => {
      this.ejes = result['data'];
      this.selectedEje = { 'id': -1, 'nombre': this.translate.instant('todos') };
      this.historicoParametrosService.GetAllEjesMaquinasWithIdMaquinas().subscribe((result2) => {
        this.ejesMaquinas = result2['data'];
        this.r4 = true;
        this.loadingEnd();
      });
      this.ejesVisibles = result['data'];
      
    });
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      var id = e.dataItem['idMaquina'];
      this.router.navigate(['informetecnicoejecucion/', id, this.fechaIni, this.fechaFin]);
    }
  }

  selectionGrupoMaquinaChange(e) {
    this.idGrupoMaquina = e.id
    
    var selectedId = e.id;
    this.maquinasVisibles = [];
    this.Jmaquinas.forEach((element) => {
      if (element.grupo == e.id || e.id == -1) {
        this.maquinasVisibles.push(element);

      }
    });

  }
  selectionMaquinaChange(e) {
    this.idMaquina = e.id;
    if (e.id == -1) {
      this.showEjes = false;
    } else {
      this.showEjes = true;
    }
    this.selectedMaquinaC = e;
    this.ejesVisibles = [];
    this.ejesVisibles.push({ 'id':-1, 'nombre': this.translate.instant('todos') });
    this.ejesMaquinas.forEach((element) => {
      if (element['idMaquina'] == this.idMaquina) {
        this.ejesVisibles.push(element);
        this.selectedEje = { 'id':-1, 'nombre': this.translate.instant('todos') };
      }
    });
    this.selectedEje = { 'id':-1, 'nombre': this.translate.instant('todos') };
  }

  onFechaFinChange(e) {
    this.fechaFin = e;
  
  }
  onFechaIniChange(e) {
    this.fechaIni = e;
  }
  selectionEjeChange(e) {
    this.idEje = e.id;
    this.nombreEje = e.nombre;
  }
  selectionVariableChange(e) {
    this.idTipoVariable = e.id;
  }

  onFilterClck() {
 
    this.historicoParametrosService.filtrarParametrosEjes(-1, this.fechaIni, this.fechaFin, this.idMaquina, this.idEje, this.idTipoVariable).subscribe((result) => {

      this.historicoparametros = result.data;
      this.loadingGraficoTresEjes = false;
    });
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
