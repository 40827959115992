import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PerdidasService, UsuariosService, AlertService, InformeOeeService, MenuService, HistoricoOperacionesService, InformeProyectosService, 
  MaquinasService, RecetasInduccionService, AtributosService } from '@app/_services';
import { MyFilter, MyFunctions } from '@app/_helpers';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataStateChangeEvent, PageChangeEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, filterBy, process, State } from '@progress/kendo-data-query';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import * as $ from 'jquery';
import { IntlService } from '@progress/kendo-angular-intl';
import { first } from 'rxjs/operators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { UploadStatusTotalComponent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-historicoPiezasComponent-data',
  templateUrl: 'historicoPiezas.html'
})
export class HistoricoPiezasComponent implements OnInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  //#region VARIABLES
  
  // public heightGrid = window.innerHeight - 120;
  public heightGrid = window.innerHeight - 270;

  public exportandoExcel = false;

  //Contenidos tooltips headers
  public contenidoAnalitica: any;
  public contenidoEstado: any;
  public tiempoAsigandoPiezaNoPreparacion: any;
  
  //Usuario
  user = this.userService.userValue;

  //Piezas
  public Jpiezas: any;
  public piezasSelecteds: any;
  public piezasSelectedsAux: any = [];

  //PARA AÑADIR PAGGING AL GRID
  public gridView: any;

  public ocultarParte: boolean = true;

  // Fechas para controlar el rango de si hacer o no consulta nueva
  public fechaIniUltimaConsulta: any;
  public fechaFinUltimaConsulta: any;

  //Variables filtro
  statusOLD: boolean = true; //para abrir o cerrar el filtro
  public listaTurnos: any;
  public turnosSeleccionados: any;
  public datosValores: any;
  public datosValoresSelected: any;
  public datosEstado: any;
  public datosEstadoSelected: any;
  private dataFiltro: any;

  private dataFiltroAux: any;
  public loadingPanel: boolean = false;
  public opcionesSeleccionadas: boolean = false;

  public listaHerramientas: any;
  public herramientasSeleccionadas: any;

  public listaOperarios: any;
  public operariosSeleccionados: any;

  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public listaPiezas: any; //POR TEXTO
  public piezasSeleccionadas: any;

  public listaPlanos: any; //POR TEXTO
  public planosSeleccionadas: any;

  public ocultarPartes: boolean;
  public listaPartes: any; //POR TEXTO
  public partesSeleccionadas: any;

  public listaOperaciones: any; //POR TEXTO
  public operacionesSeleccionadas: any;

  public terminados: boolean = false;

  public listaMaquinas: any;
  public listaMaquinasMostradas: any;
  public maquinasSeleccionadas: any;

  //GRUPOS DE MAQUINAS
  public JgruposMaquinasCombo: any;
  public JgruposMaquinasSelected: any;
  public JgruposMaquinasMaquinas: any;

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //ATRIBUTOS / SUBATRIBUTOS
  public atributos: any;
  public groupedAtributo: GroupResult[];
  public AtributosSeleccionados: any[] = [];

  //Variables calendario filtro
  public monthsInYearFila = [0, 1]; //Solo va a haber dos meses todo el tiempo
  public DaysInMonthsOLD;
  public periodoActualSeleccionado = [];
  mostrarCalendario = false; //Para abarir o cerrar el calendario
  public calendarElement: any;

  //DB
  public usuarioIdDb: any;
  public usuarioIdDbGamesa: any;
  public usuarioIdDbCAF: any;
  public esDemo: any;

  private tipoPopup: number; //1 Validar::: 2 Apartar::: 3 Chatarra

  public Jobservacion: any;

  private Jperdidas: any;
  private Joperaciones_perdida: any;
  private JDatperdidas: any;
  private JperdidasSelected: any;
  private Joperaciones_perdidaSelected: any;
  public JperdidasAchatarradaSelected: any;
  private Joperaciones_perdidaAchatarradaSelected: any;
  public observacionChatarra: string = "";
  public JperdidasApartadaSelected: any;
  private Joperaciones_perdidaApartadaSelected: any;
  public observacionApartada: string = "";

  public numeroCirculos: number = 4; // incluido  (...)

  //DIRECCION DEL FICHERO DE COPIA
  public fichero: any = '';

  @ViewChild('popup') popup: NgbModalRef;
  modalReference: NgbModalRef;

  public tituloModal: any;

  public fini_filtro: Date;
  public ffin_filtro: Date;

  public usaLotes : boolean = false;

  //STATE GRID DATA
  public state: State = {
    skip: 0,
    take: 10000000,
  };

  //Para poner la fecha disabled en el dropdown
  public itemDisabled(itemArgs: { dataItem: string; index: number }) {
    // return itemArgs.index === 0; // disable the 3rd item
    return false; 
  }

  //FILTRO *START*
  status: boolean = true; //para abrir o cerrar el filtro *se carga abierto siempre!* si se autofiltra, se cierra solo.

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs *START*

  //en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];

  public opcionDate = [
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];

  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];

  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];

  public opcionComboEstricto = [
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];

  public opcionComboFlexible = [
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];

  public opcionComboFlexibleOperarioMaquinas = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    //{ id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    //{ id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];

  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];

  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs *END*

  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas

  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fechaIniFiltro", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          },
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    ]
  };

  public datosFiltro: any = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };

  //ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, comboFlexibleOperarioMaquina check, numeric, decimal, string
  public columnasFiltro = [
    { id: 1, nombre: this.translateService.instant('fecha'), field: "fechaIniFiltro", sqlfield: "hb.fechaTurno", tipo: 'date' },
    { id: 2, nombre: this.translateService.instant('of'), field: "refOF", sqlfield: "refOF", tipo: 'comboFlexible' },
    { id: 3, nombre: this.translateService.instant('cliente'), field: "cliente", sqlfield: "cliente", tipo: 'comboFlexible' },
    { id: 4, nombre: this.translateService.instant('pieza'), field: "pieza", sqlfield: "pieza", tipo: 'comboFlexible' },
    { id: 5, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "terminado", tipo: 'check' },
    { id: 6, nombre: this.translateService.instant('valores'), field: "valorFueraLimites", sqlfield: "valorFueraLimites", tipo: 'comboEstrincto' },
    { id: 7, nombre: this.translateService.instant('estado'), field: "estado", sqlfield: "estado", tipo: 'comboEstrincto' },
    { id: 8, nombre: this.translateService.instant('nserie'), field: "numeroSerie", sqlfield: "numeroSerie", tipo: 'comboFlexible' },
    { id: 9, nombre: this.translateService.instant('operario'), field: "operarios", sqlfield: "operarios", tipo: 'comboFlexibleOperarioMaquina' },
    { id: 10, nombre: this.translateService.instant('maquina'), field: "maquinas", sqlfield: "maquinas", tipo: 'comboFlexibleOperarioMaquina' },
    { id: 11, nombre: this.translateService.instant('plano'), field: "numeroPlano", sqlfield: "numeroPlano", tipo: 'comboFlexible' },
    { id: 12, nombre: this.translateService.instant('atributos'), field: "idAtributo", sqlfield: "idAtributo", tipo: 'comboFlexible' },
  ];

  //Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaValores: any;
  public filtro_listaEstado: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  //variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  //LOADING filtro
  public loadingFiltro: boolean = false;
  public actualizarVisible: boolean = false;

  //FILTRO *END*


  //LINK URL RUTA
  public linkRuta: string = "";

  // INDUCCION
  public cantidadAcumulada: number = 0;

  public botonesVisibles = false;

  //#endregion

  constructor(
    private informeProyectosService: InformeProyectosService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UsuariosService,
    private alertService: AlertService,
    private informeOeeService: InformeOeeService,
    private translateService: TranslateService,
    private menuService: MenuService,
    private historicoOperacionesService: HistoricoOperacionesService,
    public myFunctions: MyFunctions,
    private maquinasService: MaquinasService,
    private modalService: NgbModal, private cdRef: ChangeDetectorRef, //esto evita el error de expressionchangedafterviewinit
    private intl: IntlService, //este último es para traducir las fechas para los tags
    private recetasInduccionService: RecetasInduccionService,
    private atributosService: AtributosService,
    private myFilter: MyFilter, 
    private perdidasService: PerdidasService
  ) {

    this.fini_filtro = this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7);
    this.ffin_filtro = this.myFunctions.getDateNow();

    this.contenidoAnalitica = this.translateService.instant('accesoDirectoAnalitica');
    this.contenidoEstado = this.translateService.instant('estadoProcesoPiezas');
    this.tiempoAsigandoPiezaNoPreparacion = this.translateService.instant('tiempoAsigandoPiezaNoPreparacion');

    //GRUPOS
    this.maquinasService.getGruposMaquinas().subscribe(
      json => {
        this.JgruposMaquinasCombo = json.data;
        this.JgruposMaquinasSelected = undefined;
      }
    );

    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {
      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;
          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);
          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
          this.cargarDatosFiltro();
        });
    } else {
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
      this.cargarDatosFiltro();
    }

    this.loadingPanel = true;

    this.menuService.titulo = this.translateService.instant('historicoPiezas').toUpperCase();

    //SI idDb=33 (GAMESA) ENTONCES CAMBIAR DISEÑO 
    this.usuarioIdDb = userService.userValue.idDb;
    this.usuarioIdDbGamesa = 33;
    this.usuarioIdDbCAF = userService.userValue.idDb == 7 ? true : false;
    this.esDemo = userService.userValue.idDb == 6 ? true : false;
    // this.cargarMeses();  //Se cargan los meses del calendario del filtro aquí, ya que necesitamos que esté en el DOM desde el inicio

    this.cargar_Filtro();

    //PARA IMPRIMIR EXCEL SIN TENER EN CUENTA LA PAGINACION 
    this.allData = this.allData.bind(this);

    
    //PERDIDAS // Como es multiselect, se ha quitado la opcion de achatarrar o apartar porque a veces se solicita operacion y habria que limitar la seleccion a piezas iguales o a 
    //            una sola pieza y para eso ya esta el boton de dentro.
    // this.perdidasService.GetCombo().subscribe(
    //   json => {
    //     this.JDatperdidas = json;
    //     this.Jperdidas = this.JDatperdidas;

    //     this.cargatPerdiasSeleccionadas();
    //   }
    // )
  }
  cargatPerdiasSeleccionadas(){
    // this.Jperdidas.forEach(
    //   perdida => {
    //     if (perdida.value == this.idPerdidaApartada){
    //       this.JperdidasApartadaSelected = perdida;
    //     }
    //     if (perdida.value == this.idPerdidaAchatarrada){
    //       this.JperdidasAchatarradaSelected = perdida;
    //     }
    //   });

    // this.Joperaciones_perdida.forEach(
    //   operacion => {
    //     if (operacion.value == this.idOperacionApartada){
    //       this.Joperaciones_perdidaApartadaSelected = operacion;
    //     }
    //     if (operacion.value == this.idOperacionAchatarrada){
    //       this.Joperaciones_perdidaAchatarradaSelected = operacion;
    //     }
    //   });
  }
  
  ngOnInit(): void {
    this.calendarElement = document.getElementsByClassName('calendarFiltro')[0]; //Le indicamos el elemento para poder usarlo después en otras funciones
    //Cuando se asignan las fechas iniciales el dom no está cargado aún, por lo que hay que ponerles las etiquetas aquí
    // if (this.opcionesSeleccionadas)
    //   this.incluirEnTag(this.intl.formatDate(this.periodoActualSeleccionado[0]) + "-" + this.intl.formatDate(this.periodoActualSeleccionado[1]), "fechasLabel");

    if (this.route.snapshot.params.idPieza != undefined && this.route.snapshot.params.fechaIni != 0) {
      this.cargarDatosConParametrosUrl();
    } 
  }
 
  //FILTRO
  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        ]
      }
    )
  }

  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
    }
  }

  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      //Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: []
      })
      this.actualizarVisible = true;
    }
  }

  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);

    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // COMBO FLEXIBLE OPERARIO/MAQUINA -
    else if (row.columna.tipo == 'comboFlexibleOperarioMaquina') { row.operator = this.opcionComboFlexibleOperarioMaquinas[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }

  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.gridView = this.Jpiezas;
  }

  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    if(Object.keys(this.route.snapshot.params).length>0){
      this.cargarFiltroURL();
    }
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo(): CompositeFilterDescriptor {

    var em: CompositeFilterDescriptor = this.filtroToKendo_recursivo(this.datosFiltro);
    console.log(em);
    return em;
  }

  filtroToKendo_recursivo(jFiltro): CompositeFilterDescriptor {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: "or" | "and" = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR" as unknown as CompositeFilterDescriptor)
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      //var jRow : CompositeFilterDescriptor;

      var jRow = {} as CompositeFilterDescriptor;

      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          //jSubRow1["field"] = jFiltro.columna.field;
          //jSubRow2["field"] = jFiltro.columna.field;
          jSubRow1["field"] = "fechaModFiltro";
          jSubRow2["field"] = "fechaIniFiltro";
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lt"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible' || jFiltro.columna.tipo == 'comboFlexibleOperarioMaquina') {
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
                if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10 || jFiltro.columna.id == 12) jSubRow1["operator"] = "contains"; // Operario / Maquina / Atributos
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
                if (jFiltro.columna.id == 9 || jFiltro.columna.id == 10 || jFiltro.columna.id == 12) jSubRow1["operator"] = "doesnotcontain"; // Operario / Maquina / Atributos
              }
              jSubRow1["value"] = seleccionado.id;
              if (jFiltro.columna.id == 9) jSubRow1["value"] = seleccionado.nombre.split(" ").join(";"); // Operario
              if (jFiltro.columna.id == 10) jSubRow1["value"] = seleccionado.nombre; //Maquina
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
            if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.comboSelected.nombreOperario.split(" ").join(";"); // Operario
            if (jFiltro.columna.id == 10) jRow["value"] = jFiltro.comboSelected.nombreMaquina; // Maquina
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "contains"; // Operario
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            if (jFiltro.columna.id == 9) jRow["operator"] = "doesnotcontain"; // Operario
            jRow["value"] = jFiltro.text;
            if (jFiltro.columna.id == 9) jRow["value"] = jFiltro.text.split(" ").join(";"); // Operario
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR" as unknown as CompositeFilterDescriptor;
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }

  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          if (jFiltro.operator.dobleValor) {
            jFiltro.fechaIni.setHours(0);
            jFiltro.fechaIni.setMinutes(0);
            jFiltro.fechaIni.setSeconds(0);
            this.fini_filtro = jFiltro.fechaIni

            jFiltro.fechaFin.setHours(0);
            jFiltro.fechaFin.setMinutes(0);
            jFiltro.fechaFin.setSeconds(0);
            this.ffin_filtro = jFiltro.fechaFin;
            // if (jFiltro.operator.id == 7) {
            //   sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            // }
            // else if (jFiltro.operator.id == 8) {
            //   sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            // }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {

          if (jFiltro.columna.id == 6) this.datosValoresSelected = jFiltro.comboSelecteds[0];
          if (jFiltro.columna.id == 7) this.datosEstadoSelected = jFiltro.comboSelecteds[0];

        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible' || jFiltro.columna.tipo == 'comboFlexibleOperarioMaquina') {
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
              // ATRIBUTOS 
              if (jFiltro.columna.id == 12) {
                valores = valores.replace(/'/g, "");
                valores = valores.replace(/ /g, "");
                sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + valores + "%')"
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }

  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible' || jFiltro.columna.tipo == 'comboFlexibleOperarioMaquina') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
        }
        else {
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }

  //Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }

  //Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }
    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }

  //Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }

  //Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };

  //Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  }

  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 6);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  }

  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  }

  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  }

  ultimos90DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 3, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    //FECHAS
    this.cargarMeses();

    //VALORES
    this.filtro_listaValores = [
      { nombre: this.translateService.instant('valoresIncorrectos'), nombre2: 'incorrectos', id: 1 },
      { nombre: this.translateService.instant('valoresSinIntroducir'), nombre2: 'sinIntroducir', id: 2 },
      { nombre: this.translateService.instant('valoresCorrectos'), nombre2: 'correctos', id: 3 },
      { nombre: this.translateService.instant('valoresSinValores'), nombre2: 'sinValores', id: 4 }];

    //ESTADOS
    this.filtro_listaEstado = [{ nombre: this.translateService.instant('enProceso'), nombre2: 'enProceso', id: 1 },
    { nombre: this.translateService.instant('acabada'), nombre2: 'acabada', id: 2 },
    { nombre: this.translateService.instant('validada'), nombre2: 'validada', id: 3 },
    { nombre: this.translateService.instant('apartada'), nombre2: 'apartada', id: 4 },
    { nombre: this.translateService.instant('chatarra'), nombre2: 'chatarra', id: 5 }];

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.historicoOperacionesService.Get_ClientesPiezasOfsOperacionesPartes().pipe(first()).subscribe(
      (data: any) => {
        var tablaCompleta = []
        data.dtOFs_Piezas_Partes_operaciones.forEach(
          element => {
            var nSeries = (data.dt_HPi as any).filter(f => f.idPieza == element.idPieza);
            nSeries.forEach(nserie => {
              var new_row = this.myFunctions.copy(element)
              new_row.idNserie = this.myFunctions.copy(nserie.idNserie);
              new_row.nSerie = this.myFunctions.copy(nserie.nSerie);
              tablaCompleta.push(new_row)
            });
        });

        this.dataFiltro = tablaCompleta;
        this.cargarConTodasLasRespuestas();
      });

    // ATRIBUTOS
    this.atributosService.getComboAtributos().subscribe(json => {
      var an: any = json;
      this.groupedAtributo = groupBy(an, [{ field: 'atributo' }]);
    });
  }

  preFiltrado(filtro, row) {
    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible" || row.columna.tipo == "comboFlexibleOperarioMaquina") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];
      // cargar los combos
      if (row.columna.id == 6) { // valores
        row.combo = this.filtro_listaValores;
      }
      else if (row.columna.id == 7) { // estado
        row.combo = this.filtro_listaEstado;
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 2) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.nombreOf)) {
              lag.push(of.nombreOf);
              var js = { id: of.nombreOf, nombre: of.nombreOf };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
        
      }
      else if (row.columna.id == 3) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 4) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 8) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 9) { // operarios FILTRO!
        var combo = [];
        var lag = [];
        this.listaOperarios.forEach(
          operario => {
            if (!lag.includes(operario)) {
              lag.push(operario);
              var js = { id: operario.idOperario, nombre: operario.nombreOperario };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // maquinas FILTRO!
        var combo = [];
        var lag = [];
        this.listaMaquinas.forEach(
          maquina => {
            if (!lag.includes(maquina)) {
              lag.push(maquina);
              var js = { id: maquina.idMaquina, nombre: maquina.nombreMaquina };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // planos FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          plano => {
            if (!lag.includes(plano.numeroPlano)) {
              lag.push(plano.numeroPlano);
              var js = { id: plano.numeroPlano, nombre: plano.numeroPlano };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // atributos FILTRO!
        row.combo = this.groupedAtributo;
      }
    }
  }

  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }

  onFilter() {

    var hayFecha = false;
    var fechaInicio;
    var fechaFin;
    var filtroCompleto: any = this.filtroToSQL(); // filtro completo
    this.datosFiltro.group.forEach(groups => {
      groups.group.forEach(element => {
        if (element.columna.tipo == 'date') {
          hayFecha = true;
          fechaInicio = element.fechaIni
          fechaFin = element.fechaFin
        }
      });
    });

      if ((fechaInicio < this.fechaIniUltimaConsulta || fechaFin > this.fechaFinUltimaConsulta || !this.Jpiezas/*|| this.fechaFinUltimaConsulta == undefined*/) && hayFecha) { // es necesario hacer la consulta por el rango de fechas
        this.cargarPiezas();
        this.status = true;
      } else if (!hayFecha) { // es necesario hacer la consulta ya que no hay fechas y si filtro
        this.cargarPiezas(filtroCompleto);
        this.status = true;
      } else { // no es necesario hacer la consulta ya que el filtro esta entre fechas
        if (this.Jpiezas){
          this.gridView = filterBy(this.Jpiezas, this.filtroToKendo());
          this.status = true;
          this.actualizarVisible = false;
        }
      }    
  }

  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    
    const jsonStr = sessionStorage.getItem('filtro');
    if (jsonStr) {
      datosFiltro = this.myFilter.parse_filtro(jsonStr, this.columnasFiltro);
      this.myFilter.set_filtroCompartido(datosFiltro);
      sessionStorage.removeItem('filtro');
    }

    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {

      // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
      var r1, r2 = false;
      // HISTORICO PIEZAS??
      /**/var IdHistorico = Number.parseInt(this.route.snapshot.params['idHistoricoPieza']);
      this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
          }
          r1 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
      // HISTORICO OPERACIONES??
      /**/var IdHistoricoOp = Number.parseInt(this.route.snapshot.params['idHistoricoOperacion']);
      this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
          }
          r2 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else {
      if (this.route.snapshot.params['fechaIni'] != '0' && this.route.snapshot.params['fechaFin'] != '0'
        && this.route.snapshot.params['fechaIni'] != undefined && this.route.snapshot.params['fechaFin'] != undefined) {
        datosFiltro.group[0].group.push(
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fechaIniFiltro", sqlfield: "hb.fechaTurno", tipo: 'date' },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fechaIni']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fechaFin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
      }
      if(this.dataFiltro.length>0){
        // OF
        var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idOf']);
        // if (rowOFs.length > 0) {
        if (this.route.snapshot.params['idOf'] != '0' && this.route.snapshot.params['idOf'] != undefined) {
          datosFiltro.group[0].group.push(
            {
              columna: { id: 2, nombre: this.translateService.instant('of'), field: "refOF", sqlfield: "refOF", tipo: 'comboFlexible' },
              operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
              fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
              fechaFin: this.myFunctions.getDateNow(),
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            }
          );
          // Se carga el combo de clientes (esto limpia la seleccion)
          var numeroOf = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idOf'])[0].nombreOf;
          this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
          // Se selecciona el que queremos
          var of = { id: numeroOf, nombre: numeroOf }
          datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
        }
        // CLIENTE
        // Aprovechamos que los combos ya estan cargados para conseguir el nombre del cliente.
        var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idCliente']);
        if (rowClientes.length > 0 && this.route.snapshot.params['idCliente'] != "0") {
          datosFiltro.group[0].group.push(
            {
              columna: { id: 3, nombre: this.translateService.instant('cliente'), field: "cliente", sqlfield: "do.nombreCliente", tipo: 'comboFlexible' },
              operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
              fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
              fechaFin: this.myFunctions.getDateNow(),
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            }
          );
          // Se carga el combo de clientes (esto limpia la seleccion)
          this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
          // Se selecciona el que queremos
          var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
          datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
        }
        // OPERARIO
        // Aprovechamos que los combos ya estan cargados para conseguir el nombre del operario.
        if(this.listaOperarios.length>0){
          var rowOperarios = this.listaOperarios.filter(x => x.idOperario == this.route.snapshot.params['idOperario']);
          if (rowOperarios.length > 0) {
            datosFiltro.group[0].group.push(
              {
                columna: { id: 9, nombre: this.translateService.instant('operario'), field: "operarios", sqlfield: "operarios", tipo: 'comboFlexibleOperarioMaquina' },
                operator: { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
              }
            );
            // Se carga el combo de operarios (esto limpia la seleccion)
            this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
            // Se selecciona el que queremos
            var nombre = { id: rowOperarios[0].nombreOperario, nombre: rowOperarios[0].nombreOperario }
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [nombre];
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].text = rowOperarios[0].nombreOperario;
          }
        }
        // MAQUINA
        // Aprovechamos que los combos ya estan cargados para conseguir el nombre de la maquina.
        if(this.listaMaquinas.length>0){
            var rowMaquinas = this.listaMaquinas.filter(x => x.idMaquina == this.route.snapshot.params['idMaquina']);
          if (rowMaquinas.length > 0) {
            datosFiltro.group[0].group.push(
              {
                columna: { id: 10, nombre: this.translateService.instant('maquina'), field: "maquinas", sqlfield: "do.maquinas", tipo: 'comboFlexibleOperarioMaquina' },
                operator: { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
              }
            );
            // Se carga el combo de maquinas (esto limpia la seleccion)
            this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
            // Se selecciona el que queremos
            var maquina = { id: rowMaquinas[0].nombreMaquina, nombre: rowMaquinas[0].nombreMaquina }
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [maquina];
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].text = rowMaquinas[0].nombreMaquina;
          }
        }
        
        // PIEZA
        var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idPieza']);
        // if (rowPiezas.length > 0) {
        if (this.route.snapshot.params['idPieza'] != '0' && this.route.snapshot.params['idPieza'] != undefined) {
          datosFiltro.group[0].group.push(
            {
              columna: { id: 4, nombre: this.translateService.instant('pieza'), field: "pieza", sqlfield: "pieza", tipo: 'comboFlexible' },
              operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
              fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
              fechaFin: this.myFunctions.getDateNow(),
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: []
            }
          );
          // Se carga el combo de piezas (esto limpia la seleccion)
          var nombrePieza = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idPieza'])[0].nombrePieza;
          this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
          // Se selecciona el que queremos
          var pieza = { id: nombrePieza, nombre: nombrePieza }
          datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
        }

        // NSERIE
        if (this.route.snapshot.params['nSerie'] != '0' && this.route.snapshot.params['nSerie'] != undefined) {
          
            datosFiltro.group[0].group.push(
              {
                columna: { id: 8, nombre: this.translateService.instant('nSerie'), field: "numeroSerie", sqlfield: "numeroSerie", tipo: 'comboFlexible' },
                operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
                fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
                fechaFin: this.myFunctions.getDateNow(),
                mostrarCalendario: false,
                text: '',
                numberMin: 0,
                numberMax: 0,
                decimalformat: '0.000',
                decimalMin: 0.0,
                decimalMax: 0.0,
                check: false,
                combo: [{ id: 1, nombre: "" }],
                comboSelected: {},
                comboSelecteds: []
              }
            );
            // Se carga el combo de nserie (esto limpia la seleccion)
            this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
            // Se selecciona el que queremos
            var nSerie = [];
            this.route.snapshot.params['nSerie'].split('__').forEach(element => {
              nSerie.push({ id: element, nombre: element });
            });
            datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = nSerie; 
        }

        // PLANO
        //var rowPlanos = this.dataFiltro.filter(x => x.idPlano == this.route.snapshot.params['idPlano']);
        // // if (rowPlanos.length > 0) {
        // if (this.route.snapshot.params['idPlano'] != '0' && this.route.snapshot.params['idPlano'] != undefined) {
        //   datosFiltro.group[0].group.push(
        //     {
        //       columna: { id: 14, nombre: this.translateService.instant('plano'), field: "numeroPlano", sqlfield: "numeroPlano", tipo: 'comboFlexible' },
        //       operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
        //       fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        //       fechaFin: this.myFunctions.getDateNow(),
        //       mostrarCalendario: false,
        //       text: '',
        //       numberMin: 0,
        //       numberMax: 0,
        //       decimalformat: '0.000',
        //       decimalMin: 0.0,
        //       decimalMax: 0.0,
        //       check: false,
        //       combo: [{ id: 1, nombre: "" }],
        //       comboSelected: {},
        //       comboSelecteds: []
        //     }
        //   );
        //   // Se carga el combo de planos (esto limpia la seleccion)
        //   this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        //   // Se selecciona el que queremos
        //   var plano = { id: this.route.snapshot.params['idPlano'], nombre: this.route.snapshot.params['idPlano'] }
        //   datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [plano];
        // }
        // TERMINADO 
        // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
        // * solo lo voy a filtrar si es 1
        //var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
        // if (this.route.snapshot.params['terminados'] == '1') {
        //   datosFiltro.group[0].group.push(
        //     {
        //       columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "do.operacionTerminada", tipo: 'check' },
        //       operator: { id: 0, nombre: '' },
        //       fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        //       fechaFin: this.myFunctions.getDateNow(),
        //       mostrarCalendario: false,
        //       text: '',
        //       numberMin: 0,
        //       numberMax: 0,
        //       decimalformat: '0.000',
        //       decimalMin: 0.0,
        //       decimalMax: 0.0,
        //       check: true,
        //       combo: [{ id: 1, nombre: "" }],
        //       comboSelected: {},
        //       comboSelecteds: []
        //     }
        //   );
        // }

      }
      
    }

    // Si hay filtrado
    if(datosFiltro.group.length>0){
        if (datosFiltro.group[0].group.length > 0) {
        // Annadimos la ultima linea al filtro
        datosFiltro.group[0].group.push(
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: []
          }
        );
        // Acutalizamos el filtro
        this.datosFiltro = datosFiltro;
        // Autofiltramos el informe
        this.onFilter();
      }
    }
    
  }

  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    
    // FECHAS (INICIO, FIN)
    if (this.route.snapshot.params['fechaIni'] != '0' && this.route.snapshot.params['fechaFin'] != '0'
      && this.route.snapshot.params['fechaIni'] != undefined && this.route.snapshot.params['fechaFin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fechaIniFiltro", sqlfield: "hb.fechaTurno", tipo: 'date' },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fechaIni']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fechaFin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('of'), field: "refOF", sqlfield: "do.numeroOF", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de ofs (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "pieza", sqlfield: "do.nombrePieza", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de piezas (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('nserie'), field: "numeroSerie", sqlfield: "ho.nSerie", tipo: 'string' },
          operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      //SE CREA UNA SELECCION VACIA
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "do.nombreOperacion", tipo: 'comboFlexible' },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;


    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: []
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  // END FILTRO

  /////////SELECT ALL GRID/////////
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  // public onSelectAllChange(checkedState: SelectAllCheckboxState) {

  //   if (checkedState === 'checked') {

  //     this.piezasSelecteds = [];
  //     this.selectAllState = 'checked';

  //     var dataParaFiltrar;
  //     if (this.state == undefined) {
  //       dataParaFiltrar = this.gridView;
  //     } else {
  //       this.state.skip = 0;
  //       this.state.take = 10000000;
  //       dataParaFiltrar = process(this.gridView, this.state);
  //     }
  //     Object.keys(dataParaFiltrar).forEach(
  //       operacion => {
  //         this.piezasSelecteds.push(dataParaFiltrar[operacion].id);
  //       });

  //   } else {
  //     this.piezasSelecteds = [];
  //     this.selectAllState = 'unchecked';
  //   }
  //   this.piezasSelectedsAux = this.piezasSelecteds;
  // }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.piezasSelecteds = this.piezasSelectedsAux;
    this.selectAllState = 'unchecked';
  }

  public pageChange(event: PageChangeEvent): void {
    this.piezasSelecteds = this.piezasSelectedsAux;
  }


  /////////FIN SELECT ALL GRID/////////
  
  public onChange($event) {
    this.botonesVisibles = false;
    var seleccionadas = this.gridView.filter(f => this.piezasSelecteds.includes(f.id));

    if (seleccionadas.length > 0) {
      var visibleLag = true;
      seleccionadas.every(element => {
        if (!(!element.usaLotes || (element.usaLotes && element.cantidadLote == 1)))
          visibleLag = false
        return visibleLag
      });
      this.botonesVisibles = visibleLag;
    }
  }

  //FUNCIONES ESPECIFICAS DEL FILTRO
  //Comprobar si las opciones obligatorias ya están escogidas, y entonces poner el botón de filtrar como habilitado
  //Mter esta funcion en los change de los que sean obligatorios
  comprobarOpcionesObligatorias() {
    if (this.periodoActualSeleccionado.length == 2) {
      this.opcionesSeleccionadas = true;
    } else {
      this.opcionesSeleccionadas = false;
    }
  }

  //Función para obtener la cantidad de elementos que tienen datos seleccionados dentro del filtro, y mostrar los botones de cara a ello
  // getChildren() {
  //   return document.getElementsByClassName("filtro-seleccionados")[0].children.length;
  // }

  clickEvent() { //Función para abrir y cerrar el menú del filtro
    this.statusOLD = !this.statusOLD;
    this.mostrarCalendario = false;
  }

  seccionChanged() {
    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    } else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //Le ponemos los tags a las secciones que el usuario tenga seleccionadas por defecto

    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.JgruposMaquinasSelected) {
      this.JgruposMaquinasSelected.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }

    this.listaMaquinasMostradas = this.listaMaquinas.filter(f => {
      var enGrupo = false
      if (f.idsGrupos != undefined && f.idsGrupos.length > 0)
        f.idsGrupos.forEach(
          idGrupo => {
            enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
          });

      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });
  }

  cargarDatosFiltro() {
    //VALORES
    this.datosValores = [{ nombre: this.translateService.instant('valoresCorrectos'), nombre2: 'correctos', id: 1 },
    { nombre: this.translateService.instant('valoresIncorrectos'), nombre2: 'incorrectos', id: 2 },
    { nombre: this.translateService.instant('valoresSinIntroducir'), nombre2: 'sinIntroducir', id: 3 },
    { nombre: this.translateService.instant('valoresSinValores'), nombre2: 'sinValores', id: 4 }];
    this.datosValoresSelected = { nombre: 'sinFiltrar', nombre2: '' };

    //ESTADOS
    this.datosEstado = [{ nombre: this.translateService.instant('enProceso'), nombre2: 'enProceso', id: 1 },
    { nombre: this.translateService.instant('acabada'), nombre2: 'acabada', id: 2 },
    { nombre: this.translateService.instant('validada'), nombre2: 'validada', id: 3 },
    { nombre: this.translateService.instant('apartada'), nombre2: 'apartada', id: 4 },
    { nombre: this.translateService.instant('chatarra'), nombre2: 'chatarra', id: 5 }];
    this.datosEstadoSelected = { nombre: 'sinFiltrar', nombre2: '' };

    if (this.route.snapshot.params.idturno == undefined) {
      //this.cargarPiezas(); SIEMPRE HAY QUE FILTRAR LA MAQUINA, por tema de permisos, POR ESO SE HARA LA CARGA DESPUES DE SU RESPUESTA
      //FECHAS
      this.periodoActualSeleccionado[0] = this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7); //7 egun atzera
      this.periodoActualSeleccionado[1] = this.myFunctions.getDateNow()
      this.periodoActualSeleccionado[0].setHours(0, 0, 0);
      this.periodoActualSeleccionado[1].setHours(0, 0, 0);
      this.opcionesSeleccionadas = true;
      //TURNOS
      this.listaTurnos = [
        { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
        { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
        { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
      ];

      //MAQUINAS
      this.informeProyectosService.Get_Maquinas().subscribe(
        (data: any) => {
          //los grupos se pasan en una lista de strings separados por ",", de esta forma convertimos este string en una lista de JS manejable.
          var mostra: any = [];
          data.forEach(
            row => {
              row["idsGrupos"] = row.idGrupos.split(',');
              var an: any = this.userService.secciones;
              var listaIdsecciones = (an.filter(f => { return f.activo; }) === undefined) ? [] : an.filter(f => { return f.activo; }).map(a => a.id);
              if (listaIdsecciones.includes(row.idSeccion)) {
                mostra.push(row)
              }
            });
          this.listaMaquinas = mostra;
          this.seccionChanged();
          r1 = true;
          this.cargarPiezas();
        });

      //HERRAMIENTAS
      this.informeProyectosService.Get_Herramientas().subscribe((data: any) => {
        this.listaHerramientas = data;
        this.listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        //if (r1 && r2 && r3 && r4 && r5) this.cargarPiezas(); como no hay nada filtrado no es necesario esperar a cargar el filtro.
      });

      //OPERARIOS
      this.informeProyectosService.Get_Operarios().subscribe((data: any) => {
        this.listaOperarios = data;
        this.listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));
        r3 = true;
        //if (r1 && r2 && r3 && r4 && r5) this.cargarPiezas(); como no hay nada filtrado no es necesario esperar a cargar el filtro.
      });

      //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES
      this.informeProyectosService.Get_ParteOculta().subscribe((data: any) => {
        if (data[0].ocultarParte == 0) this.ocultarPartes = false;
        if (data[0].ocultarParte == 1) this.ocultarPartes = true;
        r5 = true;
        //if (r1 && r2 && r3 && r4 && r5) this.cargarPiezas(); como no hay nada filtrado no es necesario esperar a cargar el filtro.
      });
    } else {

      var r1, r2, r3, r4, r5: boolean = false;

      //Valores del filtro.
      var fini = this.route.snapshot.params['fechaIni'];
      var ffin = this.route.snapshot.params['fechaFin'];

      var turnos = this.route.snapshot.params.idturno.split("_");
      var maquinas = this.route.snapshot.params.idMaquina.split("_");
      var herramientas = this.route.snapshot.params.idHerramienta.split("_");
      var operarios = this.route.snapshot.params.idOperario.split("_");

      var ofs = this.route.snapshot.params.idOf.split("_");
      var clientes = this.route.snapshot.params.idCliente.split("_");

      var piezas = this.route.snapshot.params.idPieza.split("_");
      var partes = this.route.snapshot.params.idParte.split("_");
      var operaciones = this.route.snapshot.params.idOperacion.split("_");

      console.log("URL", ofs, clientes, piezas)


      //FECHAS : con filtro en URL
      this.periodoActualSeleccionado[0] = new Date(fini.replaceAll('_', '-'));
      this.periodoActualSeleccionado[1] = new Date(ffin.replaceAll('_', '-'));
      this.periodoActualSeleccionado[0].setHours(0, 0, 0);
      this.periodoActualSeleccionado[1].setHours(0, 0, 0);
      this.opcionesSeleccionadas = true;

      //TURNOS
      this.listaTurnos = [
        { nombreTurno: this.translateService.instant("manana"), idTurno: 1 },
        { nombreTurno: this.translateService.instant("tarde"), idTurno: 2 },
        { nombreTurno: this.translateService.instant("noche"), idTurno: 3 }
      ];

      // selecteds
      this.turnosSeleccionados = [];
      this.listaTurnos.forEach(
        row => {
          if (turnos.includes(String(row.idTurno))) {
            this.turnosSeleccionados.push(row);
          }
        });

      //MAQUINAS
      this.informeProyectosService.Get_Maquinas().subscribe(
        data => {
          this.listaMaquinas = data;

          // selecteds
          this.maquinasSeleccionadas = [];
          this.listaMaquinas.forEach(
            row => {
              if (maquinas.includes(String(row.idMaquina)))
                this.maquinasSeleccionadas.push(row);
            });

          r1 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.CambioFiltro();
            this.cargarPiezas();
          }
        });

      //HERRAMIENTAS
      this.informeProyectosService.Get_Herramientas().subscribe(
        data => {
          this.listaHerramientas = data;

          // selecteds
          this.herramientasSeleccionadas = [];
          this.listaHerramientas.forEach(
            row => {
              if (herramientas.includes(String(row.idHerramienta)))
                this.herramientasSeleccionadas.push(row);
            });

          this.listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
          r2 = true;
          if (r1 && r2 && r3 && r4 && r5) {
            this.CambioFiltro();
            this.cargarPiezas();
          }
        });

      //OPERARIOS
      this.informeProyectosService.Get_Operarios().subscribe((data: any) => {
        this.listaOperarios = data;

        // selecteds
        this.operariosSeleccionados = [];
        this.listaOperarios.forEach(
          row => {
            if (operarios.includes(String(row.idOperario)))
              this.operariosSeleccionados.push(row);
          });

        this.listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));
        r3 = true;
        if (r1 && r2 && r3 && r4 && r5) {
          this.CambioFiltro();
          this.cargarPiezas();
        }
      });

      //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES
      this.informeProyectosService.Get_ParteOculta().subscribe((data: any) => {
        if (data[0].ocultarParte == 0) this.ocultarPartes = false;
        if (data[0].ocultarParte == 1) this.ocultarPartes = true;
        r5 = true;
        if (r1 && r2 && r3 && r4 && r5) this.cargarPiezas();
      });
    }

    if (this.route.snapshot.params.idSeccion !== undefined && this.route.snapshot.params.idSeccion !== 0 && this.route.snapshot.params.idSeccion.split("_").length !== 0) {
      this.seccionesSeleccionadas = [];
      this.route.snapshot.params.idSeccion.split("_").forEach(element => {
        this.seccionesSeleccionadas.push(this.secciones?.filter(x => x.id == element)[0]);
      });
    }

    if (this.route.snapshot.params.idGrupoMaq !== undefined && this.route.snapshot.params.idGrupoMaq !== 0 && this.route.snapshot.params.idGrupoMaq.split("_").length !== 0) {
      this.JgruposMaquinasSelected = [];
      this.route.snapshot.params.idGrupoMaq.split("_").forEach(element => {
        this.JgruposMaquinasSelected.push(this.JgruposMaquinasCombo?.filter(x => x.id == element)[0]);
      });
    }

  }

  CambioFiltro() {
    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.nombrePieza));

    var idPlanos = [];
    if (this.planosSeleccionadas != undefined)
      this.planosSeleccionadas.forEach(plano => idPlanos.push(plano.numeroPlano));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.nombreParte));

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.nombreOperacion));

    var groupByCliente = [];
    var groupByPieza = [];
    var groupByOf = [];
    var groupByOperacion = [];
    var groupByParte = [];
    var groupByPlano = [];

    //FECHA
    var fechaMin: Date;
    var fechaMax: Date;
    data.forEach(
      row => {
        if ((idsOFs.includes(row.idOf))
          || (idsClientes.includes(row.idCliente))
          || (idPiezas.includes(row.nombrePieza))
          || (idPartes.includes(row.nombreParte))
          || (idOperaciones.includes(row.nombreOperacion))
        ) {
          if (row.fechaIni != null) {
            var fecha: Date = new Date(row.fechaIni.replaceAll('_', '-'));;
            if ((fechaMin == undefined) || fechaMin > fecha)
              fechaMin = new Date(row.fechaIni.replaceAll('_', '-'));
          }


          if (row.fechafin != null) {
            var fecha: Date = new Date(row.fechaIni.replaceAll('_', '-'));;
            if ((fechaMax == undefined) || fechaMax < fecha)
              fechaMax = new Date(row.fechafin.replaceAll('_', '-'));
          }
        }
      });
    if (fechaMin != undefined)
      this.periodoActualSeleccionado[0] = fechaMin
    if (fechaMax != undefined)
      this.periodoActualSeleccionado[1] = fechaMax

    this.opcionesSeleccionadas = true;

    //GROUP BY POR OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY POR CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)
        ) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

    //GROUP BY POR PIEZA
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombrePieza]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombrePieza] = {
            idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          };
          groupByPieza.push(lag[row.nombrePieza]);
        }
      });

    this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    //GROUP BY POR PLANO
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.numeroPlano]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.numeroPlano] = {
            idPlano: row.numeroPlano, nombrePlano: row.numeroPlano,
          };
          groupByPlano.push(lag[row.numeroPlano]);
        }
      });

    this.listaPlanos = groupByPlano.filter(item => (item.idPlano != -1));
    this.listaPlanos.sort((a, b) => (a.numeroPlano > b.numeroPlano) ? 1 : ((b.numeroPlano > a.numeroPlano) ? -1 : 0));

    //GROUP BY POR PARTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreParte]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idOperaciones.includes(row.nombreOperacion) || idOperaciones[0] == undefined)) {
          lag[row.nombreParte] = {
            idParte: row.idParte, nombreParte: row.nombreParte,
          };
          groupByParte.push(lag[row.nombreParte]);
        }
      });

    this.listaPartes = groupByParte.filter(item => (item.idParte != -1));
    this.listaPartes.sort((a, b) => (a.nombreParte > b.nombreParte) ? 1 : ((b.nombreParte > a.nombreParte) ? -1 : 0));

    //GROUP BY POR OPERACION
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.nombreOperacion]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idPiezas.includes(row.nombrePieza) || idPiezas[0] == undefined)
          && (idPartes.includes(row.nombreParte) || idPartes[0] == undefined)) {
          lag[row.nombreOperacion] = {
            idOperacion: row.idOperacion, nombreOperacion: row.nombreOperacion,
          };
          groupByOperacion.push(lag[row.nombreOperacion]);
        }
      });

    this.listaOperaciones = groupByOperacion.filter(item => (item.nombreOperacion != ''));
    this.listaOperaciones.sort((a, b) => (a.nombreOperacion > b.nombreOperacion) ? 1 : ((b.nombreOperacion > a.nombreOperacion) ? -1 : 0));

  }

  //FUNCIONES VENTANA
  //Función para cargar datos grid al filtrar
  cargarPiezas(filtroCompleto = "") {
    this.loadingPanel = true;

    // var fechaInicio = (this.periodoActualSeleccionado[0] === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.periodoActualSeleccionado[0]);
    // var fechaFin = (this.periodoActualSeleccionado[1] === undefined) ? undefined : this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(this.periodoActualSeleccionado[1].getTime() + (1000 * 60 * 60 * 24)));
    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fini_filtro);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.ffin_filtro);
    var listaIdTurnos = (this.turnosSeleccionados === undefined) ? [] : this.turnosSeleccionados.map(a => a.idTurno);
    var listaIdMaquinas = (this.maquinasSeleccionadas === undefined) ? [] : this.maquinasSeleccionadas.map(a => a.idMaquina);
    var listaIdHerramientas = (this.herramientasSeleccionadas === undefined) ? [] : this.herramientasSeleccionadas.map(a => a.idHerramienta);
    var listaIdOperarios = (this.operariosSeleccionados === undefined) ? [] : this.operariosSeleccionados.map(a => a.idOperario);
    var listaIdOfs = (this.ofsSeleccionados === undefined) ? [] : this.ofsSeleccionados.map(a => a.idOf);
    var listaIdClientes = (this.clientesSeleccionados === undefined) ? [] : this.clientesSeleccionados.map(a => a.idCliente);
    var listaPiezas = (this.piezasSeleccionadas === undefined) ? [] : this.piezasSeleccionadas.map(a => "'" + a.nombrePieza + "'");
    var listaPlanos = (this.planosSeleccionadas === undefined) ? [] : this.planosSeleccionadas.map(a => "'" + a.numeroPlano + "'");
    var listaPartes = (this.partesSeleccionadas === undefined) ? [] : this.partesSeleccionadas.map(a => "'" + a.nombreParte + "'");
    var listaOperaciones = (this.operacionesSeleccionadas === undefined) ? [] : this.operacionesSeleccionadas.map(a => "'" + a.nombreOperacion + "'");

    // como ahora se tienen acceso a areas limitadas, siempre hay que filtrar las maquinas
    if (listaIdMaquinas.length == 0)
      listaIdMaquinas = (this.listaMaquinasMostradas === undefined) ? [] : this.listaMaquinasMostradas.map(a => a.idMaquina)

    var aux = 4; //si no se aplica
    if (this.datosValoresSelected.nombre2 === 'incorrectos')
      aux = 1;
    else if (this.datosValoresSelected.nombre2 === 'correctos')
      aux = 2;
    else if (this.datosValoresSelected.nombre2 === 'sinIntroducir')
      aux = 0;
    else if (this.datosValoresSelected.nombre2 === 'sinValores')
      aux = 3;

    var aux2 = 6; //si no se aplica
    if (this.datosEstadoSelected.nombre2 === 'enProceso')
      aux2 = 1;
    else if (this.datosEstadoSelected.nombre2 === 'acabada')
      aux2 = 2;
    else if (this.datosEstadoSelected.nombre2 === 'validada')
      aux2 = 3;
    else if (this.datosEstadoSelected.nombre2 === 'apartada')
      aux2 = 4;
    else if (this.datosEstadoSelected.nombre2 === 'chatarra')
      aux2 = 5;

    this.historicoOperacionesService.get_piezas(fechaInicio, fechaFin,
      listaIdTurnos.join(), listaIdMaquinas.join(), listaIdHerramientas.join(), listaIdOperarios.join(),
      listaIdOfs.join(), listaIdClientes.join(), listaPiezas.join(), listaPartes.join(), listaOperaciones.join(),
      this.terminados, this.translateService.instant("desconocido"), aux, aux2, filtroCompleto).subscribe(
        (json: any) => {
          
          this.Jpiezas = json.data;
          this.piezasSelecteds = [];
          var dict: any = {};

          this.datosFiltro.group.forEach(groups => {
            groups.group.forEach(element => {
              if (element.columna.tipo == 'date') {
                this.fechaIniUltimaConsulta = element.fechaIni;
                this.fechaFinUltimaConsulta = element.fechaFin;
              }
            });
          });

          if (json.imagenes.length > 0) {
            //Tenemos las imagenes, creamos el diccionario
            json.imagenes.forEach(element => {
              dict[element.imagen] = element.imagenBASE64;
            });
          }
          this.gridView = this.Jpiezas;//.slice(this.skip, this.skip + this.pageSize);
          if (this.gridView != null && this.gridView.length > 0)
            this.gridView.forEach(element => {
              //#region poner con formato los tiempos
              element.tiempoEstimadoTotalFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoEstimadoTotal);
              element.tiempoRealFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoRealEjecucion + element.tiempoRealPreparacion);
              element.tiempoRealEjecucionFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoRealEjecucion);
              element.tiempoRealPreparacionFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoRealPreparacion);
              //#endregion
              //Primero procesamos los operarios
              var operarios = element.operarios.split(",");
              var operariosAuxi = [];
              var operariosAuxi2 = [];
              operarios.forEach(operario => {
                var nombre;
                var apellido;
                if (operario.split(';').length > 0) {
                  if (operario == this.translateService.instant("desconocido")) {
                    operariosAuxi.push("undefined");
                    operariosAuxi2.push("undefined");
                  } else {
                    nombre = operario.split(';')[0];
                    apellido = operario.split(';')[1];
                    if (apellido != undefined)
                      operariosAuxi2.push(nombre + " " + apellido);
                    else
                      operariosAuxi2.push(nombre);
                    if (nombre != undefined)
                      nombre = nombre.trim().substring(0, 1).toUpperCase();
                    if (apellido != undefined)
                      apellido = apellido.trim().substring(0, 1).toUpperCase();
                    operariosAuxi.push(nombre + apellido);
                  }
                }
              });
              element.operariosAuxi2 = operariosAuxi2.join(";");
              element.operariosAuxi = operariosAuxi.join(",");
              //Ahora seguimos con las maquinas
              var maquinas = element.maquinas.split(",");
              var maquinasAuxi = [];
              var maquinasAuxi2 = [];
              maquinas.forEach(maquina => {
                if (maquina == this.translateService.instant("desconocido")) {
                  maquinasAuxi2.push("undefined");
                  maquinasAuxi.push("undefined");
                } else {
                  var nombre = maquina.trim().substring(0, 1).toUpperCase();
                  var apellido = maquina.trim().substring(1, 2).toUpperCase();
                  maquinasAuxi2.push(maquina);
                  maquinasAuxi.push(nombre + apellido);
                }
              });
              element.maquinasAuxi2 = maquinasAuxi2.join(";");
              element.maquinasAuxi = maquinasAuxi.join(",");
              //Ahora hay que corregir las imagenes de las maquinas
              var imagenes = element.maquinasIm.split(';and;');
              var auxiImagenes = "";
              imagenes.forEach(imagen => {
                auxiImagenes += dict[imagen] + ";and;";
              });
              element.maquinasIm = auxiImagenes;
              //Por ultimo, ponemos bien las operaciones
              //Necesitamos sumar el total de las operaciones
              if(element.operaciones!=null && element.cantidades!=null && element.ordenOperaciones!=null){
                var opera = element.operaciones.split(';and;');
                var cant = element.cantidades.split(';and;');
                var ordenOperaciones = element.ordenOperaciones.split(';and;');
                element.cant = cant;
                element.opera = opera;
                element.ordenOperaciones = ordenOperaciones;
                var sumTotalHechas = 0;
                var sumTotalTotales = 0;
                var encontrada = false;
                cant.forEach((element6, index) => {
                  if (element6.split("/")[0] != element6.split("/")[1] && !encontrada) {
                    element.operacionActual = opera[index];
                    encontrada = true;
                  }
                  if (Number(element6.split("/")[0]) == Number(element6.split("/")[1])) {
                    sumTotalHechas += 1;
                  }
                  sumTotalTotales += 1;
                });
                element.hechasTotal = sumTotalHechas;
                element.total = sumTotalTotales;
                element.porcen = ((element.hechasTotal / element.total) * 100).toFixed(0);
                if (element.porcen > 100) {
                  element.porcen = 100;
                }
                element.porcenGrafico = Math.max(element.porcen, 10);
                element.terminado = (element.terminado == 1) ? true : false;

                // cotas calidad
                element.totalOK = 0;
                element.totalMal = 0;
                element.totalVacio = 0;
                var valoresArray;
                element.valores.split(';').forEach(val => {
                  valoresArray = val.split('/');
                  element.totalOK += parseInt(valoresArray[0]);
                  element.totalMal += parseInt(valoresArray[1]);
                  element.totalVacio += parseInt(valoresArray[2]);
                });
              }
              else{
                element.cant = [];
                element.opera = [];
                element.ordenOperaciones = [];
                element.operacionActual = null;
                element.hechasTotal = 0;
                element.total = 0;
                element.porcen = 0;
                element.porcenGrafico = Math.max(element.porcen, 10);
                element.terminado = false;
                // cotas calidad
                element.totalOK = 0;
                element.totalMal = 0;
                element.totalVacio = 0;
              }
              
              //Por último nos ocupamos de las fechas
              var fechaAuxi = new Date(element.fechaIni.replace("T", " "));
              element.fechaIniOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechaIni.replace('T', ' '))) +
                this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechaIni.replace('T', ' ')));
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaIni);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechaFin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaModFiltro = this.myFunctions.sqlToJsDateT(element.fechaMod);
              element.fechaModFiltro = this.myFunctions.datetimeToSQL(element.fechaModFiltro);
              fechaAuxi = new Date(element.fechaFin.replace("T", " "));
              element.fechaFinOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechaFin.replace('T', ' '))) +
                this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechaFin.replace('T', ' ')));
              if (this.usuarioIdDbCAF && element.usaLotes == 0)
                element.auxiCantidadLote = Number(element.loteCAF);
              else if ((this.usuarioIdDbCAF && element.usaLotes == 1) || (!this.usuarioIdDbCAF))
                element.auxiCantidadLote = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.cantidadLote).toString();
              element.lote = Number(element.loteCAF); // aunque se llame loteCAF es lote normal en el resto de DB
              if(element.maquinas == "H35") //ÑAPA TTTBERGARA
                element.cantidad = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.pesoTotal).toFixed(0).toString();
              else{
                element.cantidad = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.cantidadLote).toString();
              }
              element.cantidadMalas =  Number(element.cantidadApartadas).toString() + " (+" + Number(element.cantidadAchatarradas).toString() + ")";
              // si es induccion o inyeccion / ahora vienen en la misma consulta
              // if (element.tipo_maquina == 5)
              //   this.cargarInfRecetas(element);
              // else if (element.tipo_maquina == 4)
              //   this.cargarParametrosTolerancia(element);
            
              this.usaLotes = this.usaLotes && element.usaLotes;
            });

          this.gridView = filterBy(this.Jpiezas, this.filtroToKendo());
          this.loadingPanel = false;
        }
      );
  }

  cargarDatosConParametrosUrl() {

    var fechaInicio = this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fechaIni']);
    var fechaFin = this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fechaFin']);
    var ofs = this.route.snapshot.params.idOf.split("_");
    var clientes = this.route.snapshot.params.idCliente.split("_");
    var piezas = this.route.snapshot.params.idPieza.split("_");

    this.historicoOperacionesService.get_piezas(fechaInicio, fechaFin,
      [].join(), [].join(), [].join(), [].join(),
      [].join(), [].join(), piezas.join(), [].join(), [].join(),
      this.terminados, this.translateService.instant("desconocido"), 4, 6, "").subscribe(
        (json: any) => {
          // console.log("PIEZAS", json);
          this.Jpiezas = json.data;
          this.piezasSelecteds = [];
          var dict: any = {};

          this.datosFiltro.group.forEach(groups => {
            groups.group.forEach(element => {
              if (element.columna.tipo == 'date') {
                this.fechaIniUltimaConsulta = element.fechaIni;
                this.fechaFinUltimaConsulta = element.fechaFin;
              }
            });
          });

          if (json.imagenes.length > 0) {
            //Tenemos las imagenes, creamos el diccionario
            json.imagenes.forEach(element => {
              dict[element.imagen] = element.imagenBASE64;
            });
          }
          this.gridView = this.Jpiezas;//.slice(this.skip, this.skip + this.pageSize);
          if (this.gridView != null && this.gridView.length > 0)
            this.gridView.forEach(element => {
              //#region poner con formato los tiempos
              element.tiempoEstimadoTotalFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoEstimadoTotal);
              element.tiempoRealFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoRealEjecucion + element.tiempoRealPreparacion);
              element.tiempoRealEjecucionFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoRealEjecucion);
              element.tiempoRealPreparacionFormateado = this.myFunctions.secondsTo_HH_MM(element.tiempoRealPreparacion);
              //#endregion
              //Primero procesamos los operarios
              var operarios = element.operarios.split(",");
              var operariosAuxi = [];
              var operariosAuxi2 = [];
              operarios.forEach(operario => {
                var nombre;
                var apellido;
                if (operario.split(';').length > 0) {
                  if (operario == this.translateService.instant("desconocido")) {
                    operariosAuxi.push("undefined");
                    operariosAuxi2.push("undefined");
                  } else {
                    nombre = operario.split(';')[0];
                    apellido = operario.split(';')[1];
                    if (apellido != undefined)
                      operariosAuxi2.push(nombre + " " + apellido);
                    else
                      operariosAuxi2.push(nombre);
                    if (nombre != undefined)
                      nombre = nombre.trim().substring(0, 1).toUpperCase();
                    if (apellido != undefined)
                      apellido = apellido.trim().substring(0, 1).toUpperCase();
                    operariosAuxi.push(nombre + apellido);
                  }
                }
              });
              element.operariosAuxi2 = operariosAuxi2.join(";");
              element.operariosAuxi = operariosAuxi.join(",");
              //Ahora seguimos con las maquinas
              var maquinas = element.maquinas.split(",");
              var maquinasAuxi = [];
              var maquinasAuxi2 = [];
              maquinas.forEach(maquina => {
                if (maquina == this.translateService.instant("desconocido")) {
                  maquinasAuxi2.push("undefined");
                  maquinasAuxi.push("undefined");
                } else {
                  var nombre = maquina.trim().substring(0, 1).toUpperCase();
                  var apellido = maquina.trim().substring(1, 2).toUpperCase();
                  maquinasAuxi2.push(maquina);
                  maquinasAuxi.push(nombre + apellido);
                }
              });
              element.maquinasAuxi2 = maquinasAuxi2.join(";");
              element.maquinasAuxi = maquinasAuxi.join(",");
              console.log("Maquinas");
              console.table(element.maquinasAuxi);
              //Ahora hay que corregir las imagenes de las maquinas
              var imagenes = element.maquinasIm.split(';and;');
              var auxiImagenes = "";
              imagenes.forEach(imagen => {
                auxiImagenes += dict[imagen] + ";and;";
              });
              element.maquinasIm = auxiImagenes;
              //Por ultimo, ponemos bien las operaciones
              //Necesitamos sumar el total de las operaciones
              if(element.operaciones!=null && element.cantidades!=null && element.ordenOperaciones!=null){
                var opera = element.operaciones.split(';and;');
                var cant = element.cantidades.split(';and;');
                var ordenOperaciones = element.ordenOperaciones.split(';and;');
                element.cant = cant;
                element.opera = opera;
                element.ordenOperaciones = ordenOperaciones;
                var sumTotalHechas = 0;
                var sumTotalTotales = 0;
                var encontrada = false;
                cant.forEach((element6, index) => {
                  if (element6.split("/")[0] != element6.split("/")[1] && !encontrada) {
                    element.operacionActual = opera[index];
                    encontrada = true;
                  }
                  if (Number(element6.split("/")[0]) == Number(element6.split("/")[1])) {
                    sumTotalHechas += 1;
                  }
                  sumTotalTotales += 1;
                });
                element.hechasTotal = sumTotalHechas;
                element.total = sumTotalTotales;
                element.porcen = ((element.hechasTotal / element.total) * 100).toFixed(0);
                if (element.porcen > 100) {
                  element.porcen = 100;
                }
                element.porcenGrafico = Math.max(element.porcen, 10);
                element.terminado = (element.terminado == 1) ? true : false;

                // cotas calidad
                element.totalOK = 0;
                element.totalMal = 0;
                element.totalVacio = 0;
                var valoresArray;
                element.valores.split(';').forEach(val => {
                  valoresArray = val.split('/');
                  element.totalOK += parseInt(valoresArray[0]);
                  element.totalMal += parseInt(valoresArray[1]);
                  element.totalVacio += parseInt(valoresArray[2]);
                });
              }
              else{
                element.cant = [];
                element.opera = [];
                element.ordenOperaciones = [];
                element.operacionActual = null;
                element.hechasTotal = 0;
                element.total = 0;
                element.porcen = 0;
                element.porcenGrafico = Math.max(element.porcen, 10);
                element.terminado = false;
                // cotas calidad
                element.totalOK = 0;
                element.totalMal = 0;
                element.totalVacio = 0;
              }
              
              //Por último nos ocupamos de las fechas
              var fechaAuxi = new Date(element.fechaIni.replace("T", " "));
              element.fechaIniOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechaIni.replace('T', ' '))) +
                this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechaIni.replace('T', ' ')));
              element.fechaIniFiltro = this.myFunctions.sqlToJsDateT(element.fechaIni);
              element.fechaIniFiltro = this.myFunctions.datetimeToSQL(element.fechaIniFiltro);
              element.fechaFinFiltro = this.myFunctions.sqlToJsDateT(element.fechaFin);
              element.fechaFinFiltro = this.myFunctions.datetimeToSQL(element.fechaFinFiltro);
              element.fechaModFiltro = this.myFunctions.sqlToJsDateT(element.fechaMod);
              element.fechaModFiltro = this.myFunctions.datetimeToSQL(element.fechaModFiltro);
              fechaAuxi = new Date(element.fechaFin.replace("T", " "));
              element.fechaFinOrden = this.myFunctions.dateToYYYYMMDDHHmmSS(fechaAuxi) + " " + this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(element.fechaFin.replace('T', ' '))) +
                this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(element.fechaFin.replace('T', ' ')));
              if (this.usuarioIdDbCAF && element.usaLotes == 0)
                element.auxiCantidadLote = Number(element.loteCAF);
              else if ((this.usuarioIdDbCAF && element.usaLotes == 1) || (!this.usuarioIdDbCAF))
                element.auxiCantidadLote = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.cantidadLote).toString();
              element.lote = Number(element.loteCAF); // aunque se llame loteCAF es lote normal en el resto de DB

              element.cantidad = Number(element.cantidadLoteHechas).toString() + "/" + Number(element.cantidadLote).toString();

              // si es induccion o inyeccion/ ahora vienen en la misma consulta
              // if (element.tipo_maquina == 5)
              //   this.cargarInfRecetas(element);
              // else if (element.tipo_maquina == 4)
              //   this.cargarParametrosTolerancia(element);
            });
            
          this.gridView = filterBy(this.Jpiezas, this.filtroToKendo());
          this.loadingPanel = false;
        }
      );
  }

  clickAnalitica(idHP) {

    // filtro fechas
    var fini = this.myFunctions.dateToYYYY_MM_DD(this.periodoActualSeleccionado[0]); //FORMATO: YYYY_MM_DD
    var ffin = this.myFunctions.dateToYYYY_MM_DD(this.periodoActualSeleccionado[1]); //FORMATO: YYYY_MM_DD

    // filtro generales
    var idsTurnos = [];
    if (this.turnosSeleccionados != undefined)
      this.turnosSeleccionados.forEach(of => idsTurnos.push(of.idTurno));

    var idMaquinas = [];
    if (this.maquinasSeleccionadas != undefined)
      this.maquinasSeleccionadas.forEach(of => idMaquinas.push(of.idMaquina));

    var idsHerramientas = [];
    if (this.herramientasSeleccionadas != undefined)
      this.herramientasSeleccionadas.forEach(of => idsHerramientas.push(of.idHerramienta));

    var idsOperarios = [];
    if (this.operariosSeleccionados != undefined)
      this.operariosSeleccionados.forEach(operario => idsOperarios.push(operario.idOperario));

    // filtro ofs...
    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idPiezas = [];
    if (this.piezasSeleccionadas != undefined)
      this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.idPieza));

    var idPlanos = [];
    if (this.planosSeleccionadas != undefined)
      this.planosSeleccionadas.forEach(plano => idPlanos.push(plano.numeroPlano));

    var idPartes = [];
    if (this.partesSeleccionadas != undefined)
      this.partesSeleccionadas.forEach(parte => idPartes.push(parte.idParte));

    var idOperaciones = [];
    if (this.operacionesSeleccionadas != undefined)
      this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.idOperacion));

    var ofs = this.myFunctions.listToStirng(idsOFs, "_", "0");
    var clientes = this.myFunctions.listToStirng(idsClientes, "_", "0");
    var piezas = this.myFunctions.listToStirng(idPiezas, "_", "0");
    var planos = this.myFunctions.listToStirng(idPlanos, "_", "0");

    var terminados;
    if (this.terminados) terminados = 1;
    else terminados = 0;
    
    window.open('#/analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + clientes + '/' + piezas + '/' + ofs + '/' + terminados + "/" + idHP + "/0", '_blank');
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      var selectedValue = this.gridView.filter(f => f.id == this.piezasSelecteds[this.piezasSelecteds.length-1]);
      if(selectedValue.length > 0)
      this.piezasSelecteds = [selectedValue[0].id]; // si se clica algo que no entra dentro del filtro, no se autodesselecciona. de esta forma se hace a mano.
      // filtro fechas
      // var fini = this.myFunctions.dateToYYYY_MM_DD(this.periodoActualSeleccionado[0]); //FORMATO: YYYY_MM_DD
      // var ffin = this.myFunctions.dateToYYYY_MM_DD(this.periodoActualSeleccionado[1]); //FORMATO: YYYY_MM_DD
      var fini = this.myFunctions.dateToYYYY_MM_DD(this.fini_filtro); //FORMATO: YYYY_MM_DD
      var ffin = this.myFunctions.dateToYYYY_MM_DD(this.ffin_filtro); //FORMATO: YYYY_MM_DD

      // filtro generales
      var idsTurnos = [];
      if (this.turnosSeleccionados != undefined)
        this.turnosSeleccionados.forEach(of => idsTurnos.push(of.idTurno));

      var idMaquinas = [];
      if (this.maquinasSeleccionadas != undefined)
        this.maquinasSeleccionadas.forEach(of => idMaquinas.push(of.idMaquina));

      var idsHerramientas = [];
      if (this.herramientasSeleccionadas != undefined)
        this.herramientasSeleccionadas.forEach(of => idsHerramientas.push(of.idHerramienta));

      var idsOperarios = [];
      if (this.operariosSeleccionados != undefined)
        this.operariosSeleccionados.forEach(operario => idsOperarios.push(operario.idOperario));

      // filtro ofs...
      var idsOFs = [];
      if (this.ofsSeleccionados != undefined)
        this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

      var idsClientes = [];
      if (this.clientesSeleccionados != undefined)
        this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

      var idPiezas = [];
      if (this.piezasSeleccionadas != undefined)
        this.piezasSeleccionadas.forEach(pieza => idPiezas.push(pieza.idPieza));

      var idPlanos = [];
      if (this.planosSeleccionadas != undefined)
        this.planosSeleccionadas.forEach(plano => idPlanos.push(plano.numeroPlano));

      var idPartes = [];
      if (this.partesSeleccionadas != undefined)
        this.partesSeleccionadas.forEach(parte => idPartes.push(parte.idParte));

      var idOperaciones = [];
      if (this.operacionesSeleccionadas != undefined)
        this.operacionesSeleccionadas.forEach(operacion => idOperaciones.push(operacion.idOperacion));

      var turnos = this.myFunctions.listToStirng(idsTurnos, "_", "0");
      var maquinas = this.myFunctions.listToStirng(idMaquinas, "_", "0");
      var herramientas = this.myFunctions.listToStirng(idsHerramientas, "_", "0");
      var operarios = this.myFunctions.listToStirng(idsOperarios, "_", "0");

      var ofs = this.myFunctions.listToStirng(idsOFs, "_", "0");
      var clientes = this.myFunctions.listToStirng(idsClientes, "_", "0");
      var piezas = this.myFunctions.listToStirng(idPiezas, "_", "0");
      var planos = this.myFunctions.listToStirng(idPlanos, "_", "0");
      var partes = this.myFunctions.listToStirng(idPartes, "_", "0");
      var operaciones = this.myFunctions.listToStirng(idOperaciones, "_", "0");

      var piezasSeleccionadas = this.Jpiezas.filter(f=> f.id == this.piezasSelecteds[0]);
      if (piezasSeleccionadas.length > 0 ) {
        var piezaSeleccionada = piezasSeleccionadas[0]
        fini = this.myFunctions.dateToYYYY_MM_DD(this.myFunctions.sqlToJsDateT(piezaSeleccionada.fechaIni));
        ffin = this.myFunctions.dateToYYYY_MM_DD(this.myFunctions.sqlToJsDateT(piezaSeleccionada.fechaFin));
      }

      if (selectedValue[0].tipo_maquina == 5 || selectedValue[0].tipo_maquina == 4) { // hornos o inyectoras
        const url = this.router.serializeUrl(this.router.parseUrl('#/historicoOperacionesInduccion/' + this.piezasSelecteds[0] + "/" + fini + '/' + ffin + '/' + turnos + '/' + maquinas + '/' + herramientas + '/' + operarios + '/' + ofs + '/' + clientes + '/' + piezas + '/' + partes + '/' + operaciones));
        window.open(url, '_blank');
      } else {
        //this.router.navigate(['analiticaavanzadaejecuciones/' + fini + '/' + ffin + '/' + row.idCliente + '/' + row.idPieza + '/' + row.idOF]);
        //if (e.ctrlKey) {
        const url = this.router.serializeUrl(this.router.parseUrl('#/historicoOperaciones/' + this.piezasSelecteds[0] + "/" + fini + '/' + ffin + '/' + turnos + '/' + maquinas + '/' + herramientas + '/' + operarios + '/' + ofs + '/' + clientes + '/' + piezas + '/' + partes + '/' + operaciones));
        window.open(url, '_blank');
        //}
        //else {
        //  this.router.navigate(['historicoOperaciones/' + this.piezasSelecteds[0] + "/" + fini + '/' + ffin + '/' + turnos + '/' + maquinas + '/' + herramientas + '/' + operarios + '/' + ofs + '/' + clientes + '/' + piezas + '/' + partes + '/' + operaciones]);
        //}
      }

    }

  }

  loadItems() {
    this.gridView = this.Jpiezas;
  }

  btnFinalizar() {
    var IDs: any = [];
    var IDsSoloFinalizados: any = [];
    var an: any = this.gridView
    an.forEach(
      row => {
        if (this.piezasSelecteds.includes(row.id) &&
          (row.estado == 1)) {
          row.estado = 2;
          row.estadoTexto = 'acabada';
          row.claseEstado = 'fas fa-check icono-estado-acabada';
          IDs.push(row.id);
        }
      });
    if (IDs.length > 0) this.historicoOperacionesService.Set_estadoS(IDs.join(), IDsSoloFinalizados.join(), 2, 'a').subscribe(
      json => {
        var hayFecha = false;
        this.datosFiltro.group.forEach(groups => {
          groups.group.forEach(element => {
            if (element.columna.tipo == 'date') {
              hayFecha = true;
            }
          });
        });
        var filtroCompleto: any = this.filtroToSQL(); // filtro completo
        if (!hayFecha) { // es necesario hacer la consulta con el filtro ya que no hay fechas 
          this.cargarPiezas(filtroCompleto);
        } else {
          this.cargarPiezas();
        }
      });
  }

  btnValidar() {
    if (this.piezasSelecteds.length > 0) {
      this.tipoPopup = 1;
      this.tituloModal = this.translateService.instant("validarPieza");
      this.Jobservacion = '';
      this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }

  }

  // btnApartarPieza() {
  //   if (this.piezasSelecteds.length > 0) {
  //     this.tipoPopup = 2;
  //     this.tituloModal = this.translateService.instant("apartarPieza");
  //     this.Jobservacion = '';
  //     this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  //   }
  // }
  btnApartarPieza() {
    //EL ESTADO SE CAMBIA DESPUESD EL POPUP
    this.tipoPopup = 2;
    this.tituloModal = this.translateService.instant("apartarPieza");
    this.Jobservacion = '';
    this.JperdidasSelected = {value: -1, text: "", solicitarOperacion: false};
    this.Joperaciones_perdidaSelected = {value: -1, text: ""};
    this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  btnmandarProduccion() {
    var IDs: any = [];
    var IDsSoloFinalizados: any = []; // las que ya estaban finalizadas se pasan aparte para quitarlas de la OF.
    var an: any = this.gridView
    an.forEach(
      row => {
        if (this.piezasSelecteds.includes(row.id) &&
          (row.estado == 2 || row.estado == 4 || row.estado == 5)) { //tambien se pueden recuperar las piezas achatarradas
          row.estado = 1;
          row.estadoTexto = 'enProceso';
          row.claseEstado = 'fas fa-cogs icono-estado-enProceso';
          IDs.push(row.id);
          if (row.estado = 2) IDsSoloFinalizados.push(row.id);
        }
      });
    if (IDs.length > 0) this.historicoOperacionesService.Set_estadoS(IDs.join(), IDsSoloFinalizados.join(), 1, 'a').subscribe(
      json => {
        var hayFecha = false;
        this.datosFiltro.group.forEach(groups => {
          groups.group.forEach(element => {
            if (element.columna.tipo == 'date') {
              hayFecha = true;
            }
          });
        });
        var filtroCompleto: any = this.filtroToSQL(); // filtro completo
        if (!hayFecha) { // es necesario hacer la consulta con el filtro ya que no hay fechas 
          this.cargarPiezas(filtroCompleto);
        } else {
          this.cargarPiezas();
        }
      });
  }

  // btnAchatarrar() {
  //   if (this.piezasSelecteds.length > 0) {
  //     this.tipoPopup = 3;
  //     this.tituloModal = this.translateService.instant("achatarrarPieza");
  //     this.Jobservacion = '';
  //     this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  //   }

  // }
  btnAchatarrar() {
    //EL ESTADO SE CAMBIA DESPUESD EL POPUP
    this.tipoPopup = 3;
    this.tituloModal = this.translateService.instant("achatarrarPieza");
    this.Jobservacion = '';
    this.JperdidasSelected = {value: -1, text: "", solicitarOperacion: false};
    this.Joperaciones_perdidaSelected = {value: -1, text: ""};
    this.modalReference = this.modalService.open(this.popup, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  btnPopupAceptar() {
    if (this.tipoPopup == 1) {
      var IDs: any = [];
      var IDsSoloFinalizados: any = []; // las que ya estaban finalizadas se pasan aparte para quitarlas de la OF.
      var an: any = this.gridView
      an.forEach(
        row => {
          if (this.piezasSelecteds.includes(row.id) &&
            (row.estado == 1 || row.estado == 2)) {
            row.estado = 3;
            row.estadoTexto = 'validada';
            row.claseEstado = 'fas fa-check icono-estado-validada';
            IDs.push(row.id);
            if (row.estado = 2) IDsSoloFinalizados.push(row.id);
          }
        });
      if (IDs.length > 0) {
        this.historicoOperacionesService.Set_estadoS(IDs.join(), IDsSoloFinalizados.join(), 3, this.Jobservacion).subscribe(
          json => {
            var hayFecha = false;
            this.datosFiltro.group.forEach(groups => {
              groups.group.forEach(element => {
                if (element.columna.tipo == 'date') {
                  hayFecha = true;
                }
              });
            });
            var filtroCompleto: any = this.filtroToSQL(); // filtro completo
            if (!hayFecha) { // es necesario hacer la consulta con el filtro ya que no hay fechas 
              this.cargarPiezas(filtroCompleto);
            } else {
              this.cargarPiezas();
            }
          });
        this.modalReference.close();
      } else {
        this.modalReference.close();
      }
    } else if (this.tipoPopup == 2) {
      var IDs: any = [];
      var IDsSoloFinalizados: any = []; // las que ya estaban finalizadas se pasan aparte para quitarlas de la OF.
      var an: any = this.gridView
      an.forEach(
        row => {
          if (this.piezasSelecteds.includes(row.id) &&
            (row.estado == 1 || row.estado == 2)) {
            row.estado = 4;
            row.estadoTexto = 'apartada';
            row.claseEstado = 'fas fa-times icono-estado-apartada';
            IDs.push(row.id);
            if (row.estado = 2) IDsSoloFinalizados.push(row.id);
          }
        });
      if (IDs.length > 0) {
        this.historicoOperacionesService.Set_estadoS(IDs.join(), IDsSoloFinalizados.join(), 4, this.Jobservacion).subscribe(
          json => {
            var hayFecha = false;
            this.datosFiltro.group.forEach(groups => {
              groups.group.forEach(element => {
                if (element.columna.tipo == 'date') {
                  hayFecha = true;
                }
              });
            });
            var filtroCompleto: any = this.filtroToSQL(); // filtro completo
            if (!hayFecha) { // es necesario hacer la consulta con el filtro ya que no hay fechas 
              this.cargarPiezas(filtroCompleto);
            } else {
              this.cargarPiezas();
            }
          })
        this.modalReference.close();
      } else {
        this.modalReference.close();
      }
    } else if (this.tipoPopup == 3) {
      var IDs: any = [];
      var IDsSoloFinalizados: any = []; // las que ya estaban finalizadas se pasan aparte para quitarlas de la OF.
      var an: any = this.gridView
      an.forEach(
        row => {
          if (this.piezasSelecteds.includes(row.id) &&
            (row.estado == 1 || row.estado == 2 || row.estado == 4)) {
            row.estado = 5;
            row.estadoTexto = 'chatarra';
            row.claseEstado = 'fas fa-times icono-estado-chatarra';
            IDs.push(row.id);
            if (row.estado = 2) IDsSoloFinalizados.push(row.id);
          }
        });
      if (IDs.length > 0) {
        this.historicoOperacionesService.Set_estadoS(IDs.join(), IDsSoloFinalizados.join(), 5, this.Jobservacion).subscribe(
          json => {
            var hayFecha = false;
            this.datosFiltro.group.forEach(groups => {
              groups.group.forEach(element => {
                if (element.columna.tipo == 'date') {
                  hayFecha = true;
                }
              });
            });
            var filtroCompleto: any = this.filtroToSQL(); // filtro completo
            if (!hayFecha) { // es necesario hacer la consulta con el filtro ya que no hay fechas 
              this.cargarPiezas(filtroCompleto);
            } else {
              this.cargarPiezas();
            }
          })
        this.modalReference.close();
      } else {
        this.modalReference.close();
      }
    }
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  //Cargar datos de la ruta
  cargarURLRuta(){
    // URL: \\configuracion_variables(servSmb_GestorDocumental)\OFs_Rutas_GestionDocumental(fichero)
    if (this.piezasSelecteds.length == 0 ){
      // mensaje de error no hay ninguna pieza seleccionada
      this.alertService.error(this.translateService.instant('piezaNoSeleccionada'));
    }
    else if(this.piezasSelecteds.length > 1){
      // mensaje de error hay mas de una pieza seleccionada
      this.alertService.error(this.translateService.instant('dseleccionarSoloUnaPieza'));
    }
    else{
      // copiar direccion de la ruta
      // console.log(this.piezasSelecteds[0])
      this.historicoOperacionesService.obtenerDireccionRuta(Number.parseInt(this.piezasSelecteds[0])).subscribe((data: any) => {
        var dir = data.data[0];
        this.fichero = dir.fichero;
        this.fichero = this.fichero.replaceAll(' ', '');
        this.fichero = this.fichero.replaceAll('/', '\\');
        navigator.clipboard.writeText(this.fichero);
        // console.log(this.fichero)
      });
      this.alertService.success(this.translateService.instant('direccionCopiada'));
    }

  }

  imprimirXls(e, grid: GridComponent) {
    this.exportandoExcel = true;
    e.preventDefault();
    var that = this;
    setTimeout(function () {
      try {
        grid.saveAsExcel();
        that.exportandoExcel = false;
      }
      catch (e) {
        console.log("ERROR AL EXPORTAR EL EXCEL")
      }
    });
  }

  //IMPRIMIR EXCEL SIN TENER EN CUENTA LA PAGINACION
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridView, this.state).data,
    };
    return result;
  }

  // // INDUCCION
  // cargarInfRecetas(op) {

  //   // this.gridView.forEach(op => {
  //     this.recetasInduccionService.Get_recetas(op.idHO).subscribe(
  //       json => {
  //         var data: any = json;
  //         this.cantidadAcumulada = 0;
  //         data.forEach(row => {
  //           this.cantidadAcumulada += row["piezas"];
  //         });
  //         op.cantidad = this.cantidadAcumulada + "/" + op.cantidadTotal;
  //       });
  //   // });
  // }

  // // INYECCION
  // cargarParametrosTolerancia(op) {

  //   var fechaInicio = this.datosFiltro.group[0].group[0].fechaIni
  //   var fechaFin = this.datosFiltro.group[0].group[0].fechaFin

  //   var fini = new Date(fechaInicio);
  //   var ffin = new Date(fechaFin);
     
  //   // obtener la informacion de la tolerancia de cada operacion
  //   this.historicoOperacionesService.Get_procesos_inyeccion(op.idHO).subscribe(
  //     (result: any) => {
  //       this.cantidadAcumulada = 0;
  //       result.forEach(element => {
  //         this.cantidadAcumulada += element["nPiezas"];     
  //       });

  //       op.cantidad = this.cantidadAcumulada + "/" + op.cantidadTotal;;

  //     });
    
  // }

}
