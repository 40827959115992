import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/diccionarioperdidas`;

@Injectable()
export class DiccionarioPerdidasService {

  constructor(private http: HttpClient) { }

  public GetById(id: number): Observable<any> {

    return this.http.get(baseUrl + "/" + id);
  }

  public getSubtipos(id: number): Observable<any> {

    return this.http.get(baseUrl + "/getSubtipos/" + id);
  }
  
  public getSubtipos_annadir() {
    return this.http.post<any>(baseUrl + "/getSubtipos_annadir", { withCredentials: true });
  }
  
  public update_Subtipos(idPadre, idHijos) {
    return this.http.post<any>(baseUrl + "/update_Subtipos",{ idPadre, idHijos }, { withCredentials: true });
  }

  public GetAll(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl);
  }

  public GetAll_TiposPerdidas_DAT(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/tiposperdidas");
  }

  insert(data, idTipoObservacion_DAT, idTipoPerdida_DAT, idTipoGravedad_DAT, idsMaquinas, listaIdsHijos) {
    return this.http.post<any>(baseUrl + "/crear", { idTipoPerdida_DAT: idTipoPerdida_DAT, idTipoGravedad_DAT: idTipoGravedad_DAT, nombre: data.nombre
                          , descripcion: data.descripcion, requerido: idTipoObservacion_DAT, operacion: data.operacion, seguimiento: data.seguimiento
                          , idsMaquinas: idsMaquinas, tieneSubtipos: data.tieneSubtipos, idPadre: data.idPadre, esPreparacion: data.esPreparacion
                          , idDiccionarioPerdidaTipo: data.idDiccionarioPerdidaTipo, idGrupoPerdida: data.idGrupoPerdida, atributos: data.atributos, listaIdsHijos: listaIdsHijos }, { withCredentials: true });
  }

  update(data, idTipoObservacion_DAT, idTipoPerdida_DAT, idTipoGravedad_DAT, idsMaquinas, listaIdsHijos) {
    return this.http.post<any>(baseUrl + "/editar", { id: data.id, idTipoPerdida_DAT: idTipoPerdida_DAT, idTipoGravedad_DAT: idTipoGravedad_DAT, nombre: data.nombre
                          , descripcion: data.descripcion, requerido: idTipoObservacion_DAT, operacion: data.operacion, seguimiento: data.seguimiento
                          , idsMaquinas: idsMaquinas, tieneSubtipos: data.tieneSubtipos, idPadre: data.idPadre, esPreparacion: data.esPreparacion
                          , idDiccionarioPerdidaTipo: data.idDiccionarioPerdidaTipo, idGrupoPerdida: data.idGrupoPerdida, atributos: data.atributos, listaIdsHijos: listaIdsHijos }, { withCredentials: true });
  }

  delete(data?: any): Observable<any> {

    var formData: any = new FormData();
    formData.append("ids", data.ids);

    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });
  }

  //#region DiccionarioPerdidas Grupos
  public GetAllGrupos(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/getGruposDiccionarioPerdidas");
  }

  public GetAllGruposWithPerdidas(action: string = '', param?: string, data?: ''): Observable<any> {

    var headers, params;

    if (param == undefined) {
      param = ''
    }

    return this.http.get(baseUrl + "/getGruposDiccionarioPerdidas_diccionarioPerdidas");
  }

  getGrupoDiccionarioPerdidasById(idGrupo:string): Observable<any> {
    return this.http.get(`${baseUrl}/getGrupoDiccionarioPerdidasById/${idGrupo}`, { withCredentials: true });
  }

  createGrupoDiccionarioPerdidas(params) {
    return this.http.post(baseUrl + "/createGrupoDiccionarioPerdidas", params);
  }

  updateGrupoDiccionarioPerdidas(params) {
    return this.http.post(baseUrl + "/updateGrupoDiccionarioPerdidas", params);
  }

  deleteGrupoDiccionarioPerdidas(params) {
    return this.http.post(baseUrl + "/deleteGrupoDiccionarioPerdidas", params);
  }
  //#endregion

  public GetAll_datosMaquinas(idPerdida: number): Observable<any> {
    return this.http.get(`${baseUrl}/GetAll_datosMaquinas/${idPerdida}`, { withCredentials: true });
  }

  getDiccionarioPerdidasTipo() {
    return this.http.post<JSON>(`${baseUrl}/getDiccionarioPerdidasTipo`, {}, { withCredentials: true });
  }

  update_ordenesBULK(dt) {
    // return this.http.post(baseUrl + "/update_ordenesBULK", dt);
    return this.http.post<JSON>(`${baseUrl}/update_ordenesBULK`, {dt}, { withCredentials: true });
  }

}
