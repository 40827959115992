import { Component } from '@angular/core';

import { FormGroup, FormControl, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { GridDataResult, DataStateChangeEvent, RowArgs } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, DataResult } from '@progress/kendo-data-query';

import { Observable } from 'rxjs';
import { UploadEvent, FileInfo } from '@progress/kendo-angular-upload';

import { MyFunctions } from '@app/_helpers';

import { FileRestrictions, SelectEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";

import { ExcelExportData } from '@progress/kendo-angular-excel-export';

import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService, AlertService, BomService, GestionDocumentalService, SeriesService, MenuService, RutasService, MaquinasService, PiezasService, RutasPredefinidasService, InformeProyectosService } from '@app/_services';
import { Usuario } from '@app/_models';
import { MustMatch } from '@app/_helpers';
import { OperacionesPredefinidasService } from '../../_services/operacionesPredefinidas.service';
import { OperacionesService } from '../../_services/operaciones.service';
import { PiezasPredefinidasService } from '../../_services/piezasPredefinidas.service';

import { environment } from '@environments/environment';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import * as moment from 'moment';
import { TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-piezaspredefinidasdetalle-data',
  templateUrl: 'piezasPredefinidasDetalle.html'
})

export class PiezasPredefinidasDetalleComponent {



  public idOF: number;
  public idParte: number;
  public decimals: number;
  public operaciones: [];
  public historicocambios: any = [];
  public bom: [];
  public gestiondocumental: any = [];
  public series: [];
  public referencias: [];
  public rutas: any = [];
  public imagePreviews: any[] = [];
  public events: string[] = [];
  //Minimos y maximos de los numericos
  public isMultiple: boolean;
  public minNum = 0;
  public maxNum = 999999;
  public restrictions: FileRestrictions = {
    allowedExtensions: ['.png'],
    maxFileSize: 1048576
  };
  public idPieza: number;
  cantidadAgrupada: number;
  nombre: String;
  numplano: string;
  refpieza: string;
  iderppieza: number;
  espieza: Boolean;
  limitado: Boolean;
  cantidad: number;
  hechos: number;
  coste: number;
  dureza: number;
  rutasmultiples: Boolean;
  terminado: Boolean;
  descripcion: String;
  refparte: String;
  parte: String;
  orden: number;
  tienerutas: Boolean;
  idERPparte: number;
  referencia: any;
  public botonesActivados: Boolean;
  public idRuta: number;


  public autoCorrect: boolean = true;

  public operacionesseleccionados: number[] = [];
  public rutasseleccionados: number[] = [];
  user = this.userService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  modalReferenceloadingupload: NgbModalRef;
  isAddMode: boolean;
  public read: String = "";

  form: FormGroup;
  public limitesstr: String = '';
  public gestiondocumentalstr: String = '';
  public operacionessstr: String = '';
  public historicocambiosstr: String = '';
  public rutasstr: String = '';
  public seriestr: String = '';
  public bomstr: String = '';


  closeResult = '';


  fechaHoyStr: string;
  tituloPopUp: any;
  tituloPopUpPregunta: any;
  _kendoFiles: any;

  maquinas: any[];

  public activerutas: String;
  public activeoperaciones: String;

  navigationSubscription;
  fueraturno: any;
  fuerataller: any;
  cambiomaquina: any;
  ineficencia: any;
  continuo: any;
  prioridad: any;
  partido: any;
  fechainilimite: Date;
  fechafinlimite: Date;

  public tipoPiezaLista: any = [];

  tipoPieza: any;

  //Para comprobar el form de parte
  public errorReferenciaParte: boolean = false;
  public errorNombreParte: boolean = false;
  public errorOrdenParte: boolean = false;

  public submitted = false;

  public unicaRutaPrincipal: boolean = true; //Indica si la parte tiene una �nica ruta, que ser�a la principal, pues entonces no puede ser eliminada

  @ViewChild('popupBorrarDocumentos') popupBorrarDocumentos: NgbModalRef;

  @ViewChild('tabstrip') tabstrip: TabStripComponent;

  mySelectionGestionDocumental: any = [];
  loadingGestionDocumental = true;
  isDeletingGestionDocumental;
  selectedTab: number;

  //DEPENDENCIAS
  public dependenciasPieza = [];
  public comboPartes = [];

  @ViewChild('anadirOperacionPred') anadirOperacionPred: NgbModalRef;

  public operacionesPred: any = []; //Tiene el listado de operacionesPredefinidas con sus datos
  public operacionesPredSelected: any = []; //Tiene las operacionesPredefinidas seleccionadas

  constructor(
    public myFunctions: MyFunctions,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private translate: TranslateService,
    private piezasPredefinidasService: PiezasPredefinidasService,
    private piezasService: PiezasService,
    private bomService: BomService,
    private gestionDocumentalService: GestionDocumentalService,
    private seriesService: SeriesService,
    private operacionesService: OperacionesService,
    private operacionesPredefinidasService: OperacionesPredefinidasService,
    private modalService: NgbModal,
    private menuService: MenuService,
    private maquinasService: MaquinasService,
    private rutasService: RutasPredefinidasService,
    protected alertService: AlertService,
    private informeProyectosService: InformeProyectosService) {
    this.isMultiple = false;
    this.decimals = 0;
    var nav = this.router.getCurrentNavigation();
    this.menuService.titulo = this.translate.instant('piezaspredefinidas').toUpperCase();
  }

  ngOnInit() {


    this.idPieza = this.route.snapshot.params.idpieza;
    this.idOF = this.route.snapshot.params.idof;
    this.idParte = this.route.snapshot.params.idparte;

    this.referencias = [];
    this.tituloPopUp = document.getElementById("text_loading_popup");
    this.tituloPopUpPregunta = document.getElementById("titulo_modal_pregunta");
    this.botonesActivados = false;
    this.cantidadAgrupada = 1;
    this.limitesstr = this.translate.instant('limites');
    this.gestiondocumentalstr = this.translate.instant('gestiondocumental');
    this.operacionessstr = this.translate.instant('operaciones');
    this.historicocambiosstr = this.translate.instant('historicocambios');
    this.rutasstr = this.translate.instant('rutas');
    this.seriestr = this.translate.instant('series');
    this.bomstr = "BOM";
    this.isMultiple = false;


    this.piezasService.Get_TipoPieza_Combo().pipe().subscribe((result) => {
      this.tipoPiezaLista = result;
    });


    this.isAddMode = !(this.idParte > 0);

    this.form = this.formBuilder.group({
      id: [''],
      idDb: this.user.idDb,
      nombre: ['', Validators.required],
      numplano: ['', Validators.required],
      referencia: ['', Validators.required],
      idERPpieza: ['',],
      espieza: [true,],
      limitado: [false,],
      cantidad: [1,],
      hechos: [0,],
      coste: [0.00,],
      dureza: [0,],
      rutasmultiples: ['',],
      cantidadAgrupada: [1,],
      terminado: [false,],
      descripcion: ['',],
      refparte: ['',],
      parte: ['',],
      orden: [0,],
      archivo: new FormControl(''),
      tienerutas: [false,],
      usaLotes: [false,],
      idERPparte: ['',],
      idOF: [this.idOF],
      prioridad: [0,],
      partido: [''],
      fueraturno: [''],
      fuerataller: [''],
      cambiomaquina: [false,],
      fechainilimite: [''],
      fechafinlimite: [''],
      ineficencia: ['',],
      contino: ['',],
      fechainicio: new FormControl(this.myFunctions.getDateNow()),
      fechafin: new FormControl(this.myFunctions.getDateNow()),
      continuo: ['',],
      tiempocambiomaquina: ['',],
      tadescripcion: ['',],
      idpieza: ['',],
      tipo_pieza: [undefined],
      tipo_pieza_id: [undefined],
      cantidadLote: [0,],
      todos: [-1,]
    });


    this.piezasPredefinidasService.GetReferencias().pipe().subscribe((result) => {
      this.referencias = result.piezas;

    });

    if (this.idParte > 0) {

      this.CargarDatos();

    }
    else {

      this.espieza = true;
    }


  }

  CargarDatos() {

    this.piezasPredefinidasService.GetByID(this.idParte).pipe().subscribe(
      (result) => {
        this.isMultiple = result.pieza[0].tieneRutas;
        this.idPieza = result.pieza[0].idPieza;
        //DATOS
        if (result.pieza[0].imagenBase64 != "" && result.pieza[0].imagenBase64 != null) {
          this.imageToShow = result.pieza[0].imagenBase64;
        }

        // de esta forma nos quedamos con la parte que se está buscando y su asociada, para saber si es la principal
        var auxParte = result.pieza.filter(x => x.id == this.idParte)[0];
        // si es una pieza tiene que mostrar el control de dependencias entre partes
        if (auxParte.esPieza) {
          if (result.dependencias.length == 0)
            this.dependenciasPieza = [
              {
                id: -1
                , idPieza: this.idPieza
                , orden: 1
                , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
                , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
                , cantidad: 1
              }]
          else {
            this.dependenciasPieza = [];
            result.dependencias.forEach(
              row => {
                var padre = result.partes.filter(f => f.id == row.idPartePadre);
                var hijo = result.partes.filter(f => f.id == row.idParteHijo);
                if (padre.length > 0 && hijo.length > 0) {
                  var rowDep = {};
                  rowDep["id"] = row.id;
                  rowDep["idPieza"] = this.idPieza;
                  rowDep["orden"] = row.orden;
                  rowDep["padre"] = { id: padre[0].id, nombre: padre[0].nombre };
                  rowDep["hijo"] = { id: hijo[0].id, nombre: hijo[0].nombre };
                  rowDep["cantidad"] = row.cantidad;

                  this.dependenciasPieza.push(rowDep);
                }
              });
            this.dependenciasPieza.push(
              {
                id: -1
                , idPieza: this.idPieza
                , orden: 1
                , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
                , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
                , cantidad: 1
              });
          }
          this.comboPartes = result.partes;
        }
        else {
          this.dependenciasPieza = undefined;
          this.comboPartes = undefined;
        }

        this.tienerutas = result.pieza[0].tieneRutas;
        this.fechainilimite = null;
        this.fechafinlimite = null;
        this.prioridad = 0;
        this.cantidadAgrupada = 1;
        //this.fechafinlimite.setMonth(this.fechafinlimite.getMonth() + 1);
        if (result.pieza[0].fechaIni != undefined)
          this.fechainilimite = new Date(result.pieza[0].fechaIni);
        if (result.pieza[0].fechaFin != undefined)
          this.fechafinlimite = new Date(result.pieza[0].fechaFin);
        if (result.pieza[0].prioridad != undefined)
          this.prioridad = result.pieza[0].prioridad;
        if (result.pieza[0].cantidadAgrupada != undefined) {
          this.cantidadAgrupada = result.pieza[0].cantidadAgrupada;
        }

        if (result.limites != undefined && result.limites.length > 0) {

          this.fueraturno = result.limites[0].fueraTurno;
          this.fuerataller = result.limites[0].subcontratado;
          this.cambiomaquina = result.limites[0].cambiomaquina;
          this.prioridad = result.limites[0].prioridad;
          if (result.limites[0].fechaini != undefined)
            this.fechainilimite = new Date(result.limites[0].fechaini);
          if (result.limites[0].fechafin != undefined)
            this.fechafinlimite = new Date(result.limites[0].fechafin);


        }

        this.partido = false;
        this.fueraturno = false;
        this.fuerataller = false;
        this.cambiomaquina = false;

        this.ineficencia = false;
        this.continuo = false;

        if (result.limites != undefined && result.limites.length != 0) {
          this.prioridad = result.limites[0].prioridad;
          this.partido = result.limites[0].partido;
          this.fueraturno = result.limites[0].fueraTurno;
          this.fuerataller = result.limites[0].subcontratado;
          this.cambiomaquina = result.limites[0].cambioMaquina;

          this.ineficencia = result.limites[0].tiemposParadas;
          this.continuo = result.limites[0].continuo;
        }

        var tipo_pieza = this.tipoPiezaLista.find(x => x.id === result.pieza[0].tipo_pieza);
        if (tipo_pieza == undefined) tipo_pieza = { id: undefined };

        this.form = this.formBuilder.group({
          id: this.idPieza,
          nombre: [result.pieza[0].nombre, Validators.required],
          numplano: [result.pieza[0].plano, Validators.required],
          referencia: [result.pieza[0].refPieza, Validators.required],
          idERPpieza: [result.pieza[0].idERPPieza,],
          espieza: [(result.pieza[0].esPieza == true),],
          limitado: [(result.pieza[0].limitado == "True"),],
          cantidad: [result.pieza[0].cantidad,],
          cantidadAgrupada: [this.cantidadAgrupada,],
          hechos: [result.pieza[0].hechos,],
          coste: [result.pieza[0].coste,],
          dureza: [result.pieza[0].dureza,],
          rutasmultiples: [result.pieza[0].tieneRutas,],
          terminado: [result.pieza[0].terminado,],
          descripcion: [result.pieza[0].descripcion,],
          refparte: [result.pieza[0].refParte,],
          parte: [result.pieza[0].parte,],
          orden: [result.pieza[0].orden,],
          tienerutas: [result.pieza[0].tieneRutas,],
          usaLotes: [result.pieza[0].usaLotes,],
          idERPparte: [result.pieza[0].idERPParte,],
          idOF: [result.pieza[0].idOF,],
          prioridad: [this.prioridad,],
          partido: [this.partido,],
          fueraturno: [this.fueraturno],
          fuerataller: [this.fuerataller,],
          cambiomaquina: [this.cambiomaquina,],
          fechainicio: this.fechainilimite,
          fechafin: this.fechafinlimite,
          ineficencia: [this.ineficencia,],
          continuo: [this.continuo,],
          idpieza: [this.idPieza,],
          idparte: [this.idParte,],
          //tiempocambiomaquina: [result.limites[0].tiempoCambio,],
          tiempocambiomaquina: [0,],
          tadescripcion: [result.pieza[0].descripcion,],
          archivo: new FormControl(result.pieza[0].imagen),
          tipo_pieza: [tipo_pieza,],
          tipo_pieza_id: [tipo_pieza.id,],
          cantidadLote: [result.pieza[0].cantidadLote],
          todos: [-1]
        });

        this.referencia = result.pieza[0].refPieza;

        this.limitado = (result.pieza[0].limitado == "True");

        if (this.tienerutas == true) {
          this.activerutas = "active show";
          this.activeoperaciones = "";
          this.idRuta = result.rutas[0].id;

          var found = false;
          if (result.rutas != undefined) {
            var maxIndex = result.rutas.length;
            var index = 0;
            while (!found && index < maxIndex) {
              if (result.rutas[index].principal == 1) {
                this.idRuta = result.rutas[index].id;
                found = true;
              }
              index++;
            }
          }

        }
        else {

          this.activerutas = "";
          this.activeoperaciones = "active";
          this.idRuta = result.rutas[0].id;

          var found = false;
          if (result.rutas != undefined) {
            var maxIndex = result.rutas.length;
            var index = 0;
            while (!found && index < maxIndex) {
              if (result.rutas[index].principal == 1) {
                this.idRuta = result.rutas[index].id;
                found = true;
              }
              index++;
            }
          }


        }

        this.espieza = this.form.controls["espieza"].value;



        //GRIDS

        var dict: any = {};

        if (result.imagenesMaquinas.length > 0) {
          //Tenemos las imagenes, creamos el diccionario
          result.imagenesMaquinas.forEach(element => {
            dict[element.imagen] = element.imagenBASE64;
          });
        }

        

        result.operaciones.forEach(function (o) {
          //TIEMPOS
          o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
          o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
          o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
          o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
          o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
          o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
          //FIN TIEMPOS

          if (o.idsMaquinas) {
            var idsMaquinas: any = o.idsMaquinas.split(',');

            var maquinas = [];
            var maquinasImagenes = [];
            var prioridadesSeparadas = o.prioridadesMaquinas.split(',');
            var prioridadesFinal = [];
            idsMaquinas.forEach((idm, index) => {
              if (idm != "" && idm != "-1" && idm != "0") {
                prioridadesFinal.push(prioridadesSeparadas[index]);
                var m = result.nombresMaquinas.find(x => x.id == idm);
                if (m != undefined) {
                  maquinas.push(m.nombre);
                  maquinasImagenes.push(m.urlImagen);
                }
              }
            });
            var auxDictPrioridades = {}
            prioridadesFinal.forEach((prioridad, index) => {
              auxDictPrioridades[maquinas[index]] = prioridad;
            });
            o.prioridadMaquinasAux = auxDictPrioridades;
            o.maquina = maquinas.join(', ');
            //Ahora seguimos con las maquinas
            var maquinasAuxi = [];
            var maquinasAuxi2 = [];
            maquinas.sort((a, b) =>
              (Number(auxDictPrioridades[a]) > Number(auxDictPrioridades[b])) ? -1 :
                ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
            );
            maquinas.forEach(maquina => {
              if (maquina == this.translate.instant("desconocido")) {
                maquinasAuxi2.push("undefined");
                maquinasAuxi.push("undefined");
              } else {
                var nombre = maquina.trim().substring(0, 1).toUpperCase();
                var apellido = maquina.trim().substring(1, 2).toUpperCase();
                maquinasAuxi2.push(maquina);
                maquinasAuxi.push(nombre + apellido);
              }
            });
            o.maquinasAuxi2 = maquinasAuxi2.join(";");
            o.maquinasAuxi = maquinasAuxi.join(",");
            //Ahora hay que corregir las imagenes de las maquinas
            var imagenes = maquinasImagenes;
            var auxiImagenes = "";
            imagenes.forEach(imagen => {
              auxiImagenes += dict[imagen] + ";and;";
            });
            o.maquinasIm = auxiImagenes;
          }
          else {
            o.maquina = null;
          }
        }, this);
        this.operaciones = result.operaciones;
        this.historicocambios = result.historicoCambios;
        this.historicocambios.forEach(element => {
          element.fecha = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
          if (element.valorAnterior instanceof Date) {
            element.valorAnterior = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
            element.valorAnteriorTrad = "fecha";
          } else if (element.valorAnterior == "sinAsignar" || element.valorAnterior == "true" || element.valorAnterior == "false") {
            element.valorAnteriorTrad = "traducir"
          }
          if (element.valorNuevo instanceof Date) {
            element.valorNuevo = this.myFunctions.sqlToJsDate(element.fecha.replace("T", " "));
            element.valorNuevoTrad = "fecha";
          } else if (element.valorNuevo == "sinAsignar" || element.valorNuevo == "true" || element.valorNuevo == "false") {
            element.valorNuevoTrad = "traducir"
          }
        });
        this.gestiondocumental = result.gestiondocumental;
        this.bom = result.bom;
        this.series = result.series;
        this.botonesActivados = result.operaciones.length > 0;

        var auxData = this.myFunctions.getDateNow()
        var dd = auxData.getDate();
        var mm = auxData.getMonth() + 1;
        var yyyy = auxData.getFullYear();

        if (result.operaciones[0] != undefined) {
          if (result.operaciones[0].finicio == undefined)
            result.operaciones[0].finicio = mm + '-' + dd + '-' + yyyy;
          if (result.operaciones[0].fechaEntrega == undefined)
            result.operaciones[0].fechaEntrega = mm + '-' + dd + '-' + yyyy;
        }
        this.operaciones = result.operaciones;
        this.rutas = result.rutas;
        if (this.rutas != undefined && this.rutas.length == 1 && this.rutas[0].principal)
          this.unicaRutaPrincipal = true;
        else {
          this.unicaRutaPrincipal = false;
        }

        this.isAddMode = false;
        // console.log("llegamos hasta aquí bien");
        this.gestionDocumentalService.getAllDocumentosPredefinidas(this.idRuta).pipe().subscribe((result: any) => {
          this.gestiondocumental = result;
          this.loadingGestionDocumental = false;
          // console.log("cogemos los datos de la gestion documental");
          // console.log(result);
        });

        var that = this;
        setTimeout(function () {
          // console.log("marcamos que nos seleccione el tab correcto");
          that.tabstrip.selectTab(0);
          that.selectedTab = 0;
          // console.log("el tab correcto esta marcado");
        }, 10);
        // if (this.form.value.tienerutas) {
        //   this.tabstrip.selectTab(2);
        //   this.selectedTab = 2;
        //   //y deshabilitar los necesarios
        //   this.tabstrip.tablist.nativeElement.children[2].classList.remove("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[0].classList.add("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[1].classList.add("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[3].classList.add("k-state-disabled");
        // } else {
        //   this.tabstrip.selectTab(0);
        //   this.selectedTab = 0;
        //   //y deshabilitar los necesarios
        //   this.tabstrip.tablist.nativeElement.children[2].classList.add("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[0].classList.remove("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[1].classList.remove("k-state-disabled");
        //   this.tabstrip.tablist.nativeElement.children[3].classList.remove("k-state-disabled");
        // }
      });
      this.operacionesseleccionados = [];
  }


  selectionChange(e) {

    if (e == undefined || e.id == undefined) {
      this.espieza = true;
      this.isAddMode == true;
      return;
    }

    var id = e.id;

    this.form.controls['idpieza'].setValue(id);

    this.piezasPredefinidasService.GetByIDSelectedCombo({ idpieza: id }).subscribe((result) => {

      var data = result.pieza[0];
      var tipo_pieza = this.tipoPiezaLista.find(x => x.id === result.pieza[0].tipo_pieza);
      if (tipo_pieza == undefined) tipo_pieza = { id: undefined };
      this.form.patchValue({
        referecia: data.referencia,
        nombre: data.nombre,
        numplano: data.plano,
        idERPpieza: data.idERPPieza

      });
      this.form.value.tipo_pieza_id = tipo_pieza.id;
      this.form.value.tipo_pieza = tipo_pieza;
      this.tipoPieza = tipo_pieza;
      this.espieza = false;
      this.form.controls['espieza'].setValue(this.espieza);

      this.read = "readonly";



    });

  }

  //Codigo para popup guardar cambios en las pieza relacionadas a la pieza predefinida
  onClickGuardar(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  async actualizar2(contentloading, option) {
    this.modalReference.close();
    var filename = "";
    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      try {
        var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
        filename = this.form.value.archivo[0].name;
      } catch (error) {
        var archivoBase64: any = "";
      }
    } else {
      var archivoBase64: any = "";
    }
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.idOF = this.idOF;
    this.form.value.todos = option;
    this.piezasPredefinidasService.update(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        // this.modalReferenceloading.close();
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          var idgenerado = result.id;
          //Se preparan las dependencias para poder borrarlas y volver a insertarlas (mirar como se tratan en la API)
          var dtDependencias = [];

          this.dependenciasPieza.forEach(
            dep => {
              if (dep.padre.id != -1 && dep.padre.id != -1) {
                var rowDep = {};
                rowDep["id"] = dep.id;
                rowDep["idPieza"] = dep.idPieza;
                rowDep["orden"] = dep.orden;
                rowDep["idPartePadre"] = dep.padre.id;
                rowDep["idParteHijo"] = dep.hijo.id;
                rowDep["cantidad"] = dep.cantidad;

                dtDependencias.push(rowDep);
              }
            });
          // console.log(dtDependencias)
          // console.log(dtDependencias.length + 0 + " dependencias")
          if (dtDependencias.length == 0) {
            // console.log("GO!")
            var rowDep = {};
            rowDep["id"] = -1;
            rowDep["idPieza"] = this.idPieza;
            rowDep["orden"] = 0;
            rowDep["idPartePadre"] = 0;
            rowDep["idParteHijo"] = 0;
            rowDep["cantidad"] = 0;

            dtDependencias.push(rowDep);
          }
          this.piezasPredefinidasService.update_dependencias(dtDependencias).subscribe(
            (result) => {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.CargarDatos();
              this.router.navigateByUrl('piezaspredefinidas/editar/' + this.idParte);
              this.isAddMode = false;
            });

        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }

      });
  }


  onClickEliminarOperacion(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("preguntaeliminarpopup");
    }
    if (this.operacionesseleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  onClickEliminarRuta(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    var auxRuta = this.rutas.filter(x => x.id == this.rutasseleccionados[0])[0];
    if (this.rutasseleccionados.length == 1 && auxRuta?.principal) { //solo tenemos seleccionada para eliminar la principal
      this.alertService.error(this.translate.instant('errorRuta'));
    } else {
      if (this.tituloPopUpPregunta != undefined) {
        this.tituloPopUpPregunta.innerText = this.translate.instant("preguntaeliminarpopup");
      }
      if (this.rutasseleccionados.length > 0) {
        this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }


  }

  onClickDuplicarPieza(contentloading) {

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.piezasPredefinidasService.duplicar(this.form.value).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }

  onUploadButtonClick(e: UploadEvent, content) {
    e.preventDefault();

    this._kendoFiles = e.files;
    this.myFiles = [];
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    console.log('file' + f.name);
    formData.append('file', f, f.name);
    console.log('idPieza' + this.idPieza.toString());
    formData.append('idPieza', this.idPieza.toString());
    console.log('cantidad' + this.form.value.cantidad.toString());
    formData.append('cantidad', this.form.value.cantidad.toString());


    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.piezasPredefinidasService.importarCotas(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
      this.myFiles = [];
    });

  }


  cellClick(e) {
    this.idRuta = e.dataItem.idRuta;
    this.idParte = e.dataItem.idParte;
    this.idPieza = e.dataItem.idPieza;
    if (e.columnIndex > 0) {
      this.router.navigate(['operacionespredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/" + e.dataItem.id + '/1']);
    }

  }

  cellClickRutas(e) {
    this.idRuta = e.dataItem.idRuta;
    this.idParte = e.dataItem.idParte;
    this.idPieza = e.dataItem.idPieza;
    if (e.columnIndex > 0) {
      this.router.navigate(['rutaspredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + e.dataItem.idRuta]);
    }

  }

  onClickEditarOperacion(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.operacionesseleccionados[0] > 0) {
      this.router.navigate(['operacionespredefinidas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/" + this.operacionesseleccionados[0] + '/1']);
    }
  }

  onClickEditarRuta() {
    if (this.rutasseleccionados[0] > 0) {

      this.router.navigate(['rutaspredefinidas/editar/' + this.idPieza + "/" + this.idParte + "/" + this.rutasseleccionados[0]]);
    }
  }
  onClickNuevaOperacion() {
    this.router.navigate(['operacionespredefinidas/crear/' + this.idPieza + "/" + this.idParte + "/" + this.idRuta + '/1']);
  }

  onClickNuevaOperacionPredefinida() {
    this.operacionesPredSelected = [];
    this.modalReference = this.modalService.open(this.anadirOperacionPred, { backdrop: 'static', size: 'xl', keyboard: false, centered: true });
    this.operacionesPredefinidasService.GetAll().subscribe((result: any) => {
      var dict: any = {};

      if (result.imagenesMaquinas.length > 0) {
        //Tenemos las imagenes, creamos el diccionario
        result.imagenesMaquinas.forEach(element => {
          dict[element.imagen] = element.imagenBASE64;
        });
      }

      result.operaciones.forEach(function (o) {
        //TIEMPOS
        o.tiempoEstimado = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoS);
        o.tiempoEstimadoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoPreparacionS);
        o.tiempoEstimadoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoEstimadoTotalS);
        o.tiempoPredictivo = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoS);
        o.tiempoPredictivoPreparacion = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoPreparacionS);
        o.tiempoPredictivoTotal = this.myFunctions.secondsTo_HH_MM_SS(o.tiempoPredictivoTotalS);
        //FIN TIEMPOS

        if (o.idsMaquinas) {
          var idsMaquinas: any = o.idsMaquinas.split(',');

          var maquinas = [];
          var maquinasImagenes = [];
          var prioridadesSeparadas = o.prioridadesMaquinas.split(',');
          var prioridadesFinal = [];
          idsMaquinas.forEach((idm, index) => {
            if (idm != "" && idm != "-1" && idm != "0") {
              prioridadesFinal.push(prioridadesSeparadas[index]);
              var m = result.nombresMaquinas.find(x => x.id == idm);
              if (m != undefined) {
                maquinas.push(m.nombre);
                maquinasImagenes.push(m.urlImagen);
              }
            }
          });
          var auxDictPrioridades = {}
          prioridadesFinal.forEach((prioridad, index) => {
            auxDictPrioridades[maquinas[index]] = prioridad;
          });
          o.prioridadMaquinasAux = auxDictPrioridades;
          o.maquina = maquinas.join(', ');
          //Ahora seguimos con las maquinas
          var maquinasAuxi = [];
          var maquinasAuxi2 = [];
          maquinas.sort((a, b) =>
            (Number(auxDictPrioridades[a]) > Number(auxDictPrioridades[b])) ? -1 :
              ((Number(auxDictPrioridades[b]) > Number(auxDictPrioridades[a])) ? 1 : 0)
          );
          maquinas.forEach(maquina => {
            if (maquina == this.translate.instant("desconocido")) {
              maquinasAuxi2.push("undefined");
              maquinasAuxi.push("undefined");
            } else {
              var nombre = maquina.trim().substring(0, 1).toUpperCase();
              var apellido = maquina.trim().substring(1, 2).toUpperCase();
              maquinasAuxi2.push(maquina);
              maquinasAuxi.push(nombre + apellido);
            }
          });
          o.maquinasAuxi2 = maquinasAuxi2.join(";");
          o.maquinasAuxi = maquinasAuxi.join(",");
          //Ahora hay que corregir las imagenes de las maquinas
          var imagenes = maquinasImagenes;
          var auxiImagenes = "";
          imagenes.forEach(imagen => {
            auxiImagenes += dict[imagen] + ";and;";
          });
          o.maquinasIm = auxiImagenes;
        }
        else {
          o.maquina = null;
        }
      }, this);
      this.operacionesPred = result.operaciones;
    });
  }

  onClickAceptarAnadirPred() {
    var auxOperacionPred = this.operacionesPred.filter(x => this.operacionesPredSelected.includes(x.id));
    auxOperacionPred.forEach(element => {
      // buscar si la operacion predefinida tiene mas de una pieza 
      // crear operacion para cada una de las piezas.

      var nuevaIdOperacion;
      this.operacionesPredefinidasService.creardepredefinido(element.id, this.idRuta).subscribe((result) => {
        this.modalReference.close();
        if (result.error == false) {
          nuevaIdOperacion = result.id;
          this.CargarDatos();
          this.piezasService.GetPiezaParteRutaByIdPred(this.idPieza).subscribe((piezas) => {
            var piezasSub = piezas.pieza;
            try {
              piezasSub.forEach(piezaSub => {
                this.operacionesService.creardepredefinido(nuevaIdOperacion, piezaSub.idRuta).subscribe(
                  (result) => {
                    if (result.error == true) {
                      this.alertService.error(this.translate.instant('error'));
                    }
                  });
              });
            } catch (error) {
              // console.log(error);
            }
          });
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      });

      
    });



  }

  onClickCancelar() {
    this.operacionesPredSelected = [];
  }

  /* onClickAnadirPred() {
    this.router.navigate(['operacionespredefinidas/anadireredefinido/' + this.idPieza + "/" + this.idParte + "/" + this.idRuta + "/1"]);
  } */
  onClickNuevaRuta() {
    this.router.navigate(['rutaspredefinidas/crear/' + this.idPieza + "/" + this.idParte]);
  }

  onUploadBOMButtonClick(e, content) {

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('file', f, f.name);
    formData.append("idruta", this.idRuta.toString());
    this.bomService.importar(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.CargarDatos();

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }

    });
  }

  onUploadSeriesButtonClick(e, content) {
    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('idparte', this.idParte.toString());
    formData.append('idruta', this.idRuta.toString());
    formData.append('file', f, f.name);


    this.seriesService.importar(formData).subscribe((result) => {

      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.CargarDatos();
      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }
    });


  }



  public onTieneRutasClick() {



  }


  onUploadImagenButtonClick(e: UploadEvent, content) {



    this._kendoFiles = e.files;
    var f: File = this._kendoFiles[0].rawFile;

    const formData: FormData = new FormData();
    formData.append('idparte', this.idParte.toString());
    formData.append('file', f, f.name);

    this.modalReferenceloadingupload = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    this.piezasPredefinidasService.uploadImage(formData).subscribe((result) => {
      this.modalReferenceloadingupload.close();
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });

      }
      else {
        this.alertService.error(this.translate.instant('error'));
      }
    }
    );
  }



  onSubmit(contentloading, e) {
    this.submitted = true;

    if (!this.espieza) {
      if (this.form.value.refparte == "") {
        this.errorReferenciaParte = true;
      } else {
        this.errorReferenciaParte = false;
      }
      if (this.form.value.parte == "") {
        this.errorNombreParte = true;
      } else {
        this.errorNombreParte = false;
      }
      if (this.errorReferenciaParte || this.errorNombreParte) {
        return;
      }
    }


    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });




    if (this.isAddMode) {
      this.nuevo();
    } else {
      this.actualizar();
    }
  }
  async actualizar() {
    this.modalReference.close();
    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      try {
        var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
        filename = this.form.value.archivo[0].name;
      } catch (error) {
        var archivoBase64: any = "";
      }
    } else {
      var archivoBase64: any = "";
    }

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.form.value.idOF = this.idOF;
    this.piezasPredefinidasService.update(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        //this.modalReferenceloading.close();
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          var idgenerado = result.id;
          //Se preparan las dependencias para poder borrarlas y volver a insertarlas (mirar como se tratan en la API)
          var dtDependencias = [];

          this.dependenciasPieza.forEach(
            dep => {
              if (dep.padre.id != -1 && dep.padre.id != -1) {
                var rowDep = {};
                rowDep["id"] = dep.id;
                rowDep["idPieza"] = dep.idPieza;
                rowDep["orden"] = dep.orden;
                rowDep["idPartePadre"] = dep.padre.id;
                rowDep["idParteHijo"] = dep.hijo.id;
                rowDep["cantidad"] = dep.cantidad;

                dtDependencias.push(rowDep);
              }
            });
          // console.log(dtDependencias)
          // console.log(dtDependencias.length + 0 + " dependencias")
          if (dtDependencias.length == 0) {
            // console.log("GO!")
            var rowDep = {};
            rowDep["id"] = -1;
            rowDep["idPieza"] = this.idPieza;
            rowDep["orden"] = 0;
            rowDep["idPartePadre"] = 0;
            rowDep["idParteHijo"] = 0;
            rowDep["cantidad"] = 0;

            dtDependencias.push(rowDep);
          }
          this.piezasPredefinidasService.update_dependencias(dtDependencias).subscribe(
            (result) => {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.CargarDatos();
              this.router.navigateByUrl('piezaspredefinidas/editar/' + this.idParte);
              this.isAddMode = false;
            });

        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }
        //this.router.navigate(['./'], { relativeTo: this.route });

      });
  }

  async nuevo() {

    var filename = "";

    if ((this.form.value.archivo[0] != null) && (this.form.value.archivo[0] != "")) {
      var archivoBase64: any = await this.toBase64(this.form.value.archivo[0]);
      filename = this.form.value.archivo[0].name;
    } else {
      var archivoBase64: any = "";
    }
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.guardando");
    }
    this.piezasPredefinidasService.create(this.form.value, filename, archivoBase64)
      .subscribe((result) => {
        this.modalReferenceloading.close();
        {
          if (result.error == false) {
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            var idparte = result.idparte;
            this.router.navigateByUrl('piezaspredefinidas/editar/' + idparte);
            this.isAddMode = false;
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        }
      }
      );
    this.Atras();
  }

  Atras() {
    this.router.navigate(['/piezaspredefinidas']);
  }

  private EliminarOperacion(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }

    this.operacionesPredefinidasService.getOperacionesRelacionadas(this.operacionesseleccionados).subscribe(
      (operacion) => {
        var operacion2 = operacion;
        for (let idOp in operacion2) {
          var index: number = +idOp;
          var log = this.operacionesService.EliminarOperacion(operacion2[index].id).subscribe(
            (result) => {
              console.log(result);
            }
          )
        }
      });
    this.operacionesPredefinidasService.delete({ ids: this.operacionesseleccionados }).subscribe(

      (data) => {
        this.modalReferenceloading.close();


        if (data.error == false) {
          this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
          this.CargarDatos();
        }
        else {

          this.alertService.success(this.translate.instant("error"), { keepAfterRouteChange: true });
        }


      }

    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  private EliminarPieza(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("popup.eliminando");
    }




    this.piezasPredefinidasService.delete(this.idParte).subscribe(

      (result) => {
        this.modalReferenceloading.close();
        if (result.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          this.Atras();
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }


      }

    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  public EliminarRuta(contentloading) {

    //buscamos ruta principal y borramos todas menos esa
    this.rutasseleccionados.forEach(element => {
      if (!this.rutas.filter(x => x.id == element)[0].principal) {
        this.rutasService.delete({ idruta: element }).subscribe(
          (result) => {
            this.modalReferenceloading.close();
            if (result.error == false) {
              this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
              this.CargarDatos();
            }
            else {
              this.alertService.error(this.translate.instant('error'));
            }
          });
      }
    });

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

  }

  //#region IMAGEN
  public myFiles = [];

  imageToShow: any;
  isImageLoading: boolean;
  createImageFromBase64(image) {
    let objectURL = 'data:image/jpeg;base64,' + image;
    this.imageToShow = this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //#endregion
  public async selectEventHandler(e: SelectEvent) {
    const that = this;
    var file = e.files[0];
    e.files.forEach((file) => {

      if (!file.validationErrors) {
        const reader = new FileReader();

        reader.onload = function (ev) {
          const image = {
            src: ev.target['result'],
            uid: file.uid
          };

          that.imagePreviews.unshift(image);
        };

        reader.readAsDataURL(file.rawFile);
      }
    });

    this.imagePreviews.forEach(element => { that.imageToShow = element.src; });
    this.imagePreviews = [];
  }


  public onCantidadChange(value: number) {
    var cantidad = this.form.controls["cantidad"].value;
    var agrupada = this.form.controls["cantidadAgrupada"].value;
    if (cantidad < agrupada) {

      this.form.controls["cantidadAgrupada"].setValue(cantidad);
    }
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }


  btnParte() {

    this.espieza = false;
  }

  btnPieza() {

    this.espieza = true;
  }


  public tipo_pieza_valueChange(value: any): void {
    if (value == undefined) this.form.controls['tipo_pieza_id'].setValue(undefined);
    else this.form.controls['tipo_pieza_id'].setValue(value.id);
  }


  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  //BOTONES GESTI�N DOCUMENTAL
  onCellClickGestionDocumental(e: any) {
    if (e.columnIndex > 0) {
      this.router.navigate(['/piezaspredefinidas/documento/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + e.dataItem.id + "/1"]);
    }
  }

  onClickEditarGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.mySelectionGestionDocumental.length > 0) {
      this.router.navigate(['/piezaspredefinidas/documento/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/' + this.mySelectionGestionDocumental[0] + "/1"]);
    }
  }

  onClickNuevoGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.router.navigate(['/piezaspredefinidas/documento/' + this.idPieza + '/' + this.idParte + '/' + this.idRuta + '/-1/1']);
  }

  onClickEliminarGestionDocumental(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.modalReference = this.modalService.open(this.popupBorrarDocumentos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarDocumentos() {
    this.mySelectionGestionDocumental.forEach(element => {
      if (element > 0) {
        this.isDeletingGestionDocumental = true;
        var ids: string = "";
        var idsRutas: string = "";
        var files: string = "";
        this.mySelectionGestionDocumental.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
            var auxi = this.gestiondocumental.filter(x => x.id == ap)[0];
            var auxFile = auxi.fichero;
            if (files == "")
              files += auxFile;
            else
              files += '&1sep1&' + auxFile;
            if (idsRutas == "")
              idsRutas += auxi.idRuta;
            else
              idsRutas += ',' + auxi.idRuta;
          }
        );
        this.gestionDocumentalService.deleteDocumentosPredefinidas(ids, files, idsRutas).subscribe(() => {
          this.isDeletingGestionDocumental = false;
          this.CargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

  viewPDF(element: any) {
    if (element.esLink) {
      window.open(element.fichero, '_blank');
    } else {
      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + element.pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = element.fichero;
      }, 0);
    }
  }

  tieneRutasChange() {
    var that = this;
    setTimeout(function () {
      that.tabstrip.selectTab(0);
      that.selectedTab = 0;
    }, 10);
    // if (!this.form.value.tienerutas) { //no tiene rutas
    //   //if(this.selectedTab == 2){ 
    //   //seleccionamos operaciones
    //   this.tabstrip.selectTab(0);
    //   this.selectedTab = 0;
    //   //y deshabilitar los necesarios
    //   this.tabstrip.tablist.nativeElement.children[2].classList.add("k-state-disabled");
    //   this.tabstrip.tablist.nativeElement.children[0].classList.remove("k-state-disabled");
    //   this.tabstrip.tablist.nativeElement.children[1].classList.remove("k-state-disabled");
    //   this.tabstrip.tablist.nativeElement.children[3].classList.remove("k-state-disabled");
    //   // }
    // } else { //tiene rutas
    //   //if(this.selectedTab == 0 || this.selectedTab == 1 || this.selectedTab == 4){ 
    //   //seleccionamos rutas
    //   this.tabstrip.selectTab(2); //lo cambiamos a rutas
    //   this.selectedTab = 2;
    //   //y deshabilitar los necesarios
    //   this.tabstrip.tablist.nativeElement.children[2].classList.remove("k-state-disabled");
    //   this.tabstrip.tablist.nativeElement.children[0].classList.add("k-state-disabled");
    //   this.tabstrip.tablist.nativeElement.children[1].classList.add("k-state-disabled");
    //   this.tabstrip.tablist.nativeElement.children[3].classList.add("k-state-disabled");
    //   // }    
    // }
  }

  onTabSelected(e) {
    this.selectedTab = e.index;
  }

  // DEPENDENCIAS
  borrarDependencia(dependencia) {
    let index = this.dependenciasPieza.findIndex(d => d === dependencia);
    this.dependenciasPieza.splice(index, 1);
    if (this.dependenciasPieza.length == 0)
      this.dependenciasPieza = [
        {
          id: -1
          , idPieza: this.idPieza
          , orden: 1
          , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
          , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
          , cantidad: 1
        }];
  }
  parteDependenciaSeleccionada(tipo, dependencia, newSelection) {
    var seleccionPreviaId = 0;
    if (tipo == 'padre') {
      seleccionPreviaId = dependencia.padre.id;
      dependencia.padre = newSelection;
    }
    else {
      seleccionPreviaId = dependencia.hijo.id;
      dependencia.hijo = newSelection;
    }
    if (seleccionPreviaId <= 0) {
      // antes estaba sin seleccionar ahora esta seleccionada, si las 2 estan seleccionadas, hay que añadir una linea nueva!
      if (dependencia.padre.id > 0 && dependencia.hijo.id > 0) {
        this.dependenciasPieza.push(
          {
            id: -1
            , idPieza: this.idPieza
            , orden: 1
            , padre: { id: -1, nombre: this.translate.instant('seleccioneParte') }
            , hijo: { id: -1, nombre: this.translate.instant('seleccioneSubparte') }
            , cantidad: 1
          });
      }
    }
  }
  cargaropcionesCombo(tipo, dependencia) {
    if (tipo == 'padre') {
      return this.comboPartes.filter(f => this.padreHijoPosible(f.id, dependencia.hijo.id));
    }
    else {
      return this.comboPartes.filter(f => this.padreHijoPosible(dependencia.padre.id, f.id));
    }
  }
  // DEPENDENCIAS: CHECK RECURSIVO
  padreHijoPosible(idPadre, idHijo) {
    if (idPadre == idHijo)
      return false;
    return this.padreHijoPosible_recursivo(this.dependenciasPieza, idPadre, idHijo);
  }
  padreHijoPosible_recursivo(dependencias, idPadre, idHijo) {
    if (idPadre == idHijo)
      return false;
    var ret = true;
    dependencias.forEach(
      row => {
        if (ret) {
          if (idPadre == row.hijo.id && idHijo == row.padre.id) {
            ret = false;
          }
          else if (idPadre == row.hijo.id) {
            var newDependencias = JSON.parse(JSON.stringify(this.dependenciasPieza));
            let index = newDependencias.findIndex(d => d === row);
            newDependencias.splice(index, 1);
            ret = this.padreHijoPosible_recursivo(newDependencias, row.padre.id, idHijo);
          }
        }
      });
    return ret;
  }

  indexOf(dt, row) {
    return dt.findIndex(d => d === row);
  }

}






