<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card w-50">
    <div class="card-header">
      <h3 *ngIf="isAddMode">{{ 'crearcursolectivo' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'editarcursolectivo' | translate}}</h3>
    </div>
    <div class="card-body">
      <!--NOMBRE-->
   
      <div class="row">
        <div class="col">
        <div class="form-group mr-2">
        <kendo-label text="{{ 'nombre' | translate}}">
          <input kendoTextBox [value]="curso.nombre" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && errorNombreVacio }" (change)="nombreChange($event)" />
          <div *ngIf="submitted && errorNombreVacio" class="invalid-feedback">
            <div>{{ 'nombrerequerido' | translate}}</div>
          </div>
          <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
            <div *ngIf="f.nombre.errors.maxlength">{{ 'nombre200caracteres' | translate}}</div>
          </div>
        </kendo-label>
      </div>
    </div>
    <div class="col">
      <div class="form-group mr-2">
        <kendo-label text="{{ 'activo' | translate}}"></kendo-label>
        <div class="caja">
          <kendo-switch [checked]="curso.activo" [onLabel]="' '" [offLabel]="' '">
          </kendo-switch>
        </div>
      </div>
    </div>
  </div>
   

      <!-- SELECCION FECHA DOBLE -->
      <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'periodoCurso' | translate }}</label>
        <filtro-fechas [ngClass]="{ 'is-invalid': errorFechasVacias }" [fechaIni]="curso.inicio" [fechaFin]="curso.fin"
          (newFechaInicio)="actualizarFechaInicio($event)" (newFechaFin)="actualizarFechaFin($event)">
        </filtro-fechas>
        <div *ngIf="submitted && errorFechasVacias" class="invalid-feedback">
          <div>{{ 'periodoRequerido' | translate}}</div>
        </div>
       
  
      </div>

      <!--OBSERVACIONES-->
      <div class="form-group">
        <label ID="lAsignarPerdidaA" class="control-label">{{ 'observaciones' | translate }}</label>
        <div class="caja">
          <textarea formControlName="curso.observaciones" maxlength="280" kendoTextArea [autoSize]="true"
            [value]="curso.observaciones" (change)="observacionChange($event)"></textarea>
        </div>
      </div>


      <!--HORARIO-->
      <div class="card-header">
        <h3>{{ 'horario' | translate}}</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <div class="clearfix" id="horario">
           
            <div class="float-left mr-1" #div id="listaHorarios">
              <div  [ngClass]="{ 'is-invalid': errorHorasExisten }" *ngFor="let h of horariosCurso">
                <div class="row">
                  <kendo-dropdownlist [data]="diasSemanaDropdown" [textField]="'diaN'" [valueField]="'value'"
                    [(value)]="h.diaItem" (selectionChange)="diaChange($event,h)"></kendo-dropdownlist>
                  <kendo-timepicker [formatPlaceholder]="{ hour: '00', minute: '00'}" [(value)]="h.horaInicio">
                    <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                    acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                    cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                    nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
                  </kendo-timepicker-messages>
                  </kendo-timepicker>
                  <kendo-timepicker [formatPlaceholder]="{ hour: '00', minute: '00'}" [(value)]="h.horaFin" >
                    <kendo-timepicker-messages accept="{{ 'accept' | translate }}"
                    acceptLabel="{{ 'acceptLabel' | translate }}" cancel="{{ 'cancel' | translate }}"
                    cancelLabel="{{ 'cancelLabel' | translate }}" now="{{ 'now' | translate }}"
                    nowLabel="{{ 'nowLabel' | translate }}" toggle="{{ 'toggle' | translate }}">
                  </kendo-timepicker-messages>
                  </kendo-timepicker>
                  <button Class="flt-eliminar" (click)="annadirEliminado(h.id)" *ngIf="h.diaItem.value != null"> <i class="fas fa-times"></i> </button>
                  <p class="m-0" *ngIf="h.horaInicio >= h.horaFin && h.diaItem.value != null" style="color:red;">{{ 'horasMal' | translate}}</p>
                </div>
              </div>
              <div *ngIf="submitted && errorHorasExisten" class="invalid-feedback">
                <div>{{ 'horasExisten' | translate}}</div>
              </div>
            </div>
          </div>    
        </div>
      
      </div>
    </div>
  </div>


  <!--BOTONES-->
  <div class="form-group">
    <button kendoButton class="btn mr-1  btn-success" [disabled]="loading">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ 'guardar' | translate}}
    </button>
    <a routerLink="/eskola_cursoslectivos" class="btn mr-1  btn-danger">{{ 'cancelar' | translate}}</a>
  </div>

</form>