import { Component, Input } from '@angular/core';
import { MaquinasService, MenuService, UsuariosService, AlertService } from '@app/_services';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maquina-secciones-alarmas-controladas',
  templateUrl: './maquinaDetalleAlarmasControladas.component.html'
})

export class MaquinaDetalleSeccionesAlarmasControladasComponent {

  @Input() id: string;

  public alarmasControladas: [];
  public alarmasControladas_seleccionados: number[] = [];
  public alarmasControladas_modalReference: NgbModalRef;
  public alarmasControladas_modalReferenceLoading: NgbModalRef;
  user = this.userService.userValue;

  constructor(
    private maquinasService: MaquinasService,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal,
    private alertService: AlertService) {

    this.menuService.titulo = this.translateService.instant('maquina').toUpperCase();
  }

  ngOnInit() {

    this.maquinasService.GetAll_AlarmasControladas(this.id).subscribe((result) => {
      this.alarmasControladas = result.data;
    });

  }

  alarmasControladas_onClickNuevo() {
    if (this.id != undefined) {
      this.router.navigate(['maquinas/alarmacontrolada/crear/' + this.id]);
    } else {
      this.alertService.warn(this.translateService.instant('creaPrimeroLaMaquina'), { keepAfterRouteChange: true });
    }
  }

  alarmasControladas_onClickEditar() {
    if (this.id != undefined) {
      if (this.alarmasControladas_seleccionados[0] > 0) {
        if (this.id != undefined) {
          this.router.navigate(['maquinas/alarmacontrolada/editar/' + this.id + '/' + this.alarmasControladas_seleccionados[0]]);
        }
      }
    }
  }

  alarmasControladas_cellClick(e) {
    if (this.id != undefined) {
      if (e.columnIndex > 0) {
        this.router.navigate(['maquinas/alarmacontrolada/editar/' + this.id + '/' + this.alarmasControladas_seleccionados[0]]);
      }
    }
  }

  alarmasControladas_onClickEliminar(alarmasControladas_content) {
    if (this.id != undefined) {
      if (this.alarmasControladas_seleccionados.length > 0) {
        this.alarmasControladas_modalReference = this.modalService.open(alarmasControladas_content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }
  }

  alarmasControladas_eliminarRegistro(contentLoading) {
    if (this.id != undefined) {
      this.maquinasService.Delete_Control_Alarma({ ids: this.alarmasControladas_seleccionados }).subscribe(
        (data) => {
          if (data.error == false) {
            this.maquinasService.GetAll_AlarmasControladas(this.id).subscribe((result) => {
              this.alarmasControladas = result.data;
            }
            );
          }
          this.alarmasControladas_seleccionados = [];
          this.alarmasControladas_modalReferenceLoading.close();
        }
      );

      this.alarmasControladas_modalReference.close();
      this.alarmasControladas_modalReferenceLoading = this.modalService.open(contentLoading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    }
  }

}
