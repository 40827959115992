import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/bonos`;

@Injectable()
export class BonosService {

  constructor(
    private http: HttpClient
  ) {
  }

  //SELECT 
  get(idMaquina: number, fecha: string,
      sEjecucion: string, sParada: string, sPreparacion: string, sMantenimiento: string, sAlarma: string, sApagada: string, sCanal: string,
      sNombrePrograma: string, sDescripcion: string, sOperario: string,
      sOF: string, sCliente: string, sRefPieza: string, sPieza: string, sOperacion: string, sOperacionNoEncontrada: string,
      sDuracion: string, sInicio: string, sFin: string, hoy: boolean ) {

      return this.http.post<JSON>(`${baseUrl}/Get`, {
              idMaquina, fecha,
              sEjecucion, sParada, sPreparacion, sMantenimiento, sAlarma, sApagada, sCanal,
              sNombrePrograma, sDescripcion, sOperario,
              sOF, sCliente, sRefPieza, sPieza, sOperacion, sOperacionNoEncontrada,
                sDuracion, sInicio, sFin, hoy
            }, { withCredentials: true });
  }

  getBonos(idMaquina: number, fecha: string,
    sEjecucion: string, sParada: string, sPreparacion: string, sMantenimiento: string, sAlarma: string, sApagada: string, sCanal: string,
    sNombrePrograma: string, sDescripcion: string, sOperario: string,
    sOF: string, sCliente: string, sRefPieza: string, sPieza: string, sOperacion: string, sOperacionNoEncontrada: string,
    sDuracion: string, sInicio: string, sFin: string, hoy: boolean ) {

    return this.http.post<JSON>(`${baseUrl}/Get_bonos_agrupados`, {
            idMaquina, fecha,
            sEjecucion, sParada, sPreparacion, sMantenimiento, sAlarma, sApagada, sCanal,
            sNombrePrograma, sDescripcion, sOperario,
            sOF, sCliente, sRefPieza, sPieza, sOperacion, sOperacionNoEncontrada,
              sDuracion, sInicio, sFin, hoy
          }, { withCredentials: true });
}

getBonosGeneral(idMaquina: number, fecha: string,
  sEjecucion: string, sParada: string, sPreparacion: string, sMantenimiento: string, sAlarma: string, sApagada: string, sCanal: string,
  sNombrePrograma: string, sDescripcion: string, sOperario: string,
  sOF: string, sCliente: string, sRefPieza: string, sPieza: string, sOperacion: string, sOperacionNoEncontrada: string,
  sDuracion: string, sInicio: string, sFin: string, hoy: boolean ) {

  return this.http.post<JSON>(`${baseUrl}/Get_bonos_agrupados_general`, {
          idsMaquina: idMaquina, fecha,
          sEjecucion, sParada, sPreparacion, sMantenimiento, sAlarma, sApagada, sCanal,
          sNombrePrograma, sDescripcion, sOperario,
          sOF, sCliente, sRefPieza, sPieza, sOperacion, sOperacionNoEncontrada,
            sDuracion, sInicio, sFin, hoy
        }, { withCredentials: true });
}



update(dt: any, idMaquina: number, validado: boolean, desvalidado: boolean, fecha: string, arrayPerdidas: any, actualizarCantidadesAsignadas: any) {
  return this.http.post<JSON>(`${baseUrl}/Update`, { dt, idMaquina, validado, desvalidado, fecha, arrayPerdidas, actualizarCantidadesAsignadas }, { withCredentials: true });
}

  getTiemposMicroParadas() {
    return this.http.post<JSON>(`${baseUrl}/getTiemposMicroParadas`, {}, { withCredentials: true });
  }

  Get_piezas_Nserie(idParte: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_piezas_Nserie`, { idParte }, { withCredentials: true });
  }

  Get_piezas_Lote(idParte: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_piezas_Lote`, { idParte }, { withCredentials: true });
  }

  Get_piezas_Colada(idParte: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_piezas_Colada`, { idParte }, { withCredentials: true });
  }

  getCantidades(idsHO, idsHP, idMaquina, fecha) {
    return this.http.post<JSON>(`${baseUrl}/getCantidadesByIdHo`, { idsHO, idsHP, idMaquina, fecha }, { withCredentials: true });
  }

}
