import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { ActivosService, AlertService, AreasProductivasService, MenuService, ProveedoresService, UsuariosService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-activos-detalle',
  templateUrl: './activos-detalle.component.html',
  styleUrls: ['./activos-detalle.component.less']
})
export class ActivosDetalleComponent implements OnInit {

  @ViewChild('modalIsometrico') modalIsometrico: NgbModalRef;
  @ViewChild('popupBorrarProveedores') popupBorrarProveedores: NgbModalRef;
  @ViewChild('popupBorrarRelaciones') popupBorrarRelaciones: NgbModalRef;
  @ViewChild('popupBorrarDocumentos') popupBorrarDocumentos: NgbModalRef;
  @ViewChild('popupUpdate') popupUpdate: NgbModalRef;
  @ViewChild('popupBorrarUpdates') popupBorrarUpdates: NgbModalRef;
  form: FormGroup;
  activosTipos: any;
  jerarquiaActivos: any;
  secciones: any;
  selectedSection: any = [];
  proveedores: any;
  selectedProveedor: any = [];
  submitted = false;
  idActivo: number;
  stock = false;
  public dibujosMaquinas: any;
  public dibujosObjetos: any;
  public selectedDibujo: any;
  public selectedAuxiliarDibujo: any;
  selectedJerarquiaActivo: any = {};
  selectedTipoActivo: any = {};
  loadingForm0 = true;
  loadingForm = true;
  modalReference: NgbModalRef;
  dataGestionDocumental: any;
  mySelectionGestionDocumental: any = [];
  loadingGestionDocumental = true;
  isDeletingGestionDocumental;
  dataUpdates: any;
  mySelectionUpdates: any = [];
  loadingUpdates = true;
  isDeletingUpdates;
  public imageToShow: any;

  updateNombre: any;
  updateFecha: any;
  updateDescripcion: any;
  submitted2 = false;
  errorNombre = false;
  idUpdate: number = 0;

  user = this.userService.userValue;

  public restrictionsImage: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };
  constructor(private formBuilder: FormBuilder, private menuService: MenuService, public router: Router, private modalService: NgbModal, 
    private proveedoresService: ProveedoresService, private areasProductivasService: AreasProductivasService, private myFunctions: MyFunctions,
    private userService: UsuariosService,
    private translateService: TranslateService, private activosService: ActivosService, private route: ActivatedRoute, protected alertService: AlertService) {

    this.menuService.titulo = this.translateService.instant('activo');
    this.idActivo = Number(this.route.snapshot.params['id']);
    this.stock = false;
    //Cargar datos isometrico
    this.dibujosObjetos = [
      { id: 1, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-1.png" },
      { id: 2, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-1.png" },
      { id: 3, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-2.png" },
      { id: 4, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-tipo-2.png" },
      { id: 5, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-carton-tipo-3.png" },
      { id: 6, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-cajas-madera-1.png" },
      { id: 7, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-deposito-liquidos.png" },
      { id: 8, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-barras-metal.png" },
      { id: 9, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-bidones.png" },
      { id: 10, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-cajas-carton.png" },
      { id: 11, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-cofres-01.png" },
      { id: 12, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-cofres-02.png" },
      { id: 13, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-tipo1-1.png" },
      { id: 14, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-estanterias-tipo1-2.png" },
      { id: 15, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-fenwick.png" },
      { id: 16, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-1.png" },
      { id: 17, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-2.png" },
      { id: 18, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-3.png" },
      { id: 19, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-forklift-4.png" },
      { id: 20, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-palet-sacos.png" },
      { id: 21, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-puesto-1.png" },
      { id: 22, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-puesto-2.png" },
      { id: 23, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-rollos-1.png" },
      { id: 24, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-transpaleta.png" },
      { id: 25, imagen: "../../../assets/isometrico/iconos/otros_mini/mini-tubos-estanterias.png" },
    ];
    this.dibujosMaquinas = [
      { id: 26, imagen: "../../../assets/isometrico/iconos/miniatura/durma-laser-cut-hdf-3015-miniatura-04.png" },
      { id: 27, imagen: "../../../assets/isometrico/iconos/miniatura/extrusora_3_cubos_vista_miniatura-04.png" },
      { id: 28, imagen: "../../../assets/isometrico/iconos/miniatura/extrusora2x1_miniatura-01.png" },
      { id: 29, imagen: "../../../assets/isometrico/iconos/miniatura/geminis-gt5i-miniatura-01.png" },
      { id: 30, imagen: "../../../assets/isometrico/iconos/miniatura/mazak-miniatura-02.png" }
    ];
    this.selectedDibujo = { id: -1, imagen: "../../../assets/isometrico/iconos/noimagen.png" };
    this.loadingForm0 = false;

    var r1 = false, r2 = false, r3= false, r4= false;
    this.activosService.getJerarquia().subscribe((response: any) => {
      this.jerarquiaActivos = response;
      this.selectedJerarquiaActivo = -1;
      r1 = true;
      if (r1 && r2 && r3 && r4){
        this.cargarDatos();
      }
        
    });
    this.activosService.getAllTipos().subscribe((response: any) => {
      this.activosTipos = response;
      this.selectedTipoActivo = -1;
      r2 = true;
      if (r1 && r2 && r3 && r4){
        this.cargarDatos();
      }
        
    });
    this.areasProductivasService.Seccion_Get_All().subscribe((response: any) => {
      this.secciones = response;
      this.selectedSection = -1;
      r3 = true;
      if (r1 && r2 && r3 && r4){
        this.cargarDatos();
      }
        
    });
    this.proveedoresService.getAll().subscribe((response: any) => {
      this.proveedores = response;
      this.selectedProveedor = -1;
      r4 = true;
      if (r1 && r2 && r3 && r4){
        this.cargarDatos();
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  ngOnInit(): void {
    //Para evitar errores
    this.form = this.formBuilder.group({
      nombre: new FormControl('', [Validators.required]),
      referencia: new FormControl('', [Validators.required]),
      numeroSerie: new FormControl(''),
      codigoEAN: new FormControl(''),
      vidaUtil: new FormControl(0.0),
      stock: new FormControl(false),
      descripcion: new FormControl(''),
      nombreArchivo: ['',],
      archivoBase64: ['',],
      archivo: ['',],
      baja: new FormControl(false),
      fechaCompra: new FormControl(''),
      fechaPuestoEnServicio: new FormControl(''),
      fechaBajaEstimada: new FormControl(''),
      precio: new FormControl(0.0),
      garantia: new FormControl(0),
      fechaBaja: new FormControl('')
    });
  }

  cargarDatos() {
    if (this.idActivo != 0) {
      this.activosService.getActivoById(this.idActivo).subscribe((response: any) => {
        var auxi = response[0];
        this.form = this.formBuilder.group({
          nombre: new FormControl(auxi.nombre, [Validators.required]),
          referencia: new FormControl(auxi.referencia, [Validators.required]),
          numeroSerie: new FormControl(auxi.numeroSerie),
          codigoEAN: new FormControl(auxi.codigoBarras),
          vidaUtil: new FormControl(auxi.vidaUtil),
          stock: new FormControl(auxi.stock),
          descripcion: new FormControl(auxi.descripcion),
          nombreArchivo: [auxi.imagen],
          archivoBase64: [auxi.imagenBase64,],
          archivo: ['',],
          baja: new FormControl(auxi.baja),
          fechaCompra: new FormControl(this.myFunctions.sqlToJsDate(auxi.fechaCompra.replace('T', ' '))), //El replace evita errores con la fecha
          fechaPuestoEnServicio: new FormControl(this.myFunctions.sqlToJsDate(auxi.fechaPuestoEnServicio.replace('T', ' '))),
          fechaBajaEstimada: new FormControl(this.myFunctions.sqlToJsDate(auxi.fechaBajaEstimada.replace('T', ' '))),
          precio: new FormControl(auxi.precio),
          garantia: new FormControl(auxi.garantia),
          fechaBaja: new FormControl(this.myFunctions.sqlToJsDate(auxi.fechaBaja.replace('T', ' ')))
        });
        //isométrico
        var aux = this.dibujosObjetos.filter(x => x.id == response[0].idIsometrico)[0];
        if (aux == undefined) {
          aux = this.dibujosMaquinas.filter(x => x.id == response[0].idIsometrico)[0];
          if (aux != undefined) {
            this.selectedDibujo = aux;
          }
        }
        if (aux != undefined) {
          this.selectedDibujo = aux;
        }
        //jerarquia y tipo activo
        if (this.jerarquiaActivos.filter(x => x.tipo == response[0].jerarquiaActivo)[0] !== undefined) {
          this.selectedJerarquiaActivo = this.jerarquiaActivos.filter(x => x.tipo == response[0].jerarquiaActivo)[0].id;
        } else {
          this.selectedJerarquiaActivo = -1;
        }
        if (this.activosTipos.filter(x => x.nombre == response[0].tipoActivo)[0] !== undefined) {
          this.selectedTipoActivo = this.activosTipos.filter(x => x.nombre == response[0].tipoActivo)[0].id
        } else {
          this.selectedTipoActivo = -1;
        }
        if (this.secciones.filter(x => x.id == response[0].idSeccion)[0] !== undefined) {
          this.selectedSection = this.secciones.filter(x => x.id == response[0].idSeccion)[0].id
        } else {
          this.selectedSection = -1;
        }
        if (this.proveedores.filter(x => x.id == response[0].idProveedor)[0] !== undefined) {
          this.selectedProveedor = this.proveedores.filter(x => x.id == response[0].idProveedor)[0].id
        } else {
          this.selectedProveedor = -1;
        }
        this.imageToShow = response[0].imagenBase64;
        this.loadingForm = false;
      });
      
    } else { this.loadingForm = false; }
    this.loadGestionDocumental();
    this.loadUpdates();
  }

  loadGestionDocumental(){
    this.activosService.getAllDocumentosActivos(this.idActivo).subscribe((response: any) => {
      this.dataGestionDocumental = response;
      this.loadingGestionDocumental = false;
    });
  }

  loadUpdates(){
    this.activosService.getAllUpdates(this.idActivo).subscribe((response: any) => {
      this.dataUpdates = response;
      this.loadingUpdates = false;
    });
  }

  //funciones imagen
  archivoSeleccionado(e: SelectEvent) {
    var th = this;
    var auxNombre = 'activo_' + this.idActivo + e.files[0].extension;
    this.form.controls['nombreArchivo'].setValue(auxNombre);
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.form.value.archivo !== "" && th.form.value.archivo !== null) archivoBase64 = await th.toBase64(th.form.value.archivo[0]);
      else archivoBase64 = "";
      th.form.controls['archivoBase64'].setValue(archivoBase64);
      th.imageToShow = archivoBase64;
    }, 500);

  }

  archivoEliminado(e: RemoveEvent) {
    this.form.controls['nombreArchivo'].setValue("");
    this.form.controls['archivoBase64'].setValue("");
    this.imageToShow = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //BOTONES GESTIÓN DOCUMENTAL
  onCellClickGestionDocumental(e: any){
    if (e.columnIndex > 0) {
      this.router.navigate(['/activo/' + this.idActivo + '/documento/'+ this.mySelectionGestionDocumental[0]]);
    }
  }
  
  onClickEditarGestionDocumental() {
    if(this.mySelectionGestionDocumental.length > 0 ){
      this.router.navigate(['/activo/' + this.idActivo + '/documento/'+ this.mySelectionGestionDocumental[0]]);
    }
  }

  onClickNuevoGestionDocumental() {
    this.router.navigate(['/activo/' + this.idActivo + '/documento/0']);
  }

  onClickEliminarGestionDocumental() {
    this.modalReference = this.modalService.open(this.popupBorrarDocumentos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarDocumentos() {
    this.mySelectionGestionDocumental.forEach(element => {
      if (element > 0) {
        this.isDeletingGestionDocumental = true;
        var ids: string = "";
        this.mySelectionGestionDocumental.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.activosService.deleteDocumentosActivos(ids).subscribe(() => {
          this.isDeletingGestionDocumental = false;
          this.loadGestionDocumental();
        });
      }
      this.modalReference.close();
    });
  }

  viewPDF(element: any){
    if(element.esLink){
      window.open(element.ficheroLink, '_blank');
    } else{
      const win = window.open("", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + element.pdfBase64 + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = element.ficheroLink;
      }, 0);
    }
  }

  //BOTONES UPDATES
  onCellClickUpdates(e: any){
    if (e.columnIndex > 0) {
      this.idUpdate = e.dataItem.id;
      this.activosService.getUpdateById(e.dataItem.id).subscribe((response: any) => {
        this.updateNombre = response[0].nombre;
        this.updateDescripcion = response[0].descripcion;
        this.updateFecha = this.myFunctions.sqlToJsDate(response[0].fecha.replace('T', ' '));
        this.modalReference = this.modalService.open(this.popupUpdate, { backdrop: 'static', size: 's', keyboard: false, centered: true });
      });
    }
  }
  
  onClickEditarUpdates() {
    if(this.mySelectionUpdates.length > 0 ){
      this.idUpdate = this.mySelectionUpdates[0];
      this.activosService.getUpdateById(this.mySelectionUpdates[0]).subscribe((response: any) => {
        this.updateNombre = response[0].nombre;
        this.updateDescripcion = response[0].descripcion;
        this.updateFecha = this.myFunctions.sqlToJsDate(response[0].fecha.replace('T', ' '));
        this.modalReference = this.modalService.open(this.popupUpdate, { backdrop: 'static', size: 's', keyboard: false, centered: true });
      });
    }
  }

  onClickNuevoUpdates() {
    this.idUpdate = 0;
    this.updateNombre = '';
    this.updateDescripcion = '';
    this.updateFecha = '';
    this.modalReference = this.modalService.open(this.popupUpdate, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  limpiarVariables(){
    this.idUpdate = 0;
    this.updateNombre = '';
    this.updateDescripcion = '';
    this.updateFecha = '';
  }

  onClickEliminarUpdates() {
    this.modalReference = this.modalService.open(this.popupBorrarUpdates, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarUpdates() {
    this.mySelectionUpdates.forEach(element => {
      if (element > 0) {
        this.isDeletingUpdates = true;
        var ids: string = "";
        this.mySelectionUpdates.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.activosService.deleteUpdates(ids).subscribe(() => {
          this.isDeletingUpdates = false;
          this.loadUpdates();
        });
      }
      this.modalReference.close();
    });
  }

  updateGuardarClick(){
    this.submitted2 = true;
    if (this.updateNombre == "") {
      this.errorNombre = true;
    } else{
      this.errorNombre = false;
    }
    if(this.errorNombre){
      return;
    }
    if (this.idUpdate == 0) {
      this.activosService.crearUpdate(this.idActivo, this.updateNombre, this.updateDescripcion, this.updateFecha).subscribe(
          (result: any) => {
            if (result >= 0) {
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
              this.modalReference.close();
              this.loadUpdates();
            } else {
              this.alertService.error(this.translateService.instant('error'));
            }
          });
    } else {
      this.activosService.editarUpdate(this.idUpdate, this.idActivo, this.updateNombre, this.updateDescripcion, this.updateFecha).subscribe(
          (result: any) => {
            if (result >= 0) {
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
              this.modalReference.close();
              this.loadUpdates();
            } else {
              this.alertService.error(this.translateService.instant('error'));
            }
          });
    }
  }

  //BOTONES PRINCIPALES
  guardarClick(){
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    if(this.selectedJerarquiaActivo == null){
      this.selectedJerarquiaActivo = -1;
    }
    if(this.selectedTipoActivo == null){
      this.selectedTipoActivo = -1;
    }
    if(this.selectedSection == null){
      this.selectedSection = -1;
    }
    if(this.selectedProveedor == null){
      this.selectedProveedor = -1;
    }
    if (this.idActivo == 0) {
      this.activosService.insertActivo(this.form.value.nombre, this.form.value.referencia, this.form.value.numeroSerie, this.form.value.descripcion, this.form.value.nombreArchivo, 
        this.selectedDibujo.id, this.selectedTipoActivo, this.selectedJerarquiaActivo, this.form.value.vidaUtil, 
        this.form.value.stock, this.form.value.codigoEAN, this.form.value.fechaCompra, this.form.value.fechaPuestoEnServicio, 
        this.form.value.baja, this.form.value.fechaBaja, this.selectedSection,
        this.selectedProveedor, this.form.value.precio, this.form.value.garantia, -1, this.form.value.archivoBase64).subscribe(
          (result: any) => {
            if (result >= 0) {
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
              this.router.navigate(['activos']);
            } else {
              this.alertService.error(this.translateService.instant('error'));
            }
          });
    } else {
      this.activosService.updateActivo(this.idActivo, this.form.value.nombre, this.form.value.referencia, this.form.value.numeroSerie, this.form.value.descripcion, this.form.value.nombreArchivo, 
        this.selectedDibujo.id, this.selectedTipoActivo, this.selectedJerarquiaActivo, this.form.value.vidaUtil, 
        this.form.value.stock, this.form.value.codigoEAN, this.form.value.fechaCompra, this.form.value.fechaPuestoEnServicio, 
        this.form.value.baja, this.form.value.fechaBaja, this.selectedSection,
        this.selectedProveedor, this.form.value.precio, this.form.value.garantia, -1, this.form.value.archivoBase64).subscribe(
          (result: any) => {
            if (result >= 0) {
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
              this.router.navigate(['activos']);
            } else {
              this.alertService.error(this.translateService.instant('error'));
            }
          });
    }
  }

  //Isometrico
  abrirModal() {
    this.selectedAuxiliarDibujo = this.selectedDibujo;
    this.modalReference = this.modalService.open(this.modalIsometrico, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true, scrollable: true });
  }

  guardarIsometrico() {
    this.selectedDibujo = this.selectedAuxiliarDibujo;
    this.modalReference.close();
  }

  //Opciones fecha
  recalcularFecha(){
    if(this.form.value.fechaPuestoEnServicio != "" && this.form.value.vidaUtil != 0.0){
      var year = this.form.value.fechaPuestoEnServicio.getFullYear();
      var month = this.form.value.fechaPuestoEnServicio.getMonth();
      var day = this.form.value.fechaPuestoEnServicio.getDate();
      var c = new Date(year + Math.floor(this.form.value.vidaUtil), month + (this.form.value.vidaUtil % 1)*12, day);
      this.form.value.fechaBajaEstimada = c;
    } else{
      this.form.value.fechaBajaEstimada = null;
    }
    
  }
}
