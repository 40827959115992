import { Component, HostListener, ViewChild } from '@angular/core';
import { InformeRendimientoService, MaquinasService, UsuariosService, MenuService, HmiService, HistoricoDatosService, InformeProyectosService, HistoricoOperacionesService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { dateFormatNames } from '@progress/kendo-angular-intl';

declare var GanttChartHoverLine: any;
@Component({
  selector: 'app-informe-tecnico-ejecucion',
  templateUrl: './informeTecnicoEjecucion.component.html'
})

export class InformeTecnicoEjecucionComponent {
  user = this.userService.userValue;

  // PRUEBA GRAFIKOAN MIN ETA MAX DEFINITU:
  public potenciometroMinMax = [0, 200];
  public velocidadMinMax = [0, 10000];
  public temperaturaMinMax = [0, 1];

  //LOADING
  public loadingGrafico5Ejes: boolean = false;

  //COLORES
  public colores: any = [
    { color: "#8B0707", css: "granate", enUso: false },
    { color: "#3366CC", css: "azul", enUso: false },
    { color: "#FF9900", css: "naranja", enUso: false },
    { color: "#9836CC", css: "morado", enUso: false },
    { color: "#B5E50A", css: "verde", enUso: false },
  ];

  //PANEL MAQUINAS
  private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];
  public aparecer = false;
  public maquinasElement: any;


  //MENU LATERAL
  public menuLateralDeplegado: boolean = false;
  public menuLateralElement: any;

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;
  public diaSemana: boolean = true;

  //GANTT
  public chartGanttTimeline;
  public dataValidador: JSON;
  public gantVisible: boolean = false;

  //DATOS
  public generales: any = [];
  public generalesEnGrafico: any; //AQUI ESTAN LOS DATOS QUE SE MUESTRAN EN EL GRÁFICO, POR SI YA SE HAN CARGADO LOS DATOS DE MAXIMA PRECISIÓN PERO AÚN NO SE HAN MOSTRADO EN EL GRÁFICO
  public ejes: any = [];
  public ejesEnGrafico: any; //AQUI ESTAN LOS DATOS QUE SE MUESTRAN EN EL GRÁFICO, POR SI YA SE HAN CARGADO LOS DATOS DE MAXIMA PRECISIÓN PERO AÚN NO SE HAN MOSTRADO EN EL GRÁFICO
  public numeroCamposSeleccionados: any;

  //GRAFICOS
  public grafico1: any;
  public grafico2: any;
  public grafico3: any;
  public grafico4: any;
  public grafico5: any;
  public mostrarGraficosPorLeyenda: any = [true, true, true, true, true];

  public ratonEncimaDeGraficoDeCincoEjes: boolean = false;

  public datuak: any;
  public arrayFechas: any = ["-"];
  public indicePanelIzquierdo: any = 0;

  public resumenMecanizadoCargado: boolean = false;
  public maximaPrecisionMecanizadoCargado: boolean = false;

  public fechaInicioZoom: any;
  public fechaFinZoom: any;

  public fechaPanelDerecho: string = "-";
  public operarioPanelDerecho: string = "-";
  public ofPanelDerecho: string = "-";
  public clientePanelDerecho: string = "-";
  public refPiezaPanelDerecho: string = "-";
  public piezaPanelDerecho: string = "-";
  public partePanelDerecho: string = "-";
  public operacionPanelDerecho: string = "-";
  public fechaRealPanelDerecho: string = "-";

  public timeOutZoom;


  //FILTRO
  public filtratuta: boolean = true;
  public ezkutatu: boolean = false;
  public consultaFiltroCompleto: string = "";
  public lehenAldia: boolean = true;
  public lehenExekuzioa: boolean = true;
  public fini: any;
  public ffin: any;
  public idcliente: any;
  public idpieza: any;
  public idMaquina: any;
  public idof: any;
  public nSerie: any;
  public idOperacion: any;
  private dataFiltro: any;
  private filtro_simple: any;
  status: boolean = true; //para abrir o cerrar el filtro * se carga abierto siempre! si se autofiltra, se cierra solo.
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translate.instant('y') },
    { id: 1, nombre: this.translate.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translate.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translate.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translate.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translate.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translate.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translate.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translate.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translate.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translate.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translate.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translate.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translate.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translate.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translate.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translate.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translate.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translate.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translate.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translate.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translate.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translate.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translate.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translate.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translate.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translate.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translate.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translate.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translate.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
  // DATOS INICIALES DEL FILTRO
  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translate.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translate.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
            operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -1),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          },
          {
            columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        ]
      }
    ]
  };
  public datosFiltro = {
    logic: { id: 1, nombre: this.translate.instant('o') },
    group: []
  };
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'date', field:"", tipo: 'date' },
    // { id: 2, nombre: 'dateTime', field:"", tipo: 'dateTime' },
    // { id: 4, nombre: 'comboEstrincto', field:"", tipo: 'comboEstrincto' },
    // { id: 10, nombre: 'comboFlexible', field:"", tipo: 'comboFlexible' },
    // { id: 16, nombre: 'check', field:"", tipo: 'check' },
    // { id: 17, nombre: 'numeric', field:"", tipo: 'numeric' },
    // { id: 18, nombre: 'decimal', field:"", tipo: 'decimal' },
    // { id: 19, nombre: 'string', field:"", tipo: 'string' }    
    // { id: 1, nombre: 'Fecha inicio', field: "fechaIni", sqlfield: "sql.fechaIni", tipo: 'date' },
    // { id: 2, nombre: 'Fecha fin', field: "fechaFin", sqlfield: "sql.fechaFin", tipo: 'date' },
    { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
    { id: 3, nombre: this.translate.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    //{ id: 4, nombre: this.translate.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    //{ id: 5, nombre: this.translate.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    { id: 6, nombre: this.translate.instant('maquina'), field: "idMaquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
    { id: 7, nombre: this.translate.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 9, nombre: this.translate.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string', maxItemsSelecteds: -1 },
    { id: 12, nombre: this.translate.instant('parte'), field: "nombreParte", sqlfield: "po.nombreParte", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 14, nombre: this.translate.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check', maxItemsSelecteds: -1 }
  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  public idmaquina_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;

  // azken makina gordetzeko, makina beti aukeratuta mantentzeko
  public lastMaquina: any;
  //END FILTRO


  //MODIFICAR PARAMETRO
  public ensenarModificar: boolean = true;
  public periodoInicioParametro: any;
  public periodoFinParametro: any;
  public listaParametros: any;
  public parametro: any;
  public valorActual: number;
  public valorModificar: number;

  public modalReference: NgbModalRef;
  public modalReferenceVerificar: NgbModalRef;
  public modalReferenceloading: NgbModalRef;

  @ViewChild('popupModificarParametro') popupModificarParametro: NgbModalRef;
  @ViewChild('popupVerificarGuardar') popupVerificarGuardar: NgbModalRef;

  constructor(private InformeRendimientoService: InformeRendimientoService,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private menuService: MenuService,
    private translate: TranslateService,
    private hmiService: HmiService,
    private historicoDatosService: HistoricoDatosService,
    private informeProyectosService: InformeProyectosService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private historicoOperacionesService: HistoricoOperacionesService,
    public myFunctions: MyFunctions) {

    this.userService.user.subscribe(x => this.user = x);

    this.menuService.titulo = this.translate.instant('informetecnicoejecucion').toUpperCase();

  }

  ngOnInit() {

    this.menuService.titulo = this.translate.instant('informetecnicoejecucion').toUpperCase();
    this.maquinasElement = document.getElementsByClassName('cont-select-maquinas-out')[0]; //Le indicamos el elemento maquinas para poder usarlo después en otras funciones
    this.menuLateralElement = document.getElementsByClassName('panel-menu')[0];

    this.loadingFiltro = true;

    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.cargarChartGanttTimeline();
    // });

    // FUNTZIO HAUEK ORAIN onFilter() funtzioan
    this.cargarFechas();
    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;
    // this.pintarGraficos();
    this.cargarGrupos();
    this.cargarAreasProductivas();


    this.hmiService.Get_maquinas_parametros().subscribe((result) => {
      this.listaParametros = result;
      this.parametro = this.listaParametros[0];
      if (this.listaParametros.length == 0) {
        this.ensenarModificar = false;
      } else {
        this.ensenarModificar = true;
      }
    });

  }

  posicionarEjesGrafico() {
    var element = document.getElementById("grafico1_inftec").getElementsByClassName("c3-event-rect")[0];
    var width = element["width"]["animVal"]["value"];

    // trasladar eje de los graficos
    document.getElementById("grafico1_inftec").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + 0 + "px, 0px)";
    document.getElementById("grafico2_inftec").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + -64 + "px, 0px)";
    // document.getElementById("grafico3_inftec").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + (width) + "px, 0px)";
    // document.getElementById("grafico4_inftec").getElementsByClassName("c3-axis-y")[0]["style"]["transform"]="translate(" + (width + 64) + "px, 0px)";
    document.getElementById("grafico4_inftec").getElementsByClassName("c3-axis-y2")[0]["style"]["transform"]="translate(" + (width + 64) + "px, 0px)";
  }

  //FILTRO

  cargarFiltroPorDefecto() {

    this.fechaInicio = this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -1);
    this.fechaInicio.setHours(0, 0, 0);
    this.fechaFin = this.myFunctions.getDateNow();

    this.filtroPorDefecto = {
      logic: { id: 1, nombre: this.translate.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translate.instant('y') },
          group: [
            {
              columna: { id: 1, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
              operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
              fechaIni: this.fechaInicio,
              fechaFin: this.fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: [],
              bloqueado: false
            },
            {
              columna: { id: 6, nombre: this.translate.instant('maquina'), field: "maquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
              operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
              fechaIni: this.fechaInicio,
              fechaFin: this.fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: this.filtro_listaMaquinas,
              comboSelected: {},
              comboSelecteds: [{
                id: this.filtro_listaMaquinas[0].id,
                idERP: this.filtro_listaMaquinas[0].idERP,
                idmarca: this.filtro_listaMaquinas[0].idMarca,
                idSeccion: this.filtro_listaMaquinas[0].idSeccion,
                idsGrupos: this.filtro_listaMaquinas[0].idsGrupos,
                imagen: this.filtro_listaMaquinas[0].imagen,
                imagenBase64: this.filtro_listaMaquinas[0].imagenBase64,
                nombre: this.filtro_listaMaquinas[0].nombre,
                puestoOffline: this.filtro_listaMaquinas[0].puestoOffline,
                tipo_maquina: this.filtro_listaMaquinas[0].tipo_maquina,
                visiblePlanificador: this.filtro_listaMaquinas[0].visiblePlanificador,
              }],
              bloqueado: true
            },
            {
              columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
              operator: { id: 0, nombre: '' },
              fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
              fechaFin: this.myFunctions.getDateNow(),
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: [],
              bloqueado: false
            }
          ]
        }
      ]
    };
  }


  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translate.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        bloqueado: false
      })
    }
  }
  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        bloqueado: false
      })
      this.actualizarVisible = true;
    }
  }
  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }
  // Filtro honetan makina bakarra aukeratu ahalko da, horretarako combobox-ean aukera daitezken elementu kopurua mugatuko da
  valueChangeComboEstatico(value: any) {
    this.datosFiltro.group[0].group.forEach(filtro => {
      var aux = [];
      if (filtro.columna.maxItemsSelecteds > 0) {
        if (filtro.comboSelecteds.length > filtro.columna.maxItemsSelecteds) {
          for (let i = 1; i < filtro.comboSelecteds.length; i++) {
            aux.push(filtro.comboSelecteds[i]);
          }
          filtro.comboSelecteds = [];
          aux.forEach(auxi => {
            filtro.comboSelecteds.push(auxi);
          });
          this.lastMaquina = aux;
        } else if (filtro.comboSelecteds.length == 0) {
          filtro.comboSelecteds = [];
          this.lastMaquina.forEach(auxi => {
            filtro.comboSelecteds.push(auxi);
          });
        }
      }
    });
  }
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.ezkutatu = true;
  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    console.log("URl 1");
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo() {
    // NUESTRO public datosFiltro = {                                                          => KENDO		public value: CompositeFilterDescriptor = {
    // NUESTRO   logic: { id: 0, nombre: 'and' },                                              => KENDO		    logic: "or",
    // NUESTRO   group: [                                                                      => KENDO		    filters: [
    // NUESTRO     {                                                                           => KENDO		      { field: "budget", operator: "gt", value: 60 },
    // NUESTRO       logic: { id: 0, nombre: 'and' },                                          => KENDO		      { field: "country", operator: "contains" },
    // NUESTRO       group: [                                                                  => KENDO		      { field: "discontinued", operator: "eq", value: true },
    // NUESTRO         {                                                                       => KENDO		      {
    // NUESTRO           columna: { id: 0, nombre: ' ', tipo: '' },                            => KENDO		        logic: "and",
    // NUESTRO           operator: { id: 0, nombre: '' },                                      => KENDO		        filters: [
    // NUESTRO           fechaIni: this.myFunctions.getDateNow(),                              => KENDO		          { field: "ordered on", operator: "lt", value: new Date(Date.now()) },
    // NUESTRO           fechaFin: this.myFunctions.getDateNow(),                              => KENDO		        ],
    // NUESTRO           mostrarCalendario: false,                                             => KENDO		      },
    // NUESTRO           text: '',                                                             => KENDO		    ],
    // NUESTRO           numberMin: 0,                                                         => KENDO		  };
    // NUESTRO           numberMax: 0,                                                         => KENDO
    // NUESTRO           decimalformat: '0.000',                                               => KENDO
    // NUESTRO           decimalMin: 0.0,                                                      => KENDO
    // NUESTRO           decimalMax: 0.0,                                                      => KENDO
    // NUESTRO           check: false,                                                         => KENDO
    // NUESTRO           combo: [                                                              => KENDO
    // NUESTRO             {id: 1, nombre: "opcion 1 "},                                       => KENDO
    // NUESTRO             {id: 2, nombre: "opcion 2"},                                        => KENDO
    // NUESTRO             {id: 3, nombre: "opcion 3"},                                        => KENDO
    // NUESTRO             {id: 4, nombre: "opcion 4"},                                        => KENDO
    // NUESTRO             {id: 5, nombre: "opcion 5"}                                         => KENDO
    // NUESTRO           ],                                                                    => KENDO
    // NUESTRO           comboSelected: {},                                                    => KENDO
    // NUESTRO           comboSelecteds: []                                                    => KENDO
    // NUESTRO         }                                                                       => KENDO
    // NUESTRO       ]                                                                         => KENDO
    // NUESTRO     }                                                                           => KENDO
    // NUESTRO   ]                                                                             => KENDO
    // NUESTRO };                                                                              => KENDO

    var em = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          // public opcionDate = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // DROP - Tipo nuevo para maquinas
        // else if (jFiltro.columna.tipo == 'drop') {
        //   var datoDrop: any = jFiltro.comboSelected;
        //   if(datoDrop[0].id != undefined){
        //     sqlFilter = jFiltro.columna.sqlfield + ' IN (' + datoDrop.id + ")";
        //   }
        // }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 6);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos90DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 3, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
      { nombre: this.translate.instant("manana"), id: 1 },
      { nombre: this.translate.instant("tarde"), id: 2 },
      { nombre: this.translate.instant("noche"), id: 3 }
    ];

    //OPERARIOS
    this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
      this.filtro_listaOperarios = data;
      this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));

      r3 = true;
      if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
    });

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.filtro_listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });

    //Esperamos hasta que se cargen las maquinas para coger la primera y ponerla como por defecto
    this.cargarFiltroPorDefecto();
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    this.consultaFiltroCompleto = this.filtroToSQL();
    // this.identificarMaquinaYCargarDatos(); // se llama al autofiltrarse en la primera vuelta

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
      (data: any) => {
        this.filtro_listaHerramientas = data;
        this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      });

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });

    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
        { id: 3, nombre: this.translate.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        //{ id: 4, nombre: this.translate.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        //{ id: 5, nombre: this.translate.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        //{ id: 6, nombre: this.translate.instant('maquina'), field: "idMaquina", sqlfield: "hb.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1  },
        { id: 7, nombre: this.translate.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        { id: 9, nombre: this.translate.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string', maxItemsSelecteds: -1 },
        { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        { id: 14, nombre: this.translate.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check', maxItemsSelecteds: -1 }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

  }
  preFiltrado(filtro, row) {
    console.log("URl 4");

    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible" || row.columna.tipo == "drop") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id == 3) { // turnos
        row.combo = this.filtro_listaTurnos;
      }
      else if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // operarios 
        row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.nombreOf)) {
              lag.push(of.nombreOf);
              var js = { id: of.nombreOf, nombre: of.nombreOf };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
              lag.push(operacion.nombreOperacion);
              var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {
    // si se miran las proximas funciones se vera como se aplica el filtro. 
    // aqui hay un ejemplo de todos modos:    
    //         var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    //         filtroFechas = filtroFechas.replace(/hb.fechaTurno(.*?)hb.fechaTurno/g, 'fechaMod$1fechaCreado');
    //         var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    // Hau ez da behar
    //this.cargarDatosInforme();
    // //this.cargarGraficosPerdidas();
    // console.log("onfilter")
    // Hau bai

    if ((this.filtro_simple['idMaquina'] != undefined && this.filtro_simple['idMaquina'] > 0) && this.lehenExekuzioa) {
      console.log("Filtro URL", this.filtro_simple['idMaquina']);
      this.lehenExekuzioa = false;
    } else if (this.lehenExekuzioa) {
      console.log("Bestela");
      this.cargarFiltroPorDefecto();
      this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
      this.lehenExekuzioa = false;
    }
    this.ezkutatu = false;
    this.filtratuta = true;
    this.consultaFiltroCompleto = "";
    this.consultaFiltroCompleto = this.filtroToSQL();

    var datos = this.datosFiltro;

    this.cargarFechas();
    

    // es necesario para que primero se forme la estructura html
    // setTimeout(() => {
    //   this.posicionarEjesGrafico();
    // }, 100);

    this.gantVisible = true;
    this.identificarMaquinaYCargarDatos();
    this.pintarGraficos();
    //this.status = true;
    this.actualizarVisible = false;
  }
  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    console.log("URl 2");

    
    var filtro_simple_str = sessionStorage.getItem('filtro_simple');
    if (filtro_simple_str) {
      this.filtro_simple = JSON.parse(filtro_simple_str); 
      sessionStorage.removeItem('filtro_simple');
    }else {
      this.filtro_simple = {
        idMaquina: 0,
        fini : 0,
        ffin : 0,
        clientes : 0,
        piezas : 0,
        ofs : 0,
        terminados : 0,
        idHistorico_piezas : 0,
        idHistorico_operaciones: 0
      }    
    }

    var datosFiltro = {
      logic: { id: 1, nombre: this.translate.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translate.instant('y') },
          group: []
        }
      ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.filtro_simple['idHistorico_piezas']) > 0) {
      // Historico Piezas:
      //   - fechaIni , fechaFin *fijo*
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

      // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
      var r1, r2 = false;
      // HISTORICO PIEZAS??
      /**/var IdHistorico = Number.parseInt(this.filtro_simple['idHistorico_piezas']);
      this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
          }
          r1 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
      // HISTORICO OPERACIONES??
      /**/var IdHistoricoOp = Number.parseInt(this.filtro_simple['idHistorico_operaciones']);
      this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
            this.idmaquina_prefiltro = an[0].idMaquina;
          }
          r2 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else {
      // Informe Proyectos:
      //   - fechaIni , fechaFin *fijo*
      //   - idcliente (posible extra)
      //   - idpieza (posible extra)
      //   - idof (posible extra)
      //   - terminados (posible extra)
      // FECHAS (INICIO, FIN)
      /**/this.fini = this.filtro_simple['fini'];
      /**/this.ffin = this.filtro_simple['ffin'];
      if (this.filtro_simple['fini'] != '0' && this.filtro_simple['ffin'] != '0'
        && this.filtro_simple['fini'] != undefined && this.filtro_simple['ffin'] != undefined) {
        console.log("URl 2.5 / fecha 1");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
            operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.filtro_simple['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.filtro_simple['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
      } else {
        console.log("URl 2.5 / fecha 2");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 1, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
            operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -1),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
      }
      // OF
      /**/this.idof = Number.parseInt(this.filtro_simple['ofs']);
      var rowOFs = this.dataFiltro.filter(x => x.idOf == this.filtro_simple['ofs']);
      if (rowOFs.length > 0) {
        console.log("URl 2.5 / of");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
      }
      // CLIENTE
      /**/this.idcliente = Number.parseInt(this.filtro_simple['clientes']);
      // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
      var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.filtro_simple['clientes']);
      if (rowClientes.length > 0) {
        console.log("URl 2.5 / cliente");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 9, nombre: this.translate.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
      }
      // PIEZA
      /**/this.idpieza = Number.parseInt(this.filtro_simple['piezas']);
      var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.filtro_simple['piezas']);
      if (rowPiezas.length > 0) {
        console.log("URl 2.5 / pieza");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
      }
      // MAQUINA
      /**/this.idMaquina = Number.parseInt(this.filtro_simple['idMaquina']);
      var rowMaquinas = this.dataFiltro.filter(x => x.idMaquina == this.filtro_simple['idMaquina']);
      if (rowMaquinas.length > 0) {
        console.log("URl 2.5 / makina");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 6, nombre: this.translate.instant('maquina'), field: "maquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
            operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: true
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var maquina = { id: rowMaquinas[0].nombreMaquina, nombre: rowMaquinas[0].nombreMaquina }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [maquina];
      }
      // TERMINADO 
      // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
      // * solo lo voy a filtrar si es 1
      /**/var terminados = Number.parseInt(this.filtro_simple['terminados']);
      if (this.filtro_simple['terminados'] == '1') {
        console.log("URl 2.5 / terminado");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 14, nombre: this.translate.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check', maxItemsSelecteds: -1 },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
      }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    console.log("URl 3");

    var datosFiltro = {
      logic: { id: 1, nombre: this.translate.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translate.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.filtro_simple['fini'];
    /**/this.ffin = this.filtro_simple['ffin'];
    if (this.filtro_simple['fini'] != '0' && this.filtro_simple['ffin'] != '0'
      && this.filtro_simple['fini'] != undefined && this.filtro_simple['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translate.instant('fecha'), field: "fecha", sqlfield: "dtr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
          operator: { id: 7, nombre: this.translate.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.filtro_simple['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.filtro_simple['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translate.instant('of'), field: "nombreOf", sqlfield: "po.numeroOF", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translate.instant('pieza'), field: "nombrePieza", sqlfield: "po.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translate.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string', maxItemsSelecteds: -1 },
          operator: { id: 7, nombre: this.translate.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translate.instant('operacion'), field: "nombreOperacion", sqlfield: "po.nombreOperacion", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // MAQUINA
    var rowMaquinas = this.dataFiltro.filter(x => x.idMaquina == this.idmaquina_prefiltro);
    if (rowMaquinas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 6, nombre: this.translate.instant('maquina'), field: "maquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
          operator: { id: 2, nombre: this.translate.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: true
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var maquina = { id: rowMaquinas[0].idMaquina, nombre: rowMaquinas[0].nombreMaquina }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [maquina];
    }
    this.idmaquina_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translate.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }

  // END FILTRO










  clickDesplegarMenuLateral() {
    this.menuLateralDeplegado = !this.menuLateralDeplegado;
  }

  btnDia() {
    this.diaSemana = true;
    this.cargarFechas();
  }

  btnSemana() {
    this.diaSemana = false;
    this.cargarFechas();
  }

  public fechaInicio_dateChanged(value: Date): void {
    this.cargarFechas();
  }

  private cargarFechas() {

    if (this.datosFiltro.group[0]?.group.length > 0) {
      // Cargamos las fechas desde el filtro
      this.datosFiltro.group[0].group.forEach(dato => {
        if (dato.columna.field == "fecha") {
          this.fechaInicio = new Date(dato.fechaIni);
          this.fechaFin = new Date(dato.fechaFin);
          this.periodoInicioParametro = this.fechaInicio;
          this.periodoFinParametro = this.fechaFin;
        }
      });
    }
    else if (this.diaSemana) {//CARGAR DATOS DE UN DIA

      var fechaIni;
      //calcular fecha inicio
      if (this.fechaInicio == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow()
      }
      //calcular fecha fin
      fechaIni = new Date(this.fechaInicio);
      this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 1));
      //calcular horas
      this.fechaInicio.setHours(0, 0, 0);
      this.fechaFin.setHours(0, 0, 0);

    } else {//CARGAR DATOS DE UNA SEMANA

      var fechaIni;
      //calcular fecha inicio
      if (this.fechaInicio == undefined) {
        this.fechaInicio = this.myFunctions.getDateNow()
      }
      fechaIni = this.fechaInicio
      if (fechaIni.getDay() == 3) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
      } else if (fechaIni.getDay() == 4) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
      } else if (fechaIni.getDay() == 5) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
      } else if (fechaIni.getDay() == 6) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
      } else if (fechaIni.getDay() == 0) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
      } else if (fechaIni.getDay() == 2) {
        this.fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
      }
      //calcular fecha fin
      fechaIni = new Date(this.fechaInicio);
      this.fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 1));
      //calcular horas
      this.fechaInicio.setHours(0, 0, 0);
      this.fechaFin.setHours(0, 0, 0);

    }



  }

  pintarGraficos() {

    var th = this;

    var i;
    for (i = 1; i <= 4; i++) {

      let information = th.getLabel(i-1);
      let labelAux = information.label;
      let minAux = information.min;
      let maxAux = information.max;
      let positionAux = 'outer-middle';
      let showY1axis = true;
      let showY2axis = true
      if (i <= 2) showY2axis = false;
      else showY1axis = false;

      var chart = c3.generate({
        bindto: "#grafico" + i + "_inftec",
        size: {
          height: 550
        },
        padding: {
          left: 156,
          right: 15,
          bottom: 15
        },
        data: {
          x: 'x',
          columns: [
            ['x'],
            ['valor']
          ],
          order: null,
          type: 'spline'
        },
        spline: {
          interpolation: {
            type: "monotone"
          }
        },
        transition: {
          duration: 0
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              values: function (x) {
                var fechaIni = x[0];
                fechaIni.setMinutes(fechaIni.getMinutes() - 10);
                var fechaFin = x[1];
                fechaFin.setMinutes(fechaFin.getMinutes() + 10);
                return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(15);
              },
              //format: '%m/%d - %H:%M:%S'
              format: function (x) {
                if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                  if (x.getDay() == 0) return th.translate.instant("domingo");
                  if (x.getDay() == 1) return th.translate.instant("lunes");
                  if (x.getDay() == 2) return th.translate.instant("martes");
                  if (x.getDay() == 3) return th.translate.instant("miercoles");
                  if (x.getDay() == 4) return th.translate.instant("jueves");
                  if (x.getDay() == 5) return th.translate.instant("viernes");
                  if (x.getDay() == 6) return th.translate.instant("sabado");
                } else {
                  return th.myFunctions.dateToHourString(x);
                }
              },
            },
            padding: 0
          },
          y: {
            show: showY1axis,
            label: { 
              position: positionAux,
              text: labelAux
            },
            min: minAux,
            max: maxAux,
            padding: 0,
            tick: {
              count: 11,
              format: function(x) {
                return th.myFunctions.numero_con_formato(x, 1)
              }
            }
          },
          y2: {
            show: showY2axis,
            label: { 
              position: positionAux,
              text: labelAux
            },
            min: minAux,
            max: maxAux,
            padding: 0,
            tick: {
              count: 11,
              format: function(x) {
                return th.myFunctions.numero_con_formato(x, 1)
              }
            }
          }
        },
        legend: {
          show: false
        },
        point: {
          show: false
        },
        tooltip: {
          contents: function (d, defaultTitleFormat, defaultValueFormat, color) {

            var fecha = d[0].x;

            if (fecha >= th.fechaInicioZoom && fecha <= th.fechaFinZoom) {

              if (th.ratonEncimaDeGraficoDeCincoEjes && th.gantVisible) {
                th.chartGanttTimeline.showHoverLine(d[0].x);
                th.enseñarDatosOfPanelIzquierda();
              }

              var $$ = this, config = $$.config,
                titleFormat = config.tooltip_format_title || defaultTitleFormat,
                nameFormat = config.tooltip_format_name || function (name) { return name; },
                valueFormat = config.tooltip_format_value || defaultValueFormat,
                text, i, title, value, name, bgcolor;
              for (i = 0; i < d.length; i++) {
                if (!(d[i] && (d[i].value || d[i].value === 0))) { continue; }

                if (!text) {
                  title = titleFormat ? titleFormat(d[i].x) : d[i].x;
                  text = "<table class='" + $$.CLASS.tooltip + "'>" + (title || title === 0 ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
                }

              }

              var indice = th.arrayFechas.findIndex(x => x == d[0].x);
              th.indicePanelIzquierdo = indice;
              var camposSeleccionados = [];

              th.generales.forEach(function (campo) {
                if (campo.seleccionado) camposSeleccionados.push(campo);
              }, this);

              th.ejes.forEach(function (eje) {
                eje.campos.forEach(function (campo) {
                  if (campo.seleccionado) camposSeleccionados.push(campo);
                }, this);
              }, this);

              camposSeleccionados.forEach(function (campo) {
                if (th.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1]) {
                  name = campo.nombre;
                  value = campo.valoresMomentaneo[indice] + " " + campo.unidad;
                  bgcolor = campo.color;
                  text += "<tr class='" + $$.CLASS.tooltipName + "-" + d[0].id + "'>";
                  text += "<td class='name'><span style='background-color:" + bgcolor + "'></span>" + name + "</td>";
                  text += "<td class='value'>" + value + "</td>";
                  text += "</tr>";
                }
              }, this);

              // return text + "</table>";

            }

          }
        },
        zoom: {
          enabled: (i == 1),
          type: 'drag',
          disableDefaultBehavior: true,
          onzoomend: function (domain) {
            th.fechaInicioZoom = domain[0];
            th.fechaFinZoom = domain[1];
            th.aplicarZoomEnGrafico();
            //Aplicar el zoom al GANT
            th.chartGanttTimeline.setDomain([th.fechaInicioZoom, th.fechaFinZoom]);
            th.chartGanttTimeline.updateZoomAllCharts(th.chartGanttTimeline, [], th.fechaInicioZoom, th.fechaFinZoom);
          }
        }
      });

      if (i == 1) this.grafico1 = chart;
      if (i == 2) this.grafico2 = chart;
      if (i == 3) this.grafico3 = chart;
      if (i == 4) this.grafico4 = chart;
      // if (i == 5) this.grafico5 = chart;

    }

    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#grafico1_inftec svg').node()?.childNodes;
    if (list_of_children){
      list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

      d3.select('#grafico1_inftec .c3-event-rect')._groups[0][0].onmousedown = function (e) {
        if (e.button != 0) {
          var evt = new MouseEvent("mousedown", {
            altKey: e.altKey,
            bubbles: e.bubbles,
            button: 0,
            buttons: e.buttons,
            cancelable: e.cancelable,
            clientX: e.clientX,
            clientY: e.clientY,
            composed: e.composed,
            ctrlKey: e.ctrlKey,
            detail: e.detail,
            metaKey: e.metaKey,
            movementX: e.movementX,
            movementY: e.movementY,
            relatedTarget: e.relatedTarget,
            screenX: e.screenX,
            screenY: e.screenY,
            shiftKey: e.shiftKey,
            view: e.view
          });
          d3.select("#grafico1_inftec .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
        }
      }
      d3.select('#grafico2_inftec .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_inftec .c3-event-rect')._groups[0][0].onmousedown;
      d3.select('#grafico3_inftec .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_inftec .c3-event-rect')._groups[0][0].onmousedown;
      d3.select('#grafico4_inftec .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_inftec .c3-event-rect')._groups[0][0].onmousedown;
      // d3.select('#grafico5_inftec .c3-event-rect')._groups[0][0].onmousedown = d3.select('#grafico1_inftec .c3-event-rect')._groups[0][0].onmousedown;
    }
  }

  public getLabel(index) {
    var result: any;
    var min = 0
    var max = 200
    var generalesSeleccionados = this.generales?.filter(f => f.seleccionado);

    if (generalesSeleccionados.length > 0 && generalesSeleccionados.length >= (index + 1)) {
      result = {
                label: generalesSeleccionados[index].nombre,
                min: generalesSeleccionados[index].min,
                max: generalesSeleccionados[index].max
               };
    }
    else {
      var i = index - (generalesSeleccionados.length);
      var arrayEjes = [];
      this.ejes.forEach(campos => {
        campos.campos.forEach(element => {
          if (element.seleccionado) {
            arrayEjes.push({label: element.nombre + " (" + campos.nombre + ")", min: element.minimo, max: element.maximo});
          }
        });
      });
      result = arrayEjes[i];
      if (result == undefined)
        result = {label: "", min: 0, max: 100}
    }
    return {label: result.label, min: result.min, max: result.max}
  }

  public mouseEnterGraficoCincoEjes() {
    this.ratonEncimaDeGraficoDeCincoEjes = true;
  }

  public mouseLeaveGraficoCincoEjes() {
    this.ratonEncimaDeGraficoDeCincoEjes = false;
    this.chartGanttTimeline.hideHoverLine();
  }

  // cargarGrupos() {

  //   this.maquinasService.getGruposMaquinas().subscribe(json => {
  //     this.grupos = json.data;
  //     this.gruposCargados = true;
  //     if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
  //   });

  // }

  cargarGrupos() {
    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      console.log("Grupos cargados");
      if (this.gruposCargados && this.seccionesCargadas)
        if (this.lehenAldia) {
          this.lehenAldia = false;
          this.cargar_Filtro();
        }
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }




  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        console.log("Secciones cargados R1");
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
          if (this.lehenAldia) {
            this.lehenAldia = false;
            this.cargar_Filtro();
          }
          this.filtrarMaquinasPorAreaProductivaYGrupo();
        }
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      console.log("Secciones cargados R1");
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
        if (this.lehenAldia) {
          this.lehenAldia = false;
          this.cargar_Filtro();
        }
        this.filtrarMaquinasPorAreaProductivaYGrupo();
      }
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        console.log("Secciones cargados R2");
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
          if (this.lehenAldia) {
            this.lehenAldia = false;
            this.cargar_Filtro();
          }
          this.filtrarMaquinasPorAreaProductivaYGrupo();
        }
      })
    } else {
      this.instalaciones = instalaciones_model;
      console.log("Secciones cargados R2");
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
        if (this.lehenAldia) {
          this.lehenAldia = false;
          this.cargar_Filtro();
        }
        this.filtrarMaquinasPorAreaProductivaYGrupo();
      }
    }

  }

  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    } else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    } else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0) this.selectedMaquina = this.maquinasMostradas[0];
    else this.selectedMaquina = undefined;

    // if (cargarDatos) this.identificarMaquinaYCargarDatos();

  }

  identificarMaquinaYCargarDatos() {
    if (this.filtro_simple['idMaquina'] != 0) {
      console.log("Filtro URL")
      var maquinaSeleccionada;
      console.log("makina: ", this.maquinas)
      console.log("url makinak: ", this.filtro_simple['idMaquina'])
      this.maquinas.forEach(maquina => {
        if (maquina.id == this.filtro_simple['idMaquina']) {
          maquinaSeleccionada = maquina;
          console.log("makina: ", maquina)
          this.selectedMaquina = maquina;
        }
      });

    } else {
      console.log("Bestela")
      var maquinaSeleccionada;
      this.datosFiltro.group[0].group.forEach(grupo => {
        if (grupo.columna.id == 6) {
          maquinaSeleccionada = grupo.comboSelecteds[0];
          this.selectedMaquina = maquinaSeleccionada;
        }
      });

    }
    var maquinaSeleccionada = this.selectedMaquina;
    var maquinaSeleccionada;
    if (this.filtro_simple['idMaquina'] == undefined) {
      this.datosFiltro.group[0].group.forEach(grupo => {
        if (grupo.columna.id == 6) {
          maquinaSeleccionada = grupo.comboSelecteds[0];
          this.selectedMaquina = maquinaSeleccionada;
        }
      });
    }


    if (maquinaSeleccionada != undefined) { //HAY UNA MAQUINA SELECCIONADA

      //IDENTIFICAMOS EL TIPO DE MAQUINA QUE ES Y DEPENDIENDO DEL TIPO DE MAQUINA CARGAMOS LOS DATOS DE UNA MANERA U OTRA

      if (maquinaSeleccionada.tipo_maquina == 1) { //MECANIZADO
        this.gantVisible = true;
        this.cargarChartGanttTimeline();
        this.cargarBotonesEjesPotenciometros();
      }
      if (maquinaSeleccionada.tipo_maquina == 2) { //EXTRUSORA
        this.gantVisible = true;
        this.cargarChartGanttTimeline();
        this.cargarExtrusoraCampos();
      }
      if (maquinaSeleccionada.tipo_maquina == 3) { //IMPRESORA DE PLASTICO
        this.gantVisible = true;
        this.cargarChartGanttTimeline();
        this.cargarImpresoraPlasticoCampos();
      }
      if (maquinaSeleccionada.tipo_maquina == 4) { //INYECTORA
        this.gantVisible = true;
        this.cargarChartGanttTimeline();
        this.cargarInyectoraCampos();
      }
      if (maquinaSeleccionada.tipo_maquina == 5) { //HORNO
        this.gantVisible = true;
        this.cargarChartGanttTimeline();
        this.cargarHornoCampos();
      }
      if (maquinaSeleccionada.tipo_maquina == 8) { //INSTALACION
        this.gantVisible = false;
        this.cargarInstalacionCampos();
      }
      // if (maquinaSeleccionada.tipo_maquina == 9) { //ADITIVO
      //   this.gantVisible = true;
      //   this.cargarChartGanttTimeline();
      //   this.cargarHornoCampos();
      // }
      if (maquinaSeleccionada.tipo_maquina == 12) { //HORNO 2 (HORNOS TEMPLE)
        this.gantVisible = true;
        var a = this.datosFiltro;
        this.cargarChartGanttTimeline();
        this.cargarHornosTempleCampos();
      }

    } else { //NO HAY UNA MAQUINA SELECCIONADA

      this.resetearDatosGrafico();

    }

  }

  private cargarChartGanttTimeline() {
    // this.InformeRendimientoService.gantt_sinTurno(this.selectedMaquina.id, "1, 2, 3", this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe(
    //   json => {
    //     this.dataValidador = json;
    //     this.generarChartGanttTimeline();
    //   }
    // )

    var consulta = this.consultaFiltroCompleto.replace("dtr.fecha", "hb.fechaTurno");
    consulta = consulta.replace("dtr.fecha", "hb.fechaTurno");
    consulta = consulta.replace("dtr.idMaquina", "hb.idMaquina");

    this.InformeRendimientoService.gantt_sinTurno_filtro(consulta).subscribe(
      json => {
        this.dataValidador = json;
        this.generarChartGanttTimeline();
      }
    )

  }

  private generarChartGanttTimeline() {
    var a = this.datosFiltro;
    var an: any = this.dataValidador;
    // Si no hay filtro de fecha seleccionado, hay que sacar las fechas inicio y fin desde la consulta
    if (!this.consultaFiltroCompleto.includes('fecha')) {
      this.fechaInicio = new Date(an[0].fechaini);
      this.fechaFin = new Date(an[an.length - 1].fechafin);
    }

    let tasksNumber = 600,
      startDate = new Date(this.fechaInicio.getFullYear(), this.fechaInicio.getMonth(), this.fechaInicio.getDate(), this.fechaInicio.getHours()),
      endDate = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate(), this.fechaFin.getHours());
      this.fechaInicioZoom = startDate;
      this.fechaFinZoom = endDate;
    let data = {
      machines: [
        {
          id: '1',
          name: this.translate.instant("estado") + '  ',
          image: ''
        }
      ],
      tasks: []
    };

    //var an: any = this.dataValidador;
    //CALCULAR TOOLTIP
    an.forEach(f => {

      //an.machine = 1;

      //   var tooltip = ``;

      //   tooltip = tooltip + `<div class="tooltipa"><p><strong><span class="tooltip-negrita">`;

      //   if (f.idProcesos_Tipo == 1) tooltip = tooltip + `${this.translate.instant("ejecucion").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 2) tooltip = tooltip + `${this.translate.instant("parada").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 3) tooltip = tooltip + `${this.translate.instant("preparacion").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 4) tooltip = tooltip + `${this.translate.instant("mantenimiento").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 6) tooltip = tooltip + `${this.translate.instant("alarma").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 7) tooltip = tooltip + `${this.translate.instant("alarma").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 8) tooltip = tooltip + `${this.translate.instant("apagada").toUpperCase()}`;
      //   if (f.idProcesos_Tipo == 9) tooltip = tooltip + `${this.translate.instant("canal").toUpperCase()}`;

      //   tooltip = tooltip + `</strong></label></p>`;

      //   if (f.nombrePrograma != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${f.nombrePrograma}<br></span></p>`;

      //   if (f.descripcion != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("descripcion").toUpperCase()}: </span><span class="tooltip-valor">${f.descripcion}<br></span></p>`;

      //   tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${f.operario}</span></p>`;

      //   tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

      //   if (f.refOF != "" && f.idProcesos_Tipo != 4) {
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("of").toUpperCase()}: </span><span class="tooltip-valor">${f.of}<br></span></p>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("cliente").toUpperCase()}: </span><span class="tooltip-valor">${f.cliente}<br></span></p>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("refPieza").toUpperCase()}: </span><span class="tooltip-valor">${f.refPieza}<br></span></p>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("pieza").toUpperCase()}: </span><span class="tooltip-valor">${f.pieza}<br></span></p>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("operacion").toUpperCase()}: </span><span class="tooltip-valor">${f.programa}<br></span></p>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${f.numeroSerie}<br></span></p>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("cantidad2").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidad}<br></span></p>`;
      //   } else if (f.idProcesos_Tipo != 4) {
      //     tooltip = tooltip + `<p><span class="tooltip-title">${this.translate.instant("operacionNoEncontrada").toUpperCase()}<br></span></p>`;
      //   } else {
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("mantenimiento").toUpperCase()}: </span><span class="tooltip-valor">${f.nombreMantenimiento}<br></span></p>`;
      //   }

      //   if (f.perdida != "") {
      //     tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
      //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("perdida").toUpperCase()}: </span><span class="tooltip-valor">${f.perdida}<br></span></p>`;
      //   }

      //   tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

      //   var fIni = new Date(f.fechaini);
      //   var fFin = new Date(f.fechafin);
      //   var dif = this.myFunctions.secondsTo_HH_MM_SS((fFin.getTime() - fIni.getTime()) / 1000);

      //   tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("duracion").toUpperCase()}: </span><span class="tooltip-valor">${dif}<br></span></p>`;
      //   tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("inicio2").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fIni)}<br></span></p>`;
      //   tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translate.instant("fin").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fFin)}<br></span></p>`;

      //   tooltip = tooltip + `</div>`;

      // f.detail2 = tooltip;

      f.detail = (d) => f.detail2;

    });
    data.tasks = an;

    //BORRAR TODOS LOS TOOLTIPS DEL GANTT, PORQUE SE VAN A VOLVER A CREAR
    // var tooltips = document.getElementsByClassName('tooltip-gantt');
    // while (tooltips[0]) {
    //   tooltips[0].parentNode.removeChild(tooltips[0]);
    // }

    this.chartGanttTimeline = new GanttChartHoverLine('chartGanttTimeline', {
      // width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: 100,
      startDate: startDate,
      endDate: endDate,
      enableHoverLines: true,
      addHoverLineMargin: 81,
      usuario: this.user,
      data: data
    }, this.translate).on('task_mouse', (hoverDate) => {
        var fechas = this.grafico1.data()[0].values.map(a => a.x);
        var nearestDate = fechas.reduce(function (prev, curr) { return (Math.abs(curr.getTime() - new Date(hoverDate).getTime()) < Math.abs(prev.getTime() - new Date(hoverDate).getTime()) ? curr : prev); });
        this.grafico1.tooltip.show({ x: nearestDate });
        this.enseñarDatosOfPanelIzquierda();
        this.chartGanttTimeline.showHoverLine(nearestDate);
    })
    .on('task_hide', () => {
      this.chartGanttTimeline.hideHoverLine();
  });

    // this.chartGanttTimeline
    //   .on('mouseenter', (hoverDate) => {
    //     if (!this.chartGanttTimeline.isZooming) {
    //       // var fechas = this.grafico1.data()[0].values.map(a => a.x);
    //       // var nearestDate = fechas.reduce(function (prev, curr) { return (Math.abs(curr - hoverDate) < Math.abs(prev - hoverDate) ? curr : prev); });
    //       // this.grafico1.tooltip.show({ x: nearestDate });
    //       this.enseñarDatosOfPanelIzquierda();
    //     }
    //   })
      // .on('mousemove', (hoverDate) => {
      //   if (!this.chartGanttTimeline.isZooming) {
      //     // var fechas = this.grafico1.data()[0].values.map(a => a.x);
      //     // var nearestDate = fechas.reduce(function (prev, curr) { return (Math.abs(curr - hoverDate) < Math.abs(prev - hoverDate) ? curr : prev); });
      //     // this.grafico1.tooltip.show({ x: nearestDate });
      //     this.enseñarDatosOfPanelIzquierda();
      //   }
      // })
      // .on('mouseleave', () => {
      //   // this.grafico1.tooltip.hide();
      // });

    var gant = this.chartGanttTimeline;

    this.chartGanttTimeline.eventoMoverBarraZoom = func => {
      this.timeOutZoom
      clearTimeout(this.timeOutZoom)
      this.timeOutZoom = setTimeout(this.zoomDesdeGantt, 250, [gant, this]);
    }

    

  }

  enseñarDatosOfPanelIzquierda() {

    if (this.chartGanttTimeline.tareaActual.length > 0) { //SI HAY NINGUNA TAREA SELECCIONADA EN EL GANTT

      var tarea = this.chartGanttTimeline.tareaActual[0];

      this.fechaPanelDerecho = (tarea.startDate == "") ? "-" : this.myFunctions.dateToString(tarea.startDate);
      this.operarioPanelDerecho = (tarea.operario == "") ? "-" : tarea.operario;
      this.ofPanelDerecho = (tarea.of == "") ? "-" : tarea.of;
      this.clientePanelDerecho = (tarea.cliente == "") ? "-" : tarea.cliente;
      this.refPiezaPanelDerecho = (tarea.refPieza == "") ? "-" : tarea.refPieza;
      this.piezaPanelDerecho = (tarea.pieza == "") ? "-" : tarea.pieza;
      this.partePanelDerecho = (tarea.parte == "") ? "-" : tarea.parte;
      this.operacionPanelDerecho = (tarea.nombrePrograma == "") ? "-" : tarea.nombrePrograma;

    } else { //NO HAY NINGUNA TAREA SELECCIONADA EN EL GANTT

      this.fechaPanelDerecho = "-";
      this.operarioPanelDerecho = "-";
      this.ofPanelDerecho = "-";
      this.clientePanelDerecho = "-";
      this.refPiezaPanelDerecho = "-";
      this.piezaPanelDerecho = "-";
      this.partePanelDerecho = "-";
      this.operacionPanelDerecho = "-";

    }

  }

  clickSwitch(value, campo) {

    if (value) { //UN CAMPO HA SIDO SELECCIONADO
      var primerColorEnDesuso = this.colores.find(function (x) { return !x.enUso; });
      primerColorEnDesuso.enUso = true;
      campo.cssSwitch = primerColorEnDesuso.css;
      campo.color = primerColorEnDesuso.color;
      campo.seleccionadoLeyenda = true;
      campo.estaEnGrafico = -1;
      this.numeroCamposSeleccionados++;
    } else { //UN CAMPO HA SIDO DESELECCIONADO
      var color = this.colores.find(function (x) { return x.color == campo.color; });
      color.enUso = false;
      campo.cssSwitch = "";
      campo.color = "";
      campo.seleccionadoLeyenda = true;
      campo.estaEnGrafico = -1;
      this.numeroCamposSeleccionados--;
    }

    // Esto es necesario para que el label de los graficos se actualice
    this.pintarGraficos();

    if (this.selectedMaquina.tipo_maquina == 1) this.mostrarDatosEnGraficoMecanizado();
    if (this.selectedMaquina.tipo_maquina == 2) this.mostrarDatosEnGraficoExtrusora();
    if (this.selectedMaquina.tipo_maquina == 3) this.mostrarDatosEnGraficoImpresoraPlastico();
    if (this.selectedMaquina.tipo_maquina == 4) this.mostrarDatosEnGraficoInyectora();
    if (this.selectedMaquina.tipo_maquina == 5) this.mostrarDatosEnGraficoHorno();
    if (this.selectedMaquina.tipo_maquina == 8) this.mostrarDatosEnGraficoInstalacion();
    if (this.selectedMaquina.tipo_maquina == 12) this.mostrarDatosEnGraficoHornosTemple();

  }

  clicklLeyenda(campo) {
    if (campo.seleccionadoLeyenda) {
      campo.seleccionadoLeyenda = false;
      this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = false;
    } else {
      campo.seleccionadoLeyenda = true;
      this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = true;
    }
  }

  onClickMostrarTodos() {

    this.fechaInicioZoom = this.fechaInicio;
    this.fechaFinZoom = this.fechaFin;

    if (this.selectedMaquina.tipo_maquina == 1) this.mostrarDatosEnGraficoMecanizado();
    if (this.selectedMaquina.tipo_maquina == 2) this.mostrarDatosEnGraficoExtrusora();
    if (this.selectedMaquina.tipo_maquina == 3) this.mostrarDatosEnGraficoImpresoraPlastico();
    if (this.selectedMaquina.tipo_maquina == 4) this.mostrarDatosEnGraficoInyectora();
    if (this.selectedMaquina.tipo_maquina == 5) this.mostrarDatosEnGraficoHorno();
    if (this.selectedMaquina.tipo_maquina == 8) this.mostrarDatosEnGraficoInstalacion();
    if (this.selectedMaquina.tipo_maquina == 12) this.mostrarDatosEnGraficoHornosTemple();

    //Aplicar el zoom al GANT
    this.chartGanttTimeline.setDomain([this.fechaInicioZoom, this.fechaFinZoom]);
    this.chartGanttTimeline.updateZoomAllCharts(this.chartGanttTimeline, [], this.fechaInicioZoom, this.fechaFinZoom);
    //HACERLO DOS VECES PORQUE SINO NO HACE BIEN EL ZOOM AL GANT
    this.chartGanttTimeline.setDomain([this.fechaInicioZoom, this.fechaFinZoom]);
    this.chartGanttTimeline.updateZoomAllCharts(this.chartGanttTimeline, [], this.fechaInicioZoom, this.fechaFinZoom);

  }

  aplicarZoomEnGrafico() {

    if (this.selectedMaquina.tipo_maquina == 1) this.mostrarDatosEnGraficoMecanizado();
    if (this.selectedMaquina.tipo_maquina == 2) this.mostrarDatosEnGraficoExtrusora();
    if (this.selectedMaquina.tipo_maquina == 3) this.mostrarDatosEnGraficoImpresoraPlastico();
    if (this.selectedMaquina.tipo_maquina == 4) this.mostrarDatosEnGraficoInyectora();
    if (this.selectedMaquina.tipo_maquina == 5) this.mostrarDatosEnGraficoHorno();
    if (this.selectedMaquina.tipo_maquina == 8) this.mostrarDatosEnGraficoInstalacion();
    if (this.selectedMaquina.tipo_maquina == 12) this.mostrarDatosEnGraficoHornosTemple();

  }

  zoomDesdeGantt(args) {

    var gant = args[0];
    var th = args[1];

    th.fechaInicioZoom = gant.__cachedDomain[0];
    th.fechaFinZoom = gant.__cachedDomain[1];

    if (th.selectedMaquina.tipo_maquina == 1) th.mostrarDatosEnGraficoMecanizado();
    if (th.selectedMaquina.tipo_maquina == 2) th.mostrarDatosEnGraficoExtrusora();
    if (th.selectedMaquina.tipo_maquina == 3) th.mostrarDatosEnGraficoImpresoraPlastico();
    if (th.selectedMaquina.tipo_maquina == 4) th.mostrarDatosEnGraficoInyectora();
    if (th.selectedMaquina.tipo_maquina == 5) th.mostrarDatosEnGraficoHorno();
    if (th.selectedMaquina.tipo_maquina == 8) th.mostrarDatosEnGraficoInstalacion();
    if (th.selectedMaquina.tipo_maquina == 12) this.mostrarDatosEnGraficoHornosTemple();

  }

  private cargarBotonesEjesPotenciometros() {

    this.loadingGrafico5Ejes = true;

    var datuak_ejes: any;
    var datuak_potenciometros: any;

    var r1, r2: boolean = false;

    this.hmiService.Get_ejes_maquina(this.selectedMaquina.id).subscribe((result) => {
      datuak_ejes = result;
      datuak_ejes.forEach(element => {
        element.configuracion = JSON.parse(element.configuracion);
      });
      r1 = true;
      if (r1 && r2) 
        this.crearBotonesEjesPotenciometros(datuak_ejes, datuak_potenciometros);
    });

    this.hmiService.Get_potenciadores_maquina(this.selectedMaquina.id).subscribe((result) => {
      datuak_potenciometros = result;
      r2 = true;
      if (r1 && r2) 
        this.crearBotonesEjesPotenciometros(datuak_ejes, datuak_potenciometros);
    });

  }

  private crearBotonesEjesPotenciometros(datuak_ejes: any, datuak_potenciometros: any) {

    this.generales = [];

    if (datuak_potenciometros[0].potenVisible) {
      var poten = {
        id: -1,
        nombre: this.translate.instant('potenCabezal'),
        seleccionado: false,
        seleccionadoLeyenda: true,
        estaEnGrafico: -1,
        cssSwitch: "",
        color: "",
        unidad: "%",
        valores: [],
        valoresMomentaneo: [],
        fechas: [],
        max: datuak_potenciometros[0].potenMax
      };
      this.generales.push(poten);
    }
    if (datuak_potenciometros[0].poten2Visible) {
      var poten = {
        id: -2,
        nombre: this.translate.instant('potenAvances'),
        seleccionado: false,
        seleccionadoLeyenda: true,
        estaEnGrafico: -1,
        cssSwitch: "",
        color: "",
        unidad: "%",
        valores: [],
        valoresMomentaneo: [],
        fechas: [],
        max: datuak_potenciometros[0].potenMax2
      };
      this.generales.push(poten);
    }

    this.ejes = [];

    datuak_ejes.forEach(function (row, indice, array) {
      if (row.motor) { var unidadAvan = "r.p.m"; var nombreAvan = this.translate.instant('velocidad'); }
      else { var unidadAvan = "mm/min"; var nombreAvan = this.translate.instant('avance'); }

      var confAvance = {min: 0, max: 100}
      var confTemperatura = {min: 0, max: 100}
      var confConsumo = {min: 0, max: 100}
      try {
        var auxAvance = row.configuracion.filter(f => f.idTipo == 1)[0];
        var auxTemperatura = row.configuracion.filter(f => f.idTipo == 3)[0];
        var auxConsumo = row.configuracion.filter(f => f.idTipo == 2)[0];
        confAvance = {min: auxAvance.minimo, max: auxAvance.maximo}
        confTemperatura = {min: auxTemperatura.minimo, max: auxTemperatura.maximo}
        confConsumo = {min: auxConsumo.minimo, max: auxConsumo.maximo}
      } catch {console.log("error al cargar la configuracion")}

      var eje = {
        id: row.id,
        nombre: row.nombre,
        campos: [
          { valor: "avance", valores: [], valoresMomentaneo: [], fechas: [], nombre: nombreAvan, idEje: row.id, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: unidadAvan, minimo: confAvance.min, maximo: confAvance.max },
          { valor: "temperatura", valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('temperatura'), idEje: row.id, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "º", minimo: confTemperatura.min, maximo: confTemperatura.max },
          { valor: "consumo", valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('consumo'), idEje: row.id, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "kW", minimo: confConsumo.min, maximo: confConsumo.max },
        ]
      };
      this.ejes.push(eje);
    }, this);

    this.seleccionarCamposMecanizado();

  }

  seleccionarCamposMecanizado() {

    this.numeroCamposSeleccionados = 0;

    this.generales.forEach(function (row) {
      // if (this.numeroCamposSeleccionados < 5) {
      if (this.numeroCamposSeleccionados < 4) {
        row.seleccionado = true;
        row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
        row.color = this.colores[this.numeroCamposSeleccionados].color;
        this.colores[this.numeroCamposSeleccionados].enUso = true;
        this.numeroCamposSeleccionados++;
      }
    }, this);


    this.ejes.forEach(function (eje) {
      eje.campos.forEach(function (row) {
        // if (this.numeroCamposSeleccionados < 5) {
        if (this.numeroCamposSeleccionados < 4) {
          row.seleccionado = true;
          row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
          row.color = this.colores[this.numeroCamposSeleccionados].color;
          this.colores[this.numeroCamposSeleccionados].enUso = true;
          this.numeroCamposSeleccionados++;
        }
      }, this);
    }, this);

    this.cargarDatosGraficoMecanizado();

  }

  cargarDatosGraficoMecanizado() {

    this.resumenMecanizadoCargado = false;
    this.maximaPrecisionMecanizadoCargado = false;
    this.cargarDatosGraficoMecanizadoResumen();

  }

  cargarDatosGraficoMecanizadoResumen() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    this.historicoDatosService.Get_datos_ejes_maquina_resumen(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha2);
      }, this);

      this.datuak = datuak;

      this.ejes.forEach(function (row) {
        if (datuak.length > 0) {
          var idEje = row.id;

          row.campos[0].fechas = datuak.map(a => a['fecha_date']);
          row.campos[1].fechas = datuak.map(a => a['fecha_date']);
          row.campos[2].fechas = datuak.map(a => a['fecha_date']);

          var i;
          for (i = 1; i <= 12; i++) {
            if (datuak[0][i + '_idEje'] == idEje) {
              row.campos[0].valores = datuak.map(a => a[i + '_avanceRPM']);
              row.campos[1].valores = datuak.map(a => a[i + '_temperatura']);
              row.campos[2].valores = datuak.map(a => a[i + '_consumo']);
            }
          }

        }
      }, this);

      if (this.generales.length == 2) {
        this.generales[0].valores = datuak.map(a => a.potenciometro);
        this.generales[1].valores = datuak.map(a => a.potenciometro2);
        this.generales[0].fechas = datuak.map(a => a['fecha_date']);
        this.generales[1].fechas = datuak.map(a => a['fecha_date']);
      } else if (this.generales.length == 1) {
        this.generales[0].valores = datuak.map(a => a.potenciometro);
        this.generales[0].fechas = datuak.map(a => a['fecha_date']);
      }

      this.generales.forEach(function (row) {
        row.valoresMomentaneo = JSON.parse(JSON.stringify(row.valores));
      }, this);

      this.ejes.forEach(function (row) {
        row.campos.forEach(function (campo) {
          campo.valoresMomentaneo = JSON.parse(JSON.stringify(campo.valores));
        }, this);
      }, this);

      if (datuak.length > 0) {
        this.resumenMecanizadoCargado = true;
        this.mostrarDatosEnGraficoMecanizado();
        this.cargarDatosGraficoMecanizadoMaximaPrecision(false);
      } else {
        this.cargarDatosGraficoMecanizadoMaximaPrecision(true);
      }

    });

  }

  cargarDatosGraficoMecanizadoMaximaPrecision(mostrarDatosEnGrafico) {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //var datosInforme = this.historicoDatosService.Get_datos_ejes_maquina(this.selectedMaquina.id, fechaInicio, fechaFin);

    var datosInforme = this.historicoDatosService.Get_datos_ejes_maquina_filtro(this.consultaFiltroCompleto, fechaInicio);

    datosInforme.subscribe((result) => {

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha2);
      }, this);

      this.datuak = datuak;

      this.ejes.forEach(function (row) {
        if (datuak.length > 0) {
          var idEje = row.id;

          row.campos[0].fechas = datuak.map(a => a['fecha_date']);
          row.campos[1].fechas = datuak.map(a => a['fecha_date']);
          row.campos[2].fechas = datuak.map(a => a['fecha_date']);

          var i;
          for (i = 1; i <= 12; i++) {
            if (datuak[0][i + '_idEje'] == idEje) {
              row.campos[0].valores = datuak.map(a => a[i + '_avanceRPM']);
              row.campos[1].valores = datuak.map(a => a[i + '_temperatura']);
              row.campos[2].valores = datuak.map(a => a[i + '_consumo']);
            }
          }

        }
      }, this);

      if (this.generales.length == 2) {
        this.generales[0].valores = datuak.map(a => a.potenciometro);
        this.generales[1].valores = datuak.map(a => a.potenciometro2);
        this.generales[0].fechas = datuak.map(a => a['fecha_date']);
        this.generales[1].fechas = datuak.map(a => a['fecha_date']);
      } else if (this.generales.length == 1) {
        this.generales[0].valores = datuak.map(a => a.potenciometro);
        this.generales[0].fechas = datuak.map(a => a['fecha_date']);
      }

      if (datuak.length > 0) {
        this.maximaPrecisionMecanizadoCargado = true;
        if (mostrarDatosEnGrafico) this.mostrarDatosEnGraficoMecanizado();
      } else {
        this.loadingGrafico5Ejes = false;
      }

    });

  }

  mostrarDatosEnGraficoMecanizado() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    //ACTUALIZAMOS LOS DATOS QUE SE ENSEÑARAN EN TOOLTIP Y PANEL
    if (this.maximaPrecisionMecanizadoCargado) {
      this.generales.forEach(function (row) {
        row.valoresMomentaneo = JSON.parse(JSON.stringify(row.valores));
      }, this);

      this.ejes.forEach(function (row) {
        row.campos.forEach(function (campo) {
          campo.valoresMomentaneo = JSON.parse(JSON.stringify(campo.valores));
        }, this);
      }, this);
    }

    var camposSeleccionados = [];

    this.generales.forEach(function (campo) {
      if (campo.seleccionado) camposSeleccionados.push(campo);
    }, this);

    this.ejes.forEach(function (eje) {
      eje.campos.forEach(function (campo) {
        if (campo.seleccionado) camposSeleccionados.push(campo);
      }, this);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }

  cargarExtrusoraCampos() {

    this.loadingGrafico5Ejes = true;

    var r1, r2: boolean = false;

    //CONSEGUIR LOS GENERALES DE LA EXTRUSORA
    this.historicoDatosService.Get_Generales_Extrusora(this.selectedMaquina.id).subscribe((result) => {

      var res: any = result[0];

      this.generales = [];

      if (res.kgH_activo) {
        var campo = { id: 1, nombre: this.translate.instant('kgh'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.kgH_min, max: res.kgH_max }
        this.generales.push(campo);
      }
      if (res.anchoLamina_activo) {
        var campo = { id: 2, nombre: this.translate.instant('anchoLamina'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.anchoLamina_min, max: res.anchoLamina_max }
        this.generales.push(campo);
      }
      if (res.infoControlEspesores_activo) {
        var campo = { id: 3, nombre: this.translate.instant('infoControlEspesores'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.infoControlEspesores_min, max: res.infoControlEspesores_max }
        this.generales.push(campo);
      }
      if (res.rendimientoSopladores_activo) {
        var campo = { id: 4, nombre: this.translate.instant('rendimientoSopladores'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.rendimientoSopladores_min, max: res.rendimientoSopladores_max }
        this.generales.push(campo);
      }
      if (res.tempAnilloEntrada_activo) {
        var campo = { id: 5, nombre: this.translate.instant('tempAnilloEntrada'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.tempAnilloEntrada_min, max: res.tempAnilloEntrada_max }
        this.generales.push(campo);
      }
      if (res.tempIBCDeEntrada_activo) {
        var campo = { id: 6, nombre: this.translate.instant('tempIBCEntrada'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.tempIBCDeEntrada_min, max: res.tempIBCDeEntrada_max }
        this.generales.push(campo);
      }
      if (res.tempRodilloCalandra_activo) {
        var campo = { id: 7, nombre: this.translate.instant('TempRodilloCalandra'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.tempRodilloCalandra_min, max: res.tempRodilloCalandra_max }
        this.generales.push(campo);
      }
      if (res.tempRodilloHalador_activo) {
        var campo = { id: 8, nombre: this.translate.instant('TempRodilloHalador'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.tempRodilloHalador_min, max: res.tempRodilloHalador_max }
        this.generales.push(campo);
      }
      if (res.kwhTotales_activo) {
        var campo = { id: 9, nombre: this.translate.instant('kwhTotales'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.kwhTotales_min, max: res.kwhTotales_max }
        this.generales.push(campo);
      }
      if (res.potenciaInstantanea_activo) {
        var campo = { id: 10, nombre: this.translate.instant('potenciaInstantanea'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.potenciaInstantanea_min, max: res.potenciaInstantanea_max }
        this.generales.push(campo);
      }

      r1 = true;
      if (r1 && r2) this.seleccionarCamposExtrusora();

    });

    //CONSEGUIR LAS CAPAS DE LA EXTRUSORA
    this.historicoDatosService.Get_Campos_Extrusora(this.selectedMaquina.id).subscribe((result) => {

      var datuak_capas: any = result;

      this.ejes = [];

      datuak_capas.forEach(function (row) {

        var campos = [];

        if (row.presion_activo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('presionFiltros'), idEje: row.id, idCampo: 1, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: row.presion_min, maximo: row.presion_max });
        if (row.rendimientoTasaDeTransporte_activo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('rendimientoTasaDeTransporte'), idEje: row.id, idCampo: 2, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: row.rendimientoTasaDeTransporte_min, maximo: row.rendimientoTasaDeTransporte_max });
        if (row.revolucionesMotor_activo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('revolucionesMotor'), idEje: row.id, idCampo: 3, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: row.revolucionesMotor_min, maximo: row.revolucionesMotor_max });
        if (row.cargaMotor_activo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('cargaMotor'), idEje: row.id, idCampo: 4, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: row.cargaMotor_min, maximo: row.cargaMotor_max });

        var capa = {
          id: row.id,
          nombre: row.nombre,
          campos: campos
        };

        this.ejes.push(capa);

      }, this);

      r2 = true;
      if (r1 && r2) this.seleccionarCamposExtrusora();

    });

  }

  seleccionarCamposExtrusora() {

    this.numeroCamposSeleccionados = 0;

    this.generales.forEach(function (row) {
      // if (this.numeroCamposSeleccionados < 5) {
      if (this.numeroCamposSeleccionados < 5) {
        row.seleccionado = true;
        row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
        row.color = this.colores[this.numeroCamposSeleccionados].color;
        this.colores[this.numeroCamposSeleccionados].enUso = true;
        this.numeroCamposSeleccionados++;
      }
    }, this);


    this.ejes.forEach(function (eje) {
      eje.campos.forEach(function (row) {
        // if (this.numeroCamposSeleccionados < 5) {
        if (this.numeroCamposSeleccionados < 4) {
          row.seleccionado = true;
          row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
          row.color = this.colores[this.numeroCamposSeleccionados].color;
          this.colores[this.numeroCamposSeleccionados].enUso = true;
          this.numeroCamposSeleccionados++;
        }
      }, this);
    }, this);

    this.cargarDatosGraficoExtrusora();

  }

  cargarDatosGraficoExtrusora() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //this.historicoDatosService.Get_Historico_Extrusora(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {
    this.historicoDatosService.Get_Historico_Extrusora_filtro(this.consultaFiltroCompleto).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
      }, this);

      this.datuak = datuak;

      //CALCULAMOS LOS DATOS DE LOS GENERALES
      this.generales.forEach(function (row) {
        if (datuak.length > 0) {
          var idCampo = row.id;

          row.fechas = datuak.map(a => a['fecha_date']);

          if (idCampo == 1) {
            row.valores = datuak.map(a => a['kgH']);
            row.valoresMomentaneo = datuak.map(a => a['kgH']);
          }
          if (idCampo == 2) {
            row.valores = datuak.map(a => a['anchoLamina']);
            row.valoresMomentaneo = datuak.map(a => a['anchoLamina']);
          }
          if (idCampo == 3) {
            row.valores = datuak.map(a => a['infoControlEspesores']);
            row.valoresMomentaneo = datuak.map(a => a['infoControlEspesores']);
          }
          if (idCampo == 4) {
            row.valores = datuak.map(a => a['rendimientoSopladores']);
            row.valoresMomentaneo = datuak.map(a => a['rendimientoSopladores']);
          }
          if (idCampo == 5) {
            row.valores = datuak.map(a => a['tempAnilloEntrada']);
            row.valoresMomentaneo = datuak.map(a => a['tempAnilloEntrada']);
          }
          if (idCampo == 6) {
            row.valores = datuak.map(a => a['tempIBCDeEntrada']);
            row.valoresMomentaneo = datuak.map(a => a['tempIBCDeEntrada']);
          }
          if (idCampo == 7) {
            row.valores = datuak.map(a => a['tempRodilloCalandra']);
            row.valoresMomentaneo = datuak.map(a => a['tempRodilloCalandra']);
          }
          if (idCampo == 8) {
            row.valores = datuak.map(a => a['tempRodilloHalador']);
            row.valoresMomentaneo = datuak.map(a => a['tempRodilloHalador']);
          }
          if (idCampo == 9) {
            row.valores = datuak.map(a => a['kwhTotales']);
            row.valoresMomentaneo = datuak.map(a => a['kwhTotales']);
          }
          if (idCampo == 10) {
            row.valores = datuak.map(a => a['potenciaInstantanea']);
            row.valoresMomentaneo = datuak.map(a => a['potenciaInstantanea']);
          }

        }
      }, this);

      //CALCULAMOS LOS DATOS DE LAS CAPAS
      this.ejes.forEach(function (capa) {
        capa.campos.forEach(function (row) {
          if (datuak.length > 0) {

            var idCapa = capa.id;
            var idCampo = row.idCampo;

            row.fechas = datuak.map(a => a['fecha_date']);

            for (var i = 1; i <= 10; i++) {
              if (datuak[0][i + '_idCapa'] == idCapa) {
                if (idCampo == 1) {
                  row.valores = datuak.map(a => a[i + '_presion']);
                  row.valoresMomentaneo = datuak.map(a => a[i + '_presion']);
                }
                if (idCampo == 2) {
                  row.valores = datuak.map(a => a[i + '_rendimientoTasaDeTransporte']);
                  row.valoresMomentaneo = datuak.map(a => a[i + '_rendimientoTasaDeTransporte']);
                }
                if (idCampo == 3) {
                  row.valores = datuak.map(a => a[i + '_revolucionesMotor']);
                  row.valoresMomentaneo = datuak.map(a => a[i + '_revolucionesMotor']);
                }
                if (idCampo == 4) {
                  row.valores = datuak.map(a => a[i + '_cargaMotor']);
                  row.valoresMomentaneo = datuak.map(a => a[i + '_cargaMotor']);
                }

              }
            }

          }
        }, this);
      }, this);

      this.mostrarDatosEnGraficoExtrusora();

    });

  }

  mostrarDatosEnGraficoExtrusora() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    var camposSeleccionados = [];

    this.generales.forEach(function (campo) {
      if (campo.seleccionado) camposSeleccionados.push(campo);
    }, this);

    this.ejes.forEach(function (eje) {
      eje.campos.forEach(function (campo) {
        if (campo.seleccionado) camposSeleccionados.push(campo);
      }, this);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }

  cargarImpresoraPlasticoCampos() {

    this.loadingGrafico5Ejes = true;

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE LA IMPRESORA
    this.historicoDatosService.Get_Campos_ImpresoraPlastico(this.selectedMaquina.id).subscribe((result) => {

      var res: any = result[0];

      res.speed_min = 0;
      res.speed_max = 1000;

      this.generales = [];
      this.ejes = [];

      //NO ES OPCIONAL (DE MOMENTO)
      var campo = { id: 1, nombre: this.translate.instant('speed'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.speed_min, max: res.speed_max }
      this.generales.push(campo);

      if (res.desbobinadora_activo) {
        var campo = { id: 2, nombre: this.translate.instant('desbobinadora'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.desbobinadora_min , max: res.desbobinadora_max  }
        this.generales.push(campo);
      }
      if (res.tomaDeAlimentacion_activo) {
        var campo = { id: 3, nombre: this.translate.instant('tomaDeAlimentacion'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.tomaDeAlimentacion_min , max: res.tomaDeAlimentacion_max }
        this.generales.push(campo);
      }
      if (res.accionadorRodilloEnfriamiento_activo) {
        var campo = { id: 4, nombre: this.translate.instant('accionadorRodilloEnfriamiento'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.accionadorRodilloEnfriamiento_min , max: res.accionadorRodilloEnfriamiento_max  }
        this.generales.push(campo);
      }
      if (res.bobinadora_activo) {
        var campo = { id: 5, nombre: this.translate.instant('bobinadora'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.bobinadora_min , max: res.bobinadora_max }
        this.generales.push(campo);
      }
      if (res.secadorTempBCD_activo) {
        var campo = { id: 6, nombre: this.translate.instant('secadorTempBCD'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.secadorTempBCD_min , max: res.secadorTempBCD_max }
        this.generales.push(campo);
      }
      if (res.secadorTunelTempBCD_activo) {
        var campo = { id: 7, nombre: this.translate.instant('secadorTunelTempBCD'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.secadorTunelTempBCD_min , max: res.secadorTunelTempBCD_max  }
        this.generales.push(campo);
      }
      if (res.kwhTotales_activo) {
        var campo = { id: 8, nombre: this.translate.instant('kwhTotales'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.kwhTotales_min , max: res.kwhTotales_max }
        this.generales.push(campo);
      }
      if (res.potenciaInstantanea_activo) {
        var campo = { id: 9, nombre: this.translate.instant('potenciaInstantanea'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.potenciaInstantanea_min , max: res.potenciaInstantanea_max }
        this.generales.push(campo);
      }

      this.seleccionarCamposImpresorasPlastico();

    });

  }

  seleccionarCamposImpresorasPlastico() {

    this.numeroCamposSeleccionados = 0;

    this.generales.forEach(function (row) {
      // if (this.numeroCamposSeleccionados < 5) {
      if (this.numeroCamposSeleccionados < 4) {
        row.seleccionado = true;
        row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
        row.color = this.colores[this.numeroCamposSeleccionados].color;
        this.colores[this.numeroCamposSeleccionados].enUso = true;
        this.numeroCamposSeleccionados++;
      }
    }, this);

    this.cargarDatosGraficoImpresorasPlastico();

  }

  cargarDatosGraficoImpresorasPlastico() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //this.historicoDatosService.Get_Historico_ImpresorasPlastico(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {
    this.historicoDatosService.Get_Historico_ImpresorasPlastico_filtro(this.consultaFiltroCompleto).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
      }, this);

      this.datuak = datuak;

      this.generales.forEach(function (row) {
        if (datuak.length > 0) {
          var idCampo = row.id;

          row.fechas = datuak.map(a => a['fecha_date']);

          if (idCampo == 1) {
            row.valores = datuak.map(a => a['speed']);
            row.valoresMomentaneo = datuak.map(a => a['speed']);
          }
          if (idCampo == 2) {
            row.valores = datuak.map(a => a['desbobinadora']);
            row.valoresMomentaneo = datuak.map(a => a['desbobinadora']);
          }
          if (idCampo == 3) {
            row.valores = datuak.map(a => a['tomaDeAlimentacion']);
            row.valoresMomentaneo = datuak.map(a => a['tomaDeAlimentacion']);
          }
          if (idCampo == 4) {
            row.valores = datuak.map(a => a['accionadorRodilloEnfriamiento']);
            row.valoresMomentaneo = datuak.map(a => a['accionadorRodilloEnfriamiento']);
          }
          if (idCampo == 5) {
            row.valores = datuak.map(a => a['bobinadora']);
            row.valoresMomentaneo = datuak.map(a => a['bobinadora']);
          }
          if (idCampo == 6) {
            row.valores = datuak.map(a => a['secadorTempBCD']);
            row.valoresMomentaneo = datuak.map(a => a['secadorTempBCD']);
          }
          if (idCampo == 7) {
            row.valores = datuak.map(a => a['secadorTunelTempBCD']);
            row.valoresMomentaneo = datuak.map(a => a['secadorTunelTempBCD']);
          }
          if (idCampo == 8) {
            row.valores = datuak.map(a => a['kwhTotales']);
            row.valoresMomentaneo = datuak.map(a => a['kwhTotales']);
          }
          if (idCampo == 9) {
            row.valores = datuak.map(a => a['potenciaInstantanea']);
            row.valoresMomentaneo = datuak.map(a => a['potenciaInstantanea']);
          }

        }
      }, this);

      this.mostrarDatosEnGraficoImpresoraPlastico();

    });

  }

  mostrarDatosEnGraficoImpresoraPlastico() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    var camposSeleccionados = [];

    this.generales.forEach(function (campo) {
      if (campo.seleccionado) camposSeleccionados.push(campo);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }

  cargarInyectoraCampos() {

    this.loadingGrafico5Ejes = true;

    //CONSEGUIR LOS CAMPOS DE LA INYECTORA
    this.historicoDatosService.Get_Campos_Inyectora(this.selectedMaquina.id).subscribe((result) => {

      var datuak_campos: any = result;

      this.generales = [];
      this.ejes = [];

      if (datuak_campos.length > 0) {

        var row = datuak_campos[0];
        var capa;
        var campos;
        var minimo = 0;
        var maximo = 100;

        //TIEMPOS (idEje=1)
        campos = [];
        if (row.tiempoInyeccion) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('tiempoInyeccion'), idEje: 1, idCampo: 1, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tiempoPresionRemanente) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('tiempoPresionRemanente'), idEje: 1, idCampo: 2, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tiempoEnfriamiento) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('tiempoEnfriamiento'), idEje: 1, idCampo: 3, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tiempoDosificacionPlastificacion) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('tiempoDosificPlastificacion'), idEje: 1, idCampo: 4, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tiempoCiclo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('tiempoCiclo'), idEje: 1, idCampo: 5, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });

        capa = {
          id: 1,
          nombre: this.translate.instant('tiempos'),
          campos: campos
        }
        if (row.tiempoInyeccion || row.tiempoPresionRemanente || row.tiempoEnfriamiento || row.tiempoDosificacionPlastificacion || row.tiempoCiclo)
          this.ejes.push(capa);

        //PRESIONES (idEje=2)
        campos = [];
        if (row.presionInyeccionMax) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('presInyMax'), idEje: 2, idCampo: 1, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.presionRemanente) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('presRemanente'), idEje: 2, idCampo: 2, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.contrapresion) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('contrapresion'), idEje: 2, idCampo: 3, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.ptoConmutacion) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('ptoConmutacion'), idEje: 2, idCampo: 4, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });

        capa = {
          id: 2,
          nombre: this.translate.instant('presiones'),
          campos: campos
        }
        if (row.presionInyeccionMax || row.presionRemanente || row.contrapresion || row.ptoConmutacion)
          this.ejes.push(capa);

        //TEMPERATURAS (idEje=3)
        campos = [];
        if (row.tempHusillo_activo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('temperaturasHusillo'), idEje: 3, idCampo: 1, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tempCamaraCaliente_activo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('temperaturasCamaraCaliente'), idEje: 3, idCampo: 2, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tempPresionFija) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('TPFija'), idEje: 3, idCampo: 3, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.tempPresionMovil) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('TPMovil'), idEje: 3, idCampo: 4, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });

        capa = {
          id: 3,
          nombre: this.translate.instant('temperaturas'),
          campos: campos
        }
        if (row.tempHusillo_activo || row.tempCamaraCaliente_activo || row.tempPresionFija || row.tempPresionMovil)
          this.ejes.push(capa);

        //MUCELL (idEje=4)
        campos = [];
        campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('pesoInyeccionEspumado'), idEje: 4, idCampo: 1, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('porcenScf'), idEje: 4, idCampo: 2, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('dosificacionScf'), idEje: 4, idCampo: 3, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });

        capa = {
          id: 4,
          nombre: this.translate.instant('mucell'),
          campos: campos
        }
        this.ejes.push(capa);

        //OTROS (idEje=5)
        campos = [];
        if (row.paradaDosificado) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('paradaDosific'), idEje: 5, idCampo: 1, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.retrocesoHusillo) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('retrocesoHusillo'), idEje: 5, idCampo: 2, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.paradaMolde) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('paradaMolde'), idEje: 5, idCampo: 3, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });
        if (row.cojin) campos.push({ valores: [], valoresMomentaneo: [], fechas: [], nombre: this.translate.instant('cojin'), idEje: 5, idCampo: 4, nombreEje: row.nombre, seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", minimo: minimo, maximo: maximo });

        capa = {
          id: 5,
          nombre: this.translate.instant('otros'),
          campos: campos
        }
        if (row.paradaDosificado || row.retrocesoHusillo || row.paradaMolde || row.cojin)
          this.ejes.push(capa);

      }

      this.seleccionarCamposInyectora();

    });

  }

  seleccionarCamposInyectora() {

    this.numeroCamposSeleccionados = 0;

    this.ejes.forEach(function (eje) {
      eje.campos.forEach(function (row) {
        // if (this.numeroCamposSeleccionados < 5) {
        if (this.numeroCamposSeleccionados < 4) {
          row.seleccionado = true;
          row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
          row.color = this.colores[this.numeroCamposSeleccionados].color;
          this.colores[this.numeroCamposSeleccionados].enUso = true;
          this.numeroCamposSeleccionados++;
        }
      }, this);
    }, this);

    this.cargarDatosGraficoInyectora();

  }

  cargarDatosGraficoInyectora() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //this.historicoDatosService.Get_Historico_Inyectora(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {
    while(this.consultaFiltroCompleto.includes("dtr.fecha")){
      this.consultaFiltroCompleto = this.consultaFiltroCompleto.replace("dtr.fecha", "dtr.inicio")
    }
    this.historicoDatosService.Get_Historico_Inyectora_filtro(this.consultaFiltroCompleto).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
      }, this);

      this.datuak = datuak;

      //CALCULAMOS LOS DATOS DE LOS CAMPOS
      this.ejes.forEach(function (eje) {
        eje.campos.forEach(function (row) {
          if (datuak.length > 0) {

            var idEje = eje.id;
            var idCampo = row.idCampo;

            row.fechas = datuak.map(a => a['fecha_date']);

            //TIEMPOS
            if (idEje == 1) {
              if (idCampo == 1) {
                row.valores = datuak.map(a => a['tiempoInyeccion']);
                row.valoresMomentaneo = datuak.map(a => a['tiempoInyeccion']);
              }
              if (idCampo == 2) {
                row.valores = datuak.map(a => a['tiempoPresionRemanente']);
                row.valoresMomentaneo = datuak.map(a => a['tiempoPresionRemanente']);
              }
              if (idCampo == 3) {
                row.valores = datuak.map(a => a['tiempoEnfriamiento']);
                row.valoresMomentaneo = datuak.map(a => a['tiempoEnfriamiento']);
              }
              if (idCampo == 4) {
                row.valores = datuak.map(a => a['tiempoDosificacionPlastificacion']);
                row.valoresMomentaneo = datuak.map(a => a['tiempoDosificacionPlastificacion']);
              }
              if (idCampo == 5) {
                row.valores = datuak.map(a => a['tiempoCiclo']);
                row.valoresMomentaneo = datuak.map(a => a['tiempoCiclo']);
              }
            }

            //PRESIONES
            if (idEje == 2) {
              if (idCampo == 1) {
                row.valores = datuak.map(a => a['presionInyeccionMax']);
                row.valoresMomentaneo = datuak.map(a => a['presionInyeccionMax']);
              }
              if (idCampo == 2) {
                row.valores = datuak.map(a => a['presionRemanente']);
                row.valoresMomentaneo = datuak.map(a => a['presionRemanente']);
              }
              if (idCampo == 3) {
                row.valores = datuak.map(a => a['contrapresion']);
                row.valoresMomentaneo = datuak.map(a => a['contrapresion']);
              }
              if (idCampo == 4) {
                row.valores = datuak.map(a => a['ptoConmutacion']);
                row.valoresMomentaneo = datuak.map(a => a['ptoConmutacion']);
              }
            }

            //TEMPERATURAS
            if (idEje == 3) {
              if (idCampo == 1) {
                row.valores = datuak.map(a => a['temperaturaHusillo']);
                row.valoresMomentaneo = datuak.map(a => a['temperaturaHusillo']);
              }
              if (idCampo == 2) {
                row.valores = datuak.map(a => a['temperaturaCamaraCaliente']);
                row.valoresMomentaneo = datuak.map(a => a['temperaturaCamaraCaliente']);
              }
              if (idCampo == 3) {
                row.valores = datuak.map(a => a['tempPresionFija']);
                row.valoresMomentaneo = datuak.map(a => a['tempPresionFija']);
              }
              if (idCampo == 4) {
                row.valores = datuak.map(a => a['tempPresionMovil']);
                row.valoresMomentaneo = datuak.map(a => a['tempPresionMovil']);
              }
            }

            //MUCELL
            if (idEje == 4) {
              if (idCampo == 1) {
                row.valores = datuak.map(a => a['pesoInyeccionEspumado']);
                row.valoresMomentaneo = datuak.map(a => a['pesoInyeccionEspumado']);
              }
              if (idCampo == 2) {
                row.valores = datuak.map(a => a['porcenScf']);
                row.valoresMomentaneo = datuak.map(a => a['porcenScf']);
              }
              if (idCampo == 3) {
                row.valores = datuak.map(a => a['dosificacionScf']);
                row.valoresMomentaneo = datuak.map(a => a['dosificacionScf']);
              }
            }

            //OTROS
            if (idEje == 5) {
              if (idCampo == 1) {
                row.valores = datuak.map(a => a['paradaDosificado']);
                row.valoresMomentaneo = datuak.map(a => a['paradaDosificado']);
              }
              if (idCampo == 2) {
                row.valores = datuak.map(a => a['retrocesoHusillo']);
                row.valoresMomentaneo = datuak.map(a => a['retrocesoHusillo']);
              }
              if (idCampo == 3) {
                row.valores = datuak.map(a => a['paradaMolde']);
                row.valoresMomentaneo = datuak.map(a => a['paradaMolde']);
              }
              if (idCampo == 4) {
                row.valores = datuak.map(a => a['cojin']);
                row.valoresMomentaneo = datuak.map(a => a['cojin']);
              }
            }

          }
        }, this);
      }, this);

      this.mostrarDatosEnGraficoInyectora();

    });

  }

  mostrarDatosEnGraficoInyectora() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    var camposSeleccionados = [];

    this.ejes.forEach(function (eje) {
      eje.campos.forEach(function (campo) {
        if (campo.seleccionado) camposSeleccionados.push(campo);
      }, this);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }

  cargarHornoCampos() {

    this.loadingGrafico5Ejes = true;

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE EL HORNO
    this.historicoDatosService.Get_Campos_Horno(this.selectedMaquina.id).subscribe((result) => {

      var res: any = result[0];

      this.generales = [];
      this.ejes = [];

      if (res.energia_activo) {
        var campo = { id: 1, nombre: this.translate.instant('energia'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.energia_min , max: res.energia_max }
        this.generales.push(campo);
      }
      if (res.potencia_activo) {
        var campo = { id: 2, nombre: this.translate.instant('potencia'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "KW", valores: [], valoresMomentaneo: [], fechas: [], min: res.potencia_min , max: res.potencia_max }
        this.generales.push(campo);
      }
      if (res.intensidad_activo) {
        var campo = { id: 3, nombre: this.translate.instant('intensidad'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "A", valores: [], valoresMomentaneo: [], fechas: [], min: res.intensidad_min , max: res.intensidad_max }
        this.generales.push(campo);
      }
      if (res.voltaje_activo) {
        var campo = { id: 4, nombre: this.translate.instant('voltaje'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "V", valores: [], valoresMomentaneo: [], fechas: [], min: res.voltaje_min , max: res.voltaje_max }
        this.generales.push(campo);
      }
      if (res.frecuencia_activo) {
        var campo = { id: 5, nombre: this.translate.instant('frecuencia'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "KHz", valores: [], valoresMomentaneo: [], fechas: [], min: res.frecuencia_min , max: res.frecuencia_max }
        this.generales.push(campo);
      }
      if (res.caudal_activo) {
        var campo = { id: 6, nombre: this.translate.instant('caudal'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "l/m", valores: [], valoresMomentaneo: [], fechas: [], min: res.caudal_min , max: res.caudal_max }
        this.generales.push(campo);
      }
      if (res.temperaturaPieza_activo) {
        var campo = { id: 7, nombre: this.translate.instant('temperaturaPieza'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "º", valores: [], valoresMomentaneo: [], fechas: [], min: res.temperaturaPieza_min , max: res.temperaturaPieza_max }
        this.generales.push(campo);
      }
      if (res.presionBombaDucha_activo) {
        var campo = { id: 8, nombre: this.translate.instant('presionBombaDucha'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "bar", valores: [], valoresMomentaneo: [], fechas: [], min: res.presionBombaDucha_min , max: res.presionBombaDucha_max }
        this.generales.push(campo);
      }
      if (res.temperaturaPolimero_activo) {
        var campo = { id: 9, nombre: this.translate.instant('temperaturaPolimero'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "º", valores: [], valoresMomentaneo: [], fechas: [], min: res.temperaturaPolimero_min , max: res.temperaturaPolimero_max }
        this.generales.push(campo);
      }
      if (res.porcenPolimero_activo) {
        var campo = { id: 10, nombre: this.translate.instant('porcenPolimero'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "%", valores: [], valoresMomentaneo: [], fechas: [], min: res.porcenPolimero_min , max: res.porcenPolimero_max }
        this.generales.push(campo);
      }

      this.seleccionarCamposHornos();

    });

  }

  seleccionarCamposHornos() {

    this.numeroCamposSeleccionados = 0;

    this.generales.forEach(function (row) {
      // if (this.numeroCamposSeleccionados < 5) {
      if (this.numeroCamposSeleccionados < 4) {
        row.seleccionado = true;
        row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
        row.color = this.colores[this.numeroCamposSeleccionados].color;
        this.colores[this.numeroCamposSeleccionados].enUso = true;
        this.numeroCamposSeleccionados++;
      }
    }, this);

    this.cargarDatosGraficoHornos();

  }

  cargarDatosGraficoHornos() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //this.historicoDatosService.Get_Historico_Horno(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {
    this.historicoDatosService.Get_Historico_Horno_filtro(this.consultaFiltroCompleto).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
      }, this);

      this.datuak = datuak;

      this.generales.forEach(function (row) {
        if (datuak.length > 0) {
          var idCampo = row.id;

          row.fechas = datuak.map(a => a['fecha_date']);

          if (idCampo == 1) {
            row.valores = datuak.map(a => a['energia']);
            row.valoresMomentaneo = datuak.map(a => a['energia']);
          }
          if (idCampo == 2) {
            row.valores = datuak.map(a => a['potencia']);
            row.valoresMomentaneo = datuak.map(a => a['potencia']);
          }
          if (idCampo == 3) {
            row.valores = datuak.map(a => a['intensidad']);
            row.valoresMomentaneo = datuak.map(a => a['intensidad']);
          }
          if (idCampo == 4) {
            row.valores = datuak.map(a => a['voltaje']);
            row.valoresMomentaneo = datuak.map(a => a['voltaje']);
          }
          if (idCampo == 5) {
            row.valores = datuak.map(a => a['frecuencia']);
            row.valoresMomentaneo = datuak.map(a => a['frecuencia']);
          }
          if (idCampo == 6) {
            row.valores = datuak.map(a => a['caudal']);
            row.valoresMomentaneo = datuak.map(a => a['caudal']);
          }
          if (idCampo == 7) {
            row.valores = datuak.map(a => a['temperaturaPieza']);
            row.valoresMomentaneo = datuak.map(a => a['temperaturaPieza']);
          }
          if (idCampo == 8) {
            row.valores = datuak.map(a => a['presionBombaDucha']);
            row.valoresMomentaneo = datuak.map(a => a['presionBombaDucha']);
          }
          if (idCampo == 9) {
            row.valores = datuak.map(a => a['temperaturaPolimero']);
            row.valoresMomentaneo = datuak.map(a => a['temperaturaPolimero']);
          }
          if (idCampo == 10) {
            row.valores = datuak.map(a => a['porcenPolimero']);
            row.valoresMomentaneo = datuak.map(a => a['porcenPolimero']);
          }

        }
      }, this);


      this.mostrarDatosEnGraficoHorno();

    });

  }

  mostrarDatosEnGraficoHorno() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    var camposSeleccionados = [];

    this.generales.forEach(function (campo) {
      if (campo.seleccionado) camposSeleccionados.push(campo);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }


  cargarHornosTempleCampos() {

    this.loadingGrafico5Ejes = true;

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE EL HORNO TEMPLE
    this.historicoDatosService.Get_Campos_HornosTemple(this.selectedMaquina.id).subscribe((result) => {
      var res: any = result[0];

      this.generales = [];
      this.ejes = [];
      res.minimo = 0;
      res.maximo = 100;

      if (res?.temperaturaProgramada) {
        var campo = { id: 1, nombre: this.translate.instant('temperaturaProgramada'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "º", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
        this.generales.push(campo);
      }
      if (res?.temperaturaActual) {
        var campo = { id: 2, nombre: this.translate.instant('temperaturaActual'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "º", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
        this.generales.push(campo);
      }
      if (res?.temperaturaActual) {
        var campo = { id: 3, nombre: this.translate.instant('temperaturaInterior'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "º", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
        this.generales.push(campo);
      }
      if (res?.consumo) {
        var campo = { id: 4, nombre: this.translate.instant('consumo'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "kWh", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
        this.generales.push(campo);
      }

      // TAULA HUTSA DAGO, BITARTEAN DENAK HUTSAK KARGATUKO DIRA
      var campo = { id: 1, nombre: this.translate.instant('temperaturaProgramada'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
      this.generales.push(campo);
      campo = { id: 2, nombre: this.translate.instant('temperaturaActual'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
      this.generales.push(campo);
      campo = { id: 3, nombre: this.translate.instant('temperaturaInterior'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
      this.generales.push(campo);
      campo = { id: 4, nombre: this.translate.instant('consumo'), seleccionado: false, seleccionadoLeyenda: true, estaEnGrafico: -1, cssSwitch: "", color: "", unidad: "", valores: [], valoresMomentaneo: [], fechas: [], min: res.minimo, max: res.maximo }
      this.generales.push(campo);

      this.seleccionarCamposHornosTemple();
    });
  }

  seleccionarCamposHornosTemple() {

    this.numeroCamposSeleccionados = 0;

    this.generales.forEach(function (row) {
      // if (this.numeroCamposSeleccionados < 5) {
      if (this.numeroCamposSeleccionados < 4) {
        row.seleccionado = true;
        row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
        row.color = this.colores[this.numeroCamposSeleccionados].color;
        this.colores[this.numeroCamposSeleccionados].enUso = true;
        this.numeroCamposSeleccionados++;
      }
    }, this);

    this.cargarDatosGraficoHornosTemple();

  }

  cargarDatosGraficoHornosTemple() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //this.historicoDatosService.Get_Historico_Horno(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {
    this.historicoDatosService.Get_Historico_HornosTemple_filtro(this.consultaFiltroCompleto).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
      }, this);

      this.datuak = datuak;

      this.generales.forEach(function (row) {
        if (datuak.length > 0) {
          var idCampo = row.id;

          row.fechas = datuak.map(a => a['fecha_date']);

          if (idCampo == 1) {
            row.valores = datuak.map(a => a['temperaturaProgramada']);
            row.valoresMomentaneo = datuak.map(a => a['temperaturaProgramada']);
          }
          if (idCampo == 2) {
            row.valores = datuak.map(a => a['temperaturaActual']);
            row.valoresMomentaneo = datuak.map(a => a['temperaturaActual']);
          }
          if (idCampo == 3) {
            row.valores = datuak.map(a => a['temperaturaInterior']);
            row.valoresMomentaneo = datuak.map(a => a['temperaturaInterior']);
          }
          if (idCampo == 4) {
            row.valores = datuak.map(a => a['consumo']);
            row.valoresMomentaneo = datuak.map(a => a['consumo']);
          }

        }
      }, this);

      this.mostrarDatosEnGraficoHornosTemple();

    });

  }

  mostrarDatosEnGraficoHornosTemple() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    var camposSeleccionados = [];

    this.generales.forEach(function (campo) {
      if (campo.seleccionado) camposSeleccionados.push(campo);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }

  cargarInstalacionCampos() {

    this.loadingGrafico5Ejes = true;

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE LA INSTALACION
    this.historicoDatosService.Get_Campos_Instalacion(this.selectedMaquina.id).subscribe((result) => {

      var res: any = result;

      this.generales = [];
      this.ejes = [];

      res.forEach(function (campo) {

        var poten = {
          id: campo.id,
          nombre: campo.nombre,
          seleccionado: false,
          seleccionadoLeyenda: true,
          estaEnGrafico: -1,
          cssSwitch: "",
          color: "",
          unidad: campo.unidad,
          valores: [],
          valoresMomentaneo: [],
          fechas: [],
          min: campo.minimo,
          max: campo.maximo
        };
        this.generales.push(poten);

      }, this);

      this.seleccionarCamposInstalacion();

    });

  }

  seleccionarCamposInstalacion() {

    this.numeroCamposSeleccionados = 0;

    this.generales.forEach(function (row) {
      // if (this.numeroCamposSeleccionados < 5) {
      if (this.numeroCamposSeleccionados < 4) {
        row.seleccionado = true;
        row.cssSwitch = this.colores[this.numeroCamposSeleccionados].css;
        row.color = this.colores[this.numeroCamposSeleccionados].color;
        this.colores[this.numeroCamposSeleccionados].enUso = true;
        this.numeroCamposSeleccionados++;
      }
    }, this);

    this.cargarDatosGraficoInstalacion();

  }

  cargarDatosGraficoInstalacion() {

    var fechaInicio = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio);
    var fechaFin = this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin);
    if (this.diaSemana) fechaFin = fechaInicio //Si estan seleccionados los datos de un dia le pasamos misma fechaIni que fechaFin, para que cargue los datos de ese dia

    //this.historicoDatosService.Get_Historico_Instalaciones(this.selectedMaquina.id, fechaInicio, fechaFin).subscribe((result) => {
    var consulta = this.consultaFiltroCompleto.replace('dtr.idMaquina', 'hi.idInstalacion');
    consulta = consulta.replace('dtr.fecha', 'hi.fecha');
    consulta = consulta.replace('dtr.fecha', 'hi.fecha');
    this.historicoDatosService.Get_Historico_Instalaciones_filtro(consulta).subscribe((result) => {

      this.fechaInicioZoom = this.fechaInicio;
      this.fechaFinZoom = this.fechaFin;

      var datuak: any = result;

      //CALCULAMOS LAS FECHAS
      datuak.forEach(function (valor) {
        valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
      }, this);

      this.datuak = datuak;

      this.generales.forEach(function (row) {
        if (datuak.length > 0) {
          var idCampo = row.id;

          row.fechas = datuak.map(a => a['fecha_date']);

          var i;
          for (i = 1; i <= 12; i++) {
            if (datuak[0]['idCampo' + i] == idCampo) {
              row.valores = datuak.map(a => a['valor' + i]);
              row.valoresMomentaneo = datuak.map(a => a['valor' + i]);
            }
          }

        }
      }, this);

      this.mostrarDatosEnGraficoInstalacion();

    });

  }

  mostrarDatosEnGraficoInstalacion() {

    //GUARDAMOS LAS FECHAS EN EL ARRAY DE FECHAS (PARA EL TOOLTIP DEL GRAFICO)
    this.arrayFechas = [];
    this.datuak.forEach(function (dat) {
      this.arrayFechas.push(dat.fecha_date);
    }, this);

    var camposSeleccionados = [];

    this.generales.forEach(function (campo) {
      if (campo.seleccionado) camposSeleccionados.push(campo);
    }, this);

    this.cargarGraficosTodos(camposSeleccionados);

  }

  cargarGraficosTodos(camposSeleccionados) {

    camposSeleccionados.forEach(function (campo, index) {

      var fechas: any = [];
      var valores: any = [];

      var i = 0;
      // for (i = 0; i < campo.fechas.length; i++) {
      //   //if (campo.fechas[i] >= this.fechaInicioZoom && campo.fechas[i] <= this.fechaFinZoom) {
      //   if ((campo.fechas[i + 1] >= this.fechaInicioZoom && campo.fechas[i + 1] <= this.fechaFinZoom) ||
      //     (campo.fechas[i - 1] >= this.fechaInicioZoom && campo.fechas[i - 1] <= this.fechaFinZoom) ||
      //     (campo.fechas[i] <= this.fechaInicioZoom && campo.fechas[i + 1] >= this.fechaFinZoom) ||
      //     (campo.fechas[i - 1] <= this.fechaInicioZoom && campo.fechas[i] >= this.fechaFinZoom)) {
      //     fechas.push(campo.fechas[i]);
      //     if (campo.valores[i] != undefined) {
      //       valores.push(campo.valores[i]);
      //     } else {
      //       valores.push(0)
      //     }

      //   }
      // }

      campo.fechas.forEach(element => {
        if (new Date(element).getTime() >= this.fechaInicioZoom.getTime() && new Date(element).getTime() <= this.fechaFinZoom.getTime()) {
          fechas.push(element);
          if (campo.valores[i] != undefined) {
            valores.push(campo.valores[i]);
          } else {
            valores.push(0)
          }
        }
        i++;
      });

      if (fechas.length > 150) {
        var fechas = this.aplanarArray(fechas, 150);
        var valores = this.aplanarArray(valores, 150);
      } else {
        fechas = fechas;
        valores = valores;
      }

      var c1: boolean = this.fechaInicioZoom < fechas[0];
      var c2: boolean = this.fechaFinZoom > fechas[fechas.length - 1];

      if (c1) fechas.unshift(this.fechaInicioZoom);
      fechas.unshift('x');
      if (c2) fechas.push(this.fechaFinZoom);

      if (c1) valores.unshift(null);
      valores.unshift('valor');
      if (c2) valores.push(null);

      var datos = [fechas, valores];

      if (index == 1) {
        campo.estaEnGrafico = 1;
        this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = campo.seleccionadoLeyenda;
        this.grafico1.load({ columns: datos });
        this.grafico1.data.colors({ valor: campo.color });
        this.grafico1.axis.labels({y: campo.nombre})

        //#region si el valor maximo supera el maximo establecido entonces se restablece (igual con el minimo)
        var maxValue = Math.max(...valores.slice(1));
        var maxAxis = this.grafico1.axis.max();
        if (maxValue > maxAxis.y)
          this.grafico1.axis.max({y: maxValue});

        var minValue = Math.min(...valores.slice(1));
        var minAxis = this.grafico1.axis.min();
        if (minValue < minAxis.y)  
          this.grafico1.axis.min({y: minValue});

        this.grafico1.axis.min({x: this.fechaInicioZoom})
        this.grafico1.axis.max({x: this.fechaFinZoom})
        //#endregion

        d3.selectAll('#grafico1_inftec .c3-axis-y line').style("stroke", campo.color);
        d3.selectAll('#grafico1_inftec .c3-axis-y tspan').style("fill", campo.color);//.attr("x", "-7");
        d3.selectAll('#grafico1_inftec .c3-axis-y path').style("stroke", campo.color);
        d3.selectAll('#grafico1_inftec .c3-axis-y text').style("fill", campo.color);
        d3.selectAll('#grafico1_inftec .c3-axis-y path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico1_inftec .c3-line').style("stroke-width", "1.3px");
      }
      if (index == 0) {
        campo.estaEnGrafico = 2;
        this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = campo.seleccionadoLeyenda;
        this.grafico2.load({ columns: datos });
        this.grafico2.data.colors({ valor: campo.color });
        this.grafico2.axis.labels({y: campo.nombre})
        
        //#region si el valor maximo supera el maximo establecido entonces se restablece (igual con el minimo)
        var maxValue = Math.max(...valores.slice(1));
        var maxAxis = this.grafico2.axis.max();
        if (maxValue > maxAxis.y)
          this.grafico2.axis.max({y: maxValue});

        var minValue = Math.min(...valores.slice(1));
        var minAxis = this.grafico2.axis.min();
        if (minValue < minAxis.y)  
          this.grafico2.axis.min({y: minValue});

        this.grafico2.axis.min({x: this.fechaInicioZoom})
        this.grafico2.axis.max({x: this.fechaFinZoom})
        //#endregion
        
        d3.selectAll('#grafico2_inftec .c3-axis-y line').style("stroke", campo.color);
        d3.selectAll('#grafico2_inftec .c3-axis-y tspan').style("fill", campo.color);//.attr("x", "-7");
        d3.selectAll('#grafico2_inftec .c3-axis-y path').style("stroke", campo.color);
        d3.selectAll('#grafico2_inftec .c3-axis-y text').style("fill", campo.color);
        d3.selectAll('#grafico2_inftec .c3-axis-y path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico2_inftec .c3-line').style("stroke-width", "1.3px");
      }
      if (index == 2) {
        campo.estaEnGrafico = 3;
        this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = campo.seleccionadoLeyenda;
        this.grafico3.load({ columns: datos, axes: {valor: 'y2'} });
        this.grafico3.data.colors({ valor: campo.color });
        this.grafico3.axis.labels({y2: campo.nombre})
        // d3.selectAll('#grafico3_inftec .c3-axis-y line').style("stroke", campo.color);
        // d3.selectAll('#grafico3_inftec .c3-axis-y tspan').style("fill", campo.color);//.attr("x", "-7");
        // d3.selectAll('#grafico3_inftec .c3-axis-y path').style("stroke", campo.color);
        // d3.selectAll('#grafico3_inftec .c3-axis-y text').style("fill", campo.color);
        // d3.selectAll('#grafico3_inftec .c3-axis-y path').style("stroke-width", "1.3px");
        
        //#region si el valor maximo supera el maximo establecido entonces se restablece (igual con el minimo)
        var maxValue = Math.max(...valores.slice(1));
        var maxAxis = this.grafico3.axis.max();
        if (maxValue > maxAxis.y)
          this.grafico3.axis.max({y2: maxValue});

        var minValue = Math.min(...valores.slice(1));
        var minAxis = this.grafico3.axis.min();
        if (minValue < minAxis.y)  
          this.grafico3.axis.min({y2: minValue});

        this.grafico3.axis.min({x: this.fechaInicioZoom})
        this.grafico3.axis.max({x: this.fechaFinZoom})
        //#endregion
        

        d3.selectAll('#grafico3_inftec .c3-axis-y2 line').style("stroke", campo.color);
        d3.selectAll('#grafico3_inftec .c3-axis-y2 tspan').style("fill", campo.color);//.attr("x", "-7");
        d3.selectAll('#grafico3_inftec .c3-axis-y2 path').style("stroke", campo.color);
        d3.selectAll('#grafico3_inftec .c3-axis-y2 text').style("fill", campo.color);
        d3.selectAll('#grafico3_inftec .c3-axis-y2 path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico3_inftec .c3-line').style("stroke-width", "1.3px");
      }
      if (index == 3) {
        campo.estaEnGrafico = 4;
        this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = campo.seleccionadoLeyenda;
        this.grafico4.load({ columns: datos, axes: {valor: 'y2'} });
        this.grafico4.data.colors({ valor: campo.color });
        this.grafico4.axis.labels({y2: campo.nombre})
        // d3.selectAll('#grafico4_inftec .c3-axis-y line').style("stroke", campo.color);
        // d3.selectAll('#grafico4_inftec .c3-axis-y tspan').style("fill", campo.color);//.attr("x", "-7");
        // d3.selectAll('#grafico4_inftec .c3-axis-y path').style("stroke", campo.color);
        // d3.selectAll('#grafico4_inftec .c3-axis-y text').style("fill", campo.color);
        // d3.selectAll('#grafico4_inftec .c3-axis-y path').style("stroke-width", "1.3px");
        
        //#region si el valor maximo supera el maximo establecido entonces se restablece (igual con el minimo)
        var maxValue = Math.max(...valores.slice(1));
        var maxAxis = this.grafico4.axis.max();
        if (maxValue > maxAxis.y)
          this.grafico4.axis.max({y2: maxValue});

        var minValue = Math.min(...valores.slice(1));
        var minAxis = this.grafico4.axis.min();
        if (minValue < minAxis.y)  
          this.grafico4.axis.min({y2: minValue});

        this.grafico4.axis.min({x: this.fechaInicioZoom})
        this.grafico4.axis.max({x: this.fechaFinZoom})
        //#endregion
        

        d3.selectAll('#grafico4_inftec .c3-axis-y2 line').style("stroke", campo.color);
        d3.selectAll('#grafico4_inftec .c3-axis-y2 tspan').style("fill", campo.color);//.attr("x", "-7");
        d3.selectAll('#grafico4_inftec .c3-axis-y2 path').style("stroke", campo.color);
        d3.selectAll('#grafico4_inftec .c3-axis-y2 text').style("fill", campo.color);
        d3.selectAll('#grafico4_inftec .c3-axis-y2 path').style("stroke-width", "1.3px");
        d3.selectAll('#grafico4_inftec .c3-line').style("stroke-width", "1.3px");
      }
      // if (index == 4) {
      //   campo.estaEnGrafico = 5;
      //   this.mostrarGraficosPorLeyenda[campo.estaEnGrafico - 1] = campo.seleccionadoLeyenda;
      //   this.grafico5.load({ columns: datos });
      //   this.grafico5.data.colors({ valor: campo.color });
      //   d3.selectAll('#grafico5_inftec .c3-axis-y line').style("stroke", campo.color);
      //   d3.selectAll('#grafico5_inftec .c3-axis-y tspan').style("fill", campo.color);//.attr("x", "-7");
      //   d3.selectAll('#grafico5_inftec .c3-axis-y path').style("stroke", campo.color);
      // }

    }, this);

    this.posicionarEjesGrafico();
    this.loadingGrafico5Ejes = false;

  }

  resetearDatosGrafico() {

    this.ejes = [];
    this.generales = [];
    this.arrayFechas = ["-"];
    this.indicePanelIzquierdo = 0;

    this.grafico1.load({ columns: [['x'], ['valor']] });
    this.grafico2.load({ columns: [['x'], ['valor']] });
    this.grafico3.load({ columns: [['x'], ['valor']] });
    this.grafico4.load({ columns: [['x'], ['valor']] });
    this.grafico5.load({ columns: [['x'], ['valor']] });

  }

  aplanarArray(data, aCuantosElementos) {
    //var aCuantosElementos = 1000;
    //var data = [15, 16, 9, 1, 84, 1, 6, 77];

    var resultado = [];
    resultado.push(data[0]);
    var i;
    for (i = 1; i < aCuantosElementos; i++) {
      resultado.push(data[Math.round((data.length / aCuantosElementos) * i)]);
    }
    resultado.push(data[data.length - 1]);
    return resultado;
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }

  modificarParametro() {
    this.modalReference = this.modalService.open(this.popupModificarParametro, { backdrop: 'static', size: 'md', keyboard: false, centered: true });
  }

  verificarGuardar() {
    this.modalReference.close();
    this.modalReferenceVerificar = this.modalService.open(this.popupVerificarGuardar, { backdrop: 'static', size: 'md', keyboard: false, centered: true });
  }

  guardarParametro() {
    var fechaHorasInicio = this.periodoInicioParametro.toISOString();
    var fechaHorasFin = this.periodoFinParametro.toISOString();
    console.log("Parametro:", this.parametro);
    console.log("Usuario:", this.user.id);
    console.log("Periodo inicio:", fechaHorasInicio);
    console.log("Periodo fin:", fechaHorasFin);
    console.log("Valor:", this.valorModificar);
    if (this.parametro == undefined || this.valorModificar == undefined) {
      console.log("Parametros vacios!");
      this.valorModificar = 0;
    } else {
      this.hmiService.Update_parametros(this.parametro.tabla, this.parametro.columna, this.parametro.idMaquina, this.valorModificar, fechaHorasInicio, fechaHorasFin).subscribe((result) => {
        //this.modalReference.close();
        this.modalReferenceVerificar.close();
      });
    }
  }

  cerrarTodo() {
    this.modalReferenceVerificar.close();
    this.modalReference.close();
  }


  // @HostListener('document:click', ['$event']) //función que detecta todos los clicks del documento
  // processClickEvents(event) {
  //   //makinen zerrenda ezkutatu, baina oraingoz kendu egin dugu:
  //   /*if (this.aparecer)
  //     if (this.maquinasElement !== event.target && !this.maquinasElement.contains(event.target)) { //si el click se ha hecho fuera del bloque maquinas, cierralo
  //      this.desplegarMaquinasClick();
  //      this.maquinasElement.classList.remove('desplegar'); 
  //     }
  //     */

  //   if (this.menuLateralDeplegado && !(event.target.className == 'panel-menu-desplegado') && !(event.target.className == 'desplegar-menu-lateral'))
  //     if (this.menuLateralElement !== event.target && !this.menuLateralElement.contains(event.target))
  //       //this.menuLateralDeplegado=false;
  //       this.clickDesplegarMenuLateral();

  // }






}
