import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService, AlertService, MenuService, MaquinasService, PiezasService, OFService, PiezasPredefinidasService, InformeProyectosService, AtributosService } from '@app/_services';
import { MyFunctions } from '@app/_helpers';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

import * as moment from 'moment';

@Component({
  selector: 'app-planacciondetalle-data',
  templateUrl: 'OFDetalle.html'
})

export class OFDetalleComponent {
  public loadingGraficoTresEjes: any = false;
  submitted = false;

  public idOF: number;

  public piezas: any = [];

  public secciones: any;
  public groupedSeccion: GroupResult[];

  public atributos: any;
  public groupedAtributo: GroupResult[];
  public atributosSeleccionados: any[] = [];

  public nombre: string;
  public of: string;
  public textoVersion: string;
  public proyecto: string;
  public cliente: string;
  public subcliente: string;
  public referencia: string;
  public idERP: string;
  public idSubClienteERP: string;
  public botonesActivados: Boolean;
  public idParte: number;
  public idPieza: number;
  public service: string;
  public partido: Boolean;
  public fechainilimite: Date;
  public fechafinlimite: Date;
  public prioridad: number;
  public partesseleccionados: number[] = [];
  user = this.userService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  isAddMode: boolean;
  navigationSubscription;
  form: FormGroup;

  closeResult = '';

  fechaHoyStr: string;
  tituloPopUp: any;
  tituloPopUpPregunta: any;

  public piezasstr: String = '';
  public limitesstr: String = '';

  public piezasPred: any = []; //Tiene el listado de piezasPredefinidas con sus datos
  public piezasPredSelected: any = []; //Tiene las piezaPredefinidas seleccionadas

  public haySubpartes: boolean = false;

  public terminadoInicial: boolean = false;

  @ViewChild('anadirPred') anadirPred: NgbModalRef;
  @ViewChild('terminarOFPiezaOperacion') terminarOFPiezaOperacion: NgbModalRef;
  @ViewChild('catidadHechasOperacion') catidadHechasOperacion: NgbModalRef;

  constructor(
    private formBuilder: FormBuilder,
    private ofService: OFService,
    private piezasService: PiezasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    public myFunctions: MyFunctions,
    private translate: TranslateService,
    private modalService: NgbModal,
    private menuService: MenuService,
    private maquinasService: MaquinasService,
    private piezasPredefinidasService: PiezasPredefinidasService,
    protected alertService: AlertService,
    private informeProyectosService: InformeProyectosService,
    private atributosService: AtributosService) {

    this.navigationSubscription = this.router.events.subscribe(
      (e: any) => {
        if (e instanceof NavigationEnd) {
          if (this.router.url.startsWith('/OF/editar') == true) {
            this.menuService.titulo = this.translate.instant('proyecto').toUpperCase();
            this.cargarDatos();
          }
        }
      });

  }

  ngOnInit() {

    this.idOF = this.route.snapshot.params.id;
    this.loadingGraficoTresEjes = false;
    this.piezasstr = this.translate.instant('piezas');
    this.limitesstr = this.translate.instant('limites');
    this.tituloPopUp = document.getElementById("text_loading_popup");
    this.tituloPopUpPregunta = document.getElementById("titulo_modal_pregunta");
    this.botonesActivados = false;

    this.idOF = this.route.snapshot.params['id'];
    this.isAddMode = !this.idOF;

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      of: new FormControl('', [Validators.required]),
      textoVersion: new FormControl(''),
      proyecto: new FormControl(''),
      cliente: new FormControl(''),
      subcliente: new FormControl(''),
      referencia: new FormControl(''),
      idERP: new FormControl(''),
      idSubClienteERP: new FormControl(''),
      terminado: new FormControl(false),
      prototipo: new FormControl(false),
      idSeccion: new FormControl(undefined, [Validators.required]),
      prioridad: new FormControl(0),
      fechainicio: new FormControl(undefined),
      fechafin: new FormControl(undefined),
      atributos: [[]],
    });

    this.userService.getComboSecciones().subscribe(json => {
      this.secciones = json;
      if (this.secciones.length == 1)
        this.form.controls['idSeccion'].setValue(this.secciones[0]);
      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      this.atributosService.getComboAtributos().subscribe(json => {
        this.atributos = json;
        if (this.atributos.length == 1)
          this.form.controls['atributos'].setValue(this.atributos);
        var an: any = this.atributos;
        this.groupedAtributo = groupBy(an, [{ field: 'atributo' }]);
      });

      if (this.idOF > 0) {
        this.isAddMode = false;
        this.cargarDatos();
      } else {
        this.isAddMode = true;
      }

    });

  }

  cargarDatos() {
    if (this.idOF > 0) {
      this.loadingGraficoTresEjes = true;
      this.ofService.GetByID(this.idOF).pipe().subscribe(
        (result) => {
          this.fechainilimite = null;
          this.fechafinlimite = null;
          this.prioridad = result.of[0].prioridad;

          if (result.of[0].finicio != undefined) this.fechainilimite = new Date(result.of[0].finicio);
          if (result.of[0].fechaentrega != undefined) this.fechafinlimite = new Date(result.of[0].fechaentrega);

          var atributosCargados = [];

          if (result.atributos != undefined) {
            result.atributos.forEach(a => {
              atributosCargados.push(this.atributos.find(x => x.id == a.idAtributo));
            });
          }

          // Guardar valor de terminado para compararlo despues
          this.terminadoInicial = result.of[0].terminado;

          this.form = this.formBuilder.group({
            idDb: this.user.idDb,
            id: [this.idOF],
            of: new FormControl(result.of[0].of, Validators.required),
            textoVersion: new FormControl(result.of[0].textoVersion),
            proyecto: new FormControl(result.of[0].proyecto),
            cliente: new FormControl(result.of[0].cliente),
            subcliente: new FormControl(result.of[0].subCliente),
            referencia: new FormControl(result.of[0].referencia),
            idERP: new FormControl(result.of[0].idERP),
            idSubClienteERP: new FormControl(result.of[0].idSubClienteERP),
            prioridad: [this.prioridad, 0],
            turno: new FormControl(0),
            terminado: new FormControl(result.of[0].terminado),
            prototipo: new FormControl(result.of[0].prototipo),
            fechainicio: new FormControl(this.fechainilimite),
            fechafin: new FormControl(this.fechafinlimite),
            idSeccion: new FormControl(this.secciones.find(x => x.id == result.of[0].idSeccion), [Validators.required]),
            atributos: new FormControl(atributosCargados)
          });

          if (result.piezas != undefined) {
            var piezas = result.piezas; //.filter(f => f.esPieza);
            piezas.forEach(
              row => {
                if (!row.esPieza) {
                  try {
                    var a = result.piezas.filter(f => f.esPieza && row.idPieza == f.idPieza);
                    if (a.length > 0) {
                      row.idPadre = a[0].id;
                    }
                  } catch (e) { }

                }
                else {
                  row.idPadre = null;
                }
              });

            if (result.piezas.filter(f => !f.esPieza).length > 0) {
              this.haySubpartes = true;
            }
            else {
              this.haySubpartes = false;
            }

            this.piezas = piezas;
            this.botonesActivados = (this.piezas.length > 0);
          }
          this.isAddMode = false;
          this.loadingGraficoTresEjes = false;
        })

    }

  }

  onClickEliminarPieza(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("popup.preguntaeliminarpopup");
    }
    if (this.partesseleccionados.length > 0) {
      this.service = 'pieza';
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }

  }

  onClickEliminarOF(content) {
    if (this.tituloPopUpPregunta != undefined) {
      this.tituloPopUpPregunta.innerText = this.translate.instant("popup.preguntaeliminarpopupdetallecompleto");
    }
    this.service = 'of';
    this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  onClickDuplicarOF(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.ofService.duplicar(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
        this.Atras();
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
    });
  }

  cellClick(e) {
    this.idPieza = e.dataItem.idPieza;
    if (e.columnIndex > 0) {
      this.idParte = e.dataItem.idParte;
      this.router.navigate(['piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.idParte]);
    }
  }

  onClickEditarPieza(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.idPieza = this.piezas.filter(x => x.idParte == this.partesseleccionados[0]["itemKey"])[0].idPieza;
    if (this.partesseleccionados[0]["itemKey"] > 0) {
      this.idParte = this.partesseleccionados[0]["itemKey"];
      this.router.navigate(['piezas/editar/' + this.idOF + "/" + this.idPieza + "/" + this.partesseleccionados[0]["itemKey"]]);
    }
  }

  onClickNuevaPieza(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    this.router.navigate(['piezas/crear/' + this.idOF]);
  }

  onClickNuevaPiezaPred(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    //this.router.navigate(['piezas/crear/' + this.idOF + "/" + 1]);
    this.modalReference = this.modalService.open(this.anadirPred, { backdrop: 'static', size: 'xl', keyboard: false, centered: true });
    this.piezasPredefinidasService.GetAll().subscribe((result: any) => {
      this.piezasPred = [];
      result.piezas.forEach(element => {
        if (element.esPieza)
          this.piezasPred.push(element); //Solo hay que mostrar las que sean piezas, no se pueden añadir partes porque habría que indicar a qué pieza agregársela
      });
    });
  }

  onClickAceptarAnadirPred() {
    var auxPiezaPred = this.piezasPred.filter(x => this.piezasPredSelected.includes(x.id));
    auxPiezaPred.forEach(element => {
      this.piezasService.createPiezaPred(element.id, this.idOF, element.esPieza, element.idParte).subscribe((result) => {
        this.modalReference.close();
        console.log("result: " + result.error)
        if (result.error == false) {
          this.router.navigate(['OF/editar/' + this.idOF]);
        } else {
          this.alertService.error(this.translate.instant('error'));
        }
      });
    });
  }

  onClickCancelar() {
    this.piezasPredSelected = [];
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit(contentloading, e) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    if (this.isAddMode) {
      this.nuevo();
    } else {
      this.actualizar();
    }
  }

  nuevo() {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("guardando");
    }
    this.ofService.create(this.form.value).subscribe((result) => {
      this.modalReferenceloading.close();
      if (result.error == false) {
        this.cargarDatos();
        this.router.navigate(['OF/editar/' + result.id]);
      } else {
        this.alertService.error(this.translate.instant('error'));
      }
    });
  }

  actualizar() {
    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("guardando");
    }
    this.form.value.idOF = this.idOF;

    //OFa terminado bezala jartzen bada, honen barruko pieza eta operazioak itxiko direla mezua jaurti
    if (this.form.value.terminado == true && (this.terminadoInicial != this.form.value.terminado)) {

      this.modalReference = this.modalService.open(this.terminarOFPiezaOperacion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });

      this.modalReferenceloading.close();
    } else {
      if (this.tituloPopUp != undefined) {
        this.tituloPopUp.innerText = this.translate.instant("guardando");
      }
      this.ofService.update(this.form.value)
        .subscribe((result) => {
          this.modalReferenceloading.close();
          if (result.error == false) {
            this.cargarDatos();
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            this.router.navigate(['OF/editar/' + this.idOF]);
          }
          else {
            this.alertService.error(this.translate.instant('error'));
          }
        });
    }


  }

  Atras() {
    this.router.navigate(['OF']);
  }

  operacionesCantidadHechas(){

    this.modalReference.close();
    this.modalReference = this.modalService.open(this.catidadHechasOperacion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });

  }

  closeElements(valor: number) {
    console.log("OF -> ", this.form.value.idOF);
    console.log("Terminado -> ", this.form.value.terminado);

    this.ofService.update(this.form.value)
      .subscribe((result) => {
        this.modalReferenceloading.close();
        this.modalReference.close();
        if (result.error == false) {
          //Piezak eta operazioak itxi
          this.piezasService.cerrarPiezasOperaciones(this.form.value.idOF, valor).subscribe((data) => {
            this.cargarDatos();
            this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
            this.router.navigate(['OF/editar/' + this.idOF]);
          });
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }
      });

  }

  private Eliminar(contentloading) {

    if (this.tituloPopUp != undefined) {
      this.tituloPopUp.innerText = this.translate.instant("eliminando");
    }

    switch (this.service) {

      case 'of':
        this.ofService.delete({ idof: this.idOF }).subscribe(
          (data) => {
            this.modalReferenceloading.close();
            if (data.error == false) {
              this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
              this.router.navigate(['OF']);
            } else {
              this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
            }
          });
        break;

      case 'pieza':

        // this.idPieza = this.piezas.filter(x => x.idParte == this.partesseleccionados[0]["itemKey"])[0].idPieza;
        if (this.partesseleccionados[0]["itemKey"] > 0) {
          this.idParte = this.partesseleccionados[0]["itemKey"];
          var idsPartes = []
          this.partesseleccionados.forEach(
            parte =>{
              idsPartes.push(parte["itemKey"])
          });

          this.piezasService.delete(this.myFunctions.listToStirng(idsPartes,',', '-1')).subscribe(
            (data) => {
              this.modalReferenceloading.close();
              if (data.error == false) {
                this.alertService.success(this.translate.instant("ok"), { keepAfterRouteChange: true });
                this.router.navigate(['OF/editar/' + this.idOF]);
              } else {
                this.alertService.error(this.translate.instant("error"), { keepAfterRouteChange: true });
              }
            });
        }
        break;
    }
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  selectionChangePiezaPred() { }

}
