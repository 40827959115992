import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsumibleComponent } from '@app/consumibles/consumible.component';
import { environment } from '@environments/environment';
import { tableColumnInsertLeftIcon } from '@progress/kendo-svg-icons';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/eskola_maquinas`;

@Injectable()
export class Eskola_MaquinasService {

  constructor(private http: HttpClient) { }

  getGruposMaquinas() {
    return this.http.get<any>(`${baseUrl}/get_gruposMaquinas`, { withCredentials: true });
  }
  get(){
    return this.http.get<any>(`${baseUrl}`, { withCredentials: true });
  }
}

