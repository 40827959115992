import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/boxplot`;

@Injectable()
export class BoxplotService {

  constructor(private http: HttpClient) {}

  //SELECT
  GetInformeTabla(fechaInicio, fechaFin, listaNombresClientes, listaNombresPiezas, listaNombresOfs, listaNombresPartes, listaNombresOperacion, listaIdsSecciones, listaIdsMaquinas,
  listaIdsGrupos, terminado) {
    return this.http.post<JSON>(`${baseUrl}/Get_informeTabla`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaNombresClientes: listaNombresClientes, listaNombresPiezas: listaNombresPiezas, listaNombresOfs: listaNombresOfs, listaNombresPartes: listaNombresPartes,
      listaNombresOperacion: listaNombresOperacion, listaIdsSecciones: listaIdsSecciones, listaIdsMaquinas: listaIdsMaquinas, listaIdsGrupos: listaIdsGrupos, 
      operacionTerminada: terminado}, { withCredentials: true });
  }

  //SELECT
  GetInformeTablaPiezas(fechaInicio, fechaFin, listaNombresClientes, listaNombresPiezas, listaNombresOfs, listaNombresPartes,listaNombresOperacion, listaIdsSecciones, listaIdsMaquinas,
   listaIdsGrupos, terminado) {
    return this.http.post<JSON>(`${baseUrl}/Get_informeTablaPiezas`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaNombresClientes: listaNombresClientes, listaNombresPiezas: listaNombresPiezas, listaNombresOfs: listaNombresOfs, listaNombresPartes: listaNombresPartes,
      listaNombresOperacion: listaNombresOperacion, listaIdsSecciones: listaIdsSecciones, listaIdsMaquinas: listaIdsMaquinas, listaIdsGrupos: listaIdsGrupos, 
      piezaTerminada: terminado}, { withCredentials: true });
  }

  //SELECT
  GetInformePiezasBoxplots(fechaInicio, fechaFin, listaNombresClientes, listaNombresPiezas, listaNombresOfs, listaNombresPartes, listaNombresOperacion, listaIdsSecciones, listaIdsMaquinas,
   listaIdsGrupos, terminado) {
    return this.http.post<JSON>(`${baseUrl}/Get_informePiezasBoxplots`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaNombresClientes: listaNombresClientes, listaNombresPiezas: listaNombresPiezas, listaNombresOfs: listaNombresOfs, listaNombresPartes: listaNombresPartes,
      listaNombresOperacion: listaNombresOperacion, listaIdsSecciones: listaIdsSecciones, listaIdsMaquinas: listaIdsMaquinas, listaIdsGrupos: listaIdsGrupos, 
      piezaTerminada: terminado}, { withCredentials: true });
  }

  //SELECT
  GetInformeOperacionesBoxplots(fechaInicio, fechaFin, listaNombresClientes, listaNombresPiezas, listaNombresOfs, listaNombresPartes, listaNombresOperacion, listaIdsSecciones, listaIdsMaquinas,
   listaIdsGrupos, terminado) {
    return this.http.post<JSON>(`${baseUrl}/Get_informeOperacionesBoxplots`, {
      fechaInicio: fechaInicio, fechaFin: fechaFin,
      listaNombresClientes: listaNombresClientes, listaNombresPiezas: listaNombresPiezas, listaNombresOfs: listaNombresOfs, listaNombresPartes: listaNombresPartes,
      listaNombresOperacion: listaNombresOperacion, listaIdsSecciones: listaIdsSecciones, listaIdsMaquinas: listaIdsMaquinas, listaIdsGrupos: listaIdsGrupos, 
      operacionTerminada: terminado}, { withCredentials: true });
  }
}
