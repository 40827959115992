<div class="card">
    <div class="card-body">
        <kendo-treelist [kendoTreeListFlatBinding]="data" kendoTreeListExpandable idField="id"
            [initiallyExpanded]="true" parentIdField="idPadre">
            <kendo-treelist-column [expandable]="true" field="nombre" title="{{ 'nombre' | translate}}" width="30%">
            </kendo-treelist-column>
            <kendo-treelist-column field="enServicio" title="{{ 'enServicio' | translate }}" width="10%">
            </kendo-treelist-column>
            <kendo-treelist-column field="vidaEstimada" title="{{ 'vidaEstimada' | translate }}" width="10%">
            </kendo-treelist-column>
            <kendo-treelist-column field="stock" title="{{ 'stock' | translate}}" width="10%">
            </kendo-treelist-column>
            <kendo-treelist-column field="tiempoEstimado" title="{{ 'tiempoEstimado' | translate}}" width="10%">
            </kendo-treelist-column>
            <kendo-treelist-column field="plazoEntrega" title="{{ 'plazoEntrega' | translate}}" width="10%">
            </kendo-treelist-column>
            <kendo-treelist-column field="pedido" title="{{ 'pedido' | translate}}" width="10%">
            </kendo-treelist-column>
            <kendo-treelist-messages loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
            </kendo-treelist-messages>
        </kendo-treelist>
    </div>
</div>

<div class="form-group mt-2">
    <button type="submit" class="btn btn-primary btn-sm mr-1" (click)="btnRealizarPedido()" [disabled]="this.user.stock < 2">
        {{ 'realizarPedido' | translate}}
    </button>
</div>
