<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="row">
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h3>
                                <label ID="lCaracteristicas" CssClass="">{{ 'datos' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">
                            <div class="row">

                                <!--NOMBRE-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'nombre' | translate }}">
                                        <kendo-textbox formControlName="nombre"></kendo-textbox>
                                    </kendo-label>
                                    </div>
                                </div>
                            </div>

                                <div class="row">
                                <!--tipo campo-->
                                <div class="col-md-6">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'tipoCampo' | translate }}">
                                        <!-- <kendo-textbox formControlName="idTipo_Campo_Instalacion"></kendo-textbox> -->

                                        <div class="caja">
                                            <kendo-combobox [data]="JTipoCampo"
                                                            [textField]="'nombre'"
                                                            [valueField]="'id'"
                                                            [(value)]="JTipoCampoSelected"
                                                            placeholder="{{ 'tipoCampo' | translate }}"
                                                            [kendoDropDownFilter]="{operator: 'contains'}"
                                                            [allowCustom]="true"
                                                            (valueChange)="tipoCampoChanged($event)">
                                            </kendo-combobox>
                                          </div>
                                    </kendo-label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <!--freq_captura_datos-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'frecCapturaDatos' | translate }}">
                                        <!-- <kendo-textbox formControlName="freq_captura_datos"></kendo-textbox> -->
                                        <kendo-numerictextbox formControlName="freq_captura_datos" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                    </div>
                                </div>

                                <!--max-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'maximo' | translate }}">
                                        <!-- <kendo-textbox formControlName="maximo"></kendo-textbox> -->
                                        <kendo-numerictextbox formControlName="maximo" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                    </div>
                                </div>

                                <!--min-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'minimo' | translate }}">
                                        <!-- <kendo-textbox formControlName="minimo"></kendo-textbox> -->
                                        <kendo-numerictextbox formControlName="minimo" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                    </kendo-label>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <!--tipografico-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'tipoGrafico' | translate }}">
                                        <!-- <kendo-textbox formControlName="tipoGrafico"></kendo-textbox> -->
                                        <div class="caja">
                                            <kendo-combobox [data]="JTipoGrafico"
                                                            [textField]="'nombre'"
                                                            [valueField]="'id'"
                                                            (open)="clickTipoGrafico()"
                                                            [(value)]="JTipoGraficoSelected"
                                                            placeholder="{{ 'tipoGrafico' | translate }}"
                                                            [kendoDropDownFilter]="{operator: 'contains'}"
                                                            [allowCustom]="true"
                                                            (valueChange)="tipoGraficoChanged($event)">
                                            </kendo-combobox>
                                          </div>
                                    </kendo-label>
                                    </div>
                                </div>

                                <!--unidad-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'unidad' | translate }}">
                                        <!-- <kendo-textbox formControlName="tipoGrafico"></kendo-textbox> -->
                                        <div class="caja">
                                            <kendo-combobox [data]="JTipoUnidad"
                                                            [textField]="'nombre'"
                                                            [valueField]="'id'"
                                                            [(value)]="JTipoUnidadSelected"
                                                            placeholder="{{ 'unidad' | translate }}"
                                                            [kendoDropDownFilter]="{operator: 'contains'}"
                                                            [allowCustom]="true">
                                                <ng-template kendoComboBoxItemTemplate let-dataItem>
                                                    {{ dataItem.nombre | translate }}
                                                </ng-template>
                                                <ng-template kendoComboBoxValueTemplate let-dataItem>
                                                    {{ dataItem.nombre | translate }}
                                                </ng-template>
                                            </kendo-combobox>
                                          </div>
                                    </kendo-label>
                                    </div>
                                </div>

                                <!--tipo Dato-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'tipoDato' | translate }}">
                                        <!-- <kendo-textbox formControlName="idTipo_Dato_Campo_DAT"></kendo-textbox> -->
                                        <div class="caja">
                                            <kendo-combobox [data]="JTipoDato"
                                                            [textField]="'nombre'"
                                                            [valueField]="'id'"
                                                            [(value)]="JTipoDatoSelected"
                                                            placeholder="{{ 'tipoDato' | translate }}"
                                                            [kendoDropDownFilter]="{operator: 'contains'}"
                                                            [allowCustom]="true"
                                                            (valueChange)="tipoDatoChanged($event)">
                                            </kendo-combobox>
                                          </div>
                                    </kendo-label>
                                    </div>
                                </div>

                            </div>

                            <div class="row" *ngIf="tipoGraficoDato">
                                <!--tipografico-->
                                <div class="col-md-12">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'imagen' | translate }}">
                                        <div class="caja">
                                            <kendo-dropdownlist [data]="JTipoIcono" 
                                                                [textField]="'icono'" 
                                                                [valueField]="'id'"
                                                                [autoClose]="false"
                                                                [(value)]="JTipoIconoSelected"
                                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                                style="width:100%;"
                                                                (click)="classKendoList()">
                                                <!-- <div class="cont-selector-icono"> -->
                                                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                        <span *ngIf="dataItem!=undefined" class="{{dataItem.icono}}"></span>
                                                        <span *ngIf="dataItem!=undefined"> {{ dataItem.nombre | translate }}</span>
                                                    </ng-template>
                                                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                                                        <div class="cont-selector-icono-seleccionado">
                                                            <span *ngIf="dataItem!=undefined" class="{{dataItem.icono}}"></span>
                                                            <span *ngIf="dataItem!=undefined"> {{ dataItem.nombre | translate }}</span>
                                                        </div>
                                                    </ng-template>
                                                <!-- </div> -->
                                            </kendo-dropdownlist>
                                          </div>
                                    </kendo-label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <!--trestar valor anterior-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'restarAnterior' | translate }}">
                                        <!-- <kendo-textbox formControlName="restarAnterior"></kendo-textbox> -->
                                        <br />
                                        <kendo-switch formControlName="restarAnterior" [onLabel]="' '" [offLabel]="' '" ></kendo-switch>
                                    </kendo-label>
                                    </div>
                                </div>

                                <!--activo-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'activo' | translate }}">
                                        <!-- <kendo-textbox formControlName="activo"></kendo-textbox> -->
                                        <br />
                                        <kendo-switch formControlName="activo" [onLabel]="' '" [offLabel]="' '" ></kendo-switch>
                                    </kendo-label>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <h3>
                                <label ID="lCaracteristicas" CssClass="">{{ 'datosPLC' | translate }}</label>
                            </h3>
                            <div class="plegarpanel"></div>
                        </div>
                        <div class="card-body">
                            <div class="row">

                                <!--tipo PLC-->
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'tipoPlc' | translate }}">
                                        <!-- <kendo-textbox formControlName="idTipo_PLC_DAT"></kendo-textbox> -->
                                        <div class="caja">
                                            <kendo-combobox [data]="JTipoPLC"
                                                            [textField]="'nombre'"
                                                            [valueField]="'id'"
                                                            [(value)]="JTipoPLCSelected"
                                                            placeholder="{{ 'tipoPlc' | translate }}"
                                                            [kendoDropDownFilter]="{operator: 'contains'}"
                                                            [allowCustom]="true"
                                                            (valueChange)="tipoPLCChanged($event)">
                                            </kendo-combobox>
                                          </div>
                                    </kendo-label>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="tipoPLC_Snap7">
                                <!--IP-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'ip' | translate }}">
                                            <kendo-textbox formControlName="ipAddress_S7"></kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--puerto-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'puerto' | translate }}">
                                            <!-- <kendo-textbox formControlName="port_S7"></kendo-textbox> -->
                                            <kendo-numerictextbox formControlName="port_S7" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--rack-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'rack' | translate }}">
                                            <!-- <kendo-textbox formControlName="rack_S7"></kendo-textbox> -->
                                            <kendo-numerictextbox formControlName="rack_S7" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--slot-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'slot' | translate }}">
                                            <!-- <kendo-textbox formControlName="slot_S7"></kendo-textbox> -->
                                            <kendo-numerictextbox formControlName="slot_S7" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                            
                            </div>
                            <div class="row" *ngIf="tipoPLC_Modbus">
                                <!--IP-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'ip' | translate }}">
                                            <kendo-textbox formControlName="ipAddress_Modbus"></kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--puerto-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'puerto' | translate }}">
                                            <kendo-numerictextbox formControlName="port_Modbus" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="tipoPLC_Backnet">
                                
                                <!--puerto-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'puerto' | translate }}">
                                            <!-- <kendo-textbox formControlName="port_S7"></kendo-textbox> -->
                                            <kendo-numerictextbox formControlName="port_Backnet" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--endpoint IP-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'localEndPointIP' | translate }}">
                                            <kendo-textbox formControlName="localEndpointIP_Backnet"></kendo-textbox>
                                        </kendo-label>
                                    </div>
                                </div>

                                
                            </div>

                            <div class="row" *ngIf="tipoPLC_Backnet">
                                <!--useExclusibePort_Backnet-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'useExclusivePort' | translate }}">
                                        <!-- <kendo-textbox formControlName="activo"></kendo-textbox> -->
                                        <br />
                                        <kendo-switch formControlName="useExclusibePort_Backnet" [onLabel]="' '" [offLabel]="' '" ></kendo-switch>
                                    </kendo-label>
                                    </div>
                                </div>
                                <!--dontFragment_Backnet-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                    <kendo-label text="{{ 'dontFragment' | translate }}">
                                        <!-- <kendo-textbox formControlName="activo"></kendo-textbox> -->
                                        <br />
                                        <kendo-switch formControlName="dontFragment_Backnet" [onLabel]="' '" [offLabel]="' '" ></kendo-switch>
                                    </kendo-label>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="tipoPLC_Backnet">
                                
                                <!--lowLimit_Backnet-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'lowLimit' | translate }}">
                                            <kendo-numerictextbox formControlName="lowLimit_Backnet" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--high_limit_Backnet-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'highLimit' | translate }}">
                                            <kendo-numerictextbox formControlName="high_limit_Backnet" class="form-control" [min]="0" [max]="99999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--maxPayload_Backnet-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'maxPayload' | translate }}">
                                            <kendo-numerictextbox formControlName="maxPayload_Backnet" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <!--VARIABLE-->
                        <div class="card-header">
                            <h3>
                                <label  CssClass="">{{ 'variable' | translate }}</label>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row" *ngIf="tipoPLC_Snap7">
                                <!--Tipo-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'tipoRegistro' | translate }}">
                                            <div class="caja">
                                                <kendo-combobox [data]="JTipoRegistro_S7"
                                                                [textField]="'nombre'"
                                                                [valueField]="'id'"
                                                                [(value)]="JTipoRegistroSelected_S7"
                                                                placeholder="{{ 'tipo' | translate }}"
                                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                                [allowCustom]="true"
                                                                >
                                                </kendo-combobox>
                                              </div>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--area-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'area' | translate }}">
                                            <!-- <kendo-textbox formControlName="area_S7"></kendo-textbox> -->
                                            <div class="caja">
                                                <kendo-combobox [data]="JArea_S7"
                                                                [textField]="'nombre'"
                                                                [valueField]="'id'"
                                                                [(value)]="JArea_S7Selected"
                                                                placeholder="{{ 'area' | translate }}"
                                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                                [allowCustom]="true"
                                                                >
                                                </kendo-combobox>
                                              </div>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="tipoPLC_Snap7"> 
                                <!--dbNumber_S7-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'dbNumber' | translate }}">
                                            <kendo-numerictextbox formControlName="dbNumber_S7" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--start_S7-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'inicio2' | translate }}">
                                            <kendo-numerictextbox formControlName="start_S7" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--amount_S7-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'cantidad' | translate }}">
                                            <kendo-numerictextbox formControlName="amount_S7" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="row" *ngIf="tipoPLC_Snap7"> 
                                <!--position_S7-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'posicion' | translate }}">
                                            <kendo-numerictextbox formControlName="position_S7" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--size_s7-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'tamano' | translate }}">
                                            <kendo-numerictextbox formControlName="size_S7" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>

                            </div>
                            <div class="row" *ngIf="tipoPLC_Modbus">
                                <!--Tipo registro-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'tipoRegistro' | translate }}">
                                            <div class="caja">
                                                <kendo-combobox [data]="JTipoRegistro_Modbus"
                                                                [textField]="'nombre'"
                                                                [valueField]="'id'"
                                                                [(value)]="JTipoRegistroSelected_Modbus"
                                                                placeholder="{{ 'tipo' | translate }}"
                                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                                [allowCustom]="true"
                                                                >
                                                </kendo-combobox>
                                              </div>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--area-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'area' | translate }}">
                                            <!-- <kendo-textbox formControlName="area_S7"></kendo-textbox> -->
                                            <div class="caja">
                                                <kendo-combobox [data]="JArea_Modbus"
                                                                [textField]="'nombre'"
                                                                [valueField]="'id'"
                                                                [(value)]="JArea_ModbusSelected"
                                                                placeholder="{{ 'area' | translate }}"
                                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                                [allowCustom]="true"
                                                                >
                                                </kendo-combobox>
                                              </div>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="tipoPLC_Modbus">
                                
                                <!--unitIdentifier_Modbus-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'unitIdentifier' | translate }}">
                                            <kendo-numerictextbox formControlName="unitIdentifier_Modbus" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--startingAddress_Modbus-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'startingAddress' | translate }}">
                                            <kendo-numerictextbox formControlName="startingAddress_Modbus" class="form-control" [min]="0" [max]="9999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--quantity_Modbus-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'cantidad' | translate }}">
                                            <!-- <kendo-textbox formControlName="port_S7"></kendo-textbox> -->
                                            <kendo-numerictextbox formControlName="quantity_Modbus" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                                <!--position_Modbus-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'posicion' | translate }}">
                                            <!-- <kendo-textbox formControlName="port_S7"></kendo-textbox> -->
                                            <kendo-numerictextbox formControlName="position_Modbus" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>

                                        </kendo-label>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="tipoPLC_Backnet">
                                <!--Tipo registro-->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'tipoRegistro' | translate }}">
                                            <div class="caja">
                                                <kendo-combobox [data]="JTipoRegistro_Backnet"
                                                                [textField]="'nombre'"
                                                                [valueField]="'id'"
                                                                [(value)]="JTipoRegistroSelected_Backnet"
                                                                placeholder="{{ 'tipo' | translate }}"
                                                                [kendoDropDownFilter]="{operator: 'contains'}"
                                                                [allowCustom]="true"
                                                                >
                                                </kendo-combobox>
                                            </div>
                                        </kendo-label>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="tipoPLC_Backnet">
                                 <!--instance_Backnet-->
                                 <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'instance' | translate }}">
                                            <kendo-numerictextbox formControlName="instance_Backnet" class="form-control" [min]="0" [max]="999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>
                                <!--device_id_Backnet-->
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <kendo-label text="{{ 'device_id' | translate }}">
                                            <kendo-numerictextbox formControlName="device_id_Backnet" class="form-control" [min]="0" [max]="99999" [autoCorrect]="true" [format]="'n'"></kendo-numerictextbox>
                                        </kendo-label>
                                    </div>
                                </div>

                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>



</form>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group" style=" margin-top: 20px;">

      <button type="submit" class="btn btn-primary btn-sm mr-1" [disabled]="this.user.instalaciones <2">
        {{ 'guardar' | translate}}
      </button>
      <a class="btn btn-danger btn-sm mr-1" (click)="atras()">
        {{ 'cancelar' | translate}}
      </a>

    </div>

  </form>






