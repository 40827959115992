
<div class="row">    
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
        <div class="card">
            <div class="card-body">
                <!-- ORDEN -->
                <div class="form-group">
                    <label class="crontol-label m-1">{{ 'orden' | translate }}</label>
                    <div class="caja">
                        <div class="caja">
                            <kendo-numerictextbox [format]="'n0'" [min]="1" [max]="99" [(value)]="orden"></kendo-numerictextbox>
                        </div>
                    </div>
                </div>
                <!-- TIPO PROCESO SUBESTADO -->
                <div class="form-group">
                    <label class="crontol-label m-1">{{ 'nombre' | translate }}</label>
                    <div class="caja">
                      <kendo-combobox [data]="JProcesos_Tipo_subEstados"
                                      [textField]="'nombre'"
                                      [valueField]="'id'"
                                      [(value)]="JProcesos_Tipo_subEstados_Selected"
                                      placeholder="{{ 'tipo' | translate }}"
                                      [kendoDropDownFilter]="{operator: 'contains'}"
                                      [disabled]="bloqueado">
                      </kendo-combobox>
                    </div>
                    <!-- TIEMPO PERDIDA -->
                    <div class="form-group" *ngIf="JProcesos_Tipo_subEstados_Selected?.idProcesos_Tipo == 2">
                        <label class="crontol-label m-1">{{ 'tiempoPerdida' | translate }}</label>
                        <div class="caja">
                            <div class="caja">
                                <kendo-numerictextbox [format]="'n0'" [min]="0" [max]="9999999" [(value)]="tiempoPerdida"></kendo-numerictextbox>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- INFO PROCESO SUBESTADO -->
                <div class="form-group" *ngIf="JProcesos_Tipo_subEstados_Selected != undefined">
                    <!-- COLOR -->
                    <div class="form-group">
                        <label class="crontol-label m-1">{{'color' | translate }}</label><br>
                        <kendo-colorpicker [(value)]="JProcesos_Tipo_subEstados_Selected.color" [disabled]="bloqueado"></kendo-colorpicker>                
                    </div>
                    <!-- TIPO SUBESTADO -->
                    <div class="form-group" *ngIf="!tieneHijos">
                        <label class="crontol-label m-1">{{ 'agrupadocon' | translate }}</label>
                        <div class="caja">
                          <kendo-combobox [data]="JMaquinas_SubEstados"
                                          [textField]="'nombre'"
                                          [valueField]="'id'"
                                          [(value)]="JMaquinas_SubEstadosSelected"
                                          placeholder="{{ 'tipo' | translate }}"
                                          [kendoDropDownFilter]="{operator: 'contains'}">
                          </kendo-combobox>
                        </div>
                    </div>   
                    <!-- TIEMPO PERDIDA -->
                    <div class="form-group">
                        <label class="crontol-label m-1">{{ 'tiempoCambioDeEstado' | translate }}</label>
                        <div class="caja">
                            <div class="caja">
                                <kendo-numerictextbox [format]="'n0'" [min]="0" [max]="9999999" [(value)]="tiempoCambioDeEstado"></kendo-numerictextbox>
                            </div>
                        </div>
                    </div>                 
                    <!-- TIPO SUBESTADO -->
                    <div class="form-group">
                        <label class="crontol-label m-1">{{ 'proximoEstado' | translate }}</label>
                        <div class="caja">
                          <kendo-combobox [data]="JMaquinas_proximo_SubEstados"
                                          [textField]="'nombre'"
                                          [valueField]="'id'"
                                          [(value)]="JMaquinas_proximo_SubEstados_Selected"
                                          placeholder="{{ 'tipo' | translate }}"
                                          [kendoDropDownFilter]="{operator: 'contains'}">
                          </kendo-combobox>
                        </div>
                    </div>
                </div>
                <!-- ACTIVO -->
                <div class="form-group">
                    <label class="crontol-label m-1">{{'activo' | translate }}</label><br>
                    <kendo-switch [onLabel]="' '" [offLabel]="' '" [(ngModel)]="activo"></kendo-switch>     
                </div>
            </div>
        </div>
        <!--BOTONES-->
        <div class="form-group d-flex justify-content-center">
            <button type="button" class="btn btn-info mr-1" (click)="clickGuardar()" [disabled]="this.user.maquinas<2">{{ 'guardar' | translate }}</button>
            <button type="button" class="btn btn-danger mr-1" (click)="clickAtras()">{{ 'atras' | translate }}</button>
        </div>
    </div>
</div>
