<div class="calendar">
    <div class="calendar-header">
        <span class="calendar-flecha" (click)="previousYear()">
            <i class="fas fa-angle-left"></i>
        </span>
        <span>
            {{year}}
        </span>
        <span class="calendar-flecha" (click)="nextYear()">
            <i class="fas fa-angle-right"></i>
        </span>
    </div>
    <div class="calendar-body">
        <div class="clearfix">
            <ng-container *ngFor="let month of monthsInYearFila;">
                <div class="col-mes">
                    <kendo-calendar [(value)]="DaysInMonths[month]"type="classic" (click)="valueClickCalendar(month, $event)">
                        <ng-template kendoCalendarMonthCellTemplate let-date>
                            <span [class.contenido-dia]="true" [class.calendario-deshabilitado]="isDateDisabled(date, month)"
                            [class.calendario-periodoInactivo]="isDateInInactive(date)" [class.calendario-reserva]="esReserva(date)"
                            [class.calendario-periodoActivo]="isDateInActive(date)" [class.calendario-periodoSeleccionado]="isDateInPeriod(date, month)">
                                {{ date.getDate() }}
                            </span>
                        </ng-template>
                    </kendo-calendar>
                </div>
            </ng-container>
        </div>
       
        <div>
            <button kendoButton id="guardarButton"   class="btn mr-1  btn-success btn-sm mr-1">{{ 'guardar' | translate}}</button>
            <button kendoButton id="cancelarButton"  class="btn mr-1  btn-danger btn-sm mr-1">{{ 'cancelar' | translate}}</button>
        </div>
       
    </div>
</div>

<kendo-dialog title="" *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center;">{{mensajeError | translate}}</p>
    <kendo-dialog-actions>
        <button kendoButton (click)="close()">{{'cerrar' | translate}}</button>
    </kendo-dialog-actions>
</kendo-dialog>
