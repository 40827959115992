import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { MenuService, NotasService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { FabricantesService } from '@app/_services';
import { AlmacenesService } from '@app/_services/almacenes.service';
import { MyFunctions } from '@app/_helpers';
import { FormControl, FormGroup } from "@angular/forms";
import { UsuariosService } from '@app/_services';

@Component({
  selector: 'app-notas-editar',
  templateUrl: './notas-editar.component.html',
})

export class NotasEditarComponent {

  //Variables basicas
  private translate: TranslateService;
  public permanente: boolean = false;
  public mostrarHastaPieza: boolean = false;
  public mostrarTodo: boolean = false;
  public mostrarRef: boolean = false;
  public idNota: number;
  public nota: any;

  //Seleciconados
  public tipoRef: any;
  public referencia: any;
  public OF: any;
  public pieza: any;
  public parte: any;
  public ruta: any;
  public operacion: any;

  //Listas
  public listaTipos: any;
  public listaReferencias: any;
  public listaOFs: any;
  public listaPiezas: any;
  public listaPartes: any;
  public listaRutas: any;
  public listaOperaciones: any;

  //Usuario
  public user = this.usuariosService.userValue;

  //Form para validar
  public formNota: FormGroup = new FormGroup({
    tipoRefForm: new FormControl(),
    referenciaForm: new FormControl(),
    OFForm: new FormControl(),
    piezaForm: new FormControl(),
    parteForm: new FormControl(),
    rutaForm: new FormControl(),
    operacionForm: new FormControl(),
    comentarioForm: new FormControl(),
  });

  //Constructor
  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    public route: ActivatedRoute,
    public fabricantesService: FabricantesService,
    public almacenesService: AlmacenesService,
    private usuariosService: UsuariosService,
    private notasService: NotasService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('editar nota').toUpperCase();
    this.idNota = this.route.snapshot.params['id'];
  }

  /**
   * Funcion ngOnInit, cargar todos los datos y selecciones
   * de los combos mediante el id enviado por la ruta
   */
  ngOnInit() {
    this.notasService.GetNotaByID(this.idNota).subscribe(
      (result) => {
        this.nota = result[0];
        console.log(this.nota);
        this.tipoRef = { "id": -1 };
        this.tipoRef.id = this.nota.referenciaTipo;
        this.permanente = this.nota.permanente;
        this.formNota.controls.comentarioForm.setValue(this.nota.comentario);
        this.mostrarHastaPieza = false;
        this.mostrarTodo = false;
        this.mostrarRef = false;
        if (this.tipoRef.id == 1 || this.tipoRef.id == 2 || this.tipoRef.id == 5) {
          this.mostrarRef = true;
          this.notasService.GetReferencia(this.tipoRef.id).subscribe(
            (result) => {
              this.listaReferencias = result;
              this.listaReferencias.forEach(r => {
                if (r.id == this.nota.idReferencia) this.referencia = r;
              });
            });
        } else if (this.tipoRef.id == 4) {
          this.mostrarHastaPieza = true;
          this.notasService.GetDatosPieza(this.nota.idReferencia).subscribe(
            (result) => {
              this.pieza = { 'id': -1, 'nombre': '' };
              this.pieza.id = result[0].idPieza;
              this.pieza.nombre = result[0].nombrePieza;
              this.OF = { 'id': -1, 'proyecto': '' };
              this.OF.id = result[0].idOF;
              this.OF.proyecto = result[0].proyecto;
              this.notasService.GetPiezasByOF(this.OF.id).subscribe(
                (result) => {
                  this.listaPiezas = result;
                });
            });
        } else if (this.tipoRef.id == 3) {
          this.mostrarTodo = true;
          this.notasService.GetDatosOperacion(this.nota.idReferencia).subscribe(
            (result) => {
              this.pieza = { 'id': -1, 'nombre': '' };
              this.pieza.id = result[0].idPieza;
              this.pieza.nombre = result[0].nombrePieza;
              this.OF = { 'id': -1, 'proyecto': '' };
              this.OF.id = result[0].idOF;
              this.OF.proyecto = result[0].proyecto;
              this.parte = { 'id': -1, 'nombre': '' };
              this.parte.id = result[0].idParte;
              this.parte.nombre = result[0].nombreParte;
              this.ruta = { 'id': -1, 'nombre': '' };
              this.ruta.id = result[0].idRuta;
              this.ruta.nombre = result[0].nombreRuta;
              this.operacion = { 'id': -1, 'nombre': '' };
              this.operacion.id = result[0].idOperacion;
              this.operacion.nombre = result[0].nombreOperacion;
              this.notasService.GetPiezasByOF(this.OF.id).subscribe(
                (result) => {
                  this.listaPiezas = result;
                });
              this.notasService.GetPartesByPieza(this.pieza.id).subscribe(
                (result) => {
                  this.listaPartes = result;
                });
              this.notasService.GetRutasByParte(this.parte.id).subscribe(
                (result) => {
                  this.listaRutas = result;
                });
              this.notasService.GetOperacionesByRuta(this.ruta.id).subscribe(
                (result) => {
                  this.listaOperaciones = result;
                });
            });
        }
      });

    this.notasService.GetTipos().subscribe(
      (result) => {
        this.listaTipos = result;
      });

    this.notasService.GetOFs().subscribe(
      (result) => {
        this.listaOFs = result;
      });
  }

  /**
   * Al clicar en el boton de Guardar, actualiza la nota en la DB
   */
  onSubmit() {
      if (this.tipoRef.id == 3) this.referencia = this.operacion;
      if (this.tipoRef.id == 4) this.referencia = this.pieza;
      this.notasService.UpdateNota(this.idNota, this.tipoRef.id, this.referencia.id, this.user.id, this.formNota.controls.comentarioForm.value, this.permanente).subscribe(
        (result) => {
          console.log("nota actualizada");
          this.router.navigate(['notas']);
        });
  }

  /**
   * Al cambiar el combo de tipo de referencia,
   * deshabilitar y resetear los combos correspondientes
   * y cargar el siguente
   * @param value valor del tipo de referencia seleccionada
   */
  tipoChange(value) {
    this.mostrarHastaPieza = false;
    this.mostrarTodo = false;
    this.mostrarRef = false;
    this.tipoRef = value;

    this.formNota.controls.referenciaForm.reset();
    this.formNota.controls.OFForm.reset();
    this.formNota.controls.piezaForm.reset();
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    if (this.tipoRef.id == 3) {
      this.formNota.controls.referenciaForm.disable();
    } else {
      this.formNota.controls.referenciaForm.enable();
    }

    this.formNota.controls.piezaForm.disable();
    this.formNota.controls.parteForm.disable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();


    this.notasService.GetReferencia(value.id).subscribe(
      (result) => {
        this.listaReferencias = result;
      });

    if (this.tipoRef.id == 4) this.mostrarHastaPieza = true;
    if (this.tipoRef.id == 3) this.mostrarTodo = true;
    if (this.tipoRef.id == 1 || this.tipoRef.id == 2 || this.tipoRef.id == 5) this.mostrarRef = true;
  }

  /**
   * Al cambiar la seleccion del combo de la referencia,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la referencia seleccionada
   */
  referenciaChange(value) {
    this.referencia = value;

    this.formNota.controls.OFForm.reset();
    this.formNota.controls.piezaForm.reset();
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.piezaForm.disable();
    this.formNota.controls.parteForm.disable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();
  }

  /**
   * Al cambiar la seleccion del combo de OF,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de OF seleccionado
   */
  OFChange(value) {
    this.OF = value;
    this.formNota.controls.piezaForm.reset();
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.piezaForm.enable();
    this.formNota.controls.parteForm.disable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();

    this.notasService.GetPiezasByOF(value.id).subscribe(
      (result) => {
        this.listaPiezas = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de pieza,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la pieza seleccionada
   */
  piezaChange(value) {
    this.pieza = value;
    this.formNota.controls.parteForm.reset();
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.parteForm.enable();
    this.formNota.controls.rutaForm.disable();
    this.formNota.controls.operacionForm.disable();

    this.notasService.GetPartesByPieza(value.id).subscribe(
      (result) => {
        this.listaPartes = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de parte,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguente
   * @param value valor de la parte seleccionada
   */
  parteChange(value) {
    this.parte = value;
    this.formNota.controls.rutaForm.reset();
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.rutaForm.enable();
    this.formNota.controls.operacionForm.disable();

    this.notasService.GetRutasByParte(value.id).subscribe(
      (result) => {
        this.listaRutas = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de ruta,
   * deshabilitar y resetear los combos correspondientes
   * actualizando el siguiente
   * @param value valor de la ruta seleccionada
   */
  rutaChange(value) {
    this.ruta = value;
    this.formNota.controls.operacionForm.reset();

    this.formNota.controls.operacionForm.enable();

    this.notasService.GetOperacionesByRuta(value.id).subscribe(
      (result) => {
        this.listaOperaciones = result;
      });
  }

  /**
   * Al cambiar la seleccion del combo de operacion,
   * actualizar el valor de la variable
   * @param value valor de la operacion seleccionada
   */
  operacionChange(value) {
    this.operacion = value;
  }
}