import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/informespersonalizadosdisenador`;

@Injectable()
export class InformesPersonalizadosDisenadorService {

  constructor(private http: HttpClient) { }

  public Get_Informes() {
    return this.http.post<JSON>(`${baseUrl}/Get_Informes/`, {}, { withCredentials: true });
  }

  public Get_TiposGrafico() {
    return this.http.post<JSON>(`${baseUrl}/Get_TiposGrafico/`, {}, { withCredentials: true });
  }

  public Insert_Informe(lineas: any) {
    console.log(lineas);
    return this.http.post<JSON>(`${baseUrl}/Insert_Informe/`, { lineas }, { withCredentials: true });
  }

  public Update_Informe(lineas: any, lineasInsert: any, lineasUpdate: any, lineasDelete: any, lineasOriginales: any) {
    return this.http.post<JSON>(`${baseUrl}/Update_Informe/`, { lineas, lineasInsert, lineasUpdate, lineasDelete, lineasOriginales }, { withCredentials: true });
  }

  public Delete_Informe(ids: any) {
    return this.http.post<JSON>(`${baseUrl}/Delete_Informe/`, { ids }, { withCredentials: true });
  }

  public Get_OrigenDatosByTipoModuloId(id: number): Observable<any> {
    return this.http.get(`${baseUrl}/Get_OrigenDatosByTipoModuloId/${id}`);
  }

  public Get_LineasByIdInforme(id: number): Observable<any> {
    return this.http.get(`${baseUrl}/Get_LineasByIdInforme/${id}`);
  }

  public Get_ModulosByIDs(ids: any) {
    return this.http.post<JSON>(`${baseUrl}/Get_ModulosByIDs/`, { ids }, { withCredentials: true });
  }
  public Get_TipoColumnaByTipoDato(id: number): Observable<any> {
    return this.http.get(`${baseUrl}/Get_TipoColumnaByTipoDato/${ id }`);
  }
}
