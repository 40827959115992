<style>
    .k-text-error {
        display: none;
    }

    :host /deep/ .k-grid tbody td {
        white-space: nowrap;
        line-height: 20px;
        padding: 8px 12px;
    }

    :host /deep/ .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<!-- DROPDOWNS -->
<div class="row mb-2">
    <!-- LEFT -->
    <div class="col">
        <div class="row m-2">
            <div class="col-md-2">
                <label class="crontol-label m-1 float-right">{{ 'moverde' | translate }}</label>
            </div>
            <div class="col-md-8">
                <kendo-combobox [data]="listaAlmacenesLeft" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="almacenLeft" [kendoDropDownFilter]="{operator: 'contains'}"
                    (valueChange)="valueChangeLeft($event)" [clearButton]="false">
                </kendo-combobox>
            </div>
        </div>
    </div>

    <!-- SWAP -->
    <div class="col-1 d-flex">
        <button kendoButton (click)="onClickSwap()" style="height: 50px; transform: rotate(-90deg); margin: auto;">
            <span class="k-icon k-i-arrows-swap k-icon-md"></span>
        </button>
    </div>

    <!-- RIGHT -->
    <div class="col">
        <div class="row m-2">
            <div class="col-md-2">
                <label class="crontol-label m-1 float-right">{{ 'movera' | translate }}</label>
            </div>
            <div class="col-md-8">
                <kendo-combobox [data]="listaAlmacenesRight" [textField]="'nombre'" [valueField]="'id'"
                    [(value)]="almacenRight" [kendoDropDownFilter]="{operator: 'contains'}"
                    (valueChange)="valueChangeRight($event)" [clearButton]="false">
                </kendo-combobox>
            </div>
        </div>
    </div>
</div>

<!-- GRIDS -->
<div class="row">
    <!-- GRID LEFT -->
    <div class="col">
        <kendo-grid 
            [kendoGridBinding]="consumiblesLeft" 
            [sortable]="true" 
            [navigable]="true"
            [selectable]="{enabled: true, mode: 'single'}" 
            filterable="menu" 
            [height]="800" 
            [pageSize]="999"
            scrollable="scrollable" 
            kendoGridSelectBy="index" 
            [selectedKeys]="seleccionadosLeft"
            (selectionChange)="selectionChangeLeft($event)" 
            [rowClass]="rowCallbackLeft">

            <!-- CHECKBOX -->
            <kendo-grid-checkbox-column width="5%">
                <ng-template kendoGridHeaderTemplate>
                    <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                        (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,consumiblesAlta, 'id')">
                    <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
            </kendo-grid-checkbox-column>
            <!-- POSICIÓN -->
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="posicion"
                title="{{ 'posicion' | translate}}" *ngIf="tienePosicionesLeft"></kendo-grid-column>
            <!-- REFERENCIA -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="referenciaHerramienta"
            title="{{ 'referencia' | translate}}" *ngIf="tienePosicionesLeft"></kendo-grid-column>
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="referencia"
            title="{{ 'referencia' | translate}}" *ngIf="!tienePosicionesLeft"></kendo-grid-column>
            <!-- NOMBRE -->
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="herramienta"
                title="{{ 'nombre' | translate}}" *ngIf="tienePosicionesLeft"></kendo-grid-column>
            <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="nombre"
                title="{{ 'nombre' | translate}}" *ngIf="!tienePosicionesLeft"></kendo-grid-column>
            <!-- nSerie -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="nSerie"
                title="{{ 'nserie' | translate}}"></kendo-grid-column>
            <!-- TIPO -->
            <kendo-grid-column width="13%" [style]="{'text-align': 'left'}" field="tipoHerramienta"
                title="{{ 'tipoConsumible' | translate}}" *ngIf="tienePosicionesLeft"></kendo-grid-column>
            <kendo-grid-column width="13%" [style]="{'text-align': 'left'}" field="nombreT"
                title="{{ 'tipoConsumible' | translate}}" *ngIf="!tienePosicionesLeft"></kendo-grid-column>
            <!-- CANTIDAD -->
            <kendo-grid-column width="8%" [style]="{'text-align': 'right'}" field="cantidad"
                title="{{ 'cantidad' | translate}}" *ngIf="!tienePosicionesLeft"></kendo-grid-column>
            <!-- TIEMPO USO -->
            <kendo-grid-column width="11%" [style]="{'text-align': 'right'}" field="vidaUtil"
                title="{{ 'tiempouso' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <ngcontainer *ngIf="dataItem.tieneNserie"> 
                        {{ secondsToHm(dataItem.tUsado) }} / {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    <ngcontainer *ngIf="!dataItem.tieneNserie"> {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                </ng-template>
            </kendo-grid-column>
            <!-- CANTIDAD MOVER -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'right'}" title="{{ 'cantidadMover' | translate}}"
                *ngIf="!tienePosicionesLeft" editor="numeric">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <kendo-numerictextbox [(ngModel)]="dataItem.cantidadMover" [min]="1" [max]="dataItem.cantidad"
                        [autoCorrect]="true" [format]="'n'">
                    </kendo-numerictextbox>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
    </div>

    <!-- BOTÓN -->
    <div class="col-md-auto text-center" style="height: 800px;">
        <button kendoButton (click)="onClickMover(hijos)" style="height: 100px; margin-top: 350px;">
            <span class="k-icon k-i-arrow-chevron-right k-icon-md"></span>
        </button>
    </div>

    <!-- GRID RIGHT -->
    <div class="col">
        <kendo-grid 
            [kendoGridBinding]="consumiblesRight" 
            [sortable]="true" 
            [navigable]="true"
            [selectable]="{enabled: true, mode: 'single'}" 
            filterable="menu" 
            [height]="800" 
            [pageSize]="999"
            scrollable="scrollable" 
            kendoGridSelectBy="index" 
            [selectedKeys]="seleccionadosRight"
            (selectionChange)="selectionChangeRight($event)" 
            [rowClass]="rowCallbackRight">

            <!-- CHECKBOX -->
            <kendo-grid-checkbox-column width="5%">
                <ng-template kendoGridHeaderTemplate>
                    <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                        (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,consumiblesAlta, 'id')">
                    <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                </ng-template>
            </kendo-grid-checkbox-column>
            <!-- POSICIÓN -->
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="posicion"
                title="{{ 'posicion' | translate}}" *ngIf="tienePosicionesRight"></kendo-grid-column>
            <!-- REFERENCIA -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="referenciaHerramienta"
            title="{{ 'referencia' | translate}}" *ngIf="tienePosicionesRight"></kendo-grid-column>
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="referencia"
            title="{{ 'referencia' | translate}}" *ngIf="!tienePosicionesRight"></kendo-grid-column>
            <!-- NOMBRE -->
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="herramienta"
                title="{{ 'nombre' | translate}}" *ngIf="tienePosicionesRight"></kendo-grid-column>
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="nombre"
                title="{{ 'nombre' | translate}}" *ngIf="!tienePosicionesRight"></kendo-grid-column>
            <!-- nSerie -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'left'}" field="nSerie"
                title="{{ 'nserie' | translate}}"></kendo-grid-column>
            <!-- TIPO -->
            <kendo-grid-column width="13%" [style]="{'text-align': 'left'}" field="tipoHerramienta"
                title="{{ 'tipoConsumible' | translate}}" *ngIf="tienePosicionesRight"></kendo-grid-column>
            <kendo-grid-column width="13%" [style]="{'text-align': 'left'}" field="nombreT"
                title="{{ 'tipoConsumible' | translate}}" *ngIf="!tienePosicionesRight"></kendo-grid-column>
            <!-- CANTIDAD -->
            <kendo-grid-column width="10%" [style]="{'text-align': 'right'}" field="cantidad"
                title="{{ 'cantidad' | translate}}" *ngIf="!tienePosicionesRight"></kendo-grid-column>
            <!-- TIEMPO USO -->
            <kendo-grid-column width="11%" [style]="{'text-align': 'right'}" field="vidaUtil" title="{{ 'tiempouso' | translate}}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <ngcontainer *ngIf="dataItem.tieneNserie"> 
                        {{ secondsToHm(dataItem.tUsado) }} / {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                    <ngcontainer *ngIf="!dataItem.tieneNserie"> {{ secondsToHm(dataItem.vidaUtil) }} </ngcontainer>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
    </div>
</div>

<!-- MODAL: Mover Hijos-->
<ng-template #hijos let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ "tienehijosmover" | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="moverConsumible()">
            {{ "nomoverhijos" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="moverHijos()">
            {{ "simoverhijos" | translate }}
        </button>
    </div>
</ng-template>