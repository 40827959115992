import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'app-visorConsumibles',
  templateUrl: 'vistaConsumibles.component.html'
})

export class VistaConsumibles {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //FILTRO
  public consumiblesTiposSelecteds: any[] = [];
  public consumiblesTipos: any;

  //GRID: consumibles
  public consumiblesTODOS: any;
  public consumibles: any;
  public seleccionados: number[] = [];

  //GRID: consumibles NSERIE
  public consumiblesTODOS_NSERIE: any;
  public consumibles_NSERIE: any;
  public seleccionados_NSERIE: number[] = [];

  //POPUP: Eliminar
  public popupEliminar: boolean = false;
  public popupErrorAlEliminar: boolean = false;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService,
    public router: Router,) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('visorConsumibles').toUpperCase();
  }

  ngOnInit() {
    this.cargarCombos();
  }
  cargarCombos() {
    this.loadingPanel = true;
    this.consumiblesService.Get_consumiblesTipos().subscribe(
      (json) => {
        this.consumiblesTipos = json;
        this.consumiblesTiposSelecteds = [];
        this.cargarDatos();
      });
  }
  cargarDatos() {
    this.loadingPanel = true;
    var r1, r2: boolean = false;
    this.consumiblesService.Get_consumibles_grid_by_tipo(0).subscribe(
      (json) => {
        this.consumiblesTODOS = json;
        this.consumibles = json;
        r1 = true;
        if (r1 && r2)
          this.cargarDatos_completarTabla();
      });
    this.consumiblesService.Get_historico_consumibles_grid_by_consumible(0).subscribe(
      (json) => {
        this.consumiblesTODOS_NSERIE = json;
        this.consumibles_NSERIE = json;
        r2 = true;
        if (r1 && r2)
          this.cargarDatos_completarTabla();

      });
  }
  cargarDatos_completarTabla() {
    var an: any = this.consumiblesTODOS;

    an.forEach(
      (row) => {
        row.nSeries = this.consumiblesTODOS_NSERIE.filter(f => f.idConsumible == row.id);
        row.cantidad = row.nSeries.length;
      });

    this.consumibles = this.consumiblesTODOS;

    this.loadingPanel = false;
  }

  filtrar() {
    var an: any = this.consumiblesTiposSelecteds;
    var idsConsumiblesTipos: any = [];
    an.forEach(
      (row) => {
        idsConsumiblesTipos.push(row.id);
      });

    this.consumibles = this.consumiblesTODOS.filter(f => idsConsumiblesTipos.includes(f.idConsumibles_tipos) || idsConsumiblesTipos.length == 0);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['historicoConsumibleTipo/', this.seleccionados[0]]);
    }
  }
  cellClick(e, radgrid) {
    var url = '';
    if (radgrid == 1) {
        url = this.router.serializeUrl(this.router.parseUrl('#/historicoConsumible/' + e.dataItem.idConsumibles_tipos + '/' + e.dataItem.id));      
    } else {
      url = this.router.serializeUrl(this.router.parseUrl('#/historicoConsumibleNserie/' + e.dataItem.idConsumibles_tipos + '/' + e.dataItem.idConsumible + '/' + e.dataItem.id));
    }
    window.open(url, '_blank');
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
}
