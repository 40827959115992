import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MenuService, Eskola_AsignaturasRetosService, UsuariosService } from '@app/_services';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { State } from '@progress/kendo-data-query';
import { first } from 'rxjs/operators';
import { RowClassArgs, CellClickEvent } from '@progress/kendo-angular-grid';

@Component({
    selector: 'app-asignaturasRetosDetalle',
  templateUrl: './eskola_asignaturasRetosDetalle.component.html'
  })
export class Eskola_AsignaturasRetosDetalleComponent implements OnInit {

  navigationSubscription;

  private translate: TranslateService;

  form: FormGroup;
  public id: number;
  public idDocente: number;
  public idAsignatura: number;
  public nombreAsignatura: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;

  tipoOeeLista: any;
  tipoOeeSeleccionado: any;
  user = this.userService.userValue;

  public activo : boolean;

  public docentes: any = [];
  public docentesList: any = [];
  public listaDocentesOriginal: any = [];
  public asignaturasRetos: any = [];
  mySelection: number[] = [];
  mySelectionAux: number[] = [];
  asignaturaReto: any;
  public gridData: any;

  public fromAllDocentesSelect: boolean= false;
  public buttonAllDocentesSelectName: string='';

  //Variables de validacion
  errorNombreVacio: boolean;

  constructor(
      private asignaturasRetosService: Eskola_AsignaturasRetosService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private translateService: TranslateService,
      private alertService: AlertService,
      private userService: UsuariosService,
      private menuService: MenuService
  ) {
  
      this.menuService.titulo = this.translateService.instant('asignaturasretos').toUpperCase();
      //this.cargarDatos()
      
  }

  ngOnInit() {
      //Inicializacion variables
      this.errorNombreVacio = false;
      //Creamos objeto docentes auxiliar
      this.asignaturaReto = {id: -1, nombre: '', docentes: '', activo: false, colores: ''};
      this.mySelection = []
      this.mySelectionAux = []
      this.id = this.route.snapshot.params['id'];
      this.isAddMode = !this.id;
  
      this.idAsignatura = this.route.snapshot.params['idAsignatura'];
      this.nombreAsignatura = this.route.snapshot.params['nombreAsignatura'];

      this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: new FormControl('', [Validators.required, Validators.maxLength(200)]),
          docentes: new FormControl('', [Validators.required, Validators.maxLength(200)]),
          activo: [false,],
      });      

      if (!this.isAddMode) {
          this.asignaturasRetosService.getById(this.id).subscribe(json => {
            this.asignaturasRetos = json;
            this.asignaturaReto = this.asignaturasRetos[0];
            console.table(json);
          });
        }
      this.cargarDatos();
      
  }
  cargarDatos(){
    // Lista de docentes
    this.asignaturasRetosService.GetAll_Docentes().subscribe(json => {
      this.docentesList = json;
      //console.table(json);
    });
    this.asignaturasRetosService.GetAll_datosDocentes().subscribe(json => {
      this.docentes = json;
      //console.table(json);
      this.selectDocentes();
      
      //Guardar lista de docentes original para compararla con el update
      
    });
  }

  selectDocentes() { //función para obtener la seleccion de docentes
    this.mySelection = [];
    this.mySelectionAux = [];
    for (var docente of this.docentes) {
      if(docente.idAsignatura != null) {
        if (docente.idAsignatura == this.id) {
          this.mySelection.push(docente.idDocente);
        }
      }
        
    }
    //this.mySelectionAux=this.mySelection;   //erreferentzia
    this.mySelectionAux = Object.assign([], this.mySelection); //kopia
    this.listaDocentesOriginal = Object.assign([], this.mySelection);

    if (this.docentesList.length > this.mySelectionAux.length){
      this.buttonAllDocentesSelectName=this.translateService.instant('seleccionarTodo');
    }      
    else {
      this.buttonAllDocentesSelectName=this.translateService.instant('deseleccionarTodo');
    }
    
  }
  
    public rowCallback(context: RowClassArgs) {
      return {
        dragging: context.dataItem.dragging
      };
    }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
      this.submitted = true;
  
      // reset alerts on submit
      this.alertService.clear();
  
      //form text validator
      if (this.asignaturaReto.nombre == null || this.asignaturaReto.nombre == undefined || this.asignaturaReto.nombre == '') {
          this.errorNombreVacio = true;
          return;
      }

/*         // stop here if form is invalid
      if (this.form.invalid) {
          return;
      } */
  
      this.loading = true;
      if (this.isAddMode) {
          this.crearAsignaturaReto();
      } else {
          this.updateAsignaturaReto();
      }

      
  
  } 

  nombreChange(e){
      this.asignaturaReto.nombre=e.target.value;
  }

  private crearAsignaturaReto() {
    debugger;
    let docentes: string = "";
      if(this.mySelection.length > 0){
        docentes = this.mySelection.toString();
      }
      this.asignaturasRetosService.create(-1, this.asignaturaReto.nombre, docentes, this.asignaturaReto.activo)
      .pipe(first())
      .subscribe({
          next: () => {
          debugger;
          this.alertService.success(this.translateService.instant('creadocorrectamente'), { keepAfterRouteChange: true });
          this.router.navigate(['eskola_asignaturasretos/']);
      },
      error: error => {
        debugger;
        this.alertService.error(error);
        this.loading = false;
      }
    });
    //}else{
    //  this.alertService.error(this.translateService.instant('errorseleccionardocente'), { keepAfterRouteChange: true });
    //  this.loading = false;
    //}
  }

  private updateAsignaturaReto() {
    this.mySelectionAux;
    const docentesDelete = [];
    const docentesInsert = this.mySelection;
    var cont = 0;

    for (let i = 0; i < this.listaDocentesOriginal.length; i++) {
      cont = 0;
      for (let j = 0; j < this.mySelection.length; j++) {
        if (this.listaDocentesOriginal[i] == docentesInsert[j]) {
          docentesInsert.splice(j, 1);
          cont ++;
        }
      } 
      if(cont == 0){docentesDelete.push(this.listaDocentesOriginal[i]);}
    }
    this.asignaturasRetosService.update(this.asignaturaReto.id, this.asignaturaReto.nombre, docentesDelete.toString(), docentesInsert.toString(),  this.asignaturaReto.activo).subscribe({
        next: () => {
            this.alertService.success(this.translateService.instant('editadocorrectamente'), { keepAfterRouteChange: true });
        this.router.navigate(['eskola_asignaturasretos/']);
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });
  }

  public cellClickHandler(e: CellClickEvent): void 
  {
      if (this.mySelectionAux.includes(e.dataItem.docentesID)) 
      {
          //remove id from myselection
          const index = this.mySelectionAux.indexOf(e.dataItem.docentesID, 0);
          if (index > -1) {
          this.mySelectionAux.splice(index, 1);
          }
      }
    else 
    {
      this.mySelectionAux.push(e.dataItem.docentesID)
    }
    this.mySelection = Object.assign([], this.mySelectionAux);
    console.table(this.mySelection);
  }

  public checkAllDocentes() {
    if (this.docentesList.length <= this.mySelectionAux.length)
    {
      this.mySelectionAux=[];
      this.buttonAllDocentesSelectName=this.translateService.instant('seleccionarTodo');
    }
    else
    {
      this.mySelectionAux=[];
      this.docentesList.forEach(d => {
        //f.seleccionado = true;
        this.mySelectionAux.push(d.docentesID);
      });
      this.buttonAllDocentesSelectName=this.translateService.instant('deseleccionarTodo');
    }
    //this.mySelectionAux=this.mySelection;
    this.mySelection = Object.assign([], this.mySelectionAux);
  }

}
