<div class="calendar">
    <div class="calendar-header">
        <span class="calendar-flecha" (click)="previousYear()">
            <i class="fas fa-angle-left"></i>
        </span>
        <span>
            {{year}}
        </span>
        <span class="calendar-flecha" (click)="nextYear()">
            <i class="fas fa-angle-right"></i>
        </span>
    </div>
    <div class="calendar-body">
        <div class="clearfix">
            <ng-container *ngFor="let month of monthsInYearFila;">
                <div class="col-mes">
                    <kendo-calendar [(value)]="DaysInMonths[month]"type="classic" (click)="valueClickCalendar(month, $event)">
                        <ng-template kendoCalendarMonthCellTemplate let-date>
                           <!-- <span [class.contenido-dia]="true" 
                                  [class.calendario-reserva]="esReserva(date)">
                                {{ date.getDate() }}
                            </span>-->
                          <span>{{ date.getDate() }}</span>
                          <div *ngIf="esReserva(date).reserva">
                            <ng-container *ngFor="let r of esReserva(date).reservas">
                                <span  [ngStyle]="{'color': r.colorGrupo}">•</span>
                            </ng-container>
                        </div>
                             
                            
                        </ng-template>
                    </kendo-calendar>
                </div>
            </ng-container>
        </div>
       
       
    </div>
</div>

