import { Component } from '@angular/core';
import { Router } from "@angular/router"
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { FabricantesService } from '@app/_services';
import { AlmacenesService } from '@app/_services/almacenes.service';
import { MyFunctions } from '@app/_helpers';
import { UsuariosService } from '@app/_services';

@Component({
  selector: 'app-movimientos-consumibles',
  templateUrl: './movimientos-consumibles.component.html',
})

export class MovimientosConsumiblesComponent {

  //VARIABLES BASICAS
  private translate: TranslateService;

  //Booleanos
  tienePosicionesLeft: boolean = false;
  tienePosicionesRight: boolean = false;

  //Modals
  modalReference: NgbModalRef;

  //Listas
  public listaAlmacenesLeft: any;
  public listaAlmacenesRight: any;
  public listaAlmacenesLeftOriginal: any;
  public listaAlmacenesRightOriginal: any;
  public consumiblesLeft: any;
  public consumiblesRight: any;
  public seleccionadosLeft: any[] = [];
  public seleccionadosRight: any[] = [];

  //Almacenes
  public almacenLeft: any;
  public almacenRight: any;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public route: ActivatedRoute,
    public fabricantesService: FabricantesService,
    public almacenesService: AlmacenesService,
    private usuariosService: UsuariosService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('Movimientos Consumibles').toUpperCase();
  }

  /**
   * Funcion OnInit
   */
  ngOnInit() {
    this.almacenesService.GetAll().subscribe(
      (result) => {
        this.listaAlmacenesLeftOriginal = result.data;
        this.listaAlmacenesRightOriginal = result.data;
        this.listaAlmacenesLeft = this.listaAlmacenesLeftOriginal;
        this.listaAlmacenesRight = this.listaAlmacenesRightOriginal;
      });
  }

  /**
   * Al clicar en el boton de mover, proceder a mover
   * @param hijos modal por si tiene hijos
   */
  onClickMover(hijos) {
    if (this.seleccionadosLeft.length < 1) {
      this.alertService.warn(this.translateService.instant('seleccionaconsumible'), { keepAfterRouteChange: true });
    } else if (this.tienePosicionesRight && this.seleccionadosRight.length < 1) {
      this.alertService.warn(this.translateService.instant('seleccioneposicion'), { keepAfterRouteChange: true });
    } else {
      if (this.consumiblesLeft[this.seleccionadosLeft[0]].asignado1 > 0) {
        this.modalReference = this.modalService.open(hijos, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      } else {
        this.moverConsumible();
      }
    }
  }

  /**
   * Al clicar en el boton de intercambiar almacenes,
   * cambiar tanto grids como combos
   */
  onClickSwap() {
    this.seleccionadosLeft = [];
    this.seleccionadosRight = [];
    //Swap listas almacenes
    var auxListaAlmacenes = this.listaAlmacenesLeft;
    this.listaAlmacenesLeft = this.listaAlmacenesRight;
    this.listaAlmacenesRight = auxListaAlmacenes;
    //Swap almacenes seleccionados
    var auxAlmacen = this.almacenLeft;
    this.almacenLeft = this.almacenRight;
    this.almacenRight = auxAlmacen;
    //Swap consumibles
    var auxConsumibles = this.consumiblesLeft;
    this.consumiblesLeft = this.consumiblesRight;
    this.consumiblesRight = auxConsumibles;
    //Swap tienen posiciones
    var auxTienePosiciones = this.tienePosicionesLeft;
    this.tienePosicionesLeft = this.tienePosicionesRight;
    this.tienePosicionesRight = auxTienePosiciones;
    //Resetear cantidad a mover
    this.consumiblesLeft.forEach((c, i) => {
      c.cantidadMover = 1;
    });
  }

  /**
   * Al cambiar la seleccion en el grid
   * de la izquierda, resetear cantidad mover
   * @param e evento de seleccion/deseleccion
   */
  selectionChangeLeft(e: any): void {
    this.consumiblesLeft.forEach((c, i) => {
      if (e.selectedRows[0].index != i) c.cantidadMover = 1;
    });
  }

  /**
   * Al cambiar la seleccion en el grid
   * de la derecha, solo para control
   * @param e evento de seleccion/deselecicon
   */
  selectionChangeRight(e: any): void {
    console.log(this.seleccionadosRight);
  }

  /**
   * Para determinar si la fila del grid de la
   * derecha tiene que ser deshabilitada
   * @param args fila actual
   * @returns la clase para la fila
   */
  rowCallbackRight(args: any) {
    return { 'k-state-disabled': args.dataItem.nSerie != '-' || args.dataItem.herramienta != '-' || args.dataItem.tipoHerramienta != '-' };
  }

  /**
   * Para determinar si la fila del grid de la
   * izquierda tiene que ser deshabilitada
   * @param args fila actual
   * @returns la clase para la fila
   */
  rowCallbackLeft(args: any) {
    return { 'k-state-disabled': args.dataItem.nSerie == '-' && args.dataItem.herramienta == '-' && args.dataItem.tipoHerramienta == '-' };
  }

  /**
   * Al cambiar el almacen seleccionado en el combo
   * de la izquierda, cargar los elementos correspondientes
   * al almacen
   * @param value almacen seleccionado 
   */
  valueChangeLeft(value: any) {
    this.seleccionadosLeft = [];
    this.listaAlmacenesRight = [];
    this.listaAlmacenesRightOriginal.forEach(a => {
      if (a.id != value.id) this.listaAlmacenesRight.push(a);
    });
    console.log("______________________________________________________________")
    console.log(value)
    if (value.tienePosiciones) {
      console.log("tiene posiciones")
      this.consumiblesService.Get_almacenes_con_consumibles_activos(value.id).subscribe(
        (json) => {
          this.consumiblesLeft = json['consumiblesActivos'];
          this.tienePosicionesLeft = true;
          this.consumiblesLeft.forEach((c, i) => {
            c.index = i;
          });
        });
    } else {
      console.log("NO tiene posiciones")
      this.consumiblesService.Get_consumibles_alta_by_IdAlmacen(value.id).subscribe(
        (json) => {
          this.consumiblesLeft = json;
          this.tienePosicionesLeft = false;
          this.consumiblesLeft.forEach((c, i) => {
            c.index = i;
            c.cantidadMover = 1;
          });
        });
    }
  }

  /**
   * Al cambiar el almacen seleccionado en el combo
   * de la derecha, cargar los elementos correspondientes
   * al almacen
   * @param value almacen seleccionado 
   */
  valueChangeRight(value: any) {
    this.seleccionadosRight = [];
    this.listaAlmacenesLeft = [];
    this.listaAlmacenesLeftOriginal.forEach(a => {
      if (a.id != value.id) this.listaAlmacenesLeft.push(a);
    });
    if (value.tienePosiciones) {
      this.consumiblesService.Get_almacenes_con_consumibles_activos(value.id).subscribe(
        (json) => {
          this.consumiblesRight = json['consumiblesActivos'];
          this.tienePosicionesRight = true;
          this.consumiblesRight.forEach((c, i) => {
            c.index = i;
          });
        });
    } else {
      this.consumiblesService.Get_consumibles_alta_by_IdAlmacen(value.id).subscribe(
        (json) => {
          this.consumiblesRight = json;
          this.tienePosicionesRight = false;
          this.consumiblesRight.forEach((c, i) => {
            c.index = i;
          });
        });
    }
  }

  /**
   * Para mover el consumible padre, tenga o no hijos
   */
  moverConsumible() {
    this.modalService.dismissAll();
    var ids: number[] = [];
    var posicion: number = -1;
    //Fijar posicion
    if (this.tienePosicionesRight) {
      posicion = this.consumiblesRight[this.seleccionadosRight[0]].posicion;
    } else {
      posicion = -1;
    }
    //Si tiene nSerie
    if (this.consumiblesLeft[this.seleccionadosLeft[0]].nSerie != '' && this.consumiblesLeft[this.seleccionadosLeft[0]].nSerie != '-') {
      var auxId = ((!this.tienePosicionesLeft) ? this.consumiblesLeft[this.seleccionadosLeft[0]].id : this.consumiblesLeft[this.seleccionadosLeft[0]].idHistorico_Consumibles)
      ids.push(auxId);
      //Moverlo
      this.consumiblesService.Mover_historico_consumibles(ids, this.almacenRight.id, posicion).subscribe(
        (result) => {
          this.alertService.success(this.translateService.instant('movercorrecto'), { keepAfterRouteChange: true });
          //Actualizar lista left
          if (this.almacenLeft.tienePosiciones) {
            this.consumiblesService.Get_almacenes_con_consumibles_activos(this.almacenLeft.id).subscribe(
              (json) => {
                this.consumiblesLeft = json['consumiblesActivos'];
                this.tienePosicionesLeft = true;
                this.consumiblesLeft.forEach((c, i) => {
                  c.index = i;
                });
              });
          } else {
            this.consumiblesService.Get_consumibles_alta_by_IdAlmacen(this.almacenLeft.id).subscribe(
              (json) => {
                this.consumiblesLeft = json;
                this.tienePosicionesLeft = false;
                this.consumiblesLeft.forEach((c, i) => {
                  c.index = i;
                });
              });
          }
          //Actualizar lista right
          if (this.almacenRight.tienePosiciones) {
            this.consumiblesService.Get_almacenes_con_consumibles_activos(this.almacenRight.id).subscribe(
              (json) => {
                this.consumiblesRight = json['consumiblesActivos'];
                this.tienePosicionesRight = true;
                this.consumiblesRight.forEach((c, i) => {
                  c.index = i;
                });
              });
          } else {
            this.consumiblesService.Get_consumibles_alta_by_IdAlmacen(this.almacenRight.id).subscribe(
              (json) => {
                this.consumiblesRight = json;
                this.tienePosicionesRight = false;
                this.consumiblesRight.forEach((c, i) => {
                  c.index = i;
                });
              });
          }
          this.seleccionadosLeft = [];
          this.seleccionadosRight = [];
        });
    } else {
      if (this.consumiblesLeft[this.seleccionadosLeft[0]].cantidadMover > 1 && this.tienePosicionesRight) {
        this.alertService.error(this.translateService.instant('masdeunoaposicion'), { keepAfterRouteChange: true });
      } else {
        var auxId = ((!this.tienePosicionesLeft) ? this.consumiblesLeft[this.seleccionadosLeft[0]].id : this.consumiblesLeft[this.seleccionadosLeft[0]].idHistorico_Consumibles)
        var auxCantidad = ((!this.tienePosicionesLeft) ? this.consumiblesLeft[this.seleccionadosLeft[0]].cantidadMover : 1)
        this.consumiblesService.Get_consumible_activo_by_idHistoricoConsumibles(auxId).subscribe(
          (consumible) => {
            this.consumiblesService.Get_consumibles_ids(auxCantidad, consumible[0].idComsumible, this.almacenLeft.id).subscribe(
              (json) => {
                ids = json.map(function (x) {
                  return x.id
                });
                this.consumiblesService.Mover_historico_consumibles(ids, this.almacenRight.id, posicion).subscribe(
                  (result) => {
                    this.alertService.success(this.translateService.instant('movercorrecto'), { keepAfterRouteChange: true });
                    //Actualizar lista left
                    if (this.almacenLeft.tienePosiciones) {
                      this.consumiblesService.Get_almacenes_con_consumibles_activos(this.almacenLeft.id).subscribe(
                        (json) => {
                          this.consumiblesLeft = json['consumiblesActivos'];
                          this.tienePosicionesLeft = true;
                          this.consumiblesLeft.forEach((c, i) => {
                            c.index = i;
                          });
                        });
                    } else {
                      this.consumiblesService.Get_consumibles_alta_by_IdAlmacen(this.almacenLeft.id).subscribe(
                        (json) => {
                          this.consumiblesLeft = json;
                          this.tienePosicionesLeft = false;
                          this.consumiblesLeft.forEach((c, i) => {
                            c.index = i;
                          });
                        });
                    }
                    //Actualizar lista right
                    if (this.almacenRight.tienePosiciones) {
                      this.consumiblesService.Get_almacenes_con_consumibles_activos(this.almacenRight.id).subscribe(
                        (json) => {
                          this.consumiblesRight = json['consumiblesActivos'];
                          this.tienePosicionesRight = true;
                          this.consumiblesRight.forEach((c, i) => {
                            c.index = i;
                          });
                        });
                    } else {
                      this.consumiblesService.Get_consumibles_alta_by_IdAlmacen(this.almacenRight.id).subscribe(
                        (json) => {
                          this.consumiblesRight = json;
                          this.tienePosicionesRight = false;
                          this.consumiblesRight.forEach((c, i) => {
                            c.index = i;
                          });
                        });
                    }
                    this.seleccionadosLeft = [];
                    this.seleccionadosRight = [];
                  });
              });
          });
      }
    }
  }

  /**
   * Para mover los hijos del consumible seleccionado,
   * solo en el caso de confirmar esta accion en el 
   * modal de mover hijos
   */
  moverHijos() {
    if (this.consumiblesLeft[this.seleccionadosLeft[0]].nSerie != '' && this.consumiblesLeft[this.seleccionadosLeft[0]].nSerie != '-') {
      var auxId = ((!this.tienePosicionesLeft) ? this.consumiblesLeft[this.seleccionadosLeft[0]].id : this.consumiblesLeft[this.seleccionadosLeft[0]].idHistorico_Consumibles)
      this.consumiblesService.get_ids_hijos_activos(auxId).subscribe(
        (result) => {
          result.forEach(s => {
            this.consumiblesService.Mover_A_Almacen_Hijos(s.id, this.almacenRight.id).subscribe(
              (result) => {
                console.log("Hijos movidos correctamente: " + s);
                this.modalService.dismissAll();
              });
          });
        });
      this.moverConsumible();
    } else {
      var auxCantidad = ((!this.tienePosicionesLeft) ? this.consumiblesLeft[this.seleccionadosLeft[0]].cantidadMover : 1)
      this.consumiblesService.Get_consumible_activo_by_idHistoricoConsumibles(auxId).subscribe(
        (consumible) => {
          this.consumiblesService.Get_consumibles_ids(auxCantidad, consumible[0].idComsumible, this.almacenLeft.id).subscribe(
            (json) => {
              var ids: number[] = [];
              ids = json.map(function (x) {
                return x.id
              });
              ids.forEach(id => {
                this.consumiblesService.Mover_A_Almacen_Hijos(id, this.almacenRight.id).subscribe(
                  (result) => {
                    console.log("Hijos movidos correctamente: " + id);
                    this.modalService.dismissAll();
                    this.moverConsumible();
                  });
              });
            });
        });
    }
  }

  /**
   * Convertir la cantidad de segundos a formato HH:MM:SS 
   * @param seconds Cantidad en segundos
   * @returns formato HH:MM:SS
   */
  secondsToHm(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 16);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
}
