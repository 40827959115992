import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/piezastipo`;

@Injectable()
export class PiezasTipoService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get<any[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<any>(`${baseUrl}/${id}`);
  }

  create(id: number, nombre: string, tipo_material: number) {
    return this.http.post<JSON>(`${baseUrl}/create`, { id, nombre, tipo_material }, { withCredentials: true });
  }

  update(id: number, nombre: string, tipo_material: number) {
    return this.http.post<JSON>(`${baseUrl}/update`, { id, nombre, tipo_material }, { withCredentials: true });
  }

  delete(id: number) {
    return this.http.post<JSON>(`${baseUrl}/delete`, { id }, { withCredentials: true });
  }

  Get_TipoMaterial_Combo() {
    return this.http.post<JSON>(`${baseUrl}/Get_TipoMaterial_Combo`, {}, { withCredentials: true });
  }

}
