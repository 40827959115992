<style>
  /*  ESTE CSS ES SOLO PARA ESTA PAGINA, Y SOLO PARA EL ZOOM DE LOS GRAFICOS DE ABAJO. SI SE SACASE DE ESTA PAGINA SE PERDERIA EL VINCULO DIRECTO ENTRE EL CSS Y EL CODIGO A EL QUE AFECTA.
      Por esta razon se ha dejado este codigo en este .html
  */
  .reldiv1 {
    height: 0px;
    width: 0px;
    position: relative;
  }

  :host /deep/ #chartAvance .c3-axis-y {
    transform: translate(0px,0px);
  }

  :host /deep/ #chartTemp .c3-axis-y {
    transform: translate(-37px,0px);
  }

  :host /deep/ #chartConsum .c3-axis-y {
    transform: translate(-66px,0px);
  }

  :host /deep/ .c3-drag-zoom {
    transform: translate(90.5px,4.5px);
  }
</style>

<!--FILTRO-->
<!--<form [formGroup]="form">-->
<!--Maquinas | Dia/Semana | Turnos | Fechas-->
<div class="clearfix">
  <!--Maquinas-->
  <!--<div id="divMaquinas_informeConsumo" class="cont-select-maquinas"></div>-->
  <!-- FILTRO GRUPOS -->
  <div class="filtro-areaproductiva-cont" style="width: 230px;">
    <div class="card" style="height: 90px;">
      <div class="card-body">
        <div class="row">
          <!--AREAS PRODUCTIVAS-->
          <div class="col-md-12" style="margin-top: -3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [(data)]="groupedSeccion"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="seccionesSeleccionadas"
                               placeholder="{{ 'secciones' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>
          </div>
          <!--GRUPOS-->
          <div class="col-md-12" style="margin-top: 3px;">
            <kendo-multiselect kendoMultiSelectSummaryTag [data]="grupos"
                               [textField]="'nombre'"
                               [valueField]="'id'"
                               [autoClose]="false"
                               (close)="filtrarMaquinasPorAreaProductivaYGrupo(false)"
                               [(ngModel)]="gruposSeleccionados"
                               placeholder="{{ 'grupoMaquinas' | translate }}"
                               style="width: 200px;"
                               [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'gruposSeleccionados' | translate }}</ng-container>
              </ng-template>
            </kendo-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MAQUINAS -->
  <div class="cont-select-maquinas-out" [ngClass]="{'aparecer': aparecer == true}">
    <div class="desplegar-selector-maquina" (click)="desplegarMaquinasClick()">
      <i class="fas fa-ellipsis-v"></i>
    </div>
    <div class="cont-select-maquinas" [ngClass]="{'h-90': aparecer == false}">
      <div *ngFor="let maquina of maquinasMostradas" (click)="selectedMaquina = maquina.id; desplegarMaquinasClick(); cargarInforme();" class="clickable" [ngClass]="{'actual': maquina.id==selectedMaquina}">
        <label>{{maquina.nombre}}</label>
        <img class="fas fa-3x" [src]="maquina.imagenBase64">
      </div>
    </div>
  </div>


  <!--Turnos | Fechas-->
  <div class="card cont-filtro-informes">
    <div class="card-body">
      <!--<div>-->
      <!--Turnos-->
      <!--<div class="form-group">
        <label class="control-label">{{ 'turno' | translate }}</label>
        <div class="caja">
          <kendo-multiselect [placeholder]="turnos_placeHolder" [data]="turnosCombo" [textField]="'nombre'" [valueField]="'id'"
                             (valueChange)="turnos_onValueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"></kendo-multiselect>
        </div>
      </div>-->
      <!--Dia/Semana-->
      <!--<div class="float-right">
          <div class="btn mr-1" [class.btn-success]="filtroDiaSemana" [class.btn-outline-success]="!filtroDiaSemana">
            <input type="radio" name="diaSemanaGroup" id="btnDia" class="k-radio" (click)="btnDia()" [checked]="filtroDiaSemana" kendoRadioButton>
            <label class="k-radio-label" for="btnDia">{{ 'dia' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!filtroDiaSemana" [class.btn-outline-success]="filtroDiaSemana">
            <input type="radio" name="diaSemanaGroup" id="btnSemana" class="k-radio" (click)="btnSemana()" [checked]="!filtroDiaSemana" kendoRadioButton>
            <label class="k-radio-label" for="btnSemana">{{ 'semana' | translate }}</label>
          </div>
        </div>
      </div>-->


      <div class="clearfix">
        <!--Turnos-->
        <div class="diasemana-turno ">
          <div class="form-group">
            <label class="control-label">{{ 'turno' | translate }}</label>
            <div class="caja">
              <kendo-multiselect [placeholder]="turnos_placeHolder" [data]="turnosCombo" [textField]="'nombre'" [valueField]="'id'"
                                 (valueChange)="turnos_onValueChange($event)" [kendoDropDownFilter]="{operator: 'contains'}"></kendo-multiselect>
            </div>
          </div>
        </div>
        <!--Dia/Semana-->
        <div class="float-left">
          <div class="btn mr-1" [class.btn-success]="filtroDiaSemana" [class.btn-outline-success]="!filtroDiaSemana">
            <input type="radio" name="diaSemanaGroup" id="btnDia" class="k-radio" (click)="btnDia()" [checked]="filtroDiaSemana" kendoRadioButton>
            <label class="k-radio-label" for="btnDia">{{ 'dia' | translate }}</label>
          </div>
          <div class="btn mr-1" [class.btn-success]="!filtroDiaSemana" [class.btn-outline-success]="filtroDiaSemana">
            <input type="radio" name="diaSemanaGroup" id="btnSemana" class="k-radio" (click)="btnSemana()" [checked]="!filtroDiaSemana" kendoRadioButton>
            <label class="k-radio-label" for="btnSemana">{{ 'semana' | translate }}</label>
          </div>
        </div>
      </div>

      <!--Fechas-->
      <div class="form-group">
        <label class="control-label">{{ fechasTitulo }}</label>
        <div class="caja">
          <kendo-datepicker [weekNumber]="true" class="filtro-informes-data-1" [(value)]="fechaInicio" (valueChange)="fechaInicio_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
          <kendo-datepicker [weekNumber]="true" class="filtro-informes-data-2" [readonly]="true" [style.display]="fechaFinDisplay" [(value)]="fechaFin" (valueChange)="fechaFin_dateChanged($event)">
            <kendo-calendar-messages today="{{ 'hoy' | translate }}">
            </kendo-calendar-messages>
          </kendo-datepicker>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Ejes -->
<div class="clearfix">
  <div class="card">
    <div class="card-body">

      <div id="divEjes" class="float-left">
        <div *ngFor="let eje of ejes" class="btn mr-1" [class.btn-success]="eje.selected" [class.btn-outline-success]="!eje.selected">
          <input type="radio" name="ejesGroup" [id]="'btnEje'+eje.nombre" class="k-radio" (click)="selectedChange_eje($event, eje)" [checked]="eje.selected" kendoRadioButton>
          <label class="k-radio-label" [attr.for]="'btnEje'+eje.nombre">{{ eje.nombre }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
<!--</form>-->
<!--GRAFICOS-->
<div id="divGraficos">
  <!--LINEA 1-->
  <div class="row">
    <!--Distribución de tiempo-->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'distribucionTiempo' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
          <div id="chartDonutDistribucionTiempo" style="height: 346px;"> </div>
          <div *ngIf="loadingPotenciaYAvance" class="k-i-loading"></div>
        </div>
      </div>
    </div>
    <!--Potencia consumida en funcion de rpm-->
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'potenciaConsumidaEnFuncionDeRPM' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div id="chartPotenciaConsumida">

            <!--Labels-->
            <div class="graf-barr-horas-title">
              <label>{{ 'horas' | translate }}</label>
            </div>
            <div class="graf-barr-labels">
              <div class="clearfix">
                <div class="cinco-porciento"></div>
                <label>{{labelHoras1}}</label>
                <label>{{labelHoras2}}</label>
                <label>{{labelHoras3}}</label>
                <label>{{labelHoras4}}</label>
                <label>{{labelHoras5}}</label>
                <label>{{labelHoras6}}</label>
                <label>{{labelHoras7}}</label>
                <label>{{labelHoras8}}</label>
                <label>{{labelHoras9}}</label>
                <div class="cinco-porciento"></div>
              </div>
              <div class="graf-barr-labels-linea"></div>
            </div>
            <!--Barras-->
            <div class="graf-barr">
              <div class="diez-porciento"></div>
              <div class="graf-bar-centro">
                <!-- BARRA parada-->
                <div class="graf-barr-part-cont " style="background-color: #ABA8A8" id="barra1">
                  <div class="textobarra">
                    <Label>{{labelPotencia1}}</Label>
                    <Label>{{labelDatoPotencia1}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraPotencia1Azul" [style.width.%]="(valorPotencia1Azul)" [style.background-color]="colorAzul">
                  </div>
                  <div class="graf-barr-part" id="barraPotencia1Verde" [style.width.%]="(valorPotencia1Verde)" [style.background-color]="colorVerde">
                  </div>
                  <div class="graf-barr-part" id="barraPotencia1Amarillo" [style.width.%]="(valorPotencia1Amarillo)" [style.background-color]="colorAmarillo">
                  </div>
                  <div class="graf-barr-part" id="barraPotencia1Rojo" [style.width.%]="(valorPotencia1Rojo)" [style.background-color]="colorRojo">
                  </div>
                </div>
                <!-- BARRA suave-->
                <div class="graf-barr-part-cont " style="background-color: #ABA8A8" id="barra2">
                  <div class="textobarra">
                    <Label>{{labelPotencia2}}</Label>
                    <Label>{{labelDatoPotencia2}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraPotencia2Azul" [style.width.%]="(valorPotencia2Azul)" [style.background-color]="colorAzul"></div>
                  <div class="graf-barr-part" id="barraPotencia2Verde" [style.width.%]="(valorPotencia2Verde)" [style.background-color]="colorVerde"></div>
                  <div class="graf-barr-part" id="barraPotencia2Amarillo" [style.width.%]="(valorPotencia2Amarillo)" [style.background-color]="colorAmarillo"></div>
                  <div class="graf-barr-part" id="barraPotencia2Rojo" [style.width.%]="(valorPotencia2Rojo)" [style.background-color]="colorRojo"></div>
                </div>
                <!-- BARRA media-->
                <div class="graf-barr-part-cont " style="background-color: #ABA8A8" id="barra3">
                  <div class="textobarra">
                    <Label>{{labelPotencia3}}</Label>
                    <Label>{{labelDatoPotencia3}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraPotencia3Azul" [style.width.%]="(valorPotencia3Azul)" [style.background-color]="colorAzul"></div>
                  <div class="graf-barr-part" id="barraPotencia3Verde" [style.width.%]="(valorPotencia3Verde)" [style.background-color]="colorVerde"></div>
                  <div class="graf-barr-part" id="barraPotencia3Amarillo" [style.width.%]="(valorPotencia3Amarillo)" [style.background-color]="colorAmarillo"></div>
                  <div class="graf-barr-part" id="barraPotencia3Rojo" [style.width.%]="(valorPotencia3Rojo)" [style.background-color]="colorRojo"></div>
                </div>
                <!-- BARRA alta-->
                <div class="graf-barr-part-cont " style="background-color: #ABA8A8" id="barra4">
                  <div class="textobarra">
                    <Label>{{labelPotencia4}}</Label>
                    <Label>{{labelDatoPotencia4}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraPotencia4Azul" [style.width.%]="(valorPotencia4Azul)" [style.background-color]="colorAzul"></div>
                  <div class="graf-barr-part" id="barraPotencia4Verde" [style.width.%]="(valorPotencia4Verde)" [style.background-color]="colorVerde"></div>
                  <div class="graf-barr-part" id="barraPotencia4Amarillo" [style.width.%]="(valorPotencia4Amarillo)" [style.background-color]="colorAmarillo"></div>
                  <div class="graf-barr-part" id="barraPotencia4Rojo" [style.width.%]="(valorPotencia4Rojo)" [style.background-color]="colorRojo"></div>
                </div>
                <!-- BARRA maxima-->
                <div class="graf-barr-part-cont " style="background-color: #ABA8A8" id="barra5">
                  <div class="textobarra">
                    <Label>{{labelPotencia5}}</Label>
                    <Label>{{labelDatoPotencia5}}</Label>
                  </div>
                  <div class="graf-barr-part" id="barraPotencia5Azul" [style.width.%]="(valorPotencia5Azul)"></div>
                  <div class="graf-barr-part" id="barraPotencia5Verde" [style.width.%]="(valorPotencia5Verde)"></div>
                  <div class="graf-barr-part" id="barraPotencia5Amarillo" [style.width.%]="(valorPotencia5Amarillo)"></div>
                  <div class="graf-barr-part" id="barraPotencia5Rojo" [style.width.%]="(valorPotencia5Rojo)"></div>
                </div>
                <div class="diez-porciento"></div>
              </div>
            </div>
            <!--LEYENDA-->
            <div class="leyendas leyendas-informe-potencia">
              <div class="hidden">
                <Label>{{leyenda}}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" [style.background-color]="colorAzul"></span>
                <Label>{{leyendaAzul}}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" [style.background-color]="colorVerde"></span>
                <Label>{{leyendaVerde}}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" [style.background-color]="colorAmarillo"></span>
                <Label>{{leyendaAmarillo}}</Label>
              </div>
              <div class="graf-barr-leyenda">
                <span class="colorleyenda" [style.background-color]="colorRojo"></span>
                <Label>{{leyendaRojo}}</Label>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loadingPotenciaYAvance" class="k-i-loading"></div>
      </div>
    </div>
  </div>
  <!--LINEA 2-->
  <div class="row" oncontextmenu="false;">
    <!--visor de datos-->
    <div class="col-md-12">
      <div class="card" id="cont">
        <div class="clearfix">

          <div class="card-header">
            <h3>
              <label CssClass="">{{ 'visorDatos' | translate }}</label>
            </h3>
            <div class="plegarpanel"></div>
          </div>

          <div style="height:313px;">
            <button kendoButton (click)="onMostrarTodosZoomClick()" look="flat" [icon]="'zoom-out'" style="float: right; z-index: 2; margin-right: 10px;">{{ 'mostrarTodos' | translate }}</button>
            <div class="reldiv1" id="chartAvance" [ngStyle]="{'z-index': (chartAvanceDisplay) ? 1 : 0}"></div>
            <div class="reldiv1" id="chartTemp" [ngStyle]="{'z-index': (chartTempDisplay) ? 1 : 0}"></div>
            <div class="reldiv1" id="chartConsum" [ngStyle]="{'z-index': (chartConsumDisplay) ? 1 : 0}"></div>
          </div>

        </div>
        <div class="leyendas-chart row justify-content-md-center">
          <div class="leyenda-chart col-auto" *ngFor="let leyenda of listaLeyenda" (click)="btnLeyenda(leyenda)" style="cursor: pointer;">
            <div class="leyenda-chart-color" *ngIf="leyenda.seleccionado" [style]="'background-color:' + leyenda.color"></div>
            <div class="leyenda-chart-color" *ngIf="!leyenda.seleccionado"></div>
            <button [class.leyenda-chart-label]="leyenda.seleccionado" [class.leyenda-chart-label-deseleccionado]="!leyenda.seleccionado">{{ leyenda.titulo }}</button>
          </div>
        </div>
        <div *ngIf="loadingVisorDeDatos" class="k-i-loading"></div>
      </div>
    </div>
  </div>
</div>
