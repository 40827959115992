import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { Maquina } from '@app/_models';
import { ResetAllModelsService } from '@app/_services';

const baseUrl = `${environment.apiUrl}/usuarios`;

@Injectable({ providedIn: 'root' })
export class UsuariosService {
  private userSubject: BehaviorSubject<Usuario>;
  public user: Observable<Usuario>;
  public secciones: JSON;
  public permisos: JSON;
  public llamadaEnCurso: boolean = false;
  public configuracionVariables: any | boolean = false;
  public timezones: any | boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private resetAllModelsService: ResetAllModelsService
  ) {
    this.userSubject = new BehaviorSubject<Usuario>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): Usuario {
    return this.userSubject.value;
  }

  public get_configuracion_model(): any | boolean {
    return this.configuracionVariables;
  }

  public set_configuracion_model(conf: any): void {
    this.configuracionVariables = conf;
  }

  getConfiguracionVariables() {
    return this.http.post<JSON>(`${baseUrl}/getConfiguracionVariables`, {}, { withCredentials: true });
  }

  public get_timezones_model(): any | boolean {
    return this.timezones;
  }

  public set_timezones_model(conf: any): void {
    this.timezones = conf;
  }

  getTimezones() {
    return this.http.post<JSON>(`${baseUrl}/getTimezones`, {}, { withCredentials: true });
  }

  login(idDb: number, email: string, password: string) {
    return this.http.post<any>(`${baseUrl}/authenticate`, { idDb, email, password }, { withCredentials: true })
      .pipe(map((user: any) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  logout() {
    this.resetAllModels();
    this.http.post<any>(`${baseUrl}/revoke-token`, {}, { withCredentials: true }).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['login/login']);
  }

  resetAllModels() {
    this.resetAllModelsService.resetAllModels();
    this.set_configuracion_model(false);
  }

  refreshToken() {
    return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
      .pipe(map((user) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return user;
      }));
  }

  register(user: Usuario) {
    return this.http.post(`${baseUrl}/register`, user);
  }

  verifyEmail(token: string) {
    return this.http.post(`${baseUrl}/verify-email`, { token });
  }

  forgotPassword(idDb: number, email: string) {
    return this.http.post(`${baseUrl}/forgot-password`, { idDb, email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-reset-token`, { token });
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
  }

  getAll() {
    return this.http.get<Usuario[]>(baseUrl);
  }

  getAllUsers(type: number) {
    return this.http.post<JSON>(`${baseUrl}/getAllUsers`, {type}, { withCredentials: true })
  }

  getAllMaquina() {
    return this.http.get<Maquina[]>(baseUrl);
  }

  getById(id: string) {
    return this.http.get<Usuario>(`${baseUrl}/${id}`);
  }

  create(params) {
    debugger;
    return this.http.post(baseUrl + "/create", params);
  }

  update(params) {
    return this.http.post(baseUrl + "/update", params)
      .pipe(map((user: any) => {
        // update the current user if it was updated
        if (user.id === this.userValue.id) {
          // publish updated user to subscribers
          user = { ...this.userValue, ...user };
          this.userSubject.next(user);
        }
        return user;
      }));
  }

  updateAlumnos(params){
    return this.http.post(baseUrl + "/updateAlumno", params)
    .pipe(map((user: any) => {
      // update the current user if it was updated
      if (user.id === this.userValue.id) {
        // publish updated user to subscribers
        user = { ...this.userValue, ...user };
        this.userSubject.next(user);
      }
      return user;
    }));
  }

  updateDocente(params) {
    return this.http.post(baseUrl + "/updateDocente", params);
  }

  deleteDocentes(id: number,params) {
    var ids = params.value.id;
    return this.http.post<JSON>(`${baseUrl}/deleteDocentes`, { id: ids }, { withCredentials: true });
  }

  updateUsuarioPropio(params) {
    return this.http.post(baseUrl + "/updateUsuarioPropio", params)
      .pipe(map((user: any) => {
        // update the current user if it was updated
        if (user.id === this.userValue.id) {
          // publish updated user to subscribers
          user = { ...this.userValue, ...user };
          this.userSubject.next(user);
        }
        return user;
      }));
  }

  delete(id: number, params) {
    debugger;
    var ids = params.value.id;
    return this.http.post<JSON>(`${baseUrl}/delete`, { id: ids }, { withCredentials: true })
      .pipe(finalize(() => {
        // auto logout if the logged in user was deleted
        if (id === this.userValue.id)
          this.logout();
      }));
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  getSecciones() {
    return this.http.post<JSON>(`${baseUrl}/getSecciones`, {}, { withCredentials: true })
  }

  getSeccionesByIdUsuario(id) {
    return this.http.post<JSON>(`${baseUrl}/getSeccionesByIdUsuario/${id}`, {}, { withCredentials: true })
  }

  getAllSecciones() {
    return this.http.post<JSON>(`${baseUrl}/getAllSecciones`, {}, { withCredentials: true })
  }

  getComboSecciones() {
    return this.http.post<JSON>(`${baseUrl}/getComboSecciones`, {}, { withCredentials: true })
  }

  updateSeccion(ids: string) {
    return this.http.post(`${baseUrl}/updateSeccion`, { ids }, { withCredentials: true });
  }

  updateSeccionesUsuario(idUsuario, idSeccionesStr) {
    return this.http.post(`${baseUrl}/updateSeccionesUsuario`, { idUsuario: idUsuario, idSeccionesStr: idSeccionesStr }, { withCredentials: true });
  }

  updateTimezone(idUsuario: number, idTimezone: number, timezone: string, timezoneSQL: string, desvioTimezone: number) {
    return this.http.post(`${baseUrl}/updateTimezone`, { idUsuario, idTimezone, timezone, timezoneSQL, desvioTimezone }, { withCredentials: true });
  }

  assignColour(idUsuario) {
    return this.http.post(`${baseUrl}/assignColour`, { idUsuario: idUsuario }, { withCredentials: true });
  }

  /* ERIS ESKOLA */

  public getUsuarioDocente(id: string) {
    return this.http.post<Usuario>(`${baseUrl}/GetUsuarioDocente`, { id: id }, { withCredentials: true });
  }

  public getUsuarioAlumnos(id: string) {
    return this.http.post<Usuario>(`${baseUrl}/GetUsuarioAlumno`, { id: id }, { withCredentials: true });
  }

}
