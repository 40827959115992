import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MaquinasService, MenuService } from '@app/_services';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { UsuariosService } from '@app/_services';

@Component({
  selector: 'app-maquina-detalle-capa',
  templateUrl: './maquinaDetalleCapa.component.html'
})

export class MaquinaDetalleCapaComponent {

  public submitable: boolean;
  public id: number = -1;
  public idMaquina: number = -1;
  public tipoMaquina: any;

  closeResult = '';
  form: FormGroup;
  loading = false;

  submitted = false;
  isAddMode: boolean;
  user = this.userService.userValue;

  constructor(
    private formBuilder: FormBuilder,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private route: ActivatedRoute,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService) {

    this.menuService.titulo = this.translateService.instant('capa').toUpperCase();
  }

  ngOnInit() {

    this.id = this.route.snapshot.params['id'];
    this.idMaquina = this.route.snapshot.params['idMaquina'];
    this.submitable = true;
    this.isAddMode = !this.id;

    this.maquinasService.GetTipoMaquinaById(this.idMaquina).pipe().subscribe((result) => {
      this.tipoMaquina = result[0].tipo_maquina;
    });

    this.form = this.formBuilder.group({
      idDb: this.user.idDb,
      nombre: ['',],
      idMaquina: [this.idMaquina,],
      presion_activo: [true,],
      presion_min: [0,],
      presion_max: [0,],
      rendimientoTasaDeTransporte_activo: [true,],
      rendimientoTasaDeTransporte_min: [0,],
      rendimientoTasaDeTransporte_max: [0,],
      revolucionesMotor_activo: [true,],
      revolucionesMotor_min: [0,],
      revolucionesMotor_max: [0,],
      cargaMotor_activo: [true,],
      cargaMotor_min: [0,],
      cargaMotor_max: [0,],
    });

    if (!this.isAddMode) {
      this.maquinasService.GetCapaById(this.id).pipe().subscribe((result) => {
        this.form = this.formBuilder.group({
          idDb: this.user.idDb,
          nombre: [result.data[0].nombre,],
          idMaquina: [this.idMaquina,],
          presion_activo: [result.data[0].presion_activo,],
          presion_min: [result.data[0].presion_min,],
          presion_max: [result.data[0].presion_max,],
          rendimientoTasaDeTransporte_activo: [result.data[0].rendimientoTasaDeTransporte_activo,],
          rendimientoTasaDeTransporte_min: [result.data[0].rendimientoTasaDeTransporte_min,],
          rendimientoTasaDeTransporte_max: [result.data[0].rendimientoTasaDeTransporte_max,],
          revolucionesMotor_activo: [result.data[0].revolucionesMotor_activo,],
          revolucionesMotor_min: [result.data[0].revolucionesMotor_min,],
          revolucionesMotor_max: [result.data[0].revolucionesMotor_max,],
          cargaMotor_activo: [result.data[0].cargaMotor_activo,],
          cargaMotor_min: [result.data[0].cargaMotor_min,],
          cargaMotor_max: [result.data[0].cargaMotor_max,],
        });
      })
    }

  }

  public onChangePresion(value) {
    if (!value) {
      this.form.controls['presion_min'].setValue(0);
      this.form.controls['presion_max'].setValue(0);
    }
  }

  public onChangeRendimientoTasaDeTransporte(value) {
    if (!value) {
      this.form.controls['rendimientoTasaDeTransporte_min'].setValue(0);
      this.form.controls['rendimientoTasaDeTransporte_max'].setValue(0);
    }
  }

  public onChangeRevolucionesMotor(value) {
    if (!value) {
      this.form.controls['revolucionesMotor_min'].setValue(0);
      this.form.controls['revolucionesMotor_max'].setValue(0);
    }
  }

  public onChangeCargaMotor(value) {
    if (!value) {
      this.form.controls['cargaMotor_min'].setValue(0);
      this.form.controls['cargaMotor_max'].setValue(0);
    }
  }

  get f() { return this.form.controls; }

  onSubmit() {
    if (this.form.invalid || !this.submitable) {
      this.submitable = true;
      return;
    }
    this.loading = true;
    if (this.isAddMode) {
      this.insert();
    } else {
      this.update();
    }
  }

  private insert() {
    this.maquinasService.insertCapa(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  private update() {
    this.form.value.id = this.id;
    this.maquinasService.updateCapa(this.form.value).subscribe((result) => {
      if (result.error == false) {
        this.atras();
      }
    });
  }

  public atras() {
    if (this.tipoMaquina == -1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //NO TIENE TIPO DE MAQUINA, IR A MECANIZADO POR DEFECTO
    if (this.tipoMaquina == 1) this.router.navigate(['maquinas/editar/', this.idMaquina]); //MECANIZADO
    if (this.tipoMaquina == 2) this.router.navigate(['extrusoras/editar/', this.idMaquina]); //EXTRUSORA
    if (this.tipoMaquina == 3) this.router.navigate(['impresorasPlastico/editar/', this.idMaquina]); //IMPRESORA PLASTICO
    if (this.tipoMaquina == 4) this.router.navigate(['inyectoras/editar/', this.idMaquina]); //INYECTORAS
    if (this.tipoMaquina == 5) this.router.navigate(['hornos/editar/', this.idMaquina]); //HORNOS
    if (this.tipoMaquina == 9) this.router.navigate(['aditivo/editar/', this.idMaquina]); //ADITIVO
  }

}
