<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 4px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :host /deep/ .k-grid tr.rojo {
    background-color: #ff9d9d;
  }

  :host /deep/ .k-grid tr.rojo:hover {
    background-color: #ff8989;
  }

  :host /deep/ .k-grid tr.rojo.k-state-selected td {
    background-color: #ff7676;
  }
</style>

<!-- TODO -->
<div class="historico historicooperaciones">

    <!-- TOP  -->
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'pieza' | translate}} </label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <!-- OPCIONES PIEZA -->
        <div class="clearfix">
          <button type="button" [hidden]="!visibleMandarProduccion" class="btn btn-success m-1 float-left" (click)="btnmandarProduccion()" [disabled]="this.user.historicoOperaciones < 2">
            {{ 'mandarProduccion' | translate }}
          </button>
          <button type="button" [hidden]="!visibleFinalizar" class="btn btn-azul m-1 float-left" (click)="btnFinalizar()" [disabled]="this.user.historicoOperaciones < 2">
            {{ 'finalizar' | translate }}
          </button>
          <button type="button" [hidden]="!visibleValidar" class="btn btn-verde m-1 float-left" (click)="btnValidar()">
            {{ 'validar' | translate }}
          </button>
          <button type="button" [hidden]="!visibleApartar" class="btn btn-naranja m-1 float-left" (click)="btnApartarPieza()">
            {{ 'apartarPieza' | translate }}
          </button>
          <button type="button" [hidden]="!visibleChatarra" class="btn btn-danger m-1 float-left" (click)="btnAchatarrar()">
            {{ 'achatarrar' | translate }}
          </button>
          <button type="button" [hidden]="!visibleCambiarRuta" class="btn btn-info m-1 float-left" (click)="btnCambiarRuta()">
            {{ 'cambiarRuta' | translate }}
          </button>
          <button type="button" class="btn btn-info m-1 float-left" (click)="btnAnaliticaAvanzada()">
            {{ 'analiticaAvanzadaEjecuciones' | translate }}
          </button>
        </div>
        <!-- INFO -->
        <div class="row">
          <!-- OF -->
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'of' | translate }}</label>
            <label class="bloques-info-valor">{{infoOF}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'cliente' | translate }}</label>
            <label class="bloques-info-valor">{{infoCliente}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'proyecto' | translate }}</label>
            <label class="bloques-info-valor">{{infoProyecto}}</label>
          </div>
          <!-- PIEZA -->
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'plano' | translate }}</label>
            <label class="bloques-info-valor">{{infoPlano}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'refPieza' | translate }}</label>
            <label class="bloques-info-valor">{{infoRefPieza}}</label>
          </div>
          <div class="bloques-info ">
            <label class="bloques-info-label">{{ 'pieza' | translate }}</label>
            <label class="bloques-info-valor">{{infoPieza}}</label>
          </div>
          <div class="bloques-info" *ngIf="user.verColada">
            <!-- *ngIf="usuarioIdDbCAF" -->
            <label class="bloques-info-label">{{ 'pedircolada' | translate }}</label>
            <label class="bloques-info-valor">{{coladaCAF}}</label>
          </div>
          <!-- N SERIE -->
          <div class="bloques-info" *ngIf="user.verNSerie">
            <label class="bloques-info-label">{{ 'nserie' | translate }}</label>
            <label class="bloques-info-valor">{{infonserie}}</label>
          </div>
          <div class="bloques-info" *ngIf="user.verLote">
            <label class="bloques-info-label">{{ 'lote' | translate }}</label><!-- *ngIf="!usuarioIdDbCAF" -->
            <label class="bloques-info-valor">{{ loteCAF }}</label><!-- *ngIf="usuarioIdDbCAF && usaLotes==0" -->
          </div>
          <div class="bloques-info">
            <label class="bloques-info-label">{{ 'cantidad' | translate }}</label><!-- *ngIf="usuarioIdDbCAF" -->
            <label class="bloques-info-valor">{{ cantidadLoteHechas }} / {{cantidadLote }}</label><!-- *ngIf="!usuarioIdDbCAF || (usuarioIdDbCAF && usaLotes==1)" -->
          </div>
          <div class="bloques-info">
            <label class="bloques-info-label">{{ 'tEstimado' | translate }}</label>
            <label class="bloques-info-valor">{{infoTiempoEstimado}}</label>
          </div>
          <div class="bloques-info">
            <label class="bloques-info-label">
              <span class="tooltip-consumibles-contenido">
                <i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="tiempoAsigandoPiezaNoPreparacion" class="tooltipTextHeader"></span>
              </span>
              {{ 'tiempo' | translate }}
            </label>
            <label class="bloques-info-valor">{{infoTiempoRealEjecucion}}</label>
          </div>
          <div class="bloques-info" *ngIf="usuarioIdDb!=usuarioIdDbGamesa">
            <!-- NO ENSEÑAR PARA GAMESA -->
            <label class="bloques-info-label">{{ 'tPreparacion' | translate }}</label>
            <label class="bloques-info-valor">{{infoTiempoRealPreparacion}}</label>
          </div>
          <div class="bloques-info">
            <label class="bloques-info-label">{{ 'desvio' | translate }}</label>
            <label class="bloques-info-valor">{{infoDesvio}}</label>
          </div>
          <div class="bloques-info">
            <label class="bloques-info-label">{{ 'desvioPorcentaje' | translate }}</label>
            <label class="bloques-info-valor">{{infoPorcenDesvio}}</label>
          </div>
          <div class="bloques-info">
            <label class="bloques-info-label">{{ 'estado' | translate }}</label>
            <label class="bloques-info-valor" *ngIf="idEstado==1">{{'enProceso' | translate}}</label>
            <label class="bloques-info-valor" *ngIf="idEstado==2">{{'acabada' | translate}}</label>
            <label class="bloques-info-valor" *ngIf="idEstado==3">{{'validada' | translate}}</label>
            <label class="bloques-info-valor" *ngIf="idEstado==4">{{'apartada' | translate}}</label>
            <label class="bloques-info-valor" *ngIf="idEstado==5">{{'chatarra' | translate}}</label>
          </div>
          <div class="bloques-info" *ngIf="idEstado==4 || idEstado==5" style="display: flex; align-content: center; flex-direction: row; align-items: flex-end;">
          <div>        
            <label class="bloques-info-label">{{ 'perdida' | translate }}</label>
            <div class="caja" style="width: 250px;">
              <div *ngIf="idEstado==4">
                {{ JperdidasApartadaSelected?.text }}
                <!-- <kendo-combobox [data]="Jperdidas"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="JperdidasApartadaSelected"
                                placeholder="{{ 'perdida' | translate }}"
                                [kendoDropDownFilter]="{operator: 'contains'}">                            
                </kendo-combobox> -->
              </div>
              <div *ngIf="idEstado==5">
                {{ JperdidasAchatarradaSelected?.text }}
                <!-- <kendo-combobox [data]="Jperdidas"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="JperdidasAchatarradaSelected"
                                placeholder="{{ 'perdida' | translate }}"
                                [kendoDropDownFilter]="{operator: 'contains'}">
                </kendo-combobox> -->
              </div>
            </div>
          </div>
          <div *ngIf="(idEstado==4 && JperdidasApartadaSelected?.solicitarOperacion) || (idEstado==5 && JperdidasAchatarradaSelected?.solicitarOperacion)">        
            <label class="bloques-info-label">{{ 'operacion' | translate }}</label>
            <div class="caja" style="width: 250px;">
              <div *ngIf="idEstado==4">
                {{ Joperaciones_perdidaApartadaSelected?.text }}
                <!-- <kendo-combobox [data]="Joperaciones_perdida"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="Joperaciones_perdidaApartadaSelected"
                                placeholder="{{ 'operacion' | translate }}"
                                [kendoDropDownFilter]="{operator: 'contains'}">                            
                </kendo-combobox> -->
              </div>
              <div *ngIf="idEstado==5">
                {{ Joperaciones_perdidaAchatarradaSelected?.text }}
                <!-- <kendo-combobox [data]="Joperaciones_perdida"
                                [textField]="'text'"
                                [valueField]="'value'"
                                [(value)]="Joperaciones_perdidaAchatarradaSelected"
                                placeholder="{{ 'operacion' | translate }}"
                                [kendoDropDownFilter]="{operator: 'contains'}">
                </kendo-combobox> -->
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-info m-1 float-left" (click)="btnModificarRazon()">
            <i class="icon-editar-alt"></i>
          </button>
  
          </div>
        </div>
      </div>
    </div>
    
  <ng-template #template let-anchor>
    <span>{{ anchor.nativeElement.innerText }}</span>
  </ng-template>
  <!-- GRID -->
  <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showGridTooltip($event)">
    <!-- GRID -->
    <kendo-grid [kendoGridBinding]="JOperacionesView"
                kendoGridSelectBy="idHo"
                [selectedKeys]="operacionesSelecteds"
                (cellClick)="cellClick($event)"
                [navigable]="true"
                [sortable]="true"
                filterable="menu"
                scrollable="virtual"
                [rowHeight]="37"
                [height]="500"
                [resizable]="true"
                [pageable]="true"
                [pageSize]="pageSize"
                [skip]="skip"
                class="grid-font-10"
                (pageChange)="pageChange($event)"
                [rowClass]="rowCallback">
      <ng-template kendoGridToolbarTemplate position="top" *ngIf="usuarioIdDb==usuarioIdDbGamesa">
        <div class="float-left">
          <!-- <button (click)="clickCancelar()" class="btn btn-success btn-sm mr-1">{{ 'cancelarOperacion' | translate }}</button>    -->
          <!-- MARCAR COMO ESTANDR: PARA GAMESA DAR OPCION -->           
          <button class="btn btn-success m-1 float-left" (click)="clickMarcarComoEstandar(contentMarcarComoEstandar, contentNoHayOperacionesMarcarComoEstandar)" *ngIf="usuarioIdDb==usuarioIdDbGamesa">
            {{ 'marcarComoEstandar' | translate}}
          </button>
        </div>
      </ng-template> 
      <kendo-grid-checkbox-column showSelectAll="true" width="4%" *ngIf="usuarioIdDb==usuarioIdDbGamesa"></kendo-grid-checkbox-column> 
      <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="6%"></kendo-grid-column>
      <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="16%"></kendo-grid-column>
      <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" width="12%" *ngIf="usuarioIdDb!=usuarioIdDbGamesa" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido">
            <ng-container *ngIf="dataItem.operariosAuxi!='' && (dataItem.tiempoSoloEjecucion > 0 || dataItem.tiempoRealPreparacion)">
              <ng-container *ngFor="let operario of dataItem.operariosAuxi.split(','); let i = index">
                <span *ngIf="operario!='undefined' && operario!='Dundefined'" class="grid-circulo" [style.background-color]="dataItem.operariosC.split(',')[i]+ '!important'">{{operario}}</span>
                <span *ngIf="operario=='undefined' || operario=='Dundefined'" class="grid-circulo"><i class="fas fa-user-alt"></i></span>
              </ng-container>
              <span class="tooltiptext">
                <ng-container *ngFor="let operario of dataItem.operariosAuxi2.split(';'); let i = index">
                  <label *ngIf="operario !='undefined'"> {{operario}} </label>
                  <label *ngIf="operario =='undefined'"> {{'desconocido' | translate}} </label>
                </ng-container>
              </span>
            </ng-container>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="maquinas" title="{{ 'maquinas' | translate }}" width="12%" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido">
            <ng-container *ngIf="dataItem.maquinasAuxi!='' && (dataItem.tiempoSoloEjecucion > 0 || dataItem.tiempoRealPreparacion)">
              <ng-container *ngFor="let maquina of dataItem.maquinasAuxi.split(','); let i = index">
                <span *ngIf="dataItem.maquinasIm.split(';and;')[i]!='null' && maquina!='undefined'" class="grid-circulo" style="background-color:white!important;" [ngStyle]="{'background-image': 'url(' + dataItem.maquinasIm.split(';and;')[i] + ')'}"></span>
                <span *ngIf="dataItem.maquinasIm.split(';and;')[i]=='null' && maquina!='undefined'" class="grid-circulo" [style.background-color]="dataItem.maquinasC.split(',')[i]+ '!important'"> {{maquina}} </span>
              </ng-container>
              <span class="tooltiptext">
                <ng-container *ngFor="let maquina of dataItem.maquinasAuxi2.split(';'); let i = index">
                  <label *ngIf="maquina!='undefined'"> {{maquina}} </label>
                  <label *ngIf="maquina=='undefined'"> {{'desconocido' | translate}} </label>
                </ng-container>
              </span>
            </ng-container>
          </span>
        </ng-template>
      </kendo-grid-column>
      <!--  <kendo-grid-column field="coladaCAF" title="{{ 'pedircolada' | translate }}" width="8%" *ngIf="user.verColada"></kendo-grid-column> -->
      <!-- <kendo-grid-column field="lote" title="{{ 'lote' | translate }}" width="6%" *ngIf="user.verLote"></kendo-grid-column> -->
      <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate }}" width="6%"></kendo-grid-column>
      <kendo-grid-column field="fechaIniOrden" title="{{ 'fechainicio' | translate }}" width="10%" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido" *ngIf="dataItem.fechaini != null">
            <label> {{myFunctions.dateWithoutYearShorted(dataItem.fechaini)}} {{dataItem.fechaini | kendoDate:'HH:mm' }} </label>
            <span class="tooltiptext"> {{dataItem.fechaini | kendoDate}} {{dataItem.fechaini | kendoDate:'HH:mm'}} </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="fechaFinOrden" title="{{ 'fechafin' | translate }}" width="10%" class="celda-tooltip-externo">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido" *ngIf="dataItem.fechafin != null">
            <label> {{myFunctions.dateWithoutYearShorted(dataItem.fechafin)}} {{dataItem.fechafin | kendoDate:'HH:mm' }} </label>
            <span class="tooltiptext"> {{dataItem.fechafin | kendoDate}} {{dataItem.fechafin | kendoDate:'HH:mm'}} </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tiempoEstimadoTota" title="{{ 'tEstimado' | translate }}" width="9%" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem> {{myFunctions.secondsTo_HH_MM(dataItem.tiempoEstimadoTota)}} </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tiempoSoloEjecucion" title="{{ 'tReal' | translate }}" width="9%" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{myFunctions.secondsTo_HH_MM(dataItem.tiempoSoloEjecucion+ dataItem.tiempoRealPreparacion)}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tiempoSoloEjecucion" title="{{ 'tEjecucion' | translate }}" width="9%" [style]="{'text-align': 'right'}">
        <!-- HEADER -->
        <ng-template kendoGridHeaderTemplate let-column>
          <label>
            <span class="tooltip-consumibles-contenido">
              <i class="fas fa-info-circle mr-2"></i><span [attr.contenido]="tiempoAsigandoPiezaNoPreparacion" class="tooltipTextHeader"></span>
            </span>
            {{ 'tiempo' | translate }}
          </label>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{myFunctions.secondsTo_HH_MM(dataItem.tiempoSoloEjecucion)}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="tiempoRealPreparacion" title="{{ 'tPreparacion' | translate }}" width="9%" [style]="{'text-align': 'right'}">
        <!-- NO ENSEÑAR PARA GAMESA -->
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{myFunctions.secondsTo_HH_MM(dataItem.tiempoRealPreparacion)}}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="porcenDesvio" title="{{ 'desvioPorcentaje' | translate }}" width="7%" [style]="{'text-align': 'right'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [hidden]="!dataItem.tieneHistorico">{{dataItem.porcenDesvio}} %</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="claseRespuesta" title="{{ 'estado' | translate }}" width="6%" [style]="{'text-align': 'center'}" class="celda-tooltip-externo">
        <ng-template kendoGridHeaderTemplate let-column>
          <label>
            <span class="tooltip-consumibles-contenido">
              <i class="fas fa-info-circle mr-2"></i>
              <span [attr.contenido]="contenidoEstado" class="tooltipTextHeader"></span>
            </span>
            {{ 'estado' | translate }}
          </label>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="tooltip-consumibles-contenido">
            <div class="cont-barraprogreso-grid">
              <div *ngIf="!dataItem.terminado" class="barraprogreso-grid" [ngStyle]="{'width': dataItem.porcen+'%'}"></div>
              <div *ngIf="dataItem.terminado" class="barraprogreso-grid" [ngStyle]="{'width': '100%'}"></div>
              <label *ngIf="dataItem.terminado || dataItem.porcen==100" class="label-barraprogreso-grid">100%</label>
            </div>
            <!-- <span class="tooltiptext text-left">
              <ng-container *ngFor="let operacion of dataItem.opera; let i = index">
                <label *ngIf="!dataItem.terminado">{{operacion}} {{dataItem.total}}/{{dataItem.hechasTotal}} {{dataItem.porcen}}%</label>
                <label *ngIf="dataItem.terminado">{{operacion}} {{dataItem.total}}/{{dataItem.hechasTotal}} 100%</label>
              </ng-container>
            </span> -->
          </span>
        </ng-template>
      </kendo-grid-column>

      <!--  <kendo-grid-column field="claseCancelada" title="{{ 'cancelada' | translate }}" width="7%" [style]="{'text-align': 'center'}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="icono-estado " [ngClass]="dataItem.claseCancelada"></span>
        </ng-template>
      </kendo-grid-column> -->
      <!-- VALORES -->
      <kendo-grid-column field="claseValorFueraLimites" title="{{ 'valores' | translate }}" width="3%" [style]="{'text-align': 'center'}" class="celda-tooltip-externo p-0">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- <span class="celda-completa"
                [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icono-estado-valorFueraLimites-null',
                            'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                            'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera-critico',
                            'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icono-estado-valorFueraLimites-dentro'}">
            <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites" title="{{ valores | translate }}">
            </span> -->
            <span class="celda-completa"
                  [ngClass]="{'bg-amarillo': dataItem.claseValorFueraLimites == 'fas fa-ban icon-menos-alt',
                              'bg-naranja': dataItem.claseValorFueraLimites == 'fas fa-times icono-estado-valorFueraLimites-fuera',
                              'bg-rojo': dataItem.claseValorFueraLimites == 'fas fa-times icon-equis',
                              'bg-verde': dataItem.claseValorFueraLimites == 'fas fa-check icon-check'}">
              <span class="icono-valores" [ngClass]="dataItem.claseValorFueraLimites" title="{{ valores | translate }}">
              </span>
          </span>
          <!-- tooltip -->
          <span class="tooltip-consumibles-contenido">
            <span class="tooltiptext text-left">
              <label class="tooltipDestacado">{{ 'total' | translate }} {{dataItem.cantidadOK}}/{{dataItem.cantidadMal}}/{{dataItem.cantidadValoresVacios}}</label>
            </span>
          </span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="incidencia" title="{{ 'incidencia' | translate }}" width="7%" [style]="{'text-align': 'center'}" *ngIf="usuarioIdDb==usuarioIdDbGamesa">
        <!-- INCIDENCIA: PARA GAMESA ENSEÑAR -->
        <ng-template kendoGridCellTemplate let-dataItem>
          <i class="fas fa-exclamation-triangle" *ngIf="dataItem.incidencia"></i>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="operarios" title="{{ 'operarios' | translate }}" width="11%" *ngIf="usuarioIdDb==usuarioIdDbGamesa"></kendo-grid-column><!-- OPERARIOS: PARA GAMESA ENSEÑARLO AQUI -->
      <!-- ANALITICA / TEMPLADO -->
      <kendo-grid-column title="{{ 'analitica' | translate }}" width="3%" [style]="{'text-align': 'center'}" class="celda-tooltip-externo-header p-0">
        <ng-template kendoGridHeaderTemplate let-column>
          <span class="tooltip-consumibles-contenido">
            <label><i class="fas fa-info-circle mr-2"></i><i class="fas icon-rendimiento"></i></label>
            <span [attr.contenido]="contenidoAnalitica" class="tooltipTextHeader"></span>
          </span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <button #anchor *ngIf="dataItem.maquinasTipo == 12" kendoButton class="btn-success btn" (click)="onToggle(dataItem, anchor, popupAnalitica)">
            <i class="fas icon-rendimiento" style="pointer-events: none;"></i>
          </button>
          <button *ngIf="dataItem.maquinasTipo != 12" kendoButton class="btn-success btn" (click)="clickAnalitica(dataItem.idHo)">
            <i class="fas icon-rendimiento" style="pointer-events: none;"></i>
          </button>
        </ng-template>
      </kendo-grid-column>
      <!-- OPERACION-->
      <kendo-grid-column title="{{ 'operacion' | translate }}" width="5%" [style]="{'text-align': 'center'}" class="">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button type="button" class="btn btn-info mr-1" (click)="clickOperacion(dataItem.idOperacion)"> {{ 'operacion' | translate }} </button>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
      filterContainsOperator="{{'filterContainsOperator' | translate}}"
      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
  </div>

  <div *ngIf="loadingPanel" class="k-i-loading" style="z-index: 3;"></div>

  <!-- BOTONES -->
  <div class="card">
    <div class="card-body">
      <button type="button" class="btn btn-danger m-1 float-left" (click)="btnAtras()"> {{ 'atras' | translate }} </button>
    </div>
  </div></div>
<!-- POPUP: ESTADO PIEZA -->
<ng-template #popup let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{tituloModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" *ngIf="tipoPopup != 1">
          <label>{{ 'perdida' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Jperdidas"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="JperdidasSelected"
                            placeholder="{{ 'perdida' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
        <div class="form-group" *ngIf="tipoPopup != 1 && JperdidasSelected?.solicitarOperacion">
          <label>{{ 'operacion' | translate }}</label>
          <div class="caja">
            <kendo-combobox [data]="Joperaciones_perdida"
                            [textField]="'text'"
                            [valueField]="'value'"
                            [(value)]="Joperaciones_perdidaSelected"
                            placeholder="{{ 'operacion' | translate }}"
                            [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
        <label>{{ 'observacion' | translate }}</label>
        <textarea kendoTextArea [(value)]="Jobservacion"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }} </button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptar()" [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP: RUTAS -->
<ng-template #popupRutas let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <kendo-grid [kendoGridBinding]="rutasOperaciones">
          <kendo-grid-column field="ruta" title="{{ 'ruta' | translate }}" width="100%"></kendo-grid-column>
          <div *kendoGridDetailTemplate="let dataItem">
            <kendo-grid [data]="dataItem.dtOperaciones" 
                        kendoGridSelectBy="id" 
                        [selectedKeys]="rutasOperacionesSelected" 
                        [selectable]="{ mode:'single'}">
              <kendo-grid-checkbox-column showSelectAll="true" width="7%"></kendo-grid-checkbox-column>
              <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="lado" title="{{ 'lado' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="suborden" title="{{ 'suborden' | translate }}" width="5%"></kendo-grid-column>
              <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="78%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                                 filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                                 filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                                 filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                                 filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                                 filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                                 filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                                 filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                                 filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                                 filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                                 filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                                 filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                                 filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                                 filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                                 filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                                 filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                                 filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                                 filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                                 filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                                 filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                                 groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                                 noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')"> {{ 'cancelar' | translate }} </button>
    <button type="button" class="btn btn-primary" (click)="btnPopupAceptarRuta()" [disabled]="this.user.historicoOperaciones < 2">{{ 'aceptar' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP: MARCAR COMO ESTANDAR (GAMESA)-->
<ng-template #contentMarcarComoEstandar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntamarcarcomoestandarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')"> {{ 'no' | translate }} </button>
    <button type="button" class="btn btn-danger" (click)="cambiarTiemposEstimados()" [disabled]="this.user.historicoOperaciones < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>
<!-- POPUP: NO HAY OPERACIONES PARA MARCAR COMO ESTANDAR (GAMESA)-->
<ng-template #contentNoHayOperacionesMarcarComoEstandar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'nohayoperacionesparamarcarcomoestandar' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="modal.dismiss('cancel click')"> {{ 'aceptar' | translate }} </button>
  </div>
</ng-template>
<!-- POPUP: ANALITICA AVANZADA-->
<ng-template #popupAnalitica>
  <div class="content">
    <div class="dropdown-menu dropdown-usuario show">
      <div class="cont-linea">
        <div class="inner-usuario-logout"><a class="btn btn-success w-100" (click)="clickAnalitica(-1)">{{ 'analiticaAvanzadaEjecuciones' | translate }}</a></div>
      </div>
      <div class="cont-linea">
        <div class="inner-usuario-logout"><a class="btn btn-success w-100" (click)="clickTemplado()">{{ 'informeTemplado' | translate }}</a></div>
      </div>
    </div>
  </div>
</ng-template>