import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, AutomatizacionesService } from '@app/_services';
import { MyFunctions } from '../_helpers';

@Component({
  selector: 'app-automatizaciones',
  templateUrl: 'automatizaciones.component.html'
})

export class AutomatizacionesComponent {

    public automatizaciones = [];
    public tablasNecesarias = {};

    constructor(
        public translateService: TranslateService,
        public router: Router,
        private menuService: MenuService,
        private automatizacionesService: AutomatizacionesService,
        private myFunctions: MyFunctions,
        private modalService: NgbModal) {
            this.menuService.titulo = this.translateService.instant('automatizaciones').toUpperCase();
        }

    ngOnInit() {
        this.cargarDatos();
    }

    cargarDatos() {

        this.automatizacionesService.getAutomatizaciones().pipe().subscribe((result: any) => {
            let tiposCampos, tiposCamposValores;
            let tablas = [];
            result.informacion.forEach(element => {
                tiposCampos = JSON.parse(element.tiposCampos);
                tiposCamposValores = JSON.parse(element.tiposCamposValores);
                
                element.tiposCampos = this.myFunctions.copy(tiposCampos);
                
                let index = 0;
                element.tiposCampos.forEach(element => {
                    element.valores = JSON.parse(result.valores[index]?.tiposCamposValores);
                    element.tiposCamposValores = tiposCamposValores.filter(f => f.idTipos_Campos == element.id);
                    index++;
                });

                tablas.push(...element.tiposCampos.filter(f => f.esTabla))

                element.acciones = result.acciones.filter(f => f.idAutomatizacion == element.idAutomatizacion);
                element.crear = false;
            });
            this.automatizaciones = result.informacion;

            // crear el div para crear una nueva automatizacion
            this.automatizaciones.push({
                crear: true
            })

            this.prepararInformacion();
        });
    }

    prepararInformacion() {
        let regex = /{\w*}/g;
        this.automatizaciones.forEach(element => {
            if (!element.crear) {

                //#region Tratar la condicion establecida
                let elementos = element.condicionAEstablecer.split(regex);
                let elementosElegidos = [];
                let index = 1;
                element.tiposCampos.forEach(tipoCampo => {
                    let campo;
                    // Si es vacio significa que el usuario ha metido el valor directamente o que el valor son unos valores de una tabla
                    if (tipoCampo.tiposCamposValores[0]?.valor != "")
                        campo = tipoCampo.tiposCamposValores.filter(f => tipoCampo.valores.map(v => v.valor).includes(f.valor))[0]; // aqui falta class
                    else
                        campo = { nombre: '', valor: tipoCampo.valores.map(v => v.valor)};
                    
                    if (element["idFase" + index] == 1)
                        campo.class = 'automatizacion-azul';
                    else if (element["idFase" + index] == 2)
                        campo.class = 'automatizacion-verde';
                    else if (element["idFase" + index] == 3)
                        campo.class = 'automatizacion-morado';
                    else
                        campo.class = 'automatizacion-negro';

                    elementosElegidos.push(campo);
                    index++;
                });

                index = 0;
                let elementosFinal = []
                elementos.forEach(e => {
                    elementosFinal.push({nombre: e, class: 'automatizacion-negro'});
                    if (elementosElegidos[index] != undefined)
                        elementosFinal.push(elementosElegidos[index]);
                    index++;
                });
                //#endregion

                //#region Tratar las acciones
                elementosFinal.push({nombre: element.acciones[0]?.nombre, class: 'automatizacion-morado'})
                elementosFinal.push({nombre: 'a', class: 'automatizacion-negro'})
                var operarios = JSON.parse(element.acciones[0]?.tiposCamposValores);
                operarios.forEach(operario => {
                    var nombre = operario.nombre;
                    var apellido = operario.apellido1;
                    operario.siglas = nombre.charAt(0) + apellido.charAt(0);
                });
                elementosFinal.push({valor: operarios, isOperario: true, class: 'automatizacion-morado'})
                //#endregion

                element.elementos = elementosFinal;
            }
        })

        console.log(this.automatizaciones);
    }
}
