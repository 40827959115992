<style>
    .k-text-error {
        display: none;
    }

    :host /deep/ .k-grid tbody td {
        white-space: nowrap;
        line-height: 20px;
        padding: 8px 12px;
    }

    :host /deep/ .k-grid .k-grid-content td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>

<!-- GRID: Tooltip -->
<ng-template #template let-anchor>
    <span *ngIf="this.target == 'usuario'">
        <span>
            {{ this.listaNotas[anchor.nativeElement.parentElement.parentElement.parentElement.attributes[3].value].nombreUsuario }}
            {{ this.listaNotas[anchor.nativeElement.parentElement.parentElement.parentElement.attributes[3].value].apellidoUsuario }}
        </span>
    </span>
    <span *ngIf="this.target == 'lectores'">
        <span *ngFor="let lector of this.dataGrid[anchor.nativeElement.parentElement.parentElement.parentElement.attributes[3].value].leidoPor.split(',')">
            {{ this.usuariosColores[lector][1].concat(' ',this.usuariosColores[lector][2]) }} <br>
        </span>
    </span>
</ng-template>

<!-- GRID -->
<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">
    <kendo-grid [kendoGridBinding]="dataGrid" 
                [sortable]="true" 
                [navigable]="true"
                [selectable]="{enabled: true, mode: 'multiple'}" 
                filterable="menu" 
                [pageSize]="30" 
                kendoGridSelectBy="id"
                [selectedKeys]="seleccionados" 
                (selectionChange)="selectionChange($event)"
                (cellClick)="onClick_Editar()">
        <!-- BOTONES ARRIBA -->
        <ng-template kendoGridToolbarTemplate position="top">
            <a [routerLink]="['/notas/crear']" (click)="cargado=false" class="btn btn-primary btn-sm mr-1">
                {{ 'nuevo' | translate }}</a>
            <button (click)="onClick_Editar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate }}</button>
            <button (click)="onClick_Archivar()" class="btn btn-info  btn-sm mr-1" [disabled]="this.verArchivados">{{ 'Archivar' | translate }}</button>
            <button (click)="onClick_Elimnar(eliminar)" class="btn btn-danger btn-sm mr-1">{{ 'eliminar' | translate }}</button>
            <button (click)="onClick_VerArchivados()" class="btn-sm mr-1 float-right"
                [ngClass]="{ '': !verArchivados, 'bg-info text-white': verArchivados }">{{ 'archivados' | translate }}
            </button>
        </ng-template>
        <!-- CHECKBOX -->
        <kendo-grid-checkbox-column width="3%">
            <ng-template kendoGridHeaderTemplate>
                <input class="k-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox
                    (selectAllChange)="myFunctions.onSelectAllChangeGrid($event,seleccionados,listaNotas, 'id')">
                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
            </ng-template>
        </kendo-grid-checkbox-column>
        <!-- REFERENCIA -->
        <kendo-grid-column width="15%" [style]="{'text-align': 'left'}" field="referencia"
            title="{{ 'referencia' | translate}}" *ngIf="cargado">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.referencia != 'OPERARIO'">{{ dataItem.referencia }}</span>
                <span *ngIf="dataItem.referencia == 'OPERARIO'">
                    {{ listaOperarios[dataItem.idReferencia].nombre }} 
                    {{ listaOperarios[dataItem.idReferencia].apellido1 }}</span>
            </ng-template>
        </kendo-grid-column>
        <!-- USUARIO -->
        <kendo-grid-column width="8%" [style]="{'text-align': 'left'}" field="inicialesUsuario"
            title="{{ 'usuario' | translate}}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>
                    <span>
                        <span *ngIf="dataItem.inicialesUsuario != ''" class="grid-circulo mr-n2" [style.background-color]="this.usuariosColores[dataItem.usuario] + '!important'">
                            {{ dataItem.inicialesUsuario.toUpperCase() }}
                        </span>
                        <span *ngIf="dataItem.inicialesUsuario == ''" class="grid-circulo"><i class="fas fa-user-alt"></i></span>
                    </span>
                </span>
            </ng-template>
        </kendo-grid-column>
        <!-- FECHA -->
        <kendo-grid-column width="12%" [style]="{'text-align': 'center'}" field="fecha"
            title="{{ 'fecha' | translate}}"></kendo-grid-column>
        <!-- COMENTARIO -->
        <kendo-grid-column width="38%" [style]="{'text-align': 'left'}" field="comentario"
            title="{{ 'Comentario' | translate}}">
        </kendo-grid-column>
        <!-- PERMANENTE -->
        <kendo-grid-column width="10%" [style]="{'text-align': 'center'}" field="permanente"
            title="{{ 'Permanente' | translate}}">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.permanente" class="k-icon k-i-lock"></span>
                <span *ngIf="!dataItem.permanente" class="k-icon k-i-unlock"></span>
            </ng-template>
        </kendo-grid-column>
        <!-- LEIDO POR -->
        <kendo-grid-column width="10%" [style]="{'text-align': 'center'}" field="leidoPor"
            title="{{ 'leidopor' | translate}}">
            <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngFor="let lectores of dataItem.leidoPor.split(',') | slice:0:3">
                        <span *ngIf="this.usuariosColores[lectores] != 'undefined' && cargado">
                            <span class="grid-circulo" [style.background-color]="this.usuariosColores[lectores][0] + '!important'" *ngIf="dataItem.leidoPor != ''">
                                {{ this.usuariosColores[lectores][1].substring(0, 1).concat(this.usuariosColores[lectores][2].substring(0, 1)) }}
                            </span>
                        </span>
                    </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'filterContainsOperator' | translate}}"
        filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
        filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
        filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
        groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
        noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
    </kendo-grid>
</div>

<!-- MODAL: Eliminar Nota -->
<ng-template #eliminar let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ "eliminarnota" | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">
            {{ "no" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="eliminarNota()">
            {{ "si" | translate }}
        </button>
    </div>
</ng-template>