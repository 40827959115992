import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';


const baseUrl = `${environment.apiUrl}/historicoOperaciones`;

//export class datosExcel {
//  datos: FormData;
//  idOperacion: number;
//}
@Injectable()
export class HistoricoOperacionesService {
  constructor(private http: HttpClient) {
  }//Get_datosgenerales

  // inyectoras
  Get_procesos_inyeccion(id: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_procesos_inyeccion`, { id}, { withCredentials: true });
  }

  Get_procesos_inyeccion_conRowNumer(id: number, fechaInicio: Date, fechaFin: Date, filtroCompleto = '') {
    return this.http.post<JSON>(`${baseUrl}/Get_procesos_inyeccion_rowNumber`, { id, fechaInicio, fechaFin, filtroCompleto }, { withCredentials: true });
  }

  //SELECT
  get_piezasDatosGenerales(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_datosgenerales`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_operacionesAcabadasVista(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionesfinalizadasvista`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_datosGenerales(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_datosgenerales`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_fechasOpProy(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_fechasoperacionesproyectos`, { idPieza }, { withCredentials: true });
  }

  //SELECT
  get_tablaValores(idHistorico_Operaciones: number, idsValores, frecuencia, fecha) {
    return this.http.post<JSON>(`${baseUrl}/Get_valores`, { idHistorico_Operaciones, idsValores, frecuencia, fecha }, { withCredentials: true });
  }

  get_tablaValores_piezas(idHistorico_Operaciones, textoDesatendido) {
    return this.http.post<JSON>(`${baseUrl}/Get_valores_piezas`, { idHistorico_Operaciones , textoDesatendido}, { withCredentials: true });
  }

  getdatosSerieUtillaje(idUtillaje): Observable<any> {
    return this.http.get(`${baseUrl}/getdatosSerieUtillaje/${idUtillaje}`);
  }

  //SELECT
  Get_filtro_Ofs() {
    return this.http.post<JSON>(`${baseUrl}/Get_filtro_Ofs`, {}, { withCredentials: true });
  }
  //SELECT
  get_desviosMaquinas(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_desviosoperacionespieza`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_AlarmasMaquinas(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_alarmasoperacionespieza`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_trabajomaquinas(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_trabajomaquinas`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_TiemposOperacionesFinalizadas(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionesfinalizadas`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_TiemposOperacionesEnCurso(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionesencurso`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_TiemposOperacionesPendientes(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionespendientes`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_TiemposLineaHistorico(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_tiemposlineahistorico`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_TiemposLineaPlanificador(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_tiemposlineaplanificador`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_historicoOperacionesNoPlanificadasPieza(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionesnoplanificadas`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_historicoOperacionesNoIniciadasPieza(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionesnoiniciadas`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_historicoOperacionesIniciadasPieza(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_operacionesiniciadas`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_historicoOperacionesPieza(idPieza: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_historicooperacionpieza`, { idPieza }, { withCredentials: true });
  }
  //SELECT
  get_piezas(fechaIni, fechaFin,
    listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
    listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones, terminado,
    textoDesatendido, valores, estado, filtroCompleto) {
    return this.http.post<JSON>(`${baseUrl}/Get_piezas`, {
      fechaIni, fechaFin,
      listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
      listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones,
      terminado, textoDesatendido, valores, estado, filtroCompleto
    }, { withCredentials: true });
  }
  //SELECT
  get(idHistorico_piezas: number,
    fechaIni, fechaFin,
    listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
    listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones, terminado) {
      console.log("get")
    return this.http.post<JSON>(`${baseUrl}/Get`, {
      idHistorico_piezas,
      fechaIni, fechaFin,
      listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
      listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones,
      terminado
    }, { withCredentials: true });
  }
  //SELECT
  get_pieza(idHistorico_piezas: number,
    fechaIni, fechaFin,
    listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
    listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones, terminado,
    textoDesatendido) {
      console.log("get_pieza")
    return this.http.post<JSON>(`${baseUrl}/Get_pieza`, {
      idHistorico_piezas,
      fechaIni, fechaFin,
      listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
      listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones,
      terminado, textoDesatendido
    }, { withCredentials: true });
  }
  //SELECT
  get_info_HP_Operacion(idHP: number,
    fechaIni, fechaFin,
    listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
    listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones, terminado, textoDesatendido) {
    return this.http.post<JSON>(`${baseUrl}/Get_info_HP_Operacion`, {
      idHP,
      fechaIni, fechaFin,
      listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
      listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones,
      terminado, textoDesatendido: textoDesatendido
    }, { withCredentials: true });
  }
  ////SELECT
  //Get_operarios_HP_Operacion(idHP: number) {
  //  return this.http.post<JSON>(`${baseUrl}/Get_operarios_HP_Operacion`, { idHP }, { withCredentials: true });
  //}  
  //SELECT
  Get_incidencias_HP_Operacion(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_incidencias_HP_Operacion`, { idHP }, { withCredentials: true });
  }
  //SELECT
  Get_procesos_HP_Operacion(idHP: number,
    fechaIni, fechaFin,
    listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
    listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones, terminado, textoDesatendido) {
    return this.http.post<JSON>(`${baseUrl}/Get_procesos_HP_Operacion`, {
      idHP,
      fechaIni, fechaFin,
      listaIdTurnos, listaIdMaquinas, listaIdHerramientas, listaIdOperarios,
      listaIdOfs, listaIdClientes, listaPiezas, listaPartes, listaOperaciones,
      terminado, textoDesatendido
    }, { withCredentials: true });
  }
  //SELECT
  Get_valores_HP_Operacion(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_valores_HP_Operacion`, { idHP }, { withCredentials: true });
  }
  //SELECT
  Get_imagenes_HP_Operacion(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_imagenes_HP_Operacion`, { idHP }, { withCredentials: true });
  }

  //SELECT
  Get_datos(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_datos`, { idHP }, { withCredentials: true });
  }

  //SELECT
  Get_imagenes_Procesos(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_imagenes_Procesos`, { idHP }, { withCredentials: true });
  }
  //SELECT
  Get_rutas_operaciones_HP_Pieza(idHistorico_piezas: number) {
    console.log("Get_rutas_operaciones_HP_Pieza")
    return this.http.post<JSON>(`${baseUrl}/Get_rutas_operaciones_HP_Pieza`, { idHistorico_piezas }, { withCredentials: true });
  }
  //SELECT
  Get_nSerie(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/Get_nSerie`, { idHP }, { withCredentials: true });
  }
  //SELECT
  Get_operacin_HO(idHP: number) { //Es Historico Operaciones! no HP
    return this.http.post<JSON>(`${baseUrl}/Get_operacin_HO`, { idHP }, { withCredentials: true });
  }

  //SET
  Set_estado(idHP: number, idEstado: number, observacion: string, idPerdida: number, idOperacionPerdida: number) {
    return this.http.post<JSON>(`${baseUrl}/Set_estado`, { idHP, idEstado, observacion, idPerdida, idOperacionPerdida }, { withCredentials: true }).subscribe(); //NO se espera a la respuesta para seguir adelante
  }
  //SET
  Set_estadoS(idHPs: string, IDsSoloFinalizados: string, idEstado: number, observacion: string) {
    return this.http.post<JSON>(`${baseUrl}/Set_estadoS`, { idHPs, IDsSoloFinalizados, idEstado, observacion }, { withCredentials: true })
  }
  //SET
  set_HO_cancelar(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/set_HO_cancelada`, { idHP }, { withCredentials: true }); //se espera a la respuesta para seguir adelante
  }
  //SET
  set_HO_liberar(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/set_HO_liberar`, { idHP }, { withCredentials: true }); //se espera a la respuesta para seguir adelante
  }
  //SET
  set_HO_respuesta(idHP: number) {
    return this.http.post<JSON>(`${baseUrl}/set_HO_respuesta`, { idHP }, { withCredentials: true }); //se espera a la respuesta para seguir adelante
  }
  //SET
  set_HO(idHP: number, edicion: string, lote: string, nSerie: string) {
    return this.http.post<JSON>(`${baseUrl}/set_HO`, { idHP, edicion, lote, nSerie }, { withCredentials: true }); //se espera a la respuesta para seguir adelante
  }

  set_HOV(dtValores) {
    var json: JSON = JSON.parse(JSON.stringify(dtValores));
    return this.http.post<JSON>(`${baseUrl}/set_HOV`, { json }, { withCredentials: true }); //se espera a la respuesta para seguir adelante
  }
  //SET
  CambiarRuta(idHP: number, idOperacion: number) {
    return this.http.post<JSON>(`${baseUrl}/CambiarRuta`, { idHP, idOperacion }, { withCredentials: true });
  }
  //SET
  cambiarEstimadosOperacion(listaIdsdOperaciones: any, listaIdsdOperacionesPredefinidas: any, listaTEstimadosEjecucion: any, listaTEstimadosPreparacion: any) {
    return this.http.post<JSON>(`${baseUrl}/cambiarEstimadosOperacion`, { listaIdsdOperaciones: listaIdsdOperaciones, listaIdsdOperacionesPredefinidas: listaIdsdOperacionesPredefinidas, listaTEstimadosEjecucion: listaTEstimadosEjecucion, listaTEstimadosPreparacion: listaTEstimadosPreparacion }, { withCredentials: true });
  }
  //SET
  set_Imagen_HO(idHO: number, operarioId: number, archivoAgregar: any, archivoAgregarNombre: any, nombreImagenAnterior: any) {
    return this.http.post<JSON>(`${baseUrl}/set_imagen_HO`, { idHO, operarioId, archivoAgregar, archivoAgregarNombre, nombreImagenAnterior }, { withCredentials: true });
  }

  importarExcel(datos): Observable<any> {
    return this.http.post<any>(baseUrl + "/importarExcel", datos , { withCredentials: true });
  }

  obtenerDireccionRuta(idOperacion: number){
    return this.http.post<any>(baseUrl + "/obtenerDireccionRuta", { idOperacion } , { withCredentials: true });
  }

  crearCotas_HO_pieza(idHO, pieza_crearCotas){
    return this.http.post<JSON>(`${baseUrl}/crearCotas_HO_pieza`, { idHO, pieza_crearCotas }, { withCredentials: true });
  }  
  
  Get_ClientesPiezasOfsOperacionesPartes() { //al actualizar todos los filtros a monday, esta funcion deveria de terminar desapareciendo (se ha acelerado pero no quitado la llamada)
    return this.http.post<JSON>(`${baseUrl}/Get_ClientesPiezasOfsOperacionesPartes/`, {}, { withCredentials: true });
  }
}
