import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
  translateAggregateResults,
  DataResult,
  DataSourceRequestState
} from '@progress/kendo-data-query';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { query } from '@angular/animations';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/piezaspredefinidas`;
@Injectable()
export class PiezasPredefinidasService extends BehaviorSubject<any> {

  public loading = false;

  constructor(private http: HttpClient) {
    super(null);
  }

  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }
  

  public GetAll(action: string = '',param?:string, data?: ''): Observable<any> {
    this.loading = true;
    var headers, params;

    if(param == undefined) {
      param=''

    }
    
    return this.http
      .get(baseUrl +"/piezas", { withCredentials: true });
  }

  public GetByID( idParte : number): Observable<any> {
    this.loading = true;
    var headers, params;


    //piezas/<idOF>/<idPieza>/<idParte>
    
    return this.http
      .get(baseUrl + "/" + idParte );
  }

  public GetByIDSelectedCombo(data: any): Observable<any> {
    this.loading = true;
    var headers, params;


    //piezas/<idOF>/<idPieza>/<idParte>

    return this.http
      .get(baseUrl + "/combo/" + data.idpieza);
  }

  public GetReferencias(): Observable<any> {
    this.loading = true;
    var headers, params;


    //piezas/<idOF>/<idPieza>/<idParte>

    return this.http
      .get(baseUrl + "/referencias" );
  }


  public delete(data?: any): Observable<any> {
    //this.loading = true;

    var formData: any = new FormData();
    formData.append("idpieza", data.ids);
    

    return this.http
      .post<JSON>(baseUrl + "/eliminar" , formData,{ withCredentials: true });
     
  }
  
  importarCotas(data) {
    return this.http.post<any>(baseUrl + "/importarCotas", data, { withCredentials: true });
  }

  uploadImage(data) {
    return this.http.post<any>(baseUrl + "/uploadimage", data, { withCredentials: true });
  }


  create(data, filename, archivoBase64) {

    var fIni:Date =data.fechaini;
    var fFin: Date = data.fechafin;
    data['filename'] = filename;
    data['archivo'] = archivoBase64;

    return this.http.post<any>(baseUrl + "/crear",data, { withCredentials: true });
  }

  update(data, filename, archivoBase64) {

    data['filename'] = filename;
    data['archivo'] = archivoBase64;
    var formData: any = new FormData();
    var fIni:Date =data.fechaini;
    var fFin: Date = data.fechafin;

    return this.http.post<any>(baseUrl + "/editar", data , { withCredentials: true });
  }


  duplicar(data) {


    return this.http.post<any>(baseUrl + "/duplicar", {
      id: data.idpieza,
      idparte:data.idparte,
      IDOF: data.idOF,
      Cantidad: data.cantidad,
      Coste: data.coste,
      Dureza: data.dureza,
      EsPieza: data.espieza == undefined ? false : data.espieza,
      Hechos: data.hechos,
      idERPparte: data.idERPparte,
      idERPpieza: data.idERPpieza,
      idReferencia: data.idReferencia,
      limitado: data.limitado,
      NumPlano: data.numplano,
      Orden: data.orden == undefined ? 0 : data.orden,
      Parte: data.parte,
      refParte: data.refparte,
      RutasMultiples: data.rutasmultiples,
      Terminado: data.terminado,
      TieneRutas: data.tienerutas,
      Nombre: data.nombre,
      Descripcion: data.descripcion
    }, { withCredentials: true });

  }

  update_dependencias(dependenciasPieza) {
    return this.http.post<JSON>(`${baseUrl}/update_dependencias/`, { dt: dependenciasPieza }, { withCredentials: true });
  }

}
