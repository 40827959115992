import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/clientes`;

@Injectable()
export class ClientesService {

  constructor(private http: HttpClient) {}

  getClientes() {
    return this.http.get(baseUrl);
  }
  getClienteById(id: number) {
    return this.http.get(baseUrl+"/"+id);
  }

  getGrupos() {
    return this.http.get(`${baseUrl}/grupos`);
  }

  crearCliente(data) {
    return this.http.post<any>(`${baseUrl}/crear`, data, { withCredentials: true });
  }
  editarCliente(data) {
    return this.http.post<any>(`${baseUrl}/editar`, data, { withCredentials: true });
  }

  removeCliente(id: number) {
    return this.http.get(baseUrl+"/remove/"+id);
  }

}
