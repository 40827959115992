import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyFunctions } from '@app/_helpers';
import { AlertService, InformesPersonalizadosDisenadorService, MenuService, InformePersonalizadoService, AppComponent } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CellClickEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-informes-personalizados',
  templateUrl: './informesPersonalizadosDisenador.component.html',
})

export class InformesPersonalizadosDisenadorComponent {

  //Variables basicas
  private translate: TranslateService;

  //Seleccion
  public seleccionados: any[] = [];

  //Listas
  public informes: any[] = [];
  public tiposGrafico: any[] = [];

  //Lineas
  public lineas: any[] = [];

  //Informes
  public tituloInforme: string;

  //Constructor
  constructor(
    translate: TranslateService,
    private alertService: AlertService,
    private menuService: MenuService, public router: Router,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    private modalService: NgbModal,
    private informePersonalizadoService: InformePersonalizadoService,
    public informesPersonalizadosDisenadorService: InformesPersonalizadosDisenadorService,
    private appComponent: AppComponent) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('informespersonalizados').toUpperCase();
  }

  /**
   * Funcion ngOnInit
   */
  ngOnInit() {
    this.informesPersonalizadosDisenadorService.Get_Informes().subscribe(
      (result: any) => {
        this.informes = result;
      });
  }

  onClick_Elimnar(eliminar: any) {
    // if(this.seleccionados.length < 1){
    //   this.alertService.warn(this.translate.instant('seleccionainforme'), { keepAfterRouteChange: true });
    // } else if(this.seleccionados.length > 1){
    //   this.alertService.error(this.translate.instant('masdeuninformeseleccionado'), { keepAfterRouteChange: true });
    // } else{
      this.modalService.open(eliminar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    // }
  }

  onClick_Editar() {
    if(this.seleccionados.length < 1){
      this.alertService.warn(this.translate.instant('seleccionainforme'), { keepAfterRouteChange: true });
    } else if(this.seleccionados.length > 1){
      this.alertService.error(this.translate.instant('masdeuninformeseleccionado'), { keepAfterRouteChange: true });
    } else{
      this.router.navigate(['editarInformesPersonalizados/', this.seleccionados[0]]);
    }
  }

  onClick_Duplicar() {

    if (this.seleccionados.length < 1) {

      this.alertService.warn(this.translate.instant('seleccionainforme'), { keepAfterRouteChange: true });

    } else if (this.seleccionados.length > 1) {

      this.alertService.error(this.translate.instant('masdeuninformeseleccionado'), { keepAfterRouteChange: true });

    } else {

      this.informesPersonalizadosDisenadorService.Get_TiposGrafico().subscribe(
        (result: any) => {
          this.tiposGrafico = result;
          console.table(this.tiposGrafico);
        });

      this.informesPersonalizadosDisenadorService.Get_LineasByIdInforme(this.seleccionados[0]).subscribe(
        (result: any) => {
          this.lineas = result;
          this.lineas.forEach(linea => {
            var count = 0;
            if (linea.idModulo1 != -1 && linea.idModulo1 != undefined) count++;
            if (linea.idModulo2 != -1 && linea.idModulo2 != undefined) count++;
            if (linea.idModulo3 != -1 && linea.idModulo3 != undefined) count++;
            linea.columnas = count;
            linea.nombreModulo1 = "";
            linea.nombreModulo2 = "";
            linea.nombreModulo3 = "";
            this.tiposGrafico.forEach(tipo => {
              if (tipo.id == linea.idTipoModulo1) linea.nombreModulo1 = tipo.nombre;
              if (tipo.id == linea.idTipoModulo2) linea.nombreModulo2 = tipo.nombre;
              if (tipo.id == linea.idTipoModulo3) linea.nombreModulo3 = tipo.nombre;
            });
            // linea.idModulo1 = linea.idTipoModulo1;
            // linea.idModulo2 = linea.idTipoModulo2;
            // linea.idModulo3 = linea.idTipoModulo3;
            linea.modulo1ColumnasTabla = linea.modulo1ColumnasTabla.split(",");
            linea.modulo2ColumnasTabla = linea.modulo2ColumnasTabla.split(",");
            linea.modulo3ColumnasTabla = linea.modulo3ColumnasTabla.split(",");

            linea.modulo1ColumnasOrden = linea.modulo1ColumnasOrden.split(",");
            linea.modulo2ColumnasOrden = linea.modulo2ColumnasOrden.split(",");
            linea.modulo3ColumnasOrden = linea.modulo3ColumnasOrden.split(",");

            linea.modulo1ColumnasTamanno = linea.modulo1ColumnasTamanno.split(",");
            linea.modulo2ColumnasTamanno = linea.modulo2ColumnasTamanno.split(",");
            linea.modulo3ColumnasTamanno = linea.modulo3ColumnasTamanno.split(",");

            linea.modulo1ColumnasActivo = linea.modulo1ColumnasActivo.split(",");
            linea.modulo2ColumnasActivo = linea.modulo2ColumnasActivo.split(",");
            linea.modulo3ColumnasActivo = linea.modulo3ColumnasActivo.split(",");

            linea.modulo1ColumnasActivo = linea.modulo1ColumnasActivo.map(j => JSON.parse(j));
            linea.modulo2ColumnasActivo = linea.modulo2ColumnasActivo.map(j => JSON.parse(j));
            linea.modulo3ColumnasActivo = linea.modulo3ColumnasActivo.map(j => JSON.parse(j));
          });
          console.table(this.lineas);

          var informe = this.informes.filter(obj => {
            return obj.id == this.seleccionados[0];
          })
          this.tituloInforme = informe[0].nombre;

          this.lineas[0].tituloInforme = this.tituloInforme + "_Copia";
          console.table(this.lineas);
          this.informesPersonalizadosDisenadorService.Insert_Informe(this.lineas).subscribe(
            (result: any) => {
              console.log("Informe duplicado");
              this.informesPersonalizadosDisenadorService.Get_Informes().subscribe(
                (result: any) => {
                  this.informes = result;
                  this.informePersonalizadoService.set_informes(result);
                  this.appComponent.actualizarMenuInformesPersonalizados();
                });
            });
        });
    }
  }

  eliminarInforme() {
    this.informesPersonalizadosDisenadorService.Delete_Informe(this.seleccionados).subscribe(
      (result: any) => {
        console.log("Informe eliminado");
        this.modalService.dismissAll();
          
        this.informesPersonalizadosDisenadorService.Get_Informes().subscribe(
          (result: any) => {
            this.informes = result;
            this.informePersonalizadoService.set_informes(result);
            this.appComponent.actualizarMenuInformesPersonalizados();
          });
      });
  }

  cellClick(e: CellClickEvent) {
    if (e.columnIndex > 0) {
      console.log(e.rowIndex);
      this.router.navigate(['editarInformesPersonalizados/', e.dataItem.id]);
    }
  }
}
