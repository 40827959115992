import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/* SERVICES*/
import { MaquinasService, UsuariosService, PlanificadorService, MenuService, ConfiguracionService, } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

import { ActivatedRoute } from '@angular/router';

import { TooltipDirective } from '@progress/kendo-angular-tooltip';

declare var GanttChartDrag: any;
declare var GanttChartClick: any;
declare var GanttChartPlanificadorTemporal: any;

import { PageChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { MaquinaDetalleAlarmasControladasComponent } from '../maquinaDetalle/maquinaDetalleAlarmasControladas.component';
import { MyFunctions } from '@app/_helpers';

import { GroupResult, groupBy } from '@progress/kendo-data-query';
import * as moment from 'moment';

@Component({
  selector: 'app-planificadorCortoTemporal',
  templateUrl: './planificadorCortoTemporal.component.html'
})

export class PlanificadorCortoTemporalComponent {
  @ViewChild('popupReorganizar') popupReorganizar: NgbModalRef;
  @ViewChild('popupUsarVersion') popupUsarVersion: NgbModalRef;
  @ViewChild('popupBorrarVersion') popupBorrarVersion: NgbModalRef;
  @ViewChild('popupCopiarAVersion') popupCopiarAVersion: NgbModalRef;
  @ViewChild('popupVistaTemporal') popupVistaTemporal: NgbModalRef;
  user = this.userService.userValue;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  //FILTRO
  public dtConfiguracionPlanificador: any = {};
  public multiplicadorTiempos: number = 0.0;
  public aplicarTiempoEstimado: boolean = true;
  public aplicarIneficiencias: boolean = false;
  public Jplanificadores: any;
  public JplanificadoresSelected: any;
  public JplanificadoresSinOriginal: any;
  public JplanificadoresSelectedCopiar: any;
  //TABLAS PRINCIPALES
  private DatPlanificador: JSON;
  private DatMaquinas: any;
  private DatTurnos: JSON;
  private tasks: any;
  //GANTT
  private ganttChart: any;

  //AYUDAS FECHAS
  private today: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate());
  private now: Date = new Date(this.myFunctions.getDateNow().getFullYear(), this.myFunctions.getDateNow().getMonth(), this.myFunctions.getDateNow().getDate(), this.myFunctions.getDateNow().getHours(), this.myFunctions.getDateNow().getMinutes(), this.myFunctions.getDateNow().getSeconds());

  //INFO
  public visibleInfo: boolean = false;
  public infoOF: string;
  public infoCliente: string;
  public infoProyecto: string;
  public infoPlano: string;
  public infoRefPieza: string;
  public infoPieza: string;
  public infoParte: string;

  public JsemanaSelected: any;
  public JsemanasSelected: any;
  public Jsemanas = [];

  public JmaquinaSelected: any;
  public Jmaquinas: any;
  public JmaquinaSelectedLargo: any;
  public JmaquinasSelectedLargo: any;
  public JmaquinasLargo: any;

  public Joperaciones: any;//GRID PROGRAMAS
  public operacionesSelected = [];//GRID PROGRAMAS SELECCIONADOS

  public requiereMaquina: boolean = false;
  public requiereMaquinaLargo: boolean = false;
  public requiereSemana: boolean = false;

  public DatOperacionesSinCorto: JSON;
  public JOperacionesSinCortoTodo: any;
  public JOperacionesSinCortoView: any;
  public JOperacionesSinCorto: any;
  public JOperacionesSinCortoSelecteds = [];

  public prioridadCliente: number;
  public prioridadFacturacion: number;
  public prioridadOF: number;
  public prioridadMaquina: number;

  public visibleUsarVersion: boolean = false;

  public loadingPanel: boolean = false;

  public pageSize = 40;
  public skip = 0;

  //TEMPORAL
  //private DatPlanificador_temporal: JSON;
  //private operacionesHechas = JSON;


  private dataFiltro: any;
  public listaOfs: any;
  public ofsSeleccionados: any;

  public listaClientes: any;
  public clientesSeleccionados: any;

  public listaPiezas: any;
  public piezasSeleccionados: any;


  public JgruposMaquinas: any;
  public JgruposMaquinasSelected: any;
  public JgruposMaquinasMaquinas: any;

  popupReorganizandoVersion: boolean = false;

  //AREA PRODUCTIVA / SECCION
  private secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //OPERACUIBES HECHAS AHORA MISMO
  private operacionesActuales: any = [];

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  //"LOAD"
  constructor(private maquinasService: MaquinasService, private userService: UsuariosService, private translateService: TranslateService,
    private modalService: NgbModal, private planificadorService: PlanificadorService, public myFunctions: MyFunctions,
    public route: ActivatedRoute, private menuService: MenuService, private configuracionService: ConfiguracionService) {

    /*CARGAR AREAS PRODUCTIVAS*/
    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined)
      this.secciones = an1.filter(f => f.activo === true);
    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(
        json => {
          this.userService.secciones = json;

          //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
          var an1: any = this.userService.secciones;
          this.secciones = an1.filter(f => f.activo === true);

          var an: any = this.secciones;
          this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

          an.forEach(
            row => {
              if (row.activo)
                this.seccionesSeleccionadas.push(row);
            });
        });
    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(
        row => {
          if (row.activo)
            this.seccionesSeleccionadas.push(row);
        });
    }


    this.loadingPanel = true;

    this.menuService.titulo = this.translateService.instant('vistaTemporal').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    this.planificadorService.getPlanificadoresActivos(this.translateService.instant('real')).subscribe(
      json => {
        this.Jplanificadores = json;
        this.JplanificadoresSelected = json[this.route.snapshot.params['version'] - 1]; //aqui se pasa el planificador seleccionado en la URL!

        var copia: any = [];
        copia.push(json[0]);
        copia.push(json[1]);
        copia.push(json[2]);
        copia.push(json[3]);

        this.JplanificadoresSinOriginal = copia;
        this.JplanificadoresSelectedCopiar = copia[this.route.snapshot.params['version'] - 1];

        // CONFIGURACION DE PLANIFICADOR -- AQUI SE PASA POR URL LO SELECCIONADO EN EL CORTO!
        //this.configuracionService.get_configuracion_planificador().subscribe(result => {
        //this.dtConfiguracionPlanificador = result[0];

        //this.aplicarTiempoEstimado = !this.dtConfiguracionPlanificador.predictivO_seleccionar_porDefecto;
        //this.aplicarIneficiencias = this.dtConfiguracionPlanificador.ineficienciA_seleccionar_porDefecto;
        //this.multiplicadorTiempos = this.dtConfiguracionPlanificador.multiplicadoR_seleccionar_porDefecto;

        this.aplicarTiempoEstimado = this.route.snapshot.params['tiempoEstimado'] == 'true';
        this.aplicarIneficiencias = this.route.snapshot.params['ineficiencia'] == 'true';

        this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
          json => {
            this.DatOperacionesSinCorto = json;

            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
            this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
            this.loadItems()
          }
        );

        var r1, r2, r3, r4, r5: boolean = false;
        var version = 1;

        if (this.JplanificadoresSelected != undefined)
          version = this.JplanificadoresSelected.value;

        this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          json => {
            r1 = true;
            this.DatPlanificador = json;
            if (r1 && r2 && r3 && r4 && r5)
              //this.cargarGantt();
              this.cargarGantt_operaciones_temporal();
          });

        var maquinas_planificador_model = this.maquinasService.get_maquinas_planificador_model();
        if (maquinas_planificador_model == false) {
          this.maquinasService.getMaquinasPlanificador().subscribe(json => {
            this.maquinasService.set_maquinas_planificador_model(json);
            this.Jmaquinas = this.maquinasService.get_maquinas_planificador_model();
            this.JmaquinasLargo = this.maquinasService.get_maquinas_planificador_model();
            this.DatMaquinas = this.maquinasService.get_maquinas_planificador_model();
            r2 = true;
            if (r1 && r2 && r3 && r4 && r5)
              //this.cargarGantt();
              this.cargarGantt_operaciones_temporal();
          })
        }
        else {
          this.Jmaquinas = maquinas_planificador_model;
          this.JmaquinasLargo = maquinas_planificador_model;
          this.DatMaquinas = maquinas_planificador_model;
          r2 = true;
          if (r1 && r2 && r3 && r4 && r5)
            //this.cargarGantt();
            this.cargarGantt_operaciones_temporal();
        }

        this.planificadorService.Get_turnos(this.now).subscribe(
          json => {
            r3 = true;

            this.DatTurnos = json;
            if (r1 && r2 && r3 && r4 && r5)
              //this.cargarGantt();
              this.cargarGantt_operaciones_temporal();
          });

        this.maquinasService.getGruposMaquinasPlanificadorTodos().subscribe(
          json => {
            r4 = true;
            this.JgruposMaquinas = json.data;

            if (r1 && r2 && r3 && r4 && r5)
              //this.cargarGantt();
              this.cargarGantt_operaciones_temporal();
          }
        );

        this.maquinasService.getGrupoMaquinas_MaquinasPlanificadorById('0').subscribe(
          json => {
            r5 = true;
            this.JgruposMaquinasMaquinas = json;

            if (r1 && r2 && r3 && r4 && r5)
              //this.cargarGantt();
              this.cargarGantt_operaciones_temporal();
          }
        );


      });
    //});

    this.planificadorService.GetFechaSemanasLimite().subscribe(
      (json) => {
        if (Object.keys(json).length > 0) {
          var a: any = json[0];

          var fecha = this.startOfWeek(this.dateCopy(this.now));
          var semana = this.getNumberOfWeek(fecha);
          var año = fecha.getFullYear();

          var fechaMax = a.fechaMax;
          var añoMax = a.año;
          var semanaMax = a.semana;

          var index = 1;
          while (año <= añoMax || (año == añoMax && semana <= semanaMax)) {
            //crear linea Json para añadir al "grid"
            var fecha2 = new Date(fecha);
            var js: any = {};
            js['value'] = index;
            js['fecha'] = fecha2;
            js['año'] = año;
            js['semana'] = semana;
            js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
            this.Jsemanas.push(js);

            //actualizar variables para proximo loop
            index++;
            fecha.setDate(fecha.getDate() + 7);
            semana = this.getNumberOfWeek(fecha);
            año = fecha.getFullYear();
          }
        } else {
          var fecha = this.startOfWeek(this.dateCopy(this.now));
          var semana = this.getNumberOfWeek(fecha);
          var año = fecha.getFullYear();


          var index = 1;
          //crear linea Json para añadir al "grid"
          var fecha2 = new Date(fecha);
          var js: any = {};
          js['value'] = index;
          js['fecha'] = fecha2;
          js['año'] = año;
          js['semana'] = semana;
          js['text'] = semana + ' (' + this.dateToYYYYMMDD(fecha2) + ')';
          this.Jsemanas.push(js);
        }
      }
    );

    this.cargarDatosFiltro();
  }
  ngOnInit() {

  }


  cargarDatosFiltro() {
    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTES
    var version = 1;
    version = Number(this.route.snapshot.params['version']);
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_filtro_Ofs(version, 1, 0).subscribe((data: any) => {

      this.dataFiltro = data;

      var groupByCliente = [];
      var groupByPieza = [];
      var groupByOf = [];
      var groupByOperacion = [];
      var groupByParte = [];

      //GROUP BY POR OF
      data.forEach(function (a) {
        if (!this[a.idOf]) {
          this[a.idOf] = {
            idOf: a.idOf, nombreOf: a.nombreOf,
          };
          groupByOf.push(this[a.idOf]);
        }
      }, Object.create(null));

      this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
      this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

      //GROUP BY POR CLIENTE
      data.forEach(function (a) {
        if (!this[a.idCliente]) {
          this[a.idCliente] = {
            idCliente: a.idCliente, nombreCliente: a.nombreCliente,
          };
          groupByCliente.push(this[a.idCliente]);
        }
      }, Object.create(null));

      this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
      this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

      //GROUP BY POR PIEZA
      var kontagailua=1;
      var listaPiezasSinRepetidos=[];
      data.forEach(function (a) {
        // if (!this[a.idPieza]) {
        //   this[a.idPieza] = {
        //     idPieza: a.idPieza, nombrePieza: a.nombrePieza,
        //   };
        //   groupByPieza.push(this[a.idPieza]);
        // }
        if (listaPiezasSinRepetidos.filter(x => x.nombrePieza==a.nombrePieza).length==0 && a.idPieza!=-1)
        {
          listaPiezasSinRepetidos.push({idPieza: kontagailua, nombrePieza: a.nombrePieza, ids: [a.idPieza]});
          kontagailua++;
        }
        else
        {
          listaPiezasSinRepetidos.filter(x => x.nombrePieza==a.nombrePieza)[0].ids.push(a.idPieza);
        }
      }, Object.create(null));

      //this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
      this.listaPiezas = listaPiezasSinRepetidos;
      this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

    });

  }
  CambioFiltro() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    this.planificadorService.Get_filtro_Ofs(version, 1, 0).subscribe((data: any) => {

      this.dataFiltro = data;

    });
    var data: any = this.dataFiltro;

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idsPiezas = [];
    if (this.piezasSeleccionados != undefined)
    {
      //this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.idPieza));
      this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.ids));
      idsPiezas = idsPiezas.reduce((acc, curVal) => {return acc.concat(curVal)}, []); //flatten array of arrays
    }

    var groupByCliente = [];
    var groupByOf = [];
    var groupByPieza = [];

    //GROUP BY POR OF
    var lag: any = {};
    data.forEach(
      row => {
        if (!lag[row.idOf]
          && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)
        ) {
          lag[row.idOf] = { idOf: row.idOf, nombreOf: row.nombreOf };
          groupByOf.push(lag[row.idOf]);
        }
      });

    this.listaOfs = groupByOf.filter(item => (item.idOf != -1));
    this.listaOfs.sort((a, b) => (a.nombreOf > b.nombreOf) ? 1 : ((b.nombreOf > a.nombreOf) ? -1 : 0));

    //GROUP BY POR CLIENTE
    lag = {};
    data.forEach(
      row => {
        if (!lag[row.idCliente]
          && (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
          && (idsPiezas.includes(row.idPieza) || idsPiezas[0] == undefined)) {
          lag[row.idCliente] = {
            idCliente: row.idCliente, nombreCliente: row.nombreCliente,
          };
          groupByCliente.push(lag[row.idCliente]);
        }
      });

    this.listaClientes = groupByCliente.filter(item => (item.idCliente != -1));
    this.listaClientes.sort((a, b) => (a.nombreCliente > b.nombreCliente) ? 1 : ((b.nombreCliente > a.nombreCliente) ? -1 : 0));

    //GROUP BY POR PIEZA
    lag = {};
    var kontagailua=1;
    var listaPiezasSinRepetidos=[];
    data.forEach(
      row => {
        if (//!lag[row.idPieza] &&
               (idsOFs.includes(row.idOf) || idsOFs[0] == undefined)
            && (idsClientes.includes(row.idCliente) || idsClientes[0] == undefined)) {
          // lag[row.idPieza] = {
          //   idPieza: row.idPieza, nombrePieza: row.nombrePieza,
          // };
          // groupByPieza.push(lag[row.idPieza]);
              if (listaPiezasSinRepetidos.filter(x => x.nombrePieza==row.nombrePieza).length==0)
              {
                listaPiezasSinRepetidos.push({idPieza: kontagailua, nombrePieza: row.nombrePieza, ids: [row.idPieza]});
                kontagailua++;
              }
              else listaPiezasSinRepetidos.filter(x => x.nombrePieza==row.nombrePieza)[0].ids.push(row.idPieza);

        }
      });

    //this.listaPiezas = groupByPieza.filter(item => (item.idPieza != -1));
    this.listaPiezas = listaPiezasSinRepetidos.filter(item => (item.idPieza != -1));;
    this.listaPiezas.sort((a, b) => (a.nombrePieza > b.nombrePieza) ? 1 : ((b.nombrePieza > a.nombrePieza) ? -1 : 0));

  }
  btnFiltrar() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.DatPlanificador = json;
        //this.cargarGantt();
        this.cargarGantt_operaciones_temporal();
      });

    if (version > 1)
      this.visibleUsarVersion = true;
    else
      this.visibleUsarVersion = false;
  }
  gruposMaquinasChanged() {
    this.ganttChart = undefined;
  }
  //FILTRO AREAS PRODUCTIVAS / SECCIONES
  seccionChanged() {
    this.ganttChart = undefined;
  }

  //CARGAR GANTT
  cargarGantt_operaciones_temporal() {
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;

    var r1, r2 = false;
    // COGER OPERACIONES PLANIFICADAS
    this.planificadorService.GetCorto_temporal(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        const copia = {}

        this.DatPlanificador = json;

        r1 = true;
        if (r1 && r2) {
          this.PREcargarGantt_temporal();
        }
      }
    );

    //COGER OPERACIONES HECHAS
    this.planificadorService.GetCorto_temporal_hechas().subscribe(
      json => {
        var an: any = json;

        an.forEach(row => {
          row.fecha = new Date(row.fecha);
        });

        // this.operacionesHechas = an;
        r2 = true;
        if (r1 && r2) {
          this.PREcargarGantt_temporal();
        }
      }
    );

  }

  PREcargarGantt_temporal() {
    var r1, r2: boolean = false;
    var version : number= 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    //PARA CONTAR BIEN EL TIEMPO EJECUTADO, se actualiza cada vez que se recarga el grafico.
    this.planificadorService.Get_tiempos_ejecutados(version).subscribe(
      json => {
        //CARGAR TIEMPOS EJECUTADOS START 
        //   Primero se descuenta el tiempo de la preparacion, luego se descuenta el tiempo de ejecucion
        var an: any = json;
        var operacionesOrdenadas2 = {};
        var operacionesEnEjecucion = {};
        an.forEach(
          a => {
            if (operacionesOrdenadas2[a['idOFs_Operacion']])
              operacionesOrdenadas2[a['idOFs_Operacion']] = operacionesOrdenadas2[a['idOFs_Operacion']] + a['tiempoTotal'];
            else
              operacionesOrdenadas2[a['idOFs_Operacion']] = a['tiempoTotal'];

            operacionesOrdenadas2[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['tiempoTotal'];
            operacionesEnEjecucion[a['idMaquina'] + '_' + a['idOFs_Operacion']] = a['enEjecucion'];
          });
        var an2: any = this.DatPlanificador
        //   quitar el tiempo de cada maquina y operacion, asignar operaciones en ejecucion
        an2.forEach(
          row => {
            // añadir las 3 nuevas variables
            row['tiempoEjecutado'] = 0;
            row['operacionEmpezada'] = false;
            row['enEjecucion'] = false;

            //se ponen las que estan en ejecucion en este momento
            var enEjecucion = operacionesEnEjecucion[row['idMaquina'] + '_' + row['idOperacion']];
            if (enEjecucion) {
              if (enEjecucion == 1) {
                row['enEjecucion'] = true;
              }
            }

            //se descuenta el tiempo por maquina y operacion
            var tiempoEjecutado = operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']];

            if (tiempoEjecutado) {
              //se le pone que esta empezada
              if (tiempoEjecutado > 0) {
                row['operacionEmpezada'] = true;
                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                var maximoTiempoAppointment = row['tiempoEstimadoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimadoEjecucion']) - row['tiempoEjecutado'];
                //se descuenta el tiempo de las 3 partes que afecta 
                if (tiempoEjecutado <= maximoTiempoAppointment) {
                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - tiempoEjecutado;
                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = 0;
                } else {
                  operacionesOrdenadas2[row['idMaquina'] + '_' + row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                  operacionesOrdenadas2[row['idOperacion']] = operacionesOrdenadas2[row['idOperacion']] - maximoTiempoAppointment;
                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                }
              }
            }
          });
        //   despues de quitar el tiempo de cada maquina, se quita el tiempo de la operacion aunque no este en la misma maquina
        an2.forEach(
          row => {
            var tiempoEjecutado = operacionesOrdenadas2[row['idOperacion']];
            if (tiempoEjecutado) {
              if (tiempoEjecutado > 0) {
                row['operacionEmpezada'] = true;
                //se mira cual va a ser el maximo de tiempo de este appointment (teniendo en cuenta el tiempoEjecutado) para no añadirle mas tiempo de el que luego va a poder usar
                var maximoTiempoAppointment = row['tiempoEstimadoPreparacion'] + ((row['cantidad'] - row['cantidadHechas']) * row['tiempoEstimadoEjecucion']) - row['tiempoEjecutado'];
                //se descuenta el tiempo de las 3 partes que afecta 
                if (tiempoEjecutado <= maximoTiempoAppointment) {
                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + tiempoEjecutado;
                  operacionesOrdenadas2[row['idOperacion']] = 0;
                } else {
                  operacionesOrdenadas2[row['idOperacion']] = tiempoEjecutado - maximoTiempoAppointment;
                  row['tiempoEjecutado'] = row['tiempoEjecutado'] + maximoTiempoAppointment;
                }
              }
            }
          });
        const copia = {}
        Object.assign(copia, an2);
        //CARGAR TIEMPOS EJECUTADOS END
        r1 = true;
        if (r1 && r2)
          this.cargarGantt_temporal();
      });

    this.planificadorService.Get_cantidadesOperacionesActual(version).subscribe(
      json => {
        this.operacionesActuales = [];

        var an: any = json;
        an.forEach(
          row => {
            if (row.nuevasPosibles > 0 && row.ordenOperacion > 1) { //se miran si se pueden hacer nuevas operaciones de esta operacion
              for (var _i = 0; _i < row.nuevasPosibles; _i++) {//por cada oepracion que se puede hacer se crea una linea con la operacion ANTERIOR hecha a fecha ACTUAL
                var operaciones: any = [];
                operaciones['idRuta'] = row.idRuta;
                operaciones['orden'] = row.ordenOperacion - 1;
                operaciones['cantidad'] = 1;
                operaciones['cantidadColores'] = 1;
                //operaciones['fecha'] = this.dateCopy(this.now);// cuando ya se empieza una la proxima se pone en amarillo.
                operaciones['fecha'] = row.fecha;// cuando ya se empieza una la proxima se pone en amarillo.
                this.operacionesActuales.push(operaciones)
              }
            }
          });

        r2 = true;
        if (r1 && r2)
          this.cargarGantt_temporal();
      });
  }

  cargarGantt_temporal() {
    //LAS OPERACIONES TIENEN QUE VENIR EN ORDEN!
    var operacionesHechas: any = this.operacionesActuales; //SE COGEN LAS OPERACIONES HECHAS HASTA AHORA

    var maquinas: any = this.DatMaquinas; // aqui necesitamos un formato especifico con las fechas
    maquinas.forEach(row => {
      row['name'] = row.nombre;
      row['fecha'] = this.dateCopy(this.now);
      row['image'] = 'assets/idcontent/' + this.user.idDb + '/modelos/' + row.imagen;
      row['bloqueada'] = false;
      row['planificada'] = false;
    });

    // 1- coger la maquina quie termine antes
    // 2- coger su proximo programa
    // 3- ver si se puede empezar (o porque es el 1 o porque hay hechos anteriores)
    //    OK: añadir con calculo de turnos, desbloquear maquinas
    //      3-1- se añaden una cantidad agrupada de programas
    //      3-2- se restan de los programas a planificar
    //      3-3- se quitan los programas usados de la lista de hechos
    //      3-3- se añaden como hechos los programas recien añadidos
    //    NO OK: bloquear maquina
    // 4- de nuevo al 1

    this.tasks = [];
    var index = 0;

    var salir: boolean = false;
    while (!salir) {
      var forzarPrograma = false;
      // 1- oger la maquina quie termine antes
      var maquina = this.primera_maquina_libre(maquinas, forzarPrograma);
      if (maquina == undefined) {
        //puede que las maquinas esten bloqueadas, en este caso se coge la mas antigua sin respetar el bloqueo.
        forzarPrograma = true;
        var maquina = this.primera_maquina_libre(maquinas, forzarPrograma);
        var copia = {};
        Object.assign(copia, maquina);
      }

      if (maquina == undefined) {
        salir = true;
      }
      else {
        // 2- coger su proximo programa
        var programaParaAñadir = this.primer_programa_maquina(maquina, this.DatPlanificador);
        if (forzarPrograma) {
          var copia = {};
          Object.assign(copia, programaParaAñadir);
        }
        if (programaParaAñadir == undefined) {
          // la maquina seleccionada ya no tiene mas operaciones para planificar
          maquina.planificada = true;
        }
        else {
          // 3- ver si se puede empezar
          if (programaParaAñadir.ordenOperacion == 1 || forzarPrograma) {
            // SI tiene operaciones previas, ahora se le descuentan y se añade la fehca de la maquina.
            // MINIMO tiene que tener una cantidad agrupada o la cantidad retante de operaciones realizadas antes de empezar con esta.
            var cantidadMaximaAgrupada = programaParaAñadir.cantidadAgrupada + 0;
            if (cantidadMaximaAgrupada > programaParaAñadir.cantidad)
              cantidadMaximaAgrupada = programaParaAñadir.cantidad + 0;
            // si es posible, se coge la fecha que le corresponda
            var operacionesPrevias = this.operacionesPrevias(programaParaAñadir, operacionesHechas);
            if (operacionesPrevias != undefined) {
              var fechaMinima = this.dateCopy(this.now);
              operacionesPrevias.forEach(
                operacion => {
                  if (cantidadMaximaAgrupada > 0) {
                    if (cantidadMaximaAgrupada <= operacion.cantidad) {
                      operacion.cantidad -= cantidadMaximaAgrupada;
                      cantidadMaximaAgrupada = 0;
                    }
                    else {
                      cantidadMaximaAgrupada -= operacion.cantidad;
                      operacion.cantidad = 0;
                    }
                    if (operacion.fecha > fechaMinima) {
                      fechaMinima = operacion.fecha;
                    }
                  }
                });
              // se añaden todas las operaciones respetando turnos.
              if (maquina.fecha < fechaMinima) //Si la operacion previa es anterior a la hora de la maquina, no se actualiza! 
                maquina.fecha = this.dateCopy(fechaMinima);
            }
            //se añaden todas las operaciones respetando turnos.
            var tasks_porTurnos = this.crearTask_respetandoTurnos(index, maquinas, maquina, programaParaAñadir, operacionesHechas, forzarPrograma);

            if (forzarPrograma) {

            }


            var taskN = 0;

            //se prepara el tooltip pero se cargara junto al color para poner cuantas piezas es posible hacer.
            tasks_porTurnos.forEach(
              t => {
                taskN++;
                var partevisible = ''
                if (programaParaAñadir.parte != programaParaAñadir.pieza)
                  partevisible = `<p><span class="tooltip-title">` + this.translateService.instant('parte') + ':</span>   <span class="tooltip-valor">  ' + programaParaAñadir.parte + '</span>  </p>'

                var taskNumber = ''
                if (tasks_porTurnos.length > 1) {
                  taskNumber = `<p><span class="tooltip-title" > ` + taskN + ` </span> </p >`
                }

                t['tooltip'] = `` + taskNumber + `
<p><span class="tooltip-title">` + this.translateService.instant('of') + `:</span>  <span class="tooltip-valor"> ` + programaParaAñadir.refOF + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('cliente') + `: </span>  <span class="tooltip-valor"> ` + programaParaAñadir.cliente + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('pieza') + `:</span>   <span class="tooltip-valor"> ` + programaParaAñadir.pieza + `</span>  </p>
` + partevisible + `
<p><span class="tooltip-title">` + this.translateService.instant('operacion') + `:</span>   <span class="tooltip-valor"> ` + programaParaAñadir.operacion + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('cantidad') + `:</span>  <span class="tooltip-valor"> ` + programaParaAñadir.cantidadHechas.toString() + `/` + programaParaAñadir.cantidad.toString() + `</span>  </p>`;
                t['tooltip2'] = `
<p><span class="tooltip-title">` + this.translateService.instant('tiempoEstimado') + `:</span>   <span class="tooltip-valor"> ` + this.secondsToHms(programaParaAñadir.tiempoEstimadoEjecucion * (programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas)) + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('preparacion') + `:</span>  <span class="tooltip-valor"> ` + this.secondsToHms(programaParaAñadir.tiempoEstimadoPreparacion) + `</span>  </p>`;

                if (t.enEjecucion)
                  if (!(new Date(programaParaAñadir.fechafin) > new Date(t.endDate) || programaParaAñadir.fechafin == null))
                    t['filter'] = {
                      src: 'assets/img/diagonal-horizontal.png',
                      size: [94, 50], // original image w and h
                      animated: true
                    };
                  else
                    t['filter'] = {
                      src: 'assets/img/diagonales-blancas.png',
                      size: [95, 50], // original image w and h
                      animated: true
                    };
                else
                  if (!(new Date(programaParaAñadir.fechafin) > new Date(t.endDate) || programaParaAñadir.fechafin == null)) {
                    t['filter'] = {
                      src: 'assets/img/horizontales-rojas.png',
                      size: [97, 50], // original image w and h
                      animated: true
                    };
                  }
                this.tasks.push(t);
              });

            index++; // se incrementa el id interno

            //se pone que 1 vez la cantidadAgrupada ya se ha planificado
            programaParaAñadir.enGantt += programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas;// programaParaAñadir.cantidadAgrupada; ///ARATZ: CON ESTO MOSTRABA 9 OPERACIONES 9 VECES! Y NO SOLO 9

            //como ya hemos añadido una operacion, se desbroquean todas las maquinas
            this.desbloquearMaquinas(maquinas);
          }
          else {
            var operacionesPrevias = this.operacionesPrevias(programaParaAñadir, operacionesHechas);
            if (operacionesPrevias == undefined) {
              // se prueba con otra maquina
              maquina.bloqueada = true;
            } else {
              // MINIMO tiene que tener una cantidad agrupada o la cantidad retante de operaciones realizadas antes de empezar con esta.
              var cantidadMaximaAgrupada = programaParaAñadir.cantidadAgrupada + 0;
              if (cantidadMaximaAgrupada > programaParaAñadir.cantidad)
                cantidadMaximaAgrupada = programaParaAñadir.cantidad + 0;
              // antes de hacerlo, mirar si es posible
              var cantidadPrevia = 0;
              operacionesPrevias.forEach(
                operacion => {
                  cantidadPrevia += operacion.cantidad;
                });
              if (cantidadMaximaAgrupada < cantidadPrevia) {
                // si es posible, se coge la fecha que le corresponda
                var fechaMinima = this.dateCopy(this.now);
                operacionesPrevias.forEach(
                  operacion => {
                    if (cantidadMaximaAgrupada > 0) {
                      if (cantidadMaximaAgrupada <= operacion.cantidad) {
                        operacion.cantidad -= cantidadMaximaAgrupada;
                        cantidadMaximaAgrupada = 0;
                        if (operacion.fecha > fechaMinima)
                          fechaMinima = operacion.fecha;
                      }
                      else {
                        cantidadMaximaAgrupada -= operacion.cantidad;
                        operacion.cantidad = 0;
                      }
                    }
                  });
                // se añaden todas las operaciones respetando turnos.
                if (maquina.fecha < fechaMinima) //Si la operacion previa es anterior a la hora de la maquina, no se actualiza! 
                  maquina.fecha = this.dateCopy(fechaMinima);

                var tasks_porTurnos = this.crearTask_respetandoTurnos(index, maquinas, maquina, programaParaAñadir, operacionesHechas);

                var taskN = 0;
                //se prepara el tooltip pero se cargara junto al color para poner cuantas piezas es posible hacer.
                tasks_porTurnos.forEach(
                  t => {
                    taskN++;
                    var partevisible = ''
                    if (programaParaAñadir.parte != programaParaAñadir.pieza)
                      partevisible = `<p><span class="tooltip-title">` + this.translateService.instant('parte') + ':</span>   <span class="tooltip-valor">  ' + programaParaAñadir.parte + '</span>  </p>'

                    var taskNumber = ''
                    if (tasks_porTurnos.length > 1) {
                      taskNumber = `<p><span class="tooltip-title" > ` + taskN + ` </span> </p >`
                    }
                    t['tooltip'] = `` + taskNumber + `
<p><span class="tooltip-title">` + this.translateService.instant('of') + `:</span>  <span class="tooltip-valor"> ` + programaParaAñadir.refOF + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('cliente') + `: </span>  <span class="tooltip-valor"> ` + programaParaAñadir.cliente + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('pieza') + `:</span>   <span class="tooltip-valor"> ` + programaParaAñadir.pieza + `</span>  </p>
` + partevisible + `
<p><span class="tooltip-title">` + this.translateService.instant('operacion') + `:</span>   <span class="tooltip-valor"> ` + programaParaAñadir.operacion + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('cantidad') + `:</span>  <span class="tooltip-valor"> ` + programaParaAñadir.cantidadHechas.toString() + `/` + programaParaAñadir.cantidad.toString() + `</span>  </p>`;
                    t['tooltip2'] = `
<p><span class="tooltip-title">` + this.translateService.instant('tiempoEstimado') + `:</span>   <span class="tooltip-valor"> ` + this.secondsToHms(programaParaAñadir.tiempoEstimadoEjecucion * (programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas)) + `</span>  </p>
<p><span class="tooltip-title">` + this.translateService.instant('preparacion') + `:</span>  <span class="tooltip-valor"> ` + this.secondsToHms(programaParaAñadir.tiempoEstimadoPreparacion) + `</span>  </p>`;

                    if (t.enEjecucion)
                      if (!(new Date(programaParaAñadir.fechafin) > new Date(t.endDate) || programaParaAñadir.fechafin == null))
                        t['filter'] = {
                          src: 'assets/img/diagonal-horizontal.png',
                          size: [94, 50], // original image w and h
                          animated: true
                        };
                      else
                        t['filter'] = {
                          src: 'assets/img/diagonales-blancas.png',
                          size: [95, 50], // original image w and h
                          animated: true
                        };
                    else
                      if (!(new Date(programaParaAñadir.fechafin) > new Date(t.endDate) || programaParaAñadir.fechafin == null)) {
                        t['filter'] = {
                          src: 'assets/img/horizontales-rojas.png',
                          size: [97, 50], // original image w and h
                          animated: true
                        };
                      }
                    this.tasks.push(t);
                  });

                index++; // se incrementa el id interno

                //se pone que 1 vez la cantidadAgrupada ya se ha planificado
                programaParaAñadir.enGantt += programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas;// programaParaAñadir.cantidadAgrupada; ///ARATZ: CON ESTO MOSTRABA 9 OPERACIONES 9 VECES! Y NO SOLO 9

                //como ya hemos añadido una operacion, se desbroquean todas las maquinas
                this.desbloquearMaquinas(maquinas);
              }
              else {
                //  NO HAY OPERACIONES ANTERIORES SUFICIENTES PARA SEGUIR LA CARGA.
                // se prueba con otra maquina
                maquina.bloqueada = true;
              }
            }
          }
        }
      }
    }

    var array = []
    var indiceKeys = 0;
    this.tasks.forEach(
      a => {
        array[this.dateToYYYYMMDDHHmmSS(a['startDate']) + '_' + this.addXzero(a['orden'], 20) + '_' + a['machine'] + "_" + indiceKeys] = a
        indiceKeys++;
      });
    array.sort(function (a, b) { return b[1] - a[1] });
    var keys = [];
    Object.keys(array).sort().forEach(key => keys.push(key));

    //PINTA Y COLOREA

    var fechaFin: Date;
    var coloresID: any = {};
    keys.forEach(key => {

      var row = array[key];
      //SI LA OPERACION ESTA EMPEZADA, SE PONE EN COLOR VERDE PERO DESPUES DE DESCONTAR LAS OPERACIONES PREVIAS PARA NO CONTARLAS COMO POSIBLES AUN HABIENDO EMPEZADO LA PROXIMA OPERACION.
      var cantidadEmpezada = 0;
      if (row.operacionEmpezada) {
        cantidadEmpezada = 1; //SI UNA OPERACION ESTA EN EJECUCION, POR AHORA CONTAREMOS COMO QUE HAY 1 EMPEZADA DE ESE APPOINTMENT, POR LO CUAL NO TIENE QUE CONSUMIR SU CANTIDAD COMPLETA SINO ESA CANTIDAD - 1, PUES LA EMPEZADA YA SE HA PODIDO HACER Y PARA ESO YA SE SUPONE QUE HA "DESCONTADO UNA OPERACINO ANTERIOR"
      }
      if (coloresID[row.id]) {
        row.backgroundColor = coloresID[row.id];
      } else {
        if (row.orden > 1) {
          var an: any = operacionesHechas
          var anteriores = an.filter(hecha => new Date(hecha.fecha) <= new Date(row.startDate) && hecha.idRuta == row.idRuta && hecha.orden + 1 == row.orden);
          var cantidad: number = row.cantidadReal - cantidadEmpezada;//la cantidad es igual a la cantidad - las piezas en proceso
          anteriores.forEach(anterior => {
            if (cantidad > anterior.cantidadColores) {
              cantidad = cantidad - anterior.cantidadColores;
              anterior.cantidadColores = 0;
            } else {
              anterior.cantidadColores = anterior.cantidadColores - cantidad;
              cantidad = 0;
            }
          });
          if (cantidad > 0) {
            if (cantidad < row.cantidadReal) {
              if ((row.cantidadReal - cantidadEmpezada) - cantidad >= row.cantidadAgrupada) { // hay mas operaciones que se han descontado que la cantidad agrupada minima
                row.backgroundColor = '#ebdb34'; // amarillo // es orden > 1 y entra en la fecha, ademas se puede EMPEZAR
                row.tooltip = row.tooltip + `
<p><span class="tooltip-title">` + this.translateService.instant('cantidadPosibles') + `:</span>  <span class="tooltip-valor"> ` + cantidad + `</span>  </p>`
              }
              else {
                row.backgroundColor = '#eb8f34'; // naranja //  NO se puede EMPEZAR LA CANTIDAD AGRUPADA!
                row.tooltip = row.tooltip + `
<p><span class="tooltip-title">` + this.translateService.instant('cantidadPosibles') + `:</span>  <span class="tooltip-valor"> ` + cantidad + `</span>  </p>`
              }
            }
            else {
              row.backgroundColor = '#eb8f34'; // naranja // es orden > 1 y entra en la fecha, ademas NO se puede EMPEZAR  
            }
          }
          else {
            row.backgroundColor = '#3289a8'; // azul // es orden > 1 y entra en la fecha, ademas se puede HACER
          }
        } else {
          row.backgroundColor = '#3289a8'; // azul // es de orden 1 y entra en la fecha
        }
        //una vez ya se consumen
        coloresID[row.id] = row.backgroundColor;
      }


      //SI LA OPERACION ESTA EMPEZADA, SE PONE EN COLOR VERDE PERO DESPUES DE DESCONTAR LAS OPERACIONES PREVIAS PARA NO CONTARLAS COMO POSIBLES AUN HABIENDO EMPEZADO LA PROXIMA OPERACION.
      if (row.operacionEmpezada) {
        row.backgroundColor = '#77DD77'; //VERDE //ESTA EN EJECUCION
      }

      row.detail = (d) => row.tooltip + row.tooltip2 + `
<p><span class="tooltip-valor">${d.startDate.toLocaleDateString()} ${d.startDate.toLocaleTimeString()} - ${d.endDate.toLocaleDateString()} ${d.endDate.toLocaleTimeString()}</span> </p>`;
    });

    /* FILTRO DE OF /MAQUINA /SECCION /CLIENTE.. */

    //FILTRO POR GRUPO DE MAQUINAS
    var idsGruposSelecteds: any = [];
    if (this.JgruposMaquinasSelected && this.JgruposMaquinasSelected.length > 0) {
      this.JgruposMaquinasSelected.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }
    else {
      this.JgruposMaquinas.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id);
        });
    }

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }

    //CON LOS DOS ANTERIORES FILTROS SE FILTRAN LAS ID DE LAS MAQUINAS
    var idsMaquinasSelecteds: any = [];
    this.JgruposMaquinasMaquinas.forEach(
      maquina => {
        if ((idsGruposSelecteds.includes(maquina.idGrupo) || this.JgruposMaquinas.length == 0) && (idsSeccionesSelecteds.includes(maquina.idSeccion) || this.secciones.length == 0))
          idsMaquinasSelecteds.push(maquina.id);
      });

    var idsOFs = [];
    if (this.ofsSeleccionados != undefined)
      this.ofsSeleccionados.forEach(of => idsOFs.push(of.idOf));

    var idsClientes = [];
    if (this.clientesSeleccionados != undefined)
      this.clientesSeleccionados.forEach(cliente => idsClientes.push(cliente.idCliente));

    var idsPiezas = [];
    if (this.piezasSeleccionados != undefined)
    {
      //this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.idPieza));
      this.piezasSeleccionados.forEach(pieza => idsPiezas.push(pieza.ids));
      idsPiezas = idsPiezas.reduce((acc, curVal) => {return acc.concat(curVal)}, []); //flatten array of arrays
    }

    var an2: any = this.DatPlanificador;
    var tasksEnGrupoMaquinas: any = [];

    this.tasks.forEach(
      task => {
        this.Joperaciones = an2.filter(f => f.idPlanificador == task.idPlanificador)
        var datPlan = this.Joperaciones[0];
        if (datPlan != undefined)
          if (idsMaquinasSelecteds.includes(task.machine) &&
            (idsOFs.length == 0 || idsOFs.includes(datPlan.idOF)) &&
            (idsClientes.length == 0 || idsClientes.includes(datPlan.idCliente)) &&
            (idsPiezas.length == 0 || idsPiezas.includes(datPlan.idPieza)))
            tasksEnGrupoMaquinas.push(task);
      });


    let data: any = {};
    var maquinasSeleccionadas: any = [];

    maquinas.forEach(
      maquina => {
        if (idsMaquinasSelecteds.includes(maquina.id))
          maquinasSeleccionadas.push(maquina);
      });

    data['machines'] = maquinasSeleccionadas;
    data['tasks'] = tasksEnGrupoMaquinas;
    var startDate = this.today;

    var endDate = new Date(this.now);
    maquinas.forEach(m => {
      if (endDate < m.fecha)
        endDate = this.dateCopy(m.fecha)
    });

    endDate.setDate(endDate.getDate() + 3);

    var gantt = new GanttChartPlanificadorTemporal('gantt-chart', {
      width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
      height: 50 + 50 * data['machines'].length, //comentar para poner altura por defecto
      startDate: startDate,
      endDate: endDate,
      data: data
    }, this.translateService)
    this.loadingPanel = false;

  }

  primera_maquina_libre(maquinas: any, forzarPrograma: boolean) {
    // OUTPUT: mauqina con fecha mas baja, la primera en librarse.
    var primeraMaquina;
    maquinas.forEach(
      maquina => {
        if (primeraMaquina == undefined && !maquina.planificada && (!maquina.bloqueada || forzarPrograma)) {
          primeraMaquina = maquina;
        }
        else {
          if (primeraMaquina != undefined) {
            if (maquina.fecha < primeraMaquina.fecha && !maquina.planificada && (!maquina.bloqueada || forzarPrograma)) {
              primeraMaquina = maquina;
            }
          }
        }
      }
    );
    return primeraMaquina;
  }
  primer_programa_maquina(maquina, planificador_temporal) {
    // OUTPUT: primer programa que no haya sido añadido al gantt y este planificado en la maquina seleccionada
    var programas: any = planificador_temporal.filter(f => f.idMaquina == maquina.id && f.cantidad > f.enGantt)
    if (programas != undefined) {
      return programas[0];
    }
    return undefined;
  }
  operacionesPrevias(programaParaAñadir, operacionesHechas) {
    // OUTPUT: operacion anterior a la que se quiere añadir.
    var operacionesPrevias = operacionesHechas.filter(f => f.orden == programaParaAñadir.ordenOperacion - 1 && f.idRuta == programaParaAñadir.idRuta)

    return operacionesPrevias;
  }

  crearTask_respetandoTurnos(index, maquinas, maquina, programaParaAñadir, operacionesHechas, forzarPrograma = false) {
    var copiFecha = this.dateCopy(maquina.fecha)

    var tasks2 = []; // se guardan los task generados en este momento (por si se cortan en turnos) y se le actualiza el tooltip al final 
    var task: any = {};
    task['id'] = index;// id interno
    var parte = programaParaAñadir.refOF + ' - ' + programaParaAñadir.parte
    if (programaParaAñadir.parte != programaParaAñadir.pieza) {
      parte = programaParaAñadir.refOF + ' - ' + programaParaAñadir.pieza + ' - ' + programaParaAñadir.parte
    }
    task['of'] = parte;// of del task


    var maquinasPosiblesStrings: any = programaParaAñadir.maquinasPosibles.split(',');
    var maquinasPosibles: number[] = [];
    if (maquinasPosiblesStrings[0] == '') {
      maquinas.forEach(m => maquinasPosibles.push(m.id))// si no hay maquinas, se añade a todas
    }
    else {
      maquinasPosiblesStrings.forEach(
        m => { // se añaden maquinas posibles
          var num: number = parseInt(m);
          maquinasPosibles.push(num);
        }
      );
    }

    task['allowedMachines'] = maquinasPosibles; // maquinas posibles
    //variables que hacen falta para despues NO GANTT
    task['idRuta'] = programaParaAñadir.idRuta;
    task['orden'] = programaParaAñadir.ordenOperacion;
    task['ordenPlanificado'] = programaParaAñadir.ordenPlanificado;
    task['idPlanificador'] = programaParaAñadir.idPlanificador;
    task['cantidad'] = programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas;
    task['cantidadAgrupada'] = programaParaAñadir.cantidadAgrupada;
    task['enEjecucion'] = programaParaAñadir.enEjecucion;
    task['operacionEmpezada'] = programaParaAñadir.operacionEmpezada;
    //task['cantidadReal'] = 0; //antes la cantidad iba por appointment, ahora va por operacion completa.
    task['cantidadReal'] = programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas;
    if (programaParaAñadir.fechafin == null)
      task['fechaLimite'] = programaParaAñadir.fechafin;
    else
      task['fechaLimite'] = new Date(programaParaAñadir.fechafin);

    task['machine'] = maquina.id; // maquina actual

    ////TURNOS
    if (programaParaAñadir.fueraTurno) {
      // se puede ejecutar fuera de turno.
      task['startDate'] = this.dateCopy(maquina.fecha); // inicio
      //task['cantidadReal'] = programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas;
      if (programaParaAñadir.cantidadHechas == 0 && programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas > 0) {
        maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoPreparacion));
      }

      for (var _i = programaParaAñadir.cantidadHechas; _i < programaParaAñadir.cantidad; _i++) {
        var operaciones: any = [];
        operaciones['idRuta'] = programaParaAñadir.idRuta;
        operaciones['orden'] = programaParaAñadir.ordenOperacion;
        operaciones['cantidad'] = 1;
        operaciones['cantidadColores'] = 1;
        //SE AÑADE EL TIEMPO DE EJECUCION *! teniendo en cuenta el tiempoEjecutado
        if (programaParaAñadir.tiempoEjecutado < programaParaAñadir.tiempoEstimadoEjecucion) {
          maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoEjecucion - programaParaAñadir.tiempoEjecutado));
          programaParaAñadir.tiempoEjecutado = 0;
        } else {
          programaParaAñadir.tiempoEjecutado = programaParaAñadir.tiempoEjecutado - programaParaAñadir.tiempoEstimadoEjecucion;
        }
        operaciones['fecha'] = this.dateCopy(maquina.fecha);// cuando ya se empieza una la proxima se pone en amarillo.
        operacionesHechas.push(operaciones);
      }
    }
    else {
      // se mira si entra dentro de turno.
      //SOLO EN ESTE CASO ! * ! se añade en TRUE añadirOPERACION(para tener en cuenta)
      //  NOTA: los tiempos ejecutados valen para descontar tiempo a las proximas operaciones de ese tipo,
      //  si una operacion se corta en un turno, es igual a añadir 1 en cantidad y restar el tiempo que ya se ha ejecutado como tiempoEjecutado.
      //  hay que tener en cuenta que no hay que añadir estas operaciones añadidas, ni contar como cantidad + 1 a la hora de mostrar
      var esTiempoSobranteOperacionAnterior = false;

      // se mira si entra dentro de turno. 
      var an: any = this.DatTurnos
      var turnos = an.filter(f => new Date(f.max) > maquina.fecha && f.idMaquina == maquina.id);
      if (turnos.length > 0) {
        // se mira si entra dentro de turno.
        if (new Date(turnos[0].min) > maquina.fecha) {
          maquina.fecha = new Date(turnos[0].min);// esta fuera de turno! 
        }
        task['startDate'] = this.dateCopy(maquina.fecha); // inicio
        if (programaParaAñadir.cantidadHechas == 0 && programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas > 0) {
          maquina.fecha.setSeconds(maquina.fecha.getSeconds() + programaParaAñadir.tiempoEstimadoPreparacion) // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
        }
        var repetidasPorCortesDeTurnos = 0;

        for (var _i = programaParaAñadir.cantidadHechas; _i < programaParaAñadir.cantidad + repetidasPorCortesDeTurnos; _i++) {
          var turnos = an.filter(f => new Date(f.max) > maquina.fecha && f.idMaquina == maquina.id);
          if (turnos.length > 0) {
            if (new Date(turnos[0].min) > maquina.fecha) {
              //partir appointment en 2 y añadir lo que se lleva hasta ahora.
              var task2: any = {};
              task2['id'] = index;// id interno
              task2['of'] = parte;// of del task
              task2['allowedMachines'] = maquinasPosibles; // maquinas posibles
              //variables que hacen falta para despues NO GANTT
              task2['idRuta'] = programaParaAñadir.idRuta;
              task2['orden'] = programaParaAñadir.ordenOperacion;
              task2['ordenPlanificado'] = programaParaAñadir.ordenPlanificado;
              task2['idPlanificador'] = programaParaAñadir.idPlanificador;
              task2['cantidad'] = programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas;
              task2['cantidadAgrupada'] = programaParaAñadir.cantidadAgrupada;
              if (programaParaAñadir.fechafin == null)
                task['fechaLimite'] = programaParaAñadir.fechafin;
              else
                task['fechaLimite'] = new Date(programaParaAñadir.fechafin);
              task2['machine'] = maquina.id; // maquina actual
              task2['startDate'] = task['startDate']
              task2['endDate'] = this.dateCopy(maquina.fecha); // fin
              task2['cantidadReal'] = task['cantidadReal'] + 0;
              task2['operacionEmpezada'] = programaParaAñadir.operacionEmpezada;
              task2['enEjecucion'] = programaParaAñadir.enEjecucion;
              task2['linking'] = index + ' ';// id interno
              tasks2.push(task2); // se añade el task aktual a todos

              maquina.fecha = new Date(turnos[0].min);// esta fuera de turno!
              task['startDate'] = this.dateCopy(maquina.fecha); // inicio
              //task['cantidadReal'] = 0;
            }
          }
          //task['cantidadReal']++;
          // cada vez que ya se ha tenido en cuenta la operacion (o no) este valos se vuelve a poner a false para la siguiente iteracion.
          esTiempoSobranteOperacionAnterior = false
          //SE AÑADE EL TIEMPO DE EJECUCION *! teniendo en cuenta el tiempoEjecutado
          //  SE TIENE EN CUENTA LA FECHA DE FINAL DE TURNO
          if (programaParaAñadir.tiempoEjecutado < programaParaAñadir.tiempoEstimadoEjecucion) {
            // si entró en un turno, aqui tenemos ese turno para saber cual es su fecha fin.
            if (turnos.length > 0) {
              var fechaFinActual = this.dateCopy(maquina.fecha)
              fechaFinActual.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoEjecucion - programaParaAñadir.tiempoEjecutado));
              if (new Date(turnos[0].max) < fechaFinActual) {
                esTiempoSobranteOperacionAnterior = true;
                repetidasPorCortesDeTurnos = repetidasPorCortesDeTurnos + 1;
                maquina.fecha = new Date(turnos[0].max);
                var tiempoSobranteS = (fechaFinActual.getTime() - new Date(turnos[0].max).getTime()) / 1000 //se divide en 1000 para conseguir los segundos
                programaParaAñadir.tiempoEjecutado = programaParaAñadir.tiempoEjecutado + ((programaParaAñadir.tiempoEstimadoEjecucion - programaParaAñadir.tiempoEjecutado) - tiempoSobranteS);
              } else {
                maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoEjecucion - programaParaAñadir.tiempoEjecutado));
                programaParaAñadir.tiempoEjecutado = 0;
              }
            } else {
              maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoEjecucion - programaParaAñadir.tiempoEjecutado));
              programaParaAñadir.tiempoEjecutado = 0;
            }
          }
          else {
            programaParaAñadir.tiempoEjecutado = programaParaAñadir.tiempoEjecutado - programaParaAñadir.tiempoEstimadoEjecucion;
          }

          //AÑADIR OPERACION COMO HECHA
          if (!esTiempoSobranteOperacionAnterior) {
            var operaciones: any = [];
            operaciones['idRuta'] = programaParaAñadir.idRuta;
            operaciones['orden'] = programaParaAñadir.ordenOperacion;
            operaciones['cantidad'] = 1;
            operaciones['cantidadColores'] = 1;
            operaciones['fecha'] = this.dateCopy(maquina.fecha);// cuando ya se empieza una la proxima se pone en amarillo.     
            operacionesHechas.push(operaciones);
          }


        }

        //añadir las operaciones como hechas
        var operaciones: any = [];
        operaciones['idRuta'] = programaParaAñadir.idRuta;
        operaciones['orden'] = programaParaAñadir.ordenOperacion;
        operaciones['cantidad'] = 1;
        operaciones['cantidadColores'] = 1;
        //operaciones['cantidad'] = programaParaAñadir.cantidadAgrupada;
        operaciones['fecha'] = this.dateCopy(maquina.fecha);
        operacionesHechas.push(operaciones);
      }
      else {
        //No hay mas turnos programados. 
        task['startDate'] = this.dateCopy(maquina.fecha); // inicio
        if (programaParaAñadir.cantidadHechas == 0 && programaParaAñadir.cantidad - programaParaAñadir.cantidadHechas > 0) {
          maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoPreparacion)); // Si no han empezado aun las ejecuciones, se añade el tiempo de preparacion, no sera necesario si ya se han empezado. Aunque tengan un cambio de dia.
        }
        //maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (row.tiempoEstimado * (row.cantidad - row.cantidadHechas))); // ACTUALIZAR TIEMPOS DE MAQUINAS!
        for (var _i = programaParaAñadir.cantidadHechas; _i < programaParaAñadir.cantidad; _i++) {
          var operaciones: any = [];
          operaciones['idRuta'] = programaParaAñadir.idRuta;
          operaciones['orden'] = programaParaAñadir.ordenOperacion;
          operaciones['cantidad'] = 1;
          operaciones['cantidadColores'] = 1;
          //SE AÑADE EL TIEMPO DE EJECUCION *! teniendo en cuenta el tiempoEjecutado
          if (programaParaAñadir.tiempoEjecutado < programaParaAñadir.tiempoEstimadoEjecucion) {
            maquina.fecha.setSeconds(maquina.fecha.getSeconds() + (programaParaAñadir.tiempoEstimadoEjecucion - programaParaAñadir.tiempoEjecutado));
            programaParaAñadir.tiempoEjecutado = 0;
          } else {
            programaParaAñadir.tiempoEjecutado = programaParaAñadir.tiempoEjecutado - programaParaAñadir.tiempoEstimadoEjecucion;
          }
          operaciones['fecha'] = this.dateCopy(maquina.fecha);// cuando ya se empieza una la proxima se pone en amarillo.         
          operacionesHechas.push(operaciones);
        }
      }
    }

    task['detail'] = (d) => ``; // tooltip
    task['endDate'] = this.dateCopy(maquina.fecha); // fin
    tasks2.push(task);

    return tasks2;
  }

  desbloquearMaquinas(maquinas) {
    maquinas.forEach(
      maquina => {
        maquina.bloqueada = false;
      }
    );
  }

  ganttDropped(id, newStartDate, newMachine) {
    var newOrden = 1;
    var proximasDrop = this.tasks.filter(f => f.machine == newMachine && new Date(f.endDate) >= new Date(newStartDate));
    var noSeMueve = false;
    if (proximasDrop.length > 0) { // se pone antes del proximo     
      newOrden = proximasDrop[0].ordenPlanificado;
      noSeMueve = proximasDrop[0].id == id
    } else {
      proximasDrop = this.tasks.filter(f => f.machine == newMachine);
      if (proximasDrop.length > 0)// se pone el ultimo
      {
        newOrden = proximasDrop[proximasDrop.length - 1].ordenPlanificado + 1;
        noSeMueve = proximasDrop[proximasDrop.length - 1].id == id
      }
    }
    if (!noSeMueve) {
      var idPlanificador = this.tasks.filter(f => f.id == id)[0].idPlanificador;
      var an: any = this.DatPlanificador;
      var row = an.filter(f => f.idPlanificador == idPlanificador)[0];
      var oldOrden = row.ordenPlanificado + 0;
      an.forEach(f => {
        if (f.idPlanificador == row.idPlanificador) {
          f.idMaquina = newMachine;
          f.ordenPlanificado = newOrden;
        } else {
          if (f.idMaquina == row.idMaquina && f.ordenPlanificado >= oldOrden)
            f.ordenPlanificado--;
          if (f.idMaquina == newMachine && f.ordenPlanificado >= newOrden)
            f.ordenPlanificado++;
        }
      });
    }
    //this.cargarGantt();
    this.cargarGantt_operaciones_temporal();
  }

  ganttClicked(id) {
    var task = this.tasks.filter(f => f.id == id)[0];
    var an: any = this.DatPlanificador;
    this.Joperaciones = an.filter(f => f.idPlanificador == task.idPlanificador)
    var datPlan = this.Joperaciones[0];

    if (datPlan.cantidad - datPlan.cantidadHechas < datPlan.total)
      datPlan.cantidadProv = datPlan.cantidad - datPlan.cantidadHechas;
    else
      datPlan.cantidadProv = datPlan.total;


    this.visibleInfo = true;

    this.infoOF = datPlan.refOF;
    this.infoCliente = datPlan.cliente;
    this.infoProyecto = datPlan.proyecto;
    this.infoPieza = datPlan.pieza;
    this.infoPlano = datPlan.plano;
    this.infoParte = datPlan.parte;
    this.infoRefPieza = datPlan.refPieza;

    this.JmaquinaSelected = undefined;
    this.JmaquinaSelectedLargo = undefined;
    this.Jmaquinas.forEach(f => {
      if (f.id == datPlan.idMaquina) {
        this.JmaquinaSelected = f;
        this.JmaquinaSelectedLargo = f;
      }
    });

    this.JsemanaSelected = this.Jsemanas[0];
  }

  //BOTONES "FILTRO"
  btnTiempoEstimado() {
    this.loadingPanel = true;

    this.aplicarTiempoEstimado = true;

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.DatPlanificador = json;
        //this.cargarGantt();
        this.cargarGantt_operaciones_temporal();
      });
  }
  btnPredictivo() {
    this.loadingPanel = true;

    this.aplicarTiempoEstimado = false;

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.DatPlanificador = json;
        //this.cargarGantt();
        this.cargarGantt_operaciones_temporal();
      });
  }
  btnIneficiencia() {
    this.loadingPanel = true;

    this.aplicarIneficiencias = !this.aplicarIneficiencias;

    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.DatPlanificador = json;
        //this.cargarGantt();
        this.cargarGantt_operaciones_temporal();
      });
  }
  //CAMBIO DE VERSION
  versionChanged(event) {
    this.loadingPanel = true;

    this.JplanificadoresSelected = event;

    this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
      json => {
        this.DatOperacionesSinCorto = json;

        this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
        this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
        this.loadItems()
      }
    );
    var version = 1;
    if (this.JplanificadoresSelected != undefined)
      version = this.JplanificadoresSelected.value;
    this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
      json => {
        this.DatPlanificador = json;
        //this.cargarGantt();
        this.cargarGantt_operaciones_temporal();
      });

    if (version > 1)
      this.visibleUsarVersion = true;
    else
      this.visibleUsarVersion = false;
  }

  //BOTONES
  btnTraerDesdeLargo() {
    this.visibleInfo = false;
  }
  btnGuardar() {
    this.planificadorService.Update_corto(this.DatPlanificador).subscribe();
  }
  btnReorganizar() {
    this.prioridadCliente = 0;
    this.prioridadFacturacion = 0;
    this.prioridadOF = 0;
    this.prioridadMaquina = 0;

    this.modalReference = this.modalService.open(this.popupReorganizar, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnReorganizarAceptar() {
    var prioridadCliente: number = 0;
    var prioridadFacturacion: number = 0;
    var prioridadOF: number = 0;
    var prioridadMaquina: number = 0;

    var total: number = 0;

    total = this.prioridadCliente + this.prioridadFacturacion + this.prioridadOF + this.prioridadMaquina;

    prioridadCliente = this.reglaDeTres(this.prioridadCliente, total, 100);
    prioridadFacturacion = this.reglaDeTres(this.prioridadFacturacion, total, 100);
    prioridadOF = this.reglaDeTres(this.prioridadOF, total, 100);
    prioridadMaquina = this.reglaDeTres(this.prioridadMaquina, total, 100);

    var tiempo = 1;
    if (!this.aplicarTiempoEstimado)
      tiempo = 2;

    //this.planificadorService.Reorganizar_Skootik(1, this.JplanificadoresSelected.value, tiempo,// la maquina no se usa por ahora
    //  prioridadCliente, prioridadFacturacion, prioridadOF, prioridadMaquina).subscribe(
    //    json => {
    //      this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
    //        json => {
    //          this.DatOperacionesSinCorto = json;

    //          this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
    //          this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
    //          this.loadItems()
    //        }
    //      );

    //      var version = 1;
    //      if (this.JplanificadoresSelected != undefined)
    //        version = this.JplanificadoresSelected.value;

    //      this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
    //        json => {
    //          this.DatPlanificador = json;
    //          //this.cargarGantt();
    //          this.cargarGantt_operaciones_temporal();
    //        });
    //    }
    //  );

    this.modalReference.close();
  }

  btnCopiarAVersion() {
    this.modalReference = this.modalService.open(this.popupCopiarAVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnCopiarAVersionAceptar() {
    this.planificadorService.copiarAVersion(this.JplanificadoresSelected.value, this.JplanificadoresSelectedCopiar.value, 1).subscribe(
      json => {
        this.JplanificadoresSelected = this.JplanificadoresSelectedCopiar;

        this.planificadorService.GetPlanificadoSinCorto(1).subscribe(
          json => {
            this.DatOperacionesSinCorto = json;

            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
            this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
            this.loadItems()
          }
        );
        this.planificadorService.GetCorto(this.JplanificadoresSelected.value, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          json => {
            this.DatPlanificador = json;
            //this.cargarGantt();
            this.cargarGantt_operaciones_temporal();
          });

        if (this.JplanificadoresSelected.value > 1)
          this.visibleUsarVersion = true;
        else
          this.visibleUsarVersion = false;
      });

    this.modalReference.close();
  }

  btnBorrarVersion() {
    this.modalReference = this.modalService.open(this.popupBorrarVersion, { backdrop: 'static', size: 'm', keyboard: false, centered: true });
  }
  btnBorrarVersionAceptar() {
    this.planificadorService.borrarVersion(this.JplanificadoresSelected.value, 1).subscribe(
      json => {
        this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
          json => {
            this.DatOperacionesSinCorto = json;

            this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
            this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
            this.loadItems()
          }
        );
        this.planificadorService.GetCorto(this.JplanificadoresSelected.value, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
          json => {
            this.DatPlanificador = json;
            //this.cargarGantt();
            this.cargarGantt_operaciones_temporal();
          });
      });
    this.modalReference.close();
  }

  //BOTONES
  btnSacar() {
    this.loadingPanel = true;

    var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
    rowsSelecteds.forEach(
      row => {
        row.cantidad = row.cantidad - row.cantidadProv;
        row.cantidadProv = row.cantidad - row.cantidadHechas;
      }
    );
    this.visibleInfo = false;
    //this.cargarGantt();
    this.cargarGantt_operaciones_temporal();
  }
  btnMover() {
    this.loadingPanel = true;

    var salir = false;
    if (this.JmaquinaSelected == undefined) {
      this.requiereMaquina = true;
      salir = true;
    }
    if (!salir) {
      var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
      rowsSelecteds.forEach(
        row => {
          let nuevaOperacion: any = {};
          Object.assign(nuevaOperacion, row);
          nuevaOperacion.idMaquina = this.JmaquinaSelected.id + 0;
          nuevaOperacion.cantidad = row.cantidadProv + 0;
          nuevaOperacion.cantidadProv = row.cantidadProv + 0;
          nuevaOperacion.cantidadHechas = 0;
          nuevaOperacion.total = row.cantidadProv + 0;
          nuevaOperacion.idPlanificador = 0;
          var ann: any = this.DatPlanificador;
          ann.push(nuevaOperacion);

          row.cantidad = row.cantidad - row.cantidadProv;
          row.cantidadProv = row.cantidad - row.cantidadHechas;
        }
      );

      this.visibleInfo = false;

      //this.cargarGantt();
      this.cargarGantt_operaciones_temporal();
    }
  }
  btnMandarALargo() {
    this.loadingPanel = true;

    var salir = false;
    if (this.JmaquinaSelectedLargo == undefined) {
      this.requiereMaquinaLargo = true;
      salir = true;
    }
    if (this.JsemanaSelected == undefined) {
      this.requiereSemana = true;
      salir = true;
    }

    if (!salir) {

      var reqInsertar: any = [];

      var rowsSelecteds: any = this.Joperaciones.filter(f => this.operacionesSelected.includes(f.numFila));
      rowsSelecteds.forEach(
        row => {
          var r2: any = {};
          r2.idMaquina = this.JmaquinaSelectedLargo.id;
          r2.fecha = this.dateToYYYYMMDDtHHmmSSz(this.getDateOfISOWeek(this.JsemanaSelected.semana, this.JsemanaSelected.año));
          r2.idOperacion = row.idOperacion;
          r2.cantidad = row.cantidadProv;
          r2.version = this.JplanificadoresSelected.value;
          reqInsertar.push(r2);

          row.cantidad = row.cantidad - row.cantidadProv;
          row.cantidadProv = row.cantidad - row.cantidadHechas;
        }
      );

      if (reqInsertar.length > 0)
        this.planificadorService.Update_corto(this.DatPlanificador).subscribe(
          r => {
            this.planificadorService.InsertOperacionesPlanificadas(reqInsertar).subscribe(r => {

              var r1, r2, r3: boolean = false;
              var version = 1;

              if (this.JplanificadoresSelected != undefined)
                version = this.JplanificadoresSelected.value;

              this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                json => {
                  r1 = true;

                  this.DatPlanificador = json;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                });
              //this.maquinasService.get().subscribe(
              //  json => {
              //    r2 = true;

              //    this.Jmaquinas = json;
              //    this.JmaquinasLargo = json;

              //    this.DatMaquinas = json;

              //    if (r1 && r2 && r3)
              //    //this.cargarGantt();
              //    this.cargarGantt_operaciones_temporal();
              //  });
              var maquinas_planificador_model = this.maquinasService.get_maquinas_planificador_model();
              if (maquinas_planificador_model == false) {
                this.maquinasService.getMaquinasPlanificador().subscribe(json => {
                  this.maquinasService.set_maquinas_planificador_model(json);
                  this.Jmaquinas = this.maquinasService.get_maquinas_planificador_model();
                  this.JmaquinasLargo = this.maquinasService.get_maquinas_planificador_model();
                  this.DatMaquinas = this.maquinasService.get_maquinas_planificador_model();
                  r2 = true;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                })
              } else {
                this.Jmaquinas = maquinas_planificador_model;
                this.JmaquinasLargo = maquinas_planificador_model;
                this.DatMaquinas = maquinas_planificador_model;
                r2 = true;
                if (r1 && r2 && r3)
                  //this.cargarGantt();
                  this.cargarGantt_operaciones_temporal();
              }

              this.planificadorService.Get_turnos(this.now).subscribe(
                json => {
                  r3 = true;

                  this.DatTurnos = json;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                });

              this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                json => {
                  this.DatOperacionesSinCorto = json;

                  this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
                  this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
                  this.loadItems()
                }
              );

              this.visibleInfo = false;
            });
          }
        );
    }
  }
  btnMandarACorto() {
    this.loadingPanel = true;


    var rowsSelecteds: any = this.JOperacionesSinCorto.filter(f => this.JOperacionesSinCortoSelecteds.includes(f.idPlanificador));
    var reqSacar: any = [];
    var reqInsertar: any = [];
    rowsSelecteds.forEach(f => {
      // Operaciones para sacar de plannig
      var r: any = {};
      r.idMaquina = f.idMaquina;
      r.fecha = this.dateToYYYYMMDDtHHmmSSz(new Date(f.fecha));
      r.idOperacion = f.idOperacion;
      r.nuevaCantidad = f.total - f.cantidad;
      r.version = this.JplanificadoresSelected.value;
      reqSacar.push(r);
      // Operaciones para añadir de plannig
      var r2: any = {};
      r2.idMaquina = f.idMaquina;
      r2.fecha = this.dateToYYYYMMDDtHHmmSSz(new Date(f.fecha));
      r2.idOperacion = f.idOperacion;
      r2.cantidad = f.cantidad;
      r2.version = this.JplanificadoresSelected.value;
      reqInsertar.push(r2);
    });

    var r1 = false;
    var r2 = false;
    if (reqSacar.length > 0)
      this.planificadorService.UpdateCantidades(reqSacar).subscribe(r => {
        r1 = true;
        //cerrar popup
        if (r1 && r2) {
          this.visibleInfo = false;
          this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            (json) => {
              var r1, r2, r3: boolean = false;
              var version = 1;

              if (this.JplanificadoresSelected != undefined)
                version = this.JplanificadoresSelected.value;

              this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                json => {
                  r1 = true;

                  this.DatPlanificador = json;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                });
              //this.maquinasService.get().subscribe(
              //  json => {
              //    r2 = true;

              //    this.Jmaquinas = json;
              //    this.JmaquinasLargo = json;

              //    this.DatMaquinas = json;

              //    if (r1 && r2 && r3)
              //      //this.cargarGantt();
              //      this.cargarGantt_operaciones_temporal();
              //  });
              var maquinas_planificador_model = this.maquinasService.get_maquinas_planificador_model();
              if (maquinas_planificador_model == false) {
                this.maquinasService.getMaquinasPlanificador().subscribe(json => {
                  this.maquinasService.set_maquinas_planificador_model(json);
                  this.Jmaquinas = this.maquinasService.get_maquinas_planificador_model();
                  this.JmaquinasLargo = this.maquinasService.get_maquinas_planificador_model();
                  this.DatMaquinas = this.maquinasService.get_maquinas_planificador_model();
                  r2 = true;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                })
              } else {
                this.Jmaquinas = maquinas_planificador_model;
                this.JmaquinasLargo = maquinas_planificador_model;
                this.DatMaquinas = maquinas_planificador_model;
                r2 = true;
                if (r1 && r2 && r3)
                  //this.cargarGantt();
                  this.cargarGantt_operaciones_temporal();
              }


              this.planificadorService.Get_turnos(this.now).subscribe(
                json => {
                  r3 = true;

                  this.DatTurnos = json;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                });

              this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                json => {
                  this.DatOperacionesSinCorto = json;

                  this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
                  this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
                  this.loadItems()
                }
              );
            }
          );
        }
      });
    if (reqInsertar.length > 0)
      this.planificadorService.InsertOperacionesPlanificadasACorto(reqInsertar).subscribe(r => {
        r2 = true;
        //cerrar popup
        if (r1 && r2) {
          this.visibleInfo = false;
          this.planificadorService.GetOperacionesPlanificadas(this.JplanificadoresSelected.value).subscribe(
            (json) => {
              var r1, r2, r3: boolean = false;
              var version = 1;

              if (this.JplanificadoresSelected != undefined)
                version = this.JplanificadoresSelected.value;

              this.planificadorService.GetCorto(version, this.aplicarTiempoEstimado, this.aplicarIneficiencias).subscribe(
                json => {
                  r1 = true;

                  this.DatPlanificador = json;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                });
              //this.maquinasService.get().subscribe(
              //  json => {
              //    r2 = true;
              //    this.Jmaquinas = json;
              //    this.JmaquinasLargo = json;

              //    this.DatMaquinas = json;

              //    if (r1 && r2 && r3)
              //      //this.cargarGantt();
              //      this.cargarGantt_operaciones_temporal();
              //  });
              var maquinas_planificador_model = this.maquinasService.get_maquinas_planificador_model();
              if (maquinas_planificador_model == false) {
                this.maquinasService.getMaquinasPlanificador().subscribe(json => {
                  this.maquinasService.set_maquinas_planificador_model(json);
                  this.Jmaquinas = this.maquinasService.get_maquinas_planificador_model();
                  this.JmaquinasLargo = this.maquinasService.get_maquinas_planificador_model();
                  this.DatMaquinas = this.maquinasService.get_maquinas_planificador_model();
                  r2 = true;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                })
              } else {
                this.Jmaquinas = maquinas_planificador_model;
                this.JmaquinasLargo = maquinas_planificador_model;
                this.DatMaquinas = maquinas_planificador_model;
                r2 = true;
                if (r1 && r2 && r3)
                  //this.cargarGantt();
                  this.cargarGantt_operaciones_temporal();
              }

              this.planificadorService.Get_turnos(this.now).subscribe(
                json => {
                  r3 = true;

                  this.DatTurnos = json;
                  if (r1 && r2 && r3)
                    //this.cargarGantt();
                    this.cargarGantt_operaciones_temporal();
                });

              this.planificadorService.GetPlanificadoSinCorto(this.JplanificadoresSelected.value).subscribe(
                json => {
                  this.DatOperacionesSinCorto = json;

                  this.JOperacionesSinCortoTodo = this.DatOperacionesSinCorto;
                  this.JOperacionesSinCorto = this.DatOperacionesSinCorto;
                  this.loadItems()

                }
              );
            }
          );
        }
      });

    this.loadingPanel = false;
  }

  //TOOLTIP
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  recargarGrid() {
    var idsMaquinas = [];
    if (this.JmaquinasSelectedLargo != undefined)
      this.JmaquinasSelectedLargo.forEach(row => idsMaquinas.push(row.id))
    var idsSemanas = [];
    if (this.JsemanasSelected != undefined)
      this.JsemanasSelected.forEach(row => idsSemanas.push(row.semana))

    if (idsMaquinas.length == 0)
      if (idsSemanas.length == 0)
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo; // ninguna
      else
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo.filter(f => idsSemanas.includes(f.numSemana)); //solo semnaa      
    else
      if (idsSemanas.length == 0)
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo.filter(f => idsMaquinas.includes(f.idMaquina)); // solo maquina
      else
        this.JOperacionesSinCorto = this.JOperacionesSinCortoTodo.filter(f => idsSemanas.includes(f.numSemana) && idsMaquinas.includes(f.idMaquina)); // las dos

    this.loadItems()
  }

  pageChange(event: PageChangeEvent) {
    this.skip = event.skip;
    this.loadItems();
  }
  loadItems() {
    this.JOperacionesSinCortoView = this.JOperacionesSinCorto.slice(this.skip, this.skip + this.pageSize);
  }

  btnVistaTemporal() {

    this.cargarGantt_operaciones_temporal();

    this.modalReference = this.modalService.open(this.popupVistaTemporal, { backdrop: 'static', size: 'xl', keyboard: false, centered: true });

  }

  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds());
  }
  dateToYYYYMMDDHHmmSS(fecha: Date) { // es para ordenar en ascendente el string
    //20201025230000
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + this.addZero(dia) + this.addZero(hora) + this.addZero(minutos) + this.addZero(segundos);
  }
  dateToYYYYMMDD(fecha: Date) {
    //2020-10-25
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return año + '/' + this.addZero(mes) + '/' + this.addZero(dia);
  }
  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
  msecondsToHms(mseconds: number) {
    var min = parseInt((mseconds / (60 * 1000)).toString())
    mseconds = mseconds - (min * (60 * 100))
    var s = parseInt((mseconds / 1000).toString())
    mseconds = mseconds - (s * 1000)
    return this.addZero(min) + ':' + this.addZero(s) + ':' + mseconds
  }
  startOfWeek(date) {
    var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }
  getNumberOfWeek(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }
  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return año + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }
  reglaDeTres(valor: number, total: number, sobre: number) {
    if (total == 0)
      return 0;
    else
      return sobre * valor / total;
  }
  public addXzero(n: number, x: number) {
    var zeros = 20;
    if (zeros > x)
      zeros = x;
    var r = '00000000000000000000' + n.toString();
    return r.substring(r.length - zeros, r.length);
  }
}


