import { Component } from '@angular/core';
import { Router, } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService, PlantaIsometricoService, UsuariosService } from '../_services';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-plantas-isometrico',
  templateUrl: 'plantasIsometrico.component.html'
})

export class PlantasIsometricoComponent {

  public seleccionados: number[] = [];
  public plantas: any;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  nuevaPlanta_modalReference: NgbModalRef;
  closeResult = '';
  navigationSubscription;

  nombrePlantaNueva = "";
  user = this.userService.userValue;


  constructor(public translate: TranslateService,
    private userService: UsuariosService,
    public router: Router,
    private appComponent: AppComponent,
    private plantaIsometricoService: PlantaIsometricoService,
    private menuService: MenuService,
    private alertService: AlertService,
    private modalService: NgbModal) {

    this.menuService.titulo = translate.instant('plantas').toUpperCase();

  }

  ngOnInit() {
    this.plantaIsometricoService.Get_Plantas().subscribe((result) => {
      this.plantas = result;
    });
  }

  onClickNuevo(content) {
    this.nombrePlantaNueva = "";
    this.nuevaPlanta_modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['diseñadorplantaisometrico/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['diseñadorplantaisometrico/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.plantaIsometricoService.Borrar_Planta(this.seleccionados.join()).subscribe((result: any) => {
      if (result > 0) {
        this.recargarPlantas();
      }
      this.modalReferenceloading.close();
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }

  crearPlanta(contentloading) {

    var nombresPlantas = this.plantas.map(x => x.nombre.toLowerCase());
    if (nombresPlantas.includes(this.nombrePlantaNueva.toLowerCase())) {
      this.alertService.error(this.translate.instant("nombrePlantaRepetido"));
      return;
    }

    this.nuevaPlanta_modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });

    this.plantaIsometricoService.Crear_Planta(this.nombrePlantaNueva).subscribe((result: any) => {
      if (result > 0) {
        this.recargarPlantas();
      }
      this.modalReferenceloading.close();
    });
    
  }

  recargarPlantas() {
    this.plantaIsometricoService.Get_Plantas().subscribe((result) => {
      this.plantaIsometricoService.Set_ListaPlantas_Model(result);
      this.appComponent.listaPlantas = result;
      this.plantas = result;
    });
  }

}
