<style>
  .reldiv1 {
    height: 0px;
    width: 0px;
    position: relative;
  }

  :host /deep/ .c3-drag-zoom {
    transform: translate(124.5px, 4.5px);
  }

  :host /deep/ .ocultarGrafico .c3-chart-lines {
    visibility: hidden;
  }

  :host /deep/ .ocultarGrafico .c3-axis-y {
    visibility: hidden;
    display: none;
  }

  /*GRAFICOS NUEVOS*/
  :host /deep/ #grafico2_hmiAdi .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico3_hmiAdi .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico4_hmiAdi .c3-axis-x {
    display: none;
  }

  :host /deep/ #grafico1_hmiAdi .c3-axis-y {
    transform: translate(0px, 0px);
  }

  :host /deep/ #grafico2_hmiAdi .c3-axis-y {
    transform: translate(-32px, 0px);
  }

  :host /deep/ #grafico3_hmiAdi .c3-axis-y {
    transform: translate(-64px, 0px);
  }

  :host /deep/ #grafico4_hmiAdi .c3-axis-y {
    transform: translate(-96px, 0px);
  }

  :host /deep/ #progresoGrafico_hmiAdi .c3-shapes-dataAzul>path,
  :host /deep/ #progresoGrafico_hmiAdi .c3-shapes-dataBlanco>path {
    stroke: #22C4C4 !important;
    stroke-width: 1px !important;
    stroke-opacity: 1 !important;
  }

  :host /deep/ .panelMaterialAdi .c3 line {
    stroke: #ff0000 !important;
    stroke-width: 2px !important;
  }

  /*FIN GRAFICOS NUEVOS*/
</style>

<div class="row hornos-temple-hmi">
  <!-- PANEL HOME -->
  <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

  <!--TEMPERATURA CONSUMO RESUMEN SEMANA-->
  <div class="col-md-4">
    <div class="row">
      <!--TEMPERATURAS-->
      <div class="col-8">
        <div class="card">

          <label class="titulo-seccion-maquina">{{ 'temperaturas' | translate }}</label>

          <div class="datosconicono-cont">

            <div class="dato-icono-col  temp-programada">
              <div class="icono-cont">

                <i class="icon-temperatura"></i>

              </div>

              <div class="dato-cont">
                <label class="datosconicono-titulo">{{ 'programada' | translate }}</label>

                <label class="datosconicono-dato">{{temperaturaProgramada}}</label>
              </div>
            </div>

            <div class="dato-icono-col  temp-actual">


              <div class="icono-cont">

                <i class="icon-temperatura"></i>

              </div>


              <div class="dato-cont">
                <label class="datosconicono-titulo">{{ 'actual' | translate }}</label>

                <label class="datosconicono-dato">{{temperaturaActual}}</label>
              </div>
            </div>
          </div>


        </div>
      </div>
      <!--CONSUMO-->
      <div class="col-4">
        <div class="card">
          <label class="titulo-seccion-maquina">{{ 'consumo' | translate }}</label>

          <div class="datosconicono-cont">

            <div class="dato-icono-col  consumo">
              <div class="icono-cont">

                <i class="icon-consumo"></i>

              </div>

              <div class="dato-cont">
                <label class="datosconicono-titulo">{{ 'consumo' | translate }}</label>

                <label class="datosconicono-dato">{{consumo}}</label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--RESUMEN SEMANA-->
    <div class="row">
      <div class="col">
        <div class="card">

          <label class="titulo-seccion-maquina">{{ 'resumenSemana' | translate }}</label>
          <div class="clearfix">
            <div id="resumenSemanaGrafico_hmiAdi" style="height: 153px; cursor: pointer;"
              [routerLink]="['/informerendimiento/'+idmaquina]">
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>


  <!--PROCESOS INFO -->
  <div class="col-md-4">

    <div class="card">

      <!--FPROCESOS-->
      <div class="panelMaterialAdi">
        <label class="titulo-seccion-maquina">{{ 'PROCESOS' | translate }}</label>
        <ng-template #template let-anchor>
          <span>{{ anchor.nativeElement.innerText }}</span>
        </ng-template>
        <div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
          (mouseover)="showGridTooltip($event)">

          <!-- <kendo-grid [data]="procesosMaquina" kendoGridSelectBy="id" [selectedKeys]="procesosSeleccionados"
                          (selectionChange)="clickHMI($event)"  
                          [selectable]="{mode: 'multiple'}"  [height]="320"
                          [navigable]="true" [sortable]="true"  [resizable]="true" filterable="menu"> -->
          <kendo-grid [data]="procesosMaquina" kendoGridSelectBy="id" (selectionChange)="clickHMI($event)"
            [height]="320" [navigable]="true" [sortable]="true" [resizable]="true" filterable="menu">

            <!-- <kendo-grid-checkbox-column width="8%" showSelectAll="true"></kendo-grid-checkbox-column> -->
            <kendo-grid-column field="numeroOF" title="{{ 'OF' | translate }}" width="15%"></kendo-grid-column>
            <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" width="25%">
            </kendo-grid-column>
            <kendo-grid-column field="fechaIni" title="{{ 'fechainicio' | translate }}" width="25%">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(dataItem.fechaIni.replace('T', '
                ')))}}
                {{this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(dataItem.fechaIni.replace('T', ' ')))}}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="fechaFin" title="{{ 'fechafin' | translate }}" width="25%">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{this.myFunctions.dateWithoutYearShorted(this.myFunctions.sqlToJsDate(dataItem.fechaFin.replace('T', '
                ')))}}
                {{this.myFunctions.dateToHHMM(this.myFunctions.sqlToJsDate(dataItem.fechaFin.replace('T', ' ')))}}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>

          </kendo-grid>
        </div>
      </div>

    </div>

  </div>

</div>

<!--PESTANAS-->
<div class="row">
  <div class="col">

    <kendo-tabstrip [keepTabContent]="true">

      <!--HMI-->
      <kendo-tabstrip-tab title="{{ 'hmi' | translate}}" [selected]="true">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">

            <!--VALORES-->
            <div class="col-md-3">
              <kendo-grid [data]="listaTooltip" kendoGridSelectBy="nombre" class="grid-hornos-temple"
                [selectedKeys]="listaTooltipSeleccionados" (selectionChange)="clickGridLeyenda($event)" [height]="400"
                [selectable]="{ checkboxOnly: true, mode: 'multiple' }">
                <kendo-grid-checkbox-column width="10"></kendo-grid-checkbox-column>
                <kendo-grid-column field="nombreTraducido" title="{{ 'nombre' | translate }}"
                  width="40%"></kendo-grid-column>
                <kendo-grid-column field="dim" title="{{ 'dim' | translate }}" width="20"></kendo-grid-column>
                <!-- <kendo-grid-column field="color" title="{{ 'color' | translate }}" width="15%">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <span class="colorleyenda" [ngStyle]="{'background-color': dataItem.color }"></span>
                </ng-template>
              </kendo-grid-column> -->
                <kendo-grid-column field="actual" title="{{ 'actual' | translate }}" width="23%">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <!-- <p>{{dataItem.actual}}</p> -->
                    <span *ngIf="dataItem.tipoDato != 'bit'">{{ dataItem.actual }}</span>
                    <input *ngIf="dataItem.tipoDato == 'bit'" type="checkbox" kendoCheckBox
                      [(ngModel)]="dataItem.actual" disabled />
                  </ng-template>
                </kendo-grid-column>
                <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                filterContainsOperator="{{'filterContainsOperator' | translate}}"
                filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>

            <!--GRAFICO-->
            <div class="col-md-9" oncontextmenu="return false;">

              <div style="height: 500px;">
                <button kendoButton look="flat" [icon]="'zoom-out'"
                  style="float: right; z-index: 2; margin-right: 10px;" (click)="onClickMostrarTodos()">{{
                  'mostrarTodos' | translate }}</button>
                <!-- <div class="reldiv1" id="grafico10_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[9]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico9_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[8]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico8_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[7]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico7_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[6]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico6_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[5]" style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico5_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[4]" style="z-index: 1;"></div> -->
                <div class="reldiv1" id="grafico4_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[3]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico3_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[2]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico2_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[1]"
                  style="z-index: 1;"></div>
                <div class="reldiv1" id="grafico1_hmiAdi" [class.ocultarGrafico]="!mostrarGraficosPorLeyenda[0]"
                  style="z-index: 1;"></div>
              </div>

            </div>

          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--PROCESOS-->
      <!-- <kendo-tabstrip-tab id="tabProcesos" title="{{ 'procesos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'historicoProcesos' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let historico of historicoProcesos">
                    <div class={{historico.divcss}}>
                      <div class="clearfix">
                        <div class="proces-fecha-cont">
                          <div id="horaIni" class="horaIni">
                            <label>{{historico.horaini}}</label>
                          </div>
                          <div id="diaIni" class="diaIni">
                            <label>{{historico.diaini}}</label>
                          </div>
                        </div>

                        <div class="proces-info-cont">
                          <div id="tipoProceso" *ngIf="historico.idProcesos_Tipo!=6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.nombre}}</label>
                          </div>
                          <div *ngIf="historico.idProcesos_Tipo==6">
                            <label><span class="proces-titulo-uno">{{historico.tipo}}:</span>
                              {{historico.tipoAlarNombre}}</label>
                          </div>
                          <div id="tiempos">
                            <label><span class="proces-titulo-uno">{{ 'tiempoReal' | translate}}:</span>
                              {{historico.tiemporeal}} <span class="proces-titulo-uno"
                                *ngIf="historico.idProcesos_Tipo!=2">{{ 'tiempoEstimado' | translate}}:</span><span
                                *ngIf="historico.idProcesos_Tipo!=2"> {{historico.tiempoteorico}}</span></label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{historico.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{historico.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{historico.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{historico.pieza}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'alarmas' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let alarma of alarmas">

                    <div class={{alarma.divcss}}>

                      <div class="clearfix">
                        <div class="proces-fecha-cont">

                          <div id="horaIni">
                            <label>{{alarma.horaini}}</label>
                          </div>
                          <div id="diaIni">
                            <label>{{alarma.diaini}}</label>
                          </div>

                        </div>

                        <div class="proces-info-cont">
                          <div id="numeroAlarma">
                            <label>{{alarma.numeroAlarma}}</label> - <label>{{alarma.descripcion.replace('ALARMA: ',
                              '')}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="procesdos col-sm-12 col-md-4">
              <div class="proces-titulo">
                {{ 'planificado' | translate | uppercase}}
              </div>
              <div class="clearfix">
                <div class="processcroll">
                  <div class="historial" *ngFor="let planificado of planificados">

                    <div class={{planificado.divcss}}>

                      <div class="clearfix">

                        <div class="proces-fecha-cont">
                          <div id="tiempos" class="horaIni">
                            <label>{{planificado.horaini}}</label>
                          </div>

                          <div id="tiempos" class="diaIni">
                            <label>{{planificado.diaini}}</label>
                          </div>
                        </div>

                        <div class="proces-info-cont">
                          <div id="operacion">
                            <label id="operacion"><span class="proces-titulo-uno">{{ 'operacion' | translate}}:</span>
                              {{planificado.operacion}}</label>
                          </div>
                          <div id="tiempoTeorico">
                            <label><span class="proces-titulo-uno">{{ 'tiempoEstimado' | translate}}:</span>
                              {{planificado.tiempoteorico}}</label>
                          </div>
                        </div>
                      </div>
                      <div id="informacionOF" class="informacionOF">
                        <label><span class="proces-titulo-dos">OF:</span> {{planificado.OF}} <span
                            class="proces-titulo-dos">{{ 'cliente' | translate}}:</span> {{planificado.cliente}} <span
                            class="proces-titulo-dos">{{ 'parte' | translate}}:</span> {{planificado.parte}} <span
                            class="proces-titulo-dos">{{ 'pieza' | translate}}:</span> {{planificado.pieza}}</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab> -->

      <!--MANTENIMIENTOS-->
      <kendo-tabstrip-tab id="tabMantenimiento" title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row cont-mantenimientos">
            <div class="col-md-6">
              {{ 'porFechas' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="41%">
                  </kendo-grid-column>
                  <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span>{{ dataItem.tipo | translate }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
              </div>
            </div>
            <div class="col-md-6">
              {{ 'porTiempos' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true"
                  [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)"
                        [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="51%">
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <!--NOTAS-->
      <kendo-tabstrip-tab id="tabNotas" title="{{ 'notas' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <kendo-grid [data]="notas" style="height: 400px">
              <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>
    <div *ngIf="loadingPestannas" class="k-i-loading" style="z-index: 2;"></div>

  </div>
</div>