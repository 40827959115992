import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-historicoConsumibleTipo',
  templateUrl: 'historicoConsumibleTipo.component.html'
})

export class HistoricoConsumibleTipo {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //VARIABLES DB
  public idTipo: number = 0;
  public nombre: string = '';
  public descripcion: string = '';
  public solicitarVidaUtil: boolean = false;
  public solicitarDiametro: boolean = false;
  public solicitarLongitud: boolean = false;
  public solicitarAngulo: boolean = false;
  public solicitarNumContenido: boolean = false;
  public solicitarSalto: boolean = false;
  public solicitarIdErp: boolean = false;
  public solicitarCoste: boolean = false;

  //GRID: consumibles Tipos
  public consumibles: any;
  public seleccionados: number[] = [];

  //POPUP: Eliminar
  public popupEliminar: boolean = false;
  public popupErrorAlEliminar: boolean = false;

  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService, 
    private translateService: TranslateService,
    private modalService: NgbModal,
    public router: Router,
    public route: ActivatedRoute  ) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('historicoConsumibleTipo').toUpperCase();
  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    this.loadingPanel = true;

    var r1, r2: boolean = false;
    this.idTipo = this.route.snapshot.params['idTipo'];
    console.log(this.idTipo)
    if (this.idTipo > 0) {
      this.consumiblesService.Get_consumiblesTipos_byID(this.idTipo).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            this.nombre = json[0].nombre;
            this.descripcion = json[0].descripcion;

            this.solicitarVidaUtil = json[0].solicitar_vidaUtil;
            this.solicitarDiametro = json[0].solicitar_diametro;
            this.solicitarLongitud = json[0].solicitar_longitud;
            this.solicitarAngulo = json[0].solicitar_angulo;
            this.solicitarNumContenido = json[0].solicitar_numContenido;
            this.solicitarSalto = json[0].solicitar_salto;
            this.solicitarIdErp = json[0].solicitar_idERP;
            this.solicitarCoste = json[0].solicitar_coste;
          }

          r1 = true;

          if (r1 && r2)
            this.loadingPanel = false;
        });
    }
    else {
      r1 = true;
      if (r1 && r2)
        this.loadingPanel = false;
    }

    this.consumiblesService.Get_consumibles_grid_by_tipo(this.idTipo).subscribe(
      (json) => {
        console.table(json)
        this.consumibles = json;

        r2 = true;

        if (r1 && r2)
        this.loadingPanel = false;
      });
  }

  onClickNuevo() {
    this.router.navigate(['historicoConsumible/' + this.idTipo + '/0']);
  }
  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['historicoConsumible/' + this.idTipo + '/' + this.seleccionados[0]]);
    }
  }
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['historicoConsumible/' + this.idTipo + '/' + this.seleccionados[0]]);
    }
  }
  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.popupEliminar = true;
    }
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }



  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
}
