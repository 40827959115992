<!-- FILTRO -->
<div class="row">
  <div class="col-md-12">

    <div class="card">
      <div class="card-body">
        <div class="clearfix">
          <!--FECHA INICIO-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'fechainicio' | translate }}</label>
              <div class="caja">
                <kendo-datepicker [(value)]="fechaIni" (valueChange)="onChangeFechaIni($event)" class="form-control"
                  style="max-width: 170px;"></kendo-datepicker>
              </div>
            </div>
          </div>

          <!--FECHA FIN-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'fechafin' | translate }}</label>
              <div class="caja">
                <kendo-datepicker [(value)]="fechaFin" (valueChange)="onChangeFechaFin($event)" class="form-control"
                  style="max-width: 170px;"></kendo-datepicker>
              </div>
            </div>
          </div>

          <!--LISTA TURNOS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'turno' | translate }}</label>
              <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaTurnos" [(ngModel)]="turnosSeleccionados"
                  [textField]="'nombreTurno'" [valueField]="'idTurno'" placeholder="{{ 'seleccioneTurno' | translate }}"
                  [autoClose]="false" style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreTurno}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'turnosSeleccionados' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>


          <!--AREAS PRODUCTIVAS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'seccion' | translate }}</label>
              <div class="caja">
                <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" kendoMultiSelectSummaryTag
                  [(data)]="groupedSeccion" [textField]="'nombre'" [valueField]="'id'" [autoClose]="false"
                  [(ngModel)]="seccionesSeleccionadas" (close)="seccionChanged()" style="width: 200px;">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'seccionesSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>
          <!--GRUPOS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'grupoMaquinas' | translate }}</label>
              <div class="caja">
                <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" [data]="JgruposMaquinasCombo"
                  kendoMultiSelectSummaryTag [textField]="'nombre'" [valueField]="'id'"
                  [(ngModel)]="JgruposMaquinasSelected" placeholder="{{ 'grupoMaquinas' | translate }}"
                  [autoClose]="false" (close)="seccionChanged()" style="width: 200px;">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>


          <!--LISTA MAQUINAS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'maquina' | translate }}</label>
              <div class="caja">
                <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" kendoMultiSelectSummaryTag
                  [data]="listaMaquinasMostradas" [(ngModel)]="maquinasSeleccionadas" [textField]="'nombreMaquina'"
                  [valueField]="'idMaquina'" placeholder="{{ 'seleccioneMaquina2' | translate }}" [autoClose]="false"
                  style="width: 295px;">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreMaquina}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'maquinasSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          <!--LISTA HERRAMIENTAS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'herramienta' | translate }}</label>
              <div class="caja">
                <!--     <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaHerramientas" [(ngModel)]="herramientasSeleccionadas" [textField]="'nombreHerramienta'" [valueField]="'idHerramienta'"
                                   placeholder="{{ 'seleccioneHerramienta' | translate }}" [autoClose]="false" style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}"> -->
                <kendo-multiselect kendoMultiSelectSummaryTag [textField]="'nombreHerramienta'"
                  [valueField]="'idHerramienta'" placeholder="{{ 'seleccioneHerramienta' | translate }}"
                  [autoClose]="false" style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreHerramienta}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'herramientasSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

        </div>
        <div class="clearfix">

          <!--LISTA OPERARIOS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'operario' | translate }}</label>
              <div class="caja">
                <kendo-multiselect kendoMultiSelectSummaryTag [data]="listaOperarios" [(ngModel)]="operariosSeleccionados" [textField]="'nombreOperario'" [valueField]="'idOperario'"
                                   placeholder="{{ 'seleccioneOperario' | translate }}" [autoClose]="false" style="width: 295px;" [kendoDropDownFilter]="{operator: 'contains'}">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOperario}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'operariosSeleccionados' | translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>


          <!--LISTA OFS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'of' | translate }}</label>
              <div class="caja">
                <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()"
                  kendoMultiSelectSummaryTag [data]="listaOfs" [(ngModel)]="ofsSeleccionados" [textField]="'nombreOf'"
                  [valueField]="'idOf'" placeholder="{{ 'seleccioneOf' | translate }}" [autoClose]="false"
                  style="width: 295px;">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreOf}}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'ofsSeleccionadas' | translate
                      }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          <!--LISTA CLIENTES-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'cliente' | translate }}</label>
              <div class="caja">
                <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()"
                  kendoMultiSelectSummaryTag [data]="listaClientes" [(ngModel)]="clientesSeleccionados"
                  [textField]="'nombreCliente'" [valueField]="'idCliente'"
                  placeholder="{{ 'seleccioneCliente' | translate }}" [autoClose]="false" style="width: 295px;">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombreCliente }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'clientesSeleccionados' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

          <!--LISTA PIEZAS-->
          <div class="float-left mr-2">
            <div class="form-group">
              <label>{{ 'pieza' | translate }}</label>
              <div class="caja">
                <kendo-multiselect [kendoDropDownFilter]="{operator: 'contains'}" (valueChange)="CambioFiltro()"
                  kendoMultiSelectSummaryTag [data]="listaPiezas" [(ngModel)]="piezasSeleccionadas"
                  [textField]="'nombrePieza'" [valueField]="'idPieza'" placeholder="{{ 'seleccionePieza' | translate }}"
                  [autoClose]="false" style="width: 295px;">
                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                    <span class="k-icon k-i-arrow-s"></span>
                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombrePieza }}</ng-container>
                    <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionadas' |
                      translate }}</ng-container>
                  </ng-template>
                  <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
                </kendo-multiselect>
              </div>
            </div>
          </div>

        </div>
        <!--BOTON FILTRAR-->
        <div class="caja">

          <button type="button" class="btn btn-danger mr-1 float-left" (click)="btnLimpiarFiltro()">{{ 'limpiarFiltro' | translate }}</button>
          <button type="button" class="btn btn-primary float-left" (click)="btnFiltrar()">{{ 'filtrar' | translate }}</button>

        </div>

      </div>
      <div *ngIf="!hayDatosFiltro" class="k-i-loading"></div>
    </div>

  </div>
</div>
<div class="row" >
  <!--GRAFICO DONUTS-->
  <div class="col-md-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'analisisTotal' | translate}}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="grafico_donuts" style="height: 235px;">
        </div>
        <a *ngIf="dataDonut !== undefined && dataDonut[0].ok== null" style="position: absolute; top: 45%; width: 100%; text-align: center;">{{
          'noDatosParaMostrar' | translate}}</a>
      </div>
    </div>
  </div>
  <!--GRAFICO BARRAS-->
  <div class="col-md-8">
    <div class="card">
      <div class="card-header">
        <h3>
          <label>{{ 'AnalisisDias' | translate}}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="grafico_barras" style="height: 235px;">
        </div>
        <a *ngIf="dataBarras !== undefined && dataBarras.length==0" style="position: absolute; top: 45%; width: 100%; text-align: center;">{{
          'noDatosParaMostrar' | translate}}</a>
      </div>
    </div>
  </div>
</div>

<div class="clearfix  mb-3" style="height: 550px">
  <!--GRID PIEZAS-->
  <kendo-tabstrip>
    <kendo-tabstrip-tab id="taboperaciones" [title]="operacionesstr" [selected]="true" style="height: 600px">
      <ng-template kendoTabContent style="height: 600px">
        <div kendoTooltip
             showOn="none"
             [tooltipTemplate]="template"
             filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid style="height: 500px"
                      [data]="operaciones"
                      filterable="menu"
                      [navigable]="true"
                      [sortable]="true"
                      [resizable]="true">
            <kendo-grid-column-group>
              <kendo-grid-column field="nombreOperacion" title="{{ 'operacion' | translate }}" width="100"></kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="{{ 'OK' | translate }}" [style]="{'text-align': 'center'}">
              <kendo-grid-column field="ok" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
              <kendo-grid-column field="porcenOK" title="{{ '%' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenOK}}%</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tiempoOK" title="{{ 'tiempo' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoOK)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="porcenTiempoOK" title="{{ 'tiempo%' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoOK}}%</ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group title="{{ 'NOOK' | translate }}" [style]="{'text-align': 'center'}">
              <kendo-grid-column field="noOK" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
              <kendo-grid-column field="porcenNoOK" title="{{ '%' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenNoOK}}%</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="tiempoNoOK" title="{{ 'tiempo' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoNoOK)}}</ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="porcenTiempoNoOK" title="{{ 'tiempo%' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoNoOK}}%</ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-column-group>
              <kendo-grid-column  title="{{ 'analisis' | translate }}" width="100">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button type="button" class="btn mr-1 float-left" (click)="redirectHistoricoPiezas(dataItem)"> <i class="fas fa-search"></i></button>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid-column-group>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabmaquinas" [title]="maquinasstr" style="height: 600px">
      <ng-template kendoTabContent style="height: 600px">
        <div kendoTooltip
             showOn="none"
             [tooltipTemplate]="template"
             filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid style="height: 500px"
                      [data]="maquinas"
                      filterable="menu"
                      [navigable]="true"
                      [sortable]="true"
                      [resizable]="true">
                      <kendo-grid-column-group>
                        <kendo-grid-column field="nombreMaquina" title="{{ 'maquina' | translate }}" width="100"></kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'OK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="ok" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'NOOK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="noOK" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenNoOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenNoOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoNoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoNoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoNoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoNoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group>
                        <kendo-grid-column title="{{ 'analisis' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <button type="button" class="btn mr-1 float-left" (click)="redirectHistoricoPiezas(dataItem)"> <i class="fas fa-search"></i></button>
                          </ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                      filterContainsOperator="{{'filterContainsOperator' | translate}}"
                      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="taboperarios" [title]="operariosstr" style="height: 600px">
      <ng-template kendoTabContent style="height: 600px">
        <div kendoTooltip
             showOn="none"
             [tooltipTemplate]="template"
             filter=".k-grid td"
             (mouseover)="showTooltip($event)">
          <kendo-grid style="height: 500px"
                      [data]="operarios"
                      filterable="menu"
                      [navigable]="true"
                      [sortable]="true"
                      [resizable]="true">
                      <kendo-grid-column-group>
                        <kendo-grid-column field="nombreOperario" title="{{ 'operario' | translate }}" width="100"></kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'OK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="ok" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'NOOK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="noOK" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenNoOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenNoOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoNoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoNoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoNoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoNoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group>
                        <kendo-grid-column  title="{{ 'analisis' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <button type="button" class="btn mr-1 float-left" (click)="redirectHistoricoPiezas(dataItem)"> <i class="fas fa-search"></i></button>
                          </ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                      filterContainsOperator="{{'filterContainsOperator' | translate}}"
                      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabpiezas" [title]="piezasstr" style="height: 600px">
      <ng-template kendoTabContent style="height: 600px">
        <div kendoTooltip
            showOn="none"
            [tooltipTemplate]="template"
            filter=".k-grid td"
            (mouseover)="showTooltip($event)">
          <kendo-grid style="height: 500px"
                      [data]="piezas"
                      filterable="menu"
                      [navigable]="true"
                      [sortable]="true"
                      [resizable]="true">
                      <kendo-grid-column-group>
                        <kendo-grid-column field="nombrePieza" title="{{ 'pieza' | translate }}" width="100"></kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'OK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="ok" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'NOOK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="noOK" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenNoOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenNoOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoNoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoNoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoNoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoNoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group>
                        <kendo-grid-column  title="{{ 'analisis' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <button type="button" class="btn mr-1 float-left" (click)="redirectHistoricoPiezas(dataItem)"> <i class="fas fa-search"></i></button>
                          </ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                      filterContainsOperator="{{'filterContainsOperator' | translate}}"
                      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab id="tabherramientas" [title]="herramientasstr"  [disabled]="true" style="height: 600px">
      <ng-template kendoTabContent style="height: 600px">
        <div kendoTooltip
            showOn="none"
            [tooltipTemplate]="template"
            filter=".k-grid td"
            (mouseover)="showTooltip($event)">
          <kendo-grid style="height: 500px"
                      [data]="herramientas"
                      filterable="menu"
                      [navigable]="true"
                      [sortable]="true"
                      [resizable]="true">
                      <kendo-grid-column-group>
                        <kendo-grid-column field="nombreHerramienta" title="{{ 'herramienta' | translate }}" width="100"></kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'OK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="ok" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group title="{{ 'NOOK' | translate }}" [style]="{'text-align': 'center'}">
                        <kendo-grid-column field="noOK" title="{{ 'Num' | translate }}" width="100"></kendo-grid-column>
                        <kendo-grid-column field="porcenNoOK" title="{{ '%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenNoOK}}%</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="tiempoNoOK" title="{{ 'tiempo' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM_SS(dataItem.tiempoNoOK)}}</ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="porcenTiempoNoOK" title="{{ 'tiempo%' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.porcenTiempoNoOK}}%</ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-column-group>
                        <kendo-grid-column title="{{ 'analisis' | translate }}" width="100">
                          <ng-template kendoGridCellTemplate let-dataItem>
                            <button type="button" class="btn mr-1 float-left" (click)="redirectHistoricoPiezas(dataItem)"> <i class="fas fa-search"></i></button>
                          </ng-template>
                        </kendo-grid-column>
                      </kendo-grid-column-group>
                      <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                      filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                      filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                      filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                      filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                      filterContainsOperator="{{'filterContainsOperator' | translate}}"
                      filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                      filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                      filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                      filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                      filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                      filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                      filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                      filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                      filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                      filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                      filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                      filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                      filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                      filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                      groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                      noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
          <div *ngIf="loadingDatos" id="loadingDiario" class="k-i-loading"></div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
