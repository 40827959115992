<style>

  :host /deep/ tspan {
    fill: unset;
  }

  #chartBarHoras_seguimientoProyectos .c3-axis-x text {
    font-size: 300px;   
  }

</style>

<div class="card">
  <div class="clearfix">
    <div class="card-body">

      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'proyectos' | translate }}</label>
          <div class="caja">

            <kendo-combobox style="width:150px" [data]="proyectos" [textField]="'nomOf'" [valueField]="'id'" (selectionChange)="selectionProyectosChange($event)"
                            (valueChange)="selectionProyectosChange($event)" class="form-control" [(value)]="proyectoActual" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>

          </div>
        </div>

      </div>
      <div class="float-left mr-3">
        <div class="form-group">
          <label class="control-label">{{ 'piezas' | translate }}</label>
          <div class="caja">

            <kendo-multiselect kendoMultiSelectSummaryTag [data]="piezasOF" [(ngModel)]="piezasSeleccionadas" [textField]="'refPieza'" [valueField]="'id'"
                               [autoClose]="false" style="width: 275px;" [kendoDropDownFilter]="{operator: 'contains'}">
              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                <span class="k-icon k-i-arrow-s"></span>
                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].refPieza}}</ng-container>
                <ng-container *ngIf="dataItems.length > 1">{{ dataItems.length }} {{ 'piezasSeleccionadas' | translate }}</ng-container>
              </ng-template>
              <kendo-multiselect-messages noDataText="{{'norecords' | translate}}"></kendo-multiselect-messages>
            </kendo-multiselect>

          </div>
        </div>
      </div>

      <div class="float-left mr-3">
        <div class="form-group">

          <label class="control-label">{{ 'cliente' | translate }}</label>
          <div class="caja">
            <label class="valor-label">{{ cliente }}</label>
          </div>

        </div>
      </div>

      <!--BOTON FILTRAR-->
      <div class="caja">
        <button type="button" class="btn btn-primary float-left nolabel" (click)="clickFiltrar()">{{ 'filtrar' | translate }}</button>
      </div>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-5">

    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'general' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <!--NOMBRE-->
        <div class="row">
          <div class="col-md-7">
            <div class="row">

              <div class="col-md-6">
                <div class="form-group">

                  <label class="control-label">{{ 'estado' | translate }}</label>
                  <div class="caja">
                    <label class="valor-label">{{ estado }}</label>
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">

                  <label class="control-label">{{ 'fechainicio' | translate }}</label>
                  <div class="caja">
                    <label class="valor-label">{{fechainiciostring | kendoDate }} {{fechainiciostring | kendoDate:'HH:mm:ss' }}</label>
                  </div>

                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">

                  <label class="control-label">{{ 'estimado' | translate }}</label>
                  <div class="caja">
                    <label class="valor-label">{{ myFunctions.secondsTo_HH_MM_SS(horasestimadas) }}</label>
                  </div>

                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">

                  <label class="control-label">{{ 'noperaciones' | translate }}</label>
                  <div class="caja">
                    <label class="valor-label">{{ nprocesos }}</label>
                  </div>

                </div>
              </div>

            </div>
            <div class="row">

              <kendo-grid [kendoGridBinding]="gridData"
                          [filterable]="false"
                          scrollable="true">
                <kendo-grid-column field="nombre" title=" "></kendo-grid-column>
                <kendo-grid-column *ngFor="let grupo of gruposMaquinas"
                                   field="{{ 'g_' }}{{grupo.id}}"
                                   title="{{grupo.nombre}}"></kendo-grid-column>
                                   <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                   filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                   filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                   filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                   filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                                   filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                   filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                                   filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                   filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                                   filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                                   filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                                   filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                   filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                   filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                                   filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                                   filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                   filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                   filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                   filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                                   filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                                   groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                                   noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
              </kendo-grid>
            </div>
          </div>
          <div class="col-md-5">

            <div id="chartDonutEstimacionHorasLineas" style="height:270px"></div>

          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'avance' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <!--NOMBRE-->
        <div class="row">
          <div class="col-md-4 ">
            <div class="float-left mr-3">
              <div class="form-group">

                <label class="control-label">{{ 'hreales' | translate }}</label>
                <div class="caja">
                  <label class="valor-label">{{ myFunctions.secondsTo_HH_MM_SS(hreales) }}</label>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-4 ">
            <div class="float-left mr-3">
              <div class="form-group">

                <label class="control-label">{{ 'estimado' | translate }}</label>
                <div class="caja">
                  <label class="valor-label">{{ myFunctions.secondsTo_HH_MM_SS(horasestimadas) }}</label>
                </div>

              </div>

            </div>
          </div>
          <div class="col-md-4 ">
            <div class="float-left mr-3">
              <div class="form-group">

                <div class="caja">
                  <label class="valor-label-porcenseguimiento">{{ porcentajeCompletado }}</label>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="row">

          <div id="chartBarHoras_seguimientoProyectos" style="height:215px;"></div>

        </div>
       </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="card" style="height: 355px;">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'pendiente' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <!--NOMBRE-->
        <kendo-grid [kendoGridBinding]="dataPendientes">

          <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
          <kendo-grid-column width="70%" [style]="{'text-align': 'left'}" field="nombre" title="{{ '' | translate}}"></kendo-grid-column>
          <kendo-grid-column width="30%" [style]="{'text-align': 'left'}" field="valor" title="{{ '' | translate}}"></kendo-grid-column>

          <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'filterContainsOperator' | translate}}"
          filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
          filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
          filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
          groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
          noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>

  </div>
</div>

<div class="row">
  <div class="col-md-8">

    <!--TABS-->
    <kendo-tabstrip>
      <kendo-tabstrip-tab [title]="desviosgridstr" [selected]="true">
        <ng-template kendoTabContent>
          <kendo-grid [kendoGridBinding]="desviosGrid" [height]="320">

            <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
            <kendo-grid-column width="20%" field="nombrePieza" title="{{ 'pieza' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" field="nSerie" title="{{ 'numeroserie' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="40%" field="nombreOperacion" title="{{ 'operacion' | translate}}">
            </kendo-grid-column><kendo-grid-column width="20%" field="tEstimado" title="{{ 'tEstimado' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tEstimado)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" field="tReal" title="{{ 'tReal' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tReal)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" field="porcentaje" title="{{ 'desvio' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>


            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab [title]="alarmasstr">
        <ng-template kendoTabContent>
          <kendo-grid [kendoGridBinding]="desviosGrid2" [height]="320">

            <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
            <kendo-grid-column width="20%" field="nombrePieza" title="{{ 'pieza' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" field="nSerie" title="{{ 'numeroserie' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="40%" field="nombreOperacion" title="{{ 'operacion' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" field="tEstimado" title="{{ 'tEstimado' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tEstimado)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" field="tReal" title="{{ 'tReal' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tReal)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" field="porcentaje" title="{{ 'desvio' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}"></kendo-grid-column>


            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="recursosstr">
        <ng-template kendoTabContent>
          <kendo-grid [kendoGridBinding]="fechaImportacionGrid" [height]="320">

            <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
            <kendo-grid-column width="30%" field="nombrePieza" title="{{ 'pieza' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" field="tEstimado" title="{{ 'tiempoEstimado' | translate}}" [style]="{'text-align': 'right'}" [headerStyle]="{'text-align': 'right'}">
              <ng-template kendoGridCellTemplate let-dataItem>{{myFunctions.secondsTo_HH_MM(dataItem.tEstimado)}}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" field="terminado" title="{{ 'terminado' | translate}}" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span *ngIf="dataItem.terminado" class="k-icon k-i-check"></span>
                <span *ngIf="!dataItem.terminado" class="k-icon k-i-close"></span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column width="20%" field="fechaImportacionERPoperacion" title="{{ 'fechaop' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" field="fechaImportacionERPparte" title="{{ 'fechapar' | translate}}"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab [title]="incidenciasstr">
        <ng-template kendoTabContent>
          <kendo-grid [kendoGridBinding]="incidenciasGrid" [height]="320">

            <kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>
            <kendo-grid-column width="80%" [style]="{'text-align': 'left'}" field="nombre" title="{{ 'nombre' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="importarOP" title="{{ 'fechaop' | translate}}"></kendo-grid-column>
            <kendo-grid-column width="20%" [style]="{'text-align': 'left'}" field="importarPar" title="{{ 'fechapar' | translate}}"></kendo-grid-column>

            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>
        </ng-template>
      </kendo-tabstrip-tab>


    </kendo-tabstrip>


  </div>

  <div class="col-md-4">
    <div class="card">
      <div class="card-header">
        <h3>
          <label CssClass="">{{ 'intensidadPorSeccion' | translate }}</label>
        </h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div id="chartAreaHorasDia_proyectos" style="height: 288px;"></div>
      </div>
    </div>
  </div>
</div>
