import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { ConsumiblesService } from '@app/_services/consumibles.service';
import { FabricantesService } from '@app/_services';
import { PageChangeEvent, GridDataResult, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MyFunctions } from '@app/_helpers';
import { Renderer2, NgZone } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { State, process } from "@progress/kendo-data-query";
import { tumblrBoxIcon } from '@progress/kendo-svg-icons';

//Variables para el drag and drop de la tabla
const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }
  return node;
};
const tableRow = node => node.tagName.toLowerCase() === 'tr';

@Component({
  selector: 'app-consumible',
  templateUrl: 'consumible.component.html'
})



export class ConsumibleComponent {
  //VARIABLES BASICAS
  private translate: TranslateService;
  public loadingPanel: any = false;

  //VARIABLES DB
  public id: number = 0;
  //public JconsumiblesTiposSelected: any; {id:..., ...} SE DEFINEN ABAJO
  //public JconsumiblesFabricantesSelected: any; {id:..., ...} SE DEFINEN ABAJO
  public nombre: string = '';
  public referencia: string = '';
  public descripcion: string = '';
  public tieneNserie: boolean = false;
  public numContenido: number = 0;
  public vidaUtil: number = 0;
  public vidaUtilHHmmSS: string = "00:00:00";
  public fiabilidad: number = 0;
  public diametro: number = 0;
  public longitud: number = 0;
  public angulo: number = 0;
  public salto: number = 0;
  public coste: number = 0;
  public idErp: string = '';
  public vc: number = 0;
  public f: number = 0;

  //COMBOS
  public JconsumiblesTipos: any;
  public JconsumiblesTipos_grid: any;
  public JconsumiblesTiposSelected: any = {
    descripcion: "",
    id: 0,
    nombre: "",
    referencia: "",
    orden: 1,
    solicitar_angulo: false,
    solicitar_coste: false,
    solicitar_diametro: false,
    solicitar_idERP: false,
    solicitar_longitud: false,
    solicitar_numContenido: false,
    solicitar_salto: false,
    solicitar_vidaUtil: false,
    solicitar_vc: false,
    solicitar_f: false,
    idsHijos: []
  };
  public JconsumiblesFabricantes: any;
  public JconsumiblesFabricantesSelected: any;
  public Jconsumibles: any;

  //CONTENIDO
  public Jcontenido: any = [];
  public contenidoSelected: number[] = [];

  //REQUIERED
  public requiereTipo: boolean = false;
  public requiereFabricante: boolean = false;
  public requiereNombre: boolean = false;
  public requiereReferencia: boolean = false;
  public requiereAngulo: boolean = false;
  public requiereCoste: boolean = false;
  public requiereDiametro: boolean = false;
  public requiereIdERP: boolean = false;
  public requiereLongitud: boolean = false;
  public requiereNumContenido: boolean = false;
  public requiereSalto: boolean = false;
  public requiereVidaUtil: boolean = false;
  public requierefiabilidad: boolean = false;
  public requiereVc: boolean = false;
  public requiereF: boolean = false;

  //Variables para el drag and drop del grid
  public state: State = {
    skip: 0,
    take: 100,
  };

  //POPUP: error al eliminar
  public popupErrorAlGuardar: boolean = false;
  public popupSeGuardaranLosCambios: boolean = false;


  public gridData: any = process([], this.state);
  currentSubscription: Subscription;
  user = this.userService.userValue;


  constructor(private consumiblesService: ConsumiblesService,
    translate: TranslateService,
    private menuService: MenuService,
    public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    public route: ActivatedRoute,
    public myFunctions: MyFunctions,
    public fabricantesService: FabricantesService,
    private renderer: Renderer2,
    private userService: UsuariosService,
    private zone: NgZone) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('consumible').toUpperCase();
  }

  ngOnInit() {
    //como aqui se cargan mas cosas que solo los datos ponemos la carga desde el inicio
    this.loadingPanel = true;

    this.id = parseInt(this.route.snapshot.params['id']);

    this.cargarCombos();
  }
  cargarCombos() {
    var r1, r2, r3: boolean = false;
    //TIPOS DE CONSUMIBLES
    this.consumiblesService.Get_consumiblesTipos_conHijos().subscribe(
      (json) => {
        this.JconsumiblesTipos = json;
        this.JconsumiblesTiposSelected = undefined;//SIN AUTOSELECT this.JconsumiblesTipos[0];
        this.JconsumiblesTipos_grid = this.JconsumiblesTipos; //SIN AUTOSELECT this.JconsumiblesTipos.filter(f => this.JconsumiblesTiposSelected.idsHijos.includes(',' + f.id + ',') == true);

        r1 = true;
        if (r1 && r2 && r3)
          this.cargarDatos();
      });
    // FABRICANTES
    this.fabricantesService.get_COMBO().subscribe(
      (json) => {
        this.JconsumiblesFabricantes = json.data; //Prefiero hace esto sin data
        this.JconsumiblesFabricantesSelected = undefined; //SIN AUTOSELECT this.JconsumiblesFabricantes[0];

        r2 = true;
        if (r1 && r2 && r3)
          this.cargarDatos();
      });
    //CONSUMIBLES
    this.consumiblesService.Get_consumibles().subscribe(
      (json) => {
        var an: any = json;
        an.forEach(
          (row) => {
            var splitedTooltip = row.contenido.split('<br/>');
            var tooltip = [];
            splitedTooltip.forEach(
              (row) => {
                tooltip.push(row);
              });
            row.contenido = tooltip;
          });

        this.Jconsumibles = json; //Prefiero hace esto sin data

        r3 = true;
        if (r1 && r2 && r3)
          this.cargarDatos();
      });
  }
  cargarDatos() {
    // cada vez que se recarguen los datos se asegura de poner que esta en carga
    this.loadingPanel = true;

    if (this.id > 0) {
      var r1, r2: boolean = false;
      this.consumiblesService.Get_consumibles_byID(this.id).subscribe(
        (json) => {
          if (Object.keys(json).length > 0) {
            this.JconsumiblesTiposSelected = this.JconsumiblesTipos.filter((f) => f.id == json[0].idConsumibles_tipos)[0];
            this.JconsumiblesTipos_grid = this.JconsumiblesTipos.filter(f => this.JconsumiblesTiposSelected.idsHijos.includes(',' + f.id + ',') == true);
            this.JconsumiblesFabricantesSelected = this.JconsumiblesFabricantes.filter((f) => f.id == json[0].idConsumibles_fabricantes)[0];
            this.nombre = json[0].nombre;
            this.referencia = json[0].referencia;
            this.descripcion = json[0].descripcion;
            this.tieneNserie = json[0].tieneNserie;
            this.numContenido = json[0].numContenido;
            this.vidaUtil = json[0].vidaUtil;
            this.vidaUtilHHmmSS = this.secondsToHms(json[0].vidaUtil);
            this.fiabilidad = json[0].fiabilidad;
            this.diametro = json[0].diametro;
            this.longitud = json[0].longitud;
            this.angulo = json[0].angulo;
            this.salto = json[0].salto;
            this.coste = json[0].coste;
            this.idErp = json[0].idERP;
            this.vc = json[0].vc;
            this.f = json[0].f;
          }

          r1 = true;
          if (r1 && r2) {
            this.loadingPanel = false;
            this.recargarGrid();
          }

        });
      this.consumiblesService.Get_consumibles_contenido(this.id).subscribe(
        (json) => {
          var an: any = json;
          an.forEach(
            (row) => {
              row.idConsumibles_contenido_tipo = this.JconsumiblesTipos.filter(f => f.id == row.idConsumibles_contenido_tipo)[0];
              row.idConsumibles_contenido = this.Jconsumibles.filter(f => f.id == row.idConsumibles_contenido)[0];
            });

          this.Jcontenido = an;

          r2 = true;
          if (r1 && r2) {
            this.loadingPanel = false;
            this.recargarGrid();
          }
        });
    }
    else {
      this.loadingPanel = false;
      this.Jcontenido = [];
      this.recargarGrid();
    }
  }

  //NO PERMITIQR QUE SE VACIEN LOS COMBOS, si no se hace con el timeout, no actualiza el valor del combo porque se hace despues del evento
  tipoChange() {
    var th = this;
    setTimeout(function () {
      if (th.JconsumiblesTiposSelected == undefined) {
        th.JconsumiblesTiposSelected = {
          descripcion: "",
          id: 0,
          nombre: "",
          referencia: "",
          orden: 1,
          solicitar_angulo: false,
          solicitar_coste: false,
          solicitar_diametro: false,
          solicitar_idERP: false,
          solicitar_longitud: false,
          solicitar_numContenido: false,
          solicitar_salto: false,
          solicitar_vidaUtil: false,
          solicitar_vc: false,
          solicitar_f: false,
          idsHijos: []
        };
      }

      th.JconsumiblesTipos_grid = th.JconsumiblesTipos.filter(f => th.JconsumiblesTiposSelected.idsHijos.includes(',' + f.id + ',') == true);
    }, 1);

  }
  fabricanteChange() {
    //var th = this;
    //setTimeout(function () {
    //  //if (th.JconsumiblesFabricantesSelected == undefined)
    //  th.JconsumiblesFabricantesSelected = [];//SIN AUTOSELECT th.JconsumiblesFabricantes[0];
    //}, 10);
  }

  //CONTENIDO
  removeHandler() {
    if (this.contenidoSelected.length > 0)
      this.Jcontenido = this.Jcontenido.filter(f => !this.contenidoSelected.includes(f.idTemp));
    this.contenidoSelected = [];
    this.reorderData();
    this.recargarGrid();
  }
  onClickNuevoContenido(event: any) {
    // indice que se usa para la seleccion del grid
    var idTempMax = 0;
    if (this.Jcontenido.length > 0) {
      this.Jcontenido.forEach(
        (row) => {
          if (row.idTemp > idTempMax) {
            idTempMax = row.idTemp + 0;
          }
        });
    }
    idTempMax++;

    var dt: any = {
      'id': 0,
      'idTemp': idTempMax,
      'idConsumibles': this.id,
      'idConsumibles_contenido_tipo': undefined, //SIN AUTOSELECT this.JconsumiblesTipos_grid[0],
      'idConsumibles_contenido': undefined, //SIN AUTOSELECT this.Jconsumibles.filter(f => this.JconsumiblesTipos_grid[0].id == f.idConsumibles_tipos)[0],
      'orden': Object.keys(this.Jcontenido).length + 1,
      'preferido': false,
      'cantidad': 1
    };
    this.Jcontenido.push(dt);

    this.reorderData();
    this.recargarGrid()
  }
  Jconsumibles_filter(dataItem) {
    //PARA MOSTRAR LOS CONSUMIBLES DEL TIPO SELECCIONADO SE UTILIZA ESTA FUNCION
    if (dataItem.idConsumibles_contenido_tipo == undefined)
      return []
    else
      return this.Jconsumibles.filter(f => dataItem.idConsumibles_contenido_tipo.id == f.idConsumibles_tipos);;
  }
  Jconsumibles_tipo_selected(dataItem) {
    dataItem.idConsumibles_contenido = undefined;
  }
  reorderData() {
    this.Jcontenido.sort(function (a, b) { return a['orden'] - b['orden']; })

    var orden = 1;
    this.Jcontenido.forEach(
      (row) => {
        row.orden = orden;
        orden++;
      });
  }
  redirect_consumible(dataItem) {
    if (dataItem.idConsumibles_contenido != undefined) {
      const url = this.router.serializeUrl(this.router.parseUrl('#/consumible/' + dataItem.idConsumibles_contenido.id));
      window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(this.router.parseUrl('#/consumibleTipo/' + dataItem.idConsumibles_contenido_tipo.id));
      window.open(url, '_blank');
    }
  }

  //BOTONES
  guardar() {
    var guardar = true;

    this.requiereNombre = false;
    this.requiereReferencia = false;
    this.requiereAngulo = false;
    this.requiereCoste = false;
    this.requiereDiametro = false;
    this.requiereIdERP = false;
    this.requiereLongitud = false;
    this.requiereNumContenido = false;
    this.requiereSalto = false;
    this.requiereVidaUtil = false;
    this.requiereTipo = false;
    this.requiereFabricante = false;
    this.requiereVc= false;
    this.requiereF= false;

    //valores obligatorios siempre y cuando se muestren
    if (this.nombre == '') {
      guardar = false;
      this.requiereNombre = true;
    }
    if (this.referencia == '') {
      guardar = false;
      this.requiereReferencia = true;
    }
    if (this.angulo == null && this.JconsumiblesTiposSelected.solicitar_angulo) {
      guardar = false;
      this.requiereAngulo = true;
    }
    if (this.coste == null && this.JconsumiblesTiposSelected.solicitar_coste) {
      guardar = false;
      this.requiereCoste = true;
    }
    if (this.vc == null && this.JconsumiblesTiposSelected.solicitar_vc) {
      guardar = false;
      this.requiereVc = true;
    }
    if (this.f == null && this.JconsumiblesTiposSelected.solicitar_f) {
      guardar = false;
      this.requiereF = true;
    }
    if (this.diametro == null && this.JconsumiblesTiposSelected.solicitar_diametro) {
      guardar = false;
      this.requiereDiametro = true;
    }
    if (this.idErp == null && this.JconsumiblesTiposSelected.solicitar_idERP) {
      guardar = false;
      this.requiereIdERP = true;
    }
    if (this.longitud == null && this.JconsumiblesTiposSelected.solicitar_longitud) {
      guardar = false;
      this.requiereLongitud = true;
    }
    if (this.numContenido == null && this.JconsumiblesTiposSelected.solicitar_numContenido) {
      guardar = false;
      this.requiereNumContenido = true;
    }
    if (this.salto == null && this.JconsumiblesTiposSelected.solicitar_salto) {
      guardar = false;
      this.requiereSalto = true;
    }
    if (this.vidaUtil == null && this.JconsumiblesTiposSelected.solicitar_vidaUtil) {
      guardar = false;
      this.requiereVidaUtil = true;
    }
    if (this.fiabilidad == null && this.JconsumiblesTiposSelected.solicitar_vidaUtil) {
      guardar = false;
      this.requierefiabilidad = true;
    }
    if (this.JconsumiblesTiposSelected == undefined) {
      guardar = false;
      this.requiereTipo = true;
    }
    if (this.JconsumiblesFabricantesSelected == undefined) {
      guardar = false;
      this.requiereFabricante = true;
    }

    if (guardar) {  
      if(this.tieneNserie){
        var nserie = 1;
      }else{
        var nserie = 0;
      }
      try{   // se guarda una vez comprobado que todos los valores requeridos estan definidos
      this.consumiblesService.Guardar_consumible(this.id, this.JconsumiblesTiposSelected.id, this.JconsumiblesFabricantesSelected.id,
        this.nombre, this.referencia, this.descripcion, nserie,
        this.numContenido, this.vidaUtil, this.fiabilidad, this.diametro, this.longitud,
        this.angulo, this.salto, this.idErp, this.coste, this.vc, this.f).subscribe(
          (json) => {
            console.log("Entro");
            var an: any = json;
            if (an > 0) {
              this.gridData.data.forEach(
                (row) => {
                  row.idConsumibles_contenido_tipo = row.idConsumibles_contenido_tipo.id;
                  if (row.idConsumibles_contenido == undefined)
                    row.idConsumibles_contenido = 0;
                  else
                    row.idConsumibles_contenido = row.idConsumibles_contenido.id;
                  if (row.idConsumibles == 0)
                    row.idConsumibles = json;
                });
              this.consumiblesService.Guardar_consumible_contenido(this.id, this.gridData.data).subscribe(
                (r) => {
                  var an: any = json;
                  if (an > 0) {
                    this.router.navigate(['consumibles']);
                  }
                  else {
                    this.popupErrorAlGuardar = true;
                  }
                });
            }
            else {
              this.popupErrorAlGuardar = true;
            }
          });
      }catch(e){
        console.log(e);
      }
    }
  }
  cancelar() {
    this.router.navigate(['consumibles']);
  }

  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
  //Funciones para cambiar el orden del kendo arrastrando las filas
  public rowCallback(context: RowClassArgs) {
    context.dataItem.orden = context.index + 1;
    return {
      dragging: context.dataItem.dragging
    };
  }
  private handleDragAndDrop(): Subscription {

    const sub = new Subscription(() => { });
    let draggedItemIndex;
    const tableRows = Array.from(document.querySelectorAll('.k-grid tr'));
    tableRows.forEach(item => {
      this.renderer.setAttribute(item, 'draggable', 'true');
      const dragStart = fromEvent<DragEvent>(item, 'dragstart');
      const dragOver = fromEvent(item, 'dragover');
      const dragEnd = fromEvent(item, 'dragend');

      sub.add(dragStart.pipe(
        tap(({ dataTransfer }) => {
          try {
            const dragImgEl = document.createElement('span');
            dragImgEl.setAttribute('style', 'position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;');
            document.body.appendChild(dragImgEl);
            dataTransfer.setDragImage(dragImgEl, 0, 0);
          } catch (err) {
            // IE doesn't support setDragImage
          }
        })
      ).subscribe(({ target }) => {
        const row: HTMLTableRowElement = <HTMLTableRowElement>target;
        draggedItemIndex = row.rowIndex;
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = true;
      }));

      sub.add(dragOver.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
        const dropIndex = closest(e.target, tableRow).rowIndex;
        const dropItem = this.gridData.data[dropIndex];

        draggedItemIndex = dropIndex;
        this.zone.run(() =>
          this.gridData.data.splice(dropIndex, 0, dataItem)
        );
      }));

      sub.add(dragEnd.subscribe((e: any) => {
        e.preventDefault();
        const dataItem = this.gridData.data[draggedItemIndex];
        dataItem.dragging = false;
      }));
    });

    return sub;
  }
  recargarGrid() {
    this.gridData = process(this.Jcontenido, this.state);
    this.currentSubscription.unsubscribe();
    this.zone.onStable.pipe(take(1)).subscribe(() => this.currentSubscription = this.handleDragAndDrop());
  }
  public ngAfterViewInit(): void {
    this.currentSubscription = this.handleDragAndDrop();
  }
  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }


  updateDatosHerramienta(dataItevidaUtilHHmmSSm, cambio, porcen) {
    //aquí hay que revisar que si se cambia el tiempoUso también se debe cambiar el de los demás y lo mismo con el porcentaje
    //también comprobar que no se pasen del tope entre todos los objetos

    this.requiereVidaUtil = false;
    var pattern = new RegExp('^([0-9]?[0-9]?[0-9]?[0-9]):[0-5][0-9]:[0-5][0-9]$');
    var result = pattern.test(cambio);
    if (result == false) {
      this.requiereVidaUtil = true;
    }
    else {
      this.vidaUtil = this.HMSToSeconds(cambio);
    }
  }
  HMSToSeconds(tiempoEstimado) {
    var horas, minutos, segundos;
    horas = Number(tiempoEstimado.split(":")[0]);
    minutos = Number(tiempoEstimado.split(":")[1]);
    segundos = Number(tiempoEstimado.split(":")[2]);
    return horas * 3600 + minutos * 60 + segundos;
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }
}
