<kendo-treelist [kendoTreeListFlatBinding]="diccionarioperdidas"
                kendoTreeListExpandable
                idField="id"
                [initiallyExpanded]="true"
                parentIdField="idPadre"
                [sortable]="true"
                [navigable]="true"
                filterable="menu"
                (cellClick)="cellClick($event)"
                kendoTreeListSelectable
                [selectable]="settings"
                [(selectedItems)]="selected"
                *ngIf="router.url === '/diccionarioperdidas'">
  <!--BOTONES ARRIBA-->
  <ng-template kendoTreeListToolbarTemplate position="top">
    <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
    <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
    <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting || this.user.diccionarioPerdidas<2">{{ 'eliminar' | translate}}</button>
  </ng-template>
  <!--id--><kendo-treelist-column width="0%" field="id" title="id"></kendo-treelist-column><!-- (NO PUEDE SER HIDDEN) Esta columna es necesaria para el D&D y saber sobre que columna se puede o no hacer DROP -->
  <!--checkbox--><kendo-treelist-checkbox-column width="4%" showSelectAll="true"></kendo-treelist-checkbox-column>
  <!--orden--><kendo-treelist-column [expandable]="true" width="7%" field="ordengrid" title="{{ 'orden' | translate}}"></kendo-treelist-column>
  <!--nombre--><kendo-treelist-column width="30%" field="nombre" title="{{ 'nombre' | translate}}"></kendo-treelist-column>
  <!--descripcion-->
  <kendo-treelist-column width="40%" field="descripcion" title="{{ 'descripcion' | translate}}"></kendo-treelist-column>
  <!--tipo perdida-->
  <kendo-treelist-column field="perdida" title="{{ 'perdidaAsignada' | translate}}" width="11%">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span>{{ dataItem.perdida | translate }}</span>
    </ng-template>
  </kendo-treelist-column>
  <!--requerido-->
  <kendo-treelist-column field="requerido" title="{{ 'requerido' | translate}}" width="7%"
                         [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.requerido" disabled />
    </ng-template>
  </kendo-treelist-column>

  <!-- nombreDiccionarioPerdidaDat -->
  <kendo-treelist-column field="nombreDiccionarioPerdida" title="{{ 'tipoPerdidas' | translate}}" width="21%">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span>{{ dataItem.nombreDiccionarioPerdida | translate }}</span>
    </ng-template>
  </kendo-treelist-column>
  <!--es operacion-->
  <!-- <kendo-treelist-column field="operacion" title="{{ 'esOperacion' | translate}}" width="8%"
    [style]="{'text-align': 'center'}" filter="boolean">
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <input type="checkbox" [checked]="dataItem.operacion" disabled />
    </ng-template>
  </kendo-treelist-column> -->
  <kendo-treelist-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                           filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                           filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                           filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                           filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                           filterContainsOperator="{{'filterContainsOperator' | translate}}"
                           filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                           filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                           filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                           filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                           filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                           filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                           filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                           filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                           filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                           filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                           filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                           filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                           filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                           filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                           groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                           noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
  </kendo-treelist-messages>
</kendo-treelist>

<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading)" [disabled]="isDeleting || this.user.diccionarioPerdidas<2">{{ 'si' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>