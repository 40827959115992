<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }
</style>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <!-- HIERARKIKO -->
  <kendo-treelist [kendoTreeListFlatBinding]="piezasPredefinidas"
                  kendoTreeListExpandable
                  idField="idParte"
                  [initiallyExpanded]="true"
                  parentIdField="idPadre"
                  [sortable]="true"
                  [navigable]="true"
                  filterable="menu"
                  kendoTreeListSelectable
                  [(selectedItems)]="partesseleccionados"
                  (cellClick)="cellClick($event)"
                  [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, multiple: true}"
                  *ngIf="router.url === '/piezaspredefinidas'">
     <ng-template kendoTreeListToolbarTemplate position="top">
        <button (click)="onClickEditar($event)" [disabled]="!botonesActivados" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
        <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
        <button (click)="onClickEliminar(content, $event)" [disabled]="!botonesActivados || this.user.piezasPredefinidas < 2" class="btn btn-danger btn-sm mr-1" >{{ 'eliminar' | translate}}</button>
      </ng-template>
    <kendo-treelist-checkbox-column width="5%" showSelectAll="true"></kendo-treelist-checkbox-column>
    <kendo-treelist-column [expandable]="true" field="refGrid" title="{{ 'referencia' | translate }}" width="15%"></kendo-treelist-column>
    <kendo-treelist-column field="nombreGrid" title="{{ 'nombre' | translate }}" width="20%"></kendo-treelist-column>
    <kendo-treelist-column field="numeroPlano" title="{{ 'plano' | translate }}" width="15%"></kendo-treelist-column>
    <kendo-treelist-column field="nombreParte" title="{{ 'parte' | translate }}" width="15%"></kendo-treelist-column>
    <kendo-treelist-column field="descripcion" title="{{ 'descripcion' | translate }}" width="30%"></kendo-treelist-column>
    <kendo-treelist-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                             filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                             filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                             filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                             filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                             filterContainsOperator="{{'filterContainsOperator' | translate}}"
                             filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                             filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                             filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                             filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                             filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                             filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                             filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                             filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                             filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                             filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                             filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                             filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                             filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                             filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                             groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                             noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
    </kendo-treelist-messages>
  </kendo-treelist>
</div>
<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading, contentNotErased)" 
            [disabled]="this.user.piezasPredefinidas < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP NO SE PUDO ELIMINAR-->
<ng-template #contentNotErased let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'registrosnoeliminadas' | translate }}</p>
  </div>

</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>