import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MaquinasService, InstalacionesService, TurnosService, UsuariosService, HistoricoMaquinasDatosService, HistoricoMaquinasDatosTemporal2Service, CentroMecanizadoService } from '@app/_services';
import { AppComponent } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
@Component({ selector: 'hmi-panel', templateUrl: 'hmiPanel.html' })

export class HMIPanelComponent implements OnInit {

    @Input() public idMaquina: number;

    //#region Parametros de maquinas
    public datosMaquinas: any = [];
    public historicoDatosTemporal: any = [];

    public datosCargados: boolean = false;

    public fechaInicio;
    public fechaFin;
    public tipoTurno = new Map();
    
    public primeraVez = true;

    private timeoutzen;

    public loading = false;

    public historicoMaquinasDatosCargados = false;
    //#endregion

    //#region Home plantilla base (esto se hace para que mientras los datos se carguen se enseñe una base)
    public plantillaBase =     {
        idHistorico_Procesos: 0,
        idRelacionado: -1,
        idMaquina: 0,
        fechaini: new Date(),
        idSubEstados: null,
        idEstado: 1,
        tiempoEstado: 0,
        horaServidor: new Date(),
        now: new Date(),
        datosOperaciones: [
          {
            idHistorico_operaciones: 0,
            idOperacion: 0,
            idHistorico_operaciones_grupos: 0,
            fechaini: new Date(),
            porcentajeCapacidadMaquina: 0,
            operacion: true,
            nombreOperacion: " ",
            tiempoEstimadoEjecucion: 0,
            tiempoEstimadoPreparacion: 0,
            tiempoPredictivoEjecucion: 0,
            tiempoPredictivoPreparacion: 0,
            operacionTerminada: false,
            idRuta: 0,
            idOF: 0,
            numeroOF: "",
            idCliente: 0,
            cliente: " ",
            nombreSubCliente: "",
            referencia: "",
            idPieza: 0,
            nombrePieza: " ",
            referenciaPieza: "",
            numeroPlano: "",
            idParte: 0,
            nombreParte: "",
            referenciaParte: "",
            nombreRuta: "",
            usaLotes: false,
            cantidad: 0,
            cantidadReal: 1,
            hmoar_tiempoPreparacion: 0,
            hmoar_tiempoOperacion: 0,
            hmoar_tiempoTotalOperacion: 0,
            hmoar_tiempoNoComputable: 0,
            hmoar_tiempoDisponibilidad: 0,
            hmoar_tiempoRendimiento: 0,
            hmoar_tiempoCalidad: 0,
            hmoar_tiempoEstimadoPreparacion: 0,
            hmoar_tiempoEstimadoOperacion: 0,
            hmoar_tiempoPredictivoPreparacion: 0,
            hmoar_tiempoPredictivoOperacion: 0,
            hmoar_cantidadTerminada: 0,
            hmoar_cantidadApartada: 0,
            hmoar_cantidadAchatarrada: 0,
            hmoar_cantidad: 0,
            colada: "",
            lote: "",
            nserie: "",
            ho_cantidadAchatarrada: 0,
            ho_cantidadApartada: 0,
            ho_cantidadTerminada: 0,
            tieneGrupos: false,
            gruposOperaciones: [
              {
                tiempoEstimadoTotal: 0,
                temperaturaTotal: 0,
                terminado: 0,
                nombreGrupo: "",
                unidadesTotal: 0,
                pesoTotal: 0,
                peso: 0,
                unidades: 0,
                temperatura: 0,
                tiempoEstimado: 0,
                cantidad: 0,
                numeroOf: "",
                nombrePieza: "",
                nombreOperacion: " ",
                colorCantidad: "",
              },
            ],
            tiempoLotePrepTurno: 0,
            tiempoLoteTurno: 0,
            contadorTurno: 0,
            tiempoUltimaPieza: 0,
            tiempoLote: 0,
            tiempoLotePrep: 0,
            cantidadApartadaTurno: 0,
            cantidadAchatarradaTurno: 0,
          },
        ],
        datosOperarios: [
          {
            idOperario: 997,
            fechaini: new Date(),
            operario: "",
            operarioSiglas: "",
          },
        ],
        nombrePrograma: " ",
        faseOperacion: 0,
        contadorMaquina: 0,
        contadorEstimado: 0,
        idPerdida: -1,
        perdida: "",
        idSubPerdida: -1,
        subPerdida: "",
        idGrupoPerdida: -1,
        grupoPerdida: "",
        idTipoOEE_perdida: -1,
        maquinaMantenimiento: "",
        observacionMantenimiento: "",
        maquina: "",
        idMaquinaGrupo: 0,
        maquinaGrupo: "",
        idSeccion: 0,
        seccion: "",
        idAreaProductiva: 1,
        areaProductiva: "",
        idHomeSeleccionIzq: 1,
        idHomeSeleccionDer: 4,
        idEje1: -1,
        idEje2: -1,
        idEje3: -1,
        idEje4: -1,
        idVariable_Tipo1: -1,
        idVariable_Tipo2: -1,
        idVariable_Tipo3: -1,
        idVariable_Tipo4: -1,
        coste: 0,
        puestoOffline: true,
        tipo_maquina: 0,
        cantidadesTerminadasEnPreparacion: 0,
        capacidadPesoMaximo: 0,
        capacidadUnidadesMaximo: 0,
        subestado: "",
        color_subestado: "",
        machine_subestado: 0,
        estado: "Ejecución",
        color_estado: "#C0EADA",
        machine_estado: 1,
        piezasHora: 0,
        sinConexion: false,
        FDM_temperaturaHorno: 0,
        FDM_temperaturaExtrusor: 0,
        FDM_dewPoint: 0,
        FDM_airFlow: 0,
        FDM_VoltageUPS: true,
        FDM_ligthTower: 0,
        FDM_puertaAbiertaCerrada: false,
        FDM_posicionesEjes: false,
        FDM_platinaAspiradora: false,
        FDM_cartuchos: "",
        LPFB_nivelDePolvo: 0,
        LPFB_nivelDeO2: 0,
        LPFB_temperaturaCamara: 0,
        LPFB_presionCamara: 0,
        LPFB_potenciaRecirculacion: 0,
        LPFB_presionEntradaCamara: 0,
        LPFB_temperaturaElevador: 0,
        LPFB_temperaturaAguaHelada: 0,
        LPFB_horasFiltro: 0,
        LMD_potenciaDeLaser: 0,
        LMD_velocidadDelRobot: 0,
        LMD_flujoDeMaterial: 0,
        LMD_materialConsumido: 0,
        LMD_flujoDeArgon: 0,
        HP_nivelesDeAgentes: 0,
        HP_modoDeImpresion: 0,
        info_ejes: [
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
            ensennarGrafico: false,
          },
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
          },
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
          },
          {
            idEje: -1,
            nombreEje: "",
            variableTipo_nombre: "",
            valor_min: 0,
            valor_max: 0,
            valor: 0,
          },
        ],
        tNegativoCalidad: 0,
        piezasHoraEstimado: "0",
        piezasHoraRojo: true,
        eurosHoraEstimado: 0,
        eurosHora: 0,
        eurosHoraRojo: false,
        porcentajeEurosHora: 0,
        porcentajeEurosHoraRojo: true,
      }
    //#endregion

    constructor(private historicoMaquinasDatosService: HistoricoMaquinasDatosService,
        private historicoMaquinasDatosTemporalService: HistoricoMaquinasDatosTemporal2Service,
        private maquinasService: MaquinasService,
        public router: Router,
        public appComponent: AppComponent,
        private translateService: TranslateService,
        private instalacionesService: InstalacionesService,
        private turnosService: TurnosService,
        private centroMecanizadoService: CentroMecanizadoService,
        private usuariosService: UsuariosService,
        public myFunctions: MyFunctions) {
    }

    ngOnChanges(changes: SimpleChanges) {

    }


    ngOnInit(): void {
        this.loading = true;
        this.cargarMaquinas();
        // this.actualizarTiempos();
        this.cargarTurnoActual();
    }

    timeDiff(tiempoEstado, now){
        return this.myFunctions.secondsTo_HH_MM_SS((new Date().getTime() - new Date(now).getTime()) / 1000 + tiempoEstado);
    }

    //#region Maquina con proceso -------------------------------------------------------------------------------------------------------
    cargarTurnoActual() {
        this.turnosService.Get_Turnos_Maquinas().subscribe(json => {
            var an: any = json;
            an.forEach(element => {
                this.tipoTurno.set(element.idMaquina, element);
            });
        });
    }

    cargarMaquinas() {
        //MAQUINAS
        var maquinas_model = this.maquinasService.get_maquinas_model();
        if (maquinas_model == false) {
            this.maquinasService.get().subscribe(json => {
                this.maquinasService.set_maquinas_model(json);
                this.datosMaquinas = this.maquinasService.get_maquinas_model();
                this.datosMaquinas = this.datosMaquinas.filter(f => f.id == this.idMaquina);
                this.datosMaquinas.forEach(element => {
                    element.indexOperacion = 0;
                    element.home = this.plantillaBase;
                });
                this.cargarHistoricoMaquinasDatos();
                this.cargarDatos();
            })
        } else {
            this.datosMaquinas = maquinas_model;
            this.datosMaquinas = this.datosMaquinas.filter(f => f.id == this.idMaquina);
            this.datosMaquinas.forEach(element => {
                element.indexOperacion = 0;
                element.home = this.plantillaBase;
            });
            this.cargarHistoricoMaquinasDatos();
            this.cargarDatos();
        }
    }

    cargarHistoricoMaquinasDatos() {

        if (this.router.url.includes('/procesos'))
            setTimeout((d) => this.cargarHistoricoMaquinasDatos(), (5 * 60) * 1000);

        var fechas = this.cargarFechas()

        // this.fechaInicio = this.myFunctions.dateToYYYY_MM_DD_guion(fechas.fechaInicio) + "T00:00:00Z";
        // this.fechaFin = this.myFunctions.dateToYYYY_MM_DD_guion(fechas.fechaFin) + "T00:00:00Z";
        this.fechaInicio = fechas.fechaInicio;
        this.fechaFin = fechas.fechaFin;
        var OEE_donut_calculado: any;
        if (!this.historicoMaquinasDatosService.util(this.fechaInicio, this.fechaFin)) {
            this.historicoMaquinasDatosService.cargar_historico_completo(this.fechaInicio, this.fechaFin).subscribe(
                j => {
                    this.historicoMaquinasDatosService.set_historico_datos(j, this.fechaInicio, this.fechaFin);
                    this.historicoMaquinasDatosCargados = true;
                    // this.cargarDatos();
                    this.prepararInformacion();
                });
        } else {
            this.historicoMaquinasDatosCargados = true;
            // this.cargarDatos();
            this.prepararInformacion();
        }
    }

    cargarDatos() {

        this.historicoMaquinasDatosTemporalService.actualizarTiempos();

        if (!this.historicoMaquinasDatosTemporalService.util()) {
            // if (!(this.timeoutzen == undefined)) {
            //     this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
            //     this.prepararInformacion();
            // }
            this.historicoMaquinasDatosTemporalService.cargar_historico_temporal().subscribe(
                j => {
                    this.historicoMaquinasDatosTemporalService.set_historico_temporal(j);
                    // if (this.timeoutzen == undefined || this.primeraVez) {
                        this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
                        this.prepararInformacion();
                        this.primeraVez = false;
                    // }
                });
        } else {
            this.historicoDatosTemporal = this.historicoMaquinasDatosTemporalService.get_historico_temporal();
            this.prepararInformacion();
        }
        
        if (this.router.url.includes('/procesos')  && this.historicoMaquinasDatosTemporalService.timeoutzenHMI == undefined)
            this.historicoMaquinasDatosTemporalService.timeoutzenHMI = setTimeout((d) => { this.historicoMaquinasDatosTemporalService.timeoutzenHMI = undefined; this.cargarDatos();}, 25000);
    }

    prepararInformacion() {
        this.datosMaquinas.forEach(element => {
            element.primeraCarga = true;
            element.home = this.historicoDatosTemporal.filter(f => f.idMaquina == element.id)[0];

            // this.procesarInformacionColumnasConfigurables(element.homeAux?.idHomeSeleccionIzq, 1, element.id);
            // this.procesarInformacionColumnasConfigurables(element.homeAux?.idHomeSeleccionDer, 2, element.id);

        });
        this.datosCargados = true;

        if (this.historicoDatosTemporal != undefined && this.historicoDatosTemporal.length != 0) {
            this.loading = false
        }
    }

    /*
        * int idHomeSeleccion: el id del modulo seleccionado
        * int numColumna: 1=>izq ; 2=>der
        * int idMaquina
    */
    procesarInformacionColumnasConfigurables(idHomeSeleccion, numColumna, idMaquina) {
        var info, fechaini, fechafin, turnos;

        //#region Establecer fechas
        if ([1, 4, 7].includes(idHomeSeleccion)) { // fecha semana 
            var fechas = this.cargarFechas();
            fechaini = fechas.fechaInicio;
            fechafin = fechas.fechaFin;
            turnos = [];
        } else if ([2, 5].includes(idHomeSeleccion)) { // fecha dia
            fechaini = this.myFunctions.dateToYYYY_MM_DD_guion(this.myFunctions.getDateNow()) + "T00:00:00Z";
            fechafin = this.myFunctions.dateToYYYY_MM_DD_guion(this.myFunctions.getDateNow()) + "T00:00:00Z";
            turnos = [];
        } else if ([3, 6].includes(idHomeSeleccion)) { // fecha turno
            var maquinaInf = this.tipoTurno.get(idMaquina);
            if (maquinaInf != undefined) {
                fechaini = maquinaInf.min;
                fechafin = maquinaInf.max;
                turnos = [maquinaInf.tipoTurno];
            }
        }
        //#endregion

        //#region Establecer datos
        if ([1, 2, 3].includes(idHomeSeleccion)) { // oee
            if (this.historicoMaquinasDatosCargados) info = this.historicoMaquinasDatosService.get_OEE_data_Donut_SIN_filtro([idMaquina], fechaini, fechafin, turnos);
            this.cargarGraficos(idMaquina, info, numColumna, 0);  // primera columna
        } else if ([7].includes(idHomeSeleccion)) {
            if (this.historicoMaquinasDatosCargados) info = this.datosMaquinas.filter(f => f.id == idMaquina)[0]?.home.info_ejes;
            this.cargarGraficos(idMaquina, info, numColumna, 3) 
        } else if ([4, 5, 6]) { // resumen
            if (this.historicoMaquinasDatosCargados) info = this.historicoMaquinasDatosService.get_resumen_estados_porcentaje([idMaquina], fechaini, fechafin, turnos);
            this.cargarGraficos(idMaquina, info, numColumna, 1);  // primera columna
        }
        //#endregion

    }

    actualizarTiempos() {

        if (this.router.url.includes('/procesos'))
            setTimeout((d) => this.actualizarTiempos(), 1000);

        if (this.datosCargados)
            this.datosMaquinas.forEach(element => {
                if (element.home != undefined) {
                    element.home.tiempoEstado += 1;
                    element.home.datosOperaciones.forEach(dato => {
                        dato.tiempoActualPieza += 1;
                        dato.tiempoActualLote += 1;
                    });
                }
            });
    }

    /* TIPO_GRAFICO
        * OEE => 0
        * RESUMEN => 1
        * PARAMETROS => 3
    */
    cargarGraficos(idMaquina, info, col, tipo_grafico) {

        let elemento;
        //#region Cargar datos de OEE
        if (tipo_grafico == 0) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = false;
            if (info != undefined) {

                //#region Esto se utiliza para asegurar que el html este cargado y exista el elemento
                elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
                if (elemento != null) {
                    this.estiloGraficosOEE(info, col, idMaquina);
                    this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                } else {
                    setTimeout(() => {
                        this.estiloGraficosOEE(info, col, idMaquina);
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                    }, 1);
                }
                //#endregion
                
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = Math.round(info.OEE_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = Math.round(info.disponibilidad_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = Math.round(info.rendimiento_porcentaje);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = Math.round(info.calidad_porcentaje);

            }

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_label_" + col] = this.translateService.instant("oee");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_label_" + col] = this.translateService.instant("disp");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_label_" + col] = this.translateService.instant("rend");
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_label_" + col] = this.translateService.instant("cal");
        }
        //#endregion

        //#region Cargar resumen semana
        if (tipo_grafico == 1) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajePreparacion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeEjecucion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeParada"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMantenimiento"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeAlarma"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeApagado"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMicroParada"] = 0;

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPreparacion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TEjecucion"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TParada"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMantenimiento"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TAlarma"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TApagado"] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMicroParada"] = 0;
            if (info != undefined) {
                if (info.columns.length > 0) {
                    if (info.columns.filter(f => f[0] == this.translateService.instant("preparacion")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajePreparacion"] = info.columns.filter(f => f[0] == this.translateService.instant("preparacion"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPreparacion"] = info.columns.filter(f => f[0] == this.translateService.instant("preparacion"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("ejecucion")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeEjecucion"] = info.columns.filter(f => f[0] == this.translateService.instant("ejecucion"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TEjecucion"] = info.columns.filter(f => f[0] == this.translateService.instant("ejecucion"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("parada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeParada"] = info.columns.filter(f => f[0] == this.translateService.instant("parada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TParada"] = info.columns.filter(f => f[0] == this.translateService.instant("parada"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMantenimiento"] = info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMantenimiento"] = info.columns.filter(f => f[0] == this.translateService.instant("mantenimiento"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("alarma")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeAlarma"] = info.columns.filter(f => f[0] == this.translateService.instant("alarma"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TAlarma"] = info.columns.filter(f => f[0] == this.translateService.instant("alarma"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("apagada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeApagado"] = info.columns.filter(f => f[0] == this.translateService.instant("apagada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TApagado"] = info.columns.filter(f => f[0] == this.translateService.instant("apagada"))[0][2];
                    }

                    if (info.columns.filter(f => f[0] == this.translateService.instant("microparada")).length > 0) {
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TPorcentajeMicroParada"] = info.columns.filter(f => f[0] == this.translateService.instant("microparada"))[0][1];
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["TMicroParada"] = info.columns.filter(f => f[0] == this.translateService.instant("microparada"))[0][2];
                    }

                }

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
            }
        }
        //#endregion

        //#region Cargar parametros
        if (tipo_grafico == 3) {
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = 0;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = 0;

            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_ensennarGrafico_" + col] = true;
            this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_ensennarGrafico_" + col] = true;
            if (info != undefined) {
                //#region VALORES
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_ensennarGrafico_" + col] = info[0]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_ensennarGrafico_" + col] = info[1]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_ensennarGrafico_" + col] = info[2]?.ensennarGrafico;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_ensennarGrafico_" + col] = info[3]?.ensennarGrafico;

                info.porcentaje1 = (info[0]?.valor - info[0]?.valor_min) * 100 / (info[0]?.valor_max - info[0]?.valor_min);
                info.porcentaje2 = (info[1]?.valor - info[1]?.valor_min) * 100 / (info[1]?.valor_max - info[1]?.valor_min);
                info.porcentaje3 = (info[2]?.valor - info[2]?.valor_min) * 100 / (info[2]?.valor_max - info[2]?.valor_min);
                info.porcentaje4 = (info[3]?.valor - info[3]?.valor_min) * 100 / (info[3]?.valor_max - info[3]?.valor_min);

                //#region Esto se utiliza para asegurar que el html este cargado y exista el elemento
                elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
                if (elemento != null) {
                    this.estiloGraficosParametros(info, col, idMaquina);
                    this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                } else {
                    setTimeout(() => {
                        this.estiloGraficosParametros(info, col, idMaquina);
                        this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
                    }, 1);
                }
                //#endregion

                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_value_" + col] = Math.round(info[0].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_value_" + col] = Math.round(info[1].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_value_" + col] = Math.round(info[2].valor);
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_value_" + col] = Math.round(info[3].valor);
                //#endregion
                //#region LABEL
                var label1 = info[0]?.nombreEje;
                var label2 = info[1]?.nombreEje;
                var label3 = info[2]?.nombreEje;
                var label4 = info[3]?.nombreEje;
                if (info[0]?.idEje == 0) label1 = this.translateService.instant(info[0]?.variableTipo_nombre);
                if (info[1]?.idEje == 0) label2 = this.translateService.instant(info[1]?.variableTipo_nombre);
                if (info[2]?.idEje == 0) label3 = this.translateService.instant(info[2]?.variableTipo_nombre);
                if (info[3]?.idEje == 0) label4 = this.translateService.instant(info[3]?.variableTipo_nombre);

                if (info[0]?.idEje == undefined) {
                    if (this.translateService.instant(info[0]?.nombreEje).split(" ") > 1)
                        label1 = this.translateService.instant(info[0]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[0]?.nombreEje) != undefined)
                        label1 = this.translateService.instant(info[0]?.nombreEje).split(" ")[0][0];
                } 
                if (info[1]?.idEje == undefined) {
                    if (this.translateService.instant(info[1]?.nombreEje).split(" ") > 1)
                        label2 = this.translateService.instant(info[1]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[1]?.nombreEje) != undefined)
                        label2 = this.translateService.instant(info[1]?.nombreEje).split(" ")[0][0];
                } 
                if (info[2]?.idEje == undefined) {
                    if (this.translateService.instant(info[2]?.nombreEje).split(" ") > 1)
                        label3 = this.translateService.instant(info[2]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[2]?.nombreEje) != undefined)
                        label3 = this.translateService.instant(info[2]?.nombreEje).split(" ")[0][0];
                } 
                if (info[3]?.idEje == undefined) {
                    if (this.translateService.instant(info[3]?.nombreEje).split(" ") > 1)
                        label4 = this.translateService.instant(info[3]?.nombreEje).split(" ")[0][0] + this.translateService.instant(info[0]?.nombreEje).split(" ")[1][0];
                    else if (this.translateService.instant(info[3]?.nombreEje) != undefined)
                        label4 = this.translateService.instant(info[3]?.nombreEje).split(" ")[0][0];
                } 
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_label_" + col] = label1;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col2_label_" + col] = label2;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col3_label_" + col] = label3;
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col4_label_" + col] = label4;
                //#endregion
                
                this.datosMaquinas.filter(f => f.id == idMaquina)[0]["col1_datosCargados_" + col] = true;
            }
        }
        //#endregion

        
    }

    estiloGraficosOEE(info, col, idMaquina) {
        let elemento;
        //#region SEKZIO ARGIA
        elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.OEE_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.disponibilidad_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.rendimiento_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.calidad_porcentaje, 95, 100, 1, true) + "%, #DCE2EA 0)";
        //#endregion

        //#region SEKZIO ILUNA
        elemento = document.getElementById("iluna-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.OEE_porcentaje, 95, 100, 1, true) + "%, #888888 0)";

        elemento = document.getElementById("iluna-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.disponibilidad_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.rendimiento_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.calidad_porcentaje, 95, 100, 1, true) + "%, #888888 0)";
        //#endregion

    }

    estiloGraficosParametros(info, col, idMaquina) {
        let elemento;
        //#region SEKZIO ARGIA
        elemento = document.getElementById("argia-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje1, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje2, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje3, 95, 100, 1, true) + "%, #DCE2EA 0)";
        elemento = document.getElementById("argia-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, white 79%, transparent 80% 100%),  conic-gradient(transparent 95%, white 0), conic-gradient(#7C8593 "
            + this.myFunctions.calcularPorcentaje(info.porcentaje4, 95, 100, 1, true) + "%, #DCE2EA 0)";
        //#endregion

        //#region SEKZIO ILUNA
        elemento = document.getElementById("iluna-col1_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje1, 95, 100, 1, true) + "%, #888888 0)";

        elemento = document.getElementById("iluna-col2_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje2, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col3_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje3, 95, 100, 1, true) + "%, #888888 0)";
        elemento = document.getElementById("iluna-col4_" + col + "_" + idMaquina);
        if (elemento != null) elemento.style.background = "radial-gradient(closest-side, #3c424e 79%, transparent 80% 100%),  conic-gradient(transparent 95%, #3c424e 0), conic-gradient(#CCCCCC "
            + this.myFunctions.calcularPorcentaje(info.porcentaje4, 95, 100, 1, true) + "%, #888888 0)";
        //#endregion

    }

    private cargarFechas() {
        var fechaIni, fechaInicio, fechaFin;

        //calcular fecha inicio
        fechaInicio = this.myFunctions.getDateNow();
        fechaIni = fechaInicio
        if (fechaIni.getDay() == 3) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 2));
        } else if (fechaIni.getDay() == 4) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 3));
        } else if (fechaIni.getDay() == 5) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 4));
        } else if (fechaIni.getDay() == 6) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 5));
        } else if (fechaIni.getDay() == 0) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 6));
        } else if (fechaIni.getDay() == 2) {
            fechaInicio = new Date(fechaIni.setDate(fechaIni.getDate() - 1));
        }

        //calcular fecha fin
        fechaIni = new Date(fechaInicio);
        fechaFin = new Date(fechaIni.setDate(fechaIni.getDate() + 6));

        //calcular horas
        fechaInicio.setHours(0, 0, 0);
        fechaFin.setHours(0, 0, 0);

        return { fechaInicio: fechaInicio, fechaFin: fechaFin }
    }
    //#endregion ------------------------------------------------------------------------------------------------------------------------

    redirigirRendimiento(idMaquina) {
        this.router.navigate(["informerendimiento/" + idMaquina]);
    }

}
