import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { MantenimientosPredefinidosService } from '@app/_services/mantenimientosPredefinidos.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService, UsuariosService } from '../_services';

import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-mantenimientosPredefinidos',
  templateUrl: 'mantenimientosPredefinidos.component.html'
})

export class MantenimientosPredefinidosComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public mantenimientospredefinidos: [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private mantenimientosPredefinidosService: MantenimientosPredefinidosService,
    translate: TranslateService,
    public router: Router,
    private menuService: MenuService,
    private modalService: NgbModal,
    private userService: UsuariosService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('mantenimientospredefinidos').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/mantenimientospredefinidos') {
          this.menuService.titulo = this.translate.instant('mantenimientospredefinidos').toUpperCase();
          this.mantenimientosPredefinidosService.GetAll().subscribe((result) => {
            this.mantenimientospredefinidos = result.data;
          }
          );
        } else {
        }
      }
    });

  }

  ngOnInit() {
    //this.mantenimientosPredefinidosService.GetAll().subscribe((result) => {
    //  this.mantenimientospredefinidos = result.data;
    //}
    //);
  }

  onClickNuevo() {
    this.router.navigate(['mantenimientospredefinidos/crear/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['mantenimientospredefinidos/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['mantenimientospredefinidos/editar/', e.dataItem.id]);
    } 
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    this.mantenimientosPredefinidosService.delete({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.mantenimientosPredefinidosService.GetAll().subscribe((result) => {
              this.mantenimientospredefinidos = result.data;
            }
          );
        }
        this.modalReferenceloading.close();
      }
    );

    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.seleccionados = [];
  }  
}
