import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuService, ActivosService, UsuariosService } from '@app/_services';
import { first } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tipos-activo',
  templateUrl: './tipos-activo.component.html',
  styleUrls: ['./tipos-activo.component.less']
})
export class TiposActivoComponent implements OnInit {

  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  dataTiposActivos: any[];
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  modalReference: NgbModalRef;
  form: FormGroup;

  user = this.userService.userValue;

  constructor(private activosService: ActivosService,
    private menuService: MenuService, public router: Router,
    private route: ActivatedRoute,
    private userService: UsuariosService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private modalService: NgbModal) {
      this.menuService.titulo = this.translateService.instant('tiposActivo');
    
  }

  cargarDatos() {
    this.activosService.getAllTipos().subscribe((result) => {
      this.dataTiposActivos = result;
    });
  }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['tiposActivo/' +  this.mySelection[0]]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['tiposActivo/' +  this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['tiposActivo/0']);
  }

  onClickEliminar() {
    this.modalReference = this.modalService.open(this.popupBorrar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptar() {
    this.mySelection.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        this.mySelection.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.activosService.Delete_tipos(ids).subscribe(() => {
          this.isDeleting = false;
          this.cargarDatos();
        });
      }
      this.modalReference.close();
    });
  }
}
