import { Component, Input } from '@angular/core';
import { MaquinasService, MenuService, UsuariosService } from '@app/_services';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-maquina-secciones-alarmas-pasivas',
  templateUrl: './maquinaDetalleAlarmasPasivas.component.html'
})

export class MaquinaDetalleSeccionesAlarmasPasivasComponent {

  @Input() id: string;

  public alarmasPasivas: [];
  public alarmasPasivas_seleccionados: number[] = [];
  public alarmasPasivas_modalReference: NgbModalRef;
  public alarmasPasivas_modalReferenceLoading: NgbModalRef;
  user = this.userService.userValue;

  constructor(
    private maquinasService: MaquinasService,
    public router: Router,
    private menuService: MenuService,
    private translateService: TranslateService,
    private userService: UsuariosService,
    private modalService: NgbModal) {

    this.menuService.titulo = this.translateService.instant('maquina').toUpperCase();
  }

  ngOnInit() {

    this.maquinasService.GetAll_AlarmasPasivas(this.id).subscribe((result) => {
      this.alarmasPasivas = result.data;
    });

  }

  alarmasPasivas_onClickNuevo() {
    if (this.id != undefined) {
      this.router.navigate(['maquinas/alarmapasiva/crear/' + this.id]);
    }
  }

  alarmasPasivas_onClickEditar() {
    if (this.id != undefined) {
      if (this.alarmasPasivas_seleccionados[0] > 0) {
        this.router.navigate(['maquinas/alarmapasiva/editar/' + this.id + '/' + this.alarmasPasivas_seleccionados[0]]);
      }
    }
  }

  alarmasPasivas_cellClick(e) {
    if (this.id != undefined) {
      if (e.columnIndex > 0) {
        if (this.id != undefined) {
          this.router.navigate(['maquinas/alarmapasiva/editar/' + this.id + '/' + this.alarmasPasivas_seleccionados[0]]);
        }
      }
    }
  }

  alarmasPasivas_onClickEliminar(content) {
    if (this.id != undefined) {
      if (this.alarmasPasivas_seleccionados.length > 0) {
        this.alarmasPasivas_modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
      }
    }
  }

  alarmasPasivas_eliminarRegistro(contentLoading) {
    if (this.id != undefined) {
      this.maquinasService.Delete_Alarma_Pasiva({ ids: this.alarmasPasivas_seleccionados }).subscribe(
        (data) => {
          if (data.error == false) {
            this.maquinasService.GetAll_AlarmasPasivas(this.id).subscribe((result) => {
              this.alarmasPasivas = result.data;
            }
            );
          }
          this.alarmasPasivas_seleccionados = [];
          this.alarmasPasivas_modalReferenceLoading.close();
        }
      );

      this.alarmasPasivas_modalReference.close();
      this.alarmasPasivas_modalReferenceLoading = this.modalService.open(contentLoading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    }
  }

}
