<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
      <div class="card">
        <div class="card-body">
          <!--Texto busqueda-->
          <div class="form-group">
            <kendo-label text="{{ 'textoBusqueda' | translate }}">
              <kendo-textbox formControlName="textoBusqueda" [ngClass]="{ 'is-invalid': submitted && f.textoBusqueda.errors }"></kendo-textbox>
              <div *ngIf="submitted && f.textoBusqueda.errors" class="invalid-feedback">
                <div *ngIf="f.textoBusqueda.errors.required">{{ 'textoBusquedaEsRequerido' |
                    translate}}</div>
            </div>
            </kendo-label>
          </div>
          <!--Descripcion-->
          <div class="form-group">
            <kendo-label text="{{ 'descripcion' | translate }}">
              <kendo-textbox formControlName="descripcion" [ngClass]="{ 'is-invalid': submitted && f.descripcion.errors }"></kendo-textbox>
              <div *ngIf="submitted && f.descripcion.errors" class="invalid-feedback">
                <div *ngIf="f.descripcion.errors.required">{{ 'descripcionEsRequerida' |
                    translate}}</div>
            </div>
            </kendo-label>
          </div>
          <!--Accion-->
          <div class="form-group">
            <label class="control-label">{{ 'accion' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist [data]="acciones" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="acciones_selectionChange($event)" [value]="accion_selectedItem" class="form-control">
              </kendo-dropdownlist>
            </div>
          </div>
          <!--Tipo alarma-->
          <div class="form-group">
            <label class="control-label">{{ 'tipoAlarma' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist [data]="tiposAlarmas" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="tiposAlarmas_selectionChange($event)" [value]="tipoAlarma_selectedItem" class="form-control">
              </kendo-dropdownlist>
            </div>
          </div>
          <!--Repercute en-->
          <div class="form-group">
            <label class="control-label">{{ 'repercuteEn' | translate }}</label>
            <div class="caja">
              <kendo-dropdownlist [data]="alarmasRepercutenA" [textField]="'nombre'" [valueField]="'id'" [(value)]="alarmasRepercutenASelected" class="form-control">
              </kendo-dropdownlist>
            </div>
          </div>
          <!--alarmas dentro de operacion-->
          <div class="form-group">
            <label class="control-label">{{ 'alarmasDentroDeOperacion' | translate }}</label>
            <div class="caja">
              <kendo-switch [(ngModel)]="alarmasDentroDeOperacion" formControlName="alarmasDentroDeOperacion" [onLabel]="' '" [offLabel]="' '">
              </kendo-switch>
            </div>
          </div>

          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.maquinas<2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()">
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
