import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { html5Icon } from '@progress/kendo-svg-icons';
import { MyFunctions, MyCharts } from '@app/_helpers';

@Component({ selector: 'zitu-chart-gauge', templateUrl: 'chart_gauge.component.html' })

export class ChartGaugeComponent implements OnInit {

  @Input() public min: number;
  @Input() public max: number;
  @Input() public value: number;
  @Input() public idHTML: string;

  public array = [];

  constructor(public router: Router,
    public myFunctions: MyFunctions,
    public myCharts: MyCharts) {
      
  }
  
  ngOnInit(): void {    
    setTimeout(() => { 
      this.cargarElemento();
     }, 0);    
  }

  cargarElemento(){    
    var elemento = document.getElementById(this.idHTML);

    console.log("NEW CONTROL")
    console.log(this.min)
    console.log(this.max)
    console.log(this.value)
    console.log(this.idHTML)
    console.log(elemento)

    if (elemento != null) 
      elemento.style.background = "radial-gradient(closest-side, #F8F8F8 79%, transparent 80% 100%),  conic-gradient(transparent 50%, #F8F8F8 0), conic-gradient(#22C4C4 "
        + this.myFunctions.calcularPorcentaje(this.value, 50, 100, 1, true) + "%, #DCE2EA 0)";
  }    
   
}
