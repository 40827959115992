import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"
import { TurnosService, MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MyFunctions } from '@app/_helpers';

@Component({
  selector: 'app-turnos',
  templateUrl: 'turnos.component.html'
})

export class TurnosComponent {

  public seleccionados: any[] = [];
  public fechasTurnosSeleccionados: any[] = [];
  public turnos: any;
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  user = this.userService.userValue;

  constructor(private turnosService: TurnosService,
    private translateService: TranslateService,
    private userService: UsuariosService,
    public router: Router,
    private menuService: MenuService,
    private modalService: NgbModal,
    public myFunctions: MyFunctions) {

    this.menuService.titulo = this.translateService.instant('turnos').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/turnos') {
          this.turnosService.GetAll().subscribe((result) => {
            this.turnos = result.data;
          }
          );
        } else {
        }
      }
    });
  }

  ngOnInit() {
    this.turnosService.GetAll().subscribe((result) => {
      this.turnos = result.data;
    }
    );
  }

  onClickNuevo() {
    this.router.navigate(['turnos/crear/']);
  }

  onClickEditar() {
    if (this.seleccionados[0] > 0) {
      this.router.navigate(['turnos/editar/', this.seleccionados[0]]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['turnos/editar/', this.seleccionados[0]]);
    }
  }

  onClickEliminar(content) {
    if (this.seleccionados.length > 0) {
      //metemos los turnos en la lista
      this.fechasTurnosSeleccionados = this.turnos.filter(x => this.seleccionados.includes(x.id)).map((x) => (x.fechaInicio)).map(this.myFunctions.sqlToJsDate);
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading) {
    let selected = this.myFunctions.copy(this.seleccionados);
    selected.forEach(element => {
      var idCalendarioSemanas = element;
      this.turnosService.Actualizar_Tabla_Turnos(idCalendarioSemanas, 1).subscribe((result) => {});
    });

    this.turnosService.Delete({ ids: this.seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          //BORRAMOS EL HISTORICO TURNOS DESDE LA FECHA MAS PEQUEÑA DE LOS TURNOS SELECCIONADOS
          var fechaMinTurnos = this.fechasTurnosSeleccionados.reduce(function (a, b) { return a < b ? a : b; });
          if (fechaMinTurnos != undefined && fechaMinTurnos != null) {
            this.turnosService.DeleteHistoricoTurnos(this.myFunctions.dateToYYYYMMDDtHHmmSSz(fechaMinTurnos)).subscribe((result) => {
              this.turnosService.GetAll().subscribe((result) => {
                this.turnos = result.data;
              });
            });
          }
        }
        this.modalReferenceloading.close();
        this.seleccionados = [];
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
  }
}
