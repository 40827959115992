import { Component } from '@angular/core';
import { Router, NavigationEnd } from "@angular/router"

import { PiezasPredefinidasService } from '@app/_services/piezasPredefinidas.service';

import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, InformeProyectosService, MenuService, UsuariosService } from '../_services';
import { ViewEncapsulation, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-piezaspredefinidas',
  templateUrl: 'piezasPredefinidas.component.html'
})

export class PiezasPredefinidasComponent {

  public seleccionados: number[] = [];
  private translate: TranslateService;
  public piezasPredefinidas: [];
  public partesseleccionados: number[] = [];
  modalReference: NgbModalRef;
  modalReferenceloading: NgbModalRef;
  closeResult = '';
  navigationSubscription;
  public botonesActivados: Boolean;
  user = this.usuariosService.userValue;

  constructor(private piezasPredefinidasService: PiezasPredefinidasService, private usuariosService: UsuariosService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal, protected alertService: AlertService, private informeProyectosService: InformeProyectosService) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('piezaspredefinidas').toUpperCase();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/piezaspredefinidas') {
          this.menuService.titulo = this.translateService.instant('piezaspredefinidas');
          this.piezasPredefinidasService.GetAll().subscribe((result) => {

            var piezas = result.piezas
            piezas.forEach(
              row => {
                if (!row.esPieza) {
                  var lag = result.piezas.filter(f => f.esPieza && row.id == f.idGridField)
                  if (lag.length > 0) {
                    row.idPadre = lag[0].idParte;
                  }
                  else {
                    row.idPadre = null;
                  }
                }
                else {
                  row.idPadre = null;
                }
              });

              console.table(piezas)
            this.piezasPredefinidas = piezas;
            this.botonesActivados = piezas.length > 0;
          }
          );
        } else {

        }
      }
    });
  }

  ngOnInit() {
    this.cargarDatos();
  }

  cargarDatos() {
    this.piezasPredefinidasService.GetAll().subscribe(
      (result) => {
        var piezas = result.piezas
        piezas.forEach(
          row => {
            if (!row.esPieza) {
              var lag = result.piezas.filter(f => f.esPieza && row.id == f.idGridField)
              if (lag.length > 0) {
                row.idPadre = lag[0].idParte;
              }
              else {
                row.idPadre = null;
              }
            }
            else {
              row.idPadre = null;
            }
          });

          console.table(piezas)
        this.piezasPredefinidas = piezas;
        this.botonesActivados = piezas.length > 0;
      });
    this.botonesActivados = false;
  }

  onClickNuevo() {
    this.router.navigate(['piezaspredefinidas/crear/']);
  }

  onClickEditar(e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    if (e.columnIndex > 0) {
      this.router.navigate(['piezaspredefinidas/editar/', e.dataItem.idParte]);
    }
  }

  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['piezaspredefinidas/editar/', e.dataItem.idParte]);
    }
  }

  onClickEliminar(content, e) {
    e.preventDefault(); //Con esto no manda al submit (hasta que se macrque manualmente). PERO SOLO HAY QUE PONERLO EN LOS MARCADOS
    console.log(this.partesseleccionados)
    if (this.partesseleccionados.length > 0) {
      this.modalReference = this.modalService.open(content, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  private eliminarRegistro(contentloading, contentNotErased) {
    console.log(this.partesseleccionados)

    var seleccionados = [];

    this.partesseleccionados.forEach(
      element => {
         seleccionados.push(element["itemKey" ]);
      });


    this.piezasPredefinidasService.delete({ ids: seleccionados }).subscribe(
      (data) => {
        if (data.error == false) {
          this.alertService.success(this.translate.instant('ok'), { keepAfterRouteChange: true });
          this.cargarDatos();
        }
        else {
          this.alertService.error(this.translate.instant('error'));
        }
        this.modalReferenceloading.close();
      }
    );
    this.modalReference.close();
    this.modalReferenceloading = this.modalService.open(contentloading, { backdrop: 'static', size: 'sm', keyboard: false, centered: true });
    this.partesseleccionados = [];
  }
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;


  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN') &&
      (element.offsetWidth < element.scrollWidth)
      && !element.classList.contains('celda-tooltip-externo') && !element.classList.contains('tooltiptext')) {
      //Si tiene estas clases utiliza el otro tooltip, por lo que no debe mostrar este
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
