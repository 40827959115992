import { Injectable } from '@angular/core';
import { UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import * as moment from 'moment-timezone';
import { MyFunctions } from './myfunctions';

import * as d3 from 'd3';
import * as c3 from 'c3';
import { NumericFilterCellComponent } from '@progress/kendo-angular-treelist';
import { translate } from '@angular/localize/src/utils';

@Injectable()
export class MyCharts {

  public tiempoTransition: number = 2000; // TIEMPO DE TRANSICION DE GRAFICOS ( ANIMACION DE CARGA )
  public tiempoEsperaRenderizado: number = 300; // DESDE QUE SE CARGA UN GRAFICO O SE DESCARGA HASTA QUE SE VUELVEN A AÑADIR DATOS, SE ESPERARA X MS (ESTO ES PARA QUE LOS DATOS NO SE CRUCEN Ej: el grafico lineal solapagaba puntos, mantenia criterio de agrupado por semanas cuando ya eran dias...)

  user = this.userService.userValue;
  constructor(private userService: UsuariosService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions) {
  }


  public load(grafico, data) {
    // COMO  a veces no se carga nbien los graficos y hay que esperar un momento. esta funcion hace el sleep que se necesita para todos los graficos.
    grafico.unload();
    setTimeout(() => {
      grafico.load(data);
    }, this.tiempoEsperaRenderizado);
  }



  public dibujarGraficoDonut_simple(idHTML, gordo) {
    //GRAFICO DONUT OEE
    d3.select('#' + idHTML + ' .c3-chart-arcs-title')
      .text("")
      .style("font-size", "30px")
      .attr("fill", "#18d6b0");

    d3.selectAll("#" + idHTML + " .c3-chart-arcs path").style("stroke-width", "0px");

    var donut_width = 15;
    if (gordo) {
      donut_width = 30;
    }

    return c3.generate({
      bindto: '#' + idHTML,
      data: {
        columns: [['completo', 0], ['nocompleto', 100]],
        type: 'donut',
        colors: {
          completo: '#18d6b0',
          nocompleto: '#3d6063'
        },
        order: 'null'
      },
      transition: {
        duration: this.tiempoTransition
      },
      donut: {
        title: "0",
        width: donut_width,
        label: { show: false }
      },
      legend: {
        show: false
      },
      tooltip: {
        show: false
      }
    });
  }
  public dibujarGraficoBarrasOEE(idHTML: string, remarcado?: number) {
    //GRAFICO DONUT OEE
    var dataGraficoHoras = [[this.translateService.instant('total'), 0, 0, 0, 0],
    [this.translateService.instant('rendimiento'), 0, 0, 0, 0],
    [this.translateService.instant('microparadas'), 0, 0, 0, 0],
    [this.translateService.instant('paradas'), 0, 0, 0, 0],
    [this.translateService.instant('mantenimientos'), 0, 0, 0, 0],
    [this.translateService.instant('alarmas'), 0, 0, 0, 0],
    [this.translateService.instant('apagadas'), 0, 0, 0, 0],
    [this.translateService.instant('perdidasCalidad'), 0, 0, 0, 0]];

    return c3.generate({
      bindto: '#' + idHTML,
      data: {
        columns: dataGraficoHoras,
        order: null,
        type: 'bar',
        groups: [[this.translateService.instant('total'), this.translateService.instant('rendimiento'), this.translateService.instant('microparadas'), this.translateService.instant('paradas'), this.translateService.instant('mantenimientos'),
        this.translateService.instant('alarmas'), this.translateService.instant('apagadas'), this.translateService.instant('perdidasCalidad')]]
      },
      color: {
        pattern: [['#44e3c4'], ['#ee8625'], ['#CF8729'], ['#E7CB68'], ['#99afcc'], ['#cc6464'], ['#4d4d4d'], ['#ff6347']]
      },
      axis: {
        x: {
          type: 'category',
          tick: {
            multiline: false
          },
          categories: [this.translateService.instant('tiempoTotal'), this.translateService.instant('disponibilidad'), this.translateService.instant('rendimiento'), this.translateService.instant('calidad')]
        },
        y: {
          show: false,
        },
        rotated: true
      },
      transition: {
        duration: this.tiempoTransition
      },
      bar: {
        width: {
          ratio: 0.85
        }
      },
      tooltip: {
        format: {
          value: function (value) {
            var hours = Math.floor(value / (60 * 60));
            var divisor_for_minutes = value % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);
            if (0 <= minutes && minutes < 10)
              return hours + ":0" + minutes + "h";
            else
              return hours + ":" + minutes + "h";
          }
        }
      },
      onrendered: function () {
        d3.selectAll("#" + idHTML + " .c3-bar")
          .style("opacity", function (d) {
            if (d.index == remarcado || remarcado == undefined) {
              return 1;
            } else {
              return 0.4
            }
          });
      }
    });
  }
  public dibujarGraficoDonut_INFO(idHTML) {

    // d3.selectAll(idHTML + " .c3-chart-arcs path").style("stroke-width", "0px");

    return c3.generate({
      bindto: '#' + idHTML,
      data: {
        columns: [],
        type: 'donut',
        onmouseover: function (d) {
          d3.select(idHTML + ' .c3-chart-arcs-title')
            .append("tspan")
            .attr("font-size", "35")
            .text((d.ratio * 100).toFixed(1) + "%");
          d3.select(idHTML + ' .c3-chart-arcs-title')
            .append("tspan")
            .attr("dy", 24)
            .attr("x", 0)
            .attr("font-size", "10")
            .text(d.id);
        },
        onmouseout: function (d) {
          d3.select(idHTML + ' .c3-chart-arcs-title').node().innerHTML = "";
        },
      },
      transition: {
        duration: this.tiempoTransition
      },
      legend: {
        //bottom, right, inset
        position: 'right'
      },
      color: {
        pattern: ['#fd8a1c', '#d33737', '#dc2e6e', '#7359d8', '#5fb2de', '#24fdf6', '#41af72', '#94ff43', '#f4e54e', '#ce9c40']
      },
      tooltip: {
        show: false
      }
    });
  }
  public dibujarGraficoBarras(idHTML) {
    //GRAFICO BARRAS
    var colors = ['#ffb63d', '#ff7666', '#FF89BC', '#bb8eff', '#68e0ff', '#92dcdc', '#50df88', '#87ed77', '#eaea62', '#ffb976'];
    var selectedItemsBarras = [];

    return c3.generate({
      bindto: '#' + idHTML,
      data: {
        x: 'x',
        columns: this.sortData([[' '], [this.translateService.instant('desvio'), null]]),
        type: 'bar',
        color: function (color, d) {
          return colors[d.index % 10];
        },
        selection: {
          grouped: true
        },
        onclick: function (d1) {
          //if (selectedItemsBarras.indexOf(d1) === -1) {
          if (selectedItemsBarras.map(function (e) { return e.x; }).indexOf(d1.x) === -1) {
            if (d1.value !== null) {
              selectedItemsBarras.push(d1);
            }
          } else {
            selectedItemsBarras.splice(selectedItemsBarras.map(function (e) { return e.x; }).indexOf(d1.x), 1);
          }
        }
      },
      axis: {
        x: {
          type: 'category'
        },
        y: {
          tick: {
            values: [0],
            format: function (value) {
              return Math.floor(value / 3600) + "h"

            }
          }
        }
      },

      tooltip: {
        format: {
          value: function (value) {
            if (value >= 0) {
              return Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
            } else {
              return "-" + Math.floor(Math.abs(value) / 3600) + ":" + ("0" + (Math.floor((Math.abs(value) % 3600) / 60))).slice(-2) + "h"
            }
          }
        }
      },
      transition: {
        duration: 0,//2000
      },

      bar: {
        width: {
          ratio: 0.75
        }
      },
      legend: {
        show: false
      },
      onrendered: function () {
        d3.selectAll('#' + idHTML + ' .c3-bar').style("opacity", function (d) {
          if (d.index === 2) {
            return 0;
          } else {
            return 0;
          }
        }).transition().duration(0).style("opacity", 1);
      }
    });
    //}, 2000);
  }
  public dibujarGraficoBarrasTurnos(idHTML) {
    //GRAFICO BARRAS
    var th = this;
    var chart = c3.generate({
      bindto: '#' + idHTML,
      data: {
        x: 'x',
        columns: [
          ['x', '', '', '', '', '', '', ''],
          ['m_ok', 0, 0, 0, 0, 0, 0, 0],
          ['m_nok', 0, 0, 0, 0, 0, 0, 0],
          ['t_ok', 0, 0, 0, 0, 0, 0, 0],
          ['t_nok', 0, 0, 0, 0, 0, 0, 0],
          ['n_ok', 0, 0, 0, 0, 0, 0, 0],
          ['n_nok', 0, 0, 0, 0, 0, 0, 0],
          ['total', 0, 0, 0, 0, 0, 0, 0]
        ],
        type: 'bar',
        types: {
          total: 'line'// 'spline': esta forma lo hace curbo, pero al bajar a 0 hay un momento que se quedaria en negativo
        },
        groups: [
          ['n_ok', 'n_nok'],
          ['m_ok', 'm_nok'],
          ['t_ok', 't_nok'],
        ],
        colors: {
          m_ok: '#CCFBFF', m_nok: '#F44E42',
          t_ok: '#ECF1CB', t_nok: '#F44E42',
          n_ok: '#8EB7BA', n_nok: '#F44E42',
          total: '#424242'
        },
        order: null,
        axes: {
          m_ok: 'y', m_nok: 'y',
          t_ok: 'y', t_nok: 'y',
          n_ok: 'y', n_nok: 'y',
          total: 'y2'
        }
      },
      grid: {
        y: {
          show: true
        }
      },
      legend: {
        show: false
      },
      axis: {
        x: {
          type: 'category'
        },
        y: {
          padding: {
            bottom: 0,
            top: 0
          },
          max: 30,
          tick: {
            count: 11,
            format: function (x) {
              return th.myFunctions.numero_con_formato(x, 1)
            }
          }
        },
        y2: {
          show: true,
          padding: {
            bottom: 0,
            top: 0
          },
          max: 30,
          tick: {
            count: 11,
            format: function (x) {
              return th.myFunctions.numero_con_formato(x, 1)
            }
          }
        }
      },
      tooltip: {
        contents: function (data) {
          var totalBuenas = Math.round((data[1].value + data[3].value + data[5].value) * 10) / 10;
          var totalMalas = Math.round((data[0].value + data[2].value + data[4].value) * 10) / 10;
          
          var franja1 = 'mannana';
          var franja2 = 'tarde';
          var franja3 = 'noche';
          if (data[0].id == 'n_ok') {
             franja1 = 'noche';
             franja2 = 'mannana'; 
             franja3 = 'tarde';
          }

          var tooltip = ""
            + "<div class='chart-tooltip'>"
            + " <div class='linea-tooltip linea-tooltipheader'>"
            + "   <label class=''>" + th.translateService.instant('turno') + "</label>"
            + "   <div class='piezas-turnos-cantidades'>"
            + "     <label class=''><i class='icon-check'></i></label>"
            + "     <label class='piezas-malas'><i class='icon-piezas-malas'></i></label>"
            + "   </div>"
            + " </div>";
          /* FRANJA 1 */
          tooltip += " <div class='" + franja1 + " linea-tooltip'>"
            + "   <label class=''>" + th.translateService.instant(franja1) + "</label>"
            + "   <div class='piezas-turnos-cantidades'>"
            + "     <label class=''>" + data[0].value + "</label>"
          if (data[1].value > 0) tooltip = tooltip + "     <label class='piezas-malas'>" + data[1].value + "</label>";
          else tooltip = tooltip + "     <label class='piezacero'>-</label>";
          tooltip = tooltip + "   </div>"
            + " </div>";
          /* FRANJA 2 */
          tooltip += " <div class='" + franja2 + " linea-tooltip'>"
            + "   <label class=''>" + th.translateService.instant(franja2) + "</label>"
            + "   <div class='piezas-turnos-cantidades'>"
            + "     <label class=''>" + data[2].value + "</label>"
          if (data[3].value > 0) tooltip = tooltip + "     <label class='piezas-malas'>" + data[3].value + "</label>";
          else tooltip = tooltip + "     <label class='piezacero'>-</label>";
          tooltip = tooltip + "   </div>"
            + " </div>";
          /* FRANJA 3 */
          tooltip += " <div class='" + franja3 + " linea-tooltip'>"
            + "   <label class=''>" + th.translateService.instant(franja3) + "</label>"
            + "   <div class='piezas-turnos-cantidades'>"
            + "     <label class=''>" + data[4].value + "</label>"
          if (data[5].value > 0) tooltip = tooltip + "     <label class='piezas-malas'>" + data[5].value + "</label>";
          else tooltip = tooltip + "     <label class='piezacero'>-</label>";
          tooltip = tooltip + "   </div>"
            + " </div>";

          tooltip += " <div class='total linea-tooltip'>"
            + "   <label class=''>" + th.translateService.instant('total') + "</label>"
            + "   <div class='piezas-turnos-cantidades'>"
            + "     <label class=''>" + totalMalas + "</label>";
          if (totalBuenas > 0) tooltip = tooltip + "     <label class='piezas-malas'>" + totalBuenas + "</label>";
          else tooltip = tooltip + "     <label class='piezacero'>-</label>";
          tooltip = tooltip + "   </div>"
            + " </div>";
          + "</div>";
          return tooltip;
        }
      },
      bar: {
        width: {
          ratio: 0.75
        },
        space: 0.2
      }
    });

    return chart;
  }



  cargarGraficoBarras_Grid(grafico, datos, sobre, variable, nombre_tooltip) {
    var arrayTitulos = [];
    var arrayValores = [this.translateService.instant(nombre_tooltip)];
    var arrayTitulosT = [];
    var arrayValoresT = [this.translateService.instant(nombre_tooltip)];

    datos.forEach(
      row => {
        arrayTitulos.push(row[sobre]);
        arrayValores.push(row[variable]);
        arrayTitulosT.push(row[sobre]);
        arrayValoresT.push(row[variable]);
      }, this);
    //SI SON MENOS DE 5 RELLENAR CON VACIOS PARA QUE LAS BARRAS DEL GRAFICO NO QUEDEN MUY ANCHAS
    for (var i = this.myFunctions.quitarRepeticiones(arrayTitulos).length; i <= 5; i++) {
      arrayTitulos.push(' '.repeat(i));
      arrayValores.push(null);
      arrayTitulosT.push(' '.repeat(i));
      arrayValoresT.push(null);
    }
    //GRAFICO OPERACIONES BARRAS
    var data = [arrayTitulos, arrayValores];
    var ticks = this.getChartAxisRange([arrayTitulosT, arrayValoresT]);

    grafico.internal.config.axis_y_tick_values = ticks;
    grafico.load({ columns: this.sortData(data) });
  }
  public sortData(unsortedData) {
    var sorted = unsortedData.map(function (row) {
      return row.slice();
    })
    var name = sorted[1].splice(0, 1);
    var datapoints = sorted[1].map(function (d, i) {
      return [sorted[0][i], d];
    });
    var sortedData = datapoints.sort(function (a, b) {
      return b[1] - a[1];
    });
    sorted[1] = sortedData.map(function (point, i) {
      sorted[0][i] = point[0];
      return point[1];
    });
    sorted[1] = name.concat(sorted[1]);
    sorted[0].splice(0, 0, 'x');
    return sorted;
  }
  public getChartAxisRange(data) {
    data[1].shift();

    var nmax = Math.max.apply(null, data[1]) / 3600
    var smax = Math.abs(nmax).toString().split('.')
    var dmax = Math.pow(10, smax[0].length - 1)
    var nmin = Math.min.apply(null, data[1]) / 3600
    var smin = Math.abs(nmin).toString().split('.')
    var dmin = Math.pow(10, smin[0].length - 1)

    if (nmin > 0) {
      nmin = 0
    }

    if (dmax > dmin) {
      var result = this.range(Math.round((nmin - dmax) / dmax) * dmax, nmax + dmax, dmax);
      return (result.map(x => x * 3600));
    }
    if (dmax < dmin) {
      var result = this.range(Math.round((nmin - dmin) / dmin) * dmin, nmax + dmin, dmin);
      return (result.map(x => x * 3600));
    }
    if (dmax == dmin) {
      var result = this.range(Math.round((nmin - dmin) / dmin) * dmin, Math.round((nmax + dmin) / dmax) * dmax, dmin);
      return (result.map(x => x * 3600));
    }
  }
  public range(start, end, step = 1) {
    const len = Math.floor((end - start) / step) + 1
    return Array(len).fill(0).map((_, idx) => start + (idx * step))
  }
}
