<div class="card">
    <div class="card-header">
        <h3><label>{{ 'automatizacionesCreadas' | translate }}</label></h3>
        <div class="plegarpanel"></div>
    </div>
    <div class="card-body">

        <!-- Contenedor de todal las automatizaciones -->
        <div class="automatizaciones-contenedor" *ngFor="let automatizacion of automatizaciones">
            <!-- automatizacion -->
            <div class="automatizacion-contenedor">
                <!-- guardadas -->
                <div class="automatizacion-creada" *ngIf="!automatizacion.crear">
                    <div class="automatizacion-tipo-activo">
                        <!-- tipo -->
                        <label class="automatizacion-tipo" title="{{automatizacion.tipoPlantilla}}">{{automatizacion.tipoPlantilla}}</label>
                        <!-- activo -->
                        <kendo-switch [(ngModel)]="automatizacion.activo" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </div>
                    <!-- texto -->
                    <div class="automatizacion-texto">
                        <div *ngFor="let element of automatizacion?.elementos; let i = index" [ngClass]="element.class">
                            <label *ngIf="!element?.isOperario">{{element?.nombre}}</label>
                            <!-- contenedor para los operarios -->
                            <div class="automatizacion-operarios" *ngIf="element?.isOperario">
                                <div class="automatizacion-operario" *ngFor="let operario of element.valor">
                                    <label>{{operario.siglas}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- propietarios y eliminar -->
                    <div class="automatizacion-propietarios-eliminar">
                        <!-- propietarios -->
                        <label title="{{ 'propietarios' | translate }}">{{ 'propietarios' | translate }}</label>
                        <div class="automatizacion-propietarios">

                        </div>
                        <!-- eliminar -->
                        <i class="icon-papelera"></i>
                    </div>
                </div>
                <!-- crear -->
                <div class="automatizacion-crear" *ngIf="automatizacion.crear">
                    <label title="{{'anadirAutomatizacion' | translate}}">{{'anadirAutomatizacion' | translate}}</label>
                    <i class="icon-mas-alt"></i>
                </div>
            </div>
        </div>
    </div>
</div>