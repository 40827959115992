<style>
  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :host /deep/ kendo-grid.k-widget.k-grid {
    padding-bottom: 0px;
  }
</style>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td"
     (mouseover)="showGridTooltip($event)">
  <div class="row">

    <!-- PANEL HOME -->
    <hmi-panel class="hmi-panel col-4" [idMaquina]="idmaquina"></hmi-panel>

    <div [ngClass]="{'col-md-4': operarios.length<=1, 'col-md-6': operarios.length>1}">
      <div class="panel-procesos">

        <div ID="datosof" class="datos-of" style="height: 184px;">

          <!--PANEL DATOS-->
          <div class="pdatos-of-inner" *ngIf="operarios.length==1">
            <label ID="Ldatos" class="titulo-seccion-maquina">{{ 'datos' | translate | uppercase }}</label>

            <div id="divDatosof" class="datos-of-content">
              <ul>
                <li>
                  <label ID="LcodOf" class="label-datos-home">{{ 'of' | translate}}</label>
                  <label ID="ldatocodof" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].refOF}}">
                    {{operarios[0].refOF}}
                  </label>
                </li>
                <li>
                  <label ID="Lcliente" class="label-datos-home">{{ 'cliente' | translate}}</label>
                  <label ID="ldatocliente" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].cliente}}">
                    {{operarios[0].cliente}}
                  </label>
                </li>
                <li>
                  <label ID="Lpieza" class="label-datos-home">{{ 'pieza' | translate}}</label>
                  <label ID="lDatoPieza" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].pieza}}">
                    {{operarios[0].pieza}}
                  </label>
                </li>
                <li>
                  <label ID="Lparte" class="label-datos-home">{{ 'parte' | translate}}</label>
                  <label ID="ldatoparte" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].parte}}">
                    {{operarios[0].parte}}
                  </label>
                </li>
                <li>
                  <label ID="Loperacion" class="label-datos-home">{{ 'operacion' | translate}}</label>
                  <label ID="ldatoOperacion" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].operacion}}">
                    {{operarios[0].operacion}}
                  </label>
                </li>
                <li>
                  <label ID="LnumPieza" class="label-datos-home">{{ 'numpieza' | translate}}</label>
                  <label ID="lNumeroDePieza" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].nPieza}}">
                    {{operarios[0].nPieza}}
                  </label>
                </li>
                <li>
                  <label ID="Lduplicadas" class="label-datos-home">{{ 'duplicados' | translate}}</label>
                  <label ID="LNumeroDeDuplicadas" class="label-datos-valor-home" data-toggle="tooltip" data-placement="bottom" title="{{operarios[0].repetidas}}">
                    {{operarios[0].repetidas}}
                  </label>
                </li>
              </ul>
            </div>
          </div>

          <!--PANEL PRODUCCION-->
          <div class="pdatos-of-inner" *ngIf="operarios.length>1">

            <label ID="Ldatos" class="titulo-seccion-maquina">{{ 'produccion' | translate | uppercase }}</label>

            <kendo-grid [kendoGridBinding]="dataProduccion"
                        [resizable]="true"
                        [pageable]="false"
                        [navigable]="false"
                        [sortable]="false"
                        [reorderable]="false"
                        [height]="160">
              <kendo-grid-column field="refof" title="{{ 'of' | translate }}" width="15%"></kendo-grid-column>
              <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="20%"></kendo-grid-column>
              <kendo-grid-column field="parte" title="{{ 'parte' | translate }}" width="20%"></kendo-grid-column>
              <kendo-grid-column field="completados" title="{{ 'completados' | translate }}" width="10%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridHeaderTemplate>
                  <span class="k-icon k-i-check"></span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="noCompletados" title="{{ 'noCompletados' | translate }}" width="10%" [style]="{'text-align': 'center'}" [headerStyle]="{'text-align': 'center'}">
                <ng-template kendoGridHeaderTemplate>
                  <span class="k-icon k-i-close"></span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>

          </div>

        </div>

      </div>
    </div>

    <!--PANEL RESUMEN SEMANA-->
    <div class="col-md-4">
      <div class="panel-procesos panel-con-resumen-semana">
        <label class="titulopoten">{{ 'resumenSemana' | translate | uppercase }}</label>
        <a [routerLink]="['/informerendimiento/'+idmaquina]">
          <div id="resumenSemanaGrafico_offline" style="height: 153px;"></div>
        </a>
      </div>
    </div>

  </div>
  <br />

  <div>

    <kendo-tabstrip [keepTabContent]="true">

      <kendo-tabstrip-tab title="{{ 'operarios' | translate}}" [selected]="true" *ngIf="operarios.length>1">
        <ng-template kendoTabContent>

          <div style="min-height: 600px;">
            <ng-container *ngFor="let operario of operarios">

              <div class="row" style="background-color: #f5f5f5; margin-left: -10px; margin-right: -10px;">
                <div class="col">
                  <div ID="persona" class="usuario">
                    <img id="fotopersona" src="assets/user-cm.png" />
                    <label ID="nompersona">{{operario.operario}}</label>
                  </div>
                </div>
              </div>

              <div class="row" style="background-color: #f5f5f5; margin-left: -10px; margin-right: -10px;">

                <div class="col-md-3" style="padding: 0 0 0 3px;">
                  <div ID="ejecucion" class="panel-ejecucion">
                    <div class="contenedor-eje {{operario.ejecss}}">
                      <div class="datos-de-tiempo">
                        <div id="divEjec">
                          <div class="datos-de-tiempo-inner">
                            <div class="dt-izq">

                              <div class="estado-titulo">
                                <label ID="lcodeje">{{operario.procesos_Tipostr.toUpperCase()}}</label>
                              </div>

                              <div ID="enjecucion" class="porcent-cont-outer">
                                <div class="porcent-cont">
                                  <label ID="lPorcentajeEjecucion" class="donut-porcent">{{operario.porcentaje}}</label>
                                  <img ID="imgPorcentajeEjecucion" src="{{operario.estadoimg}}" />
                                </div>
                              </div>

                            </div>

                            <div class="dt-cent">

                              <div class="tiempoestimado-datos">
                                <label ID="Ltiempo" class="estado-tiempo-titulo">{{ 'tiempo' | translate}}</label>
                                <label ID="LtiempoVal" class="estado-tiempo">{{operario.tiempopasado}}</label>
                              </div>

                            </div>

                            <div class="cont-desvalter dt-der" id="tiempos" *ngIf="operarios[0].procesos_Tipo==1 || operarios[0].procesos_Tipo==3">

                              <div class="desvalter desvalter-1">
                                <label ID="LtiempoEstimado" class="estado-tiempo-titulo">{{ 'est.' | translate}}</label>
                                <label ID="LtiempoEstimadoVal" class="estado-tiempo" *ngIf="operarios[0].procesos_Tipo==1">{{operario.tiempoEst}}</label>
                                <label ID="LtiempoEstimadoVal" class="estado-tiempo" *ngIf="operarios[0].procesos_Tipo==3">{{operario.tiempoPrep}}</label>
                              </div>
                              <div class="desvalter desvalter-2">
                                <label ID="LtiempoPredictivo" class="estado-tiempo-titulo">{{ 'pred.' | translate}}</label>
                                <label ID="LtiempoPredictivoVal" class="estado-tiempo">{{operario.tiempoPred}}</label>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="row">

                    <div class="col-md-4">

                      <div class="row">
                        <div class="col">
                          <div class="bloques-info-offline doslineas">
                            <label class="bloques-info-label-offline">{{ 'of' | translate }}</label>
                            <label class="bloques-info-valor-offline" data-toggle="tooltip" data-placement="bottom" title="{{operario.refOF}}">{{operario.refOF}}</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="bloques-info-offline doslineas">
                            <label class="bloques-info-label-offline">{{ 'cliente' | translate }}</label>
                            <label class="bloques-info-valor-offline" data-toggle="tooltip" data-placement="bottom" title="{{operario.cliente}}">{{operario.cliente}}</label>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-4">

                      <div class="row">
                        <div class="col">
                          <div class="bloques-info-offline doslineas">
                            <label class="bloques-info-label-offline">{{ 'pieza' | translate }}</label>
                            <label class="bloques-info-valor-offline" data-toggle="tooltip" data-placement="bottom" title="{{operario.pieza}}">{{operario.pieza}}</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="bloques-info-offline doslineas">
                            <label class="bloques-info-label-offline">{{ 'operacion' | translate }}</label>
                            <label class="bloques-info-valor-offline" data-toggle="tooltip" data-placement="bottom" title="{{operario.operacion}}">{{operario.operacion}}</label>
                          </div>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-4">

                      <div class="row">
                        <div class="col">
                          <div class="bloques-info-offline doslineas">
                            <label class="bloques-info-label-offline">{{ 'parte' | translate }}</label>
                            <label class="bloques-info-valor-offline" data-toggle="tooltip" data-placement="bottom" title="{{operario.parte}}">{{operario.parte}}</label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <div class="bloques-info-offline doslineas">
                            <label class="bloques-info-label-offline">{{ 'numpieza' | translate }}</label>
                            <label class="bloques-info-valor-offline" data-toggle="tooltip" data-placement="bottom" title="{{operario.nPieza}}">{{operario.nPieza}}</label>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div class="col-md-4">
                  <div [attr.id]="'resumenSemanaGrafico_offline_' + operario.idRegistro" style="height: 89px;"></div>
                </div>

              </div>

              <br />

            </ng-container>
          </div>

        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'procesos' | translate}}" [selected]="operarios.length<=1">
        <ng-template kendoTabContent>

          <kendo-grid [kendoGridBinding]="datosProcesos"
                      [resizable]="true"
                      [pageable]="false"
                      [navigable]="true"
                      [sortable]="true"
                      [reorderable]="true"
                      [columnMenu]="{ filter: true }"
                      [height]="600">
            <kendo-grid-column field="procesos_Tipo" title="" width="4%" [filterable]="false">
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="cuadrado-color-grid" [ngClass]="dataItem.procesos_Tipo">{{ dataItem.procesos_Tipo | translate }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="fechaini" title="{{ 'inicio' | translate }}" width="10%">
              <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaini | kendoDate }} {{dataItem.fechaini | kendoDate:'HH:mm:ss' }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="fechafin" title="{{ 'fin' | translate }}" width="10%">
              <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechafin | kendoDate }} {{dataItem.fechafin | kendoDate:'HH:mm:ss' }}</ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="refof" title="{{ 'of' | translate }}" width="8%"></kendo-grid-column>
            <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="parte" title="{{ 'parte' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="operario" title="{{ 'operario' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="tiempoRealHH" title="{{ 'tReal' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="perdida" title="{{ 'perdida' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>

        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'mantenimientos' | translate}}">
        <ng-template kendoTabContent>
          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <div class="col-md-6">
              {{ 'porFechas' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorFecha" style="height: 400px" [hideHeader]="true" [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitch($event, dataItem, 1)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="41%">
                  </kendo-grid-column>
                  <kendo-grid-column width="10%" field="tipo" title="{{ 'tipo' | translate}}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span>{{ dataItem.tipo | translate }}</span>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button pdf-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorFecha" class="k-i-loading"></div>
              </div>
            </div>
            <div class="col-md-6">
              {{ 'porTiempos' | translate | uppercase}}
              <div class="">
                <kendo-grid [data]="mantenimientoPorTiempo" style="height: 400px" [hideHeader]="true" [rowClass]="rowCallback">
                  <kendo-grid-column field="switch" width="17%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <kendo-switch [(ngModel)]=dataItem.checked (click)="clickSwitchTiempo($event, dataItem, 2)" [onLabel]="' '" [offLabel]="' '"></kendo-switch>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column field="texto" width="51%">
                  </kendo-grid-column>
                  <kendo-grid-column field="fecha" width="20%">
                  </kendo-grid-column>
                  <kendo-grid-column field="variacionicono" title="" width="12%">
                    <ng-template kendoGridCellTemplate let-dataItem>
                      <button (click)="abrirPdf(dataItem)" class="k-button" *ngIf="dataItem.tienePdf==true">
                        <span class="k-icon k-i-file-pdf"></span>
                      </button>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
                  filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                  filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                  filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                  filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
                  filterContainsOperator="{{'filterContainsOperator' | translate}}"
                  filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
                  filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                  filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
                  filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
                  filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
                  filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                  filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                  filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
                  filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
                  filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                  filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                  filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                  filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
                  filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
                  groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
                  noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
                </kendo-grid>
                <div *ngIf="loadingPorTiempo" class="k-i-loading"></div>
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'notas' | translate}}">
        <ng-template kendoTabContent>

          <div class="row" style="margin-left: -10px; margin-right: -10px;">
            <kendo-grid [data]="notas" [height]="600">
              <kendo-grid-column field="creadoPor" title="{{ 'creadoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="cerradoPor" title="{{ 'cerradoPor' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="fecha" title="{{ 'fecha' | translate}}" width="10%">
              </kendo-grid-column>
              <kendo-grid-column field="texto" title="{{ 'texto' | translate}}" width="70%">
              </kendo-grid-column>
              <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
              filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
              filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
              filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
              filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
              filterContainsOperator="{{'filterContainsOperator' | translate}}"
              filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
              filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
              filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
              filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
              filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
              filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
              filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
              filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
              filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
              filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
              filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
              filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
              filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
              filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
              groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
              noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
            </kendo-grid>
          </div>

        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="{{ 'planificado' | translate}}">
        <ng-template kendoTabContent>

          <kendo-grid [kendoGridBinding]="datosPlanificados"
                      [resizable]="true"
                      [pageable]="false"
                      [navigable]="true"
                      [sortable]="true"
                      [reorderable]="true"
                      [columnMenu]="{ filter: true }"
                      [height]="600">
            <kendo-grid-column field="refOF" title="{{ 'of' | translate }}" width="5%"></kendo-grid-column>
            <kendo-grid-column field="cliente" title="{{ 'cliente' | translate }}" width="15%"></kendo-grid-column>
            <kendo-grid-column field="pieza" title="{{ 'pieza' | translate }}" width="15%"></kendo-grid-column>
            <kendo-grid-column field="operacion" title="{{ 'operacion' | translate }}" width="25%"></kendo-grid-column>
            <kendo-grid-column field="semana" title="{{ 'semana' | translate }}" width="10%"></kendo-grid-column>
            <kendo-grid-column field="orden" title="{{ 'orden' | translate }}" width="10%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="tiempoPreparacionHH" title="{{ 'tPreparacion' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="tiempoEstimadoHH" title="{{ 'tEstimado' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="cantidad" title="{{ 'cantidad' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-column field="tiempoTotal" title="{{ 'tTotal' | translate }}" width="7%" [style]="{'text-align': 'right'}"></kendo-grid-column>
            <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'filterContainsOperator' | translate}}"
            filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
            filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
            filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
            groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
            noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
          </kendo-grid>

        </ng-template>
      </kendo-tabstrip-tab>

    </kendo-tabstrip>

  </div>

</div>
