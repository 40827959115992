import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/OF`;
@Injectable()
export class OFService extends BehaviorSubject<any> {

  public loading = false;  

  constructor(private http: HttpClient) {
    super(null);
  }

  GetCombo() {
    return this.http.post<JSON>(`${baseUrl}/GetCombo`, {}, { withCredentials: true });
  }

  GetComboConCliente() {
    return this.http.post<JSON>(`${baseUrl}/GetComboConCliente`, {}, { withCredentials: true });
  }

  GetProyectosPiezasClientes() {
    return this.http.post<JSON>(`${baseUrl}/GetProyectosPiezasClientes`, {}, { withCredentials: true });
  }

  GetDatosGenerales_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetDatosGenerales_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetGruposMaquinas_SeguimientoProyecto() {
    return this.http.post<JSON>(`${baseUrl}/GetGruposMaquinas_SeguimientoProyecto`, {}, { withCredentials: true });
  }

  GetGruposMaquinasConMaquinas_SeguimientoProyecto() {
    return this.http.post<JSON>(`${baseUrl}/GetGruposMaquinasConMaquinas_SeguimientoProyecto`, {}, { withCredentials: true });
  }

  GetOperacionesHechasPorGrupo_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetOperacionesHechasPorGrupo_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetOperacionesPlanificadasPorGrupo_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetOperacionesPlanificadasPorGrupo_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetTiemposEstimadosPorMaquinas_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetTiemposEstimadosPorMaquinas_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetTiemposRealesPorMaquinas_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetTiemposRealesPorMaquinas_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetTareas_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetTareas_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetTareasFechaImportacion_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetTareasFechaImportacion_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  GetIntensidadPorSeccion_SeguimientoProyecto(idProyecto, idPiezas) {
    return this.http.post<JSON>(`${baseUrl}/GetIntensidadPorSeccion_SeguimientoProyecto`, { idProyecto, idPiezas }, { withCredentials: true });
  }

  public GetAll(action: string = '',param?:string, data?: ''): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl);
  }
  public Get_All_OperacionesOF(action: string = '', param?: string, data?: ''): Observable<any> {
    return this.http.get(baseUrl + "/ofsproyectos");
  }

  public Get_All_PiezasOF(action: string = '', param?: string, data?: ''): Observable<any> {
    return this.http.get(baseUrl + "/ofspiezas");
  }

  public GetByID(id:number): Observable<any> {
    this.loading = true;
    return this.http.get(baseUrl + "/" + id,);
  }

  public GetfPieszasOfs(vertodo: boolean, terminado: boolean): Observable<any> {
    return this.http.post<JSON>(`${baseUrl}/ofspiezasinicio`, {vertodo, terminado }, { withCredentials: true });
  }

  public delete(data?: any): Observable<any> {
    var formData: any = new FormData();
    formData.append("idof", data.idof);
    return this.http.post<JSON>(baseUrl + "/eliminar", formData, { withCredentials: true });     
  }

  public eliminarTodosOf(ids: any): Observable<any> {
    
    return this.http
      .post<JSON>(baseUrl + "/eliminarTodosOf" , {ids: ids},{ withCredentials: true });
     
  }

  duplicar(data) {
    return this.http.post<any>(baseUrl + "/duplicar", { id: data.id, }, { withCredentials: true });
  }

  importar(data) {
    return this.http.post<any>(baseUrl + "/importar", data , { withCredentials: true });
  }

  create(data) {
    data.idSeccion = data.idSeccion.id;
    var fIni:Date =data.fechainicio;
    var fFin: Date = data.fechafin;
    data.atributos = data.atributos.map(a=> (a.id));
    if(fIni !== null && fIni !== undefined && data.fechainicio != "")
      data['fechainicio'] = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
      data['fechainicio'] = null;
    if(fFin !== null && fFin !== undefined && data.fechafin != "")
      data['fechafin'] = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
      data['fechafin'] = null;
    return this.http.post<any>(baseUrl + "/crear", data);
  }

  update(data) {
    data.idSeccion = data.idSeccion.id;
    var fIni:Date =data.fechainicio;
    var fFin: Date = data.fechafin;
    data.atributos = data.atributos.map(a=> (a.id));
    if(fIni !== null && fIni !== undefined && data.fechainicio != "")
      data['fechainicio'] = this.dateToYYYYMMDDtHHmmSSz(fIni);
    else
      data['fechainicio'] = null;
    if(fFin !== null && fFin !== undefined && data.fechafin != "")
      data['fechafin'] = this.dateToYYYYMMDDtHHmmSSz(fFin);
    else
      data['fechafin'] = null;
    return this.http.post<any>(baseUrl + "/editar", data, { withCredentials: true });
  }

  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }

}
