import { Component } from '@angular/core';
import * as c3 from 'c3';
import { UsuariosService, MaquinasService, MenuService, InformeConsumoService, HistoricoOperacionesService, InformeProyectosService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { first, map } from 'rxjs/operators';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { forkJoin } from 'rxjs';

interface ItemTurno {
  nombre: string,
  id: number
}


@Component({
  selector: 'app-informe-electrico',
  templateUrl: './informeElectrico.component.html'
})

export class InformeElectricoComponent {

  user = this.userService.userValue;

  //VARIABLES GENERALES
  public fechaIni: Date;
  public fechaFinal: Date;
  public datosConsumoAnual: any;
  public listaMaquinas: any;
  public top10Ofs: any[];
  public listaTotal: any[] = [
    { indice: this.translateService.instant('enero'), id: 0, value: 0.00 },
    { indice: this.translateService.instant('febrero'), id: 1, value: 0.00 },
    { indice: this.translateService.instant('marzo'), id: 2, value: 0.00 },
    { indice: this.translateService.instant('abril'), id: 3, value: 0.00 },
    { indice: this.translateService.instant('mayo'), id: 4, value: 0.00 },
    { indice: this.translateService.instant('junio'), id: 5, value: 0.00 },
    { indice: this.translateService.instant('julio'), id: 6, value: 0.00 },
    { indice: this.translateService.instant('agosto'), id: 7, value: 0.00 },
    { indice: this.translateService.instant('septiembre'), id: 8, value: 0.00 },
    { indice: this.translateService.instant('octubre'), id: 9, value: 0.00 },
    { indice: this.translateService.instant('noviembre'), id: 10, value: 0.00 },
    { indice: this.translateService.instant('diciembre'), id: 11, value: 0.00 }
  ];

  public annoActual;
  public mesSeleccionado;
  public diaSeleccionado;
  public r1: boolean = false;

  //SUMA TOTAL
  public sumaTotal: number = 0.0;
  public sumaTotalStr: string;
  public sumaTotalListaMes = new Map();
  public sumaTotalAnualPorMaquina = new Map();
  public datosDia: any;

  //ESTRUCTURA DE DATOS PARA LOS GRAFICOS
  public jsonAnual: any[];

  //CAMBIO VENTANA
  public tipoVentana: number = 0; // 0: Anual - 1: Mensual - 2: Diario

  //GRAFICOS
  public graficoBarrasConsumoPorMaquinas;
  public graficoConsumoTotalAcumulado;
  public graficoDonutMaquina;

  //DATOS COLUMNAS GRAFICO
  public columnasMes = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
  public fechasFiltradas: any;
  public mesNombre: string;




  //FILTRO BERRIA

  //PANEL MAQUINAS
  private selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;
  public diaSemana: boolean = true;

  public filtratuta: boolean = true;
  public ezkutatu: boolean = false;
  public consultaFiltroCompleto: string = "";
  public lehenAldia: boolean = true;
  public lehenExekuzioa: boolean = true;
  public fini: any;
  public ffin: any;
  public idcliente: any;
  public idpieza: any;
  public idMaquina: any;
  public idof: any;
  public nSerie: any;
  public idOperacion: any;
  private dataFiltro: any;
  status: boolean = false; //para abrir o cerrar el filtro * se carga abierto siempre! si se autofiltra, se cierra solo.
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs START
  //  en las funciones de nuestro a kendo se usan estos IDs, tanto los de AND/OR como los de las opciones de cada tipo de dato.
  public andOr = [
    { id: 0, nombre: this.translateService.instant('y') },
    { id: 1, nombre: this.translateService.instant('o') }
  ];
  public opcionDate = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Menor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 2, nombre: 'Menor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 3, nombre: 'Mayor que', dobleValor: false }, //solo comentado en esta ventana
    // { id: 4, nombre: 'Mayor o igual', dobleValor: false }, //solo comentado en esta ventana
    // { id: 5, nombre: 'Es', dobleValor: false }, //solo comentado en esta ventana
    // { id: 6, nombre: 'No es', dobleValor: false }, //solo comentado en esta ventana
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionDateTime = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false }
  ];
  public opcionNumericDecimal = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('menorQue'), dobleValor: false },
    { id: 2, nombre: this.translateService.instant('menorOigual'), dobleValor: false },
    { id: 3, nombre: this.translateService.instant('mayorQue'), dobleValor: false },
    { id: 4, nombre: this.translateService.instant('mayorOigual'), dobleValor: false },
    { id: 5, nombre: this.translateService.instant('es'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noEs'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
    { id: 8, nombre: this.translateService.instant('noEstaEntre'), dobleValor: true }
  ];
  public opcionComboEstricto = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true }, // solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true }// solo hase van a mostrar combos multiseleccionables IN / OUT
  ];
  public opcionComboFlexible = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'Uniselect', dobleValor: false},
    { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    // { id: 3, nombre: 'No select', dobleValor: false},
    { id: 4, nombre: this.translateService.instant('noEs'), dobleValor: true },// solo hase van a mostrar combos multiseleccionables IN / OUT
    { id: 5, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 6, nombre: 'No empieza por', dobleValor: false},
    { id: 7, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 8, nombre: 'No acaba por', dobleValor: false},
    { id: 9, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 10, nombre: this.translateService.instant('noContiene'), dobleValor: false }//,
    // { id: 11, nombre: 'Igual que', dobleValor: false},
    // { id: 12, nombre: 'Diferente a', dobleValor: false}
  ];
  public opcionString = [
    // { id: 0, nombre: ' ', tipo: '' },
    { id: 1, nombre: this.translateService.instant('empiezaPor'), dobleValor: false },
    // { id: 2, nombre: 'No empieza por', dobleValor: false},
    { id: 3, nombre: this.translateService.instant('acabaPor'), dobleValor: false },
    // { id: 4, nombre: 'No acaba por', dobleValor: false},
    { id: 5, nombre: this.translateService.instant('contiene'), dobleValor: false },
    { id: 6, nombre: this.translateService.instant('noContiene'), dobleValor: false },
    { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
    { id: 8, nombre: this.translateService.instant('diferenteA'), dobleValor: false }
  ];
  // NO SE PUEDEN CAMBIAR LOS PROXIMOS IDs END
  //FECHA DOBLE
  public DaysInMonths;

  // VARIABLES input del filtro
  public permitirFiltroVacio = false; // permite vaciar el filtro por completo
  public vaciarUltimaLinea = true; // cuando no se deja vaciar por completo un filtro, este resetea la ultima linea al intentar eliminarla
  public permitirMultiplesLineasVacias = false; // perminte añadir mas lineas que las que se han rellenado
  public annadirAutomatico = true; // añade lineas automaticamente si se selecciona la columna o la operacion Esta opcion contradice la de perminir multiples lineas vacias
  public dobleClickMismaFecha = false; // permite seleccionar la misma fecha en inicio y fin en el control doble de fechas
  // DATOS INICIALES DEL FILTRO
  // DATOS INICIALES DEL FILTRO
  public filtroPorDefecto = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: [
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -1),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          },
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        ]
      }
    ]
  };
  public datosFiltro = {
    logic: { id: 1, nombre: this.translateService.instant('o') },
    group: []
  };
  // ESTOS SON LOS TIPOS QUE SE ACEPTAN EN EL FILTRO: date, dateTime, comboEstrincto, comboFlexible, check, numeric, decimal, string
  public columnasFiltro = [
    // { id: 0, nombre: ' ', tipo: '' },
    // { id: 1, nombre: 'date', field:"", tipo: 'date' },
    // { id: 2, nombre: 'dateTime', field:"", tipo: 'dateTime' },
    // { id: 4, nombre: 'comboEstrincto', field:"", tipo: 'comboEstrincto' },
    // { id: 10, nombre: 'comboFlexible', field:"", tipo: 'comboFlexible' },
    // { id: 16, nombre: 'check', field:"", tipo: 'check' },
    // { id: 17, nombre: 'numeric', field:"", tipo: 'numeric' },
    // { id: 18, nombre: 'decimal', field:"", tipo: 'decimal' },
    // { id: 19, nombre: 'string', field:"", tipo: 'string' }    
    // { id: 1, nombre: 'Fecha inicio', field: "fechaIni", sqlfield: "sql.fechaIni", tipo: 'date' },
    // { id: 2, nombre: 'Fecha fin', field: "fechaFin", sqlfield: "sql.fechaFin", tipo: 'date' },
    { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
    //{ id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    //{ id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
    //{ id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
    { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "ofs.nombreOf", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    //{ id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "pi.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    //{ id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string', maxItemsSelecteds: -1 },
    { id: 12, nombre: this.translateService.instant('parte'), field: "nombreParte", sqlfield: "po.nombreParte", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "op.nombreOperacion", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
    //{ id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check', maxItemsSelecteds: -1 }
  ];

  // Combo cargable del filtro
  public filtro_listaMaquinas: any;
  public filtro_listaOperarios: any;
  public filtro_listaTurnos: any;
  public filtro_listaHerramientas: any;
  public filtro_ocultarPartes: boolean;
  // variables para cuando la precarga requiere de alguna consulta
  public idpieza_prefiltro: number = 0;
  public idof_prefiltro: number = 0;
  public nSerie_prefiltro: string = "";
  public idOperacion_prefiltro: number = 0;
  public idmaquina_prefiltro: number = 0;
  // LOADING filtro
  public loadingFiltro: boolean = false;
  // public mostrarCalendario: boolean = false;
  public actualizarVisible: boolean = false;

  // azken makina gordetzeko, makina beti aukeratuta mantentzeko
  public lastMaquina: any;
  //END FILTRO BERRIA



  constructor(private informeConsumoService: InformeConsumoService
    , private route: ActivatedRoute
    , private userService: UsuariosService
    , private menuService: MenuService
    , public myFunctions: MyFunctions
    , public maquinasService: MaquinasService
    , public historicoOperacionesService: HistoricoOperacionesService
    , public informeProyectosService: InformeProyectosService
    , private translateService: TranslateService) {

  }

  ngOnInit(): void {

    this.tipoVentana = 0;
    this.fechaIni = new Date(new Date().getFullYear(), 0, 1);
    this.fechaFinal = new Date(new Date().getFullYear(), 11, 31);
    //var dataLag = new Date(new Date().getFullYear(), 0, 1);
    //this.fechaFin = new Date(dataLag.setFullYear(dataLag.getFullYear() + 1));
    this.annoActual = new Date().getFullYear();

    // console.log("Fecha inicio: ", this.fechaIni);
    // console.log("Fecha fin: ", this.fechaFin);

    // for (let index = 0; index < 12; index++) {
    //   this.sumaTotalListaMes.set(index, 0.00);
    // }

    this.menuService.titulo = this.translateService.instant('informeElectrico').toUpperCase();

    this.cargarGrupos();
    this.cargarAreasProductivas();

    //this.cargarDatosAnuales();

  }






  //FILTRO

  cargarFiltroPorDefecto() {

    this.fechaInicio = new Date(new Date().getFullYear(), 0, 1);
    //var dataLag = new Date(new Date().getFullYear(), 0, 1);
    this.fechaFin = new Date(new Date().getFullYear(), 11, 31);
    //this.fechaFin = new Date(dataLag.setFullYear(dataLag.getFullYear() + 1));

    this.filtroPorDefecto = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: [
            {
              columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
              operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
              fechaIni: this.fechaInicio,
              fechaFin: this.fechaFin,
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: [],
              bloqueado: false
            },
            {
              columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
              operator: { id: 0, nombre: '' },
              fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
              fechaFin: this.myFunctions.getDateNow(),
              mostrarCalendario: false,
              text: '',
              numberMin: 0,
              numberMax: 0,
              decimalformat: '0.000',
              decimalMin: 0.0,
              decimalMax: 0.0,
              check: false,
              combo: [{ id: 1, nombre: "" }],
              comboSelected: {},
              comboSelecteds: [],
              bloqueado: false
            }
          ]
        }
      ]
    };
  }


  annadirGrupoFiltro() {
    this.datosFiltro.group.push(
      {
        logic: { id: 0, nombre: this.translateService.instant('y') },
        group: [
          {
            columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        ]
      }
    )
  }
  annadirLineaFiltro(filtro) {
    // Ahora se selecciona la primera opcion, no hara falta mirar si hay algo seleccionado
    if (this.permitirMultiplesLineasVacias || filtro.group.filter(f => f.columna.id == 0 /*|| f.operator.id == 0*/).length == 0) {
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        bloqueado: false
      })
    }
  }
  borrarLineaFiltro(row, filtro) {
    if (filtro.group.length > 1) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && ((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length > 1) || this.permitirFiltroVacio)) {
      //SIEMPRE Y CUANDO NO SEA EL PRIMER GRUPO, SE PUEDE ELIMINAR
      let index = this.datosFiltro.group.findIndex(d => d === filtro);
      this.datosFiltro.group.splice(index, 1);
      this.actualizarVisible = true;
    } else if (filtro.group.length == 1 && (((filtro != this.datosFiltro.group[0] || this.datosFiltro.group.length == 1) || !this.permitirFiltroVacio)) && this.vaciarUltimaLinea) {
      //ELIMINAR LINEA DE FILTRO
      let index = filtro.group.findIndex(d => d === row);
      filtro.group.splice(index, 1);
      // Se borra y se añade una linea nueva vacia
      filtro.group.push({
        columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
        operator: { id: 0, nombre: '' },
        fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
        fechaFin: this.myFunctions.getDateNow(),
        mostrarCalendario: false,
        text: '',
        numberMin: 0,
        numberMax: 0,
        decimalformat: '0.000',
        decimalMin: 0.0,
        decimalMax: 0.0,
        check: false,
        combo: [{ id: 1, nombre: "" }],
        comboSelected: {},
        comboSelecteds: [],
        bloqueado: false
      })
      this.actualizarVisible = true;
    }
  }
  filtroTipoChanged(filtro, row, newSelection) {
    row.columna = newSelection;
    if (this.annadirAutomatico)
      this.annadirLineaFiltro(filtro);


    // DATE -
    if (row.columna.tipo == 'date') { row.operator = this.opcionDate[0]; }
    // DATETIME -
    else if (row.columna.tipo == 'dateTime') { row.operator = this.opcionDateTime[0] }
    // COMBO ESTRICTO -
    else if (row.columna.tipo == 'comboEstrincto') { row.operator = this.opcionComboEstricto[0] }
    // COMBO FLEXIBLE -
    else if (row.columna.tipo == 'comboFlexible') { row.operator = this.opcionComboFlexible[0] }
    // CHECK - NO HAY
    // NUMERIC -
    else if (row.columna.tipo == 'numeric') { row.operator = this.opcionNumericDecimal[0] }
    // DECIMAL -
    else if (row.columna.tipo == 'decimal') { row.operator = this.opcionNumericDecimal[0] }
    // STRING -
    else if (row.columna.tipo == 'string') { row.operator = this.opcionString[0] }

    this.preFiltrado(filtro, row);
  }
  // Filtro honetan makina bakarra aukeratu ahalko da, horretarako combobox-ean aukera daitezken elementu kopurua mugatuko da
  valueChangeComboEstatico(value: any) {
    this.datosFiltro.group[0].group.forEach(filtro => {
      var aux = [];

      filtro.comboSelecteds.forEach(selecteds => {
        aux.push(selecteds);
      });

      this.lastMaquina = aux;


      // if (filtro.columna.maxItemsSelecteds > 0) {
      //   if (filtro.comboSelecteds.length > filtro.columna.maxItemsSelecteds) {
      //     for (let i = 1; i < filtro.comboSelecteds.length; i++) {
      //       aux.push(filtro.comboSelecteds[i]);
      //     }
      //     filtro.comboSelecteds = [];
      //     aux.forEach(auxi => {
      //       filtro.comboSelecteds.push(auxi);
      //     });
      //     this.lastMaquina = aux;
      //   } else if (filtro.comboSelecteds.length == 0) {
      //     filtro.comboSelecteds = [];
      //     this.lastMaquina.forEach(auxi => {
      //       filtro.comboSelecteds.push(auxi);
      //     });
      //   }
      // }
    });
  }
  borrarFiltro() {
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
    this.ezkutatu = true;
  }
  cargarConTodasLasRespuestas() {
    // SI ES NECESARIO, SE CARGAN LAS VARIABLES DESDE LA URL.
    // console.log("URl 1");
    this.cargarFiltroURL();
    this.loadingFiltro = false;
  }

  // DE NUESTRO FILTRO A KENDO FILTER
  filtroToKendo() {
    // NUESTRO public datosFiltro = {                                                          => KENDO		public value: CompositeFilterDescriptor = {
    // NUESTRO   logic: { id: 0, nombre: 'and' },                                              => KENDO		    logic: "or",
    // NUESTRO   group: [                                                                      => KENDO		    filters: [
    // NUESTRO     {                                                                           => KENDO		      { field: "budget", operator: "gt", value: 60 },
    // NUESTRO       logic: { id: 0, nombre: 'and' },                                          => KENDO		      { field: "country", operator: "contains" },
    // NUESTRO       group: [                                                                  => KENDO		      { field: "discontinued", operator: "eq", value: true },
    // NUESTRO         {                                                                       => KENDO		      {
    // NUESTRO           columna: { id: 0, nombre: ' ', tipo: '' },                            => KENDO		        logic: "and",
    // NUESTRO           operator: { id: 0, nombre: '' },                                      => KENDO		        filters: [
    // NUESTRO           fechaIni: this.myFunctions.getDateNow(),                              => KENDO		          { field: "ordered on", operator: "lt", value: new Date(Date.now()) },
    // NUESTRO           fechaFin: this.myFunctions.getDateNow(),                              => KENDO		        ],
    // NUESTRO           mostrarCalendario: false,                                             => KENDO		      },
    // NUESTRO           text: '',                                                             => KENDO		    ],
    // NUESTRO           numberMin: 0,                                                         => KENDO		  };
    // NUESTRO           numberMax: 0,                                                         => KENDO
    // NUESTRO           decimalformat: '0.000',                                               => KENDO
    // NUESTRO           decimalMin: 0.0,                                                      => KENDO
    // NUESTRO           decimalMax: 0.0,                                                      => KENDO
    // NUESTRO           check: false,                                                         => KENDO
    // NUESTRO           combo: [                                                              => KENDO
    // NUESTRO             {id: 1, nombre: "opcion 1 "},                                       => KENDO
    // NUESTRO             {id: 2, nombre: "opcion 2"},                                        => KENDO
    // NUESTRO             {id: 3, nombre: "opcion 3"},                                        => KENDO
    // NUESTRO             {id: 4, nombre: "opcion 4"},                                        => KENDO
    // NUESTRO             {id: 5, nombre: "opcion 5"}                                         => KENDO
    // NUESTRO           ],                                                                    => KENDO
    // NUESTRO           comboSelected: {},                                                    => KENDO
    // NUESTRO           comboSelecteds: []                                                    => KENDO
    // NUESTRO         }                                                                       => KENDO
    // NUESTRO       ]                                                                         => KENDO
    // NUESTRO     }                                                                           => KENDO
    // NUESTRO   ]                                                                             => KENDO
    // NUESTRO };                                                                              => KENDO

    var em = this.filtroToKendo_recursivo(this.datosFiltro);
    return em;
  }
  filtroToKendo_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      //variables para crear la estructura final
      var filtro = [];
      var logica: string = "and";
      if (jFiltro.logic.id == 1)
        logica = "or"
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToKendo_recursivo(linea);
          if (newRow != undefined && newRow != "ERROR")
            filtro.push(newRow);
        });
      if(filtro.length > 0)    
        return { logic: logica, filters: filtro };
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var jRow = {};
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        // public opcionDate = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
          jSubRow2["value"] = this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1)));

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = this.myFunctions.datetimeToDate(this.myFunctions.dateTimeToDate(jFiltro.fechaIni));
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        // public opcionDateTime = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.fechaIni;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        // public opcionComboEstricto = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
          }
          if (jFiltro.comboSelected != undefined)
            jRow["value"] = jFiltro.comboSelected.id;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        // public opcionComboFlexible = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Uniselect', dobleValor: false },
        //   { id: 2, nombre: 'Multiselect', dobleValor: true },
        //   { id: 3, nombre: 'No select', dobleValor: false },
        //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
        //   { id: 5, nombre: 'Empieza por', dobleValor: false },
        //   { id: 6, nombre: 'No empieza por', dobleValor: false },
        //   { id: 7, nombre: 'Acaba por', dobleValor: false },
        //   { id: 8, nombre: 'No acaba por', dobleValor: false },
        //   { id: 9, nombre: 'Contiene', dobleValor: false },
        //   { id: 10, nombre: 'No contiene', dobleValor: false },
        //   { id: 11, nombre: 'Igual que', dobleValor: false },
        //   { id: 12, nombre: 'Diferente a', dobleValor: false }
        // ];
        if (jFiltro.operator.dobleValor) {
          if (jFiltro.operator.id == 2) {
            jRow["logic"] = 'or';
          }
          else if (jFiltro.operator.id == 4) {
            jRow["logic"] = 'and';
          }
          var subFiltro = [];
          jFiltro.comboSelecteds.forEach(
            seleccionado => {
              var jSubRow1 = {};
              jSubRow1["field"] = jFiltro.columna.field;
              if (jFiltro.operator.id == 2) {
                jSubRow1["operator"] = "eq";
              }
              else if (jFiltro.operator.id == 4) {
                jSubRow1["operator"] = "neq";
              }
              jSubRow1["value"] = seleccionado.id;
              subFiltro.push(jSubRow1);
            });
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "eq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "neq";
            if (jFiltro.comboSelected != undefined)
              jRow["value"] = jFiltro.comboSelected.id;
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "startswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith";
          //   jRow["value"] = jFiltro.text; 
          // }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "endswith";
            jRow["value"] = jFiltro.text;
          }
          // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          //   jRow["value"] = jFiltro.text;
          // }
          else if (jFiltro.operator.id == 9) {
            jRow["operator"] = "contains";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 10) {
            jRow["operator"] = "doesnotcontain";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 11) {
            jRow["operator"] = "eq";
            jRow["value"] = jFiltro.text;
          }
          else if (jFiltro.operator.id == 12) {
            jRow["operator"] = "neq";
            jRow["value"] = jFiltro.text;
          }
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        // no es necesaria una opcion
        // if (jFiltro.operator.dobleValor) {
        //   // no existe este caso por ahora
        // }
        // else{
        jRow["field"] = jFiltro.columna.field;
        jRow["operator"] = "eq";
        jRow["value"] = jFiltro.check;
        // }        
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.numberMin;
          jSubRow2["value"] = jFiltro.numberMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.numberMin;
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        // public opcionNumericDecimal = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: '<', dobleValor: false },
        //   { id: 2, nombre: '<=', dobleValor: false },
        //   { id: 3, nombre: '>', dobleValor: false },
        //   { id: 4, nombre: '>=', dobleValor: false },
        //   { id: 5, nombre: '=', dobleValor: false },
        //   { id: 6, nombre: '!=', dobleValor: false },
        //   { id: 7, nombre: 'Entre', dobleValor: true },
        //   { id: 8, nombre: 'No entre', dobleValor: true }
        // ];
        if (jFiltro.operator.dobleValor) {
          var jSubRow1 = {};
          var jSubRow2 = {};
          jSubRow1["field"] = jFiltro.columna.field;
          jSubRow2["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 7) {
            jRow["logic"] = 'and';
            jSubRow1["operator"] = "gte"
            jSubRow2["operator"] = "lte"
          }
          else if (jFiltro.operator.id == 8) {
            jRow["logic"] = 'or';
            jSubRow1["operator"] = "lt"
            jSubRow2["operator"] = "gt"
          }
          jSubRow1["value"] = jFiltro.decimalMin;
          jSubRow2["value"] = jFiltro.decimalMax;

          var subFiltro = [];
          subFiltro.push(jSubRow1);
          subFiltro.push(jSubRow2);
          jRow["filters"] = subFiltro;
        }
        else {
          jRow["field"] = jFiltro.columna.field;
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "lt";
          }
          else if (jFiltro.operator.id == 2) {
            jRow["operator"] = "lte";
          }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "gt";
          }
          else if (jFiltro.operator.id == 4) {
            jRow["operator"] = "gte";
          }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "neq";
          }
          jRow["value"] = jFiltro.decimalMin;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string') {
        // public opcionString = [
        //   // { id: 0, nombre: ' ', tipo: '' },
        //   { id: 1, nombre: 'Empieza por', dobleValor: false },
        //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 3, nombre: 'Acaba por', dobleValor: false },
        //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
        //   { id: 5, nombre: 'Contiene', dobleValor: false },
        //   { id: 6, nombre: 'No contiene', dobleValor: false },
        //   { id: 7, nombre: 'Igual que', dobleValor: false },
        //   { id: 8, nombre: 'Diferente a', dobleValor: false }
        // ];
        jRow["field"] = jFiltro.columna.field;
        if (jFiltro.operator.dobleValor) {
          // no existe este caso por ahora
        }
        else {
          if (jFiltro.operator.id == 1) {
            jRow["operator"] = "startswith";
          }
          // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "startswith"; 
          // }
          else if (jFiltro.operator.id == 3) {
            jRow["operator"] = "endswith";
          }
          // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   jRow["operator"] = NO "endswith"; 
          // }
          else if (jFiltro.operator.id == 5) {
            jRow["operator"] = "contains";
          }
          else if (jFiltro.operator.id == 6) {
            jRow["operator"] = "doesnotcontain";
          }
          else if (jFiltro.operator.id == 7) {
            jRow["operator"] = "eq";
          }
          else if (jFiltro.operator.id == 8) {
            jRow["operator"] = "neq";
          }
        }
        jRow["value"] = jFiltro.text;
      }

      // LINEA
      if (jRow["value"] != undefined && jRow["operator"] != undefined && jRow["field"] != undefined) {
        return jRow;
      }
      // GRUPO
      if (jRow["filters"] != undefined && jRow["logic"] != undefined)
        if (jRow["filters"].length > 0) {
          return jRow;
        }
      // else // filtro sin terminar! no es un filtro a tener en cuenta
      //   return {}
    }
    else {
      //NO EXISTE UNA ESTRUCTURA DEFINIDA PARA ESE FILTRO
      return "ERROR";
    }
  }

  // DE NUESTRO FILTRO A SQL FILTER
  filtroToSQL(dataSQLfields = []) {
    var em = this.filtroToSQL_recursivo(this.datosFiltro, dataSQLfields);
    if (em.length > 0)
      em = " WHERE " + em;
    return em;
  }
  filtroToSQL_recursivo(jFiltro, dataSQLfields) {
    // INPUT
    //   ['hb.fechaTurno', 'columnaSQL']
    // es una linea o es un grupo?
    var sqlFilter = "";
    if (jFiltro.group != undefined) {
      var logica: string = " AND ";
      if (jFiltro.logic.id == 1)
        logica = " OR ";
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToSQL_recursivo(linea, dataSQLfields);
          if (newRow != undefined && newRow != "ERROR" && newRow != "")
            if (sqlFilter != "") {
              sqlFilter = sqlFilter + " " + logica + " (" + newRow + ")";
            }
            else {
              sqlFilter = "(" + newRow + ")";
            }
        });
    }
    else if (jFiltro.columna != undefined) {
      if (dataSQLfields.includes(jFiltro.columna.sqlfield) || dataSQLfields.length == 0) {
        // DATE -
        if (jFiltro.columna.tipo == 'date') {
          // public opcionDate = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            this.fechaInicio = this.myFunctions.dateTimeToDate(jFiltro.fechaIni);
            this.fechaFin = this.myFunctions.dateAddDays(jFiltro.fechaFin, 0);
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' AND " + jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "' OR " + jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(this.myFunctions.dateAddDays(jFiltro.fechaFin, 1))) + "'"
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + this.myFunctions.datetimeToSQL(this.myFunctions.dateTimeToDate(jFiltro.fechaIni)) + "'"
            }
          }
        }
        // DATETIME -
        else if (jFiltro.columna.tipo == 'dateTime') {
          // public opcionDateTime = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> " + this.myFunctions.datetimeToSQL(jFiltro.fechaIni);
            }
          }
        }
        // COMBO ESTRICTO -
        else if (jFiltro.columna.tipo == 'comboEstrincto') {
          // public opcionComboEstricto = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + ' IN (' + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + ' NOT IN (' + valores + ")";
              }
            }
          }
          // else {
          //   // NO EXISTE PORQUE NO TIENE SENTIDO 
          //   // if (jFiltro.operator.id == 1) {
          //   // }
          //   // else if (jFiltro.operator.id == 3) {
          //   // }
          // }
        }
        // COMBO FLEXIBLE -
        else if (jFiltro.columna.tipo == 'comboFlexible') {
          // public opcionComboFlexible = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Uniselect', dobleValor: false },
          //   { id: 2, nombre: 'Multiselect', dobleValor: true },
          //   { id: 3, nombre: 'No select', dobleValor: false },
          //   { id: 4, nombre: 'No Multiselect', dobleValor: true },
          //   { id: 5, nombre: 'Empieza por', dobleValor: false },
          //   { id: 6, nombre: 'No empieza por', dobleValor: false },
          //   { id: 7, nombre: 'Acaba por', dobleValor: false },
          //   { id: 8, nombre: 'No acaba por', dobleValor: false },
          //   { id: 9, nombre: 'Contiene', dobleValor: false },
          //   { id: 10, nombre: 'No contiene', dobleValor: false },
          //   { id: 11, nombre: 'Igual que', dobleValor: false },
          //   { id: 12, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            var valores = "";
            jFiltro.comboSelecteds.forEach(
              seleccionado => {
                if (valores == "")
                  valores = "'" + seleccionado.id + "'";
                else
                  valores += ", '" + seleccionado.id + "'";
              });
            if (valores != "") {
              if (jFiltro.operator.id == 2) {
                sqlFilter = jFiltro.columna.sqlfield + " IN (" + valores + ")";
              }
              else if (jFiltro.operator.id == 4) {
                sqlFilter = jFiltro.columna.sqlfield + " NOT IN (" + valores + ")";
              }
            }
          }
          else {
            // 1 y 3 NO TIENEN SENTIDO
            // if (jFiltro.operator.id == 1) {
            // }
            // else if (jFiltro.operator.id == 3) {
            // }
            if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 6){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 8){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 9) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 10) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 11) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 12) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // CHECK -
        else if (jFiltro.columna.tipo == 'check') {
          // no es necesaria una opcion
          // if (jFiltro.operator.dobleValor) {
          //   // no existe este caso por ahora
          // }
          // else{
          if (jFiltro.check) {
            sqlFilter = jFiltro.columna.sqlfield + " = 'true'";
          }
          else {
            sqlFilter = jFiltro.columna.sqlfield + " = 'false'";
          }
          // }        
        }
        // NUMERIC -
        else if (jFiltro.columna.tipo == 'numeric') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.numberMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.numberMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.numberMin + "'";
            }
          }
        }
        // DECIMAL -
        else if (jFiltro.columna.tipo == 'decimal') {
          // public opcionNumericDecimal = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: '<', dobleValor: false },
          //   { id: 2, nombre: '<=', dobleValor: false },
          //   { id: 3, nombre: '>', dobleValor: false },
          //   { id: 4, nombre: '>=', dobleValor: false },
          //   { id: 5, nombre: '=', dobleValor: false },
          //   { id: 6, nombre: '!=', dobleValor: false },
          //   { id: 7, nombre: 'Entre', dobleValor: true },
          //   { id: 8, nombre: 'No entre', dobleValor: true }
          // ];
          if (jFiltro.operator.dobleValor) {
            if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "' AND " + jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMax + "'";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "' OR '" + jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMax + "'";
            }
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " < '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 2) {
              sqlFilter = jFiltro.columna.sqlfield + " <= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " > '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 4) {
              sqlFilter = jFiltro.columna.sqlfield + " >= '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " = '" + jFiltro.decimalMin + "'";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " <> '" + jFiltro.decimalMin + "'";
            }
          }
        }
        // STRING -
        else if (jFiltro.columna.tipo == 'string') {
          // public opcionString = [
          //   // { id: 0, nombre: ' ', tipo: '' },
          //   { id: 1, nombre: 'Empieza por', dobleValor: false },
          //   { id: 2, nombre: 'No empieza por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 3, nombre: 'Acaba por', dobleValor: false },
          //   { id: 4, nombre: 'No acaba por', dobleValor: false }, // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
          //   { id: 5, nombre: 'Contiene', dobleValor: false },
          //   { id: 6, nombre: 'No contiene', dobleValor: false },
          //   { id: 7, nombre: 'Igual que', dobleValor: false },
          //   { id: 8, nombre: 'Diferente a', dobleValor: false }
          // ];
          if (jFiltro.operator.dobleValor) {
            // no existe este caso por ahora
          }
          else {
            if (jFiltro.operator.id == 1) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "%')";
            }
            // else if (jFiltro.operator.id == 2){// AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 3) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "')";
            }
            // else if (jFiltro.operator.id == 4){ // AAAAAA ANO EXISTEEEEE EN TELERIK!!!!
            // }
            else if (jFiltro.operator.id == 5) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 6) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('%" + jFiltro.text + "%')";
            }
            else if (jFiltro.operator.id == 7) {
              sqlFilter = jFiltro.columna.sqlfield + " LIKE ('" + jFiltro.text + "')";
            }
            else if (jFiltro.operator.id == 8) {
              sqlFilter = jFiltro.columna.sqlfield + " NOT LIKE ('" + jFiltro.text + "')";
            }
          }
        }
        // DROP - Tipo nuevo para maquinas
        // else if (jFiltro.columna.tipo == 'drop') {
        //   var datoDrop: any = jFiltro.comboSelected;
        //   if(datoDrop[0].id != undefined){
        //     sqlFilter = jFiltro.columna.sqlfield + ' IN (' + datoDrop.id + ")";
        //   }
        // }
      }
    }
    return sqlFilter;
  }

  // DE NUESTRO FILTRO A CANTIDAD FILTRADOS
  filtroToCount(jFiltro = this.datosFiltro) {
    var em = this.filtroToCount_recursivo(jFiltro);
    return em;
  }
  filtroToCount_recursivo(jFiltro) {
    // es una linea o es un grupo?
    if (jFiltro.group != undefined) {
      // GRUPO
      var count = 0;
      //por cada grupo
      jFiltro.group.forEach(
        linea => {
          var newRow = this.filtroToCount_recursivo(linea);
          if (newRow != undefined && newRow > 0)
            count += newRow;
        });
      return count;
    }
    else if (jFiltro.columna != undefined) {
      // LINEA
      var count = 0;
      // DATE -
      if (jFiltro.columna.tipo == 'date') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.fechaIni != undefined && jFiltro.fechaFin != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
            count = 1;
          }
        }
      }
      // DATETIME -
      else if (jFiltro.columna.tipo == 'dateTime') {
        if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.fechaIni != undefined) {
          count = 1;
        }
      }
      // COMBO ESTRICTO -
      else if (jFiltro.columna.tipo == 'comboEstrincto') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
      }
      // COMBO FLEXIBLE -
      else if (jFiltro.columna.tipo == 'comboFlexible') {
        if ((jFiltro.operator.id == 2 || jFiltro.operator.id == 4) && jFiltro.comboSelecteds.length > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id == 1 || jFiltro.operator.id == 3) && jFiltro.comboSelected.id > 0) {
          count = 1;
        }
        else if ((jFiltro.operator.id >= 5 && jFiltro.operator.id < 13) && jFiltro.text > "") {
          count = 1;
        }
      }
      // CHECK -
      else if (jFiltro.columna.tipo == 'check') {
        count = 1;
      }
      // NUMERIC -
      else if (jFiltro.columna.tipo == 'numeric') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.numberMin != undefined && jFiltro.numberMax != undefined) {
          count = 1;
        }
        else {
          if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.numberMin == false) {
            count = 1;
          }
        }
      }
      // DECIMAL -
      else if (jFiltro.columna.tipo == 'decimal') {
        if (jFiltro.operator.id > 6 && jFiltro.operator.id < 9 && jFiltro.decimalMin != undefined && jFiltro.decimalMax != undefined) {
          count = 1;
        }
        else if (jFiltro.operator.id > 0 && jFiltro.operator.id < 7 && jFiltro.decimalMin == false) {
          count = 1;
        }
      }
      // STRING -
      else if (jFiltro.columna.tipo == 'string' && jFiltro.text != "") {
        count = 1;
      }
      return count;
    }
    else {
      return 0;
    }
  }

  // FECHA DOBLE
  //    Función para agregar los días seleccionados al periodo correspondiente (el periodo seleccionado)
  valueClickCalendar(row, month, event) {
    if (event.target.classList.contains("calendarFiltro-calendario-contenido-dia")) { //nos aseguramos de que se está clickando en un día y no en otra parte
      if ((event.target.classList.contains("calendarFiltro-calendarioSeleccionado") && row.fechaIni != undefined && row.fechaFin == undefined)) {//se ha vuelto a seleccionar en el periodo, deseleccionar
        row.fechaFin = this.DaysInMonths[month];
        row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
      } else {
        if (row.fechaIni == undefined && row.fechaFin == undefined) {
          row.fechaIni = this.DaysInMonths[month];
        }
        else if (row.fechaIni != undefined && row.fechaFin == undefined) {
          row.fechaFin = this.DaysInMonths[month];
          if (row.fechaIni > row.fechaFin) { //mirar qué fecha debe ir primero, just in case
            //están al revés, corregirlas
            var aux = new Date(row.fechaIni.getTime());
            row.fechaIni = new Date(row.fechaFin.getTime());
            row.fechaFin = aux;
          }
          row.mostrarCalendario = false; //Si ya tenemos las dos fechas, que lo cierre
          //Y que lo añada com que ya se ha cambiado la fecha, como es obligatoria, nunca saldrá de ese div, solo se vuelve a incluir para destacar el cambio con la animación
          // this.CambioFiltro();
        }
        else {
          //en este caso había dos y se brran para empezar a seleccionar otra vez
          //por tanto, quitamos el tag hasta que se hayan seleccionado las dos
          row.fechaIni = this.DaysInMonths[month];
          row.fechaFin = undefined;
        }
      }
    }
  }
  //    Función para avanzar o ir atrás en los meses del calendario
  cambiarMeses(value) {
    if (value == -1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[0] as any).click();
    } else if (value == 1) {
      (document.getElementById("calendario-0").getElementsByTagName("kendo-calendar-header")[0].children[2].children[2] as any).click();
    }
  }
  //    Función para que el calendario se muestre o no
  showCalendar(row) {
    if (!row.mostrarCalendario) {
      row.mostrarCalendario = true;
    }
    else {
      row.mostrarCalendario = false;
      if (row.fechaIni != undefined && row.fechaFin == undefined) {
        row.fechaFin = row.fechaIni;
      }
      else if (row.fechaIni == undefined && row.fechaFin == undefined) {
        row.fechaIni = this.myFunctions.getDateNow();
        row.fechaFin = row.fechaIni;
      }

    }
    // this.mostrarCalendario = true; // activa el click de fondo!
  }
  //    Función para inicializar los meses del calendario
  cargarMeses() {
    this.DaysInMonths = [];
    //Necesitamos inicializar los meses para que se pongan en su día correcto, los pondremos en el día actual y el primer día del mes siguiente
    this.DaysInMonths.push(this.myFunctions.getDateNow());
  }
  //    Función para pintar del color adecuado el periodo seleccionado
  isDateSelected(row, date) {
    if (row.fechaIni == undefined && row.fechaFin == undefined) {
      return false;
    } else if (row.fechaIni != undefined && row.fechaFin == undefined) {
      return date.getFullYear() == row.fechaIni.getFullYear() && date.getMonth() == row.fechaIni.getMonth() && date.getDate() == row.fechaIni.getDate();
    } else if (row.fechaIni != undefined && row.fechaFin != undefined) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate()) >= new Date(row.fechaIni.getFullYear(), row.fechaIni.getMonth(), row.fechaIni.getDate()) &&
        new Date(date.getFullYear(), date.getMonth(), date.getDate()) <= new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate());
    }
  };
  //    Botones filtro fechas, (los botones que hay a la derecha del calendario)
  ultimas24HButton(row) {
    var today = this.myFunctions.getDateNow();
    row.fechaIni = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.fechaFin = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false; // quita el click de fondo!
  }
  ultimos7DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth(), row.fechaFin.getDate() - 6);
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos30DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 1, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos60DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 2, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }
  ultimos90DiasButton(row) {
    row.fechaFin = this.myFunctions.getDateNow();
    row.fechaIni = new Date(row.fechaFin.getFullYear(), row.fechaFin.getMonth() - 3, row.fechaFin.getDate());
    row.mostrarCalendario = false; //Si cierra porque ya se ha seleccionado la fecha
    // this.mostrarCalendario = false;// quita el click de fondo!
  }

  // ESTA FUNCION CAMBIA SEGUN EL FILTRO!
  cargar_Filtro() {
    // Dentro de esta funcion se meteran todas las cargas de combos, fechas... que necesite el filtro para funcionar.
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    var r1, r2, r3, r4: boolean = false;
    //FECHAS
    this.cargarMeses();

    //TURNOS
    this.filtro_listaTurnos = [
      { nombre: this.translateService.instant("manana"), id: 1 },
      { nombre: this.translateService.instant("tarde"), id: 2 },
      { nombre: this.translateService.instant("noche"), id: 3 }
    ];

    r3 = true;
    //OPERARIOS
    // this.informeProyectosService.Get_Operarios_simple().pipe(first()).subscribe((data: any) => {
    //   this.filtro_listaOperarios = data;
    //   this.filtro_listaOperarios.sort((a, b) => (a.nombreOperario > b.nombreOperario) ? 1 : ((b.nombreOperario > a.nombreOperario) ? -1 : 0));

    //   r3 = true;
    //   if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
    // });

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    else {
      this.secciones.forEach(
        seccion => {
          idsSeccionesSelecteds.push(seccion.id);
        });
    }
    //FLTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados) {
      this.gruposSeleccionados.forEach(
        grupo => {
          idsGruposSelecteds.push(grupo.id.toString()); // se pasa a string para hacer la comparacion
        });
    }
    //MAQUINAS    
    this.filtro_listaMaquinas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));
    this.filtro_listaMaquinas = this.maquinas.filter(f => {
      var enGrupo = false
      f.idsGrupos.split(',').forEach(
        idGrupo => {
          enGrupo = enGrupo || idsGruposSelecteds.includes(idGrupo);
        });
      return ((idsSeccionesSelecteds.includes(f.idSeccion) || this.secciones.length == 0) && (enGrupo || idsGruposSelecteds.length == 0));
    });

    //Esperamos hasta que se cargen las maquinas para coger la primera y ponerla como por defecto
    this.cargarFiltroPorDefecto();
    this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);

    this.consultaFiltroCompleto = this.filtroToSQL();

    // this.identificarMaquinaYCargarDatos();  ALDATU KARGA

    //HERRAMIENTAS
    this.informeProyectosService.Get_Herramientas().pipe(first()).subscribe(
      (data: any) => {
        this.filtro_listaHerramientas = data;
        this.filtro_listaHerramientas.sort((a, b) => (a.nombreHerramienta > b.nombreHerramienta) ? 1 : ((b.nombreHerramienta > a.nombreHerramienta) ? -1 : 0));
        r2 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();
      });

    //CLIENTES, PIEZAS, OFS, OPERACIONES Y PARTEShb.idMaquina
    // this.informeProyectosService.Get_ClientesPiezasOfsOperacionesPartes_simple().pipe(first()).subscribe(
    //   (data: any) => {
    //     this.dataFiltro = data;

    //     r4 = true;
    //     if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

    //   });


    this.informeConsumoService.getOFPiezaOperacionConsumo().pipe(first()).subscribe(
      (data: any) => {
        this.dataFiltro = data;

        r4 = true;
        if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

      });


    //SI ocultarParte=1 NO ENSEÑAR EL MULTISELECT DE PARTES

    if (!this.user.ocultarParte) this.filtro_ocultarPartes = false;
    if (this.user.ocultarParte) this.filtro_ocultarPartes = true;

    // se quita parte de las opciones seleccionables directamente
    if (this.filtro_ocultarPartes)
      this.columnasFiltro = [
        { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
        //{ id: 3, nombre: this.translateService.instant('turno'), field: "idTurno", sqlfield: "hb.tipoTurno", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        //{ id: 4, nombre: this.translateService.instant('seccion'), field: "idSeccion", sqlfield: "maq.idSeccion", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        { id: 5, nombre: this.translateService.instant('grupoMaquinas'), field: "idGrupo", sqlfield: "mgm.idMaquinasGrupo", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        { id: 6, nombre: this.translateService.instant('maquina'), field: "idMaquina", sqlfield: "hcr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
        //{ id: 7, nombre: this.translateService.instant('operario'), field: "idOperario", sqlfield: "hb.idOperario", tipo: 'comboEstrincto', maxItemsSelecteds: -1 },
        { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "ofs.nombreOf", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        //{ id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "pi.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        //{ id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string', maxItemsSelecteds: -1 },
        { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "op.nombreOperacion", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
        //{ id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check', maxItemsSelecteds: -1 }
      ];

    r1 = true;
    if (r1 && r2 && r3 && r4) this.cargarConTodasLasRespuestas();

  }
  preFiltrado(filtro, row) {
    // console.log("URl 4");

    // si es un COMBO lo cargamos
    if (row.columna.tipo == "comboEstrincto" || row.columna.tipo == "comboFlexible" || row.columna.tipo == "drop") {
      // cargamos los datos filtrados que NO sean de esta linea.   
      var dataFiltroLag;
      dataFiltroLag = this.dataFiltro;

      // borrar la seleccion actual
      row.comboSelected = {};
      row.comboSelecteds = [];

      // cargar los combos
      if (row.columna.id == 3) { // turnos
        row.combo = this.filtro_listaTurnos;
      }
      else if (row.columna.id == 4) { // seccion
        row.combo = this.groupedSeccion; // OK
      }
      else if (row.columna.id == 5) { // grupo de maquinas
        row.combo = this.grupos.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 6) { // maquinas FILTRO!
        row.combo = this.filtro_listaMaquinas.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0)); // OK
      }
      else if (row.columna.id == 7) { // operarios 
        row.combo = this.filtro_listaOperarios.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }

      // cargar desde dataFiltroLag = filtrado(this.dataFiltro)
      else if (row.columna.id == 8) { // OF FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          of => {
            if (!lag.includes(of.nombreOf)) {
              lag.push(of.nombreOf);
              var js = { id: of.nombreOf, nombre: of.nombreOf };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
      else if (row.columna.id == 9) { // clientes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          cliente => {
            if (!lag.includes(cliente.nombreCliente)) {
              lag.push(cliente.nombreCliente);
              var js = { id: cliente.nombreCliente, nombre: cliente.nombreCliente };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 10) { // piezas FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          pieza => {
            if (!lag.includes(pieza.nombrePieza)) {
              lag.push(pieza.nombrePieza);
              var js = { id: pieza.nombrePieza, nombre: pieza.nombrePieza };
              combo.push(js);
            }

          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 11) { // N series FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          nSerie => {
            if (!lag.includes(nSerie.nSerie)) {
              lag.push(nSerie.nSerie);
              var js = { id: nSerie.nSerie, nombre: nSerie.nSerie };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 12) { // partes FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          parte => {
            if (!lag.includes(parte.nombreParte)) {
              lag.push(parte.nombreParte);
              var js = { id: parte.nombreParte, nombre: parte.nombreParte };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));;
      }
      else if (row.columna.id == 13) { // operaciones FILTRO!
        var combo = [];
        var lag = [];
        dataFiltroLag.forEach(
          operacion => {
            if (!lag.includes(operacion.nombreOperacion)) {
              lag.push(operacion.nombreOperacion);
              var js = { id: operacion.nombreOperacion, nombre: operacion.nombreOperacion };
              combo.push(js);
            }
          });
        row.combo = combo.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
      }
    }
  }
  refiltrarFiltro(filtro) {
    // ESTA FUNCION SE EJECUTA CUANDO SE CAMBIA LA COLUMNA DE TIPO DE FILTRADO!
    // DE:
    //  - AND --> OR
    //  - OR  --> AND
  }
  onFilter() {
    // si se miran las proximas funciones se vera como se aplica el filtro. 
    // aqui hay un ejemplo de todos modos:    
    //         var filtroFechas: any = this.filtroToSQL(['hb.fechaTurno']); // filtro solo Fechas
    //         filtroFechas = filtroFechas.replace(/hb.fechaTurno(.*?)hb.fechaTurno/g, 'fechaMod$1fechaCreado');
    //         var filtroCompleto: any = this.filtroToSQL(); // filtro completo

    // Hau ez da behar
    //this.cargarDatosInforme();
    // //this.cargarGraficosPerdidas();
    // console.log("onfilter")
    // Hau bai
    this.tipoVentana = 0;
    if (this.route.snapshot.params['idMaquina'] != undefined && this.lehenExekuzioa) {
      // console.log("Filtro URL", this.route.snapshot.params['idMaquina']);
      this.lehenExekuzioa = false;
    } else if (this.lehenExekuzioa) {
      // console.log("Bestela");
      this.cargarFiltroPorDefecto();
      this.datosFiltro = this.myFunctions.copy(this.filtroPorDefecto);
      this.lehenExekuzioa = false;
    }
    this.ezkutatu = false;
    this.filtratuta = true;
    this.consultaFiltroCompleto = "";
    this.consultaFiltroCompleto = this.filtroToSQL();

    var datos = this.datosFiltro;


    this.cargarDatosAnuales();
    // this.cargarFechas();
    // this.pintarGraficos();
    // this.gantVisible = true;
    // this.identificarMaquinaYCargarDatos();  ALDATU KARGA

    //this.status = true;
    this.actualizarVisible = false;
  }
  cargarFiltroURL() {
    // cargaremos el filtro en una variable para despues actualizar el filtro
    // console.log("URl 2");

    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // Las lineas con '/**/' son lineas de antes!, para ver como se cargarian ahora en el nuevo filtro
    // SE PUEDE FILTRAR DESDE HISTORICO PIEZAS o INFORME PROYECTOS
    if (Number.parseInt(this.route.snapshot.params['idHistoricoPieza']) > 0) {
      // Historico Piezas:
      //   - fechaIni , fechaFin *fijo*
      //   - idHistoricoPiezas *fijo*
      //   - idHistoricoOperaciones (posible extra)

      // EN ESTE CASO, EL FILTRO REQUIERE UNA CONSULTA. Por eso se cargan las fechas y el filtro en otra funcion aparte despues de recibir las 2 respuestas
      var r1, r2 = false;
      // HISTORICO PIEZAS??
      /**/var IdHistorico = Number.parseInt(this.route.snapshot.params['idHistoricoPieza']);
      this.historicoOperacionesService.Get_nSerie(IdHistorico).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idpieza_prefiltro = an[0].idPieza;
            this.idof_prefiltro = an[0].idOF;
            this.nSerie_prefiltro = an[0].nSerie;
          }
          r1 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
      // HISTORICO OPERACIONES??
      /**/var IdHistoricoOp = Number.parseInt(this.route.snapshot.params['idHistoricoOperacion']);
      this.historicoOperacionesService.Get_operacin_HO(IdHistoricoOp).subscribe(
        (json) => {
          var an: any = json
          if (an.length > 0) {
            this.idOperacion_prefiltro = an[0].idOFs_Operacion;
            this.idmaquina_prefiltro = an[0].idMaquina;
          }
          r2 = true;
          if (r1 && r2)
            this.cargarFiltroURL_postConsulta();
        });
    }
    else {
      // Informe Proyectos:
      //   - fechaIni , fechaFin *fijo*
      //   - idcliente (posible extra)
      //   - idpieza (posible extra)
      //   - idof (posible extra)
      //   - terminados (posible extra)
      // FECHAS (INICIO, FIN)
      /**/this.fini = this.route.snapshot.params['fini'];
      /**/this.ffin = this.route.snapshot.params['ffin'];
      if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
        && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
        // console.log("URl 2.5 / fecha 1");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
            fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
      } else {
        // console.log("URl 2.5 / fecha 2");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 1, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
            operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -1),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
      }
      // OF
      /**/this.idof = Number.parseInt(this.route.snapshot.params['idof']);
      var rowOFs = this.dataFiltro.filter(x => x.idOf == this.route.snapshot.params['idof']);
      if (rowOFs.length > 0) {
        // console.log("URl 2.5 / of");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "ofs.nombreOf", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
      }
      // CLIENTE
      /**/this.idcliente = Number.parseInt(this.route.snapshot.params['idcliente']);
      // Aprobechamos que los combos ya estan cargados para conseguir el nombre del cliente.
      var rowClientes = this.dataFiltro.filter(x => x.idCliente == this.route.snapshot.params['idcliente']);
      if (rowClientes.length > 0) {
        // console.log("URl 2.5 / cliente");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 9, nombre: this.translateService.instant('cliente'), field: "nombreCliente", sqlfield: "po.nombreCliente", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var cliente = { id: rowClientes[0].nombreCliente, nombre: rowClientes[0].nombreCliente }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [cliente];
      }
      // PIEZA
      /**/this.idpieza = Number.parseInt(this.route.snapshot.params['idpieza']);
      var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.route.snapshot.params['idpieza']);
      if (rowPiezas.length > 0) {
        // console.log("URl 2.5 / pieza");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "pi.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
      }
      // MAQUINA
      /**/this.idMaquina = Number.parseInt(this.route.snapshot.params['idMaquina']);
      var rowMaquinas = this.dataFiltro.filter(x => x.idMaquina == this.route.snapshot.params['idMaquina']);
      if (rowMaquinas.length > 0) {
        // console.log("URl 2.5 / makina");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 6, nombre: this.translateService.instant('maquina'), field: "maquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
            operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: false,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: true
          }
        );
        // Se carga el combo de clientes (esto limpia la seleccion)
        this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
        // Se selecciona el que queremos
        var maquina = { id: rowMaquinas[0].nombreMaquina, nombre: rowMaquinas[0].nombreMaquina }
        datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [maquina];
      }
      // TERMINADO 
      // * Siempre va a tener valor 0 o 1 y se filtraria aunque no se quiera filtrar por eso...
      // * solo lo voy a filtrar si es 1
      /**/var terminados = Number.parseInt(this.route.snapshot.params['terminados']);
      if (this.route.snapshot.params['terminados'] == '1') {
        // console.log("URl 2.5 / terminado");
        datosFiltro.group[0].group.push(
          {
            columna: { id: 14, nombre: this.translateService.instant('terminado'), field: "terminado", sqlfield: "po.operacionTerminada", tipo: 'check', maxItemsSelecteds: -1 },
            operator: { id: 0, nombre: '' },
            fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
            fechaFin: this.myFunctions.getDateNow(),
            mostrarCalendario: false,
            text: '',
            numberMin: 0,
            numberMax: 0,
            decimalformat: '0.000',
            decimalMin: 0.0,
            decimalMax: 0.0,
            check: true,
            combo: [{ id: 1, nombre: "" }],
            comboSelected: {},
            comboSelecteds: [],
            bloqueado: false
          }
        );
      }
    }
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }
  cargarFiltroURL_postConsulta() {
    // Esta funcion existe para cargar los filtros previos que requieren una consulta a la DB antes de poder ser aplicados
    // cargaremos el filtro en una variable para despues actualizar el filtro    
    // console.log("URl 3");

    var datosFiltro = {
      logic: { id: 1, nombre: this.translateService.instant('o') },
      group: [
        {
          logic: { id: 0, nombre: this.translateService.instant('y') },
          group: []
        }
      ]
    };
    // FECHAS (INICIO, FIN)
    /**/this.fini = this.route.snapshot.params['fini'];
    /**/this.ffin = this.route.snapshot.params['ffin'];
    if (this.route.snapshot.params['fini'] != '0' && this.route.snapshot.params['ffin'] != '0'
      && this.route.snapshot.params['fini'] != undefined && this.route.snapshot.params['ffin'] != undefined) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 2, nombre: this.translateService.instant('fecha'), field: "fecha", sqlfield: "hcr.fecha", tipo: 'date', maxItemsSelecteds: -1 },
          operator: { id: 7, nombre: this.translateService.instant('estaEntre'), dobleValor: true },
          fechaIni: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['fini']),
          fechaFin: this.myFunctions.YYYY_MM_DDToDate(this.route.snapshot.params['ffin']),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
    }
    // OF
    var rowOFs = this.dataFiltro.filter(x => x.idOf == this.idof_prefiltro);
    if (rowOFs.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 8, nombre: this.translateService.instant('of'), field: "nombreOf", sqlfield: "ofs.nombreOf", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var of = { id: rowOFs[0].nombreOf, nombre: rowOFs[0].nombreOf }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [of];
    }
    this.idof_prefiltro = 0;
    // PIEZA
    var rowPiezas = this.dataFiltro.filter(x => x.idPieza == this.idpieza_prefiltro);
    if (rowPiezas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 10, nombre: this.translateService.instant('pieza'), field: "nombrePieza", sqlfield: "pi.nombrePieza", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var pieza = { id: rowPiezas[0].nombrePieza, nombre: rowPiezas[0].nombrePieza }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [pieza];
    }
    this.idpieza_prefiltro = 0;
    // N SERIE
    if (this.nSerie_prefiltro != "") {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 11, nombre: this.translateService.instant('nserie'), field: "nSerie", sqlfield: "ho.nSerie", tipo: 'string', maxItemsSelecteds: -1 },
          operator: { id: 7, nombre: this.translateService.instant('igualQue'), dobleValor: false },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: this.nSerie_prefiltro,
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
    }
    this.nSerie_prefiltro = "";
    // OPERACION
    var rowOperaciones = this.dataFiltro.filter(x => x.idOperacion == this.idOperacion_prefiltro);
    if (rowOperaciones.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 13, nombre: this.translateService.instant('operacion'), field: "nombreOperacion", sqlfield: "op.nombreOperacion", tipo: 'comboFlexible', maxItemsSelecteds: -1 },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var operacion = { id: rowOperaciones[0].nombreOperacion, nombre: rowOperaciones[0].nombreOperacion }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [operacion];
    }
    this.idOperacion_prefiltro = 0;
    // MAQUINA
    var rowMaquinas = this.dataFiltro.filter(x => x.idMaquina == this.idmaquina_prefiltro);
    if (rowMaquinas.length > 0) {
      datosFiltro.group[0].group.push(
        {
          columna: { id: 6, nombre: this.translateService.instant('maquina'), field: "maquina", sqlfield: "dtr.idMaquina", tipo: 'comboEstrincto', maxItemsSelecteds: 1 },
          operator: { id: 2, nombre: this.translateService.instant('es'), dobleValor: true },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: true
        }
      );
      // Se carga el combo de clientes (esto limpia la seleccion)
      this.preFiltrado(this.datosFiltro, datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1]);
      // Se selecciona el que queremos
      var maquina = { id: rowMaquinas[0].idMaquina, nombre: rowMaquinas[0].nombreMaquina }
      datosFiltro.group[0].group[datosFiltro.group[0].group.length - 1].comboSelecteds = [maquina];
    }
    this.idmaquina_prefiltro = 0;
    // Si hay filtrado
    if (datosFiltro.group[0].group.length > 0) {
      // Annadimos la ultima linea al filtro
      datosFiltro.group[0].group.push(
        {
          columna: { id: 0, nombre: this.translateService.instant('seleccioneCampo'), tipo: '' },
          operator: { id: 0, nombre: '' },
          fechaIni: this.myFunctions.dateAddDays(this.myFunctions.getDateNow(), -7),
          fechaFin: this.myFunctions.getDateNow(),
          mostrarCalendario: false,
          text: '',
          numberMin: 0,
          numberMax: 0,
          decimalformat: '0.000',
          decimalMin: 0.0,
          decimalMax: 0.0,
          check: false,
          combo: [{ id: 1, nombre: "" }],
          comboSelected: {},
          comboSelecteds: [],
          bloqueado: false
        }
      );
      // Acutalizamos el filtro
      this.datosFiltro = datosFiltro;
      // Autofiltramos el informe
      this.onFilter();
    }
  }



  cargarGrupos() {
    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      // console.log("Grupos cargados");
      if (this.gruposCargados && this.seccionesCargadas)
        if (this.lehenAldia) {
          this.lehenAldia = false;
          this.cargar_Filtro();
        }
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    var r1, r2: boolean = false;

    //MAQUINAS
    // var maquinas_model = this.maquinasService.get_maquinas_model();
    // if (maquinas_model == false) {
    this.maquinasService.Get_Maquinas_Consumos().subscribe(json => {
      // this.maquinasService.set_maquinas_model(json);
      // this.maquinas = this.maquinasService.get_maquinas_model();
      this.maquinas = json;

      this.seccionesCargadas = true;
      // console.log("Secciones cargados R1");
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
        if (this.lehenAldia) {
          this.lehenAldia = false;
          this.cargar_Filtro();
        }
        // this.filtrarMaquinasPorAreaProductivaYGrupo();  ALDATU KARGA
      }
    })
    // } else {
    //   this.maquinas = maquinas_model;
    //   this.seccionesCargadas = true;
    //   // console.log("Secciones cargados R1");
    //   r1 = true;
    //   if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
    //   if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
    //     if (this.lehenAldia) {
    //       this.lehenAldia = false;
    //       this.cargar_Filtro();
    //     }
    //     // this.filtrarMaquinasPorAreaProductivaYGrupo();  ALDATU KARGA
    //   }
    // }

    //INSTALACIONES
    // var instalaciones_model = this.maquinasService.get_instalaciones_model();
    // if (instalaciones_model == false) {
    this.maquinasService.Get_Instalaciones_Consumos().subscribe(json => {
      this.maquinasService.set_instalaciones_model(json);
      this.instalaciones = this.maquinasService.get_instalaciones_model();
      // console.log("Secciones cargados R2");
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
        if (this.lehenAldia) {
          this.lehenAldia = false;
          this.cargar_Filtro();
        }
        // this.filtrarMaquinasPorAreaProductivaYGrupo();  ALDATU KARGA
      }
    })
    // } else {
    //   this.instalaciones = instalaciones_model;
    //   // console.log("Secciones cargados R2");
    //   r2 = true;
    //   if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
    //   if (this.gruposCargados && this.seccionesCargadas && r1 && r2) {
    //     if (this.lehenAldia) {
    //       this.lehenAldia = false;
    //       this.cargar_Filtro();
    //     }
    //     // this.filtrarMaquinasPorAreaProductivaYGrupo();  ALDATU KARGA
    //   }
    // }

  }

  // END FILTRO





















  cargarDatosAnuales() {
    var itPrimero = true;
    this.sumaTotalAnualPorMaquina.clear();
    // TRAER DATOS DEL ULTIMO AÑO
    this.informeConsumoService.getDatosConsumo(this.consultaFiltroCompleto).subscribe(result => {

      this.datosConsumoAnual = result;

      this.informeConsumoService.getMaquinasConsumo(this.consultaFiltroCompleto).subscribe(data => {

        this.listaMaquinas = data;

        this.datosConsumoAnual.forEach(datosConsumo => {
          // Valor anual
          if (datosConsumo.kWh != undefined && datosConsumo.kWh > 0) {
            this.sumaTotal = this.sumaTotal + datosConsumo.kWh;
          }
          //Valor por maquina
          if (itPrimero) {
            this.listaMaquinas.forEach(maquinas => {
              this.sumaTotalAnualPorMaquina.set(maquinas.idMaquina, 0.00);
            });
            itPrimero = false;
          }

          var valorActualMaquina = this.sumaTotalAnualPorMaquina.get(datosConsumo.idMaquina);
          if (datosConsumo.kWh != undefined && datosConsumo.kWh > 0) {
            this.sumaTotalAnualPorMaquina.set(datosConsumo.idMaquina, valorActualMaquina + datosConsumo.kWh);
          }

          //Valor por mes

          //var fechaAux = new Date(datosConsumo.fecha);
          var fechaAux = datosConsumo.anno.toString() + "-" + datosConsumo.mes.toString();
          if (this.sumaTotalListaMes.has(fechaAux)) {
            var valorActualMes = this.sumaTotalListaMes.get(fechaAux);
            if (datosConsumo.kWh != undefined && datosConsumo.kWh > 0) {
              this.sumaTotalListaMes.set(fechaAux, valorActualMes + datosConsumo.kWh);
            }
          } else {
            this.sumaTotalListaMes.set(fechaAux, datosConsumo.kWh);
          }


        });
        // console.log(this.sumaTotalListaMes);
        this.crearJsonAnual();

      });
    });
  }



  crearJsonAnual() {
    this.jsonAnual = [];

    var maquinaAnual = [];
    this.sumaTotalAnualPorMaquina.forEach((value, key) => {
      var maquinaAnualLocal = {
        idMaquina: key,
        suma: value.toFixed(2)
      }
      maquinaAnual.push(maquinaAnualLocal);
    });
    var sumaMes = [];
    var sumaMaquinasMes: any = [];
    var diferenciaMeses = [];
    diferenciaMeses = this.fechasPrimerDiaMeses(this.fechaInicio, this.fechaFin);
    diferenciaMeses.forEach(fecha => {
      var keyFecha: any = fecha.getFullYear() + "-" + (fecha.getMonth() + 1).toString();
      var aurk = false;
      this.sumaTotalListaMes.forEach((value, key) => {
        if (keyFecha == key) {
          aurk = true;
          sumaMaquinasMes = [];
          var sumaMaquinasMes = this.sumarMaquinasPorMes(key);
          var mesLocal = {
            mes: key,
            suma: value,
            maquinasMes: sumaMaquinasMes
          }
          sumaMes.push(mesLocal);
        }

      });
      if (!aurk) {
        sumaMaquinasMes = [];
        var lag: any = 0.00;
        var sumaMaquinasMes = this.sumarMaquinasPorMes(keyFecha);
        var mesLocal = {
          mes: keyFecha.toString(),
          suma: lag,
          maquinasMes: sumaMaquinasMes
        }
        sumaMes.push(mesLocal);
      }

    });
    var anual = {
      sumaKWhAnual: this.sumaTotal.toFixed(2),
      maquinasAnual: maquinaAnual,
      meses: sumaMes,
      topOF: {}
    }
    this.jsonAnual.push(anual);
    this.sumaTotalStr = this.sumaTotal.toFixed(2);
    // console.log(this.sumaTotalAnualPorMaquina);
    // console.log(this.jsonAnual);


    this.cargarGraficosYTablas();



  }

  sumarMaquinasPorMes(fechaCompuesta) {
    var resultMaquinaMes = [];
    var sumaMaquinas = new Map();
    // Makina guztiak kargatu 0 defektuzko balioekin
    this.listaMaquinas.forEach(maquina => {
      sumaMaquinas.set(maquina.idMaquina, 0);
    });

    this.datosConsumoAnual.forEach(datoConsumo => {
      var fechaAux = datoConsumo.anno + "-" + datoConsumo.mes;
      if (fechaAux == fechaCompuesta) {
        var valorTempMaquina = sumaMaquinas.get(datoConsumo.idMaquina);
        // if (valorTempMaquina != undefined && datoConsumo.kWh > 0) {
        //if (datoConsumo.kWh > 0) {
        sumaMaquinas.set(datoConsumo.idMaquina, valorTempMaquina + datoConsumo.kWh);
        //} else {
        //  sumaMaquinas.set(datoConsumo.idMaquina, datoConsumo.kWh);
        //}
      }
    });
    sumaMaquinas.forEach((value, key) => {
      var maquinaMeslLocal = {
        idMaquina: key,
        suma: value
      }
      resultMaquinaMes.push(maquinaMeslLocal);
    });

    // if (fechaCompuesta == "2023-09")
    //   console.log("Maquinas Mes: ", fechaCompuesta, " :  ",resultMaquinaMes)

    return resultMaquinaMes;

  }

  cargarGraficosYTablas() {
    //CREAR GRAFICOS CON DATOS ANUALES
    this.crearGraficoConsumoPorMaquina();
    this.crearGraficoConsumoTotal();
    this.crearDonutPorMaquina();

    //CARGAR TABLAS
    this.calcularDatosTablaIzquierda();
    if (this.tipoVentana == 0) {
      this.calcularTopOFs(this.fechaIni, this.fechaFinal);
    } else if (this.tipoVentana == 1) {
      this.fechaIni = new Date(new Date().getFullYear(), 0, 1);
      var dataIniLag = new Date(new Date().getFullYear(), this.mesSeleccionado - 1, 1);
      var dataFinLag = new Date(new Date().getFullYear(), this.mesSeleccionado, 1);

      // console.log("Fecha Ini: ", dataIniLag);
      // console.log("Fecha Fin: ", dataFinLag);
      this.calcularTopOFs(dataIniLag, dataFinLag);
    } else if (this.tipoVentana == 2) {
      this.fechaIni = new Date(new Date().getFullYear(), 0, 1);
      var dataIniLag = new Date(new Date().getFullYear(), this.mesSeleccionado, this.diaSeleccionado);
      // Kontuan hartu hilabeteko azken eguna hautatu ezkero hurrengo eguneko datua hurrengo hilabetearen lehen eguna izango dela
      var dataFinLag = new Date(new Date().getFullYear(), this.mesSeleccionado, this.diaSeleccionado + 1);

      dataIniLag = new Date(dataIniLag);
      // console.log("Fecha Ini: ", dataIniLag);
      // console.log("Fecha Fin: ", dataFinLag);
      var a = 0;
      this.calcularTopOFs(dataIniLag, dataFinLag);
    }



  }

  crearGraficoConsumoPorMaquina() {
    var nombreCategorias = this.obtenerNombresDatos();
    //console.log("Nombre Categorias: " + nombreCategorias);

    // this.graficoBarrasConsumoPorMaquinas = c3.generate({
    //   bindto: '#consumoPorMaquina',
    //   data: {
    //     columns: [],
    //     type: 'bar'
    //   },
    //   axis: {
    //     x: {
    //       type: 'category',
    //       categories: nombreCategorias,
    //       tick: {
    //         rotate: 45,
    //         format: function (d) {
    //           var catName = this.api.categories()[d];
    //           if (catName.length > 5) {
    //             catName = catName.slice(0, 5) + "…";
    //           }
    //           return catName;
    //         }

    //       },
    //     },
    //     y: {
    //       label: {
    //         text: 'KWh',
    //         position: 'outer-middle'
    //       }
    //     }
    //   }
    // });
    
    this.graficoBarrasConsumoPorMaquinas = c3.generate({
      bindto: '#consumoPorMaquina',
      data: {
          columns: [
              // ['TORNO FAGOR 8070', '231.84'],
              // ['FRES. HEIDENHAIN 530', '233.88'],
              // ['FRES. FANUC 0i', '239.08'],
              // ['FRES. SIEMENS', '69.93'],
              // ['MANDRI. FAGOR 8055', '254.46']
          ],
          type: 'bar'
      },
      axis: {
        x: {
          show: false
        },
        y: {
          label: {
            text: 'KWh',
            position: 'outer-middle'
          }
        }
      },
      bar: {
          width: {
              ratio: 0.5 // this makes bar width 50% of length between ticks
          },
          space: 0.8
          // or
          //width: 100 // this makes bar width 100px
      },
      
  });

    this.cargarDatosBarras();
  }

  cargarDatosBarras() {
    var nombreCategorias = this.listaMaquinas.map(f=>f.nombre).reverse();
    debugger
    var nuevaLista = [];
    nombreCategorias.forEach(nombre => {
      nuevaLista.unshift([nombre]);
    })
    debugger

    var valores = ['KWh'];


    if (this.tipoVentana == 0) {

      this.sumaTotalAnualPorMaquina.forEach((value, key) => {
        valores.push(value.toFixed(2));
      });

    } else if (this.tipoVentana == 1) {

      var idMaquinas = new Map();
      this.sumaTotalAnualPorMaquina.forEach((value, key) => {
        idMaquinas.set(key, 0.00);
      });

      this.datosConsumoAnual.forEach(consumoAnual => {
        if (consumoAnual.anno == this.annoActual && consumoAnual.mes == this.mesSeleccionado) {
          var valorTemp = idMaquinas.get(consumoAnual.idMaquina);
          idMaquinas.set(consumoAnual.idMaquina, valorTemp + consumoAnual.kWh);
        }
      });


      idMaquinas.forEach((value, key) => {
        valores.push(value.toFixed(2));
      });


      // this.jsonAnual.forEach(anual => {
      //   anual.meses.forEach(mes => {
      //     if (mes.mes == this.mesSeleccionado) {
      //       mes.maquinasMes.forEach(maquina => {
      //         valores.push(maquina.suma.toFixed(2));
      //       });
      //     }
      //   });
      // });

    } else if (this.tipoVentana == 2) {
      this.listaMaquinas.forEach(maquina => {
        var sumaMaquina = 0;
        this.datosDia.forEach(datoDia => {
          if (maquina.idMaquina == datoDia.idMaquina) {
            sumaMaquina = sumaMaquina + datoDia.kWh;
          }
        });
        valores.push(sumaMaquina.toFixed(2));
      });
    }
    var i = 1;
    var listaFinal = []; 
    nuevaLista.forEach(v => {
      v.push(valores[i]);
      i++;
      listaFinal.push(v);
    })
    console.log(nuevaLista);
    
    
    debugger
    this.graficoBarrasConsumoPorMaquinas.load({
      unload: true,
      columns: nuevaLista,
    });
  }

  obtenerNombresDatos() {
    var idMaquinas = [];
    this.sumaTotalAnualPorMaquina.forEach((value, key) => {
      this.listaMaquinas.forEach(maquina => {
        if (key == maquina.idMaquina) {
          idMaquinas.push(maquina.nombre);
        }
      });
    });

    //console.log("IdMaquinas: " + idMaquinas);
    return idMaquinas;
  }


  crearGraficoConsumoTotal() {
    var categorias = [];
    var maquinas = [];
    var diferenciaMeses = [];
    if (this.tipoVentana == 0) {

      diferenciaMeses = this.fechasPrimerDiaMeses(this.fechaInicio, this.fechaFin);
      diferenciaMeses.forEach(fecha => {
        //categorias.push(fecha.getFullYear().toString() + "-" + this.translateService.instant(this.columnasMes[fecha.getMonth()]));
        // categorias.push((fecha.getMonth() + 1) + "-" + fecha.getFullYear().toString());
        // eneroAcortado
        var nombreAcortadoMes = this.translateService.instant(this.translateService.instant(this.columnasMes[fecha.getMonth()].toLowerCase() + "Acortado"));
        nombreAcortadoMes = nombreAcortadoMes.replace('.', '');
        categorias.push(nombreAcortadoMes + "-" + fecha.getFullYear().toString().substring(2));

      });

      // this.columnasMes.forEach(mes => {
      //   categorias.push(this.translateService.instant(mes));
      // });

      //console.log("Categorias: " + categorias);

    } else if (this.tipoVentana == 1) {

      var cantidadDias = new Date(this.annoActual, this.mesSeleccionado, 0).getDate();
      // console.log("Año actual: " + this.annoActual);
      // console.log("Mes actual: " + this.mesSeleccionado);

      for (let index = 1; index < cantidadDias + 1; index++) {
        categorias.push(index.toString());
      }

    } else if (this.tipoVentana == 2) {
      for (let index = 1; index <= 24; index++) {
        categorias.push(index.toString());
      }
    }

    this.listaMaquinas.forEach(maquina => {
      // maquinas.push("data" + maquina.idMaquina.toString());
      maquinas.push(maquina.nombre);
    });


    // console.log("Categorias: " + categorias);

    this.graficoConsumoTotalAcumulado = c3.generate({
      bindto: '#consumoTotal',
      data: {
        columns: [],
        selection: {
          grouped: true
        },
        type: 'bar',
        onclick: function (d, element) {
          //console.log(element);
        },
        groups: [maquinas]
      },
      axis: {
        x: {
          type: 'category',
          categories: categorias
        },
        y: {
          label: {
            text: 'KWh',
            position: 'outer-middle'
          }
        }
      },
      onItemDropped: (e, semana) => {
        if (this.tipoVentana == 0) {
          this.tipoVentana = 1;
          this.mesSeleccionado = e.subject.index + 1;
          this.mesNombre = this.translateService.instant(this.columnasMes[this.mesSeleccionado - 1]);
          var fechasLag = this.listaTotal[e.subject.index];
          var fechaSplit = fechasLag.indice.split("-")
          this.annoActual = +fechaSplit[0];
          this.cargarGraficosYTablas();
        } else if (this.tipoVentana == 1) {
          this.diaSeleccionado = e.subject.index + 1;
          this.informeConsumoService.getDatosConsumoDia(this.consultaFiltroCompleto, this.annoActual, this.mesSeleccionado, this.diaSeleccionado).subscribe(result => {
            this.tipoVentana = 2;
            this.datosDia = result;
            this.cargarGraficosYTablas();
          });
        }
      }
    });
    this.cargarDatosAgrupados();
  }

  cargarDatosAgrupados() {
    var valores = [];
    var maquinas = new Map();

    this.listaMaquinas.forEach(maquina => {
      maquinas.set(maquina.nombre, []);
    });

    if (this.tipoVentana == 0) {

      this.jsonAnual.forEach(anno => {
        anno.meses.forEach(mes => {
          mes.maquinasMes.forEach(maquina => {
            this.listaMaquinas.forEach(maquinalista => {
              if (maquinalista.idMaquina == maquina.idMaquina) {
                var infoTemp = maquinas.get(maquinalista.nombre);
                infoTemp.push(maquina.suma);
                maquinas.set(maquinalista.nombre, infoTemp);
              }
            });
          });
        });
      });

      //console.log(maquinas);

      maquinas.forEach((value, key) => {
        var arrayTemp = [];
        // arrayTemp.push("data" + key.toString());
        arrayTemp.push(key.toString());
        value.forEach(valor => {
          arrayTemp.push(valor.toFixed(2));
        });
        valores.push(arrayTemp);
      });

      var maquinasGroups = [];
      this.listaMaquinas.forEach(maquina => {
        // maquinasGroups.push("data" + maquina.idMaquina.toString());
        maquinasGroups.push(maquina.nombre.toString());
      });



      // console.log("Valores: ", valores);

      this.graficoConsumoTotalAcumulado.load({
        unload: true,
        columns: valores
      });


    } else if (this.tipoVentana == 1) {

      var dataMaquinasLag = new Map();

      this.informeConsumoService.getDatosConsumoMes(this.consultaFiltroCompleto, this.annoActual, this.mesSeleccionado).subscribe(result => {

        var dataMes: any = result;

        var cantidadDiasMes = new Date(this.annoActual, this.mesSeleccionado, 0).getDate();
        for (let index = 0; index < cantidadDiasMes; index++) {
          var suma = 0
          // Eguneko datuak makinaka pilatzeko datuMapa bat hasieratu
          this.listaMaquinas.forEach(maquina => {
            dataMaquinasLag.set(maquina.nombre, 0);
          });
          // Eguneko datuak  makinaka batu
          dataMes.forEach(datoMes => {
            suma = 0;
            if (datoMes.dia == index + 1) {
              this.listaMaquinas.forEach(maquinalista => {
                if (maquinalista.idMaquina == datoMes.idMaquina) {
                  suma = dataMaquinasLag.get(maquinalista.nombre);
                  dataMaquinasLag.set(maquinalista.nombre, suma + datoMes.kWh);
                }
              });

            }
          });

          // Eguneko datua makinaka array batean gorde
          this.listaMaquinas.forEach(maquina => {
            var arrayTemp = [];
            arrayTemp = maquinas.get(maquina.nombre);
            arrayTemp.push(dataMaquinasLag.get(maquina.nombre));
            maquinas.set(maquina.nombre, arrayTemp);
          });
        }

        maquinas.forEach((value, key) => {
          var arrayTemp = [];
          // arrayTemp.push("data" + key.toString());
          arrayTemp.push(key.toString());
          value.forEach(valor => {
            arrayTemp.push(valor.toFixed(2));
          });
          valores.push(arrayTemp);
        });

        this.graficoConsumoTotalAcumulado.load({
          unload: true,
          columns: valores
        });


      });

      // console.log("Valores: ", valores);

    } else if (this.tipoVentana == 2) {

      for (let index = 1; index <= 24; index++) {
        this.listaMaquinas.forEach(maquinaLista => {
          var aurk = false;
          this.datosDia.forEach(datoDia => {
            var hora: number = +datoDia.hora;
            if (hora == 0 && index == 24 && datoDia.idMaquina == maquinaLista.idMaquina && !aurk) {
              aurk = true;
              var lag = [];
              lag = maquinas.get(maquinaLista.nombre);
              lag.push(datoDia.kWh);
              maquinas.set(maquinaLista.nombre, lag);
            } else if (hora == index && datoDia.idMaquina == maquinaLista.idMaquina && !aurk) {
              aurk = true;
              var lag = [];
              lag = maquinas.get(maquinaLista.nombre);
              lag.push(datoDia.kWh);
              maquinas.set(maquinaLista.nombre, lag);
            }
          });
          if (!aurk) {
            var lag = [];
            lag = maquinas.get(maquinaLista.nombre);
            lag.push(0);
            maquinas.set(maquinaLista.nombre, lag);
          }
        });
      }

      maquinas.forEach((value, key) => {
        var arrayTemp = [];
        // arrayTemp.push("data" + key.toString());
        arrayTemp.push(key.toString());
        value.forEach(valor => {
          arrayTemp.push(valor.toFixed(2));
        });
        valores.push(arrayTemp);
      });

      this.graficoConsumoTotalAcumulado.load({
        unload: true,
        columns: valores
      });
    }

    // console.log("Valores: ", valores);
  }

  crearDonutPorMaquina() {

    this.graficoDonutMaquina = c3.generate({
      bindto: '#donutMaquina',
      data: {
        columns: [],
        type: 'donut'
      }
    });

    this.cargarDonut();
  }


  cargarDonut() {
    var valores = [];
    var valoresAux = [];
    if (this.tipoVentana == 0) {
      this.sumaTotalAnualPorMaquina.forEach((value, key) => {
        this.listaMaquinas.forEach(maquinalista => {
          if (maquinalista.idMaquina == key) {
            valoresAux = [];
            valoresAux.push(maquinalista.nombre);
            valoresAux.push(value.toFixed(2));
            valores.push(valoresAux);
          }
        });
      });
    } else if (this.tipoVentana == 1) {

      var idMaquinas = new Map();
      this.sumaTotalAnualPorMaquina.forEach((value, key) => {
        this.listaMaquinas.forEach(maquinalista => {
          if (maquinalista.idMaquina == key) {
            idMaquinas.set(maquinalista.nombre, 0.00);
          }
        });
      });

      this.datosConsumoAnual.forEach(consumoAnual => {
        if (consumoAnual.anno == this.annoActual && consumoAnual.mes == this.mesSeleccionado) {
          this.listaMaquinas.forEach(maquinalista => {
            if (maquinalista.idMaquina == consumoAnual.idMaquina) {
              var valorTemp = idMaquinas.get(maquinalista.nombre);
              idMaquinas.set(maquinalista.nombre, valorTemp + consumoAnual.kWh);
            }
          });
        }
      });


      idMaquinas.forEach((value, key) => {
        valoresAux = [];
        valoresAux.push(key.toString());
        valoresAux.push(value.toFixed(2));
        valores.push(valoresAux);
      });

      // this.jsonAnual.forEach(anual => {
      //   anual.meses.forEach(mes => {
      //     if (mes.mes == this.mesSeleccionado) {
      //       mes.maquinasMes.forEach(maquina => {
      //         var valoresAux = [];
      //         valoresAux.push(maquina.idMaquina.toString());
      //         valoresAux.push(maquina.suma.toFixed(2));
      //         valores.push(valoresAux);
      //       });
      //     }
      //   });
      // });

    } else if (this.tipoVentana == 2) {
      this.listaMaquinas.forEach(maquina => {
        var sumaMaquina = 0;
        this.datosDia.forEach(datoDia => {
          if (maquina.idMaquina == datoDia.idMaquina) {
            sumaMaquina = sumaMaquina + datoDia.kWh;
          }
        });
        valoresAux = [];
        valoresAux.push(maquina.nombre.toString());
        valoresAux.push(sumaMaquina.toFixed(2));
        valores.push(valoresAux);
      });
    }

    this.graficoDonutMaquina.load({
      unload: true,
      columns: valores
    });

  }

  calcularTopOFs(fechaIni, fechaFinal) {
    var mayor = false;
    this.top10Ofs = [];
    for (let index = 0; index < 10; index++) {
      this.top10Ofs.push({ idOF: -1, pieza: "", value: -1, top: index + 1 });
    }
    var datosConOFs = [];
    this.datosConsumoAnual.forEach(datoAnno => {
      if (Date.parse(datoAnno.fecha) > fechaIni && Date.parse(datoAnno.fecha) <= fechaFinal) {

        if (datoAnno.idOF != -1) {
          var badago = false;
          datosConOFs.forEach(dato => {
            if (dato.nombreOf == datoAnno.nombreOf && dato.nombrePieza == datoAnno.nombrePieza) {
              badago = true;
              dato.kWh = dato.kWh + datoAnno.kWh;
            }
          });
          if (!badago) {
            datosConOFs.push(datoAnno);
          }
        }
      }
    });

    datosConOFs.sort((a, b) => b.kWh - a.kWh);
    var datosTopAux = datosConOFs.slice(0, 10);
    this.top10Ofs.forEach((topOf, index) => {
      if (datosTopAux.length > index) {
        topOf.idOF = datosTopAux[index].nombreOf;
        topOf.pieza = datosTopAux[index].nombrePieza;
        topOf.value = datosTopAux[index].kWh.toFixed(2);
      }
    });


    // Zerrendatik OF gabeak kendu
    for (let index = this.top10Ofs.length - 1; index >= 0; index--) {
      if (this.top10Ofs[index].idOF == -1) {
        this.top10Ofs.splice(index, 1);
      }
    }

  }

  calcularDatosTablaIzquierda() {
    this.listaTotal = [];
    if (this.tipoVentana == 0) {

      forkJoin(
        this.jsonAnual.map(anual =>
          forkJoin(
            anual.meses.map(mes => {
              const fechasTemp = mes.mes.split("-");
              const annoTemp = fechasTemp[0];
              const mesTemp: number = +fechasTemp[1];
      
              return this.informeConsumoService.getDatosConsumoMes(this.consultaFiltroCompleto, annoTemp, mesTemp).pipe(
                map(result => {
                  const datosMes: any = result;
                  let sumaMensual = 0.0;
                  const cantidadDias = new Date(annoTemp, mesTemp, 0).getDate();
      
                  for (let index = 1; index < cantidadDias; index++) {
                    let sumaDia = 0;
                    datosMes.forEach(dato => {
                      if (dato.dia === index) {
                        sumaDia += dato.kWh;
                      }
                    });
                    sumaMensual += sumaDia;
                  }
      
                  const listaElement = {
                    indice: annoTemp + "-" + this.translateService.instant(this.columnasMes[mesTemp - 1]),
                    id: mes.mes,
                    value: sumaMensual.toFixed(2) + " KWh"
                  };
      
                  return listaElement;
                })
              );
            })
          )
        )
      ).subscribe(resultados => {
        this.listaTotal = [];
        this.sumaTotal = 0.0;
      
        resultados.forEach((resultadosMes: any[]) => {
          resultadosMes.forEach((listaElement: any ) => {
            this.listaTotal.push(listaElement);
            this.sumaTotal += +listaElement.value.split(" ")[0];
          });
        });
      
        this.sumaTotalStr = this.sumaTotal.toFixed(2);
      });

      // this.jsonAnual.forEach(anual => {
      //   anual.meses.forEach(mes => {
      //     var fechasTemp = mes.mes.split("-");
      //     var annoTemp = fechasTemp[0];
      //     var mesTemp: number = +fechasTemp[1];

      //     // var listaElement = {
      //     //   indice: annoTemp + "-" + this.translateService.instant(this.columnasMes[mesTemp - 1]),
      //     //   id: mes.mes,
      //     //   value: mes.suma.toFixed(2) + " KWh"
      //     // }

      //     // this.listaTotal.push(listaElement);

      //     // this.sumaTotal = this.sumaTotal + mes.suma;
      //     // this.sumaTotalStr = this.sumaTotal.toFixed(2);
      //     // // this.listaTotal.forEach(lista => {
      //     // //   if (lista.id == mes.mes) {
      //     // //     lista.value = mes.suma;
      //     // //   }
      //     // // });


      //     this.informeConsumoService.getDatosConsumoMes(this.consultaFiltroCompleto, annoTemp, mesTemp).subscribe(result => {
      //       var datosMes: any = result;
      //       this.listaTotal = [];
      //       this.sumaTotal = 0.0;
      //       var sumaMensual = 0.0;
      //       var cantidadDias = new Date(annoTemp, mesTemp, 0).getDate();
      //       // console.log("Cantidad de dias: " + cantidadDias);
    
      //       for (let index = 1; index < cantidadDias + 1; index++) {
      //         var sumaDia = 0;
      //         datosMes.forEach(dato => {
      //           if (dato.dia == index) {
      //             sumaDia = sumaDia + dato.kWh;
      //           }
      //         });
      //         sumaMensual = sumaMensual + sumaDia;
      //       }

      //       var listaElement = {
      //         indice: annoTemp + "-" + this.translateService.instant(this.columnasMes[mesTemp - 1]),
      //         id: mes.mes,
      //         value: sumaMensual.toFixed(2) + " KWh"
      //       }
            

      //       this.listaTotal.push(listaElement);

      //       this.sumaTotal = this.sumaTotal + sumaMensual
      //       this.sumaTotalStr = this.sumaTotal.toFixed(2);
      //       // console.log(this.listaTotal);
      //     });

      //   });
      // });

      // console.log(this.listaTotal);
    } else if (this.tipoVentana == 1) {
      this.informeConsumoService.getDatosConsumoMes(this.consultaFiltroCompleto, this.annoActual, this.mesSeleccionado).subscribe(result => {
        var datosMes: any = result;
        this.listaTotal = [];
        this.sumaTotal = 0.0;
        var cantidadDias = new Date(this.annoActual, this.mesSeleccionado, 0).getDate();
        // console.log("Cantidad de dias: " + cantidadDias);

        for (let index = 1; index < cantidadDias + 1; index++) {
          var sumaDia = 0;
          datosMes.forEach(dato => {
            if (dato.dia == index) {
              sumaDia = sumaDia + dato.kWh;
            }
          });
          var infoTemp = {
            indice: index.toString(),
            id: index,
            value: sumaDia.toFixed(2) + " KWh"
          };
          this.sumaTotal = this.sumaTotal + sumaDia;
          this.sumaTotalStr = this.sumaTotal.toFixed(2);
          this.listaTotal.push(infoTemp);
        }
        // console.log(this.listaTotal);
      });


    } else if (this.tipoVentana == 2) {
      this.listaTotal = [];
      this.sumaTotal = 0.0;

      for (let index = 1; index <= 24; index++) {
        var sumaHora = 0;
        this.datosDia.forEach(datoDia => {
          if (datoDia.hora == 0 && index == 24) {
            sumaHora = sumaHora + datoDia.kWh;
          } else if (datoDia.hora == index) {
            sumaHora = sumaHora + datoDia.kWh;
          }
        });
        var infoTemp = {
          indice: index.toString(),
          id: index,
          value: sumaHora.toFixed(2) + " KWh"
        };
        this.sumaTotal = this.sumaTotal + sumaHora;
        this.sumaTotalStr = this.sumaTotal.toFixed(2);
        this.listaTotal.push(infoTemp);
      }

    }

  }


  clickLista(e) {
    // console.log(e);

    if (this.tipoVentana == 0) {
      this.tipoVentana = 1;
      var fechasLag = e.dataItem.id.split("-");
      this.mesSeleccionado = +fechasLag[1];
      this.mesNombre = this.translateService.instant(this.columnasMes[this.mesSeleccionado - 1]);
      this.annoActual = +fechasLag[0];
      this.cargarGraficosYTablas();

    } else if (this.tipoVentana == 1) {
      this.diaSeleccionado = e.dataItem.id;
      this.informeConsumoService.getDatosConsumoDia(this.consultaFiltroCompleto, this.annoActual, this.mesSeleccionado, this.diaSeleccionado).subscribe(result => {
        this.tipoVentana = 2;
        this.datosDia = result;
        this.cargarGraficosYTablas();
      });
    }
  }

  clickCabecera() {
    // console.log(value);
    if (this.tipoVentana == 1) {
      this.tipoVentana = 0;
      this.listaTotal = [
        { indice: this.translateService.instant('enero'), id: 0, value: 0.00 },
        { indice: this.translateService.instant('febrero'), id: 1, value: 0.00 },
        { indice: this.translateService.instant('marzo'), id: 2, value: 0.00 },
        { indice: this.translateService.instant('abril'), id: 3, value: 0.00 },
        { indice: this.translateService.instant('mayo'), id: 4, value: 0.00 },
        { indice: this.translateService.instant('junio'), id: 5, value: 0.00 },
        { indice: this.translateService.instant('julio'), id: 6, value: 0.00 },
        { indice: this.translateService.instant('agosto'), id: 7, value: 0.00 },
        { indice: this.translateService.instant('septiembre'), id: 8, value: 0.00 },
        { indice: this.translateService.instant('octubre'), id: 9, value: 0.00 },
        { indice: this.translateService.instant('noviembre'), id: 10, value: 0.00 },
        { indice: this.translateService.instant('diciembre'), id: 11, value: 0.00 }
      ];
      this.cargarGraficosYTablas();
    } else if (this.tipoVentana == 2) {
      this.tipoVentana = 1;
      this.cargarGraficosYTablas();
    }
  }


  fechasPrimerDiaMeses(fecha1: Date, fecha2: Date): Date[] {
    const resultado: Date[] = [];

    let fechaActual = new Date(fecha1.getTime());
    fechaActual.setDate(1);

    while (fechaActual < fecha2) {
      resultado.push(new Date(fechaActual.getTime()));
      fechaActual.setMonth(fechaActual.getMonth() + 1);
    }

    return resultado;
  }

}
