import { Component } from '@angular/core';
import { UsuariosService, MenuService, HistoricoDatosService, MaquinasService, InformeRendimientoService } from '@app/_services';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyFunctions } from '@app/_helpers';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import * as c3 from 'c3';
import * as d3 from 'd3';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-historico-datos',
  templateUrl: './historicoDatos.component.html'
})

export class HistoricoDatosComponent {

  user = this.userService.userValue;

  //PANEL MAQUINAS
  public selectedMaquina: any;
  public maquinas: any = [];
  public instalaciones: any = [];
  public maquinasMostradas: any = [];

  //AREA PRODUCTIVA / SECCION
  public secciones: any;
  public groupedSeccion: GroupResult[];
  public seccionesSeleccionadas: any[] = [];

  //GRUPOS DE MAQUINAS
  public grupos: any;
  public gruposSeleccionados: any;

  //CARGA DE SECCIONES Y GRUPOS
  public gruposCargados: boolean = false;
  public seccionesCargadas: boolean = false;

  //FECHAS
  public fechaInicio: Date;
  public fechaFin: Date;

  public fechaInicioFiltrada: Date;
  public fechaFinFiltrada: Date;

  public tituloGrafico = this.translateService.instant('datos');
  public unidadGrafico = "";

  private dataGrafico: any;
  private grafico: any;
  private zoom_tiempoGraficoIni: any;
  private zoom_tiempoGraficoFin: any;
  public cargandoGrafico: boolean = false;

  array_valores: any;
  array_valores_medias: any;
  array_valores_min: any;
  array_valores_max: any;

  public ensennarBotonesSuperior: boolean = false;
  public listaBotonesSuperior: any = [];

  public listaBotonesFiltroGrafico: any = [];

  public datosMaquina: any = [];
  public datosMaquinaFiltrado: any = [];
  public datosMaquinaFiltradoResumen: any = [];

  idMaquina: number;
  idCampo: number = 0;

  public aparecer = false;

  // GANTT
  public fechaMin: any;
  public fechaMax: any;
  public turnosArray = [];

  constructor(private historicoDatosService: HistoricoDatosService,
    private route: ActivatedRoute,
    private maquinasService: MaquinasService,
    private userService: UsuariosService,
    private menuService: MenuService,
    private translateService: TranslateService,
    private myFunctions: MyFunctions,
    private InformeRendimientoService: InformeRendimientoService) {

    this.menuService.titulo = this.translateService.instant('historicoDatos').toUpperCase();

    this.userService.user.subscribe(x => this.user = x);

    if(this.route.snapshot.params['idMaquina']!=undefined){
      this.idMaquina = Number.parseInt(this.route.snapshot.params['idMaquina']);
    }
    this.idCampo = Number.parseInt(this.route.snapshot.params['idCampo']);

  }

  ngOnInit() {

    this.fechaInicio = this.myFunctions.getDateNow()
    this.fechaFin = this.myFunctions.getDateNow();//1 egun aurrera
    this.fechaInicio.setHours(0, 0, 0,);
    this.fechaFin.setHours(0, 0, 0, 0);

    this.cargarGrupos();
    this.cargarAreasProductivas();

    this.dibujarGrafico();

  }

  public onChangeFechaIni(value: Date): void {
    if (value == null) this.fechaInicio = this.myFunctions.getDateNow()
    else this.fechaInicio.setHours(0, 0, 0);
  }

  public onChangeFechaFin(value: Date): void {
    if (value == null) this.fechaFin = this.myFunctions.getDateNow()
    else this.fechaFin.setHours(0, 0, 0);
  }

  cargarGrupos() {

    this.maquinasService.getGruposMaquinas().subscribe(json => {
      this.grupos = json.data;
      this.gruposCargados = true;
      this.grupos.forEach(grupo => {
        if(grupo.activo) this.gruposSeleccionados.push(grupo);
      });
      if (this.gruposCargados && this.seccionesCargadas) this.filtrarMaquinasPorAreaProductivaYGrupo();
    });

  }

  cargarAreasProductivas() {

    var an1: any = this.userService.secciones;
    this.secciones == undefined

    if (an1 != undefined) this.secciones = an1.filter(f => f.activo === true);

    if (this.secciones == undefined) {

      this.userService.getSecciones().subscribe(json => {
        this.userService.secciones = json;
        //EN ESTE CASO SOLO SE COGEN LAS SECCIONES QUE ESTAN SELECCIONADAS EN LA SESION
        var an1: any = this.userService.secciones;
        this.secciones = an1.filter(f => f.activo === true);

        var an: any = this.secciones;
        this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);
        an.forEach(row => {
          if (row.activo) this.seccionesSeleccionadas.push(row);
        });

        this.cargarMaquinas();

      });

    } else {

      var an: any = this.secciones;
      this.groupedSeccion = groupBy(an, [{ field: 'areaProductiva' }]);

      an.forEach(row => {
        if (row.activo) this.seccionesSeleccionadas.push(row);
      });

      this.cargarMaquinas();

    }

  }

  cargarMaquinas() {

    var r1, r2: boolean = false;

    
    //MAQUINAS
    var maquinas_model = this.maquinasService.get_maquinas_model();
    if (maquinas_model == false) {
      this.maquinasService.get().subscribe(json => {
        this.maquinasService.set_maquinas_model(json);
        this.maquinas = this.maquinasService.get_maquinas_model();
        this.seccionesCargadas = true;
        r1 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    } else {
      this.maquinas = maquinas_model;
      this.seccionesCargadas = true;
      r1 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

    //INSTALACIONES
    var instalaciones_model = this.maquinasService.get_instalaciones_model();
    if (instalaciones_model == false) {
      this.maquinasService.GetInstalaciones().subscribe(json => {
        this.maquinasService.set_instalaciones_model(json);
        this.instalaciones = this.maquinasService.get_instalaciones_model();
        r2 = true;
        if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
        if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
      })
    } else {
      this.instalaciones = instalaciones_model;
      r2 = true;
      if (r1 && r2) this.maquinas = this.maquinas.concat(this.instalaciones);
      if (this.gruposCargados && this.seccionesCargadas && r1 && r2) this.filtrarMaquinasPorAreaProductivaYGrupo();
    }

  }

  filtrarMaquinasPorAreaProductivaYGrupo(cargarDatos = true) {

    //FLTRO POR SECCIONES
    var idsSeccionesSelecteds: any = [];
    if (this.seccionesSeleccionadas && this.seccionesSeleccionadas.length > 0) {
      this.seccionesSeleccionadas.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    } else {
      this.secciones.forEach(seccion => {
        idsSeccionesSelecteds.push(seccion.id);
      });
    }

    //FILTRO POR GRUPOS
    var idsGruposSelecteds: any = [];
    if (this.gruposSeleccionados && this.gruposSeleccionados.length > 0) {
      this.gruposSeleccionados.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    } else {
      this.grupos.forEach(grupo => {
        idsGruposSelecteds.push(grupo.id);
      });
    }

    //SI ES UNA INSTALACION NO SE TIENE EN CUENTA SECCION PORQUE NO TIENEN
    this.maquinasMostradas = this.maquinas.filter(f => (idsSeccionesSelecteds.includes(f.idSeccion) && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))) ||
      (f.tipo_maquina == 8 && idsGruposSelecteds.some(r => f.idsGrupos.split(",").map(Number).includes(r))));

    if (this.maquinasMostradas.length > 0) {
      if (this.idMaquina == undefined) {
        this.selectedMaquina = this.maquinasMostradas[0];
      } else {
        this.selectedMaquina = this.maquinasMostradas.find(f => f.id == this.idMaquina);
      }
    }

    else this.selectedMaquina = undefined;

    //SI VIENE IDMAQUINA POR RUTA, DESPUES DE LA PRIMERA CARGA LO RESETEAMOS
    this.idMaquina = undefined;

    if (cargarDatos) this.identificarMaquinaYCargarDatos();

  }

  identificarMaquinaYCargarDatos() {
    this.fechaInicioFiltrada = this.fechaInicio;
    this.fechaFinFiltrada = this.fechaFin;
    var maquinaSeleccionada = this.selectedMaquina;

    if (maquinaSeleccionada != undefined) { //HAY UNA MAQUINA SELECCIONADA

      //IDENTIFICAMOS EL TIPO DE MAQUINA QUE ES Y DEPENDIENDO DEL TIPO DE MAQUINA CARGAMOS LOS DATOS DE UNA MANERA U OTRA
      if (maquinaSeleccionada.tipo_maquina == 1) this.cargarDatosMecanizadoEjes(this.selectedMaquina.id);
      if (maquinaSeleccionada.tipo_maquina == 2) this.cargarDatosExtrusoraCapas(this.selectedMaquina.id);
      if (maquinaSeleccionada.tipo_maquina == 3) this.cargarDatosImpresoraDePlasticoCampos(this.selectedMaquina.id);
      if (maquinaSeleccionada.tipo_maquina == 4) this.cargarDatosInyectoraGrupos(this.selectedMaquina.id);
      if (maquinaSeleccionada.tipo_maquina == 5) this.cargarDatosHornoCampos(this.selectedMaquina.id);
      if (maquinaSeleccionada.tipo_maquina == 8) this.cargarDatosInstalacionCampos(this.selectedMaquina.id);

    } else { //NO HAY UNA MAQUINA SELECCIONADA

      this.resetearDatosGrafico();

    }

  }

  dibujarGrafico() {

    var th = this;

    this.dataGrafico = [
      ['x'],
      ['verde'],
      ['blanco'],
      ['rojo'],
      ['valor'],
      ['media'],
    ];

    this.grafico = c3.generate({
      bindto: '#grafico_historicoDatos',
      padding: {
        right: 15
      },
      data: {
        x: 'x',        
        columns: this.dataGrafico,
        colors: {
          verde: '#befee3',
          blanco: '#ffffff',
          rojo: '#f6a39d',
          valor: '#78afc8',
          media: '#09fa90'
        },
        types: {
          verde: 'area-step',
          blanco: 'area-step',
          rojo: 'area-step',
          valor: 'spline',
          media: 'spline'
        },
        groups: [['verde', 'blanco', 'rojo']],
        order: null
      },
      spline: {
        interpolation: {
          type: "monotone"
        }
      },
      transition: {
        duration: 0
      },
      legend: {
        show: false
      },
      tooltip: {
        format: {
          title: function (d) {
            return th.tituloGrafico + ": " + th.myFunctions.dateToDayString(d) + " - " + th.myFunctions.dateToHourString(d);
          },
          name: function (d) {
            if (d === "verde") return th.translateService.instant('minimo');
            if (d === "rojo") return th.translateService.instant('maximo');
            if (d === "valor") return th.translateService.instant('valor');
            if (d === "media") return th.translateService.instant('media');
          },
          value: function (value, ratio, id, i) {
            if (id === "verde") return th.array_valores_min[i].toFixed(2) + " " + th.unidadGrafico;
            if (id === "rojo") return th.array_valores_max[i].toFixed(2) + " " + th.unidadGrafico;
            if (id === "valor") return th.array_valores[i].toFixed(2) + " " + th.unidadGrafico;
            if (id === "media") return th.array_valores_medias[i].toFixed(2) + " " + th.unidadGrafico;
          }
        }
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            values: function (x) {
              var fechaIni = x[0];
              fechaIni.setMinutes(fechaIni.getMinutes() - 10);
              var fechaFin = x[1];
              fechaFin.setMinutes(fechaFin.getMinutes() + 10);
              return d3.scaleTime().domain([fechaIni, fechaFin]).ticks(15);
            },
            //format: '%m/%d - %H:%M:%S'
            format: function (x) {
              if (x.getHours() == 0 && x.getMinutes() == 0 && x.getSeconds() == 0) {
                if (x.getDay() == 0) return th.translateService.instant("domingo");
                if (x.getDay() == 1) return th.translateService.instant("lunes");
                if (x.getDay() == 2) return th.translateService.instant("martes");
                if (x.getDay() == 3) return th.translateService.instant("miercoles");
                if (x.getDay() == 4) return th.translateService.instant("jueves");
                if (x.getDay() == 5) return th.translateService.instant("viernes");
                if (x.getDay() == 6) return th.translateService.instant("sabado");
              } else {
                return th.myFunctions.dateToHourString(x);
              }
            },
          },
          padding: 0
        },
        y: {
          padding: {
            top: 0,
            bottom: 0
          },
          tick: {
            //format: '%m/%d - %H:%M:%S'
            format: function (x) {
                return x + " " + th.unidadGrafico;
            },
          },

        }
      },
      zoom: {
        enabled: true,
        type: 'drag',
        disableDefaultBehavior: true,
        onzoomend: function (domain) {
          th.zoom_tiempoGraficoIni = domain[0].getTime();
          th.zoom_tiempoGraficoFin = domain[1].getTime();
          th.aplicarZoomEnGrafico();
        }
      }
    });

    //SUSTITUIR LA CAPA DEL ZOOM Y LA DEL TOOLTIP Y AÑADIR QUE AL HACER CLICK CON EL BOTON IZQUIERDO SIMULE UN CLICK CON EL BOTON DERECHO PARA PODER HACER EL ZOOM
    let list_of_children = d3.select('#grafico_historicoDatos svg').node().childNodes;
    list_of_children[2].parentNode.insertBefore(list_of_children[2], list_of_children[1]);

    d3.select('#grafico_historicoDatos .c3-event-rect')._groups[0][0].onmousedown = function (e) {
      if (e.button != 0) {
        var evt = new MouseEvent("mousedown", {
          altKey: e.altKey,
          bubbles: e.bubbles,
          button: 0,
          buttons: e.buttons,
          cancelable: e.cancelable,
          clientX: e.clientX,
          clientY: e.clientY,
          composed: e.composed,
          ctrlKey: e.ctrlKey,
          detail: e.detail,
          metaKey: e.metaKey,
          movementX: e.movementX,
          movementY: e.movementY,
          relatedTarget: e.relatedTarget,
          screenX: e.screenX,
          screenY: e.screenY,
          shiftKey: e.shiftKey,
          view: e.view
        });
        d3.select("#grafico_historicoDatos .c3-drag-zoom .overlay")._groups[0][0].dispatchEvent(evt);
      }
    }

  }

  resetearDatosGrafico() {

    this.idMaquina = undefined;
    this.idCampo = undefined;

    this.listaBotonesSuperior = [];
    this.ensennarBotonesSuperior = false;

    this.listaBotonesFiltroGrafico = [];

    this.dataGrafico = [
      ['x'],
      ['verde'],
      ['blanco'],
      ['rojo'],
      ['valor'],
      ['media'],
    ];

    this.grafico.internal.config.axis_x_tick_values = [];
    
    this.grafico.load({ columns: this.dataGrafico });
    
    this.tituloGrafico = this.translateService.instant('datos');
    this.unidadGrafico = "";

  }

  public botonSuperiorChange(e, boton) {

    if (e) {
      this.listaBotonesSuperior.forEach(function (b) {
        b.selected = false;
      });
      boton.selected = true;
      this.cargarDatosPorMaquinaSuperior();
    }

  }

  cargarDatosPorMaquinaSuperior() {

    var maquinaSeleccionada = this.selectedMaquina;

    //IDENTIFICAMOS EL TIPO DE MAQUINA QUE ES Y DEPENDIENDO DEL TIPO DE MAQUINA CARGAMOS LOS DATOS DE UNA MANERA U OTRA
    if (maquinaSeleccionada.tipo_maquina == 1) this.cargarDatosMecanizadoCampos();
    if (maquinaSeleccionada.tipo_maquina == 2) this.cargarDatosExtrusoraCampos();
    if (maquinaSeleccionada.tipo_maquina == 3) { }
    if (maquinaSeleccionada.tipo_maquina == 4) this.cargarDatosInyectoraCampos();
    if (maquinaSeleccionada.tipo_maquina == 5) { }
    if (maquinaSeleccionada.tipo_maquina == 8) { }

  }

  //CARGAR LOS DATOS DE MECANIZADO
  cargarDatosMecanizadoEjes(idMaquina) {

    this.cargandoGrafico = true;

    this.ensennarBotonesSuperior = true;
    this.listaBotonesSuperior = [];

    //CONSEGUIR LOS EJES DE LA MAQUINA
    this.historicoDatosService.Get_Ejes_Mecanizado(idMaquina).subscribe((result) => {

      this.listaBotonesSuperior = result;

      //NOMBRE POTENCIOMETROS
      this.listaBotonesSuperior.forEach(function (b) {
        if (b.potenNum == 1) { b.nombre = this.translateService.instant('potenCabezal'); }
        if (b.potenNum == 2) { b.nombre = this.translateService.instant('potenAvances'); }
      }, this);

      if (this.listaBotonesSuperior.length > 0) {
        this.listaBotonesSuperior[0].selected = true;
        //CONSEGUIR TODOS LOS HISTORICOS QUE TIENE LA MAQUINA
        this.historicoDatosService.Get_datos_ejes_maquina(idMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
          this.datosMaquina = result;
          //CALCULAMOS LAS FECHAS
          this.datosMaquina.forEach(function (valor) {
            valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha2);
            valor.fecha_time = this.myFunctions.sqlToJsDate(valor.fecha2).getTime();
          }, this);
          this.cargarDatosMecanizadoCampos();
        });
      } else {
        this.listaBotonesFiltroGrafico = [];
      }

    });

  }

  cargarDatosMecanizadoCampos() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO DEL FILTRO SUPERIOR
    var botonSeleccionado = this.listaBotonesSuperior.find(x => x.selected);

    this.listaBotonesFiltroGrafico = [];

    if (botonSeleccionado.potenNum == 0) {
      //ES UN EJE
      if (botonSeleccionado.motor) { var unidadAvan = "r.p.m"; var nombreAvan = this.translateService.instant('velocidad'); }
      else { var unidadAvan = "mm/min"; var nombreAvan = this.translateService.instant('avance'); }

      this.listaBotonesFiltroGrafico.push({ nombre: nombreAvan, idEje: botonSeleccionado.id, idCampo: 1, minimo: 0, maximo: 0, unidad: unidadAvan, tieneMaxMin: false, potenNum: botonSeleccionado.potenNum });
      this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('temperatura'), idEje: botonSeleccionado.id, idCampo: 2, minimo: 0, maximo: 0, unidad: "º", tieneMaxMin: false, potenNum: botonSeleccionado.potenNum });
      this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('consumo'), idEje: botonSeleccionado.id, idCampo: 3, minimo: 0, maximo: 0, unidad: "kW", tieneMaxMin: false, potenNum: botonSeleccionado.potenNum });
    } else {
      //ES UN POTENCIOMETRO
      this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('valor'), idEje: -1, idCampo: 1, minimo: botonSeleccionado.min, maximo: botonSeleccionado.max, unidad: "%", tieneMaxMin: true, potenNum: botonSeleccionado.potenNum });
    }

    if (this.listaBotonesFiltroGrafico.length > 0) {
      this.listaBotonesFiltroGrafico[0].selected = true;

      this.tituloGrafico = this.listaBotonesFiltroGrafico[0].nombre;
      this.unidadGrafico = this.listaBotonesFiltroGrafico[0].unidad;
    }

    this.cargarDatosMecanizado();

  }

  cargarDatosMecanizado() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO PARA CARGAR LOS DATOS DE ESE FILTRO
    var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);

    if (botonSeleccionado != undefined) { //HAY UN FILTRO SELECCIONADO

      var idEje = botonSeleccionado.idEje;
      var idCampo = botonSeleccionado.idCampo;
      var potenNum = botonSeleccionado.potenNum;

      this.datosMaquinaFiltrado = [];

      this.datosMaquina.forEach(function (row) {

        var fecha_date = row.fecha_date;
        var fecha_time = row.fecha_time;
        var minimo = botonSeleccionado.minimo;
        var maximo = botonSeleccionado.maximo;
        var valor = 0;

        //ES UN EJE
        if (potenNum == 0) {

          for (var i = 1; i <= 12; i++) {
            if (row[i + '_idEje'] == idEje) {
              if (idCampo == 1) valor = row[i + '_avanceRPM'];
              if (idCampo == 2) valor = row[i + '_temperatura'];
              if (idCampo == 3) valor = row[i + '_consumo'];
            }
          }

        }
        //ES POTEN1
        if (potenNum == 1) {
          valor = row['potenciometro'];
        }
        //ES POTEN2
        if (potenNum == 2) {
          valor = row['potenciometro2'];
        }

        this.datosMaquinaFiltrado.push({ fecha_date: fecha_date, fecha_time: fecha_time, minimo: minimo, maximo: maximo, valor: valor });

      }, this);

      if (this.datosMaquinaFiltrado.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(this.datosMaquinaFiltrado, 100);
      else this.datosMaquinaFiltradoResumen = this.datosMaquinaFiltrado;

      this.cargarDatosGrafico(true);

    } else { //NO HAY UN FILTRO SELECCIONADO

    }

  }

  //CARGAR LOS DATOS DE EXTRUSORA
  cargarDatosExtrusoraCapas(idMaquina) {

    this.cargandoGrafico = true;

    this.ensennarBotonesSuperior = true;
    this.listaBotonesSuperior = [];

    //CONSEGUIR LAS CAPAS DE LA EXTRUSORA
    this.historicoDatosService.Get_Campos_Extrusora(idMaquina).subscribe((result) => {

      this.listaBotonesSuperior = result;

      if (this.listaBotonesSuperior.length > 0) {
        this.listaBotonesSuperior[0].selected = true;
        //CONSEGUIR TODOS LOS HISTORICOS QUE TIENE LA EXTRUSORA
        this.historicoDatosService.Get_Historico_Extrusora(idMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
          this.datosMaquina = result;
          //CALCULAMOS LAS FECHAS
          this.datosMaquina.forEach(function (valor) {
            valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
            valor.fecha_time = this.myFunctions.sqlToJsDate(valor.fecha).getTime();
          }, this);
          this.cargarDatosExtrusoraCampos();
        });
      } else {
        this.listaBotonesFiltroGrafico = [];
      }

    });

  }

  cargarDatosExtrusoraCampos() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO DEL FILTRO SUPERIOR
    var botonSeleccionado = this.listaBotonesSuperior.find(x => x.selected);

    this.listaBotonesFiltroGrafico = [];

    if (botonSeleccionado.presion_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('presionFiltros'), idCapa: botonSeleccionado.id, idCampo: 1, minimo: botonSeleccionado.presion_min, maximo: botonSeleccionado.presion_max, unidad: "", tieneMaxMin: true });
    if (botonSeleccionado.rendimientoTasaDeTransporte_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('rendimientoTasaDeTransporte'), idCapa: botonSeleccionado.id, idCampo: 2, minimo: botonSeleccionado.rendimientoTasaDeTransporte_min, maximo: botonSeleccionado.rendimientoTasaDeTransporte_max, unidad: "", tieneMaxMin: true });
    if (botonSeleccionado.revolucionesMotor_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('revolucionesMotor'), idCapa: botonSeleccionado.id, idCampo: 3, minimo: botonSeleccionado.revolucionesMotor_min, maximo: botonSeleccionado.revolucionesMotor_max, unidad: "", tieneMaxMin: true });
    if (botonSeleccionado.cargaMotor_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('cargaMotor'), idCapa: botonSeleccionado.id, idCampo: 4, minimo: botonSeleccionado.cargaMotor_min, maximo: botonSeleccionado.cargaMotor_max, unidad: "", tieneMaxMin: true });

    //if (this.listaBotonesFiltroGrafico.length > 0) {

    //  var botonSeleccionado;
    //  if (this.idCampo == undefined) {
    //    botonSeleccionado = this.listaBotonesFiltroGrafico[0];
    //  } else {
    //    botonSeleccionado = this.listaBotonesFiltroGrafico.find(f => f.idCampo == this.idCampo);
    //    if (botonSeleccionado == undefined) botonSeleccionado = this.listaBotonesFiltroGrafico[0];
    //  }
    //  botonSeleccionado.selected = true;
    //  this.tituloGrafico = botonSeleccionado.nombre;
    //  this.unidadGrafico = botonSeleccionado.unidad;
    //}

    if (this.listaBotonesFiltroGrafico.length > 0) {
      this.listaBotonesFiltroGrafico[0].selected = true;
      this.tituloGrafico = this.listaBotonesFiltroGrafico[0].nombre;
      this.unidadGrafico = this.listaBotonesFiltroGrafico[0].unidad;
    }

    //SI VIENE IDCAMPO POR RUTA, DESPUES DE LA PRIMERA CARGA LO RESETEAMOS
    this.idCampo = undefined;

    this.cargarDatosExtrusora();

  }

  cargarDatosExtrusora() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO PARA CARGAR LOS DATOS DE ESE FILTRO
    var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);

    if (botonSeleccionado != undefined) { //HAY UN FILTRO SELECCIONADO

      var idCapa = botonSeleccionado.idCapa;
      var idCampo = botonSeleccionado.idCampo;

      this.datosMaquinaFiltrado = [];

      this.datosMaquina.forEach(function (row) {

        var fecha_date = row.fecha_date;
        var fecha_time = row.fecha_time;
        var minimo = botonSeleccionado.minimo;
        var maximo = botonSeleccionado.maximo;
        var valor = 0;

        for (var i = 1; i <= 10; i++) {
          if (row[i + '_idCapa'] == idCapa) {
            if (idCampo == 1) valor = row[i + '_presion'];
            if (idCampo == 2) valor = row[i + '_rendimientoTasaDeTransporte'];
            if (idCampo == 3) valor = row[i + '_revolucionesMotor'];
            if (idCampo == 4) valor = row[i + '_cargaMotor'];
          }
        }

        this.datosMaquinaFiltrado.push({ fecha_date: fecha_date, fecha_time: fecha_time, minimo: minimo, maximo: maximo, valor: valor });

      }, this);

      if (this.datosMaquinaFiltrado.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(this.datosMaquinaFiltrado, 100);
      else this.datosMaquinaFiltradoResumen = this.datosMaquinaFiltrado;

      this.cargarDatosGrafico(true);

    } else { //NO HAY UN FILTRO SELECCIONADO

    }

  }

  //CARGAR LOS DATOS DE IMPRESORA DE PLASTICO
  cargarDatosImpresoraDePlasticoCampos(idMaquina) {

    this.cargandoGrafico = true;

    this.ensennarBotonesSuperior = false;
    this.listaBotonesSuperior = [];

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE LA IMPRESORA DE PLASTICO
    this.historicoDatosService.Get_Campos_ImpresoraPlastico(idMaquina).subscribe((result) => {

      this.listaBotonesFiltroGrafico = [];
      var res: any = result[0];

      this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('speed'), idCampo: 1, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false }); //NO ES OPCIONAL (DE MOMENTO)
      if (res.desbobinadora_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('desbobinadora'), idCampo: 2, minimo: res.desbobinadora_min, maximo: res.desbobinadora_max, unidad: "", tieneMaxMin: true });
      if (res.tomaDeAlimentacion_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('tomaDeAlimentacion'), idCampo: 3, minimo: res.tomaDeAlimentacion_min, maximo: res.tomaDeAlimentacion_max, unidad: "", tieneMaxMin: true });
      if (res.accionadorRodilloEnfriamiento_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('accionadorRodilloEnfriamiento'), idCampo: 4, minimo: res.accionadorRodilloEnfriamiento_min, maximo: res.accionadorRodilloEnfriamiento_max, unidad: "", tieneMaxMin: true });
      if (res.bobinadora_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('bobinadora'), idCampo: 5, minimo: res.bobinadora_min, maximo: res.bobinadora_max, unidad: "", tieneMaxMin: true });
      if (res.secadorTempBCD_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('secadorTempBCD'), idCampo: 6, minimo: res.secadorTempBCD_min, maximo: res.secadorTempBCD_max, unidad: "", tieneMaxMin: true });
      if (res.secadorTunelTempBCD_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('secadorTunelTempBCD'), idCampo: 7, minimo: res.secadorTunelTempBCD_min, maximo: res.secadorTunelTempBCD_max, unidad: "", tieneMaxMin: true });
      if (res.kwhTotales_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('kwhTotales'), idCampo: 8, minimo: res.kwhTotales_min, maximo: res.kwhTotales_max, unidad: "", tieneMaxMin: true });
      if (res.potenciaInstantanea_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('potenciaInstantanea'), idCampo: 9, minimo: res.potenciaInstantanea_min, maximo: res.potenciaInstantanea_max, unidad: "", tieneMaxMin: true });

      if (this.listaBotonesFiltroGrafico.length > 0) {

        var botonSeleccionado;
        if (this.idCampo == undefined) {
          botonSeleccionado = this.listaBotonesFiltroGrafico[0];
        } else {
          botonSeleccionado = this.listaBotonesFiltroGrafico.find(f => f.idCampo == this.idCampo);
          if (botonSeleccionado == undefined) botonSeleccionado = this.listaBotonesFiltroGrafico[0];
        }
        botonSeleccionado.selected = true;
        this.tituloGrafico = botonSeleccionado.nombre;
        this.unidadGrafico = botonSeleccionado.unidad;
      }

      //SI VIENE IDCAMPO POR RUTA, DESPUES DE LA PRIMERA CARGA LO RESETEAMOS
      this.idCampo = undefined;

      //CONSEGUIR TODOS LOS HISTORICOS QUE TIENE LA IMPRESORA
      this.historicoDatosService.Get_Historico_ImpresorasPlastico(idMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
        this.datosMaquina = result;
        //CALCULAMOS LAS FECHAS
        this.datosMaquina.forEach(function (valor) {
          valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
          valor.fecha_time = this.myFunctions.sqlToJsDate(valor.fecha).getTime();
        }, this);
        this.cargarDatosImpresoraDePlastico();
      });

    });

  }

  cargarDatosImpresoraDePlastico() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO PARA CARGAR LOS DATOS DE ESE FILTRO
    var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);

    if (botonSeleccionado != undefined) { //HAY UN FILTRO SELECCIONADO

      this.datosMaquinaFiltrado = [];
      this.datosMaquina.forEach(function (row) {
        var fecha_date = row.fecha_date;
        var fecha_time = row.fecha_time;
        var minimo = botonSeleccionado.minimo;
        var maximo = botonSeleccionado.maximo;
        var valor = 0;
        if (botonSeleccionado.idCampo == 1) valor = row.speed;
        if (botonSeleccionado.idCampo == 2) valor = row.desbobinadora;
        if (botonSeleccionado.idCampo == 3) valor = row.tomaDeAlimentacion;
        if (botonSeleccionado.idCampo == 4) valor = row.accionadorRodilloEnfriamiento;
        if (botonSeleccionado.idCampo == 5) valor = row.bobinadora;
        if (botonSeleccionado.idCampo == 6) valor = row.secadorTempBCD;
        if (botonSeleccionado.idCampo == 7) valor = row.secadorTunelTempBCD;
        if (botonSeleccionado.idCampo == 8) valor = row.kwhTotales;
        if (botonSeleccionado.idCampo == 9) valor = row.potenciaInstantanea;
        this.datosMaquinaFiltrado.push({ fecha_date: fecha_date, fecha_time: fecha_time, minimo: minimo, maximo: maximo, valor: valor });
      }, this);

      if (this.datosMaquinaFiltrado.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(this.datosMaquinaFiltrado, 100);
      else this.datosMaquinaFiltradoResumen = this.datosMaquinaFiltrado;

      this.cargarDatosGrafico(true);

    } else { //NO HAY UN FILTRO SELECCIONADO

    }

  }

  //CARGAR LOS DATOS DE INYECTORA
  cargarDatosInyectoraGrupos(idMaquina) {

    this.cargandoGrafico = true;

    this.ensennarBotonesSuperior = true;
    this.listaBotonesSuperior = [];

    //CONSEGUIR LOS GRUPOS DE LA INYECTORA
    this.historicoDatosService.Get_Campos_Inyectora(idMaquina).subscribe((result) => {

      var datuak_campos: any = result;

      if (datuak_campos.length > 0) {
        var row = datuak_campos[0];
      }

      this.listaBotonesSuperior = [];

      if (row.tiempoInyeccion || row.tiempoPresionRemanente || row.tiempoEnfriamiento || row.tiempoDosificacionPlastificacion || row.tiempoCiclo)
        this.listaBotonesSuperior.push({ id: 1, nombre: this.translateService.instant('tiempos'), tiempoInyeccion: row.tiempoInyeccion, tiempoPresionRemanente: row.tiempoPresionRemanente, tiempoEnfriamiento: row.tiempoEnfriamiento, tiempoDosificacionPlastificacion: row.tiempoDosificacionPlastificacion, tiempoCiclo: row.tiempoCiclo });

      if (row.presionInyeccionMax || row.presionRemanente || row.contrapresion || row.ptoConmutacion)
        this.listaBotonesSuperior.push({ id: 2, nombre: this.translateService.instant('presiones'), presionInyeccionMax: row.presionInyeccionMax, presionRemanente: row.presionRemanente, contrapresion: row.contrapresion, ptoConmutacion: row.ptoConmutacion });

      if (row.tempHusillo_activo || row.tempCamaraCaliente_activo || row.tempPresionFija || row.tempPresionMovil)
        this.listaBotonesSuperior.push({ id: 3, nombre: this.translateService.instant('temperaturas'), tempHusillo_activo: row.tempHusillo_activo, tempCamaraCaliente_activo: row.tempCamaraCaliente_activo, tempPresionFija: row.tempPresionFija, tempPresionMovil: row.tempPresionMovil });

      this.listaBotonesSuperior.push({ id: 4, nombre: this.translateService.instant('mucell'), pesoInyeccionEspumado: true, porcenScf: true, dosificacionScf: true });

      if (row.paradaDosificado || row.retrocesoHusillo || row.paradaMolde || row.cojin)
        this.listaBotonesSuperior.push({ id: 5, nombre: this.translateService.instant('otros'), paradaDosificado: row.paradaDosificado, retrocesoHusillo: row.retrocesoHusillo, paradaMolde: row.paradaMolde, cojin: row.cojin });

      if (this.listaBotonesSuperior.length > 0) {
        this.listaBotonesSuperior[0].selected = true;
        //CONSEGUIR TODOS LOS HISTORICOS QUE TIENE LA INYECTORA
        this.historicoDatosService.Get_Historico_Inyectora(idMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
          this.datosMaquina = result;
          //CALCULAMOS LAS FECHAS
          this.datosMaquina.forEach(function (valor) {
            valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
            valor.fecha_time = this.myFunctions.sqlToJsDate(valor.fecha).getTime();
          }, this);
          this.cargarDatosInyectoraCampos();
        });
      } else {
        this.listaBotonesFiltroGrafico = [];
      }

    });

  }

  cargarDatosInyectoraCampos() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO DEL FILTRO SUPERIOR
    var botonSeleccionado = this.listaBotonesSuperior.find(x => x.selected);

    this.listaBotonesFiltroGrafico = [];

    //TIEMPOS
    if (botonSeleccionado.id == 1) {
      if (botonSeleccionado.tiempoInyeccion) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('tiempoInyeccion'), idGrupo: botonSeleccionado.id, idCampo: 1, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tiempoPresionRemanente) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('tiempoPresionRemanente'), idGrupo: botonSeleccionado.id, idCampo: 2, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tiempoEnfriamiento) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('tiempoEnfriamiento'), idGrupo: botonSeleccionado.id, idCampo: 3, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tiempoDosificacionPlastificacion) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('tiempoDosificPlastificacion'), idGrupo: botonSeleccionado.id, idCampo: 4, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tiempoCiclo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('tiempoCiclo'), idGrupo: botonSeleccionado.id, idCampo: 5, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
    }

    //PRESIONES
    if (botonSeleccionado.id == 2) {
      if (botonSeleccionado.presionInyeccionMax) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('presInyMax'), idGrupo: botonSeleccionado.id, idCampo: 1, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.presionRemanente) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('presRemanente'), idGrupo: botonSeleccionado.id, idCampo: 2, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.contrapresion) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('contrapresion'), idGrupo: botonSeleccionado.id, idCampo: 3, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.ptoConmutacion) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('ptoConmutacion'), idGrupo: botonSeleccionado.id, idCampo: 4, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
    }

    //TEMPERATURAS
    if (botonSeleccionado.id == 3) {
      if (botonSeleccionado.tempHusillo_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('temperaturasHusillo'), idGrupo: botonSeleccionado.id, idCampo: 1, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tempCamaraCaliente_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('temperaturasCamaraCaliente'), idGrupo: botonSeleccionado.id, idCampo: 2, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tempPresionFija) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('TPFija'), idGrupo: botonSeleccionado.id, idCampo: 3, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.tempPresionMovil) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('TPMovil'), idGrupo: botonSeleccionado.id, idCampo: 4, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
    }

    //MUCELL
    if (botonSeleccionado.id == 4) {
      if (botonSeleccionado.pesoInyeccionEspumado) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('pesoInyeccionEspumado'), idGrupo: botonSeleccionado.id, idCampo: 1, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.porcenScf) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('porcenScf'), idGrupo: botonSeleccionado.id, idCampo: 2, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.dosificacionScf) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('dosificacionScf'), idGrupo: botonSeleccionado.id, idCampo: 3, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
    }

    //OTROS
    if (botonSeleccionado.id == 5) {
      if (botonSeleccionado.paradaDosificado) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('paradaDosific'), idGrupo: botonSeleccionado.id, idCampo: 1, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.retrocesoHusillo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('retrocesoHusillo'), idGrupo: botonSeleccionado.id, idCampo: 2, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.paradaMolde) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('paradaMolde'), idGrupo: botonSeleccionado.id, idCampo: 3, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
      if (botonSeleccionado.cojin) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('cojin'), idGrupo: botonSeleccionado.id, idCampo: 4, minimo: 0, maximo: 0, unidad: "", tieneMaxMin: false });
    }

    if (this.listaBotonesFiltroGrafico.length > 0) {
      this.listaBotonesFiltroGrafico[0].selected = true;
      this.tituloGrafico = this.listaBotonesFiltroGrafico[0].nombre;
      this.unidadGrafico = this.listaBotonesFiltroGrafico[0].unidad;
    }

    this.cargarDatosInyectora();

  }

  cargarDatosInyectora() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO PARA CARGAR LOS DATOS DE ESE FILTRO
    var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);

    if (botonSeleccionado != undefined) { //HAY UN FILTRO SELECCIONADO

      var idGrupo = botonSeleccionado.idGrupo;
      var idCampo = botonSeleccionado.idCampo;

      this.datosMaquinaFiltrado = [];

      this.datosMaquina.forEach(function (row) {

        var fecha_date = row.fecha_date;
        var fecha_time = row.fecha_time;
        var minimo = botonSeleccionado.minimo;
        var maximo = botonSeleccionado.maximo;
        var valor = 0;

        //TIEMPOS
        if (idGrupo == 1) {
          if (idCampo == 1) valor = row['tiempoInyeccion'];
          if (idCampo == 2) valor = row['tiempoPresionRemanente'];
          if (idCampo == 3) valor = row['tiempoEnfriamiento'];
          if (idCampo == 4) valor = row['tiempoDosificacionPlastificacion'];
          if (idCampo == 5) valor = row['tiempoCiclo'];
        }
        //PRESIONES
        if (idGrupo == 2) {
          if (idCampo == 1) valor = row['presionInyeccionMax'];
          if (idCampo == 2) valor = row['presionRemanente'];
          if (idCampo == 3) valor = row['contrapresion'];
          if (idCampo == 4) valor = row['ptoConmutacion'];
        }
        //TEMPERATURAS
        if (idGrupo == 3) {
          if (idCampo == 1) valor = row['temperaturaHusillo'];
          if (idCampo == 2) valor = row['temperaturaCamaraCaliente'];
          if (idCampo == 3) valor = row['tempPresionFija'];
          if (idCampo == 4) valor = row['tempPresionMovil'];
        }
        //MUCELL
        if (idGrupo == 4) {
          if (idCampo == 1) valor = row['pesoInyeccionEspumado'];
          if (idCampo == 2) valor = row['porcenScf'];
          if (idCampo == 3) valor = row['dosificacionScf'];
        }
        //OTROS
        if (idGrupo == 5) {
          if (idCampo == 1) valor = row['paradaDosificado'];
          if (idCampo == 2) valor = row['retrocesoHusillo'];
          if (idCampo == 3) valor = row['paradaMolde'];
          if (idCampo == 4) valor = row['cojin'];
        }

        this.datosMaquinaFiltrado.push({ fecha_date: fecha_date, fecha_time: fecha_time, minimo: minimo, maximo: maximo, valor: valor });

      }, this);

      if (this.datosMaquinaFiltrado.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(this.datosMaquinaFiltrado, 100);
      else this.datosMaquinaFiltradoResumen = this.datosMaquinaFiltrado;

      this.cargarDatosGrafico(true);

    } else { //NO HAY UN FILTRO SELECCIONADO

    }

  }

  //CARGAR LOS DATOS DE HORNO
  cargarDatosHornoCampos(idMaquina) {

    this.cargandoGrafico = true;

    this.ensennarBotonesSuperior = false;
    this.listaBotonesSuperior = [];

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE EL HORNO
    this.historicoDatosService.Get_Campos_Horno(idMaquina).subscribe((result) => {

      this.listaBotonesFiltroGrafico = [];
      var res: any = result[0];

      if (res.energia_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('energia'), idCampo: 1, minimo: res.energia_min, maximo: res.energia_max, unidad: "Kw/h", tieneMaxMin: true });
      if (res.potencia_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('potencia'), idCampo: 2, minimo: res.potencia_min, maximo: res.potencia_max, unidad: "Kw", tieneMaxMin: true });
      if (res.intensidad_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('intensidad'), idCampo: 3, minimo: res.intensidad_min, maximo: res.intensidad_max, unidad: "A", tieneMaxMin: true });
      if (res.voltaje_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('voltaje'), idCampo: 4, minimo: res.voltaje_min, maximo: res.voltaje_max, unidad: "V", tieneMaxMin: true });
      if (res.frecuencia_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('frecuencia'), idCampo: 5, minimo: res.frecuencia_min, maximo: res.frecuencia_max, unidad: "Hz", tieneMaxMin: true });
      if (res.caudal_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('caudal'), idCampo: 6, minimo: res.caudal_min, maximo: res.caudal_max, unidad: "m³/s", tieneMaxMin: true });
      if (res.temperaturaPieza_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('temperaturaPieza'), idCampo: 7, minimo: res.temperaturaPieza_min, maximo: res.temperaturaPieza_max, unidad: "º", tieneMaxMin: true });
      if (res.presionBombaDucha_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('presionBombaDucha'), idCampo: 8, minimo: res.presionBombaDucha_min, maximo: res.presionBombaDucha_max, unidad: "bar", tieneMaxMin: true });
      if (res.temperaturaPolimero_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('temperaturaPolimero'), idCampo: 9, minimo: res.temperaturaPolimero_min, maximo: res.temperaturaPolimero_max, unidad: "º", tieneMaxMin: true });
      if (res.porcenPolimero_activo) this.listaBotonesFiltroGrafico.push({ nombre: this.translateService.instant('porcenPolimero'), idCampo: 10, minimo: res.porcenPolimero_min, maximo: res.porcenPolimero_max, unidad: "%", tieneMaxMin: true });

      if (this.listaBotonesFiltroGrafico.length > 0) {

        var botonSeleccionado;
        if (this.idCampo == undefined) {
          botonSeleccionado = this.listaBotonesFiltroGrafico[0];
        } else {
          botonSeleccionado = this.listaBotonesFiltroGrafico.find(f => f.idCampo == this.idCampo);
          if (botonSeleccionado == undefined) botonSeleccionado = this.listaBotonesFiltroGrafico[0];
        }
        botonSeleccionado.selected = true;
        this.tituloGrafico = botonSeleccionado.nombre;
        this.unidadGrafico = botonSeleccionado.unidad;
      }

      //SI VIENE IDCAMPO POR RUTA, DESPUES DE LA PRIMERA CARGA LO RESETEAMOS
      this.idCampo = undefined;

      //CONSEGUIR TODOS LOS HISTORICOS QUE TIENE EL HORNO
      this.historicoDatosService.Get_Historico_Horno(idMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe((result) => {
        this.datosMaquina = result;
        //CALCULAMOS LAS FECHAS
        this.datosMaquina.forEach(function (valor) {
          valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
          valor.fecha_time = this.myFunctions.sqlToJsDate(valor.fecha).getTime();
        }, this);
        this.cargarDatosHorno();
      });

    });

  }

  cargarDatosHorno() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO PARA CARGAR LOS DATOS DE ESE FILTRO
    var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);

    if (botonSeleccionado != undefined) { //HAY UN FILTRO SELECCIONADO

      this.datosMaquinaFiltrado = [];
      this.datosMaquina.forEach(function (row) {
        var fecha_date = row.fecha_date;
        var fecha_time = row.fecha_time;
        var minimo = botonSeleccionado.minimo;
        var maximo = botonSeleccionado.maximo;
        var valor = 0;
        if (botonSeleccionado.idCampo == 1) valor = row.energia;
        if (botonSeleccionado.idCampo == 2) valor = row.potencia;
        if (botonSeleccionado.idCampo == 3) valor = row.intensidad;
        if (botonSeleccionado.idCampo == 4) valor = row.voltaje;
        if (botonSeleccionado.idCampo == 5) valor = row.frecuencia;
        if (botonSeleccionado.idCampo == 6) valor = row.caudal;
        if (botonSeleccionado.idCampo == 7) valor = row.temperaturaPieza;
        if (botonSeleccionado.idCampo == 8) valor = row.presionBombaDucha;
        if (botonSeleccionado.idCampo == 9) valor = row.temperaturaPolimero;
        if (botonSeleccionado.idCampo == 10) valor = row.porcenPolimero;
        this.datosMaquinaFiltrado.push({ fecha_date: fecha_date, fecha_time: fecha_time, minimo: minimo, maximo: maximo, valor: valor });
      }, this);

      if (this.datosMaquinaFiltrado.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(this.datosMaquinaFiltrado, 100);
      else this.datosMaquinaFiltradoResumen = this.datosMaquinaFiltrado;

      this.cargarDatosGrafico(true);

    } else { //NO HAY UN FILTRO SELECCIONADO

    }

  }

  //CARGAR LOS DATOS DE INSTALACION
  cargarDatosInstalacionCampos(idInstalacion) {

    this.cargandoGrafico = true;

    this.ensennarBotonesSuperior = false;
    this.listaBotonesSuperior = [];

    //CONSEGUIR EL VALOR DE LOS CAMPOS QUE TIENE LA INSTALACION
    this.historicoDatosService.Get_Campos_Instalacion(idInstalacion).subscribe((result) => {
      this.listaBotonesFiltroGrafico = result;

      //if (this.listaBotonesFiltroGrafico.length > 0) {
      //  this.listaBotonesFiltroGrafico[0].selected = true;
      //  this.tituloGrafico = this.listaBotonesFiltroGrafico[0].nombre;
      //  this.unidadGrafico = this.listaBotonesFiltroGrafico[0].unidad;
      //}

      if (this.listaBotonesFiltroGrafico.length > 0) {

        var botonSeleccionado;
        if (this.idCampo == undefined) {
          botonSeleccionado = this.listaBotonesFiltroGrafico[0];
        } else {
          botonSeleccionado = this.listaBotonesFiltroGrafico.find(f => f.id == this.idCampo);
          if (botonSeleccionado == undefined) botonSeleccionado = this.listaBotonesFiltroGrafico[0];
        }
        botonSeleccionado.selected = true;
        this.tituloGrafico = botonSeleccionado.nombre;
        this.unidadGrafico = botonSeleccionado.unidad;
      }

      //SI VIENE IDCAMPO POR RUTA, DESPUES DE LA PRIMERA CARGA LO RESETEAMOS
      this.idCampo = undefined;
      //CONSEGUIR TODOS LOS HISTORICOS QUE TIENE LA INSTALACION
      this.historicoDatosService.Get_Historico_Instalaciones(idInstalacion, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin), 
      this.selectedMaquina.tieneHistorico_Procesos).subscribe((result) => {
        this.datosMaquina = result;
        //CALCULAMOS LAS FECHAS
        this.datosMaquina.forEach(function (valor) {
          valor.fecha_date = this.myFunctions.sqlToJsDate(valor.fecha);
          valor.fecha_time = this.myFunctions.sqlToJsDate(valor.fecha).getTime();
        }, this);
        this.cargarDatosInstalacion();
      });

    });

  }

  cargarDatosInstalacion() {

    //CONSEGUIMOS EL BOTON QUE ESTA SELECCIONADO PARA CARGAR LOS DATOS DE ESE FILTRO
    var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);

    if (botonSeleccionado != undefined) { //HAY UN FILTRO SELECCIONADO

      this.datosMaquinaFiltrado = [];
      this.datosMaquina.forEach(function (row) {
        var fecha_date = row.fecha_date;
        var fecha_time = row.fecha_time;
        var minimo = botonSeleccionado.minimo;
        var maximo = botonSeleccionado.maximo;
        var valor = 0;
        if (botonSeleccionado.posColumnaHistorico == 1) valor = row.valor1;
        if (botonSeleccionado.posColumnaHistorico == 2) valor = row.valor2;
        if (botonSeleccionado.posColumnaHistorico == 3) valor = row.valor3;
        if (botonSeleccionado.posColumnaHistorico == 4) valor = row.valor4;
        if (botonSeleccionado.posColumnaHistorico == 5) valor = row.valor5;
        if (botonSeleccionado.posColumnaHistorico == 6) valor = row.valor6;
        if (botonSeleccionado.posColumnaHistorico == 7) valor = row.valor7;
        if (botonSeleccionado.posColumnaHistorico == 8) valor = row.valor8;
        if (botonSeleccionado.posColumnaHistorico == 9) valor = row.valor9;
        if (botonSeleccionado.posColumnaHistorico == 10) valor = row.valor10;
        this.datosMaquinaFiltrado.push({ fecha_date: fecha_date, fecha_time: fecha_time, minimo: minimo, maximo: maximo, valor: valor });
      }, this);

      if (this.datosMaquinaFiltrado.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(this.datosMaquinaFiltrado, 100);
      else this.datosMaquinaFiltradoResumen = this.datosMaquinaFiltrado;

      this.cargarDatosGrafico(true);

    } else { //NO HAY UN FILTRO SELECCIONADO

    }

  }

  aplicarZoomEnGrafico() {

    var th = this;

    //COGEMOS DE LOS DATOS GENERALES LOS QUE ESTAN EN EL INTERVALO DE FECHAS DEL ZOOM Y LOS METEMOS EN EL RESUMEN (SI ES MUY LARGO APLANAMOS ARRAY)
    //CONSEGUIR EL INDEX
    var indexInicio = this.datosMaquinaFiltrado.findIndex(function (ind) {
      return ind.fecha_time >= th.zoom_tiempoGraficoIni;
    });
    indexInicio = indexInicio - 1;
    if (indexInicio == -1 || indexInicio == 0) indexInicio = 0;

    var indexFinal = this.datosMaquinaFiltrado.findIndex(function (ind) {
      return ind.fecha_time >= th.zoom_tiempoGraficoFin;
    });
    if (indexFinal == -1) indexFinal = this.datosMaquinaFiltrado.length - 1;
    indexFinal = indexFinal + 1;

    if (indexInicio == indexFinal && indexInicio != 0) indexInicio = indexFinal - 1;

    var datosMaquinaFiltrado_TrimPorFecha = this.datosMaquinaFiltrado.slice(indexInicio, indexFinal);

    if (datosMaquinaFiltrado_TrimPorFecha.length > 100) this.datosMaquinaFiltradoResumen = this.aplanarArray(datosMaquinaFiltrado_TrimPorFecha, 100);
    else this.datosMaquinaFiltradoResumen = datosMaquinaFiltrado_TrimPorFecha;

    this.cargarDatosGrafico(false);

    if (this.__cachedDomain != undefined) {
      var domain = [th.zoom_tiempoGraficoIni, th.zoom_tiempoGraficoFin];
      this.__cachedDomain = domain;
      this.xScale.domain(domain);
      this.xGridScale.domain(domain);
      this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15);
    }
    
  }

  cargarDatosGrafico(cargarBarra) {

    //USAREMOS LOS DATOS DE this.datosMaquinaFiltradoResumen
    //TIENE QUE SER UN ARRAY DE MAXIMO 100 ELEMENTOS Y LOS ELEMENETOS TIENEN QUE TENER ESTE FORMATO:
    //  · fecha_date: La fecha en formato date
    //  · fecha_time: La fecha en formato time para luego aplicar los zoom
    //  · valor: numero
    //  · minimo: numero
    //  · maximo: numero

    if (this.datosMaquinaFiltradoResumen.length > 0) {

      var array_fecha = this.datosMaquinaFiltradoResumen.map(a => a.fecha_date);
      var array_valores = this.datosMaquinaFiltradoResumen.map(a => a.valor);
      var array_valores_min = this.datosMaquinaFiltradoResumen.map(a => a.minimo);
      var array_valores_max = this.datosMaquinaFiltradoResumen.map(a => a.maximo);

      //Vamos a comprobar que como minimo tengamos un valor para cada dia del rango especificado
      var auxiFechaInicio = new Date(this.fechaInicioFiltrada);
      var auxiFechaFin = new Date(this.fechaFinFiltrada);
      while(auxiFechaInicio.getTime()<=auxiFechaFin.getTime()){
        //Comprobamos que la fecha este incluida
        var valorEncontrado = array_fecha.some(x=>x.getDay() ==auxiFechaInicio.getDay() && 
        x.getMonth() ==auxiFechaInicio.getMonth() && x.getFullYear() ==auxiFechaInicio.getFullYear());
        if(!valorEncontrado){//Si no lo ha encontrado hay que agregar la fecha, pero cada 3 horas. Es decir, a las 3,6,9,12,15,18,21
          array_fecha.push(new Date(auxiFechaInicio));
          array_valores.push(0);
          array_valores_min.push(0);
          array_valores_max.push(0);
        }
        auxiFechaInicio = new Date(auxiFechaInicio.getTime() + (24 * 60 * 60 * 1000));
        auxiFechaInicio.setHours(0,0,0,0);
      }

      //CALCULAMOS EL MAXIMO ENTRE LOS VALORES MAXIMOS Y LOS VALORES Y HACEMOS UN ARRAY CON ESE VALOR REPETIDO
      var MAXIMO = Math.max(...array_valores_max.concat(...array_valores));
      var MAXIMO_array = new Array(this.datosMaquinaFiltradoResumen.length);
      for (var i = 0; i < MAXIMO_array.length; i++) {
        MAXIMO_array[i] = MAXIMO;
      }

      //CALCULAMOS LOS VALORES DE CADA COLOR DEL GRAFICO
      var arrayValoresFecha: any = array_fecha;

      var arrayValoresVerde: any = array_valores_min;

      var arrayValoresBlanco: any = array_valores_max.map(function (num, idx) {
        return num - arrayValoresVerde[idx];
      });

      var arrayValoresRojo: any = MAXIMO_array.map(function (num, idx) {
        return num - arrayValoresBlanco[idx] - arrayValoresVerde[idx] + (MAXIMO * 0.1);
      });

      var arrayValoresValor: any = array_valores;
      var sumValores = arrayValoresValor.reduce((a, b) => a + b, 0);
      var avgValores = (sumValores / arrayValoresValor.length) || 0;
      var arrayValoresMedia: any = new Array(this.datosMaquinaFiltradoResumen.length);
      for (var i = 0; i < arrayValoresMedia.length; i++) {
        arrayValoresMedia[i] = avgValores;
      }


      //PONEMOS EL HEADER A LOS ARRAYS DEL GRAFICO
      arrayValoresFecha.unshift('x');
      arrayValoresVerde.unshift('verde');
      arrayValoresBlanco.unshift('blanco');
      arrayValoresRojo.unshift('rojo');
      arrayValoresValor.unshift('valor');
      arrayValoresMedia.unshift('media');

      //ASIGNAMOS LOS DATOS Y LOS MOSTRAMOS EN EL GRAFICO
      var botonSeleccionado = this.listaBotonesFiltroGrafico.find(x => x.selected);
      var tieneMaxMin = true;
      if (botonSeleccionado == undefined) tieneMaxMin = true;
      else if (botonSeleccionado.tieneMaxMin == undefined) tieneMaxMin = true;
      else if (botonSeleccionado.tieneMaxMin == false) tieneMaxMin = false;

      if (tieneMaxMin) {
        this.dataGrafico = [
          arrayValoresFecha,
          arrayValoresVerde,
          arrayValoresBlanco,
          arrayValoresRojo,
          arrayValoresValor,
          arrayValoresMedia
        ];
        this.grafico.show(['rojo', 'verde', 'blanco'], { withLegend: false });
      } else {
        this.dataGrafico = [
          arrayValoresFecha,
          //arrayValoresVerde,
          //arrayValoresBlanco,
          //arrayValoresRojo,
          arrayValoresValor,
          arrayValoresMedia
        ];
        this.grafico.hide(['rojo', 'verde', 'blanco'], { withLegend: false });
      }

     /*  var x = d3.scaleTime().domain([array_fecha[1], array_fecha[array_fecha.length - 1]]);
      this.grafico.internal.config.axis_x_tick_values = x.ticks(10); */

      this.grafico.load({ columns: this.dataGrafico });

      //GUARDAR LOS DATOS EN VARIABLES GLOBALES
      array_valores.shift();
      arrayValoresMedia.shift();
      array_valores_min.shift();
      this.array_valores = array_valores;
      this.array_valores_medias = arrayValoresMedia;
      this.array_valores_min = array_valores_min;
      this.array_valores_max = array_valores_max;

      //ENFOCAMOS LOS DATOS QUE TENEMOS EN EL GRAFICO
      var minimo = Math.min(...this.array_valores);
      var maximo = Math.max(...this.array_valores);
      var borde = (maximo - minimo) * 0.2;
      if (minimo - borde > 0) 
         this.grafico.axis.min(minimo - borde);
      else 
         this.grafico.axis.min(0);
      this.grafico.axis.max(maximo + borde);

      //if (cargarBarra) this.cargarBarraZoom(array_fecha[1], array_fecha[array_fecha.length - 1]);

    } else {

      this.dataGrafico = [
        ['x'],
        ['verde'],
        ['blanco'],
        ['rojo'],
        ['valor'],
        ['media'],
      ];

      this.grafico.internal.config.axis_x_tick_values = [];

      this.grafico.load({ columns: this.dataGrafico });

      //if (cargarBarra) this.cargarBarraZoom(this.fechaInicio, this.fechaFin);

    }

    this.cargandoGrafico = false;

  }

  public botonFiltroChange(e, boton) {

    if (e) {
      this.tituloGrafico = boton.nombre;
      this.unidadGrafico = boton.unidad;
      this.listaBotonesFiltroGrafico.forEach(function (b) {
        b.selected = false;
      });
      boton.selected = true;
      this.cargarDatosPorMaquina();
    }

  }

  public onMostrarTodosZoomClick() {
    this.cargarDatosPorMaquina();
  }

  cargarDatosPorMaquina() {

    var maquinaSeleccionada = this.selectedMaquina;

    //IDENTIFICAMOS EL TIPO DE MAQUINA QUE ES Y DEPENDIENDO DEL TIPO DE MAQUINA CARGAMOS LOS DATOS DE UNA MANERA U OTRA
    if (maquinaSeleccionada.tipo_maquina == 1) this.cargarDatosMecanizado();
    if (maquinaSeleccionada.tipo_maquina == 2) this.cargarDatosExtrusora();
    if (maquinaSeleccionada.tipo_maquina == 3) this.cargarDatosImpresoraDePlastico();
    if (maquinaSeleccionada.tipo_maquina == 4) this.cargarDatosInyectora();
    if (maquinaSeleccionada.tipo_maquina == 5) this.cargarDatosHorno();
    if (maquinaSeleccionada.tipo_maquina == 8) this.cargarDatosInstalacion();

  }

  public aplanarArray(data, aCuantosElementos) {
    var resultado = [];
    resultado.push(data[0]);
    var i;
    for (i = 1; i < aCuantosElementos; i++) {
      resultado.push(data[Math.round((data.length / aCuantosElementos) * i)]);
    }
    resultado.push(data[data.length - 1]);
    return resultado;
  }

  public __cachedDomain;
  public xScale;
  public xGridScale;
  public __zoomBrushSidesHandlers;
  public xScaleRef;

  cargarBarraZoom(fini, ffin) {

    var __obj_container = document.getElementById("barra-zoom");
    __obj_container.innerHTML = "";
    __obj_container.style.display = 'flex';

    var width = __obj_container.clientWidth - 90;

    var __obj = document.createElementNS("http://www.w3.org/2000/svg", "svg")
    __obj_container.append(__obj);
    var __obj_d3 = d3.select(__obj);

    __obj_d3
      .attr('width', width + 'px')
      .attr('height', 35 + 'px')
      .attr('class', "gantt-chart")

    this.xScale = d3.scaleTime()
      .domain([fini, ffin])
      .range([0, width]);

    this.__cachedDomain = this.xScale.domain();

    this.xScaleRef = d3.scaleTime()
      .domain(this.xScale.domain())
      .range([0, width]);

    this.xGridScale = d3.scaleTime()
      .domain(this.xScale.domain())
      .range(this.xScale.range());

    var __zoomBrush = __obj_d3.append("g")
      .attr('class', "zoom-brush")
      .attr('transform', `translate(0,${15})`);

    __zoomBrush.append('rect')
      .attr('class', "bg")
      .attr('width', width)
      .attr('height', 20);

    var timeoutActualizarZoom = false;

    var __zoomBrushController = d3.brushX()
      .extent([[1, 1], [width - 2, 20 - 1]])
      .on("start", function () {
        this.classList.add('grabbing');
      })
      .on("end", function () {
        this.classList.remove('grabbing');
      })
      .on("brush end", (event) => {
        if ((event.selection && event.sourceEvent)) {

          if (!timeoutActualizarZoom) {

            var domain = event.selection.map(this.xScaleRef.invert, this.xScale);
            this.__cachedDomain = domain;
            this.xScale.domain(domain);
            this.xGridScale.domain(domain);
            this.__zoomBrushSidesHandlers.attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15);

            this.zoom_tiempoGraficoIni = domain[0].getTime();
            this.zoom_tiempoGraficoFin = domain[1].getTime();
            this.aplicarZoomEnGrafico();

            timeoutActualizarZoom = true;
            setTimeout(function () {
              timeoutActualizarZoom = false;
            }, 25);

          }

        }
      });
    __zoomBrush
      .call(__zoomBrushController);

    __zoomBrush.call(__zoomBrushController.move, this.xScale.range()); //we set the brush with the default domain

    var __zoomBrushTicks = __zoomBrush.append('g')
      .attr('class', "x-top-axis")
      .attr('clip-path', "url(#taskClip)")
      .append('g')
      .attr('transform', `translate(0,${20 + 3})`)
      .call(d3.axisTop(this.xScaleRef)
        .ticks(Math.floor(width / 70))
        .tickFormat((d) => {
          var dayNames = [this.translateService.instant('abrDomingo'), this.translateService.instant('abrLunes'), this.translateService.instant('abrMartes'), this.translateService.instant('abrMiercoles'), this.translateService.instant('abrJueves'), this.translateService.instant('abrViernes'), this.translateService.instant('abrSabado')];
          let checkDate = new Date(d.getTime());
          checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
          return checkDate.getTime() == d.getTime() && dayNames[d.getDay()] + " " + d.getDate() || (d.getHours() < 10 ? '0' : '') + d.getHours() + ":" + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
        })
    ).call((d) => this.tickSizeFix(d, width));

    this.__zoomBrushSidesHandlers = __zoomBrush.selectAll('.handle--custom')
      .data([{ type: "w" }, { type: "e" }])
      .enter()
      .append('image')
      .attr('class', (d) => `handle--custom handle--${d}`)
      .attr("width", '30px')
      .attr("height", '30px')
      .attr("x", (d) => this.xScaleRef(d.type == 'w' && this.__cachedDomain[0] || this.__cachedDomain[1]) - 15)
      .attr("y", '-6px')
      .attr("xlink:href", 'assets/img/dragIconRoundBig.svg');

    __zoomBrushTicks.selectAll('.domain, line').remove();

    __zoomBrushTicks.selectAll("g.tick text")
      .attr("style", (d) => {
        let checkDate = new Date(d.getTime());
        checkDate.setHours(0); checkDate.setMinutes(0); checkDate.setSeconds(0);
        return checkDate.getTime() == d.getTime() && 'font-weight: bold!important;' || null;
      });

  }

  tickSizeFix(d, width, checkStart = false) {
    d.selectAll('g').each(function () {
      let x = parseFloat(this.getAttribute('transform').replace('translate(', '').replace(')', '').split(',')[0]);
      if ((checkStart && x < 0) || width < x + this.getBBox().width) this.remove();
    });
  }

  desplegarMaquinasClick() {
    this.myFunctions.desplegarMaquinasClick(this);
  }




  // GANTT GRAFIKO BERRIA HASI //


  // private cargarTurnos() {
  //   var r1, r2: boolean = false;

  //   var fechafinPRATURNOS: Date = new Date(this.fechaFin)


  //   this.InformeRendimientoService.get_turnos(this.selectedMaquina, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio)
  //     , this.myFunctions.dateToYYYYMMDDtHHmmSSz(new Date(fechafinPRATURNOS.setDate(fechafinPRATURNOS.getDate() + 1)))).subscribe(
  //       json => {
  //         this.turnosArray = [];
  //         var an: any = json;

  //         this.fechaMin = this.fechaInicio;
  //         this.fechaMax = this.fechaFin;

  //         // se cargan los horarios de la maquina como "por defecto" para marcar el inicio y fin del dia en caso de que no se cubra 100% con turnos
  //         var horarioFueraTurno = an.filter(f => f.clase == 'fuera')
  //         if (horarioFueraTurno.length > 0) {
  //           this.fechaMin = new Date(horarioFueraTurno[0].min);
  //           this.fechaMax = new Date(horarioFueraTurno[0].max);
  //         }
  //         an.forEach(f => {
  //           var d = new Date(f.turno);
  //           if (f.clase != 'fuera') {
  //             if (d.getTime() >= this.fechaInicio.getTime() && d.getTime() <= this.fechaFin.getTime()) {
  //               this.turnosArray.push({ min: new Date(f.min), max: new Date(f.max), clase: f.clase });
  //               if (new Date(f.min) < this.fechaMin)
  //                 this.fechaMin = new Date(f.min);
  //               if (new Date(f.max) > this.fechaMax)
  //                 this.fechaMax = new Date(f.max);
  //             }
  //             else if (d < this.fechaInicio)
  //               this.fechaMin = new Date(f.max);
  //             else if (d > this.fechaFin)
  //               this.fechaMax = new Date(f.min);
  //           }
  //         });

  //         r1 = true;
  //         if (r1 && r2)
  //           if (!this.esOffline)
  //             this.generarChartGanttTimeline();
  //           else
  //             this.generarChartGanttTimelineOffline();

  //       });

  //   if (!this.esOffline)
  //     this.InformeRendimientoService.get_gantt(this.selectedMaquina, this.turnosComboSelected, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe(
  //       json => {
  //         this.Jvalidador = json;
  //         this.dataValidador = json;
  //         r2 = true;
  //         if (r1 && r2)
  //           this.generarChartGanttTimeline();
  //       });
  //   else
  //     this.InformeRendimientoService.get_gantt_offline(this.selectedMaquina, this.turnosComboSelected, this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaInicio), this.myFunctions.dateToYYYYMMDDtHHmmSSz(this.fechaFin)).subscribe(
  //       json => {
  //         this.Jvalidador = json;
  //         this.dataValidador = json;
  //         r2 = true;
  //         if (r1 && r2)
  //           this.generarChartGanttTimelineOffline();
  //       });

  // }

  // private generarChartGanttTimeline() {
  //   let tasksNumber = 600,
  //     startDate = new Date(this.fechaInicio.getFullYear(), this.fechaInicio.getMonth(), this.fechaInicio.getDate()),
  //     endDate = new Date(this.fechaFin.getFullYear(), this.fechaFin.getMonth(), this.fechaFin.getDate());
  //   let data = {
  //     machines: [
  //       {
  //         id: '1',
  //         name: this.translateService.instant("ejecucion"),
  //         image: ''
  //       }, {
  //         id: '2',
  //         name: this.translateService.instant("parada"),
  //         image: ''
  //       }, {
  //         id: '3',
  //         name: this.translateService.instant("mantenimiento"),
  //         image: ''
  //       }, {
  //         id: '4',
  //         name: this.translateService.instant("alarma"),
  //         image: ''
  //       }, {
  //         id: '5',
  //         name: this.translateService.instant("apagada"),
  //         image: ''
  //       }
  //     ],
  //     tasks: []
  //   };

  //   var an: any = this.dataValidador;

  //   var idDb = this.user.idDb;

  //   an.forEach(f => {

  //     if (idDb == 33) {
  //       if (f.idProcesos_Tipo == 2 && f.fueraDeTiempo) {
  //         f['filter'] = {
  //           src: 'assets/img/diagonal-paradas.png',
  //           size: [95, 50], // original image w and h
  //           animated: false
  //         };
  //         f.backgroundColor = "#096844";
  //       }
  //     }

  //     if (f.idProcesos_Tipo == 2 && f.tiemporeal <= f.tiempoMicroParada && f.tiemporeal != 0) {
  //       f.backgroundColor = "#CF8729";
  //     }

  //     var tooltip = ``;

  //     tooltip = tooltip + `<div class="tooltipa"><p><strong><span class="tooltip-negrita">`;

  //     if (f.idProcesos_Tipo == 1) tooltip = tooltip + `${this.translateService.instant("ejecucion").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 2) tooltip = tooltip + `${this.translateService.instant("parada").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 3) tooltip = tooltip + `${this.translateService.instant("preparacion").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 4) tooltip = tooltip + `${this.translateService.instant("mantenimiento").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 6) tooltip = tooltip + `${this.translateService.instant("alarma").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 7) tooltip = tooltip + `${this.translateService.instant("alarma").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 8) tooltip = tooltip + `${this.translateService.instant("apagada").toUpperCase()}`;
  //     if (f.idProcesos_Tipo == 9) tooltip = tooltip + `${this.translateService.instant("canal").toUpperCase()}`;

  //     tooltip = tooltip + `</strong></label></p>`;

  //     if (f.nombrePrograma != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("nombrePrograma").toUpperCase()}: </span><span class="tooltip-valor">${f.nombrePrograma}<br></span></p>`;

  //     if (f.descripcion != "") tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("descripcion").toUpperCase()}: </span><span class="tooltip-valor">${f.descripcion}<br></span></p>`;


  //     tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("operario").toUpperCase()}: </span><span class="tooltip-valor">${f.operario}</span></p>`;

  //     tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

  //     if (f.refOF != "" && f.idProcesos_Tipo != 4) {
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("of").toUpperCase()}: </span><span class="tooltip-valor">${f.of}<br></span></p>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("cliente").toUpperCase()}: </span><span class="tooltip-valor">${f.cliente}<br></span></p>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("refPieza").toUpperCase()}: </span><span class="tooltip-valor">${f.refPieza}<br></span></p>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("pieza").toUpperCase()}: </span><span class="tooltip-valor">${f.pieza}<br></span></p>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("operacion").toUpperCase()}: </span><span class="tooltip-valor">${f.programa}<br></span></p>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("nserie").toUpperCase()}: </span><span class="tooltip-valor">${f.numeroSerie}<br></span></p>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("cantidad2").toUpperCase()}: </span><span class="tooltip-valor">${f.cantidad}<br></span></p>`;
  //     } else if (f.idProcesos_Tipo != 4) {
  //       tooltip = tooltip + `<p><span class="tooltip-title">${this.translateService.instant("operacionNoEncontrada").toUpperCase()}<br></span></p>`;
  //     } else {
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("mantenimiento").toUpperCase()}: </span><span class="tooltip-valor">${f.nombreMantenimiento}<br></span></p>`;
  //     }

  //     if (f.perdida != "") {
  //       tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;
  //       tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("perdida").toUpperCase()}: </span><span class="tooltip-valor">${f.perdida}<br></span></p>`;
  //     }

  //     tooltip = tooltip + `<hr style="margin-top: -0.3rem; margin-bottom: 0.8rem;"/>`;

  //     var fIni = new Date(f.fechaini);
  //     var fFin = new Date(f.fechafin);
  //     var dif = this.myFunctions.secondsTo_HH_MM_SS((fFin.getTime() - fIni.getTime()) / 1000);

  //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("duracion").toUpperCase()}: </span><span class="tooltip-valor">${dif}<br></span></p>`;
  //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("inicio2").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fIni)}<br></span></p>`;
  //     tooltip = tooltip + `<p><span class="tooltip-title"> ${this.translateService.instant("fin").toUpperCase()}: </span><span class="tooltip-valor">${this.myFunctions.dateToString(fFin)}<br></span></p>`;

  //     tooltip = tooltip + `</div>`;

  //     f.detail2 = tooltip;

  //     if (this.visibleTipo) {
  //       f.detail = (d) => f.detail2;
  //     }
  //   });
  //   data.tasks = an;

  //   this.chartGanttTimeline = new GanttChartClick('chartGanttTimeline', {
  //     width: 'fit', //introductir un tamaño o poner 'fit' para que se adapte a su padre
  //     height: '250',
  //     startDate: this.fechaMin,
  //     endDate: this.fechaMax,
  //     shifts: this.turnosArray,
  //     data: data,
  //   }, this.translateService)
  //     .on('task_clicked', (id, data) => {
  //       //this.ganttClicked(id);
  //     });

  //   this.loadingGantt = false;

  // }

  // GANTT GRAFIKO BERRIA BUKATU //

}
