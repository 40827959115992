import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const baseUrl = `${environment.apiUrl}/informerendimiento`;

@Injectable()
export class InformeRendimientoService {

  constructor(private http: HttpClient) { }

  public get_turnos(idMaquina: number, fechaInicio: string, fechaFin: string) {
    //ESTA FUNCION ESTA ANTICUADA (por no tener que corregir todas las consultas ahora, he creado la que sera la nueva funcion debajo y mantengo las 2)
    return this.http.post<JSON>(`${baseUrl}/get/turnos`, { idMaquina, fechaInicio, fechaFin, hoy: false }, { withCredentials: true });
  }
  public get_turnos_new(idMaquina: number, fechaInicio: string, fechaFin: string, hoy: boolean) {
    return this.http.post<JSON>(`${baseUrl}/get/turnos`, { idMaquina, fechaInicio, fechaFin, hoy }, { withCredentials: true });
  }
  public get_gantt(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get/gantt`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public get_gantt_subEstados_activos(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get_gantt_subEstados_activos`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public gantt_sinTurno(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get/gantt_sinTurno`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public gantt_sinTurno_filtro(consultaFiltro: string) {
    return this.http.post<JSON>(`${baseUrl}/get/gantt_sinTurno_filtro`, { consultaFiltro }, { withCredentials: true });
  }
  public get_gantt_offline(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get_gantt_offline`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public get_procesos(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get/procesos`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public get_procesos_completo(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get/get_procesos_completo`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public get_procesos_horas(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get/procesosHoras`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
  public get_procesos_porDias_subEstados_activos(idMaquina: number, idsTrunos: string, fechaInicio: string, fechaFin: string) {
    return this.http.post<JSON>(`${baseUrl}/get/get_procesos_porDias_subEstados_activos`, { idMaquina, idsTrunos, fechaInicio, fechaFin }, { withCredentials: true });
  }
}
