<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-md-6">

      <div class="card">
        <div class="card-header">
          <h3>
            <label CssClass="">{{ 'herramienta' | translate }}</label>
          </h3>
          <div class="plegarpanel"></div>
        </div>
        <div class="card-body">
          <div class="row">
            <!--TIPO HERRAMIENTA-->
            <div class="form-group col">
              <kendo-label text="{{ 'tipoHerramienta' | translate }}">
                <div class="caja">
                  <kendo-combobox [data]="tipoHerramienta"
                                  [textField]="'nombre'"
                                  [valueField]="'id'"
                                  formControlName="tipoHerramienta"
                                  placeholder="{{ 'tipoHerramienta' | translate }}"
                                  [kendoDropDownFilter]="{operator: 'contains'}">
                  </kendo-combobox>
                </div>
              </kendo-label>
            </div>
            <!--FABRICANTE-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'fabricante' | translate }}">
                  <div class="caja">
                    <kendo-combobox [data]="fabricante"
                                    [textField]="'nombre'"
                                    [valueField]="'id'"
                                    formControlName="fabricante"
                                    placeholder="{{ 'fabricante' | translate }}"
                                    [kendoDropDownFilter]="{operator: 'contains'}">
                    </kendo-combobox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
          <div class="row">
            <!--NOMBRE-->
            <div class="form-group col">
              <kendo-label text="{{ 'nombre' | translate }}">
                <div class="caja">
                  <kendo-textbox formControlName="nombre" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                </div>
              </kendo-label>
            </div>
            <!--REFERENCIA-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'referencia' | translate }}">
                  <div class="caja">
                    <kendo-textbox formControlName="referencia" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-textbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--TIENE NSERIE-->
            <div class="form-group col">
              <kendo-switch formControlName="tieneNserie" [onLabel]="' '" [offLabel]="' '" id="hmiInicioId"></kendo-switch>
              <kendo-label text="{{ 'tieneNserie' | translate }}"></kendo-label>
            </div>
          </div>
          <div class="row">
            <!--VIDA UTIL-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'vidaUtil' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="vidaUtil" [decimals]="2" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--DIAMETRO-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'diametro' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="diametro" [decimals]="0" [format]="'n'" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--LONGITUD-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'longitud' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="longitud" [decimals]="0" [format]="'n'" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
          <div class="row">
            <!--ANGULO-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'angulo' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="angulo" [decimals]="0" [format]="'n'" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--NUM PLACAS-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'numPlacas' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="numPlacas" [decimals]="0" [format]="'n'" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--SALTO-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'salto' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="salto" [decimals]="0" [format]="'n'" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
          <div class="row">
            <!--IDERP-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'idERP' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="idERP" [decimals]="0" [format]="'n'" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
            <!--COSTE-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'coste' | translate }}">
                  <div class="caja">
                    <kendo-numerictextbox formControlName="coste" [decimals]="2" [min]="0" [max]="999999" maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.nombreUsuario.errors }"></kendo-numerictextbox>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>
          <div class="row">
            <!--DESCRIPCION-->
            <div class="form-group col">
              <div class="form-group col">
                <kendo-label text="{{ 'descripcion' | translate }}">
                  <div class="caja">
                    <textarea kendoTextArea formControlName="descripcion"></textarea>
                  </div>
                </kendo-label>
              </div>
            </div>
          </div>

          <!--BOTONES-->
          <div class="form-group">
            <button class="btn btn-primary btn-sm mr-1" [disabled]="this.user.herramientas < 2">
              {{ 'guardar' | translate}}
            </button>
            <button type="submit" class="btn btn-danger btn-sm mr-1" (click)="atras()" >
              {{ 'cancelar' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
