import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
@Component({ selector: 'grafico-nivel', templateUrl: 'graficoNivel.html' })

export class GraficoNivelComponent implements OnInit {

  @Input() public min: number;
  @Input() public max: number;
  @Input() public value: number;
  @Input() public id: string;

  public array = [];

  constructor(public router: Router) {
      
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.array = []
    var m = Math.round(this.min / 10) * 10;
    for (var i=m; i<=this.max; i = i+10) {
      this.array.push(Math.round(i / 10) * 10);
    }    
  }


  ngOnInit(): void {
  }

  calcularAltura() {
    if (this.value > this.max) {
      return  '100%';
    } else {
      var porcen = (this.value - this.min) * 100 / (this.max - this.min);
      return porcen + '%';
    }
  }

}
