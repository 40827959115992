<style>
  .k-text-error {
    display: none;
  }

  :host /deep/ .k-grid tbody td {
    white-space: nowrap;
    line-height: 20px;
    padding: 8px 12px;
  }

  :host /deep/ .k-grid .k-grid-content td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
<div *ngIf="loadingGraficoTresEjes" id="loadingDiario" class="k-i-loading" style="z-index: 3;"></div>
<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template><div class="card mb-0">
  <div class="clearfix">
    <div class="card-body">

      <div class="float-left mr-3">
        <div class="form-group">
          <label ID="lAsignarPerdidaA" class="control-label">{{ 'maquinasgrupos' | translate }}</label>
          <div class="caja">

            <kendo-combobox style="width:150px" [data]="gruposMaquinas" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionGrupoMaquinaChange($event)" class="form-control"
                            [value]="selectedGrupo" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>

      </div>
      <div class="float-left mr-3">
        <div class="form-group">
          <label ID="lAsignarPerdidaA" class="control-label">{{ 'maquinas' | translate }}</label>
          <div class="caja">

            <kendo-combobox style="width:250px" [data]="maquinasVisibles" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionMaquinaChange($event)" [value]="selectedMaquinaC"
                            class="form-control" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>

      </div>
      <div class="float-left mr-3">
        <div class="form-group" [style.display]="showEjes ? 'block' : 'none'">
          <label ID="lAsignarPerdidaA" class="control-label">{{ 'eje' | translate }}</label>
          <div class="caja">
            <kendo-combobox style="width:150px" [data]="ejesVisibles" [textField]="'nombre'" [valueField]="'nombre'" (selectionChange)="selectionEjeChange($event)" class="form-control"
                            [value]="selectedEje" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="float-left mr-3">
        <div class="form-group">
          <label ID="lAsignarPerdidaA" class="control-label">{{ 'tipoparametro' | translate }}</label>
          <div class="caja">
            <kendo-combobox style="width:150px" [data]="tiposVariables" [textField]="'nombre'" [valueField]="'id'" (selectionChange)="selectionVariableChange($event)" class="form-control"
                            [value]="selectedVariable" [kendoDropDownFilter]="{operator: 'contains'}">
            </kendo-combobox>
          </div>
        </div>
      </div>
      <div class="float-left mr-3">
        <div class="form-group">
          <label ID="Lfechafin" class="control-label">{{ 'fechainicio' | translate}}</label>
          <div class="caja">
            <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..." id="rntfechafin" class="form-control" (valueChange)="onFechaIniChange($event)" [(value)]="fechaIniValue">
            </kendo-datepicker>
          </div>
        </div>

      </div>
      <div class="float-left mr-3">
        <div class="form-group">
          <label ID="Lfechafin" class="control-label">{{ 'fechafin' | translate}}</label>
          <div class="caja">
            <kendo-datepicker placeholder="{{ 'fechainiciop' | translate}}..." id="rntfechafin" class="form-control" (valueChange)="onFechaFinChange($event)" [(value)]="fechaFinValue">
            </kendo-datepicker>
          </div>
        </div>

      </div>



      <div class="float-left mr-3">
        <div class="form-group">

          <div class="caja nolabel">
            <a ID="bEliminar" (click)="onFilterClck()" class="btn btn-primary btn-sm mr-1">{{ 'filtro' | translate }}</a>
          </div>
        </div>

      </div>






    </div>
  </div>
</div>
<div kendoTooltip
     showOn="none"
     [tooltipTemplate]="template"
     filter=".k-grid td th tr"
     (mouseover)="showTooltip($event)">

  <kendo-grid [kendoGridBinding]="historicoparametros"
              [sortable]="true"
              [navigable]="true"
              filterable="menu"
              scrollable="virtual"
              [rowHeight]="36"
              [height]="700"
              kendoGridSelectBy="id"
              [pageSize]="50"
              [resizable]="true"
              [selectedKeys]="seleccionados"
              (cellClick)="cellClick($event)"
              *ngIf="router.url === '/historicoparametros'">

                    <!--BOTONES ARRIBA-->
    <ng-template kendoGridToolbarTemplate position="top">

                      <!-- <button (click)="onClickEditar()" class="btn btn-success btn-sm mr-1">{{ 'editar' | translate}}</button>
        <button (click)="onClickNuevo()" class="btn btn-primary btn-sm mr-1">{{ 'nuevo' | translate}}</button>
        <button (click)="onClickEliminar(content)" class="btn btn-danger btn-sm mr-1" [disabled]="isDeleting">{{ 'eliminar' | translate}}</button>-->
    </ng-template>
    <!--checkbox NombreEje-->
    <!--<kendo-grid-checkbox-column width="4%" showSelectAll="true"></kendo-grid-checkbox-column>-->
    <!--id-->
    <!--<kendo-grid-column width="100%" field="id" hidden="hidden" title="id"></kendo-grid-column>-->
    <!--OF-->
    <kendo-grid-column width="29%" [style]="{'text-align': 'left'}" field="nombreMaquina" title="{{ 'maquina' | translate}}"></kendo-grid-column>
    <kendo-grid-column width="4%" [style]="{'text-align': 'left'}" field="nombreEje" title="{{ 'eje' | translate}}"></kendo-grid-column>
    <!--Pieza-->
    <kendo-grid-column width="8%" [style]="{'text-align': 'left'}" field="tipoDeDato" title="{{ 'dato' | translate}}"></kendo-grid-column>
    <!--Parte-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="maximo" title="{{ 'vmax' | translate}}"></kendo-grid-column>
    <!--Operacion-->
    <kendo-grid-column width="6%" [style]="{'text-align': 'left'}" field="tipoDato" title="{{ 'tipo' | translate}}"></kendo-grid-column>
    <!--Maquina-->
    <kendo-grid-column width="9%" [style]="{'text-align': 'left'}" field="fechaInicio" title="{{ 'horainicio' | translate}}">
      <!--Operario-->
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaInicio | kendoDate }} {{dataItem.fechaInicio | kendoDate:'HH:mm:ss' }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column width="6%" [style]="{'text-align': 'right'}" field="valMaxAlcanzado" title="{{ 'vmaxObtenida' | translate}}"></kendo-grid-column>
    <!--hora-->
    <kendo-grid-column width="9%" [style]="{'text-align': 'left'}" field="valorMaxFecha" title="{{ 'horapico' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.valorMaxFecha | kendoDate }} {{dataItem.valorMaxFecha | kendoDate:'HH:mm:ss' }}</ng-template>

    </kendo-grid-column>
    <!--horavuelta-->

    <kendo-grid-column width="9%" [style]="{'text-align': 'left'}" field="fechaFin" title="{{ 'horavuelta' | translate}}">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.fechaFin | kendoDate }} {{dataItem.fechaFin | kendoDate:'HH:mm:ss' }}</ng-template>

    </kendo-grid-column>
    <!--FechaInicio-->



    <kendo-grid-messages filter="{{'filter' | translate}}" filterAfterOperator="{{'filterAfterOperator' | translate}}"
    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
    filterAndLogic="{{'filterAndLogic' | translate}}" filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
    filterBooleanAll="{{'filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
    filterContainsOperator="{{'filterContainsOperator' | translate}}"
    filterDateToday="{{'filterDateToday' | translate}}" filterDateToggle="{{'filterDateToggle' | translate}}"
    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
    filterEqOperator="{{'filterEqOperator' | translate}}" filterFilterButton="{{'filterFilterButton' | translate}}"
    filterGtOperator="{{'filterGtOperator' | translate}}" filterGteOperator="{{'filterGteOperator' | translate}}"
    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}" filterIsFalse="{{'filterIsFalse' | translate}}"
    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
    filterIsNullOperator="{{'filterIsNullOperator' | translate}}" filterIsTrue="{{'filterIsTrue' | translate}}"
    filterLtOperator="{{'filterLtOperator' | translate}}" filterLteOperator="{{'filterLteOperator' | translate}}"
    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
    filterNumericIncrement="{{'filterNumericIncrement' | translate}}" filterOrLogic="{{'filterOrLogic' | translate}}"
    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}" loading="{{'loading' | translate}}"
    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}" unlock="{{'unlock' | translate}}"
    noRecords="{{'norecords' | translate}}" pagerItems="" pagerOf="/"></kendo-grid-messages>
  </kendo-grid>
</div>
<!--POPUP ELIMINAR-->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'preguntaeliminarpopup' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="eliminarRegistro(contentloading, contentNotErased)" [disabled]="this.user.historicoParametros < 2">{{ 'si' | translate }}</button>
  </div>
</ng-template>

<!--POPUP NO SE PUDO ELIMINAR-->
<ng-template #contentNotErased let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'registrosnoeliminadas' | translate }}</p>
  </div>

</ng-template>

<ng-template #contentloading let-modal>
  <div class="modal-body">
    <p>{{ 'eliminando' | translate }}</p>
    <div class="spinner-border" role="status">
      <span class="sr-only">{{ 'eliminando' | translate }}...</span>
    </div>
  </div>
</ng-template>

<router-outlet></router-outlet>
