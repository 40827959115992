import { Component, ViewChild } from '@angular/core';
import { Router } from "@angular/router"
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService, MenuService, NotasService } from '../_services';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ActivatedRoute } from '@angular/router';
import { FabricantesService } from '@app/_services';
import { AlmacenesService } from '@app/_services/almacenes.service';
import { MyFunctions } from '@app/_helpers';
import { UsuariosService } from '@app/_services';
//import { ConsumibleComponent } from '@app/consumibles/consumible.component';

@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
})

export class NotasComponent {

  //VARIABLES BASICAS
  private translate: TranslateService;
  public verArchivados: boolean = false;

  //GRID: notas
  public listaNotas: any[] = [];
  public listaNotasArchivadas: any[] = [];
  public dataGrid: any;
  public cargado: boolean = false;

  //Lectores
  public listaLectores: any[] = [];
  public inicialesLectores: any[] = [];

  //Operarios
  public listaOperarios: any[] = [];

  //Usuarios colores
  public usuariosColores: {} = {};

  //Seleccion
  public seleccionados: any[] = [];

  //Tooltip
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  public target: string = '';

  //Constructor
  constructor(
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private alertService: AlertService,
    public route: ActivatedRoute,
    public fabricantesService: FabricantesService,
    public almacenesService: AlmacenesService,
    private usuariosService: UsuariosService,
    private notasService: NotasService,
    public myFunctions: MyFunctions) {

    this.translate = translate;
    this.menuService.titulo = this.translate.instant('Notas').toUpperCase();
    this.cargado = false;
  }

  /**
   * Funcion de ngOnInit, carga las notas de la DB
   */
  ngOnInit() {
    this.cargado = false;
    this.notasService.GetAll().subscribe(
      (result) => {
        var auxlist: any = result;
        auxlist.forEach(n => {
          var idsLectores = n.leidoPor.split(',');
          var nombresLectores = [];
          idsLectores.forEach(l => {
            this.usuariosService.getById(l).subscribe(
              (lector) => {
                nombresLectores.push(lector.nombre + ' ' + lector.apellido1);
              });
          });
          this.listaLectores.push(nombresLectores);
          if (n.archivado) this.listaNotasArchivadas.push(n);
          if (!n.archivado) this.listaNotas.push(n);
          this.dataGrid = this.listaNotas;
        });
      });

      this.notasService.GetUsuariosColores().subscribe(
        (usuarios) => {
          var aux: any = usuarios;
          aux.forEach(u => {
            this.usuariosColores[u.id] = [u.color, u.nombre, u.apellido1, u.apellido2];
          });
          this.usuariosService.getAll().subscribe(
            (usuarios) => {
              for (let i = 0; i < usuarios.length; i++) {
                this.listaOperarios[usuarios[i].id] = usuarios[i];
              }
              this.cargado = true;
            });
        });
  }

  /**
   * Al clicar en el boton de Ver Archivados, cambia la fuente
   * de datos del grid
   */
  onClick_VerArchivados() {
    this.seleccionados = [];
    this.verArchivados = !this.verArchivados;
    if (this.verArchivados) this.dataGrid = this.listaNotasArchivadas;
    if (!this.verArchivados) this.dataGrid = this.listaNotas;
  }

  /**
   * Al clicar en el boton Editar, gestiona errores y 
   * navega a notas/editar
   */
  onClick_Editar() {
    if (this.seleccionados.length > 1) {
      this.alertService.error(this.translateService.instant('multiplesnotas'), { keepAfterRouteChange: true });
    } else if (this.seleccionados.length == 0) {
      this.alertService.warn(this.translateService.instant('notanoseleccionada'), { keepAfterRouteChange: true });
    } else {
      this.router.navigate(['notas/editar/', this.seleccionados[0]]);
    }
  }

  /**
   * Al clicar en Archivar, gestiona errores, archiva las notas 
   * seleccionadas y obtiene las notas actualizadas de nuevo, 
   * refrescando la fuente de datos del grid
   */
  onClick_Archivar() {
    if (this.seleccionados.length == 0) {
      this.alertService.warn(this.translateService.instant('notanoseleccionada'), { keepAfterRouteChange: true });
    } else {
      this.notasService.ArchivarNotas(this.seleccionados).subscribe(
        (result) => {
          console.log("Notas archivadas");
          this.alertService.success(this.translateService.instant('notaarchivada'), { keepAfterRouteChange: true });
          this.listaLectores = [];
          this.listaNotas = [];
          this.listaNotasArchivadas = [];
          this.notasService.GetAll().subscribe(
            (result) => {
              var auxlist: any = result;
              auxlist.forEach(n => {
                var idsLectores = n.leidoPor.split(',');
                var nombresLectores = [];
                idsLectores.forEach(l => {
                  this.usuariosService.getById(l).subscribe(
                    (lector) => {
                      nombresLectores.push(lector.nombre + ' ' + lector.apellido1);
                    });
                });
                this.listaLectores.push(nombresLectores);
                if (n.archivado) this.listaNotasArchivadas.push(n);
                if (!n.archivado) this.listaNotas.push(n);
                this.dataGrid = this.listaNotas;
              });
            });
        });
    }
  }

  /**
   * Al clicar el boton Eliminar, muestra el modal
   * de confirmacion de eliminacion
   * @param eliminar modal de eliminar
   */
  onClick_Elimnar(eliminar) {
    if (this.seleccionados.length == 0) {
      this.alertService.warn(this.translateService.instant('notanoseleccionada'), { keepAfterRouteChange: true });
    } else {
      this.modalService.open(eliminar, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }
  }

  /**
   * Se llama al confirmar eliminacion de las notas seleccionadas 
   * en el modal de eliminar. Elimina las notas y obtiene las notas
   * actualizadas refrescando la fuente de datos del grid
   */
  eliminarNota() {
    this.notasService.EliminarNotas(this.seleccionados).subscribe(
      (result) => {
        console.log("Notas eliminadas");
        this.modalService.dismissAll();
        this.alertService.success(this.translateService.instant('notaeliminada'), { keepAfterRouteChange: true });
        this.listaLectores = [];
        this.listaNotas = [];
        this.listaNotasArchivadas = [];
        this.notasService.GetAll().subscribe(
          (result) => {
            var auxlist: any = result;
            auxlist.forEach(n => {
              var idsLectores = n.leidoPor.split(',');
              var nombresLectores = [];
              idsLectores.forEach(l => {
                this.usuariosService.getById(l).subscribe(
                  (lector) => {
                    nombresLectores.push(lector.nombre + ' ' + lector.apellido1);
                  });
              });
              this.listaLectores.push(nombresLectores);
              if (n.archivado) this.listaNotasArchivadas.push(n);
              if (!n.archivado) this.listaNotas.push(n);
              if (this.verArchivados) this.dataGrid = this.listaNotasArchivadas;
              if (!this.verArchivados) this.dataGrid = this.listaNotas;
            });
          });
      });
  }

  /**
   * Para mantener un control de la seleccion
   * @param e
   */
  selectionChange(e: any): void {
    console.log("Seleccionados: " + this.seleccionados);
  }

  /**
   * Muestra el tooltip encima de los elementos de las columnas 
   * numero 2 y 6 correspondientes al los nombres y apellidos
   * del usuario creador y los lectores de la nota
   * @param e evento de hover del mouse
   */
  showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (element.attributes[0].ownerElement.className.includes('grid-circulo')) {
      if (element.parentElement.parentElement.parentElement.attributes[4].value == '2') this.target = 'usuario';
      if (element.parentElement.parentElement.parentElement.attributes[4].value == '6') this.target = 'lectores';
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
